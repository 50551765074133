<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <div
            class="main-header navbar navbar-expand navbar-white navbar-light"
          >
            <h3 class="card-title">
              Нийт суралцагчид: {{ schoolstudents.length }}. Сургуулийн
              суралцагчид шүүж харах&nbsp;&nbsp;
            </h3>
            <search-student
              @getWasSearchStudents="checkStudents($event)"
              :year_id="year_id"
            ></search-student>
          </div>
          <b-row class="mt-3">
            <b-col sm="7"
              ><multiselect
                v-model="fieldName"
                :options="fieldList"
                @select="setFieldList"
                :multiple="true"
                track-by="value"
                label="label"
                :searchable="true"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Excel файл руу гаргах баганаа сонгоно уу."
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="5">
              <b-button
                size="sm"
                @click="resetFields"
                class="mr-2"
                pill
                variant="outline-primary"
              >
                <b-icon icon="check-all" aria-hidden="true"></b-icon> Бүх талбар
              </b-button>
              <download-excel
                class="btn btn-success btn-sm"
                :data="json_data"
                :fields="json_fields"
                worksheet="Суралцагч"
                name="Суралцагчид.xls"
              >
                <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                Файл татах
              </download-excel>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-md-3">
                  <b-form-select
                    v-model="selected"
                    :options="school_classes"
                    text-field="full_name"
                    value-field="id"
                    size="sm"
                  >
                  </b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="year_id"
                    :options="schoolYears"
                    text-field="year_name"
                    value-field="id"
                    size="sm"
                  >
                  </b-form-select>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllStudents"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table
            class="table table-bordered table-hover table-striped dataTable dtr-inline"
          >
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Хүйс</th>
                <th>Жил</th>
                <th>Имэйл</th>
                <th>Анги</th>
                <th>Элсэлтийн мэдээлэл</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th v-if="role === 'agerman'">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                :class="{ 'table-warning': isAll }"
                v-for="(schoolstudent, index) in schoolstudents"
                :key="index"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- Using modifiers -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="createParent(schoolstudent)"
                  >
                    {{ schoolstudent.student_last_name }}</a
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="editStudentInfo(schoolstudent)"
                  >
                    {{ schoolstudent.student_name }}</a
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.student_gender }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <p style="width: 5rem">
                    {{ schoolstudent.start_date.substring(0, 4) }} -
                    {{ schoolstudent.finish_date.substring(0, 4) }}
                  </p>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.full_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    class="badge badge-primary text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-if="
                      schoolstudent.contact_info !== null ||
                      schoolstudent.study_class_sanal_desc !== null
                    "
                  >
                    Холбоо барих: {{ schoolstudent.contact_info }} <br />
                    Элсэлтийн санал:
                    {{ schoolstudent.study_class_sanal_desc }} <br />
                    Регистер: {{ schoolstudent.profile_student_reg }}
                  </span>
                  <span
                    class="badge badge-info text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-else
                  >
                    Мэдээлэл бүртгэгдээгүй
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.updated_at | dateYearMonthDay }}
                </td>
                <td
                  v-if="role === 'agerman'"
                  class="dtr-control sorting_1"
                  tabindex="0"
                >
                  <p style="width: 8rem">
                    <!-- <a
                        href="#"
                        class="badge bg-purple"
                        @click="viewSchoolStudent(schoolstudent)"
                        ><i class="far fa-eye"></i
                      ></a> -->
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolStudent(schoolstudent)"
                      ><i
                        class="fas fa-edit"
                        v-b-popover.hover.top="
                          'Суралцагчийг мэдээлэл засварлах!'
                        "
                        title="Засварлах"
                      ></i
                    ></a>

                    <!-- <a
                      href="#"
                      class="badge bg-info"
                      @click="inActiveSchoolStudent(schoolstudent)"
                      ><i
                        class="fas fa-step-forward"
                        v-b-popover.hover.top="
                          'Тухайн хичээлийн жилд идэвхигүй болгох!'
                        "
                        title="Идэвхигүй болгох"
                      ></i
                    ></a> -->
                    <!-- <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolStudent(schoolstudent)"
                    >
                      <i
                        class="fas fa-sign-out-alt"
                        v-b-popover.hover.top="
                          'Суралцагчийг шилжсэн төлөвт шилжүүлэх!'
                        "
                        title="Шилжих"
                      ></i
                    ></a> -->
                    <!-- <a
                      href="#"
                      class="badge bg-success"
                      @click.prevent="surdagUnen(schoolstudent)"
                    >
                      <b-icon
                        v-b-popover.hover.top="
                          'Сургуульд сурдаг нь үнэн тодорхойлолт авах!'
                        "
                        title="Суралцадаг нь үнэн"
                        icon="file-earmark"
                        aria-hidden="true"
                      ></b-icon>
                    </a> -->
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эрэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эмэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAFeMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countA() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <div class="col-lg-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Суралцагчийн мэдээлэл засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="updateSchoolStudent()">
          <div class="card-body">
            <div class="form-group">
              <label>Ургийн овог</label>
              <input
                type="text"
                name="student_family_name"
                v-model="form.student_family_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_family_name'),
                  },
                ]"
                placeholder="Ургийн овог"
              />
              <has-error :form="form" field="student_family_name"></has-error>
            </div>
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="student_last_name"
                v-model="form.student_last_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="student_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="student_name"
                v-model="form.student_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="student_name"></has-error>
            </div>
            <div class="form-group">
              <label>Имэйл</label>
              <input
                type="email"
                name="email"
                v-model="form.email"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('email'),
                  },
                ]"
                placeholder="Цахим шуудан"
              />
              <has-error :form="form" field="email"></has-error>
            </div>
            <div class="form-group">
              <label>Харьяалагдах анги</label>
              <b-form-select
                v-model="form.school_class_id"
                :options="school_classes"
                text-field="full_name"
                value-field="id"
              >
              </b-form-select>
              <has-error :form="form" field="full_name"></has-error>
            </div>
            <div class="form-group">
              <label>Харьяалагдах хичээлийн жил</label>
              <b-form-select
                v-model="form.schoolYearId"
                :options="schoolYears"
                text-field="year_name"
                value-field="id"
              >
              </b-form-select>
              <has-error :form="form" field="year_name"></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Засах
            </button>
            <button
              class="btn btn-warning"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="col-lg-3" v-show="deleteMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Суралцагч шилжсэн төлөвт шилжүүлэх</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="leaveStudent()">
          <div class="card-body">
            <div class="form-group">
              <label>Шилжсэн шалтгаан</label>
              <b-form-select
                v-model="formLeave.leave_type_list"
                :options="leave_types"
                text-field="name"
                value-field="id"
                multiple
                :select-size="4"
              ></b-form-select>
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <label>Шилжсэн тайлбар</label>
              <input
                type="text"
                name="student_shiljilt_shaltgaan"
                v-model="formLeave.student_shiljilt_shaltgaan"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_shiljilt_shaltgaan'),
                  },
                ]"
                placeholder="шилжсэн тайлбар"
              />
              <has-error
                :form="formLeave"
                field="student_shiljilt_shaltgaan"
              ></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="deleteMode">
              Хадгалах
            </button>
            <button
              class="btn btn-warning"
              v-show="deleteMode"
              @click.prevent="cancelDelete"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <create-parent
      v-show="createMode"
      :forParentChild="forParentChild"
      :parentDataProps="parentDataProps"
    />
    <b-modal
      size="xl"
      id="modal-student-info"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчийн мэдээлэл засах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <!-- <div class="card-header">
                <h3 class="card-title">Суралцагчын зураг солих</h3>
              </div> -->
              <!-- /.card-header -->
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#activity"
                          data-toggle="tab"
                          >Суралцагчийн мэдээлэл</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#edit" data-toggle="tab"
                          >Мэдээлэл засах</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="active tab-pane" id="activity">
                        <!-- Post -->
                        <div class="post">
                          <div class="user-block">
                            <img
                              v-if="
                                getMyInfoStudent.student_profile_photo_url !=
                                null
                              "
                              :src="getMyInfoStudent.student_profile_photo_url"
                              alt="user-avatar"
                              class="img-circle img-bordered-sm"
                            />
                            <img
                              v-else
                              src="/images/users/user.png"
                              alt="user-avatar"
                              class="img-circle img-bordered-sm"
                            />
                            <span class="username">
                              <a href="#"
                                >{{ getMyInfoStudent.student_last_name }}
                                {{ getMyInfoStudent.student_name }}</a
                              >
                            </span>
                            <span class="description"
                              >Системд бүртгэсэн -
                              {{
                                getMyInfoStudent.user_created_at
                                  | dateYearMonthDay
                              }}</span
                            >
                            <table class="table table-borderless">
                              <thead>
                                <tr>
                                  <th class="col-sm-3"></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-right text-bold">Регистр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_reg }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Ургийн овог:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_family_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Эцэг/Эх-ийн нэр:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_last_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Нэр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Хүйс:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_gender }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Төрсөн өдөр:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_birthDate }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Иргэншил:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_citizenship }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Төрсний гэрчилгээний дугаар:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_birth_ger_num }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Эх хэл:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_ekh_khel }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Өнчин эсэх:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_unchin_esekh }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Асран хамгаалагч:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_asran_kham }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Асран хамгаалагчын мэдээлэл:
                                  </td>
                                  <td class="text-left">
                                    {{
                                      getMyInfoStudent.student_asran_kham_med
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Цусны бүлэг:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_tsus_bul }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- /.user-block -->
                        </div>
                        <!-- /.post -->
                      </div>
                      <!-- /.tab-pane -->
                      <div class="tab-pane" id="edit">
                        <form>
                          <div class="form-group row">
                            <div class="col-sm-4 form-group">
                              <label>Регистр</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_reg"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_reg'),
                                  },
                                ]"
                                v-model="formEdit.student_reg"
                                placeholder="Регистр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Ургийн овог</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_family_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_family_name'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_family_name"
                                placeholder="Ургийн овог"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Овог</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_last_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_last_name'),
                                  },
                                ]"
                                v-model="formEdit.student_last_name"
                                placeholder="Овог"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Нэр</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_name'),
                                  },
                                ]"
                                v-model="formEdit.student_name"
                                placeholder="Нэр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Нас</label>
                              <input
                                type="number"
                                class="form-control"
                                name="student_age"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_age'),
                                  },
                                ]"
                                v-model="formEdit.student_age"
                                placeholder="Нас"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Хүйс</label>
                              <b-form-select
                                v-model="formEdit.student_gender"
                                :options="genders"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_gender'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Төрсөн он сар өдөр</label>
                              <input
                                type="date"
                                class="form-control"
                                name="student_birthDate"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_birthDate'),
                                  },
                                ]"
                                v-model="formEdit.student_birthDate"
                                placeholder="Төрсөн он сар өдөр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Иргэншил</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_citizenship"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_citizenship'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_citizenship"
                                placeholder="Иргэншил"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Төрсний гэрчилгээний дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_birth_ger_num"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_birth_ger_num'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_birth_ger_num"
                                placeholder="Төрсний гэрчилгээний дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Эх хэл</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_ekh_khel"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_ekh_khel'),
                                  },
                                ]"
                                v-model="formEdit.student_ekh_khel"
                                placeholder="Эх хэл"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Цусны бүлэг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_tsus_bul"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_tsus_bul'),
                                  },
                                ]"
                                v-model="formEdit.student_tsus_bul"
                                placeholder="Цусны бүлэг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Асран хамгаалагч</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_asran_kham"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_asran_kham'),
                                  },
                                ]"
                                v-model="formEdit.student_asran_kham"
                                placeholder="Асран хамгаалагч"
                              />
                            </div>
                            <div class="col-sm-8 form-group">
                              <label>Асран хамгаалагчийн мэдээлэл</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_asran_kham_med"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_asran_kham_med'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_asran_kham_med"
                                placeholder="Асран хамгаалагчийн мэдээлэл"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Өнчин эсэх</label>
                              <b-form-select
                                v-model="formEdit.student_unchin_esekh"
                                :options="opts"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_unchin_esekh'
                                    ),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Аймаг, нийслэл</label>
                              <b-form-select
                                v-model="formEdit.student_duureg"
                                value-field="id"
                                text-field="name"
                                v-on:change="getSum"
                                :options="aimagOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_duureg'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Сум, дүүрэг</label>
                              <b-form-select
                                v-model="formEdit.student_khoroo"
                                value-field="id"
                                text-field="name"
                                v-on:change="getKhoroo"
                                :options="sumOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_khoroo'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Баг, хороо</label>
                              <b-form-select
                                v-model="formEdit.student_khoroolol"
                                value-field="code"
                                text-field="name"
                                :options="khorooOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_khoroolol'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Хотхон</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_bair"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_bair'),
                                  },
                                ]"
                                v-model="formEdit.student_bair"
                                placeholder="Хотхон"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Байрны дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_dugaar"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_dugaar'),
                                  },
                                ]"
                                v-model="formEdit.student_dugaar"
                                placeholder="Байрны дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Тоот</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_toot"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_toot'),
                                  },
                                ]"
                                v-model="formEdit.student_toot"
                                placeholder="Тоот"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Амдардаг хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_live_address"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_live_address'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_live_address"
                                placeholder="Амдардаг хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Утасны дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_phone_number"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_phone_number'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_phone_number"
                                placeholder="Утасны дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Имэйл хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_email"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_email'),
                                  },
                                ]"
                                v-model="formEdit.student_email"
                                placeholder="Имэйл хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Social хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_social_acc"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_social_acc'),
                                  },
                                ]"
                                v-model="formEdit.student_social_acc"
                                placeholder="Social хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Веб хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_web_site"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_web_site'),
                                  },
                                ]"
                                v-model="formEdit.student_web_site"
                                placeholder="Веб хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Зэрэг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_bol_zer"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_bol_zer'),
                                  },
                                ]"
                                v-model="formEdit.student_bol_zer"
                                placeholder="Зэрэг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Шагнал</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_shagnal"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('student_shagnal'),
                                  },
                                ]"
                                v-model="formEdit.student_shagnal"
                                placeholder="Шагнал"
                              />
                            </div>
                            <div class="col-sm-8 form-group">
                              <label>Шилжсэн шалтгаан</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_shiljilt_shaltgaan"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_shiljilt_shaltgaan'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_shiljilt_shaltgaan"
                                placeholder="Шилжсэн шалтгаан"
                              />
                            </div>
                            <div class="col-sm-12 form-group">
                              <label>Вакцины мэдээлэл</label>
                            </div>
                            <div class="row">
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_1"
                                  v-model="formEdit.student_vac_1"
                                  name="checkbox-student_vac_1"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 1
                                </b-form-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_2"
                                  v-model="formEdit.student_vac_2"
                                  name="checkbox-student_vac_2"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 2
                                </b-form-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_3"
                                  v-model="formEdit.student_vac_3"
                                  name="checkbox-student_vac_3"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 3
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-sm-6">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                @click.prevent="updateStudent()"
                              >
                                Хадгалах
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <!-- /.tab-content -->
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpdate()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import searchStudent from "./StudentSearch/studentFinishSearch.vue";
import createParent from "./Parent/createParent.vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      getMyInfoStudent: [],
      getOtherInfo: [],
      errorPassword: "",
      student_pass: "",
      student_pass_verify: "",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selected: null,
      year_id: null,
      action: "",
      createMode: false,
      forParentChild: "",
      parentDataProps: null,
      editMode: false,
      deleteMode: false,
      schoolstudents: [],
      school_classes: [],
      leave_types: [],
      schoolYears: [],
      schoolLogo: "",
      form: new Form({
        id: "",
        student_name: "",
        email: "",
        student_family_name: "",
        student_last_name: "",
        school_class_id: "",
        user_id: "",
        schoolYearId: "",
      }),
      formLeave: new Form({
        id: "",
        profile_id: "",
        user_id: "",
        leave_type_list: [],
        student_shiljilt_shaltgaan: "",
      }),
      role: "",
      formEdit: new Form({
        user_id: "",
        student_family_name: "",
        student_last_name: "",
        student_name: "",
        student_gender: "",
        student_birthDate: "",
        student_age: "",
        student_citizenship: "",
        student_reg: "",
        student_birth_ger_num: "",
        student_ekh_khel: "",
        student_unchin_esekh: "",
        student_asran_kham: "",
        student_asran_kham_med: "",
        student_tsus_bul: "",
        profile_id: "",
        school_class_id: "",
        student_age: "",
        student_duureg: 0,
        student_khoroo: 0,
        student_khoroolol: 0,
        student_bair: "",
        student_dugaar: "",
        student_toot: "",
        student_live_address: "",
        student_phone_number: "",
        student_email: "",
        student_social_acc: "",
        student_web_site: "",
        student_bol_zer: "",
        student_shagnal: "",
        student_vac_1: 0,
        student_vac_2: 0,
        student_vac_3: 0,
        student_shiljilt_shaltgaan: "",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      opts: [
        { value: null, text: "Сонгох" },
        { value: "Тийм", text: "Тийм" },
        { value: "Үгүй", text: "Үгүй" },
      ],
      aimagOptions: [],
      sumOptions: [],
      khorooOptions: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldList: [],
      fieldName: "",
      firstMode: true,
      isAll: false,
    };
  },
  components: {
    searchStudent: searchStudent,
    createParent,
    Multiselect,
  },
  computed: {},

  methods: {
    setFieldList: function (e) {
      if (this.firstMode == true) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = "";
      this.fieldList.map(function (value, key) {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
    getAllStudents() {
      axios
        .get(
          "/manager/getAllFinishedStudents/" +
            this.selected +
            "/" +
            this.year_id
        )
        .then((response) => {
          this.schoolstudents = response.data.schoolstudents;
          this.school_classes = response.data.school_classes;
          // this.selected = this.school_classes[0]["id"];
          this.schoolYears = response.data.schoolYears;
          this.leave_types = response.data.school_leave_types;
          this.role = response.data.role;
          this.json_data = response.data.schoolstudents;
          this.fieldList = response.data.fields;
          var jsonF = {};
          this.fieldList.map(function (value, key) {
            var obj = {};
            var myLabel = value.label;
            obj = { [myLabel]: value.value };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolteacher);
    },
    editSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.deleteMode = false;
      this.createMode = true;
      this.parentDataProps = null;
      this.form.reset();
      this.form.fill(schoolteacher);
      this.form.school_class_id = schoolteacher.school_class_id;
      this.form.schoolYearId = schoolteacher.year_id;
    },
    updateSchoolStudent() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateStudent/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.parentDataProps = null;
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolstudent) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolstudent);
      this.deleteMode = true;
      this.editMode = false;
      this.formLeave.reset();
      this.formLeave.fill(schoolstudent);
      this.formLeave.leave_type_list = [];
    },
    leaveStudent() {
      this.formLeave
        .post("/manager/leaveStudent/")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.formLeave.reset();
          this.deleteMode = !this.deleteMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelDelete() {
      this.formLeave.reset();
      this.deleteMode = !this.deleteMode;
    },
    inActiveSchoolStudent(schoolStudent) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах", schoolStudent);
      Swal.fire({
        title:
          "Суралцагчыг тухайн хичээлийн жилд идэвхигүй төлөвт шилжүүлэхдээ итгэлтэй байна уу?",
        text: "Хичээлийн жилийг маш сайн нягтална уу?",
        showDenyButton: true,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Идэвхигүй төлөвт шилжүүлэх`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .post("/manager/inActiveStudentYear", {
              schoolStudent: schoolStudent,
            })
            .then((response) => {
              //   console.log(response);
              Fire.$emit("loadSchoolStudents");
              Swal.fire("Амжилттай!", "", "success");
            })
            .catch((error) => {
              //   console.log(error);
            });
          /* Read more about isConfirmed, isDenied below */
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    createSchoolStudent() {
      this.action = "Суралцагчийг үүсгэж байна...";
      this.form
        .post("/manager/createStudent")
        .then((response) => {
          this.$toastr.s("Суралцагчийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolStudents");
          this.parentDataProps = null;
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEdit() {
      this.form.reset();
      this.parentDataProps = null;
      this.editMode = !this.editMode;
    },
    createParent(studentInfo) {
      this.createMode = !this.createMode;
      this.forParentChild = studentInfo;
      //   console.log(studentInfo.profile_parent_id);
      if (studentInfo.profile_parent_id != null) {
        axios
          .get("/manager/getCurrentParent/" + studentInfo.profile_parent_id)
          .then((response) => {
            this.parentDataProps = response.data.parentDataProps;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      } else this.parentDataProps = null;
      this.$bvModal.show("my-modal");
      //   this.$refs["my-modal"].show();
    },
    getEditMode(payload) {
      //   console.log("Hi.........", payload);
    },

    checkStudents(event) {
      //   console.log(event);
      this.schoolstudents = event;
    },
    // getBase64ImageFromURL(url) {
    //   return new Promise((resolve, reject) => {
    //     var img = new Image();
    //     img.setAttribute("crossOrigin", "anonymous");

    //     img.onload = () => {
    //       var canvas = document.createElement("canvas");
    //       canvas.width = img.width;
    //       canvas.height = img.height;

    //       var ctx = canvas.getContext("2d");
    //       ctx.drawImage(img, 0, 0);

    //       var dataURL = canvas.toDataURL("image/png");

    //       resolve(dataURL);
    //     };

    //     img.onerror = (error) => {
    //       reject(error);
    //     };

    //     img.src = url;
    //   });
    // },
    surdagUnen(student) {
      //   console.log(student);
      axios
        .get("/manager/schoolLogo")
        .then((response) => {
          //   console.log(response.data.schoolLogo);
          this.schoolLogo = response.data.schoolLogo;
          var dd = {
            pageSize: "A5",
            content: [
              {
                image: "building",
                width: 80,
                style: ["header", "anotherStyle"],
              },
              {
                text: "\n\n\n\n",
              },
              {
                text: "Суралцагчийн тодорхойлолт\n\n\n\n",
                style: ["anotherStyle"],
              },
              {
                text: [
                  {
                    text: ".............",
                    fontSize: 12,
                  }, //Овог 0
                  " овогтой ", // 1
                  { text: "a better ", fontSize: 12, style: ["anotherStyle"] }, //нэр 2
                  " нь                   \n ", //3
                  { text: "styled ", fontSize: 12, style: ["anotherStyle"] }, //эхлэх огноо 4
                  { text: "-", style: ["anotherStyle"] }, // 5
                  {
                    text: "independently ",
                    // italics: true,
                    fontSize: 12,
                    style: ["anotherStyle"],
                  }, //төгсөх огноо 6
                  {
                    text: " оны хичээлийн жилд \n Сэлбэ сургуулийн    ", //7
                    style: ["anotherStyle"],
                  },

                  {
                    text: "....", //8
                  },
                  {
                    text: "   ангид суралцдаг нь үнэн болно.\n\n\n\n\n\n", //[9]
                    style: ["anotherStyle"],
                  },
                ],
                style: ["anotherStyle"],
              },

              {
                text: "Сургалт хариуцсан захирал...............................Э.Золбоо\n\n", //[10]
                style: ["anotherStyle"],
              },
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
              },
              subheader: {
                fontSize: 15,
                bold: true,
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
              anotherStyle: {
                // italics: true,
                alignment: "center",
                lineHeight: 1.5,
              },
            },
            images: {
              building: "",
            },
          };
          dd.content[3].text[0].text = student.student_last_name;
          dd.content[3].text[2].text = student.student_name;
          dd.content[3].text[4].text =
            this.schoolLogo[0].start_date[0] +
            this.schoolLogo[0].start_date[1] +
            this.schoolLogo[0].start_date[2] +
            this.schoolLogo[0].start_date[3];
          dd.content[3].text[6].text =
            this.schoolLogo[0].finish_date[0] +
            this.schoolLogo[0].finish_date[1] +
            this.schoolLogo[0].finish_date[2] +
            this.schoolLogo[0].finish_date[3];
          dd.content[3].text[8].text = student.full_name;
          dd.images.building = this.schoolLogo[0].school_logo;
          //   console.log(dd.images.building);
          let downName =
            student.student_last_name[0] +
            student.student_last_name[1] +
            "." +
            student.student_name +
            ".pdf";
          pdfMake.createPdf(dd).download(downName);
          //   pdfMake.createPdf(dd).open();
          //   pdfMake.createPdf(dd).open();
          //   console.log(downName);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // Тооцоолол
    //A
    countAMale() {
      let countMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эрэгтэй"
      ).length;
      return countMale;
    },
    countAFeMale() {
      let countFeMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эмэгтэй"
      ).length;
      return countFeMale;
    },
    countA() {
      let countA = this.countAMale() + this.countAFeMale();
      return countA;
    },
    //Суралцагчын мэдээлэл засах
    editStudentInfo(studentInfo) {
      axios
        .get("/allCity")
        .then((response) => {
          this.aimagOptions = response.data.allCity;
          this.getMyInfoStudent = studentInfo;
          console.log(studentInfo);
          this.formEdit.reset();
          this.formEdit.fill(studentInfo);
          this.$bvModal.show("modal-student-info");
          if (this.formEdit.student_duureg != 0) {
            this.getSum();
          } else this.formEdit.student_duureg = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSum() {
      axios
        .get("/allDistrict/" + this.formEdit.student_duureg)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
          this.khorooOptions = [];
          if (this.formEdit.student_khoroo != 0) {
            this.getKhoroo();
          } else this.formEdit.student_khoroo = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getKhoroo() {
      axios
        .post("/allKhoroo", {
          cityId: this.formEdit.student_duureg,
          districtId: this.formEdit.student_khoroo,
        })
        .then((response) => {
          this.khorooOptions = response.data.allKhoroo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    updateStudent() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.formEdit
        .put(
          "/teacher/updateMyClassStudent/" + this.getMyInfoStudent.profile_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(
            "Сурагчийн мэдээллийг амжилттай заслаа!",
            "Шинэчилсэн"
          );
          this.formEdit.reset();
          this.$bvModal.hide("modal-student-info");
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelUpdate() {
      this.formEdit.reset();
      this.$bvModal.hide("modal-student-info");
    },

    //Зарим датаг түрүүлж авах
    getSomeData() {
      axios
        .get("/manager/getSomeDatas")
        .then((response) => {
          this.school_classes = response.data.school_classes;
          this.selected = this.school_classes[0]["id"];
          this.schoolYears = response.data.schoolYears;
          this.year_id = this.schoolYears[0]["id"];
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
  },
  created() {
    this.getSomeData();
    // this.getAllStudents();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllStudents();
    });
  },
  // updated() {},
};
</script>

<style></style>
