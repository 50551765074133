<template>
    <div class="row pt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header p-2 float-center">
                    <ul class="nav nav-pills">
                        <!-- <li class="nav-item">
              <a class="nav-link active" href="#primaryschool" data-toggle="tab"
                >Бага</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#middleschool" data-toggle="tab"
                >Дунд</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#highschool" data-toggle="tab">Ахлах</a>
            </li> -->
                        <li class="nav-item">
                            <a class="nav-link" href="#school" data-toggle="tab"
                                >Сургууль</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <!-- <div class="active tab-pane" id="primaryschool">Бага</div>
            <div class="tab-pane" id="middleschool">Дунд</div>
            <div class="tab-pane" id="highschool">Ахлах</div> -->
                        <div class="active tab-pane" id="school">
                            <!-- Жилээр харуулах -->
                            <multiselect
                                v-model="school_year_id"
                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                track-by="id"
                                label="id"
                                :custom-label="customYearName"
                                placeholder="Хичээлийн жил сонгоно уу?"
                                :options="years"
                                :searchable="false"
                                :allow-empty="false"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                    ><strong
                                        >{{ option.start_date | dateYear }}-{{
                                            option.finish_date | dateYear
                                        }}
                                        - хичээлийн жил
                                    </strong></template
                                >
                            </multiselect>

                            <div class="row" v-if="viewMySchoolChart">
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box">
                                        <span
                                            class="info-box-icon bg-info elevation-1"
                                            ><i class="fas fa-cog"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Нийт үнэлгээ</span
                                            >
                                            <span class="info-box-number">
                                                {{
                                                    getToFixed0(
                                                        teacherLesEvalCount[0]
                                                            .teach_year_id
                                                    )
                                                }}
                                                <small></small>
                                            </span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-danger elevation-1"
                                            ><i class="fas fa-thumbs-up"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Үнэлгээний дундаж</span
                                            >
                                            <span class="info-box-number">{{
                                                getToFixed(
                                                    teacherLesEvalCount[0]
                                                        .characteristicAvgPer
                                                )
                                            }}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>

                                <div class="clearfix hidden-md-up"></div>

                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-success elevation-1"
                                            ><i class="fas fa-shopping-cart"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Мэргэжлийн баг</span
                                            >
                                            <span class="info-box-number">{{
                                                teacherLesEvalDepartmentsCount.length
                                            }}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-warning elevation-1"
                                            ><i class="fas fa-users"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Багш</span
                                            >
                                            <span class="info-box-number">
                                                {{
                                                    teacherLesEvalTeacherCount.length
                                                }}
                                            </span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <!-- Дараагийн мөр -->
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box">
                                        <span
                                            class="info-box-icon bg-info elevation-1"
                                            ><i class="fas fa-cog"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Хуваарьт цагийн ажиглалт</span
                                            >
                                            <span class="info-box-number">
                                                {{
                                                    getToFixed0(
                                                        teacherLesEvalLessonTimeCount.length
                                                    )
                                                }}
                                                <small></small>
                                            </span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-danger elevation-1"
                                            ><i class="fas fa-thumbs-up"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Нийт анги</span
                                            >
                                            <span class="info-box-number">{{
                                                getToFixed(
                                                    teacherLesEvalSchoolClassCount.length
                                                )
                                            }}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>

                                <div class="clearfix hidden-md-up"></div>

                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-success elevation-1"
                                            ><i class="fas fa-shopping-cart"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Ажиглалт хийсэн багш</span
                                            >
                                            <span class="info-box-number">{{
                                                teacherLesEvalRegWorkersCount.length
                                            }}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="info-box mb-3">
                                        <span
                                            class="info-box-icon bg-warning elevation-1"
                                            ><i class="fas fa-users"></i
                                        ></span>

                                        <div class="info-box-content">
                                            <span class="info-box-text"
                                                >Нийт хичээл</span
                                            >
                                            <span class="info-box-number">
                                                {{
                                                    teacherLesEvalSubjectsCount.length
                                                }}
                                            </span>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>

                                <!-- Дараагийн мөр сургуулийн дундаж -->
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Сургалтын ээлжит үйл
                                                        ажиллагаа(Жилээр)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="bar"
                                                            height="350"
                                                            :options="
                                                                optionsYear
                                                            "
                                                            :series="seriesYear"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Дараагийн мөр сургуулийн мэргэжлийн багийн дундаж -->
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Сургалтын ээлжит үйл
                                                        ажиллагаа мэргэжлийн
                                                        багаар(Жилээр)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="bar"
                                                            height="350"
                                                            :options="
                                                                optionsDepYear
                                                            "
                                                            :series="
                                                                seriesDepYear
                                                            "
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Бага
                                                        сургууль(Жилээр)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsPrimary
                                                            "
                                                            :series="
                                                                seriesPrimary
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsPri
                                                            "
                                                            :series="seriesPri"
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Дунд
                                                        сургууль(Жилээр)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsMiddle
                                                            "
                                                            :series="
                                                                seriesMiddle
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsMidd
                                                            "
                                                            :series="seriesMidd"
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Ахлах
                                                        сургууль(Жилээр)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsHigher
                                                            "
                                                            :series="
                                                                seriesHigher
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsHigh
                                                            "
                                                            :series="seriesHigh"
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

                </div> -->
                            </div>
                            <!-- Улирлаар харуулах -->
                            <multiselect
                                v-if="viewMySchoolChart"
                                v-model="school_quarter_id"
                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                track-by="id"
                                label="id"
                                :custom-label="customQuarterName"
                                placeholder="Улирал сонгоно уу?"
                                :options="quarters"
                                :searchable="false"
                                :allow-empty="false"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                    ><strong
                                        >{{ option.name }}
                                        - р улирал
                                    </strong></template
                                >
                            </multiselect>

                            <div class="row">
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div
                                            class="col-12 col-sm-4"
                                            v-if="viewMySchoolChartQuarter1"
                                        >
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Бага
                                                        сургууль(Улирлаар)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsQuarterPrimary
                                                            "
                                                            :series="
                                                                seriesQuarterPrimary
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsQuarterPri
                                                            "
                                                            :series="
                                                                seriesQuarterPri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-12 col-sm-4"
                                            v-if="viewMySchoolChartQuarter2"
                                        >
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Дунд
                                                        сургууль(Улирлаар)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsQuarterMiddle
                                                            "
                                                            :series="
                                                                seriesQuarterMiddle
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsQuarterMidd
                                                            "
                                                            :series="
                                                                seriesQuarterMidd
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-12 col-sm-4"
                                            v-if="viewMySchoolChartQuarter3"
                                        >
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                        >Ахлах
                                                        сургууль(Улирлаар)</span
                                                    >
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsQuarterHigher
                                                            "
                                                            :series="
                                                                seriesQuarterHigher
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsQuarterHigh
                                                            "
                                                            :series="
                                                                seriesQuarterHigh
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

                </div> -->
                            </div>

                            <!-- Мэргэжлийн багаар харуулах -->

                            <div class="row">
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="viewMySchoolChartQuarter1"
                                                v-model="school_department1_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="id"
                                                label="id"
                                                :custom-label="
                                                    customDepartmentName
                                                "
                                                placeholder="Мэргэжлийн баг сонгоно уу?"
                                                :options="departments"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.department_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDepartment1
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsDepartment1Primary
                                                            "
                                                            :series="
                                                                seriesDepartment1Primary
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepartment1Pri
                                                            "
                                                            :series="
                                                                seriesDepartment1Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="viewMySchoolChartQuarter2"
                                                v-model="school_department2_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="id"
                                                label="id"
                                                :custom-label="
                                                    customDepartmentName
                                                "
                                                placeholder="Мэргэжлийн баг сонгоно уу?"
                                                :options="departments"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.department_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDepartment2
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsDepartment2Middle
                                                            "
                                                            :series="
                                                                seriesDepartment2Middle
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepartment2Pri
                                                            "
                                                            :series="
                                                                seriesDepartment2Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="viewMySchoolChartQuarter3"
                                                v-model="school_department3_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="id"
                                                label="id"
                                                :custom-label="
                                                    customDepartmentName
                                                "
                                                placeholder="Мэргэжлийн баг сонгоно уу?"
                                                :options="departments"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.department_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDepartment3
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            :options="
                                                                optionsDepartment3Higher
                                                            "
                                                            :series="
                                                                seriesDepartment3Higher
                                                            "
                                                        ></apexchart>
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepartment3Pri
                                                            "
                                                            :series="
                                                                seriesDepartment3Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Багшаар харуулах -->
                            <div class="row">
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDepartment1
                                                "
                                                v-model="school_dep_teach1_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="teacher_id"
                                                label="teacher_id"
                                                :custom-label="
                                                    customTeacherDep1
                                                "
                                                placeholder="Багш сонгоно уу?"
                                                :options="teachersDep1"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.teacher_last_name
                                                        }}.
                                                        {{
                                                            option.teacher_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach1
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeach1Pri
                                                            "
                                                            :series="
                                                                seriesDepTeach1Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDepartment2
                                                "
                                                v-model="school_dep_teach2_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="teacher_id"
                                                label="teacher_id"
                                                :custom-label="
                                                    customTeacherDep1
                                                "
                                                placeholder="Багш сонгоно уу?"
                                                :options="teachersDep2"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.teacher_last_name
                                                        }}.
                                                        {{
                                                            option.teacher_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach2
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeach2Pri
                                                            "
                                                            :series="
                                                                seriesDepTeach2Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDepartment3
                                                "
                                                v-model="school_dep_teach3_id"
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="teacher_id"
                                                label="teacher_id"
                                                :custom-label="
                                                    customTeacherDep1
                                                "
                                                placeholder="Багш сонгоно уу?"
                                                :options="teachersDep3"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >{{
                                                            option.teacher_last_name
                                                        }}.
                                                        {{
                                                            option.teacher_name
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach3
                                                "
                                            >
                                                <div class="info-box-content">
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeach3Pri
                                                            "
                                                            :series="
                                                                seriesDepTeach3Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Ажиглалт шинжилгээ хийсэн багшийн хичээлүүд харуулах -->
                            <div class="row">
                                <div
                                    class="col-12 col-md-12 col-lg-12 order-2 order-md-1"
                                >
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDep_teach1
                                                "
                                                v-model="
                                                    school_dep_teach_les_eval1_id
                                                "
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="twe_id"
                                                label="twe_id"
                                                :custom-label="
                                                    customTeacherLesson
                                                "
                                                placeholder="Ажиглалт хийсэн багшийн хичээл сонгоно уу?"
                                                :options="teachersLessonEval1"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >Хичээл:
                                                        {{
                                                            option.subject_short_name
                                                        }}, Огноо:
                                                        {{
                                                            option.twe_lesson_ognoo
                                                        }}
                                                        Цаг:
                                                        {{
                                                            option.twe_lesson_time
                                                        }}
                                                        Сэдэв:
                                                        {{
                                                            option.twe_lesson_topic
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach_l_e_1
                                                "
                                            >
                                                <div
                                                    class="info-box-content"
                                                    style="overflow-x: auto"
                                                >
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeachLesEval1Pri
                                                            "
                                                            :series="
                                                                seriesDepTeachLesEval1Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                    <table class="table table-hover">
                                                        <thead
                                                            class="thead-dark"
                                                            style="font-size: 80%"
                                                        >
                                                            <tr>
                                                                <th scope="col">
                                                                    #
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                ></th>
                                                                <th scope="col">
                                                                    Шалгуур
                                                                    үзүүлэлт
                                                                </th>
                                                                <th scope="col">
                                                                    Оноо
                                                                </th>
                                                                <th scope="col">
                                                                    Эерэг
                                                                </th>
                                                                <th scope="col">
                                                                    Сайжруулах
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            style="font-size: 60%"
                                                        >
                                                            <tr>
                                                                <th scope="row">
                                                                    1
                                                                </th>
                                                                <th rowspan="3">
                                                                    Эхлэл хэсэг
                                                                </th>
                                                                <td>
                                                                    Зорилготойгоо
                                                                    холбоотой
                                                                    эрэлт
                                                                    хэрэгцээ,
                                                                    сэдэл,
                                                                    сэтгэл
                                                                    хөдлөлийг
                                                                    хянах,
                                                                    чиглүүлэх
                                                                    байдлаар
                                                                    зорилгоо
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles1
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles1_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles1_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    2
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    томъёолсон
                                                                    байдал
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles2
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles2_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles2_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    3
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    ойлгосон
                                                                    эсэхийг
                                                                    хянах, үйл
                                                                    ажиллагааг
                                                                    нь
                                                                    төлөвлүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles3
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles3_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles3_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    4
                                                                </th>
                                                                <th rowspan="5">
                                                                    Өрнөл хэсэг
                                                                </th>
                                                                <td>
                                                                    Судлагдахууны
                                                                    агуулгыг
                                                                    элементарчилж,
                                                                    эмх цэгцтэй
                                                                    хүргэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles4
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles4_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles4_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    5
                                                                </th>
                                                                <td>
                                                                    Даалгаврын
                                                                    тавил
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй
                                                                    мэдээллийг
                                                                    хүлээн авах
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles5
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles5_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles5_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    6
                                                                </th>
                                                                <td>
                                                                    Үйл
                                                                    ажиллагааны
                                                                    хэлбэр
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй сурахуйн
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles6
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles6_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles6_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    7
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэл
                                                                    дээр урам
                                                                    дэм өгч,
                                                                    магтаал
                                                                    сайшаал
                                                                    хэлсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles7
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles7_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles7_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    8
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    оюуны үйлийн
                                                                    когнитив
                                                                    системийг
                                                                    бүрэн
                                                                    ажиллуулсан
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles8
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles8_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles8_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    9
                                                                </th>
                                                                <th rowspan="2">
                                                                    Төгсгөл
                                                                    хэсэг
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэлд
                                                                    хяналт тавьж
                                                                    гүйцэтгэлийг
                                                                    нэгтгэн
                                                                    дүгнэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles9
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles9_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles9_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    10
                                                                </th>
                                                                <td>
                                                                    Эзэмшсэн
                                                                    мэдлэг
                                                                    чадварынх нь
                                                                    ач
                                                                    холбогдол,
                                                                    үнэ цэнийг
                                                                    үнэлүүлж
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles10
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles10_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval1Data.teach_shalles10_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDep_teach2
                                                "
                                                v-model="
                                                    school_dep_teach_les_eval2_id
                                                "
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="twe_id"
                                                label="twe_id"
                                                :custom-label="
                                                    customTeacherLesson
                                                "
                                                placeholder="Ажиглалт хийсэн багшийн хичээл сонгоно уу?"
                                                :options="teachersLessonEval2"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >Хичээл:
                                                        {{
                                                            option.subject_short_name
                                                        }}, Огноо:
                                                        {{
                                                            option.twe_lesson_ognoo
                                                        }}
                                                        Цаг:
                                                        {{
                                                            option.twe_lesson_time
                                                        }}
                                                        Сэдэв:
                                                        {{
                                                            option.twe_lesson_topic
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach_l_e_2
                                                "
                                            >
                                                <div
                                                    class="info-box-content"
                                                    style="overflow-x: auto"
                                                >
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeachLesEval2Pri
                                                            "
                                                            :series="
                                                                seriesDepTeachLesEval2Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                    <table class="table table-hover">
                                                        <thead
                                                            class="thead-dark"
                                                            style="font-size: 80%"
                                                        >
                                                            <tr>
                                                                <th scope="col">
                                                                    #
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                ></th>
                                                                <th scope="col">
                                                                    Шалгуур
                                                                    үзүүлэлт
                                                                </th>
                                                                <th scope="col">
                                                                    Оноо
                                                                </th>
                                                                <th scope="col">
                                                                    Эерэг
                                                                </th>
                                                                <th scope="col">
                                                                    Сайжруулах
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            style="font-size: 60%"
                                                        >
                                                            <tr>
                                                                <th scope="row">
                                                                    1
                                                                </th>
                                                                <th rowspan="3">
                                                                    Эхлэл хэсэг
                                                                </th>
                                                                <td>
                                                                    Зорилготойгоо
                                                                    холбоотой
                                                                    эрэлт
                                                                    хэрэгцээ,
                                                                    сэдэл,
                                                                    сэтгэл
                                                                    хөдлөлийг
                                                                    хянах,
                                                                    чиглүүлэх
                                                                    байдлаар
                                                                    зорилгоо
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles1
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles1_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles1_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    2
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    томъёолсон
                                                                    байдал
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles2
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles2_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles2_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    3
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    ойлгосон
                                                                    эсэхийг
                                                                    хянах, үйл
                                                                    ажиллагааг
                                                                    нь
                                                                    төлөвлүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles3
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles3_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles3_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    4
                                                                </th>
                                                                <th rowspan="5">
                                                                    Өрнөл хэсэг
                                                                </th>
                                                                <td>
                                                                    Судлагдахууны
                                                                    агуулгыг
                                                                    элементарчилж,
                                                                    эмх цэгцтэй
                                                                    хүргэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles4
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles4_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles4_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    5
                                                                </th>
                                                                <td>
                                                                    Даалгаврын
                                                                    тавил
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй
                                                                    мэдээллийг
                                                                    хүлээн авах
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles5
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles5_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles5_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    6
                                                                </th>
                                                                <td>
                                                                    Үйл
                                                                    ажиллагааны
                                                                    хэлбэр
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй сурахуйн
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles6
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles6_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles6_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    7
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэл
                                                                    дээр урам
                                                                    дэм өгч,
                                                                    магтаал
                                                                    сайшаал
                                                                    хэлсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles7
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles7_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles7_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    8
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    оюуны үйлийн
                                                                    когнитив
                                                                    системийг
                                                                    бүрэн
                                                                    ажиллуулсан
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles8
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles8_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles8_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    9
                                                                </th>
                                                                <th rowspan="2">
                                                                    Төгсгөл
                                                                    хэсэг
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэлд
                                                                    хяналт тавьж
                                                                    гүйцэтгэлийг
                                                                    нэгтгэн
                                                                    дүгнэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles9
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles9_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles9_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    10
                                                                </th>
                                                                <td>
                                                                    Эзэмшсэн
                                                                    мэдлэг
                                                                    чадварынх нь
                                                                    ач
                                                                    холбогдол,
                                                                    үнэ цэнийг
                                                                    үнэлүүлж
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles10
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles10_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval2Data.teach_shalles10_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <multiselect
                                                v-if="
                                                    viewMySchoolChartDep_teach3
                                                "
                                                v-model="
                                                    school_dep_teach_les_eval3_id
                                                "
                                                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                                track-by="twe_id"
                                                label="twe_id"
                                                :custom-label="
                                                    customTeacherLesson
                                                "
                                                placeholder="Ажиглалт хийсэн багшийн хичээл сонгоно уу?"
                                                :options="teachersLessonEval3"
                                                :searchable="false"
                                                :allow-empty="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                    ><strong
                                                        >Хичээл:
                                                        {{
                                                            option.subject_short_name
                                                        }}, Огноо:
                                                        {{
                                                            option.twe_lesson_ognoo
                                                        }}
                                                        Цаг:
                                                        {{
                                                            option.twe_lesson_time
                                                        }}
                                                        Сэдэв:
                                                        {{
                                                            option.twe_lesson_topic
                                                        }}
                                                    </strong></template
                                                >
                                            </multiselect>
                                            <div
                                                class="info-box bg-light"
                                                v-if="
                                                    viewMySchoolChartDep_teach_l_e_3
                                                "
                                            >
                                                <div
                                                    class="info-box-content"
                                                    style="overflow-x: auto"
                                                >
                                                    <span
                                                        class="info-box-text text-center text-muted"
                                                    ></span>
                                                    <span
                                                        class="info-box-number text-center text-muted mb-0"
                                                    >
                                                        <apexchart
                                                            type="polarArea"
                                                            :options="
                                                                chartOptionsDepTeachLesEval3Pri
                                                            "
                                                            :series="
                                                                seriesDepTeachLesEval3Pri
                                                            "
                                                            width="100%"
                                                        ></apexchart>
                                                    </span>
                                                    <table class="table table-hover">
                                                        <thead
                                                            class="thead-dark"
                                                            style="font-size: 80%"
                                                        >
                                                            <tr>
                                                                <th scope="col">
                                                                    #
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                ></th>
                                                                <th scope="col">
                                                                    Шалгуур
                                                                    үзүүлэлт
                                                                </th>
                                                                <th scope="col">
                                                                    Оноо
                                                                </th>
                                                                <th scope="col">
                                                                    Эерэг
                                                                </th>
                                                                <th scope="col">
                                                                    Сайжруулах
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            style="font-size: 60%"
                                                        >
                                                            <tr>
                                                                <th scope="row">
                                                                    1
                                                                </th>
                                                                <th rowspan="3">
                                                                    Эхлэл хэсэг
                                                                </th>
                                                                <td>
                                                                    Зорилготойгоо
                                                                    холбоотой
                                                                    эрэлт
                                                                    хэрэгцээ,
                                                                    сэдэл,
                                                                    сэтгэл
                                                                    хөдлөлийг
                                                                    хянах,
                                                                    чиглүүлэх
                                                                    байдлаар
                                                                    зорилгоо
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles1
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles1_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles1_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    2
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    томъёолсон
                                                                    байдал
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles2
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles2_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles2_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    3
                                                                </th>
                                                                <td>
                                                                    Зорилгоо
                                                                    ойлгосон
                                                                    эсэхийг
                                                                    хянах, үйл
                                                                    ажиллагааг
                                                                    нь
                                                                    төлөвлүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles3
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles3_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles3_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    4
                                                                </th>
                                                                <th rowspan="5">
                                                                    Өрнөл хэсэг
                                                                </th>
                                                                <td>
                                                                    Судлагдахууны
                                                                    агуулгыг
                                                                    элементарчилж,
                                                                    эмх цэгцтэй
                                                                    хүргэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles4
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles4_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles4_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    5
                                                                </th>
                                                                <td>
                                                                    Даалгаврын
                                                                    тавил
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй
                                                                    мэдээллийг
                                                                    хүлээн авах
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles5
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles5_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles5_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    6
                                                                </th>
                                                                <td>
                                                                    Үйл
                                                                    ажиллагааны
                                                                    хэлбэр
                                                                    суралцагчдад
                                                                    давамгайлж
                                                                    буй сурахуйн
                                                                    хэв шинжид
                                                                    нийцсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles6
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles6_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles6_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    7
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэл
                                                                    дээр урам
                                                                    дэм өгч,
                                                                    магтаал
                                                                    сайшаал
                                                                    хэлсэн эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles7
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles7_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles7_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    8
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    оюуны үйлийн
                                                                    когнитив
                                                                    системийг
                                                                    бүрэн
                                                                    ажиллуулсан
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles8
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles8_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles8_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    9
                                                                </th>
                                                                <th rowspan="2">
                                                                    Төгсгөл
                                                                    хэсэг
                                                                </th>
                                                                <td>
                                                                    Суралцагчдын
                                                                    гүйцэтгэлд
                                                                    хяналт тавьж
                                                                    гүйцэтгэлийг
                                                                    нэгтгэн
                                                                    дүгнэсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles9
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles9_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles9_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    10
                                                                </th>
                                                                <td>
                                                                    Эзэмшсэн
                                                                    мэдлэг
                                                                    чадварынх нь
                                                                    ач
                                                                    холбогдол,
                                                                    үнэ цэнийг
                                                                    үнэлүүлж
                                                                    хүлээн
                                                                    зөвшөөрүүлсэн
                                                                    эсэх
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles10
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles10_pos_note
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        teachersLessonEval3Data.teach_shalles10_neg_note
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            years: [],
            quarters: [],
            departments: [],
            schoolCLasses: [],
            teachers: [],

            //Сургуулийн Эхлэл
            school_year_id: "",
            viewMySchoolChart: false,

            // Бага
            optionsPrimary: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Дүн"]
            },
            seriesPrimary: [0],
            seriesPri: [],
            chartOptionsPri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },
            // Дунд
            optionsMiddle: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Дүн"]
            },
            seriesMiddle: [0],
            seriesMidd: [],
            chartOptionsMidd: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },
            // Ахлах
            optionsHigher: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Дүн"]
            },
            seriesHigher: [0],
            seriesHigh: [],
            chartOptionsHigh: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },

            //Улирал эхлэл
            school_quarter_id: "",

            viewMySchoolChartQuarter1: false,
            // Бага
            optionsQuarterPrimary: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesQuarterPrimary: [0],

            seriesQuarterPri: [],
            chartOptionsQuarterPri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },
            // Дунд
            viewMySchoolChartQuarter2: false,
            optionsQuarterMiddle: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesQuarterMiddle: [0],

            seriesQuarterMidd: [],
            chartOptionsQuarterMidd: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },

            // viewMySchoolChartQuarter2: false,
            // Ахлах
            viewMySchoolChartQuarter3: false,
            optionsQuarterHigher: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesQuarterHigher: [0],
            seriesQuarterHigh: [],
            chartOptionsQuarterHigh: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },
            //Мэргэжлийн баг эхлэл
            school_department1_id: "",
            school_department2_id: "",
            school_department3_id: "",

            viewMySchoolChartDepartment1: false,
            viewMySchoolChartDepartment2: false,
            viewMySchoolChartDepartment3: false,
            // Мэргэжлийн баг 1
            viewMySchoolChartQuarter3: false,
            optionsDepartment1Primary: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesDepartment1Primary: [0],

            seriesDepartment1Pri: [],

            chartOptionsDepartment1Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },
            // Мэргэжлийн баг 2
            optionsDepartment2Middle: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesDepartment2Middle: [0],

            seriesDepartment2Pri: [],
            chartOptionsDepartment2Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },

            // Мэргэжлийн баг 3
            optionsDepartment3Higher: {
                chart: {
                    width: "100%",
                    type: "radialBar"
                },
                colors: ["#3d85c6", "#66DA26"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            //   total: {
                            //     show: true,
                            //     label: "Нийт",
                            //   },
                            name: {
                                // offsetY: -10,
                                color: "#fff",
                                fontSize: "13px",
                                show: true
                            },
                            value: {
                                color: "#fff",
                                fontSize: "30px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#6a329f", "#FF9800"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Ээлжит хичээлийн үнэлгээ"]
            },
            seriesDepartment3Higher: [0],
            seriesDepartment3Pri: [],
            chartOptionsDepartment3Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function(value, _ref) {
                        var seriesIndex = _ref.seriesIndex,
                            dataPointIndex = _ref.dataPointIndex,
                            w = _ref.w;
                        // console.log(value);
                        // console.log(seriesIndex);
                        // console.log(_ref);
                        return w.config.series[seriesIndex];
                    }
                },

                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    // max: 100,
                    labels: {
                        formatter: function(val) {
                            return val + "%";
                        }
                    }
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            },

            //Мэргэжлийн багаас хамаарсан багш нарын нэрс
            teachersDep1: [],
            teachersDep2: [],
            teachersDep3: [],
            school_dep_teach1_id: "",
            school_dep_teach2_id: "",
            school_dep_teach3_id: "",

            viewMySchoolChartDep_teach1: false,
            viewMySchoolChartDep_teach2: false,
            viewMySchoolChartDep_teach3: false,
            seriesDepTeach1Pri: [],
            chartOptionsDepTeach1Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },
            seriesDepTeach2Pri: [],
            chartOptionsDepTeach2Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },
            seriesDepTeach3Pri: [],
            chartOptionsDepTeach3Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },

            //Тухайн багшийн хичээлд суусан ажиглалтын жагсаалт
            teachersLessonEval1: [],
            teachersLessonEval2: [],
            teachersLessonEval3: [],
            //   Бүх өгөгдлийг хадгалж буй сав
            teachersLessonEval1Data: [],
            teachersLessonEval2Data: [],
            teachersLessonEval3Data: [],
            school_dep_teach_les_eval1_id: "",
            school_dep_teach_les_eval2_id: "",
            school_dep_teach_les_eval3_id: "",
            viewMySchoolChartDep_teach_l_e_1: false,
            viewMySchoolChartDep_teach_l_e_2: false,
            viewMySchoolChartDep_teach_l_e_3: false,
            seriesDepTeachLesEval1Pri: [],
            chartOptionsDepTeachLesEval1Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },
            seriesDepTeachLesEval2Pri: [],
            chartOptionsDepTeachLesEval2Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },
            seriesDepTeachLesEval3Pri: [],
            chartOptionsDepTeachLesEval3Pri: {
                chart: {
                    type: "polarArea"
                },
                labels: [
                    "Шал-1",
                    "Шал-2",
                    "Шал-3",
                    "Шал-4",
                    "Шал-5",
                    "Шал-6",
                    "Шал-7",
                    "Шал-8",
                    "Шал-9",
                    "Шал-10"
                ],
                stroke: {
                    width: 1,
                    colors: ["#fff"]
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 100
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [
                    {
                        breakpoint: 40,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                title: {
                    text: undefined,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238"
                    }
                }
            },

            //Ерөнхий статистик
            teacherLesEvalCount: [], //Сургуулийн хэмжээнд авсан
            teacherLesEvalQuartersCount: [], //Улирлууд авсан
            teacherLesEvalQuarter1Count: [], //Улирал 1 дээрх утгаас авна
            teacherLesEvalQuarter2Count: [], //Улирал 2 дээрх утгаас авна
            teacherLesEvalQuarter3Count: [], //Улирал 3 дээрх утгаас авна
            teacherLesEvalDepartmentsCount: [], //Мэргэжлийн багаар
            teacherLesEvalSchoolClassCount: [], //Ангиар
            teacherLesEvalRegWorkersCount: [], //Үнэлгээ хийсэн ажилтнаар
            teacherLesEvalTeacherCount: [], //Үнэлэгдсэн багш нар
            teacherLesEvalSubjectsCount: [], //Хичээлээр
            teacherLesEvalLessonTimeCount: [], //Хичээлийн цагаар
            optionsYear: {
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                dataLabels: {
                    distributed: false
                }
            },
            seriesYear: [
                {
                    data: []
                }
            ],
            optionsDepYear: {
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                dataLabels: {
                    distributed: false
                }
            },
            seriesDepYear: [
                {
                    data: []
                }
            ]
        };
    },
    components: {
        Multiselect
    },
    watch: {
        school_year_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "school");
            this.seriesPri = [];
            this.seriesMidd = [];
            this.seriesHigh = [];
            this.seriesPrimary[0] = [];
            this.seriesMiddle[0] = [];
            this.seriesHigher[0] = [];
            this.viewMySchoolChart = false;
            this.school_quarter_id = "";

            axios
                .post(
                    "/curriculumadministrator/getAllSchoolYearTeachLessEvalData",
                    {
                        year: newVal
                    }
                )
                .then(res => {
                    //   console.log(res.data);

                    this.teacherLesEvalCount = res.data.teacherLesEvalCount;
                    this.seriesYear[0].data.push({
                        x: "Шал-1",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles1 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-2",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles2 / 2) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-3",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles3 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-4",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles4 / 4) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-5",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles5 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-6",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles6 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-7",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles7 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-8",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles8 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-9",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles9 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Шал-10",
                        y: this.getToFixed(
                            (this.teacherLesEvalCount[0].teach_shalles10 / 3) *
                                100
                        )
                    });
                    this.seriesYear[0].data.push({
                        x: "Дундаж",
                        y: this.getToFixed(
                            this.teacherLesEvalCount[0].characteristicAvgPer
                        )
                    });
                    this.teacherLesEvalQuartersCount =
                        res.data.teacherLesEvalQuartersCount; //Улирлууд авсан
                    this.teacherLesEvalQuarter1Count =
                        res.data.teacherLesEvalQuartersCount; //Улирал 1 дээрх утгаас авна
                    this.teacherLesEvalQuarter2Count =
                        res.data.teacherLesEvalQuartersCount; //Улирал 2 дээрх утгаас авна
                    this.teacherLesEvalQuarter3Count =
                        res.data.teacherLesEvalQuartersCount; //Улирал 3 дээрх утгаас авна
                    this.teacherLesEvalDepartmentsCount =
                        res.data.teacherLesEvalDepartmentsCount; //Мэргэжлийн багаар
                    this.teacherLesEvalDepartmentsCount.forEach(element => {
                        this.seriesDepYear[0].data.push({
                            x: element.department_code,
                            y: this.getToFixed(element.characteristicAvgPer)
                        });
                    });

                    this.teacherLesEvalSchoolClassCount =
                        res.data.teacherLesEvalSchoolClassCount; //Ангиар
                    this.teacherLesEvalRegWorkersCount =
                        res.data.teacherLesEvalRegWorkersCount; //Үнэлгээ хийсэн ажилтнаар
                    this.teacherLesEvalTeacherCount =
                        res.data.teacherLesEvalTeacherCount; //Үнэлэгдсэн багш нар
                    this.teacherLesEvalSubjectsCount =
                        res.data.teacherLesEvalSubjectsCount; //Хичээлээр
                    this.teacherLesEvalLessonTimeCount =
                        res.data.teacherLesEvalLessonTimeCount; //Хичээлийн цагаар

                    //   Primary
                    this.seriesPrimary[0] = parseFloat(
                        (res.data.teacherLessEvalYearPri[0][
                            "characteristicAvg"
                        ] *
                            100) /
                            30
                    ).toFixed(1);
                    //   this.seriesPrimary[0];
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles1
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles2
                            ) *
                                100) /
                            2
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles3
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles4
                            ) *
                                100) /
                            4
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles5
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles6
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles7
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles8
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearPri[0]
                                    .teach_shalles9
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesPri.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles10
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    //   Middle
                    this.seriesMiddle[0] = parseFloat(
                        (res.data.teacherLessEvalYearMidd[0][
                            "characteristicAvg"
                        ] *
                            100) /
                            30
                    ).toFixed(1);
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles1
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles2
                            ) *
                                100) /
                            2
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles3
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles4
                            ) *
                                100) /
                            4
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles5
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles6
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles7
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles8
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles9
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesMidd.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearMidd[0]
                                    .teach_shalles10
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );

                    //   High
                    this.seriesHigher[0] = parseFloat(
                        (res.data.teacherLessEvalYearHigh[0][
                            "characteristicAvg"
                        ] *
                            100) /
                            30
                    ).toFixed(1);
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles1
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles2
                            ) *
                                100) /
                            2
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles3
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles4
                            ) *
                                100) /
                            4
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles5
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles6
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles7
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles8
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles9
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );
                    this.seriesHigh.push(
                        (
                            (parseFloat(
                                res.data.teacherLessEvalYearHigh[0]
                                    .teach_shalles10
                            ) *
                                100) /
                            3
                        ).toFixed(1)
                    );

                    this.viewMySchoolChart = true;
                })
                .catch();
        },
        school_quarter_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "quarter");
            this.seriesQuarterPri = [];
            this.seriesQuarterMidd = [];
            this.seriesQuarterHigh = [];
            this.viewMySchoolChartQuarter1 = false;
            this.viewMySchoolChartQuarter2 = false;
            this.viewMySchoolChartQuarter3 = false;

            this.school_department1_id = "";
            this.seriesDepartment1Pri = [];
            this.viewMySchoolChartDepartment1 = false;
            this.school_department2_id = "";
            this.seriesDepartment2Pri = [];
            this.viewMySchoolChartDepartment2 = false;
            this.school_department3_id = "";
            this.seriesDepartment3Pri = [];
            this.viewMySchoolChartDepartment3 = false;

            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarterTeachLessEvalData",
                        {
                            quarter: newVal,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterPrimary[0] = parseFloat(
                                (res.data.teacherLessEvalYearPri[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);
                            this.viewMySchoolChartQuarter1 = true;
                        } else {
                            this.seriesQuarterPri = [];
                            this.viewMySchoolChartQuarter1 = false;
                        }

                        //   Middle
                        if (res.data.teacherLessEvalYearMidd.length != 0) {
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMidd.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearMidd[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterMiddle[0] = parseFloat(
                                (res.data.teacherLessEvalYearMidd[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);

                            this.viewMySchoolChartQuarter2 = true;
                        } else {
                            this.seriesQuarterMidd = [];
                            this.viewMySchoolChartQuarter2 = false;
                        }

                        //   High
                        if (res.data.teacherLessEvalYearHigh.length != 0) {
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigh.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearHigh[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesQuarterHigher[0] = parseFloat(
                                (res.data.teacherLessEvalYearHigh[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);
                            this.viewMySchoolChartQuarter3 = true;
                        } else {
                            this.seriesQuarterHigh = [];
                            this.viewMySchoolChartQuarter3 = false;
                        }
                    })
                    .catch();
            } else {
                // console.log("school_year_id өөрчлөгдлөө");
            }
        },
        school_department1_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department1");
            this.seriesDepartment1Pri = [];
            this.viewMySchoolChartDepartment1 = false;
            this.school_dep_teach1_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarterDepartment1TLED",
                        {
                            department: newVal,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment1Primary[0] = parseFloat(
                                (res.data.teacherLessEvalYearPri[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);
                            this.viewMySchoolChartDepartment1 = true;
                            this.teachersDep1 = res.data.teachers;
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department1_id өөрчлөгдлөө");
            }
        },
        school_department2_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department2");
            this.seriesDepartment2Pri = [];
            this.viewMySchoolChartDepartment2 = false;
            this.school_dep_teach2_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarterDepartment2TLED",
                        {
                            department: newVal,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment2Middle[0] = parseFloat(
                                (res.data.teacherLessEvalYearPri[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);
                            this.viewMySchoolChartDepartment2 = true;
                            this.teachersDep2 = res.data.teachers;
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department2_id өөрчлөгдлөө");
            }
        },
        school_department3_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.seriesDepartment3Pri = [];
            this.viewMySchoolChartDepartment3 = false;
            this.school_dep_teach3_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarterDepartment3TLED",
                        {
                            department: newVal,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepartment3Higher[0] = parseFloat(
                                (res.data.teacherLessEvalYearPri[0][
                                    "characteristicAvg"
                                ] *
                                    100) /
                                    30
                            ).toFixed(1);
                            this.viewMySchoolChartDepartment3 = true;
                            this.teachersDep3 = res.data.teachers;
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },
        school_dep_teach1_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.seriesDepTeach1Pri = [];
            this.viewMySchoolChartDep_teach1 = false;
            this.school_dep_teach_les_eval1_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeach1TLED",
                        {
                            teacher: newVal,
                            department: this.school_department1_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeach1Pri.title.text =
                                "Багшийн ЭХҮ-ний дундаж: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach1 = true;
                        }

                        this.teachersLessonEval1 = res.data.evaluatedlessons;
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },
        school_dep_teach2_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.seriesDepTeach2Pri = [];
            this.viewMySchoolChartDep_teach2 = false;
            this.school_dep_teach_les_eval2_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeach2TLED",
                        {
                            teacher: newVal,
                            department: this.school_department2_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeach2Pri.title.text =
                                "Багшийн ЭХҮ-ний дундаж: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach2 = true;
                            //   this.teachersDep3 = res.data.teachers;
                        }
                        this.teachersLessonEval2 = res.data.evaluatedlessons;
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },
        school_dep_teach3_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.seriesDepTeach3Pri = [];
            this.viewMySchoolChartDep_teach3 = false;
            this.school_dep_teach_les_eval3_id = "";
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeach3TLED",
                        {
                            teacher: newVal,
                            department: this.school_department3_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeach3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeach3Pri.title.text =
                                "Багшийн ЭХҮ-ний дундаж: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach3 = true;
                            //   this.teachersDep3 = res.data.teachers;
                        }
                        this.teachersLessonEval3 = res.data.evaluatedlessons;
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },

        school_dep_teach_les_eval1_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.teachersLessonEval1Data = [];
            this.seriesDepTeachLesEval1Pri = [];
            this.viewMySchoolChartDep_teach_l_e_1 = false;
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeachLesEval1TLED",
                        {
                            tle_id: newVal,
                            teacher: this.school_dep_teach1_id,
                            department: this.school_department1_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval1Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeachLesEval1Pri.title.text =
                                "Багшийн тухайн ЭХҮ-ийн үнэлгээ: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach_l_e_1 = true;
                            this.teachersLessonEval1Data =
                                res.data.teacherLessEvalYearPri[0];
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },
        school_dep_teach_les_eval2_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.teachersLessonEval2Data = [];
            this.seriesDepTeachLesEval2Pri = [];
            this.viewMySchoolChartDep_teach_l_e_2 = false;
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeachLesEval1TLED",
                        {
                            tle_id: newVal,
                            teacher: this.school_dep_teach2_id,
                            department: this.school_department2_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval2Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeachLesEval2Pri.title.text =
                                "Багшийн тухайн ЭХҮ-ийн үнэлгээ: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach_l_e_2 = true;
                            this.teachersLessonEval2Data =
                                res.data.teacherLessEvalYearPri[0];
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        },
        school_dep_teach_les_eval3_id: function(newVal, oldVal) {
            //   console.log(newVal, oldVal, "department3");
            this.teachersLessonEval3Data = [];
            this.seriesDepTeachLesEval3Pri = [];
            this.viewMySchoolChartDep_teach_l_e_3 = false;
            if (newVal != "") {
                axios
                    .post(
                        "/curriculumadministrator/getAllSchoolYearQuarDepTeachLesEval1TLED",
                        {
                            tle_id: newVal,
                            teacher: this.school_dep_teach3_id,
                            department: this.school_department3_id,
                            quarter: this.school_quarter_id,
                            year: this.school_year_id
                        }
                    )
                    .then(res => {
                        // console.log(res.data.teacherLessEvalYearPri);
                        // console.log(res.data.teacherLessEvalYearPri.length);
                        //   Primary
                        if (res.data.teacherLessEvalYearPri.length != 0) {
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles1
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles2
                                    ) *
                                        100) /
                                    2
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles3
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles4
                                    ) *
                                        100) /
                                    4
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles5
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles6
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles7
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles8
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles9
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.seriesDepTeachLesEval3Pri.push(
                                (
                                    (parseFloat(
                                        res.data.teacherLessEvalYearPri[0]
                                            .teach_shalles10
                                    ) *
                                        100) /
                                    3
                                ).toFixed(1)
                            );
                            this.chartOptionsDepTeachLesEval3Pri.title.text =
                                "Багшийн тухайн ЭХҮ-ийн үнэлгээ: " +
                                parseFloat(
                                    (res.data.teacherLessEvalYearPri[0][
                                        "characteristicAvg"
                                    ] *
                                        100) /
                                        30
                                ).toFixed(1) +
                                "%";
                            this.viewMySchoolChartDep_teach_l_e_3 = true;
                            this.teachersLessonEval3Data =
                                res.data.teacherLessEvalYearPri[0];
                        }
                    })
                    .catch();
            } else {
                // console.log("school_department3_id өөрчлөгдлөө");
            }
        }
    },
    methods: {
        getAllSchoolFilterData() {
            axios
                .get("/curriculumadministrator/filterData")
                .then(res => {
                    //   console.log(res.data);
                    //хичээлийн жил
                    this.years = res.data.years;
                    this.quarters = res.data.quarters;
                    this.departments = res.data.departments;
                    this.schoolCLasses = res.data.schoolCLasses;
                    this.teachers = res.data.teachers;
                    this.getSchoolPrimaryClasses =
                        res.data.getSchoolPrimaryClasses;
                    this.getSchoolMiddleClasses =
                        res.data.getSchoolMiddleClasses;
                    this.getSchoolHighClasses = res.data.getSchoolHighClasses;
                })
                .catch();
        },
        getToFixed0(val) {
            return parseFloat(val).toFixed(0);
        },
        getToFixed(val) {
            return parseFloat(val).toFixed(1);
        },
        customYearName({ start_date, finish_date }) {
            return `${start_date} - ${finish_date} - оны хичээлийн жил`;
        },
        customQuarterName({ name }) {
            return `${name} - р улирал`;
        },
        customDepartmentName({ department_name, department_code }) {
            return `${department_name}`;
        },
        customTeacherDep1({ teacher_last_name, teacher_name }) {
            return `${teacher_last_name}. ${teacher_name}`;
        },
        customTeacherLesson({
            subject_name,
            subject_short_name,
            twe_lesson_ognoo,
            twe_lesson_time,
            twe_lesson_topic
        }) {
            return `Хичээл: ${subject_short_name}, Огноо: ${twe_lesson_ognoo}, Цаг: ${twe_lesson_time}, Сэдэв: ${twe_lesson_topic}`;
        },
        customClassName({ full_name }) {
            return `${full_name} анги`;
        },
        customSubjectName({ subject_short_name }) {
            return `${subject_short_name}`;
        },
        customStudentName({ student_last_name, student_name }) {
            return `${student_last_name[0]}.${student_name}`;
        }
    },
    mounted() {
        // this.$nextTick(() => {
        //   this.getAllSchoolFilterData();
        //   this.viewMyChart = true;
        // });
    },
    created() {
        this.getAllSchoolFilterData();
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table {
    font-size: "50%";
}
</style>
