<template>
  <section class="content">
    <div class="container-fluid">
      <!-- /.row -->
      <div class="row pt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-header p-2">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" href="#activity" data-toggle="tab"
                    >Идэвхтэй</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#timeline" data-toggle="tab"
                    >Идэвхгүй</a
                  >
                </li>
              </ul>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive" style="overflow-x: auto">
              <div class="tab-content">
                <div class="tab-pane active" id="activity">
                  <div class="row">
                    <div class="col-md-8">
                      <table class="table table-hover text-nowrap">
                        <thead>
                          <tr role="row">
                            <th>№</th>
                            <th>Эхлэх огноо</th>
                            <th>Дуусах огноо</th>
                            <th>Хичээлийн жилийн нэр</th>
                            <th>Үүсгэсэн</th>
                            <th>Үйлдлүүд</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd"
                            v-for="(year, index) in years"
                            :key="year.id"
                          >
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.start_date | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.finish_date | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.start_date | dateYear }}
                              -
                              {{ year.finish_date | dateYear }}
                              оны хичээлийн жил
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.created_at | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              <a
                                href="#"
                                @click="viewYears(year)"
                                class="badge bg-primary"
                                ><i class="far fa-eye"></i
                              ></a>
                              <a
                                href="#"
                                @click="editYears(year)"
                                class="badge bg-warning"
                                ><i class="fas fa-edit"></i
                              ></a>

                              <a
                                href="#"
                                @click="deleteYears(year)"
                                class="badge bg-danger"
                                ><i class="fas fa-trash-alt"></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-4">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title" v-show="!editMode">
                            Хичээн жил бүртгэх
                          </h3>
                          <h3 class="card-title" v-show="editMode">
                            Хичээлийн жил засах
                          </h3>
                        </div>
                        <!-- /.card-header -->
                        <!-- form start -->
                        <form
                          @submit.prevent="
                            !editMode ? createYears() : updateYears()
                          "
                        >
                          <div class="card-body">
                            <div class="form-group">
                              <label>Эхлэх огноо</label>
                              <b-form-datepicker
                                v-model="form.start_date"
                                class="mb-2"
                              ></b-form-datepicker>
                              <has-error
                                :form="form"
                                field="start_date"
                              ></has-error>
                            </div>
                            <div class="form-group">
                              <label>Дуусах огноо</label>
                              <b-form-datepicker
                                v-model="form.finish_date"
                                class="mb-2"
                              ></b-form-datepicker>

                              <has-error
                                :form="form"
                                field="finish_date"
                              ></has-error>
                            </div>
                          </div>
                          <!-- /.card-body -->
                          <div class="card-footer">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              v-show="!editMode"
                            >
                              Хадгалах
                            </button>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              v-show="editMode"
                            >
                              Засах
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.tab-pane -->
                <div class="tab-pane" id="timeline">
                  <!-- The timeline -->
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table table-hover text-nowrap" role="grid">
                        <thead>
                          <tr role="row">
                            <th>№</th>
                            <th>Эхлэх огноо</th>
                            <th>Дуусах огноо</th>
                            <th>Хичээлийн жилийн нэр</th>
                            <th>Үүсгэсэн</th>
                            <th>Үйлдлүүд</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd"
                            v-for="(year, index) in inActiveYear"
                            :key="year.id"
                          >
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.start_date | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.finish_date | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.start_date | dateYear }}
                              -
                              {{ year.finish_date | dateYear }}
                              оны хичээлийн жил
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              {{ year.created_at | dateYearMonthDay }}
                            </td>
                            <td class="dtr-control sorting_1" tabindex="0">
                              <a href="#" @click="viewYears(year)"
                                ><i class="far fa-eye"></i
                              ></a>
                              <a href="#" @click="restoreYears(year)"
                                ><i class="fas fa-trash-restore-alt"></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.tab-pane -->
              </div>
              <!-- /.tab-content -->
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.nav-tabs-custom -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      years: [],
      inActiveYear: [],
      form: new Form({
        id: "",
        start_date: "",
        finish_date: "",
      }),
    };
  },
  methods: {
    getAllYears() {
      axios
        .get("/owner/getAllyears")
        .then((response) => {
          console.log(response.data.years);
          this.years = response.data.years;
          this.inActiveYear = response.data.inActiveYear;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewYears(year) {
      // console.log("Дэлгэрэнгүй мэдээлэл харах" + year);
    },
    editYears(year) {
      // console.log("Дэлгэрэнгүй мэдээлэл засах" + year.id);
      this.editMode = true;
      this.form.reset();
      this.form.fill(year);
    },
    updateYears() {
      this.action = "Хичээлийн жилийн мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/owner/years/" + this.form.id)
        .then((response) => {
          console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadYears");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    deleteYears(year) {
      console.log("Мэдээлэл устгах" + year.id);
      // this.form.fill(year);
      this.form
        .delete("/owner/years/" + year.id)
        .then((response) => {
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadYears");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    restoreYears(year) {
      console.log("Мэдээлэл сэргээх" + year.id);
      // this.form.fill(year);
      this.form
        .get("/owner/years/" + year.id)
        .then((response) => {
          this.$toastr.s(
            "Сонгосон огноог амжилттай сэргээлээ.",
            "Амжилттай сэргээлээ"
          );
          Fire.$emit("loadYears");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    createYears() {
      this.action = "Хичээлийн жил үүсгэж байна...";
      this.form
        .post("/owner/years")
        .then((response) => {
          this.$toastr.s("Хичээлийн жилийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadYears");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлийн жилийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllYears();
    Fire.$on("loadYears", () => {
      this.getAllYears();
    });
  },
};
</script>

<style></style>
