<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <strong>Алхам 1: Шалгалтын бүртгэл</strong>
          <b-button
            size="sm"
            class="float-right"
            variant="primary"
            @click.prevent="addProblemExam()"
            >Шалгалт бүртгэх</b-button
          >

          <multiselect
            v-model="yearId"
            deselect-label="Хасах"
            select-label="Сонгох"
            track-by="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жил сонгоно уу?"
            :options="years"
            :searchable="true"
            :allow-empty="true"
            @input="getAllBBZandOpenExamsTeacher"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>

          <div class="card-header p-0 pt-1">
            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="custom-tabs-one-home-tab"
                  data-toggle="pill"
                  href="#custom-tabs-one-home"
                  role="tab"
                  aria-controls="custom-tabs-one-home"
                  aria-selected="true"
                  >Миний үүсгэсэн шалгалтууд: {{ exams.length }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="custom-tabs-one-profile-tab"
                  data-toggle="pill"
                  href="#custom-tabs-one-profile"
                  role="tab"
                  aria-controls="custom-tabs-one-profile"
                  aria-selected="false"
                  >Бусад багш урьсан шалгалтууд
                  {{ examsInviteOtherTeachers.length }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <b-overlay :show="examLoaded" rounded="sm">
            <div class="tab-content" id="custom-tabs-one-tabContent">
              <div
                class="tab-pane fade show active"
                id="custom-tabs-one-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-one-home-tab"
              >
                <b-row>
                  <b-col lg="12" class="my-1">
                    <b-form-group
                      label="Нэг хуудсанд харуулах тоо"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageExams"
                        :options="pageOptionsExams"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Хайлт хийх утга оруулна уу!"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filterExams"
                          type="search"
                          placeholder="Хайлт хийх утгаа оруулна уу!"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            :disabled="!filterExams"
                            @click="filterExams = ''"
                            >Цэвэрлэх</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPageExams"
                  :total-rows="totalRowsExams"
                  :per-page="perPageExams"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="exams"
                  :fields="fieldsActiveExams"
                  :current-page="currentPageExams"
                  :per-page="perPageExams"
                  :filter="filterExams"
                  :filter-included-fields="filterOnExams"
                  @filtered="onFilteredExams"
                >
                  <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(flName)="data">
                    <b-badge variant="info">
                      {{ data.item.emp_teacher_last_name }}-(ын/ийн)
                      {{ data.item.emp_teacher_name }}
                    </b-badge>
                  </template>
                  <template #cell(exams_type_status)="data">
                    <b-badge
                      variant="success"
                      v-if="data.item.exams_type_status == 0"
                    >
                      ББЗ цикл
                    </b-badge>
                    <b-badge
                      variant="warning"
                      v-if="data.item.exams_type_status == 1"
                    >
                      Нээлттэй шалгалт
                    </b-badge>
                  </template>
                  <template #cell(other_actions)="data">
                    <!-- v-if="currentUserId == plan.owner_id" -->
                    <div style="display: inline-block">
                      <b-button
                        variant="outline-dark"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreTeacherForExams(data.item)"
                        v-b-tooltip.hover
                        title="Багш"
                        ><i class="fas fa-chalkboard-teacher"></i
                      ></b-button>
                      <b-button
                        variant="info"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreTopicsForExams(data.item)"
                        v-b-tooltip.hover
                        title="Сэдвүүд"
                        ><i class="fas fa-tasks"></i
                      ></b-button>
                      <b-button
                        variant="success"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreSetUpForExams(data.item)"
                        v-b-tooltip.hover
                        title="Бүтэц тохируулах"
                        ><i class="fas fa-tasks"></i>
                      </b-button>
                      <b-button
                        variant="outline-success"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreSetUpForExamsEnroll(data.item)"
                        v-b-tooltip.hover
                        title="Суралцагч бүртгэх"
                        ><i class="fas fa-users"></i>
                      </b-button>
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="giveToExamsStudentValue(data.item)"
                        v-b-tooltip.hover
                        v-b-toggle.sidebar-exam-form
                        title="Суралцагчид шалгалтын үнэлгээ бүртгэх"
                        ><i class="fas fa-users"></i>
                      </b-button>
                    </div>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(updated_at)="data">
                    <b-badge>
                      {{ data.item.updated_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editExam(data.item)"
                      ><i class="fas fa-pencil-alt"></i
                    ></a>

                    <a
                      v-if="userInfo.name == 'cipalprin'"
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExam(data.item)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div
                class="tab-pane fade"
                id="custom-tabs-one-profile"
                role="tabpanel"
                aria-labelledby="custom-tabs-one-profile-tab"
              >
                <b-row>
                  <b-col lg="12" class="my-1">
                    <b-form-group
                      label="Нэг хуудсанд харуулах тоо"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageExamsOpenAndBBZ"
                        :options="pageOptionsExamsOpenAndBBZ"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Хайлт хийх утга оруулна уу!"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filterExamsOpenAndBBZ"
                          type="search"
                          placeholder="Хайлт хийх утгаа оруулна уу!"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            :disabled="!filterExamsOpenAndBBZ"
                            @click="filterExamsOpenAndBBZ = ''"
                            >Цэвэрлэх</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPageExamsOpenAndBBZ"
                  :total-rows="totalRowsExamsOpenAndBBZ"
                  :per-page="perPageExamsOpenAndBBZ"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="examsInviteOtherTeachers"
                  :fields="fieldsActiveExamsOpenAndBBZ"
                  :current-page="currentPageExamsOpenAndBBZ"
                  :per-page="perPageExamsOpenAndBBZ"
                  :filter="filterExamsOpenAndBBZ"
                  :filter-included-fields="filterOnExamsOpenAndBBZ"
                  @filtered="onFilteredExamsOpenAndBBZ"
                >
                  <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(flName)="data">
                    <b-badge variant="info">
                      {{ data.item.emp_teacher_last_name }}-(ын/ийн)
                      {{ data.item.emp_teacher_name }}
                    </b-badge>
                  </template>
                  <template #cell(exams_type_status)="data">
                    <b-badge
                      variant="success"
                      v-if="data.item.exams_type_status == 0"
                    >
                      ББЗ цикл
                    </b-badge>
                    <b-badge
                      variant="warning"
                      v-if="data.item.exams_type_status == 1"
                    >
                      Нээлттэй шалгалт
                    </b-badge>
                  </template>
                  <template #cell(other_actions)="data">
                    <!-- v-if="currentUserId == plan.owner_id" -->
                    <div style="display: inline-block">
                      <b-button
                        variant="outline-dark"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreTeacherForExams(data.item)"
                        v-b-tooltip.hover
                        title="Багш"
                        ><i class="fas fa-chalkboard-teacher"></i
                      ></b-button>
                      <b-button
                        variant="info"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreTopicsForExams(data.item)"
                        v-b-tooltip.hover
                        title="Сэдвүүд"
                        ><i class="fas fa-tasks"></i
                      ></b-button>
                      <b-button
                        variant="success"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreSetUpForExams(data.item)"
                        v-b-tooltip.hover
                        title="Бүтэц тохируулах"
                        ><i class="fas fa-tasks"></i>
                      </b-button>
                      <b-button
                        variant="outline-success"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="moreSetUpForExamsEnroll(data.item)"
                        v-b-tooltip.hover
                        title="Суралцагч бүртгэх"
                        ><i class="fas fa-users"></i>
                      </b-button>
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        class="py-0"
                        v-show="data.item.exams_type_status == 1"
                        @click="giveToExamsStudentValue(data.item)"
                        v-b-tooltip.hover
                        v-b-toggle.sidebar-exam-form
                        title="Суралцагчид шалгалтын үнэлгээ бүртгэх"
                        ><i class="fas fa-users"></i>
                      </b-button>
                    </div>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(updated_at)="data">
                    <b-badge>
                      {{ data.item.updated_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editExam(data.item)"
                      ><i class="fas fa-pencil-alt"></i
                    ></a>

                    <a
                      v-if="userInfo.name == 'cipalprin'"
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExam(data.item)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </template>
                </b-table>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Нээлттэй шалгалт нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-create-exam"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formExamModalTitle"
      class="modal fade"
      hide-footer
      @hidden="cancelEdit"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Багш шалгалт бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">Багш шалгалт засах</h3>
              </div>
              <div class="card-body form-responsive p-0">
                <form
                  @submit.prevent="
                    !editMode ? createSchoolExam() : updateSchoolExam()
                  "
                >
                  <div class="card-body">
                    <!-- <div class="form-group">
                      <label>Багш сонгох</label>
                      <div class="row">
                        <div class="col-sm-8">
                          <multiselect
                            v-model="form.exams_teacher_id"
                            :multiple="false"
                            deselect-label="Can't remove this value"
                            :custom-label="nameTeacher"
                            track-by="emp_teacher_name"
                            label="emp_teacher_name"
                            placeholder="Сонголт хийх"
                            :options="teachers"
                            :searchable="true"
                            :allow-empty="true"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>{{ option.emp_teacher_name }}</strong>
                              багш сонгогдлоо
                            </template>
                          </multiselect>
                        </div>
                        <div class="col-sm-4">
                          <a
                            @click.prevent="getTeacherBBZandOpenCourses()"
                            class="btn btn-success"
                          >
                            Багшийн хичээл</a
                          >
                        </div>
                      </div>
                    </div> -->

                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Багшийн орж буй хичээлүүд</label>

                      <multiselect
                        v-model="form.exams_course_id"
                        :multiple="false"
                        deselect-label="Can't remove this value"
                        :custom-label="nameCourse"
                        track-by="id"
                        label="id"
                        placeholder="Сонголт хийх"
                        :options="teacherCourses"
                        :searchable="true"
                        :allow-empty="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.full_name }}
                            {{ option.subject_name }}</strong
                          >
                          сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Шалгалт авах улирал сонгох</label>
                      <multiselect
                        v-model="form.exams_quarter_id"
                        :multiple="false"
                        deselect-label="Can't remove this value"
                        :custom-label="nameQuarter"
                        track-by="quarter_id"
                        label="quarter_id"
                        placeholder="Сонголт хийх"
                        :options="quarters"
                        :searchable="true"
                        :allow-empty="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.quarter_name }}</strong>
                          -р улирал сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Шалгалтын нэр</label>
                      <input
                        type="text"
                        name="user_email"
                        v-model="form.exams_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('exams_name'),
                          },
                        ]"
                        placeholder="Шалгалтын нэр оруулна уу?"
                      />
                      <has-error :form="form" field="exams_name"></has-error>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Шалгалтын загвар</label>
                      <multiselect
                        v-model="form.exams_type_status"
                        :multiple="false"
                        deselect-label="Can't remove this value"
                        :custom-label="nameExamTypeStatus"
                        track-by="value"
                        label="value"
                        placeholder="Шалгалтын загвар сонголт хийх"
                        :options="examTypeStatus"
                        :searchable="true"
                        :allow-empty="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.text }}</strong>
                          сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Шалгалтын төрөл</label>
                      <multiselect
                        v-model="form.exams_type"
                        :multiple="false"
                        deselect-label="Can't remove this value"
                        :custom-label="nameExamType"
                        track-by="value"
                        label="value"
                        placeholder="Сонголт хийх"
                        :options="examTypes"
                        :searchable="true"
                        :allow-empty="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.value }}</strong>
                          сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Календар төлөвлөгөөний харгалзах агуулга</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.exams_calendar_plan_content"
                        placeholder="Календар төлөвлөгөөний харгалзах агуулга..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>

                      <has-error :form="form" field="schools"></has-error>
                    </div>
                    <div class="form-group" v-show="teacherCourses.length != 0">
                      <label>Шалгалтын тайлбар</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.exams_description"
                        placeholder="Тухайн шалгалтын агуулга, шалгах мэдлэг, чадвар гэх мэт..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>

                      <has-error :form="form" field="schools"></has-error>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-show="!editMode"
                    >
                      Хадгалах
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-show="editMode"
                    >
                      Засах
                    </button>
                    <button
                      class="btn btn-warning"
                      v-show="editMode"
                      @click.prevent="cancelEdit"
                    >
                      Цуцлах
                    </button>
                    <button
                      class="btn btn-warning"
                      v-show="!editMode"
                      @click.prevent="cancelCreate"
                    >
                      Болих
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Багш нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-exam-add-teacher"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Багш нарын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp"
              ><b>Үечлэл /Cambridge stage/: </b>{{ formTeacher.stage }}</span
            >
            <span class="direct-chat-timestamp float-right"
              ><b>Анги: </b>{{ formTeacher.grade }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Хичээл: </b>{{ formTeacher.subject_name }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Багш нар</h3>
                <!-- v-show="
                    formTeacher.teacher_type === 'owner' ||
                    userInfo.name === 'agerman'
                  " -->
                <a
                  href="#"
                  class="badge bg-primary"
                  @click="editExamTeacher(formTeacher.exam_id)"
                  ><i class="fa fa-edit"></i
                ></a>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Зураг</th>
                      <th>Үүрэг</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(examTeacher, index) in examTeachers"
                    :key="examTeacher.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="examTeacher.profile_photo_url != null"
                          :src="examTeacher.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span
                          v-show="examTeacher.teacher_type === 'member'"
                          class="badge bg-dark"
                          >MEMBER</span
                        >
                        <span
                          v-show="examTeacher.teacher_type === 'owner'"
                          class="badge bg-success"
                          >OWNER</span
                        >
                        <span
                          v-show="examTeacher.teacher_type === 'senior'"
                          class="badge bg-primary"
                          >EDITOR</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ examTeacher.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ examTeacher.teacher_lname[0] }}.
                        {{ examTeacher.teacher_fname }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <!-- (examTeacher.teacher_type !== 'owner' &&
                              examTeacher.teacher_id !== userDetails.id) ||
                            (examTeacher.teacher_type !== 'member' &&
                              examTeacher.teacher_type !== 'senior') -->
                        <a
                          v-show="
                            examTeacher.teacher_type !== 'owner' &&
                            examTeacher.my_exam_info['teacher_id'] ===
                              userDetails.id
                          "
                          href="#"
                          class="badge bg-danger"
                          @click="removeExamTeacher(examTeacher)"
                          ><i class="fa fa-minus"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-warning"
                          v-show="
                            examTeacher.teacher_type !== 'owner' &&
                            examTeacher.my_exam_info['teacher_id'] ===
                              userDetails.id
                          "
                          @click="editExamOnlyOneTeacher(examTeacher)"
                          ><i class="fa fa-edit"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editTeacherMode">Багш нэмэх</h3>
                <h3 class="card-title" v-show="editTeacherMode">Багш засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <div class="form-group" v-if="teacherOptions">
                  <label>Багш нар сонгох</label>
                  <multiselect
                    v-model="formTeacher.teachers"
                    :options="teacherOptions"
                    :multiple="true"
                    :hide-selected="true"
                    :searchable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    placeholder="Багш сонгоно уу!"
                    :custom-label="nameExamTeacher"
                    label="emp_teacher_name"
                    track-by="emp_teacher_name"
                    @input="modalChangeTeacherCourses"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} багш сонгогдсон
                        {{ values.emp_teacher_last_name }}</span
                      ></template
                    >
                  </multiselect>
                </div>
                <div class="form-group">
                  <label
                    >Сонгогдсон багш нарын тохирох хичээл сонгох(Нэг багшаас нэг
                    хичээл сонгох)</label
                  >
                  <multiselect
                    v-model="formTeacher.courses"
                    :options="modalTeacherCourses"
                    :multiple="true"
                    :hide-selected="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Багшид хамаарах хичээл сонгох"
                    :custom-label="nameExamTeacherCourses"
                    label="id"
                    track-by="id"
                    :preselect-first="false"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} хичээл сонгогдлоо.
                      </span></template
                    >
                    <!-- return `${teacher.teacher_last_name[0]}. ${teacher.teacher_name} . ${my_school_class.full_name} . ${my_subject.subject_name} . ${course_type.name}`; -->
                  </multiselect>
                </div>
                <form>
                  <div class="form-group">
                    <label>Гишүүний төрөл</label>
                    <b-form-select
                      v-model="formTeacher.teacher_type"
                      :options="teacherTypes"
                      text-field="name"
                      value-field="value"
                    ></b-form-select>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addExamTeacher()"
                  v-show="!editTeacherMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateExamTeacher()"
                  v-show="editTeacherMode && editTeacherOnlyMode != true"
                >
                  Олон багш засах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateExamOnlyOneTeacher()"
                  v-show="editTeacherMode && editTeacherOnlyMode != false"
                >
                  Нэг багш засах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="formTeacherReset()"
                  v-show="editTeacherMode"
                >
                  Болих
                </button>
              </div>
              <!-- <div class="card-footer">
                <span class="text-danger text-sm"
                  >Зөвхөн шалгалтын <b>ЭЗЭМШИГЧ</b> багш нэмэх боломжтой!</span
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Календараас сэдэв авч хадгалах -->
    <b-modal
      size="xl"
      id="modal-exam-add-topics"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Багш нарын мэдээлэл"
      class="modal fade"
      hide-footer
      @hidden="resetModalExamAddTopics"
    >
      <div class="card">
        <div class="card-header">
          <strong>ШАЛГАЛТАНД ХАМААРАЛТАЙ БАГШ НАР, ХИЧЭЭЛҮҮД: </strong>
          <h4>
            <b-badge
              variant="info"
              pill
              v-for="teacher in useTopicTeachers"
              :key="teacher.id"
            >
              <b-badge
                href="#"
                variant="primary"
                @click.prevent="getCalPlanTeacher(teacher)"
                v-if="teacher['calendar_plan_info']"
                >{{ teacher["my_teacher"].teacher_last_name[0] }}.
                {{ teacher["my_teacher"].teacher_name }}
                {{ teacher["calendar_plan_info"].subject_name }}
              </b-badge>
            </b-badge>
          </h4>
        </div>
        <div class="modal-body">
          <b-overlay :show="planIsLoaded" rounded="sm">
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
              <span class="mr-auto">
                Анги: <strong>{{ showTopic.grade }}</strong>
                <span class="ml-5"
                  >Хичээл: <strong>{{ showTopic.subject_name }}</strong></span
                >
              </span>
            </div>
            <div class="px-3 py-2">
              <div class="card">
                <div class="card-header">
                  <strong
                    >{{ showTopic.subject_name }} {{ showTopic.startYear }}-{{
                      showTopic.finishYear
                    }}
                    оны хичээлийн жил</strong
                  >
                </div>
                <div class="card-body table-responsive">
                  <table id="cp_table" class="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Анги
                        </td>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          :colspan="showTopic.totalWeek"
                        >
                          {{ showTopic.grade }}-р анги,
                          {{ showTopic.subject_name }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Үечлэл
                        </td>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          :colspan="showTopic.totalWeek"
                        >
                          {{ showTopic.stage }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Улирал
                        </td>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          v-for="showTerm in showTerms"
                          :key="showTerm.id"
                          :colspan="showTerm.term_week"
                        >
                          {{ showTerm.term_name }}-р улирал
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Сар
                        </td>
                        <td
                          v-for="termMonth in showMonths"
                          :key="termMonth.id"
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          :colspan="termMonth.month_week"
                        >
                          {{ termMonth.month_name }}-р сар
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          7 хоног
                        </td>
                        <td
                          v-for="weekObj in showPlanWeeks"
                          :key="weekObj.id"
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                        >
                          <b-badge
                            v-if="
                              nowWeekCount == parseInt(weekObj.week_index) &&
                              nowMonth == parseInt(weekObj.month_name)
                            "
                            variant="warning"
                            v-b-popover.hover.right="'Энэ 7 хоног'"
                            >{{ weekObj.week_name }}<br />
                            Энэ 7 хоног
                          </b-badge>
                          <b-badge variant="secondary" v-else>
                            {{ weekObj.week_name }}
                          </b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Бүлэг сэдэв
                        </td>
                        <td
                          v-for="subject in showSubjects"
                          :key="subject.id"
                          class="dtr-control sorting_1 text-center"
                          :colspan="subject.subject_week"
                          tabindex="0"
                        >
                          {{ subject.subject_name }}
                        </td>
                      </tr>
                      <tr v-for="indexUnit in 2" :key="'unit' + indexUnit">
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                          v-if="indexUnit == 1"
                        >
                          Нэгж сэдэв
                        </td>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                          v-if="indexUnit == 2"
                        >
                          Цаг
                        </td>
                        <td
                          v-for="unit in showUnits"
                          :key="unit.id"
                          class="dtr-control sorting_1 text-center"
                          :colspan="unit.unit_week"
                          tabindex="0"
                        >
                          <div v-if="indexUnit == 1">
                            {{ unit.unit_name }}
                          </div>
                          <div v-if="indexUnit == 2">
                            {{ unit.unit_hour }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center p-0"
                          tabindex="0"
                          colspan="1"
                        >
                          <div class="topicColumn">
                            <div
                              class="topic-column verticalText text-center p-0"
                            >
                              <h6>
                                Шалгалт авах сэдвүүд дээр дарж сонголтоо хийнэ
                                үү!
                              </h6>
                            </div>
                          </div>
                          <div class="topic-hour-column">Цаг</div>
                        </td>
                        <td
                          v-for="week in showPlanWeeks"
                          :key="week.id"
                          class="dtr-control sorting_1 text-center p-0"
                          tabindex="0"
                        >
                          <a
                            id="addTopic"
                            v-show="showTopic.isMyDepEmp"
                            v-if="week.topicTotalHour < week.week_hour"
                            href="#"
                            v-b-popover.hover.right="'Сэдэв нэмэх'"
                            @click.prevent="addTopic(week)"
                          >
                            <b-icon
                              icon="plus-circle-fill"
                              variant="success"
                            ></b-icon
                          ></a>
                          <a id="addTopic" v-else href="#">
                            <b-icon
                              icon="plus-circle-fill"
                              class="noActive"
                              variant="success"
                            ></b-icon
                          ></a>
                          <div
                            class="topic-wrapper"
                            v-if="week.topics.length > 0"
                          >
                            <div
                              class="topic"
                              v-for="topic in week.topics"
                              :key="topic.id"
                            >
                              <h5>
                                <a
                                  href="#"
                                  v-if="topic.teacherList.length == 0"
                                >
                                  <b-icon
                                    icon="check-circle"
                                    variant="success"
                                  ></b-icon
                                ></a>
                                <a
                                  href="#"
                                  @click="showTeacherShorts(topic)"
                                  v-else
                                >
                                  <b-icon
                                    icon="check-circle-fill"
                                    v-b-popover.hover.right="
                                      'Сэдвийн ээлжит хичээлийг заасан байдал'
                                    "
                                    variant="success"
                                  ></b-icon
                                ></a>
                                <div class="topic-title verticalText">
                                  <b-badge
                                    :disabled="topic.burtgegdsen == 1"
                                    href="#"
                                    id="topicBadge"
                                    variant="light"
                                    v-b-toggle.sidebar-short
                                    v-b-popover.hover.right="'Шалгалтанд нэмэх'"
                                    @click.prevent="addTopicForExam(topic)"
                                  >
                                    {{ topic.topic_name }}
                                    <b-badge
                                      variant="warning"
                                      v-b-popover.hover.right="'Цаг'"
                                    >
                                      {{ topic.topic_hour }} цаг
                                    </b-badge>
                                  </b-badge>
                                </div>
                              </h5>
                            </div>
                          </div>
                          <div class="topic-wrapper" v-else>
                            <div class="topic">
                              <a href="#">
                                <b-icon
                                  icon="check-circle"
                                  variant="success"
                                  class="noActive"
                                ></b-icon
                              ></a>
                              <div class="topic-title verticalText"></div>
                            </div>
                          </div>
                          <div class="topic-hour">
                            {{ week.week_hour }} /
                            {{ week.topicTotalHour }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          colspan="1"
                        >
                          Нийт
                        </td>
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                          :colspan="showTopic.totalWeek"
                        >
                          <b>{{ showTopic.totalWeek }} долоо хоног</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-overlay>

          <b-card
            no-body
            header="Шалгалтын агуулгууд"
            v-show="addTopicForExamForm.topicsForExam.length != 0"
          >
            <b-list-group>
              <b-list-group-item
                button
                v-for="(top, index) in addTopicForExamForm.topicsForExam"
                :key="index"
                >{{ top.topic_name }}
                <i
                  class="fas fa-minus-circle"
                  @click.prevent="minusTopicForExamForm(top)"
                ></i>
              </b-list-group-item>
              <b-button variant="success" @click.prevent="storeTopicForExam()"
                ><i class="fas fa-plus-circle"></i>Нэмэх</b-button
              >
              <b-button
                variant="outline-primary"
                @click.prevent="closeModalExamAddTopics"
                >Цуцлах</b-button
              >
            </b-list-group>
          </b-card>
        </div>
      </div>
    </b-modal>

    <!-- Шалгалтын бүтэц -->
    <b-modal
      size="xl"
      id="modal-exam-setup"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Шалгалтын бүтэц тохируулах"
      class="modal fade"
      hide-footer
      @hidden="resetModalExamSetUp"
    >
      <div class="card">
        <div class="card-header">
          <b-row class="text-center">
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </div>
        <div class="modal-body">
          <b-overlay :show="examSetUpLoaded" rounded="sm">
            <b-row class="text-center">
              <b-col>
                <strong
                  >АЛХАМ 1: Дугаар(Number)
                  <b-button
                    variant="success"
                    size="sm"
                    @click.prevent="addExamNumbersShow"
                    ><b-icon icon="plus-circle"></b-icon></b-button
                ></strong>
                <b-form v-if="examSetUpForm1" inline>
                  <b-form-group
                    id="input-group-1"
                    label-for="number_name"
                    description="Дараалалсан дугаараар оруулахаа мартав."
                  >
                    <b-form-input
                      id="number_name"
                      v-model="addExamNumbersForm.number_name"
                      type="number"
                      size="sm"
                      placeholder="Даалгаврын дугаар оруулна уу!"
                      required
                    ></b-form-input>
                    <b-button
                      v-show="numberEditMode != true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="storeExamNumbers()"
                      >Хадгалах</b-button
                    >
                    <b-button
                      v-show="numberEditMode != false"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="updateExamNumbersForm()"
                      >Засах</b-button
                    >
                    <b-button
                      type="reset"
                      size="sm"
                      variant="danger"
                      @click.prevent="resetExamNumbersForm()"
                      >Цуцлах</b-button
                    >
                  </b-form-group>
                </b-form>
                <b-pagination
                  v-model="currentPageNumber"
                  :total-rows="totalRowsNumber"
                  :per-page="perPageNumber"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  striped
                  hover
                  :items="probemStep1Numbers"
                  :fields="fieldsNumber"
                  :current-page="currentPageNumber"
                  :per-page="perPageNumber"
                  :filter="filterNumber"
                  :filter-included-fields="filterOnNumber"
                  :sort-by.sync="sortByNumber"
                  :sort-desc.sync="sortDescNumber"
                  :sort-direction="sortDirectionNumber"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFilteredNumber"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(number_name)="data">
                    <a
                      href="#"
                      class="badge bg-info"
                      @click.prevent="showNumberSymbols(data.item)"
                      >Дугаар: {{ data.item.number_name }}:
                      <i class="fas fa-plus-circle"></i
                    ></a>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editExamNumbersForm(data.item)"
                    >
                      <i class="fas fa-pencil-alt"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExamNumbersForm(data.item)"
                    >
                      <i class="fas fa-trash-alt"></i
                    ></a>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col v-show="step2Mode">
                <strong v-show="step2Mode"
                  >АЛХАМ 2: Үсэг(Symbol)<b-button
                    variant="success"
                    size="sm"
                    @click.prevent="addExamNumberSymbolsShow"
                    ><b-icon icon="plus-circle"></b-icon></b-button
                ></strong>
                <b-form v-if="examSetUpForm2" inline>
                  <b-form-group
                    id="input-group-1"
                    label-for="number_name"
                    description="Дараалалсан дугаараар оруулахаа мартав."
                  >
                    <b-form-select
                      v-model="addExamNumbersSymbolForm.symbol_name"
                      :options="optionsStep2Symbols"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                    <b-button
                      v-show="numberSymbolEditMode != true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="storeExamNumberSymbols()"
                      >Хадгалах</b-button
                    >
                    <b-button
                      v-show="numberSymbolEditMode != false"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="updateExamNumberSymbols()"
                      >Засах</b-button
                    >
                    <b-button
                      type="reset"
                      size="sm"
                      variant="danger"
                      @click.prevent="resetExamNumbersSymbolForm()"
                      >Цуцлах</b-button
                    >
                  </b-form-group>
                </b-form>
                <b-pagination
                  v-model="currentPageNumberSymbol"
                  :total-rows="totalRowsNumberSymbol"
                  :per-page="perPageNumberSymbol"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  striped
                  hover
                  :items="probemStep2Symbols"
                  :fields="fieldsNumberSymbol"
                  :current-page="currentPageNumberSymbol"
                  :per-page="perPageNumberSymbol"
                  :filter="filterNumberSymbol"
                  :filter-included-fields="filterOnNumberSymbol"
                  :sort-by.sync="sortByNumberSymbol"
                  :sort-desc.sync="sortDescNumberSymbol"
                  :sort-direction="sortDirectionNumberSymbol"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFilteredNumberSymbol"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(symbol_name)="data">
                    <a
                      href="#"
                      class="badge bg-info"
                      @click.prevent="showNumberSymbolsStep(data.item)"
                      >Тэмдэгт: {{ data.item.symbol_name }}:
                      <i class="fas fa-plus-circle"></i
                    ></a>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editExamNumberSymbols(data.item)"
                    >
                      <i class="fas fa-pencil-alt"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExamNumberSymbols(data.item)"
                    >
                      <i class="fas fa-trash-alt"></i
                    ></a>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col v-show="step3Mode">
                <strong v-show="step3Mode"
                  >АЛХАМ 3: Алхам(Symbol step)<b-button
                    variant="success"
                    size="sm"
                    @click.prevent="addExamNumberSymbolStepShow"
                    ><b-icon icon="plus-circle"></b-icon></b-button
                ></strong>
                <b-form v-if="examSetUpForm3">
                  <b-form-group
                    id="input-group-1"
                    label-for="number_name"
                    description="Дараалалсан дугаараар оруулахаа мартав."
                  >
                    <multiselect
                      v-model="addExamNumbersSymbolStepForm.course_id"
                      :multiple="false"
                      deselect-label="Can't remove this value"
                      :custom-label="nameStep3Subject"
                      track-by="course_id"
                      label="course_id"
                      placeholder="Хичээл сонгох"
                      :options="examStep3CoursesSubjects"
                      :searchable="true"
                      :allow-empty="true"
                    >
                      <!-- @select="toggleSelect" -->
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.my_course.my_subject.subject_name }}({{
                            option.my_school_class.full_name
                          }})</strong
                        >
                        -ын/ийн хичээл сонгогдлоо
                      </template>
                    </multiselect>
                    <b-form-select
                      v-model="addExamNumbersSymbolStepForm.symbol_step_name"
                      :options="optionsStep3SymbolSteps"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                    <b-form-select
                      v-model="addExamNumbersSymbolStepForm.confirm_value"
                      :options="optionsConfirmValue"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                    <div class="row">
                      <div class="col-lg-6">
                        <multiselect
                          v-model="addExamNumbersSymbolStepForm.tax_verb_id"
                          label="id"
                          track-by="id"
                          :options="taxanomyLevNameOperations"
                          :custom-label="customLabelTaxVerb"
                          :searchable="true"
                          :allow-empty="true"
                          placeholder="Оюуны үйлийн ай сонгох"
                        ></multiselect>
                      </div>
                      <div class="col-lg-6">
                        <multiselect
                          v-model="addExamNumbersSymbolStepForm.tax_noun_id"
                          label="id"
                          track-by="id"
                          :options="taxanomyKnowledgeNameOperations"
                          :custom-label="customLabelTaxNoun"
                          :searchable="true"
                          :allow-empty="true"
                          placeholder="Мэдлэгийн ай сонгох"
                        ></multiselect>
                      </div>
                    </div>

                    <b-button
                      v-show="numberSymbolStepEditMode != true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="storeExamNumberSymbolStep()"
                      >Хадгалах</b-button
                    >
                    <b-button
                      v-show="numberSymbolStepEditMode != false"
                      type="submit"
                      size="sm"
                      variant="primary"
                      @click.prevent="updateExamNumberSymbolStep()"
                      >Засах</b-button
                    >
                    <b-button
                      type="reset"
                      size="sm"
                      variant="danger"
                      @click.prevent="resetExamNumbersSymbolStepForm()"
                      >Цуцлах</b-button
                    >
                  </b-form-group>
                </b-form>
                <b-pagination
                  v-model="currentPageNumberSymbolStep"
                  :total-rows="totalRowsNumberSymbolStep"
                  :per-page="perPageNumberSymbolStep"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  striped
                  hover
                  :items="probemStep3SymbolsSteps"
                  :fields="fieldsNumberSymbolStep"
                  :current-page="currentPageNumberSymbolStep"
                  :per-page="perPageNumberSymbolStep"
                  :filter="filterNumberSymbolStep"
                  :filter-included-fields="filterOnNumberSymbolStep"
                  :sort-by.sync="sortByNumberSymbolStep"
                  :sort-desc.sync="sortDescNumberSymbolStep"
                  :sort-direction="sortDirectionNumberSymbolStep"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFilteredNumberSymbolStep"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(my_course)="data">
                    <!-- {{ data.item.my_course }} -->
                    <a
                      href="#"
                      class="badge bg-info"
                      v-if="data.item.my_course != null"
                    >
                      {{ data.item.my_course.my_subject.subject_short_name }}
                    </a>
                    <a href="#" class="badge bg-info" v-else>
                      Хичээл бүртгэгдээгүй
                    </a>
                  </template>
                  <template #cell(symbol_step_name)="data">
                    <a href="#" class="badge bg-info"
                      >Алхам: {{ data.item.symbol_step_name }}:
                      <i class="fas fa-plus-circle"></i
                    ></a>
                  </template>
                  <template #cell(confirm_value)="data">
                    <a href="#" class="badge bg-info"
                      >Оноо: {{ data.item.confirm_value }}:
                      <i class="fas fa-plus-circle"></i
                    ></a>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editExamNumberSymbolStep(data.item)"
                    >
                      <i class="fas fa-pencil-alt"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExamNumberSymbolStep(data.item)"
                    >
                      <i class="fas fa-trash-alt"></i
                    ></a>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
      </div>
    </b-modal>
    <!-- Шалгалтанд суралцагч бүртгэх -->
    <b-modal
      size="xl"
      id="modal-exam-add-student"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Шалгалтанд суралцагч бүртгэх"
      class="modal fade"
      hide-footer
      @hidden="resetModalExamAddStudent"
    >
      <div class="card">
        <div class="card-header">
          <b-row class="text-center">
            <b-col cols="8">
              <multiselect
                v-model="selectSchoolClassID"
                :options="getCoursesSchoolClasses"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Анги сонгоно уу?"
                label="course_id"
                track-by="course_id"
                :custom-label="schoolClassLabel"
                :show-labels="false"
                :preselect-first="true"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.my_course.my_school_class.full_name }} анги
                    сонгогдлоо</span
                  ></template
                >
              </multiselect>
            </b-col>
            <b-col cols="4"
              ><b-button
                :disabled="selectSchoolClassID == ''"
                variant="success"
                size="sm"
                @click.prevent="getMySchoolClassStudents"
                >Ангийн суралцагч дуудах <i class="fas fa-users"></i></b-button
            ></b-col>
          </b-row>
        </div>
        <div class="modal-body">
          <b-overlay :show="examAddStudentLoaded" rounded="sm">
            <b-row class="text-center">
              <div class="col-md-12">
                <!-- general form elements -->
                <div class="card card-info">
                  <div class="card-header">
                    <h3 class="card-title">Шалгалтанд суралцагч бүртгэх</h3>
                  </div>
                  <!-- /.card-header -->
                  <!-- form start -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <b-card-group deck>
                          <b-card header="Шалгалтанд бүртгэгдсэн">
                            <b-list-group>
                              <b-list-group-item>
                                <h6>
                                  <b-badge
                                    v-for="enrolledExamStudent in enrolledExamAddStudents"
                                    :key="enrolledExamStudent.id"
                                    variant="info"
                                  >
                                    {{
                                      enrolledExamStudent.student_last_name[0]
                                    }}.
                                    {{ enrolledExamStudent.student_name }}({{
                                      enrolledExamStudent.full_name
                                    }}-анги)
                                    <b-icon
                                      icon="x-circle"
                                      scale="1"
                                      variant="danger"
                                      @click.prevent="
                                        removeEnroleStudent(enrolledExamStudent)
                                      "
                                    ></b-icon>
                                  </b-badge>
                                </h6>
                              </b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </b-card-group>
                      </div>
                      <div class="col-md-6">
                        <b-card-group deck>
                          <b-card header="Шалгалтанд бүртгэгдээгүй">
                            <b-list-group>
                              <b-list-group-item>
                                <h6>
                                  <b-badge
                                    v-for="enrolledExamStudentRemoved in enrolledExamAddStudentsRemoved"
                                    :key="enrolledExamStudentRemoved.id"
                                    variant="info"
                                    >{{
                                      enrolledExamStudentRemoved
                                        .student_last_name[0]
                                    }}.
                                    {{
                                      enrolledExamStudentRemoved.student_name
                                    }}({{
                                      enrolledExamStudentRemoved.full_name
                                    }}
                                    анги)
                                    <b-icon
                                      icon="x-circle"
                                      scale="1"
                                      variant="danger"
                                      @click.prevent="
                                        reAddEnroleStudent(
                                          enrolledExamStudentRemoved
                                        )
                                      "
                                    ></b-icon>
                                  </b-badge>
                                </h6>
                              </b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </b-card-group>
                      </div>
                    </div>
                    <div>
                      <label class="typo__label"
                        >Суралцагчийн нэрээр хайлт хийх / дээр нь дарснаар
                        суралцагч сонгогдоно:
                        {{ schoolclassessstudents.length }}</label
                      >
                      <b-button
                        variant="outline-info"
                        class="mb-3"
                        @click="selectAllStudents"
                        >Бүх суралцагч сонгох</b-button
                      >
                      <multiselect
                        v-model="enrolledStudents.student_id"
                        :options="schoolclassessstudents"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Нэрээр сонголт хийнэ үү?"
                        label="profile_user_id"
                        track-by="profile_user_id"
                        :custom-label="customLabel"
                        :show-labels="false"
                        :preselect-first="true"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, search, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} Сурагч бүртгэхээр
                            сонголоо</span
                          ></template
                        >
                      </multiselect>
                      <pre
                        class="language-json"
                      ><code>{{ enrolledStudents.student_id.name  }}</code></pre>
                      <b-button
                        variant="outline-primary"
                        @click="enrollExamStudents"
                        >Суралцагч бүртгэх</b-button
                      >
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </b-row>
          </b-overlay>
        </div>
      </div>
    </b-modal>

    <!-- Шалгалтын форм  -->
    <b-sidebar
      id="sidebar-exam-form"
      title="ШАЛГАЛТЫН ФОРМ"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <!-- v-if="examInfo" -->
      <!-- :hidden="hideSidebar"
      :shown="showSidebar" -->
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Шалгалтын нэр:
            <strong>
              {{ examInfo.name }}
            </strong>
            <span class="ml-5"
              >Шалгалтын төрөл:
              <strong>
                {{ examInfo.exam_type }}
              </strong></span
            >
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-header">
            <strong>
              <b-row>
                <b-col>
                  <multiselect
                    v-model="schoolClass"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="nameSchoolClass"
                    placeholder="Сонголт хийх"
                    :options="myStudentsCourseSchoolClasses"
                    :searchable="true"
                    :allow-empty="false"
                    :hide-selected="false"
                    :preselect-first="true"
                  >
                    <!-- @select="toggleSelect" -->
                    <template slot="singleLabel" slot-scope="{ option }"
                      >{{ option.my_school_class["full_name"] }}
                      анги сонгогдлоо
                    </template>
                  </multiselect></b-col
                >
                <b-col
                  ><b-button
                    variant="outline-primary"
                    @click="schoolClassGetExams()"
                    >Ангиар шүүж харах</b-button
                  ></b-col
                >
                <b-col
                  ><b-button
                    variant="outline-primary"
                    @click="erembelekh(examInfoNumberSymbolStepStudents)"
                    >Онооны дарааллаар эрэмбэлэх</b-button
                  ></b-col
                >
              </b-row>
            </strong>
          </div>
          <div class="card-body table-responsive">
            <b-overlay :show="examIsLoaded" rounded="sm">
              <table id="cp_table" class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Дугаар
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(number, index) in examInfoNumber"
                      :key="index"
                      :colspan="countSteps(number.my_symbol)"
                    >
                      {{ number.number_name }}
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      rowspan="4"
                    >
                      Нийт
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      rowspan="5"
                    >
                      Дундаж
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      rowspan="4"
                    >
                      %
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      rowspan="5"
                    >
                      Үнэлгээ
                    </td>
                  </tr>
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Тэмдэг
                    </td>
                    <!-- examInfoNumber: [],
      examInfoNumberSymbol: [],
      examInfoNumberSymbolStep: [], -->
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(symbol, index) in examInfoNumberSymbol"
                      :key="index"
                      :colspan="countSymbol(symbol.my_symbol_step)"
                    >
                      {{ symbol.symbol_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Алхам
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in examInfoNumberSymbolStep"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      {{ step.symbol_step_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Хичээл
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in examInfoNumberSymbolStep"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week"
                      .my_course_of_exam.my_subject.subject_short_name -->
                      <span v-if="step.my_course_of_exam != null">
                        <b-badge
                          variant="light"
                          v-if="step.my_course_of_exam.my_subject != null"
                          >{{
                            step.my_course_of_exam.my_subject.subject_short_name
                          }}</b-badge
                        >
                        <b-badge variant="light" v-else
                          >Хичээл бүртгээгүй</b-badge
                        >
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Оноо
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in examInfoNumberSymbolStep"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      <b-badge variant="light" style="width: 5rem">{{
                        step.confirm_value
                      }}</b-badge>
                    </td>
                    <td>{{ sumConfirmValues }}</td>
                    <td>100%</td>
                  </tr>

                  <tr
                    v-for="(student, index) in examInfoNumberSymbolStepStudents"
                    :key="index"
                  >
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      <b-badge
                        href="#"
                        variant="primary"
                        @click.prevent="studChart(student)"
                      >
                        {{ student.student_last_name[0]
                        }}{{ student.student_last_name[1] }}.
                        {{ student.student_name }}
                        <span v-if="student.student_migration_year">
                          ({{
                            student.student_migration_year.my_school_class_info
                              .full_name
                          }})</span
                        >
                      </b-badge>
                    </td>
                    <td
                      v-for="value in student.my_open_exams"
                      :key="value.id"
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                    >
                      <b-form @change="onStoreExamStepValue($event, value)">
                        <b-form-group id="input-group-1" label-for="input-1">
                          <b-form-input
                            :id="`Даалгавар-${value.id}`"
                            :name="`Даалгавар-${value.id}`"
                            type="number"
                            :value="value.my_value"
                            :placeholder="'Value ' + (index + value.id)"
                            :max="value.confirm_value"
                          >
                            <!-- v-validate="{
                              required: true,
                              max: value.confirm_value,
                            }" -->
                            <!-- :state="validateState(`Даалгавар-${value.id}`)" -->
                          </b-form-input>
                          <!-- <ValidationProvider
                            :rules="`max:${parseFloat(value.confirm_value)}`"
                            v-slot="{ errors }"
                          >
                            <span>{{ errors[0] }}</span>
                          </ValidationProvider> -->
                          <!-- <validation-provider
                            name="attStudent.temdeglel"
                            :rules="value.confirm_value"
                          >
                            <template v-slot="{ errors }">
                              <b-form-input
                                id="input-1"
                                type="number"
                                placeholder="Даалгаврын оноо"
                                required
                                :value="value.my_value"
                              ></b-form-input>
                              <span>{{ errors[0] }}</span>
                            </template>
                          </validation-provider> -->
                        </b-form-group>
                      </b-form>
                    </td>
                    <td class="dtr-control sorting_1 text-center">
                      <strong>{{ student.my_open_exams_sum_my_value }} </strong>
                    </td>
                    <td class="dtr-control sorting_1 text-center">
                      <strong
                        >{{
                          (
                            student.my_open_exams_sum_my_value /
                            student.my_open_exams.length
                          ).toFixed(1)
                        }}
                      </strong>
                    </td>
                    <td class="dtr-control sorting_1 text-center">
                      <strong
                        >{{
                          unelgeeEveryStudent(
                            student.my_open_exams_sum_my_value,
                            sumConfirmValues
                          )
                        }}
                        %
                      </strong>
                      <!-- (
                            student.my_open_exams_sum_my_value /
                            sumConfirmValues
                          ).toFixed(1) * 100 -->
                    </td>
                    <td class="dtr-control sorting_1 text-center">
                      <strong
                        >{{
                          symbolGradeCheck(
                            (
                              student.my_open_exams_sum_my_value /
                              sumConfirmValues
                            ).toFixed(1) * 100
                          )
                        }}
                        %
                      </strong>
                    </td>
                  </tr>
                  <!-- Авах оноо -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Оноо
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in examInfoNumberSymbolStep"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      <b-badge variant="light" style="width: 5rem">{{
                        step.confirm_value
                      }}</b-badge>
                    </td>
                    <td>{{ sumConfirmValues }}</td>
                    <td>100%</td>
                    <td colspan="2">Үнэлгээ</td>
                  </tr>
                  <!-- Нийлбэр -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Нийлбэр
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(sum, index) in totalSums"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      {{ sum }}
                    </td>
                    <td></td>
                    <td></td>
                    <td>A</td>
                    <td>{{ countA.length }}</td>
                  </tr>
                  <!-- Дундаж(0 тооцсон) -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Дундаж(0 тооцсон)
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in stepsAvgValue"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      {{ niilberAvgWithZero(step.niilber, index) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td>B</td>
                    <td>{{ countB.length }}</td>
                  </tr>
                  <!-- 0 тооцсон % -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      0 тооцсон %
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in stepsAvgValue"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      {{ niilberAvgWithZeroPercent(step.niilber, index) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td>C</td>
                    <td>{{ countC.length }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      0 тооцсон %
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in stepsAvgValue"
                      :key="index"
                    >
                      {{ niilberAvgWithZeroPercent(step.niilber) }}
                    </td>
                    <td>{{ sumConfirmValues }}</td>
                    <td>100%</td>
                  </tr> -->
                  <!-- Дундаж(0 тооцоогүй) -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Дундаж(0 тооцоогүй)
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in stepsAvgValue"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      {{ niilberAvgNoneZero(step.niilber, index) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td>D</td>
                    <td>{{ countD.length }}</td>
                  </tr>
                  <!-- 0 тооцоогүй % -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      0 тооцоогүй %
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="(step, index) in stepsAvgValue"
                      :key="index"
                    >
                      <!-- :colspan="step.term_week" -->
                      <!-- {{ niilberAvgNoneZeroPercent(step.niilber, index) }} -->
                    </td>
                    <td></td>
                    <td></td>
                    <td>F</td>
                    <td>{{ countF.length }}</td>
                  </tr>
                  <!-- F -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    ></td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="stepsAvgValue.length"
                    >
                      <!-- v-for="(step, index) in stepsAvgValue"
                      :key="index" -->
                      <!-- {{ niilberAvgNoneZeroPercent(step.niilber) }} -->
                    </td>
                    <td></td>
                    <td></td>
                    <td>Success</td>
                    <td>{{ successCalculate() }}</td>
                  </tr>
                  <!-- Success -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    ></td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="stepsAvgValue.length"
                    ></td>
                    <td></td>
                    <td></td>
                    <td>Quality</td>
                    <td>{{ qualityCalculate() }}</td>
                  </tr>
                  <!-- Quality -->
                  <tr>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      ...
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    ></td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="stepsAvgValue.length"
                    ></td>
                    <td></td>
                    <td></td>
                    <td>Дундаж</td>
                    <td>{{ averageAllStudentGrades() }}</td>
                  </tr>
                  <!-- Дундаж үнэлгээ -->
                  <!-- Анализ хийж харуулна -->
                  <tr>
                    <td></td>
                    <td></td>
                    <td :colspan="examInfoNumberSymbolStep.length / 2">
                      <h6>Анализ хийж харуулна.</h6>
                      <div id="chart" v-if="chart === true">
                        <apexchart
                          v-if="studentChart"
                          type="bar"
                          height="350"
                          :options="chartOptions"
                          :series="series"
                        ></apexchart>
                      </div>
                    </td>
                    <td :colspan="examInfoNumberSymbolStep.length / 2">
                      <div id="chart" v-if="chart === true">
                        <apexchart
                          type="bar"
                          height="350"
                          :options="chartOptions"
                          :series="seriesPercent"
                        ></apexchart>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, extend } from "vee-validate";
// import { required, max } from "vee-validate/dist/rules";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      yearId: null,
      //   Шалгалтын форм
      examIsLoaded: true,
      examInfo: "",
      examInfoNumber: [],
      examInfoNumberSymbol: [],
      examInfoNumberSymbolStep: [],
      examInfoNumberSymbolStepStudents: [],
      examNumberCount: 1,
      //Нээлттэй шалгалтын хэсэг
      exams: [],
      examsInviteOtherTeachers: [],
      examLoaded: true,
      editMode: false,
      chart: false,

      //b-table тохиргоо
      totalRowsExams: 1,
      currentPageExams: 1,
      perPageExams: 25,
      pageOptionsExams: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү олноор харах" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterExams: null,
      filterOnExams: [],
      fieldsActiveExams: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "exams_type_status",
          label: "Загвар",
          sortable: true,
        },
        {
          key: "other_actions",
          label: "Нэмэлт",
          sortable: false,
        },
        {
          key: "flName",
          label: "Овог нэр",
          sortable: true,
        },

        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "department_code",
          label: "БНБ",
          sortable: true,
        },
        {
          key: "exams_type",
          label: "Шалгалтын төрөл",
          sortable: true,
        },
        {
          key: "exams_name",
          label: "Шалгалтын нэр",
          sortable: true,
        },
        {
          key: "exams_calendar_plan_content",
          label: "Шалгалтын агуулга",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      //b-table тохиргоо OpenAndBBZ
      totalRowsExamsOpenAndBBZ: 1,
      currentPageExamsOpenAndBBZ: 1,
      perPageExamsOpenAndBBZ: 25,
      pageOptionsExamsOpenAndBBZ: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү олноор харах" },
      ],
      sortByOpenAndBBZ: "",
      sortDescOpenAndBBZ: false,
      sortDirectionOpenAndBBZ: "asc",
      filterExamsOpenAndBBZ: null,
      filterOnExamsOpenAndBBZ: [],
      fieldsActiveExamsOpenAndBBZ: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "exams_type_status",
          label: "Загвар",
          sortable: true,
        },
        {
          key: "other_actions",
          label: "Нэмэлт",
          sortable: false,
        },
        {
          key: "flName",
          label: "Овог нэр",
          sortable: true,
        },

        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "department_code",
          label: "БНБ",
          sortable: true,
        },
        {
          key: "exams_type",
          label: "Шалгалтын төрөл",
          sortable: true,
        },
        {
          key: "exams_name",
          label: "Шалгалтын нэр",
          sortable: true,
        },
        {
          key: "exams_calendar_plan_content",
          label: "Шалгалтын агуулга",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      teachers: [],
      quarters: [],
      formExamModalTitle: "НЭЭЛТТЭЙ БОЛОН ББЗ ШАЛГАЛТ ҮҮСГЭХ",
      editExamMode: false,
      monthOptions: [
        { value: null, text: "Сар сонгох" },
        { value: "1", text: "1-р сар" },
        { value: "2", text: "2-р сар" },
        { value: "3", text: "3-р сар" },
      ],
      examTypes: [
        { value: "явц", text: "Явц" },
        // { value: "улирал", text: "Улирал" },
        // { value: "жил", text: "Жил" },
      ],
      examTypeStatus: [
        { value: 0, text: "ББЗ цикл" },
        { value: 1, text: "Нээлттэй шалгалт" },
      ],

      form: new Form({
        exams_id: "",
        exams_manager_id: "",
        exams_teacher_id: "",
        exams_course_id: "",
        exams_quarter_id: "",
        exams_name: "",
        exams_type: "",
        exams_type_status: 0,
        exams_calendar_plan_content: "",
        exam_plan_calendar_topics: [],
        exams_description: "",
      }),
      schoolteachers: [],
      teacherCourses: [],

      // Багш нэмэх хэсэг
      selectedDep: 0,
      teacherOptions: [],
      editTeacherMode: false,
      editTeacherOnlyMode: false,
      teacherDepCourseMode: false,
      teacherTypes: [
        { name: "Гишүүн", value: "member" },
        { name: "Ахлах", value: "senior" },
      ],
      formTeacher: new Form({
        id: null,
        teacher_type: "member",
        exam_id: null,
        teachers: [],
        courses: [],
      }),

      examTeachers: [],
      modalTeacherCourses: [],

      //Сэдэв бүртгэх хэсэг
      nowMonth: new Date().getMonth() + 1,
      nowWeekCount: this.getWeekNumOfMonthOfDate(new Date()),
      currentUserId: null,

      useTopicTeachers: [],
      planIsLoaded: true,

      showTopicMode: false,
      showTerms: [],
      showMonths: [],
      showSubjects: [],
      showUnits: [],
      showPlanWeeks: [],
      showTopic: new Form({
        id: null,
        startYear: "",
        finishYear: "",
        subject_id: "",
        subject_name: "",
        cp_status: "",
        grade: "",
        teacher_type: "",
        stage: "",
        totalWeek: 0,
        isMyDepEmp: false,
      }),
      addTopicForExamMode: false,
      addTopicForExamForm: new Form({
        id: null,
        exam_id: null,
        teacher_id: null,
        topicsForExam: [],
      }),

      // Шалгалтын бүтэц тохируулах
      examSetUpLoaded: true,
      examSetUpForm1: false,
      probemStep1Numbers: [],
      fieldsNumber: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "number_name",
          label: "Дугаар",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      totalRowsNumber: 1,
      currentPageNumber: 1,
      perPageNumber: 40,
      pageOptionsNumber: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortByNumber: "",
      sortDescNumber: false,
      sortDirectionNumber: "asc",
      filterNumber: null,
      filterOnNumber: [],
      infoModalNumber: {
        id: "info-modal",
        title: "",
        content: "",
      },
      numberEditMode: false,
      addExamNumbersForm: new Form({
        id: null,
        exam_id: null,
        number_name: null,
      }),
      // Number symbols
      step2Mode: false,
      examSetUpForm2: false,
      probemStep2Symbols: [],
      fieldsNumberSymbol: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "symbol_name",
          label: "Тэмдэглэл",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      totalRowsNumberSymbol: 1,
      currentPageNumberSymbol: 1,
      perPageNumberSymbol: 40,
      pageOptionsNumberSymbol: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortByNumberSymbol: "",
      sortDescNumberSymbol: false,
      sortDirectionNumberSymbol: "asc",
      filterNumberSymbol: null,
      filterOnNumberSymbol: [],
      infoModalNumberSymbol: {
        id: "info-modal",
        title: "",
        content: "",
      },
      numberSymbolEditMode: false,
      optionsStep2Symbols: [
        {
          value: null,
          text: "Тэмдэгт сонгоно уу!",
        },
        { value: "a", text: "Сонголт a" },
        { value: "b", text: "Сонголт b" },
        { value: "c", text: "Сонголт c" },
        { value: "d", text: "Сонголт d" },
        { value: "e", text: "Сонголт e" },
        { value: "f", text: "Сонголт f" },
        { value: "g", text: "Сонголт g" },
        { value: "h", text: "Сонголт h" },
        { value: "i", text: "Сонголт i" },
        { value: "j", text: "Сонголт j" },
        { value: "k", text: "Сонголт k" },
        { value: "l", text: "Сонголт l" },
        { value: "m", text: "Сонголт m" },
        { value: "n", text: "Сонголт n" },
        { value: "o", text: "Сонголт o" },
        { value: "p", text: "Сонголт p" },
        { value: "q", text: "Сонголт q" },
        { value: "r", text: "Сонголт r" },
        { value: "s", text: "Сонголт s" },
        { value: "t", text: "Сонголт t" },
        { value: "u", text: "Сонголт u" },
        { value: "v", text: "Сонголт v" },
        { value: "w", text: "Сонголт w" },
        { value: "x", text: "Сонголт x" },
        { value: "y", text: "Сонголт y" },
        { value: "z", text: "Сонголт z" },
      ],
      addExamNumbersSymbolForm: new Form({
        id: null,
        step1_id: null,
        symbol_name: null,
      }),

      // Алхам 3
      useStep3ExamId: null,
      step3Mode: false,
      examSetUpForm3: false,
      examStep3CoursesSubjects: [],
      probemStep3SymbolsSteps: [],
      fieldsNumberSymbolStep: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "my_course",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "symbol_step_name",
          label: "Алхам",
          sortable: true,
        },
        {
          key: "confirm_value",
          label: "Авах оноо",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      totalRowsNumberSymbolStep: 1,
      currentPageNumberSymbolStep: 1,
      perPageNumberSymbolStep: 40,
      pageOptionsNumberSymbolStep: [
        5,
        10,
        15,
        { value: 100, text: "Show a lot" },
      ],
      sortByNumberSymbolStep: "",
      sortDescNumberSymbolStep: false,
      sortDirectionNumberSymbolStep: "asc",
      filterNumberSymbolStep: null,
      filterOnNumberSymbolStep: [],
      infoModalNumberSymbolStep: {
        id: "info-modal",
        title: "",
        content: "",
      },
      numberSymbolStepEditMode: false,
      optionsStep3SymbolSteps: [
        {
          value: null,
          text: "Алхам сонгоно уу!",
        },
        { value: "i", text: "Алхам i" },
        { value: "ii", text: "Алхам ii" },
        { value: "iii", text: "Алхам iii" },
        { value: "iv", text: "Алхам iv" },
        { value: "v", text: "Алхам v" },
        { value: "vi", text: "Алхам vi" },
        { value: "vii", text: "Алхам vii" },
        { value: "viii", text: "Алхам viii" },
        { value: "ix", text: "Алхам ix" },
        { value: "x", text: "Алхам x" },
      ],
      optionsConfirmValue: [
        {
          value: null,
          text: "Оноо сонгоно уу!",
        },
        { value: "1.00", text: "1 оноо" },
        { value: "2.00", text: "2 оноо" },
        { value: "3.00", text: "3 оноо" },
        { value: "4.00", text: "4 оноо" },
        { value: "5.00", text: "5 оноо" },
        { value: "6.00", text: "6 оноо" },
        { value: "7.00", text: "7 оноо" },
        { value: "8.00", text: "8 оноо" },
        { value: "9.00", text: "9 оноо" },
        { value: "10.00", text: "10 оноо" },
        { value: "11.00", text: "11 оноо" },
        { value: "12.00", text: "12 оноо" },
        { value: "13.00", text: "13 оноо" },
        { value: "14.00", text: "14 оноо" },
        { value: "15.00", text: "15 оноо" },
        { value: "16.00", text: "16 оноо" },
        { value: "17.00", text: "17 оноо" },
        { value: "18.00", text: "18 оноо" },
        { value: "19.00", text: "19 оноо" },
        { value: "20.00", text: "20 оноо" },
        { value: "21.00", text: "21 оноо" },
        { value: "22.00", text: "22 оноо" },
        { value: "23.00", text: "23 оноо" },
        { value: "24.00", text: "24 оноо" },
        { value: "25.00", text: "25 оноо" },
        { value: "26.00", text: "26 оноо" },
        { value: "27.00", text: "27 оноо" },
        { value: "28.00", text: "28 оноо" },
        { value: "29.00", text: "29 оноо" },
        { value: "30.00", text: "30 оноо" },
        { value: "31.00", text: "31 оноо" },
        { value: "32.00", text: "32 оноо" },
        { value: "33.00", text: "33 оноо" },
        { value: "34.00", text: "34 оноо" },
        { value: "35.00", text: "35 оноо" },
        { value: "36.00", text: "36 оноо" },
        { value: "37.00", text: "37 оноо" },
        { value: "38.00", text: "38 оноо" },
        { value: "39.00", text: "39 оноо" },
        { value: "40.00", text: "40 оноо" },
        { value: "41.00", text: "41 оноо" },
        { value: "42.00", text: "42 оноо" },
        { value: "43.00", text: "43 оноо" },
        { value: "44.00", text: "44 оноо" },
        { value: "45.00", text: "45 оноо" },
        { value: "46.00", text: "46 оноо" },
        { value: "47.00", text: "47 оноо" },
        { value: "48.00", text: "48 оноо" },
        { value: "49.00", text: "49 оноо" },
        { value: "50.00", text: "50 оноо" },
        { value: "51.00", text: "51 оноо" },
        { value: "52.00", text: "52 оноо" },
        { value: "53.00", text: "53 оноо" },
        { value: "54.00", text: "54 оноо" },
        { value: "55.00", text: "55 оноо" },
        { value: "56.00", text: "56 оноо" },
        { value: "57.00", text: "57 оноо" },
        { value: "58.00", text: "58 оноо" },
        { value: "59.00", text: "59 оноо" },
        { value: "60.00", text: "60 оноо" },
        { value: "61.00", text: "61 оноо" },
        { value: "62.00", text: "62 оноо" },
        { value: "63.00", text: "63 оноо" },
        { value: "64.00", text: "64 оноо" },
        { value: "65.00", text: "65 оноо" },
        { value: "66.00", text: "66 оноо" },
        { value: "67.00", text: "67 оноо" },
        { value: "68.00", text: "68 оноо" },
        { value: "69.00", text: "69 оноо" },
        { value: "70.00", text: "70 оноо" },
        { value: "71.00", text: "71 оноо" },
        { value: "72.00", text: "72 оноо" },
        { value: "73.00", text: "73 оноо" },
        { value: "74.00", text: "74 оноо" },
        { value: "75.00", text: "75 оноо" },
        { value: "76.00", text: "76 оноо" },
        { value: "77.00", text: "77 оноо" },
        { value: "78.00", text: "78 оноо" },
        { value: "79.00", text: "79 оноо" },
        { value: "80.00", text: "80 оноо" },
        { value: "81.00", text: "81 оноо" },
        { value: "82.00", text: "82 оноо" },
        { value: "83.00", text: "83 оноо" },
        { value: "84.00", text: "84 оноо" },
        { value: "85.00", text: "85 оноо" },
        { value: "86.00", text: "86 оноо" },
        { value: "87.00", text: "87 оноо" },
        { value: "88.00", text: "88 оноо" },
        { value: "89.00", text: "89 оноо" },
        { value: "90.00", text: "90 оноо" },
        { value: "91.00", text: "91 оноо" },
        { value: "92.00", text: "92 оноо" },
        { value: "93.00", text: "93 оноо" },
        { value: "94.00", text: "94 оноо" },
        { value: "95.00", text: "95 оноо" },
        { value: "96.00", text: "96 оноо" },
        { value: "97.00", text: "97 оноо" },
        { value: "98.00", text: "98 оноо" },
        { value: "99.00", text: "99 оноо" },
        { value: "100.00", text: "100 оноо" },
      ],
      addExamNumbersSymbolStepForm: new Form({
        id: null,
        step2_id: null,
        symbol_step_name: null,
        confirm_value: null,
        tax_verb_id: null,
        tax_noun_id: null,
        course_id: null,
      }),

      //   Шалгалтанд суралцагч бүртгэх
      examAddStudentLoaded: true,
      schoolclassessstudents: [],
      enrolledExamAddStudents: [],
      enrolledExamAddStudentsRemoved: [],
      enrolledStudents: new Form({
        exam_id: "",
        student_id: [],
      }),
      getCoursesSchoolClasses: [],
      selectSchoolClassID: "",
      myStudentsCourseSchoolClasses: [],
      schoolClass: null,
      checkExam: "",

      stepsAvgValue: [],

      // Амжилт чанар тооцоолох
      countA: [],
      countB: [],
      countC: [],
      countD: [],
      countF: [],
      success: [],
      quality: [],

      //Taxanomy тохиргооseries
      taxanomyInfo: [],
      taxanomyLevNameOperations: [],
      taxanomyKnowledgeNameOperations: [],

      //График харуулах
      series: [
        {
          name: "Дээд утга",
          data: [],
        },
        {
          name: "Дундаж гүйцэтгэл /0 тооцсон/",
          data: [],
        },
        {
          name: "Дундаж гүйцэтгэл /0 тооцоогүй/",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      seriesPercent: [
        {
          name: "Дундаж гүйцэтгэл % /0 тооцоогүй/",
          data: [],
        },
        {
          name: "Дундаж гүйцэтгэл % /0 тооцсон/",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Боломжит оноонууд",
          },
        },
        fill: {
          opacity: 1,
        },
      },
      studentChart: true,
      totalSums: [],
    };
  },
  components: { Multiselect, ValidationProvider },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter((f) => f.sortable)
    //     .map((f) => {
    //       return { text: f.label, value: f.key };
    //     });
    // },
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
    userDetails() {
      return this.$store.state.user;
    },
    sumConfirmValues() {
      let sum = 0;
      if (this.examInfoNumberSymbolStep) {
        this.examInfoNumberSymbolStep.forEach(
          (el) => (sum = sum + parseFloat(el.confirm_value))
        );
        return sum;
      } else {
        return 0;
      }
    },
  },
  methods: {
    niilber(items, ind) {
      let niil = 0;
      //   console.log(items);
      items.forEach((el) => {
        niil = niil + parseFloat(el);
      });
      //console.log(niil, ind);
      return niil;
    },
    niilberAvgWithZero(items, ind) {
      let niil = 0;
      items.forEach((el) => (niil = niil + parseInt(el)));

      let nAvg =
        (niil /
          (items.length *
            parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"]))) *
        100;
      let numberAvg =
        (parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"]) * nAvg) /
        100;
      return numberAvg.toFixed(1);
    },
    niilberAvgWithZeroPercent(items, ind) {
      let niil = 0;
      items.forEach((el) => {
        niil = niil + parseFloat(el);
      });

      let nAvg =
        (niil /
          items.length /
          parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"])) *
        100;

      return nAvg.toFixed(1);
    },
    niilberAvgNoneZero(items, ind) {
      let niil = 0;
      let checkItemsCount = [];
      items.forEach((el) => {
        if (el != 0) {
          niil = niil + parseFloat(el);
          checkItemsCount.push(el);
        }
      });

      let nAvg = niil / checkItemsCount.length;

      return nAvg.toFixed(1);
    },
    //     niilberAvgNoneZeroPercent(items, ind) {
    //     //   console.log(items, "items");
    //     //   console.log(ind, "index");
    //     //   console.log(this.examInfoNumberSymbolStep);
    //       let niil = 0;
    //       let checkItemsCount = [];
    //       items.forEach((el) => {
    //         if (el == 0) {
    //           niil = niil + parseFloat(el);
    //           checkItemsCount.push(el);
    //         }
    //       });
    //
    //       let nAvg =
    //         (niil /
    //           (this.examInfoNumberSymbolStep.length *
    //             parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"]))) *
    //         100;
    //
    //       return nAvg.toFixed(1);
    //     },
    unelgeeEveryStudent(a, b) {
      let c = 0;
      c = (a / b) * 100;
      return c.toFixed(1);
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return "A+, GPA 4.33 or 4.00";
      } else if (gradeValue >= 93) {
        return "A, GPA 4.00";
      } else if (gradeValue >= 90) {
        return "A-, GPA 3.67";
      } else if (gradeValue >= 87) {
        return "B+, GPA 3.33";
      } else if (gradeValue >= 83) {
        return "B, GPA 3.00";
      } else if (gradeValue >= 80) {
        return "B-, GPA 2.67";
      } else if (gradeValue >= 77) {
        return "C+, GPA 2.33";
      } else if (gradeValue >= 73) {
        return "C, GPA 2.00";
      } else if (gradeValue >= 70) {
        return "C-, GPA 1.67";
      } else if (gradeValue >= 67) {
        return "D+, GPA 1.33";
      } else if (gradeValue >= 63) {
        return "D, GPA 1.00";
      } else if (gradeValue >= 60) {
        return "D-, GPA 0.67";
      } else {
        return "F, GPA 0.00";
      }
    },
    onStoreExamStepValue(event, item) {
      //   console.log("Формын утга: " + event.target.value);
      //   console.log("Тухайн шалгалтын утга: ", item);

      axios
        .post("/teacher/onStoreExamStepValue", {
          value: event.target.value,
          examStepInfo: item,
        })
        .then((res) => {
          //   console.log(res.data);
          this.chart = false;
          this.schoolClassGetExams(item); // Ачаалал өсчөөд байна.
          this.chart = true;
        })
        .catch();
    },
    countSteps(items) {
      //   console.log(items);
      let myCheckSymbolStep = [];
      items.forEach((el) => {
        // console.log("symbol for", el);
        el.my_symbol_step.forEach((el1) => {
          myCheckSymbolStep.push(el1);
          //   let value = [...el1.my_symbol_step];
        });
      });
      return myCheckSymbolStep.length;
    },
    countSymbol(items) {
      //   console.log(items);
      return items.length;
    },
    checkPercent(a, b) {
      let c = (a / b) * 100;
      return c.toFixed(1);
    },
    successCalculate() {
      let a = 0;
      let b = 0;
      a =
        this.countA.length +
        this.countB.length +
        this.countC.length +
        this.countD.length;
      //   console.log(a);
      b =
        this.countA.length +
        this.countB.length +
        this.countC.length +
        this.countD.length +
        this.countF.length;
      //   console.log(b);
      let c = (a / b) * 100;
      return c.toFixed(1);
    },
    qualityCalculate() {
      let d = 0;
      let e = 0;
      d = this.countA.length + this.countB.length;
      //   console.log(d);
      e =
        this.countA.length +
        this.countB.length +
        this.countC.length +
        this.countD.length +
        this.countF.length;
      //   console.log(e);
      let f = (d / e) * 100;
      return f.toFixed(1);
    },
    averageAllStudentGrades() {
      //   console.log(this.examInfoNumberSymbolStepStudents);
      let c = [];
      this.examInfoNumberSymbolStepStudents.forEach((student) => {
        c.push(
          parseFloat(
            this.unelgeeEveryStudent(
              student.my_open_exams_sum_my_value,
              this.sumConfirmValues
            )
          )
        );
      });
      //   console.log(c);

      let niilBer = c.reduce((d, e) => d + e, 0);
      //   console.log(niilBer);
      let avgNiitToo = niilBer / c.length;
      //   console.log(avgNiitToo);
      return avgNiitToo.toFixed(1);
    },
    // Шалгалтанд дүн бүртгэх
    schoolClassGetExams() {
      this.giveToExamsStudentValue(this.checkExam);
    },
    giveToExamsStudentValue(item) {
      // console.log(item, "item");
      //   this.enrolledStudents.exam_id = item.exams_id;
      this.examIsLoaded = false;
      this.checkExam = item;
      axios
        .post("/teacher/giveToExamsStudentValue", {
          item,
          schoolClass: this.schoolClass,
          selectExamYearID: this.yearId,
        })
        .then((res) => {
          // console.log(res.data.exams, "res exams");
          // console.log(res.data.exams[0], "res exams[0]");
          // console.log(res.data.exams[0].my_number, "res exams[0].number");
          this.examInfo = res.data.exams[0];
          this.examInfoNumber = this.examInfo.my_number;
          //   console.log("Number", this.examInfoNumber);
          let myCheckSymbol = [];
          this.examInfoNumber.forEach((el) => {
            // console.log(el);
            el.my_symbol.forEach((el1) => {
              myCheckSymbol.push(el1);
            });
          });
          //   console.log("Check symbol", myCheckSymbol);
          this.examInfoNumberSymbol = myCheckSymbol;
          //   console.log("Number symbol", this.examInfoNumberSymbol);
          let myCheckSymbolStep = [];
          this.examInfoNumberSymbol.forEach((el) => {
            // console.log("symbol for", el);
            el.my_symbol_step.forEach((el1) => {
              myCheckSymbolStep.push(el1);
              //   let value = [...el1.my_symbol_step];
            });
          });
          //   console.log(myCheckSymbolStep);
          this.examInfoNumberSymbolStep = myCheckSymbolStep;
          // console.log(
          //   this.examInfoNumberSymbolStep[0].my_course_of_exam.my_subject
          //     .subject_short_name
          // );
          let myCheckSymbolStepStudent = [];

          let students = [];

          //   тохиргоо хийсэн суралцагчдыг шүүж гаргах
          let filterStudent = res.data.studentExamInfos;

          let allStudents = res.data.students;

          allStudents.forEach((el1) => {
            // console.log(el1.user_id);
            filterStudent.forEach((el2) => {
              //   console.log(el2.student_id);
              if (el1.user_id == el2.student_id) {
                students.push(el1);
              }
            });
          });
          //   console.log(students);

          this.examInfoNumberSymbolStep.forEach((el) => {
            el.my_students_of_step.forEach((el1) => {
              myCheckSymbolStepStudent.push(el1);
            });
          });

          students.forEach((el2) => {
            el2.exam_info = [];
          });
          //   console.log(students);
          students.forEach((el2) => {
            // console.log("el2", el2.student_id);
            myCheckSymbolStepStudent.forEach((el) => {
              //   console.log("el", el.student_id);
              if (el2.student_id == el.student_id) {
                // console.log("el2 =>" + el2.student_id, "el =>" + el.student_id);
                el2.exam_info.push(el);
              }
            });
          });

          this.examInfoNumberSymbolStepStudents = students;
          this.myStudentsCourseSchoolClasses = res.data.examsSchoolClasses;
          //   console.log(this.examInfoNumberSymbolStepStudents);

          //   console.log("Number symbol step", this.examInfoNumberSymbolStep);
          //   studentExamInfos
          let myAvgValue = [];

          //   Датаг хоослох
          this.series[0]["data"] = [];
          this.series[1]["data"] = [];
          this.series[2]["data"] = [];
          this.chartOptions.xaxis.categories = [];
          this.seriesPercent[0]["data"] = [];
          this.seriesPercent[1]["data"] = [];
          this.chart = false;

          this.totalSums = [];

          this.examInfoNumberSymbolStep.forEach((alkham) => {
            this.totalSums.push(alkham.my_students_step_sum);
            let examValueObj = { id: alkham.id, niilber: [] };
            this.examInfoNumberSymbolStepStudents.forEach((student) => {
              student.my_open_exams.forEach((myExam) => {
                if (alkham.id == myExam.step3_id) {
                  //   myAvgValue.forEach((el2) => {
                  // if (el2.id == myExam.step3_id) {
                  examValueObj.niilber.push(parseFloat(myExam.my_value));
                  // }
                  //   });
                }
              });
            });
            myAvgValue.push(examValueObj);
            this.series[0]["data"].push(parseInt(alkham.confirm_value));
            this.chartOptions.xaxis.categories.push(alkham.symbol_step_name);
          });
          //   console.log(myAvgValue);
          //   console.log("totalSums", this.totalSums);

          this.stepsAvgValue = myAvgValue;
          this.stepsAvgValue.forEach((items, ind) => {
            let niil = 0;
            let niilCount = 0;
            let niilZero = 0;
            items.niilber.forEach((el) => {
              if (el != 0) {
                niilZero = niilZero + parseFloat(el);
                niilCount++;
              }
              niil = niil + parseFloat(el);
            });
            let nAvg = niil / items.niilber.length;
            let nZeroAvg = niilZero / niilCount;
            let nAvgPercent =
              (niil /
                items.niilber.length /
                parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"])) *
              100;
            let nZeroAvgPercent =
              (niilZero /
                niilCount /
                parseInt(this.examInfoNumberSymbolStep[ind]["confirm_value"])) *
              100;
            this.series[1]["data"].push(parseFloat(nAvg.toFixed(1)));
            this.series[2]["data"].push(parseFloat(nZeroAvg.toFixed(1)));
            this.seriesPercent[0]["data"].push(
              parseFloat(nZeroAvgPercent.toFixed(1))
            );
            this.seriesPercent[1]["data"].push(
              parseFloat(nAvgPercent.toFixed(1))
            );
          });
          this.chart = true;

          this.countA = [];
          this.countB = [];
          this.countC = [];
          this.countD = [];
          this.countF = [];
          let sum = 0;
          if (this.examInfoNumberSymbolStep) {
            this.examInfoNumberSymbolStep.forEach((el) => {
              sum = sum + parseFloat(el.confirm_value);
            });
          }
          //   console.log(this.examInfoNumberSymbolStep);
          //   console.log("Нийлбэр: " + " " + sum);

          this.examInfoNumberSymbolStepStudents.forEach((el) => {
            // console.log(el.my_open_exams);
            // console.log(el.my_open_exams_sum_my_value / sum);
            let percent = 0;
            percent = (el.my_open_exams_sum_my_value / sum) * 100;

            if (percent > 89.5) {
              this.countA.push(percent);
            } else if (percent > 79.5) {
              this.countB.push(percent);
            } else if (percent > 69.5) {
              this.countC.push(percent);
            } else if (percent > 59.5) {
              this.countD.push(percent);
            } else {
              this.countF.push(percent);
            }
          });

          this.examIsLoaded = false;
        })
        .catch((err) => {
          //   console.log(err.response.data);
        });
      //   this.$bvModal.show("modal-exam-add-student");
    },
    // Эрэмбэлэх
    erembelekh(value) {
      let too = value;
      too.sort(
        (a, b) =>
          parseFloat(b.my_open_exams_sum_my_value) -
          parseFloat(a.my_open_exams_sum_my_value)
      );
      this.examInfoNumberSymbolStepStudents = [];
      this.examInfoNumberSymbolStepStudents = too;
    },
    studChart(student) {
      this.studentChart = false;
      // let seriesCount = this.series.length;
      //   this.series[3].name = "";
      //   this.series[3].data = [];
      //   if (student) {
      // this.series[seriesCount] = {
      //   name: student.student_last_name[1] + "." + student.student_name,
      //   data: [],
      // };
      // }
      // this.series[seriesCount].data.push(parseFloat(exam.my_value).toFixed(1));

      if (student) {
        this.series[3].name =
          student.student_last_name[1] + "." + student.student_name;
        student.my_open_exams.forEach((exam) => {
          this.series[3].data.push(parseFloat(exam.my_value).toFixed(1));
        });
      }
      this.studentChart = true;
    },
    // Шалгалтанд суралцагч бүртгэх
    moreSetUpForExamsEnroll(item) {
      //   console.log(item);
      this.enrolledStudents.exam_id = item.exams_id;
      axios
        .post("/teacher/coursesSchoolClasses", { item })
        .then((res) => {
          //   console.log(res.data);
          this.getCoursesSchoolClasses = res.data.coursesSchoolClass;
          this.examAddStudentLoaded = false;
        })
        .catch((err) => {
          //   console.log(err.response.data);
        });
      this.$bvModal.show("modal-exam-add-student");
    },
    getMySchoolClassStudents() {
      axios
        .post("/teacher/getMySchoolClassStudents", {
          schoolClass: this.selectSchoolClassID,
        })
        .then((res) => {
          //   console.log(res.data);
          this.schoolclassessstudents = res.data.students;
          this.enrolledExamAddStudents = res.data.enrolledExamAddStudents;
          this.enrolledExamAddStudentsRemoved =
            res.data.enrolledExamAddStudentsRemoved;
        })
        .catch();
    },
    resetModalExamAddStudent() {
      this.schoolclassessstudents = [];
      this.enrolledExamAddStudents = [];
      this.enrolledExamAddStudentsRemoved = [];
      this.enrolledStudents.reset();
      this.getCoursesSchoolClasses = [];
      this.selectSchoolClassID = "";
    },
    selectAllStudents() {
      this.enrolledStudents.student_id = this.schoolclassessstudents;
    },
    customLabel({ name, profile_student_last_name }) {
      return `${profile_student_last_name[0]}. ${name}`;
    },
    schoolClassLabel({ my_course }) {
      return `${my_course.my_school_class.full_name}`;
    },
    enrollExamStudents() {
      this.enrolledStudents
        .post("/teacher/enrollExamStudents")
        .then((res) => {
          //   console.log(res.data);

          if (res.data.info.code === "success") {
            Swal.fire("Бүртгэлээ!", res.data.info.message, "success");
            // Fire.$emit("loadExams");
            this.getMySchoolClassStudents();
          } else if (res.data.info.code === "errors") {
            this.$toastr.e(res.data.info.message);
          }

          this.$toastr.s(
            "Суралцагчдыг шалгалтанд амжилттай бүртгэлээ.",
            "Амжилттай"
          );
          //   this.schoolclassessstudents = [];
          //   this.enrolledStudents.student_id = [];
          //   Fire.$emit("removeFromGradeStudent");
          //   this.form.school_class_id =
          //     res.data.allEditData[0].profile_school_class_id;
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчдыг шалгалтанд бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    removeEnroleStudent(enrolledExamStudent) {
      // removeFromGradeStudent
      // console.log(enrolledExamStudent);
      axios
        .post(`/teacher/removeFromExamStudent`, {
          enrolledExamStudent: enrolledExamStudent,
        })
        .then((response) => {
          this.$toastr.s(
            "Суралцагчыг тухайн улирлаас амжилттай хаслаа." +
              response.data.info.message,
            response.data.info.code
          );
          this.getMySchoolClassStudents();
          //   Fire.$emit("removeFromGradeStudent");
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлээс суралцагч хасч чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    reAddEnroleStudent(enrolledExamStudent) {
      // removeFromGradeStudent
      // console.log(enrolledExamStudent);
      axios
        .post(`/teacher/reAddFromExamStudent`, {
          enrolledExamStudent: enrolledExamStudent,
        })
        .then((response) => {
          this.$toastr.s(
            "Суралцагчыг тухайн улирлаас амжилттай хаслаа." +
              response.data.info.message,
            response.data.info.code
          );
          this.getMySchoolClassStudents();
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлээс суралцагч хасч чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },

    //Шалгалт авах
    onFilteredExams(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsExams = filteredItems.length;
      this.currentPageExams = 1;
    },
    onFilteredExamsOpenAndBBZ(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsExamsOpenAndBBZ = filteredItems.length;
      this.currentPageExamsOpenAndBBZ = 1;
    },
    getAllBBZandOpenExamsTeacher() {
      // console.log(page);
      axios
        .post("/teacher/getAllBBZandOpenExamsTeacher", {
          year: this.yearId != null ? this.yearId.id : null,
        })
        .then((res) => {
          //   console.log(response);
          this.exams = res.data.exams;
          this.examsInviteOtherTeachers = res.data.examsInviteOtherTeachers;
          this.teacherCourses = res.data.courses;
          this.totalRowsExams = this.exams.length;
          this.totalRowsExamsOpenAndBBZ = this.examsInviteOtherTeachers.length;
          this.teachers = res.data.teachers;
          this.quarters = res.data.quarters;
          this.taxanomyInfo = res.data.taxanomyInfo;
          this.taxanomyLevNameOperations = res.data.taxanomyLevNameOperations;
          this.taxanomyKnowledgeNameOperations =
            res.data.taxanomyKnowledgeNameOperations;

          this.examLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getTeacherBBZandOpenCourses() {
      this.form.exams_course_id = "";
      this.form.exams_quarter_id = "";
      this.form.exams_name = "";
      this.form.exams_type = "";
      this.form.exams_type_status = "";
      this.form.exams_calendar_plan_content = "";
      this.form.exams_description = "";
      //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
      if (this.form.exams_teacher_id !== "") {
        axios
          .post("/teacher/getTeacherBBZandOpenCourses", {
            teacher: this.form.exams_teacher_id,
          })
          .then((res) => {
            this.teacherCourses = res.data.courses;
            // console.log(this.teacherCourses);
            // this.quarters = res.data.quarters;
            // console.log(this.quarters);
          });
      } else {
        this.teacherCourses = [];
      }
    },

    addProblemExam() {
      this.form.reset();
      this.$bvModal.show("modal-create-exam");
    },
    createSchoolExam() {
      //   console.log("create");
      this.form
        .post("/teacher/examCreateTeacher")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadExams");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editExam(getAllTeachersExam) {
      //   console.log(getAllTeachersExam);
      //exams_teacher_id, exams_type_status,
      //   this.editMode = false;
      //   exams_teacher_id -аар филтер хийнэ.
      //   this.teacherCourses = [];
      //   this.quarters = [];
      //   this.schoolteachers = [];
      this.form.exams_id = getAllTeachersExam.exams_id;
      let selectTeach = this.teachers.filter(
        (el) => el.employee_user_id === getAllTeachersExam.exams_teacher_id
      );
      //   console.log("Filter teacher: " + selectTeach);
      this.form.exams_teacher_id = selectTeach[0];
      //   console.log(this.form.exams_teacher_id);

      //багшид хамааралтай бүх хичээлийг татаж авах. Тухайн шалгалтын course_id-аар exams_course_id-д утга онооно.
      axios
        .post("/teacher/getCourseTeacherEdit", { getAllTeachersExam })
        .then((res) => {
          let tCourses = res.data.myCourses;
          //   console.log(tCourses);
          //   this.form.exams_course_id
          //Болж байна.
          //   console.log(getAllTeachersExam, this.teacherCourses);
          let courseFilter = tCourses.filter(
            (el) => el.id == getAllTeachersExam.exams_course_id
          );
          //   console.log(courseFilter);
          this.form.exams_course_id = courseFilter[0];
          //   console.log(this.form.exams_course_id);

          //   this.quarters = res.data.quarters;
          let quarterFilter = this.quarters.filter(
            (el) => el.quarter_id == getAllTeachersExam.exams_quarter_id
          );
          //   console.log(quarterFilter);
          this.form.exams_quarter_id = quarterFilter[0];
          //   console.log(this.form.exams_quarter_id);
          //   console.log(getAllTeachersExam.exams_name);
          let checkName = getAllTeachersExam.exams_name.split("(")[0];
          //   console.log(checkName);
          this.form.exams_name = checkName;
          let editExamTypeStatus = this.examTypeStatus.filter(
            (el) => el.value == getAllTeachersExam.exams_type_status
          );
          this.form.exams_type_status = editExamTypeStatus[0];

          let editExamType = this.examTypes.filter(
            (el) => el.value == getAllTeachersExam.exams_type
          );
          this.form.exams_type = editExamType[0];

          this.form.exams_calendar_plan_content =
            getAllTeachersExam.exams_calendar_plan_content;
          this.form.exams_description = getAllTeachersExam.exams_description;

          this.editMode = true;

          //   this.schoolteachers = res.data.schoolteachers;
        })
        .catch((err) => {
          //   console.log(err);
        });

      this.$bvModal.show("modal-create-exam");
    },
    updateSchoolExam() {
      //   console.log("udpate");
      this.form
        .put("/teacher/updateOpenAndBBZExamTeacher")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadExams");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editMode = false;
    },
    cancelCreate() {
      this.form.reset();
      //   this.teacherCourses = [];
      this.$bvModal.hide("modal-create-exam");
    },

    // Шалгалтанд багш бүртгэх
    moreTeacherForExams(exam) {
      //   console.log(exam);
      this.formTeacher.reset();
      //   this.formTeacher.fill(exam);
      //this.isCanAdd = plan.isMyDepEmp;
      this.examId = exam.exams_id;
      this.formTeacher.exam_id = exam.exams_id;
      this.getExamTeachers();
      this.modalChangeTeacherList();
      this.$bvModal.show("modal-exam-add-teacher");
    },

    // Шалгалтанд хамаарах бүх багш нарыг авна
    getExamTeachers() {
      axios
        .get("/teacher/getExamTeachers/" + this.examId)
        .then((response) => {
          this.examTeachers = response.data.examTeachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList() {
      //   console.log("Мэргэжлийн баг солих үед ажиллана.");
      // this.formTeacher.teachers = [];
      this.formTeacher.teachers = [];
      this.teacherDepCourseMode = false;
      axios
        .get("/teacher/getTeacherList")
        .then((response) => {
          this.teacherOptions = response.data.teachers;
          this.teacherDepCourseMode = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      // this.$toastr.e("Мэдээллээ шалгана уу!", "Алдаа гарав.");
    },
    //Багш нар сонгогдсон тохиолдолд тухайн онд хамаарагдах бүх багш нарын орж буй хичээлүүдийг авчирах
    modalChangeTeacherCourses() {
      this.modalTeacherCourses = [];
      //   console.log(e, "Багш сонгох үед ажиллана.");
      //   console.log(this.formTeacher.teachers + "Сонгогдсон багш нар.");
      let changeTeachers = this.formTeacher.teachers;
      //   console.log(changeTeachers);
      changeTeachers.forEach((element) => {
        element.my_courses.forEach((el) => {
          this.modalTeacherCourses.push(el);
        });
      });
      //   console.log(this.modalTeacherCourses.length);
    },
    addExamTeacher() {
      this.formTeacher.cp_id = this.form.id;
      this.formTeacher
        .post("/teacher/addOpenAndBBZExamTeacher")
        .then((response) => {
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          // this.formTeacher.exam_id = ;
          this.formTeacherReset();
          Fire.$emit("getExamTeachers");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    editExamTeacher(teacher) {
      //   console.log(this.formTeacherReset);

      //   this.formTeacher.reset();
      this.formTeacherReset();
      axios
        .get("/teacher/getExamTeachers/" + this.examId)
        .then((response) => {
          let checkExamTeachers = [];
          this.formTeacher.teachers = [];
          checkExamTeachers = response.data.examTeachers;
          //   console.log(checkExamTeachers);
          checkExamTeachers.forEach((element) => {
            this.teacherOptions.forEach((el) => {
              if (
                el.emp_user_id == element.teacher_id &&
                element.teacher_type !== "owner"
              ) {
                this.formTeacher.teachers.push(el);
              }
            });
          });
          //   console.log(this.formTeacher.teachers);
          this.modalChangeTeacherCourses();
          checkExamTeachers.forEach((elcheck) => {
            //   console.log(e, "Багш сонгох үед ажиллана.");
            //   console.log(this.formTeacher.teachers + "Сонгогдсон багш нар.");
            let changeTeachers = this.formTeacher.teachers;
            // console.log(changeTeachers);
            changeTeachers.forEach((element) => {
              element.my_courses.forEach((el) => {
                if (elcheck.course_id == el.id) {
                  this.formTeacher.courses.push(el);
                }
              });
            });
            this.editTeacherMode = true;
            this.editTeacherOnlyMode = false;
          });
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      //   this.formTeacher.fill(teacher);
    },
    updateExamTeacher() {
      this.action = "Шалгалтын багшийн мэдээллийг шинэчилж байна...";
      this.formTeacher
        .put(
          "/teacher/updateTeachersOpenAndBBZExam/" + this.formTeacher.exam_id
        )
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formTeacherReset();
          this.editTeacherMode = false;
          //   this.teacherOptions = [];
          Fire.$emit("getExamTeachers");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    editExamOnlyOneTeacher(teacher) {
      //   console.log(teacher);
      this.formTeacherReset();
      axios
        .post("/teacher/getExamCheckOpenAndBBZTeachers", { teacher: teacher })
        .then((response) => {
          let checkExamTeachers = [];
          this.formTeacher.teachers = [];
          checkExamTeachers = response.data.examTeachers;
          checkExamTeachers.forEach((element) => {
            this.teacherOptions.forEach((el) => {
              if (el.emp_user_id == element.teacher_id) {
                this.formTeacher.teachers.push(el);
              }
            });
          });
          this.modalChangeTeacherCourses();
          checkExamTeachers.forEach((elcheck) => {
            //   console.log(e, "Багш сонгох үед ажиллана.");
            //   console.log(this.formTeacher.teachers + "Сонгогдсон багш нар.");
            let changeTeachers = this.formTeacher.teachers;
            // console.log(changeTeachers);
            changeTeachers.forEach((element) => {
              element.my_courses.forEach((el) => {
                if (elcheck.course_id == el.id) {
                  this.formTeacher.courses.push(el);
                }
              });
            });
            this.formTeacher.id = teacher.id;
            this.editTeacherMode = true;
            this.editTeacherOnlyMode = true;
          });
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      //   this.formTeacher.fill(teacher);
    },
    updateExamOnlyOneTeacher() {
      this.action = "Шалгалтын багшийн мэдээллийг шинэчилж байна...";
      this.formTeacher
        .put(
          "/teacher/updateOpenAndBBZExamOnlyOneTeacher/" + this.formTeacher.id
        )
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formTeacherReset();
          this.editTeacherMode = false;
          //   this.teacherOptions = [];
          Fire.$emit("getExamTeachers");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeExamTeacher(teacher) {
      Swal.fire({
        title: "Та шалгалтаас багш хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.formTeacher
            .get("/teacher/removeOpenAndBBZExamTeacher/" + teacher.id)
            .then((response) => {
              Fire.$emit("getExamTeachers");
              this.formTeacherReset();
              //   this.teacherOptions = [];
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    formTeacherReset() {
      this.formTeacher.teacher_type = "member";
      this.formTeacher.teachers = [];
      this.formTeacher.courses = [];
      this.editTeacherMode = false;
      this.editTeacherOnlyMode = false;
    },

    nameSchoolClass({ my_school_class }) {
      return `${my_school_class["full_name"]} анги`;
    },
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    nameCourse({ full_name, subject_name }) {
      return `${full_name}. ${subject_name}`;
    },
    nameQuarter({ quarter_name }) {
      return `${quarter_name}-р улирал`;
    },
    nameExamType({ value }) {
      return `Шалгалтын төрөл: ${value}`;
    },
    nameExamTypeStatus({ text }) {
      return `Шалгалтын онолын загвар: ${text}`;
    },

    //Шалгалтанд багш нэмэх үеийн багш сонголтын нэр
    nameExamTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    //Сонгогдсон багш нарын хичээлүүд
    nameExamTeacherCourses({
      teacher,
      my_subject,
      my_school_class,
      course_type,
    }) {
      return `${teacher.teacher_last_name[0]}. ${teacher.teacher_name} . ${my_school_class.full_name} . ${my_subject.subject_name} . ${course_type.name}`;
    },

    // Сэдэв бүртгэх
    //Одоо явж байгаа 7 хоногийг олно
    getWeekNumOfMonthOfDate(d) {
      let year = d.getFullYear();
      let month = d.getMonth();
      let firstDay = new Date(year, month, 1).getDay();
      return Math.ceil(d.getDate() / 7);
    },
    moreTopicsForExams(teacher) {
      //   console.log(teacher);
      this.getTeachersAndTopics(teacher);

      this.$bvModal.show("modal-exam-add-topics");
      //   this.showTopic.reset();
      //   this.showTopic.fill(plan);
      //   this.getShowCalendar();
    },
    getTeachersAndTopics(teacher) {
      axios
        .post("/teacher/getTeachersPlanOpenAndBBZExam", {
          teacher: teacher,
        })
        .then((res) => {
          //   console.log(res.data);
          this.useTopicTeachers = res.data.teachers;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    getCalPlanTeacher(teacher) {
      //   console.log(teacher);
      this.showTopic.reset();
      this.addTopicForExamForm.reset();
      this.showTopic.fill(teacher.calendar_plan_info);
      this.addTopicForExamForm.exam_id = teacher.exam_id;
      this.addTopicForExamForm.teacher_id = teacher.teacher_id;
      //   console.log(this.showTopic);
      //   console.log(this.addTopicForExamForm);
      this.getShowCalendar(teacher);
    },

    getShowCalendar(teacher) {
      this.planIsLoaded = true;
      axios
        .post("/teacher/getTopics", {
          cp_id: this.showTopic.id,
          teacher: teacher,
        })
        .then((response) => {
          this.showTopic.totalWeek = response.data.totalWeek;
          this.showTopic.startYear = response.data.startYear;
          this.showTopic.finishYear = response.data.finishYear;
          this.showTerms = response.data.terms;
          this.showMonths = response.data.months;
          this.showSubjects = response.data.subjects;
          this.showUnits = response.data.units;
          this.showPlanWeeks = response.data.planWeeks;
          this.showPlanWeeks.forEach((el) => {
            el.topics.forEach((element) => {
              if (element.burtgegdsen === 1) {
                this.addTopicForExamForm.topicsForExam.push(element);
              }
            });
          });
          //   console.log(this.showPlanWeeks);
          this.planIsLoaded = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    resetModalExamAddTopics() {
      this.showTopic.reset();

      this.showTerms = [];
      this.showMonths = [];
      this.showSubjects = [];
      this.showUnits = [];
      this.showPlanWeeks = [];

      this.planIsLoaded = true;
    },
    closeModalExamAddTopics() {
      //   this.resetModalExamAddTopics();
      Fire.$emit("loadExams");
      this.$bvModal.hide("modal-exam-add-topics");
    },

    // Шалгалтанд сэдэв бүртгэх
    addTopicForExam(top) {
      //   console.log(top);
      this.addTopicForExamMode = true;
      let sameId = this.addTopicForExamForm.topicsForExam.findIndex(
        (el) => el.topic_name == top.topic_name
      );
      //   console.log(sameId);

      if (sameId === -1) {
        this.addTopicForExamForm.topicsForExam.push(top);
      } else {
        this.$toastr.e("Сонгогдсон сэдэв байна!", "Анхаарна уу!");
      }
    },
    // жагсаалтаас хасах
    minusTopicForExamForm(top) {
      // console.log(top);

      let indexDelete = this.addTopicForExamForm.topicsForExam.findIndex(
        (el) => el.id == top.id
      );
      //   console.log(indexDelete);
      if (indexDelete === 0) {
        this.addTopicForExamForm.topicsForExam.splice(
          indexDelete,
          indexDelete + 1
        );
      } else {
        this.addTopicForExamForm.topicsForExam.splice(indexDelete, indexDelete);
      }

      //   console.log(
      //     this.addTopicForExamForm.topicsForExam.splice(indexDelete, indexDelete)
      //   );
    },

    storeTopicForExam() {
      //   console.log(this.addTopicForExamForm);
      this.addTopicForExamForm
        .post("/teacher/storeTopicForExam")
        .then((res) => {
          //   console.log(res.data);
          this.$toastr.s(
            "Сонгогдсон сэдэв шалгалтанд амжилттай бүртгэгдлээ!",
            "Амжилттай!"
          );
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    // 1-р алхам
    // Шалгалтын бүтэц тохируулах
    moreSetUpForExams(items) {
      //   console.log(items);
      this.getProblemStep1NumberstExams(items.exams_id);
      this.addExamNumbersForm.exam_id = items.exams_id;
      this.useStep3ExamId = items.exams_id;

      this.$bvModal.show("modal-exam-setup");
    },
    // modal хаах үед ашиглана.
    resetModalExamSetUp() {
      this.examSetUpLoaded = true;
      this.useStep3ExamId = null;

      this.addExamNumbersForm.reset();
      this.addExamNumbersSymbolForm.reset();
      this.addExamNumbersSymbolStepForm.reset();

      this.examSetUpForm1 = false;
      this.examSetUpForm2 = false;
      this.examSetUpForm3 = false;

      this.step2Mode = false;
      this.step3Mode = false;

      this.numberEditMode = false;
      this.numberSymbolEditMode = false;
      this.numberSymbolStepEditMode = false;
    },
    getProblemStep1NumberstExams(exams_id) {
      this.examSetUpLoaded = true;
      axios
        .get("/teacher/getProblemStep1NumberstExams/" + exams_id)
        .then((res) => {
          //   console.log(res.data);
          this.probemStep1Numbers = res.data.examNumbers;
          this.totalRowsNumber = this.probemStep1Numbers.length;
          this.examSetUpLoaded = false;
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    onFilteredNumber(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsNumber = filteredItems.length;
      this.currentPageNumber = 1;
    },
    resetExamNumbersForm() {
      this.addExamNumbersForm.number_name = null;
      this.examSetUpForm1 = false;
      this.numberEditMode = false;
      this.getProblemStep1NumberstExams(this.addExamNumbersForm.exam_id);
    },
    addExamNumbersShow() {
      this.examSetUpForm1 = true;
      this.examSetUpForm2 = false;
      this.examSetUpForm3 = false;
      this.step2Mode = false;
      this.step3Mode = false;
    },

    storeExamNumbers() {
      this.addExamNumbersForm
        .post("/teacher/storeExamNumbers")
        .then((res) => {
          //   console.log(res.data);
          this.resetExamNumbersForm();
          this.$toastr.s("Амжилттай бүртгэлээ", "Амжилттай!");
        })
        .catch((err) => {
          //   console.log(err.response);
          //   console.log(err.response.data.errors);
          let myErr = err.response.data.errors;
          this.$toastr.e(myErr, "Бүртгэж чадсангүй!");
        });
    },
    editExamNumbersForm(number) {
      this.numberEditMode = true;
      this.addExamNumbersForm.fill(number);
      this.addExamNumbersShow();
    },
    updateExamNumbersForm() {
      this.addExamNumbersForm
        .patch("/teacher/updateExamNumbersForm/" + this.addExamNumbersForm.id)
        .then((res) => {
          this.resetExamNumbersForm();
        })
        .catch((err) => console.log(err));
    },
    deleteExamNumbersForm(item) {
      axios
        .delete("/teacher/deleteExamNumbersForm/" + item.id)
        .then((res) => {
          this.resetExamNumbersForm();
          this.examSetUpForm2 = false;
          this.examSetUpForm3 = false;
          this.step2Mode = false;
          this.step3Mode = false;
        })
        .catch((err) => console.log(err));
    },

    // 2-р алхам
    // Тухайн дугаарт харгалзах тэмдэгт нээж харуулах
    showNumberSymbols(item) {
      //   console.log(item);
      this.step2Mode = true;
      this.step3Mode = false;
      this.addExamNumbersSymbolForm.step1_id = item.id;
      this.getProblemNumberSymbols(this.addExamNumbersSymbolForm.step1_id);
    },

    onFilteredNumberSymbol(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsNumberSymbol = filteredItems.length;
      this.currentPageNumberSymbol = 1;
    },
    resetExamNumbersSymbolForm() {
      this.addExamNumbersSymbolForm.symbol_name = null;
      this.examSetUpForm2 = false;
      this.numberSymbolEditMode = false;
    },
    getProblemNumberSymbols(symbolId) {
      axios
        .get("/teacher/getProblemNumberSymbols/" + symbolId)
        .then((res) => {
          this.probemStep2Symbols = res.data.examNumberSymbols;
          //   console.log(res.data);
        })
        .catch((err) => {
          // console.log(err.response)
        });
    },
    addExamNumberSymbolsShow() {
      this.examSetUpForm2 = true;
      this.examSetUpForm3 = false;
      this.step3Mode = false;
    },
    storeExamNumberSymbols() {
      //   this.examSetUpForm2 = true;
      this.addExamNumbersSymbolForm
        .post("/teacher/storeExamNumberSymbols")
        .then((res) => {
          //   console.log(res.data);
          this.getProblemNumberSymbols(this.addExamNumbersSymbolForm.step1_id);
          this.resetExamNumbersSymbolForm();
          this.$toastr.s("Амжилттай бүртгэлээ", "Амжилттай!");
        })
        .catch((err) => {
          // console.log(err.response)
        });
    },
    editExamNumberSymbols(item) {
      this.step3Mode = false;
      this.examSetUpForm3 = false;

      // this.numberSymbolSt
      this.examSetUpForm2 = true;
      this.numberSymbolEditMode = true;
      this.addExamNumbersSymbolForm.fill(item);
    },
    updateExamNumberSymbols() {
      this.addExamNumbersSymbolForm
        .patch(
          "/teacher/updateExamNumberSymbols/" + this.addExamNumbersSymbolForm.id
        )
        .then((res) => {
          this.getProblemNumberSymbols(this.addExamNumbersSymbolForm.step1_id);
          this.resetExamNumbersSymbolForm();
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    deleteExamNumberSymbols(item) {
      this.examSetUpForm3 = false;
      this.step3Mode = false;
      axios
        .delete("/teacher/deleteExamNumberSymbols/" + item.id)
        .then((res) => {
          this.resetExamNumbersSymbolForm();
          this.getProblemNumberSymbols(this.addExamNumbersSymbolForm.step1_id);
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    //3-р алхам Тухайн тэмдэгтэд харгалзах алхам
    showNumberSymbolsStep(item) {
      //   console.log(item);
      this.step3Mode = true;
      this.examSetUpForm3 = true;
      this.addExamNumbersSymbolStepForm.step2_id = item.id;
      this.addExamNumbersSymbolStepForm.course_id = null;
      this.getProblemNumberSymbolSteps(item.id, this.useStep3ExamId);
    },
    nameStep3Subject({ my_course, my_school_class }) {
      return `${my_course.my_subject.subject_name}(${my_school_class.full_name})`;
    },
    getProblemNumberSymbolSteps(step2Id, useStep3ExamId) {
      axios
        .get(
          "/teacher/getProblemNumberSymbolSteps/" +
            step2Id +
            "/" +
            useStep3ExamId
        )
        .then((res) => {
          this.probemStep3SymbolsSteps = res.data.examNumberSymbolSteps;
          this.examStep3CoursesSubjects = res.data.examStep3CoursesSubjects;
          //   console.log(res.data);
        })
        .catch((err) => {
          // console.log(err.response)
        });
    },
    onFilteredNumberSymbolStep(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsNumberSymbolStep = filteredItems.length;
      this.currentPageNumberSymbolStep = 1;
    },
    resetExamNumbersSymbolStepForm() {
      this.examSetUpForm3 = false;
      this.addExamNumbersSymbolStepForm.symbol_step_name = null;
      this.addExamNumbersSymbolStepForm.confirm_value = null;
      this.addExamNumbersSymbolStepForm.course_id = null;
      this.addExamNumbersSymbolStepForm.tax_verb_id = null;
      this.addExamNumbersSymbolStepForm.tax_noun_id = null;

      this.numberSymbolStepEditMode = false;
    },
    addExamNumberSymbolStepShow() {
      this.examSetUpForm3 = true;
    },
    storeExamNumberSymbolStep() {
      //   this.examSetUpForm2 = true;
      this.addExamNumbersSymbolStepForm
        .post("/teacher/storeExamNumberSymbolStep")
        .then((res) => {
          //   console.log(res.data);
          this.getProblemNumberSymbolSteps(
            this.addExamNumbersSymbolStepForm.step2_id,
            this.useStep3ExamId
          );
          this.resetExamNumbersSymbolStepForm();
          this.$toastr.s("Амжилттай бүртгэлээ", "Амжилттай!");
        })
        .catch((err) => {
          // console.log(err.response)
        });
    },
    editExamNumberSymbolStep(item) {
      // console.log(item);
      this.examSetUpForm3 = true;
      this.numberSymbolStepEditMode = true;
      this.addExamNumbersSymbolStepForm.fill(item);
      this.addExamNumbersSymbolStepForm.course_id =
        this.examStep3CoursesSubjects.filter((el) => {
          return el.course_id == item.course_id;
        })[0];
      // console.log(this.addExamNumbersSymbolStepForm.course_id);
      //   this.addExamNumbersSymbolStepForm.course_id = item.course_id;
      this.taxanomyLevNameOperations.forEach((element) => {
        if (element.id == item.tax_verb_id) {
          this.addExamNumbersSymbolStepForm.tax_verb_id = element;
        }
      });
      this.taxanomyKnowledgeNameOperations.forEach((element) => {
        if (element.id == item.tax_noun_id) {
          this.addExamNumbersSymbolStepForm.tax_noun_id = element;
        }
      });
    },
    updateExamNumberSymbolStep() {
      this.addExamNumbersSymbolStepForm
        .patch(
          "/teacher/updateExamNumberSymbolStep/" +
            this.addExamNumbersSymbolStepForm.id
        )
        .then((res) => {
          this.getProblemNumberSymbolSteps(
            this.addExamNumbersSymbolStepForm.step2_id,
            this.useStep3ExamId
          );
          this.resetExamNumbersSymbolStepForm();
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    deleteExamNumberSymbolStep(item) {
      //   console.log(item);
      axios
        .delete("/teacher/deleteExamNumberSymbolStep/" + item.id)
        .then((res) => {
          this.getProblemNumberSymbolSteps(item.step2_id, this.useStep3ExamId);
        })
        .catch((err) => {
          // console.log(err)
        });
    },

    // Ерөнхий тохиолдолд шалгалт устгах
    deleteExam(exam) {
      //   console.log(exam);
      Swal.fire({
        title: "Та итгэлтэй байна уу? Шалгалт устгах гэж байна.",
        text: "Шалгалтыг устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/deleteExam", {
              exam: exam,
            })
            .then((res) => {
              if (res.data.info.code === "success") {
                Swal.fire("Устгасан!", res.data.info.message, "success");
                Fire.$emit("loadExams");
              } else if (res.data.info.code === "errors") {
                this.$toastr.e(res.data.info.message);
              }
            })
            .catch((err) => {});
        }
      });
    },
    //Таксоном
    customLabelTaxVerb({ tax_lev_name_operation }) {
      return `${tax_lev_name_operation}`;
    },
    customLabelTaxNoun({ tax_know_lev_name_operation }) {
      return `${tax_know_lev_name_operation}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    async getCurrentYear() {
      await axios
        .get("/all_worker_functions/getCurrentYear")
        .then((response) => {
          this.yearId = response.data.currentYear;
          //   this.selectedDate = new Date(this.selected_year.start_date);
          //   this.year = this.selectedDate.getFullYear();
          //   this.getCalendarData();
        })
        .catch(() => {});
    },
  },
  watch: {
    selectSchoolClassID(newVal, oldVal) {
      this.enrolledStudents.student_id = [];
      this.schoolclassessstudents = [];
      this.enrolledExamAddStudents = [];
      this.enrolledExamAddStudentsRemoved = [];
    },
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.exams_name = "";
    //   this.form.exams_type = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/teacher/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
  },
  created() {
    this.getAllBBZandOpenExamsTeacher(this.checkPage);
    this.getCurrentYear();
    Fire.$on("loadExams", () => {
      this.getAllBBZandOpenExamsTeacher(this.checkPage);
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
