<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Хөгжүүлэх сургалт</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    label="Хүүхэд"
                    label-for="child_id"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                    <b-form-select
                      v-model="child_id"
                      :options="childOptions"
                      text-field="student_name"
                      value-field="user_id"
                      class="form-control"
                      @input="getParentProjects()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <b-overlay :show="devIsLoaded" rounded="sm">
          <b-card-group deck class="p-3 my-5">
            <div
              class="col-lg-3 mb-3"
              v-for="project in studentProjects"
              :key="project.id"
            >
              <div
                class="card"
                :style="
                  project.choosed == 0 &&
                  (project.limit == project.registered ||
                    checkCloseDate(project.reg_close_date) == false)
                    ? 'filter: grayscale(1);'
                    : ''
                "
              >
                <div class="ribbon ribbon-bottom-right"></div>
                <div class="card-container">
                  <div class="box">
                    <img :src="project.image" alt="Avatar" class="image-card" />
                    <div
                      class="ribbon ribbon-top-right"
                      v-if="project.choosed == 1"
                    >
                      <span>БҮРТГҮҮЛСЭН</span>
                    </div>
                  </div>
                  <div class="overlay text-center">
                    <div class="hoverText">
                      <div>
                        <div class="hoverBtn">
                          <b-badge
                            v-if="
                              checkCloseDate(project.reg_close_date) == false
                            "
                            pill
                            class="float-right mt-2"
                            variant="danger"
                            >БҮРТГЭЛИЙН ХУГАЦАА ДУУССАН</b-badge
                          >
                          <b-badge
                            v-else-if="project.choosed == 1"
                            pill
                            class="float-right mt-2"
                            variant="success"
                            >БҮРТГҮҮЛСЭН</b-badge
                          >
                          <b-badge
                            v-else-if="isLimitedTeacher != ''"
                            pill
                            class="float-right mt-2"
                            variant="warning"
                            >АНГИЙН БАГШ СОНГОНО.</b-badge
                          >
                          <b-button
                            v-else-if="project.limit != project.registered"
                            size="sm"
                            class="mt-2"
                            href="#"
                            variant="success"
                            @click.prevent="chooseProject(project)"
                            >СОНГОХ</b-button
                          >
                          <b-badge
                            v-else
                            pill
                            class="float-right mt-2"
                            variant="danger"
                            >БҮРТГЭЛ ДҮҮРСЭН</b-badge
                          >
                        </div>
                        <small>
                          <span v-show="project.time_type != 'Тогтмол'">
                            <i class="fas fa-calendar-alt"></i>
                            <b
                              >{{ project.start_date | dateYearMonthDay }} -
                              {{ project.end_date | dateYearMonthDay }}
                            </b>
                            <br />
                          </span>
                          <span>
                            <i class="far fa-clock"></i>
                            <b>
                              Хуваарь:
                              {{
                                project.day == 1
                                  ? "1 дэх"
                                  : project.day + " дахь"
                              }}
                              өдөр, Цаг: {{ project.startTime }} -
                              {{ project.endTime }}
                            </b>
                            <br />
                          </span>
                          <span v-show="project.isTwo == 1">
                            <i class="far fa-clock"></i>
                            <b>
                              Хуваарь-2:
                              {{
                                project.day2 == 1
                                  ? "1 дэх"
                                  : project.day2 + " дахь"
                              }}
                              өдөр, Цаг:
                              {{ project.startTime2 }} -
                              {{ project.endTime2 }}
                            </b>
                            <br />
                          </span>
                          <div class="hoverBtn">
                            <b-button
                              v-if="project.choosed == 1"
                              size="sm"
                              class="mt-2 mr-2"
                              href="#"
                              variant="success"
                              @click.prevent="showComment(project)"
                              >Эсээ харах</b-button
                            >
                            <b-button
                              size="sm"
                              class="mt-2"
                              href="#"
                              variant="primary"
                              @click.prevent="showProject(project)"
                              >Дэлгэрэнгүй харах</b-button
                            >
                          </div>
                          {{ project.description }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      <small class="text-muted">
                        {{ project.name.slice(0, 30) }}
                      </small>
                    </h5>
                    <p
                      class="card-text"
                      style="
                        text-align: justify;
                        height: 0.8rem;
                        word-break: break-all;
                      "
                    >
                      <small class="text-muted">
                        Анги:
                        <b>
                          <span>
                            {{ project.scope }}
                          </span>
                        </b>
                        Хуваарь:
                        <b>
                          <span>
                            {{
                              project.day == 1 ? "1 дэх" : project.day + " дахь"
                            }}
                            өдөр
                          </span>
                        </b>
                      </small>
                    </p>
                  </div>
                  <div class="card-footer pt-0">
                    <small class="text-muted">
                      <span v-if="project.choosed == 1">
                        <b-icon
                          icon="check-square-fill"
                          variant="success"
                        ></b-icon>
                        <b>БҮРТГҮҮЛСЭН</b>
                      </span>
                      <i class="far fa-calendar-check ml-2"></i>
                      <b>{{ project.reg_close_date | dateYearMonthDay }}</b>
                      <b-badge
                        v-if="project.limit == project.registered"
                        pill
                        class="float-right mt-2"
                        variant="danger"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                      <b-badge
                        v-else
                        pill
                        class="float-right mt-2"
                        variant="dark"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </b-card-group>
        </b-overlay>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-show-project"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <dl class="text-left">
              <dt>
                <b-alert show variant="light" class="groupForm">
                  ТӨСЛИЙН ТАНИЛЦУУЛГА
                </b-alert>
              </dt>
              <dd>
                <img
                  style="width: 100%"
                  v-if="showProjectForm.image != null"
                  :src="showProjectForm.image"
                />
              </dd>
              <dt>Нэр:</dt>
              <dd>{{ showProjectForm.name }}</dd>
              <dt>Бүртгэл хаагдах огноо:</dt>
              <dd>{{ showProjectForm.reg_close_date }}</dd>
              <dt>Хичээллэх өдөр:</dt>
              <dd>
                Өдөр-1:
                {{
                  showProjectForm.day == 1
                    ? "1 дэх"
                    : showProjectForm.day + " дахь"
                }}
                өдөр, Цаг: {{ showProjectForm.startTime }} -
                {{ showProjectForm.endTime }}<br />
                <span v-if="showProjectForm.day2 != 0">
                  Өдөр хоёр:
                  {{
                    showProjectForm.day2 == 1
                      ? "1 дэх"
                      : showProjectForm.day2 + " дахь"
                  }}
                  өдөр, Цаг:
                  {{ showProjectForm.startTime2 }} -
                  {{ showProjectForm.endTime2 }}
                </span>
              </dd>
              <dt>Нийт хамрагдах:</dt>
              <dd>{{ showProjectForm.limit }}/{{ showProjectForm.choosed }}</dd>
              <dt>Таны хүүхдийн сурдаг ангиас хамрагдах тоо:</dt>
              <dd>
                {{ showProjectForm.myClassLimit }}/{{
                  showProjectForm.myClassChoosed
                }}
              </dd>
              <dt>Тайлбар:</dt>
              <dd>
                {{ showProjectForm.description }}
              </dd>
              <dt>
                <b-alert show variant="light" class="groupForm">
                  ТӨСЛИЙН ЗОРИЛГО
                </b-alert>
              </dt>
              <dt>Юу мэддэг болох вэ?:</dt>
              <dd>
                {{ showProjectForm.what_know }}
              </dd>
              <dt>Юу ойлгох вэ?:</dt>
              <dd>{{ showProjectForm.what_understand }}</dd>
              <dt>Юу чаддаг болох вэ?:</dt>
              <dd>{{ showProjectForm.what_can }}</dd>
              <dt>
                <b-alert show variant="light" class="groupForm">
                  Урьдчилсан судалгаа
                </b-alert>
              </dt>
              <dt>Хэрэглэгдэх зүйлсийн жагсаалт:</dt>
              <dd>{{ showProjectForm.tools }}</dd>
              <dt>Хөдөлмөр аюулгүй байдал:</dt>
              <dd>{{ showProjectForm.safety }}</dd>
              <dt>Судлагдахууны талаарх мэдээлэл:</dt>
              <dd>{{ showProjectForm.info }}</dd>
              <dt>
                <b-alert show variant="light" class="groupForm">
                  СУРАГЧДАД ӨГӨХ ИЖИЛ ТӨРЛИЙН ДААЛГАВАР, ШИНЭ САНАА
                </b-alert>
              </dt>
              <dt>Даалгаврын зураг:</dt>
              <dd>
                <img
                  style="width: 100%"
                  v-if="showProjectForm.task_img != null"
                  :src="showProjectForm.task_img"
                />
              </dd>
              <dt>Даалгаврын тайлбар:</dt>
              <dd>{{ showProjectForm.task_text }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp"
              ><b>Хичээл: </b>{{ showProjectForm.name }}</span
            >
            <span class="direct-chat-timestamp float-right"
              ><b>Өдөр: </b>
              {{
                showProjectForm.day == 1
                  ? "1 дэх"
                  : showProjectForm.day + " дахь"
              }}
              өдөр, Цаг: {{ showProjectForm.startTime }} -
              {{ showProjectForm.endTime }}<br />
              <span v-if="showProjectForm.day2 != 0">
                Өдөр хоёр: {{ showProjectForm.day2 }}, Цаг:
                {{ showProjectForm.startTime2 }} -
                {{ showProjectForm.endTime2 }}
              </span></span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ showProjectForm.description }}
          </div>
        </div>
        <div class="row" v-if="beforeComments.length > 0">
          <div class="col-lg-12">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card v-html="comm.comment" class="p-1 text-justify" />
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <p class="text-center text-muted h5 mt-5">
              <b-icon icon="inboxes"></b-icon>
            </p>
            <p class="text-center text-muted font-italic text-sm">
              Одоогоор таны хүүхэд эсээ бичээгүй байна.
            </p>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      devIsLoaded: false,
      quarterOptions: [],
      studentProjects: [],
      child_id: "",
      childOptions: [],
      beforeComments: [],
      isLimitedTeacher: "",
      showProjectForm: new Form({
        id: null,
        owner_id: "",
        year_id: "",
        quarter_id: "",
        school_id: "",
        status: "",
        status_description: "",
        project_type: "",
        time_type: "",
        scope: "",
        class_limit: "",
        name: "",
        image: "",
        description: "",
        start_date: "",
        end_date: "",
        day: "",
        startTime: "",
        endTime: "",
        isTwo: "",
        day2: "",
        startTime2: "",
        endTime2: "",
        limit: "",
        registered: "",
        reg_close_date: "",
        what_know: "",
        what_understand: "",
        what_can: "",
        tools: "",
        safety: "",
        info: "",
        task_img: "",
        task_tex: "",
        myClassLimit: "",
        myClassChoosed: "",
        choosed: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    checkCloseDate(pDate) {
      return moment(moment(new Date()).format()).isBefore(
        moment(pDate).format()
      );
    },
    getChilds() {
      axios
        .get("/project_parent/getChilList")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0)
            this.child_id = this.childOptions[0].user_id;
          this.quarterOptions = response.data.quarterList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getParentProjects() {
      this.devIsLoaded = true;
      axios
        .post("/project_parent/getParentProjects", {
          child_id: this.child_id,
          project_type: "Хөгжүүлэх хичээл",
        })
        .then((response) => {
          this.studentProjects = _.orderBy(
            response.data.myProjects,
            "choosed",
            "desc"
          );
          this.isLimitedTeacher = response.data.isLimitedTeacher;
          this.devIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showProject(project) {
      this.showProjectForm.reset();
      this.showProjectForm.fill(project);
      this.$bvModal.show("modal-show-project");
    },
    showComment(project) {
      this.beforeComments = [];
      this.showProjectForm.reset();
      this.showProjectForm.fill(project);
      this.getMessage();
      this.$bvModal.show("modal-project-comment");
    },
    getMessage() {
      axios
        .post("/project_parent/getChildComment/" + this.showProjectForm.id, {
          child_id: this.child_id,
          type: "project",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //======================== Бүртгэгдсэн төлөвт шилжүүлэх ================================
    chooseProject(project) {
      Swal.fire({
        title: "Хөгжүүлэх сургалт сонгох гэж байна?",
        text:
          'Та "' +
          project.name +
          '" хөгжүүлэх сургалт сонгох гэж байна. Итгэлтэй байна уу?',
        showDenyButton: true,
        imageUrl: project.image,
        imageHeight: 200,
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#41b882",
        denyButtonColor: "#595959",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Хөгжүүлэх сургалт сонгож байна...";
          axios
            .put("/project_parent/updateChildChose/" + project.id, {
              child_id: this.child_id,
            })
            .then((response) => {
              if (response.data.error == "error") {
                Swal.fire({
                  icon: "error",
                  title: "Сонгох боломжгүй...",
                  text: response.data.errorMsj,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Амжилттай",
                  text: response.data.errorMsj,
                });
                Fire.$emit("getMyProjects");
              }
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    toggleSelect() {
      console.log("filter department");
    },
  },
  created() {
    this.getChilds();
    Fire.$on("getMyProjects", () => {
      this.getParentProjects();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
dd {
  padding-left: 20px;
}
</style>
