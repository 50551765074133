<template>
  <div class="mt-3">
    <manag-Attend-Stat :year_id="selectedYear"></manag-Attend-Stat>
    <!-- /.col -->
    <b-button @click.prevent="showStudData()" block variant="outline-primary"
      >Сурагчдийн ирцийг сурагчаар эхлэж харах</b-button
    >
    <div class="card" v-if="viewStudChart">
      <div class="card-body table-responsive bodyOfYear">
        <multiselect
          v-model="selectedYear"
          deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
          track-by="id"
          label="id"
          :custom-label="customYearName"
          placeholder="Хичээлийн жил сонгоно уу?"
          :options="years"
          :searchable="false"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong
              >{{ option.start_date | dateYear }}-{{
                option.finish_date | dateYear
              }}
              - хичээлийн жил
            </strong></template
          >
        </multiselect>
        <b-overlay :show="show" rounded="sm">
          <div class="row mt-3">
            <div class="col-12 col-sm-4"></div>
            <div class="col-12 col-sm-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Нийт сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="chartOptions"
                        :series="seriesAll"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">{{
                          irsenAll
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">{{
                          tasalsanAll
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">
                          {{ uvchteiAll }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">{{
                          chuluuteiAll
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">
                          {{ hotsorsonAll }}</b-badge
                        ></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4"></div>
            <div class="col-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Бага сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="chartOptions"
                        :series="seriesP"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">{{
                          irsenP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">{{
                          tasalsanP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">
                          {{ uvchteiP }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">{{
                          chuluuteiP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">
                          {{ hotsorsonP }}</b-badge
                        ></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Дунд сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="chartOptions"
                        :series="seriesM"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">{{
                          irsenM
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">{{
                          tasalsanM
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">{{
                          uvchteiM
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">{{
                          chuluuteiM
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">{{
                          hotsorsonM
                        }}</b-badge></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Ахлах сургууль(Жилээр)</span
                    >
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="chartOptions"
                        :series="seriesH"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">
                          {{ irsenH }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">
                          {{ tasalsanH }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">
                          {{ uvchteiH }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">
                          {{ chuluuteiH }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">
                          {{ hotsorsonH }}</b-badge
                        ></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <b-overlay :show="showQuarter" rounded="sm">
        <div class="card-body">
          <multiselect
            v-model="selectedQuarter"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="name"
            placeholder="Хичээлийн улирал сонгоно уу?"
            :options="quarters"
            :searchable="false"
            :allow-empty="false"
            v-if="viewChart"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.name }}
                - улирал
              </strong></template
            >
          </multiselect>
          <div class="row mt-3" v-if="viewQuarterChart">
            <div class="col-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Бага сургууль(Улирлаар)</span
                    ><span class="info-box-number text-muted mb-0">
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="QuarterChartOptions"
                        :series="seriesQP"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">{{
                          irsenQP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">{{
                          tasalsanQP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">{{
                          uvchteiQP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">{{
                          chuluuteiQP
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">{{
                          hotsorsonQP
                        }}</b-badge></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart" v-if="viewQuarterChart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Дунд сургууль(Улирлаар)</span
                    ><span class="info-box-number text-muted mb-0">
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="QuarterChartOptions"
                        :series="seriesQM"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">
                          {{ irsenQM }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">
                          {{ tasalsanQM }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">
                          {{ uvchteiQM }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">
                          {{ chuluuteiQM }}</b-badge
                        ></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">
                          {{ hotsorsonQM }}</b-badge
                        ></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart" v-if="viewQuarterChart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Ахлах сургууль(Улирлаар)</span
                    >
                    <span class="info-box-number text-muted mb-0">
                      <apexchart
                        width="100%"
                        type="donut"
                        :options="QuarterChartOptions"
                        :series="seriesQH"
                      ></apexchart>
                      <b-badge pill variant="success"
                        >ИРСЭН
                        <b-badge pill variant="light">{{
                          irsenQH
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="danger"
                        >ТАСАЛСАН
                        <b-badge pill variant="light">{{
                          tasalsanQH
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="warning"
                        >ӨВЧТЭЙ
                        <b-badge pill variant="light">{{
                          uvchteiQH
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="info"
                        >ЧӨЛӨӨТЭЙ
                        <b-badge pill variant="light">{{
                          chuluuteiQH
                        }}</b-badge></b-badge
                      >
                      <b-badge pill variant="primary"
                        >ХОЦОРСОН
                        <b-badge pill variant="light">{{
                          hotsorsonQH
                        }}</b-badge></b-badge
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mt-3">
            <div class="col-4" v-if="viewQuarterChart">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Бага сургууль(Улирлаар)</span
                    ><span class="info-box-number text-muted mb-0">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsClassP"
                        :series="seriesCP"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedClassP"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Хичээлийн анги сонгоно уу?"
                      :options="primaryClassLevel"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewQuarterChart"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          - анги
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewClassChartP"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsStudentP"
                        :series="seriesSP"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedStudentP"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Сурагч сонгоно уу?"
                      track-by="id"
                      label="student_name"
                      :options="pStudentNames"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewClassChartP"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.student_last_name }}.{{
                            option.student_name
                          }}
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewLessonChartP"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonP"
                        :series="seriesLP"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedLessonP"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Хичээл сонгоно уу?"
                      track-by="course_id"
                      label="subject_name"
                      :options="pLessonNames"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewLessonChartP"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong>{{ option.subject_name }} </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewMonthsChartP"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsMonthsP"
                        :series="seriesMonthsP"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart" v-if="viewQuarterChart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Дунд сургууль(Улирлаар)</span
                    ><span class="info-box-number text-muted mb-0">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsClassM"
                        :series="seriesCM"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedClassM"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Хичээлийн анги сонгоно уу?"
                      :options="middleClassLevel"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewQuarterChart"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          - анги
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewClassChartM"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsStudentM"
                        :series="seriesSM"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedStudentM"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Сурагч сонгоно уу?"
                      track-by="id"
                      label="student_name"
                      :options="mStudentNames"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewClassChartM"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.student_last_name }}.{{
                            option.student_name
                          }}
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewLessonChartM"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonM"
                        :series="seriesLM"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div id="chart" v-if="viewQuarterChart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-muted"
                      >Ахлах сургууль(Улирлаар)</span
                    >
                    <span class="info-box-number text-muted mb-0">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsClassH"
                        :series="seriesCH"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedClassH"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Хичээлийн анги сонгоно уу?"
                      :options="highClassLevel"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewQuarterChart"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          - анги
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewClassChartH"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsStudentH"
                        :series="seriesSH"
                      ></apexchart>
                    </span>
                    <multiselect
                      v-model="selectedStudentH"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Сурагч сонгоно уу?"
                      track-by="id"
                      label="student_name"
                      :options="hStudentNames"
                      :searchable="false"
                      :allow-empty="false"
                      v-if="viewClassChartH"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.student_last_name }}.{{
                            option.student_name
                          }}
                        </strong></template
                      >
                    </multiselect>
                    <span
                      class="info-box-number text-muted mb-0"
                      v-if="viewLessonChartH"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonH"
                        :series="seriesLH"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
  <!-- /.card-header -->
</template>

<script>
import Multiselect from "vue-multiselect";
import managAttendStat from "./managAttendStat";
import everyStudentAttStat from "./everyStudentAttStat.vue";
export default {
  components: {
    Multiselect,
    everyStudentAttStat,
    managAttendStat: managAttendStat,
  },
  data() {
    return {
      show: false,
      showQuarter: false,
      viewChart: false,
      viewStudChart: false,
      viewQuarterChart: false,
      selectedYear: "",
      irsenCount: 0,
      tasalsanCount: 0,
      uvchteiCount: 0,
      chuluuCount: 0,
      hotsorsonCount: 0,
      quarters: [],
      primaryClassLevel: [],
      middleClassLevel: [],
      highClassLevel: [],
      pClassNames: [],
      mClassNames: [],
      hClassNames: [],
      pStudentNames: [],
      mStudentNames: [],
      hStudentNames: [],
      pStudentFullNames: [],
      mStudentFullNames: [],
      hStudentFullNames: [],
      pLessonNames: [],
      mLessonNames: [],
      hLessonNames: [],
      pMonthNames: [],
      selectedQuarter: "",
      selectedClassP: "",
      selectedClassM: "",
      selectedClassH: "",
      selectedStudentP: "",
      selectedStudentM: "",
      selectedStudentH: "",
      selectedLessonP: "",
      myLessonOptions: [],
      selectedLesson: "",
      selectedDate: "",
      editMode: false,
      lesson_date: "",
      myLessonDates: [],
      subject_name: [],
      passPropsMyLessonDate: [],
      myAttendanceAll: "",
      primaryClass: "",
      middleClass: "",
      highClass: "",
      years: [],
      seriesP: [],
      seriesM: [],
      seriesH: [],
      seriesAll: [],
      seriesQP: [],
      seriesQM: [],
      seriesQH: [],

      seriesCP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesCM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesCH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesSP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesSM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesSH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],

      chartOptions: {
        labels: ["Ирсэн", "Тасалсан", "Өвчтэй", "Чөлөөтэй", "Хоцорсон"],
        colors: ["#00B74A", "#F93154", "#FFA900", "#39C0ED", "#B23CFD"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      QuarterChartOptions: {
        labels: ["Ирсэн", "Тасалсан", "Өвчтэй", "Чөлөөтэй", "Хоцорсон"],
        colors: ["#00B74A", "#F93154", "#FFA900", "#39C0ED", "#B23CFD"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsClassP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "middleClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "highClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsStudentP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsStudentM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsStudentH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsMonthsP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesLP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesMonthsP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      viewClassChartP: false,
      viewClassChartM: false,
      viewClassChartH: false,
      viewLessonChartP: false,
      viewLessonChartM: false,
      viewLessonChartH: false,
      viewMonthsChartP: false,
    };
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      this.school_quarter_id = "";
      this.show = true;
      if (newVal != "") {
        this.viewChart = false;
        axios
          .post("/manager/yearAllAttendance", {
            selectedYear: this.selectedYear.id,
          })
          .then((response) => {
            this.irsenP = response.data.irsenP;
            this.tasalsanP = response.data.tasalsanP;
            this.uvchteiP = response.data.uvchteiP;
            this.chuluuteiP = response.data.chuluuteiP;
            this.hotsorsonP = response.data.hotsorsonP;
            this.irsenM = response.data.irsenM;
            this.tasalsanM = response.data.tasalsanM;
            this.uvchteiM = response.data.uvchteiM;
            this.chuluuteiM = response.data.chuluuteiM;
            this.hotsorsonM = response.data.hotsorsonM;
            this.irsenH = response.data.irsenH;
            this.tasalsanH = response.data.tasalsanH;
            this.uvchteiH = response.data.uvchteiH;
            this.chuluuteiH = response.data.chuluuteiH;
            this.hotsorsonH = response.data.hotsorsonH;
            this.irsenAll =
              response.data.irsenP +
              response.data.irsenH +
              response.data.irsenM;
            this.tasalsanAll =
              response.data.tasalsanP +
              response.data.tasalsanM +
              response.data.tasalsanH;
            this.uvchteiAll =
              response.data.uvchteiP +
              response.data.uvchteiM +
              response.data.uvchteiH;
            this.chuluuteiAll =
              response.data.chuluuteiP +
              response.data.chuluuteiM +
              response.data.chuluuteiH;
            this.hotsorsonAll =
              response.data.hotsorsonP +
              response.data.hotsorsonM +
              response.data.hotsorsonH;
            this.primaryClass = response.data.primaryClass;
            this.middleClass = response.data.middleClass;
            this.highClass = response.data.highClass;
            this.seriesP[0] = this.irsenP;
            this.seriesP[1] = this.tasalsanP;
            this.seriesP[2] = this.uvchteiP;
            this.seriesP[3] = this.chuluuteiP;
            this.seriesP[4] = this.hotsorsonP;
            this.seriesM[0] = this.irsenM;
            this.seriesM[1] = this.tasalsanM;
            this.seriesM[2] = this.uvchteiM;
            this.seriesM[3] = this.chuluuteiM;
            this.seriesM[4] = this.hotsorsonM;
            this.seriesH[0] = this.irsenH;
            this.seriesH[1] = this.tasalsanH;
            this.seriesH[2] = this.uvchteiH;
            this.seriesH[3] = this.chuluuteiH;
            this.seriesH[4] = this.hotsorsonH;
            this.seriesAll[0] = this.irsenAll;
            this.seriesAll[1] = this.tasalsanAll;
            this.seriesAll[2] = this.uvchteiAll;
            this.seriesAll[3] = this.chuluuteiAll;
            this.seriesAll[4] = this.hotsorsonAll;
            this.viewChart = true;
            this.show = false;
            this.quarters = response.data.getMySchoolQuarters;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
    },
    selectedQuarter: function (newVal, oldVal) {
      this.school_quarter_id = "";
      this.showQuarter = true;
      if (newVal != "") {
        this.viewQuarterChart = false;
        axios
          .post("/manager/getQuartersAllAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
          })
          .then((response) => {
            this.irsenQP = response.data.irsenQP;
            this.tasalsanQP = response.data.tasalsanQP;
            this.uvchteiQP = response.data.uvchteiQP;
            this.chuluuteiQP = response.data.chuluuteiQP;
            this.hotsorsonQP = response.data.hotsorsonQP;
            this.irsenQM = response.data.irsenQM;
            this.tasalsanQM = response.data.tasalsanQM;
            this.uvchteiQM = response.data.uvchteiQM;
            this.chuluuteiQM = response.data.chuluuteiQM;
            this.hotsorsonQM = response.data.hotsorsonQM;
            this.irsenQH = response.data.irsenQH;
            this.tasalsanQH = response.data.tasalsanQH;
            this.uvchteiQH = response.data.uvchteiQH;
            this.chuluuteiQH = response.data.chuluuteiQH;
            this.hotsorsonQH = response.data.hotsorsonQH;
            this.primaryClass = response.data.primaryClass;
            this.middleClass = response.data.middleClass;
            this.highClass = response.data.highClass;

            this.pIrsenList = response.data.pIrsenList;
            this.pTasalsanList = response.data.pTasalsanList;
            this.pUvchteiList = response.data.pUvchteiList;
            this.pChuluuteiList = response.data.pChuluuteiList;
            this.pHotsorsonList = response.data.pHotsorsonList;

            this.mIrsenList = response.data.mIrsenList;
            this.mTasalsanList = response.data.mTasalsanList;
            this.mUvchteiList = response.data.mUvchteiList;
            this.mChuluuteiList = response.data.mChuluuteiList;
            this.mHotsorsonList = response.data.mHotsorsonList;

            this.hIrsenList = response.data.hIrsenList;
            this.hTasalsanList = response.data.hTasalsanList;
            this.hUvchteiList = response.data.hUvchteiList;
            this.hChuluuteiList = response.data.hChuluuteiList;
            this.hHotsorsonList = response.data.hHotsorsonList;

            this.seriesQP[0] = this.irsenQP;
            this.seriesQP[1] = this.tasalsanQP;
            this.seriesQP[2] = this.uvchteiQP;
            this.seriesQP[3] = this.chuluuteiQP;
            this.seriesQP[4] = this.hotsorsonQP;
            this.seriesQM[0] = this.irsenQM;
            this.seriesQM[1] = this.tasalsanQM;
            this.seriesQM[2] = this.uvchteiQM;
            this.seriesQM[3] = this.chuluuteiQM;
            this.seriesQM[4] = this.hotsorsonQM;
            this.seriesQH[0] = this.irsenQH;
            this.seriesQH[1] = this.tasalsanQH;
            this.seriesQH[2] = this.uvchteiQH;
            this.seriesQH[3] = this.chuluuteiQH;
            this.seriesQH[4] = this.hotsorsonQH;

            this.seriesCP[0].data = this.pIrsenList;
            this.seriesCP[1].data = this.pTasalsanList;
            this.seriesCP[2].data = this.pUvchteiList;
            this.seriesCP[3].data = this.pChuluuteiList;
            this.seriesCP[4].data = this.pHotsorsonList;

            this.seriesCM[0].data = this.mIrsenList;
            this.seriesCM[1].data = this.mTasalsanList;
            this.seriesCM[2].data = this.mUvchteiList;
            this.seriesCM[3].data = this.mChuluuteiList;
            this.seriesCM[4].data = this.mHotsorsonList;

            this.seriesCH[0].data = this.hIrsenList;
            this.seriesCH[1].data = this.hTasalsanList;
            this.seriesCH[2].data = this.hUvchteiList;
            this.seriesCH[3].data = this.hChuluuteiList;
            this.seriesCH[4].data = this.hHotsorsonList;

            this.viewQuarterChart = true;
            this.showQuarter = false;

            this.primaryClassLevel = response.data.primaryClassLevel;
            this.middleClassLevel = response.data.middleClassLevel;
            this.highClassLevel = response.data.highClassLevel;
            this.pClassNames = response.data.pClassNames;
            this.mClassNames = response.data.mClassNames;
            this.hClassNames = response.data.hClassNames;
            this.chartOptionsClassP.xaxis.categories =
              response.data.pClassNames;
            this.chartOptionsClassM.xaxis.categories =
              response.data.mClassNames;
            this.chartOptionsClassH.xaxis.categories =
              response.data.hClassNames;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    selectedClassP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartP = false;
        axios
          .post("/manager/getQuartersStudAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassP.id,
          })
          .then((response) => {
            this.seriesSP[0].data = response.data.studIrsenList;
            this.seriesSP[1].data = response.data.studTasalsanList;
            this.seriesSP[2].data = response.data.studUvchteiList;
            this.seriesSP[3].data = response.data.studChuluuteiList;
            this.seriesSP[4].data = response.data.studHotsorsonList;

            this.viewClassChartP = true;
            this.pStudentNames = response.data.studentIds;
            this.pStudentFullNames = response.data.stdNames;
            this.chartOptionsStudentP.xaxis.categories = response.data.stdNames;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    selectedClassM: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartM = false;
        axios
          .post("/manager/getQuartersStudAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassM.id,
          })
          .then((response) => {
            this.seriesSM[0].data = response.data.studIrsenList;
            this.seriesSM[1].data = response.data.studTasalsanList;
            this.seriesSM[2].data = response.data.studUvchteiList;
            this.seriesSM[3].data = response.data.studChuluuteiList;
            this.seriesSM[4].data = response.data.studHotsorsonList;

            this.viewClassChartM = true;
            this.mStudentNames = response.data.studentIds;
            this.mStudentFullNames = response.data.stdNames;
            this.chartOptionsStudentM.xaxis.categories = response.data.stdNames;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    selectedClassH: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartH = false;
        axios
          .post("/manager/getQuartersStudAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassH.id,
          })
          .then((response) => {
            this.seriesSH[0].data = response.data.studIrsenList;
            this.seriesSH[1].data = response.data.studTasalsanList;
            this.seriesSH[2].data = response.data.studUvchteiList;
            this.seriesSH[3].data = response.data.studChuluuteiList;
            this.seriesSH[4].data = response.data.studHotsorsonList;

            this.viewClassChartH = true;
            this.hStudentNames = response.data.studentIds;
            this.hStudentFullNames = response.data.stdNames;
            this.chartOptionsStudentH.xaxis.categories = response.data.stdNames;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    selectedStudentP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartP = false;
        axios
          .post("/manager/getQuartersLessonAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassP.id,
            selectedStud: this.selectedStudentP.user_id,
          })
          .then((response) => {
            this.seriesLP[0].data = response.data.lessonIrsenList;
            this.seriesLP[1].data = response.data.lessonTasalsanList;
            this.seriesLP[2].data = response.data.lessonUvchteiList;
            this.seriesLP[3].data = response.data.lessonChuluuteiList;
            this.seriesLP[4].data = response.data.lessonHotsorsonList;

            this.pLessonNames = response.data.lessonList;
            this.chartOptionsLessonP.xaxis.categories =
              response.data.lessonNames;
            this.viewLessonChartP = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedStudentM: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartM = false;
        axios
          .post("/manager/getQuartersLessonAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassM.id,
            selectedStud: this.selectedStudentM.user_id,
          })
          .then((response) => {
            this.seriesLM[0].data = response.data.lessonIrsenList;
            this.seriesLM[1].data = response.data.lessonTasalsanList;
            this.seriesLM[2].data = response.data.lessonUvchteiList;
            this.seriesLM[3].data = response.data.lessonChuluuteiList;
            this.seriesLM[4].data = response.data.lessonHotsorsonList;

            this.mLessonNames = response.data.lessonList;
            this.chartOptionsLessonM.xaxis.categories =
              response.data.lessonNames;
            this.viewLessonChartM = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedStudentH: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartH = false;
        axios
          .post("/manager/getQuartersLessonAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassH.id,
            selectedStud: this.selectedStudentH.user_id,
          })
          .then((response) => {
            this.seriesLH[0].data = response.data.lessonIrsenList;
            this.seriesLH[1].data = response.data.lessonTasalsanList;
            this.seriesLH[2].data = response.data.lessonUvchteiList;
            this.seriesLH[3].data = response.data.lessonChuluuteiList;
            this.seriesLH[4].data = response.data.lessonHotsorsonList;

            this.hLessonNames = response.data.lessonList;
            this.chartOptionsLessonH.xaxis.categories =
              response.data.lessonNames;
            this.viewLessonChartH = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedLessonP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewMonthsChartP = false;
        axios
          .post("/manager/getQuarterMonthsAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClass: this.selectedClassP.id,
            selectedStud: this.selectedStudentP.user_id,
            selectedLesson: this.selectedLessonP.course_id,
          })
          .then((response) => {
            this.seriesMonthsP[0].data = response.data.monthIrsenList;
            this.seriesMonthsP[1].data = response.data.monthTasalsanList;
            this.seriesMonthsP[2].data = response.data.monthUvchteiList;
            this.seriesMonthsP[3].data = response.data.monthChuluuteiList;
            this.seriesMonthsP[4].data = response.data.monthHotsorsonList;

            this.pMonthNames = response.data.monthList;
            this.chartOptionsMonthsP.xaxis.categories =
              response.data.monthNames;
            this.viewMonthsChartP = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
  },

  methods: {
    showStudData() {
      this.viewStudChart = !this.viewStudChart;
    },
    allYears() {
      axios
        .get("/manager/getYears")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getLessonList() {
      this.selectedEmp = null;
      axios
        .post("/teacher/getLessonList/", {
          selectedDate: this.selectedDate,
        })
        .then((response) => {
          this.myLessonOptions = response.data.myLesson;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customMyLessonClass({
      subject_name,
      school_class_full_name,
      attendance_lesson_time_number,
    }) {
      return `${school_class_full_name} - ${subject_name} - ${attendance_lesson_time_number}-р цаг`;
    },
    remove(attendance_id, tooluur) {
      //   this.passPropsMyLessonDate.indexOf(attendance_id, 1);
      //   console.log(this.passPropsMyLessonDate);
      // console.log(attendance_id, tooluur);
      this.passPropsMyLessonDate.splice(tooluur, 1);
      //   this.$delete(this.passPropsMyLessonDate, attendance_id);
    },
  },
  created() {
    this.allYears();
    this.getLessonList();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bodyOfYear {
  min-height: 200px;
}
</style>
