<template>
  <div class="row">
    <hr />
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Танин мэдэхүйн үйлийн түвшний операторыг илэрхийлэх үйл үг ба
            асуултууд:
            {{ child3LevelName.tax_lev_name_operation }} түвшин дахь үйл үгс ба
            асуултууд. Доорх асуух үг болон асуултуудыг ашиглан өөрийн хичээлийн
            агуулгад даалгавар боловсруулах боломжтой.
          </h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <hr />
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>
                  Үйлийн системийн түвшний операторыг илэрхийлэх үйл үг(English)
                </th>
                <th>
                  Үйлийн системийн түвшний операторыг илэрхийлэх үйл үг(Монгол)
                </th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(verb, index) in allTaxanomyLNOVerbs"
                :key="verb.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge pill variant="success">
                    {{ verb.tax_lev_name_operation_verb }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.tax_lev_name_operation_verb_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.created_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["child3LevelName", "changeData3"],
  data() {
    return {
      childMode3: false,
      //   changeData3: false,
      allTaxanomyLNOVerbs: [],
    };
  },
  watch: {
    child3LevelName: function (newVal, oldval) {
      this.getAllInfo();
    },
    changeData3: function (nv, ov) {
      console.log(nv, ov);
      this.childMode3 = false;
    },
  },
  components: {
    // searchManager: searchManager,
  },
  methods: {
    getAllInfo() {
      axios
        .get(`/teacher/getAllTLNOVerbTeacher/${this.child3LevelName["id"]}`)
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.allTaxanomyLNOVerbs = response.data.allTaxanomyLNOVerbs;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getAllInfo();
  },
};
</script>

<style></style>
