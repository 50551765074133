<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Taxanomy operation(Тархины үйлийн системийн түвшиний оператор)
          </h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн системийн түвшин</th>
                <th>Үйлийн системийн түвшиний оператор</th>
                <th>Үйлийн системийн тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(operation, index) in alloperationname"
                :key="operation.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ operation.tax_lev_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ operation.tax_lev_name_operation }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ operation.tax_lev_name_operation_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ operation.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ operation.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTaxanomyLevNameOperation(operation)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteOperationName(operation)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
          <h3 class="card-title" v-show="editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode
                ? createTaxanomyLevNameOperation()
                : updateTaxanomyLevNameOperation()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <multiselect
                  v-model="form.tax_lev_name_id"
                  deselect-label="Can't remove this value"
                  track-by="tax_lev_name"
                  label="tax_lev_name"
                  placeholder="Select one"
                  :options="alllevelnames"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.tax_lev_name }}</strong> is written
                    in<strong>
                      {{ option.tax_lev_name_explain }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэр</label>
                <b-textarea
                  name="tax_lev_name_operation"
                  v-model="form.tax_lev_name_operation"
                  text-field="tax_lev_name_operation"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="tax_lev_name_operation"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэршлийн тайлбар</label>

                <b-textarea
                  name="tax_lev_name_operation_explain"
                  v-model="form.tax_lev_name_operation_explain"
                  text-field="tax_lev_name_operation_explain"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="tax_lev_name_operation_explain"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      alloperationname: [],
      alllevelnames: [],
      form: new Form({
        id: "",
        year_id: "", //backend талд хадгална
        school_id: "", //backend талд хадгална
        department_id: "", //backend талд хадгална
        create_emp_id: "", //backend талд хадгална
        position_id: "", //backend талд хадгална
        tax_lev_name_id: "",
        tax_lev_name_operation: "",
        tax_lev_name_operation_explain: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/getAlltaxanomyLevelNameOperation")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.alloperationname = response.data.alloperationname;
          this.alllevelnames = response.data.alllevelnames;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTaxanomyLevNameOperation(operation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editMode = true;
      this.form.reset();
      this.form.fill(operation);
      let selectEl = this.alllevelnames.filter(function (e) {
        return e.id == operation.tax_lev_name_id;
      });
      //   console.log(selectEl);

      this.form.tax_lev_name_id = selectEl[0];
    },
    updateTaxanomyLevNameOperation() {
      this.form
        .put(
          "/curriculumadministrator/updatetaxanomyLevelNameOperation/" +
            this.form.id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyLevNameOperation");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createTaxanomyLevNameOperation() {
      this.form
        .post("/curriculumadministrator/createtaxanomyLevelNameOperation")
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevNameOperation");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    deleteOperationName(operation) {
      this.form
        .delete(
          `/curriculumadministrator/deletetaxanomyLevelNameOperation/${operation.id}`
        )
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай устгалаа.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevNameOperation");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyLevNameOperation", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
