<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactivity" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Олимпиад, уралдаан тэмцээн</strong>
                  <div>
                    <div class="container">
                      <div class="float-left">
                        <p class="card-title" style="font-size: 80%">
                          Нийт олимпиад, уралдаан тэмцээний тоо:
                          {{ competitions.length }}
                        </p>
                      </div>
                      <div class="float-right">
                        <b-button
                          style="font-size: 80%"
                          size="sm"
                          variant="success"
                          @click.prevent="createCompetition()"
                          >Олимпиад, уралдаан тэмцээний бүртгэл хийх</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <b-container class="bv-example-row" fluid>
                      <b-row>
                        <b-col sm="4"> </b-col>
                        <b-col sm="8"> </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="card-body table-responsive">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageCompetitions"
                            :options="pageOptionsRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterCompetitions"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterCompetitions"
                                @click="filterCompetitions = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageCompetitions"
                      :total-rows="totalRowsCompetitions"
                      :per-page="perPageCompetitions"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="competitions"
                      :fields="fieldsActiveCompetitions"
                      :current-page="currentPageCompetitions"
                      :per-page="perPageCompetitions"
                      :filter="filterCompetitions"
                      :filter-ignored-fields="filterIgnoredFieldsCompetitions"
                      :filter-included-fields="filterOnCompetitions"
                      @filtered="onFilteredCompetitions"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge
                          variant="secondary"
                          class="badge badge-primary text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.name }}
                        </b-badge>
                        <a
                          href="#"
                          class="badge bg-success"
                          @click="showCompetition(data.item)"
                          ><i class="fa fa-eye"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="createCompetitionTeacher(data.item)"
                          ><i class="fas fa-chalkboard-teacher"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-teal"
                          @click="createCompetitionStudent(data.item)"
                          ><i class="fas fa-user-graduate"></i
                        ></a>
                      </template>

                      <template #cell(register_info)="data">
                        <div style="width: 20rem">
                          <b>Бүртгэлийн мэдээлэл:</b><br />
                          <div class="text-wrap" style="text-align: justify">
                            {{ data.item.register_info }}
                          </div>
                          <span><b>Олимпиад, уралдаан болох огноо:</b> </span
                          ><br />
                          <p style="text-align: right">
                            <b-badge variant="info" class="text-wrap">
                              {{ data.item.start_date | dateYearMonthDay }} :
                              {{ data.item.finish_date | dateYearMonthDay }}
                            </b-badge>
                          </p>
                          <b>Олимпиад, уралдаан болох хаяг:</b><br />
                          <div class="text-wrap" style="text-align: right">
                            {{ data.item.address }}
                          </div>
                          <span><b>Холбоо барих мэдээлэл:</b> </span><br />
                          <div class="text-wrap" style="text-align: right">
                            {{ data.item.contact_information }}
                          </div>
                        </div>
                      </template>

                      <template #cell(competition_teachers)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="teacher in data.item.competition_teachers"
                              :key="teacher.id"
                            >
                              <img
                                v-if="teacher.my_info != null"
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                  teacher.my_info.department.department_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  teacher.my_info.teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  teacher.my_info.teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  teacher.my_info.profile_photo_url
                                    ? teacher.my_info.profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <!-- <i
                                class="fas fa-user-times"
                                @click="removeTeacher(teacher)"
                              ></i> -->
                              <b-icon
                                v-if="
                                  userInfo.pivot.model_id ==
                                    teacher.teacher_id &&
                                  userInfo.name == 'chertea'
                                "
                                icon="trophy-fill"
                                variant="success"
                                @click="addRewardTeacher(teacher)"
                              ></b-icon>
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(competition_students)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="(student, index) in data.item
                                .competition_students"
                              :key="index"
                            >
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url !=
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  student.my_info.student_profile_photo_url +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url ==
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  '/images/users/user.png' +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              {{ student.my_info.student_last_name[0]
                              }}{{ student.my_info.student_last_name[1] }}.{{
                                student.my_info.student_name
                              }}
                              <i
                                class="fas fa-user-times"
                                @click="removeStudent(student)"
                              ></i>
                              <b-icon
                                icon="trophy-fill"
                                variant="success"
                                @click="addRewardStudentFromTeacher(student)"
                              ></b-icon>
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(actions)="data">
                        <!-- <b-icon
                          icon="trophy-fill"
                          variant="success"
                          @click="addRewardAllStudents(data.item)"
                        ></b-icon> -->
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editCompetition(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteCompetition(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactivity">
              <div class="card">
                <div class="card-header">
                  <strong>Устгагдсан олимпиад, уралдаан тэмцээн</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Устгагдсан олимпиад, уралдаан тэмцээний тоо:
                      {{ competitionsDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveCompetition"
                            :options="pageOptionsInactiveCompetitions"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveRooms"
                                @click="filterInactiveRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveCompetitions"
                      :total-rows="totalRowsInactiveCompetitions"
                      :per-page="perPageInactiveCompetition"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="competitionsDeleted"
                      :fields="fieldsInactiveCompetitions"
                      :current-page="currentPageInactiveCompetitions"
                      :per-page="perPageInactiveCompetition"
                    >
                      <!-- :filter="filterInactiveRooms"
                      :filter-included-fields="filterOnInactiveRooms"
                      @filtered="onFilteredInactiveRooms" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreCompetition(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Дэлгэрэнгүй харах -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-competition"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showCompetitionInfo.name"
      class="modal fade"
      hide-footer
      @hidden="hideInfoCompetition"
    >
      <div class="modal-body">
        <div class="direct-chat-msg" style="text-align: center">
          <img
            :src="
              showCompetitionInfo.photo_url
                ? showCompetitionInfo.photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
          />
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="hideInfoCompetition()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
    <!-- Багш бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-teachers"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelCompetitionTeacher"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(teacher, index) in formTeachers.teachers"
                :key="index"
              >
                <img
                  v-if="teacher != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                    teacher.department_name +
                    '</dd><dt>Ажлын байр:</dt><dd>' +
                    teacher.position_name +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    teacher.emp_teacher_last_name +
                    ' <span class=text-uppercase>' +
                    teacher.emp_teacher_name +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    teacher.profile_photo_url
                      ? teacher.profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formTeachers.teachers"
            deselect-label="Can't remove this value"
            :custom-label="nameTeacher"
            track-by="user_id"
            label="user_name"
            placeholder="Багш сонгоно уу!"
            :options="teachers"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                  option.emp_teacher_name
                }}</strong
              >
              Багш сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeCompetitionTeachers()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelCompetitionTeacher"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Суралцагч бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-students"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelCompetitionStudent"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(student, index) in formStudents.students"
                :key="index"
              >
                <img
                  v-if="student != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Анги:</dt><dd>' +
                    student.school_class_full_name +
                    '</dd><dt>Имэйл:</dt><dd>' +
                    student.email +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    student.pro_student_last_name +
                    ' <span class=text-uppercase>' +
                    student.pro_student_name +
                    student.student_profile_photo_url +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    student.student_profile_photo_url
                      ? student.student_profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formStudents.students"
            deselect-label="Can't remove this value"
            :custom-label="nameStudent"
            track-by="pro_user_id"
            label="pro_student_name"
            placeholder="Суралцагч сонгоно уу!"
            :options="students"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.pro_student_last_name[0].toUpperCase() }}.{{
                  option.pro_student_name
                }}</strong
              >
              Суралцагч сонгогдлоо
            </template>
          </multiselect>
          <multiselect
            v-model="formStudents.subject"
            deselect-label="Can't remove this value"
            :custom-label="nameSubject"
            track-by="id"
            label="subject_name"
            placeholder="Хичээл сонгоно уу!"
            :options="subjects"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="true"
            :clear-on-select="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.subject_name }}</strong>
              -ны/ний хичээл сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeCompetitionStudents()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelCompetitionStudent"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-competition"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @reset="hideCompetition"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-3">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Олимпиад уралдаан, тэмцээн бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">
                  Олимпиад уралдаан, тэмцээн засах
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Олимпиад, уралдаан тэмцээний нэр</label>
                    <b-form-input
                      v-model="form.name"
                      placeholder="Олимпиад, уралдаан тэмцээний нэр"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                    ></b-form-input>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="form-group">
                    <label>Олимпиадын зорилго</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.objective"
                      placeholder="Олимпиадын зорилго"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('objective'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="objective"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Бүртгэлийн мэдээлэл</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.register_info"
                      placeholder="Бүртгэлийн мэдээлэл"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('register_info'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="register_info"></has-error>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Эхлэх огноо</label>
                      <b-form-datepicker
                        id="start-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.start_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_date'),
                          },
                        ]"
                        placeholder="Эхлэх огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Дуусах огноо</label>
                      <b-form-datepicker
                        id="end-datepicker"
                        :state="false"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.finish_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('finish_date'),
                          },
                        ]"
                        placeholder="Дуусах огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="finish_date"></has-error>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Шагнал, урамшуулал</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.reward"
                      placeholder="Шагнал, урамшуулал"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('reward'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="reward"></has-error>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label
                        >Үр дүн, гүйцэтгэл хамгаалалтын мэдээлэл(Бодлого
                        хамгаалалт)</label
                      >
                      <b-form-textarea
                        id="textarea"
                        v-model="form.problem_solution_protect_info"
                        placeholder="Үр дүн, гүйцэтгэл хамгаалалтын мэдээлэл(Бодлого
                      хамгаалалт)"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has(
                              'problem_solution_protect_info'
                            ),
                          },
                        ]"
                      ></b-form-textarea>
                      <has-error
                        :form="form"
                        field="problem_solution_protect_info"
                      ></has-error>
                    </div>
                    <div class="form-group col-lg-6">
                      <label
                        >Үр дүн, гүйцэтгэл хамгаалалт хийх огноо(Бодлого
                        хамгаалалт)</label
                      >
                      <b-form-datepicker
                        id="protect-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.problem_solution_protect_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has(
                              'problem_solution_protect_date'
                            ),
                          },
                        ]"
                        placeholder="Үр дүн, гүйцэтгэл хамгаалалт хийх огноо(Бодлого
                        хамгаалалт)"
                      ></b-form-datepicker>
                      <has-error
                        :form="form"
                        field="problem_solution_protect_date"
                      ></has-error>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Шагнал гардуулах тухай</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.results_meeting"
                      placeholder="Шагнал гардуулах тухай..."
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('results_meeting'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="results_meeting"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Олимпиад, уралдаан тэмцээн болон хаяг</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.address"
                      placeholder="Олимпиад, уралдаан тэмцээн болон хаяг"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('address'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="address"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Холбоо барих мэдээлэл</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.contact_information"
                      placeholder="Холбоо барих мэдээлэл"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('contact_information'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error
                      :form="form"
                      field="contact_information"
                    ></has-error>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-2">
                      <img
                        class="profile-user-img img-fluid attachment-img mb-2"
                        :src="getSelectedItemImg"
                        alt="Хөрөнгийн зураг"
                        v-show="getSelectedItemImg"
                      />
                    </div>
                    <div class="form-group col-lg-10">
                      <b-form-file
                        id=""
                        browse-text="Зураг солих"
                        size="sm"
                        @change="getItemImg"
                        :key="fileInputKey"
                        accept="image/*"
                      ></b-form-file>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="!editMode"
                  @click.prevent="storeCompetition()"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editMode"
                  @click.prevent="updateCompetition()"
                >
                  Засах
                </button>
                <button
                  class="btn btn-secondary"
                  v-show="editMode"
                  @click.prevent="cancelCompetition"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>

    <!-- Багш ажилтан Амжилт" -->
    <b-modal
      id="modal-teacher-success"
      ref="modal"
      title="Багш нарын амжилтыг бүртгэх модуль"
      @hidden="resetTeacherSuccessModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Амжилт бүртгэх</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form @submit.prevent="createTeacherSuccessFromCompetitionTeacher()">
            <div class="card-body">
              <div class="form-group">
                <li v-if="teacherProfilePhoto !== ''">
                  <img
                    v-if="teacherProfilePhoto.my_info.profile_photo_url != null"
                    alt="Avatar"
                    v-b-tooltip.hover.top.html="
                      '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                      teacherProfilePhoto.my_info.department.department_name +
                      '</dd><dt>Овог Нэр</dt><dd>' +
                      teacherProfilePhoto.my_info.teacher_last_name +
                      ' <span class=text-uppercase>' +
                      teacherProfilePhoto.my_info.teacher_name +
                      '</span></dd></dl>'
                    "
                    class="direct-chat-img"
                    :src="
                      teacherProfilePhoto.my_info.profile_photo_url
                        ? teacherProfilePhoto.my_info.profile_photo_url
                        : '/images/users/user.png'
                    "
                  />
                  <img
                    v-if="teacherProfilePhoto.my_info.profile_photo_url == null"
                    alt="Avatar"
                    v-b-tooltip.hover.top.html="
                      '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                      teacherProfilePhoto.my_info.department.department_name +
                      '</dd><dt>Овог Нэр</dt><dd>' +
                      teacherProfilePhoto.my_info.teacher_last_name +
                      ' <span class=text-uppercase>' +
                      teacherProfilePhoto.my_info.teacher_name +
                      '</span></dd></dl>'
                    "
                    class="direct-chat-img"
                    :src="
                      teacherProfilePhoto.my_info.profile_photo_url
                        ? teacherProfilePhoto.my_info.profile_photo_url
                        : '/images/users/user.png'
                    "
                  />
                </li>
              </div>
              <div class="form-group">
                <label>Амжилт гаргасан түвшинг сонгох</label>
                <multiselect
                  v-model="formTeacherSuccess.student_turul"
                  :options="turul"
                  :multiple="false"
                  track-by="student_turul"
                  label="student_turul"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Амжилт гаргасан түвшинг сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.student_turul }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formStudentSuccess"
                  field="student_turul"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Эзэлсэн байр сонгох</label>
                <multiselect
                  v-model="formTeacherSuccess.amjilt_ezelsen_bair"
                  :options="ezelsenBair"
                  :multiple="false"
                  track-by="ezelsen_bair"
                  label="ezelsen_bair"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Эзэлсэн байр сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.ezelsen_bair }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formTeacherSuccess"
                  field="ezelsen_bair"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын нэр</label>
                <input
                  type="text"
                  name="amjilt_name"
                  v-model="formTeacherSuccess.amjilt_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        formTeacherSuccess.errors.has('amjilt_name'),
                    },
                  ]"
                  placeholder="Шагналын нэр"
                />
                <has-error
                  :form="formTeacherSuccess"
                  field="amjilt_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын тайлбар</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formTeacherSuccess.amjilt_tailbar"
                  placeholder="Гаргасан амжилтын хувьд аль болох дэлгэрэнгүй
                мэдээлэл оруулна уу?"
                >
                </b-form-textarea>
                <has-error
                  :form="formTeacherSuccess"
                  field="amjilt_tailbar"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary">Хадгалах</button>
              <button
                class="btn btn-warning"
                @click.prevent="resetTeacherSuccessModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <!-- Сурагч Амжилт -->
    <b-modal
      id="modal-student-success"
      ref="modal"
      title="Суралцагчын амжилтыг бүртгэх модуль"
      @hidden="resetStudentSuccessModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Амжилт бүртгэх</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form @submit.prevent="createStudentSuccessFromCompetition()">
            <div class="card-body">
              <div class="form-group">
                <li v-if="studentProfilePhoto !== ''">
                  <img
                    v-if="
                      studentProfilePhoto.my_info.student_profile_photo_url !=
                      null
                    "
                    alt="Avatar"
                    v-b-tooltip.hover.top.html="
                      '<dd><img src=' +
                      studentProfilePhoto.my_info.student_profile_photo_url +
                      ' style=' +
                      'width:180px!important' +
                      '></dd>' +
                      '<dl class=text-left><dt>Анги:</dt><dd>' +
                      studentProfilePhoto.my_info_school_class.full_name +
                      '</dd><dt>Овог Нэр</dt><dd>' +
                      studentProfilePhoto.my_info.student_last_name +
                      ' <span class=text-uppercase>' +
                      studentProfilePhoto.my_info.student_name +
                      '</span></dd></dl>'
                    "
                    class="direct-chat-img"
                    :src="
                      studentProfilePhoto.my_info.student_profile_photo_url
                        ? studentProfilePhoto.my_info.student_profile_photo_url
                        : '/images/users/user.png'
                    "
                  />
                  <img
                    v-if="
                      studentProfilePhoto.my_info.student_profile_photo_url ==
                      null
                    "
                    alt="Avatar"
                    v-b-tooltip.hover.top.html="
                      '<dd><img src=' +
                      '/images/users/user.png' +
                      ' style=' +
                      'width:180px!important' +
                      '></dd>' +
                      '<dl class=text-left><dt>Анги:</dt><dd>' +
                      studentProfilePhoto.my_info_school_class.full_name +
                      '</dd><dt>Овог Нэр</dt><dd>' +
                      studentProfilePhoto.my_info.student_last_name +
                      ' <span class=text-uppercase>' +
                      studentProfilePhoto.my_info.student_name +
                      '</span></dd></dl>'
                    "
                    class="direct-chat-img"
                    :src="
                      studentProfilePhoto.my_info.student_profile_photo_url
                        ? studentProfilePhoto.my_info.student_profile_photo_url
                        : '/images/users/user.png'
                    "
                  />
                </li>
              </div>
              <div class="form-group">
                <label>Амжилт гаргасан түвшинг сонгох</label>
                <multiselect
                  v-model="formStudentSuccess.student_turul"
                  :options="turul"
                  :multiple="false"
                  track-by="student_turul"
                  label="student_turul"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Амжилт гаргасан түвшинг сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.student_turul }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formStudentSuccess"
                  field="student_turul"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Эзэлсэн байр сонгох</label>
                <multiselect
                  v-model="formStudentSuccess.amjilt_ezelsen_bair"
                  :options="ezelsenBair"
                  :multiple="false"
                  track-by="ezelsen_bair"
                  label="ezelsen_bair"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Эзэлсэн байр сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.ezelsen_bair }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formStudentSuccess"
                  field="ezelsen_bair"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын нэр</label>
                <input
                  type="text"
                  name="amjilt_name"
                  v-model="formStudentSuccess.amjilt_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        formStudentSuccess.errors.has('amjilt_name'),
                    },
                  ]"
                  placeholder="Шагналын нэр"
                />
                <has-error
                  :form="formStudentSuccess"
                  field="amjilt_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын тайлбар</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formStudentSuccess.amjilt_tailbar"
                  placeholder="Гаргасан амжилтын хувьд дэлгэрэнгүй
                мэдээлэл оруулна уу?"
                >
                </b-form-textarea>
                <has-error
                  :form="formStudentSuccess"
                  field="amjilt_tailbar"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary">Хадгалах</button>
              <button
                class="btn btn-warning"
                @click.prevent="resetStudentSuccessModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      competitions: [],
      competitionsDeleted: [],
      teachers: [],
      students: [],
      subjects: [],

      //b-table тохиргоо
      totalRowsCompetitions: 1,
      currentPageCompetitions: 1,
      perPageCompetitions: 25,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCompetitions: "",
      filterOnCompetitions: [],
      filterIgnoredFieldsCompetitions: [
        "competition_teachers",
        "competition_students",
      ],
      fieldsActiveCompetitions: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Олимпиад уралдааны нэр",
          sortable: true,
        },
        // {
        //   key: "objective",
        //   label: "Зорилго",
        //   sortable: true,
        // },
        {
          key: "register_info",
          label: "Товч мэдээлэл",
          sortable: true,
        },
        {
          key: "competition_teachers",
          label: "Хариуцах багш",
          sortable: true,
        },
        {
          key: "competition_students",
          label: "Оролцох суралцагчид",
          sortable: true,
        },

        // {
        //   key: "reward",
        //   label: "Шагнал урамшуулал",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_info",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалтын мэдээлэл(Бодлого хамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_date",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалт хийх огноо(Бодлогохамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "results_meeting",
        //   label: "Шагнал гардуулах ",
        //   sortable: true,
        // },

        // {
        //   key: "updated_at",
        //   label: "Зассан",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveCompetitions: 1,
      currentPageInactiveCompetitions: 1,
      perPageInactiveCompetition: 25,
      pageOptionsInactiveCompetitions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveCompetitions: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Олимпиад уралдааны нэр",
          sortable: true,
        },
        // {
        //   key: "objective",
        //   label: "Зорилго",
        //   sortable: true,
        // },
        {
          key: "register_info",
          label: "Бүртгэлийн мэдээлэл",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Олимпиад уралдаан болох огноо",
          sortable: true,
        },

        // {
        //   key: "reward",
        //   label: "Шагнал урамшуулал",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_info",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалтын мэдээлэл(Бодлого хамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_date",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалт хийх огноо(Бодлогохамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "results_meeting",
        //   label: "Шагнал гардуулах ",
        //   sortable: true,
        // },
        {
          key: "address",
          label: "Хаяг",
          sortable: true,
        },
        {
          key: "contact_information",
          label: "Холбоо барих мэдээлэл",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        // {
        //   key: "updated_at",
        //   label: "Зассан",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      showMode: false,
      showCompetitionInfo: "",
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],

      editMode: false,
      form: new Form({
        id: "",
        // year_id: "",
        // school_id: "",
        // enrol_emp_id: "",
        name: "",
        objective: "",
        register_info: "",
        start_date: "",
        finish_date: "",
        reward: "",
        problem_solution_protect_info: "",
        problem_solution_protect_date: "",
        results_meeting: "",
        address: "",
        contact_information: "",
        photo_url: "",
      }),
      //   Зураг
      getSelectedItemImg: "",
      fileInputKey: 0,

      //   Багш
      formTeachers: new Form({
        id: "",
        competition_id: "",
        teachers: [],
      }),

      //   Суралцагч
      formStudents: new Form({
        id: "",
        competition_id: "",
        students: [],
        subject: "",
      }),

      //Амжилт бүртгэх багш
      teacherProfilePhoto: "",
      formTeacherSuccess: new Form({
        id: "",
        competition_id: "",
        employee_migration_id: "",
        emp_role: "",
        student_turul: 0,
        amjilt_ezelsen_bair: 0,
        amjilt_name: "",
        amjilt_tailbar: "",
        teacherInfo: "",
      }),
      //Амжилт бүртгэх суралцагч
      studentProfilePhoto: "",
      formStudentSuccess: new Form({
        id: "",
        competition_id: "",
        student_migration_id: "",
        stu_role: "",
        // student_buleg: "",
        // student_tuvshin: "",
        student_turul: 0,
        amjilt_ezelsen_bair: 0,
        amjilt_name: "",
        amjilt_tailbar: "",
        studentInfo: "",
      }),
      turul: [
        { name: 0, student_turul: "Түвшин сонгох" },
        { name: 1, student_turul: "Анги" },
        { name: 2, student_turul: "Сургууль" },
        { name: 3, student_turul: "Дүүрэг" },
        { name: 4, student_turul: "Нийслэл" },
        { name: 5, student_turul: "Улс" },
        { name: 6, student_turul: "Олон улс" },
      ],
      ezelsenBair: [
        { name: 0, ezelsen_bair: "Сонголт хийнэ үү?" },
        { name: 1, ezelsen_bair: "Алт" },
        { name: 2, ezelsen_bair: "Мөнгө" },
        { name: 3, ezelsen_bair: "Хүрэл" },
        { name: 4, ezelsen_bair: "Тусгай байр(4-10)" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  methods: {
    getAllCompetitions() {
      this.show = true;
      axios
        .get("/teacher/getAllCompetitionsTeacher")
        .then((response) => {
          //   this.competitions =

          let check = response.data.competitions;
          //   console.log(check);
          let arr = [];
          check.forEach((element) => {
            if (element.my_owner_competition != null) {
              arr.push(element.my_owner_competition);
            }
          });
          this.competitions = arr;

          let check1 = response.data.competitionsDeleted;
          //   console.log(check1);
          let arr1 = [];
          check1.forEach((element) => {
            if (element.my_owner_competition != null) {
              arr1.push(element.my_owner_competition);
            }
          });
          this.competitionsDeleted = arr1;
          this.teachers = response.data.teachers;
          this.students = response.data.students;
          this.subjects = response.data.subjects;
          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //b-table тохирго
    onFilteredCompetitions(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCompetitions = filteredItems.length;
      this.currentPageCompetitions = 1;
    },

    createCompetition() {
      this.$bvModal.show("modal-competition");
    },

    hideCompetition() {
      Fire.$emit("getCompetitions");
      this.getSelectedItemImg = "";
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-competition");
    },
    cancelCompetition() {
      this.getSelectedItemImg = "";
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-competition");
    },
    storeCompetition() {
      this.form
        .post("/teacher/storeCompetitionTeacher")
        .then((response) => {
          this.$toastr.s("Олимпиадын мэдээлэл амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getCompetitions");
          this.hideCompetition();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    editCompetition(competition) {
      //   console.log(competition);
      this.editMode = true;
      this.form.fill(competition);
      this.getSelectedItemImg = competition.photo_url;
      this.form.photo_url = "";
      this.$bvModal.show("modal-competition");
    },
    updateCompetition() {
      //   console.log(this.form);
      this.form
        .put("/teacher/updateCompetitionTeacher/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getCompetitions");
          this.hideCompetition();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    showCompetition(competition) {
      this.showCompetitionInfo = competition;
      this.$bvModal.show("modal-show-competition");
    },
    hideInfoCompetition() {
      this.showCompetitionInfo = "";
      this.$bvModal.hide("modal-show-competition");
    },
    editMonthPlan(monthPlan) {
      this.editMode = true;
      this.form.reset();
      this.form.fill(monthPlan);
      this.form.recieved_employees = [];
      this.filterLeaderForm.department_id = monthPlan.department_id;
      this.changeOwnerTeacherList();
    },

    deleteCompetition(competition) {
      //   console.log(competition);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд олимпиад, уралдаан тэмцээний мэдээлэл болон бусад холбогдох мэдээллүүд устах болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteCompetitionTeacher/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Олимпиад, уралдаан тэмцээний мэдээлэл системээс устгалаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreCompetition(competition) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/restoreCompetitionTeacher/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Олимпиад, уралдаан тэмцээний мэдээллийг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    getItemImg(e) {
      let itemImage = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(itemImage);
      reader.onload = (e) => {
        this.form.photo_url = e.target.result;
        this.getSelectedItemImg = e.target.result;
      };
      //   this.loaded = true;
    },

    //Багш бүртгэх
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    hideCompetitionTeacher() {
      //   Fire.$emit("getCompetitions");
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    cancelCompetitionTeacher() {
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    createCompetitionTeacher(competition) {
      console.log(competition);
      this.formTeachers.competition_id = competition.id;
      this.$bvModal.show("modal-create-teachers");
    },
    storeCompetitionTeachers() {
      this.formTeachers
        .post("/teacher/storeCompetitionTeachers")
        .then((response) => {
          this.$toastr.s("Олимпиад хариуцах багшийн нэмлээ", "Бүртгэлээ");
          Fire.$emit("getCompetitions");
          this.hideCompetitionTeacher();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeTeacher(teacher) {
      console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Олимпиад, уралдаан тэмцээн хариуцах багш хасах!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/removeCompetitionTeacher", {
              competition_id: teacher.competition_id,
              teacher_id: teacher.teacher_id,
            })
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Олимпиад, уралдаан тэмцээн хариуцах багшийг хаслаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    //Суралцагч бүртгэх
    nameStudent({
      pro_student_last_name,
      pro_student_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name[0].toUpperCase()}${
        pro_student_last_name[1]
      } . ${pro_student_name} : ${school_class_full_name}`;
    },
    nameSubject({ subject_name }) {
      return `${subject_name}`;
    },
    hideCompetitionStudent() {
      //   Fire.$emit("getCompetitions");
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    cancelCompetitionStudent() {
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    createCompetitionStudent(competition) {
      console.log(competition);
      this.formStudents.competition_id = competition.id;
      this.$bvModal.show("modal-create-students");
    },

    storeCompetitionStudents() {
      this.formStudents
        .post("/teacher/storeCompetitionStudentsTeacher")
        .then((response) => {
          this.$toastr.s(
            "Олимпиад, уралдаан тэмцээнд оролцох суралцагчдыг нэмлээ",
            "Бүртгэлээ"
          );
          Fire.$emit("getCompetitions");
          this.hideCompetitionStudent();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    removeStudent(student) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Олимпиад, уралдаан тэмцээнээс тухайн суралцагчийг хасах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/removeCompetitionStudentTeacher", {
              competition_id: student.competition_id,
              school_class_id: student.school_class_id,
              student_id: student.student_id,
              subject_id: student.subject_id,
            })
            .then((res) => {
              Swal.fire(
                "Хассан!",
                "Олимпиад, уралдаан тэмцээнээс суралцагчийг хаслаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    // Амжилт бүртгэх
    addRewardStudentFromTeacher(student) {
      this.studentProfilePhoto = student;
      axios
        .post("/teacher/getStudentSuccessFromCompetitionTeacher", {
          studentInfo: student,
        })
        .then((res) => {
          //   console.log(res.data);
          let getSucInfo = res.data.studentsSuccessInfo;
          if (getSucInfo) {
            this.formStudentSuccess.fill(getSucInfo);
            this.turul.filter((el) => {
              if (el.name == getSucInfo.student_turul) {
                this.formStudentSuccess.student_turul = el;
              }
            });

            this.ezelsenBair.filter((el) => {
              if (el.name == getSucInfo.amjilt_ezelsen_bair) {
                this.formStudentSuccess.amjilt_ezelsen_bair = el;
              }
            });
            // this.formStudentSuccess.studentInfo = getSucInfo;
            this.formStudentSuccess.studentInfo = student;
            // console.log(getSucInfo);
            this.formStudentSuccess.student_migration_id =
              getSucInfo.stud_mig_id;
            this.$bvModal.show("modal-student-success");
          } else {
            this.formStudentSuccess.studentInfo = student;
            this.formStudentSuccess.competition_id = student.competition_id;
            this.$bvModal.show("modal-student-success");
          }
        })
        .catch((err) => console.log(err));
    },
    createStudentSuccessFromCompetition() {
      this.formStudentSuccess
        .post("/teacher/createStudentSuccessFromCompetitionTeacher")
        .then((res) => {
          this.resetStudentSuccessModal();
          Fire.$emit("loadSchoolSuccess");
          this.$toastr.s(
            "Суралцагчын гаргасан амжилтыг бүртгэлээ.",
            "Амжилттай"
          );
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customStudents({
      school_class_full_name,
      pro_student_last_name,
      pro_student_name,
      email,
    }) {
      return `${school_class_full_name} : ${pro_student_last_name[0]}. ${pro_student_name}: ${email}`;
    },
    resetStudentSuccessModal() {
      this.formStudentSuccess.reset();
      this.$bvModal.hide("modal-student-success");
    },
    // Амжилт бүртгэх багш
    addRewardTeacher(teacher) {
      //   console.log(teacher);
      this.teacherProfilePhoto = teacher;
      axios
        .post("/teacher/getTeacherSuccessFromCompetitionTeacher", {
          teacherInfo: teacher,
        })
        .then((res) => {
          //   console.log(res.data);
          let getSucInfo = res.data.teachersSuccessInfo;
          if (getSucInfo) {
            this.formTeacherSuccess.fill(getSucInfo);
            this.turul.filter((el) => {
              if (el.name == getSucInfo.student_turul) {
                this.formTeacherSuccess.student_turul = el;
              }
            });

            this.ezelsenBair.filter((el) => {
              if (el.name == getSucInfo.amjilt_ezelsen_bair) {
                this.formTeacherSuccess.amjilt_ezelsen_bair = el;
              }
            });
            this.formTeacherSuccess.teacherInfo = getSucInfo;
            this.formTeacherSuccess.employee_migration_id =
              getSucInfo.emp_mig_id;
            this.$bvModal.show("modal-teacher-success");
          } else {
            this.formTeacherSuccess.teacherInfo = teacher;
            this.formTeacherSuccess.competition_id = teacher.competition_id;
            this.$bvModal.show("modal-teacher-success");
          }
        })
        .catch((err) => console.log(err));
    },
    resetTeacherSuccessModal() {
      this.formTeacherSuccess.reset();
      this.$bvModal.hide("modal-teacher-success");
    },
    createTeacherSuccessFromCompetitionTeacher() {
      this.formTeacherSuccess
        .post("/teacher/createTeacherSuccessFromCompetitionTeacher")
        .then((res) => {
          this.$toastr.s("Ажилтны гаргасан амжилтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolSuccess");
          this.resetTeacherSuccessModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customTeachers({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },

    customCompetitionName({ name }) {
      return `${name}`;
    },
  },
  created() {
    this.getAllCompetitions();
    Fire.$on("getCompetitions", () => {
      this.getAllCompetitions();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
