<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">
              Хүмүүжлийн хөтөлбөр хариуцсан менежерийн үнэлгээ хийх хуудас
            </h3>
            <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
            <!-- Button trigger modal -->
            <b-button @click="createTWE" class="btn btn-primary"
              >АУБ-ийн ажлын үнэлгээ хийх</b-button
            >
          </div>
        </div>
        <div class="card-body table-responsive">
          <multiselect
            v-model="school_year_id"
            select-label="Сонгох"
            selected-label="Сонгогдсон"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жил сонгоно уу?"
            :options="years"
            :searchable="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{ option.finish_date | dateYear }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <table class="table table-striped table-valign-middle mb-5">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Овог Нэр</th>
                <th>Имэйл хаяг</th>
                <th>Албан тушаал</th>
                <th>Сар</th>
                <th
                  v-b-popover.hover.righttop="
                    '• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас доош хэрнээ өмнөх улирлынхаа үзүүлэлтийг баталсан бол Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас доош хэрнээ өмнөх сарынхаа үзүүлэлтийг ахиулсан бол Маш сайн (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас дээш бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн нэмэгдэл хөлс;'
                  "
                  title="Удирдсан ангийн суралцагчдын хүмүүжилд гарсан ахиц, өөрчлөлт;"
                >
                  Ш-5
                </th>
                <th
                  v-b-popover.hover.righttop="
                    '• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 60-75%-ийн гүйцэтгэлтэй хамрагдсан бол Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 76-85%-ийн гүйцэтгэлтэй хамрагдсан бол Маш сайн (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 86%-иас дээш гүйцэтгэлтэй хамрагдсан бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн нэмэгдэл хөлс;'
                  "
                  title="Удирдсан ангийн суралцагчдын хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд хамрагдсан байдалд гарсан ахиц, өөрчлөлт;"
                >
                  Ш-6
                </th>

                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(teacherEvaluation, index) in teachersEvaluations"
                :key="teacherEvaluation.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary"
                    >{{ teacherEvaluation.emp_teacher_last_name[0].toUpperCase() }}.
                    {{ teacherEvaluation.emp_teacher_name }}</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-success">
                    {{ teacherEvaluation.user_email }}
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span>
                    {{ teacherEvaluation.position_name }}
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.ajliin_sar }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.shal5 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.shal6 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(teacherEvaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTeacherEvaluation(teacherEvaluation)"
                    ><i class="fas fa-edit"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submit Your Name"
      size="xl"
      @show="resetModal"
      @hidden="cancelEdit"
      @ok="handleOk"
      hide-footer
    >
      <template v-slot:modal-title>
        <span v-show="!editMode"> Багшийн ажлын үнэлгээ бүртгэх </span>
        <span v-show="editMode"> Багшийн ажлын үнэлгээ засах </span>
      </template>
      <template>
        <form
          @submit.prevent="
            !editMode ? createTeacherEvaluation() : updateTeacherEvaluation()
          "
        >
          <table cellspacing="0" border="0">
            <colgroup width="272"></colgroup>
            <colgroup width="146"></colgroup>
            <colgroup width="167"></colgroup>
            <colgroup width="345"></colgroup>
            <colgroup width="113"></colgroup>
            <colgroup width="91"></colgroup>
            <colgroup width="107"></colgroup>
            <colgroup width="233"></colgroup>
            <colgroup width="198"></colgroup>
            <tr>
              <td colspan="9">
                БАТЛАВ: Сургалт хариуцсан захирал___________________/Э.Золбоо/
              </td>
              <td height="19" align="left" valign="bottom">
                <font face="Tahoma"> </font>
              </td>
              <td align="left" valign="asdfbotasdftom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="17" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <tr>
              <td colspan="7" height="26" align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    >Ажилтны ажлын гүйцэтгэлийг дүгнэх хуудас (KPI)
                  </font></b
                >
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="26" align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
            </tr>
            <!-- Овог нэр -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Овог нэр: </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <!-- {{ editMode }} -->
                  <multiselect
                    v-model="form.employee_id"
                    deselect-label="Can't remove this value"
                    :custom-label="nameTeacher"
                    track-by="emp_user_id"
                    label="emp_user_id"
                    placeholder="Select one"
                    :options="allTeachers"
                    :searchable="true"
                    :allow-empty="false"
                    @select="toggleSelect"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                          option.emp_teacher_name
                        }}</strong
                      >
                      багш сонгогдлоо
                    </template>
                  </multiselect>
                  <!-- <span v-if="!editMode">{{ form.nameEmpCheck }}</span> -->
                </font>
                <font v-if="editMode">{{ nameEmpCheck }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Албан тушаал -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Албан тушаал:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  {{ form.employee_id.position_name }}
                </font>
                <b v-if="editMode"> {{ form.employee_id.position_name }}</b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Үнэлгээ хийсэн хугацаа:</font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000">{{ timestamp }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Ажлын сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Үнэлгээ хийх сар сонгох:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <multiselect
                    v-model="form.ajliin_sar"
                    deselect-label="Can't remove this value"
                    :options="months"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Ажлын сар сонгоно уу?"
                  ></multiselect>
                </font>
                <font v-if="editMode">{{ form.ajliin_sar }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- зай авсан -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Хүснэгтийн эхлэх -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                height="38"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Гүйцэтгэлийн шалгуур үзүүлэлтүүд</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b><font face="Tahoma" size="1.2" color="#000000">Хэмжих нэгж</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Зорилтот үр дүн / Хэмжүүр</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b><font face="Tahoma" size="1.2" color="#000000">Эх сурвалж</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                colspan="3"
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b><font face="Tahoma" size="1.2" color="#000000">Үнэлгээ</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b
                  ><font face="Tahoma" color="#000000" size="1">Тайлбар<br /></font
                ></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b
                  ><font face="Tahoma" color="#000000" size="1">Шалтгаан<br /></font
                ></b>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td
                style="
                  border-top: 0.1px solid #000000;
                  border-bottom: 0.1px solid #000000;
                  border-left: 0.1px solid #000000;
                  border-right: 0.1px solid #000000;
                "
                height="63"
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Ажлын гүйцэтгэлийг илэрхийлэх гол үзүүлэлтүүд
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i><font face="Tahoma" size="1">Үндсэн цалингийн хувь </font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Тухайн үзүүлэлтийн хүрээнд тавигдаж буй зорилт
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Үнэлгээ хийх эрх зүйн баримт бичиг
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1">Эзлэх жин <br />(Ач холбогдол)</font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                  padding: 50px;
                "
                align="center"
                valign="middle"
              >
                <i><font face="Tahoma" size="1">Биелэлт</font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i><font face="Tahoma" size="1">Тооцох хувь</font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i><font face="Tahoma" size="1">Үнэлгээ хийсэн аргачлал </font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i><font face="Tahoma" size="1">Биелэлт дутуу байгаа шалтгаан</font></i>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Удирдсан ангийн суралцагчдын хүмүүжилд гарсан ахиц, өөрчлөлт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Удирдсан ангийн суралцагчдын хүмүүжилд гарсан ахиц, өөрчлөлтийг дараах
                  шалгуур үзүүлэлтүүдийг үндэслэн үнэлэх бөгөөд 150’000 төгрөг хүртэлх
                  хэмжээтэй нэмэгдэл хөлсийг улирал бүр гүйцэтгэлээр нь тооцож олгоно;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж
                  үзүүлэлт 80%-иас доош хэрнээ өмнөх улирлынхаа үзүүлэлтийг баталсан бол
                  Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс; <br />
                  • Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт
                  80%-иас доош хэрнээ өмнөх сарынхаа үзүүлэлтийг ахиулсан бол Маш сайн
                  (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;<br />
                  • Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт
                  80%-иас дээш бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн
                  нэмэгдэл хөлс; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                rowspan="2"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b><font face="Tahoma">100%</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal5"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber5"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma">Тооцоолж гаргах%</font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж
                  үзүүлэлт 80%-иас доош хэрнээ өмнөх улирлынхаа үзүүлэлтийг баталсан бол
                  Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс; <br />
                  • Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт
                  80%-иас доош хэрнээ өмнөх сарынхаа үзүүлэлтийг ахиулсан бол Маш сайн
                  (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;<br />
                  • Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт
                  80%-иас дээш бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн
                  нэмэгдэл хөлс; <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal5_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Удирдсан ангийн суралцагчдын хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд
                  хамрагдсан байдалд гарсан ахиц, өөрчлөлт;
                </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байгаа
                  эсэх; <br
                /></font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b><font face="Tahoma">100%</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal6"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber6"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma">Тооцоолж тавих%</font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн
                  ажилд дунджаар 60-75%-ийн гүйцэтгэлтэй хамрагдсан бол Сайн (80%) гэж
                  үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс;
                  <br />
                  • Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн
                  ажилд дунджаар 76-85%-ийн гүйцэтгэлтэй хамрагдсан бол Маш сайн (90%) гэж
                  үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;
                  <br />
                  • Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн
                  ажилд дунджаар 86%-иас дээш гүйцэтгэлтэй хамрагдсан бол Үлгэр жишээч
                  (100%) гэж үнэлээд 150’000 төгрөгийн нэмэгдэл хөлс;<br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal6_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>

            <!-- Хөлний тооцоолол -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="17"
                align="center"
                valign="middle"
              >
                <b><font face="Tahoma" size="1.2">Нийт</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma">20%<br /></font
                ></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <b
                  ><font face="Tahoma">
                    {{ average() }}
                    %</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"> {{ calculatePercent() }}% <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8992"
                sdnum="1033;0;0%"
              >
                <b><font face="Tahoma" size="1.2">Баганаар тооцоолж гаргах%</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Суралцагчдын амралтын хугацааны үр дүнгийн урамшууллыг хасаж тооцно.
                  <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdval="0.40464" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Ажилтан:</font></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" v-if="form.employee_id"
                  >{{ form.employee_id.emp_teacher_last_name[0] }}.{{
                    form.employee_id.emp_teacher_name
                  }}</font
                >
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Шууд удирдлага -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Шууд удирдлага:</font></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma">Э.Золбоо</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Огноо:</font></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdval="44164" sdnum="1033;0;M/D/YYYY">
                <font face="Tahoma">{{ form.timestamp }}</font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
          </table>
          <b-button class="mt-3" variant="outline-danger" block @click="cancelEdit"
            >Цуцлах</b-button
          >
          <b-button type="submit" class="mt-2" variant="outline-warning" block
            >Хадгалах</b-button
          >
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      school_year_id: "",
      years: [],
      action: "",
      editMode: false,
      //   keyword: "",
      teachersEvaluations: [],
      allTeachers: [],
      positions: [],
      nameEmpCheck: "",
      namePositionNameCheck: "",
      form: new Form({
        id: "",
        employee_id: "",
        ajliin_sar: "",
        shal5: "",
        shal5_jin_huvi: "",
        shal5_temd: "",
        shal6: "",
        shal6_jin_huvi: "",
        shal6_temd: "",
      }),
      timestamp: "",
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    };
  },
  watch: {
    school_year_id: function (newVal, oldVal) {
      this.getAllData();
    },
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    getYearList() {
      axios
        .get("/manager/getYearList")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getAllData() {
      axios
        .post("/all_worker_functions/getAllAubTeacherevaluation", {
          yearId: this.school_year_id.id,
        })
        .then((response) => {
          this.teachersEvaluations = response.data.allTeachersEvaluations;
          this.allTeachers = response.data.teachers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTeacherEvaluation(teacherEvaluation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", teacherEvaluation);

      this.form.reset();
      //   this.form.fill(teacherEvaluation);

      this.$nextTick(() => {
        // console.log("nextTick");
        this.editMode = true;
        this.nameEmpCheck =
          teacherEvaluation.emp_teacher_last_name[0].toUpperCase() +
          "." +
          teacherEvaluation.emp_teacher_name;
        this.namePositionNameCheck = teacherEvaluation.position_name;
        this.form.id = teacherEvaluation.id;
        this.form.employee_id = teacherEvaluation;
        this.form.ajliin_sar = teacherEvaluation.ajliin_sar;
        this.form.shal5 = teacherEvaluation.shal5;
        this.form.shal5_temd = teacherEvaluation.shal5_temd;
        this.form.shal6 = teacherEvaluation.shal6;
        this.form.shal6_temd = teacherEvaluation.shal6_temd;
        this.$bvModal.show("modal-prevent-closing");
      });
    },
    updateTeacherEvaluation() {
      //   this.action = "Бүртгэлийн ажилтны мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateAubTeacherEvaluation/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      //   console.log("Хаалаа");
      this.editMode = false;
      this.form.reset();
      this.nameEmpCheck = "";
      this.namePositionNameCheck = "";
      this.$bvModal.hide("modal-prevent-closing");
    },
    // deleteSchoolEmployee(schoolteacher) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
    createTWE() {
      this.form.reset();
      this.$bvModal.show("modal-prevent-closing");
    },
    createTeacherEvaluation() {
      this.action = "Багшийн ажлын үнэлгээг хийж байна...";
      this.form
        .post("/all_worker_functions/createAubTeacherEvaluation")
        .then((response) => {
          this.$toastr.s("Багшийн үнэлгээг хийлээ.", "Амжилттай");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch(() => {
          // console.log(errors.all());
          this.$toastr.e(
            "Багшийн үнэлгээг бүртгэж чадсангүй, Нэг багшид сарын үнэлгээг давхцуулж оруулах боломжгүй, Багшийн нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
            "Алдаа гарлаа."
          );
        });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    toggleSelect() {
      //   console.log("toggleSelect");
    },
    average() {
      return (parseFloat(this.form.shal5) + parseFloat(this.form.shal6)) / 2;
    },
    calculatePercent() {
      let averagePer = (parseFloat(this.form.shal5) + parseFloat(this.form.shal6)) / 2;
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },
    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  computed: {
    validationNumber5() {
      return this.form.shal5 >= 0 && this.form.shal5 <= 100;
    },
    validationNumber6() {
      return this.form.shal6 >= 0 && this.form.shal6 <= 100;
    },
  },
  created() {
    this.getYearList();
    this.getAllData();
    Fire.$on("loadSchoolTeachers", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table {
  min-height: 300px;
  font-size: 60%;
}
</style>
