var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
    '<dd><img src=' +
    _vm.myImgUrl +
    ' style=' +
    'width:180px!important' +
    '></dd><dt>...</dt><dd>' +
    _vm.lastName +
    ' <span class=text-uppercase>' +
    _vm.name +
    '</span></dd></dl>'
  ),expression:"\n    '<dd><img src=' +\n    myImgUrl +\n    ' style=' +\n    'width:180px!important' +\n    '></dd><dt>...</dt><dd>' +\n    lastName +\n    ' <span class=text-uppercase>' +\n    name +\n    '</span></dd></dl>'\n  ",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"imgZoom img-bordered-sm",attrs:{"alt":"user-avatar","src":_vm.myImgUrl ? _vm.myImgUrl : '/images/users/user.png'}})}
var staticRenderFns = []

export { render, staticRenderFns }