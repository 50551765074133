<template>
  <section class="content">
    <div class="container-fluid">
      <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-body">
                <h5><b>СУРГАЛТЫН ЭЭЛЖИТ ҮЙЛ АЖИЛЛАГААНЫ БАГШИЙН ҮНЭЛГЭЭ</b></h5>
                <table class="table table-bordered text-center">
                  <tbody>
                    <tr role="row">
                      <th rowspan="2">Хэсэг</th>
                      <th rowspan="2">№</th>
                      <th rowspan="2">Шалгуур үзүүлэлт</th>
                      <th colspan="10">
                        Сургалтын ээлжит үйл ажиллагааны сарын үнэлгээ (Оноо)
                      </th>
                      <th colspan="2">Жилийн эцсийн үнэлгээ</th>
                    </tr>
                    <tr role="row">
                      <th v-for="month in range1" :key="month">
                        {{ month }}
                      </th>
                      <th v-for="month in 6" :key="month">
                        {{ month }}
                      </th>
                      <th>Дундаж оноо</th>
                      <th>Гүйцэтгэлийн хувь</th>
                    </tr>
                    <tr
                      role="row"
                      v-for="(measure, index) in measurements1"
                      :key="measure.number"
                    >
                      <th rowspan="3" v-if="index == 0">Эхлэл хэсэг</th>
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ measure.label }}
                      </td>
                      <td v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-if="measure.number == 1">
                        {{ average1[0].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 2">
                        {{ average1[1].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 3">
                        {{ average1[2].toFixed(2) }}
                      </td>
                      <td v-if="measure.number == 1">
                        {{ ((average1[0] / 3) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 2">
                        {{ ((average1[1] / 2) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 3">
                        {{ ((average1[2] / 3) * 100).toFixed(2) }}%
                      </td>
                    </tr>
                    <tr role="row">
                      <th colspan="3">Эхлэл хэсгийн нийт оноо</th>
                      <th v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas(measurements1.total1, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas(measurements1.total1, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th>{{ total1[0].toFixed(2) }}</th>
                      <th>{{ ((total1[0] / 8) * 100).toFixed(2) }}%</th>
                    </tr>
                    <tr
                      role="row"
                      v-for="(measure, index) in measurements2"
                      :key="measure.number"
                    >
                      <th rowspan="5" v-if="index == 0">Өрнөл Хэсэг</th>
                      <td>{{ index + 4 }}</td>
                      <td>
                        {{ measure.label }}
                      </td>
                      <td v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas2(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas2(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-if="measure.number == 4">
                        {{ average2[0].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 5">
                        {{ average2[1].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 6">
                        {{ average2[2].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 7">
                        {{ average2[3].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 8">
                        {{ average2[4].toFixed(2) }}
                      </td>
                      <td v-if="measure.number == 4">
                        {{ ((average2[0] / 4) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 5">
                        {{ ((average2[1] / 3) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 6">
                        {{ ((average2[2] / 3) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 7">
                        {{ ((average2[3] / 3) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 8">
                        {{ ((average2[4] / 3) * 100).toFixed(2) }}%
                      </td>
                    </tr>
                    <tr role="row">
                      <th colspan="3">Өрнөл хэсгийн нийт оноо</th>
                      <th v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas2(measurements2.total2, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas2(measurements2.total2, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th>{{ total2[0].toFixed(2) }}</th>
                      <th>{{ ((total2[0] / 16) * 100).toFixed(2) }}%</th>
                    </tr>

                    <tr
                      role="row"
                      v-for="(measure, index) in measurements3"
                      :key="measure.number"
                    >
                      <th rowspan="2" v-if="index == 0">Төгсгөл Хэсэг</th>
                      <td>{{ index + 9 }}</td>
                      <td>
                        {{ measure.label }}
                      </td>
                      <td v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas3(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas3(measure.number, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </td>
                      <td v-if="measure.number == 9">
                        {{ average3[0].toFixed(2) }}
                      </td>
                      <td v-else-if="measure.number == 10">
                        {{ average3[1].toFixed(2) }}
                      </td>
                      <td v-if="measure.number == 9">
                        {{ ((average3[0] / 3) * 100).toFixed(2) }}%
                      </td>
                      <td v-if="measure.number == 10">
                        {{ ((average3[1] / 3) * 100).toFixed(2) }}%
                      </td>
                    </tr>
                    <tr role="row">
                      <th colspan="3">Төгсгөл хэсгийн нийт оноо</th>
                      <th v-for="month in range1" :key="month">
                        <span>
                          {{ getShalas3(measurements3.total3, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th v-for="month in 6" :key="month">
                        <span>
                          {{ getShalas3(measurements3.total3, month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th>{{ total3[0].toFixed(2) }}</th>
                      <th>{{ ((total3[0] / 6) * 100).toFixed(2) }}%</th>
                    </tr>
                    <tr role="row">
                      <th colspan="3">Ерөнхий үнэлгээ (оноо)</th>
                      <th v-for="month in range1" :key="month">
                        <span>
                          {{ getShalasTotal(month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th v-for="month in 6" :key="month">
                        <span>
                          {{ getShalasTotal(month) }}
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th>
                        {{ (total3[0] + total2[0] + total1[0]).toFixed(2) }}
                      </th>
                      <th></th>
                    </tr>
                    <tr role="row">
                      <th colspan="3">Ерөнхий үнэлгээ (хувь)</th>
                      <th v-for="month in range1" :key="month">
                        <span>
                          {{ ((getShalasTotal(month) / 30) * 100).toFixed(2) }}%
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th v-for="month in 6" :key="month">
                        <span>
                          {{ ((getShalasTotal(month) / 30) * 100).toFixed(2) }}%
                        </span>
                        <!-- <span v-else>0</span> -->
                      </th>
                      <th></th>
                      <th>
                        {{
                          (
                            ((total3[0] + total2[0] + total1[0]) / 30) *
                            100
                          ).toFixed(2)
                        }}%
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-lg-6">
                    <apexchart
                      v-if="!showChart"
                      type="radar"
                      height="350"
                      :options="chartStat"
                      :series="seriesStat"
                    ></apexchart>
                  </div>
                  <div class="col-lg-6">
                    <apexchart
                      v-if="!showChart"
                      type="radar"
                      height="350"
                      :options="chartStatMonth"
                      :series="seriesStatMonth"
                    ></apexchart>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["teacherId"],
  data() {
    return {
      evals: [],
      measurements1: [
        {
          number: 1,
          label: "Зорилгоо оновчтой томёолсон эсэх",
        },
        {
          number: 2,
          label:
            "Зорилготойгоо холбоотой эрэлт хэрэгцээ, сэдэл, сэтгэл, хөдлөлийг хянах, чиглүүлэх, байдлаар зорилгоо хүлээн зөвшөөрүүлсэн эсэх",
        },
        {
          number: 3,
          label:
            "Зорилгоо ойлгосон эсэхийг нь хянах, үйл ажиллагааг нь төвлөрүүлсэн эсэх",
        },
      ],
      measurements2: [
        {
          number: 4,
          label:
            "Судлагдахууны агуулгыг элементарчилж, эмх цэгцтэй хүргэсэн эсэх",
        },
        {
          number: 5,
          label:
            "Даалгаварын тавил суралцагчдад давамгайлж буй мэдээллийг хүлээн авах хэв шинжинд нийцсэн эсэх",
        },
        {
          number: 6,
          label:
            "Үйл ажиллагааны хэлбэр суралцагчдад давамгайлж буй сурахуйн хэв шинжид нийцсэн эсэх",
        },
        {
          number: 7,
          label:
            "Суралцагчдын гүйцэтгэл дээр урам дэм өгч, магтаал сайшаал хэлсэн эсэх",
        },
        {
          number: 8,
          label:
            "Суралцагчдын оюуны үйлийн когнитив системийг бүрэн ажиллуулсан эсэх",
        },
      ],
      measurements3: [
        {
          number: 9,
          label:
            "Суралцагчдын гүйцэтгэлд хяналт тавьж гүйцэтгэлийг нэгтгэн дүгнэсэн эсэх",
        },
        {
          number: 10,
          label:
            "Эзэмшсэн мэдлэг чадварынх нь ач холбогдол, үнэ цэнийг үнэлүүлж хүлээн зөвшөөрүүлсэн эсэх",
        },
      ],
      value: null,
      showStat: true,
      seriesStat: [
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      chartStat: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: [
            "Шалгуур1",
            "Шалгуур2",
            "Шалгуур3",
            "Шалгуур4",
            "Шалгуур5",
            "Шалгуур6",
            "Шалгуур7",
            "Шалгуур8",
            "Шалгуур9",
            "Шалгуур10",
          ],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
      seriesStatMonth: [
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      chartStatMonth: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
    };
  },
  computed: {
    range1() {
      return Array.from({ length: 4 }, (_, i) => i + 9);
    },
    average1() {
      let avg = [];
      let avg1 = [];
      let avg2 = [];
      let avg3 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.shalles1));
        avg2.push(parseFloat(element.shalles2));
        avg3.push(parseFloat(element.shalles3));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      const sum2 = avg2.reduce((a, b) => a + b, 0);
      const sum3 = avg3.reduce((a, b) => a + b, 0);
      avg.push(
        sum1 / this.evals.length,
        sum2 / this.evals.length,
        sum3 / this.evals.length
      );

      return avg;
    },
    average2() {
      let avg = [];
      let avg1 = [];
      let avg2 = [];
      let avg3 = [];
      let avg4 = [];
      let avg5 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.shalles4));
        avg2.push(parseFloat(element.shalles5));
        avg3.push(parseFloat(element.shalles6));
        avg4.push(parseFloat(element.shalles7));
        avg5.push(parseFloat(element.shalles8));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      const sum2 = avg2.reduce((a, b) => a + b, 0);
      const sum3 = avg3.reduce((a, b) => a + b, 0);
      const sum4 = avg4.reduce((a, b) => a + b, 0);
      const sum5 = avg5.reduce((a, b) => a + b, 0);
      avg.push(
        sum1 / this.evals.length,
        sum2 / this.evals.length,
        sum3 / this.evals.length,
        sum4 / this.evals.length,
        sum5 / this.evals.length
      );

      return avg;
    },
    average3() {
      let avg = [];
      let avg1 = [];
      let avg2 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.shalles9));
        avg2.push(parseFloat(element.shalles10));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      const sum2 = avg2.reduce((a, b) => a + b, 0);
      avg.push(sum1 / this.evals.length, sum2 / this.evals.length);

      return avg;
    },
    total1() {
      let avg = [];
      let avg1 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.total1));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      avg.push(sum1 / this.evals.length);

      return avg;
    },
    total2() {
      let avg = [];
      let avg1 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.total2));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      avg.push(sum1 / this.evals.length);

      return avg;
    },
    total3() {
      let avg = [];
      let avg1 = [];
      this.evals.forEach((element) => {
        avg1.push(parseFloat(element.total3));
      });
      const sum1 = avg1.reduce((a, b) => a + b, 0);
      avg.push(sum1 / this.evals.length);

      return avg;
    },
  },
  methods: {
    getShalas(number, month) {
      let obj = this.evals.filter((item) => item.month === month);
      if (obj.length > 0) {
        if (number == 1) return obj[0].shalles1;
        else if (number == 2) return obj[0].shalles2;
        else if (number == 3) return obj[0].shalles3;
        return obj[0].total1;
      } else return 0;
    },
    getShalas2(number, month) {
      let obj = this.evals.filter((item) => item.month === month);
      if (obj.length > 0) {
        if (number == 4) return obj[0].shalles4;
        else if (number == 5) return obj[0].shalles5;
        else if (number == 6) return obj[0].shalles6;
        else if (number == 7) return obj[0].shalles7;
        else if (number == 8) return obj[0].shalles8;
        else return obj[0].total2;
      } else return 0;
    },
    getShalas3(number, month) {
      let obj = this.evals.filter((item) => item.month === month);
      if (obj.length > 0) {
        if (number == 9) return obj[0].shalles9;
        if (number == 10) return obj[0].shalles10;
        else return obj[0].total3;
      } else return 0;
    },
    getShalasTotal(month) {
      let obj = this.evals.filter((item) => item.month === month);
      if (obj.length > 0) {
        return obj[0].total;
      } else return 0;
    },
    getStat12() {
      this.showChart = true;
      axios
        .post("/teacher/getStat12", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.evals = res.data.teacherEvaluations;
          this.seriesStat[0].data = [
            ((this.average1[0] / 3) * 100).toFixed(2),
            ((this.average1[1] / 2) * 100).toFixed(2),
            ((this.average1[2] / 3) * 100).toFixed(2),
            ((this.average2[0] / 4) * 100).toFixed(2),
            ((this.average2[1] / 3) * 100).toFixed(2),
            ((this.average2[2] / 3) * 100).toFixed(2),
            ((this.average2[3] / 3) * 100).toFixed(2),
            ((this.average2[4] / 3) * 100).toFixed(2),
            ((this.average3[0] / 3) * 100).toFixed(2),
            ((this.average3[1] / 3) * 100).toFixed(2),
          ];
          this.seriesStatMonth[0].data = res.data.teacherEvaluations.map(
            (item) => {
              return ((item.total / 30) * 100).toFixed(2);
            }
          );
          this.chartStatMonth.xaxis.categories =
            res.data.teacherEvaluations.map((item) => {
              return item.month + " сар";
            });
          this.showChart = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getStat12();
  },
};
</script>
