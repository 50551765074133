<template>
  <div id="chart">
    <apexchart
      height="200"
      type="line"
      :options="myChartOptions"
      :series="myChartSeries"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: [
    "nameArr",
    "schoolAvgArr",
    "classAvgArr",
    "maxArr",
    "minArr",
    "childArr",
  ],
  data() {
    return {
      myChartOptions: {
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#775DD0", "#feb019", "#E91E63", "#00e396", "#2E93fA"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
      },
      myChartSeries: [
        {
          name: "Сургуулийн дундаж",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Нормативийн доод утга",
          data: [],
        },
        {
          name: "Нормативийн дээд утга",
          data: [],
        },
        {
          name: "Суралцагчын гүйцэтгэл",
          data: [],
        },
      ],
    };
  },
  methods: {},
  created() {
    this.myChartOptions.xaxis.categories = this.nameArr;
    this.myChartSeries[0].data = this.schoolAvgArr;
    this.myChartSeries[1].data = this.classAvgArr;
    this.myChartSeries[2].data = this.minArr;
    this.myChartSeries[3].data = this.maxArr;
    this.myChartSeries[4].data = this.childArr;
  },
};
</script>

<style scoped>
</style>
