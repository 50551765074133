<template>
  <div class="row pt-3">
    <div class="col-md-2">
      <div
        class="card card-primary"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Манай ангийн суралцагчид</h3>
          <div class="row" v-if="mySchoolClasses.length > 1">
            <label>Анги сонгох</label>
            <multiselect
              v-model="school_class_id"
              :multiple="false"
              deselect-label="Can't remove this value"
              label="full_name"
              track-by="id"
              placeholder="Сонголт хийх"
              :options="mySchoolClasses"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="true"
              :preserve-search="true"
              :hide-selected="true"
            >
              <!-- @select="toggleSelect" -->
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.full_name }} анги</strong>
                сонгогдлоо
              </template>
            </multiselect>
          </div>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Овог yэр</th>
                <th>Үйлдэл</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(child, i) in students" :key="i">
                <td>{{ ++i }}</td>
                <td>
                  <b-badge variant="b-badgelight">
                    <span v-if="child.student_last_name">
                      {{ child.student_last_name[0] }}.
                      {{ child.student_name }}</span
                    >
                  </b-badge>
                </td>
                <td>
                  <a href="#" @click.prevent="beforeGetMe(child)">
                    <i class="fas fa-eye"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-md-10">
      <div class="card card-dark card-tabs">
        <div class="card-header p-0 pt-1">
          <ul class="nav nav-tabs" id="custom-tabs-five-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="custom-tabs-five-overlay-manager"
                data-toggle="pill"
                href="#custom-tabs-five-overlay"
                role="tab"
                aria-controls="custom-tabs-five-overlay"
                aria-selected="true"
                >Менежер</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-five-overlay-dark-teacher"
                data-toggle="pill"
                href="#custom-tabs-five-overlay-dark"
                role="tab"
                aria-controls="custom-tabs-five-overlay-dark"
                aria-selected="false"
                >Анги удирдсан багш</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-five-normal-parent"
                data-toggle="pill"
                href="#custom-tabs-five-normal"
                role="tab"
                aria-controls="custom-tabs-five-normal"
                aria-selected="false"
                >Эцэг эх</a
              >
            </li>
            <!-- <b-button
              v-if="getMyChildReadManager.length"
              class="nav-link"
              id="custom-tabs-five-normal-parent-create"
              href="#custom-tabs-five-normal"
              role="tab"
              aria-controls="custom-tabs-five-normal"
              aria-selected="false"
              variant="outline-primary"
              @click.prevent="createSorilParent()"
              >Сорил бүртгэх</b-button
            > -->
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-five-tabContent">
            <div
              class="tab-pane fade show active"
              id="custom-tabs-five-overlay"
              role="tabpanel"
              aria-labelledby="custom-tabs-five-overlay-manager"
            >
              <div class="overlay-wrapper">
                <b-row>Унших</b-row>
                <b-row v-if="getMyChildReadManager.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт уншсан</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildReadManager
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{ readingInfo.reg_emp.teacher_last_name[0] }}.{{
                              readingInfo.reg_emp.teacher_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoRead(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadManager.length">{{
                              niitVgiinDundaj.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundaj.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundaj.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRate"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadManager.length">{{
                              getMyChildReadManager.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>Цээж бичиг</b-row>
                <b-row v-if="getMyChildWriteManager.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт бичсэн</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildWriteManager
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{ readingInfo.reg_emp.teacher_last_name[0] }}.{{
                              readingInfo.reg_emp.teacher_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoWrite(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteManager.length">{{
                              niitVgiinDundajWrite.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundajWrite.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundajWrite.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRateWrite"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteManager.length">{{
                              getMyChildWriteManager.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildReadManager.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="readMonChartManag.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadMonManag"
                          :series="seriesMyChildReadMonManag"
                        ></apexchart>
                      </div>
                      <div v-if="readEngChartManag.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadEngManag"
                          :series="seriesMyChildReadEngManag"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildWriteManager.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="writeMonChartManag.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteMonManag"
                          :series="seriesMyChildWriteMonManag"
                        ></apexchart>
                      </div>
                      <div v-if="writeEngChartManag.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteEngManag"
                          :series="seriesMyChildWriteEngManag"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-five-overlay-dark"
              role="tabpanel"
              aria-labelledby="custom-tabs-five-overlay-dark-teacher"
            >
              <div class="overlay-wrapper">
                <b-row>Унших</b-row>
                <b-row v-if="getMyChildReadTeacher.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт уншсан</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildReadTeacher
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{ readingInfo.reg_emp.teacher_last_name[0] }}.{{
                              readingInfo.reg_emp.teacher_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoRead(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadTeacher.length">{{
                              niitVgiinDundajAub.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundajAub.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundajAub.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRateAub"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadTeacher.length">{{
                              getMyChildReadTeacher.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>Цээж бичиг</b-row>
                <b-row v-if="getMyChildWriteTeacher.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт бичсэн</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildWriteTeacher
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{ readingInfo.reg_emp.teacher_last_name[0] }}.{{
                              readingInfo.reg_emp.teacher_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoWrite(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteTeacher.length">{{
                              niitVgiinDundajWriteAub.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundajWriteAub.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundajWriteAub.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRateWriteAub"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteTeacher.length">{{
                              getMyChildWriteTeacher.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildReadTeacher.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="readMonChartTeach.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadMonTeach"
                          :series="seriesMyChildReadMonTeach"
                        ></apexchart>
                      </div>
                      <div v-if="readEngChartTeach.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadEngTeach"
                          :series="seriesMyChildReadEngTeach"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildWriteTeacher.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="writeMonChartTeach.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteMonTeach"
                          :series="seriesMyChildWriteMonTeach"
                        ></apexchart>
                      </div>
                      <div v-if="writeEngChartTeach.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteEngTeach"
                          :series="seriesMyChildWriteEngTeach"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-five-normal"
              role="tabpanel"
              aria-labelledby="custom-tabs-five-normal-parent"
            >
              <div class="overlay-wrapper">
                <b-row>Унших</b-row>
                <b-row v-if="getMyChildReadParent.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт уншсан</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildReadParent
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{
                              readingInfo.reg_emp_par.parent_father_ovog[0]
                            }}.{{
                              readingInfo.reg_emp_par.parent_father_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoRead(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                                <b-button
                                  variant="danger"
                                  @click.prevent="
                                    deleteReadedParagraphContent(readingInfo)
                                  "
                                  ><i class="far fa-trash-alt"></i
                                ></b-button>
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadParent.length">{{
                              niitVgiinDundajParent.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundajParent.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundajParent.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRateParent"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildReadParent.length">{{
                              getMyChildReadParent.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>Цээж бичиг</b-row>
                <b-row v-if="getMyChildWriteParent.length">
                  <b-col>
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="font-size: 80%; text-align: justify"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th>№</b-th>
                          <b-th>Бүртгэсэн</b-th>
                          <b-th>Суралцагч</b-th>
                          <b-th>Агуулга</b-th>
                          <b-th>Улирал</b-th>
                          <b-th>Анги</b-th>
                          <b-th>Нийт бичсэн</b-th>
                          <b-th>Алдсан</b-th>
                          <b-th>Зөв</b-th>
                          <b-th colspan="2">Ойлголт</b-th>
                          <b-th>Хяналтын эсэх</b-th>
                          <b-th>Үйлдлүүд</b-th>
                          <b-th>Огноо</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(readingInfo, index) in orderByWords(
                            getMyChildWriteParent
                          )"
                          :key="index"
                        >
                          <b-td>{{ index + 1 }}</b-td>
                          <b-td
                            >{{
                              readingInfo.reg_emp_par.parent_father_ovog[0]
                            }}.{{
                              readingInfo.reg_emp_par.parent_father_name
                            }}</b-td
                          >

                          <b-td
                            >{{
                              readingInfo.get_student.student_last_name[0]
                            }}.{{ readingInfo.get_student.student_name }}</b-td
                          >
                          <b-td
                            >{{ readingInfo.my_par_cont.paragraph_title }}:(
                            {{ readingInfo.paragraph_word_minute }} минут)
                            {{ readingInfo.type }}</b-td
                          >
                          <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                          <b-td>{{
                            readingInfo.my_school_class.full_name
                          }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                          <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                          <b-td>{{ readingInfo.paragraph_mean }} </b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-for="(s, i) in readingInfo.paragraph_mean_eval"
                              :key="i"
                            ></b-icon-star
                          ></b-td>
                          <b-td
                            ><b-icon-star
                              variant="success"
                              v-if="readingInfo.read === 1"
                            ></b-icon-star>
                            <b-icon-star
                              variant="warning"
                              v-if="readingInfo.read === 0"
                            ></b-icon-star>
                          </b-td>

                          <b-td>
                            <b-form-group label-size="sm" label-for="input-sm">
                              <b-button-group size="sm">
                                <b-button
                                  variant="outline-primary"
                                  @click.prevent="moreInfoWrite(readingInfo)"
                                >
                                  <b-icon
                                    icon="info-circle-fill"
                                    scale="1"
                                    variant="info"
                                  ></b-icon>
                                </b-button>
                                <!-- <b-button
                                  variant="danger"
                                  @click.prevent="
                                    deleteReadedParagraphContent(readingInfo)
                                  "
                                  ><i class="far fa-trash-alt"></i
                                ></b-button> -->
                              </b-button-group>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            {{ readingInfo.created_at | dateYearMonthDay }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                          >
                            Дундаж:
                          </b-td>
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteParent.length">{{
                              niitVgiinDundajWriteParent.toFixed(1)
                            }}</b>
                          </b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitAldsanVgiimDundajWriteParent.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left">{{
                            niitZuvVgiinDundajWriteParent.toFixed(1)
                          }}</b-td>
                          <b-td variant="secondary" class="text-left"></b-td>
                          <b-td variant="secondary" class="text-center">
                            <b-icon-star
                              variant="success"
                              v-for="(s, i) in niitOilgoltiinRateWriteParent"
                              :key="i"
                            ></b-icon-star>
                          </b-td>
                          <b-td
                            colspan="3"
                            variant="secondary"
                            class="text-right"
                          ></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td
                            colspan="6"
                            variant="secondary"
                            class="text-right"
                            >Бүртгэгдсэн сорил:</b-td
                          >
                          <b-td variant="secondary" class="text-left">
                            <b v-if="getMyChildWriteParent.length">{{
                              getMyChildWriteParent.length
                            }}</b>
                          </b-td>
                          <b-td
                            colspan="7"
                            variant="secondary"
                            class="text-right"
                          >
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildReadParent.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="readMonChartParent.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadMon"
                          :series="seriesMyChildReadMon"
                        ></apexchart>
                      </div>
                      <div v-if="readEngChartParent.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildReadEng"
                          :series="seriesMyChildReadEng"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-sm-12"
                v-if="getMyChildWriteParent.length > 0"
              >
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span v-if="chart === true">
                      <div v-if="writeMonChartParent.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteMon"
                          :series="seriesMyChildWriteMon"
                        ></apexchart>
                      </div>
                      <div v-if="writeEngChartParent.length > 0">
                        <apexchart
                          type="bar"
                          height="340"
                          :options="optionsMyChildWriteEng"
                          :series="seriesMyChildWriteEng"
                        ></apexchart>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>

    <!-- Эцэг эх дэлгэрэнгүй мэдээлэл харах -->
    <b-modal
      size="xl"
      id="modal-paragraph-more-info"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
      @hidden="resetShowSorilForm"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <dl class="text-left">
              <dt>Гарчиг:</dt>
              <dd>{{ showSorilForm.paragraph_content_id.paragraph_title }}</dd>
              <dt>Агуулга:</dt>
              <dd style="text-align: justify">
                {{ showSorilForm.paragraph_content_id.paragraph_content }}
              </dd>
              <b-row style="text-align: center">
                <b-col>
                  <span>Нийт үгийн тоо</span>
                  <br />
                  <b>{{ showSorilForm.paragraph_word_real }}</b>
                </b-col>
                <b-col>
                  <span v-if="showSorilForm.read == 1"
                    >Буруу уншсан үгийн тоо</span
                  >
                  <span v-if="showSorilForm.write == 1">
                    Буруу бичсэн үгийн тоо
                  </span>
                  <br />
                  <b>{{ showSorilForm.paragraph_word_error }} </b>
                </b-col>
                <b-col>
                  <span v-if="showSorilForm.read == 1"
                    >Зөв уншсан үгийн тоо</span
                  >
                  <span v-if="showSorilForm.write == 1"
                    >Зөв бичсэн үгийн тоо</span
                  >
                  <br />
                  <b>{{ showSorilForm.paragraph_word_correct }} </b>
                </b-col>
              </b-row>
              <b-row style="text-align: center">
                <b-col>
                  <span v-if="showSorilForm.read == 1">
                    Эхийг уншаад ойлгосон байдал
                  </span>
                  <span v-if="showSorilForm.write == 1">
                    Эхийг цээжээр зөв бичсэн байдал
                  </span>
                  <br />
                  <b>{{ showSorilForm.paragraph_mean }} </b>
                </b-col>
                <b-col>
                  Ойлголтыг 1-5 оноогоор үнэлбэл.
                  <br />
                  <b
                    ><b-icon-star
                      variant="success"
                      v-for="(s, i) in showSorilForm.paragraph_mean_eval"
                      :key="i"
                    ></b-icon-star>
                  </b>
                </b-col>
              </b-row>
              <b-row>
                <b>
                  <i
                    >Хүүхэдтэйгээ ажиллах үедээ дараах зүйлсийг анхаараарай.
                  </i>
                </b>
              </b-row>
              <b-row>
                <span style="text-align: justify">
                  {{
                    showSorilForm.paragraph_content_id.paragraph_content_explain
                  }}
                </span>
              </b-row>
            </dl>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Эцэг эх сорил бүртгэх -->
    <b-modal
      size="xl"
      id="modal-paragraph-read-parent"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalParentTitle"
      class="modal fade"
      hide-footer
      @hidden="cancelEdit1"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <form @submit.prevent="storeSorilParent()">
                <div class="card-body">
                  <div class="form-row">
                    <div class="col-lg-12">
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Хүүхэд сонгох"
                            label-for="input-sm"
                          >
                            <multiselect
                              v-model="createSorilForm.student_id"
                              :options="sortArrays(students)"
                              track-by="profile_user_id"
                              label="profile_student_name"
                              :show-labels="false"
                              placeholder="Хүүхэд сонгох"
                              :custom-label="studentName"
                            ></multiselect>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div v-if="schoolParagraphContents.length > 0">
                        <b-row>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              label="Эх сонгох"
                              label-for="input-sm"
                            >
                              <multiselect
                                v-model="createSorilForm.paragraph_content_id"
                                :options="schoolParagraphContents"
                                track-by="id"
                                label="paragraph_title"
                                :show-labels="true"
                                placeholder="Агуулга сонгох"
                              ></multiselect>
                            </b-form-group>
                          </b-col>
                          <b-col
                            ><b-form-group
                              label-size="sm"
                              label="Сорилын төрөл сонгох"
                              label-for="input-sm"
                            >
                              <div>
                                <b-form-select
                                  v-model="createSorilForm.content_type"
                                  :options="contenttypes"
                                  size="sm"
                                  text-field="text"
                                  value-field="value"
                                  :class="[
                                    'form-control',
                                    {
                                      'is-invalid':
                                        createSorilForm.errors.has(
                                          'content_type'
                                        ),
                                    },
                                  ]"
                                ></b-form-select>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              label="Хугацаа"
                              label-for="input-sm"
                            >
                              <b-form-input
                                v-model="createSorilForm.paragraph_word_minute"
                                placeholder="Хугацаа"
                                type="number"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="selectPara">
                          <b-col>
                            <b>
                              <i
                                >Хүүхэдтэйгээ ажиллах үедээ дараах зүйлсийг
                                анхаараарай.
                              </i>
                            </b>
                            <br />
                            <span style="text-align: justify">
                              {{ selectedParagraphs.paragraph_content_explain }}
                            </span>
                            <br />
                            <b><i>Агуулга:</i></b>
                            <p style="text-align: justify" v-if="selectPara">
                              {{ selectedParagraphs.paragraph_content }}
                            </p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              label="Нийт үгийн тоо"
                              label-for="input-sm"
                            >
                              <b-form-input
                                v-model="createSorilForm.paragraph_word_real"
                                placeholder="Нийт үгийн тоо"
                                type="number"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              :label="
                                !selectReadEdit
                                  ? 'Буруу уншсан үгийн тоо'
                                  : 'Буруу бичсэн үгийн тоо'
                              "
                              label-for="input-sm"
                            >
                              <b-form-input
                                v-model="createSorilForm.paragraph_word_error"
                                :placeholder="
                                  !selectReadEdit
                                    ? 'Буруу уншсан үгийн тоо'
                                    : 'Буруу бичсэн үгийн тоо'
                                "
                                type="number"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              :label="
                                !selectReadEdit
                                  ? 'Зөв уншсан үгийн тоо'
                                  : 'Зөв бичсэн үгийн тоо'
                              "
                              label-for="input-sm"
                            >
                              {{ sumWordsEdit }}
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              :label="
                                !selectReadEdit
                                  ? 'Эхийг уншаад ойлгосон байдал'
                                  : 'Эхийг бичээд ойлгосон байдал'
                              "
                              label-for="input-sm"
                            >
                              <b-form-textarea
                                id="textarea"
                                v-model="createSorilForm.paragraph_mean"
                                :placeholder="
                                  !selectReadEdit
                                    ? 'Эхийг уншаад ойлгосон байдал'
                                    : 'Эхийг бичээд ойлгосон байдал'
                                "
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label-size="sm"
                              label="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                              label-for="input-sm"
                            >
                              <b-form-input
                                v-model="createSorilForm.paragraph_mean_eval"
                                placeholder="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                                type="number"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="storeSorilParent()"
                  >
                    Бүртгэх
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click.prevent="cancelEdit1()"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      students: [],
      mySchoolClasses: [],
      school_class_id: null,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formModalTitle: "Сорилын дэлгэрэнгүй мэдээлэл",
      formModalParentTitle: "Хүүхэд дээрээ сорил бүртгэх",

      //   Эцэг эх
      checkMyChild: "",
      selectRead: false,
      getMyChildReadManager: [],
      getMyChildWriteManager: [],
      getMyChildReadTeacher: [],
      getMyChildWriteTeacher: [],
      getMyChildReadParent: [],
      getMyChildWriteParent: [],
      //Статистик дундаж тооцоо
      //   менежер
      niitVgiinDundaj: 0,
      niitZuvVgiinDundaj: 0,
      niitAldsanVgiimDundaj: 0,
      niitOilgoltiinRate: 0,
      niitVgiinDundajWrite: 0,
      niitZuvVgiinDundajWrite: 0,
      niitAldsanVgiimDundajWrite: 0,
      niitOilgoltiinRateWrite: 0,
      //   АУБ
      niitVgiinDundajAub: 0,
      niitZuvVgiinDundajAub: 0,
      niitAldsanVgiimDundajAub: 0,
      niitOilgoltiinRateAub: 0,
      niitVgiinDundajWriteAub: 0,
      niitZuvVgiinDundajWriteAub: 0,
      niitAldsanVgiimDundajWriteAub: 0,
      niitOilgoltiinRateWriteAub: 0,
      //   Эцэг эх
      niitVgiinDundajParent: 0,
      niitZuvVgiinDundajParent: 0,
      niitAldsanVgiimDundajParent: 0,
      niitOilgoltiinRateParent: 0,
      niitVgiinDundajWriteParent: 0,
      niitZuvVgiinDundajWriteParent: 0,
      niitAldsanVgiimDundajWriteParent: 0,
      niitOilgoltiinRateWriteParent: 0,

      showSorilForm: new Form({
        id: "",
        year_id: "",
        school_id: "",
        paragraph_content_id: "",
        content_type: "", // 0-уншлага 1-цээж бичиг
        quarter_id: "",
        type: "", // явц / улирал / жил
        school_class_id: "",
        reg_emp_id: "",
        manager_id: "",
        teacher_id: "",
        parent_id: "",
        student_id: "",
        paragraph_word_correct: 0,
        paragraph_word_error: 0,
        paragraph_word_real: 0,
        paragraph_word_minute: 1,
        paragraph_mean: "",
        paragraph_mean_eval: 0,
        read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
        write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      }),

      //Эцэг эх сорил бүртгэх
      createSorilForm: new Form({
        id: "",
        year_id: "",
        school_id: "",
        paragraph_content_id: "",
        content_type: "", // 0-уншлага 1-цээж бичиг
        quarter_id: "",
        type: "явц", // явц / улирал / жил
        school_class_id: "",
        reg_emp_id: "",
        manager_id: "",
        teacher_id: "",
        parent_id: "",
        student_id: "",
        paragraph_word_correct: 0,
        paragraph_word_error: 0,
        paragraph_word_real: 0,
        paragraph_word_minute: 1,
        paragraph_mean: "",
        paragraph_mean_eval: 0,
        read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
        write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      }),
      schoolParagraphContents: [],
      students: [],
      readMonChartManag: [],
      readEngChartManag: [],
      readMonChartParent: [],
      readEngChartParent: [],
      readMonChartTeach: [],
      readEngChartTeach: [],
      writeMonChartManag: [],
      writeEngChartManag: [],
      writeMonChartParent: [],
      writeEngChartParent: [],
      writeMonChartTeach: [],
      writeEngChartTeach: [],
      contenttypes: [
        { value: 0, text: "Уншлага" },
        { value: 1, text: "Цээж бичиг" },
      ],
      selectPara: false,
      selectedParagraphs: "",
      selectParaEdit: false,
      selectedParagraphsEdit: "",
      selectReadEdit: false,
      chart: false,
      //read
      optionsMyChildReadMon: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadMon: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],
      optionsMyChildReadEng: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadEng: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],
      optionsMyChildReadMonManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadMonManag: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],
      optionsMyChildReadEngManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadEngManag: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],

      optionsMyChildReadMonTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadMonTeach: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],
      optionsMyChildReadEngTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Унших хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildReadEngTeach: [
        {
          name: "Уншсан үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
        {
          name: "АНУ",
          data: [],
        },
      ],
      //write
      optionsMyChildWriteMon: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteMon: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],
      optionsMyChildWriteEng: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteEng: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],
      optionsMyChildWriteMonManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteMonManag: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],
      optionsMyChildWriteEngManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteEngManag: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],

      optionsMyChildWriteMonTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Монгол)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteMonTeach: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],
      optionsMyChildWriteEngTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "35%",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = parseFloat(val);
            // let a = (val / 26) * 100;
            return a;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Бичих хурдны тоон мэдээлэл график (Англи)",
          align: "left",
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = parseFloat(val);
              return a;
            },
          },
        },
      },
      seriesMyChildWriteEngTeach: [
        {
          name: "Бичсэн үгийн тоо",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Түвшингийн дундаж",
          data: [],
        },
      ],
    };
  },
  components: { Multiselect },
  watch: {
    school_class_id() {
      axios
        .get("/teacher/getMyEntireClassStat/" + this.school_class_id.id)
        .then((res) => {
          //   console.log(res.data);
          this.students = res.data.students;
          //   this.students = checkChild.filter(
          //     (el) => el.school_class_number <= 5
          //   );
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    "createSorilForm.content_type"(newVal, oldVal) {
      //   console.log(newVal, oldVal);
      if (newVal === 0) {
        this.selectRead = true;
        this.createSorilForm.write = null;
        this.createSorilForm.read = "0";
      } else if (newVal === 1) {
        this.selectRead = false;
        this.createSorilForm.read = null;
        this.createSorilForm.write = "0";
      }
    },
    "createSorilForm.student_id"(newVal, oldVal) {
      // console.log(newVal, oldVal);
      axios
        .post("/parent/getMyEntireClassStatParagraphContents", {
          childs: newVal,
        })
        .then((res) => {
          //   console.log(res);
          this.schoolParagraphContents = res.data.schoolParagraphContents;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    "createSorilForm.paragraph_content_id"(newVal, oldVal) {
      //   console.log(newVal, oldVal);
      if (Object.keys(newVal).length === 0) {
        this.selectPara = false;
        this.selectedParagraphs = "";
      } else {
        let a = this.schoolParagraphContents.filter(
          (el) => el.id === newVal.id
        );
        this.selectPara = true;
        this.selectedParagraphs = a[0];
        //   console.log(this.selectedParagraphs);
        // Fire.$emit("loadParagraphContentReads");
      }
    },
  },
  computed: {
    sumWords() {
      let a = this.showSorilForm.paragraph_word_real;
      let b = this.showSorilForm.paragraph_word_error;
      this.showSorilForm.paragraph_word_correct = parseInt(a) - parseInt(b);
      return this.showSorilForm.paragraph_word_correct;
    },
    sumWordsEdit() {
      let a = this.createSorilForm.paragraph_word_real;
      let b = this.createSorilForm.paragraph_word_error;
      this.createSorilForm.paragraph_word_correct = parseInt(a) - parseInt(b);
      return this.createSorilForm.paragraph_word_correct;
    },
  },
  methods: {
    sortArrays(students) {
      return _.orderBy(students, "student_name/student_last_name", "asc");
    },
    orderByWords(e) {
      return _.orderBy(e, "paragraph_word_real", "desc");
    },

    // эцэг эх хүүхдийнхээ сорилын мэдээлэл татаж авах
    beforeGetMe(child) {
      this.checkMyChild = child;
      this.getMyChildParagraphReadWriteDatas(child);
    },
    getMyChildParagraphReadWriteDatas(child) {
      // read
      this.seriesMyChildReadMon[0].data = [];
      this.seriesMyChildReadMon[1].data = [];
      this.seriesMyChildReadMon[2].data = [];
      this.seriesMyChildReadMon[3].data = [];
      this.optionsMyChildReadMon.xaxis.categories = [];
      this.seriesMyChildReadEng[0].data = [];
      this.seriesMyChildReadEng[1].data = [];
      this.seriesMyChildReadEng[2].data = [];
      this.seriesMyChildReadEng[3].data = [];
      this.optionsMyChildReadEng.xaxis.categories = [];

      this.seriesMyChildReadMonManag[0].data = [];
      this.seriesMyChildReadMonManag[1].data = [];
      this.seriesMyChildReadMonManag[2].data = [];
      this.seriesMyChildReadMonManag[3].data = [];
      this.optionsMyChildReadMonManag.xaxis.categories = [];
      this.seriesMyChildReadEngManag[0].data = [];
      this.seriesMyChildReadEngManag[1].data = [];
      this.seriesMyChildReadEngManag[2].data = [];
      this.seriesMyChildReadEngManag[3].data = [];
      this.optionsMyChildReadEngManag.xaxis.categories = [];

      this.seriesMyChildReadMonTeach[0].data = [];
      this.seriesMyChildReadMonTeach[1].data = [];
      this.seriesMyChildReadMonTeach[2].data = [];
      this.seriesMyChildReadMonTeach[3].data = [];
      this.optionsMyChildReadMonTeach.xaxis.categories = [];
      this.seriesMyChildReadEngTeach[0].data = [];
      this.seriesMyChildReadEngTeach[1].data = [];
      this.seriesMyChildReadEngTeach[2].data = [];
      this.seriesMyChildReadEngTeach[3].data = [];
      this.optionsMyChildReadEngTeach.xaxis.categories = [];

      //write
      this.seriesMyChildWriteMon[0].data = [];
      this.seriesMyChildWriteMon[1].data = [];
      this.seriesMyChildWriteMon[2].data = [];
      this.optionsMyChildWriteMon.xaxis.categories = [];
      this.seriesMyChildWriteEng[0].data = [];
      this.seriesMyChildWriteEng[1].data = [];
      this.seriesMyChildWriteEng[2].data = [];
      this.optionsMyChildWriteEng.xaxis.categories = [];

      this.seriesMyChildWriteMonManag[0].data = [];
      this.seriesMyChildWriteMonManag[1].data = [];
      this.seriesMyChildWriteMonManag[2].data = [];
      this.optionsMyChildWriteMonManag.xaxis.categories = [];
      this.seriesMyChildWriteEngManag[0].data = [];
      this.seriesMyChildWriteEngManag[1].data = [];
      this.seriesMyChildWriteEngManag[2].data = [];
      this.optionsMyChildWriteEngManag.xaxis.categories = [];

      this.seriesMyChildWriteMonTeach[0].data = [];
      this.seriesMyChildWriteMonTeach[1].data = [];
      this.seriesMyChildWriteMonTeach[2].data = [];
      this.optionsMyChildWriteMonTeach.xaxis.categories = [];
      this.seriesMyChildWriteEngTeach[0].data = [];
      this.seriesMyChildWriteEngTeach[1].data = [];
      this.seriesMyChildWriteEngTeach[2].data = [];
      this.optionsMyChildWriteEngTeach.xaxis.categories = [];
      this.chart = false;
      axios
        .post("/teacher/getMyClassChildPRWdatas", { child: child })
        .then((res) => {
          //   console.log(res.data);
          this.getMyChildReadManager = res.data.getMyChildReadManager;
          this.getMyChildWriteManager = res.data.getMyChildWriteManager;
          this.getMyChildReadTeacher = res.data.getMyChildReadClassTeacher;
          this.getMyChildWriteTeacher = res.data.getMyChildWriteClassTeacher;
          this.getMyChildReadParent = res.data.getMyChildReadParent;
          this.getMyChildWriteParent = res.data.getMyChildWriteParent;

          this.readMonChartManag = res.data.myChildReadCorrectMonManag;
          this.readEngChartManag = res.data.myChildReadCorrectEngManag;
          this.readMonChartParent = res.data.myChildReadCorrectMon;
          this.readEngChartParent = res.data.myChildReadCorrectEng;
          this.readMonChartTeach = res.data.myChildReadCorrectMonTeach;
          this.readEngChartTeach = res.data.myChildReadCorrectEngTeach;

          this.writeMonChartManag = res.data.myChildWriteCorrectMonManag;
          this.writeEngChartManag = res.data.myChildWriteCorrectEngManag;
          this.writeMonChartParent = res.data.myChildWriteCorrectMon;
          this.writeEngChartParent = res.data.myChildWriteCorrectEng;
          this.writeMonChartTeach = res.data.myChildWriteCorrectMonTeach;
          this.writeEngChartTeach = res.data.myChildWriteCorrectEngTeach;
          //read
          this.seriesMyChildReadMon[0].data = res.data.myChildReadCorrectMon;
          this.seriesMyChildReadMon[0].data.forEach((element) => {
            this.seriesMyChildReadMon[1].data.push(
              res.data.myChildClassReadAvgMon
            );
            this.seriesMyChildReadMon[2].data.push(
              res.data.myChildClassNumReadAvgMon
            );
            this.seriesMyChildReadMon[3].data.push(res.data.usa);
          });

          this.seriesMyChildReadMon[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadMon.xaxis.categories =
            res.data.myChildReadTypeMon;
          this.seriesMyChildReadEng[0].data = res.data.myChildReadCorrectEng;
          this.seriesMyChildReadEng[0].data.forEach((element) => {
            this.seriesMyChildReadEng[1].data.push(
              res.data.myChildClassReadAvgEng
            );
            this.seriesMyChildReadEng[2].data.push(
              res.data.myChildClassNumReadAvgEng
            );
            this.seriesMyChildReadEng[3].data.push(res.data.usa);
          });
          this.seriesMyChildReadEng[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadEng.xaxis.categories =
            res.data.myChildReadTypeEng;

          this.seriesMyChildReadMonManag[0].data =
            res.data.myChildReadCorrectMonManag;
          this.seriesMyChildReadMonManag[0].data.forEach((element) => {
            this.seriesMyChildReadMonManag[1].data.push(
              res.data.myChildClassReadManagAvgMon
            );
            this.seriesMyChildReadMonManag[2].data.push(
              res.data.myChildClassNumReadManagAvgMon
            );
            this.seriesMyChildReadMonManag[3].data.push(res.data.usa);
          });
          this.seriesMyChildReadMonManag[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadMonManag.xaxis.categories =
            res.data.myChildReadTypeMonManag;
          this.seriesMyChildReadEngManag[0].data =
            res.data.myChildReadCorrectEngManag;
          this.seriesMyChildReadEngManag[0].data.forEach((element) => {
            this.seriesMyChildReadEngManag[1].data.push(
              res.data.myChildClassReadManagAvgEng
            );
            this.seriesMyChildReadEngManag[2].data.push(
              res.data.myChildClassNumReadManagAvgEng
            );
            this.seriesMyChildReadEngManag[3].data.push(res.data.usa);
          });
          this.seriesMyChildReadEngManag[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadEngManag.xaxis.categories =
            res.data.myChildReadTypeEngManag;

          this.seriesMyChildReadMonTeach[0].data =
            res.data.myChildReadCorrectMonTeach;
          this.seriesMyChildReadMonTeach[0].data.forEach((element) => {
            this.seriesMyChildReadMonTeach[1].data.push(
              res.data.myChildClassReadTeachAvgMon
            );
            this.seriesMyChildReadMonTeach[2].data.push(
              res.data.myChildClassNumReadTeachAvgMon
            );
            this.seriesMyChildReadMonTeach[3].data.push(res.data.usa);
          });
          this.seriesMyChildReadMonTeach[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadMonTeach.xaxis.categories =
            res.data.myChildReadTypeMonTeach;
          this.seriesMyChildReadEngTeach[0].data =
            res.data.myChildReadCorrectEngTeach;
          this.seriesMyChildReadEngTeach[0].data.forEach((element) => {
            this.seriesMyChildReadEngTeach[1].data.push(
              res.data.myChildClassReadTeachAvgEng
            );
            this.seriesMyChildReadEngTeach[2].data.push(
              res.data.myChildClassNumReadTeachAvgEng
            );
            this.seriesMyChildReadEngTeach[3].data.push(res.data.usa);
          });
          this.seriesMyChildReadEngTeach[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildReadEngTeach.xaxis.categories =
            res.data.myChildReadTypeEngTeach;
          //write
          this.seriesMyChildWriteMon[0].data = res.data.myChildWriteCorrectMon;
          this.seriesMyChildWriteMon[0].data.forEach((element) => {
            this.seriesMyChildWriteMon[1].data.push(
              res.data.myChildClassWriteAvgMon
            );
            this.seriesMyChildWriteMon[2].data.push(
              res.data.myChildClassNumWriteAvgMon
            );
          });
          this.seriesMyChildWriteMon[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteMon.xaxis.categories =
            res.data.myChildWriteTypeMon;
          this.seriesMyChildWriteEng[0].data = res.data.myChildWriteCorrectEng;
          this.seriesMyChildWriteEng[0].data.forEach((element) => {
            this.seriesMyChildWriteEng[1].data.push(
              res.data.myChildClassWriteAvgEng
            );
            this.seriesMyChildWriteEng[2].data.push(
              res.data.myChildClassNumWriteAvgEng
            );
          });
          this.seriesMyChildWriteEng[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteEng.xaxis.categories =
            res.data.myChildWriteTypeEng;

          this.seriesMyChildWriteMonManag[0].data =
            res.data.myChildWriteCorrectMonManag;
          this.seriesMyChildWriteMonManag[0].data.forEach((element) => {
            this.seriesMyChildWriteMonManag[1].data.push(
              res.data.myChildClassWriteManagAvgMon
            );
            this.seriesMyChildWriteMonManag[2].data.push(
              res.data.myChildClassNumWriteManagAvgMon
            );
          });
          this.seriesMyChildWriteMonManag[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteMonManag.xaxis.categories =
            res.data.myChildWriteTypeMonManag;
          this.seriesMyChildWriteEngManag[0].data =
            res.data.myChildWriteCorrectEngManag;
          this.seriesMyChildWriteEngManag[0].data.forEach((element) => {
            this.seriesMyChildWriteEngManag[1].data.push(
              res.data.myChildClassWriteManagAvgEng
            );
            this.seriesMyChildWriteEngManag[2].data.push(
              res.data.myChildClassNumWriteManagAvgEng
            );
          });
          this.seriesMyChildWriteEngManag[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteEngManag.xaxis.categories =
            res.data.myChildWriteTypeEngManag;

          this.seriesMyChildWriteMonTeach[0].data =
            res.data.myChildWriteCorrectMonTeach;
          this.seriesMyChildWriteMonTeach[0].data.forEach((element) => {
            this.seriesMyChildWriteMonTeach[1].data.push(
              res.data.myChildClassWriteTeachAvgMon
            );
            this.seriesMyChildWriteMonTeach[2].data.push(
              res.data.myChildClassNumWriteTeachAvgMon
            );
          });
          this.seriesMyChildWriteMonTeach[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteMonTeach.xaxis.categories =
            res.data.myChildWriteTypeMonTeach;
          this.seriesMyChildWriteEngTeach[0].data =
            res.data.myChildWriteCorrectEngTeach;
          this.seriesMyChildWriteEngTeach[0].data.forEach((element) => {
            this.seriesMyChildWriteEngTeach[1].data.push(
              res.data.myChildClassWriteTeachAvgEng
            );
            this.seriesMyChildWriteEngTeach[2].data.push(
              res.data.myChildClassNumWriteTeachAvgEng
            );
          });
          this.seriesMyChildWriteEngTeach[2].name =
            this.checkMyChild.class_number + "-р ангийн суралцагчдын дундаж";
          this.optionsMyChildWriteEngTeach.xaxis.categories =
            res.data.myChildWriteTypeEngTeach;
          //   Менежер уншлага-------------------------------------------------
          // Нийт үгийн дундаж
          var niitVgDundaj = 0;
          for (var i = 0; i < this.getMyChildReadManager.length; i++) {
            niitVgDundaj += parseInt(
              this.getMyChildReadManager[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgDundaj);
          }
          this.niitVgiinDundaj =
            niitVgDundaj / this.getMyChildReadManager.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundaj = 0;
          for (var i = 0; i < this.getMyChildReadManager.length; i++) {
            niitZuvVgDundaj += parseInt(
              this.getMyChildReadManager[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundaj);
          }
          this.niitZuvVgiinDundaj =
            niitZuvVgDundaj / this.getMyChildReadManager.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundaj = 0;
          for (var i = 0; i < this.getMyChildReadManager.length; i++) {
            niitAldsanVgDundaj += parseInt(
              this.getMyChildReadManager[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundaj);
          }
          this.niitAldsanVgiimDundaj =
            niitAldsanVgDundaj / this.getMyChildReadManager.length;
          // Үнэлгээний Rate
          var niitOilgoltRate = 0;
          for (var i = 0; i < this.getMyChildReadManager.length; i++) {
            niitOilgoltRate += parseInt(
              this.getMyChildReadManager[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRate);
          }
          this.niitOilgoltiinRate = Math.round(
            niitOilgoltRate / this.getMyChildReadManager.length
          );
          //   Менежер цээж бичиг---------------------------------------------------------------------
          // Нийт үгийн дундаж
          //   niitOilgoltiinRateWrite: null,
          var niitVgiinDundajW = 0;
          for (var i = 0; i < this.getMyChildWriteManager.length; i++) {
            niitVgiinDundajW += parseInt(
              this.getMyChildWriteManager[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgiinDundajW);
          }
          this.niitVgiinDundajWrite =
            niitVgiinDundajW / this.getMyChildWriteManager.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundajW = 0;
          for (var i = 0; i < this.getMyChildWriteManager.length; i++) {
            niitZuvVgDundajW += parseInt(
              this.getMyChildWriteManager[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundajW);
          }
          this.niitZuvVgiinDundajWrite =
            niitZuvVgDundajW / this.getMyChildWriteManager.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundajW = 0;
          for (var i = 0; i < this.getMyChildWriteManager.length; i++) {
            niitAldsanVgDundajW += parseInt(
              this.getMyChildWriteManager[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundajW);
          }
          this.niitAldsanVgiimDundajWrite =
            niitAldsanVgDundajW / this.getMyChildWriteManager.length;
          // Үнэлгээний Rate
          var niitOilgoltRateW = 0;
          for (var i = 0; i < this.getMyChildWriteManager.length; i++) {
            niitOilgoltRateW += parseInt(
              this.getMyChildWriteManager[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRateW);
          }
          this.niitOilgoltiinRateWrite = Math.round(
            niitOilgoltRateW / this.getMyChildWriteManager.length
          );

          //   АУБ уншлага-------------------------------------------------
          // Нийт үгийн дундаж
          var niitVgDundajAUB = 0;
          for (var i = 0; i < this.getMyChildReadTeacher.length; i++) {
            niitVgDundajAUB += parseInt(
              this.getMyChildReadTeacher[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgDundajAUB);
          }
          this.niitVgiinDundajAub =
            niitVgDundajAUB / this.getMyChildReadTeacher.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundajAUB = 0;
          for (var i = 0; i < this.getMyChildReadTeacher.length; i++) {
            niitZuvVgDundajAUB += parseInt(
              this.getMyChildReadTeacher[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundajAUB);
          }
          this.niitZuvVgiinDundajAub =
            niitZuvVgDundajAUB / this.getMyChildReadTeacher.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundajAUB = 0;
          for (var i = 0; i < this.getMyChildReadTeacher.length; i++) {
            niitAldsanVgDundajAUB += parseInt(
              this.getMyChildReadTeacher[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundajAUB);
          }
          this.niitAldsanVgiimDundajAub =
            niitAldsanVgDundajAUB / this.getMyChildReadTeacher.length;
          // Үнэлгээний Rate
          var niitOilgoltRateAUB = 0;
          for (var i = 0; i < this.getMyChildReadTeacher.length; i++) {
            niitOilgoltRateAUB += parseInt(
              this.getMyChildReadTeacher[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRateAUB);
          }
          this.niitOilgoltiinRateAub = Math.round(
            niitOilgoltRateAUB / this.getMyChildReadTeacher.length
          );
          //   АУБ цээж бичиг---------------------------------------------------------------------
          // Нийт үгийн дундаж
          //   niitOilgoltiinRateWrite: null,
          var niitVgiinDundajWAUB = 0;
          for (var i = 0; i < this.getMyChildWriteTeacher.length; i++) {
            niitVgiinDundajWAUB += parseInt(
              this.getMyChildWriteTeacher[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgiinDundajWAUB);
          }
          this.niitVgiinDundajWriteAub =
            niitVgiinDundajWAUB / this.getMyChildWriteTeacher.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundajWAUB = 0;
          for (var i = 0; i < this.getMyChildWriteTeacher.length; i++) {
            niitZuvVgDundajWAUB += parseInt(
              this.getMyChildWriteTeacher[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundajWAUB);
          }
          this.niitZuvVgiinDundajWrite =
            niitZuvVgDundajWAUB / this.getMyChildWriteTeacher.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundajWAUB = 0;
          for (var i = 0; i < this.getMyChildWriteTeacher.length; i++) {
            niitAldsanVgDundajWAUB += parseInt(
              this.getMyChildWriteTeacher[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundajWAUB);
          }
          this.niitAldsanVgiimDundajWriteAub =
            niitAldsanVgDundajWAUB / this.getMyChildWriteTeacher.length;
          // Үнэлгээний Rate
          var niitOilgoltRateWAUB = 0;
          for (var i = 0; i < this.getMyChildWriteTeacher.length; i++) {
            niitOilgoltRateWAUB += parseInt(
              this.getMyChildWriteTeacher[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRateWAUB);
          }
          this.niitOilgoltiinRateWriteAub = Math.round(
            niitOilgoltRateWAUB / this.getMyChildWriteTeacher.length
          );

          //   Эцэг эх уншлага-------------------------------------------------
          // Нийт үгийн дундаж
          var niitVgDundajPar = 0;
          for (var i = 0; i < this.getMyChildReadParent.length; i++) {
            niitVgDundajPar += parseInt(
              this.getMyChildReadParent[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgDundajPar);
          }
          this.niitVgiinDundajParent =
            niitVgDundajPar / this.getMyChildReadParent.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundajPar = 0;
          for (var i = 0; i < this.getMyChildReadParent.length; i++) {
            niitZuvVgDundajPar += parseInt(
              this.getMyChildReadParent[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundajPar);
          }
          this.niitZuvVgiinDundajParent =
            niitZuvVgDundajPar / this.getMyChildReadParent.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundajPar = 0;
          for (var i = 0; i < this.getMyChildReadParent.length; i++) {
            niitAldsanVgDundajPar += parseInt(
              this.getMyChildReadParent[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundajPar);
          }
          this.niitAldsanVgiimDundajParent =
            niitAldsanVgDundajPar / this.getMyChildReadParent.length;
          // Үнэлгээний Rate
          var niitOilgoltRatePar = 0;
          for (var i = 0; i < this.getMyChildReadParent.length; i++) {
            niitOilgoltRatePar += parseInt(
              this.getMyChildReadParent[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRatePar);
          }
          this.niitOilgoltiinRateParent = Math.round(
            niitOilgoltRatePar / this.getMyChildReadParent.length
          );
          //   Эцэг эх цээж бичиг---------------------------------------------------------------------
          // Нийт үгийн дундаж
          //   niitOilgoltiinRateWrite: null,
          var niitVgiinDundajWPar = 0;
          for (var i = 0; i < this.getMyChildWriteParent.length; i++) {
            niitVgiinDundajWPar += parseInt(
              this.getMyChildWriteParent[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgiinDundajWPar);
          }
          this.niitVgiinDundajWriteParent =
            niitVgiinDundajWPar / this.getMyChildWriteParent.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundajWPar = 0;
          for (var i = 0; i < this.getMyChildWriteParent.length; i++) {
            niitZuvVgDundajWPar += parseInt(
              this.getMyChildWriteParent[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundajWPar);
          }
          this.niitZuvVgiinDundajWriteParent =
            niitZuvVgDundajWPar / this.getMyChildWriteParent.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundajWPar = 0;
          for (var i = 0; i < this.getMyChildWriteParent.length; i++) {
            niitAldsanVgDundajWPar += parseInt(
              this.getMyChildWriteParent[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundajWPar);
          }
          this.niitAldsanVgiimDundajWriteParent =
            niitAldsanVgDundajWPar / this.getMyChildWriteParent.length;
          // Үнэлгээний Rate
          var niitOilgoltRateWPar = 0;
          for (var i = 0; i < this.getMyChildWriteParent.length; i++) {
            niitOilgoltRateWPar += parseInt(
              this.getMyChildWriteParent[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRateWPar);
          }
          this.niitOilgoltiinRateWriteParent = Math.round(
            niitOilgoltRateWPar / this.getMyChildWriteParent.length
          );

          this.chart = true;
        })
        .catch((err) => console.log(err));
    },

    moreInfoRead(info) {
      //   console.log(info);
      this.$bvModal.show("modal-paragraph-more-info");
      this.showSorilForm.paragraph_content_id = info.my_par_cont;

      this.showSorilForm.paragraph_word_correct = info.paragraph_word_correct;
      this.showSorilForm.paragraph_word_error = info.paragraph_word_error;
      this.showSorilForm.paragraph_word_real = info.paragraph_word_real;
      this.showSorilForm.paragraph_word_minute = info.paragraph_word_minute;
      this.showSorilForm.paragraph_mean = info.paragraph_mean;
      this.showSorilForm.paragraph_mean_eval = info.paragraph_mean_eval;
      this.showSorilForm.read = 1; // 1 утга
      this.showSorilForm.write = null;
    },
    moreInfoWrite(info) {
      //   console.log(info);
      this.$bvModal.show("modal-paragraph-more-info");
      this.showSorilForm.paragraph_content_id = info.my_par_cont;

      this.showSorilForm.paragraph_word_correct = info.paragraph_word_correct;
      this.showSorilForm.paragraph_word_error = info.paragraph_word_error;
      this.showSorilForm.paragraph_word_real = info.paragraph_word_real;
      this.showSorilForm.paragraph_word_minute = info.paragraph_word_minute;
      this.showSorilForm.paragraph_mean = info.paragraph_mean;
      this.showSorilForm.paragraph_mean_eval = info.paragraph_mean_eval;
      this.showSorilForm.read = null;
      this.showSorilForm.write = 1;
    },
    resetShowSorilForm() {
      //   console.log("reset");
      this.showSorilForm.reset();
    },

    // Эцэг эх сорил бүртгэх
    createSorilParent() {
      this.$bvModal.show("modal-paragraph-read-parent");
    },
    cancelEdit1() {
      this.createSorilForm.id = "";
      this.createSorilForm.year_id = "";
      this.createSorilForm.school_id = "";
      this.createSorilForm.paragraph_content_id = "";
      this.createSorilForm.content_type = ""; // 0-уншлага 1-цээж бичиг
      this.createSorilForm.quarter_id = "";
      (this.createSorilForm.type = "яв;"), // явц / улирал / жил
        (this.createSorilForm.school_class_id = "");
      this.createSorilForm.reg_emp_id = "";
      this.createSorilForm.manager_id = "";
      this.createSorilForm.teacher_id = "";
      this.createSorilForm.parent_id = "";
      // this.createSorilForm.student_id= "";
      this.createSorilForm.paragraph_word_correct = 0;
      this.createSorilForm.paragraph_word_error = 0;
      this.createSorilForm.paragraph_word_real = 0;
      this.createSorilForm.paragraph_word_minute = 1;
      this.createSorilForm.paragraph_mean = "";
      this.createSorilForm.paragraph_mean_eval = 0;
      this.createSorilForm.read = null; //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
      this.createSorilForm.write = null; //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      this.$bvModal.hide("modal-paragraph-read-parent");
    },
    storeSorilParent() {
      this.createSorilForm
        .post("/parent/storeSorilParent")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadParagraphContentReads");
          this.cancelEdit1();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteReadedParagraphContent(readingInfo) {
      //   console.log(readingInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Дахин сэргээх боломжгүйг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцал!",
        confirmButtonColor: "#008000",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/parent/deleteReadParaContentParent/" + readingInfo.id)
            .then((res) => {
              Swal.fire("Устгалаа!", "Амжилттай устгагдсан.", "success");
              Fire.$emit("loadParagraphContentReads");
              //   console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцлагдсан",
            "Устгах үйлдлийг амжилттай цуцалсан :)",
            "error"
          );
        }
      });
    },

    studentName({ profile_student_name, profile_student_last_name }) {
      return `${profile_student_last_name[0]}. ${profile_student_name}`;
    },

    toOronTootsokh(value) {
      return parseFloat(value).toFixed(1);
    },
  },
  created() {
    axios
      .get("/teacher/getMyClasses")
      .then((res) => {
        this.mySchoolClasses = res.data.schoolclasses;
        this.mySchoolClasses.length > 0
          ? (this.school_class_id = this.mySchoolClasses[0])
          : null;
      })
      .catch((err) => {
        console.log(err);
      });
    Fire.$on("loadParagraphContentReads", () => {
      this.getMyChildParagraphReadWriteDatas(this.checkMyChild);
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
