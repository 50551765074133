<template>
    <div class="row">
        <div class="col-md-12">
            Бие бялдрын ерөнхий статистик гаргана.
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {};
    },
    components: {
        Multiselect
    },
    watch: {},
    methods: {
        customYearName({ start_date, finish_date }) {
            return `${start_date} - ${finish_date} - оны хичээлийн жил`;
        },
        customQuarterName({ name }) {
            return `${name} - р улирал`;
        },
        customClassName({ full_name }) {
            return `${full_name} анги`;
        },
        customSubjectName({ subject_short_name }) {
            return `${subject_short_name}`;
        },
        customStudentName({ student_last_name, student_name }) {
            return `${student_last_name[0]}.${student_name}`;
        }
    },
    mounted() {},
    created() {}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1000px;
}
</style>
