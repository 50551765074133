<template>
  <div class="row pt-3">
    <!-- Form Element sizes -->
    <div class="col-md-1">
      <div
        class="card card-primary"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Анги</h3>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <p
            v-for="school_class in schoolclasses"
            :key="school_class.id"
            style="line-height: 60%"
          >
            <a
              href="#"
              @click.prevent="
                schoolClassClick(school_class.id, school_class.full_name)
              "
            >
              {{ school_class.full_name }}
            </a>
          </p>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <div class="col-md-11">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Сорил бүртгэх</h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
            <!-- <button
              type="button"
              class="btn btn-tool"
              data-card-widget="remove"
            >
              <i class="fas fa-times"></i>
            </button> -->
          </div>
        </div>
        <div class="card-body" v-if="selectClass">
          <b-row>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Улирал сонгох"
                label-for="input-sm"
              >
                <multiselect
                  v-model="form.quarter_id"
                  :options="quarters"
                  :custom-label="customQuarterName"
                  track-by="quarter_id"
                  label="quarter_name"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Улирал сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.quarter_name }}-р улирал</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Эх сонгох"
                label-for="input-sm"
              >
                <multiselect
                  v-model="form.paragraph_content_id"
                  :options="schoolParagraphContents"
                  :custom-label="customParagraphContentsName"
                  track-by="id"
                  label="paragraph_title"
                  :show-labels="true"
                  placeholder="Агуулга сонгох"
                ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              ><b-form-group
                label-size="sm"
                label="Сорилын төрөл сонгох"
                label-for="input-sm"
              >
                <div>
                  <b-form-select
                    v-model="form.content_type"
                    :options="contenttypes"
                    size="sm"
                    text-field="text"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('content_type'),
                      },
                    ]"
                  ></b-form-select>
                  <!-- <multiselect
                    v-model="form.teacher_id"
                    :options="sortArrays(schoolteachers)"
                    track-by="teacher_name"
                    label="teacher_name"
                    :custom-label="customLabelTeacher"
                    :show-labels="false"
                    placeholder="Багш сонгох"
                  ></multiselect> -->
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label-for="input-sm"
                label="Сорилын явц, улирал, жил сонгох:"
              >
                <b-form-select
                  v-model="form.type"
                  :options="types"
                  size="sm"
                  text-field="text"
                  value-field="value"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('type'),
                    },
                  ]"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Сорил авсан хугацаа"
                label-for="input-sm"
              >
                <b-form-input
                  v-model="form.paragraph_word_minute"
                  placeholder="Сорил авсан хугацаа"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="selectPara">
            <b-col>
              <p style="text-align: justify" v-if="selectPara">
                {{ selectedParagraphs.paragraph_content }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Суралцагч сонгох"
                label-for="input-sm"
              >
                <multiselect
                  v-model="form.student_id"
                  :options="sortArrays(students)"
                  track-by="user_id"
                  label="student_name"
                  :show-labels="false"
                  placeholder="Суралцагч сонгох"
                  :custom-label="studentName"
                ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Нийт үгийн тоо"
                label-for="input-sm"
              >
                <b-form-input
                  v-model="form.paragraph_word_real"
                  placeholder="Нийт үгийн тоо"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                :label="
                  selectRead
                    ? 'Буруу уншсан үгийн тоо'
                    : 'Буруу бичсэн үгийн тоо'
                "
                label-for="input-sm"
              >
                <b-form-input
                  v-model="form.paragraph_word_error"
                  :placeholder="
                    selectRead
                      ? 'Буруу уншсан үгийн тоо'
                      : 'Буруу бичсэн үгийн тоо'
                  "
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                :label="
                  selectRead ? 'Зөв уншсан үгийн тоо' : 'Зөв бичсэн үгийн тоо'
                "
                label-for="input-sm"
              >
                {{ sumWords }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-size="sm"
                :label="
                  selectRead
                    ? 'Эхийг уншаад ойлгосон байдал(Багш та эцэг эх, хүүхдэд өгөх зөвлөгөөгөө бичнэ үү.)'
                    : 'Эхийг цээжээр зөв бичсэн байдал(Багш та эцэг эх, хүүхдэд өгөх зөвлөгөөгөө бичнэ үү.)'
                "
                label-for="input-sm"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="form.paragraph_mean"
                  :placeholder="
                    selectRead
                      ? 'Эхийг уншаад ойлгосон байдал'
                      : 'Эхийг цээжээр зөв бичсэн байдал'
                  "
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                label-for="input-sm"
              >
                <b-form-input
                  v-model="form.paragraph_mean_eval"
                  placeholder="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="--------------"
                label-for="input-sm"
              >
                <b-form-checkbox
                  v-if="selectRead"
                  id="checkbox-1"
                  v-model="form.read"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                  Хяналтын уншлагад тооцох эсэх
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="!selectRead"
                  id="checkbox-2"
                  v-model="form.write"
                  name="checkbox-2"
                  value="1"
                  unchecked-value="0"
                >
                  Хяналтын цээж бичигт тооцох эсэх
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Үйлдэл" label-size="sm" label-for="input-sm">
                <b-button-group size="sm" class="mr-1">
                  <b-button
                    title="Save file"
                    variant="success"
                    @click.prevent="createParagraphContent()"
                  >
                    <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                    Хадгалах
                  </b-button>
                  <b-button
                    @click.prevent="cancelEdit()"
                    class="ml-3"
                    v-if="editMode"
                    :variant="editMode ? 'danger' : 'success'"
                  >
                    <b-icon icon="backspace"></b-icon>
                    Болих
                  </b-button>
                  <b-button
                    class="ml-3"
                    variant="warning"
                    @click.prevent="getAllParagraphRead()"
                  >
                    <b-icon icon="arrow-repeat"></b-icon>
                    Өгөгдөл дахин дуудах
                  </b-button>
                </b-button-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="myGetParagraphReadContents.length">
            <b-col>
              <!-- hover
                small
                caption-top
                responsive -->
              <b-table-simple style="font-size: 80%; text-align: justify">
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>№</b-th>
                    <b-th>Бүртгэсэн</b-th>
                    <b-th>Суралцагч</b-th>
                    <b-th>Агуулга</b-th>
                    <b-th>Улирал</b-th>
                    <b-th>Анги</b-th>
                    <b-th v-if="selectRead">Нийт уншсан</b-th>
                    <b-th v-if="!selectRead">Нийт бичсэн</b-th>
                    <b-th>Алдсан</b-th>
                    <b-th>Зөв</b-th>
                    <b-th colspan="2">Ойлголт</b-th>
                    <b-th>Хяналтын эсэх</b-th>
                    <b-th>Зураг</b-th>
                    <b-th>Үйлдлүүд</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(readingInfo, index) in orderByWords(
                      myGetParagraphReadContents
                    )"
                    :key="index"
                  >
                    <b-td>{{ index + 1 }}</b-td>
                    <b-td>
                      <b-badge variant="success" v-if="readingInfo.reg_emp"
                        >{{ readingInfo.reg_emp.teacher_last_name[0] }}.{{
                          readingInfo.reg_emp.teacher_name
                        }}</b-badge
                      >
                      <b-badge v-else>Багшийн мэдээлэл бүртгэгдээгүй</b-badge>
                    </b-td>

                    <b-td>
                      <b-badge variant="info" v-if="readingInfo.get_student"
                        >{{ readingInfo.get_student.student_last_name[0] }}.{{
                          readingInfo.get_student.student_name
                        }}</b-badge
                      >
                      <b-badge v-else
                        >Суралцагчийн мэдээлэл бүртгэгдээгүй</b-badge
                      >
                    </b-td>
                    <b-td
                      >{{ readingInfo.my_par_cont.paragraph_title }}:(
                      {{ readingInfo.paragraph_word_minute }} минут)
                      {{ readingInfo.type }}</b-td
                    >
                    <b-td> {{ readingInfo.my_qrt.name }}</b-td>
                    <b-td>{{ readingInfo.my_school_class.full_name }}</b-td>
                    <b-td>{{ readingInfo.paragraph_word_real }}</b-td>
                    <b-td>{{ readingInfo.paragraph_word_error }}</b-td>
                    <b-td>{{ readingInfo.paragraph_word_correct }}</b-td>
                    <b-td>{{ readingInfo.paragraph_mean }} </b-td>

                    <b-td
                      ><b-icon-star
                        variant="success"
                        v-for="(s, i) in readingInfo.paragraph_mean_eval"
                        :key="i"
                      ></b-icon-star
                    ></b-td>
                    <b-td
                      ><b-icon-star
                        variant="success"
                        v-if="readingInfo.read === 1"
                      ></b-icon-star>
                      <b-icon-star
                        variant="warning"
                        v-if="readingInfo.read === 0"
                      ></b-icon-star>
                    </b-td>
                    <b-td>
                      <img
                        v-if="readingInfo.url_read_write != null"
                        :src="readingInfo.url_read_write"
                        alt="user-avatar"
                        class="imgZoom img-bordered-sm"
                        @mouseover="hover = true"
                        @mouseleave="hover = false"
                        :class="{ zoomed: hover }"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="imgZoom img-bordered-sm"
                      />
                    </b-td>
                    <b-td>
                      <b-form-group label-size="sm" label-for="input-sm">
                        <b-button-group size="sm">
                          <b-button
                            variant="outline-warning"
                            @click.prevent="showImageUploader(readingInfo)"
                          >
                            <b-icon icon="upload"></b-icon>
                          </b-button>
                          <b-button
                            variant="outline-primary"
                            @click.prevent="
                              editReadedParagraphContent(readingInfo)
                            "
                          >
                            <b-icon icon="tools"></b-icon>
                          </b-button>
                          <b-button
                            variant="danger"
                            @click.prevent="
                              deleteReadedParagraphContent(readingInfo)
                            "
                            ><i class="far fa-trash-alt"></i
                          ></b-button>
                        </b-button-group>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td colspan="6" variant="secondary" class="text-right">
                      Дундаж:
                    </b-td>
                    <b-td variant="secondary" class="text-left">
                      <b v-if="myGetParagraphReadContents.length">{{
                        niitVgiinDundaj.toFixed(1)
                      }}</b>
                    </b-td>
                    <b-td variant="secondary" class="text-left">{{
                      niitAldsanVgiimDundaj.toFixed(1)
                    }}</b-td>
                    <b-td variant="secondary" class="text-left">{{
                      niitZuvVgiinDundaj.toFixed(1)
                    }}</b-td>
                    <b-td variant="secondary" class="text-left"></b-td>
                    <b-td variant="secondary" class="text-center">
                      <b-icon-star
                        variant="success"
                        v-for="(s, i) in niitOilgoltiinRate"
                        :key="i"
                      ></b-icon-star>
                    </b-td>
                    <b-td
                      colspan="2"
                      variant="secondary"
                      class="text-right"
                    ></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="6" variant="secondary" class="text-right"
                      >Бүртгэгдсэн сорил:</b-td
                    >
                    <b-td variant="secondary" class="text-left">
                      <b v-if="myGetParagraphReadContents.length">{{
                        myGetParagraphReadContents.length
                      }}</b>
                    </b-td>
                    <b-td colspan="6" variant="secondary" class="text-right">
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      size="xl"
      id="modal-paragraph-read"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- /.card-header -->
              <!-- form start -->
              <form @submit.prevent="updateReadedParagraphContent()">
                <div class="card-body">
                  <div class="form-row">
                    <div class="col-lg-12">
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Эх сонгох"
                            label-for="input-sm"
                          >
                            <multiselect
                              v-model="formEdit.quarter_id"
                              :options="quarters"
                              :custom-label="customQuarterName"
                              track-by="quarter_id"
                              label="quarter_name"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Улирал сонгох"
                              :allow-empty="true"
                              deselect-label="Can't remove this value"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong>
                                  {{ option.quarter_name }}-р улирал</strong
                                ><strong> сонгогдлоо</strong></template
                              >
                            </multiselect>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Эх сонгох"
                            label-for="input-sm"
                          >
                            <multiselect
                              v-model="formEdit.paragraph_content_id"
                              :options="schoolParagraphContents"
                              :custom-label="customParagraphContentsName"
                              track-by="id"
                              label="paragraph_title"
                              :show-labels="true"
                              placeholder="Агуулга сонгох"
                            ></multiselect>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          ><b-form-group
                            label-size="sm"
                            label="Сорилын төрөл сонгох"
                            label-for="input-sm"
                          >
                            <div>
                              <b-form-select
                                v-model="formEdit.content_type"
                                :options="contenttypes"
                                size="sm"
                                text-field="text"
                                value-field="value"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formEdit.errors.has('content_type'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label-for="input-sm"
                            label="Сорилын явц, улирал, жил сонгох:"
                          >
                            <b-form-select
                              v-model="formEdit.type"
                              :options="types"
                              size="sm"
                              text-field="text"
                              value-field="value"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': formEdit.errors.has('type'),
                                },
                              ]"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Хугацаа"
                            label-for="input-sm"
                          >
                            <b-form-input
                              v-model="formEdit.paragraph_word_minute"
                              placeholder="Хугацаа"
                              type="number"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="selectParaEdit">
                        <b-col>
                          <p style="text-align: justify" v-if="selectParaEdit">
                            {{ selectedParagraphsEdit.paragraph_content }}
                          </p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Суралцагч сонгох"
                            label-for="input-sm"
                          >
                            <multiselect
                              v-model="formEdit.student_id"
                              :options="sortArrays(students)"
                              track-by="user_id"
                              label="student_name"
                              :show-labels="false"
                              placeholder="Суралцагч сонгох"
                              :custom-label="studentName"
                            ></multiselect>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Нийт үгийн тоо"
                            label-for="input-sm"
                          >
                            <b-form-input
                              v-model="formEdit.paragraph_word_real"
                              placeholder="Нийт үгийн тоо"
                              type="number"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            :label="
                              !selectReadEdit
                                ? 'Буруу уншсан үгийн тоо'
                                : 'Буруу бичсэн үгийн тоо'
                            "
                            label-for="input-sm"
                          >
                            <b-form-input
                              v-model="formEdit.paragraph_word_error"
                              :placeholder="
                                !selectReadEdit
                                  ? 'Буруу уншсан үгийн тоо'
                                  : 'Буруу бичсэн үгийн тоо'
                              "
                              type="number"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            :label="
                              !selectReadEdit
                                ? 'Зөв уншсан үгийн тоо'
                                : 'Зөв бичсэн үгийн тоо'
                            "
                            label-for="input-sm"
                          >
                            {{ sumWordsEdit }}
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            :label="
                              !selectReadEdit
                                ? 'Эхийг уншаад ойлгосон байдал'
                                : 'Эхийг бичээд ойлгосон байдал'
                            "
                            label-for="input-sm"
                          >
                            <b-form-textarea
                              id="textarea"
                              v-model="formEdit.paragraph_mean"
                              :placeholder="
                                !selectReadEdit
                                  ? 'Буруу уншсан үгийн тоо'
                                  : 'Буруу бичсэн үгийн тоо'
                              "
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                            label-for="input-sm"
                          >
                            <b-form-input
                              v-model="formEdit.paragraph_mean_eval"
                              placeholder="Ойлголтыг 1-5 оноогоор үнэлнэ үү."
                              type="number"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label-size="sm"
                            label="--------------"
                            label-for="input-sm"
                          >
                            <b-form-checkbox
                              v-if="selectReadEdit"
                              id="checkbox-1"
                              v-model="formEdit.read"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                            >
                              Хяналтын уншлагад тооцох эсэх
                            </b-form-checkbox>
                            <b-form-checkbox
                              v-if="!selectReadEdit"
                              id="checkbox-2"
                              v-model="formEdit.write"
                              name="checkbox-2"
                              value="1"
                              unchecked-value="0"
                            >
                              Хяналтын цээж бичигт тооцох эсэх
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="updateReadedParagraphContent()"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click.prevent="cancelEdit1()"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Зураг оруулах -->
    <b-modal
      size="xs"
      id="modal-image"
      class="modal fade"
      title="Зураг нэмэх"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label>Зураг</label>
              <b-form-file
                v-model="imageData"
                id="image"
                browse-text="Зураг сонгох"
                :class="[
                  'form-control',
                  {
                    'is-invalid': imgError,
                  },
                ]"
                @change="setImg"
                accept="image/jpeg, image/png"
              ></b-form-file>
              <span class="col-sm-5">
                {{ imgError }}
              </span>
            </div>
            <div v-if="formImg.image" class="form-group">
              <b-avatar
                variant="light"
                size="6rem"
                :src="formImg.image"
                square
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="createImage()"
        >
          Хадгалах
        </button>
        <button class="btn btn-secondary" @click.prevent="cancelUploadImg">
          Болих
        </button>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      editMode: false,
      schoolclasses: [], //ашигласан
      selectClass: false,
      quarters: [], //ашигласан
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        paragraph_content_id: "",
        content_type: "", // 0-уншлага 1-цээж бичиг
        quarter_id: "",
        type: "", // явц / улирал / жил
        school_class_id: "",
        reg_emp_id: "",
        manager_id: "",
        teacher_id: "",
        parent_id: "",
        student_id: "",
        paragraph_word_correct: 0,
        paragraph_word_error: 0,
        paragraph_word_real: 0,
        paragraph_word_minute: 1,
        paragraph_mean: "",
        paragraph_mean_eval: 0,
        read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
        write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      }),
      schoolParagraphContents: [], //Бүх агуулга
      students: [],

      selectPara: false,
      selectedParagraphs: "",

      contenttypes: [
        { value: 0, text: "Уншлага" },
        { value: 1, text: "Цээж бичиг" },
      ],
      types: [
        { value: "явц", text: "Явц" },
        { value: "улирал", text: "Улирал" },
        { value: "жил", text: "Жил" },
      ],
      selectRead: false,
      myGetParagraphReadContents: [],

      //Статистик дундаж тооцоо
      niitVgiinDundaj: null,
      niitZuvVgiinDundaj: null,
      niitAldsanVgiimDundaj: null,
      niitOilgoltiinRate: null,

      //Edit функц
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formModalTitle: "Сорил засах",
      selectReadEdit: false,
      formEdit: new Form({
        id: "",
        year_id: "",
        school_id: "",
        paragraph_content_id: "",
        content_type: "", // 0-уншлага 1-цээж бичиг
        quarter_id: "",
        type: "", // явц / улирал / жил
        school_class_id: "",
        reg_emp_id: "",
        manager_id: "",
        teacher_id: "",
        parent_id: "",
        student_id: "",
        paragraph_word_correct: 0,
        paragraph_word_error: 0,
        paragraph_word_real: 0,
        paragraph_word_minute: 1,
        paragraph_mean: "",
        paragraph_mean_eval: 0,
        read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
        write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      }),
      selectParaEdit: false,
      selectedParagraphsEdit: "",

      //   Хүүхдийн зураг хадгалах
      imageData: null,
      imgError: "",
      formImg: new Form({
        id: null,
        student_id: null,
        image: "",
      }),
      hover: false,
    };
  },
  components: { Multiselect },
  watch: {
    "form.content_type"(newVal, oldVal) {
      //   console.log(newVal, oldVal);
      if (newVal === 0) {
        this.selectRead = true;
        this.form.write = null;
        this.form.read = "0";
      } else if (newVal === 1) {
        this.selectRead = false;
        this.form.read = null;
        this.form.write = "0";
      }
      //   this.selectPara = true;
      //   this.selectedParagraphs = a[0];
      //   console.log(this.selectedParagraphs);
    },
    "form.paragraph_content_id"(newVal, oldVal) {
      // console.log(newVal, oldVal);
      if (Object.keys(newVal).length === 0) {
        this.selectPara = false;
        this.selectedParagraphs = "";
      } else {
        let a = this.schoolParagraphContents.filter(
          (el) => el.id === newVal.id
        );
        this.selectPara = true;
        this.selectedParagraphs = a[0];
        //   console.log(this.selectedParagraphs);
        // Fire.$emit("loadParagraphContentReads");
      }
    },
    "formEdit.paragraph_content_id"(newVal, oldVal) {
      // console.log(newVal, oldVal);
      if (Object.keys(newVal).length === 0) {
        this.selectParaEdit = false;
        this.selectedParagraphsEdit = "";
      } else {
        let a = this.schoolParagraphContents.filter(
          (el) => el.id === newVal.id
        );
        this.selectParaEdit = true;
        this.selectedParagraphsEdit = a[0];
        // console.log(this.selectedParagraphsEdit);
        Fire.$emit("loadParagraphContentReads");
      }
    },
  },
  computed: {
    sumWords() {
      let a = this.form.paragraph_word_real;
      let b = this.form.paragraph_word_error;
      this.form.paragraph_word_correct = parseInt(a) - parseInt(b);
      return this.form.paragraph_word_correct;
    },
    sumWordsEdit() {
      let a = this.formEdit.paragraph_word_real;
      let b = this.formEdit.paragraph_word_error;
      this.formEdit.paragraph_word_correct = parseInt(a) - parseInt(b);
      return this.formEdit.paragraph_word_correct;
    },
  },
  methods: {
    sortArrays(students) {
      return _.orderBy(students, "student_name/student_last_name", "asc");
    },
    orderByWords(e) {
      return _.orderBy(e, "paragraph_word_real", "desc");
    },
    studentName({ student_name, student_last_name }) {
      return `${student_last_name}. ${student_name}`;
    },
    getAllParagraphContentInfos() {
      axios
        .get("/teacher/getAllParagraphReadInfoTeacher")
        .then((response) => {
          this.quarters = response.data.schoolquarters;
          this.schoolclasses = response.data.getMySchoolClassesInfo;
          //   this.schoolParagraphContents = response.data.schoolParagraphContents;
          //   this.students = response.data.students;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllParagraphRead() {
      axios
        .post("/teacher/getAllParagraphReadTeacher", { form: this.form })
        .then((response) => {
          //   this.schoolclasses = response.data.schoolclasses;
          this.myGetParagraphReadContents =
            response.data.myGetParagraphReadContents;
          // Нийт үгийн дундаж
          var niitVgDundaj = 0;
          for (var i = 0; i < this.myGetParagraphReadContents.length; i++) {
            niitVgDundaj += parseInt(
              this.myGetParagraphReadContents[i]["paragraph_word_real"]
            ); //don't forget to add the base
            // console.log(niitVgDundaj);
          }
          this.niitVgiinDundaj =
            niitVgDundaj / this.myGetParagraphReadContents.length;

          // Зөв үгийн дундаж
          var niitZuvVgDundaj = 0;
          for (var i = 0; i < this.myGetParagraphReadContents.length; i++) {
            niitZuvVgDundaj += parseInt(
              this.myGetParagraphReadContents[i]["paragraph_word_correct"]
            ); //don't forget to add the base
            // console.log(niitZuvVgDundaj);
          }
          this.niitZuvVgiinDundaj =
            niitZuvVgDundaj / this.myGetParagraphReadContents.length;

          // Алдсан үгийн дундаж
          var niitAldsanVgDundaj = 0;
          for (var i = 0; i < this.myGetParagraphReadContents.length; i++) {
            niitAldsanVgDundaj += parseInt(
              this.myGetParagraphReadContents[i]["paragraph_word_error"]
            ); //don't forget to add the base
            // console.log(niitAldsanVgDundaj);
          }
          this.niitAldsanVgiimDundaj =
            niitAldsanVgDundaj / this.myGetParagraphReadContents.length;
          // Үнэлгээний Rate
          var niitOilgoltRate = 0;
          for (var i = 0; i < this.myGetParagraphReadContents.length; i++) {
            niitOilgoltRate += parseInt(
              this.myGetParagraphReadContents[i]["paragraph_mean_eval"]
            ); //don't forget to add the base
            // console.log(niitOilgoltRate);
          }
          this.niitOilgoltiinRate = Math.round(
            niitOilgoltRate / this.myGetParagraphReadContents.length
          );

          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelEdit() {
      // console.log("asdas");
      this.form.reset();
      this.editMode = false;
      this.selectClass = false;
    },
    createParagraphContent() {
      this.form
        .post("/teacher/createParagraphReadContentTeacher")
        .then((res) => {
          // console.log(res);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadParagraphContentReads");
          this.form.paragraph_word_real = 0;
          this.form.paragraph_word_error = 0;
          this.form.paragraph_mean = "";
          this.form.paragraph_mean_eval = 0;
          this.form.student_id = "";
          if (this.form.content_type === 0) {
            //Уншлага
            this.selectRead = true;
            this.form.write = null;
          } else if (this.form.content_type === 1) {
            //Цээж бичиг
            this.selectRead = false;
            this.form.read = null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteReadedParagraphContent(readingInfo) {
      //   console.log(readingInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Дахин сэргээх боломжгүйг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцал!",
        confirmButtonColor: "#008000",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteReadParaContentTeacher/" + readingInfo.id)
            .then((res) => {
              Swal.fire("Устгалаа!", "Амжилттай устгагдсан.", "success");
              Fire.$emit("loadParagraphContentReads");
              //   console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцлагдсан",
            "Устгах үйлдлийг амжилттай цуцалсан :)",
            "error"
          );
        }
      });
    },
    editReadedParagraphContent(readingInfo) {
      this.$bvModal.show("modal-paragraph-read");
      this.formEdit.fill(readingInfo);
      //   console.log(this.formEdit, "formEdit");
      this.selectReadEdit = true;
      let par_con_id = this.schoolParagraphContents.filter(
        (el) => el.id === readingInfo.paragraph_content_id
      );
      this.formEdit.paragraph_content_id = par_con_id[0];
      let stud = this.students.filter(
        (el) => el.user_id === readingInfo.student_id
      );
      this.formEdit.student_id = stud[0];
      let quart = this.quarters.filter(
        (el) => el.quarter_id === readingInfo.quarter_id
      );
      this.formEdit.quarter_id = quart[0];
    },

    updateReadedParagraphContent() {
      //   console.log("update");
      this.formEdit
        .put("/teacher/updateReadParaContentTeacher")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit1();
          Fire.$emit("loadParagraphContentReads");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    cancelEdit1() {
      // console.log("asdas");
      this.formEdit.reset();
      this.editMode = false;
      this.selectReadEdit = false;
      //   this.selectClass = false;
      this.$bvModal.hide("modal-paragraph-read");
    },
    // Суралцагчийн уншлага, цээж бичгийн зураг оруулах
    showImageUploader(items) {
      //   console.log(items);
      this.imageData = null;
      this.formImg.reset();
      this.formImg.id = items.id;
      this.formImg.student_id = items.student_id;
      this.$bvModal.show("modal-image");
    },
    setImg(e) {
      this.imgError = "";
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        return;
      }

      if (img.size > 1024 * 512) {
        e.preventDefault();
        this.imgError = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.reamImage(img);
    },
    reamImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.formImg.image = e.target.result;
      };
    },
    createImage() {
      this.formImg
        .post("/teacher/uploadReadWriteImage")
        .then((response) => {
          if (response.data.success == "yes") {
            this.$toastr.s("Зураг амжилттай нэмлээ", "Амжилттай");
            this.cancelUploadImg();
            this.getCourseStudents();
          } else this.$toastr.e("Зураг нэмэх үед алдаа гарлаа.", "Анхаар!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteImage(image) {
      //   console.log(parentmeet);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Та суралцагчийн зургийг устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteImage/" + image.id)
            .then((res) => {
              this.$toastr.s("Зураг амжилттай устгалаа", "Амжилттай");
              this.getCourseStudents();
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    cancelUploadImg() {
      this.imageData = null;
      this.formImg.reset();
      this.$bvModal.hide("modal-image");
    },
    // Бүх ангиудыг авах
    schoolClassClick(id, full_name) {
      // this.cancelEdit();
      this.selectClass = false;
      this.selectPara = false;
      this.form.reset();
      //   console.log(this.form);
      this.form.school_class_id = id;
      this.chosesClass = full_name;
      this.students = [];
      this.schoolParagraphContents = [];

      // console.log(id);
      axios
        .get(
          "/all_worker_functions/getSchoolClassParagraphContents/" +
            this.form.school_class_id
        )
        .then((response) => {
          this.schoolParagraphContents = response.data.schoolParagraphContents;
          this.students = response.data.students;
          // this.quarters = response.data.quarters;
          this.selectClass = true;
          // console.log(response.data.schoolclassesscourses);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    customParagraphContentsName({ paragraph_title, reg_emp }) {
      return `${paragraph_title}( ${reg_emp["teacher_last_name"]["0"]} .${reg_emp["teacher_name"]})`;
    },
    customQuarterName({ quarter_name }) {
      return `${quarter_name}-р улирал`;
    },
  },
  created() {
    this.getAllParagraphContentInfos();
    Fire.$on("loadParagraphContentReads", () => {
      this.getAllParagraphRead();
    });
    // Fire.$on("loadSchoolClasses", (someData) => {
    //   // console.log(
    //   //     someData,
    //   //     "Fire on loadSchoolClasses аас " + someData.school_class_id,
    //   //     someData.full_name
    //   // );
    //   this.schoolClass(someData.school_class_id, someData.full_name);
    // });
    // Fire.$on("removeFromGradeStudent", (schoolclassesscourse) => {
    //   this.selectClassStudents(this.removeSchoolClassCourse);
    // });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.imgZoom {
  height: 60px;
  transition: transform 0.3s ease; /* Аажмаар томруулах шилжилт */
}

.imgZoom.zoomed {
  transform: scale(6); /* Томруулах хэмжээ */
  z-index: 9999; /* Өөр элементүүдээс дээгүүр харагдах */
  position: relative;
}
</style>
