<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <strong>НИЙТ ТӨСӨВ</strong>
        </div>
        <div class="card-header">
          <excel-export
            :fieldList="fields"
            :dataList="selectedBudgets"
          ></excel-export>
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedStatus"
                    :options="statusOptions"
                    text-field="text"
                    size="sm"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedStatus'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedYear"
                    :options="yearOptions"
                    text-field="text"
                    size="sm"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedYear'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedType"
                    :options="budgetTypes"
                    text-field="name"
                    size="sm"
                    value-field="id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedType'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="3">
                  <b-form-select
                    v-model="selectedDep"
                    v-on:change="getTeacherList"
                    :options="depOptions"
                    text-field="department_name"
                    size="sm"
                    value-field="id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedDep'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedEmp"
                    :options="empOptions"
                    text-field="emp_teacher_name"
                    value-field="emp_id"
                    size="sm"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedEmp'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    size="sm"
                    variant="primary"
                    @click.prevent="getAllFinanceBudgets()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="checked1"
                      name="checkbox-1"
                      @change="allCheckBox()"
                    >
                      <!-- value="accepted"
                      unchecked-value="not_accepted" -->
                    </b-form-checkbox>
                  </div>
                  <b-iconstack
                    variant="warning"
                    v-if="select.length != 0"
                    font-scale="1.3"
                    @click="selectCheckBoxAllBudgetConfirm()"
                  >
                    <b-icon stacked icon="square-fill"></b-icon>
                    <b-icon
                      variant="success"
                      stacked
                      icon="cloud-upload"
                      aria-hidden="true"
                    ></b-icon>
                  </b-iconstack>
                </th>
                <th>
                  <!-- хэвийн ажиллаж байгаа... -->
                  <!-- Батлах(
                  <b-badge variant="warning" @click="getSelectItems()"
                    >Сонгох</b-badge
                  >) -->
                </th>
                <th>Овог нэр</th>
                <!-- <th>БНБ</th>
                    <th>Ажлын байр</th> -->
                <th>Төсвийн төрөл</th>
                <th>Төсөвлөсөн бүтээгдэхүүн</th>
                <th>Зураг</th>
                <th>Нэгж үнэ(төсөвт ооруулсан)</th>
                <th>Тухайн бүтээгдэхүүний үнэ</th>
                <th>Нэгж үнийн зөрүү</th>
                <th>Авах тоо</th>
                <th>Батлуулах дүн</th>
                <th>Баталсан</th>
                <th>Баталсан дүн</th>
                <th>Зарцуулсан дүн</th>
                <th>Зарцуулсан тайлбар</th>
              </tr>
            </thead>
            <tbody v-for="(budget, index) in selectedBudgets" :key="budget.id">
              <tr class="odd">
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <b-form-checkbox
                    :disabled="budget.confirmed == 1 ? true : false"
                    size="sm"
                    v-model="budget.check"
                    @change="singleCheckBoxClick(budget)"
                  ></b-form-checkbox>
                </td>
                <td tabindex="0">
                  <!-- Төсөв бүрииг шалгаж батлах -->
                  <a
                    href="#"
                    size="sm"
                    @click="confirmBudget(budget)"
                    class="badge bg-primary"
                    :class="{
                      'bg-success': budget.confirmed == 1,
                    }"
                    v-b-popover.hover.bottom="
                      'Та энэ төсвийг баталгаажуулах уу? Баталгаажуулах тоо хэмжээг оруулах ёстой'
                    "
                    title="Батлах"
                    ><i class="fas fa-check"></i
                  ></a>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="width: 8rem"
                >
                  {{ budget.employee_teacher_last_name[0] }}.
                  {{ budget.employee_teacher_name }}
                </td>
                <td tabindex="0">
                  {{ budget.budget_type_name }}
                </td>
                <td tabindex="0">
                  {{ budget.budget_item_name }}
                </td>
                <td tabindex="0">
                  <img
                    v-b-tooltip.hover.top.html="
                      '<dd><img src=' +
                      budget.budget_images_1 +
                      ' style=' +
                      'width:100%!important </dd></dl>'
                    "
                    class="direct-chat-img"
                    :src="budget.budget_images_1"
                    alt="user-avatar"
                  />
                </td>
                <td tabindex="0">
                  <b-badge variant="success"> {{ budget.price }} төг</b-badge>
                </td>
                <td tabindex="0">
                  <b-badge variant="primary"
                    >{{ budget.budget_item_price }} төг</b-badge
                  >
                </td>
                <td tabindex="0">
                  <b-badge variant="warning"
                    >{{
                      budgetPriceDifference(
                        budget.price,
                        budget.budget_item_price
                      )
                    }}
                    төг</b-badge
                  >
                </td>
                <td tabindex="0">
                  <b-badge variant="success">{{ budget.pieces }}</b-badge>
                </td>
                <td tabindex="0">
                  <b-badge variant="info">{{ budget.total }} төг</b-badge>
                </td>
                <td tabindex="0">
                  <b-badge variant="success" v-show="budget.confirmed == 1"
                    >Баталсан</b-badge
                  >
                  <b-badge variant="warning" v-show="budget.confirmed == 0"
                    >Батлаагүй</b-badge
                  >
                </td>
                <td tabindex="0">
                  <b-badge variant="success" v-show="budget.confirmed == 1"
                    >{{ budget.confirmed_total }} төг</b-badge
                  >
                  <b-badge variant="warning" v-show="budget.confirmed == 0"
                    >0 төг</b-badge
                  >
                </td>
                <td tabindex="0">
                  <b-badge
                    variant="success"
                    v-show="budget.zartsuulsan !== null"
                    >{{ budget.zartsuulsan }} төг</b-badge
                  >
                  <b-badge
                    variant="warning"
                    v-show="budget.zartsuulsan === null"
                    >0 төг</b-badge
                  >
                </td>
                <td tabindex="0">
                  <b-badge
                    variant="info"
                    v-show="budget.zartsuulsan_tailbar !== null"
                    >{{ budget.zartsuulsan_tailbar }}</b-badge
                  >
                  <b-badge
                    variant="info"
                    v-show="budget.zartsuulsan_tailbar === null"
                    >Тайлбар ороогүй</b-badge
                  >
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td colspan="5"></td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ negjUne.toLocaleString() }} </strong>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ tuhainUne.toLocaleString() }} </strong>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ zuruuUne.toLocaleString() }} </strong>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ avahToo.toLocaleString() }} </strong>
                </td>
                <td colspan="2" class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ batluulahUne.toLocaleString() }} </strong>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ batalsanUne.toLocaleString() }} </strong>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ zartsuulsanUne.toLocaleString() }} </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      id="modal-budget-confirm"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсөв батлах"
      class="modal fade"
      hide-footer
      @hide="cancelBudgetConfirm"
    >
      <div class="modal-body">
        <table class="table text-wrap">
          <tbody>
            <tr role="row">
              <th>Төсөв эзэмшигч</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.employee_teacher_last_name[0] }}.
                {{ form.employee_teacher_name }}
              </td>
            </tr>
            <tr role="row">
              <th>Төсвийн төрөл</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.budget_type_name }}
              </td>
            </tr>
            <tr role="row">
              <th>Төсөвлөсөн бүтээгдэхүүн</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.budget_item_name }}
              </td>
            </tr>
            <tr role="row">
              <th>Нэгж үнэ(төсөвт ооруулсан)</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.price }}
              </td>
            </tr>
            <tr role="row">
              <th>Тухайн бүтээгдэхүүний үнэ</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.budget_item_price }}
              </td>
            </tr>
            <tr role="row">
              <th>Авах тоо</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.pieces }}
              </td>
            </tr>
            <tr role="row">
              <th>Батлуулах дүн</th>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ form.total }}
              </td>
            </tr>
            <tr role="row">
              <th>Батлагдсан төсвийн дүн</th>
              <td class="dtr-control sorting_1" tabindex="0">
                <form class="form-horizontal">
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="confirmed_total"
                      placeholder="Батлагдсан дүн"
                      v-model="form.confirmed_total"
                    />
                  </div>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="confirmBudgetUpdate()" variant="success">
          Хадгалах
        </b-button>
        <b-button @click="cancelBudgetConfirm()" variant="danger">
          Хаах
        </b-button>
      </div>
    </b-modal>

    <!-- Сонгосон элементүүдийг батлах -->
    <b-modal
      size="lg"
      id="modal-budget-confirm-select-items"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсөв батлах"
      class="modal fade"
      hide-footer
      @hide="resetBudgetsToConfirm"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label
                >Төсөв сонгох
                <b-button variant="outline-primary" @click="addPushAllBudget()"
                  >Бүх төсвийг сонгох</b-button
                >
              </label>
              <multiselect
                v-model="selectBudgetsToConfirms"
                :multiple="true"
                track-by="id"
                label="id"
                :custom-label="customLabelNameSelectBudgets"
                placeholder="Төсөв сонгоно уу?"
                :options="budgets"
                :searchable="true"
                :allow-empty="true"
                :clear-on-select="true"
                :close-on-select="false"
                :hide-selected="true"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.employee_teacher_last_name[0]
                    }}{{ option.employee_teacher_name }}, (Нийт:
                    {{ option.total }} төг), (Төрөл:
                    {{ option.budget_type_name }}), (Төсөвлөсөн бүтээгдэхүүн:
                    {{ option.budget_item_name }})</strong
                  ></template
                >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="storePushAllBudgetConfirm()" variant="success">
          Хадгалах
        </b-button>
        <b-button @click="resetBudgetsToConfirm()" variant="danger">
          Хаах
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import excelExport from "../AllFunctions/excel.vue";
export default {
  data() {
    return {
      priceList: [],
      itemPList: [],
      diffList: [],
      piecesList: [],
      batluulahList: [],
      batalsanList: [],
      zartsuulsanList: [],
      negjUne: 0,
      tuhainUne: 0,
      zuruuUne: 0,
      avahToo: 0,
      batluulahUne: 0,
      batalsanUne: 0,
      zartsuulsanUne: 0,
      selectedStatus: 2,
      statusOptions: [
        { value: 2, text: "Бүх төлөв" },
        { value: 1, text: "Баталсан" },
        { value: 0, text: "Батлаагүй" },
      ],
      selectedYear: null,
      selectedType: null,
      selectedDep: null,
      selectedEmp: null,
      subjectOptions: [],
      depOptions: [],
      empOptions: [],
      yearOptions: [
        { value: null, text: "Бүх төсөв" },
        { value: "thisYear", text: "Энэ жилийн төсөв" },
        { value: "nextYear", text: "Ирэх жилийн төсөв" },
      ],
      editMode: false,
      itemAddMode: false,
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      budgets: [],
      budgetsNextYear: [],
      years: [],
      budgetTypes: [],
      items: [],
      form: new Form({
        id: "",
        employee_teacher_name: "",
        employee_teacher_last_name: "",
        budget_type_name: "",
        budget_item_name: "",
        budget_item_price: "",
        year_id: "",
        school_id: "",
        budget_type_id: "",
        budget_item_id: "",
        dep_id: "",
        emp_id: "",
        project_id: "",
        name: "",
        measure_unit: "",
        pieces: "",
        price: "",
        total: "",
        budget_images_1: "",
        budget_images_2: "",
        budget_images_3: "",
        zoriulalt: "",
        compare: "",
        supplier: "",
        choose_1: "",
        choose_1_link: "",
        choose_2: "",
        choose_2_link: "",
        choose_3: "",
        choose_3_link: "",
        confirmed_total: "",
        zartsuulsan: "",
        zartsuulsan_tailbar: "",
      }),
      formItem: new Form({
        id: "",
        budget_type_id: "",
        item_name: "",
        item_price: "",
        item_description: "",
      }),
      //Зурагтай ажиллах
      defaultimage: "/images/users/user.png",
      imgError1: "",
      imgError2: "",
      imgError3: "",
      imgLoaded1: false,
      imgLoaded2: false,
      imgLoaded3: false,

      //Сонгогдсон төсвүүдийг батлах
      selectBudgetsToConfirms: [],
      checked1: false,
      budgetCheck: [],
      select: [],
      fields: [],
    };
  },
  components: {
    Multiselect,
    excelExport: excelExport,
  },
  computed: {
    totalUne: function () {
      let totalDun;
      if (this.form.pieces && this.form.price) {
        totalDun = this.form.pieces * this.form.price;
        this.form.total = totalDun;
        return totalDun;
      } else {
        this.form.total = 0;
        return 0;
      }
    },
    selectedBudgets: function () {
      //   this.budgets.forEach((el) => {

      //   });
      return this.budgets;
    },
  },
  methods: {
    // Сонгосон элементүүдийг баталгаажуулах
    singleCheckBoxClick(budget) {
      //   console.log(budget);
      //   console.log(this.select);
      let index = this.select.findIndex((el) => el.id == budget.id);
      //   console.log(index);
      if (index === -1) {
        this.select.splice(index, index);
        this.select.push(budget);
        // console.log(index + " нэмлээ");
      } else if (index === 0) {
        // console.log(index + " хамгийн анхны элемент хаслаа");
        this.select.splice(index, index + 1);
      } else {
        this.select.splice(index, index);
        // console.log(index + " дээрх элементийг хаслаа");
      }
      if (this.select.length == 0) {
        this.checked1 = false;
      }
      //   this.select.forEach((element) => {
      //     console.log(element.budget_item_name);
      //   });
    },
    allCheckBox() {
      //   console.log(this.checked1);
      if (this.checked1 == true) {
        this.select = [];

        this.budgets.forEach((el) => {
          el.check = true;
        });
        let i = this.budgets;
        this.select = i.filter((el) => el.confirmed == 0);
      } else if (this.checked1 == false) {
        let s = this.budgets;
        s.forEach((el) => {
          if (el.confirmed == 1) {
          } else {
            el.check = false;
          }
          //   el.check = false;
        });
        this.select = [];
      }
    },
    selectCheckBoxAllBudgetConfirm() {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Сонгогдсон төсвүүдийг 'БАТАЛСАН' төлөвт шилжүүлэх гэж байна! Дүнгийн утгыг төсөвт санал оруулсан багш, ажилтны саналаар батлах болно.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, батлах!",
        cancelButtonText: "Үгүй, цуцлах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/finance/storePushAllBudgetConfirm", {
              items: this.select,
            })
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("loadBudgets");
              //   this.resetBudgetsToConfirm();
              Swal.fire(
                "Батталаа!",
                "Сонгогдсон төсвүүдийг амжилттай баталлаа.",
                "success"
              );
            })
            .catch();
        }
      });
    },

    // Сонгосон элементүүдийг баталгаажуулах
    getSelectItems() {
      //   console.log("select items");
      this.$bvModal.show("modal-budget-confirm-select-items");
    },
    resetBudgetsToConfirm() {
      this.selectBudgetsToConfirms = [];
      this.$bvModal.hide("modal-budget-confirm-select-items");
    },
    addPushAllBudget() {
      this.selectBudgetsToConfirms = this.budgets.filter((el) => {
        return el.confirmed == 0;
      });
    },
    storePushAllBudgetConfirm() {
      axios
        .post("/finance/storePushAllBudgetConfirm", {
          items: this.selectBudgetsToConfirms,
        })
        .then((res) => {
          // console.log(res.data);
          Fire.$emit("loadBudgets");
          this.resetBudgetsToConfirm();
        })
        .catch();
    },
    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelNameSelectBudgets({
      employee_teacher_last_name,
      employee_teacher_name,
      total,
      budget_type_name,
      budget_item_name,
    }) {
      return `${employee_teacher_last_name[0]}. ${employee_teacher_name}, (Төрөл: ${budget_type_name}), (Төсөвлөсөн бүтээгдэхүүн: ${budget_item_name}), (Нийт: ${total} төг)`;
    },

    getAllFinanceBudgets() {
      axios
        .post("/finance/getAllFinanceBudgets/", {
          selectedStatus: this.selectedStatus,
          selectedYear: this.selectedYear,
          selectedType: this.selectedType,
          selectedDep: this.selectedDep,
          selectedEmp: this.selectedEmp,
        })
        .then((response) => {
          this.fields = response.data.fields;
          this.budgetTypes = response.data.budgetTypes;
          this.budgetTypes.push({
            id: null,
            name: "Бүх төрөл",
          });
          this.depOptions = response.data.depOptions;
          this.depOptions.push({
            id: null,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.budgets = response.data.budgets;

          this.budgets.forEach((el) => {
            if (el.confirmed == 1) {
              el.check = true;
            } else {
              el.check = false;
            }
          });
          this.checked1 = false;
          this.select = [];
          this.budgetCheck = this.budgets;
          this.years = response.data.years;
          this.items = response.data.items;
          this.sumCheck(this.selectedBudgets);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sumCheck(allBudget) {
      this.priceList = [];
      this.itemPList = [];
      this.diffList = [];
      this.piecesList = [];
      this.batluulahList = [];
      this.batalsanList = [];
      this.zartsuulsanList = [];
      allBudget.forEach((element) => {
        if (element.price == null) this.priceList.push(0);
        else this.priceList.push(parseInt(element.price));
        if (element.budget_item_price == null) this.itemPList.push(0);
        else this.itemPList.push(parseInt(element.budget_item_price));
        if (element.price == null && element.budget_item_price == null)
          this.diffList.push(0);
        else
          this.diffList.push(
            parseInt(
              this.budgetPriceDifference(
                element.price,
                element.budget_item_price
              )
            )
          );
        if (element.pieces == null) this.piecesList.push(0);
        else this.piecesList.push(parseInt(element.pieces));
        if (element.total == null) this.batluulahList.push(0);
        else this.batluulahList.push(parseInt(element.total));
        if (element.confirmed_total == null) this.batalsanList.push(0);
        else this.batalsanList.push(parseInt(element.confirmed_total));
        if (element.zartsuulsan == null) this.zartsuulsanList.push(0);
        else this.zartsuulsanList.push(parseInt(element.zartsuulsan));
      });

      //   console.log(this.zartsuulsanList, "asdasd");
      this.negjUne = this.priceList.reduce((a, b) => a + b, 0);
      this.tuhainUne = this.itemPList.reduce((a, b) => a + b, 0);
      this.zuruuUne = this.diffList.reduce((a, b) => a + b, 0);
      this.avahToo = this.piecesList.reduce((a, b) => a + b, 0);
      this.batluulahUne = this.batluulahList.reduce((a, b) => a + b, 0);
      this.batalsanUne = this.batalsanList.reduce((a, b) => a + b, 0);
      this.zartsuulsanUne = this.zartsuulsanList.reduce((a, b) => a + b, 0);
    },
    getTeacherList() {
      this.selectedEmp = null;
      axios
        .get("/finance/getTeacherList/" + this.selectedDep)
        .then((response) => {
          this.empOptions = response.data.teachers;
          this.empOptions.push({
            emp_id: null,
            emp_teacher_name: "Нийт мэргэжлийн баг",
          });
          // console.log(this.empOptions);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    budgetPriceDifference(price, budget_item_price) {
      return price - budget_item_price;
    },
    budgetType({ name }) {
      return `Төсвийн ангилал: ` + `${name} `;
    },
    cancelBudgetConfirm() {
      this.$bvModal.hide("modal-budget-confirm");
    },
    confirmBudget(budget) {
      this.form.fill(budget);
      this.$bvModal.show("modal-budget-confirm");
    },
    confirmBudgetUpdate() {
      this.form
        .put("/finance/confirmBudget/" + this.form.id)
        .then((res) => {
          Fire.$emit("loadBudgets");
          this.cancelBudgetConfirm();
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getAllFinanceBudgets();
    Fire.$on("loadBudgets", () => {
      this.getAllFinanceBudgets();
    });
  },
};
</script>

<style></style>
