<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card p-2">
        <div class="card-header pr-0 d-flex align-items-center">
          <h3 class="card-title">Анги</h3>
          <input
            type="text"
            v-model="searchTerm"
            class="form-control ml-3"
            placeholder="Анги, бүлэг талбараар хайх..."
            style="max-width: 200px"
          />
          <b-button @click="showLog" size="sm" class="ml-auto" variant="success"
            ><b-icon icon="list-ul" aria-hidden="true"></b-icon> Өмнөх жилүүд</b-button
          >
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <div class="dataTables_wrapper dt-bootstrap4">
            <table
              class="table table-bordered table-hover table-striped dataTable dtr-inline"
              role="grid"
            >
              <thead>
                <tr role="row">
                  <th>№</th>
                  <th>Анги</th>
                  <th>Бүлэг</th>
                  <th>Бүлэг дүүргэлт</th>
                  <th>Ангийн бүтэн нэр</th>
                  <th>АУБ</th>
                  <th>Ангийн №</th>
                  <th>Кабинет</th>
                  <th>Үүсгэсэн</th>
                  <th>Зассан</th>
                  <th>Үйлдлүүд</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="odd"
                  v-for="(schoolclass, index) in filteredSchoolClasses"
                  :key="schoolclass.id"
                >
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ ++index }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.class_number }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.class_level }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <b-badge variant="success">
                      {{ schoolclass.full_load }}
                    </b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <b-badge variant="primary">
                      <span v-if="userInfo.role == 'agerman'">
                        {{ schoolclass.full_name }}
                      </span>
                      <span v-else-if="userInfo.role == 'agermankg'">
                        {{ schoolclass.class_cabinet }}
                      </span>
                      <span v-else>
                        {{ schoolclass.full_name }} <br />
                        {{ schoolclass.class_cabinet }}
                      </span>
                    </b-badge>
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    v-if="schoolclass.teacher"
                  >
                    {{ schoolclass.teacher["teacher_last_name"][0] }}.{{
                      schoolclass.teacher["teacher_name"]
                    }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0" v-else></td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.class_room }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.class_cabinet }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.created_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolclass.updated_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolClasses(schoolclass)"
                      ><i class="fas fa-edit"></i
                    ></a>

                    <!-- <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolClasses(schoolclass)"
                      ><i class="fas fa-trash-alt"></i
                    ></a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-sm-4 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Анги бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Анги засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="!editMode ? createSchoolClasses() : updateSchoolClasses()">
          <div class="card-body">
            <div class="form-group">
              <label>Анги</label>
              <input
                type="text"
                name="class_number"
                v-model="form.class_number"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('class_number'),
                  },
                ]"
                placeholder="Анги"
              />
              <has-error :form="form" field="class_number"></has-error>
            </div>
            <div class="form-group">
              <label>Бүлэг</label>
              <input
                type="text"
                name="class_level"
                v-model="form.class_level"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('class_level'),
                  },
                ]"
                placeholder="Бүлэг"
              />
              <has-error :form="form" field="class_level"></has-error>
            </div>
            <div class="form-group" v-if="userInfo.name == 'cipalprin'">
              <label>Бүлэг дүүргэлт</label>
              <input
                type="number"
                name="full_load"
                v-model="form.full_load"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('full_load'),
                  },
                ]"
                placeholder="Анги дүүргэлт оруулна уу?"
              />
              <has-error :form="form" field="full_load"></has-error>
            </div>
            <div class="form-group">
              <label>Ангийн №</label>
              <input
                type="text"
                name="class_room"
                v-model="form.class_room"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('class_room'),
                  },
                ]"
                placeholder="Ангийн дугаар оруулна уу?"
              />
              <has-error :form="form" field="class_room"></has-error>
            </div>
            <div class="form-group">
              <label>Кабинет</label>
              <input
                type="text"
                name="class_cabinet"
                v-model="form.class_cabinet"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('class_cabinet'),
                  },
                ]"
                placeholder="Кабинетын нэр"
              />
              <has-error :form="form" field="class_cabinet"></has-error>
            </div>
            <div class="form-group">
              <multiselect
                v-model="form.teacher_id"
                :options="schoolteachers"
                track-by="emp_user_id"
                label="emp_user_id"
                :custom-label="nameTeachers"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :show-labels="false"
                :allow-empty="true"
                placeholder="Анги удирдсан багш сонгох"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.emp_teacher_last_name[0]
                    }}{{ option.emp_teacher_last_name[1] }}.
                    {{ option.emp_teacher_name }}</strong
                  >
                  сонгогдлоо
                </template>
              </multiselect>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="!editMode">
              Хадгалах
            </button>
            <button type="submit" class="btn btn-primary" v-show="editMode">Засах</button>
            <button class="btn btn-warning" v-show="editMode" @click.prevent="cancelEdit">
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-show-log"
      class="modal fade"
      title="Өмнөх жилүүд"
      hide-footer
      @hide="cancelClassEdit"
    >
      <div class="modal-body">
        <multiselect
          v-model="yearId"
          deselect-label="Хасах"
          select-label="Сонгох"
          track-by="id"
          :custom-label="customYearName"
          placeholder="Хичээлийн жил сонгоно уу?"
          :options="years"
          :searchable="true"
          :allow-empty="true"
          @input="getClassYears"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong
              >{{ option.start_date | dateYear }}-{{ option.finish_date | dateYear }}
              - хичээлийн жил
            </strong></template
          >
        </multiselect>
        <div class="row">
          <div class="col-lg-8 mt-3">
            <div class="card p-2">
              <div class="card-header pr-0">
                <h3 class="card-title">Анги</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <b-overlay :show="showYearsLog" rounded="sm">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Анги</th>
                        <th>Багш</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(yearData, index) in classYears" :key="yearData.id">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          <b-button
                            variant="outline-primary"
                            size="xs"
                            @click="editYearClass(yearData)"
                          >
                            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            Засах</b-button
                          >
                          <b-button
                            size="xs"
                            @click="deleteYearClass(yearData)"
                            variant="outline-danger"
                          >
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            Устгах
                          </b-button>
                        </td>
                        <td>
                          {{ yearData.full_name }}
                          <b-avatar
                            :src="yearData.image"
                            variant="light"
                            icon="people-fill"
                            class="ml-2"
                            rounded="sm"
                          ></b-avatar>
                        </td>
                        <td>
                          {{ yearData.teacher }}
                        </td>
                        <td>
                          {{ yearData.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="classYears.length == 0">
                    <p class="text-center text-muted h5 mt-5">
                      <b-icon icon="trophy"></b-icon>
                    </p>
                    <p class="text-center text-muted font-italic text-sm">
                      Одоогоор мэдээлэл бүртгэгдээгүй байна.
                    </p>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-3">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editClassMode">
                  Ангийн мэдээлэл бүртгэх
                </h3>
                <h3 class="card-title" v-show="editClassMode">Ангийн мэдээлэл засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="!editClassMode ? createYearClass() : updateYearClass()"
              >
                <div class="card-body">
                  <div class="form-group" v-if="!editClassMode">
                    <b-select
                      v-model="formYear.class_id"
                      :options="schoolclasses"
                      text-field="full_name"
                      value-field="id"
                    ></b-select>
                  </div>
                  <div class="form-group" v-else>{{ formYear.full_name }} анги</div>
                  <div class="form-group">
                    <multiselect
                      v-model="formYear.teacher_id"
                      :options="schoolTeachersWorkOut"
                      track-by="emp_user_id"
                      label="emp_user_id"
                      :custom-label="nameTeachers"
                      :searchable="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :show-labels="false"
                      :allow-empty="true"
                      placeholder="Анги удирдсан багш сонгох"
                    >
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <b-avatar
                      :src="formYear.image"
                      variant="light"
                      icon="people-fill"
                      size="100%"
                      class="mr-1"
                      rounded="sm"
                    ></b-avatar>
                    <b-form-file
                      id=""
                      browse-text="Зураг солих"
                      size="sm"
                      @change="getClassImg"
                      accept="image/*"
                    ></b-form-file>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary" v-show="!editClassMode">
                    Хадгалах
                  </button>
                  <button type="submit" class="btn btn-primary" v-show="editClassMode">
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editClassMode"
                    @click.prevent="cancelClassEdit"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      schoolclasses: [],
      schoolteachers: [],
      schoolTeachersWorkOut: [],
      form: new Form({
        id: "",
        teacher_id: "",
        class_number: "",
        class_level: "",
        full_load: 26,
        class_room: "",
        class_cabinet: "",
      }),
      formYear: new Form({
        id: "",
        year_id: "",
        class_id: "",
        teacher_id: "",
        full_name: "",
        image: "",
      }),
      yearId: "",
      classYears: [],
      showYearsLog: true,
      editClassMode: false,
      searchTerm: "",
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    filteredSchoolClasses() {
      return this.schoolclasses.filter((schoolclass) => {
        return (
          schoolclass.class_number
            .toString()
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          schoolclass.class_level
            .toString()
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
          //     ||
          //   schoolclass.full_name
          //     .toString()
          //     .toLowerCase()
          //     .includes(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    doApi() {
      console.log(this.form.teacher_id);
    },
    getAllSchoolClassess() {
      axios
        .get("/manager/getAllSchoolClass")
        .then((response) => {
          //   console.log(response.data);
          this.schoolclasses = response.data.schoolclasses;
          this.schoolteachers = response.data.schoolteachers;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolClasses(schoolclasses) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolclasses);
    },
    editSchoolClasses(schoolclasses) {
      //   console.log(schoolclasses);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolclasses);
      //   this.form.teacher_id = schoolclasses.teacher;

      this.schoolteachers.filter((element) => {
        if (element.emp_user_id == schoolclasses.teacher_id) {
          this.form.teacher_id = element;
        }
      });
      //   console.log(this.form);
    },
    updateSchoolClasses() {
      // this.action = "Ангийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/schoolclass/" + this.form.id)
        .then((response) => {
          // console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadSchoolClasses");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolClasses(schoolclasses) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolclasses);
    },
    createSchoolClasses() {
      this.action = "Ангийг үүсгэж байна...";
      this.form
        .post("/manager/schoolclass")
        .then((response) => {
          this.$toastr.s("Ангийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolClasses");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Ангийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    nameTeachers({ emp_teacher_name, emp_teacher_last_name, emp_work_out }) {
      let empType = "";
      if (emp_work_out === 0) {
        empType = "Ажлаас чөлөөлөгдсөн";
      } else if (emp_work_out === 1) {
        empType = "Идэвхтэй";
      } else if (emp_work_out === 2) {
        empType = "Жирэмсний амралттай";
      }
      return `${emp_teacher_last_name}. ${emp_teacher_name} (${empType})`;
    },
    showLog() {
      this.$bvModal.show("modal-show-log");
      this.yearId = this.years[0];
      this.getClassYears();
    },
    getClassYears() {
      this.showYearsLog = true;
      axios
        .get("/manager/getClassYears/" + this.yearId.id)
        .then((response) => {
          //   console.log(response.data);
          this.schoolTeachersWorkOut = response.data.schoolTeachersWorkOut;
          this.classYears = response.data.classYears;
          this.showYearsLog = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    createYearClass() {
      this.action = "Ангийг үүсгэж байна...";
      this.formYear.year_id = this.yearId.id;
      if (this.formYear.teacher_id) {
        // this.formYear.teacher_id = this.formYear.teacher_id.emp_user_id;

        this.formYear
          .post("/manager/createYearClass")
          .then((response) => {
            // if (response.data.error) {
            //   //   this.formYear.teacher_id = null;
            //   this.$toastr.e(response.data.error);
            // } else {
            this.$toastr.s("Ангийг амжилттай үүсгэлээ.", "Амжилттай");
            this.getClassYears();
            this.formYear.reset();
            // }
          })
          .catch(() => {
            // this.formYear.teacher_id = null;
            this.$toastr.e("Багш өөр анги дээр бүртгэлтэй байна!");
          });
      }
    },
    editYearClass(yearClass) {
      this.editClassMode = true;
      this.formYear.reset();
      this.formYear.fill(yearClass);
      this.schoolteachers.filter((element) => {
        if (element.emp_user_id == yearClass.teacher_id) {
          this.formYear.teacher_id = element;
        }
      });
    },
    getClassImg(e) {
      let img = e.target.files[0];
      this.readClassImg(img);
    },
    readClassImg(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.formYear.image = e.target.result;
      };
      this.loaded = true;
    },
    updateYearClass() {
      this.formYear.teacher_id = this.formYear.teacher_id.emp_user_id;
      this.formYear
        .put("/manager/updateYearClass/" + this.formYear.id)
        .then((response) => {
          if (response.data.error) this.$toastr.e(response.data.error);
          else {
            this.$toastr.s(response.data, "Шинэчилсэн");
            this.formYear.reset();
            this.editClassMode = !this.editClassMode;
            this.getClassYears();
          }
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelClassEdit() {
      this.formYear.reset();
      this.editClassMode = false;
    },
    deleteYearClass(yearClass) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Ангийн мэдээлэл устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manager/deleteYearClass/" + yearClass.id)
            .then((response) => {
              this.$toastr.s("Ангийн мэдээлэл амжилттай устгалаа!", "Шинэчилсэн");
              this.getClassYears();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
  created() {
    this.getAllSchoolClassess();
    Fire.$on("loadSchoolClasses", () => {
      this.getAllSchoolClassess();
    });
  },
};
</script>

<style></style>
