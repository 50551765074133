<template>
  <div>
    <div class="col-lg-12 col-12">
      <cultural class="pt-3"></cultural>
    </div>
    <stat-stud class="pt-3"></stat-stud>
    <div class="col-lg-12 col-12 pt-3">
      <div class="small-box bg-info" style="align: center">
        <div class="inner">
          <h3>{{ totalRows }}/{{ allLoginUserInfos.length }}</h3>

          <p>Систем ашиглалтанд орсон хугацаанд</p>
        </div>
        <div class="icon">
          <i class="ion ion-person-add"></i>
        </div>
        <a href="#" class="small-box-footer"
          >Дэлгэрэнгүй <i class="fas fa-arrow-circle-right"></i
        ></a>
      </div>
    </div>

    <div class="col-lg-12 col-12 pt-3">
      <b-overlay :show="loaded" rounded="sm">
        <b-row>
          <b-col lg="12" class="my-1">
            <b-form-group
              label="Нэг хуудсанд"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label="Хайх утга!"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Хайлт хийх утгаа оруулна уу!"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Цэвэрлэх</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        <b-table
          hover
          :items="allLoginUserInfos"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-ignored-fields="filterIgnoredFields"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
        >
          <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(role_description)="data">
            <div class="row callout callout-warning">
              {{ data.item.role_description }}
            </div>
          </template>
          <template #cell(log_act_description)="data">
            <div class="row callout callout-warning">
              {{ data.item.log_act_description }}
            </div>
          </template>
          <template #cell(more_description)="data">
            <div class="row callout callout-warning">
              <div class="col-md-12" v-if="data.item.parent_profile_user">
                <span style="font-size: 80%">
                  <b>Аав:</b>
                  <b-badge variant="info">
                    <span
                      v-if="data.item.parent_profile_user.parent_father_ovog"
                    >
                      {{ data.item.parent_profile_user.parent_father_ovog[0] }}
                    </span>
                    .
                    {{ data.item.parent_profile_user.parent_father_name }}
                  </b-badge>
                  <b> Утас: </b>
                  {{ data.item.parent_profile_user.parent_father_phone_number }}
                  <br />
                  <b>Ээж:</b>
                  <b-badge variant="info">
                    <span
                      v-if="data.item.parent_profile_user.parent_mother_ovog"
                      >{{ data.item.parent_profile_user.parent_mother_ovog[0] }}
                    </span>
                    .
                    {{ data.item.parent_profile_user.parent_mother_name }}
                  </b-badge>
                  <b> Утас: </b>
                  {{ data.item.parent_profile_user.parent_mother_phone_number }}
                </span>
                <br />
                <p
                  style="font-size: 80%"
                  v-if="
                    data.item.parent_profile_user &&
                    data.item.parent_profile_user.get_my_primary_child
                  "
                >
                  <b>Хүүхдүүд:</b>
                  <span
                    v-for="(child, index) in data.item.parent_profile_user
                      .get_my_primary_child"
                    :key="index"
                  >
                    <b-badge variant="info">
                      <span v-if="child.student_last_name">{{
                        child.student_last_name[0]
                      }}</span>
                      .
                      {{ child.student_name }}
                      <span
                        v-if="
                          child.student_migration_info &&
                          child.student_migration_info.my_school_class_info
                        "
                        >({{
                          child.student_migration_info.my_school_class_info
                            .full_name
                        }})</span
                      >
                      <span v-else variant="varning">
                        Анги тодорхойгүй байна.</span
                      >
                    </b-badge>
                  </span>
                </p>
                <p v-else>Хүүхдүүдийн мэдээлэл тодорхойгүй байна.</p>
              </div>
              <div class="col-md-12" v-if="data.item.profile">
                <p style="font-size: 80%" v-if="data.item.profile">
                  <b>Суралцагч:</b>
                  <b-badge variant="info">
                    <span v-if="data.item.profile.student_last_name">
                      {{ data.item.profile.student_last_name[0] }}
                    </span>
                    .
                    {{ data.item.profile.student_name }}({{
                      data.item.profile.student_migration_info
                        .my_school_class_info.full_name
                    }})
                  </b-badge>
                  <br />
                  <b>Утас:</b>
                  <span>
                    {{ data.item.profile.student_phone_number }}
                  </span>
                </p>
              </div>
              <div class="col-md-12" v-if="data.item.employee_user">
                <p style="font-size: 80%" v-if="data.item.employee_user">
                  <b>Багш, ажилтан:</b>
                  <b-badge variant="info">
                    <span v-if="data.item.employee_user.teacher_last_name">
                      {{ data.item.employee_user.teacher_last_name[0] }}
                    </span>
                    .{{ data.item.employee_user.teacher_name }}
                  </b-badge>
                  <br />
                  <b>Утас:</b>
                  <span>
                    {{
                      data.item.employee_user.employee_migration_info
                        .teacher_phoneNumber1
                    }}
                  </span>
                </p>
              </div>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="row callout callout-warning">
              {{ data.item.created_at | dateYearMonthDayTime }}
            </div>
          </template>
        </b-table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
// import { mapState, mapGetters } from "vuex";
import cultural from "../Project/CulturalCalendar.vue";
import statStud from "../Manager/Statistic/StatisticStudents.vue";
export default {
  data() {
    return {
      allLoginUserInfos: [],
      allLoginUserInfosCount: "",
      managers: [],
      managersCount: "",
      //   managersVaa: [],
      teachers: [],
      teachersCount: "",
      students: [],
      studentsCount: "",
      parents: [],
      parentsCount: "",

      loaded: true,
      // Хүснэгтийн тохиргоо
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFields: ["parent_profile_user", "profile", "employee_user"],
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "role_description",
          label: "Дүр",
          sortable: true,
        },
        {
          key: "log_act_description",
          label: "Нэвтэрсэн мэдээлэл",
          sortable: true,
        },
        {
          key: "more_description",
          label: "Нэмэлт мэдээлэл",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
      ],
    };
  },
  components: { cultural, statStud },
  //1st method
  //   computed: {
  //     userName() {
  //       return this.$store.state.user.name;
  //     },
  //     userID() {
  //       return this.$store.state.user.id;
  //     },
  //   },
  //2nd method
  //   computed: mapState({
  //     userName: (state) => state.user.name, // товчоор бичиж болно. user: 'user'
  //     userID: (state) => state.user.id, // товчоор бичиж болно. user: 'user'
  //     categories: (state) => state.categories, // товчоор бичиж болно. categories: 'categories'
  //     count: (state) => state.count, // товчоор бичиж болно. count: 'count'
  //   }),
  //3rd method
  computed: {
    // getEvent() {
    //   return this.$store.getters.getEventById;
    // },
    // catLength() {
    //   return this.$store.getters.catLength;
    // },
    // ...mapGetters(["getEventById"]),
    // ...mapState(["user", "categories"]),
  },
  methods: {
    //b-table тохирго
    onFiltered(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // getCurrentUser() {
    //   return this.$store.getters.getCurrentUser();
    // },
    getMyAllUserLoginInFo() {
      axios
        .get("/principal/getMyAllUserLoginInFo")
        .then((res) => {
          this.allLoginUserInfos = res.data.allLoginUserInfos;
          this.totalRows = this.allLoginUserInfos.length;
          this.allLoginUserInfosCount = res.data.allLoginUserInfosCount;
          //   this.managers = res.data.managers;
          //   this.managersCount = res.data.managersCount;
          //   this.teachers = res.data.teachers;
          //   this.teachersCount = res.data.teachersCount;
          //   this.students = res.data.students;
          //   this.studentsCount = res.data.studentsCount;
          //   this.parents = res.data.parents;
          //   this.parentsCount = res.data.parentsCount;

          this.loaded = false;
        })
        .catch((err) => err);
    },
    getNow() {
      const today = new Date();
      //   console.log(today.getMonth() + 1);
      let checkMonth = today.getMonth() + 1;
      checkMonth =
        checkMonth < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
      //   console.log(checkMonth);
      const date =
        today.getFullYear() + "-" + checkMonth + "-" + today.getDate();
      //   const time =
      //     today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      //   const dateTime = date + " " + time;
      //   this.timestamp = dateTime;
      return date;
    },
  },

  created() {
    // console.log(this.$store);
    // console.log(this.getNow());
    this.getMyAllUserLoginInFo();
    // this.filter = this.getNow();
  },
};
</script>

<style scoped></style>
