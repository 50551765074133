<template>
  <div class="card">
    <div class="card-body">
      <h5><b>Судлагдахууны дундаж үнэлгээ</b></h5>
      <b-row>
        <b-col sm="6">
          <multiselect
            v-model="selectedLesson"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            placeholder="Хичээл сонгоно уу?"
            track-by="id"
            label="subject_name"
            :options="lessonList"
            :searchable="false"
            :allow-empty="false"
            @input="getStat18Lesson"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.subject_name }} </strong></template
            >
          </multiselect>
        </b-col>
        <b-col sm="6">
          <multiselect
            v-model="selectedExamType"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            placeholder="Шалгалтын төрөл сонгоно уу?"
            track-by="exam_type"
            label="exam_type"
            :options="examType"
            :searchable="false"
            :allow-empty="false"
            @input="getStat18Lesson"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.exam_type }} </strong></template
            >
          </multiselect>
        </b-col>
      </b-row>
      <b-overlay rounded="sm">
        <table class="table table-bordered text-center" v-show="selectedLesson">
          <thead>
            <tr role="row">
              <th rowspan="3">№</th>
              <th rowspan="3">Анги</th>
              <th colspan="8">Оюуны үйлийн чадвар, ур ухаан(%)</th>
              <th rowspan="3">Анги бүлгийн дундаж үнэлгээ(%)</th>
            </tr>
            <tr>
              <th colspan="2">Сэдэл, сэтгэл хөдлөлөө хянах</th>
              <th colspan="2">Оюуны үйлийн явцыг хянах</th>
              <th colspan="4">Танин мэдэх</th>
            </tr>
            <tr>
              <th>1</th>
              <th>8</th>
              <th>2</th>
              <th>7</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(classExam, index) in subjClassExams"
              :key="classExam.id"
            >
              <td class="dtr-control sorting_1" tabindex="0">
                {{ index + 1 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.full_name }} анги
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam1 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam8 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam2 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam7 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam3 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam4 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam5 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ classExam.exam6 }}
              </td>
              <th>
                {{
                  (
                    classExam.exam1 * 0.1 +
                    classExam.exam8 * 0.1 +
                    classExam.exam2 * 0.1 +
                    classExam.exam7 * 0.1 +
                    classExam.exam3 * 0.1 +
                    classExam.exam4 * 0.1 +
                    classExam.exam5 * 0.2 +
                    classExam.exam6 * 0.2
                  ).toFixed(2)
                }}
              </th>
            </tr>
            <tr>
              <th colspan="2">Хичээлийн дундаж үнэлгээ</th>
              <th>{{ examClassAvg[0] }}</th>
              <th>{{ examClassAvg[7] }}</th>
              <th>{{ examClassAvg[1] }}</th>
              <th>{{ examClassAvg[6] }}</th>
              <th>{{ examClassAvg[2] }}</th>
              <th>{{ examClassAvg[3] }}</th>
              <th>{{ examClassAvg[4] }}</th>
              <th>{{ examClassAvg[5] }}</th>
              <th>
                {{
                  (
                    examClassAvg[0] * 0.1 +
                    examClassAvg[7] * 0.1 +
                    examClassAvg[1] * 0.1 +
                    examClassAvg[6] * 0.1 +
                    examClassAvg[2] * 0.1 +
                    examClassAvg[3] * 0.1 +
                    examClassAvg[4] * 0.2 +
                    examClassAvg[5] * 0.2
                  ).toFixed(2)
                }}
              </th>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
    <div class="card-body" v-show="selectedLesson != null">
      <h5><b>Шалгалтын төрлөөр сонгож харах</b></h5>
      <multiselect
        v-model="selectedClass"
        deselect-label="Хасах"
        select-label="Сонгох"
        track-by="course_id"
        label="full_name"
        placeholder="Анги сонгох"
        :options="subjClassExams"
        :searchable="true"
        :allow-empty="true"
        @input="getStat18Class"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.full_name }}
        </template>
      </multiselect>
      <div class="table-responsive">
        <b-overlay rounded="sm">
          <table
            class="table table-bordered text-center"
            v-show="selectedClass"
          >
            <thead>
              <tr role="row">
                <th rowspan="3">№</th>
                <th rowspan="3">Суралцагчдын нэрс</th>
                <th colspan="8">Оюуны үйлийн чадвар, ур ухаан(%)</th>
                <th rowspan="3">Суралцагчдын дундаж үнэлгээ(%)</th>
              </tr>
              <tr>
                <th colspan="2">Сэдэл, сэтгэл хөдлөлөө хянах</th>
                <th colspan="2">Оюуны үйлийн явцыг хянах</th>
                <th colspan="4">Танин мэдэх</th>
              </tr>
              <tr>
                <th>1</th>
                <th>8</th>
                <th>2</th>
                <th>7</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(studExam, index) in subjStudExams" :key="studExam.id">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.student_last_name }}.{{ studExam.student_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam8 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam2 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam7 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam3 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam4 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam5 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExam.exam6 }}
                </td>
                <th>
                  {{ getStudAvg(studExam) }}
                </th>
              </tr>
              <tr>
                <th colspan="2">Ангийн дундаж үнэлгээ</th>
                <th>{{ examStudAvg[0] }}</th>
                <th>{{ examStudAvg[7] }}</th>
                <th>{{ examStudAvg[1] }}</th>
                <th>{{ examStudAvg[6] }}</th>
                <th>{{ examStudAvg[2] }}</th>
                <th>{{ examStudAvg[3] }}</th>
                <th>{{ examStudAvg[4] }}</th>
                <th>{{ examStudAvg[5] }}</th>
                <th>
                  {{ getAvg(examStudAvg) }}
                </th>
              </tr>
            </tbody>
          </table>
        </b-overlay>
      </div>
    </div>
    <div class="card-body" v-show="selectedClass != null">
      <h5>
        <b
          >Сургалтын үр дүнд үнэлгээ шинжилгээ хийх аргачлал (суралцагчдын
          үнэлгээ)</b
        >
      </h5>
      <div class="table-responsive">
        <div class="row">
          <div class="col-12 col-sm-5">
            <b-overlay rounded="sm">
              <table
                class="table table-bordered text-center"
                v-show="selectedClass"
              >
                <thead>
                  <tr role="row">
                    <th>Суралцагчдын нэрс</th>
                    <th>Суралцагчдын дундаж үнэлгээ</th>
                    <th>Ангийн дундаж үнэлгээ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="studExam in subjStudExams" :key="studExam.id">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ studExam.student_last_name }}.{{
                        studExam.student_name
                      }}
                    </td>
                    <th>
                      {{ getStudAvg(studExam) }}
                    </th>
                    <th>
                      {{ getAvg(examStudAvg) }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
          <div class="col-12 col-sm-7">
            <span v-if="chartClass === true">
              <div v-if="subjStudExams.length > 0">
                <apexchart
                  type="radar"
                  height="450"
                  :options="chartClassOptions"
                  :series="seriesClass"
                >
                </apexchart>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-show="selectedClass != null">
      <multiselect
        v-model="selectedStud"
        deselect-label="Хасах"
        select-label="Сонгох"
        track-by="user_id"
        label="student_name"
        placeholder="Суралцагч сонгох"
        :options="studOptions"
        :searchable="true"
        :allow-empty="true"
        @input="getStat18Stud"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.student_name }}
        </template>
      </multiselect>
      <div class="table-responsive" v-show="selectedStud">
        <b-overlay rounded="sm">
          <table class="table table-bordered text-center">
            <thead>
              <tr role="row">
                <th rowspan="3">Суралцагчийн нэр</th>
                <th colspan="8">Оюуны үйлийн чадвар, ур ухаан(%)</th>
                <th rowspan="3">Суралцагчийн дундаж үнэлгээ(%)</th>
              </tr>
              <tr>
                <th colspan="2">Сэдэл, сэтгэл хөдлөлөө хянах</th>
                <th colspan="2">Оюуны үйлийн явцыг хянах</th>
                <th colspan="4">Танин мэдэх</th>
              </tr>
              <tr>
                <th>1</th>
                <th>8</th>
                <th>2</th>
                <th>7</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="studExams">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.student_last_name }}.{{ studExams.student_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam8 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam2 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam7 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam3 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam4 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam5 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ studExams.exam6 }}
                </td>
                <th>
                  {{ getStudAvg(studExams) }}
                </th>
              </tr>
              <tr>
                <th>Ангийн дундаж үнэлгээ</th>
                <th>{{ examStudAvg[0] }}</th>
                <th>{{ examStudAvg[7] }}</th>
                <th>{{ examStudAvg[1] }}</th>
                <th>{{ examStudAvg[6] }}</th>
                <th>{{ examStudAvg[2] }}</th>
                <th>{{ examStudAvg[3] }}</th>
                <th>{{ examStudAvg[4] }}</th>
                <th>{{ examStudAvg[5] }}</th>
                <th>
                  {{ getAvg(examStudAvg) }}
                </th>
              </tr>
            </tbody>
          </table>
        </b-overlay>
        <span v-if="chartStud === true">
          <div v-if="studExams">
            <apexchart
              type="radar"
              height="350"
              :options="chartStudOptions"
              :series="seriesStud"
            >
            </apexchart>
          </div>
          <div v-if="seriesHWLesson[0].data">
            <apexchart
              type="line"
              height="350"
              :options="chartHWLessonOptions"
              :series="seriesHWLesson"
            >
            </apexchart>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["teacherId"],
  data() {
    return {
      lessonList: [],
      examType: [],
      classOptions: [],
      studOptions: [],
      subjClassExams: [],
      subjStudExams: [],
      studExams: null,
      selectedLesson: null,
      selectedExamType: null,
      selectedClass: null,
      selectedStud: null,
      chartClass: false,
      chartStud: false,
      seriesClass: [
        {
          name: "Суралцагчдын ерөнхий үнэлгээ",
          data: [],
        },
        {
          name: "Ангийн дундаж үнэлгээ",
          data: [],
        },
      ],
      chartClassOptions: {
        chart: {
          height: 450,
          type: "radar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesStud: [
        {
          name: "Суралцагчийн ерөнхий үнэлгээ",
          data: [],
        },
        {
          name: "Ангийн дундаж үнэлгээ",
          data: [],
        },
      ],
      chartStudOptions: {
        chart: {
          height: 350,
          type: "radar",
        },
        xaxis: {
          categories: ["1", "2", "3", "4", "5", "6", "7", "8"],
        },
      },
      seriesHWLesson: [
        {
          name: "Суралцагчийн үнэлгээ",
          type: "column",
          data: [],
        },
        {
          name: "Суралцагчийн дундаж үнэлгээ",
          type: "line",
          data: [],
        },
      ],
      chartHWLessonOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    examClassAvg() {
      let examAvg = [];
      const exam1Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam1),
          0
        ) / this.subjClassExams.length;
      const exam2Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam2),
          0
        ) / this.subjClassExams.length;
      const exam3Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam3),
          0
        ) / this.subjClassExams.length;
      const exam4Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam4),
          0
        ) / this.subjClassExams.length;
      const exam5Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam5),
          0
        ) / this.subjClassExams.length;
      const exam6Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam6),
          0
        ) / this.subjClassExams.length;
      const exam7Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam7),
          0
        ) / this.subjClassExams.length;
      const exam8Avg =
        this.subjClassExams.reduce(
          (total, item) => total + parseInt(item.exam8),
          0
        ) / this.subjClassExams.length;
      examAvg.push(
        exam1Avg.toFixed(2),
        exam2Avg.toFixed(2),
        exam3Avg.toFixed(2),
        exam4Avg.toFixed(2),
        exam5Avg.toFixed(2),
        exam6Avg.toFixed(2),
        exam7Avg.toFixed(2),
        exam8Avg.toFixed(2)
      );
      return examAvg;
    },
    examStudAvg() {
      let examAvg = [];
      const exam1Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam1),
          0
        ) / this.subjStudExams.length;
      const exam2Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam2),
          0
        ) / this.subjStudExams.length;
      const exam3Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam3),
          0
        ) / this.subjStudExams.length;
      const exam4Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam4),
          0
        ) / this.subjStudExams.length;
      const exam5Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam5),
          0
        ) / this.subjStudExams.length;
      const exam6Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam6),
          0
        ) / this.subjStudExams.length;
      const exam7Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam7),
          0
        ) / this.subjStudExams.length;
      const exam8Avg =
        this.subjStudExams.reduce(
          (total, item) => total + parseInt(item.exam8),
          0
        ) / this.subjStudExams.length;
      examAvg.push(
        exam1Avg.toFixed(2),
        exam2Avg.toFixed(2),
        exam3Avg.toFixed(2),
        exam4Avg.toFixed(2),
        exam5Avg.toFixed(2),
        exam6Avg.toFixed(2),
        exam7Avg.toFixed(2),
        exam8Avg.toFixed(2)
      );
      return examAvg;
    },
    selectedStudExam() {
      let exam = [];
      exam.push(
        this.studExams.exam1,
        this.studExams.exam2,
        this.studExams.exam3,
        this.studExams.exam4,
        this.studExams.exam5,
        this.studExams.exam6,
        this.studExams.exam7,
        this.studExams.exam8
      );
      return exam;
    },
  },
  methods: {
    getAvg(examStudAvg) {
      return (
        examStudAvg[0] * 0.1 +
        examStudAvg[7] * 0.1 +
        examStudAvg[1] * 0.1 +
        examStudAvg[6] * 0.1 +
        examStudAvg[2] * 0.1 +
        examStudAvg[3] * 0.1 +
        examStudAvg[4] * 0.2 +
        examStudAvg[5] * 0.2
      ).toFixed(2);
    },
    getStudAvg(studExam) {
      return (
        studExam.exam1 * 0.1 +
        studExam.exam8 * 0.1 +
        studExam.exam2 * 0.1 +
        studExam.exam7 * 0.1 +
        studExam.exam3 * 0.1 +
        studExam.exam4 * 0.1 +
        studExam.exam5 * 0.2 +
        studExam.exam6 * 0.2
      ).toFixed(2);
    },
    getMyCourses() {
      axios
        .post("/teacher/getMyCourses", {
          teacherId: this.teacherId,
        })
        .then((response) => {
          this.examType = response.data.examType;
          this.lessonList = response.data.subjects;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getStat18Lesson() {
      axios
        .post("/teacher/getStat18Lesson", {
          subjId: this.selectedLesson.id,
          examType: this.selectedExamType,
          allClass: this.classOptions,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.subjClassExams = res.data.allSubjClassExams;
          if (this.selectedClass) this.getStat18Class();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStat18Class() {
      this.seriesClass[0].data = [];
      this.seriesClass[1].data = [];
      this.chartClassOptions.xaxis.categories = [];
      this.chartClass = false;
      axios
        .post("/teacher/getStat18Class", {
          selectedClassId: this.selectedClass.school_class_id,
          examType: this.selectedExamType,
          courseId: this.selectedClass.course_id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.subjStudExams = res.data.allSubjStudExams;
          this.studOptions = res.data.studentIds;
          res.data.allSubjStudExams.forEach((el) => {
            this.chartClassOptions.xaxis.categories.push(el.student_name);
            this.seriesClass[0].data.push(this.getStudAvg(el));
            this.seriesClass[1].data.push(this.getAvg(this.examStudAvg));
          });
          this.chartClass = true;
          if (this.selectedStud) this.getStat18Stud();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStat18Stud() {
      this.seriesStud[0].data = [];
      this.seriesStud[1].data = [];
      this.seriesHWLesson[0].data = [];
      this.seriesHWLesson[1].data = [];
      this.chartHWLessonOptions.xaxis.categories = [];
      this.chartStud = false;
      axios
        .post("/teacher/getStat18Stud", {
          selectedClassId: this.selectedClass.school_class_id,
          examType: this.selectedExamType,
          courseId: this.selectedClass.course_id,
          selectedStudId: this.selectedStud.user_id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.studExams = res.data.studExam;
          res.data.studHW.forEach((el) => {
            this.chartHWLessonOptions.xaxis.categories.push(
              el.formatted_created_at
            );
            this.seriesHWLesson[0].data.push(el.hw_grade);
            this.seriesHWLesson[1].data.push(res.data.hwAvg.avg);
          });
          this.seriesStud[0].data = this.selectedStudExam;
          this.seriesStud[1].data = this.examStudAvg;
          this.chartStud = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getMyCourses();
  },
};
</script>

<style scoped>
.card-body {
  min-height: 250px;
}
</style>
