<template>
  <div class="card card-success card-outline card-outline-tabs mt-3">
    <div class="card-header">
      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="calendar-tab"
            data-toggle="pill"
            href="#calendar"
            role="tab"
            aria-controls="calendar"
            aria-selected="true"
            >Соёл хүмүүжлийн ажил</a
          >
        </li>
        <li class="nav-item">
          <a
            @click="getAllCourseCommentForMyChild()"
            class="nav-link"
            id="homework-tab"
            data-toggle="pill"
            href="#homework"
            role="tab"
            aria-controls="homework"
            aria-selected="false"
            >Гэрийн даалгавар</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="courseComment-tab"
            data-toggle="pill"
            href="#courseComment"
            role="tab"
            aria-controls="courseComment"
            aria-selected="false"
            >Хичээлийн ажиглалт, тэмдэглэл</a
          >
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="calendar"
          role="tabpanel"
          aria-labelledby="calendar-tab"
        >
          <cultural></cultural>
        </div>
        <div
          class="tab-pane fade show"
          id="homework"
          role="tabpanel"
          aria-labelledby="homework-tab"
        >
          <div class="card card-dark card-outline card-outline-tabs">
            <div class="card-header p-0 pt-1">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-undone-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-undone-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-undone-homework"
                    aria-selected="true"
                    >Хүлээгдэж буй
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-done-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-done-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-done-homework"
                    aria-selected="false"
                    >Гүйцэтгэсэн</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-noted-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-noted-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-noted-homework"
                    aria-selected="false"
                    >Эцэг эх, хүүхэд тэмдэглэл оруулсан.</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="noted-homework-tab"
                    data-toggle="pill"
                    href="#noted-homework"
                    role="tab"
                    aria-controls="noted-homework"
                    aria-selected="false"
                    >Гэрийн даалгаврын статистик</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-one-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-one-undone-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-undone-homework-tab"
                >
                  <div class="card card-default">
                    <div class="col-md-12">
                      <b-container class="bv-example-row" fluid>
                        <b-row>
                          <label>Улирал сонгох:</label>
                          <b-col sm="2"
                            ><b-form-select
                              name="selectedQuarter"
                              v-model="selectedQuarter"
                              :options="quarterOptions"
                              text-field="name"
                              value-field="id"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid':
                                    form.errors.has('selectedQuarter'),
                                },
                              ]"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-select>
                          </b-col>
                          <label>Даалгавар эхлэх огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwStartDate"
                              class="mb-2"
                              :class="['form-control']"
                              placeholder="Даалгавар эхлэх огноо"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-datepicker>
                          </b-col>
                          <label>Даалгавар хаагдах огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwEndDate"
                              class="mb-2"
                              :class="['form-control']"
                              @input="getAllCourseCommentForMyChild"
                              placeholder="Даалгавар хаагдах огноо"
                            ></b-form-datepicker>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-atlas"></i>
                        Хүлээгдэж буй гэрийн даалгавар:
                        {{ myChildCourseHomeworkunDone.length }}
                        байна.
                      </h3>
                      <h3 class="card-title float-right">
                        Нийт даалгаврын гүйцэтгэх хугацаа:
                        <b-badge
                          class="text-warning mr-1"
                          v-for="spend in sumSpendHW"
                          :key="spend.name"
                        >
                          {{ spend.name }}:{{
                            spend.totalUndoneTime
                          }}
                          минут</b-badge
                        >
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageUndoneHW"
                                :options="pageOptionsUnDoneHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterUnDoneHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterUnDoneHW"
                                    @click="filterUnDoneHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageUndoneHW"
                          :total-rows="totalRowsUndoneHW"
                          :per-page="perPageUndoneHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myChildCourseHomeworkunDone"
                          :fields="fieldsUndoneHW"
                          :current-page="currentPageUndoneHW"
                          :per-page="perPageUndoneHW"
                          :filter="filterUnDoneHW"
                          :filter-ignored-fields="filterIgnoredFieldsUnDoneHW"
                          @filtered="onFilteredUndoneHW"
                          :filter-included-fields="filterOnUnDoneHW"
                        >
                          <!-- :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-warning">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 0
                                    "
                                    style="float: right"
                                    variant="warning"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                          v-show="unReadComment.imReadParent == 1"
                          style="float: right"
                          icon="heart-fill"
                          variant="success"
                          @click.prevent="imReadParentConfirm(unReadComment)"
                        ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-one-done-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-done-homework-tab"
                >
                  <div class="card card-default">
                    <div class="col-md-12">
                      <b-container class="bv-example-row" fluid>
                        <b-row
                          ><label>Улирал сонгох:</label>
                          <b-col sm="2"
                            ><b-form-select
                              name="selectedQuarter"
                              v-model="selectedQuarter"
                              :options="quarterOptions"
                              text-field="name"
                              value-field="id"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid':
                                    form.errors.has('selectedQuarter'),
                                },
                              ]"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-select>
                          </b-col>
                          <label>Даалгавар эхлэх огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwStartDate"
                              class="mb-2"
                              :class="['form-control']"
                              placeholder="Даалгавар эхлэх огноо"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-datepicker>
                          </b-col>
                          <label>Даалгавар хаагдах огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwEndDate"
                              class="mb-2"
                              :class="['form-control']"
                              @input="getAllCourseCommentForMyChild"
                              placeholder="Даалгавар хаагдах огноо"
                            ></b-form-datepicker>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-bullhorn"></i>
                        Багш гэрийн даалгаварыг үнэлсэн:
                        {{ myChildCourseHomeworkDone.length }}
                        байна.
                      </h3>
                      <h3 class="card-title float-right">
                        Нийт даалгаврын гүйцэтгэсэн хугацаа:
                        <b-badge
                          class="text-success mr-1"
                          v-for="spend in sumSpendHWDone"
                          :key="spend.name"
                        >
                          {{ spend.name }}:{{ spend.totalDoneTime }} минут
                        </b-badge>
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageDoneHW"
                                :options="pageOptionsDoneHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterDoneHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterDoneHW"
                                    @click="filterDoneHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageDoneHW"
                          :total-rows="totalRowsDoneHW"
                          :per-page="perPageDoneHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myChildCourseHomeworkDone"
                          :fields="fieldsDoneHW"
                          :current-page="currentPageDoneHW"
                          :per-page="perPageDoneHW"
                          :filter="filterDoneHW"
                          :filter-ignored-fields="filterIgnoredFieldsDoneHW"
                          @filtered="onFilteredDoneHW"
                          :filter-included-fields="filterOnDoneHW"
                        >
                          <!-- :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-success">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 1
                                    "
                                    style="float: right"
                                    variant="success"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                          v-show="unReadComment.imReadParent == 1"
                          style="float: right"
                          icon="heart-fill"
                          variant="success"
                          @click.prevent="imReadParentConfirm(unReadComment)"
                        ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                      <!-- <div
                class="callout callout-success"
                v-for="(unDoneHw, i) in myChildCourseHomeworkDone"
                :key="i"
              >
                <div class="row">
                  <div class="col-md-10">
                    <span style="font-size: 80%">
                      <b> Бүртгэсэн: </b>
                      {{ unDoneHw.created_at | dateYearMonthDay }}
                    </span>
                    <br />
                    <p style="font-size: 80%">
                      <b>Суралцагч:</b>
                      {{
                        unDoneHw.student_last_name
                          | capitalizeAndEkhniiVseg
                      }}.
                      {{ unDoneHw.student_name }}
                      <br />
                      <b>Эхлэх:</b>
                      {{ unDoneHw.course_hw_start_date | dateYearMonthDay }}
                      {{ unDoneHw.course_hw_start_time }}
                      <br />
                      <b>Дуусах:</b>
                      {{ unDoneHw.course_hw_end_date | dateYearMonthDay }}
                      {{ unDoneHw.course_hw_end_time }}
                      <br />
                      <b>Багш:</b>
                      {{
                        unDoneHw.employee_teacher_last_name
                          | capitalizeAndEkhniiVseg
                      }}.
                      {{ unDoneHw.employee_teacher_name }}
                      <br />
                      <b> Цаг: </b>
                      {{ unDoneHw.course_hw_course_hour }}-р цаг
                      <br />
                      <b>Хичээл:</b>
                      {{ unDoneHw.subject_name }}
                      <br />
                      <b>Үнэлгээ:</b>
                      {{ unDoneHw.course_hw_stud_hw_grade }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <span style="font-size: 80%">
                      <b-iconstack
                        font-scale="2"
                        v-show="unDoneHw.course_hw_stud_hw_status == 1"
                        style="float: right"
                        variant="success"
                        @click.prevent="viewMoreInfo(unDoneHw)"
                      >
                        <b-icon stacked icon="square"></b-icon>
                        <b-icon
                          stacked
                          icon="bell-fill"
                          scale="0.75"
                        ></b-icon>
                      </b-iconstack>
                      <b-icon
                          v-show="unReadComment.imReadParent == 1"
                          style="float: right"
                          icon="heart-fill"
                          variant="success"
                          @click.prevent="imReadParentConfirm(unReadComment)"
                        ></b-icon>
                    </span>
                  </div>
                </div>
              </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-one-noted-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-noted-homework-tab"
                >
                  <div class="card card-default">
                    <div class="col-md-12">
                      <b-container class="bv-example-row" fluid>
                        <b-row
                          ><label>Улирал сонгох:</label>
                          <b-col sm="2"
                            ><b-form-select
                              name="selectedQuarter"
                              v-model="selectedQuarter"
                              :options="quarterOptions"
                              text-field="name"
                              value-field="id"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid':
                                    form.errors.has('selectedQuarter'),
                                },
                              ]"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-select>
                          </b-col>
                          <label>Даалгавар эхлэх огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwStartDate"
                              class="mb-2"
                              :class="['form-control']"
                              placeholder="Даалгавар эхлэх огноо"
                              @input="getAllCourseCommentForMyChild"
                            ></b-form-datepicker>
                          </b-col>
                          <label>Даалгавар хаагдах огноо</label>
                          <b-col sm="3">
                            <b-form-datepicker
                              size="sm"
                              v-model="hwEndDate"
                              class="mb-2"
                              :class="['form-control']"
                              @input="getAllCourseCommentForMyChild"
                              placeholder="Даалгавар хаагдах огноо"
                            ></b-form-datepicker>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b-icon icon="pencil-square"></b-icon>
                        Тэмдэглэл оруулсан:
                        {{ myChildCourseHomeworkNoted.length }}
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageNotedHW"
                                :options="pageOptionsNotedHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterNotedHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterNotedHW"
                                    @click="filterNotedHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageNotedHW"
                          :total-rows="totalRowsNotedHW"
                          :per-page="perPageNotedHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myChildCourseHomeworkNoted"
                          :fields="fieldsNotedHW"
                          :current-page="currentPageNotedHW"
                          :per-page="perPageNotedHW"
                          :filter="filterNotedHW"
                          :filter-ignored-fields="filterIgnoredFieldsNotedHW"
                          @filtered="onFilteredNotedHW"
                          :filter-included-fields="filterOnNotedHW"
                        >
                          <!-- :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-warning">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 0
                                    "
                                    style="float: right"
                                    variant="warning"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                          v-show="unReadComment.imReadParent == 1"
                          style="float: right"
                          icon="heart-fill"
                          variant="success"
                          @click.prevent="imReadParentConfirm(unReadComment)"
                        ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="noted-homework"
                  role="tabpanel"
                  aria-labelledby="noted-homework-tab"
                >
                  <div class="card card-default">
                    <div class="col-md-6">
                      <b-container class="bv-example-row" fluid>
                        <b-row>
                          <b-col sm="4">
                            <multiselect
                              v-model="quarter"
                              deselect-label="Сонголт цуцлах"
                              select-label="Улирал сонгох"
                              track-by="id"
                              :custom-label="customQuarterName"
                              label="name"
                              placeholder="Хичээлийн улирал сонгоно уу?"
                              :options="quarterOptions"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong
                                  >{{ option.name }}-р улирал
                                </strong></template
                              >
                            </multiselect>
                          </b-col>
                          <b-col sm="4">
                            <multiselect
                              v-model="selectedMonth"
                              deselect-label="Сонголт цуцлах"
                              select-label="Сар сонгох"
                              :custom-label="customMonthName"
                              track-by="month_name"
                              label="month_name"
                              placeholder="Хичээлийн сар сонгоно уу?"
                              :options="monthList"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >
                                <strong>{{ option.month_name }}-р сар</strong>
                              </template>
                            </multiselect>
                          </b-col>
                          <b-col sm="4">
                            <multiselect
                              v-model="selectedChild"
                              deselect-label="Сонголт цуцлах"
                              select-label="Хүүхэд сонгох"
                              placeholder="Хүүхэд сонгоно уу?"
                              track-by="user_id"
                              label="student_name"
                              :options="myChilds"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong>{{
                                  option.student_name
                                }}</strong></template
                              >
                            </multiselect>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                    <div class="card-body" id="chart-container">
                      <span v-if="chart === true">
                        <div
                          v-if="courseAvgName.length > 0"
                          id="chart-labels"
                          style="text-align: center; margin-bottom: 10px"
                        >
                          <div id="chart">
                            <apexchart
                              type="bar"
                              height="450"
                              :options="chartHWOptions"
                              :series="seriesHW"
                            >
                            </apexchart>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="card card-default" v-if="selectedChild">
                    <div class="col-md-6">
                      <b-container class="bv-example-row" fluid>
                        <b-row>
                          <b-col sm="4">
                            <multiselect
                              v-model="selectedLesson"
                              deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                              placeholder="Хичээл сонгоно уу?"
                              track-by="course_id"
                              label="subject_name"
                              :options="lessonList"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkLessonStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong
                                  >{{ option.subject_name }}
                                </strong></template
                              >
                            </multiselect>
                          </b-col>
                          <b-col sm="4">
                            <multiselect
                              v-model="quarterLesson"
                              deselect-label="Сонголт цуцлах"
                              select-label="Улирал сонгох"
                              track-by="id"
                              :custom-label="customQuarterName"
                              label="name"
                              placeholder="Хичээлийн улирал сонгоно уу?"
                              :options="quarterOptions"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkLessonStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong
                                  >{{ option.name }}-р улирал
                                </strong></template
                              >
                            </multiselect>
                          </b-col>
                          <b-col sm="4">
                            <multiselect
                              v-model="selectedMonthLesson"
                              deselect-label="Сонголт цуцлах"
                              select-label="Сар сонгох"
                              :custom-label="customMonthName"
                              track-by="month_name"
                              label="month_name"
                              placeholder="Хичээлийн сар сонгоно уу?"
                              :options="months"
                              :searchable="false"
                              :allow-empty="false"
                              @input="homeWorkLessonStat"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong
                                  >{{ option.month_name }}-р сар</strong
                                ></template
                              >
                            </multiselect>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                    <div class="card-body">
                      <span v-if="chartLesson === true">
                        <div v-if="courseLessonName.length > 0">
                          <apexchart
                            type="line"
                            height="350"
                            :options="chartHWLessonOptions"
                            :series="seriesHWLesson"
                          >
                          </apexchart>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
            <b-modal
              size="xl"
              id="modal-homework-student"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
              :body-bg-variant="bodyBgVariant"
              title="Гэрийн даалгаврын гүйцэтгэл"
              class="modal fade"
              hide-footer
              @hidden="resetFormHwGradeStudent"
            >
              <div class="modal-body">
                <div class="direct-chat-msg">
                  <div class="direct-chat-infos clearfix">
                    <span class="direct-chat-name float-left">
                      Гэрийн даалгавар/Homework</span
                    >
                    <span class="direct-chat-timestamp float-right"
                      ><b>Даалгавар эхлэх огноо: </b
                      >{{
                        selectHWStudents.course_hw_start_date | dateYearMonthDay
                      }}
                      -
                      {{ selectHWStudents.course_hw_start_time }}
                      цаг</span
                    ><br />
                    <span class="direct-chat-timestamp float-right"
                      ><b>Даалгавар дуусах огноо: </b>
                      {{
                        selectHWStudents.course_hw_end_date | dateYearMonthDay
                      }}
                      -
                      {{ selectHWStudents.course_hw_end_time }}
                      цаг</span
                    >
                  </div>
                  <div class="direct-chat-text primary ml-0">
                    <label>Гэрийн даалгавар</label>
                    <!-- <quill-editor
                      v-model="selectHWStudents.hw"
                      :options="editorOptionStudent"
                    >
                    </quill-editor> -->
                    <div v-html="selectHWStudents.hw"></div>
                </div>
                  <div
                    class="direct-chat-text primary ml-0"
                    v-if="teacherCommentCheck !== null"
                  >
                    <label>Багшийн тайлбар</label>
                    <quill-editor
                      v-model="teacherCommentCheck"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                  </div>
                  <div
                    class="direct-chat-text primary ml-0"
                    v-if="studentCommentCheck !== null"
                  >
                    <label>Миний хүүхдийн тайлбар</label>
                    <quill-editor
                      v-model="studentCommentCheck"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                  </div>
                  <div class="direct-chat-text primary ml-0">
                    <label>Эцэг эхийн тэмдэглэл</label>
                    <quill-editor
                      v-model="form.hw_parent_comment"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                  </div>
                  <b-button
                    variant="outline-primary"
                    @click="saveHWParentExplain()"
                    >Хадгалах</b-button
                  >
                </div>
              </div>
            </b-modal>
          </div>
        </div>
        <div
          class="tab-pane fade show"
          id="courseComment"
          role="tabpanel"
          aria-labelledby="courseComment-tab"
        >
          <div class="card card-dark card-outline card-outline-tabs">
            <div class="card-header p-0 pt-1">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-home"
                    role="tab"
                    aria-controls="custom-tabs-one-home"
                    aria-selected="true"
                    >Танилцаагүй</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-profile-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-profile"
                    role="tab"
                    aria-controls="custom-tabs-one-profile"
                    aria-selected="false"
                    >Танилцсан</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-one-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-one-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-home-tab"
                >
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-bullhorn"></i>
                        Таны хүүхэд сургалтын үйл ажиллагаанд оролцох явцад
                        ажиглагдсан ахиц, амжилт, давуу ба сул тал, анхаарах
                        зүйлсийн талаар багш нарын өгсөн мэдээлэл. Хүүхдийг
                        амжилттай суралцахад эцэг эх, багшийн хамтын ажиллагааг
                        бэхжүүлэхэд ашиглана.
                      </h3>
                    </div>
                    <div class="card-body">
                      <div
                        class="callout callout-warning"
                        v-for="(unReadComment, i) in myChildCourseCommentunRead"
                        :key="i"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <span style="font-size: 120%">
                              {{ unReadComment.subject_name }}
                              {{ unReadComment.teacher_last_name[0] }}.
                              {{ unReadComment.teacher_name }}
                              багш
                            </span>
                            <span style="font-size: 80%">
                              {{
                                unReadComment.created_at | dateYearMonthDay
                              }}</span
                            >
                          </div>
                          <div class="col-md-6">
                            <div
                              class="float-right"
                              v-show="unReadComment.imReadParent == 0"
                            >
                              <cite class="small text-muted"
                                >Та танилцсан бол дарна уу.</cite
                              >
                              <b-icon
                                icon="hand-index"
                                animation="cylon"
                                rotate="90"
                              ></b-icon>
                              <b-button
                                class="py-0"
                                size="sm"
                                variant="outline-warning"
                                @click.prevent="
                                  imReadParentConfirm(unReadComment)
                                "
                                ><b-icon icon="heart-fill"></b-icon>
                                Танилцсан</b-button
                              >
                            </div>
                          </div>
                        </div>
                        <p style="font-size: 80%">
                          <b
                            >{{ unReadComment.student_last_name }}
                            {{ unReadComment.student_name }}:
                          </b>
                          <quill-editor
                            v-model="unReadComment.comments"
                            :options="editorOptionStudent"
                            placeholder="Тэмдэглэл"
                          >
                          </quill-editor>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-one-profile"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-profile-tab"
                >
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-bullhorn"></i>
                        Таны хүүхэд сургалтын үйл ажиллагаанд оролцох явцад
                        ажиглагдсан ахиц, амжилт, давуу ба сул тал, анхаарах
                        зүйлсийн талаар багш нарын өгсөн мэдээлэл. Хүүхдийг
                        амжилттай суралцахад эцэг эх, багшийн хамтын ажиллагааг
                        бэхжүүлэхэд ашиглана.
                      </h3>
                    </div>
                    <div class="card-body">
                      <div
                        class="callout callout-success"
                        v-for="(readComm, i) in myChildCourseCommentRead"
                        :key="i"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <span style="font-size: 120%">
                              {{ readComm.subject_name }}
                              {{ readComm.teacher_last_name[0] }}.
                              {{ readComm.teacher_name }}
                              багш
                            </span>
                            <span style="font-size: 80%">
                              {{ readComm.created_at | dateYearMonthDay }}</span
                            >
                          </div>
                          <div class="col-md-6">
                            <span style="font-size: 80%">
                              <!-- @click.prevent="imReadParentConfirm(readComm)" -->
                              <b-icon
                                v-show="readComm.imReadParent == 1"
                                style="float: right"
                                icon="heart-fill"
                                variant="success"
                              ></b-icon>
                            </span>
                          </div>
                        </div>

                        <p style="font-size: 80%">
                          <b
                            >{{ readComm.student_last_name }}
                            {{ readComm.student_name }}:
                          </b>
                          <quill-editor
                            v-model="readComm.comments"
                            :options="editorOptionStudent"
                            placeholder="Тэмдэглэл"
                          >
                          </quill-editor>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import cultural from "../Project/CulturalCalendar.vue";
export default {
  components: {
    cultural: cultural,
    Multiselect,
  },
  data() {
    return {
      editMode: "",
      myChildCourseCommentunRead: [],
      myChildCourseCommentRead: [],
      myChildCourseHomeworkunDone: [],
      myChildCourseHomeworkDone: [],
      myChildCourseHomeworkNoted: [],
      courseAvgName: [],
      courseAvg: [],
      courseLessonName: [],
      courseLesson: [],
      monthList: [],
      months: [],
      lessonList: [],
      quarterOptions: [],
      myChilds: [],
      chart: false,
      chartLesson: false,
      selectedQuarter: 0,
      sumSpendHW: [],
      sumSpendHWDone: [],
      quarter: "",
      selectedMonth: null,
      quarterLesson: "",
      selectedMonthLesson: null,
      selectedChild: "",
      selectedLesson: "",
      //   гэрийн даалгавар
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selectHWStudents: "",
      hwStartDate: "",
      hwEndDate: "",
      //   vue-quill-editor
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },

      form: new Form({
        id: "",
        course_hw_id: "",
        student_id: "",
        hw_parent_comment: "",
        teacher_id: "",
        student_class: "",
        student_name: "",
        student_last_name: "",
      }),
      teacherCommentCheck: "",
      studentCommentCheck: "",

      editorOptionParent: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder:
          "Эцэг эхийн зүгээс хүүхдийнхээ гэрийн даалгаврын гүйцэтгэлд тайлбар өгөх...",
      },

      homeWorkLoaded: true,
      // Хүснэгтийн тохиргоо
      totalRowsUndoneHW: 1,
      currentPageUndoneHW: 1,
      perPageUndoneHW: 10,
      pageOptionsUnDoneHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsUnDoneHW: [
        "course_hw_stud_hw_parent_comment",
        "course_hw_stud_hw_student_comment",
        "course_hw_teacher_explain",
      ],
      filterUnDoneHW: null,
      filterOnUnDoneHW: [],
      fieldsUndoneHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],

      //   Хийсэн
      totalRowsDoneHW: 1,
      currentPageDoneHW: 1,
      perPageDoneHW: 10,
      pageOptionsDoneHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      filterIgnoredFieldsDoneHW: [
        "course_hw_stud_hw_parent_comment",
        "course_hw_stud_hw_student_comment",
        "course_hw_teacher_explain",
      ],
      filterDoneHW: null,
      filterOnDoneHW: [],
      fieldsDoneHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],
      //   Тэмдэглэл хийсэн
      totalRowsNotedHW: 1,
      currentPageNotedHW: 1,
      perPageNotedHW: 10,
      pageOptionsNotedHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      filterIgnoredFieldsNotedHW: [
        "course_hw_stud_hw_parent_comment",
        "course_hw_stud_hw_student_comment",
        "course_hw_teacher_explain",
      ],
      filterNotedHW: null,
      filterOnNotedHW: [],
      fieldsNotedHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],
      seriesHW: [
        {
          name: "Дундаж гүйцэтгэл(HW)", // Student evaluation
          data: [], // Your data here
        },
        {
          name: "Даалгаварын тоо", // Homework count
          data: [], // Your data here
        },
      ],
      chartHWOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: -30,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "last", // 'all', 'last'
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      seriesHWLesson: [
        {
          name: "Суралцагчийн үнэлгээ",
          type: "column",
          data: [],
        },
        {
          name: "Суралцагчийн дундаж үнэлгээ",
          type: "line",
          data: [],
        },
      ],
      chartHWLessonOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    //b-table тохирго
    onFilteredUndoneHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsUndoneHW = filteredItems.length;
      this.currentPageUndoneHW = 1;
    },
    onFilteredDoneHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDoneHW = filteredItems.length;
      this.currentPageDoneHW = 1;
    },
    onFilteredNotedHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsNotedHW = filteredItems.length;
      this.currentPageNotedHW = 1;
    },
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    customMonthName({ month_name }) {
      return `${month_name}-р сар`;
    },
    getMyChilds() {
      axios
        .get("/parent/getMyChild")
        .then((res) => {
          //   console.log(res.data);
          this.myChilds = res.data.mychilds;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSchoolQuarters() {
      axios
        .get("/parent/getSchoolQuarters")
        .then((response) => {
          this.quarterOptions = response.data.schoolquarters;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/parent/getCurrentQuarter")
        .then((response) => {
          this.selectedQuarter = response.data.currentQuarter.id;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getAllCourseCommentForMyChild() {
      this.sumSpendHW = [];
      this.sumSpendHWDone = [];
      axios
        .post("/parent/getCourseCommForMyChild", {
          selectedQuarter: this.selectedQuarter,
          hwStartDate: this.hwStartDate,
          hwEndDate: this.hwEndDate,
        })
        .then((res) => {
          //   console.log(res.data);
          //Тэмдэглэл
          this.myChildCourseCommentunRead = res.data.myChildCourseCommentunRead;
          this.myChildCourseCommentRead = res.data.myChildCourseCommentRead;
          // гэрийн даалгавар хүлээгдэж байгаа
          this.myChildCourseHomeworkunDone =
            res.data.myChildCourseHomeworkunDone;
          this.totalRowsUndoneHW = this.myChildCourseHomeworkunDone.length;
          // гэрийн даалгавар гүйцэтгэсэн
          this.myChildCourseHomeworkDone = res.data.myChildCourseHomeworkDone;
          this.totalRowsDoneHW = this.myChildCourseHomeworkDone.length;
          // гэрийн даалгавар тэмдэглэсэн
          this.myChildCourseHomeworkNoted = res.data.myChildCourseHomeworkNoted;
          this.totalRowsNotedHW = this.myChildCourseHomeworkNoted.length;
          this.childs = [];
          this.sumSpendHW = [];
          this.sumSpendHWDone = [];
          this.childs = res.data.myChilds;
          this.childs.forEach((child) => {
            const totalDoneTime = this.myChildCourseHomeworkDone
              .filter((i) => i.course_hw_stud_id === child.user_id)
              .reduce((sum, element) => sum + (element.spend_minute || 0), 0);

            const totalUndoneTime = this.myChildCourseHomeworkunDone
              .filter((i) => i.course_hw_stud_id === child.user_id)
              .reduce((sum, element) => sum + (element.spend_minute || 0), 0);

            this.sumSpendHW.push({
              name: child.student_name,
              totalUndoneTime: totalUndoneTime,
            });
            this.sumSpendHWDone.push({
              name: child.student_name,
              totalDoneTime: totalDoneTime,
            });
          });

          this.homeWorkLoaded = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    homeWorkStat() {
      this.seriesHW[0].data = [];
      this.seriesHW[1].data = [];
      this.chartHWOptions.xaxis.categories = [];
      this.chart = false;
      axios
        .post("/parent/homeWorkStat", {
          quarter: this.quarter.id,
          selectedMonth: this.selectedMonth,
          selectedChild: this.selectedChild.user_id,
        })
        .then((res) => {
          //   console.log(res.data);
          //Тэмдэглэл
          this.lessonList = res.data.courses;
          this.monthList = res.data.monthList;
          this.courseAvg = res.data.courseAvg;
          this.courseAvgName = res.data.courseAvgName;
          this.seriesHW[0].data = res.data.courseAvg;
          this.seriesHW[1].data = res.data.courseHWCount;
          this.chartHWOptions.xaxis.categories = res.data.courseAvgName;
          //   var labelsContainer = document.getElementById("chart-labels");
          //   labelsContainer.innerHTML = seriesHW[1].data
          //     .map(
          //       (val, index) =>
          //         `<span style="margin: 0 15px; font-weight: bold;">${val}</span>`
          //     )
          //     .join(""); // Adjust spacing as needed
          this.chart = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    homeWorkLessonStat() {
      this.seriesHWLesson[0].data = [];
      this.seriesHWLesson[1].data = [];
      this.chartHWLessonOptions.xaxis.categories = [];
      this.chartLesson = false;
      axios
        .post("/parent/homeWorkLessonStat", {
          quarter: this.quarterLesson.id,
          selectedMonth: this.selectedMonthLesson,
          selectedChild: this.selectedChild.user_id,
          selectedLesson: this.selectedLesson.course_id,
        })
        .then((res) => {
          this.courseLesson = res.data.hwGrade;
          this.courseLessonName = res.data.hwName;
          this.months = res.data.months;
          this.seriesHWLesson[0].data = res.data.hwGrade;
          res.data.hwGrade.forEach((el) => {
            this.seriesHWLesson[1].data.push(res.data.avg.hw_grade_avg);
          });
          this.chartHWLessonOptions.xaxis.categories = res.data.hwName;
          this.chartLesson = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    imReadParentConfirm(comment) {
      axios
        .post("/parent/readParentConfirm", { comment })
        .then((res) => {
          Fire.$emit("readComment");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    viewMoreInfo(infos) {
      //   console.log(infos);
      this.form.course_hw_id = infos.course_hw_id;
      this.form.student_id = infos.course_hw_stud_id;
      this.form.hw_parent_comment = infos.course_hw_stud_hw_parent_comment;
      this.form.teacher_id = infos.course_hw_teacher_id;
      this.form.school_class_id = infos.course_hw_school_class_id;
      this.form.student_last_name = infos.student_last_name;
      this.form.student_name = infos.student_name;
      this.teacherCommentCheck = infos.course_hw_teacher_explain;
      this.studentCommentCheck = infos.course_hw_stud_hw_student_comment;
      axios
        .post("/parent/getMyChildHW", {
          info: infos,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectHWStudents = res.data.selectHWStudents;
          //   console.log(this.selectHWStudents);
        })
        .catch((err) => console.log(err));
      //   console.log(this.selectHWStudents);
      this.$bvModal.show("modal-homework-student");
      //   console.log(infos);
    },
    saveHWParentExplain() {
      this.form
        .post("/parent/saveHWParentExplain")
        .then((res) => {
          if (res.data.info.code === "success") {
            Fire.$emit("readComment");

            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if ((res.info.code = "error")) {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.$bvModal.hide("modal-homework-student");
        })
        .catch((err) => console.log(err));
    },
    resetFormHwGradeStudent() {
      this.selectHWStudents = "";
      this.teacherCommentCheck = "";
      this.form.reset();
    },
  },
  created() {
    this.getSchoolQuarters();
    this.homeWorkStat();
    this.getMyChilds();
    Fire.$on("readComment", () => {
      this.getAllCourseCommentForMyChild();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 50%;
  height: auto;
  /* width: 40px !important;
  height: 40px;
  object-fit: cover; */
}
</style>
