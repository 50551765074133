<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="years"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#active" data-toggle="tab"
                >Хариу өгөөгүй</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#temdeglesen" data-toggle="tab"
                >Хариу өгсөн</a
              >
            </li>
          </ul>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Өгсөн үүрэг даалгаврууд</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body table-responsive p-0">
            <div class="tab-content">
              <!-- <div class="row"></div> -->
              <div class="tab-pane active" id="active">
                <b-form-group
                  label="Нэг хуудсанд харуулах тоо"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageTasks"
                    :options="pageOptionsTasks"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Хайлт хийх утга оруулна уу!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterTasks"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-pagination
                  v-model="currentPageTasks"
                  :total-rows="totalRowsTasks"
                  :per-page="perPageTasks"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="allTask"
                  :fields="fieldsActiveTasks"
                  :current-page="currentPageTasks"
                  :per-page="perPageTasks"
                  :filter="filterTasks"
                  :filter-included-fields="filterOnTasks"
                  @filtered="onFilteredTasks"
                >
                  <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->

                  <!-- index
            action
            guitsetgel
            enrol_fname
            task
            performance_date
            created_at
            manager_explain
            updated_at -->

                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-primary"
                      @click="editTask(data.item)"
                      ><i class="fa fa-check"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-success"
                      @click="showTask(data.item)"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <b-badge
                      v-b-tooltip.hover.top.html="
                        '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                        data.item.replyTeachers.join('<br>') +
                        ' <span class=text-uppercase>' +
                        '</span></dd></dl>'
                      "
                      pill
                      class="text-center"
                    >
                      {{ data.item.allTeacherCount }}/{{ data.item.replyCount }}
                    </b-badge>
                  </template>
                  <template #cell(guitsetgel)="data">
                    <b-badge
                      v-if="data.item.guitsetgel >= 85"
                      variant="success"
                      v-show="data.item.guitsetgel != null"
                    >
                      {{ data.item.guitsetgel }}</b-badge
                    >
                    <b-badge
                      v-if="data.item.guitsetgel < 85"
                      variant="light"
                      v-show="data.item.guitsetgel != null"
                    >
                      {{ data.item.guitsetgel }}</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-show="data.item.guitsetgel === null"
                    >
                      Гүйцэтгэл ороогүй.</b-badge
                    >
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="data.item.guitsetgel < 30"
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (data.item.guitsetgel >= 30) &
                          (data.item.guitsetgel < 60)
                        "
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (data.item.guitsetgel >= 60) &
                          (data.item.guitsetgel < 80)
                        "
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="data.item.guitsetgel >= 80"
                        class="progress-bar bg-success"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                    </div>
                  </template>
                  <template #cell(enrol_fname)="data">
                    <b-badge variant="info">
                      <span v-if="data.item.enrol_lname">
                        {{ data.item.enrol_lname[0].toUpperCase() }}.{{
                          data.item.enrol_fname
                        }}
                      </span>
                    </b-badge>
                  </template>
                  <template #cell(task)="data">
                    <b-badge
                      variant="warning"
                      style="width: 10rem"
                      class="badge badge-primary text-wrap"
                    >
                      <span
                        style="font-size: 80%; text-align: justify"
                        v-html="
                          data.item.task ? data.item.task.substring(0, 100) : ''
                        "
                      >
                      </span>
                    </b-badge>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(performance_date)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                      :
                      {{ data.item.performance_date | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(manager_explain)="data">
                    <b-badge variant="light">
                      <span
                        v-html="
                          data.item.manager_explain
                            ? data.item.manager_explain.substring(0, 100)
                            : ''
                        "
                      >
                      </span>
                    </b-badge>
                  </template>
                  <template #cell(updated_at)="data">
                    <b-badge>
                      {{ data.item.updated_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                </b-table>
              </div>
              <div class="tab-pane" id="temdeglesen">
                <b-form-group
                  label="Нэг хуудсанд харуулах тоо"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageTasksTemdeglesen"
                    :options="pageOptionsTasksTemdeglesen"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Хайлт хийх утга оруулна уу!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterTasksTemdeglesen"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-pagination
                  v-model="currentPageTasksTemdeglesen"
                  :total-rows="totalRowsTasksTemdeglesen"
                  :per-page="perPageTasksTemdeglesen"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="allTaskReplied"
                  :fields="fieldsActiveTasksTemdeglesen"
                  :current-page="currentPageTasksTemdeglesen"
                  :per-page="perPageTasksTemdeglesen"
                  :filter="filterTasksTemdeglesen"
                  :filter-included-fields="filterOnTasksTemdeglesen"
                  @filtered="onFilteredTasksTemdeglesen"
                >
                  <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->

                  <!-- index
            action
            guitsetgel
            enrol_fname
            task
            performance_date
            created_at
            manager_explain
            updated_at -->

                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="badge bg-primary"
                      @click="editTask(data.item)"
                      ><i class="fa fa-check"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-success"
                      @click="showTask(data.item)"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <b-badge
                      v-b-tooltip.hover.top.html="
                        '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                        data.item.replyTeachers.join('<br>') +
                        ' <span class=text-uppercase>' +
                        '</span></dd></dl>'
                      "
                      pill
                      class="text-center"
                    >
                      {{ data.item.allTeacherCount }}/{{ data.item.replyCount }}
                    </b-badge>
                  </template>
                  <template #cell(guitsetgel)="data">
                    <!-- <b-badge variant="info">
                                            {{
                                                data.item.emp_teacher_last_name
                                            }}-(ын/ийн)
                                            {{ data.item.emp_teacher_name }}
                                        </b-badge> -->

                    <b-badge
                      v-if="data.item.guitsetgel >= 85"
                      variant="success"
                      v-show="data.item.guitsetgel != null"
                    >
                      {{ data.item.guitsetgel }}</b-badge
                    >
                    <b-badge
                      v-if="data.item.guitsetgel < 85"
                      variant="light"
                      v-show="data.item.guitsetgel != null"
                    >
                      {{ data.item.guitsetgel }}</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-show="data.item.guitsetgel === null"
                    >
                      Гүйцэтгэл ороогүй.</b-badge
                    >
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="data.item.guitsetgel < 30"
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (data.item.guitsetgel >= 30) &
                          (data.item.guitsetgel < 60)
                        "
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (data.item.guitsetgel >= 60) &
                          (data.item.guitsetgel < 80)
                        "
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                      <div
                        v-if="data.item.guitsetgel >= 80"
                        class="progress-bar bg-success"
                        :style="{
                          width: `${data.item.guitsetgel}%`,
                        }"
                      ></div>
                    </div>
                  </template>
                  <template #cell(enrol_fname)="data">
                    <b-badge variant="info">
                      {{ data.item.enrol_lname[0].toUpperCase() }}.{{
                        data.item.enrol_fname
                      }}
                    </b-badge>
                  </template>
                  <template #cell(task)="data">
                    <b-badge
                      variant="warning"
                      style="width: 10rem"
                      class="badge badge-primary text-wrap"
                    >
                      <span
                        style="font-size: 80%; text-align: justify"
                        v-html="
                          data.item.task ? data.item.task.substring(0, 100) : ''
                        "
                      >
                      </span>
                    </b-badge>
                  </template>
                  <template #cell(created_at)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(performance_date)="data">
                    <b-badge>
                      {{ data.item.created_at | dateYearMonthDay }}
                      :
                      {{ data.item.performance_date | dateYearMonthDay }}
                    </b-badge>
                  </template>
                  <template #cell(manager_explain)="data">
                    <b-badge variant="light">
                      <span
                        v-html="
                          data.item.manager_explain
                            ? data.item.manager_explain.substring(0, 100)
                            : ''
                        "
                      >
                      </span>
                    </b-badge>
                  </template>
                  <template #cell(updated_at)="data">
                    <b-badge>
                      {{ data.item.updated_at | dateYearMonthDay }}
                    </b-badge>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-view-task"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Үүрэг даалгавар"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              <img
                v-if="
                  showTaskData.profile_photo_url &&
                  showTaskData.profile_photo_url != null
                "
                :src="showTaskData.profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showTaskData.enrol_lname }}
              {{ showTaskData.enrol_fname }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>
              {{ showTaskData.created_at | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showTaskData.task"> </span>
            <span
              v-if="showTaskData.manager_explain"
              v-html="showTaskData.manager_explain"
            >
            </span>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="col-lg-4 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-if="!editMode">Үүрэг даалгавар бүртгэх</h3>
          <h3 class="card-title" v-if="editMode">Үүрэг даалгавар засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <form v-on:change="changeTeacherList($event)" v-if="!editMode">
            <div class="form-group row">
              <div class="form-group">
                <label>Харьяалагдах мэргэжлийн баг</label>
                <b-form-select
                  v-model="filterForm.department_id"
                  :options="departments"
                  text-field="department_name"
                  value-field="id"
                ></b-form-select>
                <has-error
                  :form="filterForm"
                  field="department_name"
                ></has-error>
              </div>
            </div>
          </form>
          <form>
            <div class="form-group" v-if="!editMode">
              <label>Багш, ажилтан: </label
              ><span>{{ Object.keys(teachers).length }}</span>
              <b-form-select
                v-model="form.recieved_employees"
                :options="teachers"
                text-field="emp_teacher_name"
                value-field="emp_id"
                multiple
                :select-size="4"
              ></b-form-select>
              <has-error :form="form" field="emp_teacher_name"></has-error>
            </div>
            <div class="form-group">
              <label>Гүйцэтгэл тайлагнах хугацаа</label>
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.performance_date"
                class="mb-2"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('performance_date'),
                  },
                ]"
                placeholder="Гүйцэтгэл тайлагнах хугацаа"
              ></b-form-datepicker>
              <has-error :form="form" field="performance_date"></has-error>
            </div>
            <div class="form-group">
              <label for="evaluation">Өгсөн үүрэг, даалгавар</label>
              <validation-provider name="form.notification" rules="alpha_num">
                <template>
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="form.task"
                    :options="editorOption"
                  />
                </template>
              </validation-provider>
            </div>
            <div class="form-group" v-if="editMode">
              <label>Менежер тайлбар</label>
              <template>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="form.manager_explain"
                  :options="editorOption"
                />
              </template>
            </div>
            <div class="form-group" v-if="editMode">
              <b-form-checkbox
                class="float-right success"
                :name="isConfirmType"
                aria-describedby="active"
                switch
                size="xs"
                @change="changeStatus"
                ><span v-if="isConfirmType == 'true'" class="badge bg-primary"
                  >Нийт гүйцэтгэл оруулах
                </span>
                <span v-else class="badge bg-secondary"
                  >Дутуу гүйцэтгэл оруулах
                </span>
              </b-form-checkbox>
              <input
                type="number"
                name="guitsetgel"
                max="100"
                v-model="form.guitsetgel"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('guitsetgel'),
                  },
                ]"
                placeholder="Гүйтэгэлийн оноо %-аар тавих"
              />
              <has-error :form="form" field="guitsetgel"></has-error>
            </div>
          </form>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editMode"
            @click.prevent="createTask()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editMode"
            @click.prevent="updateTask()"
          >
            Засах
          </button>
          <button
            class="btn btn-secondary"
            v-show="editMode"
            @click.prevent="cancelEdit"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-taskInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Үүрэг даалгавар"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              >{{ showTaskInfo.enrol_lname }}
              {{ showTaskInfo.enrol_fname }}</span
            >
            <span class="direct-chat-timestamp float-right">{{
              showTaskInfo.created_at | dateYearMonthDay
            }}</span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            v-if="showTaskInfo.profile_photo_url != null"
            :src="showTaskInfo.profile_photo_url"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <img
            v-else
            src="/images/users/user.png"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <!-- <div class="direct-chat-text primary">
                        {{ showTaskInfo.task }}
                    </div> -->

          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showTaskInfo.task"> </span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Үүрэг даалгавар авсан багш нар</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="taskInfo != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Үнэлгээ</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Багш</th>
                      <th>Тайлбар</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(taskD, index) in taskInfo"
                    :key="taskD.show_task_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ taskD.guitsetgel }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <small>
                          {{ taskD.department_name }}
                        </small>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="taskD.user">
                          <span v-if="taskD.user.employee_migration_info">
                            <span
                              v-if="
                                taskD.user.employee_migration_info
                                  .my_position_info
                              "
                            >
                              <small>
                                {{
                                  taskD.user.employee_migration_info
                                    .my_position_info.position_name
                                }}
                              </small>
                            </span>
                          </span>
                        </span>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ taskD.emp_teacher_last_name[0] }}.
                        {{ taskD.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge
                          variant="light"
                          style="
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          v-b-tooltip.hover.top.html="
                            taskD.teacher_explain +
                            '<span class=text-uppercase></span>'
                          "
                          class="text-center"
                        >
                          <span
                            v-html="formatTeacherExplain(taskD.teacher_explain)"
                          ></span>
                          <span
                            variant="warning"
                            style="font-size: 80%; text-align: right"
                            v-show="taskD.teacher_explain != null"
                          >
                            {{ taskD.updated_at | dateYearMonthDay }}
                          </span>
                        </b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editTeacher(taskD)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(taskD)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editTeacherMode">Багш нэмэх</h3>
                <h3 class="card-title" v-show="editTeacherMode">
                  Багшид үнэлгээ өгөх
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form
                  v-on:change="modalChangeTeacherList($event)"
                  v-show="!editTeacherMode"
                >
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group" v-show="!editTeacherMode">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                  <div class="form-group" v-show="editTeacherMode">
                    <label>Гүйцэтгэл</label>
                    <input
                      type="number"
                      name="guitsetgel"
                      max="100"
                      v-model="formAddTeacher.guitsetgel"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('guitsetgel'),
                        },
                      ]"
                      placeholder="Гүйтэгэлийн оноо %-аар тавих"
                    />
                    <has-error
                      :form="formAddTeacher"
                      field="guitsetgel"
                    ></has-error>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacherTask()"
                  v-show="!editTeacherMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateTeacherTask()"
                  v-show="editTeacherMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditTeacher()"
                  v-show="editTeacherMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelTaskInfo()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      isConfirmType: "false",

      year_id: "",

      allTask: [],
      allTaskReplied: [],
      showTaskData: "",
      search: "",
      taskInfo: [],
      departments: [
        { value: null, department_name: "Please select an option" },
      ],
      teachers: [],
      showMode: false,
      showTaskMode: false,
      filterForm: new Form({
        department_id: 0,
      }),
      showTaskInfo: new Form({
        task_id: "",
        department_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employee_id: "",
        position_id: "",
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editMode: false,
      form: new Form({
        isConfirmType: "",
        task_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employees: [],
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editTeacherMode: false,
      modalTeachers: [],
      formAddTeacher: new Form({
        show_task_id: null,
        task_id: null,
        department_id: 0,
        recieved_employees: [],
        guitsetgel: "",
      }),
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
      //Task хүснэгтийн тайлбар
      totalRowsTasks: 1,
      currentPageTasks: 1,
      perPageTasks: 25,
      pageOptionsTasks: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү ихээр харах" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTasks: null,
      filterOnTasks: [],

      fieldsActiveTasks: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
        {
          key: "guitsetgel",
          label: "Гүйцэтгэл",
          sortable: true,
        },
        {
          key: "enrol_fname",
          label: "Өгсөн",
          sortable: true,
        },
        {
          key: "task",
          label: "Үүрэг",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "performance_date",
          label: "Үргэлжлэх огноо",
          sortable: true,
        },
        {
          key: "manager_explain",
          label: "Тайлбар",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
      ],
      //Task temdeglesen хүснэгтийн тайлбар
      totalRowsTasksTemdeglesen: 1,
      currentPageTasksTemdeglesen: 1,
      perPageTasksTemdeglesen: 25,
      pageOptionsTasksTemdeglesen: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү ихээр харах" },
      ],
      sortByTemdeglesen: "",
      sortDescTemdeglesen: false,
      sortDirectionTemdeglesen: "asc",
      filterTasksTemdeglesen: null,
      filterOnTasksTemdeglesen: [],

      fieldsActiveTasksTemdeglesen: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
        {
          key: "guitsetgel",
          label: "Гүйцэтгэл",
          sortable: true,
        },
        {
          key: "enrol_fname",
          label: "Өгсөн",
          sortable: true,
        },
        {
          key: "task",
          label: "Үүрэг",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "performance_date",
          label: "Үргэлжлэх огноо",
          sortable: true,
        },
        {
          key: "manager_explain",
          label: "Тайлбар",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    editor() {
      return this.$refs.myQuillEditor;
    },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      // console.log(newVal);
      axios
        .post(`/curriculumadministrator/allTaskCurrAdmin`, {
          year_id: newVal.id,
        })
        .then((response) => {
          this.filterForm.department_id = 0;
          this.allTask = response.data.allTask;
          this.allTaskReplied = response.data.allTaskReplied;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.teachers = response.data.teachers;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    formatTeacherExplain(text) {
      return text ? text.substring(0, 200) : "";
    },
    changeStatus() {
      if (this.isConfirmType == "false") {
        this.isConfirmType = "true";
        this.dNone = "";
      } else {
        this.isConfirmType = "false";
        this.dNone = "display: none";
      }
    },
    changeTeacherList: function (e) {
      this.filterForm.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.filterForm
        .get(
          "/curriculumadministrator/getEmployeeList/" +
            this.filterForm.department_id
        )
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formAddTeacher
        .get(
          "/curriculumadministrator/getEmployeeList/" +
            this.formAddTeacher.department_id
        )
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    onFilteredTasks(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsTasks = filteredItems.length;
      this.currentPageTasks = 1;
    },
    onFilteredTasksTemdeglesen(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsTasksTemdeglesen = filteredItems.length;
      this.currentPageTasksTemdeglesen = 1;
    },
    //Done
    getAllTask() {
      this.allTask = [];
      this.allTaskReplied = [];
      axios
        .post(`/curriculumadministrator/allTaskCurrAdmin`, {
          year_id: this.year_id.id,
        })
        .then((response) => {
          this.filterForm.department_id = 0;
          this.allTask = response.data.allTask;
          this.allTaskReplied = response.data.allTaskReplied;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.teachers = response.data.teachers;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //Done
    showTask(task) {
      this.action = "Үүрэг даалгаврын мэдээллийг харах...";
      axios
        .get("/curriculumadministrator/showTaskCurrAdmin/" + task.task_id)
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.showTaskInfo.reset();
          this.showTaskInfo.fill(task);
          this.showMode = !this.showMode;
          this.modalTeachers = this.teachers;
          this.formAddTeacher.reset();
          this.$bvModal.show("modal-taskInfo");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    showTaskView(task) {
      this.showTaskData = task;
      this.$bvModal.show("modal-view-task");
    },
    //Done
    editTask(task) {
      //   console.log(task);
      this.editMode = true;
      this.form.reset();
      this.form.fill(task);
    },
    updateTask() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form.isConfirmType = this.isConfirmType;
      this.form
        .put(
          "/curriculumadministrator/taskUpdateCurrAdmin/" + this.form.task_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createTask() {
      this.form
        .post("/curriculumadministrator/createTaskCurrAdmin")
        .then((response) => {
          this.$toastr.s("Үүрэг даалгавар амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getTasks");
          this.form.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    deleteTask(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    cancelTaskInfo() {
      this.showMode = !this.showMode;
      this.showTaskInfo.reset();
      this.$bvModal.hide("modal-taskInfo");
    },
    editTeacher(task) {
      this.editTeacherMode = true;
      this.formAddTeacher.reset();
      this.formAddTeacher.fill(task);
    },
    updateTeacherTask() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      this.formAddTeacher
        .put(
          "/curriculumadministrator/updateTeacherTaskCurrAdmin/" +
            this.formAddTeacher.show_task_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Багшийг амжилттай үнэллээ!", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.formAddTeacher.reset();
          this.editTeacherMode = !this.editTeacherMode;
          this.taskInfo = response.data.taskInfo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEditTeacher() {
      this.formAddTeacher.reset();
      this.editTeacherMode = !this.editTeacherMode;
    },
    removeTeacher(schoolteacher) {
      this.action = "Багш хасаж байна...";
      this.form
        .get(
          "/curriculumadministrator/removeTeacherTaskCurrAdmin/" +
            schoolteacher.show_task_id,
          {
            params: { task_id: schoolteacher.task_id },
          }
        )
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addTeacherTask() {
      this.formAddTeacher.task_id = this.showTaskInfo.task_id;
      this.formAddTeacher
        .post("/curriculumadministrator/addTeacherTaskCurrAdmin")
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formAddTeacher.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    this.getAllTask();
    // console.log(this.year_id + "asdfadsf");
    Fire.$on("getTasks", () => {
      this.getAllTask();
    });
  },
};
</script>

<style></style>
