<template>
  <div class="row pt-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header p-2 float-center">
          <ul class="nav nav-pills">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#primaryschool" data-toggle="tab"
                >Бага</a
              >
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="#middleschool" data-toggle="tab"
                >Дунд</a
              >
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="#highschool" data-toggle="tab">Ахлах</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link active" href="#school" data-toggle="tab"
                >Хувилбар-1</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <!-- <div class="tab-pane" id="primaryschool">Бага</div> -->
            <!-- <div class="tab-pane" id="middleschool">Дунд</div> -->

            <!-- <div class="tab-pane" id="highschool">Ахлах</div> -->
            <div class="active tab-pane" id="school">
              <multiselect
                v-model="school_year_id"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="id"
                label="id"
                :custom-label="customYearName"
                placeholder="Хичээлийн жил сонгоно уу?"
                :options="years"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                    - хичээлийн жил
                  </strong></template
                >
              </multiselect>
              <!-- Жилээр харуулах -->
              <div class="row" v-if="viewMySchoolChart">
                <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Бага сургууль(Жилээр)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsPrimary"
                              :series="seriesPrimary"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Дунд сургууль(Жилээр)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsMiddle"
                              :series="seriesMiddle"
                            ></apexchart
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Ахлах сургууль(Жилээр)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                            ><apexchart
                              :options="optionsHigh"
                              :series="seriesHigh"
                            ></apexchart
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <h4>Recent Activity</h4>
                    </div>
                  </div> -->
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

                </div> -->
              </div>
              <multiselect
                v-if="viewMySchoolChart"
                v-model="school_quarter_id"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="id"
                label="id"
                :custom-label="customQuarterName"
                placeholder="Улирал сонгоно уу?"
                :options="quarters"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ option.name }} - р улирал </strong></template
                >
              </multiselect>
              <!-- Улирлаар харуулах -->
              <div class="row" v-if="viewMySchoolQuarterChart">
                <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Бага сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsQuarterPrimary"
                              :series="seriesQuarterPrimary"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Дунд сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsQuarterMiddle"
                              :series="seriesQuarterMiddle"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Ахлах сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                            ><apexchart
                              :options="optionsQuarterHigh"
                              :series="seriesQuarterHigh"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

                </div> -->
              </div>

              <div class="row" v-if="viewMySchoolQuarterChart">
                <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Бага сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassPrimary"
                              :series="optionsClassPrimary.series"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterChart"
                            v-model="school_primary_class_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="id"
                            label="id"
                            :custom-label="customClassName"
                            placeholder="Ангиар сонгож, хичээлийн статистик харах"
                            :options="getSchoolPrimaryClasses"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.full_name }} хичээл
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectsPrimary"
                              :series="optionsClassSubjectsPrimary.series"
                              v-if="viewMySchoolPrimaryClassChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolPrimaryClassChart"
                            v-model="school_primary_classes_subject_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="subject_id"
                            label="subject_id"
                            :custom-label="customSubjectName"
                            placeholder="Хичээлээр сонгож, суралцагчийн статистик харах"
                            :options="getSchPriClassesSubjects"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.subject_name }} анги
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectStudentsPrimary"
                              :series="
                                optionsClassSubjectStudentsPrimary.series
                              "
                              v-if="viewMySchoolQuarterPriClassSubjectChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterPriClassSubjectChart"
                            v-model="school_primary_classes_subject_student_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="student_id"
                            label="student_id"
                            :custom-label="customStudentName"
                            placeholder="Суралцагчаар сонгож статистик харах"
                            :options="getSchPriClassesSubjectsStudents"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.student_last_name[0] }}.{{
                                  option.student_name
                                }}
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              v-if="
                                viewMySchoolQuarterPriClassSubjectStudentChart
                              "
                              type="radar"
                              :options="optionsStudentPri"
                              :series="optionsStudentPri.seriesStudentPri"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Дунд сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassMiddle"
                              :series="optionsClassMiddle.series"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterChart"
                            v-model="school_middle_class_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="id"
                            label="id"
                            :custom-label="customClassName"
                            placeholder="Ангиар сонгож, хичээлийн статистик харах"
                            :options="getSchoolMiddleClasses"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.full_name }} анги
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectsMiddle"
                              :series="optionsClassSubjectsMiddle.series"
                              v-if="viewMySchoolMiddleClassChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolMiddleClassChart"
                            v-model="school_middle_classes_subject_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="subject_id"
                            label="subject_id"
                            :custom-label="customSubjectName"
                            placeholder="Хичээлээр сонгож, суралцагчийн статистик харах"
                            :options="getSchMiddClassesSubjects"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.subject_name }} хичээл
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectStudentsMiddle"
                              :series="optionsClassSubjectStudentsMiddle.series"
                              v-if="viewMySchoolQuarterMiddClassSubjectChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterMiddClassSubjectChart"
                            v-model="school_middle_classes_subject_student_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="student_id"
                            label="student_id"
                            :custom-label="customStudentName"
                            placeholder="Суралцагчаар сонгож статистик харах"
                            :options="getSchMiddClassesSubjectsStudents"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.student_last_name[0] }}.{{
                                  option.student_name
                                }}
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              type="radar"
                              v-if="
                                viewMySchoolQuarterMiddClassSubjectStudentChart
                              "
                              :options="optionsStudentMidd"
                              :series="optionsStudentMidd.seriesStudentMidd"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span class="info-box-text text-center text-muted"
                            >Ахлах сургууль(Улирал)</span
                          >
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassHigh"
                              :series="optionsClassHigh.series"
                              v-if="viewMySchoolQuarterChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterChart"
                            v-model="school_high_class_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="id"
                            label="id"
                            :custom-label="customClassName"
                            placeholder="Ангиар сонгож, хичээлийн статистик харах"
                            :options="getSchoolHighClasses"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.full_name }} анги
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectsHigh"
                              :series="optionsClassSubjectsHigh.series"
                              v-if="viewMySchoolHighClassChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolHighClassChart"
                            v-model="school_high_classes_subject_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="subject_id"
                            label="subject_id"
                            :custom-label="customSubjectName"
                            placeholder="Хичээлээр сонгож, суралцагчийн статистик харах"
                            :options="getSchHighClassesSubjects"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.subject_name }} хичээл
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              :options="optionsClassSubjectStudentsHigh"
                              :series="optionsClassSubjectStudentsHigh.series"
                              v-if="viewMySchoolQuarterHighClassSubjectChart"
                            ></apexchart>
                          </span>
                          <multiselect
                            v-if="viewMySchoolQuarterHighClassSubjectChart"
                            v-model="school_high_classes_subject_student_id"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="student_id"
                            label="student_id"
                            :custom-label="customStudentName"
                            placeholder="Суралцагчаар сонгож статистик харах"
                            :options="getSchHighClassesSubjectsStudents"
                            :searchable="false"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.student_last_name[0] }}.{{
                                  option.student_name
                                }}
                              </strong></template
                            >
                          </multiselect>
                          <span
                            class="info-box-number text-center text-muted mb-0"
                          >
                            <apexchart
                              type="radar"
                              v-if="
                                viewMySchoolQuarterHighClassSubjectStudentChart
                              "
                              :options="optionsStudentHigh"
                              :series="optionsStudentHigh.seriesStudentHigh"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      year_id: "",
      schoolPrimary_year_id: "",
      schoolMiddle_year_id: "",
      schoolHigh_year_id: "",

      //Сургуулиар
      school_year_id: "",
      school_quarter_id: "",
      school_primary_class_id: "",
      school_middle_class_id: "",
      school_high_class_id: "",
      years: [],
      quarters: [],
      departments: [],
      schoolCLasses: [],
      teachers: [],

      options: {
        width: "100%",
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
      //Сургуулийн Эхлэл
      viewMySchoolChart: false,

      optionsPrimary: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesPrimary: [0],
      optionsMiddle: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesMiddle: [0],
      optionsHigh: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesHigh: [0],

      //Төгсгөл
      //Улирал эхлэх

      viewMySchoolQuarterChart: false,

      optionsQuarterPrimary: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesQuarterPrimary: [0],
      optionsQuarterMiddle: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesQuarterMiddle: [0],
      optionsQuarterHigh: {
        chart: {
          width: "100%",
          type: "radialBar",
        },
        colors: ["#3d85c6", "#66DA26"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              //   total: {
              //     show: true,
              //     label: "Нийт",
              //   },
              name: {
                // offsetY: -10,
                color: "#fff",
                fontSize: "13px",
                show: true,
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#6a329f", "#FF9800"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Дүн"],
      },
      seriesQuarterHigh: [0],
      //Улирал төгсгөл

      //Анги эхлэх
      getSchoolPrimaryClasses: [],
      getSchoolMiddleClasses: [],
      getSchoolHighClasses: [],
      viewMySchoolPrimaryClassChart: false,
      viewMySchoolMiddleClassChart: false,
      viewMySchoolHighClassChart: false,
      //   Ангиуд боловсролын түвшингөөр
      optionsClassPrimary: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val, opts) {
            return val;
          },
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassMiddle: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val, opts) {
            return val;
          },
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassHigh: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val, opts) {
            return val;
          },
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      //   Ангиуд хичээлээр
      optionsClassSubjectsPrimary: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
            height: 500,
          },
        },
        range: undefined,
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          maxHeight: 200,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassSubjectsMiddle: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassSubjectsHigh: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      viewMySchoolClassChart: false,
      //Хичээлийн суралцагчаар
      getSchPriClassesSubjects: [],
      getSchMiddClassesSubjects: [],
      getSchHighClassesSubjects: [],
      school_primary_classes_subject_id: "",
      school_middle_classes_subject_id: "",
      school_high_classes_subject_id: "",
      viewMySchoolQuarterPriClassSubjectChart: false,
      viewMySchoolQuarterMiddClassSubjectChart: false,
      viewMySchoolQuarterHighClassSubjectChart: false,
      optionsClassSubjectStudentsPrimary: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
            height: 500,
          },
        },
        range: undefined,
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          maxHeight: 200,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassSubjectStudentsMiddle: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      optionsClassSubjectStudentsHigh: {
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: 10,
          },
        },
        legend: {
          show: false,
        },
        chart: {
          type: "bar",
        },
        series: [
          {
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "top",
          distributed: false,
          offsetX: 0,
          offsetY: 100,
          style: {
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },

      //Суралцагчаар
      getSchPriClassesSubjectsStudents: [],
      getSchMiddClassesSubjectsStudents: [],
      getSchHighClassesSubjectsStudents: [],
      school_primary_classes_subject_student_id: "",
      school_middle_classes_subject_student_id: "",
      school_high_classes_subject_student_id: "",
      viewMySchoolQuarterPriClassSubjectStudentChart: false,
      viewMySchoolQuarterMiddClassSubjectStudentChart: false,
      viewMySchoolQuarterHighClassSubjectStudentChart: false,
      optionsStudentPri: {
        chart: {
          height: 450,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 80,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        seriesStudentPri: [
          {
            name: "",
            data: [],
          },
        ],
        labels: ["MoIn-1", "MoIn-2", "AtBe-1", "AtBe-2", "BeWo-1", "BeWo-2"],
      },
      optionsStudentMidd: {
        chart: {
          height: 450,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 80,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        seriesStudentMidd: [
          {
            name: "",
            data: [],
          },
        ],
        labels: ["MoIn-1", "MoIn-2", "AtBe-1", "AtBe-2", "BeWo-1", "BeWo-2"],
      },
      optionsStudentHigh: {
        chart: {
          height: 450,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 80,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        seriesStudentHigh: [
          {
            name: "",
            data: [],
          },
        ],
        labels: ["MoIn-1", "MoIn-2", "AtBe-1", "AtBe-2", "BeWo-1", "BeWo-2"],
      },
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    school_year_id: function (newVal, oldVal) {
      1;
      // console.log(newVal, oldVal);
      this.viewMySchoolChart = false;
      this.seriesPrimary[0] = [];
      this.seriesMiddle[0] = [];
      this.seriesHigh[0] = [];
      this.school_quarter_id = "";
      if (newVal != "") {
        axios
          .post("/curriculumadministrator/getAllSchoolYearGradeBehaviorData", {
            year: newVal,
          })
          .then((res) => {
            if (
              res.data.gradePrimary[0]["grade_behavior"] != null &&
              res.data.gradeMiddle[0]["grade_behavior"] != null &&
              res.data.gradeHigh[0]["grade_behavior"] != null
            ) {
              this.seriesPrimary[0] = parseFloat(
                res.data.gradePrimary[0]["grade_behavior"]
              ).toFixed(1);
              this.seriesMiddle[0] = parseFloat(
                res.data.gradeMiddle[0]["grade_behavior"]
              ).toFixed(1);
              this.seriesHigh[0] = parseFloat(
                res.data.gradeHigh[0]["grade_behavior"]
              ).toFixed(1);
              // console.log(res.data);
            } else {
              this.seriesPrimary[0] = 0;
              this.seriesMiddle[0] = 0;
              this.seriesHigh[0] = 0;
            }

            this.viewMySchoolChart = true;
          })
          .catch();
      }
    },
    school_quarter_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      this.viewMySchoolQuarterChart = false;
      this.seriesQuarterPrimary[0] = 0;
      this.seriesQuarterMiddle[0] = 0;
      this.seriesQuarterHigh[0] = 0;

      this.optionsClassPrimary.series[0].data = [];
      this.optionsClassMiddle.series[0].data = [];
      this.optionsClassHigh.series[0].data = [];

      this.school_primary_class_id = "";
      this.school_middle_class_id = "";
      this.school_high_class_id = "";

      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolQuarterGradeBehaviorData",
            {
              quarter: newVal,
              year: this.school_year_id,
            }
          )
          .then((res) => {
            //   console.log(res.data);
            if (
              res.data.gradePrimary[0]["grade_behavior"] != null &&
              res.data.gradeMiddle[0]["grade_behavior"] != null &&
              res.data.gradeHigh[0]["grade_behavior"] != null
            ) {
              this.seriesQuarterPrimary[0] = parseFloat(
                res.data.gradePrimary[0]["grade_behavior"]
              ).toFixed(1);
              this.seriesQuarterMiddle[0] = parseFloat(
                res.data.gradeMiddle[0]["grade_behavior"]
              ).toFixed(1);
              this.seriesQuarterHigh[0] = parseFloat(
                res.data.gradeHigh[0]["grade_behavior"]
              ).toFixed(1);
            } else {
              this.seriesQuarterPrimary[0] = 0;
              this.seriesQuarterMiddle[0] = 0;
              this.seriesQuarterHigh[0] = 0;
            }

            let sortGradeClassesPrimary = res.data.gradeClassesPrimary;
            sortGradeClassesPrimary.sort(
              (a, b) =>
                a.full_name - b.full_name ||
                a.full_name.localeCompare(b.full_name)
            );
            sortGradeClassesPrimary.forEach((element) => {
              this.optionsClassPrimary.series[0].data.push({
                x: element.full_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            let sortGradeClassesMiddle = res.data.gradeClassesMiddle;
            sortGradeClassesMiddle.sort(
              (a, b) =>
                a.full_name - b.full_name &&
                a.full_name.localeCompare(b.full_name)
            );
            sortGradeClassesMiddle.forEach((element) => {
              this.optionsClassMiddle.series[0].data.push({
                x: element.full_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            let sortGradeClassesHigh = res.data.gradeClassesHigh;
            sortGradeClassesHigh.sort(
              (a, b) =>
                a.full_name - b.full_name &&
                a.full_name.localeCompare(b.full_name)
            );
            sortGradeClassesHigh.forEach((element) => {
              this.optionsClassHigh.series[0].data.push({
                x: element.full_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });

            this.viewMySchoolQuarterChart = true;
          })
          .catch();
      }
    },
    school_primary_class_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      this.viewMySchoolPrimaryClassChart = false;
      this.optionsClassSubjectsPrimary.series[0].data = [];
      this.school_primary_classes_subject_id = "";
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolPClassGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              schoolPrimaryClass: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let sortGradeExamClassesPrimary =
              res.data.classPrimaryLessonsGrades;
            sortGradeExamClassesPrimary.sort(
              (a, b) =>
                a.subject_name - b.subject_name ||
                a.subject_name.localeCompare(b.subject_name)
            );
            sortGradeExamClassesPrimary.forEach((element) => {
              this.optionsClassSubjectsPrimary.series[0].data.push({
                x: element.subject_short_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            this.viewMySchoolPrimaryClassChart = true;
            this.getSchPriClassesSubjects = sortGradeExamClassesPrimary;
          })
          .catch();
      }
    },
    school_middle_class_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      this.viewMySchoolMiddleClassChart = false;
      this.optionsClassSubjectsMiddle.series[0].data = [];
      this.school_middle_classes_subject_id = "";
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolMClassGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              schoolMiddleClass: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let sortGradeExamClassesMiddle = res.data.classMiddleLessonsGrades;
            sortGradeExamClassesMiddle.sort(
              (a, b) =>
                a.subject_name - b.subject_name ||
                a.subject_name.localeCompare(b.subject_name)
            );
            sortGradeExamClassesMiddle.forEach((element) => {
              this.optionsClassSubjectsMiddle.series[0].data.push({
                x: element.subject_short_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });

            this.viewMySchoolMiddleClassChart = true;
            this.getSchMiddClassesSubjects = sortGradeExamClassesMiddle;
          })
          .catch();
      }
    },
    school_high_class_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      this.viewMySchoolHighClassChart = false;
      this.optionsClassSubjectsHigh.series[0].data = [];
      this.school_high_classes_subject_id = "";
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolHClassGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              schoolHighClass: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let sortGradeExamClassesHigh = res.data.classHighLessonsGrades;
            sortGradeExamClassesHigh.sort(
              (a, b) =>
                a.subject_name - b.subject_name ||
                a.subject_name.localeCompare(b.subject_name)
            );
            sortGradeExamClassesHigh.forEach((element) => {
              this.optionsClassSubjectsHigh.series[0].data.push({
                x: element.subject_short_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });

            this.viewMySchoolHighClassChart = true;
            this.getSchHighClassesSubjects = sortGradeExamClassesHigh;
          })
          .catch();
      }
    },
    school_primary_classes_subject_id: function (newVal, oldVal) {
      // console.log(newVal);
      this.viewMySchoolQuarterPriClassSubjectChart = false;
      this.optionsClassSubjectStudentsPrimary.series[0].data = [];

      //   Суралцагчийн сонголтын утгуудыг хоослох
      this.school_primary_classes_subject_student_id = "";
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolPClassSubjectGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              selectedSchoolPriClass: this.school_primary_class_id,
              selectedSchoolPriSubject: newVal,
            }
          )
          .then((res) => {
            //   console.log(res.data);
            let sortclassPriSubjectsGrades = res.data.classPriSubjectsGrades;
            sortclassPriSubjectsGrades.sort(
              (a, b) =>
                a.student_name - b.student_name ||
                a.student_name.localeCompare(b.student_name)
            );
            sortclassPriSubjectsGrades.forEach((element) => {
              this.optionsClassSubjectStudentsPrimary.series[0].data.push({
                x: element.student_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            this.getSchPriClassesSubjectsStudents = sortclassPriSubjectsGrades;
            this.viewMySchoolQuarterPriClassSubjectChart = true;
          })
          .catch();
      }
    },
    school_middle_classes_subject_id: function (newVal, oldVal) {
      // console.log(newVal);
      this.viewMySchoolQuarterMiddClassSubjectChart = false;
      this.optionsClassSubjectStudentsMiddle.series[0].data = [];

      //   Суралцагчийн сонголтыг хоослох
      this.school_middle_classes_subject_student_id = "";
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolMClassSubjectGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              selectedSchoolMiddClass: this.school_middle_class_id,
              selectedSchoolMidSubject: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let sortclassMiddSubjectsGrades = res.data.classMiddSubjectsGrades;
            sortclassMiddSubjectsGrades.sort(
              (a, b) =>
                a.student_name - b.student_name ||
                a.student_name.localeCompare(b.student_name)
            );
            sortclassMiddSubjectsGrades.forEach((element) => {
              this.optionsClassSubjectStudentsMiddle.series[0].data.push({
                x: element.student_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            this.getSchMiddClassesSubjectsStudents =
              sortclassMiddSubjectsGrades;
            this.viewMySchoolQuarterMiddClassSubjectChart = true;
          })
          .catch();
      }
    },
    school_high_classes_subject_id: function (newVal, oldVal) {
      // console.log(newVal);
      this.viewMySchoolQuarterHighClassSubjectChart = false;
      this.optionsClassSubjectStudentsHigh.series[0].data = [];
      this.school_high_classes_subject_student_id = "";

      //Суралцагчийн хэсгийг хоослох
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolHClassSubjectGradeBehaviorData",
            {
              year: this.school_year_id,
              quarter: this.school_quarter_id,
              selectedSchoolHighClass: this.school_high_class_id,
              selectedSchoolHighSubject: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let sortclassHighSubjectsGrades = res.data.classHighSubjectsGrades;
            sortclassHighSubjectsGrades.sort(
              (a, b) =>
                a.student_name - b.student_name ||
                a.student_name.localeCompare(b.student_name)
            );
            sortclassHighSubjectsGrades.forEach((element) => {
              this.optionsClassSubjectStudentsHigh.series[0].data.push({
                x: element.student_name,
                y: parseFloat(element.grade_behavior).toFixed(1),
              });
            });
            this.getSchHighClassesSubjectsStudents =
              sortclassHighSubjectsGrades;
            this.viewMySchoolQuarterHighClassSubjectChart = true;
          })
          .catch();
      }
    },

    school_primary_classes_subject_student_id: function (newVal, oldVal) {
      this.optionsStudentPri.seriesStudentPri = [];
      this.viewMySchoolQuarterPriClassSubjectStudentChart = false;
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolPClassSubjectStudentGradeBehaviorData",
            {
              year: this.school_year_id,
              // quarter: this.school_quarter_id,
              // selectedSchoolHighClass: this.school_high_class_id,
              // selectedSchoolHighSubject: this.school_primary_classes_subject_id,
              selectedSchoolPriSubjectStudent: newVal,
            }
          )
          .then((res) => {
            //   console.log(res.data);
            let students8StepsAverage = res.data.students8StepsAverage;
            this.seriesStudent = [];
            students8StepsAverage.forEach((element) => {
              this.optionsStudentPri.seriesStudentPri.push({
                name: element.student_last_name[0] + "." + element.student_name,
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            let students8StepsAverageCourse =
              res.data.students8StepsAverageCourse;
            students8StepsAverageCourse.forEach((element) => {
              this.optionsStudentPri.seriesStudentPri.push({
                name: "Ангийн дундаж",
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            this.viewMySchoolQuarterPriClassSubjectStudentChart = true;
          })
          .catch();
      }
    },
    school_middle_classes_subject_student_id: function (newVal, oldVal) {
      this.optionsStudentMidd.seriesStudentMidd = [];
      this.viewMySchoolQuarterMiddClassSubjectStudentChart = false;
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolMClassSubjectStudentGradeBehaviorData",
            {
              year: this.school_year_id,
              // quarter: this.school_quarter_id,
              // selectedSchoolHighClass: this.school_high_class_id,
              // selectedSchoolHighSubject: this.school_primary_classes_subject_id,
              selectedSchoolMiddSubjectStudent: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let students8StepsAverage = res.data.students8StepsAverage;
            students8StepsAverage.forEach((element) => {
              this.optionsStudentMidd.seriesStudentMidd.push({
                name: element.student_last_name[0] + "." + element.student_name,
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            let students8StepsAverageCourse =
              res.data.students8StepsAverageCourse;
            students8StepsAverageCourse.forEach((element) => {
              this.optionsStudentMidd.seriesStudentMidd.push({
                name: "Ангийн дундаж",
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            this.viewMySchoolQuarterMiddClassSubjectStudentChart = true;
          })
          .catch();
      }
    },
    school_high_classes_subject_student_id: function (newVal, oldVal) {
      this.optionsStudentHigh.seriesStudentHigh = [];
      this.viewMySchoolQuarterHighClassSubjectStudentChart = false;
      if (newVal != "") {
        axios
          .post(
            "/curriculumadministrator/getAllSchoolHClassSubjectStudentGradeBehaviorData",
            {
              year: this.school_year_id,
              // quarter: this.school_quarter_id,
              // selectedSchoolHighClass: this.school_high_class_id,
              // selectedSchoolHighSubject: this.school_primary_classes_subject_id,
              selectedSchoolHighSubjectStudent: newVal,
            }
          )
          .then((res) => {
            // console.log(res.data);
            let students8StepsAverage = res.data.students8StepsAverage;
            students8StepsAverage.forEach((element) => {
              this.optionsStudentHigh.seriesStudentHigh.push({
                name: element.student_last_name[0] + "." + element.student_name,
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            let students8StepsAverageCourse =
              res.data.students8StepsAverageCourse;
            students8StepsAverageCourse.forEach((element) => {
              this.optionsStudentHigh.seriesStudentHigh.push({
                name: "Ангийн дундаж",
                data: [
                  element.exam1,
                  element.exam2,
                  element.exam3,
                  element.exam4,
                  element.exam5,
                  element.exam6,
                ],
              });
            });
            this.viewMySchoolQuarterHighClassSubjectStudentChart = true;
          })
          .catch();
      }
    },
  },
  methods: {
    getAllSchoolFilterData() {
      axios
        .get("/curriculumadministrator/getAllFilterDataCurriAdmin")
        .then((res) => {
          // console.log(res.data);
          //хичээлийн жил
          this.years = res.data.years;
          this.quarters = res.data.quarters;
          this.departments = res.data.departments;
          this.schoolCLasses = res.data.schoolCLasses;
          this.teachers = res.data.teachers;
          this.getSchoolPrimaryClasses = res.data.getSchoolPrimaryClasses;
          this.getSchoolMiddleClasses = res.data.getSchoolMiddleClasses;
          this.getSchoolHighClasses = res.data.getSchoolHighClasses;
        })
        .catch();
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    customQuarterName({ name }) {
      return `${name} - р улирал`;
    },
    customClassName({ full_name }) {
      return `${full_name} анги`;
    },
    customSubjectName({ subject_short_name }) {
      return `${subject_short_name}`;
    },
    customStudentName({ student_last_name, student_name }) {
      return `${student_last_name[0]}.${student_name}`;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.getAllSchoolFilterData();
    //   this.viewMyChart = true;
    // });
  },
  created() {
    this.getAllSchoolFilterData();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
</style>
