<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5><b>Төсөв зарцуулалтын тайлан </b></h5>
                                <b-overlay :show="isLoad" rounded="sm">
                                    <table class="table table-bordered text-center">
                                        <tbody>
                                            <tr role="row">
                                                <th class="text-wrap">Төсвийн төрөл</th>
                                                <th class="text-wrap">Төсвийн тоо</th>
                                                <th class="text-wrap">Мөнгөн дүн</th>
                                            </tr>
                                            <tr>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    Бүртгэсэн төсөв
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.count_all }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.total !== undefined && allBudget.total !== null ?
                                                        allBudget.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                        '' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    Батлагдсан төсөв
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.count_confirmed }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.confirmed !== undefined && allBudget.confirmed !== null
                                                        ? allBudget.confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                            ",") : '' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    Зарцуулсан төсөв
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.count_zartsuulsan }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ allBudget.zartsuulsan !== undefined && allBudget.zartsuulsan !==
                                                        null ?
                                                        allBudget.zartsuulsan.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                            ",") : '' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h5><b>Батлагдсан төсөв </b></h5>
                                    <table class="table table-bordered text-center">
                                        <tbody>
                                            <tr role="row">
                                                <th class="text-wrap">Төрөл</th>
                                                <th class="text-wrap">Батлагдсан төсөв</th>
                                                <th class="text-wrap">Зарцуулсан төсөв</th>
                                                <th class="text-wrap">Зөрүү</th>
                                            </tr>
                                            <tr v-for="confirmedBudget in confirmedBudgets" :key="confirmedBudget.id">
                                                <th class="dtr-control sorting_1" tabindex="0">
                                                    {{ confirmedBudget.name }}
                                                </th>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ confirmedBudget.confirmed !== undefined &&
                                                        confirmedBudget.confirmed !== null ?
                                                        confirmedBudget.confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                            ",") : '' }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ confirmedBudget.zartsuulsan !== undefined &&
                                                        confirmedBudget.zartsuulsan !== null ?
                                                        confirmedBudget.zartsuulsan.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                            ",") : '' }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ confirmedBudget.zartsuulsan !== undefined &&
                                                        confirmedBudget.zartsuulsan !== null && confirmedBudget.confirmed
                                                        !== undefined && confirmedBudget.confirmed !== null ?
                                                        (confirmedBudget.confirmed -
                                                            confirmedBudget.zartsuulsan).toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                ",") : '' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            allBudget: [],
            confirmedBudgets: [],
            isLoad: true,
        };
    },
    methods: {
        reportBudgets() {
            this.isLoad = true;
            axios
                .post("/all_worker_functions/reportBudgets", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.allBudget = res.data.allBudget;
                    this.confirmedBudgets = res.data.confirmedBudgets;
                    this.isLoad = false;
                })
                .catch((err) => {
                    this.isLoad = false;
                });
        },
    },
    created() {
        this.reportBudgets();
    },
};
</script>
