<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">
              Хөтөлбөр хариуцсан менежерийн үнэлгээ хийх хуудас
            </h3>
            <b-button @click="createTWE" class="btn btn-primary"
              >Менежерийн ажлын үнэлгээ хийх</b-button
            >
          </div>
        </div>
        <div class="card-body">
          <b-container class="bv-example-row mb-3" fluid>
            <b-row>
              <b-col sm="3">
                <multiselect
                  v-model="school_year_id"
                  select-label="Сонгох"
                  selected-label="Сонгогдсон"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="id"
                  label="id"
                  :custom-label="customYearName"
                  placeholder="Хичээлийн жил сонгоно уу?"
                  :options="years"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.start_date | dateYear }}-{{
                        option.finish_date | dateYear
                      }}
                      - хичээлийн жил
                    </strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="3">
                <multiselect
                  v-model="filterDepartment"
                  :multiple="true"
                  deselect-label="Can't remove this value"
                  :custom-label="nameDepartment"
                  track-by="department_code"
                  label="department_code"
                  placeholder="Сонголт хийх"
                  :options="filterDepartments"
                  :searchable="true"
                  :allow-empty="true"
                  @select="toggleSelect"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.department_name }}</strong>
                    сонгогдлоо
                  </template>
                </multiselect>
              </b-col>
              <b-col sm="3">
                <multiselect
                  v-model="selectedFilterMonths"
                  :options="monthsRe"
                  :multiple="true"
                  track-by="name"
                  label="month"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Сар сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.month }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="2"
                ><b-button variant="success" @click.prevent="getAllData()"
                  >Шүүж харах</b-button
                >
              </b-col>
            </b-row>
          </b-container>
          <div class="table-responsive">
            <b-table
              striped
              bordered
              hover
              :items="managersEvaluations"
              :fields="fields"
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(name)="row">
                <span
                  class="badge badge-primary"
                  v-if="row.item.emp_teacher_last_name"
                >
                  {{ row.item.emp_teacher_last_name[0].toUpperCase() }}.
                  {{ row.item.emp_teacher_name }}
                </span>
              </template>
              <template #cell(position_name)="row">
                <span class="badge badge-primary">
                  <font face="Tahoma" size="0.6"
                    >{{ row.item.position_name }}
                  </font>
                </span>
              </template>
              <template #cell(isCheckResult)="row">
                <a
                  v-show="row.item.readManager === null"
                  href="#"
                  class="badge bg-warning"
                  v-b-popover.hover.top="
                    'Менежер сарын үнэлгээтэй танилцаагүй!'
                  "
                  title="Танилцаагүй"
                  ><i class="fas fa-check-circle"></i
                ></a>
                <span
                  class="badge bg-success"
                  v-show="row.item.readManager === 1"
                  v-b-popover.hover.top="'Менежер сарын үнэлгээтэй танилцсан!'"
                  title="Танилцсан"
                >
                  <i class="fas fa-check-double"></i>
                </span>
                <a
                  v-show="row.item.confirmed === null"
                  v-b-popover.hover.top="
                    'Менежер сарын үнэлгээтэй танилцсан бол баталгаажуулах!'
                  "
                  title="Баталгаажуулах"
                  href="#"
                  class="badge bg-warning"
                  @click="confirmedMWE(row.item)"
                >
                  <i class="fas fa-piggy-bank"></i>
                </a>
                <a
                  v-show="row.item.confirmed === 1"
                  v-b-popover.hover.top="
                    'Багш сарын үнэлгээг бүрэн баталгаажуулсан!'
                  "
                  title="Баталгаажсан"
                  href="#"
                  class="badge bg-success"
                >
                  <i class="fas fa-piggy-bank"></i>
                </a>
              </template>
              <template #cell(enroll_employee_info)="row">
                <span
                  class="badge badge-primary"
                  v-if="row.item.enroll_employee_info"
                >
                  {{
                    row.item.enroll_employee_info.teacher_last_name[0].toUpperCase()
                  }}.
                  {{ row.item.enroll_employee_info.teacher_name }}
                </span>
              </template>
              <template #cell(shal1)="row">
                <span class="badge badge-primary">
                  {{ row.item.shal1 }} * {{ row.item.shal1_jin_huvi }} =
                  {{
                    calculatePercentElement(
                      row.item.shal1,
                      row.item.shal1_jin_huvi
                    )
                  }}
                </span>
              </template>
              <template #cell(shal2)="row">
                <span class="badge badge-primary">
                  {{ row.item.shal2 }} * {{ row.item.shal2_jin_huvi }} =
                  {{
                    calculatePercentElement(
                      row.item.shal2,
                      row.item.shal2_jin_huvi
                    )
                  }}
                </span>
              </template>
              <template #cell(shal3)="row">
                <span class="badge badge-primary">
                  {{ row.item.shal3 }} * {{ row.item.shal3_jin_huvi }} =
                  {{
                    calculatePercentElement(
                      row.item.shal3,
                      row.item.shal3_jin_huvi
                    )
                  }}
                </span>
              </template>
              <template #cell(shal4)="row">
                <span class="badge badge-primary">
                  {{ row.item.shal4 }} * {{ row.item.shal4_jin_huvi }} =
                  {{
                    calculatePercentElement(
                      row.item.shal4,
                      row.item.shal4_jin_huvi
                    )
                  }}
                </span>
              </template>
              <template #cell(shal1-4)="row">
                <span class="badge badge-primary">
                  {{
                    calculateManagerOnlyPercent(
                      row.item.shal1,
                      row.item.shal1_jin_huvi,
                      row.item.shal2,
                      row.item.shal2_jin_huvi,
                      row.item.shal3,
                      row.item.shal3_jin_huvi,
                      row.item.shal4,
                      row.item.shal4_jin_huvi
                    )
                  }}
                  % /
                  {{
                    calculateManagerPercentJinHuvi(
                      row.item.shal1,
                      row.item.shal1_jin_huvi,
                      row.item.shal2,
                      row.item.shal2_jin_huvi,
                      row.item.shal3,
                      row.item.shal3_jin_huvi,
                      row.item.shal4,
                      row.item.shal4_jin_huvi
                    )
                  }}
                  %
                </span>
              </template>
              <template #cell(created_at)="row">
                {{ row.item.created_at | dateYearMonthDay }}
              </template>
              <template #cell(updated_at)="row">
                {{ row.item.updated_at | dateYearMonthDay }}
              </template>
              <template #cell(actions)="row">
                <a
                  href="#"
                  class="badge bg-warning"
                  v-show="
                    row.item.readManager === null && row.item.confirmed === null
                  "
                  @click="editManagersEvaluation(row.item)"
                  ><i class="fas fa-edit"></i
                ></a>
              </template>
            </b-table>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submit Your Name"
      size="xl"
      @show="resetModal"
      @hidden="cancelEdit"
      @ok="handleOk"
      hide-footer
    >
      <template v-slot:modal-title>
        <span v-show="!editMode"> Менежерийн ажлын үнэлгээ бүртгэх </span>
        <span v-show="editMode"> Менежерийн ажлын үнэлгээ засах </span>
      </template>
      <template>
        <form
          @submit.prevent="
            !editMode ? createManagerEvaluation() : updateManagerEvaluation()
          "
        >
          <table cellspacing="0" border="0">
            <tr>
              <td colspan="9">
                БАТЛАВ: Гүйцэтгэх захирал____________________/Э.Золбоо/
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td colspan="7" height="26" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    >Менежерийн ажлын гүйцэтгэлийг дүгнэх хуудас (KPI)
                  </font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"></font>
              </td>
            </tr>
            <tr></tr>
            <!-- Овог нэр -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Овог нэр: </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <!-- {{ editMode }} -->
                  <multiselect
                    v-model="form.employee_id"
                    deselect-label="Can't remove this value"
                    :custom-label="nameTeacher"
                    track-by="emp_user_id"
                    label="emp_user_id"
                    placeholder="Select one"
                    :options="allManagers"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <!-- @select="toggleSelect" -->
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                          option.emp_teacher_name
                        }}</strong
                      >
                      менежер сонгогдлоо
                    </template>
                  </multiselect>
                  <!-- <span v-if="!editMode">{{ form.nameEmpCheck }}</span> -->
                </font>
                <font v-if="editMode">{{ nameEmpCheck }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
            </tr>
            <!-- Албан тушаал -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Албан тушаал:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  {{ form.employee_id.position_name }}
                </font>
                <b v-if="editMode"> {{ form.employee_id.position_name }}</b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Үнэлгээ хийсэн хугацаа:</font
                >
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000">{{
                  timestamp
                }}</font>
              </td>
            </tr>
            <!-- Ажлын сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Үнэлгээ хийх сар сонгох:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <multiselect
                    v-model="form.ajliin_sar"
                    deselect-label="Can't remove this value"
                    :options="months"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Ажлын сар сонгоно уу?"
                  >
                  </multiselect>
                </font>
                <font v-if="editMode">{{ form.ajliin_sar }}</font>
              </td>
            </tr>

            <!-- Багш нарын баг ахалсан эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <b-form-checkbox
                  id="checkbox-bnb"
                  v-model="form.bnbMode"
                  name="checkbox-bnb"
                >
                  Мэргэжлийн баг ахалсан эсэх:
                </b-form-checkbox>
              </td>
              <td align="left" valign="middle" colspan="3" v-if="form.bnbMode">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input
                    v-model="form.shal8"
                    placeholder="Мэргэжлийн баг ахалсан ажлын үнэлгээ"
                    type="number"
                    :state="validationNumber8"
                  ></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle" v-if="form.bnbMode">
                <font face="Tahoma" size="3"></font>
              </td>
              <td
                align="center"
                valign="middle"
                colspan="4"
                v-if="form.bnbMode"
              >
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    ><b-form-textarea
                      id="textarea"
                      v-model="form.shal8_temd"
                      placeholder="Багш нарын баг ахалсан ажлын үнэлгээний тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea
                  ></font>
                </b>
              </td>
            </tr>
            <!-- Мэргэжлийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Мэргэжлийн зэрэгтэй эсэх:
                </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-select
                    v-model="form.shal9"
                    :options="options"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
            </tr>
            <!-- Эрдмийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Эрдмийн зэрэгтэй эсэх :</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox
                    id="checkbox-erdzer"
                    v-model="form.shal10"
                    name="checkbox-erdzer"
                    value="1"
                    unchecked-value="0"
                  >
                    Эрдмийн зэрэгтэй эсэх(Доктор)
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    ><b-form-textarea
                      id="textarea"
                      v-model="form.shal10_temd"
                      placeholder="Эрдмийн зэргийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea
                  ></font>
                </b>
              </td>
            </tr>
            <!-- Спортын зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Спортын зэрэгтэй эсэх:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox
                    id="checkbox-sport-mas"
                    v-model="form.shal17"
                    name="checkbox-sport-mas"
                    value="1"
                    unchecked-value="0"
                  >
                    Спортын мастер эсэх
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    ><b-form-textarea
                      id="textarea"
                      v-model="form.shal17_temd"
                      placeholder="Спортын мастерын зэргийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea
                  ></font>
                </b>
              </td>
            </tr>
            <!-- Ажилласан цаг -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Ажилласан цаг:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  {{ form.shal12 }} / {{ form.shal13 }} цаг
                  <!-- <b-form-input
                    v-model="form.shal12"
                    placeholder="Тухайн сард ажиласан цаг. 168-176 цаг байна."
                    type="number"
                    :state="validationNumber12"
                  ></b-form-input> -->
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    ><b-form-textarea
                      id="textarea"
                      v-model="form.shal12_temd"
                      placeholder="Тухайн сард ажилласан цагийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea
                  ></font>
                </b>
              </td>
            </tr>

            <!-- Илүү цаг сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Тухайн сард ажилласан илүү цаг:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input
                    v-model="form.shal7"
                    placeholder="Тухайн сард ажилласан илүү цаг"
                    type="number"
                    :state="validationNumber7"
                  ></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"
                    ><b-form-textarea
                      id="textarea"
                      v-model="form.shal7_temd"
                      placeholder="Тухайн сард илүү цаг ажилласан эсэхийг тайлбарласан"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea
                  ></font>
                </b>
              </td>
            </tr>
            <!-- зай авсан -->
            <tr></tr>
            <!-- Хүснэгтийн эхлэх -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                height="38"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2" color="#000000"
                    >Гүйцэтгэлийн шалгуур үзүүлэлтүүд</font
                  >
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2" color="#000000"
                    >Хэмжих нэгж</font
                  >
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2" color="#000000"
                    >Зорилтот үр дүн / Хэмжүүр</font
                  >
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2" color="#000000"
                    >Эх сурвалж</font
                  >
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                colspan="3"
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Үнэлгээ</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b>
                  <font face="Tahoma" color="#000000" size="1">Тайлбар</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b>
                  <font face="Tahoma" color="#000000" size="1">Шалтгаан</font>
                </b>
              </td>
            </tr>
            <br />
            <tr>
              <td
                style="
                  border-top: 0.1px solid #000000;
                  border-bottom: 0.1px solid #000000;
                  border-left: 0.1px solid #000000;
                  border-right: 0.1px solid #000000;
                "
                height="63"
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Ажлын гүйцэтгэлийг илэрхийлэх гол үзүүлэлтүүд
                  </font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1">Үндсэн цалингийн хувь </font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Тухайн үзүүлэлтийн хүрээнд тавигдаж буй зорилт
                  </font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Үнэлгээ хийх эрх зүйн баримт бичиг
                  </font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Эзлэх жин <br />(Ач холбогдол)</font
                  >
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                  padding: 50px;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1">Биелэлт</font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                  padding: 50px;
                "
                align="center"
                valign="middle"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Тооцох хувь <br />
                    0-0%, 0.1-10%, 0.9-90% гэм мэт..</font
                  >
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i>
                  <font face="Tahoma" size="1">Үнэлгээ хийсэн аргачлал </font>
                </i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i>
                  <font face="Tahoma" size="1"
                    >Биелэлт дутуу байгаа шалтгаан</font
                  >
                </i>
              </td>
            </tr>
            <!-- Шалгуур 1 -->
            <!-- Анхны утга нь сургалтын албаны менежер -->
            <tr v-if="form.employee_id.role_name == 'agerman'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын
                  гүйцэтгэл;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >ТҮБД 3.5 цалингийн сүлжээнд харгалзах цалингийн 60%-ийг 100%
                  гэж үзээд зорилтот үр дүнгийн үнэлгээ хэдэн хувь байна вэ
                  гэдгээр тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь
                  хамрагдсан байна. 2. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд
                  хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна.
                  3. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85%
                  байна.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <!-- step="any" -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Хичээл заалгадаг нийт хүүхдүүдийн 10% хүрэхгүй хэсэг нь
                  хамрагдсан бол 0%, 10-20% нь хамрагдсан бол 80%, 21-30% нь
                  хамрагдсан бол 90%, 31-40% нь хамрагдсан бол 95%, 40%-иас дээш
                  хувь нь хамрагдсан бол 100% гэж тус тус үнэлнэ. <br />
                  2. Хамрагдвал зохих нийт хүүхдүүдийн 80%-иас бага хүүхэд
                  тухайн соёл хүмүүжлийн арга хэмжээнд хамрагдсан бол 0%, 80% ба
                  түүнээс дээш хувь нь хамрагдсан бол гүйцэтгэлийн хувиар тус
                  тус тооцож үнэлнэ.<br />
                  3. СА-наас нийт хэдэн үүрэг даалгавар өгснөөс хэд нь биелснийг
                  хувилж тооцно.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agerman'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны чанар;
                </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байгаа эсэх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ 36% хүрэхгүй
                  бол 0%, 36% ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус
                  тооцно;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agerman'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн
                  явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байна; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Хөндлөнгийн шалгалтын дундаж үнэлгээ 30%-хүрэхгүй бол 0%, 30%
                  ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус тооцно;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agerman'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын талаарх хэрэглэгчдийн сэтгэл ханамж, санал гомдол
                  шийдвэрлэсэн байдал;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн
                  85%-иас дээш үзүүлэлттэй байна.<br />
                  2. Санал гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл 80%-иас
                  дээш үзүүлэлттэй байна.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Цахим систем; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн
                  80%-иас доош үзүүлэлттэй бол 0%, 80% ба түүнээс дээш
                  үзүүлэлттэй бол гүйцэтгэлийн хувиар тус тус тооцно. <br />
                  2. Санал, гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл
                  80%-иас доош үзүүлэлттэй бол 0%, 80% ба түүнээс дээш
                  үзүүлэлттэй бол гүйцэтгэлийн хувиар тус тус тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <!-- Хоёрдахь утга нь санхүүгийн албаны менежер -->
            <tr v-if="form.employee_id.role_name == 'finance'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Санхүү, татварын бүртгэл, тайлагнал, өдөр тутмын бүртгэлийн
                  үйл ажиллагаа;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"> % </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Аудитын сайн дүнгэлттэй байх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Тайлан, мэдээг хуулийн хугацаанд бэлтгэж тайлагнасан
                  байдал <br />
                  2. СТОУС-н дагуу бэлтгэгдэж аудитын тайлан зөв дүгнэлттэй
                  гарсан байх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах %</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, <br />
                  80-аас их бол хувилж тооцно.
                  <br />;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'finance'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Санхүү, төсвийн менежмент; </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">Гүйцэтгэл 100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >ТУЗ-аас батлагдсан төсвийн дагуу орлого, зарлагын бүрдүүлэлт,
                  зарцуулалт хийгдсэн байдал; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих %</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, <br />
                  80-аас их бол хувилж тооцно;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'finance'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Орлого бүрдүүлэлт, авлага цуглуулалт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">85% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Авлага барагдуулах арга хэмжээг тухай бүр авсан байх ба
                  авлагын тайлан; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, <br />
                  80-аас их бол хувилж тооцно;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'finance'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Үүрэг даалгавар, Ажлын төлөвлөгөөний биелэлт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар, ажлын
                  төлөвлөгөө; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих %</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, <br />
                  80-аас их бол хувилж тооцно;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <!-- Гуравдахь утга нь үйл ажиллагааны албаны менежер -->
            <tr v-if="form.employee_id.role_name == 'agermanvaa'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Барилга байгууламжийн аюулгүй байдал, хэвийн ажиллагаа,
                  засвар үйлчилгээ, тохижилтын чанарын үзүүлэлт (жинлэх хувь
                  20%);
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >ТҮБД 3.5 цалингийн сүлжээнд харгалзах цалингийн 60%-ийг 100%
                  гэж үзээд зорилтот үр дүнгийн үнэлгээ хэдэн хувь байна вэ
                  гэдгээр тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь
                  хамрагдсан байна. 2. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд
                  хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна.
                  3. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85%
                  байна.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Хичээл заалгадаг нийт хүүхдүүдийн 10% хүрэхгүй хэсэг нь
                  хамрагдсан бол 0%, 10-20% нь хамрагдсан бол 80%, 21-30% нь
                  хамрагдсан бол 90%, 31-40% нь хамрагдсан бол 95%, 40%-иас дээш
                  хувь нь хамрагдсан бол 100% гэж тус тус үнэлнэ. <br />
                  2. Хамрагдвал зохих нийт хүүхдүүдийн 80%-иас бага хүүхэд
                  тухайн соёл хүмүүжлийн арга хэмжээнд хамрагдсан бол 0%, 80% ба
                  түүнээс дээш хувь нь хамрагдсан бол гүйцэтгэлийн хувиар тус
                  тус тооцож үнэлнэ.<br />
                  3. СА-наас нийт хэдэн үүрэг даалгавар өгснөөс хэд нь биелснийг
                  хувилж тооцно.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermanvaa'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">
                  Байгууллагын орчны эрүүл ахуйн стандарт, норм норматив болон
                  Хөдөлмөрийн Аюулгүй Байдлыг хангаж ажилласан байдал (жинлэх
                  хувь 30%);
                </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байгаа эсэх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ 36% хүрэхгүй
                  бол 0%, 36% ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус
                  тооцно;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermanvaa'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">
                  Хоол үйлдвэрлэл, үйлчилгээний талаарх хэрэглэгч болон
                  сонирхогч талуудын сэтгэл ханамж (жинлэх хувь 30%);
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн
                  явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байна; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Хөндлөнгийн шалгалтын дундаж үнэлгээ 30%-хүрэхгүй бол 0%, 30%
                  ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус тооцно;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermanvaa'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">
                  1. Гэрээний менежмент(жинлэх хувь 10%); <br />
                  2. Үүрэг даалгавар(жинлэх хувь 5%); <br />
                  3. Ажлын төлөвлөгөөний биелэлт(жинлэх хувь 5%); <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн
                  85%-иас дээш үзүүлэлттэй байна.<br />
                  2. Санал гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл 80%-иас
                  дээш үзүүлэлттэй байна.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Цахим систем; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн
                  80%-иас доош үзүүлэлттэй бол 0%, 80% ба түүнээс дээш
                  үзүүлэлттэй бол гүйцэтгэлийн хувиар тус тус тооцно. <br />
                  2. Санал, гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл
                  80%-иас доош үзүүлэлттэй бол 0%, 80% ба түүнээс дээш
                  үзүүлэлттэй бол гүйцэтгэлийн хувиар тус тус тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <!-- Дөрөвдахь утга нь захиргаа, хүний нөөц -->
            <tr v-if="form.employee_id.role_name == 'hr'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Байгууллагын эрх зүйн орчин бүрдүүлэлт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">% </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"> 100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Эрх зүйн орчны SIPOC диаграмм; <br />
                  2. Тухайн сард боловсруулж батлуулахаар төлөвлөгдсөн бичиг
                  баримтуудын гүйцэтгэл болон хэрэгжилт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'hr'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Албан хэрэг хөтлөлт: <br />
                  1. Байгууллагын бичиг хэрэг хөтлөлтийг стандарт заавар журмын
                  дагуу хийж гүйцэтгэх;
                  <br />
                  2. Байгууллагын архивын үйл ажиллагааг хууль, тогтоомжийн
                  дагуу явуулах; <br />
                </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Бичиг хэрэг хөтлөлт стандартын дагуу гүйцэтгэл 100%, үнэн
                  зөв байх (20%) <br />
                  2. Архивын үйл ажиллагаа стандартын дагуу гүйцэтгэл 100% үнэн
                  зөв байх (10%)
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'hr'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Байгууллагын хүний нөөцтэй холбоотой үйл ажиллагаа;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Хүний нөөцийн хангалт, хөдөлмөрийн харилцаа хуулийн дагуу
                  байх, шинэ ажилтны чиглүүлэх хөтөлбөрийг боловсруулах, зохион
                  байгуулах гүйцэтгэл 100%, үнэн зөв байх;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'hr'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Үүрэг даалгавар; <br />
                  2. Ажлын төлөвлөгөөний биелэлт; <br />
                  3. Нийгмийн сүлжээ;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар гүйцэтгэл
                  100%, үнэн зөв байх (10%); <br />
                  2. Ажлын төлөвлөгөө гүйцэтгэл 100%, үнэн зөв байх(10%); <br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх гүйцэтгэл 100%,
                  үнэн зөв байх (10%);</font
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар; 2. Ажлын
                  төлөвлөгөө;<br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>

            <!-- Тавдахь утга нь спорт клуб -->
            <tr v-if="form.employee_id.role_name == 'supervisorshift'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Спорт клуб; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">% </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"> 100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Эрх зүйн орчны SIPOC диаграмм; <br />
                  2. Тухайн сард боловсруулж батлуулахаар төлөвлөгдсөн бичиг
                  баримтуудын гүйцэтгэл болон хэрэгжилт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'supervisorshift'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Спорт клуб; </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Бичиг хэрэг хөтлөлт стандартын дагуу гүйцэтгэл 100%, үнэн
                  зөв байх (20%) <br />
                  2. Архивын үйл ажиллагаа стандартын дагуу гүйцэтгэл 100% үнэн
                  зөв байх (10%)
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'supervisorshift'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Спорт клуб; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Хүний нөөцийн хангалт, хөдөлмөрийн харилцаа хуулийн дагуу
                  байх, шинэ ажилтны чиглүүлэх хөтөлбөрийг боловсруулах, зохион
                  байгуулах гүйцэтгэл 100%, үнэн зөв байх;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'supervisorshift'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Үүрэг даалгавар; <br />
                  2. Ажлын төлөвлөгөөний биелэлт; <br />
                  3. Нийгмийн сүлжээ;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар гүйцэтгэл
                  100%, үнэн зөв байх (10%); <br />
                  2. Ажлын төлөвлөгөө гүйцэтгэл 100%, үнэн зөв байх(10%); <br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх гүйцэтгэл 100%,
                  үнэн зөв байх (10%);</font
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар; 2. Ажлын
                  төлөвлөгөө;<br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <!-- Тавдахь утга нь спорт клуб -->
            <tr v-if="form.employee_id.role_name == 'agermankg'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Цэцэрлэг; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">% </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"> 100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Эрх зүйн орчны SIPOC диаграмм; <br />
                  2. Тухайн сард боловсруулж батлуулахаар төлөвлөгдсөн бичиг
                  баримтуудын гүйцэтгэл болон хэрэгжилт;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermankg'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Цэцэрлэг; </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Бичиг хэрэг хөтлөлт стандартын дагуу гүйцэтгэл 100%, үнэн
                  зөв байх (20%) <br />
                  2. Архивын үйл ажиллагаа стандартын дагуу гүйцэтгэл 100% үнэн
                  зөв байх (10%)
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation2_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermankg'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Цэцэрлэг; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Хүний нөөцийн хангалт, хөдөлмөрийн харилцаа хуулийн дагуу
                  байх, шинэ ажилтны чиглүүлэх хөтөлбөрийг боловсруулах, зохион
                  байгуулах гүйцэтгэл 100%, үнэн зөв байх;
                  <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation3_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >80-аас бага бол 0%, 80-аас их бол хувилж тооцно.;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr v-if="form.employee_id.role_name == 'agermankg'">
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Үүрэг даалгавар; <br />
                  2. Ажлын төлөвлөгөөний биелэлт; <br />
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">100% байх; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар гүйцэтгэл
                  100%, үнэн зөв байх (10%); <br />
                  2. Ажлын төлөвлөгөө гүйцэтгэл 100%, үнэн зөв байх(10%); <br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх гүйцэтгэл 100%,
                  үнэн зөв байх (10%);</font
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation4_0oosNeg"
                      min="0"
                      max="1"
                      step="any"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >1. Шууд удирдлагаас тухай бүр өгсөн үүрэг даалгавар; 2. Ажлын
                  төлөвлөгөө;<br />
                  3. Social хуудсанд хариу өгөх, постер бэлтгэх;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>

            <!-- Хөлний тооцоолол -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="17"
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma" size="1.2">Нийт</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <b>
                  <font face="Tahoma">15%<br /></font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">
                    {{ average() }}
                    %</font
                  >
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"> {{ calculatePercent() }}% <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8992"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma" size="1.2">
                    <!-- Баганаар тооцоолж гаргах% -->
                  </font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Суралцагчдын амралтын хугацааны үр дүнгийн урамшууллыг хасаж
                  тооцно. <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr></tr>
            <!-- Зай авсан -->
            <tr></tr>
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Ажилтан:</font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" v-if="form.employee_id"
                  >{{ form.employee_id.emp_teacher_last_name[0] }}.{{
                    form.employee_id.emp_teacher_name
                  }}</font
                >
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr></tr>
            <!-- Шууд удирдлага -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Шууд удирдлага:</font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma">/................/</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr></tr>
            <tr></tr>
            <!-- Огноо -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Огноо:</font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td
                align="center"
                valign="middle"
                sdval="44164"
                sdnum="1033;0;M/D/YYYY"
              >
                <font face="Tahoma">{{ timestamp }}</font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
          </table>

          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="cancelEdit"
            >Цуцлах</b-button
          >
          <b-button type="submit" class="mt-2" variant="outline-warning" block
            >Хадгалах</b-button
          >
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      school_year_id: "",
      years: [],
      filterDepartments: [],
      filterDepartment: null,
      selectedFilterMonths: null,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      action: "",
      editMode: false,
      //   keyword: "",
      managersEvaluations: [],
      allManagers: [],
      //   positions: [],
      nameEmpCheck: "",
      namePositionNameCheck: "",
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        enrol_emp_id: "",
        department_id: "",
        employee_id: "",
        bnbMode: false,
        position_id: "",
        ajliin_sar: "",
        readTeach: "",
        shal1: "",
        shal1_jin_huvi: 0.2,
        shal1_temd: "",
        shal2: "",
        shal2_jin_huvi: 0.3,
        shal2_temd: "",
        shal3: "",
        shal3_jin_huvi: 0.3,
        shal3_temd: "",
        shal4: "",
        shal4_jin_huvi: 0.2,
        shal4_temd: "",
        shal7: "",
        shal7_jin_huvi: 1,
        shal7_temd: "",
        shal8: "",
        shal8_jin_huvi: 1,
        shal8_temd: "",
        shal9: "",
        shal9_jin_huvi: 1,
        shal9_temd: "",
        shal10: "",
        shal10_jin_huvi: 1,
        shal10_temd: "",
        shal11: "",
        shal11_jin_huvi: 1,
        shal11_temd: "",
        shal12: "",
        shal12_jin_huvi: 1,
        shal12_temd: "",
        shal13: "",
        shal13_jin_huvi: 1,
        shal13_temd: "",
        shal14: "",
        shal14_jin_huvi: 1,
        shal14_temd: "",
        shal15: "",
        shal15_jin_huvi: 1,
        shal15_temd: "",
        shal16: "",
        shal16_jin_huvi: 1,
        shal16_temd: "",
        shal17: "",
        shal17_jin_huvi: 1,
        shal17_temd: "",
        shal18: "",
        shal18_jin_huvi: 1,
        shal18_temd: "",
      }),
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      options: [
        { value: null, text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: 0, text: "Зэрэггүй" },
        { value: 1, text: "Заах аргач" },
        { value: 2, text: "Тэргүүлэх" },
        { value: 3, text: "Зөвлөх" },
        { value: 4, text: "Ахлах нийгмийн ажилтан" },
      ],
      timestamp: "",

      myDownloadData: [],
      selectedMonths: [],
      resultMy: [],

      json_fields: {
        "Complete name": "name",
        City: "city",
        Telephone: "phone.mobile",
        "Telephone 2": {
          field: "phone.landline",
          callback: (value) => {
            return `Landline Phone - ${value}`;
          },
        },
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Овог Нэр",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Имэйл хаяг",
          sortable: true,
        },
        {
          key: "position_name",
          label: "Албан тушаал",
          sortable: true,
        },
        {
          key: "ajliin_sar",
          label: "Сар",
          sortable: true,
        },
        {
          key: "isCheckResult",
          label: "Дүнтэй танилцсан",
          sortable: true,
        },
        {
          key: "enroll_employee_info",
          label: "Үнэлгээ хийсэн",
          sortable: true,
        },
        {
          key: "shal1",
          label: "Ш-1",
          sortable: true,
        },
        {
          key: "shal2",
          label: "Ш-2",
          sortable: true,
        },
        {
          key: "shal3",
          label: "Ш-3",
          sortable: true,
        },
        {
          key: "shal4",
          label: "Ш-4",
          sortable: true,
        },
        {
          key: "shal1-4",
          label: "Ш1-4",
          sortable: true,
        },
        {
          key: "shal5",
          label: "Ш-5",
          sortable: true,
        },
        {
          key: "shal6",
          label: "Ш-6",
          sortable: true,
        },
        {
          key: "shal7",
          label: "Ш-7",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    selectedMonths(newVal, oldVal) {
      //   console.log("шинэ" + newVal, "Хуучин" + oldVal);
      this.resultMy = [];
    },
  },
  components: {
    Multiselect,
    // searchManager: searchManager,\
  },
  methods: {
    getYearList() {
      axios
        .get("/manager/getYearList")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    nameDepartment({ department_code }) {
      return `${department_code}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getAllData() {
      axios
        .post("/all_worker_functions/getAllManagersEvaluations", {
          yearId: this.school_year_id.id,
          filterDepartment: this.filterDepartment,
          selectedMonths: this.selectedFilterMonths,
        })
        .then((response) => {
          //   console.log(response.data);
          this.managersEvaluations = response.data.allManagersEvaluations;
          this.allManagers = response.data.managers;
          this.filterDepartments = response.data.managerDepartments;
          //   this.positions = response.data.managerPositionsName;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editManagersEvaluation(managersEvaluation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", managersEvaluation);

      this.form.reset();
      //   this.form.fill(managersEvaluation);

      this.$nextTick(() => {
        // console.log("nextTick");
        this.editMode = true;
        this.nameEmpCheck =
          managersEvaluation.emp_teacher_last_name[0].toUpperCase() +
          "." +
          managersEvaluation.emp_teacher_name;
        this.namePositionNameCheck = managersEvaluation.position_name;
        this.form.id = managersEvaluation.id;
        this.form.employee_id = managersEvaluation;
        this.form.ajliin_sar = managersEvaluation.ajliin_sar;
        this.form.shal1 = managersEvaluation.shal1;
        this.form.shal1_jin_huvi = managersEvaluation.shal1_jin_huvi;
        this.form.shal1_temd = managersEvaluation.shal1_temd;
        this.form.shal2 = managersEvaluation.shal2;
        this.form.shal2_jin_huvi = managersEvaluation.shal2_jin_huvi;
        this.form.shal2_temd = managersEvaluation.shal2_temd;
        this.form.shal3 = managersEvaluation.shal3;
        this.form.shal3_jin_huvi = managersEvaluation.shal3_jin_huvi;
        this.form.shal3_temd = managersEvaluation.shal3_temd;
        this.form.shal4 = managersEvaluation.shal4;
        this.form.shal4_jin_huvi = managersEvaluation.shal4_jin_huvi;
        this.form.shal4_temd = managersEvaluation.shal4_temd;
        this.form.shal7 = managersEvaluation.shal7;
        this.form.shal7_temd = managersEvaluation.shal7_temd;

        this.form.bnbMode = managersEvaluation.shal8 ? true : false;
        this.form.shal8 = managersEvaluation.shal8;
        this.form.shal8_temd = managersEvaluation.shal8_temd;
        // console.log(parseInt(managersEvaluation.shal9));
        if (parseInt(managersEvaluation.shal9) === 0) {
          this.form.shal9 = 0;
        } else if (parseInt(managersEvaluation.shal9) === 1) {
          this.form.shal9 = 1;
        } else if (parseInt(managersEvaluation.shal9) === 2) {
          this.form.shal9 = 2;
        } else if (parseInt(managersEvaluation.shal9) === 3) {
          this.form.shal9 = 3;
        } else {
          this.form.shal9 = 0;
        }
        // this.form.shal9 = managersEvaluation.shal9;
        this.form.shal9_temd = managersEvaluation.shal9_temd;
        this.form.shal10 = parseInt(managersEvaluation.shal10);
        this.form.shal10_temd = managersEvaluation.shal10_temd;
        // this.form.shal11 = parseInt(managersEvaluation.shal11);
        // this.form.shal11_temd = managersEvaluation.shal11_temd;
        this.form.shal12 = parseInt(managersEvaluation.shal12);
        this.form.shal12_temd = managersEvaluation.shal12_temd;
        this.form.shal13 = parseInt(managersEvaluation.shal13);
        this.form.shal13_temd = managersEvaluation.shal13_temd;
        this.form.shal17 = parseInt(managersEvaluation.shal17);
        this.form.shal17_temd = managersEvaluation.shal17_temd;
        this.$bvModal.show("modal-prevent-closing");
        this.$bvModal.show("modal-prevent-closing");
      });
    },
    updateManagerEvaluation() {
      //   this.action = "Бүртгэлийн ажилтны мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateManagerEvaluation/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolManagers");
          this.form.reset();
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      //   console.log("Хаалаа");
      this.editMode = false;
      this.form.reset();
      this.nameEmpCheck = "";
      this.namePositionNameCheck = "";
      this.$bvModal.hide("modal-prevent-closing");
    },
    // deleteSchoolEmployee(schoolteacher) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
    createTWE() {
      this.form.reset();
      this.$bvModal.show("modal-prevent-closing");
    },
    createManagerEvaluation() {
      this.action = "Менежерийн ажлын үнэлгээг хийж байна...";
      this.form
        .post("/all_worker_functions/createManagerEvaluation")
        .then((response) => {
          this.$toastr.s("Менежерийн үнэлгээг хийлээ.", "Амжилттай");
          Fire.$emit("loadSchoolManagers");
          this.form.reset();
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch(() => {
          // console.log(errors.all());
          this.$toastr.e(
            "Менежерийн үнэлгээг бүртгэж чадсангүй, Нэг багшид сарын үнэлгээг давхцуулж оруулах боломжгүй, Менежерийн нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
            "Алдаа гарлаа."
          );
        });
    },
    confirmedMWE(managerEvaluation) {
      //   console.log(managerEvaluation);
      Swal.fire({
        title: "Менежер ажлын үнэлгээтэй танилцсан эсэх?",
        text: "Менежер таны үнэлгээтэй танилцсан бол үнэлгээг баталгаажуулна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/all_worker_functions/confirmMWE", {
              id: managerEvaluation.id,
              confirmed: true,
            })
            .then((response) => {
              this.$toastr.s("Амжилттай баталгаажууллаа.", "Амжилттай");
              Fire.$emit("loadSchoolManagers");
            })
            .catch(() => {
              this.$toastr.e(
                "Менежерийн үнэлгээг бүртгэж чадсангүй, Нэг менежерт сарын үнэлгээг давхцуулж оруулах боломжгүй, Менежерийн нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    toggleSelect() {
      //   console.log("toggleSelect");
    },
    average() {
      return (
        parseFloat(this.form.shal1) * parseFloat(this.form.shal1_jin_huvi) +
        parseFloat(this.form.shal2) * parseFloat(this.form.shal2_jin_huvi) +
        parseFloat(this.form.shal3) * parseFloat(this.form.shal3_jin_huvi) +
        parseFloat(this.form.shal4) * parseFloat(this.form.shal4_jin_huvi)
      );
    },
    calculatePercent() {
      let averagePer =
        parseFloat(this.form.shal1) * parseFloat(this.form.shal1_jin_huvi) +
        parseFloat(this.form.shal2) * parseFloat(this.form.shal2_jin_huvi) +
        parseFloat(this.form.shal3) * parseFloat(this.form.shal3_jin_huvi) +
        parseFloat(this.form.shal4) * parseFloat(this.form.shal4_jin_huvi);
      let myPercent = (averagePer * 15) / 100;
      return myPercent.toFixed(1);
    },
    calculatePercentElement(shal, shal_jin_huvi) {
      let averagePer = parseFloat(shal) * parseFloat(shal_jin_huvi);
      return averagePer.toFixed(1);
    },

    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },

    //Dynamic table generate
    buildTableBody(data, columns) {
      var body = [];

      body.push(columns);

      data.forEach(function (row) {
        var dataRow = [];

        columns.forEach(function (column) {
          dataRow.push(row[column].toString());
        });

        body.push(dataRow);
      });

      return body;
    },

    table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: this.buildTableBody(data, columns),
        },
      };
    },

    calculateManagerPercent(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        (parseFloat(shal1) +
          parseFloat(shal2) +
          parseFloat(shal3) +
          parseFloat(shal4)) /
        4;
      let myManagerPercent = (averageMyPer * 15) / 100;
      return myManagerPercent;
    },
    calculateManagerOnlyPercent(
      shal1,
      shal1_jin_huvi,
      shal2,
      shal2_jin_huvi,
      shal3,
      shal3_jin_huvi,
      shal4,
      shal4_jin_huvi
    ) {
      let averageMyPer =
        parseFloat(shal1) * parseFloat(shal1_jin_huvi) +
        parseFloat(shal2) * parseFloat(shal2_jin_huvi) +
        parseFloat(shal3) * parseFloat(shal3_jin_huvi) +
        parseFloat(shal4) * parseFloat(shal4_jin_huvi);
      return averageMyPer.toFixed(1);
    },
    calculateManagerPercentJinHuvi(
      shal1,
      shal1_jin_huvi,
      shal2,
      shal2_jin_huvi,
      shal3,
      shal3_jin_huvi,
      shal4,
      shal4_jin_huvi
    ) {
      let averageMyPer =
        parseFloat(shal1) * parseFloat(shal1_jin_huvi) +
        parseFloat(shal2) * parseFloat(shal2_jin_huvi) +
        parseFloat(shal3) * parseFloat(shal3_jin_huvi) +
        parseFloat(shal4) * parseFloat(shal4_jin_huvi);
      let myManagerPercent = (averageMyPer * 15) / 100;
      return myManagerPercent.toFixed(1);
    },

    //Сараар шүүж авах

    firstListen() {
      axios
        .post("/all_worker_functions/myManagersDataConcate", {
          ajliin_sar: this.selectedMonths,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch();

      this.selectedMonths.forEach((month) => {
        let bigCities = this.managersEvaluations.filter(function (e) {
          return parseInt(e.ajliin_sar) == parseInt(month.name);
        });
        // console.log(bigCities);
        bigCities.forEach((element) => {
          this.resultMy.push(element);
        });
        // this.result = [...bigCities];
      });
      //   console.log(this.resultMy);
      this.managersEvaluationsDownload(this.resultMy);
    },

    //Менежерийн ажлын үнэлгээг татах функцүүд

    managersEvaluationsDownload(myDatas) {
      //   this.myDownloadData = [...this.managersEvaluations];
      //   console.log(this.myDownloadData);
      //   console.log(myDatas);
      var myArr = myDatas,
        result = Object.values(
          myArr.reduce((r, o) => {
            r[o.emp_id] = r[o.emp_id] || {
              ...o,
              // emp_id: o.emp_id,
              // ajliin_sar: o.ajliin_sar,
              // emp_teacher_last_name: o.emp_teacher_last_name,
              // emp_teacher_name: o.emp_teacher_name,
              evaluations: [],
            };
            r[o.emp_id]["evaluations"].push({
              [o.ajliin_sar]: {
                ajliin_sar: o.ajliin_sar,
                shal1: o.shal1,
                shal2: o.shal2,
                shal3: o.shal3,
                shal4: o.shal4,
                percent: this.calculateManagerPercent(
                  o.shal1,
                  o.shal2,
                  o.shal3,
                  o.shal4
                ),
              },

              //   [o.shal1]: { shal1: o.shal1 },
            });
            return r;
          }, {})
        );

      //   console.log("myArr", myArr, result);
      this.json_data = result;
    },
  },
  computed: {
    shal1_jin_huviCalculate() {
      return this.form.shal1 * this.form.shal1_jin_huvi;
    },
    shal2_jin_huviCalculate() {
      return this.form.shal2 * this.form.shal2_jin_huvi;
    },
    shal3_jin_huviCalculate() {
      return this.form.shal3 * this.form.shal3_jin_huvi;
    },
    shal4_jin_huviCalculate() {
      return this.form.shal4 * this.form.shal4_jin_huvi;
    },
    validation1_0oosNeg() {
      return this.form.shal1_jin_huvi >= 0 && this.form.shal1_jin_huvi <= 1;
    },
    validation2_0oosNeg() {
      return this.form.shal2_jin_huvi >= 0 && this.form.shal2_jin_huvi <= 1;
    },
    validation3_0oosNeg() {
      return this.form.shal3_jin_huvi >= 0 && this.form.shal3_jin_huvi <= 1;
    },
    validation4_0oosNeg() {
      return this.form.shal4_jin_huvi >= 0 && this.form.shal4_jin_huvi <= 1;
    },
    validationNumber1() {
      return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    },
    validationNumber2() {
      return this.form.shal2 >= 0 && this.form.shal2 <= 100;
    },
    validationNumber3() {
      return this.form.shal3 >= 0 && this.form.shal3 <= 100;
    },
    validationNumber4() {
      return this.form.shal4 >= 0 && this.form.shal4 <= 100;
    },
    validationNumber7() {
      return this.form.shal7 >= 0 && this.form.shal7 <= 100;
    },
    validationNumber8() {
      return this.form.shal8 >= 0 && this.form.shal8 <= 100;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  created() {
    this.getYearList();
    this.getAllData();
    Fire.$on("loadSchoolManagers", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table {
  min-height: 300px;
}
</style>
