<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5><b>Сургалтын үйл ажиллагааны цогц байдал</b></h5>
                                <table class="table table-bordered text-center">
                                    <tbody>
                                        <tr role="row">
                                            <th>Сургалтын үйл ажиллагааны хэлбэр</th>
                                            <th>Нийт бүлгийн тоо</th>
                                            <th>Хамрагдвал зохих нийт хүүхдийн тоо</th>
                                            <th>Хамрагдсан нийт хүүхдийн тоо</th>
                                            <th>Боловсролын үйлчилгээний хүртээмж</th>
                                            <th>Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс</th>
                                        </tr>
                                        <tr>
                                            <td>Хичээл</td>
                                            <td>{{ myClass }}</td>
                                            <td>{{ studCount }}</td>
                                            <td>{{ myStudents }}</td>
                                            <td>{{ lessonAccess }}%</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Хөгжүүлэх ажил</td>
                                            <td>{{ allProject[0] }}</td>
                                            <td>{{ allProject[1] }}</td>
                                            <td>{{ allProject[2] }}</td>
                                            <td>{{ allProject[3].toFixed(2) }}%</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Соёл хүмүүжлийн ажил</td>
                                            <td>{{ projectCxa[0] }}</td>
                                            <td>{{ projectCxa[1] }}</td>
                                            <td>{{ projectCxa[2] }}</td>
                                            <td v-if="projectCxa[3]">{{ projectCxa[3].toFixed(2) }}%</td>
                                            <td v-else="projectCxa[3]==null">0</td>
                                        </tr>

                                        <tr>
                                            <td>Дундаж үзүүлэлт</td>
                                            <td>
                                                {{ ((myClass + allProject[0] + projectCxa[0]) / 3).toFixed(2) }}
                                            </td>
                                            <td>
                                                {{ ((studCount + allProject[1] + projectCxa[1]) / 3).toFixed(2) }}
                                            </td>
                                            <td>
                                                {{
                                                    ((myStudents + allProject[2] + projectCxa[2]) / 3).toFixed(2)
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    ((myStudents + allProject[2] + projectCxa[2]) / (studCount +
                                                        allProject[1] + projectCxa[1]) * 100
                                                    ).toFixed(2)
                                                }}%
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            myStudents: [],
            studCount: [],
            myClass: [],
            myGrades: [],
            project: [],
            projectsCXA: [],
            projectScore: [],
            classAvg: [],
            studLimitAvg: [],
            studRegAvg: [],
            studExamAvg: [],
            lessonAccess: [],
            projectAccess: [],
            editMode: false,
            seriesStat: [
                {
                    name: "Хичээл",
                    data: [9, 191, 193, 101],
                },
                {
                    name: "Хөгжүүлэх ажил",
                    data: [7, 15, 12, 80],
                },
                {
                    name: "Соёл хүмүүжлийн ажил",
                    data: [8, 103, 102, 99],
                },
            ],
            optionsStat: {
                chart: {
                    height: 350,
                    type: "radar",
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1,
                    },
                },
                stroke: {
                    width: 2,
                },
                fill: {
                    opacity: 0.1,
                },
                markers: {
                    size: 0,
                },
                xaxis: {
                    categories: [
                        "Нийт бүлгийн тоо",
                        "Хамрагдвал зохих нийт хүүхдийн тоо",
                        "Хамрагдсан нийт хүүхдийн тоо",
                        "Боловсролын үйлчилгээний хүртээмж ",
                    ],
                },
            },
        };
    },
    computed: {
        allProject() {
            let project = [];
            let projectClass = [];
            let projectStudCount = [];
            let projectMyStud = [];
            this.project.forEach((element) => {
                projectClass.push(parseFloat(element.classCount));
                projectStudCount.push(parseFloat(element.limit));
                projectMyStud.push(parseFloat(element.studCount));
            });
            const classes = projectClass.reduce((a, b) => a + b, 0);
            const studLimit = projectStudCount.reduce((a, b) => a + b, 0);
            const studReg = projectMyStud.reduce((a, b) => a + b, 0);
            const access = (studReg / studLimit) * 100;
            project.push(classes, studLimit, studReg, access);
            return project;
        },
        projectCxa() {
            let project = [];
            let projectStudLimit = [];
            let projectStudReg = [];
            this.projectsCXA.forEach((element) => {
                projectStudLimit.push(parseFloat(element.limit));
                projectStudReg.push(parseFloat(element.registered));
            });
            const count = this.projectsCXA.length;
            const studLimit = projectStudLimit.reduce((a, b) => a + b, 0);
            const studReg = projectStudReg.reduce((a, b) => a + b, 0);
            const access = (studReg / studLimit) * 100;
            project.push(count, studLimit, studReg, access);
            return project;
        },
    },
    methods: {
        getStat4() {
            axios
                .post("/teacher/getStat4", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.myStudents = res.data.myStudents;
                    this.studCount = res.data.studCount;
                    this.myClass = res.data.myClass;
                    this.myGrades = res.data.myGrades;
                    this.project = res.data.project;
                    this.projectScore = res.data.projectScore;
                    this.projectsCXA = res.data.projectsCXA;
                    this.studExamAvg = parseFloat(
                        (this.studRegAvg / this.studLimitAvg) * 100
                    ).toFixed(2);
                    this.lessonAccess = parseFloat(
                        (this.myStudents / this.studCount) * 100
                    ).toFixed(2);
                    this.seriesStat[0].data = res.data.myStudents;
                    this.seriesStat[1].data = res.data.studCount;
                    this.seriesStat[2].data = res.data.myClass;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getStat4();
    },
};
</script>
