<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5>
                                    <b>СУРГАЛТЫН ЭЭЛЖИТ ҮЙЛ АЖИЛЛАГААНД ҮНЭЛГЭЭ ШИНЖИЛГЭЭ ХИЙХ АРГАЧЛАЛ
                                        (БАГШИЙН ҮНЭЛГЭЭ)</b>
                                </h5>
                                <table class="table table-bordered text-center">
                                    <tbody>
                                        <tr role="row">
                                            <th rowspan="3">Үзүүлэлтүүд</th>
                                            <th colspan="10">
                                                Сургалтын ээлжит үйл ажиллагааг үнэлэх шалгуур үзүүлэлтүүд
                                            </th>
                                        </tr>
                                        <tr role="row">
                                            <th colspan="3">Эхлэл</th>
                                            <th colspan="5">Өрнөл</th>
                                            <th colspan="2">Төгсгөл</th>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>5</td>
                                            <td>6</td>
                                            <td>7</td>
                                            <td>8</td>
                                            <td>9</td>
                                            <td>10</td>
                                        </tr>
                                        <tr role="row" v-if="depEval">
                                            <th>
                                                Сургалтын ээлжит үйл ажиллагааны шалгуур үзүүлэлтүүдийн үнэлгээ
                                                (БНБ -ын дундаж)
                                            </th>
                                            <td>
                                                {{ depEval.shalles1 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles2 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles3 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles4 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles5 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles6 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles7 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles8 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles9 }}
                                            </td>
                                            <td>
                                                {{ depEval.shalles10 }}
                                            </td>
                                        </tr>
                                        <tr role="row" v-if="eval">
                                            <th>Багшийн ерөнхий үнэлгээ</th>
                                            <td>
                                                {{ eval.shalles1 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles2 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles3 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles4 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles5 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles6 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles7 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles8 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles9 }}
                                            </td>
                                            <td>
                                                {{ eval.shalles10 }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- /.card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            eval: null,
            depEval: null,
            value: null,
        };
    },
    methods: {
        getStat13() {
            axios
                .post("/teacher/getStat13", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.eval = res.data.teacherEvaluation;
                    this.depEval = res.data.depEvaluation;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getStat13();
    },
};
</script>
