<template>
  <div>
    <cultural class="pt-3"></cultural>
    <stat-stud class="pt-3" v-if="userInfo.name == 'consultant'"></stat-stud>
  </div>
</template>
<script>
import cultural from "../Project/CulturalCalendar.vue";
import statStud from "../Manager/Statistic/StatisticStudents.vue";
export default {
  data() {
    return {};
  },
  components: {
    cultural: cultural,
    statStud,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped></style>
