<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">Ажилчдын үнэлгээ хийх хуудас</h3>
            <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
            <!-- Button trigger modal -->
            <b-button @click="createTWE" class="btn btn-primary"
              >Ажилчдын ажлын үнэлгээ хийх</b-button
            >
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-container class="bv-example-row mb-3" fluid>
            <b-row>
              <b-col sm="3">
                <multiselect
                  v-model="selectedYear"
                  select-label="Сонгох"
                  selected-label="Сонгогдсон"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="id"
                  label="id"
                  :custom-label="customYearName"
                  placeholder="Хичээлийн жил сонгоно уу?"
                  :options="years"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.start_date | dateYear }}-{{
                        option.finish_date | dateYear
                      }}
                      - хичээлийн жил
                    </strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="3"
                ><multiselect
                  v-model="selectedMonths"
                  :options="monthsRe"
                  :multiple="true"
                  track-by="name"
                  label="month"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Сар сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.month }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="2"
                ><b-button variant="success" @click.prevent="getAllData()"
                  >Шүүж харах</b-button
                >
              </b-col>
            </b-row>
          </b-container>
          <b-table
            striped
            bordered
            hover
            :items="employeesEvaluations"
            :fields="fields"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(name)="row">
              <span
                class="badge badge-primary"
                v-if="row.item.emp_teacher_last_name"
              >
                {{ row.item.emp_teacher_last_name[0].toUpperCase() }}.
                {{ row.item.emp_teacher_name }}
              </span>
            </template>
            <template #cell(isCheckResult)="row">
              <a
                v-show="row.item.readEmployee === null"
                href="#"
                class="badge bg-warning"
                ><i class="fas fa-check-circle"></i
              ></a>
              <span
                class="badge bg-success"
                v-show="row.item.readEmployee === 1"
              >
                <i class="fas fa-check-double"></i>
              </span>
              <a
                v-show="row.item.confirmed === null"
                href="#"
                class="badge bg-warning"
                @click="confirmedEWE(row.item)"
              >
                <i class="fas fa-piggy-bank"></i>
              </a>
              <a
                v-show="row.item.confirmed === 1"
                href="#"
                class="badge bg-success"
              >
                <i class="fas fa-piggy-bank"></i>
              </a>
            </template>
            <template #cell(enr_emp_teacher_name)="row">
              <span
                class="badge badge-light"
                v-if="row.item.enroll_emp.teacher_last_name"
              >
                {{ row.item.enroll_emp.teacher_last_name[0].toUpperCase() }}.
                {{ row.item.enroll_emp.teacher_name }}
              </span>
            </template>
            <template #cell(shal1)="row">
              <span class="badge badge-primary">
                {{ row.item.shal1 }} * {{ row.item.shal1_jin_huvi }} =
                {{ row.item.shal1 * row.item.shal1_jin_huvi }}
              </span>
            </template>
            <template #cell(shal2)="row">
              <span class="badge badge-primary">
                {{ row.item.shal2 }} * {{ row.item.shal2_jin_huvi }} =
                {{ row.item.shal2 * row.item.shal2_jin_huvi }}
              </span>
            </template>
            <template #cell(shal3)="row">
              <span class="badge badge-primary">
                {{ row.item.shal3 }} * {{ row.item.shal3_jin_huvi }} =
                {{ row.item.shal3 * row.item.shal3_jin_huvi }}
              </span>
            </template>
            <template #cell(shal4)="row">
              <span class="badge badge-primary">
                {{ row.item.shal4 }} * {{ row.item.shal4_jin_huvi }} =
                {{ row.item.shal4 * row.item.shal4_jin_huvi }}
              </span>
            </template>
            <template #cell(shal1-4)="row">
              <span class="badge badge-success">
                {{
                  row.item.shal1 * row.item.shal1_jin_huvi +
                  row.item.shal2 * row.item.shal2_jin_huvi +
                  row.item.shal3 * row.item.shal3_jin_huvi +
                  row.item.shal4 * row.item.shal4_jin_huvi
                }}%
              </span>
            </template>
            <template #cell(created_at)="row">
              {{ row.item.created_at | dateYearMonthDay }}
            </template>
            <template #cell(updated_at)="row">
              {{ row.item.updated_at | dateYearMonthDay }}
            </template>
            <template #cell(actions)="row">
              <a
                href="#"
                class="badge bg-warning"
                v-show="
                  row.item.readEmployee === null && row.item.confirmed === null
                "
                @click="editEmployeesEvaluation(row.item)"
                ><i class="fas fa-edit"></i
              ></a>
            </template>
          </b-table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submit Your Name"
      size="xl"
      @show="resetModal"
      @hidden="cancelEdit"
      @ok="handleOk"
      hide-footer
    >
      <template v-slot:modal-title>
        <span v-show="!editMode"> Ажилчдын ажлын үнэлгээ бүртгэх </span>
        <span v-show="editMode"> Ажилчдын ажлын үнэлгээ засах </span>
      </template>
      <template>
        <form
          @submit.prevent="
            !editMode ? createEmployeeEvaluation() : updateEmployeeEvaluation()
          "
        >
          <table cellspacing="0" border="0">
            <colgroup width="272"></colgroup>
            <colgroup width="146"></colgroup>
            <colgroup width="167"></colgroup>
            <colgroup width="345"></colgroup>
            <colgroup width="113"></colgroup>
            <colgroup width="91"></colgroup>
            <colgroup width="107"></colgroup>
            <colgroup width="233"></colgroup>
            <colgroup width="198"></colgroup>
            <tr>
              <td colspan="9">
                БАТЛАВ: Сургуулийн захирал___________________/................/
              </td>
              <td height="19" align="left" valign="bottom">
                <font face="Tahoma"> </font>
              </td>
              <td align="left" valign="asdfbotasdftom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="17" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <tr>
              <td colspan="7" height="26" align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    >Ажилчдын ажлын гүйцэтгэлийг дүгнэх хуудас (KPI)
                  </font></b
                >
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="26" align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
            </tr>
            <!-- Овог нэр -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Овог нэр: </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <!-- {{ editMode }} -->
                  <multiselect
                    v-model="form.employee_id"
                    deselect-label="Can't remove this value"
                    :custom-label="nameTeacher"
                    track-by="emp_user_id"
                    label="emp_user_id"
                    placeholder="Select one"
                    :options="allEmployees"
                    :searchable="false"
                    :allow-empty="false"
                    @select="toggleSelect"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                          option.emp_teacher_name
                        }}</strong
                      >
                      менежер сонгогдлоо
                    </template>
                  </multiselect>
                  <!-- <span v-if="!editMode">{{ form.nameEmpCheck }}</span> -->
                </font>
                <font v-if="editMode">{{ nameEmpCheck }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Албан тушаал -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Албан тушаал:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  {{ form.employee_id.position_name }}
                </font>
                <b v-if="editMode"> {{ form.employee_id.position_name }}</b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Үнэлгээ хийсэн хугацаа:</font
                >
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000">{{
                  timestamp
                }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Ажлын сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Үнэлгээ хийх сар сонгох:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <multiselect
                    v-model="form.ajliin_sar"
                    deselect-label="Can't remove this value"
                    :options="months"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Ажлын сар сонгоно уу?"
                  ></multiselect>
                </font>
                <font v-if="editMode">{{ form.ajliin_sar }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>

            <!-- Багш нарын баг ахалсан эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <b-form-checkbox
                  id="checkbox-bnb"
                  v-model="form.bnbMode"
                  name="checkbox-bnb"
                >
                  Мэргэжлийн баг ахалсан эсэх:
                </b-form-checkbox>
              </td>
              <td align="left" valign="middle" colspan="3" v-if="form.bnbMode">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input
                    v-model="form.shal8"
                    placeholder="Мэргэжлийн баг ахалсан ажлын үнэлгээ"
                    type="number"
                    :state="validationNumber8"
                  ></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle" v-if="form.bnbMode">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td
                align="center"
                valign="middle"
                colspan="4"
                v-if="form.bnbMode"
              >
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal8_temd"
                      placeholder="Багш нарын баг ахалсан ажлын үнэлгээний тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Мэргэжлийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Мэргэжлийн зэрэгтэй эсэх:
                </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-select
                    v-model="form.shal9"
                    :options="options"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal9_temd"
                      placeholder="Мэргэжлийн зэргийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Эрдмийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Эрдмийн зэрэгтэй эсэх :</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox
                    id="checkbox-erdzer"
                    v-model="form.shal10"
                    name="checkbox-erdzer"
                    value="1"
                    unchecked-value="0"
                  >
                    Эрдмийн зэрэгтэй эсэх(Доктор)
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal10_temd"
                      placeholder="Эрдмийн зэргийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Спортын зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Спортын зэрэгтэй эсэх:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox
                    id="checkbox-sport-mas"
                    v-model="form.shal17"
                    name="checkbox-sport-mas"
                    value="1"
                    unchecked-value="0"
                  >
                    Спортын мастер эсэх
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal17_temd"
                      placeholder="Спортын мастерын зэргийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Ажилласан цаг -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Ажилласан цаг:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  {{ form.shal12 }} / {{ form.shal13 }} цаг
                  <!-- <b-form-input
                    v-model="form.shal12"
                    placeholder="Тухайн сард ажиласан цаг. 168-176 цаг байна."
                    type="number"
                    :state="validationNumber12"
                  ></b-form-input> -->
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal12_temd"
                      placeholder="Тухайн сард ажилласан цагийн тайлбар"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>

            <!-- Илүү цаг сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000"
                  >Тухайн сард ажилласан илүү цаг:</font
                >
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input
                    v-model="form.shal7"
                    placeholder="Тухайн сард ажилласан илүү цаг"
                    type="number"
                    :state="validationNumber7"
                  ></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b
                  ><font face="Tahoma" size="4" color="#000000"
                    ><br /><b-form-textarea
                      id="textarea"
                      v-model="form.shal7_temd"
                      placeholder="Тухайн сард илүү цаг ажилласан эсэхийг тайлбарласан"
                      rows="2"
                      max-rows="4"
                    ></b-form-textarea></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- зай авсан -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="4" color="#000000"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Хүснэгтийн эхлэх -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                height="38"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Гүйцэтгэлийн шалгуур үзүүлэлтүүд</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Хэмжих нэгж</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Зорилтот үр дүн / Хэмжүүр</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Эх сурвалж</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                colspan="3"
                rowspan="2"
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma" size="1.2" color="#000000"
                    >Үнэлгээ</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b
                  ><font face="Tahoma" color="#000000" size="1"
                    >Тайлбар<br /></font
                ></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                rowspan="2"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <b
                  ><font face="Tahoma" color="#000000" size="1"
                    >Шалтгаан<br /></font
                ></b>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td
                style="
                  border-top: 0.1px solid #000000;
                  border-bottom: 0.1px solid #000000;
                  border-left: 0.1px solid #000000;
                  border-right: 0.1px solid #000000;
                "
                height="63"
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Ажлын гүйцэтгэлийг илэрхийлэх гол үзүүлэлтүүд
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1">Үндсэн цалингийн хувь </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Тухайн үзүүлэлтийн хүрээнд тавигдаж буй зорилт
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Үнэлгээ хийх эрх зүйн баримт бичиг
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Эзлэх жин <br />(Ач холбогдол)</font
                  ></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                  padding: 50px;
                "
                align="center"
                valign="middle"
              >
                <i><font face="Tahoma" size="1">Биелэлт</font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <i><font face="Tahoma" size="1">Тооцох хувь</font></i>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Үнэлгээ хийсэн аргачлал
                  </font></i
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <i
                  ><font face="Tahoma" size="1"
                    >Биелэлт дутуу байгаа шалтгаан</font
                  ></i
                >
              </td>
            </tr>

            <!-- Бүх менежер -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Шалгуур 1; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                rowspan="4"
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Зорилтот үр дүнгийн үнэлгээ хэдэн хувь байна вэ гэдгээр
                  тооцно.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1">
                  Шалгуур 1 ≥85% үзүүлэлттэй байгаа эсэх;<br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Ажилтны ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="white"
                sdval="0.95"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber1"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.19"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal1_jin_huvi"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="0.1"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Шууд удирдлага задаргаа үнэлгээнүүдийг дэлгэрэнгүй хөтөлсөн
                  байна.</font
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  <b-form-textarea
                    id="textarea"
                    v-model="form.shal1_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal1_temd }}</b-form-textarea
                  >
                </font>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Шалгуур 2; </font>
              </td>

              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Шалгуур 2 ≥85% үзүүлэлттэй байгаа эсэх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Ажилтны ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber2"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.16"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal2_jin_huvi"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="0.1"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1">
                  Шууд удирдлага задаргаа үнэлгээнүүдийг дэлгэрэнгүй хөтөлсөн
                  байна.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal2_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal2_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Шалгуур 3; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Шалгуур 3 ≥85% үзүүлэлттэй байгаа эсэх; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Ажилтны ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.4"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber3"
                      min="0"
                      max="100"
                    ></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.3492"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal3_jin_huvi"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="0.1"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Шууд удирдлага задаргаа үнэлгээнүүдийг дэлгэрэнгүй хөтөлсөн
                  байна.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal3_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal3_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="34"
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1">Шалгуур 4; </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Шалгуур 4 ≥85% үзүүлэлттэй байгаа эсэх;
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma" size="1"
                  >Ажилтны ажлын үнэлгээний журам; <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4"
                      type="number"
                      id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validationNumber4"
                      min="0"
                      max="100"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.2"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"
                  ><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="form.shal4_jin_huvi"
                      placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg"
                      min="0"
                      max="1"
                      step="0.1"
                    ></b-form-input> </b-input-group
                ></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Шууд удирдлага задаргаа үнэлгээнүүдийг дэлгэрэнгүй хөтөлсөн
                  байна.
                </font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"
                  ><b-form-textarea
                    id="textarea"
                    v-model="form.shal4_temd"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    >{{ form.shal4_temd }}</b-form-textarea
                  ></font
                >
              </td>
            </tr>

            <!-- Хөлний тооцоолол -->
            <tr>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                height="17"
                align="center"
                valign="middle"
              >
                <b><font face="Tahoma" size="1.2">Нийт</font></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
              >
                <b
                  ><font face="Tahoma">15%<br /></font
                ></b>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
              >
                <font face="Tahoma"><br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="1"
                sdnum="1033;0;0%"
              >
                <b
                  ><font face="Tahoma">
                    {{ average() }}
                    %</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma"> {{ calculatePercent() }}% <br /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="center"
                valign="middle"
                sdval="0.8992"
                sdnum="1033;0;0%"
              >
                <b
                  ><font face="Tahoma" size="1.2"
                    >10% = 0.1 <br />
                    20% = 0.2 гэх мэт. <br />
                    4 үзүүлэлт нийлээд 100% = 1 байхыг анхаарна уу?</font
                  ></b
                >
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma" size="1"
                  >Амралтын үед үр дүнгийн урамшуулал тооцохгүй <br
                /></font>
              </td>
              <td
                style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                "
                align="left"
                valign="middle"
                bgcolor="#D9D9D9"
              >
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td
                align="center"
                valign="middle"
                sdval="0.40464"
                sdnum="1033;0;0%"
              >
                <font face="Tahoma" color="#808080"></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Ажилтан:</font></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" v-if="form.employee_id"
                  >{{ form.employee_id.emp_teacher_last_name[0] }}.{{
                    form.employee_id.emp_teacher_name
                  }}</font
                >
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Шууд удирдлага -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Шууд удирдлага:</font></b>
              </td>
              <td align="center" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma">/................/</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b><font face="Tahoma" size="3">Огноо:</font></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td
                align="center"
                valign="middle"
                sdval="44164"
                sdnum="1033;0;M/D/YYYY"
              >
                <font face="Tahoma">{{ timestamp }}</font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b
                  ><font face="Tahoma" size="3"><br /></font
                ></b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
          </table>
          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="cancelEdit"
            >Цуцлах</b-button
          >
          <b-button type="submit" class="mt-2" variant="outline-warning" block
            >Хадгалах</b-button
          >
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selectedYear: null,
      school_year_id: null,
      years: [],
      selectedMonths: null,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      action: "",
      editMode: false,
      //   keyword: "",
      employeesEvaluations: [],
      allEmployees: [],
      //   positions: [],
      nameEmpCheck: "",
      namePositionNameCheck: "",
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        enrol_emp_id: "",
        department_id: "",
        employee_id: "",
        bnbMode: false,
        position_id: "",
        ajliin_sar: "",
        readEmployee: "",
        shal1: "",
        shal1_jin_huvi: 0.2,
        shal1_temd: "",
        shal2: "",
        shal2_jin_huvi: 0.3,
        shal2_temd: "",
        shal3: "",
        shal3_jin_huvi: 0.3,
        shal3_temd: "",
        shal4: "",
        shal4_jin_huvi: 0.2,
        shal4_temd: "",
        shal5: "",
        shal5_temd: "",
        shal6: "",
        shal6_temd: "",
        shal7: "",
        shal7_temd: "",
        shal8: "",
        shal8_temd: "",
        shal9: null,
        shal9_temd: "",
        shal10: "",
        shal10_temd: "",
        shal11: "",
        shal11_temd: "",
        shal12: "",
        shal12_temd: "",
        shal13: "",
        shal13_temd: "",
        shal14: "",
        shal14_temd: "",
        shal15: "",
        shal15_temd: "",
        shal16: "",
        shal16_temd: "",
        shal17: "",
        shal17_temd: "",
        shal18: "",
        shal18_temd: "",
      }),
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      options: [
        { value: null, text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: 0, text: "Зэрэггүй" },
        { value: 1, text: "Заах аргач" },
        { value: 2, text: "Тэргүүлэх" },
        { value: 3, text: "Зөвлөх" },
        { value: 4, text: "Ахлах нийгмийн ажилтан" },
      ],
      timestamp: "",
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Овог Нэр",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Имэйл хаяг",
          sortable: true,
        },
        {
          key: "position_name",
          label: "Албан тушаал",
          sortable: true,
        },
        {
          key: "ajliin_sar",
          label: "Сар",
          sortable: true,
        },
        {
          key: "isCheckResult",
          label: "Дүнтэй танилцсан",
          sortable: true,
        },
        {
          key: "enr_emp_teacher_name",
          label: "Үнэлгээ хийсэн",
          sortable: true,
        },
        {
          key: "shal1",
          label: "Ш-1",
          sortable: true,
        },
        {
          key: "shal2",
          label: "Ш-2",
          sortable: true,
        },
        {
          key: "shal3",
          label: "Ш-3",
          sortable: true,
        },
        {
          key: "shal4",
          label: "Ш-4",
          sortable: true,
        },
        {
          key: "shal1-4",
          label: "Ш1-4",
          sortable: true,
        },
        {
          key: "shal7",
          label: "Ш-7",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: true,
        },
      ],
    };
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    getYearList() {
      axios
        .get("/all_worker_functions/getYearList")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getAllData() {
      if (this.selectedYear != null) this.school_year_id = this.selectedYear.id;
      axios
        .post("/all_worker_functions/getAllEmployeeEvaluations", {
          yearId: this.school_year_id,
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          //   console.log(response.data);
          this.employeesEvaluations = response.data.allEmployeesEvaluations;
          this.allEmployees = response.data.employees;
          //   this.positions = response.data.managerPositionsName;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editEmployeesEvaluation(employeeEvaluation) {
      // console.log("Дэлгэрэнгүй мэдээлэл засах", employeeEvaluation);

      this.form.reset();
      //   this.form.fill(managersEvaluation);

      this.$nextTick(() => {
        // console.log("nextTick");
        this.editMode = true;
        this.nameEmpCheck =
          employeeEvaluation.emp_teacher_last_name[0].toUpperCase() +
          "." +
          employeeEvaluation.emp_teacher_name;
        this.namePositionNameCheck = employeeEvaluation.position_name;
        this.form.id = employeeEvaluation.id;
        this.form.employee_id = employeeEvaluation;
        this.form.ajliin_sar = employeeEvaluation.ajliin_sar;
        this.form.shal1 = employeeEvaluation.shal1;
        this.form.shal1_temd = employeeEvaluation.shal1_temd;
        this.form.shal2 = employeeEvaluation.shal2;
        this.form.shal2_temd = employeeEvaluation.shal2_temd;
        this.form.shal3 = employeeEvaluation.shal3;
        this.form.shal3_temd = employeeEvaluation.shal3_temd;
        this.form.shal4 = employeeEvaluation.shal4;
        this.form.shal4_temd = employeeEvaluation.shal4_temd;
        this.form.shal7 = employeeEvaluation.shal7;
        this.form.shal7_temd = employeeEvaluation.shal7_temd;
        this.form.bnbMode = employeeEvaluation.shal8 ? true : false;
        this.form.shal8 = employeeEvaluation.shal8;
        this.form.shal8_temd = employeeEvaluation.shal8_temd;
        // console.log(parseInt(employeeEvaluation.shal9));
        if (parseInt(employeeEvaluation.shal9) === 0) {
          this.form.shal9 = 0;
        } else if (parseInt(employeeEvaluation.shal9) === 1) {
          this.form.shal9 = 1;
        } else if (parseInt(employeeEvaluation.shal9) === 2) {
          this.form.shal9 = 2;
        } else if (parseInt(employeeEvaluation.shal9) === 3) {
          this.form.shal9 = 3;
        } else {
          this.form.shal9 = 0;
        }
        // this.form.shal9 = employeeEvaluation.shal9;
        this.form.shal9_temd = employeeEvaluation.shal9_temd;
        this.form.shal10 = parseInt(employeeEvaluation.shal10);
        this.form.shal10_temd = employeeEvaluation.shal10_temd;
        // this.form.shal11 = parseInt(employeeEvaluation.shal11);
        // this.form.shal11_temd = employeeEvaluation.shal11_temd;
        this.form.shal12 = parseInt(employeeEvaluation.shal12);
        this.form.shal12_temd = employeeEvaluation.shal12_temd;
        this.form.shal13 = parseInt(employeeEvaluation.shal13);
        this.form.shal13_temd = employeeEvaluation.shal13_temd;
        this.form.shal17 = parseInt(employeeEvaluation.shal17);
        this.form.shal17_temd = employeeEvaluation.shal17_temd;
        this.$bvModal.show("modal-prevent-closing");
      });
    },
    updateEmployeeEvaluation() {
      //   this.action = "Бүртгэлийн ажилтны мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateEmployeeEvaluation/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolEmployees");
          this.form.reset();
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      //   console.log("Хаалаа");
      this.editMode = false;
      this.form.reset();
      this.nameEmpCheck = "";
      this.namePositionNameCheck = "";
      this.$bvModal.hide("modal-prevent-closing");
    },
    // deleteSchoolEmployee(schoolteacher) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
    createTWE() {
      this.form.reset();
      this.$bvModal.show("modal-prevent-closing");
    },
    createEmployeeEvaluation() {
      this.form
        .post("/all_worker_functions/createEmployeeEvaluation")
        .then((response) => {
          this.$toastr.s("Ажилтны үнэлгээг хийлээ.", "Амжилттай");
          Fire.$emit("loadSchoolEmployees");
          this.form.reset();
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch(() => {
          // console.log(errors.all());
          this.$toastr.e(
            "Ажилтны үнэлгээг бүртгэж чадсангүй, Нэг ажилтанд сарын үнэлгээг давхцуулж оруулах боломжгүй, Ажилтны нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
            "Алдаа гарлаа."
          );
        });
    },
    confirmedEWE(employeeEvaluation) {
      //   console.log(managerEvaluation);
      Swal.fire({
        title: "Менежер ажлын үнэлгээтэй танилцсан эсэх?",
        text: "Ажилчид ажлын үнэлгээтэй танилцсан бол үнэлгээг баталгаажуулна уу! Менежерийн өгсөн хугацаанд ажилчид ажлын үнэлгээтэй танилцаж, системд тэмдэглэсэн байна. Заасан хугацаанд тэмдэглээгүй бол менежер баталгаажуулах эрхтэй.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/all_worker_functions/confirmEWE", {
              id: employeeEvaluation.id,
              confirmed: true,
            })
            .then((response) => {
              this.$toastr.s("Амжилттай баталгаажууллаа.", "Амжилттай");
              Fire.$emit("loadSchoolEmployees");
            })
            .catch(() => {
              this.$toastr.e(
                "Үнэлгээг бүртгэж чадсангүй, Ажилтанд сарын үнэлгээг давхцуулж оруулах боломжгүй, Ажилтны нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    toggleSelect() {
      console.log("toggleSelect");
    },
    average() {
      return (
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4
      );
    },
    calculatePercent() {
      let averagePer =
        parseFloat(this.form.shal1) * parseFloat(this.form.shal1_jin_huvi) +
        parseFloat(this.form.shal2) * parseFloat(this.form.shal2_jin_huvi) +
        parseFloat(this.form.shal3) * parseFloat(this.form.shal3_jin_huvi) +
        parseFloat(this.form.shal4) * parseFloat(this.form.shal4_jin_huvi);
      // console.log(averagePer);
      let myPercent = (averagePer * 15) / 100;
      return myPercent;
    },
    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },
  },
  computed: {
    shal1_jin_huviCalculate() {
      return this.form.shal1 * this.form.shal1_jin_huvi;
    },
    shal2_jin_huviCalculate() {
      return this.form.shal2 * this.form.shal2_jin_huvi;
    },
    shal3_jin_huviCalculate() {
      return this.form.shal3 * this.form.shal3_jin_huvi;
    },
    shal4_jin_huviCalculate() {
      return this.form.shal4 * this.form.shal4_jin_huvi;
    },
    validation1_0oosNeg() {
      return this.form.shal1_jin_huvi >= 0 && this.form.shal1_jin_huvi <= 1;
    },
    validation2_0oosNeg() {
      return this.form.shal2_jin_huvi >= 0 && this.form.shal2_jin_huvi <= 1;
    },
    validation3_0oosNeg() {
      return this.form.shal3_jin_huvi >= 0 && this.form.shal3_jin_huvi <= 1;
    },
    validation4_0oosNeg() {
      return this.form.shal4_jin_huvi >= 0 && this.form.shal4_jin_huvi <= 1;
    },
    validationNumber1() {
      return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    },
    validationNumber2() {
      return this.form.shal2 >= 0 && this.form.shal2 <= 100;
    },
    validationNumber3() {
      return this.form.shal3 >= 0 && this.form.shal3 <= 100;
    },
    validationNumber4() {
      return this.form.shal4 >= 0 && this.form.shal4 <= 100;
    },
    validationNumber7() {
      return this.form.shal7 >= 0 && this.form.shal7 <= 100;
    },
    validationNumber8() {
      return this.form.shal8 >= 0 && this.form.shal8 <= 100;
    },
  },
  created() {
    this.getYearList();
    this.getAllData();
    Fire.$on("loadSchoolEmployees", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table {
  min-height: 300px;
}
</style>
