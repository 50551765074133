<template>
  <div class="mt-2">
    <b-button @click.prevent="getProYear()" block variant="outline-primary"
      >Ажилчдыг тухайн мэргэжлээрээ ажилласан жилээр харах</b-button
    >
    <div class="row" v-if="isWorkYearProView">
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="340"
                class="barChart"
                :options="optionsProYearAllEmp"
                :series="seriesProYearAllEmp"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="350"
                class="pieChart"
                :options="optionsProYearAllEmpP"
                :series="seriesProYearAllEmpP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <!-- Багш нар харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsProYearTeach"
                :series="seriesProYearTeach"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsProYearTeachP"
                :series="seriesProYearTeachP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="teachProYear in proYearTeach"
              :key="teachProYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ teachProYear.teacher_tukhain_mer_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    teachProYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="teachProYearDetal in proYearTeachDetal"
                    :key="teachProYearDetal.id"
                  >
                    <tr
                      v-if="
                        teachProYear.teacher_tukhain_mer_ajiljil ==
                        teachProYearDetal.teacher_tukhain_mer_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="teachProYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="teachProYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachProYearDetal.teacher_last_name[0] }}.{{
                          teachProYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachProYearDetal.teacher_tukhain_mer_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewProYearTeach"
              @click.prevent="getProYearTeachDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Ажилчид харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsProYearEmployee"
                :series="seriesProYearEmployee"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsProYearEmployeeP"
                :series="seriesProYearEmployeeP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="employeeProYear in proYearEmployee"
              :key="employeeProYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ employeeProYear.teacher_tukhain_mer_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    employeeProYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="employeeProYearDetal in proYearEmployeeDetal"
                    :key="employeeProYearDetal.id"
                  >
                    <tr
                      v-if="
                        employeeProYear.teacher_tukhain_mer_ajiljil ==
                        employeeProYearDetal.teacher_tukhain_mer_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="employeeProYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="employeeProYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeProYearDetal.teacher_last_name[0] }}.{{
                          employeeProYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeProYearDetal.teacher_tukhain_mer_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewProYearEmployee"
              @click.prevent="getProYearEmployeeDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Удирдлагууд харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsProYearManag"
                :series="seriesProYearManag"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsProYearManagP"
                :series="seriesProYearManagP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="managProYear in proYearManag"
              :key="managProYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ managProYear.teacher_tukhain_mer_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    managProYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="managProYearDetal in proYearManagDetal"
                    :key="managProYearDetal.id"
                  >
                    <tr
                      v-if="
                        managProYear.teacher_tukhain_mer_ajiljil ==
                        managProYearDetal.teacher_tukhain_mer_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="managProYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="managProYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managProYearDetal.teacher_last_name[0] }}.{{
                          managProYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managProYearDetal.teacher_tukhain_mer_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewProYearManag"
              @click.prevent="getProYearManagDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Цэцэрлэг харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsProYearKg"
                :series="seriesProYearKg"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг тухайн мэргэжлээрээ ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsProYearKgP"
                :series="seriesProYearKgP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="kgProYear in proYearKg"
              :key="kgProYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ kgProYear.teacher_tukhain_mer_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ kgProYear.total }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="kgProYearDetal in proYearKgDetal"
                    :key="kgProYearDetal.id"
                  >
                    <tr
                      v-if="
                        kgProYear.teacher_tukhain_mer_ajiljil ==
                        kgProYearDetal.teacher_tukhain_mer_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="kgProYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="kgProYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgProYearDetal.teacher_last_name[0] }}.{{
                          kgProYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgProYearDetal.teacher_tukhain_mer_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewProYearKg"
              @click.prevent="getProYearKgDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./card-body -->
</template>

<script>
export default {
  props: ["year_id"],
  data() {
    return {
      isWorkYearProView: false,
      viewProYearTeach: true,
      viewProYearEmployee: true,
      viewProYearManag: true,
      viewProYearKg: true,
      proYearTeach: [],
      proYearEmployee: [],
      proYearManag: [],
      proYearKg: [],
      proYearTeachDetal: [],
      proYearEmployeeDetal: [],
      proYearManagDetal: [],
      proYearKgDetal: [],
      optionsProYearAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesProYearAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsProYearTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesProYearTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsProYearEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesProYearEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsProYearManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesProYearManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsProYearKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesProYearKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesProYearAllEmpP: [],
      seriesProYearTeachP: [],
      seriesProYearEmployeeP: [],
      seriesProYearManagP: [],
      seriesProYearKgP: [],
      optionsProYearAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsProYearTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsProYearEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsProYearManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsProYearKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {
    getProYear() {
      if (!this.isWorkYearProView) {
        axios
          .post("/manager/getProYear", {
            year: this.year_id,
          })
          .then((res) => {
            this.seriesProYearAllEmp[0].data = res.data.proYearTotal;
            this.optionsProYearAllEmp.xaxis.categories = res.data.proYearName;
            this.seriesProYearTeach[0].data = res.data.proYearTotalTeach;
            this.optionsProYearTeach.xaxis.categories =
              res.data.proYearNameTeach;
            this.seriesProYearEmployee[0].data = res.data.proYearTotalEmployee;
            this.optionsProYearEmployee.xaxis.categories =
              res.data.proYearNameEmployee;
            this.seriesProYearManag[0].data = res.data.proYearTotalManag;
            this.optionsProYearManag.xaxis.categories =
              res.data.proYearNameManag;
            this.seriesProYearKg[0].data = res.data.proYearTotalKg;
            this.optionsProYearKg.xaxis.categories = res.data.proYearNameKg;

            this.seriesProYearAllEmpP = res.data.proYearTotal;
            this.optionsProYearAllEmpP.labels = res.data.proYearName;
            this.seriesProYearTeachP = res.data.proYearTotalTeach;
            this.optionsProYearTeachP.labels = res.data.proYearNameTeach;
            this.seriesProYearEmployeeP = res.data.proYearTotalEmployee;
            this.optionsProYearEmployeeP.labels = res.data.proYearNameEmployee;
            this.seriesProYearManagP = res.data.proYearTotalManag;
            this.optionsProYearManagP.labels = res.data.proYearNameManag;
            this.seriesProYearKgP = res.data.proYearTotalKg;
            this.optionsProYearKgP.labels = res.data.proYearNameKg;
            this.isWorkYearProView = !this.isWorkYearProView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isWorkYearProView = !this.isWorkYearProView;
    },
    getProYearTeachDetal() {
      axios
        .post("/manager/getProYearTeachDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.proYearTeach = res.data.proYearCountTeach;
          this.proYearTeachDetal = res.data.proYearTeachDetal;
          this.viewProYearTeach = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProYearEmployeeDetal() {
      axios
        .post("/manager/getProYearEmployeeDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.proYearEmployee = res.data.proYearCountEmployee;
          this.proYearEmployeeDetal = res.data.proYearEmployeeDetal;
          this.viewProYearEmployee = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProYearManagDetal() {
      axios
        .post("/manager/getProYearManagDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.proYearManag = res.data.proYearCountManag;
          this.proYearManagDetal = res.data.proYearManagDetal;
          this.viewProYearManag = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProYearKgDetal() {
      axios
        .post("/manager/getProYearKgDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.proYearKg = res.data.proYearCountKg;
          this.proYearKgDetal = res.data.proYearKgDetal;
          this.viewProYearKg = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
  font-size: 70%;
}
.pieChart {
  height: 330px;
}
.barChart {
  height: 340px;
}
</style>
