<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#allWorkerNotifys"
                data-toggle="tab"
                >Бүх ажилчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#allTeachNotifys" data-toggle="tab"
                >Бүх багш</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#allPaNotifys" data-toggle="tab"
                >Бүх эцэг, эх</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#allStudNotifys" data-toggle="tab"
                >Бүх суралцагчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#depNotifys" data-toggle="tab"
                >Багш нарын баг</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#classPaNotifys" data-toggle="tab"
                >Ангийн эцэг, эх</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#classStudNotifys" data-toggle="tab"
                >Ангийн суралцагчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#teachNotifys" data-toggle="tab"
                >Багш</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#parentNotifys" data-toggle="tab"
                >Эцэг, эх</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#studNotifys" data-toggle="tab"
                >Суралцагч</a
              >
            </li>
          </ul>
          <b-button
            size="sm"
            class="float-right"
            variant="success"
            @click.prevent="createNotify()"
            >Мэдээлэл оруулах</b-button
          >
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="allWorkerNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in allWorkerNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="allTeachNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in allTeachNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="allPaNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in allPaNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="allStudNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in allStudNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="depNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in depNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="classPaNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Багш/Ажилтан</th>
                        <th>Анги</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in classPaNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span
                            v-if="notifi.my_manager"
                            class="direct-chat-name float-left"
                          >
                            <img
                              v-if="
                                notifi.my_manager.profile_photo_url &&
                                notifi.my_manager.profile_photo_url != null
                              "
                              :src="notifi.my_manager.profile_photo_url"
                              alt="user-avatar"
                              class="img-size-50 mr-3 img-circle img-bordered-sm"
                            />
                            <img
                              v-else
                              src="/images/users/user.png"
                              alt="user-avatar"
                              class="img-size-50 mr-3 img-circle img-bordered-sm"
                            />
                            {{ notifi.my_manager.teacher_last_name }}
                            {{ notifi.my_manager.teacher_name }}
                          </span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            v-for="item in classFilter(notifi.school_class_id)"
                            :key="item"
                            variant="info"
                            class="mr-1"
                          >
                            {{ item }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="classStudNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in classStudNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="teachNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in teachNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="text-sm" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="parentNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in parentNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="studNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Танилцсан эсэх</th>
                        <th>Мэдээлэл</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in studNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(1)
                              }}%</b-badge
                            ></a
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light">
                            <p class="text-sm">
                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showSentNotify(notifi)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      notifi.notifcation.substring(0, 100)
                                    "
                                  >
                                  </span> </b-badge
                              ></a>
                            </p>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-notifi"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Мэдэгдэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title" v-show="!editEvalMode">Мэдээлэл оруулах</h3>
          </div>
          <div class="card-body">
            <form>
              <div class="form-group" v-show="!editEvalMode">
                <label>Бүлэг сонгох</label>
                <multiselect
                  v-model="form.type"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  label="text"
                  placeholder="Төрөл сонгох"
                  :options="notifiOptions"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.text }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.department_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  label="department_name"
                  placeholder="Хэлтэс сонгох"
                  :options="depOptions"
                  :searchable="true"
                  :allow-empty="true"
                  :close-on-select="false"
                  :show-labels="false"
                  v-show="showDep"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.department_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.school_class_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  label="full_name"
                  placeholder="Анги сонгох"
                  :options="classOptions"
                  :multiple="true"
                  :searchable="true"
                  :allow-empty="true"
                  :close-on-select="false"
                  :show-labels="false"
                  v-show="showClassParent"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.full_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.school_class_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  label="full_name"
                  placeholder="Анги сонгох"
                  :options="classOptions"
                  :multiple="true"
                  :searchable="true"
                  :allow-empty="true"
                  :close-on-select="false"
                  :show-labels="false"
                  v-show="showClassStud"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.full_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.teacher_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="user_id"
                  label="teacher_name"
                  placeholder="Багш сонгох"
                  :options="teacherOptions"
                  :searchable="true"
                  :allow-empty="true"
                  v-show="showTeacher"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.teacher_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.parent_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="user_id"
                  :custom-label="customUserName"
                  placeholder="Эцэг, эх сонгох"
                  :options="parentOptions"
                  :searchable="true"
                  :allow-empty="true"
                  v-show="showParent"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.full_name }}
                    {{ option.dad_full_name }}
                    {{ option.student_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group" v-show="!editEvalMode">
                <multiselect
                  v-model="form.stud_id"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="user_id"
                  placeholder="Суралцагч сонгох"
                  :options="studOptions"
                  label="email"
                  :searchable="true"
                  :allow-empty="true"
                  v-show="showStud"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.email }}
                  </template>
                </multiselect>
              </div>
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <label for="evaluation">Мэдээлэл</label>
                  <validation-provider
                    name="form.notification"
                    rules="alpha_num"
                  >
                    <template>
                      <quill-editor
                        ref="myQuillEditor"
                        v-model="form.notification"
                        :options="editorOption"
                      />
                    </template>
                  </validation-provider>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-success"
              v-show="!editEvalMode"
              @click.prevent="createNotifi"
            >
              Мэдээлэл оруулах
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              v-show="editEvalMode"
              @click.prevent="updateNotifi"
            >
              Хадгалах
            </button>
            <button
              type="submit"
              class="btn btn-dark"
              v-show="editEvalMode"
              @click.prevent="cancelNotifi"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-notify"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Мэдэгдэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span
              v-if="showNotifyData.my_manager"
              class="direct-chat-name float-left"
            >
              <img
                v-if="
                  showNotifyData.my_manager.profile_photo_url &&
                  showNotifyData.my_manager.profile_photo_url != null
                "
                :src="showNotifyData.my_manager.profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showNotifyData.my_manager.teacher_last_name }}
              {{ showNotifyData.my_manager.teacher_name }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>
              {{ showNotifyData.created_at | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showNotifyData.notifcation"> </span>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-views"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Мэдэгдэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="card card-success" v-if="viewShowViewers">
          <div class="card-header">
            <h3 class="card-title">Танилцсан</h3>

            <div class="card-tools">
              <span class="badge badge-warning" v-if="showNotifyViewers.data">{{
                showNotifyViewers.data.length
              }}</span>
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="collapse"
              >
                <i class="fa fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0" v-if="showNotifyViewers">
            <ul class="users-list clearfix">
              <li
                v-for="viewed in showNotifyViewers.data"
                :key="viewed.notification_user_id"
              >
                <span v-if="roleName == 'employee' && viewed.my_viewers">
                  <img
                    v-if="
                      viewed.my_viewers.profile_photo_url &&
                      viewed.my_viewers.profile_photo_url != null
                    "
                    :src="viewed.my_viewers.profile_photo_url"
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a class="users-list-name" href="#"
                    >{{ viewed.my_viewers.teacher_last_name[0] }}.{{
                      viewed.my_viewers.teacher_name
                    }}</a
                  >
                </span>
                <span
                  v-else-if="roleName == 'student' && viewed.my_show_students"
                >
                  <img
                    v-if="
                      viewed.my_show_students.student_profile_photo_url &&
                      viewed.my_show_students.student_profile_photo_url != null
                    "
                    :src="viewed.my_show_students.student_profile_photo_url"
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a class="users-list-name" href="#"
                    >{{ viewed.my_show_students.student_last_name[0] }}.{{
                      viewed.my_show_students.student_name
                    }}</a
                  >
                </span>
                <span v-else-if="viewed.my_show_parents">
                  <img
                    v-if="
                      viewed.my_show_parents.parent_father_profile_photo_url &&
                      viewed.my_show_parents.parent_father_profile_photo_url !=
                        null
                    "
                    :src="
                      viewed.my_show_parents.parent_father_profile_photo_url
                    "
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a
                    class="users-list-name"
                    v-if="viewed.my_show_parents.parent_father_ovog"
                    href="#"
                    >{{ viewed.my_show_parents.parent_father_ovog[0] }}.{{
                      viewed.my_show_parents.parent_father_name
                    }}</a
                  >
                  <b-badge variant="light">
                    <span
                      v-for="child in viewed.my_show_parents
                        .get_my_primary_child"
                      :key="child.id"
                      class="mr-1"
                      >{{ child.full_name }}</span
                    ></b-badge
                  >
                </span>
              </li>
            </ul>
            <pagination
              align="center"
              :data="showNotifyViewers"
              @pagination-change-page="list"
            >
            </pagination>
          </div>
        </div>
        <div class="card card-danger" v-if="viewShowViewers">
          <div class="card-header">
            <h3 class="card-title">Танилцаагүй</h3>

            <div class="card-tools">
              <span
                class="badge badge-warning"
                v-if="showNotifyNotViewers.data"
                >{{ showNotifyNotViewers.data.length }}</span
              >
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="collapse"
              >
                <i class="fa fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0" v-if="showNotifyNotViewers">
            <ul class="users-list clearfix">
              <li
                v-for="viewed in showNotifyNotViewers.data"
                :key="viewed.notification_user_id"
              >
                <span v-if="roleName == 'employee' && viewed.my_viewers">
                  <img
                    v-if="
                      viewed.my_viewers.profile_photo_url &&
                      viewed.my_viewers.profile_photo_url != null
                    "
                    :src="viewed.my_viewers.profile_photo_url"
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a class="users-list-name" href="#"
                    >{{ viewed.my_viewers.teacher_last_name[0] }}.{{
                      viewed.my_viewers.teacher_name
                    }}</a
                  >
                </span>
                <span
                  v-else-if="roleName == 'student' && viewed.my_show_students"
                >
                  <img
                    v-if="
                      viewed.my_show_students.student_profile_photo_url &&
                      viewed.my_show_students.student_profile_photo_url != null
                    "
                    :src="viewed.my_show_students.student_profile_photo_url"
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a class="users-list-name" href="#"
                    >{{ viewed.my_show_students.student_last_name[0] }}.{{
                      viewed.my_show_students.student_name
                    }}</a
                  >
                </span>
                <span v-else-if="viewed.my_show_parents">
                  <img
                    v-if="
                      viewed.my_show_parents.parent_father_profile_photo_url &&
                      viewed.my_show_parents.parent_father_profile_photo_url !=
                        null
                    "
                    :src="
                      viewed.my_show_parents.parent_father_profile_photo_url
                    "
                    alt="user-avatar"
                  />
                  <img v-else src="/images/users/user.png" alt="user-avatar" />
                  <a
                    class="users-list-name"
                    v-if="viewed.my_show_parents.parent_father_ovog"
                    href="#"
                    >{{ viewed.my_show_parents.parent_father_ovog[0] }}.{{
                      viewed.my_show_parents.parent_father_name
                    }}</a
                  >
                  <b-badge variant="light">
                    <span
                      v-for="child in viewed.my_show_parents
                        .get_my_primary_child"
                      :key="child.id"
                      class="mr-1"
                      >{{ child.full_name }}</span
                    ></b-badge
                  >
                </span>
              </li>
            </ul>
            <pagination
              align="center"
              :data="showNotifyNotViewers"
              @pagination-change-page="listNot"
            ></pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Норматив нэмэх -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import pagination from "laravel-vue-pagination";
export default {
  data() {
    return {
      current_page: 1,
      headerBgVariant: "light",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
      editEvalMode: false,
      selectedType: null,
      showDep: false,
      showClassStud: false,
      showClassParent: false,
      showTeacher: false,
      showAddTypeMode: false,
      showParent: false,
      showStud: false,
      viewShowViewers: false,
      showNotifyViewers: "",
      showNotifyNotViewers: "",
      roleName: "",
      notifications: [],
      allWorkerNotifys: [],
      allTeachNotifys: [],
      allPaNotifys: [],
      allStudNotifys: [],
      classPaNotifys: [],
      classStudNotifys: [],
      teachNotifys: [],
      parentNotifys: [],
      studNotifys: [],
      showMode: false,
      showNotifyData: "",
      depNotifys: [],
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 1,
            maxWidth: 800,
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
      },
      form: new Form({
        id: "",
        type: "",
        notification: "",
        year_id: "",
        school_id: "",
        department_id: "",
        school_class_id: [],
        reg_emp_id: "",
        parent_id: "",
        stud_id: "",
        manager_id: "",
        teacher_id: "",
        notifcation: "",
        notification_status: "",
      }),
      notifiType: [],
      depOptions: [],
      classOptions: [],
      teacherOptions: [],
      parentOptions: [],
      studOptions: [],
      notifiOptions: [
        { id: 1, text: "Бүх ажилчид" },
        { id: 2, text: "Бүх багш нар" },
        { id: 3, text: "Бүх эцэг эх" },
        { id: 4, text: "Бүх суралцагчид" },
        { id: 5, text: "Багш нарын баг" },
        { id: 6, text: "Анги (эцэг, эх)" },
        { id: 7, text: "Анги (суралцагчид)" },
        { id: 8, text: "Багшид" },
        { id: 9, text: "Эцэг эхэд" },
        { id: 10, text: "Суралцагчид" },
        { id: 11, text: "Мэдээлэл" },
      ],
      showViewId: null,
      showNotViewId: null,
    };
  },
  components: {
    Multiselect,
    pagination,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor;
    },
    // userInfo() {
    //         return this.$store.state.userRoleInfo;
    //     },
    allSchoolClasses() {
      return this.$store.state.allSchoolClasses;
      //   .filter((todo) => todo.done).length;
    },
  },
  watch: {
    "form.type": function (newVal) {
      if (newVal.id == 5) {
        this.showDep = true;
        axios.get("/manager/getAllDepartments/").then((response) => {
          this.depOptions = response.data.departments;
        });
      } else this.showDep = false;
      if (newVal.id == 6) {
        this.showClassParent = true;
        axios.get("/physical/getAllSchoolClass/").then((response) => {
          this.classOptions = response.data.schoolclasses;
        });
      } else this.showClassParent = false;
      if (newVal.id == 7) {
        this.showClassStud = true;
        axios.get("/physical/getAllSchoolClass/").then((response) => {
          this.classOptions = response.data.schoolclasses;
        });
      } else this.showClassStud = false;
      if (newVal.id == 8) {
        this.showTeacher = true;
        axios.get("/manager/getAllTeacher/").then((response) => {
          this.teacherOptions = response.data.teachers;
        });
      } else this.showTeacher = false;
      if (newVal.id == 9) {
        this.showParent = true;
        axios.get("/manager/getAllUsersParent/").then((response) => {
          this.parentOptions = response.data.parents;
        });
      } else this.showParent = false;
      if (newVal.id == 10) {
        this.showStud = true;
        axios.get("/manager/getAllStudents/").then((response) => {
          this.studOptions = response.data.students;
        });
      } else this.showStud = false;
    },
  },
  methods: {
    async list(page = 1) {
      await axios
        .get(
          `/all_worker_functions/showNotifyViews/${this.showViewId}?page=${page}`
        )
        .then(({ data }) => {
          this.showNotifyViewers = data.notifiViews;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    async listNot(page = 1) {
      await axios
        .get(
          `/all_worker_functions/showNotNotifyViews/${this.showNotViewId}?page=${page}`
        )
        .then(({ data }) => {
          this.showNotifyNotViewers = data.notifiNotViews;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    getColor(count, total) {
      let huvi = (count / total) * 100;
      if (huvi < 10) return "danger";
      else if (huvi < 30) return "warning";
      else if (huvi < 60) return "primer";
      else if (60 < huvi) return "success";
    },
    classFilter(classIdStr) {
  if (!classIdStr) {
    return [];
  }

  if (classIdStr === "-") {
    return [];
  }

  var classArr = classIdStr.split("-");
  let classList = [];

  if (classArr.length > 0) {
    classArr.forEach((classId) => {
      if (classId !== "") {
        const classObj = this.allSchoolClasses.find((el) => el.id === parseInt(classId));
        if (classObj) {
          classList.push(classObj.full_name);
        }
      }
    });
    return classList;
  } else {
    if (classIdStr) {
      const classObj = this.allSchoolClasses.find((el) => el.id === parseInt(classIdStr));
      if (classObj) {
        classList.push(classObj.full_name);
      }
    }
    return classList;
  }
},

    //энэ юунд хэрэглэгдээд байгааг ойлгосонгүй....
    getManagAllInfo() {
      axios
        .get("/manager/getManagAllInfo/")
        .then((response) => {
          this.notifications = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllWorkerNotify() {
      axios
        .get("/manager/getAllWorkerNotify/")
        .then((response) => {
          this.allWorkerNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllTeacherNotify() {
      axios
        .get("/manager/getAllTeacherNotify/")
        .then((response) => {
          this.allTeachNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getDepTeacherNotify() {
      axios
        .get("/manager/getDepTeacherNotify/")
        .then((response) => {
          this.depNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getClassPaNotify() {
      axios
        .get("/manager/getClassPaNotify/")
        .then((response) => {
          this.classPaNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getClassStudNotify() {
      axios
        .get("/manager/getClassStudNotify/")
        .then((response) => {
          this.classStudNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllParentNotify() {
      axios
        .get("/manager/getAllParentNotify/")
        .then((response) => {
          this.allPaNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllStudNotify() {
      axios
        .get("/manager/getAllStudNotify/")
        .then((response) => {
          this.allStudNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getTeacherNotify() {
      axios
        .get("/manager/getTeacherNotify/")
        .then((response) => {
          this.teachNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getParentNotify() {
      axios
        .get("/manager/getParentNotify/")
        .then((response) => {
          this.parentNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getStudNotify() {
      axios
        .get("/manager/getStudNotify/")
        .then((response) => {
          this.studNotifys = response.data.notifications;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showNotifi() {
      this.showAddTypeMode = false;
      this.form.reset();
      this.getManagAllInfo();
    },
    addNotifi() {
      this.showAddTypeMode = true;
      this.editEvalMode = false;
    },
    createNotifi() {
      var notifyType = this.form.type.text;
      this.form.type = this.form.type.text;
      this.form.department_id = this.form.department_id.id;
      this.form.school_class_id = this.form.school_class_id;
      this.form.parent_id = this.form.parent_id.user_id;
      this.form.stud_id = this.form.stud_id.user_id;
      this.form.teacher_id = this.form.teacher_id.user_id;
      this.form
        .post("/manager/createNotifi/")
        .then((response) => {
          this.$toastr.s("Мэдэгдэл амжилттай илгээлээ.", "Амжилттай");
          //   this.getManagAllInfo();
          console.log(this.form.type);
          if (this.form.type == "Бүх ажилчид") {
            this.getAllWorkerNotify();
          } else if (this.form.type == "Бүх суралцагчид") {
            this.getAllStudNotify();
          } else if (this.form.type == "Бүх багш нар") {
            this.getAllTeacherNotify();
          } else if (this.form.type == "Багш нарын баг") {
            this.getDepTeacherNotify();
          } else if (this.form.type == "Анги (суралцагчид)") {
            this.getClassStudNotify();
          } else if (this.form.type == "Анги (эцэг, эх)") {
            this.getClassPaNotify();
          }
          //   else if (this.form.type == "Манай ангийн эцэг, эх") {
          //     // this.getA
          //   } else if (this.form.type == "Манай ангийн суралцагчид") {
          //   }
          else if (this.form.type == "Бүх эцэг эх") {
            this.getAllParentNotify();
          } else if (this.form.type == "Эцэг эхэд") {
            this.getParentNotify();
          } else if (this.form.type == "Багшид") {
            this.getTeacherNotify();
          } else if (this.form.type == "Суралцагчид") {
            this.getStudNotify();
          }

          this.showAddTypeMode = false;
          this.form.reset();
          this.form.type = notifyType;
          this.$bvModal.hide("modal-notifi");
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editNotifi(notifi) {
      this.showAddTypeMode = false;
      this.editEvalMode = true;
      this.form.fill(notifi);
      this.form.type = notifi.type;
      this.form.notification = notifi.notifcation;
      this.$bvModal.show("modal-notifi");
      console.log(notifi)
    },
    updateNotifi() {
      this.form
        .put("/manager/updateNotifi/"  + this.form.id)
        .then((response) => {
            if (this.form.type == "Бүх ажилчид") {
            this.getAllWorkerNotify();
          } else if (this.form.type == "Бүх суралцагчид") {
            this.getAllStudNotify();
          } else if (this.form.type == "Бүх багш нар") {
            this.getAllTeacherNotify();
          } else if (this.form.type == "Багш нарын баг") {
            this.getDepTeacherNotify();
          } else if (this.form.type == "Анги (суралцагчид)") {
            this.getClassStudNotify();
          } else if (this.form.type == "Анги (эцэг, эх)") {
            this.getClassPaNotify();
          }
          //   else if (this.form.type == "Манай ангийн эцэг, эх") {
          //     // this.getA
          //   } else if (this.form.type == "Манай ангийн суралцагчид") {
          //   }
          else if (this.form.type == "Бүх эцэг эх") {
            this.getAllParentNotify();
          } else if (this.form.type == "Эцэг эхэд") {
            this.getParentNotify();
          } else if (this.form.type == "Багшид") {
            this.getTeacherNotify();
          } else if (this.form.type == "Суралцагчид") {
            this.getStudNotify();
          }
          this.form.reset();
          this.$bvModal.hide("modal-notifi");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelNotifi() {
      this.editEvalMode = false;
      this.form.reset();
    },
    removeNotifi(p) {
      Swal.fire({
        title: "Мэдэгдэл устгах",
        text: p.type + " Мэдэгдэл устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Мэдэгдэл хасаж байна...";
          axios
            .get("/manager/removeNotifi/" + p.id)
            .then((response) => {
              this.$toastr.s("Мэдэгдэл амжилттай устгалаа!", "Шинэчилсэн");
              this.getManagAllInfo();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    showSentNotify(notify) {
      this.showNotifyData = notify;
      this.showMode = !this.showMode;
      this.$bvModal.show("modal-notify");
    },
    showNotifyViews(notify) {
      this.showViewId = notify.id;
      this.showNotViewId = notify.id;
      this.action = "Mэдээллийг харах...";
      axios
        .get("/all_worker_functions/showNotifyViews/" + notify.id)
        .then((response) => {
          this.showNotifyViewers = response.data.notifiViews;
          this.roleName = response.data.roleName;
          this.viewShowViewers = true;
          this.$bvModal.show("modal-views");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/all_worker_functions/showNotNotifyViews/" + notify.id)
        .then((response) => {
          this.showNotifyNotViewers = response.data.notifiNotViews;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createNotify() {
      this.$bvModal.show("modal-notifi");
    },
    customUserName({ full_name, dad_full_name, student_name }) {
      return `${full_name}, ${dad_full_name}, ${student_name}`;
    },
  },
  created() {
    // this.getManagAllInfo();
    this.getAllWorkerNotify();
    this.getAllTeacherNotify();
    this.getDepTeacherNotify();
    this.getClassPaNotify();
    this.getClassStudNotify();
    this.getAllParentNotify();
    this.getAllStudNotify();
    this.getTeacherNotify();
    this.getParentNotify();
    this.getStudNotify();
    Fire.$on("allWorkerNotifyLoad", () => {
      this.getAllWorkerNotify();
    });
    Fire.$on("allTeacherNotifyLoad", () => {
      this.getAllTeacherNotify();
    });
    Fire.$on("allParentNotifyLoad", () => {
      this.getAllParentNotify();
    });
    Fire.$on("allStudNotifyLoad", () => {
      this.getAllStudNotify();
    });
    Fire.$on("teacherDepNotifyLoad", () => {
      this.getDepTeacherNotify();
    });
    Fire.$on("classParentNotifyLoad", () => {
      this.getClassPaNotify();
    });
    Fire.$on("classStudNotifyLoad", () => {
      this.getClassStudNotify();
    });
    Fire.$on("teacherNotifyLoad", () => {
      this.getTeacherNotify();
    });
    Fire.$on("parentNotifyLoad", () => {
      this.getParentNotify();
    });
    Fire.$on("studNotifyLoad", () => {
      this.getStudNotify();
    });
  },
};
</script>

<style scoped>
.users-list > li {
  width: 16%;
}
</style>
