<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header"></div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 5px">№</th>
                <th>Овог Нэр</th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын зорилго эсвэл агуулгатай холбоотой сэдэл, сэтгэл хөдлөлөө хянах, үнэлэх чадвар! Жинлэх хувь 10%'
                  "
                  title="Сельф-1"
                >
                  Се1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын зорилго эсвэл агуулгыг ойлгож байгаа эсэхээ нягтлах, түүнийг хэрхэн хэрэгжүүлэх арга зам, алхмуудыг төлөвлөх! Жинлэх хувь 10%'
                  "
                  title="Метакогнитив-1"
                >
                  Ме1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой түлхүүр үгс, ойлголтуудыг сэргээх санах! Жинлэх хувь 10%'
                  "
                  title="Когнитив-1"
                >
                  Ко1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой түлхүүр үгс, ойлголтуудад утга өгөх чадвар! Жинлэх хувь 10%'
                  "
                  title="Когнитив-2"
                >
                  Ко2 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой мэдлэгийг задлан шинжлэх чадвар! Жинлэх хувь 20%'
                  "
                  title="Когнитив-3"
                >
                  Ко3 (20%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой мэдлэгийг танил болон танил бус нөхцөлд хэрэглэх чадвар! Жинлэх хувь 20%'
                  "
                  title="Когнитив-4"
                >
                  Ко4 (20%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаварын гүйцэтгэлийг хянах, алдааг засаж залруулах, үр дүнгээ нэгтгэх, эмхэлж цэгцлэх! Жинлэх хувь 10%'
                  "
                  title="Метакогнитив-2"
                >
                  Ме2 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаварын үр дүнг үнэлж дүгнэх, үр дүнтэй холбоотой сэдэл, сэтгэл хөдлөлөө хянах, үнэлэх чадвар! Жинлэх хувь 10%'
                  "
                  title="Сельф-2"
                >
                  Се2 (10%)
                </th>
                <th>Жил</th>
                <th>Улирал 1</th>
                <th>Улирал 2</th>
                <th>Улирал 3</th>
                <th>Улирал 4</th>
                <th colspan="2">Жилийн эцэс</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(examData, index) in changeSelectedLessonExamData"
                :key="examData.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{ examData.student_last_name[0]
                  }}{{ examData.student_last_name[1] }}.{{
                    examData.student_name
                  }}
                </td>
                <td>
                  {{ examData.exam1Year }}
                </td>
                <td>
                  {{ examData.exam2Year }}
                </td>
                <td>
                  {{ examData.exam3Year }}
                </td>
                <td>
                  {{ examData.exam4Year }}
                </td>
                <td>
                  {{ examData.exam5Year }}
                </td>
                <td>
                  {{ examData.exam6Year }}
                </td>
                <td>
                  {{ examData.exam7Year }}
                </td>
                <td>
                  {{ examData.exam8Year }}
                </td>
                <td>
                  <span
                    class="badge bg-success"
                    v-if="examData.examAverageYear > 0"
                  >
                    {{ examData.examAverageYear.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.examAverageYear) }}
                    )</span
                  >
                </td>
                <td>
                  <span class="badge bg-success" v-if="examData.uliral_1 >= 0">
                    {{ examData.uliral_1.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.uliral_1) }}
                    )</span
                  >
                </td>
                <td>
                  <span class="badge bg-success" v-if="examData.uliral_2 >= 0">
                    {{ examData.uliral_2.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.uliral_2) }}
                    )</span
                  >
                </td>
                <td>
                  <span class="badge bg-success" v-if="examData.uliral_3 >= 0">
                    {{ examData.uliral_3.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.uliral_3) }}
                    )</span
                  >
                </td>
                <td>
                  <span class="badge bg-success" v-if="examData.uliral_4 >= 0">
                    {{ examData.uliral_4.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.uliral_4) }}
                    )</span
                  >
                </td>
                <!-- <td></td> -->
                <td>
                  <div
                    class="text-center"
                    v-for="dataCheck in dataChecked"
                    :key="dataCheck.id"
                  >
                    <b-icon
                      v-if="
                        dataCheck.exam != null &&
                        examData.grade_exam_student_id == dataCheck.student_id
                      "
                      icon="check-square"
                      scale="0.8"
                      variant="warning"
                    ></b-icon>
                    <b-badge
                      v-if="
                        dataCheck.exam != null &&
                        examData.grade_exam_student_id == dataCheck.student_id
                      "
                      variant="light"
                      >{{ dataCheck.exam }}</b-badge
                    >
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th style="width: 5px"></th>
                <th style="text-align: right">Дундаж</th>
                <th>
                  <span class="badge bg-warning" v-if="exam1CalsAvg > 0">
                    {{ exam1CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam2CalsAvg > 0">
                    {{ exam2CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam3CalsAvg > 0">
                    {{ exam3CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam4CalsAvg > 0">
                    {{ exam4CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam5CalsAvg > 0">
                    {{ exam5CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam6CalsAvg > 0">
                    {{ exam6CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam7CalsAvg > 0">
                    {{ exam7CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam8CalsAvg > 0">
                    {{ exam8CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-success" v-if="examCalsAvgYearFull > 0">
                    {{ examCalsAvgYearFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgYearFull) }}
                    )</span
                  >
                </th>
                <th>
                  <span class="badge bg-success" v-if="examCalsAvgFull > 0">
                    {{ examCalsAvgFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgFull) }}
                    )</span
                  >
                </th>
                <th>
                  <span
                    class="badge bg-success"
                    v-if="examCalsAvgQuarterFull > 0"
                  >
                    {{ examCalsAvgQuarterFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgQuarterFull) }}
                    )</span
                  >
                </th>
              </tr>
              <tr>
                <th style="width: 5px">№</th>
                <th style="text-align: right">Үнэлгээ</th>
                <th>Се1</th>
                <th>Ме1</th>
                <th>Ко1</th>
                <th>Ко2</th>
                <th>Ко3</th>
                <th>Ко4</th>
                <th>Ме2</th>
                <th>Се2</th>
                <th>Дүн(Үнэлгээ)</th>
                <th style="text-align: center">Үнэлгээ</th>
                <th style="text-align: center">Үнэлгээ</th>
              </tr>
              <tr>
                <th style="text-align: right">1</th>
                <th style="text-align: right">A</th>
                <th>{{ exam1CountA.length }}</th>
                <th>{{ exam2CountA.length }}</th>
                <th>{{ exam3CountA.length }}</th>
                <th>{{ exam4CountA.length }}</th>
                <th>{{ exam5CountA.length }}</th>
                <th>{{ exam6CountA.length }}</th>
                <th>{{ exam7CountA.length }}</th>
                <th>{{ exam8CountA.length }}</th>
                <th>{{ examAverageYearCountA.length }}</th>
                <th>{{ examAverageUliral1CountA.length }}</th>
                <th>{{ examAverageUliral2CountA.length }}</th>
                <th>{{ examAverageUliral3CountA.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">2</th>
                <th style="text-align: right">B</th>
                <th>{{ exam1CountB.length }}</th>
                <th>{{ exam2CountB.length }}</th>
                <th>{{ exam3CountB.length }}</th>
                <th>{{ exam4CountB.length }}</th>
                <th>{{ exam5CountB.length }}</th>
                <th>{{ exam6CountB.length }}</th>
                <th>{{ exam7CountB.length }}</th>
                <th>{{ exam8CountB.length }}</th>
                <th>{{ examAverageYearCountB.length }}</th>
                <th>{{ examAverageUliral1CountB.length }}</th>
                <th>{{ examAverageUliral2CountB.length }}</th>
                <th>{{ examAverageUliral3CountB.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">3</th>
                <th style="text-align: right">C</th>
                <th>{{ exam1CountC.length }}</th>
                <th>{{ exam2CountC.length }}</th>
                <th>{{ exam3CountC.length }}</th>
                <th>{{ exam4CountC.length }}</th>
                <th>{{ exam5CountC.length }}</th>
                <th>{{ exam6CountC.length }}</th>
                <th>{{ exam7CountC.length }}</th>
                <th>{{ exam8CountC.length }}</th>
                <th>{{ examAverageYearCountC.length }}</th>
                <th>{{ examAverageUliral1CountC.length }}</th>
                <th>{{ examAverageUliral2CountC.length }}</th>
                <th>{{ examAverageUliral3CountC.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">4</th>
                <th style="text-align: right">D</th>
                <th>{{ exam1CountD.length }}</th>
                <th>{{ exam2CountD.length }}</th>
                <th>{{ exam3CountD.length }}</th>
                <th>{{ exam4CountD.length }}</th>
                <th>{{ exam5CountD.length }}</th>
                <th>{{ exam6CountD.length }}</th>
                <th>{{ exam7CountD.length }}</th>
                <th>{{ exam8CountD.length }}</th>
                <th>{{ examAverageYearCountD.length }}</th>
                <th>{{ examAverageUliral1CountD.length }}</th>
                <th>{{ examAverageUliral2CountD.length }}</th>
                <th>{{ examAverageUliral3CountD.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">5</th>
                <th style="text-align: right">F</th>
                <th>{{ exam1CountF.length }}</th>
                <th>{{ exam2CountF.length }}</th>
                <th>{{ exam3CountF.length }}</th>
                <th>{{ exam4CountF.length }}</th>
                <th>{{ exam5CountF.length }}</th>
                <th>{{ exam6CountF.length }}</th>
                <th>{{ exam7CountF.length }}</th>
                <th>{{ exam8CountF.length }}</th>
                <th>{{ examAverageYearCountF.length }}</th>
                <th>{{ examAverageUliral1CountF.length }}</th>
                <th>{{ examAverageUliral2CountF.length }}</th>
                <th>{{ examAverageUliral3CountF.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">6</th>
                <th style="text-align: right">Success/Амжилт</th>
                <th v-if="exam1CountSuccess > 0">
                  {{ exam1CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam2CountSuccess > 0">
                  {{ exam2CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam3CountSuccess > 0">
                  {{ exam3CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam4CountSuccess > 0">
                  {{ exam4CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam5CountSuccess > 0">
                  {{ exam5CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam6CountSuccess > 0">
                  {{ exam6CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam7CountSuccess > 0">
                  {{ exam7CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam8CountSuccess > 0">
                  {{ exam8CountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageYearCountSuccess > 0">
                  {{ examAverageYearCountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral1CountSuccess > 0">
                  {{ examAverageUliral1CountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral2CountSuccess > 0">
                  {{ examAverageUliral2CountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral3CountSuccess > 0">
                  {{ examAverageUliral3CountSuccess.toFixed(1) }}
                </th>
              </tr>
              <tr>
                <th style="text-align: right">7</th>
                <th style="text-align: right">Quality/Чанар</th>
                <th v-if="exam1CountQuality > 0">
                  {{ exam1CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam2CountQuality > 0">
                  {{ exam2CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam3CountQuality > 0">
                  {{ exam3CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam4CountQuality > 0">
                  {{ exam4CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam5CountQuality > 0">
                  {{ exam5CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam6CountQuality > 0">
                  {{ exam6CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam7CountQuality > 0">
                  {{ exam7CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam8CountQuality > 0">
                  {{ exam8CountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageCountQuality > 0">
                  {{ examAverageCountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral1CountQuality > 0">
                  {{ examAverageUliral1CountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral2CountQuality > 0">
                  {{ examAverageUliral2CountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageUliral3CountQuality > 0">
                  {{ examAverageUliral3CountQuality.toFixed(1) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  props: ["selectedLessonExamData", "teacher_id", "course_id", "quarter_id"],
  data() {
    return {
      //Шалгах дата
      dataChecked: [],
      //props-оор орж ирсэн өгөгдлийг шинэчилж авна.
      changeSelectedLessonExamData: [],
      //   symbolGrade: "",
      exam1CountA: [],
      exam1CountB: [],
      exam1CountC: [],
      exam1CountD: [],
      exam1CountF: [],
      exam1Average: [],
      exam1CalsAvg: "",
      exam1CountSuccess: 0,
      exam1CountQuality: 0,
      exam2CountA: [],
      exam2CountB: [],
      exam2CountC: [],
      exam2CountD: [],
      exam2CountF: [],
      exam2Average: [],
      exam2CalsAvg: "",
      exam2Average: [],
      exam2CountSuccess: 0,
      exam2CountQuality: 0,
      exam3CountA: [],
      exam3CountB: [],
      exam3CountC: [],
      exam3CountD: [],
      exam3CountF: [],
      exam3Average: [],
      exam3CalsAvg: "",
      exam3CountSuccess: 0,
      exam3CountQuality: 0,
      exam4CountA: [],
      exam4CountB: [],
      exam4CountC: [],
      exam4CountD: [],
      exam4CountF: [],
      exam4Average: [],
      exam4CalsAvg: "",
      exam4CountSuccess: 0,
      exam4CountQuality: 0,
      exam5CountA: [],
      exam5CountB: [],
      exam5CountC: [],
      exam5CountD: [],
      exam5CountF: [],
      exam5Average: [],
      exam5CalsAvg: "",
      exam5CountSuccess: 0,
      exam5CountQuality: 0,
      exam6CountA: [],
      exam6CountB: [],
      exam6CountC: [],
      exam6CountD: [],
      exam6CountF: [],
      exam6Average: [],
      exam6CalsAvg: "",
      exam6CountSuccess: 0,
      exam6CountQuality: 0,
      exam7CountA: [],
      exam7CountB: [],
      exam7CountC: [],
      exam7CountD: [],
      exam7CountF: [],
      exam7Average: [],
      exam7CalsAvg: "",
      exam7CountSuccess: 0,
      exam7CountQuality: 0,
      exam8CountA: [],
      exam8CountB: [],
      exam8CountC: [],
      exam8CountD: [],
      exam8CountF: [],
      exam8Average: [],
      exam8CalsAvg: "",
      exam8CountSuccess: 0,
      exam8CountQuality: 0,

      examAverageCountA: [],
      examAverageCountB: [],
      examAverageCountC: [],
      examAverageCountD: [],
      examAverageCountF: [],
      examAverageFull: [],
      examCalsAvgFull: "",
      examAverageCountSuccess: 0,
      examAverageCountQuality: 0,

      examAverageQuarterCountA: [],
      examAverageQuarterCountB: [],
      examAverageQuarterCountC: [],
      examAverageQuarterCountD: [],
      examAverageQuarterCountF: [],
      examAverageQuarterFull: [],
      examCalsAvgQuarterFull: "",
      examAverageQuarterCountSuccess: 0,
      examAverageQuarterCountQuality: 0,

      examAverageYearCountA: [],
      examAverageYearCountB: [],
      examAverageYearCountC: [],
      examAverageYearCountD: [],
      examAverageYearCountF: [],
      examAverageYearFull: [],
      examCalsAvgYearFull: "",
      examAverageYearCountSuccess: 0,
      examAverageYearCountQuality: 0,

      //Улирал 1
      examAverageUliral1CountA: [],
      examAverageUliral1CountB: [],
      examAverageUliral1CountC: [],
      examAverageUliral1CountD: [],
      examAverageUliral1CountF: [],
      examAverageUliral1Full: [],
      examCalsAvgUliral1Full: "",
      examAverageUliral1CountSuccess: 0,
      examAverageUliral1CountQuality: 0,
      //Улирал 2
      examAverageUliral2CountA: [],
      examAverageUliral2CountB: [],
      examAverageUliral2CountC: [],
      examAverageUliral2CountD: [],
      examAverageUliral2CountF: [],
      examAverageUliral2Full: [],
      examCalsAvgUliral2Full: "",
      examAverageUliral2CountSuccess: 0,
      examAverageUliral2CountQuality: 0,
      //Улирал 3
      examAverageUliral3CountA: [],
      examAverageUliral3CountB: [],
      examAverageUliral3CountC: [],
      examAverageUliral3CountD: [],
      examAverageUliral3CountF: [],
      examAverageUliral3Full: [],
      examCalsAvgUliral3Full: "",
      examAverageUliral3CountSuccess: 0,
      examAverageUliral3CountQuality: 0,
    };
  },
  computed: {},
  methods: {
    saveStudentFinalYear(examData) {
      //   console.log("Дүнг хадгалах", examData);
      Swal.fire({
        title: "Дүн баталгаажуулах?",
        text:
          examData.student_last_name[0].toUpperCase() +
          examData.student_last_name[1] +
          "." +
          examData.student_name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Дүнг баталгаажуулах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/confirmExamYear", { examData: examData })
            .then((response) => {
              //   console.log(response.data);
            })
            .catch((errors) => {
              console.log(errors);
            });
          Swal.fire(
            "Амжилттай баталгаажууллаа!",
            "Сонгогдсон суралцагчын дүн баталгаажсан.",
            "success"
          );
        }
      });
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return "A+, GPA 4.33 or 4.00";
      } else if (gradeValue >= 93) {
        return "A, GPA 4.00";
      } else if (gradeValue >= 90) {
        return "A-, GPA 3.67";
      } else if (gradeValue >= 87) {
        return "B+, GPA 3.33";
      } else if (gradeValue >= 83) {
        return "B, GPA 3.00";
      } else if (gradeValue >= 80) {
        return "B-, GPA 2.67";
      } else if (gradeValue >= 77) {
        return "C+, GPA 2.33";
      } else if (gradeValue >= 73) {
        return "C, GPA 2.00";
      } else if (gradeValue >= 70) {
        return "C-, GPA 1.67";
      } else if (gradeValue >= 67) {
        return "D+, GPA 1.33";
      } else if (gradeValue >= 63) {
        return "D, GPA 1.00";
      } else if (gradeValue >= 60) {
        return "D-, GPA 0.67";
      } else {
        return "F, GPA 0.00";
      }
    },
    countByMyExams() {
      this.changeSelectedLessonExamData = [...this.selectedLessonExamData];
      //   console.log(
      //     "childComp everyStudent props selectedLessonExamData",
      //     this.selectedLessonExamData
      //   );
      this.changeSelectedLessonExamData.forEach((el, index) => {
        this.exam1Average.push(el.exam1Year);
        this.exam2Average.push(el.exam2Year);
        this.exam3Average.push(el.exam3Year);
        this.exam4Average.push(el.exam4Year);
        this.exam5Average.push(el.exam5Year);
        this.exam6Average.push(el.exam6Year);
        this.exam7Average.push(el.exam7Year);
        this.exam8Average.push(el.exam8Year);
        // this.examAverageFull.push(el.examAverage);
        // this.examAverageQuarterFull.push(el.examAverageQuarter);
        this.examAverageYearFull.push(el.examAverageYear);

        if (el.exam1Year >= 90) {
          this.exam1CountA.push(el.exam1Year);
        } else if (el.exam1Year >= 80) {
          this.exam1CountB.push(el.exam1Year);
        } else if (el.exam1Year >= 70) {
          this.exam1CountC.push(el.exam1Year);
        } else if (el.exam1Year >= 60) {
          this.exam1CountD.push(el.exam1Year);
        } else {
          this.exam1CountF.push(el.exam1Year);
        }

        if (el.exam2Year >= 90) {
          this.exam2CountA.push(el.exam2Year);
        } else if (el.exam2Year >= 80) {
          this.exam2CountB.push(el.exam2Year);
        } else if (el.exam2Year >= 70) {
          this.exam2CountC.push(el.exam2Year);
        } else if (el.exam2Year >= 60) {
          this.exam2CountD.push(el.exam2Year);
        } else {
          this.exam2CountF.push(el.exam2Year);
        }

        if (el.exam3Year >= 90) {
          this.exam3CountA.push(el.exam3Year);
        } else if (el.exam3Year >= 80) {
          this.exam3CountB.push(el.exam3Year);
        } else if (el.exam3Year >= 70) {
          this.exam3CountC.push(el.exam3Year);
        } else if (el.exam3Year >= 60) {
          this.exam3CountD.push(el.exam3Year);
        } else {
          this.exam3CountF.push(el.exam3Year);
        }

        if (el.exam4Year >= 90) {
          this.exam4CountA.push(el.exam4Year);
        } else if (el.exam4Year >= 80) {
          this.exam4CountB.push(el.exam4Year);
        } else if (el.exam4Year >= 70) {
          this.exam4CountC.push(el.exam4Year);
        } else if (el.exam4Year >= 60) {
          this.exam4CountD.push(el.exam4Year);
        } else {
          this.exam4CountF.push(el.exam4Year);
        }

        if (el.exam5Year >= 90) {
          this.exam5CountA.push(el.exam5Year);
        } else if (el.exam5Year >= 80) {
          this.exam5CountB.push(el.exam5Year);
        } else if (el.exam5Year >= 70) {
          this.exam5CountC.push(el.exam5Year);
        } else if (el.exam5Year >= 60) {
          this.exam5CountD.push(el.exam5Year);
        } else {
          this.exam5CountF.push(el.exam5Year);
        }

        if (el.exam6Year >= 90) {
          this.exam6CountA.push(el.exam6Year);
        } else if (el.exam6Year >= 80) {
          this.exam6CountB.push(el.exam6Year);
        } else if (el.exam6Year >= 70) {
          this.exam6CountC.push(el.exam6Year);
        } else if (el.exam6Year >= 60) {
          this.exam6CountD.push(el.exam6Year);
        } else {
          this.exam6CountF.push(el.exam6Year);
        }

        if (el.exam7Year >= 90) {
          this.exam7CountA.push(el.exam7Year);
        } else if (el.exam7Year >= 80) {
          this.exam7CountB.push(el.exam7Year);
        } else if (el.exam7Year >= 70) {
          this.exam7CountC.push(el.exam7Year);
        } else if (el.exam7Year >= 60) {
          this.exam7CountD.push(el.exam7Year);
        } else {
          this.exam7CountF.push(el.exam7Year);
        }

        if (el.exam8Year >= 90) {
          this.exam8CountA.push(el.exam8Year);
        } else if (el.exam8Year >= 80) {
          this.exam8CountB.push(el.exam8Year);
        } else if (el.exam8Year >= 70) {
          this.exam8CountC.push(el.exam8Year);
        } else if (el.exam8Year >= 60) {
          this.exam8CountD.push(el.exam8Year);
        } else {
          this.exam8CountF.push(el.exam8Year);
        }

        // if (el.examAverage >= 90) {
        //   this.examAverageCountA.push(el.examAverage);
        // } else if (el.examAverage >= 80) {
        //   this.examAverageCountB.push(el.examAverage);
        // } else if (el.examAverage >= 70) {
        //   this.examAverageCountC.push(el.examAverage);
        // } else if (el.examAverage >= 60) {
        //   this.examAverageCountD.push(el.examAverage);
        // } else {
        //   this.examAverageCountF.push(el.examAverage);
        // }

        if (el.examAverageYear >= 90) {
          this.examAverageYearCountA.push(el.examAverageYear);
        } else if (el.examAverageYear >= 80) {
          this.examAverageYearCountB.push(el.examAverageYear);
        } else if (el.examAverageYear >= 70) {
          this.examAverageYearCountC.push(el.examAverageYear);
        } else if (el.examAverageYear >= 60) {
          this.examAverageYearCountD.push(el.examAverageYear);
        } else {
          this.examAverageYearCountF.push(el.examAverageYear);
        }
        //Улирал 1
        if (el.uliral_1 >= 90) {
          this.examAverageUliral1CountA.push(el.uliral_1);
        } else if (el.uliral_1 >= 80) {
          this.examAverageUliral1CountB.push(el.uliral_1);
        } else if (el.uliral_1 >= 70) {
          this.examAverageUliral1CountC.push(el.uliral_1);
        } else if (el.uliral_1 >= 60) {
          this.examAverageUliral1CountD.push(el.uliral_1);
        } else {
          this.examAverageUliral1CountF.push(el.uliral_1);
        }
        //Улирал 2
        if (el.uliral_2 >= 90) {
          this.examAverageUliral2CountA.push(el.uliral_2);
        } else if (el.uliral_2 >= 80) {
          this.examAverageUliral2CountB.push(el.uliral_2);
        } else if (el.uliral_2 >= 70) {
          this.examAverageUliral2CountC.push(el.uliral_2);
        } else if (el.uliral_2 >= 60) {
          this.examAverageUliral2CountD.push(el.uliral_2);
        } else {
          this.examAverageUliral2CountF.push(el.uliral_2);
        }
        //Улирал 3
        if (el.uliral_3 >= 90) {
          this.examAverageUliral3CountA.push(el.uliral_3);
        } else if (el.uliral_3 >= 80) {
          this.examAverageUliral3CountB.push(el.uliral_3);
        } else if (el.uliral_3 >= 70) {
          this.examAverageUliral3CountC.push(el.uliral_3);
        } else if (el.uliral_3 >= 60) {
          this.examAverageUliral3CountD.push(el.uliral_3);
        } else {
          this.examAverageUliral3CountF.push(el.uliral_3);
        }
      });
      //Exam1 average утгыг бодож олъё.
      var exam1Sum = 0;
      for (var i = 0; i < this.exam1Average.length; i++) {
        exam1Sum += parseFloat(this.exam1Average[i]); //don't forget to add the base
      }
      this.exam1CalsAvg = exam1Sum / this.exam1Average.length;
      // console.log(this.exam1CalsAvg);
      //Exam2 average утгыг бодож олъё.
      var exam2Sum = 0;
      for (var i = 0; i < this.exam2Average.length; i++) {
        exam2Sum += parseFloat(this.exam2Average[i]); //don't forget to add the base
      }
      this.exam2CalsAvg = exam2Sum / this.exam2Average.length;
      // console.log(this.exam2CalsAvg);
      //Exam3 average утгыг бодож олъё.
      var exam3Sum = 0;
      for (var i = 0; i < this.exam3Average.length; i++) {
        exam3Sum += parseFloat(this.exam3Average[i]); //don't forget to add the base
      }
      this.exam3CalsAvg = exam3Sum / this.exam3Average.length;
      // console.log(this.exam3CalsAvg);
      //Exam4 average утгыг бодож олъё.
      var exam4Sum = 0;
      for (var i = 0; i < this.exam4Average.length; i++) {
        exam4Sum += parseFloat(this.exam4Average[i]); //don't forget to add the base
      }
      this.exam4CalsAvg = exam4Sum / this.exam4Average.length;
      // console.log(this.exam4CalsAvg);
      //Exam5 average утгыг бодож олъё.
      var exam5Sum = 0;
      for (var i = 0; i < this.exam5Average.length; i++) {
        exam5Sum += parseFloat(this.exam5Average[i]); //don't forget to add the base
      }
      this.exam5CalsAvg = exam5Sum / this.exam5Average.length;
      // console.log(this.exam5CalsAvg);
      //Exam6 average утгыг бодож олъё.
      var exam6Sum = 0;
      for (var i = 0; i < this.exam6Average.length; i++) {
        exam6Sum += parseFloat(this.exam6Average[i]); //don't forget to add the base
      }
      this.exam6CalsAvg = exam6Sum / this.exam6Average.length;
      // console.log(this.exam6CalsAvg);
      //Exam7 average утгыг бодож олъё.
      var exam7Sum = 0;
      for (var i = 0; i < this.exam7Average.length; i++) {
        exam7Sum += parseFloat(this.exam7Average[i]); //don't forget to add the base
      }
      this.exam7CalsAvg = exam7Sum / this.exam7Average.length;
      // console.log(this.exam7CalsAvg);
      //Exam8 average утгыг бодож олъё.
      var exam8Sum = 0;
      for (var i = 0; i < this.exam8Average.length; i++) {
        exam8Sum += parseFloat(this.exam8Average[i]); //don't forget to add the base
      }
      this.exam8CalsAvg = exam8Sum / this.exam8Average.length;
      // console.log(this.exam8CalsAvg);
      //Exam нийлбэр average утгыг бодож олъё.
      var examSum = 0;
      for (var i = 0; i < this.examAverageFull.length; i++) {
        examSum += parseFloat(this.examAverageFull[i]); //don't forget to add the base
      }
      this.examCalsAvgFull = examSum / this.examAverageFull.length;
      // console.log(this.exam1CalsAvg);

      //ExamAverageQuarter нийлбэр average утгыг бодож олъё.
      var examQuarterSum = 0;
      for (var i = 0; i < this.examAverageQuarterFull.length; i++) {
        examQuarterSum += parseFloat(this.examAverageQuarterFull[i]); //don't forget to add the base
      }
      this.examCalsAvgQuarterFull =
        examQuarterSum / this.examAverageQuarterFull.length;

      //ExamAverageYear нийлбэр average утгыг бодож олъё.
      var examYearSum = 0;
      for (var i = 0; i < this.examAverageYearFull.length; i++) {
        examYearSum += parseFloat(this.examAverageYearFull[i]); //don't forget to add the base
      }
      this.examCalsAvgYearFull = examYearSum / this.examAverageYearFull.length;

      //Exam1 амжилт чанар
      this.exam1CountSuccess =
        ((this.exam1CountA.length +
          this.exam1CountB.length +
          this.exam1CountC.length +
          this.exam1CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam1CountQuality =
        ((this.exam1CountA.length + this.exam1CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam2 амжилт чанар
      this.exam2CountSuccess =
        ((this.exam2CountA.length +
          this.exam2CountB.length +
          this.exam2CountC.length +
          this.exam2CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam2CountQuality =
        ((this.exam2CountA.length + this.exam2CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam3 амжилт чанар
      this.exam3CountSuccess =
        ((this.exam3CountA.length +
          this.exam3CountB.length +
          this.exam3CountC.length +
          this.exam3CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam3CountQuality =
        ((this.exam3CountA.length + this.exam3CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam4 амжилт чанар
      this.exam4CountSuccess =
        ((this.exam4CountA.length +
          this.exam4CountB.length +
          this.exam4CountC.length +
          this.exam4CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam4CountQuality =
        ((this.exam4CountA.length + this.exam4CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam5 амжилт чанар
      this.exam5CountSuccess =
        ((this.exam5CountA.length +
          this.exam5CountB.length +
          this.exam5CountC.length +
          this.exam5CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam5CountQuality =
        ((this.exam5CountA.length + this.exam5CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam6 амжилт чанар
      this.exam6CountSuccess =
        ((this.exam6CountA.length +
          this.exam6CountB.length +
          this.exam6CountC.length +
          this.exam6CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam6CountQuality =
        ((this.exam6CountA.length + this.exam6CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam7 амжилт чанар
      this.exam7CountSuccess =
        ((this.exam7CountA.length +
          this.exam7CountB.length +
          this.exam7CountC.length +
          this.exam7CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam7CountQuality =
        ((this.exam7CountA.length + this.exam7CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam8 амжилт чанар
      this.exam8CountSuccess =
        ((this.exam8CountA.length +
          this.exam8CountB.length +
          this.exam8CountC.length +
          this.exam8CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam8CountQuality =
        ((this.exam8CountA.length + this.exam8CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар
      this.examAverageCountSuccess =
        ((this.examAverageCountA.length +
          this.examAverageCountB.length +
          this.examAverageCountC.length +
          this.examAverageCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageCountQuality =
        ((this.examAverageCountA.length + this.examAverageCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар улирлын
      this.examAverageQuarterCountSuccess =
        ((this.examAverageQuarterCountA.length +
          this.examAverageQuarterCountB.length +
          this.examAverageQuarterCountC.length +
          this.examAverageQuarterCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageQuarterCountQuality =
        ((this.examAverageQuarterCountA.length +
          this.examAverageQuarterCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар жилийн
      this.examAverageYearCountSuccess =
        ((this.examAverageYearCountA.length +
          this.examAverageYearCountB.length +
          this.examAverageYearCountC.length +
          this.examAverageYearCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageYearCountQuality =
        ((this.examAverageYearCountA.length +
          this.examAverageYearCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //   Ерөнхий амжилт чанар 1-р улирал

      this.examAverageUliral1CountSuccess =
        ((this.examAverageUliral1CountA.length +
          this.examAverageUliral1CountB.length +
          this.examAverageUliral1CountC.length +
          this.examAverageUliral1CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageUliral1CountQuality =
        ((this.examAverageUliral1CountA.length +
          this.examAverageUliral1CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageUliral2CountSuccess =
        ((this.examAverageUliral2CountA.length +
          this.examAverageUliral2CountB.length +
          this.examAverageUliral2CountC.length +
          this.examAverageUliral2CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageUliral2CountQuality =
        ((this.examAverageUliral2CountA.length +
          this.examAverageUliral2CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageUliral3CountSuccess =
        ((this.examAverageUliral3CountA.length +
          this.examAverageUliral3CountB.length +
          this.examAverageUliral3CountC.length +
          this.examAverageUliral3CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageUliral3CountQuality =
        ((this.examAverageUliral1CountA.length +
          this.examAverageUliral3CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
    },
  },
  beforeMount() {
    this.countByMyExams();
    // console.log(this.selectedLessonExamData);

    axios
      .post("/teacher/checkTeacherConfirmExamStudYear", {
        checkData: this.selectedLessonExamData,
        // teacher_id: this.teacher_id,
        course_id: this.course_id,
        quarter_id: this.quarter_id,
      })
      .then((response) => {
        this.dataChecked = response.data.dataChecked;
        // console.log(response.data);
      })
      .catch((errors) => {});
  },
  created() {},
};
</script>

<style></style>
