<template>
    <div class="mt-2">
        <b-button @click.prevent="getSchoolYear()" block variant="outline-primary">Ажилчдыг тухайн байгууллагад
            ажилласан жилээр харах</b-button>
        <div class="row" v-if="isWorkYearView">
            <div class="col-12 col-sm-6">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Ажилчдын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="bar" height="340" class="barChart" :options="optionsSchoolYearAllEmp"
                                :series="seriesSchoolYearAllEmp"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Ажилчдын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="donut" height="350" class="pieChart" :options="optionsSchoolYearAllEmpP"
                                :series="seriesSchoolYearAllEmpP"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <!-- Багш нар харуулах -->
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Багш нарын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="bar" height="320" :options="optionsSchoolYearTeach"
                                :series="seriesSchoolYearTeach"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Багш нарын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="donut" height="360" class="pieChart" :options="optionsSchoolYearTeachP"
                                :series="seriesSchoolYearTeachP"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <div class="card card-secondary collapsed-card" v-for="teachSchoolYear in schoolYearTeach"
                            :key="teachSchoolYear.id">
                            <div class="card-header">
                                <h3 class="card-title" v-if="teachSchoolYear.teacher_tukhain_bai_ajiljil == null">
                                    Ажилласан жил бүртгэлгүй
                                </h3>
                                <h3 class="card-title" v-else>
                                    {{ teachSchoolYear.teacher_tukhain_bai_ajiljil }} - жил
                                </h3>
                                <div class="card-tools">
                                    <span class="badge badge-warning">{{
                                        teachSchoolYear.total
                                    }}</span>
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-0 table-responsive">
                                <table class="table table-bordered">
                                    <thead style="font-size: 100%">
                                        <tr role="row" class="text-center">
                                            <th>Зураг</th>
                                            <th>Овог нэр</th>
                                            <th>Ажилласан жил</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="teachSchoolYearDetal in schoolYearTeachDetal"
                                        :key="teachSchoolYearDetal.id">
                                        <tr v-if="
                                            teachSchoolYear.teacher_tukhain_bai_ajiljil ==
                                            teachSchoolYearDetal.teacher_tukhain_bai_ajiljil
                                        ">
                                            <td class="text-center" tabindex="10" padding="150px">
                                                <img v-if="teachSchoolYearDetal.profile_photo_url != null"
                                                    class="direct-chat-img"
                                                    :src="teachSchoolYearDetal.profile_photo_url" alt="user-avatar" />
                                                <img v-else class="direct-chat-img" src="/images/users/user.png"
                                                    alt="user-avatar" />
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ teachSchoolYearDetal.teacher_last_name[0] }}.{{
                                                    teachSchoolYearDetal.teacher_name
                                                }}
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ teachSchoolYearDetal.teacher_tukhain_bai_ajiljil }} -жил
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <b-button v-if="viewSchoolYearTeach" @click.prevent="getSchoolYearTeachDetal()" block
                            variant="outline-primary">Ажилчдыг харах</b-button>
                    </div>
                </div>
            </div>
            <!-- Ажилчид харуулах -->
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Ажилчдын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="bar" height="320" :options="optionsSchoolYearEmployee"
                                :series="seriesSchoolYearEmployee"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Ажилчдын тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="donut" height="360" class="pieChart" :options="optionsSchoolYearEmployeeP"
                                :series="seriesSchoolYearEmployeeP"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <div class="card card-secondary collapsed-card" v-for="employeeSchoolYear in schoolYearEmployee"
                            :key="employeeSchoolYear.id">
                            <div class="card-header">
                                <h3 class="card-title" v-if="employeeSchoolYear.teacher_tukhain_bai_ajiljil == null">
                                    Ажилласан жил бүртгэлгүй
                                </h3>
                                <h3 class="card-title" v-else>
                                    {{ employeeSchoolYear.teacher_tukhain_bai_ajiljil }} - жил
                                </h3>
                                <div class="card-tools">
                                    <span class="badge badge-warning">{{
                                        employeeSchoolYear.total
                                    }}</span>
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-0 table-responsive">
                                <table class="table table-bordered">
                                    <thead style="font-size: 100%">
                                        <tr role="row" class="text-center">
                                            <th>Зураг</th>
                                            <th>Овог нэр</th>
                                            <th>Ажилласан жил</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="employeeSchoolYearDetal in schoolYearEmployeeDetal"
                                        :key="employeeSchoolYearDetal.id">
                                        <tr v-if="
                                            employeeSchoolYear.teacher_tukhain_bai_ajiljil ==
                                            employeeSchoolYearDetal.teacher_tukhain_bai_ajiljil
                                        ">
                                            <td class="text-center" tabindex="10" padding="150px">
                                                <img v-if="
                                                    employeeSchoolYearDetal.profile_photo_url != null
                                                " class="direct-chat-img"
                                                    :src="employeeSchoolYearDetal.profile_photo_url"
                                                    alt="user-avatar" />
                                                <img v-else class="direct-chat-img" src="/images/users/user.png"
                                                    alt="user-avatar" />
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ employeeSchoolYearDetal.teacher_last_name[0] }}.{{
                                                    employeeSchoolYearDetal.teacher_name
                                                }}
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ employeeSchoolYearDetal.teacher_tukhain_bai_ajiljil }} - жил
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <b-button v-if="viewSchoolYearEmployee" @click.prevent="getSchoolYearEmployeeDetal()" block
                            variant="outline-primary">Ажилчдыг харах</b-button>
                    </div>
                </div>
            </div>
            <!-- Удирдлагууд харуулах -->
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Удирдлагын алба тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="bar" height="320" :options="optionsSchoolYearManag"
                                :series="seriesSchoolYearManag"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Удирдлагын алба тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="donut" height="360" class="pieChart" :options="optionsSchoolYearManagP"
                                :series="seriesSchoolYearManagP"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <div class="card card-secondary collapsed-card" v-for="managSchoolYear in schoolYearManag"
                            :key="managSchoolYear.id">
                            <div class="card-header">
                                <h3 class="card-title" v-if="managSchoolYear.teacher_tukhain_bai_ajiljil == null">
                                    Ажилласан жил бүртгэлгүй
                                </h3>
                                <h3 class="card-title" v-else>
                                    {{ managSchoolYear.teacher_tukhain_bai_ajiljil }} - жил
                                </h3>
                                <div class="card-tools">
                                    <span class="badge badge-warning">{{
                                        managSchoolYear.total
                                    }}</span>
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-0 table-responsive">
                                <table class="table table-bordered">
                                    <thead style="font-size: 100%">
                                        <tr role="row" class="text-center">
                                            <th>Зураг</th>
                                            <th>Овог нэр</th>
                                            <th>Ажилласан жил</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="managSchoolYearDetal in schoolYearManagDetal"
                                        :key="managSchoolYearDetal.id">
                                        <tr v-if="
                                            managSchoolYear.teacher_tukhain_bai_ajiljil ==
                                            managSchoolYearDetal.teacher_tukhain_bai_ajiljil
                                        ">
                                            <td class="text-center" tabindex="10" padding="150px">
                                                <img v-if="managSchoolYearDetal.profile_photo_url != null"
                                                    class="direct-chat-img"
                                                    :src="managSchoolYearDetal.profile_photo_url" alt="user-avatar" />
                                                <img v-else class="direct-chat-img" src="/images/users/user.png"
                                                    alt="user-avatar" />
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ managSchoolYearDetal.teacher_last_name[0] }}.{{
                                                    managSchoolYearDetal.teacher_name
                                                }}
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ managSchoolYearDetal.teacher_tukhain_bai_ajiljil }} - жил
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <b-button v-if="viewSchoolYearManag" @click.prevent="getSchoolYearManagDetal()" block
                            variant="outline-primary">Ажилчдыг харах</b-button>
                    </div>
                </div>
            </div>
            <!-- Цэцэрлэг харуулах -->
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Цэцэрлэг тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="bar" height="320" :options="optionsSchoolYearKg"
                                :series="seriesSchoolYearKg"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <span>
                            <strong class="info-box-text mt-4 text-center">
                                Цэцэрлэг тухайн байгууллагад ажилласан жил</strong>
                            <apexchart type="donut" height="360" class="pieChart" :options="optionsSchoolYearKgP"
                                :series="seriesSchoolYearKgP"></apexchart>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="info-box bg-light">
                    <div class="info-box-content">
                        <div class="card card-secondary collapsed-card" v-for="kgSchoolYear in schoolYearKg"
                            :key="kgSchoolYear.id">
                            <div class="card-header">
                                <h3 class="card-title" v-if="kgSchoolYear.teacher_tukhain_bai_ajiljil == null">
                                    Ажилласан жил бүртгэлгүй
                                </h3>
                                <h3 class="card-title" v-else>
                                    {{ kgSchoolYear.teacher_tukhain_bai_ajiljil }} - жил
                                </h3>
                                <div class="card-tools">
                                    <span class="badge badge-warning">{{
                                        kgSchoolYear.total
                                    }}</span>
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-0 table-responsive">
                                <table class="table table-bordered">
                                    <thead style="font-size: 100%">
                                        <tr role="row" class="text-center">
                                            <th>Зураг</th>
                                            <th>Овог нэр</th>
                                            <th>Ажилласан жил</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="kgSchoolYearDetal in schoolYearKgDetal" :key="kgSchoolYearDetal.id">
                                        <tr v-if="
                                            kgSchoolYear.teacher_tukhain_bai_ajiljil ==
                                            kgSchoolYearDetal.teacher_tukhain_bai_ajiljil
                                        ">
                                            <td class="text-center" tabindex="10" padding="150px">
                                                <img v-if="kgSchoolYearDetal.profile_photo_url != null"
                                                    class="direct-chat-img" :src="kgSchoolYearDetal.profile_photo_url"
                                                    alt="user-avatar" />
                                                <img v-else class="direct-chat-img" src="/images/users/user.png"
                                                    alt="user-avatar" />
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ kgSchoolYearDetal.teacher_last_name[0] }}.{{
                                                    kgSchoolYearDetal.teacher_name
                                                }}
                                            </td>
                                            <td class="text-center" tabindex="10" padding="150px">
                                                {{ kgSchoolYearDetal.teacher_tukhain_bai_ajiljil }} - жил
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <b-button v-if="viewSchoolYearKg" @click.prevent="getSchoolYearKgDetal()" block
                            variant="outline-primary">Ажилчдыг харах</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ./card-body -->
</template>

<script>
export default {
    props: ["year_id"],
    data() {
        return {
            isWorkYearView: false,
            viewSchoolYearTeach: true,
            viewSchoolYearEmployee: true,
            viewSchoolYearManag: true,
            viewSchoolYearKg: true,
            schoolYearTeach: [],
            schoolYearEmployee: [],
            schoolYearManag: [],
            schoolYearKg: [],
            schoolYearTeachDetal: [],
            schoolYearEmployeeDetal: [],
            schoolYearManagDetal: [],
            schoolYearKgDetal: [],
            optionsSchoolYearAllEmp: {
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },

                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesSchoolYearAllEmp: [
                {
                    name: "Ажилчдын тоо",
                    data: [],
                },
            ],
            optionsSchoolYearTeach: {
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },

                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesSchoolYearTeach: [
                {
                    name: "Ажилчдын тоо",
                    data: [],
                },
            ],
            optionsSchoolYearEmployee: {
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },

                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesSchoolYearEmployee: [
                {
                    name: "Ажилчдын тоо",
                    data: [],
                },
            ],
            optionsSchoolYearManag: {
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },

                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesSchoolYearManag: [
                {
                    name: "Ажилчдын тоо",
                    data: [],
                },
            ],
            optionsSchoolYearKg: {
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },

                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesSchoolYearKg: [
                {
                    name: "Ажилчдын тоо",
                    data: [],
                },
            ],
            //Хувиар харуулах
            seriesSchoolYearAllEmpP: [],
            seriesSchoolYearTeachP: [],
            seriesSchoolYearEmployeeP: [],
            seriesSchoolYearManagP: [],
            seriesSchoolYearKgP: [],
            optionsSchoolYearAllEmpP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            optionsSchoolYearTeachP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            optionsSchoolYearEmployeeP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            optionsSchoolYearManagP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            optionsSchoolYearKgP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
        };
    },
    watch: {},
    methods: {
        getSchoolYear() {
            if (!this.isWorkYearView) {
                axios
                    .post("/manager/getSchoolYear", {
                        year: this.year_id,
                    })
                    .then((res) => {
                        this.seriesSchoolYearAllEmp[0].data = res.data.schoolYearTotal;
                        this.optionsSchoolYearAllEmp.xaxis.categories =
                            res.data.schoolYearName;
                        this.seriesSchoolYearTeach[0].data = res.data.schoolYearTotalTeach;
                        this.optionsSchoolYearTeach.xaxis.categories =
                            res.data.schoolYearNameTeach;
                        this.seriesSchoolYearEmployee[0].data =
                            res.data.schoolYearTotalEmployee;
                        this.optionsSchoolYearEmployee.xaxis.categories =
                            res.data.schoolYearNameEmployee;
                        this.seriesSchoolYearManag[0].data = res.data.schoolYearTotalManag;
                        this.optionsSchoolYearManag.xaxis.categories =
                            res.data.schoolYearNameManag;
                        this.seriesSchoolYearKg[0].data = res.data.schoolYearTotalKg;
                        this.optionsSchoolYearKg.xaxis.categories =
                            res.data.schoolYearNameKg;

                        this.seriesSchoolYearAllEmpP = res.data.schoolYearTotal;
                        this.optionsSchoolYearAllEmpP.labels = res.data.schoolYearName;
                        this.seriesSchoolYearTeachP = res.data.schoolYearTotalTeach;
                        this.optionsSchoolYearTeachP.labels = res.data.schoolYearNameTeach;
                        this.seriesSchoolYearEmployeeP = res.data.schoolYearTotalEmployee;
                        this.optionsSchoolYearEmployeeP.labels =
                            res.data.schoolYearNameEmployee;
                        this.seriesSchoolYearManagP = res.data.schoolYearTotalManag;
                        this.optionsSchoolYearManagP.labels = res.data.schoolYearNameManag;
                        this.seriesSchoolYearKgP = res.data.schoolYearTotalKg;
                        this.optionsSchoolYearKgP.labels = res.data.schoolYearNameKg;
                        this.isWorkYearView = !this.isWorkYearView;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else this.isWorkYearView = !this.isWorkYearView;
        },
        getSchoolYearTeachDetal() {
            axios
                .post("/manager/getSchoolYearTeachDetal", {
                    year: this.year_id,
                })
                .then((res) => {
                    this.schoolYearTeach = res.data.schoolYearCountTeach;
                    this.schoolYearTeachDetal = res.data.schoolYearTeachDetal;
                    this.viewSchoolYearTeach = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getSchoolYearEmployeeDetal() {
            axios
                .post("/manager/getSchoolYearEmployeeDetal", {
                    year: this.year_id,
                })
                .then((res) => {
                    this.schoolYearEmployee = res.data.schoolYearCountEmployee;
                    this.schoolYearEmployeeDetal = res.data.schoolYearEmployeeDetal;
                    this.viewSchoolYearEmployee = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getSchoolYearManagDetal() {
            axios
                .post("/manager/getSchoolYearManagDetal", {
                    year: this.year_id,
                })
                .then((res) => {
                    this.schoolYearManag = res.data.schoolYearCountManag;
                    this.schoolYearManagDetal = res.data.schoolYearManagDetal;
                    this.viewSchoolYearManag = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSchoolYearKgDetal() {
            axios
                .post("/manager/getSchoolYearKgDetal", {
                    year: this.year_id,
                })
                .then((res) => {
                    this.schoolYearKg = res.data.schoolYearCountKg;
                    this.schoolYearKgDetal = res.data.schoolYearKgDetal;
                    this.viewSchoolYearKg = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
    font-size: 70%;
}

.pieChart {
    height: 330px;
}

.barChart {
    height: 340px;
}
</style>
