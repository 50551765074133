<template>
  <div>
    <div class="m-2">
      <b-badge
        @click.prevent="addPhysical(student.id, 'physical')"
        variant="success"
        role="button"
      >
        <b-icon
          icon="plus"
          variant="success"
          v-b-popover.hover.top="'Суралцагчын үзүүлэлт нэмэх'"
        ></b-icon>
        Үзүүлэлт нэмэх
      </b-badge>
      <b-badge
        @click.prevent="addPhysical(student.id, 'weight')"
        variant="success"
        role="button"
      >
        <b-icon
          icon="plus"
          variant="success"
          v-b-popover.hover.top="'Суралцагчын жингийн индекс нэмэх'"
        ></b-icon>
        Жингийн индекс нэмэх
      </b-badge>
    </div>
    <b-overlay :show="loadData" rounded="sm" class="mt-3">
      <div
        v-if="
          student.myData.my_body_index_list &&
          student.myData.my_body_index_list.length > 0
        "
        class="row m-0"
      >
        <div class="col-lg-12">
          <div class="card card-warning" id="strategyCard">
            <div class="card-header">
              <h3 class="card-title">Биеийн хэмжилт</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <physical-body-chart
                  class="col-lg-4"
                  v-if="showChart"
                  title="Биеийн жингийн индекс"
                  :nameArr="student.myData.bodyIndexNames"
                  :indexArr="student.myData.bodyIndexArr"
                  :schoolAvgArr="student.myData.bodySchoolIndexAvgArr"
                  :classAvgArr="student.myData.bodyClassIndexAvgArr"
                ></physical-body-chart>
                <physical-body-chart
                  class="col-lg-4"
                  v-if="showChart"
                  title="Өндрийн хэмжилт"
                  :nameArr="student.myData.bodyIndexNames"
                  :indexArr="student.myData.bodyHeightArr"
                  :schoolAvgArr="student.myData.bodySchoolHeightAvgArr"
                  :classAvgArr="student.myData.bodyClassHeightAvgArr"
                ></physical-body-chart>
                <physical-body-chart
                  class="col-lg-4"
                  v-if="showChart"
                  title="Жингийн хэмжилт"
                  :nameArr="student.myData.bodyIndexNames"
                  :indexArr="student.myData.bodyWeightArr"
                  :schoolAvgArr="student.myData.bodySchoolWeightAvgArr"
                  :classAvgArr="student.myData.bodyClassWeightAvgArr"
                ></physical-body-chart>
              </div>
              <div class="row">
                <div
                  class="col-lg-6"
                  v-for="(bodyIndex, index) in student.myData.my_body_index_list"
                  :key="bodyIndex.id"
                >
                  <div :class="['small-box', getIndexColor(bodyIndex.index)]">
                    <div class="inner">
                      <div class="row">
                        <div class="col-lg-4">
                          <small>Биеийн жингийн индекс</small>
                          <h3>{{ bodyIndex.index }}</h3>
                          <p>
                            <b-badge variant="light">
                              {{ getIndexValue(bodyIndex.index) }}
                            </b-badge>
                          </p>
                        </div>
                        <div class="col-lg-4">
                          <small>Өндөр:</small>
                          <b-badge variant="light"> {{ bodyIndex.height }} см </b-badge>
                          <br />
                          <small>Жин:</small>
                          <b-badge variant="light"> {{ bodyIndex.weight }} кг </b-badge>
                          <p>
                            <b-badge variant="light">
                              {{ bodyIndex.description }}
                            </b-badge>
                          </p>
                        </div>
                        <div class="col-lg-4">
                          <b-badge variant="light">
                            {{ index + 1 }}-р хэмжилт
                            <b-badge variant="light" pill>
                              <a href="#" @click.prevent="editStudentIndex(bodyIndex)"
                                ><b-icon icon="pencil-square" variant="primary"></b-icon>
                              </a>
                            </b-badge>
                          </b-badge>
                          <br />
                          <b-badge variant="light" class="text-muted">
                            Огноо:
                            {{ bodyIndex.created_at | dateYearMonthDay }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div class="icon">
                      <i class="ion ion-stats-bars"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div
        v-if="student.myData.my_physicals && student.myData.my_physicals.length > 0"
        class="row m-0 mt-3"
      >
        <div
          class="col-lg-12"
          v-for="myPhysical in student.myData.my_physicals"
          :key="myPhysical.id"
        >
          <div
            class="card card-info mb-3"
            id="strategyCard"
            v-if="myPhysical.guitsetgelList.length > 0"
          >
            <div class="card-header">
              <h3 class="card-title">
                <b-badge variant="light" class="text-wrap">
                  {{ myPhysical.physical_type.name }}
                  <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                  {{ myPhysical.name }}
                </b-badge>
              </h3>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-lg-6">
                  <div
                    class="card card-light mb-3"
                    v-for="guitsetgel in myPhysical.guitsetgelList"
                    :key="guitsetgel.id"
                  >
                    <div class="card-header studentCardHeader">
                      <h3 class="card-title">
                        <b-badge variant="light" pill class="text-wrap">
                          Гүйцэтгэл оруулсан багш:
                          <img
                            alt="Avatar"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              guitsetgel.my_teacher.profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Үнэлгээ оруулсан багш</dt><dd>' +
                              guitsetgel.my_teacher.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              guitsetgel.my_teacher.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              guitsetgel.my_teacher.profile_photo_url
                                ? guitsetgel.my_teacher.profile_photo_url
                                : '/images/users/user.png'
                            "
                          />
                          {{
                            guitsetgel.my_teacher.teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}
                          {{ guitsetgel.my_teacher.teacher_name }}
                        </b-badge>
                      </h3>
                      <div class="card-tools">
                        <b-badge
                          :variant="pointColor(guitsetgel.my_physical_measure.evalution)"
                          pill
                        >
                          Үнэлгээ:
                          {{ guitsetgel.my_physical_measure.point }}
                          {{ guitsetgel.my_physical_measure.evalution }}
                          |
                          {{ guitsetgel.count_value }}
                          <b-badge variant="light" pill>
                            <a
                              href="#"
                              @click.prevent="
                                editPhysicalStudent(
                                  guitsetgel,
                                  myPhysical,
                                  student.student_full_name
                                )
                              "
                              ><b-icon icon="pencil-square" variant="primary"></b-icon>
                            </a>
                          </b-badge>
                        </b-badge>
                      </div>
                    </div>
                    <div class="card-body">
                      Тайлбар:
                      <b-badge variant="light" class="text-wrap">
                        {{ guitsetgel.my_physical_measure.description }}
                      </b-badge>
                      <div>
                        <small class="">Огноо:</small>
                        <b-badge variant="light">
                          {{ guitsetgel.created_at | dateYearMonthDay }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <physical-student-chart
                    v-if="showChart"
                    :nameArr="myPhysical.nameArr"
                    :schoolAvgArr="myPhysical.schoolAvgArr"
                    :classAvgArr="myPhysical.classAvgArr"
                    :childArr="myPhysical.childArr"
                    :minArr="myPhysical.minArr"
                    :maxArr="myPhysical.maxArr"
                  ></physical-student-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="callout callout-light mx-2">
        <p class="text-muted">
          Энэ суралцагчын хүйс болон насанд хамаарагдах бие бялдрын шалгуур ороогүй байна.
        </p>
      </div>
    </b-overlay>
    <b-modal
      size="lg"
      :id="'modal-add-physical' + student.id"
      :title="editMode ? 'Суралцагчын үзүүлэлт засах' : 'Суралцагчын үзүүлэлт нэмэх'"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label>Суралцагч</label>
            <p class="text-muted" v-show="editMode">
              {{ formPhysicalStudent.student_name }}
            </p>
            <b-form-select
              v-show="!editMode"
              v-model="formPhysicalStudent.student_id"
              :options="studentOptions"
              @change="clearFormPhysicalStudent"
              text-field="student_full_name"
              value-field="student_user_id"
            ></b-form-select>
            <has-error :form="formPhysicalStudent" field="student_full_name"></has-error>
          </div>
          <div class="form-group">
            <label>Төрөл</label>
            <b-form-select
              v-model="formPhysicalStudent.physical_type_id"
              :options="physicalTypeOptions"
              text-field="name"
              value-field="id"
              placeholder="Төрөл"
              @change="getStudentPhysicals"
              :class="[
                'form-control',
                {
                  'is-invalid': formPhysicalStudent.errors.has('physical_type_id'),
                },
              ]"
            ></b-form-select>
          </div>
          <div class="form-group">
            <label>Шалгуур</label>
            <multiselect
              v-model="formPhysicalStudent.physical_id"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="id"
              :custom-label="customPhysicalName"
              placeholder="Шалгуур"
              :options="physicalOptions"
              :searchable="true"
              :allow-empty="true"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.gender }}, {{ option.age_start }} - {{ option.age_end }} нас,
                {{ option.name }}
              </template>
            </multiselect>
          </div>
          <div class="form-group">
            <label>{{ isTimeText }}</label>
            <input
              type="number"
              v-model="formPhysicalStudent.count_value"
              :class="[
                'form-control',
                {
                  'is-invalid': formPhysicalStudent.errors.has('count_value'),
                },
              ]"
              :placeholder="isTimeText"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-success"
          v-show="!editMode"
          @click.prevent="createPhysicalStudent"
        >
          Нэмэх
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          v-show="editMode"
          @click.prevent="updatePhysicalStudent"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-dark"
          @click.prevent="cancelEditPhysicalStudent"
          v-show="editMode"
        >
          Болих
        </button>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      :id="'modal-add-weight' + student.id"
      :title="
        editIndexMode
          ? 'Суралцагчын жингийн индекс засах'
          : 'Суралцагчын жингийн индекс нэмэх'
      "
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label>Суралцагч</label>
            <p class="text-muted" v-show="editIndexMode">
              {{ formStudentIndex.student_name }}
            </p>
            <b-form-select
              v-show="!editIndexMode"
              v-model="formStudentIndex.student_id"
              :options="studentOptions"
              text-field="student_full_name"
              value-field="student_user_id"
              :class="[
                'form-control',
                {
                  'is-invalid': formStudentIndex.errors.has('student_id'),
                },
              ]"
            ></b-form-select>
            <has-error :form="formStudentIndex" field="student_full_name"></has-error>
          </div>
          <div class="form-group">
            <label>Өндөр</label>
            <input
              type="number"
              v-model="formStudentIndex.height"
              :class="[
                'form-control',
                {
                  'is-invalid': formStudentIndex.errors.has('height'),
                },
              ]"
              placeholder="Өндөр /см оруулах/"
            />
          </div>
          <div class="form-group">
            <label>Жин</label>
            <input
              type="number"
              v-model="formStudentIndex.weight"
              :class="[
                'form-control',
                {
                  'is-invalid': formStudentIndex.errors.has('weight'),
                },
              ]"
              placeholder="Жин"
            />
          </div>
          <div class="form-group">
            <label>Тайлбар</label>
            <textarea
              type="text"
              v-model="formStudentIndex.description"
              :class="[
                'form-control',
                {
                  'is-invalid': formStudentIndex.errors.has('description'),
                },
              ]"
              placeholder="Тайлбар"
            />
          </div>
          <div v-show="showIndex">
            <label>Жингийн индекс:</label>
            <b-badge :variant="indexColor" pill>
              <strong>{{ formStudentIndex.index }}</strong>
              {{ indexValue }}
            </b-badge>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-success"
          v-show="!editIndexMode"
          @click.prevent="createStudentIndex"
        >
          Нэмэх
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          v-show="editIndexMode"
          @click.prevent="updateStudentIndex"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-dark"
          @click.prevent="cancelEditStudentIndex"
          v-show="editIndexMode"
        >
          Болих
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import physicalStudentChart from "./physicalStudentChart.vue";
import physicalBodyChart from "./physicalBodyChart.vue";
import Multiselect from "vue-multiselect";

export default {
  props: [
    "selectedClass",
    "student",
    "showChart",
    "physicalTypeOptions",
    "studentOptions",
  ],
  components: {
    Multiselect,
    physicalStudentChart: physicalStudentChart,
    physicalBodyChart: physicalBodyChart,
  },

  data() {
    return {
      editMode: false,
      physicalOptions: [],
      formPhysicalStudent: new Form({
        id: null,
        physical_type_id: "",
        physical_id: "",
        class_id: "",
        student_id: "",
        count_value: "",
      }),
      formStudentIndex: new Form({
        id: null,
        class_id: "",
        student_id: "",
        weight: 0,
        height: 0,
        index: 0,
      }),
      editIndexMode: false,
      indexValue: "",
      indexColor: "",
      showIndex: false,
      isTimeText: "Тоо хэмжээ",
      loadData: false,
    };
  },
  watch: {
    "formStudentIndex.weight": function (newVal) {
      let height = this.formStudentIndex.height;
      if (height > 0) {
        this.formStudentIndex.index = ((newVal / (height * height)) * 10000).toFixed(1);
        this.setIndexColor();
      }
    },
    "formStudentIndex.height": function (newVal) {
      let weight = this.formStudentIndex.weight;
      if (weight > 0) {
        this.formStudentIndex.index = ((weight / (newVal * newVal)) * 10000).toFixed(1);
        this.setIndexColor();
      }
    },
    "formPhysicalStudent.physical_id": function (newVal) {
      if (newVal.isTime == 1) this.isTimeText = "Хугацаа оруулна уу";
      else this.isTimeText = "Тоо хэмжээ";
    },
  },
  methods: {
    getMyPhysical() {
      this.loadData = true;
      this.showChart = false;
      axios
        .post("/physical/getMyPhysical", {
          classId: this.selectedClass.id,
          stdId: this.student.student_user_id,
        })
        .then((response) => {
          this.student.myData = response.data.std;
          this.showChart = true;
          this.loadData = false;
        })
        .catch((error) => {
          this.loadData = false;
        });
    },
    getStudentPhysicals() {
      axios
        .post("/physical/getStudentPhysicals/", {
          physical_type_id: this.formPhysicalStudent.physical_type_id,
          selectedStudent: this.formPhysicalStudent.student_id,
        })
        .then((res) => {
          this.physicalOptions = res.data.physicalOptions;
        });
    },
    customPhysicalName({ gender, age_start, age_end, name }) {
      return `${gender}, ${age_start} -
                  ${age_end} нас, ${name}`;
    },
    setIndexColor() {
      if (this.formStudentIndex.index > 0) this.showIndex = true;
      else this.showIndex = false;
      if (this.formStudentIndex.index < 18.5) {
        this.indexValue = "Туранхай";
        this.indexColor = "primary";
      } else if (
        this.formStudentIndex.index >= 18.5 &&
        this.formStudentIndex.index <= 24.9
      ) {
        this.indexValue = "Хэвийн жинтэй";
        this.indexColor = "success";
      } else if (
        this.formStudentIndex.index >= 25 &&
        this.formStudentIndex.index <= 29.9
      ) {
        this.indexValue = "Илүүдэл жинтэй";
        this.indexColor = "warning";
      } else if (
        this.formStudentIndex.index >= 30 &&
        this.formStudentIndex.index <= 34.9
      ) {
        this.indexValue = "Таргалалт I зэрэг";
        this.indexColor = "danger";
      } else if (
        this.formStudentIndex.index >= 35 &&
        this.formStudentIndex.index <= 39.9
      ) {
        this.indexValue = "Таргалалт II зэрэг";
        this.indexColor = "danger";
      } else if (this.formStudentIndex.index >= 40) {
        this.indexValue = "Таргалалт III зэрэг";
        this.indexColor = "danger";
      }
    },
    getIndexValue(index) {
      if (index < 18.5) {
        return "Туранхай";
      } else if (index >= 18.5 && index <= 24.9) {
        return "Хэвийн жинтэй";
      } else if (index >= 25 && index <= 29.9) {
        return "Илүүдэл жинтэй";
      } else if (index >= 30 && index <= 34.9) {
        return "Таргалалт I зэрэг";
      } else if (index >= 35 && index <= 39.9) {
        return "Таргалалт II зэрэг";
      } else if (index >= 40) {
        return "Таргалалт III зэрэг";
      }
    },
    pointColor(evalution) {
      let variantValue = "";
      if (evalution.includes("A")) variantValue = "success";
      else if (evalution.includes("B")) variantValue = "primary";
      else if (evalution.includes("C")) variantValue = "warning";
      else if (evalution.includes("D")) variantValue = "dark";
      else if (evalution.includes("F")) variantValue = "danger";

      return variantValue;
    },
    getIndexColor(index) {
      if (index < 18.5) {
        return "bg-primary";
      } else if (index >= 18.5 && index <= 24.9) {
        return "bg-success";
      } else if (index >= 25 && index <= 29.9) {
        return "bg-warning";
      } else if (index >= 30 && index <= 34.9) {
        return "bg-danger";
      } else if (index >= 35 && index <= 39.9) {
        return "bg-danger";
      } else if (index >= 40) {
        return "bg-danger";
      }
    },
    addPhysical(stdId, type) {
      this.editMode = false;
      this.formPhysicalStudent.reset();
      this.formPhysicalStudent.class_id = this.selectedClass.id;
      this.formPhysicalStudent.student_id = stdId;
      this.formPhysicalStudent.physical_id = "";
      this.getStudentPhysicals();
      this.formStudentIndex.class_id = this.selectedClass.id;
      this.formStudentIndex.student_id = stdId;
      if (type == "physical") this.$bvModal.show("modal-add-physical" + this.student.id);
      else this.$bvModal.show("modal-add-weight" + this.student.id);
    },
    createPhysicalStudent() {
      var physic = this.formPhysicalStudent.physical_id;
      this.formPhysicalStudent.physical_id = this.formPhysicalStudent.physical_id.id;
      this.formPhysicalStudent.class_id = this.selectedClass.id;
      this.formPhysicalStudent
        .post("/physical/createPhysicalStudent/")
        .then((response) => {
          if (response.data.errorMsj == null) {
            this.$toastr.s("Үнэлгээ амжилттай үүсгэлээ.", "Амжилттай");
            this.$bvModal.hide("modal-add-physical" + this.student.id);
            this.getMyPhysical();
            this.formPhysicalStudent.count_value = "";
          } else {
            this.$toastr.e(response.data.errorMsj, "Алдаа гарлаа.");
          }
          this.formPhysicalStudent.physical_id = physic;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editPhysicalStudent(guitsetgel, p, stdName) {
      this.editMode = true;
      this.formPhysicalStudent.reset();
      this.formPhysicalStudent.fill(guitsetgel);
      console.log(this.formPhysicalStudent);
      this.getStudentPhysicals();
      this.formPhysicalStudent.physical_id = p;
      this.formPhysicalStudent.student_name = stdName;
      this.$bvModal.show("modal-add-physical" + this.student.id);
    },
    updatePhysicalStudent() {
      var physic = this.formPhysicalStudent.physical_id;
      this.formPhysicalStudent.physical_id = this.formPhysicalStudent.physical_id.id;
      this.formPhysicalStudent
        .put("/physical/updatePhysicalStudent/" + this.formPhysicalStudent.id)
        .then((response) => {
          if (response.data.errorMsj == null) {
            this.$toastr.s("Амжилттай", "Шинэчилсэн");
            this.formPhysicalStudent.reset();
            this.editMode = false;
            this.$bvModal.hide("modal-add-physical" + this.student.id);
            this.getMyPhysical();
          } else this.$toastr.e(response.data.errorMsj, "Алдаа гарлаа.");
          this.formPhysicalStudent.physical_id = physic;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditPhysicalStudent() {
      this.editMode = false;
      this.$bvModal.hide("modal-add-physical" + this.student.id);
      this.formPhysicalStudent.reset();
    },
    clearFormPhysicalStudent() {
      this.editMode = false;
      let id = this.formPhysicalStudent.id;
      let stdId = this.formPhysicalStudent.student_id;
      this.formPhysicalStudent.reset();
      this.formPhysicalStudent.id = id;
      this.formPhysicalStudent.student_id = stdId;
    },
    createStudentIndex() {
      this.formStudentIndex.class_id = this.selectedClass.id;
      this.formStudentIndex
        .post("/physical/createStudentIndex/")
        .then((response) => {
          this.$toastr.s("Жингий индекс амжилттай үүсгэлээ.", "Амжилттай");
          this.formStudentIndex.reset();
          this.showIndex = 0;
          this.$bvModal.hide("modal-add-weight" + this.student.id);
          this.getMyPhysical();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editStudentIndex(index) {
      this.editIndexMode = true;
      this.formStudentIndex.reset();
      this.formStudentIndex.fill(index);
      this.$bvModal.show("modal-add-weight" + this.student.id);
    },
    updateStudentIndex() {
      this.formStudentIndex
        .put("/physical/updateStudentIndex/" + this.formStudentIndex.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.formStudentIndex.reset();
          this.editIndexMode = false;
          this.showIndex = 0;
          this.$bvModal.hide("modal-add-weight" + this.student.id);
          this.getMyPhysical();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditStudentIndex() {
      this.editIndexMode = false;
      this.$bvModal.hide("modal-add-weight" + this.student.id);
      this.formStudentIndex.reset();
    },
  },
  created() {},
};
</script>

<style scoped></style>
