<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Хоолны ирцийн мэдээ</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <b-form-datepicker
                                id="day-picker"
                                v-model="pickDate"
                                v-bind="labels[locale] || {}"
                                :locale="locale"
                                :start-weekday="weekday"
                                :show-decade-nav="showDecadeNav"
                                :hide-header="hideHeader"
                                size="sm"
                                class="mb-2"
                                ></b-form-datepicker>
                        </div>
                        <div class="col-md-3">
                            <button class="btn bg-gradient-primary btn-sm" @click.prevent="getClasslunchData">Хайх</button>
                        </div>
                    </div>
                </div>
            </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Анги</th>
                <th>Багш</th>
                <th>Тоо</th>
                <th>Огноо</th>
              </tr>
            </thead>
            <tbody v-for="(lunch, index) in lunchData"
                :key="lunch.id">
              <tr v-bind="getTotal(lunch.lunchCount)">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ lunch.className }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ lunch.teacher_last_name[0] }}.
                  {{ lunch.teacher_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                    {{ lunch.lunchCount }}
                </td>
                <td>
                    {{ lunch.dateFormated }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr role="row">
                <th colspan="2"></th>
                <th class="text-right">Нийт:</th>
                <th>{{totalSumm}}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      isCheck: true,
      lunchData: [],
      pickDate: '',
      totalLunch: 0,
      locale: 'mn',
      showDecadeNav: true,
      hideHeader: false,
      weekday: 1,
      labels: {
          mn: {
            labelPrevDecade: 'Өмнөх арван жил',
            labelPrevYear: 'Өмнөх жил',
            labelPrevMonth: 'Өнгөрсөн сар',
            labelCurrentMonth: 'Одоогийн сар',
            labelNextMonth: 'Дараа сар',
            labelNextYear: 'Ирэх жил',
            labelNextDecade: 'Дараагийн арван жил',
            labelToday: 'Өнөөдөр',
            labelSelected: 'Сонгосон огноо',
            labelNoDateSelected: 'Огноо сонгогдоогүй байна',
            labelCalendar: 'Хуанли',
            labelNav: 'Календарийн навигаци',
            labelHelp: 'Хуанлийн огноог удирдахын тулд курсорын товчлуурыг ашиглана уу.'
          }
        }
    };
  },
  methods: {
    getTotal(countL) {
        this.totalLunch += countL
    },
    getClasslunchData() {
      axios
        .get("/reg_emp/getLunchData/"+ this.pickDate)
        .then((response) => {
          this.lunchData = response.data.lunchData;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  computed: {
    totalSumm: function(){
        return this.lunchData.reduce(function(total, item){
            return total + item.lunchCount;
        },0);
    },
  },
  created() {
    //this.getClasslunchData();
  },
};
</script>

<style></style>
