<template>
  <div class="row">
    <!-- /.col -->
    <div class="col-md-12 mt-3">
      <div id="2" class="card card-widget widget-user shadow">
        <div class="card-header">
          <h5 class="card-title">Ангийн суралцагчид дээр ирсэн тэмдэглэл</h5>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="row" v-if="mySchoolClasses.length > 1">
            <label>Анги сонгох</label>
            <multiselect
              v-model="school_class_id"
              :multiple="false"
              deselect-label="Can't remove this value"
              label="full_name"
              track-by="id"
              placeholder="Сонголт хийх"
              :options="mySchoolClasses"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="true"
              :preserve-search="true"
              :hide-selected="true"
            >
              <!-- @select="toggleSelect" -->
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.full_name }} анги</strong>
                сонгогдлоо
              </template>
            </multiselect>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- Анги улирлаар бүх хичээл -->
              <div class="row mt-2" v-if="viewYearSchClaQrtChart">
                <div class="col-12 col-sm-12">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл
                          улирлаар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter1CourseComSchClas"
                          :series="seriesQuarter1CourseComSchClas"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <!-- <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл улирал
                          болон ангиар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter2CourseComSchClas"
                          :series="seriesQuarter2CourseComSchClas"
                        ></apexchart>
                      </span>
                    </div>
                  </div> -->
                </div>
                <div class="col-lg-8">
                  <multiselect
                    v-model="school_class_course_id"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id"
                    label="id"
                    :custom-label="customSchoolClassCoursesName"
                    placeholder="Тухайн ангид хамаарах хичээл сонгоно уу?"
                    :options="allSchoolClassessCourses"
                    :searchable="true"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.full_name }} анги
                        {{ option.subject_name }} хичээл сонгогдлоо
                      </strong></template
                    >
                  </multiselect>
                </div>
                <div class="col-lg-4">
                  <b-button
                    variant="outline-primary"
                    @click.prevent="getAllQrtSchClaCourseDatas()"
                    >Мэдээлэл дуудах</b-button
                  >
                </div>
              </div>
              <div class="row mt-2" v-if="viewYearSchClaQrtCourseChart">
                <div class="col-12 col-sm-12">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл
                          улирлаар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter1CourseComSchClasCourse"
                          :series="seriesQuarter1CourseComSchClasCourse"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <!-- <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл улирал
                          болон ангиар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter2CourseComSchClas"
                          :series="seriesQuarter2CourseComSchClas"
                        ></apexchart>
                      </span>
                    </div>
                  </div> -->
                </div>
                <div class="col-lg-8">
                  <multiselect
                    v-model="school_class_student_id"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="profile_user_id"
                    label="profile_user_id"
                    :custom-label="customSchoolClassStudentName"
                    placeholder="Суралцагч сонгоно уу?"
                    :options="allSchoolClassStudents"
                    :searchable="true"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.student_last_name[0] }}.
                        {{ option.student_name }} сонгогдлоо
                      </strong></template
                    >
                  </multiselect>
                </div>
                <div class="col-lg-4">
                  <b-button
                    variant="outline-primary"
                    @click.prevent="getAllQrtSchClaStuDatas()"
                    >Мэдээлэл дуудах</b-button
                  >
                </div>
              </div>
              <div class="row mt-2" v-if="viewYearSchClaQrtCourseStuChart">
                <div class="col-12 col-sm-12">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл
                          суралцагчаар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter1CourseComSchClasStud"
                          :series="seriesQuarter1CourseComSchClasStud"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <!-- <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Багш нарын хичээлүүд дээр бичсэн тэмдэглэл улирал
                          болон ангиар</strong
                        >
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsQuarter2CourseComSchClas"
                          :series="seriesQuarter2CourseComSchClas"
                        ></apexchart>
                      </span>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-header p-0 pt-1">
                      <ul
                        class="nav nav-tabs"
                        id="custom-tabs-one-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="custom-tabs-one-home-tab"
                            data-toggle="pill"
                            href="#custom-tabs-one-home"
                            role="tab"
                            aria-controls="custom-tabs-one-home"
                            aria-selected="true"
                            >Танилцаагүй</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="custom-tabs-one-profile-tab"
                            data-toggle="pill"
                            href="#custom-tabs-one-profile"
                            role="tab"
                            aria-controls="custom-tabs-one-profile"
                            aria-selected="false"
                            >Танилцсан</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="card-body">
                      <div class="tab-content" id="custom-tabs-one-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="custom-tabs-one-home"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-home-tab"
                        >
                          <div class="card card-default">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fas fa-bullhorn"></i>
                                Таны хүүхэд сургалтын үйл ажиллагаанд оролцох
                                явцад ажилглагдсан ахиц, амжилт, давуу ба сул
                                тал, анхаарах зүйлсийн талаар багш нарын өгсөн
                                мэдээлэл. Хүүхдийг амжилттай суралцахад эцэг эх,
                                багшийн хамтын ажиллагааг бэхжүүлэхэд ашиглана.
                              </h3>
                            </div>
                            <div class="card-body">
                              <div
                                class="callout callout-warning"
                                v-for="(
                                  unReadComment, i
                                ) in myChildCourseCommentunRead"
                                :key="i"
                              >
                                <div class="row">
                                  <div class="col-md-6">
                                    <span style="font-size: 120%">
                                      {{ unReadComment.subject_name }}
                                      {{ unReadComment.teacher_last_name[0] }}.
                                      {{ unReadComment.teacher_name }} багш
                                    </span>
                                    <span style="font-size: 80%">
                                      {{
                                        unReadComment.created_at
                                          | dateYearMonthDay
                                      }}</span
                                    >
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-size: 80%">
                                      <b-icon
                                        v-show="unReadComment.imReadParent == 0"
                                        style="float: right"
                                        icon="heart-fill"
                                        variant="warning"
                                      ></b-icon>
                                      <!-- <b-icon
                                        @click.prevent="
                                          imReadParentConfirm(unReadComment)
                                        "
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon> -->
                                    </span>
                                  </div>
                                </div>
                                <p style="font-size: 80%">
                                  <b
                                    >{{ unReadComment.student_last_name }}
                                    {{ unReadComment.student_name }}:
                                  </b>
                                  {{ unReadComment.comments }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="custom-tabs-one-profile"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-one-profile-tab"
                        >
                          <div class="card card-default">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fas fa-bullhorn"></i>
                                Таны хүүхэд сургалтын үйл ажиллагаанд оролцох
                                явцад ажилглагдсан ахиц, амжилт, давуу ба сул
                                тал, анхаарах зүйлсийн талаар багш нарын өгсөн
                                мэдээлэл. Хүүхдийг амжилттай суралцахад эцэг эх,
                                багшийн хамтын ажиллагааг бэхжүүлэхэд ашиглана.
                              </h3>
                            </div>
                            <div class="card-body">
                              <div
                                class="callout callout-warning"
                                v-for="(
                                  readComm, i
                                ) in myChildCourseCommentRead"
                                :key="i"
                              >
                                <div class="row">
                                  <div class="col-md-6">
                                    <span style="font-size: 120%">
                                      {{ readComm.subject_name }}
                                      {{ readComm.teacher_last_name[0] }}.
                                      {{ readComm.teacher_name }} багш
                                    </span>
                                    <span style="font-size: 80%">
                                      {{
                                        readComm.created_at | dateYearMonthDay
                                      }}</span
                                    >
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-size: 80%">
                                      <!-- @click.prevent="imReadParentConfirm(readComm)" -->
                                      <b-icon
                                        v-show="readComm.imReadParent == 1"
                                        style="float: right"
                                        icon="heart-fill"
                                        variant="success"
                                      ></b-icon>
                                    </span>
                                  </div>
                                </div>

                                <p style="font-size: 80%">
                                  <b
                                    >{{ readComm.student_last_name }}
                                    {{ readComm.student_name }}:
                                  </b>
                                  {{ readComm.comments }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /.card -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- ./card-body -->
      </div>
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //Улирал хичээлээр шүүж харах
      allSchoolClassess: [],
      mySchoolClasses: [],
      school_class_id: null,
      allSchoolQuarters: [],
      viewYearSchClaQrtChart: false,

      //   1-р улирал ангиар
      optionsQuarter1CourseComSchClas: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter1CourseComSchClas: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   2-р улирал ангиар
      optionsQuarter2CourseComSchClas: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter2CourseComSchClas: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   3-р улирал ангиар
      optionsQuarter3CourseComSchClas: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter3CourseComSchClas: [
        {
          name: "series-1",
          data: [],
        },
      ],
      // Хичээлээр
      school_class_course_id: "",
      allSchoolClassessCourses: [],
      viewYearSchClaQrtCourseChart: false,
      //   1-р улирал ангиар
      optionsQuarter1CourseComSchClasCourse: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter1CourseComSchClasCourse: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   2-р улирал ангиар
      optionsQuarter2CourseComSchClasCourse: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter2CourseComSchClasCourse: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   3-р улирал ангиар
      optionsQuarter3CourseComSchClasCourse: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter3CourseComSchClasCourse: [
        {
          name: "series-1",
          data: [],
        },
      ],

      //   суралцагчаар
      allSchoolClassStudents: [],
      viewYearSchClaQrtCourseStuChart: false,
      school_class_student_id: "",
      myChildCourseCommentunRead: [],
      myChildCourseCommentRead: [],
      //   1-р улирал ангиар
      optionsQuarter1CourseComSchClasStud: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter1CourseComSchClasStud: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   2-р улирал ангиар
      optionsQuarter2CourseComSchClasStud: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter2CourseComSchClasStud: [
        {
          name: "series-1",
          data: [],
        },
      ],
      //   3-р улирал ангиар
      optionsQuarter3CourseComSchClasStud: {
        chart: {
          id: "Улирал ба хичээлээр",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "%";
          //   },
          //   offsetY: -20,
          style: {
            fontSize: "6px",
            colors: ["#304758"],
          },
        },
      },
      seriesQuarter3CourseComSchClasStud: [
        {
          name: "series-1",
          data: [],
        },
      ],
    };
  },
  watch: {
    school_class_id() {
      this.viewYearSchClaQrtChart = false;
      this.viewYearSchClaQrtCourseChart = false;
      this.viewYearSchClaQrtCourseStuChart = false;
      axios
        .get("/teacher/getAllQrtSchClaAubTeachDatas/" + this.school_class_id.id)
        .then((res) => {
          //   console.log(res.data);

          this.seriesQuarter1CourseComSchClas[0].data = [];
          this.optionsQuarter1CourseComSchClas.xaxis.categories = [];
          res.data.newArray.forEach((el) => {
            //   console.log(el.quarterArrs_1);
            //   console.log(el.quarterArrs_2);
            //   console.log(el.quarterArrs_3);
            //   1-р улирал
            el.quarterArrs_1.forEach((el1) => {
              // console.log(el1.qAll);
              // console.log(el1.qRead);
              // console.log(el1.qUnread);
              // console.log(el1.q);
              this.seriesQuarter1CourseComSchClas[0].data.push(el1.qUnread);
              this.seriesQuarter1CourseComSchClas[0].data.push(el1.qAll);
              this.seriesQuarter1CourseComSchClas[0].data.push(el1.qRead);

              this.optionsQuarter1CourseComSchClas.xaxis.categories.push(
                el.full_name + " " + el1.q + ": танилцаагүй"
              );
              this.optionsQuarter1CourseComSchClas.xaxis.categories.push(
                el.full_name + " " + el1.q + ": нийт"
              );
              this.optionsQuarter1CourseComSchClas.xaxis.categories.push(
                el.full_name + " " + el1.q + ": танилцсан"
              );
            });
          });
          this.seriesQuarter1CourseComSchClas[0].name =
            "Нийт хичээлүүдийн тэмдэглэл улирал болон ангиар.";
          this.allSchoolClassessCourses = res.data.getAllCoursesClasses;
          this.viewYearSchClaQrtChart = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    // Хичээлээр
    getAllQrtSchClaCourseDatas() {
      this.viewYearSchClaQrtCourseChart = false;
      this.viewYearSchClaQrtCourseStuChart = false;
      axios
        .post("/teacher/getAllQrtSchClaCourseAubTeachDatas", {
          school_class_course_id: this.school_class_course_id,
          classId: this.school_class_id.id,
        })
        .then((res) => {
          //   console.log(res.data);

          this.seriesQuarter1CourseComSchClasCourse[0].data = [];
          this.optionsQuarter1CourseComSchClasCourse.xaxis.categories = [];
          res.data.newArray.forEach((el) => {
            //   console.log(el.quarterArrs_1);
            //   console.log(el.quarterArrs_2);
            //   console.log(el.quarterArrs_3);
            //   1-р улирал
            el.quarterArrs_1.forEach((el1) => {
              // console.log(el1.qAll);
              // console.log(el1.qRead);
              // console.log(el1.qUnread);
              // console.log(el1.q);
              this.seriesQuarter1CourseComSchClasCourse[0].data.push(
                el1.qUnread
              );
              this.seriesQuarter1CourseComSchClasCourse[0].data.push(el1.qAll);
              this.seriesQuarter1CourseComSchClasCourse[0].data.push(el1.qRead);

              this.optionsQuarter1CourseComSchClasCourse.xaxis.categories.push(
                el1.q + ": танилцаагүй"
              );
              this.optionsQuarter1CourseComSchClasCourse.xaxis.categories.push(
                el1.q + ": нийт"
              );
              this.optionsQuarter1CourseComSchClasCourse.xaxis.categories.push(
                el1.q + ": танилцсан"
              );
            });
          });
          this.seriesQuarter1CourseComSchClasCourse[0].name =
            "Нийт хичээлүүдийн тэмдэглэл улирал болон ангиар.";
          this.allSchoolClassStudents = res.data.students;
          this.viewYearSchClaQrtCourseChart = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Суралцагчаар дуудах
    getAllQrtSchClaStuDatas() {
      this.viewYearSchClaQrtCourseStuChart = false;
      axios
        .post("/teacher/getAllQrtSchClaStuAubTeachDatas", {
          school_class_student_id: this.school_class_student_id,
          classId: this.school_class_id.id,
        })
        .then((res) => {
          //   console.log(res.data);

          this.seriesQuarter1CourseComSchClasStud[0].data = [];
          this.optionsQuarter1CourseComSchClasStud.xaxis.categories = [];
          res.data.newArray.forEach((el) => {
            //   console.log(el.quarterArrs_1);
            //   console.log(el.quarterArrs_2);
            //   console.log(el.quarterArrs_3);
            //   1-р улирал
            el.quarterArrs_1.forEach((el1) => {
              // console.log(el1.qAll);
              // console.log(el1.qRead);
              // console.log(el1.qUnread);
              // console.log(el1.q);
              this.seriesQuarter1CourseComSchClasStud[0].data.push(el1.qUnread);
              this.seriesQuarter1CourseComSchClasStud[0].data.push(el1.qAll);
              this.seriesQuarter1CourseComSchClasStud[0].data.push(el1.qRead);

              this.optionsQuarter1CourseComSchClasStud.xaxis.categories.push(
                el.full_name + " " + el1.q + ": танилцаагүй"
              );
              this.optionsQuarter1CourseComSchClasStud.xaxis.categories.push(
                el.full_name + " " + el1.q + ": нийт"
              );
              this.optionsQuarter1CourseComSchClasStud.xaxis.categories.push(
                el.full_name + " " + el1.q + ": танилцсан"
              );
            });
          });
          this.seriesQuarter1CourseComSchClasStud[0].name =
            "Нийт хичээлүүдийн тэмдэглэл улирал болон ангиар.";
          this.viewYearSchClaQrtCourseStuChart = true;
          this.myChildCourseCommentunRead = res.data.myChildCourseCommentunRead;
          this.myChildCourseCommentRead = res.data.myChildCourseCommentRead;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // customYearName({ start_date, finish_date }) {
    //   return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    // },
    // customQuarterName({ quarter_name }) {
    //   return `${quarter_name} - улирал`;
    // },
    // customSchoolClassName({ full_name }) {
    //   return `${full_name} - анги`;
    // },
    customSchoolClassCoursesName({ full_name, subject_name }) {
      return `${full_name} - анги ${subject_name} хичээл`;
    },
    customSchoolClassStudentName({ student_name, student_last_name }) {
      return `${student_last_name[0]}. ${student_name}`;
    },
  },
  created() {
    axios
      .get("/teacher/getMyClasses")
      .then((res) => {
        this.mySchoolClasses = res.data.schoolclasses;
        this.mySchoolClasses.length > 0
          ? (this.school_class_id = this.mySchoolClasses[0])
          : null;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
</style>
