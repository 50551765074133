<template>
  <div class="card card-success card-outline card-outline-tabs mt-3">
    <div class="card-body">
      <div class="tab-content">
        <div class="card card-dark card-outline card-outline-tabs">
          <div class="card-header p-0 pt-1">
            <h3>Гэрийн даалгаварын статистик</h3>
          </div>
          <div class="card-body">
            <div class="card card-default">
              <div class="col-md-12">
                <b-container class="bv-example-row" fluid>
                  <b-row>
                    <b-col sm="3">
                      <multiselect
                        v-model="quarter"
                        deselect-label="Сонголт цуцлах"
                        select-label="Улирал сонгох"
                        track-by="id"
                        :custom-label="customQuarterName"
                        label="name"
                        placeholder="Хичээлийн улирал сонгоно уу?"
                        :options="quarterOptions"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.name }}-р улирал </strong>
                        </template>
                      </multiselect>
                    </b-col>
                    <b-col sm="3">
                      <multiselect
                        v-model="selectedMonth"
                        deselect-label="Сонголт цуцлах"
                        select-label="Сар сонгох"
                        :custom-label="customMonthName"
                        track-by="month_name"
                        label="month_name"
                        placeholder="Хичээлийн сар сонгоно уу?"
                        :options="monthList"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.month_name }}-р сар</strong>
                        </template>
                      </multiselect>
                    </b-col>
                    <b-col sm="3">
                      <multiselect
                        v-model="selectedLesson"
                        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        placeholder="Хичээл сонгоно уу?"
                        track-by="id"
                        label="subject_name"
                        :options="lessonList"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.subject_name }} </strong>
                        </template>
                      </multiselect>
                    </b-col>
                    <b-col sm="3">
                      <multiselect
                        v-model="selectedClass"
                        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        placeholder="Анги сонгоно уу?"
                        track-by="school_class_id"
                        label="full_name"
                        :options="schoolClasses"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.full_name }} </strong>
                        </template>
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <b-row>
                <div class="col-md-6">
                  <span v-if="chart">
                    <div>
                      <apexchart
                        type="bar"
                        height="450"
                        :options="chartHWCOptions"
                        :series="seriesHWC"
                      >
                      </apexchart>
                    </div>
                  </span>
                </div>
                <div class="col-md-6">
                  <span v-if="chart">
                    <div>
                      <apexchart
                        type="bar"
                        height="450"
                        :options="chartHWLOptions"
                        :series="seriesHWL"
                      >
                      </apexchart>
                    </div>
                  </span>
                </div>
                <div class="col-md-12">
                  <span>
                    <div v-if="selectedClass && selectedLesson && chart">
                      <apexchart
                        type="bar"
                        height="450"
                        :options="chartHWClassOptions"
                        :series="seriesHWClass"
                      >
                      </apexchart>
                    </div>
                  </span>
                </div>
                <b-col sm="4" v-if="selectedClass && selectedLesson && chart">
                  <multiselect
                    v-model="selectedChild"
                    deselect-label="Сонголт цуцлах"
                    select-label="Хүүхэд сонгох"
                    placeholder="Хүүхэд сонгоно уу?"
                    track-by="user_id"
                    label="student_name"
                    :options="studOptions"
                    :searchable="false"
                    :allow-empty="false"
                    @input="homeWorkLessonStat"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.student_name }}</strong>
                    </template>
                  </multiselect>
                </b-col>
                <div class="col-md-12" v-if="chartStud">
                  <span v-if="selectedChild">
                    <div>
                      <apexchart
                        type="line"
                        height="350"
                        :options="chartHWStudOptions"
                        :series="seriesHWStud"
                      >
                      </apexchart>
                    </div>
                  </span>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["teacherId"],
  components: {
    Multiselect,
  },
  data() {
    return {
      editMode: "",
      studOptions: [],
      quarterOptions: [],
      monthList: [],
      monthListClass: [],
      months: [],
      lessonList: [],
      lessonListClass: [],
      courseAvgName: [],
      courseLessonName: [],
      selectedClass: "",
      schoolClasses: [],
      chart: false,
      chartClass: false,
      chartStud: false,
      selectedQuarter: 0,
      quarter: "",
      quarterClass: "",
      selectedMonth: null,
      selectedMonthClass: null,
      quarterLesson: "",
      selectedMonthLesson: null,
      selectedChild: "",
      selectedLesson: "",
      selectedLessonClass: "",
      //   гэрийн даалгавар
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selectHWStudents: "",
      homeWorkLoaded: true,
      //   Тэмдэглэл хийсэн
      seriesHWC: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      chartHWCOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesHWL: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      chartHWLOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesHWClass: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      chartHWClassOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
      },
      seriesHWStud: [
        {
          name: "Суралцагчийн үнэлгээ",
          type: "column",
          data: [],
        },
        {
          name: "Суралцагчийн дундаж үнэлгээ",
          type: "line",
          data: [],
        },
      ],
      chartHWStudOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    customMonthName({ month_name }) {
      return `${month_name}-р сар`;
    },
    getSchoolQuarters() {
      axios
        .get("/teacher/getSchoolQuarters")
        .then((response) => {
          this.quarterOptions = response.data.schoolquarters;
        })
        .catch((errors) => {
          console.error(errors);
        });
      axios
        .get("/teacher/getCurrentQuarter")
        .then((response) => {
          this.selectedQuarter = response.data.currentQuarter.id;
        })
        .catch((errors) => {
          console.error(errors);
        });
    },
    homeWorkStat() {
      this.seriesHWC[0].data = [];
      this.chartHWCOptions.xaxis.categories = [];
      this.seriesHWL[0].data = [];
      this.chartHWLOptions.xaxis.categories = [];
      this.seriesHWClass[0].data = [];
      this.chartHWClassOptions.xaxis.categories = [];
      this.chart = false;
      axios
        .post("/teacher/myAllLessonHWStat", {
          quarter: this.quarter.id,
          selectedMonth: this.selectedMonth,
          selectedLesson: this.selectedLesson.id,
          selectedClass: this.selectedClass.school_class_id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.lessonList = res.data.subjs;
          this.schoolClasses = res.data.schoolClasses;
          this.studOptions = res.data.students;
          this.monthList = res.data.monthList;
          this.seriesHWC[0].data = res.data.classAvg;
          this.chartHWCOptions.xaxis.categories = res.data.classAvgName;
          this.seriesHWL[0].data = res.data.lessonAvg;
          this.chartHWLOptions.xaxis.categories = res.data.lessonAvgName;
          this.seriesHWClass[0].data = res.data.studAvg;
          this.chartHWClassOptions.xaxis.categories = res.data.studAvgName;
          console.log("Series Data: ", this.seriesHWClass[0].data);
          console.log(
            "Categories: ",
            this.chartHWClassOptions.xaxis.categories
          );
          this.chart = true;
        })
        .catch((errors) => {
          console.error(errors);
        });
    },
    homeWorkLessonStat() {
      this.seriesHWStud[0].data = [];
      this.seriesHWStud[1].data = [];
      this.chartHWStudOptions.xaxis.categories = [];
      this.chartStud = false;
      axios
        .post("/teacher/myStudHomeWorkLessonStat", {
          quarter: this.quarter.id,
          selectedMonth: this.selectedMonth,
          selectedLesson: this.selectedLesson.id,
          selectedChild: this.selectedChild.user_id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.seriesHWStud[0].data = res.data.hwGrade;
          res.data.hwGrade.forEach(() => {
            this.seriesHWStud[1].data.push(res.data.avg);
          });
          this.chartHWStudOptions.xaxis.categories = res.data.hwName;
          this.chartStud = true;
        })
        .catch((errors) => {
          console.error(errors);
        });
    },
  },
  created() {
    this.homeWorkStat();
    this.getSchoolQuarters();
  },
};
</script>

<style scoped>
.table-avatar {
  width: 50%;
  height: auto;
}
</style>
