import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
//================================ Нүүр хуудас ==============================================
import indexDoctorHome from "../components/Doctor/index.vue";
import indexParentHome from "../components/Parent/HomePage.vue";
import indexStudentHome from "../components/Student/HomePage.vue";
import indexTeacherHome from "../components/Teacher/IndexTeacherHome.vue";
import indexManagerHome from "../components/Manager/ManagerHome.vue";

// nerow
import indexRegEmpHome from "../components/RegEmployer/Index.vue";
import indexPrincipalHome from "../components/Principal/Index.vue";
import indexCurriculumAdministratorHome from "../components/CurrciculumAdministrator/indexHome.vue";
import indexManagerVaaHome from "../components/VaaManager/index.vue";
import indexManagerKgHome from "../components/KgManager/index.vue";
import indexTeacherKgHome from "../components/KgTeacher/index.vue";
import indexHrHome from "../components/Hr/index.vue";
import indexPsychoHome from "../components/Psycho/index.vue";
import indexCelorcounceHome from "../components/Celorcounce/index.vue";
import indexLibrarianHome from "../components/Lib/index.vue";
import indexItHome from "../components/It/index.vue";
import indexVaaAjiltanHome from "../components/VaaAjiltan/index.vue";
import indexFinanceHome from "../components/Finance/index.vue";
import indexPlumberHome from "../components/Plumber/index.vue";
import indexCarpenterHome from "../components/Carpenter/index.vue";
import indexHygienistHome from "../components/Hygienist/index.vue";
import indexServantHome from "../components/Servant/index.vue";
import indexAccountantHome from "../components/Accountant/indexHome.vue";
// Admin - minad
import indexAdminHome from "../components/Admin/Index.vue";
// agermangen
import indexSocialworkerHome from "../components/Socialworker/index.vue";
import indexConsultantHome from "../components/Consultant/index.vue";

//================================ AllWorker ==============================================
import profileEmployee from "../components/Profiles/IndexEmpProfile.vue";
import profile from "../components/Profiles/IndexTeacherProfile.vue";
import IndexRuleOfSurgalt from "../components/AllWorker/Rules/IndexRuleOfSurgalt.vue";
import IndexEeljitKhicheel from "../components/AllWorker/Rules/IndexEeljitKhicheel.vue";
import IndexEeljitKhugjuulekh from "../components/AllWorker/Rules/IndexEeljitKhugjuulekh.vue";
import IndexEeljitSoyolKhuumjil from "../components/AllWorker/Rules/IndexEeljitSoyolKhuumjil.vue";
import EmployeeOgsm from "../components/AllWorker/Ogsm/EmployeeOgsm.vue";
import habits from "../components/AllWorker/Habits/Index.vue";
import habitsTeacher from "../components/AllWorker/Habits/IndexTeacher.vue";
import chat from "../components/All/Chats/ChatForm.vue";
import chatGPT from "../components/ChatGpt/ChatGpt.vue";
import reward from "../components/Manager/Reward/Index.vue";
import rewardSuccess from "../components/Manager/RewardSuccess/Index.vue";
import SchoolElseltShiljiltIndex from "../components/AllWorker/AllSchoolReports/IndexAllSRShiljilt.vue";
// Ажлын үнэлгээ
// import employeeMyEvaluations from "../components/AllWorker/MyWorkEvaluation/Index.vue";//ажилчид
import managerWorkEvaluationsPrincipal from "../components/AllWorker/WorkEvaluationStore/ManagerWorkEvaluation/IndexPrincipal.vue";
import employeeEvaluations from "../components/AllWorker/WorkEvaluationStore/EmployeeWorkEvaluation/Index.vue"; // CurrciculumAdministrator/EmployeeWorkEvaluation/Index.vue";
// import employeeEvaluationsFinance from "../components/AllWorker/WorkEvaluationStore/EmployeeWorkEvaluation/IndexEmployeeFinance.vue"; //Finance/EmployeeWorkEvaluation/IndexEmployeeFinance.vue";
// import employeeEvaluationsVAA from "../components/AllWorker/WorkEvaluationStore/EmployeeWorkEvaluation/IndexEmployeeVAA.vue"; //VaaManager/EmployeeWorkEvaluation/IndexEmployeeVAA.vue";

import managerevaluations from "../components/AllWorker/WorkEvaluationStore/ManagerWorkEvaluation/IndexManager.vue"; //CurrciculumAdministrator/ManagerWorkEvaluation/Index.vue";
import integrationEvaluation from "../components/AllWorker/WorkEvaluationStore/IntegrationEvaluation/indexCurrAdmin.vue"; //CurrciculumAdministrator/IntegrationEvaluation/Index.vue";
import getMyEvaluation from "../components/AllWorker/WorkEvaluationStore/MyWorkEvaluationAllManagers/IndexSchoolManager.vue"; //Manager/MyWorkEvaluation/Index.vue";
// import getMyEvaluationFinance from "../components/AllWorker/WorkEvaluationStore/MyWorkEvaluation/IndexFinance.vue"; //Finance/MyWorkEvaluation/Index.vue";
// import getMyEvaluationAgermanVaa from "../components/AllWorker/WorkEvaluationStore/MyWorkEvaluation/IndexVaaManager.vue"; //VaaManager/MyWorkEvaluation/Index.vue";
// import getMyEvaluationHr from "../components/AllWorker/WorkEvaluationStore/MyWorkEvaluation/IndexHr.vue"; //Hr/MyWorkEvaluation/Index.vue";

import evaluationsMonthAllEmployees from "../components/AllWorker/WorkEvaluationStore/monthEvaluationHR/index.vue"; //Hr/monthEvaluation/Index.vue";
import evaluationsNegtgelAllEmployees from "../components/AllWorker/WorkEvaluationStore/IntegrationEvaluation/IndexHR.vue"; //Hr/IntegrationEvaluation/Index.vue";
import getMyWorkEvaluations from "../components/AllWorker/WorkEvaluationStore/getMyWorkEvaluation/Index.vue"; //Teacher/TeacherWorkEvaluation/Index.vue";

import roomUserItems from "../components/AllWorker/AllSchoolReports/RoomItems/items.vue";
import satisfactions from "../components/SatisfactionForm/satisfaction.vue";
import userForm from "../components/SatisfactionForm/userForm.vue";
import employeeReports from "../components/AllWorker/Report/IndexReport.vue";

//================================ Teacher ==============================================

import monthPlans from "../components/MonthPlan/MonthPlan.vue";
import indexSpecialPlanEmployee from "../components/Psycho/SpecialPlan/indexSpecialPlanEmployee.vue";
import teacherStudents from "../components/Teacher/teacherStudents.vue";
import HowToUse from "../components/Parent/HowToUse/HowToUse.vue";
import IndexGradeBehavior from "../components/Teacher/Grade/GradeBehavior/IndexGradeBehavior.vue";
import teacherComment from "../components/Teacher/CourseComment/CourseCommentStat/Index.vue";
import paragraphContentTeacher from "../components/Teacher/ParagraphContent/Index.vue";
import paragraphReadTeacher from "../components/Teacher/ParagraphRead/Index.vue";
import myEntireClassParagraphReadAndWrite from "../components/Teacher/ParagraphRead/myEntireClassParagraphReadAndWrite.vue";

import classAttend from "../components/Attendance/AttendanceStat/classAttendStat.vue";
import lunchAttendance from "../components/Lunch/lunchAttendance.vue";
import tempAttendance from "../components/Covid19info/tempAttendance.vue";

import exam from "../components/Exams/IndexExam.vue"; //Зөвхөн ББЗ шалгалт
import openProblemExamTeacher from "../components/Teacher/Exams/AllExam/IndexOpenAndBBZExamsTeacher.vue"; //Шалгалтын функцыг нэгтгэх

import myExamStat from "../components/GradeExams/ExamStatForTeachers/ExamStatForTeacher.vue";
import teacherDev from "../components/Project/teacher/Dev.vue";
import teacherCulture from "../components/Project/teacher/Culture.vue";
import physicalGeneral from "../components/GradeBody/PhysicalGeneral.vue";
import physicalMeasure from "../components/Physical/index.vue";
import physicalStudents from "../components/Physical/physicalStudents.vue";
import attendaceteacherstat from "../components/Attendance/AttendanceStat/teachClassAttendStat.vue";
import teacherGetConfirmedGradeDataYear from "../components/Teacher/Grade/GradeConfirm/IndexGradeConfirmYear.vue";
import myClassAllCourseGrade from "../components/Grades/Teachers/IndexMyCourseStudentGrade.vue";
import reportForTeacher from "../components/Teacher/Report/Report.vue";
import taskTeacher from "../components/Teacher/Task/Task.vue";
import myLessonEvaluation from "../components/Teacher/TeacherLessonEvaluation/indexTeacherEvaluation.vue";

import bbzIndex from "../components/CurrciculumAdministrator/Taxanomy/BbzCycleProblem/BbzCycleProblem.vue";
import mybbzIndex from "../components/Teacher/MyBbzProblem/MyBbzProblem.vue";
import myTraining from "../components/Teacher/Developement/MyTraining/IndexMyTraining.vue";
import teacherBudgets from "../components/Budget/teacherBudgets.vue";
import teachNotifi from "../components/Notifications/TeachNotification.vue";
import competitionTeamTeacher from "../components/CompetitionTeam/indexCompetitionTeamTeacher.vue";
import competitionsTeacher from "../components/Competitions/indexCompetitionsTeacher.vue";
import weekendWorkTeacher from "../components/WeekendWork/indexWeekendWorkTeacher.vue";
import successTeacher from "../components/Manager/Success/IndexTeacher.vue";
import indexTeacherHw from "../components/Teacher/Homework/indexHW.vue";
import teacherComplain from "../components/Parent/Complaints/IndexComplainTeacher.vue";
import teacherParentMeet from "../components/Teacher/ParentMeet/IndexParentMeet.vue";
import indexTechnologyCard from "../components/Teacher/TechnologyCard/indexTechnologyCard.vue";

//================================ Manager ==============================================

import managerNotify from "../components/Notifications/ManagNotification.vue";
import ogsmManager from "../components/AllWorker/Ogsm/ManagerOgsm.vue";
import managerMonthPlans from "../components/MonthPlan/MonthPlanManager.vue";
import specialPlan from "../components/Psycho/SpecialPlan/indexSpecialPlan.vue";
import departments from "../components/Depatments/Index.vue";
import quarters from "../components/Quarters/Index.vue";
import attendancetypes from "../components/AttendanceTypes/Index.vue";
import schoolclasses from "../components/SchoolClasses/Index.vue";
import subjects from "../components/Subjects/Index.vue";
import coursetypes from "../components/CourseTypes/Index.vue";

// import examManager from "../components/Manager/Exams/IndexExam.vue";

import IndexOpenExamsEduLevel from "../components/Manager/Exams/AllExam/IndexOpenExamsEduLevel.vue";
import classPromotion from "../components/SchoolClasses/IndexClassPromotion.vue";

import timeTableIndex from "../components/CurrciculumAdministrator/TimeTable/Index.vue";

import teachers from "../components/Users/AllTeachers.vue";
import students from "../components/Users/AllStudents.vue";
import finishedStudents from "../components/Users/AllFinishedStudents.vue";
import parents from "../components/Manager/Parent/parentsList.vue";
import parentsBoards from "../components/Manager/Parent/parentsBoards.vue";
import getParentAppr from "../components/Parent/Appreciations/ApreciationForManager.vue";
import myDepComplaints from "../components/Manager/Complaint/IndexParentComplaint.vue";
import beltgelBvleg from "../components/Manager/BeltgelBvleg/indeBeltgelBvleg.vue";
import leaveTypes from "../components/Manager/Leave/LeaveTypes.vue";
import waitEntrantList from "../components/Manager/Entrant/waitEntrantList.vue";
import paidEntrantList from "../components/Manager/Entrant/paidEntrantList.vue";
import leavedStudents from "../components/Manager/Entrant/leavedStudentsList.vue";
import leavedTeachers from "../components/Manager/Entrant/leavedTeachersList.vue";
import complaintsManager from "../components/Manager/Complaint/Index.vue";
import positions from "../components/Positions/IndexPosition.vue";
import interviews from "../components/Interviews/Index.vue";
import trainings from "../components/Manager/Training/Training.vue";

import lessonEvaluations from "../components/TeacherLessonEvaluations/IndexTeacherEvaluationHome.vue";
import courseComments from "../components/Manager/CourseComment/Index.vue";
import courseCommentStats from "../components/Manager/CourseComment/CourseCommentStat/Index.vue";
import teacherEvaluation from "../components/AllWorker/WorkEvaluationStore/TeacherWorkEvaluationSchManager/index.vue";
import aubTeacherEvaluation from "../components/AllWorker/WorkEvaluationStore/TeacherWorkEvaluationSchManager/IndexAub.vue";

import allTasks from "../components/Manager/Task/Task.vue";
import managerExamStat from "../components/Manager/Grade/GradeExam/IndexExamStat.vue";
import managerConfirmGradeData from "../components/Manager/Grade/GradeExam/GradeConfirm/IndexGradeConfirm.vue";
import managerConfirmGradeDataYear from "../components/Manager/Grade/GradeExam/GradeConfirm/IndexGradeConfirmYear.vue";
import gradeConcateManager from "../components/Manager/Grade/GradeConcate/IndexGradeConcate.vue";
import managerDev from "../components/Project/Manager/Dev.vue";
import managerCulture from "../components/Project/Manager/Culture.vue";
import coursebehaviour from "../components/Manager/Behaviour/Index.vue";
import managerConfirmGradeBehaviorData from "../components/Manager/Grade/GradeBehavior/GradeBehaviorConfirm/IndexGradeBehaviorConfirm.vue";
import managerConfirmGradeBehaviorDataYear from "../components/Manager/Grade/GradeBehavior/GradeBehaviorConfirm/IndexGradeBehaviorConfirmYear.vue";
import paragraphContent from "../components/Manager/ParagraphContent/Index.vue";
import paragraphRead from "../components/Manager/ParagraphRead/Index.vue";
import paragraphStat from "../components/Manager/ParagraphStat/IndexStat.vue";
import managAttendStat from "../components/Attendance/AttendanceStat/managerAttendStat.vue";

import reportSelectedTeacher from "../components/Manager/Report/teacherReport.vue";
import managerLunch from "../components/Manager/attendanceTemp/managerLunchData.vue";
import managerTemp from "../components/Manager/attendanceTemp/managerTempData.vue";
import managerLunchInfo from "../components/Manager/attendanceTemp/lunchInfo.vue";
import budgetType from "../components/Budget/Type.vue";
import allBudget from "../components/Budget/ManagerBudgets.vue";
import managerGradeCourseHwsInfo from "../components/Manager/Grade/GradeCourseHw/IndexHwInfo.vue";
import managerGradeCourseHwsStat from "../components/Manager/Grade/GradeCourseHw/IndexHwStat.vue";
import competitionsManager from "../components/Competitions/indexCompetitionsManager.vue";
import competitionTeamManager from "../components/CompetitionTeam/indexCompetitionTeamManager.vue";
import weekendWork from "../components/WeekendWork/indexWeekendWorksManager.vue";
import allSatisfactions from "../components/SatisfactionForm/manager.vue";
import methods from "../components/SatisfactionForm/method.vue";
import elseltEmployee from "../components/Manager/Report/employeeMigration/empMig.vue";
import elseltStudent from "../components/Manager/Report/studMigration/studMig.vue";
import examStat from "../components/Manager/Report/stat/examStat.vue";
import calendarConfig from "../components/ConfigModule/calendar.vue";
import indexDigitalResourceCategory from "../components/Manager/DigitalResourceCategory/indexDigitalResourceCategory.vue";

//================================ Curriculumadministrator ==============================================

import indexGradeExamStatCurriAdmin from "../components/CurrciculumAdministrator/Statistic/GradeExamStat/IndexGradeExamStat.vue";
import indexGradeBehaviorStatCurriAdmin from "../components/CurrciculumAdministrator/Statistic/GradeBehaviorStat/IndexGradeBehaviorStat.vue";
import indexGradeBehaviorSorilStatCurriAdmin from "..//components/CurrciculumAdministrator/Statistic/GradeBehaviorStat/GradeBehaveSoril/IndexGradeBehaviorSoril.vue";
import getAllTeachLessGenStat from "../components/CurrciculumAdministrator/Statistic/TeacherLessonEvalStat/IndexTeachLessEvalGenStat.vue";
import getAllTeachLessStat from "../components/CurrciculumAdministrator/Statistic/TeacherLessonEvalStat/IndexTeachLessEvalStat.vue";
import trainingPlanSubjectTypeIndex from "../components/CurrciculumAdministrator/TrainingPlanSubjectType/Index.vue";

import TaxanomyTable from "../components/CurrciculumAdministrator/Taxanomy/TaxanomyMentalSystem/TaxanomyTable.vue";
import taxanomy from "../components/CurrciculumAdministrator/Taxanomy/Taxanomy.vue";
import taxanomyLevelName from "../components/CurrciculumAdministrator/Taxanomy/TaxanomyLevelName/TaxanomyLevelName.vue";
import taxanomyLevelNameOperation from "../components/CurrciculumAdministrator/Taxanomy/TaxanomyLevelNameOperation/TaxanomyLevelNameOperation.vue";
import taxanomyLevelNameOperationVerbWord from "../components/CurrciculumAdministrator/Taxanomy/TaxanomyLevelNameOperationVerbWord/taxanomyLevelNameOperationVerbWord.vue";
import taxanomyKnowledgeSystem from "../components/CurrciculumAdministrator/TaxanomyKnowledge/TaxanomyKnowledge.vue";
import taxanomyKnowledgeLname from "../components/CurrciculumAdministrator/TaxanomyKnowledge/TaxanomyKnowledgeLevelName/TaxanomyKnowledgeLevelName.vue";
import taxanomyKnowledgeLNOperation from "../components/CurrciculumAdministrator/TaxanomyKnowledge/TaxanomyKnowledgeLevelNameOperation/TaxanomyKnowledgeLevelNameOperation.vue";
import indexTaxanomyStepExplainSentences from "../components/CurrciculumAdministrator/Taxanomy/TaxanomyLevelNameOperationExplain/TaxanomyLevelNameOperationExplain.vue";
import indexCurrAdmin from "../components/CurrciculumAdministrator/Task/TaskManager.vue";

import schoolDaylyLogReport from "../components/CurrciculumAdministrator/SchoolActivityLog/SchoolActivityLogIndex.vue";
import indexStudentAllInfo from "../components/CurrciculumAdministrator/Statistic/StudentAllInfo/IndexStudentAllInfo.vue";
import indexSurTsogtsBaidal from "../components/Manager/Report/stat/Stat4.vue";
import indexBuildingLoad from "../components/Statistics/Building/Index.vue";

//================================ Shift supervisor  ==============================================
import indexSupervisorShiftHome from "../components/ShiftSupervisor/indexHome";

//================================ Rentpa ==============================================

import parentProfile from "../components/Profiles/IndexParentProfile.vue";

import parentComplainPublic from "../components/Parent/Complaints/IndexComplainPublic.vue";
import parentMyChildCourse from "../components/Parent/Courses/MyChildCourses.vue";
import parentMyChildBehaveCourse from "../components/Parent/Courses/MyChildBehaveCourses.vue";

import indexAppreciationForManager from "../components/Parent/Appreciations/ApreciationForManager.vue";
import parentDev from "../components/Project/Parent/Dev.vue";
import parentCulture from "../components/Project/Parent/Culture.vue";
import parentChilds from "../components/Parent/myChild.vue";
import paragraphReadParent from "../components/Parent/ParagraphRead/MyChildParagraphRead.vue";
import indexParentHomework from "../components/Parent/CourseHomework/MyChildCoursesHomework.vue";
import childAttendStat from "../components/Attendance/AttendanceStat/childAttendStat.vue";
import physicalStudent from "../components/Physical/physicalStudent.vue";
import parentNotification from "../components/Notifications/parentNotification.vue";
import childCalendarPlan from "../components/Plan/Parent/CalendarPlan.vue";
import specialPlanParent from "../components/Psycho/SpecialPlan/indexSpecialPlanParent.vue";
import weekendWorkParent from "../components/WeekendWork/indexWeekendWorkParent.vue";
import competitionsParent from "../components/Competitions/indexCompetitionsParent.vue";
import successParent from "../components/Manager/Success/IndexParent.vue";
import salesBooks from "../components/Books/BookSales.vue";
import myBook from "../components/Books/MyBook.vue";

import indexOpenDay from "../components/Parent/OpenDay/IndexOpenDay.vue";
//================================ Student ==============================================

import studentprofile from "../components/Profiles/IndexStudentProfile.vue";
import myCourses from "../components/Student/Courses/MyCourses.vue";
import myParents from "../components/Student/Parents/MyParents.vue";
import studentDev from "../components/Project/Student/Dev.vue";
import studentCulture from "../components/Project/Student/Culture.vue";
import studentNotify from "../components/Notifications/StudentNotification.vue";

import weekendWorkStudent from "../components/WeekendWork/indexWeekendWorkStudent.vue";
import competitionsStudent from "../components/Competitions/indexCompetitionsStudent.vue";

//================================ Psycho ==============================================
import waitEntrantListPsy from "../components/Psycho/Entrant/waitEntrantList.vue";
import problemCatDetailIndex from "../components/Psycho/ProblemTypeDetail/PTDetail.vue";
import psyMeetIndex from "../components/Psycho/Meet/Index.vue";
// import psychoSpecialPlan from "../components/Psycho/SpecialPlan/indexSpecialPlan.vue";
import indexPsychologyCourseComment from "../components/Psycho/CourseComment/Index.vue";

//================================ HR ==============================================

import complaints from "../components/Hr/Complaint/IndexComplaint.vue";

import hrCurriculumTypeStat from "../components/AllWorker/AllSchoolReports/HrCurriculumTypeStat/IndexCurriculumTypeStat.vue";
import hrAllUsersStat from "../components/AllWorker/AllSchoolReports/HrInfoEmployees/IndexInfoEmployeesStat.vue";
import hrAllUsersParentStat from "../components/AllWorker/AllSchoolReports/HrInfoEmployees/IndexInfoParentsStat.vue";
import indexHrDevStat from "../components/AllWorker/AllSchoolReports/HrDevStat/IndexHrDevStat.vue";
import indexTeacherEvalStat from "../components/AllWorker/AllSchoolReports/HrTeacherEvalStat/IndexHrTeacherEvalStat.vue";
import indexManagerEvalStat from "../components/AllWorker/AllSchoolReports/HrManagerEvalStat/IndexHrManagerEvalStat.vue";
import allComplaintsStatistic from "../components/Hr/Complaint/IndexComplaintStat.vue";
import allComplaints from "../components/Hr/Complaint/IndexComplaint.vue";

import salaryGrades from "../components/Hr/SalaryGrade/IndexSalaryGrade.vue";
import employeeleaveTypes from "../components/Hr/EmployeeLeave/EmployeeLeaveTypes.vue";
import contractType from "../components/Hr/Contract/IndexContractType.vue";
import contract from "../components/Hr/Contract/IndexContract.vue";

import PunishmentType from "../components/Hr/PunishmentType/Index.vue";
import Punishment from "../components/Hr/Punishment/Index.vue";
import CameAndSentDocument from "../components/Hr/CameAndSentDocument/Index.vue";

//================================ Owner ==============================================
import allUsers from "../components/Users/Index.vue";
import years from "../components/Years/Index.vue";
import roles from "../components/Roles/Index.vue";
import allManagers from "../components/Manager/SchoolManager/addManagerSchool.vue";

import allMenus from "../components/ConfigModule/menu.vue";
import allTopMenus from "../components/ConfigModule/topMenu.vue";
import allModules from "../components/ConfigModule/module.vue";
import allSchoolModules from "../components/ConfigModule/schoolModule.vue";
import allModels from "../components/ConfigModule/model.vue";

//================================ Principal ==============================================

import glossaries from "../components/Principal/Glossary/Index.vue";
import principalOgsm from "../components/AllWorker/Ogsm/IndexOgsm.vue";

import educationalLevel from "../components/Principal/Depatments/indexEducationalLevel.vue"; //Боловсролын түвшин
import departmentsCurriculumTeam from "../components/Principal/Depatments/IndexDepCurrTeam.vue"; //Хөтөлбөр хэрэгжүүлэх хэлтэс

import indexRiskManagementsCategory from "../components/Principal/RiskManagement/RiskManagementsCategory.vue"; //Эрсдэлийн удирдлагын ангилал
import indexRiskManagementsDefinition from "../components/Principal/RiskManagement/RiskManagementsDefinition.vue"; //Эрсдэлийн удирдлагын тодорхойлолт
import indexRiskManagementsProbability from "../components/Principal/RiskManagement/RiskManagementsProbability.vue"; //Эрсдэлийн тохиолдох магадлал
import indexRiskManagementsConsecuence from "../components/Principal/RiskManagement/RiskManagementsConsecuence.vue"; //Эрсдэлийн үр дагавар
import indexRiskManagementsMatrice from "../components/Principal/RiskManagement/RiskManagementsMatrice.vue"; //Эрсдэлийн матриц
import indexRiskManagements from "../components/Principal/RiskManagement/RiskManagements.vue"; //Эрсдэлийн бүртгэл
import riskStat from "../components/Principal/RiskManagement/RiskStat.vue"; //Эрсдэлийн stat
import successStat from "../components/Manager/Report/stat/successStat.vue"; //Эрсдэлийн удирдлагын тодорхойлолт
import indexSkillsCategory from "../components/AllWorker/Skills/SkillsCategory.vue"; //Ур чадвар тодорхойлолт

//================================ finance ==============================================
import payEntruntCheckBeltgel from "../components/CurrciculumAdministrator/Finance/payEntrantCheckBeltgel.vue";
import payEntruntCheck from "../components/CurrciculumAdministrator/Finance/payEntruntCheck.vue";

import acceptEntrantBeltgel from "../components/Finance/entrant/acceptEntrantBeltgel.vue";
import acceptEntrant from "../components/Finance/entrant/acceptEntrant.vue";
import usersFinanceStudents from "../components/Finance/StudentPayUpdate/StudentsPayUpdate.vue";
import allFinanceBudget from "../components/Budget/FinanceBudgets.vue";
//================================ accountant ==============================================

//================================ Doctor ==============================================
import doctorLunch from "../components/Doctor/doctorLunchData.vue";
import doctorTemp from "../components/Doctor/doctorTempData.vue";

//================================ RegEmp ==============================================

import regEmpLunch from "../components/RegEmployer/lunchInfo.vue";
import regEmpTempInfo from "../components/RegEmployer/tempInfo.vue";
import regEmpAdmission from "../components/RegEmployer/admission.vue";

//================================ ҮАА-ны менежэр ==============================================
import employees from "../components/Users/AllEmployees.vue";

import rooms from "../components/VaaManager/Room/IndexRoom.vue";
import roomItems from "../components/VaaManager/item/items.vue";
import roomCategories from "../components/VaaManager/RoomCategory/IndexRoomCategory.vue";
//================================ counselor ==============================================

//================================ librarian ==============================================
import libraryBooks from "../components/Books/LibraryBook.vue";

const routes = [
    //================================ RegEmp ==============================
    { path: "/reg_emp/home", component: indexRegEmpHome },
    { path: "/reg_emp/lunchInfo", component: regEmpLunch },
    { path: "/reg_emp/tempInfo", component: regEmpTempInfo },
    { path: "/reg_emp/regEmpAdmission", component: regEmpAdmission },

    //================================ ҮАА-ны менежэр ==========================
    {
        path: "/manager_vaa/home",
        component: indexManagerVaaHome,
        name: "indexManagerVaaHome"
    },
    { path: "/manager_vaa/employees", component: employees, name: "employees" },
    // {
    //     path: "/manager_vaa/employeeEvaluationsVAA",
    //     component: employeeEvaluationsVAA
    // },
    { path: "/manager_vaa/rooms", component: rooms },
    { path: "/manager_vaa/roomItems", component: roomItems },
    { path: "/manager_vaa/roomCategories", component: roomCategories },

    //================================ Цэцэрлэгийн менежер, эрхлэгч ==============================
    {
        path: "/manager_kg/home",
        component: indexManagerKgHome,
        name: "indexManagerKgHome"
    },
    //================================ Цэцэрлэгийн багш ==============================
    {
        path: "/teacher_kg/home",
        component: indexTeacherKgHome,
        name: "indexTeacherKgHome"
    },

    //================================ Owner ==============================
    { path: "/users", component: allUsers },
    { path: "/owner/years", component: years },
    {
        path: "/owner/schools",
        component: () => import("../components/Schools/Index.vue"),
        name: "schools"
    },
    { path: "/owner/roles", component: roles },
    { path: "/owner/allManagers", component: allManagers },
    {
        path: "/owner/allManagersOwner",
        component: () =>
            import("../components/Manager/SchoolManager/addManagerOwner.vue"),
        name: "allManagersOwner"
    },
    { path: "/owner/allMenus", component: allMenus, name: "allMenus" },
    { path: "/owner/allTopMenus", component: allTopMenus },
    { path: "/owner/allModules", component: allModules },
    { path: "/owner/allSchoolModules", component: allSchoolModules },
    { path: "/owner/allModels", component: allModels },

    //================================ Admin  ==============================================
    { path: "/admin/home", component: indexAdminHome },

    //================================ principal ==============================================
    { path: "/principal/home", component: indexPrincipalHome },
    { path: "/principal/glossaries", component: glossaries },
    {
        path: "/principal/ogsm",
        component: principalOgsm,
        name: "principalOgsm"
    },
    {
        path: "/principal/educationalLevel",
        component: educationalLevel,
        name: "educationalLevel"
    },
    {
        path: "/principal/departmentsCurriculumTeam",
        component: departmentsCurriculumTeam,
        name: "departmentsCurriculumTeam"
    },
    {
        path: "/principal/indexDepartments",
        component: () =>
            import("../components/Principal/Depatments/IndexDep.vue"), //Багш нарын баг
        name: "indexDepartments"
    },
    {
        path: "/principal/indexRiskManagementsCategory",
        component: indexRiskManagementsCategory,
        name: "indexRiskManagementsCategory"
    },
    {
        path: "/principal/indexRiskManagementsDefinition",
        component: indexRiskManagementsDefinition,
        name: "indexRiskManagementsDefinition"
    },
    {
        path: "/principal/indexRiskManagementsProbability",
        component: indexRiskManagementsProbability,
        name: "indexRiskManagementsProbability"
    },
    {
        path: "/principal/indexRiskManagementsConsecuence",
        component: indexRiskManagementsConsecuence,
        name: "indexRiskManagementsConsecuence"
    },
    {
        path: "/principal/indexRiskManagementsMatrice",
        component: indexRiskManagementsMatrice,
        name: "indexRiskManagementsMatrice"
    },
    {
        path: "/principal/indexRiskManagements",
        component: indexRiskManagements,
        name: "indexRiskManagements"
    },
    {
        path: "/principal/riskStat",
        component: riskStat,
        name: "riskStat"
    },
    {
        path: "/principal/successStat",
        component: successStat,
        name: "successStat"
    },
    {
        path: "/principal/indexSkillsCategory",
        component: indexSkillsCategory,
        name: "indexSkillsCategory"
    },
    // {
    //     path: "/principal/indexDepartments",
    //     component: indexDepartments,
    //     name: "indexDepartments"
    // },
    {
        path: "/principal/indexMarketResearchForSchool",
        component: () =>
            import(
                "../components/Schools/MarketResearch/indexMarketResearchForSchool.vue"
            ), //зах зээлийн судалгаа
        name: "indexMarketResearchForSchool"
    },

    //================================ Shift supervisor  ==============================================
    {
        path: "/shiftSupervisor/indexSupervisorShiftHome",
        component: indexSupervisorShiftHome
    },
    //================================ HR ==============================
    { path: "/hr/home", component: indexHrHome },
    { path: "/hr/complaints", component: complaints },
    {
        path: "/hr/evaluationsMonthAllEmployees",
        component: evaluationsMonthAllEmployees
    },
    {
        path: "/hr/evaluationsNegtgelAllEmployees",
        component: evaluationsNegtgelAllEmployees
    },
    // {
    //     path: "/hr/evaluationsMonthAllEmployees",
    //     component: evaluationsMonthAllEmployees
    // },
    // {
    //     path: "/hr/evaluationsNegtgelAllEmployees",
    //     component: evaluationsNegtgelAllEmployees
    // },
    {
        path: "/hr/hrWorkGradeStatStat",
        component: () =>
            import(
                "../components/AllWorker/AllSchoolReports/HrWorkGradeStat/IndexWorkGradeStat.vue"
            ),
        name: "hrWorkGradeStatStat"
    },
    {
        path: "/hr/hrCurriculumTypeStat",
        component: hrCurriculumTypeStat,
        name: "hrCurriculumTypeStat"
    },
    {
        path: "/hr/hrAllUsersStat",
        component: hrAllUsersStat,
        name: "hrAllUsersStat"
    },
    { path: "/hr/hrAllUsersParentStat", component: hrAllUsersParentStat },
    { path: "/hr/indexHrDevStat", component: indexHrDevStat },
    { path: "/hr/indexTeacherEvalStat", component: indexTeacherEvalStat },
    { path: "/hr/indexManagerEvalStat", component: indexManagerEvalStat },
    { path: "/hr/allComplaintsStatistic", component: allComplaintsStatistic },
    { path: "/hr/allComplaints", component: allComplaints },
    {
        path: "/hr/document",
        component: () => import("../components/Hr/Document/IndexDocument.vue"),
        name: "document"
    },
    {
        path: "/hr/indexprocessingofdocuments",
        component: () =>
            import(
                "../components/Hr/Document/Report/IndexProcessingOfDocuments.vue"
            ),
        name: "indexprocessingofdocuments"
    },
    {
        path: "/hr/documentClassify",
        component: () =>
            import("../components/Hr/Document/IndexDocumentClassify.vue"),
        name: "documentClassify"
    },
    {
        path: "/hr/documentClassifyItem",
        component: () =>
            import("../components/Hr/Document/IndexDocumentClassifyItem.vue"),
        name: "documentClassifyItem"
    },

    {
        path: "/hr/documentStatuses",
        component: () =>
            import("../components/Hr/Document/IndexDocumentStatuses.vue"),
        name: "documentStatuses"
    },
    {
        path: "/hr/documentDepartments",
        component: () =>
            import("../components/Hr/Document/IndexDocumentDeps.vue"),
        name: "documentDepartments"
    },

    {
        path: "/hr/documentReport",
        component: () =>
            import("../components/Hr/Document/Report/indexDocumentReport.vue"),
        name: "documentReport"
    },

    {
        path: "/hr/salaryGrades",
        component: salaryGrades,
        name: "salaryGrades"
    },
    {
        path: "/hr/employeeleaveTypes",
        component: employeeleaveTypes,
        name: "employeeleaveTypes"
    },

    {
        path: "/hr/contractType",
        component: contractType,
        name: "contractType"
    },
    {
        path: "/hr/contract",
        component: contract,
        name: "contract"
    },
    {
        path: "/hr/PunishmentType",
        component: PunishmentType,
        name: "PunishmentType"
    },
    { path: "/hr/Punishment", component: Punishment, name: "Punishment" },
    {
        path: "/hr/CameAndSentDocument",
        component: CameAndSentDocument,
        name: "CameAndSentDocument"
    },

    //================================ Curriculumadministrator ==============================

    {
        path: "/curriculumadministrator/home",
        component: indexCurriculumAdministratorHome
    },
    {
        path: "/curriculumadministrator/gradeExamStat",
        component: indexGradeExamStatCurriAdmin
    },
    {
        path: "/curriculumadministrator/gradeBehaviorStat",
        component: indexGradeBehaviorStatCurriAdmin
    },
    {
        path: "/curriculumadministrator/soril",
        component: indexGradeBehaviorSorilStatCurriAdmin
    },
    {
        path: "/curriculumadministrator/getAllTeachLessGenStat",
        component: getAllTeachLessGenStat
    },
    {
        path: "/curriculumadministrator/getAllTeachLessStat",
        component: getAllTeachLessStat
    },
    {
        path: "/curriculumadministrator/trainingPlanSubjectTypeIndex",
        component: trainingPlanSubjectTypeIndex
    },
    {
        path: "/curriculumadministrator/trainingPlanIndex",
        component: () =>
            import(
                "../components/CurrciculumAdministrator/TrainingPlan/Index.vue"
            ),
        name: "trainingPlanIndex"
    },
    {
        path: "/curriculumadministrator/usersEmployees",
        component: () =>
            import(
                "../components/Owner/SchoolEmployees/addEmployeesSchool.vue"
            ),
        name: "usersEmployees"
    },
    {
        path: "/curriculumadministrator/TaxanomyTable",
        component: TaxanomyTable,
        name: "TaxanomyTable"
    },
    // console.log('TaxanomyTable: З') //Устгаж болохгүй
    {
        path: "/curriculumadministrator/taxanomy",
        component: taxanomy,
        name: "taxanomy"
    },
    {
        path: "/curriculumadministrator/taxanomyLevelName",
        component: taxanomyLevelName
    },
    {
        path: "/curriculumadministrator/taxanomyLevelNameOperation",
        component: taxanomyLevelNameOperation
    },
    {
        path: "/curriculumadministrator/taxanomyLevelNameOperationVerbWord",
        component: taxanomyLevelNameOperationVerbWord
    },
    {
        path: "/curriculumadministrator/taxanomyKnowledgeSystem",
        component: taxanomyKnowledgeSystem,
        name: "taxanomyKnowledgeSystem"
    },
    {
        path: "/curriculumadministrator/taxanomyKnowledgeLname",
        component: taxanomyKnowledgeLname
    },
    {
        path: "/curriculumadministrator/taxanomyKnowledgeLNOperation",
        component: taxanomyKnowledgeLNOperation
    },
    {
        path: "/curriculumadministrator/indexTaxanomyStepExplainSentences",
        component: indexTaxanomyStepExplainSentences
    },
    {
        path: "/curriculumadministrator/indexCurrAdmin",
        component: indexCurrAdmin,
        name: "indexCurrAdmin"
    },
    {
        path: "/curriculumadministrator/integrationEvaluation",
        component: integrationEvaluation
    },
    {
        path: "/curriculumadministrator/schoolDaylyLogReport",
        component: schoolDaylyLogReport
    },
    {
        path: "/curriculumadministrator/indexStudentAllInfo",
        component: indexStudentAllInfo,
        name: "indexStudentAllInfo"
    },
    {
        path: "/curriculumadministrator/trainingPlanSubjectIndex",
        component: () =>
            import(
                "../components/CurrciculumAdministrator/TrainingPlanSubject/Index.vue"
            ),
        name: "trainingPlanSubjectIndex"
    },
    {
        path: "/curriculumadministrator/KnowledgeTaxanomy",
        component: () =>
            import(
                "../components/CurrciculumAdministrator/TaxanomyKnowledge/KnowledgeTaxanomy/KnowledgeTaxanomy.vue"
            ),
        name: "KnowledgeTaxanomy"
    },

    {
        path: "/curriculumadministrator/timeTableIndex",
        component: timeTableIndex,
        name: "timeTableIndex"
    },

    {
        path: "/curriculumadministrator/indexSurTsogtsBaidal",
        component: indexSurTsogtsBaidal,
        name: "indexSurTsogtsBaidal"
    },
    {
        path: "/curriculumadministrator/indexBuildingLoad",
        component: indexBuildingLoad,
        name: "indexBuildingLoad"
    },

    //================================ Manager ==============================================
    { path: "/manager/home", component: indexManagerHome },
    { path: "/manager/managerNotify", component: managerNotify },
    { path: "/manager/ogsmManager", component: ogsmManager },
    {
        path: "/manager/managerMonthPlans",
        component: managerMonthPlans,
        name: "managerMonthPlans"
    },
    { path: "/psycho/specialPlan", component: specialPlan },
    { path: "/manager/departments", component: departments },
    { path: "/manager/quarters", component: quarters },
    { path: "/manager/attendancetypes", component: attendancetypes },
    { path: "/manager/schoolclasses", component: schoolclasses },
    { path: "/manager/subjects", component: subjects },
    { path: "/manager/coursetypes", component: coursetypes },
    {
        path: "/manager/courses",
        component: () => import("../components/Courses/Index.vue"),
        name: "courses"
    },
    // { path: "/manager/examManager", component: examManager },
    {
        path: "/manager/allIndexOpenProblemExam",
        component: () =>
            import(
                "../components/Manager/Exams/AllExam/IndexOpenAndBBZExams.vue"
            ),
        name: "allIndexOpenProblemExam"
    },
    {
        path: "/manager/IndexOpenExamsEduLevel",
        component: IndexOpenExamsEduLevel,
        name: "IndexOpenExamsEduLevel"
    },
    { path: "/manager/classPromotion", component: classPromotion },
    {
        path: "/manager/calendarPlan",
        component: () => import("../components/Plan/Manager/CalendarPlan.vue"),
        name: "managerCalendarPlan"
    },
    { path: "/manager/teachers", component: teachers },
    { path: "/manager/students", component: students },
    { path: "/manager/finishedStudents", component: finishedStudents },
    { path: "/manager/parents", component: parents, name: "parents" },
    {
        path: "/manager/parentsBoards",
        component: parentsBoards,
        name: "parentsBoards"
    },
    { path: "/manager/getParentAppr", component: getParentAppr },
    { path: "/manager/myDepComplaints", component: myDepComplaints },
    { path: "/manager/beltgelBvleg", component: beltgelBvleg },
    { path: "/manager/leaveTypes", component: leaveTypes, name: "leaveTypes" },
    { path: "/manager/waitEntrantList", component: waitEntrantList },
    { path: "/manager/paidEntrantList", component: paidEntrantList },
    {
        path: "/manager/leavedStudents",
        component: leavedStudents,
        name: "leavedStudents"
    },
    {
        path: "/manager/leavedTeachers",
        component: leavedTeachers,
        name: "leavedTeachers"
    },
    { path: "/manager/complaintsManager", component: complaintsManager },
    { path: "/manager/positions", component: positions, name: "positions" },
    { path: "/manager/interviews", component: interviews },
    { path: "/manager/trainings", component: trainings },
    {
        path: "/manager/lessonEvaluations",
        component: lessonEvaluations,
        name: "lessonEvaluations"
    },
    { path: "/manager/courseComments", component: courseComments },
    { path: "/manager/courseCommentStats", component: courseCommentStats },

    { path: "/manager/allTasks", component: allTasks, name: "allTasks" },
    { path: "/manager/managerExamStat", component: managerExamStat },
    {
        path: "/manager/managerConfirmGradeData",
        component: managerConfirmGradeData
    },
    {
        path: "/manager/IndexGradeComfirmCheck",
        component: () =>
            import(
                "../components/Manager/Grade/GradeExam/GradeConfirm/IndexGradeComfirmCheck.vue"
            ),
        name: "IndexGradeComfirmCheck"
    },
    {
        path: "/manager/gradeCourseHwLoad",
        component: () =>
            import("../components/Manager/Grade/GradeCourseHw/indexHWLoad.vue"),
        name: "gradeCourseHwLoad"
    },
    {
        path: "/manager/managerConfirmGradeDataYear",
        component: managerConfirmGradeDataYear
    },
    { path: "/manager/gradeConcateManager", component: gradeConcateManager },
    { path: "/manager/managerDev", component: managerDev },
    { path: "/manager/managerCulture", component: managerCulture },
    { path: "/manager/coursebehaviour", component: coursebehaviour },
    {
        path: "/manager/managerConfirmGradeBehaviorData",
        component: managerConfirmGradeBehaviorData
    },
    {
        path: "/manager/managerConfirmGradeBehaviorDataYear",
        component: managerConfirmGradeBehaviorDataYear
    },
    { path: "/manager/paragraphContent", component: paragraphContent },
    { path: "/manager/paragraphRead", component: paragraphRead },
    {
        path: "/manager/paragraphStat",
        component: paragraphStat,
        name: "paragraphStat"
    },
    { path: "/manager/managAttendStat", component: managAttendStat },
    {
        path: "/manager/reportForManager",
        component: () => import("../components/Manager/Report/Report.vue"),
        name: "reportForManager"
    },
    {
        path: "/manager/reportSelectedTeacher",
        component: reportSelectedTeacher
    },
    { path: "/manager/managerLunch", component: managerLunch },
    { path: "/manager/managerTemp", component: managerTemp },
    { path: "/manager/managerLunchInfo", component: managerLunchInfo },
    { path: "/manager/budgetType", component: budgetType },
    { path: "/manager/allBudget", component: allBudget },
    {
        path: "/manager/gradeCourseHwsInfo",
        component: managerGradeCourseHwsInfo
    },
    {
        path: "/manager/gradeCourseHwsStat",
        component: managerGradeCourseHwsStat
    },
    {
        path: "/manager/competitionsManager",
        component: competitionsManager,
        name: "competitionsManager"
    },
    {
        path: "/manager/competitionTeamManager",
        component: competitionTeamManager,
        name: "competitionTeamManager"
    },
    {
        path: "/manager/weekendWork",
        component: weekendWork,
        name: "weekendWork"
    },
    {
        path: "/manager/success",
        component: () => import("../components/Manager/Success/Index.vue"),
        name: "success"
    },
    { path: "/manager/allSatisfactions", component: allSatisfactions },
    { path: "/manager/methods", component: methods },
    {
        path: "/manager/elseltEmployee",
        component: elseltEmployee,
        name: "elseltEmployee"
    },
    {
        path: "/manager/elseltStudent",
        component: elseltStudent,
        name: "elseltStudent"
    },
    { path: "/manager/examStat", component: examStat, name: "examStat" },
    {
        path: "/manager/calendarConfig",
        component: calendarConfig,
        name: "calendarConfig"
    },
    {
        path: "/manager/indexDigitalResourceCategory",
        component: indexDigitalResourceCategory,
        name: "indexDigitalResourceCategory"
    },

    {
        path: "/manager/managerParentPlans",
        component: () => import("../components/Plan/Parent/parentPlan.vue"),
        name: "managerParentPlans"
    },
    {
        path: "/manager/managerEmployeeChildrenStat",
        component: () =>
            import(
                "../components/Manager/Report/stat/EmployeeChildrenStat.vue"
            ),
        name: "managerEmployeeChildrenStat"
    },

    //================================ All worker ==============================================
    {
        path: "/all_worker_functions/profileEmployee",
        component: profileEmployee
    },
    { path: "/all_worker_functions/index", component: indexTeacherHome }, //Энэ ямар учиртай юм болчив
    { path: "/all_worker_functions/profile", component: profile },
    {
        path: "/all_worker_functions/managerWorkEvaluationsPrincipal",
        component: managerWorkEvaluationsPrincipal,
        name: "managerWorkEvaluationsPrincipal"
    },
    {
        path: "/all_worker_functions/employeeEvaluations",
        component: employeeEvaluations
    },
    {
        path: "/all_worker_functions/getMyWorkEvaluations",
        component: getMyWorkEvaluations
    },
    {
        path: "/all_worker_functions/teacherEvaluation",
        component: teacherEvaluation
    },
    { path: "/manager/aubTeacherEvaluation", component: aubTeacherEvaluation },
    {
        path: "/all_worker_functions/getMyEvaluation",
        component: getMyEvaluation,
        name: "getMyEvaluation"
    },
    // {
    //     path: "/finance/getMyEvaluationFinance",
    //     component: getMyEvaluationFinance,
    //     name: "getMyEvaluationFinance"
    // },
    // {
    //     path: "/manager_vaa/getMyEvaluationAgermanVaa",
    //     component: getMyEvaluationAgermanVaa,
    //     name: "getMyEvaluationAgermanVaa"
    // },
    // {
    //     path: "/hr/getMyEvaluationHr",
    //     component: getMyEvaluationHr,
    //     name: "getMyEvaluationHr"
    // },
    // {
    //     path: "/all_worker_functions/employeeEvaluations",
    //     component: employeeMyEvaluations
    // },
    {
        path: "/all_worker_functions/managerevaluations",
        component: managerevaluations
    },
    {
        path: "/all_worker_functions/indexRuleOfSurgalt",
        component: IndexRuleOfSurgalt
    },
    {
        path: "/all_worker_functions/indexEeljitKhicheel",
        component: IndexEeljitKhicheel
    },
    {
        path: "/all_worker_functions/IndexEeljitKhugjuulekh",
        component: IndexEeljitKhugjuulekh
    },
    {
        path: "/all_worker_functions/IndexEeljitSoyolKhuumjil",
        component: IndexEeljitSoyolKhuumjil
    },
    {
        path: "/all_worker_functions/ogsm/ogsmEmployee",
        component: EmployeeOgsm
    },
    { path: "/all_worker_functions/monthPlans", component: monthPlans },
    { path: "/all_worker_functions/myTraining", component: myTraining },
    { path: "/all_worker_functions/reward", component: reward, name: "reward" },
    {
        path: "/all_worker_functions/rewardSuccess",
        component: rewardSuccess,
        name: "rewardSuccess"
    },
    { path: "/all_worker_functions/teacherBudgets", component: teacherBudgets },
    { path: "/all_worker_functions/habitsTeacher", component: habitsTeacher },
    { path: "/all_worker_functions/habits", component: habits, name: "habits" },
    { path: "/all_worker_functions/chat", component: chat },
    { path: "/all_worker_functions/chatGPT", component: chatGPT },

    {
        path: "/all_worker_functions/SchoolElseltShiljiltIndex",
        component: SchoolElseltShiljiltIndex
    },
    { path: "/all_workers/roomUserItems", component: roomUserItems },
    { path: "/all_worker_functions/satisfactions", component: satisfactions },
    { path: "/all_worker_functions/userForm", component: userForm },
    {
        path: "/all_worker_functions/documentEmployees",
        component: () =>
            import("../components/Hr/Document/IndexDocumentEmployees.vue"),
        name: "documentEmployees"
    },
    {
        path: "/all_worker_functions/SkillEvaluationsForEmployee",
        component: () =>
            import(
                "../components/AllWorker/Skills/SkillEvaluationsForEmployee.vue"
            ),
        name: "SkillEvaluationsForEmployee"
    },
    {
        path: "/all_worker_functions/employeeReports",
        component: employeeReports,
        name: "employeeReports"
    },

    //================================ Teacher ==============================================
    { path: "/teacher/home", component: indexTeacherHome },
    { path: "/teacher/getMySpecialPlans", component: indexSpecialPlanEmployee },
    { path: "/teacher/teacherStudents", component: teacherStudents },
    {
        path: "/teacher/indexAssignCourseBehavior",
        component: IndexGradeBehavior
    },
    { path: "/teacher/TeacherCourseComment", component: teacherComment },
    {
        path: "/teacher/paragraphContentTeacher",
        component: paragraphContentTeacher
    },
    { path: "/teacher/paragraphReadTeacher", component: paragraphReadTeacher },
    {
        path: "/teacher/myEntireClassParagraphReadAndWrite",
        component: myEntireClassParagraphReadAndWrite,
        name: "myEntireClassParagraphReadAndWrite"
    },
    { path: "/teacher/classAttend", component: classAttend },
    { path: "/teacher/lunchAttendance", component: lunchAttendance },
    { path: "/teacher/tempAttendance", component: tempAttendance },
    {
        path: "/plan/teacherCalendar",
        component: () => import("../components/Plan/Teacher/CalendarPlan.vue"),
        name: "teacherCalendar"
    },
    { path: "/teacher/exam", component: exam },
    {
        path: "/teacher/indexAssignCourseExam",
        component: () => import("../components/GradeExams/IndexGradeExam.vue"),
        name: "indexAssignCourseExam"
    },
    { path: "/teacher/myExamStat", component: myExamStat },
    { path: "/teacher/teacherDev", component: teacherDev },
    { path: "/teacher/teacherCulture", component: teacherCulture },
    { path: "/teacher/physicalGeneral", component: physicalGeneral },
    { path: "/teacher/physicalMeasure", component: physicalMeasure },
    { path: "/teacher/physicalStudents", component: physicalStudents },
    { path: "/teacher/attendaceteacherstat", component: attendaceteacherstat },
    {
        path: "/teacher/teacherGetConfirmedGradeDataYear",
        component: teacherGetConfirmedGradeDataYear
    },
    {
        path: "/teacher/myClassAllCourseGrade",
        component: myClassAllCourseGrade
    },
    { path: "/teacher/reportForTeacher", component: reportForTeacher },
    {
        path: "/teacher/taskTeacher",
        component: taskTeacher,
        name: "taskTeacher"
    },
    { path: "/teacher/myLessonEvaluation", component: myLessonEvaluation },

    { path: "/teacher/bbzIndex", component: bbzIndex },
    { path: "/teacher/mybbzIndex", component: mybbzIndex },
    { path: "/teacher/teachNotifi", component: teachNotifi },
    {
        path: "/teacher/competitionsTeacher",
        component: competitionsTeacher,
        name: "competitionsTeacher"
    },
    {
        path: "/teacher/competitionTeamTeacher",
        component: competitionTeamTeacher,
        name: "competitionTeamTeacher"
    },
    {
        path: "/teacher/weekendWorkTeacher",
        component: weekendWorkTeacher,
        name: "weekendWorkTeacher"
    },
    {
        path: "/teacher/successTeacher",
        component: successTeacher,
        name: "successTeacher"
    },
    {
        path: "/teacher/openProblemExamTeacher",
        component: openProblemExamTeacher,
        name: "openProblemExamTeacher"
    },
    {
        path: "/teacher/indexTeacherHw",
        component: indexTeacherHw,
        name: "indexTeacherHw"
    },
    {
        path: "/teacher/teacherComplain",
        component: teacherComplain,
        name: "teacherComplain"
    },
    {
        path: "/teacher/teacherParentMeet",
        component: teacherParentMeet,
        name: "teacherParentMeet"
    },
    {
        path: "/teacher/indexTechnologyCard",
        component: indexTechnologyCard,
        name: "indexTechnologyCard"
    },
    {
        path: "/teacher/indexLifeLine",
        component: () => import("../components/LifeLine/Index.vue"),
        name: "indexLifeLine"
    },

    {
        path: "/teacher/myParentPlans",
        component: () => import("../components/Plan/Parent/parentPlan.vue"),
        name: "myParentPlans"
    },
    //================================ Parent ==============================================
    {
        path: "/parent/home",
        component: indexParentHome,
        name: "indexParentHome"
    }, //Нүүр хуудас
    {
        path: "/parent/howtousesystem",
        component: HowToUse,
        name: "parentHowToUse"
    }, //Гарын авлага
    {
        path: "/parent/Profile",
        component: parentProfile,
        name: "parentProfile"
    }, //Эцэг эхийн профайл
    {
        path: "/parent/parentNotification",
        component: parentNotification,
        name: "parentNotification"
    }, // мэдэгдлүүд
    {
        path: "/parent/Childs",
        component: parentChilds,
        name: "parentChilds"
    }, //Миний хүүхдүүд
    {
        path: "/parent/MyChildCourse",
        component: parentMyChildCourse,
        name: "parentMyChildCourse"
    }, //Миний хүүхдүүдийн хичээл
    {
        path: "/parent/MyChildBehaveCourse",
        component: parentMyChildBehaveCourse,
        name: "parentMyChildBehaveCourse"
    }, //Миний хүүхдүүдийн хичээл
    {
        path: "/parent/Dev",
        component: parentDev,
        name: "parentDev"
    }, //Хөгжүүлэх
    {
        path: "/parent/Culture",
        component: parentCulture,
        name: "parentCulture"
    }, //Соёл хүмүүншлийн ажил
    {
        path: "/parent/physicalStudent",
        component: physicalStudent,
        name: "physicalStudent"
    }, //Бие бялдарын сорил
    {
        path: "/parent/paragraphRead",
        component: paragraphReadParent,
        name: "paragraphReadParent"
    }, //Унших, цээж бичиг
    {
        path: "/parent/childAttendStat",
        component: childAttendStat,
        name: "childAttendStat"
    }, //Ирцийн статистик
    {
        path: "/parent/Complain",
        component: () =>
            import("../components/Parent/Complaints/IndexComplain.vue"),
        name: "parentComplain"
    }, //Гомдол санал, хүсэлт
    {
        path: "/parent/Appreciation",
        component: () =>
            import("../components/Parent/Appreciations/Apreciation.vue"),
        name: "parentAppreciation"
    }, //Талархал
    {
        path: "/parent/Complain/Public",
        component: parentComplainPublic,
        name: "parentComplainPublic"
    },

    {
        path: "/parent/AppreciationForManager",
        component: indexAppreciationForManager,
        name: "indexAppreciationForManager"
    },

    {
        path: "/parent/Homework",
        component: indexParentHomework,
        name: "indexParentHomework"
    },

    {
        path: "/parent/childCalendarPlan",
        component: childCalendarPlan,
        name: "childCalendarPlan"
    },
    {
        path: "/parent/specialPlanParent",
        component: specialPlanParent,
        name: "specialPlanParent"
    },
    {
        path: "/parent/weekendWorkParent",
        component: weekendWorkParent,
        name: "weekendWorkParent"
    },
    {
        path: "/parent/competitionsParent",
        component: competitionsParent,
        name: "competitionsParent"
    },
    {
        path: "/parent/successParent",
        component: successParent,
        name: "successParent"
    },
    {
        path: "/parent/SalesBooks",
        component: salesBooks,
        name: "salesBooks"
    },
    {
        path: "/parent/myBook",
        component: myBook,
        name: "mybook"
    },
    {
        path: "/parent/parentsBoardsParent",
        component: () => import("../components/Parent/parentsBoardsParent.vue"),
        name: "parentsBoardsParent"
    },
    {
        path: "/parent/indexOpenDay",
        component: indexOpenDay,
        name: "indexOpenDay"
    },

    {
        path: "/parent/parentPlans",
        component: () => import("../components/Parent/Plan/Plan.vue"),
        name: "parentPlans"
    },

    {
        path: "/parent/parentDocuments",
        component: () =>
            import("../components/Hr/Document/IndexDocumentParents.vue"),
        name: "parentDocuments"
    },

    //================================ student ==============================================
    { path: "/student/home", component: indexStudentHome },
    { path: "/student/profile", component: studentprofile },
    { path: "/student/myCourses", component: myCourses },
    { path: "/student/studentDev", component: studentDev },
    { path: "/student/studentCulture", component: studentCulture },
    { path: "/student/myParents", component: myParents },
    { path: "/student/studentNotify", component: studentNotify },
    {
        path: "/student/specialPlanStudent",
        component: () =>
            import(
                "../components/Psycho/SpecialPlan/indexSpecialPlanStudent.vue"
            ),
        name: "specialPlanStudent"
    },
    {
        path: "/student/weekendWorkStudent",
        component: weekendWorkStudent,
        name: "weekendWorkStudent"
    },
    {
        path: "/student/competitionsStudent",
        component: competitionsStudent,
        name: "competitionsStudent"
    },
    {
        path: "/student/successStudent",
        component: () =>
            import("../components/Manager/Success/IndexStudent.vue"),
        name: "successStudent"
    },
    //================================ Psycho сэтгэл зүйч ==============================================
    {
        path: "/psycho/home",
        component: indexPsychoHome,
        name: "indexPsychoHome"
    },
    {
        path: "/psycho/waitEntrantListPsy",
        component: waitEntrantListPsy,
        name: "waitEntrantListPsy"
    },
    {
        path: "/psycho/problemCatDetailIndex",
        component: problemCatDetailIndex,
        name: "problemCatDetailIndex"
    },
    {
        path: "/psycho/psyMeetIndex",
        component: psyMeetIndex,
        name: "psyMeetIndex"
    },
    // { path: "/psycho/specialPlan", component: psychoSpecialPlan },
    {
        path: "/psycho/indexPsychologyCourseComment",
        component: indexPsychologyCourseComment
    },

    //================================ doctor ==============================================
    { path: "/doctor/indexDoctorHome", component: indexDoctorHome },

    { path: "/doctor/doctorLunch", component: doctorLunch },
    { path: "/doctor/doctorTemp", component: doctorTemp },
    //================================ counselor ==============================================
    {
        path: "/counselor/home",
        component: indexCelorcounceHome,
        name: "indexCelorcounceHome"
    },
    {
        path: "/counselor/specialPlanCounselor",
        component: () =>
            import(
                "../components/Psycho/SpecialPlan/indexSpecialPlanCounselor.vue"
            ),
        name: "specialPlanCounselor"
    },
    //================================ rariallib номын санч ==============================================
    {
        path: "/librarian/home",
        component: indexLibrarianHome,
        name: "indexLibrarianHome"
    },
    {
        path: "/librarian/books",
        component: libraryBooks,
        name: "libraryBooks"
    },
    //================================ it номын санч ==============================================
    {
        path: "/it/home",
        component: indexItHome,
        name: "indexItHome"
    },
    //================================ vaaajiltan үйл ажиллагааны албаны ажилтан ==========================
    {
        path: "/vaaajiltan/home",
        component: indexVaaAjiltanHome,
        name: "indexVaaAjiltanHome"
    },
    //================================ finance ==============================================
    {
        path: "/finance/home",
        component: indexFinanceHome,
        name: "indexFinanceHome"
    },
    // {
    //     path: "/finance/employeeEvaluationsFinance",
    //     component: employeeEvaluationsFinance,
    //     name: "employeeEvaluationsFinance"
    // },
    {
        path: "/finance/payEntruntCheckBeltgel",
        component: payEntruntCheckBeltgel
    },
    { path: "/finance/payEntruntCheck", component: payEntruntCheck },
    {
        path: "/finance/schoolPayment",
        component: () => import("../components/Finance/schoolPayment.vue"),
        name: "schoolPayment"
    },
    { path: "/finance/acceptEntrantBeltgel", component: acceptEntrantBeltgel },
    { path: "/finance/acceptEntrant", component: acceptEntrant },
    { path: "/finance/usersFinanceStudents", component: usersFinanceStudents },
    { path: "/finance/allFinanceBudget", component: allFinanceBudget },
    //================================ Plumber сантехникч =====================================
    {
        path: "/plumber/home",
        component: indexPlumberHome,
        name: "indexPlumberHome"
    },
    //================================ Carpenter мужаан =====================================
    {
        path: "/carpenter/home",
        component: indexCarpenterHome,
        name: "indexCarpenterHome"
    },
    //================================ Hygienist эрүүл ахуйч =====================================
    {
        path: "/hygienist/home",
        component: indexHygienistHome,
        name: "indexHygienistHome"
    },
    //================================ Servent үйлчлэгч =====================================
    {
        path: "/servant/home",
        component: indexServantHome,
        name: "indexServantHome"
    },
    //================================ Accountant Нягтлан бодогч =====================================
    {
        path: "/accountant/home",
        component: indexAccountantHome,
        name: "indexAccountantHome"
    },
    //================================ SocialWorker Нийгмийн ажилтан =====================================
    {
        path: "/socialworker/home",
        component: indexSocialworkerHome,
        name: "indexSocialworkerHome"
    },
    //================================ Consultant зөвлөх =====================================
    {
        path: "/consultant/home",
        component: indexConsultantHome,
        name: "indexConsultantHome"
    },

    //================================ Тэмцээн уралдаан =====================================
    {
        path: "/competitions",
        component: () => import("../components/Match/Index.vue"),
        name: "match"
    },
    {
        path: "/userCompetitions",
        component: () => import("../components/Match/userMatchs.vue"),
        name: "userMatchs"
    }
];

export default new VueRouter({
    mode: "history",
    routes
});
