<template>
  <label
    ><input
      size="70"
      type="search"
      class="form-control form-control-sm"
      placeholder="Сургуулийн нэрээр хайна."
      aria-controls="example1"
      v-model="keyword"
  /></label>
</template>

<script>
export default {
  data() {
    return {
      keyword: null,
      getSearchSchoolManagers: [],
    };
  },
  watch: {
    keyword(after, before) {
      this.searchSubjects();
    },
  },
  methods: {
    searchSubjects() {
      axios
        .get("/owner/managersearch", { params: { keyword: this.keyword } })
        .then((response) => {
          console.log(response.data.getSearchSchoolManagers);
          this.getSearchSchoolManagers = response.data.getSearchSchoolManagers;
          this.$emit("getWasSearchManagers", this.getSearchSchoolManagers);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>

<style>
</style>
