<template>
  <div>
    <img
      v-if="myImgUrl != null"
      :src="myImgUrl"
      alt="user-avatar"
      class="imgZoom img-bordered-sm"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ zoomed: hover }"
    />
    <img
      v-else
      src="/images/users/user.png"
      alt="user-avatar"
      class="imgZoom img-bordered-sm"
    />
  </div>
</template>

<script>
export default {
  props: ["myImgUrl"],
  data() {
    return {
      hover: false,
    };
  },
  created() {
    // console.log(this.myImgUrl);
  },
};
</script>

<style scoped>
/* .profile-container {
  position: relative;
  display: inline-block;
} */

.imgZoom {
  height: auto;
  transition: transform 0.3s ease; /* Аажмаар томруулах шилжилт */
}

.imgZoom.zoomed {
  transform: scale(6); /* Томруулах хэмжээ */
  z-index: 1000; /* Өөр элементүүдээс дээгүүр харагдах */
  position: relative;
}
</style>
