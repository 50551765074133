<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Тархины үйлийн систем</h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн систем</th>
                <th>Үйлийн системийн тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(mental, index) in allmentalsystem"
                :key="mental.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.mental_system_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.mental_system_name_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.created_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.updated_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTaxanomySystem(mental)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <!-- <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteComplaint(schoolRegEmp)"
                    ><i class="fas fa-trash-alt"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
          <h3 class="card-title" v-show="editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode ? createTaxanomySystem() : updateTaxanomySystem()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label>Тархины үйлийн системийн нэр</label>
                <b-textarea
                  name="mental_system_name"
                  v-model="form.mental_system_name"
                  text-field="mental_system_name"
                  placeholder="Тархины үйлийн системийн нэр"
                ></b-textarea>
                <!-- console.log('Тархины үйлийн системийн нэр л.') //Устгаж болохгүй-->
                <has-error :form="form" field="mental_system_name"></has-error>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэршлийн тайлбар</label>

                <b-textarea
                  name="mental_system_name_explain"
                  v-model="form.mental_system_name_explain"
                  text-field="mental_system_name_explain"
                  placeholder="Тархины үйлийн системийн нэршлийн тайлбар"
                ></b-textarea>
                <!-- console.log('Тархины үйлийн системийн нэршлийн тайлбар') //Устгаж болохгүй -->
                <has-error
                  :form="form"
                  field="mental_system_name_explain"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      allmentalsystem: [],
      form: new Form({
        id: "",
        year_id: "", //backend талд хадгална
        school_id: "", //backend талд хадгална
        department_id: "", //backend талд хадгална
        create_emp_id: "", //backend талд хадгална
        position_id: "", //backend талд хадгална
        mental_system_name: "",
        mental_system_name_explain: "",
      }),
    };
  },
  components: {
    // searchManager: searchManager,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/getAllTaxamoySystem")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.allmentalsystem = response.data.allmentalsystem;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTaxanomySystem(mental) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editMode = true;
      this.form.reset();
      this.form.fill(mental);
    },
    updateTaxanomySystem() {
      this.form
        .put("/curriculumadministrator/updateTaxanomySystem/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyMentalSystem");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createTaxanomySystem() {
      this.form
        .post("/curriculumadministrator/createTaxanomySystem")
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyMentalSystem");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyMentalSystem", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
