<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Модуль тохируулах</strong>
          </h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <th>№</th>
                <th>Модуль</th>
                <th>Цэсний тоо</th>
                <th>Үнэ</th>
                <th>Үйлдэл</th>
              </thead>
              <tbody>
                <tr v-for="(mod, index) in modules" :key="mod.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ mod.name }}
                  </td>
                  <td>
                    {{ mod.module_menus_count }}
                  </td>
                  <td>{{ mod.price }}₮</td>
                  <td>
                    <a href="#" @click.prevent="editModule(mod)"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a href="#" @click.prevent="deleteModule(mod)"
                      ><i class="fas fa-trash text-danger"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td colspan="2"></td>
                <td>
                  <strong>{{ moduleTotalMenu }} ширхэг</strong>
                </td>
                <td colspan="2">
                  <strong>{{ moduleTotalPrice }}₮</strong>
                </td>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong v-if="!editModuleMode">Модуль нэмэх</strong>
            <strong v-else>Модуль засах</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                v-model="formModule.name"
                placeholder="Нэр"
                class="form-control"
                :class="{ 'is-invalid': formModule.errors.has('name') }"
              />
            </div>
            <div class="form-group">
              <label>Үнэ</label>
              <input
                type="number"
                v-model="formModule.price"
                placeholder="Үнэ"
                class="form-control"
                :class="{ 'is-invalid': formModule.errors.has('price') }"
              />
            </div>
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-sm btn-success"
              @click.prevent="updateModule()"
              v-if="editModuleMode"
            >
              Засах
            </b-button>
            <button
              v-if="editModuleMode"
              @click.prevent="cancelModule()"
              class="btn btn-sm btn-warning"
            >
              Болих
            </button>
            <button
              type="submit"
              v-if="!editModuleMode"
              @click.prevent="createModule()"
              class="btn btn-sm btn-primary"
            >
              Нэмэх
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      editModuleMode: false,
      modules: [],
      formModule: new Form({
        id: "",
        name: "",
        price: "",
      }),
      moduleTotalPrice: "",
      moduleTotalMenu: "",
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getModules() {
      axios
        .get("/owner/getModules")
        .then((response) => {
          this.modules = _.orderBy(
            response.data.modules,
            "module_menus_count",
            "desc"
          );
          this.moduleTotalPrice = response.data.moduleTotalPrice;
          this.moduleTotalMenu = response.data.moduleTotalMenu;
        })
        .catch(() => {});
    },
    //================== Module ======================
    editModule(module) {
      this.formModule.fill(module);
      this.editModuleMode = true;
    },
    updateModule() {
      this.formModule
        .put("/owner/updateModule/" + this.formModule.id)
        .then(() => {
          this.$toastr.s("Модуль амжилттай заслаа.", "Амжилттай");
          this.getModules();
          this.formModule.reset();
          this.editModuleMode = false;
        })
        .catch(() => {});
    },
    createModule() {
      this.formModule
        .post("/owner/createModule")
        .then(() => {
          this.$toastr.s("Модуль амжилттай нэмлээ.", "Амжилттай");
          this.getModules();
          this.formModule.reset();
          this.editModuleMode = false;
        })
        .catch(() => {});
    },
    deleteModule(module) {
      Swal.fire({
        title: "Модуль устгах!",
        text: "Модуль устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Модуль хасаж байна...";
          axios
            .get("/owner/deleteModule/" + module.id)
            .then((response) => {
              if (!response.data.errorMsj) {
                this.$toastr.s("Модуль амжилттай устгалаа!", "Шинэчилсэн");
                this.getModules();
              } else this.$toastr.w(response.data.errorMsj, "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelModule() {
      this.formModule.reset();
      this.editModuleMode = false;
    },
  },
  created() {
    this.getModules();
  },
};
</script>

<style scoped>
.myList {
  border-top: 1px solid rgb(255 255 255);
}
.display-flex {
  display: flex;
}
.modulMenus {
  background-color: #563d7c;
  height: 100%;
}
.modulNoMenus {
  background-color: #563d7c;
  opacity: 60%;
  height: 100%;
}
.modPad {
  padding: 15px;
}
.text-disabled {
  font-weight: 100;
}
</style>
