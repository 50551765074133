var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mt-3"},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body table-responsive"},[_c('b-container',{staticClass:"bv-example-row mb-3",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('multiselect',{attrs:{"select-label":"Сонгох","selected-label":"Сонгогдсон","deselect-label":"Сонгосон өгөгдлийг устгах боломжгүй.","track-by":"id","label":"id","custom-label":_vm.customYearName,"placeholder":"Хичээлийн жил сонгоно уу?","options":_vm.years,"searchable":false,"allow-empty":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm._f("dateYear")(option.start_date))+"-"+_vm._s(_vm._f("dateYear")(option.finish_date))+"\n                    - хичээлийн жил\n                  ")])]}}]),model:{value:(_vm.school_year_id),callback:function ($$v) {_vm.school_year_id=$$v},expression:"school_year_id"}})],1),_vm._v(" "),_c('b-col',{attrs:{"sm":"3"}},[_c('multiselect',{attrs:{"options":_vm.monthsRe,"multiple":true,"track-by":"name","label":"month","searchable":false,"close-on-select":false,"show-labels":false,"placeholder":"Сар сонгох","allow-empty":true,"deselect-label":"Can't remove this value"},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(" "+_vm._s(option.month))]),_c('strong',[_vm._v(" сонгогдлоо")])]}}]),model:{value:(_vm.selectedMonths),callback:function ($$v) {_vm.selectedMonths=$$v},expression:"selectedMonths"}})],1),_vm._v(" "),_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.getAllData()}}},[_vm._v("Шүүж харах")])],1)],1)],1),_vm._v(" "),_c('b-table',{attrs:{"striped":"","bordered":"","hover":"","items":_vm.managerEvaluations,"fields":_vm.fields},scopedSlots:_vm._u([{key:"head(shal1)",fn:function(){return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(
                'Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85% байна.'
              ),expression:"\n                'Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85% байна.'\n              ",modifiers:{"hover":true}}],attrs:{"title":"Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын гүйцэтгэл;"}},[_vm._v("\n              Ш-1")])]},proxy:true},{key:"head(shal2)",fn:function(){return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.righttop",value:(
                'Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
              ),expression:"\n                'Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'\n              ",modifiers:{"hover":true,"righttop":true}}],attrs:{"title":"Сургалтын ээлжит үйл ажиллагааны чанар;"}},[_vm._v("\n              Ш-2")])]},proxy:true},{key:"head(shal3)",fn:function(){return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.righttop",value:(
                'Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
              ),expression:"\n                'Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'\n              ",modifiers:{"hover":true,"righttop":true}}],attrs:{"title":"Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;"}},[_vm._v("\n              Ш-3")])]},proxy:true},{key:"head(shal4)",fn:function(){return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.righttop",value:(
                '1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн 85%-иас дээш үзүүлэлттэй байна.2. Санал гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл 80%-иас дээш үзүүлэлттэй байна.'
              ),expression:"\n                '1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн 85%-иас дээш үзүүлэлттэй байна.2. Санал гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл 80%-иас дээш үзүүлэлттэй байна.'\n              ",modifiers:{"hover":true,"righttop":true}}],attrs:{"title":"Сургалтын талаарх хэрэглэгчдийн сэтгэл ханамж, санал гомдол шийдвэрлэсэн байдал;"}},[_vm._v("\n              Ш-4")])]},proxy:true},{key:"head(shal5)",fn:function(){return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.righttop",value:('Тухайн сард ажилласан цаг'),expression:"'Тухайн сард ажилласан цаг'",modifiers:{"hover":true,"righttop":true}}],attrs:{"title":"Илүү цаг"}},[_vm._v("\n              Ш-5")])]},proxy:true},{key:"cell(index)",fn:function(row){return [_vm._v("\n            "+_vm._s(row.index + 1)+"\n          ")]}},{key:"cell(name)",fn:function(row){return [(row.item.emp_teacher_last_name)?_c('span',{staticClass:"badge badge-primary"},[_vm._v("\n              "+_vm._s(row.item.emp_teacher_last_name[0].toUpperCase())+".\n              "+_vm._s(row.item.emp_teacher_name)+"\n            ")]):_vm._e()]}},{key:"cell(shal1)",fn:function(row){return [_c('span',{staticClass:"badge badge-primary"},[_vm._v("\n              "+_vm._s(row.item.shal1)+" * "+_vm._s(row.item.shal1_jin_huvi)+" =\n              "+_vm._s(_vm.calculatePercentElement(
                  row.item.shal1,
                  row.item.shal1_jin_huvi
                ))+"\n            ")])]}},{key:"cell(shal2)",fn:function(row){return [_c('span',{staticClass:"badge badge-primary"},[_vm._v("\n              "+_vm._s(row.item.shal2)+" * "+_vm._s(row.item.shal2_jin_huvi)+" =\n              "+_vm._s(_vm.calculatePercentElement(
                  row.item.shal2,
                  row.item.shal2_jin_huvi
                ))+"\n            ")])]}},{key:"cell(shal3)",fn:function(row){return [_c('span',{staticClass:"badge badge-primary"},[_vm._v("\n              "+_vm._s(row.item.shal3)+" * "+_vm._s(row.item.shal3_jin_huvi)+" =\n              "+_vm._s(_vm.calculatePercentElement(
                  row.item.shal3,
                  row.item.shal3_jin_huvi
                ))+"\n            ")])]}},{key:"cell(shal4)",fn:function(row){return [_c('span',{staticClass:"badge badge-primary"},[_vm._v("\n              "+_vm._s(row.item.shal4)+" * "+_vm._s(row.item.shal4_jin_huvi)+" =\n              "+_vm._s(_vm.calculatePercentElement(
                  row.item.shal4,
                  row.item.shal4_jin_huvi
                ))+"\n            ")])]}},{key:"cell(shal1-4)",fn:function(row){return [_c('span',{staticClass:"badge badge-success"},[_vm._v("\n              "+_vm._s(_vm.calculateManagerPercentJinHuvi(
                  row.item.shal1,
                  row.item.shal1_jin_huvi,
                  row.item.shal2,
                  row.item.shal2_jin_huvi,
                  row.item.shal3,
                  row.item.shal3_jin_huvi,
                  row.item.shal4,
                  row.item.shal4_jin_huvi
                ))+"%\n            ")])]}},{key:"cell(shal5)",fn:function(row){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.item.shal5 === null),expression:"row.item.shal5 === null"}]},[_vm._v(" 0 ц")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.item.shal5 !== null),expression:"row.item.shal5 !== null"}]},[_vm._v("\n              "+_vm._s(row.item.shal5)+" ц")])]}},{key:"cell(created_at)",fn:function(row){return [_vm._v("\n            "+_vm._s(_vm._f("dateYearMonthDay")(row.item.created_at))+"\n          ")]}},{key:"cell(updated_at)",fn:function(row){return [_vm._v("\n            "+_vm._s(_vm._f("dateYearMonthDay")(row.item.updated_at))+"\n          ")]}},{key:"cell(actions)",fn:function(row){return [_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.item.readManager === null),expression:"row.item.readManager === null"}],staticClass:"badge bg-warning",attrs:{"href":"#"},on:{"click":function($event){return _vm.readEvaluation(row.item)}}},[_c('i',{staticClass:"fas fa-check-circle"})]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.item.readManager === 1),expression:"row.item.readManager === 1"}],staticClass:"badge bg-success"},[_c('i',{staticClass:"fas fa-check-double"})])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"card-title"},[_vm._v("Сарын ажлын үнэлгээ")])])])}]

export { render, staticRenderFns }