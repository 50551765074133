<template>
  <b-row class="mt-3">
    <b-col sm="9"
      ><multiselect
        v-model="fieldName"
        :options="fieldList"
        @select="setFieldList"
        :multiple="true"
        track-by="value"
        label="label"
        :searchable="true"
        :allowEmpty="false"
        :close-on-select="false"
        :show-labels="true"
        placeholder="Excel файл руу гаргах баганаа сонгоно уу."
        :allow-empty="true"
        deselect-label="Хасах"
        select-label="Сонгох"
        selected-label="Сонгогдсон"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong> {{ option.month }}</strong
          ><strong> сонгогдлоо</strong></template
        >
      </multiselect>
    </b-col>
    <b-col sm="3">
      <b-button
        size="sm"
        @click="resetFields"
        class="mr-2"
        pill
        variant="outline-primary"
      >
        <b-icon icon="check-all" aria-hidden="true"></b-icon> Бүх талбар
      </b-button>
      <download-excel
        v-show="fieldName.length > 0"
        class="btn btn-success btn-sm"
        :data="dataList"
        :fields="json_fields"
        worksheet="Төсөв"
        name="Төсөв.xls"
      >
        <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
        Файл татах
      </download-excel>
    </b-col>
  </b-row>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["fieldList", "dataList"],
  components: {
    Multiselect,
  },
  data() {
    return {
      json_fields: {},
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldName: [],
      firstMode: true,
    };
  },
  methods: {
    setFieldList: function (e) {
      if (this.firstMode == true) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = this.fieldList;
      this.fieldList.map(function (value, key) {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
  },
  created() {
    var jsonF = {};
    this.fieldList.map(function (value, key) {
      var obj = {};
      var myLabel = value.label;
      obj = { [myLabel]: value.value };
      Object.assign(jsonF, obj);
    });
    this.json_fields = jsonF;
  },
};
</script>
