<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#teachersList" data-toggle="tab"
                >Багш</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#studentsList" data-toggle="tab"
                >Суралцагч</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#parentsList" data-toggle="tab"
                >Эцэг эх</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="teachersList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                      <b-badge variant="info">
                        Эерэг сэтгэгдэл:{{
                          habitsTeachers.filter(
                            (type) => type.habit_of_type == 0
                          ).length
                        }}
                      </b-badge>
                      <b-badge variant="info">
                        Сөрөг сэтгэгдэл:{{
                          habitsTeachers.filter(
                            (type) => type.habit_of_type == 1
                          ).length
                        }}
                      </b-badge>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                    <b-col sm="2">
                      <multiselect
                        v-model="regEpmId"
                        :options="habitsTeacherEnrollers"
                        :custom-label="customRegEmpName"
                        :multiple="false"
                        track-by="reg_user_id"
                        label="Багш"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Бүртгэсэн багш"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.reg_t_last_name[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.reg_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="2">
                      <multiselect
                        v-model="empUserId"
                        :options="habitsEmployees"
                        :custom-label="customEmpName"
                        :multiple="false"
                        track-by="employee_user_id"
                        label="Багш"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Багш сонгох"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.employee_teacher_last_name[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.employee_teacher_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="start-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.start_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_date'),
                          },
                        ]"
                        placeholder="Эхлэх огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="end-datepicker"
                        :state="false"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.end_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('end_date'),
                          },
                        ]"
                        placeholder="Дуусах огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="end_date"></has-error>
                    </div>
                    <b-col lg="1"
                      ><b-button
                        variant="success"
                        @click.prevent="getAllInfo()"
                        size="sm"
                        >Шүүх</b-button
                      ></b-col
                    ><b-col lg="1"
                      ><b-button
                        variant="primary"
                        @click.prevent="cancelfilters()"
                        size="sm"
                        >Цэвэрлэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitT, index) in habitsTeachers"
                      :key="habitT.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitT)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.reg_t_last_name[0] }}.
                          {{ habitT.reg_name }}({{
                            habitT.reg_department_code
                          }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.employee_teacher_last_name[0] }}.
                          {{ habitT.emp_teacher_name }}({{
                            habitT.department_code
                          }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitT.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitT.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitT.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="studentsList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                      <b-badge variant="info">
                        Эерэг сэтгэгдэл:{{
                          habitsStudents.filter(
                            (type) => type.habit_of_type == 0
                          ).length
                        }}
                      </b-badge>
                      <b-badge variant="info">
                        Сөрөг сэтгэгдэл:{{
                          habitsStudents.filter(
                            (type) => type.habit_of_type == 1
                          ).length
                        }}
                      </b-badge>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                    <b-col sm="2">
                      <multiselect
                        v-model="regEpmIdStud"
                        :options="habitsStudEnrollers"
                        :custom-label="customRegEmpName"
                        :multiple="false"
                        track-by="reg_user_id"
                        label="Багш"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Бүртгэсэн багш"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.reg_t_last_name[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.reg_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="2">
                      <multiselect
                        v-model="studUserId"
                        :options="habitsStuds"
                        :custom-label="customStudName"
                        :multiple="false"
                        track-by="pro_user_id"
                        label="Сурагч"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Сурагч сонгох"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.pro_student_last_name[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.pro_student_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="start-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.start_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_date'),
                          },
                        ]"
                        placeholder="Эхлэх огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="end-datepicker"
                        :state="false"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.end_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('end_date'),
                          },
                        ]"
                        placeholder="Дуусах огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="end_date"></has-error>
                    </div>
                    <b-col lg="1"
                      ><b-button
                        variant="success"
                        @click.prevent="getAllInfo()"
                        size="sm"
                        >Шүүх</b-button
                      ></b-col
                    ><b-col lg="1"
                      ><b-button
                        variant="primary"
                        @click.prevent="cancelfilters()"
                        size="sm"
                        >Цэвэрлэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitS, index) in habitsStudents"
                      :key="habitS.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitS)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.reg_t_last_name[0] }}.
                          {{ habitS.reg_name }}({{ habitS.department_code }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.pro_student_last_name[0] }}.
                          {{ habitS.pro_student_name }}({{
                            habitS.school_class_full_name
                          }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitS.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitS.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitS.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->

            <div class="tab-pane" id="parentsList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                      <b-badge variant="info">
                        Эерэг сэтгэгдэл:{{
                          habitsParents.filter(
                            (type) => type.habit_of_type == 0
                          ).length
                        }}
                      </b-badge>
                      <b-badge variant="info">
                        Сөрөг сэтгэгдэл:{{
                          habitsParents.filter(
                            (type) => type.habit_of_type == 1
                          ).length
                        }}
                      </b-badge>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                    <b-col sm="2">
                      <multiselect
                        v-model="regEpmIdParent"
                        :options="habitsParentEnrollers"
                        :custom-label="customRegEmpName"
                        :multiple="false"
                        track-by="reg_user_id"
                        label="Багш"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Бүртгэсэн багш"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.reg_t_last_name[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.reg_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="2">
                      <multiselect
                        v-model="parentUserId"
                        :options="habitsParentFilter"
                        :custom-label="customParentName"
                        :multiple="false"
                        track-by="pro_user_id"
                        label="Эцэг эх"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Эцэг эх сонгох"
                        :allow-empty="true"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>
                            {{
                              option.parent_father_ovog[0]
                                | capitalizeAndEkhniiVseg
                            }}. {{ option.parent_father_name }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </b-col>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="start-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.start_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_date'),
                          },
                        ]"
                        placeholder="Эхлэх огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <div class="form-group col-lg-3">
                      <b-form-datepicker
                        id="end-datepicker"
                        :state="false"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.end_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('end_date'),
                          },
                        ]"
                        placeholder="Дуусах огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="end_date"></has-error>
                    </div>
                    <b-col lg="1"
                      ><b-button
                        variant="success"
                        @click.prevent="getAllInfo()"
                        size="sm"
                        >Шүүх</b-button
                      ></b-col
                    ><b-col lg="1"
                      ><b-button
                        variant="primary"
                        @click.prevent="cancelfilters()"
                        size="sm"
                        >Цэвэрлэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitP, index) in habitsParents"
                      :key="habitP.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitP)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitP.reg_t_last_name[0] }}.
                          {{ habitP.reg_name }}({{
                            habitP.reg_department_code
                          }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          Эцэг эх:
                          {{ habitP.parent_father_ovog[0] }}.
                          {{ habitP.parent_father_name }}({{
                            habitP.school_class_full_name
                          }}
                          анги-{{ habitP.student_last_name[0] }}.
                          {{ habitP.student_name }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitP.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitP.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitP.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitP.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
          </div>
          <!-- /.tab-content -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <b-modal
      size="xl"
      id="modal-habit"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дадал, зуршил бүртгэх"
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <!-- {{ form.comp_par_main_problem }} -->
                </h3>
                <b-row>
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-teacher"
                      value="teacher"
                      >Багш</b-form-radio
                    ></b-col
                  >
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-student"
                      value="student"
                      >Суралцагч</b-form-radio
                    ></b-col
                  >
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-parent"
                      value="parent"
                      >Эцэг эх</b-form-radio
                    ></b-col
                  >
                </b-row>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <Multiselect
                  v-show="selected === 'teacher'"
                  v-model="form.selectedPeople"
                  :options="allTeachers"
                  track-by="employee_teacher_name"
                  label="employee_teacher_name"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="Багш сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :custom-label="customNameTeacher"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>
                      {{ option.emp_teacher_last_name }}
                      {{ option.employee_teacher_name }}({{
                        option.department_code
                      }})</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </Multiselect>

                <Multiselect
                  v-show="selected === 'student'"
                  v-model="form.selectedPeople"
                  :options="allStudents"
                  track-by="pro_student_name"
                  label="pro_student_name"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="Суралцагч сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :custom-label="customNameStudent"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>
                      {{ option.pro_student_last_name }}
                      {{ option.pro_student_name }}({{
                        option.school_class_full_name
                      }})</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </Multiselect>
                <Multiselect
                  v-show="selected === 'parent'"
                  v-model="form.selectedPeople"
                  :options="allParents"
                  track-by="parent_father_name"
                  label="parent_father_name"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="Эцэг эх сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :custom-label="customNameParent"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>
                      Аав:
                      {{ option.parent_father_ovog }}
                      {{ option.parent_father_name }}

                      Анги:
                      {{ option.school_class_full_name }}: Суралцагч:
                      {{ option.student_last_name }}
                      {{ option.student_name }}
                      сонгогдлоо</strong
                    ></template
                  >
                </Multiselect>
                <!-- <b-form @submit="onUpdate" @reset="onReset"> -->
                <!-- <b-form @submit="onUpdate"> -->
                <!-- @submit="" @reset="" -->
                <b-form>
                  <b-form-group id="habit_of_info" label-for="habit_of_info">
                    <b-form-textarea
                      id="habit_of_info"
                      v-model="form.habit_of_info"
                      placeholder="Ажигласан мэдээлэл"
                      required
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="form.checked"
                      id="checkboxes-4"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-radio
                        value="positive"
                        v-model="form.habit_of_type"
                        >Эерэг</b-form-radio
                      >
                      <b-form-radio
                        value="negative"
                        v-model="form.habit_of_type"
                        >Сөрөг</b-form-radio
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button variant="primary" @click.prevent="storeHabit()"
                    >Хадгалах</b-button
                  >
                  <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //MODAL BEGIN
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showMode: false,
      //MODAL END

      editMode: false,
      allHabits: [],
      allYears: [],
      year_id: "",
      regEpmId: "",
      empUserId: "",
      regEpmIdStud: "",
      studUserId: "",
      regEpmIdParent: "",
      parentUserId: "",
      //   keyword: "",
      selected: false,
      allTeachers: [],
      allStudents: [],
      allParents: [],
      habitsTeachers: [],
      habitsStudents: [],
      habitsParents: [],
      shiidver: [
        { value: null, text: "Төвөл сонгоно уу?" },
        { value: 0, text: "Эерэг" },
        { value: 1, text: "Сөрөг" },
      ],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        regis_worker_id: "",
        regis_department_id: "",

        selectedPeople: "",
        // teacher_id: "",
        // teacher_dep_id: "",
        // student_id: "",
        // student_school_class_id: "",
        // parent_id: "",
        habit_of_info: "", //Ямар үйлдэл хийсэн талаарх тайлбар
        habit_of_type: "", //Эерэг-0 ба сөрөг-1
        start_date: "", //Эерэг-0 ба сөрөг-1
        end_date: "", //Эерэг-0 ба сөрөг-1
      }),
    };
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  computed: {
    habitsTeacherEnrollers() {
      const grouped = this.habitsTeachers.reduce((acc, teacher) => {
        const key = teacher.reg_user_id;
        if (!acc[key]) {
          acc[key] = {
            reg_user_id: teacher.reg_user_id,
            reg_t_last_name: teacher.reg_t_last_name,
            reg_name: teacher.reg_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
    habitsEmployees() {
      const grouped = this.habitsTeachers.reduce((acc, teacher) => {
        const key = teacher.employee_user_id;
        if (!acc[key]) {
          acc[key] = {
            employee_user_id: teacher.employee_user_id,
            employee_teacher_last_name: teacher.employee_teacher_last_name,
            employee_teacher_name: teacher.employee_teacher_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
    habitsStudEnrollers() {
      const grouped = this.habitsStudents.reduce((acc, stud) => {
        const key = stud.reg_user_id;
        if (!acc[key]) {
          acc[key] = {
            reg_user_id: stud.reg_user_id,
            reg_t_last_name: stud.reg_t_last_name,
            reg_name: stud.reg_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
    habitsStuds() {
      const grouped = this.habitsStudents.reduce((acc, stud) => {
        const key = stud.pro_user_id;
        if (!acc[key]) {
          acc[key] = {
            pro_user_id: stud.pro_user_id,
            pro_student_last_name: stud.pro_student_last_name,
            pro_student_name: stud.pro_student_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
    habitsParentEnrollers() {
      const grouped = this.habitsParents.reduce((acc, parent) => {
        const key = parent.reg_user_id;
        if (!acc[key]) {
          acc[key] = {
            reg_user_id: parent.reg_user_id,
            reg_t_last_name: parent.reg_t_last_name,
            reg_name: parent.reg_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
    habitsParentFilter() {
      const grouped = this.habitsParents.reduce((acc, parent) => {
        const key = parent.pro_user_id;
        if (!acc[key]) {
          acc[key] = {
            pro_user_id: parent.pro_user_id,
            parent_father_ovog: parent.parent_father_ovog,
            parent_father_name: parent.parent_father_name,
            count: 0,
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});
      return Object.values(grouped);
    },
  },
  watch: {
    selected(newValue, oldValue) {
      this.form.reset();
      // if (newValue.indexOf("?") > -1) {
      // }
    },
    year_id: function (newVal, oldVal) {
      // console.log(newVal);
      axios
        .post("/all_worker_functions/getAllHabits", {
          yearID: newVal.id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.allTeachers = res.data.teachers;
          this.allStudents = res.data.students;
          this.allParents = res.data.parents;

          this.habitsTeachers = res.data.habitsOfTeachers;
          this.habitsStudents = res.data.habitsOfStudents;
          this.habitsParents = res.data.habitsOfParents;
          //   this.allEmployees = res.data.employees;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  methods: {
    getAllInfo() {
      axios
        .post("/all_worker_functions/getAllHabits", {
          yearID: this.year_id,
          empUserId: this.empUserId,
          regEpmId: this.regEpmId,
          regEpmIdStud: this.regEpmIdStud,
          studUserId: this.studUserId,
          regEpmIdParent: this.regEpmIdParent,
          parentUserId: this.parentUserId,
          startDate: this.form.start_date,
          endDate: this.form.end_date,
        })
        .then((res) => {
          //   console.log(res.data);
          this.allYears = res.data.allYears;
          this.allTeachers = res.data.teachers;
          this.allStudents = res.data.students;
          this.allParents = res.data.parents;

          this.habitsTeachers = res.data.habitsOfTeachers;
          this.habitsStudents = res.data.habitsOfStudents;
          this.habitsParents = res.data.habitsOfParents;
          //   this.allEmployees = res.data.employees;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelfilters() {
      // this.formSpecialPlanAction.reset();
      this.empUserId = "";
      this.regEpmId = "";
      this.regEpmIdStud = "";
      this.studUserId = "";
      this.regEpmIdParent = "";
      this.parentUserId = "";
      this.form.start_date = "";
      this.form.end_date = "";
      this.getAllInfo();
    },

    customRegEmpName({ reg_t_last_name, reg_name, count }) {
      return `${reg_t_last_name[0]}. ${reg_name} (${count})`;
    },
    customEmpName({
      employee_teacher_last_name,
      employee_teacher_name,
      count,
    }) {
      return `${employee_teacher_last_name[0]}. ${employee_teacher_name} (${count})`;
    },
    customStudName({ pro_student_last_name, pro_student_name, count }) {
      return `${pro_student_last_name[0]}. ${pro_student_name} (${count})`;
    },
    customParentName({ parent_father_ovog, parent_father_name, count }) {
      return `${parent_father_ovog[0]}. ${parent_father_name} (${count})`;
    },
    customNameTeacher({
      employee_teacher_name,
      employee_teacher_last_name,
      department_code,
    }) {
      return `${employee_teacher_last_name} ${employee_teacher_name}(${department_code})`;
    },
    customNameStudent({
      pro_student_name,
      pro_student_last_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name} ${pro_student_name}: Анги: ${school_class_full_name}`;
    },
    customNameParent({
      parent_father_name,
      parent_father_ovog,
      school_class_full_name,
      student_last_name,
      student_name,
    }) {
      return `Аав: ${parent_father_ovog} ${parent_father_name}: Анги: ${school_class_full_name}: Суралцагч: ${student_last_name} ${student_name}`;
    },

    resetModal() {
      //   console.log("resetModal");
      this.$bvModal.hide("modal-habit");
      this.selected = "";
      this.form.reset();
    },
    createHabit() {
      this.$bvModal.show("modal-habit");
      //   console.log("create function load");
    },
    storeHabit() {
      axios
        .post("/all_worker_functions/createHabit", {
          selected: this.selected,
          form: this.form,
        })
        .then((response) => {
          this.resetModal();
          this.$toastr.s("Ажиглалт амжилттай хадгаллаа.", "Амжилттай");
          Fire.$emit("loadAllHabits");
        })
        .catch(() => {
          this.$toastr.e(
            "Уулзалтын бүртгэл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },

    passChangeSchoolEmployee(schoolEmp) {
      //   console.log(schoolEmp);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe%2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manage/passChangeEmployer/" + schoolEmp.user_id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    editComplaint(complaint) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(complaint);
      this.form.id = complaint.complaint_id;
      this.form.student_id = complaint.complaint_students_id;
      this.form.main_content = complaint.complaint_main_content;
      this.form.offer = complaint.complaint_offer;
      this.form.complaint = complaint.complaint_complaint;
      this.form.advise = complaint.complaint_advise;
      this.form.uulz_ajilt_set = complaint.complaint_uulz_ajilt_set;
      this.form.shiid_baidal = complaint.complaint_shiid_baidal;
      this.form.shiid_tailbar = complaint.complaint_shiid_tailbar;
      this.form.meet_time = complaint.complaint_meet_time;
    },
    updateComplaint() {
      //   console.log(this.form);
      this.form
        .put("/manager/updateManagerComplaint/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllComplains");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteComplaint(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllHabits", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
