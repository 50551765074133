<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Төсвийн төрөл</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="5">
                      <b-form-input
                        id="input-1"
                        v-model="form.name"
                        placeholder="Төсвийн төрөл бүртгэх(Бичиг хэрэг, сургалтын хэрэглэгдэхүүн)..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="createBudgetType()"
                        v-if="!editMode"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="updateBudgetType()"
                        v-if="editMode"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack font-scale="2" @click.prevent="cancelEdit()">
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>

        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Нэр</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(budgetType, index) in allBudgetType"
                :key="budgetType.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ budgetType.name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-info"
                    @click="viewBudgetTypeItems(budgetType)"
                    ><i class="fas fa-sitemap"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editBudgetType(budgetType)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteBudgetType(budgetType)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-if="showItemMode">
      <div class="card card-primary">
        <div class="card-header" style="font-size: 70%">
          <h3 class="card-title" v-show="showItemMode">
            Төсвийн төрөлд харьяалагдах бүтээгдэхүүн бүртгэх
          </h3>
          <h3 class="card-title" v-show="!showItemMode">
            Төсвийн төрөлд харьяалагдах бүтээгдэхүүн засах
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <b-form-input
                style="font-size: 70%"
                v-model="formItem.item_name"
                placeholder="Бүтээгдэхүүний нэр"
              ></b-form-input>
              <b-form-input
                type="number"
                min="0"
                max="5"
                style="font-size: 70%"
                v-model="formItem.item_price"
                placeholder="Бүтээгдэхүүний одоогийн үнэ"
              ></b-form-input>
              <b-form-textarea
                style="font-size: 70%"
                id="textarea"
                v-model="formItem.item_description"
                placeholder="Бүтээгдэхүүний тайлбар"
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formItem.errors.has('item_description'),
                  },
                ]"
              ></b-form-textarea>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editItemMode"
            @click.prevent="createBudgetItem()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editItemMode"
            @click.prevent="updateBudgetItem()"
          >
            Засах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editItemMode"
            @click.prevent="clearBudgetItem()"
          >
            Цэвэрлэх
          </button>
          <button class="btn btn-secondary" @click.prevent="cancelItem()">
            Болих
          </button>
        </div>
        <div style="overflow-x: auto">
          <table class="table table-hover text-nowrap">
            <thead style="font-size: 70%">
              <tr role="row">
                <th>#</th>
                <th>Төрөл</th>
                <th>Бүтээгдэхүүн</th>
                <th>Ширхэгийн үнэ</th>
                <th>Тайлбар</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody style="font-size: 60%">
              <tr v-for="(item, index) in items" :key="item.id">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    item.my_type.name
                  }}</b-badge>
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    item.item_name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    item.item_price
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    item.item_description
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editBudgetItem(item)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteBudgetItem(item)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ item.created_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      allBudgetType: [],
      form: new Form({
        id: "",
        name: "",
      }),
      //Items
      showItemMode: false,
      editItemMode: false,
      items: [],
      formItem: new Form({
        id: "",
        budget_type_id: "",
        item_name: "",
        item_price: "",
        item_description: "",
      }),
    };
  },
  methods: {
    getBudgeTypes() {
      axios
        .get("/all_worker_functions/getBudgeTypes")
        .then((response) => {
          this.allBudgetType = response.data.allBudgetType;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editBudgetType(bType) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(bType);
      this.clearBudgetItem();
    },
    createBudgetType() {
      this.form
        .post("/all_worker_functions/createBudgetType")
        .then((response) => {
          this.$toastr.s("Төсвийн төрөл амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadBudgetType");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Төсвийн төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    updateBudgetType() {
      this.action = "Төсвийн төрөл шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateBudgetType/" + this.form.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadBudgetType");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          this.$toastr.e(
            "Төсвийн төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = false;
    },
    deleteBudgetType(bType) {
      Swal.fire({
        title: "Энэ төсвийн төрлийг устгахдаа итгэлтэй байна уу?",
        text:
          "Энэ" + bType.name + " төсвийн төрлийг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Төсвийн төрлийг хасаж байна...";
          this.form
            .get("/all_worker_functions/deleteBudgetType/" + bType.id)
            .then((response) => {
              this.$toastr.s(
                "Төсвийн төрлийг амжилттай устгалаа!",
                "Шинэчилсэн"
              );
              Fire.$emit("loadBudgetType");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },

    //Items

    viewBudgetTypeItems(bType) {
      //   console.log(bType);
      this.cancelEdit();
      this.clearBudgetItem();

      this.formItem.budget_type_id = bType.id;
      this.getMyItems(bType.id);
    },
    getMyItems(id) {
      axios
        .get("/all_worker_functions/items/" + id)
        .then((res) => {
          this.showItemMode = true;
          this.items = res.data.budgetTypeItems;
          //   console.log(res.data);
        })
        .catch((err) => console.log(err));
    },

    createBudgetItem() {
      this.formItem
        .post("/all_worker_functions/createItem")
        .then((res) => {
          this.clearBudgetItem();
          //   console.log(res);
          Fire.$emit("loadBudgetTypeItem");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editBudgetItem(item) {
      this.editItemMode = true;
      this.cancelEdit();
      this.formItem.fill(item);
    },
    updateBudgetItem() {
      this.formItem
        .put("/all_worker_functions/updateBudgetItem/" + this.formItem.id)
        .then((res) => {
          //   console.log(res.data);
          this.getMyItems(this.formItem.budget_type_id);
          Fire.$emit("loadBudgetTypeItem");
          this.clearBudgetItem();
        })
        .catch((error) => console.log(error));
    },
    clearBudgetItem() {
      this.formItem.item_name = "";
      this.formItem.item_price = "";
      this.formItem.item_description = "";
      this.editItemMode = false;
    },

    deleteBudgetItem(item) {
      Swal.fire({
        title: "Энэ бүтээгдэхүүн устгахдаа итгэлтэй байна уу?",
        text:
          "Энэ" +
          item.item_name +
          " бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.form
            .get("/all_worker_functions/deleteBudgetItem/" + item.id)
            .then((response) => {
              this.$toastr.s("Бүтээгдэхүүн амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("loadBudgetTypeItem");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },

    cancelItem() {
      this.showItemMode = false;
      this.clearBudgetItem();
      this.cancelEdit();
    },
  },
  created() {
    this.getBudgeTypes();
    Fire.$on("loadBudgetType", () => {
      this.getBudgeTypes();
    });
    Fire.$on("loadBudgetTypeItem", () => {
      this.getMyItems(this.formItem.budget_type_id);
    });
  },
};
</script>

<style></style>
