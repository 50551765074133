<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong
              >Суралцагчдын бие бялдрын хөгжлийг үнэлэх шалгуур үзүүлэлтүүд ба
              хэмжүүр</strong
            >
          </h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3">
                  <multiselect
                    v-model="selectedClass"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="customClassLabel"
                    placeholder="Анги сонгох"
                    :options="physicalClassOptions"
                    :searchable="true"
                    :allow-empty="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.full_name }} анги -
                      {{ option.teacher.teacher_last_name | capitalizeAndEkhniiVseg }}.{{
                        option.teacher.teacher_name
                      }}
                    </template>
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="getPhysicalStudents()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="physicalIsLoaded" rounded="sm" class="mt-3">
            <b-card
              v-show="isFilter"
              bg-variant="light"
              text-variant="dark"
              title="АНГИЙН ДУНДАЖ ҮЗҮҮЛЭЛТ"
            >
              <b-card-text>
                <apexchart
                  height="200"
                  v-if="showChart"
                  type="line"
                  :options="chartOptions"
                  :series="chartSeries"
                ></apexchart>
              </b-card-text>
            </b-card>
            <div class="row" v-show="isFilter">
              <div
                class="col-lg-12"
                v-for="student in physicalStudents"
                :key="student.id"
              >
                <div
                  :class="[
                    'card',
                    { 'collapsed-card': student.myData == null },
                    student.myData &&
                    student.myData.my_physicals &&
                    student.myData.my_physicals.length == 0 &&
                    student.myData.my_body_index_list &&
                    student.myData.my_body_index_list.length == 0
                      ? 'card-light'
                      : 'card-success',
                  ]"
                >
                  <div class="card-header">
                    <h3 class="card-title mr-2">
                      {{ student.student_full_name }}
                    </h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        @click="getMyPhysical(student)"
                        data-card-widget="collapse"
                      >
                        <i class="fas fa-plus" v-if="student.myData == null"></i>
                        <i class="fas fa-minus" v-else></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <b-overlay :show="student.isLoad == 1" rounded="sm" class="mt-3">
                      <physical-data
                        v-if="student.myData"
                        :selectedClass="selectedClass"
                        :student="student"
                        :studentOptions="studentOptions"
                        :showChart="showChart"
                        :physicalTypeOptions="physicalTypeOptions"
                      ></physical-data>
                    </b-overlay>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import physicalData from "./physical-data.vue";

export default {
  data() {
    return {
      selectedClass: null,
      physicalIsLoaded: true,
      isFilter: false,
      physicalStudents: [],
      physicalClassOptions: [],
      showChart: false,
      chartOptions: {
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#00e396", "#2E93fA", "#E91E63"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
        },
      },
      chartSeries: [
        {
          name: "Нормативийн дээд утга",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "Нормативийн доод утга",
          data: [],
        },
      ],
      nameArr: [],
      studentOptions: [],
    };
  },
  components: {
    Multiselect,
    physicalData: physicalData,
  },
  watch: {
    selectedClass: function (newVal) {
      axios
        .get("/project_mentor/getStudentList/" + newVal.id)
        .then((response) => {
          this.studentOptions = response.data.students;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  methods: {
    //#region Students
    getPhysicalType() {
      axios
        .get("/physical/getPhysicalsType/")
        .then((response) => {
          this.physicalTypeOptions = response.data.physicalTypes;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllSchoolClass() {
      this.physicalIsLoaded = false;
      axios
        .get("/physical/getAllSchoolClass/")
        .then((response) => {
          this.physicalClassOptions = response.data.schoolclasses;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    customClassLabel({ full_name, teacher }) {
      return `${full_name} анги -
                  ${teacher.teacher_last_name} ${teacher.teacher_name}`;
    },
    getPhysicalStudents() {
      if (this.selectedClass) {
        this.physicalIsLoaded = true;
        this.showChart = false;
        axios
          .get("/physical/getPhysicalStudents/" + this.selectedClass.id)
          .then((response) => {
            this.isFilter = true;
            this.physicalStudents = response.data.physicalStudents;
            this.nameArr = response.data.nameArr;
            this.chartOptions.xaxis.categories = this.nameArr;
            this.chartSeries[0].data = response.data.maxArr;
            this.chartSeries[1].data = response.data.avgArr;
            this.chartSeries[2].data = response.data.minArr;
            this.showChart = true;
            this.physicalIsLoaded = false;
          })
          .catch((error) => {
            //   console.log(error);
          });
      }
    },
    getMyPhysical(student) {
      student.isLoad = 1;
      this.showChart = false;
      axios
        .post("/physical/getMyPhysical", {
          classId: this.selectedClass.id,
          stdId: student.student_user_id,
        })
        .then((response) => {
          student.myData = response.data.std;
          student.isLoad = 0;
          this.showChart = true;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //#endregion
  },
  created() {
    this.getAllSchoolClass();
    this.getPhysicalType();
    Fire.$on("getPhysicalStudents", () => {
      this.getPhysicalStudents();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 20px !important;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}
.studentCardHeader {
  background-color: #fff !important;
}
.callout {
  margin-bottom: 0px !important;
}
.badgePhysic {
  font-size: 90%;
}
</style>
