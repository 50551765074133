<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab">Устгагдсан</a>
            </li> -->
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Бүртгэлтэй тайлан</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addProblemRoom()"
                    >Тайлан бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт тайлангийн тоо:
                      {{ reports.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="reportLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageReports"
                            :options="pageOptionsReports"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterReports"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterReports"
                                @click="filterReports = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageReports"
                      :total-rows="totalRowsReports"
                      :per-page="perPageReports"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="reports"
                      :fields="fieldsActiveReports"
                      :current-page="currentPageReports"
                      :per-page="perPageReports"
                      :filter="filterReports"
                      :filter-ignored-fields="filterIgnoredFieldsReports"
                      :filter-included-fields="filterOnReports"
                      @filtered="onFilteredRooms"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(employee_info)="data">
                        <b-badge
                          variant="info"
                          v-if="data.item.employee_info != null"
                        >
                          {{ data.item.employee_info.teacher_last_name[0] }}.
                          {{ data.item.employee_info.teacher_name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Багш, ажилтан бүртгэгдээгүй
                        </b-badge>
                      </template>

                      <template #cell(enrol_employee_info)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.enrol_employee_info !== null"
                        >
                          {{
                            data.item.enrol_employee_info.teacher_last_name[0]
                          }}. {{ data.item.enrol_employee_info.teacher_name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Үнэлгээ хийсэн ажилтан бүртгэгдээгүй.
                        </b-badge>
                      </template>

                      <template #cell(onoo)="data">
                        <b-badge variant="secondary">
                          {{ data.item.onoo }}
                        </b-badge>
                      </template>

                      <template #cell(advantage)="data">
                        <b-badge variant="success" style="width: 10rem">
                          <!-- <quill-editor v-model="data.item.advantage" :options="editorOptionOnlyOpen">
                          </quill-editor> -->
                          <span
                            style="white-space: pre-wrap; font-size: 80%"
                            v-html="data.item.advantage.substring(0, 10000)"
                          ></span>
                        </b-badge>
                      </template>

                      <template #cell(deadvantage)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.deadvantage !== null"
                          style="width: 10rem"
                        >
                          <!-- <quill-editor v-model="data.item.deadvantage" :options="editorOptionOnlyOpen">
                          </quill-editor> -->
                          <span
                            style="white-space: pre-wrap; font-size: 80%"
                            v-html="data.item.deadvantage.substring(0, 10000)"
                          ></span>
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Сайжруулах санал бүртгэгдээгүй.
                        </b-badge>
                      </template>
                      <template #cell(conclusion)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.conclusion !== null"
                          style="width: 10rem"
                        >
                          <!-- <quill-editor v-model="data.item.conclusion" :options="editorOptionOnlyOpen">
                          </quill-editor> -->
                          <span
                            style="white-space: pre-wrap; font-size: 80%"
                            v-html="data.item.conclusion.substring(0, 10000)"
                          ></span>
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Дүгнэлт бүртгэгдээгүй.
                        </b-badge>
                      </template>

                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolReport(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolReport(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong>Байгууллагын хэмжээнд бүртгэлтэй өрөө, танхимууд</strong>
                  <div class="main-header navbar navbar-expand navbar-white navbar-light">
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт өрөө танхимуудын тоо:
                      {{ reportsDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="reportLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group label="Нэг хуудсанд харуулах тоо" label-for="per-page-inactive-select"
                          label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="right" label-size="sm"
                          class="mb-0">
                          <b-form-select id="per-page-inactive-select" v-model="perPageInactiveReports"
                            :options="pageOptionsInactiveReports" size="sm"></b-form-select>
                        </b-form-group>

                      </b-col>
                    </b-row>
                    <b-pagination v-model="currentPageInactiveReports" :total-rows="totalRowsInactiveReports"
                      :per-page="perPageInactiveReports" align="fill" size="sm" class="my-0"></b-pagination>
                    <b-table hover :items="reportsDeleted" :fields="fieldsInactiveReports"
                      :current-page="currentPageInactiveReports" :per-page="perPageInactiveReports">

                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(block_name)="data">
                        <b-badge variant="info">
                          {{ data.item.block_name }}
                        </b-badge>
                      </template>

                      <template #cell(my_room_category)="data">
                        <b-badge variant="secondary">
                          {{ data.item.my_room_category.name }}
                        </b-badge>
                      </template>

                      <template #cell(room_number)="data">
                        <b-badge variant="success">
                          {{ data.item.room_number }}
                        </b-badge>
                      </template>

                      <template #cell(room_employees)="data">
                        <div style="display: inline-block" v-for="(employee, index) in data.item.room_employees"
                          :key="index">
                          <b-badge variant="success" v-if="employee.my_user_info">
                            {{ employee.my_user_info.teacher_last_name[0] }}.
                            {{ employee.my_user_info.teacher_name }}
                          </b-badge>
                        </div>
                      </template>

                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a href="#" class="badge bg-warning" @click="restoreSchoolRoom(data.item)"><i
                            class="fas fa-trash-restore-alt"></i></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Тайлангийн үнэлгээ бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-report"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="!editModeReport ? 'Тайлан бүртгэх' : 'Тайлан засах'"
        class="modal fade"
        hide-footer
        @hidden="cancelEdit"
      >
        <div class="modal-body">
          <form
            @submit.prevent="
              !editModeReport ? createSchoolReport() : updateSchoolReport()
            "
          >
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <div class="row">
                      <multiselect
                        v-model="form.emp_id"
                        :multiple="false"
                        deselect-label="Can't remove this value"
                        :custom-label="nameTeacher"
                        track-by="emp_teacher_name"
                        label="emp_teacher_name"
                        placeholder="Сонголт хийх"
                        :options="employees"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        :preserve-search="true"
                      >
                        <!-- @select="toggleSelect" -->

                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.emp_teacher_last_name }}.
                            {{ option.emp_teacher_name }} ({{
                              option.dep_department_code
                            }})</strong
                          >
                          сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Үнэлгээ</label>
                    <b-form-input
                      id="onoo"
                      v-model="form.onoo"
                      type="number"
                      size="sm"
                      placeholder="Үнэлгээ"
                      required
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Давуу тал</label>
                    <quill-editor
                      v-model="form.advantage"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                    <!-- <b-form-textarea id="advantage" v-model="form.advantage"
                      placeholder="Давуу тал (Бусад багш, ажилтанд жишиг болохуйц санаа, шинэлэг талууд)" rows="3"
                      max-rows="6"></b-form-textarea>

                    <has-error :form="form" field="advantage"></has-error> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Сайжруулах санал(Сул тал)</label>
                    <quill-editor
                      v-model="form.deadvantage"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                    <!-- <b-form-textarea id="deadvantage" v-model="form.deadvantage"
                      placeholder="Давуу тал (Бусад багш, ажилтанд жишиг болохуйц санаа, шинэлэг талууд)" rows="3"
                      max-rows="6"></b-form-textarea>

                    <has-error :form="form" field="deadvantage"></has-error> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Дүгнэлт</label>
                    <quill-editor
                      v-model="form.conclusion"
                      :options="editorOptionParent"
                    >
                    </quill-editor>
                    <!-- <b-form-textarea id="conclusion" v-model="form.conclusion"
                      placeholder="Дүгнэлт(....................)" rows="3" max-rows="6"></b-form-textarea>

                    <has-error :form="form" field="schools"></has-error> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeReport"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeReport"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeReport"
                @click.prevent="cancelEdit"
              >
                Цуцлах
              </button>
              <button
                class="btn btn-warning"
                v-show="!editModeReport"
                @click.prevent="cancelCreate"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formRoomModalTitle: "Өрөө, танхим бүртгэх",

      //Өрөө танхимын хэсэг
      reports: [],
      reportsDeleted: [],

      reportLoaded: true,
      editModeReport: false,

      //b-table тохиргоо
      totalRowsReports: 1,
      currentPageReports: 1,
      perPageReports: 200,
      pageOptionsReports: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterReports: "",
      filterOnReports: [],
      filterIgnoredFieldsReports: ["advantage", "deadvantage", "conclusion"],
      fieldsActiveReports: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "employee_info",
          label: "Багш, ажилтан",
          sortable: true,
        },
        {
          key: "enrol_employee_info",
          label: "Тайланг үнэлсэн",
          sortable: true,
        },
        {
          key: "onoo",
          label: "Үнэлгээ",
          sortable: true,
        },
        {
          key: "advantage",
          label: "Давуу тал",
          sortable: true,
        },
        {
          key: "deadvantage",
          label: "Сайжруулах санал",
          sortable: true,
        },
        {
          key: "conclusion",
          label: "Дүгнэлт",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveReports: 1,
      currentPageInactiveReports: 1,
      perPageInactiveReports: 25,
      pageOptionsInactiveReports: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveReports: "",
      filterOnInactiveReports: ["advantage", "deadvantage", "conclusion"],
      fieldsInactiveReports: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "emp_id",
          label: "Багш, ажилтан",
          sortable: true,
        },
        {
          key: "enrol_emp_id",
          label: "Тайланг үнэлсэн",
          sortable: true,
        },
        {
          key: "onoo",
          label: "Үнэлгээ",
          sortable: true,
        },
        {
          key: "advantage",
          label: "Давуу тал",
          sortable: true,
        },
        {
          key: "deadvantage",
          label: "Сайжруулах санал",
          sortable: true,
        },
        {
          key: "conclusion",
          label: "Дүгнэлт",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        course_id: "",
        subject_id: "",
        enrol_emp_id: "",
        emp_id: "",
        onoo: "",
        advantage: "",
        deadvantage: "",
        conclusion: "",
      }),
      editorOptionParent: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder: "Тайлбар бичих...",
      },
      editorOptionOnlyOpen: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
    };
  },
  components: { Multiselect },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    employees: function () {
      return this.$store.state.all_users;
    },
  },
  methods: {
    getAllReports() {
      axios
        .post("/all_worker_functions/getAllReports")
        .then((res) => {
          //   console.log(response);
          this.reports = res.data.reports;
          this.reportsDeleted = res.data.reportsDeleted;
          this.totalRowsReports = this.reports ? this.reports.length : 0;
          this.totalRowsInactiveReports = this.reportsDeleted
            ? this.reportsDeleted.length
            : 0;
          this.reportLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    schoolClassLabel({ my_course }) {
      return `${my_course.my_school_class.full_name}`;
    },

    //b-table тохирго
    onFilteredRooms(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsReports = filteredItems.length;
      this.currentPageReports = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveReports = filteredItems.length;
      this.currentPageInactiveReports = 1;
    },

    addProblemRoom() {
      this.form.reset();
      this.$bvModal.show("modal-create-report");
    },
    createSchoolReport() {
      //   console.log("create");
      this.form
        .post("/all_worker_functions/createSchoolReport")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadReports");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editSchoolReport(report) {
      // console.log(report);
      //exams_teacher_id, block_name,
      this.editModeReport = true;
      this.form.fill(report);
      //       let selectRoomCat = "";
      //
      //       this.schoolRoomCategories.forEach((element) => {
      //         if (element.id == room.room_cat_id) {
      //           selectRoomCat = element;
      //         }
      //       });

      this.employees.forEach((element) => {
        // console.log(element);
        if (element.emp_user_id === report.employee_info.user_id) {
          this.form.emp_id = element;
        }
      });

      this.$bvModal.show("modal-create-report");
    },
    updateSchoolReport() {
      //   console.log("udpate");
      this.form
        .put("/all_worker_functions/updateSchoolReport")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadReports");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeReport = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-report");
    },

    nameTeacher({
      emp_teacher_last_name,
      emp_teacher_name,
      dep_department_code,
    }) {
      return `${emp_teacher_last_name}. ${emp_teacher_name}(${dep_department_code})`;
    },

    deleteSchoolReport(room) {
      //   console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн хэрэглэгчид хамаарал бүхий тайлангийн үнэлгээ ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/all_worker_functions/deleteSchoolReport/" + room.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Тайланг системээс устгалаа.", "success");
              Fire.$emit("loadReports");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreSchoolRoom(room) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/all_worker_functions/restoreSchoolRoom/" + room.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadReports");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  watch: {
    "formRoomAddTime.year_id"(newVal, oldVal) {
      this.getAllTimeTables = [];
      // console.log("teacher_id Өөрчлөгдсөн.", newVal);
      if (this.formRoomAddTime.year_id !== "") {
        axios
          .post("/all_worker_functions/getAllTimeTables", {
            yearId: newVal,
          })
          .then((res) => {
            this.getAllTimeTables = res.data.getAllTimeTables;
          });
      } else {
        this.getAllTimeTables = [];
      }
    },
  },
  created() {
    this.getAllReports();
    Fire.$on("loadReports", () => {
      this.getAllReports();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
