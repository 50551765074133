<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">Ажлын үнэлгээ</h3>
            <download-excel
              v-show="downloadData"
              :data="myAllEvaluations"
              class="btn btn-default"
              :fields="json_fields"
            >
              Сонгосон саруудын үнэлгээний нэгтгэл татах
            </download-excel>
            <multiselect
              v-model="selectedMonths"
              :options="monthsRe"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Нэгдсэн дүн татах сарыг сонгоно уу?"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >Нийт {{ values.length }} сар сонгогдлоо. Сонгогдсон сараар
                  дүнгийн нэгтгэл харагдана.</span
                ></template
              >
            </multiselect>
            <b-button @click="filterByMonthED()" class="btn btn-warning"
              >Өгөгдөл</b-button
            >
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <!-- Сургалт хариуцсан захирал -->
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsMinadCurr">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsMinadCurr"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Бүх түвшний менежер -->
            <thead>
              <tr role="row">
                <th colspan="22">Цогцолборын удирдлага</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsManager">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsManager"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Сургуулийн багш нар -->
            <thead>
              <tr role="row">
                <th colspan="22">Сургуулийн багш нар</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsTeacher">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsTeacher"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Сургуулийн суралцахуйн ажилчид -->
            <thead>
              <tr role="row">
                <th colspan="22">Сургуулийн суралцахуйн албаны ажилчид</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsEmployee">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployee"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Цэцэрлэгийн багш нар -->
            <thead>
              <tr role="row">
                <th colspan="22">Цэцэрлэгийн багш нар</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsKGTeacher">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsKGTeacher"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Спорт клубын багш нар -->
            <thead>
              <tr role="row">
                <th colspan="22">Спорт клубын багш нар</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsSKTeacher">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsSKTeacher"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Спорт клубын ажилчид -->
            <thead>
              <tr role="row">
                <th colspan="22">Спорт клубын ажилчид</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsSKEmployee">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsSKEmployee"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Суралцахуйг дэмжих үйл ажиллагааны албаны ажилчид -->
            <thead>
              <tr role="row">
                <th colspan="22">
                  Суралцахуйг дэмжих үйл ажиллагааны албаны ажилчид
                </th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsVAAEmployee">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsVAAEmployee"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Санхүүгийн албаны ажилчид -->
            <thead>
              <tr role="row">
                <th colspan="22">Санхүүгийн албаны ажилчид</th>
              </tr>
              <tr role="row">
                <th>№</th>
                <th>Мэргэжлийн баг</th>
                <th>Албан тушаал</th>
                <th>Ажилтны эцэг/эх/-ийн нэр</th>
                <th>Ажилтны нэр</th>
                <th>Emp ID</th>
                <th>Ажиллах нийт цаг</th>
                <th>Цалин тооцох цаг</th>
                <th>Илүү цаг(1.5)</th>
                <th id="popover-target-1">
                  Илүү цаг
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Илүү цаг</template>
                    Хичээлийн цагийн нормоос хэтэрсэн
                  </b-popover>
                </th>
                <th>Цалинтай чөлөө</th>
                <th>Цалингүй чөлөө</th>
                <th>Тасалсан</th>
                <th id="popover-target-2">
                  Үр дүнгийн урамшуулал
                  <b-popover
                    target="popover-target-2"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Үр дүнгийн урамшуулал</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож, улиралд 60%-ыг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-3">
                  АУБ үнэлгээ
                  <b-popover
                    target="popover-target-3"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>АУБ үнэлгээ</template>
                    Улиралд нийт 450000 төгрөг.
                    <b>Сар бүр 50000 төгрөг тогтмол олгоно.</b> Үлдсэн 300000
                    төгрөгийг улиралд гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-4">
                  БНБ ахалсан
                  <b-popover
                    target="popover-target-4"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>БНБ ахалсан</template>
                    Улиралд 300000 төгрөг.
                    <b>Сар бүр 100000 төгрөг.</b> Улиралд 300000 төгрөгийг
                    гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-5">
                  Мэргэжлийн зэрэг
                  <b-popover
                    target="popover-target-5"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Мэргэжлийн зэрэг</template>
                    ТҮБД-3 сүлжээний шатлалаар тооцож сар бүр Заах аргач 10%,
                    Тэргүүлэх 15%, Зөвлөх 20%, Ахлах нийгмийн ажилтан 20% тооцож
                    олгоно.
                    <b>Улиралд 300000</b> төгрөгийг гүйцэтгэлээр бодож олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-6">
                  Эрдмийн зэрэг
                  <b-popover
                    target="popover-target-6"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Эрдмийн зэрэг</template>
                    Доктор 20%, Үндсэн цалингийн <b>20%</b> сар бүр тооцож,
                    улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th id="popover-target-7">
                  Спортын зэрэг
                  <b-popover
                    target="popover-target-7"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>Спортын зэрэг</template>
                    Спортын мастер 20%, Үндсэн цалингийн <b>20%</b> сар бүр
                    тооцож, улиралд 60% олгоно.
                  </b-popover>
                </th>
                <th>Дүгнэсэн</th>
                <th>Баталгаажилт</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsVAAFinance">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsVAAFinance"
                :key="evaluation.id"
              >
                <td tabindex="0">
                  {{ index + 1 }}
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}
                    </span>
                  </h6>
                </td>
                <td tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ niitTsag(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsagToots(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag1_5(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag7(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag15(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag16(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitTsag18(evaluation) }} ц
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitUrDunUramsh(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsAUB(evaluation) }}
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsBNB(evaluation) }}</span
                  >
                </td>
                <td tabindex="0">
                  <span class="badge bg-info">
                    {{ niitNemKhulsMerZer(evaluation) }} %
                  </span>
                </td>
                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsErdZer(evaluation) }} %</span
                  >
                </td>

                <td tabindex="0">
                  <span class="badge bg-info"
                    >{{ niitNemKhulsSportZer(evaluation) }} %</span
                  >
                </td>
                <td tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td tabindex="0">
                  {{ confirmCheck(evaluation) }}
                </td>
                <td tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      //   keyword: "",
      allEvaluationsMinadCurr: [],
      allEvaluationsManager: [],
      allEvaluationsTeacher: [],
      allEvaluationsEmployee: [],
      allEvaluationsKGTeacher: [],
      allEvaluationsSKTeacher: [],
      allEvaluationsSKEmployee: [],
      allEvaluationsVAAEmployee: [],
      allEvaluationsVAAFinance: [],
      monthsRe: [
        { name: "1", january: "1-сар" },
        { name: "2", february: "2-сар" },
        { name: "3", march: "3-сар" },
        { name: "4", april: "4-сар" },
        { name: "5", may: "5-сар" },
        { name: "6", june: "6-сар" },
        { name: "7", july: "7-сар" },
        { name: "8", august: "8-сар" },
        { name: "9", september: "9-сар" },
        { name: "10", october: "10-сар" },
        { name: "11", november: "11-сар" },
        { name: "12", december: "12-сар" },
      ],
      selectedMonths: [],
      resultMy: [],

      myAllEvaluations: [],
      downloadData: false,
      json_fields: {
        "Мэргэжлийн баг": "department_code",
        "Албан тушаал": "position_name",
        "Ажилтны эцэг/эх/-ийн нэр": "emp_teacher_last_name",
        "Ажилтны нэр": "emp_teacher_name",
        "Ажиллах нийт цаг": "shal13",
        "Цалин тооцох цаг": "shal12",
        "Илүү цаг(1.5)": "shal14",
        "Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)": "shal7",
        "Цалинтай чөлөө": "shal15",
        "Цалингүй чөлөө": "shal16",
        Тасалсан: "shal18",
        "Үр дүнгийн урамшуулал": "shal1_4",
        "АУБ үнэлгээ": "shal5_6",
        "БНБ ахалсан": "shal8_per",
        "Мэргэжлийн зэрэг": "shal9_per",
        "Эрдмийн зэрэг": "shal10_per",
        "Спортын зэрэг": "shal17_per",
        Баталгаажилт: "confirm",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  watch: {
    selectedMonths(newVal, oldVal) {
      //   console.log("шинэ" + newVal, "Хуучин" + oldVal);
      this.resultMy = [];
    },
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    //Сараар шүүж авах

    filterByMonthED() {
      axios
        .post("/hr/evaluationsDataUliral", {
          ajliin_sar: this.selectedMonths,
        })
        .then((response) => {
          let sortMinadCurr = response.data.minadCurr;
          sortMinadCurr.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.allEvaluationsMinadCurr = sortMinadCurr;

          let sortManager = response.data.managers;
          sortManager.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.allEvaluationsManager = sortManager;

          let sortTeacher = response.data.teachers;
          sortTeacher.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.allEvaluationsTeacher = sortTeacher;

          let sortEmployee = response.data.employees;
          sortEmployee.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsEmployee = sortEmployee;
          //   console.log(response.data);
          //   console.log(this.allEvaluationsTeacher);
          //  'allEvaluationsKGTeacher' => $allEvaluationsKGTeacher, //Цэцэрлэгийн багш
          let sortTeacherKG = response.data.allEvaluationsKGTeacher;
          sortTeacherKG.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsKGTeacher = sortTeacherKG;
          //   'allEvaluationsSKTeacher' => $allEvaluationsSKTeacher, //Клубын багш
          let sortTeacherSK = response.data.allEvaluationsSKTeacher;
          sortTeacherSK.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsSKTeacher = sortTeacherSK;
          //   'allEvaluationsSKEmployee' => $allEvaluationsSKEmployee, //Клубын ажилчид
          let sortEmployeeSK = response.data.allEvaluationsSKEmployee;
          sortEmployeeSK.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsSKEmployee = sortEmployeeSK;
          //   'allEvaluationsVAAEmployee' => $allEvaluationsVAAEmployee, //Үйл ажиллагааны ажилчид
          let sortEmployeeVAA = response.data.allEvaluationsVAAEmployee;
          sortEmployeeVAA.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsVAAEmployee = sortEmployeeVAA;

          //   'allEvaluationsVAAFinance' => $allEvaluationsVAAFinance, //Санхүүгийн албаны ажилчид
          let sortEmployeeFinance = response.data.allEvaluationsVAAFinance;
          sortEmployeeFinance.sort(
            (a, b) =>
              a.department_name.localeCompare(b.department_name) ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );

          this.allEvaluationsVAAFinance = sortEmployeeFinance;
          this.myAllEvaluations = [
            ...this.allEvaluationsMinadCurr,
            ...this.allEvaluationsManager,
            ...this.allEvaluationsTeacher,
            ...this.allEvaluationsEmployee,
            ...this.allEvaluationsKGTeacher,
            ...this.allEvaluationsSKTeacher,
            ...this.allEvaluationsSKEmployee,
            ...this.allEvaluationsVAAEmployee,
            ...this.allEvaluationsVAAFinance,
          ];
          this.myAllEvaluations.forEach((element) => {
            element.shal13 = this.niitTsag(element);
            element.shal12 = this.niitTsagToots(element);
            element.shal14 = this.niitTsag1_5(element);
            element.shal7 = this.niitTsag7(element);
            element.shal15 = this.niitTsag15(element);
            element.shal16 = this.niitTsag16(element);
            element.shal18 = this.niitTsag18(element);
            element.shal1_4 = this.niitUrDunUramsh(element);
            element.shal5_6 = this.niitNemKhulsAUB(element);
            element.shal8_per = this.niitNemKhulsBNB(element);
            element.shal9_per = this.niitNemKhulsMerZer(element);
            element.shal10_per = this.niitNemKhulsErdZer(element);
            element.shal17_per = this.niitNemKhulsSportZer(element);
            element.confirm = this.confirmCheck(element);
          });
          //   console.log(this.myAllEvaluations);/
          this.downloadData = true;
        })
        .catch();
    },

    niitTsag(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal13 = [];
      this.monthsRe.forEach((element) => {
        let shal13_name = "ajliin_sar_" + element.name + "_shal13";
        myShal13.push(shal13_name);
      });
      let niil = myShal13.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsagToots(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal12 = [];
      this.monthsRe.forEach((element) => {
        let shal12_name = "ajliin_sar_" + element.name + "_shal12";
        myShal12.push(shal12_name);
      });
      let niil = myShal12.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsag1_5(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal14 = [];
      this.monthsRe.forEach((element) => {
        let shal14_name = "ajliin_sar_" + element.name + "_shal14";
        myShal14.push(shal14_name);
      });
      let niil = myShal14.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsag7(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal7 = [];
      this.monthsRe.forEach((element) => {
        let shal7_name = "ajliin_sar_" + element.name + "_shal7";
        myShal7.push(shal7_name);
      });
      let niil = myShal7.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsag15(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal15 = [];
      this.monthsRe.forEach((element) => {
        let shal15_name = "ajliin_sar_" + element.name + "_shal15";
        myShal15.push(shal15_name);
      });
      let niil = myShal15.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsag16(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal16 = [];
      this.monthsRe.forEach((element) => {
        let shal16_name = "ajliin_sar_" + element.name + "_shal16";
        myShal16.push(shal16_name);
      });
      let niil = myShal16.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitTsag18(evaluations) {
      //   console.log(evaluations.ajliin_sar_9_shal13);
      let myShal18 = [];
      this.monthsRe.forEach((element) => {
        let shal18_name = "ajliin_sar_" + element.name + "_shal18";
        myShal18.push(shal18_name);
      });
      let niil = myShal18.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber;
    },
    niitUrDunUramsh(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShal1_4 = [];
      this.monthsRe.forEach((element) => {
        let shal1_4_name = "ajliin_sar_" + element.name + "_shal1_4";
        myShal1_4.push(shal1_4_name);
      });
      let niil = myShal1_4.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber.toFixed(1);
    },
    niitNemKhulsAUB(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShalaub = [];
      this.monthsRe.forEach((element) => {
        let shalaub_name = "ajliin_sar_" + element.name + "_aub";
        myShalaub.push(shalaub_name);
      });
      let niil = myShalaub.map(function (el, index, array) {
        return evaluations[el];
      });
      //   console.log(niil);
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      return niilber.toFixed(1);
    },
    niitNemKhulsBNB(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShal8 = [];
      this.monthsRe.forEach((element) => {
        let shal8_name = "ajliin_sar_" + element.name + "_shal8";
        myShal8.push(shal8_name);
      });
      let niil = myShal8.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      return niilber.toFixed(1);
    },
    niitNemKhulsMerZer(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShal9 = [];
      this.monthsRe.forEach((element) => {
        let shal9_name = "ajliin_sar_" + element.name + "_shal9";
        myShal9.push(shal9_name);
      });
      let niil = myShal9.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      return niilber;
    },
    niitNemKhulsErdZer(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShal10 = [];
      this.monthsRe.forEach((element) => {
        let shal10_name = "ajliin_sar_" + element.name + "_shal10";
        myShal10.push(shal10_name);
      });
      let niil = myShal10.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      return niilber;
    },
    niitNemKhulsSportZer(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myShal17 = [];
      this.monthsRe.forEach((element) => {
        let shal17_name = "ajliin_sar_" + element.name + "_shal17";
        myShal17.push(shal17_name);
      });
      let niil = myShal17.map(function (el, index, array) {
        return evaluations[el];
      });
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      return niilber;
    },
    confirmCheck(evaluations) {
      // console.log(evaluations.ajliin_sar_9_shal13);
      let myConfirm = [];
      this.monthsRe.forEach((element) => {
        let confirm_name = "ajliin_sar_" + element.name + "_confirmed";
        myConfirm.push(confirm_name);
      });
      //   console.log(myConfirm);
      let niil = myConfirm.map(function (el, index, array) {
        return evaluations[el];
      });
      //   console.log(niil);
      let niilFilter = niil.filter((el) => el !== undefined && el !== null);
      //   console.log(niilFilter);
      let urt = niilFilter.length;
      //   console.log(urt);
      let niilber = niilFilter.reduce((a, b) => a + b, 0);
      //   console.log(niilber);
      if (urt / 2 < niilber) {
        return "Баталгаажсан";
      } else {
        return "Баталгаажаагүй";
      }
    },
  },
  computed: {
    // validationNumber1() {
    //   return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    // },
  },
  created() {
    Fire.$on("loadSchoolManagers", () => {});
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
th,
span {
  font-size: 60%;
}
</style>
