<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Сургуулийн ирцийн төрөл</h3>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
          <div class="col-sm-8">
            <table
              class="table table-bordered table-hover table-striped dataTable dtr-inline"
              role="grid"
            >
              <thead>
                <tr role="row">
                  <th>№</th>
                  <th>Ирцийн төрөл</th>
                  <th>Үүсгэсэн</th>
                  <th>Зассан</th>
                  <th>Үйлдлүүд</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="odd"
                  v-for="(schoolattendancetype, index) in schoolattendancetypes"
                  :key="schoolattendancetype.id"
                >
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolattendancetype.attendance_type }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolattendancetype.created_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolattendancetype.updated_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <a
                      href="#"
                      class="badge bg-purple"
                      @click="viewSchoolAttendanceType(schoolattendancetype)"
                      ><i class="far fa-eye"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolAttendanceType(schoolattendancetype)"
                      ><i class="fas fa-edit"></i
                    ></a>

                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolCourseType(schoolattendancetype)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Ирцийн төрөл бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">Ирцийн төрөл засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="
                  !editMode
                    ? createSchoolAttendanceType()
                    : updateSchoolAttendanceType()
                "
              >
                <div class="card-body">
                  <div class="form-group">
                    <label>Ирцийн төрөл</label>
                    <input
                      type="text"
                      name="attendance_type"
                      v-model="form.attendance_type"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('attendance_type'),
                        },
                      ]"
                      placeholder="Ирсэн, тас, өвчтэй, чөлөөтэй, хүндэтгэх шалтгаантай"
                    />
                    <has-error :form="form" field="attendance_type"></has-error>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editMode"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editMode"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editMode"
                    @click.prevent="cancelEdit"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      schoolattendancetypes: [],
      form: new Form({
        id: "",
        attendance_type: "",
      }),
    };
  },
  methods: {
    getAllSchoolAttendanceTypes() {
      axios
        .get("/manager/getAllSchoolAttendanceType")
        .then((response) => {
          //   console.log(response);
          this.schoolattendancetypes = response.data.schoolattendancetypes;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolAttendanceType(schoolattendancetype) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolattendancetype);
    },
    editSchoolAttendanceType(schoolattendancetype) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolattendancetype);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolattendancetype);
      //   console.log(this.form);
    },
    updateSchoolAttendanceType() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/attendancetype/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadSchoolAttendanceTypes");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolAttendanceType(schoolattendancetype) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolattendancetype);
    },
    createSchoolAttendanceType() {
      this.action = "Хичээлийн төрөл үүсгэж байна...";
      this.form
        .post("/manager/attendancetype")
        .then((response) => {
          this.$toastr.s("Ирцийн төрлийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolAttendanceTypes");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Ирцийн төрлийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllSchoolAttendanceTypes();
    Fire.$on("loadSchoolAttendanceTypes", () => {
      this.getAllSchoolAttendanceTypes();
    });
  },
};
</script>

<style></style>
