<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">Хөтөлбөр хариуцсан менежерийн үнэлгээ</h3>
            <!-- Button trigger modal -->
            <b-button @click="createTWE" class="btn btn-primary">Багшийн ажлын үнэлгээ хийх</b-button>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-container class="bv-example-row" fluid>
            <b-row>
              <b-col sm="3">
                <multiselect v-model="school_year_id" select-label="Сонгох" selected-label="Сонгогдсон"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй." track-by="id" label="id"
                  :custom-label="customYearName" placeholder="Хичээлийн жил сонгоно уу?" :options="years"
                  :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear }}-{{
              option.finish_date | dateYear
            }}
                      - хичээлийн жил
                    </strong></template>
                </multiselect>
              </b-col>
              <b-col sm="3">
                <multiselect v-model="filterDepartment" :multiple="true" deselect-label="Can't remove this value"
                  :custom-label="nameDepartment" track-by="department_code" label="department_code"
                  placeholder="Сонголт хийх" :options="filterDepartments" :searchable="true" :allow-empty="true"
                  @select="toggleSelect">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.department_name }}</strong>
                    сонгогдлоо
                  </template>
                </multiselect>
              </b-col>
              <b-col sm="3">
                <multiselect v-model="selectedMonths" :options="monthsRe" :multiple="true" track-by="name" label="name"
                  :searchable="false" :close-on-select="false" :show-labels="false" placeholder="Сар сонгох"
                  :allow-empty="true" deselect-label="Can't remove this value">
                  <template slot="singleLabel" slot-scope="{ option }"><strong> {{ option.month }}</strong><strong>
                      сонгогдлоо</strong></template>
                </multiselect>
              </b-col>
              <b-col sm="2"><b-button variant="success" @click.prevent="getAllData()">Шүүж харах</b-button>
              </b-col>
            </b-row>
          </b-container>
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Овог Нэр</th>
                <th>Имэйл хаяг</th>
                <th>Албан тушаал</th>
                <th>Сар</th>
                <th>Дүнтэй танилцсан</th>
                <th v-b-popover.hover.righttop="'Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85% байна.'
              " title="Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын гүйцэтгэл;">
                  Ш-1
                </th>
                <th v-b-popover.hover.righttop="'Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
              " title="Сургалтын ээлжит үйл ажиллагааны чанар;">
                  Ш-2
                </th>
                <th v-b-popover.hover.righttop="'Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
              " title="Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;">
                  Ш-3
                </th>
                <th v-b-popover.hover.righttop="'СА-наас үүрэг даалгавар болгож өгсөн цахим контент, технологийн карт, арга зүйн боловсруулалт зэргийн гүйцэтгэл ≥60% үзүүлэлттэй байна.'
              " title="Сургалтын хөтөлбөр, технологийг хөгжүүлсэн байдал;">
                  Ш-4
                </th>
                <th v-b-popover.hover.righttop="'1-4-р шалгуур үзүүлэлтүүдийг жинлэх хувиар тооцвол.'
              " title="Жинлэх хувь(1-4);">
                  Ш1-4
                </th>
                <th v-b-popover.hover.righttop="'Тухайн сард ажилласан илүү цаг'" title="Илүү цаг">
                  Ш-5
                </th>
                <th>Үүсгэсэн</th>
                <!-- <th>Зассан</th> -->
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr class="odd" v-for="(teacherEvaluation, index) in teachersEvaluations" :key="teacherEvaluation.id">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">{{
              teacherEvaluation.emp_teacher_last_name[0].toUpperCase()
            }}. {{ teacherEvaluation.emp_teacher_name }}</span>
                  </h6>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <h6>
                    <span class="badge badge-success" size="1">
                      {{ teacherEvaluation.user_email }}
                    </span>
                  </h6>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ teacherEvaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.ajliin_sar }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- @click="readEvaluation(teacherEvaluation)" -->
                  <a v-show="teacherEvaluation.readTeach === null" href="#" class="badge bg-warning"
                    v-b-popover.hover.top="'Багш сарын үнэлгээтэй танилцаагүй!'" title="Танилцаагүй"><i
                      class="fas fa-check-circle"></i></a>
                  <span class="badge bg-success" v-show="teacherEvaluation.readTeach === 1"
                    v-b-popover.hover.top="'Багш сарын үнэлгээтэй танилцсан!'" title="Танилцсан">
                    <i class="fas fa-check-double"></i>
                  </span>
                  <a v-show="teacherEvaluation.confirmed === null" v-b-popover.hover.top="'Багш сарын үнэлгээтэй танилцсан бол баталгаажуулах!'
              " title="Баталгаажуулах" href="#" class="badge bg-warning" @click="confirmedTWE(teacherEvaluation)">
                    <i class="fas fa-piggy-bank"></i>
                  </a>
                  <a v-show="teacherEvaluation.confirmed === 1" v-b-popover.hover.top="'Багш сарын үнэлгээг бүрэн баталгаажуулсан!'
              " title="Баталгаажсан" href="#" class="badge bg-success">
                    <i class="fas fa-piggy-bank"></i>
                  </a>
                  <a v-if="userInfo.name == 'cipalprin'" v-b-popover.hover.top="'Багш сарын үнэлгээний баталгаажилтыг цуцлах!'
              " title="Цуцлах" href="#" class="badge bg-danger" @click="reConfirmTWE(teacherEvaluation)">
                    <i class="fas fa-cogs"></i>
                  </a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary">
                    {{ teacherEvaluation.shal1 }} *
                    {{ teacherEvaluation.shal1_jin_huvi }} =
                    {{
              calculatePercentPerValue(
                teacherEvaluation.shal1,
                teacherEvaluation.shal1_jin_huvi
              )
            }}
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary">
                    {{ teacherEvaluation.shal2 }} *
                    {{ teacherEvaluation.shal2_jin_huvi }} =
                    {{
              calculatePercentPerValue(
                teacherEvaluation.shal2,
                teacherEvaluation.shal2_jin_huvi
              )
            }}</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary">
                    {{ teacherEvaluation.shal3 }} *
                    {{ teacherEvaluation.shal3_jin_huvi }} =
                    {{
              calculatePercentPerValue(
                teacherEvaluation.shal3,
                teacherEvaluation.shal3_jin_huvi
              )
            }}</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary">
                    {{ teacherEvaluation.shal4 }} *
                    {{ teacherEvaluation.shal4_jin_huvi }} =
                    {{
              calculatePercentPerValue(
                teacherEvaluation.shal4,
                teacherEvaluation.shal4_jin_huvi
              )
            }}</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge badge-primary">
                    {{
                calculatePercent1_4Percent(
                  teacherEvaluation.shal1,
                  teacherEvaluation.shal1_jin_huvi,
                  teacherEvaluation.shal2,
                  teacherEvaluation.shal2_jin_huvi,
                  teacherEvaluation.shal3,
                  teacherEvaluation.shal3_jin_huvi,
                  teacherEvaluation.shal4,
                  teacherEvaluation.shal4_jin_huvi
                )
              }}
                    %
                  </span>
                  <span class="badge badge-success">
                    {{
                calculatePercent1_4(
                  teacherEvaluation.shal1,
                  teacherEvaluation.shal1_jin_huvi,
                  teacherEvaluation.shal2,
                  teacherEvaluation.shal2_jin_huvi,
                  teacherEvaluation.shal3,
                  teacherEvaluation.shal3_jin_huvi,
                  teacherEvaluation.shal4,
                  teacherEvaluation.shal4_jin_huvi
                )
              }}
                    % / 20 %
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-show="teacherEvaluation.shal7 === null"> 0 ц</span>
                  <span v-show="teacherEvaluation.shal7 !== null">
                    {{ teacherEvaluation.shal7 }} ц</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.created_at | dateYearMonthDay }}
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0">
                  {{ teacherEvaluation.updated_at | dateYearMonthDay }}
                </td> -->
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(teacherEvaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <a href="#" class="badge bg-warning" v-show="teacherEvaluation.readTeach === null &&
              teacherEvaluation.confirmed === null
              " @click="editTeacherEvaluation(teacherEvaluation)"><i class="fas fa-edit"></i></a>
                  <a href="#" class="badge bg-info" @click="viewTeacherEvaluation(teacherEvaluation)"><b-icon
                      icon="eye"></b-icon></a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td class="dtr-control sorting_1" tabindex="0" colspan="6" align="right">
                  Дундаж (0 утгыг тооцсон)
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ allShal1sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ allShal2sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ allShal3sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ allShal4sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- {{ allShal1_4sAverage }} -->
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td class="dtr-control sorting_1" tabindex="0" colspan="6" align="right">
                  Дундаж утга (0 утгыг тооцохгүй)
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ all0Shal1sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ all0Shal2sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ all0Shal3sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ all0Shal4sAverage }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal id="modal-prevent-closing" ref="modal" title="Submit Your Name" size="xl" @show="resetModal"
      @hidden="cancelEdit" @ok="handleOk" hide-footer>
      <template v-slot:modal-title>
        <span v-show="!editMode"> Багшийн ажлын үнэлгээ бүртгэх </span>
        <span v-show="editMode"> Багшийн ажлын үнэлгээ засах </span>
      </template>
      <template>
        <form @submit.prevent="
              !editMode ? createTeacherEvaluation() : updateTeacherEvaluation()
              ">
          <table cellspacing="0" border="0">
            <colgroup width="272"></colgroup>
            <colgroup width="146"></colgroup>
            <colgroup width="167"></colgroup>
            <colgroup width="345"></colgroup>
            <colgroup width="113"></colgroup>
            <colgroup width="91"></colgroup>
            <colgroup width="107"></colgroup>
            <colgroup width="233"></colgroup>
            <colgroup width="198"></colgroup>
            <tr>
              <td colspan="9">
                БАТЛАВ: Сургалт хариуцсан
                захирал___________________/................/
              </td>
              <td height="19" align="left" valign="bottom">
                <font face="Tahoma"> </font>
              </td>
              <td align="left" valign="asdfbotasdftom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- KPI -->
            <tr>
              <td colspan="7" height="26" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000">Багшийн ажлын гүйцэтгэлийг дүгнэх хуудас (KPI)
                  </font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авах -->
            <tr>
              <td height="26" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
            </tr>
            <!-- Овог нэр -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Овог нэр: </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <!-- {{ editMode }} -->
                  <multiselect v-model="form.employee_id" deselect-label="Can't remove this value"
                    :custom-label="nameTeacher" track-by="emp_user_id" label="emp_user_id" placeholder="Select one"
                    :options="allTeachers" :searchable="true" :allow-empty="false" @select="toggleSelect">
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{
              option.emp_teacher_last_name[0].toUpperCase() }}.{{
              option.emp_teacher_name
            }}</strong>
                      багш сонгогдлоо
                    </template>
                  </multiselect>
                </font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Албан тушаал -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Албан тушаал:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  {{ form.employee_id.position_name }}
                </font>
                <b v-if="editMode"> {{ form.employee_id.position_name }}</b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Үнэлгээ хийсэн хугацаа:</font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000">{{
              form.timestamp
            }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Ажлын сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Үнэлгээ хийх сар сонгох:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000" v-if="!editMode">
                  <multiselect v-model="form.ajliin_sar" deselect-label="Can't remove this value" :options="months"
                    :searchable="false" :close-on-select="true" :show-labels="true" placeholder="Ажлын сар сонгоно уу?">
                  </multiselect>
                </font>
                <font v-if="editMode">{{ form.ajliin_sar }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Багш нарын баг ахалсан эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <b-form-checkbox id="checkbox-bnb" v-model="form.bnbMode" name="checkbox-bnb">
                  Мэргэжлийн баг ахалсан эсэх:
                </b-form-checkbox>
              </td>
              <td align="left" valign="middle" colspan="3" v-if="form.bnbMode">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input v-model="form.shal8" placeholder="Мэргэжлийн баг ахалсан ажлын үнэлгээ" type="number"
                    :state="validationNumber8"></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle" v-if="form.bnbMode">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4" v-if="form.bnbMode">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal8_temd" placeholder="Багш нарын баг ахалсан ажлын үнэлгээний тайлбар" rows="2"
                      max-rows="4"></b-form-textarea>
                  </font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Мэргэжлийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Мэргэжлийн зэрэгтэй эсэх:
                </font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-select v-model="form.shal9" :options="options" size="sm" class="mt-3"></b-form-select>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal9_temd" placeholder="Мэргэжлийн зэргийн тайлбар" rows="2"
                      max-rows="4"></b-form-textarea></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Эрдмийн зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Эрдмийн зэрэгтэй эсэх :</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox id="checkbox-erdzer" v-model="form.shal10" name="checkbox-erdzer" value="1"
                    unchecked-value="0">
                    Эрдмийн зэрэгтэй эсэх(Доктор)
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal10_temd" placeholder="Эрдмийн зэргийн тайлбар" rows="2"
                      max-rows="4"></b-form-textarea></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Спортын зэрэгтэй эсэх -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Спортын зэрэгтэй эсэх:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-checkbox id="checkbox-sport-mas" v-model="form.shal17" name="checkbox-sport-mas" value="1"
                    unchecked-value="0">
                    Спортын мастер эсэх
                  </b-form-checkbox>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal17_temd" placeholder="Спортын мастерын зэргийн тайлбар" rows="2"
                      max-rows="4"></b-form-textarea></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Ажилласан цаг -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Ажилласан цаг:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  {{ form.shal12 }} / {{ form.shal13 }} цаг
                  <!-- <b-form-input
                    v-model="form.shal12"
                    placeholder="Тухайн сард ажиласан цаг. 168-176 цаг байна."
                    type="number"
                    :state="validationNumber12"
                  ></b-form-input> -->
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal12_temd" placeholder="Тухайн сард ажилласан цагийн тайлбар" rows="2"
                      max-rows="4"></b-form-textarea></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Илүү цаг сар -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" color="#000000">Тухайн сард ажилласан илүү цаг:</font>
              </td>
              <td align="left" valign="middle" colspan="3">
                <font face="Tahoma" size="3" color="#000000">
                  <b-form-input v-model="form.shal7" placeholder="Тухайн сард ажилласан илүү цаг" type="number"
                    :state="validationNumber7"></b-form-input>
                </font>
                <!-- <font v-if="editMode">{{ form.shal5 }}</font> -->
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle" colspan="4">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /><b-form-textarea id="textarea"
                      v-model="form.shal7_temd" placeholder="Тухайн сард илүү цаг ажилласан эсэхийг тайлбарлана."
                      rows="2" max-rows="4"></b-form-textarea>
                  </font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- зай авсан -->
            <tr>
              <td height="24" align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="4" color="#000000"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3" color="#000000"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
              <td align="left" valign="middle">
                <font face="Tahoma" size="3"><br /></font>
              </td>
            </tr>
            <!-- Хүснэгтийн эхлэх -->
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " rowspan="2" height="38" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Гүйцэтгэлийн шалгуур үзүүлэлтүүд</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " rowspan="2" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Хэмжих нэгж</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " rowspan="2" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Зорилтот үр дүн / Хэмжүүр</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " rowspan="2" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Эх сурвалж</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " colspan="3" rowspan="2" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2" color="#000000">Үнэлгээ</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" rowspan="2" valign="middle" bgcolor="#D9D9D9">
                <b>
                  <font face="Tahoma" color="#000000" size="1">Тайлбар<br /></font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" rowspan="2" valign="middle" bgcolor="#D9D9D9">
                <b>
                  <font face="Tahoma" color="#000000" size="1">Шалтгаан<br /></font>
                </b>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td style="
                  border-top: 0.1px solid #000000;
                  border-bottom: 0.1px solid #000000;
                  border-left: 0.1px solid #000000;
                  border-right: 0.1px solid #000000;
                " height="63" align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Ажлын гүйцэтгэлийг илэрхийлэх гол үзүүлэлтүүд
                  </font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Үндсэн цалингийн хувь </font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Тухайн үзүүлэлтийн хүрээнд тавигдаж буй зорилт
                  </font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Үнэлгээ хийх эрх зүйн баримт бичиг
                  </font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Эзлэх жин <br />(Ач холбогдол)</font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                  padding: 50px;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Биелэлт</font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <i>
                  <font face="Tahoma" size="1">Тооцох хувь</font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <i>
                  <font face="Tahoma" size="1">Үнэлгээ хийсэн аргачлал
                  </font>
                </i>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <i>
                  <font face="Tahoma" size="1">Биелэлт дутуу байгаа шалтгаан</font>
                </i>
              </td>
            </tr>
            <!-- Сургалтын албаны менежер -->
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " height="34" align="left" valign="middle">
                <font face="Tahoma" size="1">Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын
                  гүйцэтгэл;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " rowspan="4" align="center" valign="middle">
                <font face="Tahoma" size="1">Үндсэн цалингийн хувиар тооцно. Сарын 20%, улирлын 60%.
                  60%-ийг 100% гэж үзээд зорилтот үр дүнгийн үнэлгээ хэдэн хувь
                  байна вэ гэдгээр тооцно.
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <font face="Tahoma" size="1">1. Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь
                  хамрагдсан байна. 2. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд
                  хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна.
                  3. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85%
                  байна. <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma" size="1">Багшийн ажлын үнэлгээний журам; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.2" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma">{{ shal1_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="white" sdval="0.95" sdnum="1033;0;0%">
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal1" type="number" id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?" :state="validationNumber1" min="0" max="100"></b-form-input>
                  </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.19" sdnum="1033;0;0%">
                <!-- <font face="Tahoma">Тооцоолж гаргах%</font> -->
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal1_jin_huvi" placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg" min="0" max="1" step="0.1"></b-form-input> </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">1. Хичээл заалгадаг нийт хүүхдүүдийн 10% хүрэхгүй хэсэг нь
                  хамрагдсан бол 0%, 10-20% нь хамрагдсан бол 80%, 21-30% нь
                  хамрагдсан бол 90%, 31-40% нь хамрагдсан бол 95%, 40%-иас дээш
                  хувь нь хамрагдсан бол 100% гэж тус тус үнэлнэ. <br />
                  2. Хамрагдвал зохих нийт хүүхдүүдийн 80%-иас бага хүүхэд
                  тухайн соёл хүмүүжлийн арга хэмжээнд хамрагдсан бол 0%, 80% ба
                  түүнээс дээш хувь нь хамрагдсан бол гүйцэтгэлийн хувиар тус
                  тус тооцож үнэлнэ.<br />
                  3. СА-наас нийт хэдэн үүрэг даалгавар өгснөөс хэд нь биелснийг
                  хувилж тооцно. <br />
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">
                  <b-form-textarea id="textarea" v-model="form.shal1_temd" placeholder="Enter something..." rows="3"
                    max-rows="6">{{ form.shal1_temd }}</b-form-textarea>
                </font>
              </td>
            </tr>
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " height="34" align="left" valign="middle">
                <font face="Tahoma" size="1">Сургалтын ээлжит үйл ажиллагааны чанар;
                </font>
              </td>

              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <font face="Tahoma" size="1">Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байгаа эсэх; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma" size="1">Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.2" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma">{{ shal2_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.8" sdnum="1033;0;0%">
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal2" type="number" id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?" :state="validationNumber2" min="0" max="100"></b-form-input>
                  </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.16" sdnum="1033;0;0%">
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal2_jin_huvi" placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg" min="0" max="1" step="0.1"></b-form-input> </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ 36% хүрэхгүй
                  бол 0%, 36% ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус
                  тооцно;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma"><b-form-textarea id="textarea" v-model="form.shal2_temd"
                    placeholder="Enter something..." rows="3" max-rows="6">{{ form.shal2_temd }}</b-form-textarea>
                </font>
              </td>
            </tr>
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " height="34" align="left" valign="middle">
                <font face="Tahoma" size="1">Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <font face="Tahoma" size="1">Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн
                  явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60%
                  үзүүлэлттэй байна; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma" size="1">Багшийн ажлыг үнэлэх, цалин хөлс, урамшуулал тооцож олгох
                  журам; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.4" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma">{{ shal3_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal3" type="number" id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?" :state="validationNumber3" min="0" max="100"></b-form-input>
                  </b-input-group>
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.3492" sdnum="1033;0;0%">
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal3_jin_huvi" placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg" min="0" max="1" step="0.1"></b-form-input> </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">Хөндлөнгийн шалгалтын дундаж үнэлгээ 30%-хүрэхгүй бол 0%, 30%
                  ба түүнээс дээш бол гүйцэтгэлийн хувиар тус тус тооцно;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma"><b-form-textarea id="textarea" v-model="form.shal3_temd"
                    placeholder="Enter something..." rows="3" max-rows="6">{{ form.shal3_temd }}</b-form-textarea>
                </font>
              </td>
            </tr>
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " height="34" align="left" valign="middle">
                <font face="Tahoma" size="1">Сургалтын хөтөлбөр, технологийг хөгжүүлсэн байдал;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <font face="Tahoma" size="1">СА-наас үүрэг даалгавар болгож өгсөн цахим контент,
                  технологийн карт, арга зүйн боловсруулалт зэргийн гүйцэтгэл
                  ≥60% үзүүлэлттэй байна; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma" size="1">Цахим систем; <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.2" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma">{{ shal4_jin_huviCalculate }}%</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="1" sdnum="1033;0;0%">
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal4" type="number" id="inline-form-input-username"
                      placeholder="Тоон утга оруулна уу?" :state="validationNumber4" min="0" max="100"></b-form-input>
                  </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.2" sdnum="1033;0;0%">
                <!-- <font face="Tahoma">Тооцоолж тавих%</font> -->
                <font face="Tahoma"><b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="form.shal4_jin_huvi" placeholder="Тоон утга оруулна уу?"
                      :state="validation1_0oosNeg" min="0" max="1" step="0.1"></b-form-input> </b-input-group></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">Дундаж гүйцэтгэл 60% хүрэхгүй бол 0%, 60% ба түүнээс дээш бол
                  гүйцэтгэлийн хувиар тус тус тооцно;
                </font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma"><b-form-textarea id="textarea" v-model="form.shal4_temd"
                    placeholder="Enter something..." rows="3" max-rows="6">{{ form.shal3_temd }}</b-form-textarea>
                </font>
              </td>
            </tr>
            <!-- Хөлний тооцоолол -->
            <tr>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " height="17" align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="1.2">Нийт</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle">
                <b>
                  <font face="Tahoma">20%<br /></font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="1" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma">
                    {{ average() }}
                    %</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"> {{ calculatePercent() }}% <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="center" valign="middle" sdval="0.8992" sdnum="1033;0;0%">
                <b>
                  <font face="Tahoma" size="1.2">10% = 0.1 <br />
                    20% = 0.2 гэх мэт. <br />
                    4 үзүүлэлт нийлээд 100% = 1 байхыг анхаарна уу?</font>
                </b>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma" size="1">Суралцагчдын амралтын хугацааны үр дүнгийн урамшууллыг хасаж
                  тооцно. <br /></font>
              </td>
              <td style="
                  border-top: 1px solid #000000;
                  border-bottom: 1px solid #000000;
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                " align="left" valign="middle" bgcolor="#D9D9D9">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b>
                  <font face="Tahoma"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdval="0.40464" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="17" align="center" valign="middle">
                <b>
                  <font face="Tahoma"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
              <td align="center" valign="middle" sdnum="1033;0;0%">
                <font face="Tahoma" color="#808080"><br /></font>
              </td>
            </tr>
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Ажилтан:</font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma" v-if="form.employee_id">{{ form.employee_id.emp_teacher_last_name[0] }}.{{
              form.employee_id.emp_teacher_name
            }}</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Шууд удирдлага -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Шууд удирдлага:</font>
                </b>
              </td>
              <td align="center" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma">/................/</font>
              </td>
              <td align="center" valign="middle">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Зай авсан -->
            <tr>
              <td height="20" align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
            <!-- Огноо -->
            <tr>
              <td height="20" align="right" valign="middle">
                <b>
                  <font face="Tahoma" size="3">Огноо:</font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="center" valign="middle" sdval="44164" sdnum="1033;0;M/D/YYYY">
                <font face="Tahoma">{{ form.timestamp }}</font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="middle">
                <b>
                  <font face="Tahoma" size="3"><br /></font>
                </b>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
              <td align="left" valign="bottom">
                <font face="Tahoma"><br /></font>
              </td>
            </tr>
          </table>
          <b-button class="mt-3" variant="outline-danger" block @click="cancelEdit">Цуцлах</b-button>
          <b-button type="submit" class="mt-2" variant="outline-warning" block>Хадгалах</b-button>
        </form>
      </template>
    </b-modal>

    <b-modal id="modal-prevent-closing-view" ref="modal" title="Submit Your Name" size="xl" @hidden="cancelView"
      @ok="handleOk" hide-footer>
      <template v-slot:modal-title>
        <span>
          {{ viewEvaluation.ajliin_sar }}-р сарын багшийн ажлын үнэлгээ
        </span>
      </template>
      <template>
        <div class="card">
          <!-- <div class="card-header">
            <h3 class="card-title">Condensed Full Width Table</h3>
          </div> -->
          <div class="card-body p-0">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 10px">#</th>
                  <th>Мэдээлэл</th>
                  <th>Үнэлгээ</th>
                  <th>Тооцоолол</th>
                  <th>Тайлбар</th>
                </tr>
              </thead>
              <tbody v-if="viewEvaluation">
                <tr>
                  <td>1.</td>
                  <td>Мэргэжлийн баг</td>
                  <td>
                    {{ viewEvaluation.department_code }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Ажлын байр</td>
                  <td>
                    {{ viewEvaluation.position_name }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>Овог нэр</td>
                  <td>
                    {{ viewEvaluation.emp_teacher_last_name }}.
                    {{ viewEvaluation.emp_teacher_name }}
                  </td>
                  <!-- <span class="badge bg-primary">30%</span> -->
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>Ажиллах нийт цаг</td>
                  <td>{{ shal13Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal13Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal13_temd }}</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>Ажилласан цаг</td>
                  <td>{{ viewEvaluation.shal12 }}</td>
                  <td>
                    <span class="badge bg-success">{{ viewGuitsetgelShal12() }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal12_temd }}</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>Илүү цаг (1.5)</td>
                  <td>{{ shal14Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal14Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal14_temd }}</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>Илүү цаг (Хичээлийн нормоос хэтэрсэн)</td>
                  <td>{{ shal7Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal7Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal7_temd }}</td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>Цалинтай чөлөө</td>
                  <td>{{ shal15Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal15Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal15_temd }}</td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>Цалингүй чөлөө</td>
                  <td>{{ shal16Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal16Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal16_temd }}</td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td>Тасалсан</td>
                  <td>{{ shal18Check() }}</td>
                  <td>
                    <span class="badge bg-success">{{ shal18Check() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal18_temd }}</td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>Үр дүнгийн үнэлгээ (Шалгуур 1)</td>
                  <td>{{ viewEvaluation.shal1 }}</td>
                  <td>
                    <span class="badge bg-success">{{ viewEvaluation.shal1 * 0.2 }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal1_temd }}</td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td>Үр дүнгийн үнэлгээ (Шалгуур 2)</td>
                  <td>{{ viewEvaluation.shal2 }}</td>
                  <td>
                    <span class="badge bg-success">{{ viewEvaluation.shal2 * 0.3 }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal2_temd }}</td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td>Үр дүнгийн үнэлгээ (Шалгуур 3)</td>
                  <td>{{ viewEvaluation.shal3 }}</td>
                  <td>
                    <span class="badge bg-success">{{ viewEvaluation.shal3 * 0.3 }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal3_temd }}</td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td>Үр дүнгийн үнэлгээ (Шалгуур 4)</td>
                  <td>{{ viewEvaluation.shal4 }}</td>
                  <td>
                    <span class="badge bg-success">{{ viewEvaluation.shal4 * 0.2 }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal4_temd }}</td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td>Үр дүнгийн үнэлгээ дундаж</td>
                  <td>
                    <span class="badge bg-success">{{ viewAverage() }} %</span>
                  </td>
                  <td>
                    <span class="badge bg-success">{{ viewCalculatePercent() }} %</span>
                  </td>
                  <td>{{ viewEvaluation.shal4_temd }}</td>
                </tr>
                <tr>
                  <td>16.</td>
                  <td>АУБ-ын үнэлгээ (Шалгуур 5)</td>
                  <td>{{ viewAverageShal5() }}</td>
                  <td></td>
                  <td>{{ viewEvaluation.shal5_temd }}</td>
                </tr>
                <tr>
                  <td>17.</td>
                  <td>АУБ-ын үнэлгээ (Шалгуур 6)</td>
                  <td>{{ viewAverageShal6() }}</td>
                  <td></td>
                  <td>{{ viewEvaluation.shal6_temd }}</td>
                </tr>
                <tr>
                  <td>18.</td>
                  <td>АУБ-ын үнэлгээний дундаж</td>
                  <td>
                    <span class="badge bg-success">{{
              viewAverageShal56()
            }}</span>
                  </td>
                  <td>
                    <span class="badge bg-success">{{
                viewAverageShal56()
              }}</span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>19.</td>
                  <td>БНБ-ын ахалсан</td>
                  <td>{{ viewAverageShal8() }}</td>
                  <td>
                    <span class="badge bg-success">{{
              viewAverageShal8()
            }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal8_temd }}</td>
                </tr>
                <tr>
                  <td>20.</td>
                  <td>Мэргэжлийн зэрэг</td>
                  <td>{{ myMerZerCheckName() }}</td>
                  <td>
                    <span class="badge bg-success">{{ myMerZerCheck() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal9_temd }}</td>
                </tr>
                <tr>
                  <td>21.</td>
                  <td>Эрдмийн зэрэг</td>
                  <td>{{ myErdZerCheckName() }}</td>
                  <td>
                    <span class="badge bg-success">{{ myErdZerCheck() }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal10_temd }}</td>
                </tr>
                <tr>
                  <td>22.</td>
                  <td>Спортын зэрэг</td>
                  <td>{{ mySportZerCheckName() }}</td>
                  <td>
                    <span class="badge bg-success">{{
                      mySportZerCheck()
                      }}</span>
                  </td>
                  <td>{{ viewEvaluation.shal17_temd }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      school_year_id: null,
      // years: [],
      action: "",
      editMode: false,
      //   keyword: "",
      teachersEvaluations: [],
      allTeachers: [],
      //   positions: [],
      namePositionNameCheck: "",
      form: new Form({
        id: "",
        employee_id: "",
        bnbMode: false,
        ajliin_sar: "",
        shal1: "",
        shal1_jin_huvi: 0.2,
        shal1_temd: "",
        shal2: "",
        shal2_jin_huvi: 0.3,
        shal2_temd: "",
        shal3: "",
        shal3_jin_huvi: 0.3,
        shal3_temd: "",
        shal4: "",
        shal4_jin_huvi: 0.2,
        shal4_temd: "",
        shal7: "",
        shal7_temd: "",
        shal8: "",
        shal8_temd: "",
        shal9: null,
        shal9_temd: "",
        shal10: "",
        shal10_temd: "",
        shal11: "",
        shal11_temd: "",
        shal12: "",
        shal12_temd: "",
        shal13: "",
        shal13_temd: "",
        shal14: "",
        shal14_temd: "",
        shal15: "",
        shal15_temd: "",
        shal16: "",
        shal16_temd: "",
        shal17: "",
        shal17_temd: "",
        shal18: "",
        shal18_temd: "",
        temdeglel: "",
        timestamp: "",
      }),
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      options: [
        { value: null, text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: 0, text: "Зэрэггүй" },
        { value: 1, text: "Заах аргач" },
        { value: 2, text: "Тэргүүлэх" },
        { value: 3, text: "Зөвлөх" },
        { value: 4, text: "Ахлах нийгмийн ажилтан" },
      ],
      filterDepartments: [],
      filterDepartment: null,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      allShal1s: [],
      allShal1sum: "",
      allShal1sAverage: "",
      allShal2s: [],
      allShal2sum: "",
      allShal2sAverage: "",
      allShal3s: [],
      allShal3sum: "",
      allShal3sAverage: "",
      allShal4s: [],
      allShal4sum: "",
      allShal4sAverage: "",
      allShal1_4sAverage: "",

      //together 0
      all0Shal1s: [],
      all0Shal1sum: "",
      all0Shal1sAverage: "",
      all0Shal2s: [],
      all0Shal2sum: "",
      all0Shal2sAverage: "",
      all0Shal3s: [],
      all0Shal3sum: "",
      all0Shal3sAverage: "",
      all0Shal4s: [],
      all0Shal4sum: "",
      all0Shal4sAverage: "",

      viewEvaluation: {
        id: "",
        employee_id: "",
        bnbMode: false,
        ajliin_sar: "",
        shal1: "",
        shal1_temd: "",
        shal2: "",
        shal2_temd: "",
        shal3: "",
        shal3_temd: "",
        shal4: "",
        shal4_temd: "",
        shal7: "",
        shal7_temd: "",
        shal8: "",
        shal8_temd: "",
        shal9: null,
        shal9_temd: "",
        shal10: "",
        shal10_temd: "",
        shal11: "",
        shal11_temd: "",
        shal12: "",
        shal12_temd: "",
        shal13: "",
        shal13_temd: "",
        shal14: "",
        shal14_temd: "",
        shal15: "",
        shal15_temd: "",
        shal16: "",
        shal16_temd: "",
        shal17: "",
        shal17_temd: "",
        shal18: "",
        shal18_temd: "",
        temdeglel: "",
        timestamp: "",
      },
    };
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  watch: {
    school_year_id: function (newVal, oldVal) {
      this.getAllData();
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },

    years: function () {
      return this.$store.state.years;
    },

    shal1_jin_huviCalculate() {
      return this.form.shal1 * this.form.shal1_jin_huvi;
    },
    shal2_jin_huviCalculate() {
      return this.form.shal2 * this.form.shal2_jin_huvi;
    },
    shal3_jin_huviCalculate() {
      return this.form.shal3 * this.form.shal3_jin_huvi;
    },
    shal4_jin_huviCalculate() {
      return this.form.shal4 * this.form.shal4_jin_huvi;
    },
    validation1_0oosNeg() {
      return this.form.shal1_jin_huvi >= 0 && this.form.shal1_jin_huvi <= 1;
    },
    validation2_0oosNeg() {
      return this.form.shal2_jin_huvi >= 0 && this.form.shal2_jin_huvi <= 1;
    },
    validation3_0oosNeg() {
      return this.form.shal3_jin_huvi >= 0 && this.form.shal3_jin_huvi <= 1;
    },
    validation4_0oosNeg() {
      return this.form.shal4_jin_huvi >= 0 && this.form.shal4_jin_huvi <= 1;
    },
    validationNumber1() {
      return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    },
    validationNumber2() {
      return this.form.shal2 >= 0 && this.form.shal2 <= 100;
    },
    validationNumber3() {
      return this.form.shal3 >= 0 && this.form.shal3 <= 100;
    },
    validationNumber4() {
      return this.form.shal4 >= 0 && this.form.shal4 <= 100;
    },
    validationNumber7() {
      return this.form.shal7 >= 0 && this.form.shal7 <= 100;
    },
    validationNumber8() {
      return this.form.shal8 >= 0 && this.form.shal8 <= 100;
    },
    validationNumber12() {
      return this.form.shal12 >= 0 && this.form.shal12 <= 180;
    },
  },
  methods: {
    // getYearList() {
    //   axios
    //     .get("/all_worker_functions/getYearList")
    //     .then((response) => {
    //       this.years = response.data.years;
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    getAllData() {
      axios
        .post("/all_worker_functions/getAllTeachersEvaluations", {
          yearId: this.school_year_id != null ? this.school_year_id.id : this.school_year_id,
          filterDepartment: this.filterDepartment,
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          //   console.log(response.data);
          let sortTeachers = response.data.allTeachersEvaluations;
          sortTeachers.sort(
            (a, b) =>
              a.department_name - b.department_name ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.teachersEvaluations = sortTeachers;
          this.averageCheck(this.teachersEvaluations);

          this.allTeachers = response.data.teachers;
          //Бүх менежерүүдийг авах
          this.filterDepartments = response.data.managerDepartments;
          //Бүх мэргэжлийн багуудыг авах
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    averageCheck(allEvaluations) {
      this.allShal1s = [];
      this.allShal1sum = "";
      this.allShal1sAverage = "";
      this.allShal2s = [];
      this.allShal2sum = "";
      this.allShal2sAverage = "";
      this.allShal3s = [];
      this.allShal3sum = "";
      this.allShal3sAverage = "";
      this.allShal4s = [];
      this.allShal4sum = "";
      this.allShal4sAverage = "";

      //without 0
      this.all0Shal1s = [];
      this.all0Shal1sum = "";
      this.all0Shal1sAverage = "";
      this.all0Shal2s = [];
      this.all0Shal2sum = "";
      this.all0Shal2sAverage = "";
      this.all0Shal3s = [];
      this.all0Shal3sum = "";
      this.all0Shal3sAverage = "";
      this.all0Shal4s = [];
      this.all0Shal4sum = "";
      this.all0Shal4sAverage = "";
      //   console.log(allEvaluations);
      allEvaluations.forEach((element) => {
        this.allShal1s.push(parseInt(element.shal1));
        this.allShal2s.push(parseInt(element.shal2));
        this.allShal3s.push(parseInt(element.shal3));
        this.allShal4s.push(parseInt(element.shal4));
      });

      this.allShal1sum = this.allShal1s.reduce((a, b) => a + b, 0);
      this.allShal1sAverage = (
        this.allShal1sum / this.allShal1s.length
      ).toFixed();
      this.allShal2sum = this.allShal2s.reduce((a, b) => a + b, 0);
      this.allShal2sAverage = (
        this.allShal2sum / this.allShal2s.length
      ).toFixed();
      this.allShal3sum = this.allShal3s.reduce((a, b) => a + b, 0);
      this.allShal3sAverage = (
        this.allShal3sum / this.allShal3s.length
      ).toFixed();
      this.allShal4sum = this.allShal4s.reduce((a, b) => a + b, 0);
      this.allShal4sAverage = (
        this.allShal4sum / this.allShal4s.length
      ).toFixed();

      //together zero
      this.all0Shal1s = this.allShal1s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal1sum = this.all0Shal1s.reduce((a, b) => a + b, 0);
      this.all0Shal1sAverage = (
        this.all0Shal1sum / this.all0Shal1s.length
      ).toFixed();

      this.all0Shal2s = this.allShal2s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal2sum = this.all0Shal2s.reduce((a, b) => a + b, 0);
      this.all0Shal2sAverage = (
        this.all0Shal2sum / this.all0Shal2s.length
      ).toFixed();

      this.all0Shal3s = this.allShal3s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal3sum = this.all0Shal3s.reduce((a, b) => a + b, 0);
      this.all0Shal3sAverage = (
        this.all0Shal3sum / this.all0Shal3s.length
      ).toFixed();

      this.all0Shal4s = this.allShal4s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal4sum = this.all0Shal4s.reduce((a, b) => a + b, 0);
      this.all0Shal4sAverage = (
        this.all0Shal4sum / this.all0Shal4s.length
      ).toFixed();
    },

    editTeacherEvaluation(teacherEvaluation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", teacherEvaluation);

      this.form.reset();
      //   this.form.fill(teacherEvaluation);

      this.$nextTick(() => {
        // console.log("nextTick");
        this.editMode = true;
        teacherEvaluation.emp_teacher_last_name[0].toUpperCase() +
          "." +
          teacherEvaluation.emp_teacher_name;
        this.namePositionNameCheck = teacherEvaluation.position_name;
        this.form.id = teacherEvaluation.id;
        this.form.employee_id = teacherEvaluation;
        this.form.ajliin_sar = teacherEvaluation.ajliin_sar;
        this.form.shal1 = teacherEvaluation.shal1;
        this.form.shal1_temd = teacherEvaluation.shal1_temd;
        this.form.shal2 = teacherEvaluation.shal2;
        this.form.shal2_temd = teacherEvaluation.shal2_temd;
        this.form.shal3 = teacherEvaluation.shal3;
        this.form.shal3_temd = teacherEvaluation.shal3_temd;
        this.form.shal4 = teacherEvaluation.shal4;
        this.form.shal4_temd = teacherEvaluation.shal4_temd;
        this.form.shal7 = teacherEvaluation.shal7;
        this.form.shal7_temd = teacherEvaluation.shal7_temd;
        // this.bnbMode = teacherEvaluation.shal8 ? true : false;
        this.form.bnbMode = teacherEvaluation.shal8 ? true : false;
        this.form.shal8 = teacherEvaluation.shal8;
        this.form.shal8_temd = teacherEvaluation.shal8_temd;
        // console.log(parseInt(teacherEvaluation.shal9));
        if (parseInt(teacherEvaluation.shal9) === 0) {
          this.form.shal9 = 0;
        } else if (parseInt(teacherEvaluation.shal9) === 1) {
          this.form.shal9 = 1;
        } else if (parseInt(teacherEvaluation.shal9) === 2) {
          this.form.shal9 = 2;
        } else if (parseInt(teacherEvaluation.shal9) === 3) {
          this.form.shal9 = 3;
        } else {
          this.form.shal9 = 0;
        }
        // this.form.shal9 = teacherEvaluation.shal9;
        this.form.shal9_temd = teacherEvaluation.shal9_temd;
        this.form.shal10 = parseInt(teacherEvaluation.shal10);
        this.form.shal10_temd = teacherEvaluation.shal10_temd;
        // this.form.shal11 = parseInt(teacherEvaluation.shal11);
        // this.form.shal11_temd = teacherEvaluation.shal11_temd;
        this.form.shal12 = parseInt(teacherEvaluation.shal12);
        this.form.shal12_temd = teacherEvaluation.shal12_temd;
        this.form.shal13 = parseInt(teacherEvaluation.shal13);
        this.form.shal13_temd = teacherEvaluation.shal13_temd;
        this.form.shal17 = parseInt(teacherEvaluation.shal17);
        this.form.shal17_temd = teacherEvaluation.shal17_temd;
        this.$bvModal.show("modal-prevent-closing");
      });
    },
    updateTeacherEvaluation() {
      //   this.action = "Бүртгэлийн ажилтны мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateTeacherEvaluation/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    viewTeacherEvaluation(evaluation) {
      //   console.log(evaluation, this.viewEvaluation);
      this.viewEvaluation = { ...evaluation };
      //   console.log(this.viewEvaluation);
      this.$bvModal.show("modal-prevent-closing-view");
    },
    viewGuitsetgelShal12() {
      if (this.viewEvaluation.shal12 && this.viewEvaluation.shal13) {
        let gu =
          (parseInt(this.viewEvaluation.shal12) * 100) /
          parseInt(this.viewEvaluation.shal13);
        return gu.toFixed(1);
      } else {
        return 0;
      }
    },
    viewCalculatePercent() {
      let averagePer =
        parseFloat(this.viewEvaluation.shal1) * 0.2 +
        parseFloat(this.viewEvaluation.shal2) * 0.3 +
        parseFloat(this.viewEvaluation.shal3) * 0.3 +
        parseFloat(this.viewEvaluation.shal4) * 0.2;
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },
    viewAverage() {
      return (
        parseFloat(this.viewEvaluation.shal1) * 0.2 +
        parseFloat(this.viewEvaluation.shal2) * 0.3 +
        parseFloat(this.viewEvaluation.shal3) * 0.3 +
        parseFloat(this.viewEvaluation.shal4) * 0.2
      );
    },
    viewAverageShal5() {
      if (this.viewEvaluation.shal5) {
        return parseFloat(this.viewEvaluation.shal5).toFixed(1);
      } else {
        return 0;
      }
    },
    viewAverageShal6() {
      if (this.viewEvaluation.shal6) {
        return parseFloat(this.viewEvaluation.shal6).toFixed(1);
      } else {
        return 0;
      }
    },
    viewAverageShal56() {
      if (this.viewEvaluation.shal6 && this.viewEvaluation.shal5) {
        let shal5_6;
        shal5_6 =
          (parseFloat(this.viewEvaluation.shal5) +
            parseFloat(this.viewEvaluation.shal6)) /
          2;
        return shal5_6.toFixed(1);
      } else {
        return 0;
      }
    },
    viewAverageShal8() {
      if (this.viewEvaluation.shal8) {
        return parseFloat(this.viewEvaluation.shal8).toFixed(1);
      } else {
        return 0;
      }
    },
    myMerZerCheckName() {
      if (parseInt(this.viewEvaluation.shal9) == 4) {
        return "Ахлах нийгмийн ажилтан";
      } else if (parseInt(this.viewEvaluation.shal9) == 3) {
        return "Зөвлөх";
      } else if (parseInt(this.viewEvaluation.shal9) == 2) {
        return "Тэргүүлэх";
      } else if (parseInt(this.viewEvaluation.shal9) == 1) {
        return "Заах аргач";
      } else {
        return "Зэрэггүй";
      }
    },
    myMerZerCheck() {
      if (parseInt(this.viewEvaluation.shal9) == 4) {
        return 20;
      } else if (parseInt(this.viewEvaluation.shal9) == 3) {
        return 20;
      } else if (parseInt(this.viewEvaluation.shal9) == 2) {
        return 15;
      } else if (parseInt(this.viewEvaluation.shal9) == 1) {
        return 10;
      } else {
        return 0;
      }
    },
    myErdZerCheckName() {
      if (parseInt(this.viewEvaluation.shal10) == 1) {
        return "Доктор";
      } else {
        return "Зэрэггүй";
      }
    },
    myErdZerCheck() {
      if (parseInt(this.viewEvaluation.shal10) == 1) {
        return 20;
      } else {
        return 0;
      }
    },
    mySportZerCheckName() {
      if (parseInt(this.viewEvaluation.shal17) == 1) {
        return "Спортын мастер";
      } else {
        return "Зэрэггүй";
      }
    },
    mySportZerCheck() {
      if (parseInt(this.viewEvaluation.shal17) == 1) {
        return 20;
      } else {
        return 0;
      }
    },
    shal15Check() {
      if (this.viewEvaluation.shal15) {
        return this.viewEvaluation.shal15.toFixed(1);
      } else {
        return 0;
      }
    },
    shal14Check() {
      if (this.viewEvaluation.shal14) {
        return this.viewEvaluation.shal14.toFixed(1);
      } else {
        return 0;
      }
    },
    shal13Check() {
      if (this.viewEvaluation.shal13) {
        return parseFloat(this.viewEvaluation.shal13).toFixed(1);
      } else {
        return 0;
      }
    },
    shal7Check() {
      if (this.viewEvaluation.shal7) {
        return parseFloat(this.viewEvaluation.shal7).toFixed();
      } else {
        return 0;
      }
    },
    shal16Check() {
      if (this.viewEvaluation.shal16) {
        return parseFloat(this.viewEvaluation.shal16).toFixed();
      } else {
        return 0;
      }
    },
    shal18Check() {
      if (this.viewEvaluation.shal18) {
        return parseFloat(this.viewEvaluation.shal18).toFixed();
      } else {
        return 0;
      }
    },
    cancelView() {
      //   console.log("Хаалаа");
      this.viewEvaluation = {};
      this.$bvModal.hide("modal-prevent-closing-view");
    },

    cancelEdit() {
      //   console.log("Хаалаа");
      this.editMode = false;
      this.form.reset();
      this.namePositionNameCheck = "";
      this.$bvModal.hide("modal-prevent-closing");
    },
    // deleteSchoolEmployee(schoolteacher) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
    createTWE() {
      this.form.reset();
      this.$bvModal.show("modal-prevent-closing");
    },
    createTeacherEvaluation() {
      this.form
        .post("/all_worker_functions/createTeacherEvaluation")
        .then((response) => {
          this.$toastr.s("Багшийн үнэлгээг хийлээ.", "Амжилттай");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch(() => {
          // console.log(errors.all());
          this.$toastr.e(
            "Багшийн үнэлгээг бүртгэж чадсангүй, Нэг багшид сарын үнэлгээг давхцуулж оруулах боломжгүй, Багшийн нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
            "Алдаа гарлаа."
          );
        });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.form.timestamp = dateTime;
    },
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },

    toggleSelect() {
      //   console.log("filter department");
    },
    average() {
      return (
        parseFloat(this.form.shal1) * parseFloat(this.form.shal1_jin_huvi) +
        parseFloat(this.form.shal2) * parseFloat(this.form.shal2_jin_huvi) +
        parseFloat(this.form.shal3) * parseFloat(this.form.shal3_jin_huvi) +
        parseFloat(this.form.shal4) * parseFloat(this.form.shal4_jin_huvi)
      );
    },
    calculatePercent() {
      let averagePer =
        parseFloat(this.form.shal1) * parseFloat(this.form.shal1_jin_huvi) +
        parseFloat(this.form.shal2) * parseFloat(this.form.shal2_jin_huvi) +
        parseFloat(this.form.shal3) * parseFloat(this.form.shal3_jin_huvi) +
        parseFloat(this.form.shal4) * parseFloat(this.form.shal4_jin_huvi);
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },
    calculatePercentPerValue(val, val_jin) {
      let averagePer = parseFloat(val) * parseFloat(val_jin);

      return averagePer.toFixed(1);
    },
    calculatePercent1_4(
      val1,
      val1_jin,
      val2,
      val2_jin,
      val3,
      val3_jin,
      val4,
      val4_jin
    ) {
      let averagePer =
        parseFloat(val1) * parseFloat(val1_jin) +
        parseFloat(val2) * parseFloat(val2_jin) +
        parseFloat(val3) * parseFloat(val3_jin) +
        parseFloat(val4) * parseFloat(val4_jin);
      let myPercent = (averagePer * 20) / 100;
      return myPercent.toFixed(1);
    },
    calculatePercent1_4Percent(
      val1,
      val1_jin,
      val2,
      val2_jin,
      val3,
      val3_jin,
      val4,
      val4_jin
    ) {
      let averagePer =
        parseFloat(val1) * parseFloat(val1_jin) +
        parseFloat(val2) * parseFloat(val2_jin) +
        parseFloat(val3) * parseFloat(val3_jin) +
        parseFloat(val4) * parseFloat(val4_jin);

      return averagePer.toFixed(1);
    },
    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },
    confirmedTWE(teacherEvaluation) {
      //   console.log(teacherEvaluation);
      Swal.fire({
        title: "Багш ажлын үнэлгээтэй танилцсан эсэх?",
        text: "Багш таны үнэлгээтэй танилцсан бол үнэлгээг баталгаажуулна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/all_worker_functions/confirmTWE", {
              id: teacherEvaluation.id,
              confirmed: true,
            })
            .then((response) => {
              this.$toastr.s("Амжилттай баталгаажууллаа.", "Амжилттай");
              Fire.$emit("loadSchoolTeachers");
            })
            .catch(() => {
              this.$toastr.e(
                "Багшийн үнэлгээг бүртгэж чадсангүй, Нэг багшид сарын үнэлгээг давхцуулж оруулах боломжгүй, Багшийн нэр, ажлын сар давхцсан эсэхийг нягтална уу?",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    // Баталгаажилтын цуцлах, засах эрхтэй болгох
    reConfirmTWE(teacherEvaluation) {
      //   console.log(teacherEvaluation);
      Swal.fire({
        title: "Багш ажлын үнэлгээг засах эрхийг нээх",
        text: "Багшид үнэлгээтэй танилцахыг сануулна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/all_worker_functions/reConfirmTWE", {
              id: teacherEvaluation.id,
              confirmed: true,
            })
            .then((response) => {
              this.$toastr.s("Амжилттай цуцаллаа.", "Амжилттай");
              Fire.$emit("loadSchoolTeachers");
            })
            .catch(() => {
              this.$toastr.e(
                "Багшийн нэр, ажлын сар давхцсан эсэхийг нягтална уу? Цуцалж чадсангүй.",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },

    nameDepartment({ department_code }) {
      return `${department_code}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },

  created() {
    // this.getYearList();
    this.getAllData();
    Fire.$on("loadSchoolTeachers", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table {
  min-height: 300px;
}

th,
span {
  font-size: 60%;
}
</style>
