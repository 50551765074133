<template>
    <div class="card">
        <div class="card-header" style="background-color: #ec742545">
            <h5 class="card-title"><b>Сургуулийн шалгалтын тоон статистик</b></h5>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <multiselect v-model="yearId" deselect-label="Хасах" select-label="Сонгох" track-by="id"
                :custom-label="customYearName" placeholder="Хичээлийн жил сонгоно уу" :options="years"
                :searchable="true" :allow-empty="true" @input="getExamStat">
                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear }}-{{
                    option.finish_date | dateYear }}
                        - хичээлийн жил
                    </strong></template>
            </multiselect>
            <b-overlay :show="show" rounded="sm">
                <div v-show="yearId != null">
                    <div class="info-box bg-light">
                        <div class="info-box-content">
                            <div class="col-12 col-sm-12">
                                <span v-if="
                                    seriesAllExamCount.length != 0 &&
                                    optionsAllExamCount.labels.length != 0
                                ">
                                    <strong class="info-box-text mt-4 text-center">
                                        Нийт сургуулийн хэмжээнд авсан шалгалтын тоон үзүүлэлт</strong>
                                    <apexchart type="donut" class="pieChart" height="350" :options="optionsAllExamCount"
                                        :series="seriesAllExamCount"></apexchart>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="info-box bg-light">
                        <div class="info-box-content">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesQuarter1Count.length != 0 &&
                                        optionsQuarter1Count.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            1-р улиралд авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsQuarter1Count" :series="seriesQuarter1Count"></apexchart>
                                    </span>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesQuarter2Count.length != 0 &&
                                        optionsQuarter2Count.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            2-р улиралд авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsQuarter2Count" :series="seriesQuarter2Count"></apexchart>
                                    </span>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesQuarter3Count.length != 0 &&
                                        optionsQuarter3Count.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            3-р улиралд авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsQuarter3Count" :series="seriesQuarter3Count"></apexchart>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <b-overlay v-show="yearId != null" rounded="sm">
                <div class="card-body">
                    <multiselect v-model="selectedQuarter" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        track-by="id" label="name" placeholder="Хичээлийн улирал сонгоно уу?" :options="quarters"
                        :searchable="false" :allow-empty="false" v-if="viewChart">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}
                                - улирал
                            </strong></template>
                    </multiselect>
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <span v-if="
                                seriesExamClassQuarterCountP.length != 0 &&
                                optionsExamClassQuarterCountP.labels.length != 0
                            ">
                                <strong class="info-box-text mt-4 text-center">
                                    Бага ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                <apexchart type="donut" class="pieChart" height="350"
                                    :options="optionsExamClassQuarterCountP" :series="seriesExamClassQuarterCountP">
                                </apexchart>
                            </span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <span v-if="
                                seriesExamClassQuarterCountM.length != 0 &&
                                optionsExamClassQuarterCountM.labels.length != 0
                            ">
                                <strong class="info-box-text mt-4 text-center">
                                    Дунд ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                <apexchart type="donut" class="pieChart" height="350"
                                    :options="optionsExamClassQuarterCountM" :series="seriesExamClassQuarterCountM">
                                </apexchart>
                            </span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <span v-if="
                                seriesExamClassQuarterCountH.length != 0 &&
                                optionsExamClassQuarterCountH.labels.length != 0
                            ">
                                <strong class="info-box-text mt-4 text-center">
                                    Ахлах ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                <apexchart type="donut" class="pieChart" height="350"
                                    :options="optionsExamClassQuarterCountH" :series="seriesExamClassQuarterCountH">
                                </apexchart>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <span v-if="
                            seriesExamClassCount.length != 0 &&
                            optionsExamClassCount.xaxis.categories.length != 0
                        ">
                            <strong class="info-box-text mt-4 text-center">
                                Суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                            <apexchart type="bar" height="340" class="barChart" :options="optionsExamClassCount"
                                :series="seriesExamClassCount"></apexchart>
                        </span>
                    </div>
                </div>
            </b-overlay>
            <b-overlay :show="show" rounded="sm">
                <div v-show="yearId != null">
                    <div class="info-box bg-light">
                        <div class="info-box-content">
                            <div class="col-12 col-sm-12">
                                <span v-if="seriesClassExamCount.length != 0">
                                    <strong class="info-box-text mt-4 text-center">
                                        Нийт сургуулийн хэмжээнд авсан шалгалтын тоон үзүүлэлт</strong>
                                    <apexchart type="donut" class="pieChart" height="350"
                                        :options="optionsClassExamCount" :series="seriesClassExamCount"></apexchart>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="info-box bg-light">
                        <div class="info-box-content">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesExamClassCountP.length != 0 &&
                                        optionsExamClassCountP.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            Бага ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsExamClassCountP" :series="seriesExamClassCountP">
                                        </apexchart>
                                    </span>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesExamClassCountM.length != 0 &&
                                        optionsExamClassCountM.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            Дунд ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsExamClassCountM" :series="seriesExamClassCountM">
                                        </apexchart>
                                    </span>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <span v-if="
                                        seriesExamClassCountH.length != 0 &&
                                        optionsExamClassCountH.labels.length != 0
                                    ">
                                        <strong class="info-box-text mt-4 text-center">
                                            Ахлах ангийн суралцагчдаас авсан шалгалтын тоон үзүүлэлт</strong>
                                        <apexchart type="donut" class="pieChart" height="350"
                                            :options="optionsExamClassCountH" :series="seriesExamClassCountH">
                                        </apexchart>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <span v-if="
                            seriesLessonExamCount.length != 0 &&
                            optionsLessonExamCount.xaxis.categories.length != 0
                        ">
                            <strong class="info-box-text mt-4 text-center">
                                Шалгалтын тоон үзүүлэлт (Хичээлээр)</strong>
                            <apexchart type="bar" height="340" class="barChart" :options="optionsLessonExamCount"
                                :series="seriesLessonExamCount"></apexchart>
                        </span>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            yearId: null,
            quarters: [],
            selectedQuarter: "",
            viewChart: false,
            viewQuarterChart: false,
            seriesExamClassCount: [
                {
                    name: "Явц",
                    data: [],
                },
                {
                    name: "Улирал",
                    data: [],
                },
                {
                    name: "Жил",
                    data: [],
                },
            ],
            optionsExamClassCount: {
                chart: {
                    height: 340,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },
                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#BED1E6",
                                colorTo: "#D8E3F0",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            seriesExamClassQuarterCountP: [],
            optionsExamClassQuarterCountP: {
                labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            seriesExamClassQuarterCountM: [],
            optionsExamClassQuarterCountM: {
                labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            seriesExamClassQuarterCountH: [],
            optionsExamClassQuarterCountH: {
                labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            getAllExamCount: [],
            show: false,
            statTurul_id: "",
            //Сургууль
            seriesAllExamCount: [],
            optionsAllExamCount: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            seriesClassExamCount: [],
            optionsClassExamCount: {
                labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            //Улирлаар
            seriesQuarter1Count: [],
            optionsQuarter1Count: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "1-р Улирал",
                                },
                            },
                        },
                    },
                },
            },
            seriesQuarter2Count: [],
            optionsQuarter2Count: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "2-р Улирал",
                                },
                            },
                        },
                    },
                },
            },
            seriesQuarter3Count: [],
            optionsQuarter3Count: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "3-р Улирал",
                                },
                            },
                        },
                    },
                },
            },
            // Ангиар
            seriesExamClassCountP: [],
            optionsExamClassCountP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Бага анги",
                                },
                            },
                        },
                    },
                },
            },
            seriesExamClassCountM: [],
            optionsExamClassCountM: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Дунд анги",
                                },
                            },
                        },
                    },
                },
            },
            seriesExamClassCountH: [],
            optionsExamClassCountH: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Ахлах анги",
                                },
                            },
                        },
                    },
                },
            },
            seriesLessonExamCount: [
                {
                    name: "Явц",
                    data: [],
                },
                {
                    name: "Улирал",
                    data: [],
                },
                {
                    name: "Жил",
                    data: [],
                },
            ],
            optionsLessonExamCount: {
                chart: {
                    height: 340,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "center", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (data) {
                        return data;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },
                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#BED1E6",
                                colorTo: "#D8E3F0",
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
            optionsLessonExamCount: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: "13px",
                                    fontWeight: 900,
                                },
                            },
                        },
                    },
                },
                xaxis: {
                    categories: [],
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                },
                legend: {
                    position: "right",
                    offsetY: 40,
                },
                fill: {
                    opacity: 1,
                },
            },
        };
    },
    components: {
        Multiselect,
    },
    watch: {
        selectedQuarter: function (newVal, oldVal) {
            this.optionsExamClassQuarterCountP.labels = [];
            this.seriesExamClassQuarterCountP = [];
            this.optionsExamClassQuarterCountM.labels = [];
            this.seriesExamClassQuarterCountM = [];
            this.optionsExamClassQuarterCountH.labels = [];
            this.seriesExamClassQuarterCountH = [];
            this.seriesExamClassCount[0].data = [];
            this.seriesExamClassCount[1].data = [];
            this.seriesExamClassCount[2].data = [];
            this.optionsExamClassCount.xaxis.categories = [];
            this.school_quarter_id = "";
            if (newVal != "") {
                this.viewQuarterChart = false;
                axios
                    .post("/manager/getExamStatQuarter", {
                        year: this.yearId.id,
                        selectedQuarter: this.selectedQuarter.id,
                    })
                    .then((response) => {
                        this.seriesExamClassQuarterCountP = response.data.examClassQuarterCountP;
                        this.optionsExamClassQuarterCountP.labels = response.data.examQuarterNameP;
                        this.seriesExamClassQuarterCountM = response.data.examClassQuarterCountM;
                        this.optionsExamClassQuarterCountM.labels = response.data.examQuarterNameM;
                        this.seriesExamClassQuarterCountH = response.data.examClassQuarterCountH;
                        this.optionsExamClassQuarterCountH.labels = response.data.examQuarterNameH;
                        this.seriesExamClassCount[0].data = response.data.examTypeYavts;
                        this.seriesExamClassCount[1].data = response.data.examTypeUliral;
                        this.seriesExamClassCount[2].data = response.data.examTypeJil;
                        this.optionsExamClassCount.xaxis.categories = response.data.className;
                        this.viewQuarterChart = true;
                    })
                    .catch((errors) => {
                        //console.log(errors);
                    });
            }
        },
    },
    computed: {
        years: function () {
            return this.$store.state.years;
        },
    },
    methods: {
        customYearName({ start_date, finish_date }) {
            return `${start_date} - ${finish_date} - оны хичээлийн жил`;
        },
        getExamStat() {
            this.show = true;
            this.viewChart = false;
            this.seriesLessonExamCount[0].data = [];
            this.seriesLessonExamCount[1].data = [];
            this.seriesLessonExamCount[2].data = [];
            this.optionsLessonExamCount.xaxis.categories = [];
            this.optionsAllExamCount.labels = [];
            this.seriesAllExamCount = [];
            this.optionsExamClassCountP.labels = [];
            this.seriesExamClassCountP = [];
            this.optionsExamClassCountM.labels = [];
            this.seriesExamClassCountM = [];
            this.optionsExamClassCountH.labels = [];
            this.seriesExamClassCountH = [];
            this.optionsQuarter1Count.labels = [];
            this.seriesQuarter1Count = [];
            this.optionsQuarter2Count.labels = [];
            this.seriesQuarter2Count = [];
            this.optionsQuarter3Count.labels = [];
            this.seriesQuarter3Count = [];
            axios
                .get("/manager/getExamStat/" + this.yearId.id)
                .then((response) => {
                    this.getAllExamCount = response.data.getAllExamCount;
                    this.seriesAllExamCount = response.data.examTotal;
                    this.optionsAllExamCount.labels = response.data.examName;
                    this.seriesClassExamCount = response.data.classExamCount;
                    this.seriesExamClassCountP = response.data.examClassCountP;
                    this.optionsExamClassCountP.labels = response.data.examNameP;
                    this.seriesExamClassCountM = response.data.examClassCountM;
                    this.optionsExamClassCountM.labels = response.data.examNameM;
                    this.seriesExamClassCountH = response.data.examClassCountH;
                    this.optionsExamClassCountH.labels = response.data.examNameH;
                    this.seriesQuarter1Count = response.data.quarter1Total;
                    this.optionsQuarter1Count.labels = response.data.quarter1Name;
                    this.seriesQuarter2Count = response.data.quarter2Total;
                    this.optionsQuarter2Count.labels = response.data.quarter2Name;
                    this.seriesQuarter3Count = response.data.quarter3Total;
                    this.optionsQuarter3Count.labels = response.data.quarter3Name;
                    this.seriesLessonExamCount[0].data = response.data.lessonExamCountsYavts;
                    this.seriesLessonExamCount[1].data = response.data.lessonExamCountsUliral;
                    this.seriesLessonExamCount[2].data = response.data.lessonExamCountsJil;
                    this.optionsLessonExamCount.xaxis.categories = response.data.subjectNames;
                    this.quarters = response.data.getMySchoolQuarters;
                    this.viewChart = true;
                    this.show = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    beforeMount() { },
};
</script>
