<template>
  <nav
    v-if="userInfo.user"
    class="main-header navbar navbar-expand navbar-white navbar-light fixed-top position-sticky"
  >
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto" v-if="userInfo.user.roles">
      <li v-for="topMenu in topMenus" :key="topMenu.id" class="nav-item dropdown">
        <router-link :to="topMenu.url" class="nav-link">
          <b-icon
            v-if="topMenu.iconType == 'bootstrap'"
            :icon="topMenu.icon"
            :variant="topMenu.variant"
            v-b-popover.hover="topMenu.description"
          ></b-icon>
          <i
            v-else-if="topMenu.iconType == 'favicon'"
            v-b-popover.hover="topMenu.description"
            :class="[topMenu.icon, 'text-' + topMenu.variant]"
          ></i>
        </router-link>
      </li>
      <!-- <li class="nav-item dropdown" v-if="userInfo.userRoleInfo.name == 'agerman'">
        <router-link to="/all_worker_functions/chat" class="nav-link">
          <b-icon
            icon="chat-dots-fill"
            class="h5 mb-2"
            variant="warning"
            v-b-popover.hover="'Чат!'"
          >
          </b-icon>
        </router-link>
      </li> -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i :class="['far fa-bell text-dark', { bellEffect: notifyCount > 0 }]"></i>
          <span
            v-show="notifyCount > 0"
            class="badge badge-danger navbar-badge badgeEffect"
          >
            <span>{{ notifyCount }}</span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div class="overflow-auto notify">
            <a
              v-for="myNotify in notifications"
              :key="myNotify.id"
              href="#"
              @click="showNotify(myNotify)"
              :class="[
                'dropdown-item',
                myNotify.notification_user_status == 1 ? 'showed-notify' : '',
              ]"
            >
              <div v-if="myNotify.my_manager" class="media">
                <img
                  v-if="myNotify.my_manager.profile_photo_url != null"
                  :src="myNotify.my_manager.profile_photo_url"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{ myNotify.my_manager.teacher_last_name }}
                    {{ myNotify.my_manager.teacher_name }}
                  </h3>
                  <p class="text-sm" v-html="myNotify.notifcation.substring(0, 50)"></p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i>
                    {{ myNotify.created_at | dateYearMonthDay }}
                  </p>
                </div>
              </div>
              <div v-else-if="myNotify.my_user" class="media">
                <img
                  v-if="myNotify.my_user.parent_father_profile_photo_url != null"
                  :src="myNotify.my_user.parent_father_profile_photo_url"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{ myNotify.my_user.parent_father_ovog }}
                    {{ myNotify.my_user.parent_father_name }}
                  </h3>
                  <p class="text-sm" v-html="myNotify.notifcation.substring(0, 50)"></p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i>
                    {{ myNotify.created_at | dateYearMonthDay }}
                  </p>
                </div>
              </div>
              <div v-else-if="myNotify.stud" class="media">
                <img
                  v-if="myNotify.stud.parent_father_profile_photo_url != null"
                  :src="myNotify.stud.parent_father_profile_photo_url"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{ myNotify.stud.parent_father_ovog }}
                    {{ myNotify.stud.parent_father_name }}
                  </h3>
                  <p class="text-sm" v-html="myNotify.notifcation.substring(0, 50)"></p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i>
                    {{ myNotify.created_at | dateYearMonthDay }}
                  </p>
                </div>
              </div>
              <div v-else-if="myNotify.my_stud" class="media">
                <img
                  v-if="myNotify.my_stud.parent_father_profile_photo_url != null"
                  :src="myNotify.my_stud.parent_father_profile_photo_url"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{ myNotify.my_stud.parent_father_ovog }}
                    {{ myNotify.my_stud.parent_father_name }}
                  </h3>
                  <p class="text-sm" v-html="myNotify.notifcation.substring(0, 50)"></p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i>
                    {{ myNotify.created_at | dateYearMonthDay }}
                  </p>
                </div>
              </div>
              <div v-else-if="myNotify.my_stud_parent" class="media">
                <img
                  v-if="myNotify.my_stud_parent.parent_father_profile_photo_url != null"
                  :src="myNotify.my_stud_parent.parent_father_profile_photo_url"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  alt="user-avatar"
                  class="img-size-50 mr-3 img-circle img-bordered-sm"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{ myNotify.my_stud_parent.parent_father_ovog }}
                    {{ myNotify.my_stud_parent.parent_father_name }}
                  </h3>
                  <p class="text-sm" v-html="myNotify.notifcation.substring(0, 50)"></p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i>
                    {{ myNotify.created_at | dateYearMonthDay }}
                  </p>
                </div>
              </div>
              <div class="dropdown-divider"></div>
            </a>
          </div>
          <!-- Message End -->
          <router-link :to="myNotifyLink" class="dropdown-item dropdown-footer">
            Бүх мэдэгдэл
          </router-link>
          <router-link
            v-if="
              userInfo.userRoleInfo.name == 'cipalprin' ||
              userInfo.userRoleInfo.name == 'celorcounce' ||
              userInfo.userRoleInfo.name == 'agerman' ||
              userInfo.userRoleInfo.name == 'agermanvaa' ||
              userInfo.userRoleInfo.name == 'finance' ||
              userInfo.userRoleInfo.name == 'logistpsycho' ||
              userInfo.userRoleInfo.name == 'socialworker' ||
              userInfo.userRoleInfo.name == 'exam_officer' ||
              userInfo.userRoleInfo.name == 'hr'
            "
            to="/manager/managerNotify"
            class="dropdown-item dropdown-footer"
          >
            Мэдэгдэл үүсгэх
          </router-link>
        </div>
      </li>
      <li
        class="nav-item dropdown user-menu"
        v-if="userInfo && userInfo.user && userInfo.userRoleInfo"
      >
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
          <b-avatar
            v-if="userInfo.userRoleInfo.name == 'dentstu' && userInfo.user.profile"
            variant="secondary"
            :src="userInfo.user.profile.student_profile_photo_url"
            size="24"
          ></b-avatar>
          <b-avatar
            v-else-if="
              userInfo.userRoleInfo.name == 'rentpa' && userInfo.user.profile_parent
            "
            variant="secondary"
            :src="userInfo.user.profile_parent.parent_father_profile_photo_url"
            size="24"
          ></b-avatar>
          <b-avatar
            v-else-if="userInfo.user.employee_user"
            variant="secondary"
            :src="userInfo.user.employee_user.profile_photo_url"
            size="24"
          ></b-avatar>
          <span class="d-none d-md-inline">{{ userInfo.user.name }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <!-- User image -->
          <li class="user-header bg-navy">
            <b-avatar
              v-if="userInfo.userRoleInfo.name == 'dentstu' && userInfo.user.profile"
              variant="secondary"
              :src="userInfo.user.profile.student_profile_photo_url"
              size="80"
            ></b-avatar>
            <b-avatar
              v-else-if="
                userInfo.userRoleInfo.name == 'rentpa' && userInfo.user.profile_parent
              "
              variant="secondary"
              :src="userInfo.user.profile_parent.parent_father_profile_photo_url"
              size="80"
            ></b-avatar>
            <b-avatar
              v-else-if="userInfo.user.employee_user"
              variant="secondary"
              :src="userInfo.user.employee_user.profile_photo_url"
              size="80"
            ></b-avatar>
            <p>
              {{ userInfo.user.name }}
              <small>{{ userInfo.user.created_at | dateYearMonthDay }}</small>
            </p>
          </li>
          <li class="user-footer">
            <router-link
              to="/student/profile"
              v-if="userInfo.userRoleInfo.name == 'dentstu'"
              class="btn btn-default btn-flat"
            >
              Профайл
            </router-link>
            <router-link
              to="/parent/profile"
              v-else-if="userInfo.userRoleInfo.name == 'rentpa'"
              class="btn btn-default btn-flat"
            >
              Профайл
            </router-link>
            <router-link
              to="/all_worker_functions/profile"
              v-else
              class="btn btn-default btn-flat"
            >
              Профайл
            </router-link>
            <a class="btn btn-default btn-flat float-right" @click="logout" href="/home"
              >Системээс гарах
            </a>
          </li>
        </ul>
      </li>
      <li v-show="checkDisplay()" class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li v-show="checkDisplay()" class="nav-item">
        <a class="nav-link" @click="logout" href="/home">
          <i class="fa fa-power-off"></i>
        </a>
      </li>
    </ul>
    <!-- /.card-body -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-notify-top"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Мэдэгдэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span v-if="showNotifyData.my_manager" class="direct-chat-name float-left">
              <img
                v-if="showNotifyData.my_manager.profile_photo_url != null"
                :src="showNotifyData.my_manager.profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showNotifyData.my_manager.teacher_last_name }}
              {{ showNotifyData.my_manager.teacher_name }}
            </span>
            <span v-else-if="showNotifyData.my_user" class="direct-chat-name float-left">
              <img
                v-if="showNotifyData.my_user.parent_father_profile_photo_url != null"
                :src="showNotifyData.my_user.parent_father_profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showNotifyData.my_user.parent_father_ovog }}
              {{ showNotifyData.my_user.parent_father_name }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b> {{ showNotifyData.created_at | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showNotifyData.notifcation"></span>
          </div>
        </div>
      </div>
    </b-modal>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      form: new Form({
        csrf: document.head.querySelector('meta[name="csrf-token"]')
          ? document.head.querySelector('meta[name="csrf-token"]').content
          : "",
      }),
      showNotifyData: "",
      headerBgVariant: "light",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
      showMode: false,
      notifications: [],
      myNotifyLink: "",
      userRoleName: "",
      topMenus: [],
      notifyCount: 0,
      editorOption: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
    };
  },
  computed: {
    userInfo: function () {
      return this.$store.state;
    },
  },
  methods: {
    checkDisplay() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 500) return false;
      else return true;
    },
    logout() {
      axios.get("/logout").catch();
    },
    getUserTopMenus() {
      axios
        .get("/getUserTopMenus")
        .then((res) => {
          this.topMenus = res.data.topMenus;
        })
        .catch();
    },
    showNotify(notify) {
      axios
        .get("/all_worker_functions/showNotify/" + notify.id)
        .then((res) => {
          this.showNotifyData = notify;
          this.showMode = !this.showMode;
          this.$bvModal.show("modal-notify-top");
          this.myNotifications();
        })
        .catch();
    },
    myNotifications() {
      axios
        .get("/getRoleName")
        .then((res) => {
          if (res.data.roleName == "rentpa")
            axios
              .get("/all_worker_functions/parentNotifications")
              .then((res) => {
                this.notifications = res.data.notifys;
                this.myNotifyLink = "/parent/parentNotification";
                this.notifyCount = res.data.notifyCount;
              })
              .catch();
          else if (res.data.roleName == "dentstu")
            axios
              .get("/all_worker_functions/studentNotifications")
              .then((res) => {
                this.notifications = res.data.notifys;
                this.myNotifyLink = "/student/studentNotify";
                this.notifyCount = res.data.notifyCount;
              })
              .catch();
          else
            axios
              .get("/all_worker_functions/myNotifications")
              .then((res) => {
                this.notifications = res.data.notifys;
                this.myNotifyLink = "/teacher/teachNotifi";
                this.notifyCount = res.data.notifyCount;
              })
              .catch();
        })
        .catch();
    },
  },
  created() {
    this.myNotifications();
    this.getUserTopMenus();
  },
};
</script>

<style>
.notify {
  max-height: 390px;
}
.showed-notify {
  opacity: 0.5;
}
.badgeEffect {
  box-shadow: 0 0 0 0 rgba(220, 53, 68, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(220, 53, 68, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(220, 53, 68, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(220, 53, 68, 0);
  }
}
.bellEffect {
  animation: bellshake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  transform-origin: top right;
  animation-iteration-count: infinite;
}
@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
