<template>
  <div class="card">
    <div class="card-header" style="background-color: #ec742545">
      <h5 class="card-title"><b>Суралцахуйн үйл ажиллагааны цогц байдал</b></h5>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
    <div class="card-body">
      <multiselect
        v-model="yearId"
        deselect-label="Хасах"
        select-label="Сонгох"
        track-by="id"
        :custom-label="customYearName"
        placeholder="Хичээлийн жил сонгоно уу?"
        :options="years"
        :searchable="true"
        :allow-empty="true"
        @input="getStat4All"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{
              option.finish_date | dateYear
            }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <b-overlay :show="show" rounded="sm">
        <table class="table table-bordered text-center" v-show="yearId != null">
          <tbody>
            <tr role="row">
              <th>Сургалтын үйл ажиллагааны хэлбэр</th>
              <th>Нийт бүлгийн тоо</th>
              <th>Хамрагдвал зохих нийт хүүхдийн тоо</th>
              <th>Хамрагдсан нийт хүүхдийн тоо</th>
              <th>Боловсролын үйлчилгээний хүртээмж</th>
              <th>Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс</th>
            </tr>
            <tr>
              <td>Хичээл</td>
              <td>{{ depClassAll }}</td>
              <td>{{ studentsAll }}</td>
              <td>{{ studCountAll }}</td>
              <td>{{ lessonAccessAll }}%</td>
              <td></td>
            </tr>
            <tr>
              <td>Хөгжүүлэх ажил</td>
              <td>{{ allProject[0] }}</td>
              <td>{{ allProject[1] }}</td>
              <td>{{ allProject[2] }}</td>
              <td>{{ allProject[3].toFixed(2) }}%</td>
              <td></td>
            </tr>
            <tr>
              <td>Соёл хүмүүжлийн ажил</td>
              <td>{{ projectCxa[0] }}</td>
              <td>{{ projectCxa[1] }}</td>
              <td>{{ projectCxa[2] }}</td>
              <td>{{ projectCxa[3].toFixed(2) }}%</td>
            </tr>
            <tr>
              <td>Нийлбэр / Дундаж үзүүлэлт</td>
              <td>
                {{
                  ((depClassAll + allProject[0] + projectCxa[0]) / 3).toFixed(2)
                }}
              </td>
              <td>
                {{
                  ((studentsAll + allProject[1] + projectCxa[1]) / 3).toFixed(2)
                }}
              </td>
              <td>
                {{
                  ((studCountAll + allProject[2] + projectCxa[2]) / 3).toFixed(
                    2
                  )
                }}
              </td>
              <td>{{ yearAvg }}%</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <apexchart
          v-if="!showAll"
          type="radar"
          height="350"
          :options="chartStatAll"
          :series="seriesStatAll"
        ></apexchart>
        <multiselect
          v-show="yearId != null"
          v-model="department"
          deselect-label="Хасах"
          select-label="Сонгох"
          track-by="department_id"
          label="department_name"
          placeholder="Хэлтэс сонгох"
          :options="depOptions"
          :searchable="true"
          :allow-empty="true"
          @input="getStat4"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option.department_name }}
          </template>
        </multiselect>
        <table
          class="table table-bordered text-center"
          v-show="department != null"
        >
          <tbody>
            <tr role="row">
              <th>Сургалтын үйл ажиллагааны хэлбэр</th>
              <th>Нийт бүлгийн тоо</th>
              <th>Хамрагдвал зохих нийт хүүхдийн тоо</th>
              <th>Хамрагдсан нийт хүүхдийн тоо</th>
              <th>Боловсролын үйлчилгээний хүртээмж</th>
              <th>Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс</th>
            </tr>
            <tr>
              <td>Хичээл</td>
              <td>{{ depClass }}</td>
              <td>{{ students }}</td>
              <td>{{ studCount }}</td>
              <td>{{ lessonAccess }}%</td>
              <td></td>
            </tr>
            <tr>
              <td>Хөгжүүлэх ажил</td>
              <td>{{ depProject[0] }}</td>
              <td>{{ depProject[1] }}</td>
              <td>{{ depProject[2] }}</td>
              <td>{{ depProject[3].toFixed(2) }}%</td>
              <td></td>
            </tr>
            <tr>
              <td>Соёл хүмүүжлийн ажил</td>
              <td>{{ depProjectCxa[0] }}</td>
              <td>{{ depProjectCxa[1] }}</td>
              <td>{{ depProjectCxa[2] }}</td>
              <td>{{ depProjectCxa[3].toFixed(2) }}%</td>
              <td></td>
            </tr>

            <tr>
              <td>Дундаж үзүүлэлт</td>
              <td>
                {{
                  ((depClass + depProject[0] + depProject[0]) / 3).toFixed(2)
                }}
              </td>
              <td>
                {{
                  ((students + depProject[1] + depProject[1]) / 3).toFixed(2)
                }}
              </td>
              <td>
                {{
                  ((studCount + depProject[2] + depProject[2]) / 3).toFixed(2)
                }}
              </td>
              <td>{{ depAvg }}%</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <apexchart
          v-if="!showDep"
          type="radar"
          height="350"
          :options="chartStatDep"
          :series="seriesStatDep"
        ></apexchart>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      // Сургуулийн хэмжээнд харах
      studentsAll: [],
      studCountAll: [],
      depClassAll: [],
      projectAll: [],
      depProjects: [],
      projectsCXA: [],
      depProjectsCXA: [],
      yearId: null,
      show: false,
      showAll: true,
      showDep: true,
      classAvgAll: 0,
      yearAvg: 0,
      depAvg: 0,
      studLimitAvgAll: 0,
      studRegAvgAll: 0,
      studExamAvgAll: 0,
      lessonAccessAll: [],
      depOptionsAll: [],
      projectAccessAll: [],
      seriesStatAll: [
        {
          name: "Дундаж хувь",
          data: [],
        },
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      chartStatAll: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: ["Хичээл", "Хөгжүүлэх ажил", "Соёл хүмүүжлийн ажил"],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
      seriesStatDep: [
        {
          name: "Дундаж хувь",
          data: [],
        },
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      chartStatDep: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: ["Хичээл", "Хөгжүүлэх ажил", "Соёл хүмүүжлийн ажил"],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
      //Мэргэжлийн багаар харах
      students: [],
      studCount: [],
      depClass: [],
      project: [],
      classAvg: [],
      studLimitAvg: [],
      studRegAvg: [],
      studExamAvg: [],
      lessonAccess: [],
      depOptions: [],
      projectAccess: [],
      department: null,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    allProject() {
      let project = [];
      let projectStudCount = [];
      let projectMyStud = [];
      this.projectAll.forEach((element) => {
        projectStudCount.push(parseFloat(element.limit));
        projectMyStud.push(parseFloat(element.registered));
      });
      const classes = this.projectAll.length;
      const studLimit = projectStudCount.reduce((a, b) => a + b, 0);
      const studReg = projectMyStud.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(classes, studLimit, studReg, access);
      return project;
    },
    projectCxa() {
      let project = [];
      let projectStudLimit = [];
      let projectStudReg = [];
      this.projectsCXA.forEach((element) => {
        projectStudLimit.push(parseFloat(element.limit));
        projectStudReg.push(parseFloat(element.registered));
      });
      const count = this.projectsCXA.length;
      const studLimit = projectStudLimit.reduce((a, b) => a + b, 0);
      const studReg = projectStudReg.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(count, studLimit, studReg, access);
      return project;
    },

    depProject() {
      let project = [];
      let projectStudCount = [];
      let projectMyStud = [];
      this.depProjects.forEach((element) => {
        projectStudCount.push(parseFloat(element.limit));
        projectMyStud.push(parseFloat(element.registered));
      });
      const classes = this.depProjects.length;
      const studLimit = projectStudCount.reduce((a, b) => a + b, 0);
      const studReg = projectMyStud.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(classes, studLimit, studReg, access);
      return project;
    },
    depProjectCxa() {
      let project = [];
      let projectStudLimit = [];
      let projectStudReg = [];
      this.depProjectsCXA.forEach((element) => {
        projectStudLimit.push(parseFloat(element.limit));
        projectStudReg.push(parseFloat(element.registered));
      });
      const count = this.depProjectsCXA.length;
      const studLimit = projectStudLimit.reduce((a, b) => a + b, 0);
      const studReg = projectStudReg.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(count, studLimit, studReg, access);
      return project;
    },
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getMySchoolExamStatData() {
      axios
        .get("/manager/mySchoolExamsStatData")
        .then((response) => {
          //   console.log(response.data);
          this.depOptions = response.data.mySchoolDepartments;
          let a = response.data.mySchoolsQuarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getStat4All() {
      this.show = true;
      this.showAll = true;
      axios
        .get("/manager/getStat4All/" + this.yearId.id)
        .then((res) => {
          this.studentsAll = res.data.students;
          this.studCountAll = res.data.studCount;
          this.depClassAll = res.data.depClass;
          this.projectAll = res.data.projects;
          this.projectsCXA = res.data.projectsCXA;

          // Prevent division by zero
          this.lessonAccessAll = this.studentsAll
            ? parseFloat((this.studCountAll / this.studentsAll) * 100).toFixed(
                2
              )
            : 0;

          // Get project statistics
          const allProjectAccess = parseFloat(this.allProject[3]).toFixed(2);
          const projectCxaAccess = parseFloat(this.projectCxa[3]).toFixed(2);

          // Calculate average
          this.yearAvg = (
            (parseFloat(this.lessonAccessAll) +
              parseFloat(allProjectAccess) +
              parseFloat(projectCxaAccess)) /
            3
          ).toFixed(2);

          // Update chart series data
          this.seriesStatAll[1].data = [
            parseFloat(this.lessonAccessAll),
            parseFloat(allProjectAccess),
            parseFloat(projectCxaAccess),
          ];

          this.seriesStatAll[0].data = [
            parseFloat(this.yearAvg),
            parseFloat(this.yearAvg),
            parseFloat(this.yearAvg),
          ];

          this.show = false;
          this.showAll = false;
        })
        .catch((err) => {
          console.error("Error fetching statistics:", err);
          this.$toast?.error("Failed to load statistics. Please try again.");
          this.show = false;
        });
    },
    getStat4() {
      if (this.department) {
        this.show = true;
        this.showDep = true;
        axios
          .post("/manager/getStat4", {
            year: this.yearId.id,
            depId: this.department.department_id,
          })
          .then((res) => {
            this.students = res.data.students;
            this.studCount = res.data.studCount;
            this.depClass = res.data.depClass;
            this.depProjects = res.data.projects;
            this.depProjectsCXA = res.data.projectsCXA;
            this.lessonAccess = parseFloat(
              (this.studCount / this.students) * 100
            ).toFixed(2);
            this.lessonAccess = this.students
              ? parseFloat((this.studCount / this.students) * 100).toFixed(2)
              : 0;

            // Get project statistics
            const depProjectAccess = parseFloat(this.depProject[3]).toFixed(2);
            const depProjectCxaAccess = parseFloat(
              this.depProjectCxa[3]
            ).toFixed(2);

            // Calculate average
            this.depAvg = (
              (parseFloat(this.lessonAccess) +
                parseFloat(depProjectAccess) +
                parseFloat(depProjectCxaAccess)) /
              3
            ).toFixed(2);

            // Update chart series data
            this.seriesStatDep[1].data = [
              parseFloat(this.lessonAccess),
              parseFloat(depProjectAccess),
              parseFloat(depProjectCxaAccess),
            ];

            this.seriesStatDep[0].data = [
              parseFloat(this.depAvg),
              parseFloat(this.depAvg),
              parseFloat(this.depAvg),
            ];

            this.show = false;
            this.showDep = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.getMySchoolExamStatData();
  },
};
</script>
