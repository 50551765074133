<template>
  <div>
    <div class="row">
      <div class="card col-lg-12 border-success">
        <div class="card-body">
          <div class="form-group">
            <label>Чадвар сонгох</label>
            <multiselect
              v-model="selectedSgs"
              deselect-label="Хасах"
              select-label="Сонгох"
              selected-label="Сонгогдсон"
              track-by="id"
              label="name"
              placeholder="Чадвар сонгох"
              :options="skillOptions"
              :searchable="true"
              :allow-empty="false"
              class="my-2"
            >
            </multiselect>
          </div>
          <div class="form-group">
            <label>Сонгогдсон ажилчид</label>
            <b-list-group>
              <b-list-group-item
                v-for="(user, index) in userDatas"
                :key="user.userId"
                class="d-flex justify-content-between py-0"
              >
                <span>
                  {{ index + 1 }}.
                  {{ user.userName }}
                </span>
                <strong class="text-success">
                  {{ user.value.toFixed(2) }} %
                </strong>
              </b-list-group-item>
            </b-list-group>
          </div>
          <b-button
            :disabled="selectedSgs == null"
            size="sm"
            @click.prevent="setSkillValues()"
            variant="outline-success"
          >
            <b-icon icon="award"></b-icon>
            Үнэлгээ оруулах
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["userDatas"],
  data() {
    return {
      selectedSgs: null,
      skillOptions: [],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getSkillList() {
      this.selectedSgs = null;
      axios
        .get("/manager/getSkillList")
        .then((response) => {
          this.skillOptions = response.data.skillOptions;
          this.$bvModal.show("modal-result-skill");
        })
        .catch((errors) => {});
    },
    setSkillValues() {
      axios
        .post("/manager/setSkillValues", {
          userDatas: this.userDatas,
          selectedSgs: this.selectedSgs.id,
        })
        .then((response) => {
          if (response.data.errMsj == "success")
            this.$toastr.s("Үнэлгээг амжилттай орууллаа!", "Амжилттай");
          else
            this.$toastr.w("Хэрэглэгч дээр чадвар бүртгээгүй байна!", "Анхаар");
        })
        .catch((errors) => {
          this.$toastr.w("Алдаа гарлаа." + errors, "Анхаар");
          return;
        });
    },
  },
  created() {
    this.getSkillList();
  },
};
</script>

<style scoped></style>
