<template>
    <div class="pt-3">
        <div class="card myCard">
            <div class="card-header ui-sortable-handle" style="cursor: move">
                <h3 class="card-title">
                    <i class="fas fa-users mr-1"></i>
                    Хэрэглэгчид
                </h3>
                <div class="card-tools">
                    <ul class="nav nav-pills ml-auto">
                        <li class="nav-item mr-1">
                            <button
                                class="btn btn-sm btn-primary"
                                @click="createMode"
                            >
                                <i class="fas fa-plus-circle"></i> Хэрэглэгч
                                бүртгэх
                            </button>
                        </li>
                        <li class="nav-item">
                            <div
                                class="input-group mt-0 input-group-sm"
                                style="width: 350px"
                            >
                                <input
                                    type="text"
                                    name="table_search"
                                    class="form-control float-right"
                                    placeholder="Нэр, цахим шуудан зэргээр хайлт хийх.."
                                    v-model="searchWord"
                                />
                                <div class="input-group-append">
                                    <button
                                        type="submit"
                                        class="btn btn-default"
                                        @click="search"
                                    >
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
                <div class="row">
                    <div class="col-md-6 col-lg-6">
                        <multiselect
                            v-model="school"
                            select-label="Сонгох"
                            selected-label="Сонгогдсон"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            label="school_name"
                            track-by="id"
                            @input="getUsers"
                            placeholder="Сургууль сонгоно уу?"
                            :options="schools"
                            :option-height="104"
                            :custom-label="customLabel"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                            <template slot="singleLabel" slot-scope="props">
                                <div class="d-flex">
                                    <img
                                        class="option__image"
                                        :src="props.option.school_logo_url_hor"
                                        alt="logo"
                                        v-if="props.option.school_logo_url_hor"
                                    />
                                    <img
                                        class="option__image"
                                        src="/images/logo/no-image.png"
                                        alt="logo"
                                        v-else
                                    />
                                    <div class="option__desc ml-3">
                                        <p class="option__title">
                                            {{ props.option.school_name }}
                                        </p>
                                        <p class="option__small">
                                            {{ props.option.school_principal }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="d-flex">
                                    <img
                                        class="option__image"
                                        :src="props.option.school_logo_url_hor"
                                        alt="logo"
                                        v-if="props.option.school_logo_url_hor"
                                    />
                                    <img
                                        class="option__image"
                                        src="/images/logo/no-image.png"
                                        alt="logo"
                                        v-else
                                    />
                                    <div class="option__desc ml-3">
                                        <p class="option__title">
                                            {{ props.option.school_name }}
                                        </p>
                                        <p class="option__small">
                                            {{ props.option.school_principal }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <a
                            class="btn btn-app bg-success float-right"
                            v-if="school"
                            @click="importExcel"
                        >
                            <i class="fas fa-table"></i> Excel import
                        </a>
                    </div>
                </div>
                <b-overlay :show="loading" rounded="sm">
                    <table class="table mt-3">
                        <thead>
                            <tr>
                                <th>#№</th>
                                <th>#id</th>
                                <!-- <th>Зураг</th> -->
                                <th>Нэр</th>
                                <th>Үүрэг</th>
                                <th>Цахим шуудан</th>
                                <th>Үйлдэл</th>
                                <th>Огноо</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in users" :key="user.id">
                                <td>{{ ++index }}</td>
                                <td>{{ user.id }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.role }}</td>
                                <td>{{ user.email }}</td>
                                <td>
                                    <button
                                        class="btn btn-sm btn-info"
                                        @click="viewUser(user)"
                                    >
                                        <i class="fa fa-eye"></i> Харах
                                    </button>
                                    <button
                                        class="btn btn-sm btn-warning"
                                        @click="editUser(user)"
                                    >
                                        <i class="fa fa-edit"></i> Засах
                                    </button>
                                    <button
                                        class="btn btn-sm btn-danger"
                                        @click="deleteUser(user)"
                                    >
                                        <i class="fa fa-trash"></i> Устгах
                                    </button>
                                </td>
                                <td>
                                    {{ user.created_at | dateYearMonthDay }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-overlay>
            </div>
        </div>

        <div
            class="modal fade"
            id="viewUser"
            tabindex="-1"
            role="dialog"
            aria-labelledby="viewUserModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row" v-if="user != null">
                            <div class="col-md-6">
                                <p><b>Name:</b> {{ user.name }}</p>
                                <p><b>Email:</b> {{ user.email }}</p>
                                <p>
                                    <b>Засалт хийгдсэн огноо:</b>
                                    {{ user.updated_at | dateYearMonthDayTime }}
                                </p>
                                <p>
                                    <b>Бүртгэгдсэн огноо:</b>
                                    {{ user.created_at | dateYearMonthDayTime }}
                                </p>
                            </div>

                            <div class="col-md-6">
                                <img
                                    v-if="user.profile_photo_url"
                                    :src="user.profile_photo_url"
                                    class="w-100"
                                />
                                <img
                                    v-else
                                    src="/images/users/user.png"
                                    class="w-100"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="createUser"
            tabindex="-1"
            role="dialog"
            aria-labelledby="createUserModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                            class="modal-title"
                            id="createUserModalLabel"
                            v-show="!editMode"
                        >
                            Хэрэглэгч үүсгэх
                        </h5>
                        <h5
                            class="modal-title"
                            id="createUserModalLabel"
                            v-show="editMode"
                        >
                            Хэрэглэгчийн өгөгдлийг засах
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form
                        @submit.prevent="
                            !editMode ? createUser() : updateUser()
                        "
                    >
                        <div class="modal-body">
                            <div class="form-group">
                                <label> Name </label>
                                <input
                                    v-model="form.name"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    class="form-control"
                                    :class="{
                                        'is-invaild': form.errors.has('name')
                                    }"
                                />
                                <has-error
                                    :form="form"
                                    field="name"
                                ></has-error>
                            </div>

                            <div class="form-group">
                                <label> Email </label>
                                <input
                                    v-model="form.email"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    class="form-control"
                                    :class="{
                                        'is-invaild': form.errors.has('email')
                                    }"
                                />
                                <has-error
                                    :form="form"
                                    field="email"
                                ></has-error>
                            </div>

                            <!-- <div class="form-group">
                                <label> Phone Number </label>
                                <input
                                    v-model="form.phone"
                                    type="text"
                                    name="phone"
                                    placeholder="Phone Number"
                                    class="form-control"
                                    :class="{
                                        'is-invaild': form.errors.has('phone')
                                    }"
                                />
                                <has-error
                                    :form="form"
                                    field="phone"
                                ></has-error>
                            </div> -->

                            <div class="form-group">
                                <label> Choose Role </label>
                                <b-form-select
                                    v-model="form.role"
                                    :options="roles"
                                    text-field="name"
                                    value-field="id"
                                ></b-form-select>
                                <has-error
                                    :form="form"
                                    field="role"
                                ></has-error>
                            </div>

                            <div class="form-group">
                                <label> Password </label>
                                <input
                                    v-model="form.password"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    class="form-control"
                                    :class="{
                                        'is-invaild': form.errors.has(
                                            'password'
                                        )
                                    }"
                                />
                                <has-error
                                    :form="form"
                                    field="password"
                                ></has-error>
                            </div>

                            <b-form-group label="Assign Permissions">
                                <b-form-checkbox
                                    v-for="option in permissions"
                                    v-model="form.permissions"
                                    :key="option.name"
                                    :value="option.name"
                                    name="flavour-3a"
                                >
                                    {{ option.name }}
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button
                                type="button"
                                class="btn btn-lg btn-danger"
                                data-dismiss="modal"
                            >
                                Хаах
                            </button>
                            <b-button
                                variant="primary"
                                v-if="!load"
                                class="btn-lg"
                                disabled
                            >
                                <b-spinner small type="grow"></b-spinner>
                                {{ action }}
                            </b-button>
                            <button
                                type="submit"
                                v-if="load"
                                v-show="!editMode"
                                class="btn btn-lg btn-primary"
                            >
                                Хэрэглэгч үүсгэх
                            </button>
                            <button
                                type="submit"
                                v-if="load"
                                v-show="editMode"
                                class="btn btn-lg btn-success"
                            >
                                Хэрэглэгчийн мэдээлэл шинэчлэх
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Import excel -->
        <div
            class="modal fade"
            id="importExcel"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Excel файлаар хэрэглэгч нэмэх
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <b-overlay :show="showFormLoader" rounded="sm">
                            <form>
                                <div class="form-group">
                                    <label>{{ school.school_name }}</label>
                                </div>
                                <div class="form-group">
                                    <label>Role</label>
                                    <multiselect
                                        v-model="formExcel.role"
                                        :options="roles"
                                        track-by="id"
                                        label="name"
                                        :custom-label="customRoleName"
                                        :searchable="true"
                                        :show-labels="true"
                                        :allow-empty="false"
                                        placeholder="Role сонгох"
                                        deselect-label="Хасах"
                                        select-label="Сонгох"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                            ><strong>
                                                {{ option.description }} -
                                                {{ option.name }} </strong
                                            ><strong>
                                                сонгогдлоо</strong
                                            ></template
                                        >
                                    </multiselect>
                                </div>
                                <div class="form-group">
                                    <label for="name">Excel файл</label>
                                    <input
                                        type="file"
                                        v-on:change="addfile($event)"
                                        placeholder="Upload file"
                                        :key="fileInputKey"
                                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </div>
                                <div
                                    class="form-group overflow-auto"
                                    v-if="errorUsers.length > 0"
                                >
                                    <label for="name"
                                        >Дараах
                                        {{ errorUsers.length }} хэрэглэгчийн
                                        бүртгэл давхцсан байна!</label
                                    >
                                    <b-alert
                                        variant="warning"
                                        class="d-flex justify-content-between"
                                        fade
                                        show
                                        v-for="user in errorUsers"
                                        :key="user.email"
                                    >
                                        {{ user.name }}
                                        <b-badge variant="warning" pill>
                                            {{ user.email }}</b-badge
                                        >
                                    </b-alert>
                                </div>
                                <button
                                    type="submit"
                                    @click.prevent="excelAdd()"
                                    class="btn btn-sm btn-primary"
                                >
                                    Нэмэх
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-dark float-right"
                                    data-dismiss="modal"
                                >
                                    Хаах
                                </button>
                            </form>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import * as XLSX from "xlsx";
export default {
    data() {
        return {
            schools: [],
            school: "",
            action: "",
            searchWord: "",
            loading: false,
            editMode: false,
            load: true,
            user: null,
            users: [],
            roles: [],
            permissions: [],
            form: new Form({
                id: "",
                name: "",
                // phone: "",
                password: "",
                email: "",
                permissions: [],
                role: 4,
                profile_photo_url: ""
            }),
            formExcel: new Form({
                users: [],
                role: "",
                school_id: ""
            }),
            file: File,
            arrayBuffer: null,
            fileInputKey: 0,
            errorUsers: [],
            showFormLoader: false
        };
    },
    components: {
        Multiselect
    },
    methods: {
        customLabel({ school_name, school_principal }) {
            return `${school_name} – ${school_principal}`;
        },
        getAllSchools() {
            axios
                .get("/owner/getAllSchools")
                .then(response => {
                    this.schools = response.data.activeSchools;
                })
                .catch(() => {});
        },

        //=========================== EXCEL IMPORT ===========================
        customRoleName({ name, description }) {
            return `${description} - ${name}`;
        },
        importExcel() {
            this.errorUsers = [];
            this.showFormLoader = false;
            this.formExcel.reset();
            this.file = null;
            this.arrayBuffer = null;
            this.fileInputKey++;
            this.formExcel.school_id = this.school.id;
            $("#importExcel").modal("show");
        },
        addfile(event) {
            this.file = event.target.files[0];
            let fileReader = new FileReader();
            fileReader.readAsArrayBuffer(this.file);
            fileReader.onload = e => {
                this.arrayBuffer = fileReader.result;
                var data = new Uint8Array(this.arrayBuffer);
                var arr = new Array();
                for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLSX.read(bstr, { type: "binary" });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                this.formExcel.users = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true
                });
            };
        },
        excelAdd() {
            this.showFormLoader = true;
            this.formExcel
                .post("/owner/importExcel")
                .then(response => {
                    if (response.data.errorCode == null) {
                        this.$toastr.s(
                            "Хэрэглэгч Excel-ээс амжилттай орууллаа.",
                            "Амжилттай"
                        );
                        Fire.$emit("loadUser");
                        this.formExcel.reset();
                        $("#importExcel").modal("hide");
                    } else {
                        this.$toastr.e(response.data.errorCode, "Анхаар");
                        this.errorUsers = response.data.errorUsers;
                    }
                    this.showFormLoader = false;
                })
                .catch(() => {
                    this.showFormLoader = false;
                    this.$toastr.e(
                        "Хэрэглэгч Excel-ээс үүсгэж чадсангүй!",
                        "Алдаа гарлаа."
                    );
                });
        },
        createMode() {
            this.editMode = false;
            $("#createUser").modal("show");
        },
        editUser(user) {
            this.editMode = true;
            this.form.reset();
            this.form.fill(user);
            this.form.role = user.roles[0].id;
            this.form.permissions = user.userPermissions;
            $("#createUser").modal("show");
        },
        //=========================== END EXCEL IMPORT ===========================

        getUsers() {
            this.action = "Хэрэглэгчийн мэдээллийг дуудаж байна.";
            this.loading = true;
            axios
                .get("/owner/getAllUsers/" + this.school.id)
                .then(response => {
                    this.loading = false;
                    this.users = response.data.users;
                    this.roles = response.data.roles;
                })
                .catch(() => {
                    this.loading = false;
                    this.$toastr.e(
                        "Өгөгдлийг серверээс авч чадсангүй.",
                        "Алдаа гарлаа."
                    );
                });
        },
        viewUser(user) {
            $("#viewUser").modal("show");
            this.user = user;
        },
        getPermissions() {
            axios.get("/owner/getAllPermissions").then(response => {
                this.permissions = response.data.permissions;
            });
        },
        createUser() {
            this.action = "Хэрэглэгчийг үүсгэж байна ...";
            this.load = false;
            this.form
                .post("/owner/users")
                .then(response => {
                    this.load = true;
                    this.$toastr.s(
                        "Хэрэглэгч амжилттай үүсгэлээ.",
                        "Амжилттай"
                    );
                    Fire.$emit("loadUser");
                    $("#createUser").modal("hide");
                    this.form.reset();
                })
                .catch(() => {
                    this.load = true;
                    this.$toastr.e(
                        "Хэрэглэгчийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },
        updateUser() {
            this.action = "Хэрэглэгчийн мэдээллийг шинэчилж байна ...";
            this.load = false;
            this.form
                .put("/owner/users/" + this.form.id)
                .then(response => {
                    this.load = true;
                    this.$toastr.s(
                        "Хэрэглэгчийн мэдээллийг амжилттай шинэчиллээ.",
                        "Шинэчилсэн"
                    );
                    Fire.$emit("loadUser");
                    $("#createUser").modal("hide");
                    this.form.reset();
                })
                .catch(() => {
                    this.load = true;
                    this.$toastr.e(
                        "Хэрэглэгчийн мэдээллийг шинэчилж чадсангүй, дахин оролдоно уу?",
                        "Алдаа гарлаа"
                    );
                });
        },
        deleteUser(user) {
            // console.log(user);
            Swal.fire({
                title: "Устгахдаа итгэлтэй байна уу?",
                text:
                    user.name +
                    " Хэрэглэгчийн мэдээлэл уствал дахин сэргээх боломжгүй болно!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Үгүй, цуцлах",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Тийм, хэрэглэгчийг устга!"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/owner/users/delete/" + user.id)
                        .then(response => {
                            this.deleteUser;
                            Swal.fire(
                                "Устгагдлаа!",
                                "Сонгосон хэрэглэгчийн мэдээлэл устгагдлаа." +
                                    user.name,
                                "success"
                            );
                            Fire.$emit("loadUser");
                        })
                        .catch(() => {
                            this.$toastr.e(
                                user.name +
                                    "Хэрэглэгчийн мэдээллийг устгаж чадсангүй, дахин оролдоно уу?",
                                "Алдаа гарлаа"
                            );
                        });
                }
            });
        },
        search() {
            this.loading = true;
            axios
                .get("/owner/users/search/search?search=" + this.searchWord)
                .then(response => {
                    // console.log(response);
                    this.loading = false;
                    this.users = response.data.users;
                })
                .catch(() => {
                    // console.log(erros);
                    this.loading = true;
                    this.$toastr.e("Хайлтын утга буруу байна", "Алдаа гарлаа");
                });
        }
    },
    created() {
        this.getAllSchools();
        // this.$toastr.s("Амжилттай ажиллаа", "Баяр хүргэе.");
        this.getPermissions();
        Fire.$on("loadUser", () => {
            this.getUsers();
        });
    }
};
</script>

<style scoped>
.option__image {
    height: 50px;
}
.myCard {
    min-height: 500px;
}
</style>
