<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item active">
              <a class="nav-link active" href="#mysuccess" data-toggle="tab"
                >Миний хүүхдүүдийн амжилт</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#timeline" data-toggle="tab"
                >Суралцагч</a
              >
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#activity" data-toggle="tab"
                >Багш, ажилтан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="mysuccess">
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
                v-if="mySuccesses.length != 0"
              >
                <table
                  class="table table-hover table-striped table-valign-middle"
                >
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Оролцсон олимпиад, уралдаан тэмцээн</th>
                      <th>Суралцагчийн овог, нэр</th>
                      <th>Анги</th>
                      <th>Байр</th>
                      <th>Амжилт гаргасан түвшин</th>
                      <th>Гаргасан амжилт</th>
                      <th>Тайлбар</th>
                      <th>Бүртгэсэн</th>
                      <!-- <th>Зассан</th> -->
                      <!-- <th>Үйлдлүүд</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(mySuccess, index) in mySuccesses"
                      :key="mySuccess.id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="mySuccess.my_competition_info !== null">
                          <b-badge
                            variant="success"
                            class="text-wrap"
                            style="width: 8rem"
                            >{{ mySuccess.my_competition_info.name }}</b-badge
                          >
                          {{
                        }}</span>
                        <span
                          v-if="mySuccess.my_competition_info === null"
                          class="text-wrap"
                          style="width: 8rem"
                          >Олимпиад, уралдаан тэмцээн бүртгээгүй байна.</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.student_last_name[0] }}.
                        {{ mySuccess.student_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.student_tuvshin
                        }}{{ mySuccess.student_buleg }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge variant="info">{{
                          filterEzelsenBair(mySuccess.amjilt_ezelsen_bair)
                        }}</b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge variant="info">
                          {{
                            filterStudent_turul(mySuccess.student_turul)
                          }}</b-badge
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.amjilt_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.amjilt_tailbar }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.created_at | dateYearMonthDay }}
                      </td>
                      <!-- <td class="dtr-control sorting_1" tabindex="0">
                        {{ mySuccess.updated_at | dateYearMonthDay }}
                      </td> -->
                      <!-- <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolStudentSuccess(sSuccess)"
                          ><i class="fas fa-edit"></i
                        ></a>

                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolStudentSuccess(sSuccess)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
                v-if="mySuccesses.length == 0"
              >
                Одоогоор олимпиад уралдаан тэмцээн бүртгэгдээгүй байна.
              </div>
            </div>
            <div class="tab-pane" id="timeline">
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <table
                  class="table table-hover table-striped table-valign-middle"
                >
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Оролцсон олимпиад, уралдаан тэмцээн</th>
                      <th>Суралцагчийн овог, нэр</th>
                      <th>Анги</th>
                      <th>Байр</th>
                      <th>Амжилт гаргасан түвшин</th>
                      <th>Гаргасан амжилт</th>
                      <th>Тайлбар</th>
                      <th>Бүртгэсэн</th>
                      <!-- <th>Зассан</th> -->
                      <!-- <th>Үйлдлүүд</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(sSuccess, index) in studentSuccesses"
                      :key="sSuccess.id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="sSuccess.my_competition_info !== null">
                          <b-badge
                            variant="success"
                            class="text-wrap"
                            style="width: 8rem"
                            >{{ sSuccess.my_competition_info.name }}</b-badge
                          >
                          {{
                        }}</span>
                        <span
                          v-if="sSuccess.my_competition_info === null"
                          class="text-wrap"
                          style="width: 8rem"
                          >Олимпиад, уралдаан тэмцээн бүртгээгүй байна.</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.student_last_name[0] }}.
                        {{ sSuccess.student_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.student_tuvshin
                        }}{{ sSuccess.student_buleg }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge variant="info">{{
                          filterEzelsenBair(sSuccess.amjilt_ezelsen_bair)
                        }}</b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge variant="info">
                          {{
                            filterStudent_turul(sSuccess.student_turul)
                          }}</b-badge
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.amjilt_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.amjilt_tailbar }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.created_at | dateYearMonthDay }}
                      </td>
                      <!-- <td class="dtr-control sorting_1" tabindex="0">
                        {{ sSuccess.updated_at | dateYearMonthDay }}
                      </td> -->
                      <!-- <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                      >
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolStudentSuccess(sSuccess)"
                          ><i class="fas fa-edit"></i
                        ></a>

                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolStudentSuccess(sSuccess)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-pane" id="activity">
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <table
                  class="table table-hover table-striped table-valign-middle"
                >
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Оролцсон олимпиад, уралдаан тэмцээн</th>
                      <th>Багшийн овог, нэр</th>
                      <th>Байр</th>
                      <th>Түвшин</th>
                      <th>Гаргасан амжилт</th>
                      <th>Тайлбар</th>
                      <th>Бүртгэсэн</th>
                      <!-- <th>Зассан</th> -->
                      <!-- <th>Үйлдлүүд</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(tSuccess, index) in teacherSuccesses"
                      :key="tSuccess.success_id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="tSuccess.my_competition_info !== null">
                          <b-badge
                            variant="success"
                            class="text-wrap"
                            style="width: 8rem"
                            >{{ tSuccess.my_competition_info.name }}</b-badge
                          >
                          {{
                        }}</span>
                        <span
                          v-if="tSuccess.my_competition_info === null"
                          class="text-wrap"
                          style="width: 8rem"
                          >Олимпиад, уралдаан тэмцээн бүртгээгүй байна.</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tSuccess.emp_teacher_last_name[0] }}.
                        {{ tSuccess.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ filterEzelsenBair(tSuccess.amjilt_ezelsen_bair) }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ filterStudent_turul(tSuccess.student_turul) }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tSuccess.amjilt_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tSuccess.amjilt_tailbar }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tSuccess.created_at | dateYearMonthDay }}
                      </td>
                      <!-- <td class="dtr-control sorting_1" tabindex="0">
                        {{ tSuccess.updated_at | dateYearMonthDay }}
                      </td> -->
                      <!-- <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                      >
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolTeacherSuccess(tSuccess)"
                          ><i class="fas fa-edit"></i
                        ></a>

                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolTeacherSuccess(tSuccess)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- @show="resetTeacherSuccessModal" -->
    <!-- @ok="handleOk" -->

    <!-- Сурагч -->
    <!-- <b-modal
      id="modal-student-success"
      ref="modal"
      title="Суралцагчын амжилтыг бүртгэх модуль"
      @hidden="resetStudentSuccessModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editModeStudent">Амжилт бүртгэх</h3>
          <h3 class="card-title" v-show="editModeStudent">Амжилт засах</h3>
        </div>
        <div class="card-body form-responsive p-0">
          <form
            @submit.prevent="
              !editModeStudent
                ? createSchoolStudentSuccess()
                : updateSchoolStudentSuccess()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label
                  >Олимпиад, уралдаан тэмцээн сонгох(Заавал сонгох
                  шаардлагагүй)</label
                >
                <multiselect
                  v-model="formStudentSuccess.competition_id"
                  deselect-label="Can't remove this value"
                  track-by="id"
                  label="id"
                  :custom-label="customCompetitionName"
                  placeholder="Олимпиад, уралдаан тэмцээн сонгох(Заавал сонгох шаардлагагүй)"
                  :options="competitions"
                  :searchable="true"
                  :allow-empty="true"
                  :close-on-select="true"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.name }}</strong></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Суралцагч сонгох</label>
                <multiselect
                  v-model="formStudentSuccess.student_migration_id"
                  deselect-label="Can't remove this value"
                  track-by="stud_mig_id"
                  label="stud_mig_id"
                  :custom-label="customStudents"
                  placeholder="Суралцагч сонгох"
                  :options="students"
                  :searchable="true"
                  :allow-empty="false"
                  :close-on-select="true"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.school_class_full_name }}:
                      {{ option.pro_student_last_name[0] }}.
                      {{ option.pro_student_name }}: {{ option.email }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Амжилт гаргасан түвшинг сонгох</label>
                <multiselect
                  v-model="formStudentSuccess.student_turul"
                  :options="turul"
                  :multiple="false"
                  track-by="student_turul"
                  label="student_turul"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Амжилт гаргасан түвшинг сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.student_turul }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formStudentSuccess"
                  field="student_turul"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Эзэлсэн байр сонгох</label>
                <multiselect
                  v-model="formStudentSuccess.amjilt_ezelsen_bair"
                  :options="ezelsenBair"
                  :multiple="false"
                  track-by="ezelsen_bair"
                  label="ezelsen_bair"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Эзэлсэн байр сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.ezelsen_bair }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formStudentSuccess"
                  field="ezelsen_bair"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын нэр</label>
                <input
                  type="text"
                  name="amjilt_name"
                  v-model="formStudentSuccess.amjilt_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        formStudentSuccess.errors.has('amjilt_name'),
                    },
                  ]"
                  placeholder="Шагналын нэр"
                />
                <has-error
                  :form="formStudentSuccess"
                  field="amjilt_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын тайлбар</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formStudentSuccess.amjilt_tailbar"
                  placeholder="Гаргасан амжилтын хувьд дэлгэрэнгүй
                мэдээлэл оруулна уу?"
                >
                </b-form-textarea>
                <has-error
                  :form="formStudentSuccess"
                  field="amjilt_tailbar"
                ></has-error>
              </div>
            </div>
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeStudent"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeStudent"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeStudent"
                @click.prevent="resetStudentSuccessModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editModeTeacher: false,
      editModeStudent: false,
      //   role: "",
      //   teachers: [],
      //   students: [],
      //   competitions: [],
      mySuccesses: [],
      studentSuccesses: [],
      teacherSuccesses: [],
      //   formTeacherSuccess: new Form({
      //     id: "",
      //     competition_id: "",
      //     employee_migration_id: "",
      //     emp_role: "",
      //     student_turul: 0,
      //     amjilt_ezelsen_bair: 0,
      //     amjilt_name: "",
      //     amjilt_tailbar: "",
      //   }),
      //   formStudentSuccess: new Form({
      //     id: "",
      //     competition_id: "",
      //     student_migration_id: "",
      //     stu_role: "",
      //     // student_buleg: "",
      //     // student_tuvshin: "",
      //     student_turul: 0,
      //     amjilt_ezelsen_bair: 0,
      //     amjilt_name: "",
      //     amjilt_tailbar: "",
      //   }),
      turul: [
        { name: 0, student_turul: "Түвшин сонгох" },
        { name: 1, student_turul: "Анги" },
        { name: 2, student_turul: "Сургууль" },
        { name: 3, student_turul: "Дүүрэг" },
        { name: 4, student_turul: "Нийслэл" },
        { name: 5, student_turul: "Улс" },
        { name: 6, student_turul: "Олон улс" },
      ],
      ezelsenBair: [
        { name: 0, ezelsen_bair: "Сонголт хийнэ үү?" },
        { name: 1, ezelsen_bair: "Алт" },
        { name: 2, ezelsen_bair: "Мөнгө" },
        { name: 3, ezelsen_bair: "Хүрэл" },
        { name: 4, ezelsen_bair: "Тусгай байр(4-10)" },
      ],
    };
  },
  components: { Multiselect },
  methods: {
    getAllSuccesses() {
      axios
        .get("/parent/getAllParentSuccesses")
        .then((res) => {
          // console.log(response.data.rewards);
          this.teacherSuccesses = res.data.teacherSuccesses;
          this.studentSuccesses = res.data.studentSuccesses;
          this.mySuccesses = res.data.mySuccesses;
          // //   this.role = res.data.role;
          // //   this.teachers = res.data.teachers;
          // //   this.students = res.data.students;
          //   this.competitions = res.data.competitions;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    filterEzelsenBair(val) {
      //   console.log(val);
      let myEzBair = "";
      this.ezelsenBair.forEach((element) => {
        // console.log("name =>" + element.name);
        if (element.name == val) {
          myEzBair = element.ezelsen_bair;
        }
      });
      return myEzBair;
    },
    filterStudent_turul(val) {
      //   console.log(val);
      let myTurul = "";
      this.turul.forEach((element) => {
        // console.log("name =>" + element.name);
        if (element.name == val) {
          myTurul = element.student_turul;
        }
      });
      return myTurul;
    },
    editSchoolTeacherSuccess(success) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", success);
      this.editModeTeacher = true;
      this.formTeacherSuccess.reset();
      this.formTeacherSuccess.fill(success);
      this.teachers.filter((el) => {
        if (el.emp_mig_id == success.emp_mig_id) {
          this.formTeacherSuccess.employee_migration_id = el;
        }
      });
      this.turul.filter((el) => {
        if (el.name == success.student_turul) {
          this.formTeacherSuccess.student_turul = el;
        }
      });
      this.ezelsenBair.filter((el) => {
        if (el.name == success.amjilt_ezelsen_bair) {
          this.formTeacherSuccess.amjilt_ezelsen_bair = el;
        }
      });

      this.$bvModal.show("modal-teacher-success");
    },
    updateSchoolTeacherSuccess() {
      this.formTeacherSuccess
        .put(
          "/all_worker_functions/updateTeacherSuccess/" +
            this.formTeacherSuccess.id
        )
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolSuccess");
          this.resetTeacherSuccessModal();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    deleteSchoolTeacherSuccess(success) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/all_worker_functions/deleteTeacherSuccess/" + success.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadSchoolSuccess");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    resetTeacherSuccessModal() {
      this.formTeacherSuccess.reset();
      this.editModeTeacher = false;
      this.$bvModal.hide("modal-teacher-success");
    },
    createFillFromForTeacher() {
      this.$bvModal.show("modal-teacher-success");
      //   this.formTeacherSuccess.emp_role = this.role;
    },
    createSchoolTeacherSuccess() {
      this.formTeacherSuccess
        .post("/all_worker_functions/createTeacherSuccess")
        .then((res) => {
          this.$toastr.s("Ажилтны гаргасан амжилтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolSuccess");
          this.resetTeacherSuccessModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customTeachers({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    //Сурагч
    editSchoolStudentSuccess(success) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", success);
      this.editModeStudent = true;
      this.formStudentSuccess.reset();
      this.formStudentSuccess.fill(success);
      this.students.filter((el) => {
        if (el.stud_mig_id == success.stud_mig_id) {
          this.formStudentSuccess.student_migration_id = el;
        }
      });
      this.formStudentSuccess.student_turul = success.student_turul;
      this.turul.filter((el) => {
        if (el.name == success.student_turul) {
          this.formStudentSuccess.student_turul = el;
        }
      });

      this.ezelsenBair.filter((el) => {
        if (el.name == success.amjilt_ezelsen_bair) {
          this.formStudentSuccess.amjilt_ezelsen_bair = el;
        }
      });

      this.$bvModal.show("modal-student-success");
    },
    updateSchoolStudentSuccess() {
      this.formStudentSuccess
        .put(
          "/all_worker_functions/updateStudentSuccess/" +
            this.formStudentSuccess.id
        )
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolSuccess");
          this.resetStudentSuccessModal();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    deleteSchoolStudentSuccess(success) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/all_worker_functions/deleteStudentSuccess/" + success.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadSchoolSuccess");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    resetStudentSuccessModal() {
      this.formStudentSuccess.reset();
      this.editModeStudent = false;
      this.$bvModal.hide("modal-student-success");
    },
    createFillFromForStudent() {
      this.$bvModal.show("modal-student-success");
    },
    createSchoolStudentSuccess() {
      this.formStudentSuccess
        .post("/all_worker_functions/createStudentSuccess")
        .then((res) => {
          this.$toastr.s("Ажилтны гаргасан амжилтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolSuccess");
          this.resetStudentSuccessModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customStudents({
      school_class_full_name,
      pro_student_last_name,
      pro_student_name,
      email,
    }) {
      return `${school_class_full_name} : ${pro_student_last_name[0]}. ${pro_student_name}: ${email}`;
    },
    customCompetitionName({ name }) {
      return `${name}`;
    },
  },
  //   beforeCreate() {
  //     axios
  //       .get("/all_worker_functions/getMyData")
  //       .then((res) => {
  //         // console.log(res.data);
  //         // this.role = res.data.role;
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //       });
  //   },
  created() {
    this.getAllSuccesses();
    Fire.$on("loadSchoolSuccess", () => {
      this.getAllSuccesses();
    });
  },
};
</script>

<style></style>
