<template>
  <img
    alt="user-avatar"
    v-b-tooltip.hover.top.html="
      '<dd><img src=' +
      myImgUrl +
      ' style=' +
      'width:180px!important' +
      '></dd><dt>...</dt><dd>' +
      lastName +
      ' <span class=text-uppercase>' +
      name +
      '</span></dd></dl>'
    "
    class="imgZoom img-bordered-sm"
    :src="myImgUrl ? myImgUrl : '/images/users/user.png'"
  />
  <!-- class="user-avatar" -->
</template>

<script>
export default {
  props: ["myImgUrl", "name", "lastName"],
  data() {
    return {};
  },
};
</script>

<style scoped>
/* .profile-container {
  position: relative;
  display: inline-block;
} */

.imgZoom {
  height: auto;
}
</style>
