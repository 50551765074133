<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <multiselect v-model="year_id" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй." track-by="id" label="id"
        :custom-label="customYearName" placeholder="Хичээлийн жилээр шүүж харах" :options="years" :searchable="true"
        :allow-empty="false">
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear }}-{{
          option.finish_date | dateYear }}
            - хичээлийн жил
          </strong></template>
      </multiselect>
      <!-- Эцэг эхчүүдийн мэдээлэл -->
      <div class="row">
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <apexchart width="85%" type="bar" :options="options" :series="series"></apexchart>
            <!-- dontConnectStudents -->
            <b-form-group label="Шүүлт харах" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
              label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Хайлтын утгыг цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-table hover :items="dontConnectStudents" :fields="fields" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" responsive="sm" :filter="filter" :filter-included-fields="filterOn">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  Info modal
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <apexchart width="80%" type="donut" :options="options1" :series="series1"></apexchart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8 mt-3">
          <multiselect v-model="allSelectedStudents" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="pro_user_id" label="pro_user_id" :options="allStudents" :multiple="true"
            :custom-label="customStudentName" :close-on-select="false" :clear-on-select="false" :hidden-on-select="true"
            placeholder="Суралцагч сонгох" :searchable="true" :allow-empty="true">
            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.pro_student_last_name }}{{
              option.pro_student_name }}
              </strong></template>
          </multiselect>
        </div>
        <div class="col-lg-4 mt-3" variant="success">
          <b-button size="sm" @click.prevent="selectStudents" variant="success">Бүх суралцагчид сонгох</b-button>
          <b-button size="sm" @click.prevent="unselectStudents" variant="warning">Бүх суралцагчид арилгах</b-button>
          <b-button size="sm" @click.prevent="updateParentStatus" variant="danger">Сонгогдсон суралцагчдын эцэг эхийг
            "acive"
            болгох.</b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-6">
          <div class="small-box bg-white">
            <apexchart width="85%" type="bar" :options="options2" :series="series2"></apexchart>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <b-form-group label="Нэг хүүхэдтэй гэр бүлийг шүүлт хийж харах" label-for="filter-input" label-cols-sm="3"
              label-align-sm="right" label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="familyOnefilter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!familyOnefilter" @click="familyOnefilter = ''">Хайлтын утгыг цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-pagination v-model="familyOnecurrentPage" :total-rows="familyOneTotalRows" :per-page="familyOnePerPage"
                align="fill" size="sm" class="my-0"></b-pagination>
            </b-form-group>
            <b-table hover :items="familyOneChild" :fields="familyfields" :sort-by.sync="familyOnesortBy"
              :sort-desc.sync="familyOnesortDesc" responsive="sm" :filter="familyOnefilter"
              :filter-included-fields="familyOnefilterOn" :filter-ignored-fields="familyIgnoredFields"
              :per-page="familyOnePerPage" :current-page="familyOnecurrentPage">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(parent_father_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_father_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_father_name }} : Утас: {{ data.item.parent_father_phone_number }} <br> Ажил : {{
                    data.item.parent_father_ajil_gazar }}
                </b-badge>

              </template>
              <template #cell(parent_mother_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_mother_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_mother_name }} : Утас: {{ data.item.parent_mother_phone_number }}<br>Ажил : {{
                    data.item.parent_mother_ajil_gazar }}
                </b-badge>
              </template>
              <template #cell(get_my_primary_child)="data">
                <b-badge variant="info" v-for="(stud, index) in data.item.get_my_primary_child" :key="stud.user_id">
                  {{ stud.student_last_name | capitalizeAndEkhniiVseg }}.
                  {{ stud.student_name }}. Анги: {{ stud.student_migrations[0].my_school_class_info['full_name'] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  ...
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <b-form-group label="Хоёр хүүхэдтэй гэр бүлийг шүүлт хийж харах" label-for="filter-input" label-cols-sm="3"
              label-align-sm="right" label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="familyTwofilter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!familyTwofilter" @click="familyTwofilter = ''">Хайлтын утгыг цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-pagination v-model="familyTwocurrentPage" :total-rows="familyTwoTotalRows" :per-page="familyTwoPerPage"
              align="fill" size="sm" class="my-0"></b-pagination>
            <b-table hover :items="familyTwoChild" :fields="familyfields" :sort-by.sync="familyTwosortBy"
              :sort-desc.sync="familyTwosortDesc" responsive="sm" :filter="familyTwofilter"
              :filter-included-fields="familyTwofilterOn" :per-page="familyTwoPerPage"
              :filter-ignored-fields="familyIgnoredFields" :current-page="familyTwocurrentPage">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(parent_father_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_father_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_father_name }} : Утас: {{ data.item.parent_father_phone_number }}<br> Ажил : {{
                    data.item.parent_father_ajil_gazar }}
                </b-badge>

              </template>
              <template #cell(parent_mother_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_mother_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_mother_name }} : Утас: {{ data.item.parent_mother_phone_number }}<br> Ажил : {{
                    data.item.parent_mother_ajil_gazar }}
                </b-badge>
              </template>
              <template #cell(get_my_primary_child)="data">
                <b-badge variant="info" v-for="(stud, index) in data.item.get_my_primary_child" :key="stud.user_id">
                  {{ stud.student_last_name | capitalizeAndEkhniiVseg }}.
                  {{ stud.student_name }}. Анги: {{ stud.student_migrations[0].my_school_class_info['full_name'] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  ...
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <b-form-group label="Гурван хүүхэдтэй гэр бүлийг шүүлт хийж харах" label-for="filter-input"
              label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="familyThreefilter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!familyThreefilter" @click="familyThreefilter = ''">Хайлтын утгыг
                    цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-pagination v-model="familyThreecurrentPage" :total-rows="familyThreeTotalRows"
              :per-page="familyThreePerPage" align="fill" size="sm" class="my-0"></b-pagination>
            <b-table hover :items="familyThreeChild" :fields="familyfields" :sort-by.sync="familyThreesortBy"
              :sort-desc.sync="familyThreesortDesc" responsive="sm" :filter="familyThreefilter"
              :filter-included-fields="familyThreefilterOn" :per-page="familyThreePerPage"
              :filter-ignored-fields="familyIgnoredFields" :current-page="familyThreecurrentPage">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(parent_father_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_father_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_father_name }} : Утас: {{ data.item.parent_father_phone_number }}<br> Ажил : {{
                    data.item.parent_father_ajil_gazar }}
                </b-badge>

              </template>
              <template #cell(parent_mother_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_mother_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_mother_name }} : Утас: {{ data.item.parent_mother_phone_number }}<br> Ажил : {{
                    data.item.parent_mother_ajil_gazar }}
                </b-badge>
              </template>
              <template #cell(get_my_primary_child)="data">
                <b-badge variant="info" v-for="(stud, index) in data.item.get_my_primary_child" :key="stud.user_id">
                  {{ stud.student_last_name | capitalizeAndEkhniiVseg }}.
                  {{ stud.student_name }}. Анги: {{ stud.student_migrations[0].my_school_class_info['full_name'] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  ...
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <b-form-group label="Дөрвөн хүүхэдтэй гэр бүлийг шүүлт хийж харах" label-for="filter-input"
              label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="familyFourfilter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!familyFourfilter" @click="familyFourfilter = ''">Хайлтын утгыг
                    цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-pagination v-model="familyFourcurrentPage" :total-rows="familyFourTotalRows"
              :per-page="familyFourPerPage" align="fill" size="sm" class="my-0"></b-pagination>
            <b-table hover :items="familyFourChild" :fields="familyfields" :sort-by.sync="familyFoursortBy"
              :sort-desc.sync="familyFoursortDesc" responsive="sm" :filter="familyFourfilter"
              :filter-included-fields="familyFourfilterOn" :per-page="familyFourPerPage"
              :filter-ignored-fields="familyIgnoredFields" :current-page="familyFourcurrentPage">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(parent_father_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_father_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_father_name }} : Утас: {{ data.item.parent_father_phone_number }}<br> Ажил : {{
                    data.item.parent_father_ajil_gazar }}
                </b-badge>

              </template>
              <template #cell(parent_mother_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_mother_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_mother_name }} : Утас: {{ data.item.parent_mother_phone_number }}<br> Ажил : {{
                    data.item.parent_mother_ajil_gazar }}
                </b-badge>
              </template>
              <template #cell(get_my_primary_child)="data">
                <b-badge variant="info" v-for="(stud, index) in data.item.get_my_primary_child" :key="stud.user_id">
                  {{ stud.student_last_name | capitalizeAndEkhniiVseg }}.
                  {{ stud.student_name }}. Анги: {{ stud.student_migrations[0].my_school_class_info['full_name'] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  ...
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="small-box bg-white">
            <b-form-group label="Таван хүүхэдтэй гэр бүлийг шүүлт хийж харах" label-for="filter-input" label-cols-sm="3"
              label-align-sm="right" label-size="sm" class="mb-1">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="familyFivefilter" type="search"
                  placeholder="Бүх талбараар хайх боломжтой."></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!familyFivefilter" @click="familyFivefilter = ''">Хайлтын утгыг
                    цэвэрлэх</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-pagination v-model="familyFivecurrentPage" :total-rows="familyFiveTotalRows"
              :per-page="familyFivePerPage" align="fill" size="sm" class="my-0"></b-pagination>
            <b-table hover :items="familyFiveChild" :fields="familyfields" :sort-by.sync="familyFivesortBy"
              :sort-desc.sync="familyFivesortDesc" responsive="sm" :filter="familyFivefilter"
              :filter-included-fields="familyFivefilterOn" :per-page="familyFivePerPage"
              :filter-ignored-fields="familyIgnoredFields" :current-page="familyFivecurrentPage">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(parent_father_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_father_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_father_name }} : Утас: {{ data.item.parent_father_phone_number }}<br> Ажил : {{
                    data.item.parent_father_ajil_gazar }}
                </b-badge>

              </template>
              <template #cell(parent_mother_name)="data">
                <b-badge variant="primary">
                  {{ data.item.parent_mother_ovog | capitalizeAndEkhniiVseg }}.
                  {{ data.item.parent_mother_name }} : Утас: {{ data.item.parent_mother_phone_number }}<br> Ажил : {{
                    data.item.parent_mother_ajil_gazar }}
                </b-badge>
              </template>
              <template #cell(get_my_primary_child)="data">
                <b-badge variant="info" v-for="(stud, index) in data.item.get_my_primary_child" :key="stud.user_id">
                  {{ stud.student_last_name | capitalizeAndEkhniiVseg }}.
                  {{ stud.student_name }}. Анги: {{ stud.student_migrations[0].my_school_class_info['full_name'] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" @click="info(data.item, data.index, $event.target)" class="mr-1" variant="success">
                  ...
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      year_id: "",
      allUsers: [],
      allStudents: [],
      allSelectedStudents: [],

      //Эцэг эхчүүдийн мэдээлэл
      options: {
        chart: {
          id: "Эцэг эх",
        },
        title: {
          text: "Эцэг эх",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: "Жил",
          data: [], //жил
        },
      ],
      //   Эцэг эхчүүд холбогдоогүй суралцагчид
      dontConnectStudents: [],
      sortBy: "age",
      sortDesc: false,
      filter: null,
      filterOn: [],
      fields: [
        { key: "index", label: "№" },
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Нэр", sortable: true },
        { key: "email", label: "Имэйл", sortable: true },
        // { key: "profile_user_id", sortable: true },
        // { key: "school_class_id", sortable: false },
        {
          key: "school_class_full_name",
          label: "Анги, бүлэг",
          sortable: true,
        },
        { key: "actions", label: "Үйлдлүүд" },
      ],
      options1: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series1: [],

      // Эцэг эхийн хүүхдийн тоо
      options2: {
        chart: {
          id: "Хэдэн хүүхэдтэй гэр бүлийн статистик",
        },
        title: {
          text: "Гэр бүлийн тоо",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series2: [
        {
          name: "Гэр бүлийн тоо",
          data: [], //Гэр бүлийн тоо
        },
      ],

      // Эцэг эх(хүүхэд)
      familyOneChild: [],
      familyTwoChild: [],
      familyThreeChild: [],
      familyFourChild: [],
      familyFiveChild: [],

      familyOnefilter: "",
      familyTwofilter: "",
      familyThreefilter: "",
      familyFourfilter: "",
      familyFivefilter: "",

      familyOneTotalRows: 1,
      familyTwoTotalRows: 1,
      familyThreeTotalRows: 1,
      familyFourTotalRows: 1,
      familyFiveTotalRows: 1,

      familyOnecurrentPage: 1,
      familyTwocurrentPage: 1,
      familyThreecurrentPage: 1,
      familyFourcurrentPage: 1,
      familyFivecurrentPage: 1,

      familyOnePerPage: 20,
      familyOnesortBy: "age",
      familyOnesortDesc: false,
      familyOnefilter: null,
      familyOnefilterOn: [],

      familyTwoPerPage: 20,
      familyTwosortBy: "age",
      familyTwosortDesc: false,
      familyTwofilter: null,
      familyTwofilterOn: [],

      familyThreePerPage: 20,
      familyThreesortBy: "age",
      familyThreesortDesc: false,
      familyThreefilter: null,
      familyThreefilterOn: [],

      familyFourPerPage: 20,
      familyFoursortBy: "age",
      familyFoursortDesc: false,
      familyFourfilter: null,
      familyFourfilterOn: [],

      familyFivePerPage: 20,
      familyFivesortBy: "age",
      familyFivesortDesc: false,
      familyFivefilter: null,
      familyFivefilterOn: [],

      familyIgnoredFields: ['get_my_primary_child'],
      familyfields: [
        { key: "index", label: "№" },
        { key: "parent_father_name", label: "Аав", sortable: true },
        { key: "parent_mother_name", label: "Ээж", sortable: true },
        {
          key: "get_my_primary_child",
          label: "Хүүхдүүд",
          sortable: true,
        },
        { key: "actions", label: "Үйлдлүүд" },
      ],

    };
  },
  components: {
    Multiselect,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    years: function () {
      return this.$store.state.years;
    },
    // allComplaintsPercent() {
    //   let percent = (this.allComplaints / this.allComplaints) * 100;
    //   return percent.toFixed(1);
    // },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      //   console.log(newVal, oldVal);
      axios
        .post("/hr/allComplaintsStatisticInfoYear", {
          year_id: newVal,
        })
        .then((response) => { })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    getAllComplaint() {
      this.familyOneChild = [];
      this.familyTwoChild = [];
      this.familyThreeChild = [];
      this.familyFourChild = [];
      this.familyFiveChild = [];
      axios
        .get("/hr/getAllUsersParentStat")
        .then((response) => {
          // console.log(response.data);
          // this.allUsers = response.data.allUsers;
          this.allStudents = response.data.allStudents;
          // Эцэг эх
          this.dontConnectStudents = response.data.allUsersUnRegParentsStudentInfo;
          let new1DataCats = ["Эцэг эх", "Бүртгэлтэй", "Бүртгэлгүй"];
          let new1Data = [
            response.data.allParents,
            response.data.allUsersActRegParent,
            response.data.allUsersUnRegParent,
          ];
          new1DataCats.forEach((element) => {
            this.options.xaxis.categories.push(element);
          });
          // this.options.xaxis.categories = [new1DataCats];
          this.series = [{ data: new1Data }];
          //Эцэг эхийн хувь
          let new1DataCatsLebels = ["Бүртгэлтэй", "Бүртгэлгүй"];
          new1DataCatsLebels.forEach((element) => {
            this.options1.labels.push(element);
          });
          this.series1 = [
            response.data.allUsersActRegParent,
            response.data.allUsersUnRegParent,
          ];

          // Насны бүтэц эхлэл
          let one = [];
          let two = [];
          let three = [];
          let four = [];
          let five = [];

          response.data.allUsersActRegParentWithChild
            .forEach(element => {
              if (element.profile_parent.get_my_primary_child_count === 1) {
                one.push(element.profile_parent.get_my_primary_child_count)
                this.familyOneChild.push(element.profile_parent)
              } else if (element.profile_parent.get_my_primary_child_count === 2) {
                two.push(element.profile_parent.get_my_primary_child_count)
                this.familyTwoChild.push(element.profile_parent)
              } else if (element.profile_parent.get_my_primary_child_count === 3) {
                three.push(element.profile_parent.get_my_primary_child_count)
                this.familyThreeChild.push(element.profile_parent)
              } else if (element.profile_parent.get_my_primary_child_count === 4) {
                four.push(element.profile_parent.get_my_primary_child_count)
                this.familyFourChild.push(element.profile_parent)
              } else if (element.profile_parent.get_my_primary_child_count === 5) {
                five.push(element.profile_parent.get_my_primary_child_count)
                this.familyFiveChild.push(element.profile_parent)
              }
              // element.profile_parent.get_my_primary_child.forEach(element1 => {
              //   console.log(element1.student_name);
              // });
              // console.log(element.name)
            });
          this.familyOneTotalRows = this.familyOneChild.length;
          this.familyTwoTotalRows = this.familyTwoChild.length;
          this.familyThreeTotalRows = this.familyThreeChild.length;
          this.familyFourTotalRows = this.familyFourChild.length;
          this.familyFiveTotalRows = this.familyFiveChild.length;

          let childsData = ["1 хүүхэд", "2 хүүхэд", "3 хүүхэд", "4 хүүхэд", "5 хүүхэд"];
          let new2ChildData = [
            one.length, two.length, three.length, four.length, five.length
          ];
          childsData.forEach((element) => {
            this.options2.xaxis.categories.push(element);
          });
          // this.options.xaxis.categories = [childsData];
          this.series2 = [{ data: new2ChildData }];

        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    customStudentName({ pro_student_last_name, pro_student_name }) {
      return `${pro_student_last_name} ${pro_student_name} жил`;
    },
    info(item, index, button) {
      //   console.log("====>", item);
      //   console.log("====>", index);
      //   console.log("====>", button);
      //   this.infoModal.title = `Row index: ${index}`;
    },
    selectStudents() {
      console.log("select students")
      this.allSelectedStudents = [];
      this.allSelectedStudents = this.allStudents;
    },
    unselectStudents() {
      console.log("select students")
      this.allSelectedStudents = [];
    },
    updateParentStatus() {
      Swal.fire({
        title: "Статус шинэчлэх",
        text:
          "Эцэг эхийн эрхийн статусыг active болгох эсэх?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, шинэчлэх!",
        cancelButtonText: "Үгүй, Цуцлах"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .post("/hr/updateParentStatus", {
              students: this.allSelectedStudents
            })
            .then(response => {
              //   console.log(response.data);
              Swal.fire(
                "Статус шинэчлэгдлээ!",
                "Амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch(errors => {
              console.log(errors);
            });
        }
      });
    },
  },
  // beforeMount() {
  //   this.getAllComplaint();
  // },
  created() {
    this.getAllComplaint();
    Fire.$on("loadComplains", () => {
      this.getAllComplaint();
    });
  },
};
</script>

<style></style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
