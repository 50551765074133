<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Хичээл
            <span class="badge bg-success"
              >A: {{ examAverageCountA.length }}
            </span>
            <span class="badge bg-success"
              >B:{{ examAverageCountB.length }}</span
            >
            <span class="badge bg-info">C:{{ examAverageCountC.length }}</span>
            <span class="badge bg-info">D: {{ examAverageCountD.length }}</span>
            <span class="badge bg-danger"
              >F: {{ examAverageCountF.length }}</span
            >
          </h3>
          <h3 class="card-title">
            Хөгжүүлэх
            <span class="badge bg-success"
              >A: {{ examAverageQuarterCountA.length }}
            </span>
            <span class="badge bg-success"
              >B:{{ examAverageQuarterCountB.length }}</span
            >
            <span class="badge bg-info"
              >C:{{ examAverageQuarterCountC.length }}</span
            >
            <span class="badge bg-info"
              >D: {{ examAverageQuarterCountD.length }}</span
            >
            <span class="badge bg-danger"
              >F: {{ examAverageQuarterCountF.length }}</span
            >
          </h3>
          <h3 class="card-title">
            Өөрийн түүхээ бүтээсэн байдал
            <span class="badge bg-success"
              >A: {{ examAverageYearCountA.length }}
            </span>
            <span class="badge bg-success"
              >B:{{ examAverageYearCountB.length }}</span
            >
            <span class="badge bg-info"
              >C:{{ examAverageYearCountC.length }}</span
            >
            <span class="badge bg-info"
              >D: {{ examAverageYearCountD.length }}</span
            >
            <span class="badge bg-danger"
              >F: {{ examAverageYearCountF.length }}</span
            >
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 5px">№</th>
                <th>Овог Нэр</th>
                <th
                  v-b-popover.hover.top="
                    'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Зорилгоо нэгэн утгатай тодорхой болгож, хүлээгдэж буй үр дүнг дүрслэн төсөөлөх чадвар (“Ажлыг эхлэхдээ төгсгөлийг нь бод” дадал)'
                  "
                  title="Motivation & Intention"
                >
                  MoIn-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Цуглуулсан мэдээлэл бодитой эсэхийг нягтлах чадвар (“Долоо хэмжиж нэг огтол” дадал)'
                  "
                  title="Motivation & Intention"
                >
                  MoIn-2 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Өөрөө өөртэйгээ ярилцах, үйл ажиллагаагаа төлөвлөх чадвар (“Эзэн хичээвэл заяа хичээнэ” дадал)'
                  "
                  title="Attitude & Belief"
                >
                  AtBe-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Гүйцэтгэлээ хянах, өөр өөр арга замаар оролдлого хийж хүлээгдэж буй үр дүндээ хүрэх чадвар (“Оролдлого сайт оройд нь” дадал)'
                  "
                  title="Attitude & Belief"
                >
                  AtBe-2 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Зорилго, хэрэгжилт, үр дүнгийн талаарх бусдын санаа бодлыг сонсох чадвар (“Нөхрөө харж биеэ, сүүдрээ харж бүсээ” дадал)'
                  "
                  title="Behaviour & Worth"
                >
                  BeWo-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Бусдыг өөртөө итгүүлэх, хамтран ажиллах замаар тэдэнд хүлээн зөвшөөрөгдөх чадвар (“Дэм дэмэндээ дээс эрчиндээ” дадал)'
                  "
                  title="Behaviour & Worth"
                >
                  BeWo-2 (100%)
                </th>
                <th>Дүн(Хичээл)</th>
                <th>Дүн(Хөгжүүлэх)</th>
                <th>Дүн(Түүх)</th>
                <th colspan="2">
                  Дүн баталгаажуулах
                  <i
                    class="far fa-save btn-danger btn-xs"
                    @click.prevent="
                      saveStudentsFinalQuarter(changeSelectedLessonExamData)
                    "
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(examData, index) in changeSelectedLessonExamData"
                :key="examData.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{ examData.student_last_name[0]
                  }}{{ examData.student_last_name[1] }}.{{
                    examData.student_name
                  }}
                </td>
                <td>
                  {{ parseFloat(examData.exam1).toFixed(1) }}
                </td>
                <td>
                  {{ parseFloat(examData.exam2).toFixed(1) }}
                </td>
                <td>
                  {{ parseFloat(examData.exam3).toFixed(1) }}
                </td>
                <td>
                  {{ parseFloat(examData.exam4).toFixed(1) }}
                </td>
                <td>
                  {{ parseFloat(examData.exam5).toFixed(1) }}
                </td>
                <td>
                  {{ parseFloat(examData.exam6).toFixed(1) }}
                </td>
                <td>
                  <span
                    class="badge bg-success"
                    v-if="examData.examAverage > 0"
                  >
                    {{ examData.examAverage.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.examAverage) }}
                    )</span
                  >
                </td>
                <td>
                  <span
                    class="badge bg-success"
                    v-if="examData.examAverageQuarter > 0"
                  >
                    {{ examData.examAverageQuarter.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.examAverageQuarter) }}
                    )</span
                  >
                </td>
                <td>
                  <span
                    class="badge bg-success"
                    v-if="examData.examAverageYear > 0"
                  >
                    {{ examData.examAverageYear.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examData.examAverageYear) }}
                    )</span
                  >
                </td>
                <td>
                  <i
                    class="far fa-save btn-success btn-xs"
                    @click.prevent="saveStudentBehaveFinalQuarter(examData)"
                  ></i>
                </td>
                <td>
                  <div
                    class="text-center"
                    v-for="dataCheck in dataChecked"
                    :key="dataCheck.id"
                  >
                    <b-icon
                      v-if="
                        dataCheck.behavior != null &&
                        examData.grade_exam_student_id == dataCheck.student_id
                      "
                      icon="check-square"
                      scale="0.8"
                      variant="warning"
                    ></b-icon>
                    <b-badge
                      v-if="
                        dataCheck.behavior != null &&
                        examData.grade_exam_student_id == dataCheck.student_id
                      "
                      variant="light"
                      >{{ parseFloat(dataCheck.behavior).toFixed(1) }}</b-badge
                    >
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th style="width: 5px"></th>
                <th style="text-align: right">Дундаж</th>
                <th>
                  <span class="badge bg-warning" v-if="exam1CalsAvg > 0">
                    {{ exam1CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam2CalsAvg > 0">
                    {{ exam2CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam3CalsAvg > 0">
                    {{ exam3CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam4CalsAvg > 0">
                    {{ exam4CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam5CalsAvg > 0">
                    {{ exam5CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning" v-if="exam6CalsAvg > 0">
                    {{ exam6CalsAvg.toFixed(1) }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-success" v-if="examCalsAvgFull > 0">
                    {{ examCalsAvgFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgFull) }}
                    )</span
                  >
                </th>
                <th>
                  <span
                    class="badge bg-success"
                    v-if="examCalsAvgQuarterFull > 0"
                  >
                    {{ examCalsAvgQuarterFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgQuarterFull) }}
                    )</span
                  >
                </th>
                <th>
                  <span class="badge bg-success" v-if="examCalsAvgYearFull > 0">
                    {{ examCalsAvgYearFull.toFixed(1) }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgYearFull) }}
                    )</span
                  >
                </th>
              </tr>
              <tr>
                <th style="width: 5px">№</th>
                <th style="text-align: right">Үнэлгээ</th>
                <th
                  v-b-popover.hover.top="
                    'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Зорилгоо нэгэн утгатай тодорхой болгож, хүлээгдэж буй үр дүнг дүрслэн төсөөлөх чадвар (“Ажлыг эхлэхдээ төгсгөлийг нь бод” дадал)'
                  "
                  title="Motivation & Intention"
                >
                  MoIn-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Цуглуулсан мэдээлэл бодитой эсэхийг нягтлах чадвар (“Долоо хэмжиж нэг огтол” дадал)'
                  "
                  title="Motivation & Intention"
                >
                  MoIn-2 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Өөрөө өөртэйгээ ярилцах, үйл ажиллагаагаа төлөвлөх чадвар (“Эзэн хичээвэл заяа хичээнэ” дадал)'
                  "
                  title="Attitude & Belief"
                >
                  AtBe-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Гүйцэтгэлээ хянах, өөр өөр арга замаар оролдлого хийж хүлээгдэж буй үр дүндээ хүрэх чадвар (“Оролдлого сайт оройд нь” дадал)'
                  "
                  title="Attitude & Belief"
                >
                  AtBe-2 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Зорилго, хэрэгжилт, үр дүнгийн талаарх бусдын санаа бодлыг сонсох чадвар (“Нөхрөө харж биеэ, сүүдрээ харж бүсээ” дадал)'
                  "
                  title="Behaviour & Worth"
                >
                  BeWo-1 (100%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Бусдыг өөртөө итгүүлэх, хамтран ажиллах замаар тэдэнд хүлээн зөвшөөрөгдөх чадвар (“Дэм дэмэндээ дээс эрчиндээ” дадал)'
                  "
                  title="Behaviour & Worth"
                >
                  BeWo-2 (100%)
                </th>
                <th>Дүн(Үнэлгээ)</th>
                <th style="text-align: center">Үнэлгээ</th>
                <th style="text-align: center">Үнэлгээ</th>
              </tr>
              <tr>
                <th style="text-align: right">1</th>
                <th style="text-align: right">A</th>
                <th>{{ exam1CountA.length }}</th>
                <th>{{ exam2CountA.length }}</th>
                <th>{{ exam3CountA.length }}</th>
                <th>{{ exam4CountA.length }}</th>
                <th>{{ exam5CountA.length }}</th>
                <th>{{ exam6CountA.length }}</th>
                <th>{{ examAverageCountA.length }}</th>
                <th>{{ examAverageQuarterCountA.length }}</th>
                <th>{{ examAverageYearCountA.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">2</th>
                <th style="text-align: right">B</th>
                <th>{{ exam1CountB.length }}</th>
                <th>{{ exam2CountB.length }}</th>
                <th>{{ exam3CountB.length }}</th>
                <th>{{ exam4CountB.length }}</th>
                <th>{{ exam5CountB.length }}</th>
                <th>{{ exam6CountB.length }}</th>
                <th>{{ examAverageCountB.length }}</th>
                <th>{{ examAverageQuarterCountB.length }}</th>
                <th>{{ examAverageYearCountB.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">3</th>
                <th style="text-align: right">C</th>
                <th>{{ exam1CountC.length }}</th>
                <th>{{ exam2CountC.length }}</th>
                <th>{{ exam3CountC.length }}</th>
                <th>{{ exam4CountC.length }}</th>
                <th>{{ exam5CountC.length }}</th>
                <th>{{ exam6CountC.length }}</th>
                <th>{{ examAverageCountC.length }}</th>
                <th>{{ examAverageQuarterCountC.length }}</th>
                <th>{{ examAverageYearCountC.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">4</th>
                <th style="text-align: right">D</th>
                <th>{{ exam1CountD.length }}</th>
                <th>{{ exam2CountD.length }}</th>
                <th>{{ exam3CountD.length }}</th>
                <th>{{ exam4CountD.length }}</th>
                <th>{{ exam5CountD.length }}</th>
                <th>{{ exam6CountD.length }}</th>
                <th>{{ examAverageCountD.length }}</th>
                <th>{{ examAverageQuarterCountD.length }}</th>
                <th>{{ examAverageYearCountD.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">5</th>
                <th style="text-align: right">F</th>
                <th>{{ exam1CountF.length }}</th>
                <th>{{ exam2CountF.length }}</th>
                <th>{{ exam3CountF.length }}</th>
                <th>{{ exam4CountF.length }}</th>
                <th>{{ exam5CountF.length }}</th>
                <th>{{ exam6CountF.length }}</th>
                <th>{{ examAverageCountF.length }}</th>
                <th>{{ examAverageQuarterCountF.length }}</th>
                <th>{{ examAverageYearCountF.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">6</th>
                <th style="text-align: right">Success/Амжилт</th>
                <th v-if="exam1CountSuccess > 0">
                  {{ exam1CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam2CountSuccess > 0">
                  {{ exam2CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam3CountSuccess > 0">
                  {{ exam3CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam4CountSuccess > 0">
                  {{ exam4CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam5CountSuccess > 0">
                  {{ exam5CountSuccess.toFixed(1) }}
                </th>
                <th v-if="exam6CountSuccess > 0">
                  {{ exam6CountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageCountSuccess > 0">
                  {{ examAverageCountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageQuarterCountSuccess > 0">
                  {{ examAverageQuarterCountSuccess.toFixed(1) }}
                </th>
                <th v-if="examAverageYearCountSuccess > 0">
                  {{ examAverageYearCountSuccess.toFixed(1) }}
                </th>
              </tr>
              <tr>
                <th style="text-align: right">7</th>
                <th style="text-align: right">Quality/Чанар</th>
                <th v-if="exam1CountQuality > 0">
                  {{ exam1CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam2CountQuality > 0">
                  {{ exam2CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam3CountQuality > 0">
                  {{ exam3CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam4CountQuality > 0">
                  {{ exam4CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam5CountQuality > 0">
                  {{ exam5CountQuality.toFixed(1) }}
                </th>
                <th v-if="exam6CountQuality > 0">
                  {{ exam6CountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageCountQuality > 0">
                  {{ examAverageCountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageQuarterCountQuality > 0">
                  {{ examAverageQuarterCountQuality.toFixed(1) }}
                </th>
                <th v-if="examAverageYearCountQuality > 0">
                  {{ examAverageYearCountQuality.toFixed(1) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  props: ["selectedLessonExamData", "teacher_id", "course_id", "quarter_id"],
  data() {
    return {
      //Шалгах дата
      dataChecked: [],
      //props-оор орж ирсэн өгөгдлийг шинэчилж авна.
      changeSelectedLessonExamData: [],
      //   symbolGrade: "",
      exam1CountA: [],
      exam1CountB: [],
      exam1CountC: [],
      exam1CountD: [],
      exam1CountF: [],
      exam1Average: [],
      exam1CalsAvg: "",
      exam1CountSuccess: 0,
      exam1CountQuality: 0,
      exam2CountA: [],
      exam2CountB: [],
      exam2CountC: [],
      exam2CountD: [],
      exam2CountF: [],
      exam2Average: [],
      exam2CalsAvg: "",
      exam2Average: [],
      exam2CountSuccess: 0,
      exam2CountQuality: 0,
      exam3CountA: [],
      exam3CountB: [],
      exam3CountC: [],
      exam3CountD: [],
      exam3CountF: [],
      exam3Average: [],
      exam3CalsAvg: "",
      exam3CountSuccess: 0,
      exam3CountQuality: 0,
      exam4CountA: [],
      exam4CountB: [],
      exam4CountC: [],
      exam4CountD: [],
      exam4CountF: [],
      exam4Average: [],
      exam4CalsAvg: "",
      exam4CountSuccess: 0,
      exam4CountQuality: 0,
      exam5CountA: [],
      exam5CountB: [],
      exam5CountC: [],
      exam5CountD: [],
      exam5CountF: [],
      exam5Average: [],
      exam5CalsAvg: "",
      exam5CountSuccess: 0,
      exam5CountQuality: 0,
      exam6CountA: [],
      exam6CountB: [],
      exam6CountC: [],
      exam6CountD: [],
      exam6CountF: [],
      exam6Average: [],
      exam6CalsAvg: "",
      exam6CountSuccess: 0,
      exam6CountQuality: 0,

      examAverageCountA: [],
      examAverageCountB: [],
      examAverageCountC: [],
      examAverageCountD: [],
      examAverageCountF: [],
      examAverageFull: [],
      examCalsAvgFull: "",
      examAverageCountSuccess: 0,
      examAverageCountQuality: 0,

      examAverageQuarterCountA: [],
      examAverageQuarterCountB: [],
      examAverageQuarterCountC: [],
      examAverageQuarterCountD: [],
      examAverageQuarterCountF: [],
      examAverageQuarterFull: [],
      examCalsAvgQuarterFull: "",
      examAverageQuarterCountSuccess: 0,
      examAverageQuarterCountQuality: 0,

      examAverageYearCountA: [],
      examAverageYearCountB: [],
      examAverageYearCountC: [],
      examAverageYearCountD: [],
      examAverageYearCountF: [],
      examAverageYearFull: [],
      examCalsAvgYearFull: "",
      examAverageYearCountSuccess: 0,
      examAverageYearCountQuality: 0,
    };
  },
  computed: {},
  methods: {
    saveStudentBehaveFinalQuarter(examData) {
      //   console.log("Дүнг хадгалах", examData);
      Swal.fire({
        title: "Дүн баталгаажуулах?",
        text:
          examData.student_last_name[0].toUpperCase() +
          examData.student_last_name[1] +
          "." +
          examData.student_name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Дүнг баталгаажуулах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/confirmBehavior", { examData: examData })
            .then((response) => {
              //   console.log(response.data);
              // Fire.$emit("loadDatas");
            })
            .catch((errors) => {
              console.log(errors);
            });
          Swal.fire(
            "Амжилттай баталгаажууллаа!",
            "Сонгогдсон суралцагчын дүн баталгаажсан.",
            "success"
          );
        }
      });
    },
    saveStudentsFinalQuarter(examAllData) {
      //   console.log("Дүнг хадгалах", examAllData);
      Swal.fire({
        title: "Бүх суралцагчдын дүн баталгаажуулах?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Дүнг баталгаажуулах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/confirmAllBehavior", { examAllData: examAllData })
            .then((response) => {
              //   console.log(response.data);
              Fire.$emit("loadDatas");
            })
            .catch((errors) => {
              console.log(errors);
            });
          Swal.fire(
            "Амжилттай баталгаажууллаа!",
            "Сонгогдсон суралцагчдын дүн баталгаажсан.",
            "success"
          );
        }
      });
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return "A+, GPA 4.33 or 4.00";
      } else if (gradeValue >= 93) {
        return "A, GPA 4.00";
      } else if (gradeValue >= 90) {
        return "A-, GPA 3.67";
      } else if (gradeValue >= 87) {
        return "B+, GPA 3.33";
      } else if (gradeValue >= 83) {
        return "B, GPA 3.00";
      } else if (gradeValue >= 80) {
        return "B-, GPA 2.67";
      } else if (gradeValue >= 77) {
        return "C+, GPA 2.33";
      } else if (gradeValue >= 73) {
        return "C, GPA 2.00";
      } else if (gradeValue >= 70) {
        return "C-, GPA 1.67";
      } else if (gradeValue >= 67) {
        return "D+, GPA 1.33";
      } else if (gradeValue >= 63) {
        return "D, GPA 1.00";
      } else if (gradeValue >= 60) {
        return "D-, GPA 0.67";
      } else {
        return "F, GPA 0.00";
      }
    },
    countByMyExams() {
      this.changeSelectedLessonExamData = [...this.selectedLessonExamData];
      //   console.log(
      //     "childComp everyStudent props selectedLessonExamData",
      //     this.selectedLessonExamData
      //   );
      this.changeSelectedLessonExamData.forEach((el, index) => {
        this.exam1Average.push(el.exam1);
        this.exam2Average.push(el.exam2);
        this.exam3Average.push(el.exam3);
        this.exam4Average.push(el.exam4);
        this.exam5Average.push(el.exam5);
        this.exam6Average.push(el.exam6);
        this.examAverageFull.push(el.examAverage);
        this.examAverageQuarterFull.push(el.examAverageQuarter);
        this.examAverageYearFull.push(el.examAverageYear);

        if (el.exam1 >= 90) {
          this.exam1CountA.push(el.exam1);
        } else if (el.exam1 >= 80) {
          this.exam1CountB.push(el.exam1);
        } else if (el.exam1 >= 70) {
          this.exam1CountC.push(el.exam1);
        } else if (el.exam1 >= 60) {
          this.exam1CountD.push(el.exam1);
        } else {
          this.exam1CountF.push(el.exam1);
        }

        if (el.exam2 >= 90) {
          this.exam2CountA.push(el.exam2);
        } else if (el.exam2 >= 80) {
          this.exam2CountB.push(el.exam2);
        } else if (el.exam2 >= 70) {
          this.exam2CountC.push(el.exam2);
        } else if (el.exam2 >= 60) {
          this.exam2CountD.push(el.exam2);
        } else {
          this.exam2CountF.push(el.exam2);
        }

        if (el.exam3 >= 90) {
          this.exam3CountA.push(el.exam3);
        } else if (el.exam3 >= 80) {
          this.exam3CountB.push(el.exam3);
        } else if (el.exam3 >= 70) {
          this.exam3CountC.push(el.exam3);
        } else if (el.exam3 >= 60) {
          this.exam3CountD.push(el.exam3);
        } else {
          this.exam3CountF.push(el.exam3);
        }

        if (el.exam4 >= 90) {
          this.exam4CountA.push(el.exam4);
        } else if (el.exam4 >= 80) {
          this.exam4CountB.push(el.exam4);
        } else if (el.exam4 >= 70) {
          this.exam4CountC.push(el.exam4);
        } else if (el.exam4 >= 60) {
          this.exam4CountD.push(el.exam4);
        } else {
          this.exam4CountF.push(el.exam4);
        }

        if (el.exam5 >= 90) {
          this.exam5CountA.push(el.exam5);
        } else if (el.exam5 >= 80) {
          this.exam5CountB.push(el.exam5);
        } else if (el.exam5 >= 70) {
          this.exam5CountC.push(el.exam5);
        } else if (el.exam5 >= 60) {
          this.exam5CountD.push(el.exam5);
        } else {
          this.exam5CountF.push(el.exam5);
        }

        if (el.exam6 >= 90) {
          this.exam6CountA.push(el.exam6);
        } else if (el.exam6 >= 80) {
          this.exam6CountB.push(el.exam6);
        } else if (el.exam6 >= 70) {
          this.exam6CountC.push(el.exam6);
        } else if (el.exam6 >= 60) {
          this.exam6CountD.push(el.exam6);
        } else {
          this.exam6CountF.push(el.exam6);
        }

        if (el.examAverage >= 90) {
          this.examAverageCountA.push(el.examAverage);
        } else if (el.examAverage >= 80) {
          this.examAverageCountB.push(el.examAverage);
        } else if (el.examAverage >= 70) {
          this.examAverageCountC.push(el.examAverage);
        } else if (el.examAverage >= 60) {
          this.examAverageCountD.push(el.examAverage);
        } else {
          this.examAverageCountF.push(el.examAverage);
        }

        if (el.examAverageQuarter >= 90) {
          this.examAverageQuarterCountA.push(el.examAverageQuarter);
        } else if (el.examAverageQuarter >= 80) {
          this.examAverageQuarterCountB.push(el.examAverageQuarter);
        } else if (el.examAverageQuarter >= 70) {
          this.examAverageQuarterCountC.push(el.examAverageQuarter);
        } else if (el.examAverageQuarter >= 60) {
          this.examAverageQuarterCountD.push(el.examAverageQuarter);
        } else {
          this.examAverageQuarterCountF.push(el.examAverageQuarter);
        }

        if (el.examAverageYear >= 90) {
          this.examAverageYearCountA.push(el.examAverageYear);
        } else if (el.examAverageYear >= 80) {
          this.examAverageYearCountB.push(el.examAverageYear);
        } else if (el.examAverageYear >= 70) {
          this.examAverageYearCountC.push(el.examAverageYear);
        } else if (el.examAverageYear >= 60) {
          this.examAverageYearCountD.push(el.examAverageYear);
        } else {
          this.examAverageYearCountF.push(el.examAverageYear);
        }
      });
      //Exam1 average утгыг бодож олъё.
      var exam1Sum = 0;
      for (var i = 0; i < this.exam1Average.length; i++) {
        exam1Sum += parseFloat(this.exam1Average[i]); //don't forget to add the base
      }
      this.exam1CalsAvg = exam1Sum / this.exam1Average.length;
      // console.log(this.exam1CalsAvg);
      //Exam2 average утгыг бодож олъё.
      var exam2Sum = 0;
      for (var i = 0; i < this.exam2Average.length; i++) {
        exam2Sum += parseFloat(this.exam2Average[i]); //don't forget to add the base
      }
      this.exam2CalsAvg = exam2Sum / this.exam2Average.length;
      // console.log(this.exam2CalsAvg);
      //Exam3 average утгыг бодож олъё.
      var exam3Sum = 0;
      for (var i = 0; i < this.exam3Average.length; i++) {
        exam3Sum += parseFloat(this.exam3Average[i]); //don't forget to add the base
      }
      this.exam3CalsAvg = exam3Sum / this.exam3Average.length;
      // console.log(this.exam3CalsAvg);
      //Exam4 average утгыг бодож олъё.
      var exam4Sum = 0;
      for (var i = 0; i < this.exam4Average.length; i++) {
        exam4Sum += parseFloat(this.exam4Average[i]); //don't forget to add the base
      }
      this.exam4CalsAvg = exam4Sum / this.exam4Average.length;
      // console.log(this.exam4CalsAvg);
      //Exam5 average утгыг бодож олъё.
      var exam5Sum = 0;
      for (var i = 0; i < this.exam5Average.length; i++) {
        exam5Sum += parseFloat(this.exam5Average[i]); //don't forget to add the base
      }
      this.exam5CalsAvg = exam5Sum / this.exam5Average.length;
      // console.log(this.exam5CalsAvg);
      //Exam6 average утгыг бодож олъё.
      var exam6Sum = 0;
      for (var i = 0; i < this.exam6Average.length; i++) {
        exam6Sum += parseFloat(this.exam6Average[i]); //don't forget to add the base
      }
      this.exam6CalsAvg = exam6Sum / this.exam6Average.length;
      // console.log(this.exam6CalsAvg);
      // console.log(this.exam8CalsAvg);
      //Exam нийлбэр average утгыг бодож олъё.
      var examSum = 0;
      for (var i = 0; i < this.examAverageFull.length; i++) {
        examSum += parseFloat(this.examAverageFull[i]); //don't forget to add the base
      }
      this.examCalsAvgFull = examSum / this.examAverageFull.length;
      // console.log(this.exam1CalsAvg);

      //ExamAverageQuarter нийлбэр average утгыг бодож олъё.
      var examQuarterSum = 0;
      for (var i = 0; i < this.examAverageQuarterFull.length; i++) {
        examQuarterSum += parseFloat(this.examAverageQuarterFull[i]); //don't forget to add the base
      }
      this.examCalsAvgQuarterFull =
        examQuarterSum / this.examAverageQuarterFull.length;

      //ExamAverageYear нийлбэр average утгыг бодож олъё.
      var examYearSum = 0;
      for (var i = 0; i < this.examAverageYearFull.length; i++) {
        examYearSum += parseFloat(this.examAverageYearFull[i]); //don't forget to add the base
      }
      this.examCalsAvgYearFull = examYearSum / this.examAverageYearFull.length;

      //Exam1 амжилт чанар
      this.exam1CountSuccess =
        ((this.exam1CountA.length +
          this.exam1CountB.length +
          this.exam1CountC.length +
          this.exam1CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam1CountQuality =
        ((this.exam1CountA.length + this.exam1CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam2 амжилт чанар
      this.exam2CountSuccess =
        ((this.exam2CountA.length +
          this.exam2CountB.length +
          this.exam2CountC.length +
          this.exam2CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam2CountQuality =
        ((this.exam2CountA.length + this.exam2CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam3 амжилт чанар
      this.exam3CountSuccess =
        ((this.exam3CountA.length +
          this.exam3CountB.length +
          this.exam3CountC.length +
          this.exam3CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam3CountQuality =
        ((this.exam3CountA.length + this.exam3CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam4 амжилт чанар
      this.exam4CountSuccess =
        ((this.exam4CountA.length +
          this.exam4CountB.length +
          this.exam4CountC.length +
          this.exam4CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam4CountQuality =
        ((this.exam4CountA.length + this.exam4CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam5 амжилт чанар
      this.exam5CountSuccess =
        ((this.exam5CountA.length +
          this.exam5CountB.length +
          this.exam5CountC.length +
          this.exam5CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam5CountQuality =
        ((this.exam5CountA.length + this.exam5CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam6 амжилт чанар
      this.exam6CountSuccess =
        ((this.exam6CountA.length +
          this.exam6CountB.length +
          this.exam6CountC.length +
          this.exam6CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam6CountQuality =
        ((this.exam6CountA.length + this.exam6CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;

      //Ерөнхий амжилт чанар
      this.examAverageCountSuccess =
        ((this.examAverageCountA.length +
          this.examAverageCountB.length +
          this.examAverageCountC.length +
          this.examAverageCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageCountQuality =
        ((this.examAverageCountA.length + this.examAverageCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар улирлын
      this.examAverageQuarterCountSuccess =
        ((this.examAverageQuarterCountA.length +
          this.examAverageQuarterCountB.length +
          this.examAverageQuarterCountC.length +
          this.examAverageQuarterCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageQuarterCountQuality =
        ((this.examAverageQuarterCountA.length +
          this.examAverageQuarterCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар жилийн
      this.examAverageYearCountSuccess =
        ((this.examAverageYearCountA.length +
          this.examAverageYearCountB.length +
          this.examAverageYearCountC.length +
          this.examAverageYearCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageYearCountQuality =
        ((this.examAverageYearCountA.length +
          this.examAverageYearCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
    },
  },
  beforeMount() {
    this.countByMyExams();
    // console.log(this.selectedLessonExamData);

    axios
      .post("/manager/checkConfirmBehaviorStud", {
        checkData: this.selectedLessonExamData,
        teacher_id: this.teacher_id,
        course_id: this.course_id,
        quarter_id: this.quarter_id,
      })
      .then((response) => {
        this.dataChecked = response.data.dataChecked;
        // console.log(response.data);
      })
      .catch((errors) => {});
  },
  created() {
    Fire.$on("loadDatas", () => {
      axios
        .post("/manager/checkConfirmBehaviorStud", {
          checkData: this.selectedLessonExamData,
          teacher_id: this.teacher_id,
          course_id: this.course_id,
          quarter_id: this.quarter_id,
        })
        .then((response) => {
          this.dataChecked = response.data.dataChecked;
        })
        .catch((errors) => {});
    });
  },
};
</script>
<style scoped>
/* <span class="badge bg-info"> */
/* table {
  font-size: "50%";
} */
</style>
