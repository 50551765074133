<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card" style="overflow-x: auto">
        <div class="card-header">
          <h3 class="card-title">Миний үүсгэсэн шалгалтууд</h3>
        </div>
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="font-size: 80%">№</th>
                <th style="font-size: 80%">Улирал</th>
                <th style="font-size: 80%">Хичээл</th>
                <th style="font-size: 80%">Шалгалтын онолын загвар</th>
                <th style="font-size: 80%">Шалгалтын төрөл</th>
                <th style="font-size: 80%">Шалгалтын нэр</th>
                <th style="font-size: 80%">Календар төлөвлөгөөний агуулга</th>
                <th style="font-size: 80%">Тайлбар</th>
                <th style="font-size: 80%">Үүсгэсэн</th>
                <th style="font-size: 80%">Зассан</th>
                <th style="font-size: 80%">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(getAllExam, index) in getAllTeachersExams"
                :key="getAllExam.id"
              >
                <td style="font-size: 80%">
                  {{ index + 1 }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.quart_name }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.subj_short_name }}
                </td>
                <td style="font-size: 80%">
                  <b-badge
                    variant="success"
                    v-if="getAllExam.exam_type_status == 0"
                  >
                    ББЗ цикл
                  </b-badge>
                  <b-badge
                    variant="info"
                    v-if="getAllExam.exam_type_status == 1"
                  >
                    Нээлттэй шалгалт
                  </b-badge>
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.exam_type }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.name }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.calendar_plan_content }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.description }}
                </td>

                <td style="font-size: 80%">
                  {{ getAllExam.created_at | dateYearMonthDay }}
                </td>
                <td style="font-size: 80%">
                  {{ getAllExam.updated_at | dateYearMonthDay }}
                </td>
                <td style="font-size: 80%">
                  <!-- <a
                      href="#"
                      class="badge bg-purple"
                      @click="viewExam(getAllExam)"
                      ><i class="far fa-eye"></i
                    ></a> -->
                  <a
                    v-show="getAllExam.exam_type === 'явц'"
                    href="#"
                    class="badge bg-warning"
                    @click="editExam(getAllExam)"
                    ><i class="fas fa-edit"></i
                  ></a>
                  <!-- <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteExam(getAllExam)"
                      ><i class="fas fa-trash-alt"></i
                    ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Шалгалт бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Шалгалт засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="!editMode ? createExam() : updateExam()">
          <div class="card-body">
            <div class="form-group">
              <multiselect
                v-model="form.course_id"
                deselect-label="Can't remove this value"
                track-by="id"
                label="id"
                :custom-label="nameCourses"
                placeholder="Хичээл сонгох"
                :options="courses"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.full_name }} {{ option.subject_name }}</strong
                  >
                  сонгогдлоо
                </template>
              </multiselect>
            </div>
            <div class="form-group">
              <multiselect
                v-model="form.quarter_id"
                deselect-label="Can't remove this value"
                track-by="name"
                label="name"
                :custom-label="nameQuarter"
                placeholder="Улирал сонгох"
                :options="quarters"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ option.name }}</strong> -р улирал сонгогдлоо
                </template>
              </multiselect>
            </div>
            <div class="form-group">
              <multiselect
                v-model="form.exam_type"
                deselect-label="Can't remove this value"
                track-by="text"
                label="text"
                :custom-label="nameExamType"
                placeholder="Шалгалтын төрөл сонгох"
                :options="options"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  >Шалгалтын <strong>{{ option.value }}</strong> төрөл
                  сонгогдлоо
                </template>
              </multiselect>
              <has-error :form="form" field="exam_type"></has-error>
            </div>
            <div class="form-group">
              <label>Шалгалтын нэр үүсгэхдээ доорхыг анхаарна уу?</label>
              <input
                type="text"
                name="name"
                v-model="form.name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('name'),
                  },
                ]"
                placeholder="Улирлын нэр: Шалгалтын сэдэв: Шалгалтын нэр"
              />
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <b-form-textarea
                id="textarea"
                v-model="form.calendar_plan_content"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('calendar_plan_content'),
                  },
                ]"
                placeholder="Календар төлөвлөгөөний харгалзах агуулга"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <has-error :form="form" field="calendar_plan_content"></has-error>
            </div>
            <div class="form-group">
              <b-form-textarea
                id="textarea"
                v-model="form.description"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('description'),
                  },
                ]"
                placeholder="Тайлбар"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <has-error :form="form" field="description"></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="!editMode">
              Хадгалах
            </button>
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Засах
            </button>
            <button
              class="btn btn-warning"
              v-show="editMode"
              @click.prevent="cancelSubmit"
            >
              Цуцлах
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      getAllTeachersExams: [],
      options: [
        { value: "явц", text: "Явц" },
        // { value: "улирал", text: "Улирал" },
        // { value: "жил", text: "Жил" },
      ],
      courses: [],
      quarters: [],
      form: new Form({
        id: "",
        teacher_id: "",
        course_id: "",
        quarter_id: "",
        name: "",
        exam_type: null,
        calendar_plan_content: "",
        description: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllExams() {
      axios
        .get("/teacher/getAllExams")
        .then((response) => {
          //   console.log(response);
          this.getAllTeachersExams = response.data.getAllExams;
          this.courses = response.data.courses;
          this.quarters = response.data.schoolquarters;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    nameQuarter({ name }) {
      return `${name} — р улирал`;
    },
    nameExamType({ value }) {
      return `Шалгалтын ${value} төрөл сонгогдлоо`;
    },
    nameCourses({ subject_name, full_name }) {
      return `${subject_name} - ${full_name}`;
    },
    viewExam(position) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + position);
    },
    editExam(exam) {
      //   console.log(exam);
      this.form.reset();
      this.form.id = exam.id;
      let myCourseId = this.courses.filter((el) => el.id == exam.course_id);
      //   console.log(myCourseId);
      this.form.course_id = myCourseId[0];
      let myQuarterId = this.quarters.filter((el) => el.id == exam.quarter_id);
      this.form.quarter_id = myQuarterId[0];
      let myExamType = this.options.filter((el) => el.value == exam.exam_type);
      this.form.exam_type = myExamType[0];
      let checkName = exam.name.split("(")[0];
      //   console.log(checkName);
      //   console.log(checkName[0]);

      this.form.name = checkName;
      this.form.calendar_plan_content = exam.calendar_plan_content;
      this.form.description = exam.description;
      this.editMode = true;
      //   this.form.fill(exam);
    },
    updateExam() {
      this.action = "Шалгалтын мэдээллийг шинэчилж байна...";
      this.form
        .put("/teacher/exam/" + this.form.id)
        .then((response) => {
          this.$toastr.s(
            "Шалгалтын мэдээллийг амжилттай шинэчиллээ.",
            "Шинэчилсэн"
          );
          Fire.$emit("loadExams");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch();
    },
    deleteExam(exam) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + exam);
    },
    createExam() {
      this.action = "Шалгалт үүсгэж байна....";
      this.form
        .post("/teacher/exam")
        .then((response) => {
          this.$toastr.s("Шалгалт амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadExams");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Шалгалт үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelSubmit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllExams();
    Fire.$on("loadExams", () => {
      this.getAllExams();
    });
  },
};
</script>

<style></style>
