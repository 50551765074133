<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#book" data-toggle="tab"
                >Номын ангилал</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#myBook" data-toggle="tab"
                >Ном сурах бичиг</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="book">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <b-container class="bv-example-row" fluid>
                    <b-row>
                      <b-col sm="3">
                        <b-form-group
                          label="Ангиар шүүх:"
                          label-for="class_number"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <b-form-select
                            v-model="classNumber"
                            :options="classNumbers"
                            text-field="class_text"
                            value-field="class_number"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('class_number'),
                              },
                            ]"
                            @input="getLibraryBooks()"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          label="Хичээлээр шүүх:"
                          label-for="subj.id"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <multiselect
                            v-model="subj_id"
                            deselect-label="Can't remove this value"
                            track-by="id"
                            label="subject_name"
                            placeholder="Хичээлийн нэр сонгоно уу"
                            :options="subj"
                            :searchable="true"
                            :allow-empty="false"
                            @input="getLibraryBooks()"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>{{ option.subject_name }}</strong>
                            </template>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group
                          label="Төрлөөр шүүх:"
                          label-for="type"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <b-form-select
                            v-model="type_id"
                            :options="types"
                            text-field="type"
                            value-field="type"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('type'),
                              },
                            ]"
                            @input="getLibraryBooks()"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="2">
                        <b-button
                          size="sm"
                          class="float-right"
                          variant="success"
                          @click.prevent="createLibraryBook()"
                          >Номын ангилал бүртгэх</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </div>
              <pagination
                :data="books"
                @pagination-change-page="getLibraryBooks"
                :limit="5"
                size="small"
                align="center"
              >
                <span slot="prev-nav">&lt; Өмнөх</span>
                <span slot="next-nav">Дараах &gt;</span>
              </pagination>
              <b-overlay :show="bookIsLoaded" rounded="sm">
                <b-card-group deck class="p-3 my-5">
                  <div
                    class="col-lg-3 mb-3"
                    v-for="book in books.data"
                    :key="book.id"
                  >
                    <div class="ribbon ribbon-bottom-right"></div>
                    <div class="card-container">
                      <div class="box">
                        <img
                          :src="book.image"
                          alt="Avatar"
                          class="image-card"
                        />
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">
                          <small class="text-muted">
                            {{ book.name.slice(0, 30) }}
                          </small>
                        </h5>
                        <p
                          class="card-text"
                          style="
                            text-align: justify;
                            height: 0.8rem;
                            word-break: break-all;
                          "
                        >
                          <small
                            class="text-muted d-flex justify-content-between"
                          >
                            <span>
                              Анги:
                              <b>
                                <span> {{ book.class_number }} -р анги </span>
                              </b>
                            </span>
                            <span>
                              Үнэ:
                              <b>
                                <span>
                                  {{ book.price }}
                                </span>
                              </b>
                            </span>
                          </small>
                        </p>
                      </div>
                      <div class="card-footer">
                        <small class="text-muted">
                          <i class="far fa-calendar-check ml-2"></i>
                          <b>{{ book.created_at | dateYearMonthDay }}</b>
                        </small>
                        <b-badge variant="light" class="float-right">
                          <a
                            href="#"
                            class="badge bg-success"
                            @click="connectUser(book)"
                            >Хэрэглэгч холбох</a
                          >
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editBook(book)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="removeBook(book)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </b-card-group>
              </b-overlay>
              <b-modal
                size="xl"
                id="modal-library-Books"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                :title="
                  editMode === false
                    ? 'Номын ангилал бүртгэх'
                    : 'Номын ангилал засах'
                "
                class="modal fade"
                hide-footer
                @hide="cancelBook"
              >
                <div class="modal-body">
                  <form
                    class="form-horizontal"
                    wtx-context="9944B6A2-0454-4588-82D8-836F0401FA6F"
                  >
                    <div>
                      <!-- <b-alert show variant="warning">
                        <h4 class="alert-heading">Анхаарах зүйлс!</h4>
                        <p>
                          Сурах бичгийн хавтас гэмтэлгүй байх, Суралцагчийн нэрийг
                          арилгах, Сурах бичгийн хуудас бүрэн байх, Сурах бичиг дээр
                          бичсэн зүйлсийг бүрэн арилгах, Work book дээр бичиж тэмдэглснээ
                          цэвэрхэн арилгаж дахин хэрэглэх боломжтой болгосон байх
                        </p>
                        <hr />
                        <p class="mb-0">
                          Та зар оруулахдаа анхаарах зүйлсийг зөрчихгүй байж бусдад
                          хүндрэл учруулахгүй байхыг хүсье.
                        </p>
                      </b-alert> -->
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-sm-3 col-form-label"
                        >Номны нэр:</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Номны нэр бичнэ үү."
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="class_number" class="col-sm-3 col-form-label"
                        >Анги:</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="form.class_number"
                          deselect-label="Can't remove this value"
                          track-by="class_number"
                          label="class_text"
                          placeholder="Хэддүгээр ангид хамааралтай ном болохыг сонгоно уу"
                          :options="classNumbers"
                          :searchable="true"
                          :allow-empty="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.class_text }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="subj" class="col-sm-3 col-form-label"
                        >Хичээл:</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="form.subj_id"
                          deselect-label="Can't remove this value"
                          track-by="id"
                          label="subject_name"
                          placeholder="Хичээлийн нэр сонгоно уу"
                          :options="subj"
                          :searchable="true"
                          :allow-empty="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.subject_name }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="subj" class="col-sm-3 col-form-label"
                        >Номын сан:</label
                      >
                      <div class="col-sm-9">
                        <b-form-select
                          v-model="form.library_id"
                          :options="library"
                          text-field="name"
                          value-field="id"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': form.errors.has('library_id'),
                            },
                          ]"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="types" class="col-sm-3 col-form-label"
                        >Төрөл:</label
                      >
                      <div class="col-sm-9">
                        <b-form-select
                          v-model="form.type"
                          :options="types"
                          text-field="type"
                          value-field="type"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': form.errors.has('type'),
                            },
                          ]"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="isbn" class="col-sm-3 col-form-label"
                        >ISBN дугаар:</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="number"
                          class="form-control"
                          id="isbn"
                          placeholder="ISBN дугаар"
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.isbn"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="price" class="col-sm-3 col-form-label"
                        >Үнэ:</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          placeholder="Номны үнэ"
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.price"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-sm-3 col-form-label"
                        >Тайлбар:</label
                      >
                      <div class="col-sm-9">
                        <b-form-textarea
                          id="textarea"
                          v-model="form.description"
                          placeholder="Номтой холбоотой дэлгэрэнгүй мэдээлэл..."
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="imgBook" class="col-sm-3 col-form-label"
                        >Номны зураг</label
                      >
                      <div class="col-sm-9">
                        <div class="row">
                          <span class="col-sm-2 direct-chat-name float-left">
                            <img
                              v-show="imgLoaded"
                              class="profile-user-img img-fluid attachment-img mr-2"
                              :src="imgUrl"
                              alt="Төслийн зураг"
                              accept="image/*"
                              v-if="imgUrl !== ''"
                            />
                            <img
                              class="profile-user-img img-fluid attachment-img"
                              :src="defaultimage"
                              alt="Багшийн зураг"
                              accept="image/*"
                              v-else
                            />
                          </span>
                          <b-form-file
                            id="imgBook"
                            browse-text="Зураг сонгох"
                            class="col-sm-5"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('imgBook'),
                              },
                            ]"
                            @change="getImg"
                          ></b-form-file>
                          <span class="col-sm-5">
                            {{ imgError }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer justify-content-between">
                  <b-button
                    @click="storeBook()"
                    v-if="!editMode"
                    variant="success"
                  >
                    Хадгалах
                  </b-button>
                  <b-button
                    @click="updateBook()"
                    v-if="editMode"
                    variant="warning"
                  >
                    Засах
                  </b-button>
                  <b-button @click="cancelBook()" variant="danger">
                    Хаах
                  </b-button>
                </div>
              </b-modal>
              <b-modal
                size="xl"
                id="modal-userBook"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                title="Хэрэглэгч холбох"
                class="modal fade"
                hide-footer
                @hide="cancelBook"
              >
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <input
                        type="number"
                        class="form-control"
                        v-model="qrCode"
                        placeholder="QR кодоор хайх"
                        @input="getUsers(bookId)"
                      />
                      <div class="card">
                        <div class="card-header">
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                href="#library"
                                data-toggle="tab"
                                >Номын сан</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                href="#student"
                                data-toggle="tab"
                                >Сурагч</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                href="#employee"
                                data-toggle="tab"
                                >Ажилтан</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="card-body table-responsive">
                          <div class="tab-content">
                            <div class="tab-pane active" id="library">
                              <b-overlay :show="isLoaded" rounded="sm">
                                <table class="table text-center">
                                  <tbody>
                                    <tr role="row">
                                      <th>№</th>
                                      <th>Хэрэглэгчийн төрөл</th>
                                      <th>Эзэмшиж буй төрөл</th>
                                      <th>QR код</th>
                                      <th>Огноо</th>
                                      <th>Үйлдэл</th>
                                    </tr>
                                    <tr
                                      v-for="(user, index) in libraryBook"
                                      :key="user.id"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ user.user_type }}</td>
                                      <td>{{ user.status }}</td>
                                      <td>{{ user.qr_code }}</td>
                                      <td>
                                        {{ user.created_at | dateYearMonthDay }}
                                      </td>
                                      <td>
                                        <b-badge
                                          variant="light"
                                          class="float-right"
                                        >
                                          <a
                                            href="#"
                                            class="badge bg-primary"
                                            @click="editUser(user)"
                                            ><i class="fa fa-edit"></i
                                          ></a>
                                          <a
                                            href="#"
                                            class="badge bg-danger"
                                            @click="removeUser(user)"
                                            ><i class="fa fa-minus"></i
                                          ></a>
                                        </b-badge>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-overlay>
                            </div>
                            <div class="tab-pane" id="student">
                              <b-overlay :show="isLoaded" rounded="sm">
                                <table class="table text-center">
                                  <tbody>
                                    <tr role="row">
                                      <th>№</th>
                                      <th>Хэрэглэгчийн төрөл</th>
                                      <th>Эзэмшигчийн нэр</th>
                                      <th>Эзэмшиж буй төрөл</th>
                                      <th>QR код</th>
                                      <th>Огноо</th>
                                      <th>Үйлдэл</th>
                                    </tr>
                                    <tr
                                      v-for="(user, index) in students"
                                      :key="user.id"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ user.user_type }}</td>
                                      <td v-if="user.student">
                                        {{ user.student.student_last_name[0] }}.
                                        {{ user.student.student_name }}
                                      </td>
                                      <td v-else>{{ user.user_type }}</td>
                                      <td>{{ user.status }}</td>
                                      <td>{{ user.qr_code }}</td>
                                      <td>
                                        {{ user.created_at | dateYearMonthDay }}
                                      </td>
                                      <td>
                                        <b-badge
                                          variant="light"
                                          class="float-right"
                                        >
                                          <a
                                            href="#"
                                            class="badge bg-primary"
                                            @click="editUser(user)"
                                            ><i class="fa fa-edit"></i
                                          ></a>
                                          <a
                                            href="#"
                                            class="badge bg-danger"
                                            @click="removeUser(user)"
                                            ><i class="fa fa-minus"></i
                                          ></a>
                                        </b-badge>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-overlay>
                            </div>
                            <div class="tab-pane" id="employee">
                              <b-overlay :show="isLoaded" rounded="sm">
                                <table class="table text-center">
                                  <tbody>
                                    <tr role="row">
                                      <th>№</th>
                                      <th>Хэрэглэгчийн төрөл</th>
                                      <th>Эзэмшигчийн нэр</th>
                                      <th>Эзэмшиж буй төрөл</th>
                                      <th>QR код</th>
                                      <th>Огноо</th>
                                      <th>Үйлдэл</th>
                                    </tr>
                                    <tr
                                      v-for="(user, index) in employees"
                                      :key="user.id"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ user.user_type }}</td>
                                      <td v-if="user.employee">
                                        {{
                                          user.employee.teacher_last_name[0]
                                        }}.
                                        {{ user.employee.teacher_name }}
                                      </td>
                                      <td v-else>{{ user.user_type }}</td>
                                      <td>{{ user.status }}</td>
                                      <td>{{ user.qr_code }}</td>
                                      <td>
                                        {{ user.created_at | dateYearMonthDay }}
                                      </td>
                                      <td>
                                        <b-badge
                                          variant="light"
                                          class="float-right"
                                        >
                                          <a
                                            href="#"
                                            class="badge bg-primary"
                                            @click="editUser(user)"
                                            ><i class="fa fa-edit"></i
                                          ></a>
                                          <a
                                            href="#"
                                            class="badge bg-danger"
                                            @click="removeUser(user)"
                                            ><i class="fa fa-minus"></i
                                          ></a>
                                        </b-badge>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-overlay>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title" v-if="!editModeUser">
                            Хэрэглэгч нэмэх
                          </h3>
                          <h3 class="card-title" v-if="editModeUser">
                            Хэрэглэгч солих
                          </h3>
                        </div>
                        <div class="card-body">
                          <div class="form-group row">
                            <label for="userType">Хэрэглэгчийн төрөл:</label>

                            <b-form-select
                              v-model="formUser.user_type"
                              :options="userType"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid':
                                    formUser.errors.has('user_type'),
                                },
                              ]"
                              @input="userInfo()"
                            ></b-form-select>
                          </div>
                          <div
                            class="form-group row"
                            v-if="formUser.user_type != 'Номын сан'"
                          >
                            <label for="userType">Хэрэглэгчийн нэр:</label>

                            <multiselect
                              v-model="formUser.user_id"
                              deselect-label="Can't remove this value"
                              track-by="user_id"
                              :custom-label="customName"
                              placeholder="Хэрэглэгчийн нэрийг сонгоно уу"
                              :options="userOptions"
                              :searchable="true"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong v-if="teacher_last_name"
                                  >{{ option.teacher_last_name }}.{{
                                    option.teacher_name
                                  }}</strong
                                ><strong v-else-if="student_last_name"
                                  >{{ option.student_last_name }}.{{
                                    option.student_name
                                  }}</strong
                                >
                              </template>
                            </multiselect>
                          </div>
                          <div class="form-group row">
                            <label for="qr_code">QR code:</label>

                            <input
                              type="number"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': formUser.errors.has('qr_code'),
                                },
                              ]"
                              id="qr"
                              placeholder="QR code"
                              wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                              v-model="formUser.qr_code"
                            />
                            <span v-if="formUser.qr_code == ''"
                              >QR код оруулна уу!</span
                            >
                            <span v-else-if="formUser.errors.has('qr_code')"
                              >QR код давхацсан байна!</span
                            >
                          </div>
                          <div class="form-group row">
                            <label for="status">Төрөл:</label>

                            <b-form-select
                              v-model="formUser.status"
                              :options="status"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': formUser.errors.has('status'),
                                },
                              ]"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="card-footer">
                          <b-button
                            @click="createBookUser()"
                            v-if="!editModeUser"
                            variant="success"
                          >
                            Хадгалах
                          </b-button>
                          <b-button
                            @click="updateUser()"
                            v-if="editModeUser"
                            variant="warning"
                          >
                            Засах
                          </b-button>
                          <b-button @click="cancelUser()" variant="danger">
                            Хаах
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
            <!-- <div class="tab-pane" id="myBook">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <b-container class="bv-example-row" fluid>
                    <b-row>
                      <b-col sm="3">
                        <b-form-group
                          label="Ангиар шүүх:"
                          label-for="class_number"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <b-form-select
                            v-model="classNumber"
                            :options="classNumbers"
                            text-field="class_text"
                            value-field="class_number"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('class_number'),
                              },
                            ]"
                            @input="getBooks()"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group
                          label="Хичээлээр шүүх:"
                          label-for="class_number"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <b-form-select
                            v-model="classNumber"
                            :options="classNumbers"
                            text-field="class_text"
                            value-field="class_number"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('class_number'),
                              },
                            ]"
                            @input="getBooks()"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group
                          label="Төрлөөр шүүх:"
                          label-for="class_number"
                          label-cols-sm="5"
                          label-align-sm="right"
                        >
                          <b-form-select
                            v-model="classNumber"
                            :options="classNumbers"
                            text-field="class_text"
                            value-field="class_number"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('class_number'),
                              },
                            ]"
                            @input="getBooks()"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="9">
                        <b-button
                          size="sm"
                          class="float-right"
                          variant="success"
                          @click.prevent="createBook()"
                          >Ном бүртгэх</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </div>
              <b-modal
                size="xl"
                id="modal-Books"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                :title="editMode === false ? 'Ном бүртгэх' : 'Ном засах'"
                class="modal fade"
                hide-footer
                @hide="cancelBook"
              >
                <div class="modal-body">
                  <form
                    class="form-horizontal"
                    wtx-context="9944B6A2-0454-4588-82D8-836F0401FA6F"
                  >
                    <div>
                      <b-alert show variant="warning">
                        <h4 class="alert-heading">Анхаарах зүйлс!</h4>
                        <p>
                          Сурах бичгийн хавтас гэмтэлгүй байх, Суралцагчийн нэрийг
                          арилгах, Сурах бичгийн хуудас бүрэн байх, Сурах бичиг дээр
                          бичсэн зүйлсийг бүрэн арилгах, Work book дээр бичиж тэмдэглснээ
                          цэвэрхэн арилгаж дахин хэрэглэх боломжтой болгосон байх
                        </p>
                        <hr />
                        <p class="mb-0">
                          Та зар оруулахдаа анхаарах зүйлсийг зөрчихгүй байж бусдад
                          хүндрэл учруулахгүй байхыг хүсье.
                        </p>
                      </b-alert>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-sm-3 col-form-label">Номны нэр:</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Номны нэр бичнэ үү."
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="class_number" class="col-sm-3 col-form-label"
                        >Анги:</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="form.class_number"
                          deselect-label="Can't remove this value"
                          track-by="class_number"
                          label="class_text"
                          placeholder="Хэддүгээр ангид хамааралтай ном болохыг сонгоно уу"
                          :options="classNumbers"
                          :searchable="true"
                          :allow-empty="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.class_text }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="price" class="col-sm-3 col-form-label">Үнэ:</label>
                      <div class="col-sm-9">
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          placeholder="Номны үнэ"
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.price"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-sm-3 col-form-label"
                        >Тайлбар:</label
                      >
                      <div class="col-sm-9">
                        <b-form-textarea
                          id="textarea"
                          v-model="form.description"
                          placeholder="Номтой холбоотой дэлгэрэнгүй мэдээлэл..."
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="budget_images_1" class="col-sm-3 col-form-label"
                        >Номны зураг</label
                      >
                      <div class="col-sm-9">
                        <div class="row">
                          <span class="col-sm-2 direct-chat-name float-left">
                            <img
                              v-show="imgLoaded"
                              class="profile-user-img img-fluid attachment-img mr-2"
                              :src="imgUrl"
                              alt="Төслийн зураг"
                              accept="image/*"
                              v-if="imgUrl !== ''"
                            />
                            <img
                              class="profile-user-img img-fluid attachment-img"
                              :src="defaultimage"
                              alt="Багшийн зураг"
                              accept="image/*"
                              v-else
                            />
                          </span>
                          <b-form-file
                            id="imgBook"
                            browse-text="Зураг сонгох"
                            class="col-sm-5"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('imgBook'),
                              },
                            ]"
                            @change="getImg"
                          ></b-form-file>
                          <span class="col-sm-5">
                            {{ imgError }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer justify-content-between">
                  <b-button @click="storeBook()" v-if="!editMode" variant="success">
                    Хадгалах
                  </b-button>
                  <b-button @click="updateBook()" v-if="editMode" variant="warning">
                    Засах
                  </b-button>
                  <b-button @click="cancelBook()" variant="danger"> Хаах </b-button>
                </div>
              </b-modal>
              <b-overlay :show="bookIsLoaded" rounded="sm">
                <b-card-group deck class="p-3 my-5">
                  <div class="col-lg-3 mb-3" v-for="book in myBooks" :key="book.id">
                    <div class="ribbon ribbon-bottom-right"></div>
                    <div class="card-container">
                      <div class="box">
                        <img
                          :src="book.imgBook"
                          alt="Avatar"
                          class="image-card card-img"
                        />
                        <b-badge variant="light" class="buyers">
                          <a href="#" class="badge bg-primary" @click="saleBook(book)"
                            >Хүсэлт илгээсэн: {{ book.buyers.length }}</a
                          >
                        </b-badge>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">
                          <small class="text-muted">
                            {{ book.name.slice(0, 30) }}
                          </small>
                        </h5>
                        <p
                          class="card-text"
                          style="
                            text-align: justify;
                            height: 0.8rem;
                            word-break: break-all;
                          "
                        >
                          <small class="text-muted d-flex justify-content-between">
                            <span>
                              Анги:
                              <b>
                                <span> {{ book.class_number }} -р анги </span>
                              </b>
                            </span>
                            <span>
                              Үнэ:
                              <b>
                                <span>
                                  {{ book.price }}
                                </span>
                              </b>
                            </span>
                          </small>
                        </p>
                      </div>
                      <div class="card-footer">
                        <small class="text-muted">
                          <i class="far fa-calendar-check ml-2"></i>
                          <b>{{ book.created_at | dateYearMonthDay }}</b>
                        </small>
                        <b-badge variant="light" class="float-right">
                          <a href="#" class="badge bg-primary" @click="editBook(book)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a href="#" class="badge bg-danger" @click="removeBook(book)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </b-card-group>
              </b-overlay>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      bookIsLoaded: false,
      quarterOptions: [],
      subj: [],
      books: [],
      myBooks: [],
      child_id: "",
      childOptions: [],
      editMode: false,
      editModeUser: false,
      classNumber: "",
      type_id: "",
      subj_id: "",
      qrCode: null,
      classNumbers: [
        { class_number: 1, class_text: "1-р анги" },
        { class_number: 2, class_text: "2-р анги" },
        { class_number: 3, class_text: "3-р анги" },
        { class_number: 4, class_text: "4-р анги" },
        { class_number: 5, class_text: "5-р анги" },
        { class_number: 6, class_text: "6-р анги" },
        { class_number: 7, class_text: "7-р анги" },
        { class_number: 8, class_text: "8-р анги" },
        { class_number: 9, class_text: "9-р анги" },
        { class_number: 10, class_text: "10-р анги" },
        { class_number: 11, class_text: "11-р анги" },
        { class_number: 12, class_text: "12-р анги" },
      ],
      types: [
        { type: "Cambridge" },
        { type: "Монгол цөм хөтөлбөр" },
        { type: "Бусад" },
      ],
      library: [
        { id: 1, name: "Бага" },
        { id: 2, name: "Дунд" },
        { id: 3, name: "Ахлах" },
      ],
      imgUrl: "",
      form: new Form({
        id: "",
        class_number: "",
        subj_id: "",
        library_id: "",
        type: "",
        isbn: "",
        name: "",
        description: "",
        price: "",
        imgBook: null,
      }),
      formUser: new Form({
        id: "",
        book_id: "",
        class_number: "",
        user_id: "",
        qr_code: "",
        status: "Номын санд",
        user_type: "Номын сан",
      }),
      bookClassNumber: null,
      userType: ["Номын сан", "Ажилтан", "Сурагч"],
      status: ["Номын санд", "Худалдаж авсан", "Түрээслэсэн"],
      defaultimage: "/images/users/user.png",
      imgError: "",
      imgLoaded: false,
      userOptions: [],
      students: [],
      employees: [],
      libraryBook: [],
      bookId: null,
      isLoaded: true,

      totalRowsDocumentStatuses: 1,
      currentPageDocuments: 1,
      perPageDocuments: 24,
      checkPage: undefined,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getLibraryBooks(page = 1) {
      this.checkPage = undefined ? page == 1 : page == this.checkPage;
      axios
        .post(`/librarian/getLibraryBooks?page=${page}`, {
          class_number: this.classNumber,
          type_id: this.type_id,
          subj_id: this.subj_id.id,
        })
        .then(({ data }) => {
          this.books = data.books;
          this.totalRowsDocumentStatuses = this.books ? this.books.length : 0;
          this.checkPage = page;
          this.bookIsLoaded = false;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    subjs() {
      axios
        .get("/librarian/subjs", {})
        .then((response) => {
          this.subj = response.data.subjs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getLibraryBooks() {
    //   this.bookIsLoaded = true;
    //   axios
    //     .post("librarian/getLibraryBooks", {
    //       class_number: this.classNumber,
    //       type_id: this.type_id,
    //       subj_id: this.subj_id.id,
    //     })
    //     .then((response) => {
    //       this.books = response.data.books;
    //       this.myBooks = response.data.myBooks;
    //       this.totalRowsDocumentStatuses = this.books ? this.books.length : 0;
    //       this.bookIsLoaded = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    createLibraryBook() {
      this.$bvModal.show("modal-library-Books");
    },
    storeBook() {
      this.form
        .post("/librarian/createLibraryBook")
        .then((response) => {
          this.$toastr.s("Ном амжилттай бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadBooks");
          this.cancelBook();
        })
        .catch(() => {
          this.$toastr.e(
            "Ном бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelBook() {
      this.$bvModal.hide("modal-library-Books");
      this.form.reset();
      this.editMode = false;

      //   console.log("Book");
    },
    getImg(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError =
          "1024 x 1024 пикселээс бага хэмжээтэй зураг оруулна уу!";
        return;
      } else this.imgError = "";
      //   console.log(img);
      this.readImage(img);
    },
    readImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.form.imgBook = e.target.result;
        this.imgUrl = e.target.result;
      };
      this.imgLoaded = true;
    },
    editBook(book) {
      this.imgUrl = book.image;
      this.editMode = true;
      this.form.reset();
      this.form.fill(book);
      this.form.class_number = {
        class_number: book.class_number,
        class_text: `${book.class_number}-р анги`,
      };
      this.imgLoaded = true;
      this.form.imgBook = null;
      this.$bvModal.show("modal-library-Books");
    },
    updateBook() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/librarian/bookUpdate/" + this.form.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.form.reset();
          this.imgUrl = "";
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-library-Books");
          Fire.$emit("loadBooks");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeBook(book) {
      Swal.fire({
        title: "Энэ номыг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Номын зарыг устгаж байна...";
          axios
            .get("/librarian/removeBook/" + book.id)
            .then(() => {
              this.$toastr.s("Номыг амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("loadBooks");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    // хэрэглэгч холбох
    customName({
      student_last_name,
      student_name,
      teacher_last_name,
      teacher_name,
    }) {
      if (student_last_name) {
        return `${student_last_name}. ${student_name}`;
      } else {
        return `${teacher_last_name}. ${teacher_name}`;
      }
    },
    connectUser(book) {
      this.isLoaded = true;
      this.form.class_number = {
        class_number: book.class_number,
        class_text: `${book.class_number}-р анги`,
      };
      this.getUsers(book.id);
      this.formUser.fill(book);
      this.formUser.book_id = book.id;
      this.bookId = book.id;
      this.formUser.status = "Номын санд";
      this.formUser.user_type = "Номын сан";
      this.bookClassNumber = book.class_number;
      this.$bvModal.show("modal-userBook");
    },
    getUsers(id) {
      axios
        .post("/librarian/getUserData/" + id, {
          qrCode: this.qrCode,
        })
        .then((response) => {
          this.students = response.data.students;
          this.employees = response.data.employees;
          this.libraryBook = response.data.libraryBook;
          this.isLoaded = false;
        })
        .catch((errors) => {});
    },
    createBookUser() {
      this.formUser.book_id = this.bookId;
      this.formUser
        .put("/librarian/createBookUser")
        .then((response) => {
          this.$toastr.s("Хүсэлт амжилттай илгээлээ", "Бүртгэлээ");
          this.getUsers(this.bookId);
          this.formUser.reset();
          this.formUser.book_id = this.bookId;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editUser(user) {
      this.editModeUser = true;
      this.formUser.fill(user);
      if (user.student) {
        this.formUser.user_id = user.student;
      } else if (user.employee) {
        this.formUser.user_id = user.employee;
      }
      this.formUser.class_number = this.bookClassNumber;
      this.userInfo();
    },
    userInfo() {
      if (this.formUser.user_type == "Ажилтан") {
        axios
          .get("/librarian/getEmployees")
          .then((response) => {
            this.userOptions = response.data.employees;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.formUser.user_type == "Сурагч") {
        axios
          .get("/librarian/getStudents/" + this.bookClassNumber)
          .then((response) => {
            this.userOptions = response.data.students;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateUser() {
      this.action = "Хэрэглэгчийн мэдээллийг шинэчилж байна...";
      this.formUser.book_id = this.bookId;
      // console.log(this.form);
      this.formUser
        .put("/librarian/bookUpdateUser/" + this.formUser.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.getUsers(this.bookId);
          this.formUser.reset();
          this.formUser.book_id = this.bookId;
          this.editModeUser = !this.editModeUser;
          this.userInfo();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelUser() {
      this.$bvModal.hide("modal-userBook");
      this.formUser.reset();
      this.bookId = null;
      this.editModeUser = false;
    },
    removeUser(book) {
      Swal.fire({
        title: "Та хэрэглэгчийг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Хэрэглэгчийг устгаж байна...";
          axios
            .get("/librarian/removeBookUser/" + book.id)
            .then(() => {
              this.$toastr.s("Хэрэглэгчийг амжилттай устгалаа!", "Шинэчилсэн");
              this.getUsers(this.bookId);
              this.formUser.reset();
              this.formUser.book_id = this.bookId;
              this.userInfo();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },
  created() {
    this.getLibraryBooks(this.checkPage);
    this.subjs();
    Fire.$on("loadBooks", () => {
      this.getLibraryBooks(this.checkPage);
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
dd {
  padding-left: 20px;
}
.buyers {
  position: absolute;
  bottom: 0;
  right: 15px;
}
</style>
