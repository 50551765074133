<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2"></div>
        <div class="card-body table-responsive">
          <div class="card">
            <div class="card-header">
              <strong>Эд хөрөнгийн бүртгэл</strong>
              <div
                class="main-header navbar navbar-expand navbar-white navbar-light"
              >
                <!-- <h3 class="card-title" style="font-size: 80%">
                      Нийт эд хөрөнгийн тоо:
                      {{ allData.length }}
                    </h3> -->
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <b-row>
                <b-col lg="3" class="my-1">
                  <multiselect
                    v-model="getCategoryItems"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="status"
                    label="status"
                    placeholder="Ангилал"
                    :options="categoryStatus"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.status }}
                    </template>
                  </multiselect>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-group
                    label="Нэг хуудсанд харуулах тоо"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPageCatItemData"
                      :options="pageOptionsCatItemData"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-pagination
                v-model="currentPageCatItemData"
                :total-rows="totalRowsCatItemData"
                :per-page="perPageCatItemData"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
              <b-overlay :show="catItemLoaded" rounded="sm">
                <b-table
                  hover
                  :items="catItems"
                  :fields="fieldsCatItemData"
                  :current-page="currentPageCatItemData"
                  :per-page="perPageCatItemData"
                  :filter="filterCatItemData"
                  :filter-ignored-fields="filterIgnoredFieldsCatItemData"
                  :filter-included-fields="filterOnCatItemData"
                  @filtered="onFilteredCatItemData"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(room_full_name)="data">
                    <b-badge variant="info">
                      {{ data.item.room_user.my_room.room_full_name }}
                    </b-badge>
                  </template>
                  <template #cell(teacher_name)="data">
                    <b-badge
                      variant="secondary"
                      v-if="data.item.room_user.my_user_info"
                    >
                      {{ data.item.room_user.my_user_info.teacher_name }}
                    </b-badge>
                  </template>
                  <template #cell(name)="data">
                    <b-badge
                      variant="success"
                      v-if="data.item.items.capital_category"
                    >
                      {{ data.item.items.capital_category.name }}
                    </b-badge>
                  </template>
                  <template #cell(status)="data">
                    <b-badge
                      variant="secondary"
                      v-if="data.item.items.capital_category"
                    >
                      {{ data.item.items.capital_category.status }}
                    </b-badge>
                  </template>
                  <template #cell(item_image)="data">
                    <img
                      v-b-tooltip.hover.top.html="
                        '<dd><img src=' +
                        data.item.items.item_image +
                        ' style=' +
                        'width:100%!important </dd></dl>'
                      "
                      class="table-avatar"
                      :src="data.item.items.item_image"
                      alt="user-avatar"
                  /></template>
                  <template #cell(item_name)="data">
                    <b-badge variant="light">
                      {{ data.item.items.item_name }}
                    </b-badge>
                  </template>
                  <template #cell(item_code)="data">
                    <b-badge variant="light">
                      {{ data.item.items.item_code }}
                    </b-badge>
                  </template>
                  <template #cell(count)="data">
                    <b-badge variant="light">
                      {{ data.item.count }}
                    </b-badge>
                  </template>
                  <template #cell(item_price)="data">
                    <b-badge variant="light">
                      {{ data.item.count * data.item.items.item_price }}
                    </b-badge>
                  </template>
                  <template #cell(reg_date)="data">
                    <b-badge variant="light">
                      {{ data.item.reg_date }}
                    </b-badge>
                  </template>
                </b-table>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      catItems: [],
      categoryStatus: [],
      categoryStat: "",
      getCategoryItems: { status: "Бүгд" },
      totalRowsCatItemData: 1,
      currentPageCatItemData: 1,
      perPageCatItemData: 100,
      pageOptionsCatItemData: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 300, text: "Илүү олноор харах" },
      ],
      filterCatItemData: "",
      filterOnCatItemData: [],
      filterIgnoredFieldsCatItemData: ["items", "room_user"],
      fieldsCatItemData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "room_full_name",
          label: "Харьяа өрөө",
          sortable: true,
        },
        {
          key: "teacher_name",
          label: "Хариуцагч",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },

        {
          key: "item_image",
          label: "Зураг",
          sortable: false,
        },
        {
          key: "item_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "count",
          label: "Тоо ширхэг",
          sortable: true,
        },
        {
          key: "item_price",
          label: "Үнэ",
          sortable: true,
        },
        {
          key: "reg_date",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
      ],
      leftItemCount: 0,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formItemModalTitle: "Өрөө, танхим бүртгэх",
      oldCount: 0,
      //Өрөө танхимын хэсэг
      itemLoaded: true,
      catItemLoaded: true,
      editCategoryMode: false,
      editItemMode: false,
      editRoomUser: false,
      fileInputKey: 0,
    };
  },
  components: { Multiselect },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  watch: {
    getCategoryItems: function (newVal, oldVal) {
      if (newVal != "") {
        this.getCatItems();
      }
    },
  },
  methods: {
    getCatItems() {
      axios
        .post("/all_worker_functions/getCategoryItems/", {
          categoryStat: this.getCategoryItems.status,
        })
        .then((res) => {
          this.categoryStatus = res.data.categoryStatus;
          this.categoryStatus.push({
            status: "Бүгд",
          });
          this.catItems = res.data.catItems;
          this.catItemLoaded = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // -------------------------- Нэгдсэн мэдээлэл ------------------------------------
    onFilteredCatItemData(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCatItemData = filteredItems.length;
      this.currentPageCatItemData = 1;
    },
  },
  created() {
    this.getCatItems();
    Fire.$on("loadItems", () => {
      this.getCatItems();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
