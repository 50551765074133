<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Мэдлэгийн ай(Knowledge domain)</h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Мэдлэгийн ай</th>
                <th>Мэдлэгийн айн түвшин</th>
                <th>Мэдлэгийн айн түвшин тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(knowledge, index) in getAllKnowledgeLevelName"
                :key="knowledge.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ knowledge.knowledge_system_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ knowledge.knowledge_lev_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ knowledge.knowledge_lev_name_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ knowledge.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ knowledge.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTaxanomyKnowledgeSystem(knowledge)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteKnowledge(knowledge)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Мэдлэгийн ай бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Мэдлэгийн ай засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode
                ? createTaxanomyKnowledgeSystem()
                : updateTaxanomyKnowledgeSystem()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <multiselect
                  v-model="form.tax_know_sys_id"
                  deselect-label="Can't remove this value"
                  track-by="knowledge_system_name"
                  label="knowledge_system_name"
                  placeholder="Select one"
                  :options="knowledgeSystems"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.knowledge_system_name }}</strong> is
                    written in<strong>
                      {{ option.knowledge_system_name_explain }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Мэдлэгийн айн нэр</label>
                <b-textarea
                  name="knowledge_lev_name"
                  v-model="form.knowledge_lev_name"
                  text-field="knowledge_lev_name"
                ></b-textarea>
                <has-error :form="form" field="knowledge_lev_name"></has-error>
              </div>
              <div class="form-group">
                <label>Мэдлэгийн айн нэршлийн тайлбар</label>

                <b-textarea
                  name="knowledge_lev_name_explain"
                  v-model="form.knowledge_lev_name_explain"
                  text-field="knowledge_lev_name_explain"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="knowledge_lev_name_explain"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      getAllKnowledgeLevelName: [],
      knowledgeSystems: [],
      form: new Form({
        id: "",
        year_id: "", //backend талд хадгална
        school_id: "", //backend талд хадгална
        department_id: "", //backend талд хадгална
        create_emp_id: "", //backend талд хадгална
        position_id: "", //backend талд хадгална
        tax_know_sys_id: "",
        knowledge_lev_name: "",
        knowledge_lev_name_explain: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/getAlltaxanomyKnowledgeLNameSystem")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.getAllKnowledgeLevelName =
            response.data.getAllKnowledgeLevelName;
          this.knowledgeSystems = response.data.knowledgeSystems;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTaxanomyKnowledgeSystem(mental) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editMode = true;
      this.form.reset();
      this.form.fill(mental);
      let mySelectKnowledgeSystem = this.knowledgeSystems.filter(
        (el) => el.id === mental.tax_know_sys_id
      );
      this.form.tax_know_sys_id = mySelectKnowledgeSystem[0];
    },
    updateTaxanomyKnowledgeSystem() {
      this.form
        .put(
          "/curriculumadministrator/updateTaxanomyKnowledgeLNameSystem/" +
            this.form.id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyKnowledgeSystem");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createTaxanomyKnowledgeSystem() {
      this.form
        .post("/curriculumadministrator/createTaxanomyKnowledgeLNameSystem")
        .then((response) => {
          this.$toastr.s("Мэдлэгийн айг амжилттай хадгалсан.", "Амжилттай");
          Fire.$emit("loadAllTaxanomyKnowledgeSystem");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Мэдлэгийн айг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    deleteKnowledge(knowledge) {
      this.form
        .delete(
          `/curriculumadministrator/deletetaxanomyKnowledgeLName/${knowledge.id}`
        )
        .then((response) => {
          this.$toastr.s(
            "Мэдлэгийн айн түвшинг амжилттай устгалаа.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyKnowledgeSystem");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Мэдлэгийн айн түвшинг устгаж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyKnowledgeSystem", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
