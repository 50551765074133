<template>
  <div class="row">
    <div class="col-md-12 pt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h6 class="card-title" style="font-size: 80%">
            Хичээлүүд(Улирлын дүн баталгаажаагүй үед 0 утгатай байна. 0 утгатай байсан ч
            тухайн улиралд авсан байгаа явцын шалгалтуудын дүнг харж болно. Тиймээс дүн
            дээр дарна уу.)
          </h6>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Хичээл</th>
                <th>Багш</th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Улирал 1"
                >
                  Улир-1
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Улирал 2"
                >
                  Улир-2
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Улирал 3"
                >
                  Улир-3
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Улирал 4"
                >
                  Улир-4
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Жил"
                >
                  Жил
                </th>
                <th>Үйлдэл</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(childCourse, i) in myCoursesGrades" :key="i">
                <td>{{ ++i }}</td>
                <td>{{ childCourse.subject_name }}</td>
                <td>
                  {{ childCourse.emp_teacher_last_name[0].toUpperCase() }}.{{
                    childCourse.emp_teacher_name
                  }}
                </td>
                <td>
                  <b-button
                    pill
                    size="sm"
                    style="width: 130px"
                    variant="light"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 1)"
                    ><span v-if="childCourse.grade_exam_1 === null"
                      >0 ({{ symbolGradeCheck(childCourse.grade_exam_1) }})</span
                    >
                    <span v-if="childCourse.grade_exam_1 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_1) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_1)
                      }})</span
                    >
                  </b-button>
                </td>
                <td>
                  <b-button
                    pill
                    size="sm"
                    style="width: 130px"
                    variant="light"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 2)"
                  >
                    <span v-if="childCourse.grade_exam_2 === null"
                      >0 ({{ symbolGradeCheck(childCourse.grade_exam_2) }})</span
                    >
                    <span v-if="childCourse.grade_exam_2 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_2) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_2)
                      }})</span
                    >
                  </b-button>
                </td>
                <td>
                  <b-button
                    pill
                    size="sm"
                    style="width: 130px"
                    variant="light"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 3)"
                  >
                    <span v-if="childCourse.grade_exam_3 === null"
                      >0 ({{ symbolGradeCheck(childCourse.grade_exam_3) }})</span
                    >
                    <span v-if="childCourse.grade_exam_3 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_3) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_3)
                      }})</span
                    >
                  </b-button>
                </td>
                <td>
                  <b-button
                    pill
                    size="sm"
                    style="width: 130px"
                    variant="light"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 4)"
                  >
                    <span v-if="childCourse.grade_exam_4 === null"
                      >0 ({{ symbolGradeCheck(childCourse.grade_exam_4) }})</span
                    >
                    <span v-if="childCourse.grade_exam_4 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_4) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_4)
                      }})</span
                    >
                  </b-button>
                </td>

                <td>
                  <span
                    >{{ averageYearBefore(childCourse).toFixed(1) }} ({{
                      symbolGradeCheck(averageYearBefore(childCourse).toFixed(1))
                    }})</span
                  >
                </td>
                <td>
                  <!-- <a href="#">
                    <i class="fas fa-diagnoses"></i>
                  </a> -->
                  <b-button
                    size="sm"
                    class="mt-2 mr-2"
                    href="#"
                    variant="outline-primary"
                    @click.prevent="showComment(childCourse)"
                    >Эсээ бичих</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <div class="col-md-12" v-show="showExam">
      <div class="card card-primary">
        <div class="card-header bg-info color-palette">
          <div class="row">
            <div class="col-md-3">
              <h3 class="card-title">{{ courseExam.subject_name }} шалгалтууд</h3>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="selectCourseExam"
                :options="myCourseExams"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                placeholder="Шалгалт сонгох"
                :custom-label="nameWithExams"
              ></multiselect>
            </div>
            <div class="col-md-1">
              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" style="overflow-x: auto">
          <table
            class="table table-hover table-sm"
            v-if="selectCourseExam.exam_type_status == 0"
          >
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Даалгавар</th>
                <th>Алхам-1</th>
                <th>Алхам-2</th>
                <th>Алхам-3</th>
                <th>Алхам-4</th>
                <th>Алхам-5</th>
                <th>Алхам-6</th>
                <th>Алхам-7</th>
                <th>Алхам-8</th>
                <th>Гүйцэтгэл</th>
                <th>GPA</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(exam, index) in selectCourseExamValues" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ exam.daalgar_number }}</td>
                <td>
                  {{ transferInt(exam.exam1) }}
                </td>
                <td>
                  {{ transferInt(exam.exam2) }}
                </td>
                <td>
                  {{ transferInt(exam.exam3) }}
                </td>
                <td>
                  {{ transferInt(exam.exam4) }}
                </td>
                <td>
                  {{ transferInt(exam.exam5) }}
                </td>
                <td>
                  {{ transferInt(exam.exam6) }}
                </td>
                <td>
                  {{ transferInt(exam.exam7) }}
                </td>
                <td>
                  {{ transferInt(exam.exam8) }}
                </td>
                <td>
                  <span>{{ averageExamStep(exam) }}</span>
                </td>
                <td>
                  <span class="badge badge-info"
                    >({{ symbolGradeCheck(averageExamStep(exam)) }})</span
                  >
                </td>
              </tr>
              <tr v-if="selectCourseExamValues.length != 0">
                <td></td>
                <td>Дундаж гүйцэтгэл</td>
                <td>{{ examPerValuesAverage.exam1Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam2Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam3Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam4Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam5Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam6Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam7Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam8Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam1_8Ave.toFixed(1) }}</td>
                <td>
                  <span class="badge badge-info">
                    ({{ symbolGradeCheck(examPerValuesAverage.exam1_8Ave.toFixed(1)) }})
                  </span>
                </td>
              </tr>
              <!-- График -->
              <tr>
                <td colspan="12">
                  <div class="row mt-2" v-if="viewYearChart">
                    <div class="col-12 col-sm-12">
                      <div class="info-box bg-light">
                        <div class="info-box-content">
                          <span>
                            <strong class="info-box-text mt-4 text-center">
                              Тухайн шалгалтыг ангийн дундажтай харьцуулбал</strong
                            >
                            <apexchart
                              type="bar"
                              height="340"
                              class="barChart"
                              :options="optionsGenderAllClass"
                              :series="seriesExams"
                            ></apexchart>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th style="width: 10px">#</th>
                <th>Даалгавар</th>
                <th>Оюуны үйл-1</th>
                <th>Оюуны үйл-2</th>
                <th>Оюуны үйл-3</th>
                <th>Оюуны үйл-4</th>
                <th>Оюуны үйл-5</th>
                <th>Оюуны үйл-6</th>
                <th>Оюуны үйл-7</th>
                <th>Оюуны үйл-8</th>
              </tr>
              <tr v-for="(exam, index) in selectCourseExamValues" :key="exam.id">
                <td>{{ ++index }}</td>
                <td>{{ exam.daalgar_number }}</td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam1Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam2Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam3Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam4Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam5Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam6Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam7Explpain }}
                  </span>
                </td>
                <td>
                  <span data-v-1e2e2960="" class="badge text-wrap text-left badge-light">
                    {{ exam.exam8Explpain }}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <b-overlay :show="isOpenExam" rounded="sm">
              <div class="row px-2">
                <b-list-group
                  v-for="(number, index) in selectCourseExamValues"
                  :key="index"
                  class="mb-3 col-lg-4"
                >
                  <b-list-group-item>
                    <strong>{{ number.number_name }}-р асуулт</strong>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(symbol, index) in number.my_symbol"
                    :key="index"
                  >
                    <strong class="text-muted">{{ symbol.symbol_name }} хэсэг</strong>
                    <table class="table table-sm table-hover mt-3 text-center">
                      <thead>
                        <tr>
                          <th class="text-muted" style="width: 10px">№</th>
                          <th class="text-muted">Авсан оноо</th>
                          <th class="text-muted">Нийт оноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(step, index) in symbol.my_symbol_step" :key="index">
                          <td>{{ step.symbol_step_name }}</td>
                          <td>
                            <b-badge
                              v-if="
                                step.my_students_of_step.length > 0 &&
                                step.my_students_of_step[0]
                              "
                              variant="success"
                            >
                              {{ step.my_students_of_step[0].my_value }}
                            </b-badge>
                            <span v-else>0</span>
                          </td>
                          <td>
                            {{ step.confirm_value }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div class="row mt-2" v-if="viewYearChart">
                <div class="col-12 col-sm-12">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Тухайн шалгалтын даалгаврын оноог ангийн дундажтай харьцуулбал
                        </strong>
                        <apexchart
                          type="bar"
                          height="340"
                          class="barChart"
                          :options="optionsGenderAllClass"
                          :series="seriesExams"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ бичих"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp"
              ><b>Хичээл: </b>{{ showCourseComment.subject_name }}</span
            >
            <span class="direct-chat-timestamp float-right"
              ><b>Багш: </b>
              {{ showCourseComment.emp_teacher_name }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <small class="text-muted"
              >Хамгийн багадаа 25 үг бичнэ үү! Үгийн тоо:
              {{ formComment.comment.replace(/<\/?[^>]+(>|$)/g, "").length }}</small
            >
            <div class="form-group">
              <quill-editor
                ref="myQuillEditor"
                v-model="formComment.comment"
                :options="editorOption"
              />
            </div>
            <div class="form-group">
              <b-button
                :disabled="formComment.comment.replace(/<\/?[^>]+(>|$)/g, '').length < 25"
                size="sm"
                href="#"
                variant="primary"
                @click.prevent="sendMessage"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                {{ editCommentMode ? "Засах" : "Илгээх" }}
              </b-button>
              <b-button
                v-if="editCommentMode"
                :disabled="formComment.comment.replace(/<\/?[^>]+(>|$)/g, '').length < 25"
                size="sm"
                href="#"
                variant="light"
                @click.prevent="cancelEditComm"
              >
                Болих
              </b-button>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card class="p-1 text-justify">
                      <b-badge
                        variant="secondary"
                        @click.prevent="editComment(comm)"
                        role="button"
                      >
                        <b-icon icon="pencil" aria-hidden="true"></b-icon>
                      </b-badge>
                      <b-badge
                        variant="danger"
                        @click.prevent="deleteComment(comm)"
                        role="button"
                      >
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                      </b-badge>
                      <div v-html="comm.comment" />
                    </b-card>
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isOpenExam: false,
      // График
      viewYearChart: false,
      optionsGenderAllClass: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data + "%";
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#28a745", "#ffc107"],
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#BED1E6",
                colorTo: "#D8E3F0",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesExams: [
        // {
        //   name: "Ангийн дундаж",
        //   data: [],
        // },
        // {
        //   name: "Сонгосон шалгалт",
        //   data: [],
        // },
      ],
      editMode: false,
      myCourses: [],
      myCoursesGrades: [],

      //Хичээлээр шалгалтыг шүүж харах.
      showExam: false,
      courseExam: "",
      myCourseExams: [],
      selectCourseExam: "",
      selectCourseExamValues: "",
      examPerValuesAverage: [],
      showCourseComment: new Form({
        course_id: null,
        subject_name: "",
        emp_teacher_name: "",
      }),
      beforeComments: [],
      formComment: new Form({
        id: null,
        type: "course",
        comment: "",
      }),
      editCommentMode: false,
      editorOption: {
        placeholder: "Хичээлийн талаарх эсээ бичих ...",
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.5, // default
            maxWidth: 550, // default
            imageType: ["image/jpeg", "image/png", "image/jpg"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
    };
  },
  components: { Multiselect },
  watch: {
    selectCourseExam: {
      handler: function (newVal, oldVal) {
        if (oldVal !== null) {
          //   console.log(newVal, oldVal);
          this.getExamValues(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    getMyCourses() {
      axios
        .get("/student/getMyCourses")
        .then((res) => {
          this.myCourses = res.data.myCourses;
          this.myCoursesGrades = res.data.myCoursesGrades;
          //   console.log(res.data);
        })
        .catch();
    },
    averageYearBefore(childCourse) {
      let average =
        (childCourse.grade_exam_1 + childCourse.grade_exam_2 + childCourse.grade_exam_3) /
        3;
      return average;
    },
    getQuarterExamAnalyse(course, quarter_id) {
      this.selectCourseExamValues = "";
      this.myCourseExams = [];
      this.selectCourseExam = "";
      this.showExam = false;
      //   console.log(course, quarter_id);
      this.courseExam = course;
      axios
        .post("/student/getMyCourseExams", {
          course,
          quarter_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.myCourseExams = res.data.getMyCourseExams;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      this.showExam = true;
    },
    getExamValues(newVal) {
      this.selectCourseExamValues = [];
      this.isOpenExam = true;
      this.viewYearChart = false;
      axios
        .post("/student/examValues", {
          exam: newVal,
        })
        .then((res) => {
          if (newVal.exam_type_status == 0) {
            //   console.log(res.data);
            this.selectCourseExamValues = res.data.examValues;
            this.calculateAverageExamValues(this.selectCourseExamValues);

            this.optionsGenderAllClass.xaxis.categories = [];
            this.seriesExams = [];

            this.optionsGenderAllClass.xaxis.categories = [
              "Алхам-1:Сельф-1",
              "Алхам-2:Метакогнитив-1",
              "Алхам-3:Когнитив-1",
              "Алхам-4:Когнитив-2",
              "Алхам-5:Когнитив-3",
              "Алхам-6:Когнитив-4",
              "Алхам-7:Метакогнитив-2",
              "Алхам-8:Сельф-2",
            ];

            this.selectCourseExamValues.forEach((element) => {
              this.seriesExams.push({
                name: "Даалгавар-" + element.daalgar_number,
                data: [
                  element.exam1 === null ? 0 : parseFloat(element.exam1),
                  element.exam2 === null ? 0 : parseFloat(element.exam2),
                  element.exam3 === null ? 0 : parseFloat(element.exam3),
                  element.exam4 === null ? 0 : parseFloat(element.exam4),
                  element.exam5 === null ? 0 : parseFloat(element.exam5),
                  element.exam6 === null ? 0 : parseFloat(element.exam6),
                  element.exam7 === null ? 0 : parseFloat(element.exam7),
                  element.exam8 === null ? 0 : parseFloat(element.exam8),
                ],
              });
            });

            let urt = this.seriesExams.length;

            this.seriesExams[urt] = {
              name: "Ангийн дундаж",
              data: [
                res.data.selectedExamClassAVG[0]["exam1"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam1"]),
                res.data.selectedExamClassAVG[0]["exam2"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam2"]),
                res.data.selectedExamClassAVG[0]["exam3"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam3"]),
                res.data.selectedExamClassAVG[0]["exam4"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam4"]),
                res.data.selectedExamClassAVG[0]["exam5"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam5"]),
                res.data.selectedExamClassAVG[0]["exam6"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam6"]),
                res.data.selectedExamClassAVG[0]["exam7"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam7"]),
                res.data.selectedExamClassAVG[0]["exam8"] === null
                  ? 0
                  : parseFloat(res.data.selectedExamClassAVG[0]["exam8"]),
              ],
            };

            // this.seriesExams[1].data = res.data.allClassStdEr;
            this.isOpenExam = false;
            this.viewYearChart = true;
          } else {
            this.selectCourseExamValues = res.data.examValues;

            this.optionsGenderAllClass.xaxis.categories = [];
            this.seriesExams = [];
            this.seriesExams.push({
              name: "Авсан оноо",
              data: [],
            });
            this.seriesExams.push({
              name: "Ангийн дундаж",
              data: [],
            });
            this.selectCourseExamValues.forEach((number) => {
              number.my_symbol.forEach((symbol) => {
                symbol.my_symbol_step.forEach((step) => {
                  this.seriesExams[0].data.push(
                    step.my_students_of_step.length > 0 && step.my_students_of_step[0]
                      ? (parseFloat(step.my_students_of_step[0].my_value) * 100) /
                          parseFloat(step.confirm_value)
                      : 0
                  );
                  this.seriesExams[1].data.push(
                    step.students_evalutaion.length > 0 && step.students_evalutaion[0]
                      ? (parseFloat(step.students_evalutaion[0].value) * 100) /
                          parseFloat(step.confirm_value)
                      : 0
                  );
                });
              });
            });
            this.viewYearChart = true;
            this.isOpenExam = false;
          }
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    transferInt(value) {
      return parseInt(value);
    },
    averageExamStep(exam) {
      let averageExam =
        parseFloat(exam.exam1) * 0.1 +
        parseFloat(exam.exam2) * 0.1 +
        parseFloat(exam.exam3) * 0.1 +
        parseFloat(exam.exam4) * 0.1 +
        parseFloat(exam.exam5) * 0.2 +
        parseFloat(exam.exam6) * 0.2 +
        parseFloat(exam.exam7) * 0.1 +
        parseFloat(exam.exam8) * 0.1;
      return averageExam;
    },
    calculateAverageExamValues(values) {
      //   console.log(values);
      let exam1 = [];
      let exam2 = [];
      let exam3 = [];
      let exam4 = [];
      let exam5 = [];
      let exam6 = [];
      let exam7 = [];
      let exam8 = [];
      let exam1_8 = [];
      values.forEach((element) => {
        exam1.push(parseFloat(element.exam1));
        exam2.push(parseFloat(element.exam2));
        exam3.push(parseFloat(element.exam3));
        exam4.push(parseFloat(element.exam4));
        exam5.push(parseFloat(element.exam5));
        exam6.push(parseFloat(element.exam6));
        exam7.push(parseFloat(element.exam7));
        exam8.push(parseFloat(element.exam8));
        exam1_8.push(
          parseFloat(
            element.exam1 * 0.1 +
              element.exam2 * 0.1 +
              element.exam3 * 0.1 +
              element.exam4 * 0.1 +
              element.exam5 * 0.2 +
              element.exam6 * 0.2 +
              element.exam7 * 0.1 +
              element.exam8 * 0.1
          )
        );
      });
      //   console.log(exam1);
      let exam1Sum = exam1.reduce(function (a, b) {
        return a + b;
      });
      let exam2Sum = exam2.reduce(function (a, b) {
        return a + b;
      });
      let exam3Sum = exam3.reduce(function (a, b) {
        return a + b;
      });
      let exam4Sum = exam4.reduce(function (a, b) {
        return a + b;
      });
      let exam5Sum = exam5.reduce(function (a, b) {
        return a + b;
      });
      let exam6Sum = exam6.reduce(function (a, b) {
        return a + b;
      });
      let exam7Sum = exam7.reduce(function (a, b) {
        return a + b;
      });
      let exam8Sum = exam8.reduce(function (a, b) {
        return a + b;
      });
      let exam1_8Sum = exam1_8.reduce(function (a, b) {
        return a + b;
      });
      this.examPerValuesAverage.exam1Ave = exam1Sum / exam1.length;
      this.examPerValuesAverage.exam2Ave = exam2Sum / exam2.length;
      this.examPerValuesAverage.exam3Ave = exam3Sum / exam3.length;
      this.examPerValuesAverage.exam4Ave = exam4Sum / exam4.length;
      this.examPerValuesAverage.exam5Ave = exam5Sum / exam5.length;
      this.examPerValuesAverage.exam6Ave = exam6Sum / exam6.length;
      this.examPerValuesAverage.exam7Ave = exam7Sum / exam7.length;
      this.examPerValuesAverage.exam8Ave = exam8Sum / exam8.length;
      this.examPerValuesAverage.exam1_8Ave = exam1_8Sum / exam1_8.length;
      //   console.log(this.examPerValuesAverage);
    },
    nameWithExams({ exam_type, name }) {
      return `${exam_type}-ын шалгалт: ${name}`;
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
    toOronTootsokh(value) {
      return parseFloat(value).toFixed(1);
    },
    showComment(course) {
      this.beforeComments = [];
      this.showCourseComment.reset();
      this.showCourseComment.fill(course);
      this.getMessage();
      this.$bvModal.show("modal-project-comment");
    },
    getMessage() {
      axios
        .post("/project_student/getStudentComment/" + this.showCourseComment.course_id, {
          type: "course",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      this.formComment
        .post("/project_student/sendComment/" + this.showCourseComment.course_id)
        .then(() => {
          this.cancelEditComm();
          this.$toastr.s("Таны сэтгэгдэл амжилттай илгээгдлээ.", "Амжилттай");
          this.getMessage();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editComment(comm) {
      this.formComment.reset();
      this.formComment.fill(comm);
      this.editCommentMode = true;
    },
    cancelEditComm() {
      this.formComment.reset();
      this.editCommentMode = false;
    },
    deleteComment(comm) {
      Swal.fire({
        title: "Эсээ устгах!",
        html: comm.comment,
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Цэс хасаж байна...";
          axios
            .get("/project_student/deleteComment/" + comm.id)
            .then(() => {
              this.$toastr.s("Эсээ амжилттай устгалаа.", "Амжилттай");
              this.getMessage();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  created() {
    this.getMyCourses();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
