<template>
  <div class="row">
    <div class="col-md-1 mt-2">
      <div
        class="card card-fuchsia"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Анги</h3>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <p
            v-for="school_class in schoolclasses"
            :key="school_class.id"
            style="line-height: 60%"
          >
            <a
              href="#"
              @click.prevent="
                schoolClass(school_class.id, school_class.full_name)
              "
            >
              {{ school_class.full_name }}
            </a>
          </p>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-md-8 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <div class="row">
            <h3 class="card-title" v-show="!selectClass">
              1-р алхам: Анги сонгох (Өмнөх баганаас анги заавал сонгох)
            </h3>
            <div class="col-md-6">
              <span
                ><h3
                  style="inline-block"
                  class="card-title"
                  v-show="selectClass"
                >
                  Анги - {{ chosesClass }} :
                  <b-col>
                    <b-form-group
                      label-size="sm"
                      label="2-р алхам: Суралцаж буй хичээлийн жил сонгох"
                      label-for="input-sm"
                      style="inline"
                    >
                      <b-form-select
                        v-model="form.year_id"
                        :options="years"
                        text-field="year_name"
                        value-field="id"
                        size="sm"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('year_id'),
                          },
                        ]"
                      ></b-form-select>
                      <!-- <has-error
                                    :form="form"
                                    field="subject_id"
                                ></has-error> -->
                      <!-- <div class="mt-3">
                        Selected:
                        <strong>{{ form.year_id }}</strong>
                      </div> -->
                    </b-form-group>
                  </b-col>
                </h3></span
              >
            </div>
            <div class="col-md-6">
              <span
                ><h3
                  style="inline-block"
                  class="card-title"
                  v-show="selectClass"
                >
                  Анги : {{ chosesClass }}
                  <b-col>
                    <b-form-group
                      label-size="sm"
                      label="3-р алхам: Дэвшүүлэх анги"
                      label-for="input-sm"
                      style="inline"
                    >
                      <b-button
                        variant="danger"
                        @click.prevent="getPromotionsStudents()"
                        >Дуудах</b-button
                      >
                    </b-form-group>
                  </b-col>
                </h3></span
              >
            </div>
          </div>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col>
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>№</b-th>
                    <b-th>О.Нэр</b-th>
                    <b-th>Анги</b-th>
                    <b-th>Хичээлийн жил</b-th>
                    <b-th>Жилийн эцсийн дүн(%)</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(
                      schoolclassessstudent, index
                    ) in schoolclassessstudents"
                    :key="index"
                    v-if="schoolclassessstudents.length"
                  >
                    <b-td>{{ ++index }}</b-td>
                    <b-td>
                      {{
                        schoolclassessstudent
                          .student_profiles_student_last_name[0]
                      }}.
                      {{
                        schoolclassessstudent.student_profiles_student_name
                      }}</b-td
                    >
                    <b-td>{{
                      schoolclassessstudent.school_class_full_name
                    }}</b-td>
                    <b-td> {{ schoolclassessstudent.year_name }}</b-td>
                    <b-td>{{ schoolclassessstudent.grade }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td colspan="7" variant="secondary" class="text-right">
                      Бүртгэгдсэн суралцагчид:
                      <b v-if="schoolclassessstudents.length">{{
                        schoolclassessstudents.length
                      }}</b>
                      <b v-if="!schoolclassessstudents.length"
                        >Одоогоор хичээл бүртгэлтэй суралцагчид байхгүй
                        байна.</b
                      >
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-card
              border-variant="primary"
              header="Анхаарах:"
              header-text-variant="danger"
              header-bg-variant="transparent"
              text-variant="warning"
              align="center"
              size="sm"
              ><b-card-text>
                Анги дэвшүүлэх тохиолдолд 4, 5, 6-р алхам тус бүрийг зөв
                сонгоно. 4-р алхам дээр дэвших гэж буй ангийг сонгоно. Жнь: 3а
                ангийг 4а анги.<br />
                5-р алхам дээр дэвшилтийн төрөл сонгоно.
                <br />
                Хэрэв дэвшилтийн төрөл дээр "төгсгөх" гэж сонгосон бол 4, 6-р
                алхамыг алгасна. <br />
                6-р алхам дээр 2-р алхамд сонгосон оныг дараагийн оноор сольж
                сонгоно.
              </b-card-text>
            </b-card>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-size="sm"
                label="4-р алхам: Дэвшүүлэх анги"
                label-for="input-sm"
              >
                <b-form-select
                  v-model="form1.school_class_id"
                  :options="schoolclasses"
                  text-field="full_name"
                  value-field="id"
                  size="sm"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form1.errors.has('school_class_id'),
                    },
                  ]"
                ></b-form-select>
                <!-- <div class="mt-3">
                  Selected:
                  <strong>{{ form1.school_class_id }}</strong>
                </div> -->
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="5-р алхам: Дэвшилт төрөл"
                label-for="input-sm"
              >
                <b-form-select
                  v-model="form1.promotion_type"
                  :options="promotionTypes"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('promotion_type'),
                    },
                  ]"
                  size="sm"
                ></b-form-select>
                <has-error :form="form" field="promotion_type"></has-error>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-size="sm"
                label="6-р алхам: Хичээл жил"
                label-for="input-sm"
              >
                <b-form-select
                  v-model="form1.year_id"
                  :options="years"
                  text-field="year_name"
                  value-field="id"
                  size="sm"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form1.errors.has('year_id'),
                    },
                  ]"
                ></b-form-select>
                <!-- <has-error
                                    :form="form"
                                    field="subject_id"
                                ></has-error> -->
                <!-- <div class="mt-3">
                  Selected:
                  <strong>{{ form1.year_id }}</strong>
                </div> -->
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Үйлдлүүд"
                label-size="sm"
                label-for="input-sm"
              >
                <b-button-group size="sm" class="mr-1">
                  <b-button
                    title="Save file"
                    :variant="editMode ? 'warning' : 'success'"
                    @click.prevent="
                      editMode
                        ? updateClassPromotionStudents()
                        : createClassPromotionStudents()
                    "
                  >
                    <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- <b-button variant="outline-primary">
                                        <b-icon icon="tools"></b-icon>
                                    </b-button>
                                    <b-button variant="outline-primary success">
                                        <b-icon icon="person-fill"></b-icon>
                                    </b-button> -->
                </b-button-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-md-3 mt-2">
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title">Хичээлийн жилээр шүүж суралцагч харах</h3>
        </div>
        <div class="card-body">
          <b-form-group label-size="sm" label="Анги" label-for="input-sm">
            <b-form-select
              v-model="filter_school_class_id"
              :options="schoolclasses"
              text-field="full_name"
              value-field="id"
              size="sm"
            ></b-form-select>
            <div class="mt-3">
              <!-- Selected:
              <strong>{{ filter_school_class_id }}</strong> -->
            </div>
          </b-form-group>
          <b-form-group
            label-size="sm"
            label="Хичээлийн жил"
            label-for="input-sm"
          >
            <b-form-select
              v-model="filter_year_id"
              :options="years"
              text-field="year_name"
              value-field="id"
              size="sm"
            ></b-form-select>
            <!-- <has-error
                                    :form="form"
                                    field="subject_id"
                                ></has-error> -->
            <div class="mt-3">
              <!-- Сонгогдсон:
              <strong>{{ filter_year_id }}</strong> -->
              <table class="table table-hover table-sm">
                <thead>
                  <tr>
                    <th style="width: 10px">№</th>
                    <th>Овог нэр</th>
                    <th style="width: 40px">Анги</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in studentsCheck" :key="index">
                    <td>{{ ++index }}</td>
                    <td>
                      {{ student.profiles_student_last_name[0] }}.{{
                        student.profiles_student_name
                      }}
                    </td>

                    <td>
                      <span class="badge bg-danger">{{
                        student.school_class_full_name
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
//Анги дэвшүүлэхэд дараах зүйлсийг анхаарна.
// users->profiles->student_migrations->success хүснэгттэй холбоно. Тиймээс цаашид багш, ажилчид, суралцагчийн гаргасан амжилтуудыг дээрх хүснэгтэнд оруулж байх.
export default {
  data() {
    return {
      action: "",
      editMode: false,
      selectClass: false,
      chosesClass: "",
      years: [],
      schoolclasses: [], //ашигласан
      schoolclassessstudents: [],
      studentsCheck: [],
      form: new Form({
        id: "",
        year_id: "",
        school_class_id: "",
        students: [],
      }),
      form1: new Form({
        id: "",
        year_id: "",
        school_class_id: "",
        students: [],
        promotion_type: "active",
      }),
      filter_year_id: "",
      filter_school_class_id: "",
      promotionTypes: [
        { value: "active", text: "Дэвшүүлэх" },
        { value: "finished", text: "Төгсгөх" },
      ],
    };
  },
  components: { Multiselect },
  computed: {
    PropertyYearAndPropertyClassId() {
      // return `${this.filter_year_id}|${this.filter_school_class_id}`;
      const { filter_year_id, filter_school_class_id } = this;
      return {
        filter_year_id,
        filter_school_class_id,
      };
    },
  },
  watch: {
    PropertyYearAndPropertyClassId(newVal, oldVal) {
      // const [oldPropertyA, oldProvertyB] = oldVal.split("|Хуучин");
      // const [newPropertyA, newProvertyB] = newVal.split("|Шинэ");
      if (
        newVal.filter_year_id !== "" &&
        newVal.filter_school_class_id !== ""
      ) {
        // console.log("Энд хүсэлт явуулна.");
        axios
          .post("/manager/thisYearStudentCheck", {
            year_id: newVal.filter_year_id,
            school_class_id: newVal.filter_school_class_id,
          })
          .then((response) => {
            this.studentsCheck = response.data.studentCheck; //Сонгогдсон ангийн бүх сурагчдыг дуудна, он ангиар нь шалгаж үзүүлнэ.
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
      // if (newVal.filter_year_id !== "") {
      //   console.log(typeof newVal.filter_year_id);
      //   console.log(newVal.filter_year_id);
      // }
      // if (oldVal.filter_year_id !== "") {
      //   console.log(typeof oldVal.filter_year_id);
      //   console.log(oldVal.filter_year_id);
      // }
      // console.log("----------------");
      // if (newVal.filter_school_class_id !== "") {
      //   console.log(typeof newVal.filter_school_class_id);
      //   console.log(newVal.filter_school_class_id);
      // }
      // if (oldVal.filter_school_class_id !== "") {
      //   console.log(typeof oldVal.filter_school_class_id);
      //   console.log(oldVal.filter_school_class_id);
      // }
    },
    // "form.year_id": function (newValue, oldValue) {
    //   //   console.log(oldValue, newValue);
    //   axios
    //     .post("/manager/????", {
    //       year_id: newValue,
    //     })
    //     .then((response) => {
    //       this.students = response.data.students; //Сонгогдсон ангийн бүх сурагчдыг дуудна.
    //     })
    //     .catch((errors) => {
    //       //   console.log(errors);
    //     });
    // },
  },
  methods: {
    getAllClass() {
      axios
        .get("/manager/getSchoolClassess")
        .then((response) => {
          this.schoolclasses = response.data.schoolclasses;
          this.form.school_class_id = response.data.schoolclasses.id;
          this.years = response.data.years;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    schoolClass(id, full_name) {
      this.form.reset();
      this.form1.reset();
      this.schoolclassessstudents = [];
      this.form.school_class_id = id;
      this.selectClass = true;
      this.chosesClass = full_name;
    },
    getPromotionsStudents() {
      axios
        .post("/manager/getSchoolClassessStudents", {
          school_class_id: this.form.school_class_id,
          school_year_id: this.form.year_id,
        })
        .then((response) => {
          this.schoolclassessstudents = response.data.schoolclassessstudents;
          this.form1.students = response.data.schoolclassessstudents;
          // console.log(response.data.schoolclassessstudents);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createClassPromotionStudents() {
      Swal.fire({
        title: "Анги дэвшүүлэхдээ итгэлтэй байна уу?",
        text: "4, 5, 6-р алхмуудыг дахин нягтлаарай.",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Дэвшүүлэх`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        axios
          .post("/manager/createClassPromotionStudents", {
            students: this.form1.students,
            school_class_id: this.form1.school_class_id,
            new_year_id: this.form1.year_id,
            old_year_id: this.form.year_id,
            old_school_class_id: this.form.school_class_id,
            promotion_type: this.form1.promotion_type,
          })
          .then((response) => {
            this.form1.reset();
            this.schoolclassessstudents = [];
            // console.log(response.data);
          })
          .catch((errors) => {
            // console.log(errors);
          });
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Амжилттай дэвшүүллээ!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Анги дэвшилтийг цуцаллаа.", "", "info");
        }
      });

      //   console.log("Суралцагчдыг анги дэвшүүлэх");
    },
  },
  created() {
    this.getAllClass();
    Fire.$on("loadSchoolCourses", () => {
      this.getAllClass();
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
