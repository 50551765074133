<template>
  <div class="card card-default color-palette-box mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр OGSM шүүж харах"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div>
        <div class="col-lg-8 text-right">
          <b-button
            @click="selectedYear = null"
            v-b-modal.modal-restore
            variant="outline-success"
            class="mr-2"
          >
            <i class="fas fa-clone"></i> Өмнөх оны өгөгдөл татах
          </b-button>
          <b-modal
            id="modal-restore"
            title="Өмнөх оны өгөгдөл хуулбарлах"
            hide-footer
          >
            <div class="modal-body">
              <label>Жил сонгоно уу</label>
              <multiselect
                v-model="selectedYear"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                :custom-label="customYearName"
                placeholder="Хичээлийн жил сонгоно уу?"
                :options="
                  allYears.filter((y) => y.start_date < year_id.start_date)
                "
                :searchable="true"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                    - хичээлийн жил
                  </strong>
                </template>
              </multiselect>
              <b-overlay
                :show="loadRestore"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <button
                  type="submit"
                  class="btn btn-primary mt-3"
                  :disabled="busy || selectedYear == null"
                  @click.prevent="restoreData()"
                >
                  Хуулбарлах
                </button>
              </b-overlay>
              <button
                type="submit"
                class="btn btn-secondary mt-3 float-right"
                @click="$bvModal.hide('modal-restore')"
              >
                Болих
              </button>
            </div>
          </b-modal>
          <b-button
            variant="outline-primary"
            @click="strategyAvgFromMeasures()"
          >
            <i class="fas fa-wrench"></i>
            Стратегийн утга шинэчлэх
          </b-button>
        </div>
      </div>
      <div class="row mt-3" v-show="form1">
        <div class="col-lg-1">Зорилго:</div>
        <div class="col-lg-2">
          <input
            class="form-control form-control-sm"
            type="text"
            placeholder="Зорилго 1 гэх мэтээр дугаарлах"
            name="ogsmObjective"
            v-model="vForm1.objective_name"
          />
        </div>
        <div class="col-lg-4">
          <input
            class="form-control form-control-sm"
            type="text"
            placeholder="Зорилго(Objective) оруулах"
            name="ogsmObjective"
            v-model="vForm1.objective"
          />
        </div>
        <div class="col-lg-2">
          <multiselect
            v-model="vForm1.year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жил сонго"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                жил
              </strong></template
            >
          </multiselect>
        </div>
        <div class="col-lg-2">
          <multiselect
            v-model="vForm1.status"
            :options="statusTeams"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="OGSM харьяалагдах баг сонгох"
            label="name"
            track-by="name"
          >
            <!-- :preselect-first="true" -->
            <!-- <template slot="selection" slot-scope="{ values, search, isOpen }" -->
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span class="multiselect__single" v-if="values.length && !isOpen"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="col-lg-1 mt-1">
          <b-icon
            @click.prevent="objectiveStore()"
            variant="success"
            icon="plus-circle"
            aria-hidden="true"
            font-scale="1.5"
            v-show="!objectiveSaveOrEdit"
          ></b-icon>
          <b-icon
            @click.prevent="objectiveUpdate()"
            variant="primary"
            icon="pencil-square"
            aria-hidden="true"
            font-scale="1.5"
            v-show="objectiveSaveOrEdit"
          ></b-icon>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table
              class="table table-striped table-hover table-bordered table-sm border-warning"
            >
              <!-- Бүртгэх форм эхэлж байна. -->
              <thead>
                <tr>
                  <th scope="col">
                    <b-badge
                      variant="success"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ЗОРИЛГО(Objective)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="warning"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ЗОРИЛТ(Goal)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="primary"
                      class="py-2 text-center"
                      style="width: 20%"
                      >СТРАТЕГИ(Strategy)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="secondary"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ХЭМЖҮҮР(Measure)</b-badge
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Шууд objective талаас нь авсан -->
                <b-overlay :show="dataLoad" rounded="sm" class="mt-4">
                  <tr>
                    <div v-for="(ogsm, index) in allOgsm" :key="ogsm.id">
                      <td style="width: 600px">
                        <div class="info-box bg-gradient-light">
                          <div class="info-box-content">
                            <span class="info-box-text">
                              <b-badge variant="success" pill
                                ><strong>{{ index + 1 }}</strong></b-badge
                              >
                              <b-badge variant="success" pill
                                >{{ ogsm.objective_name }}
                                <b-badge variant="light" pill
                                  ><b-icon
                                    icon="pencil-square"
                                    variant="primary"
                                    @click.prevent="objectiveEdit(ogsm)"
                                  ></b-icon>
                                  <b-icon
                                    icon="trash-fill"
                                    variant="danger"
                                    @click.prevent="objectiveDelete(ogsm)"
                                  ></b-icon></b-badge
                              ></b-badge>
                              <span
                                class="badge badge-success float-right"
                                v-if="ogsm.goalTotal"
                                >{{
                                  (ogsm.goalTotal / ogsm.goalCount).toFixed(2)
                                }}</span
                              >
                            </span>
                            <span class="progress-description">
                              <b-badge
                                @click="visibleForm2(ogsm)"
                                style="width: 100%"
                                class="text-wrap text-justify"
                                variant="light"
                              >
                                {{ ogsm.objective }}
                              </b-badge>
                            </span>
                            <span class="info-box-text"
                              ><b-badge variant="secondary">
                                {{ objectiveStatus1(ogsm) }}
                              </b-badge>
                              <b-badge
                                @click="visibleForm2(ogsm)"
                                class="float-right"
                                variant="success"
                              >
                                <i class="fas fa-plus"></i> Зорилт нэмэх
                              </b-badge>
                            </span>
                          </div>
                          <!-- /.info-box-content -->
                        </div>
                      </td>
                      <td style="width: 90vw">
                        <div
                          v-for="(ogsmGoal, index) in ogsm.goals"
                          :key="ogsmGoal.id"
                        >
                          <div
                            class="card card-warning collapsed-card"
                            id="ogsmCard"
                          >
                            <div class="card-header">
                              <h3 class="card-title">
                                <b-badge variant="light" pill
                                  ><strong>{{ index + 1 }}</strong></b-badge
                                >
                                {{ ogsmGoal.goals_name }}
                              </h3>
                              <div class="card-tools">
                                <span
                                  class="badge badge-success"
                                  v-if="ogsmGoal.strategyTotal"
                                  >{{
                                    (
                                      ogsmGoal.strategyTotal /
                                      ogsmGoal.measureCount
                                    ).toFixed(2)
                                  }}</span
                                >
                                <span
                                  class="badge badge-light"
                                  v-if="ogsmGoal.strategyManagerTotal"
                                  >{{
                                    (
                                      ogsmGoal.strategyManagerTotal /
                                      ogsmGoal.measureCount
                                    ).toFixed(2)
                                  }}</span
                                >
                                <span class="badge badge-light" v-else>0</span>
                                <button
                                  type="button"
                                  class="btn btn-tool"
                                  data-card-widget="collapse"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="card-body p-0">
                              <div class="info-box bg-gradient-light">
                                <div class="info-box-content">
                                  <span class="info-box-text">
                                    <b-badge variant="success" pill
                                      >{{ ogsm.objective_name }}
                                    </b-badge>
                                    <i
                                      class="fa fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                    <b-badge variant="warning" pill>
                                      {{ ogsmGoal.goals_name }}
                                      <b-badge variant="light" pill
                                        ><b-icon
                                          icon="pencil-square"
                                          variant="primary"
                                          @click.prevent="goalEdit(ogsmGoal)"
                                        ></b-icon>
                                        <b-icon
                                          icon="trash-fill"
                                          variant="danger"
                                          @click.prevent="goalDelete(ogsmGoal)"
                                        ></b-icon></b-badge
                                    ></b-badge>
                                    <b-badge
                                      @click="visibleForm3(ogsmGoal)"
                                      class="float-right"
                                      variant="primary"
                                    >
                                      <i class="fas fa-plus"></i> Стратеги нэмэх
                                    </b-badge>
                                  </span>
                                  <span class="progress-description">
                                    <b-badge
                                      @click="visibleForm3(ogsmGoal)"
                                      style="width: 100%"
                                      class="text-wrap text-justify"
                                      variant="light"
                                    >
                                      {{ ogsmGoal.goals }}
                                    </b-badge>
                                  </span>
                                  <div v-if="ogsmGoal.strategies.length > 0">
                                    <div
                                      class="card card-primary collapsed-card"
                                      id="strategyCard"
                                      v-for="(
                                        strategy, index
                                      ) in ogsmGoal.strategies"
                                      :key="strategy.id"
                                    >
                                      <div class="card-header">
                                        <h3 class="card-title">
                                          <b-badge variant="light" pill
                                            ><strong>{{
                                              index + 1
                                            }}</strong></b-badge
                                          >
                                          <b-badge variant="primary" pill>
                                            {{ strategy.strategies_name }}
                                            <b-badge variant="light" pill
                                              ><b-icon
                                                icon="pencil-square"
                                                variant="primary"
                                                @click.prevent="
                                                  strategyEdit(strategy)
                                                "
                                              ></b-icon>
                                              <b-icon
                                                icon="trash-fill"
                                                variant="danger"
                                                @click.prevent="
                                                  strategyDelete(strategy)
                                                "
                                              ></b-icon></b-badge
                                          ></b-badge>
                                        </h3>
                                        <div class="card-tools">
                                          <span
                                            class="badge badge-success"
                                            v-if="strategy.measureTotal"
                                            >{{
                                              (
                                                strategy.measureTotal /
                                                strategy.measures.length
                                              ).toFixed(2)
                                            }}</span
                                          >
                                          <span
                                            class="badge badge-light"
                                            v-if="strategy.measureManagerTotal"
                                            >{{
                                              (
                                                strategy.measureManagerTotal /
                                                strategy.measures.length
                                              ).toFixed(2)
                                            }}</span
                                          >
                                          <span class="badge badge-light" v-else
                                            >0</span
                                          >
                                          <button
                                            type="button"
                                            class="btn btn-tool"
                                            data-card-widget="collapse"
                                          >
                                            <i class="fas fa-plus"></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="card-body p-0">
                                        <div class="info-box bg-gradient-light">
                                          <div class="info-box-content">
                                            <span class="progress-description">
                                              <b-badge
                                                @click="visibleForm4(strategy)"
                                                variant="light"
                                                class="text-wrap col-10 text-justify"
                                              >
                                                {{ strategy.strategies }}
                                              </b-badge>
                                              <b-badge
                                                @click="visibleForm4(strategy)"
                                                class="float-right"
                                                variant="secondary"
                                              >
                                                <i class="fas fa-plus"></i>
                                                Хэмжүүр нэмэх
                                              </b-badge>
                                            </span>
                                            <div
                                              v-if="
                                                strategy.measures.length > 0
                                              "
                                            >
                                              <div
                                                class="card card-secondary collapsed-card"
                                                id="measureCard"
                                                v-for="(
                                                  measure, index
                                                ) in strategy.measures"
                                                :key="measure.id"
                                              >
                                                <div class="card-header">
                                                  <h3 class="card-title">
                                                    <b-badge
                                                      variant="light"
                                                      pill
                                                      ><strong>{{
                                                        index + 1
                                                      }}</strong></b-badge
                                                    >
                                                    <b-badge
                                                      variant="light"
                                                      pill
                                                    >
                                                      {{
                                                        measure.measures_name
                                                      }}
                                                      <b-badge
                                                        variant="light"
                                                        pill
                                                        ><b-icon
                                                          icon="pencil-square"
                                                          variant="primary"
                                                          @click.prevent="
                                                            measureEdit(measure)
                                                          "
                                                        ></b-icon>
                                                        <b-icon
                                                          icon="trash-fill"
                                                          variant="danger"
                                                          @click.prevent="
                                                            measureDelete(
                                                              measure,
                                                              strategy
                                                            )
                                                          "
                                                        ></b-icon></b-badge
                                                    ></b-badge>
                                                  </h3>

                                                  <div class="card-tools">
                                                    <span
                                                      class="badge badge-success"
                                                    >
                                                      {{
                                                        measure.measures_number
                                                      }}
                                                    </span>
                                                    <button
                                                      type="button"
                                                      class="btn btn-tool"
                                                      data-card-widget="collapse"
                                                    >
                                                      <i
                                                        class="fas fa-plus"
                                                      ></i>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div class="card-body p-0">
                                                  <div
                                                    class="info-box bg-gradient-light"
                                                  >
                                                    <div
                                                      class="info-box-content"
                                                    >
                                                      <span
                                                        class="progress-description"
                                                      >
                                                        <span
                                                          style="width: 100%"
                                                          class="text-wrap text-justify"
                                                        >
                                                          {{ measure.measures }}
                                                        </span>
                                                      </span>
                                                      <p
                                                        v-if="
                                                          measure.manager_strategies_avg_strategy_number
                                                        "
                                                      >
                                                        <strong>
                                                          {{
                                                            parseFloat(
                                                              measure.manager_strategies_avg_strategy_number
                                                            ).toFixed(2)
                                                          }}
                                                        </strong>
                                                        <small>
                                                          /Менежерүүдийн
                                                          оруулсан хэмжүүрийн
                                                          дундажаар/
                                                        </small>
                                                      </p>
                                                      <p v-else>
                                                        <small>
                                                          /Менежерүүд хэмжүүрт
                                                          үнэлгээ оруулаагүй
                                                          байна!/
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              v-else
                                              class="callout callout-dark text-muted"
                                            >
                                              <h5>Мэдээлэл байхгүй!</h5>
                                              <p>
                                                Хэмжүүр одоогоор бүртгэгдээгүй
                                                байна.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-else
                                    class="callout callout-dark text-muted"
                                  >
                                    <h5>Мэдээлэл байхгүй!</h5>
                                    <p>
                                      Стратеги одоогоор бүртгэгдээгүй байна.
                                    </p>
                                  </div>
                                </div>
                                <!-- /.info-box-content -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                </b-overlay>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Нэмэх хэсэг -->
    <b-modal
      size="xs"
      id="modal-add"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- /.card-header -->
              <!-- form start -->
              <form>
                <div class="card-body">
                  <div v-show="form2">
                    <div class="form-group">
                      <div class="form-group" v-if="goalSaveOrEdit">
                        <label>Зорилго</label>
                        <b-form-select
                          v-model="vForm2.ogsm_objective_id"
                          :options="objectOptions"
                          text-field="objective_name"
                          value-field="id"
                          placeholder="Зорилго"
                          :class="[
                            'form-control',
                            {
                              'is-invalid':
                                vForm2.errors.has('ogsm_objective_id'),
                            },
                          ]"
                        ></b-form-select>
                      </div>
                      <label>Нэр(Зорилт 1 гэх мэтээр дугаарлах)</label>
                      <input
                        type="text"
                        v-model="vForm2.goals_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm2.errors.has('goals_name'),
                          },
                        ]"
                      />
                    </div>
                    <div class="form-group">
                      <label>Зорилт</label>
                      <textarea
                        rows="4"
                        cols="50"
                        v-model="vForm2.goals"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm2.errors.has('goals'),
                          },
                        ]"
                        placeholder="Зорилт(Goal) оруулах"
                      ></textarea>
                    </div>
                  </div>
                  <div v-show="form3">
                    <div class="form-group" v-if="strategySaveOrEdit">
                      <label>Зорилт</label>
                      <b-form-select
                        v-model="vForm3.ogsm_goal_id"
                        :options="goalOptions"
                        text-field="goals_name"
                        value-field="id"
                        placeholder="Зорилт"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('ogsm_goal_id'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <label>Нэр(Стратеги 1 гэх мэтээр дугаарлах)</label>
                      <input
                        type="text"
                        v-model="vForm3.strategies_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('strategies_name'),
                          },
                        ]"
                      />
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="vForm3.departments"
                        :options="departments"
                        :multiple="true"
                        placeholder="Харьяалагдах баг сонгох"
                        label="department_name"
                        track-by="id"
                        :searchable="true"
                        :close-on-select="false"
                        :hideSelected="true"
                        :show-labels="true"
                        :allow-empty="false"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          >{{ option.department_name }} ></template
                        >
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <label>Стратеги</label>
                      <textarea
                        rows="4"
                        cols="50"
                        v-model="vForm3.strategies"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('strategies'),
                          },
                        ]"
                        placeholder="Стратеги(Strategy) оруулах"
                      ></textarea>
                    </div>
                  </div>
                  <div v-show="form4">
                    <div class="form-group" v-if="measureSaveOrEdit">
                      <label>Стратеги</label>
                      <b-form-select
                        v-model="vForm4.ogsm_strategy_id"
                        :options="strategyOptions"
                        text-field="strategies_name"
                        value-field="id"
                        placeholder="Стратеги"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('ogsm_strategy_id'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <label>Нэр(Хэмжүүр 1 гэх мэтээр дугаарлах)</label>
                      <input
                        type="text"
                        v-model="vForm4.measures_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('measures_name'),
                          },
                        ]"
                      />
                    </div>
                    <div class="form-group">
                      <label>Хэмжүүр</label>
                      <textarea
                        rows="4"
                        cols="50"
                        v-model="vForm4.measures"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('strategies'),
                          },
                        ]"
                        placeholder="Хэмжүүр(Measure) оруулах"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label>Утга</label>
                      <input
                        type="number"
                        v-model="vForm4.measures_number"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('measures_number'),
                          },
                        ]"
                        placeholder="Хэмжүүр(Measure) оруулах"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div v-show="form2">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="goalStore()"
                      v-show="!goalSaveOrEdit"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="goalUpdate()"
                      v-show="goalSaveOrEdit"
                    >
                      Хадгалах
                    </button>
                  </div>
                  <div v-show="form3">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="strategyStore()"
                      v-show="!strategySaveOrEdit"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="strategyUpdate()"
                      v-show="strategySaveOrEdit"
                    >
                      Хадгалах
                    </button>
                  </div>
                  <div v-show="form4">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="measureStore()"
                      v-show="!measureSaveOrEdit"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="measureUpdate()"
                      v-show="measureSaveOrEdit"
                    >
                      Хадгалах
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selectedYear: null,
      loadRestore: false,
      formModalTitle: "Зорилт нэмэх",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allOgsm: [],
      allYears: [],
      year_id: "",
      editorOption: {
        // some quill options
      },
      statusTeams: [
        { name: "Удирдлага", value: 0 },
        { name: "Сургалт", value: 1 },
        { name: "Үйл ажиллагааны алба", value: 2 },
        { name: "Цэцэрлэг", value: 3 },
        { name: "Спорт клуб", value: 4 },
        { name: "Сургалтын төв", value: 5 },
      ],

      objectiveSaveOrEdit: false,
      goalSaveOrEdit: false,
      strategySaveOrEdit: false,
      measureSaveOrEdit: false,

      form1: true,
      form2: false,
      form3: false,
      form4: false,
      strategyOptions: [],
      goalOptions: [],
      objectOptions: [],

      vForm1: new Form({
        id: "",
        year_id: "",
        status: { name: "Удирдлага", value: 0 },
        objective_name: "",
        objective: "",
      }),
      vForm2: new Form({
        id: "",
        year_id: "",
        ogsm_objective_id: "",
        status: { name: "Удирдлага", value: 0 },
        goals_name: "Зорилт ",
        goals: "",
      }),
      vForm3: new Form({
        id: "",
        year_id: "",
        ogsm_goal_id: "",
        status: { name: "Удирдлага", value: 0 },
        strategies_name: "Стратеги ",
        strategies: "",
        departments: "",
      }),
      vForm4: new Form({
        id: "",
        year_id: "",
        ogsm_strategy_id: "",
        status: { name: "Удирдлага", value: 0 },
        measures_name: "Хэмжүүр ",
        measures: "",
        measures_number: "",
      }),
      dataLoad: true,
    };
  },
  components: { Multiselect },
  watch: {
    year_id: function (newVal, oldVal) {
      //   console.log(newVal, oldVal);
      this.dataLoad = true;
      axios
        .post("/ogsm/getAllOgsmYear", { year_id: newVal })
        .then((res) => {
          //   console.log(res.data);
          this.allOgsm = [];
          this.allOgsm = res.data.allOgsm;
          this.dataLoad = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    departments: function () {
      return this.$store.state.departments;
    },
  },
  methods: {
    restoreData() {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Та сонгосон оны өгөгдлийг хуулбарлах гэж байна?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм!",
        cancelButtonText: "Үгүй",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadRestore = true;
          axios
            .get("/ogsm/restoreOgsm/" + this.selectedYear.id)
            .then((res) => {
              Swal.fire(
                "Амжилттай!",
                "Бүх өгөгдлийг амжилттай хуулбарлалаа.",
                "success"
              );
              this.loadRestore = false;
              this.$bvModal.hide("modal-restore");
              this.getAllOgsmObjectives();
            })
            .catch((err) => {
              Swal.fire("Алдаа!", "Алдаа гарлаа.", "warning");
              this.loadRestore = false;
            });
        }
      });
    },
    strategyAvgFromMeasures() {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Бүх стратегийн үнэлгээг хэмжүүрүүдийн дундаж оноогоор шинэчлэлт хийх үү?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм!",
        cancelButtonText: "Үгүй",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/strategyAvgFromMeasures")
            .then((res) => {
              Swal.fire(
                "Амжилттай!",
                "Бүх стратегийн үнэлгээг хэмжүүрүүдийн дундаж оноогоор шинэчлэлт амжилттай хийгдлээ.",
                "success"
              );
            })
            .catch((err) => {
              Swal.fire("Алдаа!", "Алдаа гарлаа.", "warning");
            });
        }
      });
    },
    objectiveStatus1(value) {
      //   console.log(value);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === value.status
      );
      //   console.log(status1Name);
      return status1Name[0].name;
    },

    getAllOgsmObjectives() {
      axios
        .get("/ogsm/getAllOgsm")
        .then((res) => {
          //   console.log(res.data);
          this.allYears = res.data.allYears;
          this.year_id = res.data.yearId;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    objectiveStore() {
      //   console.log("Ажилласан байна.");
      this.vForm1
        .post("/ogsm/ogsmStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm1Reset();
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    objectiveEdit(ogsm) {
      //   console.log(ogsm);
      this.objectiveSaveOrEdit = true;
      this.vForm1.fill(ogsm);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsm.status
      );
      this.vForm1.status = status1Name[0];
      let status1Year = this.allYears.filter((el) => el.id === ogsm.year_id);
      this.vForm1.year_id = status1Year[0];
    },
    objectiveUpdate() {
      //   console.log("Ажилласан байна. objective");
      this.vForm1
        .patch("/ogsm/ogsmUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm1Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    objectiveDelete(objective) {
      console.log(objective);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Зорилгод хамаарал бүхий зорилт, зорилтод хамаарал бүхий стратеги, стратегид хамаарал бүхий хэмжүүрүүд хамт устгагдана. Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmObjectiveDelete/" + objective.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    vForm1Reset() {
      this.vForm1.reset();
      this.objectiveSaveOrEdit = false;
      this.vForm2Reset();
    },

    //Form2 нээх хаах
    visibleForm2(ogsm) {
      //   console.log(ogsm);
      this.vForm2.reset();
      this.form2 = false;
      this.goalSaveOrEdit = false;
      this.vForm2.ogsm_objective_id = ogsm.id;
      this.vForm2.status = ogsm.status;
      this.vForm2.year_id = ogsm.year_id;
      this.form2 = true;
      this.form3 = false;
      this.form4 = false;
      this.formModalTitle = "Зорилт нэмэх";
      this.headerBgVariant = "success";
      this.$bvModal.show("modal-add");
    },
    goalStore() {
      //   console.log("Ажилласан байна.");
      this.vForm2
        .post("/ogsm/ogsmGoalStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm2Reset();
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goalEdit(ogsmGoal) {
      this.getObjectOptions();
      this.vForm2.reset();
      this.form2 = true;
      this.goalSaveOrEdit = true;
      this.vForm2.fill(ogsmGoal);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsmGoal.status
      );
      this.vForm2.status = status1Name[0];
      this.form3 = false;
      this.form4 = false;
      this.formModalTitle = "Зорилт засах";
      this.headerBgVariant = "success";
      this.$bvModal.show("modal-add");
    },
    goalUpdate() {
      //   console.log("Ажилласан байна.");
      this.vForm2
        .patch("/ogsm/ogsmGoalUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm2Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goalDelete(goal) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Зорилтод хамаарал бүхий стратеги, стратегид хамаарал бүхий хэмжүүрүүд хамт устгагдана. Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmGoalDelete/" + goal.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    vForm2Reset() {
      this.vForm2.reset();
      this.goalSaveOrEdit = false;
      this.form2 = false;
      this.vForm3Reset();
    },
    getObjectOptions() {
      axios
        .get("/ogsm/getOgsmObject/")
        .then((res) => {
          this.objectOptions = res.data.objectList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //Form3 нээх хаах
    visibleForm3(ogsmGoal) {
      //   console.log(ogsmGoal);
      this.vForm3.reset();
      this.form3 = false;
      this.strategySaveOrEdit = false;
      this.vForm3.ogsm_goal_id = ogsmGoal.id;
      this.vForm3.status = ogsmGoal.status;
      this.vForm3.year_id = ogsmGoal.year_id;
      this.form3 = true;
      this.form2 = false;
      this.form4 = false;
      this.formModalTitle = "Стратеги нэмэх";
      this.headerBgVariant = "primary";
      this.$bvModal.show("modal-add");
    },

    strategyStore() {
      //   console.log("Ажилласан байна.");
      this.vForm3
        .post("/ogsm/ogsmStrategyStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm3Reset();
          this.$toastr.s("Амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    strategyEdit(ogsmStrategy) {
      this.getGoalOptions(ogsmStrategy.ogsm_goal_id);
      this.vForm3.reset();
      this.form3 = true;
      this.strategySaveOrEdit = true;
      this.vForm3.fill(ogsmStrategy);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsmStrategy.status
      );
      this.vForm3.status = status1Name[0];
      this.form2 = false;
      this.form4 = false;
      this.formModalTitle = "Стратеги засах";
      this.headerBgVariant = "primary";
      this.$bvModal.show("modal-add");
    },
    strategyUpdate() {
      //   console.log("Ажилласан байна.");
      this.vForm3
        .patch("/ogsm/ogsmStrategyUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm3Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    strategyDelete(strategy) {
      console.log(strategy);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Стратегид хамаарал бүхий хэмжүүрүүд хамт устгагдана. Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmStrategyDelete/" + strategy.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    vForm3Reset() {
      this.vForm3.reset();
      this.strategySaveOrEdit = false;
      this.form3 = false;
      this.vForm4Reset();
    },
    getGoalOptions(goalId) {
      axios
        .get("/ogsm/getOgsmGoals/" + goalId)
        .then((res) => {
          //   console.log(res.data);
          this.goalOptions = res.data.goalList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //Form4 нээх хаах
    visibleForm4(ogsmStrategy) {
      this.vForm4.reset();
      this.form4 = false;
      this.vForm4.ogsm_strategy_id = ogsmStrategy.id;
      this.vForm4.status = ogsmStrategy.status;
      this.vForm4.year_id = ogsmStrategy.year_id;
      this.measureSaveOrEdit = false;
      this.form4 = true;
      this.form2 = false;
      this.form3 = false;
      this.formModalTitle = "Хэмжүүр нэмэх";
      this.headerBgVariant = "secondary";
      this.$bvModal.show("modal-add");
    },
    measureStore() {
      //   console.log("Ажилласан байна.");
      this.vForm4
        .post("/ogsm/ogsmMeasureStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.vForm4Reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    measureEdit(ogsmMeasure) {
      this.getStrategyOptions(ogsmMeasure.ogsm_strategy_id);
      this.vForm4.reset();
      this.form4 = true;
      this.measureSaveOrEdit = true;
      this.vForm4.fill(ogsmMeasure);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsmMeasure.status
      );
      this.vForm4.status = status1Name[0];
      this.form2 = false;
      this.form3 = false;
      this.formModalTitle = "Хэмжүүр засах";
      this.headerBgVariant = "secondary";
      this.$bvModal.show("modal-add");
    },
    measureUpdate() {
      //   console.log("Ажилласан байна.");
      this.vForm4
        .patch("/ogsm/ogsmMeasureUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm4Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStrategyOptions(strategyId) {
      axios
        .get("/ogsm/getOgsmStrategy/" + strategyId)
        .then((res) => {
          //   console.log(res.data);
          this.strategyOptions = res.data.strategyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    measureDelete(measure) {
      console.log(measure);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmMeasureDelete/" + measure.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },

    vForm4Reset() {
      this.vForm4.reset();
      this.measureSaveOrEdit = false;
      this.form4 = false;
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    this.getAllOgsmObjectives();
    Fire.$on("loadOgsmObjectives", () => {
      this.getAllOgsmObjectives();
      this.$bvModal.hide("modal-add");
    });
  },
};
</script>

<style lang="scss" scoped>
.b-icon,
.badge {
  cursor: pointer !important;
}
.list-group-item {
  padding: 0.2rem 1rem;
}
#strategyCard {
  margin-left: 23%;
}
#measureCard {
  margin-left: 30%;
}
.table-responsive {
  overflow-x: unset !important;
}
</style>
