import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: [],
        userRoleInfo: "",
        all_users: [],
        activeYear: "",
        years: [],
        departments: [],
        allSchoolClasses: [],//Цогцолборын бүх анги
        allSchoolPriMidHighClasses: [],//Сургуулийн бүх анги
        allSchoolKGClasses: [],//Зөвхөн цэцэрлэг
        allSchoolSubjects: [],
        positions: [],
        roles: [],
        salary_grades: [],
        school_leave_types: [],
        school_students_s_kg_complex_count_info: [], //school_students_s_kg_complex_count_info
    },

    mutations: {
        UPDATE_USER(state, value) {
            // console.log("mutation value: ", value);
            state.user = value;
        },
        UPDATE_USER_ROLE_INFO(state, value) {
            // console.log("mutation value role: ", value);
            state.userRoleInfo = value;
        },
        UPDATE_ALL_USER_INFO(state, value) {
            // console.log("mutation value role: ", value);
            state.all_users = value;
        },
        UPDATE_YEARS(state, value) {
            // console.log("mutation value role: ", value);
            state.years = value;
        },
        UPDATE_ACTIVE_YEAR(state, value) {
            // console.log("mutation value role: ", value);
            state.activeYear = value;
        },
        UPDATE_DEPARTMENTS(state, value) {
            // console.log("mutation value role: ", value);
            state.departments = value;
        },
        UPDATE_All_SCHOOL_CLASSES(state, value) {
            // console.log("mutation value role: ", value);
            state.allSchoolClasses = value;
        },
        UPDATE_All_SCHOOL_PRI_MID_HIGH_CLASSES(state, value) {
            // console.log("mutation value role: ", value);
            state.allSchoolPriMidHighClasses = value;
        },
        UPDATE_All_SCHOOL_KG_CLASSES(state, value) {
            // console.log("mutation value role: ", value);
            state.allSchoolKGClasses = value;
        },
        UPDATE_All_SCHOOL_SUBJECTS(state, value) {
            // console.log("mutation value role: ", value);
            state.allSchoolSubjects = value;
        },
        UPDATE_POSITIONS(state, value) {
            // console.log("mutation value role: ", value);
            state.positions = value;
        },
        UPDATE_ROLES(state, value) {
            // console.log("mutation value role: ", value);
            state.roles = value;
        },
        UPDATE_SALARY_GRADES(state, value) {
            // console.log("mutation value role: ", value);
            state.salary_grades = value;
        },
        UPDATE_SCHOOL_LEAVE_TYPES(state, value) {
            // console.log("mutation value role: ", value);
            state.school_leave_types = value;
        },
        UPDATE_STUDENT_COUNT_S_KG_COMPLEX(state, value) {
            // console.log("mutation value role: ", value);
            state.school_students_s_kg_complex_count_info = value;
        }
    },

    actions: {
        UPDATE_USER_INFO(context) {
            axios
                .get("/currentUser")
                .then(res => {
                    // console.log(res.data);
                    // store.commit("UPDATE_USER", res.data.user);
                    // this.user = res.data.user;
                    context.commit("UPDATE_USER", res.data.user);
                    context.commit(
                        "UPDATE_USER_ROLE_INFO",
                        res.data.user.roles[0]
                    );
                })
                .catch(err => console.log(err));
        },
        UPDATE_SOME_INFO(context) {
            axios
                .get("/somedatas")
                .then(res => {
                    // console.log(res.data);
                    // store.commit("UPDATE_USER", res.data.user);
                    // this.user = res.data.user;
                    context.commit("UPDATE_ALL_USER_INFO", res.data.all_users);
                    context.commit("UPDATE_YEARS", res.data.years);
                    context.commit("UPDATE_ACTIVE_YEAR", res.data.activeYear);
                    context.commit("UPDATE_DEPARTMENTS", res.data.departments);
                    context.commit("UPDATE_All_SCHOOL_CLASSES", res.data.allSchoolClasses);
                    context.commit("UPDATE_All_SCHOOL_PRI_MID_HIGH_CLASSES", res.data.allSchoolPriMidHighClasses);
                    context.commit("UPDATE_All_SCHOOL_KG_CLASSES", res.data.allSchoolKGClasses);
                    context.commit("UPDATE_All_SCHOOL_SUBJECTS", res.data.allSchoolSubjects);
                    context.commit("UPDATE_POSITIONS", res.data.positions);
                    context.commit("UPDATE_ROLES", res.data.roles);
                    context.commit(
                        "UPDATE_SALARY_GRADES",
                        res.data.salary_grades
                    );
                    context.commit(
                        "UPDATE_SCHOOL_LEAVE_TYPES",
                        res.data.leave_types
                    );
                    context.commit(
                        "UPDATE_STUDENT_COUNT_S_KG_COMPLEX",
                        res.data.allSchoolsAndKGStudentsCount
                    );
                })
                .catch(err => console.log(err));
        },

    },

    getters: {
        // getEventById: (state, id) => {
        //     return state.events.find(event => event.id === id);
        // },
        // catLength(state) {
        //     return state.categories.length;
        // },
        // getMyCounter(state) {
        //     return state.count;
        // },
        getCurrentUserInfos(state) {
            return state.user;
        }
    }
});
