<template>
  <div>
    <div
      class="modal fade"
      id="modal-xl-describe"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Багшийн ээлжит үйл ажиллагааны ажиглалт, оношилгооны хуудас
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Хичээлийн цаг</label
                    >
                    <div class="col-sm-6">
                      {{ form.lesson_time }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Хичээлийн сэдэв</label
                    >
                    <div class="col-sm-6">
                      {{ form.lesson_topic }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Огноо</label
                    >
                    <div class="col-sm-6">
                      {{ form.lesson_ognoo }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body p-0">
                      <table class="table table-hover table-sm">
                        <thead>
                          <tr>
                            <th style="width: 10%">№</th>
                            <th style="width: 30%">Шалгуур үзүүлэлт</th>
                            <th style="width: 10%">Оноо</th>
                            <th style="width: 5%">%</th>
                            <th>Эерэг тэмдэглэл</th>
                            <th>Сайжруулах тэмдэглэл</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1.</td>
                            <td>
                              Зорилготойгоо холбоотой эрэлт хэрэгцээ, сэдэл,
                              сэтгэл хөдлөлийг хянах, чиглүүлэх байдлаар
                              зорилгоо хүлээн зөвшөөрүүлсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles1 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun1Percent().toFixed(1) }}%
                              </span>
                            </td>
                            <td>
                              {{ form.shalles1_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles1_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>Зорилгоо томъёолсон байдал</td>
                            <td>
                              {{ form.shalles2 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun2Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles2_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles2_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>
                              Зорилгоо ойлгосон эсэхийг хянах, үйл ажиллагааг нь
                              төлөвлүүлсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles3 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun3Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles3_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles3_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>
                              Судлагдахууны агуулгыг элементарчилж, эмх цэгцтэй
                              хүргэсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles4 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun4Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles4_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles4_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>
                              Даалгаврын тавил суралцагчдад давамгайлж буй
                              мэдээллийг хүлээн авах хэв шинжид нийцсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles5 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun5Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles5_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles5_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>6.</td>
                            <td>
                              Үйл ажиллагааны хэлбэр суралцагчдад давамгайлж буй
                              сурахуйн хэв шинжид нийцсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles6 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun6Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles6_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles6_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>7.</td>
                            <td>
                              Суралцагчдын гүйцэтгэл дээр урам дэм өгч, магтаал
                              сайшаал хэлсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles7 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun7Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles7_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles7_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>8.</td>
                            <td>
                              Суралцагчдын оюуны үйлийн когнитив системийг бүрэн
                              ажиллуулсан эсэх
                            </td>
                            <td>
                              {{ form.shalles8 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun8Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles8_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles8_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>9.</td>
                            <td>
                              Суралцагчдын гүйцэтгэлд хяналт тавьж гүйцэтгэлийг
                              нэгтгэн дүгнэсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles9 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun9Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles9_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles9_neg_note }}
                            </td>
                          </tr>
                          <tr>
                            <td>10.</td>
                            <td>
                              Эзэмшсэн мэдлэг чадварынх нь ач холбогдол, үнэ
                              цэнийг үнэлүүлж хүлээн зөвшөөрүүлсэн эсэх
                            </td>
                            <td>
                              {{ form.shalles10 }}
                            </td>
                            <td>
                              <span class="badge bg-info">
                                {{ lessonBegun10Percent().toFixed(1) }}%</span
                              >
                            </td>
                            <td>
                              {{ form.shalles10_pos_note }}
                            </td>
                            <td>
                              {{ form.shalles10_neg_note }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                v-show="!editMode"
                @click.prevent="cancelCreate"
              >
                Хаах
              </button>
            </div>
          </form>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "editMode",
    "form",
    "schoolDepartments",
    "schoolClasses",
    "schoolTeachers",
    "schoolSubjects",
  ],
  data() {
    return {
      shalles1change: "",
      shalles2change: "",
      shalles3change: "",
      shalles4change: "",
      shalles5change: "",
      shalles6change: "",
      shalles7change: "",
      shalles8change: "",
      shalles9change: "",
      shalles10change: "",
      //editor config
      // editor: ClassicEditor,
      // editorConfig: {
      //     // The configuration of the editor.
      // },
      lessonTimeNumbers: [
        { value: null, text: "Хичээлийн цаг сонгоно уу?" },
        { value: "1", text: "1-р цаг" },
        { value: "2", text: "2-р цаг" },
        { value: "3", text: "3-р цаг" },
        { value: "4", text: "4-р цаг" },
        { value: "5", text: "5-р цаг" },
        { value: "6", text: "6-р цаг" },
        { value: "7", text: "7-р цаг" },
        { value: "8", text: "8-р цаг" },
        { value: "9", text: "9-р цаг" },
        { value: "10", text: "10-р цаг" },
      ],
    };
  },
  methods: {
    lessonBegun1Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles1change = (this.form.shalles1 / 3) * 100);
    },
    lessonBegun2Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles2change = (this.form.shalles2 / 2) * 100);
    },
    lessonBegun3Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles3change = (this.form.shalles3 / 3) * 100);
    },
    lessonBegun4Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles4change = (this.form.shalles4 / 4) * 100);
    },
    lessonBegun5Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles5change = (this.form.shalles5 / 3) * 100);
    },
    lessonBegun6Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles6change = (this.form.shalles6 / 3) * 100);
    },
    lessonBegun7Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles7change = (this.form.shalles7 / 3) * 100);
    },
    lessonBegun8Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles8change = (this.form.shalles8 / 3) * 100);
    },
    lessonBegun9Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles9change = (this.form.shalles9 / 3) * 100);
    },
    lessonBegun10Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles10change = (this.form.shalles10 / 3) * 100);
    },
    // editMyLessonEvaluation(department) {
    //     //   console.log("Дэлгэрэнгүй мэдээлэл засах" + department);
    //     this.editMode = true;
    //     this.form.reset();
    //     this.form.fill(department);
    // },

    cancelEdit() {
      //   this.form.reset();
      this.editMode = false;
      this.$emit("getWasEditMode", this.editMode);
      this.form.reset();
      $("#modal-xl-describe").modal("hide");
    },
    cancelCreate() {
      //   this.form.reset();
      this.editMode = false;
      this.$emit("getWasEditMode", this.editMode);
      this.form.reset();
      $("#modal-xl-describe").modal("hide");
    },
    modalCloseWhite(editMode) {
      this.$emit("getWasEditMode", editMode);
    },
  },
  mounted() {},
  created() {
    // console.log(this.editMode);
    Fire.$on("loadTeacherLessonEvaluation", () => {
      this.getAllSchoolSubjects();
    });
  },
};
</script>

<style></style>
