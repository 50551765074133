<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <h6 class="card-title p-2">
            Нийт нэр, томъёоны тайлбар: {{ glossaries.length }}
          </h6>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active">
              <button
                type="button"
                class="btn btn-block btn-success btn-xs"
                @click.prevent="createGlossary()"
              >
                Шинэ нэр томъёо бүртгэх
              </button>
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <table class="table table-hover table-striped table-valign-middle">
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Нэр, томъёо</th>
                      <th>Тайлбар</th>
                      <th>Үүсгэсэн</th>
                      <th>Үйлдлүүд</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(glossary, index) in glossaries"
                      :key="glossary.id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ glossary.word }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ glossary.word_description }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ glossary.created_at | dateYearMonthDay }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editGlossary(glossary)"
                          ><i class="fas fa-edit"></i
                        ></a>

                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteGlossary(glossary)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-glossary"
      size="xl"
      ref="modal"
      @hidden="resetModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Нэр, томъёо бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Нэр, томъёо засах</h3>
        </div>
        <div class="card-body form-responsive p-0">
          <form
            @submit.prevent="!editMode ? storeGloassary() : updateGlossary()"
          >
            <div class="card-body">
              <div class="form-group">
                <label>Нэр, томъёо</label>
                <b-form-input
                  v-model="form.word"
                  placeholder="Нэр, томъёо оруулна уу."
                >
                </b-form-input>
              </div>
              <div class="form-group">
                <label>Нэр, томъёоны тайлбар</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="form.word_description"
                  placeholder="Нэр, томъёоны тайлбар оруулна уу."
                >
                </b-form-textarea>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="resetModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      editMode: false,
      glossaries: [],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        reg_emp_id: "",
        upd_emp_id: "",
        word: "",
        word_description: "",
      }),
    };
  },
  components: { Multiselect },
  methods: {
    getAllGlossaries() {
      axios
        .get("/principal/getAllGlossaries")
        .then((res) => {
          this.glossaries = res.data.glossaries;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editGlossary(glossary) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", glossary);
      this.editMode = true;
      this.form.reset();
      this.form.fill(glossary);
      this.$bvModal.show("modal-glossary");
    },
    updateGlossary() {
      this.form
        .put("/principal/updateGlossary/" + this.form.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadGloassaries");
          this.resetModal();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    deleteGlossary(glossary) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/principal/deleteGlossary/" + glossary.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadGloassaries");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    resetModal() {
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-glossary");
    },
    createGlossary() {
      this.$bvModal.show("modal-glossary");
    },
    storeGloassary() {
      this.form
        .post("/principal/createGlossary")
        .then((res) => {
          this.$toastr.s("Нэр, томъёо бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadGloassaries");
          this.resetModal();
        })
        .catch((error) => {
          this.$toastr.e(
            "Бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllGlossaries();
    Fire.$on("loadGloassaries", () => {
      this.getAllGlossaries();
    });
  },
};
</script>

<style></style>
