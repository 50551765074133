<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <strong>Миний оролцох олимпиад, уралдаан тэмцээн</strong>
          <div>
            <div class="float-left">
              <p class="card-title" style="font-size: 80%">
                Нийт олимпиад, уралдаан тэмцээний тоо:
                {{ competitions.length }}
              </p>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="4"> </b-col>
                <b-col sm="8"> </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="show" v-if="competitions.length != 0" rounded="sm">
            <b-row>
              <b-col lg="12" class="my-1">
                <b-form-group
                  label="Нэг хуудсанд харуулах тоо"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageCompetitions"
                    :options="pageOptionsRooms"
                    size="sm"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label="Хайлт хийх утга оруулна уу!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterCompetitions"
                      type="search"
                      placeholder="Хайлт хийх утгаа оруулна уу!"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterCompetitions"
                        @click="filterCompetitions = ''"
                        >Цэвэрлэх</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-pagination
              v-model="currentPageCompetitions"
              :total-rows="totalRowsCompetitions"
              :per-page="perPageCompetitions"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <b-table
              hover
              :items="competitions"
              :fields="fieldsActiveCompetitions"
              :current-page="currentPageCompetitions"
              :per-page="perPageCompetitions"
              :filter="filterCompetitions"
              :filter-ignored-fields="filterIgnoredFieldsCompetitions"
              :filter-included-fields="filterOnCompetitions"
              @filtered="onFilteredCompetitions"
            >
              <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(name)="data">
                <b-badge
                  variant="secondary"
                  class="badge badge-primary text-wrap"
                  style="width: 8rem"
                >
                  {{ data.item.name }}
                </b-badge>
                <a
                  href="#"
                  class="badge bg-success"
                  @click="showCompetition(data.item)"
                  ><i class="fa fa-eye"></i
                ></a>
              </template>

              <template #cell(register_info)="data">
                <div style="width: 20rem">
                  <b>Бүртгэлийн мэдээлэл:</b><br />
                  <div class="text-wrap" style="text-align: justify">
                    {{ data.item.register_info }}
                  </div>
                  <span><b>Олимпиад, уралдаан болох огноо:</b> </span><br />
                  <p style="text-align: right">
                    <b-badge variant="info" class="text-wrap">
                      {{ data.item.start_date | dateYearMonthDay }} :
                      {{ data.item.finish_date | dateYearMonthDay }}
                    </b-badge>
                  </p>
                  <b>Олимпиад, уралдаан болох хаяг:</b><br />
                  <div class="text-wrap" style="text-align: right">
                    {{ data.item.address }}
                  </div>
                  <span><b>Холбоо барих мэдээлэл:</b> </span><br />
                  <div class="text-wrap" style="text-align: right">
                    {{ data.item.contact_information }}
                  </div>
                </div>
              </template>

              <template #cell(competition_teachers)="data">
                <span>
                  <ul class="list-inline">
                    <li
                      class="list-inline-item"
                      v-for="teacher in data.item.competition_teachers"
                      :key="teacher.id"
                    >
                      <img
                        v-if="teacher.my_info != null"
                        alt="Avatar"
                        v-b-tooltip.hover.top.html="
                          '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                          teacher.my_info.department.department_name +
                          '</dd><dt>Овог Нэр</dt><dd>' +
                          teacher.my_info.teacher_last_name +
                          ' <span class=text-uppercase>' +
                          teacher.my_info.teacher_name +
                          '</dd><dt>Утас</dt><dd>' +
                          teacher.my_info.employee_migration_info
                            .teacher_phoneNumber1 +
                          '</span></dd></dl>'
                        "
                        class="direct-chat-img"
                        :src="
                          teacher.my_info.profile_photo_url
                            ? teacher.my_info.profile_photo_url
                            : '/images/users/user.png'
                        "
                      />
                    </li>
                  </ul>
                </span>
              </template>
              <template #cell(competition_students)="data">
                <span>
                  <ul class="list-inline">
                    <li
                      class="list-inline-item"
                      v-for="(student, index) in data.item.competition_students"
                      :key="index"
                    >
                      <img
                        v-if="student.my_info.student_profile_photo_url != null"
                        alt="Avatar"
                        v-b-tooltip.hover.top.html="
                          '<dd><img src=' +
                          student.my_info.student_profile_photo_url +
                          ' style=' +
                          'width:180px!important' +
                          '></dd>' +
                          '<dl class=text-left><dt>Анги:</dt><dd>' +
                          student.my_info_school_class.full_name +
                          '</dd><dt>Овог Нэр</dt><dd>' +
                          student.my_info.student_last_name +
                          ' <span class=text-uppercase>' +
                          student.my_info.student_name +
                          '</span></dd></dl>'
                        "
                        class="direct-chat-img"
                        :src="
                          student.my_info.student_profile_photo_url
                            ? student.my_info.student_profile_photo_url
                            : '/images/users/user.png'
                        "
                      />
                      <img
                        v-if="student.my_info.student_profile_photo_url == null"
                        alt="Avatar"
                        v-b-tooltip.hover.top.html="
                          '<dd><img src=' +
                          '/images/users/user.png' +
                          ' style=' +
                          'width:180px!important' +
                          '></dd>' +
                          '<dl class=text-left><dt>Анги:</dt><dd>' +
                          student.my_info_school_class.full_name +
                          '</dd><dt>Овог Нэр</dt><dd>' +
                          student.my_info.student_last_name +
                          ' <span class=text-uppercase>' +
                          student.my_info.student_name +
                          '</span></dd></dl>'
                        "
                        class="direct-chat-img"
                        :src="
                          student.my_info.student_profile_photo_url
                            ? student.my_info.student_profile_photo_url
                            : '/images/users/user.png'
                        "
                      />
                      {{ student.my_info.student_last_name[0]
                      }}{{ student.my_info.student_last_name[1] }}.{{
                        student.my_info.student_name
                      }}
                    </li>
                  </ul>
                </span>
              </template>
            </b-table>
          </b-overlay>
          <span v-if="competitions.length == 0"
            >Одоогоор олимпиад, уралдаан тэмцээн бүртгэдээгүй байна.</span
          >
        </div>
      </div>
    </div>

    <!-- Дэлгэрэнгүй харах -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-competition"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showCompetitionInfo.name"
      class="modal fade"
      hide-footer
      @hidden="hideInfoCompetition"
    >
      <div class="modal-body">
        <div class="direct-chat-msg" style="text-align: center">
          <!-- <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ showCompetitionInfo.objective }}
              {{ showCompetitionInfo.register_info }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ showCompetitionInfo.start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ showCompetitionInfo.finish_date | dateYearMonthDay }}
            </span>
          </div> -->
          <img
            :src="
              showCompetitionInfo.photo_url
                ? showCompetitionInfo.photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
          />
          <!-- class="direct-chat-img" -->
          <!-- <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>АГУУЛГА:</b><br />
              {{ showCompetitionInfo.object }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>АНАЛИЗ:</b><br />
              {{ showCompetitionInfo.register_info }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ХЯНАЛТЫН ҮЗҮҮЛЭЛТ:</b><br />
              {{ showCompetitionInfo.reward }}
            </div>
          </div> -->
        </div>
        <!-- <div class="row">
          <div class="col-lg-12 mt-2">
            <div
              class="callout callout-success"
              v-if="showCompetitionInfo.point > 0"
            >
              <small>
                <strong>Удахгүй харагдах байдлыг сайжруулна :)</strong></small
              >
              <p>
                <small>{{
                  showCompetitionInfo.problem_solution_protect_info
                }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сарын төлөвлөгөө авсан багш нар</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b>
                  {{ showCompetitionInfo.problem_solution_protect_date }}%
                </span>
              </div>
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Зураг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(emp, index) in monthPlanEmps"
                    :key="emp.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.department.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.position.position_name }}
                      </td>
                      <td>
                        <img
                          :src="
                            emp.employee.profile_photo_url
                              ? emp.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{
                          emp.employee.teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}.
                        {{ emp.employee.teacher_name | uppercase }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(emp)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="hideInfoCompetition()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      competitions: [],

      //b-table тохиргоо
      totalRowsCompetitions: 1,
      currentPageCompetitions: 1,
      perPageCompetitions: 25,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCompetitions: "",
      filterOnCompetitions: [],
      filterIgnoredFieldsCompetitions: [
        "competition_teachers",
        "competition_students",
      ],
      fieldsActiveCompetitions: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Олимпиад уралдааны нэр",
          sortable: true,
        },
        // {
        //   key: "objective",
        //   label: "Зорилго",
        //   sortable: true,
        // },
        {
          key: "register_info",
          label: "Товч мэдээлэл",
          sortable: true,
        },
        {
          key: "competition_teachers",
          label: "Хариуцах багш",
          sortable: true,
        },
        {
          key: "competition_students",
          label: "Оролцох суралцагчид",
          sortable: true,
        },
        // {
        //   key: "actions",
        //   label: "Үйлдлүүд",
        //   sortable: false,
        // },
      ],
      showMode: false,
      showCompetitionInfo: "",

      editMode: false,
      form: new Form({
        id: "",
        // year_id: "",
        // school_id: "",
        // enrol_emp_id: "",
        name: "",
        objective: "",
        register_info: "",
        start_date: "",
        finish_date: "",
        reward: "",
        problem_solution_protect_info: "",
        problem_solution_protect_date: "",
        results_meeting: "",
        address: "",
        contact_information: "",
        photo_url: "",
      }),
      //   Зураг
      getSelectedItemImg: "",
      fileInputKey: 0,

      //   Багш
      formTeachers: new Form({
        id: "",
        competition_id: "",
        teachers: [],
      }),

      //   Суралцагч
      formStudents: new Form({
        id: "",
        competition_id: "",
        students: [],
        subject: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllCompetitions() {
      this.show = true;
      axios
        .get("/student/getAllCompetitionsStudent")
        .then((response) => {
          //   this.competitions =

          let check = response.data.competitions;
          //   console.log(check);
          let arr = [];
          check.forEach((element) => {
            arr.push(element.my_owner_competition);
          });
          this.competitions = arr;

          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //b-table тохирго
    onFilteredCompetitions(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCompetitions = filteredItems.length;
      this.currentPageCompetitions = 1;
    },
    showCompetition(competition) {
      this.showCompetitionInfo = competition;
      this.$bvModal.show("modal-show-competition");
    },
    hideInfoCompetition() {
      this.showCompetitionInfo = "";
      this.$bvModal.hide("modal-show-competition");
    },
  },
  created() {
    this.getAllCompetitions();
    Fire.$on("getCompetitions", () => {
      this.getAllCompetitions();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
