<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Таксономын хавтгай</h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн систем</th>
                <th>Үйлийн системийн түвшин</th>
                <th>Үйлийн системийн түвшиний оператор</th>
                <th>Үйлийн системийн түвшиний операторыг илэрхийлэх үйл үг</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(info, index) in taxanomyInfos"
                :key="info.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ info.mental_system_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                    <th>
                  <span v-for="(level, i) in info.levels" :key="level.id"
                    >{{ i }}.{{ level.tax_lev_name }} <br />
                  </span>

                    </th>
                </td>
                <td></td>
                <td></td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ info.created_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ info.updated_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTaxanomySystem(info)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <!-- <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteComplaint(schoolRegEmp)"
                    ><i class="fas fa-trash-alt"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      taxanomyInfos: [],
    };
  },
  components: {
    // searchManager: searchManager,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/taxanomyallinfo")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.taxanomyInfos = response.data.taxanomyInfo;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTaxanomySystem(mental) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editMode = true;
      this.form.reset();
      this.form.fill(mental);
    },
    updateTaxanomySystem() {
      this.form
        .put("/curriculumadministrator/updateTaxanomySystem/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyMentalSystem");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createTaxanomySystem() {
      this.form
        .post("/curriculumadministrator/createTaxanomySystem")
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyMentalSystem");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    // console.log('TaxanomyTable: сул хоёр баганын мэдээллийг ирээдүйд засна. о') //Устгаж болохгүй
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyMentalSystem", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
