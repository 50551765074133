<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Соёл хүмүүжлийн ажил</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3">
                  <b-form-select
                    v-model="child_id"
                    @input="getParentProjects"
                    :options="childOptions"
                    text-field="student_name"
                    value-field="user_id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('child_id'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <b-overlay :show="projectIsLoaded" rounded="sm">
          <b-card-group deck class="p-3 my-5">
            <div
              class="col-lg-3 mb-3"
              v-for="project in studentProjects"
              :key="project.id"
            >
              <div class="card">
                <div class="ribbon ribbon-bottom-right"></div>
                <div class="card-container">
                  <div class="box">
                    <img :src="project.image" alt="Avatar" class="image-card" />
                    <div class="ribbon ribbon-top-right">
                      <span>{{ project.time_type }}</span>
                    </div>
                  </div>
                  <div class="overlay text-center">
                    <div class="hoverText">
                      <div>
                        <div class="hoverBtn">
                          <b-badge
                            v-if="project.choosed == 1"
                            pill
                            class="float-right mt-2"
                            variant="outline-success"
                            >БҮРТГҮҮЛСЭН</b-badge
                          >
                          <b-button
                            v-else-if="project.limit != project.registered"
                            size="sm"
                            class="mt-2"
                            href="#"
                            variant="success"
                            @click.prevent="chooseProject(project)"
                            >СОНГОХ</b-button
                          >
                          <b-badge v-else pill class="float-right mt-2" variant="danger"
                            >БҮРТГЭЛ ДҮҮРСЭН</b-badge
                          >
                        </div>
                        <small>
                          <span v-show="project.time_type != 'Тогтмол'">
                            <i class="fas fa-calendar-alt"></i>
                            <b
                              >{{ project.start_date | dateYearMonthDay }} -
                              {{ project.end_date | dateYearMonthDay }}
                            </b>
                            <br />
                          </span>
                          <span>
                            <i class="far fa-clock"></i>
                            <b>
                              Өдөр: {{ project.day }}, Цаг: {{ project.startTime }} -
                              {{ project.endTime }}
                            </b>
                            <br />
                          </span>
                          <div class="hoverBtn">
                            <b-button
                              v-if="project.choosed == 1"
                              size="sm"
                              class="mt-2 mr-2"
                              href="#"
                              variant="success"
                              @click.prevent="showComment(project)"
                              >Эсээ харах</b-button
                            >
                          </div>
                          {{ project.description }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      <small class="text-muted">
                        {{ project.name.slice(0, 30) }}
                      </small>
                    </h5>
                    <p
                      class="card-text"
                      style="text-align: justify; height: 0.8rem; word-break: break-all"
                    >
                      <small class="text-muted">
                        Анги:
                        <b>
                          <span
                            v-for="angi in project.scope
                              .split('-')
                              .filter((i) => i != '')"
                            :key="angi"
                          >
                            {{ angi != "" ? angi + ", " : "" }}
                          </span>
                        </b>
                      </small>
                    </p>
                  </div>
                  <div class="card-footer pt-0">
                    <small class="text-muted">
                      <span v-if="project.choosed == 1">
                        <b-icon icon="check-square-fill" variant="success"></b-icon>
                        <b>БҮРТГҮҮЛСЭН</b>
                      </span>
                      <i class="far fa-calendar-check ml-2"></i>
                      <b>{{ project.start_date | dateYearMonthDay }}</b>
                      <b-badge
                        v-if="project.limit == project.registered"
                        pill
                        class="float-right mt-2"
                        variant="danger"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                      <b-badge v-else pill class="float-right mt-2" variant="dark"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </b-card-group>
        </b-overlay>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ"
      hide-footer
    >
      <div class="modal-body">
        <div class="row" v-if="beforeComments.length > 0">
          <div class="col-lg-12">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card v-html="comm.comment" class="p-1 text-justify" />
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <p class="text-center text-muted h5 mt-5">
              <b-icon icon="inboxes"></b-icon>
            </p>
            <p class="text-center text-muted font-italic text-sm">
              Одоогоор суралцагч эсээ бичээгүй байна.
            </p>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  data() {
    return {
      projectIsLoaded: false,
      studentProjects: [],
      child_id: "",
      childOptions: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,
      form: new Form({
        id: null,
        status: "",
        project_type: "",
        time_type: "",
        scopeList: [],
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        limit: "",
        registered: "",
        reg_close_date: "",
        created_at: "",
      }),
      beforeComments: [],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    checkCloseDate(pDate) {
      return moment(moment(new Date()).format()).isBefore(moment(pDate).format());
    },
    getChilds() {
      axios
        .get("/project_parent/getChilList")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0) this.child_id = this.childOptions[0].user_id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getParentProjects() {
      this.projectIsLoaded = true;
      axios
        .post("/project_parent/getParentProjects", {
          child_id: this.child_id,
          project_type: "СХА",
        })
        .then((response) => {
          this.studentProjects = response.data.myProjects;
          this.projectIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showComment(project) {
      this.beforeComments = [];
      axios
        .post("/project_parent/getChildComment/" + project.id, {
          child_id: this.child_id,
          type: "project",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$bvModal.show("modal-project-comment");
    },
    //======================== Бүртгэгдсэн төлөвт шилжүүлэх ================================
    chooseProject(project) {
      Swal.fire({
        title: "Соёл хүмүүжлийн ажил сонгох гэж байна?",
        text:
          'Та "' +
          project.name +
          '" Соёл хүмүүжлийн ажил сонгох гэж байна. Итгэлтэй байна уу?',
        showDenyButton: true,
        imageUrl: project.image,
        imageHeight: 200,
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#41b882",
        denyButtonColor: "#595959",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Соёл хүмүүжлийн ажил сонгож байна...";
          axios
            .put("/project_parent/updateChildChose/" + project.id, {
              child_id: this.child_id,
            })
            .then((response) => {
              if (response.data.error == "error") {
                Swal.fire({
                  icon: "error",
                  title: "Сонгох боломжгүй...",
                  text: response.data.errorMsj,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Амжилттай",
                  text: response.data.errorMsj,
                });
                Fire.$emit("getMyProjects");
              }
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    toggleSelect() {
      console.log("filter department");
    },
  },
  created() {
    this.getChilds();
    Fire.$on("getMyProjects", () => {
      this.getParentProjects();
    });
  },
};
</script>

<style></style>
