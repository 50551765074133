<template>
    <div class="row">
        <div class="col-lg-8 mt-3">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">
                        Ажлаас чөлөөлөгдсөн багш нарын жагсаалт
                    </h3>
                    <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    Ажлаас чөлөөлөгдсөн багш нарын тоо:
                                    {{ getAllLeavedEmployees.length }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
                            </b-form-select>

                            <b-form-select
                              v-model="sortDesc"
                              :disabled="!sortBy"
                              :aria-describedby="ariaDescribedby"
                              size="sm"
                              class="w-25"
                            >
                              <option :value="false">Asc</option>
                              <option :value="true">Desc</option>
                            </b-form-select>
                          </b-input-group>
                        </b-form-group>
                      </b-col> -->
                        <b-col lg="12" class="my-1">
                            <b-form-group
                                label="Нэг хуудсанд харуулах тоо"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                            </b-form-group>

                            <b-form-group
                                label="Хайлт хийх утга оруулна уу!"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        placeholder="Хайлт хийх утгаа оруулна уу!"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >Цэвэрлэх</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                    ></b-pagination>
                    <b-table
                        hover
                        :items="getAllLeavedEmployees"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        style="font-size: 80%"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :filter="filter"
                    >
                        <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(status1)="data">
                            <span class="badge bg-danger">Чөлөөлөгдсөн</span>
                            <br />
                            <b-badge
                                variant="info"
                                class="text-wrap"
                                style="width: 8rem"
                                v-if="
                                    Array.isArray(
                                        data.item.my_employee_info
                                            .leave_type_teacher
                                    )
                                "
                            >
                                <span
                                    v-for="(shalt, i) in data.item
                                        .my_employee_info.leave_type_teacher"
                                    :key="shalt.id"
                                >
                                    <span
                                        v-if="shalt.my_leave_type_info !== null"
                                    >
                                        {{ i + 1 }}.
                                        {{ shalt.my_leave_type_info.name }}
                                        <br />
                                    </span>
                                </span>
                            </b-badge>
                            <span v-else
                                >Шилжсэн шалтгаан бүртгэгдээгүй байна.</span
                            >
                        </template>
                        <template #cell(employee_shiljilt_shaltgaan)="data">
                            <b-badge
                                variant="light"
                                class="text-wrap"
                                style="width: 8rem"
                                v-if="
                                    data.item.employee_shiljilt_shaltgaan !==
                                        null
                                "
                            >
                                {{ data.item.employee_shiljilt_shaltgaan }}
                            </b-badge>
                            <b-badge
                                variant="warning"
                                class="text-wrap"
                                style="width: 8rem"
                                v-else
                            >
                                Тайлбар тэмдэглэгдээгүй байна.
                            </b-badge>
                        </template>
                        <template #cell(teacher_last_name)="data">
                            <b-badge
                                variant="secondary"
                                class="text-wrap"
                                style="width: 8rem"
                            >
                                {{
                                    data.item.my_employee_info
                                        .teacher_last_name[0]
                                }}.
                                {{ data.item.my_employee_info.teacher_name }}
                            </b-badge>
                        </template>

                        <template #cell(users_email)="data">
                            {{ data.item.my_employee_info.users_info.email }}
                        </template>

                        <template #cell(employees_deleted_at)="data">
                            {{ data.item.deleted_at | dateYearMonthDay }}
                        </template>

                        <template #cell(actions)="data">
                            <a
                                href="#"
                                class="badge bg-primary"
                                @click="refreshSchoolEmployee(data.item)"
                                ><i class="fa fa-check"></i
                            ></a>
                        </template>
                    </b-table>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <div class="col-lg-4 mt-3" v-show="editMode">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title" v-show="editMode">
                        Багшийн ажлаас гарсан шалтгааны тодорхойлох
                    </h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form
                    @submit.prevent="
                        editMode ? updateSchoolEmployeesRefresh() : ''
                    "
                >
                    <div class="card-body">
                        <div class="form-group">
                            <label>Ажлаас чөлөөлөгдөх шалтгаан</label>
                            <b-form-select
                                v-model="form.leave_type_list"
                                :options="leave_types"
                                text-field="name"
                                value-field="id"
                                multiple
                                :select-size="4"
                            ></b-form-select>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label
                                >Ажлаас гарсан шалтгааны тайлбар
                                тэмдэглэх</label
                            >
                            <textarea
                                name="employee_shiljilt_shaltgaan"
                                id=""
                                cols="30"
                                rows="5"
                                v-model="form.employee_shiljilt_shaltgaan"
                                :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has(
                                            'employee_shiljilt_shaltgaan'
                                        )
                                    }
                                ]"
                                placeholder="Ажлаас гарсан шалтгааны тайлбар
                                тэмдэглэх"
                            ></textarea>
                            <has-error
                                :form="form"
                                field="employee_shiljilt_shaltgaan"
                            ></has-error>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            v-show="editMode"
                        >
                            Хадгалах
                        </button>
                        <button
                            class="btn btn-secondary"
                            v-show="editMode"
                            @click.prevent="cancelEdit"
                        >
                            Болих
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
    data() {
        return {
            selected: 5,
            action: "",
            editMode: false,
            getAllLeavedEmployees: [],
            leave_types: [],
            form: new Form({
                employee_id: "",
                user_id: "",
                leave_type_list: [],
                employee_shiljilt_shaltgaan: ""
            }),

            //b-table тохиргоо
            totalRows: 1,
            currentPage: 1,
            perPage: 150,
            pageOptions: [
                1,
                2,
                3,
                4,
                5,
                10,
                15,
                25,
                35,
                45,
                55,
                65,
                75,
                100,
                125,
                150,
                175,
                200,
                { value: 300, text: "Илүү олноор харах" }
            ],
            //   sortBy: "",
            //   sortDesc: false,
            //   sortDirection: "asc",
            filter: "",
            filterOn: [],
            filtered: 0,
            // filterIgnoredFieldsDocuments: [
            //     "my_doc_proccess",
            //     "my_doc_owner",
            //     "my_doc_department",
            //     // "my_doc_classify_item",
            // ],
            fields: [
                {
                    key: "index",
                    label: "№",
                    sortable: true
                },
                {
                    key: "status1",
                    label: "Төлөв",
                    sortable: true
                },
                {
                    key: "employee_shiljilt_shaltgaan",
                    label: "Тайлбар",
                    sortable: true
                },
                {
                    key: "teacher_last_name",
                    label: "Овог нэр",
                    sortable: true
                },
                {
                    key: "users_email",
                    label: "Цахим шуудан",
                    sortable: true
                },

                {
                    key: "employees_deleted_at",
                    label: "Чөлөөлөгдсөн огноо",
                    sortable: true
                    //   sortDirection: "desc",
                },
                {
                    key: "actions",
                    label: "Үйлдлүүд",
                    sortable: false
                }
            ]
        };
    },

    methods: {
        getAllLeavedInfo() {
            axios
                .get("/manager/getAllLeavedEmployeesInfo")
                .then(response => {
                    this.leave_types = response.data.school_leave_types;
                })
                .catch(error => {
                    //   console.log(error);
                });
        },
        //b-table тохирго
        onFiltered(filteredItems) {
            //   console.log(filteredItems);
            this.filtered = filteredItems.length;
            //   console.log(filteredItems.length);
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getLeavedTeachersAll() {
            axios
                .get("/manager/getLeavedTeachersAll")
                .then(response => {
                    this.getAllLeavedEmployees =
                        response.data.getAllLeavedEmployees;
                })
                .catch(error => {
                    //   console.log(error);
                });
        },
        refreshSchoolEmployee(schoolEntrant) {
            // console.log(schoolEntrant);
            this.editMode = true;
            this.form.reset();
            this.form.fill(schoolEntrant);
            // this.form.employee_id = schoolEntrant.employee_id;
            // if (schoolEntrant.status1 == "registered")
            //     this.form.checked = false;
            // else if (schoolEntrant.status1 == "confirmed")
            //     this.form.checked = true;
            this.form.leave_type_list = [];

            let checkLTL = schoolEntrant.my_employee_info.leave_type_teacher;
            // console.log(checkLTL)

            this.leave_types.forEach(el1 => {
                checkLTL.forEach(el2 => {
                    if (el1.id === el2.leave_type_id) {
                        this.form.leave_type_list.push(el1.id);
                    }
                });
            });
        },
        updateSchoolEmployeesRefresh() {
            //   console.log(this.form);
            this.form
                .put(
                    "/manager/updateSchoolEmployeesRefresh/" +
                        this.form.employee_id
                )
                .then(response => {
                    //   console.log(response.data);
                    this.$toastr.s("Амжилттай", "Шинэчилсэн");
                    Fire.$emit("loadSchoolStudents");
                    this.form.reset();
                    this.editMode = !this.editMode;
                })
                .catch(errors => {
                    //   console.log(errors);
                });
        },
        deleteSchoolStudent(schoolteacher) {
            //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
        },
        cancelEdit() {
            this.form.reset();
            this.editMode = !this.editMode;
        }
    },
    created() {
        this.getAllLeavedInfo();
        this.getLeavedTeachersAll();
        // this.getAllEntrants();
        Fire.$on("loadSchoolStudents", () => {
            this.getLeavedTeachersAll();
            // this.getSchoolClassLeaveStudents();
        });
    }
};
</script>

<style></style>
