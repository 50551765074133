import { render, staticRenderFns } from "./IndexCurriculumTypeStat.vue?vue&type=template&id=7b0127bc&scoped=true&"
import script from "./IndexCurriculumTypeStat.vue?vue&type=script&lang=js&"
export * from "./IndexCurriculumTypeStat.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&id=7b0127bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b0127bc",
  null
  
)

export default component.exports