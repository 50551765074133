<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Taxanomy verbs and Quastion(Тархины үйлийн системийн түвшинг
            илэрхийлэх үйл үг ба асуултууд)
          </h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн систем</th>
                <th>Үйлийн системийн түвшин</th>
                <th>Үйлийн системийн түвшний оператор</th>
                <th>Үйлийн операторын үйл үг</th>
                <th>Үйлийн операторын үйл үгийн тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(verb, index) in alloperationnameverbwords"
                :key="verb.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.mental_system_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.tax_lev_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.tax_lev_name_operation }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.tax_lev_name_operation_verb }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.tax_lev_name_operation_verb_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ verb.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editOperationNameVerb(verb)"
                    ><i class="fas fa-edit"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteOperationNameVerb(verb)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Үйл үг бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Үйл үг засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode ? createOperationNameVerb() : updateOperationNameVerb()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <multiselect
                  v-model="form.tax_lev_name_oper_id"
                  deselect-label="Can't remove this value"
                  track-by="tax_lev_name_operation"
                  label="tax_lev_name_operation"
                  placeholder="Select one"
                  :custom-label="nameWithLang"
                  :options="alllevelnameoperations"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.tax_lev_name_operation }}</strong> is
                    written in<strong>
                      {{ option.tax_lev_name_operation_explain }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Үйл үгийн нэр</label>
                <b-textarea
                  name="tax_lev_name_operation_verb"
                  v-model="form.tax_lev_name_operation_verb"
                  text-field="tax_lev_name_operation_verb"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="tax_lev_name_operation_verb"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэршлийн тайлбар</label>

                <b-textarea
                  name="tax_lev_name_operation_verb_explain"
                  v-model="form.tax_lev_name_operation_verb_explain"
                  text-field="tax_lev_name_operation_verb_explain"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="tax_lev_name_operation_verb_explain"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      alloperationnameverbwords: [],
      alllevelnameoperations: [],

      form: new Form({
        id: "",
        year_id: "", //backend талд хадгална
        school_id: "", //backend талд хадгална
        department_id: "", //backend талд хадгална
        create_emp_id: "", //backend талд хадгална
        position_id: "", //backend талд хадгална
        tax_lev_name_oper_id: "",
        tax_lev_name_operation_verb: "",
        tax_lev_name_operation_verb_explain: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllInfo() {
      axios
        .get(
          "/curriculumadministrator/getAlltaxanomyLevelNameOperationVerbWord"
        )
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.alloperationnameverbwords =
            response.data.alloperationnameverbword;
          this.alllevelnameoperations = response.data.alllevelnameoperations;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editOperationNameVerb(verb) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", verb);
      this.editMode = true;
      this.form.reset();
      this.form.fill(verb);

      let selectEl = this.alllevelnameoperations.filter(function (e) {
        return e.id == verb.tax_lev_name_oper_id;
      });

      this.form.tax_lev_name_oper_id = selectEl[0];
    },
    updateOperationNameVerb() {
      this.form
        .put(
          "/curriculumadministrator/updatetaxanomyLevelNameOperationVerbWord/" +
            this.form.id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyLevNameOperationVerb");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createOperationNameVerb() {
      this.form
        .post(
          "/curriculumadministrator/createtaxanomyLevelNameOperationVerbWord"
        )
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevNameOperationVerb");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    deleteOperationNameVerb(verb) {
      this.form
        .delete(`/curriculumadministrator/deletetaxanomyVerbWord/${verb.id}`)
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийн түвшний операторын илэрхийлэх үйл үгийг устгалаа.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevNameOperationVerb");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн системийн түвшний операторын илэрхийлэх үйл үгийг устгаж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    nameWithLang({ tax_lev_name, tax_lev_name_operation }) {
      return `${tax_lev_name} — [${tax_lev_name_operation}]`;
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyLevNameOperationVerb", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
