<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Багш, ажилтан</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#timeline" data-toggle="tab"
                >Суралцагч</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#parent" data-toggle="tab">Эцэг эх</a>
            </li>
            <multiselect
              v-model="yearId"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="id"
              :custom-label="customYearName"
              placeholder="Хичээлийн жил сонгоно уу?"
              :options="years"
              :searchable="true"
              :allow-empty="true"
              @input="getAllMeets"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong
                  >{{ option.start_date | dateYear }}-{{
                    option.finish_date | dateYear
                  }}
                  - хичээлийн жил
                </strong></template
              >
            </multiselect>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <button
                type="button"
                class="btn btn-block btn-success btn-xs"
                @click.prevent="createFillFromForTeacher()"
              >
                Багшийн уулзалт бүртгэх
              </button>
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <table
                  class="table table-hover table-striped table-valign-middle"
                >
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Хариуцах эзэн</th>
                      <th>Хэний асуудлаар</th>
                      <th>Хамт уулзсан</th>
                      <th>Асуудлын төрөл болон задаргаа</th>
                      <th>Уулзалт болсон цаг</th>
                      <th>Хэддэх уулзалт</th>
                      <th>Тэмдэглэл</th>
                      <th>Ажиглалт</th>
                      <th>Дүгнэлт</th>
                      <th>Үүсгэсэн</th>
                      <th>Үйлдлүүд</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(tMeets, index) in teacherMeets"
                      :key="tMeets.id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td tabindex="0">
                        <b-badge variant="primary" pill v-if="tMeets.reg_emp">
                          {{
                            tMeets.reg_emp.teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}. {{ tMeets.reg_emp.teacher_name }}
                        </b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_whom_by_prob }} <br />
                        <b-badge variant="info" v-if="tMeets.meet_reg_emp">
                          {{
                            tMeets.meet_reg_emp.teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}.{{ tMeets.meet_reg_emp.teacher_name }} багш уулзав
                        </b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge
                          variant="info"
                          v-if="tMeets.my_users.length != 0"
                        >
                          <span
                            v-for="(user, index) in tMeets.my_users"
                            :key="user.user_id"
                          >
                            <span v-if="user.user_info">
                              {{
                                user.user_info.teacher_last_name
                                  | capitalizeAndEkhniiVseg
                              }}. {{ user.user_info.teacher_name }} <br />
                            </span>
                          </span>
                        </b-badge>
                        <b-badge variant="info" v-else>
                          Хамт уулзсан багш,<br />
                          ажилтан бүртгэгдээгүй
                        </b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        Асуудлын задаргааг дэлгэрэнгүй харуулна
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_start_time }} :
                        {{ tMeets.psy_meet_end_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_count }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_note }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_observation }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.psy_meet_conclusion }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ tMeets.created_at | dateYearMonthDay }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolTeacherMeet(tMeets)"
                          ><i class="fas fa-edit"></i
                        ></a>

                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolTeacherMeet(tMeets)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="timeline">
              <button
                type="button"
                class="btn btn-block btn-success btn-xs"
                @click.prevent="createFillFromForStudent()"
              >
                Суралцагчийн уулзалт бүртгэх
              </button>
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <table
                  class="table table-hover table-striped table-valign-middle"
                >
                  <thead>
                    <tr role="row">
                      <th>№</th>
                      <th>Хариуцах эзэн</th>
                      <th>Хэний асуудлаар</th>
                      <th>Хамт уулзсан</th>
                      <th>Асуудлын төрөл болон задаргаа</th>
                      <th>Уулзалт болсон цаг</th>
                      <th>Хэддэх уулзалт</th>
                      <th>Тэмдэглэл</th>
                      <th>Ажиглалт</th>
                      <th>Дүгнэлт</th>
                      <th>Үүсгэсэн</th>
                      <th>Үйлдлүүд</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="odd"
                      v-for="(sMeets, index) in studentMeets"
                      :key="sMeets.id"
                    >
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td tabindex="0"></td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_whom_by_prob }} <br />
                        <b-badge variant="info" v-if="sMeets.meet_reg_pro">
                          {{
                            sMeets.meet_reg_pro.student_last_name
                              | capitalizeAndEkhniiVseg
                          }}.{{ sMeets.meet_reg_pro.student_name }} суралцагч
                          уулзав
                        </b-badge>
                        <b-badge variant="warning" v-else
                          >Суралцагч бүртгэгдээгүй</b-badge
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge
                          variant="info"
                          v-if="sMeets.meet_reg_profile.length > 0"
                        >
                          <span
                            v-for="(user, index) in sMeets.meet_reg_profile"
                            :key="user.user_id"
                          >
                            <span v-if="user.user_profile_info">
                              {{
                                user.user_profile_info.student_last_name
                                  | capitalizeAndEkhniiVseg
                              }}. {{ user.user_profile_info.student_name }}
                              <br />
                            </span>
                          </span>
                        </b-badge>
                        <b-badge variant="info" v-else>
                          Хамт уулзсан,<br />
                          суралцагчид бүртгэгдээгүй
                        </b-badge>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        Асуудлын задаргааг дэлгэрэнгүй харуулна
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_start_time }} :
                        {{ sMeets.psy_meet_end_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_count }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_note }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_observation }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.psy_meet_conclusion }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ sMeets.created_at | dateYearMonthDay }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolStudentMeet(sMeets)"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolStudentMeet(sMeets)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="parent">
              <button
                type="button"
                class="btn btn-block btn-success btn-xs"
                @click.prevent="createFillFromForParent()"
              >
                Эцэг эхийн уулзалт бүртгэх
              </button>
              <div
                class="card-body table-responsive p-0"
                style="overflow-x: auto"
              >
                <b-overlay :show="loaded" rounded="sm">
                  <b-row>
                    <b-col lg="12" class="my-1">
                      <b-form-group
                        label="Нэг хуудсанд харуулах тоо"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPageMeetParents"
                          :options="pageOptionsMeetParents"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>

                      <!-- <b-form-group
                        label="Хайлт хийх утга оруулна уу!"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-input"
                            v-model="filterMeetParents"
                            type="search"
                            placeholder="Хайлт хийх утгаа оруулна уу!"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              :disabled="!filterMeetParents"
                              @click="filterMeetParents = ''"
                              >Цэвэрлэх</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                    </b-col>
                  </b-row>
                  <b-pagination
                    v-model="currentPageMeetParents"
                    :total-rows="totalRowsMeetParents"
                    :per-page="perPageMeetParents"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                  <b-table
                    hover
                    :items="parentMeets"
                    :fields="fieldsMeetParent"
                    :current-page="currentPageMeetParents"
                    :per-page="perPageMeetParents"
                  >
                    <!-- :filter="filterMeetParents"
                    :filter-included-fields="filterOnRooms"
                    @filtered="onFilteredMeetParents" -->
                    <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(reg_emp)="data">
                      <b-badge variant="info" v-if="data.item.reg_emp">
                        {{ data.item.reg_emp.teacher_last_name[0] }}.
                        {{ data.item.reg_emp.teacher_name }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_whom_by_prob)="data">
                      <b-badge variant="secondary">
                        {{ data.item.psy_meet_whom_by_prob }}
                      </b-badge>
                      <br />
                      <b-badge
                        variant="info"
                        v-if="data.item.my_parent_info != null"
                      >
                        Аав:
                        {{
                          data.item.my_parent_info.parent_father_ovog
                            | capitalizeAndEkhniiVseg
                        }}.{{ data.item.my_parent_info.parent_father_name }}
                        <br />
                        Ээж:
                        {{
                          data.item.my_parent_info.parent_mother_ovog
                            | capitalizeAndEkhniiVseg
                        }}.{{ data.item.my_parent_info.parent_mother_name }}
                        <br />
                        эцэг эх уулзав
                      </b-badge>
                      <b-badge variant="warning" v-else
                        >Эцэг эх бүртгэгдээгүй</b-badge
                      >
                    </template>
                    <template #cell(meet_reg_parents_profile)="data">
                      <b-badge
                        variant="success"
                        v-if="data.item.meet_reg_parents_profile.length != 0"
                        style="text-align: left"
                      >
                        <span
                          v-for="(parent, index) in data.item
                            .meet_reg_parents_profile"
                          :key="index"
                        >
                          {{ index + 1 }}). Аав:
                          {{
                            parent.user_profile_parent_info.parent_father_ovog
                              | capitalizeAndEkhniiVseg
                          }}.{{
                            parent.user_profile_parent_info.parent_father_name
                          }}
                          <br />
                          Ээж:
                          {{
                            parent.user_profile_parent_info.parent_mother_ovog
                              | capitalizeAndEkhniiVseg
                          }}.{{
                            parent.user_profile_parent_info.parent_mother_name
                          }}
                          <br />
                        </span>
                        <br />
                        эцэг эхчүүд уулзав
                      </b-badge>
                      <b-badge variant="warning" v-else
                        >Хамт уулзсан эцэг эх <br />
                        бүртгэгдээгүй</b-badge
                      >
                    </template>
                    <template #cell(my_cat_details)="data">
                      <b-badge
                        variant="success"
                        style="
                          font-size: 80%;
                          width: 8rem;
                          text-align: left;
                          white-space: pre-wrap;
                        "
                        v-for="(catDetail, index) in data.item.my_cat_details"
                        :key="index"
                      >
                        {{ catDetail.my_category_info.psy_cat_detail }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_start_time)="data">
                      <b-badge>
                        {{ data.item.psy_meet_start_time }} :
                        {{ data.item.psy_meet_end_time }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_count)="data">
                      <b-badge>
                        {{ data.item.psy_meet_count }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_note)="data">
                      <b-badge
                        class="text-wrap"
                        style="
                          font-size: 80%;
                          width: 20rem;
                          text-align: justify;
                        "
                      >
                        {{ data.item.psy_meet_note }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_observation)="data">
                      <b-badge
                        class="text-wrap"
                        style="
                          font-size: 80%;
                          width: 20rem;
                          text-align: justify;
                        "
                      >
                        {{ data.item.psy_meet_observation }}
                      </b-badge>
                    </template>
                    <template #cell(psy_meet_conclusion)="data">
                      <b-badge
                        class="text-wrap"
                        style="
                          font-size: 80%;
                          width: 20rem;
                          text-align: justify;
                        "
                      >
                        {{ data.item.psy_meet_conclusion }}
                      </b-badge>
                    </template>
                    <template #cell(created_at)="data">
                      <b-badge>
                        {{ data.item.created_at | dateYearMonthDay }}
                      </b-badge>
                    </template>
                    <template #cell(updated_at)="data">
                      <b-badge>
                        {{ data.item.updated_at | dateYearMonthDay }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <a
                        href="#"
                        class="badge bg-warning"
                        @click="editSchoolParentMeet(data.item)"
                        ><i class="fas fa-edit"></i
                      ></a>

                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="deleteSchoolParentMeet(data.item)"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- @show="resetTeacherMeetModal" -->
    <!-- @ok="handleOk" -->
    <b-modal
      id="modal-teacher-meet"
      size="xl"
      ref="modal"
      title="Багш ажилтантай уулзсан уулзалтын модуль"
      @hidden="resetTeacherMeetModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editModeTeacher">Уулзалт бүртгэх</h3>
          <h3 class="card-title" v-show="editModeTeacher">Уулзалт засах</h3>
        </div>
        <div class="card-body form-responsive p-0">
          <form
            @submit.prevent="
              !editModeTeacher
                ? createSchoolTeacherMeet()
                : updateSchoolTeacherMeet()
            "
          >
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Багш сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_emp_id"
                      deselect-label="Can't remove this value"
                      track-by="emp_mig_id"
                      label="emp_mig_id"
                      :custom-label="customTeachers"
                      placeholder="Багшийн нэр сонгох"
                      :options="teachers"
                      :searchable="true"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.emp_teacher_last_name[0] }}.
                          {{ option.emp_teacher_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Хамт уулзсан багш нар сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_users"
                      deselect-label="Can't remove this value"
                      track-by="emp_mig_id"
                      label="emp_mig_id"
                      :custom-label="customTeachers"
                      placeholder="Багшийн нэр сонгох"
                      :options="teachers"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.emp_teacher_last_name[0] }}.
                          {{ option.emp_teacher_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Асуудлын ангилал, түүний задаргаа асуудлууд</label>
                    <multiselect
                      v-model="formMeet.selectProbCategoryDetails"
                      deselect-label="Can't remove this value"
                      track-by="id"
                      label="id"
                      :custom-label="problemCatDetailName"
                      placeholder="Асуудлын ангилал сонгох"
                      :options="problemCategoryDetails"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.psy_cat_prob_cat_name }}-{{
                            option.psy_cat_detail
                          }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Хэний асуудлаар</label>
                    <b-form-input
                      v-model="formMeet.psy_meet_whom_by_prob"
                      placeholder="Хэний асуудлаар"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт эхэлсэн цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_start_time"
                      id="datepicker-start-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт дууссан цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_end_time"
                      id="datepicker-end-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Тэмдэглэл</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_note"
                  placeholder="Тэмдэглэл..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Ажиглалт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_observation"
                  placeholder="Ажиглалт..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Дүгнэлт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_conclusion"
                  placeholder="Дүгнэлт..."
                >
                </b-form-textarea>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeTeacher"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeTeacher"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeTeacher"
                @click.prevent="resetTeacherMeetModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <!-- Сурагч -->
    <b-modal
      id="modal-student-meet"
      size="xl"
      ref="modal"
      title="Суралцагчтай уулзсан уулзалтын модуль"
      @hidden="resetStudentMeetModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editModeStudent">Уулзалт бүртгэх</h3>
          <h3 class="card-title" v-show="editModeStudent">Уулзалт засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editModeStudent
                ? createSchoolStudentMeet()
                : updateSchoolStudentMeet()
            "
          >
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Суралцагч сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_pro_id"
                      deselect-label="Can't remove this value"
                      track-by="pro_id"
                      label="pro_id"
                      :custom-label="customStudents"
                      placeholder="Суралцагчийн нэр сонгох"
                      :options="students"
                      :searchable="true"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.school_class_full_name }}:
                          {{ option.pro_student_last_name[0] }}.{{
                            option.pro_student_name
                          }}: {{ option.email }}
                          {{ option.emp_teacher_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Хамт уулзсан суралцагчид сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_users"
                      deselect-label="Can't remove this value"
                      track-by="pro_id"
                      label="pro_id"
                      :custom-label="customStudents"
                      placeholder="Суралцагчийн нэр сонгох"
                      :options="students"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.school_class_full_name }}:
                          {{ option.pro_student_last_name[0] }}.{{
                            option.pro_student_name
                          }}: {{ option.email }}
                          {{ option.emp_teacher_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Асуудлын ангилал, түүний задаргаа асуудлууд</label>
                    <multiselect
                      v-model="formMeet.selectProbCategoryDetails"
                      deselect-label="Can't remove this value"
                      track-by="id"
                      label="id"
                      :custom-label="problemCatDetailName"
                      placeholder="Асуудлын ангилал сонгох"
                      :options="problemCategoryDetails"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.psy_cat_prob_cat_name }}-{{
                            option.psy_cat_detail
                          }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Хэний асуудлаар</label>
                    <b-form-input
                      v-model="formMeet.psy_meet_whom_by_prob"
                      placeholder="Хэний асуудлаар"
                    ></b-form-input>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт эхэлсэн цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_start_time"
                      id="datepicker-start-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт дууссан цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_end_time"
                      id="datepicker-end-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Тэмдэглэл</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_note"
                  placeholder="Тэмдэглэл..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Ажиглалт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_observation"
                  placeholder="Ажиглалт..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Дүгнэлт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_conclusion"
                  placeholder="Дүгнэлт..."
                >
                </b-form-textarea>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeStudent"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeStudent"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeStudent"
                @click.prevent="resetStudentMeetModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <!-- Эцэг эх -->
    <b-modal
      id="modal-parent-meet"
      size="xl"
      ref="modal"
      title="Эцэг эхтэй уулзсан уулзалтын модуль"
      @hidden="resetParentMeetModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editModeParent">Уулзалт бүртгэх</h3>
          <h3 class="card-title" v-show="editModeParent">Уулзалт засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editModeParent
                ? createSchoolParentMeet()
                : updateSchoolParentMeet()
            "
          >
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Эцэг эх сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_par_pro_id"
                      deselect-label="Can't remove this value"
                      track-by="id"
                      label="id"
                      :custom-label="customParents"
                      placeholder="Эцэг эхийн нэр сонгох"
                      :options="parents"
                      :searchable="true"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <strong
                          >Эцэг эх: {{ option.parent_father_name }}
                          {{ option.school_class_full_name }} :
                          {{ option.student_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Хамт уулзсан эцэг эх сонгох</label>
                    <multiselect
                      v-model="formMeet.psy_meet_users"
                      deselect-label="Can't remove this value"
                      track-by="id"
                      label="id"
                      :custom-label="customParents"
                      placeholder="Эцэг эхийн нэр сонгох"
                      :options="parents"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <strong
                          >Эцэг эх: {{ option.parent_father_name }}
                          {{ option.school_class_full_name }} :
                          {{ option.student_name }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Асуудлын ангилал, түүний задаргаа асуудлууд</label>
                    <multiselect
                      v-model="formMeet.selectProbCategoryDetails"
                      deselect-label="Can't remove this value"
                      track-by="id"
                      label="id"
                      :custom-label="problemCatDetailName"
                      placeholder="Асуудлын ангилал сонгох"
                      :options="problemCategoryDetails"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.psy_cat_prob_cat_name }}-{{
                            option.psy_cat_detail
                          }}</strong
                        ></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Хэний асуудлаар</label>
                    <b-form-input
                      v-model="formMeet.psy_meet_whom_by_prob"
                      placeholder="Хэний асуудлаар"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт эхэлсэн цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_start_time"
                      id="datepicker-start-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Уулзалт дууссан цаг</label>
                    <b-form-timepicker
                      v-model="formMeet.psy_meet_end_time"
                      id="datepicker-end-time"
                      now-button
                      reset-button
                    ></b-form-timepicker>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Тэмдэглэл</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_note"
                  placeholder="Тэмдэглэл..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Ажиглалт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_observation"
                  placeholder="Ажиглалт..."
                >
                </b-form-textarea>
              </div>
              <div class="form-group">
                <label>Дүгнэлт</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formMeet.psy_meet_conclusion"
                  placeholder="Дүгнэлт..."
                >
                </b-form-textarea>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeParent"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeParent"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeParent"
                @click.prevent="resetParentMeetModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      yearId: null,
      action: "",
      editModeTeacher: false,
      editModeStudent: false,
      editModeParent: false,
      teachers: [],
      students: [],
      parents: [],
      teacherMeets: [],
      studentMeets: [],
      parentMeets: [],
      formMeet: new Form({
        id: "",
        psy_meet_year_id: "",
        psy_meet_school_id: "",
        psy_meet_adv_id: "",
        psy_meet_adv_tr_id: "",
        psy_meet_reg_emp_id: "",
        psy_meet_emp_id: "",
        psy_meet_emp_dep_id: "",
        psy_meet_par_pro_id: "",
        psy_meet_pro_id: "",
        psy_meet_pro_sch_cla_id: "",
        psy_meet_users: [], //Хамт уулзсан багш, ажилтан, суралцагч, эцэг эх
        selectProbCategoryDetails: [], //Асуудлын ангилалууд
        psy_meet_time_req_id: "",
        psy_meet_whom_by_prob: "",
        psy_meet_start_time: "",
        psy_meet_end_time: "",
        psy_meet_first: "",
        psy_meet_count: "",
        psy_meet_note: "",
        psy_meet_observation: "",
        psy_meet_conclusion: "",
      }),
      problemCategories: [],
      problemCategoryDetails: [],

      // Хүснэгтийн тохиргоо
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      loaded: true,

      //   Багш нар
      totalRowsMeetParents: 1,
      currentPageMeetParents: 1,
      perPageMeetParents: 25,
      pageOptionsMeetParents: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterMeetParents: "",
      filterOnRooms: [],
      fieldsMeetParent: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "reg_emp",
          label: "Хариуцах эзэн",
          sortable: true,
        },
        {
          key: "psy_meet_whom_by_prob",
          label: "Хэний асуудлаар",
          sortable: true,
        },
        {
          key: "meet_reg_parents_profile",
          label: "Хамт уулзсан эцэг эх",
          sortable: true,
        },
        {
          key: "my_cat_details",
          label: "Асуудлын төрөл болон задаргаа",
          sortable: true,
        },
        {
          key: "psy_meet_start_time",
          label: "Уулзалт болсон цаг",
          sortable: true,
        },

        {
          key: "psy_meet_count",
          label: "Хэддэх уулзалт",
          sortable: true,
        },
        {
          key: "psy_meet_note",
          label: "Тэмдэглэл",
          sortable: true,
        },
        {
          key: "psy_meet_observation",
          label: "Ажиглалт",
          sortable: true,
        },
        {
          key: "psy_meet_conclusion",
          label: "Дүгнэлт",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        // {
        //   key: "updated_at",
        //   label: "Зассан",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
    };
  },
  watch: {},
  components: { Multiselect },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  methods: {
    getAllMeets() {
      axios
        .post("/psycho/getAllMeets", {
          year: this.yearId != null ? this.yearId.id : null,
        })
        .then((res) => {
          // console.log(response.data.rewards);
          //Нийт хэрэглэгчид
          this.teachers = res.data.teachers;
          this.students = res.data.students;
          this.parents = res.data.parents;
          //нийт уулзалтуудыг харуулах
          this.teacherMeets = res.data.teacherMeets;
          this.studentMeets = res.data.studentMeets;
          this.parentMeets = res.data.parentMeets;
          //асуудлын ангилалууд
          this.problemCategories = res.data.problemCategories;
          this.problemCategoryDetails = res.data.problemCategoryDetails;
          this.loaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    problemCatName({ psy_cat_prob_cat_name }) {
      return `${psy_cat_prob_cat_name}`;
    },
    problemCatDetailName({ psy_cat_prob_cat_name, psy_cat_detail }) {
      return `${psy_cat_prob_cat_name}-${psy_cat_detail}`;
    },

    //Багш
    editSchoolTeacherMeet(tMeet) {
      // console.log("Дэлгэрэнгүй мэдээлэл засах", tMeet);
      this.editModeTeacher = true;
      this.formMeet.reset();
      //   this.formMeet.fill(tMeet);
      this.formMeet.id = tMeet.id;
      let emp = this.teachers.filter((el) => {
        if (el.emp_id == tMeet.psy_meet_emp_id) {
          return el;
        }
      });
      this.formMeet.psy_meet_emp_id = emp[0];
      let detailts = [];
      this.problemCategoryDetails.forEach((element) => {
        tMeet.my_cat_details.forEach((el) => {
          if (element.id == el.psy_cat_det_id) {
            detailts.push(element);
            // console.log(element);
          }
        });
      });
      let users = [];
      this.teachers.forEach((element) => {
        tMeet.my_users.forEach((el) => {
          if (element.emp_user_id == el.user_id) {
            users.push(element);
            // console.log(element);
          }
        });
      });
      this.formMeet.selectProbCategoryDetails = [...detailts];
      this.formMeet.psy_meet_users = [...users];
      this.formMeet.psy_meet_whom_by_prob = tMeet.psy_meet_whom_by_prob;
      this.formMeet.psy_meet_start_time = tMeet.psy_meet_start_time;
      this.formMeet.psy_meet_end_time = tMeet.psy_meet_end_time;
      this.formMeet.psy_meet_first = tMeet.psy_meet_first;
      this.formMeet.psy_meet_count = tMeet.psy_meet_count;
      this.formMeet.psy_meet_note = tMeet.psy_meet_note;
      this.formMeet.psy_meet_observation = tMeet.psy_meet_observation;
      this.formMeet.psy_meet_conclusion = tMeet.psy_meet_conclusion;

      this.$bvModal.show("modal-teacher-meet");
    },
    updateSchoolTeacherMeet() {
      this.formMeet
        .put("/psycho/updateTeacherMeet/" + this.formMeet.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadMeets");
          this.resetTeacherMeetModal();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    deleteSchoolTeacherMeet(tMeet) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteMeet/" + tMeet.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadMeets");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    resetTeacherMeetModal() {
      this.formMeet.reset();
      this.editModeTeacher = false;
      this.$bvModal.hide("modal-teacher-meet");
    },
    createFillFromForTeacher() {
      this.$bvModal.show("modal-teacher-meet");
    },
    createSchoolTeacherMeet() {
      this.formMeet
        .post("/psycho/createTeacherMeet")
        .then((res) => {
          this.$toastr.s("Уулзалтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadMeets");
          this.resetTeacherMeetModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customTeachers({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    //Сурагч
    editSchoolStudentMeet(sMeet) {
      // console.log(sMeet);
      this.editModeStudent = true;

      this.formMeet.reset();

      this.formMeet.id = sMeet.id;
      let student = this.students.filter((el) => {
        if (el.pro_id == sMeet.psy_meet_pro_id) {
          return el;
        }
      });
      this.formMeet.psy_meet_pro_id = student[0];
      let detailts = [];
      this.problemCategoryDetails.forEach((element) => {
        sMeet.my_cat_details.forEach((el) => {
          if (element.id == el.psy_cat_det_id) {
            detailts.push(element);
            // console.log(element);
          }
        });
      });
      let studsss = [];
      this.students.forEach((element) => {
        sMeet.meet_reg_profile.forEach((el) => {
          if (element.pro_user_id == el.user_id) {
            studsss.push(element);
            // console.log(element);
          }
        });
      });
      this.formMeet.selectProbCategoryDetails = [...detailts];
      this.formMeet.psy_meet_users = [...studsss];
      this.formMeet.psy_meet_whom_by_prob = sMeet.psy_meet_whom_by_prob;
      this.formMeet.psy_meet_start_time = sMeet.psy_meet_start_time;
      this.formMeet.psy_meet_end_time = sMeet.psy_meet_end_time;
      this.formMeet.psy_meet_first = sMeet.psy_meet_first;
      this.formMeet.psy_meet_count = sMeet.psy_meet_count;
      this.formMeet.psy_meet_note = sMeet.psy_meet_note;
      this.formMeet.psy_meet_observation = sMeet.psy_meet_observation;
      this.formMeet.psy_meet_conclusion = sMeet.psy_meet_conclusion;

      this.$bvModal.show("modal-student-meet");
    },
    updateSchoolStudentMeet() {
      this.formMeet
        .put("/psycho/updateStudentMeet/" + this.formMeet.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadMeets");
          this.resetStudentMeetModal();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    deleteSchoolStudentMeet(sMeet) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteMeet/" + sMeet.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadMeets");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    resetStudentMeetModal() {
      this.formMeet.reset();
      this.editModeStudent = false;
      this.$bvModal.hide("modal-student-meet");
    },
    createFillFromForStudent() {
      this.$bvModal.show("modal-student-meet");
    },
    createSchoolStudentMeet() {
      this.formMeet
        .post("/psycho/createStudentMeet")
        .then((res) => {
          this.$toastr.s("Уулзалтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadMeets");
          this.resetStudentMeetModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customStudents({
      school_class_full_name,
      pro_student_last_name,
      pro_student_name,
      email,
    }) {
      return `${school_class_full_name} : ${pro_student_last_name[0]}. ${pro_student_name}: ${email}`;
    },

    //Эцэг эх
    customParents({
      school_class_full_name,
      student_name,
      parent_father_name,
    }) {
      return (
        `Эцэг эх: ` +
        `${parent_father_name} ` +
        `${school_class_full_name} ` +
        `:` +
        `${student_name}`
      );
    },
    editSchoolParentMeet(pMeet) {
      // console.log(pMeet);
      this.editModeParent = true;
      this.formMeet.reset();
      this.formMeet.id = pMeet.id;
      let parent = this.parents.filter((el) => {
        if (el.id == pMeet.psy_meet_par_pro_id) {
          return el;
        }
      });
      this.formMeet.psy_meet_par_pro_id = parent[0];
      let detailts = [];
      this.problemCategoryDetails.forEach((element) => {
        pMeet.my_cat_details.forEach((el) => {
          if (element.id == el.psy_cat_det_id) {
            detailts.push(element);
            // console.log(element);
          }
        });
      });
      let parentsss = [];
      this.parents.forEach((element) => {
        pMeet.meet_reg_parents_profile.forEach((el) => {
          if (element.user_id == el.user_id) {
            parentsss.push(element);
            // console.log(element);
          }
        });
      });
      this.formMeet.selectProbCategoryDetails = [...detailts];
      this.formMeet.psy_meet_users = [...parentsss];
      this.formMeet.psy_meet_whom_by_prob = pMeet.psy_meet_whom_by_prob;
      this.formMeet.psy_meet_start_time = pMeet.psy_meet_start_time;
      this.formMeet.psy_meet_end_time = pMeet.psy_meet_end_time;
      this.formMeet.psy_meet_first = pMeet.psy_meet_first;
      this.formMeet.psy_meet_count = pMeet.psy_meet_count;
      this.formMeet.psy_meet_note = pMeet.psy_meet_note;
      this.formMeet.psy_meet_observation = pMeet.psy_meet_observation;
      this.formMeet.psy_meet_conclusion = pMeet.psy_meet_conclusion;

      this.$bvModal.show("modal-parent-meet");
    },
    updateSchoolParentMeet() {
      this.formMeet
        .put("/psycho/updateParentMeet/" + this.formMeet.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadMeets");
          this.resetParentMeetModal();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    resetParentMeetModal() {
      this.formMeet.reset();
      this.editModeParent = false;
      this.$bvModal.hide("modal-parent-meet");
    },
    createFillFromForParent() {
      this.$bvModal.show("modal-parent-meet");
    },
    createSchoolParentMeet() {
      this.formMeet
        .post("/psycho/createParentMeet")
        .then((res) => {
          this.$toastr.s("Уулзалтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadMeets");
          this.resetParentMeetModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    deleteSchoolParentMeet(pMeet) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteMeet/" + pMeet.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadMeets");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },

    //b-table тохирго
    onFilteredMeetParents(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRooms = filteredItems.length;
      this.currentPageRooms = 1;
    },
  },
  created() {
    this.getAllMeets();
    Fire.$on("loadMeets", () => {
      this.getAllMeets();
    });
  },
};
</script>

<style></style>
