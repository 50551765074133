<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        {{
          myPassCourse.class_number <= 0
            ? myPassCourse.class_cabinet
            : myPassCourse.full_name
        }}-{{ subject_name }} ирц бүртгэх
      </h3>
    </div>
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <b-container class="bv-example-row my-3" fluid>
          <b-row>
            <b-col sm="4">
              <b-form-datepicker
                id="example-datepicker"
                v-model="selected_date"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col sm="3">
              <multiselect
                v-model="selected_quarter"
                :options="myQuarters"
                track-by="id"
                label="name"
                :searchable="false"
                :show-labels="false"
                placeholder="Улирал сонгох"
                :allow-empty="true"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.name }}-р улирал</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="3">
              <multiselect
                v-model="selected_time"
                :options="attendance_lesson_numbers"
                track-by="value"
                label="text"
                :searchable="false"
                :show-labels="false"
                placeholder="Цаг сонгох"
                :allow-empty="true"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.value }}-р цаг</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="2"
              ><label for="example-datepicker"></label>
              <b-button
                variant="success"
                @click.prevent="showSelectedStudentsAtt()"
                >Ирц харах</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <b-overlay :show="loading" rounded="sm">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th style="width: 10px">#</th>
              <th>Суралцагч</th>
              <th>Ирцийн огноо</th>
              <th>Ирцийн төрөл</th>
              <th>Тайлбар</th>
              <th>Ирц өөрчилж хадгалах</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attStudent, index) in attendanceStudents" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                <span>
                  {{ attStudent.student_last_name[0]
                  }}{{ attStudent.student_last_name[1] }}.{{
                    attStudent.user_name
                  }}
                </span>
              </td>
              <td>
                {{ selected_date | dateYearMonthDay
                }}{{ getAttValues(attStudent) }}
                {{ course_id }}
              </td>
              <td>
                <b-form-select
                  v-model="attStudent.attendance_type_id"
                  @change="settype($event, attStudent)"
                  :options="attendanceType"
                  value-field="id"
                  text-field="attendance_type"
                  size="sm"
                ></b-form-select>
              </td>
              <td>
                <validation-provider
                  name="attStudent.temdeglel"
                  rules="alpha_num"
                >
                  <template>
                    <b-form-textarea
                      id="textarea"
                      v-model="attStudent.temdeglel"
                      placeholder="Тэмдэглэл"
                      rows="0"
                      max-rows="1"
                      @change="setDescription($event, attStudent)"
                    ></b-form-textarea>
                  </template>
                </validation-provider>
              </td>
              <td>
                <b-overlay
                  :show="load"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    variant="success"
                    @click.prevent="changeAttendance(attStudent)"
                  >
                    Хадгалах
                  </b-button>
                </b-overlay>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  props: [
    "teacher_id",
    "course_id",
    "school_class_id",
    "attendance_lesson_numbers",
    "subject_name",
    "myQuarters",
    "myPassCourse",
  ],
  data() {
    return {
      attendanceType: [],
      loading: false,
      load: false,
      attendance: null,
      form: new Form({
        id: "",
        teacher_id: "",
        student_id: "",
        course_id: "",
        quarter_id: "",
        school_class_id: "",
        attendance_type_id: "",
        lesson_time_number: "",
        lesson_date: "",
        temdeglel: "",
      }),
      selected_quarter: "",
      selected_date: new Date(),
      selected_time: "",
      attendanceStudents: [],
    };
  },
  components: {
    ValidationProvider,
    Multiselect,
  },
  methods: {
    setCurrentTime() {
      let nowHour = parseInt(moment(this.selected_date).format("HH"));
      let nowMinute = parseInt(moment(this.selected_date).format("mm"));
      switch (nowHour) {
        case 8:
          if (nowMinute <= 55)
            this.selected_time = { value: "1", text: "1-р цаг" };
          else this.selected_time = { value: "2", text: "2-р цаг" };
          break;
        case 9:
          if (nowMinute <= 40)
            this.selected_time = { value: "2", text: "2-р цаг" };
          else this.selected_time = { value: "3", text: "3-р цаг" };
          break;
        case 10:
          if (nowMinute <= 25)
            this.selected_time = { value: "3", text: "3-р цаг" };
          else this.selected_time = { value: "4", text: "4-р цаг" };
          break;
        case 11:
          if (nowMinute <= 10)
            this.selected_time = { value: "4", text: "4-р цаг" };
          else if (nowMinute <= 55)
            this.selected_time = { value: "5", text: "5-р цаг" };
          else this.selected_time = { value: "6", text: "6-р цаг" };
          break;
        case 12:
          if (nowMinute <= 40)
            this.selected_time = { value: "6", text: "6-р цаг" };
          else this.selected_time = { value: "7", text: "7-р цаг" };
          break;
        case 13:
          if (nowMinute <= 25)
            this.selected_time = { value: "7", text: "7-р цаг" };
          else this.selected_time = { value: "8", text: "8-р цаг" };
          break;
        case 14:
          if (nowMinute <= 10)
            this.selected_time = { value: "8", text: "8-р цаг" };
          else if (nowMinute <= 55)
            this.selected_time = { value: "9", text: "9-р цаг" };
          else this.selected_time = { value: "10", text: "10-р цаг" };
          break;
        case 15:
          if (nowMinute <= 40)
            this.selected_time = { value: "10", text: "10-р цаг" };
          else this.selected_time = { value: "11", text: "11-р цаг" };
          break;
        case 16:
          if (nowMinute <= 25)
            this.selected_time = { value: "11", text: "11-р цаг" };
          else this.selected_time = { value: "12", text: "12-р цаг" };
          break;
        default:
          break;
      }
    },

    settype(e, attStd) {
      attStd.new_type_id = e;
    },

    setDescription(e, attStd) {
      attStd.new_temdeglel = e;
    },
    getAttValues(student) {
      let arrStds = this.attendance.students.split("<#>");
      arrStds.forEach((std) => {
        let stdVals = std.split("||");
        if (stdVals[0] == student.student_id) {
          student.attendance_type_id = stdVals[1];
          student.temdeglel = stdVals[2];
        }
      });
    },
    showSelectedStudentsAtt() {
      if (
        this.selected_quarter != "" &&
        this.selected_date != "" &&
        this.selected_time != ""
      ) {
        this.getAttendances();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Огноо болон улирал, хичээлийн цаг сонгоно уу!",
          showConfirmButton: false,
        });
      }
    },
    getAttendances() {
      this.loading = true;
      this.form.teacher_id = this.teacher_id;
      this.form.course_id = this.course_id;
      this.form.quarter_id = this.selected_quarter.id;
      this.form.school_class_id = this.school_class_id;
      this.form.lesson_time_number = this.selected_time.value;
      this.form.lesson_date = this.selected_date;
      this.form.attendance_type_id = this.attendance_type_id;
      this.form
        .post("/teacher/getAttendance")
        .then((response) => {
          this.attendanceType = response.data.attendanceType;
          this.attendance = response.data.attendance;
          this.attendanceStudents = response.data.attendanceStudents;
          this.loading = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    changeAttendance(stdAtt) {
      //   console.log(this.getCourseStudents);
      this.load = true;
      this.form.teacher_id = this.teacher_id;
      this.form.course_id = this.course_id;
      this.form.quarter_id = this.selected_quarter.id;
      this.form.school_class_id = this.school_class_id;
      this.form.lesson_time_number = this.selected_time.value;
      this.form.lesson_date = this.selected_date;

      this.form.student_id = stdAtt.student_id;
      if (stdAtt.new_type_id) this.form.attendance_type_id = stdAtt.new_type_id;
      else this.form.attendance_type_id = stdAtt.attendance_type_id;

      if (stdAtt.new_temdeglel) this.form.temdeglel = stdAtt.new_temdeglel;
      else this.form.temdeglel = stdAtt.temdeglel;
      this.form
        .post("/teacher/updateAttendance/" + this.attendance.id)
        .then((response) => {
          this.$toastr.s("Ирц бүртгэгдлээ.", "Амжилттай");
          this.load = false;
        })
        .catch((errors) => {
          this.load = false;
          Swal.fire({
            icon: "error",
            title: "Алдаа гарлаа",
            text: "Ирцийн өгөгдлийг шалгана уу!",
            footer: "Алдаагаа засна уу?",
          });
          //   console.log(errors);
        });
      //   console.log("Тоо өөрчилсөн байна.");
    },
  },
  created() {
    this.setCurrentTime();
    axios
      .get("/teacher/getCurrentQuarter")
      .then((response) => {
        this.selected_quarter = response.data.currentQuarter;
      })
      .catch((errors) => {
        //   console.log(errors);
      });
    Fire.$on("loadMyExamValue", () => {
      this.getAttendances();
    });
  },
};
</script>

<style></style>
