<template>
  <div class="row pt-3">
    <div class="col-md-7">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Бүртгэлтэй байгаа агуулгууд</h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
            <!-- <button
              type="button"
              class="btn btn-tool"
              data-card-widget="remove"
            >
              <i class="fas fa-times"></i>
            </button> -->
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col>
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="light" style="font-size: 90%">
                  <b-tr>
                    <b-th>№</b-th>
                    <b-th>Бүртгэсэн</b-th>
                    <b-th>Улирал</b-th>
                    <b-th>Анги</b-th>
                    <b-th>Гарчиг</b-th>
                    <b-th>Агуулга</b-th>
                    <b-th>Тайлбар</b-th>
                    <b-th>Огноо</b-th>
                    <b-th>Үйлдлүүд</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  class="text-center"
                  style="font-size: 80%; text-align: justify"
                >
                  <b-tr
                    v-for="(paragraphContent, index) in paragraphContents"
                    :key="index"
                    v-if="paragraphContents.length"
                  >
                    <b-td>{{ index + 1 }}</b-td>
                    <b-td
                      >{{
                        paragraphContent.reg_emp_teach.teacher_last_name[0].toUpperCase()
                      }}.{{ paragraphContent.reg_emp_teach.teacher_name }}</b-td
                    >
                    <b-td>{{ paragraphContent.my_qrt.name }} </b-td>
                    <b-td
                      >{{ paragraphContent.start_grade }} :
                      {{ paragraphContent.finish_grade }}
                    </b-td>
                    <b-td style="text-align: justify">{{
                      paragraphContent.paragraph_title
                    }}</b-td>
                    <b-td style="width: 20rem; text-align: justify">{{
                      paragraphContent.paragraph_content
                    }}</b-td>
                    <b-td style="width: 15rem">
                      {{ paragraphContent.paragraph_content_explain }}</b-td
                    >
                    <b-td>{{
                      paragraphContent.created_at | dateYearMonthDay
                    }}</b-td>

                    <b-td>
                      <b-form-group label-size="sm" label-for="input-sm">
                        <b-button-group size="sm">
                          <b-button
                            variant="outline-primary"
                            @click.prevent="
                              editParagraphContent(paragraphContent)
                            "
                          >
                            <b-icon icon="tools" font-scale="0.8"></b-icon>
                          </b-button>

                          <b-button
                            variant="danger"
                            @click.prevent="
                              deleteParagraphContent(paragraphContent)
                            "
                            ><b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                              font-scale="0.8"
                            ></b-icon
                          ></b-button>
                        </b-button-group>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td colspan="9" variant="secondary" class="text-right">
                      Бүртгэлтэй агуулга:
                      <b v-if="paragraphContents.length">{{
                        paragraphContents.length
                      }}</b>
                      <b v-if="!paragraphContents.length"
                        >Одоогоор агуулга бүртгээгүй байна.</b
                      >
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Хичээлд суралцагч бүртгэх -->
    <div class="col-md-5">
      <!-- general form elements -->
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">Сорилд ашиглах агуулга бүртгэх</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <b-form-select
                v-model="form.start_grade"
                :options="grades"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <b-form-select
                v-model="form.finish_grade"
                :options="grades"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-md">
              <b-form-select
                v-model="form.quarter_id"
                :options="quarters"
                size="sm"
                class="mt-3"
                aria-placeholder="Улирал сонгох"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >-- Улирал сонгоно уу! --</b-form-select-option
                  >
                </template>
              </b-form-select>

              <b-form-input
                v-model="form.paragraph_title"
                placeholder="Тухайн агуулгын гарчиг оруулах"
              ></b-form-input>
              <b-form-textarea
                id="textarea"
                v-model="form.paragraph_content"
                placeholder="Сорилын агуулга оруулах"
                rows="7"
                max-rows="10"
              ></b-form-textarea>
              <b-form-textarea
                id="textarea"
                v-model="form.paragraph_content_explain"
                placeholder="Хэрэв та минутын уншлага хийх бол дараах зүйлс дээр анхаарна уу? Хэрэв та цээж бичиг хийх бол дараах зүйлс дээр анхаарна уу? гэх мэтээр агуулгатай хэрхэн ажиллах арга барилыг тайлбарлаж бичнэ."
                rows="5"
                max-rows="10"
              ></b-form-textarea>
            </div>
          </div>
          <div>
            <b-button
              variant="outline-success"
              @click="createParagraphContent()"
              v-show="!editMode"
              >Агуулга бүртгэх</b-button
            >
            <b-button
              variant="outline-warning"
              v-show="editMode"
              @click="updateParagraphContent()"
              >Агуулга засах</b-button
            >
            <b-button
              variant="outline-primary"
              v-show="editMode"
              @click="resetParagraph()"
              >Цуцлах</b-button
            >
          </div>
        </div>
      </div>
      <!-- /.card -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      paragraphContents: [], //ашигласан
      quarters: [],
      form: new Form({
        id: "",
        quarter_id: "",
        start_grade: null,
        finish_grade: null,
        paragraph_title: "",
        paragraph_content: "",
        paragraph_content_explain: "",
      }),

      grades: [
        { value: null, text: "Анги сонгоно уу!" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
      ],
    };
  },
  components: { Multiselect },
  methods: {
    sortArrays(schoolteachers) {
      return _.orderBy(schoolteachers, "teacher_name/department_name", "asc");
    },
    getAllParagraphContent() {
      axios
        .get("/teacher/getAllParagraphContentsTeacher")
        .then((response) => {
          this.paragraphContents = response.data.paragraphContents;
          this.quarters = response.data.quarters;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createParagraphContent() {
      this.form
        .post("/teacher/createParagraphContentTeacher")
        .then((res) => {
          // console.log(res);
          Fire.$emit("loadParagraphContentTeacher");
          this.resetParagraph();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetParagraph() {
      this.form.reset();
      this.editMode = false;
    },

    editParagraphContent(paragraphContent) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", paragraphContent);
      this.editMode = true;
      this.form.reset();
      this.form.fill(paragraphContent);

      this.form.start_grade = paragraphContent.start_grade;
      this.form.finish_grade = paragraphContent.finish_grade;
    },
    updateParagraphContent() {
      //   this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/teacher/updateParagraphContentTeacher/" + this.form.id)
        .then((response) => {
          // console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadParagraphContentTeacher");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    deleteParagraphContent(paragraphContent) {
      Swal.fire({
        title: "Сорилын агуулга устгахдаа итгэлтэй байна уу?",
        text: "Агуулга устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: `Агуулга устгах`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete(
              "/teacher/deleteParagraphContentTeacher/" + paragraphContent.id
            )
            .then((res) => {
              //   console.log(res);
              Fire.$emit("loadParagraphContentTeacher");
            })
            .catch((err) => {
              console.log(err);
            });
          /* Read more about isConfirmed, isDenied below */
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },

    // customLabel({ user_name, profile_student_last_name }) {
    //   return `${user_name} – ${profile_student_last_name}`;
    // },
    // customLabelTeacher({ teacher_name, teacher_last_name }) {
    //   return ` ${teacher_last_name[0].toUpperCase()}.${teacher_name}`;
    // },
  },
  created() {
    this.getAllParagraphContent();
    Fire.$on("loadParagraphContentTeacher", () => {
      this.getAllParagraphContent();
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
