<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong
                    >Байгууллагын хэмжээнд бүртгэлтэй өрөө, танхимууд</strong
                  >
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addProblemRoom()"
                    >Өрөө, танхим бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт өрөө танхимуудын тоо:
                      {{ rooms.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="roomLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageRooms"
                            :options="pageOptionsRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterRooms"
                                @click="filterRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageRooms"
                      :total-rows="totalRowsRooms"
                      :per-page="perPageRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="rooms"
                      :fields="fieldsActiveRooms"
                      :current-page="currentPageRooms"
                      :per-page="perPageRooms"
                      :filter="filterRooms"
                      :filter-ignored-fields="filterIgnoredFieldsRoom"
                      :filter-included-fields="filterOnRooms"
                      @filtered="onFilteredRooms"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(block_name)="data">
                        <b-badge variant="info">
                          {{ data.item.block_name }}
                        </b-badge>
                      </template>

                      <template #cell(my_education_level_info)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.my_education_level_info !== null"
                        >
                          {{ data.item.my_education_level_info.name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Ашиглах цогцолборын бүтэц бүртгэгдээгүй.
                        </b-badge>
                      </template>

                      <template #cell(my_room_category)="data">
                        <b-badge variant="secondary">
                          {{ data.item.my_room_category.name }}
                        </b-badge>
                      </template>

                      <template #cell(room_number)="data">
                        <b-badge variant="success">
                          {{ data.item.room_number }}
                        </b-badge>
                      </template>

                      <template #cell(my_school_class_info)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.my_school_class_info !== null"
                        >
                          {{ data.item.my_school_class_info.full_name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Эзэмшигч анги бүртгэгдээгүй.
                        </b-badge>
                      </template>

                      <template #cell(room_employees)="data">
                        <div
                          style="display: inline-block"
                          v-for="(employee, index) in data.item.room_employees"
                          :key="index"
                        >
                          <b-badge
                            variant="success"
                            v-if="employee.my_user_info"
                          >
                            {{ employee.my_user_info.teacher_last_name[0] }}.
                            {{ employee.my_user_info.teacher_name }}
                            <!-- {{ data.item.room_employees }} -->
                          </b-badge>
                        </div>
                      </template>

                      <template #cell(rooms_times)="data">
                        <div
                          style="display: inline-block"
                          v-for="(time, index) in data.item.rooms_times"
                          :key="index"
                        >
                          <b-badge
                            variant="success"
                            v-if="time.my_time_table_info"
                          >
                            {{ time.my_time_table_info.name }}
                            ({{ time.my_time_table_info.start_time }}
                            :
                            {{ time.my_time_table_info.finish_time }})

                            <!-- {{ data.item.room_employees }} -->
                          </b-badge>
                        </div>
                      </template>

                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolRoom(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolRoom(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-success"
                          @click="addTimeSchoolRoom(data.item)"
                          v-if="
                            userInfo.name == 'cipalprin' ||
                            userInfo.name == 'agermanvaa'
                          "
                          ><i class="fas fa-stopwatch"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong
                    >Байгууллагын хэмжээнд бүртгэлтэй өрөө, танхимууд</strong
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт өрөө танхимуудын тоо:
                      {{ roomsDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="roomLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveRooms"
                            :options="pageOptionsInactiveRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveRooms"
                                @click="filterInactiveRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveRooms"
                      :total-rows="totalRowsInactiveRooms"
                      :per-page="perPageInactiveRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="roomsDeleted"
                      :fields="fieldsInactiveRooms"
                      :current-page="currentPageInactiveRooms"
                      :per-page="perPageInactiveRooms"
                    >
                      <!-- :filter="filterInactiveRooms"
                      :filter-included-fields="filterOnInactiveRooms"
                      @filtered="onFilteredInactiveRooms" -->

                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(block_name)="data">
                        <b-badge variant="info">
                          {{ data.item.block_name }}
                        </b-badge>
                      </template>

                      <template #cell(my_room_category)="data">
                        <b-badge variant="secondary">
                          {{ data.item.my_room_category.name }}
                        </b-badge>
                      </template>

                      <template #cell(room_number)="data">
                        <b-badge variant="success">
                          {{ data.item.room_number }}
                        </b-badge>
                      </template>

                      <template #cell(room_employees)="data">
                        <div
                          style="display: inline-block"
                          v-for="(employee, index) in data.item.room_employees"
                          :key="index"
                        >
                          <b-badge
                            variant="success"
                            v-if="employee.my_user_info"
                          >
                            {{ employee.my_user_info.teacher_last_name[0] }}.
                            {{ employee.my_user_info.teacher_name }}
                          </b-badge>
                        </div>
                      </template>

                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreSchoolRoom(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Өрөө танхим бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-room"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="formRoomModalTitle"
        class="modal fade"
        hide-footer
        @hidden="cancelEdit"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModeRoom">
                    Өрөө, танхим бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editModeRoom">
                    Өрөө, танхим засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editModeRoom ? createSchoolRoom() : updateSchoolRoom()
                    "
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Блокын нэр</label>
                            <b-form-select
                              v-model="form.block_name"
                              :options="blockNames"
                              text-field="name"
                              value-field="value"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Өрөөг ашиглах цогцолборын бүтэц</label>
                            <multiselect
                              v-model="form.education_level_id"
                              :multiple="false"
                              deselect-label="Can't remove this value"
                              :custom-label="nameEducationLevel"
                              track-by="id"
                              label="id"
                              placeholder="Сонголт хийх"
                              :options="educationLevels"
                              :searchable="true"
                              :allow-empty="true"
                              :close-on-select="true"
                              :preserve-search="true"
                            >
                              <!-- @select="toggleSelect" -->

                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }} бүтэц</strong>
                                сонгогдлоо
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Өрөө, танхимын ангилал сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.room_cat_id"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameRoomcategory"
                                track-by="id"
                                label="name"
                                placeholder="Сонголт хийх"
                                :options="schoolRoomCategories"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :preselect-first="true"
                                :hide-selected="false"
                              >
                                <!-- @select="toggleSelect" -->

                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Өрөөг эзэмших анги</label>
                            <multiselect
                              v-model="form.school_class_id"
                              :multiple="false"
                              deselect-label="Can't remove this value"
                              :custom-label="nameSchoolClass"
                              track-by="id"
                              label="id"
                              placeholder="Сонголт хийх"
                              :options="schoolClasses"
                              :searchable="true"
                              :allow-empty="true"
                              :close-on-select="true"
                              :preserve-search="true"
                            >
                              <!-- @select="toggleSelect" -->

                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.full_name }} анги</strong>
                                сонгогдлоо
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Өрөө, танхимын дугаар</label>
                            <b-form-input
                              id="room_number"
                              v-model="form.room_number"
                              type="text"
                              size="sm"
                              placeholder="Өрөө танхимын дугаар оруулна уу!"
                              required
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Өрөө, танхимын зориулалт</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="form.room_zoriulalt"
                              placeholder="Өрөө, танхимын зориулалт (Галын өрөө, физикийн кабинет, эмчийн өрөө...)"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>

                            <has-error :form="form" field="schools"></has-error>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Өрөө, танхимын хэмжээ (м^2)</label>
                            <b-form-input
                              id="room_size"
                              v-model="form.room_size"
                              type="number"
                              step="0.01"
                              size="sm"
                              placeholder="Өрөө танхимын хэмжээ (м^2) оруулна уу!"
                              required
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Өрөө, танхимын шалны өнгөлгөө</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="form.room_plate_face"
                              placeholder="Өрөө, танхимын шалны өнгөлгөө (хулдаас, плита, шахмал модон гэх мэт...)"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>

                            <has-error :form="form" field="schools"></has-error>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Өрөө, танхим эзэмшигчид сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.room_owner_employees"
                                :multiple="true"
                                deselect-label="Can't remove this value"
                                :custom-label="nameTeacher"
                                track-by="emp_teacher_name"
                                label="emp_teacher_name"
                                placeholder="Сонголт хийх"
                                :options="employees"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="false"
                                :preserve-search="true"
                              >
                                <!-- @select="toggleSelect" -->

                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{
                                    option.emp_teacher_name
                                  }}</strong>
                                  нар сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editModeRoom"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editModeRoom"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editModeRoom"
                        @click.prevent="cancelEdit"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editModeRoom"
                        @click.prevent="cancelCreate"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Өрөө танхимд цагийн ачаалал бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-room-add-time"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        title="Цагийн ачаалал бүртгэх"
        class="modal fade"
        hide-footer
        @hidden="cancelEditTime"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Өрөө, танхимд цаг бүртгэх</h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form @submit.prevent="createSchoolRoomAddTime()">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <small class="col-lg-12 text-danger"
                              >Цагийн ачааллыг хичээлийн жилээр шүүж
                              харах</small
                            >
                            <div class="row">
                              <multiselect
                                v-model="formRoomAddTime.year_id"
                                :options="years"
                                :multiple="false"
                                :custom-label="customYears"
                                track-by="id"
                                label="id"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="true"
                                placeholder="Хичээлийн жил сонгох"
                                :allow-empty="true"
                                deselect-label="Хасах"
                                select-label="Сонгох"
                                :preserve-search="true"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong> {{ option.start_date }}</strong
                                  ><strong> {{ option.finish_date }}</strong
                                  ><strong> сонгогдлоо</strong></template
                                >
                              </multiselect>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>Цаг сонгох</label>
                            <b-button
                              v-if="formRoomAddTime.year_id"
                              variant="outline-info"
                              class="mb-1"
                              @click="selectAllTimes"
                              >Бүх цаг сонгох</b-button
                            >
                            <div class="row">
                              <multiselect
                                v-if="formRoomAddTime.year_id"
                                v-model="formRoomAddTime.times"
                                :multiple="true"
                                deselect-label="Can't remove this value"
                                :custom-label="nameTime"
                                track-by="id"
                                label="id"
                                placeholder="Сонголт хийх"
                                :options="getAllTimeTables"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="false"
                                :preserve-search="true"
                                :clear-on-select="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->

                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  {{ option.start_time }} :
                                  {{ option.finish_time }}
                                  сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                          <!-- <div class="form-group">
                            <div class="row">
                              <b-form-checkbox
                                id="isTwo"
                                v-model="formRoomAddTime.isHasLesson"
                                name="isTwo"
                                value="1"
                                unchecked-value="0"
                              >
                                Тухайн цагийн ашигладаг бол сонгох
                              </b-form-checkbox>
                              <has-error
                                :form="formRoomAddTime"
                                field="formRoomAddTime.isHasLesson"
                              ></has-error>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-primary">
                        Хадгалах
                      </button>

                      <button
                        class="btn btn-warning"
                        @click.prevent="cancelEditTime"
                      >
                        Цуцлах
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formRoomModalTitle: "Өрөө, танхим бүртгэх",

      //Өрөөг ашинлах бүтэц
      educationLevels: [],
      //Өрөө танхимын хэсэг
      rooms: [],
      roomsDeleted: [],
      employees: [],
      schoolRoomCategories: [],
      schoolClasses: [],
      roomLoaded: true,
      editModeRoom: false,

      //b-table тохиргоо
      totalRowsRooms: 1,
      currentPageRooms: 1,
      perPageRooms: 200,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterRooms: "",
      filterOnRooms: [],
      filterIgnoredFieldsRoom: ["room_employees"],
      fieldsActiveRooms: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "block_name",
          label: "Харьяа блок",
          sortable: true,
        },
        {
          key: "my_education_level_info",
          label: "Ашиглах бүтэц",
          sortable: true,
        },
        {
          key: "my_room_category",
          label: "Өрөөний ангилал",
          sortable: true,
        },
        {
          key: "room_number",
          label: "Өрөөний №",
          sortable: true,
        },
        {
          key: "room_full_name",
          label: "Бүтэн нэр",
          sortable: true,
        },

        {
          key: "room_zoriulalt",
          label: "Өрөөний зориулалт",
          sortable: true,
        },
        {
          key: "my_school_class_info",
          label: "Эзэмшигч анги",
          sortable: true,
        },
        {
          key: "room_size",
          label: "Хэмжээ (м^2)",
          sortable: true,
        },
        {
          key: "room_plate_face",
          label: "Шалны өнгөлгөө",
          sortable: true,
        },
        {
          key: "room_employees",
          label: "Өрөө эзэмшигчид",
          sortable: true,
        },
        {
          key: "rooms_times",
          label: "Өрөөний цагийн бүртгэл",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveRooms: 1,
      currentPageInactiveRooms: 1,
      perPageInactiveRooms: 25,
      pageOptionsInactiveRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveRooms: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "block_name",
          label: "Харьяа блок",
          sortable: true,
        },
        {
          key: "my_room_category",
          label: "Өрөөний ангилал",
          sortable: true,
        },
        {
          key: "room_number",
          label: "Өрөөний №",
          sortable: true,
        },
        {
          key: "room_full_name",
          label: "Бүтэн нэр",
          sortable: true,
        },

        {
          key: "room_zoriulalt",
          label: "Өрөөний зориулалт",
          sortable: true,
        },
        {
          key: "room_size",
          label: "Хэмжээ (м^2)",
          sortable: true,
        },
        {
          key: "room_plate_face",
          label: "Шалны өнгөлгөө",
          sortable: true,
        },
        {
          key: "room_employees",
          label: "Өрөө эзэмшигчид",
          sortable: true,
          //   formatter: (value, key, item) => {
          //     return value, key, item;
          //   },
          //   filterByFormatted: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      editRoomMode: false,

      form: new Form({
        id: "",
        education_level_id: "",
        school_class_id: "",
        room_cat_id: "",
        block_name: "",
        room_number: "",
        room_full_name: "",
        room_zoriulalt: "",
        room_size: "",
        room_plate_face: "",
        room_owner_employees: [],
      }),

      blockNames: [
        { name: "А блок", value: "A" },
        { name: "B блок", value: "B" },
        { name: "C блок", value: "C" },
        { name: "D блок", value: "D" },
        { name: "E блок", value: "E" },
        { name: "F блок", value: "F" },
      ],

      // Анги танхимд цагийн ачаалал бүртгэх
      getAllTimeTables: [],
      formRoomAddTime: new Form({
        year_id: "",
        room_id: "",
        isHasLesson: 0,
        times: [],
      }),
    };
  },
  components: { Multiselect },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    // items() {
    //   if (this.filterInactiveRooms != "") {
    //     this.roomsDeleted.forEach((element) => {
    //       return element.room_employees.filter(
    //         (item) =>
    //           item.my_user_info.teacher_name.includes(this.filterInactiveRooms)
    //         //   ||
    //         //   item.lastname.includes(this.keyword) ||
    //         //   item.email.includes(this.keyword)
    //       );
    //     });
    //   } else {
    //     return this.roomsDeleted;
    //   }
    // },
  },
  methods: {
    getAllRooms() {
      axios
        .get("/manager_vaa/getAllRooms")
        .then((res) => {
          //   console.log(response);
          this.rooms = res.data.rooms;
          this.roomsDeleted = res.data.roomsDeleted;
          this.totalRowsRooms = this.rooms ? this.rooms.length : 0;
          this.totalRowsInactiveRooms = this.roomsDeleted
            ? this.roomsDeleted.length
            : 0;
          this.employees = res.data.employees;
          this.schoolRoomCategories = res.data.schoolRoomCategories;
          this.schoolClasses = res.data.schoolClasses;
          this.educationLevels = res.data.educationLevels;
          this.roomLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    customLabel({ name, profile_student_last_name }) {
      return `${profile_student_last_name[0]}. ${name}`;
    },
    schoolClassLabel({ my_course }) {
      return `${my_course.my_school_class.full_name}`;
    },

    //b-table тохирго
    onFilteredRooms(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRooms = filteredItems.length;
      this.currentPageRooms = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveRooms = filteredItems.length;
      this.currentPageInactiveRooms = 1;
    },

    addProblemRoom() {
      this.form.reset();
      this.$bvModal.show("modal-create-room");
    },
    createSchoolRoom() {
      //   console.log("create");
      this.form
        .post("/manager_vaa/createSchoolRoom")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadRooms");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editSchoolRoom(room) {
      // console.log(room);
      //exams_teacher_id, block_name,
      this.editModeRoom = true;
      this.form.fill(room);
      let selectRoomCat = "";

      this.schoolRoomCategories.forEach((element) => {
        if (element.id == room.room_cat_id) {
          selectRoomCat = element;
        }
      });

      this.educationLevels.forEach((element) => {
        if (
          room.education_level_id !== null &&
          element.id === room.education_level_id
        ) {
          this.form.education_level_id = element;
        } else {
          this.education_level_id = "";
        }
      });
      this.schoolClasses.forEach((element) => {
        if (
          room.my_school_class_info !== null &&
          element.id === room.my_school_class_info.id
        ) {
          this.form.school_class_id = element;
        }
      });
      this.form.room_cat_id = selectRoomCat;
      let selectTeach = [];
      this.employees.forEach((element) => {
        room.room_employees.forEach((element1) => {
          if (element.emp_user_id == element1.employee_id) {
            selectTeach.push(element);
          }
        });
      });

      this.form.room_owner_employees = selectTeach;

      this.$bvModal.show("modal-create-room");
    },
    updateSchoolRoom() {
      //   console.log("udpate");
      this.form
        .put("/manager_vaa/updateSchoolRoom")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadRooms");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeRoom = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-room");
    },

    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    nameSchoolClass({ full_name }) {
      return `${full_name} анги`;
    },
    nameEducationLevel({ name }) {
      return `${name}`;
    },
    nameRoomcategory({ name, description }) {
      return `${name} (${description})`;
    },

    deleteSchoolRoom(room) {
      //   console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн сургуультай хамаарал бүхий хэрэглэгчид ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteSchoolRoom/" + room.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Сургуулийн өрөө танхимыг системээс устгалаа.",
                "success"
              );
              Fire.$emit("loadRooms");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreSchoolRoom(room) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/restoreSchoolRoom/" + room.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadRooms");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    // Анги танхимд цагийн ачаалал бүртгэх
    customYears({ start_date, finish_date }) {
      return `${start_date} : ${finish_date}`;
    },
    nameTime({ name, start_time, finish_time }) {
      return `${name}. ${start_time} : ${finish_time}`;
    },
    addTimeSchoolRoom(room) {
      // console.log(room);
      this.formRoomAddTime.room_id = room.id;
      this.$bvModal.show("modal-create-room-add-time");
    },
    cancelEditTime() {
      this.formRoomAddTime.reset();
      this.$bvModal.hide("modal-create-room-add-time");
    },
    createSchoolRoomAddTime() {
      //   console.log("create");
      this.formRoomAddTime
        .post("/manager_vaa/createSchoolRoomAddTime")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadRooms");
          this.cancelEditTime();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    selectAllTimes() {
      this.formRoomAddTime.times = this.getAllTimeTables;
    },
  },
  watch: {
    "formRoomAddTime.year_id"(newVal, oldVal) {
      this.getAllTimeTables = [];
      // console.log("teacher_id Өөрчлөгдсөн.", newVal);
      if (this.formRoomAddTime.year_id !== "") {
        axios
          .post("/manager_vaa/getAllTimeTables", {
            yearId: newVal,
          })
          .then((res) => {
            this.getAllTimeTables = res.data.getAllTimeTables;
          });
      } else {
        this.getAllTimeTables = [];
      }
    },
  },
  created() {
    this.getAllRooms();
    Fire.$on("loadRooms", () => {
      this.getAllRooms();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
