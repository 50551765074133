<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link active-invite"
                href="#activity-invite"
                data-toggle="tab"
                >Хамтарч хийхээр таныг урьсан</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactivity" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Бүртгэгдсэн сургалтууд(Цагаас гадуур)</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <div class="container">
                      <div class="float-left">
                        <p class="card-title" style="font-size: 80%">
                          Нийт амралтын өдрийн төлөвлөж буй ажлын тоо:
                          {{ weekendWorks.length }}
                        </p>
                      </div>
                      <div class="float-right">
                        <b-button
                          style="font-size: 80%"
                          size="sm"
                          variant="success"
                          @click.prevent="createWeekendWork()"
                          >Амралтын өдрийн төлөвлөж буй ажил бүртгэх
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <b-container class="bv-example-row" fluid>
                      <b-row>
                        <!-- <b-col sm="3"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    variant="success"
                    @click.prevent="getAllWeekendWorks()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
                        <b-col sm="4"> </b-col>
                        <b-col sm="8"> </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="card-body table-responsive">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageWeekendWork"
                            :options="pageOptionsWeekendWork"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterWeekendWorks"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterWeekendWorks"
                                @click="filterWeekendWorks = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageWeekendWorks"
                      :total-rows="totalRowsWeekendWorks"
                      :per-page="perPageWeekendWork"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="weekendWorks"
                      :fields="fieldsActiveWeekendWorks"
                      :current-page="currentPageWeekendWorks"
                      :per-page="perPageWeekendWork"
                      :filter="filterWeekendWorks"
                      :filter-ignored-fields="filterIgnoredFieldsWeekendWorks"
                      :filter-included-fields="filterOnWeekendWorks"
                      @filtered="onFilteredWeekendWork"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.name }}
                        </b-badge>
                        <!-- <a
                          href="#"
                          class="badge bg-success"
                          @click="showWeekendWork(data.item)"
                          ><i class="fa fa-eye"></i
                        ></a> -->
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="createWeekendWorkTeacher(data.item)"
                          ><i class="fas fa-chalkboard-teacher"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-teal"
                          @click="createWeekendWorkStudent(data.item)"
                          ><i class="fas fa-user-graduate"></i
                        ></a>
                      </template>

                      <template #cell(description)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 15rem; text-align: justify"
                        >
                          {{ data.item.description }}
                        </b-badge>
                        <br />
                        <b-badge variant="warning">
                          {{ data.item.start_date }}:
                          {{ data.item.end_date }} </b-badge
                        ><br />
                        <b-badge variant="info">
                          {{ data.item.start_time }}:
                          {{ data.item.end_time }}
                        </b-badge>
                      </template>
                      <template #cell(status)="data">
                        <b-iconstack
                          font-scale="1"
                          v-if="data.item.status == 1"
                          variant="success"
                        >
                          <b-icon stacked icon="square"></b-icon>
                          <b-icon stacked icon="check"></b-icon>
                        </b-iconstack>
                        <b-iconstack
                          font-scale="1"
                          v-if="data.item.status == 0"
                          variant="danger"
                        >
                          <b-icon stacked icon="square"></b-icon>
                          <b-icon stacked icon="x"></b-icon>
                        </b-iconstack>
                      </template>

                      <template #cell(weekend_work_owner_teacher)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-if="data.item.weekend_work_owner_teacher"
                            >
                              <img
                                v-if="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url != null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url
                                    ? data.item.weekend_work_owner_teacher
                                        .profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(weekend_work_teachers)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="teacher in data.item.weekend_work_teachers"
                              :key="teacher.id"
                            >
                              <img
                                v-if="teacher.my_info != null"
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                  teacher.my_info.department.department_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  teacher.my_info.teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  teacher.my_info.teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  teacher.my_info.profile_photo_url
                                    ? teacher.my_info.profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <i
                                class="fas fa-user-times"
                                @click="removeWeekendWorkTeacher(teacher)"
                              ></i>
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(weekend_work_students)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="(student, index) in data.item
                                .weekend_work_students"
                              :key="index"
                            >
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url !=
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  student.my_info.student_profile_photo_url +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url ==
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  '/images/users/user.png' +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              {{ student.my_info.student_last_name[0]
                              }}{{ student.my_info.student_last_name[1] }}.{{
                                student.my_info.student_name
                              }}
                              <i
                                class="fas fa-user-times"
                                @click="removeWeekendWorkStudent(student)"
                              ></i>
                            </li>
                          </ul>

                          <!-- <b-badge
                            v-if="
                              student.my_info.student_profile_photo_url != null
                            "
                            variant="light"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              student.my_info.student_profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Суралцагч</dt><dd>' +
                              student.my_info.student_last_name +
                              ' ' +
                              student.my_info.student_name
                            "
                          >
                            {{ student.my_info.student_last_name[0]
                            }}{{ student.my_info.student_last_name[1] }}.{{
                              student.my_info.student_name
                            }}
                            <i
                              class="fas fa-user-times"
                              @click="removeWeekendWorkStudent(student)"
                            ></i>
                          </b-badge>

                          <b-badge
                            v-if="
                              student.my_info.student_profile_photo_url == null
                            "
                            variant="light"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              '/images/users/user.png' +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Суралцагч</dt><dd>' +
                              student.my_info.student_last_name[0] +
                              student.my_info.student_last_name[1] +
                              '.' +
                              student.my_info.student_name
                            "
                          >
                            {{ student.my_info.student_last_name[0]
                            }}{{ student.my_info.student_last_name[1] }}.{{
                              student.my_info.student_name
                            }}
                            <i
                              class="fas fa-user-times"
                              @click="removeWeekendWorkStudent(student)"
                            ></i>
                          </b-badge> -->
                        </span>
                      </template>
                      <template #cell(actions)="data">
                        <span v-if="data.item.status == 0">
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editWeekendWork(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteWeekendWork(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </span>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane active-invite" id="activity-invite">
              <div class="card">
                <div class="card-header">
                  <strong>Бүртгэгдсэн сургалтууд(Цагаас гадуур)</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <div class="container">
                      <div class="float-left">
                        <p class="card-title" style="font-size: 80%">
                          Нийт амралтын өдрийн төлөвлөж буй ажлын тоо:
                          {{ weekendWorksInvite.length }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <b-container class="bv-example-row" fluid>
                      <b-row>
                        <!-- <b-col sm="3"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    variant="success"
                    @click.prevent="getAllWeekendWorks()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
                        <b-col sm="4"> </b-col>
                        <b-col sm="8"> </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="card-body table-responsive">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageWeekendWork"
                            :options="pageOptionsWeekendWork"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterWeekendWorks"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterWeekendWorks"
                                @click="filterWeekendWorks = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageWeekendWorks"
                      :total-rows="totalRowsWeekendWorks"
                      :per-page="perPageWeekendWork"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="weekendWorksInvite"
                      :fields="fieldsActiveWeekendWorksInvite"
                      :current-page="currentPageWeekendWorks"
                      :per-page="perPageWeekendWork"
                      :filter="filterWeekendWorks"
                      :filter-ignored-fields="filterIgnoredFieldsWeekendWorks"
                      :filter-included-fields="filterOnWeekendWorks"
                      @filtered="onFilteredWeekendWork"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.name }}
                        </b-badge>
                        <!-- <a
                          href="#"
                          class="badge bg-success"
                          @click="showWeekendWork(data.item)"
                          ><i class="fa fa-eye"></i
                        ></a> -->
                        <br />
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="createWeekendWorkTeacher(data.item)"
                          ><i class="fas fa-chalkboard-teacher"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-teal"
                          @click="createWeekendWorkStudent(data.item)"
                          ><i class="fas fa-user-graduate"></i
                        ></a>
                      </template>

                      <template #cell(description)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 15rem; text-align: justify"
                        >
                          {{ data.item.description }}
                        </b-badge>
                        <br />
                        <b-badge variant="warning">
                          {{ data.item.start_date }}:
                          {{ data.item.end_date }} </b-badge
                        ><br />
                        <b-badge variant="info">
                          {{ data.item.start_time }}:
                          {{ data.item.end_time }}
                        </b-badge>
                      </template>
                      <template #cell(status)="data">
                        <b-iconstack
                          font-scale="1"
                          v-if="data.item.status == 1"
                          variant="success"
                        >
                          <b-icon stacked icon="square"></b-icon>
                          <b-icon stacked icon="check"></b-icon>
                        </b-iconstack>
                        <b-iconstack
                          font-scale="1"
                          v-if="data.item.status == 0"
                          variant="danger"
                        >
                          <b-icon stacked icon="square"></b-icon>
                          <b-icon stacked icon="x"></b-icon>
                        </b-iconstack>
                      </template>

                      <template #cell(weekend_work_owner_teacher)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-if="data.item.weekend_work_owner_teacher"
                            >
                              <img
                                v-if="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url != null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url
                                    ? data.item.weekend_work_owner_teacher
                                        .profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(weekend_work_teachers)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="teacher in data.item.weekend_work_teachers"
                              :key="teacher.id"
                            >
                              <img
                                v-if="teacher.my_info != null"
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                  teacher.my_info.department.department_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  teacher.my_info.teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  teacher.my_info.teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  teacher.my_info.profile_photo_url
                                    ? teacher.my_info.profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <!-- <i
                                class="fas fa-user-times"
                                @click="removeWeekendWorkTeacher(teacher)"
                              ></i> -->
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(weekend_work_students)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="(student, index) in data.item
                                .weekend_work_students"
                              :key="index"
                            >
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url !=
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  student.my_info.student_profile_photo_url +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url ==
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  '/images/users/user.png' +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              {{ student.my_info.student_last_name[0]
                              }}{{ student.my_info.student_last_name[1] }}.{{
                                student.my_info.student_name
                              }}
                              <i
                                class="fas fa-user-times"
                                @click="removeWeekendWorkStudent(student)"
                              ></i>
                            </li>
                          </ul>
                        </span>
                      </template>
                      <!-- <template #cell(actions)="data">
                        <span v-if="data.item.status == 0">
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editWeekendWork(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteWeekendWork(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </span>
                      </template> -->
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactivity">
              <div class="card">
                <div class="card-header">
                  <strong>Устгагдсан ажил(Цагаас гадуур)</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Устгагдсан амралтын өдрийн төлөвлөж буй ажил тэмцээний
                      тоо:
                      {{ weekendWorksDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveWeekendWorksDeleted"
                            :options="pageOptionsInactiveWeekendWorksDeleted"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveRooms"
                                @click="filterInactiveRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveWeekendWorksDeleted"
                      :total-rows="totalRowsInactiveWeekendWorksDeleted"
                      :per-page="perPageInactiveWeekendWorksDeleted"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="weekendWorksDeleted"
                      :fields="fieldsInactiveWeekendWorksDeleted"
                      :current-page="currentPageInactiveWeekendWorksDeleted"
                      :per-page="perPageInactiveWeekendWorksDeleted"
                    >
                      <!-- :filter="filterInactiveRooms"
                      :filter-included-fields="filterOnInactiveRooms"
                      @filtered="onFilteredInactiveRooms" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.name }}
                        </b-badge>
                      </template>

                      <template #cell(description)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 15rem; text-align: justify"
                        >
                          {{ data.item.description }}
                        </b-badge>
                        <br />
                        <b-badge variant="warning">
                          {{ data.item.start_date }}:
                          {{ data.item.end_date }} </b-badge
                        ><br />
                        <b-badge variant="info">
                          {{ data.item.start_time }}:
                          {{ data.item.end_time }}
                        </b-badge>
                      </template>

                      <template #cell(weekend_work_owner_teacher)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-if="data.item.weekend_work_owner_teacher"
                            >
                              <img
                                v-if="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url != null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  data.item.weekend_work_owner_teacher
                                    .teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  data.item.weekend_work_owner_teacher
                                    .profile_photo_url
                                    ? data.item.weekend_work_owner_teacher
                                        .profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreWeekendWorksDeleted(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Дэлгэрэнгүй харах -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-competition"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showCompetitionInfo.name"
      class="modal fade"
      hide-footer
      @hidden="hideInfoWeekendWork"
    >
      <div class="modal-body">
        <div class="direct-chat-msg" style="text-align: center">
          <!-- <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ showCompetitionInfo.objective }}
              {{ showCompetitionInfo.register_info }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ showCompetitionInfo.start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ showCompetitionInfo.finish_date | dateYearMonthDay }}
            </span>
          </div> -->
          <img
            :src="
              showCompetitionInfo.photo_url
                ? showCompetitionInfo.photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
          />
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="hideInfoWeekendWork()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
    <!-- Багш бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-teachers"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelWeekendWorkTeacher"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(teacher, index) in formTeachers.teachers"
                :key="index"
              >
                <img
                  v-if="teacher != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                    teacher.department_name +
                    '</dd><dt>Ажлын байр:</dt><dd>' +
                    teacher.position_name +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    teacher.emp_teacher_last_name +
                    ' <span class=text-uppercase>' +
                    teacher.emp_teacher_name +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    teacher.profile_photo_url
                      ? teacher.profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formTeachers.teachers"
            deselect-label="Can't remove this value"
            :custom-label="nameTeacher"
            track-by="user_id"
            label="user_name"
            placeholder="Багш сонгоно уу!"
            :options="teachers"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                  option.emp_teacher_name
                }}</strong
              >
              Багш сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeWeekendWorkTeachers()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelWeekendWorkTeacher"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Суралцагч бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-students"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelWeekendWorkStudent"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(student, index) in formStudents.students"
                :key="index"
              >
                <img
                  v-if="student != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Анги:</dt><dd>' +
                    student.school_class_full_name +
                    '</dd><dt>Имэйл:</dt><dd>' +
                    student.email +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    student.pro_student_last_name +
                    ' <span class=text-uppercase>' +
                    student.pro_student_name +
                    student.student_profile_photo_url +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    student.student_profile_photo_url
                      ? student.student_profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formStudents.students"
            deselect-label="Can't remove this value"
            :custom-label="nameStudent"
            track-by="pro_user_id"
            label="pro_student_name"
            placeholder="Суралцагч сонгоно уу!"
            :options="students"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.pro_student_last_name[0].toUpperCase() }}.{{
                  option.pro_student_name
                }}</strong
              >
              Суралцагч сонгогдлоо
            </template>
          </multiselect>
          <multiselect
            v-model="formStudents.subject"
            deselect-label="Can't remove this value"
            :custom-label="nameSubject"
            track-by="id"
            label="subject_name"
            placeholder="Хичээл сонгоно уу!"
            :options="subjects"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="true"
            :clear-on-select="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.subject_name }}</strong>
              -ны/ний хичээл сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeWeekendWorkStudents()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelWeekendWorkStudent"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-weekend-work"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @reset="hideWeekendWork"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-3">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Амралтын өдрийн төлөвлөж буй ажил бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">
                  Амралтын өдрийн төлөвлөж буй ажил засах
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Амралтын өдрийн төлөвлөж буй ажил</label>
                    <b-form-input
                      v-model="form.name"
                      placeholder="Амралтын өдрийн төлөвлөж буй ажил"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                    ></b-form-input>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="form-group">
                    <label>Дэлгэрэнгүй мэдээлэл</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.description"
                      placeholder="Дэлгэрэнгүй мэдээлэл"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('description'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="description"></has-error>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Эхлэх огноо</label>
                      <b-form-datepicker
                        id="start-datepicker"
                        :state="true"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.start_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_date'),
                          },
                        ]"
                        placeholder="Эхлэх огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Дуусах огноо</label>
                      <b-form-datepicker
                        id="end-datepicker"
                        :state="false"
                        size="sm"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="form.end_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('end_date'),
                          },
                        ]"
                        placeholder="Дуусах огноо"
                      ></b-form-datepicker>
                      <has-error :form="form" field="end_date"></has-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Эхлэх цаг</label>
                      <b-form-timepicker
                        id="start-time"
                        :state="true"
                        size="sm"
                        now-button
                        label-no-time-selected="Эхлэх цаг сонгох"
                        label-now-button="Одоо"
                        label-close-button="Хаах"
                        today-button
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        v-model="form.start_time"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('start_time'),
                          },
                        ]"
                        placeholder="Эхлэх цаг"
                      ></b-form-timepicker>
                      <has-error :form="form" field="start_time"></has-error>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Дуусах цаг</label>
                      <b-form-timepicker
                        id="end-time"
                        :state="false"
                        size="sm"
                        today-button
                        now-button
                        label-no-time-selected="Эхлэх цаг сонгох"
                        label-now-button="Одоо"
                        label-close-button="Хаах"
                        label-today-button="Өнөөдөр"
                        reset-button
                        label-reset-button="Шинэчлэх"
                        v-model="form.end_time"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('end_time'),
                          },
                        ]"
                        placeholder="Дуусах цаг"
                      ></b-form-timepicker>
                      <has-error :form="form" field="end_time"></has-error>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="!editMode"
                  @click.prevent="storeWeekendWork()"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editMode"
                  @click.prevent="updateWeekendWork()"
                >
                  Засах
                </button>
                <button
                  class="btn btn-secondary"
                  v-show="editMode"
                  @click.prevent="cancelWeekendWork"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      weekendWorks: [],
      weekendWorksInvite: [],
      weekendWorksDeleted: [],
      teachers: [],
      students: [],
      subjects: [],

      //b-table тохиргоо
      totalRowsWeekendWorks: 1,
      currentPageWeekendWorks: 1,
      perPageWeekendWork: 25,
      pageOptionsWeekendWork: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterWeekendWorks: "",
      filterOnWeekendWorks: [],
      filterIgnoredFieldsWeekendWorks: [],
      fieldsActiveWeekendWorks: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Төлөвлөсөн ажил",
          sortable: true,
        },
        {
          key: "description",
          label: "Дэлгэрэнгүй мэдээлэл",
          sortable: true,
        },
        {
          key: "status",
          label: "Менежер танилцсан",
          sortable: true,
        },
        {
          key: "weekend_work_owner_teacher",
          label: "Хариуцагч багш",
          sortable: true,
        },
        {
          key: "weekend_work_teachers",
          label: "Хамтрах багш",
          sortable: true,
        },
        {
          key: "weekend_work_students",
          label: "Хамрагдах суралцагчид",
          sortable: true,
        },

        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //   Invite
      fieldsActiveWeekendWorksInvite: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Төлөвлөсөн ажил",
          sortable: true,
        },
        {
          key: "description",
          label: "Дэлгэрэнгүй мэдээлэл",
          sortable: true,
        },
        {
          key: "status",
          label: "Менежер танилцсан",
          sortable: true,
        },
        {
          key: "weekend_work_owner_teacher",
          label: "Хариуцагч багш",
          sortable: true,
        },
        {
          key: "weekend_work_teachers",
          label: "Хамтрах багш",
          sortable: true,
        },
        {
          key: "weekend_work_students",
          label: "Хамрагдах суралцагчид",
          sortable: true,
        },

        // {
        //   key: "actions",
        //   label: "Үйлдлүүд",
        //   sortable: false,
        // },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveWeekendWorksDeleted: 1,
      currentPageInactiveWeekendWorksDeleted: 1,
      perPageInactiveWeekendWorksDeleted: 25,
      pageOptionsInactiveWeekendWorksDeleted: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveWeekendWorksDeleted: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Төлөвлөсөн ажил",
          sortable: true,
        },
        {
          key: "description",
          label: "Дэлгэрэнгүй мэдээлэл",
          sortable: true,
        },
        {
          key: "weekend_work_owner_teacher",
          label: "Хариуцагч багш",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      showMode: false,
      showCompetitionInfo: "",
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],

      editMode: false,
      form: new Form({
        id: "",
        teacher_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        // status: "",
      }),
      //   Зураг
      getSelectedItemImg: "",
      fileInputKey: 0,

      //   Багш
      formTeachers: new Form({
        id: "",
        weekend_work_id: "",
        teachers: [],
      }),

      //   Суралцагч
      formStudents: new Form({
        id: "",
        weekend_work_id: "",
        students: [],
        subject: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllWeekendWorks() {
      this.show = true;
      axios
        .get("/teacher/getAllWeekendWorks")
        .then((response) => {
          this.weekendWorks = response.data.weekendWorks;
          this.weekendWorksDeleted = response.data.weekendWorksDeleted;

          let check = response.data.weekendWorksInvite;
          //   console.log(check);
          let arr = [];
          check.forEach((element) => {
            arr.push(element.my_owner_weekend_work);
          });
          this.weekendWorksInvite = arr;

          this.teachers = response.data.teachers;
          this.students = response.data.students;
          this.subjects = response.data.subjects;
          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //b-table тохирго
    onFilteredWeekendWork(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsWeekendWorks = filteredItems.length;
      this.currentPageWeekendWorks = 1;
    },

    createWeekendWork() {
      this.$bvModal.show("modal-weekend-work");
    },

    hideWeekendWork() {
      Fire.$emit("getWeekendWork");
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-weekend-work");
    },
    cancelWeekendWork() {
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-weekend-work");
    },
    storeWeekendWork() {
      this.form
        .post("/teacher/storeWeekendWork")
        .then((response) => {
          this.$toastr.s(
            "Амралтын өдрийн төлөвлөж буй ажил амжилттай нэмлээ",
            "Бүртгэлээ"
          );
          this.hideWeekendWork();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    editWeekendWork(weekendWork) {
      //   console.log(weekendWork);
      this.editMode = true;
      this.form.fill(weekendWork);
      this.$bvModal.show("modal-weekend-work");
    },
    updateWeekendWork() {
      //   console.log(this.form);
      this.form
        .put("/teacher/updateWeekendWork/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.hideWeekendWork();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    showWeekendWork(competition) {
      this.showCompetitionInfo = competition;
      this.$bvModal.show("modal-show-competition");
    },
    hideInfoWeekendWork() {
      this.showCompetitionInfo = "";
      this.$bvModal.hide("modal-show-competition");
    },

    deleteWeekendWork(competition) {
      //   console.log(competition);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд амралтын өдрийн төлөвлөж буй ажлын мэдээлэл болон бусад холбогдох мэдээллүүд устах болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteWeekendWork/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Амралтын өдрийн төлөвлөж буй ажлын мэдээлэл системээс устгалаа.",
                "success"
              );
              Fire.$emit("getWeekendWork");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreWeekendWorksDeleted(competition) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/restoreWeekendWorksDeleted/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Амралтын өдрийн төлөвлөж буй ажлын мэдээллийг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("getWeekendWork");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    getItemImg(e) {
      let itemImage = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(itemImage);
      reader.onload = (e) => {
        this.form.photo_url = e.target.result;
        this.getSelectedItemImg = e.target.result;
      };
      //   this.loaded = true;
    },

    //Багш бүртгэх
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    hideWeekendWorkTeacher() {
      //   Fire.$emit("getWeekendWork");
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    cancelWeekendWorkTeacher() {
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    createWeekendWorkTeacher(weekendWork) {
      //   console.log(weekendWork);
      this.formTeachers.weekend_work_id = weekendWork.id;
      this.$bvModal.show("modal-create-teachers");
    },
    storeWeekendWorkTeachers() {
      this.formTeachers
        .post("/teacher/storeWeekendWorkTeachers")
        .then((response) => {
          this.$toastr.s("Хамт ажиллах багшийг нэмлээ", "Бүртгэлээ");
          Fire.$emit("getWeekendWork");
          this.hideWeekendWorkTeacher();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeWeekendWorkTeacher(teacher) {
      //   console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Амралтын өдрийн төлөвлөж буй ажил тэмцээн хариуцах багш хасах!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/removeWeekendWorkTeacher", {
              weekend_work_id: teacher.weekend_work_id,
              teacher_id: teacher.teacher_id,
            })
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Амралтын өдрийн төлөвлөж буй ажил тэмцээн хариуцах багшийг хаслаа.",
                "success"
              );
              Fire.$emit("getWeekendWork");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    //Суралцагч бүртгэх
    nameStudent({
      pro_student_last_name,
      pro_student_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name[0].toUpperCase()}${
        pro_student_last_name[1]
      } . ${pro_student_name} : ${school_class_full_name}`;
    },
    nameSubject({ subject_name }) {
      return `${subject_name}`;
    },
    hideWeekendWorkStudent() {
      //   Fire.$emit("getWeekendWork");
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    cancelWeekendWorkStudent() {
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    createWeekendWorkStudent(weekendWork) {
      //   console.log(weekendWork);
      this.formStudents.weekend_work_id = weekendWork.id;
      this.$bvModal.show("modal-create-students");
    },

    storeWeekendWorkStudents() {
      this.formStudents
        .post("/teacher/storeWeekendWorkStudents")
        .then((response) => {
          this.$toastr.s(
            "Амралтын өдрийн төлөвлөж буй ажил тэмцээнд оролцох суралцагчдыг нэмлээ",
            "Бүртгэлээ"
          );
          Fire.$emit("getWeekendWork");
          this.hideWeekendWorkStudent();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    removeWeekendWorkStudent(student) {
      //   console.log(student);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Амралтын өдрийн төлөвлөж буй ажил тэмцээнээс тухайн суралцагчийг хасах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/removeWeekendWorkStudent", {
              weekend_work_id: student.weekend_work_id,
              school_class_id: student.school_class_id,
              student_id: student.student_id,
              subject_id: student.subject_id,
            })
            .then((res) => {
              Swal.fire(
                "Хассан!",
                "Амралтын өдрийн төлөвлөж буй ажил тэмцээнээс суралцагчийг хаслаа.",
                "success"
              );
              Fire.$emit("getWeekendWork");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  created() {
    this.getAllWeekendWorks();
    Fire.$on("getWeekendWork", () => {
      this.getAllWeekendWorks();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
