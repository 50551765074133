<template>
  <div>
    <div class="row pt-3">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box">
          <span class="info-box-icon bg-info elevation-1"
            ><i class="fas fa-cog"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Нийт үнэлгээ</span>
            <span class="info-box-number">
              {{ allTeacherEvalCount }}
              <!-- <small>%</small> -->
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-danger elevation-1"
            ><i class="fas fa-thumbs-up"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Нийт үнэлгээний дундаж</span>
            <span class="info-box-number">
              {{ allTeacherEvalAvg }}
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->

      <!-- fix for small devices only -->
      <div class="clearfix hidden-md-up"></div>

      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-success elevation-1"
            ><i class="fas fa-shopping-cart"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Мэргэжлийн баг</span>
            <span class="info-box-number">
              {{ allTeacherEvalDepartmentsCount }}
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-warning elevation-1"
            ><i class="fas fa-users"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Үнэлэгдсэн багш нар</span>
            <span class="info-box-number">{{ allTeacherEvalEmployees }} </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
    </div>
    <!-- /.col -->
    <div class="row">
      <div class="col-lg-12">
        <div class="small-box bg-white">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <!-- <apexchart
            width="85%"
            type="bar"
            :options="options"
            :series="series"
          ></apexchart> -->
        </div>
      </div>
      <div class="col-lg-12">
        <div class="small-box bg-white">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions1"
            :series="series1"
          ></apexchart>
          <!-- <apexchart
            width="85%"
            type="bar"
            :options="options"
            :series="series"
          ></apexchart> -->
        </div>
      </div>
      <div class="col-lg-12">
        <div class="small-box bg-white">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions2"
            :series="series2"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MyPie from "./PieChart.vue";
// import VueApexCharts from "vue-apexcharts";
export default {
  data() {
    return {
      allTeacherEvalCount: null,
      allTeacherEvalAvg: null,
      allTeacherEvalDepartmentsCount: null,
      allTeacherEvalDepartments: [],
      allTeacherEvalEmployees: null,
      allTeacherEvalMonthsEmployees: [],
      // allModulesSubTraining: "",
      // allModulesSubTrainingTimesNiilber: "",
      // allModulesSubTrainingTimes: "",
      // allModulesSubTrainingStudents: "",
      // allModulesTimes: "",

      //График

      // Ажлын үнэлгээний дундаж сараар харуулах
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            // dataLabels: {
            //   position: "top", // top, center, bottom
            // },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          // offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          position: "top",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0,
            },
          },
        },
        yaxis: {
          title: {
            text: "%",
          },
        },
        // fill: {
        //   opacity: 1,
        // },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val + " %";
        //     },
        //   },
        // },
        title: {
          text: "Багшийн ажлын үнэлгээний дундаж сараар",
          floating: true,
          // offsetY: 330,
          align: "top",
          style: {
            color: "#444",
          },
        },
      },
      // Ажлын үнэлгээний дундаж үзүүлэлт бүрээр сараар харуулах
      series1: [
        {
          name: "Сургалтын цогц байдал ба тэгш боломж",
          data: [],
        },
        {
          name: "Сургалтын ээлжит үйл ажиллагааны чанар",
          data: [],
        },
        {
          name: "Суралцагчдын хөгжилд гарсан ахиц, өөрчлөлт",
          data: [],
        },
        {
          name: "Сургалтын хөтөлбөр технологийг хөгжүүлсэн байдал",
          data: [],
        },
      ],
      chartOptions1: {
        chart: {
          type: "bar",
          height: 350,
          // stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: [],
          // tickPlacement: "between",
          // floating: true,
          position: "bottom",
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "Багшийн ажлын үнэлгээг сар бүр дэх 4 үзүүлэлтээр харуулбал",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          tooltip: {
            formatter: function (val, opts) {
              return val + "...";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 0,
          horizontalAlign: "center",
        },
      },

      // Ажлын үнэлгээний дундаж үзүүлэлт бүрээр сараар харуулах
      series2: [
        {
          name: "Сургалтын цогц байдал ба тэгш боломж",
          data: [],
        },
        {
          name: "Сургалтын ээлжит үйл ажиллагааны чанар",
          data: [],
        },
        {
          name: "Суралцагчдын хөгжилд гарсан ахиц, өөрчлөлт",
          data: [],
        },
        {
          name: "Сургалтын хөтөлбөр технологийг хөгжүүлсэн байдал",
          data: [],
        },
        {
          name: "Дундаж",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          type: "bar",
          height: 350,
          // stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: [],
          // tickPlacement: "between",
          // floating: true,
          position: "bottom",
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "Багшийн нарын багаар 4 үзүүлэлт болон дундаж утгаар харуулбал",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          tooltip: {
            formatter: function (val, opts) {
              return val + "...";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 0,
          horizontalAlign: "center",
        },
      },
    };
  },
  components: {},
  computed: {
    convertFromMinuteToHour() {
      let a = this.allModulesSubTrainingTimesNiilber / 60;
      return a.toFixed(1);
    },
  },
  methods: {
    getAllHrTeacherEvalStatsDatas() {
      axios
        .get("/hr/indexHrTeacherEvalStatDatas")
        .then((response) => {
          // console.log(response.data);

          this.allTeacherEvalCount = response.data.allTeacherEvalCount;

          response.data.allTeacherEvalAvg.forEach((element) => {
            element.dundaj = this.getScore(element.dundaj);
          });
          this.allTeacherEvalAvg = response.data.allTeacherEvalAvg[0]["dundaj"];

          this.allTeacherEvalDepartmentsCount =
            response.data.allTeacherEvalDepartments.length;
          this.allTeacherEvalDepartments =
            response.data.allTeacherEvalDepartments;

          response.data.allTeacherEvalEmployees.forEach((element) => {
            element.dundaj = this.getScore(element.dundaj);
          });
          this.allTeacherEvalEmployees =
            response.data.allTeacherEvalEmployees.length;

          this.allTeacherEvalMonthsEmployees =
            response.data.allTeacherEvalMonthsEmployees;
          // chartOptions;
          //     xaxis: {
          //   categories: [],
          // },
          this.allTeacherEvalMonthsEmployees.forEach((element) => {
            this.series.push({
              name: element.ajliin_sar + " сар",
              data: [this.getScore(element.dundaj)],
            });
            this.chartOptions.xaxis.categories.push(
              element.ajliin_sar + " сар"
            );
          });
          //Ажлын сарын 4 үзүүлэлт тус бүрээр.
          // console.log(this.allTeacherEvalMonthsEmployees);
          this.allTeacherEvalMonthsEmployees.forEach((element) => {
            this.series1[0].data.push(this.getScore(parseFloat(element.shal1)));
            this.series1[1].data.push(this.getScore(parseFloat(element.shal2)));
            this.series1[2].data.push(this.getScore(parseFloat(element.shal3)));
            this.series1[3].data.push(this.getScore(parseFloat(element.shal4)));
            this.chartOptions1.xaxis.categories.push(
              element.ajliin_sar + " сар"
            );
          });
          //Ажлын сарын 4 үзүүлэлт тус бүрээр мэргэжлийн багуудыг харуулах.
          // console.log(this.allTeacherEvalMonthsEmployees);
          this.allTeacherEvalDepartments.forEach((element) => {
            this.series2[0].data.push(this.getScore(parseFloat(element.shal1)));
            this.series2[1].data.push(this.getScore(parseFloat(element.shal2)));
            this.series2[2].data.push(this.getScore(parseFloat(element.shal3)));
            this.series2[3].data.push(this.getScore(parseFloat(element.shal4)));
            this.series2[4].data.push(
              this.getScore(parseFloat(element.dundaj))
            );
            this.chartOptions2.xaxis.categories.push(element.department_code);
          });
          // this.allTeacherEvalMonthsEmployees.forEach((element) => {});
          // console.log(this.chartOptions1.xaxis.categories);
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    //Орон нарийвчлах
    getScore(val) {
      return val.toFixed(1);
    },
    //Эерэг сурах утга хэвлэх
    signCheck(number) {
      return Math.sign(number);
    },
    checkClass(value) {
      if (value <= 50) {
        return "bg-danger";
      } else if (value <= 80) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
  },
  created() {
    this.getAllHrTeacherEvalStatsDatas();
  },
};
</script>

<style>
</style>
