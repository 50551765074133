<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Цэсний жагсаалт</strong>
          </h3>
          <b-form-checkbox
            class="float-right success"
            :name="colapseStatus"
            aria-describedby="active"
            switch
            size="xs"
            @change="changeStatus"
            ><span v-if="colapseStatus == 'true'" class="badge bg-primary"
              >НЭЭГДСЭН
            </span>
            <span v-else class="badge bg-secondary">ХААГДСАН </span>
          </b-form-checkbox>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="8">
                  <multiselect
                    v-model="selectedRoles"
                    :options="roles"
                    :multiple="true"
                    track-by="id"
                    :custom-label="customRoleName"
                    :hideSelected="true"
                    :searchable="true"
                    :close-on-select="false"
                    :show-labels="true"
                    placeholder="Role сонгох"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.name }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="primary" @click.prevent="getAllMenus()"
                    >Шүүж харах</b-button
                  >
                </b-col>
                <b-col sm="2">
                  <span class="float-right">
                    Нийт цэс:
                    <strong>{{ menuList.length }}</strong>
                  </span>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <tbody>
              <tr v-for="menu in menuList" :key="menu.id" class="expandable-body">
                <td>
                  <div class="p-0">
                    <table class="table table-hover m-0 w-100">
                      <tbody>
                        <tr data-widget="expandable-table" :aria-expanded="colapseStatus">
                          <td>
                            <div class="d-flex justify-content-between w-100">
                              <div>
                                <b-badge pill class="mr-1 menuLevel1">{{
                                  menu.order
                                }}</b-badge>
                                <i
                                  :class="[
                                    {
                                      'expandable-table-caret fas fa-caret-right fa-fw':
                                        menu.isParent == 1,
                                    },
                                  ]"
                                ></i>
                                <strong>{{ menu.name }}</strong>
                                <b-badge
                                  v-if="menu.isParent == 0"
                                  :variant="menu.isMobile == 1 ? 'success' : 'light'"
                                  class="mr-1"
                                >
                                  Холбоос:
                                  {{ menu.url }}
                                </b-badge>
                                <i
                                  v-if="menu.iconType == 'favicon'"
                                  :class="[menu.icon, 'text-dark']"
                                ></i>
                                <b-icon
                                  v-if="menu.iconType == 'bootstrap'"
                                  class="bootstrap"
                                  :icon="menu.icon"
                                ></b-icon>
                              </div>
                              <div class="actions">
                                <a
                                  href="#"
                                  class="badge bg-primary"
                                  @click="editMenu(menu)"
                                  ><i class="fas fa-edit"></i
                                ></a>
                                <a
                                  href="#"
                                  class="badge bg-danger"
                                  @click="deleteMenu(menu)"
                                  ><i class="fas fa-trash"></i
                                ></a>
                              </div>
                              <!-- <b-icon
                                    icon="arrow-right"
                                    font-scale="0.8"
                                ></b-icon> -->
                            </div>
                            <div class="mt-1 roles ml-4">
                              <span class="mt-1">Role:</span>
                              <b-badge
                                variant="light"
                                class="ml-1 mt-1"
                                v-for="role in menu.my_roles"
                                :key="role.id"
                              >
                                {{ role.name }}
                              </b-badge>
                            </div>
                          </td>
                        </tr>
                        <tr
                          :class="[
                            'expandable-body',
                            {
                              'd-none': colapseStatus == 'false',
                            },
                          ]"
                        >
                          <td>
                            <div class="p-0" :style="dNone">
                              <table class="table table-hover m-0 w-100">
                                <tbody v-for="child in menu.my_childs" :key="child.id">
                                  <tr
                                    data-widget="expandable-table"
                                    :aria-expanded="colapseStatus"
                                  >
                                    <td>
                                      <div class="d-flex justify-content-between w-100">
                                        <div>
                                          <i
                                            :class="[
                                              {
                                                'expandable-table-caret fas fa-caret-right fa-fw':
                                                  child.isParent == 1,
                                              },
                                              {
                                                'expandable-table-caret fas fa-dot-circle-o fa-fw':
                                                  child.isParent == 0,
                                              },
                                            ]"
                                          ></i>
                                          <b-badge pill class="mr-1 menuLevel2"
                                            >{{ menu.order }}.{{ child.order }}</b-badge
                                          >
                                          {{ child.name }}
                                          <b-badge
                                            v-if="child.isParent == 0"
                                            :variant="child.isMobile == 1 ? 'success' : 'light'"
                                            class="mr-1"
                                          >
                                            Холбоос:
                                            {{ child.url }}
                                          </b-badge>
                                          <i
                                            v-if="child.iconType == 'favicon'"
                                            :class="[child.icon, 'text-dark']"
                                          ></i>
                                          <b-icon
                                            v-if="child.iconType == 'bootstrap'"
                                            class="bootstrap"
                                            :icon="child.icon"
                                          ></b-icon>
                                        </div>
                                        <div class="actions">
                                          <a
                                            href="#"
                                            class="badge bg-primary"
                                            @click="editMenu(child)"
                                            ><i class="fas fa-edit fa-sm"></i
                                          ></a>
                                          <a
                                            href="#"
                                            class="badge bg-danger"
                                            @click="deleteMenu(child)"
                                            ><i class="fas fa-trash fa-sm"></i
                                          ></a>
                                        </div>
                                      </div>

                                      <div class="mt-1 roles ml-4">
                                        <span class="mt-1">Role:</span>
                                        <b-badge
                                          variant="light"
                                          class="ml-1 mt-1"
                                          v-for="role in child.my_roles"
                                          :key="role.id"
                                        >
                                          {{ role.name }}
                                        </b-badge>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    :class="[
                                      'expandable-body',
                                      {
                                        'd-none': colapseStatus == 'false',
                                      },
                                    ]"
                                  >
                                    <td>
                                      <div class="py-0 pl-5 pr-0" :style="dNone">
                                        <table class="table table-hover w-100 m-0">
                                          <tbody
                                            v-for="child2 in child.my_childs"
                                            :key="child2.id"
                                          >
                                            <tr>
                                              <td>
                                                <div
                                                  class="d-flex justify-content-between w-100"
                                                >
                                                  <div>
                                                    <b-badge pill class="mr-1 menuLevel3"
                                                      >{{ menu.order }}.{{
                                                        child.order
                                                      }}.{{ child2.order }}</b-badge
                                                    >
                                                    {{ child2.name }}
                                                    <b-badge
                                                      v-if="child2.isParent == 0"
                                                      :variant="child2.isMobile == 1 ? 'success' : 'light'"
                                                      class="mr-1"
                                                    >
                                                      Холбоос:
                                                      {{ child2.url }}
                                                    </b-badge>
                                                    <i
                                                      v-if="child2.iconType == 'favicon'"
                                                      :class="[child2.icon, 'text-dark']"
                                                    ></i>
                                                    <b-icon
                                                      v-if="
                                                        child2.iconType == 'bootstrap'
                                                      "
                                                      class="bootstrap"
                                                      :icon="child2.icon"
                                                    ></b-icon>
                                                  </div>
                                                  <div class="actions">
                                                    <a
                                                      href="#"
                                                      class="badge bg-primary"
                                                      @click="editMenu(child2)"
                                                      ><i class="fas fa-edit fa-xs"></i
                                                    ></a>
                                                    <a
                                                      href="#"
                                                      class="badge bg-danger"
                                                      @click="deleteMenu(child2)"
                                                      ><i class="fas fa-trash fa-xs"></i
                                                    ></a>
                                                  </div>
                                                </div>

                                                <div class="mt-1 roles ml-4">
                                                  <span class="mt-1">Role:</span>
                                                  <b-badge
                                                    variant="light"
                                                    class="ml-1 mt-1"
                                                    v-for="role in child2.my_roles"
                                                    :key="role.id"
                                                  >
                                                    {{ role.name }}
                                                  </b-badge>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong v-if="!editMenuMode">Цэс нэмэх</strong>
            <strong v-else>Цэс засах</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Модуль</label>
              <b-form-select
                v-model="formMenu.module_id"
                :options="modules"
                text-field="name"
                value-field="id"
                size="sm"
                class="form-control"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Role</label>
              <multiselect
                v-model="formMenu.selected_roles"
                :options="roles"
                :multiple="true"
                track-by="id"
                :custom-label="customRoleName"
                :searchable="true"
                :close-on-select="false"
                :hideSelected="true"
                :show-labels="true"
                placeholder="Role сонгох"
                :allow-empty="false"
                deselect-label="Хасах"
                select-label="Сонгох"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.name }} - {{ option.description }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                v-model="formMenu.name"
                placeholder="Нэр"
                class="form-control"
                :class="{
                  'is-invalid': formMenu.errors.has('name'),
                }"
              />
            </div>
            <div class="form-group">
              <label>Эрэмбэ</label>
              <input
                type="number"
                v-model="formMenu.order"
                placeholder="Эрэмбэ"
                class="form-control"
                :class="{
                  'is-invalid': formMenu.errors.has('order'),
                }"
              />
            </div>
            <div class="form-group">
              <b-form-checkbox v-model="formMenu.isMobile" value="1" unchecked-value="0">
                Утасны цэс эсэх
              </b-form-checkbox>
            </div>
            <div class="form-group">
              <b-form-checkbox v-model="formMenu.isMain" value="1" unchecked-value="0">
                Үндсэн цэс эсэх
              </b-form-checkbox>
            </div>
            <div class="form-group" v-if="formMenu.isMain == 0">
              <label>Эцэг цэс сонгох</label>
              <b-form-select
                v-model="formMenu.parent_menu_id"
                :options="parentMenus"
                text-field="name"
                value-field="id"
                size="sm"
                class="form-control"
              ></b-form-select>
            </div>
            <div class="form-group">
              <b-form-checkbox v-model="formMenu.isParent" value="1" unchecked-value="0">
                Эцэг цэс эсэх
              </b-form-checkbox>
            </div>
            <div class="form-group" v-if="formMenu.isParent == 0">
              <b-form-checkbox v-model="formMenu.isUrl" value="1" unchecked-value="0">
                Гадаад холбоос эсэх
              </b-form-checkbox>
            </div>
            <div class="form-group" v-if="formMenu.isParent == 0">
              <label>Холбоос ( /-р эхэлнэ )</label>
              <input
                type="text"
                v-model="formMenu.url"
                placeholder="/student/home"
                class="form-control"
                :class="{
                  'is-invalid': formMenu.errors.has('url'),
                }"
              />
            </div>
            <div class="form-group">
              <label>Дүрсний төрөл</label>
              <b-form-select
                v-model="formMenu.iconType"
                :options="iconTypes"
                text-field="name"
                value-field="name"
                size="sm"
                class="form-control"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Дүрс (favicon v 5.15.4)</label>
              <input
                type="text"
                v-model="formMenu.icon"
                placeholder="fa fa-pencil, pencil"
                class="form-control"
                :class="{
                  'is-invalid': formMenu.errors.has('icon'),
                }"
              />
            </div>
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-sm btn-success"
              @click.prevent="updateMenu()"
              v-if="editMenuMode"
            >
              Засах
            </b-button>
            <button
              type="submit"
              v-if="!editMenuMode"
              @click.prevent="createMenu()"
              class="btn btn-sm btn-primary"
            >
              Нэмэх
            </button>
            <button @click.prevent="cancelEditMenu()" class="btn btn-sm btn-warning">
              Болих
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selectedRoles: "",
      dNone: "display: none",
      colapseStatus: "false",
      editMenuMode: false,
      formMenu: new Form({
        id: "",
        module_id: "",
        selected_roles: [],
        parent_menu_id: "",
        isMobile: 0,
        isMain: 0,
        isParent: 0,
        name: "",
        order: "",
        isUrl: 0,
        url: "",
        iconType: "favicon",
        icon: "",
      }),
      menuList: [],
      parentMenus: [],
      modules: [],
      roles: [],
      iconTypes: [{ name: "favicon" }, { name: "bootstrap" }],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    customRoleName({ name, description }) {
      return `${description}(${name})`;
    },
    changeStatus() {
      if (this.colapseStatus == "false") {
        this.colapseStatus = "true";
        this.dNone = "";
      } else {
        this.colapseStatus = "false";
        this.dNone = "display: none";
      }
    },
    getAllRoles() {
      axios
        .get("/owner/getRoles")
        .then((response) => {
          this.roles = response.data.roles;
          this.modules = response.data.modules;
          this.parentMenus = response.data.parentMenus;
        })
        .catch(() => {});
    },
    getAllMenus() {
      axios
        .post("/owner/getAllMenus", {
          selectedRoles: this.selectedRoles,
        })
        .then((response) => {
          this.menuList = response.data.menuList;
        })
        .catch(() => {});
    }, //================== Menu ======================
    editMenu(menu) {
      this.formMenu.fill(menu);
      this.formMenu.selected_roles = menu.my_roles;
      this.editMenuMode = true;
    },
    updateMenu() {
      this.formMenu
        .put("/owner/updateMenu/" + this.formMenu.id)
        .then(() => {
          this.$toastr.s("Цэс амжилттай заслаа.", "Амжилттай");
          this.getAllMenus();
          this.formMenu.reset();
          this.editMenuMode = false;
        })
        .catch(() => {});
    },
    cancelEditMenu() {
      this.formMenu.reset();
      this.editMenuMode = false;
    },
    createMenu() {
      this.formMenu
        .post("/owner/createMenu")
        .then(() => {
          this.$toastr.s("Цэс амжилттай нэмлээ.", "Амжилттай");
          this.getAllMenus();
          this.formMenu.reset();
          this.editMenuMode = false;
        })
        .catch(() => {});
    },
    deleteMenu(menu) {
      Swal.fire({
        title: "Цэс устгах!",
        text: "Та " + menu.name + " цэсийг устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Цэс хасаж байна...";
          axios
            .get("/owner/deleteMenu/" + menu.id)
            .then((response) => {
              if (!response.data.errorMsj) {
                this.$toastr.s("Цэс амжилттай устгалаа!", "Шинэчилсэн");
                this.getAllMenus();
              } else this.$toastr.w(response.data.errorMsj, "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },
  created() {
    this.getAllRoles();
    this.getAllMenus();
  },
};
</script>

<style scoped>
.bootstrap {
  color: #563d7c;
}
.menuLevel1 {
  background-color: #0050fd;
}
.menuLevel2 {
  background-color: #3562c4;
}
.menuLevel3 {
  background-color: #668cdf;
}
.badge.roles {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
</style>
