<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Гадны хэрэглэгчдээс ирсэн санал, өргөдөл, гомдол</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#timeline" data-toggle="tab"
                >Эцэг эх(Хүүхэд нууцалсан)</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#settings" data-toggle="tab"
                >Эцэг эх, хүүхэд тодорхой</a
              >
            </li>
            <li class="nav-item" v-if="allComplaintsEmployees.length != 0">
              <a class="nav-link" href="#employees" data-toggle="tab"
                >Багш, ажилчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#decisions" data-toggle="tab"
                >Шийдвэрлэсэн</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsPublic"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            class="btn btn-warning btn-sm"
                            @click.prevent="editComplain(complain)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <!-- <span v-if="complain.comp_par_decision != null">
                            {{ complain.comp_par_decision }}
                          </span> -->
                        </td>
                        <!-- <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            {{ complain.comp_par_decision_explain }}
                          </b-badge>
                        </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="timeline">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Мэргэжил</th>
                        <th>Утас</th>
                        <th>Хүүхэд</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <!-- <th>Шийдвэрийн тайлбар</th> -->
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsParent"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                          </span>
                          {{ complain.parent_info.parent_father_name }}
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge
                            variant="success"
                            v-show="
                              complain.parent_info.parent_father_mergejil !=
                              null
                            "
                          >
                            Аав:
                            {{ complain.parent_info.parent_father_mergejil }}

                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_mergejil
                            }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="text-align: center"
                        >
                          <b-badge variant="light">
                            Аав:
                            {{
                              complain.parent_info.parent_father_phone_number
                            }}
                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_phone_number
                            }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light" style="white-space: pre-wrap"
                            >{{ complain.scho_cla_full_name }}:
                            {{ complain.prof_student_name }}</b-badge
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            class="btn btn-warning btn-sm"
                            @click.prevent="editComplain(complain)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <!-- <span v-if="complain.comp_par_decision != null">
                            {{ complain.comp_par_decision }}
                          </span> -->
                        </td>
                        <!-- <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            {{ complain.comp_par_decision_explain }}
                          </b-badge>
                        </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->

            <div class="tab-pane" id="settings">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Мэргэжил</th>
                        <th>Утас</th>
                        <th>Хүүхэд</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <!-- <th>Шийдвэрийн тайлбар</th> -->
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsParentChild"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                          </span>
                          {{ complain.parent_info.parent_father_name }}
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge
                            variant="success"
                            v-show="
                              complain.parent_info.parent_father_mergejil !=
                              null
                            "
                          >
                            Аав:
                            {{ complain.parent_info.parent_father_mergejil }}

                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_mergejil
                            }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge variant="light">
                            Аав:
                            {{
                              complain.parent_info.parent_father_phone_number
                            }}
                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_phone_number
                            }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge variant="light" style="white-space: pre-wrap"
                            >{{ complain.scho_cla_full_name }}:
                            {{ complain.prof_student_name }}</b-badge
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            class="btn btn-warning btn-sm"
                            @click.prevent="editComplain(complain)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <!-- <span v-if="complain.comp_par_decision != null">
                            {{ complain.comp_par_decision }}
                          </span> -->
                        </td>
                        <!-- <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            {{ complain.comp_par_decision_explain }}</b-badge
                          >
                        </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <div
              class="tab-pane"
              id="employees"
              v-if="allComplaintsEmployees.length != 0"
            >
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <!-- <th>Мэргэжил</th> -->
                        <!-- <th>Утас</th> -->
                        <!-- <th>Хүүхэд</th> -->
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <th>Шийдвэрийн тайлбар</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsEmployees"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.employee_info">
                            {{
                              complain.employee_info.teacher_last_name
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.employee_info.teacher_name }}
                          </span>
                        </td>
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    style="text-align: center"
                                                    tabindex="0"
                                                >
                                                    <b-badge
                                                        variant="success"
                                                        v-show="
                                                            complain.parent_info
                                                                .parent_father_mergejil !=
                                                                null
                                                        "
                                                    >
                                                        Аав:
                                                        {{
                                                            complain.parent_info
                                                                .parent_father_mergejil
                                                        }}

                                                        <br />
                                                        Ээж:
                                                        {{
                                                            complain.parent_info
                                                                .parent_mother_mergejil
                                                        }}</b-badge
                                                    >
                                                </td> -->
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    style="text-align: center"
                                                    tabindex="0"
                                                >
                                                    <b-badge variant="light">
                                                        Аав:
                                                        {{
                                                            complain.parent_info
                                                                .parent_father_phone_number
                                                        }}
                                                        <br />
                                                        Ээж:
                                                        {{
                                                            complain.parent_info
                                                                .parent_mother_phone_number
                                                        }}
                                                    </b-badge>
                                                </td> -->
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    <b-badge
                                                        variant="light"
                                                        style="white-space: pre-wrap"
                                                        >{{
                                                            complain.scho_cla_full_name
                                                        }}:
                                                        {{
                                                            complain.prof_student_name
                                                        }}</b-badge
                                                    >
                                                </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            class="btn btn-warning btn-sm"
                            @click.prevent="editComplain(complain)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <!-- <span v-if="complain.comp_par_decision != null">
                            {{ complain.comp_par_decision }}
                          </span> -->
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-if="complain.comp_par_decision_explain !== null"
                              v-html="
                                complain.comp_par_decision_explain.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                            <span
                              v-else-if="
                                complain.comp_par_decision_explain === null
                              "
                              >Тайлбар бүртгэгдээгүй</span
                            >
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else>Мэргэжлийн баг бүртгэгдээгүй </span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <div class="tab-pane" id="decisions">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Утас</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <th>Шийдвэрийн тайлбар</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsDecisions"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.parent_info.parent_father_name }}
                          </span>
                          <span v-else-if="complain.employee_info">
                            {{
                              complain.employee_info.teacher_last_name
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.employee_info.teacher_name }}
                            багш/ажилтан</span
                          >
                          <span v-else>Гадны хэрэглэгч</span>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="text-align: center"
                        >
                          <b-badge variant="light" v-if="complain.parent_info">
                            Аав:
                            {{
                              complain.parent_info.parent_father_phone_number
                            }}
                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_phone_number
                            }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-if="complain.comp_par_main_problem !== null"
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            class="btn btn-warning btn-sm"
                            @click.prevent="editComplain(complain)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <b-badge
                            variant="success"
                            v-if="
                              complain.comp_par_decision != null ||
                              complain.comp_par_decision == 1
                            "
                            >Шийдвэрлэсэн</b-badge
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 20rem"
                          >
                            <span
                              v-if="complain.comp_par_decision_explain !== null"
                              v-html="
                                complain.comp_par_decision_explain.substring(
                                  0,
                                  100000
                                )
                              "
                            ></span>
                            <span
                              v-else-if="
                                complain.comp_par_decision_explain === null
                              "
                              >Тайлбар бүртгэгдээгүй</span
                            >
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
          </div>
          <!-- /.tab-content -->
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-complain"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Өргөдөл, гомдол, санал, хүсэлтэд хариу шийдвэр өгөх."
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 mt-2">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Ирсэн ёс зүй, өргөдөл, гомдол, санал хүсэлтийн дэлгэрэнгүй
                      мэдээлэл.
                    </h3>
                  </div>
                  <!-- /.card-header -->

                  <div class="card-body table-responsive">
                    <div class="row">
                      <div class="col-sm-3">
                        <b-form-checkbox
                          id="checkbox-2"
                          name="checkbox-2"
                          v-model="poll"
                          :disabled="disabledCheckbox"
                        >
                          Санал
                        </b-form-checkbox>
                      </div>
                      <div class="col-sm-3">
                        <b-form-checkbox
                          id="checkbox-3"
                          name="checkbox-3"
                          v-model="complaint"
                          :disabled="disabledCheckbox"
                        >
                          Гомдол
                        </b-form-checkbox>
                      </div>
                      <div class="col-sm-3">
                        <b-form-checkbox
                          id="checkbox-4"
                          name="checkbox-4"
                          v-model="contribution"
                          :disabled="disabledCheckbox"
                        >
                          Хамтран ажиллах
                        </b-form-checkbox>
                      </div>
                      <div class="col-sm-3">
                        <b-form-checkbox
                          id="checkbox-5"
                          name="checkbox-5"
                          v-model="ethics"
                          :disabled="disabledCheckbox"
                        >
                          Ёс зүй
                        </b-form-checkbox>
                      </div>
                    </div>
                    <b><span>Эцэг эхийн зүгээс ирүүлсэн гол агуулга</span></b>
                    <br />
                    <span
                      v-if="showMoreInfo.comp_par_main_problem !== null"
                      v-html="showMoreInfo.comp_par_main_problem"
                    ></span>
                    <span v-else>Эцэг эх тайлбар өгөөгүй.</span>

                    <table class="table text-wrap">
                      <thead>
                        <tr role="row">
                          <th class="col-1">№</th>
                          <th class="col-5">Асуулт</th>
                          <th class="col-6">Хариулт</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            Санал гомдол гаргах болсон асуудлын гол шалтгааныг
                            та юу гэж бодож байна вэ?
                          </td>
                          <td>
                            <span
                              v-if="showMoreInfo.comp_par_question1 !== null"
                              v-html="showMoreInfo.comp_par_question1"
                            ></span>
                            <span v-else>Эцэг эх тайлбар өгөөгүй.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            Асуудлаас болоод үүсч буй сөрөг үр дагавар нь юу вэ?
                          </td>
                          <td>
                            <span
                              v-if="showMoreInfo.comp_par_question2 !== null"
                              v-html="showMoreInfo.comp_par_question2"
                            ></span>
                            <span v-else>Эцэг эх тайлбар өгөөгүй.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            Та хүүхэдтэйгээ асуудлын шалтгаан ба шийдлийн талаар
                            ярилцаж, санаа бодлыг нь сонссон уу? Асуудлыг өөрөөр
                            нь шийдүүлэх, хандлагыг нь эерэг болгох оролдлого
                            хийсэн үү?
                          </td>
                          <td>
                            <span
                              v-if="showMoreInfo.comp_par_question3 !== null"
                              v-html="showMoreInfo.comp_par_question3"
                            ></span>
                            <span v-else>Эцэг эх тайлбар өгөөгүй.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            Энэ асуудлыг шийдвэрлэснээр бий болох үр дүн буюу
                            хүлээлт тань юу вэ? Таны хувьд асуудлыг шийдэх тал
                            дээр ямар арга замыг санал болгож байгаа вэ?
                          </td>
                          <td>
                            <span
                              v-if="showMoreInfo.comp_par_question4 !== null"
                              v-html="showMoreInfo.comp_par_question4"
                            ></span>
                            <span v-else>Эцэг эх тайлбар өгөөгүй.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            Асуудлыг бий болгоод байгаа багш (ажилтан)-тай
                            уулзаж асуудлын шийдэл, хүлээгдэж буй үр дүнгийнхээ
                            талаар ярилцсан уу? Ямар нэг ахиц өөрчлөлт
                            ажиглагдсан уу?
                          </td>
                          <td>
                            <span
                              v-if="showMoreInfo.comp_par_question5 !== null"
                              v-html="showMoreInfo.comp_par_question5"
                            ></span>
                            <span v-else>Эцэг эх тайлбар өгөөгүй.</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-2">
            <div class="card">
              <!-- <div class="card-header">
                <h3 class="card-title">
                  {{ form.comp_par_main_problem }}
                </h3>
              </div> -->
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <!-- <multiselect
                  v-show="selectedEmployee"
                  v-model="form.comp_par_department_id"
                  :options="departments"
                  track-by="department_name"
                  label="department_name"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Мэргэжлийн баг сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.department_name }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect> -->

                <!-- <b-form-textarea
                  id="textarea"
                  v-model="form.comp_par_decision_explain"
                  placeholder="Шийдвэрийн тайлбар"
                  rows="5"
                  max-rows="10"
                ></b-form-textarea> -->
                <quill-editor
                  v-model="form.comp_par_decision_explain"
                  :options="editorOptionParent"
                  placeholder="Шийдвэрийн тайлбар"
                >
                </quill-editor>

                <br />
                <br />
                <b-button
                  type="submit"
                  variant="primary"
                  @click.prevent="onUpdate()"
                  >Засах</b-button
                >
                <b-button
                  type="reset"
                  variant="danger"
                  @click.prevent="onCancel()"
                  >Болих</b-button
                >
                <!-- </b-form> -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      allComplaintsPublic: [],
      allComplaintsParent: [],
      allComplaintsParentChild: [],
      allComplaintsEmployees: [],
      allComplaintsDecisions: [],
      departments: [],
      //MODAL BEGIN
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showMode: false,
      //MODAL END

      form: new Form({
        comp_par_id: "",
        comp_par_main_problem: "",
        comp_par_decision_explain: "",
        created_at: "",
      }),
      selectedEmployee: false,
      showMoreInfo: "",
      disabledCheckbox: true,
      //   vue-quill-editor
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      editorOptionParent: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder:
          "Эцэг эхийн зүгээс санал, гомдол, хүсэлтэд тайлбар өгөх...",
      },
      poll: false,
      complaint: false,
      contribution: false,
      ethics: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllComplaint() {
      axios
        .get("/manager/getMyDepComplaints")
        .then((response) => {
          this.allComplaintsPublic = response.data.allComplaintsPublic;
          this.allComplaintsParent = response.data.allComplaintsParent; //Нууцалсан
          this.allComplaintsParentChild =
            response.data.allComplaintsParentChild;
          this.allComplaintsEmployees = response.data.allComplaintsEmployees;
          this.allComplaintsDecisions = response.data.allComplaintsDecisions;
          this.departments = response.data.departments;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editComplain(complain) {
      //   console.log(complain);
      // this.form.fill(complain);
      this.showMoreInfo = complain;
      this.poll = complain.comp_par_poll === 1 ? true : false;
      this.complaint = complain.comp_par_complaint === 1 ? true : false;
      this.contribution = complain.comp_par_contribution === 1 ? true : false;
      this.ethics = complain.comp_par_ethics === 1 ? true : false;
      this.form.comp_par_id = complain.id;
      this.form.comp_par_decision_explain = complain.comp_par_decision_explain;

      if (complain.employee_info) {
        this.selectedEmployee = true;
        this.departments.filter((el) => {
          if (el.department_id == complain.department_id) {
            this.formDeps.comp_par_department_id = el;
          }
        });
      }
      this.$bvModal.show("modal-complain");
    },
    onUpdate() {
      //   console.log("onUpdate");
      this.form
        .put("/manager/updateMyDepComplaints")
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.onCancel();
          Fire.$emit("loadComplains");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    onCancel() {
      // console.log("onCancel");
      this.showMoreInfo = "";
      this.form.reset();
      this.selectedEmployee = false;
      this.$bvModal.hide("modal-complain");
    },
    // onReset() {
    //   console.log("onReset");
    // },
    resetModal() {
      //   console.log("resetModal");
      this.showMoreInfo = "";
      this.form.reset();
    },
    selectDepartment(value) {
      //   console.log(value);
      let dep = this.departments.filter((el) => el.department_id == value);
      return dep[0]["department_code"];
    },
  },
  created() {
    this.getAllComplaint();
    Fire.$on("loadComplains", () => {
      this.getAllComplaint();
    });
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
