<template>
    <div class="card" v-if="mySchoolClassId != null">
        <div class="card-body">
            <h5><b>Анги бүлгийн хүмүүжлийн үнэлгээ</b></h5>
            <div class="table-responsive">
                <b-overlay rounded="sm">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr role="row">
                                <th rowspan="3">№</th>
                                <th rowspan="3">Суралцагчдын нэрс</th>
                                <th colspan="6">Зөв 6 дадлын үнэлгээ</th>
                                <th rowspan="3">Суралцагчдын дундаж үнэлгээ(%)</th>
                            </tr>
                            <tr>
                                <th>Сэдэл, сэтгэл хөдлөлөө хянах</th>
                                <th>Оюуны үйлийн явцыг хянах</th>
                                <th>Танин мэдэх</th>
                                <th>Сэдэл, сэтгэл хөдлөлөө хянах</th>
                                <th>Оюуны үйлийн явцыг хянах</th>
                                <th>Танин мэдэх</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(beStudExam, index) in behaviorStudExams" :key="beStudExam.id">
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ index + 1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.student_last_name }}.{{ beStudExam.student_name }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam2 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam3 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam4 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam5 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ beStudExam.exam6 }}
                                </td>
                                <th>
                                    {{ getStudAvg(beStudExam) }}
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2">Ангийн дундаж үнэлгээ</th>
                                <th>{{ examStudAvg[0] }}</th>
                                <th>{{ examStudAvg[1] }}</th>
                                <th>{{ examStudAvg[2] }}</th>
                                <th>{{ examStudAvg[3] }}</th>
                                <th>{{ examStudAvg[4] }}</th>
                                <th>{{ examStudAvg[5] }}</th>
                                <th>
                                    {{ getAvg(examStudAvg) }}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </b-overlay>
            </div>
        </div>
        <div class="card-body">
            <h5>
                <b>Сургалтын үр дүнд үнэлгээ шинжилгээ хийх аргачлал (суралцагчдын үнэлгээ)</b>
            </h5>
            <div class="table-responsive">
                <div class="row">
                    <div class="col-12 col-sm-5">
                        <b-overlay rounded="sm">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr role="row">
                                        <th>Суралцагчдын нэрс</th>
                                        <th>Суралцагчдын дундаж үнэлгээ</th>
                                        <th>Ангийн дундаж үнэлгээ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="studExam in behaviorStudExams" :key="studExam.id">
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            {{ studExam.student_last_name }}.{{ studExam.student_name }}
                                        </td>
                                        <th>
                                            {{ getStudAvg(studExam) }}
                                        </th>
                                        <th>
                                            {{ getAvg(examStudAvg) }}
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </b-overlay>
                    </div>
                    <div class="col-12 col-sm-7">
                        <span v-if="chart === true">
                            <div v-if="behaviorStudExams.length > 0">
                                <apexchart type="radar" height="450" :options="chartClassOptions" :series="seriesClass">
                                </apexchart>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <multiselect v-model="selectedStud" deselect-label="Хасах" select-label="Сонгох" track-by="user_id"
                label="student_name" placeholder="Суралцагч сонгох" :options="studOptions" :searchable="true"
                :allow-empty="true" @input="getStat23">
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.student_name }}
                </template>
            </multiselect>
            <div class="table-responsive" v-if="selectedStud != null">
                <b-overlay rounded="sm">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr role="row">
                                <th rowspan="3">Суралцагчийн нэр</th>
                                <th colspan="6">Зөв 6 дадлын үнэлгээ</th>
                                <th rowspan="3">Суралцагчийн дундаж үнэлгээ(%)</th>
                            </tr>
                            <tr>
                                <th>Сэдэл, сэтгэл хөдлөлөө хянах</th>
                                <th>Оюуны үйлийн явцыг хянах</th>
                                <th>Танин мэдэх</th>
                                <th>Сэдэл, сэтгэл хөдлөлөө хянах</th>
                                <th>Оюуны үйлийн явцыг хянах</th>
                                <th>Танин мэдэх</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.student_last_name }}.{{ studExams.student_name }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam2 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam3 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam4 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam5 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0" v-if="studExams">
                                    {{ studExams.exam6 }}
                                </td>
                                <th v-if="studExams">
                                    {{ getStudAvg(studExams) }}
                                </th>
                            </tr>
                            <tr>
                                <th>Ангийн дундаж үнэлгээ</th>
                                <th>{{ examStudAvg[0] }}</th>
                                <th>{{ examStudAvg[1] }}</th>
                                <th>{{ examStudAvg[2] }}</th>
                                <th>{{ examStudAvg[3] }}</th>
                                <th>{{ examStudAvg[4] }}</th>
                                <th>{{ examStudAvg[5] }}</th>
                                <th>
                                    {{ getAvg(examStudAvg) }}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </b-overlay>
                <span v-if="chart === true">
                    <div v-if="selectedStud != null">
                        <apexchart type="radar" height="350" :options="chartStudOptions" :series="seriesStud">
                        </apexchart>
                    </div>
                    <div v-if="selectedStud != null">
                        <apexchart type="bar" height="350" :options="chartExamOptions" :series="seriesExam">
                        </apexchart>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    props: ["teacherId"],
    data() {
        return {
            studOptions: [],
            behaviorStudExams: [],
            studExams: null,
            selectedLesson: null,
            selectedClass: null,
            selectedStud: null,
            mySchoolClassId: null,
            chart: false,
            seriesClass: [
                {
                    name: "Суралцагчдын ерөнхий үнэлгээ",
                    data: [],
                },
                {
                    name: "Ангийн дундаж үнэлгээ",
                    data: [],
                },
            ],
            chartClassOptions: {
                chart: {
                    height: 450,
                    type: "radar",
                },
                xaxis: {
                    categories: [],
                },
            },
            seriesStud: [
                {
                    name: "Суралцагчийн ерөнхий үнэлгээ",
                    data: [],
                },
                {
                    name: "Ангийн дундаж үнэлгээ",
                    data: [],
                },
            ],
            chartStudOptions: {
                chart: {
                    height: 350,
                    type: "radar",
                },
                xaxis: {
                    categories: ["1", "2", "3", "4", "5", "6"],
                },
            },
            seriesExam: [
                {
                    name: "Суралцагчийн үнэлгээ",
                    data: [],
                },
            ],
            chartExamOptions: {
                chart: {
                    height: 450,
                    type: "bar",
                },
                xaxis: {
                    categories: [],
                },
            },
        };
    },
    components: {
        Multiselect,
    },
    computed: {
        examStudAvg() {
            let examAvg = [];
            const exam1Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam1), 0) /
                this.behaviorStudExams.length;
            const exam2Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam2), 0) /
                this.behaviorStudExams.length;
            const exam3Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam3), 0) /
                this.behaviorStudExams.length;
            const exam4Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam4), 0) /
                this.behaviorStudExams.length;
            const exam5Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam5), 0) /
                this.behaviorStudExams.length;
            const exam6Avg =
                this.behaviorStudExams.reduce((total, item) => total + parseInt(item.exam6), 0) /
                this.behaviorStudExams.length;
            examAvg.push(
                exam1Avg.toFixed(2),
                exam2Avg.toFixed(2),
                exam3Avg.toFixed(2),
                exam4Avg.toFixed(2),
                exam5Avg.toFixed(2),
                exam6Avg.toFixed(2)
            );
            return examAvg;
        },
        selectedStudExam() {
            let exam = [];
            exam.push(
                this.studExams.exam1,
                this.studExams.exam2,
                this.studExams.exam3,
                this.studExams.exam4,
                this.studExams.exam5,
                this.studExams.exam6
            );
            return exam;
        },
    },
    methods: {
        getAvg(examStudAvg) {
            let sum =
                examStudAvg[0] * 1 +
                examStudAvg[1] * 1 +
                examStudAvg[2] * 1 +
                examStudAvg[3] * 1 +
                examStudAvg[4] * 1 +
                examStudAvg[5] * 1;
            return (sum / 6).toFixed(2);
        },
        getStudAvg(beStudExam) {
            let sum =
                beStudExam.exam1 * 1 +
                beStudExam.exam2 * 1 +
                beStudExam.exam3 * 1 +
                beStudExam.exam4 * 1 +
                beStudExam.exam5 * 1 +
                beStudExam.exam6 * 1;
            return (sum / 6).toFixed(2);
        },
        getStat23() {
            this.seriesClass[0].data = [];
            this.seriesClass[1].data = [];
            this.seriesStud[0].data = [];
            this.seriesStud[1].data = [];
            this.chartClassOptions.xaxis.categories = [];
            this.seriesExam[0].data = [];
            this.chartExamOptions.xaxis.categories = [];
            this.chart = false;
            axios
                .post("/teacher/getStat23", {
                    selectedStudId: this.selectedStud,
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.behaviorStudExams = res.data.behaviorStudExams;
                    this.studExams = res.data.behaviorChildLesson;
                    this.mySchoolClassId = res.data.mySchoolClassId;
                    this.studOptions = res.data.studentIds;
                    res.data.behaviorStudExams.forEach((el) => {
                        this.chartClassOptions.xaxis.categories.push(el.student_name);
                        this.seriesClass[0].data.push(this.getStudAvg(el));
                        this.seriesClass[1].data.push(this.getAvg(this.examStudAvg));
                    });
                    this.seriesStud[0].data = this.selectedStudExam;
                    this.seriesStud[1].data = this.examStudAvg;
                    this.seriesExam[0].data = res.data.studExamAvg;
                    this.chartExamOptions.xaxis.categories = res.data.subjName;
                    this.chart = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getStat23();
    },
};
</script>

<style scoped>
.card-body {
    min-height: 250px;
}
</style>
