<template>
  <div class="row pt-3">
    <div class="col-md-3">
      <!-- Profile Image -->
      <div class="card card-primary card-outline">
        <div class="card-body box-profile">
          <div class="text-center">
            <img
              class="profile-user-img img-fluid attachment-img mb-2"
              :src="getSelectedProfileImg"
              alt="Багшийн зураг"
              accept="image/*"
            />
            <b-form-file
              id=""
              browse-text="Зураг солих"
              size="sm"
              @change="getProfileImg"
            ></b-form-file>
            <div class="row mt-2">
              <div class="col-sm-6">
                <a
                  href="#"
                  class="btn btn-block btn-primary btn-xs"
                  @click="uploadProfile"
                  v-if="loaded"
                  >Хадгалах</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  href="#"
                  class="btn btn-block btn-secondary btn-xs"
                  @click.prevent="uploadProfileCancel"
                  v-if="loaded"
                  >Цуцлах</a
                >
              </div>
            </div>
          </div>

          <h3 class="profile-username text-center">
            {{ getMyInfoTeacher.teacher_last_name | capitalizeAndEkhniiVseg }}.
            {{ getMyInfoTeacher.teacher_name }}
          </h3>

          <p class="text-muted text-center">
            {{ getMyInfoTeacher.position_name }}
          </p>
          <!-- <p>Нууц үг өөрчлөх</p> -->
          <ul class="list-group list-group-unbordered mb-3">
            <!-- <li class="list-group-item">
              <b>Анги</b> <a class="float-right">1,322</a>
            </li>
            <li class="list-group-item">
              <b>Суралцагчдын тоо</b> <a class="float-right">543</a>
            </li> -->
            <!-- <li class="list-group-item">
              <b>Friends</b> <a class="float-right">13,287</a>
            </li> -->
          </ul>

          <!-- <a href="#" class="btn btn-primary btn-block"><b>Follow</b></a> -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
    <div class="col-md-9">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Үндсэн мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#edit" data-toggle="tab">Мэдээлэл засах</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#password" data-toggle="tab">Нууц үг солих</a>
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="active tab-pane" id="activity">
              <!-- Post -->
              <div class="post">
                <div class="user-block">
                  <img
                    class="img-circle img-bordered-sm"
                    :src="getMyInfoTeacher.profile_photo_url"
                    alt="user image"
                  />
                  <span class="username">
                    <a href="#"
                      >{{ getMyInfoTeacher.teacher_last_name }}
                      {{ getMyInfoTeacher.teacher_name }}</a
                    >
                  </span>
                  <span class="description"
                    >Системд бүртгэсэн -
                    {{ getMyInfoTeacher.user_created_at | dateYearMonthDay }}</span
                  >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="col-sm-3"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-right text-bold">Регистр:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.teacher_reg }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Ургийн овог:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.teacher_urgiin_ovog }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Эцэг/Эх-ийн нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.teacher_last_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.teacher_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Хүйс:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.gender }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Төрсөн өдөр:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.teacher_birthDate }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Иргэншил:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.citizenship }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Гэрлэсэн/Гэрлээгүй:</td>
                        <td class="text-left">
                          {{ getMyInfoTeacher.married }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.user-block -->
              </div>
              <!-- /.post -->
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="edit">
              <form>
                <div class="form-group row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Регистр</label>
                      <input
                        type="teacher_reg"
                        class="form-control"
                        name="teacher_reg"
                        v-model="form.teacher_reg"
                        placeholder="Регистр"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_reg'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Ургийн овог</label>
                      <input
                        type="teacher_urgiin_ovog"
                        class="form-control"
                        name="teacher_urgiin_ovog"
                        v-model="form.teacher_urgiin_ovog"
                        placeholder="Ургийн овог"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_urgiin_ovog'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Овог</label>
                      <input
                        type="teacher_last_name"
                        class="form-control"
                        name="teacher_last_name"
                        v-model="form.teacher_last_name"
                        placeholder="Овог"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_last_name'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Нэр</label>
                      <input
                        type="teacher_name"
                        class="form-control"
                        name="teacher_name"
                        v-model="form.teacher_name"
                        placeholder="Нэр"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_name'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Хүйс</label>
                      <b-form-select
                        v-model="form.gender"
                        :options="genders"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('gender'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Төрсөн он сар өдөр</label>
                      <input
                        type="date"
                        class="form-control"
                        name="teacher_birthDate"
                        v-model="form.teacher_birthDate"
                        placeholder="Төрсөн он сар өдөр"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_birthDate'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Иргэншил</label>
                      <input
                        type="citizenship"
                        class="form-control"
                        name="citizenship"
                        v-model="form.citizenship"
                        placeholder="Иргэншил"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('citizenship'),
                          },
                        ]"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-checkbox
                        id="checkbox-sport-master"
                        v-model="form.teacher_sport_mas"
                        name="checkbox-sport-master"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Спортын мастер эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.teacher_sport_mas"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-checkbox
                        id="married"
                        v-model="form.married"
                        name="married"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Гэрлэсэн эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.married"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-checkbox
                        id="tetgever_togtooson"
                        v-model="form.tetgever_togtooson"
                        name="tetgever_togtooson"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Тэтгэвэр тогтоосон эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.tetgever_togtooson"></has-error>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row" v-if="form.tetgever_togtooson == 'Тийм'">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Тэтгэвэр тогтоосон огноо</label>
                          <input
                            type="date"
                            name="tetgever_togtooson_ognoo"
                            v-model="form.tetgever_togtooson_ognoo"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('tetgever_togtooson_ognoo'),
                              },
                            ]"
                            placeholder="Тэтгэвэр тогтоосон огноо"
                          />
                          <has-error
                            :form="form"
                            field="tetgever_togtooson_ognoo"
                          ></has-error>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Тэтгэвэр тогтоосон нас</label>
                          <input
                            type="number"
                            name="tetgever_togtooson_age"
                            v-model="form.tetgever_togtooson_age"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('tetgever_togtooson_age'),
                              },
                            ]"
                            placeholder="Тэтгэвэр тогтоосон нас"
                          />
                          <has-error
                            :form="form"
                            field="tetgever_togtooson_age"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Ажлын төрөл</label>
                      <b-form-select
                        v-model="form.work_type"
                        :options="workTypes"
                      ></b-form-select>
                      <has-error :form="form" field="work_type"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Ажилд орсон огноо</label>
                      <input
                        type="date"
                        name="work_start_date"
                        v-model="form.work_start_date"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('work_start_date'),
                          },
                        ]"
                        placeholder="Ажилд орсон огноо"
                      />
                      <has-error :form="form" field="work_start_date"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Ажлаас гарсан огноо</label>
                      <input
                        type="date"
                        name="work_finish_date"
                        v-model="form.work_finish_date"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('work_finish_date'),
                          },
                        ]"
                        placeholder="Ажлаас гарсан огноо"
                      />
                      <has-error :form="form" field="work_finish_date"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-checkbox
                        id="vild_evlel_member"
                        v-model="form.vild_evlel_member"
                        name="vild_evlel_member"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Үйлдвэрчний эвлэлийн гишүүн эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.vild_evlel_member"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="form.vild_evlel_member == 'Тийм'">
                    <div class="form-group">
                      <label>Гишүүнээр элссэн огноо</label>
                      <input
                        type="date"
                        name="vild_evlel_member_ognoo"
                        v-model="form.vild_evlel_member_ognoo"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('vild_evlel_member_ognoo'),
                          },
                        ]"
                        placeholder="Тэтгэвэр тогтоосон огноо"
                      />
                      <has-error :form="form" field="vild_evlel_member_ognoo"></has-error>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Дүүрэг</label>
                      <b-form-select
                        v-model="form.district"
                        :options="districtOptions"
                      ></b-form-select>
                      <has-error :form="form" field="form.district"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Утас</label>
                      <input
                        type="number"
                        name="teacher_phoneNumber1"
                        v-model="form.teacher_phoneNumber1"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_phoneNumber1'),
                          },
                        ]"
                        placeholder="Утас"
                      />
                      <has-error :form="form" field="teacher_phoneNumber1"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Хувийн цахим шуудан</label>
                      <input
                        type="email"
                        name="teacher_personal_email"
                        v-model="form.teacher_personal_email"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_personal_email'),
                          },
                        ]"
                        placeholder="Хувийн цахим шуудан"
                      />
                      <has-error :form="form" field="teacher_personal_email"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Дээд боловсролын зэрэг</label>
                      <b-form-select
                        v-model="form.deed_bol"
                        :options="degrees"
                      ></b-form-select>
                      <has-error :form="form" field="form.deed_bol"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Оршин суугаа хаяг</label>
                      <b-form-textarea
                        id="teacher_live_address"
                        v-model="form.teacher_live_address"
                        placeholder="Оршин суугаа хаяг"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error :form="form" field="teacher_live_address"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Төгссөн сургууль</label>
                      <b-form-textarea
                        id="teacher_tugssun_sur"
                        v-model="form.teacher_tugssun_sur"
                        placeholder="Төгссөн сургууль"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error :form="form" field="teacher_tugssun_sur"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Мэрэгшүүлэх сургалт</label>
                      <b-form-textarea
                        id="mergeshvvlekh_surgalt"
                        v-model="form.mergeshvvlekh_surgalt"
                        placeholder="Мэрэгшүүлэх сургалт"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error :form="form" field="mergeshvvlekh_surgalt"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Мэрэгжил</label>
                      <b-form-textarea
                        id="teacher_mergejil"
                        v-model="form.teacher_mergejil"
                        placeholder="Мэрэгжил"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error :form="form" field="teacher_mergejil"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Эрдмийн бүтээлийн бүртгэл</label>
                      <b-form-textarea
                        id="teacher_erd_bvt_bvrt"
                        v-model="form.teacher_erd_bvt_bvrt"
                        placeholder="Эрдмийн бүтээлийн бүртгэл"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error :form="form" field="teacher_erd_bvt_bvrt"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Ажилласан илтгэл, төсөл хөтөлбөр</label>
                      <b-form-textarea
                        id="teacher_erd_ajils_ilt_proj"
                        v-model="form.teacher_erd_ajils_ilt_proj"
                        placeholder="Ажилласан илтгэл, төсөл хөтөлбөр"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error
                        :form="form"
                        field="teacher_erd_ajils_ilt_proj"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Гадагшаа зорчсон албан ажил</label>
                      <b-form-textarea
                        id="teacher_alban_ajil_gad_zor"
                        v-model="form.teacher_alban_ajil_gad_zor"
                        placeholder="Гадагшаа зорчсон албан ажил"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <has-error
                        :form="form"
                        field="teacher_alban_ajil_gad_zor"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Англи хэлний мэдлэг</label>
                      <b-form-select
                        v-model="form.teacher_english"
                        :options="engOptions"
                      ></b-form-select>
                      <has-error :form="form" field="form.teacher_english"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <b-form-checkbox
                        id="teacher_chinese"
                        v-model="form.teacher_chinese"
                        name="teacher_chinese"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Хятад хэлтэй эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.teacher_chinese"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <b-form-checkbox
                        id="teacher_japanese"
                        v-model="form.teacher_japanese"
                        name="teacher_japanese"
                        value="Тийм"
                        unchecked-value="Үгүй"
                      >
                        Япон хэлтэй эсэх
                      </b-form-checkbox>
                      <has-error :form="form" field="form.teacher_japanese"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Боловсролын байгууллагад ажилласан жил</label>
                      <b-form-select
                        v-model="form.teacher_bol_bai_ajiljil"
                        :options="yearOptions"
                      ></b-form-select>
                      <has-error
                        :form="form"
                        field="form.teacher_bol_bai_ajiljil"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Тухайн мэргэжлээр ажилласан жил</label>
                      <b-form-select
                        v-model="form.teacher_tukhain_mer_ajiljil"
                        :options="yearOptions"
                      ></b-form-select>
                      <has-error
                        :form="form"
                        field="form.teacher_tukhain_mer_ajiljil"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Тухайн байгууллагад ажлласан жил</label>
                      <b-form-select
                        v-model="form.teacher_tukhain_bai_ajiljil"
                        :options="yearOptions"
                      ></b-form-select>
                      <has-error
                        :form="form"
                        field="form.teacher_tukhain_bai_ajiljil"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Төрийн байгууллагад ажилласан жил</label>
                      <b-form-select
                        v-model="form.turiin_alba_ajiljil"
                        :options="yearOptions"
                      ></b-form-select>
                      <has-error
                        :form="form"
                        field="form.turiin_alba_ajiljil"
                      ></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Амьдралын нөхцөл байдал</label>
                      <input
                        type="text"
                        name="teacher_live_situation"
                        v-model="form.teacher_live_situation"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_live_situation'),
                          },
                        ]"
                        placeholder="Амьдралын нөхцөл байдал"
                      />
                      <has-error :form="form" field="teacher_live_situation"></has-error>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Зээлийн нөхцөл байдал</label>
                      <input
                        type="text"
                        name="teacher_zeel_situation"
                        v-model="form.teacher_zeel_situation"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('teacher_zeel_situation'),
                          },
                        ]"
                        placeholder="Зээлийн нөхцөл байдал"
                      />
                      <has-error :form="form" field="teacher_zeel_situation"></has-error>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="updateTeacher()"
                    >
                      Хадгалах
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="password">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <!-- /.card-header -->
                    <div class="card-body">
                      <form>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг:"
                          label-for="teacher_pass"
                        >
                          <b-form-input
                            type="password"
                            v-model="teacher_pass"
                            placeholder="Нууц үг"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('teacher_pass'), }, ]"
                          ></b-form-input>
                          <has-error :form="form" field="teacher_pass"></has-error>
                        </b-form-group>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг давтах:"
                          label-for="teacher_pass_verify"
                        >
                          <b-form-input
                            type="password"
                            v-model="teacher_pass_verify"
                            placeholder="Нууц үг давтах"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('teacher_pass_verify'), }, ]"
                          ></b-form-input>
                          <has-error :form="form" field="teacher_pass_verify"></has-error>
                        </b-form-group>
                      </form>
                      <h6
                        class="text-success"
                        v-if="errorPassword == 'Таны нууц үгийг амжилттай шинэчиллээ!'"
                      >
                        {{ errorPassword }}
                      </h6>
                      <h6 class="text-danger" v-else>{{ errorPassword }}</h6>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
              <div class="row pl-2">
                <b-button @click="resetPassword()" variant="primary" type="submit">
                  Нууц үг солих
                </b-button>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.nav-tabs-custom -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      getSelectedProfileImg: "",
      getMyInfoTeacher: [],
      errorPassword: "",
      teacher_pass: "",
      teacher_pass_verify: "",
      workTypes: [
        { value: "", text: "Сонгох" },
        { value: "Бүтэн", text: "Бүтэн" },
        { value: "Цагийн", text: "Цагийн" },
        { value: "Гэрээт", text: "Гэрээт" },
        { value: "Бусад", text: "Бусад" },
      ],
      options: [
        { value: "", text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: "Багш", text: "Багш" },
        { value: "Ахлах багш", text: "Ахлах багш" },
        { value: "Арга зүйч багш", text: "Арга зүйч багш" },
        { value: "Тэргүүлэх багш", text: "Тэргүүлэх багш" },
        { value: "Зөвлөх багш", text: "Зөвлөх багш" },
      ],
      degrees: [
        { value: "", text: "Боловсролын зэрэг сонгоно уу?" },
        { value: "Дипломын дээд", text: "Дипломын дээд" },
        { value: "Бакалавр", text: "Бакалавр" },
        { value: "Магистр", text: "Магистр" },
        { value: "Доктор", text: "Доктор" },
      ],
      yearOptions: [
        { value: "0-3 жил", text: "0-3 жил" },
        { value: "4-6 жил", text: "4-6 жил" },
        { value: "7-9 жил", text: "7-9 жил" },
        { value: "10-12 жил", text: "10-12 жил" },
        { value: "13-15 жил", text: "13-15 жил" },
        { value: "16-18 жил", text: "16-18 жил" },
        { value: "19-21 жил", text: "19-21 жил" },
        { value: "22-25 жил", text: "22-25 жил" },
        { value: "25-аас дээш жил", text: "25-аас дээш жил" },
      ],
      engOptions: [
        { value: "Анхан шатны (Elementary)", text: "Анхан шатны (Elementary)" },
        {
          value: "Дунджаас доогуур (Pre-intermediate)",
          text: "Дунджаас доогуур (Pre-intermediate)",
        },
        {
          value: "Дундаж түвшний (Intermediate)",
          text: "Дундаж түвшний (Intermediate)",
        },
        {
          value: "Ахисан түвшний (Upper-intermediate)",
          text: "Ахисан түвшний (Upper-intermediate)",
        },
        {
          value: "Гүнзгийрсэн түвшний (Advanced)",
          text: "Гүнзгийрсэн түвшний (Advanced)",
        },
        { value: "TOFEL, IELTS-ийн оноотой", text: "TOFEL, IELTS-ийн оноотой" },
      ],
      districtOptions: [
        { value: "СБД", text: "Сүхбаатар" },
        { value: "ЧД", text: "Чингэлтэй" },
        { value: "БЗД", text: "Баянзүрх" },
        { value: "БГД", text: "Баянгол" },
        { value: "ХУД", text: "Хан-Уул" },
        { value: "СХД", text: "Сонгинохайрхан" },
        { value: "БХД", text: "Багахангай" },
        { value: "НД", text: "Налайх" },
        { value: "БД", text: "Багануур" },
      ],
      form: new Form({
        teacher_id: "",
        teacher_reg: "",
        teacher_name: "",
        gender: "",
        teacher_birthDate: "",
        citizenship: "",
        name: "",
        email: "",
        teacher_urgiin_ovog: "",
        teacher_last_name: "",
        bnb: "",
        user_id: "",
        married: "",
        tetgever_togtooson: "",
        tetgever_togtooson_ognoo: "",
        tetgever_togtooson_age: "",
        work_type: "",
        work_start_date: "",
        work_finish_date: "",
        tvbd_zer: "",
        vild_evlel_member: "",
        vild_evlel_member_ognoo: "",
        teacher_live_address: "",
        teacher_phoneNumber1: "",
        teacher_personal_email: "",
        deed_bol: "",
        mer_bol: "",
        buren_dund_bol: "",
        mergeshvvlekh_surgalt: "",
        teacher_mergejil: "",
        teacher_erd_zer: "",
        teacher_erd_bvt_bvrt: "",
        teacher_sport_mas: "",
        teacher_erd_ajils_ilt_proj: "",
        teacher_alban_ajil_gad_zor: "",
        teacher_bol_zer: "",
        teacher_bagshlakh_erkh: "",
        teacher_english: "",
        teacher_chinese: "",
        teacher_japanese: "",
        teacher_bol_bai_ajiljil: "",
        teacher_tukhain_mer_ajiljil: "",
        teacher_tukhain_bai_ajiljil: "",
        turiin_alba_ajiljil: "",
        teacher_live_situation: "",
        teacher_zeel_situation: "",
        teacher_tugssun_sur: "",
        cambridge_teacher: "",
        teacher_cambridge_exam_officer: "",
        district: "СБД",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эр", text: "Эрэгтэй" },
        { value: "Эм", text: "Эмэгтэй" },
      ],
    };
  },
  methods: {
    getMyInfo() {
      //   console.log("Ажиллаж байна. getMyInfo");
      axios
        .get("/all_worker_functions/getMyInfo")
        .then((res) => {
          this.getMyInfoTeacher = res.data.getMyInfoTeacher;
          if (res.data.getMyInfoTeacher.profile_photo_url != null)
            this.getSelectedProfileImg = res.data.getMyInfoTeacher.profile_photo_url;
          else {
            this.getSelectedProfileImg = "/images/users/user.png";
            this.getMyInfoTeacher.profile_photo_url = "/images/users/user.png";
          }
          this.form.reset();
          this.form.fill(this.getMyInfoTeacher);
        })
        .catch();
    },
    uploadProfile() {
      //   console.log("Ажиллаж байна. uploadProfile");
      //   console.log(this.selectedSchool.school_logo_url);
      //   console.log(this.selectedSchool.id);
      axios
        .post("/all_worker_functions/uploadTeacherProfileImg", {
          getSelectedProfileImg: this.getSelectedProfileImg,
          //   schoolLogoUrlHor: this.getSelectedLogoUrlHor,
          //   schoolId: this.selectedSchool.id,
        })
        .then((res) => {
          Fire.$emit("loadStart");
          //   console.log("Амжилттай");
          this.loaded = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadProfileCancel() {
      //   console.log("Ажиллаж байна. uploadProfileCancel");
      this.loaded = false;
      this.getSelectedProfileImg = this.getMyInfoTeacher.profile_photo_url;
    },
    getProfileImg(e) {
      //   console.log("Ажиллаж байна. getProfileImg");
      //   console.log(e.target.files[0].name);
      let teacherProfileImg = e.target.files[0];
      this.readImageHor(teacherProfileImg);
    },
    readImageHor(teacherProfileImg) {
      let reader = new FileReader();
      reader.readAsDataURL(teacherProfileImg);
      reader.onload = (e) => {
        this.getSelectedProfileImg = e.target.result;
      };
      this.loaded = true;
    },

    editGenInfo() {
      //   console.log("Засах мэдээллээ оруулна уу?");
    },
    updateTeacher() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.form
        .put("/all_worker_functions/updateTeacher/")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Багшийн мэдээллийг амжилттай заслаа!", "Шинэчилсэн");
          Fire.$emit("getMyInfo");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    resetPassword() {
      if (this.teacher_pass == this.teacher_pass_verify) {
        axios
          .post("/all_worker_functions/changePassword", {
            password: this.teacher_pass,
          })
          .then((res) => {
            Swal.fire(
              "Нууц өөрчлөгдлөө!",
              "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
              "success"
            );
            this.teacher_pass = "";
            this.teacher_pass_verify = "";
            this.errorPassword = "Таны нууц үгийг амжилттай шинэчиллээ!";
            this.loaded = false;
          })
          .catch((err) => {
            this.errorPassword = "Нууц үг нь хамгийн багадаа 6 оронтой байх ёстой!";
          });
      } else {
        this.errorPassword = "Нууц үгийг зөв давтан оруулна уу!";
      }
    },
  },
  created() {
    this.getMyInfo();
    Fire.$on("loadStart", () => {
      this.getMyInfo();
    });
  },
};
</script>

<style></style>
