<template>
  <div class="row pt-3">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-header">
          <div
            class="main-header navbar navbar-expand navbar-white navbar-light"
          >
            <span></span>
            <h3 class="card-title mr-2">Сургуулийн менежер</h3>
            <search-manager
              @getWasSearchManagers="schoolmanagers = $event"
            ></search-manager>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>
                  <font size="1.2"> Ургийн овог </font>
                </th>
                <th>
                  <font size="1.2"> Овог нэр </font>
                </th>
                <th>
                  <font size="1.2"> Профайл </font>
                </th>
                <th>
                  <font size="1.2"> Имэйл хаяг </font>
                </th>
                <th>
                  <font size="1.2"> БНБ </font>
                </th>
                <th>
                  <font size="1.2"> Үүсгэсэн </font>
                </th>
                <th>
                  <font size="1.2"> Зассан </font>
                </th>
                <th>
                  <font size="1.2">Үйлдлүүд</font>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(schoolmanager, index) in schoolmanagers"
                :key="schoolmanager.id"
                v-if="schoolmanager.employee_user"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    {{ schoolmanager.employee_user.teacher_urgiin_ovog }}
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    {{
                      schoolmanager.employee_user.teacher_last_name
                        ? schoolmanager.employee_user.teacher_last_name[0]
                        : ""
                    }}.
                    {{ schoolmanager.employee_user.teacher_name }}
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <img
                    class="profile-user-img img-fluid attachment-img"
                    :src="schoolmanager.employee_user.profile_photo_url"
                    alt="Багшийн зураг"
                    accept="image/*"
                    v-if="schoolmanager.employee_user.profile_photo_url != null"
                  />
                  <img
                    class="profile-user-img img-fluid attachment-img"
                    :src="defaultimage"
                    alt="Багшийн зураг"
                    accept="image/*"
                    v-else-if="
                      schoolmanager.employee_user.profile_photo_url === null
                    "
                  />
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    {{ schoolmanager.email }}
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    <span
                      class="badge badge-success"
                      v-for="(dep, index) in schoolmanager.departments"
                      >{{ dep.department_code }}</span
                    >
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    {{
                      schoolmanager.employee_user.created_at | dateYearMonthDay
                    }}
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <font size="1">
                    {{
                      schoolmanager.employee_user.updated_at | dateYearMonthDay
                    }}
                  </font>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolManager(schoolmanager)"
                  >
                    <i class="fas fa-key"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editschoolManager(schoolmanager)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteSchoolManager(schoolmanager)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Менежер бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Менежер засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode ? createSchoolManager() : updateSchoolManager()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label>Ургийн овог</label>
                <input
                  type="text"
                  name="emp_teacher_urgiin_ovog"
                  v-model="form.emp_teacher_urgiin_ovog"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('emp_teacher_urgiin_ovog'),
                    },
                  ]"
                  placeholder="Ургийн овог"
                />
                <has-error
                  :form="form"
                  field="emp_teacher_urgiin_ovog"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Овог</label>
                <input
                  type="text"
                  name="emp_teacher_last_name"
                  v-model="form.emp_teacher_last_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('emp_teacher_last_name'),
                    },
                  ]"
                  placeholder="Овог"
                />
                <has-error
                  :form="form"
                  field="emp_teacher_last_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Нэр</label>
                <input
                  type="text"
                  name="user_name"
                  v-model="form.user_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('user_name'),
                    },
                  ]"
                  placeholder="Нэр"
                />
                <has-error :form="form" field="user_name"></has-error>
              </div>
              <div class="form-group">
                <label>Имэйл</label>
                <input
                  type="email"
                  name="user_email"
                  v-model="form.user_email"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('user_email'),
                    },
                  ]"
                  placeholder="Цахим шуудан"
                />
                <has-error :form="form" field="user_email"></has-error>
              </div>
              <div class="form-group">
                <label>Харьяалагдах ажлын байр</label>
                <b-form-select
                  v-model="form.position_name"
                  :options="positions"
                  text-field="position_name"
                  value-field="id"
                ></b-form-select>
                <has-error :form="form" field="position"></has-error>
              </div>
              <b-form-group aria-label="Assign departments">
                <b-form-checkbox
                  v-for="option in allDepartments"
                  v-model="form.departments"
                  :key="option.id"
                  :value="option.id"
                  name="flavour-3a"
                >
                  {{ option.department_name }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      //   keyword: "",
      defaultimage: "http://localhost:8000/images/users/user.png",
      schoolmanagers: [],
      schools: [],
      positions: [],
      allDepartments: [],
      form: new Form({
        id: "",
        user_name: "",
        user_email: "",
        emp_teacher_urgiin_ovog: "",
        emp_teacher_last_name: "",
        position_name: "",
        user_id: "",
        year_id: "",
        departments: [],
      }),
    };
  },
  components: {
    searchManager: searchManager,
  },
  methods: {
    getAllManagers() {
      axios
        .get("/owner/getAllManagersSchool")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.schoolmanagers = response.data.schoolmanagers;
          // console.log(this.schoolteachers);
          this.positions = response.data.positions;
          this.allDepartments = response.data.departments;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    passChangeSchoolManager(schoolmanager) {
      console.log(schoolmanager);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe%2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/owner/passChangeSchoolManager/" + schoolmanager.id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    viewSchoolManager(schoolmanager) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolmanager);
    },
    editschoolManager(schoolmanager) {
      // console.log("Дэлгэрэнгүй мэдээлэл засах", schoolmanager);
      this.editMode = true;
      this.form.reset();
      //   this.form.fill(schoolmanager);
      //   this.form.school_name = schoolmanager.emp_school_id;

      this.form.user_name = schoolmanager.employee_user.teacher_name;
      this.form.user_email = schoolmanager.email;
      this.form.emp_teacher_urgiin_ovog =
        schoolmanager.employee_user.teacher_urgiin_ovog;
      this.form.emp_teacher_last_name =
        schoolmanager.employee_user.teacher_last_name;
      if (schoolmanager.employee_user) {
        if (schoolmanager.employee_user.employee_migration_info) {
          this.form.position_name =
            schoolmanager.employee_user.employee_migration_info.position_id;
        } else {
          this.$toastr.e(
            "Алдаа",
            "Хэрэглэгчийн EmployeeMigration талбар бүртгэгдээгүй байна."
          );
        }
      } else {
        this.$toastr.e(
          "Алдаа",
          "Хэрэглэгчийн Employee талбар бүртгэгдээгүй байна."
        );
      }

      this.form.user_id = schoolmanager.id;
      //   console.log(schoolmanager.year_id);
      this.form.year_id = schoolmanager.year_id;
      schoolmanager.departments.forEach((element) => {
        this.form.departments.push(element.id);
      });
    },
    updateSchoolManager() {
      //   console.log(this.form);
      this.form
        .put("/owner/updateManager/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolManagers");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolManager(schoolteacher) {
      console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    createSchoolManager() {
      this.action = "Багш үүсгэж байна...";
      this.form
        .post("/owner/createManager")
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolManagers");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Багшийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllManagers();
    Fire.$on("loadSchoolManagers", () => {
      this.getAllManagers();
    });
  },
};
</script>

<style></style>
