<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Сурагчдын хоолны ирц</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <form @submit.prevent="getClassStudents()">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <b-form-datepicker
                                    id="day-picker"
                                    name="pickDate"
                                    v-model="form.pickDate"
                                    v-bind="labels[locale] || {}"
                                    :locale="locale"
                                    :start-weekday="weekday"
                                    :show-decade-nav="showDecadeNav"
                                    :hide-header="hideHeader"
                                    size="sm"
                                    :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has('pickDate'),
                                    },
                                    ]"
                                    ></b-form-datepicker>
                                    <has-error :form="form" field="pickDate"></has-error>
                            </div>
                            <div class="col-md-2">
                                <b-form-select
                                    name="schoolClass"
                                    v-model="form.schoolClass"
                                    :options="schoolclasses"
                                    text-field="full_name"
                                    value-field="id"
                                    size="sm"
                                    :class="[
                                    'form-control',
                                        {
                                            'is-invalid': form.errors.has('schoolClass'),
                                        },
                                    ]"
                                >
                                </b-form-select>
                            </div>
                            <div class="col-md-2">
                                <button type="submit" class="btn bg-gradient-primary btn-sm">Хайх</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Ангийн багш</th>
                <th>Сурагч</th>
                <th>Хоол идэх эсэх</th>
                <th>Огноо</th>
              </tr>
            </thead>
            <tbody v-for="(student, index) in students"
                :key="student.id">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ student.teacher_last_name[0] }}.
                  {{ student.teacher_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ student.student_last_name[0] }}.
                  {{ student.student_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="student.is_lunch === 1" class="badge bg-secondary">ТИЙМ</span>
                  <span v-if="student.is_lunch === 0" class="badge bg-warning">ҮГҮЙ</span>
                </td>
                <td>
                    {{ student.dateFormated }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      isCheck: true,
      students: [],
      schoolclasses: [],
      form: new Form({
        pickDate: "",
        schoolClass: ""
      }),
      formTemp: new Form({
        id:"",
        input_temp: "",
        input_description: ""
      }),
      newTemAtts: [],
      exists: null,
      locale: 'mn',
      showDecadeNav: true,
      hideHeader: false,
      weekday: 1,
      labels: {
          mn: {
            labelPrevDecade: 'Өмнөх арван жил',
            labelPrevYear: 'Өмнөх жил',
            labelPrevMonth: 'Өнгөрсөн сар',
            labelCurrentMonth: 'Одоогийн сар',
            labelNextMonth: 'Дараа сар',
            labelNextYear: 'Ирэх жил',
            labelNextDecade: 'Дараагийн арван жил',
            labelToday: 'Өнөөдөр',
            labelSelected: 'Сонгосон огноо',
            labelNoDateSelected: 'Огноо сонгогдоогүй байна',
            labelCalendar: 'Хуанли',
            labelNav: 'Календарийн навигаци',
            labelHelp: 'Хуанлийн огноог удирдахын тулд курсорын товчлуурыг ашиглана уу.'
          }
        }
    };
  },

  methods: {
    getClassStudents() {
        this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
        this.form
            .put("/doctor/getLunchStudentData/" + this.form.pickDate)
            .then((response) => {
                this.students = response.data.students;
            })
            .catch((errors) => {
            //   console.log(errors);
            });
    },
    getClasses() {
        axios
            .get("/doctor/getClasses/")
            .then((response) => {
                this.schoolclasses = response.data.schoolclasses;
            })
            .catch((errors) => {
            //   console.log(errors);
            });
    },
  },
  created() {
    this.schoolclasses = this.getClasses();
  },
};
</script>

<style></style>
