<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <!-- <h3 class="card-title">
            <span class="badge bg-success">A: </span>
            <span class="badge bg-success">B:</span>
            <span class="badge bg-info">C:</span>
            <span class="badge bg-info">D: </span>
            <span class="badge bg-danger">F: </span>
          </h3> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 5px">№</th>
                <th>Овог нэр</th>
                <th
                  v-for="(allSchoolClassCourse, index) in allSchoolClassCourses"
                  :key="index"
                  class="col-span-3"
                >
                  {{ allSchoolClassCourse.subject_short_name }}
                </th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(allStudent, indexStudentRow) in allStudents"
                :key="indexStudentRow"
              >
                <td>{{ indexStudentRow + 1 }}</td>
                <td>
                  {{ allStudent.profile_student_last_name[0]
                  }}{{ allStudent.profile_student_last_name[1] }}.{{
                    allStudent.profile_student_name
                  }}
                </td>
                <td
                  v-for="(
                    allSchoolClassCourse, indexCourseColumn
                  ) in allSchoolClassCourses"
                  :key="indexCourseColumn"
                >
                  <span
                    v-for="(allStudentsGrade, indexGrade) in allStudentsGrades"
                    :key="indexGrade"
                  >
                    <span
                      class="badge bg-info"
                      v-if="
                        allSchoolClassCourse.course_id ===
                          allStudentsGrade.grade_course_id &&
                        allSchoolClassCourse.course_subject_id ===
                          allStudentsGrade.grade_subject_id &&
                        allStudent.grade_student_id ===
                          allStudentsGrade.grade_student_id
                      "
                      >{{ allStudentsGrade["grade_exam"] }}</span
                    >
                  </span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-warning btn-sm"
                    v-b-popover.hover="'Дүнгийн тодорхойлолт! Тун удахгүй.'"
                    @click.prevent="dungiinTod(allStudent)"
                  >
                    <b-icon icon="file-earmark" variant="success"></b-icon>
                  </button>
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    v-b-popover.hover="
                      'Суралцдаг нь үнэн тодорхойлолт! Тун удахгүй.'
                    "
                    @click.prevent="surdagUnen(allStudent)"
                  >
                    <b-icon icon="file-earmark-ruled" variant="danger"></b-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
// var pdfMake = require("pdfmake/build/pdfmake.js");
// var pdfFonts = require("pdfmake/build/vfs_fonts.js");
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  props: ["allSchoolClassCourses", "allStudentsGrades", "allStudents"],
  data() {
    return {
      schoolLogo: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.employee_user;
      //   .filter((todo) => todo.done).length;
    },
  },
  methods: {
    dungiinTod(allStudent) {
      //   console.log(allStudent);
      axios
        .get("/manager/schoolLogo")
        .then((response) => {
          this.schoolLogo = response.data.schoolLogo;
          var dd = {
            content: [
              {
                image: "building",
                width: 100,
              },
              {
                text: "This is a header, using header style",
                style: "header",
              },
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam.\n\n",
              {
                text: "Subheader 1 - using subheader style",
                style: "subheader",
              },
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efficiat sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi diogenem dixerit logikh levius probabo adipiscuntur afficitur, factis magistra inprobitatem aliquo andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca sedatio aliena video.",
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efficiat sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi diogenem dixerit logikh levius probabo adipiscuntur afficitur, factis magistra inprobitatem aliquo andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca sedatio aliena video.",
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efficiat sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi diogenem dixerit logikh levius probabo adipiscuntur afficitur, factis magistra inprobitatem aliquo andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca sedatio aliena video.\n\n",
              {
                text: "Subheader 2 - using subheader style",
                style: "subheader",
              },
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efficiat sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi diogenem dixerit logikh levius probabo adipiscuntur afficitur, factis magistra inprobitatem aliquo andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca sedatio aliena video.",
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efficiat sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi diogenem dixerit logikh levius probabo adipiscuntur afficitur, factis magistra inprobitatem aliquo andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca sedatio aliena video.\n\n",
              {
                text: "It is possible to apply multiple styles, by passing an array. This paragraph uses two styles: quote and small. When multiple styles are provided, they are evaluated in the specified order which is important in case they define the same properties",
                style: ["quote", "small"],
              },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
              },
              subheader: {
                fontSize: 15,
                bold: true,
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
            },
            images: {
              building: "",
            },
          };
          dd.images.building = this.schoolLogo[0];

          pdfMake.createPdf(dd).open();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    surdagUnen(allStudent) {
      //   console.log(allStudent);
      axios
        .get("/manager/schoolLogo")
        .then((response) => {
          //   console.log(response.data.schoolLogo);
          this.schoolLogo = response.data.schoolLogo;
          var dd = {
            pageSize: "A5",
            content: [
              {
                image: "building",
                width: 80,
                style: ["header", "anotherStyle"],
              },
              {
                text: "\n\n\n\n",
              },
              {
                text: "Суралцагчийн тодорхойлолт\n\n\n\n",
                style: ["anotherStyle"],
              },
              {
                text: [
                  {
                    text: ".............",
                    fontSize: 12,
                  }, //Овог 0
                  " овогтой ", // 1
                  { text: "a better ", fontSize: 12, style: ["anotherStyle"] }, //нэр 2
                  " нь                   \n ", //3
                  { text: "styled ", fontSize: 12, style: ["anotherStyle"] }, //эхлэх огноо 4
                  { text: "-", style: ["anotherStyle"] }, // 5
                  {
                    text: "independently ",
                    // italics: true,
                    fontSize: 12,
                    style: ["anotherStyle"],
                  }, //төгсөх огноо 6
                  {
                    text: " оны хичээлийн жилд \n Нийслэлийн ерөнхий боловсролын Сэлбэ олон улсын сургуулийн    ", //7
                    style: ["anotherStyle"],
                  },

                  {
                    text: "....", //8
                  },
                  {
                    text: "   ангид суралцдаг нь үнэн болно.\n\n\n\n\n\n", //[9]
                    style: ["anotherStyle"],
                  },
                ],
                style: ["anotherStyle"],
              },

              {
                text: "", //[10]
                style: ["anotherStyle"],
              },
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
              },
              subheader: {
                fontSize: 15,
                bold: true,
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
              anotherStyle: {
                // italics: true,
                alignment: "center",
                lineHeight: 1.5,
              },
            },
            images: {
              building: "",
            },
          };
          dd.content[3].text[0].text = allStudent.profile_student_last_name;
          dd.content[3].text[2].text = allStudent.profile_student_name;
          dd.content[dd.content.length - 1].text =
            "Суралцахуйн албаны дарга....................... " +
            this.userInfo.teacher_last_name[0] +
            "." +
            this.userInfo.teacher_name +
            " \n\n";
          //   console.log(dd.content[dd.content.length - 1]);
          // .years[0]
          // .years[0]
          // .years[0]
          // .years[0]
          // .years[0]
          // .years[0]
          // .years[0]
          // .years[0]
          dd.content[3].text[4].text =
            this.schoolLogo[0].start_date[0] +
            this.schoolLogo[0].start_date[1] +
            this.schoolLogo[0].start_date[2] +
            this.schoolLogo[0].start_date[3];
          dd.content[3].text[6].text =
            this.schoolLogo[0].finish_date[0] +
            this.schoolLogo[0].finish_date[1] +
            this.schoolLogo[0].finish_date[2] +
            this.schoolLogo[0].finish_date[3];
          dd.content[3].text[8].text = allStudent.grade_school_class_full_name;
          dd.images.building = this.schoolLogo[0].school_logo;
          let downName =
            allStudent.profile_student_last_name[0] +
            allStudent.profile_student_last_name[1] +
            "." +
            allStudent.profile_student_name +
            ".pdf";
          pdfMake.createPdf(dd).download(downName);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  //   components: { jsPDF },
  //   created() {},
};
</script>

<style></style>
