<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactivity" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Шигшээ баг</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <div class="container">
                      <div class="float-left">
                        <p class="card-title" style="font-size: 80%">
                          Нийт шигшээ багийн тоо:
                          {{ competitions.length }}
                        </p>
                      </div>
                      <div class="float-right">
                        <b-button
                          style="font-size: 80%"
                          size="sm"
                          variant="success"
                          @click.prevent="createCompetitionTeam()"
                          >Шигшээ багийн бүртгэл хийх</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <b-container class="bv-example-row" fluid>
                      <b-row>
                        <!-- <b-col sm="3"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    variant="success"
                    @click.prevent="getAllCompetitions()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
                        <b-col sm="4"> </b-col>
                        <b-col sm="8"> </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="card-body table-responsive">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageCompetitions"
                            :options="pageOptionsRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterCompetitions"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterCompetitions"
                                @click="filterCompetitions = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageCompetitions"
                      :total-rows="totalRowsCompetitions"
                      :per-page="perPageCompetitions"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="competitions"
                      :fields="fieldsActiveCompetitions"
                      :current-page="currentPageCompetitions"
                      :per-page="perPageCompetitions"
                      :filter="filterCompetitions"
                      :filter-ignored-fields="filterIgnoredFieldsCompetitions"
                      :filter-included-fields="filterOnCompetitions"
                      @filtered="onFilteredCompetitions"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(my_competition_subject)="data">
                        <b-badge
                          variant="secondary"
                          class="badge badge-primary text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.my_competition_subject.subject_name }}
                        </b-badge>
                        <a
                          href="#"
                          class="badge bg-success"
                          @click="showCompetition(data.item)"
                          ><i class="fa fa-eye"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="createCompetitionTeamTeacher(data.item)"
                          ><i class="fas fa-chalkboard-teacher"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-teal"
                          @click="createCompetitionTeamStudent(data.item)"
                          ><i class="fas fa-user-graduate"></i
                        ></a>
                      </template>

                      <template #cell(my_competition_school_class)="data">
                        <b v-if="data.item.my_competition_school_class">{{
                          data.item.my_competition_school_class.full_name
                        }}</b>
                        <b v-else> Анги бүртгэгдээгүй </b>
                      </template>

                      <template #cell(competition_teachers)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="teacher in data.item.competition_teachers"
                              :key="teacher.id"
                            >
                              <img
                                v-if="teacher.my_info != null"
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                  teacher.my_info.department.department_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  teacher.my_info.teacher_last_name +
                                  ' <span class=text-uppercase>' +
                                  teacher.my_info.teacher_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  teacher.my_info.profile_photo_url
                                    ? teacher.my_info.profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <i
                                class="fas fa-user-times"
                                @click="removeCompetitionTeamTeacher(teacher)"
                              ></i>
                            </li>
                          </ul>
                        </span>
                      </template>
                      <template #cell(competition_students)="data">
                        <span>
                          <ul class="list-inline">
                            <li
                              class="list-inline-item"
                              v-for="(student, index) in data.item
                                .competition_students"
                              :key="index"
                            >
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url !=
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  student.my_info.student_profile_photo_url +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              <img
                                v-if="
                                  student.my_info.student_profile_photo_url ==
                                  null
                                "
                                alt="Avatar"
                                v-b-tooltip.hover.top.html="
                                  '<dd><img src=' +
                                  '/images/users/user.png' +
                                  ' style=' +
                                  'width:180px!important' +
                                  '></dd>' +
                                  '<dl class=text-left><dt>Анги:</dt><dd>' +
                                  student.my_info_school_class.full_name +
                                  '</dd><dt>Овог Нэр</dt><dd>' +
                                  student.my_info.student_last_name +
                                  ' <span class=text-uppercase>' +
                                  student.my_info.student_name +
                                  '</span></dd></dl>'
                                "
                                class="direct-chat-img"
                                :src="
                                  student.my_info.student_profile_photo_url
                                    ? student.my_info.student_profile_photo_url
                                    : '/images/users/user.png'
                                "
                              />
                              {{ student.my_info.student_last_name[0]
                              }}{{ student.my_info.student_last_name[1] }}.{{
                                student.my_info.student_name
                              }}
                              <i
                                class="fas fa-user-times"
                                @click="removeCompetitionTeamStudent(student)"
                              ></i>
                            </li>
                          </ul>

                          <!-- <b-badge
                            v-if="
                              student.my_info.student_profile_photo_url != null
                            "
                            variant="light"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              student.my_info.student_profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Суралцагч</dt><dd>' +
                              student.my_info.student_last_name +
                              ' ' +
                              student.my_info.student_name
                            "
                          >
                            {{ student.my_info.student_last_name[0]
                            }}{{ student.my_info.student_last_name[1] }}.{{
                              student.my_info.student_name
                            }}
                            <i
                              class="fas fa-user-times"
                              @click="removeCompetitionTeamStudent(student)"
                            ></i>
                          </b-badge>

                          <b-badge
                            v-if="
                              student.my_info.student_profile_photo_url == null
                            "
                            variant="light"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              '/images/users/user.png' +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Суралцагч</dt><dd>' +
                              student.my_info.student_last_name[0] +
                              student.my_info.student_last_name[1] +
                              '.' +
                              student.my_info.student_name
                            "
                          >
                            {{ student.my_info.student_last_name[0]
                            }}{{ student.my_info.student_last_name[1] }}.{{
                              student.my_info.student_name
                            }}
                            <i
                              class="fas fa-user-times"
                              @click="removeCompetitionTeamStudent(student)"
                            ></i>
                          </b-badge> -->
                        </span>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editCompetitionTeam(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteCompetitionTeam(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactivity">
              <div class="card">
                <div class="card-header">
                  <strong>Устгагдсан шигшээ баг</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Устгагдсан шигшээ багийн тоо:
                      {{ competitionsDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="show" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveCompetition"
                            :options="pageOptionsInactiveCompetitions"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveRooms"
                                @click="filterInactiveRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveCompetitions"
                      :total-rows="totalRowsInactiveCompetitions"
                      :per-page="perPageInactiveCompetition"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="competitionsDeleted"
                      :fields="fieldsInactiveCompetitions"
                      :current-page="currentPageInactiveCompetitions"
                      :per-page="perPageInactiveCompetition"
                    >
                      <!-- :filter="filterInactiveRooms"
                      :filter-included-fields="filterOnInactiveRooms"
                      @filtered="onFilteredInactiveRooms" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreCompetitionTeam(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Дэлгэрэнгүй харах -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-competition"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showCompetitionInfo.name"
      class="modal fade"
      hide-footer
      @hidden="hideInfoCompetition"
    >
      <div class="modal-body">
        <div class="direct-chat-msg" style="text-align: center">
          <!-- <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ showCompetitionInfo.objective }}
              {{ showCompetitionInfo.register_info }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ showCompetitionInfo.start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ showCompetitionInfo.finish_date | dateYearMonthDay }}
            </span>
          </div> -->
          <img
            :src="
              showCompetitionInfo.photo_url
                ? showCompetitionInfo.photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
          />
          <!-- class="direct-chat-img" -->
          <!-- <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>АГУУЛГА:</b><br />
              {{ showCompetitionInfo.object }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>АНАЛИЗ:</b><br />
              {{ showCompetitionInfo.register_info }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ХЯНАЛТЫН ҮЗҮҮЛЭЛТ:</b><br />
              {{ showCompetitionInfo.reward }}
            </div>
          </div> -->
        </div>
        <!-- <div class="row">
          <div class="col-lg-12 mt-2">
            <div
              class="callout callout-success"
              v-if="showCompetitionInfo.point > 0"
            >
              <small>
                <strong>Удахгүй харагдах байдлыг сайжруулна :)</strong></small
              >
              <p>
                <small>{{
                  showCompetitionInfo.problem_solution_protect_info
                }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сарын төлөвлөгөө авсан багш нар</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b>
                  {{ showCompetitionInfo.problem_solution_protect_date }}%
                </span>
              </div>
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Зураг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(emp, index) in monthPlanEmps"
                    :key="emp.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.department.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.position.position_name }}
                      </td>
                      <td>
                        <img
                          :src="
                            emp.employee.profile_photo_url
                              ? emp.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{
                          emp.employee.teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}.
                        {{ emp.employee.teacher_name | uppercase }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeCompetitionTeamTeacher(emp)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="hideInfoCompetition()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
    <!-- Багш бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-teachers"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelCompetitionTeacher"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(teacher, index) in formTeachers.teachers"
                :key="index"
              >
                <img
                  v-if="teacher != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                    teacher.department_name +
                    '</dd><dt>Ажлын байр:</dt><dd>' +
                    teacher.position_name +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    teacher.emp_teacher_last_name +
                    ' <span class=text-uppercase>' +
                    teacher.emp_teacher_name +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    teacher.profile_photo_url
                      ? teacher.profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formTeachers.teachers"
            deselect-label="Can't remove this value"
            :custom-label="nameTeacher"
            track-by="user_id"
            label="user_name"
            placeholder="Багш сонгоно уу!"
            :options="teachers"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.emp_teacher_last_name[0].toUpperCase() }}.{{
                  option.emp_teacher_name
                }}</strong
              >
              Багш сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeCompetitionTeamTeachers()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelCompetitionTeacher"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Суралцагч бүртгэх -->
    <b-modal
      size="lg"
      id="modal-create-students"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @hidden="cancelCompetitionStudent"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <ul class="list-inline">
              <li
                class="list-inline-item"
                v-for="(student, index) in formStudents.students"
                :key="index"
              >
                <img
                  v-if="student != null"
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dl class=text-left><dt>Анги:</dt><dd>' +
                    student.school_class_full_name +
                    '</dd><dt>Имэйл:</dt><dd>' +
                    student.email +
                    '</dd><dt>Овог Нэр</dt><dd>' +
                    student.pro_student_last_name +
                    ' <span class=text-uppercase>' +
                    student.pro_student_name +
                    student.student_profile_photo_url +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    student.student_profile_photo_url
                      ? student.student_profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </li>
            </ul>
          </div>
          <multiselect
            :multiple="true"
            v-model="formStudents.students"
            deselect-label="Can't remove this value"
            :custom-label="nameStudent"
            track-by="pro_user_id"
            label="pro_student_name"
            placeholder="Суралцагч сонгоно уу!"
            :options="students"
            :searchable="true"
            :allow-empty="false"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.pro_student_last_name[0].toUpperCase() }}.{{
                  option.pro_student_name
                }}</strong
              >
              Суралцагч сонгогдлоо
            </template>
          </multiselect>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="storeCompetitionTeamStudents()"
            >
              Хадгалах
            </button>

            <button
              class="btn btn-secondary"
              @click.prevent="cancelCompetitionStudent"
            >
              Болих
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Шигшээ баг бүртгэх -->
    <b-modal
      size="lg"
      id="modal-competition"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
      @reset="hideCompetition"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-3">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Сургуулийн шигшээ баг бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">
                  Сургуулийн шигшээ баг засах
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Анги сонгоно уу!</label>
                      <multiselect
                        :multiple="false"
                        v-model="form.school_class_id"
                        deselect-label="Can't remove this value"
                        :custom-label="nameSchoolClass"
                        track-by="id"
                        label="full_name"
                        placeholder="Анги сонгоно уу!"
                        :options="schoolClasses"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="true"
                      >
                        <!-- @select="toggleSelect" -->
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.full_name }}</strong>
                          сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Хичээл сонгоно уу!</label>
                      <multiselect
                        v-model="form.subject_id"
                        deselect-label="Can't remove this value"
                        :custom-label="nameSubject"
                        track-by="id"
                        label="subject_name"
                        placeholder="Хичээл сонгоно уу!"
                        :options="subjects"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                      >
                        <!-- @select="toggleSelect" -->
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.subject_name }}</strong>
                          -ны/ний хичээл сонгогдлоо
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Шигшээ багийн зорилго, тактик</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.description"
                      placeholder="Шигшээ багийн зорилго, тактик"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('description'),
                        },
                      ]"
                    ></b-form-textarea>
                    <has-error :form="form" field="description"></has-error>
                  </div>

                  <!-- <div class="row">
                    <div class="form-group col-lg-2">
                      <img
                        class="profile-user-img img-fluid attachment-img mb-2"
                        :src="getSelectedItemImg"
                        alt="Хөрөнгийн зураг"
                        v-show="getSelectedItemImg"
                      />
                    </div>
                    <div class="form-group col-lg-10">
                      <b-form-file
                        id=""
                        browse-text="Зураг солих"
                        size="sm"
                        @change="getItemImg"
                        :key="fileInputKey"
                        accept="image/*"
                      ></b-form-file>
                    </div>
                  </div> -->
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="!editMode"
                  @click.prevent="storeCompetitionTeam()"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editMode"
                  @click.prevent="updateCompetitionTeam()"
                >
                  Засах
                </button>
                <button
                  class="btn btn-secondary"
                  v-show="editMode"
                  @click.prevent="cancelCompetition"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      competitions: [],
      competitionsDeleted: [],
      teachers: [],
      students: [],
      subjects: [],
      schoolClasses: [],

      //b-table тохиргоо
      totalRowsCompetitions: 1,
      currentPageCompetitions: 1,
      perPageCompetitions: 200,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCompetitions: "",
      filterOnCompetitions: [],
      filterIgnoredFieldsCompetitions: [
        "competition_teachers",
        "competition_students",
      ],
      fieldsActiveCompetitions: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "my_competition_subject",
          label: "Агуулга",
          sortable: true,
        },
        {
          key: "description",
          label: "Шигшээ багийн зорилго",
          sortable: true,
        },
        {
          key: "my_competition_school_class",
          label: "Анги",
          sortable: true,
        },
        {
          key: "competition_teachers",
          label: "Хариуцах багш",
          sortable: true,
        },
        {
          key: "competition_students",
          label: "Оролцох суралцагчид",
          sortable: true,
        },

        // {
        //   key: "updated_at",
        //   label: "Зассан",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveCompetitions: 1,
      currentPageInactiveCompetitions: 1,
      perPageInactiveCompetition: 25,
      pageOptionsInactiveCompetitions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveCompetitions: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Олимпиад уралдааны нэр",
          sortable: true,
        },
        // {
        //   key: "objective",
        //   label: "Зорилго",
        //   sortable: true,
        // },
        {
          key: "register_info",
          label: "Бүртгэлийн мэдээлэл",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Олимпиад уралдаан болох огноо",
          sortable: true,
        },

        // {
        //   key: "reward",
        //   label: "Шагнал урамшуулал",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_info",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалтын мэдээлэл(Бодлого хамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "problem_solution_protect_date",
        //   label: "Үр дүн, гүйцэтгэл хамгаалалт хийх огноо(Бодлогохамгаалалт)",
        //   sortable: true,
        // },
        // {
        //   key: "results_meeting",
        //   label: "Шагнал гардуулах ",
        //   sortable: true,
        // },
        {
          key: "address",
          label: "Хаяг",
          sortable: true,
        },
        {
          key: "contact_information",
          label: "Холбоо барих мэдээлэл",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        // {
        //   key: "updated_at",
        //   label: "Зассан",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      showMode: false,
      showCompetitionInfo: "",
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],

      editMode: false,
      form: new Form({
        id: "",
        school_class_id: "",
        subject_id: "",
        description: "",
      }),
      //   //   Зураг
      //   getSelectedItemImg: "",
      //   fileInputKey: 0,

      //   Багш
      formTeachers: new Form({
        id: "",
        competition_team_id: "",
        teachers: [],
      }),

      //   Суралцагч
      formStudents: new Form({
        id: "",
        competition_team_id: "",
        students: [],
        school_class_id: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllCompetitions() {
      this.show = true;
      axios
        .get("/manager/getAllCompetitionTeams")
        .then((response) => {
          this.competitions = response.data.competitions;
          this.competitionsDeleted = response.data.competitionsDeleted;
          this.teachers = response.data.teachers;
          this.students = response.data.students;
          this.subjects = response.data.subjects;
          this.schoolClasses = response.data.schoolClasses;
          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //b-table тохирго
    onFilteredCompetitions(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCompetitions = filteredItems.length;
      this.currentPageCompetitions = 1;
    },

    createCompetitionTeam() {
      this.$bvModal.show("modal-competition");
    },

    hideCompetition() {
      Fire.$emit("getCompetitions");
      //   this.getSelectedItemImg = "";
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-competition");
    },
    cancelCompetition() {
      //   this.getSelectedItemImg = "";
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-competition");
    },
    storeCompetitionTeam() {
      this.form
        .post("/manager/storeCompetitionTeam")
        .then((response) => {
          this.$toastr.s(
            "Шигшээ багийн мэдээлэл амжилттай нэмлээ",
            "Бүртгэлээ"
          );
          Fire.$emit("getCompetitions");
          this.hideCompetition();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editCompetitionTeam(competition) {
      //   console.log(competition);
      this.editMode = true;
      this.form.fill(competition);
      //   this.getSelectedItemImg = competition.photo_url;
      this.form.school_class_id = this.schoolClasses.filter(
        (el) => el.id == competition.school_class_id
      );
      this.form.subject_id = this.subjects.filter(
        (el) => el.id == competition.subject_id
      );

      this.$bvModal.show("modal-competition");
    },
    updateCompetitionTeam() {
      //   console.log(this.form);
      this.form
        .put("/manager/updateCompetitionTeam/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getCompetitions");
          this.hideCompetition();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    showCompetition(competition) {
      this.showCompetitionInfo = competition;
      this.$bvModal.show("modal-show-competition");
    },
    hideInfoCompetition() {
      this.showCompetitionInfo = "";
      this.$bvModal.hide("modal-show-competition");
    },

    deleteCompetitionTeam(competition) {
      //   console.log(competition);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Шигшээ багийн мэдээлэл устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/deleteCompetitionTeam/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Шигшээ багийн мэдээлэл системээс устгалаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreCompetitionTeam(competition) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/restoreCompetitionTeam/" + competition.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Шигшээ багийн мэдээллийг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    // getItemImg(e) {
    //   let itemImage = e.target.files[0];
    //   let reader = new FileReader();
    //   reader.readAsDataURL(itemImage);
    //   reader.onload = (e) => {
    //     this.form.photo_url = e.target.result;
    //     this.getSelectedItemImg = e.target.result;
    //   };
    //   //   this.loaded = true;
    // },

    //Багш бүртгэх
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    hideCompetitionTeacher() {
      //   Fire.$emit("getCompetitions");
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    cancelCompetitionTeacher() {
      //   this.getSelectedItemImg = "";
      this.formTeachers.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-teachers");
    },
    createCompetitionTeamTeacher(competition) {
      //   console.log(competition);
      this.formTeachers.competition_team_id = competition.id;
      this.$bvModal.show("modal-create-teachers");
    },
    storeCompetitionTeamTeachers() {
      this.formTeachers
        .post("/manager/storeCompetitionTeamTeachers")
        .then((response) => {
          this.$toastr.s("Шигшээ баг хариуцах багш нарийг нэмлээ", "Бүртгэлээ");
          Fire.$emit("getCompetitions");
          this.hideCompetitionTeacher();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeCompetitionTeamTeacher(teacher) {
      //   console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Шигшээ баг хариуцах багш хасах!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/removeCompetitionTeamTeacher", {
              competition_team_id: teacher.competition_team_id,
              teacher_id: teacher.teacher_id,
            })
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Шигшээ баг хариуцах багшийг хаслаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    //Суралцагч бүртгэх
    nameStudent({
      pro_student_last_name,
      pro_student_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name[0].toUpperCase()}${
        pro_student_last_name[1]
      } . ${pro_student_name} : ${school_class_full_name}`;
    },
    nameSchoolClass({ full_name }) {
      return `${full_name}`;
    },
    nameSubject({ subject_name }) {
      return `${subject_name}`;
    },
    hideCompetitionStudent() {
      //   Fire.$emit("getCompetitions");
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    cancelCompetitionStudent() {
      //   this.getSelectedItemImg = "";
      this.formStudents.reset();
      //   this.editMode = false;
      this.$bvModal.hide("modal-create-students");
    },
    createCompetitionTeamStudent(competition) {
      //   console.log(competition);
      this.formStudents.competition_team_id = competition.id;
      this.$bvModal.show("modal-create-students");
    },

    storeCompetitionTeamStudents() {
      this.formStudents
        .post("/manager/storeCompetitionTeamStudents")
        .then((response) => {
          this.$toastr.s(
            "Олимпиад, уралдаан тэмцээнд оролцох суралцагчдыг нэмлээ",
            "Бүртгэлээ"
          );
          Fire.$emit("getCompetitions");
          this.hideCompetitionStudent();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    removeCompetitionTeamStudent(student) {
      //   console.log(student);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Олимпиад, уралдаан тэмцээнээс тухайн суралцагчийг хасах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/removeCompetitionTeamStudent", {
              competition_team_id: student.competition_team_id,
              school_class_id: student.school_class_id,
              student_id: student.student_id,
            })
            .then((res) => {
              Swal.fire(
                "Хассан!",
                "Шигшээ багаас суралцагчийг хаслаа.",
                "success"
              );
              Fire.$emit("getCompetitions");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  created() {
    this.getAllCompetitions();
    Fire.$on("getCompetitions", () => {
      this.getAllCompetitions();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
