<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Эд хөрөнгийн бүртгэл</strong>
                  <div class="card-tools">
                    <b-button
                      size="sm"
                      variant="primary"
                      @click.prevent="showCapitalCategory()"
                      >Ангилал бүртгэх</b-button
                    >
                    <b-button
                      size="sm"
                      variant="primary"
                      @click.prevent="showCapitalItem()"
                      v-b-toggle.sidebar-item
                      >Хөрөнгө бүртгэх</b-button
                    >
                  </div>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт эд хөрөнгийн тоо:
                      {{ allData.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="itemLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageAllData"
                            :options="pageOptionsAllData"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterAllData"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterAllData"
                                @click="filterAllData = ''"
                                >Цэвэрлэwх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageAllData"
                      :total-rows="totalRowsAllData"
                      :per-page="perPageAllData"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="allData"
                      :fields="fieldsAllData"
                      :current-page="currentPageAllData"
                      :per-page="perPageAllData"
                      :filter="filterAllData"
                      :filter-ignored-fields="filterIgnoredFieldsAllData"
                      :filter-included-fields="filterOnAllData"
                      @filtered="onFilteredAllData"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(room_full_name)="data">
                        <b-badge variant="info">
                          {{ data.item.room_user.my_room.room_full_name }}
                        </b-badge>
                      </template>

                      <template #cell(teacher_name)="data">
                        <b-badge
                          variant="secondary"
                          v-if="data.item.room_user.my_user_info"
                        >
                          {{ data.item.room_user.my_user_info.teacher_name }}
                        </b-badge>
                      </template>

                      <template #cell(name)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.items.capital_category !== null"
                        >
                          {{ data.item.items.capital_category.name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Бүртгэлгүй.
                        </b-badge>
                      </template>

                      <template #cell(status)="data">
                        <b-badge
                          variant="secondary"
                          v-if="data.item.items.capital_category !== null"
                        >
                          {{ data.item.items.capital_category.status }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Бүртгэлгүй.
                        </b-badge>
                      </template>

                      <template #cell(item_image)="data">
                        <img
                          v-b-tooltip.hover.top.html="
                            '<dd><img src=' +
                            data.item.items.item_image +
                            ' style=' +
                            'width:100%!important </dd></dl>'
                          "
                          class="table-avatar"
                          :src="data.item.items.item_image"
                          alt="user-avatar"
                      /></template>

                      <template #cell(item_name)="data">
                        <b-badge variant="light">
                          {{ data.item.items.item_name }}
                        </b-badge>
                      </template>
                      <template #cell(serial_num)="data">
                        <b-badge variant="light">
                          {{ data.item.serial_num }}
                        </b-badge>
                      </template>

                      <template #cell(item_code)="data">
                        <b-badge variant="light">
                          {{ data.item.items.item_code }}
                        </b-badge>
                      </template>

                      <template #cell(count)="data">
                        <b-badge variant="light">
                          {{ data.item.count }}
                        </b-badge>
                      </template>

                      <template #cell(item_price)="data">
                        <b-badge variant="light">
                          {{ data.item.count * data.item.items.item_price }}
                        </b-badge>
                      </template>

                      <template #cell(reg_date)="data">
                        <b-badge variant="light">
                          {{ data.item.reg_date }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editRoomUserItem(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteRoomUserItem(data.item.id)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong>Эд хөрөнгийн бүртгэл</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт эд хөрөнгийн тоо:
                      {{ alldeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="itemLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageDeletedData"
                            :options="pageOptionsAllData"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterDeletedData"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterDeletedData"
                                @click="filterDeletedData = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageDeletedData"
                      :total-rows="totalRowsDeletedData"
                      :per-page="perPageDeletedData"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="alldeleted"
                      :fields="fieldsDeletedAllData"
                      :current-page="currentPageDeletedData"
                      :per-page="perPageDeletedData"
                      :filter="filterDeletedData"
                      :filter-ignored-fields="filterIgnoredFieldsDeletedAllData"
                      :filter-included-fields="filterOnDeletedData"
                      @filtered="onFilteredDeletedData"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(room_full_name)="data">
                        <b-badge variant="info">
                          {{ data.item.room_user.my_room.room_full_name }}
                        </b-badge>
                      </template>

                      <template #cell(teacher_name)="data">
                        <b-badge
                          variant="secondary"
                          v-if="data.item.room_user.my_user_info"
                        >
                          {{ data.item.room_user.my_user_info.teacher_name }}
                        </b-badge>
                      </template>

                      <template #cell(name)="data">
                        <b-badge
                          variant="success"
                          v-if="data.item.items.capital_category !== null"
                        >
                          {{ data.item.items.capital_category.name }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Бүртгэлгүй.
                        </b-badge>
                      </template>

                      <template #cell(status)="data">
                        <b-badge
                          variant="light"
                          v-if="data.item.items.capital_category !== null"
                        >
                          {{ data.item.items.capital_category.status }}
                        </b-badge>
                        <b-badge variant="warning" v-else>
                          Бүртгэлгүй.
                        </b-badge>
                      </template>

                      <template #cell(item_image)="data">
                        <img
                          v-b-tooltip.hover.top.html="
                            '<dd><img src=' +
                            data.item.items.item_image +
                            ' style=' +
                            'width:100%!important </dd></dl>'
                          "
                          class="direct-chat-img"
                          :src="data.item.items.item_image"
                          alt="user-avatar"
                      /></template>

                      <template #cell(item_name)="data">
                        <b-badge variant="light">
                          {{ data.item.items.item_name }}
                        </b-badge>
                      </template>

                      <template #cell(item_code)="data">
                        <b-badge variant="light">
                          {{ data.item.items.item_code }}
                        </b-badge>
                      </template>
                      <template #cell(serial_num)="data">
                        <b-badge variant="light">
                          {{ data.item.serial_num }}
                        </b-badge>
                      </template>

                      <template #cell(count)="data">
                        <b-badge variant="light">
                          {{ data.item.count }}
                        </b-badge>
                      </template>

                      <template #cell(item_price)="data">
                        <b-badge variant="light">
                          {{ data.item.count * data.item.items.item_price }}
                        </b-badge>
                      </template>

                      <template #cell(reg_date)="data">
                        <b-badge variant="light">
                          {{ data.item.reg_date }}
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreRoomUserItem(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <h3 class="card-title" v-show="!editRoomUser">
            Өрөө болон хэрэглэгч холбох
          </h3>
          <h3 class="card-title" v-show="editRoomUser">
            Өрөө болон хэрэглэгч солих
          </h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <form>
            <div class="form-row">
              <div class="form-group col-lg-12">
                <multiselect
                  v-model="formUserItem.roomUserId"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  :custom-label="customRoomUserName"
                  placeholder="Өрөө, Хэрэглэгч"
                  :options="allRoomUsers"
                  :searchable="true"
                  :allow-empty="true"
                >
                  <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                    v-if="allRoomUsers.my_user_info"
                  >
                    {{ option.room_full_name }},
                    {{ option.my_user_info.teacher_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group col-lg-12">
                <multiselect
                  v-model="formUserItem.catItemId"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  track-by="id"
                  :custom-label="customCatItemName"
                  placeholder="Хөрөнгө"
                  :options="capitalItems"
                  :searchable="true"
                  :allow-empty="true"
                  :disabled="editRoomUser"
                  @input="getLeftItemCount"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{
                      option.capital_category
                        ? option.capital_category.name
                        : "Ангилал бүртгэгдээгүй"
                    }}, {{ option.item_name }}
                  </template>
                </multiselect>
              </div>
              <div class="form-group col-lg-12">
                Үлдэгдэл: {{ leftItemCount }}+{{ oldCount }} =
                {{ leftItemCount + oldCount }}
              </div>
              <div class="form-group col-lg-12">
                <input
                  type="number"
                  v-model="formUserItem.count"
                  :max-value="leftItemCount"
                  :maxlength="leftItemCount"
                  placeholder="Тоо ширхэг"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formUserItem.errors.has('count'),
                    },
                  ]"
                />
              </div>
              <div class="form-group col-lg-12">
                <input
                  type="text"
                  v-model="formUserItem.serial_num"
                  placeholder="serial number"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formUserItem.errors.has('serial_num'),
                    },
                  ]"
                />
              </div>
              <div class="form-group col-lg-12">
                <b-form-datepicker
                  id="reg_date"
                  :state="true"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="formUserItem.reg_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formUserItem.errors.has('reg_date'),
                    },
                  ]"
                  placeholder="Бүртгэсэн огноо"
                ></b-form-datepicker>
              </div>
            </div>
          </form>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-success"
            v-show="!editRoomUser"
            @click.prevent="createRoomUserItem"
          >
            Бүртгэх
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editRoomUser"
            @click.prevent="updateRoomUserItem"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-dark"
            @click.prevent="cancelRoomUserItem"
            v-show="editRoomUser"
          >
            Болих
          </button>
        </div>
      </div>
    </div>

    <!-- Ангилал бүртгэх -->
    <b-modal
      size="xl"
      id="modal-type"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Ангилал нэмэх"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-3">
            <div class="card card-primary card-outline">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      href="#catActivity"
                      data-toggle="tab"
                      >Бүртгэсэн</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#catInactive" data-toggle="tab"
                      >Устгагдсан</a
                    >
                  </li>
                </ul>
              </div>

              <div class="card-body table-responsive">
                <div class="tab-content">
                  <div class="tab-pane active" id="catActivity">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">Ангилал</h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body table-responsive p-0">
                        <b-overlay :show="itemLoaded" rounded="sm">
                          <b-row>
                            <b-col lg="12" class="my-1">
                              <b-form-group
                                label="Нэг хуудсанд харуулах тоо"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                              >
                                <b-form-select
                                  id="per-page-select"
                                  v-model="perPageCategoryData"
                                  :options="pageOptionsAllData"
                                  size="sm"
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group
                                label="Хайлт хийх утга оруулна уу!"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                              >
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="filterCategoryData"
                                    type="search"
                                    placeholder="Хайлт хийх утгаа оруулна уу!"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!filterCategoryData"
                                      @click="filterCategoryData = ''"
                                      >Цэвэрлэх</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-pagination
                            v-model="currentPageCategoryData"
                            :total-rows="totalRowsCategoryData"
                            :per-page="perPageCategoryData"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                          <b-table
                            hover
                            :items="capitalCategory"
                            :fields="fieldsCategoryData"
                            :current-page="currentPageCategoryData"
                            :per-page="perPageCategoryData"
                            :filter="filterCategoryData"
                            :filter-included-fields="filterOnCategoryData"
                            @filtered="onFilteredCategoryData"
                          >
                            <template #cell(index)="data">
                              {{ data.index + 1 }}
                            </template>

                            <template #cell(name)="data">
                              <b-badge variant="info">
                                {{ data.item.name }}
                              </b-badge>
                            </template>

                            <template #cell(status)="data">
                              <b-badge variant="light">
                                {{ data.item.status }}
                              </b-badge>
                            </template>

                            <template #cell(actions)="data">
                              <a
                                href="#"
                                class="badge bg-warning"
                                @click="editCapitalCategory(data.item)"
                                ><i class="fas fa-pencil-alt"></i
                              ></a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteCapitalCategory(data.item.id)"
                                ><i class="far fa-trash-alt"></i
                              ></a>
                            </template>
                          </b-table>
                        </b-overlay>
                      </div>
                      <!-- /.card-body -->
                    </div>
                  </div>
                  <div class="tab-pane" id="catInactive">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">Ангилал</h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body table-responsive p-0">
                        <b-overlay :show="itemLoaded" rounded="sm">
                          <b-row>
                            <b-col lg="12" class="my-1">
                              <b-form-group
                                label="Нэг хуудсанд харуулах тоо"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                              >
                                <b-form-select
                                  id="per-page-select"
                                  v-model="perPageDeletedCategoryData"
                                  :options="pageOptionsAllData"
                                  size="sm"
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group
                                label="Хайлт хийх утга оруулна уу!"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                              >
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="filterDeletedCategoryData"
                                    type="search"
                                    placeholder="Хайлт хийх утгаа оруулна уу!"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!filterDeletedCategoryData"
                                      @click="filterDeletedCategoryData = ''"
                                      >Цэвэрлэх</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-pagination
                            v-model="currentPageDeletedCategoryData"
                            :total-rows="totalRowsDeletedCategoryData"
                            :per-page="perPageDeletedCategoryData"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                          <b-table
                            hover
                            :items="deletedCategory"
                            :fields="fieldsDeletedCategoryData"
                            :current-page="currentPageDeletedCategoryData"
                            :per-page="perPageDeletedCategoryData"
                            :filter="filterDeletedCategoryData"
                            :filter-included-fields="
                              filterOnDeletedCategoryData
                            "
                            @filtered="onFilteredDeletedCategoryData"
                          >
                            <template #cell(index)="data">
                              {{ data.index + 1 }}
                            </template>

                            <template #cell(name)="data">
                              <b-badge variant="info">
                                {{ data.item.name }}
                              </b-badge>
                            </template>

                            <template #cell(status)="data">
                              <b-badge variant="light">
                                {{ data.item.status }}
                              </b-badge>
                            </template>

                            <template #cell(actions)="data">
                              <a
                                href="#"
                                class="badge bg-warning"
                                @click="restoreCategory(data.item)"
                                ><i class="fas fa-trash-restore-alt"></i
                              ></a>
                            </template>
                          </b-table>
                        </b-overlay>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editCategoryMode">
                  Ангилал үүсгэх
                </h3>
                <h3 class="card-title" v-show="editCategoryMode">
                  Ангилал засах
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-row">
                    <div class="form-group col-lg-12">
                      <b-form-textarea
                        id="textarea"
                        v-model="formCategory.name"
                        placeholder="Ерөнхий нэршил"
                        rows="0"
                        max-rows="1"
                        :value="formCategory.name"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group col-lg-12">
                      <b-form-select
                        v-model="formCategory.status"
                        :options="CatStatOptions"
                        text-field="text"
                        value-field="text"
                        size="sm"
                        class="form-control"
                      >
                      </b-form-select>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editCategoryMode"
                  @click.prevent="createCapitalCategory"
                >
                  Бүртгэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editCategoryMode"
                  @click.prevent="updateCapitalCategory"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditCategory"
                  v-show="editCategoryMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Хөрөнгө бүртгэх -->
    <b-sidebar
      id="sidebar-item"
      title="Хөрөнө нэмэх"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="row">
        <div class="col-lg-8 mt-2">
          <div class="card card-primary card-outline">
            <div class="card-header p-2">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    href="#itemActivity"
                    data-toggle="tab"
                    >Бүртгэсэн</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#itemInactive" data-toggle="tab"
                    >Устгагдсан</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body table-responsive">
              <div class="tab-content">
                <div class="tab-pane active" id="itemActivity">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Хөрөнгө</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                      <b-overlay :show="itemLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд харуулах тоо"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageItemData"
                                :options="pageOptionsAllData"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайлт хийх утга оруулна уу!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterItemData"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterItemData"
                                    @click="filterItemData = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageItemData"
                          :total-rows="totalRowsItemData"
                          :per-page="perPageItemData"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="capitalItems"
                          :fields="fieldsItemData"
                          :current-page="currentPageItemData"
                          :per-page="perPageItemData"
                          :filter="filterItemData"
                          :filter-ignored-fields="filterIgnoredFieldsItem"
                          :filter-included-fields="filterOnItemData"
                          @filtered="onFilteredItemData"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(name)="data">
                            <b-badge variant="info">
                              {{ data.item.capital_category.name }}
                            </b-badge>
                          </template>

                          <template #cell(status)="data">
                            <b-badge variant="light">
                              {{ data.item.capital_category.status }}
                            </b-badge>
                          </template>

                          <template #cell(item_image)="data">
                            <img
                              v-b-tooltip.hover.top.html="
                                '<dd><img src=' +
                                data.item.item_image +
                                ' style=' +
                                'width:100%!important </dd></dl>'
                              "
                              class="direct-chat-img"
                              :src="data.item.item_image"
                              alt="user-avatar"
                          /></template>

                          <template #cell(item_name)="data">
                            <b-badge variant="light">
                              {{ data.item.item_name }}
                            </b-badge>
                          </template>

                          <template #cell(item_code)="data">
                            <b-badge variant="light">
                              {{ data.item.item_code }}
                            </b-badge>
                          </template>

                          <template #cell(item_count)="data">
                            <b-badge variant="light">
                              {{ data.item.item_count }}
                            </b-badge>
                          </template>

                          <template #cell(item_price)="data">
                            <b-badge variant="light">
                              {{ data.item.item_count * data.item.item_price }}
                            </b-badge>
                          </template>

                          <template #cell(item_reg)="data">
                            <b-badge variant="light">
                              {{ data.item.item_reg }}
                            </b-badge>
                          </template>

                          <template #cell(reg_date)="data">
                            <b-badge variant="light">
                              {{ data.item.reg_date }}
                            </b-badge>
                          </template>

                          <template #cell(actions)="data">
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="editCapitalItem(data.item)"
                              ><i class="fa fa-edit"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-danger"
                              @click="deleteCapitalItem(data.item.id)"
                              ><i class="fa fa-minus"></i
                            ></a>
                          </template>
                        </b-table>
                      </b-overlay>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
                <div class="tab-pane" id="itemInactive">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Хөрөнгө</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                      <b-overlay :show="itemLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд харуулах тоо"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageDeletedItemData"
                                :options="pageOptionsAllData"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайлт хийх утга оруулна уу!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterDeletedItemData"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterDeletedItemData"
                                    @click="filterDeletedItemData = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageDeletedItemData"
                          :total-rows="totalRowsDeletedItemData"
                          :per-page="perPageDeletedItemData"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="deletedItem"
                          :fields="fieldsDeletedItemData"
                          :current-page="currentPageDeletedItemData"
                          :per-page="perPageDeletedItemData"
                          :filter="filterDeletedItemData"
                          :filter-ignored-fields="
                            filterIgnoredFieldsDeletedItem
                          "
                          :filter-included-fields="filterOnDeletedItemData"
                          @filtered="onFilteredDeletedItemData"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(name)="data">
                            <b-badge variant="info">
                              {{ data.item.capital_category.name }}
                            </b-badge>
                          </template>

                          <template #cell(status)="data">
                            <b-badge variant="light">
                              {{ data.item.capital_category.status }}
                            </b-badge>
                          </template>

                          <template #cell(item_image)="data">
                            <img
                              v-b-tooltip.hover.top.html="
                                '<dd><img src=' +
                                data.item.item_image +
                                ' style=' +
                                'width:100%!important </dd></dl>'
                              "
                              class="direct-chat-img"
                              :src="data.item.item_image"
                              alt="user-avatar"
                          /></template>

                          <template #cell(item_name)="data">
                            <b-badge variant="light">
                              {{ data.item.item_name }}
                            </b-badge>
                          </template>

                          <template #cell(item_code)="data">
                            <b-badge variant="light">
                              {{ data.item.item_code }}
                            </b-badge>
                          </template>

                          <template #cell(item_count)="data">
                            <b-badge variant="light">
                              {{ data.item.item_count }}
                            </b-badge>
                          </template>

                          <template #cell(item_price)="data">
                            <b-badge variant="light">
                              {{ data.item.item_count * data.item.item_price }}
                            </b-badge>
                          </template>

                          <template #cell(item_reg)="data">
                            <b-badge variant="light">
                              {{ data.item.item_reg }}
                            </b-badge>
                          </template>

                          <template #cell(reg_date)="data">
                            <b-badge variant="light">
                              {{ data.item.reg_date }}
                            </b-badge>
                          </template>

                          <template #cell(actions)="data">
                            <a
                              href="#"
                              class="badge bg-warning"
                              @click="restoreCapitalItem(data.item)"
                              ><i class="fas fa-trash-restore-alt"></i
                            ></a>
                          </template>
                        </b-table>
                      </b-overlay>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-2">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title" v-show="!editItemMode">Хөрөнгө бүртгэх</h3>
              <h3 class="card-title" v-show="editItemMode">Хөрөнгө засах</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <div class="card-body">
              <form>
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <multiselect
                      v-model="formItem.cap_cat_id"
                      deselect-label="Хасах"
                      select-label="Сонгох"
                      track-by="id"
                      :custom-label="customPhysicalName"
                      placeholder="нэр, төрөл"
                      :options="capitalCategory"
                      :searchable="true"
                      :allow-empty="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}, {{ option.status }}
                      </template>
                    </multiselect>
                  </div>
                  <div class="form-group col-lg-12">
                    <img
                      class="profile-user-img img-fluid attachment-img mb-2"
                      :src="getSelectedItemImg"
                      alt="Хөрөнгийн зураг"
                      v-show="getSelectedItemImg"
                    />
                    <b-form-file
                      id=""
                      browse-text="Зураг солих"
                      size="sm"
                      @change="getItemImg"
                      :key="fileInputKey"
                      accept="image/*"
                    ></b-form-file>
                  </div>
                  <div class="form-group col-lg-12">
                    <b-form-textarea
                      id="textarea"
                      v-model="formItem.item_name"
                      placeholder="Ерөнхий нэршил"
                      rows="0"
                      max-rows="1"
                      :value="formItem.item_name"
                    ></b-form-textarea>
                  </div>
                  <div class="form-group col-lg-12">
                    <input
                      type="number"
                      v-model="formItem.item_code"
                      :disabled="editItemMode"
                      placeholder="Код"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formItem.errors.has('item_code'),
                        },
                      ]"
                    />
                  </div>
                  <div class="form-group col-lg-12">
                    <input
                      type="number"
                      v-model="formItem.item_count"
                      placeholder="Тоо ширхэг"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formItem.errors.has('item_count'),
                        },
                      ]"
                    />
                  </div>
                  <div class="form-group col-lg-12">
                    <input
                      type="number"
                      v-model="formItem.item_price"
                      placeholder="Нэгж үнэ"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formItem.errors.has('item_price'),
                        },
                      ]"
                    />
                  </div>
                  <div class="form-group col-lg-12">
                    <b-form-datepicker
                      id="item_reg"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formItem.item_reg"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formItem.errors.has('item_reg'),
                        },
                      ]"
                      placeholder="Бүртгэсэн огноо"
                    ></b-form-datepicker>
                  </div>
                  <div class="form-group col-lg-12">
                    <b-form-datepicker
                      id="reg_date"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formItem.reg_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formItem.errors.has('reg_date'),
                        },
                      ]"
                      placeholder="Шилжүүлсэн огноо"
                    ></b-form-datepicker>
                  </div>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-success"
                v-show="!editItemMode"
                @click.prevent="createCapitalItem"
              >
                Бүртгэх
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editItemMode"
                @click.prevent="updateCapitalItem"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-dark"
                @click.prevent="cancelEditItem"
                v-show="editItemMode"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      allRoomUsers: [],
      allData: [],
      alldeleted: [],
      getSelectedItemImg: "",
      totalRowsAllData: 1,
      currentPageAllData: 1,
      perPageAllData: 200,
      totalRowsDeletedData: 1,
      currentPageDeletedData: 1,
      perPageDeletedData: 200,
      totalRowsCategoryData: 1,
      currentPageCategoryData: 1,
      perPageCategoryData: 200,
      totalRowsDeletedCategoryData: 1,
      currentPageDeletedCategoryData: 1,
      perPageDeletedCategoryData: 200,
      totalRowsItemData: 1,
      currentPageItemData: 1,
      perPageItemData: 200,
      totalRowsDeletedItemData: 1,
      currentPageDeletedItemData: 1,
      perPageDeletedItemData: 200,
      pageOptionsAllData: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      filterAllData: "",
      filterDeletedData: "",
      filterCategoryData: "",
      filterDeletedCategoryData: "",
      filterItemData: "",
      filterDeletedItemData: "",
      filterOnAllData: [],
      filterOnDeletedData: [],
      filterOnCategoryData: [],
      filterOnDeletedCategoryData: [],
      filterOnItemData: [],
      filterOnDeletedItemData: [],
      filterIgnoredFieldsAllData: ["items", "room_user"],
      filterIgnoredFieldsDeletedAllData: ["items", "room_user"],
      filterIgnoredFieldsItem: ["capital_category"],
      filterIgnoredFieldsDeletedItem: ["capital_category"],
      fieldsAllData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "room_full_name",
          label: "Харьяа өрөө",
          sortable: true,
        },
        {
          key: "teacher_name",
          label: "Хариуцагч",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },

        {
          key: "item_image",
          label: "Зураг",
          sortable: true,
        },
        {
          key: "item_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "item_code",
          label: "код",
          sortable: true,
        },
        {
          key: "serial_num",
          label: "serial number",
          sortable: true,
        },
        {
          key: "count",
          label: "Тоо ширхэг",
          sortable: true,
        },
        {
          key: "item_price",
          label: "Үнэ",
          sortable: true,
        },
        {
          key: "reg_date",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      fieldsDeletedAllData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "room_full_name",
          label: "Харьяа өрөө",
          sortable: true,
        },
        {
          key: "teacher_name",
          label: "Хариуцагч",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },

        {
          key: "item_image",
          label: "Зураг",
          sortable: true,
        },
        {
          key: "item_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "item_code",
          label: "код",
          sortable: true,
        },
        {
          key: "serial_num",
          label: "serial number",
          sortable: true,
        },
        {
          key: "count",
          label: "Тоо ширхэг",
          sortable: true,
        },
        {
          key: "item_price",
          label: "Үнэ",
          sortable: true,
        },
        {
          key: "reg_date",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      fieldsCategoryData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      fieldsDeletedCategoryData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      fieldsItemData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },

        {
          key: "item_image",
          label: "Зураг",
          sortable: true,
        },
        {
          key: "item_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "item_code",
          label: "Код",
          sortable: true,
        },
        {
          key: "item_count",
          label: "Тоо ширхэг",
          sortable: true,
        },
        {
          key: "item_price",
          label: "Үнэ",
          sortable: true,
        },
        {
          key: "item_reg",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "reg_date",
          label: "Шилжүүлсэн огноо",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      fieldsDeletedItemData: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ерөнхий нэршил",
          sortable: true,
        },
        {
          key: "status",
          label: "Ангилал",
          sortable: true,
        },

        {
          key: "item_image",
          label: "Зураг",
          sortable: true,
        },
        {
          key: "item_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "item_code",
          label: "Код",
          sortable: true,
        },
        {
          key: "item_count",
          label: "Тоо ширхэг",
          sortable: true,
        },
        {
          key: "item_price",
          label: "Үнэ",
          sortable: true,
        },
        {
          key: "item_reg",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
        {
          key: "reg_date",
          label: "Шилжүүлсэн огноо",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
      formUserItem: new Form({
        id: "",
        roomUserId: "",
        catItemId: "",
        serial_num: "",
        count: 0,
        reg_date: "",
      }),
      leftItemCount: 0,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formItemModalTitle: "Өрөө, танхим бүртгэх",
      oldCount: 0,
      //Өрөө танхимын хэсэг
      itemsDeleted: [],
      capitalCategory: [],
      deletedCategory: [],
      capitalItems: [],
      deletedItem: [],
      allItems: [],
      itemLoaded: true,
      editCategoryMode: false,
      editItemMode: false,
      editRoomUser: false,

      formCategory: new Form({
        id: "",
        name: "",
        status: "",
      }),
      formItem: new Form({
        id: "",
        cap_cat_id: "",
        item_name: "",
        item_code: "",
        item_count: 0,
        item_price: "",
        item_reg: "",
        reg_date: "",
        item_image: "",
      }),
      CatStatOptions: [{ text: "Хангамж" }, { text: "Үндсэн хөрөнгө" }],
      fileInputKey: 0,
    };
  },
  components: { Multiselect },
  methods: {
    // -------------------------- Нэгдсэн мэдээлэл ------------------------------------
    customRoomUserName({ room_full_name, my_user_info }) {
      // console.log(my_user_info);
      // return `${room_full_name}-д багш бүртгээгүй байна.`;
      if (my_user_info !== null) {
        return `${room_full_name}, ${
          my_user_info ? my_user_info.teacher_name : "эзэмшигч бүртгэгдээгүй."
        }`;
      } else {
        return `${room_full_name}-д багш бүртгээгүй байна.`;
      }
    },
    customCatItemName({ capital_category, item_name, item_count }) {
      return `${
        capital_category ? capital_category.name : "ангилал бүртгэгдээгүй"
      }, ${item_name}, Үлдэгдэл: ${item_count}`;
    },
    getAllItems() {
      this.allRoomUsers = [];
      this.allData = [];

      axios
        .get("/manager_vaa/getAllItems/")
        .then((response) => {
          this.allRoomUsers = response.data.allRoomUsers;
          // this.allRoomUsers.forEach((element) => {
          //   console.log(element.my_user_info.teacher_name);
          // });
          this.allData = response.data.allData;
          console.log(this.allData);
          this.totalRowsAllData = this.allData ? this.allData.length : 0;
          this.totalRowsDeletedData = this.alldeleted
            ? this.alldeleted.length
            : 0;
          this.alldeleted = response.data.alldeleted;
          this.itemLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    createRoomUserItem() {
      var roomUser = this.formUserItem.roomUserId;
      var catItemId = this.formUserItem.catItemId;
      this.formUserItem.roomUserId = this.formUserItem.roomUserId.id;
      this.formUserItem.catItemId = this.formUserItem.catItemId.id;
      if (this.formUserItem.count <= this.leftItemCount)
        this.formUserItem
          .post("/manager_vaa/createRoomUserItem")
          .then((res) => {
            if (res.data.errorMesg == "") {
              Swal.fire("Амжилттай", "Өрөөнд хөрөнгө бүртгэгдлээ", "success");
              this.formUserItem.reset();
              this.leftItemCount = 0;
              Fire.$emit("loadItems");
              this.cancelRoomUserItem();
              this.getAllItems();
            } else {
              Swal.fire(
                "Анхаар!",
                "Хөрөнгийн үлдэгдэл хүрэлцэхгүй байна!",
                "warning"
              );

              this.formUserItem.roomUserId = roomUser;
              this.formUserItem.catItemId = catItemId;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      else {
        Swal.fire(
          "Анхаар!",
          "Хөрөнгийн үлдэгдэл хүрэлцэхгүй байна!",
          "warning"
        );
        this.formUserItem.roomUserId = roomUser;
        this.formUserItem.catItemId = catItemId;
      }
    },
    editRoomUserItem(item) {
      this.editRoomUser = true;
      this.leftItemCount = 0;
      this.formUserItem.fill(item);
      this.formUserItem.roomUserId = item.room_user;
      this.formUserItem.catItemId = item.items;
      this.oldCount = this.formUserItem.count;
      this.getLeftItemCount();
      this.$bvModal.show("modal-roomUser");
    },
    getLeftItemCount() {
      axios
        .get(
          "/manager_vaa/getCapitalItemCount/" + this.formUserItem.catItemId.id
        )
        .then((response) => {
          this.leftItemCount = response.data.capitalItemCount;
          // console.log(this.leftItemCount);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    updateRoomUserItem() {
      var roomUser = this.formUserItem.roomUserId;
      var catItemId = this.formUserItem.catItemId;
      this.formUserItem.roomUserId = this.formUserItem.roomUserId.id;
      this.formUserItem.catItemId = this.formUserItem.catItemId.id;
      this.formUserItem;
      if (this.formUserItem.count <= this.leftItemCount + this.oldCount)
        this.formUserItem
          .put("/manager_vaa/updateRoomUserItem")
          .then((res) => {
            if (res.data.errorMesg == "") {
              Swal.fire("Амжилттай", "Өрөөнд хөрөнгө бүртгэгдлээ", "success");
              this.formUserItem.reset();
              this.leftItemCount = 0;
              Fire.$emit("loadItems");
              this.cancelRoomUserItem();
              this.getAllItems();
            } else
              Swal.fire(
                "Анхаар!",
                "Хөрөнгийн үлдэгдэл хүрэлцэхгүй байна!",
                "warning"
              );
            this.formUserItem.roomUserId = roomUser;
            this.formUserItem.catItemId = catItemId;
          })
          .catch((err) => {});
      else {
        Swal.fire(
          "Анхаар!",
          "Хөрөнгийн үлдэгдэл хүрэлцэхгүй байна!",
          "warning"
        );
        this.formUserItem.roomUserId = roomUser;
        this.formUserItem.catItemId = catItemId;
      }
    },
    cancelRoomUserItem() {
      this.leftItemCount = 0;
      this.oldCount = 0;
      this.editRoomUser = false;
      this.formUserItem.reset();
      this.$bvModal.hide("modal-create-item");
    },
    deleteRoomUserItem(itemId) {
      //   console.log(item);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Өрөө болон хөрөнгийн холболтыг салгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteRoomUserItem/" + itemId)
            .then((res) => {
              Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
              this.getAllItems();
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreRoomUserItem(alldeleted) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/restoreRoomUserItem/" + alldeleted.id)
            .then((res) => {
              if (!res.data.errorMesg) {
                Swal.fire(
                  "Сэргээлээ!",
                  "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                  "success"
                );
                this.getAllItems();
              } else Swal.fire("Алдаа гарлаа!", res.data.errorMesg, "error");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // --------------------------хөрөнгийн төрөл -----------------------------------
    getCapitalCategory() {
      axios
        .get("/manager_vaa/getCapitalCategory/")
        .then((response) => {
          this.capitalCategory = response.data.capitalCategory;
          this.deletedCategory = response.data.deletedCategory;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showCapitalCategory() {
      this.editCategoryMode = false;
      this.formCategory.reset();
      this.getCapitalCategory();
      this.$bvModal.show("modal-type");
    },
    createCapitalCategory() {
      this.formCategory
        .post("/manager_vaa/createCapitalCategory")
        .then((res) => {
          Fire.$emit("loadItems");
          this.formCategory.reset();
          this.getCapitalCategory();
          this.cancelCreateCategory();
        })
        .catch((err) => {});
    },
    editCapitalCategory(category) {
      this.editCategoryMode = true;
      this.formCategory.fill(category);
      this.$bvModal.show("modal-create-item");
    },
    updateCapitalCategory() {
      this.formCategory
        .put("/manager_vaa/updateCapitalCategory")
        .then((res) => {
          this.formCategory.reset();
          this.cancelEditCategory();
          this.getCapitalCategory();
        })
        .catch((err) => {});
    },
    cancelEditCategory() {
      this.cancelCreateCategory();
      this.formCategory.reset();
      this.editCategoryMode = false;
    },
    cancelCreateCategory() {
      this.formCategory.reset();
      this.$bvModal.hide("modal-create-item");
    },

    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },

    deleteCapitalCategory(categoryId) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Ангилалыг системээс устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteCapitalCategory/" + categoryId)
            .then((res) => {
              if (!res.data.errorMesg) {
                Swal.fire(
                  "Устгасан!",
                  "Ангилалыг системээс устгалаа.",
                  "success"
                );
                this.getCapitalCategory();
              } else Swal.fire("Алдаа гарлаа!", res.data.errorMesg, "error");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreCategory(category) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/restoreCategory/" + category.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Ангилалыг системд буцаан сэргээлээ.",
                "success"
              );
              this.getCapitalCategory();
              Fire.$emit("loadItems");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // ------------------------------ эд, хөрөнгө -----------------------------------------
    getCapitalItem() {
      axios
        .get("/manager_vaa/getCapitalItem/")
        .then((response) => {
          this.capitalItems = response.data.capitalItems;
          this.capitalItems.forEach((element) => {
            // console.log(element);
          });
          this.deletedItem = response.data.deletedItem;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showCapitalItem() {
      this.fileInputKey++;
      this.formItem.reset();
      this.getCapitalItem();
    },

    createCapitalItem() {
      var capCat = this.formItem.cap_cat_id;
      this.formItem.cap_cat_id = this.formItem.cap_cat_id.id;
      if (this.formItem.cap_cat_id) {
        this.formItem
          .post("/manager_vaa/createCapitalItem")
          .then((res) => {
            Swal.fire("Амжилттай", "Өрөөнд хөрөнгө бүртгэгдлээ", "success");
            this.formItem.reset();
            this.fileInputKey++;
            this.getSelectedItemImg = "";
            this.leftItemCount = 0;
            Fire.$emit("loadItems");
            this.cancelRoomUserItem();
            this.getCapitalItem();
          })
          .catch((err) => {});
      } else {
        Swal.fire("Анхаар!", "Нэр төрөл сонгоогүй байна", "warning");
        this.formItem.cap_cat_id = capCat;
      }
    },
    getItemImg(e) {
      let itemImage = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(itemImage);
      reader.onload = (e) => {
        this.formItem.item_image = e.target.result;
      };
      this.loaded = true;
    },
    editCapitalItem(item) {
      this.fileInputKey++;
      this.editItemMode = true;
      this.formItem.fill(item);
      this.formItem.cap_cat_id = item.capital_category;
      this.getSelectedItemImg = item.item_image;
      this.formItem.item_image = item.item_image;
      this.$bvModal.show("modal-create-item");
    },
    updateCapitalItem() {
      var capCat = this.formItem.cap_cat_id;
      this.formItem.cap_cat_id = this.formItem.cap_cat_id.id;
      if (this.formItem.cap_cat_id) {
        this.formItem
          .put("/manager_vaa/updateCapitalItem")
          .then((res) => {
            this.fileInputKey++;
            this.formItem.reset();
            this.getSelectedItemImg = "";
            this.cancelEditItem();
            this.getCapitalItem();
            this.formItem.cap_cat_id = capCat;
          })
          .catch((err) => {});
      } else {
        Swal.fire("Анхаар!", "Нэр төрөл сонгоогүй байна", "warning");
        this.formItem.cap_cat_id = capCat;
      }
    },
    cancelEditItem() {
      this.fileInputKey++;
      this.formItem.reset();
      this.getSelectedItemImg = "";
      this.cancelCreateItem();
      this.editItemMode = false;
    },
    cancelCreateItem() {
      this.formItem.reset();
      this.$bvModal.hide("modal-create-item");
    },
    deleteCapitalItem(itemId) {
      //   console.log(item);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Хөрөнгө устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteCapitalItem/" + itemId)
            .then((res) => {
              if (!res.data.errorMesg) {
                Swal.fire(
                  "Устгасан!",
                  "Хөрөнгийг системээс устгалаа.",
                  "success"
                );
                this.getCapitalItem();
              } else Swal.fire("Алдаа гарлаа!", res.data.errorMesg, "error");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreCapitalItem(itemId) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/restoreCapitalItem/" + itemId.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Хөрөнгийг системд буцаан сэргээлээ.",
                "success"
              );
              this.getCapitalItem();
              Fire.$emit("loadItems");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    customPhysicalName({ name, status }) {
      return `${name}, ${status}`;
    },
    onFilteredAllData(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAllData = filteredItems.length;
      this.currentPageAllData = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredDeletedData(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDeletedData = filteredItems.length;
      this.currentPageDeletedData = 1;
    },
    onFilteredCategoryData(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDeletedData = filteredItems.length;
      this.currentPageDeletedData = 1;
    },
    onFilteredDeletedCategoryData(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDeletedData = filteredItems.length;
      this.currentPageDeletedData = 1;
    },
    onFilteredItemData(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsItemData = filteredItems.length;
      this.currentPageItemData = 1;
    },
    onFilteredDeletedItemData(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDeletedItemData = filteredItems.length;
      this.currentPageDeletedItemData = 1;
    },
  },
  created() {
    this.getCapitalCategory();
    this.getCapitalItem();
    this.getAllItems();
    Fire.$on("loadItems", () => {
      this.getAllItems();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
