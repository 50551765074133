<template>
  <div id="chart">
    <apexchart
      height="200"
      type="line"
      :options="myChartOptions"
      :series="myChartSeries"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: ["title", "nameArr", "indexArr", "schoolAvgArr", "classAvgArr"],
  data() {
    return {
      myChartOptions: {
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "",
          align: "left",
          style: {
            fontWeight: "normal",
            color: "#263238",
          },
        },
        colors: ["#775DD0", "#feb019", "#2E93fA"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
      },
      myChartSeries: [
        {
          name: "Сургуулийн дундаж",
          data: [],
        },
        {
          name: "Ангийн дундаж",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
    };
  },
  methods: {},
  created() {
    this.myChartOptions.xaxis.categories = this.nameArr;
    this.myChartOptions.title.text = this.title;
    this.myChartSeries[0].data = this.schoolAvgArr;
    this.myChartSeries[1].data = this.classAvgArr;
    this.myChartSeries[2].data = this.indexArr;
    this.myChartSeries[2].name = this.title;
  },
};
</script>

<style scoped>
</style>
