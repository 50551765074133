<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <multiselect
          v-model="yearId"
          deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
          track-by="id"
          label="id"
          :custom-label="customYearName"
          placeholder="Хичээлийн жилээр шүүж харах"
          :options="years"
          :searchable="true"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong
              >{{ option.start_date | dateYear }}-{{
                option.finish_date | dateYear
              }}
              - хичээлийн жил
            </strong></template
          >
        </multiselect>
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <!-- <li class="nav-item">
                            <a class="nav-link" href="#inactive" data-toggle="tab">Устгагдсан</a>
                        </li> -->
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Ирсэн ба явуулсан баримт бичгийн бүртгэл</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="font-size: 80%" class="float-center">
                    <b>Бүртгэгдсэн баримт бичгийн тоо:</b>
                    {{
                      cameAndSentDocuments.length
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Шүүсэн баримт бичгийн тоо:</b>
                    {{ filterCameAndSentDocuments }}
                  </span>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addCameAndSentDocument()"
                    >Бичиг баримт бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  ></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <b-overlay :show="cameAndSentDocumentLoaded" rounded="sm">
                    <b-row>
                      <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
</b-form-select>

<b-form-select v-model="sortDesc" :disabled="!sortBy" :aria-describedby="ariaDescribedby" size="sm" class="w-25">
    <option :value="false">Asc</option>
    <option :value="true">Desc</option>
</b-form-select>
</b-input-group>
</b-form-group>
</b-col> -->
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageCameAndSentDocuments"
                            :options="pageOptionsCameAndSentDocuments"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterCameAndSentDocuments"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterCameAndSentDocuments"
                                @click="filterCameAndSentDocuments = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageCameAndSentDocuments"
                      :total-rows="totalRowsCameSentDocsStatuses"
                      :per-page="perPageCameAndSentDocuments"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="cameAndSentDocuments"
                      :fields="fieldsActiveCameAndSentDocuments"
                      :current-page="currentPageCameAndSentDocuments"
                      :per-page="perPageCameAndSentDocuments"
                      style="font-size: 80%"
                      :filter-ignored-fields="
                        filterIgnoredFieldsCameAndSentDocuments
                      "
                      :filter="filterCameAndSentDocuments"
                      :filter-included-fields="filterOnPunishments"
                      @filtered="onFilteredDocuments"
                    >
                      <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(came_or_sent)="data">
                        <b-badge
                          v-if="data.item.came_or_sent == 1"
                          variant="secondary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          Явуулсан
                        </b-badge>
                        <b-badge
                          v-else
                          variant="warning"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          Ирүүлсэн
                        </b-badge>
                      </template>
                      <template #cell(my_emp_mig_info_avsan)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{
                            data.item.my_emp_mig_info_avsan.my_employee_info
                              .teacher_last_name[0]
                          }}.
                          {{
                            data.item.my_emp_mig_info_avsan.my_employee_info
                              .teacher_name
                          }}
                        </b-badge>
                      </template>
                      <template #cell(my_came_and_files)="data">
                        <p v-if="data.item.my_came_and_files.length != 0">
                          <span
                            v-for="(file, i) in data.item.my_came_and_files"
                            :key="file.id"
                          >
                            {{ i + 1 }}.
                            <a
                              href="#"
                              v-b-toggle.sidebar-file
                              @click="viewFile(file.came_sent_url)"
                            >
                              {{ file.came_sent_url }}
                            </a>

                            <b-icon
                              v-if="userInfo.name == 'cipalprin'"
                              icon="x-octagon"
                              variant="danger"
                              @click="deleteCameAndSentDocumentFile(file)"
                            ></b-icon>
                            <br />
                          </span>
                        </p>
                        <p v-if="data.item.my_came_and_files.length == 0">
                          <small class="text-muted">Файл хавсаргаагүй!</small>
                        </p>
                        <b-badge
                          variant="light"
                          role="button"
                          font-scale="1.6"
                          @click="uploadFiles(data.item.id)"
                        >
                          Файл нэмэх
                          <b-icon icon="upload" variant="success"></b-icon>
                        </b-badge>
                      </template>

                      <template #cell(my_dep_info)="data">
                        <b-badge
                          variant="primary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.my_dep_info.department_code }}
                        </b-badge>
                      </template>
                      <template #cell(respondIs)="data">
                        <b-badge
                          variant="success"
                          class="text-wrap"
                          style="width: 8rem"
                          v-if="data.item.respondIs === 1"
                        >
                          Тийм
                        </b-badge>
                        <b-badge
                          variant="danger"
                          class="text-wrap"
                          style="width: 8rem"
                          v-else
                        >
                          Үгүй
                        </b-badge>
                      </template>

                      <template #cell(created_at)="data">
                        {{ data.item.created_at | dateYearMonthDay }}
                      </template>
                      <template #cell(actions)="data">
                        <div>
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editCameAndSentDocument(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            v-if="userInfo.name == 'cipalprin'"
                            href="#"
                            class="badge bg-danger"
                            @click="deleteCameAndSentDocument(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane" id="inactive">
                            <div class="card">
                                <div class="card-header">
                                    <strong>Боловсруулах бичиг баримт</strong>
                                    <div class="
                      main-header
                      navbar navbar-expand navbar-white navbar-light
                    ">
                                        <h3 class="card-title" style="font-size: 80%">
                                            Нийт бичиг баримтын тоо:
                                            {{ punishmensDeleted.length }}
                                        </h3>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <b-overlay :show="cameAndSentDocumentLoaded" rounded="sm">
                                        <b-row>
                                            <b-col lg="12" class="my-1">
                                                <b-form-group label="Нэг хуудсанд харуулах тоо"
                                                    label-for="per-page-inactive-select" label-cols-sm="6"
                                                    label-cols-md="4" label-cols-lg="3" label-align-sm="right"
                                                    label-size="sm" class="mb-0">
                                                    <b-form-select id="per-page-inactive-select" v-model="perPageInactiveRooms
                                            " :options="pageOptionsInactiveDocumentStatus
                                            " size="sm"></b-form-select>
                                                </b-form-group>

                                            </b-col>
                                        </b-row>
                                        <b-pagination v-model="currentPageInactiveRooms" :total-rows="totalRowsInactiveDocumentStatuses
                                            " :per-page="perPageInactiveRooms" align="fill" size="sm"
                                            class="my-0"></b-pagination>
                                        <b-table hover :items="punishmensDeleted" :fields="fieldsInactiveDocumentStatus
                                            " :current-page="currentPageInactiveRooms
                                            " :per-page="perPageInactiveRooms">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template #cell(doc_name)="data">
                                                <b-badge variant="info">
                                                    {{ data.item.doc_name }}
                                                </b-badge>
                                            </template>
                                            <template #cell(doc_description)="data">
                                                <b-badge variant="secondary">
                                                    {{
                                            data.item
                                                .doc_description
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(my_doc_owner)="data">
                                                <b-badge variant="secondary">
                                                    {{
                                            data.item.my_doc_owner
                                                .teacher_name
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(doc_approve_date)="data">
                                                <b-badge variant="secondary">
                                                    {{
                                            data.item
                                                .doc_approve_date
                                            | dateYearMonthDay
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(my_doc_department)="data">
                                                <b-badge variant="secondary">
                                                    {{
                                            data.item
                                                .my_doc_department
                                                .department_code
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(my_doc_proccess)="data">
                                                <b-badge variant="success" v-for="(process,
                                            index) in data.item
                                                .my_doc_proccess" :key="process.id">
                                                    {{ index + 1 }}. Хувилбар:
                                                    {{
                                            process.doc_proc_version_number
                                        }}. Төлөв:
                                                    {{
                                                process
                                                    .my_doc_proc_status
                                                    .name
                                            }}. (Санал:
                                                    {{
                                                process.my_create_emp
                                                    .teacher_name
                                            }})
                                                </b-badge>
                                            </template>
                                            <template #cell(created_at)="data">
                                                <b-badge>
                                                    {{
                                            data.item.created_at
                                            | dateYearMonthDay
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(updated_at)="data">
                                                <b-badge>
                                                    {{
                                            data.item.updated_at
                                            | dateYearMonthDay
                                        }}
                                                </b-badge>
                                            </template>
                                            <template #cell(actions)="data">
                                                <a v-if="userInfo.pivot
                                            .model_id ==
                                            data.item
                                                .doc_owner_id ||
                                            userInfo.name ==
                                            'cipalprin'
                                            " href="#" class="badge bg-warning" @click="
                                            restoreCameAndSentDocument(
                                                data.item
                                            )
                                            "><i class="fas fa-trash-restore-alt"></i></a>
                                            </template>
                                        </b-table>
                                    </b-overlay>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>

      <!--  Ирсэн ба явсан бичиг баримтын бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-came-sent"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="
          !editModeCameAndSentDocsStatus
            ? formDocModalTitle
            : 'Ирсэн ба явсан бичиг баримтын засах'
        "
        class="modal fade"
        hide-footer
        @hidden="cancelEdit"
      >
        <div class="modal-body">
          <div class="card-body form-responsive p-0">
            <form
              @submit.prevent="
                !editModeCameAndSentDocsStatus
                  ? createCameAndSentDocument()
                  : updateCameAndSentDocument()
              "
            >
              <div class="row">
                <div class="form-group col-lg-12">
                  <!-- v-if="userInfo.name == 'cipalprin'" -->
                  <multiselect
                    v-model="form.year_id"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="customYearName"
                    placeholder="Хичээлийн жил сонгоно уу?"
                    :options="years"
                    :searchable="true"
                    :allow-empty="true"
                  >
                    <!-- @input="filterByMonthED" -->
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.start_date | dateYear }}-{{
                          option.finish_date | dateYear
                        }}
                        - хичээлийн жил
                      </strong></template
                    >
                  </multiselect>
                </div>
                <div class="form-group col-lg-4">
                  <label>Ирсэн эсвэл явуулсан бичиг баримт эсэх:</label>
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-came_or_sent"
                      v-model="form.came_or_sent"
                      name="checkbox-came_or_sent"
                      value="1"
                      unchecked-value="0"
                    >
                      Ирсэн бол сонгохгүй, явуулсан бол сонгоно
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="form-group col-lg-4">
                  <label>Сар, өдөр:</label>
                  <b-form-datepicker
                    id="doc_date"
                    :state="false"
                    size="sm"
                    today-button
                    label-today-button="Өнөөдөр"
                    reset-button
                    label-reset-button="Шинэчлэх"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    v-model="form.doc_date"
                    class="mb-2"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('doc_date'),
                      },
                    ]"
                    placeholder="Сар, өдөр"
                  ></b-form-datepicker>
                  <has-error :form="form" field="doc_date"></has-error>
                </div>
                <div class="form-group col-lg-4">
                  <label>Дугаар</label>
                  <b-form-input
                    id="type-dugaar"
                    type="number"
                    v-model="form.dugaar"
                  ></b-form-input>
                </div>
                <div class="form-group col-lg-6">
                  <label>Хаанаас, хэнээс; Хаана, хэнд...</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.from_and_whom"
                    placeholder="Хаанаас, хэнээс; Хаана, хэнд..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <div class="form-group col-lg-6">
                  <label>Товч утга</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.tovch_utga"
                    placeholder="Товч утга..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <div class="form-group col-lg-6">
                  <label>Хуудасны тоо</label>
                  <b-form-input
                    id="type-number"
                    type="number"
                    v-model="form.page_numbers"
                  ></b-form-input>
                </div>
                <div class="form-group col-lg-6">
                  <label>Картын дугаар</label>
                  <b-form-input
                    id="type-number"
                    type="number"
                    v-model="form.card_number"
                  ></b-form-input>
                </div>
                <div class="form-group col-lg-6">
                  <label>Хүлээн авсан ажилтан:</label>
                  <multiselect
                    v-model="form.came_sent_emp_mig_id"
                    :multiple="false"
                    deselect-label="Can't remove this value"
                    :custom-label="nameEmployees"
                    track-by="id"
                    label="name"
                    placeholder="Хүлээн авсан ажилтан"
                    :options="getAllEmployees"
                    :searchable="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :preserve-search="true"
                    :hide-selected="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.emp_teacher_last_name[0] }}.
                        {{ option.emp_teacher_name }}</strong
                      >
                      сонгогдлоо
                    </template>
                  </multiselect>
                </div>

                <div class="form-group col-lg-6">
                  <label>Аль нэгжээс ирүүлсэн:</label>
                  <multiselect
                    v-model="form.department_id"
                    :multiple="false"
                    deselect-label="Can't remove this value"
                    :custom-label="nameDepartment"
                    track-by="id"
                    label="name"
                    placeholder="Аль нэгжээс ирүүлсэн"
                    :options="allDepartments"
                    :searchable="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :preserve-search="true"
                    :hide-selected="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.department_name }}({{
                          option.department_code
                        }})</strong
                      >
                      сонгогдлоо
                    </template>
                  </multiselect>
                </div>
                <div class="form-group col-lg-4">
                  <label>Хариутай эсэх:</label>
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-respondIs"
                      v-model="form.respondIs"
                      name="checkbox-respondIs"
                      value="1"
                      unchecked-value="0"
                    >
                      Хариугүй бол сонгохгүй, хариутай бол сонгоно.
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="form-group col-lg-4">
                  <label>Хариу ирүүлсэн сар, өдөр:</label>
                  <b-form-datepicker
                    id="respond_date"
                    :state="false"
                    size="sm"
                    today-button
                    label-today-button="Өнөөдөр"
                    reset-button
                    label-reset-button="Шинэчлэх"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    v-model="form.respond_date"
                    class="mb-2"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('respond_date'),
                      },
                    ]"
                    placeholder="Сар, өдөр"
                  ></b-form-datepicker>
                  <has-error :form="form" field="respond_date"></has-error>
                </div>

                <div class="form-group col-lg-4">
                  <label>Ирүүлсэн хариуны дугаар</label>
                  <b-form-input
                    id="type-number"
                    type="number"
                    v-model="form.respond_number"
                  ></b-form-input>
                </div>
                <div class="form-group col-lg-6">
                  <label>Ирүүлсэн хариуны товч утга...</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.respond_tovch_utga"
                    placeholder="Ирүүлсэн хариуны товч утга..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <div class="form-group col-lg-6">
                  <label>Тайлбар...</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder="Тайлбар..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>

              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="!editModeCameAndSentDocsStatus"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editModeCameAndSentDocsStatus"
                >
                  Засах
                </button>
                <button
                  class="btn btn-warning"
                  v-show="editModeCameAndSentDocsStatus"
                  @click.prevent="cancelEdit"
                >
                  Цуцлах
                </button>
                <button
                  class="btn btn-warning"
                  v-show="!editModeCameAndSentDocsStatus"
                  @click.prevent="cancelCreate"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
          <!-- <div class="row">
                        <div class="col-lg-12 mt-2">
                        </div>
                    </div> -->
        </div>
      </b-modal>
      <!--  Ирсэн ба явсан бичиг баримтын  файл хавсаргах -->
      <b-modal
        size="xl"
        id="modal-create-came-sent-files"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        title="Файл хавсаргах"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModePunishmentFiles">
                    Файл бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editModePunishmentFiles">
                    Файл засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="createCameAndSentDocumentFile()"
                    enctype="multipart/form-data"
                  >
                    <div class="card-body">
                      <b-form-group
                        label="Файлаар нь хавсаргах:"
                        label-cols-sm="2"
                        label-size="sm"
                      >
                        <input
                          type="file"
                          name="filename"
                          class="form-control"
                          id="inputFileUpload"
                          placeholder="Файл хавсаргах:"
                          v-on:change="selectFiles"
                        />
                      </b-form-group>
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-primary">
                        Хадгалах
                      </button>

                      <button
                        class="btn btn-warning"
                        @click.prevent="cancelEditFiles"
                      >
                        Цуцлах
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Бичиг баримт харуулах хэсэг -->
      <b-sidebar
        title="Бичиг баримтууд"
        id="sidebar-file"
        bg-variant="dark"
        text-variant="light"
        right
        z-index="1039"
        shadow
        width="60%"
        backdrop
      >
        <div class="px-3 py-2 text-center">
          <embed
            v-if="docType === 'pdf'"
            :src="pdfValue"
            width="100%"
            class="vh-100"
          />
          <VueDocPreview v-else :value="docValue" type="office" />
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
import VueDocPreview from "vue-doc-preview";

export default {
  data() {
    return {
      docValue: "",
      pdfValue: "",
      docType: "",
      showFileInfo: {
        info: "",
      },
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formDocModalTitle: "Ирсэн ба явсан бичиг баримтын бүртгэх",

      //Бүртгэлтэй Ирсэн ба явсан бичиг баримтын хэсэг
      cameAndSentDocuments: [],
      allDepartments: [],
      punishmensDeleted: [],
      getAllEmployees: [],

      cameAndSentDocumentLoaded: true,
      editModeCameAndSentDocsStatus: false,
      editModePunishmentFiles: false,

      //b-table тохиргоо
      totalRowsCameSentDocsStatuses: 1,
      currentPageCameAndSentDocuments: 1,
      perPageCameAndSentDocuments: 25,
      pageOptionsCameAndSentDocuments: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCameAndSentDocuments: "",
      filterOnPunishments: [],
      filteredPunishmentsCount: 0,
      filterIgnoredFieldsCameAndSentDocuments: [
        // "my_doc_proccess",
        // "my_doc_owner",
        // "my_doc_department"
        // "my_doc_classify_item",
      ],

      fieldsActiveCameAndSentDocuments: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "came_or_sent",
          label: "Ирсэн ба Явуулсан",
          sortable: true,
        },
        {
          key: "my_came_and_files",
          label: "Бичиг баримтууд",
          sortable: true,
        },

        {
          key: "doc_date",
          label: "Хүлээн авсан огноо",
          sortable: true,
        },
        {
          key: "dugaar",
          label: "Дугаар",
          sortable: true,
        },
        {
          key: "from_and_whom",
          label: "Хаанаас, хэнээс; Хаана, хэнд",
          sortable: true,
        },
        {
          key: "tovch_utga",
          label: "Товч утга",
          sortable: true,
        },
        {
          key: "page_numbers",
          label: "Хуудасны тоо",
          sortable: true,
        },
        {
          key: "card_number",
          label: "Картын дугаар",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "my_emp_mig_info_avsan",
          label: "Хүлээн авсан ажилтан",
          sortable: true,
        },
        {
          key: "my_dep_info",
          label: "Аль нэгжээс ирүүлсэн",
          sortable: true,
        },
        {
          key: "respondIs",
          label: "Хариутай эсэх",
          sortable: true,
        },
        {
          key: "respond_date",
          label: "Хариу ирсэн огноо",
          sortable: true,
        },

        {
          key: "respond_number",
          label: "Хариуны дугаар",
          sortable: true,
        },
        {
          key: "respond_tovch_utga",
          label: "Ирсэн хариуны товч утга",
          sortable: true,
        },
        {
          key: "description",
          label: "Тайлбар",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      // totalRowsInactiveDocumentStatuses: 1,
      // currentPageInactiveRooms: 1,
      // perPageInactiveRooms: 25,
      // pageOptionsInactiveDocumentStatus: [
      //     1,
      //     2,
      //     3,
      //     4,
      //     5,
      //     10,
      //     15,
      //     25,
      //     35,
      //     45,
      //     55,
      //     65,
      //     75,
      //     100,
      //     125,
      //     150,
      //     175,
      //     200,
      //     { value: 300, text: "Илүү олноор харах" }
      // ],
      // //   sortByInactive: "",
      // //   sortDescInactive: false,
      // //   sortDirectionInactive: "asc",
      // filterInactiveRooms: "",
      // filterOnInactiveRooms: [],
      // fieldsInactiveDocumentStatus: [
      //     {
      //         key: "index",
      //         label: "№",
      //         sortable: true
      //     },
      //     {
      //         key: "doc_name",
      //         label: "Бичиг баримтын нэр",
      //         sortable: true
      //     },
      //     {
      //         key: "doc_description",
      //         label: "Бичиг баримтын тухай",
      //         sortable: true
      //     },
      //     {
      //         key: "my_doc_owner",
      //         label: "Эзэмшигч",
      //         sortable: true
      //     },
      //     {
      //         key: "doc_approve_date",
      //         label: "Батлах огноо",
      //         sortable: true
      //     },
      //     {
      //         key: "my_doc_department",
      //         label: "Харьяа баг",
      //         sortable: true
      //     },
      //     {
      //         key: "my_doc_proccess",
      //         label: "Саналууд",
      //         sortable: true
      //     },
      //     {
      //         key: "created_at",
      //         label: "Үүсгэсэн",
      //         sortable: true
      //     },
      //     {
      //         key: "updated_at",
      //         label: "Зассан",
      //         sortable: true
      //     },
      //     {
      //         key: "actions",
      //         label: "Үйлдлүүд",
      //         sortable: false
      //     }
      // ],

      form: new Form({
        id: "",
        year_id: null,
        school_id: null,
        came_or_sent: 0,
        reg_emp_mig_id: "",
        up_emp_mig_id: "",
        doc_date: "",
        dugaar: "",
        from_and_whom: "",
        tovch_utga: "",
        page_numbers: "",
        card_number: "",
        came_sent_emp_mig_id: "",
        department_id: "",
        respondIs: 0,
        respond_date: "",
        respond_number: "",
        respond_tovch_utga: "",
        description: "",
      }),

      // Файл хадгалах
      formFile: new Form({
        id: "",
        file: "",
        url: "",
      }),
      // editorOption: {
      //     // Some Quill options...
      //     modules: {
      //         //   theme: "snow",
      //         toolbar: [
      //             [{ size: [] }],
      //             [{ header: [1, 2, 3, 4, 5, 6, false] }],
      //             ["bold", "italic", "underline", "strike"],
      //             [{ color: [] }, { background: [] }],
      //             [{ script: "super" }, { script: "sub" }],
      //             [
      //                 { header: "1" },
      //                 { header: "2" },
      //                 "blockquote",
      //                 "code-block"
      //             ],
      //             [
      //                 { list: "ordered" },
      //                 { list: "bullet" },
      //                 { indent: "-1" },
      //                 { indent: "+1" }
      //             ],
      //             [{ direction: "rtl" }, { align: [] }],
      //             ["link", "image", "video", "formula"],
      //             ["clean"]
      //         ],
      //         imageCompress: {
      //             quality: 0.6, // default
      //             maxWidth: 600, // default
      //             // maxHeight: 1000, // default
      //             imageType: ["image/jpeg", "image/png"], // default
      //             debug: false, // default
      //             suppressErrorLogging: false, // default
      //             insertIntoEditor: undefined // default
      //         },
      //         imageDrop: true,
      //         imageResize: {
      //             modules: ["Resize", "DisplaySize", "Toolbar"],
      //             handleStyles: {
      //                 backgroundColor: "black",
      //                 border: "none"
      //                 //   color: white,
      //                 // other camelCase styles for size display
      //             },
      //             displayStyles: {
      //                 backgroundColor: "black",
      //                 border: "none"
      //                 //   color: white,
      //                 // other camelCase styles for size display
      //             }
      //         }
      //     }
      // }
      yearId: null,
    };
  },
  components: { Multiselect, VueDocPreview },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
    years: function () {
      return this.$store.state.years;
    },
  },
  watch: {
    yearId: function (newVal, oldVal) {
      this.getAllCameAndSentDocument();
    },
  },
  methods: {
    viewFile(url) {
      // teacher, date, host_type
      // console.log("view");
      // console.log(host_type);
      // console.log(url);
      // this.showFileInfo.info = "Ажилтан: " + teacher + " | Огноо:" + date;
      // this.showFileInfo.info = url;
      var typeArr = url.split(".");
      this.docType = typeArr[typeArr.length - 1];
      // console.log(this.docType)
      if (this.docType == "pdf") {
        this.pdfValue = url;
      } else this.docValue = "grading4you.com" + url;

      // console.log(this.docValue)
    },
    percentVote(plus, minus) {
      //   console.log(plus, minus);
      let niilber = plus.length + minus.length;

      let huve = (plus.length / niilber) * 100;
      return huve.toFixed(1);
    },

    getAllCameAndSentDocument() {
      this.cameAndSentDocumentLoaded = true;
      axios
        .post("/hr/getAllCameAndSentDocument", {
          yearId: this.yearId, //!= "" ? this.yearId : 0
        })
        .then((res) => {
          this.cameAndSentDocuments = res.data.cameAndSentDocuments;
          this.allDepartments = res.data.allDepartments;
          this.getAllEmployees = res.data.getAllEmployees;
          // this.punishmensDeleted = res.data.punishmensDeleted;
          // this.mySchoolDepartments = res.data.mySchoolDepartments;
          // this.documentStatuses = res.data.documentStatuses;
          // this.documentClassifyItems = res.data.documentClassifyItems;
          this.totalRowsCameSentDocsStatuses = this.cameAndSentDocuments
            ? this.cameAndSentDocuments.length
            : 0;
          // this.totalRowsInactiveDocumentStatuses = this
          //     .punishmensDeleted
          //     ? this.punishmensDeleted.length
          //     : 0;
          this.cameAndSentDocumentLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //b-table тохирго
    onFilteredDocuments(filteredItems) {
      //   console.log(filteredItems);
      this.filteredPunishmentsCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCameSentDocsStatuses = filteredItems.length;
      this.currentPageCameAndSentDocuments = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveDocumentStatuses = filteredItems.length;
      this.currentPageInactiveRooms = 1;
    },

    addCameAndSentDocument() {
      this.form.reset();
      this.$bvModal.show("modal-create-came-sent");
    },
    createCameAndSentDocument() {
      //   console.log("create");
      this.form
        .post("/hr/createCameAndSentDocument")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadCameAndSentDocument");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editCameAndSentDocument(document) {
      // console.log(document);
      //exams_teacher_id, block_name,
      this.editModeCameAndSentDocsStatus = true;
      this.form.fill(document);
      this.getAllEmployees.forEach((element) => {
        if ((element.emp_mig_id = document.my_emp_mig_info_avsan.id)) {
          this.form.came_sent_emp_mig_id = element;
        }
      });
      this.allDepartments.forEach((element) => {
        if ((element.id = document.department_id)) {
          this.form.department_id = element;
        }
      });
      this.years.forEach((element) => {
        // console.log(element.id)
        if (element.id == document.year_id) {
          this.form.year_id = element;
        }
      });

      this.$bvModal.show("modal-create-came-sent");
    },
    updateCameAndSentDocument() {
      //   console.log("udpate");
      this.form
        .put("/hr/updateCameAndSentDocument")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadCameAndSentDocument");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeCameAndSentDocsStatus = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-came-sent");
    },

    deleteCameAndSentDocument(punishment) {
      // console.log(punishment)
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн сургуультай хамаарал бүхий хэрэглэгчид ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteCameAndSentDocument/" + punishment.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Ажилтны Ирсэн ба явсан бичиг баримтын йг устгалаа.",
                "success"
              );
              Fire.$emit("loadCameAndSentDocument");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreCameAndSentDocument(document) {
      // console.log(document);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/restoreCameAndSentDocument/" + document.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadCameAndSentDocument");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    // Файлтай ажиллах хэсэг
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} файл сонгогдлоо`;
    },
    uploadFiles(id) {
      // console.log(id);
      this.formFile.reset();
      this.formFile.id = id;
      this.$bvModal.show("modal-create-came-sent-files");
    },
    selectFiles(e) {
      // console.log(e)
      this.formFile.file = e.target.files[0];
    },
    createCameAndSentDocumentFile() {
      this.$bvModal.hide("modal-create-came-sent-files");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formFile.file);
      axios
        .post(
          "/hr/createCameAndSentDocumentFile/" + this.formFile.id,
          formData,
          config
        )
        .then(function (response) {
          if (response.data.errorMsj != "")
            Swal.fire("Алдаа гарлаа!", response.data.errorMsj, "warning");
          else {
            Fire.$emit("loadCameAndSentDocument");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
          }
        });
      //   this.formFile.post("/hr/createCameAndSentDocumentFile").then((res) => {});
    },
    cancelCreateFiles() {
      this.formFile.reset();
      this.$bvModal.hide("modal-create-came-sent-files");
    },
    cancelEditFiles() {
      this.cancelCreateFiles();
      this.editModePunishmentFiles = false;
    },

    deleteCameAndSentDocumentFile(file) {
      // console.log(file);
      Swal.fire({
        title: "Та итгэлтэй байна уу? Файл устгах гэж байна.",
        text: "Файлыг устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteCameAndSentDocumentFile/" + file.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Файлыг системээс устгалаа.", "success");
              Fire.$emit("loadCameAndSentDocument");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    nameDocDep({ department_name }) {
      return `${department_name}`;
    },
    nameDocClassifyItem({ doc_classify_item_name }) {
      return `${doc_classify_item_name}`;
    },
    // Бүх ажилчид
    nameEmployees({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    // Ирсэн ба явсан бичиг баримтын йн төрөл
    nameDepartment({ department_name, department_code }) {
      return `${department_name}(${department_code})`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  created() {
    this.getAllCameAndSentDocument();
    Fire.$on("loadCameAndSentDocument", () => {
      this.getAllCameAndSentDocument();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}

.cardFile {
  border-width: 1px;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-baqh {
  text-align: center;
  vertical-align: top;
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
