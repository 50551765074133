<template>
  <div class="card">
    <!-- Багш нарын шилжилт хөдөлгөөн -->
    <div class="card-header">
      <h5 class="card-title">
        <b>Элсэлт ба шилжилт хөдөлгөөн (Багш, ажилчид)</b>
      </h5>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <multiselect
            v-model="school_year_id2"
            select-label="Сонгох"
            selected-label="Сонгогдсон"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жил сонгоно уу?"
            :options="years"
            :searchable="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <!-- /.col -->
          <div class="row mt-2" v-if="viewYearChartTeach">
            <!-- Цогцолбор -->
            <div class="info-box mb-3">
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Цогцолбор(Жилээр)</strong
                >
                <apexchart
                  type="donut"
                  height="350"
                  :options="optionsYearAllTeach"
                  :series="seriesAllEmployee"
                >
                </apexchart>
              </div>
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Цогцолбор(Жилээр)</strong
                >
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsYearAllTeachRad"
                  :series="seriesAllTeachRad"
                >
                </apexchart>
              </div>
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Ажлаас гарсан шалтгаан</strong
                >
                <apexchart
                  type="polarArea"
                  :options="optionsLeaveType"
                  :series="seriesLeaveType"
                >
                </apexchart>
              </div>
            </div>
            <!-- Багш -->
            <b-button
              v-if="viewYearChartTeach"
              @click.prevent="getTeachMigData()"
              block
              variant="outline-primary"
              >Багш нарын шилжилт хөдөлгөөн</b-button
            >
            <div class="col-12 col-sm-12" v-if="viewTeachMigData">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center">Багш</strong>
                      <apexchart
                        type="donut"
                        :options="optionsYear"
                        :series="seriesTeach"
                      >
                      </apexchart>
                      <div class="text-center">
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesTeach[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Ажлаас чөлөөлөгдсөн
                          <b-badge variant="light" pill>{{
                            seriesTeach[1]
                          }}</b-badge>
                        </b-badge>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsYearTeachRad"
                        :series="seriesTeachRad"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Ажлаас гарсан шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeTeach"
                        :series="seriesLeaveTypeTeach"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-12">
                      <strong class="info-box-text mt-4 text-center">
                        Мэргэжлийн баг тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsTeacherType"
                        :series="seriesTeacherType"
                      >
                      </apexchart>
                      <b-button
                        v-if="viewYearChartTeach && !viewTeachLeave"
                        @click.prevent="getLeaveTeach()"
                        block
                        variant="outline-primary"
                        >Багш нарын дэлгэрэнгүйг харах</b-button
                      >
                      <div
                        class="card card-success collapsed-card"
                        v-if="viewTeachLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Багш нар</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(teachActivelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="teachActive in teachActivelist"
                              :key="teachActive.id"
                            >
                              <tr>
                                <td class="text-center" tabindex="10">
                                  <img
                                    v-if="teachActive.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="teachActive.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{ teachActive.teacher_last_name[0] }}.{{
                                    teachActive.teacher_name
                                  }}
                                </td>

                                <td class="text-center" tabindex="10">
                                  {{ teachActive.department_name }}
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{
                                    teachActive.created_at | dateYearMonthDay
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="card card-danger collapsed-card"
                        v-if="viewTeachLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Чөлөөлөгдсөн</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(teachLeavelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="teachLeave in teachLeavelist"
                              :key="teachLeave.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="teachLeave.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="teachLeave.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ teachLeave.teacher_last_name[0] }}
                                  {{ teachLeave.teacher_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ teachLeave.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ teachLeave.deleted_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <foreign-teach
                        v-if="viewYearChartTeach"
                        :year_id="school_year_id2"
                      ></foreign-teach>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Бусад -->
            <b-button
              v-if="viewYearChartTeach"
              @click.prevent="getEmpMigData()"
              block
              variant="outline-primary"
              >Бусад ажилтан шилжилт хөдөлгөөн</b-button
            >
            <div class="col-12 col-sm-12" v-if="viewEmpMigData">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Бусад ажилтан</strong
                      >
                      <div class="text-center">
                        <apexchart
                          :options="optionsYear"
                          :series="seriesEmployee"
                        ></apexchart>
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesEmployee[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Ажлаас чөлөөлөгдсөн
                          <b-badge variant="light" pill>{{
                            seriesEmployee[1]
                          }}</b-badge>
                        </b-badge>
                        <!-- <b-badge variant="primary" pill>
                                    Тухайн жил ирсэн
                                    <b-badge variant="light" pill>{{
                                      seriesEmployeeType[2]
                                    }}</b-badge>
                                  </b-badge> -->
                        <!-- <b-badge variant="warning" pill>
                                    Зөрүү
                                    <b-badge variant="light" pill>{{
                                      yEmployeeDiff
                                    }}</b-badge>
                                  </b-badge> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsYearEmployeeRad"
                        :series="seriesEmployeeRad"
                      ></apexchart>
                    </div>
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Ажлаас гарсан шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeEmployee"
                        :series="seriesLeaveTypeEmployee"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-12">
                      <strong class="info-box-text mt-4 text-center">
                        Мэргэжил тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsEmployeeType"
                        :series="seriesEmployeeType"
                      >
                      </apexchart>
                      <b-button
                        v-if="viewYearChartTeach && !viewEmployeeLeave"
                        @click.prevent="getLeaveEmployee()"
                        block
                        variant="outline-primary"
                        >Ажилчдыг харах</b-button
                      >
                      <div
                        class="card card-success collapsed-card"
                        v-if="viewEmployeeLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(employeeActivelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="employeeActive in employeeActivelist"
                              :key="employeeActive.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="
                                      employeeActive.profile_photo_url != null
                                    "
                                    class="direct-chat-img"
                                    :src="employeeActive.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ employeeActive.teacher_last_name[0] }}.{{
                                    employeeActive.teacher_name
                                  }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ employeeActive.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{
                                    employeeActive.created_at | dateYearMonthDay
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="card card-danger collapsed-card"
                        v-if="viewEmployeeLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(employeeLeavelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="employeeLeave in employeeLeavelist"
                              :key="employeeLeave.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="
                                      employeeLeave.profile_photo_url != null
                                    "
                                    class="direct-chat-img"
                                    :src="employeeLeave.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ employeeLeave.teacher_last_name[0] }}.{{
                                    employeeLeave.teacher_name
                                  }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ employeeLeave.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{
                                    employeeLeave.deleted_at | dateYearMonthDay
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Удирдлага -->
            <b-button
              v-if="viewYearChartTeach"
              @click.prevent="getManagementMigData()"
              block
              variant="outline-primary"
              >Удирдлагын ажилтан шилжилт хөдөлгөөн</b-button
            >
            <div class="col-12 col-sm-12" v-if="viewManagementMigData">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Удирдлагын алба</strong
                      >
                      <div class="text-center">
                        <apexchart
                          :options="optionsYear"
                          :series="seriesManagement"
                        >
                        </apexchart>
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesManagement[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Ажлаас чөлөөлөгдсөн
                          <b-badge variant="light" pill>{{
                            seriesManagement[1]
                          }}</b-badge>
                        </b-badge>
                        <!-- <b-badge variant="primary" pill>
                                    Тухайн жил ирсэн
                                    <b-badge variant="light" pill>{{
                                      seriesManagementType[2]
                                    }}</b-badge>
                                  </b-badge> -->
                        <!-- <b-badge variant="warning" pill>
                                    Зөрүү
                                    <b-badge variant="light" pill>{{
                                      yManagementDiff
                                    }}</b-badge>
                                  </b-badge> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsYearManagRad"
                        :series="seriesManagRad"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Ажлаас гарсан шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeManag"
                        :series="seriesLeaveTypeManag"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-12">
                      <strong class="info-box-text mt-4 text-center">
                        Алба тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsManagementType"
                        :series="seriesManagementType"
                      ></apexchart>
                      <b-button
                        v-if="viewYearChartTeach && !viewManagLeave"
                        @click.prevent="getLeaveManag()"
                        block
                        variant="outline-primary"
                        >Ажилчдыг харах</b-button
                      >
                      <div
                        class="card card-success collapsed-card"
                        v-if="viewManagLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(managActivelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="managActive in managActivelist"
                              :key="managActive.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="managActive.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="managActive.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ managActive.teacher_last_name[0] }}.{{
                                    managActive.teacher_name
                                  }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ managActive.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{
                                    managActive.created_at | dateYearMonthDay
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="card card-danger collapsed-card"
                        v-if="viewManagLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Чөлөөлөгдсөн ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(managLeavelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="managLeave in managLeavelist"
                              :key="managLeave.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="managLeave.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="managLeave.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ managLeave.teacher_last_name[0] }}.{{
                                    managLeave.teacher_name
                                  }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ managLeave.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ managLeave.deleted_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Цэцэрлэг -->
            <b-button
              v-if="viewYearChartTeach"
              @click.prevent="getKgMigData()"
              block
              variant="outline-primary"
              >Цэцэрлэгийн ажилтан шилжилт хөдөлгөөн</b-button
            >
            <div class="col-12 col-sm-12" v-if="viewKgMigData">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Цэцэрлэг</strong
                      >
                      <apexchart
                        type="donut"
                        :options="optionsYear"
                        :series="seriesKg"
                      >
                      </apexchart>
                      <div class="text-center">
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesKg[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Ажлаас чөлөөлөгдсөн
                          <b-badge variant="light" pill>{{
                            seriesKg[1]
                          }}</b-badge>
                        </b-badge>
                        <!-- <b-badge variant="primary" pill>
                                    Тухайн жил ирсэн
                                    <b-badge variant="light" pill>{{
                                      seriesTeacherType[2]
                                    }}</b-badge>
                                  </b-badge> -->
                        <!-- <b-badge variant="warning" pill>
                                    Зөрүү
                                    <b-badge variant="light" pill>{{
                                      yTeachDiff
                                    }}</b-badge>
                                  </b-badge> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsYearKgRad"
                        :series="seriesKgRad"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Ажлаас гарсан шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeKg"
                        :series="seriesLeaveTypeKg"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-12">
                      <strong class="info-box-text mt-4 text-center">
                        Танхим тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsKgType"
                        :series="seriesKgType"
                      ></apexchart>
                      <b-button
                        v-if="viewYearChartTeach && !viewKgLeave"
                        @click.prevent="getLeaveKg()"
                        block
                        variant="outline-primary"
                        >Aжилчдыг харах</b-button
                      >
                      <div
                        class="card card-success collapsed-card"
                        v-if="viewKgLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(kgActivelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="kgActive in kgActivelist"
                              :key="kgActive.id"
                            >
                              <tr>
                                <td class="text-center" tabindex="10">
                                  <img
                                    v-if="kgActive.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="kgActive.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{ kgActive.teacher_last_name[0] }}.{{
                                    kgActive.teacher_name
                                  }}
                                </td>

                                <td class="text-center" tabindex="10">
                                  {{ kgActive.department_name }}
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{ kgActive.created_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="card card-danger collapsed-card"
                        v-if="viewKgLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Чөлөөлөгдсөн</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(kgLeavelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="kgLeave in kgLeavelist"
                              :key="kgLeave.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="kgLeave.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="kgLeave.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ kgLeave.teacher_last_name[0] }}
                                  {{ kgLeave.teacher_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ kgLeave.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ kgLeave.deleted_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Спорт клубын багш, ажилчид -->
            <b-button
              v-if="viewYearChartTeach"
              @click.prevent="getSportClubMigData()"
              block
              variant="outline-primary"
              >Спорт клубын ажилтан шилжилт хөдөлгөөн</b-button
            >
            <div class="col-12 col-sm-12" v-if="viewSportClubMigData">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Спорт клубын багш, ажилчид</strong
                      >
                      <apexchart
                        type="donut"
                        :options="optionsSportClubTeacher"
                        :series="seriesSportClub"
                      >
                      </apexchart>
                      <div class="text-center">
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesSportClub[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Ажлаас чөлөөлөгдсөн
                          <b-badge variant="light" pill>{{
                            seriesSportClub[1]
                          }}</b-badge>
                        </b-badge>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsSportClubRad"
                        :series="seriesSportClubRad"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-4">
                      <strong class="info-box-text text-center"
                        >Ажлаас гарсан шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeSportClub"
                        :series="seriesLeaveTypeSportClub"
                      >
                      </apexchart>
                    </div>
                    <div class="col-12 col-sm-12">
                      <strong class="info-box-text mt-4 text-center">
                        Танхим тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsTypeSportClub"
                        :series="seriesTypeSportClub"
                      ></apexchart>
                      <b-button
                        v-if="viewYearChartTeach && !viewSportClubLeave"
                        @click.prevent="getLeaveSportClub()"
                        block
                        variant="outline-primary"
                        >Aжилчдыг харах</b-button
                      >
                      <!-- this.sportClubActivelist
                        this.sportClubLeavelist  -->
                      <div
                        class="card card-success collapsed-card"
                        v-if="viewSportClubLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Ажилчид</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(sportClubActivelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="clubActive in sportClubActivelist"
                              :key="clubActive.id"
                            >
                              <tr>
                                <td class="text-center" tabindex="10">
                                  <img
                                    v-if="clubActive.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="clubActive.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{ clubActive.teacher_last_name[0] }}.{{
                                    clubActive.teacher_name
                                  }}
                                </td>

                                <td class="text-center" tabindex="10">
                                  {{ clubActive.department_name }}
                                </td>
                                <td class="text-center" tabindex="10">
                                  {{ clubActive.created_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="card card-danger collapsed-card"
                        v-if="viewSportClubLeave"
                      >
                        <div class="card-header">
                          <h3 class="card-title">Чөлөөлөгдсөн</h3>
                          <div class="card-tools">
                            <span class="badge badge-warning">{{
                              Object.keys(sportClubLeavelist).length
                            }}</span>
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0 table-responsive">
                          <table class="table table-bordered">
                            <thead style="font-size: 100%">
                              <tr role="row" class="text-center">
                                <th>Зураг</th>
                                <th>Овог нэр</th>
                                <th>Мэргэжлийн баг</th>
                                <th>Огноо</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="clubLeave in sportClubLeavelist"
                              :key="clubLeave.id"
                            >
                              <tr>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  <img
                                    v-if="clubLeave.profile_photo_url != null"
                                    class="direct-chat-img"
                                    :src="clubLeave.profile_photo_url"
                                    alt="user-avatar"
                                  />
                                  <img
                                    v-else
                                    class="direct-chat-img"
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                  />
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ clubLeave.teacher_last_name[0] }}
                                  {{ clubLeave.teacher_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ clubLeave.department_name }}
                                </td>
                                <td
                                  class="text-center"
                                  tabindex="10"
                                  padding="150px"
                                >
                                  {{ clubLeave.deleted_at | dateYearMonthDay }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Ажилчдын хаяг байршил харах -->
          <b-button
            v-if="viewYearChartTeach"
            @click.prevent="getDist()"
            block
            variant="outline-primary"
            >Ажилчдын хаяг байршил харах</b-button
          >
          <div class="row" v-if="isDistView">
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistAllEmp"
                      :series="seriesDistAllEmp"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistAllEmpP"
                      :series="seriesDistAllEmpP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Багш нар харах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      height="330"
                      class="barChart"
                      :options="optionsDistTeach"
                      :series="seriesDistTeach"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistTeachP"
                      :series="seriesDistTeachP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="teachDist in distTeach"
                    :key="teachDist.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ teachDist.district }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          teachDist.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="teachDistDetal in distTeachDetal"
                          :key="teachDistDetal.id"
                        >
                          <tr
                            v-if="teachDist.district == teachDistDetal.district"
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="teachDistDetal.profile_photo_url != null"
                                class="direct-chat-img"
                                :src="teachDistDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachDistDetal.teacher_last_name[0] }}.{{
                                teachDistDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachDistDetal.district }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewDistTeach"
                    @click.prevent="getDistTeachDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Ажилчид харах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistEmployee"
                      :series="seriesDistEmployee"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistEmployeeP"
                      :series="seriesDistEmployeeP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="employeeDist in distEmployee"
                    :key="employeeDist.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ employeeDist.district }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          employeeDist.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="employeeDistDetal in distEmployeeDetal"
                          :key="employeeDistDetal.id"
                        >
                          <tr
                            v-if="
                              employeeDist.district ==
                              employeeDistDetal.district
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  employeeDistDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="employeeDistDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeDistDetal.teacher_last_name[0] }}.{{
                                employeeDistDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeDistDetal.district }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewDistEmployee"
                    @click.prevent="getDistEmployeeDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Удирдлага харах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistManag"
                      :series="seriesDistManag"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistManagP"
                      :series="seriesDistManagP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="managDist in distManag"
                    :key="managDist.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ managDist.district }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          managDist.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="managDistDetal in distManagDetal"
                          :key="managDistDetal.id"
                        >
                          <tr
                            v-if="managDist.district == managDistDetal.district"
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="managDistDetal.profile_photo_url != null"
                                class="direct-chat-img"
                                :src="managDistDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managDistDetal.teacher_last_name[0] }}.{{
                                managDistDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managDistDetal.district }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewDistManag"
                    @click.prevent="getDistManagDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Цэцэрлэг харах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчид хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistKg"
                      :series="seriesDistKg"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчид хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistKgP"
                      :series="seriesDistKgP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="kgDist in distKg"
                    :key="kgDist.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ kgDist.district }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          kgDist.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="kgDistDetal in distKgDetal"
                          :key="kgDistDetal.id"
                        >
                          <tr v-if="kgDist.district == kgDistDetal.district">
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="kgDistDetal.profile_photo_url != null"
                                class="direct-chat-img"
                                :src="kgDistDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgDistDetal.teacher_last_name[0] }}.{{
                                kgDistDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgDistDetal.district }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewDistKg"
                    @click.prevent="getDistKgDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Ажилчдын хүйсийн харьцаа харах -->
          <b-button
            v-if="viewYearChartTeach"
            @click.prevent="getGender()"
            block
            variant="outline-primary"
            >Ажилчдын хүйсийн харьцаа харах</b-button
          >
          <div class="row" v-if="isGenderView">
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Хүйсийн харьцаа (Сургуулийн бүх ажилчид)</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderAllEmp"
                      :series="seriesGenderAllEmp"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Хүйсийн харьцаа (Сургуулийн бүх ажилчид)</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsGenderAllEmpP"
                      :series="seriesGenderAllEmpP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Багш нар харуулах -->
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderTeach"
                      :series="seriesGenderTeach"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsGenderTeachP"
                      :series="seriesGenderTeachP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Ажилчид харуулах -->
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderEmployee"
                      :series="seriesGenderEmployee"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsGenderEmployeeP"
                      :series="seriesGenderEmployeeP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Удирдлага харуулах -->
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderManag"
                      :series="seriesGenderManag"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsGenderManagP"
                      :series="seriesGenderManagP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Цэцэрлэг харуулах -->
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderKg"
                      :series="seriesGenderKg"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsGenderKgP"
                      :series="seriesGenderKgP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Ажилчдын боловсролын зэрэг харах -->
          <b-button
            v-if="viewYearChartTeach"
            @click.prevent="getBolZer()"
            block
            variant="outline-primary"
            >Ажилчдын боловсролын зэрэг харах</b-button
          >
          <div class="row" v-if="isBolZerView">
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsBolZerAllEmp"
                      :series="seriesBolZerAllEmp"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsBolZerAllEmpP"
                      :series="seriesBolZerAllEmpP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Багш нар харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsBolZerTeach"
                      :series="seriesBolZerTeach"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsBolZerTeachP"
                      :series="seriesBolZerTeachP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="teachBolZer in bolZerTeach"
                    :key="teachBolZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ teachBolZer.deed_bol }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          teachBolZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="teachBolZerDetal in bolZerTeachDetal"
                          :key="teachBolZerDetal.id"
                        >
                          <tr
                            v-if="
                              teachBolZer.deed_bol == teachBolZerDetal.deed_bol
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  teachBolZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="teachBolZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachBolZerDetal.teacher_last_name[0] }}.{{
                                teachBolZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachBolZerDetal.deed_bol }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewBolZerTeach"
                    @click.prevent="getBolZerTeachDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Ажилчид харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsBolZerEmployee"
                      :series="seriesBolZerEmployee"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsBolZerEmployeeP"
                      :series="seriesBolZerEmployeeP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="employeeBolZer in bolZerEmployee"
                    :key="employeeBolZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ employeeBolZer.deed_bol }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          employeeBolZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="employeeBolZerDetal in bolZerEmployeeDetal"
                          :key="employeeBolZerDetal.id"
                        >
                          <tr
                            v-if="
                              employeeBolZer.deed_bol ==
                              employeeBolZerDetal.deed_bol
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  employeeBolZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="employeeBolZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeBolZerDetal.teacher_last_name[0] }}.{{
                                employeeBolZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeBolZerDetal.deed_bol }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewBolZerEmployee"
                    @click.prevent="getBolZerEmployeeDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Удирдлага харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsBolZerManag"
                      :series="seriesBolZerManag"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsBolZerManagP"
                      :series="seriesBolZerManagP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="managBolZer in bolZerManag"
                    :key="managBolZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ managBolZer.deed_bol }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          managBolZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="managBolZerDetal in bolZerManagDetal"
                          :key="managBolZerDetal.id"
                        >
                          <tr
                            v-if="
                              managBolZer.deed_bol == managBolZerDetal.deed_bol
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  managBolZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="managBolZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managBolZerDetal.teacher_last_name[0] }}.{{
                                managBolZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managBolZerDetal.deed_bol }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewBolZerManag"
                    @click.prevent="getBolZerManagDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>

            <!-- Удирдлага харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsBolZerKg"
                      :series="seriesBolZerKg"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэгийн ажилчдын боловсролын зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsBolZerKgP"
                      :series="seriesBolZerKgP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="kgBolZer in bolZerKg"
                    :key="kgBolZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ kgBolZer.deed_bol }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          kgBolZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="kgBolZerDetal in bolZerKgDetal"
                          :key="kgBolZerDetal.id"
                        >
                          <tr
                            v-if="kgBolZer.deed_bol == kgBolZerDetal.deed_bol"
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="kgBolZerDetal.profile_photo_url != null"
                                class="direct-chat-img"
                                :src="kgBolZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgBolZerDetal.teacher_last_name[0] }}.{{
                                kgBolZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgBolZerDetal.deed_bol }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewBolZerKg"
                    @click.prevent="getBolZerKgDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Ажилчдын мэргэжлийн зэрэг харах -->
          <b-button
            v-if="viewYearChartTeach"
            @click.prevent="getMerZer()"
            block
            variant="outline-primary"
            >Ажилчдын мэргэжлийн зэрэг харах</b-button
          >
          <div class="row" v-if="isMerZerView">
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsMerZerAllEmp"
                      :series="seriesMerZerAllEmp"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsMerZerAllEmpP"
                      :series="seriesMerZerAllEmpP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Багш нар харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsMerZerTeach"
                      :series="seriesMerZerTeach"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Багш нарын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsMerZerTeachP"
                      :series="seriesMerZerTeachP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="teachMerZer in merZerTeach"
                    :key="teachMerZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ teachMerZer.teacher_bol_zer }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          teachMerZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="teachMerZerDetal in merZerTeachDetal"
                          :key="teachMerZerDetal.id"
                        >
                          <tr
                            v-if="
                              teachMerZer.teacher_bol_zer ==
                              teachMerZerDetal.teacher_bol_zer
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  teachMerZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="teachMerZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachMerZerDetal.teacher_last_name[0] }}.{{
                                teachMerZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ teachMerZerDetal.teacher_bol_zer }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewMerZerTeach"
                    @click.prevent="getMerZerTeachDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Ажилчид харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsMerZerEmployee"
                      :series="seriesMerZerEmployee"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Ажилчдын мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsMerZerEmployeeP"
                      :series="seriesMerZerEmployeeP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="employeeMerZer in merZerEmployee"
                    :key="employeeMerZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ employeeMerZer.teacher_bol_zer }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          employeeMerZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="employeeMerZerDetal in merZerEmployeeDetal"
                          :key="employeeMerZerDetal.id"
                        >
                          <tr
                            v-if="
                              employeeMerZer.teacher_bol_zer ==
                              employeeMerZerDetal.teacher_bol_zer
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  employeeMerZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="employeeMerZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeMerZerDetal.teacher_last_name[0] }}.{{
                                employeeMerZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ employeeMerZerDetal.teacher_bol_zer }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewMerZerEmployee"
                    @click.prevent="getMerZerEmployeeDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Удирдлагууд харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsMerZerManag"
                      :series="seriesMerZerManag"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Удирдлагын алба мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsMerZerManagP"
                      :series="seriesMerZerManagP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="managMerZer in merZerManag"
                    :key="managMerZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ managMerZer.teacher_bol_zer }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          managMerZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="managMerZerDetal in merZerManagDetal"
                          :key="managMerZerDetal.id"
                        >
                          <tr
                            v-if="
                              managMerZer.teacher_bol_zer ==
                              managMerZerDetal.teacher_bol_zer
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="
                                  managMerZerDetal.profile_photo_url != null
                                "
                                class="direct-chat-img"
                                :src="managMerZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managMerZerDetal.teacher_last_name[0] }}.{{
                                managMerZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ managMerZerDetal.teacher_bol_zer }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewMerZerManag"
                    @click.prevent="getMerZerManagDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Цэцэрлэг харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэг мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="bar"
                      height="320"
                      :options="optionsMerZerKg"
                      :series="seriesMerZerKg"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Цэцэрлэг мэргэжлийн зэрэг</strong
                    >
                    <apexchart
                      type="donut"
                      height="360"
                      class="pieChart"
                      :options="optionsMerZerKgP"
                      :series="seriesMerZerKgP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <div
                    class="card card-secondary collapsed-card"
                    v-for="kgMerZer in merZerKg"
                    :key="kgMerZer.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        {{ kgMerZer.teacher_bol_zer }}
                      </h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          kgMerZer.total
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 100%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Дүүрэг</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="kgMerZerDetal in merZerKgDetal"
                          :key="kgMerZerDetal.id"
                        >
                          <tr
                            v-if="
                              kgMerZer.teacher_bol_zer ==
                              kgMerZerDetal.teacher_bol_zer
                            "
                          >
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              <img
                                v-if="kgMerZerDetal.profile_photo_url != null"
                                class="direct-chat-img"
                                :src="kgMerZerDetal.profile_photo_url"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgMerZerDetal.teacher_last_name[0] }}.{{
                                kgMerZerDetal.teacher_name
                              }}
                            </td>
                            <td
                              class="text-center"
                              tabindex="10"
                              padding="150px"
                            >
                              {{ kgMerZerDetal.teacher_bol_zer }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-button
                    v-if="viewYearChartTeach && !viewMerZerKg"
                    @click.prevent="getMerZerKgDetal()"
                    block
                    variant="outline-primary"
                    >Ажилчдыг харах</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <citizen
            v-if="viewYearChartTeach"
            :year_id="school_year_id2"
          ></citizen>
          <school-year-report
            v-if="viewYearChartTeach"
            :year_id="school_year_id2"
          ></school-year-report>
          <state-year-report
            v-if="viewYearChartTeach"
            :year_id="school_year_id2"
          ></state-year-report>
          <edu-year-report
            v-if="viewYearChartTeach"
            :year_id="school_year_id2"
          ></edu-year-report>
          <pro-year-report
            v-if="viewYearChartTeach"
            :year_id="school_year_id2"
          ></pro-year-report>
        </div>
      </div>
      <!-- /.row -->
    </div>
  </div>
  <!--/. container-fluid -->
  <!-- Цэс-->
</template>

<script>
import Multiselect from "vue-multiselect";
import schoolYearReport from "../workYear/schoolYearReport.vue";
import stateYearReport from "../workYear/stateYearReport.vue";
import eduYearReport from "../workYear/eduYearReport.vue";
import proYearReport from "../workYear/proYearReport.vue";
import citizen from "../workYear/citizenship.vue";
import foreignTeach from "../../Report/employeeMigration/foreignTeach.vue";
export default {
  data() {
    return {
      myAllInfos: [],
      iconMode: "fa fa-bars",
      btnColor: "warning",
      teachertypes: [],
      school_year_id2: "",
      selectedDepTeach: "",
      viewYearChartTeach: false,
      seriesTeach: [0],
      seriesEmployee: [0],
      seriesManagement: [0],
      seriesKg: [0],
      optionsYear: {
        labels: ["Идэвхтэй", "Шилжсэн"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      yTeachDiff: 0,
      yEmployeeDiff: 0,
      yManagementDiff: 0,
      isDistView: false,
      viewDistTeach: false,
      viewTeachMigData: false,
      viewEmpMigData: false,
      viewManagementMigData: false,
      viewKgMigData: false,
      viewSportClubMigData: false,
      viewDistEmployee: false,
      viewDistManag: false,
      viewDistKg: false,
      isGenderView: false,
      isBolZerView: false,
      viewBolZerTeach: false,
      viewBolZerEmployee: false,
      viewBolZerManag: false,
      viewBolZerKg: false,
      isMerZerView: false,
      viewMerZerTeach: false,
      viewMerZerEmployee: false,
      viewMerZerManag: false,
      viewMerZerKg: false,
      teachActivelist: [],
      teachLeavelist: [],
      employeeActivelist: [],
      employeeLeavelist: [],
      managActivelist: [],
      managLeavelist: [],
      kgActivelist: [],
      kgLeavelist: [],
      sportClubActivelist: [],
      sportClubLeavelist: [],
      distTeach: [],
      distEmployee: [],
      distManag: [],
      distKg: [],
      distTeachDetal: [],
      distEmployeeDetal: [],
      distManagDetal: [],
      distKgDetal: [],
      bolZerTeach: [],
      bolZerEmployee: [],
      bolZerManag: [],
      bolZerKg: [],
      bolZerTeachDetal: [],
      bolZerEmployeeDetal: [],
      bolZerManagDetal: [],
      bolZerKgDetal: [],
      merZerTeach: [],
      merZerEmployee: [],
      merZerManag: [],
      merZerKg: [],
      merZerTeachDetal: [],
      merZerEmployeeDetal: [],
      merZerManagDetal: [],
      merZerKgDetal: [],
      viewTeachLeave: false,
      viewEmployeeLeave: false,
      viewManagLeave: false,
      viewKgLeave: false,
      viewSportClubLeave: false,

      allEmployeeNew: "",
      allEmployeeLeave: "",
      allEmployeeActive: "",
      yAllEmployeeDiff: 0,

      seriesAllClass: [0],
      seriesAllClassRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesAllTeachRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesTeachRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesEmployeeRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesManagRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesKgRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
      seriesAllEmployee: [0],
      // optionsYearAll: {
      //   labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
      //   chart: {
      //     type: "donut",
      //   },
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      //   plotOptions: {
      //     pie: {
      //       expandOnClick: true,
      //       donut: {
      //         labels: {
      //           show: true,
      //           name: { show: true },
      //           value: { show: true },
      //           total: {
      //             show: true,
      //             label: "Нийт",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      optionsYearAllTeach: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsYearAllRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYearAllTeachRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYearTeachRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYearEmployeeRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYearManagRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYearKgRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      seriesTeacherType: [
        {
          name: "Идэвхтэй",
          data: [],
        },
        {
          name: "Ажлаас чөлөөлөгдсөн",
          data: [],
        },
      ],
      seriesEmployeeType: [
        {
          name: "Идэвхтэй",
          data: [],
        },
        {
          name: "Ажлаас чөлөөлөгдсөн",
          data: [],
        },
      ],
      seriesManagementType: [
        {
          name: "Идэвхтэй",
          data: [],
        },
        {
          name: "Ажлаас чөлөөлөгдсөн",
          data: [],
        },
      ],
      seriesKgType: [
        {
          name: "Идэвхтэй",
          data: [],
        },
        {
          name: "Ажлаас чөлөөлөгдсөн",
          data: [],
        },
      ],
      optionsEmployeeType: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsManagementType: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsKgType: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsTeacherType: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsTeacher: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      // Ажлаас гарсан шатгаан
      seriesLeaveType: [],
      optionsLeaveType: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      seriesLeaveTypeTeach: [],
      optionsLeaveTypeTeach: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      seriesLeaveTypeEmployee: [],
      optionsLeaveTypeEmployee: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      seriesLeaveTypeManag: [],
      optionsLeaveTypeManag: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      seriesLeaveTypeKg: [],
      optionsLeaveTypeKg: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      //Ажилчдыг хаяг байршлаар харах
      optionsDistAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsDistTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsDistEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsDistManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsDistKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesDistAllEmpP: [],
      seriesDistTeachP: [],
      seriesDistEmployeeP: [],
      seriesDistManagP: [],
      seriesDistKgP: [],
      optionsDistAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      //Ажилчдын хүйсийн харьцаа харах
      optionsGenderAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsGenderTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsGenderEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsGenderManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsGenderKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      seriesGenderKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesGenderAllEmpP: [],
      seriesGenderTeachP: [],
      seriesGenderEmployeeP: [],
      seriesGenderManagP: [],
      seriesGenderKgP: [],
      optionsGenderAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsGenderTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsGenderEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsGenderManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsGenderKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      //Ажилчдын боловсролын зэрэг харах
      optionsBolZerAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBolZerAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsBolZerTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBolZerTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsBolZerEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBolZerEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsBolZerManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBolZerManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsBolZerKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBolZerKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesBolZerAllEmpP: [],
      seriesBolZerTeachP: [],
      seriesBolZerEmployeeP: [],
      seriesBolZerManagP: [],
      seriesBolZerKgP: [],
      optionsBolZerAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsBolZerTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsBolZerEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsBolZerManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsBolZerKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      //Ажилчдын мэрэгжлийн зэрэг харах
      optionsMerZerAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesMerZerAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsMerZerTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesMerZerTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsMerZerEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesMerZerEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsMerZerManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesMerZerManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsMerZerKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesMerZerKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesMerZerAllEmpP: [],
      seriesMerZerTeachP: [],
      seriesMerZerEmployeeP: [],
      seriesMerZerManagP: [],
      seriesMerZerKgP: [],
      optionsMerZerAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsMerZerTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsMerZerEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsMerZerManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsMerZerKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },

      //   Дүүрэг шалгах
      aimagOptions: [],
      sumOptions: [],
      checkStudents: [],
      distName: [],

      // Спорт клубын багш нар
      optionsSportClubTeacher: {
        labels: ["Идэвхтэй", "Шилжсэн"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesSportClub: [0],

      optionsSportClubRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      seriesSportClubRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],

      seriesLeaveTypeSportClub: [],
      optionsLeaveTypeSportClub: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      optionsTypeSportClub: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#28a745", "#bf1010", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesTypeSportClub: [
        {
          name: "Идэвхтэй",
          data: [],
        },
        {
          name: "Ажлаас чөлөөлөгдсөн",
          data: [],
        },
      ],
      // viewYearChartTeach: false,
      // viewKgLeave: false,
    };
  },
  components: {
    schoolYearReport: schoolYearReport,
    stateYearReport: stateYearReport,
    eduYearReport: eduYearReport,
    proYearReport: proYearReport,
    citizen: citizen,
    foreignTeach: foreignTeach,
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  watch: {
    school_year_id2: function (newVal, oldVal) {
      this.viewYearChartTeach = false;
      this.seriesAllTeachRad[0].data = [];
      this.optionsYearAllTeach.labels = [];

      if (newVal != "") {
        axios
          .post("/manager/getYearTeacherData", {
            year: newVal.id,
          })
          .then((res) => {
            this.allEmployeeNew = res.data.allEmployeeNew;
            this.allEmployeeLeave = res.data.allEmployeeLeave;
            this.allEmployeeActive = res.data.allEmployeeActive;
            this.yAllEmployeeDiff =
              res.data.allEmployeeNew - res.data.allEmployeeLeave;

            this.optionsYearAllTeach.labels.push(
              "Ажилчид: " + res.data.employeeActive
            );
            this.optionsYearAllTeach.labels.push(
              "Багш нар: " + res.data.teacherActive
            );
            this.optionsYearAllTeach.labels.push(
              "Удирдлагын алба: " + res.data.managementActive
            );
            this.optionsYearAllTeach.labels.push(
              "Цэцэрлэг: " + res.data.kgActive
            );
            this.optionsYearAllTeach.labels.push(
              "Клубын ажилчид: " + res.data.sportClubActive
            );
            this.optionsYearAllTeach.labels.push(
              "Жирэмсэн ба бусад: " + res.data.employeeOtherActive
            );
            this.seriesAllEmployee[0] = res.data.teacherActive;
            this.seriesAllEmployee[1] = res.data.employeeActive;
            this.seriesAllEmployee[2] = res.data.managementActive;
            this.seriesAllEmployee[3] = res.data.kgActive;
            this.seriesAllEmployee[4] = res.data.sportClubActive;
            this.seriesAllEmployee[5] = res.data.employeeOtherActive;

            //all employee % seriesAllTeachRad
            this.seriesAllTeachRad[0].data.push(100).toFixed(1);
            this.seriesAllTeachRad[0].data.push(
              (
                (res.data.allEmployeeNew /
                  (res.data.allEmployeeActive + res.data.employeeOtherActive)) *
                100
              ).toFixed(1)
            );
            this.seriesAllTeachRad[0].data.push(
              (
                (res.data.allEmployeeLeave /
                  (res.data.allEmployeeActive + res.data.employeeOtherActive)) *
                100
              ).toFixed(1)
            );
            this.seriesAllTeachRad[0].data.push(
              (
                (res.data.allEmployeeLeave /
                  (res.data.allEmployeeActive +
                    res.data.employeeOtherActive +
                    res.data.allEmployeeLeave)) *
                100
              ).toFixed(1)
            );
            this.optionsYearAllTeachRad.xaxis.categories[0] =
              "Идэвхтэй: " +
              (res.data.allEmployeeActive + res.data.employeeOtherActive);
            this.optionsYearAllTeachRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.allEmployeeNew;
            this.optionsYearAllTeachRad.xaxis.categories[2] =
              "Шилжсэн(Идэвхтэй ажилчидтай харьцуулбал): " +
              res.data.allEmployeeLeave;
            this.optionsYearAllTeachRad.xaxis.categories[3] =
              "Шилжсэн(Нийт ажилчидтай харьцуулбал): " +
              res.data.allEmployeeLeave;

            // Ажлаас гарсан шалтгаан нийт
            this.seriesLeaveType = res.data.allEmployeeLeaveType;
            this.optionsLeaveType.labels = res.data.allEmployeeLeaveTypeName;
            this.viewYearChartTeach = true;
          })
          .catch();
      }
    },
  },
  methods: {
    // Багшийн шилжилт хөдөлгөөн
    getTeachMigData() {
      if (!this.viewTeachMigData) {
        this.seriesTeach[0] = [];
        this.seriesTeachRad[0].data = [];
        axios
          .post("/manager/getTeachMigData", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesTeach[0] = res.data.teacherActive;
            this.seriesTeach[1] = res.data.teacherLeave;

            this.teachertypes = res.data.teacherType;
            this.optionsTeacherType.xaxis.categories = res.data.typeNameT;
            this.seriesTeacherType[0].data = res.data.teacherActiveType;
            this.seriesTeacherType[1].data = res.data.teacherLeftType;
            //teacher %
            this.seriesTeachRad[0].data.push(100).toFixed(1);
            this.seriesTeachRad[0].data.push(
              ((res.data.teacherNew / res.data.teacherActive) * 100).toFixed(1)
            );
            this.seriesTeachRad[0].data.push(
              ((res.data.teacherLeave / res.data.teacherActive) * 100).toFixed(
                1
              )
            );
            this.seriesTeachRad[0].data.push(
              (
                (res.data.teacherLeave /
                  (res.data.teacherActive + res.data.teacherLeave)) *
                100
              ).toFixed(1)
            );
            this.optionsYearTeachRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.teacherActive;
            this.optionsYearTeachRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.teacherNew;
            this.optionsYearTeachRad.xaxis.categories[2] =
              "Шилжсэн(Идэвхтэй багш): " + res.data.teacherLeave;
            this.optionsYearTeachRad.xaxis.categories[3] =
              "Шилжсэн(Нийт багш): " + res.data.teacherLeave;

            // Ажлаас гарсан шалтгаан багш
            this.seriesLeaveTypeTeach = res.data.teacherLeaveType;
            this.optionsLeaveTypeTeach.labels = res.data.teacherLeaveTypeName;
            this.viewTeachMigData = !this.viewTeachMigData;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.viewTeachMigData = !this.viewTeachMigData;
    },
    // Бусад ажилтан шилжилт хөдөлгөөн
    getEmpMigData() {
      if (!this.viewEmpMigData) {
        this.seriesEmployee[0] = [];
        this.seriesEmployeeRad[0].data = [];
        axios
          .post("/manager/getEmpMigData", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesEmployee[0] = res.data.employeeActive;
            this.seriesEmployee[1] = res.data.employeeLeave;

            this.seriesEmployeeType[0].data = res.data.employeeActiveType;
            this.seriesEmployeeType[1].data = res.data.employeeLeftType;
            this.optionsEmployeeType.xaxis.categories = res.data.typeNameE;

            //employee %
            this.seriesEmployeeRad[0].data.push(100).toFixed(1);
            this.seriesEmployeeRad[0].data.push(
              ((res.data.employeeNew / res.data.employeeActive) * 100).toFixed(
                1
              )
            );
            this.seriesEmployeeRad[0].data.push(
              (
                (res.data.employeeLeave / res.data.employeeActive) *
                100
              ).toFixed(1)
            );
            this.optionsYearEmployeeRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.employeeActive;
            this.optionsYearEmployeeRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.employeeNew;
            this.optionsYearEmployeeRad.xaxis.categories[2] =
              "Шилжсэн: " + res.data.employeeLeave;

            // Ажлаас гарсан шалтгаан ажилчид
            this.seriesLeaveTypeEmployee = res.data.employeeLeaveType;
            this.optionsLeaveTypeEmployee.labels =
              res.data.employeeLeaveTypeName;
            this.viewEmpMigData = !this.viewEmpMigData;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.viewEmpMigData = !this.viewEmpMigData;
    },
    // Удирдлага шилжилт хөдөлгөөн
    getManagementMigData() {
      if (!this.viewManagementMigData) {
        this.seriesManagement[0] = [];
        this.seriesManagRad[0].data = [];
        axios
          .post("/manager/getManagerMigData", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesManagement[0] = res.data.managementActive;
            this.seriesManagement[1] = res.data.managementLeave;

            this.seriesManagementType[0].data = res.data.managementActiveType;
            this.seriesManagementType[1].data = res.data.managerLeftType;
            this.optionsManagementType.xaxis.categories = res.data.typeNameM;

            //manag %
            this.seriesManagRad[0].data.push(100).toFixed(1);
            this.seriesManagRad[0].data.push(
              (
                (res.data.managementNew / res.data.managementActive) *
                100
              ).toFixed(1)
            );
            this.seriesManagRad[0].data.push(
              (
                (res.data.managementLeave / res.data.managementActive) *
                100
              ).toFixed(1)
            );
            this.optionsYearManagRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.managementActive;
            this.optionsYearManagRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.managementNew;
            this.optionsYearManagRad.xaxis.categories[2] =
              "Шилжсэн: " + res.data.managementLeave;
            // Ажлаас гарсан шалтгаан удирдлага
            this.seriesLeaveTypeManag = res.data.managementLeaveType;
            this.optionsLeaveTypeManag.labels =
              res.data.managementLeaveTypeName;
            this.viewManagementMigData = !this.viewManagementMigData;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.viewManagementMigData = !this.viewManagementMigData;
    },
    // Цэцэрлэг шилжилт хөдөлгөөн
    getKgMigData() {
      if (!this.viewKgMigData) {
        this.seriesKgRad[0].data = [];
        this.seriesKg[0] = [];
        this.seriesKg[1] = [];
        axios
          .post("/manager/getKgMigData", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesKg[0] = res.data.kgActive;
            this.seriesKg[1] = res.data.kgLeave;

            this.seriesKgType[0].data = res.data.kgActiveType;
            this.seriesKgType[1].data = res.data.kgLeftType;
            this.optionsKgType.xaxis.categories = res.data.typeNameKg;

            //kg %
            this.seriesKgRad[0].data.push(100).toFixed(1);
            this.seriesKgRad[0].data.push(
              ((res.data.kgNew / res.data.kgActive) * 100).toFixed(1)
            );
            this.seriesKgRad[0].data.push(
              ((res.data.kgLeave / res.data.kgActive) * 100).toFixed(1)
            );
            this.optionsYearKgRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.kgActive;
            this.optionsYearKgRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.kgNew;
            this.optionsYearKgRad.xaxis.categories[2] =
              "Шилжсэн: " + res.data.kgLeave;
            // Ажлаас гарсан шалтгаан цэцэрлэг
            this.seriesLeaveTypeKg = res.data.kgLeaveType;
            this.optionsLeaveTypeKg.labels = res.data.kgLeaveTypeName;
            this.viewKgMigData = !this.viewKgMigData;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.viewKgMigData = !this.viewKgMigData;
    },
    // Спорт клуб шилжилт хөдөлгөөн
    getSportClubMigData() {
      if (!this.viewSportClubMigData) {
        this.seriesSportClubRad[0].data = [];
        this.seriesSportClub[0] = [];
        this.seriesSportClub[1] = [];
        axios
          .post("/manager/getSportClubMigData", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesSportClub[0] = res.data.sportClubActive;
            this.seriesSportClub[1] = res.data.sportClubLeave;

            // Спорт клуб
            this.seriesTypeSportClub[0].data = res.data.sportClubActiveType;
            this.seriesTypeSportClub[1].data = res.data.sportClubLeftType;
            this.optionsTypeSportClub.xaxis.categories =
              res.data.typeNameSportClub;

            //Спорт клуб %
            this.seriesSportClubRad[0].data.push(100).toFixed(1);
            this.seriesSportClubRad[0].data.push(
              (
                (res.data.sportClubAjilchidNew / res.data.sportClubActive) *
                100
              ).toFixed(1)
            );
            this.seriesSportClubRad[0].data.push(
              (
                (res.data.sportClubLeave / res.data.sportClubActive) *
                100
              ).toFixed(1)
            );
            this.seriesSportClubRad[0].data.push(
              (
                (res.data.sportClubLeave /
                  (res.data.sportClubLeave + res.data.sportClubActive)) *
                100
              ).toFixed(1)
            );
            this.optionsSportClubRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.sportClubActive;
            this.optionsSportClubRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.sportClubAjilchidNew;
            this.optionsSportClubRad.xaxis.categories[2] =
              "Шилжсэн(Идэвхтэй): " + res.data.sportClubLeave;
            this.optionsSportClubRad.xaxis.categories[3] =
              "Шилжсэн(Нийт): " + res.data.sportClubLeave;

            // Ажлаас гарсан шалтгаан спорт клуб
            this.seriesLeaveTypeSportClub = res.data.sportClubLeaveType;
            this.optionsLeaveTypeSportClub.labels =
              res.data.sportClubLeaveTypeName;
            this.viewSportClubMigData = !this.viewSportClubMigData;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.viewSportClubMigData = !this.viewSportClubMigData;
    },
    // Дүүрэг шалгах
    getSum() {
      axios
        .get("/allDistrict/" + 11)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    switchMenu() {
      if (this.iconMode != "fa fa-bars") {
        this.iconMode = "fa fa-bars";
        this.btnColor = "warning";
      } else {
        this.iconMode = "fa fa-times";
        this.btnColor = "danger";
      }
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getLeaveTeach() {
      axios
        .post("/manager/getLeaveTeach", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.teachActivelist = res.data.teachActive;
          this.teachLeavelist = res.data.teachLeave;
          this.viewTeachLeave = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeaveEmployee() {
      axios
        .post("/manager/getLeaveEmployee", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.employeeActivelist = res.data.empActive;
          this.employeeLeavelist = res.data.empLeave;
          this.viewEmployeeLeave = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeaveManag() {
      axios
        .post("/manager/getLeaveManag", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.managLeavelist = res.data.managLeave;
          this.managActivelist = res.data.managActive;
          this.viewManagLeave = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeaveKg() {
      axios
        .post("/manager/getLeaveKg", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.kgActivelist = res.data.kgActive;
          this.kgLeavelist = res.data.kgLeave;
          this.viewKgLeave = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeaveSportClub() {
      axios
        .post("/manager/getLeaveSportClub", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.sportClubActivelist = res.data.sportClubActivelist;
          this.sportClubLeavelist = res.data.sportClubLeavelist;
          this.viewSportClubLeave = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDist() {
      if (!this.isDistView) {
        axios
          .post("/manager/getDist", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesDistAllEmp[0].data = res.data.distTotal;
            this.optionsDistAllEmp.xaxis.categories = res.data.distName;
            this.seriesDistTeach[0].data = res.data.distTotalTeach;
            this.optionsDistTeach.xaxis.categories = res.data.distNameTeach;
            this.seriesDistEmployee[0].data = res.data.distTotalEmployee;
            this.optionsDistEmployee.xaxis.categories =
              res.data.distNameEmployee;
            this.seriesDistManag[0].data = res.data.distTotalManag;
            this.optionsDistManag.xaxis.categories = res.data.distNameManag;
            this.seriesDistKg[0].data = res.data.distTotalKg;
            this.optionsDistKg.xaxis.categories = res.data.distNameKg;

            this.seriesDistAllEmpP = res.data.distTotal;
            this.optionsDistAllEmpP.labels = res.data.distName;
            this.seriesDistTeachP = res.data.distTotalTeach;
            this.optionsDistTeachP.labels = res.data.distNameTeach;
            this.seriesDistEmployeeP = res.data.distTotalEmployee;
            this.optionsDistEmployeeP.labels = res.data.distNameEmployee;
            this.seriesDistManagP = res.data.distTotalManag;
            this.optionsDistManagP.labels = res.data.distNameManag;
            this.seriesDistKgP = res.data.distTotalKg;
            this.optionsDistKgP.labels = res.data.distNameKg;
            this.isDistView = !this.isDistView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isDistView = !this.isDistView;
    },
    getDistTeachDetal() {
      axios
        .post("/manager/getDistTeachDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.distTeach = res.data.distCountTeach;
          this.distTeachDetal = res.data.distTeachDetal;
          this.viewDistTeach = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDistEmployeeDetal() {
      axios
        .post("/manager/getDistEmployeeDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.distEmployee = res.data.distCountEmployee;
          this.distEmployeeDetal = res.data.distEmployeeDetal;
          this.viewDistEmployee = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDistManagDetal() {
      axios
        .post("/manager/getDistManagDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.distManag = res.data.distCountManag;
          this.distManagDetal = res.data.distManagDetal;
          this.viewDistManag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDistKgDetal() {
      axios
        .post("/manager/getDistKgDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.distKg = res.data.distCountKg;
          this.distKgDetal = res.data.distKgDetal;
          this.viewDistKg = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGender() {
      if (!this.isGenderView) {
        axios
          .post("/manager/getGender", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesGenderAllEmp[0].data = res.data.genderTotal;
            this.optionsGenderAllEmp.xaxis.categories = res.data.genderName;
            this.seriesGenderTeach[0].data = res.data.genderTotalTeach;
            this.optionsGenderTeach.xaxis.categories = res.data.genderNameTeach;
            this.seriesGenderEmployee[0].data = res.data.genderTotalEmployee;
            this.optionsGenderEmployee.xaxis.categories =
              res.data.genderNameEmployee;
            this.seriesGenderManag[0].data = res.data.genderTotalManag;
            this.optionsGenderManag.xaxis.categories = res.data.genderNameManag;
            this.seriesGenderKg[0].data = res.data.genderTotalKg;
            this.optionsGenderKg.xaxis.categories = res.data.genderNameKg;

            this.seriesGenderAllEmpP = res.data.genderTotal;
            this.optionsGenderAllEmpP.labels = res.data.genderName;
            this.seriesGenderTeachP = res.data.genderTotalTeach;
            this.optionsGenderTeachP.labels = res.data.genderNameTeach;
            this.seriesGenderEmployeeP = res.data.genderTotalEmployee;
            this.optionsGenderEmployeeP.labels = res.data.genderNameEmployee;
            this.seriesGenderManagP = res.data.genderTotalManag;
            this.optionsGenderManagP.labels = res.data.genderNameManag;
            this.seriesGenderKgP = res.data.genderTotalKg;
            this.optionsGenderKgP.labels = res.data.genderNameKg;
            this.isGenderView = !this.isGenderView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isGenderView = !this.isGenderView;
    },
    getBolZer() {
      if (!this.isBolZerView) {
        axios
          .post("/manager/getBolZer", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesBolZerAllEmp[0].data = res.data.bolZerTotal;
            this.optionsBolZerAllEmp.xaxis.categories = res.data.bolZerName;
            this.seriesBolZerTeach[0].data = res.data.bolZerTotalTeach;
            this.optionsBolZerTeach.xaxis.categories = res.data.bolZerNameTeach;
            this.seriesBolZerEmployee[0].data = res.data.bolZerTotalEmployee;
            this.optionsBolZerEmployee.xaxis.categories =
              res.data.bolZerNameEmployee;
            this.seriesBolZerManag[0].data = res.data.bolZerTotalManag;
            this.optionsBolZerManag.xaxis.categories = res.data.bolZerNameManag;
            this.seriesBolZerKg[0].data = res.data.bolZerTotalKg;
            this.optionsBolZerKg.xaxis.categories = res.data.bolZerNameKg;

            this.seriesBolZerAllEmpP = res.data.bolZerTotal;
            this.optionsBolZerAllEmpP.labels = res.data.bolZerName;
            this.seriesBolZerTeachP = res.data.bolZerTotalTeach;
            this.optionsBolZerTeachP.labels = res.data.bolZerNameTeach;
            this.seriesBolZerEmployeeP = res.data.bolZerTotalEmployee;
            this.optionsBolZerEmployeeP.labels = res.data.bolZerNameEmployee;
            this.seriesBolZerManagP = res.data.bolZerTotalManag;
            this.optionsBolZerManagP.labels = res.data.bolZerNameManag;
            this.seriesBolZerKgP = res.data.bolZerTotalKg;
            this.optionsBolZerKgP.labels = res.data.bolZerNameKg;
            this.isBolZerView = !this.isBolZerView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isBolZerView = !this.isBolZerView;
    },
    getBolZerTeachDetal() {
      axios
        .post("/manager/getBolZerTeachDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.bolZerTeach = res.data.bolZerCountTeach;
          this.bolZerTeachDetal = res.data.bolZerTeachDetal;
          this.viewBolZerTeach = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBolZerEmployeeDetal() {
      axios
        .post("/manager/getBolZerEmployeeDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.bolZerEmployee = res.data.bolZerCountEmployee;
          this.bolZerEmployeeDetal = res.data.bolZerEmployeeDetal;
          this.viewBolZerEmployee = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBolZerManagDetal() {
      axios
        .post("/manager/getBolZerManagDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.bolZerManag = res.data.bolZerCountManag;
          this.bolZerManagDetal = res.data.bolZerManagDetal;
          this.viewBolZerManag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBolZerKgDetal() {
      axios
        .post("/manager/getBolZerKgDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.bolZerKg = res.data.bolZerCountKg;
          this.bolZerKgDetal = res.data.bolZerKgDetal;
          this.viewBolZerKg = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMerZer() {
      if (!this.isMerZerView) {
        axios
          .post("/manager/getMerZer", {
            year: this.school_year_id2,
          })
          .then((res) => {
            this.seriesMerZerAllEmp[0].data = res.data.merZerTotal;
            this.optionsMerZerAllEmp.xaxis.categories = res.data.merZerName;
            this.seriesMerZerTeach[0].data = res.data.merZerTotalTeach;
            this.optionsMerZerTeach.xaxis.categories = res.data.merZerNameTeach;
            this.seriesMerZerEmployee[0].data = res.data.merZerTotalEmployee;
            this.optionsMerZerEmployee.xaxis.categories =
              res.data.merZerNameEmployee;
            this.seriesMerZerManag[0].data = res.data.merZerTotalManag;
            this.optionsMerZerManag.xaxis.categories = res.data.merZerNameManag;
            this.seriesMerZerKg[0].data = res.data.merZerTotalKg;
            this.optionsMerZerKg.xaxis.categories = res.data.merZerNameKg;

            this.seriesMerZerAllEmpP = res.data.merZerTotal;
            this.optionsMerZerAllEmpP.labels = res.data.merZerName;
            this.seriesMerZerTeachP = res.data.merZerTotalTeach;
            this.optionsMerZerTeachP.labels = res.data.merZerNameTeach;
            this.seriesMerZerEmployeeP = res.data.merZerTotalEmployee;
            this.optionsMerZerEmployeeP.labels = res.data.merZerNameEmployee;
            this.seriesMerZerManagP = res.data.merZerTotalManag;
            this.optionsMerZerManagP.labels = res.data.merZerNameManag;
            this.seriesMerZerKgP = res.data.merZerTotalKg;
            this.optionsMerZerKgP.labels = res.data.merZerNameKg;
            this.isMerZerView = !this.isMerZerView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isMerZerView = !this.isMerZerView;
    },

    getMerZerTeachDetal() {
      axios
        .post("/manager/getMerZerTeachDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.merZerTeach = res.data.merZerCountTeach;
          this.merZerTeachDetal = res.data.merZerTeachDetal;
          this.viewMerZerTeach = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMerZerEmployeeDetal() {
      axios
        .post("/manager/getMerZerEmployeeDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.merZerEmployee = res.data.merZerCountEmployee;
          this.merZerEmployeeDetal = res.data.merZerEmployeeDetal;
          this.viewMerZerEmployee = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMerZerManagDetal() {
      axios
        .post("/manager/getMerZerManagDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.merZerManag = res.data.merZerCountManag;
          this.merZerManagDetal = res.data.merZerManagDetal;
          this.viewMerZerManag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMerZerKgDetal() {
      axios
        .post("/manager/getMerZerKgDetal", {
          year: this.school_year_id2,
        })
        .then((res) => {
          this.merZerKg = res.data.merZerCountKg;
          this.merZerKgDetal = res.data.merZerKgDetal;
          this.viewMerZerKg = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {},
  created() {
    this.getSum();
  },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
  font-size: 70%;
}

.pieChart {
  height: 330px;
}

.barChart {
  height: 340px;
}
</style>
