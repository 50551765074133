<template>
  <div>
    <cultural class="pt-3"></cultural>
    <stat-stud class="pt-3"></stat-stud>
  </div>
</template>
<script>
import cultural from "../Project/CulturalCalendar.vue";
import statStud from "../Manager/Statistic/StatisticStudents.vue";
export default {
  components: {
    cultural: cultural,
    statStud: statStud,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped></style>
