<template>
  <div class="row pt-3">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Системд нэвтрэх, Нэр, нууц үг</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/656457458?h=8151d530a8"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <!-- /.card -->
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Дүнгийн мэдээлэлтэй танилцах</h3>
        </div>
        <div class="card-body">
          <iframe
            src="https://player.vimeo.com/video/656457340?h=6d907795b4"
            width="100%"
            height="300px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Хөтөч цэвэрлэх</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781524882?h=ec3dcf784e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="1. Clear browsing data"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Цэс</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781525028?h=8e2c0304b4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="2. Menu"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Гэрийн даалгавар</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781527306?h=c5a1df608d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="3. Course comment and Homework"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Мэдэгдэл</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781525131?h=0dbc53e530&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="4. Notification"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Хичээлийн дүн</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781525195?h=62d72fe03b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="5. Academic evaluation"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Хүмүүжлийн хичээлийн дүн</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781525349?h=89622e8388&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="6. Behaviour evaluation"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Бие бялдрын сорил</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/781525538?h=702c7f70a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="7. Physical evaluation"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Санал, гомдол, өргөдөл, Талархал</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/656457392?h=a88c56816c"
              width="100%"
              height="300px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">
            Ном сурах бичиг зарах ба худалдан авах заавар
          </h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://onedrive.live.com/embed?resid=545686A2437B5781%21165449&authkey=!AKe0dMpRJZubo2I&em=2"
              width="100%"
              height="800px"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">Зарах сурах бичиг бүртгэх</h3>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <iframe
              src="https://player.vimeo.com/video/1001594729?h=ed134c418c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="800px"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="8. Шинэ ном бүртгэх"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: "",
      myChildCourseCommentunRead: [],
      myChildCourseCommentRead: [],
    };
  },
  methods: {
    getAllCourseCommentForMyChild() {
      axios
        .get("/parent/getCourseCommForMyChild")
        .then((res) => {
          //   console.log(res.data);
          this.myChildCourseCommentunRead = res.data.myChildCourseCommentunRead;
          this.myChildCourseCommentRead = res.data.myChildCourseCommentRead;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    imReadParentConfirm(comment) {
      axios
        .post("/parent/readParentConfirm", { comment })
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("readComment");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
  },
  created() {
    this.getAllCourseCommentForMyChild();
    Fire.$on("readComment", () => {
      this.getAllCourseCommentForMyChild();
    });
  },
};
</script>

<style>
</style>
