<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Сургуулийн хичээлийн төрөл</h3>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
          <div class="col-sm-8">
            <table
              class="table table-bordered table-hover table-striped dataTable dtr-inline"
              role="grid"
            >
              <thead>
                <tr role="row">
                  <th>№</th>
                  <th>Хичээлийн төрөл(товчлол)</th>
                  <th>Хичээлийн төрлийн тайлбар</th>
                  <th>Үүсгэсэн</th>
                  <th>Зассан</th>
                  <th>Үйлдлүүд</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="odd"
                  v-for="(schoolcoursetype, index) in schoolcoursetypes"
                  :key="schoolcoursetype.id"
                >
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolcoursetype.name }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolcoursetype.description }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolcoursetype.created_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ schoolcoursetype.updated_at | dateFullYear }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <!-- <a
                      href="#"
                      class="badge bg-pirple"
                      @click="viewSchoolCourseType(schoolcoursetype)"
                      ><i class="far fa-eye"></i
                    ></a> -->
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolCourseType(schoolcoursetype)"
                      ><i class="fas fa-edit"></i
                    ></a>

                    <!-- <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolCourseType(schoolcoursetype)"
                      ><i class="fas fa-trash-alt"></i
                    ></a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Хичээлийн төрөл бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">Хичээл төрөл засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="
                  !editMode
                    ? createSchoolCourseType()
                    : updateSchoolCourseType()
                "
              >
                <div class="card-body">
                  <div class="form-group">
                    <label>Хичээлийн төрөл(товчлол)</label>
                    <input
                      type="text"
                      name="name"
                      v-model="form.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                      placeholder="Хичээлийн төрөл(товчлол)"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Хичээлийн төрлийн тайлбар</label>
                    <input
                      type="text"
                      name="description"
                      v-model="form.description"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('description'),
                        },
                      ]"
                      placeholder="Хичээлийн төрлийн тайлбар"
                    />
                    <has-error :form="form" field="description"></has-error>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editMode"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editMode"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editMode"
                    @click.prevent="cancelEdit"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      schoolcoursetypes: [],
      form: new Form({
        id: "",
        name: "",
        description: "",
      }),
    };
  },
  methods: {
    getAllSchoolCourseTypes() {
      axios
        .get("/manager/getAllSchoolCourseType")
        .then((response) => {
          //   console.log(response);
          this.schoolcoursetypes = response.data.schoolcoursetypes;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolCourseType(schoolsubject) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolsubject);
    },
    editSchoolCourseType(schoolsubject) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolsubject);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolsubject);
    },
    updateSchoolCourseType() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/coursetype/" + this.form.id)
        .then((response) => {
          // console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadSchoolCourseTypes");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolCourseType(schoolsubject) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolsubject);
    },
    createSchoolCourseType() {
      this.action = "Хичээлийн төрөл үүсгэж байна...";
      this.form
        .post("/manager/coursetype")
        .then((response) => {
          this.$toastr.s("Хичээлийн төрлийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolCourseTypes");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllSchoolCourseTypes();
    Fire.$on("loadSchoolCourseTypes", () => {
      this.getAllSchoolCourseTypes();
    });
  },
};
</script>

<style></style>
