<template>
  <div class="row pt-3">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Боловсролын салбарт өгч болох болон бусад бүртгэлтэй шагналууд
          </h3>
        </div>
        <div class="card-body table-responsive p-0" style="overflow-x: auto">
          <table class="table table-hover table-striped table-valign-middle">
            <thead style="font-size: 90%">
              <tr role="row">
                <th>№</th>
                <th>Салбар</th>
                <th>Түвшин</th>
                <th>Ангилал</th>
                <th>Төрөл</th>
                <th>Шагналын нэр</th>
                <th>Шагналын тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody style="font-size: 80%">
              <tr
                class="odd"
                v-for="(reward, index) in rewards"
                :key="reward.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.shagnal_salbar }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    pill
                    variant="info"
                    class="text-wrap"
                    style="width: 8rem"
                  >
                    {{ reward.shagnal_tuvshin }}</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.shagnal_angilal }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    pill
                    variant="info"
                    class="text-wrap"
                    style="width: 8rem"
                    >{{ reward.shagnal_turul }}</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.shagnal_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.shagnal_tailbar }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ reward.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editSchoolReward(reward)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteSchoolReward(reward)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Шагнал бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Шагнал засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode ? createSchoolReward() : updateSchoolReward()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label>Салбар</label>
                <input
                  type="text"
                  name="shagnal_salbar"
                  v-model="form.shagnal_salbar"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('shagnal_salbar'),
                    },
                  ]"
                  placeholder="Шагналын салбар"
                />
                <has-error :form="form" field="shagnal_salbar"></has-error>
              </div>
              <div class="form-group">
                <label>Түвшин</label>
                <multiselect
                  v-model="form.shagnal_tuvshin"
                  :options="shagnal_tuvshin"
                  :multiple="false"
                  track-by="tuvshin"
                  label="tuvshin"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Шагналын түвшинг сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.tuvshin }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
                <has-error :form="form" field="shagnal_tuvshin"></has-error>
              </div>
              <div class="form-group">
                <label>Ангилал</label>
                <input
                  type="text"
                  name="shagnal_angilal"
                  v-model="form.shagnal_angilal"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('shagnal_angilal'),
                    },
                  ]"
                  placeholder="Шагналын ангилал"
                />
                <has-error :form="form" field="shagnal_angilal"></has-error>
              </div>
              <div class="form-group">
                <label>Төрөл</label>
                <multiselect
                  v-model="form.shagnal_turul"
                  :options="shagnal_turul"
                  :multiple="false"
                  track-by="turul"
                  label="turul"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Шагналын төрөл сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.turul }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
                <has-error :form="form" field="shagnal_turul"></has-error>
              </div>
              <div class="form-group">
                <label>Шагналын нэр</label>
                <input
                  type="text"
                  name="shagnal_name"
                  v-model="form.shagnal_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('shagnal_name'),
                    },
                  ]"
                  placeholder="Шагналын нэр"
                />
                <has-error :form="form" field="shagnal_name"></has-error>
              </div>
              <div class="form-group">
                <label>Шагналын тайлбар</label>
                <input
                  type="text"
                  name="shagnal_tailbar"
                  v-model="form.shagnal_tailbar"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('shagnal_tailbar'),
                    },
                  ]"
                  placeholder="Шагналын тайлбар"
                />
                <has-error :form="form" field="shagnal_tailbar"></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      rewards: [],
      form: new Form({
        id: "",
        shagnal_salbar: "",
        shagnal_tuvshin: null,
        shagnal_angilal: "",
        shagnal_turul: "",
        shagnal_name: "",
        shagnal_tailbar: "",
        user_email: "",
      }),
      shagnal_tuvshin: [
        { name: "Анги", tuvshin: "Анги" },
        { name: "Сургууль", tuvshin: "Сургууль" },
        { name: "Дүүрэг", tuvshin: "Дүүрэг" },
        { name: "Нийслэл", tuvshin: "Нийслэл" },
        { name: "Улс", tuvshin: "Улс" },
        { name: "Олон улс", tuvshin: "Олон улс" },
      ],
      shagnal_turul: [
        { name: "Өргөмжлөл", turul: "Өргөмжлөл" },
        { name: "Медаль", turul: "Медаль" },
        { name: "Баярын бичиг", turul: "Баярын бичиг" },
        { name: "Нэрэмжит шагнал", turul: "Нэрэмжит шагнал" },
        {
          name: "Эд зүйл болон үнэ бүхий зүйл",
          turul: "Эд зүйл болон үнэ бүхий зүйл",
        },
        { name: "Хүндэт шагнал", turul: "Хүндэт шагнал" },
        { name: "Цол тэмдэг", turul: "Цол тэмдэг" },
        { name: "Жуух бичиг", turul: "Жуух бичиг" },
        { name: "Тэргүүний ажилтан", turul: "Тэргүүний ажилтан" },
        { name: "Эрхэм хүндэт шагнал", turul: "Эрхэм хүндэт шагнал" },
      ],
    };
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  methods: {
    getAllRewards() {
      axios
        .get("/all_worker_functions/getAllRewards")
        .then((response) => {
          // console.log(response.data.rewards);
          this.rewards = response.data.rewards;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // filterByTuvshin(value) {
    //     //   console.log(value, "value");
    //     let tuvshinFiltered = null;
    //     this.shagnal_tuvshin.filter(el => {
    //         if (el.name === value) {
    //             //   console.log("name " + el.name + "=>" + "Value is: " + value);
    //             tuvshinFiltered = el.tuvshin;
    //         }
    //     });
    //     return tuvshinFiltered;
    // },
    //         filterByTurul(value) {
    //             //   console.log(value);
    //
    //             let turulFiltered = null;
    //             this.shagnal_turul.filter(el => {
    //                 if (el.name === value) {
    //                     //   console.log("name " + el.name + "=>" + "Value is: " + value);
    //                     turulFiltered = el.turul;
    //                 }
    //             });
    //             return turulFiltered;
    //         },
    passChangeSchoolEmployee(schoolEmp) {
      console.log(schoolEmp);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe%2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manage/passChangeEmployer/" + schoolEmp.user_id)
            .then((response) => {
              console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    editSchoolReward(reward) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(reward);
      this.shagnal_tuvshin.filter((el) => {
        if (el.name === reward.shagnal_tuvshin) {
          this.form.shagnal_tuvshin = el;
        }
      });
      this.shagnal_turul.filter((el) => {
        if (el.name === reward.shagnal_turul) {
          this.form.shagnal_turul = el;
        }
      });
    },
    updateSchoolReward() {
      this.form
        .put("/all_worker_functions/updateReward/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolRewards");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolReward(reward) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/all_worker_functions/deleteReward/" + reward.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadSchoolRewards");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    createSchoolReward() {
      this.form
        .post("/all_worker_functions/createReward")
        .then((response) => {
          this.$toastr.s("Шагналыг амжилттай бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolRewards");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllRewards();
    Fire.$on("loadSchoolRewards", () => {
      this.getAllRewards();
    });
  },
};
</script>

<style></style>
