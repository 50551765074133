<template>
  <div class="mt-2">
    <b-button @click.prevent="getStateYear()" block variant="outline-primary"
      >Ажилчдыг улсад ажилласан жилээр харах</b-button
    >
    <div class="row" v-if="isWorkYearStateView">
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын улсад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="340"
                class="barChart"
                :options="optionsStateYearAllEmp"
                :series="seriesStateYearAllEmp"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын улсад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="350"
                class="pieChart"
                :options="optionsStateYearAllEmpP"
                :series="seriesStateYearAllEmpP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <!-- Багш нар харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын улсад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsStateYearTeach"
                :series="seriesStateYearTeach"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын улсад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsStateYearTeachP"
                :series="seriesStateYearTeachP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="teachStateYear in stateYearTeach"
              :key="teachStateYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ teachStateYear.turiin_alba_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    teachStateYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="teachStateYearDetal in stateYearTeachDetal"
                    :key="teachStateYearDetal.id"
                  >
                    <tr
                      v-if="
                        teachStateYear.turiin_alba_ajiljil ==
                        teachStateYearDetal.turiin_alba_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="teachStateYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="teachStateYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachStateYearDetal.teacher_last_name[0] }}.{{
                          teachStateYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachStateYearDetal.turiin_alba_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewStateYearTeach"
              @click.prevent="getStateYearTeachDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Ажилчид харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын улсад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsStateYearEmployee"
                :series="seriesStateYearEmployee"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын улсад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsStateYearEmployeeP"
                :series="seriesStateYearEmployeeP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="employeeStateYear in stateYearEmployee"
              :key="employeeStateYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ employeeStateYear.turiin_alba_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    employeeStateYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="employeeStateYearDetal in stateYearEmployeeDetal"
                    :key="employeeStateYearDetal.id"
                  >
                    <tr
                      v-if="
                        employeeStateYear.turiin_alba_ajiljil ==
                        employeeStateYearDetal.turiin_alba_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="
                            employeeStateYearDetal.profile_photo_url != null
                          "
                          class="direct-chat-img"
                          :src="employeeStateYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeStateYearDetal.teacher_last_name[0] }}.{{
                          employeeStateYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeStateYearDetal.turiin_alba_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewStateYearEmployee"
              @click.prevent="getStateYearEmployeeDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Удирдлагууд харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба улсад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsStateYearManag"
                :series="seriesStateYearManag"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба улсад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsStateYearManagP"
                :series="seriesStateYearManagP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="managStateYear in stateYearManag"
              :key="managStateYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ managStateYear.turiin_alba_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    managStateYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="managStateYearDetal in stateYearManagDetal"
                    :key="managStateYearDetal.id"
                  >
                    <tr
                      v-if="
                        managStateYear.turiin_alba_ajiljil ==
                        managStateYearDetal.turiin_alba_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="managStateYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="managStateYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managStateYearDetal.teacher_last_name[0] }}.{{
                          managStateYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managStateYearDetal.turiin_alba_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewStateYearManag"
              @click.prevent="getStateYearManagDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Цэцэрлэг харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг улсад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsStateYearKg"
                :series="seriesStateYearKg"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг улсад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsStateYearKgP"
                :series="seriesStateYearKgP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="kgStateYear in stateYearKg"
              :key="kgStateYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ kgStateYear.turiin_alba_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{
                    kgStateYear.total
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="kgStateYearDetal in stateYearKgDetal"
                    :key="kgStateYearDetal.id"
                  >
                    <tr
                      v-if="
                        kgStateYear.turiin_alba_ajiljil ==
                        kgStateYearDetal.turiin_alba_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="kgStateYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="kgStateYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgStateYearDetal.teacher_last_name[0] }}.{{
                          kgStateYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgStateYearDetal.turiin_alba_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewStateYearKg"
              @click.prevent="getStateYearKgDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./card-body -->
</template>

<script>
export default {
  props: ["year_id"],
  data() {
    return {
      isWorkYearStateView: false,
      viewStateYearTeach: true,
      viewStateYearEmployee: true,
      viewStateYearManag: true,
      viewStateYearKg: true,
      stateYearTeach: [],
      stateYearEmployee: [],
      stateYearManag: [],
      stateYearKg: [],
      stateYearTeachDetal: [],
      stateYearEmployeeDetal: [],
      stateYearManagDetal: [],
      stateYearKgDetal: [],
      optionsStateYearAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesStateYearAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsStateYearTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesStateYearTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsStateYearEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesStateYearEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsStateYearManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesStateYearManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsStateYearKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesStateYearKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesStateYearAllEmpP: [],
      seriesStateYearTeachP: [],
      seriesStateYearEmployeeP: [],
      seriesStateYearManagP: [],
      seriesStateYearKgP: [],
      optionsStateYearAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsStateYearTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsStateYearEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsStateYearManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsStateYearKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {
    getStateYear() {
      if (!this.isWorkYearStateView) {
        axios
          .post("/manager/getStateYear", {
            year: this.year_id,
          })
          .then((res) => {
            this.seriesStateYearAllEmp[0].data = res.data.stateYearTotal;
            this.optionsStateYearAllEmp.xaxis.categories =
              res.data.stateYearName;
            this.seriesStateYearTeach[0].data = res.data.stateYearTotalTeach;
            this.optionsStateYearTeach.xaxis.categories =
              res.data.stateYearNameTeach;
            this.seriesStateYearEmployee[0].data =
              res.data.stateYearTotalEmployee;
            this.optionsStateYearEmployee.xaxis.categories =
              res.data.stateYearNameEmployee;
            this.seriesStateYearManag[0].data = res.data.stateYearTotalManag;
            this.optionsStateYearManag.xaxis.categories =
              res.data.stateYearNameManag;
            this.seriesStateYearKg[0].data = res.data.stateYearTotalKg;
            this.optionsStateYearKg.xaxis.categories = res.data.stateYearNameKg;

            this.seriesStateYearAllEmpP = res.data.stateYearTotal;
            this.optionsStateYearAllEmpP.labels = res.data.stateYearName;
            this.seriesStateYearTeachP = res.data.stateYearTotalTeach;
            this.optionsStateYearTeachP.labels = res.data.stateYearNameTeach;
            this.seriesStateYearEmployeeP = res.data.stateYearTotalEmployee;
            this.optionsStateYearEmployeeP.labels =
              res.data.stateYearNameEmployee;
            this.seriesStateYearManagP = res.data.stateYearTotalManag;
            this.optionsStateYearManagP.labels = res.data.stateYearNameManag;
            this.seriesStateYearKgP = res.data.stateYearTotalKg;
            this.optionsStateYearKgP.labels = res.data.stateYearNameKg;
            this.isWorkYearStateView = !this.isWorkYearStateView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isWorkYearStateView = !this.isWorkYearStateView;
    },
    getStateYearTeachDetal() {
      axios
        .post("/manager/getStateYearTeachDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.stateYearTeach = res.data.stateYearCountTeach;
          this.stateYearTeachDetal = res.data.stateYearTeachDetal;
          this.viewStateYearTeach = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStateYearEmployeeDetal() {
      axios
        .post("/manager/getStateYearEmployeeDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.stateYearEmployee = res.data.stateYearCountEmployee;
          this.stateYearEmployeeDetal = res.data.stateYearEmployeeDetal;
          this.viewStateYearEmployee = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStateYearManagDetal() {
      axios
        .post("/manager/getStateYearManagDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.stateYearManag = res.data.stateYearCountManag;
          this.stateYearManagDetal = res.data.stateYearManagDetal;
          this.viewStateYearManag = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStateYearKgDetal() {
      axios
        .post("/manager/getStateYearKgDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.stateYearKg = res.data.stateYearCountKg;
          this.stateYearKgDetal = res.data.stateYearKgDetal;
          this.viewStateYearKg = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
  font-size: 70%;
}
.pieChart {
  height: 330px;
}
.barChart {
  height: 340px;
}
</style>
