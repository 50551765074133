<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <span class="badge bg-success"
              >A: {{ examAverageCountA.length }}
            </span>
            <span class="badge bg-success"
              >B:{{ examAverageCountB.length }}</span
            >
            <span class="badge bg-info">C:{{ examAverageCountC.length }}</span>
            <span class="badge bg-info">D: {{ examAverageCountD.length }}</span>
            <span class="badge bg-danger"
              >F: {{ examAverageCountF.length }}</span
            >
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 5px">№</th>
                <th>Овог Нэр</th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын зорилго эсвэл агуулгатай холбоотой сэдэл, сэтгэл хөдлөлөө хянах, үнэлэх чадвар! Жинлэх хувь 10%'
                  "
                  title="Сельф-1"
                >
                  Се1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын зорилго эсвэл агуулгыг ойлгож байгаа эсэхээ нягтлах, түүнийг хэрхэн хэрэгжүүлэх арга зам, алхмуудыг төлөвлөх! Жинлэх хувь 10%'
                  "
                  title="Метакогнитив-1"
                >
                  Ме1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой түлхүүр үгс, ойлголтуудыг сэргээх санах! Жинлэх хувь 10%'
                  "
                  title="Когнитив-1"
                >
                  Ко1 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой түлхүүр үгс, ойлголтуудад утга өгөх чадвар! Жинлэх хувь 10%'
                  "
                  title="Когнитив-2"
                >
                  Ко2 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой мэдлэгийг задлан шинжлэх чадвар! Жинлэх хувь 20%'
                  "
                  title="Когнитив-3"
                >
                  Ко3 (20%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаврын агуулгатай холбоотой мэдлэгийг танил болон танил бус нөхцөлд хэрэглэх чадвар! Жинлэх хувь 20%'
                  "
                  title="Когнитив-4"
                >
                  Ко4 (20%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаварын гүйцэтгэлийг хянах, алдааг засаж залруулах, үр дүнгээ нэгтгэх, эмхэлж цэгцлэх! Жинлэх хувь 10%'
                  "
                  title="Метакогнитив-2 "
                >
                  Ме2 (10%)
                </th>
                <th
                  v-b-popover.hover.top="
                    'Даалгаварын үр дүнг үнэлж дүгнэх, үр дүнтэй холбоотой сэдэл, сэтгэл хөдлөлөө хянах, үнэлэх чадвар! Жинлэх хувь 10%'
                  "
                  title="Сельф-2"
                >
                  Се2 (10%)
                </th>
                <th>Дүн(Үнэлгээ)</th>
                <!-- <th>Дүн баталгаажуулах</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(examData, index) in changeSelectedLessonExamData"
                :key="examData.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <h6>
                    <b-badge
                      @click.prevent="getExamValueStudentsManager(examData)"
                    >
                      {{ examData.student_last_name[0]
                      }}{{ examData.student_last_name[1] }}.{{
                        examData.student_name
                      }}</b-badge
                    >
                  </h6>
                </td>
                <td>
                  {{ examData.exam1 }}
                </td>
                <td>
                  {{ examData.exam2 }}
                </td>
                <td>
                  {{ examData.exam3 }}
                </td>
                <td>
                  {{ examData.exam4 }}
                </td>
                <td>
                  {{ examData.exam5 }}
                </td>
                <td>
                  {{ examData.exam6 }}
                </td>
                <td>
                  {{ examData.exam7 }}
                </td>
                <td>
                  {{ examData.exam8 }}
                </td>
                <td>
                  <span class="badge bg-success">
                    {{ examData.examAverage.toFixed(1) }}
                    %</span
                  >
                  <span class="badge bg-info">
                    {{ symbolGradeCheck(examData.examAverage) }}</span
                  >
                </td>
                <!-- <td>
                  <i
                    class="far fa-save btn-success btn-xs"
                    @click.prevent="saveStudentFinalQuarter(examData)"
                  ></i>
                </td> -->
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th style="width: 5px"></th>
                <th style="text-align: right">Дундаж</th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam1CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam2CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam3CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam4CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam5CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam6CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam7CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-warning">
                    {{ exam8CalsAvg }}
                    %</span
                  >
                </th>
                <th>
                  <span class="badge bg-success">
                    {{ examCalsAvgFull }}
                    %</span
                  ><span class="badge bg-info">
                    (
                    {{ symbolGradeCheck(examCalsAvgFull) }}
                    )</span
                  >
                </th>
              </tr>
              <tr>
                <th style="width: 5px">№</th>
                <th style="text-align: right">Үнэлгээ</th>
                <th>Се1</th>
                <th>Ме1</th>
                <th>Ко1</th>
                <th>Ко2</th>
                <th>Ко3</th>
                <th>Ко4</th>
                <th>Ме2</th>
                <th>Се2</th>
                <th>Дүн(Үнэлгээ)</th>
              </tr>
              <tr>
                <th style="text-align: right">1</th>
                <th style="text-align: right">A</th>
                <th>{{ exam1CountA.length }}</th>
                <th>{{ exam2CountA.length }}</th>
                <th>{{ exam3CountA.length }}</th>
                <th>{{ exam4CountA.length }}</th>
                <th>{{ exam5CountA.length }}</th>
                <th>{{ exam6CountA.length }}</th>
                <th>{{ exam7CountA.length }}</th>
                <th>{{ exam8CountA.length }}</th>
                <th>{{ examAverageCountA.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">2</th>
                <th style="text-align: right">B</th>
                <th>{{ exam1CountB.length }}</th>
                <th>{{ exam2CountB.length }}</th>
                <th>{{ exam3CountB.length }}</th>
                <th>{{ exam4CountB.length }}</th>
                <th>{{ exam5CountB.length }}</th>
                <th>{{ exam6CountB.length }}</th>
                <th>{{ exam7CountB.length }}</th>
                <th>{{ exam8CountB.length }}</th>
                <th>{{ examAverageCountB.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">3</th>
                <th style="text-align: right">C</th>
                <th>{{ exam1CountC.length }}</th>
                <th>{{ exam2CountC.length }}</th>
                <th>{{ exam3CountC.length }}</th>
                <th>{{ exam4CountC.length }}</th>
                <th>{{ exam5CountC.length }}</th>
                <th>{{ exam6CountC.length }}</th>
                <th>{{ exam7CountC.length }}</th>
                <th>{{ exam8CountC.length }}</th>
                <th>{{ examAverageCountC.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">4</th>
                <th style="text-align: right">D</th>
                <th>{{ exam1CountD.length }}</th>
                <th>{{ exam2CountD.length }}</th>
                <th>{{ exam3CountD.length }}</th>
                <th>{{ exam4CountD.length }}</th>
                <th>{{ exam5CountD.length }}</th>
                <th>{{ exam6CountD.length }}</th>
                <th>{{ exam7CountD.length }}</th>
                <th>{{ exam8CountD.length }}</th>
                <th>{{ examAverageCountD.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">5</th>
                <th style="text-align: right">F</th>
                <th>{{ exam1CountF.length }}</th>
                <th>{{ exam2CountF.length }}</th>
                <th>{{ exam3CountF.length }}</th>
                <th>{{ exam4CountF.length }}</th>
                <th>{{ exam5CountF.length }}</th>
                <th>{{ exam6CountF.length }}</th>
                <th>{{ exam7CountF.length }}</th>
                <th>{{ exam8CountF.length }}</th>
                <th>{{ examAverageCountF.length }}</th>
              </tr>
              <tr>
                <th style="text-align: right">6</th>
                <th style="text-align: right">Success/Амжилт</th>
                <th>{{ exam1CountSuccess.toFixed(1) }}</th>
                <th>{{ exam2CountSuccess.toFixed(1) }}</th>
                <th>{{ exam3CountSuccess.toFixed(1) }}</th>
                <th>{{ exam4CountSuccess.toFixed(1) }}</th>
                <th>{{ exam5CountSuccess.toFixed(1) }}</th>
                <th>{{ exam6CountSuccess.toFixed(1) }}</th>
                <th>{{ exam7CountSuccess.toFixed(1) }}</th>
                <th>{{ exam8CountSuccess.toFixed(1) }}</th>
                <th>
                  {{ examAverageCountSuccess.toFixed(1) }}
                </th>
              </tr>
              <tr>
                <th style="text-align: right">7</th>
                <th style="text-align: right">Quality/Чанар</th>
                <th>{{ exam1CountQuality.toFixed(1) }}</th>
                <th>{{ exam2CountQuality.toFixed(1) }}</th>
                <th>{{ exam3CountQuality.toFixed(1) }}</th>
                <th>{{ exam4CountQuality.toFixed(1) }}</th>
                <th>{{ exam5CountQuality.toFixed(1) }}</th>
                <th>{{ exam6CountQuality.toFixed(1) }}</th>
                <th>{{ exam7CountQuality.toFixed(1) }}</th>
                <th>{{ exam8CountQuality.toFixed(1) }}</th>
                <th>
                  {{ examAverageCountQuality.toFixed(1) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-taxanomy-stat"
      class="modal fade"
      title="Таксономын анализ"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 10px">#</th>
                  <th>Даалгавар</th>
                  <th>Алхам-1</th>
                  <th>Алхам-2</th>
                  <th>Алхам-3</th>
                  <th>Алхам-4</th>
                  <th>Алхам-5</th>
                  <th>Алхам-6</th>
                  <th>Алхам-7</th>
                  <th>Алхам-8</th>
                  <th>Гүйцэтгэл</th>
                  <th>GPA</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(exam, index) in selectChildCourseExamValues"
                  :key="index"
                >
                  <td>{{ ++index }}</td>
                  <td>{{ exam.daalgar_number }}</td>
                  <td>
                    {{ transferInt(exam.exam1) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam2) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam3) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam4) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam5) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam6) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam7) }}
                  </td>
                  <td>
                    {{ transferInt(exam.exam8) }}
                  </td>
                  <td>
                    <span>{{ averageExamStep(exam) }}</span>
                  </td>
                  <td>
                    <span class="badge badge-info"
                      >({{ symbolGradeCheck(averageExamStep(exam)) }})</span
                    >
                  </td>
                </tr>
                <tr v-if="selectChildCourseExamValues.length != 0">
                  <td></td>
                  <td>Дундаж гүйцэтгэл</td>
                  <td>{{ examPerValuesAverage.exam1Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam2Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam3Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam4Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam5Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam6Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam7Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam8Ave.toFixed(1) }}</td>
                  <td>{{ examPerValuesAverage.exam1_8Ave.toFixed(1) }}</td>
                  <td>
                    <span class="badge badge-info">
                      ({{
                        symbolGradeCheck(
                          examPerValuesAverage.exam1_8Ave.toFixed(1)
                        )
                      }})
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                    <div class="row mt-2" v-if="viewYearChart">
                      <div class="col-12 col-sm-12">
                        <div class="info-box bg-light">
                          <div class="info-box-content">
                            <span>
                              <strong class="info-box-text mt-4 text-center">
                                Тухайн шалгалтыг ангийн дундажтай
                                харьцуулбал</strong
                              >
                              <apexchart
                                type="bar"
                                height="340"
                                class="barChart"
                                :options="optionsGenderAllClass"
                                :series="seriesExams"
                              ></apexchart>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style="width: 10px">#</th>
                  <th>Даалгавар</th>
                  <th>Сельф систем-1</th>
                  <th>Метакогнитив систем-2</th>
                  <th>Когнитив систем-1</th>
                  <th>Когнитив систем-2</th>
                  <th>Когнитив систем-3</th>
                  <th>Когнитив систем-4</th>
                  <th>Метакогнитив систем-2</th>
                  <th>Сельф систем-2</th>
                </tr>
                <tr
                  v-for="(exam, index) in selectChildCourseExamValues"
                  :key="exam.id"
                >
                  <td>{{ ++index }}</td>
                  <td>{{ exam.daalgar_number }}</td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam1Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam2Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam3Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam4Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam5Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam6Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam7Explpain }}
                    </span>
                  </td>
                  <td>
                    <span
                      data-v-1e2e2960=""
                      class="badge text-wrap text-left badge-light"
                    >
                      {{ exam.exam8Explpain }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["selectedLessonExamData"],
  data() {
    return {
      changeSelectedLessonExamData: [],
      //   symbolGrade: "",
      exam1CountA: [],
      exam1CountB: [],
      exam1CountC: [],
      exam1CountD: [],
      exam1CountF: [],
      exam1Average: [],
      exam1CalsAvg: "",
      exam1CountSuccess: 0,
      exam1CountQuality: 0,
      exam2CountA: [],
      exam2CountB: [],
      exam2CountC: [],
      exam2CountD: [],
      exam2CountF: [],
      exam2Average: [],
      exam2CalsAvg: "",
      exam2Average: [],
      exam2CountSuccess: 0,
      exam2CountQuality: 0,
      exam3CountA: [],
      exam3CountB: [],
      exam3CountC: [],
      exam3CountD: [],
      exam3CountF: [],
      exam3Average: [],
      exam3CalsAvg: "",
      exam3CountSuccess: 0,
      exam3CountQuality: 0,
      exam4CountA: [],
      exam4CountB: [],
      exam4CountC: [],
      exam4CountD: [],
      exam4CountF: [],
      exam4Average: [],
      exam4CalsAvg: "",
      exam4CountSuccess: 0,
      exam4CountQuality: 0,
      exam5CountA: [],
      exam5CountB: [],
      exam5CountC: [],
      exam5CountD: [],
      exam5CountF: [],
      exam5Average: [],
      exam5CalsAvg: "",
      exam5CountSuccess: 0,
      exam5CountQuality: 0,
      exam6CountA: [],
      exam6CountB: [],
      exam6CountC: [],
      exam6CountD: [],
      exam6CountF: [],
      exam6Average: [],
      exam6CalsAvg: "",
      exam6CountSuccess: 0,
      exam6CountQuality: 0,
      exam7CountA: [],
      exam7CountB: [],
      exam7CountC: [],
      exam7CountD: [],
      exam7CountF: [],
      exam7Average: [],
      exam7CalsAvg: "",
      exam7CountSuccess: 0,
      exam7CountQuality: 0,
      exam8CountA: [],
      exam8CountB: [],
      exam8CountC: [],
      exam8CountD: [],
      exam8CountF: [],
      exam8Average: [],
      exam8CalsAvg: "",
      exam8CountSuccess: 0,
      exam8CountQuality: 0,
      examAverageCountA: [],
      examAverageCountB: [],
      examAverageCountC: [],
      examAverageCountD: [],
      examAverageCountF: [],
      examAverageFull: [],
      examCalsAvgFull: "",
      examAverageCountSuccess: 0,
      examAverageCountQuality: 0,

      // График
      selectChildCourseExamValues: [],
      viewYearChart: false,
      optionsGenderAllClass: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107", "#6495ED"],
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#BED1E6",
                colorTo: "#D8E3F0",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesExams: [
        // {
        //   name: "Ангийн дундаж",
        //   data: [],
        // },
        // {
        //   name: "Сонгосон шалгалт",
        //   data: [],
        // },
      ],
      examPerValuesAverage: [],
    };
  },
  methods: {
    // saveStudentFinalQuarter(examData) {
    //   console.log("Дүнг хадгалах", examData);
    //   Swal.fire({
    //     title: "Дүн баталгаажуулах?",
    //     text:
    //       examData.student_last_name[0].toUpperCase() +
    //       examData.student_last_name[1] +
    //       "." +
    //       examData.student_name,
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Тийм, Дүнг баталгаажуулах!",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       axios
    //         .post("/manager/confirmExam", { examData: examData })
    //         .then((response) => {
    //           console.log(response.data);
    //         })
    //         .catch((errors) => {
    //           console.log(errors);
    //         });
    //       Swal.fire(
    //         "Амжилттай баталгаажууллаа!",
    //         "Сонгогдсон суралцагчын дүн баталгаажсан.",
    //         "success"
    //       );
    //     }
    //   });
    // },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return "A+, GPA 4.33 or 4.00";
      } else if (gradeValue >= 93) {
        return "A, GPA 4.00";
      } else if (gradeValue >= 90) {
        return "A-, GPA 3.67";
      } else if (gradeValue >= 87) {
        return "B+, GPA 3.33";
      } else if (gradeValue >= 83) {
        return "B, GPA 3.00";
      } else if (gradeValue >= 80) {
        return "B-, GPA 2.67";
      } else if (gradeValue >= 77) {
        return "C+, GPA 2.33";
      } else if (gradeValue >= 73) {
        return "C, GPA 2.00";
      } else if (gradeValue >= 70) {
        return "C-, GPA 1.67";
      } else if (gradeValue >= 67) {
        return "D+, GPA 1.33";
      } else if (gradeValue >= 63) {
        return "D, GPA 1.00";
      } else if (gradeValue >= 60) {
        return "D-, GPA 0.67";
      } else {
        return "F, GPA 0.00";
      }
    },
    countByMyExams() {
      this.changeSelectedLessonExamData = [...this.selectedLessonExamData];
      //   console.log(this.selectedLessonExamData);
      this.changeSelectedLessonExamData.forEach((el, index) => {
        this.exam1Average.push(el.exam1);
        this.exam2Average.push(el.exam2);
        this.exam3Average.push(el.exam3);
        this.exam4Average.push(el.exam4);
        this.exam5Average.push(el.exam5);
        this.exam6Average.push(el.exam6);
        this.exam7Average.push(el.exam7);
        this.exam8Average.push(el.exam8);
        this.examAverageFull.push(el.examAverage);
        if (el.exam1 >= 90) {
          this.exam1CountA.push(el.exam1);
        } else if (el.exam1 >= 80) {
          this.exam1CountB.push(el.exam1);
        } else if (el.exam1 >= 70) {
          this.exam1CountC.push(el.exam1);
        } else if (el.exam1 >= 60) {
          this.exam1CountD.push(el.exam1);
        } else {
          this.exam1CountF.push(el.exam1);
        }

        if (el.exam2 >= 90) {
          this.exam2CountA.push(el.exam2);
        } else if (el.exam2 >= 80) {
          this.exam2CountB.push(el.exam2);
        } else if (el.exam2 >= 70) {
          this.exam2CountC.push(el.exam2);
        } else if (el.exam2 >= 60) {
          this.exam2CountD.push(el.exam2);
        } else {
          this.exam2CountF.push(el.exam2);
        }

        if (el.exam3 >= 90) {
          this.exam3CountA.push(el.exam3);
        } else if (el.exam3 >= 80) {
          this.exam3CountB.push(el.exam3);
        } else if (el.exam3 >= 70) {
          this.exam3CountC.push(el.exam3);
        } else if (el.exam3 >= 60) {
          this.exam3CountD.push(el.exam3);
        } else {
          this.exam3CountF.push(el.exam3);
        }

        if (el.exam4 >= 90) {
          this.exam4CountA.push(el.exam4);
        } else if (el.exam4 >= 80) {
          this.exam4CountB.push(el.exam4);
        } else if (el.exam4 >= 70) {
          this.exam4CountC.push(el.exam4);
        } else if (el.exam4 >= 60) {
          this.exam4CountD.push(el.exam4);
        } else {
          this.exam4CountF.push(el.exam4);
        }

        if (el.exam5 >= 90) {
          this.exam5CountA.push(el.exam5);
        } else if (el.exam5 >= 80) {
          this.exam5CountB.push(el.exam5);
        } else if (el.exam5 >= 70) {
          this.exam5CountC.push(el.exam5);
        } else if (el.exam5 >= 60) {
          this.exam5CountD.push(el.exam5);
        } else {
          this.exam5CountF.push(el.exam5);
        }

        if (el.exam6 >= 90) {
          this.exam6CountA.push(el.exam6);
        } else if (el.exam6 >= 80) {
          this.exam6CountB.push(el.exam6);
        } else if (el.exam6 >= 70) {
          this.exam6CountC.push(el.exam6);
        } else if (el.exam6 >= 60) {
          this.exam6CountD.push(el.exam6);
        } else {
          this.exam6CountF.push(el.exam6);
        }

        if (el.exam7 >= 90) {
          this.exam7CountA.push(el.exam7);
        } else if (el.exam7 >= 80) {
          this.exam7CountB.push(el.exam7);
        } else if (el.exam7 >= 70) {
          this.exam7CountC.push(el.exam7);
        } else if (el.exam7 >= 60) {
          this.exam7CountD.push(el.exam7);
        } else {
          this.exam7CountF.push(el.exam7);
        }

        if (el.exam8 >= 90) {
          this.exam8CountA.push(el.exam8);
        } else if (el.exam8 >= 80) {
          this.exam8CountB.push(el.exam8);
        } else if (el.exam8 >= 70) {
          this.exam8CountC.push(el.exam8);
        } else if (el.exam8 >= 60) {
          this.exam8CountD.push(el.exam8);
        } else {
          this.exam8CountF.push(el.exam8);
        }

        if (el.examAverage >= 90) {
          this.examAverageCountA.push(el.examAverage);
        } else if (el.examAverage >= 80) {
          this.examAverageCountB.push(el.examAverage);
        } else if (el.examAverage >= 70) {
          this.examAverageCountC.push(el.examAverage);
        } else if (el.examAverage >= 60) {
          this.examAverageCountD.push(el.examAverage);
        } else {
          this.examAverageCountF.push(el.examAverage);
        }
      });
      //   console.log(this.changeSelectedLessonExamData);
      //Exam1 average утгыг бодож олъё.
      var exam1Sum = 0;
      for (var i = 0; i < this.exam1Average.length; i++) {
        exam1Sum += parseFloat(this.exam1Average[i]); //don't forget to add the base
      }
      this.exam1CalsAvg = (exam1Sum / this.exam1Average.length).toFixed(1);
      // console.log(this.exam1CalsAvg);
      //Exam2 average утгыг бодож олъё.
      var exam2Sum = 0;
      for (var i = 0; i < this.exam2Average.length; i++) {
        exam2Sum += parseFloat(this.exam2Average[i]); //don't forget to add the base
      }
      this.exam2CalsAvg = (exam2Sum / this.exam2Average.length).toFixed(1);
      // console.log(this.exam2CalsAvg);
      //Exam3 average утгыг бодож олъё.
      var exam3Sum = 0;
      for (var i = 0; i < this.exam3Average.length; i++) {
        exam3Sum += parseFloat(this.exam3Average[i]); //don't forget to add the base
      }
      this.exam3CalsAvg = (exam3Sum / this.exam3Average.length).toFixed(1);
      // console.log(this.exam3CalsAvg);
      //Exam4 average утгыг бодож олъё.
      var exam4Sum = 0;
      for (var i = 0; i < this.exam4Average.length; i++) {
        exam4Sum += parseFloat(this.exam4Average[i]); //don't forget to add the base
      }
      this.exam4CalsAvg = (exam4Sum / this.exam4Average.length).toFixed(1);
      // console.log(this.exam4CalsAvg);
      //Exam5 average утгыг бодож олъё.
      var exam5Sum = 0;
      for (var i = 0; i < this.exam5Average.length; i++) {
        exam5Sum += parseFloat(this.exam5Average[i]); //don't forget to add the base
      }
      this.exam5CalsAvg = (exam5Sum / this.exam5Average.length).toFixed(1);
      // console.log(this.exam5CalsAvg);
      //Exam6 average утгыг бодож олъё.
      var exam6Sum = 0;
      for (var i = 0; i < this.exam6Average.length; i++) {
        exam6Sum += parseFloat(this.exam6Average[i]); //don't forget to add the base
      }
      this.exam6CalsAvg = (exam6Sum / this.exam6Average.length).toFixed(1);
      // console.log(this.exam6CalsAvg);
      //Exam7 average утгыг бодож олъё.
      var exam7Sum = 0;
      for (var i = 0; i < this.exam7Average.length; i++) {
        exam7Sum += parseFloat(this.exam7Average[i]); //don't forget to add the base
      }
      this.exam7CalsAvg = (exam7Sum / this.exam7Average.length).toFixed(1);
      // console.log(this.exam7CalsAvg);
      //Exam8 average утгыг бодож олъё.
      var exam8Sum = 0;
      for (var i = 0; i < this.exam8Average.length; i++) {
        exam8Sum += parseFloat(this.exam8Average[i]); //don't forget to add the base
      }
      this.exam8CalsAvg = (exam8Sum / this.exam8Average.length).toFixed(1);
      // console.log(this.exam8CalsAvg);
      //Exam нийлбэр average утгыг бодож олъё.
      var examSum = 0;
      for (var i = 0; i < this.examAverageFull.length; i++) {
        examSum += parseFloat(this.examAverageFull[i]); //don't forget to add the base
      }
      this.examCalsAvgFull = (examSum / this.examAverageFull.length).toFixed(1);
      // console.log(this.exam1CalsAvg);
      //Exam1 амжилт чанар
      this.exam1CountSuccess =
        ((this.exam1CountA.length +
          this.exam1CountB.length +
          this.exam1CountC.length +
          this.exam1CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam1CountQuality =
        ((this.exam1CountA.length + this.exam1CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam2 амжилт чанар
      this.exam2CountSuccess =
        ((this.exam2CountA.length +
          this.exam2CountB.length +
          this.exam2CountC.length +
          this.exam2CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam2CountQuality =
        ((this.exam2CountA.length + this.exam2CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam3 амжилт чанар
      this.exam3CountSuccess =
        ((this.exam3CountA.length +
          this.exam3CountB.length +
          this.exam3CountC.length +
          this.exam3CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam3CountQuality =
        ((this.exam3CountA.length + this.exam3CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam4 амжилт чанар
      this.exam4CountSuccess =
        ((this.exam4CountA.length +
          this.exam4CountB.length +
          this.exam4CountC.length +
          this.exam4CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam4CountQuality =
        ((this.exam4CountA.length + this.exam4CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam5 амжилт чанар
      this.exam5CountSuccess =
        ((this.exam5CountA.length +
          this.exam5CountB.length +
          this.exam5CountC.length +
          this.exam5CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam5CountQuality =
        ((this.exam5CountA.length + this.exam5CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam6 амжилт чанар
      this.exam6CountSuccess =
        ((this.exam6CountA.length +
          this.exam6CountB.length +
          this.exam6CountC.length +
          this.exam6CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam6CountQuality =
        ((this.exam6CountA.length + this.exam6CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam7 амжилт чанар
      this.exam7CountSuccess =
        ((this.exam7CountA.length +
          this.exam7CountB.length +
          this.exam7CountC.length +
          this.exam7CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam7CountQuality =
        ((this.exam7CountA.length + this.exam7CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Exam8 амжилт чанар
      this.exam8CountSuccess =
        ((this.exam8CountA.length +
          this.exam8CountB.length +
          this.exam8CountC.length +
          this.exam8CountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.exam8CountQuality =
        ((this.exam8CountA.length + this.exam8CountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //Ерөнхий амжилт чанар
      this.examAverageCountSuccess =
        ((this.examAverageCountA.length +
          this.examAverageCountB.length +
          this.examAverageCountC.length +
          this.examAverageCountD.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      this.examAverageCountQuality =
        ((this.examAverageCountA.length + this.examAverageCountB.length) /
          this.changeSelectedLessonExamData.length) *
        100;
      //   console.log(this.exam1CountA);
    },

    getExamValueStudentsManager(newVal) {
      // console.log(newVal);
      this.selectChildCourseExamValues = [];
      axios
        .post("/manager/getExamValueStudentsManager", {
          exam: newVal,
        })
        .then((res) => {
          //   console.log(res.data);
          this.$bvModal.show("modal-taxanomy-stat");
          this.selectChildCourseExamValues = res.data.examValues;
          this.calculateAverageExamValues(this.selectChildCourseExamValues);
          this.optionsGenderAllClass.xaxis.categories = [];
          this.seriesExams = [];

          this.optionsGenderAllClass.xaxis.categories = [
            "Алхам-1:Сельф-1",
            "Алхам-2:Метакогнитив-1",
            "Алхам-3:Когнитив-1",
            "Алхам-4:Когнитив-2",
            "Алхам-5:Когнитив-3",
            "Алхам-6:Когнитив-4",
            "Алхам-7:Метакогнитив-2",
            "Алхам-8:Сельф-2",
          ];

          this.selectChildCourseExamValues.forEach((element) => {
            this.seriesExams.push({
              name: "Даалгавар-" + element.daalgar_number,
              data: [
                element.exam1 === null ? 0 : parseFloat(element.exam1),
                element.exam2 === null ? 0 : parseFloat(element.exam2),
                element.exam3 === null ? 0 : parseFloat(element.exam3),
                element.exam4 === null ? 0 : parseFloat(element.exam4),
                element.exam5 === null ? 0 : parseFloat(element.exam5),
                element.exam6 === null ? 0 : parseFloat(element.exam6),
                element.exam7 === null ? 0 : parseFloat(element.exam7),
                element.exam8 === null ? 0 : parseFloat(element.exam8),
              ],
            });
          });

          let urt0 = this.seriesExams.length;

          // examPerValuesAverage.exam1Ave.toFixed(1)
          this.seriesExams[urt0] = {
            name: "Миний дундаж",
            data: [
              this.examPerValuesAverage.exam1Ave.toFixed(1),
              this.examPerValuesAverage.exam2Ave.toFixed(1),
              this.examPerValuesAverage.exam3Ave.toFixed(1),
              this.examPerValuesAverage.exam4Ave.toFixed(1),
              this.examPerValuesAverage.exam5Ave.toFixed(1),
              this.examPerValuesAverage.exam6Ave.toFixed(1),
              this.examPerValuesAverage.exam7Ave.toFixed(1),
              this.examPerValuesAverage.exam8Ave.toFixed(1),
            ],
          };
          let urt = this.seriesExams.length;

          // examPerValuesAverage.exam1Ave.toFixed(1)
          this.seriesExams[urt] = {
            name: "Ангийн дундаж",
            data: [
              res.data.selectedExamClassAVG[0]["exam1"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam1"]),
              res.data.selectedExamClassAVG[0]["exam2"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam2"]),
              res.data.selectedExamClassAVG[0]["exam3"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam3"]),
              res.data.selectedExamClassAVG[0]["exam4"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam4"]),
              res.data.selectedExamClassAVG[0]["exam5"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam5"]),
              res.data.selectedExamClassAVG[0]["exam6"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam6"]),
              res.data.selectedExamClassAVG[0]["exam7"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam7"]),
              res.data.selectedExamClassAVG[0]["exam8"] === null
                ? 0
                : parseFloat(res.data.selectedExamClassAVG[0]["exam8"]),
            ],
          };

          // this.seriesExams[1].data = res.data.allClassStdEr;
          this.viewYearChart = true;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    transferInt(value) {
      return parseInt(value);
    },
    averageExamStep(exam) {
      let averageExam =
        parseFloat(exam.exam1) * 0.1 +
        parseFloat(exam.exam2) * 0.1 +
        parseFloat(exam.exam3) * 0.1 +
        parseFloat(exam.exam4) * 0.1 +
        parseFloat(exam.exam5) * 0.2 +
        parseFloat(exam.exam6) * 0.2 +
        parseFloat(exam.exam7) * 0.1 +
        parseFloat(exam.exam8) * 0.1;
      return averageExam;
    },
    calculateAverageExamValues(values) {
      //   console.log(values);
      let exam1 = [];
      let exam2 = [];
      let exam3 = [];
      let exam4 = [];
      let exam5 = [];
      let exam6 = [];
      let exam7 = [];
      let exam8 = [];
      let exam1_8 = [];
      values.forEach((element) => {
        exam1.push(parseFloat(element.exam1));
        exam2.push(parseFloat(element.exam2));
        exam3.push(parseFloat(element.exam3));
        exam4.push(parseFloat(element.exam4));
        exam5.push(parseFloat(element.exam5));
        exam6.push(parseFloat(element.exam6));
        exam7.push(parseFloat(element.exam7));
        exam8.push(parseFloat(element.exam8));
        exam1_8.push(
          parseFloat(
            element.exam1 * 0.1 +
              element.exam2 * 0.1 +
              element.exam3 * 0.1 +
              element.exam4 * 0.1 +
              element.exam5 * 0.2 +
              element.exam6 * 0.2 +
              element.exam7 * 0.1 +
              element.exam8 * 0.1
          )
        );
      });
      //   console.log(exam1);
      let exam1Sum = exam1.reduce(function (a, b) {
        return a + b;
      });
      let exam2Sum = exam2.reduce(function (a, b) {
        return a + b;
      });
      let exam3Sum = exam3.reduce(function (a, b) {
        return a + b;
      });
      let exam4Sum = exam4.reduce(function (a, b) {
        return a + b;
      });
      let exam5Sum = exam5.reduce(function (a, b) {
        return a + b;
      });
      let exam6Sum = exam6.reduce(function (a, b) {
        return a + b;
      });
      let exam7Sum = exam7.reduce(function (a, b) {
        return a + b;
      });
      let exam8Sum = exam8.reduce(function (a, b) {
        return a + b;
      });
      let exam1_8Sum = exam1_8.reduce(function (a, b) {
        return a + b;
      });
      this.examPerValuesAverage.exam1Ave = exam1Sum / exam1.length;
      this.examPerValuesAverage.exam2Ave = exam2Sum / exam2.length;
      this.examPerValuesAverage.exam3Ave = exam3Sum / exam3.length;
      this.examPerValuesAverage.exam4Ave = exam4Sum / exam4.length;
      this.examPerValuesAverage.exam5Ave = exam5Sum / exam5.length;
      this.examPerValuesAverage.exam6Ave = exam6Sum / exam6.length;
      this.examPerValuesAverage.exam7Ave = exam7Sum / exam7.length;
      this.examPerValuesAverage.exam8Ave = exam8Sum / exam8.length;
      this.examPerValuesAverage.exam1_8Ave = exam1_8Sum / exam1_8.length;
      //   console.log(this.examPerValuesAverage);
    },
  },
  beforeMount() {
    // console.log("BeforeMount хийхээс өмнө дуудлаа.");
    this.countByMyExams();
    // this.symbolGradeCheck();
  },
  created() {},
};
</script>

<style></style>
