<template>
  <tr>
    <td>{{ tooluur + 1 }}</td>
    <td>{{ passPropsMyLessonDateSingle.school_class_full_name }}</td>
    <td>
      {{ passPropsMyLessonDateSingle.student_last_name[0].toUpperCase()
      }}{{ passPropsMyLessonDateSingle.student_last_name[1] }}.
      {{ passPropsMyLessonDateSingle.student_name }}
    </td>

    <td>
      {{ passPropsMyLessonDateSingle.attendance_type }}
    </td>
    <td>
      <span class="badge bg-danger"
        ><i
          class="fas fa-trash-alt btn btn-danger"
          @click="
            deleteStudentAttendance(
              passPropsMyLessonDateSingle.attendance_id,
              tooluur
            )
          "
        ></i
      ></span>
    </td>
  </tr>
</template>

<script>
export default {
  props: ["passPropsMyLessonDateSingle", "tooluur"],
  methods: {
    deleteStudentAttendance(attendance_id, tooluur) {
      Swal.fire({
        title: "Ирцийн мэдээллийг устгахдаа итгэлтэй байна уу?",
        text: "Сонгогдсон ирцийн бүртгэлийг системээс устгалаа!!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/selectedStudentAttendanceDelete", {
              attendance_id: attendance_id,
            })
            .then((response) => {
              this.$emit("attendance-student-remove", attendance_id, tooluur);
              Swal.fire(
                "Амжилттай устгалаа!",
                "Сонгосон суралцагчийн ирцийн мэдээлэл устлаа.",
                "success"
              );
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },

  created() {
    // console.log(this.passPropsMyLessonDateSingle);
  },
};
</script>

<style>
</style>
