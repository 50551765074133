<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col cols="2"
              ><h3 class="card-title">Үнэлгээ сар: Хүний нөөц</h3></b-col
            >
            <b-col cols="2">
              <multiselect
                v-if="userInfo.name == 'cipalprin'"
                v-model="yearId"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                :custom-label="customYearName"
                placeholder="Хичээлийн жил сонгоно уу?"
                :options="years"
                :searchable="true"
                :allow-empty="true"
              >
                <!-- @input="filterByMonthED" -->
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                    - хичээлийн жил
                  </strong></template
                >
              </multiselect>
            </b-col>
            <b-col cols="2">
              <multiselect
                v-model="selectedMonths"
                :options="monthsRe"
                track-by="name"
                label="name"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                placeholder="Сар сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect></b-col
            >
            <b-col cols="2"
              ><b-button
                @click.prevent="filterByMonthED()"
                class="btn btn-warning"
                >Өгөгдөл</b-button
              ></b-col
            >
            <b-col cols="4">
              <download-excel
                v-show="downloadData"
                :data="myAllEvaluations"
                class="btn btn-default"
                :fields="json_fields"
              >
                Сарын үнэлгээ татах
              </download-excel>
              <b-button
                v-show="downloadData"
                variant="warning"
                @click.prevent="editEmployeesShal13()"
                >Цаг бүртгэх</b-button
              >
            </b-col>
          </b-row>
          <!-- <div class="d-flex justify-content-between"></div> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <!-- Сургалт хариуцсан захирал -->
            <thead v-if="allEvaluationsMinadCurr.length != 0">
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsMinadCurr.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsMinadCurr"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info"> {{ evaluation.shal18 }} ц </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{
                      calculateAverageSKhZ(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationMinadCurr(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Цогцолборын менежерүүд -->
            <thead v-if="allEvaluationsManager.length != 0">
              <tr role="row">
                <th colspan="21">Цогцолборын менежерүүд</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsManager.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsManager"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageMan(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }} %
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationManager(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Сургуулийн багш -->

            <thead v-if="allEvaluationsTeacher.length != 0">
              <tr role="row">
                <th colspan="21">Сургуулийн багш нар</th>
              </tr>
              <tr role="row" v-if="allEvaluationsTeacher.length != 0">
                <th colspan="8">
                  <multiselect
                    v-model="selectedDepartments"
                    :options="allSchoolDep"
                    :multiple="true"
                    :custom-label="customDepartmentLabel"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Шүүж харах мэргэжлийн багийг сонгоно уу?"
                    label="department_name"
                    track-by="department_name"
                    :preselect-first="true"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >Нийт {{ values.length }} мэргэжлийн баг сонгогдлоо.
                        Сонгогдсон мэргэжлийн багаар нэгтгэл харагдана.</span
                      ></template
                    >
                  </multiselect>
                </th>
                <th colspan="13">
                  <b-button
                    variant="success"
                    @click.prevent="filterByMonthDepED()"
                    >БНБ сонгох</b-button
                  >
                </th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsTeacher.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsTeacher"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageTeach(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationTeacher(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            <!-- Суралцахуйн албаны ажилтан -->
            <thead v-if="allEvaluationsEmployee.length != 0">
              <tr role="row">
                <th colspan="21">Суралцахуйн албаны ажилчид</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsEmployee.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployee"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageEmp(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationEmployee(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>

            <!-- Цэцэрлэгийн багш -->

            <thead v-if="allEvaluationsTeacherKG.length != 0">
              <tr role="row">
                <th colspan="21">Цэцэрлэгийн багш нар</th>
              </tr>

              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsTeacherKG.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsTeacherKG"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageTeach(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationTeacher(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>

            <!-- Спорт клубын багш -->
            <thead v-if="allEvaluationsTeacherSK.length != 0">
              <tr role="row">
                <th colspan="21">Спорт клубын багш нар</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsTeacherSK.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsTeacherSK"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageTeach(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationTeacher(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>

            <!-- Спорт клубын ажилтан -->
            <thead v-if="allEvaluationsEmployeeSK.length != 0">
              <tr role="row">
                <th colspan="21">Спорт клубын ажилчид</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsEmployeeSK.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployeeSK"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageEmp(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationEmployee(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>

            <!-- Үйл ажиллагааны албаны ажилтан -->
            <thead v-if="allEvaluationsEmployeeVAA.length != 0">
              <tr role="row">
                <th colspan="21">Үйл ажиллагааны албаны ажилчид</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsEmployeeVAA.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployeeVAA"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageEmp(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationEmployee(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>

            <!-- Санхүүгийн албаны ажилтан -->
            <thead v-if="allEvaluationsEmployeeFinance.length != 0">
              <tr role="row">
                <th colspan="21">Санхүүгийн албаны ажилчид</th>
              </tr>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Мэргэжлийн баг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Ажилтны эцэг/эх/-ийн нэр</th>
                <th class="text-sm">Ажилтны нэр</th>
                <th class="text-sm">Emp ID</th>
                <th class="text-sm">Ажиллах нийт цаг</th>
                <th class="text-sm">Цалин тооцох цаг</th>
                <th class="text-sm">Илүү цаг(1.5)</th>
                <th class="text-sm">
                  Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                </th>
                <th class="text-sm">Цалинтай чөлөө</th>
                <th class="text-sm">Цалингүй чөлөө</th>
                <th class="text-sm">Тасалсан</th>
                <th class="text-sm">Үр дүнгийн урамшуулал</th>
                <th class="text-sm">АУБ үнэлгээ</th>
                <th class="text-sm">БНБ ахалсан</th>
                <th class="text-sm">Мэргэжлийн зэрэг</th>
                <th class="text-sm">Эрдмийн зэрэг</th>
                <th class="text-sm">Спортын зэрэг</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-if="allEvaluationsEmployeeFinance.length != 0">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployeeFinance"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.department_code }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary">
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-info"> EmpID</span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span class="badge bg-info">
                    {{ evaluation.shal13 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal14 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal15 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal16 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    {{ evaluation.shal18 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span class="badge bg-info">
                    <!-- {{
                      calculateAverageEmp(
                        evaluation.shal1,
                        evaluation.shal2,
                        evaluation.shal3,
                        evaluation.shal4
                      )
                    }}% -->
                    {{ evaluation.shal1_4.toFixed(1) }}%
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="
                      (evaluation.shal5 != null && evaluation.shal5 != 0) ||
                      (evaluation.shal6 != null && evaluation.shal6 != 0)
                    "
                  >
                    {{
                      calculateAverageAUB(evaluation.shal5, evaluation.shal6)
                    }}
                  </span>
                  <span class="badge bg-info" v-else> 0 </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal8 != null || evaluation.shal8 != 0"
                    >0</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ evaluation.shal8 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal9 == null || evaluation.shal9 == 0"
                    >Зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateZereg(evaluation.shal9) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal10 == null || evaluation.shal10 == 0"
                    >Эрдмийн зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateErdZereg(evaluation.shal10) }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-info"
                    v-if="evaluation.shal17 == null || evaluation.shal17 == 0"
                    >Спортын зэрэггүй(0 %)</span
                  >
                  <span class="badge bg-info" v-else>
                    {{ calculateSportZereg(evaluation.shal17) }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editEvaluationEmployee(evaluation)"
                    ><i class="fas fa-marker"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      id="modal-minadCurr"
      ref="modal"
      title="Submit Your Name"
      size="xl"
      @show="resetModal"
      @hidden="cancelEdit"
      @ok="handleOk"
      hide-footer
    >
      <template v-slot:modal-title>
        <span v-show="editMode">
          Сургалт хариуцсан захиралын өгөгдлийг засах
        </span>
        <span v-show="editModeManager">
          Сургалтын хөтөлбөр хариуцсан менежерийн өгөгдлийг засах
        </span>
        <span v-show="editModeTeacher"> Багш нарын өгөгдлийг засах </span>
        <span v-show="editModeEmployee"> Ажилчдын өгөгдлийг засах </span>
      </template>
      <template>
        <form @submit.prevent="updateMinadCurrEvaluation()">
          <b-container fluid>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Ажиллах нийт цаг <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal13"
                  type="number"
                  v-model="form.shal13"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for="">Цалин тооцох цаг <code></code>:</label>
              </b-col>
              <b-col sm="4"> {{ shal12Solve }} ц </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Илүү цаг 1.5 <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal14"
                  type="number"
                  v-model="form.shal14"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for=""
                  >Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                  <code></code>:</label
                >
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal7"
                  type="number"
                  v-model="form.shal7"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Цалинтай чөлөө <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal15"
                  type="number"
                  v-model="form.shal15"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for="">Цалингүй чөлөө <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal16"
                  type="number"
                  v-model="form.shal16"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Тасалсан <code></code>:</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  id="shal18"
                  type="number"
                  v-model="form.shal18"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="cancelEdit"
            >Цуцлах</b-button
          >
          <b-button type="submit" class="mt-2" variant="outline-warning" block
            >Хадгалах</b-button
          >
        </form>
      </template>
    </b-modal>

    <!-- Цаг бүртгэх -->
    <b-modal
      id="modal-register-employees-time"
      ref="modal"
      title="Бүх ажилчид дээр цаг бүртгэх"
      size="xl"
      @hidden="cancelEmployeeTime"
      hide-footer
    >
      <!-- @ok="handleOk"
      @show="resetModal" -->
      <!-- <template v-slot:modal-title>
        <span v-show="editMode">
          Сургалт хариуцсан захиралын өгөгдлийг засах
        </span>
        <span v-show="editModeManager">
          Сургалтын хөтөлбөр хариуцсан менежерийн өгөгдлийг засах
        </span>
        <span v-show="editModeTeacher"> Багш нарын өгөгдлийг засах </span>
        <span v-show="editModeEmployee"> Ажилчдын өгөгдлийг засах </span>
      </template> -->
      <template>
        <form @submit.prevent="updateEmployeesShal13()">
          <b-container fluid>
            <b-row class="my-1">
              <multiselect
                v-model="formEmployeesTime.ajliin_sar"
                :options="monthsRe"
                track-by="name"
                label="name"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                placeholder="Сар сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Ажиллах нийт цаг <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="shal13"
                  type="number"
                  v-model="formEmployeesTime.shal13"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for="">Цалин тооцох цаг <code></code>:</label>
              </b-col>
              <b-col sm="4"> {{ shal12Solve }} ц </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Илүү цаг 1.5 <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  disabled
                  id="shal14"
                  type="number"
                  v-model="formEmployeesTime.shal14"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for=""
                  >Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)
                  <code></code>:</label
                >
              </b-col>
              <b-col sm="4">
                <b-form-input
                  disabled
                  id="shal7"
                  type="number"
                  v-model="formEmployeesTime.shal7"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Цалинтай чөлөө <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  disabled
                  id="shal15"
                  type="number"
                  v-model="formEmployeesTime.shal15"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <label for="">Цалингүй чөлөө <code></code>:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  disabled
                  id="shal16"
                  type="number"
                  v-model="formEmployeesTime.shal16"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label for="">Тасалсан <code></code>:</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  disabled
                  id="shal18"
                  type="number"
                  v-model="formEmployeesTime.shal18"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="cancelEmployeeTime"
            >Цуцлах</b-button
          >
          <b-button type="submit" class="mt-2" variant="outline-warning" block
            >Хадгалах</b-button
          >
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      yearId: null,
      action: "",
      editMode: false,
      editModeManager: false,
      editModeTeacher: false,
      editModeEmployee: false,
      updateMode: "",
      //   keyword: "",
      allEvaluationsMinadCurr: [],
      allEvaluationsManager: [],
      allEvaluationsTeacher: [],
      allEvaluationsTeacherRestart: [],
      allEvaluationsEmployee: [],
      // Шинээр нэмэгдсэн бусад албадууд
      allEvaluationsTeacherKG: [],
      allEvaluationsTeacherRestartKG: [],
      allEvaluationsTeacherSK: [],
      allEvaluationsTeacherRestartSK: [],
      allEvaluationsEmployeeSK: [],
      allEvaluationsEmployeeVAA: [],
      allEvaluationsEmployeeFinance: [],

      allSchoolDep: [],

      selectedDepartments: "",
      averageMyDepartmentsED: "",
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      // Формтой ажиллах
      form: new Form({
        ajliin_sar: "",
        confirmed: "",
        department_code: "",
        department_id: "",
        department_name: "",
        emp_id: "",
        emp_teacher_last_name: "",
        emp_teacher_name: "",
        employee_id: "",
        enrol_emp_id: "",
        id: "",
        name: "",
        position_id: "",
        position_name: "",
        read: "",
        school_id: "",
        shal1: "",
        shal1_temd: "",
        shal2: "",
        shal2_temd: "",
        shal3: "",
        shal3_temd: "",
        shal4: "",
        shal4_temd: "",
        shal5: "",
        shal5_temd: "",
        shal6: "",
        shal6_temd: "",
        shal7: "",
        shal7_temd: "",
        shal8: "",
        shal8_temd: "",
        shal9: "",
        shal9_temd: "",
        shal10: "",
        shal10_temd: "",
        shal11: "",
        shal11_temd: "",
        shal12: "",
        shal12_temd: "",
        shal13: "",
        shal13_temd: "",
        shal14: "",
        shal14_temd: "",
        shal15: "",
        shal15_temd: "",
        shal16: "",
        shal16_temd: "",
        shal17: "",
        shal18: "",
        shal18_temd: "",
        teacher_id: "",
        user_email: "",
        year_id: "",
      }),

      myAllEvaluations: [],
      downloadData: false,
      //Татаж авах тест дата
      json_fields: {
        "Мэргэжлийн баг": "department_code",
        "Албан тушаал": "position_name",
        "Ажилтны эцэг/эх/-ийн нэр": "emp_teacher_last_name",
        "Ажилтны нэр": "emp_teacher_name",
        "Ажиллах нийт цаг": "shal13",
        "Цалин тооцох цаг": "shal12",
        "Илүү цаг(1.5)": "shal14",
        "Илүү цаг(Хичээлийн цагийн нормоос хэтэрсэн)": "shal7",
        "Цалинтай чөлөө": "shal15",
        "Цалингүй чөлөө": "shal16",
        Тасалсан: "shal18",
        "Үр дүнгийн урамшуулал": "shal1_4",
        "АУБ үнэлгээ": "shal5_6",
        "БНБ ахалсан": "shal8_per",
        "Мэргэжлийн зэрэг": "shal9_per",
        "Эрдмийн зэрэг": "shal10_per",
        "Спортын зэрэг": "shal17_per",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      //   Бүх ажилчдын цаг бүртгэх
      formEmployeesTime: new Form({
        ajliin_sar: "",
        shal13: "",
      }),
    };
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
    shal12Solve: function () {
      return (this.form.shal12 =
        this.form.shal13 - this.form.shal16 - this.form.shal18);
    },

    avgMyDepTeachEd: function () {
      let averageMyDepartmentsED = 0;

      this.allEvaluationsTeacher.forEach((el) => {
        averageMyDepartmentsED = averageMyDepartmentsED + el.shal1_4;
      });

      let avg = averageMyDepartmentsED / this.allEvaluationsTeacher.length;

      return avg.toFixed(1);
    },
  },

  components: {
    Multiselect,
  },
  methods: {
    //Үр дүнгийн урамшууллын тооцоолол
    calculateAverageSKhZ(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        parseFloat(shal1) * 0.1 +
        parseFloat(shal2) * 0.4 +
        parseFloat(shal3) * 0.4 +
        parseFloat(shal4) * 0.1;
      let mySKZ = (averageMyPer * 20) / 100;
      return mySKZ.toFixed(1);
    },
    calculateAverageMan(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        parseFloat(shal1) * 0.2 +
        parseFloat(shal2) * 0.3 +
        parseFloat(shal3) * 0.3 +
        parseFloat(shal4) * 0.2;
      let myManagerPercent = (averageMyPer * 15) / 100;
      return myManagerPercent.toFixed(1);
    },
    calculateAverageTeach(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        parseFloat(shal1) * 0.2 +
        parseFloat(shal2) * 0.3 +
        parseFloat(shal3) * 0.3 +
        parseFloat(shal4) * 0.2;
      let myTeacherPercent = (averageMyPer * 20) / 100;
      return myTeacherPercent.toFixed(1);
    },
    calculateAverageEmp(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        parseFloat(shal1) * 0.4 +
        parseFloat(shal2) * 0.4 +
        parseFloat(shal3) * 0.1 +
        parseFloat(shal4) * 0.1;
      let myEmployeePercent = (averageMyPer * 15) / 100;
      return myEmployeePercent.toFixed(1);
    },

    // Нэмэгдэл хөлс тооцоолол
    calculateAverageAUB(shal5, shal6) {
      let averageAUB = "";
      if (shal5 != null && shal5 != 0 && shal6 != null && shal6 != 0) {
        averageAUB = (parseFloat(shal5) + parseFloat(shal6)) / 2;
        return averageAUB.toFixed(1);
      } else if (shal5 != null && shal5 != 0) {
        averageAUB = parseFloat(shal5) / 2;
        return averageAUB.toFixed(1);
      } else {
        averageAUB = parseFloat(shal6) / 2;
        return averageAUB.toFixed(1);
      }
    },
    calculateZereg(shal9) {
      if (parseInt(shal9) == 1) {
        return "Заах аргач " + 10 + "%";
      } else if (parseInt(shal9) == 2) {
        return "Тэргүүлэх " + 15 + "%";
      } else if (parseInt(shal9) == 3) {
        return "Зөвлөх " + 20 + "%";
      } else if (parseInt(shal9) == 4) {
        return "Ахлах НА " + 20 + "%";
      } else {
        return "Зэрэггүй " + 0 + "%";
      }
    },
    calculateErdZereg(shal10) {
      if (parseInt(shal10) == 1) {
        return "Доктор " + 20 + "%";
      } else {
        return "Эрдмийн зэрэггүй " + 0 + "%";
      }
    },
    calculateSportZereg(shal17) {
      if (parseInt(shal17) == 1) {
        return "Спортын мастер " + 20 + "%";
      } else {
        return "Спортын зэрэггүй " + 0 + "%";
      }
    },

    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    dynamicSortMultiple() {
      /*
       * save the arguments object as it will be overwritten
       * note that arguments object is an array-like object
       * consisting of the names of the properties to sort by
       */
      var props = arguments;
      return function (obj1, obj2) {
        var i = 0,
          result = 0,
          numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
          result = this.dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      };
    },
    //Сараар шүүж авах
    filterByMonthED() {
      axios
        .post("/hr/evaluationsDataMonth", {
          ajliin_sar: this.selectedMonths,
          year: this.yearId != null ? this.yearId.id : null,
        })
        .then((response) => {
          //   console.log(response.data);
          this.downloadData = false;

          this.allSchoolDep = response.data.departments;
          //сортлох
          let sortMinadCurr = response.data.myPushSomeDataMinadCurr;
          if (sortMinadCurr.length != 0) {
            sortMinadCurr.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
            // this.allEvaluationsMinadCurr = sortMinadCurr;
          }
          //   else {
          //     this.allEvaluationsMinadCurr = [];
          //   }

          let sortManager = response.data.myPushSomeDataManager;
          if (sortManager.length != 0) {
            sortManager.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
            //   this.allEvaluationsManager = sortManager;
          }

          let sortTeacher = response.data.myPushSomeDataTeacher;
          if (sortTeacher.length != 0) {
            sortTeacher.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
            //   this.allEvaluationsTeacher = sortTeacher;
          }

          let sortEmployee = response.data.myPushSomeDataEmployee;
          if (sortEmployee.length != 0) {
            sortEmployee.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
            //   this.allEvaluationsEmployee = sortEmployee;
          }

          let sortTeacherKG = response.data.myPushSomeDataTeacherKG;
          if (sortTeacherKG.length != 0) {
            sortTeacherKG.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
          }
          let sortTeacherSK = response.data.myPushSomeDataTeacherSK;
          if (sortTeacherSK.length != 0) {
            sortTeacherSK.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
          }
          let sortEmployeeSK = response.data.myPushSomeDataEmployeeSK;
          if (sortEmployeeSK.length != 0) {
            sortEmployeeSK.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
          }
          let sortEmployeeVAA = response.data.myPushSomeDataEmployeeVAA;
          if (sortEmployeeVAA.length != 0) {
            sortEmployeeVAA.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
          }
          let sortEmployeeFinance = response.data.myPushSomeDataEmployeeFinance;
          if (sortEmployeeFinance.length != 0) {
            sortEmployeeFinance.sort(
              (a, b) =>
                a.department_name.localeCompare(b.department_name) ||
                a.emp_teacher_name.localeCompare(b.emp_teacher_name)
            );
          }

          //Сургалт хариуцсан захирал
          if (sortMinadCurr != 0) {
            sortMinadCurr.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  20) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsMinadCurr = sortMinadCurr;
            // console.log(this.allEvaluationsMinadCurr);
          }

          //Цогцолборын менежерүүд
          if (sortManager != 0) {
            sortManager.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  15) /
                100;

              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsManager = sortManager;
            // console.log(this.allEvaluationsManager);
          }

          //Сургуулийн багш
          if (sortTeacher != 0) {
            sortTeacher.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  20) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsTeacher = sortTeacher;
            this.allEvaluationsTeacherRestart = sortTeacher;
            // console.log(this.allEvaluationsTeacherRestart);
          }

          //Суралцахуйн албаны ажилчид
          if (sortEmployee != 0) {
            sortEmployee.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  10) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsEmployee = sortEmployee;
            // console.log(this.allEvaluationsEmployee);
          }

          //Цэцэрлэг багш
          if (sortTeacherKG != 0) {
            sortTeacherKG.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  20) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsTeacherKG = sortTeacherKG;
            this.allEvaluationsTeacherRestartKG = sortTeacherKG;
            // console.log(this.allEvaluationsTeacherRestart);
          }
          //Спорт клубын багш
          if (sortTeacherSK != 0) {
            sortTeacherSK.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  10) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsTeacherSK = sortTeacherSK;
            this.allEvaluationsTeacherRestartSK = sortTeacherSK;
            // console.log(this.allEvaluationsTeacherRestart);
          }
          //Спорт клубын ажилчид
          if (sortEmployeeSK != 0) {
            sortEmployeeSK.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  10) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsEmployeeSK = sortEmployeeSK;
            // console.log(this.allEvaluationsEmployeeSK);
          }
          //Суралцахуйг дэмжих үйл ажиллагааны албаны ажилчид
          if (sortEmployeeVAA != 0) {
            sortEmployeeVAA.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  10) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsEmployeeVAA = sortEmployeeVAA;
            // console.log(this.allEvaluationsEmployeeVAA);
          }
          //Санхүүгийн албаны ажилчид
          if (sortEmployeeFinance != 0) {
            sortEmployeeFinance.forEach((element) => {
              element.shal1_4 =
                ((parseFloat(element.shal1) *
                  parseFloat(element.shal1_jin_huvi) +
                  parseFloat(element.shal2) *
                    parseFloat(element.shal2_jin_huvi) +
                  parseFloat(element.shal3) *
                    parseFloat(element.shal3_jin_huvi) +
                  parseFloat(element.shal4) *
                    parseFloat(element.shal4_jin_huvi)) *
                  10) /
                100;
              element.shal5_6 = this.myAUBCheck(element.shal5, element.shal6);
              element.shal8_per = this.myBNBCheck(element.shal8);
              element.shal9_per = this.myMerZerCheck(element.shal9);
              element.shal10_per = this.myErdZerCheck(element.shal10);
              element.shal17_per = this.mySportZerCheck(element.shal17);
            });
            this.allEvaluationsEmployeeFinance = sortEmployeeFinance;
            // console.log(this.allEvaluationsEmployeeFinance);
          }

          this.myAllEvaluations = [
            ...this.allEvaluationsMinadCurr, //Сургалт хариуцсан захирал
            ...this.allEvaluationsManager, //Цогцолборын бүх менежер
            ...this.allEvaluationsTeacher, //Сургуулийн багш
            ...this.allEvaluationsEmployee, //Суралцахуйн албаны ажилчид
            ...this.allEvaluationsTeacherKG, //Цэцэрлэгийн багш
            ...this.allEvaluationsTeacherSK, //Спорт клубын багш
            ...this.allEvaluationsEmployeeSK, //Спорт клубын ажилчид
            ...this.allEvaluationsEmployeeVAA, //ҮАА ажилчид
            ...this.allEvaluationsEmployeeFinance, //Санхүүгийн албаны ажилчид
          ];
          this.downloadData = true;
          //   console.log(this.myAllEvaluations);
        })
        .catch();
    },
    customDepartmentLabel({ department_name }) {
      return `${department_name}`;
    },

    filterByMonthDepED() {
      let myTeachersED = this.allEvaluationsTeacherRestart;
      //   console.log(myTeachersED); //department_id
      //   console.log(this.selectedDepartments); //id
      let filteredED = [];
      this.selectedDepartments.forEach((el) => {
        myTeachersED.forEach((element) => {
          if (el.id === element.department_id) {
            filteredED.push(element);
          }
        });
      });

      this.allEvaluationsTeacher = filteredED;
      //   console.log(filteredED); //department_id
    },

    myAUBCheck(shal5, shal6) {
      let averageAUB = "";
      if (shal5 != null && shal5 != 0 && shal6 != null && shal6 != 0) {
        averageAUB = (parseFloat(shal5) + parseFloat(shal6)) / 2;
        return averageAUB.toFixed(1);
      } else if (shal5 != null && shal5 != 0) {
        averageAUB = parseFloat(shal5) / 2;
        return averageAUB.toFixed(1);
      } else if (shal6 != null && shal6 != 0) {
        averageAUB = parseFloat(shal6) / 2;
        return averageAUB.toFixed(1);
      } else {
        return 0;
      }
    },
    myBNBCheck(shal8) {
      if (shal8 == null) {
        return 0;
      } else if (parseFloat(shal8) == 0) {
        return 0;
      } else {
        return shal8;
      }
    },
    myMerZerCheck(shal9) {
      if (parseInt(shal9) == 4) {
        return 20;
      } else if (parseInt(shal9) == 3) {
        return 20;
      } else if (parseInt(shal9) == 2) {
        return 15;
      } else if (parseInt(shal9) == 1) {
        return 10;
      } else {
        return 0;
      }
    },
    myErdZerCheck(shal10) {
      if (parseInt(shal10) == 1) {
        return 20;
      } else {
        return 0;
      }
    },
    mySportZerCheck(shal17) {
      if (parseInt(shal17) == 1) {
        return 20;
      } else {
        return 0;
      }
    },

    //Модал засварлах функц
    //MinadCurr
    editEvaluationMinadCurr(evaluation) {
      this.editMode = true;
      this.editModeManager = false;
      this.editModeTeacher = false;
      this.editModeEmployee = false;
      this.updateMode = "minadcurr";
      this.form.fill(evaluation);
      //   console.log(evaluation);
      this.$bvModal.show("modal-minadCurr");
    },
    // manager
    editEvaluationManager(evaluation) {
      this.editMode = false;
      this.editModeManager = true;
      this.editModeTeacher = false;
      this.editModeEmployee = false;
      this.updateMode = "manager";
      this.form.fill(evaluation);
      //   console.log(evaluation);
      this.$bvModal.show("modal-minadCurr");
    },
    // Teacher
    editEvaluationTeacher(evaluation) {
      this.editMode = false;
      this.editModeManager = false;
      this.editModeTeacher = true;
      this.editModeEmployee = false;
      this.updateMode = "teacher";
      this.form.fill(evaluation);
      //   console.log(evaluation);
      this.$bvModal.show("modal-minadCurr");
    },
    // Employee
    editEvaluationEmployee(evaluation) {
      this.editMode = false;
      this.editModeManager = false;
      this.editModeTeacher = false;
      this.editModeEmployee = true;
      this.updateMode = "employee";
      this.form.fill(evaluation);
      //   console.log(evaluation);
      this.$bvModal.show("modal-minadCurr");
    },
    updateMinadCurrEvaluation() {
      //   console.log(this.form);
      this.form
        .put("/hr/updateMinadCurrEval/" + this.updateMode)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.$bvModal.hide("modal-minadCurr");
          //   this.form.reset();
          //   this.editMode = !this.editMode;
          this.filterByMonthED();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    //Модалтай ажиллах функцүүд
    resetModal() {
      //   console.log("reset");
    },
    cancelEdit() {
      this.$bvModal.hide("modal-minadCurr");
      this.editMode = false;
      this.editModeManager = false;
      this.editModeTeacher = false;
      this.editModeEmployee = false;
      this.form.reset();
      //   console.log("cancel");
    },
    handleOk() {
      //   console.log("ok");
    },

    // Бүх ажилчдын цаг бүртгэх
    editEmployeesShal13() {
      this.$bvModal.show("modal-register-employees-time");
    },
    updateEmployeesShal13() {
      this.formEmployeesTime
        .post("/hr/updateEmployeesShal13")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEmployeeTime();
        })
        .catch((err) => console.log(err));
    },
    cancelEmployeeTime() {
      this.formEmployeesTime.reset();
      this.$bvModal.hide("modal-register-employees-time");
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },

  created() {
    Fire.$on("loadSchoolManagers", () => {});
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
th,
span {
  font-size: 60%;
}
</style>
