<template>
  <div class="row pt-3">
    <div class="col-lg-9">
      <div class="card">
        <div class="card-header">
          <div
            class="main-header navbar navbar-expand navbar-white navbar-light"
          >
            <h3 class="card-title">
              Нийт суралцагчид: {{ schoolstudents.length }}. Суралцагчид шүүж
              харах&nbsp;&nbsp;
            </h3>
            <!-- <search-student
              @getWasSearchStudents="checkStudents($event)"
            ></search-student> -->
          </div>
          <b-row class="mt-3" v-if="role === 'agerman' || role === 'cipalprin'">
            <b-col sm="9">
              <multiselect
                v-model="fieldName"
                :options="fieldList"
                @select="setFieldList"
                :multiple="true"
                track-by="value"
                label="label"
                :searchable="true"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Excel файл руу гаргах баганаа сонгоно уу."
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="3">
              <b-button
                size="sm"
                @click="resetFields"
                class="mr-2"
                pill
                variant="outline-primary"
              >
                <b-icon icon="check-all" aria-hidden="true"></b-icon> Бүх талбар
              </b-button>
              <download-excel
                class="btn btn-success btn-sm"
                :data="json_data"
                :fields="json_fields"
                worksheet="Суралцагч"
                name="Суралцагчид.xls"
              >
                <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                Файл татах
              </download-excel>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-8">
              <div class="form-group row">
                <div class="col-md-5">
                  <multiselect
                    v-model="year_id"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id"
                    label="id"
                    :custom-label="customYearName"
                    placeholder="Хичээлийн жилээр OGSM шүүж харах"
                    :options="allYears"
                    :searchable="true"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.start_date | dateYear }}-{{
                          option.finish_date | dateYear
                        }}
                        - хичээлийн жил
                      </strong></template
                    >
                  </multiselect>
                </div>

                <div class="col-md-4">
                  <multiselect
                    v-model="selected"
                    :options="school_classes"
                    :multiple="true"
                    track-by="id"
                    label="full_name"
                    :custom-label="customSchoolClassName"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Анги сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                  </multiselect>
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong> {{ option.full_name }}</strong>

                    <strong>сонгогдлоо</strong></template
                  >
                </div>
                <div class="col-md-3">
                  <b-button
                    size="sm"
                    @click="getAllStudents"
                    class="mr-2"
                    pill
                    variant="outline-primary"
                  >
                    <b-icon icon="check-all" aria-hidden="true"></b-icon>
                    Суралцагч дуудах
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <table
            class="table table-bordered table-hover table-striped dataTable dtr-inline"
          >
            <thead>
              <tr role="row" style="font-size: 80%">
                <th>#</th>
                <th v-if="hasAccess">Овог</th>
                <th v-if="hasAccess">Нэр</th>
                <th v-if="hasAccess">Багш(Эцэг эх)</th>
                <th v-if="hasAccess">Хүйс</th>
                <th v-if="hasAdminAccess">Жил</th>
                <th v-if="hasAdminAccess">
                  <a @click.prevent="openStatModal()"
                    ><i class="fas fa-chart-bar"></i> Суудал баталгаажилт <br />
                    дараа жил</a
                  >
                </th>
                <th v-if="hasAdminAccess">Оношилгоо</th>
                <th v-if="hasAdminAccess">Суралцсан жил</th>
                <th v-if="hasAdminAccess">Кэмб(ID)</th>
                <th v-if="hasAdminAccess">Элсэлтийн мэдээлэл</th>
                <th>Огноо</th>
                <!-- <th>Зассан</th> -->
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                :class="{ 'table-warning': isAll }"
                v-for="(schoolstudent, index) in schoolstudents"
                :key="index"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  <!-- Using modifiers -->
                  <a
                    v-if="
                      (role === 'agerman' ||
                        role === 'cipalprin' ||
                        role === 'logistpsycho') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="createParent(schoolstudent)"
                  >
                    {{ schoolstudent.student_last_name }}</a
                  >
                  <a v-else href="#" class="badge bg-warning">
                    {{ schoolstudent.student_last_name }}</a
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  <a
                    v-if="
                      (role === 'agerman' ||
                        role === 'agermankg' ||
                        role === 'cipalprin' ||
                        role === 'reg_emp') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="editStudentInfo(schoolstudent)"
                  >
                    {{ schoolstudent.student_name }}
                  </a>
                  <a v-else href="#" class="badge bg-primary">
                    {{ schoolstudent.student_name }}</a
                  >
                  <a
                    v-if="
                      (role === 'agerman' ||
                        role === 'agermankg' ||
                        role === 'cipalprin' ||
                        role === 'reg_emp') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="resetPass(schoolstudent)"
                  >
                    <i class="fa fa-key"></i>
                  </a>
                  <a
                    v-if="
                      (role === 'agerman' ||
                        role === 'agermankg' ||
                        role === 'cipalprin' ||
                        role === 'hr') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="addTeacherParentForStudent(schoolstudent)"
                  >
                    <i class="fas fa-users-cog"></i>
                  </a>
                  <b-badge variant="primary">
                    {{ schoolstudent.email }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  <b-badge
                    v-if="
                      schoolstudent.profile_info != null &&
                      schoolstudent.profile_info.student_migration_info !=
                        null &&
                      schoolstudent.profile_info.student_migration_info
                        .my_parent_teacher_info != null
                    "
                    variant="info"
                    >{{
                      schoolstudent.profile_info.student_migration_info
                        .my_parent_teacher_info.teacher_last_name[0]
                    }}.
                    {{
                      schoolstudent.profile_info.student_migration_info
                        .my_parent_teacher_info.teacher_name
                    }}</b-badge
                  >
                  <b-badge v-else variant="warning"
                    >Эцэг эх бүртгэгдээгүй</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  <!-- {{ schoolstudent.student_gender }} -->
                  <b-badge variant="info">
                    {{ schoolstudent.student_gender }}
                  </b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  <b-badge variant="info" style="width: 5rem">
                    {{ schoolstudent.start_date.substring(0, 4) }} -
                    {{ schoolstudent.finish_date.substring(0, 4) }}
                  </b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  <b-badge
                    variant="success"
                    v-if="schoolstudent.confirm_next_year == 1"
                    >Баталгаажив</b-badge
                  >
                  <b-badge variant="danger" v-else>Баталгаажаагүй</b-badge>
                  <br />
                  <a
                    href="#"
                    class="badge bg-primary"
                    v-if="role === 'agerman' || role === 'cipalprin'"
                    @click.prevent="confirmNextYear(schoolstudent)"
                  >
                    <b-icon icon="calendar-plus" aria-hidden="true"></b-icon>
                  </a>
                  <a
                    v-if="schoolstudent.confirm_next_year_file"
                    href="#"
                    v-b-toggle.sidebar-file-confirm
                    @click="
                      viewFileConfirm(schoolstudent.confirm_next_year_file)
                    "
                  >
                    {{ schoolstudent.confirm_next_year_file.split("-")[1] }}
                  </a>
                  <span v-else style="font-size: 60%">Файл хавсаргаагүй</span>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess || role === 'logistpsycho'"
                >
                  <span v-if="schoolstudent.profile_info">
                    <b-badge
                      variant="info"
                      class="mb-2 text-wrap"
                      style="width: 10rem; font-size: 80%"
                      v-if="
                        schoolstudent.profile_info.my_diagnostics.length != 0
                      "
                    >
                      <span
                        v-for="(stud, i) in schoolstudent.profile_info
                          .my_diagnostics"
                        :key="stud.id"
                      >
                        {{ i + 1 }}
                        <a
                          href="#"
                          v-b-toggle.sidebar-file
                          v-if="
                            role === 'cipalprin' ||
                            role === 'logistpsycho' ||
                            depInfo === 11
                          "
                          @click="
                            viewFile(stud.diagnostic_file_url, stud.created_at)
                          "
                        >
                          {{ stud.diagnostic_file_url.split("-")[1] }}
                        </a>
                        <a v-else>{{
                          stud.diagnostic_file_url.split("-")[1]
                        }}</a
                        ><b-icon
                          icon="x-octagon"
                          variant="danger"
                          v-if="role === 'cipalprin' || depInfo === 11"
                          @click="deleteDiagnosticFile(stud)"
                        ></b-icon>
                        <br />
                      </span>
                    </b-badge>
                    <p v-else>
                      <small class="text-muted">Оношилгоо хавсаргаагүй!</small>
                    </p>
                  </span>
                  <a
                    v-if="
                      role === 'logistpsycho' ||
                      role === 'cipalprin' ||
                      depInfo === 11
                    "
                    v-b-popover.hover="
                      'Суралцагчийн оношилгоог файлаар хадгалах.'
                    "
                    title="Оношилгоо"
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="diagnosticFileInfo(schoolstudent)"
                    >Оношилгоо хавсаргах</a
                  >
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  <b-badge
                    v-if="
                      schoolstudent.profile_info.student_migrations.length != 0
                    "
                    variant="info"
                    class="mb-2"
                  >
                    <div>
                      <div
                        v-for="(year, index) in schoolstudent.profile_info
                          .student_migrations"
                        :key="year.id"
                        class="mb-1"
                      >
                        <a
                          href="#"
                          @click.prevent="changeImage(year)"
                          class="d-block mb-1"
                        >
                          {{ index + 1 }}). {{ year.my_year_info.start_date }} -
                          {{ year.my_year_info.finish_date }}
                        </a>
                      </div>
                      <div>
                        Нийт:
                        {{
                          schoolstudent.profile_info.student_migrations.length
                        }}
                        жил суралцсан.
                      </div>
                    </div>
                  </b-badge>
                  <b-badge v-else variant="warning"
                    >Суралцсан жил бүртгэгдээгүй</b-badge
                  >
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  <b-badge variant="info" v-if="schoolstudent.student_camb_num">
                    {{ schoolstudent.student_camb_num }}
                  </b-badge>
                  <b-badge variant="info" v-else> Кэм ID бүртгээгүй.</b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  <span
                    class="badge badge-primary text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-if="
                      schoolstudent.contact_info !== null ||
                      schoolstudent.study_class_sanal_desc !== null
                    "
                  >
                    Холбоо барих: {{ schoolstudent.contact_info }} <br />
                    Элсэлтийн санал:
                    {{ schoolstudent.study_class_sanal_desc }} <br />
                    Регистер: {{ schoolstudent.profile_student_reg }}
                  </span>

                  <span
                    class="badge badge-info text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-else
                  >
                    Мэдээлэл бүртгэгдээгүй
                  </span>
                  <br />
                  <a
                    v-if="
                      schoolstudent.contact_info &&
                      schoolstudent.year_id == activeYear.id
                    "
                    v-b-popover.hover="
                      'Түвшин тогтоох шалгалтын дүнгийн мэдээлэл'
                    "
                    title="Дүн"
                    href="#"
                    class="badge bg-info"
                    @click="showSchoolEntrantExam(schoolstudent)"
                    >Элсэлтийн дүн</a
                  >

                  <br />
                  <span v-if="schoolstudent.profile_info">
                    <p
                      v-if="
                        schoolstudent.profile_info.my_parent_app_info.length !=
                        0
                      "
                    >
                      <span
                        v-for="(stud, i) in schoolstudent.profile_info
                          .my_parent_app_info"
                        :key="stud.id"
                      >
                        {{ i + 1 }}.
                        <a
                          href="#"
                          v-b-toggle.sidebar-file
                          @click="
                            viewFile(stud.profile_file_url, stud.created_at)
                          "
                        >
                          {{ stud.profile_file_url.split("-")[1] }}
                        </a>
                        <b-icon
                          icon="x-octagon"
                          variant="danger"
                          @click="deleteParentApplicationFile(stud)"
                        ></b-icon>
                        <br />
                      </span>
                    </p>

                    <p v-else>
                      <small class="text-muted">Файл хавсаргаагүй!</small>
                    </p>
                  </span>
                  <a
                    v-if="
                      (role === 'agerman' ||
                        role === 'cipalprin' ||
                        role === 'reg_emp') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    v-b-popover.hover="'Эцэг эхийн хүсэлтийг файлаар хадгалах.'"
                    title="Хүсэлт"
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="applicationFileInfo(schoolstudent)"
                    >Хүсэлт хавсаргах</a
                  >
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="font-size: 80%; width: 20rem; text-align: justify"
                >
                  <b-badge variant="warning"
                    >Бүртгэсэн:
                    {{ schoolstudent.created_at | dateYearMonthDay }}
                  </b-badge>
                  <br />
                  <b-badge variant="info"
                    >Зассан:
                    {{ schoolstudent.updated_at | dateYearMonthDay }}
                  </b-badge>
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.updated_at | dateYearMonthDay }}
                </td> -->
                <td class="dtr-control sorting_1" tabindex="0">
                  <p
                    style="width: 8rem"
                    v-if="
                      (role === 'agerman' ||
                        role === 'agermankg' ||
                        role === 'cipalprin') &&
                      schoolstudent.year_id == activeYear.id
                    "
                  >
                    <!-- <a
                        href="#"
                        class="badge bg-purple"
                        @click="viewSchoolStudent(schoolstudent)"
                        ><i class="far fa-eye"></i
                      ></a> -->
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolStudent(schoolstudent)"
                      ><i
                        class="fas fa-edit"
                        v-b-popover.hover.top="
                          'Суралцагчийг мэдээлэл засварлах!'
                        "
                        title="Засварлах"
                      ></i
                    ></a>

                    <a
                      href="#"
                      class="badge bg-info"
                      @click="inActiveSchoolStudent(schoolstudent)"
                      ><i
                        class="fas fa-step-forward"
                        v-b-popover.hover.top="
                          'Тухайн хичээлийн жилд идэвхигүй болгох!'
                        "
                        title="Идэвхигүй болгох"
                      ></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolStudent(schoolstudent)"
                    >
                      <i
                        class="fas fa-sign-out-alt"
                        v-b-popover.hover.top="
                          'Суралцагчийг шилжсэн төлөвт шилжүүлэх!'
                        "
                        title="Шилжих"
                      ></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-success"
                      @click.prevent="surdagUnen(schoolstudent)"
                    >
                      <b-icon
                        v-b-popover.hover.top="
                          'Сургуульд сурдаг нь үнэн тодорхойлолт авах!'
                        "
                        title="Суралцадаг нь үнэн"
                        icon="file-earmark"
                        aria-hidden="true"
                      ></b-icon>
                    </a>
                  </p>
                  <p
                    v-else-if="
                      (role === 'cipalprin' ||
                        role === 'supervisorshift' ||
                        role === 'reg_empsk') &&
                      schoolstudent.year_id == activeYear.id
                    "
                    variant="info"
                  >
                    <a
                      href="#"
                      class="badge bg-success"
                      @click.prevent="surdagUnen(schoolstudent)"
                    >
                      <b-icon
                        v-b-popover.hover.top="'Спорт клуб!'"
                        title="Спорт клуб"
                        icon="file-earmark"
                        aria-hidden="true"
                      ></b-icon>
                    </a>
                  </p>
                  <b-badge v-else variant="warning">
                    <p>Үйлдэл хийхийг хориглов.</p>
                  </b-badge>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                  v-if="hasAccess"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  Хүйс(Эрэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  {{ countAMale() }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  Баталгаажсан
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  {{ selectedClassConfirmParent }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                  v-if="hasAccess"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  Хүйс(Эмэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  {{ countAFeMale() }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  Баталгаажаагүй
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  {{ selectedClassNotConfirmParent }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                  v-if="hasAccess"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  Нийт
                </td>
                <td class="dtr-control sorting_1" tabindex="0" v-if="hasAccess">
                  {{ countA() }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  Хувь
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                >
                  {{ selectedClassConfirmParentPercent }} %
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="hasAdminAccess"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <div class="col-lg-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Суралцагчийн мэдээлэл засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="updateSchoolStudent()">
          <div class="card-body">
            <div class="form-group">
              <label>Ургийн овог</label>
              <input
                type="text"
                name="student_family_name"
                v-model="form.student_family_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_family_name'),
                  },
                ]"
                placeholder="Ургийн овог"
              />
              <has-error :form="form" field="student_family_name"></has-error>
            </div>
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="student_last_name"
                v-model="form.student_last_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="student_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="student_name"
                v-model="form.student_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="student_name"></has-error>
            </div>
            <div class="form-group">
              <label>Имэйл</label>
              <input
                type="email"
                name="email"
                v-model="form.email"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('email'),
                  },
                ]"
                placeholder="Цахим шуудан"
              />
              <has-error :form="form" field="email"></has-error>
            </div>
            <div class="form-group">
              <label>Харьяалагдах анги</label>
              <b-form-select
                v-model="form.school_class_id"
                :options="school_classes"
                text-field="full_name"
                value-field="id"
              >
              </b-form-select>
              <has-error :form="form" field="full_name"></has-error>
            </div>
            <div class="form-group">
              <label>Харьяалагдах хичээлийн жил</label>
              <b-form-select
                v-model="form.schoolYearId"
                :options="schoolYears"
                text-field="year_name"
                value-field="id"
              >
              </b-form-select>
              <has-error :form="form" field="year_name"></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Засах
            </button>
            <button
              class="btn btn-warning"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="col-lg-3" v-show="deleteMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Суралцагч шилжсэн төлөвт шилжүүлэх</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="leaveStudent()">
          <div class="card-body">
            <div class="form-group">
              <label>Шилжсэн шалтгаан</label>
              <b-form-select
                v-model="formLeave.leave_type_list"
                :options="leave_types"
                text-field="name"
                value-field="id"
                multiple
                :select-size="4"
              ></b-form-select>
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <label>Шилжсэн тайлбар</label>
              <input
                type="text"
                name="student_shiljilt_shaltgaan"
                v-model="formLeave.student_shiljilt_shaltgaan"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_shiljilt_shaltgaan'),
                  },
                ]"
                placeholder="шилжсэн тайлбар"
              />
              <has-error
                :form="formLeave"
                field="student_shiljilt_shaltgaan"
              ></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="deleteMode">
              Хадгалах
            </button>
            <button
              class="btn btn-warning"
              v-show="deleteMode"
              @click.prevent="cancelDelete"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <create-parent
      v-show="createMode"
      :forParentChild="forParentChild"
      :parentDataProps="parentDataProps"
    />
    <b-modal
      size="xl"
      id="modal-student-info"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчийн мэдээлэл засах"
      class="modal fade"
      hide-footer
      @hidden="resetStudentMoreInfoEditForm"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active" href="#grades" data-toggle="tab"
                      >Суралцагчийн хөгжлийн ерөнхий үнэлгээ</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#activity" data-toggle="tab"
                      >Суралцагчийн мэдээлэл</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#edit" data-toggle="tab"
                      >Мэдээлэл засах</a
                    >
                  </li>
                </ul>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">
                  <div class="active tab-pane" id="grades">
                    <student-open-day
                      v-if="isShow"
                      :selectedChildId="formEdit.user_id"
                      :quarters="quarters"
                    ></student-open-day>
                  </div>
                  <div class="tab-pane" id="activity">
                    <!-- Post -->
                    <div class="post">
                      <div class="user-block">
                        <img
                          v-if="
                            getMyInfoStudent.student_profile_photo_url != null
                          "
                          :src="getMyInfoStudent.student_profile_photo_url"
                          alt="user-avatar"
                          class="img-circle img-bordered-sm"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="img-circle img-bordered-sm"
                        />
                        <span class="username">
                          <a href="#"
                            >{{ getMyInfoStudent.student_last_name }}
                            {{ getMyInfoStudent.student_name }}</a
                          >
                        </span>
                        <span class="description"
                          >Системд бүртгэсэн -
                          {{
                            getMyInfoStudent.user_created_at | dateYearMonthDay
                          }}</span
                        >
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <th class="col-sm-3"></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-right text-bold">Регистр:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_reg }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Ургийн овог:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_family_name }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">
                                Эцэг/Эх-ийн нэр:
                              </td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_last_name }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Нэр:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_name }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Хүйс:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_gender }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Төрсөн өдөр:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_birthDate }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Иргэншил:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_citizenship }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">
                                Төрсний гэрчилгээний дугаар:
                              </td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_birth_ger_num }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Эх хэл:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_ekh_khel }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Өнчин эсэх:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_unchin_esekh }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">
                                Асран хамгаалагч:
                              </td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_asran_kham }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">
                                Асран хамгаалагчын мэдээлэл:
                              </td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_asran_kham_med }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right text-bold">Цусны бүлэг:</td>
                              <td class="text-left">
                                {{ getMyInfoStudent.student_tsus_bul }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- /.user-block -->
                    </div>
                    <!-- /.post -->
                  </div>
                  <!-- /.tab-pane -->
                  <div class="tab-pane" id="edit">
                    <form>
                      <div class="form-group row">
                        <div class="col-sm-4 form-group">
                          <label>Регистр</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_reg"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_reg'),
                              },
                            ]"
                            v-model="formEdit.student_reg"
                            placeholder="Регистр"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Ургийн овог</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_family_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_family_name'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_family_name"
                            placeholder="Ургийн овог"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Овог</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_last_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_last_name'),
                              },
                            ]"
                            v-model="formEdit.student_last_name"
                            placeholder="Овог"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Нэр</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_name'),
                              },
                            ]"
                            v-model="formEdit.student_name"
                            placeholder="Нэр"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Нас</label>
                          <input
                            type="number"
                            class="form-control"
                            name="student_age"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_age'),
                              },
                            ]"
                            v-model="formEdit.student_age"
                            placeholder="Нас"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Хүйс</label>
                          <b-form-select
                            v-model="formEdit.student_gender"
                            :options="genders"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_gender'),
                              },
                            ]"
                          ></b-form-select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Төрсөн он сар өдөр</label>
                          <input
                            type="date"
                            class="form-control"
                            name="student_birthDate"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_birthDate'),
                              },
                            ]"
                            v-model="formEdit.student_birthDate"
                            placeholder="Төрсөн он сар өдөр"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Иргэншил</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_citizenship"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_citizenship'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_citizenship"
                            placeholder="Иргэншил"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Төрсний гэрчилгээний дугаар</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_birth_ger_num"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_birth_ger_num'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_birth_ger_num"
                            placeholder="Төрсний гэрчилгээний дугаар"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label
                            >Кэмбриж дугаар(Зөвхөн Кэмбрижийн хөтөлбөр хариуцсан
                            менежер оруулна)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            name="student_camb_num"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_camb_num'),
                              },
                            ]"
                            v-model="formEdit.student_camb_num"
                            placeholder="Кэмбриж дугаар"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Эх хэл</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_ekh_khel"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_ekh_khel'),
                              },
                            ]"
                            v-model="formEdit.student_ekh_khel"
                            placeholder="Эх хэл"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Цусны бүлэг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_tsus_bul"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_tsus_bul'),
                              },
                            ]"
                            v-model="formEdit.student_tsus_bul"
                            placeholder="Цусны бүлэг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Асран хамгаалагч</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_asran_kham"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_asran_kham'),
                              },
                            ]"
                            v-model="formEdit.student_asran_kham"
                            placeholder="Асран хамгаалагч"
                          />
                        </div>
                        <div class="col-sm-8 form-group">
                          <label>Асран хамгаалагчийн мэдээлэл</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_asran_kham_med"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_asran_kham_med'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_asran_kham_med"
                            placeholder="Асран хамгаалагчийн мэдээлэл"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Өнчин эсэх</label>
                          <b-form-select
                            v-model="formEdit.student_unchin_esekh"
                            :options="opts"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_unchin_esekh'
                                ),
                              },
                            ]"
                          ></b-form-select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Аймаг, нийслэл</label>
                          <b-form-select
                            v-model="formEdit.student_duureg"
                            value-field="id"
                            text-field="name"
                            v-on:change="getSum"
                            :options="aimagOptions"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_duureg'),
                              },
                            ]"
                          ></b-form-select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Сум, дүүрэг</label>
                          <b-form-select
                            v-model="formEdit.student_khoroo"
                            value-field="id"
                            text-field="name"
                            v-on:change="getKhoroo"
                            :options="sumOptions"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_khoroo'),
                              },
                            ]"
                          ></b-form-select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Баг, хороо</label>
                          <b-form-select
                            v-model="formEdit.student_khoroolol"
                            value-field="code"
                            text-field="name"
                            :options="khorooOptions"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_khoroolol'),
                              },
                            ]"
                          ></b-form-select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Хотхон</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_bair"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_bair'),
                              },
                            ]"
                            v-model="formEdit.student_bair"
                            placeholder="Хотхон"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Байрны дугаар</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_dugaar"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_dugaar'),
                              },
                            ]"
                            v-model="formEdit.student_dugaar"
                            placeholder="Байрны дугаар"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Тоот</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_toot"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_toot'),
                              },
                            ]"
                            v-model="formEdit.student_toot"
                            placeholder="Тоот"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Амдардаг хаяг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_live_address"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_live_address'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_live_address"
                            placeholder="Амдардаг хаяг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Утасны дугаар</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_phone_number"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_phone_number'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_phone_number"
                            placeholder="Утасны дугаар"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Имэйл хаяг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_email"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_email'),
                              },
                            ]"
                            v-model="formEdit.student_email"
                            placeholder="Имэйл хаяг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Social хаяг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_social_acc"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_social_acc'),
                              },
                            ]"
                            v-model="formEdit.student_social_acc"
                            placeholder="Social хаяг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Веб хаяг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_web_site"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_web_site'),
                              },
                            ]"
                            v-model="formEdit.student_web_site"
                            placeholder="Веб хаяг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Зэрэг</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_bol_zer"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_bol_zer'),
                              },
                            ]"
                            v-model="formEdit.student_bol_zer"
                            placeholder="Зэрэг"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label>Шагнал</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_shagnal"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formEdit.errors.has('student_shagnal'),
                              },
                            ]"
                            v-model="formEdit.student_shagnal"
                            placeholder="Шагнал"
                          />
                        </div>
                        <div class="col-sm-8 form-group">
                          <label>Шилжсэн шалтгаан</label>
                          <input
                            type="text"
                            class="form-control"
                            name="student_shiljilt_shaltgaan"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': formEdit.errors.has(
                                  'student_shiljilt_shaltgaan'
                                ),
                              },
                            ]"
                            v-model="formEdit.student_shiljilt_shaltgaan"
                            placeholder="Шилжсэн шалтгаан"
                          />
                        </div>
                        <div class="col-sm-12 form-group">
                          <label>Вакцины мэдээлэл</label>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <b-form-checkbox
                              id="checkbox-student_vac_1"
                              v-model="formEdit.student_vac_1"
                              name="checkbox-student_vac_1"
                              value="1"
                              unchecked-value="0"
                            >
                              Вакцин 1
                            </b-form-checkbox>
                          </div>
                          <div class="col-sm-4">
                            <b-form-checkbox
                              id="checkbox-student_vac_2"
                              v-model="formEdit.student_vac_2"
                              name="checkbox-student_vac_2"
                              value="1"
                              unchecked-value="0"
                            >
                              Вакцин 2
                            </b-form-checkbox>
                          </div>
                          <div class="col-sm-4">
                            <b-form-checkbox
                              id="checkbox-student_vac_3"
                              v-model="formEdit.student_vac_3"
                              name="checkbox-student_vac_3"
                              value="1"
                              unchecked-value="0"
                            >
                              Вакцин 3
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            @click.prevent="updateStudent()"
                          >
                            Хадгалах
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- /.tab-content -->
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.nav-tabs-custom -->
          </div>
          <!-- /.card-body -->
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpdate()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>

    <b-modal
      size="sx"
      id="modal-entrustInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Түвшин тогтоох шалгалтын дүн оруулах"
      class="modal fade"
      hide-footer
      @hide="cancelEntrant"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-0.2">
            <div class="card-body">
              <div class="form-group">
                <label>Монгол хэл</label>
                <br />
                <b-badge v-if="formEntrunt.mon_script != null" variant="info">{{
                  formEntrunt.mon_script
                }}</b-badge>
                <b-badge v-else variant="warning"
                  >Үнэлгээ бүртгэгдээгүй</b-badge
                >
              </div>
              <div class="form-group">
                <label>Математик</label>
                <br />
                <b-badge v-if="formEntrunt.mon_maths != null" variant="info">{{
                  formEntrunt.mon_maths
                }}</b-badge>
                <b-badge v-else variant="warning"
                  >Үнэлгээ бүртгэгдээгүй</b-badge
                >
              </div>
              <div class="form-group">
                <label>Кэмбриж математик</label>
                <br />
                <b-badge v-if="formEntrunt.camb_maths != null" variant="info">{{
                  formEntrunt.camb_maths
                }}</b-badge>
                <b-badge v-else variant="warning"
                  >Үнэлгээ бүртгэгдээгүй</b-badge
                >
              </div>
              <div class="form-group">
                <label>Кэмбриж англи хэл</label>
                <br />
                <b-badge v-if="formEntrunt.camb_eng != null" variant="info">{{
                  formEntrunt.camb_eng
                }}</b-badge>
                <b-badge v-else variant="warning"
                  >Үнэлгээ бүртгэгдээгүй</b-badge
                >
              </div>
              <div class="form-group">
                <label>Кэмбриж байгалийн ухаан</label>
                <br />
                <b-badge v-if="formEntrunt.camb_sci != null" variant="info">{{
                  formEntrunt.camb_sci
                }}</b-badge>
                <b-badge v-else variant="warning"
                  >Үнэлгээ бүртгэгдээгүй</b-badge
                >
              </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-secondary" @click.prevent="cancelEntrant">
                Хаах
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Файл upload хийх(Эцэг эхийн хүсэлт) -->
    <b-modal
      size="xl"
      id="modal-files"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Файл хавсаргах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="createParentApplicationFile()"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <b-form-group
                  label="Файлаар нь хавсаргах:"
                  label-cols-sm="2"
                  label-size="sm"
                >
                  <input
                    type="file"
                    name="filename"
                    class="form-control"
                    id="inputFileUpload"
                    placeholder="Файл хавсаргах:"
                    v-on:change="selectFilesParentRequest"
                  />
                </b-form-group>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-warning"
                  @click.prevent="cancelCreateFiles"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Файл upload хийх(Суралцагчийн оношилгоо) -->
    <b-modal
      size="xl"
      id="modal-stud-diagnostics"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Оношилгоо хавсаргах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="createDiagnosticFile()"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <b-form-group
                  label="Оношилгоог хавсаргах:"
                  label-cols-sm="2"
                  label-size="sm"
                >
                  <input
                    type="file"
                    name="filename"
                    class="form-control"
                    id="inputFileUpload"
                    placeholder="Файл хавсаргах:"
                    v-on:change="selectFilesDiagnosticRequest"
                  />
                </b-form-group>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-warning"
                  @click.prevent="cancelDiagnosticFiles"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Бичиг баримт харуулах хэсэг -->
    <b-sidebar
      :title="showFileInfo.info"
      id="sidebar-file"
      bg-variant="dark"
      text-variant="light"
      right
      z-index="1039"
      shadow
      width="60%"
      backdrop
    >
      <div class="px-3 py-2 text-center">
        <div>
          <embed
            v-if="docType === 'pdf'"
            :src="pdfValue"
            width="100%"
            class="vh-100"
          />
          <VueDocPreview v-else :value="docValue" type="office" />
        </div>
      </div>
    </b-sidebar>

    <!-- Багш эцэг эхтэй суралцагчыг холбох -->
    <b-modal
      size="xl"
      id="modal-student-add-tparent"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчийг багш эцэг эхтэй холбох"
      class="modal fade"
      hide-footer
      @hidden="closeTeacherParentForStudent"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <Multiselect
              v-model="formTEmpUserInfo.user_id"
              :options="all_users"
              track-by="emp_teacher_name"
              label="emp_teacher_name"
              :searchable="true"
              :close-on-select="true"
              :show-labels="true"
              placeholder="Багш сонгох"
              :allow-empty="false"
              deselect-label="Can't remove this value"
              :custom-label="customNameTeacher"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>
                  {{ option.emp_teacher_last_name }}
                  {{ option.emp_teacher_name }}({{
                    option.dep_department_code
                  }})</strong
                ><strong> сонгогдлоо</strong></template
              >
            </Multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button
          @click="updateTeacherParentForStudent()"
          variant="success"
          type="submit"
        >
          Холбох
        </b-button>
        <b-button
          @click="closeTeacherParentForStudent()"
          variant="warning"
          type="reset"
        >
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>

    <!-- Эцэг эхийн суудал баталгаажилт -->
    <b-modal
      size="xl"
      id="modal-student-confirm"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Файл хавсаргах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="updateConfrimNextYearInfo()"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="formConfirmNextYear.confirm_next_year_status"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  Баталгаажуулах эсэх (Тийм/Үгүй)
                </b-form-checkbox>
                <b-form-group
                  label="PDF файл хавсаргах:"
                  label-cols-sm="2"
                  label-size="sm"
                >
                  <input
                    type="file"
                    name="filename"
                    class="form-control"
                    id="inputFileUpload"
                    placeholder="Файл хавсаргах:"
                    v-on:change="selectFiles"
                  />
                </b-form-group>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-warning"
                  @click.prevent="hidemyconfirmmodal()"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Бичиг баримт харуулах хэсэг -->
    <b-sidebar
      title="Суудал баталгаажуулсан файл"
      id="sidebar-file-confirm"
      bg-variant="dark"
      text-variant="light"
      right
      z-index="1039"
      shadow
      width="60%"
      backdrop
    >
      <div class="px-3 py-2 text-center">
        <div>
          <embed
            v-if="docType1 === 'pdf'"
            :src="pdfValue1"
            width="100%"
            class="vh-100"
          />

          <VueDocPreview v-else :value="docValue1" type="office" />
        </div>
      </div>
    </b-sidebar>

    <!-- Ангиудын баталгаажилтын статистик -->
    <b-modal
      size="xl"
      id="modal-student-confirm-stat"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Ангиудын суудал баталгаажилт"
      class="modal fade"
      hide-footer
      @hidden="closeConfirmParents"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group row">
              <div class="col-md-9">
                <multiselect
                  v-model="year_idSelected"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="id"
                  label="id"
                  :custom-label="customYearName"
                  placeholder="Хичээлийн жилээр статистик шүүж харах"
                  :options="allYears"
                  :searchable="true"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.start_date | dateYear }}-{{
                        option.finish_date | dateYear
                      }}
                      - хичээлийн жил
                    </strong></template
                  >
                </multiselect>
              </div>

              <div class="col-md-3">
                <b-button
                  size="sm"
                  @click="checkAllSchoolClasses"
                  class="mr-2"
                  pill
                  variant="outline-primary"
                >
                  <b-icon icon="check-all" aria-hidden="true"></b-icon>
                  Статистик дахин дуудах
                </b-button>
              </div>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr role="row" style="font-size: 80%">
                  <th>#</th>
                  <th>Анги</th>
                  <th>Баталгаажсан</th>
                  <th>Баталгаажаагүй</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="odd"
                  :class="{ 'table-warning': isAll }"
                  v-for="(schoolClass, index) in mySchoolClassesConfirm"
                  :key="index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    <b-badge variant="info">{{
                      schoolClass.full_name
                    }}</b-badge>
                  </td>
                  <td>
                    <b-badge variant="success">{{
                      schoolClass.my_students_of_class_confirm_count
                    }}</b-badge>
                  </td>
                  <td>
                    <b-badge variant="danger">{{
                      schoolClass.my_students_of_class_not_confirm_count
                    }}</b-badge>
                  </td>
                  <td>
                    <b-badge variant="info">
                      {{
                        countPercentConfirm(
                          schoolClass.my_students_of_class_confirm_count,
                          schoolClass.my_students_of_class_not_confirm_count
                        )
                      }}
                      %
                    </b-badge>
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="
                          countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) < 30
                        "
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          )}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) >=
                            30) &
                          (countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) <
                            60)
                        "
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          )}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) >=
                            60) &
                          (countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) <
                            80)
                        "
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          )}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          ) >= 80
                        "
                        class="progress-bar bg-success"
                        :style="{
                          width: `${countPercentConfirm(
                            schoolClass.my_students_of_class_confirm_count,
                            schoolClass.my_students_of_class_not_confirm_count
                          )}%`,
                        }"
                      ></div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="odd">
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    align="right"
                  ></td>
                  <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <b-badge variant="success">
                      {{ countConfirmAll }}
                    </b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <b-badge variant="danger">
                      {{ countNotConfirmAll }}
                    </b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ countConfirmAllPercent }} %
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="countConfirmAllPercent < 30"
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${countConfirmAllPercent}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (countConfirmAllPercent >= 30) &
                          (countConfirmAllPercent < 60)
                        "
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${countConfirmAllPercent}%`,
                        }"
                      ></div>
                      <div
                        v-if="
                          (countConfirmAllPercent >= 60) &
                          (countConfirmAllPercent < 80)
                        "
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${countConfirmAllPercent}%`,
                        }"
                      ></div>
                      <div
                        v-if="countConfirmAllPercent >= 80"
                        class="progress-bar bg-success"
                        :style="{
                          width: `${countConfirmAllPercent}%`,
                        }"
                      ></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <!-- <b-button
          @click="updateTeacherParentForStudent()"
          variant="success"
          type="submit"
        >
          Холбох
        </b-button> -->
        <b-button @click="closeConfirmParents()" variant="warning" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- profile photo upload -->
      <!-- /.modal-dialog -->
    </b-modal>
    <b-modal
      size="m"
      id="modal-profileImg"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Зураг солих"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Суралцагчын зураг солих</h3>
                <b-badge variant="info" class="float-right"
                  >{{ start_date }} - {{ finish_date }}</b-badge
                >
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="formProfile != null"
              >
                <div class="row mt-2">
                  <div class="col-sm-2">
                    <b-avatar
                      :src="getSelectedProfileImg"
                      variant="light"
                      size="30"
                      class="mr-1"
                    ></b-avatar>
                  </div>
                  <div class="col-sm-10">
                    <b-form-file
                      id=""
                      browse-text="Зураг солих"
                      size="sm"
                      @change="getProfileImg"
                    ></b-form-file>
                    <h6 class="text-success">{{ successUpload }}</h6>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-6">
                    <a
                      href="#"
                      class="btn btn-block btn-primary btn-xs"
                      @click="uploadProfile"
                      v-if="loaded"
                      >Хадгалах</a
                    >
                  </div>
                  <div class="col-sm-6">
                    <a
                      href="#"
                      class="btn btn-block btn-secondary btn-xs"
                      @click.prevent="uploadProfileCancel"
                      v-if="loaded"
                      >Цуцлах</a
                    >
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpload()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import searchStudent from "./StudentSearch/studentSearch.vue";
import createParent from "./Parent/createParent.vue";
import Multiselect from "vue-multiselect";
import studentOpenDay from "../Parent/OpenDay/studentOpenDay.vue";
import VueDocPreview from "vue-doc-preview";
import axios from "axios";

export default {
  data() {
    return {
      start_date: "",
      finish_date: "",
      quarter: [],
      formProfile: new Form({
        user_id: null,
        profile_id: null,
        student_profile_photo_url: "",
        yearId: null,
      }),
      getSelectedProfileImg: "",
      successUpload: "",
      loaded: true,
      yearId: null,
      allYears: [],
      headerBgVariant: "primary",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      isShow: false,
      getMyInfoStudent: [],
      getOtherInfo: [],
      errorPassword: "",
      student_pass: "",
      student_pass_verify: "",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selected: [],
      action: "",
      createMode: false,
      forParentChild: "",
      parentDataProps: null,
      editMode: false,
      deleteMode: false,
      schoolstudents: [],
      school_classes: [],
      leave_types: [],
      schoolYears: [],
      depInfo: [],
      schoolLogo: "",
      form: new Form({
        id: "",
        student_name: "",
        email: "",
        student_family_name: "",
        student_last_name: "",
        school_class_id: "",
        user_id: "",
        schoolYearId: "",
      }),
      formLeave: new Form({
        id: "",
        profile_id: "",
        user_id: "",
        leave_type_list: [],
        student_shiljilt_shaltgaan: "",
      }),
      role: "",
      formEdit: new Form({
        user_id: "",
        student_family_name: "",
        student_last_name: "",
        student_name: "",
        student_gender: "",
        student_birthDate: "",
        student_age: "",
        student_citizenship: "",
        student_reg: "",
        student_camb_num: "",
        student_birth_ger_num: "",
        student_ekh_khel: "",
        student_unchin_esekh: "",
        student_asran_kham: "",
        student_asran_kham_med: "",
        student_tsus_bul: "",
        profile_id: "",
        school_class_id: "",
        student_age: "",
        student_duureg: 0,
        student_khoroo: 0,
        student_khoroolol: 0,
        student_bair: "",
        student_dugaar: "",
        student_toot: "",
        student_live_address: "",
        student_phone_number: "",
        student_email: "",
        student_social_acc: "",
        student_web_site: "",
        student_bol_zer: "",
        student_shagnal: "",
        student_vac_1: 0,
        student_vac_2: 0,
        student_vac_3: 0,
        student_shiljilt_shaltgaan: "",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      opts: [
        { value: null, text: "Сонгох" },
        { value: "Тийм", text: "Тийм" },
        { value: "Үгүй", text: "Үгүй" },
      ],
      aimagOptions: [],
      sumOptions: [],
      khorooOptions: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldList: [],
      fieldName: "",
      firstMode: true,
      isAll: false,
      // Элсэлтийн шалгалтын мэдээлэл
      formEntrunt: new Form({
        user_id: "",
        mon_script: null,
        mon_maths: null,
        camb_maths: null,
        camb_eng: null,
        camb_sci: null,
      }),

      //FileUpload мэдээлэл
      docType: "",
      docValue: "",
      pdfValue: "",
      showFileInfo: {
        info: "",
      },
      // Файл хадгалах
      formFile: new Form({
        id: "",
        profile_id: "",
        file: "",
        url: "",
      }),
      // Оношилгоо хадгалах
      formDiagnosticFile: new Form({
        id: "",
        stud_id: "",
        file: "",
        url: "",
      }),
      // Багш эцэг эхийг суралцагчтай холбох
      formTEmpUserInfo: new Form({
        user_id: "",
        studentInfo: "",
      }),
      //Суралцагчийн суудал баталгаажуулалт
      formConfirmNextYear: new Form({
        stud_mig_id: null,
        confirm_next_year_status: false,
        file: "",
      }),

      docValue1: "",
      pdfValue1: "",
      docType1: "",
      selectedClassConfirmParent: 0,
      selectedClassNotConfirmParent: 0,
      selectedClassConfirmParentPercent: 0,
      // Суудал баталгаажуулсан стат
      year_idSelected: "",
      mySchoolClassesConfirm: [],
      countConfirmAll: 0,
      countNotConfirmAll: 0,
      countConfirmAllPercent: 0,

      allYears: [],
      year_id: "",
    };
  },
  watch: {
    // year_id: function (newVal, oldVal) {
    //   //   console.log(newVal, oldVal);
    //   axios
    //     .post("/all_worker_functions/getAllManagerOgsmYear", {
    //       year_id: newVal,
    //     })
    //     .then((res) => {
    //       //   console.log(res.data);
    //       this.allOgsm = [];
    //       this.allOgsm = res.data.allOgsm;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  components: {
    searchStudent: searchStudent,
    createParent,
    Multiselect,
    studentOpenDay: studentOpenDay,
    VueDocPreview,
  },
  computed: {
    all_users() {
      return this.$store.state.all_users;
    },
    activeYear() {
      // this.yearId = this.$store.state.activeYear;
      return this.$store.state.activeYear;
    },
    userInfo() {
      return this.$store.state.user.employee_user;
    },
    // Хүснэгтийг шалгаж угсрах зорилгоор, ер нь хаана ч ашиглаж болно.
    hasAccess() {
      return [
        "agerman",
        "cipalprin",
        "minadcurri",
        "agermankg",
        "hr",
        "logistpsycho",
        "finance",
        "accountant",
        "socialworker",
        "supervisorshift",
        "reg_emp",
        "reg_empsk",
        "celorcounce",
      ].includes(this.role);
    },
    hasAdminAccess() {
      return ["agerman", "agermankg", "cipalprin", "reg_emp"].includes(
        this.role
      );
    },
  },

  methods: {
    setFieldList: function (e) {
      if (this.firstMode == true) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = this.fieldList;
      this.fieldList.map(function (value, key) {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
    getClassData() {
      axios
        .get("/accountant/getClassData")
        .then((response) => {
          this.school_classes = response.data.school_classes;
          //   if (
          //     this.school_classes.length > 0 &&
          //     (response.data.role == "cipalprin" ||
          //       response.data.role == "minadcurri" ||
          //       response.data.role == "hr" ||
          //       response.data.role == "adminOfficer" ||
          //       response.data.role == "agerman")
          //   ) {
          //     this.selected.push(this.school_classes[4]);
          //   } else if (
          //     this.school_classes.length > 0 &&
          //     response.data.role == "agermankg"
          //   ) {
          //       this.selected.push(this.school_classes[0]);
          //   }
          this.selected.push(this.school_classes[0]);
          this.schoolYears = response.data.schoolYears;
          this.leave_types = response.data.school_leave_types;
          this.role = response.data.role;
          this.allYears = response.data.allYears;
          this.year_id = response.data.yearId;
          this.getAllStudents();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getAllStudents() {
      let selectedClassIds = [];
      if (this.selected)
        this.selected.map((q) => {
          selectedClassIds.push(q.id);
        });
      axios
        .post("/accountant/getAllStudents", {
          selectedClass: selectedClassIds,
          year_id: this.year_id,
        })
        .then((response) => {
          this.schoolstudents = response.data.schoolstudents;

          let a = [];
          let b = [];
          this.schoolstudents.forEach((element) => {
            if (element.confirm_next_year == 1) {
              a.push(element.confirm_next_year);
            } else if (element.confirm_next_year == 0) {
              b.push(element.confirm_next_year);
            }
          });
          this.selectedClassConfirmParent = a.length;
          this.selectedClassNotConfirmParent = b.length;

          let c = (a.length / this.schoolstudents.length) * 100;
          this.selectedClassConfirmParentPercent = c.toFixed(1);
          this.fieldList = response.data.fields;
          var jsonF = {};
          this.fieldList.map(function (value, key) {
            var obj = {};
            var myLabel = value.label;
            obj = { [myLabel]: value.value };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
          this.json_data = response.data.schoolstudents;
          this.depInfo = response.data.department;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolteacher);
    },
    editSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.deleteMode = false;
      this.createMode = true;
      this.parentDataProps = null;
      this.form.reset();
      this.form.fill(schoolteacher);
      this.form.school_class_id = schoolteacher.school_class_id;
      this.form.schoolYearId = schoolteacher.year_id;
    },
    updateSchoolStudent() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateStudent/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.parentDataProps = null;
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolstudent) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolstudent);
      this.deleteMode = true;
      this.editMode = false;
      this.formLeave.reset();
      this.formLeave.fill(schoolstudent);
      this.formLeave.leave_type_list = [];
    },
    leaveStudent() {
      this.formLeave
        .post("/manager/leaveStudent/")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.formLeave.reset();
          this.deleteMode = !this.deleteMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelDelete() {
      this.formLeave.reset();
      this.deleteMode = !this.deleteMode;
    },
    inActiveSchoolStudent(schoolStudent) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах", schoolStudent);
      Swal.fire({
        title:
          "Суралцагчыг тухайн хичээлийн жилд идэвхгүй төлөвт шилжүүлэхдээ итгэлтэй байна уу?",
        text: "Хичээлийн жилийг маш сайн нягтална уу?",
        showDenyButton: true,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Идэвхгүй төлөвт шилжүүлэх`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .post("/manager/inActiveStudentYear", {
              schoolStudent: schoolStudent,
            })
            .then((response) => {
              //   console.log(response);
              Fire.$emit("loadSchoolStudents");
              Swal.fire("Амжилттай!", "", "success");
            })
            .catch((error) => {
              //   console.log(error);
            });
          /* Read more about isConfirmed, isDenied below */
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    createSchoolStudent() {
      this.action = "Суралцагчийг үүсгэж байна...";
      this.form
        .post("/manager/createStudent")
        .then((response) => {
          this.$toastr.s("Суралцагчийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolStudents");
          this.parentDataProps = null;
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEdit() {
      this.form.reset();
      this.parentDataProps = null;
      this.editMode = !this.editMode;
    },
    createParent(studentInfo) {
      this.createMode = !this.createMode;
      this.forParentChild = studentInfo;
      //   console.log(studentInfo.profile_parent_id);
      if (studentInfo.profile_parent_id != null) {
        axios
          .get("/manager/getCurrentParent/" + studentInfo.profile_parent_id)
          .then((response) => {
            this.parentDataProps = response.data.parentDataProps;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      } else this.parentDataProps = null;
      this.$bvModal.show("my-modal");
      //   this.$refs["my-modal"].show();
    },
    getEditMode(payload) {
      //   console.log("Hi.........", payload);
    },

    checkStudents(event) {
      //   console.log(event);
      this.schoolstudents = event;
    },
    resetPass(student) {
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/teacher/resetRandomPass/" + student.user_id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц үг амжилттай шинэчиллээ!",
                "Нууц үг: " + response.data.randomPass,
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    // getBase64ImageFromURL(url) {
    //   return new Promise((resolve, reject) => {
    //     var img = new Image();
    //     img.setAttribute("crossOrigin", "anonymous");

    //     img.onload = () => {
    //       var canvas = document.createElement("canvas");
    //       canvas.width = img.width;
    //       canvas.height = img.height;

    //       var ctx = canvas.getContext("2d");
    //       ctx.drawImage(img, 0, 0);

    //       var dataURL = canvas.toDataURL("image/png");

    //       resolve(dataURL);
    //     };

    //     img.onerror = (error) => {
    //       reject(error);
    //     };

    //     img.src = url;
    //   });
    // },
    surdagUnen(student) {
      //   console.log(student);
      axios
        .get("/manager/schoolLogo")
        .then((response) => {
          //   console.log(response.data.schoolLogo);
          this.schoolLogo = response.data.schoolLogo;
          var dd = {
            pageSize: "A5",
            content: [
              {
                image: "building",
                width: 80,
                style: ["header", "anotherStyle"],
              },
              {
                text: "\n\n\n\n",
              },
              {
                text: "Суралцагчийн тодорхойлолт\n\n\n\n",
                style: ["anotherStyle"],
              },
              {
                text: [
                  {
                    text: ".............",
                    fontSize: 12,
                  }, //Овог 0
                  " овогтой ", // 1
                  { text: "a better ", fontSize: 12, style: ["anotherStyle"] }, //нэр 2
                  " нь                   \n ", //3
                  { text: "styled ", fontSize: 12, style: ["anotherStyle"] }, //эхлэх огноо 4
                  { text: "-", style: ["anotherStyle"] }, // 5
                  {
                    text: "independently ",
                    // italics: true,
                    fontSize: 12,
                    style: ["anotherStyle"],
                  }, //төгсөх огноо 6
                  {
                    text: " оны хичээлийн жилд \n Нийслэлийн ерөнхий боловсролын Сэлбэ олон улсын сургуулийн    ", //7
                    style: ["anotherStyle"],
                  },

                  {
                    text: "", //8
                  },
                  {
                    text: "   ангид суралцдаг нь үнэн болно.\n\n\n\n", //[9]
                    style: ["anotherStyle"],
                  },
                ],
                style: ["anotherStyle"],
              },

              {
                text: "", //[10]
                style: ["anotherStyle"],
              },
              {
                text: "", //Улаанбаатар хот 2024 он.
                style: ["anotherStyle"],
              },
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
              },
              subheader: {
                fontSize: 15,
                bold: true,
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
              anotherStyle: {
                // italics: true,
                alignment: "center",
                lineHeight: 1.5,
              },
            },
            images: {
              building: "",
            },
          };
          dd.content[3].text[0].text = student.student_last_name;
          dd.content[3].text[2].text = student.student_name;
          dd.content[3].text[4].text =
            this.schoolLogo[0].start_date[0] +
            this.schoolLogo[0].start_date[1] +
            this.schoolLogo[0].start_date[2] +
            this.schoolLogo[0].start_date[3];
          dd.content[3].text[6].text =
            this.schoolLogo[0].finish_date[0] +
            this.schoolLogo[0].finish_date[1] +
            this.schoolLogo[0].finish_date[2] +
            this.schoolLogo[0].finish_date[3];
          dd.content[3].text[8].text = student.full_name;
          //   dd.content[3].text[8].text = student.full_name;
          dd.content[dd.content.length - 2].text =
            "Суралцахуйн албаны менежер....................... " +
            this.userInfo.teacher_last_name[0] +
            "." +
            this.userInfo.teacher_name +
            " \n\n\n";

          // Одоогийн огноо авах
          const today = new Date();

          // Жил, сар, өдрийг авах
          const year = today.getFullYear();
          const month = today.getMonth() + 1; // Сар нь 0-с эхэлдэг тул 1 нэмнэ
          const day = today.getDate();
          dd.content[dd.content.length - 1].text =
            "Улаанбаатар хот \n" +
            "Огноо: " +
            year +
            "." +
            month +
            "." +
            day +
            "\n\n";
          dd.images.building = this.schoolLogo[0].school_logo;
          //   console.log(dd.images.building);
          let downName =
            student.student_last_name[0] +
            student.student_last_name[1] +
            "." +
            student.student_name +
            ".pdf";
          pdfMake.createPdf(dd).download(downName);
          //   pdfMake.createPdf(dd).open();
          //   pdfMake.createPdf(dd).open();
          //   console.log(downName);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // Тооцоолол
    //A
    countAMale() {
      let countMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эрэгтэй"
      ).length;
      return countMale;
    },
    countAFeMale() {
      let countFeMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эмэгтэй"
      ).length;
      return countFeMale;
    },
    countA() {
      let countA = this.countAMale() + this.countAFeMale();
      return countA;
    },
    //Суралцагчын мэдээлэл засах
    resetStudentMoreInfoEditForm() {
      this.formEdit.reset();
    },
    editStudentInfo(studentInfo) {
      this.isShow = false;
      axios
        .get("/manager/getAllSchoolQuarters")
        .then((res) => {
          this.quarters = res.data.schoolquarters;
          this.isShow = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/allCity")
        .then((response) => {
          this.aimagOptions = response.data.allCity;
          this.getMyInfoStudent = studentInfo;
          //   console.log(studentInfo);
          this.formEdit.reset();
          this.formEdit.fill(studentInfo);
          this.$bvModal.show("modal-student-info");
          if (this.formEdit.student_duureg != 0) {
            this.getSum();
          } else this.formEdit.student_duureg = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSum() {
      axios
        .get("/allDistrict/" + this.formEdit.student_duureg)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
          this.khorooOptions = [];
          if (this.formEdit.student_khoroo != 0) {
            this.getKhoroo();
          } else this.formEdit.student_khoroo = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getKhoroo() {
      axios
        .post("/allKhoroo", {
          cityId: this.formEdit.student_duureg,
          districtId: this.formEdit.student_khoroo,
        })
        .then((response) => {
          this.khorooOptions = response.data.allKhoroo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    updateStudent() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.formEdit
        .put(
          "/teacher/updateMyClassStudent/" + this.getMyInfoStudent.profile_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(
            "Сурагчийн мэдээллийг амжилттай заслаа!",
            "Шинэчилсэн"
          );
          this.formEdit.reset();
          this.$bvModal.hide("modal-student-info");
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors.response);
          //   console.log(errors.response.data.errors);
          this.$toastr.e(errors.response.data.message, "Алдаа");
        });
    },
    cancelUpdate() {
      this.formEdit.reset();
      this.$bvModal.hide("modal-student-info");
    },

    // Элсэлтийн шалгалтын мэдээлэл
    showSchoolEntrantExam(schoolEntrant) {
      // console.log(schoolEntrant);
      this.formEntrunt.mon_script = schoolEntrant.mon_script;
      this.formEntrunt.mon_maths = schoolEntrant.mon_maths;
      this.formEntrunt.camb_maths = schoolEntrant.camb_maths;
      this.formEntrunt.camb_eng = schoolEntrant.camb_eng;
      this.formEntrunt.camb_sci = schoolEntrant.camb_sci;
      this.$bvModal.show("modal-entrustInfo");
    },
    cancelEntrant() {
      this.formEntrunt.reset();
      this.$bvModal.hide("modal-entrustInfo");
    },

    // Эцэг эхийн хүсэлтийг файлаар хадгалах
    applicationFileInfo(studentInfo) {
      //   console.log(studentInfo);
      this.formFile.reset();
      this.formFile.profile_id = studentInfo.profile_id;
      this.$bvModal.show("modal-files");
    },
    viewFile(url, date) {
      // console.log("view");
      // console.log(url);
      this.showFileInfo.info = " Огноо:" + date;
      var typeArr = url.split(".");
      this.docType = typeArr[typeArr.length - 1];
      if (this.docType == "pdf") {
        this.pdfValue = url;
      } else this.docValue = "grading4you.com" + url;
    },
    // Файлтай ажиллах хэсэг
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} файл сонгогдлоо`;
    },

    selectFilesParentRequest(e) {
      //   console.log(e);
      this.formFile.file = e.target.files[0];
      //   console.log(this.formFile.file);
    },
    createParentApplicationFile() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formFile.file);
      // formData.append("docUploadType", this.docUploadType);
      // formData.append("docUploadUrl", this.formFile.url);
      axios
        .post(
          "/manager/createParentApplicationFile/" + this.formFile.profile_id,
          formData,
          config
        )
        .then(function (response) {
          if (response.data.errorMsj != "") {
            Swal.fire("Алдаа гарлаа!", response.data.errorMsj, "warning");
          } else {
            Fire.$emit("loadSchoolStudents");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
          }
        });

      this.$bvModal.hide("modal-files");
    },
    cancelCreateFiles() {
      this.formFile.reset();
      this.$bvModal.hide("modal-files");
    },

    deleteParentApplicationFile(file) {
      // console.log(file);
      Swal.fire({
        title: "Та итгэлтэй байна уу? Файл устгах гэж байна.",
        text: "Файлыг устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/deleteParentApplicationFile/" + file.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Файлыг системээс устгалаа.", "success");
              Fire.$emit("loadSchoolStudents");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // Суралцагчийн оношилгоог файлаар хадгалах
    diagnosticFileInfo(studentInfo) {
      this.formDiagnosticFile.reset();
      this.formDiagnosticFile.stud_id = studentInfo.user_id;
      this.$bvModal.show("modal-stud-diagnostics");
    },
    selectFilesDiagnosticRequest(e) {
      this.formDiagnosticFile.file = e.target.files[0];
    },
    createDiagnosticFile() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formDiagnosticFile.file);
      axios
        .post(
          "/manager/createDiagnosticFile/" + this.formDiagnosticFile.stud_id,
          formData,
          config
        )
        .then(function (response) {
          if (response.data.errorMsj != "") {
            Swal.fire("Алдаа гарлаа!", response.data.errorMsj, "warning");
          } else {
            Fire.$emit("loadSchoolStudents");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
          }
        });

      this.$bvModal.hide("modal-stud-diagnostics");
    },
    cancelDiagnosticFiles() {
      this.formDiagnosticFile.reset();
      this.$bvModal.hide("modal-stud-diagnostics");
    },

    deleteDiagnosticFile(file) {
      // console.log(file);
      Swal.fire({
        title: "Та итгэлтэй байна уу? Файл устгах гэж байна.",
        text: "Файлыг устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/deleteDiagnosticFile/" + file.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Файлыг системээс устгалаа.", "success");
              Fire.$emit("loadSchoolStudents");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // Эцэг эх нь багш бол холболт хийх
    customNameTeacher({
      emp_teacher_name,
      emp_teacher_last_name,
      dep_department_code,
    }) {
      return `${emp_teacher_last_name} ${emp_teacher_name}(${dep_department_code})`;
    },
    addTeacherParentForStudent(studentInfo) {
      // console.log(studentInfo)
      this.formTEmpUserInfo.studentInfo = studentInfo.user_id;
      this.$bvModal.show("modal-student-add-tparent");
    },
    updateTeacherParentForStudent() {
      this.formTEmpUserInfo
        .post("/manager/updateTeacherParentForStudent")
        .then((response) => {
          // this.aimagOptions = response.data.allCity;
          this.closeTeacherParentForStudent();
          Swal.fire("Эцэг эх холбох!", "Амжилттай холболоо.", "success");
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
          // Багш эцэг эхтэй суралцагчийг холбох
          // updateTeacherParentForStudent
        });
    },
    closeTeacherParentForStudent() {
      this.formTEmpUserInfo.reset();
      this.$bvModal.hide("modal-student-add-tparent");
    },
    // Баталгаажуулалт хийх
    confirmNextYear(item) {
      // console.log(item);
      // console.log(this);
      this.formConfirmNextYear.stud_mig_id = item.stud_mig_id;
      this.formConfirmNextYear.confirm_next_year_status =
        item.confirm_next_year == 1 ? "true" : "false";
      this.$bvModal.show("modal-student-confirm");
    },
    hidemyconfirmmodal() {
      this.$bvModal.hide("modal-student-confirm");
      this.formConfirmNextYear.reset();
      this.docValue = "";
      this.pdfValue = "";
      this.docType = "";
    },
    selectFiles(e) {
      this.formConfirmNextYear.file = e.target.files[0];
    },
    updateConfrimNextYearInfo() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formConfirmNextYear.file);
      formData.append(
        "status",
        this.formConfirmNextYear.confirm_next_year_status
      );
      axios
        .post(
          "/teacher/updateConfrimNextYearInfo/" +
            this.formConfirmNextYear.stud_mig_id,
          formData,
          config
        )
        .then((res) => {
          // console.log(res.data.errorMsj);
          if (res.data.errorMsj != "")
            Swal.fire("Алдаа гарлаа!", res.data.errorMsj, "warning");
          else {
            // this.hidemyconfirmmodal();
            Fire.$emit("loadSchoolStudents");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
            this.hidemyconfirmmodal();
          }
        })
        .catch((err) => console.log(err));
    },
    viewFileConfirm(url) {
      // console.log(url);
      var typeArr = url.split(".");
      this.docType1 = typeArr[typeArr.length - 1];
      if (this.docType1 == "pdf") {
        this.pdfValue1 = url;
      } else this.docValue1 = "grading4you.com" + url;
    },
    // Суудал баталгаажилтын статистик
    // model open
    openStatModal() {
      this.checkAllSchoolClasses();
      this.$bvModal.show("modal-student-confirm-stat");
    },
    checkAllSchoolClasses() {
      this.mySchoolClassesConfirm = [];
      axios
        .post("/accountant/confirmstat", {
          year_id: this.year_idSelected,
        })
        .then((res) => {
          // console.log(res);
          this.mySchoolClassesConfirm = res.data.mySchoolClasses;
          let confirmToo = [];
          let notconfirmToo = [];
          this.mySchoolClassesConfirm.forEach((element) => {
            confirmToo.push(element.my_students_of_class_confirm_count);
            notconfirmToo.push(element.my_students_of_class_not_confirm_count);
          });
          let niilberConfirm = confirmToo.reduce((a, b) => a + b);
          let niilberNotConfirm = notconfirmToo.reduce((a, b) => a + b);
          this.countConfirmAll = niilberConfirm;
          this.countNotConfirmAll = niilberNotConfirm;
          let x =
            (this.countConfirmAll /
              (this.countConfirmAll + this.countNotConfirmAll)) *
            100;
          this.countConfirmAllPercent = x.toFixed(1);
          // console.log(this.countConfirmAll, this.countNotConfirmAll);
        })
        .catch((err) => console.log(err));
    },
    countPercentConfirm(countConfirm, countNotConfirm) {
      let c = (countConfirm / (countConfirm + countNotConfirm)) * 100;
      return c.toFixed(1);
    },
    closeConfirmParents() {
      this.mySchoolClassesConfirm = [];
      this.$bvModal.hide("modal-student-confirm-stat");
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    customSchoolClassName({ class_number, full_name, class_cabinet }) {
      if (
        parseInt(class_number) == 0 ||
        parseInt(class_number) == -1 ||
        parseInt(class_number) == -2
      ) {
        return `${class_cabinet}`;
      } else {
        return `${full_name} анги`;
      }
    },
    // Суралцагчийн profile зураг оруулах
    changeImage(year) {
      this.formProfile.reset();
      this.successUpload = "";
      this.start_date = "";
      this.finish_date = "";
      axios
        .post("/teacher/studProfileImg/", {
          studMigId: year.profile_id,
          yearID: year.year_id,
        })
        .then((res) => {
          this.formProfile.yearId = year.year_id;
          this.start_date = year.my_year_info.start_date;
          this.finish_date = year.my_year_info.finish_date;
          this.formProfile.yearId = year.year_id;
          this.formProfile.profile_id = res.data.studMigData.profile_id;
          this.formProfile.student_profile_photo_url =
            res.data.studMigData.student_profile_photo_url;
          this.getSelectedProfileImg =
            res.data.studMigData.student_profile_photo_url;
          this.$bvModal.show("modal-profileImg");
        });
    },
    cancelUpload() {
      this.formProfile.reset();
      this.start_date = "";
      this.finish_date = "";
      this.$bvModal.hide("modal-profileImg");
    },
    uploadProfile() {
      axios
        .post("/teacher/uploadStudentImg/" + this.formProfile.profile_id, {
          getSelectedProfileImg: this.getSelectedProfileImg,
          yearID: this.formProfile.yearId,
        })
        .then((res) => {
          Fire.$emit("loadSchoolStudents");
          this.successUpload = "Суралцагчийн зургийг амжилттай шинэчиллээ!";
          this.loaded = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    uploadProfileCancel() {
      //   console.log("Ажиллаж байна. uploadProfileCancel");
      this.loaded = false;
      this.getSelectedProfileImg = "/images/users/user.png";
    },
    getProfileImg(e) {
      //   console.log("Ажиллаж байна. getProfileImg", e);
      let studentProfileImg = e.target.files[0];
      this.readImageHor(studentProfileImg);
    },
    readImageHor(studentProfileImg) {
      let reader = new FileReader();
      reader.readAsDataURL(studentProfileImg);
      reader.onload = (e) => {
        this.getSelectedProfileImg = e.target.result;
      };
      this.loaded = true;
    },
  },
  created() {
    this.getClassData();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllStudents();
    });
  },
};
</script>

<style>
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.d-block {
  display: block !important;
}
</style>
