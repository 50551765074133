<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card card-primary card-tabs">
        <div class="card-header p-0 pt-1">
          <b-row>
            <b-col sm="9">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <!-- Суралцагчид -->
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-student-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-student"
                    role="tab"
                    aria-controls="custom-tabs-one-student"
                    aria-selected="true"
                    >Суралцагч</a
                  >
                </li>
                <!-- Анги -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-school-class-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-school-class"
                    role="tab"
                    aria-controls="custom-tabs-one-school-class"
                    aria-selected="false"
                    >Анги</a
                  >
                </li>
                <!-- Багш -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-employees-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-employees"
                    role="tab"
                    aria-controls="custom-tabs-one-employees"
                    aria-selected="false"
                    >Багш, ажилтан</a
                  >
                </li>
              </ul>
            </b-col>
            <b-col sm="3">
              <b-button
                variant="warning"
                class="float-right"
                @click.prevent="createSP()"
                >Төлөвлөгөө бүртгэх</b-button
              >
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-one-tabContent">
            <!-- Суралцагч -->

            <div
              class="tab-pane fade show active"
              id="custom-tabs-one-student"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-student-tab"
            >
              <div class="col-lg-12 mt-3 mb-3">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <b-container class="bv-example-row" fluid>
                        <b-button-group size="sm">
                          <b-button
                            :pressed="studStat === 'Элсэлт'"
                            variant="primary"
                            @click="specialPlanStud('Элсэлт')"
                          >
                            Элсэлтийн хоцрогдол
                          </b-button>
                          <b-button
                            :pressed="studStat === 'Хоцрогдол'"
                            variant="warning"
                            @click="specialPlanStud('Хоцрогдол')"
                          >
                            Хоцрогдол
                          </b-button>
                          <b-button
                            :pressed="studStat === 'Хүмүүжил'"
                            variant="success"
                            @click="specialPlanStud('Хүмүүжил')"
                          >
                            Хүмүүжил
                          </b-button>
                          <b-button
                            :pressed="studStat === 'Ахлах боловсрол'"
                            variant="danger"
                            @click="specialPlanStud('Ахлах боловсрол')"
                          >
                            Ахлах боловсрол
                          </b-button>
                        </b-button-group>
                      </b-container>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <b-overlay
                      :show="show"
                      rounded="sm"
                      v-if="studStat == 'Элсэлт'"
                    >
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <!-- <th>Агуулга</th> -->
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th
                              v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                              title="Хэрэглэгч"
                            >
                              Харуулах эсэх
                            </th>
                            <th>Огноо</th>
                            <!-- <th>Анализ</th>
                <th>Хяналтын үзүүлэлт</th> -->
                          </tr>
                        </thead>
                        <tbody
                          v-for="(specialPlan, index) in specialPlanOfStudents"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-primary"
                                @click="editSpecialPlan(specialPlan, 'student')"
                                ><i class="fa fa-check"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteSpecialPlan(specialPlan)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0">
                              <!-- <div
                                class="
                                  progress progress-xs progress-striped
                                  active
                                "
                              >
                                <div
                                  v-if="specialPlan.point < 30"
                                  class="progress-bar bg-danger"
                                  :style="{ width: `${specialPlan.point}%` }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlan.point >= 30 &&
                                    specialPlan.point < 60
                                  "
                                  class="progress-bar bg-warning"
                                  :style="{ width: `${specialPlan.point}%` }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlan.point >= 60 &&
                                    specialPlan.point < 80
                                  "
                                  class="progress-bar bg-primary"
                                  :style="{ width: `${specialPlan.point}%` }"
                                ></div>
                                <div
                                  v-if="specialPlan.point >= 80"
                                  class="progress-bar bg-success"
                                  :style="{ width: `${specialPlan.point}%` }"
                                ></div>
                              </div>
                              <small>
                                <b-badge
                                  v-if="specialPlan.point >= 80"
                                  variant="success"
                                  v-show="specialPlan.point != null"
                                >
                                  {{ specialPlan.point }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlan.point >= 60 &&
                                    specialPlan.point < 80
                                  "
                                  variant="primary"
                                  v-show="specialPlan.point != null"
                                >
                                  {{ specialPlan.point }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlan.point >= 30 &&
                                    specialPlan.point < 60
                                  "
                                  variant="warning"
                                  v-show="specialPlan.point != null"
                                >
                                  {{ specialPlan.point }}</b-badge
                                >
                                <b-badge
                                  v-if="specialPlan.point < 30"
                                  variant="danger"
                                  v-show="specialPlan.point != null"
                                >
                                  {{ specialPlan.point }}</b-badge
                                >
                                <b-badge
                                  variant="warning"
                                  v-show="specialPlan.point === null"
                                >
                                  ОРООГҮЙ</b-badge
                                >
                              </small> -->
                            </td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <!-- <td tabindex="0">
                  <small>
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      specialPlan.description
                    }}</b-badge>
                  </small>
                </td> -->
                            <td
                              tabindex="0"
                              v-if="
                                specialPlan.en_roll_employee_user_special_plan !=
                                null
                              "
                            >
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul
                                class="list-inline"
                                v-if="
                                  specialPlan.special_plan_students !== null
                                "
                              >
                                <li
                                  class="list-inline-item"
                                  v-for="student in specialPlan.special_plan_students"
                                  :key="student.id"
                                >
                                  <img
                                    v-if="
                                      student.student_user_special_plan !== null
                                    "
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      student.student_user_special_plan
                                        .student_profile_photo_url
                                        ? student.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      student.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      student.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                              <ul class="list-inline" v-else>
                                Суралцагч сонгогдоогүй байна.
                              </ul>
                            </td>
                            <td tabindex="0">
                              <b-icon
                                v-if="specialPlan.read_par_stud == 1"
                                icon="eye-fill"
                                variant="success"
                                @click.prevent="disableView(specialPlan)"
                              ></b-icon>
                              <b-icon
                                v-if="specialPlan.read_par_stud == 0"
                                icon="eye-slash-fill"
                                variant="secondary"
                                @click.prevent="enableView(specialPlan)"
                              ></b-icon>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                            <!-- <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.analysis
                  }}</b-badge>
                </td>
                <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.indicator
                  }}</b-badge>
                </td> -->
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgeeAvg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgee0Avg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ niit }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                    <b-overlay
                      :show="show"
                      rounded="sm"
                      v-if="studStat == 'Хоцрогдол'"
                    >
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <!-- <th>Агуулга</th> -->
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th
                              v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                              title="Хэрэглэгч"
                            >
                              Харуулах эсэх
                            </th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(specialPlan, index) in specialPlanOfStudents"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-primary"
                                @click="editSpecialPlan(specialPlan, 'student')"
                                ><i class="fa fa-check"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteSpecialPlan(specialPlan)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0"></td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td
                              tabindex="0"
                              v-if="
                                specialPlan.en_roll_employee_user_special_plan !=
                                null
                              "
                            >
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul
                                class="list-inline"
                                v-if="
                                  specialPlan.special_plan_students !== null
                                "
                              >
                                <li
                                  class="list-inline-item"
                                  v-for="student in specialPlan.special_plan_students"
                                  :key="student.id"
                                >
                                  <img
                                    v-if="
                                      student.student_user_special_plan !== null
                                    "
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      student.student_user_special_plan
                                        .student_profile_photo_url
                                        ? student.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      student.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      student.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                              <ul class="list-inline" v-else>
                                Суралцагч сонгогдоогүй байна.
                              </ul>
                            </td>
                            <td tabindex="0">
                              <b-icon
                                v-if="specialPlan.read_par_stud == 1"
                                icon="eye-fill"
                                variant="success"
                                @click.prevent="disableView(specialPlan)"
                              ></b-icon>
                              <b-icon
                                v-if="specialPlan.read_par_stud == 0"
                                icon="eye-slash-fill"
                                variant="secondary"
                                @click.prevent="enableView(specialPlan)"
                              ></b-icon>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgeeAvg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgee0Avg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ niit }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                    <b-overlay
                      :show="show"
                      rounded="sm"
                      v-if="studStat == 'Хүмүүжил'"
                    >
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <!-- <th>Агуулга</th> -->
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th
                              v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                              title="Хэрэглэгч"
                            >
                              Харуулах эсэх
                            </th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(
                            specialPlan, index
                          ) in allspecialPlanOfStudents"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-primary"
                                @click="editSpecialPlan(specialPlan, 'student')"
                                ><i class="fa fa-check"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteSpecialPlan(specialPlan)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0"></td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td
                              tabindex="0"
                              v-if="
                                specialPlan.en_roll_employee_user_special_plan !=
                                null
                              "
                            >
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  <img
                                    v-if="
                                      specialPlan.student_user_special_plan !==
                                      null
                                    "
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_user_special_plan
                                        .student_profile_photo_url
                                        ? specialPlan.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                            </td>
                            <td tabindex="0">
                              <b-icon
                                v-if="specialPlan.read_par_stud == 1"
                                icon="eye-fill"
                                variant="success"
                                @click.prevent="disableView(specialPlan)"
                              ></b-icon>
                              <b-icon
                                v-if="specialPlan.read_par_stud == 0"
                                icon="eye-slash-fill"
                                variant="secondary"
                                @click.prevent="enableView(specialPlan)"
                              ></b-icon>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgeeAvg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgee0Avg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ niit }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                    <b-overlay
                      :show="show"
                      rounded="sm"
                      v-if="studStat == 'Ахлах боловсрол'"
                    >
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <!-- <th>Агуулга</th> -->
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th
                              v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                              title="Хэрэглэгч"
                            >
                              Харуулах эсэх
                            </th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(specialPlan, index) in specialPlanOfCouncelor"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-primary"
                                @click="editSpecialPlan(specialPlan, 'student')"
                                ><i class="fa fa-check"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteSpecialPlan(specialPlan)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0"></td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td
                              tabindex="0"
                              v-if="
                                specialPlan.en_roll_employee_user_special_plan !==
                                null
                              "
                            >
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  <img
                                    v-if="
                                      specialPlan.student_user_special_plan !==
                                      null
                                    "
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_user_special_plan
                                        .student_profile_photo_url
                                        ? specialPlan.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                            </td>
                            <td tabindex="0">
                              <b-icon
                                v-if="specialPlan.read_par_stud == 1"
                                icon="eye-fill"
                                variant="success"
                                @click.prevent="disableView(specialPlan)"
                              ></b-icon>
                              <b-icon
                                v-if="specialPlan.read_par_stud == 0"
                                icon="eye-slash-fill"
                                variant="secondary"
                                @click.prevent="enableView(specialPlan)"
                              ></b-icon>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgeeAvg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgee0Avg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ niit }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>

            <!-- /.card-body -->
            <!-- Анги -->
            <div
              class="tab-pane fade show"
              id="custom-tabs-one-school-class"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-school-class-tab"
            >
              <div
                class="tab-pane fade show active"
                id="custom-tabs-one-student"
                role="tabpanel"
                aria-labelledby="custom-tabs-one-student-tab"
              >
                <div class="col-lg-12 mt-3">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <b-container class="bv-example-row" fluid>
                          <b-button-group size="sm">
                            <b-button
                              :pressed="studStatClass === 'Хүмүүжил'"
                              variant="success"
                              @click="specialPlanClass('Хүмүүжил')"
                            >
                              Хүмүүжил
                            </b-button>
                            <b-button
                              :pressed="studStatClass === 'Ахлах боловсрол'"
                              variant="danger"
                              @click="specialPlanClass('Ахлах боловсрол')"
                            >
                              Ахлах боловсрол
                            </b-button>
                          </b-button-group>
                        </b-container>
                      </div>
                    </div>
                    <div class="card-body table-responsive">
                      <b-overlay
                        :show="show"
                        v-if="studStatClass == 'Хүмүүжил'"
                        rounded="sm"
                      >
                        <table class="table projects">
                          <thead>
                            <tr role="row">
                              <th>#</th>
                              <th>Үйлдлүүд</th>
                              <th>Гүйцэтгэл</th>
                              <th>Төлөвлөгөөний нэр</th>
                              <!-- <th>Агуулга</th> -->
                              <th>Хариуцах эзэн</th>
                              <th>Төлөвлөгөөнд хамрагдах</th>
                              <th
                                v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                                title="Хэрэглэгч"
                              >
                                Харуулах эсэх
                              </th>
                              <th>Огноо</th>
                              <!-- <th>Анализ</th>
                <th>Хяналтын үзүүлэлт</th> -->
                            </tr>
                          </thead>
                          <tbody
                            v-for="(
                              specialPlan, index
                            ) in allspecialPlanOfSchoolClass"
                            :key="specialPlan.id"
                          >
                            <tr>
                              <td tabindex="0">
                                {{ index + 1 }}
                              </td>
                              <td tabindex="0">
                                <a
                                  href="#"
                                  class="badge bg-primary"
                                  @click="
                                    editSpecialPlan(specialPlan, 'schoolclass')
                                  "
                                  ><i class="fa fa-check"></i>
                                </a>
                                <a
                                  href="#"
                                  class="badge bg-success"
                                  @click="
                                    showSpecialPlanForActionsSchoolClass(
                                      specialPlan
                                    )
                                  "
                                  ><i class="fa fa-eye"></i>
                                </a>
                                <a
                                  href="#"
                                  class="badge bg-danger"
                                  @click="deleteSpecialPlan(specialPlan)"
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </a>
                              </td>
                              <td style="text-align: center" tabindex="0">
                                <div
                                  class="progress progress-xs progress-striped active"
                                ></div>
                                <small> </small>
                              </td>
                              <td tabindex="0">
                                <b-badge
                                  variant="light"
                                  style="white-space: pre-wrap"
                                  >{{ specialPlan.s_p_name }}</b-badge
                                >
                              </td>
                              <!-- <td tabindex="0">
                  <small>
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      specialPlan.description
                    }}</b-badge>
                  </small>
                </td> -->
                              <td
                                tabindex="0"
                                v-if="
                                  specialPlan.en_roll_employee_user_special_plan !=
                                  null
                                "
                              >
                                {{
                                  specialPlan.en_roll_employee_user_special_plan
                                    .teacher_last_name
                                    | capitalizeAndEkhniiVseg
                                }}.
                                {{
                                  specialPlan.en_roll_employee_user_special_plan
                                    .teacher_name
                                }}
                              </td>
                              <td tabindex="0">
                                <ul class="list-inline">
                                  <li class="list-inline-item">
                                    {{
                                      specialPlan.school_class_special_plan
                                        .full_name
                                    }}
                                    анги
                                  </li>
                                </ul>
                              </td>
                              <td tabindex="0">
                                <b-icon
                                  v-if="specialPlan.read_par_stud == 1"
                                  icon="eye-fill"
                                  variant="success"
                                  @click.prevent="disableView(specialPlan)"
                                ></b-icon>
                                <b-icon
                                  v-if="specialPlan.read_par_stud == 0"
                                  icon="eye-slash-fill"
                                  variant="secondary"
                                  @click.prevent="enableView(specialPlan)"
                                ></b-icon>
                              </td>
                              <td
                                class="dtr-control sorting_1 text-center"
                                tabindex="0"
                              >
                                {{
                                  specialPlan.s_p_start_date | dateYearMonthDay
                                }}
                                <br />
                                <i
                                  class="fa fa-arrow-down"
                                  aria-hidden="true"
                                ></i>
                                <br />
                                {{
                                  specialPlan.s_p_finish_date | dateYearMonthDay
                                }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Дундаж /0 тооцсон/</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ unelgeeAvg }}%</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Дундаж /0 тооцохгүй/</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ unelgee0Avg }}%</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Нийт:</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ niit }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-overlay>
                      <b-overlay
                        :show="show"
                        v-if="studStatClass == 'Ахлах боловсрол'"
                        rounded="sm"
                      >
                        <table class="table projects">
                          <thead>
                            <tr role="row">
                              <th>#</th>
                              <th>Үйлдлүүд</th>
                              <th>Гүйцэтгэл</th>
                              <th>Төлөвлөгөөний нэр</th>
                              <!-- <th>Агуулга</th> -->
                              <th>Хариуцах эзэн</th>
                              <th>Төлөвлөгөөнд хамрагдах</th>
                              <th
                                v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                                title="Хэрэглэгч"
                              >
                                Харуулах эсэх
                              </th>
                              <th>Огноо</th>
                              <!-- <th>Анализ</th>
                <th>Хяналтын үзүүлэлт</th> -->
                            </tr>
                          </thead>
                          <tbody
                            v-for="(
                              specialPlan, index
                            ) in allspecialPlanOfSchoolClassCouncelor"
                            :key="specialPlan.id"
                          >
                            <tr>
                              <td tabindex="0">
                                {{ index + 1 }}
                              </td>
                              <td tabindex="0">
                                <a
                                  href="#"
                                  class="badge bg-primary"
                                  @click="
                                    editSpecialPlan(specialPlan, 'schoolclass')
                                  "
                                  ><i class="fa fa-check"></i>
                                </a>
                                <a
                                  href="#"
                                  class="badge bg-success"
                                  @click="
                                    showSpecialPlanForActionsSchoolClass(
                                      specialPlan
                                    )
                                  "
                                  ><i class="fa fa-eye"></i>
                                </a>
                                <a
                                  href="#"
                                  class="badge bg-danger"
                                  @click="deleteSpecialPlan(specialPlan)"
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </a>
                              </td>
                              <td style="text-align: center" tabindex="0">
                                <div
                                  class="progress progress-xs progress-striped active"
                                ></div>
                                <small> </small>
                              </td>
                              <td tabindex="0">
                                <b-badge
                                  variant="light"
                                  style="white-space: pre-wrap"
                                  >{{ specialPlan.s_p_name }}</b-badge
                                >
                              </td>
                              <!-- <td tabindex="0">
                  <small>
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      specialPlan.description
                    }}</b-badge>
                  </small>
                </td> -->
                              <td
                                tabindex="0"
                                v-if="
                                  specialPlan.en_roll_employee_user_special_plan !=
                                  null
                                "
                              >
                                {{
                                  specialPlan.en_roll_employee_user_special_plan
                                    .teacher_last_name
                                    | capitalizeAndEkhniiVseg
                                }}.
                                {{
                                  specialPlan.en_roll_employee_user_special_plan
                                    .teacher_name
                                }}
                              </td>
                              <td tabindex="0">
                                <ul class="list-inline">
                                  <li class="list-inline-item">
                                    {{
                                      specialPlan.school_class_special_plan
                                        .full_name
                                    }}
                                    анги
                                  </li>
                                </ul>
                              </td>
                              <td tabindex="0">
                                <b-icon
                                  v-if="specialPlan.read_par_stud == 1"
                                  icon="eye-fill"
                                  variant="success"
                                  @click.prevent="disableView(specialPlan)"
                                ></b-icon>
                                <b-icon
                                  v-if="specialPlan.read_par_stud == 0"
                                  icon="eye-slash-fill"
                                  variant="secondary"
                                  @click.prevent="enableView(specialPlan)"
                                ></b-icon>
                              </td>
                              <td
                                class="dtr-control sorting_1 text-center"
                                tabindex="0"
                              >
                                {{
                                  specialPlan.s_p_start_date | dateYearMonthDay
                                }}
                                <br />
                                <i
                                  class="fa fa-arrow-down"
                                  aria-hidden="true"
                                ></i>
                                <br />
                                {{
                                  specialPlan.s_p_finish_date | dateYearMonthDay
                                }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Дундаж /0 тооцсон/</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ unelgeeAvg }}%</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Дундаж /0 тооцохгүй/</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ unelgee0Avg }}%</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" class="text-right">
                                <small>Нийт:</small>
                              </td>
                              <td colspan="3">
                                <strong>{{ niit }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-overlay>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Эцэг эх -->
            <!-- Багш, ажилтан -->
            <div
              class="tab-pane fade"
              id="custom-tabs-one-employees"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-employees-tab"
            >
              <div class="col-lg-12 mt-3">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <b-container class="bv-example-row" fluid>
                        <!-- <b-row>
                        <b-col md="6" sm="3">
                          <multiselect
                            v-model="selectedMonths"
                            :options="monthsRe"
                            :multiple="true"
                            track-by="name"
                            label="month"
                            :searchable="false"
                            :close-on-select="false"
                            :show-labels="false"
                            placeholder="Сар сонгох"
                            :allow-empty="true"
                            deselect-label="Can't remove this value"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong> {{ option.month }}</strong
                              ><strong> сонгогдлоо</strong></template
                            >
                          </multiselect>
                        </b-col>
                        <b-col md="6" sm="2">
                          <b-button
                            variant="success"
                            @click.prevent="getAllDepInfo()"
                            >Шүүж харах</b-button
                          >
                        </b-col>
                      </b-row> -->
                      </b-container>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <b-overlay :show="show" rounded="sm">
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <!-- <th>Агуулга</th> -->
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th
                              v-b-popover.hover.top="'Суралцагч, эцэг эх'"
                              title="Хэрэглэгч"
                            >
                              Харуулах эсэх
                            </th>
                            <th>Огноо</th>
                            <!-- <th>Анализ</th>
                <th>Хяналтын үзүүлэлт</th> -->
                          </tr>
                        </thead>
                        <tbody
                          v-for="(
                            specialPlan, index
                          ) in allspecialPlanOfTeachers"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-primary"
                                @click="editSpecialPlan(specialPlan, 'teacher')"
                                ><i class="fa fa-check"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="showSpecialPlanForActions(specialPlan)"
                                ><i class="fa fa-eye"></i>
                              </a>
                              <a
                                href="#"
                                class="badge bg-danger"
                                @click="deleteSpecialPlan(specialPlan)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0">
                              <div
                                class="progress progress-xs progress-striped active"
                              >
                                <!-- <div
                                v-if="specialPlan.point < 30"
                                class="progress-bar bg-danger"
                                :style="{ width: `${specialPlan.point}%` }"
                              ></div>
                              <div
                                v-if="
                                  specialPlan.point >= 30 &&
                                  specialPlan.point < 60
                                "
                                class="progress-bar bg-warning"
                                :style="{ width: `${specialPlan.point}%` }"
                              ></div>
                              <div
                                v-if="
                                  specialPlan.point >= 60 &&
                                  specialPlan.point < 80
                                "
                                class="progress-bar bg-primary"
                                :style="{ width: `${specialPlan.point}%` }"
                              ></div>
                              <div
                                v-if="specialPlan.point >= 80"
                                class="progress-bar bg-success"
                                :style="{ width: `${specialPlan.point}%` }"
                              ></div> -->
                              </div>
                              <small>
                                <!-- <b-badge
                                v-if="specialPlan.point >= 80"
                                variant="success"
                                v-show="specialPlan.point != null"
                              >
                                {{ specialPlan.point }}</b-badge
                              >
                              <b-badge
                                v-if="
                                  specialPlan.point >= 60 &&
                                  specialPlan.point < 80
                                "
                                variant="primary"
                                v-show="specialPlan.point != null"
                              >
                                {{ specialPlan.point }}</b-badge
                              >
                              <b-badge
                                v-if="
                                  specialPlan.point >= 30 &&
                                  specialPlan.point < 60
                                "
                                variant="warning"
                                v-show="specialPlan.point != null"
                              >
                                {{ specialPlan.point }}</b-badge
                              >
                              <b-badge
                                v-if="specialPlan.point < 30"
                                variant="danger"
                                v-show="specialPlan.point != null"
                              >
                                {{ specialPlan.point }}</b-badge
                              >
                              <b-badge
                                variant="warning"
                                v-show="specialPlan.point === null"
                              >
                                ОРООГҮЙ</b-badge
                              > -->
                              </small>
                            </td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <!-- <td tabindex="0">
                  <small>
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      specialPlan.description
                    }}</b-badge>
                  </small>
                </td> -->
                            <td
                              tabindex="0"
                              v-if="
                                specialPlan.en_roll_employee_user_special_plan !=
                                null
                              "
                            >
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  <!-- @click="showImage(teacher)"
                                  v-if="teacher.employee != null"
                                  v-b-tooltip.hover.top.html="
                                    '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                    teacher.department.department_name +
                                    '</dd><dt>Ажлын байр:</dt><dd>' +
                                    teacher.position.position_name +
                                    '</dd><dt>Овог Нэр</dt><dd>' +
                                    teacher.employee.teacher_last_name +
                                    ' <span class=text-uppercase>' +
                                    teacher.employee.teacher_name +
                                    '</span></dd></dl>'
                                  " -->
                                  <img
                                    v-if="
                                      specialPlan.employee_user_special_plan !=
                                      null
                                    "
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.employee_user_special_plan
                                        .profile_photo_url
                                        ? specialPlan.employee_user_special_plan
                                            .profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.employee_user_special_plan
                                        .teacher_last_name +
                                      '<span class=text-uppercase>' +
                                      specialPlan.employee_user_special_plan
                                        .teacher_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                            </td>
                            <td tabindex="0">
                              <b-icon
                                v-if="specialPlan.read_par_stud == 1"
                                icon="eye-fill"
                                variant="success"
                                @click.prevent="disableView(specialPlan)"
                              ></b-icon>
                              <b-icon
                                v-if="specialPlan.read_par_stud == 0"
                                icon="eye-slash-fill"
                                variant="secondary"
                                @click.prevent="enableView(specialPlan)"
                              ></b-icon>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                            <!-- <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.analysis
                  }}</b-badge>
                </td>
                <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.indicator
                  }}</b-badge>
                </td> -->
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgeeAvg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ unelgee0Avg }}%</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{ niit }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>

    <!-- Тусгай төлөвлөгөө бүртгэх -->
    <b-modal
      size="xl"
      id="modal-special-plan"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="editMode ? 'Сарын төлөвлөгөө засах' : 'Тусгай төлөвлөгөө бүртгэх'"
      class="modal fade"
      hide-footer
      @hide="resetSP"
    >
      <div class="card card-primary">
        <div class="card-body">
          <form>
            <div class="form-group">
              <b-row>
                <b-col lg="3">
                  <b-form-radio
                    v-model="selected"
                    name="some-student"
                    value="student"
                    @change="changeCheckBox;"
                    >Суралцагч</b-form-radio
                  >
                </b-col>
                <b-col lg="3">
                  <b-form-radio
                    v-model="selected"
                    name="some-student"
                    value="schoolclass"
                    @change="changeCheckBox"
                    >Анги</b-form-radio
                  >
                </b-col>
                <b-col lg="3">
                  <b-form-radio
                    v-model="selected"
                    name="some-teacher"
                    value="teacher"
                    @change="changeCheckBox"
                    >Багш, ажилчин</b-form-radio
                  >
                </b-col>
                <b-col lg="3">
                  <b-form-radio
                    v-model="selected"
                    name="some-parent"
                    value="parent"
                    @change="changeCheckBox"
                    >Эцэг эх</b-form-radio
                  >
                </b-col>
              </b-row>
            </div>
            <div class="form-group">
              <b-button-group size="sm" v-show="selected === 'student'">
                <b-button
                  :pressed="studStatCreate === 'Элсэлт'"
                  variant="primary"
                  @click="specialPlanStudCreate('Элсэлт')"
                >
                  Элсэлтийн хоцрогдол
                </b-button>
                <b-button
                  :pressed="studStatCreate === 'Хоцрогдол'"
                  variant="warning"
                  @click="specialPlanStudCreate('Хоцрогдол')"
                >
                  Хоцрогдол
                </b-button>
                <b-button
                  :pressed="studStatCreate === 'Хүмүүжил'"
                  variant="success"
                  @click="specialPlanStudCreate('Хүмүүжил')"
                >
                  Хүмүүжил
                </b-button>
              </b-button-group>
              <table
                class="table table-bordered text-center"
                v-if="studStatCreate === 'Элсэлт'"
                v-show="selected === 'student'"
              >
                <thead>
                  <tr role="row">
                    <th rowspan="2">№</th>
                    <th rowspan="2">Анги</th>
                    <th rowspan="2">Төлөв</th>
                    <th rowspan="2">Суралцагчийн овог нэр</th>
                    <th colspan="5">Судлагдахууны нэр</th>
                    <th rowspan="2">Суралцагчийн дундаж үнэлгээ</th>
                  </tr>
                  <tr>
                    <th>Монгол хэл</th>
                    <th>Мат (мон/цөм)</th>
                    <th>Мат (camb)</th>
                    <th>English (mon/camb)</th>
                    <th>Science (for/camb)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(grade, index) in newStudExam" :key="grade.id">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <span v-if="grade.student_mig_data">
                        {{ grade.student_mig_data.full_name }}
                        анги</span
                      >
                      <span v-else>{{ grade.study_class }}-р анги</span>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="grade.status1 == 'active'"
                        >Элсэлт баталгаажсан</b-badge
                      >
                      <b-badge variant="warning" v-else>Хүлээлгэнд</b-badge>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ grade.lastName }}.{{ grade.studName }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="
                          parseInt(grade.exam1) >= 80 &&
                          parseInt(grade.exam1) <= 100
                        "
                        >{{ grade.exam1 }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="
                          parseInt(grade.exam1) >= 60 &&
                          parseInt(grade.exam1) <= 79
                        "
                        >{{ grade.exam1 }}</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-else-if="
                          parseInt(grade.exam1) >= 0 &&
                          parseInt(grade.exam1) <= 59
                        "
                        >{{ grade.exam1 }}</b-badge
                      >
                      <b-badge variant="danger" v-else-if="grade.exam1 == null"
                        >0</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="
                          parseInt(grade.exam2) >= 80 &&
                          parseInt(grade.exam2) <= 100
                        "
                        >{{ grade.exam2 }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="
                          parseInt(grade.exam2) >= 60 &&
                          parseInt(grade.exam2) <= 79
                        "
                        >{{ grade.exam2 }}</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-else-if="
                          parseInt(grade.exam2) >= 0 &&
                          parseInt(grade.exam2) <= 59
                        "
                        >{{ grade.exam2 }}</b-badge
                      ><b-badge variant="danger" v-else-if="grade.exam2 == null"
                        >0</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="
                          parseInt(grade.exam3) >= 80 &&
                          parseInt(grade.exam3) <= 100
                        "
                        >{{ grade.exam3 }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="
                          parseInt(grade.exam3) >= 60 &&
                          parseInt(grade.exam3) <= 79
                        "
                        >{{ grade.exam3 }}</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-else-if="
                          parseInt(grade.exam3) >= 0 &&
                          parseInt(grade.exam3) <= 59
                        "
                        >{{ grade.exam3 }}</b-badge
                      ><b-badge variant="danger" v-else-if="grade.exam3 == null"
                        >0</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="
                          parseInt(grade.exam4) >= 80 &&
                          parseInt(grade.exam4) <= 100
                        "
                        >{{ grade.exam4 }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="
                          parseInt(grade.exam4) >= 60 &&
                          parseInt(grade.exam4) <= 79
                        "
                        >{{ grade.exam4 }}</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-else-if="
                          parseInt(grade.exam4) >= 0 &&
                          parseInt(grade.exam4) <= 59
                        "
                        >{{ grade.exam4 }}</b-badge
                      ><b-badge variant="danger" v-else-if="grade.exam4 == null"
                        >0</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="
                          parseInt(grade.exam5) >= 80 &&
                          parseInt(grade.exam5) <= 100
                        "
                        >{{ grade.exam5 }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-else-if="
                          parseInt(grade.exam5) >= 60 &&
                          parseInt(grade.exam5) <= 79
                        "
                        >{{ grade.exam5 }}</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-else-if="
                          parseInt(grade.exam5) >= 0 &&
                          parseInt(grade.exam5) <= 59
                        "
                        >{{ grade.exam5 }}</b-badge
                      ><b-badge variant="danger" v-else-if="grade.exam5 == null"
                        >0</b-badge
                      >
                    </td>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(grade.avg.avgStud) >= 80 &&
                        parseInt(grade.avg.avgStud) <= 100
                          ? `bg-success`
                          : parseInt(grade.avg.avgStud) >= 60 &&
                            parseInt(grade.avg.avgStud) <= 79
                          ? `bg-warning`
                          : parseInt(grade.avg.avgStud) >= 0 &&
                            parseInt(grade.avg.avgStud) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="grade.avg.avgStud"
                        >{{ grade.avg.avgStud }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="4">Судлагдахууны дундаж</th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.examAvg1) >= 80 &&
                        parseInt(examAvg.examAvg1) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.examAvg1) >= 60 &&
                            parseInt(examAvg.examAvg1) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.examAvg1) >= 0 &&
                            parseInt(examAvg.examAvg1) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.examAvg1"
                        >{{ examAvg.examAvg1 }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.examAvg2) >= 80 &&
                        parseInt(examAvg.examAvg2) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.examAvg2) >= 60 &&
                            parseInt(examAvg.examAvg2) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.examAvg2) >= 0 &&
                            parseInt(examAvg.examAvg2) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.examAvg2"
                        >{{ examAvg.examAvg2 }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.examAvg3) >= 80 &&
                        parseInt(examAvg.examAvg3) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.examAvg3) >= 60 &&
                            parseInt(examAvg.examAvg3) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.examAvg3) >= 0 &&
                            parseInt(examAvg.examAvg3) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.examAvg3"
                        >{{ examAvg.examAvg3 }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.examAvg4) >= 80 &&
                        parseInt(examAvg.examAvg4) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.examAvg4) >= 60 &&
                            parseInt(examAvg.examAvg4) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.examAvg4) >= 0 &&
                            parseInt(examAvg.examAvg4) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.examAvg4"
                        >{{ examAvg.examAvg4 }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.examAvg5) >= 80 &&
                        parseInt(examAvg.examAvg5) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.examAvg5) >= 60 &&
                            parseInt(examAvg.examAvg5) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.examAvg5) >= 0 &&
                            parseInt(examAvg.examAvg5) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.examAvg5"
                        >{{ examAvg.examAvg5 }}
                      </span>
                      <span v-else>0 </span>
                    </th>
                    <th
                      :class="[
                        'dtr-control sorting_1',
                        parseInt(examAvg.allAvg) >= 80 &&
                        parseInt(examAvg.allAvg) <= 100
                          ? `bg-success`
                          : parseInt(examAvg.allAvg) >= 60 &&
                            parseInt(examAvg.allAvg) <= 79
                          ? `bg-warning`
                          : parseInt(examAvg.allAvg) >= 0 &&
                            parseInt(examAvg.allAvg) <= 59
                          ? `bg-danger`
                          : `bg-danger`,
                      ]"
                      tabindex="0"
                    >
                      <span v-if="examAvg.allAvg">{{ examAvg.allAvg }} </span>
                      <span v-else>0 </span>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div class="row" v-if="studStatCreate === 'Элсэлт'">
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.studyClass"
                    :options="groupedStudyClasses"
                    track-by="study_class"
                    label="study_class"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Анги сонгох"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    :custom-label="customNameClass"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.study_class }}-р анги</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="lessonName"
                    :options="lessons"
                    track-by="name"
                    label="name"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээл сонгох"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.name }} хичээл</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.s_p_owner_id"
                    :options="allTeachers"
                    track-by="emp_user_id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Багш сонгох"
                    :allow-empty="false"
                    :custom-label="customNameTeacher"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.emp_teacher_last_name[0] }}.{{
                          option.emp_teacher_name
                        }}Багш</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.studentIds"
                    :options="
                      newStudExam.filter(
                        (i) =>
                          i.study_class ==
                          formSpecialStud.studyClass.study_class
                      )
                    "
                    track-by="studId"
                    label="pro_student_name"
                    :searchable="true"
                    :show-labels="true"
                    placeholder="Суралцагч сонгох"
                    :allow-empty="false"
                    :close-on-select="false"
                    :multiple="true"
                    deselect-label="Can't remove this value"
                    :custom-label="customNameStudent0"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.lastName }}
                        {{ option.studName }}({{ option.study_class }}-р
                        анги)</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
              </div>
              <div class="row" v-if="studStatCreate === 'Хоцрогдол'">
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.studyClass"
                    :options="schoolClassNum"
                    track-by="class_number"
                    label="class_number"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Суралцагч сонгох"
                    :allow-empty="false"
                    :custom-label="customClassName"
                    deselect-label="Can't remove this value"
                    @input="getSpecialPlanLessons()"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.class_number }}-р анги</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="lessonName"
                    :options="specialPlanLessons"
                    track-by="subject_name"
                    label="subject_name"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээл сонгох"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.subject_name }} хичээл</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.s_p_owner_id"
                    :options="allTeachers"
                    track-by="emp_user_id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Багш сонгох"
                    :allow-empty="false"
                    :custom-label="customNameTeacher"
                    @input="getSpecialPlanStudents()"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.emp_teacher_last_name[0] }}.{{
                          option.emp_teacher_name
                        }}
                        Багш</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
                <div class="form-group col-lg-3">
                  <Multiselect
                    v-model="formSpecialStud.studentIds"
                    :options="specialPlanStudents"
                    track-by="user_id"
                    label="pro_student_name"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    placeholder="Суралцагч сонгох"
                    :multiple="true"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    :custom-label="customNameStudent2"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.pro_student_last_name }}
                        {{ option.pro_student_name }}({{
                          option.school_class_full_name
                        }}-р анги)</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </Multiselect>
                </div>
              </div>
              <Multiselect
                v-show="selected === 'student'"
                v-if="studStatCreate === 'Хүмүүжил'"
                v-model="formSpecial.s_p_student_id"
                :options="allStudents"
                track-by="pro_user_id"
                label="pro_student_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Суралцагч сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameStudent2"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.pro_student_last_name }}
                    {{ option.pro_student_name }}({{
                      option.school_class_full_name
                    }}-р анги)</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </Multiselect>
              <Multiselect
                v-show="selected === 'schoolclass'"
                v-model="formSpecial.s_p_school_class_id"
                :options="allSchoolClasses"
                track-by="id"
                label="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Анги сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameSchoolClass"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.full_name }} анги</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </Multiselect>
              <Multiselect
                v-show="selected === 'teacher'"
                v-model="formSpecial.s_p_employee_id"
                :options="allTeachers"
                track-by="emp_teacher_name"
                label="emp_teacher_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Багш сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameTeacher"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.emp_teacher_last_name }}
                    {{ option.emp_teacher_name }}({{
                      option.dep_department_code
                    }})</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </Multiselect>
              <Multiselect
                v-show="selected === 'parent'"
                v-model="formSpecial.s_p_parent_id"
                :options="allParents"
                track-by="parent_father_name"
                label="parent_father_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Эцэг эх сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameParent"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>
                    Аав:
                    {{ option.parent_father_ovog }}
                    {{ option.parent_father_name }}

                    Анги: {{ option.school_class_full_name }}: Суралцагч:
                    {{ option.student_last_name }}
                    {{ option.student_name }} сонгогдлоо</strong
                  ></template
                >
              </Multiselect>
            </div>
            <div class="form-group">
              <label>Төлөвлөж буй үйл ажиллагааны нэр</label>
              <input
                type="text"
                name="name"
                max="100"
                v-model="formSpecial.s_p_name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formSpecial.errors.has('s_p_name'),
                  },
                ]"
                placeholder="Төлөвлөж буй үйл ажиллагааны нэр"
              />
              <has-error :form="formSpecial" field="s_p_name"></has-error>
            </div>
            <div class="form-group">
              <label>Зорилго</label>
              <b-form-textarea
                id="textarea"
                v-model="formSpecial.s_p_objective"
                placeholder="Зорилго..."
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formSpecial.errors.has('s_p_objective'),
                  },
                ]"
              ></b-form-textarea>
              <has-error :form="formSpecial" field="s_p_objective"></has-error>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Эхлэх огноо</label>
                <b-form-datepicker
                  id="start-datepicker"
                  :state="true"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="formSpecial.s_p_start_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formSpecial.errors.has('s_p_start_date'),
                    },
                  ]"
                  placeholder="Эхлэх огноо"
                ></b-form-datepicker>
                <has-error
                  :form="formSpecial"
                  field="s_p_start_date"
                ></has-error>
              </div>
              <div class="form-group col-lg-6">
                <label>Дуусах огноо</label>
                <b-form-datepicker
                  id="end-datepicker"
                  :state="false"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="formSpecial.s_p_finish_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formSpecial.errors.has('s_p_finish_date'),
                    },
                  ]"
                  placeholder="Дуусах огноо"
                ></b-form-datepicker>
                <has-error
                  :form="formSpecial"
                  field="s_p_finish_date"
                ></has-error>
              </div>
            </div>
            <div class="form-group" v-show="editModeSP">
              <label>Төлөвлөгдсөн үйл ажиллагаанд өгөх тайлан, дүгнэлт</label>
              <b-form-textarea
                id="textarea"
                v-model="formSpecial.s_p_conclusion"
                placeholder="Төлөвлөгдсөн үйл ажиллагаанд өгөх тайлан, дүгнэлт"
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formSpecial.errors.has('s_p_conclusion'),
                  },
                ]"
              ></b-form-textarea>
              <has-error :form="formSpecial" field="s_p_conclusion"></has-error>
            </div>
            <div class="form-group" v-show="editModeSP">
              <label>Төлөвлөсөн ажлыг хаах эсэх</label>
              <b-col lg="3">
                <b-form-radio
                  v-model="formSpecial.s_p_done"
                  name="some-parent"
                  value="1"
                  >Төлөвлөсөн ажлыг хаах эсэх</b-form-radio
                >
              </b-col>
              <has-error :form="formSpecial" field="s_p_done"></has-error>
            </div>
          </form>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <span v-show="!editModeSP">
            <button
              type="submit"
              class="btn btn-primary"
              v-if="
                studStatCreate === 'Элсэлт' || studStatCreate === 'Хоцрогдол'
              "
              @click.prevent="createSpecialPlanStud()"
            >
              Хадгалах
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              v-else
              @click.prevent="createSpecialPlan()"
            >
              Хадгалах
            </button>
          </span>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editModeSP"
            @click.prevent="updateSpecialPlan()"
          >
            Засах
          </button>
          <button class="btn btn-secondary" @click.prevent="hideSP">
            Болих
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Багш ажилтан тусгай төлөвлөгөөн дээр үйл ажиллагаа нэмэх функц -->
    <b-modal
      v-show="showModeSpecialPlan"
      size="xl"
      id="modal-show-special-plan-action"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="
        formSpecial.s_p_name
          ? formSpecial.s_p_name
          : 'Төлөвлөгөө бүртгэгдээгүй байна.'
      "
      class="modal fade"
      hide-footer
    >
      <!-- @show="resetSP" -->
      <!-- @ok="handleOk" -->
      <div class="modal-body">
        <div class="direct-chat-msg" v-if="formSpecial.s_p_employee_id">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ formSpecial.s_p_employee_id.teacher_last_name }}.
              {{ formSpecial.s_p_employee_id.teacher_name | uppercase }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ formSpecial.s_p_start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ formSpecial.s_p_finish_date | dateYearMonthDay }}
            </span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            :src="
              formSpecial.s_p_employee_id.profile_photo_url
                ? formSpecial.s_p_employee_id.profile_photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>ТӨЛӨВЛӨГӨӨНИЙ НЭР:</b><br />
              {{ formSpecial.s_p_name }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ЗОРИЛГО:</b><br />
              {{ formSpecial.s_p_objective }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ДУУССАН ЭСЭХ:</b>
              <span v-if="formSpecial.s_p_done == 1" class="float-right"
                >Тийм</span
              >
              <span v-if="formSpecial.s_p_done == 0" class="float-right"
                >Үгүй</span
              ><br />
              {{ formSpecial.s_p_a_success_point }}
              <b>ДҮГНЭЛТ:</b><br />
              {{ formSpecial.s_p_conclusion }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="callout callout-success" v-if="formSpecial.point > 0">
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>{{ formSpecial.point_explain }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төлөвлөгдсөн үйл ажиллагаанууд</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ formSpecial.point }}%
                </span>
              </div>
              <div
                class="card-body table-responsive"
                v-if="allspecialPlanActionsOfTeachers"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>ҮА-ны нэр</th>
                      <th>Огноо</th>
                      <th>Хариуцах эзэн</th>
                      <th>Үргэлжилсэн хугацаа</th>
                      <th>Гүйцэтгэлийн үнэлгээ</th>
                      <th>Тайлбар</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(action, index) in allspecialPlanActionsOfTeachers"
                    :key="action.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span class="direct-chat-timestamp float-right"
                          >{{ action.s_p_a_start_date | dateYearMonthDay }}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          {{ action.s_p_a_finish_date | dateYearMonthDay }}
                        </span>
                      </td>
                      <td>
                        <ul class="list-inline">
                          <li
                            class="list-inline-item"
                            v-for="teacher in action.my_special_plan_action_users"
                            :key="teacher.id"
                          >
                            <img
                              @click="showImage(teacher)"
                              v-if="
                                teacher.special_plan_action_user_info != null
                              "
                              alt="Avatar"
                              class="direct-chat-img"
                              :src="
                                teacher.special_plan_action_user_info
                                  .profile_photo_url
                                  ? teacher.special_plan_action_user_info
                                      .profile_photo_url
                                  : '/images/users/user.png'
                              "
                              v-b-tooltip.hover.top.html="
                                '</dd><dt>Овог Нэр</dt><dd>' +
                                teacher.special_plan_action_user_info
                                  .teacher_last_name +
                                ' <span class=text-uppercase>' +
                                teacher.special_plan_action_user_info
                                  .teacher_name +
                                '</span></dd></dl>'
                              "
                            />
                            <i
                              class="fas fa-user-times"
                              @click="removeTeacher(teacher)"
                            ></i>
                          </li>
                        </ul>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_during_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_point }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_explain }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSpecialPlanAction(action)"
                        >
                          <i class="far fa-edit"></i>
                        </a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSpecialPlanAction(action)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editModeSPA">
                  Үйл ажиллагаа нэмэх
                </h3>
                <h3 class="card-title" v-show="editModeSPA">
                  Үйл ажиллагаа Засах
                </h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label>Үйл ажиллагаа</label>
                  <b-form-input
                    v-model="formSpecialPlanAction.s_p_a_name"
                    placeholder="Үйл ажиллагааны нэр"
                  >
                  </b-form-input>
                  <has-error
                    :form="formSpecialPlanAction"
                    field="s_p_a_name"
                  ></has-error>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label>Эхлэх огноо</label>
                    <b-form-datepicker
                      id="start-datepicker"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_start_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_start_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_start_date"
                    ></has-error>
                  </div>
                  <div class="form-group col-lg-6">
                    <label>Дуусах огноо</label>
                    <b-form-datepicker
                      id="end-datepicker"
                      :state="false"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_finish_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_finish_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_finish_date"
                    ></has-error>
                  </div>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_during_time"
                    placeholder="Үргэлжилсэн хугацаа(мин)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_success_point"
                    placeholder="Гүйцэтгэлийн оноо(0-100)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-textarea
                    id="textarea"
                    v-model="formSpecialPlanAction.s_p_a_success_explain"
                    placeholder="Гүйцэтгэлийн тайлбар"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formSpecialPlanAction.s_p_a_owner_id"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    >
                    </b-form-select>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editModeSPA"
                  @click.prevent="createSpecialPlanAction()"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="editModeSPA"
                  @click.prevent="updateSpecialPlanAction()"
                >
                  Засах
                </button>
                <b-button
                  @click="cancelSpecialPlanAction()"
                  variant="danger"
                  type="reset"
                >
                  Форм цэвэрлэх
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between"></div>
    </b-modal>

    <!-- Суралцагчийн тусгай төлөвлөгөөн дээр үйл ажиллагаа нэмэх функц -->
    <b-modal
      v-show="showModeSpecialPlanStudent"
      size="xl"
      id="modal-show-special-plan-action-student"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="
        formSpecial.s_p_name
          ? formSpecial.s_p_name
          : 'Төлөвлөгөө бүртгэгдээгүй байна.'
      "
      class="modal fade"
      hide-footer
      modal-header-close
    >
      <!-- @hidden="resetSP" -->
      <!-- @show="resetSP" -->
      <!-- @ok="handleOk" -->
      <div class="modal-body">
        <div class="direct-chat-msg" v-if="formSpecial.s_p_student_id">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ formSpecial.s_p_student_id.student_last_name }}.
              {{ formSpecial.s_p_student_id.student_name | uppercase }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ formSpecial.s_p_start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ formSpecial.s_p_finish_date | dateYearMonthDay }}
            </span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            :src="
              formSpecial.s_p_student_id.student_profile_photo_url
                ? formSpecial.s_p_student_id.student_profile_photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>ТӨЛӨВЛӨГӨӨНИЙ НЭР:</b><br />
              {{ formSpecial.s_p_name }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ЗОРИЛГО:</b><br />
              {{ formSpecial.s_p_objective }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ДУУССАН ЭСЭХ:</b>
              <span v-if="formSpecial.s_p_done == 1" class="float-right"
                >Тийм</span
              >
              <span v-if="formSpecial.s_p_done == 0" class="float-right"
                >Үгүй</span
              ><br />
              {{ formSpecial.s_p_a_success_point }}
              <b>ДҮГНЭЛТ:</b><br />
              {{ formSpecial.s_p_conclusion }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="callout callout-success" v-if="formSpecial.point > 0">
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>{{ formSpecial.point_explain }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төлөвлөгдсөн үйл ажиллагаанууд</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ formSpecial.point }}%
                </span>
              </div>
              <div
                class="card-body table-responsive"
                v-if="allspecialPlanActionsOfStudents"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>ҮА-ны нэр</th>
                      <th>Огноо</th>
                      <th>Хариуцах эзэн</th>
                      <th>Үргэлжилсэн хугацаа</th>
                      <th>Гүйцэтгэлийн үнэлгээ</th>
                      <th>Тайлбар</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(action, index) in allspecialPlanActionsOfStudents"
                    :key="action.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span class="direct-chat-timestamp float-right"
                          >{{ action.s_p_a_start_date | dateYearMonthDay }}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          {{ action.s_p_a_finish_date | dateYearMonthDay }}
                        </span>
                      </td>
                      <td>
                        <ul class="list-inline">
                          <li
                            class="list-inline-item"
                            v-for="teacher in action.my_special_plan_action_users"
                            :key="teacher.id"
                          >
                            <img
                              @click="showImage(teacher)"
                              v-if="
                                teacher.special_plan_action_user_info != null
                              "
                              alt="Avatar"
                              class="direct-chat-img"
                              :src="
                                teacher.special_plan_action_user_info
                                  .profile_photo_url
                                  ? teacher.special_plan_action_user_info
                                      .profile_photo_url
                                  : '/images/users/user.png'
                              "
                              v-b-tooltip.hover.top.html="
                                '</dd><dt>Овог Нэр</dt><dd>' +
                                teacher.special_plan_action_user_info
                                  .teacher_last_name +
                                ' <span class=text-uppercase>' +
                                teacher.special_plan_action_user_info
                                  .teacher_name +
                                '</span></dd></dl>'
                              "
                            />
                            <i
                              class="fas fa-user-times"
                              @click="removeTeacherOfStudent(teacher)"
                            ></i>
                          </li>
                        </ul>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_during_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_point }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_explain }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-success"
                          v-if="action.my_special_plan_topics.length > 0"
                        >
                          <show-plan-calendar
                            :cp_id="action.my_special_plan_topics[0].cp_id"
                            :topics="action.my_special_plan_topics"
                            :index="index"
                          ></show-plan-calendar>
                        </a>
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSpecialPlanActionStudent(action)"
                        >
                          <i class="far fa-edit"></i>
                        </a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSpecialPlanActionStudent(action)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editModeSPAStudent">
                  Үйл ажиллагаа нэмэх
                </h3>
                <h3 class="card-title" v-show="editModeSPAStudent">
                  Үйл ажиллагаа Засах
                </h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label>Үйл ажиллагаа</label>
                  <b-form-input
                    v-model="formSpecialPlanAction.s_p_a_name"
                    placeholder="Үйл ажиллагааны нэр"
                  >
                  </b-form-input>
                  <has-error
                    :form="formSpecialPlanAction"
                    field="s_p_a_name"
                  ></has-error>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label>Эхлэх огноо</label>
                    <b-form-datepicker
                      id="start-datepicker"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_start_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_start_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_start_date"
                    ></has-error>
                  </div>
                  <div class="form-group col-lg-6">
                    <label>Дуусах огноо</label>
                    <b-form-datepicker
                      id="end-datepicker"
                      :state="false"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_finish_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_finish_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_finish_date"
                    ></has-error>
                  </div>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_during_time"
                    placeholder="Үргэлжилсэн хугацаа(мин)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_success_point"
                    placeholder="Гүйцэтгэлийн оноо(0-100)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-textarea
                    id="textarea"
                    v-model="formSpecialPlanAction.s_p_a_success_explain"
                    placeholder="Гүйцэтгэлийн тайлбар"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formSpecialPlanAction.s_p_a_owner_id"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    >
                    </b-form-select>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editModeSPAStudent"
                  @click.prevent="createSpecialPlanActionStudent()"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="editModeSPAStudent"
                  @click.prevent="updateSpecialPlanActionStudent()"
                >
                  Засах
                </button>
                <b-button
                  @click="cancelSpecialPlanActionStudent()"
                  variant="danger"
                  type="reset"
                >
                  Форм цэвэрлэх
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button class="mt-3" block @click="hideSpecialPlanForActionsStudent()"
          >Хаах</b-button
        >
      </div>
    </b-modal>

    <!-- Ангийн тусгай төлөвлөгөөн дээр үйл ажиллагаа нэмэх функц -->
    <b-modal
      v-show="showModeSpecialPlanSchoolClass"
      size="xl"
      id="modal-show-special-plan-action-school-class"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="
        formSpecial.s_p_name
          ? formSpecial.s_p_name
          : 'Төлөвлөгөө бүртгэгдээгүй байна.'
      "
      class="modal fade"
      hide-footer
      modal-header-close
    >
      <!-- @hidden="resetSP" -->
      <!-- @show="resetSP" -->
      <!-- @ok="handleOk" -->
      <div class="modal-body">
        <div
          class="direct-chat-msg"
          v-if="formSpecial.school_class_special_plan"
        >
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ formSpecial.school_class_special_plan.full_name }}
              анги</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ formSpecial.s_p_start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ formSpecial.s_p_finish_date | dateYearMonthDay }}
            </span>
          </div>
          <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>ТӨЛӨВЛӨГӨӨНИЙ НЭР:</b><br />
              {{ formSpecial.s_p_name }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ЗОРИЛГО:</b><br />
              {{ formSpecial.s_p_objective }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ДУУССАН ЭСЭХ:</b>
              <span v-if="formSpecial.s_p_done == 1" class="float-right"
                >Тийм</span
              >
              <span v-if="formSpecial.s_p_done == 0" class="float-right"
                >Үгүй</span
              ><br />
              {{ formSpecial.s_p_a_success_point }}
              <b>ДҮГНЭЛТ:</b><br />
              {{ formSpecial.s_p_conclusion }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="callout callout-success" v-if="formSpecial.point > 0">
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>{{ formSpecial.point_explain }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төлөвлөгдсөн үйл ажиллагаанууд</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ formSpecial.point }}%
                </span>
              </div>
              <div
                class="card-body table-responsive"
                v-if="allspecialPlanActionsOfSchoolClass"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>ҮА-ны нэр</th>
                      <th>Огноо</th>
                      <th>Хариуцах эзэн</th>
                      <th>Үргэлжилсэн хугацаа</th>
                      <th>Гүйцэтгэлийн үнэлгээ</th>
                      <th>Тайлбар</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(
                      action, index
                    ) in allspecialPlanActionsOfSchoolClass"
                    :key="action.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span class="direct-chat-timestamp float-right"
                          >{{ action.s_p_a_start_date | dateYearMonthDay }}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          {{ action.s_p_a_finish_date | dateYearMonthDay }}
                        </span>
                      </td>
                      <td>
                        <ul class="list-inline">
                          <li
                            class="list-inline-item"
                            v-for="teacher in action.my_special_plan_action_users"
                            :key="teacher.id"
                          >
                            <img
                              @click="showImage(teacher)"
                              v-if="
                                teacher.special_plan_action_user_info != null
                              "
                              alt="Avatar"
                              class="direct-chat-img"
                              :src="
                                teacher.special_plan_action_user_info
                                  .profile_photo_url
                                  ? teacher.special_plan_action_user_info
                                      .profile_photo_url
                                  : '/images/users/user.png'
                              "
                              v-b-tooltip.hover.top.html="
                                '</dd><dt>Овог Нэр</dt><dd>' +
                                teacher.special_plan_action_user_info
                                  .teacher_last_name +
                                ' <span class=text-uppercase>' +
                                teacher.special_plan_action_user_info
                                  .teacher_name +
                                '</span></dd></dl>'
                              "
                            />
                            <i
                              class="fas fa-user-times"
                              @click="removeTeacherOfSchoolClass(teacher)"
                            ></i>
                          </li>
                        </ul>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_during_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_point }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_explain }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSpecialPlanActionSchoolClass(action)"
                        >
                          <i class="far fa-edit"></i>
                        </a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSpecialPlanActionSchoolClass(action)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editModeSPAStudent">
                  Үйл ажиллагаа нэмэх
                </h3>
                <h3 class="card-title" v-show="editModeSPAStudent">
                  Үйл ажиллагаа Засах
                </h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label>Үйл ажиллагаа</label>
                  <b-form-input
                    v-model="formSpecialPlanAction.s_p_a_name"
                    placeholder="Үйл ажиллагааны нэр"
                  >
                  </b-form-input>
                  <has-error
                    :form="formSpecialPlanAction"
                    field="s_p_a_name"
                  ></has-error>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label>Эхлэх огноо</label>
                    <b-form-datepicker
                      id="start-datepicker"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_start_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_start_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_start_date"
                    ></has-error>
                  </div>
                  <div class="form-group col-lg-6">
                    <label>Дуусах огноо</label>
                    <b-form-datepicker
                      id="end-datepicker"
                      :state="false"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_finish_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_finish_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_finish_date"
                    ></has-error>
                  </div>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_during_time"
                    placeholder="Үргэлжилсэн хугацаа(мин)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_success_point"
                    placeholder="Гүйцэтгэлийн оноо(0-100)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-textarea
                    id="textarea"
                    v-model="formSpecialPlanAction.s_p_a_success_explain"
                    placeholder="Гүйцэтгэлийн тайлбар"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formSpecialPlanAction.s_p_a_owner_id"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    >
                    </b-form-select>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editModeSPASchoolClass"
                  @click.prevent="createSpecialPlanActionSchoolClass()"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="editModeSPASchoolClass"
                  @click.prevent="updateSpecialPlanActionSchoolClass()"
                >
                  Засах
                </button>
                <b-button
                  @click="cancelSpecialPlanActionSchoolClass()"
                  variant="danger"
                  type="reset"
                >
                  Форм цэвэрлэх
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button
          class="mt-3"
          block
          @click="hideSpecialPlanForActionsSchoolClass()"
          >Хаах</b-button
        >
      </div>
    </b-modal>
    <!-- Төгсөгл -->
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import showPlanCalendar from "../../Plan/PlanCalendarShow.vue";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",
      studStat: "Хүмүүжил",
      studStatClass: "Хүмүүжил",
      studStatCreate: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",
      lessonName: "",
      newStudExam: [],
      examAvg: [],
      headerBgVariant: "light",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
      allMonthPlans: [],
      monthPlanEmps: [],
      departments: [
        { value: null, department_name: "Please select an option" },
      ],
      leaderTeachers: [],
      myDepId: "",
      myEmpId: "",
      teachers: [],
      showMode: false,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,
      filterForm: new Form({
        department_id: 0,
      }),
      filterLeaderForm: new Form({
        department_id: 0,
      }),
      //   showMonthPlanInfo: new Form({
      //     monthPlanId: "",
      //     owner_id: "",
      //     name: "",
      //     description: "",
      //     start_date: "",
      //     end_date: "",
      //     analysis: "",
      //     indicator: "",
      //     point: "",
      //     point_explain: "",
      //     recieved_employees: [],
      //     owner: [],
      //   }),
      editMode: false,
      //   form: new Form({
      //     monthPlanId: "",
      //     owner_id: "",
      //     leader_id: "",
      //     name: "",
      //     description: "",
      //     start_date: "",
      //     end_date: "",
      //     analysis: "",
      //     indicator: "",
      //     point: "",
      //     point_explain: "",
      //     recieved_employees: [],
      //   }),
      modalTeachers: [],
      formAddTeacher: new Form({
        monthPlanId: null,
        department_id: 0,
        recieved_employees: [],
      }),
      showEmployee: new Form({
        department: "",
        employee: "",
        position: "",
      }),

      // Тусгай төлөвлөлт эхэлж байна.
      editModeSP: false,
      formSpecial: new Form({
        id: "",
        year_id: "",
        school_id: "",
        s_p_owner_id: "",
        s_p_name: "",
        s_p_objective: "",
        s_p_start_date: "",
        s_p_finish_date: "",
        s_p_employee_id: "",
        s_p_school_class_id: "",
        s_p_student_id: "",
        s_p_parent_id: "",
        s_p_done: null,
        s_p_conclusion: "",
      }),
      formSpecialStud: new Form({
        id: "",
        year_id: "",
        school_id: "",
        s_p_owner_id: "",
        studyClass: "",
        studentIds: [],
        lessonName: "",
        s_p_done: null,
        s_p_conclusion: "",
      }),
      showModeSpecialPlan: false,

      selected: "",
      allTeachers: [],
      specialPlanTeachers: [],
      specialPlanStudents: [],
      specialPlanLessons: [],
      allEmployees: [],
      allStudents: [],
      allParents: [],
      allSchoolClasses: [],
      schoolClassNum: [],
      allspecialPlanOfTeachers: [],
      allspecialPlanActionsOfTeachers: [],
      allspecialPlanOfStudents: [],
      specialPlanOfStudents: [],
      specialPlanOfCouncelor: [],
      allspecialPlanActionsOfStudents: [],
      allspecialPlanOfSchoolClass: [],
      allspecialPlanOfSchoolClassCouncelor: [],
      allspecialPlanActionsOfSchoolClass: [],

      // Тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх форм
      editModeSPA: false,
      formSpecialPlanAction: new Form({
        id: "",
        year_id: "",
        school_id: "",
        s_p_id: "",
        s_p_a_name: "",
        cp_id: "",
        topic_id: [],
        s_p_a_start_date: "",
        s_p_a_finish_date: "",
        s_p_a_during_time: 0,
        s_p_a_success_point: 0,
        s_p_a_success_explain: "",
        s_p_a_owner_id: [],
      }),
      check_s_p_id: "",
      //Суралцагчийн тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх
      showModeSpecialPlanStudent: false,
      editModeSPAStudent: false,
      //Суралцагчийн тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх
      showModeSpecialPlanSchoolClass: false,
      editModeSPASchoolClass: false,
      groupedStudyClasses: [],
      lessons: [
        { name: "Монгол хэл" },
        { name: "Мат(мон/цөм)" },
        { name: "Mathematics(mon/camb)" },
        { name: "English (mon/camb)" },
        { name: "Science(mon/camb)" },
      ],
    };
  },
  components: {
    Multiselect,
    showPlanCalendar: showPlanCalendar,
  },
  methods: {
    averageCheck(allMonthPlans) {
      this.niit = 0;
      this.unelgeeList = [];
      this.unelgeeSum = "";
      this.unelgeeAvg = "";

      this.unelgee0List = [];
      this.unelgee0Sum = "";
      this.unelgee0Avg = "";

      allMonthPlans.forEach((element) => {
        if (element.point == null) this.unelgeeList.push(0);
        else this.unelgeeList.push(parseInt(element.point));
      });

      this.unelgeeSum = this.unelgeeList.reduce((a, b) => a + b, 0);
      this.unelgeeAvg = (this.unelgeeSum / this.unelgeeList.length).toFixed();
      this.niit = this.unelgeeList.length;

      //together zero
      this.unelgee0List = this.unelgeeList.filter((el) => el !== 0);
      this.unelgee0Sum = this.unelgee0List.reduce((a, b) => a + b, 0);
      this.unelgee0Avg = (
        this.unelgee0Sum / this.unelgee0List.length
      ).toFixed();
    },
    // changeTeacherList() {
    //   this.action = "Багш нарын мэдээллийг шинэчилж байна...";
    //   this.filterForm
    //     .get(
    //       "/month_plan_manager/getTeacherList/" + this.filterForm.department_id
    //     )
    //     .then((response) => {
    //       this.teachers = response.data.teachers;
    //     })
    //     .catch((errors) => {
    //       //   console.log(errors);
    //     });
    // },
    changeOwnerTeacherList() {
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.filterLeaderForm
        .get("/psycho/getTeacherList/" + this.filterLeaderForm.department_id)
        .then((response) => {
          this.leaderTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    specialPlanStudCreate(value) {
      this.studStatCreate = value;
      if (this.studStatCreate == "Хүмүүжил") {
        this.getAllSpecialDatas();
      } else {
        this.getStudEnrollStatSpecial();
      }
    },
    specialPlanStud(value) {
      this.studStat = value;
      if (this.studStat == "Элсэлт" || "Хоцрогдол") {
        this.getSpecialStudStat();
      }
    },
    specialPlanClass(value) {
      this.studStatClass = value;
      if (this.studStatClass == "Ахлах боловсрол") {
        this.getSpecialStudStat();
      }
    },
    getSpecialPlanLessons() {
      axios
        .post("/manager/getSpecialPlanLessons", {
          classNum: this.formSpecialStud.studyClass,
        })
        .then((response) => {
          this.specialPlanLessons = response.data.lessons;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSpecialPlanStudents() {
      axios
        .post("/manager/getSpecialPlanStudents", {
          classNum: this.formSpecialStud.studyClass,
        })
        .then((response) => {
          this.specialPlanStudents = response.data.students;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      // console.log(e);
      this.formAddTeacher.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formAddTeacher
        .get("/psycho/getTeacherList/" + this.formAddTeacher.department_id)
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getAllDepInfo() {
      this.show = true;
      axios
        .get("/psycho/getAllDepInfo")
        .then((response) => {
          this.filterForm.department_id = 0;
          //   this.allMonthPlans = response.data.allMonthPlans;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.teachers = response.data.teachers;
          this.leaderTeachers = this.teachers;
          this.myDepId = response.data.dep_id;
          this.filterLeaderForm.department_id = this.myDepId;
          this.myEmpId = response.data.leader_id;
          this.changeOwnerTeacherList();
          //   this.form.leader_id = this.myEmpId;
          //   this.averageCheck(this.allMonthPlans);
          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },
    //Тусгай төлөвлөлт эхэллээ.

    getAllSpecialDatas() {
      axios
        .get("/psycho/getAllSpecialDatas")
        .then((res) => {
          //   console.log(res.data);
          this.allTeachers = res.data.teachers;
          this.allEmployees = res.data.employees;
          this.allStudents = res.data.students;
          this.allParents = res.data.parents;
          this.allSchoolClasses = res.data.schoolClasses;
          this.schoolClassNum = res.data.classNum;
          this.allspecialPlanOfTeachers = res.data.allspecialPlanOfTeachers;
          this.allspecialPlanOfStudents = res.data.allspecialPlanOfStudents;
          this.allspecialPlanOfSchoolClass =
            res.data.allspecialPlanOfSchoolClass;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSpecialStudStat() {
      axios
        .post("/psycho/getSpecialStudStat", {
          studStat: this.studStat,
        })
        .then((res) => {
          //   console.log(res.data);
          this.specialPlanOfCouncelor = res.data.specialPlanOfCouncelor;
          this.specialPlanOfStudents = res.data.specialPlanOfStudents;
          this.allspecialPlanOfSchoolClassCouncelor =
            res.data.allspecialPlanOfSchoolClassCouncelor;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetSP() {
      //   console.log("specialPlan reset");
      this.editModeSP = false;
      this.formSpecial.reset();
      this.formSpecialStud.reset();
      this.lessonName = "";
      this.studStatCreate = "";
      this.selected = "";
    },
    hideSP() {
      this.studStatCreate = "";
      this.$bvModal.hide("modal-special-plan");
      this.studStatCreate = "";
      this.resetSP();
    },
    createSP() {
      this.studStatCreate = "";
      this.formSpecial.reset();
      this.$bvModal.show("modal-special-plan");
    },
    editSpecialPlan(specialPlan, select) {
      //   console.log(specialPlan, select);
      this.formSpecial.fill(specialPlan);
      //   console.log(this.formSpecial);
      this.selected = select;
      if (this.selected === "student") {
        // console.log(specialPlan.student_user_special_plan.user_id);
        this.allStudents.forEach((el) => {
          //   console.log(el.pro_user_id);
          if (
            el.pro_user_id === specialPlan.student_user_special_plan.user_id
          ) {
            this.formSpecial.s_p_student_id = el;
          }
        });
      } else if (this.selected === "schoolclass") {
        // console.log(specialPlan);
        this.allSchoolClasses.forEach((el) => {
          //   console.log(el.pro_user_id);
          if (el.id === specialPlan.s_p_school_class_id) {
            this.formSpecial.s_p_school_class_id = el;
          }
        });
      } else if (this.selected === "teacher") {
        this.allTeachers.forEach((el) => {
          if (el.user_id === specialPlan.employee_user_special_plan.user_id) {
            this.formSpecial.s_p_employee_id = el;
          }
        });
      } else if (this.selected === "parent") {
      }
      this.editModeSP = true;
      this.$bvModal.show("modal-special-plan");
    },
    updateSpecialPlan() {
      this.formSpecial
        .post("/psycho/updateSpecialPlan")
        .then((res) => {
          //   console.log(res.data);
          if (res.data.info.code === "success") {
            // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.hideSP();
          Fire.$emit("getSpecialPlans");
        })
        .catch((err) => console.log(err));
    },
    changeCheckBox(e) {
      if (e === "student") {
        this.formSpecial.s_p_employee_id = "";
        this.formSpecial.s_p_parent_id = "";
        this.formSpecial.s_p_school_class_id = "";
      } else if (e === "schoolclass") {
        this.formSpecial.s_p_employee_id = "";
        this.formSpecial.s_p_parent_id = "";
        this.formSpecial.s_p_student_id = "";
      } else if (e === "teacher") {
        this.formSpecial.s_p_student_id = "";
        this.formSpecial.s_p_parent_id = "";
        this.formSpecial.s_p_school_class_id = "";
      } else if (e === "parent") {
        this.formSpecial.s_p_employee_id = "";
        this.formSpecial.s_p_student_id = "";
        this.formSpecial.s_p_school_class_id = "";
      }
    },
    createSpecialPlan() {
      this.formSpecial
        .post("/psycho/createSpecialPlan")
        .then((res) => {
          Fire.$emit("getSpecialPlans");
          this.hideSP();
        })
        .catch((err) => console.log(err));
    },
    createSpecialPlanStud() {
      axios
        .post("/psycho/createSpecialPlanStud", {
          studentIds: this.formSpecialStud.studentIds,
          s_p_owner_id: this.formSpecialStud.s_p_owner_id,
          subj_name: this.lessonName,
          studyClass: this.formSpecialStud.studyClass,
          s_p_name: this.formSpecial.s_p_name,
          s_p_objective: this.formSpecial.s_p_objective,
          s_p_start_date: this.formSpecial.s_p_start_date,
          s_p_finish_date: this.formSpecial.s_p_finish_date,
          studStat: this.studStatCreate,
        })
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("getSpecialPlans");
          this.hideSP();
        })
        .catch((err) => console.log(err));
    },
    deleteSpecialPlan(specialPlan) {
      //   console.log(specialPlan);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагааг устгах гэж байна. Төлөвлөсөн ҮА-г хариуцаж байгаа багш нар устгагдана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteSpecialPlan/" + specialPlan.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              Fire.$emit("getSpecialPlans");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    // Тусгай төлөвлөгөө харуулах, хаах
    enableView(specialPlan) {
      //   console.log(specialPlan);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөгөөг эцэг эх, суралцагчид харуулах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, харуул!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/enableSpecialPlan/" + specialPlan.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              Fire.$emit("getSpecialPlans");
            })
            .catch((err) => {
              //   console.log(err);
            });
        }
      });
    },
    disableView(specialPlan) {
      //   console.log(specialPlan);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөгөөг эцэг эх, суралцагчаас харуулахыг болиулах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хаа!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/disableSpecialPlan/" + specialPlan.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              Fire.$emit("getSpecialPlans");
            })
            .catch((err) => {
              //   console.log(err);
            });
        }
      });
    },

    // Тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх модал
    showSpecialPlanForActions(specialPlan) {
      //   console.log(specialPlan, "special plan");
      // this.monthPlanEmps = monthPlan.month_plan_teachers;
      this.formSpecial.reset();
      this.formSpecial.fill(specialPlan);
      this.formSpecial.s_p_owner_id =
        specialPlan.en_roll_employee_user_special_plan;
      this.formSpecial.s_p_employee_id = specialPlan.employee_user_special_plan;
      this.formSpecialPlanAction.s_p_id = specialPlan.id;
      this.showModeSpecialPlan = !this.showModeSpecialPlan;
      this.modalTeachers = this.teachers;
      this.formAddTeacher.reset();
      this.getMySpecialPlansActions();
      this.$bvModal.show("modal-show-special-plan-action");
    },

    getMySpecialPlansActions() {
      axios
        .get("/psycho/getAllSpecialPlanActions/" + this.formSpecial.id)
        .then((res) => {
          //   console.log(res.data);
          this.allspecialPlanActionsOfTeachers =
            res.data.allspecialPlanActionsOfTeachers;
        })
        .catch((err) => {});
    },
    hideSpecialPlanForActions() {
      this.$bvModal.hide("modal-show-special-plan-action");
    },

    cancelSpecialPlanAction() {
      this.editModeSPA = false;
      this.formAddTeacher.department_id = 0;
      // this.formSpecialPlanAction.reset();
      this.formSpecialPlanAction.id = "";
      this.formSpecialPlanAction.year_id = "";
      this.formSpecialPlanAction.school_id = "";
      // this.formSpecialPlanAction.s_p_id = "";
      this.formSpecialPlanAction.s_p_a_name = "";
      this.formSpecialPlanAction.s_p_a_start_date = "";
      this.formSpecialPlanAction.s_p_a_finish_date = "";
      this.formSpecialPlanAction.s_p_a_during_time = 0;
      this.formSpecialPlanAction.s_p_a_success_point = 0;
      this.formSpecialPlanAction.s_p_a_success_explain = "";
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      this.getMySpecialPlansActions();
    },
    createSpecialPlanAction() {
      this.formSpecialPlanAction
        .post("/psycho/createSpecialPlanAction")
        .then((res) => {
          res.data.ar;
          if (res.data.info.code === "success") {
            // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanAction();
        })
        .catch((err) => console.log(err));
    },

    editSpecialPlanAction(action) {
      // console.log(action.my_special_plan_action_users[0]['special_plan_action_user_info']['department_id']);
      // console.log(action);
      this.formSpecialPlanAction.reset();
      this.editModeSPA = true;
      this.formSpecialPlanAction.fill(action);
      this.formAddTeacher.department_id =
        action.my_special_plan_action_users[0]["special_plan_action_user_info"][
          "department_id"
        ];
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      let convertModalTeachers = [];
      Object.entries(this.modalTeachers).forEach(([key, element]) => {
        convertModalTeachers.push(element);
      });
      // console.log(this.formSpecialPlanAction.s_p_a_owner_id);
      action.my_special_plan_action_users.forEach((el) => {
        // console.log(el.s_p_a_owner_id);
        convertModalTeachers.forEach((element) => {
          // console.log(element.emp_user_id);
          if (el.s_p_a_owner_id == element.emp_user_id) {
            this.formSpecialPlanAction.s_p_a_owner_id.push(element.emp_user_id);
          }
        });
      });
    },
    updateSpecialPlanAction() {
      this.formSpecialPlanAction
        .patch("/psycho/updateSpecialPlanAction")
        .then((res) => {
          //   console.log(res.data);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message, "Амжилттай заслаа");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanAction();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeTeacher(teacher) {
      //   console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагаанаас хариуцах багш хасах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              "/psycho/removeTeacherSpecialPlanActionUserPlan/" +
                teacher.s_p_a_owner_id,
              {
                params: { specialPlanActionId: teacher.s_p_a_id },
              }
            )
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActions();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    deleteSpecialPlanAction(action) {
      //   console.log(action);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагааг устгах гэж байна. Төлөвлөсөн ҮА-г хариуцаж байгаа багш нар устгагдана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteSpecialPlanAction/" + action.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActions();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    // Суралцагч төлөвлөгөөнд үйл ажиллагаа нэмэх модал
    showSpecialPlanForActionsStudent(specialPlan) {
      //   console.log(specialPlan.student_user_special_plan, "special plan");
      this.formSpecial.reset();
      this.formSpecial.fill(specialPlan);
      this.formSpecial.s_p_owner_id =
        specialPlan.en_roll_employee_user_special_plan;
      this.formSpecial.s_p_student_id = specialPlan.student_user_special_plan;
      this.formSpecialPlanAction.s_p_id = specialPlan.id;
      this.showModeSpecialPlanStudent = !this.showModeSpecialPlanStudent;
      this.modalTeachers = this.teachers;
      this.formAddTeacher.reset();
      this.getMySpecialPlansActionsStudent(); //allspecialPlanActionsOfStudents
      this.$bvModal.show("modal-show-special-plan-action-student");
    },
    getMySpecialPlansActionsStudent() {
      this.allspecialPlanActionsOfStudents = [];
      axios
        .get("/psycho/getAllSpecialPlanActionsStudents/" + this.formSpecial.id)
        .then((res) => {
          //   console.log(res.data);
          this.allspecialPlanActionsOfStudents =
            res.data.allspecialPlanActionsOfStudent;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideSpecialPlanForActionsStudent() {
      this.$bvModal.hide("modal-show-special-plan-action-student");
    },

    cancelSpecialPlanActionStudent() {
      this.editModeSPAStudent = false;
      this.formAddTeacher.department_id = 0;
      // this.formSpecialPlanAction.reset();
      this.formSpecialPlanAction.id = "";
      this.formSpecialPlanAction.year_id = "";
      this.formSpecialPlanAction.school_id = "";
      // this.formSpecialPlanAction.s_p_id = "";
      this.formSpecialPlanAction.s_p_a_name = "";
      this.formSpecialPlanAction.s_p_a_start_date = "";
      this.formSpecialPlanAction.s_p_a_finish_date = "";
      this.formSpecialPlanAction.s_p_a_during_time = 0;
      this.formSpecialPlanAction.s_p_a_success_point = 0;
      this.formSpecialPlanAction.s_p_a_success_explain = "";
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      this.getMySpecialPlansActionsStudent();
    },
    createSpecialPlanActionStudent() {
      this.formSpecialPlanAction
        .post("/psycho/createSpecialPlanActionStudent")
        .then((res) => {
          res.data.ar;
          if (res.data.info.code === "success") {
            // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionStudent();
        })
        .catch((err) => console.log(err));
    },

    editSpecialPlanActionStudent(action) {
      // console.log(action.my_special_plan_action_users[0]['special_plan_action_user_info']['department_id']);
      // console.log(action);
      this.formSpecialPlanAction.reset();
      this.editModeSPAStudent = true;
      this.formSpecialPlanAction.fill(action);
      this.formAddTeacher.department_id =
        action.my_special_plan_action_users[0]["special_plan_action_user_info"][
          "department_id"
        ];
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      // console.log(this.formSpecialPlanAction.s_p_a_owner_id);
      let convertModalTeachers = [];
      Object.entries(this.modalTeachers).forEach(([key, element]) => {
        convertModalTeachers.push(element);
      });
      action.my_special_plan_action_users.forEach((el) => {
        // console.log(el.s_p_a_owner_id);
        convertModalTeachers.forEach((element) => {
          // console.log(element.emp_user_id);
          if (el.s_p_a_owner_id == element.emp_user_id) {
            this.formSpecialPlanAction.s_p_a_owner_id.push(element.emp_user_id);
          }
        });
      });
    },
    updateSpecialPlanActionStudent() {
      this.formSpecialPlanAction
        .patch("/psycho/updateSpecialPlanActionStudent")
        .then((res) => {
          //   console.log(res.data);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message, "Амжилттай заслаа");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionStudent();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeTeacherOfStudent(teacher) {
      //   console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагаанаас хариуцах багш хасах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              "/psycho/removeTeacherSpecialPlanActionUserPlan/" +
                teacher.s_p_a_owner_id,
              {
                params: { specialPlanActionId: teacher.s_p_a_id },
              }
            )
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActionsStudent();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    deleteSpecialPlanActionStudent(action) {
      //   console.log(action);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагааг устгах гэж байна. Төлөвлөсөн ҮА-г хариуцаж байгаа багш нар устгагдана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteSpecialPlanAction/" + action.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActionsStudent();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    // Ангийн төлөвлөгөөнд үйл ажиллагаа нэмэх модал

    showSpecialPlanForActionsSchoolClass(specialPlan) {
      //   console.log(specialPlan, "special plan");
      // this.monthPlanEmps = monthPlan.month_plan_teachers;
      this.formSpecial.reset();
      this.formSpecial.fill(specialPlan);
      this.formSpecial.s_p_owner_id =
        specialPlan.en_roll_employee_user_special_plan;
      this.formSpecial.s_p_student_id = specialPlan.student_user_special_plan;
      this.formSpecialPlanAction.s_p_id = specialPlan.id;
      this.showModeSpecialPlanSchoolClass =
        !this.showModeSpecialPlanSchoolClass;
      this.modalTeachers = this.teachers;
      this.formAddTeacher.reset();
      this.getMySpecialPlansActionsSchoolClass(); //allspecialPlanActionsOfSchoolClass
      this.$bvModal.show("modal-show-special-plan-action-school-class");
    },
    getMySpecialPlansActionsSchoolClass() {
      axios
        .get(
          "/psycho/getAllSpecialPlanActionsSchoolClass/" + this.formSpecial.id
        )
        .then((res) => {
          //   console.log(res.data);
          this.allspecialPlanActionsOfSchoolClass =
            res.data.allspecialPlanActionsOfSchoolClass;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideSpecialPlanForActionsSchoolClass() {
      this.$bvModal.hide("modal-show-special-plan-action-school-class");
    },

    cancelSpecialPlanActionSchoolClass() {
      this.editModeSPASchoolClass = false;
      this.formAddTeacher.department_id = 0;
      // this.formSpecialPlanAction.reset();
      this.formSpecialPlanAction.id = "";
      this.formSpecialPlanAction.year_id = "";
      this.formSpecialPlanAction.school_id = "";
      // this.formSpecialPlanAction.s_p_id = "";
      this.formSpecialPlanAction.s_p_a_name = "";
      this.formSpecialPlanAction.s_p_a_start_date = "";
      this.formSpecialPlanAction.s_p_a_finish_date = "";
      this.formSpecialPlanAction.s_p_a_during_time = 0;
      this.formSpecialPlanAction.s_p_a_success_point = 0;
      this.formSpecialPlanAction.s_p_a_success_explain = "";
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      this.getMySpecialPlansActionsSchoolClass();
    },
    createSpecialPlanActionSchoolClass() {
      this.formSpecialPlanAction
        .post("/psycho/createSpecialPlanActionSchoolClass")
        .then((res) => {
          res.data.ar;
          if (res.data.info.code === "success") {
            // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionSchoolClass();
        })
        .catch((err) => console.log(err));
    },

    editSpecialPlanActionSchoolClass(action) {
      // console.log(action.my_special_plan_action_users[0]['special_plan_action_user_info']['department_id']);
      // console.log(action);
      this.formSpecialPlanAction.reset();
      this.editModeSPASchoolClass = true;
      this.formSpecialPlanAction.fill(action);
      this.formAddTeacher.department_id =
        action.my_special_plan_action_users[0]["special_plan_action_user_info"][
          "department_id"
        ];
      this.formSpecialPlanAction.s_p_a_owner_id = [];
      // console.log(this.formSpecialPlanAction.s_p_a_owner_id);
      let convertModalTeachers = [];
      Object.entries(this.modalTeachers).forEach(([key, element]) => {
        convertModalTeachers.push(element);
      });
      action.my_special_plan_action_users.forEach((el) => {
        // console.log(el.s_p_a_owner_id);
        convertModalTeachers.forEach((element) => {
          // console.log(element.emp_user_id);
          if (el.s_p_a_owner_id == element.emp_user_id) {
            this.formSpecialPlanAction.s_p_a_owner_id.push(element.emp_user_id);
          }
        });
      });
    },
    updateSpecialPlanActionSchoolClass() {
      this.formSpecialPlanAction
        .patch("/psycho/updateSpecialPlanActionSchoolClass")
        .then((res) => {
          //   console.log(res.data);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message, "Амжилттай заслаа");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionSchoolClass();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeTeacherOfSchoolClass(teacher) {
      //   console.log(teacher);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагаанаас хариуцах багш хасах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              "/psycho/removeTeacherSpecialPlanActionUserPlan/" +
                teacher.s_p_a_owner_id,
              {
                params: { specialPlanActionId: teacher.s_p_a_id },
              }
            )
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActionsSchoolClass();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    deleteSpecialPlanActionSchoolClass(action) {
      //   console.log(action);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагааг устгах гэж байна. Төлөвлөсөн ҮА-г хариуцаж байгаа багш нар устгагдана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteSpecialPlanAction/" + action.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              // Fire.$emit("getMonthPlans");
              this.getMySpecialPlansActionsSchoolClass();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    customNameTeacher({
      emp_teacher_name,
      emp_teacher_last_name,
      dep_department_code,
    }) {
      return `${emp_teacher_last_name} ${emp_teacher_name}(${dep_department_code})`;
    },

    customNameSpecialTeacher({ emp_teacher_name, emp_teacher_last_name }) {
      return `${emp_teacher_last_name} ${emp_teacher_name}`;
    },
    customNameSchoolClass({ full_name }) {
      return `${full_name} анги`;
    },
    customNameStudent0({ studName, lastName, study_class }) {
      return `${lastName} ${studName}: Анги: ${study_class} `;
    },
    customNameStudent2({
      pro_student_name,
      pro_student_last_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name} ${pro_student_name}: Анги: ${school_class_full_name} `;
    },
    customNameClass({ study_class }) {
      return `${study_class}-р анги`;
    },
    customClassName({ class_number }) {
      return `${class_number}-р анги`;
    },
    customNameParent({
      parent_father_name,
      parent_father_ovog,
      school_class_full_name,
      student_last_name,
      student_name,
    }) {
      return `Аав: ${parent_father_ovog} ${parent_father_name}: Анги: ${school_class_full_name}: Суралцагч: ${student_last_name}  ${student_name}`;
    }, //? ${student_last_name}: ''
    getStudEnrollStatSpecial() {
      this.show = true;
      axios
        .get("/manager/getStudEnrollStatSpecial")
        .then((res) => {
          this.newStudExam = res.data.newStudExam;
          this.groupedStudyClasses = _.uniqBy(this.newStudExam, "study_class");
          this.examAvg = res.data.examAvgs;
          this.classNumAvgs = res.data.classNumAvgs;
          this.classAvgs = res.data.classAvgs;
          this.show = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAllDepInfo();
    this.getAllSpecialDatas();
    Fire.$on("getMonthPlans", () => {
      this.getAllDepInfo();
    });
    Fire.$on("getSpecialPlans", () => {
      this.getAllSpecialDatas();
      this.getSpecialStudStat();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
