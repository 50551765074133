import { render, staticRenderFns } from "./parentNotification.vue?vue&type=template&id=5b87e0ab&scoped=true&"
import script from "./parentNotification.vue?vue&type=script&lang=js&"
export * from "./parentNotification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b87e0ab",
  null
  
)

export default component.exports