<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        Танин мэдэхүйн үйлийн түвшний оператор:
        {{ child2LevelName.tax_lev_name }} түвшин дахь операторууд
      </h3>
      <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
    </div>
    <div class="card-body table-responsive p-0">
      <hr />
      <table class="table table-hover table-striped table-valign-middle">
        <thead>
          <tr role="row">
            <th>№</th>
            <th>Танин мэдэхүйн үйлийн түвшний оператор</th>
            <th>Танин мэдэхүйн үйлийн түвшний операторын тайлбар</th>
            <th>Үүсгэсэн</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="odd"
            v-for="(operation, index) in allTaxanomyLNOperations"
            :key="operation.id"
          >
            <td class="dtr-control sorting_1" tabindex="0">
              {{ index + 1 }}
            </td>
            <td class="dtr-control sorting_1" tabindex="0">
              <b-badge
                variant="primary"
                @click="childCompLevelNameOperation(operation)"
              >
                {{ operation.tax_lev_name_operation }}
              </b-badge>
            </td>
            <td class="dtr-control sorting_1" tabindex="0">
              {{ operation.tax_lev_name_operation_explain }}
            </td>
            <td class="dtr-control sorting_1" tabindex="0">
              {{ operation.created_at | dateYearMonthDay }}
            </td>
          </tr>
        </tbody>
      </table>
      <operation
        v-if="childMode3"
        :changeData3="changeData3"
        :child3LevelName="child3LevelName"
      ></operation>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
import operation from "./Child3LevelNameOperation/Child3LevelNameOperation.vue";
export default {
  props: ["child2LevelName", "changeData2"],
  data() {
    return {
      childMode3: false,
      changeData3: false,
      child3LevelName: "",
      allTaxanomyLNOperations: [],
    };
  },
  watch: {
    child2LevelName: function (newVal, oldval) {
      this.getAllInfo();
    },
    changeData2: function (nv, ov) {
      console.log(nv, ov);
      this.childMode3 = false;
      this.changeData3 = false;
    },
  },
  components: {
    operation,
  },
  methods: {
    getAllInfo() {
      axios
        .get(`/teacher/getAllTLNOperationTeacher/${this.child2LevelName["id"]}`)
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.allTaxanomyLNOperations = response.data.allTaxanomyLNOperations;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    childCompLevelNameOperation(operation) {
      //   console.log(operation);
      this.child3LevelName = "";
      this.childMode3 = false;
      this.child3LevelName = operation;
      //   console.log(this.child3LevelName);
      this.childMode3 = true;
    },
  },
  created() {
    this.getAllInfo();
  },
};
</script>

<style></style>
