<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedAgeStart"
                    :options="physicalAgeOptions"
                    text-field="text"
                    value-field="age"
                    size="sm"
                    class="form-control"
                  >
                  </b-form-select>
                </b-col>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedAgeEnd"
                    :options="physicalAgeOptions"
                    text-field="text"
                    value-field="age"
                    size="sm"
                    class="form-control"
                  >
                  </b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedGender"
                    :options="genderOptions"
                    text-field="name"
                    value-field="name"
                    size="sm"
                    class="form-control"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="getPhysicalGenerals()"
                    >Шүүж харах</b-button
                  >
                </b-col>
                <b-col sm="12">
                  <b-button
                    class="float-right"
                    variant="warning"
                    @click.prevent="showAgeType()"
                  >
                    Норматив бүртгэх</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="physicalIsLoaded" rounded="sm" class="py-3">
            <table class="table table-bordered table-striped" v-show="isFilter">
              <tbody>
                <tr
                  v-for="ageType in physicalGenerals"
                  :key="ageType.age_matrice"
                >
                  <td class="text-center p-0 verticalText text-bold">
                    {{ ageType.age_matrice }}
                  </td>
                  <td>
                    <div>
                      <div class="card card-secondary" age_matrice="ogsmCard">
                        <div class="card-header">
                          <h3 class="card-title mr-2">
                            {{ ageType.gender_matrice }}-н хэмжүүрүүд
                          </h3>
                          <b-badge variant="light" pill
                            >Норматив: {{ ageType.normative_start_matrice }} -
                            {{ ageType.normative_finish_matrice }}</b-badge
                          >
                          <div class="card-tools">
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0">
                          <div class="info-box bg-gradient-light">
                            <div class="info-box-content">
                              <div v-if="ageType.my_evals.length > 0">
                                <div
                                  class="card card-light mb-1"
                                  id="strategyCard"
                                  v-for="evalType in ageType.my_evals"
                                  :key="evalType.id"
                                >
                                  <div class="card-header">
                                    <h3 class="card-title">
                                      <b-badge variant="light" pill
                                        ><strong>Оноо: </strong>
                                        {{ evalType.start_matrice }} -
                                        {{ evalType.finish_matrice }}
                                        <i
                                          class="fa fa-arrow-right mx-3"
                                          aria-hidden="true"
                                        ></i>
                                        <strong>Үнэлгээ:</strong>
                                        {{ evalType.evaluation_matrice }}
                                      </b-badge>
                                    </h3>
                                    <div class="card-tools">
                                      <b-badge variant="success" pill
                                        >Засах:
                                        <b-badge variant="light" pill>
                                          <a
                                            href="#"
                                            @click.prevent="
                                              editEval(evalType, ageType)
                                            "
                                          >
                                            <b-icon
                                              icon="pencil-square"
                                              variant="primary"
                                            ></b-icon>
                                          </a>
                                        </b-badge>
                                      </b-badge>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-else class="callout callout-danger">
                                <h5>МЭДЭЭЛЭЛ БАЙХГҮЙ!</h5>
                                <p>Хэмжүүр одоогоор бүртгэгдээгүй байна.</p>
                              </div>
                            </div>
                            <!-- /.info-box-content -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- <div class="card-body">
                    <b-overlay :show="physicalIsLoaded" rounded="sm">
                        <table class="table table-sm table-bordered" v-show="isFilter">
                            <thead>
                                <tr role="row">
                                    <td colspan="2" class="text-center">Дүн</td>
                                    <td class="text-center" colspan="2">Үнэлгээ (оноо)</td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                    <td v-for="(ageType, age_matrice ) in physicalGenerals" :key="age_matrice"
                                        class="text-center p-0" :colspan="2">
                                        {{ ageType.age_matrice }}
                                    </td>
                                </tr>
                                <tr v-for="evalType in physicalGenerals" :key="evalType.age_matrice"
                                    class="text-center p-0" :colspan="2">
                                    <td>{{ evalType.evaluation_matrice }}</td>
                                    <td>
                                        {{ evalType.start_matrice }} - {{ evalType.finish_matrice }}
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </b-overlay>
                </div> -->
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editEvalMode">Хэмжүүр нэмэх</h3>
          <h3 class="card-title" v-show="editEvalMode">Хэмжүүр засах</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Нас, хүйс</label>
              <multiselect
                v-model="formEval.age_type_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                :custom-label="customPhysicalName"
                placeholder="Нас, хүйс"
                :options="ageTypes"
                :searchable="true"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.age_matrice }}, {{ option.gender_matrice }} нас
                </template>
              </multiselect>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-12">
                <label for="evaluation">Дүн</label>
                <b-form-select
                  v-model="formEval.evaluation_matrice"
                  :options="evolutionOptions"
                  text-field="name"
                  value-field="name"
                  placeholder="Дүн"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formEval.errors.has('evaluation'),
                    },
                  ]"
                ></b-form-select>
              </div>
            </div>
            <label for="name">Хэмжүүр</label>
            <div class="form-row">
              <div class="form-group col-lg-5">
                <input
                  type="number"
                  v-model="formEval.start_matrice"
                  v-on:change="checkMeasureCount"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formEval.errors.has('start_matrice'),
                    },
                  ]"
                  placeholder="Доод"
                />
              </div>
              <div class="col-lg-1">
                <i class="fa fa-arrow-right pt-2"></i>
              </div>
              <div class="form-group col-lg-6">
                <input
                  type="number"
                  v-model="formEval.finish_matrice"
                  v-on:change="checkMeasureCount"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formEval.errors.has('finish_matrice'),
                    },
                  ]"
                  placeholder="Дээд"
                />
                <span class="text-red">{{ evalError }}</span>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-success"
            v-show="!editEvalMode"
            @click.prevent="createEval"
          >
            Хэмжүүр нэмэх
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editEvalMode"
            @click.prevent="updateEval"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-dark"
            v-show="editEvalMode"
            @click.prevent="cancelEditEval"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <!-- Норматив нэмэх -->
    <b-modal
      v-show="showTypeMode"
      size="xl"
      id="modal-type"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Норматив нэмэх"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Үзүүлэлтүүд</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Хүйс</th>
                      <th>Нас</th>
                      <th>Норматив доод</th>
                      <th>Норматив дээд</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody v-for="(ageType, index) in ageTypes" :key="ageType.id">
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ ageType.gender_matrice }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ ageType.age_matrice }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ ageType.normative_start_matrice }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ ageType.normative_finish_matrice }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editAgeType(ageType)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          v-show="ageType.age_matrice != null"
                          href="#"
                          class="badge bg-danger"
                          @click="removeAgeType(ageType)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2" v-if="showAddTypeMode">
            <div class="card card-primary">
              <div class="card-header"></div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body"></div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updatePhysicType()"
                  v-show="editTypeMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditPhysicType()"
                  v-show="editTypeMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editTypeMode">
                  Норматив оруулах
                </h3>
                <h3 class="card-title" v-show="editTypeMode">Норматив засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <label>Хүйс</label>
                      <b-form-select
                        v-model="formAge.gender_matrice"
                        :options="genderOptions"
                        text-field="name"
                        value-field="name"
                        placeholder="Хүйс"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formAge.errors.has('gender_matrice'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-lg-6">
                      <label for="name">Нас</label>
                      <input
                        type="number"
                        v-model="formAge.age_matrice"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formAge.errors.has('age_matrice'),
                          },
                        ]"
                        placeholder="Нас"
                      />
                    </div>
                    <label for="name">Норматив</label>
                    <div class="form-row">
                      <div class="form-group col-lg-5">
                        <input
                          type="number"
                          v-model="formAge.normative_start_matrice"
                          v-on:change="checkMeasureCount"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': formAge.errors.has(
                                'normative_start_matrice'
                              ),
                            },
                          ]"
                          placeholder="Доод"
                        />
                      </div>
                      <div class="col-lg-1">
                        <i class="fa fa-arrow-right pt-2"></i>
                      </div>
                      <div class="form-group col-lg-6">
                        <input
                          type="number"
                          v-model="formAge.normative_finish_matrice"
                          v-on:change="checkMeasureCount"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': formAge.errors.has(
                                'normative_finish_matrice'
                              ),
                            },
                          ]"
                          placeholder="Дээд"
                        />
                        <span class="text-red">{{ evalError }}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editTypeMode"
                  @click.prevent="createAgeType"
                >
                  Норматив үүсгэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editTypeMode"
                  @click.prevent="updateAgeType"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelAgeType"
                  v-show="editTypeMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      physicalIsLoaded: false,
      isFilter: false,
      showAddMode: false,
      showTypeMode: false,
      showAddTypeMode: false,
      editTypeMode: false,
      editEvalMode: false,
      selectedAgeStart: null,
      selectedAgeEnd: null,
      selectedGender: "Эрэгтэй",
      physicalAgeOptions: [
        { age: 6, text: "6 нас" },
        { age: 7, text: "7 нас" },
        { age: 8, text: "8 нас" },
        { age: 9, text: "9 нас" },
        { age: 10, text: "10 нас" },
        { age: 11, text: "11 нас" },
        { age: 12, text: "12 нас" },
        { age: 13, text: "13 нас" },
        { age: 14, text: "14 нас" },
        { age: 15, text: "15 нас" },
        { age: 16, text: "16 нас" },
        { age: 17, text: "17 нас" },
        { age: 18, text: "18 нас" },
      ],
      physicalTypeList: [],
      mode: [],
      evalError: "",
      ageError: "",
      normativeError: "",
      genderOptions: [{ name: "Эрэгтэй" }, { name: "Эмэгтэй" }],
      formAge: new Form({
        id: "",
        gender_matrice: "",
        age_matrice: "",
        normative_start_matrice: "",
        normative_finish_matrice: "",
      }),
      formEval: new Form({
        id: "",
        age_type_id: "",
        start_matrice: "",
        finish_matrice: "",
        evaluation_matrice: "",
      }),
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      editMode: false,
      physicalGenerals: [],
      ageTypes: [],
      evalTypes: [],
      evolutionOptions: [
        { name: "A+" },
        { name: "A" },
        { name: "A-" },
        { name: "B+" },
        { name: "B" },
        { name: "B-" },
        { name: "C+" },
        { name: "C" },
        { name: "C-" },
        { name: "D+" },
        { name: "D" },
        { name: "D-" },
        { name: "F+" },
        { name: "F" },
        { name: "F-" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getPhysicalGenerals() {
      if (this.selectedAgeStart && this.selectedAgeEnd && this.selectedGender) {
        this.physicalIsLoaded = true;
        axios
          .post("/physical/getPhysicalGenerals/", {
            selectedAgeStart: this.selectedAgeStart,
            selectedAgeEnd: this.selectedAgeEnd,
            selectedGender: this.selectedGender,
          })
          .then((response) => {
            this.isFilter = true;
            this.physicalGenerals = response.data.physicalGenerals;
            this.physicalIsLoaded = false;
          })
          .catch((error) => {
            //   console.log(error);
          });
      } else this.$toastr.e("Нас болон хүйс сонгоно уу!", "Алдаа");
    },
    getAgeType() {
      axios
        .get("/physical/getGradeBodyAgeTypes/")
        .then((response) => {
          this.ageTypes = response.data.ageTypes;
          this.showTypeMode = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
      this.$bvModal.show("modal-type");
    },
    showAgeType() {
      this.showAddTypeMode = false;
      this.formAge.reset();
      this.getAgeType();
      this.$bvModal.show("modal-type");
    },
    addAgeType() {
      this.showAddTypeMode = true;
      this.editTypeMode = false;
    },
    createAgeType() {
      this.formAge
        .post("/physical/createAgeType/")
        .then((response) => {
          this.$toastr.s("Шалгуурын төрөл амжилттай үүсгэлээ.", "Амжилттай");
          this.getAgeType();
          this.showAddTypeMode = false;
          this.formAge.reset();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    customPhysicalName({ gender_matrice, age_matrice }) {
      return `${gender_matrice}, ${age_matrice}`;
    },
    checkMeasureCount() {
      let start = this.formEval.start_matrice;
      let end = this.formEval.finish_matrice;
      if (end != null && start != null) {
        if (end < start)
          this.evalError = "Хэмжүүрийн дээд утга нь доод утгаас их байх ёстой.";
        else this.evalError = "";
      } else {
        this.evalError = "Хэмжүүрийн  утгыг оруулна уу.";
      }
    },
    editAgeType(p) {
      this.showAddTypeMode = false;
      this.editTypeMode = true;
      this.formAge.reset();
      this.formAge.fill(p);
    },
    updateAgeType() {
      if (this.ageError != "") return;
      if (this.normativeError != "") return;
      this.formAge
        .put("/physical/updateAgeType/" + this.formAge.id)
        .then((response) => {
          this.formAge.reset();
          this.editTypeMode = false;
          this.getAgeType();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelAgeType() {
      this.editTypeMode = false;
      this.formAge.reset();
    },
    removeAgeType(p) {
      Swal.fire({
        title: "Шалгуур устгах",
        text: p.name + " шалгуурыг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Шалгуур хасаж байна...";
          axios
            .get("/physical/removeAgeType/" + p.id)
            .then((response) => {
              this.$toastr.s("Шалгуур амжилттай устгалаа!", "Шинэчилсэн");
              this.getAgeType();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    createEval() {
      this.formEval.age_type_id = this.formEval.age_type_id.id;
      this.formEval
        .post("/physical/createEval/")
        .then((response) => {
          this.getPhysicalGenerals();
          this.physicalIsLoaded = false;
          this.formEval.reset();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editEval(m, evalType) {
      this.editEvalMode = true;
      this.formEval.reset();
      this.formEval.fill(m);
      this.formEval.age_type_id = evalType;
    },
    updateEval() {
      if (this.evalError != "") return;
      this.formEval.age_type_id = this.formEval.age_type_id.id;
      this.formEval
        .put("/physical/updateEval/" + this.formEval.id)
        .then((response) => {
          this.$toastr.s("Хэмжүүр амжилттай заслаа", "Амжилттай");
          this.formEval.reset();
          this.editEvalMode = false;
          this.getPhysicalGenerals();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditEval() {
      this.editEvalMode = false;
      this.formEval.reset();
    },
    removeEval(p) {
      Swal.fire({
        title: "Шалгуур устгах",
        text: p.name + " шалгуурыг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Шалгуур хасаж байна...";
          axios
            .get("/physical/removeEval/" + p.id)
            .then((response) => {
              this.$toastr.s("Шалгуур амжилттай устгалаа!", "Шинэчилсэн");
              this.getPhysicalGenerals();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },
  created() {
    this.getAgeType();
    Fire.$on("getPhysicalGenerals", () => {
      this.getPhysicalGenerals();
    });
  },
};
</script>

<style scoped>
</style>
