<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header"></div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="d-flex justify-content-between">
                    <p style="font-size: 80%">
                      ЭЭЛЖИТ ХӨГЖҮҮЛЭХ ПРОЦЕССИЙН ЗУРАГЛАЛ
                    </p>
                  </div>
                </div>
                <div class="card-body">
                  <div class="position-relative mb-4">
                    <!-- <iframe
                      src="https://onedrive.live.com/embed?cid=545686A2437B5781&resid=545686A2437B5781%2195408&authkey=ALWjwcY9plUqBAY&em=2"
                      width="100%"
                      height="764"
                      frameborder="0"
                      scrolling="no"
                    ></iframe> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="d-flex justify-content-between">
                    <p style="font-size: 80%">
                      <!-- СУРГАЛТЫН ҮЙЛ ЯВЦ ДАХЬ МЭДЭЭ, МЭДЭЭЛЛИЙГ БАРИМТЖУУЛАХ
                      БОЛОВСРУУЛАХ, ХЯНАХ, БАТЛАХ ТУХАЙ ЖУРАМ -->
                    </p>
                  </div>
                </div>
                <div class="card-body">
                  <div class="position-relative mb-4">
                    <!-- <iframe
                      src="https://onedrive.live.com/embed?cid=545686A2437B5781&resid=545686A2437B5781%2193783&authkey=ABJM14ucGKJwqUw&em=2"
                      width="100%"
                      height="764"
                      frameborder="0"
                      scrolling="no"
                    ></iframe> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      employeeEvaluations: [],
      form: new Form({
        id: "",
        ajliin_sar: "",
        readEmployee: false,
      }),

      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      allShal1s: [],
      allShal1sum: "",
      allShal1sAverage: "",
      allShal2s: [],
      allShal2sum: "",
      allShal2sAverage: "",
      allShal3s: [],
      allShal3sum: "",
      allShal3sAverage: "",
      allShal4s: [],
      allShal4sum: "",
      allShal4sAverage: "",

      //together 0
      all0Shal1s: [],
      all0Shal1sum: "",
      all0Shal1sAverage: "",
      all0Shal2s: [],
      all0Shal2sum: "",
      all0Shal2sAverage: "",
      all0Shal3s: [],
      all0Shal3sum: "",
      all0Shal3sAverage: "",
      all0Shal4s: [],
      all0Shal4sum: "",
      all0Shal4sAverage: "",
    };
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    getAllData() {
      axios
        .get("/all_worker_functions/getAllEmployeesEvaluations")
        .then((response) => {
          //   console.log(response.data);
          //   this.employeeEvaluations = response.data.myWorkEvaluations;
          let sortEmployees = response.data.myWorkEvaluations;
          sortEmployees.sort(
            (a, b) =>
              a.department_name - b.department_name ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.employeeEvaluations = sortEmployees;

          this.averageCheck(this.employeeEvaluations);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    readEvaluation(employeeEvaluation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", employeeEvaluation);
      this.form.id = employeeEvaluation.id;
      this.form.ajliin_sar = employeeEvaluation.ajliin_sar;
      this.form.readEmployee = true;
      Swal.fire({
        title: "Шууд удирдлагын өгсөн үнэлгээг зөвшөөрч байна?",
        text: "Шууд удирдлагын өгсөн үнэлгээтэй танилцаж, саналаа өгсөн! Тийм, хадгалах! товчыг дарснаар шууд удирдлага дүнг засах боломжгүй болно.Тиймээс сарын үнэлгээ бүрэн баталгаажих болно.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.form
            .post("/all_worker_functions/readEmployee")
            .then((response) => {
              //   this.$toastr.s("Үнэлгээтэй танилцсан.", "Амжилттай");
              Swal.fire("Үнэлгээтэй танилцсан.", "Амжилттай", "success");
              Fire.$emit("loadSchoolEmployees");
              this.form.reset();
            })
            .catch(() => {
              // console.log(errors.all());
              this.$toastr.e(
                "Үнэлгээтэй танилцсан мэдээллийг хадгалж чадсангүй.",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    getFilterAllData() {
      axios
        .post("/all_worker_functions/getAllEmployeesFilterEvaluations", {
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          console.log(response.data);
          let sortEmployees = response.data.myWorkEvaluations;
          sortEmployees.sort(
            (a, b) =>
              a.department_name - b.department_name ||
              a.emp_teacher_name.localeCompare(b.emp_teacher_name)
          );
          this.employeeEvaluations = sortEmployees;

          this.averageCheck(this.employeeEvaluations);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    averageCheck(allEvaluations) {
      this.allShal1s = [];
      this.allShal1sum = "";
      this.allShal1sAverage = "";
      this.allShal2s = [];
      this.allShal2sum = "";
      this.allShal2sAverage = "";
      this.allShal3s = [];
      this.allShal3sum = "";
      this.allShal3sAverage = "";
      this.allShal4s = [];
      this.allShal4sum = "";
      this.allShal4sAverage = "";

      //without 0
      this.all0Shal1s = [];
      this.all0Shal1sum = "";
      this.all0Shal1sAverage = "";
      this.all0Shal2s = [];
      this.all0Shal2sum = "";
      this.all0Shal2sAverage = "";
      this.all0Shal3s = [];
      this.all0Shal3sum = "";
      this.all0Shal3sAverage = "";
      this.all0Shal4s = [];
      this.all0Shal4sum = "";
      this.all0Shal4sAverage = "";
      //   console.log(allEvaluations);
      allEvaluations.forEach((element) => {
        this.allShal1s.push(parseInt(element.shal1));
        this.allShal2s.push(parseInt(element.shal2));
        this.allShal3s.push(parseInt(element.shal3));
        this.allShal4s.push(parseInt(element.shal4));
      });

      this.allShal1sum = this.allShal1s.reduce((a, b) => a + b, 0);
      this.allShal1sAverage = (
        this.allShal1sum / this.allShal1s.length
      ).toFixed();
      this.allShal2sum = this.allShal2s.reduce((a, b) => a + b, 0);
      this.allShal2sAverage = (
        this.allShal2sum / this.allShal2s.length
      ).toFixed();
      this.allShal3sum = this.allShal3s.reduce((a, b) => a + b, 0);
      this.allShal3sAverage = (
        this.allShal3sum / this.allShal3s.length
      ).toFixed();
      this.allShal4sum = this.allShal4s.reduce((a, b) => a + b, 0);
      this.allShal4sAverage = (
        this.allShal4sum / this.allShal4s.length
      ).toFixed();

      //together zero
      this.all0Shal1s = this.allShal1s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal1sum = this.all0Shal1s.reduce((a, b) => a + b, 0);
      this.all0Shal1sAverage = (
        this.all0Shal1sum / this.all0Shal1s.length
      ).toFixed();

      this.all0Shal2s = this.allShal2s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal2sum = this.all0Shal2s.reduce((a, b) => a + b, 0);
      this.all0Shal2sAverage = (
        this.all0Shal2sum / this.all0Shal2s.length
      ).toFixed();

      this.all0Shal3s = this.allShal3s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal3sum = this.all0Shal3s.reduce((a, b) => a + b, 0);
      this.all0Shal3sAverage = (
        this.all0Shal3sum / this.all0Shal3s.length
      ).toFixed();

      this.all0Shal4s = this.allShal4s.filter(
        (el) => el !== undefined && el !== null && el !== 0
      );
      this.all0Shal4sum = this.all0Shal4s.reduce((a, b) => a + b, 0);
      this.all0Shal4sAverage = (
        this.all0Shal4sum / this.all0Shal4s.length
      ).toFixed();
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    average() {
      return (
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4
      );
    },
    calculatePercent() {
      let averagePer =
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4;
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },
    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },
  },
  computed: {
    validationNumber1() {
      return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    },
    validationNumber2() {
      return this.form.shal2 >= 0 && this.form.shal2 <= 100;
    },
    validationNumber3() {
      return this.form.shal3 >= 0 && this.form.shal3 <= 100;
    },
    validationNumber4() {
      return this.form.shal4 >= 0 && this.form.shal4 <= 100;
    },
    validationNumber5() {
      return this.form.shal5 >= 0 && this.form.shal5 <= 100;
    },
  },
  created() {
    this.getAllData();
    Fire.$on("loadSchoolEmployees", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
