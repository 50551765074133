<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Гэрээ</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addContract()"
                    >Гэрээ бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      <b>Нийт гэрээний тоо:</b>
                      {{
                        contractManagements.length
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>Шүүсэн гэрээний тоо:</b>
                      {{ filteredContractManagementCount }}
                    </h3>
                  </div>
                  <multiselect
                    v-model="yearId"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="customYearName"
                    placeholder="Хичээлийн жил сонгоно уу?"
                    :options="years"
                    :searchable="true"
                    :allow-empty="true"
                    @input="getAllContracts"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.start_date | dateYear }}-{{
                          option.finish_date | dateYear
                        }}
                        - хичээлийн жил
                      </strong></template
                    >
                  </multiselect>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay
                    :show="documentContractManagementLoaded"
                    rounded="sm"
                  >
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageContractManagement"
                            :options="pageOptionsDocuments"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterContractManagement"
                              type="search"
                              placeholder="Гүйцэтгэх ажил талбараар хайлт хийхийг анхаарна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterContractManagement"
                                @click="filterContractManagement = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageContractManagement"
                      :total-rows="totalRowsContractManagement"
                      :per-page="perPageContractManagement"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="contractManagements"
                      :fields="fieldsActiveContractManagement"
                      :current-page="currentPageContractManagement"
                      :per-page="perPageContractManagement"
                      style="font-size: 80%"
                      :filter-ignored-fields="
                        filterIgnoredFieldsContractManagement
                      "
                      :filter="filterContractManagement"
                      @filtered="onFilteredContractManagement"
                    >
                      <!-- :filter-included-fields="
                                                filterOnContractManagement
                                            " -->
                      <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(worktodo)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.worktodo }}
                        </b-badge>
                      </template>
                      <template #cell(my_files)="data">
                        <div
                          v-if="
                            userInfo.name == 'cipalprin' ||
                            userInfo.name == 'agermanvaa' ||
                            userInfo.name == 'supervisorshift'
                          "
                        >
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editContractManagement(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteContractManagement(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </div>

                        <b-card
                          header-tag="header"
                          footer-tag="footer"
                          v-for="(process, i) in data.item.my_files"
                          :key="process.id"
                          border-variant="success"
                          header-bg-variant="transparent"
                          class="cardFile"
                          v-if="data.item.my_files.length != 0"
                        >
                          <b-card-text>
                            {{ i + 1 }}.
                            <a
                              href="#"
                              v-b-toggle.sidebar-file
                              @click="
                                viewFile(
                                  process.contract_url,
                                  process.created_at
                                )
                              "
                              >{{ process.contract_url.split("-")[1] }}</a
                            >
                            <b-icon
                              v-if="
                                userInfo.name == 'cipalprin' ||
                                userInfo.name == 'agermanvaa' ||
                                userInfo.name == 'supervisorshift'
                              "
                              icon="x-octagon"
                              variant="danger"
                              @click="deleteContractFile(process)"
                            ></b-icon>
                          </b-card-text>
                        </b-card>
                        <b-card
                          header-tag="header"
                          footer-tag="footer"
                          border-variant="warning"
                          header-bg-variant="transparent"
                          class="cardFile"
                          v-else
                        >
                          <b-card-text>
                            <b-badge
                              variant="light"
                              role="button"
                              font-scale="1.6"
                            >
                              Файл хавсаргаагүй байна.
                            </b-badge>
                          </b-card-text>
                        </b-card>
                        <b-badge
                          variant="light"
                          role="button"
                          v-if="
                            userInfo.name == 'cipalprin' ||
                            userInfo.name == 'agermanvaa' ||
                            userInfo.name == 'supervisorshift'
                          "
                          font-scale="1.6"
                          @click="uploadFiles(data.item.id)"
                        >
                          Гэрээ хавсаргах
                          <b-icon icon="upload" variant="success"></b-icon>
                        </b-badge>
                      </template>
                      <template #cell(contract_number)="data">
                        <b-badge
                          variant="secondary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.contract_number }}
                        </b-badge>
                      </template>
                      <template #cell(contracted_company)="data">
                        {{ data.item.contracted_company }}
                      </template>
                      <template #cell(company_phone_number)="data">
                        {{ data.item.company_phone_number }}
                      </template>
                      <template #cell(my_contract_work_type)="data">
                        {{ data.item.my_contract_work_type.name }}
                      </template>
                      <template #cell(signed_contracted_date)="data">
                        {{
                          data.item.signed_contracted_date | dateYearMonthDay
                        }}
                      </template>
                      <template #cell(start_contract_date)="data">
                        <td
                          class="dtr-control sorting_1 text-center"
                          tabindex="0"
                        >
                          {{ data.item.start_contract_date | dateYearMonthDay }}

                          <br />
                          <i class="fa fa-arrow-down" aria-hidden="true"></i>
                          <br />
                          {{
                            data.item.finish_contract_date | dateYearMonthDay
                          }}
                        </td>
                      </template>
                      <!-- <template
                                                #cell(finish_contract_date)="data"
                                            >
                                                {{
                                                    data.item
                                                        .finish_contract_date
                                                        | dateYearMonthDay
                                                }}
                                            </template> -->
                      <template #cell(my_work_plans)="data">
                        <b-icon
                          v-b-toggle.sidebar-work-plan
                          icon="eye"
                          variant="success"
                          @click.prevent="showMyJobPlans(data.item)"
                        ></b-icon>
                        {{ calculateMyValuesAvg(data.item) }}
                        <div
                          class="progress progress-xs progress-striped active"
                        >
                          <div
                            v-if="calculateMyValuesAvg(data.item) < 30"
                            class="progress-bar bg-danger"
                            :style="{
                              width: `${calculateMyValuesAvg(data.item)}%`,
                            }"
                          ></div>
                          <div
                            v-if="
                              (calculateMyValuesAvg(data.item) >= 30) &
                              (calculateMyValuesAvg(data.item) < 60)
                            "
                            class="progress-bar bg-warning"
                            :style="{
                              width: `${calculateMyValuesAvg(data.item)}%`,
                            }"
                          ></div>
                          <div
                            v-if="
                              (calculateMyValuesAvg(data.item) >= 60) &
                              (calculateMyValuesAvg(data.item) < 80)
                            "
                            class="progress-bar bg-primary"
                            :style="{
                              width: `${calculateMyValuesAvg(data.item)}%`,
                            }"
                          ></div>
                          <div
                            v-if="calculateMyValuesAvg(data.item) >= 80"
                            class="progress-bar bg-success"
                            :style="{
                              width: `${calculateMyValuesAvg(data.item)}%`,
                            }"
                          ></div>
                        </div>
                      </template>
                      <template #cell(total_amount_contract)="data">
                        {{ data.item.total_amount_contract | converTugrik }}
                      </template>
                      <template #cell(sankhvvjilt_start)="data">
                        {{ data.item.sankhvvjilt_start | converTugrik }}
                      </template>
                      <template #cell(sankhvvjilt_mid)="data">
                        {{ data.item.sankhvvjilt_mid | converTugrik }}
                      </template>
                      <template #cell(sankhvvjilt_finish)="data">
                        {{ data.item.sankhvvjilt_finish | converTugrik }}
                      </template>
                      <template #cell(sankhvvjilt_vldegdel)="data">
                        {{ data.item.sankhvvjilt_vldegdel | converTugrik }}
                      </template>
                      <template #cell(wariant_period_contract)="data">
                        {{ data.item.wariant_period_contract }}
                      </template>
                      <template #cell(akt_date)="data">
                        {{ data.item.akt_date }}
                      </template>
                      <template #cell(akt_grade)="data">
                        {{ data.item.akt_grade }}
                      </template>
                      <template #cell(akt_description)="data">
                        <div
                          style="
                            font-size: 70%;
                            width: 10rem;
                            text-align: justify;
                          "
                        >
                          <!-- {{
                                                        data.item
                                                            .akt_description
                                                    }} -->
                          <span
                            v-html="
                              data.item.akt_description
                                ? data.item.akt_description.substring(0, 1000)
                                : ''
                            "
                          >
                          </span>
                        </div>
                      </template>
                      <template #cell(description)="data">
                        <div
                          style="
                            font-size: 70%;
                            width: 10rem;
                            text-align: justify;
                          "
                        >
                          <!-- {{ data.item.description }} -->
                          <span
                            v-html="
                              data.item.description
                                ? data.item.description.substring(0, 1000)
                                : ''
                            "
                          >
                          </span>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong>Устгагдсан гэрээ</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт бичиг баримтын тоо:
                      {{ contractManagementsDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay
                    :show="documentContractManagementLoaded"
                    rounded="sm"
                  >
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveContractManagement"
                            :options="pageOptionsInactiveDocumentStatus"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveContractManagement"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveContractManagement"
                                @click="filterInactiveContractManagement = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveContractManagement"
                      :total-rows="totalRowsInactiveContratManagement"
                      :per-page="perPageInactiveContractManagement"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="contractManagementsDeleted"
                      :fields="fieldsInactiveContractManagementStatus"
                      :current-page="currentPageInactiveContractManagement"
                      :per-page="perPageInactiveContractManagement"
                    >
                      <!-- :filter="filterInactiveContractManagement"
                      :filter-included-fields="filterOnInactiveContractManagement"
                      @filtered="onFilteredInactiveRooms" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(worktodo)="data">
                        <div
                          v-if="
                            userInfo.pivot.model_id == data.item.doc_owner_id ||
                            userInfo.name == 'cipalprin'
                          "
                        >
                          <a
                            v-if="
                              userInfo.pivot.model_id ==
                                data.item.doc_owner_id ||
                              userInfo.name == 'cipalprin'
                            "
                            href="#"
                            class="badge bg-warning"
                            @click="restoreContractManagement(data.item)"
                            ><i class="fas fa-trash-restore-alt"></i
                          ></a>
                        </div>
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.worktodo }}
                        </b-badge>
                      </template>
                      <template #cell(contract_number)="data">
                        <b-badge
                          variant="secondary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.contract_number }}
                        </b-badge>
                      </template>
                      <template #cell(contracted_company)="data">
                        {{ data.item.contracted_company }}
                      </template>
                      <template #cell(company_phone_number)="data">
                        {{ data.item.company_phone_number }}
                      </template>
                      <template #cell(my_contract_work_type)="data">
                        {{ data.item.my_contract_work_type.name }}
                      </template>
                      <template #cell(signed_contracted_date)="data">
                        {{
                          data.item.signed_contracted_date | dateYearMonthDay
                        }}
                      </template>
                      <template #cell(start_contract_date)="data">
                        {{ data.item.start_contract_date | dateYearMonthDay }}
                      </template>
                      <template #cell(finish_contract_date)="data">
                        {{ data.item.finish_contract_date | dateYearMonthDay }}
                      </template>
                      <template #cell(total_amount_contract)="data">
                        {{ data.item.total_amount_contract.department_code }}
                      </template>
                      <template #cell(sankhvvjilt_start)="data">
                        {{ data.item.sankhvvjilt_start }}
                      </template>
                      <template #cell(sankhvvjilt_mid)="data">
                        {{ data.item.sankhvvjilt_mid }}
                      </template>
                      <template #cell(sankhvvjilt_finish)="data">
                        {{ data.item.sankhvvjilt_finish }}
                      </template>
                      <template #cell(sankhvvjilt_vldegdel)="data">
                        {{ data.item.sankhvvjilt_vldegdel }}
                      </template>
                      <template #cell(wariant_period_contract)="data">
                        {{ data.item.wariant_period_contract }}
                      </template>
                      <template #cell(akt_date)="data">
                        {{ data.item.akt_date }}
                      </template>
                      <template #cell(akt_grade)="data">
                        {{ data.item.akt_grade }}
                      </template>
                      <template #cell(akt_description)="data">
                        {{ data.item.akt_description }}
                      </template>
                      <template #cell(description)="data">
                        {{ data.item.description }}
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Гэрээ бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-contract-management"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="!editModeContractManagement ? 'Гэрээ бүртгэх' : 'Гэрээ засах'"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <form
                @submit.prevent="
                  !editModeContractManagement
                    ? createCantractManagement()
                    : updateContractManagement()
                "
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>Гүйцэтгэх ажил</label>
                        <input
                          type="text"
                          v-model="form.worktodo"
                          placeholder="Гүйцэтгэх ажил"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('worktodo'),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Баталгаат хугацаа</label>
                        <input
                          type="number"
                          v-model="form.wariant_period_contract"
                          placeholder="Баталгаат хугацаа"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has(
                              'wariant_period_contract'
                            ),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Гэрээний дугаар</label>
                            <input
                              type="text"
                              v-model="form.contract_number"
                              placeholder="Гэрээний дугаар"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  form.errors.has('contract_number'),
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Гэрээний төрөл сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.work_type_id"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameContractTypes"
                                track-by="id"
                                label="department_name"
                                placeholder="Сонголт хийх"
                                :options="contractTypes"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-7">
                          <div class="form-group">
                            <label>Ажил гүйцэтгэх байгууллага, хувь хүн</label>
                            <input
                              type="text"
                              v-model="form.contracted_company"
                              placeholder="Ажил гүйцэтгэх байгууллага, хувь хүн"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  form.errors.has('contracted_company'),
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="form-group">
                            <label>Холбоо барих дугаар</label>
                            <input
                              type="text"
                              v-model="form.company_phone_number"
                              placeholder="Холбоо барих дугаар"
                              class="form-control"
                              :class="{
                                'is-invalid': form.errors.has(
                                  'company_phone_number'
                                ),
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Гэрээ байгуулсан огноо</label>
                            <b-form-datepicker
                              v-model="form.signed_contracted_date"
                              class="mb-2"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': form.errors.has(
                                    'signed_contracted_date'
                                  ),
                                },
                              ]"
                              placeholder="Огноо"
                            ></b-form-datepicker>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Гэрээний ажил эхлэх огноо</label>
                            <b-form-datepicker
                              v-model="form.start_contract_date"
                              class="mb-2"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': form.errors.has(
                                    'start_contract_date'
                                  ),
                                },
                              ]"
                              placeholder="Эхлэх огноо"
                            ></b-form-datepicker>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Гэрээний ажил дуусах огноо</label>
                            <b-form-datepicker
                              v-model="form.finish_contract_date"
                              class="mb-2"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': form.errors.has(
                                    'finish_contract_date'
                                  ),
                                },
                              ]"
                              placeholder="Дуусах огноо"
                            ></b-form-datepicker>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Гэрээний нийт дүн</label>
                        <input
                          type="number"
                          v-model="form.total_amount_contract"
                          placeholder="Гэрээний нийт дүн"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has(
                              'total_amount_contract'
                            ),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Санхүүжилт(урьдчилгаа)</label>
                        <input
                          type="number"
                          v-model="form.sankhvvjilt_start"
                          placeholder="Санхүүжилт(урьдчилгаа)"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('sankhvvjilt_start'),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Санхүүжилт(явц)</label>
                        <input
                          type="number"
                          v-model="form.sankhvvjilt_mid"
                          placeholder="Санхүүжилт(явц)"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('sankhvvjilt_mid'),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Санхүүжилт(эцэс)</label>
                        <input
                          type="number"
                          v-model="form.sankhvvjilt_finish"
                          placeholder="Санхүүжилт(эцэс)"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('sankhvvjilt_finish'),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Санхүүжилт(үлдэгдэл)</label>
                        <input
                          type="number"
                          v-model="form.sankhvvjilt_vldegdel"
                          placeholder="Санхүүжилт(үлдэгдэл)"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has(
                              'sankhvvjilt_vldegdel'
                            ),
                          }"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Акт хийж, ажил хүлээлцсэн огноо</label>
                        <b-form-datepicker
                          v-model="form.akt_date"
                          class="mb-2"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': form.errors.has('akt_date'),
                            },
                          ]"
                          placeholder="Акт хийж, ажил хүлээлцсэн огноо"
                        ></b-form-datepicker>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Акт үнэлгээ</label>
                        <input
                          type="number"
                          v-model="form.akt_grade"
                          placeholder="Акт үнэлгээ"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('akt_grade'),
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>Гэрээний акт хийсэн тайлбар</label>
                        <quill-editor
                          ref="myQuillEditor"
                          v-model="form.akt_description"
                          :options="editorOption"
                        />
                        <!-- <b-form-textarea
                                                    id="textarea"
                                                    v-model="
                                                        form.akt_description
                                                    "
                                                    placeholder="Гэрээний акт хийсэн тайлбар"
                                                    rows="3"
                                                    max-rows="6"
                                                    :class="{
                                                        'is-invalid': form.errors.has(
                                                            'akt_description'
                                                        )
                                                    }"
                                                ></b-form-textarea> -->
                      </div>
                      <div class="form-group">
                        <label>Гэрээний ерөнхий тайлбар</label>
                        <quill-editor
                          ref="myQuillEditor"
                          v-model="form.description"
                          :options="editorOption"
                        />
                        <!-- <b-form-textarea
                                                    id="textarea"
                                                    v-model="form.description"
                                                    placeholder="Гэрээний ерөнхий тайлбар"
                                                    rows="3"
                                                    max-rows="6"
                                                    :class="{
                                                        'is-invalid': form.errors.has(
                                                            'description'
                                                        )
                                                    }"
                                                ></b-form-textarea> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editModeContractManagement"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editModeContractManagement"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editModeContractManagement"
                    @click.prevent="cancelEdit"
                  >
                    Цуцлах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="!editModeContractManagement"
                    @click.prevent="cancelCreate"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- Бичиг баримтад файл хавсаргах -->
      <b-modal
        size="xl"
        id="modal-create-contract-management-files"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        title="Файл хавсаргах"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModeDocumentFiles">
                    Файл бүртгэх(Файлын нэрэнд дундуур зураас болон тусгай
                    тэмдэгт ашиглахгүй байхыг маш сайн анхаараарай)
                  </h3>
                  <h3 class="card-title" v-show="editModeDocumentFiles">
                    Файл засах(Файлын нэрэнд дундуур зураас болон тусгай тэмдэгт
                    ашиглахгүй байхыг маш сайн анхаараарай)
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editModeDocumentFiles
                        ? createSchoolContractFile()
                        : updateSchoolDocumentFile()
                    "
                    enctype="multipart/form-data"
                  >
                    <div class="card-body">
                      <b-form-group
                        label="Файл хавсаргах:"
                        label-cols-sm="2"
                        label-size="sm"
                      >
                        <input
                          type="file"
                          name="filename"
                          class="form-control"
                          id="inputFileUpload"
                          placeholder="Файл хавсаргах:"
                          v-on:change="selectFiles"
                        />
                      </b-form-group>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editModeDocumentFiles"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editModeDocumentFiles"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editModeDocumentFiles"
                        @click.prevent="cancelEditFiles"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editModeDocumentFiles"
                        @click.prevent="cancelCreateFiles"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- Бичиг баримт харуулах хэсэг -->
    <b-sidebar
      :title="showFileInfo.info"
      id="sidebar-file"
      bg-variant="dark"
      text-variant="light"
      right
      z-index="1039"
      shadow
      width="60%"
      backdrop
    >
      <div class="px-3 py-2 text-center">
        <div v-if="docType == 'pdf'">
          <embed :src="pdfValue" width="100%" class="vh-100" />
        </div>
        <div v-else>
          <VueDocPreview :value="docValue" type="office" />
        </div>
      </div>
    </b-sidebar>
    <!-- төлөвлөгдсөн ажлын график -->
    <b-sidebar
      :title="infoWorkPlans.worktodo"
      id="sidebar-work-plan"
      text-variant="light"
      right
      z-index="1039"
      shadow
      width="60%"
      backdrop
      no-close-on-route-change
      v-model="sidebarStatus"
    >
      <!-- bg-variant="dark" -->
      <div class="px-3 py-2 text-center">
        <b-row>
          <b-col lg="12">
            <b-button
              size="sm"
              class="float-right"
              variant="primary"
              @click="createWorkPlan()"
              >Ажил бүртгэх</b-button
            >
          </b-col>
          <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
                            </b-form-select>

                            <b-form-select
                              v-model="sortDesc"
                              :disabled="!sortBy"
                              :aria-describedby="ariaDescribedby"
                              size="sm"
                              class="w-25"
                            >
                              <option :value="false">Asc</option>
                              <option :value="true">Desc</option>
                            </b-form-select>
                          </b-input-group>
                        </b-form-group>
                      </b-col> -->

          <b-col lg="12" class="my-1">
            <b-form-group
              label="Нэг хуудсанд харуулах тоо"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPageJobPlans"
                :options="pageOptionsJobPlans"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label="Хайлт хийх утга оруулна уу!"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filterJobPlans"
                  type="search"
                  placeholder="Хайлт хийх утгаа оруулна уу!"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="!filterJobPlans"
                    @click="filterJobPlans = ''"
                    >Цэвэрлэх</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-pagination
          v-model="currentPageJobPlans"
          :total-rows="totalRowsJobPlans"
          :per-page="perPageJobPlans"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        <b-table
          hover
          :items="jobPlans"
          :fields="fieldsActiveJobPlans"
          :current-page="currentPageJobPlans"
          :per-page="perPageJobPlans"
          style="font-size: 80%"
          :filter-ignored-fields="filterIgnoredFieldsJobPlans"
          :filter="filterJobPlans"
          :filter-included-fields="filterOnJobPlans"
          @filtered="onFilteredJobPlans"
        >
          <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(grade)="data">
            <div
              v-if="
                userInfo.name == 'cipalprin' || userInfo.name == 'agermanvaa'
              "
            >
              <a
                href="#"
                class="badge bg-warning"
                @click="editContractWorkPlan(data.item)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                href="#"
                class="badge bg-danger"
                @click="deleteContractWorkPlan(data.item)"
                ><i class="far fa-trash-alt"></i
              ></a>
            </div>
            <b-badge variant="light" class="text-wrap" style="width: 8rem">
              {{ data.item.grade }}
            </b-badge>
          </template>
          <template #cell(work_name)="data">
            <b-badge variant="secondary" class="text-wrap" style="width: 8rem">
              {{ data.item.work_name }}
            </b-badge>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | dateYearMonthDay }}
          </template>
          <template #cell(finish_date)="data">
            {{ data.item.finish_date | dateYearMonthDay }}
          </template>

          <template #cell(duration)="data">
            {{ data.item.duration }}
          </template>
          <template #cell(description)="data">
            <span
              v-html="
                data.item.description
                  ? data.item.description.substring(0, 1000)
                  : ''
              "
            >
            </span>
          </template>
        </b-table>
      </div>
    </b-sidebar>

    <!--Ажил гүйцэтгэх график бүртгэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-create-work-plan"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="!editModeWorkPlan ? 'Ажил бүртгэх' : 'Ажил засах'"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="
                !editModeWorkPlan
                  ? createContractWorkPlan()
                  : updateContractWorkPlan()
              "
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Гүйцэтгэх ажлын нэр</label>
                      <input
                        type="text"
                        v-model="formWorkPlan.work_name"
                        placeholder="Гүйцэтгэх ажлын нэр"
                        class="form-control"
                        :class="{
                          'is-invalid': formWorkPlan.errors.has('work_name'),
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>Үнэлгээ(0-100)</label>
                      <input
                        type="number"
                        v-model="formWorkPlan.grade"
                        placeholder="Үнэлгээ"
                        class="form-control"
                        :class="{
                          'is-invalid': formWorkPlan.errors.has('grade'),
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Эхлэх огноо</label>
                          <b-form-datepicker
                            v-model="formWorkPlan.start_date"
                            class="mb-2"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formWorkPlan.errors.has('start_date'),
                              },
                            ]"
                            placeholder="Эхлэх огноо"
                          ></b-form-datepicker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Дуусах огноо</label>
                          <b-form-datepicker
                            v-model="formWorkPlan.finish_date"
                            class="mb-2"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formWorkPlan.errors.has('finish_date'),
                              },
                            ]"
                            placeholder="Дуусах огноо"
                          ></b-form-datepicker>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Хугацаа(хоног)</label>
                          <input
                            type="number"
                            v-model="formWorkPlan.duration"
                            placeholder="Хугацаа"
                            class="form-control"
                            :class="{
                              'is-invalid': formWorkPlan.errors.has('duration'),
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>Тайлбар</label>
                      <quill-editor
                        ref="myQuillEditor"
                        v-model="formWorkPlan.description"
                        :options="editorOption"
                      />
                      <!-- <b-form-textarea
                                                id="textarea"
                                                v-model="
                                                    formWorkPlan.description
                                                "
                                                placeholder="Тайлбар"
                                                rows="3"
                                                max-rows="6"
                                                :class="{
                                                    'is-invalid': formWorkPlan.errors.has(
                                                        'description'
                                                    )
                                                }"
                                            ></b-form-textarea> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="!editModeWorkPlan"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editModeWorkPlan"
                >
                  Засах
                </button>
                <button
                  class="btn btn-warning"
                  v-show="editModeWorkPlan"
                  @click.prevent="cancelEditWorkPlan"
                >
                  Цуцлах
                </button>
                <button
                  class="btn btn-warning"
                  v-show="!editModeWorkPlan"
                  @click.prevent="cancelCreateWorkPlan"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
import VueDocPreview from "vue-doc-preview";

export default {
  data() {
    return {
      docValue: "",
      pdfValue: "",
      docType: "",
      showFileInfo: {
        info: "",
      },
      yearId: null,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      //b-table тохиргоо
      totalRowsContractManagement: 1,
      currentPageContractManagement: 1,
      perPageContractManagement: 25,
      pageOptionsDocuments: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterContractManagement: "",
      // filterOnContractManagement: [],
      filteredContractManagementCount: 0,
      filterIgnoredFieldsContractManagement: [
        // "worktodo",
        "my_files",
        "contract_number",
        "wariant_period_contract",
        "contracted_company",
        "my_work_plans",
        "company_phone_number",
        "my_contract_work_type",
        "signed_contracted_date",
        "start_contract_date",
        "total_amount_contract",
        "sankhvvjilt_start",
        "sankhvvjilt_mid",
        "sankhvvjilt_finish",
        "sankhvvjilt_vldegdel",
        "akt_date",
        "akt_grade",
        "akt_description",
        "description",
      ],

      fieldsActiveContractManagement: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "worktodo",
          label: "Гүйцэтгэх ажил",
          sortable: true,
        },
        {
          key: "my_files",
          label: "Файлууд",
          sortable: false,
        },

        {
          key: "contract_number",
          label: "Гэрээ №",
          sortable: true,
        },
        {
          key: "wariant_period_contract",
          label: "Баталгаат хугацаа",
          sortable: true,
        },
        {
          key: "contracted_company",
          label: "Ажил гүйцэтгэх байгууллага, хувь хүн",
          sortable: true,
        },
        {
          key: "my_work_plans",
          label: "Ажил гүйцэтгэх график",
          sortable: true,
        },
        {
          key: "company_phone_number",
          label: "Холбоо барих дугаар",
          sortable: true,
        },
        {
          key: "my_contract_work_type",
          label: "Гэрээний төрөл",
          sortable: true,
        },
        {
          key: "signed_contracted_date",
          label: "Гэрээ байгуулсан огноо",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "start_contract_date",
          label: "Эхлэх огноо - Дуусах огноо",
          sortable: true,
        },
        // {
        //     key: "finish_contract_date",
        //     label: "Дуусах огноо",
        //     sortable: true
        // },
        {
          key: "total_amount_contract",
          label: "Гэрээний нийт дүн",
          sortable: true,
        },
        {
          key: "sankhvvjilt_start",
          label: "Урьдчилгаа",
          sortable: true,
        },
        {
          key: "sankhvvjilt_mid",
          label: "Явцын санхүүжилт",
          sortable: true,
        },
        {
          key: "sankhvvjilt_finish",
          label: "Гэрээний сүүл төлбөр",
          sortable: true,
        },
        {
          key: "sankhvvjilt_vldegdel",
          label: "Гэрээний үлдэгдэл",
          sortable: true,
        },
        {
          key: "akt_date",
          label: "Акт үйлдсэн огноо",
          sortable: true,
        },
        {
          key: "akt_grade",
          label: "Актын үнэлгээ",
          sortable: true,
        },
        {
          key: "akt_description",
          label: "Актын тайлбар",
          sortable: true,
        },
        {
          key: "description",
          label: "Гэрээний ерөнхий тайлбар",
          sortable: true,
        },
        // {
        //     key: "created_at",
        //     label: "Үүсгэсэн",
        //     sortable: true
        // },
        // {
        //     key: "updated_at",
        //     label: "Зассан",
        //     sortable: true
        // },
        // {
        //     key: "actions",
        //     label: "Үйлдлүүд",
        //     sortable: false
        // }
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveContratManagement: 1,
      currentPageInactiveContractManagement: 1,
      perPageInactiveContractManagement: 25,
      pageOptionsInactiveDocumentStatus: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveContractManagement: "",
      filterOnInactiveContractManagement: [],
      fieldsInactiveContractManagementStatus: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "worktodo",
          label: "Гүйцэтгэх ажил",
          sortable: true,
        },

        {
          key: "contract_number",
          label: "Гэрээ №",
          sortable: true,
        },
        {
          key: "contracted_company",
          label: "Ажил гүйцэтгэх байгууллага, хувь хүн",
          sortable: true,
        },
        {
          key: "company_phone_number",
          label: "Холбоо барих дугаар",
          sortable: true,
        },
        {
          key: "my_contract_work_type",
          label: "Гэрээний төрөл",
          sortable: true,
        },
        {
          key: "signed_contracted_date",
          label: "Гэрээ байгуулсан огноо",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "start_contract_date",
          label: "Эхлэх огноо",
          sortable: true,
        },
        {
          key: "finish_contract_date",
          label: "Дуусах огноо",
          sortable: true,
        },
        {
          key: "total_amount_contract",
          label: "Гэрээний нийт дүн",
          sortable: true,
        },
        {
          key: "sankhvvjilt_start",
          label: "Урьдчилгаа",
          sortable: true,
        },
        {
          key: "sankhvvjilt_mid",
          label: "Явцын санхүүжилт",
          sortable: true,
        },
        {
          key: "sankhvvjilt_finish",
          label: "Гэрээний сүүл төлбөр",
          sortable: true,
        },
        {
          key: "sankhvvjilt_vldegdel",
          label: "Гэрээний үлдэгдэл",
          sortable: true,
        },
        {
          key: "wariant_period_contract",
          label: "Баталгаат хугацаа",
          sortable: true,
        },
        {
          key: "akt_date",
          label: "Акт үйлдсэн огноо",
          sortable: true,
        },
        {
          key: "akt_grade",
          label: "Актын үнэлгээ",
          sortable: true,
        },
        {
          key: "akt_description",
          label: "Актын тайлбар",
          sortable: true,
        },
        {
          key: "description",
          label: "Гэрээний ерөнхий тайлбар",
          sortable: true,
        },
      ],

      // Гэрээний хэсэг
      documentContractManagementLoaded: true,
      editModeContractManagement: false,
      editModeDocumentFiles: false,
      contractTypes: [],
      contractManagements: [],
      contractManagementsDeleted: [],
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        worktodo: "", //хийсэн ажил
        contract_number: "", //гэрээний дугаар
        contracted_company: "", //гэрээ хийсэн компани, хувь хүн
        company_phone_number: "", // холбоо барих дугаар
        work_type_id: "", //гэрээний төрлийн id
        signed_contracted_date: "", //гэрээ хийсэн огноо
        start_contract_date: "", //гэрээний эхлэх огноо
        finish_contract_date: "", //гэрээ дуусах огноо
        total_amount_contract: "", //гэрээний нийт дүн
        sankhvvjilt_start: "", //эхний санхүүжилт
        sankhvvjilt_mid: "", //явцын санхүүжилт
        sankhvvjilt_finish: "", // эцсийн санхүүжилт
        sankhvvjilt_vldegdel: "", // санхүүжилтийн үлдэгдэл
        wariant_period_contract: "", //гэрээний баталгаат хугацаа
        akt_date: "", //гэрээний акт үйлдсэн огноо
        akt_grade: "", //гэрээний актын үнэлгээ
        akt_description: "", //гэрээний актын тайлбар
        description: "", // гэрээний ерөнхий тайлбар
      }),

      // Файл хадгалах
      formFile: new Form({
        id: "",
        file: "",
      }),
      // Ажил гүйцэтгэх график харах
      infoWorkPlans: "",
      jobPlans: [],
      //b-table тохиргоо
      totalRowsJobPlans: 1,
      currentPageJobPlans: 1,
      perPageJobPlans: 25,
      pageOptionsJobPlans: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterJobPlans: "",
      filterOnJobPlans: [],
      filteredJobPlansCount: 0,
      filterIgnoredFieldsJobPlans: [
        "my_doc_proccess",
        "my_doc_owner",
        "my_doc_department",
        // "my_doc_classify_item",
      ],

      fieldsActiveJobPlans: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "grade",
          label: "Гүйцэтгэл %",
          sortable: true,
        },
        {
          key: "work_name",
          label: "Гүйцэтгэх ажил",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Эхлэх огноо",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "finish_date",
          label: "Дуусах огноо",
          sortable: true,
        },

        {
          key: "duration",
          label: "Хугацаа(хоног)",
          sortable: true,
        },
        {
          key: "description",
          label: "Тайлбар",
          sortable: true,
        },
      ],
      //   Нэмэлт ажил бүртгэх
      editModeWorkPlan: false,
      sidebarStatus: false,
      formWorkPlan: new Form({
        id: "",
        contract_management_id: "",
        grade: "",
        work_name: "",
        start_date: "",
        finish_date: "",
        duration: "",
        description: "",
      }),
      // Гэрээний төгсгөл
    };
  },
  components: { Multiselect, VueDocPreview },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
    years: function () {
      return this.$store.state.years;
    },
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    viewFile(url, date) {
      // console.log(url, date);
      this.showFileInfo.info = " Огноо:" + date;
      var typeArr = url.split(".");
      this.docType = typeArr[typeArr.length - 1];
      // console.log(this.docType);
      if (this.docType == "pdf") {
        this.pdfValue = url;
      } else this.docValue = "grading4you.com" + url;
      // console.log(this.docValue);
    },

    percentVote(plus, minus) {
      //   console.log(plus, minus);
      let niilber = plus.length + minus.length;

      let huve = (plus.length / niilber) * 100;
      return huve.toFixed(1);
    },
    getAllContracts() {
      this.documentContractManagementLoaded = true;
      let yearID = null;
      if (this.yearId) {
        yearID = this.yearId.id;
      }
      axios
        .get("/hr/getAllContracts/" + yearID)
        .then((res) => {
          //   console.log(response); contractTypes
          this.contractManagements = res.data.contractManagements;
          this.contractManagementsDeleted = res.data.contractManagementsDeleted;

          this.contractTypes = res.data.contractTypes;

          this.totalRowsContractManagement = this.contractManagements
            ? this.contractManagements.length
            : 0;
          this.totalRowsInactiveContratManagement = this
            .contractManagementsDeleted
            ? this.contractManagementsDeleted.length
            : 0;
          this.documentContractManagementLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //b-table тохирго
    onFilteredContractManagement(filteredItems) {
      //   console.log(filteredItems);
      this.filteredContractManagementCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsContractManagement = filteredItems.length;
      this.currentPageContractManagement = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveContratManagement = filteredItems.length;
      this.currentPageInactiveContractManagement = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredJobPlans(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveContratManagement = filteredItems.length;
      this.currentPageInactiveContractManagement = 1;
    },

    addContract() {
      this.form.reset();
      this.$bvModal.show("modal-create-contract-management");
    },
    createCantractManagement() {
      //   console.log("create");
      this.form
        .post("/hr/createCantractManagement")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadContracts");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editContractManagement(contractInfo) {
      // console.log(contractInfo);
      //exams_teacher_id, block_name,
      this.editModeContractManagement = true;
      this.form.fill(contractInfo);

      this.contractTypes.forEach((element) => {
        if (element.id == contractInfo.work_type_id) {
          this.form.work_type_id = element;
        }
      });

      this.$bvModal.show("modal-create-contract-management");
    },
    updateContractManagement() {
      //   console.log("udpate");
      this.form
        .put("/hr/updateContractManagement")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadContracts");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeContractManagement = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-contract-management");
    },

    // nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
    //   return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    // },
    nameContractTypes({ name }) {
      return `${name}`;
    },

    deleteContractManagement(contractInfo) {
      // console.log(contractInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн гэрээ системээс устгагдах болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteContractManagement/" + contractInfo.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Гэрээг системээс устгалаа.", "success");
              Fire.$emit("loadContracts");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreContractManagement(contractManagementInfo) {
      // console.log(contractManagementInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              "/hr/restoreContractManagement/" + contractManagementInfo.id
            )
            .then((res) => {
              Swal.fire("Сэргээлээ!", "Системд буцаан сэргээлээ.", "success");
              Fire.$emit("loadContracts");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    // Файлтай ажиллах хэсэг
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} файл сонгогдлоо`;
    },
    uploadFiles(myDocProccessId) {
      console.log(myDocProccessId);
      this.formFile.reset();
      this.formFile.id = myDocProccessId;
      this.$bvModal.show("modal-create-contract-management-files");
    },
    selectFiles(e) {
      this.formFile.file = e.target.files[0];
    },
    createSchoolContractFile() {
      this.$bvModal.hide("modal-create-contract-management-files");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formFile.file);
      axios
        .post(
          "/hr/createSchoolContractFile/" + this.formFile.id,
          formData,
          config
        )
        .then(function (response) {
          if (response.data.errorMsj != "")
            Swal.fire("Алдаа гарлаа!", response.data.errorMsj, "warning");
          else {
            Fire.$emit("loadContracts");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
          }
        });
      //   this.formFile.post("/hr/createSchoolContractFile").then((res) => {});
    },
    cancelCreateFiles() {
      this.formFile.reset();
      this.$bvModal.hide("modal-create-contract-management-files");
    },
    cancelEditFiles() {
      this.cancelCreateFiles();
      this.editModeDocumentFiles = false;
    },

    deleteContractFile(file) {
      // console.log(file);
      Swal.fire({
        title: "Та итгэлтэй байна уу? Файл устгах гэж байна.",
        text: "Файлыг устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/hr/deleteContractFile", {
              id: file.id,
              contract_url: file.contract_url,
            })
            .then((res) => {
              Swal.fire("Устгасан!", "Файлыг системээс устгалаа.", "success");
              Fire.$emit("loadContracts");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // Ажил гүйцэтгэх график
    showMyJobPlans(item) {
      // console.log(item);
      this.infoWorkPlans = item;
      this.jobPlans = item.my_work_plans;
    },
    calculateMyValuesAvg(item) {
      // console.log(item.my_work_plans.length);
      let avg = 0;
      let sum = 0;
      if (item.my_work_plans.length != 0) {
        item.my_work_plans.forEach((element) => {
          sum += parseInt(element.grade);
        });
        avg = sum / item.my_work_plans.length;
      }
      // console.log(sum);

      return avg.toFixed(1);
    },

    cancelEditWorkPlan() {
      //   this.form.reset();
      this.cancelCreateWorkPlan();
      this.editModeWorkPlan = false;
    },
    cancelCreateWorkPlan() {
      this.formWorkPlan.reset();
      this.$bvModal.hide("modal-create-work-plan");
    },
    createWorkPlan() {
      this.formWorkPlan.contract_management_id = this.infoWorkPlans.id;
      this.$bvModal.show("modal-create-work-plan");
    },
    createContractWorkPlan() {
      //   console.log("create");
      this.formWorkPlan
        .post("/hr/createContractWorkPlan")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadWorkPlans");
          this.cancelCreateWorkPlan();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    loadMyPlans(item) {
      // console.log(item);
      axios
        .get("/hr/getMyContractAllPlans/" + item)
        .then((res) => {
          // console.log(res.data);
          this.jobPlans = res.data.jobPlans;
        })
        .catch();
    },

    editContractWorkPlan(itemInfo) {
      // console.log(itemInfo);
      this.editModeWorkPlan = true;
      this.formWorkPlan.fill(itemInfo);
      this.$bvModal.show("modal-create-work-plan");
    },
    updateContractWorkPlan() {
      //   console.log("udpate");
      this.formWorkPlan
        .put("/hr/updateContractWorkPlan")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadWorkPlans");
          this.cancelCreateWorkPlan();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    deleteContractWorkPlan(contractInfo) {
      // console.log(contractInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн гэрээ системээс устгагдах болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteContractWorkPlan/" + contractInfo.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Гэрээг системээс устгалаа.", "success");
              Fire.$emit("loadWorkPlans");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  watch: {
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.room_owner_employees = "";
    //   this.form.room_plate_face = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/manager/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
    sidebarStatus: function (newVal, oldVal) {
      if (newVal === false) {
        Fire.$emit("loadContracts");
      }
    },
  },
  created() {
    this.getAllContracts();
    Fire.$on("loadContracts", () => {
      this.getAllContracts();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
    Fire.$on("loadWorkPlans", () => {
      this.loadMyPlans(this.infoWorkPlans.id);
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}

.cardFile {
  border-width: 1px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
