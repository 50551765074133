<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Уулзалтын бүртгэл</h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr role="row">
                <th>№</th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Бүртгэсэн ажилтан</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Харьяалагдах Х.Баг</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Анги</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Суралцагч</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Эцэг эх</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Ярилцлагын агуулга</b-badge
                  >
                </th>
                <th class="text-sm">Санал</th>
                <th class="text-sm">Гомдол</th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Уулзсан ажилтны сэтгэгдэл</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Шийдсэн байдал</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge variant="secondary"
                    >Шийдвэрийн
                    тайлбар....................................................
                  </b-badge>
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                  >
                    Цаг
                  </b-badge>
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Үүсгэсэн</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Зассан</b-badge
                  >
                </th>
                <th class="text-sm">
                  <b-badge
                    variant="secondary"
                    style="white-space: pre-wrap font-size: 2px"
                    >Үйлдлүүд</b-badge
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(complaint, index) in allComplaints"
                :key="complaint.id"
              >
                <td class="text-sm">
                  {{ index + 1 }}
                </td>
                <td class="text-sm">
                  {{ complaint.teacher_last_name[0] }}.{{
                    complaint.teacher_name
                  }}
                </td>
                <td class="text-sm">
                  {{ complaint.department_code }}
                </td>

                <td class="text-sm">
                  {{ complaint.full_name }}
                </td>
                <td class="text-sm">
                  {{ complaint.profile_student_last_name[0] }}.{{
                    complaint.profile_student_name
                  }}
                </td>
                <td class="text-sm">
                  {{ complaint.profile_student_last_name }}
                  {{ complaint.parent_father_phone_number }}
                  {{ complaint.parent_father_email }}
                </td>
                <td class="text-sm" style="width: 90px">
                  <b-badge variant="secondary" style="white-space: pre-wrap">{{
                    complaint.complaint_main_content
                  }}</b-badge>
                </td>
                <td class="text-sm">
                  <b-badge variant="warning" v-show="complaint.complaint_offer">
                    Санал</b-badge
                  >
                </td>
                <td class="text-sm">
                  <b-badge
                    variant="danger"
                    v-show="complaint.complaint_complaint"
                  >
                    Гомдол</b-badge
                  >
                </td>
                <td class="text-sm">
                  <b-badge variant="info" style="white-space: pre-wrap">
                    {{ complaint.complaint_uulz_ajilt_set }}
                  </b-badge>
                </td>
                <td class="text-sm">
                  <b-badge
                    variant="warning"
                    v-show="complaint.complaint_shiid_baidal === 0"
                  >
                    Холбон зуучилсан</b-badge
                  >
                  <b-badge
                    variant="success"
                    v-show="complaint.complaint_shiid_baidal === 1"
                  >
                    Өөрөө шийдвэрлэсэн</b-badge
                  >
                  <b-badge
                    variant="danger"
                    v-show="complaint.complaint_shiid_baidal === 2"
                  >
                    Шийдвэрлэх боломжгүй</b-badge
                  >
                </td>
                <td class="text-sm">
                  <b-badge variant="info" style="white-space: pre-wrap">
                    {{ complaint.complaint_shiid_tailbar }}
                  </b-badge>
                </td>
                <td class="text-sm">{{ complaint.complaint_meet_time }} мин</td>
                <td class="text-sm">
                  {{ complaint.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm">
                  {{ complaint.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editComplaint(complaint)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <!-- <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteComplaint(schoolRegEmp)"
                    ><i class="fas fa-trash-alt"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Уулзалтын тэмдэглэл</h3>
          <h3 class="card-title" v-show="editMode">
            Уулзалтын тэмдэглэл засах
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="!editMode ? createComplaint() : updateComplaint()"
          >
            <div class="card-body">
              <div class="form-group">
                <label>Хөтөлбөрийн баг</label>
                <b-form-select
                  v-model="form.department_id"
                  :options="departments"
                  size="sm"
                  value-field="id"
                  text-field="department_name"
                ></b-form-select>
                <has-error :form="form" field="id"></has-error>
              </div>
              <div class="form-group">
                <label>Сургуулийн анги</label>
                <b-form-select
                  v-model="form.school_class_id"
                  placeholder="Шийдвэрлэсэн байдал"
                  :options="school_classes"
                  value-field="id"
                  text-field="full_name"
                  size="sm"
                ></b-form-select>
                <has-error :form="form" field="id"></has-error>
              </div>
              <div class="form-group">
                <label>Сонгогдсон ангийн суралцагчид</label>
                <b-form-select
                  v-model="form.student_id"
                  placeholder="Шийдвэрлэсэн байдал"
                  :options="students"
                  value-field="profiles_id"
                  text-field="profiles_student_name"
                  size="sm"
                ></b-form-select>
                <has-error :form="form" field="profiles_id"></has-error>
              </div>
              <div class="form-group">
                <label>Сонгогдсон суралцагчын эцэг эх</label>
                <b-form-select
                  v-model="form.parent_id"
                  :options="parents"
                  value-field="profile_parent_id"
                  text-field="parent_father_name"
                  size="sm"
                ></b-form-select>
                <has-error :form="form" field="profiles_id"></has-error>
              </div>

              <div class="form-group">
                <label>Гол агуулга(Субъектив илрэл)</label>
                <b-textarea
                  type="text"
                  name="emp_teacher_urgiin_ovog"
                  v-model="form.main_content"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('main_content'),
                    },
                  ]"
                  placeholder="Гол агуулга(Субъектив илрэл)"
                />
                <has-error :form="form" field="main_content"></has-error>
              </div>
              <div class="form-group">
                <div class="container">
                  <div class="row">
                    <div class="col-sm">
                      <div class="input-group mb-3">
                        <label class="mr-3">Санал</label>
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <input
                              v-model="form.offer"
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm">
                      <div class="input-group mb-3">
                        <label class="mr-3">Гомдол</label>
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <input
                              v-model="form.complaint"
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm">
                      <div class="input-group mb-3">
                        <label class="mr-3">Зөвлөгөө</label>
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <input
                              v-model="form.advise"
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Уулзалсан ажилтны сэтгэгдэл</label>
                <b-textarea
                  name="uulz_ajilt_set"
                  v-model="form.uulz_ajilt_set"
                  text-field="uulz_ajilt_set"
                  value-field="id"
                ></b-textarea>
                <has-error :form="form" field="uulz_ajilt_set"></has-error>
              </div>
              <div class="form-group">
                <label>Шийдвэрлэсэн байдал</label>
                <b-form-select
                  v-model="form.shiid_baidal"
                  placeholder="Шийдвэрлэсэн байдал"
                  :options="shiidver"
                  size="sm"
                ></b-form-select>
                <has-error :form="form" field="shiid_baidal"></has-error>
              </div>
              <div class="form-group">
                <label>Шийдвэрлэсэн тайлбар</label>
                <b-textarea
                  name="shiid_tailbar"
                  v-model="form.shiid_tailbar"
                  text-field="shiid_tailbar"
                  value-field="id"
                ></b-textarea>
                <has-error :form="form" field="shiid_tailbar"></has-error>
              </div>
              <div class="form-group">
                <label
                  >Санал, гомдол, шийдвэр, зөвлөгөө өгөхөд зарцуулсан
                  хугацаа(минутаар)</label
                >
                <b-form-input
                  v-model="form.meet_time"
                  type="number"
                ></b-form-input>
                <has-error :form="form" field="shiid_tailbar"></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      allComplaints: [],
      //   keyword: "",
      selectStudParent: false,
      departments: [],
      school_classes: [],
      students: [],
      parents: [],
      shiidver: [
        { value: null, text: "Шийдвэр сонгоно уу?" },
        { value: 0, text: "Холбон зуучлах" },
        { value: 1, text: "Өөрөө шийдвэрлэсэн" },
        { value: 2, text: "Шийдвэрлэх боломжгүй" },
      ],
      form: new Form({
        id: "",
        department_id: "",
        school_class_id: "",
        student_id: "",
        parent_id: "",
        main_content: "",
        offer: "",
        complaint: "",
        advise: "",
        uulz_ajilt_set: "",
        shiid_baidal: "",
        shiid_tailbar: "",
        meet_time: "",
      }),
    };
  },
  watch: {
    "form.school_class_id": function (newValue, oldValue) {
      //   console.log(oldValue, newValue);
      axios
        .post("/manager/allManagerStudentSchoolClass", {
          schoolClass_id: newValue,
        })
        .then((response) => {
          this.students = response.data.students; //Сонгогдсон ангийн бүх сурагчдыг дуудна.
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    "form.student_id": function (newValue, oldValue) {
      console.log(oldValue, newValue);
      axios
        .post("/manager/selectedManagerStudentParent", {
          student_id: newValue,
        })
        .then((response) => {
          this.parents = response.data.parents; //Сонгогдсон сурагчдын эцэг эхийг дуудна.
          this.form.parent_id = this.parents[0].profile_parent_id;
          //сонгогдсон утгаар харуулах
          this.selectStudParent = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  components: {
    // searchManager: searchManager,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/manager/getManagerAllInfo")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.allComplaints = response.data.allComplaints;
          this.departments = response.data.departments;
          this.school_classes = response.data.school_classes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    passChangeSchoolEmployee(schoolEmp) {
      console.log(schoolEmp);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe%2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manage/passChangeEmployer/" + schoolEmp.user_id)
            .then((response) => {
              console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    editComplaint(complaint) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(complaint);
      this.form.id = complaint.complaint_id;
      this.form.student_id = complaint.complaint_students_id;
      this.form.main_content = complaint.complaint_main_content;
      this.form.offer = complaint.complaint_offer;
      this.form.complaint = complaint.complaint_complaint;
      this.form.advise = complaint.complaint_advise;
      this.form.uulz_ajilt_set = complaint.complaint_uulz_ajilt_set;
      this.form.shiid_baidal = complaint.complaint_shiid_baidal;
      this.form.shiid_tailbar = complaint.complaint_shiid_tailbar;
      this.form.meet_time = complaint.complaint_meet_time;
    },
    updateComplaint() {
      this.action = "Санал, гомдол шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateManagerComplaint/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllComplains");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteComplaint(schoolteacher) {
      console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    createComplaint() {
      this.action = "Уулзалтын бүртгэл үүсгэж байна...";
      this.form
        .post("/manager/createManagerComplain")
        .then((response) => {
          this.$toastr.s(
            "Уулзалтын тэмдэглэлийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllComplains");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Уулзалтын бүртгэл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllComplains", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
