<template>
  <section class="content">
    <div class="container-fluid">
      <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->

      <div class="row">
        <div class="col-md-12 mt-3">
          <div id="1" class="card card-widget widget-user shadow">
            <div class="widget-user-header bg-primary">
              <h3 class="widget-user-username">
                {{ myAllInfos.teacher_last_name }}
                {{ myAllInfos.teacher_name }}
              </h3>
              <h5 class="widget-user-desc">
                {{ myAllInfos.position_name }}
              </h5>
            </div>
            <div class="widget-user-image">
              <img
                class="user-image img-circle elevation-2"
                :src="myAllInfos.profile_photo_url"
                alt="image"
              />
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-2 border-right">
                  <div class="description-block">
                    <h5
                      v-if="myAllInfos.teacher_tugssun_sur != null"
                      class="description-header"
                    >
                      {{ myAllInfos.teacher_tugssun_sur }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text">Төгссөн сургууль</span>
                  </div>
                  <!-- /.description-block -->
                </div>
                <!-- /.col -->
                <div class="col-sm-1 border-right">
                  <div class="description-block">
                    <h5
                      v-if="myAllInfos.teacher_bol_zer != null"
                      class="description-header"
                    >
                      {{ myAllInfos.teacher_bol_zer }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text">Мэргэжлийн зэрэг</span>
                  </div>
                </div>
                <div class="col-sm-1 border-right">
                  <div class="description-block">
                    <h5
                      v-if="myAllInfos.teacher_bol_bai_ajiljil != null"
                      class="description-header"
                    >
                      {{ myAllInfos.teacher_bol_bai_ajiljil }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text">Ажилласан жил</span>
                  </div>
                </div>
                <div class="col-sm-2 border-right">
                  <div class="description-block">
                    <h5
                      v-if="myAllInfos.deed_bol != null"
                      class="description-header"
                    >
                      {{ myAllInfos.deed_bol }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text">Дээд боловсролын зэрэг</span>
                  </div>
                </div>
                <div class="col-sm-2 border-right">
                  <div class="description-block">
                    <h5
                      v-if="
                        userFiles.filter(
                          (item) => item.type == 'Багшлах эрхийн үнэмлэх'
                        ).length > 0
                      "
                      class="description-header"
                    >
                      Тийм
                      <b-badge variant="success">
                        <a
                          href="#"
                          v-b-toggle.sidebar-file
                          @click="viewFile(file.created_at, file.url)"
                          ><b-icon icon="eye" aria-hidden="true"></b-icon>
                        </a>
                      </b-badge>
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text"
                      >Багшлах эрхийн үнэмлэхтэй эсэх</span
                    >
                  </div>
                </div>
                <div class="col-sm-2 border-right">
                  <div
                    class="description-block"
                    v-if="myAllInfos.department != null"
                  >
                    <h5
                      v-if="myAllInfos.department.my_dep_curr_teams != null"
                      class="description-header"
                    >
                      {{ myAllInfos.department.my_dep_curr_teams.name }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text"
                      >Харьяалагдах хөтөлбөрийн баг</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5
                      v-if="myAllInfos.department != null"
                      class="description-header"
                    >
                      {{ myAllInfos.department.department_name }}
                    </h5>
                    <h5 v-else class="description-header">Бүртгээгүй</h5>
                    <span class="description-text"
                      >Харьяалагдах мэргэжлийн баг</span
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6">
                  <table class="table text-wrap report-user-info">
                    <tbody>
                      <tr role="row">
                        <th>Хүйс</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <i
                            class="fas fa-female"
                            v-if="myAllInfos.gender === 'Эм'"
                          ></i>
                          <i
                            class="fas fa-male"
                            v-if="myAllInfos.gender === 'Эр'"
                          ></i>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Гэрлэсэн эсэх</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <i
                            class="fas fa-people-arrows"
                            v-if="myAllInfos.married === '1'"
                          ></i>
                          <i class="fas fa-restroom" v-else></i>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Утас</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_phoneNumber1 }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Хувийн цахим шуудан</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_personal_email }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Хаяг</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_live_address }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Төгссөн сургууль/Суурь, бүрэн дунд боловсрол/</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.buren_dund_bol }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Боловсролын байгууллагад ажилласан жил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_bol_bai_ajiljil }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Тухайн байгууллагад ажлласан жил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_tukhain_bai_ajiljil }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Харьяалагдах хөтөлбөрийн баг</th>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          v-if="depCurr != null"
                        >
                          <div v-for="(curr, index) in depCurr" :key="curr.id">
                            {{ index + 1 }}.{{ curr.description }}
                          </div>
                        </td>
                        <td v-else>Бүртгээгүй</td>
                      </tr>
                      <tr role="row">
                        <th>Харьяалагдах мэргэжлийн баг</th>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          v-if="depInfo != null"
                        >
                          <div v-for="(dep, index) in depInfo" :key="dep.id">
                            {{ index + 1 }}.{{ dep.department_code }}
                          </div>
                        </td>
                        <td v-else>Бүртгээгүй</td>
                      </tr>
                      <tr role="row">
                        <th>Мэргэжил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_mergejil }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-6">
                  <table class="table text-wrap report-user-info">
                    <tbody>
                      <tr role="row">
                        <th>
                          Дээд боловсролын зэрэг/Бакалавр, Магистр, Ph.D, Sc.D/
                        </th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.deed_bol }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>ТҮБД зэрэг</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.tvbd_zer }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Спортын мастер эсэх</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_sport_mas }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Эрдмийн бүтээлийн бүртгэл</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <div
                            v-if="
                              userFiles.filter(
                                (item) =>
                                  item.type == 'Эрдмийн бүтээлийн бүртгэл'
                              ).length > 0
                            "
                          >
                            <b-badge
                              variant="light"
                              v-for="file in userFiles.filter(
                                (item) =>
                                  item.type == 'Эрдмийн бүтээлийн бүртгэл'
                              )"
                              :key="file.id"
                            >
                              <a
                                href="#"
                                v-b-toggle.sidebar-file
                                @click="viewFile(file.created_at, file.url)"
                              >
                                {{ getFileName(file.url) }}
                              </a>
                            </b-badge>
                          </div>
                          <b-badge v-else> Бүртгэгдээгүй байна </b-badge>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Мэрэгшүүлэх сургалт</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <div
                            v-if="
                              userFiles.filter(
                                (item) => item.type == 'Мэргэшүүлэх сургалт'
                              ).length > 0
                            "
                          >
                            <b-badge
                              variant="light"
                              v-for="file in userFiles.filter(
                                (item) => item.type == 'Мэргэшүүлэх сургалт'
                              )"
                              :key="file.id"
                            >
                              <a
                                href="#"
                                v-b-toggle.sidebar-file
                                @click="viewFile(file.created_at, file.url)"
                              >
                                {{ getFileName(file.url) }}
                              </a>
                            </b-badge>
                          </div>
                          <b-badge v-else> Бүртгэгдээгүй байна </b-badge>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Ажилласан илтгэл, төсөл хөтөлбөр</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <div
                            v-if="
                              userFiles.filter(
                                (item) =>
                                  item.type ==
                                  'Ажилласан илтгэл, төсөл хөтөлбөр'
                              ).length > 0
                            "
                          >
                            <b-badge
                              variant="light"
                              v-for="file in userFiles.filter(
                                (item) =>
                                  item.type ==
                                  'Ажилласан илтгэл, төсөл хөтөлбөр'
                              )"
                              :key="file.id"
                            >
                              <a
                                href="#"
                                v-b-toggle.sidebar-file
                                @click="viewFile(file.created_at, file.url)"
                              >
                                {{ getFileName(file.url) }}
                              </a>
                            </b-badge>
                          </div>
                          <b-badge v-else> Бүртгэгдээгүй байна </b-badge>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Гадагшаа зорчсон албан ажил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <div
                            v-if="
                              userFiles.filter(
                                (item) =>
                                  item.type == 'Гадагшаа зорчсон албан ажил'
                              ).length > 0
                            "
                          >
                            <b-badge
                              variant="light"
                              v-for="file in userFiles.filter(
                                (item) =>
                                  item.type == 'Гадагшаа зорчсон албан ажил'
                              )"
                              :key="file.id"
                            >
                              <a
                                href="#"
                                v-b-toggle.sidebar-file
                                @click="viewFile(file.created_at, file.url)"
                              >
                                {{ getFileName(file.url) }}
                              </a>
                            </b-badge>
                          </div>
                          <b-badge v-else> Бүртгэгдээгүй байна </b-badge>
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Ажлын төрөл</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.work_type }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Тухайн мэргэжлээр ажилласан жил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_tukhain_mer_ajiljil }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Төрийн байгууллагад ажилласан жил</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.turiin_alba_ajiljil }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Амьдралын нөхцөл байдал</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_live_situation }}
                        </td>
                      </tr>
                      <tr role="row">
                        <th>Зээлийн нөхцөл байдал</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ myAllInfos.teacher_zeel_situation }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                  <p class="text-center">
                    <strong>Хэлний мэдлэг</strong>
                  </p>
                  <div class="info-box">
                    <span class="info-box-icon bg-warning elevation-1"
                      ><i class="fa fa-language"></i
                    ></span>

                    <div class="info-box-content">
                      <span class="info-box-text">Англи хэл</span>
                      <span class="info-box-number">
                        {{ myAllInfos.teacher_english }}
                      </span>
                    </div>
                    <div class="info-box-content">
                      <span class="info-box-text">Япон хэл</span>
                      <span class="info-box-number">
                        {{ myAllInfos.teacher_japanese }}
                      </span>
                    </div>
                    <div class="info-box-content">
                      <span class="info-box-text">Хятад хэл</span>
                      <span class="info-box-number">
                        {{ myAllInfos.teacher_chinese }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                  <p class="text-center">
                    <strong>Олон улсын хөтөлбөр</strong>
                  </p>
                  <div class="info-box">
                    <span class="info-box-icon bg-danger elevation-1"
                      ><i class="fa fa-id-card"></i
                    ></span>

                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Олон улсын хөтөлбөр хэрэгжүүлэх эрхтэй эсэх</span
                      >
                      <div
                        v-if="
                          userFiles.filter(
                            (item) =>
                              item.type == 'Олон улсын хөтөлбөр хэрэгжүүлэх эрх'
                          ).length > 0
                        "
                      >
                        <b-badge
                          variant="light"
                          v-for="file in userFiles.filter(
                            (item) =>
                              item.type == 'Олон улсын хөтөлбөр хэрэгжүүлэх эрх'
                          )"
                          :key="file.id"
                        >
                          <a
                            href="#"
                            v-b-toggle.sidebar-file
                            @click="viewFile(file.created_at, file.url)"
                          >
                            {{ getFileName(file.url) }}
                          </a>
                        </b-badge>
                      </div>
                      <b-badge v-else class="text-wrap" style="width: 8rem"
                        >Үгүй</b-badge
                      >
                    </div>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Олон улсын шалгагч багш эсэх</span
                      >
                      <div
                        v-if="
                          userFiles.filter(
                            (item) => item.type == 'Олон улсын шалгагч багш'
                          ).length > 0
                        "
                      >
                        <b-badge
                          variant="light"
                          v-for="file in userFiles.filter(
                            (item) => item.type == 'Олон улсын шалгагч багш'
                          )"
                          :key="file.id"
                        >
                          <a
                            href="#"
                            v-b-toggle.sidebar-file
                            @click="viewFile(file.created_at, file.url)"
                          >
                            {{ getFileName(file.url) }}
                          </a>
                        </b-badge>
                      </div>
                      <b-badge v-else class="text-wrap" style="width: 8rem">
                        Үгүй
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <stat4
              id="2"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            ></stat4>
            <stat7
              id="3"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            ></stat7>
            <stat12
              id="4"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            ></stat12>
            <stat13
              id="5"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            ></stat13>
            <teacherEval
              id="6"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </teacherEval>
            <stat18
              id="7"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            ></stat18>
            <teachClassAttendStat
              id="8"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </teachClassAttendStat>
            <myLessonHomeWork
              id="8"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </myLessonHomeWork>
            <reportMonthPlan
              id="9"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </reportMonthPlan>
            <reportMyWorkEvaluation
              id="10"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </reportMyWorkEvaluation>
            <reportSuccess
              id="11"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </reportSuccess>
            <reportBudgets
              id="12"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </reportBudgets>
            <courseComment
              id="13"
              class="card card-widget widget-user shadow"
              :teacherId="teacherId"
            >
            </courseComment>
            <!-- ангийн багшийн хэсэг -->
            <div v-if="myClass.length > 0">
              <classInfo
                id="14"
                class="card card-widget widget-user shadow"
                :teacherId="teacherId"
              >
              </classInfo>
              <classSuccess
                id="15"
                class="card card-widget widget-user shadow"
                :teacherId="teacherId"
              >
              </classSuccess>
              <stat23
                id="16"
                class="card card-widget widget-user shadow"
                :teacherId="teacherId"
              ></stat23>
              <homeWork
                id="17"
                class="card card-widget widget-user shadow"
                :teacherId="teacherId"
              >
              </homeWork>
              <myClassAtt
                id="17"
                class="card card-widget widget-user shadow"
                :teacherId="teacherId"
              >
              </myClassAtt>
            </div>
          </div>
          <!-- <grade>Дүнгийн мэдээлэл</grade>
          <attend>Ирцийн мэдээлэлl</attend> -->
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
      <div id="mybutton">
        <b-button
          v-b-toggle.sidebar-report
          @click="switchMenu()"
          class="feedback btn-sm"
          block
          :variant="btnColor"
          ><i :class="iconMode" aria-hidden="true"></i
        ></b-button>
      </div>
    </div>
    <b-sidebar
      id="sidebar-file"
      bg-variant="dark"
      text-variant="light"
      right
      z-index="1041"
      shadow
      width="90%"
      backdrop
    >
      <template slot="title"
        >Огноо:
        {{ showFileInfo.date | dateYearMonthDay }}
      </template>
      <div class="px-3 py-2 text-center">
        <div>
          <embed
            v-if="showFileInfo.docType == 'pdf'"
            :src="showFileInfo.pdfValue"
            width="100%"
            class="vh-100"
          />
          <VueDocPreview
            v-else-if="showFileInfo.docType != 'share'"
            :value="showFileInfo.docValue"
            type="office"
          />
        </div>
      </div>
    </b-sidebar>
    <b-sidebar
      id="sidebar-report"
      :no-close-on-route-change="true"
      aria-labelledby="sidebar-no-header-title"
      no-header
      width="250px"
      z-index="1039"
      shadow
      bg-variant="dark"
    >
      <template>
        <div class="p-3">
          <h4 id="sidebar-no-header-title">Тайлан</h4>
          <ul class="document-toc-list" id="toc-entries">
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#1"
                :aria-current="[this.$route.hash == '#1' ? true : false]"
              >
                1. Багшийн ерөнхий болон ур чадварын мэдээлэл
              </a>
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#2"
                :aria-current="[this.$route.hash == '#2' ? true : false]"
                >2. Сургалтын үйл ажиллагааны цогц байдал</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#3"
                :aria-current="[this.$route.hash == '#3' ? true : false]"
                >3. Боловсролын хөтөлбөрийн агуулгын хэрэгжилт</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#4"
                :aria-current="[this.$route.hash == '#4' ? true : false]"
                >4. Сургалтын ээлжит үйл ажиллагааны багшийн үнэлгээ</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#5"
                :aria-current="[this.$route.hash == '#5' ? true : false]"
                >5. Сургалтын ээлжит үйл ажиллагаанд үнэлгээ шинжилгээ хийх
                аргачлал</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#6"
                :aria-current="[this.$route.hash == '#6' ? true : false]"
                >6. Ээлжит хичээлийн үнэлгээ</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#7"
                :aria-current="[this.$route.hash == '#7' ? true : false]"
                >7. Судлагдахууны дундаж үнэлгээ</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#8"
                :aria-current="[this.$route.hash == '#8' ? true : false]"
                >8. Анги бүлгийн хүмүүжлийн үнэлгээ</a
              >
            </li>
            <li class="document-toc-item">
              <a
                class="document-toc-link"
                href="#9"
                :aria-current="[this.$route.hash == '#9' ? true : false]"
                >9. Сарын төлөвлөгөө болон үүрэг даалгавар</a
              >
            </li>
          </ul>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import teachClassAttendStat from "./AttendanceStat/teachClassAttendStat.vue";
import grade from "./GradeStat/IndexGradeConfirmYear.vue";
import stat4 from "./Stat/Stat4.vue";
import stat7 from "./Stat/Stat7.vue";
import stat12 from "./Stat/Stat12.vue";
import stat13 from "./Stat/Stat13.vue";
import stat18 from "./Stat/stat18.vue";
import stat23 from "./Stat/stat23.vue";
import teacherEval from "../TeacherLessonEvaluation/indexTeacherEvaluation.vue";
import reportMonthPlan from "./Stat/reportMonthPlan.vue";
import reportMyWorkEvaluation from "./Stat/reportMyWorkEvaluation.vue";
import reportSuccess from "./Stat/reportSuccess.vue";
import reportBudgets from "./Stat/reportBudgets.vue";
import courseComment from "./Stat/courseComment.vue";
import classInfo from "./reportClass/classInfo.vue";
import classSuccess from "./reportClass/classSuccess.vue";
import homeWork from "./reportClass/homeWork.vue";
import myLessonHomeWork from "./Stat/myLessonHomeWork.vue";
import myClassAtt from "./reportClass/classAttendStat.vue";
export default {
  props: ["teacherId"],
  data() {
    return {
      myAllInfos: [],
      myClass: [],
      userFiles: [],
      depInfo: [],
      depCurr: [],
      editMode: false,
      iconMode: "fa fa-bars",
      btnColor: "warning",
      form: new Form({
        show_task_id: null,
        task_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employees: [],
        performance_date: "",
        task: "",
        manager_explain: "",
        teacher_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),

      showFileInfo: {
        date: "",
        docType: "",
        pdfValue: "",
        docValue: "",
      },
    };
  },
  components: {
    //attend: attend,
    grade: grade,
    stat4: stat4,
    stat7: stat7,
    stat12: stat12,
    stat13: stat13,
    stat18: stat18,
    stat23: stat23,
    teacherEval: teacherEval,
    reportMonthPlan: reportMonthPlan,
    reportMyWorkEvaluation: reportMyWorkEvaluation,
    reportSuccess: reportSuccess,
    reportBudgets: reportBudgets,
    courseComment: courseComment,
    classInfo: classInfo,
    classSuccess: classSuccess,
    homeWork: homeWork,
    teachClassAttendStat: teachClassAttendStat,
    myLessonHomeWork: myLessonHomeWork,
    myClassAtt: myClassAtt,
  },
  methods: {
    getAllMyInfo() {
      axios
        .post("/teacher/getAllMyInfo", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.myAllInfos = res.data.myAllInfos;
          this.years = res.data.years;
          this.myClass = res.data.myClass;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMyFiles() {
      //   console.log("shortPlanSequence");
      axios
        .post("/all_worker_functions/getMyFiles", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.userFiles = res.data.userFiles;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getMyDepInfo() {
      //   console.log("shortPlanSequence");
      axios
        .post("/all_worker_functions/getMyDepInfo", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.depInfo = res.data.depInfo;
          this.depCurr = res.data.depCurr;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getFileName(url) {
      let arr = url.split("/");
      return arr[arr.length - 1];
    },
    viewFile(date, url) {
      this.showFileInfo.date = date;
      var typeArr = url.split(".");
      this.showFileInfo.docType = typeArr[typeArr.length - 1];
      if (this.showFileInfo.docType == "pdf") {
        this.showFileInfo.pdfValue = url;
      } else this.showFileInfo.docValue = "grading4you.com" + url;
    },
    switchMenu() {
      if (this.iconMode != "fa fa-bars") {
        this.iconMode = "fa fa-bars";
        this.btnColor = "warning";
      } else {
        this.iconMode = "fa fa-times";
        this.btnColor = "danger";
      }
    },
  },
  created() {
    this.getAllMyInfo();
    this.getMyFiles();
    this.getMyDepInfo();
  },
};
</script>

<style scoped>
span {
  font-size: 70%;
}

b {
  font-size: 80%;
}
</style>
