<template>
  <tr>
    <b-spinner small v-if="loading" variant="info"></b-spinner>
    <td>{{ tooluur + 1 }}</td>
    <td>
      <b-badge
        v-if="getCourseStudents.student_profile_photo_url != null"
        variant="light"
        v-b-tooltip.hover.top.html="
          '<dd><img src=' +
          getCourseStudents.student_profile_photo_url +
          ' style=' +
          'width:180px!important' +
          '></dd><dt>Суралцагч</dt><dd>' +
          getCourseStudents.student_last_name +
          ' ' +
          getCourseStudents.user_name
        "
      >
        {{ getCourseStudents.student_last_name[0]
        }}{{ getCourseStudents.student_last_name[1] }}.{{
          getCourseStudents.user_name
        }}
      </b-badge>
      <b-badge
        v-if="getCourseStudents.student_profile_photo_url == null"
        variant="light"
        v-b-tooltip.hover.top.html="
          '<dd><img src=' +
          '/images/users/user.png' +
          ' style=' +
          'width:180px!important' +
          '></dd><dt>Суралцагч</dt><dd>' +
          getCourseStudents.student_last_name[0] +
          getCourseStudents.student_last_name[1] +
          '.' +
          getCourseStudents.user_name
        "
      >
        {{ getCourseStudents.student_last_name[0]
        }}{{ getCourseStudents.student_last_name[1] }}.{{
          getCourseStudents.user_name
        }}
      </b-badge>
    </td>
    <td>
      <validation-provider name="form.exam1" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            v-model="form.exam1"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam1"
            html-field="0"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
      <!-- @keyup.tab="calcAverage(15)" -->
    </td>
    <td>
      <validation-provider name="form.exam2" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            v-model="form.exam2"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam2"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
    </td>
    <td>
      <validation-provider name="form.exam3" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            min="0"
            max="100"
            v-model="form.exam3"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam3"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
    </td>
    <td>
      <validation-provider name="form.exam4" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            min="0"
            max="100"
            v-model="form.exam4"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam4"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
    </td>
    <td>
      <validation-provider name="form.exam5" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            min="0"
            max="100"
            v-model="form.exam5"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam5"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
    </td>
    <td>
      <validation-provider name="form.exam6" rules="max_value:100|min_value:0">
        <template #default="{ errors }">
          <b-form-input
            size="sm"
            type="number"
            min="0"
            max="100"
            v-model="form.exam6"
            @change="changeExam"
            placeholder="(0-100)"
            :value="form.exam6"
          ></b-form-input>
          <span>{{ errors[0] }}</span>
        </template>
      </validation-provider>
    </td>
    <td>
      <textarea
        v-model="form.temdeglel"
        placeholder="Тэмдэглэл"
        value="form.temdeglel"
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="1"
      ></textarea>
    </td>
    <td>
      <b-badge variant="light">
        {{ form.examAverage.toFixed(1) }}
        -(
        <span v-if="examChange">{{ symbolGrade }}</span>
        <span
          v-if="!examChange"
          :symbolGrade="symbolGradeCheck(form.examAverage)"
        >
          {{ symbolGrade }}
        </span>
        )
      </b-badge>
    </td>
    <td>
      <b-badge variant="success">
        <b-icon
          icon="cloud-upload"
          aria-hidden="true"
          @click.prevent="changeNumber"
          variant="link"
          class="button"
        ></b-icon>
      </b-badge>
    </td>
  </tr>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  props: [
    "tooluur",
    "getCourseStudents",
    "changeQuarterId",
    "school_id",
    "teacher_id",
    "student_id",
    "course_id",
    "quarter_id",
    "exam_id",
    "school_class_id",
  ],
  data() {
    return {
      loading: false,
      symbolGrade: "",
      form: new Form({
        id: "",
        school_id: "",
        teacher_id: "",
        student_id: "",
        course_id: "",
        quarter_id: "",
        exam_id: "",
        school_class_id: "",
        daalgar_number: "",
        exam1: 0,
        exam2: 0,
        exam3: 0,
        exam4: 0,
        exam5: 0,
        exam6: 0,
        temdeglel: "",
        examAverage: 0,
      }),
      examChange: false,
    };
  },
  components: {
    ValidationProvider,
  },
  watch: {
    exam_id: function (newVal, oldVal) {
      //   console.log(oldVal, newVal);
      //   <loading :loading="loading"></loading>
      this.getMyExamValue();
    },
    quarter_id: function (newVal, oldVal) {
      //   console.log(oldVal, newVal);
      this.getMyExamValue();
    },
    // "form.exam1": function (newValue, oldValue) {
    //   console.log(oldValue, newValue);
    //   this.form.examAverage =
    //     (this.form.newValue +
    //       this.form.exam2 +
    //       this.form.exam3 +
    //       this.form.exam1 +
    //       this.form.exam5 +
    //       this.form.exam6 +
    //       this.form.exam6 +
    //       this.form.exam7 +
    //       this.form.exam8) /
    //     8;
    // },
  },
  methods: {
    changeExam(e) {
      this.exam1Change = true;
      var exam11 = parseFloat(this.form.exam1);
      var exam12 = parseFloat(this.form.exam2);
      var exam13 = parseFloat(this.form.exam3);
      var exam14 = parseFloat(this.form.exam4);
      var exam15 = parseFloat(this.form.exam5);
      var exam16 = parseFloat(this.form.exam6);
      this.form.examAverage =
        (exam11 + exam12 + exam13 + exam14 + exam15 + exam16) / 6;
      this.symbolGradeCheck(this.form.examAverage);
    },
    changeNumber() {
      //   console.log(this.getCourseStudents);
      this.loading = true;
      this.form.school_id = this.school_id;
      this.form.teacher_id = this.teacher_id;
      this.form.student_id = this.getCourseStudents.student_id;
      this.form.course_id = this.course_id;
      this.form.quarter_id = this.quarter_id;
      this.form.exam_id = this.exam_id;
      this.form.school_class_id = this.school_class_id;
      this.form.daalgar_number = this.daalgar_number;
      this.form
        .post("/teacher/gradebehavior")
        .then((response) => {
          this.loading = false;
          //   console.log(response.data);
          // Fire.$emit("loadMyExamValue");
        })
        .catch((errors) => {
          Swal.fire({
            icon: "error",
            title: "Алдаа гарлаа",
            text: "Даалгаварт өгөх оноо 0-100 хооронд байна.",
            footer: "Алдаагаа засна уу?",
          });
          //   console.log(errors);
        });
      //   console.log("Тоо өөрчилсөн байна.");
    },
    getMyExamValue() {
      this.loading = true;
      this.form.school_id = this.school_id;
      this.form.teacher_id = this.teacher_id;
      this.form.student_id = this.getCourseStudents.student_id;
      this.form.course_id = this.course_id;
      this.form.quarter_id = this.quarter_id;
      this.form.exam_id = this.exam_id;
      this.form.school_class_id = this.school_class_id;
      //   this.form.daalgar_number = this.daalgar_number;
      this.form
        .post("/teacher/myBehavior")
        .then((response) => {
          if (response.data.myExam) {
            // console.log(response.data.myExam);
            this.form.fill(response.data.myExam);
            this.form.exam1 = response.data.myExam.b_a1;
            this.form.exam2 = response.data.myExam.b_a2;
            this.form.exam3 = response.data.myExam.b_b1;
            this.form.exam4 = response.data.myExam.b_b2;
            this.form.exam5 = response.data.myExam.b_c1;
            this.form.exam6 = response.data.myExam.b_c2;
            this.calcAverage(response.data.myExam);
          } else {
            // console.log(response.data.myExam);
            this.form.exam1 = 0;
            this.form.exam2 = 0;
            this.form.exam3 = 0;
            this.form.exam4 = 0;
            this.form.exam5 = 0;
            this.form.exam6 = 0;
            this.form.examAverage = 0;
            this.form.temdeglel = "";
          }
          this.loading = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    calcAverage: function (examVues) {
      //   console.log(examVues);
      var exam11 = parseFloat(examVues.b_a1);
      var exam12 = parseFloat(examVues.b_a2);
      var exam13 = parseFloat(examVues.b_b1);
      var exam14 = parseFloat(examVues.b_a2);
      var exam15 = parseFloat(examVues.b_c1);
      var exam16 = parseFloat(examVues.b_c2);

      this.form.examAverage =
        (exam11 + exam12 + exam13 + exam14 + exam15 + exam16) / 6;
      //   console.log(this.form);
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
  },
  created() {
    this.getMyExamValue();
    Fire.$on("loadMyExamValue", () => {
      this.getMyExamValue();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 30px !important;
  height: 30px;
  object-fit: cover;
}
</style>
