<template>
  <!-- The modal -->
  <b-modal size="xl" id="my-modal" title="Эцэг эхийн мэдээлэл" hide-footer>
    <b-form-group
      v-if="this.parentDataProps == null"
      id="input-check_email"
      label="Эцэг эх өмнө нь бүртгэлтэй эсэхийг имэйл-ээр хайж шалгах"
      label-for="check_email"
    >
      <b-form-input
        id="check_email"
        v-model="check_email"
        placeholder="Имэйл"
        type="email"
        required
        class="[ 'form-control', { 'is-invalid': form.errors.has('check_email'), }, ]"
      ></b-form-input>
    </b-form-group>
    <!-- form start -->
    <form>
      <div class="row" v-show="editMode">
        <div
          class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"
        >
          <div class="card bg-light d-flex flex-fill mr-1">
            <div class="card-header text-muted border-bottom-0">
              Эцгийн мэдээлэл
            </div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-7">
                  <h2 class="lead">
                    <b
                      >{{ form.parent_father_ovog }}
                      {{ form.parent_father_name }}</b
                    >
                  </h2>
                  <p class="text-muted text-sm">
                    {{ form.parent_father_mergejil }},
                    {{ form.parent_father_ajil_gazar }}
                  </p>
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-users"></i
                      ></span>
                      Ургийн овог: {{ form.parent_father_urgiin_ovog }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-building"></i
                      ></span>
                      Хаяг: {{ form.parent_father_address }}
                    </li>
                    <li class="small">
                      <span class="fa-li">
                        <i class="fab fa-facebook"></i>
                      </span>
                      FB: {{ form.parent_father_social }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-phone"></i
                      ></span>
                      Утас: {{ form.parent_father_phone_number }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-envelope"></i
                      ></span>
                      Цахим шуудан: {{ form.parent_father_email }}
                    </li>
                    <li class="small">
                      <span class="fa-li"><i class="fa fa-hashtag"></i></span>
                      РД: {{ form.parent_father_reg }}
                    </li>
                    <li class="small">
                      <span class="fa-li"><i class="fa fa-id-card"></i></span>
                      Яс үндэс: {{ form.parent_father_yas_und }}
                    </li>
                  </ul>
                </div>
                <div class="col-5 text-center">
                  <img
                    v-if="getSelectedFatherImg != null"
                    :src="getSelectedFatherImg"
                    alt="user-avatar"
                    class="img-circle img-fluid"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-circle img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row text-right">
                <form
                  @submit.prevent="uploadFatherImg"
                  enctype="multipart/form-data"
                >
                  <input
                    type="file"
                    name="getSelectedFatherImg"
                    @change="getFatherImg"
                    accept="image/png,image/gif,image/jpeg"
                    required
                  />
                  <button
                    type="submit"
                    class="btn btn-success"
                    v-if="loadedFather"
                  >
                    Хадгалах
                  </button>
                </form>
                <button
                  class="btn btn-danger ml-2"
                  @click.prevent="uploadFatherImgCancel"
                  v-if="loadedFather"
                >
                  Цуцлах
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"
        >
          <div class="card bg-light d-flex flex-fill ml-1">
            <div class="card-header text-muted border-bottom-0">
              Эхийн мэдээлэл
            </div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-7">
                  <h2 class="lead">
                    <b
                      >{{ form.parent_mother_ovog }}
                      {{ form.parent_mother_name }}</b
                    >
                  </h2>
                  <p class="text-muted text-sm">
                    {{ form.parent_mother_mergejil }},
                    {{ form.parent_mother_ajil_gazar }}
                  </p>
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-users"></i
                      ></span>
                      Ургийн овог: {{ form.parent_mother_urgiin_ovog }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-building"></i
                      ></span>
                      Хаяг: {{ form.parent_mother_address }}
                    </li>
                    <li class="small">
                      <span class="fa-li">
                        <i class="fab fa-facebook"></i>
                      </span>
                      FB: {{ form.parent_mother_social }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-phone"></i
                      ></span>
                      Утас: {{ form.parent_mother_phone_number }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-envelope"></i
                      ></span>
                      Цахим шуудан: {{ form.parent_mother_email }}
                    </li>
                    <li class="small">
                      <span class="fa-li"><i class="fa fa-hashtag"></i></span>
                      РД: {{ form.parent_mother_reg }}
                    </li>
                    <li class="small">
                      <span class="fa-li"><i class="fa fa-id-card"></i></span>
                      Яс үндэс: {{ form.parent_mother_yas_und }}
                    </li>
                  </ul>
                </div>
                <div class="col-5 text-center">
                  <img
                    v-if="getSelectedMotherImg != null"
                    :src="getSelectedMotherImg"
                    alt="user-avatar"
                    class="img-circle img-fluid"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-circle img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row text-right">
                <form
                  @submit.prevent="uploadMotherImg"
                  enctype="multipart/form-data"
                >
                  <input
                    type="file"
                    name="getSelectedMotherImg"
                    @change="getMotherImg"
                    accept="image/png,image/gif,image/jpeg"
                    required
                  />
                  <button
                    type="submit"
                    class="btn btn-success"
                    v-if="loadedMother"
                  >
                    Хадгалах
                  </button>
                </form>
                <button
                  class="btn btn-danger ml-2"
                  @click.prevent="uploadMotherImgCancel"
                  v-if="loadedMother"
                >
                  Цуцлах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="!editMode">
        <div class="col-lg-6">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Эцгийн талаарх мэдээлэл</h3>
            </div>
            <!-- /.card-header -->

            <div class="card-body">
              <b-form-group
                id="input-group-1"
                label="Ургийн овог:"
                label-for="parent_father_urgiin_ovog"
              >
                <b-form-input
                  id="parent_father_urgiin_ovog"
                  v-model="form.parent_father_urgiin_ovog"
                  placeholder="Ургийн овог"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has(
                        'parent_father_urgiin_ovog'
                      ),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_ovog"
                label="Овог:"
                label-for="parent_father_ovog"
              >
                <b-form-input
                  id="parent_father_ovog"
                  v-model="form.parent_father_ovog"
                  placeholder="Овог"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_ovog'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_name"
                label="Нэр:"
                label-for="parent_father_name"
              >
                <b-form-input
                  id="parent_father_name"
                  v-model="form.parent_father_name"
                  placeholder="Нэр"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_name'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_reg"
                label="Регистрийн дугаар:"
                label-for="parent_father_reg"
              >
                <b-form-input
                  id="parent_father_reg"
                  v-model="form.parent_father_reg"
                  placeholder="Регистрийн дугаар"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_reg'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_yas_und"
                label="Яс үндэс:"
                label-for="parent_father_yas_und"
              >
                <b-form-input
                  id="parent_father_yas_und"
                  v-model="form.parent_father_yas_und"
                  placeholder="Яс үндэс"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_yas_und'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_mergejil"
                label="Мэргэжил:"
                label-for="parent_father_mergejil"
              >
                <b-form-input
                  id="parent_father_mergejil"
                  v-model="form.parent_father_mergejil"
                  placeholder="Мэргэжил"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_mergejil'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_bol"
                label="Боловсрол:"
                label-for="parent_father_bol"
              >
                <b-form-input
                  id="parent_father_bol"
                  v-model="form.parent_father_bol"
                  placeholder="Боловсрол"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_bol'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_ajil_gazar"
                label="Ажлын газар:"
                label-for="parent_father_ajil_gazar"
              >
                <b-form-input
                  id="parent_father_ajil_gazar"
                  v-model="form.parent_father_ajil_gazar"
                  placeholder="Ажлын газар"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_ajil_gazar'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_phone_number"
                label="Утасны дугаар:"
                label-for="parent_father_phone_number"
              >
                <b-form-input
                  id="parent_father_phone_number"
                  v-model="form.parent_father_phone_number"
                  placeholder="Утасны дугаар"
                  type="number"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has(
                        'parent_father_phone_number'
                      ),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_email"
                label="Имэйл:"
                label-for="parent_father_email"
              >
                <b-form-input
                  id="parent_father_email"
                  v-model="form.parent_father_email"
                  placeholder="Имэйл"
                  type="email"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_email'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_address"
                label="Гэрийн хаяг:"
                label-for="parent_father_address"
              >
                <b-form-input
                  id="parent_father_address"
                  v-model="form.parent_father_address"
                  placeholder="Гэрийн хаяг"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_address'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_father_social"
                label="Аавын фэйсбүүк хаягийн холбоос:"
                label-for="parent_father_social"
              >
                <b-form-input
                  id="parent_father_social"
                  v-model="form.parent_father_social"
                  placeholder="Аавын фэйсбүүк хаягийн холбоос"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_father_social'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Эхийн талаарх мэдээлэл</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <b-form-group
                id="input-group-1"
                label="Ургийн овог:"
                label-for="parent_mother_urgiin_ovog"
              >
                <b-form-input
                  id="parent_mother_urgiin_ovog"
                  v-model="form.parent_mother_urgiin_ovog"
                  placeholder="Ургийн овог"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has(
                        'parent_mother_urgiin_ovog'
                      ),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_ovog"
                label="Овог:"
                label-for="parent_mother_ovog"
              >
                <b-form-input
                  id="parent_mother_ovog"
                  v-model="form.parent_mother_ovog"
                  placeholder="Овог"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_ovog'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_name"
                label="Нэр:"
                label-for="parent_mother_name"
              >
                <b-form-input
                  id="parent_mother_name"
                  v-model="form.parent_mother_name"
                  placeholder="Нэр"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_name'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_reg"
                label="Регистрийн дугаар:"
                label-for="parent_mother_reg"
              >
                <b-form-input
                  id="parent_mother_reg"
                  v-model="form.parent_mother_reg"
                  placeholder="Регистрийн дугаар"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_reg'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_yas_und"
                label="Яс үндэс:"
                label-for="parent_mother_yas_und"
              >
                <b-form-input
                  id="parent_mother_yas_und"
                  v-model="form.parent_mother_yas_und"
                  placeholder="Яс үндэс"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_yas_und'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_mergejil"
                label="Мэргэжил:"
                label-for="parent_mother_mergejil"
              >
                <b-form-input
                  id="parent_mother_mergejil"
                  v-model="form.parent_mother_mergejil"
                  placeholder="Мэргэжил"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_mergejil'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_bol"
                label="Боловсрол:"
                label-for="parent_mother_bol"
              >
                <b-form-input
                  id="parent_mother_bol"
                  v-model="form.parent_mother_bol"
                  placeholder="Боловсрол"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_bol'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_ajil_gazar"
                label="Ажлын газар:"
                label-for="parent_mother_ajil_gazar"
              >
                <b-form-input
                  id="parent_mother_ajil_gazar"
                  v-model="form.parent_mother_ajil_gazar"
                  placeholder="Ажлын газар"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_ajil_gazar'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_phone_number"
                label="Утасны дугаар:"
                label-for="parent_mother_phone_number"
              >
                <b-form-input
                  id="parent_mother_phone_number"
                  v-model="form.parent_mother_phone_number"
                  placeholder="Утасны дугаар"
                  type="number"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has(
                        'parent_mother_phone_number'
                      ),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_email"
                label="Имэйл:"
                label-for="parent_mother_email"
              >
                <b-form-input
                  id="parent_mother_email"
                  v-model="form.parent_mother_email"
                  placeholder="Имэйл"
                  type="email"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_email'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_address"
                label="Гэрийн хаяг:"
                label-for="parent_mother_address"
              >
                <b-form-input
                  id="parent_mother_address"
                  v-model="form.parent_mother_address"
                  placeholder="Гэрийн хаяг"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_address'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-parent_mother_social"
                label="Ээжийн фэйсбүүк хаягийн холбоос:"
                label-for="parent_mother_social"
              >
                <b-form-input
                  id="parent_mother_social"
                  v-model="form.parent_mother_social"
                  placeholder="Ээжийн фэйсбүүк хаягийн холбоос"
                  required
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('parent_mother_social'),
                    },
                  ]"
                ></b-form-input>
              </b-form-group>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
      <div class="row pl-2">
        <b-button
          v-if="this.parentDataProps == null"
          @click.prevent="storeparentInfo()"
          v-show="!editMode"
          variant="success"
          type="submit"
        >
          Хадгалах
        </b-button>
        <b-button
          v-if="this.parentDataProps != null"
          @click.prevent="updateParentInfo()"
          v-show="editParent"
          variant="primary"
          type="submit"
        >
          Засах
        </b-button>
        <b-button
          v-if="this.parentDataProps == null"
          @click.prevent="connectParentInfo()"
          v-show="editMode"
          variant="success"
          type="submit"
        >
          Холбох
        </b-button>
        <b-button
          v-if="this.parentDataProps != null"
          @click.prevent="changeMode()"
          v-show="editMode"
          variant="primary"
          class="ml-2"
        >
          Эцэг эх засах
        </b-button>
        <b-button
          v-if="this.parentDataProps != null"
          @click.prevent="addNewParent()"
          v-show="editMode"
          variant="success"
          class="ml-2"
        >
          Өөр эцэг эх холбох
        </b-button>
        <b-button
          @click.prevent="cancel()"
          variant="danger"
          type="reset"
          class="ml-2"
        >
          Цуцлах
        </b-button>
        <b-button @click.prevent="resetPassword()" variant="info" class="ml-2">
          Нууц үг солих
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  props: ["forParentChild", "parentDataProps"],
  data() {
    return {
      loadedFather: false,
      loadedMother: false,
      getSelectedFatherImg: "",
      getSelectedMotherImg: "",
      editMode: false,
      editParent: false,
      show: false,
      check_email: null,
      form: new Form({
        id: "",
        user_id: "",
        user_name: "",
        user_email: "",
        student_profile_id: "",
        schoolYearId: "",
        parent_father_urgiin_ovog: "",
        parent_father_ovog: "",
        parent_father_name: "",
        parent_father_reg: "",
        parent_father_yas_und: "",
        parent_father_mergejil: "",
        parent_father_bol: "",
        parent_father_ajil_gazar: "",
        parent_father_phone_number: "",
        parent_father_email: "",
        parent_father_address: "",
        parent_father_profile_photo_url: "",
        parent_father_social: "",
        parent_mother_urgiin_ovog: "",
        parent_mother_ovog: "",
        parent_mother_name: "",
        parent_mother_reg: "",
        parent_mother_yas_und: "",
        parent_mother_mergejil: "",
        parent_mother_bol: "",
        parent_mother_ajil_gazar: "",
        parent_mother_phone_number: "",
        parent_mother_email: "",
        parent_mother_address: "",
        parent_mother_profile_photo_url: "",
        parent_mother_social: "",
      }),
    };
  },
  watch: {
    parentDataProps: function (val) {
      this.editParent = false;
      this.form.reset();

      if (this.parentDataProps != null) {
        this.editMode = true;
        this.form.fill(this.parentDataProps);
        this.getSelectedFatherImg = this.form.parent_father_profile_photo_url;
        this.getSelectedMotherImg = this.form.parent_mother_profile_photo_url;
      } else {
        this.editMode = false;
      }
    },
    check_email(after, before) {
      if (this.check_email != null)
        if (this.check_email.length > 3) this.checkParent();
        else {
          this.editMode = false;
          this.form.reset();
        }
    },
  },
  methods: {
    uploadFatherImg() {
      axios
        .post("/teacher/uploadParentImg/" + this.form.id, {
          getSelectedFatherImg: this.getSelectedFatherImg,
        })
        .then((res) => {
          Fire.$emit("loadStart");
          this.loadedFather = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadFatherImgCancel() {
      this.loadedFather = false;
      this.getSelectedFatherImg = null;
    },
    getFatherImg(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.getSelectedFatherImg = e.target.result;
      };
      this.loadedFather = true;
    },
    uploadMotherImg() {
      axios
        .post("/teacher/uploadParentImg/" + this.form.id, {
          getSelectedMotherImg: this.getSelectedMotherImg,
        })
        .then((res) => {
          Fire.$emit("loadStart");
          this.loadedMother = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadMotherImgCancel() {
      this.loadedMother = false;
      this.getSelectedMotherImg = null;
    },
    getMotherImg(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.getSelectedMotherImg = e.target.result;
      };
      this.loadedMother = true;
    },
    addNewParent() {
      this.parentDataProps = null;
      this.check_email = null;
      this.editParent = false;
      this.editMode = false;
      this.form.reset();
    },
    changeMode() {
      this.editParent = true;
      this.editMode = false;
    },
    checkParent() {
      this.editParent = false;
      this.editMode = false;
      this.form.reset();
      axios
        .get("/teacher/getParent", {
          params: { email: this.check_email },
        })
        .then((response) => {
          if (response.data.parentData != null) {
            this.editMode = true;
            this.form.fill(response.data.parentData);
          }
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.check_email = null;
      this.editMode = false;
      this.editParent = false;
      this.form.reset();
    },
    resetPassword(schoolParent) {
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: Selbe@2023 (Сүүлийн 4 орон нь тухайн он байна.)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/teacher/passChangeParent/" + this.form.user_id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },

    updateParentInfo() {
      this.form.student_profile_id = this.forParentChild.profile_id;
      this.form
        .put("/teacher/updateParentInfo/" + this.form.id)
        .then((response) => {
          this.$toastr.s(
            "Суралцагчид эцэг эхийг амжилттай бүтгэлээ.",
            "Амжилттай"
          );
          //   Fire.$emit("loadSchoolStudents");
          this.check_email = null;
          this.editMode = false;
          this.editParent = false;
          this.form.reset();
          this.$bvModal.hide("my-modal");
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчид эцэг эхийг бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    storeparentInfo() {
      //   console.log("ok");
      this.form.student_profile_id = this.forParentChild.profile_id;
      this.form
        .post("/teacher/createParent")
        .then((response) => {
          this.$toastr.s(
            "Суралцагчид эцэг эхийг амжилттай бүтгэлээ.",
            "Амжилттай"
          );
          //   Fire.$emit("loadSchoolStudents");
          this.check_email = null;
          this.editMode = false;
          this.editParent = false;
          this.form.reset();
          this.$bvModal.hide("my-modal");
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчид эцэг эхийг бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    connectParentInfo() {
      this.form.student_profile_id = this.forParentChild.profile_id;
      this.form
        .post("/teacher/connectParent")
        .then((response) => {
          this.$toastr.s(
            "Суралцагчид эцэг эхийг амжилттай бүтгэлээ.",
            "Амжилттай"
          );
          this.check_email = null;
          this.editMode = false;
          this.editParent = false;
          this.form.reset();
          this.$bvModal.hide("my-modal");
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчид эцэг эхийг бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancel() {
      //   console.log("cancel");
      this.check_email = null;
      this.editMode = false;
      this.editParent = false;
      this.form.reset();
      this.$bvModal.hide("my-modal");
    },
    onReset(event) {
      //   console.log("Reset дарагдлаа", event);
    },
  },

  created() {
    // console.log("parentDataProps", this.parentDataProps);
  },
};
</script>

<style></style>
