<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Соёл хүмүүжлийн ажил</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :searchable="true"
                    :close-on-select="false"
                    :show-labels="true"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="primary" @click.prevent="getStudentProjects()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <b-overlay :show="devIsLoaded" rounded="sm">
          <b-card-group deck class="p-3 my-5">
            <div
              class="col-lg-3 mb-3"
              v-for="project in studentProjects"
              :key="project.id"
            >
              <div class="card">
                <div class="ribbon ribbon-bottom-right"></div>
                <div class="card-container">
                  <div class="box">
                    <img :src="project.image" alt="Avatar" class="image-card" />
                    <div class="ribbon ribbon-top-right">
                      <span>{{ project.time_type }}</span>
                    </div>
                  </div>
                  <div class="overlay text-center">
                    <div class="hoverText">
                      <div>
                        <small>
                          <span v-show="project.time_type != 'Тогтмол'">
                            <i class="fas fa-calendar-alt"></i>
                            <b
                              >{{ project.start_date | dateYearMonthDay }} -
                              {{ project.end_date | dateYearMonthDay }}
                            </b>
                            <br />
                          </span>
                          <span>
                            <i class="far fa-clock"></i>
                            <b>
                              Өдөр: {{ project.day }}, Цаг: {{ project.startTime }} -
                              {{ project.endTime }}
                            </b>
                            <br />
                          </span>
                          <div class="hoverBtn">
                            <b-button
                              size="sm"
                              class="mt-2 mr-2"
                              href="#"
                              variant="light"
                              @click.prevent="showComment(project)"
                              >Эсээ бичих</b-button
                            >
                          </div>
                          {{ project.description }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      <small class="text-muted">
                        {{ project.name.slice(0, 30) }}
                      </small>
                    </h5>
                    <p
                      class="card-text"
                      style="text-align: justify; height: 0.8rem; word-break: break-all"
                    >
                      <small class="text-muted">
                        Анги:
                        <b>
                          <span>
                            {{ project.scope }}
                          </span>
                        </b>
                        Өдөр:
                        <b>
                          <span>
                            {{ project.day }}
                          </span>
                        </b>
                      </small>
                    </p>
                  </div>
                  <div class="card-footer pt-0">
                    <small class="text-muted">
                      <span v-if="project.choosed == 1">
                        <b-icon icon="check-square-fill" variant="success"></b-icon>
                        <b>БҮРТГҮҮЛСЭН</b>
                      </span>
                      <i class="far fa-calendar-check ml-2"></i>
                      <b>{{ project.reg_close_date | dateYearMonthDay }}</b>
                      <b-badge
                        v-if="project.limit == project.registered"
                        pill
                        class="float-right mt-2"
                        variant="danger"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                      <b-badge v-else pill class="float-right mt-2" variant="dark"
                        >{{ project.limit }} / {{ project.registered }}</b-badge
                      >
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </b-card-group>
        </b-overlay>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ бичих"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp"><b>Хичээл: </b>{{ form.name }}</span>
            <span class="direct-chat-timestamp float-right"
              ><b>Эхлэх өдөр: </b>
              {{ form.start_date }}
              <br />
              <span> <b>Дуусах хоёр:</b> {{ form.end_date }} </span></span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <small class="text-muted"
              >Хамгийн багадаа 25 үг бичнэ үү! Үгийн тоо:
              {{ formComment.comment.replace(/<\/?[^>]+(>|$)/g, "").length }}</small
            >
            <div class="form-group">
              <quill-editor
                ref="myQuillEditor"
                v-model="formComment.comment"
                :options="editorOption"
              />
            </div>
            <div class="form-group">
              <b-button
                :disabled="formComment.comment.replace(/<\/?[^>]+(>|$)/g, '').length < 25"
                size="sm"
                href="#"
                variant="primary"
                @click.prevent="sendMessage"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                {{ editCommentMode ? "Засах" : "Илгээх" }}
              </b-button>
              <b-button
                v-if="editCommentMode"
                :disabled="formComment.comment.replace(/<\/?[^>]+(>|$)/g, '').length < 25"
                size="sm"
                href="#"
                variant="light"
                @click.prevent="cancelEditComm"
              >
                Болих
              </b-button>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card class="p-1 text-justify">
                      <b-badge
                        variant="secondary"
                        @click.prevent="editComment(comm)"
                        role="button"
                      >
                        <b-icon icon="pencil" aria-hidden="true"></b-icon>
                      </b-badge>
                      <b-badge
                        variant="danger"
                        @click.prevent="deleteComment(comm)"
                        role="button"
                      >
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                      </b-badge>
                      <div v-html="comm.comment" />
                    </b-card>
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  data() {
    return {
      devIsLoaded: true,
      studentProjects: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,
      form: new Form({
        id: null,
        status: "",
        project_type: "",
        time_type: "",
        scopeList: [],
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        limit: "",
        registered: "",
        reg_close_date: "",
        day: "",
        startTime: "",
        endTime: "",
        isTwo: "",
        day2: "",
        startTime2: "",
        endTime2: "",
        created_at: "",
      }),
      beforeComments: [],
      formComment: new Form({
        id: null,
        type: "project",
        comment: "",
      }),
      editorOption: {
        placeholder: "СХА-ын талаарх эсээ бичих ...",
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.5, // default
            maxWidth: 550, // default
            imageType: ["image/jpeg", "image/png", "image/jpg"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
      editCommentMode: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    checkCloseDate(pDate) {
      return moment(moment(new Date()).format()).isBefore(moment(pDate).format());
    },
    getStudentProjects() {
      this.devIsLoaded = true;
      axios
        .post("/project_student/getStudentProjects", {
          project_type: "СХА",
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          this.studentProjects = response.data.myProjects;
          this.devIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //======================== Бүртгэгдсэн төлөвт шилжүүлэх ================================
    chooseProject(project) {
      Swal.fire({
        title: "Соёл хүмүүжлийн ажил-г сонгох гэж байна?",
        text:
          'Та "' +
          project.name +
          '" соёл хүмүүжлийн ажил сонгох гэж байна. Итгэлтэй байна уу?',
        showDenyButton: true,
        imageUrl: project.image,
        imageHeight: 200,
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#41b882",
        denyButtonColor: "#595959",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Соёл хүмүүжлийн ажил сонгож байна...";
          axios
            .put("/project_student/updateChose/" + project.id)
            .then((response) => {
              if (response.data.error == "error") {
                Swal.fire({
                  icon: "error",
                  title: "Сонгох боломжгүй...",
                  text: response.data.errorMsj,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Амжилттай",
                  text: response.data.errorMsj,
                });
                Fire.$emit("getMyProjects");
              }
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    toggleSelect() {
      console.log("filter department");
    },
    showComment(project) {
      this.beforeComments = [];
      this.form.reset();
      this.form.fill(project);
      this.getMessage();
      this.$bvModal.show("modal-project-comment");
    },
    getMessage() {
      axios
        .post("/project_student/getStudentComment/" + this.form.id, {
          type: "project",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      this.formComment
        .post("/project_student/sendComment/" + this.form.id)
        .then((response) => {
          this.cancelEditComm();
          this.$toastr.s("Таны сэтгэгдэл амжилттай илгээгдлээ.", "Амжилттай");
          this.getMessage();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editComment(comm) {
      this.formComment.reset();
      this.formComment.fill(comm);
      this.editCommentMode = true;
    },
    cancelEditComm() {
      this.formComment.reset();
      this.editCommentMode = false;
    },
    deleteComment(comm) {
      Swal.fire({
        title: "Эсээ устгах!",
        html: "Эсээ устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Цэс хасаж байна...";
          axios
            .get("/project_student/deleteComment/" + comm.id)
            .then(() => {
              this.$toastr.s("Эсээ амжилттай устгалаа.", "Амжилттай");
              this.getMessage();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  created() {
    this.getStudentProjects();
    Fire.$on("getMyProjects", () => {
      this.getStudentProjects();
    });
  },
};
</script>

<style></style>
