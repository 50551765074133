<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Цалингийн шатлал бүртгэх</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addSalaryGrade()"
                    >Бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      <b>Бүртгэгдсэн шатлалын тоо:</b>
                      {{
                        salaryGrades.length
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>Шүүсэн шатлалын тоо:</b>
                      {{ filteredSalaryGradesCount }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="salaryGradesStatusLoaded" rounded="sm">
                    <b-row>
                      <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
</b-form-select>

<b-form-select v-model="sortDesc" :disabled="!sortBy" :aria-describedby="ariaDescribedby" size="sm" class="w-25">
  <option :value="false">Asc</option>
  <option :value="true">Desc</option>
</b-form-select>
</b-input-group>
</b-form-group>
</b-col> -->
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageDocuments"
                            :options="pageOptionsDocuments"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterDocuments"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterDocuments"
                                @click="filterDocuments = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageDocuments"
                      :total-rows="totalRowsDocumentStatuses"
                      :per-page="perPageDocuments"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="salaryGrades"
                      :fields="fieldsActiveDocument"
                      :current-page="currentPageDocuments"
                      :per-page="perPageDocuments"
                      style="font-size: 80%"
                      :filter-ignored-fields="filterIgnoredFieldsDocuments"
                      :filter="filterDocuments"
                      :filter-included-fields="filterOnDocuments"
                      @filtered="onFilteredDocuments"
                    >
                      <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(curri_dep_id)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 4rem"
                        >
                          {{ filterDepCurrTeam(data.item.curri_dep_id) }}
                        </b-badge>
                      </template>
                      <template #cell(salary_rank)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 4rem"
                        >
                          {{ filterRank(data.item.salary_rank) }}
                          зэрэг
                        </b-badge>
                      </template>
                      <template #cell(salary_scale)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 2rem"
                        >
                          {{ data.item.salary_scale }}
                        </b-badge>
                      </template>
                      <template #cell(my_skills)="data">
                        <b-badge
                          variant="warning"
                          class="text-wrap"
                          style="width: 10rem"
                          v-if="data.item.my_sal_skill_grade.length != 0"
                        >
                          <span
                            v-for="(skill, index) in data.item
                              .my_sal_skill_grade"
                            :key="index"
                          >
                            {{ skill.my_skill_info.name }} <br />
                          </span>
                        </b-badge>
                        <b-badge
                          variant="info"
                          class="text-wrap"
                          style="width: 10rem"
                          v-else
                          >Ур чадвар бүртгэгдээгүй</b-badge
                        >
                      </template>

                      <template #cell(created_at)="data">
                        {{ data.item.created_at | dateYearMonthDay }}
                      </template>
                      <template #cell(updated_at)="data">
                        {{ data.item.updated_at | dateYearMonthDay }}
                      </template>
                      <template #cell(actions)="data">
                        <div>
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editSalaryGrade(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteSalaryGrade(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                          <a
                            v-if="userInfo.name == 'cipalprin'"
                            href="#"
                            class="badge bg-info"
                            @click.prevent="skillsConfigEmployee(data.item)"
                          >
                            <i class="fas fa-tasks"></i> тохиргоо
                          </a>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong>Устгагдсан шатлал</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт шатлалын тоо:
                      {{ salaryGradesDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="salaryGradesStatusLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveRooms"
                            :options="pageOptionsInactiveDocumentStatus"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveRooms"
                      :total-rows="totalRowsInactiveDocumentStatuses"
                      :per-page="perPageInactiveRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="salaryGradesDeleted"
                      :fields="fieldsInactiveDocumentStatus"
                      :current-page="currentPageInactiveRooms"
                      :per-page="perPageInactiveRooms"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(curri_dep_id)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 4rem"
                        >
                          {{ filterDepCurrTeam(data.item.curri_dep_id) }}
                        </b-badge>
                      </template>
                      <template #cell(salary_rank)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 4rem"
                        >
                          {{ filterRank(data.item.salary_rank) }}
                          зэрэг
                        </b-badge>
                      </template>
                      <template #cell(salary_scale)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 2rem"
                        >
                          {{ data.item.salary_scale }}
                          <!-- {{ filterScale() }} -->
                        </b-badge>
                      </template>

                      <template #cell(created_at)="data">
                        {{ data.item.created_at | dateYearMonthDay }}
                      </template>
                      <template #cell(updated_at)="data">
                        {{ data.item.updated_at | dateYearMonthDay }}
                      </template>
                      <template #cell(actions)="data">
                        <!-- v-if="userInfo.name == 'cipalprin'" -->
                        <div>
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="restoreSchoolSalaryGrade(data.item)"
                            ><i class="fas fa-trash-restore-alt"></i
                          ></a>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-salary-grades"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="formDocModalTitle"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModeDocumentStatus">
                    Бичиг баримтын хувилбар бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editModeDocumentStatus">
                    Бичиг баримтын хувилбар засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editModeDocumentStatus
                        ? createSchoolSalaryGrade()
                        : updateSchoolSalaryGrade()
                    "
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label>Хөтөлбөрийн баг сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.curri_dep_id"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameDepCurr"
                                track-by="id"
                                label="name"
                                placeholder="Сонголт хийх"
                                :options="departmentCurriculumTeams"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label>Албан тушаалын зэрэг сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.salary_rank"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameRank"
                                track-by="value"
                                label="name"
                                placeholder="Сонголт хийх"
                                :options="ranks"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  зэрэг сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label
                              >Албан тушаалын зэрэгт харьяалах шатлал
                              сонгох</label
                            >
                            <div class="row">
                              <multiselect
                                v-model="form.salary_scale"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameScale"
                                track-by="value"
                                label="name"
                                placeholder="Сонголт хийх"
                                :options="scales"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong>
                                  шатлал сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label>Шатлалын нэр</label>
                            <input
                              type="text"
                              v-model="form.salary_scale_name"
                              placeholder="Шатлалын нэр"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  form.errors.has('salary_scale_name'),
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label>Шатлалын товч нэр</label>
                            <input
                              type="text"
                              v-model="form.salary_scale_short_name"
                              placeholder="Шатлалын товч нэр"
                              class="form-control"
                              :class="{
                                'is-invalid': form.errors.has(
                                  'salary_scale_short_name'
                                ),
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-sm-12"
                          v-if="
                            userInfo.name == 'cipalprin' &&
                            editModeDocumentStatus
                          "
                        >
                          <div class="form-group">
                            <label>Ур чадварууд сонгох</label>
                            <div class="row">
                              <multiselect
                                v-model="form.skills"
                                :multiple="true"
                                deselect-label="Can't remove this value"
                                :custom-label="nameSkill"
                                track-by="id"
                                label="name"
                                placeholder="Сонголт хийх"
                                :options="skills"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="false"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong
                                    >{{ option.name }}({{
                                      option.my_own_skill_cat.name
                                    }})</strong
                                  >
                                  сонгогдлоо
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editModeDocumentStatus"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editModeDocumentStatus"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editModeDocumentStatus"
                        @click.prevent="cancelEdit"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editModeDocumentStatus"
                        @click.prevent="cancelCreate"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        size="xl"
        id="modal-salary-grades-skill-config"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        title="Тохиргоо хийх"
        class="modal fade"
        hide-footer
        @hide="cancelConfig"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">
                    Ур чадварыг ажилчдад тохируулах(Бүх роль дээр тохируулах.)
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <b-overlay :show="show" rounded="sm"> </b-overlay>
                  <form
                    @submit.prevent="configStoreSalaryGradeSkillEmployee()"
                    v-if="show1"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <b-container class="bv-example-row" fluid>
                            <b-row>
                              <b-col sm="8">
                                <multiselect
                                  v-model="selectedRoles"
                                  :options="roles"
                                  :multiple="true"
                                  track-by="id"
                                  :custom-label="customRoleName"
                                  :hideSelected="true"
                                  :searchable="true"
                                  :close-on-select="false"
                                  :show-labels="true"
                                  placeholder="Role сонгох"
                                  deselect-label="Хасах"
                                  select-label="Сонгох"
                                >
                                  <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                    ><strong> {{ option.name }}</strong
                                    ><strong> сонгогдлоо</strong></template
                                  >
                                </multiselect>
                              </b-col>
                              <b-col sm="4">
                                <button
                                  class="btn btn-primary"
                                  @click.prevent="allRolesSelect()"
                                >
                                  Бүх дүрийг сонгох
                                </button>
                              </b-col>
                            </b-row>
                          </b-container>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-primary">
                        Хадгалах
                      </button>

                      <button
                        class="btn btn-warning"
                        @click.prevent="cancelConfig"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      show: false,
      show1: true,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formDocModalTitle: "Цалингийн шатлал бүртгэх",
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        curri_dep_id: "", //хөтөлбөрийн баг
        pos_id: "", //Ажлын байр
        salary_rank: "", //зэрэглэл
        salary_scale: "", //шатлал
        salary_scale_name: "", //шатлалын нэр
        salary_scale_short_name: "", //шатлалын товч нэр
        skills: [], //Ур чадварууд
      }),
      ranks: [
        { name: "I", value: 1 },
        { name: "II", value: 2 },
        { name: "III", value: 3 },
        { name: "IV", value: 4 },
        { name: "V", value: 5 },
        { name: "VI", value: 6 },
        { name: "VII", value: 7 },
        { name: "VIII", value: 8 },
        { name: "IX", value: 9 },
        { name: "X", value: 10 },
        { name: "XI", value: 11 },
        { name: "XII", value: 12 },
        { name: "XIII", value: 13 },
        { name: "XIV", value: 14 },
        { name: "XV", value: 15 },
        { name: "XVI", value: 16 },
        { name: "XVII", value: 17 },
        { name: "XVIII", value: 18 },
        { name: "XIX", value: 19 },
        { name: "XX", value: 20 },
      ],
      scales: [
        { name: "Шатлалд хүрээгүй", value: 0 },
        { name: "Шатлал 1", value: 1 },
        { name: "Шатлал 1", value: 1 },
        { name: "Шатлал 2", value: 2 },
        { name: "Шатлал 3", value: 3 },
        { name: "Шатлал 4", value: 4 },
        { name: "Шатлал 5", value: 5 },
        { name: "Шатлал 6", value: 6 },
        { name: "Шатлал 7", value: 7 },
        { name: "Шатлал 8", value: 8 },
        { name: "Шатлал 9", value: 9 },
        { name: "Шатлал 10", value: 10 },
      ],
      //Цалингийн шатлал
      salaryGrades: [],
      salaryGradesDeleted: [],
      departmentCurriculumTeams: [],

      //   mySchoolDepartments: [],
      //   documentStatuses: [],
      //   documentClassifyItems: [],

      salaryGradesStatusLoaded: true,
      editModeDocumentStatus: false,
      editModeDocumentFiles: false,

      //b-table тохиргоо
      totalRowsDocumentStatuses: 1,
      currentPageDocuments: 1,
      perPageDocuments: 25,
      pageOptionsDocuments: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterDocuments: "",
      filterOnDocuments: [],
      filteredSalaryGradesCount: 0,
      filterIgnoredFieldsDocuments: [
        "my_doc_proccess",
        "my_doc_owner",
        "my_doc_department",
        // "my_doc_classify_item",
      ],
      fieldsActiveDocument: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "curri_dep_id",
          label: "Хөтөлбөрийн баг",
          sortable: true,
        },
        {
          key: "salary_rank",
          label: "Зэрэг",
          sortable: true,
        },

        {
          key: "salary_scale",
          label: "Шатлал",
          sortable: true,
        },
        {
          key: "salary_scale_name",
          label: "Шатлалын нэр",
          sortable: true,
        },
        {
          key: "salary_scale_short_name",
          label: "Шатлалын товч нэр",
          sortable: true,
        },
        {
          key: "my_skills",
          label: "Ур чадварууд",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveDocumentStatuses: 1,
      currentPageInactiveRooms: 1,
      perPageInactiveRooms: 25,
      pageOptionsInactiveDocumentStatus: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveDocumentStatus: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "curri_dep_id",
          label: "Хөтөлбөрийн баг",
          sortable: true,
        },
        {
          key: "salary_rank",
          label: "Зэрэг",
          sortable: true,
        },

        {
          key: "salary_scale",
          label: "Шатлал",
          sortable: true,
        },
        {
          key: "salary_scale_name",
          label: "Шатлалын нэр",
          sortable: true,
        },
        {
          key: "salary_scale_short_name",
          label: "Шатлалын товч нэр",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //Ур чадварууд
      roles: [],
      skills: [],
      passSkills: [],
      selectedRoles: "",
    };
  },
  components: { Multiselect },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },

    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fieldsActiveDocument
    //     .filter((f) => f.sortable)
    //     .map((f) => {
    //       return { text: f.label, value: f.key };
    //     });
    // },
  },
  methods: {
    filterDepCurrTeam(item) {
      //   console.log(item);
      if (item !== null) {
        let checkData = this.departmentCurriculumTeams.filter(
          (el) => el.id === item
        );
        // console.log(checkData[0].description);
        return checkData[0].description;
      } else {
        return "Хөтөлбөрийн баг бүртгэгдээгүй байна.";
      }
    },
    filterRank(item) {
      //   console.log(item);
      let checkData = this.ranks.filter((el) => el.value === item);
      //   console.log(checkData[0].name);
      return checkData[0].name;
    },
    filterScale(item) {
      //   console.log(item);
      let checkData = this.scales.filter((el) => el.value === item);
      //   console.log(checkData[0].name);
      return checkData[0].name;
    },
    getAllSalaryGrades() {
      this.salaryGradesStatusLoaded = true;
      axios
        .get("/hr/getAllSalaryGrades")
        .then((res) => {
          //   console.log(res);
          this.salaryGrades = res.data.salaryGrades;
          this.salaryGradesDeleted = res.data.salaryGradesDeleted;
          this.departmentCurriculumTeams = res.data.departmentCurriculumTeams;
          this.skills = res.data.skills;
          this.roles = res.data.roles;
          //   this.mySchoolDepartments = res.data.mySchoolDepartments;
          //   this.documentStatuses = res.data.documentStatuses;
          //   this.documentClassifyItems = res.data.documentClassifyItems;
          this.totalRowsDocumentStatuses = this.salaryGrades
            ? this.salaryGrades.length
            : 0;
          //   this.totalRowsInactiveDocumentStatuses = this.salaryGradesDeleted
          //     ? this.salaryGradesDeleted.length
          //     : 0;
          this.salaryGradesStatusLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //b-table тохирго
    onFilteredDocuments(filteredItems) {
      //   console.log(filteredItems);
      this.filteredSalaryGradesCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDocumentStatuses = filteredItems.length;
      this.currentPageDocuments = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveDocumentStatuses = filteredItems.length;
      this.currentPageInactiveRooms = 1;
    },

    addSalaryGrade() {
      this.form.reset();
      this.$bvModal.show("modal-create-salary-grades");
    },
    createSchoolSalaryGrade() {
      //   console.log("create");
      this.form
        .post("/hr/createSchoolSalaryGrade")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadSalaryGrades");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editSalaryGrade(item) {
      //   console.log(item);
      this.form.fill(item);

      let a = this.departmentCurriculumTeams.filter(
        (el) => el.id === item.curri_dep_id
      );
      this.form.curri_dep_id = a[0];
      let b = this.ranks.filter((el) => el.value === item.salary_rank);
      this.form.salary_rank = b[0];
      let c = this.scales.filter((el) => el.value === item.salary_scale);
      this.form.salary_scale = c[0];
      let s = [];
      this.skills.forEach((el) => {
        item.my_sal_skill_grade.forEach((el1) => {
          if (el.id == el1.s_id) {
            s.push(el);
          }
        });
      });
      this.form.skills = s;
      //   this.ranks.forEach((element) => {
      //     if (element.value == item) {
      //       this.form.salary_rank = element.salary_rank;
      //     }
      //   });
      this.editModeDocumentStatus = true;
      this.$bvModal.show("modal-create-salary-grades");
    },
    updateSchoolSalaryGrade() {
      //   console.log("udpate");
      this.form
        .put("/hr/updateSchoolSalaryGrade")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadSalaryGrades");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeDocumentStatus = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-salary-grades");
    },

    nameDepCurr({ name }) {
      return `${name}`;
    },
    nameSkill({ name, my_own_skill_cat }) {
      return `${name} (${my_own_skill_cat.name})`;
    },
    nameRank({ name }) {
      return `${name} зэрэг`;
    },
    nameScale({ name }) {
      return `${name} шатлал`;
    },

    deleteSalaryGrade(item) {
      //   console.log(item);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгах өгөгдлийг сайтар шалгана уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteSalaryGrade/" + item.id)
            .then((res) => {
              Swal.fire("Устгасан!", "Системээс устгалаа.", "success");
              Fire.$emit("loadSalaryGrades");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreSchoolSalaryGrade(item) {
      // console.log(item);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/restoreSchoolSalaryGrade/" + item.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadSalaryGrades");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },

    //Бүх ажилчид дээр тохиргоо хийх функц
    customRoleName({ name, description }) {
      return `${description}(${name})`;
    },
    skillsConfigEmployee(item) {
      // console.log(item);
      this.passSkills = item;
      this.$bvModal.show("modal-salary-grades-skill-config");
      // console.log("config");
    },
    allRolesSelect() {
      this.selectedRoles = this.roles;
    },

    configStoreSalaryGradeSkillEmployee() {
      this.show1 = false;
      this.show = true;
      axios
        .post(`/all_worker_functions/skillsConfigEmployee`, {
          item: this.passSkills,
          roles: this.selectedRoles,
        })
        .then((res) => {
          // console.log(res.data);

          this.show = false;
          Swal.fire(
            "Амжилттай тохирууллаа!",
            "Системд ур чадварыг тохируулсан.",
            "success"
          );
          this.cancelConfig();
          this.show1 = true;
        })
        .catch((err) => {
          Swal.fire(
            "Тохируулж чадсангүй!",
            "Системд  ур чадварыг тохируулж чадсангүй.",
            "error"
          );
          // console.log(err);
        });
    },
    cancelConfig() {
      this.selectedRoles = "";
      this.passSkills = [];
      this.$bvModal.hide("modal-salary-grades-skill-config");
    },
  },
  watch: {
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.room_owner_employees = "";
    //   this.form.room_plate_face = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/manager/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
  },
  created() {
    this.getAllSalaryGrades();
    Fire.$on("loadSalaryGrades", () => {
      this.getAllSalaryGrades();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}

.cardFile {
  border-width: 1px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
