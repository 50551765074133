<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Хүлээлгийн жагсаалт</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="schoolClassesCheck"
                    text-field="text"
                    value-field="value"
                    size="sm"
                  >
                  </b-form-select>
                  <has-error :form="form" field="value"></has-error>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllEntrants"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap" style="font-size: 80%">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Төлөв</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Цахим шуудан</th>
                <th>Анги</th>
                <th>С/З санал</th>
                <th>Холбоо барих мэдээлэл</th>
                <th>Үүсгэсэн</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody
              v-for="(schoolEntrant, index) in schoolentrants"
              :key="schoolEntrant.user_id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-warning"
                    >БАТАЛСАН</span
                  >
                  <span
                    v-if="schoolEntrant.status1 === 'registered'"
                    class="badge bg-secondary"
                    >БҮРТГЭГДСЭН</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    v-if="schoolEntrant.study_class === '-1'"
                  >
                    Цэцэрлэг
                  </b-badge>
                  <b-badge
                    variant="success"
                    class="text-wrap"
                    v-else-if="schoolEntrant.study_class === '0'"
                  >
                    Бэлтгэл анги
                  </b-badge>
                  <b-badge variant="info" class="text-wrap" v-else
                    >{{ schoolEntrant.study_class }} анги
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.psychologist_offer === 1"
                    >Элсүүлэх</b-badge
                  >
                  <b-badge variant="info" v-else>Элсүүлэхгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="info"
                    class="text-wrap"
                    style="width: 8rem"
                    v-if="schoolEntrant.contact_info !== null"
                  >
                    {{ schoolEntrant.contact_info }}
                  </b-badge>
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    style="width: 8rem"
                    v-else
                  >
                    Мэдээлэл бүртгэгдээгүй.
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- style="width: 8rem" -->
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    v-if="schoolEntrant.contact_info !== null"
                  >
                    Эл.Шал.Дүн:
                    {{ calculateAvgEnrollment(schoolEntrant) }}
                  </b-badge>

                  <a
                    v-b-popover.hover="'Түвшин тогтоох шалгалтын дүн оруулах!'"
                    title="Дүн"
                    href="#"
                    class="badge bg-info"
                    @click="editSchoolEntrantExam(schoolEntrant)"
                    ><i class="fas fa-font"></i
                  ></a>
                  <a
                    v-b-popover.hover="
                      'Шалгалтанд тэнцсэн суралцагчыгбаталгаажуулах'
                    "
                    title="Тэнцсэн суралцагч"
                    href="#"
                    class="badge bg-primary"
                    @click="editSchoolEntrant(schoolEntrant)"
                    ><i class="fa fa-check"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">Элсэгч баталгаажуулах</h3>
        </div>
        <form @submit.prevent="editMode ? updateSchoolEntrant() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="profile_last_name"
                v-model="form.profile_last_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('profile_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="profile_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="user_name"
                v-model="form.user_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="user_name"></has-error>
            </div>
            <div class="form-group">
              <label>Төлөв</label>
              <b-form-checkbox
                v-model="form.checked"
                name="checked"
                switch
                size="lg"
              />
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      size="sx"
      id="modal-entrustInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Түвшин тогтоох шалгалтын дүн оруулах"
      class="modal fade"
      hide-footer
      @hide="cancelEntrant"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-0.2">
            <form @submit.prevent="updateEntrantExamGrade()">
              <div class="card-body">
                <div class="form-group">
                  <label>Монгол хэл</label>
                  <input
                    type="number"
                    name="mon_script"
                    v-model="formEntrunt.mon_script"
                    placeholder="Монгол хэлний дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('mon_script'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Математик</label>
                  <input
                    type="number"
                    name="mon_maths"
                    v-model="formEntrunt.mon_maths"
                    placeholder="Монгол математикийн дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('mon_maths'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж математик</label>
                  <input
                    type="number"
                    name="camb_maths"
                    v-model="formEntrunt.camb_maths"
                    placeholder="Кембриж математикийн дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_maths'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж англи хэл</label>
                  <input
                    type="number"
                    name="camb_eng"
                    v-model="formEntrunt.camb_eng"
                    placeholder="Кембриж англи хэлний дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_eng'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж байгалийн ухаан</label>
                  <input
                    type="number"
                    name="camb_sci"
                    v-model="formEntrunt.camb_sci"
                    placeholder="Кембриж байгалийн ухааны дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_sci'),
                      },
                    ]"
                  />
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-secondary"
                  @click.prevent="cancelEntrant"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      selected: "1",
      school_classes: [
        { value: "-1", text: "Цэцэрлэг" },
        { value: "0", text: "Бэлтгэл анги" },
        { value: "1", text: "1-р анги" },
        { value: "2", text: "2-р анги" },
        { value: "3", text: "3-р анги" },
        { value: "4", text: "4-р анги" },
        { value: "5", text: "5-р анги" },
        { value: "6", text: "6-р анги" },
        { value: "7", text: "7-р анги" },
        { value: "8", text: "8-р анги" },
        { value: "9", text: "9-р анги" },
        { value: "10", text: "10-р анги" },
        { value: "11", text: "11-р анги" },
        { value: "12", text: "12-р анги" },
      ],
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      action: "",
      editMode: false,
      schoolentrants: [],
      form: new Form({
        user_name: "",
        profile_last_name: "",
        checked: false,
        user_id: "",
      }),
      formEntrunt: new Form({
        user_id: "",
        mon_script: null,
        mon_maths: null,
        camb_maths: null,
        camb_eng: null,
        camb_sci: null,
      }),
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    schoolClassesCheck: function () {
      let a = [];
      if (this.userInfo.name == "agermankg") {
        a = this.school_classes.filter((el) => el.value === "-1");
        // console.log(a);
        // a.push(this.school_classes[0]);
      } else if (
        this.userInfo.name == "cipalprin" ||
        this.userInfo.name == "minadcurri" ||
        this.userInfo.name == "hr" ||
        this.userInfo.name == "adminOfficer"
      ) {
        a = this.school_classes;
      } else if (
        this.userInfo.name == "agerman" ||
        this.userInfo.name == "reg_emp"
      ) {
        a = this.school_classes.filter(
          (el) => el.value !== "-1" && el.value !== "0"
        );
      }
      return a;
    },
  },
  methods: {
    getAllEntrants() {
      axios
        .get("/manager/getWaitEntrants/" + this.selected)
        .then((response) => {
          this.schoolentrants = response.data.schoolentrants;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
      if (schoolEntrant.status1 == "registered") this.form.checked = false;
      else if (schoolEntrant.status1 == "confirmed") this.form.checked = true;
    },
    updateSchoolEntrant() {
      this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateEntrantConfirm/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    // Дүн оруулах
    editSchoolEntrantExam(schoolEntrant) {
      this.formEntrunt.fill(schoolEntrant);
      this.$bvModal.show("modal-entrustInfo");
    },
    updateEntrantExamGrade() {
      this.formEntrunt
        .put("/manager/updateEntruntExamGrade")
        .then((res) => {
          Fire.$emit("loadSchoolStudents");
          this.$toastr.s("Амжилттай", "Дүнгийн мэдээллийг орууллаа");
          this.cancelEntrant();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    cancelEntrant() {
      this.formEntrunt.reset();
      this.$bvModal.hide("modal-entrustInfo");
    },
    calculateAvgEnrollment(item) {
      // console.log(item.camb_maths);
      // console.log(item.camb_sci);
      // console.log(item.camb_eng);
      // console.log(item.mon_maths);
      // console.log(item.mon_script);
      let avgGrade = 0;
      let cambMaths = 0;
      let cambSci = 0;
      let cambEng = 0;
      let monMaths = 0;
      let monScript = 0;
      item.camb_maths === null
        ? cambMaths
        : (cambMaths = parseFloat(item.camb_maths));
      item.camb_sci === null ? cambSci : (cambSci = parseFloat(item.camb_sci));
      item.camb_eng === null ? cambEng : (cambEng = parseFloat(item.camb_eng));
      item.mon_maths === null
        ? monMaths
        : (monMaths = parseFloat(item.mon_maths));
      item.mon_script === null
        ? monScript
        : (monScript = parseFloat(item.mon_script));
      avgGrade = (cambMaths + cambSci + cambEng + monMaths + monScript) / 5;
      return avgGrade.toFixed(1);
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
