<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="9"></b-col>
                <b-col sm="3"
                  ><b-button
                    class="float-right"
                    variant="success"
                    @click.prevent="addTecknologyCard()"
                    >Технологийн карт
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="techCardIsLoaded" rounded="sm">
            <table class="table table-hover">
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Үйлдэл</th>
                  <th
                    v-if="
                      userInfo.name == 'agerman' ||
                      userInfo.name == 'cipalprin' ||
                      userInfo.name == 'consultant'
                    "
                  >
                    Багш
                  </th>
                  <th>Технологийн картын нэр</th>
                  <th>Технологийн картын агуулга</th>
                  <th>Технологийн картын зөвлөгөө</th>
                  <th>Үнэлгээ</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody
                v-for="(technologyCard, index) in allTechnologyCards"
                :key="technologyCard.id"
              >
                <tr>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="1">
                    <a
                      href="#"
                      class="badge bg-dark"
                      @click="showTechnologyCard(technologyCard)"
                    >
                      <b-icon icon="eye" aria-hidden="true"></b-icon>
                      Дэлгэрэнгүй
                    </a>

                    <a
                      href="#"
                      class="badge bg-primary"
                      @click="editTechnologyCard(technologyCard)"
                      ><i class="fa fa-edit"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteTechnologyCard(technologyCard)"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    tabindex="1"
                    v-if="
                      userInfo.name == 'agerman' ||
                      userInfo.name == 'cipalprin' ||
                      userInfo.name == 'consultant'
                    "
                  >
                    <b-badge variant="light">
                      <span v-if="technologyCard.owner_emp !== null">
                        {{
                          technologyCard.owner_emp.teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}
                        {{ technologyCard.owner_emp.teacher_name }}</span
                      >
                      <span v-else>Багш бүртгэгдээгүй байна.</span>
                    </b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="2">
                    {{ technologyCard.tc_name }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="3">
                    <span
                      v-html="technologyCard.tc_content.substring(0, 500)"
                    ></span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="4">
                    <span
                      v-if="technologyCard.tc_content_comment"
                      v-html="
                        technologyCard.tc_content_comment.substring(0, 500)
                      "
                    ></span>
                    <span v-else>Одоогоор зөвлөгөө өгөгдөөгүй байна.</span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="4">
                    {{ technologyCard.tc_grade }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="5">
                    {{ technologyCard.created_at | dateFullYear }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Технологийн карт нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-technology-card"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="!editMode ? 'Техологийн карт бүртгэх' : 'Технологийн карт засах'"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="
                  !editMode ? createTechCard() : updateTechCard()
                "
              >
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-lg-12">
                      <label for="name">Технологийн картын нэр</label>
                      <input
                        type="text"
                        name="tc_name"
                        v-model="form.tc_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('tc_name'),
                          },
                        ]"
                        placeholder="Технологийн картын нэр"
                      />
                    </div>
                    <div class="form-group col-lg-12">
                      <label for="tc_content">Технологийн картын агуулга</label>
                      <quill-editor
                        v-model="form.tc_content"
                        :options="editorOption"
                        style="height: 350px"
                      />
                    </div>
                    <div class="form-group col-lg-12">
                      <br />
                      <br />
                    </div>
                    <div
                      class="form-group col-lg-12"
                      v-if="
                        userInfo.name == 'agerman' ||
                        userInfo.name == 'cipalprin' ||
                        userInfo.name == 'consultant'
                      "
                    >
                      <label for="tc_content_comment"
                        >Технологийн картад өгөх зөвлөмж</label
                      >
                      <quill-editor
                        v-model="form.tc_content_comment"
                        :options="editorOptionComment"
                        style="height: 250px"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editMode"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editMode"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click.prevent="cancelTechnologycard"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-technology-card-view"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дэлгэрэнгүй харах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <label for="name"><b>Технологийн картын нэр: </b></label>
                    {{ form.tc_name }}
                  </div>
                  <div class="form-group col-lg-12">
                    <label for="tc_content"
                      ><b>Технологийн картын агуулга:</b></label
                    >
                    <span v-html="form.tc_content.substring(0, 500)"></span>
                  </div>
                  <div class="form-group col-lg-12">
                    <br />
                    <br />
                  </div>
                  <div class="form-group col-lg-12">
                    <label for="tc_content_comment"
                      ><b>Технологийн картад өгөх зөвлөмж:</b></label
                    >
                    <span
                      v-if="form.tc_content_comment"
                      v-html="form.tc_content_comment.substring(0, 500)"
                    ></span>
                    <span v-else>Одоогоо зөвлөгөө өгөгдөөгүй байна.</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn btn-secondary"
                  @click.prevent="closeTechnologycard"
                >
                  Хаах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      techCardIsLoaded: true,
      editMode: false,
      allTechnologyCards: [],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        drci_id: "",
        owner_id: "",
        tc_name: "",
        tc_grade: "",
        tc_content: "",
        tc_content_comment: "",
      }),
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formModalTitle: "Технологийн карт бүртгэх",
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: ["large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },

        placeholder: `Технологийн картны бүртгэл хийлгэхийн тулд таны бэлтгэсэн технологийн карт дараах шалгууруудыг хангасан байна.
        1.	Нэр
        2.	Зорилго
            2.1.	Мэдэх
            2.2.	Чадах
        3.	Эзэмших чадвар
            3.1.	Ерөнхий ба тусгай чадвар
        4.	Хэрэглэх материал
        5.	Хэрэглэгдэхүүн
        6.	Хэрэглэх аргачлал
            6.1.	Үйл ажиллагааны дүрэм
        7.	Багшид өгөх зөвлөмж
            7.1.	Үйл ажиллагааны түвшин бүрт тавих багшийн асуулт
            7.2.	Арга барилд сургах үйл ажиллагаа
            7.3.	Чадваржуулах үйл ажиллагаа
            7.4.	Бүтээх чадварыг хөгжүүлэх үйл ажиллагаа
        8.	ХАБ, ЭА-ын зааварчилгаа
          `,
      },
      editorOptionComment: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: ["large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: `Технологийн картад өгөх зөвлөмж`,
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  mounted() {},
  components: {
    Multiselect,
  },
  methods: {
    getAllTechnologyCards() {
      axios
        .get("/teacher/getAllTechnologyCards")
        .then((res) => {
          this.allTechnologyCards = res.data.getAllTechnologyCards;
          this.techCardIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showTechnologyCard(itemInfo) {
      this.form.reset();
      this.form.fill(itemInfo);
      this.$bvModal.show("modal-technology-card-view");
    },

    addTecknologyCard() {
      this.editMode = false;
      this.formModalTitle = "Технологийн карт бүртгэх";
      this.form.reset();
      this.$bvModal.show("modal-technology-card");
    },
    createTechCard() {
      this.form
        .post("/teacher/createTechCard")
        .then((response) => {
          this.$toastr.s("Хичээл амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("getTechCards");
          this.form.reset();
          this.$bvModal.hide("modal-technology-card");
        })
        .catch(() => {
          this.$toastr.e(
            "Төсөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    editTechnologyCard(technologyCard) {
      //   console.log(technologyCard);
      this.editMode = true;
      this.formModalTitle = "Төсөл засах";
      this.form.reset();
      this.form.fill(technologyCard);

      this.$bvModal.show("modal-technology-card");
    },
    updateTechCard() {
      //   console.log(this.form);
      this.form
        .put("/teacher/updateTechCard/" + this.form.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getTechCards");
          this.form.reset();
          this.editMode = false;
          this.$bvModal.hide("modal-technology-card");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelTechnologycard() {
      this.editMode = false;
      this.form.reset();
      this.$bvModal.hide("modal-technology-card");
    },
    closeTechnologycard() {
      this.form.reset();
      this.$bvModal.hide("modal-technology-card-view");
    },
    deleteTechnologyCard(technologyCard) {
      Swal.fire({
        title: "Технологийн карт устгахдаа итгэлтэй байна уу?",
        text: "Устгасан үед зөвхөн менежер сэргээх боломжтой?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteTechnologyCard/" + technologyCard.id)
            .then((response) => {
              this.$toastr.s("Амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getTechCards");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
  },
  created() {
    this.getAllTechnologyCards();
    Fire.$on("getTechCards", () => {
      this.getAllTechnologyCards();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
</style>
