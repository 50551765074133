<template>
  <div class="row pt-3">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Сургуулийн ажилчид</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Ургийн овог</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Профайл</th>
                <th>Имэйл</th>
                <th>БНБ</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="odd"
                v-for="(schoolEmp, index) in employees"
                :key="schoolEmp.user_id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.teacher_urgiin_ovog }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.teacher_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.teacher_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <img
                    class="profile-user-img img-fluid attachment-img"
                    :src="schoolEmp.profile_photo_url"
                    alt="Багшийн зураг"
                    accept="image/*"
                    v-if="schoolEmp.profile_photo_url != null"
                  />
                  <img
                    class="profile-user-img img-fluid attachment-img"
                    :src="defaultimage"
                    alt="Багшийн зураг"
                    accept="image/*"
                    v-else-if="schoolEmp.profile_photo_url === null"
                  />
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.department_code }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEmp.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmp(schoolEmp)"
                  >
                    <i class="fas fa-key"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editSchoolEmployee(schoolEmp)"
                    ><i class="fas fa-user-edit"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteSchoolEmployee(schoolEmp)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Ажилтан бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Ажилтан засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form @submit.prevent="!editMode ? createSchoolEmp() : updateSchoolEmp()">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6" v-if="editMode">
                  <div class="form-group">
                    <label>Регистер</label>
                    <input
                      type="teacher_reg"
                      class="form-control"
                      name="teacher_reg"
                      v-model="form.teacher_reg"
                      placeholder="Регистр"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ургийн овог</label>
                    <input
                      type="text"
                      name="teacher_urgiin_ovog"
                      v-model="form.teacher_urgiin_ovog"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_urgiin_ovog'),
                        },
                      ]"
                      placeholder="Ургийн овог"
                    />
                    <has-error :form="form" field="teacher_urgiin_ovog"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Овог</label>
                    <input
                      type="text"
                      name="teacher_last_name"
                      v-model="form.teacher_last_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_last_name'),
                        },
                      ]"
                      placeholder="Овог"
                    />
                    <has-error :form="form" field="teacher_last_name"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="form.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                      placeholder="Нэр"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-show="editMode">
                  <div class="form-group">
                    <label>Хүйс</label>
                    <b-form-select
                      v-model="form.gender"
                      :options="genders"
                    ></b-form-select>
                  </div>
                </div>
                <div class="col-md-6" v-show="editMode">
                  <div class="form-group">
                    <label>Төрсөн он сар өдөр</label>
                    <input
                      type="date"
                      class="form-control"
                      name="teacher_birthDate"
                      v-model="form.teacher_birthDate"
                      placeholder="Төрсөн он сар өдөр"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Имэйл</label>
                    <input
                      type="email"
                      name="email"
                      v-model="form.email"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('email'),
                        },
                      ]"
                      placeholder="Цахим шуудан"
                    />
                    <has-error :form="form" field="email"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Харьяалагдах мэргэжлийн баг</label>
                    <b-form-select
                      v-model="form.dep_id"
                      :options="deparments"
                      text-field="department_name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('dep_id'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="department"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Харьяалагдах ажлын байр</label>
                    <b-form-select
                      v-model="form.pos_id"
                      :options="positions"
                      text-field="position_name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('pos_id'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="position"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Хэрэглэгчийн дүр</label>
                    <b-form-select
                      v-model="form.role_name"
                      :options="roleList"
                      text-field="label"
                      value-field="name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('role_name'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Утас</label>
                    <input
                      type="number"
                      name="teacher_phoneNumber1"
                      v-model="form.teacher_phoneNumber1"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_phoneNumber1'),
                        },
                      ]"
                      placeholder="Утас"
                    />
                    <has-error :form="form" field="teacher_phoneNumber1"></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-show="editMode">
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-erd-zer"
                      v-model="form.teacher_erd_zer"
                      name="checkbox-erd-zer"
                      value="1"
                      unchecked-value="0"
                    >
                      PhD эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.teacher_erd_zer"></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-show="editMode">
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-sport-master"
                      v-model="form.teacher_sport_mas"
                      name="checkbox-sport-master"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Спортын мастер эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.teacher_sport_mas"></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-show="editMode">
                  <div class="form-group">
                    <b-form-checkbox
                      id="married"
                      v-model="form.married"
                      name="married"
                      value="1"
                      unchecked-value="0"
                    >
                      Гэрлэсэн эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.married"></has-error>
                  </div>
                </div>
              </div>
              <div class="form-group" v-show="editMode">
                <b-form-checkbox
                  id="tetgever_togtooson"
                  v-model="form.tetgever_togtooson"
                  name="tetgever_togtooson"
                  value="Тийм"
                  unchecked-value="Үгүй"
                >
                  Тэтгэвэр тогтоосон эсэх
                </b-form-checkbox>
                <has-error :form="form" field="form.tetgever_togtooson"></has-error>
              </div>
              <div class="row" v-if="form.tetgever_togtooson == 'Тийм'">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тэтгэвэр тогтоосон огноо</label>
                    <input
                      type="date"
                      name="tetgever_togtooson_ognoo"
                      v-model="form.tetgever_togtooson_ognoo"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('tetgever_togtooson_ognoo'),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон огноо"
                    />
                    <has-error :form="form" field="tetgever_togtooson_ognoo"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тэтгэвэр тогтоосон нас</label>
                    <input
                      type="number"
                      name="tetgever_togtooson_age"
                      v-model="form.tetgever_togtooson_age"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('tetgever_togtooson_age'),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон нас"
                    />
                    <has-error :form="form" field="tetgever_togtooson_age"></has-error>
                  </div>
                </div>
              </div>
              <div class="row" v-show="editMode">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Ажлын төрөл</label>
                    <b-form-select
                      v-model="form.work_type"
                      :options="workTypes"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_type'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="work_type"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажилд орсон огноо</label>
                    <input
                      type="date"
                      name="work_start_date"
                      v-model="form.work_start_date"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_start_date'),
                        },
                      ]"
                      placeholder="Ажилд орсон огноо"
                    />
                    <has-error :form="form" field="work_start_date"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажлаас гарсан огноо</label>
                    <input
                      type="date"
                      name="work_finish_date"
                      v-model="form.work_finish_date"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_finish_date'),
                        },
                      ]"
                      placeholder="Ажлаас гарсан огноо"
                    />
                    <has-error :form="form" field="work_finish_date"></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b-form-checkbox
                      id="vild_evlel_member"
                      v-model="form.vild_evlel_member"
                      name="vild_evlel_member"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Үйлдвэрчний эвлэлийн гишүүн эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.vild_evlel_member"></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-if="form.vild_evlel_member == 'Тийм'">
                  <div class="form-group">
                    <label>Гишүүнээр элссэн огноо</label>
                    <input
                      type="date"
                      name="vild_evlel_member_ognoo"
                      v-model="form.vild_evlel_member_ognoo"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('vild_evlel_member_ognoo'),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон огноо"
                    />
                    <has-error :form="form" field="vild_evlel_member_ognoo"></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Оршин суугаа хаяг</label>
                    <input
                      type="text"
                      name="teacher_live_address"
                      v-model="form.teacher_live_address"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_live_address'),
                        },
                      ]"
                      placeholder="Оршин суугаа хаяг"
                    />
                    <has-error :form="form" field="teacher_live_address"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Дүүрэг</label>
                    <b-form-select
                      v-model="form.district"
                      :options="districtOptions"
                    ></b-form-select>
                    <has-error :form="form" field="form.district"></has-error>
                  </div>
                </div>
              </div>
              <div class="row" v-show="editMode">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Хувийн цахим шуудан</label>
                    <input
                      type="email"
                      name="teacher_personal_email"
                      v-model="form.teacher_personal_email"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_personal_email'),
                        },
                      ]"
                      placeholder="Хувийн цахим шуудан"
                    />
                    <has-error :form="form" field="teacher_personal_email"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Дээд боловсролын зэрэг</label>
                    <b-form-select
                      v-model="form.deed_bol"
                      :options="degrees"
                      size="sm"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('deed_bol'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="form.deed_bol"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Төгссөн сургууль</label>
                    <input
                      type="text"
                      name="teacher_tugssun_sur"
                      size="sm"
                      v-model="form.teacher_tugssun_sur"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_tugssun_sur'),
                        },
                      ]"
                      placeholder="Төгссөн сургууль"
                    />
                    <has-error :form="form" field="teacher_tugssun_sur"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Мэрэгшүүлэх сургалт</label>
                    <input
                      type="text"
                      name="mergeshvvlekh_surgalt"
                      v-model="form.mergeshvvlekh_surgalt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('mergeshvvlekh_surgalt'),
                        },
                      ]"
                      placeholder="Мэрэгшүүлэх сургалт"
                    />
                    <has-error :form="form" field="mergeshvvlekh_surgalt"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Мэрэгжил</label>
                    <input
                      type="text"
                      name="teacher_mergejil"
                      v-model="form.teacher_mergejil"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_mergejil'),
                        },
                      ]"
                      placeholder="Мэрэгжил"
                    />
                    <has-error :form="form" field="teacher_mergejil"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Эрдмийн бүтээлийн бүртгэл</label>
                    <input
                      type="text"
                      name="teacher_erd_bvt_bvrt"
                      v-model="form.teacher_erd_bvt_bvrt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_erd_bvt_bvrt'),
                        },
                      ]"
                      placeholder="Эрдмийн бүтээлийн бүртгэл"
                    />
                    <has-error :form="form" field="teacher_erd_bvt_bvrt"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажилласан илтгэл, төсөл хөтөлбөр</label>
                    <input
                      type="text"
                      name="teacher_erd_ajils_ilt_proj"
                      v-model="form.teacher_erd_ajils_ilt_proj"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_erd_ajils_ilt_proj'),
                        },
                      ]"
                      placeholder="Ажилласан илтгэл, төсөл хөтөлбөр"
                    />
                    <has-error
                      :form="form"
                      field="teacher_erd_ajils_ilt_proj"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Гадагшаа зорчсон албан ажил</label>
                    <input
                      type="text"
                      name="teacher_alban_ajil_gad_zor"
                      v-model="form.teacher_alban_ajil_gad_zor"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_alban_ajil_gad_zor'),
                        },
                      ]"
                      placeholder="Гадагшаа зорчсон албан ажил"
                    />
                    <has-error
                      :form="form"
                      field="teacher_alban_ajil_gad_zor"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Англи хэлний мэдлэг</label>
                    <b-form-select
                      v-model="form.teacher_english"
                      :options="engOptions"
                    ></b-form-select>
                    <has-error :form="form" field="form.teacher_english"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_chinese"
                      v-model="form.teacher_chinese"
                      name="teacher_chinese"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Хятад хэлтэй эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.teacher_chinese"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_japanese"
                      v-model="form.teacher_japanese"
                      name="teacher_japanese"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Япон хэлтэй эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.teacher_japanese"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Боловсролын байгууллагад ажилласан жил</label>
                    <b-form-select
                      v-model="form.teacher_bol_bai_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_bol_bai_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тухайн мэргэжлээр ажилласан жил</label>
                    <b-form-select
                      v-model="form.teacher_tukhain_mer_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_tukhain_mer_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тухайн байгууллагад ажлласан жил</label>
                    <b-form-select
                      v-model="form.teacher_tukhain_bai_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_tukhain_bai_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Төрийн байгууллагад ажилласан жил</label>
                    <b-form-select
                      v-model="form.turiin_alba_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error :form="form" field="form.turiin_alba_ajiljil"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Амьдралын нөхцөл байдал</label>
                    <input
                      type="text"
                      name="teacher_live_situation"
                      v-model="form.teacher_live_situation"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_live_situation'),
                        },
                      ]"
                      placeholder="Амьдралын нөхцөл байдал"
                    />
                    <has-error :form="form" field="teacher_live_situation"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Зээлийн нөхцөл байдал</label>
                    <input
                      type="text"
                      name="teacher_zeel_situation"
                      v-model="form.teacher_zeel_situation"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_zeel_situation'),
                        },
                      ]"
                      placeholder="Зээлийн нөхцөл байдал"
                    />
                    <has-error :form="form" field="teacher_zeel_situation"></has-error>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      //   keyword: "",
      employees: [],
      deparments: [],
      positions: [],
      workTypes: [
        { value: "", text: "Сонгох" },
        { value: "Бүтэн", text: "Бүтэн цагийн" },
        { value: "Цагийн", text: "Цагийн" },
        { value: "Гэрээт", text: "Гэрээт" },
        { value: "Бусад", text: "Бусад" },
      ],
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эр", text: "Эрэгтэй" },
        { value: "Эм", text: "Эмэгтэй" },
      ],
      defaultimage: "/images/users/user.png",
      roleList: [
        {
          id: 1,
          name: "tordoc",
          label: "Эмч",
        },
        {
          id: 24,
          name: "hygienist",
          label: "Эрүүл ахуйч",
        },
        {
          id: 22,
          name: "plumber",
          label: "Сантехникч",
        },
        {
          id: 23,
          name: "carpenter",
          label: "Мужаан",
        },
      ],
      form: new Form({
        id: "",
        name: "",
        gender: "",
        teacher_birthDate: "",
        email: "",
        teacher_reg: "",
        teacher_urgiin_ovog: "",
        teacher_last_name: "",
        dep_id: "",
        pos_id: "",
        role_name: "",
        bnb: "",
        user_id: "",
        married: "",
        tetgever_togtooson: "",
        tetgever_togtooson_ognoo: "",
        tetgever_togtooson_age: "",
        work_type: "",
        work_start_date: "",
        work_finish_date: "",
        tvbd_zer: "",
        vild_evlel_member: "",
        vild_evlel_member_ognoo: "",
        teacher_live_address: "",
        teacher_phoneNumber1: "",
        teacher_personal_email: "",
        deed_bol: "",
        mer_bol: "",
        buren_dund_bol: "",
        mergeshvvlekh_surgalt: "",
        teacher_mergejil: "",
        teacher_erd_zer: "",
        teacher_erd_bvt_bvrt: "",
        teacher_sport_mas: "",
        teacher_erd_ajils_ilt_proj: "",
        teacher_alban_ajil_gad_zor: "",
        teacher_bol_zer: "",
        teacher_bagshlakh_erkh: "",
        teacher_english: "",
        teacher_chinese: "",
        teacher_japanese: "",
        teacher_bol_bai_ajiljil: "",
        teacher_tukhain_mer_ajiljil: "",
        teacher_tukhain_bai_ajiljil: "",
        turiin_alba_ajiljil: "",
        teacher_live_situation: "",
        teacher_zeel_situation: "",
        teacher_tugssun_sur: "",
        cambridge_teacher: "",
        teacher_cambridge_exam_officer: "",
        online_platform_skill: "",
        district: "СБД",
      }),
      options: [
        { value: "", text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: "Багш", text: "Багш" },
        { value: "Ахлах багш", text: "Ахлах багш" },
        { value: "Арга зүйч багш", text: "Арга зүйч багш" },
        { value: "Тэргүүлэх багш", text: "Тэргүүлэх багш" },
        { value: "Зөвлөх багш", text: "Зөвлөх багш" },
      ],
      degrees: [
        { value: "", text: "Боловсролын зэрэг сонгоно уу?" },
        { value: "Дипломын дээд", text: "Дипломын дээд" },
        { value: "Бакалавр", text: "Бакалавр" },
        { value: "Магистр", text: "Магистр" },
        { value: "Доктор", text: "Доктор" },
      ],
      yearOptions: [
        { value: "0-3 жил", text: "0-3 жил" },
        { value: "4-6 жил", text: "4-6 жил" },
        { value: "7-9 жил", text: "7-9 жил" },
        { value: "10-12 жил", text: "10-12 жил" },
        { value: "13-15 жил", text: "13-15 жил" },
        { value: "16-18 жил", text: "16-18 жил" },
        { value: "19-21 жил", text: "19-21 жил" },
        { value: "22-25 жил", text: "22-25 жил" },
        { value: "25-аас дээш жил", text: "25-аас дээш жил" },
      ],
      engOptions: [
        { value: "Анхан шатны (Elementary)", text: "Анхан шатны (Elementary)" },
        {
          value: "Дунджаас доогуур (Pre-intermediate)",
          text: "Дунджаас доогуур (Pre-intermediate)",
        },
        {
          value: "Дундаж түвшний (Intermediate)",
          text: "Дундаж түвшний (Intermediate)",
        },
        {
          value: "Ахисан түвшний (Upper-intermediate)",
          text: "Ахисан түвшний (Upper-intermediate)",
        },
        {
          value: "Гүнзгийрсэн түвшний (Advanced)",
          text: "Гүнзгийрсэн түвшний (Advanced)",
        },
        { value: "TOFEL, IELTS-ийн оноотой", text: "TOFEL, IELTS-ийн оноотой" },
      ],
      onlineSkillOptions: [
        { value: "Анхан шатны", text: "Анхан шатны" },
        { value: "Дунджаас доогуур түвшний", text: "Дунджаас доогуур түвшний" },
        { value: "Дундаж түвшний", text: "Дундаж түвшний" },
        { value: "Ахисан түвшний", text: "Ахисан түвшний" },
        { value: "Гүнзгийрүүлсэн түвшний", text: "Гүнзгийрүүлсэн түвшний" },
        { value: "Инновацийн түвшний", text: "Инновацийн түвшний" },
      ],
      districtOptions: [
        { value: "СБД", text: "Сүхбаатар" },
        { value: "ЧД", text: "Чингэлтэй" },
        { value: "БЗД", text: "Баянзүрх" },
        { value: "БГД", text: "Баянгол" },
        { value: "ХУД", text: "Хан-Уул" },
        { value: "СХД", text: "Сонгинохайрхан" },
        { value: "БХД", text: "Багахангай" },
        { value: "НД", text: "Налайх" },
        { value: "БД", text: "Багануур" },
      ],
    };
  },
  methods: {
    getAllEmployees() {
      axios
        .get("/manager_vaa/getAllUaEmps")
        .then((res) => {
          this.employees = res.data.employees;
          this.deparments = res.data.schooldepartments;
          this.positions = res.data.positions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    passChangeSchoolEmp(schoolteacher) {
      console.log(schoolteacher);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text:
          "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe#2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manager_vaa/passChangeUaEmp/" + schoolteacher.user_id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    viewSchoolTeacher(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolteacher);
    },
    editSchoolEmployee(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolteacher);
      this.form.user_id = schoolteacher.emp_id;
    },
    updateSchoolEmp() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager_vaa/updateUaEmp/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadEmployees");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolEmployee(schoolteacher) {
      // console.log(schoolteacher);
      Swal.fire({
        title: "Идэвхигүй болгох!",
        text: "Ажилтанг идэвхгүй болгохдоо итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteUaEmp/" + schoolteacher.user_id)
            .then((response) => {
              //   console.log(response.data);
              this.$toastr.s("Амжилттай", "Шинэчилсэн");
              Fire.$emit("loadEmployees");
              this.form.reset();
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    createSchoolEmp() {
      this.action = "Багш үүсгэж байна...";
      this.form
        .post("/manager_vaa/createUaEmp")
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadEmployees");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Багшийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created: function () {
    this.getAllEmployees();
    Fire.$on("loadEmployees", () => {
      this.getAllEmployees();
    });
  },
};
</script>

<style></style>
