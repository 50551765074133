<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5><b>БОЛОВСРОЛЫН ХӨТӨЛБӨРИЙН АГУУЛГЫН ХЭРЭГЖИЛТ</b></h5>
                                <table class="table table-bordered text-center">
                                    <thead>
                                        <tr role="row">
                                            <th rowspan="2">№</th>
                                            <th rowspan="2">Судлагдахууны нэр (Анги)</th>
                                            <th colspan="5">
                                                Сургалтын хөтөлбөрийн агуулгын гүйцэтгэл
                                            </th>
                                            <th rowspan="2">
                                                Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс
                                            </th>
                                        </tr>
                                        <tr role="row">
                                            <th>Хэрэгжүүлбэл зохих бүлэг сэдвийн тоо</th>
                                            <th>Хэрэгжүүлбэл зохих сэдвийн тоо</th>
                                            <th>Хэрэгжүүлсэн бүлэг сэдвийн тоо</th>
                                            <th>Хэрэгжүүлсэн сэдвийн тоо</th>
                                            <th>Гүйцэтгэлийн хувь</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(subName, index) in myPlanCalendar" :key="subName.id">
                                        <tr>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ index + 1 }}
                                            </td>

                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ subName.subject_name }} / {{ subName.grade }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ subName.subjCount }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ subName.topicCount }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ subName.successCount }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ subName.successTopicCount }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{
                                                    (
                                                        (subName.successTopicCount / subName.topicCount) *
                                                        100
                                                    ).toFixed(2)
                                                }}%
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- /.card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            myStudents: [],
            studCount: [],
            myClass: [],
            myGrades: [],
            project: [],
            projectScore: [],
            classAvg: [],
            studLimitAvg: [],
            studRegAvg: [],
            studExamAvg: [],
            myPlanCalendar: [],
            editMode: false,
        };
    },
    methods: {
        getStat7() {
            axios
                .post("/teacher/getStat7", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.myPlanCalendar = res.data.myPlanCalendar;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getStat7();
    },
};
</script>
