<template>
  <div class="row pt-3">
    <!-- /.col -->
    <div class="col-12">
      <div class="card card-primary card-tabs">
        <div class="card-header p-0 pt-1">
          <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="custom-tabs-one-home-tab"
                data-toggle="pill"
                href="#custom-tabs-one-home"
                role="tab"
                aria-controls="custom-tabs-one-home"
                aria-selected="true"
                >Шинэ тэмдэглэл: {{ totalRowsAllComment }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-one-profile"
                role="tab"
                aria-controls="custom-tabs-one-profile"
                aria-selected="false"
                >Эцэг эхэд илгээсэн тэмдэглэл:
                {{ totalRowsAllCommentConfirmParent }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-messages-tab"
                data-toggle="pill"
                href="#custom-tabs-one-messages"
                role="tab"
                aria-controls="custom-tabs-one-messages"
                aria-selected="false"
                >Эцэг эх танилцсан тэмдэглэл:
                {{ totalRowsAllCommentConfirmParentReaded }}</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-one-tabContent">
            <div
              class="tab-pane fade show active"
              id="custom-tabs-one-home"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-home-tab"
            >
              <div class="card">
                <div class="card-header"></div>
                <div class="card-body p-0" style="overflow-x: auto">
                  <b-overlay :show="commentLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageAllComment"
                            :options="pageOptionsAllComment"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайх утга!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterAllComment"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterAllComment"
                                @click="filterAllComment = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Сонголт хийх"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-button size="sm" @click="selectAllRows"
                            >Бүдийг сонгох</b-button
                          >
                          <b-button size="sm" @click="clearSelected"
                            >Сонголтыг хүчингүй болгох</b-button
                          >
                          <b-button
                            v-if="selected.length != 0"
                            size="sm"
                            @click.prevent="allCommentReadParentConfirm"
                            variant="success"
                            >Эцэг эхэд харуулах</b-button
                          >
                          <b-button
                            v-if="selected.length != 0"
                            size="sm"
                            @click.prevent="allCommentReadParentConfirmDelete"
                            variant="danger"
                            >Ажилглалт тэмдэглэлийг системээс устгах</b-button
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageAllComment"
                      :total-rows="totalRowsAllComment"
                      :per-page="perPageAllComment"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="allComments"
                      :fields="fieldsAllComment"
                      :current-page="currentPageAllComment"
                      :per-page="perPageAllComment"
                      :filter="filterAllComment"
                      :filter-ignored-fields="filterIgnoredFieldsAllComment"
                      @filtered="onFilteredAllComment"
                      :filter-included-fields="filterOnAllComment"
                      :select-mode="selectMode"
                      ref="selectableTable"
                      selectable
                      @row-selected="onRowSelected"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(emp_profile_photo)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.emp_profile_photo"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-if="data.item.emp_profile_photo != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-else-if="data.item.emp_profile_photo === null"
                        />
                      </template>
                      <template #cell(student_name)="data">
                        <b-badge variant="secondary">
                          <span v-if="data.item.student_last_name">
                            {{ data.item.student_last_name[0] }}.</span
                          >
                          {{ data.item.student_name }}</b-badge
                        >
                      </template>
                      <template #cell(student_profile_photo_url)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.student_profile_photo_url"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-if="data.item.student_profile_photo_url != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-else-if="
                            data.item.student_profile_photo_url === null
                          "
                        />
                      </template>
                      <template #cell(course_comment)="data">
                        <b-badge
                          variant="secondary"
                          style="white-space: pre-wrap"
                        >
                          <quill-editor
                            v-model="data.item.course_comment"
                            :options="editorOptionOnlyShow"
                            placeholder="Тэмдэглэл"
                          >
                          </quill-editor>
                          <!-- <span style="font-size: 70%; text-align: justify" v-html="data.item.course_comment
                            ? data.item.course_comment.substring(0, 1000)
                            : ''
                            ">
                          </span> -->
                        </b-badge>
                        <br />
                        <span class="badge bg-info">{{
                          data.item.created_at | dateYearMonthDay
                        }}</span>
                      </template>
                      <template #cell(course_comment_readParent)="data">
                        <b-icon
                          icon="x-circle"
                          scale="1.4"
                          variant="danger"
                          v-show="data.item.course_comment_readParent == 0"
                          @click.prevent="readParentConfirm(data.item)"
                        ></b-icon>
                        <!-- <b-icon
                          icon="check-square"
                          scale="1.4"
                          variant="success"
                          v-show="data.item.course_comment_readParent == 1"
                          @click.prevent="removeParentConfirm(data.item)"
                        ></b-icon> -->
                      </template>
                      <template #cell(course_comment_imReadParent)="data">
                        <!-- style="float: right" -->
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 1"
                          icon="heart-fill"
                          variant="success"
                          left
                          font-scale="2"
                        ></b-icon>
                        <!-- style="float: right" -->
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 0"
                          icon="heart-fill"
                          variant="warning"
                          left
                          font-scale="2"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-one-profile"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-profile-tab"
            >
              <div class="card">
                <div class="card-header"></div>
                <div class="card-body p-0" style="overflow-x: auto">
                  <b-overlay :show="commentLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageAllCommentConfirmParent"
                            :options="pageOptionsAllCommentConfirmParent"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайх утга!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterAllCommentConfirmParent"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterAllCommentConfirmParent"
                                @click="filterAllCommentConfirmParent = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageAllCommentConfirmParent"
                      :total-rows="totalRowsAllCommentConfirmParent"
                      :per-page="perPageAllCommentConfirmParent"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="allCommentsConfirmParent"
                      :fields="fieldsAllCommentConfirmParent"
                      :current-page="currentPageAllCommentConfirmParent"
                      :per-page="perPageAllCommentConfirmParent"
                      :filter="filterAllCommentConfirmParent"
                      :filter-ignored-fields="
                        filterIgnoredFieldsAllCommentConfirmParent
                      "
                      @filtered="onFilteredAllCommentConfirmParent"
                      :filter-included-fields="filterOnAllCommentConfirmParent"
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(emp_profile_photo)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.emp_profile_photo"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-if="data.item.emp_profile_photo != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-else-if="data.item.emp_profile_photo === null"
                        />
                      </template>
                      <template #cell(student_name)="data">
                        <b-badge variant="secondary">
                          <span v-if="data.item.student_last_name">
                            {{ data.item.student_last_name[0] }}.</span
                          >
                          {{ data.item.student_name }}</b-badge
                        >
                      </template>
                      <template #cell(student_profile_photo_url)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.student_profile_photo_url"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-if="data.item.student_profile_photo_url != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-else-if="
                            data.item.student_profile_photo_url === null
                          "
                        />
                      </template>
                      <template #cell(course_comment)="data">
                        <b-badge
                          variant="secondary"
                          style="white-space: pre-wrap"
                          >{{ data.item.course_comment }}</b-badge
                        >
                        <br />
                        <span class="badge bg-info">{{
                          data.item.created_at | dateYearMonthDay
                        }}</span>
                      </template>
                      <template #cell(course_comment_readParent)="data">
                        <!-- <b-icon
                          icon="x-circle"
                          scale="1.4"
                          variant="danger"
                          v-show="data.item.course_comment_readParent == 0"
                          @click.prevent="readParentConfirm(data.item)"
                        ></b-icon> -->
                        <b-icon
                          icon="backspace-fill"
                          scale="1.4"
                          variant="warning"
                          @click.prevent="removeParentConfirm(data.item)"
                        ></b-icon>
                        <!-- v-show="data.item.course_comment_readParent == 1" -->
                      </template>
                      <template #cell(course_comment_imReadParent)="data">
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 1"
                          icon="eye-fill"
                          variant="success"
                          left
                          font-scale="2"
                        ></b-icon>
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 0"
                          icon="eye-slash-fill"
                          variant="danger"
                          left
                          font-scale="2"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-one-messages"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-messages-tab"
            >
              <div class="card">
                <div class="card-header"></div>
                <div class="card-body p-0" style="overflow-x: auto">
                  <b-overlay :show="commentLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageAllCommentConfirmParentReaded"
                            :options="pageOptionsAllCommentConfirmParentReaded"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайх утга!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterAllCommentConfirmParentReaded"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterAllCommentConfirmParentReaded"
                                @click="
                                  filterAllCommentConfirmParentReaded = ''
                                "
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageAllCommentConfirmParentReaded"
                      :total-rows="totalRowsAllCommentConfirmParentReaded"
                      :per-page="perPageAllCommentConfirmParentReaded"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="allCommentsConfirmParentReaded"
                      :fields="fieldsAllCommentConfirmParentReaded"
                      :current-page="currentPageAllCommentConfirmParentReaded"
                      :per-page="perPageAllCommentConfirmParentReaded"
                      :filter="filterAllCommentConfirmParentReaded"
                      :filter-ignored-fields="
                        filterIgnoredFieldsAllCommentConfirmParentReaded
                      "
                      @filtered="onFilteredAllCommentConfirmParentReaded"
                      :filter-included-fields="
                        filterOnAllCommentConfirmParentReaded
                      "
                    >
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(emp_profile_photo)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.emp_profile_photo"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-if="data.item.emp_profile_photo != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Багшийн зураг"
                          accept="image/*"
                          v-else-if="data.item.emp_profile_photo === null"
                        />
                      </template>
                      <template #cell(student_name)="data">
                        <b-badge variant="secondary">
                          <span v-if="data.item.student_last_name">
                            {{ data.item.student_last_name[0] }}.</span
                          >
                          {{ data.item.student_name }}</b-badge
                        >
                      </template>
                      <template #cell(student_profile_photo_url)="data">
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="data.item.student_profile_photo_url"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-if="data.item.student_profile_photo_url != null"
                        />
                        <img
                          class="profile-user-img img-fluid attachment-img"
                          :src="defaultimage"
                          alt="Суралцагчийн зураг"
                          accept="image/*"
                          v-else-if="
                            data.item.student_profile_photo_url === null
                          "
                        />
                      </template>
                      <template #cell(course_comment)="data">
                        <b-badge
                          variant="secondary"
                          style="white-space: pre-wrap"
                          >{{ data.item.course_comment }}</b-badge
                        >
                        <br />
                        <span class="badge bg-info">{{
                          data.item.created_at | dateYearMonthDay
                        }}</span>
                      </template>
                      <!-- <template #cell(course_comment_readParent)="data">
                        <b-icon
                          icon="x-circle"
                          scale="1.4"
                          variant="danger"
                          v-show="data.item.course_comment_readParent == 0"
                          @click.prevent="readParentConfirm(data.item)"
                        ></b-icon>
                        <b-icon
                          icon="check-square"
                          scale="1.4"
                          variant="success"
                          v-show="data.item.course_comment_readParent == 1"
                          @click.prevent="removeParentConfirm(data.item)"
                        ></b-icon>
                      </template> -->
                      <template #cell(course_comment_imReadParent)="data">
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 1"
                          icon="heart-fill"
                          variant="success"
                          left
                          font-scale="2"
                        ></b-icon>
                        <b-icon
                          v-show="data.item.course_comment_imReadParent == 0"
                          icon="heart-fill"
                          variant="warning"
                          left
                          font-scale="2"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      //   search: "",
      //   search1: "",
      //   search2: "",
      //   checkPage: undefined,
      //   checkPage1: undefined,
      //   checkPage2: undefined,
      allComments: [],
      allCommentsConfirmParent: [],
      allCommentsConfirmParentReaded: [],
      defaultimage: "http://localhost:8000/images/users/user.png",
      commentLoaded: true,
      // Хүснэгтийн тохиргоо
      totalRowsAllComment: 1,
      currentPageAllComment: 1,
      perPageAllComment: 25,
      pageOptionsAllComment: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsAllComment: [
        "course_comment",
        "emp_profile_photo",
        "student_profile_photo_url",
        "course_comment_readParent",
        "course_comment_imReadParent",
        "school_class_id",
        "subject_id",
        "emp_user_id",
        "emp_department_id",
        "course_student_id",
        "course_comment_id",
      ],
      filterAllComment: "",
      filterOnAllComment: [],
      fieldsAllComment: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "emp_teacher_name",
          label: "Багш",
          sortable: true,
        },
        {
          key: "emp_profile_photo",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Суралцагч",
          sortable: true,
        },
        {
          key: "student_profile_photo_url",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "school_class_full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "course_comment",
          label: "Тэмдэглэл",
          sortable: true,
        },
        // {
        //   key: "course_comment_readParent",
        //   label: "Эцэг эх харах эсэх",
        //   sortable: true,
        // },
        {
          key: "course_comment_imReadParent",
          label: "Эцэг эх танилцсан эсэх",
          sortable: true,
        },
      ],
      selectMode: "multi",
      selected: [],

      // Хүснэгтийн тохиргоо Эцэг эх үзэх
      totalRowsAllCommentConfirmParent: 1,
      currentPageAllCommentConfirmParent: 1,
      perPageAllCommentConfirmParent: 25,
      pageOptionsAllCommentConfirmParent: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsAllCommentConfirmParent: [
        "course_comment",
        "emp_profile_photo",
        "student_profile_photo_url",
        "course_comment_readParent",
        "course_comment_imReadParent",
        "school_class_id",
        "subject_id",
        "emp_user_id",
        "emp_department_id",
        "course_student_id",
        "course_comment_id",
      ],
      filterAllCommentConfirmParent: "",
      filterOnAllCommentConfirmParent: [],
      fieldsAllCommentConfirmParent: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "emp_teacher_name",
          label: "Багш",
          sortable: true,
        },
        {
          key: "emp_profile_photo",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Суралцагч",
          sortable: true,
        },
        {
          key: "student_profile_photo_url",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "school_class_full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "course_comment",
          label: "Тэмдэглэл",
          sortable: true,
        },
        {
          key: "course_comment_readParent",
          label: "Эцэг эх харах эсэхийг болиулах",
          sortable: true,
        },
        {
          key: "course_comment_imReadParent",
          label: "Эцэг эх танилцсан эсэх",
          sortable: true,
        },
      ],

      // Хүснэгтийн тохиргоо Эцэг эх танилцсан
      totalRowsAllCommentConfirmParentReaded: 1,
      currentPageAllCommentConfirmParentReaded: 1,
      perPageAllCommentConfirmParentReaded: 25,
      pageOptionsAllCommentConfirmParentReaded: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsAllCommentConfirmParentReaded: [
        "course_comment",
        "emp_profile_photo",
        "student_profile_photo_url",
        "course_comment_readParent",
        "course_comment_imReadParent",
        "school_class_id",
        "subject_id",
        "emp_user_id",
        "emp_department_id",
        "course_student_id",
        "course_comment_id",
      ],
      filterAllCommentConfirmParentReaded: "",
      filterOnAllCommentConfirmParentReaded: [],
      fieldsAllCommentConfirmParentReaded: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "emp_teacher_name",
          label: "Багш",
          sortable: true,
        },
        {
          key: "emp_profile_photo",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Суралцагч",
          sortable: true,
        },
        {
          key: "student_profile_photo_url",
          label: "Профайл зураг",
          sortable: true,
        },
        {
          key: "school_class_full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "course_comment",
          label: "Тэмдэглэл",
          sortable: true,
        },
        // {
        //   key: "course_comment_readParent",
        //   label: "Эцэг эх харах эсэх",
        //   sortable: true,
        // },
        {
          key: "course_comment_imReadParent",
          label: "Эцэг эх танилцсан эсэх",
          sortable: true,
        },
      ],
      editorOptionOnlyShow: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
    };
  },
  methods: {
    //b-table тохирго
    onFilteredAllComment(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAllComment = filteredItems.length;
      this.currentPageAllComment = 1;
    },
    onFilteredAllCommentConfirmParent(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAllCommentConfirmParent = filteredItems.length;
      this.currentPageAllCommentConfirmParent = 1;
    },
    onFilteredAllCommentConfirmParentReaded(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAllCommentConfirmParentReaded = filteredItems.length;
      this.currentPageAllCommentConfirmParentReaded = 1;
    },
    // b-table сонголт хийх тохиргоо
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    async getAllData() {
      // page = 1
      //   this.checkPage = undefined ? page == 1 : page == this.checkPage;
      await axios
        .get(`/manager/getTeachersAllComment`)
        .then((res) => {
          //   console.log(res.data);
          this.allComments = res.data.allComments;
          this.totalRowsAllComment = this.allComments.length;
          //   this.checkPage = page;
          this.commentLoaded = false;
        })
        .catch((err) => console.log(err));
    },
    async getAllData1() {
      // page = 1
      //   this.checkPage1 = undefined ? page == 1 : page == this.checkPage1;
      //   ?page=${page}&search=${this.search1}
      await axios
        .get(`/manager/getTeachersAllCommentConfirmParent`)
        .then((res) => {
          //   console.log(res.data);
          this.allCommentsConfirmParent = res.data.allCommentsConfirmParent;
          //   this.checkPage1 = page;
          this.totalRowsAllCommentConfirmParent =
            this.allCommentsConfirmParent.length;
        })
        .catch();
    },
    async getAllData2() {
      //     page = 1
      //   this.checkPage2 = undefined ? page == 1 : page == this.checkPage2;
      //   ?page=${page}&search=${this.search2}
      await axios
        .get(`/manager/getTeachersAllCommentConfirmParentReaded`)
        .then((res) => {
          //   console.log(res.data);
          this.allCommentsConfirmParentReaded =
            res.data.allCommentsConfirmParentReaded;
          //   this.checkPage2 = page;
          this.totalRowsAllCommentConfirmParentReaded =
            this.allCommentsConfirmParentReaded.length;
        })
        .catch();
    },
    readParentConfirm(comment) {
      axios
        .post("/manager/confirmReadParent", { comment, read: true })
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("read");
          Fire.$emit("read1");
          Fire.$emit("read2");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    async removeParentConfirm(comment) {
      this.commentLoaded = true;
      await axios
        .post("/manager/removeReadParent", { comment, read: false })
        .then((res) => {
          Fire.$emit("read");
          Fire.$emit("read1");
          Fire.$emit("read2");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      this.commentLoaded = false;
    },
    allCommentReadParentConfirm() {
      this.commentLoaded = true;
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Багшийн ажиглалт, тэмдэглэл, шүүмжийг эцэг эхэд харуулах тохиогоог хийх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Идэвхжүүл!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/allCommentReadParentConfirm", {
              comment: this.selected,
              read: true,
            })
            .then((res) => {
              Swal.fire({
                title: "Идэхжүүллээ!",
                text: "Эцэг эхэд харуулах тохиргоог амжилттай хийж дууслаа.",
                icon: "success",
              });
              //   console.log(res.data);
              Fire.$emit("read");
              Fire.$emit("read1");
              Fire.$emit("read2");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
      this.commentLoaded = false;
    },
    allCommentReadParentConfirmDelete() {
      this.commentLoaded = true;
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Багшийн ажиглалт, тэмдэглэл, шүүмжийг эцэг эхэд харуулахаас өмнө бүрэн устгал хийх гэж байна! Мөн ажиглалт, тэмдэглэл оруулсан багшид харагдахгүй.",
        icon: "danger",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/manager/allCommentReadParentConfirmDelete", {
              comment: this.selected,
              // read: true,
            })
            .then((res) => {
              Swal.fire({
                title: "Устгалаа!",
                text: "Ажиглалт, тэмдэглэлийг амжилттай устгаж дууслаа.",
                icon: "success",
              });
              //   console.log(res.data);
              Fire.$emit("read");
              Fire.$emit("read1");
              Fire.$emit("read2");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
      this.commentLoaded = false;
    },
  },
  created() {
    this.getAllData();
    // this.checkPage;
    // this.checkPage1
    this.getAllData1();
    // this.checkPage2
    this.getAllData2();
    Fire.$on("read", () => {
      this.getAllData();
      //   this.checkPage
    });
    Fire.$on("read1", () => {
      // this.checkPage1
      this.getAllData1();
    });
    Fire.$on("read2", () => {
      // this.checkPage2
      this.getAllData2();
    });
  },
};
</script>

<style></style>
