<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Шилжсэн суралцагчдын жагсаалт</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="form-group">
            <multiselect
              v-model="yearId"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="id"
              :custom-label="customYearName"
              placeholder="Хичээлийн жил сонгоно уу"
              :options="years"
              :searchable="true"
              :allow-empty="true"
              @input="getSchoolClassLeavedStudentsAll"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong
                  >{{ option.start_date | dateYear }}-{{
                    option.finish_date | dateYear
                  }}
                  - хичээлийн жил
                </strong></template
              >
            </multiselect>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="school_classes"
                    text-field="school_class_full_name"
                    value-field="school_class_id"
                    size="sm"
                  >
                  </b-form-select>
                  <has-error :form="form" field="school_class_id"></has-error>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getSchoolClassLeaveStudents"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                Нийт шилжсэн суралцагдын тоо:
                {{ leavedStudents.length }}
              </div>
            </div>
          </div>
          <b-row>
            <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
                            </b-form-select>

                            <b-form-select
                              v-model="sortDesc"
                              :disabled="!sortBy"
                              :aria-describedby="ariaDescribedby"
                              size="sm"
                              class="w-25"
                            >
                              <option :value="false">Asc</option>
                              <option :value="true">Desc</option>
                            </b-form-select>
                          </b-input-group>
                        </b-form-group>
                      </b-col> -->
            <b-col lg="12" class="my-1">
              <b-form-group
                label="Нэг хуудсанд харуулах тоо"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                label="Хайлт хийх утга оруулна уу!"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Хайлт хийх утгаа оруулна уу!"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Цэвэрлэх</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          <b-table
            hover
            :items="leavedStudents"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            style="font-size: 80%"
            :filter-included-fields="filterOn"
            :filterIgnoredFields="filterIgnoredFields"
            @filtered="onFiltered"
            :filter="filter"
          >
            <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(profiles_status1)="data">
              <span
                v-if="data.item.profiles_status1 === 'leave'"
                class="badge bg-danger"
                >ШИЛЖСЭН</span
              >
              <br />
              <b-badge
                variant="light"
                class="text-wrap"
                style="width: 8rem"
                v-if="data.item.my_profile_info !== null"
              >
                <span
                  v-if="
                    data.item.my_profile_info.leave_type_student.length != 0
                  "
                >
                  <span
                    v-for="(shalt, i) in data.item.my_profile_info
                      .leave_type_student"
                    :key="shalt.id"
                  >
                    <span v-if="shalt.my_leave_type_info !== null">
                      {{ i + 1 }}.
                      {{ shalt.my_leave_type_info.name }}
                      <br />
                    </span>
                  </span>
                </span>
                <span v-else>Шилжсэн шалтгаан бүртгэгдээгүй байна.</span>
              </b-badge>
            </template>
            <template #cell(profiles_student_last_name)="data">
              <b-badge
                variant="secondary"
                class="text-wrap"
                style="width: 8rem"
              >
                {{ data.item.profiles_student_last_name }}
              </b-badge>
            </template>
            <template #cell(profiles_student_name)="data">
              {{ data.item.profiles_student_name }}
            </template>
            <template #cell(users_student_email)="data">
              {{ data.item.users_student_email }}
            </template>
            <template #cell(school_classes_full_name)="data">
              {{ data.item.school_classes_full_name }}
            </template>
            <template #cell(profiles_deleted_at)="data">
              {{ data.item.profiles_deleted_at | dateYearMonthDay }}
            </template>

            <template #cell(actions)="data">
              <a
                href="#"
                class="badge bg-primary"
                @click="refreshSchoolStudents(data.item)"
                ><i class="fa fa-check"></i
              ></a>
              <a
                href="#"
                class="badge bg-danger"
                @click="deleteSchoolStudent(data.item)"
              >
                <i
                  class="fas fa-sign-out-alt"
                  v-b-popover.hover.top="'Суралцагчийг системээс бүрэн устгах!'"
                  title="Шилжих"
                ></i
              ></a>
            </template>
          </b-table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">
            Суралцагчийн эрхийг сэргээх
          </h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="editMode ? updateSchoolStudents() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Төлөв</label>
              <b-form-checkbox
                v-model="form.checked"
                name="checked"
                switch
                size="lg"
              />
              <label v-if="form.checked">Идэвхтэй</label>
            </div>
            <div class="form-group">
              <label>Шилжсэн шалтгаан</label>
              <b-form-select
                v-model="form.leave_type_list"
                :options="leave_types"
                text-field="name"
                value-field="id"
                multiple
                :select-size="4"
              ></b-form-select>
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <label>Шилжсэн тайлбар</label>
              <textarea
                name="student_shiljilt_shaltgaan"
                id=""
                cols="30"
                rows="5"
                v-model="form.student_shiljilt_shaltgaan"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('student_shiljilt_shaltgaan'),
                  },
                ]"
                placeholder="шилжсэн тайлбар"
              ></textarea>
              <has-error
                :form="form"
                field="student_shiljilt_shaltgaan"
              ></has-error>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      yearId: null,
      selected: 5,
      school_classes: [],
      action: "",
      editMode: false,
      leavedStudents: [],
      leave_types: [],
      form: new Form({
        checked: false,
        profile_id: "",
        user_id: "",
        leave_type_list: [],
        student_shiljilt_shaltgaan: "",
      }),

      //b-table тохиргоо
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filter: "",
      filterOn: [],
      filtered: 0,
      filterIgnoredFields: ["index", "my_profile_info"],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "profiles_status1",
          label: "Төлөв",
          sortable: true,
        },

        {
          key: "profiles_student_last_name",
          label: "Овог",
          sortable: true,
        },
        {
          key: "profiles_student_name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "dos_stat_name",
          label: "Бичиг баримтын төлөв",
          sortable: true,
        },
        {
          key: "users_student_email",
          label: "Цахим шуудан",
          sortable: true,
        },
        {
          key: "school_classes_full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "profiles_deleted_at",
          label: "Шилжсэн огноо",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getAllLeavedInfo() {
      axios
        .get("/manager/getAllLeavedStudentsInfo")
        .then((response) => {
          this.school_classes = response.data.mySchoolClassId;
          this.leave_types = response.data.school_leave_types;
          //   this.years = response.data.years;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //b-table тохирго
    onFiltered(filteredItems) {
      //   console.log(filteredItems);
      this.filtered = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getSchoolClassLeaveStudents() {
      axios
        .get("/manager/getSchoolClassLeaveStudents/" + this.selected)
        .then((response) => {
          this.leavedStudents = response.data.leavedStudents;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getSchoolClassLeavedStudentsAll() {
      axios
        .get("/manager/getSchoolClassLeavedStudentsAll/" + this.yearId.id)
        .then((response) => {
          this.leavedStudents = response.data.leavedStudents;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    refreshSchoolStudents(schoolEntrant) {
      // console.log(schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
      if (schoolEntrant.status1 == "registered") this.form.checked = false;
      else if (schoolEntrant.status1 == "confirmed") this.form.checked = true;
      this.form.leave_type_list = [];

      let checkLTL = schoolEntrant.my_profile_info.leave_type_student;
      // console.log(checkLTL)

      this.leave_types.forEach((el1) => {
        checkLTL.forEach((el2) => {
          if (el1.id === el2.leave_type_id) {
            this.form.leave_type_list.push(el1.id);
          }
        });
      });
      // axios
      //     .get("/manager/getStudentLeaveTypes/" + this.form.profile_id)
      //     .then((response) => {
      //         this.form.leave_type_list = response.data.leave_type_list;
      //     })
      //     .catch((error) => {
      //         //   console.log(error);
      //     });
    },
    updateSchoolStudents() {
      //   console.log(this.form);
      this.form
        .put("/manager/updateSchoolStudentsRefresh/" + this.form.profile_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.form.reset();
          this.editMode = !this.editMode;
          this.getSchoolClassLeaveStudents();
          //   this.getSchoolClassLeavedStudentsAll();
        })
        .catch((errors) => {
          this.$toastr.e("Алдаа", "Амжилтгүй!");
          //   console.log(errors);
        });
    },

    deleteSchoolStudent(schoolstudent) {
      // console.log("Дэлгэрэнгүй мэдээлэл засах", schoolstudent);
      Swal.fire({
        title:
          "Суралцагчыг системээс сэргээж болохгүйгээр устгах гэж байна. Сургуульд огт сураагүй эсвэл буруу бүртгэгдсэн суралцагчыг системээс устгана.",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: `Системээс устгах`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .post("/manager/destroyStudent", {
              user_id: schoolstudent.my_profile_info.user_id,
              year_id: schoolstudent.year_id,
            })
            .then((response) => {
              // console.log(response.data.success);
              this.$toastr.s("Амжилттай", response.data.success);
              this.getSchoolClassLeaveStudents();
            })
            .catch((error) => {
              //   console.log(error);
            });
          /* Read more about isConfirmed, isDenied below */
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllLeavedInfo();
    // this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllLeavedInfo();
      // this.getSchoolClassLeaveStudents();
    });
  },
};
</script>

<style></style>
