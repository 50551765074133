<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="row">
        <div class="col-lg-6">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="years"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div>
        <!-- <div class="col-lg-6">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="years"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div> -->
      </div>

      <div v-if="isShow">
        <!--Нийт ажилчдын шатлалаар харуулбал-->
        <!-- <div class="row">
          <div class="col-lg-12">
            <apexchart
              type="line"
              height="350"
              :options="chartOptionsLineEmployees"
              :series="seriesLineEmployees"
            >
            </apexchart>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="optionsEmployees"
                :series="seriesEmployees"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options1Employees"
                :series="series1Employees"
              ></apexchart>
            </div>
          </div>
        </div> -->
        <!--Багш нар ерөнхий ур чадвар-->
        <div class="row">
          <div class="col-lg-12">
            <div class="small-box bg-white">
              <apexchart
                type="line"
                height="350"
                :options="chartOptionsLineTeacher"
                :series="seriesLineTeacher"
              >
              </apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options1"
                :series="series1"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- Тусгай ур чадвар -->
        <!-- <div class="row">
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="options2"
                :series="series2"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options3"
                :series="series3"
              ></apexchart>
            </div>
          </div>
        </div> -->
        <!-- Мэргэжлийн багуудын бүтэц дахин нягтлав. Нийт идэвхитэй багш ажилтан -->
        <!-- <div class="row">
                    <div class="col-lg-6 col-6">
                        <div class="small-box bg-white">
                            <apexchart
                                width="85%"
                                type="bar"
                                :options="optionsDep"
                                :series="seriesDep"
                            ></apexchart>
                        </div>
                    </div>
                    <div class="col-lg-6 col-6">
                        <div class="small-box bg-white">
                            <apexchart
                                width="80%"
                                type="donut"
                                :options="options1Dep"
                                :series="series1Dep"
                            ></apexchart>
                        </div>
                    </div>
                </div> -->

        <!-- Хүйсийн харьцаа -->
        <!-- <div class="row">
                    <div class="col-lg-6 col-6">
                        <div class="small-box bg-white">
                            <apexchart
                                width="85%"
                                type="bar"
                                :options="options4"
                                :series="series4"
                            ></apexchart>
                        </div>
                    </div>
                    <div class="col-lg-6 col-6">
                        <div class="small-box bg-white">
                            <apexchart
                                width="80%"
                                type="donut"
                                :options="options5"
                                :series="series5"
                            ></apexchart>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isShow: false,
      year_id: "",
      // allYears: [],
      allEmployees: [],
      getAllDeletedEmployees: [],

      //Ажлын зэрэглэл стат ерөнхий
      getAllWorkGradesTeacher: [],
      options: {
        chart: {
          id: "Багш (Ур чадвар)",
        },
        title: {
          text: "Багш (Ур чадвар)",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: "Зэрэг",
          data: [], //жил
        },
      ],
      options1: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Багш (Ур чадвар)",
                },
              },
            },
          },
        },
      },
      series1: [],
      //   Line graphics
      seriesLineTeacher: [
        {
          name: "Багш нарын шатлал",
          data: [], //10, 41, 35, 51, 49, 62, 69, 91, 148
        },
      ],
      chartOptionsLineTeacher: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Багш нарын шатлал",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],

          // "Jan",
          // "Feb",
          // "Mar",
          // "Apr",
          // "May",
          // "Jun",
          // "Jul",
          // "Aug",
          // "Sep",
          title: {
            text: "Шатлал-нэршил",
          },
        },
        yaxis: {
          title: {
            text: "Тоо",
          },
          min: 0,
          max: 100,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      //Ажлын зэрэглэл бүх ажилчид
      getAllWorkGradesEmployees: [],
      optionsEmployees: {
        chart: {
          id: "Багш (Ур чадвар)",
        },
        title: {
          text: "Багш (Ур чадвар)",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      seriesEmployees: [
        {
          name: "Зэрэг",
          data: [], //жил
        },
      ],
      options1Employees: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Багш (Ур чадвар)",
                },
              },
            },
          },
        },
      },
      series1Employees: [],
      //   Line graphics
      seriesLineEmployees: [
        {
          name: "Багш нарын шатлал",
          data: [], //10, 41, 35, 51, 49, 62, 69, 91, 148
        },
      ],
      chartOptionsLineEmployees: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Багш нарын шатлал",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],

          // "Jan",
          // "Feb",
          // "Mar",
          // "Apr",
          // "May",
          // "Jun",
          // "Jul",
          // "Aug",
          // "Sep",
          title: {
            text: "Шатлал-нэршил",
          },
        },
        yaxis: {
          title: {
            text: "Тоо",
          },
          min: 0,
          // max: 100,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    // allComplaintsPercent() {
    //   let percent = (this.allComplaints / this.allComplaints) * 100;
    //   return percent.toFixed(1);
    // },
    // pollPercent() {
    //   let percent =
    //     (this.allComplaintsParentPoll / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // complaintPercent() {
    //   let percent =
    //     (this.allComplaintsParentComplaint / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // contributionPercent() {
    //   let percent =
    //     (this.allComplaintsParentContribution / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // ethicsPercent() {
    //   let percent =
    //     (this.allComplaintsParentEthics / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    years: function () {
      return this.$store.state.years;
    },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      this.getAllWorkGrades();
    },
  },
  methods: {
    getAllWorkGrades() {
      this.isShow = false;
      // Бүх ажилчид
      this.seriesEmployees[0].data = [];
      this.optionsEmployees.xaxis.categories = [];
      this.series1Employees = [];
      this.options1Employees.labels = [];
      this.seriesLineEmployees[0].data = [];
      this.chartOptionsLineEmployees.xaxis.categories = [];
      // Бүх багш нар
      this.series[0].data = [];
      this.options.xaxis.categories = [];
      this.series1 = [];
      this.options1.labels = [];
      this.seriesLineTeacher[0].data = [];
      this.chartOptionsLineTeacher.xaxis.categories = [];
      axios
        .post("/hr/getAllWorkGrades", {
          yearId: this.year_id,
        })
        .then((res) => {
          // console.log(res.data.teachers);
          //Бүх ажилчдын зэрэглэл
          //           this.getAllWorkGradesEmployees = res.data.getAllWorkGradesEmployees;
          //           this.seriesEmployees[0].data.push(
          //             res.data.zeregguiTooEmployee + res.data.zeregguiTooEmployeeUnActive
          //           );
          //           this.optionsEmployees.xaxis.categories.push("Шатлал бүртгэгдээгүй.");
          //           this.series1Employees.push(
          //             parseInt(
          //               res.data.zeregguiTooEmployee +
          //                 res.data.zeregguiTooEmployeeUnActive
          //             )
          //           );
          //           this.options1Employees.labels.push("Шатлал бүртгэгдээгүй.");
          //
          //           this.seriesLineEmployees[0].data.push(
          //             res.data.zeregguiTooEmployee + res.data.zeregguiTooEmployeeUnActive
          //           );
          //           this.chartOptionsLineEmployees.xaxis.categories.push(
          //             "Шатлал бүртгэгдээгүй."
          //           );
          //
          //           this.getAllWorkGradesEmployees.forEach((el) => {
          //             this.seriesEmployees[0].data.push(el.niit);
          //             this.optionsEmployees.xaxis.categories.push(
          //               el.salary_scale_short_name
          //             );
          //             this.series1Employees.push(parseInt(el.niit));
          //             this.options1Employees.labels.push(el.salary_scale_short_name);
          //
          //             this.seriesLineEmployees[0].data.push(el.niit);
          //             this.chartOptionsLineEmployees.xaxis.categories.push(
          //               el.salary_scale_short_name
          //             );
          //           });
          //Багш нарын зэрэглэл
          this.getAllWorkGradesTeacher = res.data.getAllWorkGradesTeacher;
          this.series[0].data.push(
            res.data.zeregguiTooTeacher
            // res.data.zeregguiTooTeacher + res.data.zeregguiTooTeacherUnActive
          );
          this.options.xaxis.categories.push("Шатлал бүртгэгдээгүй.");
          this.series1.push(parseInt(res.data.zeregguiTooTeacher));
          this.options1.labels.push("Шатлал бүртгэгдээгүй.");

          this.seriesLineTeacher[0].data.push(res.data.zeregguiTooTeacher);
          this.chartOptionsLineTeacher.xaxis.categories.push(
            "Шатлал бүртгэгдээгүй."
          );

          this.getAllWorkGradesTeacher.forEach((el) => {
            this.series[0].data.push(el.niit);
            this.options.xaxis.categories.push(
              el.salary_scale_short_name + " " + el.salary_scale_name
            );
            this.series1.push(parseInt(el.niit));
            this.options1.labels.push(el.salary_scale_short_name);

            this.seriesLineTeacher[0].data.push(el.niit);
            this.chartOptionsLineTeacher.xaxis.categories.push(
              el.salary_scale_short_name
            );
          });

          this.isShow = true;
        });
    },
    //
    //         getAllUsersStat(id) {
    //             // console.log(Array.isArray(this.seriesDep[0].data));
    //             // console.log(Array.isArray(this.optionsDep.xaxis.categories));
    //
    //             axios
    //                 .post("/hr/getAllUsersStat", { yearId: id })
    //                 .then(response => {
    //                     this.allEmployees = response.data.getAllEmployees;
    //                     this.getAllDeletedEmployees =
    //                         response.data.getAllDeletedEmployees;
    //
    //                     // Мэргэжлийн багийн бүтэц дахин шалгав
    //
    //                     // console.log(this.optionsDep);
    //                     // console.log(this.series1Dep);
    //                     // console.log(this.options1Dep);
    //
    //                     this.allEmployees.forEach(el => {
    //                         this.seriesDep[0].data.push(el.dep_too);
    //                         this.optionsDep.xaxis.categories.push(
    //                             el.dep_department_code
    //                         );
    //                         this.series1Dep.push(parseInt(el.dep_too));
    //                         this.options1Dep.labels.push(el.dep_department_code);
    //                     });
    //                     // console.log(this.seriesDep[0].data);
    //                     // console.log(this.optionsDep.xaxis.categories);
    //
    //                     this.getAllDeletedEmployees.forEach(el => {
    //                         this.series[0].data.push(el.dep_too);
    //                         this.options.xaxis.categories.push(
    //                             el.dep_department_code
    //                         );
    //                         this.series1.push(parseInt(el.dep_too));
    //                         this.options1.labels.push(el.dep_department_code);
    //                     });
    //
    //                     // Мэргэжлийн багийн бүтэц төгсгөл
    //                     // Насны бүтэц эхлэл
    //                     let filterNotZeroAsd = response.data.allAgesCheck;
    //                     // console.log(filterNotZeroAsd);
    //                     let myArr = [];
    //                     filterNotZeroAsd.forEach(element => {
    //                         // console.log(element.value);
    //                         if (element > 0) {
    //                             myArr.push(element);
    //                         }
    //                     });
    //                     // console.log(myArr);
    //                     let Niilber = filterNotZeroAsd.reduce((a, b) => a + b);
    //                     // console.log(Niilber);
    //
    //                     let filterNotZeroAvg = Niilber / myArr.length || 0;
    //                     // console.log(filterNotZeroAvg);
    //
    //                     let new2DataAgeCats = [
    //                         "20-25 нас",
    //                         "25-30 нас",
    //                         "30-35 нас",
    //                         "35-40 нас",
    //                         "40-45 нас",
    //                         "45-ээс дээш",
    //                         "Нас бүртгэгдээгүй",
    //                         "Дундаж"
    //                     ];
    //                     let new2DataAge = [
    //                         response.data.allAge20To25Count,
    //                         response.data.allAge25To30Count,
    //                         response.data.allAge30To35Count,
    //                         response.data.allAge35To40Count,
    //                         response.data.allAge40To45Count,
    //                         response.data.allAge45UpCount,
    //                         response.data.allAgeUnRegCount,
    //                         filterNotZeroAvg.toFixed(1)
    //                     ];
    //                     new2DataAgeCats.forEach(element => {
    //                         this.options2.xaxis.categories.push(element);
    //                     });
    //                     // this.options.xaxis.categories = [new1DataCats];
    //                     this.series2 = [{ data: new2DataAge }];
    //                     //Насны харьцааг хувиар илэрхийлэх
    //                     let new3DataAgeCatsLebels = [
    //                         "20-25 нас",
    //                         "25-30 нас",
    //                         "30-35 нас",
    //                         "35-40 нас",
    //                         "40-45 нас",
    //                         "45-ээс дээш",
    //                         "Нас бүртгэгдээгүй"
    //                     ];
    //                     new3DataAgeCatsLebels.forEach(element => {
    //                         this.options3.labels.push(element);
    //                     });
    //                     this.series3 = [
    //                         response.data.allAge20To25Count,
    //                         response.data.allAge25To30Count,
    //                         response.data.allAge30To35Count,
    //                         response.data.allAge35To40Count,
    //                         response.data.allAge40To45Count,
    //                         response.data.allAge45UpCount,
    //                         response.data.allAgeUnRegCount
    //                     ];
    //                     // Насны бүтэц төгсгөл
    //
    //                     // Хүйсийн бүтэц эхлэл
    //
    //                     let new4DataAgeCats = ["Эр", "Эм", "Хүйс бүртгэгдээгүй"];
    //                     let new4DataAge = [
    //                         response.data.allUsersMale,
    //                         response.data.allUsersFemale,
    //                         response.data.allUsersUnGender
    //                     ];
    //                     new4DataAgeCats.forEach(element => {
    //                         this.options4.xaxis.categories.push(element);
    //                     });
    //                     // this.options.xaxis.categories = [new1DataCats];
    //                     this.series4 = [{ data: new4DataAge }];
    //                     //Хүйсийн харьцааг хувиар илэрхийлэх
    //                     let new5DataAgeCatsLebels = [
    //                         "Эр",
    //                         "Эм",
    //                         "Хүйс бүртгэгдээгүй"
    //                     ];
    //                     new5DataAgeCatsLebels.forEach(element => {
    //                         this.options5.labels.push(element);
    //                     });
    //                     this.series5 = [
    //                         response.data.allUsersMale,
    //                         response.data.allUsersFemale,
    //                         response.data.allUsersUnGender
    //                     ];
    //                     // Хүйсийн бүтэц төгсгөл
    //                     this.isShow = true;
    //                 })
    //                 .catch(error => {
    //                     //   console.log(error);
    //                 });
    //         },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  // beforeMount() {
  //   this.getAllUsersStat();
  // },
  created() {
    this.getAllWorkGrades();
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
