<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Дээд цэс тохируулах</strong>
          </h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <th>№</th>
                <th>Төлөв</th>
                <th>Цэс</th>
                <th>Эрэмбэ</th>
                <th>Role</th>
                <th>Сургууль</th>
                <th>Үйлдэл</th>
              </thead>
              <tbody>
                <tr v-for="(menu, index) in topMenus" :key="menu.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <b-form-checkbox
                      :checked="`${getStatusBool(menu.status)}`"
                      :name="menu.status"
                      aria-describedby="active"
                      switch
                      size="xs"
                      @change="changeStatus(menu.id)"
                    >
                    </b-form-checkbox>
                  </td>
                  <td>
                    <i
                      v-if="menu.iconType == 'favicon'"
                      :class="[menu.icon, 'text-' + menu.variant]"
                    ></i>
                    <b-icon
                      v-if="menu.iconType == 'bootstrap'"
                      class="bootstrap"
                      :variant="menu.variant"
                      :icon="menu.icon"
                    ></b-icon>
                    <span>
                      {{ menu.url }}
                    </span>
                    <p>{{ menu.description }}</p>
                  </td>
                  <td>
                    {{ menu.order }}
                  </td>
                  <td>
                    <span>
                      <b-badge
                        variant="secondary"
                        class="ml-1 mt-1"
                        v-for="role in menu.my_roles"
                        :key="role.id"
                      >
                        {{ role.name }}
                      </b-badge>
                    </span>
                  </td>
                  <td>
                    <span>
                      <b-badge
                        variant="secondary"
                        class="ml-1 mt-1"
                        v-for="school in menu.my_schools"
                        :key="school.id"
                      >
                        {{ school.school_name }}
                      </b-badge>
                    </span>
                  </td>
                  <td>
                    <a href="#" @click.prevent="editTopMenu(menu)"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a href="#" @click.prevent="deleteTopMenu(menu)"
                      ><i class="fas fa-trash text-danger"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong v-if="!editTopMenuMode">Дээд цэс нэмэх</strong>
            <strong v-else>Дээд цэс засах</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Сургууль</label>
              <multiselect
                v-model="formTopMenu.selected_schools"
                :options="schools"
                :multiple="true"
                track-by="id"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Хасах"
                label="school_name"
                placeholder="Сургууль сонгоно уу?"
                :searchable="true"
                :close-on-select="false"
                :allow-empty="false"
              >
              </multiselect>
            </div>
            <div class="form-group">
              <label>Role</label>
              <multiselect
                v-model="formTopMenu.selected_roles"
                :options="roles"
                :multiple="true"
                track-by="id"
                :custom-label="customRoleName"
                placeholder="Role сонгох"
                :hideSelected="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                :searchable="true"
                :close-on-select="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.name }} - {{ option.description }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </div>
            <div class="form-group">
              <label>Тайлбар</label>
              <textarea
                type="text"
                name="description"
                v-model="formTopMenu.description"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formTopMenu.errors.has('description'),
                  },
                ]"
                placeholder="Тайлбар"
              >
              </textarea>
            </div>
            <div class="form-group">
              <label>Эрэмбэ</label>
              <input
                type="number"
                v-model="formTopMenu.order"
                placeholder="Эрэмбэ"
                class="form-control"
                :class="{ 'is-invalid': formTopMenu.errors.has('order') }"
              />
            </div>
            <div class="form-group">
              <label>Холбоос ( /-р эхэлнэ )</label>
              <input
                type="text"
                v-model="formTopMenu.url"
                placeholder="/student/home"
                class="form-control"
                :class="{ 'is-invalid': formTopMenu.errors.has('url') }"
              />
            </div>
            <div class="form-group">
              <label>Icon type</label>
              <b-form-select
                v-model="formTopMenu.iconType"
                :options="iconTypes"
                text-field="name"
                value-field="name"
                size="sm"
                class="form-control"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Icon color</label>
              <multiselect
                v-model="formTopMenu.variant"
                :options="variantOptions"
                :custom-label="customColorName"
                track-by="name"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Хасах"
                placeholder="Өнгө сонгоно уу?"
                :searchable="true"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <b-badge :variant="option.name" v-if="option.name">
                    {{ option.name.toUpperCase() }} сонгогдлоо
                  </b-badge>
                </template>
                <template slot="option" slot-scope="props">
                  <b-badge
                    :variant="props.option.name"
                    v-if="props.option.name"
                  >
                    {{ props.option.name.toUpperCase() }}
                  </b-badge>
                </template>
              </multiselect>
            </div>
            <div class="form-group">
              <label>Icon (favicon v 5.15.4)</label>
              <input
                type="text"
                v-model="formTopMenu.icon"
                placeholder="fa fa-pencil, pencil"
                class="form-control"
                :class="{ 'is-invalid': formTopMenu.errors.has('icon') }"
              />
            </div>
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-sm btn-success"
              @click.prevent="updateTopMenu()"
              v-if="editTopMenuMode"
            >
              Засах
            </b-button>
            <button
              v-if="editTopMenuMode"
              @click.prevent="cancelTopMenu()"
              class="btn btn-sm btn-warning"
            >
              Болих
            </button>
            <button
              type="submit"
              v-if="!editTopMenuMode"
              @click.prevent="createTopMenu()"
              class="btn btn-sm btn-primary"
            >
              Нэмэх
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      schools: [],
      editTopMenuMode: false,
      topMenus: [],
      formTopMenu: new Form({
        id: "",
        selected_schools: [],
        selected_roles: [],
        description: "",
        order: "",
        variant: { name: "dark" },
        url: "",
        iconType: "favicon",
        icon: "",
      }),
      roles: [],
      iconTypes: [{ name: "favicon" }, { name: "bootstrap" }],
      variantOptions: [
        { name: "dark" },
        { name: "secondary" },
        { name: "light" },
        { name: "primary" },
        { name: "info" },
        { name: "success" },
        { name: "warning" },
        { name: "danger" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getStatusBool(value) {
      if (value == "active") return true;
      else return false;
    },
    customRoleName({ name, description }) {
      return `${name}- ${description}`;
    },
    customColorName({ name }) {
      return `${name}`;
    },
    changeStatus(tMenuId) {
      this.action = "Календарь төлөвлөгөөний мэдээлэл шинэчилж байна...";
      axios
        .put("/owner/updateTopMenuStatus/" + tMenuId)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.getTopMenus();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getTopMenus() {
      axios
        .get("/owner/getTopMenus")
        .then((response) => {
          this.roles = response.data.roles;
          this.topMenus = response.data.topMenus;
        })
        .catch(() => {});
      axios
        .get("/owner/getAllSchools")
        .then((response) => {
          this.schools = response.data.activeSchools;
        })
        .catch(() => {});
    },
    //================== Top Menu ======================
    editTopMenu(tMenu) {
      this.formTopMenu.fill(tMenu);
      this.editTopMenuMode = true;
      this.formTopMenu.selected_roles = tMenu.my_roles;
      this.formTopMenu.selected_schools = tMenu.my_schools;
      this.formTopMenu.variant = { name: this.formTopMenu.variant };
    },
    updateTopMenu() {
      this.formTopMenu.variant = this.formTopMenu.variant.name;
      this.formTopMenu
        .put("/owner/updateTopMenu/" + this.formTopMenu.id)
        .then(() => {
          this.$toastr.s("Дээд цэс амжилттай заслаа.", "Амжилттай");
          this.getTopMenus();
          this.formTopMenu.reset();
          this.editTopMenuMode = false;
        })
        .catch(() => {});
    },
    createTopMenu() {
      this.formTopMenu.variant = this.formTopMenu.variant.name;
      this.formTopMenu
        .post("/owner/createTopMenu")
        .then(() => {
          this.$toastr.s("Дээд цэс амжилттай нэмлээ.", "Амжилттай");
          this.getTopMenus();
          this.formTopMenu.reset();
          this.editTopMenuMode = false;
        })
        .catch(() => {});
    },
    deleteTopMenu(tMenu) {
      Swal.fire({
        title: "Дээд цэс устгах!",
        text: "Дээд цэс устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Дээд цэс хасаж байна...";
          axios
            .get("/owner/deleteTopMenu/" + tMenu.id)
            .then((response) => {
              if (!response.data.errorMsj) {
                this.$toastr.s("Дээд цэс амжилттай устгалаа!", "Шинэчилсэн");
                this.getTopMenus();
              } else this.$toastr.w(response.data.errorMsj, "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelTopMenu() {
      this.formTopMenu.reset();
      this.editTopMenuMode = false;
    },
  },
  created() {
    this.getTopMenus();
  },
};
</script>

<style scoped>
</style>
