<template>
  <div class="row pt-3">
    <div class="col-md-12">
      <div
        class="card card-light"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Хүүхэд</h3>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow-x: auto">
          <b-button
            v-for="(child, i) in myChilds"
            :key="i"
            pill
            size="sm"
            variant="outline-primary"
            @click.prevent="getMyChildBehaveCourses(child)"
            class="mr-1"
          >
            {{ child.school_class_full_name }}
            {{ child.profile_student_name }}
          </b-button>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-md-12">
      <div class="card card-light">
        <div class="card-header">
          <h6 class="card-title" style="font-size: 80%">
            Хүмүүжлийн хичээл дээр суралцагчид хичээл, хөгжүүлэх сургалт, өөрийн
            түүх гэсэн 3 агуулгын хүрээнд анги удирдсан багш үнэлгээ
            хийнэ.(Улирлын дүн баталгаажаагүй үед 0 утгатай байна. АУБ үнэлгээ
            оруулснаар та бүхэн үнэлгээтэй танилцах боломжтой болно.)
          </h6>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>

        <div class="card-body" style="overflow-x: auto">
          <b-overlay :show="allGradeCheck" rounded="sm">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 10px">#</th>
                  <th>Хичээл</th>
                  <th>Багш</th>
                  <th
                    v-b-popover.hover.top="
                      'Тухайн улиралд хичээл, хөгжүүлэх хичээл, өөрийн түүхийг бүтээсэн байдал танилцах.'
                    "
                    title="Шалгалт"
                  >
                    Улир-1
                  </th>
                  <th
                    v-b-popover.hover.top="
                      'Тухайн улиралд хичээл, хөгжүүлэх хичээл, өөрийн түүхийг бүтээсэн байдал танилцах.'
                    "
                    title="Шалгалт"
                  >
                    Улир-2
                  </th>
                  <th
                    v-b-popover.hover.top="
                      'Тухайн улиралд хичээл, хөгжүүлэх хичээл, өөрийн түүхийг бүтээсэн байдал танилцах.'
                    "
                    title="Шалгалт"
                  >
                    Улир-3
                  </th>
                  <th
                    v-b-popover.hover.top="
                      'Тухайн улиралд хичээл, хөгжүүлэх хичээл, өөрийн түүхийг бүтээсэн байдал танилцах.'
                    "
                    title="Шалгалт"
                  >
                    Улир-4
                  </th>
                  <th>Дундаж(Улирал)</th>
                  <th
                    v-b-popover.hover.top="' Хувийн хэрэгт бичигдэнэ.'"
                    title="Жилийн эцсийн үнэлгээ"
                  >
                    Жил
                  </th>
                  <!-- <th>Үйлдэл</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(childCourse, i) in myChildCoursesGrades" :key="i">
                  <td>{{ ++i }}</td>
                  <td>{{ childCourse.subject_name }}</td>
                  <td>
                    {{ childCourse.emp_teacher_last_name[0].toUpperCase() }}.{{
                      childCourse.emp_teacher_name
                    }}
                  </td>
                  <td>
                    <b-button
                      pill
                      size="sm"
                      style="width: 130px"
                      variant="light"
                      @click.prevent="getQuarterExamAnalyse(childCourse, 1)"
                      ><span v-if="childCourse.grade_behavior_1 === null"
                        >0 ({{
                          symbolGradeCheck(childCourse.grade_behavior_1)
                        }})</span
                      >
                      <span v-if="childCourse.grade_behavior_1 !== null"
                        >{{ toOronTootsokh(childCourse.grade_behavior_1) }} ({{
                          symbolGradeCheck(childCourse.grade_behavior_1)
                        }})</span
                      >
                    </b-button>
                  </td>
                  <td>
                    <b-button
                      pill
                      size="sm"
                      style="width: 130px"
                      variant="light"
                      @click.prevent="getQuarterExamAnalyse(childCourse, 2)"
                    >
                      <span v-if="childCourse.grade_behavior_2 === null"
                        >0 ({{
                          symbolGradeCheck(childCourse.grade_behavior_2)
                        }})</span
                      >
                      <span v-if="childCourse.grade_behavior_2 !== null"
                        >{{ toOronTootsokh(childCourse.grade_behavior_2) }} ({{
                          symbolGradeCheck(childCourse.grade_behavior_2)
                        }})</span
                      >
                    </b-button>
                  </td>
                  <td>
                    <b-button
                      pill
                      size="sm"
                      style="width: 130px"
                      variant="light"
                      @click.prevent="getQuarterExamAnalyse(childCourse, 3)"
                    >
                      <span v-if="childCourse.grade_behavior_3 === null"
                        >0 ({{
                          symbolGradeCheck(childCourse.grade_behavior_3)
                        }})</span
                      >
                      <span v-if="childCourse.grade_behavior_3 !== null"
                        >{{ toOronTootsokh(childCourse.grade_behavior_3) }} ({{
                          symbolGradeCheck(childCourse.grade_behavior_3)
                        }})</span
                      >
                    </b-button>
                  </td>
                  <td>
                    <b-button
                      pill
                      size="sm"
                      style="width: 130px"
                      variant="light"
                      @click.prevent="getQuarterExamAnalyse(childCourse, 4)"
                    >
                      <span v-if="childCourse.grade_behavior_4 === null"
                        >0 ({{
                          symbolGradeCheck(childCourse.grade_behavior_4)
                        }})</span
                      >
                      <span v-if="childCourse.grade_behavior_4 !== null"
                        >{{ toOronTootsokh(childCourse.grade_behavior_4) }} ({{
                          symbolGradeCheck(childCourse.grade_behavior_4)
                        }})</span
                      >
                    </b-button>
                  </td>
                  <!-- Улирлын шалгалтын дундаж -->
                  <td>
                    <span
                      >{{ averageYearBefore(childCourse).toFixed(1) }} ({{
                        symbolGradeCheck(
                          averageYearBefore(childCourse).toFixed(1)
                        )
                      }})</span
                    >
                  </td>
                  <!-- Жилийн эцсийн баталгаажсан дүн -->
                  <td>
                    <b-button
                      pill
                      size="sm"
                      style="width: 130px"
                      variant="light"
                      @click.prevent="getYearExamAnalyse(childCourse, 4)"
                    >
                      <span v-if="childCourse.grade_behavior_jil === null"
                        >0 ({{
                          symbolGradeCheck(childCourse.grade_behavior_jil)
                        }})</span
                      >
                      <span v-if="childCourse.grade_behavior_jil !== null"
                        >{{
                          toOronTootsokh(childCourse.grade_behavior_jil)
                        }}
                        ({{
                          symbolGradeCheck(childCourse.grade_behavior_jil)
                        }})</span
                      >
                    </b-button>
                  </td>
                  <!-- <td></td> -->
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <div class="col-md-12" v-show="showExam">
      <div class="card card-primary">
        <div class="card-header bg-info color-palette">
          <div class="row">
            <div class="col-md-3">
              <h3 class="card-title">
                {{ courseExam.subject_name }} даалгаврууд
              </h3>
            </div>
            <div class="col-md-6">
              <multiselect
                v-model="selectChildCourseExam"
                :options="myExams"
                :searchable="true"
                :close-on-select="true"
                placeholder="Хүмүүжлийн хичээлийн даалгаврын төрөл сонгох"
                label="name"
                track-by="value"
                :custom-label="nameWithExams"
              >
              </multiselect>
            </div>
            <div class="col-md-3">
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-body"
          style="overflow-x: auto"
          v-show="selectChildCourseBehaviorValues.length"
        >
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Алхам-1</th>
                <th>Алхам-2</th>
                <th>Алхам-3</th>
                <th>Алхам-4</th>
                <th>Алхам-5</th>
                <th>Алхам-6</th>
                <th>Гүйцэтгэл(GPA)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(behavior, index) in selectChildCourseBehaviorValues"
                :key="index"
              >
                <td>{{ ++index }}</td>
                <td>
                  {{ transferInt(behavior.b_a1) }}
                </td>
                <td>
                  {{ transferInt(behavior.b_a2) }}
                </td>
                <td>
                  {{ transferInt(behavior.b_b1) }}
                </td>
                <td>
                  {{ transferInt(behavior.b_b2) }}
                </td>
                <td>
                  {{ transferInt(behavior.b_c1) }}
                </td>
                <td>
                  {{ transferInt(behavior.b_c2) }}
                </td>
                <td>
                  <span>{{ averageBehaviorStep(behavior) }}</span>
                  <span class="badge badge-info"
                    >({{
                      symbolGradeCheck(averageBehaviorStep(behavior))
                    }})</span
                  >
                </td>
              </tr>
              <tr v-if="selectChildCourseBehaviorValues.length != 0">
                <td>Дундаж гүйцэтгэл</td>
                <td>{{ behaviorPerValuesAverage.behavior1Ave.toFixed(1) }}</td>
                <td>{{ behaviorPerValuesAverage.behavior2Ave.toFixed(1) }}</td>
                <td>{{ behaviorPerValuesAverage.behavior3Ave.toFixed(1) }}</td>
                <td>{{ behaviorPerValuesAverage.behavior4Ave.toFixed(1) }}</td>
                <td>{{ behaviorPerValuesAverage.behavior5Ave.toFixed(1) }}</td>
                <td>{{ behaviorPerValuesAverage.behavior6Ave.toFixed(1) }}</td>
                <td>
                  {{ behaviorPerValuesAverage.behavior1_6Ave.toFixed(1) }}
                  <span class="badge badge-info">
                    ({{
                      symbolGradeCheck(
                        behaviorPerValuesAverage.behavior1_6Ave.toFixed(1)
                      )
                    }})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="light"
          ></b-table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selectedChildId: null,
      editMode: false,
      myChilds: [],
      myChildCourses: [],
      myChildCoursesGrades: [],

      //Хичээлээр шалгалтыг шүүж харах.
      showExam: false,
      courseExam: "",
      myExams: [
        { value: "1", name: "Хичээл" },
        { value: "2", name: "Хөгжүүлэх" },
        { value: "3", name: "Өөрийн түүхээ бүтээсэн байдал" },
      ],
      selectChildCourseExam: "",
      selectChildCourseBehaviorValues: [],
      behaviorPerValuesAverage: [],
      allGradeCheck: true,
      checkQuarterName: "",

      //Хүмүүжлийн хөтөлбөрийн цагаар дараах чадваруудыг төлөвшүүлэхийг зорино.
      fields: [
        {
          key: "number",
          label: "№",
          sortable: false,
          //   variant: "danger",
        },
        {
          key: "name",
          label: "Дадал",
          sortable: false,
          //   variant: "danger",
        },
        {
          key: "explain",
          label: "Тайлбар",
          sortable: false,
          //   variant: "danger",
        },
      ],
      items: [
        {
          number: 1,
          name: "“Ажлыг эхлэхдээ төгсгөлийг нь бод” дадал",
          explain:
            "Зорилгоо нэгэн утгатай тодорхой болгож, хүлээгдэж буй үр дүнг дүрслэн төсөөлөх чадвар ",
        },
        {
          number: 2,
          name: "“Долоо хэмжиж нэг огтол” дадал",
          explain: "Цуглуулсан мэдээлэл бодитой эсэхийг нягтлах чадвар",
        },
        {
          number: 3,
          name: "“Эзэн хичээвэл заяа хичээнэ” дадал",
          explain: "Өөрөө өөртэйгөө ярилцах, үйл ажиллагаагаа төлөвлөх чадвар ",
        },
        {
          number: 4,
          name: "“Оролдлого сайт оройд нь” дадал",
          explain:
            "Гүйцэтгэлээ хянах, өөр өөр арга замаар оролдлого хийж хүлээгдэж буй үр дүндээ хүрэх чадвар",
        },
        {
          number: 5,
          name: "“Нөхрөө харж биеэ, сүүдрээ харж бүсээ” дадал",
          explain:
            "Зорилго, хэрэгжилт, үр дүнгийн талаарх бусдын санаа бодлыг сонсох чадвар",
        },
        {
          number: 6,
          name: "“Дэм дэмэндээ дээс эрчиндээ” дадал",
          explain:
            "Бусдыг өөртөө итгүүлэх, хамтран ажиллах замаар тэдэнд хүлээн зөвшөөрөгдөх чадвар ",
        },
      ],
    };
  },
  components: { Multiselect },
  watch: {
    selectChildCourseExam: {
      handler: function (newVal, oldVal) {
        if (oldVal !== null) {
          //   console.log(newVal, oldVal);
          this.getExamValues(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    getMyChilds() {
      axios
        .get("/parent/mychilds")
        .then((res) => {
          //   console.log(res.data);
          this.myChilds = res.data.mychilds;
          if (this.myChilds.length > 0) {
            this.selectedChildId = this.myChilds[0].profile_user_id;
            this.getSelectedChild();
          }
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getMyChildBehaveCourses(child) {
      //   console.log("my courses");
      this.showExam = false;
      this.myChildCourses = [];
      this.myChildCoursesGrades = [];
      this.courseExam = "";
      this.myChildCourseExams = [];
      this.selectChildCourseExam = "";
      this.selectChildCourseBehaviorValues = [];
      this.selectedChildId = child.profile_user_id;
      this.getSelectedChild();
    },
    getSelectedChild() {
      this.allGradeCheck = true;
      axios
        .post("/parent/getMyChildBehaveCourses", {
          childId: this.selectedChildId,
        })
        .then((res) => {
          this.allGradeCheck = false;
          this.myChildCourses = res.data.myChildCourses;
          this.myChildCoursesGrades = res.data.myChildCoursesGrades;
          //   console.log(res.data);
        })
        .catch();
    },
    averageYearBefore(childCourse) {
      let average =
        (childCourse.grade_behavior_1 +
          childCourse.grade_behavior_2 +
          childCourse.grade_behavior_3 +
          childCourse.grade_behavior_4) /
        4;
      return average;
    },
    //явц улиралын шалгалтууд дуудах
    getQuarterExamAnalyse(course, quarter_id) {
      //   console.log(course);
      this.showExam = false;
      this.selectChildCourseBehaviorValues = [];
      this.behaviorPerValuesAverage = [];
      //   console.log(course);
      this.courseExam = course;
      this.selectChildCourseExam = { value: "1", name: "Хичээл" };
      this.checkQuarterName = quarter_id;
      this.showExam = true;
    },
    //жилийн шалгалтууд дуудах
    getYearExamAnalyse(course) {
      this.showExam = false;
      //   console.log(course, quarter_id);
      this.courseExam = course;
      axios
        .post("/parent/getMyChildCourseExamsYears", {
          course,
        })
        .then((res) => {
          //   console.log(res.data);
          //   this.myChildCourseExams = res.data.getMyChildCourseExams;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      this.showExam = true;
    },

    getExamValues(newVal) {
      this.selectChildCourseBehaviorValues = [];
      axios
        .post("/parent/examBehaveValues", {
          examType: newVal,
          courseExam: this.courseExam,
          checkQuarterName: this.checkQuarterName,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectChildCourseBehaviorValues = res.data.examBehaveValues;
          this.calculateAverageExamValues(this.selectChildCourseBehaviorValues);
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    transferInt(value) {
      return parseInt(value);
    },
    averageBehaviorStep(behavior) {
      let averageExam =
        (parseFloat(behavior.b_a1) +
          parseFloat(behavior.b_a2) +
          parseFloat(behavior.b_b1) +
          parseFloat(behavior.b_b2) +
          parseFloat(behavior.b_c1) +
          parseFloat(behavior.b_c2)) /
        6;

      return averageExam.toFixed(1);
    },
    calculateAverageExamValues(values) {
      //   console.log(values);
      let b_a1 = [];
      let b_a2 = [];
      let b_b1 = [];
      let b_b2 = [];
      let b_c1 = [];
      let b_c2 = [];
      let behavior1_6 = [];
      values.forEach((element) => {
        b_a1.push(parseFloat(element.b_a1));
        b_a2.push(parseFloat(element.b_a2));
        b_b1.push(parseFloat(element.b_b1));
        b_b2.push(parseFloat(element.b_b2));
        b_c1.push(parseFloat(element.b_c1));
        b_c2.push(parseFloat(element.b_c2));
        behavior1_6.push(
          parseFloat(
            element.b_a1 +
              element.b_a2 +
              element.b_b1 +
              element.b_b2 +
              element.b_c1 +
              element.b_c2
          )
        );
      });
      //   console.log(b_a1, b_a2, b_b1, b_b2, b_c1, b_c2, behavior1_6);
      let behavior1Sum = b_a1.reduce(function (a, b) {
        return a + b;
      });
      let behavior2Sum = b_a2.reduce(function (a, b) {
        return a + b;
      });
      let behavior3Sum = b_b1.reduce(function (a, b) {
        return a + b;
      });
      let behavior4Sum = b_b2.reduce(function (a, b) {
        return a + b;
      });
      let behavior5Sum = b_c1.reduce(function (a, b) {
        return a + b;
      });
      let behavior6Sum = b_c2.reduce(function (a, b) {
        return a + b;
      });

      let behavior1_6Sum = behavior1_6.reduce(function (a, b) {
        return a + b;
      });
      //   console.log(
      //     behavior1Sum,
      //     behavior2Sum,
      //     behavior3Sum,
      //     behavior4Sum,
      //     behavior5Sum,
      //     behavior6Sum,
      //     behavior1_6Sum
      //   );
      this.behaviorPerValuesAverage.behavior1Ave = behavior1Sum / b_a1.length;
      this.behaviorPerValuesAverage.behavior2Ave = behavior2Sum / b_a2.length;
      this.behaviorPerValuesAverage.behavior3Ave = behavior3Sum / b_b1.length;
      this.behaviorPerValuesAverage.behavior4Ave = behavior4Sum / b_b2.length;
      this.behaviorPerValuesAverage.behavior5Ave = behavior5Sum / b_c1.length;
      this.behaviorPerValuesAverage.behavior6Ave = behavior6Sum / b_c2.length;

      this.behaviorPerValuesAverage.behavior1_6Ave =
        behavior1_6Sum / behavior1_6.length;
      //   console.log(this.behaviorPerValuesAverage);
    },
    nameWithExams({ name }) {
      return `${name}`;
    },

    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
    toOronTootsokh(value) {
      return parseFloat(value).toFixed(1);
    },
  },
  created() {
    this.getMyChilds();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
