<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Элсэгчдийн жагсаалт</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="schoolClassesCheck"
                    text-field="text"
                    value-field="value"
                    size="sm"
                  >
                  </b-form-select>
                  <has-error :form="form" field="value"></has-error>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllEntrants"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Ургийн овог</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Цахим шуудан</th>
                <th>Анги</th>
                <th>Үүсгэсэн</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody
              v-for="(schoolEntrant, index) in schoolentrants"
              :key="schoolEntrant.user_id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_family_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    v-if="schoolEntrant.study_class === '-1'"
                  >
                    Цэцэрлэг
                  </b-badge>
                  <b-badge
                    variant="success"
                    class="text-wrap"
                    v-else-if="schoolEntrant.study_class === '0'"
                  >
                    Бэлтгэл анги
                  </b-badge>
                  <b-badge variant="info" class="text-wrap" v-else
                    >{{ schoolEntrant.study_class }} анги
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.created_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="editSchoolEntrant(schoolEntrant)"
                    ><i class="fa fa-check"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">Анги хуваарилах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="editMode ? updateSchoolEntrant() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="profile_last_name"
                v-model="form.profile_last_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('profile_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="profile_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="user_name"
                v-model="form.user_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="user_name"></has-error>
            </div>
            <div class="form-group">
              <label>Имэйл</label>
              <input
                type="email"
                name="user_email"
                v-model="form.user_email"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_email'),
                  },
                ]"
                placeholder="Цахим шуудан"
              />
              <has-error :form="form" field="user_email"></has-error>
            </div>
            <div class="form-group">
              <label>Харьяалагдах анги</label>
              <multiselect
                v-model="form.profile_school_class_id"
                :options="schoolClassesOptions"
                :multiple="false"
                track-by="id"
                label="full_name"
                :custom-label="customSchoolClassName"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                placeholder="Анги сонгох"
                :allow-empty="true"
                deselect-label="Can't remove this value"
              >
              </multiselect>
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong> {{ option.full_name }}</strong
                ><strong> сонгогдлоо</strong></template
              >
            </div>
            <div class="form-group">
              <label>Харьяалагдах хичээлийн жил</label>
              <b-form-select
                v-model="form.schoolYearId"
                :options="years"
                text-field="year_name"
                value-field="id"
              >
              </b-form-select>
              <has-error :form="form" field="year_name"></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-success" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selected: "0",
      school_classes: [
        { value: "-1", text: "Цэцэрлэг" },
        { value: "0", text: "Бэлтгэл анги" },
        { value: "1", text: "1-р анги" },
        { value: "2", text: "2-р анги" },
        { value: "3", text: "3-р анги" },
        { value: "4", text: "4-р анги" },
        { value: "5", text: "5-р анги" },
        { value: "6", text: "6-р анги" },
        { value: "7", text: "7-р анги" },
        { value: "8", text: "8-р анги" },
        { value: "9", text: "9-р анги" },
        { value: "10", text: "10-р анги" },
        { value: "11", text: "11-р анги" },
        { value: "12", text: "12-р анги" },
      ],
      action: "",
      schoolYears: [],
      editMode: false,
      schoolentrants: [],
      //   school_classes: [],
      form: new Form({
        id: "",
        user_name: "",
        profile_last_name: "",
        user_email: "",
        profile_school_class_id: "",
        schoolYearId: "",
        user_id: "",
      }),
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    years: function () {
      return this.$store.state.years;
    },
    allSchoolClasses: function () {
      return this.$store.state.allSchoolPriMidHighClasses;
    },
    allSchoolKGClasses: function () {
      return this.$store.state.allSchoolKGClasses;
    },
    schoolClassesCheck: function () {
      let a = [];
      if (this.userInfo.name == "agermankg") {
        a = this.school_classes.filter((el) => el.value === "-1");
        // console.log(a);
        // a.push(this.school_classes[0]);
      } else if (
        this.userInfo.name == "cipalprin" ||
        this.userInfo.name == "minadcurri" ||
        this.userInfo.name == "hr" ||
        this.userInfo.name == "adminOfficer"
      ) {
        a = this.school_classes;
      } else if (
        this.userInfo.name == "agerman" ||
        this.userInfo.name == "reg_emp"
      ) {
        a = this.school_classes.filter(
          (el) => el.value !== "-1" && el.value !== "0"
        );
      }
      return a;
    },

    // b-select options
    schoolClassesOptions() {
      return this.userInfo.name === "agermankg"
        ? this.allSchoolKGClasses
        : this.allSchoolClasses;
    },
  },
  components: {
    Multiselect,
  },

  methods: {
    getAllEntrants() {
      axios
        .get("/manager/getPaidEntrants/" + this.selected)
        .then((response) => {
          this.schoolentrants = response.data.schoolentrants;
          //   this.school_classes = response.data.school_classes;
          //   this.schoolYears = response.data.schoolYears;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
      this.form.school_class = schoolEntrant.school_class_id;
      this.form.schoolYearId = schoolEntrant.year_id;
    },
    updateSchoolEntrant() {
      this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateEntrantActive/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    customSchoolClassName({ class_number, full_name, class_cabinet }) {
      if (
        parseInt(class_number) == 0 ||
        parseInt(class_number) == -1 ||
        parseInt(class_number) == -2
      ) {
        return `${class_cabinet}`;
      } else {
        return `${full_name} анги`;
      }
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
