<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title"></h3>
          <b-button
            block
            variant="outline-info"
            size="sm"
            @click.prevent="createBbzProblem()"
          >
            ББЗ циклийн дагуу боловсруулсан миний даалгавар</b-button
          >
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Багш</th>
                <th>Анги</th>
                <th>Хичээл</th>
                <th>Даалгаврын нэр</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(bbzProblem, index) in getMyBbzproblems"
                :key="bbzProblem.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: left"
                  tabindex="0"
                >
                  <span
                    v-if="bbzProblem.my_owner_employee_info.teacher_last_name"
                  >
                    {{
                      bbzProblem.my_owner_employee_info.teacher_last_name[0]
                    }} </span
                  >.
                  {{ bbzProblem.my_owner_employee_info.teacher_name }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: left"
                  tabindex="0"
                >
                  {{ bbzProblem.my_owner_school_class_info.full_name }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: left"
                  tabindex="0"
                >
                  {{ bbzProblem.my_owner_subject_info.subject_name }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: left"
                  tabindex="0"
                >
                  {{ bbzProblem.general_question_name }}
                </td>

                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="editBbzProblem(bbzProblem)"
                  >
                    <b-icon icon="pen-fill"></b-icon>
                  </a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click.prevent="viewBbzProblem(bbzProblem)"
                  >
                    <b-icon icon="eye-fill"></b-icon>
                  </a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click.prevent="deleteBbzProblem(bbzProblem)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-bbzProblemInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Үүрэг даалгавар"
      class="modal fade"
      hide-footer
      @show="showModal"
      @hide="hideModalCancel"
      @ok="hideModalOk"
    >
      <template #modal-title>
        <span v-show="!editMode"> ББЗ даалгавар боловсруулах</span>
        <span v-show="editMode"> ББЗ даалгавар засах</span>
      </template>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <b-form-input
              v-model="form.general_question_name"
              placeholder="Ээлжит хичээлийн алхмуудтай холбох тул нэршлийг багш та өөртөө ялгахаар бодож өгөөрэй."
            ></b-form-input>
          </div>
          <div class="col-lg-12 mt-2">
            <multiselect
              v-model="form.course_id"
              label="course_id"
              track-by="course_id"
              :options="courses"
              :custom-label="customLabelTeacher"
              :searchable="true"
              :allow-empty="true"
              placeholder="Багш, Хичээл, анги сонгох"
            ></multiselect>
          </div>
        </div>
        <div class="row mt-3" v-if="form.course_id">
          <div class="col-lg">
            <strong>Багш: </strong>
            {{ form.course_id.teacher_last_name[0] }}.
            {{ form.course_id.name }}
          </div>
          <div class="col-lg-3">
            <strong>Хичээл: </strong>
            {{ form.course_id.subject_name }}
          </div>
          <div class="col-lg-3">
            <strong>Анги: </strong> {{ form.course_id.full_name }}
          </div>
          <div class="col-lg-3">
            <strong>Хичээлийн төрөл: </strong>{{ form.course_id.LessonType }}
          </div>
        </div>
        <div>
          <quill-editor
            v-model="form.general_question"
            :options="editorOptionGeQuestion"
          />
          <div class="row">
            <div class="col-lg-6">
              <multiselect
                v-model="form.tax_verb_id"
                label="id"
                track-by="id"
                :options="taxanomyLevNameOperations"
                :custom-label="customLabelTaxVerb"
                :searchable="true"
                :allow-empty="true"
                placeholder="Оюуны үйлийн ай сонгох"
              ></multiselect>
            </div>
            <div class="col-lg-6">
              <multiselect
                v-model="form.tax_noun_id"
                label="id"
                track-by="id"
                :options="taxanomyKnowledgeNameOperations"
                :custom-label="customLabelTaxNoun"
                :searchable="true"
                :allow-empty="true"
                placeholder="Мэдлэгийн ай сонгох"
              ></multiselect>
            </div>
          </div>
          <!-- <b-form-textarea
            v-model="form.general_question"
            id="textarea"
            placeholder="ББЗ циклээр зохион байгуулах даалгавар. Ерөнхий асуулт."
            rows="3"
            max-rows="12"
          ></b-form-textarea> -->
        </div>
        <div class="row" v-if="step1">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-1</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row" size="0.8">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[0]["levels"][0]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      v-for="(oper, i) in taxanomyInfo[0][`levels`][0][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>

                      <b-list-group>
                        <b-list-group-item
                          v-for="(o, ind) in oper.verbs"
                          :key="ind"
                        >
                          {{ o.tax_lev_name_operation_verb }}-{{
                            o.tax_lev_name_operation_verb_explain
                          }}
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 1-ын асуулт</h3>
                &nbsp;&nbsp;
                <!-- ref="myQuillEditor" -->
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    v-model="step1Prob.question"
                    :options="editorOption"
                  />
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step1Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step1Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step1Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step2Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step2">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-2</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[1]["levels"][0]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[1][`levels`][0][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 2-ын асуулт</h3>
                &nbsp;&nbsp;
                <a
                  href="https://katex.org/docs/supported.html"
                  target="_blank"
                  ref="myQuillEditor"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    v-model="step2Prob.question"
                    :options="editorOption"
                  />
                  <!-- @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onEditorChange($event)" -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step2Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step2Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step2Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step3Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step3">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-3</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[2]["levels"][0]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[2][`levels`][0][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 3-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
                <!-- ref="myQuillEditor" -->
              </div>
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    v-model="step3Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step3Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step3Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step3Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step3Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step4Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step4">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-4</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[2]["levels"][1]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[2][`levels`][1][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 4-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
                <!-- ref="myQuillEditor" -->
              </div>
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    v-model="step4Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step4Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step4Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step4Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step4Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step5Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step5">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-5</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[2]["levels"][2]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[2][`levels`][2][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 5-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <!-- ref="myQuillEditor" -->
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    v-model="step5Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step5Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step5Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step5Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step5Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step6Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step6">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-6</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[2]["levels"][3]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[2][`levels`][3][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 6-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <!-- ref="myQuillEditor" -->
                <div>
                  <quill-editor
                    v-model="step6Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step6Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step6Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step6Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step6Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step7Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step7">
          <!-- <div class="col-lg-6 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-7</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[3]["levels"][0]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[3][`levels`][0][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 7-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  <!-- ref="myQuillEditor" -->
                  <quill-editor
                    v-model="step7Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step7Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step7Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step7Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <b-button
                v-show="!step7Btn"
                variant="outline-primary"
                block
                size="1"
                @click="step8Done()"
                >Дараагийн алхамд шилжих</b-button
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="step8">
          <!-- <div class="col-lg- mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам-8</h3>
              </div>
              <div class="card-body table-responsive">
                <div class="row">
                  <div class="col-md-3">
                    <b-list-group>
                      <b-list-group-item class="sm">
                        {{ taxanomyInfo[4]["levels"][0]["tax_lev_name"] }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="col-md-9">
                    <span
                      style="word-wrap: break-word"
                      size="1"
                      v-for="(oper, i) in taxanomyInfo[4][`levels`][0][
                        `operations`
                      ]"
                      :key="i"
                    >
                      <b-badge variant="info" size="0.8">
                        {{ oper.tax_lev_name_operation }}
                      </b-badge>
                      <h6>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(o, ind) in oper.verbs"
                            :key="ind"
                            >{{ o.tax_lev_name_operation_verb }}-{{
                              o.tax_lev_name_operation_verb_explain
                            }}</b-list-group-item
                          >
                        </b-list-group>
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 8-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="step8Prob.question"
                    :options="editorOption"
                  />
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="step8Prob.question"
                    :config="editorConfig"
                  ></ckeditor> -->
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step8Prob.tax_verb_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyLevNameOperations"
                      :custom-label="customLabelTaxVerb"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Оюуны үйлийн ай сонгох"
                    ></multiselect>
                  </div>
                  <div class="col-lg-6">
                    <multiselect
                      v-model="step8Prob.tax_noun_id"
                      label="id"
                      track-by="id"
                      :options="taxanomyKnowledgeNameOperations"
                      :custom-label="customLabelTaxNoun"
                      :searchable="true"
                      :allow-empty="true"
                      placeholder="Мэдлэгийн ай сонгох"
                    ></multiselect>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button variant="danger" class="mt-3" block @click="close()">
          Хаах
        </b-button>
        <b-button
          type="submit"
          variant="success"
          class="mt-3"
          block
          v-show="!editMode"
          @click="storeBbzProblem()"
          >Хадгалах</b-button
        >
        <b-button
          type="submit"
          variant="warning"
          class="mt-3"
          block
          v-show="editMode"
          @click="updateBbzProblem()"
          >Засах</b-button
        >
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <!-- Даалгаврын дэлгэрэнгүй мэдээлэл харах -->
    <b-modal
      size="xl"
      id="modal-bbzProblemMoreInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Даалгавар"
      class="modal fade"
      hide-footer
      @hide="hideModalCancel"
    >
      <!-- @ok="hideModalOk"
      @show="showModal" -->
      <template #modal-title>
        <span> ББЗ даалгавар</span>
      </template>
      <div class="modal-body">
        <!-- <div class="row">
          <div class="col-lg">
            <span v-if="form.course_id"
              >{{ form.course_id.teacher_last_name[0] }}.
              {{ form.course_id.name }}({{ form.course_id.full_name }}:
              {{ form.course_id.subject_name }})
            </span>
          </div>
        </div> -->
        <div class="row mt-3" v-if="form.course_id">
          <div class="col-lg">
            <strong>Багш: </strong>
            {{ form.course_id.teacher_last_name[0] }}.
            {{ form.course_id.name }}
          </div>
          <div class="col-lg-3">
            <strong>Хичээл: </strong>
            {{ form.course_id.subject_name }}
          </div>
          <div class="col-lg-3">
            <strong>Анги: </strong> {{ form.course_id.full_name }}
          </div>
          <div class="col-lg-3">
            <strong>Хичээлийн төрөл: </strong>{{ form.course_id.LessonType }}
          </div>
        </div>
        <div>
          <quill-editor
            v-model="form.general_question"
            :options="editorOptionView"
          />
          <div class="row">
            <div class="col-lg-6">
              <span v-if="form.tax_verb_id">
                <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                {{ form.tax_verb_id.tax_lev_name_operation }}
              </span>
            </div>
            <div class="col-lg-6">
              <span v-if="form.tax_noun_id">
                <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                {{ form.tax_noun_id.tax_know_lev_name_operation }}
              </span>
            </div>
          </div>
        </div>
        <div class="row" v-if="step1">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 1-ын асуулт</h3>
                &nbsp;&nbsp;
                <!-- ref="myQuillEditor" -->
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[0]["levels"][0]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step1Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step1Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step1Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step1Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step1Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step2">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 2-ын асуулт</h3>
                &nbsp;&nbsp;
                <a
                  href="https://katex.org/docs/supported.html"
                  target="_blank"
                  ref="myQuillEditor"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[1]["levels"][0]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step2Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step2Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step2Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step2Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step2Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step3">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 3-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[2]["levels"][0]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step3Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step3Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step3Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step3Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step3Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step4">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 4-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[2]["levels"][1]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step4Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step4Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step4Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step4Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step4Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step5">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 5-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[2]["levels"][2]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step5Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step5Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step5Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step5Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step5Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step6">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 6-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[2]["levels"][3]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step6Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step6Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step6Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step6Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step6Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step7">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 7-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[3]["levels"][0]["tax_lev_name"] }}
                  <quill-editor
                    v-model="step7Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step7Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step7Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step7Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step7Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="step8">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Алхам 8-ын асуулт</h3>
                &nbsp;&nbsp;
                <a href="https://katex.org/docs/supported.html" target="_blank"
                  >Томъёо(заавар)</a
                >
              </div>
              <div class="card-body table-responsive">
                <div>
                  {{ taxanomyInfo[4]["levels"][0]["tax_lev_name"] }}
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="step8Prob.question"
                    :options="editorOptionView"
                  />
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="step8Prob.tax_verb_id">
                        <b-badge variant="warning"> Оюуны үйлийн ай: </b-badge>
                        {{ step8Prob.tax_verb_id.tax_lev_name_operation }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span v-if="step8Prob.tax_noun_id">
                        <b-badge variant="warning"> Мэдлэгийн ай: </b-badge>
                        {{ step8Prob.tax_noun_id.tax_know_lev_name_operation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button variant="danger" class="mt-3" block @click="closeBBZInfo()">
          Хаах
        </b-button>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import katex from "katex";
import "katex/dist/katex.min.css";
export default {
  data() {
    return {
      //   vue-quill-editor
      // ерөнхий асуултын quill тохиргоо
      editorOptionGeQuestion: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder:
          "8 асуулт бүхий цикл даалгаварт хамаарах ерөнхий асуулт. Ерөнхий асуулт нь аль оюуны үйлийн ай болон мэдлэгийн айд харгалзаж байгааг сонгоно уу?",
      },
      // 8 асуултын Quill тохиргоо
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder:
          "Асуулт нь аль оюуны үйлийн ай болон мэдлэгийн айд харгалзаж байгааг сонгоно уу?",
      },
      editorOptionView: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: false,
          //   toolbar: [
          //     // [{ size: [] }],
          //     // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          //     // ["bold", "italic", "underline", "strike"],
          //     // [{ color: [] }, { background: [] }],
          //     // [{ script: "super" }, { script: "sub" }],
          //     // [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
          //     // [
          //     //   { list: "ordered" },
          //     //   { list: "bullet" },
          //     //   { indent: "-1" },
          //     //   { indent: "+1" },
          //     // ],
          //     // [{ direction: "rtl" }, { align: [] }],
          //     // ["link", "image", "video", "formula"],
          //     // ["clean"],
          //     false,
          //   ],
          //   imageDrop: true,
          //   imageResize: {
          //     modules: ["Resize", "DisplaySize", "Toolbar"],
          //     handleStyles: {
          //       backgroundColor: "black",
          //       border: "none",
          //       //   color: white,
          //       // other camelCase styles for size display
          //     },
          //     displayStyles: {
          //       backgroundColor: "black",
          //       border: "none",
          //       //   color: white,
          //       // other camelCase styles for size display
          //     },
          //   },
          //   imageCompress: {
          //     quality: 0.6, // default
          //     maxWidth: 800, // default
          //     // maxHeight: 1000, // default
          //     imageType: ["image/jpeg", "image/png"], // default
          //     debug: false, // default
          //     suppressErrorLogging: false, // default
          //     insertIntoEditor: undefined, // default
          //   },
        },
      },

      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      selectedCourse: "",

      getMyBbzproblems: [],
      courses: [],
      taxanomyInfo: [],
      taxanomyLevNameOperations: [],
      taxanomyKnowledgeNameOperations: [],
      step1Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 1,
        question: "",
      },
      step2Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 2,
        question: "",
      },
      step3Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 3,
        question: "",
      },
      step4Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 4,
        question: "",
      },
      step5Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 5,
        question: "",
      },
      step6Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 6,
        question: "",
      },
      step7Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 7,
        question: "",
      },
      step8Prob: {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 8,
        question: "",
      },
      step1Btn: false,
      step2Btn: false,
      step3Btn: false,
      step4Btn: false,
      step5Btn: false,
      step6Btn: false,
      step7Btn: false,
      step8Btn: false,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      step8: false,
      editMode: false,
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        department_id: "",
        eval_emp_id: "",
        create_emp_id: "",
        create_emp_pos_id: "",
        course_id: "",
        subject_id: "",
        school_class_id: "",
        general_question_name: "",
        general_question: "",
        tax_verb_id: null,
        tax_noun_id: null,
        unelgee_problem: "",
        comment_problem: "",
        bbzCycleProblemSteps: [],
      }),
    };
  },
  components: { Multiselect },
  computed: {
    // editor() {
    // return this.$refs.myQuillEditor;
    // },
  },
  methods: {
    onEditorBlur(quill) {
      //   console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      //   console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      //   console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      //   console.log("editor change!", quill, html, text);
      this.content = html;
    },
    getAllProblems() {
      axios
        .get("/teacher/getbbzMyCourseInfo")
        .then((response) => {
          this.courses = response.data.courses;
          this.getMyBbzproblems = response.data.getMyBbzproblems;
          this.taxanomyInfo = response.data.taxanomyInfo;
          this.taxanomyLevNameOperations =
            response.data.taxanomyLevNameOperations;
          this.taxanomyKnowledgeNameOperations =
            response.data.taxanomyKnowledgeNameOperations;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    editBbzProblem(bbzProblem) {
      // console.log(bbzProblem);
      this.editMode = true;
      this.form.reset();

      let mySelectCourse = this.courses.filter(
        (el) => el.course_id === bbzProblem.course_id
      );
      this.form.id = bbzProblem.id;
      this.form.course_id = mySelectCourse[0];
      this.form.general_question_name = bbzProblem.general_question_name;
      this.form.general_question = bbzProblem.general_question;
      this.taxanomyLevNameOperations.forEach((element) => {
        if (element.id == bbzProblem.tax_verb_id) {
          this.form.tax_verb_id = element;
        }
      });
      this.taxanomyKnowledgeNameOperations.forEach((element) => {
        if (element.id == bbzProblem.tax_noun_id) {
          this.form.tax_noun_id = element;
        }
      });

      if (bbzProblem.my_problem_steps.length != 0) {
        this.step1Prob.question = bbzProblem.my_problem_steps[0]["question"];
        this.step1Prob.id = bbzProblem.my_problem_steps[0]["id"];
        this.taxanomyLevNameOperations.forEach((element) => {
          bbzProblem.my_problem_steps.forEach((steps) => {
            if (element.id == steps.tax_verb_id && steps.step == 1) {
              this.step1Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 2) {
              this.step2Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 3) {
              this.step3Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 4) {
              this.step4Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 5) {
              this.step5Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 6) {
              this.step6Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 7) {
              this.step7Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 8) {
              this.step8Prob.tax_verb_id = element;
            }
          });
        });
        this.taxanomyKnowledgeNameOperations.forEach((element) => {
          bbzProblem.my_problem_steps.forEach((steps) => {
            if (element.id == steps.tax_noun_id && steps.step == 1) {
              this.step1Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 2) {
              this.step2Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 3) {
              this.step3Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 4) {
              this.step4Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 5) {
              this.step5Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 6) {
              this.step6Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 7) {
              this.step7Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 8) {
              this.step8Prob.tax_noun_id = element;
            }
          });
        });
        this.step2Prob.question = bbzProblem.my_problem_steps[1]["question"];
        this.step2Prob.id = bbzProblem.my_problem_steps[1]["id"];
        this.step3Prob.question = bbzProblem.my_problem_steps[2]["question"];
        this.step3Prob.id = bbzProblem.my_problem_steps[2]["id"];
        this.step4Prob.question = bbzProblem.my_problem_steps[3]["question"];
        this.step4Prob.id = bbzProblem.my_problem_steps[3]["id"];
        this.step5Prob.question = bbzProblem.my_problem_steps[4]["question"];
        this.step5Prob.id = bbzProblem.my_problem_steps[4]["id"];
        this.step6Prob.question = bbzProblem.my_problem_steps[5]["question"];
        this.step6Prob.id = bbzProblem.my_problem_steps[5]["id"];
        this.step7Prob.question = bbzProblem.my_problem_steps[6]["question"];
        this.step7Prob.id = bbzProblem.my_problem_steps[6]["id"];
        this.step8Prob.question = bbzProblem.my_problem_steps[7]["question"];
        this.step8Prob.id = bbzProblem.my_problem_steps[7]["id"];
      }
      this.step1Btn = true;
      this.step2Btn = true;
      this.step3Btn = true;
      this.step4Btn = true;
      this.step5Btn = true;
      this.step6Btn = true;
      this.step7Btn = true;
      this.step8Btn = true;
      this.step1 = true;
      this.step2 = true;
      this.step3 = true;
      this.step4 = true;
      this.step5 = true;
      this.step6 = true;
      this.step7 = true;
      this.step8 = true;
      this.$bvModal.show("modal-bbzProblemInfo");
    },

    updateBbzProblem() {
      this.form.bbzCycleProblemSteps = [];
      this.form.bbzCycleProblemSteps.push(this.step1Prob);
      this.form.bbzCycleProblemSteps.push(this.step2Prob);
      this.form.bbzCycleProblemSteps.push(this.step3Prob);
      this.form.bbzCycleProblemSteps.push(this.step4Prob);
      this.form.bbzCycleProblemSteps.push(this.step5Prob);
      this.form.bbzCycleProblemSteps.push(this.step6Prob);
      this.form.bbzCycleProblemSteps.push(this.step7Prob);
      this.form.bbzCycleProblemSteps.push(this.step8Prob);

      //   console.log(this.form.bbzCycleProblemSteps);
      this.form
        .put("/teacher/updateBbzProblem")
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.$bvModal.hide("modal-bbzProblemInfo");
          this.resetData();
          Fire.$emit("getProblems");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    customLabelTeacher({ teacher_last_name, name, subject_name, full_name }) {
      return `${teacher_last_name[0].toUpperCase()}.${name} . ${subject_name} . ${full_name}`;
    },
    customLabelTaxVerb({ tax_lev_name_operation }) {
      return `${tax_lev_name_operation}`;
    },
    customLabelTaxNoun({ tax_know_lev_name_operation }) {
      return `${tax_know_lev_name_operation}`;
    },

    createBbzProblem() {
      //   console.log("createProblem");
      this.step1 = true;
      this.$bvModal.show("modal-bbzProblemInfo");
    },
    storeBbzProblem() {
      this.form.bbzCycleProblemSteps = [];
      this.form.bbzCycleProblemSteps.push(this.step1Prob);
      this.form.bbzCycleProblemSteps.push(this.step2Prob);
      this.form.bbzCycleProblemSteps.push(this.step3Prob);
      this.form.bbzCycleProblemSteps.push(this.step4Prob);
      this.form.bbzCycleProblemSteps.push(this.step5Prob);
      this.form.bbzCycleProblemSteps.push(this.step6Prob);
      this.form.bbzCycleProblemSteps.push(this.step7Prob);
      this.form.bbzCycleProblemSteps.push(this.step8Prob);

      // console.log(this.form.bbzCycleProblemSteps);
      this.form
        .post("/teacher/storeBbzProblem")
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.$bvModal.hide("modal-bbzProblemInfo");
          this.resetData();
          Fire.$emit("getProblems");
        })
        .catch((errors) => {
          // console.log(errors);
          this.$toastr.e("Алдаа", "Формыг бүрэн бөглөнө үү?");
          // errors.forEach(element => console.log(element));
          //  {
          //
          //     // this.$toastr.e("Алдаа", element);
          // });
        });
    },

    step2Done() {
      this.step1Btn = true;
      this.step2 = true;
    },
    step3Done() {
      this.step2Btn = true;
      this.step3 = true;
    },
    step4Done() {
      this.step3Btn = true;
      this.step4 = true;
    },
    step5Done() {
      this.step4Btn = true;
      this.step5 = true;
    },
    step6Done() {
      this.step5Btn = true;
      this.step6 = true;
    },
    step7Done() {
      this.step6Btn = true;
      this.step7 = true;
    },
    step8Done() {
      this.step7Btn = true;
      this.step8 = true;
    },

    //Асуултын ID-г массивт хадгалах
    step1ProbChange(o) {
      //   console.log(o);
      this.step1Prob.tax_verb_id = { ...o.id };
    },
    close() {
      this.resetData();

      this.$bvModal.hide("modal-bbzProblemInfo");
    },
    closeBBZInfo() {
      this.resetData();
      this.$bvModal.hide("modal-bbzProblemMoreInfo");
    },

    resetData() {
      this.form.reset();
      this.editMode = false;
      this.step1Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 1,
        question: "",
      };
      this.step2Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 2,
        question: "",
      };
      this.step3Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 3,
        question: "",
      };
      this.step4Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 4,
        question: "",
      };
      this.step5Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 5,
        question: "",
      };
      this.step6Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 6,
        question: "",
      };
      this.step7Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 7,
        question: "",
      };
      this.step8Prob = {
        id: null,
        tax_verb_id: null,
        tax_noun_id: null,
        step: 8,
        question: "",
      };
      this.step1Btn = false;
      this.step2Btn = false;
      this.step3Btn = false;
      this.step4Btn = false;
      this.step5Btn = false;
      this.step6Btn = false;
      this.step7Btn = false;
      this.step8Btn = false;
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
      this.step5 = false;
      this.step6 = false;
      this.step7 = false;
      this.step8 = false;
    },

    //Modal
    showModal() {
      //   console.log("Show");
    },
    hideModalOk(bvModalEvt) {
      //   console.log("ok");
      //   bvModalEvt.preventDefault();
      //   // Trigger submit handler
      //   this.handleSubmit();
    },
    hideModalCancel() {
      //   console.log("cancel");
      this.resetData();
    },

    //Даалгаврыг харуулах функц
    viewBbzProblem(bbzProblem) {
      // console.log(bbzProblem);
      this.form.reset();

      let mySelectCourse = this.courses.filter(
        (el) => el.course_id === bbzProblem.course_id
      );
      this.form.id = bbzProblem.id;
      this.form.course_id = mySelectCourse[0];
      this.form.general_question = bbzProblem.general_question;

      this.taxanomyLevNameOperations.forEach((element) => {
        if (bbzProblem.tax_verb_id) {
          if (element.id == bbzProblem.tax_verb_id) {
            this.form.tax_verb_id = element;
          }
        }
      });
      this.taxanomyKnowledgeNameOperations.forEach((element) => {
        if (bbzProblem.tax_noun_id) {
          if (element.id == bbzProblem.tax_noun_id) {
            this.form.tax_noun_id = element;
          }
        }
      });

      if (bbzProblem.my_problem_steps.length != 0) {
        // console.log(bbzProblem.my_problem_steps.length);

        this.taxanomyLevNameOperations.forEach((element) => {
          bbzProblem.my_problem_steps.forEach((steps) => {
            if (element.id == steps.tax_verb_id && steps.step == 1) {
              this.step1Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 2) {
              this.step2Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 3) {
              this.step3Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 4) {
              this.step4Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 5) {
              this.step5Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 6) {
              this.step6Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 7) {
              this.step7Prob.tax_verb_id = element;
            } else if (element.id == steps.tax_verb_id && steps.step == 8) {
              this.step8Prob.tax_verb_id = element;
            }
          });
        });
        this.taxanomyKnowledgeNameOperations.forEach((element) => {
          bbzProblem.my_problem_steps.forEach((steps) => {
            if (element.id == steps.tax_noun_id && steps.step == 1) {
              this.step1Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 2) {
              this.step2Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 3) {
              this.step3Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 4) {
              this.step4Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 5) {
              this.step5Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 6) {
              this.step6Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 7) {
              this.step7Prob.tax_noun_id = element;
            } else if (element.id == steps.tax_noun_id && steps.step == 8) {
              this.step8Prob.tax_noun_id = element;
            }
          });
        });
        // step1

        this.step1Prob.question = bbzProblem.my_problem_steps[0]
          ? bbzProblem.my_problem_steps[0]["question"]
          : "";
        this.step1Prob.id = bbzProblem.my_problem_steps[0]["id"];
        // step2

        this.step2Prob.question = bbzProblem.my_problem_steps[1]
          ? bbzProblem.my_problem_steps[1]["question"]
          : "";
        this.step2Prob.id = bbzProblem.my_problem_steps[1]["id"];
        // step3

        this.step3Prob.question = bbzProblem.my_problem_steps[2]
          ? bbzProblem.my_problem_steps[2]["question"]
          : "";
        this.step3Prob.id = bbzProblem.my_problem_steps[2]["id"];
        // step4

        this.step4Prob.question = bbzProblem.my_problem_steps[3]
          ? bbzProblem.my_problem_steps[3]["question"]
          : "";
        this.step4Prob.id = bbzProblem.my_problem_steps[3]["id"];
        // step5

        this.step5Prob.question = bbzProblem.my_problem_steps[4]
          ? bbzProblem.my_problem_steps[4]["question"]
          : "";
        this.step5Prob.id = bbzProblem.my_problem_steps[4]["id"];
        // step6

        this.step6Prob.question = bbzProblem.my_problem_steps[5]
          ? bbzProblem.my_problem_steps[5]["question"]
          : "";
        this.step6Prob.id = bbzProblem.my_problem_steps[5]["id"];
        // step7

        this.step7Prob.question = bbzProblem.my_problem_steps[6]
          ? bbzProblem.my_problem_steps[6]["question"]
          : "";
        this.step7Prob.id = bbzProblem.my_problem_steps[6]["id"];
        // step8
        this.step8Prob.question = bbzProblem.my_problem_steps[7]
          ? bbzProblem.my_problem_steps[7]["question"]
          : "";
        this.step8Prob.id = bbzProblem.my_problem_steps[7]["id"];
      }
      // else {
      //     console.log("Утга ирээгүй.");
      // }
      this.step1Btn = true;
      this.step2Btn = true;
      this.step3Btn = true;
      this.step4Btn = true;
      this.step5Btn = true;
      this.step6Btn = true;
      this.step7Btn = true;
      this.step8Btn = true;
      this.step1 = true;
      this.step2 = true;
      this.step3 = true;
      this.step4 = true;
      this.step5 = true;
      this.step6 = true;
      this.step7 = true;
      this.step8 = true;
      this.$bvModal.show("modal-bbzProblemMoreInfo");
    },
    //Даалгаврыг устгах функц
    deleteBbzProblem(bbzInfo) {
      //   console.log(bbzInfo);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "ББЗ даалгаврын мэдээлэл устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteBbzProblem/" + bbzInfo.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "ББЗ даалгаврын мэдээлэл системээс устгалаа.",
                "success"
              );
              Fire.$emit("getProblems");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  mounted() {
    window.katex = katex;
    // console.log("this is current quill instance object", this.editor);
  },
  created() {
    this.getAllProblems();
    Fire.$on("getProblems", () => {
      this.getAllProblems();
    });
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
