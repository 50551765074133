<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Эрсдэлийн удирдлага(Эрсдэлийн үр дагавар)</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="4">
                      <b-form-input
                        id="input-2"
                        v-model="formRiskConsequence.rlc_name"
                        placeholder="Эрсдэлийн үр дагаврын нэр..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                        id="input-2"
                        v-model="formRiskConsequence.rlc_value"
                        placeholder="Эрсдэлийн үр дагаврын утга..."
                        required
                        type="number"
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskLevelConsequenceCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskLevelConsequenceUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskLevelConsequenceClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Үр дагавар</th>
                <th>Үр дагаварын тоон утга</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(riskConsequence, index) in riskConsequences"
                :key="riskConsequence.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    riskConsequence.rlc_name
                  }}</b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    riskConsequence.rlc_value
                  }}</b-badge>
                </td>

                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editRiskLevelConsequence(riskConsequence)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <!-- <a
                                        href="#"
                                        class="badge bg-success"
                                        @click="
                                            createRiskDefinitionList(
                                                riskConsequence
                                            )
                                        "
                                        ><i class="fa fa-eye"></i
                                    ></a> -->
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteRiskLevelConsequence(riskConsequence)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ riskConsequence.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ riskConsequence.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-risk-definition-list"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дэд ангилал"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-2"
                  v-model="formRiskDefinitionList.rdl_name"
                  placeholder="Дэд ангилалын нэр..."
                  required
                ></b-form-input>
                <has-error
                  :form="formRiskDefinitionList"
                  field="rcl_name"
                ></has-error>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          type="submit"
          class="btn btn-success"
          v-show="!showEditRCL"
          @click.prevent="storeRiskDefinitionList()"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          v-show="showEditRCL"
          @click.prevent="updateRiskDefinitionList()"
        >
          Засах
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      departments: [
        { value: null, department_name: "Мэргэжлийн багш сонгоно уу?" },
      ],
      teachers: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //Training Эндээс эхэллээ...

      allTrainings: [],

      //subTrainings
      showSubRiskCategoryMode: false,
      editSubTrainMode: false,
      useSubTrainofParent: "",
      allSubTrainings: [],

      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      modalTeachers: [],

      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),

      // RiskCategory эхэллээ
      riskConsequences: [],
      formRiskConsequence: new Form({
        id: "",
        rlc_name: "",
        rlc_value: "",
      }),
      showEdit: false,

      //   RiskCategoryList эхэллээ
      formRiskDefinitionList: new Form({
        id: "",
        rd_id: "",
        rdl_name: "",
      }),
      showEditRCL: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //Эрсдэлийн ангилал эхэлж байна...
    getAllRiskLevelConsequence() {
      axios
        .get("/principal/getAllRiskLevelConsequence")
        .then((res) => {
          //   console.log(res);
          this.riskConsequences = res.data.riskConsequences;
          //   this.teachers = res.data.teachers;
          //   this.allTrainings = res.data.getAllTrainings;
          //   this.filterForm.department_id = 0;
          //   this.departments = res.data.departments;
          //   this.departments.push({
          //     id: 0,
          //     department_name: "Нийт мэргэжлийн баг",
          //   });
          //   this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    riskLevelConsequenceCreate() {
      //   console.log(this.formRiskConsequence);
      this.formRiskConsequence
        .post("/principal/riskLevelConsequenceCreate")
        .then((res) => {
          // console.log(res);
          Fire.$emit("getRiskLevelConsequence");
          this.formRiskConsequence.reset();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    editRiskLevelConsequence(riskLevelConsequence) {
      //   console.log(riskLevelConsequence);
      this.formRiskConsequence.fill(riskLevelConsequence);
      this.showEdit = true;
    },
    riskLevelConsequenceUpdate() {
      this.formRiskConsequence
        .put(
          "/principal/riskLevelConsequenceUpdate/" + this.formRiskConsequence.id
        )
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getRiskLevelConsequence");
          this.formRiskConsequence.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskLevelConsequence(riskLevelConsequence) {
      //   console.log(riskLevelConsequence);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн үр дагавар устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              `/principal/riskLevelConsequenceDestroy/${riskLevelConsequence.id}`
            )
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRiskLevelConsequence");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцалсан",
            "Эрсдэлийн тодорхойлолт устгасангүй :)",
            "error"
          );
        }
      });
    },
    riskLevelConsequenceClear() {
      this.formRiskConsequence.reset();
      this.showEdit = false;
    },
    //Эрсдэлийн дэд ангилалууд

    createRiskDefinitionList(riskDefinition) {
      // console.log(riskDefinition);
      //   this.getCourseStudents(train.id);
      //   this.showModalTeachers = true;
      //   this.useInfosofSubTrains = train;
      this.formRiskDefinitionList.rd_id = riskDefinition.id;
      this.$bvModal.show("modal-risk-definition-list");
    },

    storeRiskDefinitionList() {
      this.formRiskDefinitionList
        .post("/principal/storeRiskDefinitionList")
        .then((res) => {
          this.$toastr.s("Дэд ангилал амжилттай нэмлээ!", "Шинэчилсэн");
          this.resetFormRiskList();
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    resetFormRiskList() {
      this.showEditRCL = false;
      Fire.$emit("getRiskLevelConsequence");
      this.formRiskDefinitionList.reset();
      this.$bvModal.hide("modal-risk-definition-list");
    },

    cancel() {
      this.showEditRCL = false;
      this.formRiskDefinitionList.reset();
      this.$bvModal.hide("modal-risk-definition-list");
    },

    editRiskDefinitionList(rdl) {
      //   console.log(rdl);
      this.formRiskDefinitionList.fill(rdl);
      this.showEditRCL = true;
      this.$bvModal.show("modal-risk-definition-list");
    },
    updateRiskDefinitionList() {
      //   console.log("update");

      this.formRiskDefinitionList
        .put(
          "/principal/updateRiskDefinitionList/" +
            this.formRiskDefinitionList.id
        )
        .then((res) => {
          //   console.log(res);
          this.resetFormRiskList();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskDefinitionList(rdl) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн тодорхойлолтын дэд ангилал устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/principal/riskDefinitionListDestroy/${rdl.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRiskLevelConsequence");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Дэд ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Дэд ангилал устгахыг цуцалсан :)", "error");
        }
      });
    },

    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllRiskLevelConsequence();
    Fire.$on("getRiskLevelConsequence", () => {
      this.getAllRiskLevelConsequence();
    });
    Fire.$on("getSubTrainings", () => {
      this.getSubTrainAgain();
    });
  },
};
</script>

<style></style>
