<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">Aжилчдын ажлын үнэлгээ</h3>
            <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
            <!-- Button trigger modal -->
            <download-excel
              class="btn btn-primary btn-sm"
              :data="json_data"
              :fields="json_fields"
              type="csv"
              name="filename.xls"
            >
              Татах
              <!-- <img src="download_icon.png" /> -->
            </download-excel>
            <multiselect
              v-model="selectedMonths"
              :options="monthsRe"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Нэгдсэн дүн татах сарыг сонгоно уу?"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >Нийт {{ values.length }} сар сонгогдлоо</span
                ></template
              >
            </multiselect>
            <b-button @click="filterByMonthED()" class="btn btn-warning"
              >Өгөгдөл</b-button
            >
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <h6 class="card-title">Сургалт хариуцсан захиралын үнэлгээ</h6>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Овог Нэр</th>
                <th class="text-sm">Имэйл хаяг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Сар-9</th>
                <th class="text-sm">Сар-10</th>
                <th class="text-sm">Сар-11</th>
                <th class="text-sm">Сар-12</th>
                <th class="text-sm">Сар-1</th>
                <th class="text-sm">Сар-2</th>
                <th class="text-sm">Сар-3</th>
                <th class="text-sm">Сар-4</th>
                <th class="text-sm">Сар-5</th>
                <th class="text-sm">Сар-6</th>
                <th class="text-sm">Сар-7</th>
                <th class="text-sm">Сар-8</th>
                <th class="text-sm">Ш-2</th>
                <th class="text-sm">Ш-3</th>
                <th class="text-sm">Ш-4</th>
                <th class="text-sm">Ш-5</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsMinadCurr">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsMinadCurr"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name[0].toUpperCase() }}.
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.teacher_email }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- Баталгаажаагүй -->
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <!-- Баталгаажсан -->
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <!-- Баталгаажаагүй -->
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                  <!-- Баталгаажсан -->
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_11_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_11_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_11_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_11_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_11_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_11_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>
                <td class="text-sm" tabindex="0"></td>

                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal2 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal3 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal4 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal5 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(evaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            Менежерүүд
            <thead>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Овог Нэр</th>
                <th class="text-sm">Имэйл хаяг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Сар-9</th>
                <th class="text-sm">Сар-10</th>
                <th class="text-sm">Сар-11</th>
                <th class="text-sm">Сар-12</th>
                <th class="text-sm">Сар-1</th>
                <th class="text-sm">Сар-2</th>
                <th class="text-sm">Сар-3</th>
                <th class="text-sm">Сар-4</th>
                <th class="text-sm">Сар-5</th>
                <th class="text-sm">Сар-6</th>
                <th class="text-sm">Сар-7</th>
                <th class="text-sm">Сар-8</th>
                <th class="text-sm">Ш-2</th>
                <th class="text-sm">Ш-3</th>
                <th class="text-sm">Ш-4</th>
                <th class="text-sm">Ш-5</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsTeacher">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsTeacher"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name[0].toUpperCase() }}.
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.teacher_email }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_11_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_11_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_12_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_12_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_1_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_1_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_2_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_2_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_3_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_3_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_4_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_4_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_5_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_5_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_6_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_6_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_7_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_7_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_8_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_8_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal2 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal3 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal4 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal5 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(evaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            Багш нар
            <thead>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Овог Нэр</th>
                <th class="text-sm">Имэйл хаяг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Сар-9</th>
                <th class="text-sm">Сар-10</th>
                <th class="text-sm">Сар-11</th>
                <th class="text-sm">Сар-12</th>
                <th class="text-sm">Сар-1</th>
                <th class="text-sm">Сар-2</th>
                <th class="text-sm">Сар-3</th>
                <th class="text-sm">Сар-4</th>
                <th class="text-sm">Сар-5</th>
                <th class="text-sm">Сар-6</th>
                <th class="text-sm">Сар-7</th>
                <th class="text-sm">Сар-8</th>
                <th class="text-sm">Ш-2</th>
                <th class="text-sm">Ш-3</th>
                <th class="text-sm">Ш-4</th>
                <th class="text-sm">Ш-5</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsEmployee">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployee"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name[0].toUpperCase() }}.
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.teacher_email }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_11_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_11_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_12_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_12_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_1_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_1_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_2_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_2_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_3_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_3_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_4_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_4_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_5_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_5_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_6_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_6_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_7_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_7_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_8_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_8_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal2 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal3 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal4 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal5 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(evaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
            Сургалтын албаны ажилчид
            <thead>
              <tr role="row">
                <th class="text-sm">№</th>
                <th class="text-sm">Овог Нэр</th>
                <th class="text-sm">Имэйл хаяг</th>
                <th class="text-sm">Албан тушаал</th>
                <th class="text-sm">Сар-9</th>
                <th class="text-sm">Сар-10</th>
                <th class="text-sm">Сар-11</th>
                <th class="text-sm">Сар-12</th>
                <th class="text-sm">Сар-1</th>
                <th class="text-sm">Сар-2</th>
                <th class="text-sm">Сар-3</th>
                <th class="text-sm">Сар-4</th>
                <th class="text-sm">Сар-5</th>
                <th class="text-sm">Сар-6</th>
                <th class="text-sm">Сар-7</th>
                <th class="text-sm">Сар-8</th>
                <th class="text-sm">Ш-2</th>
                <th class="text-sm">Ш-3</th>
                <th class="text-sm">Ш-4</th>
                <th class="text-sm">Ш-5</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Зассан</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-show="allEvaluationsEmployee">
              <tr
                class="odd"
                v-for="(evaluation, index) in allEvaluationsEmployee"
                :key="evaluation.id"
              >
                <td class="text-sm" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-primary"
                      >{{ evaluation.emp_teacher_last_name[0].toUpperCase() }}.
                      {{ evaluation.emp_teacher_name }}</span
                    >
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.teacher_email }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <h6>
                    <span class="badge badge-success">
                      {{ evaluation.position_name }}
                    </span>
                  </h6>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_9_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_9_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_9_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10 }}
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_10_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_10_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_10_aub }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_11_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_11_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_11 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_12_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_12_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_12 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_1_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_1_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_1 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_2_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_2_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_2 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_3_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_3_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_3 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_4_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_4_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_4 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_5_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_5_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_5 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_6_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_6_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_6 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_7_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_7_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_7 }}
                  </span>
                </td>
                <td class="text-sm" tabindex="0">
                  <span
                    class="badge bg-warning"
                    v-show="evaluation.ajliin_sar_8_confirm === null"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                  <span
                    class="badge bg-success"
                    v-show="evaluation.ajliin_sar_8_confirm === 1"
                  >
                    {{ evaluation.ajliin_sar_8 }}
                  </span>
                </td>

                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal1 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal2 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal3 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal4 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.shal5 }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.created_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  {{ evaluation.updated_at | dateYearMonthDay }}
                </td>
                <td class="text-sm" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="deleteSchoolEmployee(evaluation)"
                    ><i class="far fa-handshake"></i
                  ></a> -->
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    v-show="
                      evaluation.readManager === null &&
                      evaluation.confirmed === null
                    "
                    @click="editevaluation(evaluation)"
                    ><i class="fas fa-edit"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      //   keyword: "",
      allEvaluationsMinadCurr: [],
      allEvaluationsTeacher: [],
      allEvaluationsEmployee: [],
      allManagers: [],
      //   positions: [],
      nameEmpCheck: "",
      namePositionNameCheck: "",
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],

      myDownloadData: [],
      monthsRe: [
        { name: "1", january: "1-сар" },
        { name: "2", february: "2-сар" },
        { name: "3", march: "3-сар" },
        { name: "4", april: "4-сар" },
        { name: "5", may: "5-сар" },
        { name: "6", june: "6-сар" },
        { name: "7", july: "7-сар" },
        { name: "8", august: "8-сар" },
        { name: "9", september: "9-сар" },
        { name: "10", october: "10-сар" },
        { name: "11", november: "11-сар" },
        { name: "12", december: "12-сар" },
      ],
      selectedMonths: [],
      resultMy: [],

      json_fields: {
        "Complete name": "name",
        City: "city",
        Telephone: "phone.mobile",
        "Telephone 2": {
          field: "phone.landline",
          callback: (value) => {
            return `Landline Phone - ${value}`;
          },
        },
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  watch: {
    selectedMonths(newVal, oldVal) {
      //   console.log("шинэ" + newVal, "Хуучин" + oldVal);
      this.resultMy = [];
    },
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0].toUpperCase()} . ${emp_teacher_name}`;
    },
    toggleSelect() {
      console.log("toggleSelect");
    },
    average() {
      return (
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4
      );
    },
    calculatePercent() {
      let averagePer =
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4;
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },

    calculateManagerPercent(shal1, shal2, shal3, shal4) {
      let averageMyPer =
        (parseFloat(shal1) +
          parseFloat(shal2) +
          parseFloat(shal3) +
          parseFloat(shal4)) /
        4;
      let myManagerPercent = (averageMyPer * 20) / 100;
      return myManagerPercent;
    },

    //Сараар шүүж авах

    filterByMonthED() {
      axios
        .post("/curriculumadministrator/myManagersDataConcate", {
          ajliin_sar: this.selectedMonths,
        })
        .then((response) => {
          this.allEvaluationsMinadCurr = response.data.myPushSomeDataMinadCurr;
          this.allEvaluationsManager = response.data.myPushSomeDataManager;
          this.allEvaluationsTeacher = response.data.myPushSomeDataTeacher;
          this.allEvaluationsEmployee = response.data.myPushSomeDataEmployee;
          console.log(response.data);
        })
        .catch();
    },

    //Менежерийн ажлын үнэлгээг татах функцүүд

    managersEvaluationsDownload(myDatas) {
      //   this.myDownloadData = [...this.allEvaluations];
      //   console.log(this.myDownloadData);
      //   console.log(myDatas);
      var myArr = myDatas,
        result = Object.values(
          myArr.reduce((r, o) => {
            r[o.emp_id] = r[o.emp_id] || {
              ...o,
              // emp_id: o.emp_id,
              // ajliin_sar: o.ajliin_sar,
              // emp_teacher_last_name: o.emp_teacher_last_name,
              // emp_teacher_name: o.emp_teacher_name,
              evaluations: [],
            };
            r[o.emp_id]["evaluations"].push({
              [o.ajliin_sar]: {
                ajliin_sar: o.ajliin_sar,
                shal1: o.shal1,
                shal2: o.shal2,
                shal3: o.shal3,
                shal4: o.shal4,
                percent: this.calculateManagerPercent(
                  o.shal1,
                  o.shal2,
                  o.shal3,
                  o.shal4
                ),
              },

              //   [o.shal1]: { shal1: o.shal1 },
            });
            return r;
          }, {})
        );

      console.log("myArr", myArr, result);
      this.json_data = result;
    },
  },
  computed: {
    // validationNumber1() {
    //   return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    // },
  },
  created() {
    Fire.$on("loadSchoolManagers", () => {});
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
