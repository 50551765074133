<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" style="font-size: 80%">
            Асуудлын ангилалууд (Устгасан асуудлын ангилал болон задаргааг
            сэргээх бол админд хандана уу? Устгахаас өмнө анхааралтай байна уу?)
          </h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="5">
                      <b-form-input
                        id="input-1"
                        v-model="formProbCategory.psy_cat_prob_cat_name"
                        placeholder="Асуудлын ангилал"
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="5">
                      <b-form-input
                        id="input-2"
                        v-model="formProbCategory.psy_cat_prob_cat_desc"
                        placeholder="Асуудлын ангиллалын тайлбар"
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="probCategoryCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="probCategoryUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="probCategoryClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Асуудлын ангилал</th>
                <th>Асуудлын ангиллалын тайлбар</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(category, index) in allProblemCategories"
                :key="category.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    category.psy_cat_prob_cat_name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    category.psy_cat_prob_cat_desc
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="probCategoryEdit(category)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="showProbCatDetails(category)"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="probCategoryDelete(category)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ category.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ category.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-if="showProbCatDetailMode">
      <div class="card card-primary">
        <div class="card-header" style="font-size: 70%">
          <h3 class="card-title" v-show="showProbCatDetailMode">
            Асуудлын ангилалын задаргаа бүртгэх
          </h3>
          <h3 class="card-title" v-show="!showProbCatDetailMode">
            Асуудлын ангилалын задаргаа засах
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <b-form-textarea
                style="font-size: 70%"
                id="textarea"
                v-model="formProbCategoryDetail.psy_cat_detail"
                placeholder="Асуудлын ангилалд харьяалагдах задаргаануудыг оруулна уу?"
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid':
                      formProbCategoryDetail.errors.has('psy_cat_detail'),
                  },
                ]"
              ></b-form-textarea>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editProbCatDetailMode"
            @click.prevent="probCatDetailCreate()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editProbCatDetailMode"
            @click.prevent="probCatDetailUpdate()"
          >
            Засах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editProbCatDetailMode"
            @click.prevent="probCatDetailClear()"
          >
            Цэвэрлэх
          </button>
          <button
            class="btn btn-secondary"
            @click.prevent="probCatDetailCancel()"
          >
            Болих
          </button>
        </div>
        <div style="overflow-x: auto">
          <table class="table table-hover text-nowrap">
            <thead style="font-size: 70%">
              <tr role="row">
                <th>#</th>
                <th>Задаргаа</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody style="font-size: 60%">
              <tr
                v-for="(detail, index) in allProbCategoryDetails"
                :key="detail.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    detail.psy_cat_detail
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="probCatDetailEdit(detail)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="probCatDetailDelete(detail)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    detail.created_at | dateYearMonthDay
                  }}</b-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <b-modal
      v-show="showModalTeachers"
      size="xl"
      id="modal-train-teachers"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Сургалт"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              >Сургалт хийх хүний Овог Нэр</span
            >
            <span class="direct-chat-timestamp float-right">{{
              useInfosofSubTrains.created_at | dateYearMonthDay
            }}</span>
          </div>
          <img
            v-if="useInfosofSubTrains.profile_photo_url != null"
            :src="useInfosofSubTrains.profile_photo_url"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <img
            v-else
            src="/images/users/user.png"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <div class="direct-chat-text primary">
            {{ useInfosofSubTrains.taining_course_content }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сургалтанд хамрагдах багш нар</h3>
              </div>
              <div
                class="card-body table-responsive"
                v-if="useInfosofSubTrains != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Албан тушаал</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(enrollT, index) in enrolledTeachers"
                    :key="enrollT.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_teacher_last_name[0] }}.
                        {{ enrollT.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_position_name }}
                      </td>

                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(enrollT)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="recieved_employees"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacher()"
                >
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      //probCategory Эндээс эхэллээ...
      showEdit: false,
      allProblemCategories: [],
      formProbCategory: new Form({
        id: "",
        psy_cat_prob_cat_name: "",
        psy_cat_prob_cat_desc: "",
      }),
      //probCategoryDetails
      showProbCatDetailMode: false,
      editProbCatDetailMode: false,
      useParentProbCat: "",
      allProbCategoryDetails: [],
      formProbCategoryDetail: new Form({
        id: "",
        psy_cat_id: "",
        psy_cat_detail: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //categories эхэлж байна...
    getAllProbCatregories() {
      axios
        .get("/psycho/getAllProblemCategory")
        .then((res) => {
          //   console.log(res.data);
          this.allProblemCategories = res.data.allProblemCategories;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    probCategoryCreate() {
      //   console.log(this.formTraining);
      this.formProbCategory
        .post("/psycho/problemCategoryCreate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("getCategories");
          this.formProbCategory.reset();
        })
        .catch((err) => {
          console.log("errors");
        });
    },
    probCategoryEdit(category) {
      //   console.log(category);
      this.formProbCategory.fill(category);
      this.showEdit = true;
    },
    probCategoryUpdate() {
      axios
        .put("/psycho/problemCategoryUpdate/", {
          id: this.formProbCategory.id,
          psy_cat_prob_cat_name: this.formProbCategory.psy_cat_prob_cat_name,
          psy_cat_prob_cat_desc: this.formProbCategory.psy_cat_prob_cat_desc,
        })
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getCategories");
          this.formProbCategory.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          console.log("errors");
        });
    },
    probCategoryDelete(category) {
      //   console.log(category);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Асуудлын ангилалыг устгахад, түүнд харьяалагдах бүх дэд задаргаанууд устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/psycho/probCategoryDestroy/${category.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getCategories");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire(
            "Амжилттай устгалаа!",
            "Асуудлын ангилалыг устгасан.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Асуудлын ангилалыг устгасангүй :)", "error");
        }
      });
    },
    probCategoryClear() {
      this.formProbCategory.reset();
      this.showEdit = false;
    },
    //Асуудлын ангилалын задаргаанууд
    getAllProbCatDetails() {
      axios
        .post("/psycho/getMyProbCatDetails", {
          parentProbCategory: this.useParentProbCat,
        })
        .then((res) => {
          //   console.log(res.data);
          this.allProbCategoryDetails = res.data.allProbCategoryDetails;
          this.showProbCatDetailMode = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showProbCatDetails(category) {
      //   console.log(category);
      this.useParentProbCat = category;
      this.showProbCatDetailMode = false;
      this.formProbCategoryDetail.reset();

      this.getAllProbCatDetails();
    },
    probCatDetailCreate() {
      this.formProbCategoryDetail.psy_cat_id = this.useParentProbCat.id;
      this.formProbCategoryDetail
        .post("/psycho/probCatDetailStore")
        .then((res) => {
          //   console.log(res.data);
          this.formProbCategoryDetail.reset();
          Fire.$emit("getProbCatDetails");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    probCatDetailEdit(detail) {
      this.editProbCatDetailMode = true;
      this.formProbCategoryDetail.fill(detail);
    },
    probCatDetailUpdate() {
      this.formProbCategoryDetail
        .put("/psycho/probCatDetailUpdate")
        .then((res) => {
          Fire.$emit("getProbCatDetails");
          this.probCatDetailClear();
          this.editProbCatDetailMode = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    probCatDetailDelete(detail) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Асуудлын ангилалыг задаргааг устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/psycho/probCatDetailDestroy/${detail.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getProbCatDetails");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire(
            "Амжилттай устгалаа!",
            "Асуудлын ангилалын задаргааг устгасан.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцалсан",
            "Асуудлын ангилалын задаргааг устгасангүй :)",
            "error"
          );
        }
      });
    },
    probCatDetailClear() {
      this.formProbCategoryDetail.reset();
    },
    probCatDetailCancel() {
      this.showProbCatDetailMode = false;
      this.editProbCatDetailMode = false;
      this.useParentProbCat = "";
      this.formProbCategoryDetail.reset();
    },
  },
  created() {
    this.getAllProbCatregories();
    Fire.$on("getCategories", () => {
      this.getAllProbCatregories();
    });
    Fire.$on("getProbCatDetails", () => {
      this.getAllProbCatDetails();
    });
  },
};
</script>

<style></style>
