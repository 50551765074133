<template>
  <div class="col-12 mt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <!-- Left navbar links -->
      <!-- <multiselect
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="1-р алхам-Улирал сонголт"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect> -->
      <multiselect
        class="mr-2"
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="teacher_id"
        label="teacher_id"
        :custom-label="customLabelTeacherName"
        placeholder="1-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.employee_teacher_last_name[0]
            }}{{ option.employee_teacher_last_name[1] }}.{{
              option.employee_teacher_name
            }}-{{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="2-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button>
    </nav>
    <every-student
      v-if="selectedCourseData"
      :selectedLessonExamData="selectedLessonExamDataPass"
      :teacher_id="teacher_id"
      :course_id="course_id"
      :quarter_id="quarter_id"
    ></every-student>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudent from "../ExamStatForManagers/everyStudentConfirmYear.vue";
export default {
  components: {
    Multiselect,
    everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      mySchoolQuarters: [],
      mySchoolsTeachers: [],
      mySchoolCourses: [],
      // Дуудах request
      quarter_id: "",
      department_id: "",
      teacher_id: "",
      course_id: "",
      //Pass to child component
      selectedCourseData: false,
      selectedLessonExamData: [],
      selectedLessonExamDataPass: [],
      selectedLessonExamDataUliralEtses: [],
      selectedLessonExamDataYear: [],
      allStudents: [],
    };
  },
  watch: {
    teacher_id: function (newValue, oldValue) {
      this.course_id = "";
      this.selectedCourseData = false;
      this.selectedLessonExamData = [];
      this.selectedLessonExamDataPass = [];
      this.selectedLessonExamDataUliralEtses = [];
      this.selectedLessonExamDataYear = [];

      this.mySchoolCourses = [];
      if (newValue != "") {
        axios
          .post("/manager/getMySchoolBehaviorCourses", {
            teacher: newValue,
            quarter: this.quarter_id,
          })
          .then((response) => {
            this.mySchoolCourses = response.data.mySchoolBehaviorCourses;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
    },
    course_id: function (newValue, oldValue) {
      axios
        .post("/manager/mySchoolCourseExams", {
          myCourseData: this.course_id,
        })
        .then((response) => {
          //   console.log(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  methods: {
    getMySchoolExamStatData() {
      axios
        .get("/manager/mySchoolBehaviorsStatData")
        .then((response) => {
          //   console.log(response.data);
          this.mySchoolQuarters = response.data.mySchoolsQuarters;

          let sortTeachers = response.data.mySchoolsTeachers;
          sortTeachers.sort(
            (a, b) =>
              a.school_class_number - b.school_class_number ||
              a.school_class_level.localeCompare(b.school_class_level)
          );
          this.mySchoolsTeachers = sortTeachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    getSelectMyExamData() {
      this.selectedCourseData = false;
      axios
        .post("/manager/getManagerGradeBehaviorDataYear", {
          quarter_id: this.quarter_id,
          teacher_id: this.teacher_id,
          course_id: this.course_id,
        })
        .then((response) => {
          //   console.log(response.data);
          //Бүх суралцагчид
          let sortManager = response.data.schoolClassAllStudents;
          sortManager.sort(
            (a, b) =>
              a.student_last_name - b.student_last_name ||
              a.student_name.localeCompare(b.student_name)
          );

          this.allStudents = sortManager;

          //Зөвхөн жилийг тооцоолно.
          if (this.allStudents.length > 0) {
            // console.log(merged);
            this.selectedCourseData = true;
            this.selectedLessonExamDataPass = this.allStudents;
            // console.log(this.selectedLessonExamDataPass);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelTeacherName({
      employee_teacher_name,
      employee_teacher_last_name,
      school_class_full_name,
    }) {
      return `${employee_teacher_last_name[0]}${employee_teacher_last_name[1]} . ${employee_teacher_name} - ${school_class_full_name}`;
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    // customLabelQuarter({ quarter_name }) {
    //   return `${quarter_name} – улирал`;
    // },
  },
  created() {
    this.getMySchoolExamStatData();
  },
};
</script>

<style></style>
