<template>
  <div class="row pt-3">
    <div class="col-md-2">
      <div
        class="card card-light"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Хүүхэд</h3>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow-x: auto">
          <b-button
            v-for="(child, i) in myChilds"
            :key="i"
            pill
            size="sm"
            variant="outline-primary"
            @click.prevent="getSelectedStudent(child.user_id)"
            class="mr-1"
          >
            {{ child.student_name }}
          </b-button>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-md-10">
      <student-open-day
        v-if="isShow"
        :selectedChildId="selectedChildId"
        :quarters="quarters"
      ></student-open-day>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import studentOpenDay from "./studentOpenDay.vue";
export default {
  components: {
    Multiselect,
    studentOpenDay: studentOpenDay,
  },
  data() {
    return {
      quarters: [],
      myChilds: [],
      selectedChildId: null,
      isShow: false,
    };
  },
  methods: {
    getMyChilds() {
      this.isShow = false;
      axios
        .get("/project_parent/getChilList")
        .then((res) => {
          this.myChilds = res.data.childs;
          this.quarters = res.data.quarterList;
          if (this.myChilds.length > 0) {
            this.selectedChildId = this.myChilds[0].user_id;
            this.isShow = true;
          }
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSelectedStudent(childId) {
      this.isShow = false;
      this.$nextTick(() => {
        this.selectedChildId = childId;
        this.isShow = true;
      });
    },
  },
  created() {
    this.getMyChilds();
  },
};
</script>
<style></style>
