<template>
  <div class="row">
    <div class="d-flex px-3 pb-3 col-lg-3">
      <div
        class="calendar__number calendar__number--cultural mr-1"
        title="Соёл хүмүүжлийн ажил"
      >
        1
      </div>
      <b-form-checkbox
        v-model="cultureCalCheck"
        class="ml-1 text-success"
        name="cultureCalCheck"
        button-variant="success"
        size="sm"
      >
        <strong class="text-success" title="Соёл хүмүүжлийн ажил">
          Соёл хүмүүжлийн ажил</strong
        >
      </b-form-checkbox>
    </div>
    <div
      class="d-flex px-3 pb-3 col-lg-2"
      v-if="role.name != 'dentstu' && role.name != 'rentpa'"
    >
      <div
        class="calendar__number--task my-auto legends"
        title="Үүрэг даалгавар"
      ></div>
      <b-form-checkbox
        v-model="taskCalCheck"
        class="ml-1 text-warning"
        name="taskCalCheck"
        size="sm"
      >
        <strong class="text-warning" title="Үүрэг даалгавар">
          Үүрэг даалгавар</strong
        >
      </b-form-checkbox>
    </div>
    <div
      class="d-flex px-3 pb-3 col-lg-2"
      v-if="role.name != 'dentstu' && role.name != 'rentpa'"
    >
      <div
        class="calendar__number--monthPlan my-auto legends"
        title="Сарын төлөвлөгөө"
      ></div>
      <b-form-checkbox
        v-model="monthCalCheck"
        class="ml-1 text-primary"
        name="monthCalCheck"
        size="sm"
      >
        <strong class="text-primary"> Сарын төлөвлөгөө </strong>
      </b-form-checkbox>
    </div>
    <div class="d-flex px-3 pb-3 col-lg-3" v-else-if="role.name == 'rentpa'">
      <div
        class="calendar__number--monthPlan my-auto legends"
        title="Хүүхдийн тусгай төлөвлөлт"
      ></div>
      <b-form-checkbox
        v-model="childSPCalCheck"
        class="ml-1 text-primary"
        name="childSPCalCheck"
        size="sm"
      >
        <strong class="text-primary"> Хүүхдийн тусгай төлөвлөлт </strong>
      </b-form-checkbox>
    </div>
    <div
      class="d-flex px-3 pb-3 col-lg-2"
      v-if="role.name != 'dentstu' && role.name != 'rentpa'"
    >
      <div
        class="calendar__number--specialPlan my-auto legends"
        title="Тусгай төлөвлөлт"
      ></div>
      <b-form-checkbox
        v-model="specialCalCheck"
        class="ml-1 text-danger"
        name="specialCalCheck"
        size="sm"
      >
        <strong class="text-danger"> Тусгай төлөвлөлт </strong>
      </b-form-checkbox>
    </div>
    <div class="d-flex px-3 pb-3 col-lg-2" v-else>
      <div
        class="calendar__number--specialPlan my-auto legends"
        title="Тусгай төлөвлөлт"
      ></div>
      <b-form-checkbox
        v-model="specialCalCheck"
        class="ml-1 text-danger"
        name="specialCalCheck"
        size="sm"
      >
        <strong class="text-danger"> Тусгай төлөвлөлт </strong>
      </b-form-checkbox>
    </div>
    <div class="d-flex px-3 pb-3 col-lg-2">
      <div
        class="calendar__number calendar__number--current mr-1"
        title="Өнөөдөр"
      >
        1
      </div>
      <strong class="text-info"> Өнөөдөр </strong>
    </div>
    <multiselect
      v-model="selected_year"
      select-label="Сонгох"
      selected-label="Сонгогдсон"
      track-by="id"
      label="id"
      :custom-label="customYearName"
      placeholder="Хичээлийн жил сонгоно уу?"
      :options="years"
      :searchable="false"
      :allow-empty="false"
      class="mx-3"
      @input="selectYear()"
      v-if="role.name != 'dentstu' && role.name != 'rentpa'"
    >
      <template slot="singleLabel" slot-scope="{ option }"
        ><strong
          >{{ option.start_date | dateYear }}-{{
            option.finish_date | dateYear
          }}
          - хичээлийн жил
        </strong>
      </template>
    </multiselect>
    <div class="row m-3">
      <div
        v-for="monthData in calendarMonths"
        :key="monthData.id"
        class="pb-4 pr-4"
      >
        <div class="calendar">
          <div class="calendar__picture position-relative card-container">
            <img class="calendar__img card-img" :src="monthData.image" />
            <div class="card-body card-img-overlay">
              <h5
                :style="{
                  'background-color': colors[monthData.month - 1],
                }"
              >
                {{ monthData.month }} сар
                {{ getYear(monthData.month) }}
              </h5>
              <p
                class="px-2"
                :style="{
                  'background-color': colors[monthData.month - 1],
                }"
              >
                {{ monthData.description }}
              </p>
            </div>
            <div
              class="overlay text-center"
              :style="{
                'background-color': colors[monthData.month - 1] + '!important',
              }"
              role="button"
              v-b-toggle.sidebar-month
              @click="showMonthData(monthData)"
            >
              <div class="hoverText">
                <div class="hoverBtn">
                  <b-icon
                    icon="arrows-fullscreen"
                    animation="throb"
                    font-scale="3"
                  >
                  </b-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content">
            <div class="calendar__date col-lg-7">
              <div class="calendar__day">M</div>
              <div class="calendar__day">T</div>
              <div class="calendar__day">W</div>
              <div class="calendar__day">T</div>
              <div class="calendar__day">F</div>
              <div class="calendar__day">S</div>
              <div class="calendar__day">S</div>
              <div
                v-for="emptyDay in getMonthStartWeekDay(monthData.month)"
                :key="monthData.month + 'month' + emptyDay"
              ></div>
              <div
                v-for="day in getDaysInMonth(monthData.month)"
                :key="day"
                :class="[
                  'calendar__number',
                  {
                    'calendar__number--current':
                      getYear(monthData.month) == nowYear &&
                      monthData.month == nowMonth &&
                      day == today,
                  },
                  {
                    'calendar__number--cultural':
                      cultureCalCheck &&
                      getFilterData(monthData.month, day, monthData.culturals)
                        .length > 0,
                  },
                ]"
                v-b-popover.hover.v-success.top.html="
                  cultureCalCheck &&
                  getFilterData(monthData.month, day, monthData.culturals)
                    .length > 0
                    ? getTooltipContent(
                        'cultural',
                        monthData.month,
                        day,
                        monthData.culturals
                      )
                    : ''
                "
                :title="
                  getYear(monthData.month) == nowYear &&
                  monthData.month == nowMonth &&
                  day == today
                    ? 'Өнөөдөр'
                    : ''
                "
              >
                {{ day }}
                <div class="d-flex flex-column">
                  <router-link
                    v-if="
                      role.name != 'dentstu' &&
                      role.name != 'rentpa' &&
                      taskCalCheck &&
                      getFilterData(monthData.month, day, monthData.tasks)
                        .length > 0
                    "
                    class="calendar__number--task"
                    v-b-popover.hover.v-warning.left.html="
                      getFilterData(monthData.month, day, monthData.tasks)
                        .length > 0
                        ? getTooltipContent(
                            'task',
                            monthData.month,
                            day,
                            monthData.tasks
                          )
                        : ''
                    "
                    title="Үүрэг даалгавар"
                    :to="getUrl('task')"
                  ></router-link>
                  <router-link
                    v-if="
                      role.name != 'dentstu' &&
                      role.name != 'rentpa' &&
                      monthCalCheck &&
                      getFilterData(monthData.month, day, monthData.monthPlans)
                        .length > 0
                    "
                    class="calendar__number--monthPlan"
                    v-b-popover.hover.v-primary.left.html="
                      getFilterData(monthData.month, day, monthData.monthPlans)
                        .length > 0
                        ? getTooltipContent(
                            'monthPlan',
                            monthData.month,
                            day,
                            monthData.monthPlans
                          )
                        : ''
                    "
                    title="Сарын төлөвлөгөө"
                    :to="getUrl('monthPlan')"
                  ></router-link>
                  <router-link
                    v-if="
                      role.name == 'rentpa' &&
                      childSPCalCheck &&
                      getFilterData(
                        monthData.month,
                        day,
                        monthData.childsSPlans
                      ).length > 0
                    "
                    class="calendar__number--monthPlan"
                    v-b-popover.hover.v-primary.left.html="
                      getFilterData(
                        monthData.month,
                        day,
                        monthData.childsSPlans
                      ).length > 0
                        ? getTooltipContent(
                            'childsSPlans',
                            monthData.month,
                            day,
                            monthData.childsSPlans
                          )
                        : ''
                    "
                    title="Хүүхдийн тусгай төлөвлөлт"
                    :to="getUrl('specialPlan')"
                  ></router-link>
                  <router-link
                    v-if="
                      specialCalCheck &&
                      getFilterData(
                        monthData.month,
                        day,
                        monthData.specialPlans
                      ).length > 0
                    "
                    :class="[
                      'calendar__number--specialPlan',
                      {
                        'calendar__number--stdParentSPlan':
                          role.name == 'dentstu',
                      },
                    ]"
                    v-b-popover.hover.v-danger.left.html="
                      getFilterData(
                        monthData.month,
                        day,
                        monthData.specialPlans
                      ).length > 0
                        ? getTooltipContent(
                            'specialPlan',
                            monthData.month,
                            day,
                            monthData.specialPlans
                          )
                        : ''
                    "
                    title="Тусгай төлөвлөлт"
                    :to="getUrl('specialPlan')"
                  ></router-link>
                </div>
              </div>
            </div>
            <div
              class="current-events col-lg-5"
              :style="{
                'background-color': colors[monthData.month - 1],
              }"
            >
              <b-badge
                pill
                variant="light"
                v-b-toggle.sidebar-month
                @click="showMonthData(monthData)"
                >Дэлгэрэнгүй харах <b-icon icon="arrow-right-short"></b-icon>
              </b-badge>
              <div v-if="monthData.culturals.length > 0">
                Соёл хүмүүжлийн ажил
                <ul>
                  <li v-for="culture in monthData.culturals" :key="culture.id">
                    {{ culture.name }} /{{
                      culture.level && culture.level.name
                    }}/
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
      v-if="monthData"
      id="sidebar-month"
      :title="monthData.month + '-р сар'"
      :style="{
        'background-color': colors[monthData.month - 1],
      }"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Сар: <strong>{{ monthData.month }}</strong>
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-header">
            <strong>{{ monthData.month }} -р сарын төлөвлөгөөт ажлууд</strong>
          </div>
          <div class="card-body table-responsive mb-3">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-timestamp float-left"
                  ><img
                    class="profile-user-img img-fluid attachment-img mr-2"
                    :src="monthData.image"
                    alt="Төслийн зураг"
                    accept="image/*"
                    v-b-tooltip.hover.html="
                      '<dd><img src=' +
                      monthData.image +
                      ' style=' +
                      'width:280px!important' +
                      '></dd><dt>Зураач</dt><dd>' +
                      monthData.description +
                      '</dd></dl>'
                    "
                  />
                  <b>Зураач: </b>{{ monthData.description }}
                </span>
                <span class="direct-chat-timestamp float-right"
                  ><b>Хамрах хугацаа: </b>{{ monthData.month }}/1 -
                  {{ monthData.month }}/{{
                    getDaysInMonth(monthData.month).length
                  }}</span
                >
              </div>
              <div class="direct-chat-text primary ml-0">
                {{ monthData.description }}
              </div>
            </div>
            <div class="row">
              <div class="px-3 pb-3 col-lg-3">
                <b-form-checkbox
                  v-model="cultureCheck"
                  class="ml-1 text-success"
                  name="cultureCheck"
                  button-variant="success"
                  size="sm"
                >
                  Соёл хүмүүжлийн ажил
                </b-form-checkbox>
              </div>
              <div
                class="px-3 pb-3 col-lg-2"
                v-if="role.name != 'dentstu' && role.name != 'rentpa'"
              >
                <b-form-checkbox
                  v-model="taskCheck"
                  class="ml-1 text-warning"
                  name="taskCheck"
                  size="sm"
                >
                  Үүрэг даалгавар
                </b-form-checkbox>
              </div>
              <div
                class="px-3 pb-3 col-lg-2"
                v-if="role.name != 'dentstu' && role.name != 'rentpa'"
              >
                <b-form-checkbox
                  v-model="monthCheck"
                  class="ml-1 text-primary"
                  name="monthCheck"
                  size="sm"
                >
                  Сарын төлөвлөгөө
                </b-form-checkbox>
              </div>
              <div class="px-3 pb-3 col-lg-3" v-else-if="role.name == 'rentpa'">
                <b-form-checkbox
                  size="sm"
                  v-model="childSPCheck"
                  class="ml-1 text-primary"
                  name="childSPCheck"
                >
                  Хүүхдийн тусгай төлөвлөлт
                </b-form-checkbox>
              </div>
              <div class="px-3 pb-3 col-lg-2">
                <b-form-checkbox
                  v-model="specialCheck"
                  class="ml-1 text-danger"
                  name="specialCheck"
                  size="sm"
                >
                  Тусгай төлөвлөлт
                </b-form-checkbox>
              </div>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr role="row">
                  <th :rowspan="monthData.culturals.length">Ажлын төрөл</th>
                  <th>№</th>
                  <th>Ажлын дараалал</th>
                  <th
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-warning':
                        getYear(monthData.month) == nowYear &&
                        monthData.month == nowMonth &&
                        day == today,
                    }"
                    :title="
                      getYear(monthData.month) == nowYear &&
                      monthData.month == nowMonth &&
                      day == today
                        ? 'Өнөөдөр'
                        : monthData.month + '-р сарын ' + day
                    "
                  >
                    {{ monthData.month }}/{{ day }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="monthData.culturals.length > 0 && cultureCheck == true"
              >
                <tr
                  v-for="(culture, index) in monthData.culturals"
                  :key="culture.project_id"
                >
                  <td v-if="index == 0" :rowspan="monthData.culturals.length">
                    <b
                      >Соёл хүмүүжлийн ажил /{{
                        culture.level && culture.level.name
                      }}/</b
                    >
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ culture.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-success': getDayData(monthData.month, culture, day),
                    }"
                    :title="
                      getDayData(monthData.month, culture, day)
                        ? culture.startMonth +
                          '/' +
                          culture.startDay +
                          '-' +
                          culture.endMonth +
                          '/' +
                          culture.endDay +
                          ': ' +
                          culture.name
                        : ''
                    "
                  ></td>
                </tr>
              </tbody>
              <tbody v-if="monthData.tasks.length > 0 && taskCheck == true">
                <tr
                  v-for="(task, index) in monthData.tasks"
                  :key="task.project_id"
                >
                  <td v-if="index == 0" :rowspan="monthData.tasks.length">
                    <b>Үүрэг даалгавар</b>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    v-html="task.name"
                  ></td>
                  <td
                    class="dtr-control sorting_1"
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-warning': getDayData(monthData.month, task, day),
                    }"
                    :title="
                      getDayData(monthData.month, task, day)
                        ? task.startMonth +
                          '/' +
                          task.startDay +
                          '-' +
                          task.endMonth +
                          '/' +
                          task.endDay +
                          ': ' +
                          htmlToString(task.name)
                        : ''
                    "
                  ></td>
                </tr>
              </tbody>
              <tbody
                v-if="monthData.monthPlans.length > 0 && monthCheck == true"
              >
                <tr
                  v-for="(monthPlan, index) in monthData.monthPlans"
                  :key="monthPlan.project_id"
                >
                  <td v-if="index == 0" :rowspan="monthData.monthPlans.length">
                    <b>Сарын төлөвлөгөө</b>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ monthPlan.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-primary': getDayData(monthData.month, monthPlan, day),
                    }"
                    :title="
                      getDayData(monthData.month, monthPlan, day)
                        ? monthPlan.startMonth +
                          '/' +
                          monthPlan.startDay +
                          '-' +
                          monthPlan.endMonth +
                          '/' +
                          monthPlan.endDay +
                          ': ' +
                          monthPlan.name
                        : ''
                    "
                  ></td>
                </tr>
              </tbody>
              <tbody
                v-if="monthData.childsSPlans.length > 0 && childSPCheck == true"
              >
                <tr
                  v-for="(childSPlan, index) in monthData.childsSPlans"
                  :key="childSPlan.project_id"
                >
                  <td
                    v-if="index == 0"
                    :rowspan="monthData.childsSPlans.length"
                  >
                    <b>Хүүхдийн тусгай төлөвлөлт</b>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ childSPlan.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-primary': getDayData(
                        monthData.month,
                        childSPlan,
                        day
                      ),
                    }"
                    :title="
                      getDayData(monthData.month, childSPlan, day)
                        ? childSPlan.startMonth +
                          '/' +
                          childSPlan.startDay +
                          '-' +
                          childSPlan.endMonth +
                          '/' +
                          childSPlan.endDay +
                          ' ' +
                          childSPlan.student_name +
                          ': ' +
                          childSPlan.name
                        : ''
                    "
                  ></td>
                </tr>
              </tbody>
              <tbody
                v-if="monthData.specialPlans.length > 0 && specialCheck == true"
              >
                <tr
                  v-for="(sPlan, index) in monthData.specialPlans"
                  :key="sPlan.project_id"
                >
                  <td
                    v-if="index == 0"
                    :rowspan="monthData.specialPlans.length"
                  >
                    <b>Тусгай төлөвлөлт</b>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ sPlan.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    v-for="day in getDaysInMonth(monthData.month)"
                    :key="day"
                    :class="{
                      'bg-danger': getDayData(monthData.month, sPlan, day),
                    }"
                    :title="
                      getDayData(monthData.month, sPlan, day)
                        ? sPlan.startMonth +
                          '/' +
                          sPlan.startDay +
                          '-' +
                          sPlan.endMonth +
                          '/' +
                          sPlan.endDay +
                          ': ' +
                          sPlan.name
                        : ''
                    "
                  ></td>
                </tr>
              </tbody>
            </table>
            <div class="row mt-3">
              <div class="d-flex px-3 pb-3 col-lg-3">
                <div
                  class="bg-success my-auto legends"
                  title="Соёл хүмүүжлийн ажил"
                ></div>
                <strong class="text-success" title="Соёл хүмүүжлийн ажил">
                  Соёл хүмүүжлийн ажил
                </strong>
              </div>
              <div
                class="d-flex px-3 pb-3 col-lg-3"
                v-if="role.name != 'dentstu' && role.name != 'rentpa'"
              >
                <div
                  class="bg-warning my-auto legends"
                  title="Үүрэг даалгавар"
                ></div>
                <strong class="text-warning" title="Үүрэг даалгавар">
                  Үүрэг даалгавар</strong
                >
              </div>
              <div
                class="d-flex px-3 pb-3 col-lg-3"
                v-if="role.name != 'dentstu' && role.name != 'rentpa'"
              >
                <div
                  class="bg-primary my-auto legends"
                  title="Сарын төлөвлөгөө"
                ></div>
                <strong class="text-primary"> Сарын төлөвлөгөө </strong>
              </div>
              <div
                class="d-flex px-3 pb-3 col-lg-3"
                v-else-if="role.name == 'rentpa'"
              >
                <div
                  class="bg-primary my-auto legends"
                  title="Хүүхдийн тусгай төлөвлөлт"
                ></div>
                <strong class="text-primary">
                  Хүүхдийн тусгай төлөвлөлт
                </strong>
              </div>
              <div class="d-flex px-3 pb-3 col-lg-3">
                <div
                  class="bg-danger my-auto legends"
                  title="Тусгай төлөвлөлт"
                ></div>
                <strong class="text-danger"> Тусгай төлөвлөлт </strong>
              </div>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    role: function () {
      return this.$store.state.userRoleInfo;
    },
  },
  data() {
    return {
      selectedDate: new Date(),
      selected_year: null,
      year: null,
      calendarMonths: [],
      nowYear: null,
      nowMonth: null,
      today: null,
      colors: [
        "rgb(106 50 159 / 45%)",
        "rgb(239 130 0 / 45%)",
        "rgb(0 172 62 / 45%)",
        "rgb(0 119 180 / 45%)",
        "rgb(224 72 53 / 45%)",
        "rgb(158 1 147 / 45%)",
        "rgb(241 194 50 / 45%)",
        "rgb(44 70 221 / 45%)",
        "rgb(102 205 170 / 45%)",
        "rgb(237 112 0 / 45%)",
        "rgb(206 40 51 / 45%)",
        "rgb(254 200 67 / 45%)",
      ],
      monthData: null,
      cultureCheck: true,
      taskCheck: true,
      monthCheck: true,
      specialCheck: true,
      childSPCheck: true,

      cultureCalCheck: true,
      taskCalCheck: true,
      monthCalCheck: true,
      specialCalCheck: true,
      childSPCalCheck: true,
    };
  },
  methods: {
    //#region calendar months
    selectYear() {
      this.selectedDate = new Date(this.selected_year.start_date);
      this.year = this.selectedDate.getFullYear();
      this.getCalendarData();
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getCurrentYear() {
      axios
        .get("/all_worker_functions/getCurrentYear")
        .then((response) => {
          this.selected_year = response.data.currentYear;
          this.selectedDate = new Date(this.selected_year.start_date);
          this.year = this.selectedDate.getFullYear();
          this.getCalendarData();
        })
        .catch(() => {});
    },
    getUrl(type) {
      if (type == "task") return "/teacher/taskTeacher";
      else if (type == "monthPlan") return "/all_worker_functions/monthPlans";
      else if (type == "specialPlan") {
        if (this.role.name == "rentpa") return "/parent/specialPlanParent";
        else if (this.role.name == "rentpa") "/student/specialPlanStudent";
        else return "/teacher/getMySpecialPlans";
      } else return "";
    },
    getFilterData(month, day, items) {
      return items.filter(
        (i) =>
          i.startYear == this.getYear(month) &&
          (i.endMonth > i.startMonth
            ? i.startMonth == month
              ? i.startDay <= day
              : i.endDay >= day
            : i.startDay <= day && i.endDay >= day)
      );
    },
    getTooltipContent(type, month, day, items) {
      const filteredData = this.getFilterData(month, day, items);
      let tasksList = "";
      if (this.role.name == "rentpa" && type == "childsSPlans")
        tasksList = filteredData
          .map(
            (item) =>
              `<li key="${item.id}"><b>${item.student_name}</b>: ${item.name}</li>`
          )
          .join("<br>");
      else
        tasksList = filteredData
          .map((item) => `<li key="${item.id}">${item.name}</li>`)
          .join("<br>");

      return `<dd class="calendar__number--${type}-text"><ul>${tasksList}</ul></dd>`;
    },
    getCalendarData() {
      let nowDate = new Date();
      this.nowYear = nowDate.getFullYear();
      this.nowMonth = nowDate.getMonth() + 1;
      this.today = nowDate.getDate();
      axios
        .get("/all_worker_functions/getCalenderMonths/" + this.selected_year.id)
        .then((response) => {
          this.calendarMonths = response.data.calendarMonths;
        })
        .catch(() => {});
    },
    getYear(month) {
      let dateYear = 0;
      if (month < 8) dateYear = this.year + 1;
      else dateYear = this.year;
      return dateYear;
    },
    getDaysInMonth(month) {
      let day = new Date(this.getYear(month), month, 0).getDate();
      return Array.from({ length: day }, (_, index) => index + 1);
    },
    getMonthStartWeekDay(month) {
      const firstDay = new Date(this.getYear(month), month - 1, 1);
      const firstDayOfWeek = firstDay.getDay(); // 0 (Sunday) to 6 (Saturday)
      return Array.from(
        { length: firstDayOfWeek > 0 ? firstDayOfWeek - 1 : 6 },
        (_, index) => index + 1
      );
    },
    //#endregion
    //#region month data show
    showMonthData(monthData) {
      this.monthData = monthData;
    },
    htmlToString(html) {
      return html.replace(/<[^>]*>/g, "");
    },
    getDayData(month, item, day) {
      return item.endMonth > item.startMonth
        ? item.startMonth == month
          ? item.startDay <= day
          : item.endDay >= day
        : item.startDay <= day && item.endDay >= day;
    },
    //#endregion
  },
  created() {
    this.getCurrentYear();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
</style>
