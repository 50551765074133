<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Бэлтгэл бүлгийн жагсаалт</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="study_classes"
                    text-field="text"
                    value-field="value"
                    size="sm"
                  >
                  </b-form-select>
                  <has-error :form="form" field="value"></has-error>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllEntrants"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap" style="font-size: 80%">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Төлөв</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Хүйс</th>
                <th>Цахим шуудан</th>
                <th>Анги</th>
                <th>Сургах ангийн санал</th>
                <th>Тэмдэглэл</th>
                <th>Холбоо барих мэдээлэл</th>
                <th>С/З санал</th>
                <th>Төлбөр</th>
                <th>Төлсөн</th>
                <th>Үлдэгдэл</th>
                <th>Төлөв</th>
                <th>Хувь</th>
                <th>Хөнгөлөлт</th>
                <th>Үүсгэсэн</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody
              v-for="(schoolEntrant, index) in schoolentrants"
              :key="schoolEntrant.user_id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-warning"
                    >БАТАЛСАН</span
                  >
                  <span
                    v-if="schoolEntrant.status1 === 'registered'"
                    class="badge bg-secondary"
                    >БҮРТГЭГДСЭН</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.student_gender }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.study_class === '0'"
                    >Бэлтгэл</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.study_class_sanal !== null"
                    >{{ schoolEntrant.study_class_sanal }}</b-badge
                  >
                  <b-badge variant="info" v-else>Анги саналгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="warning"
                    class="badge badge-primary text-wrap"
                    style="width: 8rem"
                    v-if="schoolEntrant.study_class_sanal_desc !== null"
                    >{{ schoolEntrant.study_class_sanal_desc }}</b-badge
                  >
                  <b-badge variant="info" v-else
                    >Мэдээлэл бүртгэгдээгүй.</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.contact_info !== null"
                    >{{ schoolEntrant.contact_info }}</b-badge
                  >
                  <b-badge variant="info" v-else>Мэдээлэлгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.psychologist_offer === 1"
                    >Элсүүлэх</b-badge
                  >
                  <b-badge variant="info" v-else>Элсүүлэхгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.pay }} төг
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.paid }} төг
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{
                    schoolEntrant.pay -
                    schoolEntrant.paid -
                    schoolEntrant.discount
                  }}
                  төг
                </td>
                <td class="dtr-control sorting_1 pt-4" tabindex="0">
                  <div class="progress progress-xs progress-striped active">
                    <div
                      v-if="schoolEntrant.percent < 30"
                      class="progress-bar bg-danger"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 30 &&
                        schoolEntrant.percent < 60
                      "
                      class="progress-bar bg-warning"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 60 &&
                        schoolEntrant.percent < 80
                      "
                      class="progress-bar bg-primary"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="schoolEntrant.percent >= 80"
                      class="progress-bar bg-success"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                  </div>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-danger"
                    >ШИНЭ</span
                  >
                  <span
                    v-if="schoolEntrant.percent < 30"
                    class="badge bg-danger"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 30 && schoolEntrant.percent < 60
                    "
                    class="badge bg-warning"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 60 && schoolEntrant.percent < 80
                    "
                    class="badge bg-primary"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="schoolEntrant.percent >= 80"
                    class="badge bg-success"
                    >{{ schoolEntrant.percent }}%</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.discountPercent < 30"
                    class="badge bg-danger"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.discountPercent >= 30 &&
                      schoolEntrant.discountPercent < 60
                    "
                    class="badge bg-warning"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.discountPercent >= 60 &&
                      schoolEntrant.discountPercent < 80
                    "
                    class="badge bg-primary"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="schoolEntrant.discountPercent >= 80"
                    class="badge bg-success"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    v-b-popover.hover="'Түвшин тогтоох шалгалтын дүн оруулах!'"
                    title="Дүн"
                    href="#"
                    class="badge bg-info"
                    @click="editSchoolEntrantExam(schoolEntrant)"
                    ><i class="fas fa-font"></i
                  ></a>
                  <a
                    v-b-popover.hover="
                      'Шалгалтанд тэнцсэн суралцагчыг баталгаажуулах'
                    "
                    title="Тэнцсэн суралцагч"
                    href="#"
                    class="badge bg-primary"
                    @click="editSchoolEntrant(schoolEntrant)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    v-show="schoolEntrant.status1 != 'registered'"
                    v-b-popover.hover="
                      'Суралцагчийг хүлээлгийн горимд шилжүүлэх.'
                    "
                    title="Суралцагч буцаах"
                    href="#"
                    class="badge bg-warning"
                    @click="moveRegisterMode(schoolEntrant)"
                  >
                    <i class="far fa-arrow-alt-circle-down"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="6"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">Анги</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эрэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эмэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="6"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">1а</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAFeMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countA() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="6"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">1б</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countBMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countBFeMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countB() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="6"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">1c</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countCMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countCFeMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countC() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="6"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAllEntrunts() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">Элсэгч баталгаажуулах</h3>
        </div>
        <form @submit.prevent="editMode ? updateSchoolEntrant() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="profile_last_name"
                v-model="form.profile_last_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('profile_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="profile_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="user_name"
                v-model="form.user_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="user_name"></has-error>
            </div>
            <div class="form-group">
              <label>Хүйс</label>
              <b-form-select
                v-model="form.student_gender"
                :options="genderOptions"
                text-field="text"
                value-field="value"
              >
              </b-form-select>
              <has-error :form="form" field="student_gender"></has-error>
            </div>
            <div class="form-group">
              <label>Төлөв</label>
              <b-form-checkbox
                v-model="form.checked"
                name="checked"
                switch
                size="lg"
              />
            </div>
            <div class="form-group">
              <label
                >Анги сонгох(Эцсийн сонголт биш, бэлтгэл хөтөлбөрийн дараа анги
                хуваарилалд албан ёсоор хийгдэнэ)</label
              >
              <b-form-select
                v-model="form.study_class_sanal"
                :options="options"
                size="sm"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Анги хуваарилалт дээр саналуудыг тэмдэглэж явах</label>
              <b-form-textarea
                id="textarea"
                v-model="form.study_class_sanal_desc"
                placeholder="Эцэг эх, багш, менежер гэх мэт холбогдох хүмүүсийн саналыг тэмдэглэх"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      size="sx"
      id="modal-entrustInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Түвшин тогтоох шалгалтын дүн оруулах"
      class="modal fade"
      hide-footer
      @hide="cancelEntrant"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-0.2">
            <form @submit.prevent="updateEntrantExamGrade()">
              <div class="card-body">
                <div class="form-group">
                  <label>Монгол хэл</label>
                  <input
                    type="number"
                    name="mon_script"
                    v-model="formEntrunt.mon_script"
                    placeholder="Монгол хэлний дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('mon_script'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Математик</label>
                  <input
                    type="number"
                    name="mon_maths"
                    v-model="formEntrunt.mon_maths"
                    placeholder="Монгол математикийн дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('mon_maths'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж математик</label>
                  <input
                    type="number"
                    name="camb_maths"
                    v-model="formEntrunt.camb_maths"
                    placeholder="Кембриж математикийн дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_maths'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж англи хэл</label>
                  <input
                    type="number"
                    name="camb_eng"
                    v-model="formEntrunt.camb_eng"
                    placeholder="Кембриж англи хэлний дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_eng'),
                      },
                    ]"
                  />
                </div>
                <div class="form-group">
                  <label>Кембриж байгалийн ухаан</label>
                  <input
                    type="number"
                    name="camb_sci"
                    v-model="formEntrunt.camb_sci"
                    placeholder="Кембриж байгалийн ухааны дүн"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('camb_sci'),
                      },
                    ]"
                  />
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-secondary"
                  @click.prevent="cancelEntrant"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      selected: "0",
      study_classes: [{ value: "0", text: "Бэлтгэл анги" }],
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      action: "",
      editMode: false,
      schoolentrants: [],
      options: [
        { value: "1a", text: "1a анги" },
        { value: "1b", text: "1b анги" },
        { value: "1c", text: "1c анги" },
      ],
      form: new Form({
        user_name: "",
        profile_last_name: "",
        checked: false,
        study_class_sanal: "",
        student_gender: "",
        study_class_sanal_desc: "",
        user_id: "",
      }),
      formEntrunt: new Form({
        user_id: "",
        mon_script: null,
        mon_maths: null,
        camb_maths: null,
        camb_eng: null,
        camb_sci: null,
      }),
      //gender
      genderOptions: [
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
    };
  },

  methods: {
    getAllEntrants() {
      axios
        .get("/manager/getWaitEntrants/" + this.selected)
        .then((response) => {
          let allStudents = response.data.schoolentrants;
          allStudents.forEach((element) => {
            if (element.paid != 0) {
              element.percent = parseFloat(
                (element.paid * 100) / element.pay
              ).toFixed(2);
            } else {
              element.percent = 0;
            }
            if (element.discount != 0) {
              element.discountPercent = parseFloat(
                (element.discount * 100) / element.pay
              ).toFixed(2);
            } else {
              element.discountPercent = 0;
            }
          });
          this.schoolentrants = allStudents;
          this.school_classes = response.data.school_classes;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
      if (schoolEntrant.status1 == "registered") this.form.checked = false;
      else if (schoolEntrant.status1 == "confirmed") this.form.checked = true;
    },
    updateSchoolEntrant() {
      this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateEntrantConfirm/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    editSchoolEntrantExam(schoolEntrant) {
      this.formEntrunt.fill(schoolEntrant);
      this.$bvModal.show("modal-entrustInfo");
    },
    updateEntrantExamGrade() {
      this.formEntrunt
        .put("/manager/updateEntruntExamGrade")
        .then((res) => {
          Fire.$emit("loadSchoolStudents");
          this.$toastr.s("Амжилттай", "Дүнгийн мэдээллийг орууллаа");
          this.cancelEntrant();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    moveRegisterMode(schoolEntrant) {
      //   console.log(schoolEntrant);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Суралцагчийг хүлээлгийн горимд шилжүүлэх гэж байна.!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хүлээлгэнд шилжүүл!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put("/manager/moveRegisterMode/" + schoolEntrant.user_id, {
              profileInfo: schoolEntrant,
            })
            .then((res) => {
              Fire.$emit("loadSchoolStudents");
              this.$toastr.s("Амжилттай", "Суралцагчыг амжилттай буцаалаа.");
              Swal.fire(
                "Шилжүүлсэн!",
                "Суралцагчийг хүлээлгийн горимд шилжүүллээ.",
                "success"
              );
              //   console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    cancelEntrant() {
      this.formEntrunt.reset();
      this.$bvModal.hide("modal-entrustInfo");
    },

    // Тооцоолол
    //A
    countAMale() {
      let countMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эрэгтэй" && obj.study_class_sanal === "1a"
      ).length;
      return countMale;
    },
    countAFeMale() {
      let countFeMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эмэгтэй" && obj.study_class_sanal === "1a"
      ).length;
      return countFeMale;
    },
    countA() {
      let countA = this.countAMale() + this.countAFeMale();
      return countA;
    },
    //B
    countBMale() {
      let countMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эрэгтэй" && obj.study_class_sanal === "1b"
      ).length;
      return countMale;
    },
    countBFeMale() {
      let countFeMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эмэгтэй" && obj.study_class_sanal === "1b"
      ).length;
      return countFeMale;
    },
    countB() {
      let countA = this.countBMale() + this.countBFeMale();
      return countA;
    },
    //C
    countCMale() {
      let countMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эрэгтэй" && obj.study_class_sanal === "1c"
      ).length;
      return countMale;
    },
    countCFeMale() {
      let countFeMale = this.schoolentrants.filter(
        (obj) =>
          obj.student_gender === "Эмэгтэй" && obj.study_class_sanal === "1c"
      ).length;
      return countFeMale;
    },
    countC() {
      let countA = this.countCMale() + this.countCFeMale();
      return countA;
    },
    countAllEntrunts() {
      let allEntrunts = this.countA() + this.countB() + this.countC();
      return allEntrunts;
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
