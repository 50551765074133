<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Сургуульд модуль тохируулах</strong>
          </h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="maximize">
              <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <multiselect
                v-model="school_year"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="id"
                label="id"
                @input="getAllModules"
                :custom-label="customYearName"
                placeholder="Хичээлийн жил сонгоно уу?"
                :options="years"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                    - хичээлийн жил
                  </strong></template
                >
              </multiselect>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <th>№</th>
                <th>Сургууль</th>
                <th>Модуль</th>
              </thead>
              <tbody>
                <tr v-for="(schoolModule, index) in schoolModules" :key="schoolModule.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ schoolModule.school_name }}
                  </td>
                  <td class="">
                    <div class="row">
                      <div
                        class="col-lg-3 col-md-6 col-sm-12 mb-2"
                        v-for="mod in schoolModule.my_school_modules"
                        :key="mod.id"
                      >
                        <b-badge
                          :class="[
                            'mr-1 modPad w-100',
                            {
                              modulMenus: mod.status == 'active',
                              modulNoMenus: mod.status == 'no',
                            },
                          ]"
                          variant="success"
                        >
                          <div class="d-flex justify-content-between">
                            <div class="module-name">
                              <a
                                href="#"
                                v-if="mod.status == 'active'"
                                @click="checkModule(mod)"
                              >
                                <b-icon
                                  icon="check-square-fill"
                                  aria-hidden="true"
                                  class="mr-2 text-success"
                                ></b-icon
                              ></a>
                              <a href="#" v-else @click="checkModule(mod)">
                                <b-icon
                                  icon="square"
                                  aria-hidden="true"
                                  class="mr-2 text-success"
                                ></b-icon
                              ></a>
                              {{ mod.my_module.name | uppercase }}
                            </div>
                            <div class="actions">
                              <a
                                href="#"
                                class="badge bg-primary ml-2"
                                v-if="mod.status == 'active'"
                                @click="editModFromSchool(schoolModule, mod)"
                                ><i class="fas fa-edit"></i
                              ></a>
                              <a
                                href="#"
                                class="badge bg-warning"
                                @click="
                                  removeModFromSchool(schoolModule.school_name, mod)
                                "
                                ><i class="fas fa-minus"></i
                              ></a>
                            </div>
                          </div>
                          <ul class="list-unstyled text-left pt-1 mt-1 myList">
                            <li
                              v-for="menu in mod.my_menus"
                              :key="menu.id"
                              class="mb-2"
                              :class="[
                                { 'ml-2': menu.level == 2 },
                                { 'ml-4': menu.level == 3 },
                              ]"
                            >
                              <div class="d-flex w-100">
                                <div class="menuCheck">
                                  <a
                                    href="#"
                                    v-if="menu.status == 'active'"
                                    @click="checkMenu(menu)"
                                  >
                                    <b-icon
                                      icon="check-circle-fill"
                                      aria-hidden="true"
                                      class="mr-1 text-success fa-sm"
                                    ></b-icon
                                  ></a>
                                  <a href="#" v-else @click="checkMenu(menu)">
                                    <b-icon
                                      icon="circle"
                                      aria-hidden="true"
                                      class="mr-1 fa-sm text-success"
                                    ></b-icon
                                  ></a>
                                </div>
                                <div class="menuName">
                                  <span v-if="menu.status == 'active'">
                                    {{ menu.name }}
                                  </span>
                                  <span v-else class="text-disabled">
                                    {{ menu.name }}
                                  </span>
                                </div>
                              </div>
                              <div class="mt-1 roles ml-4">
                                <span class="mt-1">Role:</span>
                                <b-badge
                                  variant="secondary"
                                  class="ml-1 mt-1"
                                  v-for="role in menu.my_roles"
                                  :key="role.id"
                                >
                                  {{ role.name }}
                                </b-badge>
                              </div>
                            </li>
                          </ul>
                        </b-badge>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <b-button
                          @click.prevent="addModForSchool(schoolModule)"
                          size="lg"
                          class="modulMenus"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="plus"
                            variant="success"
                            scale="1.5"
                            aria-label="Add"
                          ></b-icon>
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Модуль сургуульд нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-addModule"
      title="Сургуулийн модуль"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>{{ formSchoolModule.school_name }}</label>
              </div>
              <div class="form-group" v-if="!editModuleForSchoolMode">
                <label>Модуль сонгох</label>
                <b-form-select
                  v-model="formSchoolModule.module_id"
                  :options="otherModules"
                  text-field="name"
                  @change="getModuleMenus"
                  value-field="id"
                  size="sm"
                  :class="[
                    'form-control',
                    { 'is-invalid': formSchoolModule.errors.has('module_id') },
                  ]"
                ></b-form-select>
              </div>
              <div class="form-group" v-else>
                <label>Модуль: </label>
                {{ formSchoolModule.module_name }}
              </div>
              <div class="form-group">
                <label>Цэс сонгох</label>
                <multiselect
                  v-model="formSchoolModule.selected_menus"
                  :options="moduleMenus"
                  :multiple="true"
                  track-by="menu_id"
                  label="name"
                  :searchable="true"
                  :close-on-select="false"
                  :show-labels="true"
                  :hideSelected="true"
                  placeholder="Цэс сонгох"
                  :allow-empty="true"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                  selected-label="Сонгогдсон"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.name }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc justify-content-between">
                      <span
                        :class="[
                          'option__title',
                          {
                            'ml-3': props.option.level == 2,
                          },
                          {
                            'ml-5': props.option.level == 3,
                          },
                        ]"
                        ><i
                          :class="[
                            {
                              'fas fa-caret-down fa-fw':
                                props.option.isParent == 1 && props.option.level != 3,
                            },
                          ]"
                          v-if="props.option.isParent == 1"
                        ></i>
                        <span v-else>•</span>
                        {{ props.option.name }}
                      </span>
                      <span class="option__small">
                        <b-badge
                          variant="secondary"
                          class="mx-1"
                          v-for="role in props.option.my_roles"
                          :key="role.id"
                          >{{ role.my_role.name }}</b-badge
                        >
                      </span>
                    </div>
                  </template>
                </multiselect>
                <has-error :form="formSchoolModule" field="selected_menus"></has-error>
              </div>
              <button
                type="submit"
                v-if="editModuleForSchoolMode"
                @click.prevent="updateModForSchool()"
                class="btn btn-sm btn-success"
              >
                Засах
              </button>
              <button
                v-if="editModuleForSchoolMode"
                @click.prevent="cancelModForSchool()"
                class="btn btn-sm btn-warning"
              >
                Болих
              </button>
              <button
                type="submit"
                v-if="!editModuleForSchoolMode"
                @click.prevent="addModuleForSchool()"
                class="btn btn-sm btn-primary"
              >
                Нэмэх
              </button>
            </form>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      years: [],
      school_year: "",
      formSchoolModule: new Form({
        id: "",
        school_name: "",
        module_name: "",
        module_id: "",
        school_id: "",
        year_id: "",
        selected_menus: [],
      }),
      schoolModules: [],
      otherModules: [],
      moduleMenus: [],
      editModuleForSchoolMode: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllYears() {
      axios
        .get("/owner/getAllyears")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch(() => {});
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getAllModules() {
      axios
        .get("/owner/getAllModules/" + this.school_year.id)
        .then((response) => {
          this.schoolModules = response.data.schoolModules;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //================== School module ======================
    addModForSchool(school) {
      axios
        .post("/owner/getOtherModules", {
          yearId: this.school_year.id,
          schoolId: school.id,
        })
        .then((response) => {
          this.otherModules = response.data.otherModules;
        })
        .catch(() => {});
      this.formSchoolModule.reset();
      this.moduleMenus = [];
      this.formSchoolModule.school_id = school.id;
      this.formSchoolModule.school_name = school.school_name;
      this.editModuleForSchoolMode = false;
      this.$bvModal.show("modal-addModule");
    },
    getModuleMenus() {
      axios
        .get("/owner/getModuleMenus/" + this.formSchoolModule.module_id)
        .then((response) => {
          this.moduleMenus = response.data.moduleMenus;
        })
        .catch(() => {});
    },
    checkModule(mod) {
      axios
        .get("/owner/checkModuleForSchool/" + mod.id)
        .then(() => {
          this.getAllModules();
        })
        .catch(() => {});
    },
    checkMenu(menu) {
      axios
        .get("/owner/checkMenuForSchool/" + menu.id)
        .then(() => {
          this.getAllModules();
        })
        .catch(() => {});
    },
    addModuleForSchool() {
      this.formSchoolModule.year_id = this.school_year.id;
      this.formSchoolModule
        .post("/owner/addModuleForSchool")
        .then(() => {
          this.$toastr.s("Сургуульд модуль амжилттай нэмлээ.", "Амжилттай");
          this.getAllModules();
          this.formSchoolModule.reset();
          this.$bvModal.hide("modal-addModule");
          this.editModuleForSchoolMode = false;
        })
        .catch(() => {});
    },
    editModFromSchool(school, module) {
      this.formSchoolModule.fill(module);
      this.formSchoolModule.selected_menus = module.my_menus;
      this.formSchoolModule.school_id = school.id;
      this.formSchoolModule.school_name = school.school_name;
      this.formSchoolModule.module_name = module.my_module.name;
      this.editModuleForSchoolMode = true;
      this.getModuleMenus();
      this.$bvModal.show("modal-addModule");
    },
    updateModForSchool() {
      this.formSchoolModule
        .put("/owner/updateModForSchool/" + this.formSchoolModule.id)
        .then(() => {
          this.$toastr.s("Сургуулийн модуль амжилттай заслаа.", "Амжилттай");
          this.getAllModules();
          this.formSchoolModule.reset();
          this.$bvModal.hide("modal-addModule");
          this.editModuleForSchoolMode = false;
        })
        .catch(() => {});
    },
    cancelModForSchool() {
      this.formSchoolModule.reset();
      this.editModuleForSchoolMode = false;
      this.$bvModal.hide("modal-addModule");
    },
    removeModFromSchool(schoolName, module) {
      Swal.fire({
        title: "Модуль хасах!",
        text:
          "Та " +
          schoolName +
          " сургуулийн " +
          module.my_module.name +
          " модулийг хасах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Хасах`,
        denyButtonText: `Болих`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Модуль хасаж байна...";
          axios
            .get("/owner/removeModFromSchool/" + module.id)
            .then((response) => {
              this.$toastr.s("Модуль амжилттай хасла!", "Шинэчилсэн");
              this.getAllModules();
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
  },
  created() {
    this.getAllYears();
  },
};
</script>

<style scoped>
.myList {
  border-top: 1px solid rgb(255 255 255);
}
.display-flex {
  display: flex;
}
.modulMenus {
  height: 100%;
}
.modulNoMenus {
  opacity: 60%;
  height: 100%;
}
.modPad {
  padding: 15px;
}
.text-disabled {
  font-weight: 100;
}
.roles {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
.menuName {
  text-wrap: wrap;
}
.badge {
  font-weight: 400;
}
</style>
