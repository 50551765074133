<template>
  <div class="row">
    <div class="col-lg-3 col-6">
      <div class="small-box bg-info">
        <div class="inner">
          <h4>
            {{ allSchoolStudents }}/{{ schoolCapacity }} :
            {{ getScore((allSchoolStudents / schoolCapacity) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>Сургууль</p>
        </div>
        <div class="icon">
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ангиудын бүртгэл</h3>
        </div>
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCount"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-6">
      <div class="small-box bg-success">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsPrimary }}/{{ schoolCapacity }} :
            {{
              getScore((allSchoolStudentsPrimary / schoolCapacity) * 100)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Бага сургууль: {{ allSchoolStudentsPrimary }}/208 :
            {{ getScore((allSchoolStudentsPrimary / 312) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Бага ангийн бүртгэл</h3>
        </div>
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountPrimary"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-6">
      <div class="small-box bg-warning">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsSecondary }}/{{ schoolCapacity }} :
            {{
              getScore((allSchoolStudentsSecondary / schoolCapacity) * 100)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Дунд сургууль: {{ allSchoolStudentsSecondary }}/208 :
            {{ getScore((allSchoolStudentsSecondary / 156) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Дунд ангийн бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountMiddle"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-3 col-6">
      <!-- small box -->
      <div class="small-box bg-danger">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsHigh }}/{{ schoolCapacity }} :
            {{ getScore((allSchoolStudentsHigh / schoolCapacity) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Ахлах сургууль: {{ allSchoolStudentsHigh }}/208 :
            {{ getScore((allSchoolStudentsHigh / 208) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ахлах ангийн бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountHigh"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: false,
      schoolCapacity: "",

      allSchoolStudents: "",
      allSchoolStudentsPrimary: "",
      allSchoolStudentsSecondary: "",
      allSchoolStudentsHigh: "",

      getClassesAll: "",
      getClassesPrimary: "",
      getClassesSecondary: "",
      getClassesHigh: "",

      allClassStudentsCount: [],
      allClassStudentsCountPrimary: [],
      allClassStudentsCountMiddle: [],
      allClassStudentsCountHigh: [],

      teachers: "",
      teachersPrimary: "",
      teachersSecondary: "",
      teachersHigh: "",
    };
  },
  methods: {
    getAllSchoolStudents() {
      axios
        .get("/reg_emp/getAllSchoolStudents")
        .then((response) => {
          this.schoolCapacity = response.data.schoolCapacity;
          //Суралцагчид
          this.allSchoolStudents = response.data.allSchoolStudents;
          //Бага суралцагч
          this.allSchoolStudentsPrimary =
            response.data.getSchoolStudentsPrimary;
          //Дунд суралцагч
          this.allSchoolStudentsSecondary =
            response.data.getSchoolStudentsSecondary;
          //ахлах суралцагч
          this.allSchoolStudentsHigh = response.data.getSchoolStudentsHigh;
          //   console.log(response.data);

          //Ангиуд
          this.getClassesAll = response.data.getClassesAll;
          this.getClassesPrimary = response.data.getClassesPrimary;
          this.getClassesSecondary = response.data.getClassesSecondary;
          this.getClassesHigh = response.data.getClassesHigh;
          //Ангиудын суралцагчдын тоо
          this.allClassStudentsCount = response.data.allClassStudentsCount;
          this.allClassStudentsCountPrimary =
            response.data.allClassStudentsCountPrimary;
          this.allClassStudentsCountMiddle =
            response.data.allClassStudentsCountMiddle;
          this.allClassStudentsCountHigh =
            response.data.allClassStudentsCountHigh;
          //Багш нар
          this.teachers = response.data.teachers;
          this.teachersPrimary = response.data.teachersPrimary;
          this.teachersSecondary = response.data.teachersSecondary;
          this.teachersHigh = response.data.teachersHigh;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    //Орон нарийвчлах
    getScore(val) {
      return val.toFixed(1);
    },
    //Эерэг сурах утга хэвлэх
    signCheck(number) {
      return Math.sign(number);
    },
    checkClass(value) {
      if (value <= 50) {
        return "bg-danger";
      } else if (value <= 80) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
  },
  created() {
    this.getAllSchoolStudents();
  },
};
</script>

<style>
</style>
