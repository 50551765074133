<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="#activity" data-toggle="tab" @click="changeTab">Миний хичээлд
                            суусан багш нарын ажиглалт, үнэлгээ, зөвлөгөө,
                            шүүмж</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#timeline" data-toggle="tab" @click="changeTab">Миний зүгээс багшид
                            өгсөн ажиглалт, үнэлгээ, зөвлөгөө, шүүмж</a>
                    </li>
                </ul>
            </h3>
            <div class="card-tools">
                <ul class="pagination pagination-sm float-right">
                    <div v-if="!editDescribeMode">
                        <describe-lesson :editDescribeMode="editDescribeMode" :form="form"
                            :schoolDepartments="schoolDepartments" :schoolClasses="schoolClasses"
                            :schoolTeachers="schoolTeachers" :schoolSubjects="schoolSubjects"
                            @getLoadTeacherEvaluations="getLoadTeacherEvaluations = $event"
                            @getWasEditMode="changeEdit($event)"></describe-lesson>
                    </div>
                </ul>
                <ul class="pagination pagination-sm float-right">
                    <div v-if="!teaEvaEditMode">
                        <create-lesson :teaEvaEditMode="teaEvaEditMode" :form="form"
                            :mySchoolQuarters="mySchoolQuarters" :schoolDepartments="schoolDepartments"
                            :schoolClasses="schoolClasses" :schoolTeachers="schoolTeachers"
                            :schoolSubjects="schoolSubjects"
                            @getLoadTeacherEvaluations="getLoadTeacherEvaluations = $event"
                            @getWasEditMode="changeEdit($event)"></create-lesson>
                    </div>
                    <div v-else>
                        <create-lesson :teaEvaEditMode="teaEvaEditMode" :form="form"
                            :mySchoolQuarters="mySchoolQuarters" :schoolDepartments="schoolDepartments"
                            :schoolClasses="schoolClasses" :schoolTeachers="schoolTeachers"
                            :schoolSubjects="schoolSubjects"
                            @getLoadTeacherEvaluations="getLoadTeacherEvaluations = $event"
                            @getWasEditMode="changeEdit($event)"></create-lesson>
                    </div>
                </ul>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
            <div>
                <compare-teacher-lesson-evaluation v-if="compareTE"
                    :passTeachersEvaluationData="passTeachersEvaluationData">
                </compare-teacher-lesson-evaluation>
            </div>
            <div class="tab-content">
                <div class="tab-pane active" id="activity">
                    <div class="card-body table-responsive p-0" style="overflow-x: auto">
                        <search-evaluation @getWasSearchTeacherEvaluations="
                            changeTeacherEvaluations = $event
                            "
                            placeText="Таны хичээлд суусан үнэлгээнээс хайх бол хичээлийн сэдвээр хайна уу!"></search-evaluation>
                        <table class="table table-hover table-sm">
                            <thead>
                                <tr>
                                    <th style="width: 10px">#</th>
                                    <th>Багш</th>
                                    <th>БНБ</th>
                                    <th>Менежер</th>
                                    <th>Анги</th>
                                    <th>Хичээл</th>
                                    <th>Сэдэв</th>
                                    <th>Оноо хийсэн огноо</th>
                                    <th>Эхлэх хэсэг</th>
                                    <th>Өрнөл хэсэг</th>
                                    <th>Төгсгөл хэсэг</th>
                                    <th>Ерөнхий үнэлгээ</th>
                                    <th style="width: 10%">Үйлдлүүд</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <load-evaluations></load-evaluations> -->
                                <tr v-if="changeTeacherEvaluations.length"
                                    v-for="(teacherEvaluation, index) in changeTeacherEvaluations"
                                    :key="teacherEvaluation.id">
                                    <td>{{ index + 1 }}.</td>
                                    <td>
                                        {{ teacherEvaluation.employees_teacher_last_name[0] }}.{{
                                            teacherEvaluation.employees_teacher_name
                                        }}
                                    </td>
                                    <td>{{ teacherEvaluation.department_code }}</td>
                                    <td>{{ teacherEvaluation.evaluation_worker_name }}</td>
                                    <td>{{ teacherEvaluation.school_class_full_name }}</td>
                                    <td>{{ teacherEvaluation.subject_name }}</td>
                                    <td>{{ teacherEvaluation.lesson_topic }}</td>
                                    <td>{{ teacherEvaluation.lesson_ognoo }}</td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-warning" :style="{
                                                width: teacherEvaluation.averageShalBegProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-warning">{{ teacherEvaluation.averageShalBegProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-success" :style="{
                                                width: teacherEvaluation.averageShalMidProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-success">{{ teacherEvaluation.averageShalMidProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-danger" :style="{
                                                width: teacherEvaluation.averageShalEndProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-danger">{{ teacherEvaluation.averageShalEndProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-info" :style="{
                                                width: teacherEvaluation.averageShalFullProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-info">{{ teacherEvaluation.averageShalFullProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <a href="#" class="badge bg-purple"
                                            @click="viewLessonEvaluation(teacherEvaluation)">
                                            <i class="fas fa-chart-bar"></i>
                                        </a>

                                        <a href="#" class="badge bg-info"
                                            @click="describeLessonEvaluation(teacherEvaluation)"><i
                                                class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane" id="timeline">
                    <div class="card-body table-responsive p-0" style="overflow-x: auto">
                        <search-evaluation @getWasSearchGiveTeacherEvaluations="
                            changeGiveTeacherEvaluations = $event
                            "
                            placeText="Багш нарын хичээлд суусан үнэлгээнээс хайх бол багшийн нэрээр хайна уу!"></search-evaluation>
                        <table class="table table-hover table-sm">
                            <thead>
                                <tr>
                                    <th style="width: 10px">#</th>
                                    <th>Багш</th>
                                    <th>БНБ</th>
                                    <th>Менежер</th>
                                    <th>Анги</th>
                                    <th>Хичээл</th>
                                    <th>Сэдэв</th>
                                    <th>Оноо хийсэн огноо</th>
                                    <th>Эхлэх хэсэг</th>
                                    <th>Өрнөл хэсэг</th>
                                    <th>Төгсгөл хэсэг</th>
                                    <th>Ерөнхий үнэлгээ</th>
                                    <th style="width: 10%">Үйлдлүүд</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <load-evaluations></load-evaluations> -->
                                <tr v-if="changeGiveTeacherEvaluations.length" v-for="(
                    teacherEvaluation, index
                  ) in changeGiveTeacherEvaluations" :key="teacherEvaluation.id">
                                    <td>{{ index + 1 }}.</td>
                                    <td>
                                        {{ teacherEvaluation.employees_teacher_last_name[0] }}.{{
                                            teacherEvaluation.employees_teacher_name
                                        }}
                                    </td>
                                    <td>{{ teacherEvaluation.department_code }}</td>
                                    <td>{{ teacherEvaluation.evaluation_worker_name }}</td>
                                    <td>{{ teacherEvaluation.school_class_full_name }}</td>
                                    <td>{{ teacherEvaluation.subject_name }}</td>
                                    <td>{{ teacherEvaluation.lesson_topic }}</td>
                                    <td>{{ teacherEvaluation.lesson_ognoo }}</td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-warning" :style="{
                                                width: teacherEvaluation.averageShalBegProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-warning">{{ teacherEvaluation.averageShalBegProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-success" :style="{
                                                width: teacherEvaluation.averageShalMidProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-success">{{ teacherEvaluation.averageShalMidProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-danger" :style="{
                                                width: teacherEvaluation.averageShalEndProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-danger">{{ teacherEvaluation.averageShalEndProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-info" :style="{
                                                width: teacherEvaluation.averageShalFullProcess + '%',
                                            }"></div>
                                        </div>
                                        <span class="badge bg-info">{{ teacherEvaluation.averageShalFullProcess
                                            }}%</span>
                                    </td>
                                    <td>
                                        <a href="#" class="badge bg-purple"
                                            @click="viewLessonEvaluation(teacherEvaluation)">
                                            <i class="fas fa-chart-bar"></i>
                                        </a>

                                        <a href="#" class="badge bg-info"
                                            @click="describeLessonEvaluation(teacherEvaluation)"><i
                                                class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
</template>

<script>
import createLesson from "./createLesson/createTeacherLesson.vue";
import searchEvaluation from "./searchLessonEvaluation/searchEvaluation.vue";
import compareteacherLessonEvaluation from "./CompareTEva/CompareteacherteacherEvaluation.vue";
import describeLesson from "./DescribeLesson/describeLesson.vue";
export default {
    props: ["teacherId"],
    data() {
        return {
            editDescribeMode: false,
            teaEvaEditMode: false,
            compareTE: false,
            teacherEvaluations: [],
            changeTeacherEvaluations: [],
            teacherGiveEvaluations: [],
            changeGiveTeacherEvaluations: [],
            schoolDepartments: [],
            schoolClasses: [],
            schoolTeachers: [],
            schoolSubjects: [],
            mySchoolQuarters: [],
            form: new Form({
                id: null,
                department_id: null,
                school_class_id: null,
                teacher_id: null,
                subject_id: null,
                lesson_time: null,
                lesson_topic: null,
                lesson_ognoo: null,
                shalles1: null,
                shalles1_pos_note: null,
                shalles1_neg_note: null,
                shalles2: null,
                shalles2_pos_note: null,
                shalles2_neg_note: null,
                shalles3: null,
                shalles3_pos_note: null,
                shalles3_neg_note: null,
                shalles4: null,
                shalles4_pos_note: null,
                shalles4_neg_note: null,
                shalles5: null,
                shalles5_pos_note: null,
                shalles5_neg_note: null,
                shalles6: null,
                shalles6_pos_note: null,
                shalles6_neg_note: null,
                shalles7: null,
                shalles7_pos_note: null,
                shalles7_neg_note: null,
                shalles8: null,
                shalles8_pos_note: null,
                shalles8_neg_note: null,
                shalles9: null,
                shalles9_pos_note: null,
                shalles9_neg_note: null,
                shalles10: null,
                shalles10_pos_note: null,
                shalles10_neg_note: null,
            }),
            //Compare evaluations
            passTeachersEvaluationData: [],
        };
    },
    components: {
        searchEvaluation,
        compareTeacherLessonEvaluation: compareteacherLessonEvaluation,
        describeLesson,
        createLesson,
    },
    methods: {
        changeEdit(event) {
            //   console.log(event);
            //   this.editDescribeMode = event;
            Fire.$emit("getLoadTeacherEvaluations");
        },
        changeEditedAndCreated(event) {
            //   console.log(event);
            //   this.editDescribeMode = event;
        },
        calculateLessonBegun(shalles1, shalles2, shalles3) {
            let shalles11 = parseFloat(shalles1);
            let shalles22 = parseFloat(shalles2);
            let shalles33 = parseFloat(shalles3);
            let aveBegun = ((shalles11 + shalles22 + shalles33) / 8) * 100;
            return aveBegun.toFixed(1);
        },
        calculateLessonMid(shalles4, shalles5, shalles6, shalles7, shalles8) {
            let shalles44 = parseFloat(shalles4);
            let shalles55 = parseFloat(shalles5);
            let shalles66 = parseFloat(shalles6);
            let shalles77 = parseFloat(shalles7);
            let shalles88 = parseFloat(shalles8);
            let aveMid =
                ((shalles44 + shalles55 + shalles66 + shalles77 + shalles88) / 16) *
                100;
            aveMid = aveMid.toFixed(1);
            return aveMid;
        },
        calculateLessonEnd(shalles9, shalles10) {
            let shalles99 = parseFloat(shalles9);
            let shalles100 = parseFloat(shalles10);
            let aveEnd = ((shalles99 + shalles100) / 6) * 100;

            return aveEnd.toFixed(1);
        },

        calculateLessonFull(
            shalles1,
            shalles2,
            shalles3,
            shalles4,
            shalles5,
            shalles6,
            shalles7,
            shalles8,
            shalles9,
            shalles10
        ) {
            let shalles11 = parseFloat(shalles1);
            let shalles22 = parseFloat(shalles2);
            let shalles33 = parseFloat(shalles3);
            let shalles44 = parseFloat(shalles4);
            let shalles55 = parseFloat(shalles5);
            let shalles66 = parseFloat(shalles6);
            let shalles77 = parseFloat(shalles7);
            let shalles88 = parseFloat(shalles8);
            let shalles99 = parseFloat(shalles9);
            let shalles100 = parseFloat(shalles10);
            let aveFull =
                ((shalles11 +
                    shalles22 +
                    shalles33 +
                    shalles44 +
                    shalles55 +
                    shalles66 +
                    shalles77 +
                    shalles88 +
                    shalles99 +
                    shalles100) /
                    30) *
                100;
            return aveFull.toFixed(1);
        },

        getLessonEvaluationDatas() {
            axios
                .post("/teacher/getAllMyLessonEvaluation", {
                    teacherId: this.teacherId,
                })
                .then((response) => {
                    this.mySchoolQuarters = response.data.mySchoolsQuarters;
                    this.schoolClasses = response.data.schoolClasses;
                    this.schoolDepartments = response.data.schoolDepartments;
                    this.schoolSubjects = response.data.schoolSubjects;

                    this.teacherEvaluations = response.data.teacherEvaluations;
                    //   console.log(this.teacherEvaluations);
                    this.changeTeacherEvaluations = [...this.teacherEvaluations];
                    this.changeTeacherEvaluations.forEach((element) => {
                        element.averageShalBegProcess = this.calculateLessonBegun(
                            element.shalles1,
                            element.shalles2,
                            element.shalles3
                        );
                        // console.log("Эхлэл" + element.averageShalBegProcess);
                        element.averageShalMidProcess = this.calculateLessonMid(
                            element.shalles4,
                            element.shalles5,
                            element.shalles6,
                            element.shalles7,
                            element.shalles8
                        );
                        // console.log("Өрнөл" + element.averageShalMidProcess);
                        element.averageShalEndProcess = this.calculateLessonEnd(
                            element.shalles9,
                            element.shalles10
                        );
                        //Ээлжит хичээлийн нийт үнэлгээний дундаж үзүүлэлт
                        element.averageShalFullProcess = this.calculateLessonFull(
                            element.shalles1,
                            element.shalles2,
                            element.shalles3,
                            element.shalles4,
                            element.shalles5,
                            element.shalles6,
                            element.shalles7,
                            element.shalles8,
                            element.shalles9,
                            element.shalles10
                        );
                    });

                    this.teacherGiveEvaluations = response.data.teacherGiveEvaluations;
                    this.changeGiveTeacherEvaluations = [...this.teacherGiveEvaluations];
                    this.changeGiveTeacherEvaluations.forEach((element) => {
                        element.averageShalBegProcess = this.calculateLessonBegun(
                            element.shalles1,
                            element.shalles2,
                            element.shalles3
                        );
                        // console.log("Эхлэл" + element.averageShalBegProcess);
                        element.averageShalMidProcess = this.calculateLessonMid(
                            element.shalles4,
                            element.shalles5,
                            element.shalles6,
                            element.shalles7,
                            element.shalles8
                        );
                        // console.log("Өрнөл" + element.averageShalMidProcess);
                        element.averageShalEndProcess = this.calculateLessonEnd(
                            element.shalles9,
                            element.shalles10
                        );
                        //Ээлжит хичээлийн нийт үнэлгээний дундаж үзүүлэлт
                        element.averageShalFullProcess = this.calculateLessonFull(
                            element.shalles1,
                            element.shalles2,
                            element.shalles3,
                            element.shalles4,
                            element.shalles5,
                            element.shalles6,
                            element.shalles7,
                            element.shalles8,
                            element.shalles9,
                            element.shalles10
                        );
                    });
                    //   console.log(this.changeTeacherEvaluations);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        viewLessonEvaluation(teacherEvaluation) {
            //   console.log("Дарагдлаа");
            this.compareTE = false;
            this.passTeachersEvaluationData = [];
            this.passTeachersEvaluationData = teacherEvaluation;
            this.$nextTick(() => {
                this.compareTE = true;
                //$nextTick супер функц нь component-ыг дахин релоад хийх боломж олгоно.
            });
            //   console.log(this.passTeachersEvaluationData);
        },
        describeLessonEvaluation(teacherEvaluation) {
            //   console.log(teacherEvaluation);
            this.form.fill(teacherEvaluation);
            $("#modal-xl-describe").modal("show");
        },

        //өөрчлөлтийг мэдрэх
        changeTab() {
            //   console.log("Таб өөрчлөгдлөө.");
            this.compareTE = false;
        },
    },
    created() {
        this.getLessonEvaluationDatas();
        Fire.$on("getLoadTeacherEvaluations", () => {
            this.getLessonEvaluationDatas();
        });
        Fire.$on("getWasEditMode", (event) => {
            this.editDescribeMode = event;
        });
    },
};
</script>

<style></style>
