<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ур чадвар(Ангилал)</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="4">
                      <b-form-input
                        id="input-2"
                        v-model="formSkillsCategory.name"
                        placeholder="Ангилалын нэр..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                        id="input-2"
                        v-model="formSkillsCategory.description"
                        placeholder="Ангилалын тайлбар..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="4">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="skillsCategoryCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="skillsCategoryUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="skillsCategoryClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Ангилалын нэр</th>
                <th>Ангилалын тайлбар</th>
                <th>Дэд ангилал</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(skillsCategory, index) in skillsCategories"
                :key="skillsCategory.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    skillsCategory.name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    skillsCategory.description
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Нэр</th>
                        <th scope="col">Тайлбар</th>
                        <th scope="col">Үүсгэсэн</th>
                        <th scope="col">Үйлдлүүд</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(skill, i) in skillsCategory.my_skills"
                        :key="skill.id"
                      >
                        <th scope="row">{{ i + 1 }}</th>

                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ skill.name }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ skill.description }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ skill.created_at | dateYearMonthDay }}</b-badge
                          >
                        </td>
                        <td>
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editSkill(skill)"
                            ><i class="fa fa-check"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteSkill(skill)"
                            ><b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editSkillsCategory(skillsCategory)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="createSkills(skillsCategory)"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="skillsCategoryDestroy(skillsCategory)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ skillsCategory.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ skillsCategory.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-skill"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Ур чадвар"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-2"
                  v-model="formSkill.name"
                  placeholder="Дэд ангилалын нэр..."
                  required
                ></b-form-input>
                <has-error :form="formSkill" field="name"></has-error>
              </div>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-3"
                  v-model="formSkill.description"
                  placeholder="Дэд ангилалын тайлбар..."
                  required
                ></b-form-input>
                <has-error :form="formSkill" field="description"></has-error>
              </div>
              <!-- <div class="form-group">
                <label>Хариуцах ажилтан сонгох</label>
                <multiselect
                  v-model="selectedEmployees"
                  :options="managers"
                  :multiple="true"
                  :custom-label="customName"
                  label="text"
                  :searchable="true"
                  :close-on-select="false"
                  :show-labels="true"
                  placeholder="Хариуцах ажилтан сонгох"
                  :allow-empty="false"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }}.{{ option.lastName }}
                    </strong></template
                  >
                </multiselect>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          type="submit"
          class="btn btn-success"
          v-show="!showEditRCL"
          @click.prevent="storeSkill()"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          v-show="showEditRCL"
          @click.prevent="updateSkills()"
        >
          Засах
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      departments: [
        { value: null, department_name: "Мэргэжлийн багш сонгоно уу?" },
      ],
      managers: [],
      selectedEmployees: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //Training Эндээс эхэллээ...

      allTrainings: [],

      //subTrainings
      showSubRiskCategoryMode: false,
      editSubTrainMode: false,
      useSubTrainofParent: "",
      allSubTrainings: [],

      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      modalTeachers: [],

      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),

      // RiskCategory эхэллээ
      skillsCategories: [],
      formSkillsCategory: new Form({
        id: "",
        name: "",
        description: "",
      }),
      showEdit: false,

      //   RiskCategoryList эхэллээ
      formSkill: new Form({
        id: "",
        sc_id: "",
        name: "",
        description: "",
      }),
      showEditRCL: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //Эрсдэлийн ангилал эхэлж байна...
    getAllSkillsCategories() {
      axios
        .get("/all_worker_functions/getAllSkillsCategories")
        .then((res) => {
          //   console.log(res);
          this.skillsCategories = res.data.skillsCategories;
          // this.managers = res.data.managers;
          //   this.allTrainings = res.data.getAllTrainings;
          //   this.filterForm.department_id = 0;
          //   this.departments = res.data.departments;
          //   this.departments.push({
          //     id: 0,
          //     department_name: "Нийт мэргэжлийн баг",
          //   });
          //   this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    skillsCategoryCreate() {
      //   console.log(this.formSkillsCategory);
      this.formSkillsCategory
        .post("/all_worker_functions/skillsCategoryCreate")
        .then((res) => {
          // console.log(res);
          Fire.$emit("getSkills");
          this.formSkillsCategory.reset();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    editSkillsCategory(skillsCategory) {
      //   console.log(skillsCategory);
      this.formSkillsCategory.fill(skillsCategory);
      this.showEdit = true;
    },
    skillsCategoryUpdate() {
      this.formSkillsCategory
        .put(
          "/all_worker_functions/skillsCategoryUpdate/" +
            this.formSkillsCategory.id
        )
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getSkills");
          this.formSkillsCategory.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    skillsCategoryDestroy(skillsCategory) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Ур чадварын ангилал устгахад, түүнд харьяалагдах бүх ур чадварууд устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              `/all_worker_functions/skillsCategoryDestroy/${skillsCategory.id}`
            )
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getSkills");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцалсан",
            "Таны модуль сургалтыг устгасангүй :)",
            "error"
          );
        }
      });
    },
    skillsCategoryClear() {
      this.formSkillsCategory.reset();
      this.showEdit = false;
    },
    //Ур чадварын дэд ангилалууд

    createSkills(skill) {
      // console.log(skill);
      //   this.getCourseStudents(train.id);
      //   this.showModalTeachers = true;
      //   this.useInfosofSubTrains = train;
      this.formSkill.sc_id = skill.id;
      this.$bvModal.show("modal-skill");
    },

    storeSkill() {
      this.formSkill
        .post("/all_worker_functions/storeSkill")
        .then((res) => {
          this.$toastr.s("Ур чадвар амжилттай нэмлээ!", "Нэмсэн");
          this.resetFormSkill();
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    resetFormSkill() {
      this.showEditRCL = false;
      Fire.$emit("getSkills");
      this.formSkill.reset();
      this.$bvModal.hide("modal-skill");
    },

    cancel() {
      this.showEditRCL = false;
      this.formSkill.reset();
      this.$bvModal.hide("modal-skill");
    },

    editSkill(skill) {
      //   console.log(skill);
      this.formSkill.fill(skill);
      this.showEditRCL = true;
      this.$bvModal.show("modal-skill");
    },
    updateSkills() {
      //   console.log("update");

      this.formSkill
        .put("/all_worker_functions/updateSkills/" + this.formSkill.id)
        .then((res) => {
          //   console.log(res);
          this.resetFormSkill();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteSkill(skill) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Ур чадвар устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/all_worker_functions/skillDestroy/${skill.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getSkills");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Ур чадвар устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Ур чадвар устгахыг цуцалсан :)", "error");
        }
      });
    },

    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customName({ last_name, name }) {
      return `${last_name[0]}. ${name}`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllSkillsCategories();
    Fire.$on("getSkills", () => {
      this.getAllSkillsCategories();
    });
    Fire.$on("getSubTrainings", () => {
      this.getSubTrainAgain();
    });
  },
};
</script>

<style></style>
