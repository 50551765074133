<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Үүрэг даалгаврууд</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="success" @click.prevent="getAllTask()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Үйлдэл</th>
                <th>Гүйцэтгэл</th>
                <th>Өгсөн үүрэг</th>
                <th>Эхлэх огноо</th>
                <th>Дуусах огноо</th>
                <th>Тайлбар (Менежер)</th>
                <th>Тайлбар (Өөрийн)</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody v-for="(task, index) in allTask" :key="task.task_id">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a href="#" class="badge bg-success" @click="showTask(task)"
                    ><i class="fa fa-eye"></i
                  ></a>
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge
                    v-if="task.guitsetgel >= 80"
                    variant="success"
                    v-show="task.guitsetgel != null"
                  >
                    {{ task.guitsetgel }}</b-badge
                  >
                  <b-badge
                    v-if="(task.guitsetgel >= 60) & (task.guitsetgel < 80)"
                    variant="primary"
                    v-show="task.guitsetgel != null"
                  >
                    {{ task.guitsetgel }}</b-badge
                  >
                  <b-badge
                    v-if="(task.guitsetgel >= 30) & (task.guitsetgel < 60)"
                    variant="warning"
                    v-show="task.guitsetgel != null"
                  >
                    {{ task.guitsetgel }}</b-badge
                  >
                  <b-badge
                    v-if="task.guitsetgel < 30"
                    variant="danger"
                    v-show="task.guitsetgel != null"
                  >
                    {{ task.guitsetgel }}</b-badge
                  >
                  <b-badge variant="warning" v-show="task.guitsetgel === null">
                    Гүйцэтгэл ороогүй.</b-badge
                  >
                  <div class="progress progress-xs progress-striped active">
                    <div
                      v-if="task.guitsetgel < 30"
                      class="progress-bar bg-danger"
                      :style="{
                        width: `${task.guitsetgel}%`,
                      }"
                    ></div>
                    <div
                      v-if="(task.guitsetgel >= 30) & (task.guitsetgel < 60)"
                      class="progress-bar bg-warning"
                      :style="{
                        width: `${task.guitsetgel}%`,
                      }"
                    ></div>
                    <div
                      v-if="(task.guitsetgel >= 60) & (task.guitsetgel < 80)"
                      class="progress-bar bg-primary"
                      :style="{
                        width: `${task.guitsetgel}%`,
                      }"
                    ></div>
                    <div
                      v-if="task.guitsetgel >= 80"
                      class="progress-bar bg-success"
                      :style="{
                        width: `${task.guitsetgel}%`,
                      }"
                    ></div>
                  </div>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">
                    <!-- {{ task.task }} -->
                    <!-- <span v-html="task.task"> </span> -->
                    <b-badge variant="light">
                      <p class="text-sm">
                        <a
                          href="#"
                          class="dropdown-item"
                          @click="showTaskView(task)"
                        >
                          <b-badge variant="light">
                            <span v-html="task.task.substring(0, 100)"> </span>
                          </b-badge>
                        </a>
                      </p>
                    </b-badge>
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ task.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ task.performance_date | dateYearMonthDay }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="white-space: pre-wrap"
                >
                  <!-- <b-badge
                                        variant="light"
                                        style="white-space: pre-wrap"
                                        >{{ task.manager_explain }}</b-badge
                                    > -->
                  <p class="text-sm">
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="showTaskView(task)"
                    >
                      <b-badge variant="light">
                        <span
                          v-html="
                            task.manager_explain
                              ? task.manager_explain.substring(0, 100)
                              : ''
                          "
                        >
                        </span>
                      </b-badge>
                    </a>
                  </p>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="white-space: pre-wrap"
                >
                  <p class="text-sm">
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="showTaskView(task)"
                    >
                      <b-badge variant="light">
                        <span
                          v-html="
                            task.teacher_explain
                              ? task.teacher_explain.substring(0, 100)
                              : ''
                          "
                        >
                        </span>
                      </b-badge>
                    </a>
                  </p>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ task.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Дундаж /0 тооцсон/
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ unelgeeAvg }}%</strong>
                </td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Дундаж /0 тооцохгүй/
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ unelgee0Avg }}%</strong>
                </td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Нийт:
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ niit }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- <div class="col-lg-3 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Үүрэг даалгавар бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Үүрэг даалгавар засах</h3>
        </div>
        <div class="card-body">
          <form v-on:change="changeTeacherList($event)" v-show="!editMode">
            <div class="form-group row">
              <div class="form-group">
                <label>Харьяалагдах мэргэжлийн баг</label>
                <b-form-select
                  v-model="filterForm.department_id"
                  :options="departments"
                  text-field="department_name"
                  value-field="id"
                ></b-form-select>
                <has-error :form="filterForm" field="department_name"></has-error>
              </div>
            </div>
          </form>
          <form>
            <div class="form-group" v-show="!editMode">
              <label>Багш, ажилтан</label>
              <span>{{ Object.keys(teachers).length }}</span>
              <b-form-select
                v-model="form.recieved_employees"
                :options="teachers"
                text-field="emp_teacher_name"
                value-field="emp_id"
                multiple
                :select-size="4"
              ></b-form-select>
              <has-error :form="form" field="emp_teacher_name"></has-error>
            </div>
            <div class="form-group">
              <label>Гүйцэтгэл тайлагнах хугацаа</label>
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.performance_date"
                today-button
                label-today-button="Өнөөдөр"
                reset-button
                label-reset-button="Шинэчлэх"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                class="mb-2"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('performance_date'),
                  },
                ]"
                placeholder="Гүйцэтгэл тайлагнах хугацаа"
              ></b-form-datepicker>
              <has-error :form="form" field="performance_date"></has-error>
            </div>
            <div class="form-group">
              <label for="evaluation">Өгсөн үүрэг, даалгавар</label>
              <validation-provider name="form.notification" rules="alpha_num">
                <template>
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="form.task"
                    :options="editorOption"
                  />
                </template>
              </validation-provider>
            </div>
            <div class="form-group" v-show="editMode">
              <label>Менежер тайлбар</label>
              <template>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="form.manager_explain"
                  :options="editorOption"
                />
              </template>
            </div>
            <div class="form-group" v-show="editMode">
              <p>Гүйцэтгэл оруулах</p>
              <input
                type="number"
                name="guitsetgel"
                max="100"
                v-model="form.guitsetgel"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('guitsetgel'),
                  },
                ]"
                placeholder="Гүйтэгэлийн оноо %-аар тавих"
              />
              <has-error :form="form" field="guitsetgel"></has-error>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editMode"
            @click.prevent="createTask()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editMode"
            @click.prevent="updateTask()"
          >
            Засах
          </button>
          <button class="btn btn-secondary" v-show="editMode" @click.prevent="cancelEdit">
            Болих
          </button>
        </div>
      </div>
    </div> -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-taskInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Үүрэг даалгавар"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              >{{ showTaskInfo.enrol_lname }}
              {{ showTaskInfo.enrol_fname }}</span
            >
            <span class="direct-chat-timestamp float-right">{{
              showTaskInfo.created_at | dateYearMonthDay
            }}</span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            v-if="showTaskInfo.profile_photo_url != null"
            :src="showTaskInfo.profile_photo_url"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <img
            v-else
            src="/images/users/user.png"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <!-- <div class="direct-chat-text primary">
                        {{ showTaskInfo.task }}
                    </div> -->
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showTaskInfo.task"> </span>
            <span
              v-if="showTaskInfo.manager_explain"
              v-html="showTaskInfo.manager_explain"
            >
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Үүрэг даалгавар авсан багш нар</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="taskInfo != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Үйлдэл</th>
                      <th>Үнэлгээ</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Багш</th>
                      <th>Тайлбар</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(taskD, index) in taskInfo"
                    :key="taskD.show_task_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          v-show="taskD.recieved_employee_id == currentUserId"
                          href="#"
                          class="badge bg-primary"
                          @click="editTask(taskD)"
                        >
                          <i class="fa fa-check"></i>
                        </a>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span
                          v-if="taskD.recieved_employee_id == currentUserId"
                        >
                          {{ taskD.guitsetgel }}
                        </span>
                        <span v-else>
                          <i class="fas fa-ban"></i>
                        </span>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ taskD.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="taskD.user">
                          <span v-if="taskD.user.employee_migration_info">
                            <span
                              v-if="
                                taskD.user.employee_migration_info
                                  .my_position_info
                              "
                            >
                              <small>
                                {{
                                  taskD.user.employee_migration_info
                                    .my_position_info.position_name
                                }}
                              </small>
                            </span>
                          </span>
                        </span>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ taskD.emp_teacher_last_name[0] }}.
                        {{ taskD.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge variant="light" style="white-space: pre-wrap">
                          <!-- {{ taskD.teacher_explain }} -->
                          <span v-html="taskD.teacher_explain"> </span>
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2" v-show="editMode">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Үүрэг даалгавар тайлбар оруулах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Тайлбар</label>

                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.teacher_explain"
                      :options="editorOption"
                    />

                    <!-- <b-form-textarea
                                            id="textarea"
                                            v-model="form.teacher_explain"
                                            placeholder="Багш, ажилчтны тайлбар"
                                            rows="3"
                                            max-rows="6"
                                            :class="[
                                                'form-control',
                                                {
                                                    'is-invalid': form.errors.has(
                                                        'teacher_explain'
                                                    )
                                                }
                                            ]"
                                        ></b-form-textarea>
                                        <has-error
                                            :form="form"
                                            field="teacher_explain"
                                        ></has-error> -->
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateTask()"
                >
                  Хадгалах
                </button>
                <button class="btn btn-secondary" @click.prevent="cancelEdit">
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelTaskInfo()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <b-modal
      size="xl"
      id="modal-view-task"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Үүрэг даалгавар"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              <img
                v-if="
                  showTaskData.profile_photo_url &&
                  showTaskData.profile_photo_url != null
                "
                :src="showTaskData.profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showTaskData.enrol_lname }}
              {{ showTaskData.enrol_fname }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>
              {{ showTaskData.created_at | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showTaskData.task"> </span>
            <span
              v-if="showTaskData.manager_explain"
              v-html="showTaskData.manager_explain"
            >
            </span>
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span> Өөрийн тайлбар: </span>
            <span
              v-if="showTaskData.teacher_explain"
              v-html="showTaskData.teacher_explain"
            >
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",
      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",
      currentUserId: null,
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allTask: [],
      teachers: [],
      taskInfo: [],
      filterForm: new Form({
        department_id: 0,
      }),
      departments: [
        { value: null, department_name: "Please select an option" },
      ],
      showTaskData: "",
      showMode: false,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,
      showTaskInfo: new Form({
        task_id: "",
        department_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employee_id: "",
        position_id: "",
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editMode: false,
      form: new Form({
        show_task_id: null,
        task_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employees: [],
        performance_date: "",
        task: "",
        manager_explain: "",
        teacher_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    changeTeacherList: function (e) {
      this.filterForm.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.filterForm
        .get("/task/getTeacherList/" + this.filterForm.department_id)
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getAllTask() {
      axios
        .post(`/task/getTeacherTask`, {
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          this.allTask = response.data.allDepartmentTask;
          this.teachers = response.data.teachers;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.currentUserId = response.data.currentUserId;
          this.averageCheck(this.allTask);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    createTask() {
      this.form
        .post("/task/createTask")
        .then((response) => {
          this.$toastr.s("Үүрэг даалгавар амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getTasks");
          this.form.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    updateTask() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/task/taskUpdate/" + this.form.task_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    averageCheck(allTask) {
      this.niit = 0;
      this.unelgeeList = [];
      this.unelgeeSum = "";
      this.unelgeeAvg = "";

      this.unelgee0List = [];
      this.unelgee0Sum = "";
      this.unelgee0Avg = "";

      allTask.forEach((element) => {
        if (element.guitsetgel == null) this.unelgeeList.push(0);
        else this.unelgeeList.push(parseInt(element.guitsetgel));
      });

      this.unelgeeSum = this.unelgeeList.reduce((a, b) => a + b, 0);
      this.unelgeeAvg = (this.unelgeeSum / this.unelgeeList.length).toFixed();
      this.niit = this.unelgeeList.length;

      //together zero
      this.unelgee0List = this.unelgeeList.filter((el) => el !== 0);
      this.unelgee0Sum = this.unelgee0List.reduce((a, b) => a + b, 0);
      this.unelgee0Avg = (
        this.unelgee0Sum / this.unelgee0List.length
      ).toFixed();
    },
    showTask(task) {
      this.action = "Үүрэг даалгаврын мэдээллийг харах...";
      axios
        .get("/task/showTask/" + task.task_id)
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.showTaskInfo.reset();
          this.showTaskInfo.fill(task);
          this.showMode = !this.showMode;
          this.$bvModal.show("modal-taskInfo");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    showTaskView(task) {
      this.showTaskData = task;
      this.$bvModal.show("modal-view-task");
    },
    editTask(task) {
      this.editMode = true;
      this.form.reset();
      this.form.fill(task);
    },
    updateTask() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      console.log(this.form);
      this.form
        .put("/task/updateTeacherExplain/" + this.form.show_task_id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.form.reset();
          this.editMode = !this.editMode;
          this.taskInfo = response.data.taskInfo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteTask(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    cancelTaskInfo() {
      this.showMode = !this.showMode;
      this.showTaskInfo.reset();
      this.$bvModal.hide("modal-taskInfo");
    },
    toggleSelect() {
      //   console.log("filter department");
    },
  },
  created() {
    this.getAllTask();
    Fire.$on("getTasks", () => {
      this.getAllTask();
    });
  },
};
</script>

<style></style>
