<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">Сарын ажлын үнэлгээ. </h3>
                <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
                <!-- Button trigger modal -->
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th>Сар</th>
                            <th v-b-popover.hover.righttop="'Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85% байна.'
                                " title="Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын гүйцэтгэл;">
                                Ш-1
                            </th>
                            <th v-b-popover.hover.righttop="'Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
                                " title="Сургалтын ээлжит үйл ажиллагааны чанар;">
                                Ш-2
                            </th>
                            <th v-b-popover.hover.righttop="'Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
                                " title="Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;">
                                Ш-3
                            </th>
                            <th v-b-popover.hover.righttop="'СА-наас үүрэг даалгавар болгож өгсөн цахим контент, технологийн карт, арга зүйн боловсруулалт зэргийн гүйцэтгэл ≥60% үзүүлэлттэй байна.'
                                " title="Сургалтын хөтөлбөр, технологийг хөгжүүлсэн байдал;">
                                Ш-4
                            </th>
                            <th v-b-popover.hover.righttop="'1-4-р шалгуур үзүүлэлтүүдийг жинлэх хувиар тооцвол.'
                                " title="Жинлэх хувь(1-4);">
                                Ш1-4
                            </th>
                            <th v-b-popover.hover.righttop="'• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас доош хэрнээ өмнөх улирлынхаа үзүүлэлтийг баталсан бол Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс; • Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас доош хэрнээ өмнөх сарынхаа үзүүлэлтийг ахиулсан бол Маш сайн (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчдын хүмүүжлийн үнэлгээний дундаж үзүүлэлт 80%-иас дээш бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн нэмэгдэл хөлс;'
                                " title="Удирдсан ангийн суралцагчдын хүмүүжилд гарсан ахиц, өөрчлөлт;"
                                v-if="myTeacherId">
                                Ш-5
                            </th>
                            <th v-b-popover.hover.righttop="'• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 60-75%-ийн гүйцэтгэлтэй хамрагдсан бол Сайн (80%) гэж үнэлээд 45’000 төгрөгийн нэмэгдэл хөлс; • Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 76-85%-ийн гүйцэтгэлтэй хамрагдсан бол Маш сайн (90%) гэж үнэлээд 90’000 төгрөгийн нэмэгдэл хөлс;• Удирдсан ангийнх нь суралцагчид хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд дунджаар 86%-иас дээш гүйцэтгэлтэй хамрагдсан бол Үлгэр жишээч (100%) гэж үнэлээд 150’000 төгрөгийн нэмэгдэл хөлс;'
                                "
                                title="Удирдсан ангийн суралцагчдын хөгжүүлэх ажил болон соёл хүмүүжлийн ажилд хамрагдсан байдалд гарсан ахиц, өөрчлөлт;"
                                v-if="myTeacherId">
                                Ш-6
                            </th>
                            <th v-b-popover.hover.righttop="'Шалгуур 5 ба шалгуур 6'" title="АУБ нэгдсэн үнэлгээ;"
                                v-if="myTeacherId">
                                Ш5-6
                            </th>
                            <th v-b-popover.hover.righttop="'Тухайн сард ажилласан цаг'" title="Илүү цаг">
                                Ш-7
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(teacherEvaluation, index) in teachersEvaluations" :key="teacherEvaluation.id">
                            <td>
                                {{ teacherEvaluation.ajliin_sar }}
                            </td>
                            <td>
                                <span class="badge badge-primary">
                                    {{ teacherEvaluation.shal1 }} *
                                    {{ teacherEvaluation.shal1_jin_huvi }} =
                                    {{
                                        calculateMyPercent(
                                            teacherEvaluation.shal1,
                                            teacherEvaluation.shal1_jin_huvi
                                        )
                                    }}
                                </span>
                            </td>
                            <td>
                                <span class="badge badge-primary">
                                    {{ teacherEvaluation.shal2 }} *
                                    {{ teacherEvaluation.shal2_jin_huvi }} =
                                    {{
                                        calculateMyPercent(
                                            teacherEvaluation.shal2,
                                            teacherEvaluation.shal2_jin_huvi
                                        )
                                    }}</span>
                            </td>
                            <td>
                                <span class="badge badge-primary">
                                    {{ teacherEvaluation.shal3 }} *
                                    {{ teacherEvaluation.shal3_jin_huvi }} =
                                    {{
                                        calculateMyPercent(
                                            teacherEvaluation.shal3,
                                            teacherEvaluation.shal3_jin_huvi
                                        )
                                    }}</span>
                            </td>
                            <td>
                                <span class="badge badge-primary">
                                    {{ teacherEvaluation.shal4 }} *
                                    {{ teacherEvaluation.shal4_jin_huvi }} =
                                    {{
                                        calculateMyPercent(
                                            teacherEvaluation.shal4,
                                            teacherEvaluation.shal4_jin_huvi
                                        )
                                    }}</span>
                            </td>
                            <td>
                                <span class="badge badge-success">
                                    {{
                                        calculateMy1_4Percent(
                                            teacherEvaluation.shal1,
                                            teacherEvaluation.shal1_jin_huvi,
                                            teacherEvaluation.shal2,
                                            teacherEvaluation.shal2_jin_huvi,
                                            teacherEvaluation.shal3,
                                            teacherEvaluation.shal3_jin_huvi,
                                            teacherEvaluation.shal4,
                                            teacherEvaluation.shal4_jin_huvi
                                        )
                                    }}
                                    %
                                </span>
                            </td>
                            <td v-if="myTeacherId">
                                <span class="badge badge-info">
                                    {{ teacherEvaluation.shal5 }} *
                                    {{ teacherEvaluation.shal5_jin_huvi }} =
                                    {{
                                        teacherEvaluation.shal5 * teacherEvaluation.shal5_jin_huvi
                                    }}</span>
                            </td>
                            <td v-if="myTeacherId">
                                <span class="badge badge-info">
                                    {{ teacherEvaluation.shal6 }} *
                                    {{ teacherEvaluation.shal6_jin_huvi }} =
                                    {{
                                        teacherEvaluation.shal6 * teacherEvaluation.shal6_jin_huvi
                                    }}</span>
                            </td>
                            <td v-if="myTeacherId">
                                <span class="badge badge-success">
                                    {{
                                        teacherEvaluation.shal5 * teacherEvaluation.shal5_jin_huvi +
                                        teacherEvaluation.shal6 * teacherEvaluation.shal6_jin_huvi
                                    }}
                                    %
                                </span>
                            </td>
                            <td>
                                <span class="badge badge-warning" v-show="teacherEvaluation.shal7 === null">0 ц</span>
                                <span class="badge badge-success" v-show="teacherEvaluation.shal7 !== null">
                                    {{ teacherEvaluation.shal7 }} ц</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
    props: ["teacherId"],
    data() {
        return {
            action: "",
            editMode: false,
            teachersEvaluations: [],
            myTeacherId: null,
        };
    },
    components: {
        Multiselect,
        // searchManager: searchManager,
    },
    computed: {
        validationNumber1() {
            return this.form.shal1 >= 0 && this.form.shal1 <= 100;
        },
        validationNumber2() {
            return this.form.shal2 >= 0 && this.form.shal2 <= 100;
        },
        validationNumber3() {
            return this.form.shal3 >= 0 && this.form.shal3 <= 100;
        },
        validationNumber4() {
            return this.form.shal4 >= 0 && this.form.shal4 <= 100;
        },
        validationNumber5() {
            return this.form.shal5 >= 0 && this.form.shal5 <= 100;
        },
    },
    methods: {
        getAllData() {
            axios
                .post("/all_worker_functions/myWorkEvaluations", {
                    teacherId: this.teacherId,
                })
                .then((response) => {
                    //   console.log(response.data);
                    this.teachersEvaluations = response.data.myWorkEvaluations;
                    this.myTeacherId = response.data.classTeacher;
                    //   this.positions = response.data.teacherPositionsName;
                })
                .catch((error) => {
                    //   console.log(error);
                });
        },
        average() {
            return (
                (parseFloat(this.form.shal1) +
                    parseFloat(this.form.shal2) +
                    parseFloat(this.form.shal3) +
                    parseFloat(this.form.shal4)) /
                4
            );
        },
        calculateMyPercent(val1, val2) {
            let val = val1 * val2;
            return val.toFixed(1);
        },
        calculateMy1_4Percent(val1, val2, val3, val4, val5, val6, val7, val8) {
            let val12 = val1 * val2;
            let val34 = val3 * val4;
            let val56 = val5 * val6;
            let val78 = val7 * val8;
            let valNiil = val12 + val34 + val56 + val78;
            return valNiil.toFixed(1);
        },
        calculatePercent() {
            let averagePer =
                (parseFloat(this.form.shal1) +
                    parseFloat(this.form.shal2) +
                    parseFloat(this.form.shal3) +
                    parseFloat(this.form.shal4)) /
                4;
            let myPercent = (averagePer * 20) / 100;
            return myPercent;
        },
        checkFormValidity() {
            //   console.log("checkFormValidity");
            const valid = this.$refs.form.checkValidity();
            this.nameState = valid;
            return valid;
        },
        resetModal() {
            //   console.log("resetModal");
        },
        handleOk(bvModalEvt) {
            //   console.log("handleOk");
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            //   console.log("handleSubmit");
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            // Push the name to submitted names
            this.submittedNames.push(this.name);
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
                this.$forceUpdate();
            });
        },
    },

    created() {
        this.getAllData();
        Fire.$on("loadSchoolTeachers", () => {
            this.getAllData();
        });
        setInterval(this.getNow, 1000);
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table {
    min-height: 300px;
}

.badge {
    font-size: 60%;
}

th {
    font-size: 60%;
}
</style>
