//Top menu component
Vue.component("topMenu", require("./components/layout/topMenu.vue").default);
Vue.component("leftMenu", require("./components/layout/leftMenu.vue").default);
Vue.component("chatComponent", require("./components/layout/chat.vue").default);
Vue.component("chatGPTComponent", require("./components/ChatGpt/ChatGpt.vue").default);
// Vue.component("test-hello", require("./components/Test.vue").default);

// Vue.component("main-hello", require("./components/Partials/Main.vue").default);

// //loading is global
// Vue.component("loading", require("./components/MyTools/Loading.vue").default);

// // Бүх ажилчид
// Vue.component(
//     "allMyWorkEvaluation",
//     require("./components/AllWorker/MyWorkEvaluation/Index.vue").default
// );

// //СУРГАЛТЫН ҮЙЛ ЯВЦ ДАХЬ МЭДЭЭ, МЭДЭЭЛЛИЙГБАРИМТЖУУЛАХ БОЛОВСРУУЛАХ, ХЯНАХ, БАТЛАХ ТУХАЙ ЖУРАМ
// Vue.component(
//     "indexRuleOfSurgalt",
//     require("./components/AllWorker/Rules/IndexRuleOfSurgalt.vue").default
// );
// //Ээлжит хичээлийн процессын зураглал
// Vue.component(
//     "indexEeljitKhicheel",
//     require("./components/AllWorker/Rules/IndexEeljitKhicheel.vue").default
// );
// //Ээлжит хөгжүүлэх процессын зураглал
// Vue.component(
//     "indexEeljitKhugjuulekh",
//     require("./components/AllWorker/Rules/IndexEeljitKhugjuulekh.vue").default
// );
// //Ээлжит СХА процессын зураглал
// Vue.component(
//     "indexEeljitSoyolKhumuujil",
//     require("./components/AllWorker/Rules/IndexEeljitSoyolKhuumjil.vue").default
// );
// //Багшийн ажлыг үнэлэх, цалин хөлс, урамшууллыг тооцож олгох журам
// Vue.component(
//     "indexRuleOfBagshiinAjil",
//     require("./components/AllWorker/Rules/IndexRuleOfBagshiinAjil.vue").default
// );

// //Стратеги төлөвлөлт
// Vue.component('indexOgsm', require('./components/AllWorker/Ogsm/IndexOgsm.vue').default);

// //Стратеги төлөвлөлт менежер
// Vue.component('managerOgsm', require('./components/AllWorker/Ogsm/ManagerOgsm.vue').default);

// //Стратеги төлөвлөлт ажилтан
// Vue.component('employeeOgsm', require('./components/AllWorker/Ogsm/EmployeeOgsm.vue').default);

// //Зөвлөх
// Vue.component("consultantHome", require("./components/Consultant/index.vue").default);

// //Бие бялдар
// Vue.component("physicalMeasure", require("./components/Physical/index.vue").default);
// Vue.component("physicalStudents", require("./components/Physical/physicalStudents.vue").default);
