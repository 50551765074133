<template>
    <div class="card" v-if="dist">
        <div class="card-header">
            <h5 class="card-title"><b>Ангийн суралцагчдийн дэлгэрэнгүй мэдээлэл</b></h5>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="info-box bg-light">
                                <div class="info-box-content">
                                    <span>
                                        <strong class="info-box-text mt-4 text-center">
                                            Суралцагчдын хаяг байршил</strong>
                                        <apexchart type="donut" height="350" class="pieChart"
                                            :options="optionsDistClassP" :series="seriesDistClassP"></apexchart>
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="info-box bg-light">
                                <div class="info-box-content">
                                    <span>
                                        <strong class="info-box-text mt-4 text-center">
                                            Суралцагчдын хүйсийн харьцаа</strong>
                                        <apexchart type="donut" height="350" class="pieChart"
                                            :options="optionsGenderClassP" :series="seriesGenderClassP"></apexchart>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="info-box bg-light">
                                <div class="info-box-content">
                                    <span>
                                        <strong class="info-box-text mt-4 text-center">
                                            Суралцагчдын иргэншил</strong>
                                        <apexchart type="donut" height="350" class="pieChart"
                                            :options="optionsCitizenshipClassP" :series="seriesCitizenshipClassP">
                                        </apexchart>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
        </div>
    </div>
    <!--/. container-fluid -->
    <!-- Цэс-->
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    props: ["teacherId"],
    data() {
        return {
            iconMode: "fa fa-bars",
            btnColor: "warning",
            dist: [],

            //Суралцагчдын хаяг байршлаар харах
            seriesDistClassP: [],
            optionsDistClassP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            //Суралцагчдын хүйсийн харьцаа харах
            seriesGenderClassP: [],
            optionsGenderClassP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            //Суралцагчдын иргэншил харьцаа харах
            seriesCitizenshipClassP: [],
            optionsCitizenshipClassP: {
                labels: [],
                chart: {
                    type: "donut",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: { show: true },
                                value: { show: true },
                                total: {
                                    show: true,
                                    label: "Нийт",
                                },
                            },
                        },
                    },
                },
            },
            //   Дүүрэг шалгах
            aimagOptions: [],
            sumOptions: [],
            checkStudents: [],
            distName: [],
        };
    },
    components: {
        Multiselect,
    },
    computed: {
        years: function () {
            return this.$store.state.years;
        },
    },
    methods: {
        // Дүүрэг шалгах
        getSum() {
            axios
                .get("/allDistrict/" + 11)
                .then((response) => {
                    this.sumOptions = response.data.allDistrict;
                })
                .catch((errors) => {
                    //   console.log(errors);
                });
        },
        switchMenu() {
            if (this.iconMode != "fa fa-bars") {
                this.iconMode = "fa fa-bars";
                this.btnColor = "warning";
            } else {
                this.iconMode = "fa fa-times";
                this.btnColor = "danger";
            }
        },
        customYearName({ start_date, finish_date }) {
            return `${start_date} - ${finish_date} - оны хичээлийн жил`;
        },
        getDist() {
            axios
                .post("/teacher/myClassDist", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.dist = res.data.distStud;
                    res.data.distStud.forEach((el) => {
                        this.seriesDistClassP.push(el.total);
                        this.optionsDistClassP.labels.push(el.address_name);
                    });
                    res.data.gender.forEach((el) => {
                        this.seriesGenderClassP.push(el.total);
                        this.optionsGenderClassP.labels.push(el.student_gender);
                    });
                    res.data.citizenship.forEach((el) => {
                        this.seriesCitizenshipClassP.push(el.total);
                        this.optionsCitizenshipClassP.labels.push(el.student_citizenship);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    beforeMount() { },
    created() {
        this.getSum();
        this.getDist();
    },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
    font-size: 70%;
}

.pieChart {
    height: 330px;
}

.barChart {
    height: 340px;
}
</style>
