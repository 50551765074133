<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Хоолны ирц</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <b-form-datepicker
                                id="day-picker"
                                v-model="pickDate"
                                v-bind="labels[locale] || {}"
                                :locale="locale"
                                :start-weekday="weekday"
                                :show-decade-nav="showDecadeNav"
                                :hide-header="hideHeader"
                                size="sm"
                                class="mb-2"
                                ></b-form-datepicker>
                        </div>
                        <div class="col-md-3">
                            <button class="btn bg-gradient-primary btn-sm" @click.prevent="getClassStudents">Хайх</button>
                        </div>
                    </div>
                </div>
            </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Нэр</th>
                <th>Хоол идэх эсэх</th>
                <th>Огноо</th>
              </tr>
            </thead>
            <tbody v-for="(student, index) in students"
                :key="student.profiles_id">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ student.student_last_name[0] }}.
                  {{ student.student_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                    <b-form-checkbox  :checked="`${getLunchBool(student.is_lunch)}`" :name="student.user_id" switch size="lg" @change="changeLunch(student.id)"/>
                </td>
                <td>
                    {{ student.dateFormated }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      isCheck: true,
      students: [],
      pickDate: '',
      locale: 'mn',
      showDecadeNav: true,
      hideHeader: false,
      weekday: 1,
      labels: {
          mn: {
            labelPrevDecade: 'Өмнөх арван жил',
            labelPrevYear: 'Өмнөх жил',
            labelPrevMonth: 'Өнгөрсөн сар',
            labelCurrentMonth: 'Одоогийн сар',
            labelNextMonth: 'Дараа сар',
            labelNextYear: 'Ирэх жил',
            labelNextDecade: 'Дараагийн арван жил',
            labelToday: 'Өнөөдөр',
            labelSelected: 'Сонгосон огноо',
            labelNoDateSelected: 'Огноо сонгогдоогүй байна',
            labelCalendar: 'Хуанли',
            labelNav: 'Календарийн навигаци',
            labelHelp: 'Хуанлийн огноог удирдахын тулд курсорын товчлуурыг ашиглана уу.'
          }
        }
    };
  },

  methods: {
    changeLunch(lunchId) {
        this.action = "Суралцагчын мэдээллийг шинэчилж байна...";
      axios
        .put("/teacher/updateLunch/" + lunchId)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getLunchBool(value) {
        console.log(value);
        if(value==1)
            return this.isCheck
        else if(value==0)
            return false
    },
    getClassStudents() {
      axios
        .get("/teacher/getLunchInfo/"+ this.pickDate)
        .then((response) => {
          this.students = response.data.students;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    //this.getClassStudents();
  },
};
</script>

<style></style>
