<template>
  <div class="col-12 mt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <multiselect
        class="mr-2"
        v-model="school_class_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="school_class_id"
        label="school_class_id"
        :custom-label="customLabelSchoolClassName"
        placeholder="Анги сонгоно уу?"
        :options="mySchoolClasses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.full_name }} - анги</strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="quarter_id"
        :custom-label="customLabelQuarterName"
        placeholder="Улирал сонгоно уу?"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning mr-2"
        @click.prevent="getMySchoolGradesDatas()"
      >
        Нэгтгэл
      </button>
      <button
        type="button"
        class="btn btn-success"
        @click.prevent="getMySchoolGradesYearDatas()"
      >
        Жилээр
      </button>
    </nav>
    <index-grade-concate-child
      v-if="allSchoolClassCourses.length > 0"
      :allSchoolClassCourses="allSchoolClassCourses"
      :allStudentsGrades="allStudentsGrade"
      :allStudents="allStudents"
    ></index-grade-concate-child>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import IndexGradeConcateChild from "./IndexGradeConcateChild.vue";
export default {
  components: {
    Multiselect,
    IndexGradeConcateChild,
  },
  data() {
    return {
      school_class_id: "",
      quarter_id: "",
      mySchoolClasses: [],
      allStudentsGrade: [],
      allSchoolClassCourses: [],
      allStudents: [],
      mySchoolQuarters: [],
    };
  },
  watch: {
    // school_class_id: function (newValue, oldValue) {
    //   axios
    //     .post("/manager/allStudentGrade", {
    //       schoolClass_id: newValue,
    //     })
    //     .then((response) => {
    //       console.log("Бүх ангийн дуудлаа...");
    //       this.allStudentsGrade = [];
    //       this.allStudentsGrade = response.data.allStudentsGrade; //Сонгогдсон ангийн бүх дүнг сурагчдаар нь авч нэгтгэж харуулна.
    //       this.allSchoolClassCourses = response.data.allSchoolClassCourse;
    //       this.allStudents = response.data.allStudents;
    //     })
    //     .catch((errors) => {
    //       //   console.log(errors);
    //     });
    // },
  },
  methods: {
    getMySchoolClass() {
      axios
        .get("/manager/mySchoolClasses")
        .then((response) => {
          //   console.log(response.data);
          this.mySchoolClasses = [];
          this.mySchoolQuarters = [];
          this.quarter_id = response.data.currentQuarter;
          this.mySchoolClasses = response.data.allSchoolClasses;
          this.mySchoolQuarters = response.data.mySchoolQuarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getMySchoolGradesDatas() {
      axios
        .post("/manager/getMySchoolGradesDatasFilQuar", {
          school_class_id: this.school_class_id,
          quarter_id: this.quarter_id,
        })
        .then((response) => {
          let timerInterval;
          Swal.fire({
            title: "Мэдээлэл татаж байна!",
            html: "Мэдээлэл дуудахад зарцуулсан хугацаа <b></b> миллисекунд.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              //   console.log("I was closed by the timer");
              this.allStudentsGrade = [];
              this.allStudentsGrade = response.data.allStudentsGrade; //Сонгогдсон ангийн бүх дүнг сурагчдаар нь авч нэгтгэж харуулна.
              this.allSchoolClassCourses = response.data.allSchoolClassCourse;
              this.allStudents = response.data.allStudents;
            }
          });
        })
        .catch((errors) => {
          Swal.fire({
            title: "Өгөгдөл дутуу байна!",
            text: "Анги, улирлын сонголтоо дахин нягтлаад, мэдээллээ дуудна уу!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "За, ойлголоо!",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                "Сонголт!",
                "Дэлгэцийн дээд хэсэгт байгаа сонголтуудыг бүрэн хийх.",
                "success"
              );
            }
          });
        });
    },

    getMySchoolGradesYearDatas() {
      axios
        .post("/manager/allStudentGrade", {
          schoolClass_id: this.school_class_id,
        })
        .then((response) => {
          //   console.log("Бүх ангийн дуудлаа...");
          this.allStudentsGrade = [];
          this.allStudentsGrade = response.data.allStudentsGrade; //Сонгогдсон ангийн бүх дүнг сурагчдаар нь авч нэгтгэж харуулна.
          this.allSchoolClassCourses = response.data.allSchoolClassCourse;
          this.allStudents = response.data.allStudents;
        })
        .catch((errors) => {
          //   console.log(errors);
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              title: "Хөтөлбөр хариуцсан менежер та анги сонгоно уу?",
              text: "Ангийн нэгдсэн үнэлгээг харахын тулд заавал анги сонгохыг анхаарна уу!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ойлголоо, анги сонгох!",
              cancelButtonText: "Үгүй, цуцлах!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                swalWithBootstrapButtons.fire(
                  "Анги сонголт!",
                  "Дэлгэцийн зүүн дээд талын эхний цэс.",
                  "success"
                );
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                  "Цуцлагдсан",
                  "Анг сонгоогүй байна. Алдаа гарлаа :)",
                  "error"
                );
              }
            });
        });
    },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelQuarterName({ quarter_name }) {
      return `${quarter_name} - улирал `;
    },
    customLabelSchoolClassName({ class_number, class_level, class_room }) {
      return `${class_number}${class_level} - Анги : ${class_room} тоот `;
    },
  },
  created() {
    this.getMySchoolClass();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
