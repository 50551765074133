<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5><b>Сарын төлөвлөгөө болон үүрэг даалгавар </b></h5>
                                <b-overlay :show="isLoad" rounded="sm">
                                    <table class="table table-bordered text-center">
                                        <tbody>
                                            <tr role="row">
                                                <th class="text-wrap">Сар</th>
                                                <th class="text-wrap">Хэрэгжүүлсэн сарын төлөвлөгөөний тоо</th>
                                                <th class="text-wrap">Гүйцэтгэлийн дундаж</th>
                                                <th class="text-wrap">Төлөвлөгөөтэй холбоотой үүрэг даалгаварын тоо</th>
                                                <th class="text-wrap">Төлөвлөгөөтэй холбоотой үүрэг даалгаварын
                                                    гүйцэтгэлийн дундаж</th>
                                                <th class="text-wrap">Төлөвлөгөөтэй холбоогүй үүрэг даалгаварын тоо</th>
                                                <th class="text-wrap">Төлөвлөгөөтэй холбоогүй үүрэг даалгаварын
                                                    гүйцэтгэлийн дундаж</th>
                                                <th class="text-wrap">Дундаж үнэлгээ</th>
                                            </tr>
                                            <tr v-for="monthPlan in monthPlans" :key="monthPlan.month_number">
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ monthPlan.month_number }} -р сар
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ monthPlan.count }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0"
                                                    v-if="monthPlan.point == null">
                                                    Үнэлгээ хийгдээгүй
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0" v-else>
                                                    {{ monthPlan.point }} %
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ monthPlan.tasks_count }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0"
                                                    v-if="monthPlan && monthPlan.tasks_avg_guitsetgel">
                                                    {{ parseFloat(monthPlan.tasks_avg_guitsetgel).toFixed(2) }} %
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0" v-else>
                                                    Үнэлгээ хийгдээгүй
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0">
                                                    {{ monthPlan.task_count }}
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0"
                                                    v-if="monthPlan && monthPlan.task_guitsetgel">
                                                    {{ monthPlan.task_guitsetgel }} %
                                                </td>
                                                <td class="dtr-control sorting_1" tabindex="0" v-else>
                                                    Үнэлгээ хийгдээгүй
                                                </td>
                                                <th class="dtr-control sorting_1" tabindex="0">
                                                    {{ monthPlan.guitsetgel_avg.toFixed(2) }} %
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Дундаж үзүүлэлт</th>
                                                <th>{{ monthPlanAvg[0] }}</th>
                                                <th>{{ monthPlanAvg[1] }} %</th>
                                                <th>{{ monthPlanAvg[2] }}</th>
                                                <th>{{ monthPlanAvg[3] }} %</th>
                                                <th>{{ monthPlanAvg[4] }}</th>
                                                <th>{{ monthPlanAvg[5] }} %</th>
                                                <th>
                                                    {{ monthPlanAvg[6] }} %
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            monthPlans: [],
            isLoad: true,
            monthPlanAvg: [],
        };
    },
    methods: {
        monthPlanStat() {
            this.isLoad = true;
            axios
                .post("/teacher/monthPlanStat", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.monthPlanAvg = [];
                    this.monthPlans = res.data.monthPlanStat;

                    let sumMonthPlanCount = 0;
                    let monthPlanCountlen = 0;
                    let sumMonthPlanPoint = 0;
                    let monthPlanPointLen = 0;
                    let sumMonthPlanTaskGuitsetgel = 0;
                    let monthPlanTaskGuitsetgelLen = 0;
                    let sumTaskCount = 0;
                    let taskCountLen = 0;
                    let sumTaskGuitsetgel = 0;
                    let taskGuitsetgelLen = 0;
                    let sumMonthPlanTaskCount = 0;
                    let monthPlanTaskCountLen = 0;
                    this.monthPlans.forEach((element) => {
                        let point = 0;
                        let guitsetgel = 0;
                        let task_guitsetgel = 0;
                        let myLen = 0;
                        if (element.count) {
                            sumMonthPlanCount += parseFloat(element.count);
                            monthPlanCountlen++;
                        }
                        if (element.point) {
                            sumMonthPlanPoint += parseFloat(element.point);
                            point += parseFloat(element.point);
                            myLen++;
                            monthPlanPointLen++;
                        }
                        if (element.tasks_count) {
                            sumMonthPlanTaskCount += parseFloat(element.tasks_count);
                            monthPlanTaskCountLen++;
                        }
                        if (element.tasks_avg_guitsetgel) {
                            sumMonthPlanTaskGuitsetgel += parseFloat(element.tasks_avg_guitsetgel);
                            guitsetgel += parseFloat(element.tasks_avg_guitsetgel);
                            monthPlanTaskGuitsetgelLen++;
                            myLen++;
                        }
                        if (element.task_count) {
                            sumTaskCount += parseFloat(element.task_count);
                            taskCountLen++;
                        }
                        if (element.task_guitsetgel) {
                            sumTaskGuitsetgel += parseFloat(element.task_guitsetgel);
                            task_guitsetgel += parseFloat(element.task_guitsetgel);
                            taskGuitsetgelLen++;
                            myLen++;
                        }
                        element.guitsetgel_avg = (point + guitsetgel + task_guitsetgel) / myLen;
                    });
                    const monthPlan = sumMonthPlanCount;
                    const point = (sumMonthPlanPoint / monthPlanPointLen).toFixed(2);
                    const mTaskCount = sumMonthPlanTaskCount;
                    const mTaskGuitsetgel = (sumMonthPlanTaskGuitsetgel / monthPlanTaskGuitsetgelLen).toFixed(2);
                    const taskCountAvg = sumTaskCount;
                    const taskGuitsetgelAvg = (sumTaskGuitsetgel / taskGuitsetgelLen).toFixed(2);
                    let sumOfAvg = 0;
                    let countOfAvg = 0;
                    if (monthPlanPointLen > 0) countOfAvg++;
                    if (monthPlanTaskGuitsetgelLen > 0) countOfAvg++;
                    if (taskGuitsetgelLen > 0) countOfAvg++;
                    sumOfAvg = (parseFloat(point) + parseFloat(mTaskGuitsetgel) + parseFloat(taskGuitsetgelAvg)) / countOfAvg;
                    this.monthPlanAvg.push(monthPlan, point, mTaskCount, mTaskGuitsetgel, taskCountAvg, taskGuitsetgelAvg, sumOfAvg.toFixed(2));
                    this.isLoad = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoad = false;
                });
        },
    },
    created() {
        this.monthPlanStat();
    },
};
</script>
