<template>
  <div class="row">
    <div class="col-md-6">
      <h6>Тухайн сонгосон ээлжит хичээлийн үнэлгээний график</h6>
      <apexchart :options="options" :series="series"></apexchart>
    </div>
    <div class="col-md-6">
      <h6>
        Харьяалагдах МБ-тай багшийн нийт үнэлэгдсэн хичээлийн дундаж үнэлгээг
        харьцуулсан график
      </h6>
      <apexchart
        :options="optionsDepartment"
        :series="seriesDepartment"
      ></apexchart>
    </div>
    <br />
    <!-- <div class="col-md-12">
      <b-row>
        <b-col sm="5">
          <multiselect
            v-model="selectedManager"
            :options="schoolEmployees"
            :multiple="true"
            track-by="employee_user_id"
            label="employee_teacher_name"
            :custom-label="customEmployeeName"
            :searchable="true"
            :close-on-select="false"
            :show-labels="false"
            placeholder="Ажиглалт хийсэн багш, удирдлага"
            :allow-empty="true"
            deselect-label="Can't remove this value"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <strong>
                {{
                  capitalizeAndEkhniiVseg(option.employee_teacher_last_name)
                }}.{{ option.employee_teacher_name }}</strong
              >
              <strong>( {{ option.department_code }})</strong>
              <strong> сонгогдлоо</strong></template
            >
          </multiselect>
        </b-col>
        <b-col sm="5">
          <multiselect
            v-model="selectedTeacher"
            :options="schoolEmployees"
            :multiple="true"
            track-by="employee_user_id"
            label="employee_teacher_name"
            :custom-label="customEmployeeName"
            :searchable="true"
            :close-on-select="false"
            :show-labels="false"
            placeholder="Үнэлгээг харьцуулах багш"
            :allow-empty="true"
            deselect-label="Can't remove this value"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <strong>
                {{
                  capitalizeAndEkhniiVseg(option.employee_teacher_last_name)
                }}.{{ option.employee_teacher_name }}</strong
              >
              <strong>( {{ option.department_code }})</strong>
              <strong> сонгогдлоо</strong></template
            >
          </multiselect>
        </b-col>
        <b-col sm="2">
          <b-button
            variant="outline-warning"
            @click.prevent="getCompareEvaluationDataPlus()"
            >Үнэлгээ татах</b-button
          >
        </b-col>
      </b-row>
    </div> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["passTeachersEvaluationData", "schoolEmployees"],
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
          type: "radar",
          width: "100%",
        },
        xaxis: {
          categories: [
            "LB1",
            "LB2",
            "LB3",
            "LM4",
            "LM5",
            "LM6",
            "LM7",
            "LM8",
            "LE9",
            "LE10",
          ],
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
        {
          name: "Бүрэн",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
      ],
      optionsDepartment: {
        chart: {
          id: "vuechart-example1",
          type: "radar",
          width: "100%",
        },
        markers: {
          size: 5,
          hover: {
            size: 10,
          },
        },
        xaxis: {
          categories: [
            "LB1",
            "LB2",
            "LB3",
            "LM4",
            "LM5",
            "LM6",
            "LM7",
            "LM8",
            "LE9",
            "LE10",
          ],
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
      },
      seriesDepartment: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "Бүрэн",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
      ],

      //Өөрчлөлт
      selectedManager: [],
      selectedTeacher: [],
    };
  },
  components: { Multiselect },
  methods: {
    //props-оор орж ирсэн сонгогдсон хичээлийн үнэлгээг графикт харуулах.
    selectedteachersData() {
      this.series[0].name =
        this.passTeachersEvaluationData.employees_teacher_name;
      this.series[0].data[0] = (
        (this.passTeachersEvaluationData.shalles1 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[1] = (
        (this.passTeachersEvaluationData.shalles2 / 2) *
        100
      ).toFixed(1);
      this.series[0].data[2] = (
        (this.passTeachersEvaluationData.shalles3 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[3] = (
        (this.passTeachersEvaluationData.shalles4 / 4) *
        100
      ).toFixed(1);
      this.series[0].data[4] = (
        (this.passTeachersEvaluationData.shalles5 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[5] = (
        (this.passTeachersEvaluationData.shalles6 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[6] = (
        (this.passTeachersEvaluationData.shalles7 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[7] = (
        (this.passTeachersEvaluationData.shalles8 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[8] = (
        (this.passTeachersEvaluationData.shalles9 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[9] = (
        (this.passTeachersEvaluationData.shalles10 / 3) *
        100
      ).toFixed(1);
    },
    selectedDepartmentteachersData(departmentNegtgelteacherLesEval) {
      //   console.log(departmentNegtgelteacherLesEval[0].department_code);
      this.seriesDepartment[0].name =
        departmentNegtgelteacherLesEval[0].department_code;
      this.seriesDepartment[0].data[0] = (
        (departmentNegtgelteacherLesEval[0].shalles1 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[1] = (
        (departmentNegtgelteacherLesEval[0].shalles2 / 2) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[2] = (
        (departmentNegtgelteacherLesEval[0].shalles3 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[3] = (
        (departmentNegtgelteacherLesEval[0].shalles4 / 4) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[4] = (
        (departmentNegtgelteacherLesEval[0].shalles5 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[5] = (
        (departmentNegtgelteacherLesEval[0].shalles6 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[6] = (
        (departmentNegtgelteacherLesEval[0].shalles7 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[7] = (
        (departmentNegtgelteacherLesEval[0].shalles8 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[8] = (
        (departmentNegtgelteacherLesEval[0].shalles9 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[9] = (
        (departmentNegtgelteacherLesEval[0].shalles10 / 3) *
        100
      ).toFixed(1);
    },
    selfTeachersData(seflNegtgelteacherLesEval) {
      //   console.log(departmentNegtgelteacherLesEval[0].department_code);
      this.seriesDepartment[1].name = seflNegtgelteacherLesEval[0].teacher_name;
      this.seriesDepartment[1].data[0] = (
        (seflNegtgelteacherLesEval[0].shalles1 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[1] = (
        (seflNegtgelteacherLesEval[0].shalles2 / 2) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[2] = (
        (seflNegtgelteacherLesEval[0].shalles3 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[3] = (
        (seflNegtgelteacherLesEval[0].shalles4 / 4) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[4] = (
        (seflNegtgelteacherLesEval[0].shalles5 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[5] = (
        (seflNegtgelteacherLesEval[0].shalles6 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[6] = (
        (seflNegtgelteacherLesEval[0].shalles7 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[7] = (
        (seflNegtgelteacherLesEval[0].shalles8 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[8] = (
        (seflNegtgelteacherLesEval[0].shalles9 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[9] = (
        (seflNegtgelteacherLesEval[0].shalles10 / 3) *
        100
      ).toFixed(1);
    },
    getCompareEvaluationData() {
      //   console.log(this.series[0].data[0]);
      //   console.log(this.passTeachersEvaluationData);
      axios
        .get("/manager/getLesEvaData", {
          params: {
            department_id: this.passTeachersEvaluationData.department_id,
            teacher_id: this.passTeachersEvaluationData.employees_teacher_id,
            evaluation_worker_id:
              this.passTeachersEvaluationData.evaluation_worker_id,
          },
        })
        .then((response) => {
          this.departmentNegtgelTeacherLesEvalNum =
            response.data.departmentNegtgelteacherLesEval;
          this.selectedDepartmentteachersData(
            response.data.departmentNegtgelteacherLesEval
          );
          this.selfTeachersData(response.data.mySelfNegtgelteacherLesEval);
          //   console.log(response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getCompareEvaluationDataPlus() {
      //   console.log(this.series[0].data[0]);
      //   console.log(this.passTeachersEvaluationData);
      axios
        .post("/manager/getLesEvaDataPlus", {
          selectedManager: this.selectedManager,
          selectedTeacher: this.selectedTeacher,
        })
        .then((response) => {
          this.departmentNegtgelTeacherLesEvalNum =
            response.data.departmentNegtgelteacherLesEval;
          this.selectedDepartmentteachersData(
            response.data.departmentNegtgelteacherLesEval
          );
          this.selfTeachersData(response.data.mySelfNegtgelteacherLesEval);
          //   console.log(response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customEmployeeName({
      employee_teacher_last_name,
      employee_teacher_name,
      department_code,
    }) {
      return `${employee_teacher_last_name}. ${employee_teacher_name} (${department_code})`;
    },
    //  <strong>
    //               {{
    //                 capitalizeAndEkhniiVseg(option.employee_teacher_last_name)
    //               }}.{{ option.employee_teacher_name }}</strong
    //             >
  },
  created() {
    // console.log(this.passTeachersEvaluationData, "Харьцуулах");
    this.getCompareEvaluationData();
    this.selectedteachersData();
  },
};
</script>

<style></style>
