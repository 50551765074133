<template>
  <div class="row pt-3">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between mt-2">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedType"
                    :options="typeOptions"
                    v-on:change="getAllteachers"
                    text-field="text"
                    size="sm"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedType'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <!-- <div
            class="main-header navbar navbar-expand navbar-white navbar-light"
          >
            <h3 class="card-title">Сургуулийн багш нар</h3>
            <search-teacher
              @getWasSearchTeachers="schoolteachers = $event"
            ></search-teacher>
          </div> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Ургийн овог</th>
                <th>Овог Нэр, Имэйл, Утас</th>
                <!-- <th></th> -->
                <th>Профайл</th>
                <!-- <th>Имэйл</th> -->
                <th>
                  БНБ / <br />
                  Ажлын байр
                </th>
                <th>БНБ ахлагч</th>
                <th>Үүсгэсэн</th>
                <!-- <th>Зассан</th> -->
                <th>Үйлдлүүд</th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="odd"
                v-for="(schoolteacher, index) in schoolteachers"
                :key="schoolteacher.user_id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolteacher.teacher_urgiin_ovog }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="info">
                    {{ schoolteacher.teacher_last_name }}
                    {{ schoolteacher.teacher_name }}
                  </b-badge>
                  <br />
                  <b-badge variant="warning">
                    Имэйл: {{ schoolteacher.email }}
                    <br />
                    Утас: {{ schoolteacher.teacher_phoneNumber1 }}
                  </b-badge>
                  <br />
                  <b-badge variant="danger" v-if="schoolteacher.work_out === 2">
                    Жирэмсний амралттай
                  </b-badge>
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td> -->
                <td class="dtr-control sorting_1" tabindex="0">
                  <img
                    @click="showImage(schoolteacher)"
                    alt="Avatar"
                    class="table-avatar img-circle elevation-2"
                    :src="
                      schoolteacher.profile_photo_url
                        ? schoolteacher.profile_photo_url
                        : '/images/users/user.png'
                    "
                  />
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0">

                </td> -->
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="info">
                    {{ schoolteacher.department_code }}/
                    {{ schoolteacher.position_name }}
                    <br />
                    Ажилласан жил:
                    <b-badge
                      v-if="schoolteacher.teacher_tukhain_bai_ajiljil !== null"
                      variant="warning"
                      style="font-size: 110%"
                    >
                      {{ schoolteacher.teacher_tukhain_bai_ajiljil }}
                    </b-badge>
                    <b-badge variant="danger" style="font-size: 80%" v-else>
                      Бүртгээгүй.
                    </b-badge>
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-show="schoolteacher.bnb === 1"
                    class="badge bg-success"
                  >
                    <i class="fas fa-check-double"></i>
                  </span>
                  <span
                    v-show="
                      schoolteacher.bnb === null || schoolteacher.bnb === 0
                    "
                    class="badge bg-warning"
                  >
                    <i class="fas fa-times-circle"></i>
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolteacher.created_at | dateYearMonthDay }}
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolteacher.updated_at | dateYearMonthDay }}
                </td> -->
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolTeacher(schoolteacher)"
                  >
                    <i class="fas fa-key"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editschoolTeacher(schoolteacher)"
                    ><i class="fas fa-user-edit"></i
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="deleteBeforeEmployeePosition(schoolteacher)"
                    ><i class="fas fa-times-circle"></i
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4" v-if="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Багш бүртгэх</h3>
          <h3 class="card-title" v-show="editMode">Багш засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode ? createSchoolTeacher() : updateSchoolTeacher()
            "
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Регистер</label>
                    <input
                      type="teacher_reg"
                      class="form-control"
                      name="teacher_reg"
                      v-model="form.teacher_reg"
                      placeholder="Регистр"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ургийн овог</label>
                    <input
                      type="text"
                      name="teacher_urgiin_ovog"
                      v-model="form.teacher_urgiin_ovog"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_urgiin_ovog'),
                        },
                      ]"
                      placeholder="Ургийн овог"
                    />
                    <has-error
                      :form="form"
                      field="teacher_urgiin_ovog"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Овог</label>
                    <input
                      type="text"
                      name="teacher_last_name"
                      v-model="form.teacher_last_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_last_name'),
                        },
                      ]"
                      placeholder="Овог"
                    />
                    <has-error
                      :form="form"
                      field="teacher_last_name"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="form.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                      placeholder="Нэр"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Хүйс</label>
                    <b-form-select
                      v-model="form.gender"
                      :options="genders"
                    ></b-form-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Төрсөн он сар өдөр</label>
                    <input
                      type="date"
                      class="form-control"
                      name="teacher_birthDate"
                      v-model="form.teacher_birthDate"
                      placeholder="Төрсөн он сар өдөр"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Имэйл</label>
                    <input
                      type="email"
                      name="email"
                      v-model="form.email"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('email'),
                        },
                      ]"
                      placeholder="Цахим шуудан"
                    />
                    <has-error :form="form" field="email"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Хэрэглэгчийн дүр</label>
                    <b-form-select
                      v-model="form.role_name"
                      :options="roleList"
                      text-field="label"
                      value-field="name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('role_name'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Харьяалагдах мэргэжлийн баг</label>
                    <b-form-select
                      v-model="form.dep_id"
                      :options="deparments"
                      text-field="department_name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('dep_id'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="department"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Харьяалагдах ажлын байр</label>
                    <b-form-select
                      v-model="form.pos_id"
                      :options="positions"
                      text-field="position_name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('pos_id'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="position"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-bnb"
                      v-model="form.bnb"
                      name="checkbox-bnb"
                      value="1"
                      unchecked-value="0"
                    >
                      БНБ ахлах эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.bnb"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-erd-zer"
                      v-model="form.teacher_erd_zer"
                      name="checkbox-erd-zer"
                      value="1"
                      unchecked-value="0"
                    >
                      PhD эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_erd_zer"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="checkbox-sport-master"
                      v-model="form.teacher_sport_mas"
                      name="checkbox-sport-master"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Спортын мастер эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_sport_mas"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="married"
                      v-model="form.married"
                      name="married"
                      value="1"
                      unchecked-value="0"
                    >
                      Гэрлэсэн эсэх
                    </b-form-checkbox>
                    <has-error :form="form" field="form.married"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Мэргэжлийн зэрэг</label>
                    <b-form-select
                      v-model="form.teacher_bol_zer"
                      :options="options"
                      size="sm"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_bol_zer'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_bol_zer"
                    ></has-error>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <b-form-checkbox
                  id="tetgever_togtooson"
                  v-model="form.tetgever_togtooson"
                  name="tetgever_togtooson"
                  value="Тийм"
                  unchecked-value="Үгүй"
                >
                  Тэтгэвэр тогтоосон эсэх
                </b-form-checkbox>
                <has-error
                  :form="form"
                  field="form.tetgever_togtooson"
                ></has-error>
              </div>
              <div class="row" v-if="form.tetgever_togtooson == 'Тийм'">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тэтгэвэр тогтоосон огноо</label>
                    <input
                      type="date"
                      name="tetgever_togtooson_ognoo"
                      v-model="form.tetgever_togtooson_ognoo"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'tetgever_togtooson_ognoo'
                          ),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон огноо"
                    />
                    <has-error
                      :form="form"
                      field="tetgever_togtooson_ognoo"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тэтгэвэр тогтоосон нас</label>
                    <input
                      type="number"
                      name="tetgever_togtooson_age"
                      v-model="form.tetgever_togtooson_age"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'tetgever_togtooson_age'
                          ),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон нас"
                    />
                    <has-error
                      :form="form"
                      field="tetgever_togtooson_age"
                    ></has-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Ажлын төрөл</label>
                    <b-form-select
                      v-model="form.work_type"
                      :options="workTypes"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_type'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="work_type"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажилд орсон огноо</label>
                    <input
                      type="date"
                      name="work_start_date"
                      v-model="form.work_start_date"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_start_date'),
                        },
                      ]"
                      placeholder="Ажилд орсон огноо"
                    />
                    <has-error :form="form" field="work_start_date"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажлаас гарсан огноо</label>
                    <input
                      type="date"
                      name="work_finish_date"
                      v-model="form.work_finish_date"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('work_finish_date'),
                        },
                      ]"
                      placeholder="Ажлаас гарсан огноо"
                    />
                    <has-error
                      :form="form"
                      field="work_finish_date"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>ТҮБД зэрэг</label>
                    <b-form-select
                      v-model="form.tvbd_zer"
                      :options="options"
                      size="sm"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('tvbd_zer'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="form.tvbd_zer"></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <b-form-checkbox
                      id="vild_evlel_member"
                      v-model="form.vild_evlel_member"
                      name="vild_evlel_member"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Үйлдвэрчний эвлэлийн гишүүн эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.vild_evlel_member"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6" v-if="form.vild_evlel_member == 'Тийм'">
                  <div class="form-group">
                    <label>Гишүүнээр элссэн огноо</label>
                    <input
                      type="date"
                      name="vild_evlel_member_ognoo"
                      v-model="form.vild_evlel_member_ognoo"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'vild_evlel_member_ognoo'
                          ),
                        },
                      ]"
                      placeholder="Тэтгэвэр тогтоосон огноо"
                    />
                    <has-error
                      :form="form"
                      field="vild_evlel_member_ognoo"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Оршин суугаа хаяг</label>
                    <input
                      type="text"
                      name="teacher_live_address"
                      v-model="form.teacher_live_address"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_live_address'),
                        },
                      ]"
                      placeholder="Оршин суугаа хаяг"
                    />
                    <has-error
                      :form="form"
                      field="teacher_live_address"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Дүүрэг</label>
                    <b-form-select
                      v-model="form.district"
                      :options="districtOptions"
                    ></b-form-select>
                    <has-error :form="form" field="form.district"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Утас</label>
                    <input
                      type="number"
                      name="teacher_phoneNumber1"
                      v-model="form.teacher_phoneNumber1"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_phoneNumber1'),
                        },
                      ]"
                      placeholder="Утас"
                    />
                    <has-error
                      :form="form"
                      field="teacher_phoneNumber1"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Хувийн цахим шуудан</label>
                    <input
                      type="email"
                      name="teacher_personal_email"
                      v-model="form.teacher_personal_email"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'teacher_personal_email'
                          ),
                        },
                      ]"
                      placeholder="Хувийн цахим шуудан"
                    />
                    <has-error
                      :form="form"
                      field="teacher_personal_email"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Дээд боловсролын зэрэг</label>
                    <b-form-select
                      v-model="form.deed_bol"
                      :options="degrees"
                      size="sm"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('deed_bol'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="form.deed_bol"></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Төгссөн сургууль</label>
                    <input
                      type="text"
                      name="teacher_tugssun_sur"
                      size="sm"
                      v-model="form.teacher_tugssun_sur"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_tugssun_sur'),
                        },
                      ]"
                      placeholder="Төгссөн сургууль"
                    />
                    <has-error
                      :form="form"
                      field="teacher_tugssun_sur"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Мэрэгшүүлэх сургалт</label>
                    <input
                      type="text"
                      name="mergeshvvlekh_surgalt"
                      v-model="form.mergeshvvlekh_surgalt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'mergeshvvlekh_surgalt'
                          ),
                        },
                      ]"
                      placeholder="Мэрэгшүүлэх сургалт"
                    />
                    <has-error
                      :form="form"
                      field="mergeshvvlekh_surgalt"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Мэрэгжил</label>
                    <input
                      type="text"
                      name="teacher_mergejil"
                      v-model="form.teacher_mergejil"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_mergejil'),
                        },
                      ]"
                      placeholder="Мэрэгжил"
                    />
                    <has-error
                      :form="form"
                      field="teacher_mergejil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Эрдмийн бүтээлийн бүртгэл</label>
                    <input
                      type="text"
                      name="teacher_erd_bvt_bvrt"
                      v-model="form.teacher_erd_bvt_bvrt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('teacher_erd_bvt_bvrt'),
                        },
                      ]"
                      placeholder="Эрдмийн бүтээлийн бүртгэл"
                    />
                    <has-error
                      :form="form"
                      field="teacher_erd_bvt_bvrt"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ажилласан илтгэл, төсөл хөтөлбөр</label>
                    <input
                      type="text"
                      name="teacher_erd_ajils_ilt_proj"
                      v-model="form.teacher_erd_ajils_ilt_proj"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'teacher_erd_ajils_ilt_proj'
                          ),
                        },
                      ]"
                      placeholder="Ажилласан илтгэл, төсөл хөтөлбөр"
                    />
                    <has-error
                      :form="form"
                      field="teacher_erd_ajils_ilt_proj"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Гадагшаа зорчсон албан ажил</label>
                    <input
                      type="text"
                      name="teacher_alban_ajil_gad_zor"
                      v-model="form.teacher_alban_ajil_gad_zor"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'teacher_alban_ajil_gad_zor'
                          ),
                        },
                      ]"
                      placeholder="Гадагшаа зорчсон албан ажил"
                    />
                    <has-error
                      :form="form"
                      field="teacher_alban_ajil_gad_zor"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_bagshlakh_erkh"
                      v-model="form.teacher_bagshlakh_erkh"
                      name="teacher_bagshlakh_erkh"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Багшлах эрхийн үнэмлэхтэй эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_bagshlakh_erkh"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="cambridge_teacher"
                      v-model="form.cambridge_teacher"
                      name="cambridge_teacher"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Олон улсын хөтөлбөр хэрэгжүүлэх эрхтэй эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.cambridge_teacher"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_cambridge_exam_officer"
                      v-model="form.teacher_cambridge_exam_officer"
                      name="teacher_cambridge_exam_officer"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Олон улсын шалгалтын шалгагч багшийн эрхтэй эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_cambridge_exam_officer"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Англи хэлний мэдлэг</label>
                    <b-form-select
                      v-model="form.teacher_english"
                      :options="engOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_english"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_chinese"
                      v-model="form.teacher_chinese"
                      name="teacher_chinese"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Хятад хэлтэй эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_chinese"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-checkbox
                      id="teacher_japanese"
                      v-model="form.teacher_japanese"
                      name="teacher_japanese"
                      value="Тийм"
                      unchecked-value="Үгүй"
                    >
                      Япон хэлтэй эсэх
                    </b-form-checkbox>
                    <has-error
                      :form="form"
                      field="form.teacher_japanese"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Боловсролын байгууллагад ажилласан жил</label>
                    <b-form-select
                      v-model="form.teacher_bol_bai_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_bol_bai_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тухайн мэргэжлээр ажилласан жил</label>
                    <b-form-select
                      v-model="form.teacher_tukhain_mer_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_tukhain_mer_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Тухайн байгууллагад ажлласан жил</label>
                    <b-form-select
                      v-model="form.teacher_tukhain_bai_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.teacher_tukhain_bai_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Төрийн байгууллагад ажилласан жил</label>
                    <b-form-select
                      v-model="form.turiin_alba_ajiljil"
                      :options="yearOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.turiin_alba_ajiljil"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Амьдралын нөхцөл байдал</label>
                    <input
                      type="text"
                      name="teacher_live_situation"
                      v-model="form.teacher_live_situation"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'teacher_live_situation'
                          ),
                        },
                      ]"
                      placeholder="Амьдралын нөхцөл байдал"
                    />
                    <has-error
                      :form="form"
                      field="teacher_live_situation"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Зээлийн нөхцөл байдал</label>
                    <input
                      type="text"
                      name="teacher_zeel_situation"
                      v-model="form.teacher_zeel_situation"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'teacher_zeel_situation'
                          ),
                        },
                      ]"
                      placeholder="Зээлийн нөхцөл байдал"
                    />
                    <has-error
                      :form="form"
                      field="teacher_zeel_situation"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label
                      >Цахим болон төсөлт хичээлийн сургалтын технологийг
                      хэрэгжүүлэх чадвар</label
                    >
                    <b-form-select
                      v-model="form.online_platform_skill"
                      :options="onlineSkillOptions"
                    ></b-form-select>
                    <has-error
                      :form="form"
                      field="form.online_platform_skill"
                    ></has-error>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal
      size="sm"
      id="modal-complain"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Жирэмсний амралт."
      class="modal fade"
      hide-footer
      @hidden="onCancel"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"></h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <div class="col-md-12">
                  <div class="form-group">
                    <label
                      >Тухайн ажилтанг жирэмсний амралтруу шилжүүлэх гэж
                      байна.</label
                    >
                  </div>
                </div>
                <b-button variant="primary" @click="setWorkOut(2)">
                  Шилжүүлэх
                </b-button>
                <b-button
                  type="reset"
                  variant="danger"
                  @click.prevent="onCancel()"
                  >Болих</b-button
                >
                <!-- </b-form> -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-show-employee"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-2">
            <img
              style="width: 100%"
              v-if="showEmployee.profile_photo_url != null"
              :src="showEmployee.profile_photo_url"
            />
          </div>
          <div class="col-lg-4 mt-2">
            <dl class="text-left">
              <dt>Иргэншил:</dt>
              <dd>{{ showEmployee.citizenship }}</dd>
              <dt>Мэргэжлийн баг:</dt>
              <dd>{{ showEmployee.department_name }}</dd>
              <dt>Ажлын байр:</dt>
              <dd>{{ showEmployee.position_name }}</dd>
              <dt>Овог Нэр:</dt>
              <dd>
                {{ showEmployee.teacher_last_name }}
                <span class="text-uppercase">
                  {{ showEmployee.teacher_name }}
                </span>
              </dd>
              <dt>Нэвтрэх нэр:</dt>
              <dd>{{ showEmployee.email }}</dd>
              <dt>Регистер:</dt>
              <dd>{{ showEmployee.teacher_reg }}</dd>
              <dt>Системд бүртгэгдсэн огноо:</dt>
              <dd>
                {{ showEmployee.created_at | dateYearMonthDay }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
// import searchTeacher from "./TeacherSearch/teacherSearch.vue";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      action: "",
      editMode: false,
      workOut: 1,
      //   keyword: "",
      schoolteachers: [],
      deparments: [],
      positions: [],
      workTypes: [
        { value: "", text: "Сонгох" },
        { value: "Бүтэн", text: "Бүтэн цагийн" },
        { value: "Цагийн", text: "Цагийн" },
        { value: "Гэрээт", text: "Гэрээт" },
        { value: "Бусад", text: "Бусад" },
      ],
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эр", text: "Эрэгтэй" },
        { value: "Эм", text: "Эмэгтэй" },
      ],
      showEmployee: new Form({
        profile_photo_url: "",
        citizenship: "",
        department_name: "",
        position_name: "",
        email: "",
        teacher_reg: "",
        teacher_urgiin_ovog: "",
        teacher_last_name: "",
      }),
      defaultimage: "/images/users/user.png",
      form: new Form({
        id: "",
        name: "",
        gender: "",
        teacher_birthDate: "",
        email: "",
        teacher_reg: "",
        teacher_urgiin_ovog: "",
        teacher_last_name: "",
        dep_id: "",
        pos_id: "",
        bnb: "",
        user_id: "",
        married: "",
        tetgever_togtooson: "",
        tetgever_togtooson_ognoo: "",
        tetgever_togtooson_age: "",
        work_type: "",
        work_start_date: "",
        work_finish_date: "",
        tvbd_zer: "",
        vild_evlel_member: "",
        vild_evlel_member_ognoo: "",
        teacher_live_address: "",
        teacher_phoneNumber1: "",
        teacher_personal_email: "",
        deed_bol: "",
        mer_bol: "",
        buren_dund_bol: "",
        mergeshvvlekh_surgalt: "",
        teacher_mergejil: "",
        teacher_erd_zer: "",
        teacher_erd_bvt_bvrt: "",
        teacher_sport_mas: "",
        teacher_erd_ajils_ilt_proj: "",
        teacher_alban_ajil_gad_zor: "",
        teacher_bol_zer: "",
        teacher_bagshlakh_erkh: "",
        teacher_english: "",
        teacher_chinese: "",
        teacher_japanese: "",
        teacher_bol_bai_ajiljil: "",
        teacher_tukhain_mer_ajiljil: "",
        teacher_tukhain_bai_ajiljil: "",
        turiin_alba_ajiljil: "",
        teacher_live_situation: "",
        teacher_zeel_situation: "",
        teacher_tugssun_sur: "",
        cambridge_teacher: "",
        teacher_cambridge_exam_officer: "",
        online_platform_skill: "",
        district: "СБД",
        role_name: "",
      }),
      roleList: [
        {
          id: 4,
          name: "chertea",
          label: "Багш",
        },
        {
          id: 15,
          name: "logistpsycho",
          label: "Сэтгэл зүйч",
        },
        {
          id: 28,
          name: "socialworker",
          label: "Нийгмийн ажилтан",
        },
        {
          id: 8,
          name: "reg_emp",
          label: "Бүртгэлийн ажилтан",
        },
        {
          id: 14,
          name: "hr",
          label: "Хүний нөөц",
        },
        {
          id: 17,
          name: "rarianlib",
          label: "Номын санч",
        },
        {
          id: 19,
          name: "it",
          label: "Мэдээлэл технологийн ажилтан",
        },
      ],
      options: [
        { value: "", text: "Мэргэжлийн зэрэг сонгоно уу?" },
        { value: "Багш", text: "Багш" },
        { value: "Ахлах багш", text: "Ахлах багш" },
        { value: "Заах аргач багш", text: "Заах аргач багш" },
        { value: "Тэргүүлэх багш", text: "Тэргүүлэх багш" },
        { value: "Зөвлөх багш", text: "Зөвлөх багш" },
      ],
      degrees: [
        { value: "", text: "Боловсролын зэрэг сонгоно уу?" },
        { value: "Дипломын дээд", text: "Дипломын дээд" },
        { value: "Бакалавр", text: "Бакалавр" },
        { value: "Магистр", text: "Магистр" },
        { value: "Доктор", text: "Доктор" },
      ],
      yearOptions: [
        { value: "0-3 жил", text: "0-3 жил" },
        { value: "4-6 жил", text: "4-6 жил" },
        { value: "7-9 жил", text: "7-9 жил" },
        { value: "10-12 жил", text: "10-12 жил" },
        { value: "13-15 жил", text: "13-15 жил" },
        { value: "16-18 жил", text: "16-18 жил" },
        { value: "19-21 жил", text: "19-21 жил" },
        { value: "22-25 жил", text: "22-25 жил" },
        { value: "25-аас дээш жил", text: "25-аас дээш жил" },
      ],
      engOptions: [
        { value: "Анхан шатны (Elementary)", text: "Анхан шатны (Elementary)" },
        {
          value: "Дунджаас доогуур (Pre-intermediate)",
          text: "Дунджаас доогуур (Pre-intermediate)",
        },
        {
          value: "Дундаж түвшний (Intermediate)",
          text: "Дундаж түвшний (Intermediate)",
        },
        {
          value: "Ахисан түвшний (Upper-intermediate)",
          text: "Ахисан түвшний (Upper-intermediate)",
        },
        {
          value: "Гүнзгийрсэн түвшний (Advanced)",
          text: "Гүнзгийрсэн түвшний (Advanced)",
        },
        { value: "TOFEL, IELTS-ийн оноотой", text: "TOFEL, IELTS-ийн оноотой" },
      ],
      onlineSkillOptions: [
        { value: "Анхан шатны", text: "Анхан шатны" },
        { value: "Дунджаас доогуур түвшний", text: "Дунджаас доогуур түвшний" },
        { value: "Дундаж түвшний", text: "Дундаж түвшний" },
        { value: "Ахисан түвшний", text: "Ахисан түвшний" },
        { value: "Гүнзгийрүүлсэн түвшний", text: "Гүнзгийрүүлсэн түвшний" },
        { value: "Инновацийн түвшний", text: "Инновацийн түвшний" },
      ],
      districtOptions: [
        { value: "СБД", text: "Сүхбаатар" },
        { value: "ЧД", text: "Чингэлтэй" },
        { value: "БЗД", text: "Баянзүрх" },
        { value: "БГД", text: "Баянгол" },
        { value: "ХУД", text: "Хан-Уул" },
        { value: "СХД", text: "Сонгинохайрхан" },
        { value: "БХД", text: "Багахангай" },
        { value: "НД", text: "Налайх" },
        { value: "БД", text: "Багануур" },
      ],
      deleteEmployeeInfo: null,
      selectedType: "chertea",
      typeOptions: [
        { value: "chertea", text: "Багш нар" },
        { value: "surgalt", text: "Сургалтын алба" },
      ],
    };
  },
  components: {
    // searchTeacher: searchTeacher,
  },
  methods: {
    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },
    getAllteachers() {
      axios
        .post("/manager/getAllteachers", { selectedType: this.selectedType })
        .then((res) => {
          this.schoolteachers = res.data.schoolteachers;
          this.deparments = res.data.schooldepartments;
          this.positions = res.data.positions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    passChangeSchoolTeacher(schoolteacher) {
      console.log(schoolteacher);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe#ТухайнОн",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manager/passChangeSchoolTeacher/" + schoolteacher.id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    viewSchoolTeacher(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolteacher);
    },
    editschoolTeacher(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolteacher);
      this.form.user_id = schoolteacher.emp_id;
    },
    updateSchoolTeacher() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateTeacher/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    onCancel() {
      this.$bvModal.hide("modal-complain");
    },
    deleteBeforeEmployeePosition(employee) {
      this.$bvModal.show("modal-complain");
      // console.log(employee);
      this.deleteEmployeeInfo = employee;
    },
    setWorkOut(value) {
      this.workOut = value;
      this.deleteSchoolTeacher();
    },
    deleteSchoolTeacher() {
      // console.log(schoolteacher);
      let schoolteacher = this.deleteEmployeeInfo;
      Swal.fire({
        title: "Шилжүүлэх!",
        text: "Багшийг жирэмсний амралтруу шилжүүлэхдээ итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Шилжүүлэх`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .post("/manager/deleteSchoolTeacher", {
              workOut: this.workOut,
              schoolTeacher: schoolteacher,
            })
            .then((response) => {
              //   console.log(response.data);
              this.$toastr.s("Амжилттай", "Шинэчилсэн");
              Fire.$emit("loadSchoolTeachers");
              this.form.reset();
            })
            .catch((errors) => {
              //   console.log(errors);
            });
          this.$bvModal.hide("modal-complain");
        }
      });
    },
    createSchoolTeacher() {
      this.action = "Багш үүсгэж байна...";
      this.form
        .post("/manager/createTeacher")
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolTeachers");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Багшийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  //   computed: {
  //     filteredschoolteachers: function () {
  //       return this.schoolteachers.filter((schoolteacher) => {
  //         return schoolteacher.teacher_last_name.match(this.keyword);
  //       });
  //     },
  //   },
  //   beforeCreate() {
  //     console.log("beforecreate");
  //   },
  created: function () {
    this.getAllteachers();
    Fire.$on("loadSchoolTeachers", () => {
      this.getAllteachers();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
