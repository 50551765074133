<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="card-title">Сарын ажлын үнэлгээ</h3>
            <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
            <!-- Button trigger modal -->
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-container class="bv-example-row mb-3" fluid>
            <b-row>
              <b-col sm="3">
                <multiselect
                  v-model="school_year_id"
                  select-label="Сонгох"
                  selected-label="Сонгогдсон"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="id"
                  label="id"
                  :custom-label="customYearName"
                  placeholder="Хичээлийн жил сонгоно уу?"
                  :options="years"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.start_date | dateYear }}-{{
                        option.finish_date | dateYear
                      }}
                      - хичээлийн жил
                    </strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="3"
                ><multiselect
                  v-model="selectedMonths"
                  :options="monthsRe"
                  :multiple="true"
                  track-by="name"
                  label="month"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Сар сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.month }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="2"
                ><b-button variant="success" @click.prevent="getAllData()"
                  >Шүүж харах</b-button
                >
              </b-col>
            </b-row>
          </b-container>
          <b-table
            striped
            bordered
            hover
            :items="managerEvaluations"
            :fields="fields"
          >
            <template v-slot:head(shal1)>
              <span
                v-b-popover.hover="
                  'Хөгжүүлэх ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Хичээлээс гадуурх Соёл хүмүүжлийн ажилд хамрагдвал зохих нийт суралцагчдын ≥85% нь хамрагдсан байна. Сургалтын албанаас өгсөн үүрэг даалгаврын гүйцэтгэл ≥85% байна.'
                "
                title="Сургалтын цогц тогтолцоо ба тэгш боломж, үүрэг даалгаврын гүйцэтгэл;"
              >
                Ш-1</span
              >
            </template>
            <template v-slot:head(shal2)>
              <span
                v-b-popover.hover.righttop="
                  'Сургалтын ээлжит үйл ажиллагааны дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
                "
                title="Сургалтын ээлжит үйл ажиллагааны чанар;"
              >
                Ш-2</span
              >
            </template>
            <template v-slot:head(shal3)>
              <span
                v-b-popover.hover.righttop="
                  'Суралцагчдын сурлага, хүмүүжил болон бие бялдрын хөгжлийн явцын эсхүл хөндлөнгийн шалгалтын дундаж үнэлгээ ≥60% үзүүлэлттэй байна.'
                "
                title="Суралцагчдын хөгжилд гарсан ахиц өөрчлөлт;"
              >
                Ш-3</span
              >
            </template>
            <template v-slot:head(shal4)>
              <span
                v-b-popover.hover.righttop="
                  '1. Хэрэглэгчдээс авсан сэтгэл ханамжийн судалгааны үр дүн 85%-иас дээш үзүүлэлттэй байна.2. Санал гомдлын мөрөөр авсан арга хэмжээний гүйцэтгэл 80%-иас дээш үзүүлэлттэй байна.'
                "
                title="Сургалтын талаарх хэрэглэгчдийн сэтгэл ханамж, санал гомдол шийдвэрлэсэн байдал;"
              >
                Ш-4</span
              >
            </template>
            <template v-slot:head(shal5)>
              <span
                v-b-popover.hover.righttop="'Тухайн сард ажилласан цаг'"
                title="Илүү цаг"
              >
                Ш-5</span
              >
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(name)="row">
              <span
                class="badge badge-primary"
                v-if="row.item.emp_teacher_last_name"
              >
                {{ row.item.emp_teacher_last_name[0].toUpperCase() }}.
                {{ row.item.emp_teacher_name }}
              </span>
            </template>
            <template #cell(shal1)="row">
              <span class="badge badge-primary">
                {{ row.item.shal1 }} * {{ row.item.shal1_jin_huvi }} =
                {{
                  calculatePercentElement(
                    row.item.shal1,
                    row.item.shal1_jin_huvi
                  )
                }}
              </span>
            </template>
            <template #cell(shal2)="row">
              <span class="badge badge-primary">
                {{ row.item.shal2 }} * {{ row.item.shal2_jin_huvi }} =
                {{
                  calculatePercentElement(
                    row.item.shal2,
                    row.item.shal2_jin_huvi
                  )
                }}
              </span>
            </template>
            <template #cell(shal3)="row">
              <span class="badge badge-primary">
                {{ row.item.shal3 }} * {{ row.item.shal3_jin_huvi }} =
                {{
                  calculatePercentElement(
                    row.item.shal3,
                    row.item.shal3_jin_huvi
                  )
                }}
              </span>
            </template>
            <template #cell(shal4)="row">
              <span class="badge badge-primary">
                {{ row.item.shal4 }} * {{ row.item.shal4_jin_huvi }} =
                {{
                  calculatePercentElement(
                    row.item.shal4,
                    row.item.shal4_jin_huvi
                  )
                }}
              </span>
            </template>
            <template #cell(shal1-4)="row">
              <span class="badge badge-success">
                {{
                  calculateManagerPercentJinHuvi(
                    row.item.shal1,
                    row.item.shal1_jin_huvi,
                    row.item.shal2,
                    row.item.shal2_jin_huvi,
                    row.item.shal3,
                    row.item.shal3_jin_huvi,
                    row.item.shal4,
                    row.item.shal4_jin_huvi
                  )
                }}%
              </span>
            </template>
            <template #cell(shal5)="row">
              <span v-show="row.item.shal5 === null"> 0 ц</span>
              <span v-show="row.item.shal5 !== null">
                {{ row.item.shal5 }} ц</span
              >
            </template>
            <template #cell(created_at)="row">
              {{ row.item.created_at | dateYearMonthDay }}
            </template>
            <template #cell(updated_at)="row">
              {{ row.item.updated_at | dateYearMonthDay }}
            </template>
            <template #cell(actions)="row">
              <a
                v-show="row.item.readManager === null"
                href="#"
                class="badge bg-warning"
                @click="readEvaluation(row.item)"
                ><i class="fas fa-check-circle"></i
              ></a>
              <span
                class="badge bg-success"
                v-show="row.item.readManager === 1"
              >
                <i class="fas fa-check-double"></i>
              </span>
            </template>
          </b-table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      school_year_id: "",
      years: [],
      selectedMonths: null,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      action: "",
      editMode: false,
      managerEvaluations: [],
      form: new Form({
        id: "",
        ajliin_sar: "",
        readManager: false,
      }),
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Овог Нэр",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Имэйл хаяг",
          sortable: true,
        },
        {
          key: "position_name",
          label: "Албан тушаал",
          sortable: true,
        },
        {
          key: "ajliin_sar",
          label: "Сар",
          sortable: true,
        },
        {
          key: "shal1",
          label: "Ш-1",
          sortable: true,
        },
        {
          key: "shal2",
          label: "Ш-2",
          sortable: true,
        },
        {
          key: "shal3",
          label: "Ш-3",
          sortable: true,
        },
        {
          key: "shal4",
          label: "Ш-4",
          sortable: true,
        },
        {
          key: "shal1-4",
          label: "Ш1-4",
          sortable: true,
        },
        {
          key: "shal5",
          label: "Ш-5",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Танилцсан",
          sortable: true,
        },
      ],
    };
  },
  components: {
    Multiselect,
    // searchManager: searchManager,
  },
  methods: {
    getYearList() {
      axios
        .get("/all_worker_functions/getYearList")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    nameDepartment({ department_code }) {
      return `${department_code}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getAllData() {
      axios
        .post("/all_worker_functions/getAllMyWorkEvaluationsManager", {
          yearId: this.school_year_id.id,
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          //   console.log(response.data);
          this.managerEvaluations = response.data.myWorkEvaluations;
          //   this.positions = response.data.teacherPositionsName;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    readEvaluation(managerEvaluation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", managerEvaluation);
      this.form.id = managerEvaluation.id;
      this.form.ajliin_sar = managerEvaluation.ajliin_sar;
      this.form.readManager = true;
      Swal.fire({
        title: "Шууд удирдлагын өгсөн үнэлгээг зөвшөөрч байна.",
        text: "Шууд удирдлагын өгсөн үнэлгээтэй танилцаж, саналаа биечлэн өгсөн үү? Тийм бол хадгалах! товчыг дарснаар шууд удирдлага дүнг засах боломжгүй болно.Сарын үнэлгээ бүрэн баталгаажина.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хадгалах!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.form
            .post("/all_worker_functions/readManagerED")
            .then((response) => {
              //   this.$toastr.s("Үнэлгээтэй танилцсан.", "Амжилттай");
              Swal.fire("Үнэлгээтэй танилцсан.", "Амжилттай", "success");
              Fire.$emit("loadSchoolManagers");
              this.form.reset();
            })
            .catch(() => {
              // console.log(errors.all());
              this.$toastr.e(
                "Үнэлгээтэй танилцсан мэдээллийг хадгалж чадсангүй.",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    average() {
      return (
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4
      );
    },
    calculatePercent() {
      let averagePer =
        (parseFloat(this.form.shal1) +
          parseFloat(this.form.shal2) +
          parseFloat(this.form.shal3) +
          parseFloat(this.form.shal4)) /
        4;
      let myPercent = (averagePer * 20) / 100;
      return myPercent;
    },
    checkFormValidity() {
      //   console.log("checkFormValidity");
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      //   console.log("resetModal");
    },
    handleOk(bvModalEvt) {
      //   console.log("handleOk");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //   console.log("handleSubmit");
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$forceUpdate();
      });
    },
    calculatePercentElement(shal, shal_jin_huvi) {
      let averagePer = parseFloat(shal) * parseFloat(shal_jin_huvi);
      return averagePer.toFixed(1);
    },
    calculateManagerPercentJinHuvi(
      shal1,
      shal1_jin_huvi,
      shal2,
      shal2_jin_huvi,
      shal3,
      shal3_jin_huvi,
      shal4,
      shal4_jin_huvi
    ) {
      let averageMyPer =
        parseFloat(shal1) * parseFloat(shal1_jin_huvi) +
        parseFloat(shal2) * parseFloat(shal2_jin_huvi) +
        parseFloat(shal3) * parseFloat(shal3_jin_huvi) +
        parseFloat(shal4) * parseFloat(shal4_jin_huvi);
      let myManagerPercent = (averageMyPer * 15) / 100;
      return myManagerPercent.toFixed(1);
    },
  },
  computed: {
    validationNumber1() {
      return this.form.shal1 >= 0 && this.form.shal1 <= 100;
    },
    validationNumber2() {
      return this.form.shal2 >= 0 && this.form.shal2 <= 100;
    },
    validationNumber3() {
      return this.form.shal3 >= 0 && this.form.shal3 <= 100;
    },
    validationNumber4() {
      return this.form.shal4 >= 0 && this.form.shal4 <= 100;
    },
    validationNumber5() {
      return this.form.shal5 >= 0 && this.form.shal5 <= 100;
    },
  },
  created() {
    this.getYearList();
    this.getAllData();
    Fire.$on("loadSchoolManagers", () => {
      this.getAllData();
    });
    setInterval(this.getNow, 1000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
