<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header border-0">
          <h3 class="card-title">
            Сургуульдаа илэрхийлсэн санал, хүсэлт, гомдол, хамтран ажиллах санал
          </h3>
          <div class="card-tools">
            <!-- <a
              href="#"


            >
              <i class="fas fa-download"></i>
              Санал, хүсэлт, гомдол бүртгэх
            </a> -->
            <!-- class="btn btn-tool btn-sm" -->
            <b-button @click="createComplaintsParent" variant="outline-success"
              >Санал, хүсэлт, гомдол бүртгэх</b-button
            >
            <!-- <a href="#" class="btn btn-tool btn-sm">
              <i class="fas fa-bars"></i>
            </a> -->
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr>
                <th>№</th>
                <th>Шийдвэрлэсэн</th>
                <th>Шийдвэрлэсэн тайлбар</th>
                <th>Гол агуулга</th>
                <th>Хүүхэд</th>
                <th>Үйлдэл</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(complaint, index) in myComplaints"
                :key="complaint.id"
              >
                <td>{{ ++index }}</td>
                <td>
                  <small
                    v-show="complaint.complaints_parents_decision == 0"
                    class="text-warning"
                  >
                    Хүлээгдэж байгаа
                  </small>
                  <small v-show="complaint.complaints_parents_decision == 1">
                    <i class="far fa-check-circle text-success"></i>
                  </small>
                </td>
                <td>
                  <small class="mr-1">
                    <i
                      class="fas fa-exclamation text-warning"
                      v-show="complaint.complaints_parents_decision === 0"
                    ></i>
                    <i
                      class="fas fa-exclamation text-success"
                      v-show="complaint.complaints_parents_decision === 1"
                    ></i>
                    <small class="text-info">
                      <span
                        v-if="
                          complaint.complaints_parents_decision_explain !== null
                        "
                        v-html="
                          complaint.complaints_parents_decision_explain.substring(
                            0,
                            1000000
                          )
                        "
                      ></span>
                      <span v-else>Шийдвэр ирээгүй байна.</span>
                    </small>
                  </small>
                </td>
                <td>
                  <small class="text-info">
                    <span
                      v-html="
                        complaint.complaints_parents_main_problem.substring(
                          0,
                          10000
                        )
                      "
                    ></span>
                  </small>
                </td>
                <td>
                  <small
                    class="text-info"
                    v-if="complaint.my_child_profile !== null"
                  >
                    {{ complaint.my_child_profile.student_last_name[0] }}.
                    {{ complaint.my_child_profile.student_name }}
                  </small>
                  <small class="text-info" v-else> Хүүхэд нууцалсан </small>
                </td>
                <td>
                  <a
                    v-if="complaint.complaints_parents_decision !== 1"
                    href="#"
                    class="text-warning"
                    @click.prevent="editComplaints(complaint)"
                  >
                    <i class="far fa-edit"></i>
                  </a>
                  <a
                    v-if="complaint.complaints_parents_decision !== 1"
                    href="#"
                    class="text-danger"
                    @click.prevent="deleteComplaints(complaint)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-parentComplain"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template #modal-title size="1">
        Эрхэм үйлчлүүлэгч та өргөдөл, гомдлоо нээлттэй илэрхийлж, бидэнтэй
        хамтран ажиллаж байгаа явдалд талархал илэрхийлье!
      </template>
      <form
        @submit.prevent="
          !editMode ? saveParentComplain() : updateParentComplain()
        "
      >
        <div class="modal-body">
          <div class="direct-chat-msg">
            <div class="direct-chat-infos clearfix"></div>

            <div class="row">
              <div class="col-sm-6">
                <multiselect
                  v-show="form.notUser != true"
                  v-model="selected_student"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="student_user_id"
                  label="student_name"
                  :custom-label="customLabelStudent"
                  placeholder="Эцэг эх та хүүхэд сонгоно уу?"
                  :options="myChildren"
                  :searchable="true"
                  :allow-empty="false"
                  class="sm"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.student_last_name[0]
                      }}{{ option.student_last_name[1] }}.{{
                        option.student_name
                      }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="col-sm-6">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.notUser"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  Нэр хадгалахгүй санал, гомдол илэрхийлэх ба мэдээллийг нууцлах
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Өргөдөл гомдол гаргах хуудас</h3>
                </div>
                <!-- /.card-header -->

                <div class="card-body table-responsive">
                  <!-- v-if="modalComments != null" -->
                  <pre class="text-right">{{ timestamp }}</pre>
                  <span v-show="notUserSelect != `true`">
                    Сэлбэ сургууль, цэцэрлэгийн
                    {{ selected_student.full_name }}-р ангийн суралцагч
                    {{ selected_student.student_last_name }}
                    овогтой
                    {{ selected_student.student_name }}-ийн хууль ёсны төлөөлөгч
                    (эцэг, эх, асран хамгаалагч, харгалзан хамгаалагч, бусад)
                    {{ selected_student.parent_father_ovog }}
                    овогтой
                    {{ selected_student.parent_father_name }}
                    миний бие дараах санал гомдлыг маань хүлээн авч шийдвэрлэх
                    тал дээр хамтран ажиллахыг хүсч байна.
                  </span>
                  <br />
                  Тухайлбал та дараах сонголтуудаас өөрийн асуудалд тохирохыг
                  сонгоно уу?
                  <span class="text-sm"></span>
                  <div class="row">
                    <span></span>
                    <div class="col-sm-3">
                      <b-form-checkbox
                        id="checkbox-2"
                        name="checkbox-2"
                        v-model="poll"
                        value="true"
                        unchecked-value="false"
                        @change="changePoll"
                      >
                        Санал
                      </b-form-checkbox>
                    </div>
                    <div class="col-sm-3">
                      <b-form-checkbox
                        id="checkbox-3"
                        name="checkbox-3"
                        value="true"
                        v-model="complaint"
                        unchecked-value="false"
                        @change="changeComplaint"
                      >
                        Гомдол
                      </b-form-checkbox>
                    </div>
                    <div class="col-sm-3">
                      <b-form-checkbox
                        id="checkbox-4"
                        name="checkbox-4"
                        v-model="contribution"
                        value="true"
                        unchecked-value="false"
                        @change="changeContribution"
                      >
                        Хамтран ажиллах
                      </b-form-checkbox>
                    </div>
                    <div class="col-sm-3">
                      <b-form-checkbox
                        id="checkbox-5"
                        name="checkbox-5"
                        v-model="ethics"
                        value="true"
                        unchecked-value="false"
                        @change="changeEthics"
                      >
                        Ёс зүй
                      </b-form-checkbox>
                    </div>
                  </div>
                  <quill-editor
                    v-model="form.main_problem"
                    :options="editorOptionParent"
                    placeholder="Санал, гомдол, шүүмж бичих..., Хамааралтай этгээд болон үйл явдлыг дэлгэрэнгүй бичнэ үү!"
                  >
                  </quill-editor>

                  <div>
                    <p
                      class="text-sm-right"
                      size="0.6"
                      v-show="form.ethics == `true`"
                    >
                      <i
                        >Сэлбэ Сургууль Цэцэрлэгийн Цогцолборын Ёс зүйн хороо
                        мэдээллийн нууцлалыг чандлан хадгалж, мэдээллийг хянаж
                        шийдвэрлэнэ.</i
                      >
                    </p>
                  </div>
                  <table class="table text-wrap">
                    <thead>
                      <tr role="row">
                        <th class="col-1">№</th>
                        <th class="col-5">Асуулт</th>
                        <th class="col-6">Хариулт</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          Санал гомдол гаргах болсон асуудлын гол шалтгааныг та
                          юу гэж бодож байна вэ?
                        </td>
                        <td>
                          <quill-editor
                            v-model="form.question1"
                            :options="editorOptionParent"
                            placeholder="Та хариултаа бичнэ үү?"
                          >
                          </quill-editor>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          Асуудлаас болоод үүсч буй сөрөг үр дагавар нь юу вэ?
                        </td>
                        <td>
                          <quill-editor
                            v-model="form.question2"
                            :options="editorOptionParent"
                            placeholder="Та хариултаа бичнэ үү?"
                          >
                          </quill-editor>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          Та хүүхэдтэйгээ асуудлын шалтгаан ба шийдлийн талаар
                          ярилцаж, санаа бодлыг нь сонссон уу? Асуудлыг өөрөөр
                          нь шийдүүлэх, хандлагыг нь эерэг болгох оролдлого
                          хийсэн үү?
                        </td>
                        <td>
                          <quill-editor
                            v-model="form.question3"
                            :options="editorOptionParent"
                            placeholder="Та хариултаа бичнэ үү?"
                          >
                          </quill-editor>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          Энэ асуудлыг шийдвэрлэснээр бий болох үр дүн буюу
                          хүлээлт тань юу вэ? Таны хувьд асуудлыг шийдэх тал
                          дээр ямар арга замыг санал болгож байгаа вэ?
                        </td>
                        <td>
                          <quill-editor
                            v-model="form.question4"
                            :options="editorOptionParent"
                            placeholder="Та хариултаа бичнэ үү?"
                          >
                          </quill-editor>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          Асуудлыг бий болгоод байгаа багш (ажилтан)-тай уулзаж
                          асуудлын шийдэл, хүлээгдэж буй үр дүнгийнхээ талаар
                          ярилцсан уу? Ямар нэг ахиц өөрчлөлт ажиглагдсан уу?
                        </td>
                        <td>
                          <quill-editor
                            v-model="form.question5"
                            :options="editorOptionParent"
                            placeholder="Та хариултаа бичнэ үү?"
                          >
                          </quill-editor>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <b-button @click="resetModal()" variant="danger" type="reset">
            Хаах
          </b-button>
          <button type="submit" class="btn btn-success" v-show="!editMode">
            Нэмэх
          </button>
          <button type="submit" class="btn btn-primary" v-show="editMode">
            Засах
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //header style
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      //edit
      showMode: false,
      editMode: false,
      selected_student: "",

      myComplaints: [],
      myChildren: [],
      // Тусад нь ашиглалаа
      poll: false,
      complaint: false,
      contribution: false,
      ethics: false,
      form: new Form({
        id: "",
        // year_id: "",
        school_id: "",
        parent_id: "",
        school_class_id: "",
        main_problem: "",
        student_id: "",
        question1: "",
        question2: "",
        question3: "",
        question4: "",
        question5: "",
        question6: "",
        question7: "",
        question8: "",
        question9: "",
        question10: "",
        poll: false,
        complaint: false,
        contribution: false,
        ethics: false,
        decision: "",
        decision_explain: "",
        notUser: false,
      }),
      notUserSelect: false,

      // Тухайн өдрийн огноо
      timestamp: "",
      //   vue-quill-editor
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      editorOptionParent: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder:
          "Эцэг эхийн зүгээс санал, гомдол, хүсэлтэд тайлбар өгөх...",
      },
    };
  },
  components: {
    Multiselect,
  },
  //   beforeMount() {
  //     console.log("beforeMount");
  //   },
  //   updated() {
  //     console.log("updated");
  //   },
  watch: {
    selected_student(newVal, oldVal) {
      //   console.log(newVal, oldVal, this.editMode);
      this.form.school_id = newVal.school_id;
      this.form.parent_id = newVal.profile_parent_id;
      this.form.school_class_id = newVal.school_class_id;
      this.form.student_id = newVal.student_user_id;
      //   if (this.editMode) {
      //   }
      //   {
      //     this.form.question1 = "";
      //     this.form.question2 = "";
      //     this.form.question3 = "";
      //     this.form.question4 = "";
      //     this.form.question5 = "";
      //   }
    },
    "form.notUser"(newVal, oldVal) {
      this.notUserSelect = newVal;
      //   this.$forceUpdate();
    },
  },
  methods: {
    changePoll(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = true;
        this.form.complaint = false;
        this.form.contribution = false;
        this.form.ethics = false;
        this.poll = true;
        this.complaint = false;
        this.contribution = false;
        this.ethics = false;
      }
    },
    changeComplaint(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = false;
        this.form.complaint = true;
        this.form.contribution = false;
        this.form.ethics = false;
        this.poll = false;
        this.complaint = true;
        this.contribution = false;
        this.ethics = false;
      }
    },
    changeContribution(checked) {
      if (checked) {
        this.form.poll = false;
        this.form.complaint = false;
        this.form.contribution = true;
        this.form.ethics = false;
        this.poll = false;
        this.complaint = false;
        this.contribution = true;
        this.ethics = false;
      }
    },
    changeEthics(checked) {
      if (checked) {
        this.form.poll = false;
        this.form.complaint = false;
        this.form.contribution = false;
        this.form.ethics = true;
        this.poll = false;
        this.complaint = false;
        this.contribution = false;
        this.ethics = true;
      }
    },
    getAllParentsComplaints() {
      axios
        .get("/parent/getAllParentsComplaints")
        .then((response) => {
          this.myComplaints = response.data.allMyComplaints;
          this.myChildren = response.data.myChildren;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createComplaintsParent() {
      //   console.log("Create");
      this.$bvModal.show("modal-parentComplain");
    },
    // Гомдол санал хадгалах функц бэлэн болсон.
    saveParentComplain() {
      if (this.form.notUser === false) {
        if (this.form.student_id === "") {
          Swal.fire({
            icon: "error",
            title: "Алдаа гарлаа",
            text: "Та өөрийн хүүхдийг сонгоно уу?",
            // footer: "Алдаагаа засна уу?",
          });
        } else {
          this.form
            .post("/parent/saveParent")
            .then((response) => {
              this.$bvModal.hide("modal-parentComplain");
              this.form.reset();
              this.selected_student = "";
              Fire.$emit("loadMyComplaints");
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Амжилттай",
                text: "Амжилттай хадгаллаа",
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .catch((errors) => {
              //   console.log(this.form.errors.get("main_problem"));
              Swal.fire({
                icon: "error",
                title: "Алдаа гарлаа",
                text: "`Санал, гомдол, шүүмж бичих..., Хамааралтай бүхий багш, ажилтан, суралцагч, эцэг эх болон үйл явдлыг дэлгэрэнгүй бичнэ үү!` гэсэн формыг бөглөнө үү?",
                footer: "Алдаагаа засна уу?",
              });
              //   console.log(errors);
            });
        }
      } else {
        this.form
          .post("/parent/saveParent")
          .then((response) => {
            this.$bvModal.hide("modal-parentComplain");
            this.form.reset();
            this.selected_student = "";
            Fire.$emit("loadMyComplaints");
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Амжилттай",
              text: "Амжилттай хадгаллаа",
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((errors) => {
            //   console.log(this.form.errors.get("main_problem"));
            Swal.fire({
              icon: "error",
              title: "Алдаа гарлаа",
              text: "`Санал, гомдол, шүүмж бичих..., Хамааралтай этгээд болон үйл явдлыг дэлгэрэнгүй бичнэ үү!` гэсэн формыг бөглөнө үү?",
              footer: "Алдаагаа засна уу?",
            });
            //   console.log(errors);
          });
      }
    },

    editComplaints(complaint) {
      //   console.log(complaint);
      this.editMode = true;
      this.form.id = complaint.id;
      this.form.main_problem = complaint.complaints_parents_main_problem;
      //   this.form.student_id = complaint.complaints_parents_student_id;

      if (complaint.complaints_parents_poll == 1) {
        this.poll = true;
      } else {
        this.poll = false;
      }
      if (complaint.complaints_parents_complaint == 1) {
        this.complaint = true;
      } else {
        this.complaint = false;
      }
      if (complaint.complaints_parents_contribution == 1) {
        this.contribution = true;
      } else {
        this.contribution = false;
      }
      if (complaint.complaints_parents_ethics == 1) {
        this.ethics = true;
      } else {
        this.ethics = false;
      }

      this.form.notUser = false;
      if (complaint.student_id !== null) {
        this.form.notUser = false;
        let mySlectStudent = this.myChildren.filter(
          (el) => el.student_user_id === complaint.student_id
        );
        this.selected_student = mySlectStudent[0];
      } else {
        this.form.notUser = true;
      }

      //   console.log(mySlectStudent, complaint.complaints_parents_student_id);

      this.form.question1 = complaint.complaints_parents_question1;
      this.form.question2 = complaint.complaints_parents_question2;
      this.form.question3 = complaint.complaints_parents_question3;
      this.form.question4 = complaint.complaints_parents_question4;
      this.form.question5 = complaint.complaints_parents_question5;
      this.$bvModal.show("modal-parentComplain");
    },

    updateParentComplain() {
      this.form
        .put("/parent/updateComplaintsParents/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай шинэчиллээ!", "Шинэчилсэн");
          //   Fire.$emit("getComments");
          this.resetModal();
          Fire.$emit("loadMyComplaints");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    //Modal-тай ажиллах функц
    resetModal() {
      //   console.log("reset");
      this.$bvModal.hide("modal-parentComplain");
      this.form.reset();
      this.selected_student = "";
      this.poll = false;
      this.complaint = false;
      this.contribution = false;
      this.ethics = false;
      this.editMode = false;
    },
    handleOk(bvModalEvt) {
      //   console.log("ok");
      // Prevent modal from closing
      bvModalEvt.preventDefault();
    },

    deleteComplaints(complaint) {
      //   console.log(complaint);
      Swal.fire({
        title: "Хүчингүй болгохдоо итгэлтэй байна уу?",
        text: "Сэргээх боломжгүй!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Хүчингүй боллоо!", "Амжилттай устгагдлаа.", "success");
          axios
            .delete("/parent/deleteComplaint/" + complaint.id)
            .then((response) => {
              Fire.$emit("loadMyComplaints");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    customLabelStudent({ student_last_name, student_name }) {
      return `${student_last_name[0]}${student_last_name[1]}.${student_name}`;
    },

    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
  },
  created() {
    // console.log("Created");
    this.getAllParentsComplaints();
    setInterval(this.getNow, 1000);
    Fire.$on("loadMyComplaints", () => {
      this.getAllParentsComplaints();
    });
  },
};
</script>

<style></style>
