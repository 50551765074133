<template>
  <div class="col-12 pt-3">
    <nav class="bg-primary p-3">
      <!-- Left navbar links -->

      <div class="row">
        <div class="col-lg-3">
          <label>Улирал сонгох</label>
          <multiselect
            v-model="quarter_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="quarter_id"
            label="name"
            :custom-label="customLabelQuarter"
            placeholder="1-р алхам-Улирал сонголт"
            :options="mySchoolQuarters"
            :searchable="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.quarter_name }} - улирал</strong></template
            >
          </multiselect>
        </div>
        <div class="col-lg-3" v-if="mySchoolClasses.length > 1">
          <label>Анги сонгох</label>
          <multiselect
            v-model="school_class_id"
            :multiple="false"
            deselect-label="Can't remove this value"
            label="full_name"
            track-by="id"
            placeholder="Сонголт хийх"
            :options="mySchoolClasses"
            :searchable="true"
            :allow-empty="true"
            :close-on-select="true"
            :preserve-search="true"
            :hide-selected="true"
          >
            <!-- @select="toggleSelect" -->
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.full_name }} анги</strong>
              сонгогдлоо
            </template>
          </multiselect>
        </div>
      </div>
      <!-- <multiselect
        v-model="department_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="department_id"
        label="department_name"
        placeholder="2-р алхам-Мэргэжлийн баг сонголт"
        :options="mySchoolsDepartments"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.department_name }} </strong></template
        >
      </multiselect> -->
      <!-- <multiselect
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="employees_user_id"
        label="teacher_name"
        :custom-label="customLabelTeacherName"
        placeholder="3-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.teacher_last_name[0]
            }}{{ option.teacher_last_name[1] }}.{{
              option.teacher_name
            }}</strong
          ></template
        >
      </multiselect> -->
      <!-- <multiselect
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="4-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect> -->
      <!-- <multiselect
        v-model="exam_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="exam_id"
        label="name"
        :custom-label="customLabelExam"
        placeholder="5-р алхам-Шалгалт сонголт"
        :options="mySchoolCoursesExam"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.exam_name }}</strong></template
        >
      </multiselect> -->
      <!-- <button
        type="button"
        class="btn btn-warning btn-lg"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button> -->
    </nav>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Дүнгийн нэгтгэл</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0">
        <!-- <table>
          <thead>
            <tr>
              <th style="width: 50px"></th>
              <th
                v-for="(column, columnIndex) in niitHicheel"
                :key="columnIndex"
              >
                {{ column.subject_short_name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(studentName, rowIndex) in myClassStudentsGrades"
              :key="rowIndex"
            >
              <td>
                {{ studentName.profile_student_last_name[0].toUpperCase()
                }}{{ studentName.profile_student_last_name[1] }}.{{
                  studentName.profile_student_name
                }}
              </td>
              <td
                v-for="(column, columnIndex) in niitHicheel"
                :key="columnIndex"
              >
                <span v-for="(record, indexRow) in records" :key="indexRow">
                  <span
                    v-show="
                      column.grade_course_id === record.grade_course_id &&
                      column.subject_id === record.subject_id &&
                      studentName.grade_student_id === record.grade_student_id
                    "
                    >{{ record["grade_exam"] }}</span
                  >
                </span>
              </td>
            </tr>
          </tbody>
        </table> -->
        <div>
          <b-overlay :show="isLoad" rounded="sm">
            <vue-table-dynamic :params="params" ref="table"></vue-table-dynamic>
          </b-overlay>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isLoad: false,
      quarter_id: null,
      mySchoolQuarters: [],
      mySchoolClasses: [],
      school_class_id: null,

      //MyTable
      records: [], //нийт сурагч, дүн, хичээлийн дугаар
      recordssss: [],
      myClassStudentsGrades: [], //Мөрийн дагуу хэвлэх нийт суралцагчид
      niitHicheel: [], //Баганы дагуу хэвлэх хичээлүүд

      //Тестлэх шалгах
      params: {
        data: [],
        header: "row",
        border: true,
        stripe: true,
        enableSearch: true,
        // sort: [0, 1, 2, 3],
        columnWidth: [
          { column: 0, width: 30 },
          { column: 1, width: 120 },
        ],
        fixed: 1,
      },
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    quarter_id: function () {
      if (this.school_class_id) this.getMyClassAllCourseGradeDatas();
    },
    school_class_id: function () {
      if (this.quarter_id) this.getMyClassAllCourseGradeDatas();
    },
  },
  methods: {
    getMyClassAllCourseGradeDatas() {
      this.isLoad = true;
      axios
        .post("/teacher/myClassAllCourseGradeDatas", {
          quarter_id: this.quarter_id.quarter_id,
          classId: this.school_class_id.id,
        })
        .then((response) => {
          // console.log(response.data);
          this.records = response.data.myClassAllCoursesGrades;
          //   console.log(this.records);
          this.myClassStudentsGrades =
            response.data.myClassAllCoursesGradesStudentNameDatas;
          //   console.log(this.myClassStudentsGrades);
          this.niitHicheel =
            response.data.myClassAllCoursesGradesSubjectNameDatas;
          this.params.data = response.data.arrTable;
          //   console.log(response.data.studentGrade["grade_exam"]);
          this.isLoad = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getMyClassStudentCoursesGrade() {
      axios
        .get("/teacher/mySchoolQuarters")
        .then((response) => {
          this.mySchoolQuarters = response.data.mySchoolQuarter;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
  },
  created() {
    axios
      .get("/teacher/getMyClasses")
      .then((res) => {
        this.mySchoolClasses = res.data.schoolclasses;
        this.mySchoolClasses.length > 0
          ? (this.school_class_id = this.mySchoolClasses[0])
          : null;
      })
      .catch((err) => {
        console.log(err);
      });
    this.getMyClassStudentCoursesGrade();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
