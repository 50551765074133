<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#book" data-toggle="tab"
                >Номын худалдаа</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#myBook" data-toggle="tab"
                >Миний оруулсан ном</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="book">
              <div class="card-header">
                <b-row>
                  <b-col sm="3">
                    <b-form-group
                      label="Хүүхэд:"
                      label-for="id"
                      label-cols-sm="3"
                      label-align-sm="right"
                    >
                      <b-form-select
                        v-model="childId"
                        :options="childOptions"
                        text-field="student_name"
                        value-field="id"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('id'),
                          },
                        ]"
                        @input="getSalesBooks()"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-overlay :show="bookIsLoaded" rounded="sm">
                <b-card-group deck class="p-3">
                  <div
                    class="col-lg-3 mb-3"
                    v-for="book in books"
                    :key="book.id"
                  >
                    <div class="card-container">
                      <div class="box">
                        <img
                          :src="book.photo_url"
                          alt="Avatar"
                          class="image-card"
                        />
                        <div
                          class="ribbon ribbon-top-right"
                          v-if="
                            book.buyers.filter((el) => el.buyer_id == myId)
                              .length > 0
                          "
                        >
                          <span><small>Хүсэлт илгээсэн</small></span>
                        </div>
                      </div>
                      <div
                        class="overlay text-center"
                        v-if="
                          book.buyers.filter((el) => el.buyer_id == myId)
                            .length == 0
                        "
                      >
                        <div class="hoverText">
                          <small>
                            {{ book.description }}
                            <div class="hoverBtn">
                              <b-button
                                size="sm"
                                class="mt-2"
                                href="#"
                                variant="light"
                                @click.prevent="createBookUser(book)"
                                >Хүсэлт илгээх</b-button
                              >
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">
                          <small class="text-muted">
                            {{ book.name.slice(0, 30) }}
                          </small>
                        </h5>
                        <p
                          class="card-text"
                          style="
                            text-align: justify;
                            height: 0.8rem;
                            word-break: break-all;
                          "
                        >
                          <small
                            class="text-muted d-flex justify-content-between"
                          >
                            <span>
                              Анги:
                              <b>
                                <span> {{ book.class_number }} -р анги </span>
                              </b>
                            </span>
                            <span>
                              Үнэ:
                              <b>
                                <span>
                                  {{ book.price }}
                                </span>
                              </b>
                            </span>
                          </small>
                        </p>
                      </div>
                      <div class="card-footer pt-0">
                        <small class="text-muted">
                          <i class="far fa-calendar-check ml-2"></i>
                          <b>{{ book.created_at | dateYearMonthDay }}</b>
                        </small>
                      </div>
                    </div>
                  </div>
                </b-card-group>
              </b-overlay>
            </div>
            <div class="tab-pane" id="myBook">
              <div class="card-header">
                <b-row>
                  <b-col sm="3">
                    <b-form-group
                      label="Ангиар шүүх:"
                      label-for="class_number"
                      label-cols-sm="5"
                      label-align-sm="right"
                    >
                      <b-form-select
                        v-model="classNumber"
                        :options="classNumbers"
                        text-field="class_text"
                        value-field="class_number"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('class_number'),
                          },
                        ]"
                        @input="getMySalesBooks()"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-button
                      size="sm"
                      class="float-right"
                      variant="success"
                      @click.prevent="createbook()"
                      >Ном бүртгэх</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <b-modal
                size="xl"
                id="modal-Books"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                :title="editMode === false ? 'Ном бүртгэх' : 'Ном засах'"
                class="modal fade"
                hide-footer
                @hide="cancelBook"
              >
                <div class="modal-body">
                  <form
                    class="form-horizontal"
                    wtx-context="9944B6A2-0454-4588-82D8-836F0401FA6F"
                  >
                    <div>
                      <b-alert show variant="warning">
                        <h4 class="alert-heading">Анхаарах зүйлс!</h4>
                        <p>
                          Сурах бичгийн хавтас гэмтэлгүй байх, Суралцагчийн
                          нэрийг арилгах, Сурах бичгийн хуудас бүрэн байх, Сурах
                          бичиг дээр бичсэн зүйлсийг бүрэн арилгах, Work book
                          дээр бичиж тэмдэглснээ цэвэрхэн арилгаж дахин хэрэглэх
                          боломжтой болгосон байх
                        </p>
                        <hr />
                        <p class="mb-0">
                          Та зар оруулахдаа анхаарах зүйлсийг зөрчихгүй байж
                          бусдад хүндрэл учруулахгүй байхыг хүсье.
                        </p>
                      </b-alert>
                    </div>
                    <div class="form-group row">
                      <label for="class_number" class="col-sm-3 col-form-label"
                        >Анги:</label
                      >
                      <div class="col-sm-9">
                        <b-select
                          v-model="form.class_number"
                          value-field="class_number"
                          text-field="class_text"
                          placeholder="Хэддүгээр ангид хамааралтай ном болохыг сонгоно уу"
                          :options="classNumbers"
                          @input="getLibraryBooks()"
                        >
                        </b-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-sm-3 col-form-label"
                        >Номны нэр:</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="form.name"
                          deselect-label="Can't remove this value"
                          track-by="id"
                          label="name"
                          placeholder="Хэддүгээр ангид хамааралтай ном болохыг сонгоно уу"
                          :options="libraryBooks"
                          :searchable="true"
                          :allow-empty="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.name }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="price" class="col-sm-3 col-form-label"
                        >Үнэ:</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          placeholder="Номны үнэ"
                          wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                          v-model="form.price"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-sm-3 col-form-label"
                        >Тайлбар:</label
                      >
                      <div class="col-sm-9">
                        <b-form-textarea
                          id="textarea"
                          v-model="form.description"
                          placeholder="Номтой холбоотой дэлгэрэнгүй мэдээлэл..."
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="budget_images_1"
                        class="col-sm-3 col-form-label"
                        >Номны зураг</label
                      >
                      <div class="col-sm-9">
                        <div class="row">
                          <span class="col-sm-2 direct-chat-name float-left">
                            <img
                              v-show="imgLoaded"
                              class="profile-user-img img-fluid attachment-img mr-2"
                              :src="imgUrl"
                              alt="Төслийн зураг"
                              accept="image/*"
                              v-if="imgUrl !== ''"
                            />
                            <img
                              class="profile-user-img img-fluid attachment-img"
                              :src="defaultimage"
                              alt="Багшийн зураг"
                              accept="image/*"
                              v-else
                            />
                          </span>
                          <b-form-file
                            id="photo_url"
                            browse-text="Зураг сонгох"
                            class="col-sm-5"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('photo_url'),
                              },
                            ]"
                            @change="getImg"
                          ></b-form-file>
                          <span class="col-sm-5">
                            {{ imgError }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer justify-content-between">
                  <b-button
                    @click="storeBook()"
                    v-if="!editMode"
                    variant="success"
                  >
                    Хадгалах
                  </b-button>
                  <b-button
                    @click="updateBook()"
                    v-if="editMode"
                    variant="warning"
                  >
                    Засах
                  </b-button>
                  <b-button @click="cancelBook()" variant="danger">
                    Хаах
                  </b-button>
                </div>
              </b-modal>
              <b-overlay :show="bookIsLoaded" rounded="sm">
                <b-card-group deck class="p-3">
                  <div
                    class="col-lg-3 mb-3"
                    v-for="book in myBooks"
                    :key="book.id"
                  >
                    <div class="ribbon ribbon-bottom-right"></div>
                    <div class="card-container">
                      <div class="box">
                        <div
                          class="ribbon ribbon-top-right"
                          v-if="book.buyer_id != null"
                        >
                          <span>ЗАРСАН</span>
                        </div>
                        <img
                          :src="book.photo_url"
                          alt="Avatar"
                          class="image-card card-img"
                        />
                        <b-badge
                          variant="light"
                          class="buyers"
                          @click="showUsers(book)"
                          role="button"
                        >
                          Хүсэлт илгээсэн: {{ book.buyers.length }}
                        </b-badge>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">
                          <small class="text-muted">
                            {{ book.name.slice(0, 30) }}
                          </small>
                        </h5>
                        <p
                          class="card-text"
                          style="
                            text-align: justify;
                            height: 0.8rem;
                            word-break: break-all;
                          "
                        >
                          <small
                            class="text-muted d-flex justify-content-between"
                          >
                            <span>
                              Анги:
                              <b>
                                <span> {{ book.class_number }} -р анги </span>
                              </b>
                            </span>
                            <span>
                              Үнэ:
                              <b>
                                <span>
                                  {{ book.price }}
                                </span>
                              </b>
                            </span>
                          </small>
                        </p>
                      </div>
                      <div class="card-footer">
                        <small class="text-muted">
                          <i class="far fa-calendar-check ml-2"></i>
                          <b>{{ book.created_at | dateYearMonthDay }}</b>
                        </small>
                        <b-badge
                          variant="light"
                          class="float-right"
                          v-if="book.buyer_id == null"
                        >
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editBook(book)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="removeBook(book)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </b-card-group>
              </b-overlay>
              <b-modal
                size="sl"
                id="modal-Users"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                title="Үзсэн эцэг эхийн мэдээлэл"
                class="modal fade"
                hide-footer
                @hide="cancelUsers"
              >
                <div
                  class="card card-primary"
                  style="
                    transition: all 0.15s ease 0s;
                    height: inherit;
                    width: inherit;
                  "
                >
                  <!-- /.card-header -->
                  <div class="card-body" style="overflow-x: auto">
                    <b-alert show variant="warning">
                      <h4 class="alert-heading">Мэдээлэл</h4>
                      <p>
                        Хэрвээ та аль нэг эцэг эхтэй холбогдон номоо зарсан бол
                        тухайн эцэг эхийн мэдээлэлийн ард байрлах зарсан гэсэн
                        товч дээр дарна уу баярлалаа.
                      </p>
                    </b-alert>
                    <table class="table table-hover table-sm">
                      <thead>
                        <tr>
                          <th style="width: 10px">#</th>
                          <th>Нэр</th>
                          <th>Утас</th>
                          <th>Үйлдэл</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(buyer, i) in buyers"
                          :key="i"
                          :class="{
                            'bg-success': buyer.child_id == form.buyer_id,
                          }"
                        >
                          <td>{{ ++i }}</td>
                          <td v-if="buyer">{{ buyer.fatherName }}</td>
                          <td v-if="buyer">{{ buyer.fatherPhone }}</td>
                          <td>
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="soldBook(buyer)"
                              role="button"
                              >Зарсан</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- /.card-body -->
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <b-button @click="cancelUsers()" variant="danger">
                    Хаах
                  </b-button>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      bookIsLoaded: false,
      quarterOptions: [],
      books: [],
      buyers: [],
      myBooks: [],
      childId: "",
      myId: "",
      salesBookId: "",
      childOptions: [],
      editMode: false,
      classNumber: "",
      libraryBooks: [],
      classNumbers: [
        { class_number: "1", class_text: "1-р анги" },
        { class_number: "2", class_text: "2-р анги" },
        { class_number: "3", class_text: "3-р анги" },
        { class_number: "4", class_text: "4-р анги" },
        { class_number: "5", class_text: "5-р анги" },
        { class_number: "6", class_text: "6-р анги" },
        { class_number: "7", class_text: "7-р анги" },
        { class_number: "8", class_text: "8-р анги" },
        { class_number: "9", class_text: "9-р анги" },
        { class_number: "10", class_text: "10-р анги" },
        { class_number: "11", class_text: "11-р анги" },
        { class_number: "12", class_text: "12-р анги" },
      ],
      imgUrl: "",
      form: new Form({
        id: "",
        class_number: null,
        name: null,
        description: "",
        price: "",
        buyer_id: "",
        photo_url: null,
      }),
      defaultimage: "/images/users/user.png",
      imgError: "",
      imgLoaded: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getMyChilds() {
      axios
        .get("/parent/getMyChilds")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0)
            this.childId = this.childOptions[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLibraryBooks() {
      axios
        .get("/parent/getLibraryBooks/" + this.form.class_number)
        .then((response) => {
          this.libraryBooks = response.data.libraryBooks;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSalesBooks() {
      this.bookIsLoaded = true;
      axios
        .post("/parent/getSalesBooks", {
          id: this.childId,
        })
        .then((response) => {
          this.books = response.data.books;
          this.myId = response.data.myId;
          this.bookIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMySalesBooks() {
      this.bookIsLoaded = true;
      axios
        .post("/parent/getMySalesBooks", {
          class_number: this.classNumber,
        })
        .then((response) => {
          this.myBooks = response.data.myBooks;
          this.myId = response.data.myId;
          this.bookIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createbook() {
      this.form.reset();
      this.imgUrl = "";
      this.$bvModal.show("modal-Books");
    },
    showUsers(book) {
      this.salesBookId = book.id;
      this.form.reset();
      this.form.fill(book);
      this.buyersInfo();
      this.$bvModal.show("modal-Users");
    },
    buyersInfo() {
      axios
        .get("/parent/buyersInfo/" + this.salesBookId)
        .then((response) => {
          this.buyers = response.data.buyers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    soldBook(buyer) {
      axios
        .put("/parent/soldBook", {
          buyerId: buyer.child_id,
          soldBookId: buyer.soldBookId,
        })
        .then((response) => {
          this.$toastr.s("Амжилттай зарлаа");
          this.$bvModal.hide("modal-Users");
          Fire.$emit("loadMyBooks");
        })
        .catch((errors) => {
          console.error(errors);
        });
    },
    cancelUsers() {
      this.$bvModal.hide("modal-Users");
    },
    storeBook() {
      this.form
        .post("/parent/createbook")
        .then((response) => {
          this.$toastr.s("Ном амжилттай бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadMyBooks");
          this.libraryBooks = [];
          this.cancelBook();
        })
        .catch(() => {
          this.$toastr.e(
            "Ном бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelBook() {
      this.$bvModal.hide("modal-Books");
      this.form.reset();
      this.editMode = false;
    },
    getImg(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError =
          "1024 x 1024 пикселээс бага хэмжээтэй зураг оруулна уу!";
        return;
      } else this.imgError = "";
      //   console.log(img);
      this.readImage(img);
    },
    readImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.form.photo_url = e.target.result;
        this.imgUrl = e.target.result;
      };
      this.imgLoaded = true;
    },
    createBookUser(book) {
      axios
        .post("/parent/createBookUser", {
          id: book.id,
          saler_id: book.saler_id,
          childId: this.childId,
          name: book.name,
        })
        .then((response) => {
          if (response.data.errMsj == "") {
            this.$toastr.s("Хүсэлт амжилттай илгээлээ", "Бүртгэлээ");
          } else this.$toastr.e("Та хүсэлт илгээсэн байна!");
          Fire.$emit("loadBooks");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editBook(book) {
      this.imgUrl = book.photo_url;
      this.editMode = true;
      this.form.reset();
      this.form.fill(book);
      this.form.class_number = {
        class_number: book.class_number,
        class_text: `${book.class_number}-р анги`,
      };
      this.imgLoaded = true;
      this.form.photo_url = null;
      this.$bvModal.show("modal-Books");
    },
    updateBook() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/parent/bookUpdate/" + this.form.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.form.reset();
          this.imgUrl = "";
          this.editMode = !this.editMode;
          this.$bvModal.hide("modal-Books");
          Fire.$emit("loadMyBooks");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeBook(book) {
      Swal.fire({
        title: "Энэ номыг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Номын зарыг устгаж байна...";
          axios
            .get("/parent/removeBook/" + book.id)
            .then(() => {
              this.$toastr.s("Номыг амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("loadMyBooks");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },
  created() {
    this.getMySalesBooks();
    this.getMyChilds();
    Fire.$on("loadMyBooks", () => {
      this.getMySalesBooks();
    });
    Fire.$on("loadBooks", () => {
      this.getSalesBooks();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
dd {
  padding-left: 20px;
}
.buyers {
  position: absolute;
  bottom: 5px;
  right: 15px;
}
</style>
