<template>
  <div class="col-12 pt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <multiselect
        class="mr-2"
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="1-р алхам-Улирал сонголт"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="department_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="department_id"
        label="department_name"
        placeholder="2-р алхам-Мэргэжлийн баг сонголт"
        :options="mySchoolsDepartments"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.department_name }} </strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="employees_user_id"
        label="teacher_name"
        :custom-label="customLabelTeacherName"
        placeholder="3-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.teacher_last_name[0]
            }}{{ option.teacher_last_name[1] }}.{{
              option.teacher_name
            }}</strong
          ></template
        >
      </multiselect>
      <!-- <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="4-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect> -->
      <!-- <multiselect
        class="mr-2"
        v-model="exam_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="exam_id"
        label="name"
        :custom-label="customLabelExam"
        placeholder="5-р алхам-Шалгалт сонголт"
        :options="mySchoolCoursesExam"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.exam_name }}</strong></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button> -->
    </nav>
    <div class="card">
      <div class="card-body table-responsive p-0">
        <table
          class="table table-hover text-nowrap"
          v-if="mySchoolCourses.length"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Багш</th>
              <th>Анги</th>
              <th>Хичээл</th>
              <th>Хичээлийн төрөл</th>
              <th>Үнэлгээ</th>
              <th>Үйлдлүүд</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(myCourse, index) in mySchoolCourses" :key="myCourse.id">
              <td>{{ index + 1 }}</td>
              <td>
                {{ myCourse.teacher_last_name[0].toUpperCase() }}.{{
                  myCourse.user_teacher_name
                }}
              </td>
              <td>{{ myCourse.school_class_full_name }}</td>
              <td>{{ myCourse.subject_name }}</td>
              <td>{{ myCourse.LessonType }}</td>
              <td>{{ myCourse.course_percent }}%</td>
              <td>
                <!-- <button
                type="button"
                class="btn btn-primary btn-xs"
                @click.prevent="selectCourse(myCourse)"
                v-b-popover.hover="
                  'Багш өөрийн зохиосон шалгалтанд дүн оруулах бол энд дарна уу.'
                "
                title="Шалгалт"
              >
                <i class="fas fa-chalkboard-teacher"></i>
              </button>
              <button
                type="button"
                class="btn btn-success btn-xs"
                @click.prevent="getAttendance(myCourse)"
                v-b-popover.hover="
                  'Хичээлд өдөр бүр ирц бүртгэхийг анхаарна уу?'
                "
                title="Ирц"
              >
                <b-icon icon="alarm" aria-hidden="true"></b-icon>
              </button>
              <button
                type="button"
                class="btn btn-info btn-xs"
                @click.prevent="myCourseExamGradeFinalQuarter(myCourse)"
              >
                <i class="fas fa-chart-line"></i>
              </button>
              <button
                type="button"
                class="btn btn-warning btn-xs"
                @click.prevent="courseCommentForStudent(myCourse)"
              >
                <b-iconstack font-scale="1" variant="white">
                  <b-icon stacked icon="square-fill" variant="dark"></b-icon>
                  <b-icon
                    stacked
                    icon="arrow-up-short"
                    scale="0.5"
                    shift-v="3"
                    shift-h="-3"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="arrow-up-short"
                    scale="0.5"
                    shift-v="3"
                    shift-h="3"
                    rotate="90"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="arrow-up-short"
                    scale="0.5"
                    shift-v="-3"
                    shift-h="3"
                    rotate="180"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="arrow-up-short"
                    scale="0.5"
                    shift-v="-3"
                    shift-h="-3"
                    rotate="270"
                  ></b-icon>
                </b-iconstack>
              </button> -->
                <button
                  type="button"
                  class="btn btn-danger btn-xs"
                  v-b-popover.hover="
                    'Хичээл орох бүрд гэрийн даалгавар бүртгэх, Суралцагчийн гэрийн даалгаварын гүйцэтгэлийг харуулахаас гадна, эцэг эх танилцаж хүүхдийнхээ гэрийн даалгаврын явцыг хянах боломжтой болно.'
                  "
                  title="Гэрийн даалгавар"
                  @click.prevent="courseHomeworkForStudent(myCourse)"
                >
                  <i class="fas fa-house-user"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Багшийн зүгээс гэрийн даалгавар оруулах -->
      <div class="row" v-if="toStudentForHW">
        <div class="col-12 mt-1">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Ангийн гэрийн даалгаврын мэдээлэл</h3>

              <div class="card-tools"></div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-12">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Анги</th>
                        <th>
                          Хичээл
                          <!-- <button
                            type="button"
                            class="btn btn-info btn-xs"
                            @click.prevent="createHW()"
                          >
                            <i class="fas fa-chart-line"></i> Гэрийн даалгавар
                            бүртгэх
                          </button> -->
                        </th>
                        <th>Улирал</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>
                          {{ selectedSchoolClassHW.school_class_full_name }}
                        </td>
                        <td>
                          {{ selectedSchoolClassHW.subject_name }}
                        </td>
                        <td>
                          <b-form-select
                            :options="mySchoolQuarters"
                            value-field="quarter_id"
                            text-field="quarter_name"
                            size="sm"
                            @change="changeQuarterHW"
                          ></b-form-select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Улирал</th>
                        <th>Гэрийн даалгавар гүйцэтгэх огноо</th>
                        <th>Хичээл орсон цаг</th>
                        <th>Гүйцэтгэл</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(thw, index) in teachersHomeWorks"
                        :key="index"
                      >
                        <td>{{ index + 1 }}.</td>
                        <td>{{ thw.my_home_work_quarter.name }}-р улирал</td>
                        <td>
                          {{ thw.course_hw_start_date }} :
                          {{ thw.course_hw_start_time }} -
                          {{ thw.course_hw_end_date }} :
                          {{ thw.course_hw_end_time }}
                        </td>
                        <td>{{ thw.course_hour }}</td>
                        <td>{{ thw.hw_grade_avg }}%</td>
                        <td>
                          <a
                            href="#"
                            class="badge bg-info"
                            @click="moreInfoHW(thw)"
                            ><i class="fas fa-low-vision"></i
                          ></a>
                          <!-- <a
                            href="#"
                            class="badge bg-warning"
                            @click="editHW(thw)"
                            ><i class="fas fa-edit"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteHW(thw)"
                            ><i class="fas fa-trash-alt"></i
                          ></a> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- loadingHw -->

                  <b-spinner
                    variant="primary"
                    label="Spinning"
                    v-if="loadingHw"
                  ></b-spinner>
                  <!-- <loading :loading="loadingHw"></loading> -->
                  <!-- Гэрийн даалгавар бүртгэх, засах -->
                  <!-- <b-modal
                    size="xl"
                    id="modal-homework"
                    :header-bg-variant="headerBgVariant"
                    :header-text-variant="headerTextVariant"
                    :body-bg-variant="bodyBgVariant"
                    :title="
                      editModeHW
                        ? 'ГЭРИЙН ДААЛГАВРЫН МЭДЭЭЛЭЛ: Засах(Аль болох зураг оруулахгүй байх)'
                        : 'ГЭРИЙН ДААЛГАВРЫН МЭДЭЭЛЭЛ: Бүртгэх(Аль болох зураг оруулахгүй байх)'
                    "
                    class="modal fade"
                    hide-footer
                    @hidden="resetHW"
                  >
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-lg-12 mt-2">
                          <div class="card card-primary">
                            <form
                              @submit.prevent="
                                !editModeHW ? storeHW() : updateHW()
                              "
                            >
                              <div class="card-body">
                                <div class="form-row">
                                  <div class="form-group col-lg-2">
                                    <label>Улирал</label>
                                    <b-form-select
                                      v-model="homeworkForm.quarter_id"
                                      :options="mySchoolQuarters"
                                      text-field="name"
                                      value-field="id"
                                      :class="[
                                        'form-control',
                                        {
                                          'is-invalid':
                                            homeworkForm.errors.has('id'),
                                        },
                                      ]"
                                    ></b-form-select>
                                  </div>
                                  <div class="form-group col-lg-2">
                                    <label>Хичээл орсон цаг</label>
                                    <b-form-select
                                      v-model="homeworkForm.course_hour"
                                      :options="attendance_lesson_numbers"
                                      text-field="text"
                                      value-field="value"
                                      :class="[
                                        'form-control',
                                        {
                                          'is-invalid':
                                            homeworkForm.errors.has('value'),
                                        },
                                      ]"
                                    ></b-form-select>
                                  </div>
                                  <div class="form-group col-lg-4">
                                    <label>Даалгавар эхлэх огноо</label>
                                    <b-form-datepicker
                                      v-model="
                                        homeworkForm.course_hw_start_date
                                      "
                                      class="mb-2"
                                      :class="['form-control']"
                                      placeholder="Даалгавар эхлэх огноо"
                                    ></b-form-datepicker>
                                  </div>
                                  <div class="form-group col-lg-4">
                                    <label>Даалгавар хаагдах огноо</label>
                                    <b-form-datepicker
                                      v-model="homeworkForm.course_hw_end_date"
                                      class="mb-2"
                                      :class="[
                                        'form-control',
                                        {
                                          'is-invalid':
                                            homeworkForm.errors.has(
                                              'course_hw_end_date'
                                            ),
                                          'is-valid': hwEndDate,
                                        },
                                      ]"
                                      placeholder="Даалгавар хаагдах огноо"
                                    ></b-form-datepicker>
                                    <span>
                                      {{ errCloseDate }}
                                    </span>
                                  </div>
                                  <div class="col-lg-4"></div>
                                  <div class="form-group col-lg-4">
                                    <label>Эхлэх цаг</label>
                                    <b-form-timepicker
                                      v-model="
                                        homeworkForm.course_hw_start_time
                                      "
                                      v-bind="labels['de']"
                                      locale="de"
                                    ></b-form-timepicker>
                                  </div>
                                  <div class="form-group col-lg-4">
                                    <label>Дуусах цаг</label>
                                    <b-form-timepicker
                                      v-model="homeworkForm.course_hw_end_time"
                                      v-bind="labels['de']"
                                      locale="de"
                                    ></b-form-timepicker>
                                  </div>
                                  <div class="form-group col-lg-12">
                                    <label
                                      >Гэрийн даалгаварын дэлгэрэнгүй
                                      мэдээлэл</label
                                    >
                                    <quill-editor
                                      ref="myQuillEditor"
                                      v-model="homeworkForm.hw"
                                      :options="editorOption"
                                      style="height: 250px"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="card-footer">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  v-show="!editModeHW"
                                >
                                  Хадгалах
                                </button>
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  v-show="editModeHW"
                                >
                                  Засах
                                </button>
                                <button
                                  class="btn btn-secondary"
                                  @click.prevent="cancelHW"
                                >
                                  Болих
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal> -->
                  <!-- Гэрийн даалгаврын гүйцэтгэл суралцагч дээр нэмэх -->
                  <b-modal
                    size="xl"
                    id="modal-homework-student"
                    :header-bg-variant="headerBgVariant"
                    :header-text-variant="headerTextVariant"
                    :body-bg-variant="bodyBgVariant"
                    title="Гэрийн даалгаврын гүйцэтгэл"
                    class="modal fade"
                    hide-footer
                    @hidden="resetFormHwGradeStudent"
                  >
                    <div class="modal-body">
                      <div class="direct-chat-msg">
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-left">
                            Гэрийн даалгавар/Homework</span
                          >
                          <span class="direct-chat-timestamp float-right"
                            ><b>Даалгавар эхлэх огноо: </b
                            >{{
                              selectHWStudents.course_hw_start_date
                                | dateYearMonthDay
                            }}
                            -
                            {{ selectHWStudents.course_hw_start_time }}
                            цаг</span
                          ><br />
                          <span class="direct-chat-timestamp float-right"
                            ><b>Даалгавар дуусах огноо: </b>
                            {{
                              selectHWStudents.course_hw_end_date
                                | dateYearMonthDay
                            }}
                            -
                            {{ selectHWStudents.course_hw_end_time }} цаг</span
                          >
                        </div>
                        <div class="direct-chat-text primary ml-0">
                          <quill-editor
                            v-model="selectHWStudents.hw"
                            :options="editorOptionStudent"
                          >
                          </quill-editor>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8">
                          <!-- <div class="row">
                            <b-col sm="3">
                              <span
                                class="direct-chat-name float-right"
                                style="font-size: 80%"
                              >
                                Бүх суралцагчид дээр дүн бүртгэх:</span
                              >
                            </b-col>

                            <b-col sm="5">
                              <b-form-input
                                id="hw_grade"
                                type="number"
                                size="sm"
                                v-model="formHwGradeStudent.hw_grade"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formHwGradeStudent.errors.has('hw_grade'),
                                  },
                                ]"
                                placeholder="Гүйцэтгэлийн оноо %-аар тавих"
                              ></b-form-input>
                            </b-col>
                            <b-col sm="4">
                              <span class="direct-chat-timestamp">
                                <b-button
                                  size="sm"
                                  variant="outline-primary"
                                  @click.prevent="
                                    addGradeClick(selectHWStudents)
                                  "
                                >
                                  <b-icon icon="plus-circle-fill"></b-icon>
                                  Гүйцэтгэл бүртгэх
                                </b-button>
                              </span></b-col
                            >
                          </div> -->
                          <div class="card">
                            <div class="card-header"></div>
                            <div class="card-body table-responsive">
                              <table class="table text-wrap">
                                <thead>
                                  <tr role="row">
                                    <th>#</th>
                                    <th>Зураг</th>
                                    <th>Суралцагч</th>
                                    <th>Гүйцэтгэл</th>
                                    <th>Тайлбар</th>
                                    <!-- <th>Үйлдэл</th> -->
                                  </tr>
                                </thead>
                                <tbody
                                  v-for="(
                                    hwInfo, index
                                  ) in selectHWStudents.my_home_work_students"
                                  :key="hwInfo.id"
                                >
                                  <tr v-if="hwInfo.my_profile">
                                    <td
                                      class="dtr-control sorting_1"
                                      tabindex="0"
                                    >
                                      {{ index + 1 }}
                                    </td>
                                    <td
                                      class="dtr-control sorting_1"
                                      tabindex="0"
                                    >
                                      <img
                                        v-if="
                                          hwInfo.my_profile
                                            .student_profile_photo_url == null
                                        "
                                        src="/images/users/user.png"
                                        alt="user-avatar"
                                        class="direct-chat-img"
                                      />
                                      <img
                                        v-else
                                        :src="
                                          hwInfo.my_profile
                                            .student_profile_photo_url
                                        "
                                        alt="user-avatar"
                                        class="direct-chat-img"
                                      />
                                    </td>
                                    <td
                                      class="dtr-control sorting_1"
                                      tabindex="0"
                                    >
                                      {{
                                        hwInfo.my_profile.student_last_name[0]
                                      }}.
                                      {{ hwInfo.my_profile.student_name }}
                                      <b-spinner
                                        v-if="hwInfo.hw_explain != null"
                                        variant="primary"
                                        key="primary"
                                        type="grow"
                                      ></b-spinner>
                                      <b-spinner
                                        v-if="hwInfo.hw_parent_comment != null"
                                        variant="info"
                                        key="info"
                                        type="grow"
                                      ></b-spinner>
                                    </td>
                                    <td
                                      class="dtr-control sorting_1"
                                      style="text-align: center"
                                      tabindex="0"
                                    >
                                      <b-badge
                                        v-if="hwInfo.hw_grade >= 80"
                                        variant="success"
                                        v-show="hwInfo.hw_grade != null"
                                      >
                                        {{ hwInfo.hw_grade }}</b-badge
                                      >
                                      <b-badge
                                        v-if="
                                          (hwInfo.hw_grade >= 60) &
                                          (hwInfo.hw_grade < 80)
                                        "
                                        variant="primary"
                                        v-show="hwInfo.hw_grade != null"
                                      >
                                        {{ hwInfo.hw_grade }}</b-badge
                                      >
                                      <b-badge
                                        v-if="
                                          (hwInfo.hw_grade >= 30) &
                                          (hwInfo.hw_grade < 60)
                                        "
                                        variant="warning"
                                        v-show="hwInfo.hw_grade != null"
                                      >
                                        {{ hwInfo.hw_grade }}</b-badge
                                      >
                                      <b-badge
                                        v-if="hwInfo.hw_grade < 30"
                                        variant="danger"
                                        v-show="hwInfo.hw_grade != null"
                                      >
                                        {{ hwInfo.hw_grade }}</b-badge
                                      >
                                      <b-badge
                                        variant="warning"
                                        v-show="hwInfo.hw_grade === null"
                                      >
                                        0</b-badge
                                      >
                                      <div
                                        class="progress progress-xs progress-striped active"
                                      >
                                        <div
                                          v-if="hwInfo.hw_grade < 30"
                                          class="progress-bar bg-danger"
                                          :style="{
                                            width: `${hwInfo.hw_grade}%`,
                                          }"
                                        ></div>
                                        <div
                                          v-if="
                                            (hwInfo.hw_grade >= 30) &
                                            (hwInfo.hw_grade < 60)
                                          "
                                          class="progress-bar bg-warning"
                                          :style="{
                                            width: `${hwInfo.hw_grade}%`,
                                          }"
                                        ></div>
                                        <div
                                          v-if="
                                            (hwInfo.hw_grade >= 60) &
                                            (hwInfo.hw_grade < 80)
                                          "
                                          class="progress-bar bg-primary"
                                          :style="{
                                            width: `${hwInfo.hw_grade}%`,
                                          }"
                                        ></div>
                                        <div
                                          v-if="hwInfo.hw_grade >= 80"
                                          class="progress-bar bg-success"
                                          :style="{
                                            width: `${hwInfo.hw_grade}%`,
                                          }"
                                        ></div>
                                      </div>
                                    </td>
                                    <td
                                      class="dtr-control sorting_1"
                                      tabindex="0"
                                    >
                                      <b-icon
                                        icon="eye"
                                        variant="info"
                                        @click="moreHwGradeStudent(hwInfo)"
                                      >
                                      </b-icon>
                                    </td>
                                    <!-- <td
                                      class="dtr-control sorting_1"
                                      tabindex="0"
                                    >
                                      <a
                                        href="#"
                                        class="badge bg-primary"
                                        @click="editHwGradeStudent(hwInfo)"
                                        ><i class="fa fa-check"></i
                                      ></a>
                                      <a
                                        href="#"
                                        class="badge bg-danger"
                                        @click="removeHwGradeStudent(hwInfo)"
                                        ><i class="fa fa-minus"></i
                                      ></a>
                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mt-2">
                          <!-- <div
                            class="card card-primary"
                            v-show="editModeHwGrade"
                          >
                            <div class="card-header">
                              <h3 class="card-title">
                                Суралцагчийн гүйцэтгэл оруулах
                              </h3>
                            </div>
                            <div class="card-body">
                              <form>
                                <div class="form-group">
                                  <label>Гүйцэтгэл</label>
                                  <input
                                    type="number"
                                    name="hw_grade"
                                    max="100"
                                    v-model="
                                      formHwGradeSelectedStudent.hw_grade
                                    "
                                    :class="[
                                      'form-control',
                                      {
                                        'is-invalid':
                                          formHwGradeSelectedStudent.errors.has(
                                            'hw_grade'
                                          ),
                                      },
                                    ]"
                                    placeholder="Гүйцэтгэлийн оноо %-аар тавих"
                                  />
                                </div>
                                <div class="form-group">
                                  <b-form-textarea
                                    id="textarea"
                                    v-model="
                                      formHwGradeSelectedStudent.hw_explain
                                    "
                                    placeholder="Гүйцэтгэлийн талаарх тайлбар оруулна уу."
                                    rows="3"
                                    max-rows="6"
                                    :class="[
                                      'form-control',
                                      {
                                        'is-invalid':
                                          formHwGradeSelectedStudent.errors.has(
                                            'hw_explain'
                                          ),
                                      },
                                    ]"
                                  ></b-form-textarea>
                                </div>
                              </form>
                            </div>
                            <div class="card-footer">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                @click.prevent="updateHwGradeStudent()"
                              >
                                Хадгалах
                              </button>
                              <button
                                type="submit"
                                class="btn btn-dark"
                                @click.prevent="cancelHwGradeStudent()"
                              >
                                Болих
                              </button>
                            </div>
                          </div> -->
                          <div class="card card-primary">
                            <b-card-group deck>
                              <b-card
                                header="Гэрийн даалгавраас хасагдсан суралцагчид"
                              >
                                <b-list-group>
                                  <b-list-group-item
                                    class="d-flex justify-content-between align-items-center"
                                    v-for="(
                                      hwInfo, index
                                    ) in selectHWStudents.my_home_work_deleted_students"
                                    :key="hwInfo.id"
                                    >{{ index + 1 }}.
                                    <img
                                      v-if="
                                        hwInfo.my_profile
                                          .student_profile_photo_url == null
                                      "
                                      src="/images/users/user.png"
                                      alt="user-avatar"
                                      class="direct-chat-img" />
                                    <img
                                      v-else
                                      :src="
                                        hwInfo.my_profile
                                          .student_profile_photo_url
                                      "
                                      alt="user-avatar"
                                      class="direct-chat-img" />
                                    {{
                                      hwInfo.my_profile.student_last_name[0]
                                    }}.
                                    {{ hwInfo.my_profile.student_name }}
                                    <b-badge variant="primary" pill>
                                      <b-link href="#">
                                        <b-icon
                                          variant="warning"
                                          pill
                                          icon="person-plus-fill"
                                          @click="restoreHWDStudent(hwInfo)"
                                        ></b-icon>
                                      </b-link> </b-badge
                                  ></b-list-group-item>
                                </b-list-group>
                              </b-card>
                            </b-card-group>
                          </div>

                          <div
                            class="card card-primary"
                            v-show="moreInfoHWShowMode"
                          >
                            <div class="card-header">
                              <h3 class="card-title">
                                Суралцагч, эцэг эх гэрийн даалгаварт оруулсан
                                мэдээлэл
                              </h3>
                            </div>
                            <div class="card-body">
                              <p v-if="moreInfoStudentHW.hw_explain != null">
                                Суралцагч
                              </p>
                              <p v-if="moreInfoStudentHW.hw_explain == null">
                                Суралцагч гэрийн даалгавар оруулаагүй байна.
                              </p>
                              <b-card-group>
                                <b-card-text>
                                  <quill-editor
                                    v-if="moreInfoStudentHW.hw_explain != null"
                                    ref="myQuillEditor"
                                    v-model="moreInfoStudentHW.hw_explain"
                                    :options="editorOption"
                                  />
                                </b-card-text>
                              </b-card-group>
                              <p
                                v-if="
                                  moreInfoStudentHW.hw_parent_comment != null
                                "
                              >
                                Эцэг эх
                              </p>
                              <p
                                v-if="
                                  moreInfoStudentHW.hw_parent_comment == null
                                "
                              >
                                Эцэг эх гэрийн даалгаврын мэдээлэл оруулаагүй
                                байна.
                              </p>
                              <b-card-group>
                                <b-card-text
                                  ><quill-editor
                                    v-if="
                                      moreInfoStudentHW.hw_parent_comment !=
                                      null
                                    "
                                    ref="myQuillEditor"
                                    v-model="
                                      moreInfoStudentHW.hw_parent_comment
                                    "
                                    :options="editorOption"
                                /></b-card-text>
                              </b-card-group>
                            </div>
                            <div class="card-footer">
                              <button
                                type="submit"
                                class="btn btn-dark"
                                @click="moreInfoStudentHWClose"
                              >
                                Хаах
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    // everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      mySchoolQuarters: [],
      mySchoolsDepartments: [],
      mySchoolsTeachers: [],
      mySchoolCourses: [],
      mySchoolCoursesExam: [],
      // Дуудах request
      quarter_id: "",
      department_id: "",
      teacher_id: "",
      course_id: "",
      exam_id: "",
      //Pass to child component
      selectedCourseData: false,
      selectedLessonExamData: [],

      //   Гэрийн даалгавар
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      toStudentForHW: false,
      loadingHw: false,
      editModeHW: false,
      editModeHwGrade: false,
      teachersHomeWorks: [],
      selectedSchoolClassHW: "",
      hw_changeQuarterId: "",
      selectHWStudents: "",

      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",

          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder:
          "Гэрийн даалгавар WWW.GRADING4YOU.COM системээр өгөхдөө АНХААРАХ зүйлс \n Зурган файл хэт ихээр илгээхгүй байх /workbook, coursebook ний зураг илгээхгүй байх/ \n Зааварчилгааг тодорхой өгөх Жишээ нь: Хуудас 156 Дасгал 27Б даалгавар дээр үгсийг хараад өгүүлбэр нөхөөрэй, шинэ үгсийг дэвтэртээ тэмдэглэх, Metaphor family шүлэг цээжлэх /цээжлэх шүлэгний зураг илгээж болно/ \n Үг үсэгний алдааг хянах",
      },
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      homeworkForm: new Form({
        id: "",
        quarter_id: "",
        school_class_id: "",
        department_id: "",
        course_id: "",
        course_type_id: "",
        course_hour: "",
        subject_id: "",
        teacher_id: "",
        reg_emp_id: "",
        student_id: "",
        course_hw_start_date: "",
        course_hw_end_date: "",
        course_hw_start_time: "",
        course_hw_end_time: "",
        hw: "",
      }),
      formHwGradeStudent: new Form({
        id: "",
        course_hw_id: "",
        hw_grade: "",
      }),
      formHwGradeSelectedStudent: new Form({
        id: "",
        course_hw_id: "",
        student_id: "",
        hw_explain: "",
        hw_status: "",
        hw_grade: "",
        hw_parent_comment: "",
      }),
      //   Гэрийн даалгавар суралцагч, эцэг эх мэдээлэл оруулсан хэсгийг харуулах
      moreInfoHWShowMode: false,
      moreInfoStudentHW: "",
    };
  },
  watch: {
    department_id: function (newValue, oldValue) {
      //   console.log("Өөрчлөгдсөн.");
      //   this.selectedCourseData = false;
      //   this.selectedExamData = false;
      //   this.selectedQuarterData = false;
      axios
        .post("/manager/departmentsTeacher", {
          department: this.department_id,
        })
        .then((response) => {
          this.mySchoolsTeachers = [];
          this.mySchoolsTeachers = response.data.mySchoolsTeachers;
          //   this.selectedLessonExamData.forEach((el) => {
          //     el.examAverage =
          //       (parseFloat(el.exam1) +
          //         parseFloat(el.exam2) +
          //         parseFloat(el.exam3) +
          //         parseFloat(el.exam4) +
          //         parseFloat(el.exam5) +
          //         parseFloat(el.exam6) +
          //         parseFloat(el.exam7) +
          //         parseFloat(el.exam8)) /
          //       8;
          //   });
          //   console.log(this.selectedLessonExamData);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    teacher_id: function (newValue, oldValue) {
      axios
        .post("/manager/getMySchoolCourses", {
          teacher: this.teacher_id,
        })
        .then((response) => {
          this.mySchoolCourses = [];
          this.mySchoolCourses = response.data.mySchoolCourses;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // course_id: function (newValue, oldValue) {
    //   axios
    //     .post("/manager/mySchoolCourseExams", {
    //       myCourseData: this.course_id,
    //     })
    //     .then((response) => {
    //       this.mySchoolCoursesExam = [];
    //       this.mySchoolCoursesExam = response.data.mySchoolCoursesExam;
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    // quarter_id: function (newValue, oldValue) {
    //   axios
    //     .post("/manager/mySchoolCourseExamsQuarter", {
    //       myCourseData: this.course_id,
    //       quarter: newValue,
    //       teacher: this.teacher_id,
    //     })
    //     .then((response) => {
    //       this.mySchoolCoursesExam = [];
    //       this.mySchoolCoursesExam = response.data.mySchoolCoursesExam;
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    hw_changeQuarterId(newVal, oldVal) {
      //   console.log(newVal, oldVal);
      this.loadingHw = true;
      this.changeQuarterId = this.quarter_id;
      //   console.log(this.course_id);
      axios
        .post("/manager/selectTeacherQuarterCourseHW", {
          courseInfo: this.selectedSchoolClassHW,
          quarter_id: newVal,
          //   exam_id: this.exam_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          this.loadingHw = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  methods: {
    getMySchoolHWsInfo() {
      axios
        .get("/manager/mySchoolHWsInfo")
        .then((res) => {
          //   console.log(res.data);
          this.quarter_id = res.data.currentQuarter;
          this.mySchoolsDepartments = res.data.mySchoolDepartments;
          this.mySchoolQuarters = res.data.mySchoolsQuarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    // getSelectMyExamData() {
    //   this.selectedCourseData = false;
    //   axios
    //     .post("/manager/mySelectStatData", {
    //       quarter_id: this.quarter_id,
    //       department_id: this.department_id,
    //       teacher_id: this.teacher_id,
    //       course_id: this.course_id,
    //       exam_id: this.exam_id,
    //     })
    //     .then((response) => {
    //       //   console.log(response.data);
    //       this.selectedLessonExamData = [];
    //       this.selectedLessonExamData = response.data.selectedLessonExamData;
    //       this.selectedLessonExamData.forEach((el) => {
    //         el.examAverage =
    //           parseFloat(el.exam1) * 0.1 +
    //           parseFloat(el.exam2) * 0.1 +
    //           parseFloat(el.exam3) * 0.1 +
    //           parseFloat(el.exam4) * 0.1 +
    //           parseFloat(el.exam5) * 0.2 +
    //           parseFloat(el.exam6) * 0.2 +
    //           parseFloat(el.exam7) * 0.1 +
    //           parseFloat(el.exam8) * 0.1;

    //         if (el.examAverage >= 97) {
    //           el.gpa = this.symbolGrade = "A+, GPA 4.33 or 4.00";
    //         } else if (el.examAverage >= 93) {
    //           el.gpa = this.symbolGrade = "A, GPA 4.00";
    //         } else if (el.examAverage >= 90) {
    //           el.gpa = this.symbolGrade = "A-, GPA 3.67";
    //         } else if (el.examAverage >= 87) {
    //           el.gpa = this.symbolGrade = "B+, GPA 3.33";
    //         } else if (el.examAverage >= 83) {
    //           el.gpa = this.symbolGrade = "B, GPA 3.00";
    //         } else if (el.examAverage >= 80) {
    //           el.gpa = this.symbolGrade = "B-, GPA 2.67";
    //         } else if (el.examAverage >= 77) {
    //           el.gpa = this.symbolGrade = "C+, GPA 2.33";
    //         } else if (el.examAverage >= 73) {
    //           el.gpa = this.symbolGrade = "C, GPA 2.00";
    //         } else if (el.examAverage >= 70) {
    //           el.gpa = this.symbolGrade = "C-, GPA 1.67";
    //         } else if (el.examAverage >= 67) {
    //           el.gpa = this.symbolGrade = "D+, GPA 1.33";
    //         } else if (el.examAverage >= 63) {
    //           el.gpa = this.symbolGrade = "D, GPA 1.00";
    //         } else if (el.examAverage >= 60) {
    //           el.gpa = this.symbolGrade = "D-, GPA 0.67";
    //         } else {
    //           el.gpa = this.symbolGrade = "F, GPA 0.00";
    //         }
    //       });

    //       //   console.log(this.selectedLessonExamData);
    //       this.selectedCourseData = true;
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },

    // Гэрийн даалгавар
    // Багш гэрийн даалгавар өгөх функц
    courseHomeworkForStudent(myCourse) {
      //   console.log(myCourse);
      //   this.selectedSchoolCourse = false;
      //   this.selectedAttendance = false;
      //   this.selectTeachQuartFinal = false;
      //   this.selectStudentForCourseComment = false;
      this.toStudentForHW = true;
      this.selectedSchoolClassHW = myCourse;
      //   this.selectHWStudents = "";
      this.loadingHw = true;
      //   console.log(this.course_id);
      axios
        .post("/teacher/selectMyCourseAllHW", {
          courseInfo: myCourse,
          //   exam_id: this.exam_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          this.loadingHw = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    changeQuarterHW(e) {
      //   console.log(e);
      this.hw_changeQuarterId = e;
      //   console.log("..........." + this.hw_changeQuarterId);
    },
    resetHW() {
      //   console.log("reset");
      this.editModeHW = false;
      this.homeworkForm.reset();
      this.$bvModal.hide("modal-homework");
    },
    cancelHW() {
      //   console.log("cancel");
      this.resetHW();
    },
    createHW() {
      //   console.log("create");
      //   console.log(this.selectedSchoolClassHW);
      this.homeworkForm.course_id = this.selectedSchoolClassHW.course_id;
      this.homeworkForm.school_class_id =
        this.selectedSchoolClassHW.course_school_class_id;
      this.homeworkForm.department_id =
        this.selectedSchoolClassHW.department_id;
      this.homeworkForm.subject_id = this.selectedSchoolClassHW.subject_id;
      this.homeworkForm.course_type_id =
        this.selectedSchoolClassHW.course_type_id;
      this.homeworkForm.teacher_id =
        this.selectedSchoolClassHW.course_teacher_id;

      this.$bvModal.show("modal-homework");
    },
    storeHW() {
      //   console.log("store");
      this.homeworkForm
        .post("/teacher/storeHomeWork")
        .then((res) => {
          //   console.log(res.data);
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          this.resetHW();
        })
        .catch((err) => console.log(err));
    },
    editHW(hwInfo) {
      this.homeworkForm.fill(hwInfo);
      this.editModeHW = true;
      this.$bvModal.show("modal-homework");
    },
    updateHW() {
      //   console.log("update");
      this.homeworkForm
        .patch("/teacher/updateHomeWork")
        .then((res) => {
          //   console.log(res.data);
          this.resetHW();
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          //   this.hw_changeQuarterId();
        })
        .catch((err) => console.log(err));
    },
    deleteHW(hwInfo) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Дахин сэргээх боломжгүйг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцал!",
        confirmButtonColor: "#008000",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/deleteHomeWork", {
              hwInfoDelete: hwInfo,
            })
            .then((res) => {
              Swal.fire("Устгалаа!", "Амжилттай устгагдсан.", "success");
              this.teachersHomeWorks = res.data.teachersHomeWorks;
              //   console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцлагдсан",
            "Устгах үйлдлийг амжилттай цуцалсан :)",
            "error"
          );
        }
      });
    },
    restoreHWDStudent(hwInfo) {
      axios
        .post("/teacher/restoreHomeWorkStudent", {
          hwInfoDelete: hwInfo,
        })
        .then((res) => {
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          this.selectHWStudents = res.data.teachersHomeWorksupdate;
          //   console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Гэрийн даалгаварт суралцагч үнэлгээ өгөх, засах
    moreInfoHW(hwInfo) {
      //   console.log(hwInfo);
      //   array.forEach((element) => {});
      this.selectHWStudents = hwInfo;
      //   this.selectHWStudents.my_home_work_students.forEach((element) => {
      //     console.log(
      //       element.my_profile.student_profile_photo_url +
      //         " :" +
      //         element.my_profile.student_name +
      //         " :" +
      //         typeof element.my_profile.student_profile_photo_url
      //     );
      //   });

      this.formHwGradeStudent.course_hw_id = hwInfo.id;
      this.$bvModal.show("modal-homework-student");
    },
    resetFormHwGradeStudent() {
      this.formHwGradeStudent.reset();
    },

    // addGradeClick(selectHWStudents) {
    //   //   console.log("addGradeClick");
    //   axios
    //     .post("/teacher/addGradeAllStudents", {
    //       course: selectHWStudents,
    //       formHwGradeStudent: this.formHwGradeStudent,
    //     })
    //     .then((res) => {
    //       //   console.log(res.data);
    //       this.selectHWStudents = res.data.teachersHomeWorks;
    //       this.formHwGradeStudent.hw_grade = "";
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // editHwGradeStudent(hwInfo) {
    //   //   console.log("editHwGradeStudent", hwInfo);
    //   this.formHwGradeSelectedStudent.fill(hwInfo);
    //   this.editModeHwGrade = true;
    // },
    // updateHwGradeStudent() {
    //   //   console.log("updateHwGradeStudent");
    //   axios
    //     .post("/teacher/updateHwGradeStudent", {
    //       //   course: selectHWStudents,
    //       formHwGradeSelectedStudent: this.formHwGradeSelectedStudent,
    //     })
    //     .then((res) => {
    //       //   console.log(res.data);
    //       this.selectHWStudents = res.data.teachersHomeWorks;
    //       this.cancelHwGradeStudent();
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // removeHwGradeStudent(hwInfo) {
    //   //   console.log("removeHwGradeStudent");
    //   axios
    //     .post("/teacher/removeHwGradeStudent", {
    //       hwInfo,
    //     })
    //     .then((res) => {
    //       //   console.log(res.data);
    //       this.selectHWStudents = res.data.teachersHomeWorks;
    //     })
    //     .catch((err) => console.log(err));
    // },
    moreHwGradeStudent(hwInfo) {
      //   console.log(hwInfo);
      this.moreInfoStudentHW = hwInfo;
      this.moreInfoHWShowMode = true;
    },
    moreInfoStudentHWClose() {
      this.moreInfoStudentHW = "";
      this.moreInfoHWShowMode = false;
    },
    // cancelHwGradeStudent() {
    //   //   console.log("cancelHwGradeStudent");
    //   this.formHwGradeSelectedStudent.reset();
    //   this.editModeHwGrade = false;
    // },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelTeacherName({ teacher_name, teacher_last_name }) {
      return `${teacher_last_name[0]}${teacher_last_name[1]} . ${teacher_name}`;
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
    customLabelExam({ exam_name, exam_type }) {
      return `${exam_name} – ${exam_type}`;
    },
  },
  created() {
    this.getMySchoolHWsInfo();
  },
};
</script>

<style>
</style>
