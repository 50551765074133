<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong
              >Бие бялдрын хөгжлийг үнэлэх шалгуур үзүүлэлтүүд ба
              хэмжүүр</strong
            >
          </h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedAge"
                    :options="physicalAgeOptions"
                    text-field="ageRange"
                    value-field="ageRange"
                    size="sm"
                    class="form-control"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedGender"
                    :options="genderOptions"
                    text-field="text"
                    value-field="name"
                    size="sm"
                    class="form-control"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="getPhysicalMeasures()"
                    >Шүүж харах</b-button
                  >
                </b-col>
                <b-col sm="6">
                  <b-button
                    class="float-right"
                    variant="warning"
                    size="sm"
                    @click.prevent="showPhysicalType()"
                    ><i class="fa fa-plus"></i> Шалгуур үзүүлэлт</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="physicalIsLoaded" rounded="sm" class="py-3">
            <table class="table table-bordered table-striped" v-show="isFilter">
              <tbody>
                <tr v-for="physicalType in physicalList" :key="physicalType.id">
                  <td class="text-center p-0 verticalText text-bold">
                    {{ physicalType.name }}
                  </td>
                  <td>
                    <div
                      v-for="physical in physicalType.my_physicals"
                      :key="physical.id"
                    >
                      <div class="card card-secondary" id="ogsmCard">
                        <div class="card-header">
                          <h3 class="card-title mr-2">
                            {{ physical.name }}-н хэмжүүрүүд
                          </h3>
                          <b-badge variant="light" pill
                            >Норматив: {{ physical.normative_start }} -
                            {{ physical.normative_finish }}</b-badge
                          >
                          <div class="card-tools">
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body p-0">
                          <div v-if="physical.my_measures.length > 0">
                            <div
                              class="card card-light mb-0"
                              id="strategyCard"
                              v-for="measure in physical.my_measures"
                              :key="measure.id"
                            >
                              <div class="card-header">
                                <h3 class="card-title">
                                  <b-badge variant="light" pill
                                    ><strong>Оноо: </strong>
                                    {{ measure.point }}
                                    <i
                                      class="fa fa-arrow-right mx-3"
                                      aria-hidden="true"
                                    ></i>
                                    <strong>Үнэлгээ:</strong>
                                    {{ measure.evalution }}
                                  </b-badge>
                                </h3>
                                <div class="card-tools">
                                  <b-badge variant="success" pill
                                    >Хэмжүүр: {{ measure.start_count }} -
                                    {{ measure.end_count }}
                                    <b-badge variant="light" pill>
                                      <a
                                        href="#"
                                        @click.prevent="
                                          editPhysicalMeasure(measure, physical)
                                        "
                                        ><b-icon
                                          icon="pencil-square"
                                          variant="primary"
                                        ></b-icon>
                                      </a>
                                    </b-badge>
                                  </b-badge>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="callout callout-danger">
                            <p class="text-muted">
                              Хэмжүүр одоогоор бүртгэгдээгүй байна.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMeasureMode">Хэмжүүр нэмэх</h3>
          <h3 class="card-title" v-show="editMeasureMode">Хэмжүүр засах</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Төрөл</label>
              <b-form-select
                v-model="formMeasure.physical_type_id"
                :options="physicalTypeOptions"
                text-field="name"
                value-field="id"
                placeholder="Төрөл"
                @change="getTypeOfPhysical"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formMeasure.errors.has('physical_type_id'),
                  },
                ]"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Шалгуур</label>
              <multiselect
                v-model="formMeasure.physical_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                :custom-label="customPhysicalName"
                placeholder="Шалгуур"
                :options="physicalOptions"
                :searchable="true"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.gender }}, {{ option.age_start }} -
                  {{ option.age_end }} нас, {{ option.name }}
                </template>
              </multiselect>
            </div>
            <div class="form-group">
              <label>Оноо</label>
              <b-form-input
                type="number"
                size="sm"
                v-model="formMeasure.point"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formMeasure.errors.has('point'),
                  },
                ]"
                placeholder="Оноо"
              />
            </div>
            <div class="form-group">
              <label>Үнэлгээ</label>
              <b-form-select
                size="sm"
                v-model="formMeasure.evalution"
                :options="evolutionOptions"
                text-field="name"
                value-field="name"
                placeholder="Дүн"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formMeasure.errors.has('point_id'),
                    'is-invalid': formMeasure.errors.has('evalution'),
                  },
                ]"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Тайлбар</label>
              <textarea
                type="text"
                name="goal"
                v-model="formMeasure.description"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formMeasure.errors.has('description'),
                  },
                ]"
                placeholder="Тайлбар"
              />
            </div>
            <label for="name">{{ isTimeText }}</label>
            <div class="form-row">
              <div class="form-group col-lg-5">
                <input
                  type="number"
                  v-model="formMeasure.start_count"
                  v-on:change="checkMeasureCount"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formMeasure.errors.has('start_count'),
                    },
                  ]"
                  :placeholder="isTimeText"
                />
              </div>
              <div class="col-lg-1">
                <i class="fa fa-arrow-right pt-2"></i>
              </div>
              <div class="form-group col-lg-6">
                <input
                  type="number"
                  v-model="formMeasure.end_count"
                  v-on:change="checkMeasureCount"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formMeasure.errors.has('end_count'),
                    },
                  ]"
                  placeholder="Дээд"
                />
              </div>
            </div>
            <small class="text-red font-italic">{{ measureError }}</small>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-success"
            v-show="!editMeasureMode"
            @click.prevent="createPhysicalMeasure"
          >
            Шалгуур үүсгэх
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editMeasureMode"
            @click.prevent="updatePhysicalMeasure"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-dark"
            @click.prevent="cancelEditPhysicalMeasure"
            v-show="editMeasureMode"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <!-- Шалгуур нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-type"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Шалгуур үзүүлэлтүүд"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Үзүүлэлтүүд</h3>
                <b-button
                  class="float-right"
                  variant="success"
                  size="sm"
                  @click.prevent="addPhysicalType()"
                  ><i class="fa fa-plus"></i> Төрөл нэмэх</b-button
                >
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Төрөл</th>
                      <th>Нэр</th>
                      <th>Хугацааны хэмжилт</th>
                      <th>Нас</th>
                      <th>Хүйс</th>
                      <th>Норматив</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody v-for="(physic, index) in physicals" :key="physic.id">
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ physic.physical_type.name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ physic.name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span v-if="physic.isTime == 1"> Тийм </span>
                        <span v-else> Үгүй </span>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ physic.age_start }} - {{ physic.age_end }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ physic.gender }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ physic.normative_start }} -
                        {{ physic.normative_finish }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editPhysical(physic)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <!-- <a
                          v-show="physic.mentor_type != 'owner'"
                          href="#"
                          class="badge bg-danger"
                          @click="removePhysical(physic)"
                          ><i class="fa fa-minus"></i
                        ></a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-dark">
              <div class="card-header">
                <h3 class="card-title">Шалгуурын төрлүүд</h3>
              </div>
              <div class="card-body">
                <b-list-group-item class="flex-column align-items-start mb-3">
                  <b-badge
                    pill
                    v-for="pType in physicalTypes"
                    :key="pType.id"
                    variant="dark"
                    class="mr-1"
                  >
                    {{ pType.name }}
                    <b-badge pill variant="secondary">
                      <a
                        href="#"
                        class="badge bg-primary"
                        @click.prevent="editPhysicalType(pType)"
                        ><i class="fa fa-edit"></i
                      ></a>
                    </b-badge>
                  </b-badge>
                </b-list-group-item>
              </div>
            </div>
            <div class="card card-primary" v-if="showAddTypeMode">
              <div class="card-header">
                <h3 class="card-title" v-show="!editTypeMode">Төрөл нэмэх</h3>
                <h3 class="card-title" v-show="editTypeMode">Төрөл засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label for="name">Нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="formType.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formType.errors.has('name'),
                        },
                      ]"
                      placeholder="Нэр"
                    />
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editTypeMode"
                  @click.prevent="createPhysicalType"
                >
                  Төрөл үүсгэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editTypeMode"
                  @click.prevent="updatePhysicType"
                >
                  Төрөл хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditPhysicType"
                >
                  Болих
                </button>
              </div>
            </div>
            <div class="card card-primary" v-else>
              <div class="card-header">
                <h3 class="card-title" v-show="!editPhysicalMode">
                  Шалгуур нэмэх
                </h3>
                <h3 class="card-title" v-show="editPhysicalMode">
                  Шалгуур засах
                </h3>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Төрөл</label>
                    <b-form-select
                      v-model="formPhysical.physical_type_id"
                      :options="physicalTypes"
                      text-field="name"
                      value-field="id"
                      placeholder="Төрөл"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formPhysical.errors.has('physical_type_id'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label for="name">Нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="formPhysical.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formPhysical.errors.has('name'),
                        },
                      ]"
                      placeholder="Нэр"
                    />
                  </div>
                  <label for="name">Нас</label>
                  <div class="form-row">
                    <div class="form-group col-lg-5">
                      <input
                        type="number"
                        v-model="formPhysical.age_start"
                        v-on:change="checkAge"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formPhysical.errors.has('age_start'),
                          },
                        ]"
                        placeholder="Доод"
                      />
                    </div>
                    <div class="col-lg-1">
                      <i class="fa fa-arrow-right pt-2"></i>
                    </div>
                    <div class="form-group col-lg-6">
                      <input
                        type="number"
                        v-model="formPhysical.age_end"
                        v-on:change="checkAge"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formPhysical.errors.has('age_end'),
                          },
                        ]"
                        placeholder="Дээд"
                      />
                      <span class="text-red">{{ ageError }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Хүйс</label>
                    <b-form-select
                      v-model="formPhysical.gender"
                      :options="genderOptions"
                      text-field="text"
                      value-field="name"
                      placeholder="Хүйс"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formPhysical.errors.has('gender'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <b-form-checkbox
                      id="isTime"
                      v-model="formPhysical.isTime"
                      v-on:change="checkNormative"
                      name="isTime"
                      value="1"
                      unchecked-value="0"
                    >
                      Хугацааны хэмжилт эсэх
                    </b-form-checkbox>
                  </div>
                  <label for="name">Норматив</label>
                  <div class="form-row">
                    <div class="form-group col-lg-5">
                      <input
                        type="number"
                        v-model="formPhysical.normative_start"
                        v-on:change="checkNormative"
                        :class="[
                          'form-control',
                          {
                            'is-invalid':
                              formPhysical.errors.has('normative_start'),
                          },
                        ]"
                        placeholder="Доод"
                      />
                    </div>
                    <div class="col-lg-1">
                      <i class="fa fa-arrow-right pt-2"></i>
                    </div>
                    <div class="form-group col-lg-6">
                      <input
                        type="number"
                        v-on:change="checkNormative"
                        v-model="formPhysical.normative_finish"
                        :class="[
                          'form-control',
                          {
                            'is-invalid':
                              formPhysical.errors.has('normative_finish'),
                          },
                        ]"
                        placeholder="Дээд"
                      />
                    </div>
                  </div>
                  <small class="text-red font-italic">{{
                    normativeError
                  }}</small>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editPhysicalMode"
                  @click.prevent="createPhysical"
                >
                  Шалгуур үүсгэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  v-show="editPhysicalMode"
                  @click.prevent="updatePhysical"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditPhysical"
                  v-show="editPhysicalMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      measureId: 0,
      isFilter: false,
      selectedAge: null,
      selectedGender: "Эрэгтэй",
      physicalTypeOptions: [],
      physicalAgeOptions: [],
      physicalTypeList: [],
      physicalIsLoaded: true,
      showAddMode: false,
      showAddTypeMode: false,
      editTypeMode: false,
      mode: [],
      formType: new Form({
        id: "",
        name: "",
      }),
      editPhysicalMode: false,
      ageError: "",
      normativeError: "",
      formPhysical: new Form({
        id: "",
        isTime: 0,
        physical_type_id: 1,
        name: "",
        age_start: 6,
        age_end: 6,
        gender: "Эрэгтэй",
        normative_start: 5,
        normative_finish: 5,
      }),
      genderOptions: [
        { name: "Эрэгтэй", text: "Эрэгтэй" },
        { name: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      editMode: false,
      physicalList: [],
      physicals: [],
      physicalTypes: [],
      physicalOptions: [],
      editMeasureMode: false,
      formMeasure: new Form({
        id: "",
        isTime: 0,
        physical_type_id: "",
        physical_id: "",
        point: "",
        evalution: "",
        start_count: 1,
        end_count: 5,
        description: "",
      }),
      evolutionOptions: [
        { name: "A+" },
        { name: "A" },
        { name: "A-" },
        { name: "B+" },
        { name: "B" },
        { name: "B-" },
        { name: "C+" },
        { name: "C" },
        { name: "C-" },
        { name: "D+" },
        { name: "D" },
        { name: "D-" },
        { name: "F+" },
        { name: "F" },
        { name: "F-" },
      ],
      measureError: "",
      measureStr: "",
      isTimeText: "Хэмжүүр",
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    "formMeasure.physical_id": function (newVal) {
      this.formMeasure.isTime = newVal.isTime;
      this.checkMeasureCount();
      if (newVal.isTime == 1) this.isTimeText = "Хугацааны хэмжүүр оруулна уу";
      else this.isTimeText = "Хэмжүүр";
    },
  },
  methods: {
    //#region Type and physicals
    showPhysicalType() {
      this.showAddTypeMode = false;
      this.formType.reset();
      this.formPhysical.reset();
      this.getPhysicalType();
      this.$bvModal.show("modal-type");
    },
    getPhysicalType() {
      axios
        .get("/physical/getPhysicalsType/")
        .then((response) => {
          this.physicalTypes = response.data.physicalTypes;
          this.physicals = response.data.physicals;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    addPhysicalType() {
      this.showAddTypeMode = true;
      this.editTypeMode = false;
      this.formType.reset();
    },
    //Шалгуурын төрлийн хэсэг
    createPhysicalType() {
      this.formType
        .post("/physical/createPhysicalType/")
        .then((response) => {
          this.$toastr.s("Шалгуурын төрөл амжилттай үүсгэлээ.", "Амжилттай");
          this.getPhysicalType();
          this.showAddTypeMode = false;
          this.formType.reset();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editPhysicalType(p) {
      this.showAddTypeMode = true;
      this.editTypeMode = true;
      this.formType.reset();
      this.formType.fill(p);
    },
    updatePhysicType() {
      this.formType
        .put("/physical/updatePhysicalType/" + this.formType.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.formType.reset();
          this.showAddTypeMode = false;
          this.editTypeMode = false;
          this.getPhysicalType();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditPhysicType() {
      this.showAddTypeMode = false;
      this.formType.reset();
    },
    checkAge() {
      let start = parseInt(this.formPhysical.age_start);
      let end = parseInt(this.formPhysical.age_end);
      if (end != null && start != null) {
        if (end < start)
          this.ageError = "Насны дээд утга нь доод утгаас их байх ёстой.";
        else this.ageError = "";
      } else {
        this.ageError = "Насны утгыг оруулна уу.";
      }
    },
    checkNormative() {
      let start, end;
      if (this.formPhysical.isTime == 0) {
        start = parseInt(this.formPhysical.normative_start);
        end = parseInt(this.formPhysical.normative_finish);
      } else {
        start = parseInt(this.formPhysical.normative_finish);
        end = parseInt(this.formPhysical.normative_start);
      }

      if (end != null && start != null) {
        if (end < start)
          this.normativeError =
            "Нормативийн дээд, доод утга буруу байна шалгана уу";
        else this.normativeError = "";
      } else {
        this.normativeError = "Нормативийн утгыг оруулна уу.";
      }
    },
    //Шалгуурын хэсэг
    createPhysical() {
      if (this.ageError != "") return;
      if (this.normativeError != "") return;
      this.formPhysical
        .post("/physical/createPhysical/")
        .then((response) => {
          this.$toastr.s("Шалгуурын амжилттай үүсгэлээ.", "Амжилттай");
          this.getPhysicalType();
          this.formPhysical.reset();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editPhysical(p) {
      this.showAddTypeMode = false;
      this.editPhysicalMode = true;
      this.formPhysical.reset();
      this.formPhysical.fill(p);
    },
    updatePhysical() {
      if (this.ageError != "") return;
      if (this.normativeError != "") return;
      this.formPhysical
        .put("/physical/updatePhysical/" + this.formPhysical.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.formPhysical.reset();
          this.editPhysicalMode = false;
          this.getPhysicalType();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditPhysical() {
      this.editPhysicalMode = false;
      this.formPhysical.reset();
      this.checkNormative();
    },
    removePhysical(p) {
      Swal.fire({
        title: "Шалгуур устгах",
        text: p.name + " шалгуурыг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Шалгуур хасаж байна...";
          axios
            .get("/physical/removePhysical/" + p.id)
            .then((response) => {
              this.$toastr.s("Шалгуур амжилттай устгалаа!", "Шинэчилсэн");
              this.getPhysicalType();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    //#endregion
    //#region Measures
    customPhysicalName({ gender, age_start, age_end, name }) {
      return `${gender}, ${age_start} -
                  ${age_end} нас, ${name}`;
    },
    getPhysicalOptions() {
      this.physicalIsLoaded = false;
      axios
        .get("/physical/getPhysicalOptions/")
        .then((response) => {
          this.physicalAgeOptions = response.data.physicalAgeOptions;
          this.physicalTypeOptions = response.data.physicalTypeOptions;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getTypeOfPhysical() {
      axios
        .get(
          "/physical/getTypeOfPhysicals/" + this.formMeasure.physical_type_id
        )
        .then((res) => {
          this.physicalOptions = res.data.physicalOptions;
        });
    },
    getPhysicalMeasures() {
      if (this.selectedAge && this.selectedGender) {
        this.physicalIsLoaded = true;
        axios
          .post("/physical/getPhysicalMeasures/", {
            selectedAge: this.selectedAge,
            selectedGender: this.selectedGender,
          })
          .then((response) => {
            this.isFilter = true;
            this.physicalList = response.data.physicalList;
            this.physicalIsLoaded = false;
          })
          .catch((error) => {
            //   console.log(error);
          });
      } else this.$toastr.e("Нас болон хүйс сонгоно уу!", "Алдаа");
    },
    checkMeasureCount() {
      let start, end;
      if (this.formMeasure.isTime == 0) {
        start = parseInt(this.formMeasure.start_count);
        end = parseInt(this.formMeasure.end_count);
      } else {
        start = parseInt(this.formMeasure.end_count);
        end = parseInt(this.formMeasure.start_count);
      }

      if (end != null && start != null) {
        if (end < start)
          this.measureError =
            "Хэмжүүрийн дээд, доод утга буруу байна шалгана уу.";
        else this.measureError = "";
      } else {
        this.measureError = "Хэмжүүрийн  утгыг оруулна уу.";
      }
    },
    //Хэмжүүр хэсэг
    createPhysicalMeasure() {
      this.checkMeasureCount();
      if (this.measureError != "") return;
      this.selectedAge =
        this.formMeasure.physical_id.age_start +
        "-" +
        this.formMeasure.physical_id.age_end;
      var physic = this.formMeasure.physical_id;
      this.formMeasure.physical_id = this.formMeasure.physical_id.id;
      this.formMeasure
        .post("/physical/createPhysicalMeasure/")
        .then((response) => {
          if (response.data.errorMsj == null) {
            this.$toastr.s("Хэмжүүр амжилттай үүсгэлээ.", "Амжилттай");
            this.getPhysicalMeasures();
            this.formMeasure.point = "";
            this.formMeasure.description = "";
            this.formMeasure.isTime = 0;
            this.checkMeasureCount();
          } else {
            this.$toastr.e(response.data.errorMsj, "Алдаа гарлаа.");
            this.formMeasure.physical_id = physic;
          }
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editPhysicalMeasure(m, p) {
      this.editMeasureMode = true;
      this.formMeasure.reset();
      this.formMeasure.fill(m);
      this.getTypeOfPhysical();
      this.formMeasure.physical_id = p;
    },
    updatePhysicalMeasure() {
      this.checkMeasureCount();
      if (this.measureError != "") return;
      var physic = this.formMeasure.physical_id;
      this.formMeasure.physical_id = this.formMeasure.physical_id.id;
      this.formMeasure
        .put("/physical/updatePhysicalMeasure/" + this.formMeasure.id)
        .then((response) => {
          if (response.data.errorMsj == null) {
            this.$toastr.s("Хэмжүүр амжилттай заслаа.", "Амжилттай");
            this.formMeasure.reset();
            this.formMeasure.isTime = 0;
            this.editMeasureMode = false;
            this.getPhysicalMeasures();
            this.checkMeasureCount();
          } else {
            this.$toastr.e(response.data.errorMsj, "Алдаа гарлаа.");
            this.formMeasure.physical_id = physic;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditPhysicalMeasure() {
      this.editMeasureMode = false;
      this.formMeasure.reset();
      this.checkMeasureCount();
    },
    //#endregion
  },
  created() {
    this.getPhysicalOptions();
    Fire.$on("getPhysicalOptions", () => {
      this.getPhysicalOptions();
    });
  },
};
</script>

<style scoped>
hr {
  margin-top: 0;
  margin-bottom: 0;
}
.callout {
  margin-bottom: 0px !important;
}
</style>
