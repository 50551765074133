<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Model тохируулах</strong>
          </h3>
        </div>
        <div class="card-body">
          <ul
            id="my-custom-tags-list"
            class="list-unstyled d-inline-flex flex-wrap mb-0"
            aria-live="polite"
            aria-atomic="false"
            aria-relevant="additions removals"
          >
            <b-card
              v-for="(model, index) in models"
              :key="index"
              tag="li"
              class="mt-1 mr-4"
              body-class="py-1 pr-4 text-nowrap"
            >
              <b-form-checkbox
                :disabled="model.title == ''"
                :checked="`${getStatusBool(model.status)}`"
                :name="model.name"
                aria-describedby="active"
                switch
                size="xs"
                @change="createModel(model)"
              >
                <span
                  :class="[
                    'text-muted',
                    model.status == 'active' ? 'labelActive' : 'label',
                  ]"
                  >{{ model.name }}</span
                >
              </b-form-checkbox>
              <input
                type="text"
                v-model="model.title"
                placeholder="Тайлбар"
                :class="[
                  'form-control text-muted',
                  model.status == 'active' ? 'labelActive' : 'label',
                  { 'is-invalid': model.title == '' },
                ]"
              />
            </b-card>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      models: [],
    };
  },
  methods: {
    getStatusBool(value) {
      if (value == "active") return true;
      else return false;
    },
    createModel(model) {
      if (model.title != "")
        axios
          .post("/owner/createModel", { name: model.name, title: model.title })
          .then((response) => {
            if (response.data.errorMsj)
              this.$toastr.s("Анхаар!", response.data.errorMsj);
            else {
              this.$toastr.s("Амжилттай", "Шинэчилсэн");
              this.getModels();
            }
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      else {
        model.isError = true;
        this.$toastr.e("Алдаа", "Тайлбар оруулна уу!");
      }
    },
    getModels() {
      axios
        .get("/owner/getModels")
        .then((response) => {
          this.models = response.data.models;
        })
        .catch(() => {});
    },
  },
  created() {
    this.getModels();
  },
};
</script>

<style scoped>
.label {
  font-weight: 200;
}
.labelActive {
  font-weight: 600;
}
</style>
