<template>
  <div class="row pt-3">
    <div class="col-md-7">
      <section class="content">
        <!-- Default box -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Ярилцлага</h3>

            <div class="card-tools">
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
              >
                <i class="fas fa-minus"></i>
              </button>
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="remove"
                title="Remove"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0" style="overflow-x: auto">
            <table class="table table-striped projects">
              <thead style="font-size: 60%">
                <tr>
                  <th style="width: 1%" class="text-center">#</th>
                  <th style="width: 10%" class="text-center">
                    Ярилцлага хийсэн
                  </th>
                  <th style="width: 10%" class="text-center">Нэр</th>
                  <th style="width: 10%" class="text-center">Утас</th>
                  <th style="width: 15%" class="text-center">
                    Эзэмшсэн мэргэжлийн талаар(1-5 оноо)
                  </th>
                  <th style="width: 15%" class="text-center">
                    Хувь хүний зан байдал, төлөвшлийн талаар(1-5 оноо)
                  </th>
                  <th style="width: 15%" class="text-center">
                    Ирээдүйн төлөвлөгөөний талаар(1-5 оноо)
                  </th>
                  <th style="width: 15%" class="text-center">
                    Амжилт, бүтээл болон гавъяа шагналын талаар(1-5 оноо)
                  </th>
                  <th style="width: 5%" class="text-center">Тэнцсэн</th>
                  <th style="width: 10%" class="text-center">Үйлдлүүд</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(interview, index) in interviews" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        {{ interview.teacher_last_name[0] }}.
                        {{ interview.teacher_name }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        {{ interview.candidate_name }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        {{ interview.phone_number }}
                      </li>
                    </ul>
                  </td>
                  <td class="project_progress">
                    <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-green"
                        role="progressbar"
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{
                          width: interview.ps_total + '%',
                        }"
                      ></div>
                    </div>
                    <small> {{ interview.ps_total }}% Complete </small>
                  </td>
                  <td class="project_progress">
                    <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-green"
                        role="progressbar"
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{
                          width: interview.pb_total + '%',
                        }"
                      ></div>
                    </div>
                    <small>
                      {{ interview.pb_total }}
                      % Complete
                    </small>
                  </td>
                  <td class="project_progress">
                    <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-green"
                        role="progressbar"
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{
                          width: interview.fp_total + '%',
                        }"
                      ></div>
                    </div>
                    <small> {{ interview.fp_total }} % Complete </small>
                  </td>
                  <td class="project_progress">
                    <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-green"
                        role="progressbar"
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{
                          width: interview.sa_total + '%',
                        }"
                      ></div>
                    </div>
                    <small>
                      {{ interview.sa_total }}
                      % Complete
                    </small>
                  </td>
                  <td class="project-state">
                    <span class="badge badge-success" v-if="interview.status"
                      ><i class="fas fa-user-check"></i
                    ></span>
                    <span class="badge badge-danger" v-if="!interview.status"
                      ><i class="fas fa-user-check"></i
                    ></span>
                  </td>
                  <td class="project-actions text-center">
                    <a class="btn btn-primary btn-xs" href="#">
                      <i class="fas fa-eye"> </i>
                    </a>
                    <a
                      class="btn btn-info btn-xs"
                      href="#"
                      @click.prevent="editInterviews(interview)"
                    >
                      <i class="fas fa-pencil-alt"> </i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </section>
    </div>
    <div class="col-md-5">
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title">Ярилцлагын форм бөглөх</h3>
        </div>
        <div class="card-body">
          <p>Үндсэн мэдээлэл</p>
          <b-form-input
            v-model="form.sonirkhoj_alban_tushaal"
            placeholder="Сонирхож буй албан тушаал"
            class="form-control-sm"
          ></b-form-input>

          <b-form-input
            v-model="form.candidate_name"
            placeholder="Багшийн нэр"
            class="form-control-sm"
          ></b-form-input>
          <b-form-input
            v-model="form.phone_number"
            placeholder="Багшийн утас"
            class="form-control-sm"
            type="number"
          ></b-form-input>
          <p><b>Эзэмшсэн мэргэжлийн талаар(1-5)</b></p>
          <div class="row">
            <p>Мэргэжлээ сонгоход чинь ямар хүчин зүйлс нөлөөлсөн бэ?</p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_1"
                placeholder="Тайлбар"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_1_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Дунд сургууль болон их, дээд сургуульд сурч байхдаа ямар ямар
              хичээлийн агуулгыг эзэмшихэд мэдлэг, чадвар чинь хүрэлцдэг, харин
              ямар ямар хичээлийн агуулгыг эзэмшихэд их бэрхшээлтэй байдаг
              байсан бэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_2"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_2_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Мэргэжлийн ур чадварын хувьд өөрт чинь ямар давуу ба сул талууд
              байдаг вэ? Мэргэжил нэгт нөхдүүдтэйгээ өрсөлдөхүйц ямар чадварууд
              байгаа вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_3"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_3_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Манай байгууллагад ажиллах хүсэлт гаргахад чинь ямар хүчин зүйлс
              нөлөөлсөн бэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_4"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_4_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Хөдөлмөрийн гэрээнд ямар ямар нөхцөлүүдийг тусгахыг хүсч байгаа
              вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_5"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_5_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Манай байгууллагад мэргэжлээрээ ажиллахад чинь учирч болзошгүй
              бэрхшээлүүд юу вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_6"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_6_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>Манай байгууллага таниас ямар үр дүнг хүлээж болох вэ?</p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.ps_7"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.ps_7_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <p><b>Хувь хүний зан байдал, төлөвшлийн талаар(1-5)</b></p>
          <div class="row">
            <p>
              Хувь хүнийхээ хувьд танд ямар сайн ба муу чанарууд байдаг вэ?
              Бусдад хүлээн зөвшөөрөгдөхөд чинь таны чухам ямар чанарууд
              шийдвэрлэх нөлөө үзүүлнэ гэж бодож байна вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.pb_1"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.pb_1_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Ямар нэг баг хамт олныг албан болон албан бус байдлаар манлайлж
              удирдаж үзсэн үү? Эсвэл нийгэм рүү чиглэсэн ямар арга хэмжээнүүдэд
              идэвхтэй оролцож байсан бэ? Ямар амжилт, үр дүнд хүрч байсан бэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.pb_2"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.pb_2_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Хүүхэд байхаасаа хөгжүүлсэн эсвэл одоо хөгжүүлж байгаа авьяас,
              сонирхол чинь юу вэ? Ямар түвшинд хүртэл нь хөгжүүлсэн бэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.pb_3"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.pb_3_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <p><b>Ирээдүйн төлөвлөгөөний талаар(1-5)</b></p>
          <div class="row">
            <p>
              Мэргэжлийнхээ ур чадварыг хөгжүүлэх тал дээр танд ямар төлөвлөгөө
              байгаа вэ? Хүлээгдэж буй үр дүнгээ та хэрхэн төсөөлж байгаа вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.fp_1"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.fp_1_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Гэр бүлийнхээ амьдралыг хэрхэн төлөвлөж байна вэ? Хүлээгдэж буй үр
              дүнгээ та хэрхэн төсөөлж байгаа вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.fp_2"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.fp_2_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Хувь хүнийхээ хувьд ямар ямар зан байдал, үйлдэл болон буруу
              зуршлаа засаж сайжруулах оролдлого хийх гэж байгаа вэ?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.fp_3"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.fp_3_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <p>
            <b>Амжилт, бүтээл болон гавъяаа шагналын талаар(1-5)</b>
          </p>
          <div class="row">
            <p>
              Мэргэжлийн ур чадварын хувьд та ямар амжилтуудаараа илүү бахархдаг
              вэ? Яагаад?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.sa_1"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.sa_1_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Тантай хамтарч ажилласан хэн хэний, ямар амжилтуудыг та өндрөөр
              үнэлж, бахархаж явдаг вэ? Яагаад?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.sa_2"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.sa_2_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Урлаг, спортын амжилтуудаасаа хамгийн их сэтгэлд хоногшсоноос нь
              дурдахгүй юу?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.sa_3"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.sa_3_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="row">
            <p>
              Танд өөрөө хийж бүтээсэн оюуны болон биет бүтээлүүд байдаг бол тэр
              талаараа ярихгүй юу?
            </p>
            <div class="col-sm-6">
              <b-form-input
                v-model="form.sa_4"
                placeholder="Оноо(0-5)"
                class="form-control-sm"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-sm-6">
              <b-form-textarea
                v-model="form.sa_4_desc"
                placeholder="Тайлбар"
                class="form-control-sm"
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
        <b-col>
          <b-form-group label="Үйлдлүүд" label-size="sm" label-for="input-sm">
            <b-button-group size="sm" class="mr-1">
              <b-button
                title="Save file"
                :variant="editMode ? 'warning' : 'success'"
                @click.prevent="
                  editMode ? updateInterviews() : createInterviews()
                "
              >
                <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
              </b-button>
              <b-button title="cancel" variant="outline-wirning">
                <b-icon
                  icon="x-circle"
                  variant="danger"
                  aria-hidden="true"
                  @click="cancelEdit()"
                ></b-icon>
              </b-button>

              <!-- <b-button variant="outline-primary">
                <b-icon icon="tools"></b-icon>
              </b-button>
              <b-button variant="outline-primary success">
                <b-icon icon="person-fill"></b-icon>
              </b-button> -->
            </b-button-group>
          </b-form-group>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      interviews: [],
      form: new Form({
        id: "",
        user_id: "",
        candidate_name: "",
        phone_number: "",
        sonirkhoj_alban_tushaal: "",
        ps_1: "",
        ps_1_desc: "",
        ps_2: "",
        ps_2_desc: "",
        ps_3: "",
        ps_3_desc: "",
        ps_4: "",
        ps_4_desc: "",
        ps_5: "",
        ps_5_desc: "",
        ps_6: "",
        ps_6_desc: "",
        ps_7: "",
        ps_7_desc: "",
        ps_total: "",
        pb_1: "",
        pb_1_desc: "",
        pb_2: "",
        pb_2_desc: "",
        pb_3: "",
        pb_3_desc: "",
        pb_total: "",
        fp_1: "",
        fp_1_desc: "",
        fp_2: "",
        fp_2_desc: "",
        fp_3: "",
        fp_3_desc: "",
        fp_total: "",
        sa_1: "",
        sa_1_desc: "",
        sa_2: "",
        sa_2_desc: "",
        sa_3: "",
        sa_3_desc: "",
        sa_4: "",
        sa_4_desc: "",
        sa_total: "",
        status: "",
      }),
    };
  },
  methods: {
    changeNumber(value) {
      return value.toFixed(1);
    },
    getAllInterviews() {
      axios
        .get("/manager/getAllInterviews")
        .then((response) => {
          this.interviews = response.data.interviews;
          //   console.log(response.data.interviews);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewInterviews(interview) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + interview);
    },
    editInterviews(interview) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + interview);
      this.editMode = true;
      this.form.reset();
      this.form.fill(interview);
      //   this.form.department = interview.department_id;
    },
    createInterviews() {
      //   console.log(this.form);
      //   this.action = "Ярилцлага үүсгэж байна...";
      this.form
        .post("/manager/interview")
        .then((response) => {
          this.$toastr.s(response.data.interview, "Амжилттай");
          Fire.$emit("loadInterviews");
          this.form.reset();
        })
        .catch((errors) => {
          //   console.log(errors);
          // this.selectClass = true;
          this.$toastr.e(
            "Ярилцлагыг нэмж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа." + errors
          );
        });
    },
    updateInterviews() {
      //   this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form, "update");
      this.form
        .put("/manager/updateInterview")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadInterviews");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.editMode = false;
      this.form.reset();
    },
    // deleteInterviews(schoolteacher) {
    //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
  },
  created() {
    this.getAllInterviews();
    Fire.$on("loadInterviews", () => {
      this.getAllInterviews();
    });
    // Fire.$on("loadSchoolClasses", someData => {
    //     // console.log(
    //     //     someData,
    //     //     "Fire on loadSchoolClasses аас " + someData.school_class_id,
    //     //     someData.full_name
    //     // );
    //     this.schoolClass(someData.school_class_id, someData.full_name);
    // });
  },
};
</script>

<style></style>
