<template>
  <div class="mt-2">
    <b-button @click.prevent="getCitizen()" block variant="outline-primary"
      >Ажилчдын иргэншил харах</b-button
    >
    <div class="row" v-if="isCitizenView">
      <!-- Багш нар харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын иргэншил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsCitizenTeach"
                :series="seriesCitizenTeach"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын иргэншил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsCitizenTeachP"
                :series="seriesCitizenTeachP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="teachCitizen in citizenTeach"
              :key="teachCitizen.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ teachCitizen.citizenship }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ teachCitizen.total }}</span>
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>иргэншил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="teachEduYearDetal in citizenTeachDetal"
                    :key="teachEduYearDetal.id"
                  >
                    <tr
                      v-if="
                        teachCitizen.citizenship ==
                        teachEduYearDetal.citizenship
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="teachEduYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="teachEduYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachEduYearDetal.teacher_last_name[0] }}.{{
                          teachEduYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachEduYearDetal.citizenship }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewCitizenTeach"
              @click.prevent="getCitizenTeachDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./card-body -->
</template>

<script>

export default {
  props: ["year_id"],
  data() {
    return {
      viewCitizenTeach: true,
      isCitizenView: false,
      citizenTeachDetal: [],
      citizenTeach: [],
      optionsCitizenTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesCitizenTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesCitizenTeachP: [],
      optionsCitizenTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {
    getCitizen() {
      if (!this.isCitizenView) {
        axios
          .post("/manager/getCitizen", {
            year: this.year_id,
          })
          .then((res) => {
            this.seriesCitizenTeach[0].data = res.data.citizenTotalTeach;
            this.optionsCitizenTeach.xaxis.categories = res.data.citizenNameTeach;

            this.seriesCitizenTeachP = res.data.citizenTotalTeach;
            this.optionsCitizenTeachP.labels = res.data.citizenNameTeach;
            this.isCitizenView = !this.isCitizenView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isCitizenView = !this.isCitizenView;
    },
    getCitizenTeachDetal() {
      axios
        .post("/manager/getCitizenTeachDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.citizenTeach = res.data.citizenCountTeach;
          this.citizenTeachDetal = res.data.citizenTeachDetal;
          this.viewCitizenTeach = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
  font-size: 70%;
}
.pieChart {
  height: 330px;
}
.barChart {
  height: 340px;
}
</style>
