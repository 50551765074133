<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <multiselect
        v-model="year_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="id"
        label="id"
        :custom-label="customYearName"
        placeholder="Хичээлийн жилээр шүүж харах"
        :options="allYears"
        :searchable="true"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{
              option.finish_date | dateYear
            }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <!-- <div class="card">
        <div class="card-header p-2"></div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12"></div>
          </div>
        </div>
      </div> -->
      <div v-if="isShow">
        <div class="row">
          <!-- <div class="col-lg-6 col-6">
          <div class="small-box bg-gray">
            <header class="inner text-center">
              Нийт санал, гомдол, хамтран ажиллах хүсэлт, ёс зүйн асуудлаар
              ирсэн
            </header>
            <div class="inner text-center">
              <h3>
                {{ allComplaints }}/{{ allComplaintsPercent
                }}<sup style="font-size: 20px">%</sup>
              </h3>
              <h4>
                {{ allComplaints }} хэрэглэгчийн хүсэлт дотор санал, гомдол,
                хамтран ажиллах хүсэлт, ёс зүйн асуудлууд давхардаж ирэх
                боломжтой.
              </h4>

              <p>
                Хэрэглэгчээс ирсэн өргөдөл, санал, гомдол, хамтран ажиллах
                хүсэлт
              </p>
              <p>
                Гадаад хэрэглэгчдээс ирсэн санал хүсэлт, гомдлыг үйл ажиллагааг
                сайжруулахад ашиглана.
              </p>
            </div>
            <div class="icon">
              <i class="ion ion-bag"></i>
            </div>
            <a href="#" class="small-box-footer"
              >More info <i class="fas fa-arrow-circle-right"></i
            ></a>
          </div>
        </div> -->
          <!-- <div class="col-lg-1 col-12"></div> -->

          <div class="col-lg-6 col-12">
            <div
              class="small-box bg-white"
              style="display: flex; justify-content: center"
            >
              <apexchart
                width="500px"
                type="donut"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
          <!-- <div class="col-lg-1 col-12"></div> -->
          <div class="col-lg-3 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="100%"
                type="donut"
                :options="options1"
                :series="series1"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="100%"
                type="donut"
                :options="options2"
                :series="series2"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-6"></div>
          <div class="col-lg-3 col-6"></div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-info">
              <div class="inner">
                <h3>
                  {{ allComplaintsParentPoll }}/{{ pollPercent
                  }}<sup style="font-size: 20px">%</sup>
                </h3>

                <h4>САНАЛ</h4>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
              <!-- <a href="#" class="small-box-footer"
              >More info <i class="fas fa-arrow-circle-right"></i
            ></a> -->
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>
                  {{ allComplaintsParentComplaint }} /
                  {{ complaintPercent }}
                  <sup style="font-size: 20px">%</sup>
                </h3>

                <h4>ГОМДОЛ</h4>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <!-- <a href="#" class="small-box-footer"
              >More info <i class="fas fa-arrow-circle-right"></i
            ></a> -->
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>
                  {{ allComplaintsParentContribution }} /
                  {{ contributionPercent }}
                  <sup style="font-size: 20px">%</sup>
                </h3>

                <h4>ХАМТРАН АЖИЛЛАХ</h4>
              </div>
              <div class="icon">
                <i class="ion ion-person-add"></i>
              </div>
              <!-- <a href="#" class="small-box-footer"
              >More info <i class="fas fa-arrow-circle-right"></i
            ></a> -->
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>
                  {{ allComplaintsParentEthics }} / {{ ethicsPercent
                  }}<sup style="font-size: 20px">%</sup>
                </h3>

                <h4>ЁС ЗҮЙ</h4>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
              <!-- <a href="#" class="small-box-footer"
              >More info <i class="fas fa-arrow-circle-right"></i
            ></a> -->
            </div>
          </div>
          <!-- ./col -->
        </div>
        <div class="row">
          <div class="col-lg-12">
            <apexchart
              type="treemap"
              height="350"
              :options="chartOptions3"
              :series="series3"
            ></apexchart>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-2">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link active" href="#settings" data-toggle="tab"
                  >Шийдвэрлэсэн</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#decisions" data-toggle="tab"
                  >Шийдвэрлээгүй</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#publicDec" data-toggle="tab"
                  >Гадны шийдвэрлэсэн</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#publicUnDec" data-toggle="tab"
                  >Гадны шийдвэрлээгүй</a
                >
              </li>
            </ul>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <div class="tab-content">
              <!-- Шийдвэрлэгдсэн -->
              <div class="tab-pane active" id="settings">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                    </h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body table-responsive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Овог нэр</th>
                          <th>Мэргэжил</th>
                          <th>Утас</th>
                          <th>Хүүхэд</th>
                          <th>Санал</th>
                          <th>Гомдол</th>
                          <th>Хамтран ажиллах</th>
                          <th>Ёс зүй</th>
                          <th>Өргөдлийн агуулга</th>
                          <th>Шийдвэр</th>
                          <th>Шийдвэрийн тайлбар</th>
                          <th>Харьяа мэргэжлийн баг</th>
                          <th>Огноо</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(complain, index) in allComplaintsDecisions"
                        :key="complain.index"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span v-if="complain.prof_par_parent_father_ovog">
                              {{ complain.prof_par_parent_father_ovog[0] }}
                            </span>
                            .{{ complain.prof_par_parent_father_name }}
                            <!-- <b-icon
                              icon="trash-fill"
                              variant="danger"
                              @click.prevent="complaintDelete(complain)"
                            ></b-icon> -->
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            style="text-align: center"
                            tabindex="0"
                          >
                            <b-badge
                              variant="success"
                              v-show="
                                complain.prof_par_parent_father_mergejil != null
                              "
                            >
                              {{
                                complain.prof_par_parent_father_mergejil
                              }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap"
                              >{{
                                complain.prof_par_parent_father_phone_number
                              }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap"
                              >{{ complain.scho_cla_full_name }}:
                              {{ complain.prof_student_name }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_poll == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_poll == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_complaint == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_complaint == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_contribution == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_contribution == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_ethics == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_ethics == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              >{{ complain.comp_par_main_problem }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-if="complain.comp_par_decision == 1"
                              >Шийдвэрлэсэн</b-badge
                            >
                            <b-badge
                              variant="danger"
                              v-if="complain.comp_par_decision == 0"
                              >Шийдвэрлээгүй</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                            >
                              {{ complain.comp_par_decision_explain }}
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span
                              v-if="complain.comp_par_department_id != null"
                              >{{
                                selectDepartment(
                                  complain.comp_par_department_id
                                )
                              }}</span
                            >
                            <span
                              v-if="complain.comp_par_department_id == null"
                            >
                              <a
                                class="btn btn-warning btn-sm"
                                @click.prevent="editComplain(complain)"
                              >
                                <i class="far fa-edit"></i>
                              </a>
                              Мэргэжлийн баг бүртгээгүй
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ complain.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <!-- Шийдвэрлэгдээгүй -->
              <div class="tab-pane" id="decisions">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                    </h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body table-responsive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Санал</th>
                          <th>Гомдол</th>
                          <th>Хамтран ажиллах</th>
                          <th>Ёс зүй</th>
                          <th>Өргөдлийн агуулга</th>
                          <th>Шийдвэр</th>
                          <th>Шийдвэрийн тайлбар</th>
                          <th>Харьяа мэргэжлийн баг</th>
                          <th>Огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(complain, index) in allComplaintsUnDecisions"
                          :key="complain.index"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_poll == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_poll == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_complaint == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_complaint == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_contribution == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_contribution == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_ethics == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_ethics == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              >{{ complain.comp_par_main_problem }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-if="complain.comp_par_decision == 1"
                              >Шийдвэрлэсэн</b-badge
                            >
                            <b-badge
                              variant="danger"
                              v-if="complain.comp_par_decision == 0"
                              >Шийдвэрлээгүй</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain != null"
                            >
                              {{ complain.comp_par_decision_explain }}
                            </b-badge>
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain == null"
                            >
                              Тайлбар ороогүй...
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span
                              v-if="complain.comp_par_department_id != null"
                              >{{
                                selectDepartment(
                                  complain.comp_par_department_id
                                )
                              }}</span
                            >
                            <span
                              v-if="complain.comp_par_department_id == null"
                            >
                              Мэргэжлийн баг бүртгээгүй
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ complain.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <!-- гадны шийдвэрлэгдсэн -->
              <div class="tab-pane" id="publicDec">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                    </h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body table-responsive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Санал</th>
                          <th>Гомдол</th>
                          <th>Хамтран ажиллах</th>
                          <th>Ёс зүй</th>
                          <th>Өргөдлийн агуулга</th>
                          <th>Шийдвэр</th>
                          <th>Шийдвэрийн тайлбар</th>
                          <th>Харьяа мэргэжлийн баг</th>
                          <th>Огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            complain, index
                          ) in allComplaintsDecisionsPublicParent"
                          :key="complain.index"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_poll == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_poll == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_complaint == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_complaint == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_contribution == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_contribution == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_ethics == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_ethics == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              >{{ complain.comp_par_main_problem }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-if="complain.comp_par_decision == 1"
                              >Шийдвэрлэсэн</b-badge
                            >
                            <b-badge
                              variant="danger"
                              v-if="complain.comp_par_decision == 0"
                              >Шийдвэрлээгүй</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain != null"
                            >
                              {{ complain.comp_par_decision_explain }}
                            </b-badge>
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain == null"
                            >
                              Тайлбар ороогүй...
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span
                              v-if="complain.comp_par_department_id != null"
                              >{{
                                selectDepartment(
                                  complain.comp_par_department_id
                                )
                              }}</span
                            >
                            <span
                              v-if="complain.comp_par_department_id == null"
                            >
                              Мэргэжлийн баг бүртгээгүй
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ complain.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <!-- гадны шийдвэрлэгдсэн -->
              <div class="tab-pane" id="publicUnDec">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                    </h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body table-responsive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Санал</th>
                          <th>Гомдол</th>
                          <th>Хамтран ажиллах</th>
                          <th>Ёс зүй</th>
                          <th>Өргөдлийн агуулга</th>
                          <th>Шийдвэр</th>
                          <th>Шийдвэрийн тайлбар</th>
                          <th>Харьяа мэргэжлийн баг</th>
                          <th>Огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            complain, index
                          ) in allComplaintsUnDecisionsPublicParent"
                          :key="complain.index"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_poll == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_poll == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_complaint == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_complaint == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_contribution == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_contribution == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="success"
                              v-show="complain.comp_par_ethics == 1"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-check-circle"></i
                            ></b-badge>
                            <b-badge
                              variant="danger"
                              v-show="complain.comp_par_ethics == 0"
                              style="white-space: pre-wrap"
                              ><i class="fas fa-times"></i
                            ></b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              >{{ complain.comp_par_main_problem }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="success"
                              v-if="complain.comp_par_decision == 1"
                              >Шийдвэрлэсэн</b-badge
                            >
                            <b-badge
                              variant="danger"
                              v-if="complain.comp_par_decision == 0"
                              >Шийдвэрлээгүй</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain != null"
                            >
                              {{ complain.comp_par_decision_explain }}
                            </b-badge>
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; width: 16rem"
                              v-if="complain.comp_par_decision_explain == null"
                            >
                              Тайлбар ороогүй...
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span
                              v-if="complain.comp_par_department_id != null"
                              >{{
                                selectDepartment(
                                  complain.comp_par_department_id
                                )
                              }}</span
                            >
                            <span
                              v-if="complain.comp_par_department_id == null"
                            >
                              Мэргэжлийн баг бүртгээгүй
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ complain.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isShow: false,
      year_id: "",
      allYears: [],
      allComplaints: [],
      allComplaintsPublic: [],
      allComplaintsParent: [],
      //   allComplaintsCompleteDecPublicParent: [],
      //   allComplaintsUnCompleteDecPublicParent: [],
      allComplaintsParentPoll: [],
      allComplaintsParentComplaint: [],
      allComplaintsParentContribution: [],
      allComplaintsParentEthics: [],

      // Гадны санал гомдлуудын дэлгэрэнгүй
      allComplaintsDecisionsPublicParent: [],
      allComplaintsUnDecisionsPublicParent: [],

      // Мэргэжлийн багуудаар
      allComplaintsDepartments: [],

      //Нийт гомдол санал дээр ашиглалаа
      options: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        chart: {
          height: "500px",
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  // fontSize: "12px",
                  // fontFamily: "Helvetica, Arial, sans-serif",
                  // fontWeight: 600,
                  // color: "#FF9800",
                  // offsetY: -10,
                  // formatter: function (val) {
                  //   return val;
                  // },
                },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
        title: {
          text: "Нийт санал, гомдол, хамтран ажиллах хүсэлт, ёс зүйн асуудлаар ирсэн",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: undefined,
          },
        },
      },
      series: [],
      //Нийт гомдол саналын шийдвэрлэсэн эсэх гадны
      options1: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
        title: {
          text: "Гадаад",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: undefined,
          },
        },
      },
      series1: [],
      //Нийт гомдол саналын шийдвэрлэсэн эсэх дотоод
      options2: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        plotOptions: {
          expandOnClick: true,
          pie: {
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
        title: {
          text: "Дотоод",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: undefined,
          },
        },
      },
      series2: [],
      //Мэргэжлийн багаар гаргах график
      series3: [
        {
          data: [],
        },
      ],
      chartOptions3: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        title: {
          text: "Мэргэжлийн багуудаар ёс зүй, санал, гомдол, хамтран ажиллах хүсэлтүүдийг ангилбал",
          align: "center",
          style: {
            fontSize: "24px",
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "24px",
          },
          formatter: function (text, op) {
            return [text, op.value];
          },
          offsetY: -4,
        },
        colors: [
          "#3B93A5",
          "#F7B844",
          "#ADD8C7",
          "#EC3C65",
          "#CDD7B6",
          "#C1F666",
          "#D43F97",
          "#1E5D8C",
          "#421243",
          "#7F94B0",
          "#EF6537",
          "#C0ADDB",
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },

      //Шийдвэрлэгдсэн болон шийдвэрлэгдээгүй асуудлууд
      departments: [],
      allComplaintsDecisions: [], // шийдвэрлэгдсэн
      allComplaintsUnDecisions: [], // шийдвэрлэгдээгүй
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    allComplaintsPercent() {
      let percent = (this.allComplaints / this.allComplaints) * 100;
      return percent.toFixed(1);
    },
    pollPercent() {
      let percent =
        (this.allComplaintsParentPoll / this.allComplaintsParent) * 100;
      return percent.toFixed(1);
    },
    complaintPercent() {
      let percent =
        (this.allComplaintsParentComplaint / this.allComplaintsParent) * 100;
      return percent.toFixed(1);
    },
    contributionPercent() {
      let percent =
        (this.allComplaintsParentContribution / this.allComplaintsParent) * 100;
      return percent.toFixed(1);
    },
    ethicsPercent() {
      let percent =
        (this.allComplaintsParentEthics / this.allComplaintsParent) * 100;
      return percent.toFixed(1);
    },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      //   console.log(newVal, oldVal);
      this.isShow = false;
      axios
        .post("/hr/allComplaintsStatisticInfoYear", {
          year_id: newVal,
        })
        .then((response) => {
          // Өгөгдөл хоослох
          this.allComplaints = [];
          this.allComplaintsPublic = [];
          this.allComplaintsParent = [];
          this.allComplaintsCompleteDecPublicParent = [];
          this.allComplaintsUnCompleteDecPublicParent = [];
          this.allComplaintsParentPoll = [];
          this.allComplaintsParentComplaint = [];
          this.allComplaintsParentContribution = [];
          this.allComplaintsParentEthics = [];
          this.allComplaintsParentDecision = [];
          this.series = [];
          this.options.labels = [];
          this.series1 = [];
          this.options1.labels = [];
          this.series2 = [];
          this.options2.labels = [];

          this.series3[0].data = [];

          this.allComplaints = response.data.allComplaints;
          // Эхний гарфик дотоод болон гадаад
          this.allComplaintsPublic = response.data.allComplaintsPublic;
          this.allComplaintsParent = response.data.allComplaintsParent;
          this.series.push(this.allComplaintsParent);
          this.options.labels.push("Дотоод");
          this.series.push(this.allComplaintsPublic);
          this.options.labels.push("Гадаад");

          //   2 дахь график
          // Гадны хэрэглэгчдийн гомдол саналыг шийдвэрлэсэн.
          this.allComplaintsCompleteDecPublicParent =
            response.data.allComplaintsCompleteDecPublicParent;
          this.series1.push(this.allComplaintsCompleteDecPublicParent);
          this.options1.labels.push("Шийдвэрлэсэн");
          this.allComplaintsUnCompleteDecPublicParent =
            response.data.allComplaintsUnCompleteDecPublicParent;
          this.series1.push(this.allComplaintsUnCompleteDecPublicParent);
          this.options1.labels.push("Шийдвэрлээгүй");
          //   3 дахь график
          //   Дотоод хэрэглэгчдийн гомдол саналыг шийдвэрлэсэн.
          this.series2.push(response.data.allComplaintsParentCompleteDec);
          this.options2.labels.push("Шийдвэрлэсэн");
          this.allComplaintsUnCompleteDecPublicParent =
            response.data.allComplaintsUnCompleteDecPublicParent;
          this.series2.push(response.data.allComplaintsParentUnCompleteDec);
          this.options2.labels.push("Шийдвэрлээгүй");
          // 4 дэх стат Санал, гомдол, хамтран ажиллах, ёс зүй
          this.allComplaintsParentPoll = response.data.allComplaintsParentPoll;
          this.allComplaintsParentComplaint =
            response.data.allComplaintsParentComplaint;
          this.allComplaintsParentContribution =
            response.data.allComplaintsParentContribution;
          this.allComplaintsParentEthics =
            response.data.allComplaintsParentEthics;
          // 5 дахь график
          // Мэргэжлийн багаар утгуудыг гаргах
          let allComplaintsDepartments = response.data.departmentsAllComplaint;

          let filteredDeps = allComplaintsDepartments.filter(function (e) {
            if (e.niilber > 0) return e;
          });
          filteredDeps.forEach((element) => {
            this.series3[0].data.push({
              x: element.department_code,
              y: element.niilber,
            });
          });

          //Шийдвэрлэгдсэн асуудлууд болон шийдвэрлэгдээгүй асуудлууд
          this.departments = response.data.departments;
          this.allComplaintsDecisions = response.data.allComplaintsDecisions;
          this.allComplaintsUnDecisions =
            response.data.allComplaintsUnDecisions;
          this.allComplaintsDecisionsPublicParent =
            response.data.allComplaintsDecisionsPublicParent;
          this.allComplaintsUnDecisionsPublicParent =
            response.data.allComplaintsUnDecisionsPublicParent;

          this.isShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    getAllComplaint() {
      axios
        .get("/hr/allComplaintsStatisticInfo")
        .then((response) => {
          this.allYears = response.data.allYears;

          this.allComplaints = response.data.allComplaints;
          // Эхний гарфик дотоод болон гадаад
          this.allComplaintsPublic = response.data.allComplaintsPublic;
          this.allComplaintsParent = response.data.allComplaintsParent;
          this.series.push(this.allComplaintsParent);
          this.options.labels.push("Дотоод");
          this.series.push(this.allComplaintsPublic);
          this.options.labels.push("Гадаад");

          //   2 дахь график
          // Гадны хэрэглэгчдийн гомдол саналыг шийдвэрлэсэн.
          this.allComplaintsCompleteDecPublicParent =
            response.data.allComplaintsCompleteDecPublicParent;
          this.series1.push(this.allComplaintsCompleteDecPublicParent);
          this.options1.labels.push("Шийдвэрлэсэн");
          this.allComplaintsUnCompleteDecPublicParent =
            response.data.allComplaintsUnCompleteDecPublicParent;
          this.series1.push(this.allComplaintsUnCompleteDecPublicParent);
          this.options1.labels.push("Шийдвэрлээгүй");
          //   3 дахь график
          //   Дотоод хэрэглэгчдийн гомдол саналыг шийдвэрлэсэн.
          this.series2.push(response.data.allComplaintsParentCompleteDec);
          this.options2.labels.push("Шийдвэрлэсэн");
          this.allComplaintsUnCompleteDecPublicParent =
            response.data.allComplaintsUnCompleteDecPublicParent;
          this.series2.push(response.data.allComplaintsParentUnCompleteDec);
          this.options2.labels.push("Шийдвэрлээгүй");
          // 4 дэх стат Санал, гомдол, хамтран ажиллах, ёс зүй
          this.allComplaintsParentPoll = response.data.allComplaintsParentPoll;
          this.allComplaintsParentComplaint =
            response.data.allComplaintsParentComplaint;
          this.allComplaintsParentContribution =
            response.data.allComplaintsParentContribution;
          this.allComplaintsParentEthics =
            response.data.allComplaintsParentEthics;
          // 5 дахь график
          // Мэргэжлийн багаар утгуудыг гаргах
          let allComplaintsDepartments = response.data.departmentsAllComplaint;

          let filteredDeps = allComplaintsDepartments.filter(function (e) {
            if (e.niilber > 0) return e;
          });
          filteredDeps.forEach((element) => {
            this.series3[0].data.push({
              x: element.department_code,
              y: element.niilber,
            });
          });

          //Шийдвэрлэгдсэн асуудлууд болон шийдвэрлэгдээгүй асуудлууд
          this.departments = response.data.departments;
          this.allComplaintsDecisions = response.data.allComplaintsDecisions;
          this.allComplaintsUnDecisions =
            response.data.allComplaintsUnDecisions;
          this.allComplaintsDecisionsPublicParent =
            response.data.allComplaintsDecisionsPublicParent;
          this.allComplaintsUnDecisionsPublicParent =
            response.data.allComplaintsUnDecisionsPublicParent;

          this.isShow = true;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    selectDepartment(value) {
      //   console.log(value);
      let dep = this.departments.filter((el) => el.department_id == value);
      return dep[0]["department_code"];
    },
  },
  created() {
    this.getAllComplaint();
    Fire.$on("loadComplains", () => {
      this.getAllComplaint();
    });
  },
};
</script>

<style></style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
