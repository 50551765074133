<template>
  <div class="row pt-3">
    <div class="col-md-3">
      <!-- Profile Image -->
      <div class="card card-primary card-outline">
        <div class="card-body box-profile">
          <div class="text-center">
            <img
              v-if="getMyInfoStudent.student_profile_photo_url != null"
              :src="getMyInfoStudent.student_profile_photo_url"
              alt="Зураг"
              class="profile-user-img img-fluid attachment-img mb-2"
            />
            <img
              v-else
              src="/images/users/user.png"
              alt="Зураг"
              class="profile-user-img img-fluid attachment-img mb-2"
            />
          </div>

          <h3 class="profile-username text-center">
            {{ getMyInfoStudent.student_last_name | capitalizeAndEkhniiVseg }}.
            {{ getMyInfoStudent.student_name }}
          </h3>

          <p class="text-muted text-center">
            {{ getMyInfoStudent.position_name }}
          </p>
          <ul class="list-group list-group-unbordered mb-3">
            <li class="list-group-item">
              <b>Ангийн төрөл</b>
              <a class="float-right">{{ getOtherInfo.class_cabinet }}</a>
            </li>
            <li class="list-group-item">
              <b>Анги</b>
              <a class="float-right">{{ getOtherInfo.full_name }}</a>
            </li>
            <li class="list-group-item">
              <b>Өрөөний дугаар</b>
              <a class="float-right">{{ getOtherInfo.class_room }}</a>
            </li>
            <li class="list-group-item">
              <b>Ангийн багш</b>
              <a class="float-right"
                >{{
                  getOtherInfo.teacher_last_name | capitalizeAndEkhniiVseg
                }}.{{ getOtherInfo.teacher_name }}</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
    <div class="col-md-9">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Үндсэн мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#edit" data-toggle="tab"
                >Мэдээлэл засах</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#password" data-toggle="tab"
                >Нууц үг солих</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="active tab-pane" id="activity">
              <!-- Post -->
              <div class="post">
                <div class="user-block">
                  <img
                    v-if="getMyInfoStudent.student_profile_photo_url != null"
                    :src="getMyInfoStudent.student_profile_photo_url"
                    alt="user-avatar"
                    class="img-circle img-bordered-sm"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-circle img-bordered-sm"
                  />
                  <span class="username">
                    <a href="#"
                      >{{ getMyInfoStudent.student_last_name }}
                      {{ getMyInfoStudent.student_name }}</a
                    >
                  </span>
                  <span class="description"
                    >Системд бүртгэсэн -
                    {{
                      getMyInfoStudent.user_created_at | dateYearMonthDay
                    }}</span
                  >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="col-sm-3"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-right text-bold">Регистр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_reg }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Ургийн овог:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_family_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Эцэг/Эх-ийн нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_last_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Хүйс:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_gender }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Төрсөн өдөр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_birthDate }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Иргэншил:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_citizenship }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">
                          Төрсний гэрчилгээний дугаар:
                        </td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_birth_ger_num }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Эх хэл:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_ekh_khel }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Өнчин эсэх:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_unchin_esekh }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Асран хамгаалагч:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_asran_kham }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">
                          Асран хамгаалагчын мэдээлэл:
                        </td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_asran_kham_med }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Цусны бүлэг:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_tsus_bul }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.user-block -->
              </div>
              <!-- /.post -->
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="edit">
              <form>
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Регистр</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_reg'),
                        },
                      ]"
                      name="student_reg"
                      v-model="form.student_reg"
                      placeholder="Регистр"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Ургийн овог</label>
                    <input
                      disabled
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_family_name'),
                        },
                      ]"
                      name="student_family_name"
                      v-model="form.student_family_name"
                      placeholder="Ургийн овог"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Овог</label>
                    <input
                      disabled
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_last_name'),
                        },
                      ]"
                      name="student_last_name"
                      v-model="form.student_last_name"
                      placeholder="Овог"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Нэр</label>
                    <input
                      disabled
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_name'),
                        },
                      ]"
                      name="student_name"
                      v-model="form.student_name"
                      placeholder="Нэр"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Нас</label>
                    <input
                      type="number"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_age'),
                        },
                      ]"
                      name="student_age"
                      v-model="form.student_age"
                      placeholder="Нас"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Хүйс</label>
                    <b-form-select
                      v-model="form.student_gender"
                      :options="genders"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4">
                    <label>Төрсөн он сар өдөр</label>
                    <input
                      type="date"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_birthDate'),
                        },
                      ]"
                      name="student_birthDate"
                      v-model="form.student_birthDate"
                      placeholder="Төрсөн он сар өдөр"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Иргэншил</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_citizenship'),
                        },
                      ]"
                      name="student_citizenship"
                      v-model="form.student_citizenship"
                      placeholder="Иргэншил"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Төрсний гэрчилгээний дугаар</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'student_birth_ger_num'
                          ),
                        },
                      ]"
                      name="student_birth_ger_num"
                      v-model="form.student_birth_ger_num"
                      placeholder="Төрсний гэрчилгээний дугаар"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Эх хэл</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_ekh_khel'),
                        },
                      ]"
                      name="student_ekh_khel"
                      v-model="form.student_ekh_khel"
                      placeholder="Эх хэл"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Цусны бүлэг</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_tsus_bul'),
                        },
                      ]"
                      name="student_tsus_bul"
                      v-model="form.student_tsus_bul"
                      placeholder="Цусны бүлэг"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Асран хамгаалагч</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('student_asran_kham'),
                        },
                      ]"
                      name="student_asran_kham"
                      v-model="form.student_asran_kham"
                      placeholder="Асран хамгаалагч"
                    />
                  </div>
                  <div class="col-sm-8">
                    <label>Асран хамгаалагчийн мэдээлэл</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'student_asran_kham_med'
                          ),
                        },
                      ]"
                      name="student_asran_kham_med"
                      v-model="form.student_asran_kham_med"
                      placeholder="Асран хамгаалагчийн мэдээлэл"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Өнчин эсэх</label>
                    <b-form-select
                      v-model="form.student_unchin_esekh"
                      :options="opts"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="updateStudent()"
                    >
                      Хадгалах
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="password">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <!-- /.card-header -->
                    <div class="card-body">
                      <form>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг:"
                          label-for="student_pass"
                        >
                          <b-form-input
                            type="password"
                            v-model="student_pass"
                            placeholder="Нууц үг"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('student_pass'), }, ]"
                          ></b-form-input>
                          <has-error
                            :form="form"
                            field="student_pass"
                          ></has-error>
                        </b-form-group>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг давтах:"
                          label-for="student_pass_verify"
                        >
                          <b-form-input
                            type="password"
                            v-model="student_pass_verify"
                            placeholder="Нууц үг давтах"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('student_pass_verify'), }, ]"
                          ></b-form-input>
                          <has-error
                            :form="form"
                            field="student_pass_verify"
                          ></has-error>
                        </b-form-group>
                      </form>
                      <h6
                        class="text-success"
                        v-if="
                          errorPassword ==
                          'Таны нууц үгийг амжилттай шинэчиллээ!'
                        "
                      >
                        {{ errorPassword }}
                      </h6>
                      <h6 class="text-danger" v-else>{{ errorPassword }}</h6>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
              <div class="row pl-2">
                <b-button
                  @click.prevent="resetPassword()"
                  variant="primary"
                  type="submit"
                >
                  Нууц үг солих
                </b-button>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.nav-tabs-custom -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      getMyInfoStudent: [],
      getOtherInfo: [],
      errorPassword: "",
      student_pass: "",
      student_pass_verify: "",
      form: new Form({
        user_id: "",
        student_family_name: "",
        student_last_name: "",
        student_name: "",
        student_gender: "",
        student_birthDate: "",
        student_age: "",
        student_citizenship: "",
        student_reg: "",
        student_birth_ger_num: "",
        student_ekh_khel: "",
        student_unchin_esekh: "",
        student_asran_kham: "",
        student_asran_kham_med: "",
        student_tsus_bul: "",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      opts: [
        { value: null, text: "Сонгох" },
        { value: "Тийм", text: "Тийм" },
        { value: "Үгүй", text: "Үгүй" },
      ],
    };
  },
  methods: {
    getMyInfo() {
      console.log("Ажиллаж байна. getMyInfo");
      axios
        .get("/student/getMyInfo")
        .then((res) => {
          this.getMyInfoStudent = res.data.getMyInfoStudent;
          this.getOtherInfo = res.data.getOtherInfo;
          this.form.reset();
          this.form.fill(this.getMyInfoStudent);
        })
        .catch();
    },
    editGenInfo() {
      console.log("Засах мэдээллээ оруулна уу?");
    },
    updateStudent() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.form
        .put("/student/updateStudentInfo/" + this.getMyInfoStudent.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Багшийн мэдээллийг амжилттай заслаа!", "Шинэчилсэн");
          Fire.$emit("loadStart");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    resetPassword() {
      if (this.student_pass == this.student_pass_verify) {
        axios
          .post("/student/changePassword", {
            password: this.student_pass,
          })
          .then((res) => {
            Swal.fire(
              "Нууц өөрчлөгдлөө!",
              "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
              "success"
            );
            this.student_pass = "";
            this.student_pass_verify = "";
            this.errorPassword = "Таны нууц үгийг амжилттай шинэчиллээ!";
            this.loaded = false;
          })
          .catch((err) => {
            this.errorPassword =
              "Нууц үг нь хамгийн багадаа 6 оронтой байх ёстой!";
          });
      } else {
        this.errorPassword = "Нууц үгийг зөв давтан оруулна уу!";
      }
    },
  },
  created() {
    this.getMyInfo();
    Fire.$on("loadStart", () => {
      this.getMyInfo();
    });
  },
};
</script>

<style>
</style>
