<template>
  <div class="row">
    <h3 class="card-title col-lg-12 m-3">
      <strong
        >Суралцагчдын бие бялдрын хөгжлийг үнэлэх шалгуур үзүүлэлтүүд ба хэмжүүр</strong
      >
    </h3>
    <b-overlay :show="physicalIsLoaded" rounded="sm" class="col-lg-12 m-3">
      <div v-for="student in physicalStudents" :key="student.id">
        <div class="card card-widget widget-user">
          <div class="widget-user-header headerYearData">
            <h3 class="widget-user-username">
              {{ student.student_full_name }}
            </h3>
            <h5 class="widget-user-desc">{{ student.email }}</h5>
          </div>
          <div class="widget-user-image">
            <img
              v-if="student.student_profile_photo_url"
              class="img-circle elevation-2"
              :src="student.student_profile_photo_url"
              alt="User Avatar"
            />
            <img
              v-else
              class="img-circle elevation-2"
              src="/images/users/user.png"
              alt="User Avatar"
            />
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-4 border-right">
                <div class="description-block">
                  <h5 class="description-header">
                    {{ student.student_birthDate }}
                  </h5>
                  <span class="description-text">Төрсөн өдөр</span>
                </div>
                <!-- /.description-block -->
              </div>
              <!-- /.col -->
              <div class="col-sm-4 border-right">
                <div class="description-block">
                  <h5 class="description-header">
                    {{ student.nas }}
                  </h5>
                  <span class="description-text">Нас</span>
                </div>
                <!-- /.description-block -->
              </div>
              <!-- /.col -->
              <div class="col-sm-4">
                <div class="description-block">
                  <h5 class="description-header">
                    {{ student.student_gender }}
                  </h5>
                  <span class="description-text">Хүйс</span>
                </div>
                <!-- /.description-block -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
        </div>
        <div v-if="student.my_body_index_list.length > 0" class="row m-0 mt-2">
          <div class="col-lg-12">
            <div class="card card-warning" id="strategyCard">
              <div class="card-header">
                <h3 class="card-title">Биеийн хэмжилт</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <physical-body-chart
                    class="col-lg-4"
                    v-if="showChart"
                    title="Биеийн жингийн индекс"
                    :nameArr="student.bodyIndexNames"
                    :indexArr="student.bodyIndexArr"
                    :schoolAvgArr="student.bodySchoolIndexAvgArr"
                    :classAvgArr="student.bodyClassIndexAvgArr"
                  ></physical-body-chart>
                  <physical-body-chart
                    class="col-lg-4"
                    v-if="showChart"
                    title="Өндрийн хэмжилт"
                    :nameArr="student.bodyIndexNames"
                    :indexArr="student.bodyHeightArr"
                    :schoolAvgArr="student.bodySchoolHeightAvgArr"
                    :classAvgArr="student.bodyClassHeightAvgArr"
                  ></physical-body-chart>
                  <physical-body-chart
                    class="col-lg-4"
                    v-if="showChart"
                    title="Жингийн хэмжилт"
                    :nameArr="student.bodyIndexNames"
                    :indexArr="student.bodyWeightArr"
                    :schoolAvgArr="student.bodySchoolWeightAvgArr"
                    :classAvgArr="student.bodyClassWeightAvgArr"
                  ></physical-body-chart>
                </div>
                <div class="row">
                  <div
                    class="col-lg-6"
                    v-for="(bodyIndex, index) in student.my_body_index_list"
                    :key="bodyIndex.id"
                  >
                    <div :class="['small-box', getIndexColor(bodyIndex.index)]">
                      <div class="inner">
                        <div class="row">
                          <div class="col-lg-4">
                            <small>Биеийн жингийн индекс</small>
                            <h3>{{ bodyIndex.index }}</h3>
                            <p>
                              <b-badge variant="light">
                                {{ getIndexValue(bodyIndex.index) }}
                              </b-badge>
                            </p>
                          </div>
                          <div class="col-lg-4">
                            <small>Өндөр:</small>
                            <b-badge variant="light"> {{ bodyIndex.height }} см </b-badge>
                            <br />
                            <small>Жин:</small>
                            <b-badge variant="light"> {{ bodyIndex.weight }} кг </b-badge>
                            <p>
                              <b-badge variant="light">
                                {{ bodyIndex.description }}
                              </b-badge>
                            </p>
                          </div>
                          <div class="col-lg-4">
                            <b-badge variant="light"> {{ index + 1 }}-р хэмжилт </b-badge>
                            <br />
                            <b-badge variant="light" class="text-muted">
                              Огноо:
                              {{ bodyIndex.created_at | dateYearMonthDay }}
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div v-if="student.my_physicals.length > 0" class="row m-0">
          <div
            class="col-lg-12"
            v-for="myPhysical in student.my_physicals"
            :key="myPhysical.id"
          >
            <div
              class="card card-secondary mb-3"
              id="strategyCard"
              v-if="myPhysical.guitsetgelList.length > 0"
            >
              <div class="card-header">
                <h3 class="card-title">
                  <b-badge variant="light" class="text-wrap" pill>
                    {{ myPhysical.physical_type.name }}
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    {{ myPhysical.name }}
                  </b-badge>
                </h3>
              </div>
              <div class="card-body px-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="card card-light mb-3"
                      v-for="guitsetgel in myPhysical.guitsetgelList"
                      :key="guitsetgel.id"
                    >
                      <div class="card-header studentCardHeader">
                        <h3 class="card-title">
                          <b-badge variant="light" class="text-wrap" pill>
                            Багш:
                            <img
                              alt="Avatar"
                              v-b-tooltip.hover.top.html="
                                '<dd><img src=' +
                                guitsetgel.my_teacher.profile_photo_url +
                                ' style=' +
                                'width:180px!important' +
                                '></dd><dt>Үнэлгээ оруулсан багш</dt><dd>' +
                                guitsetgel.my_teacher.teacher_last_name +
                                ' <span class=text-uppercase>' +
                                guitsetgel.my_teacher.teacher_name +
                                '</span></dd></dl>'
                              "
                              class="table-avatar"
                              :src="
                                guitsetgel.my_teacher.profile_photo_url
                                  ? guitsetgel.my_teacher.profile_photo_url
                                  : '/images/users/user.png'
                              "
                            />
                            {{
                              guitsetgel.my_teacher.teacher_last_name
                                | capitalizeAndEkhniiVseg
                            }}
                            {{ guitsetgel.my_teacher.teacher_name }}
                          </b-badge>
                        </h3>
                        <div class="card-tools">
                          <b-badge
                            :variant="
                              pointColor(guitsetgel.my_physical_measure.evalution)
                            "
                            pill
                          >
                            Үнэлгээ:
                            {{ guitsetgel.my_physical_measure.point }}
                            {{ guitsetgel.my_physical_measure.evalution }}
                            |
                            {{ guitsetgel.count_value }}
                          </b-badge>
                        </div>
                      </div>
                      <div class="card-body">
                        Үнэлгээний тайлбар:
                        <b-badge variant="light" class="text-wrap">
                          {{ guitsetgel.my_physical_measure.description }}
                        </b-badge>
                        <div>
                          <small class="">Гүйцэтгэл оруулсан огноо:</small>
                          <b-badge variant="light">
                            {{ guitsetgel.created_at | dateYearMonthDay }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <physical-student-chart
                      v-if="showChart"
                      :nameArr="myPhysical.nameArr"
                      :schoolAvgArr="myPhysical.schoolAvgArr"
                      :classAvgArr="myPhysical.classAvgArr"
                      :childArr="myPhysical.childArr"
                      :minArr="myPhysical.minArr"
                      :maxArr="myPhysical.maxArr"
                    ></physical-student-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="callout callout-light">
          <p class="text-muted">Үнэлгээ оруулаагүй байна.</p>
        </div>
      </div>
    </b-overlay>
    <!-- /.card-body -->
  </div>
</template>

<script>
import physicalStudentChart from "./physicalStudentChart.vue";
import physicalBodyChart from "./physicalBodyChart.vue";
export default {
  data() {
    return {
      physicalIsLoaded: false,
      showChart: false,
      student: null,
      physicalStudents: [],
    };
  },
  components: {
    physicalStudentChart: physicalStudentChart,
    physicalBodyChart: physicalBodyChart,
  },
  methods: {
    getIndexColor(index) {
      if (index < 18.5) {
        return "bg-primary";
      } else if (index >= 18.5 && index <= 24.9) {
        return "bg-success";
      } else if (index >= 25 && index <= 29.9) {
        return "bg-warning";
      } else if (index >= 30 && index <= 34.9) {
        return "bg-danger";
      } else if (index >= 35 && index <= 39.9) {
        return "bg-danger";
      } else if (index >= 40) {
        return "bg-danger";
      }
    },
    getIndexValue(index) {
      if (index < 18.5) {
        return "Туранхай";
      } else if (index >= 18.5 && index <= 24.9) {
        return "Хэвийн жинтэй";
      } else if (index >= 25 && index <= 29.9) {
        return "Илүүдэл жинтэй";
      } else if (index >= 30 && index <= 34.9) {
        return "Таргалалт I зэрэг";
      } else if (index >= 35 && index <= 39.9) {
        return "Таргалалт II зэрэг";
      } else if (index >= 40) {
        return "Таргалалт III зэрэг";
      }
    },
    pointColor(evalution) {
      let variantValue = "";
      if (evalution.includes("A")) variantValue = "success";
      else if (evalution.includes("B")) variantValue = "primary";
      else if (evalution.includes("C")) variantValue = "warning";
      else if (evalution.includes("D")) variantValue = "dark";
      else if (evalution.includes("F")) variantValue = "danger";

      return variantValue;
    },
    getChildsPhysical() {
      this.physicalIsLoaded = true;
      axios
        .get("/project_parent/getChildsPhysical")
        .then((response) => {
          this.physicalStudents = response.data.physicalStudents;
          this.showChart = true;
          this.physicalIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getChildsPhysical();
  },
};
</script>

<style scoped>
.studentCardHeader {
  background-color: #fff !important;
}
.callout {
  margin-bottom: 0px !important;
}
.physicCardHeader {
  background-color: #3bc7c4 !important;
  color: #fff !important;
}
.badgePhysic {
  font-size: 90%;
}
</style>
