<template>
  <div class="card">
    <div class="card-header" style="background-color: #ec742545">
      <h5 class="card-title"><b>Амжилтын статистик</b></h5>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <multiselect
        v-model="yearId"
        deselect-label="Хасах"
        select-label="Сонгох"
        track-by="id"
        label="name"
        :custom-label="customYearName"
        placeholder="Шалгалтын төрөл сонгоно уу"
        :options="years"
        :searchable="true"
        :allow-empty="true"
        @input="getSuccessesStat"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{
              option.finish_date | dateYear
            }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <b-overlay :show="show" rounded="sm">
        <div v-show="yearId != null">
          <table class="table table-bordered text-center">
            <thead>
              <tr>
                <th>Эзэлсэн байр</th>
                <th>Алт</th>
                <th>Мөнгө</th>
                <th>Хүрэл</th>
                <th>Тусгай байр(4-10)</th>
                <th>Оролцсон</th>
                <th>Нийт</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(counts, turul) in turulBairCounts" :key="turul">
                <td>
                  {{
                    turul === "1"
                      ? "Анги"
                      : turul === "2"
                      ? "Сургууль"
                      : turul === "3"
                      ? "Дүүрэг"
                      : turul === "4"
                      ? "Нийслэл"
                      : turul === "5"
                      ? "Улс"
                      : turul === "6"
                      ? "Олон улс"
                      : turul === "0"
                      ? "Бусад"
                      : turul
                  }}
                </td>
                <td v-for="(count, bair) in counts.bairCounts" :key="bair">
                  {{ count !== null ? count : 0 }}
                </td>
                <th>{{ counts.totalBairCount }}</th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Нийт</th>
                <th v-for="turulSum in turulSums" :key="turulSum.id">
                  {{ turulSum }}
                </th>
                <th>
                  {{ this.totalCount }}
                </th>
              </tr>
            </tfoot>
          </table>
          <div class="row">
            <div class="col-12 col-sm-6">
              <span
                v-if="
                  seriesTurul.length != 0 && optionsTurul.labels.length != 0
                "
              >
                <apexchart
                  type="donut"
                  class="pieChart"
                  height="350"
                  :options="optionsTurul"
                  :series="seriesTurul"
                >
                </apexchart>
              </span>
            </div>
            <div class="col-12 col-sm-6">
              <span
                v-if="seriesBair.length != 0 && optionsBair.labels.length != 0"
              >
                <apexchart
                  type="donut"
                  class="pieChart"
                  height="350"
                  :options="optionsBair"
                  :series="seriesBair"
                >
                </apexchart>
              </span>
            </div>
          </div>
        </div>
      </b-overlay>

      <!-- Ажилтын төрөл буюу student_turul талбар сонгоно уу -->
      <div class="col-lg-12 mt-12" v-show="yearId != null">
        <multiselect
          v-model="statTurul_id"
          select-label="Сонгох"
          selected-label="Сонгогдсон"
          deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
          label="student_turul"
          track-by="name"
          placeholder="Амжилтын төрөл сонгоно уу"
          :options="turul"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong>{{ option.student_turul }} </strong></template
          >
        </multiselect>

        <span
          v-if="seriesSchool.length != 0 && optionsSchool.labels.length != 0"
        >
          <apexchart
            type="donut"
            class="pieChart"
            height="350"
            :options="optionsSchool"
            :series="seriesSchool"
          >
          </apexchart>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //   subjs: [],
      //   avg: [],
      turulBairCounts: {},
      bairs: [],
      turulSums: [],
      yearId: null,
      turuls: [],
      bairCounts: [],
      totalCount: [],
      show: false,
      statTurul_id: "",
      turul: [
        { name: 1, student_turul: "Анги" },
        { name: 2, student_turul: "Сургууль" },
        { name: 3, student_turul: "Дүүрэг" },
        { name: 4, student_turul: "Нийслэл" },
        { name: 5, student_turul: "Улс" },
        { name: 6, student_turul: "Олон улс" },
      ],
      seriesBair: [],
      optionsBair: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Медалийн чанараар",
                },
              },
            },
          },
        },
      },
      seriesTurul: [],
      optionsTurul: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Төрөлөөр",
                },
              },
            },
          },
        },
      },
      seriesSchool: [],
      optionsSchool: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    statTurul_id: function (newVal, oldVal) {
      this.optionsSchool.labels = [];
      this.seriesSchool = [];
      if (newVal != "") {
        axios
          .post("/manager/getSuccessesStatTurul", {
            year: this.yearId.id,
            turul: newVal.name,
          })
          .then((res) => {
            this.bairCounts = res.data.bairCounts;
            this.seriesSchool = res.data.bairCounts;
            this.optionsSchool.labels = res.data.bairNames;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getSuccessesStat() {
      this.show = true;
      this.optionsTurul.labels = [];
      this.seriesTurul = [];
      this.optionsBair.labels = [];
      this.seriesBair = [];
      axios
        .get("/manager/getSuccessesStat/" + this.yearId.id)
        .then((response) => {
          const data = response.data.turulBairCounts;
          this.turulSums = response.data.turulSum;
          this.totalCount = response.data.totalCount;
          this.seriesBair = response.data.totalBairCounts;
          this.optionsBair.labels = response.data.turulNames;
          this.seriesTurul = response.data.turulSum;
          this.optionsTurul.labels = response.data.bairNames;

          const allBairs = new Set();
          Object.values(data).forEach((counts) => {
            Object.keys(counts).forEach((bair) => {
              allBairs.add(bair);
            });
          });

          Object.values(data).forEach((counts) => {
            allBairs.forEach((bair) => {
              if (!(bair in counts)) {
                counts[bair] = 0;
              }
            });
          });
          //   this.series[0].data = response.data.turulBairCounts;
          this.turulBairCounts = data;
          this.bairs = Array.from(allBairs); // Update the bairs array with all bair keys

          this.show = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {},
};
</script>
