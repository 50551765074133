<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Модуль сургалтууд</h3>
          <!-- <search-student
            ></search-student> -->
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="8">
                  <b-row v-if="userInfo.name == 'cipalprin'">
                    <b-col sm="5">
                      <b-form-input
                        id="input-1"
                        v-model="formTraining.taining_name"
                        placeholder="Модуль сургалтын нэр..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="5">
                      <b-form-input
                        id="input-2"
                        v-model="formTraining.taining_area"
                        placeholder="Хамрах хүрээ"
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="trainingCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="trainingUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="trainingClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-card no-body class="text-center">
                      <div class="bg-secondary text-light">
                        Модуль сургалт бүртгэхээр бол админд хандана уу!
                      </div>
                    </b-card>
                  </b-row>
                </b-col>
                <!-- <b-col sm="2"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="success" @click.prevent="getAllTask()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Модуль сургалтын нэр</th>
                <th>Хамрах хүрээ</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(train, index) in allTrainings" :key="train.id">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    train.taining_name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    train.taining_area
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a href="#" class="badge bg-primary" @click="editTrain(train)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="showSubTrain(train)"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="trainDelete(train)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ train.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ train.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
            <!-- <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Дундаж /0 тооцсон/
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ unelgeeAvg }}%</strong>
                </td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Дундаж /0 тооцохгүй/
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ unelgee0Avg }}%</strong>
                </td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Нийт:
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <strong>{{ niit }}</strong>
                </td>
              </tr>
            </tbody> -->
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-if="showSubTrainMode">
      <div class="card card-primary">
        <div class="card-header" style="font-size: 70%">
          <h3 class="card-title" v-show="showSubTrainMode">
            Модуль сургалтанд дэд сургалт бүртгэх
          </h3>
          <h3 class="card-title" v-show="!showSubTrainMode">
            Модуль сургалтын дэд сургалт засах
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <!-- <b-form-select
                v-model="formSubTraining.training_teacher_id"
                :options="teachers"
                size="sm"
                text-field="emp_teacher_name"
                value-field="emp_user_id"
              ></b-form-select> -->
              <multiselect
                v-model="formSubTraining.training_teacher_id"
                :options="teachers"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Багш сонгох, Багш сонгохгүй бол автоматаар таныг багшаар бүртгэнэ."
                :custom-label="customLabel"
              ></multiselect>
              <b-form-checkbox
                v-show="editSubTrainMode"
                id="checkbox-1"
                v-model="formSubTraining.training_confirm_teacher"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                Багшид сургалтыг зөвшөөрөх эсэх
              </b-form-checkbox>
              <b-form-input
                style="font-size: 70%"
                v-model="formSubTraining.taining_course_title"
                placeholder="Дэд сургалтын сэдэв"
              ></b-form-input>
              <b-form-textarea
                style="font-size: 70%"
                id="textarea"
                v-model="formSubTraining.taining_course_content"
                placeholder="Дэд сургалтын агуулга болон сургалт болох газар, өрөө, байршил, огноо, бэлтгэх  зүйлс гэх мэт бусад шаардлагатай мэдээллээ оруулна уу?"
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formSubTraining.errors.has(
                      'taining_course_content'
                    ),
                  },
                ]"
              ></b-form-textarea>
              <b-form-input
                type="number"
                v-model="formSubTraining.taining_course_must_people"
                placeholder="Хамрагдвал зохих хүний тоо"
              ></b-form-input>
              <b-form-input
                type="number"
                v-model="formSubTraining.taining_course_time"
                placeholder="Сургалтын үргэлжлэх хугацаа минутаар оруулах"
              ></b-form-input>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editSubTrainMode"
            @click.prevent="createSubTrain()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editSubTrainMode"
            @click.prevent="updateSubTrain()"
          >
            Засах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editSubTrainMode"
            @click.prevent="clearSubTrain()"
          >
            Цэвэрлэх
          </button>
          <button class="btn btn-secondary" @click.prevent="cancelSubTrain()">
            Болих
          </button>
        </div>
        <!-- allSubTrainings -->
        <multiselect
          v-if="userInfo.name == 'cipalprin'"
          v-model="yearId"
          deselect-label="Хасах"
          select-label="Сонгох"
          track-by="id"
          :custom-label="customYearName"
          placeholder="Хичээлийн жил сонгоно уу?"
          :options="years"
          :searchable="true"
          :allow-empty="true"
        >
          <!-- @input="filterByMonthED" -->
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong
              >{{ option.start_date | dateYear }}-{{
                option.finish_date | dateYear
              }}
              - хичээлийн жил
            </strong></template
          >
        </multiselect>
        <div style="overflow-x: auto">
          <table class="table table-hover text-nowrap">
            <thead style="font-size: 70%">
              <tr role="row">
                <th>#</th>
                <th>Үйлдлүүд</th>
                <th>Багш</th>
                <th>Зөвшөөрсөн</th>
                <th>Сургалтын нэр</th>
                <th>Агуулга</th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody style="font-size: 60%">
              <tr
                v-for="(subTrain, index) in allSubTrainings"
                :key="subTrain.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editSubTrain(subTrain)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="enrollSubTrain(subTrain)"
                    ><b-icon icon="person-fill"></b-icon
                  ></a>
                  <!-- <a
                    href="#"
                    class="badge bg-danger"
                    @click="subTrainDelete(subTrain)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a> -->
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ subTrain.emp_teacher_last_name[0] }}.
                  {{ subTrain.emp_teacher_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <p class="h5 mb-2">
                    <b-icon
                      icon="check-square"
                      scale="1"
                      variant="success"
                      v-show="subTrain.training_confirm_teacher != 0"
                    ></b-icon>
                    <b-icon
                      v-show="subTrain.training_confirm_teacher == 0"
                      icon="x-circle"
                      scale="1"
                      variant="danger"
                    ></b-icon>
                  </p>
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    subTrain.taining_course_title
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    subTrain.taining_course_content
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ subTrain.created_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      v-show="showModalTeachers"
      size="xl"
      id="modal-train-teachers"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Сургалт"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              >Сургалт хийх хүний Овог Нэр</span
            >
            <span class="direct-chat-timestamp float-right">{{
              useInfosofSubTrains.created_at | dateYearMonthDay
            }}</span>
          </div>
          <img
            v-if="useInfosofSubTrains.profile_photo_url != null"
            :src="useInfosofSubTrains.profile_photo_url"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <img
            v-else
            src="/images/users/user.png"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <div class="direct-chat-text primary">
            {{ useInfosofSubTrains.taining_course_content }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сургалтанд хамрагдах багш нар</h3>
              </div>
              <div
                class="card-body table-responsive"
                v-if="useInfosofSubTrains != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Албан тушаал</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(enrollT, index) in enrolledTeachers"
                    :key="enrollT.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_teacher_last_name[0] }}.
                        {{ enrollT.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_position_name }}
                      </td>

                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(enrollT)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="fullName"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="recieved_employees"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacher()"
                >
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      departments: [
        { value: null, department_name: "Мэргэжлийн багш сонгоно уу?" },
      ],
      teachers: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //Training Эндээс эхэллээ...
      showEdit: false,
      allTrainings: [],
      formTraining: new Form({
        id: "",
        taining_name: "",
        taining_area: "",
      }),
      //subTrainings
      showSubTrainMode: false,
      editSubTrainMode: false,
      useSubTrainofParent: "",
      allSubTrainings: [],
      formSubTraining: new Form({
        id: "",
        training_id: "",
        training_teacher_id: "",
        training_confirm_teacher: 0,
        taining_course_title: "",
        taining_course_content: "",
        taining_course_must_people: null,
        taining_course_time: null,
      }),
      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      modalTeachers: [],

      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),
      yearId: null,
    };
  },
  watch: {
    yearId: function (newVal, oldVal) {
      this.getMySubTrainYear(newVal, this.useSubTrainofParent);
      // console.log(newVal, oldVal);
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    //trainiиg эхэлж байна...
    getAllTrainings() {
      axios
        .get("/manager/getAllTrainings")
        .then((res) => {
          //   console.log(res);
          this.allTrainings = res.data.getAllTrainings;
          this.teachers = res.data.teachers;
          this.filterForm.department_id = 0;
          this.departments = res.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    trainingCreate() {
      //   console.log(this.formTraining);
      axios
        .post("/manager/trainingCreate/", {
          taining_name: this.formTraining.taining_name,
          taining_area: this.formTraining.taining_area,
        })
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getTrainings");
          this.formTraining.reset();
        })
        .catch((err) => {
          console.log("errors");
        });
    },
    editTrain(trainingData) {
      //   console.log(trainingData);
      this.formTraining.fill(trainingData);
      this.showEdit = true;
    },
    trainingUpdate() {
      axios
        .put("/manager/trainingUpdate/", {
          id: this.formTraining.id,
          taining_name: this.formTraining.taining_name,
          taining_area: this.formTraining.taining_area,
        })
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getTrainings");
          this.formTraining.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          console.log("errors");
        });
    },
    trainDelete(train) {
      //   console.log(train);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Модуль сургалтыг устгахад, түүнд харьяалагдах бүх дэд сургалт болон холбогдсон суралцагчид устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/manager/trainingDestroy/${train.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getTrainings");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire(
            "Амжилттай устгалаа!",
            "Модуль сургалтыг устгасан.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцалсан",
            "Таны модуль сургалтыг устгасангүй :)",
            "error"
          );
        }
      });
    },
    trainingClear() {
      this.formTraining.reset();
      this.showEdit = false;
    },
    //дэд сургалтууд
    showSubTrain(train) {
      //   console.log(train);
      this.useSubTrainofParent = train;
      this.showSubTrainMode = false;
      this.formSubTraining.reset();
      axios
        .post("/manager/getMySubTrain", {
          parentTrain: train,
          yearId: this.yearId,
        })
        .then((res) => {
          //   console.log(res.data);
          this.showSubTrainMode = true;
          this.formSubTraining.training_id = train.id;
          this.allSubTrainings = res.data.subTrains;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMySubTrainYear($yearID, $parentTrainInfo) {
      // console.log($yearID, $parentTrainInfo);
      // this.useSubTrainofParent = train;
      // this.showSubTrainMode = false;
      this.formSubTraining.reset();
      axios
        .post("/manager/getMySubTrain", {
          parentTrain: $parentTrainInfo,
          yearId: $yearID,
        })
        .then((res) => {
          //   console.log(res.data);
          // this.showSubTrainMode = true;
          this.formSubTraining.training_id = $parentTrainInfo["id"];
          this.allSubTrainings = res.data.subTrains;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createSubTrain() {
      axios
        .post("/manager/createSubTrain", {
          training_id: this.formSubTraining.training_id,
          training_teacher_id: this.formSubTraining.training_teacher_id,
          training_confirm_teacher:
            this.formSubTraining.training_confirm_teacher,
          taining_course_title: this.formSubTraining.taining_course_title,
          taining_course_content: this.formSubTraining.taining_course_content,
          taining_course_must_people:
            this.formSubTraining.taining_course_must_people,
          taining_course_time: this.formSubTraining.taining_course_time,
        })
        .then((res) => {
          //   console.log(res.data);
          this.formSubTraining.reset();
          Fire.$emit("getSubTrainings");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSubTrainAgain() {
      this.formSubTraining.training_id = "";
      //   console.log(this.useSubTrainofParent);
      axios
        .post("/manager/getMySubTrain", {
          parentTrain: this.useSubTrainofParent,
        })
        .then((res) => {
          //   console.log(res.data);
          this.showSubTrainMode = true;
          this.formSubTraining.training_id = this.useSubTrainofParent["id"];
          this.allSubTrainings = res.data.subTrains;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editSubTrain(train) {
      //   console.log(train);

      this.editSubTrainMode = true;
      this.formSubTraining.fill(train);
      //   console.log(this.teachers);
      this.teachers.filter((el) => {
        // console.log(el.emp_teacher_name);
        if (el.emp_user_id == train.training_teacher_id) {
          //   console.log(el);
          this.formSubTraining.training_teacher_id = el;
        }
      });

      //   this.formSubTraining.training_teacher_id =
    },
    updateSubTrain() {
      axios
        .put("/manager/updateSubTrain", {
          id: this.formSubTraining.id,
          training_id: this.formSubTraining.training_id,
          training_teacher_id: this.formSubTraining.training_teacher_id,
          training_confirm_teacher:
            this.formSubTraining.training_confirm_teacher,
          taining_course_title: this.formSubTraining.taining_course_title,
          taining_course_content: this.formSubTraining.taining_course_content,
          taining_course_must_people:
            this.formSubTraining.taining_course_must_people,
          taining_course_time: this.formSubTraining.taining_course_time,
        })
        .then((res) => {
          Fire.$emit("getSubTrainings");
          //   console.log(res.data);
          //   this.formSubTraining.taining_course_title = "",
          //   this.formSubTraining.taining_course_content = "",
          this.clearSubTrain();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearSubTrain() {
      this.editSubTrainMode = false;
      this.formSubTraining.id = "";
      this.formSubTraining.training_id = "";
      this.formSubTraining.training_teacher_id = "";
      this.formSubTraining.training_confirm_teacher = 0;
      this.formSubTraining.taining_course_title = "";
      this.formSubTraining.taining_course_content = "";
      this.formSubTraining.taining_course_must_people = "";
      this.formSubTraining.taining_course_time = "";
      //   this.formSubTraining.reset();
    },
    cancelSubTrain() {
      this.showSubTrainMode = false;
      this.editSubTrainMode = false;
      this.useSubTrainofParent = "";
      this.formSubTraining.reset();
    },

    //Дэд сургалтанд багш бүртгэх
    enrollSubTrain(train) {
      this.getCourseStudents(train.id);
      this.showModalTeachers = true;
      this.useInfosofSubTrains = train;
      this.formAddTeacher.training_course_id = train.id;
      this.$bvModal.show("modal-train-teachers");
    },
    getCourseStudents(courseId) {
      //   this.enrolledTeachers = [];
      axios
        .get("/manager/getCourseStudents/" + courseId)
        .then((res) => {
          //   console.log(res.data);
          this.enrolledTeachers = res.data.trainTeachers;
          this.modalTeachers = res.data.teachers;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filteredFormTeacherList: function () {
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get("/manager/getDepTeachers/" + this.filterForm.department_id)
        .then((res) => {
          this.modalTeachers = res.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      //   console.log(this.formAddTeacher.department_id);
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get("/manager/getDepTeachers/" + this.formAddTeacher.department_id)
        .then((res) => {
          this.modalTeachers = res.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addTeacher() {
      axios
        .post("/manager/addTeacherTrainCourse", {
          enrollTeachers: this.formAddTeacher,
        })
        .then((response) => {
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          //   this.formAddTeacher.reset();
          this.getCourseStudents(this.formAddTeacher.training_course_id);
          //   console.log(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    removeTeacher(train) {
      //   console.log(train);
      axios
        .post("/manager/removeTeacherTrain", {
          removeTeacherOfTrain: train,
        })
        .then((response) => {
          this.getCourseStudents(train.training_course_id);
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    cancel() {
      this.showModalTeachers = false;
      this.useInfosofSubTrains = "";
      this.$bvModal.hide("modal-train-teachers");
      this.formAddTeacher.reset();
    },
    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllTrainings();
    Fire.$on("getTrainings", () => {
      this.getAllTrainings();
    });
    Fire.$on("getSubTrainings", () => {
      this.getSubTrainAgain();
    });
  },
};
</script>

<style></style>
