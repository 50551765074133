<template>
  <div class="row pt-3">
    <div class="col-md-3">
      <div
        class="card card-primary"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Миний хүүхдүүд</h3>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Овог</th>
                <th>Нэр(Дарна уу?)</th>
                <th style="width: 40px">Анги</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(child, i) in myChilds" :key="i">
                <td>{{ ++i }}</td>
                <td>{{ child.student_last_name }}</td>
                <td>
                  <a href="#" @click.prevent="editStudentInfo(child)">{{
                    child.student_name
                  }}</a>
                </td>
                <td>{{ child.full_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-lg-9" v-show="editMode">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Хүүхдийн мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#edit" data-toggle="tab"
                >Мэдээлэл засах</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#password" data-toggle="tab"
                >Нууц үг солих</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="active tab-pane" id="activity">
              <!-- Post -->
              <div class="post">
                <div class="user-block profile-container">
                  <!-- <my-img-view
                    v-if="hover == true"
                    :myImg="getMyInfoStudent.student_profile_photo_url"
                  ></my-img-view> -->
                  <!-- <my-img-zoom
                    v-if="getMyInfoStudent.student_profile_photo_url != null"
                    :myImgUrl="getMyInfoStudent.student_profile_photo_url"
                  ></my-img-zoom> -->
                  <img-rectangle-view-vue
                    v-if="getMyInfoStudent.student_profile_photo_url != null"
                    :myImgUrl="getMyInfoStudent.student_profile_photo_url"
                    :name="getMyInfoStudent.student_name"
                    :lastName="getMyInfoStudent.student_last_name"
                  ></img-rectangle-view-vue>
                  <!-- <img
                    :src="getMyInfoStudent.student_profile_photo_url"
                    alt="user-avatar"
                    class="img-circle"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    :class="{ zoomed: hover }"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-circle"
                  /> -->
                  <span class="username">
                    <a href="#"
                      >{{ getMyInfoStudent.student_last_name }}
                      {{ getMyInfoStudent.student_name }}</a
                    >
                  </span>
                  <span class="description"
                    >Системд бүртгэсэн -
                    {{
                      getMyInfoStudent.user_created_at | dateYearMonthDay
                    }}</span
                  >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="col-sm-3"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-right text-bold">Регистр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_reg }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Ургийн овог:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_family_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Эцэг/Эх-ийн нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_last_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Нэр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Хүйс:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_gender }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Төрсөн өдөр:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_birthDate }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Иргэншил:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_citizenship }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">
                          Төрсний гэрчилгээний дугаар:
                        </td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_birth_ger_num }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Эх хэл:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_ekh_khel }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Өнчин эсэх:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_unchin_esekh }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Асран хамгаалагч:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_asran_kham }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">
                          Асран хамгаалагчын мэдээлэл:
                        </td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_asran_kham_med }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right text-bold">Цусны бүлэг:</td>
                        <td class="text-left">
                          {{ getMyInfoStudent.student_tsus_bul }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.user-block -->
              </div>
              <!-- /.post -->
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="edit">
              <form>
                <div class="form-group row">
                  <div class="col-sm-4 form-group">
                    <label>Регистр</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_reg"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_reg'),
                        },
                      ]"
                      v-model="formEdit.student_reg"
                      placeholder="Регистр"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Ургийн овог</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_family_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_family_name'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_family_name"
                      placeholder="Ургийн овог"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Овог</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_last_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formEdit.errors.has('student_last_name'),
                        },
                      ]"
                      v-model="formEdit.student_last_name"
                      placeholder="Овог"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Нэр</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_name'),
                        },
                      ]"
                      v-model="formEdit.student_name"
                      placeholder="Нэр"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Нас</label>
                    <input
                      type="number"
                      class="form-control"
                      name="student_age"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_age'),
                        },
                      ]"
                      v-model="formEdit.student_age"
                      placeholder="Нас"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Хүйс</label>
                    <b-form-select
                      v-model="formEdit.student_gender"
                      :options="genders"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_gender'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Төрсөн он сар өдөр</label>
                    <input
                      type="date"
                      class="form-control"
                      name="student_birthDate"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formEdit.errors.has('student_birthDate'),
                        },
                      ]"
                      v-model="formEdit.student_birthDate"
                      placeholder="Төрсөн он сар өдөр"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Иргэншил</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_citizenship"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_citizenship'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_citizenship"
                      placeholder="Иргэншил"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Төрсний гэрчилгээний дугаар</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_birth_ger_num"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_birth_ger_num'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_birth_ger_num"
                      placeholder="Төрсний гэрчилгээний дугаар"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Эх хэл</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_ekh_khel"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_ekh_khel'),
                        },
                      ]"
                      v-model="formEdit.student_ekh_khel"
                      placeholder="Эх хэл"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Цусны бүлэг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_tsus_bul"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_tsus_bul'),
                        },
                      ]"
                      v-model="formEdit.student_tsus_bul"
                      placeholder="Цусны бүлэг"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Асран хамгаалагч</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_asran_kham"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formEdit.errors.has('student_asran_kham'),
                        },
                      ]"
                      v-model="formEdit.student_asran_kham"
                      placeholder="Асран хамгаалагч"
                    />
                  </div>
                  <div class="col-sm-8 form-group">
                    <label>Асран хамгаалагчийн мэдээлэл</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_asran_kham_med"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_asran_kham_med'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_asran_kham_med"
                      placeholder="Асран хамгаалагчийн мэдээлэл"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Өнчин эсэх</label>
                    <b-form-select
                      v-model="formEdit.student_unchin_esekh"
                      :options="opts"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_unchin_esekh'
                          ),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Аймаг, нийслэл</label>
                    <b-form-select
                      v-model="formEdit.student_duureg"
                      value-field="id"
                      text-field="name"
                      v-on:change="getSum"
                      :options="aimagOptions"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_duureg'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Сум, дүүрэг</label>
                    <b-form-select
                      v-model="formEdit.student_khoroo"
                      value-field="id"
                      text-field="name"
                      v-on:change="getKhoroo"
                      :options="sumOptions"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_khoroo'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Баг, хороо</label>
                    <b-form-select
                      v-model="formEdit.student_khoroolol"
                      value-field="code"
                      text-field="name"
                      :options="khorooOptions"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formEdit.errors.has('student_khoroolol'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Хотхон</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_bair"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_bair'),
                        },
                      ]"
                      v-model="formEdit.student_bair"
                      placeholder="Хотхон"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Байрны дугаар</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_dugaar"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_dugaar'),
                        },
                      ]"
                      v-model="formEdit.student_dugaar"
                      placeholder="Байрны дугаар"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Тоот</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_toot"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_toot'),
                        },
                      ]"
                      v-model="formEdit.student_toot"
                      placeholder="Тоот"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Амдардаг хаяг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_live_address"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_live_address'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_live_address"
                      placeholder="Амдардаг хаяг"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Утасны дугаар</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_phone_number"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_phone_number'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_phone_number"
                      placeholder="Утасны дугаар"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Имэйл хаяг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_email"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_email'),
                        },
                      ]"
                      v-model="formEdit.student_email"
                      placeholder="Имэйл хаяг"
                      disabled
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Social хаяг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_social_acc"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formEdit.errors.has('student_social_acc'),
                        },
                      ]"
                      v-model="formEdit.student_social_acc"
                      placeholder="Social хаяг"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Веб хаяг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_web_site"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_web_site'),
                        },
                      ]"
                      v-model="formEdit.student_web_site"
                      placeholder="Веб хаяг"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Зэрэг</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_bol_zer"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_bol_zer'),
                        },
                      ]"
                      v-model="formEdit.student_bol_zer"
                      placeholder="Зэрэг"
                    />
                  </div>
                  <div class="col-sm-4 form-group">
                    <label>Шагнал</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_shagnal"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has('student_shagnal'),
                        },
                      ]"
                      v-model="formEdit.student_shagnal"
                      placeholder="Шагнал"
                    />
                  </div>
                  <div class="col-sm-8 form-group">
                    <label>Шилжсэн шалтгаан</label>
                    <input
                      type="text"
                      class="form-control"
                      name="student_shiljilt_shaltgaan"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formEdit.errors.has(
                            'student_shiljilt_shaltgaan'
                          ),
                        },
                      ]"
                      v-model="formEdit.student_shiljilt_shaltgaan"
                      placeholder="Шилжсэн шалтгаан"
                    />
                  </div>
                  <div class="col-sm-12 form-group">
                    <label>Вакцины мэдээлэл</label>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <b-form-checkbox
                        id="checkbox-student_vac_1"
                        v-model="formEdit.student_vac_1"
                        name="checkbox-student_vac_1"
                        value="1"
                        unchecked-value="0"
                      >
                        Вакцин 1
                      </b-form-checkbox>
                    </div>
                    <div class="col-sm-4">
                      <b-form-checkbox
                        id="checkbox-student_vac_2"
                        v-model="formEdit.student_vac_2"
                        name="checkbox-student_vac_2"
                        value="1"
                        unchecked-value="0"
                      >
                        Вакцин 2
                      </b-form-checkbox>
                    </div>
                    <div class="col-sm-4">
                      <b-form-checkbox
                        id="checkbox-student_vac_3"
                        v-model="formEdit.student_vac_3"
                        name="checkbox-student_vac_3"
                        value="1"
                        unchecked-value="0"
                      >
                        Вакцин 3
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="updateStudent()"
                    >
                      Хадгалах
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="tab-pane" id="password">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <!-- /.card-header -->
                    <div class="card-body">
                      <form>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг:"
                          label-for="student_pass"
                        >
                          <b-form-input
                            type="password"
                            v-model="student_pass"
                            placeholder="Нууц үг"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('student_pass'), }, ]"
                          ></b-form-input>
                          <has-error
                            :form="formEdit"
                            field="student_pass"
                          ></has-error>
                        </b-form-group>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг давтах:"
                          label-for="student_pass_verify"
                        >
                          <b-form-input
                            type="password"
                            v-model="student_pass_verify"
                            placeholder="Нууц үг давтах"
                            required
                            class="[ 'form-control', { 'is-invalid': form.errors.has('student_pass_verify'), }, ]"
                          ></b-form-input>
                          <has-error
                            :form="formEdit"
                            field="student_pass_verify"
                          ></has-error>
                        </b-form-group>
                      </form>
                      <h6
                        class="text-success"
                        v-if="
                          errorPassword ==
                          'Таны нууц үгийг амжилттай шинэчиллээ!'
                        "
                      >
                        {{ errorPassword }}
                      </h6>
                      <h6 class="text-danger" v-else>{{ errorPassword }}</h6>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
              <div class="row pl-2">
                <b-button
                  @click="resetPassword()"
                  variant="primary"
                  type="submit"
                >
                  Нууц үг солих
                </b-button>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.nav-tabs-custom -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ImgRectangleViewVue from "../AllFunctions/ImgRectangleView.vue";
import MyImgZoom from "../AllFunctions/ImgZoom.vue";

export default {
  data() {
    return {
      editMode: false,
      myChilds: [],
      myChildCourses: [],
      myChildCoursesGrades: [],
      getMyInfoStudent: [],
      getOtherInfo: [],
      errorPassword: "",
      student_pass: "",
      student_pass_verify: "",
      formEdit: new Form({
        user_id: "",
        student_family_name: "",
        student_last_name: "",
        student_name: "",
        student_gender: "",
        student_birthDate: "",
        student_age: "",
        student_citizenship: "",
        student_reg: "",
        student_birth_ger_num: "",
        student_ekh_khel: "",
        student_unchin_esekh: "",
        student_asran_kham: "",
        student_asran_kham_med: "",
        student_tsus_bul: "",
        profile_id: "",
        school_class_id: "",
        student_age: "",
        student_duureg: 0,
        student_khoroo: 0,
        student_khoroolol: 0,
        student_bair: "",
        student_dugaar: "",
        student_toot: "",
        student_live_address: "",
        student_phone_number: "",
        student_email: "",
        student_social_acc: "",
        student_web_site: "",
        student_bol_zer: "",
        student_shagnal: "",
        student_vac_1: 0,
        student_vac_2: 0,
        student_vac_3: 0,
        student_shiljilt_shaltgaan: "",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      opts: [
        { value: null, text: "Сонгох" },
        { value: "Тийм", text: "Тийм" },
        { value: "Үгүй", text: "Үгүй" },
      ],
      aimagOptions: [],
      sumOptions: [],
      khorooOptions: [],
    };
  },
  components: { Multiselect, MyImgZoom, ImgRectangleViewVue },
  watch: {
    selectChildCourseExam: {
      handler: function (newVal, oldVal) {
        if (oldVal !== null) {
          //   console.log(newVal, oldVal);
          this.getExamValues(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    getMyChilds() {
      axios
        .get("/parent/getMyChild")
        .then((res) => {
          //   console.log(res.data);
          this.myChilds = res.data.mychilds;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    editStudentInfo(studentInfo) {
      axios
        .get("/allCity")
        .then((response) => {
          this.aimagOptions = response.data.allCity;
          this.getMyInfoStudent = studentInfo;
          this.formEdit.reset();
          this.formEdit.fill(studentInfo);
          this.editMode = true;
          if (this.formEdit.student_duureg != null) {
            this.getSum();
          } else this.formEdit.student_duureg = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSum() {
      axios
        .get("/allDistrict/" + this.formEdit.student_duureg)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
          this.khorooOptions = [];
          if (this.formEdit.student_khoroo != null) {
            this.getKhoroo();
          } else this.formEdit.student_khoroo = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getKhoroo() {
      axios
        .post("/allKhoroo", {
          cityId: this.formEdit.student_duureg,
          districtId: this.formEdit.student_khoroo,
        })
        .then((response) => {
          this.khorooOptions = response.data.allKhoroo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    updateStudent() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.formEdit
        .put("/parent/updateMyChild/" + this.getMyInfoStudent.profile_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(
            "Сурагчийн мэдээллийг амжилттай заслаа!",
            "Шинэчилсэн"
          );
          this.formEdit.reset();
          this.editMode = false;
          Fire.$emit("refreshChilds");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    resetPassword() {
      if (this.student_pass == this.student_pass_verify) {
        axios
          .post("/parent/changeChildPassword/" + this.formEdit.user_id, {
            password: this.student_pass,
          })
          .then((res) => {
            Swal.fire(
              "Нууц өөрчлөгдлөө!",
              "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
              "success"
            );
            this.student_pass = "";
            this.student_pass_verify = "";
            this.errorPassword = "Таны нууц үгийг амжилттай шинэчиллээ!";
            this.loaded = false;
          })
          .catch((err) => {
            this.errorPassword =
              "Нууц үг нь хамгийн багадаа 6 оронтой байх ёстой!";
          });
      } else {
        this.errorPassword = "Нууц үгийг зөв давтан оруулна уу!";
      }
    },
  },
  created() {
    this.getMyChilds();
    Fire.$on("refreshChilds", () => {
      this.getMyChilds();
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>
