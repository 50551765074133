<template>
  <div class="form-group input-group-sm">
    <input
      type="text"
      name="table_search"
      class="form-control"
      :placeholder="placeText"
      v-model="keyword"
    />
  </div>
</template>

<script>
export default {
  props: ["placeText"],
  data() {
    return {
      keyword: null,
      teacherEvaluationsSearch: [],
      teacherGiveEvaluationsSearch: [],
    };
  },
  watch: {
    keyword(after, before) {
      this.searchteachersEvaluations();
    },
  },
  methods: {
    calculateLessonBegun(shalles1, shalles2, shalles3) {
      let shalles11 = parseFloat(shalles1);
      let shalles22 = parseFloat(shalles2);
      let shalles33 = parseFloat(shalles3);
      let aveBegun = ((shalles11 + shalles22 + shalles33) / 8) * 100;
      return aveBegun.toFixed(1);
    },
    calculateLessonMid(shalles4, shalles5, shalles6, shalles7, shalles8) {
      let shalles44 = parseFloat(shalles4);
      let shalles55 = parseFloat(shalles5);
      let shalles66 = parseFloat(shalles6);
      let shalles77 = parseFloat(shalles7);
      let shalles88 = parseFloat(shalles8);
      let aveMid =
        ((shalles44 + shalles55 + shalles66 + shalles77 + shalles88) / 16) *
        100;
      return aveMid.toFixed(1);
    },
    calculateLessonEnd(shalles9, shalles10) {
      let shalles99 = parseFloat(shalles9);
      let shalles100 = parseFloat(shalles10);
      let aveEnd = ((shalles99 + shalles100) / 6) * 100;
      return aveEnd.toFixed(1);
    },
    calculateLessonFull(
      shalles1,
      shalles2,
      shalles3,
      shalles4,
      shalles5,
      shalles6,
      shalles7,
      shalles8,
      shalles9,
      shalles10
    ) {
      let shalles11 = parseFloat(shalles1);
      let shalles22 = parseFloat(shalles2);
      let shalles33 = parseFloat(shalles3);
      let shalles44 = parseFloat(shalles4);
      let shalles55 = parseFloat(shalles5);
      let shalles66 = parseFloat(shalles6);
      let shalles77 = parseFloat(shalles7);
      let shalles88 = parseFloat(shalles8);
      let shalles99 = parseFloat(shalles9);
      let shalles100 = parseFloat(shalles10);
      let aveFull =
        ((shalles11 +
          shalles22 +
          shalles33 +
          shalles44 +
          shalles55 +
          shalles66 +
          shalles77 +
          shalles88 +
          shalles99 +
          shalles100) /
          30) *
        100;
      return aveFull.toFixed(1);
    },
    searchteachersEvaluations() {
      axios
        .get("/teacher/teacherEvaluationsearch", {
          params: { keyword: this.keyword },
        })
        .then((response) => {
          // console.log(response.data.teacherEvaluations);
          this.teacherEvaluationsSearch = response.data.teacherEvaluations;
          // console.log(this.teacherEvaluationsSearch);
          this.teacherEvaluationsSearch.forEach((element) => {
            element.averageShalBegProcess = this.calculateLessonBegun(
              element.shalles1,
              element.shalles2,
              element.shalles3
            );
            element.averageShalMidProcess = this.calculateLessonMid(
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8
            );
            element.averageShalEndProcess = this.calculateLessonEnd(
              element.shalles9,
              element.shalles10
            );
            //Ээлжит хичээлийн нийт үнэлгээний дундаж үзүүлэлт
            element.averageShalFullProcess = this.calculateLessonFull(
              element.shalles1,
              element.shalles2,
              element.shalles3,
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8,
              element.shalles9,
              element.shalles10
            );
          });
          this.$emit(
            "getWasSearchTeacherEvaluations",
            this.teacherEvaluationsSearch
          );

          //   teacherGiveEvaluations
          this.teacherGiveEvaluationsSearch =
            response.data.teacherGiveEvaluations;
          // console.log(this.teacherEvaluationsSearch);
          this.teacherGiveEvaluationsSearch.forEach((element) => {
            element.averageShalBegProcess = this.calculateLessonBegun(
              element.shalles1,
              element.shalles2,
              element.shalles3
            );
            element.averageShalMidProcess = this.calculateLessonMid(
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8
            );
            element.averageShalEndProcess = this.calculateLessonEnd(
              element.shalles9,
              element.shalles10
            );
            //Ээлжит хичээлийн нийт үнэлгээний дундаж үзүүлэлт
            element.averageShalFullProcess = this.calculateLessonFull(
              element.shalles1,
              element.shalles2,
              element.shalles3,
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8,
              element.shalles9,
              element.shalles10
            );
          });

          this.$emit(
            "getWasSearchGiveTeacherEvaluations",
            this.teacherGiveEvaluationsSearch
          );
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
};
</script>

<style></style>
