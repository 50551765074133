<template>
  <div class="col-12 mt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <multiselect
        class="mr-2"
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="1-р алхам-Улирал сонголт"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="teacher_id"
        label="teacher_id"
        :custom-label="customLabelTeacherName"
        placeholder="2-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.employee_teacher_last_name[0]
            }}{{ option.employee_teacher_last_name[1] }}.{{
              option.employee_teacher_name
            }}-{{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="3-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button>
    </nav>
    <every-student
      v-if="selectedCourseData"
      :selectedLessonExamData="selectedLessonExamDataPass"
      :teacher_id="teacher_id"
      :course_id="course_id"
      :quarter_id="quarter_id"
    ></every-student>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudent from "../ExamStatForManagers/everyStudentConfirm.vue";
export default {
  components: {
    Multiselect,
    everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      //   mySchoolsDepartments: [],
      mySchoolQuarters: [],
      mySchoolsTeachers: [],
      mySchoolCourses: [],
      // Дуудах request
      quarter_id: "",
      department_id: "",
      teacher_id: "",
      course_id: "",
      //Pass to child component
      selectedCourseData: false,
      selectedLessonExamData: [],
      selectedLessonExamDataPass: [],
      selectedLessonExamDataUliralEtses: [],
      selectedLessonExamDataYear: [],
      allStudents: [],
    };
  },
  watch: {
    quarter_id: function (newVal, oldVal) {
      this.teacher_id = "";
    },
    teacher_id: function (newValue, oldValue) {
      //   console.log(newValue, oldValue);
      this.course_id = "";
      this.selectedCourseData = false;
      this.selectedLessonExamData = [];
      this.selectedLessonExamDataPass = [];
      this.selectedLessonExamDataUliralEtses = [];
      this.selectedLessonExamDataYear = [];

      this.mySchoolCourses = [];
      if (newValue != "") {
        axios
          .post("/manager/getMySchoolBehaviorCourses", {
            teacher: newValue,
            quarter: this.quarter_id,
          })
          .then((response) => {
            this.mySchoolCourses = response.data.mySchoolBehaviorCourses;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
    },
  },
  methods: {
    getMySchoolExamStatData() {
      axios
        .get("/manager/mySchoolBehaviorsStatData")
        .then((response) => {
          //   console.log(response.data);
          this.mySchoolQuarters = response.data.mySchoolsQuarters;

          let sortTeachers = response.data.mySchoolsTeachers;
          sortTeachers.sort(
            (a, b) =>
              a.school_class_number - b.school_class_number ||
              a.school_class_level.localeCompare(b.school_class_level)
          );
          this.mySchoolsTeachers = sortTeachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    getSelectMyExamData() {
      this.selectedCourseData = false;
      axios
        .post("/manager/getManagerGradeBehaviorData", {
          quarter_id: this.quarter_id,
          teacher_id: this.teacher_id,
          course_id: this.course_id,
        })
        .then((response) => {
          //   console.log(response.data);

          //Явц - Хичээл
          this.selectedLessonExamData = [];
          this.selectedLessonExamData =
            response.data.selectedBehaviorChildLesson;
          //Улирал - Хөгжүүлэх ажил
          this.selectedLessonExamDataUliralEtses = [];
          this.selectedLessonExamDataUliralEtses =
            response.data.selectedBehaviorDataChildDev;
          //Жил - Өөрийн түүхээ бүтээсэн байдал
          this.selectedLessonExamDataYear = [];
          this.selectedLessonExamDataYear =
            response.data.selectedBehaviorDataChildHistory;

          //Бүх суралцагчид
          let sortStudents = response.data.schoolClassAllStudents;
          sortStudents.sort(
            (a, b) =>
              b.student_name - a.student_name &&
              b.student_name.localeCompare(a.student_name)
          );

          this.allStudents = sortStudents;
          //   console.log(this.selectedLessonExamData.length);
          //   console.log(this.selectedLessonExamDataUliralEtses.length);
          //   console.log(this.selectedLessonExamDataYear.length);
          //   console.log(this.allStudents);

          let merged = [];

          //Явц - Хичээл
          //Улирал - Хөгжүүлэх ажил
          //Жил - Өөрийн түүхээ бүтээсэн байдал

          // 'selectedBehaviorChildLesson'
          // 'selectedBehaviorDataChildDev'
          // 'selectedBehaviorDataChildHistory'
          //явц, улирал хоёрыг тооцоолно - Хичээл, хөгжүүлэх ажил
          if (
            this.selectedLessonExamData.length > 0 &&
            this.selectedLessonExamDataUliralEtses.length > 0 &&
            this.selectedLessonExamDataYear.length == 0
          ) {
            // console.log("Хичээл > 0, Хөгжүүлэх > 0, Өөрийн түүх = 0");
            let a1 = [];
            // console.log(a1);

            this.selectedLessonExamDataUliralEtses.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamData];
            let a3 = [...this.selectedLessonExamDataUliralEtses];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1 = parseFloat(ela2.exam1);
                  ela1.exam2 = parseFloat(ela2.exam2);
                  ela1.exam3 = parseFloat(ela2.exam3);
                  ela1.exam4 = parseFloat(ela2.exam4);
                  ela1.exam5 = parseFloat(ela2.exam5);
                  ela1.exam6 = parseFloat(ela2.exam6);
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela2.grade_exam_type;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
              });
              // merged = a1;
            });
            // console.log("a1, a2", a1);
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1FinalQuarter = parseFloat(ela3.exam1);
                  ela1.exam2FinalQuarter = parseFloat(ela3.exam2);
                  ela1.exam3FinalQuarter = parseFloat(ela3.exam3);
                  ela1.exam4FinalQuarter = parseFloat(ela3.exam4);
                  ela1.exam5FinalQuarter = parseFloat(ela3.exam5);
                  ela1.exam6FinalQuarter = parseFloat(ela3.exam6);
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela3.grade_exam_type;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                }
              });
              merged = a1;
            });

            // console.log("a1, a3", merged);
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(this.selectedLessonExamDataPass);

            // console.log(this.selectedLessonExamData);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }
          //явц, жил хоёрыг тооцоолно Хичээл, өөрийн түүхээ бүтээсэн байдал
          if (
            this.selectedLessonExamData.length > 0 &&
            this.selectedLessonExamDataUliralEtses.length == 0 &&
            this.selectedLessonExamDataYear.length > 0
          ) {
            console.log("Хичээл > 0, Хөгжүүлэх = 0, Өөрийн түүх > 0");
            let a1 = [];
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataYear.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamData];
            let a3 = [...this.selectedLessonExamDataYear];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1 = ela2.exam1;
                  ela1.exam2 = ela2.exam2;
                  ela1.exam3 = ela2.exam3;
                  ela1.exam4 = ela2.exam4;
                  ela1.exam5 = ela2.exam5;
                  ela1.exam6 = ela2.exam6;
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela2.grade_exam_type;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
              });
              // merged = a1;
            });
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1Year = ela3.exam1;
                  ela1.exam2Year = ela3.exam2;
                  ela1.exam3Year = ela3.exam3;
                  ela1.exam4Year = ela3.exam4;
                  ela1.exam5Year = ela3.exam5;
                  ela1.exam6Year = ela3.exam6;
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela3.grade_exam_type;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                }
              });
              merged = a1;
            });
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);

            // console.log(this.selectedLessonExamData);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }

          //Улирал жил хоёроор тооцоолох Хөгжүүлэх ажил, өөрийн түүхээ бүтээсэн байдал
          if (
            this.selectedLessonExamData.length == 0 &&
            this.selectedLessonExamDataUliralEtses.length > 0 &&
            this.selectedLessonExamDataYear.length > 0
          ) {
            let a1 = [];
            console.log("Хичээл = 0, Хөгжүүлэх > 0, Өөрийн түүх > 0");
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataUliralEtses.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamDataUliralEtses];
            let a3 = [...this.selectedLessonExamDataYear];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1FinalQuarter = ela2.exam1;
                  ela1.exam2FinalQuarter = ela2.exam2;
                  ela1.exam3FinalQuarter = ela2.exam3;
                  ela1.exam4FinalQuarter = ela2.exam4;
                  ela1.exam5FinalQuarter = ela2.exam5;
                  ela1.exam6FinalQuarter = ela2.exam6;
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela2.grade_exam_type;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
                // merged = a1;
              });
            });
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1Year = ela3.exam1;
                  ela1.exam2Year = ela3.exam2;
                  ela1.exam3Year = ela3.exam3;
                  ela1.exam4Year = ela3.exam4;
                  ela1.exam5Year = ela3.exam5;
                  ela1.exam6Year = ela3.exam6;
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela3.grade_exam_type;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                }
              });
              merged = a1;
            });
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);

            // console.log(this.selectedLessonExamData);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }

          //Зөвхөн явцын үнэлгээ - Хичээл

          if (
            this.selectedLessonExamData.length > 0 &&
            this.selectedLessonExamDataUliralEtses.length == 0 &&
            this.selectedLessonExamDataYear.length == 0
          ) {
            console.log("Хичээл > 0, Хөгжүүлэх = 0, Өөрийн түүх = 0");
            let a1 = [];
            // console.log("Зөвхөн хичээлийн үнэлгээ");
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamData.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamData];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1 = ela2.exam1;
                  ela1.exam2 = ela2.exam2;
                  ela1.exam3 = ela2.exam3;
                  ela1.exam4 = ela2.exam4;
                  ela1.exam5 = ela2.exam5;
                  ela1.exam6 = ela2.exam6;
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela2.grade_exam_type;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
              });
            });
            merged = a1;

            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);

            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }

          //Зөвхөн жилийг тооцоолно. Өөрийн түүхээ бүтээсэн байдал
          if (
            this.selectedLessonExamData.length == 0 &&
            this.selectedLessonExamDataUliralEtses.length == 0 &&
            this.selectedLessonExamDataYear.length > 0
          ) {
            console.log("Хичээл = 0, Хөгжүүлэх = 0, Өөрийн түүх > 0");
            let a1 = [];
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataYear.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a3 = [...this.selectedLessonExamDataYear];
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1Year = ela3.exam1;
                  ela1.exam2Year = ela3.exam2;
                  ela1.exam3Year = ela3.exam3;
                  ela1.exam4Year = ela3.exam4;
                  ela1.exam5Year = ela3.exam5;
                  ela1.exam6Year = ela3.exam6;
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela3.grade_exam_type;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                }
              });
              merged = a1;
            });
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }

          //Зөвхөн улирал - хөгжүүлэх ажил
          if (
            this.selectedLessonExamData.length == 0 &&
            this.selectedLessonExamDataUliralEtses.length > 0 &&
            this.selectedLessonExamDataYear.length == 0
          ) {
            console.log("Хичээл = 0, Хөгжүүлэх > 0, Өөрийн түүх = 0");
            // console.log("Улирал сонгогдсон");
            let a1 = [];
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataUliralEtses.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamDataUliralEtses];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1FinalQuarter = ela2.exam1;
                  ela1.exam2FinalQuarter = ela2.exam2;
                  ela1.exam3FinalQuarter = ela2.exam3;
                  ela1.exam4FinalQuarter = ela2.exam4;
                  ela1.exam5FinalQuarter = ela2.exam5;
                  ela1.exam6FinalQuarter = ela2.exam6;
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela2.grade_exam_type;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
              });
              merged = a1;
            });
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }

          // явц; улирал, жилийг гурвууланг нь тооцоолно.
          if (
            this.selectedLessonExamData.length > 0 &&
            this.selectedLessonExamDataUliralEtses.length > 0 &&
            this.selectedLessonExamDataYear.length > 0
          ) {
            let a1 = [];
            console.log("Хичээл > 0, Хөгжүүлэх > 0, Өөрийн түүх > 0");
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataUliralEtses.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a2 = [...this.selectedLessonExamData];
            let a3 = [...this.selectedLessonExamDataUliralEtses];
            let a4 = [...this.selectedLessonExamDataYear];
            a1.forEach((ela1) => {
              a2.forEach((ela2) => {
                if (ela1.grade_exam_student_id === ela2.grade_exam_student_id) {
                  ela1.exam1 = ela2.exam1;
                  ela1.exam2 = ela2.exam2;
                  ela1.exam3 = ela2.exam3;
                  ela1.exam4 = ela2.exam4;
                  ela1.exam5 = ela2.exam5;
                  ela1.exam6 = ela2.exam6;
                  ela1.grade_exam_course_id = ela2.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela2.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela2.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela2.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela2.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela2.grade_exam_teacher_id;
                  ela1.student_last_name = ela2.student_last_name;
                  ela1.student_name = ela2.student_name;
                }
              });
            });
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1FinalQuarter = ela3.exam1;
                  ela1.exam2FinalQuarter = ela3.exam2;
                  ela1.exam3FinalQuarter = ela3.exam3;
                  ela1.exam4FinalQuarter = ela3.exam4;
                  ela1.exam5FinalQuarter = ela3.exam5;
                  ela1.exam6FinalQuarter = ela3.exam6;
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                }
              });
            });

            a1.forEach((ela1) => {
              a4.forEach((ela4) => {
                if (ela1.grade_exam_student_id === ela4.grade_exam_student_id) {
                  ela1.exam1Year = ela4.exam1;
                  ela1.exam2Year = ela4.exam2;
                  ela1.exam3Year = ela4.exam3;
                  ela1.exam4Year = ela4.exam4;
                  ela1.exam5Year = ela4.exam5;
                  ela1.exam6Year = ela4.exam6;
                  ela1.grade_exam_course_id = ela4.grade_exam_course_id;
                  ela1.grade_exam_quarter_id = ela4.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela4.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela4.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela4.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela4.grade_exam_teacher_id;
                  ela1.student_last_name = ela4.student_last_name;
                  ela1.student_name = ela4.student_name;
                }
              });
              merged = a1;
            });

            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) >
                0
              ) {
                el.examAverage =
                  (parseFloat(el.exam1) +
                    parseFloat(el.exam2) +
                    parseFloat(el.exam3) +
                    parseFloat(el.exam4) +
                    parseFloat(el.exam5) +
                    parseFloat(el.exam6)) /
                  6;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  (parseFloat(el.exam1FinalQuarter) +
                    parseFloat(el.exam2FinalQuarter) +
                    parseFloat(el.exam3FinalQuarter) +
                    parseFloat(el.exam4FinalQuarter) +
                    parseFloat(el.exam5FinalQuarter) +
                    parseFloat(el.exam6FinalQuarter)) /
                  6;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) >
                0
              ) {
                el.examAverageYear =
                  (parseFloat(el.exam1Year) +
                    parseFloat(el.exam2Year) +
                    parseFloat(el.exam3Year) +
                    parseFloat(el.exam4Year) +
                    parseFloat(el.exam5Year) +
                    parseFloat(el.exam6Year)) /
                  6;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;

            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelTeacherName({
      employee_teacher_name,
      employee_teacher_last_name,
      school_class_full_name,
    }) {
      return `${employee_teacher_last_name[0]}${employee_teacher_last_name[1]} . ${employee_teacher_name} - ${school_class_full_name}`;
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
  },
  created() {
    this.getMySchoolExamStatData();
  },
};
</script>

<style></style>
