<template>
  <div class="row">
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Үүрэг тодорхойлох</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="modal-body">
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  v-model="form.name"
                  placeholder="Үүргийн нэр"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has('name'),
                  }"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <input
                  type="number"
                  name="name"
                  v-model="form.erembe"
                  placeholder="Эрэмбэ"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has('erembe'),
                  }"
                />
                <has-error :form="form" field="erembe"></has-error>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  name="Тайлбар"
                  v-model="form.description"
                  placeholder="Role description"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has('description'),
                  }"
                />
                <has-error :form="form" field="description"></has-error>
              </div>
              <b-form-group
                aria-label="Assign permissions"
                v-if="dis && !editMode"
              >
                <b-form-checkbox
                  v-for="option in permissions"
                  v-model="form.permission"
                  :key="option.name"
                  :value="option.name"
                  name="flavour-3a"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="modal-footer justify-content-between">
              <b-button
                type="submit"
                variant="primary"
                class="btn-lg btn-primary"
                v-if="!dis"
                disabled
              >
                <b-spinner small type="grow"></b-spinner>
              </b-button>
              <button
                type="submit"
                v-if="dis && !editMode"
                @click.prevent="createRole()"
                class="btn btn-lg btn-primary"
              >
                <i class="fas fa-save"></i>
              </button>
              <button
                type="submit"
                v-if="dis && editMode"
                @click.prevent="updateRole()"
                class="btn btn-lg btn-success"
              >
                <i class="fas fa-save"></i>
              </button>
              <button
                type="submit"
                v-if="dis && editMode"
                @click.prevent="cancelEdit()"
                class="btn btn-lg btn-warning"
              >
                <i class="far fa-window-close"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Үүргийн жагсаалт</strong>
          </h3>
        </div>
        <div class="card-body">
          <div class="flex-column align-items-start">
            <b-badge
              pill
              v-for="role in roles"
              :key="role.id"
              variant="dark"
              class="mr-1"
            >
              {{ role.name }} : {{ role.description }}({{ role.erembe }})
              <b-badge pill variant="light" @click="editRole(role)">
                <a href="#"><i class="fas fa-edit fa-sm"></i></a>
              </b-badge>
            </b-badge>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Үйлдэл тодорхойлох</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="modal-body">
              <div class="form-group">
                <label>Үйлдэл</label>
                <b-form-select
                  v-model="formPermission.permissionName"
                  :options="permissions"
                  text-field="name"
                  value-field="name"
                  placeholder="Үйлдэл"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formPermission.errors.has('permissionName'),
                    },
                  ]"
                ></b-form-select>
              </div>
              <div class="form-group">
                <label>Харьяалагдах мэргэжлийн баг</label>
                <b-form-select
                  v-model="formPermission.department_id"
                  :options="departments"
                  @change="getTeachers"
                  text-field="department_name"
                  value-field="id"
                ></b-form-select>
              </div>
              <div class="form-group">
                <label>Багш, ажилтан</label>
                <b-form-select
                  v-model="formPermission.model_id"
                  :options="teachers"
                  text-field="emp_teacher_name"
                  value-field="emp_user_id"
                  :select-size="10"
                ></b-form-select>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="submit"
                @click.prevent="createPermission()"
                class="btn btn-lg btn-primary"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Үйлдлийн жагсаалт</strong>
          </h3>
        </div>
        <div class="card-body">
          <div class="flex-column align-items-start">
            <b-badge
              pill
              v-for="mHasP in modelHasPermissions"
              :key="mHasP.userName + mHasP.permissionName"
              variant="primary"
              class="mr-1"
            >
              {{ mHasP.userName }}
              <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
              <b-badge pill variant="light" class="mr-1">
                {{ mHasP.permissionName }}
              </b-badge>
            </b-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      name: "",
      dis: true,
      roles: [],
      teachers: [],
      departments: [],
      modelHasPermissions: [],
      permissions: [],
      formPermission: new Form({
        department_id: "",
        permissionName: "",
        model_id: "",
      }),
      form: new Form({
        id: null,
        name: "",
        erembe: null,
        description: "",
        permission: [],
      }),
    };
  },
  methods: {
    getTeachers() {
      axios
        .get(
          "/project_mentor/getTeacherList/" + this.formPermission.department_id
        )
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getAllPermissions() {
      axios
        .get("/owner/getAllPermissions")
        .then((response) => {
          // console.log(response);
          this.permissions = response.data.permissions;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
      axios.get("/owner/getAllRoles").then((response) => {
        this.roles = response.data.roles;
        this.departments = response.data.departments;
        this.modelHasPermissions = response.data.modelHasPermissions;
      });
    },
    createRole() {
      this.editMode = false;
      this.dis = false;
      // alert('You clicked me!!!');
      this.form
        .post("/owner/roles")
        .then(() => {
          this.form.reset();
          this.$toastr.s("Үүрэг амжилттай нэмлээ.", "Амжилттай");
          this.getAllPermissions();
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
      this.dis = true;
    },
    editRole(role) {
      this.editMode = true;
      this.form.reset();
      this.form.fill(role);
      // this.form.permissions = user.permissions;
    },
    updateRole() {
      this.dis = false;
      this.form
        .put("/owner/roles/" + this.form.id)
        .then(() => {
          this.form.reset();
          this.$toastr.s("Үүрэг амжилттай заслаа.", "Амжилттай");
          this.getAllPermissions();
          this.editMode = false;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
      this.dis = true;
    },
    createPermission() {
      this.formPermission
        .post("/owner/createPermissionUser")
        .then(() => {
          this.getAllPermissions();
          this.$toastr.s("Үйлдэл амжилттай нэмлээ.", "Амжилттай");
        })
        .catch(() => {});
      this.teachers = [];
      this.formPermission.reset();
    },
    cancelEdit() {
      this.editMode = false;
      this.form.reset();
    },
  },
  created() {
    this.getAllPermissions();
  },
};
</script>

<style></style>
