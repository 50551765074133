<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <strong v-if="!editMethodMode">Оношилгоо үүсгэх</strong>
          <strong v-else>Оношилгоо засах</strong>
        </div>
        <div class="card-body">
          <form
            @submit.prevent="editMethodMode ? updateMethod() : createMethod()"
          >
            <div class="form-row">
              <div class="form-group col-lg-4">
                <label>Нэр</label>
                <input
                  type="text"
                  v-model="form.name"
                  placeholder="Нэр"
                  class="form-control"
                  required
                  :class="{
                    'is-invalid': form.errors.has('name'),
                  }"
                />
              </div>
              <div class="form-group col-lg-4">
                <label>Графикийн төрөл</label>
                <b-form-select
                  required
                  v-model="form.chart"
                  :options="methodChartOptions"
                ></b-form-select>
              </div>
              <div class="form-group col-lg-4">
                <label>Тооцооллын төрөл</label>
                <b-form-select
                  required
                  v-model="form.type"
                  :options="methodTypeOptions"
                ></b-form-select>
              </div>
              <div class="form-group col-lg-12">
                <label>Тайлбар </label>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="form.description"
                  :options="editorOption"
                />
              </div>
            </div>
            <div v-if="form.type != ''">
              <hr />
              <label>Оноо тохируулах</label>
              <div class="row">
                <div
                  v-for="(input, index) in filteredPoints"
                  :key="input.order"
                  class="col-lg-4 px-2"
                >
                  <b-card
                    header-tag="header"
                    footer-tag="footer"
                    class="pointCard animated fadeInDown form-group"
                  >
                    <template #header>
                      {{ index + 1 }}-р онооны мэдээлэл
                      <div class="card-tools">
                        <b-button
                          size="sm"
                          class="text-muted"
                          variant="light"
                          :disabled="filteredPoints.length == 1"
                          @click="removePoint(input)"
                        >
                          <b-icon icon="trash" aria-hidden="true"></b-icon>
                        </b-button>
                      </div>
                    </template>
                    <b-card-text class="row">
                      <div class="form-group col-lg-6">
                        <label>Нэр: </label>
                        <input
                          required
                          type="text"
                          v-model="input.name"
                          placeholder="Нэр"
                          class="form-control"
                          :class="{
                            'is-invalid': form.errors.has('name'),
                          }"
                          @change="changedInput(input)"
                        />
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Оноо: </label>
                        <input
                          :disabled="form.type == 'Хариултын тоогоор'"
                          type="number"
                          required
                          v-model="input.point"
                          placeholder="Оноо"
                          class="form-control"
                          @change="changedInput(input)"
                        />
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Асуулгын дугаар: </label>
                        <b-form-select
                          required
                          v-model="input.numbers"
                          :options="numberOptions"
                          placeholder="Асуулгын дугаар"
                          multiple
                          @change="changedInput(input)"
                        ></b-form-select>
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Хариултын дугаар: </label>
                        <b-form-select
                          required
                          v-model="input.value"
                          :options="answerOptions"
                          placeholder="Хариултын дугаар"
                          @change="changedInput(input)"
                        ></b-form-select>
                      </div>
                    </b-card-text>
                  </b-card>
                </div>
              </div>
              <div class="form-group">
                <b-button variant="outline-info" size="sm" @click="addPoint()"
                  ><b-icon
                    icon="plus-lg"
                    aria-hidden="true"
                    class="mr-1"
                  ></b-icon>
                  Онооны хэмжүүр нэмэх</b-button
                >
              </div>
              <hr />
              <label>Үр дүн тохируулах</label>
              <div class="row">
                <div
                  v-for="(input, index) in filteredResults"
                  :key="input.order"
                  class="col-lg-12 px-2"
                >
                  <b-card
                    header-tag="header"
                    footer-tag="footer"
                    class="resultCard animated fadeInDown"
                  >
                    <template #header>
                      {{ index + 1 }}-р үр дүнгийн мэдээлэл
                      <div class="card-tools">
                        <b-button
                          size="sm"
                          class="text-muted"
                          variant="light"
                          :disabled="filteredResults.length == 1"
                          @click="removeResult(input)"
                        >
                          <b-icon icon="trash" aria-hidden="true"></b-icon>
                        </b-button>
                      </div>
                    </template>
                    <b-card-text class="row">
                      <div class="col-lg-9">
                        <div class="form-group">
                          <label>Нэр: </label>
                          <input
                            type="text"
                            required
                            v-model="input.name"
                            placeholder="Нэр"
                            class="form-control"
                            @change="changedInput(input)"
                          />
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label
                              ><b-form-checkbox
                                :checked="getInputBool(input.value)"
                                value="true"
                                unchecked-value="false"
                                @change="changeIsValue(input)"
                              >
                                Хариултын дугаар сонгох бол
                              </b-form-checkbox></label
                            >
                            <b-form-select
                              v-if="input.value != 0"
                              v-model="input.value"
                              :options="answerOptions"
                              placeholder="Хариултын дугаар"
                              @change="changedInput(input)"
                            ></b-form-select>
                          </div>
                          <div class="form-group col-lg-6">
                            <b-form-checkbox
                              v-model="input.isPercent"
                              value="1"
                              unchecked-value="0"
                              @change="changedInput(input)"
                            >
                              Хувиар тооцох бол
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-lg-3">
                        <label>Асуулгын дугаар: </label>
                        <b-form-select
                          required
                          v-model="input.numbers"
                          :options="numberOptions"
                          :select-size="5"
                          placeholder="Асуулгын дугаар"
                          multiple
                          @change="changedInput(input)"
                        ></b-form-select>
                      </div>
                      <div class="form-group col-lg-12">
                        <label>Тайлбар: </label>
                        <quill-editor
                          ref="myQuillEditor"
                          v-model="input.description"
                          :options="editorOption"
                          @change="changedInput(input)"
                        />
                      </div>
                      <hr />
                      <div
                        v-for="(rText, index) in input.result_texts"
                        :key="rText.order"
                        class="col-lg-12 form-group"
                      >
                        <b-card
                          border-variant="secondary"
                          header-border-variant="secondary"
                          header-tag="header"
                          footer-tag="footer"
                          class="animated fadeInDown"
                        >
                          <template #header>
                            {{ index + 1 }}-р тайлбар
                            <div class="card-tools">
                              <b-button
                                size="sm"
                                class="text-muted"
                                variant="light"
                                :disabled="input.result_texts.length == 1"
                                @click="removeResultRext(input, rText.order)"
                              >
                                <b-icon
                                  icon="trash"
                                  aria-hidden="true"
                                ></b-icon>
                              </b-button>
                            </div>
                          </template>
                          <b-card-text class="row">
                            <div class="form-group col-lg-12">
                              <label
                                ><b-form-checkbox
                                  :checked="getPointBool(rText.point)"
                                  value="true"
                                  unchecked-value="false"
                                  @change="changeIsPoint(input, rText)"
                                >
                                  Оноог тоолж тооцоололт хийх бол
                                </b-form-checkbox>
                              </label>
                              <input
                                v-if="rText.point != null"
                                type="number"
                                required
                                v-model="rText.point"
                                placeholder="Оноо"
                                class="form-control"
                                @change="changedInput(input)"
                              />
                            </div>
                            <div
                              class="form-group col-lg-12"
                              v-if="
                                input.numbers.length > 0 && input.isPercent == 1
                              "
                            >
                              <label
                                ><b-form-checkbox
                                  :checked="
                                    rText.numbers.length > 0 ? true : false
                                  "
                                  value="true"
                                  unchecked-value="false"
                                  @change="changeIsNumbers(input, rText)"
                                >
                                  Асуулгын дугаар тохируулах бол
                                </b-form-checkbox></label
                              >
                              <b-form-select
                                v-if="rText.numbers.length > 0"
                                required
                                v-model="rText.numbers"
                                :options="input.numbers"
                                :select-size="5"
                                placeholder="Асуулгын дугаар"
                                multiple
                                @change="changedInput(input)"
                              ></b-form-select>
                            </div>
                            <div class="col-lg-4">
                              <b-form-group
                                label="Доод оноо:"
                                :disabled="
                                  input.input_answers_count > 0 ? true : false
                                "
                                label-for="nested-street"
                                label-cols-sm="3"
                                label-align-sm="right"
                              >
                                <input
                                  type="number"
                                  required
                                  v-model="rText.min_point"
                                  placeholder="Доод оноо"
                                  class="form-control"
                                  @change="changedInput(input)"
                                />
                              </b-form-group>
                              <b-form-group
                                label="Дээд оноо:"
                                :disabled="
                                  input.input_answers_count > 0 ? true : false
                                "
                                label-for="nested-street"
                                label-cols-sm="3"
                                label-align-sm="right"
                              >
                                <input
                                  type="number"
                                  required
                                  v-model="rText.max_point"
                                  placeholder="Дээд оноо"
                                  class="form-control"
                                  @change="changedInput(input)"
                                />
                              </b-form-group>
                            </div>
                            <div class="form-group col-lg-8">
                              <quill-editor
                                ref="myQuillEditor"
                                v-model="rText.result"
                                :options="editorOption"
                                @change="changedInput(input)"
                              />
                            </div>
                          </b-card-text>
                        </b-card>
                      </div>
                      <div class="form-group col-lg-12">
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="addResultText(input)"
                          ><b-icon
                            icon="plus-lg"
                            aria-hidden="true"
                            class="mr-1"
                          ></b-icon>
                          Тайлбар нэмэх</b-button
                        >
                      </div>
                    </b-card-text>
                  </b-card>
                </div>
              </div>
              <div class="form-group">
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="addResult()"
                  ><b-icon
                    icon="plus-lg"
                    aria-hidden="true"
                    class="mr-1"
                  ></b-icon>
                  Үр дүн нэмэх</b-button
                >
              </div>
            </div>
            <div class="float-right">
              <b-overlay
                :show="lsClicked"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn btn-success"
                  v-if="editMethodMode"
                >
                  <i class="fa fa-save mr-1"></i> Засах
                </b-button>
                <button
                  type="submit"
                  v-if="!editMethodMode"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save mr-1"></i> Үүсгэх
                </button>
                <button @click.prevent="cancelMethod" class="btn btn-warning">
                  Болих
                </button>
              </b-overlay>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <strong>Оношилгоо</strong>
        </div>
        <div class="card-body">
          <b-table
          hover
            :items="methods"
            :fields="fields"
            sort-by.sync="title"
            sort-desc.sync="false"
            responsive="sm"
            :filter="null"
            :filter-included-fields="filterOn"
            show-empty
            empty-text="Одоогоор Оношилгоо байхгүй байна."
          >
            <template #empty="scope">
              <p class="text-center text-muted h5 mt-5">
                <b-icon icon="inboxes"></b-icon>
              </p>
              <p class="text-center text-muted font-italic">
                {{ scope.emptyText }}
              </p>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(chart)="data">
              <b-icon
                v-if="data.item.chart == 'bar'"
                icon="bar-chart-line"
                aria-hidden="true"
                animation="cylon-vertical"
              >
              </b-icon>
              <b-icon
                v-else-if="data.item.chart == 'pie'"
                icon="pie-chart"
                aria-hidden="true"
                animation="spin"
              >
                Pie chart</b-icon
              >
              <b-icon
                v-else-if="data.item.chart == 'radar'"
                icon="nut"
                animation="spin"
                aria-hidden="true"
              ></b-icon>
              <b-icon
                v-else-if="data.item.chart == 'area'"
                icon="bar-chart-steps"
                animation="cylon-vertical"
                aria-hidden="true"
              ></b-icon>
            </template>
            <template #cell(actions)="data">
              <b-button
                class="my-1"
                size="sm"
                v-b-toggle.sidebar-form
                @click.prevent="editMethod(data.item.id)"
                variant="outline-primary"
                ><i class="fas fa-edit"></i
              ></b-button>
              <b-button
                class="my-1"
                size="sm"
                @click.prevent="deleteMethod(data.item.id)"
                variant="outline-danger"
                ><b-icon icon="trash" aria-hidden="true"> </b-icon
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      methods: [],
      fields: [
        { key: "index", label: "№" },
        { key: "type", label: "Төрөл", sortable: true },
        { key: "name", label: "Нэр", sortable: true },
        { key: "chart", label: "Графикийн төрөл", sortable: true },
        { key: "actions", label: "Үйлдэл", sortable: true },
      ],
      filterOn: [],
      methodChartOptions: [
        { value: "bar", text: "Bar chart" },
        { value: "pie", text: "Pie chart" },
        { value: "radar", text: "Radar chart" },
        { value: "area", text: "Area chart" },
      ],
      methodTypeOptions: [
        { value: "", text: "Тооцооллын төрөл сонгох" },
        { value: "Онооны нийлбэрээр", text: "Онооны нийлбэрээр" },
        { value: "Хариултын тоогоор", text: "Хариултын тоогоор" },
      ],
      answerOptions: [
        { value: "", text: "Сонгох" },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
        { value: 24, text: 24 },
        { value: 25, text: 25 },
        { value: 26, text: 26 },
        { value: 27, text: 27 },
        { value: 28, text: 28 },
        { value: 29, text: 29 },
        { value: 30, text: 30 },
        { value: 31, text: 31 },
      ],
      numberOptions: [],
      form: new Form({
        id: "",
        type: "",
        name: "",
        chart: "bar",
        description: "",
        points: [],
        results: [],
      }),
      methodIsLoaded: true,
      lsClicked: false,
      editMethodMode: false,
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
      },
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    filteredPoints() {
      return this.form.points.filter((input) => {
        return input.isRemoved == false;
      });
    },
    filteredResults() {
      return this.form.results.filter((input) => {
        return input.isRemoved == false;
      });
    },
    editor() {
      return this.$refs.myQuillEditor;
    },
  },
  methods: {
    getInputBool(value) {
      if (value == 0) return false;
      else return true;
    },
    changeIsValue(input) {
      input.isChanged = true;
      if (input.value == 0) input.value = 1;
      else input.value = 0;
    },
    getPointBool(value) {
      if (value == null) return false;
      else return true;
    },
    changeIsPoint(input, rText) {
      input.isChanged = true;
      if (rText.point == null) rText.point = 0;
      else rText.point = null;
    },
    changeIsNumbers(input, rText) {
      input.isChanged = true;
      if (rText.numbers.length == 0) rText.numbers = [0];
      else rText.numbers = [];
    },
    getMethods() {
      this.methodIsLoaded = true;
      axios
        .get("/manager/getMethods")
        .then((response) => {
          this.methods = response.data.methods;
          this.methodIsLoaded = false;
          this.numberOptions = [];
          for (let i = 1; i <= 150; i++) {
            this.numberOptions.push(i);
          }
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addPoint() {
      this.form.points.push({
        id: null,
        order: this.form.points.length + 1,
        name: "",
        numbers: [],
        value: "",
        point: 1,
        isRemoved: false,
        isChanged: false,
      });
    },
    removePoint(input) {
      input.isRemoved = true;
      this.form.points = _.orderBy(this.form.points, "order");
    },
    addResult() {
      this.form.results.push({
        id: null,
        order: this.form.results.length + 1,
        name: "",
        isPercent: 0,
        value: 0,
        numbers: [],
        description: "",
        result_texts: [
          {
            id: null,
            point: null,
            numbers: [],
            order: 1,
            min_point: 1,
            max_point: 1,
            result: "Тайлбар",
          },
        ],
        isRemoved: false,
        isChanged: false,
      });
    },
    removeResult(input) {
      input.isRemoved = true;
      this.form.results = _.orderBy(this.form.results, "order");
    },
    addResultText(input) {
      input.isChanged = true;
      input.result_texts.push({
        id: null,
        point: null,
        numbers: [],
        order: input.result_texts.length + 1,
        min_point: 1,
        max_point: 1,
        result: "Тайлбар",
      });
    },
    removeResultRext(input, order) {
      input.isChanged = true;
      input.result_texts = input.result_texts.filter((i) => i.order != order);
    },
    changedInput(input) {
      input.isChanged = true;
    },
    createMethod() {
      if (this.form.points.length == 0) {
        this.$toastr.w("Онооны мэдээлэл оруулна уу.", "Анхаар");
        return;
      }
      if (this.form.results.length == 0) {
        this.$toastr.w("Үр дүнгийн мэдээлэл оруулна уу.", "Анхаар");
        return;
      }
      this.lsClicked = true;
      this.form
        .post("/manager/createMethod")
        .then(() => {
          this.getMethods();
          this.form.reset();
          this.$toastr.s("Оношилгоо амжилттай үүсгэлээ.", "Амжилттай");
          this.lsClicked = false;
        })
        .catch(() => {
          this.$toastr.e("Оношилгоо үүсгэж чадсангүй!", "Алдаа гарлаа");
          this.lsClicked = false;
        });
    },
    editMethod(id) {
      axios
        .get("/manager/editMethod/" + id)
        .then((response) => {
          this.editMethodMode = true;
          this.form.reset();
          this.form.fill(response.data.method);
          this.form.points.forEach(function (point) {
            point.isRemoved = false;
            point.isChanged = false;
            let numberList = [];
            numberList = point.numbers.split("-");
            point.numbers = [];
            numberList.forEach((val) => {
              if (val != "") point.numbers.push(val);
            });
          });
          this.form.results.forEach(function (result) {
            result.isRemoved = false;
            result.isChanged = false;
            let numberList = [];
            numberList = result.numbers.split("-");
            result.numbers = [];
            numberList.forEach((val) => {
              if (val != "") result.numbers.push(val);
            });
            result.result_texts.forEach((rText) => {
              if (rText.numbers != null) {
                let rNumberList = [];
                rNumberList = rText.numbers.split("-");
                rText.numbers = [];
                rNumberList.forEach((number) => {
                  if (number != "") rText.numbers.push(number);
                });
              } else rText.numbers = [];
            });
          });
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    updateMethod() {
      if (this.form.points.length == 0) {
        this.$toastr.w("Онооны мэдээлэл оруулна уу.", "Анхаар");
        return;
      }
      if (this.form.results.length == 0) {
        this.$toastr.w("Үр дүнгийн мэдээлэл оруулна уу.", "Анхаар");
        return;
      }
      this.lsClicked = true;
      this.form
        .put("/manager/updateMethod/" + this.form.id)
        .then(() => {
          this.getMethods();
          this.form.reset();
          this.$toastr.s("Оношилгоо амжилттай заслаа.", "Амжилттай");
          this.lsClicked = false;
        })
        .catch(() => {
          this.$toastr.e("Оношилгоо засаж чадсангүй!", "Алдаа гарлаа");
          this.lsClicked = false;
        });
    },
    cancelMethod() {
      this.editMethodMode = false;
      this.form.reset();
    },
    deleteMethod(id) {
      Swal.fire({
        title: "Оношилгоо устгах!",
        text: "Оношилгоо устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Оношилгоо хасаж байна...";
          axios
            .get("/manager/deleteMethod/" + id)
            .then(() => {
              this.getMethods();
              this.$toastr.s("Оношилгоо амжилттай устгалаа.", "Амжилттай");
            })
            .catch((errors) => {
              this.$toastr.e("Оношилгоо устгаж чадсангүй.", "Алдаа гарлаа!");
            });
        }
      });
    },
  },
  created() {
    this.getMethods();
  },
};
</script>

<style scoped>
.pointCard {
  box-shadow: 0 0 1px rgb(23 162 184), 0 1px 3px #00bcd4;
}
.resultCard {
  box-shadow: 0 0 1px rgb(40 167 69), 0 1px 3px #28a745;
}
</style>
