<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Тухайн сонгосон ээлжит хичээлийн үнэлгээний график</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <apexchart :options="options" :series="series"></apexchart>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  Харьяалагдах МБ-тай багшийн нийт үнэлэгдсэн хичээлийн дундаж
                  үнэлгээг харьцуулсан график
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <apexchart
                    :options="optionsDepartment"
                    :series="seriesDepartment"
                  ></apexchart>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["passTeachersEvaluationData"],
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
          type: "radar",
          width: "100%",
        },
        xaxis: {
          categories: [
            "LB1",
            "LB2",
            "LB3",
            "LM4",
            "LM5",
            "LM6",
            "LM7",
            "LM8",
            "LE9",
            "LE10",
          ],
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
        {
          name: "Бүрэн",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
      ],
      optionsDepartment: {
        chart: {
          id: "vuechart-example1",
          type: "radar",
          width: "100%",
        },
        markers: {
          size: 5,
          hover: {
            size: 10,
          },
        },
        xaxis: {
          categories: [
            "LB1",
            "LB2",
            "LB3",
            "LM4",
            "LM5",
            "LM6",
            "LM7",
            "LM8",
            "LE9",
            "LE10",
          ],
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
      },
      seriesDepartment: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "Бүрэн",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
      ],
    };
  },
  methods: {
    selectedteachersData() {
      this.series[0].name =
        this.passTeachersEvaluationData.employees_teacher_name;
      this.series[0].data[0] = (
        (this.passTeachersEvaluationData.shalles1 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[1] = (
        (this.passTeachersEvaluationData.shalles2 / 2) *
        100
      ).toFixed(1);
      this.series[0].data[2] = (
        (this.passTeachersEvaluationData.shalles3 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[3] = (
        (this.passTeachersEvaluationData.shalles4 / 4) *
        100
      ).toFixed(1);
      this.series[0].data[4] = (
        (this.passTeachersEvaluationData.shalles5 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[5] = (
        (this.passTeachersEvaluationData.shalles6 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[6] = (
        (this.passTeachersEvaluationData.shalles7 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[7] = (
        (this.passTeachersEvaluationData.shalles8 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[8] = (
        (this.passTeachersEvaluationData.shalles9 / 3) *
        100
      ).toFixed(1);
      this.series[0].data[9] = (
        (this.passTeachersEvaluationData.shalles10 / 3) *
        100
      ).toFixed(1);
    },
    selectedDepartmentteachersData(departmentNegtgelteacherLesEval) {
      //   console.log(departmentNegtgelteacherLesEval[0].department_code);
      this.seriesDepartment[0].name =
        departmentNegtgelteacherLesEval[0].department_code;
      this.seriesDepartment[0].data[0] = (
        (departmentNegtgelteacherLesEval[0].shalles1 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[1] = (
        (departmentNegtgelteacherLesEval[0].shalles2 / 2) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[2] = (
        (departmentNegtgelteacherLesEval[0].shalles3 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[3] = (
        (departmentNegtgelteacherLesEval[0].shalles4 / 4) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[4] = (
        (departmentNegtgelteacherLesEval[0].shalles5 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[5] = (
        (departmentNegtgelteacherLesEval[0].shalles6 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[6] = (
        (departmentNegtgelteacherLesEval[0].shalles7 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[7] = (
        (departmentNegtgelteacherLesEval[0].shalles8 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[8] = (
        (departmentNegtgelteacherLesEval[0].shalles9 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[0].data[9] = (
        (departmentNegtgelteacherLesEval[0].shalles10 / 3) *
        100
      ).toFixed(1);
    },
    selfTeachersData(seflNegtgelteacherLesEval) {
      //   console.log(departmentNegtgelteacherLesEval[0].department_code);
      this.seriesDepartment[1].name = seflNegtgelteacherLesEval[0].teacher_name;
      this.seriesDepartment[1].data[0] = (
        (seflNegtgelteacherLesEval[0].shalles1 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[1] = (
        (seflNegtgelteacherLesEval[0].shalles2 / 2) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[2] = (
        (seflNegtgelteacherLesEval[0].shalles3 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[3] = (
        (seflNegtgelteacherLesEval[0].shalles4 / 4) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[4] = (
        (seflNegtgelteacherLesEval[0].shalles5 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[5] = (
        (seflNegtgelteacherLesEval[0].shalles6 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[6] = (
        (seflNegtgelteacherLesEval[0].shalles7 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[7] = (
        (seflNegtgelteacherLesEval[0].shalles8 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[8] = (
        (seflNegtgelteacherLesEval[0].shalles9 / 3) *
        100
      ).toFixed(1);
      this.seriesDepartment[1].data[9] = (
        (seflNegtgelteacherLesEval[0].shalles10 / 3) *
        100
      ).toFixed(1);
    },
    getCompareEvaluationData() {
      //   console.log(this.series[0].data[0]);
      //   console.log(this.passTeachersEvaluationData);
      axios
        .get("/teacher/getLesEvaData", {
          params: {
            department_id: this.passTeachersEvaluationData.department_id,
            teacher_id: this.passTeachersEvaluationData.employees_teacher_id,
          },
        })
        .then((response) => {
          this.departmentNegtgelTeacherLesEvalNum =
            response.data.departmentNegtgelteacherLesEval;
          this.selectedDepartmentteachersData(
            response.data.departmentNegtgelteacherLesEval
          );
          this.selfTeachersData(response.data.mySelfNegtgelteacherLesEval);
          //   console.log(response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  created() {
    // console.log(this.passTeachersEvaluationData, "Харьцуулах");
    this.getCompareEvaluationData();
    this.selectedteachersData();
  },
};
</script>

<style></style>
