<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-12 col-md-2">
          <h3 class="card-title">Сургуулийн хичээл</h3>
        </div>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
          <div class="col-sm-8">
            <b-row>
              <b-col lg="12" class="my-1">
                <b-form-group
                  label="Нэг хуудсанд харуулах тоо"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageSubjects"
                    :options="pageOptionsSubjects"
                    size="sm"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label="Хайлт хийх утга оруулна уу!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterSubjects"
                      type="search"
                      placeholder="Хайлт хийх утгаа оруулна уу!"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterSubjects"
                        @click="filterSubjects = ''"
                        >Цэвэрлэх</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-pagination
              v-model="currentPageSubjects"
              :total-rows="totalRowsSubjects"
              :per-page="perPageSubjects"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <b-table
              hover
              :items="schoolsubjects"
              :fields="fieldsActiveSubjects"
              :current-page="currentPageSubjects"
              :per-page="perPageSubjects"
              :filter="filterSubjects"
              :filter-included-fields="filterOnSubjects"
              @filtered="onFilteredSubjects"
            >
              <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <!-- <template #cell(flName)="data">
                <b-badge variant="info">
                  {{ data.item.subject_name }}
                </b-badge>
              </template> -->
              <template #cell(created_at)="data">
                <b-badge>
                  {{ data.item.created_at | dateYearMonthDay }}
                </b-badge>
              </template>
              <template #cell(updated_at)="data">
                <b-badge>
                  {{ data.item.updated_at | dateYearMonthDay }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <a
                  href="#"
                  class="badge bg-warning"
                  @click="editSchoolSubject(data.item)"
                  ><i class="fas fa-edit"></i
                ></a>
                <a
                  href="#"
                  class="badge bg-danger"
                  v-if="userInfo.name == 'cipalprin'"
                  @click="deleteSchoolSubject(data.item)"
                  ><i class="far fa-trash-alt"></i
                ></a>
              </template>
            </b-table>
          </div>
          <div class="col-sm-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">Хичээл бүртгэх</h3>
                <h3 class="card-title" v-show="editMode">Хичээл засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="
                  !editMode ? createSchoolSubjetc() : updateSchoolSubject()
                "
              >
                <div class="card-body">
                  <div class="form-group">
                    <label
                      >Хичээл (Хичээл үүсгэх үед дараах зүйлсийг анхаарна.)
                    </label>
                    <br />
                    <span style="font-size: 60%; text-align: justify">
                      Цөм, Гадаад(Cambridge, IB etc...) <br />
                      Математик(мон/цөм)/(М/Ц) Тайлбар: монгол багш цөм хөтөлбөр
                      хэрэгжүүлнэ.<br />
                      Математик(мон/camb)/(М/C) Тайлбар: монгол багш кэмбрижийн
                      хөтөлбөр хэрэгжүүлнэ.<br />
                      Mathematics(for/camb)/(М/C) Тайлбар: гадаад багш
                      кэмбрижийн хөтөлбөр хэрэгжүүлнэ.
                    </span>
                    <input
                      type="text"
                      name="subject_name"
                      v-model="form.subject_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('subject_name'),
                        },
                      ]"
                      placeholder="Хичээлийн нэр"
                    />
                    <has-error :form="form" field="subject_name"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Товч нэр</label>
                    <input
                      type="text"
                      name="subject_short_name"
                      v-model="form.subject_short_name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('subject_short_name'),
                        },
                      ]"
                      placeholder="Товч нэр"
                    />
                    <has-error
                      :form="form"
                      field="subject_short_name"
                    ></has-error>
                  </div>
                  <div class="form-group">
                    <label>Харьяалагдах мэргэжлийн баг</label>
                    <b-form-select
                      v-model="form.department_id"
                      :options="departments"
                      text-field="department_name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('department_id'),
                        },
                      ]"
                    ></b-form-select>
                    <has-error :form="form" field="department_id"></has-error>
                  </div>
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.behave_subject"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      Хүмүүжлийн хөтөлбөрийн хичээл мөн бол сонгох
                    </b-form-checkbox>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editMode"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editMode"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editMode"
                    @click.prevent="cancelEdit"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
// import SearchSubject from "./SubjectsSub/SearchSubject.vue"; Ашиглахаа болив

export default {
  data() {
    return {
      action: "",
      editMode: false,
      schoolsubjects: [],
      //Хичээлийн table тохиргоо
      totalRowsSubjects: 1,
      currentPageSubjects: 1,
      perPageSubjects: 35,
      pageOptionsSubjects: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү олноор харах" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterSubjects: null,
      filterOnSubjects: [],
      fieldsActiveSubjects: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Хичээлийн нэр",
          sortable: true,
        },

        {
          key: "subject_short_name",
          label: "Товч нэр",
          sortable: true,
        },
        {
          key: "department_name",
          label: "Харьяалагдах БНБ",
          sortable: true,
        },
        {
          key: "behave_subject",
          label: "Хүмүүжлийн хичээл",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      departments: [],
      form: new Form({
        id: "",
        subject_name: "",
        subject_short_name: "",
        department_id: "",
        school_id: "",
        behave_subject: "",
      }),
    };
  },
  components: {
    // searchSubject: SearchSubject,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  methods: {
    getAllSchoolSubjects() {
      axios
        .get("/manager/getAllSchoolSubject")
        .then((response) => {
          //   console.log(response);
          this.schoolsubjects = response.data.schoolsubjects;
          this.totalRowsSubjects = this.schoolsubjects.length;
          this.departments = response.data.departments;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolSubject(schoolsubject) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolsubject);
    },
    editSchoolSubject(schoolsubject) {
      // console.log(schoolsubject);

      this.form.reset();
      this.form.fill(schoolsubject);
      this.form.department = schoolsubject.department;
      this.form.behave_subject =
        schoolsubject.behave_subject == 1 ? "accepted" : "not_accepted";
      this.editMode = true;
    },
    updateSchoolSubject() {
      //   console.log(this.form);
      this.form
        .put("/manager/subject/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadSchoolSubjects");
          this.form.reset();
          this.editMode = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolSubject(schoolsubject) {
      console.log(schoolsubject);
      Swal.fire({
        title: "Та итгэлтэй байна уу? хичээл(subject) устгах гэж байна.",
        text: "Маш онцгой тохиолдолд энэ функцыг ашиглана. Хичээл(subject) устгасан тохиолдолд сэргээх боломжгүйг анхаарна уу? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/subject/" + schoolsubject.id)
            .then((res) => {
              if (res.data.info.code === "success") {
                Swal.fire("Устгасан!", res.data.info.message, "success");
                Fire.$emit("loadSchoolSubjects");
              } else if (res.data.info.code === "errors") {
                this.$toastr.e(res.data.info.message);
              }
            })
            .catch((err) => {});
        }
      });
    },
    createSchoolSubjetc() {
      this.action = "Ангийг үүсгэж байна...";
      this.form
        .post("/manager/subject")
        .then((response) => {
          this.$toastr.s("Хичээлийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolSubjects");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },

    //b-table тохирго
    onFilteredSubjects(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsSubjects = filteredItems.length;
      this.currentPageSubjects = 1;
    },
  },
  created() {
    this.getAllSchoolSubjects();
    Fire.$on("loadSchoolSubjects", () => {
      this.getAllSchoolSubjects();
    });
    // Fire.$on("getWasSearchSubjects", () => {
    //   this.schoolsubjects = schoolsubjects;
    // });
  },
};
</script>

<style></style>
