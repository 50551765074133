<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Эрсдэлийн удирдлага(Ангилал)</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="3">
                      <b-form-input
                        id="input-1"
                        v-model="formRisk.rc_code"
                        placeholder="Ангилалын код..."
                        required
                        type="number"
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        id="input-2"
                        v-model="formRisk.rc_name"
                        placeholder="Ангилалын нэр..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        id="input-2"
                        v-model="formRisk.rc_description"
                        placeholder="Ангилалын тайлбар..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskCategoryCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskCategoryUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="riskCategoryClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col sm="2"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="success" @click.prevent="getAllTask()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Ангилалын код(7 оронтой)</th>
                <th>Ангилалын нэр</th>
                <th>Ангилалын тайлбар</th>
                <th>Дэд ангилал</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(riskCategory, index) in riskCategories"
                :key="riskCategory.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    riskCategory.rc_code
                  }}</b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    riskCategory.rc_name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    riskCategory.rc_description
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Дэд ангилалын код</th>
                        <th scope="col">Нэр</th>
                        <th scope="col">Тайлбар</th>
                        <th scope="col">Үүсгэсэн</th>
                        <th scope="col">Үйлдлүүд</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(rcl, i) in riskCategory.my_r_c_lists"
                        :key="rcl.id"
                      >
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ rcl.rcl_code }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ rcl.rcl_name }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ rcl.rcl_description }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ rcl.created_at | dateYearMonthDay }}</b-badge
                          >
                        </td>
                        <td>
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editRiskCategoryList(rcl)"
                            ><i class="fa fa-check"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteRiskCategoryList(rcl)"
                            ><b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editRiskCategory(riskCategory)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="createRiskCategoryList(riskCategory)"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteRiskCategory(riskCategory)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ riskCategory.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ riskCategory.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-risk-category-list"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дэд ангилал"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <label>Код</label>
                <b-form-input
                  id="input-1"
                  v-model="formRiskList.rcl_code"
                  placeholder="Ангилалын код..."
                  required
                  type="number"
                ></b-form-input>
                <has-error :form="formRiskList" field="rcl_code"></has-error>
              </div>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-2"
                  v-model="formRiskList.rcl_name"
                  placeholder="Дэд ангилалын нэр..."
                  required
                ></b-form-input>
                <has-error :form="formRiskList" field="rcl_name"></has-error>
              </div>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-3"
                  v-model="formRiskList.rcl_description"
                  placeholder="Дэд ангилалын тайлбар..."
                  required
                ></b-form-input>
                <has-error
                  :form="formRiskList"
                  field="rcl_description"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Хариуцах ажилтан сонгох</label>
                <multiselect
                  v-model="selectedEmployees"
                  :options="managers"
                  :multiple="true"
                  :custom-label="customName"
                  label="text"
                  :searchable="true"
                  :close-on-select="false"
                  :show-labels="true"
                  placeholder="Хариуцах ажилтан сонгох"
                  :allow-empty="false"
                  deselect-label="Хасах"
                  select-label="Сонгох"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }}.{{ option.lastName }}
                    </strong></template
                  >
                </multiselect>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          type="submit"
          class="btn btn-success"
          v-show="!showEditRCL"
          @click.prevent="storeRiskCategoryList()"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          v-show="showEditRCL"
          @click.prevent="updateRiskCategoryList()"
        >
          Засах
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      departments: [
        { value: null, department_name: "Мэргэжлийн багш сонгоно уу?" },
      ],
      managers: [],
      selectedEmployees: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //Training Эндээс эхэллээ...

      allTrainings: [],

      //subTrainings
      showSubRiskCategoryMode: false,
      editSubTrainMode: false,
      useSubTrainofParent: "",
      allSubTrainings: [],

      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      modalTeachers: [],

      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),

      // RiskCategory эхэллээ
      riskCategories: [],
      formRisk: new Form({
        id: "",
        rc_code: "",
        rc_name: "",
        rc_description: "",
      }),
      showEdit: false,

      //   RiskCategoryList эхэллээ
      formRiskList: new Form({
        id: "",
        rc_id: "",
        rcl_code: "",
        rcl_name: "",
        rcl_description: "",
      }),
      showEditRCL: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //Эрсдэлийн ангилал эхэлж байна...
    getAllRiskCategories() {
      axios
        .get("/principal/getAllRiskCategories")
        .then((res) => {
          //   console.log(res);
          this.riskCategories = res.data.riskCategories;
          this.managers = res.data.managers;
          //   this.allTrainings = res.data.getAllTrainings;
          //   this.filterForm.department_id = 0;
          //   this.departments = res.data.departments;
          //   this.departments.push({
          //     id: 0,
          //     department_name: "Нийт мэргэжлийн баг",
          //   });
          //   this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    riskCategoryCreate() {
      //   console.log(this.formRisk);
      this.formRisk
        .post("/principal/riskCategoryCreate")
        .then((res) => {
          // console.log(res);
          Fire.$emit("getRisks");
          this.formRisk.reset();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    editRiskCategory(riskCategory) {
      //   console.log(riskCategory);
      this.formRisk.fill(riskCategory);
      this.showEdit = true;
    },
    riskCategoryUpdate() {
      this.formRisk
        .put("/principal/riskCategoryUpdate/" + this.formRisk.id)
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getRisks");
          this.formRisk.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskCategory(riskCategory) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн ангилал устгахад, түүнд харьяалагдах бүх дэд ангилал устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/principal/riskCategoryDestroy/${riskCategory.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRisks");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцалсан",
            "Таны модуль сургалтыг устгасангүй :)",
            "error"
          );
        }
      });
    },
    riskCategoryClear() {
      this.formRisk.reset();
      this.showEdit = false;
    },
    //Эрсдэлийн дэд ангилалууд

    createRiskCategoryList(riskCategory) {
      // console.log(riskCategory);
      //   this.getCourseStudents(train.id);
      //   this.showModalTeachers = true;
      //   this.useInfosofSubTrains = train;
      this.formRiskList.rc_id = riskCategory.id;
      this.$bvModal.show("modal-risk-category-list");
    },

    storeRiskCategoryList() {
      this.formRiskList
        .post("/principal/storeRiskCategoryList")
        .then((res) => {
          this.$toastr.s("Дэд ангилал амжилттай нэмлээ!", "Шинэчилсэн");
          this.resetFormRiskList();
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    resetFormRiskList() {
      this.showEditRCL = false;
      Fire.$emit("getRisks");
      this.formRiskList.reset();
      this.$bvModal.hide("modal-risk-category-list");
    },

    cancel() {
      this.showEditRCL = false;
      this.formRiskList.reset();
      this.$bvModal.hide("modal-risk-category-list");
    },

    editRiskCategoryList(rcl) {
      //   console.log(rcl);
      this.formRiskList.fill(rcl);
      this.showEditRCL = true;
      this.$bvModal.show("modal-risk-category-list");
    },
    updateRiskCategoryList() {
      //   console.log("update");

      this.formRiskList
        .put("/principal/updateRiskCategoryList/" + this.formRiskList.id)
        .then((res) => {
          //   console.log(res);
          this.resetFormRiskList();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskCategoryList(rcl) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн дэд ангилал устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/principal/riskCategoryListDestroy/${rcl.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRisks");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Дэд ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Дэд ангилал устгахыг цуцалсан :)", "error");
        }
      });
    },

    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customName({ last_name, name }) {
      return `${last_name[0]}. ${name}`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllRiskCategories();
    Fire.$on("getRisks", () => {
      this.getAllRiskCategories();
    });
    Fire.$on("getSubTrainings", () => {
      this.getSubTrainAgain();
    });
  },
};
</script>

<style></style>
