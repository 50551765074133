<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-8">
              <multiselect
                v-model="selectedOperation"
                :options="alloperationname"
                :multiple="false"
                track-by="id"
                label="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Тархины үйлийн системд харгалзах оюуны үйлүүдийг сонгох!"
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                :custom-label="nameChange"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.tax_lev_name }} — [{{
                      option.tax_lev_name_operation
                    }}]</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </div>
            <div class="col-md-4">
              <div class="hoverBtn">
                <b-button
                  size="sm"
                  class="mt-2"
                  href="#"
                  variant="primary"
                  @click.prevent="createExplainText"
                  >Тохиргоо бүртгэх</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн системийн түвшиний оператор</th>
                <th>Эхлэх оноо</th>
                <th>Дуусах оноо</th>
                <th>Онооны шалгуур</th>
                <th>Тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(explain, index) in allStepExplains"
                :key="explain.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="width: 20rem text-align: justify"
                >
                  <b v-if="explain.my_lev_name_oper">
                    {{ explain.my_lev_name_oper["tax_lev_name_operation"] }}
                  </b>
                  <p v-if="explain.my_lev_name_oper" style="font-size: 80%">
                    {{
                      explain.my_lev_name_oper["tax_lev_name_operation_explain"]
                    }}
                  </p>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ explain.onoo_start }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ explain.onoo_finish }}
                </td>
                <td
                  class="dtr-control sorting_1 text-wrap"
                  tabindex="0"
                  style="width: 20rem text-align: justify; font-size: 80%"
                >
                  {{ explain.grading_require }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="width: 20rem text-align: justify; font-size: 80%"
                >
                  {{ explain.explain_sentence }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ explain.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ explain.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTaxonoyStepExplain(explain)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click.prevent="deleteStepExplain(explain.id)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-show-project"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      @hidden="cancelStepExplain"
    >
      <template #modal-title>
        <span v-show="editStepExplain === false">Бүртгэх</span>
        <span v-show="editStepExplain === true">Засах</span>
      </template>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <dl class="text-left">
              <div class="form-row">
                <div class="form-group col-lg-8">
                  <label
                    >Тархины үйлийн системд харгалзах оюуны үйлүүдийг
                    сонгох!</label
                  >
                  <multiselect
                    v-model="form.taxanomyLN_op_id"
                    :options="alloperationname"
                    :multiple="false"
                    track-by="id"
                    label="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Тархины үйлийн системд харгалзах оюуны үйлүүдийг сонгох!"
                    :allow-empty="true"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    :custom-label="nameChange"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.tax_lev_name }} — [{{
                          option.tax_lev_name_operation
                        }}]</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </div>

                <div class="form-group col-lg-4">
                  <label>Сургалтын төрөл сонгох</label>

                  <multiselect
                    v-model="form.surgalt_type"
                    :options="surgalt_types"
                    :multiple="false"
                    track-by="name"
                    label="name"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Сургалтын төрөл сонгох!"
                    :allow-empty="true"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    :custom-label="nameSurgalt"
                    :preselect-first="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.type_name }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-lg-3">
                  <label>Интервалын доод хязгаарын оноо</label>
                  <validation-provider
                    name="form.onoo_start"
                    rules="max_value:100|min_value:0"
                  >
                    <template #default="{ errors }">
                      <input
                        type="number"
                        name="onoo_start"
                        v-model="form.onoo_start"
                        aria-describedby="input-live-help input-live-feedback"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': errors[0],
                          },
                        ]"
                        placeholder="Оноо"
                        trim
                      />
                      <p style="font-size: 70%; text-align: justify">
                        Бүртгэгдсэн интервалын дээд онооноос их, тухайн
                        интервалын дээд онооноос бага байна.
                      </p>
                      <span
                        :class="[
                          {
                            'is-invalid': errors[0],
                          },
                        ]"
                      >
                        {{ errors[0] }}</span
                      >
                    </template>
                  </validation-provider>
                </div>
                <div class="form-group col-lg-3">
                  <label>Интервалын дээд хязгаарын оноо</label>
                  <validation-provider
                    name="form.onoo_finish"
                    rules="max_value:100|min_value:0"
                  >
                    <template #default="{ errors }">
                      <input
                        type="number"
                        name="onoo_finish"
                        v-model="form.onoo_finish"
                        aria-describedby="input-live-help input-live-feedback"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': errors[0],
                          },
                        ]"
                        placeholder="Оноо"
                        trim
                      />
                      <p style="font-size: 70%; text-align: justify">
                        Интервалын эхний онооноос их, 100 тэнцүү буюу бага
                        байна.
                      </p>
                      <span
                        :class="[
                          {
                            'is-invalid': errors[0],
                          },
                        ]"
                      >
                        {{ errors[0] }}</span
                      >
                    </template>
                  </validation-provider>
                </div>
                <div class="form-group col-lg-6">
                  <label>Тайлбар</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.grading_require"
                    placeholder="Онооны шалгуур"
                    rows="6"
                    max-rows="9"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('grading_require'),
                        'is-valid': form.grading_require,
                      },
                    ]"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <label>Тайлбар</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.explain_sentence"
                    placeholder="Тархины үйлийн системийн даалгаварт авсан онооны тайлбар. Энэхүү тайлбарлын тухайн оноонд харгалзуулан суралцагч, эцэг эхэд үзүүлнэ. Тиймээс тайлбарыг маш нарийн бодож хийхийг зөвлөж байна."
                    rows="6"
                    max-rows="9"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('explain_sentence'),
                        'is-valid': form.explain_sentence,
                      },
                    ]"
                  ></b-form-textarea>
                </div>
              </div>
            </dl>
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editStepExplain"
                @click.prevent="createStepExplain"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editStepExplain"
                @click.prevent="updateStepExplain"
              >
                Засах
              </button>
              <button
                class="btn btn-secondary"
                @click.prevent="cancelStepExplain"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      alloperationname: [],
      allStepExplains: [],
      surgalt_types: [
        { name: 1, type_name: "Хичээл" },
        { name: 2, type_name: "Хүмүүжил" },
        { name: 3, type_name: "Бие бялдар" },
      ], // 1-хичээл 2-хүмүүжил 3-бие биялдар
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        taxanomyLN_op_id: "",
        course_id: "",
        subject_id: "",
        enrol_emp_id: "",
        surgalt_type: "",
        onoo_start: "",
        onoo_finish: "",
        grading_require: "",
        explain_sentence: "",
      }),

      selectedOperation: "",
      editStepExplain: false,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    selectedOperation: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      axios
        .post("/curriculumadministrator/getMyOperationsStepExplain", {
          operations: newVal,
        })
        .then((res) => {
          // console.log(res.data);
          this.allStepExplains = res.data.allStepExplains;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    cancelStepExplain() {
      this.editStepExplain = false;
      this.form.reset();
      this.$bvModal.hide("modal-show-project");
    },

    getAllInfo() {
      axios
        .get("/curriculumadministrator/getTaxStepExpSenLevelNameOperAll")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.alloperationname = response.data.alloperationname;
          this.allStepExplains = response.data.allStepExplains;
          // this.allStepExplains.forEach((element) => {
          //   console.log(element.my_lev_name_oper);
          // });
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getConstOperationStepExplain() {
      axios
        .post("/curriculumadministrator/getMyOperationsStepExplain", {
          operations: this.selectedOperation,
        })
        .then((res) => {
          // console.log(res.data);
          this.allStepExplains = res.data.allStepExplains;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editTaxonoyStepExplain(explain) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editStepExplain = true;
      this.form.reset();
      this.form.fill(explain);
      let selectEl = this.alloperationname.filter(function (e) {
        return e.id == explain.taxanomyLN_op_id;
      });
      // console.log(selectEl);

      this.form.taxanomyLN_op_id = selectEl[0];

      let selectEl1 = this.surgalt_types.filter(function (e) {
        return e.name == explain.surgalt_type;
      });
      // console.log(selectEl1);

      this.form.surgalt_type = selectEl1[0];

      this.$bvModal.show("modal-show-project");
    },

    updateStepExplain() {
      this.form
        .patch("/curriculumadministrator/updateStepExplain/" + this.form.id)
        .then((res) => {
          // console.log(res.data);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message);
            this.cancelStepExplain();
            Fire.$emit("loadAllConstStepsExplains");
          } else if (res.data.info.code === "error")
            this.$toastr.e(res.data.info.message);
        })
        .catch((err) => console.log(err));
    },

    createExplainText() {
      this.form.reset();
      this.editStepExplain = false;
      let checkOperation = this.selectedOperation;
      // console.log(checkOperation);

      if (checkOperation != "") {
        let selectEl = this.alloperationname.filter(function (e) {
          return e.id == checkOperation["id"];
        });
        // console.log(selectEl);
        this.form.taxanomyLN_op_id = selectEl[0];
      }

      // this.showProjectForm.fill(project);
      this.$bvModal.show("modal-show-project");
    },
    createStepExplain() {
      this.form
        .post("/curriculumadministrator/createStepExplain")
        .then((res) => {
          // console.log(res.data.info);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message);
            this.cancelStepExplain();
            Fire.$emit("loadAllConstStepsExplains");
          } else if (res.data.info.code === "error")
            this.$toastr.e(res.data.info.message);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteStepExplain(id) {
      Swal.fire({
        title: "Энэ тайлбарын устгахдаа итгэлтэй байна уу?",
        text: "Устгасан үед зөвхөн админ сэргээх боломжтой!!!",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete("/curriculumadministrator/deleteStepExplain/" + id)
            .then((res) => {
              if (res.data.info.code === "success") {
                this.$toastr.s(res.data.info.message);
                this.cancelStepExplain();
                Fire.$emit("loadAllConstStepsExplains");
              } else if (res.data.info.code === "error")
                this.$toastr.e(res.data.info.message);
            })
            .catch((err) => console.log(err));
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },

    nameChange({ tax_lev_name, tax_lev_name_operation }) {
      return `${tax_lev_name} — [${tax_lev_name_operation}]`;
    },
    nameSurgalt({ type_name }) {
      return `${type_name}`;
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllStepsExplains", () => {
      this.getAllInfo();
    });
    Fire.$on("loadAllConstStepsExplains", () => {
      this.getConstOperationStepExplain();
    });
  },
};
</script>

<style></style>
