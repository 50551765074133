<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#teachersMineList"
                data-toggle="tab"
                >Миний дадал</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#teachersList" data-toggle="tab"
                >Багш</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#studentsList" data-toggle="tab"
                >Суралцагч</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#parentsList" data-toggle="tab"
                >Эцэг эх</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <!-- Багшийн өөрийн дадлууд -->
            <div class="tab-pane active" id="teachersMineList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="12">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                    </b-col>
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <!-- <th>Үйлдэл</th> -->
                        <!-- <th>Бүртгэсэн</th> -->
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitT, index) in habitsTeachersPositive"
                      :key="habitT.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <!-- <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitT)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td> -->

                        <!-- <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.reg_t_last_name[0] }}.
                          {{ habitT.reg_name }}({{
                            habitT.reg_department_code
                          }})
                        </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.employee_teacher_last_name[0] }}.
                          {{ habitT.emp_teacher_name }}({{
                            habitT.department_code
                          }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitT.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitT.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitT.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- Багш -->
            <div class="tab-pane" id="teachersList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitT, index) in habitsTeachers"
                      :key="habitT.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitT)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.reg_t_last_name[0] }}.
                          {{ habitT.reg_name }}({{
                            habitT.reg_department_code
                          }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.employee_teacher_last_name[0] }}.
                          {{ habitT.emp_teacher_name }}({{
                            habitT.department_code
                          }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitT.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitT.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitT.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitT.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="studentsList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitS, index) in habitsStudents"
                      :key="habitS.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitS)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.reg_t_last_name[0] }}.
                          {{ habitS.reg_name }}({{ habitS.department_code }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.pro_student_last_name[0] }}.
                          {{ habitS.pro_student_name }}({{
                            habitS.school_class_full_name
                          }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitS.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitS.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitS.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitS.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->

            <div class="tab-pane" id="parentsList">
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col lg="10">
                      <h3 class="card-title">Дадлын ажиглалт</h3>
                    </b-col>
                    <b-col lg="2" class="pb-2"
                      ><b-button
                        variant="outline-primary"
                        @click.prevent="createHabit()"
                        size="sm"
                        >Бүртгэх</b-button
                      ></b-col
                    >
                  </b-row>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Үйлдэл</th>
                        <th>Бүртгэсэн</th>
                        <th>Нэр</th>
                        <th>Хийсэн зан үйл</th>
                        <th>Дадлын үнэлэмж</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(habitP, index) in habitsParents"
                      :key="habitP.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span>
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editHabit(habitP)"
                            >
                              <i class="far fa-edit"></i>
                            </a>
                          </span>
                        </td>

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitP.reg_t_last_name[0] }}.
                          {{ habitP.reg_name }}({{
                            habitP.reg_department_code
                          }})
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          Эцэг эх: {{ habitP.parent_father_ovog[0] }}.
                          {{ habitP.parent_father_name }}({{
                            habitP.school_class_full_name
                          }}
                          анги-{{ habitP.student_last_name[0] }}.
                          {{ habitP.student_name }})
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ habitP.habit_of_info }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="habitP.habit_of_type === 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="habitP.habit_of_type === 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ habitP.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
          </div>
          <!-- /.tab-content -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <b-modal
      size="xl"
      id="modal-habit"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Өргөдөл, гомдол, санал, хүсэлтүүдийг харьяа мэргэжлийн багт хуваарилах"
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <!-- {{ form.comp_par_main_problem }} -->
                </h3>
                <b-row>
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-teacher"
                      value="teacher"
                      >Багш</b-form-radio
                    ></b-col
                  >
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-student"
                      value="student"
                      >Суралцагч</b-form-radio
                    ></b-col
                  >
                  <b-col lg="4">
                    <b-form-radio
                      v-model="selected"
                      name="some-parent"
                      value="parent"
                      >Эцэг эх</b-form-radio
                    ></b-col
                  >
                </b-row>
              </div>
              <!-- /.card-header -->
              <Multiselect
                v-show="selected === 'teacher'"
                v-model="form.selectedPeople"
                :options="allTeachers"
                track-by="employee_teacher_name"
                label="employee_teacher_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Багш сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameTeacher"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.emp_teacher_last_name }}
                    {{ option.employee_teacher_name }}({{
                      option.department_code
                    }})</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </Multiselect>

              <Multiselect
                v-show="selected === 'student'"
                v-model="form.selectedPeople"
                :options="allStudents"
                track-by="pro_student_name"
                label="pro_student_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Суралцагч сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameStudent"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>
                    {{ option.pro_student_last_name }}
                    {{ option.pro_student_name }}({{
                      option.school_class_full_name
                    }})</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </Multiselect>
              <Multiselect
                v-show="selected === 'parent'"
                v-model="form.selectedPeople"
                :options="allParents"
                track-by="parent_father_name"
                label="parent_father_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Эцэг эх сонгох"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :custom-label="customNameParent"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>
                    Аав:
                    {{ option.parent_father_ovog }}
                    {{ option.parent_father_name }}

                    Анги: {{ option.school_class_full_name }}: Суралцагч:
                    {{ option.student_last_name }}
                    {{ option.student_name }} сонгогдлоо</strong
                  ></template
                >
              </Multiselect>
              <div class="card-body table-responsive">
                <!-- <b-form @submit="onUpdate" @reset="onReset"> -->
                <!-- <b-form @submit="onUpdate"> -->
                <!-- @submit="" @reset="" -->
                <b-form>
                  <b-form-group id="habit_of_info" label-for="habit_of_info">
                    <b-form-textarea
                      id="habit_of_info"
                      v-model="form.habit_of_info"
                      placeholder="Ажигласан мэдээлэл"
                      required
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="form.checked"
                      id="checkboxes-4"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-radio
                        value="positive"
                        v-model="form.habit_of_type"
                        >Эерэг</b-form-radio
                      >
                      <b-form-radio
                        value="negative"
                        v-model="form.habit_of_type"
                        >Сөрөг</b-form-radio
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button variant="primary" @click.prevent="storeHabit()"
                    >Хадгалах</b-button
                  >
                  <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //MODAL BEGIN
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showMode: false,
      //MODAL END

      editMode: false,
      allHabits: [],
      //   keyword: "",
      selected: false,
      allTeachers: [],
      allStudents: [],
      allParents: [],
      habitsTeachersPositive: [],
      habitsTeachers: [],
      habitsStudents: [],
      habitsParents: [],
      shiidver: [
        { value: null, text: "Төвөл сонгоно уу?" },
        { value: 0, text: "Эерэг" },
        { value: 1, text: "Сөрөг" },
      ],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        regis_worker_id: "",
        regis_department_id: "",

        selectedPeople: "",
        // teacher_id: "",
        // teacher_dep_id: "",
        // student_id: "",
        // student_school_class_id: "",
        // parent_id: "",
        habit_of_info: "", //Ямар үйлдэл хийсэн талаарх тайлбар
        habit_of_type: "", //Эерэг-0 ба сөрөг-1
      }),
    };
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.indexOf("?") > -1) {
        this.form.reset();
      }
    },
  },
  methods: {
    getAllInfo() {
      axios
        .get("/all_worker_functions/getAllHabitsTeacher")
        .then((response) => {
          //   console.log(response.data);
          this.allTeachers = response.data.teachers;
          this.allStudents = response.data.students;
          this.allParents = response.data.parents;

          this.habitsTeachersPositive = response.data.habitsOfTeachersPositive;
          this.habitsTeachers = response.data.habitsOfTeachers;
          this.habitsStudents = response.data.habitsOfStudents;
          this.habitsParents = response.data.habitsOfParents;
          //   this.allEmployees = response.data.employees;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    customNameTeacher({
      employee_teacher_name,
      employee_teacher_last_name,
      department_code,
    }) {
      return `${employee_teacher_last_name} ${employee_teacher_name}(${department_code})`;
    },
    customNameStudent({
      pro_student_name,
      pro_student_last_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name} ${pro_student_name}: Анги: ${school_class_full_name}`;
    },
    customNameParent({
      parent_father_name,
      parent_father_ovog,
      school_class_full_name,
      student_last_name,
      student_name,
    }) {
      return `Аав: ${parent_father_ovog} ${parent_father_name}: Анги: ${school_class_full_name}: Суралцагч: ${student_last_name} ${student_name}`;
    },

    resetModal() {
      //   console.log("resetModal");
      this.$bvModal.hide("modal-habit");
      this.selected = "";
      this.form.reset();
    },
    createHabit() {
      this.$bvModal.show("modal-habit");
      //   console.log("create function load");
    },
    storeHabit() {
      axios
        .post("/all_worker_functions/createHabit", {
          selected: this.selected,
          form: this.form,
        })
        .then((response) => {
          this.resetModal();
          this.$toastr.s("Ажиглалт амжилттай хадгаллаа.", "Амжилттай");
          Fire.$emit("loadAllHabits");
        })
        .catch(() => {
          this.$toastr.e(
            "Уулзалтын бүртгэл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },

    passChangeSchoolEmployee(schoolEmp) {
      //   console.log(schoolEmp);
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу! Нууц үг: $elbe%2021",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/manage/passChangeEmployer/" + schoolEmp.user_id)
            .then((response) => {
              //   console.log(response.data);
              Swal.fire(
                "Нууц өөрчлөгдлөө!",
                "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
                "success"
              );
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    editComplaint(complaint) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", schoolteacher);
      this.editMode = true;
      this.form.reset();
      this.form.fill(complaint);
      this.form.id = complaint.complaint_id;
      this.form.student_id = complaint.complaint_students_id;
      this.form.main_content = complaint.complaint_main_content;
      this.form.offer = complaint.complaint_offer;
      this.form.complaint = complaint.complaint_complaint;
      this.form.advise = complaint.complaint_advise;
      this.form.uulz_ajilt_set = complaint.complaint_uulz_ajilt_set;
      this.form.shiid_baidal = complaint.complaint_shiid_baidal;
      this.form.shiid_tailbar = complaint.complaint_shiid_tailbar;
      this.form.meet_time = complaint.complaint_meet_time;
    },
    updateComplaint() {
      this.action = "Санал, гомдол шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateManagerComplaint/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllComplains");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteComplaint(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllHabits", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
