<template>
  <div class="row pt-3">
    <div class="col-md-8">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Бүртгэлтэй байгаа агуулгууд:</h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
            <b-button
              type="button"
              class="btn btn-tool"
              @click.prevent="showRandomParagraphQR()"
              variant="outline-dark"
              ><i class="fas fa-qrcode"></i>
            </b-button>
            <!-- <button
              type="button"
              class="btn btn-tool"
              data-card-widget="remove"
            >
              <i class="fas fa-times"></i>
            </button> -->
          </div>
        </div>
        <div class="card-body" style="overflow-x: auto">
          <b-row>
            <!-- <b-col>
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="light" style="font-size: 90%">
                  <b-tr>
                    <b-th>№</b-th>
                    <b-th>Бүртгэсэн</b-th>
                    <b-th>Улирал</b-th>
                    <b-th>Анги</b-th>
                    <b-th>Гарчиг</b-th>
                    <b-th>Агуулга</b-th>
                    <b-th>Тайлбар</b-th>
                    <b-th>Огноо</b-th>
                    <b-th>Үйлдлүүд</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  class="text-center"
                  style="font-size: 80%; text-align: justify"
                >
                  <b-tr
                    v-for="(paragraphContent, index) in paragraphContents"
                    :key="index"
                    v-if="paragraphContents.length"
                  >
                    <b-td>{{ index + 1 }}</b-td>
                    <b-td
                      >{{
                        paragraphContent.reg_emp.teacher_last_name[0].toUpperCase()
                      }}.{{ paragraphContent.reg_emp.teacher_name }}</b-td
                    >
                    <b-td>{{ paragraphContent.my_qrt.name }} </b-td>
                    <b-td
                      >{{ paragraphContent.start_grade }} :
                      {{ paragraphContent.finish_grade }}
                    </b-td>
                    <b-td style="text-align: justify">{{
                      paragraphContent.paragraph_title
                    }}</b-td>
                    <b-td style="width: 20rem; text-align: justify">{{
                      paragraphContent.paragraph_content
                    }}</b-td>
                    <b-td style="width: 15rem">
                      {{ paragraphContent.paragraph_content_explain }}</b-td
                    >
                    <b-td>{{
                      paragraphContent.created_at | dateYearMonthDay
                    }}</b-td>

                    <b-td>
                      <b-form-group label-size="sm" label-for="input-sm">
                        <b-button-group size="sm">
                          <b-button
                            class="my-1"
                            size="sm"
                            variant="outline-primary"
                            @click.prevent="
                              editParagraphContent(paragraphContent)
                            "
                          >
                            <b-icon icon="tools" font-scale="0.8"></b-icon>
                          </b-button>

                          <b-button
                            class="my-1"
                            size="sm"
                            @click.prevent="showParagraphQR(paragraphContent)"
                            variant="outline-dark"
                            ><i class="fas fa-qrcode"></i>
                          </b-button>

                          <b-button
                            class="my-1"
                            size="sm"
                            variant="danger"
                            @click.prevent="
                              deleteParagraphContent(paragraphContent)
                            "
                            ><b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                              font-scale="0.8"
                            ></b-icon
                          ></b-button>
                        </b-button-group>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td colspan="9" variant="secondary" class="text-right">
                      Бүртгэлтэй агуулга:
                      <b v-if="paragraphContents.length">{{
                        paragraphContents.length
                      }}</b>
                      <b v-if="!paragraphContents.length"
                        >Одоогоор агуулга бүртгээгүй байна.</b
                      >
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-col> -->

            <!-- <b-col sm="7" md="12" class="my-1">
              <b-form-group
                label="Initial sort"
                label-for="initial-sort-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="initial-sort-select"
                  v-model="sortDirection"
                  :options="languages"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col> -->

            <b-col sm="7" md="12" class="my-1">
              <b-form-group
                label="Эрэмбэлэх"
                label-for="sort-by-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                  >
                    <template #first>
                      <option value="">-- талбар сонгоно уу --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :aria-describedby="ariaDescribedby"
                    size="sm"
                    class="w-25"
                  >
                    <option :value="false">Багаас ихрүү</option>
                    <option :value="true">Ихээс багаруу</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="7" md="12" class="my-1">
              <b-form-group
                label="Хайх"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Гарчиг, агуулгаар шүүнэ үү"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Цэвэрлэх</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="7" md="12" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-table
              hover
              :items="paragraphContents"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              @filtered="onFiltered"
              :filter-ignored-fields="filterIgnoredFields"
            >
              <!-- :filter-included-fields="filterOn" -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(reg_emp)="data">
                <b-badge variant="info" v-if="data.item.reg_emp">
                  {{ data.item.reg_emp.teacher_name }} <br />
                  (Улирал: {{ data.item.my_qrt.name }}) <br />
                </b-badge>
                <b-badge variant="warning" v-else> Бүртгэгдээгүй </b-badge>
              </template>
              <template #cell(languages)="data">
                <b-badge variant="info"
                  >(Хэл: {{ checkLanguage(data.item.language) }})
                </b-badge>
              </template>
              <template #cell(start_grade)="data">
                {{ data.item.start_grade }} : {{ data.item.finish_grade }} анги
              </template>
              <template #cell(paragraph_title)="data">
                {{ data.item.paragraph_title }}
              </template>
              <template #cell(paragraph_content)="data">
                <div
                  class="truncate"
                  style="width: 14rem; hight: 50px; text-align: justify"
                >
                  {{ data.item.paragraph_content }}
                </div>
              </template>
              <template #cell(paragraph_content_explain)="data">
                <div
                  class="truncate"
                  style="width: 8rem; hight: 50px; text-align: justify"
                >
                  {{ data.item.paragraph_content_explain }}
                </div>
              </template>
              <template #cell(action)="data">
                <b-form-group label-size="sm" label-for="input-sm">
                  <b-button-group size="sm">
                    <b-button
                      class="my-1"
                      size="sm"
                      variant="outline-primary"
                      @click.prevent="editParagraphContent(data.item)"
                    >
                      <b-icon icon="tools" font-scale="0.8"></b-icon>
                    </b-button>

                    <b-button
                      class="my-1"
                      size="sm"
                      @click.prevent="showParagraphQR(data.item)"
                      variant="outline-dark"
                      ><i class="fas fa-qrcode"></i>
                    </b-button>

                    <b-button
                      class="my-1"
                      size="sm"
                      variant="danger"
                      @click.prevent="deleteParagraphContent(data.item)"
                      ><b-icon
                        icon="trash-fill"
                        aria-hidden="true"
                        font-scale="0.8"
                      ></b-icon
                    ></b-button>
                  </b-button-group>
                </b-form-group>
              </template>
            </b-table>
          </b-row>
        </div>
      </div>
    </div>
    <!-- Хичээлд суралцагч бүртгэх -->
    <div class="col-md-4">
      <!-- general form elements -->
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">Сорилд ашиглах агуулга бүртгэх</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <b-form-select
                v-model="form.start_grade"
                :options="grades"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <b-form-select
                v-model="form.finish_grade"
                :options="grades"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <b-form-select
                v-model="form.quarter_id"
                :options="quarters"
                size="sm"
                class="mt-3"
                aria-placeholder="Улирал сонгох"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >-- Улирал сонгоно уу! --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-md-6">
              <b-form-select
                v-model="form.language"
                :options="languages"
                size="sm"
                class="mt-3"
                aria-placeholder="Хэл сонгох"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >-- Хэл сонгоно уу! --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>

            <div class="col-md">
              <b-form-input
                v-model="form.paragraph_title"
                placeholder="Тухайн агуулгын гарчиг оруулах"
              ></b-form-input>
              <b-form-textarea
                id="textarea"
                v-model="form.paragraph_content"
                placeholder="Сорилын агуулга оруулах"
                rows="7"
                max-rows="10"
              ></b-form-textarea>
              <b-form-textarea
                id="textarea"
                v-model="form.paragraph_content_explain"
                placeholder="Хэрэв та минутын уншлага хийх бол дараах зүйлс дээр анхаарна уу? Хэрэв та цээж бичиг хийх бол дараах зүйлс дээр анхаарна уу? гэх мэтээр агуулгатай хэрхэн ажиллах арга барилыг тайлбарлаж бичнэ."
                rows="5"
                max-rows="10"
              ></b-form-textarea>
            </div>
          </div>
          <div>
            <b-button
              variant="outline-success"
              @click="createParagraphContent()"
              v-show="!editMode"
              >Агуулга бүртгэх</b-button
            >
            <b-button
              variant="outline-warning"
              v-show="editMode"
              @click="updateParagraphContent()"
              >Агуулга засах</b-button
            >
            <b-button
              variant="outline-primary"
              v-show="editMode"
              @click="resetParagraph()"
              >Цуцлах</b-button
            >
          </div>
        </div>
      </div>
      <!-- /.card -->
    </div>
    <b-modal size="sm" id="modal-qr" title="QR код">
      <div class="text-center">
        <p><b>Нэр: </b>{{ qrShow.name }}</p>
        <qr-code class="qrCode" :text="qrShow.url"></qr-code>
        <p class="mt-3">
          <b>Холбоос: </b>
          <a :href="qrShow.url" target="_blank">{{ qrShow.name }}</a>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      paragraphContents: [], //ашигласан
      quarters: [],
      form: new Form({
        id: "",
        language: "",
        quarter_id: "",
        start_grade: null,
        finish_grade: null,
        paragraph_title: "",
        paragraph_content: "",
        paragraph_content_explain: "",
      }),

      grades: [
        { value: null, text: "Анги сонгоно уу!" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
      ],
      languages: [
        { value: null, text: "Хэл сонгоно уу!" },
        { text: "Монгол хэл", value: 0 },
        { text: "Англи хэл", value: 1 },
        { text: "Хятад хэл", value: 2 },
      ],

      //Хүснэгтийн тохиргоо
      totalRows: 1,
      currentPage: 1,
      perPage: 30,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      //   filterOn: [],
      filterIgnoredFields: [
        "index",
        "start_grade",
        // "paragraph_content",
        "paragraph_content_explain",
        "reg_emp",
      ],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "reg_emp",
          label: "Бүртгэсэн",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "languages",
          label: "Хэл",
          sortable: true,
          //   sortDirection: "desc",
        },
        // {
        //   key: "my_qrt",
        //   label: "Улирал",
        //   sortable: true,
        //   //   sortDirection: "desc",
        // },
        {
          key: "start_grade",
          label: "Анги",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "paragraph_title",
          label: "Гарчиг",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "paragraph_content",
          label: "Агуулга",
          sortable: true,
          //   sortDirection: "desc",
        },
        {
          key: "paragraph_content_explain",
          label: "Тайлбар",
          sortable: true,
          //   sortDirection: "desc",
        },
        // {
        //   key: "created_at",
        //   label: "Огноо",
        //   sortable: true,
        //   //   sortDirection: "desc",
        // },
        {
          key: "action",
          label: "Үйлдлүүд",
          sortable: true,
          //   sortDirection: "desc",
        },
        // {
        //   key: "isActive",
        //   label: "Is Active",
        //   formatter: (value, key, item) => {
        //     return value ? "Yes" : "No";
        //   },
        //   sortable: true,
        //   sortByFormatted: true,
        //   filterByFormatted: true,
        // },
      ],

      //QR public
      qrShow: { name: "", url: "" },
    };
  },
  components: { Multiselect },
  computed: {
    baseURL() {
      return (
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
      );
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  //   directives: {
  //     truncateText: {
  //       bind(el, binding) {
  //         // console.log(binding.value);
  //         const maxLength = binding.value.length; // Maximum length of the truncated text
  //         // console.log("el=>" + el.textContent.length);
  //         // console.log("binding=>" + maxLength);
  //         if (el.textContent.length > maxLength) {
  //           el.textContent = el.textContent.slice(0, maxLength) + "...";
  //         }
  //       },
  //     },
  //   },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkLanguage(item) {
      let a = "";
      this.languages.forEach((element) => {
        if (element.value == item) {
          a = element.text;
        }
      });
      return a;
    },
    sortArrays(schoolteachers) {
      return _.orderBy(schoolteachers, "teacher_name/department_name", "asc");
    },
    getAllParagraphContent() {
      axios
        .get("/manager/getAllParagraphContents")
        .then((response) => {
          this.paragraphContents = response.data.paragraphContents;
          this.totalRows = this.paragraphContents.length;
          this.quarters = response.data.quarters;
          // this.paragraphContents.forEach((el) => {
          //   console.log(el.reg_emp);
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createParagraphContent() {
      this.form
        .post("/manager/createParagraphContent")
        .then((res) => {
          // console.log(res);
          Fire.$emit("loadParagraphContent");
          this.resetParagraph();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetParagraph() {
      this.form.reset();
      this.editMode = false;
    },

    editParagraphContent(paragraphContent) {
      //   console.log(paragraphContent);
      this.editMode = true;
      this.form.reset();
      this.form.fill(paragraphContent);
    },
    updateParagraphContent() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      // console.log(this.form);
      this.form
        .put("/manager/updateParagraphContent/" + this.form.id)
        .then((response) => {
          // console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadParagraphContent");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    showParagraphQR(sat) {
      //   console.log(sat);
      this.qrShow.url = this.baseURL + "/enterParagraph/" + sat.id;
      //   if (sat.type == "Дурын хэрэглэгчид")
      //     this.qrShow.url = this.baseURL + "/enterSatisfaction/" + sat.id;
      //   else
      //     this.qrShow.url = this.baseURL + "/all_worker_functions/satisfactions";
      this.qrShow.name = sat.paragraph_title;
      this.$bvModal.show("modal-qr");
    },
    showRandomParagraphQR() {
      //   console.log(sat);
      this.qrShow.url = this.baseURL + "/enterParagraphRandom";
      //   if (sat.type == "Дурын хэрэглэгчид")
      //     this.qrShow.url = this.baseURL + "/enterSatisfaction/" + sat.id;
      //   else
      //     this.qrShow.url = this.baseURL + "/all_worker_functions/satisfactions";
      this.qrShow.name = "Уншлагын сангаас санамсаргүй";
      this.$bvModal.show("modal-qr");
    },

    deleteParagraphContent(paragraphContent) {
      Swal.fire({
        title: "Сорилын агуулга устгахдаа итгэлтэй байна уу?",
        text: "Агуулга устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: `Агуулга устгах`,
        denyButtonText: `Цуцлах`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete("/manager/deleteParagraphContent/" + paragraphContent.id)
            .then((res) => {
              //   console.log(res);
              Fire.$emit("loadParagraphContent");
            })
            .catch((err) => {
              console.log(err);
            });
          /* Read more about isConfirmed, isDenied below */
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },

    // customLabel({ user_name, profile_student_last_name }) {
    //   return `${user_name} – ${profile_student_last_name}`;
    // },
    // customLabelTeacher({ teacher_name, teacher_last_name }) {
    //   return ` ${teacher_last_name[0].toUpperCase()}.${teacher_name}`;
    // },
  },
  created() {
    this.getAllParagraphContent();
    Fire.$on("loadParagraphContent", () => {
      this.getAllParagraphContent();
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
