<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        <b>Элсэлт ба шилжилт хөдөлгөөн (Суралцагчид)</b>
      </h5>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <multiselect
            v-model="school_year_id"
            select-label="Сонгох"
            selected-label="Сонгогдсон"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жил сонгоно уу?"
            :options="years"
            :searchable="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <!-- /.col -->
          <div class="row mt-2" v-if="viewYearChart">
            <div class="col-12 col-sm-12">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын тоо анги тус бүрээр</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsAllClass"
                      :series="seriesAllClassNum"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын тоо бүлэг тус бүрээр</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsAllClassLvl"
                      :series="seriesAllClassLvl"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Нийт суралцагчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      class="barChart"
                      height="340"
                      :options="optionsGenderAllStud"
                      :series="seriesGenderAllStud"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Нийт суралцагчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsGenderAllStudP"
                      :series="seriesGenderAllStudP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Бэлтгэл бүлэг -->
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Бэлтгэл бүлгийн суралцагчдын хүйсийн харьцаа
                    </strong>
                    <apexchart
                      type="bar"
                      class="barChart"
                      height="340"
                      :options="optionsGenderStudPre"
                      :series="seriesGenderStudPre"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Бэлтгэл бүлгийн суралцагчдын хүйсийн харьцаа
                    </strong>
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsGenderStudPre2"
                      :series="seriesGenderStudPre2"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Хувиар харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хүйсийн харьцаа(Бага анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsGenderPrimaryStudP"
                      :series="seriesGenderPrimaryStudP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хүйсийн харьцаа (Дунд анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsGenderMiddleStudP"
                      :series="seriesGenderMiddleStudP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хүйсийн харьцаа (Ахлах анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsGenderHighStudP"
                      :series="seriesGenderHighStudP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хүйсийн харьцаа</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsGenderAllClass"
                      :series="seriesGenderAllClass"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="bar"
                      class="barChart"
                      height="340"
                      :options="optionsDistAllStud"
                      :series="seriesDistAllStud"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistAllStudP"
                      :series="seriesDistAllStudP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил (Бага анги)</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistPrimaryStud"
                      :series="seriesDistPrimaryStud"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил (Дунд анги)</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistMiddleStud"
                      :series="seriesDistMiddleStud"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил (Ахлах анги)</strong
                    >
                    <apexchart
                      type="bar"
                      height="340"
                      class="barChart"
                      :options="optionsDistHighStud"
                      :series="seriesDistHighStud"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
            <!-- Хувиар харуулах -->
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил(Бага анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistPrimaryStudP"
                      :series="seriesDistPrimaryStudP"
                    >
                    </apexchart>
                    <multiselect
                      v-model="classDistP_id"
                      select-label="Сонгох"
                      selected-label="Сонгогдсон"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Анги сонгоно уу?"
                      :options="classListP"
                      :searchable="false"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          анги
                        </strong></template
                      >
                    </multiselect>
                    <apexchart
                      v-if="viewDistClassP"
                      type="donut"
                      height="350"
                      class="pieChart"
                      :options="optionsDistClassSelPName"
                      :series="seriesDistClassSelP"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил (Дунд анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistMiddleStudP"
                      :series="seriesDistMiddleStudP"
                    >
                    </apexchart>
                    <multiselect
                      v-model="classDistM_id"
                      select-label="Сонгох"
                      selected-label="Сонгогдсон"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Анги сонгоно уу?"
                      :options="classListM"
                      :searchable="false"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          анги
                        </strong></template
                      >
                    </multiselect>
                    <apexchart
                      v-if="viewDistClassM"
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistClassSelMName"
                      :series="seriesDistClassSelM"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span>
                    <strong class="info-box-text mt-4 text-center">
                      Суралцагчдын хаяг байршил (Ахлах анги)</strong
                    >
                    <apexchart
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistHighStudP"
                      :series="seriesDistHighStudP"
                    ></apexchart>
                    <multiselect
                      v-model="classDistH_id"
                      select-label="Сонгох"
                      selected-label="Сонгогдсон"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="id"
                      label="full_name"
                      placeholder="Анги сонгоно уу?"
                      :options="classListH"
                      :searchable="false"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.full_name }}
                          анги
                        </strong></template
                      >
                    </multiselect>
                    <apexchart
                      v-if="viewDistClassH"
                      type="donut"
                      class="pieChart"
                      height="350"
                      :options="optionsDistClassSelHName"
                      :series="seriesDistClassSelH"
                    >
                    </apexchart>
                  </span>
                </div>
              </div>
            </div>
            <div class="info-box mb-3">
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Нийт сургууль(Жилээр)</strong
                >
                <apexchart
                  type="donut"
                  :options="optionsYearAll"
                  :series="seriesAllClass"
                >
                </apexchart>
              </div>
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Нийт сургууль(Жилээр)</strong
                >
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsYearAllRad"
                  :series="seriesAllClassRad"
                >
                </apexchart>
              </div>
              <div class="col-12 col-sm-4">
                <strong class="info-box-text text-center"
                  >Нийт сургууль(Жилээр)</strong
                >
                <apexchart
                  type="polarArea"
                  :options="optionsLeaveTypeAll"
                  :series="seriesAllLType"
                >
                </apexchart>
              </div>
            </div>
          </div>
          <div class="row mt-2" v-if="viewYearChart">
            <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <strong class="info-box-text text-center"
                        >Бага сургууль(Жилээр)</strong
                      >
                      <apexchart
                        type="donut"
                        :options="optionsYear"
                        :series="seriesPrimary"
                      >
                      </apexchart>
                      <div class="text-center">
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesPrimary[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Шилжсэн
                          <b-badge variant="light" pill>{{
                            seriesPrimary[1]
                          }}</b-badge>
                        </b-badge>
                      </div>
                      <strong class="info-box-text mt-4 text-center"
                        >Шилжсэн шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeP"
                        :series="seriesPrimaryLType"
                      >
                      </apexchart>
                      <strong class="info-box-text mt-4 text-center">
                        Сар тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsMonth"
                        :series="seriesPrimaryMonth"
                      >
                      </apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <strong class="info-box-text text-center"
                        >Дунд сургууль(Жилээр)</strong
                      >
                      <div class="text-center">
                        <apexchart
                          :options="optionsYear"
                          :series="seriesMiddle"
                        ></apexchart>
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesMiddle[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Шилжсэн
                          <b-badge variant="light" pill>{{
                            seriesMiddle[1]
                          }}</b-badge>
                        </b-badge>
                        <!-- <b-badge variant="primary" pill>
                                  Тухайн жил ирсэн
                                  <b-badge variant="light" pill>{{
                                    seriesMiddle[2]
                                  }}</b-badge>
                                </b-badge>
                                <b-badge variant="warning" pill>
                                  Зөрүү
                                  <b-badge variant="light" pill>{{
                                    yMiddleDiff
                                  }}</b-badge>
                                </b-badge> -->
                      </div>
                      <strong class="info-box-text mt-4 text-center"
                        >Шилжсэн шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeM"
                        :series="seriesMiddleLType"
                      >
                      </apexchart>
                      <strong class="info-box-text mt-4 text-center"
                        >Сар тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsMonth"
                        :series="seriesMiddleMonth"
                      >
                      </apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <strong class="info-box-text text-center"
                        >Ахлах сургууль(Жилээр)</strong
                      >
                      <div class="text-center">
                        <apexchart
                          :options="optionsYear"
                          :series="seriesHigh"
                        ></apexchart>
                        <b-badge variant="success" pill>
                          Идэвхтэй
                          <b-badge variant="light" pill>{{
                            seriesHigh[0]
                          }}</b-badge>
                        </b-badge>
                        <b-badge variant="danger" pill>
                          Шилжсэн
                          <b-badge variant="light" pill>{{
                            seriesHigh[1]
                          }}</b-badge>
                        </b-badge>
                        <!-- <b-badge variant="primary" pill>
                                  Тухайн жил ирсэн
                                  <b-badge variant="light" pill>{{
                                    seriesHigh[2]
                                  }}</b-badge>
                                </b-badge>
                                <b-badge variant="warning" pill>
                                  Зөрүү
                                  <b-badge variant="light" pill>{{
                                    yHighDiff
                                  }}</b-badge>
                                </b-badge> -->
                      </div>
                      <strong class="info-box-text text-center mt-4"
                        >Шилжсэн шалтгаан</strong
                      >
                      <apexchart
                        type="polarArea"
                        :options="optionsLeaveTypeH"
                        :series="seriesHighLType"
                      >
                      </apexchart>
                      <strong class="info-box-text text-center mt-4"
                        >Сар тус бүрээр</strong
                      >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="optionsMonth"
                        :series="seriesHighMonth"
                      >
                      </apexchart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="isClassView">
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Анги тус бүрээр</strong
                        >
                        <apexchart
                          type="bar"
                          height="350"
                          :options="optionsPrimaryClass"
                          :series="seriesPrimaryClass"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                  <multiselect
                    v-model="classP_id"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id"
                    label="full_name"
                    placeholder="Анги сонгоно уу?"
                    :options="classListP"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.full_name }}
                        анги
                      </strong></template
                    >
                  </multiselect>
                  <div
                    class="card card-success collapsed-card"
                    v-if="viewClassStdP"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжиж ирсэн</h3>

                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classNewPlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        <li
                          v-for="classNewP in classNewPlist"
                          :key="classNewP.id"
                        >
                          <img
                            v-if="classNewP.img != null"
                            :src="classNewP.img"
                            alt="Зураг"
                          />
                          <img
                            v-else
                            src="/images/users/user.png"
                            alt="Зураг"
                          />
                          <a class="users-list-name" href="#">{{
                            classNewP.name
                          }}</a>
                          <span class="users-list-date">{{
                            classNewP.date | dateYearMonthDay
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="card card-danger collapsed-card"
                    v-if="viewClassStdP"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжсэн</h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classLeavePlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 90%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Шилжсэн төрөл</th>
                            <th>Шилжсэн шалтгаан</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="classLeaveP in classLeavePlist"
                          :key="classLeaveP.id"
                        >
                          <tr>
                            <td>
                              <img
                                v-if="classLeaveP.img != null"
                                class="direct-chat-img"
                                :src="classLeaveP.img"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveP.name }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveP.type }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveP.shaltgaan }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveP.date | dateYearMonthDay }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Анги тус бүрээр</strong
                        >
                        <apexchart
                          type="bar"
                          height="350"
                          :options="optionsMiddleClass"
                          :series="seriesMiddleClass"
                        ></apexchart>
                      </span>
                    </div>
                  </div>
                  <multiselect
                    v-model="classM_id"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id"
                    label="full_name"
                    placeholder="Анги сонгоно уу?"
                    :options="classListM"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.full_name }}
                        анги
                      </strong></template
                    >
                  </multiselect>
                  <div
                    class="card card-success collapsed-card"
                    v-if="viewClassStdM"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжиж ирсэн</h3>

                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classNewMlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        <li
                          v-for="classNewM in classNewMlist"
                          :key="classNewM.id"
                        >
                          <img
                            v-if="classNewM.img != null"
                            :src="classNewM.img"
                            alt="Зураг"
                          />
                          <img
                            v-else
                            src="/images/users/user.png"
                            alt="Зураг"
                          />
                          <a class="users-list-name" href="#">{{
                            classNewM.name
                          }}</a>
                          <span class="users-list-date">{{
                            classNewM.date | dateYearMonthDay
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="card card-danger collapsed-card"
                    v-if="viewClassStdM"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжсэн</h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classLeaveMlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 90%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Шилжсэн төрөл</th>
                            <th>Шилжсэн шалтгаан</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="classLeaveM in classLeaveMlist"
                          :key="classLeaveM.id"
                        >
                          <tr>
                            <td>
                              <img
                                v-if="classLeaveM.img != null"
                                class="direct-chat-img"
                                :src="classLeaveM.img"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveM.name }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveM.type }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveM.shaltgaan }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveM.date | dateYearMonthDay }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span>
                        <strong class="info-box-text mt-4 text-center">
                          Анги тус бүрээр</strong
                        >
                        <apexchart
                          type="bar"
                          height="350"
                          :options="optionsHighClass"
                          :series="seriesHighClass"
                        >
                        </apexchart>
                      </span>
                    </div>
                  </div>
                  <multiselect
                    v-model="classH_id"
                    select-label="Сонгох"
                    selected-label="Сонгогдсон"
                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id"
                    label="full_name"
                    placeholder="Анги сонгоно уу?"
                    :options="classListH"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.full_name }}
                        анги
                      </strong></template
                    >
                  </multiselect>
                  <div
                    class="card card-success collapsed-card"
                    v-if="viewClassStdH"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжиж ирсэн</h3>

                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classNewHlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        <li
                          v-for="classNewH in classNewHlist"
                          :key="classNewH.id"
                        >
                          <img
                            v-if="classNewH.img != null"
                            :src="classNewH.img"
                            alt="Зураг"
                          />
                          <img
                            v-else
                            src="/images/users/user.png"
                            alt="Зураг"
                          />
                          <a class="users-list-name" href="#">{{
                            classNewH.name
                          }}</a>
                          <span class="users-list-date">{{
                            classNewH.date | dateYearMonthDay
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="card card-danger collapsed-card"
                    v-if="viewClassStdH"
                  >
                    <div class="card-header">
                      <h3 class="card-title">Шилжсэн</h3>
                      <div class="card-tools">
                        <span class="badge badge-warning">{{
                          Object.keys(classLeaveHlist).length
                        }}</span>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 table-responsive">
                      <table class="table table-bordered">
                        <thead style="font-size: 90%">
                          <tr role="row" class="text-center">
                            <th>Зураг</th>
                            <th>Овог нэр</th>
                            <th>Шилжсэн төрөл</th>
                            <th>Шилжсэн шалтгаан</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="classLeaveH in classLeaveHlist"
                          :key="classLeaveH.id"
                        >
                          <tr>
                            <td>
                              <img
                                v-if="classLeaveH.img != null"
                                class="direct-chat-img"
                                :src="classLeaveH.img"
                                alt="user-avatar"
                              />
                              <img
                                v-else
                                class="direct-chat-img"
                                src="/images/users/user.png"
                                alt="user-avatar"
                              />
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveH.name }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveH.type }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveH.shaltgaan }}
                            </td>
                            <td class="text-center" tabindex="0">
                              {{ classLeaveH.date | dateYearMonthDay }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-button
            v-if="viewYearChart && !isClassView"
            @click.prevent="getEachClass()"
            block
            variant="outline-primary"
            >Анги тус бүрээр харах</b-button
          >
        </div>
      </div>
      <!-- /.row -->
    </div>
  </div>
  <!-- /.col -->
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      myAllInfos: [],
      iconMode: "fa fa-bars",
      btnColor: "warning",
      teachertypes: [],
      school_year_id: "",
      school_year_id2: "",
      selectedDepTeach: "",
      viewYearChart: false,
      viewYearChartTeach: false,
      seriesPre: [0],
      seriesPrimary: [0],
      seriesMiddle: [0],
      seriesHigh: [0],
      isClassView: false,
      optionsYearAll: {
        labels: ["Бага анги", "Дунд анги", "Ахлах анги"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsYearAllRad: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1) + "%";
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      optionsYear: {
        labels: ["Идэвхтэй", "Шилжсэн"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      yPrimaryDiff: 0,
      yMiddleDiff: 0,
      yHighDiff: 0,
      yTeachDiff: 0,
      yEmployeeDiff: 0,
      yManagementDiff: 0,
      seriesPrimaryLType: [],
      seriesAllLType: [],
      optionsLeaveTypeP: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      optionsLeaveTypeM: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      optionsLeaveTypeH: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      optionsLeaveTypeAll: {
        labels: [],
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      seriesMiddleLType: [],
      seriesHighLType: [],
      seriesPrimaryMonth: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      optionsMonth: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "month",
          categories: [
            "1-р сар",
            "2-р сар",
            "3-р сар",
            "4-р сар",
            "5-р сар",
            "6-р сар",
            "7-р сар",
            "8-р сар",
            "9-р сар",
            "10-р сар",
            "11-р сар",
            "12-р сар",
          ],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesMiddleMonth: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      seriesHighMonth: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      optionsPrimaryClass: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "number",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsMiddleClass: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "number",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      optionsHighClass: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "number",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesPrimaryClass: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      seriesMiddleClass: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      seriesHighClass: [
        {
          name: "Шилжсэн",
          data: [],
        },
        {
          name: "Шилжиж ирсэн",
          data: [],
        },
        {
          name: "Зөрүү",
          data: [],
        },
      ],
      //анги тус бүрээр хүүхдийн тоо харах
      optionsGenderAllClass: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#bf1010", "#28a745", "#ffc107", "#6495ED"],
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#BED1E6",
                colorTo: "#D8E3F0",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderAllClass: [
        {
          name: "Бүртгэлгүй",
          data: [],
        },
        {
          name: "Эрэгтэй",
          data: [],
        },
        {
          name: "Эмэгтэй",
          data: [],
        },
        {
          name: "Нийт",
          data: [],
        },
      ],
      optionsAllClass: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = (val / 52) * 100;
            return a.toFixed(1) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              let a = val - 52;
              if (a > 0) {
                return "+" + a;
              } else {
                return a;
              }
            },
          },
        },
      },
      seriesAllClassNum: [
        {
          name: "Суралцагчдын тоо хэтэрсэн байдал",
          data: [],
        },
      ],
      optionsAllClassLvl: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let a = (val / 26) * 100;
            return a.toFixed(1) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: 26,
          },
          axisTicks: {
            show: 26,
          },
          labels: {
            show: 26,
            formatter: function (val) {
              let a = val - 26;
              if (a > 0) {
                return "+" + a;
              } else {
                return a;
              }
            },
          },
        },
      },
      seriesAllClassLvl: [
        {
          name: "Суралцагчдын тоо хэтэрсэн байдал",
          data: [],
        },
      ],
      //Сурагчдын хүйсийн харьцаа
      optionsGenderAllStud: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderAllStud: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      seriesGenderAllStudP: [],
      optionsGenderAllStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },

      //Бэлтгэл
      optionsGenderStudPre: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesGenderStudPre: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      optionsGenderStudPre2: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesGenderStudPre2: [],

      seriesGenderPrimaryStudP: [],
      optionsGenderPrimaryStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesGenderMiddleStudP: [],
      optionsGenderMiddleStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesGenderHighStudP: [],
      optionsGenderHighStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      //Сурагчдын хаяг байршил
      optionsDistAllStud: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesDistAllStud: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      seriesDistAllStudP: [],
      optionsDistAllStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistPrimaryStud: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsDistMiddleStud: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsDistHighStud: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsDistPrimaryStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistMiddleStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsDistHighStudP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesDistPrimaryStudP: [],
      seriesDistMiddleStudP: [],
      seriesDistHighStudP: [],
      seriesDistPrimaryStud: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      seriesDistMiddleStud: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      seriesDistHighStud: [
        {
          name: "Сурагчдын тоо",
          data: [],
        },
      ],
      seriesDistClassSelP: [],
      optionsDistClassSelPName: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesDistClassSelM: [],
      optionsDistClassSelMName: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesDistClassSelH: [],
      optionsDistClassSelHName: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      classListP: [],
      classListM: [],
      classListH: [],
      classP_id: "",
      classM_id: "",
      classH_id: "",
      classDistP_id: "",
      classDistM_id: "",
      classDistH_id: "",
      classLeavePlist: [],
      classNewPlist: [],
      viewClassStdP: false,
      viewDistClassP: false,
      viewDistClassM: false,
      viewDistClassH: false,
      classLeaveMlist: [],
      classNewMlist: [],
      viewClassStdM: false,
      classLeaveHlist: [],
      classNewHlist: [],
      viewClassStdH: false,
      students: [],
      studentsPrimary: [],

      allClassNew: "",
      allClassLeave: "",
      allClassActive: "",
      yAllClassDiff: 0,

      seriesAllClass: [0],
      seriesAllClassRad: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  watch: {
    school_year_id: function (newVal, oldVal) {
      this.viewYearChart = false;
      // this.seriesPre[0] = [];
      this.seriesPrimary[0] = [];
      this.seriesMiddle[0] = [];
      this.seriesHigh[0] = [];
      if (newVal != "") {
        // this.getSum();
        axios
          .post("/manager/getYearStudentData", {
            year: newVal.id,
          })
          .then((res) => {
            this.distName = res.data.distCount;
            this.distName.forEach((element) => {
              this.sumOptions.forEach((el) => {
                if (element["student_khoroo"] == el["id"]) {
                  element["dist_name"] = el.name;
                }
              });
            });
            // console.log(this.distName);

            this.seriesPre[0] = res.data.preClassActive;
            // this.seriesPre[1] = res.data.primaryLeave;

            this.seriesPrimary[0] = res.data.primaryActive;
            this.seriesPrimary[1] = res.data.primaryLeave;
            // this.seriesPrimary[2] = res.data.primaryNew;
            // this.yPrimaryDiff = res.data.primaryNew - res.data.primaryLeave;
            // this.seriesPrimary[3] = this.yPrimaryDiff;

            this.seriesMiddle[0] = res.data.middleActive;
            this.seriesMiddle[1] = res.data.middleLeave;
            // this.seriesMiddle[2] = res.data.middleNew;
            // this.yMiddleDiff = res.data.middleNew - res.data.middleLeave;
            // this.seriesMiddle[3] = this.yMiddleDiff;

            this.seriesHigh[0] = res.data.highActive;
            this.seriesHigh[1] = res.data.highLeave;
            // this.seriesHigh[2] = res.data.highNew;
            // this.yHighDiff = res.data.highNew - res.data.highLeave;
            // this.seriesHigh[3] = this.yHighDiff;

            this.viewYearChart = true;

            this.seriesPrimaryLType = res.data.primaryLType;
            this.seriesMiddleLType = res.data.middleLType;
            this.seriesHighLType = res.data.hightLType;
            this.seriesAllLType = res.data.allLType;

            this.seriesPrimaryMonth[0].data = res.data.primaryMonthL;
            this.seriesPrimaryMonth[1].data = res.data.primaryMonthN;
            this.seriesPrimaryMonth[2].data = res.data.primaryMonthZ;

            this.seriesMiddleMonth[0].data = res.data.middleMonthL;
            this.seriesMiddleMonth[1].data = res.data.middleMonthN;
            this.seriesMiddleMonth[2].data = res.data.middleMonthZ;

            this.seriesHighMonth[0].data = res.data.highMonthL;
            this.seriesHighMonth[1].data = res.data.highMonthN;
            this.seriesHighMonth[2].data = res.data.highMonthZ;

            this.allClassNew = res.data.allClassNew;
            this.allClassLeave = res.data.allClassLeave;
            this.allClassActive = res.data.allClassActive;
            this.yAllClassDiff = res.data.allClassNew - res.data.allClassLeave;

            this.seriesAllClass[0] = res.data.primaryActive;
            this.seriesAllClass[2] = res.data.middleActive;
            this.seriesAllClass[1] = res.data.highActive;
            //this.seriesAllClassRad[0].data = res.data.allClassOfYearPercent;
            this.seriesAllClassRad[0].data.push(100).toFixed(1);
            this.seriesAllClassRad[0].data.push(
              ((res.data.allClassNew / res.data.allClassActive) * 100).toFixed(
                1
              )
            );
            this.seriesAllClassRad[0].data.push(
              (
                (res.data.allClassLeave / res.data.allClassActive) *
                100
              ).toFixed(1)
            );
            // console.log(this.seriesAllClassRad);
            this.optionsYearAllRad.xaxis.categories[0] =
              "Идэвхтэй: " + res.data.allClassActive;
            this.optionsYearAllRad.xaxis.categories[1] =
              "Тухайн жил ирсэн: " + res.data.allClassNew;
            this.optionsYearAllRad.xaxis.categories[2] =
              "Шилжсэн: " + res.data.allClassLeave;
            this.optionsLeaveTypeP.labels = res.data.typeNameP;
            this.optionsLeaveTypeM.labels = res.data.typeNameM;
            this.optionsLeaveTypeH.labels = res.data.typeNameH;
            this.optionsLeaveTypeAll.labels = res.data.typeNameAll;
            this.students = res.data.students;
            this.studentsPrimary = res.data.studentsPrimary;
            //Сурагчдын тоон үзүүлэлтүүд
            this.seriesGenderAllClass[0].data = res.data.allClassStdNull;
            this.seriesGenderAllClass[1].data = res.data.allClassStdEr;
            this.seriesGenderAllClass[2].data = res.data.allClassStdEm;
            this.seriesGenderAllClass[3].data = res.data.allClassStd;
            this.seriesAllClassLvl[0].data = res.data.allClassStd;
            this.seriesAllClassNum[0].data = res.data.allClassTotal;
            this.optionsAllClassLvl.xaxis.categories = res.data.allClassName;
            this.optionsGenderAllClass.xaxis.categories = res.data.allClassName;
            this.optionsAllClass.xaxis.categories = res.data.allClassNum;
            //Сурагчдийн хүйсийн харьцаа
            this.seriesGenderAllStud[0].data = res.data.genderTotal;
            this.optionsGenderAllStud.xaxis.categories = res.data.genderName;

            this.seriesGenderAllStudP = res.data.genderTotal;
            this.optionsGenderAllStudP.labels = res.data.genderName;
            // Бэлтгэл
            // 'genderTotalPre' => $genderTotalPre,
            // 'genderNamePre' => $genderNamePre,
            // this.seriesGenderStudPre = res.data.genderTotalPre;
            // this.optionsGenderStudPre = res.data.genderNamePre;
            this.seriesGenderStudPre[0].data = res.data.genderTotalPre;
            this.optionsGenderStudPre.xaxis.categories = res.data.genderNamePre;

            this.seriesGenderStudPre2 = res.data.genderTotalPre;
            this.optionsGenderStudPre2.labels = res.data.genderNamePre;

            // Бага
            this.seriesGenderPrimaryStudP = res.data.genderTotalP;
            this.optionsGenderPrimaryStudP.labels = res.data.genderNameP;

            this.seriesGenderMiddleStudP = res.data.genderTotalM;
            this.optionsGenderMiddleStudP.labels = res.data.genderNameM;
            this.seriesGenderHighStudP = res.data.genderTotalH;
            this.optionsGenderHighStudP.labels = res.data.genderNameH;
            //сурагчдын хаяг байршил
            this.seriesDistAllStud[0].data = res.data.distTotal;
            this.optionsDistAllStud.xaxis.categories = res.data.distName;
            this.seriesDistAllStudP = res.data.distTotal;
            this.optionsDistAllStudP.labels = res.data.distName;
            this.seriesDistPrimaryStud[0].data = res.data.distTotalP;
            this.optionsDistPrimaryStud.xaxis.categories = res.data.distNameP;
            this.seriesDistPrimaryStudP = res.data.distTotalP;
            this.optionsDistPrimaryStudP.labels = res.data.distNameP;
            this.seriesDistMiddleStud[0].data = res.data.distTotalM;
            this.optionsDistMiddleStud.xaxis.categories = res.data.distNameM;
            this.seriesDistMiddleStudP = res.data.distTotalM;
            this.optionsDistMiddleStudP.labels = res.data.distNameM;
            this.seriesDistHighStud[0].data = res.data.distTotalH;
            this.optionsDistHighStud.xaxis.categories = res.data.distNameH;
            this.seriesDistHighStudP = res.data.distTotalH;
            this.optionsDistHighStudP.labels = res.data.distNameH;
            //сурагчдын хаяг байршил анги тус бүрээр

            this.classListP = res.data.primaryClassList;
            this.classListM = res.data.middleClassList;
            this.classListH = res.data.highClassList;
          })
          .catch();
      }
    },
    classDistP_id: function (newVal, oldVal) {
      this.viewDistClassP = false;
      this.optionsDistClassSelPName.labels = [];
      this.seriesDistClassSelP = [];
      if (newVal != "") {
        axios
          .post("/manager/getDistClass", {
            year: this.school_year_id.id,
            classId: newVal.id,
          })
          .then((res) => {
            this.seriesDistClassSelP = res.data.distClassSel;
            this.optionsDistClassSelPName.labels = res.data.distNameSel;
            this.viewDistClassP = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    classDistM_id: function (newVal, oldVal) {
      this.viewDistClassM = false;
      this.optionsDistClassSelMName.labels = [];
      this.seriesDistClassSelM = [];
      if (newVal != "") {
        this.optionsDistClassSelMName.labels = [];
        this.seriesDistClassSelM = [];
        axios
          .post("/manager/getDistClassM", {
            year: this.school_year_id.id,
            classId: newVal.id,
          })
          .then((res) => {
            this.seriesDistClassSelM = res.data.distClassSel;
            this.optionsDistClassSelMName.labels = res.data.distNameSel;
            this.viewDistClassM = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    classDistH_id: function (newVal, oldVal) {
      this.viewDistClassH = false;
      this.optionsDistClassSelHName.labels = [];
      this.seriesDistClassSelH = [];
      if (newVal != "") {
        axios
          .post("/manager/getDistClassH", {
            year: this.school_year_id.id,
            classId: newVal.id,
          })
          .then((res) => {
            this.seriesDistClassSelH = res.data.distClassSel;
            this.optionsDistClassSelHName.labels = res.data.distNameSel;
            this.viewDistClassH = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    classP_id: function (newVal, oldVal) {
      if (newVal != "") {
        axios
          .post("/manager/getEachStdData", {
            year: this.school_year_id,
            classId: newVal.id,
          })
          .then((res) => {
            this.classLeavePlist = res.data.classLeave;
            this.classNewPlist = res.data.classNew;
            this.viewClassStdP = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    classM_id: function (newVal, oldVal) {
      if (newVal != "") {
        axios
          .post("/manager/getEachStdData", {
            year: this.school_year_id,
            classId: newVal.id,
          })
          .then((res) => {
            this.classLeaveMlist = res.data.classLeave;
            this.classNewMlist = res.data.classNew;
            this.viewClassStdM = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    classH_id: function (newVal, oldVal) {
      if (newVal != "") {
        axios
          .post("/manager/getEachStdData", {
            year: this.school_year_id,
            classId: newVal.id,
          })
          .then((res) => {
            this.classLeaveHlist = res.data.classLeave;
            this.classNewHlist = res.data.classNew;
            this.viewClassStdH = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  methods: {
    getSum() {
      axios
        .get("/allDistrict/" + 11)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getEachClass() {
      axios
        .post("/manager/getEachClass", {
          year: this.school_year_id,
        })
        .then((res) => {
          this.seriesPrimaryClass[0].data = res.data.primaryClassL;
          this.seriesPrimaryClass[1].data = res.data.primaryClassN;
          this.seriesPrimaryClass[2].data = res.data.primaryClassZ;
          this.optionsPrimaryClass.xaxis.categories = res.data.primaryClassCats;

          this.seriesMiddleClass[0].data = res.data.middleClassL;
          this.seriesMiddleClass[1].data = res.data.middleClassN;
          this.seriesMiddleClass[2].data = res.data.middleClassZ;
          this.optionsMiddleClass.xaxis.categories = res.data.middleClassCats;

          this.seriesHighClass[0].data = res.data.highClassL;
          this.seriesHighClass[1].data = res.data.highClassN;
          this.seriesHighClass[2].data = res.data.highClassZ;
          this.optionsHighClass.xaxis.categories = res.data.highClassCats;
          this.isClassView = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  beforeMount() {},

  created() {
    this.getSum();
  },
};
</script>
