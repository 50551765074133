<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div
            class="main-header navbar navbar-expand navbar-white navbar-light"
          >
            <h3 class="card-title">
              Нийт суралцагчид: {{ schoolstudents.length }}.
            </h3>
          </div>
          <b-row class="mt-3">
            <b-col sm="7"
              ><multiselect
                v-model="fieldName"
                :options="fieldList"
                @select="setFieldList"
                :multiple="true"
                track-by="value"
                label="label"
                :searchable="true"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Excel файл руу гаргах баганаа сонгоно уу."
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="5">
              <b-button
                size="sm"
                @click="resetFields"
                class="mr-2"
                pill
                variant="outline-primary"
              >
                <b-icon icon="check-all" aria-hidden="true"></b-icon> Бүх талбар
              </b-button>

              <download-excel
                class="btn btn-success btn-sm"
                :data="json_data"
                :fields="json_fields"
                worksheet="Суралцагч"
                name="Суралцагчид.xls"
              >
                <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                Файл татах
              </download-excel>
            </b-col>
          </b-row>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="school_classes"
                    text-field="full_name"
                    value-field="id"
                    size="sm"
                  >
                  </b-form-select>
                </div>
                <div class="col-sm-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllStudents"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-12">
                  <b-button
                    size="sm"
                    @click="checkStudents"
                    class="mr-2"
                    pill
                    variant="outline-primary"
                  >
                    <b-icon icon="check-all" aria-hidden="true"></b-icon> Төлбөр
                    бүртгэх
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <table
            class="table table-bordered table-hover table-striped dataTable dtr-inline"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Хүйс</th>
                <th>Жил</th>
                <th>Имэйл</th>
                <th>Анги</th>
                <th>Элсэлтийн мэдээлэл</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                :class="{ 'table-warning': isAll }"
                v-for="(schoolstudent, index) in schoolstudents"
                :key="index"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- Using modifiers -->
                  <b-badge variant="warning">
                    {{ schoolstudent.student_last_name }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="primary">
                    {{ schoolstudent.student_name }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.student_gender }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <p style="width: 5rem">
                    {{ schoolstudent.start_date.substring(0, 4) }} -
                    {{ schoolstudent.finish_date.substring(0, 4) }}
                  </p>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.full_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    class="badge badge-primary text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-if="
                      schoolstudent.contact_info !== null ||
                      schoolstudent.study_class_sanal_desc !== null
                    "
                  >
                    Холбоо барих: {{ schoolstudent.contact_info }} <br />
                    Элсэлтийн санал:
                    {{ schoolstudent.study_class_sanal_desc }} <br />
                    Регистер: {{ schoolstudent.profile_student_reg }}
                  </span>
                  <span
                    class="badge badge-info text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-else
                  >
                    Мэдээлэл бүртгэгдээгүй
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эрэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">
                  Хүйс(Эмэгтэй)
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAFeMale() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <!-- <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>-->
                <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countA() }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      size="xl"
      id="modal-student-info"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Ангийн төлбөрийн мэдээллийг суралцагч бүрт шинэчлэх"
      class="modal fade"
      hide-footer
      @hidden="cancelUpdate"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <b-badge variant="warning">
              {{ formEdit.title }}
            </b-badge>
            <b-badge
              variant="info"
              v-for="(student, index) in formEdit.students"
              :key="index"
            >
              {{ student.student_last_name[0] }}. {{ student.name }}
            </b-badge>
            <div class="form-group">
              <label>Төлбөр сонгох</label>
              <multiselect
                v-model="formEdit.payment_value"
                deselect-label="Хасах"
                :options="payments"
                :searchable="true"
                :close-on-select="true"
                track-by="payment_value"
                label="payment_value"
                :show-labels="true"
                placeholder="Тухайн ангид тохирох төлбөрийг сонгоно уу?"
                :allow-empty="false"
                select-label="Сонгох"
                :custom-label="nameValueOfPayment"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong> {{ option.payment_name }}</strong>
                  <strong> {{ option.payment_value }}</strong>
                  <strong> сонгогдлоо</strong>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpdate()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          @click="updateStudentsPayment()"
          type="button"
          class="btn btn-primary"
        >
          Шинэчлэх
        </button>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      getMyInfoStudent: [],

      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      selected: 5,
      action: "",
      schoolstudents: [],
      payments: [],
      school_classes: [],
      // schoolYears: [],
      // leave_types: [],
      formEdit: new Form({
        title: "",
        students: "",
        payment_value: "",
      }),
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldList: [],
      fieldName: "",
      firstMode: true,
      isAll: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {},
  methods: {
    setFieldList: function (e) {
      if (this.firstMode == true) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = "";
      this.fieldList.map(function (value, key) {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
    nameValueOfPayment({ payment_name, payment_value }) {
      return `${payment_name} — [${payment_value}]`;
    },
    getAllStudents() {
      axios
        .get("/manager/getAllStudents/" + this.selected)
        .then((response) => {
          this.schoolstudents = response.data.schoolstudents;
          this.school_classes = response.data.school_classes;
          // this.schoolYears = response.data.schoolYears;
          // this.leave_types = response.data.school_leave_types;
          // this.role = response.data.role;
          this.json_data = response.data.schoolstudents;
          this.fieldList = response.data.fields;
          var jsonF = {};
          this.fieldList.map(function (value, key) {
            var obj = {};
            var myLabel = value.label;
            obj = { [myLabel]: value.value };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    getAllPaymentsInfo() {
      axios
        .get("/finance/getAllPaymentsInfo")
        .then((response) => {
          this.payments = response.data.payments;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    checkStudents() {
      this.$bvModal.show("modal-student-info");
      this.formEdit.students = this.schoolstudents;
      let filterEl = this.school_classes.filter(
        (el) => el.id === this.selected
      );
      // console.log(filterEl);
      this.formEdit.title = filterEl[0]["full_name"];
    },

    updateStudentsPayment() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.formEdit
        .put("/finance/updateStudentsPayment")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(
            "Сурагчийн мэдээллийг амжилттай заслаа!",
            "Шинэчилсэн"
          );
          this.cancelUpdate();
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelUpdate() {
      this.formEdit.reset();
      this.$bvModal.hide("modal-student-info");
    },

    // Тооцоолол
    //A
    countAMale() {
      let countMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эрэгтэй"
      ).length;
      return countMale;
    },
    countAFeMale() {
      let countFeMale = this.schoolstudents.filter(
        (obj) => obj.student_gender === "Эмэгтэй"
      ).length;
      return countFeMale;
    },
    countA() {
      let countA = this.countAMale() + this.countAFeMale();
      return countA;
    },
    //Суралцагчын төлбөрийн мэдээлэл засах
  },
  created() {
    this.getAllStudents();
    this.getAllPaymentsInfo();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllStudents();
    });
  },
};
</script>

<style></style>
