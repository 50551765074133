<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn-info"
      data-toggle="modal"
      data-target="#modal-xl-create"
      data-keyboard="false"
      data-dismiss="modal"
    >
      Багшийн ээлжит үйл ажиллагааны ажиглалт, оношилгоо хийх
    </button>
    <div
      class="modal fade"
      id="modal-xl-create"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Багшийн ээлжит үйл ажиллагааны ажиглалт, оношилгооны хуудас
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form
            @submit.prevent="
              !teaEvaEditMode
                ? createLessonEvaluation()
                : updateLessonEvaluation()
            "
          >
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Улирал</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.quarter_id"
                        :options="mySchoolQuarters"
                        value-field="quarter_id"
                        text-field="quarter_name"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Багш нарын баг</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.department_id"
                        @change="getTeachers"
                        :options="schoolDepartments"
                        value-field="id"
                        text-field="department_code"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Багш</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.teacher_id"
                        :options="schoolTeachers"
                        text-field="fullName"
                        value-field="emp_user_id"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Анги</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.school_class_id"
                        :options="schoolClasses"
                        value-field="id"
                        text-field="full_name"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <!-- /input-group -->
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-lg-6">
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Хичээлийн нэр</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.subject_id"
                        :options="schoolSubjects"
                        value-field="id"
                        text-field="subject_name"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Хичээлийн цаг</label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        v-model="form.lesson_time"
                        :options="lessonTimeNumbers"
                        size="sm"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Хичээлийн сэдэв</label
                    >
                    <div class="col-sm-6">
                      <b-form-input
                        v-model="form.lesson_topic"
                        size="sm"
                        placeholder="Тухайн цаг дээр орсон хчичээлийн сэдэв..."
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-6 col-form-label"
                      >Огноо</label
                    >
                    <div class="col-sm-6">
                      <b-form-datepicker
                        v-model="form.lesson_ognoo"
                        id="datepicker-sm"
                        size="sm"
                        locale="mn"
                        placeholder="Огноо сонгох"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <!-- /input-group -->
                </div>
                <!-- /.col-lg-6 -->
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body p-0">
                      <table class="table table-hover table-sm">
                        <thead>
                          <tr>
                            <th style="width: 10%">№</th>
                            <th style="width: 30%">Шалгуур үзүүлэлт(MN/ENG)</th>
                            <th style="width: 10%">Оноо</th>
                            <th style="width: 5%">%</th>
                            <th>Эерэг тэмдэглэл</th>
                            <th>Сайжруулах тэмдэглэл</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1.</td>
                            <td>
                              Зорилготойгоо холбоотой эрэлт хэрэгцээ, сэдэл,
                              сэтгэл хөдлөлийг хянах, чиглүүлэх байдлаар
                              зорилгоо хүлээн зөвшөөрүүлсэн эсэх <br />
                              <b>
                                Creates a supportive atmosphere for learning
                                where the learners embrace the objectives by
                                managing and guiding their needs, feelings, and
                                motivations related to the objective
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles1"
                                    placeholder="0-3 оноо"
                                    @change="lessonBegun1Percent"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles1change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles1change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles1change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <!-- <ckeditor
                                :editor="editor"
                                v-model="form.shalles1_pos_note"
                                :config="editorConfig"
                              ></ckeditor> -->
                              <b-form-textarea
                                id="shalles1pos"
                                v-model="form.shalles1_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles1neg"
                                v-model="form.shalles1_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>
                              Зорилгоо томъёолсон байдал <br />
                              <b> Lesson objectives are clearly defined </b>
                            </td>
                            <td>
                              <validation-provider name="" rules="between:0,2">
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles2"
                                    placeholder="0-2 оноо"
                                    @change="lessonBegun2Percent"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles2change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles2change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles2change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles2pos"
                                v-model="form.shalles2_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles2neg"
                                v-model="form.shalles2_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>
                              Зорилгоо ойлгосон эсэхийг хянах, үйл ажиллагааг нь
                              төлөвлүүлсэн эсэх <br />
                              <b>
                                Establishes an effective routine to determine
                                whether learners have understood the lesson
                                objectives and to help them plan their actions
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles3"
                                    @change="lessonBegun3Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles3change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles3change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles3change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles3pos"
                                v-model="form.shalles3_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles3neg"
                                v-model="form.shalles3_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>
                              Судлагдахууны агуулгыг элементарчилж, эмх цэгцтэй
                              хүргэсэн эсэх <br />
                              <b>
                                The content of the lesson is straightforward,
                                uncomplicated and well-delivered
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:4|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles4"
                                    @change="lessonBegun4Percent"
                                    placeholder="0-4 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles4change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles4change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles4change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles4pos"
                                v-model="form.shalles4_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles4neg"
                                v-model="form.shalles4_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>
                              Даалгаврын тавил суралцагчдад давамгайлж буй
                              мэдээллийг хүлээн авах хэв шинжид нийцсэн эсэх
                              <br />
                              <b>
                                Tasks and assignments are designed in the
                                learners’ preferred way of information intake
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles5"
                                    @change="lessonBegun5Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles5change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles5change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles5change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles5pos"
                                v-model="form.shalles5_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles5neg"
                                v-model="form.shalles5_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>6.</td>
                            <td>
                              Үйл ажиллагааны хэлбэр суралцагчдад давамгайлж буй
                              сурахуйн хэв шинжид нийцсэн эсэх <br />
                              <b>
                                Activities meet learners’ preferred learning
                                styles
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles6"
                                    @change="lessonBegun6Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles6change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles6change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles6change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles6pos"
                                v-model="form.shalles6_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles6neg"
                                v-model="form.shalles6_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>7.</td>
                            <td>
                              Суралцагчдын гүйцэтгэл дээр урам дэм өгч, магтаал
                              сайшаал хэлсэн эсэх <br />
                              <b>
                                The teacher recognizes learners’ demonstrations
                                by encouraging, praising, and supporting
                                learning
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles7"
                                    @change="lessonBegun7Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles7change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles7change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles7change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles7pos"
                                v-model="form.shalles7_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles7neg"
                                v-model="form.shalles7_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>8.</td>
                            <td>
                              Суралцагчдын оюуны үйлийн когнитив системийг бүрэн
                              ажиллуулсан эсэх <br />
                              <b>
                                Activities fully ensure cognitive learning
                                processes in learners (use of Bloom’s Taxonomy
                                questions)
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles8"
                                    @change="lessonBegun8Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles8change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles8change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles8change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles8pos"
                                v-model="form.shalles8_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles8neg"
                                v-model="form.shalles8_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>9.</td>
                            <td>
                              Суралцагчдын гүйцэтгэлд хяналт тавьж гүйцэтгэлийг
                              нэгтгэн дүгнэсэн эсэх <br />
                              <b>
                                Assesses learners’ work and summarizes the
                                lesson with key points learned today
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles9"
                                    @change="lessonBegun9Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles9change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles9change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles9change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles9pos"
                                v-model="form.shalles9_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles9neg"
                                v-model="form.shalles9_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>10.</td>
                            <td>
                              Эзэмшсэн мэдлэг чадварынх нь ач холбогдол, үнэ
                              цэнийг үнэлүүлж хүлээн зөвшөөрүүлсэн эсэх <br />
                              <b>
                                The importance, value, and usefulness of the
                                acquired knowledge and skills have been
                                acknowledged
                              </b>
                            </td>
                            <td>
                              <validation-provider
                                name=""
                                rules="max_value:3|min_value:0"
                              >
                                <template #default="{ errors }">
                                  <b-form-input
                                    type="number"
                                    v-model="form.shalles10"
                                    @change="lessonBegun10Percent"
                                    placeholder="0-3 оноо"
                                    step="any"
                                  ></b-form-input>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </template>
                              </validation-provider>
                            </td>
                            <td>
                              <span
                                v-if="shalles10change"
                                class="badge bg-danger"
                                >{{
                                  parseFloat(shalles10change).toFixed(0)
                                }}%</span
                              >
                              <span
                                v-if="!shalles10change"
                                class="badge bg-danger"
                                >0%</span
                              >
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles10pos"
                                v-model="form.shalles10_pos_note"
                                placeholder="Суралцахуйд эерэг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                            <td>
                              <b-form-textarea
                                id="shalles10neg"
                                v-model="form.shalles10_neg_note"
                                placeholder="Суралцахуйд сөрөг нөлөөлсөн багшийн үйл ажиллагаа"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                v-show="!teaEvaEditMode"
                @click.prevent="cancelCreate"
              >
                Цуцлах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!teaEvaEditMode"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="teaEvaEditMode"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="teaEvaEditMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "teaEvaEditMode",
    "form",
    "schoolDepartments",
    "schoolClasses",
    "schoolTeachers",
    "schoolSubjects",
    "mySchoolQuarters",
  ],
  data() {
    return {
      shalles1change: "",
      shalles2change: "",
      shalles3change: "",
      shalles4change: "",
      shalles5change: "",
      shalles6change: "",
      shalles7change: "",
      shalles8change: "",
      shalles9change: "",
      shalles10change: "",
      lessonTimeNumbers: [
        { value: null, text: "Хичээлийн цаг сонгоно уу?" },
        { value: "1", text: "1-р цаг" },
        { value: "2", text: "2-р цаг" },
        { value: "3", text: "3-р цаг" },
        { value: "4", text: "4-р цаг" },
        { value: "5", text: "5-р цаг" },
        { value: "6", text: "6-р цаг" },
        { value: "7", text: "7-р цаг" },
        { value: "8", text: "8-р цаг" },
        { value: "9", text: "9-р цаг" },
        { value: "10", text: "10-р цаг" },
      ],
    };
  },
  methods: {
    getTeachers() {
      axios
        .get("/teacher/getTeacherList/" + this.form.department_id)
        .then((response) => {
          this.schoolTeachers = response.data.teachers;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    lessonBegun1Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles1change = (this.form.shalles1 / 3) * 100);
    },
    lessonBegun2Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles2change = (this.form.shalles2 / 2) * 100);
    },
    lessonBegun3Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles3change = (this.form.shalles3 / 3) * 100);
    },
    lessonBegun4Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles4change = (this.form.shalles4 / 4) * 100);
    },
    lessonBegun5Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles5change = (this.form.shalles5 / 3) * 100);
    },
    lessonBegun6Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles6change = (this.form.shalles6 / 3) * 100);
    },
    lessonBegun7Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles7change = (this.form.shalles7 / 3) * 100);
    },
    lessonBegun8Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles8change = (this.form.shalles8 / 3) * 100);
    },
    lessonBegun9Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles9change = (this.form.shalles9 / 3) * 100);
    },
    lessonBegun10Percent: function () {
      // console.log("Өөрчлөлтийг мэдэрлээ.");
      return (this.shalles10change = (this.form.shalles10 / 3) * 100);
    },
    // editMyLessonEvaluation(department) {
    //     //   console.log("Дэлгэрэнгүй мэдээлэл засах" + department);
    //     this.teaEvaEditMode = true;
    //     this.form.reset();
    //     this.form.fill(department);
    // },

    updateLessonEvaluation() {
      this.form
        .put("/teacher/teacherEvaForTeacherUpdate/" + this.form.id)
        .then((response) => {
          this.$toastr.s(
            "Ээлжит хичээлийн үнэлгээг амжилттай шинэчиллээ.",
            "Шинэчилсэн"
          );
          this.teaEvaEditMode = !this.teaEvaEditMode;
          Fire.$emit("getLoadTeacherEvaluations");
          this.form.reset();
          this.$emit("getWasEditMode", this.teaEvaEditMode);
          $("#modal-xl-create").modal("hide");
        })
        .catch();
    },
    cancelEdit() {
      //   this.form.reset();
      this.teaEvaEditMode = false;
      this.$emit("getWasEditMode", this.teaEvaEditMode);
      this.form.reset();
      $("#modal-xl-create").modal("hide");
    },
    cancelCreate() {
      //   this.form.reset();
      this.teaEvaEditMode = false;
      this.$emit("getWasEditMode", this.teaEvaEditMode);
      this.form.reset();
      $("#modal-xl-create").modal("hide");
    },
    createLessonEvaluation() {
      //   console.log("Амжилттай хадгалсан.");
      this.form
        .post("/teacher/teacherEvaForTeacherCreate")
        .then((response) => {
          this.$toastr.s(
            "Багшийн ээлжит хичээлийн үнэлгээг амжилттай үүсгэлээ.",
            "Амжилттай"
          );
          //   Fire.$emit("getLoadTeacherEvaluations");
          this.$emit("getWasEditMode", this.teaEvaEditMode);
          //   this.$emit("getLoadTeacherEvaluations");
          this.form.reset();
          $("#modal-xl-create").modal("hide");
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    modalCloseWhite(teaEvaEditMode) {
      this.$emit("getWasEditMode", teaEvaEditMode);
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
  },
  mounted() {},
  created() {
    // console.log(this.teaEvaEditMode);
    Fire.$on("loadTeacherLessonEvaluation", () => {
      this.getAllSchoolSubjects();
    });
  },
};
</script>

<style></style>
