<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5><b>Суралцагчийн тэмдэглэл </b></h5>
                                <b-overlay :show="isLoad" rounded="sm">
                                    <table class="table table-bordered text-center">
                                        <thead>
                                            <tr>
                                                <th rowspan="2">Анги</th>
                                                <th v-for="quarter in schoolquarters" :key="quarter.id">
                                                    {{ quarter.name }} -р улирал
                                                </th>
                                            </tr>
                                            <tr>
                                                <th v-for="quarter in schoolquarters" :key="quarter.id">
                                                    <div role="table" class="d-flex justify-content-between">
                                                        <div class="myTableData"> Нийт</div>
                                                        <div class="myTableData"> Уншсан</div>
                                                        <div class="myTableData"> Уншаагүй</div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="mySubj in myClassComment" :key="mySubj.id">
                                            <tr class="dtr-control sorting_1" tabindex="0">
                                                <td>{{ mySubj.my_school_class.full_name }} анги {{
                                                    mySubj.my_subject.subject_name }}</td>
                                                <td v-for="quarter in mySubj.quarters" :key="quarter.id">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="myTableData"> {{ quarter.count_all }}</div>
                                                        <div class="myTableData"> {{ quarter.count_read }}</div>
                                                        <div class="myTableData"> {{ quarter.count_not_read }}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            myClassComment: [],
            schoolquarters: [],
            isLoad: true,
        };
    },
    methods: {
        courseComment() {
            this.isLoad = true;
            axios
                .post("/teacher/courseComment", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.schoolquarters = res.data.schoolquarters;
                    this.myClassComment = res.data.myClassComment;
                    this.isLoad = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.courseComment();
    },
};
</script>
<style scoped>
.myTableData {
    width: 100%;
    border: solid;
    border-width: 0 1px 0 0;
    border-color: #dee2e6;
}

.myTableData:nth-child(3) {
    border: none;
}
</style>
