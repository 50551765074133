<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Сарын төлөвлөгөө</h3>
          <!-- <search-student
            ></search-student> -->
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3">
                  <multiselect
                    v-model="yearId"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="customYearName"
                    placeholder="Хичээлийн жил сонгоно уу?"
                    :options="years"
                    :searchable="true"
                    :allow-empty="true"
                    @input="getMyMonthPlans"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.start_date | dateYear }}-{{
                          option.finish_date | dateYear
                        }}
                        - хичээлийн жил
                      </strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="3">
                  <multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    variant="success"
                    @click.prevent="getMyMonthPlans()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="show" rounded="sm">
            <table class="table projects">
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Үйлдэл</th>
                  <th>Гүйцэтгэл</th>
                  <th>Нэр</th>
                  <th>Хариуцах эзэн</th>
                  <th>Хамтран ажиллах</th>
                  <th>Огноо</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-warning"
                  v-for="(monthPlan, index) in allMonthMyPlans"
                  :key="monthPlan.monthPlanId"
                >
                  <td tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td tabindex="0">
                    <a
                      href="#"
                      class="badge bg-primary"
                      @click="editMonthPlan(monthPlan)"
                      ><i class="fa fa-check"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-success"
                      @click="showMonthPlan(monthPlan)"
                      ><i class="fa fa-eye"></i
                    ></a>
                  </td>
                  <td style="text-align: center" tabindex="0">
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="monthPlan.point < 30"
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 80"
                        class="progress-bar bg-success"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                    </div>
                    <small>
                      <b-badge
                        v-if="monthPlan.point >= 80"
                        variant="success"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                        variant="primary"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                        variant="warning"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point < 30"
                        variant="danger"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-show="monthPlan.point === null"
                      >
                        ОРООГҮЙ</b-badge
                      >
                    </small>
                  </td>
                  <td tabindex="0">
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      monthPlan.name
                    }}</b-badge>
                  </td>
                  <td
                    tabindex="0"
                    v-if="monthPlan.owner != null"
                    class="center-badge"
                  >
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <img
                          class="table-avatar"
                          :src="
                            monthPlan.owner.profile_photo_url
                              ? monthPlan.owner.profile_photo_url
                              : '/images/users/user.png'
                          "
                        />
                      </li>
                    </ul>
                    <b-badge variant="light" style="centered-text">
                      {{
                        monthPlan.owner.teacher_last_name
                          | capitalizeAndEkhniiVseg
                      }}.{{ monthPlan.owner.teacher_name }}
                    </b-badge>
                  </td>
                  <td tabindex="0">
                    <ul class="list-inline">
                      <li
                        class="list-inline-item"
                        v-for="teacher in monthPlan.month_plan_teachers"
                        :key="teacher.id"
                      >
                        <img
                          v-if="teacher.employee != null"
                          @click="showImage(teacher)"
                          alt="Avatar"
                          v-b-tooltip.hover.top.html="
                            '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                            teacher.department.department_name +
                            '</dd><dt>Ажлын байр:</dt><dd>' +
                            teacher.position.position_name +
                            '</dd><dt>Овог Нэр</dt><dd>' +
                            teacher.employee.teacher_last_name +
                            ' <span class=text-uppercase>' +
                            teacher.employee.teacher_name +
                            '</span></dd></dl>'
                          "
                          class="table-avatar"
                          :src="
                            teacher.employee.profile_photo_url
                              ? teacher.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                        />
                      </li>
                    </ul>
                  </td>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    {{ monthPlan.start_date | dateYearMonthDay }}
                    <br />
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    <br />
                    {{ monthPlan.end_date | dateYearMonthDay }}
                  </td>
                </tr>
                <tr
                  v-for="(monthPlan, index) in allMonthPlans"
                  :key="monthPlan.monthPlanId"
                >
                  <td tabindex="0">
                    {{ allMonthMyPlans.length + index + 1 }}
                  </td>
                  <td tabindex="0">
                    <a
                      href="#"
                      class="badge bg-success"
                      @click="showMonthPlan(monthPlan)"
                      ><i class="fa fa-eye"></i
                    ></a>
                  </td>
                  <td style="text-align: center" tabindex="0">
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="monthPlan.point < 30"
                        class="progress-bar bg-danger"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                        class="progress-bar bg-warning"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                        class="progress-bar bg-primary"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                      <div
                        v-if="monthPlan.point >= 80"
                        class="progress-bar bg-success"
                        :style="{
                          width: `${monthPlan.point}%`,
                        }"
                      ></div>
                    </div>
                    <small>
                      <b-badge
                        v-if="monthPlan.point >= 80"
                        variant="success"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                        variant="primary"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                        variant="warning"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        v-if="monthPlan.point < 30"
                        variant="danger"
                        v-show="monthPlan.point != null"
                      >
                        {{ monthPlan.point }}</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-show="monthPlan.point === null"
                      >
                        ОРООГҮЙ</b-badge
                      >
                    </small>
                  </td>
                  <td tabindex="0">
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      monthPlan.name
                    }}</b-badge>
                  </td>
                  <td
                    tabindex="0"
                    v-if="monthPlan.owner != null"
                    class="center-badge"
                  >
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <img
                          class="table-avatar"
                          :src="
                            monthPlan.owner.profile_photo_url
                              ? monthPlan.owner.profile_photo_url
                              : '/images/users/user.png'
                          "
                        />
                      </li>
                    </ul>
                    <b-badge variant="light" style="centered-text">
                      {{
                        monthPlan.owner.teacher_last_name
                          | capitalizeAndEkhniiVseg
                      }}.{{ monthPlan.owner.teacher_name }}
                    </b-badge>
                  </td>
                  <td tabindex="0">
                    <ul class="list-inline">
                      <li
                        class="list-inline-item"
                        v-for="teacher in monthPlan.month_plan_teachers"
                        :key="teacher.id"
                      >
                        <img
                          @click="showImage(teacher)"
                          alt="Avatar"
                          v-if="teacher.employee != null"
                          v-b-tooltip.hover.top.html="
                            '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                            teacher.department.department_name +
                            '</dd><dt>Ажлын байр:</dt><dd>' +
                            teacher.position.position_name +
                            '</dd><dt>Овог Нэр</dt><dd>' +
                            teacher.employee.teacher_last_name +
                            ' <span class=text-uppercase>' +
                            teacher.employee.teacher_name +
                            '</span></dd></dl>'
                          "
                          class="table-avatar"
                          :src="
                            teacher.employee.profile_photo_url
                              ? teacher.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                        />
                      </li>
                    </ul>
                  </td>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    {{ monthPlan.start_date | dateYearMonthDay }}
                    <br />
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    <br />
                    {{ monthPlan.end_date | dateYearMonthDay }}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colspan="3" class="text-right">
                    <small>Дундаж /0 тооцсон/</small>
                  </td>
                  <td colspan="3">
                    <strong>{{ unelgeeAvg }}%</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-right">
                    <small>Дундаж /0 тооцохгүй/</small>
                  </td>
                  <td colspan="3">
                    <strong>{{ unelgee0Avg }}%</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-right">
                    <small>Нийт:</small>
                  </td>
                  <td colspan="3">
                    <strong>{{ niit }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Сарын төлөвлөгөө бүртгэх
          </h3>
          <h3 class="card-title" v-show="editMode">Сарын төлөвлөгөө засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <form>
            <div class="form-group row">
              <b-form-checkbox
                class="float-right success"
                v-model="isConfirmType"
                aria-describedby="active"
                switch
                size="xs"
                ><span v-if="isConfirmType == true" class="badge bg-secondary"
                  >Хамтарсан сарын төлөвлөгөөг цуцлах
                </span>
                <span v-else class="badge bg-primary"
                  >Хамтарсан сарын төлөвлөгөө оруулах</span
                >
              </b-form-checkbox>
            </div>
            <div class="form-group" v-show="isConfirmType">
              <label>Харьяалагдах мэргэжлийн баг</label>
              <b-form-select
                v-on:change="changeTeacherList()"
                v-model="filterForm.department_id"
                :options="departments"
                text-field="department_name"
                value-field="id"
              ></b-form-select>
              <has-error :form="filterForm" field="department_name"></has-error>
              <label>Багш, ажилтан</label>
              <b-form-select
                v-if="teachers.length != 0"
                v-model="form.recieved_employees"
                :options="teachers"
                text-field="emp_teacher_name"
                value-field="emp_id"
                multiple
                :select-size="4"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('recieved_employees'),
                  },
                ]"
              ></b-form-select>
              <has-error :form="form" field="recieved_employees"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="name"
                max="100"
                v-model="form.name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <label>Агуулга</label>
              <quill-editor
                v-model="form.description"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Эхлэх огноо</label>
                <b-form-datepicker
                  id="start-datepicker"
                  :state="true"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="form.start_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('start_date'),
                    },
                  ]"
                  placeholder="Эхлэх огноо"
                ></b-form-datepicker>
                <has-error :form="form" field="start_date"></has-error>
              </div>
              <div class="form-group col-lg-6">
                <label>Дуусах огноо</label>
                <b-form-datepicker
                  id="end-datepicker"
                  :state="false"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="form.end_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('end_date'),
                    },
                  ]"
                  placeholder="Дуусах огноо"
                ></b-form-datepicker>
                <has-error :form="form" field="end_date"></has-error>
              </div>
            </div>
            <div class="form-group">
              <label>Анализ</label>
              <quill-editor
                v-model="form.analysis"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
            </div>
            <div class="form-group">
              <label>Хяналтын үзүүлэлт</label>
              <quill-editor
                v-model="form.indicator"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
            </div>
            <div class="form-group" v-show="editMode">
              <label>Үнэлгээ</label>
              <input
                type="number"
                name="point"
                max="100"
                v-model="form.point"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('point'),
                  },
                ]"
                placeholder="Үнэлгээ %-аар тавих"
              />
              <has-error :form="form" field="point"></has-error>
            </div>
            <div class="form-group" v-show="editMode">
              <label>Үнэлгээний тайлбар</label>
              <quill-editor
                v-model="form.point_explain"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
            </div>
          </form>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editMode"
            @click.prevent="createMonthPlan()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editMode"
            @click.prevent="updateMonthPlan()"
          >
            Засах
          </button>
          <button
            class="btn btn-secondary"
            v-show="editMode"
            @click.prevent="cancelEdit"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-month-plan"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showMonthPlanInfo.name"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span
              class="direct-chat-name float-left"
              v-if="showMonthPlanInfo.owner != null"
            >
              {{ showMonthPlanInfo.owner.teacher_last_name }}
              {{ showMonthPlanInfo.owner.teacher_name | uppercase }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ showMonthPlanInfo.start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ showMonthPlanInfo.end_date | dateYearMonthDay }}
            </span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            :src="
              showMonthPlanInfo.owner.profile_photo_url
                ? showMonthPlanInfo.owner.profile_photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <div class="row">
            <div class="direct-chat-text col-lg-11">
              <b>АГУУЛГА:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.description"
                :options="editorOptionView"
              >
              </quill-editor>
              <b>АНАЛИЗ:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.analysis"
                :options="editorOptionView"
              >
              </quill-editor>
              <b>ХЯНАЛТЫН ҮЗҮҮЛЭЛТ:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.indicator"
                :options="editorOptionView"
              >
              </quill-editor>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div
              class="callout callout-success"
              v-if="showMonthPlanInfo.point > 0"
            >
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>
                  <span v-html="showMonthPlanInfo.point_explain"> </span>
                </small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Хамтран ажиллах ажилчид</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ showMonthPlanInfo.point }}%
                </span>
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="monthPlanEmps != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Зураг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(emp, index) in monthPlanEmps"
                    :key="emp.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.department.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.position.position_name }}
                      </td>
                      <td>
                        <img
                          :src="
                            emp.employee.profile_photo_url
                              ? emp.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                        v-if="emp.employee != null"
                      >
                        {{
                          emp.employee.teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}.
                        {{ emp.employee.teacher_name | uppercase }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(emp)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacherMonthPlan()"
                >
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelMonthlyPlan()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <b-modal
      size="lg"
      id="modal-show-employee"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-2">
            <img
              style="width: 100%"
              v-if="showEmployee.employee.profile_photo_url != null"
              :src="showEmployee.employee.profile_photo_url"
            />
          </div>
          <div class="col-lg-4 mt-2">
            <dl class="text-left">
              <dt>Иргэншил:</dt>
              <dd>{{ showEmployee.employee.citizenship }}</dd>
              <dt>Мэргэжлийн баг:</dt>
              <dd>
                {{ showEmployee.department.department_name }}
              </dd>
              <dt>Ажлын байр:</dt>
              <dd>{{ showEmployee.position.position_name }}</dd>
              <dt>Овог Нэр:</dt>
              <dd v-if="showEmployee.employee != null">
                {{ showEmployee.employee.teacher_last_name }}
                <span class="text-uppercase">
                  {{ showEmployee.employee.teacher_name }}
                </span>
              </dd>
              <dt>Системд бүртгэгдсэн огноо:</dt>
              <dd>
                {{ showEmployee.employee.created_at | dateYearMonthDay }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      yearId: null,
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",
      editMode: false,

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",
      isConfirmType: false,

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allMonthPlans: [],
      allMonthMyPlans: [],
      monthPlanEmps: [],
      teachers: [],
      showMode: false,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      modalTeachers: [],
      filterForm: new Form({
        department_id: 0,
      }),
      departments: [],
      selectedMonths: null,
      showMonthPlanInfo: new Form({
        monthPlanId: "",
        owner_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
        owner: [],
      }),
      form: new Form({
        monthPlanId: "",
        owner_id: "",
        leader_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
      }),
      myEmpId: "",
      showEmployee: new Form({
        department: "",
        employee: "",
        position: "",
      }),
      formAddTeacher: new Form({
        monthPlanId: null,
        department_id: 0,
        recieved_employees: [],
      }),
      // зөвхөн харах
      editorOptionView: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      editorOptionStudentPlus: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: "Агуулгад тайлбар өгөх...",
      },
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  methods: {
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    getMyInfo() {
      //   console.log("Ажиллаж байна. getMyInfo");
      axios
        .get("/all_worker_functions/getMyInfo")
        .then((res) => {
          this.myEmpId = res.data.getMyInfoTeacher.employee_id;
        })
        .catch();
    },

    averageCheck(allMonthPlans) {
      this.niit = 0;
      this.unelgeeList = [];
      this.unelgeeSum = "";
      this.unelgeeAvg = "";

      this.unelgee0List = [];
      this.unelgee0Sum = "";
      this.unelgee0Avg = "";

      allMonthPlans.forEach((element) => {
        if (element.point == null) this.unelgeeList.push(0);
        else this.unelgeeList.push(parseInt(element.point));
      });

      this.unelgeeSum = this.unelgeeList.reduce((a, b) => a + b, 0);
      this.unelgeeAvg = (this.unelgeeSum / this.unelgeeList.length).toFixed();
      this.niit = this.unelgeeList.length;

      //together zero
      this.unelgee0List = this.unelgeeList.filter((el) => el !== 0);
      this.unelgee0Sum = this.unelgee0List.reduce((a, b) => a + b, 0);
      this.unelgee0Avg = (
        this.unelgee0Sum / this.unelgee0List.length
      ).toFixed();
    },
    changeTeacherList() {
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.filterForm
        .get(
          "/all_worker_functions/getTeacherList/" +
            this.filterForm.department_id
        )
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formAddTeacher
        .get(
          "/all_worker_functions/getTeacherList/" +
            this.formAddTeacher.department_id
        )
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getMyMonthPlans() {
      this.show = true;
      let yearId = null;
      if (this.yearId) yearId = this.yearId.id;
      axios
        .post("/all_worker_functions/getMyMonthPlans", {
          selectedMonths: this.selectedMonths,
          year_id: yearId,
        })
        .then((response) => {
          this.allMonthPlans = response.data.allMonthPlans;
          this.departments = response.data.departments;
          this.teachers = response.data.teachers;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.averageCheck(this.allMonthPlans);
          this.changeTeacherList();
          this.show = false;
          this.allMonthMyPlans = response.data.allMonthMyPlans;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showMonthPlan(monthPlan) {
      // console.log(monthPlan);
      this.monthPlanEmps = monthPlan.month_plan_teachers;
      this.showMonthPlanInfo.reset();
      this.showMonthPlanInfo.fill(monthPlan);
      this.showMode = !this.showMode;
      this.modalTeachers = this.teachers;
      this.$bvModal.show("modal-show-month-plan");
    },
    editMonthPlan(monthPlan) {
      this.editMode = true;
      this.form.reset();
      this.form.fill(monthPlan);
      this.form.recieved_employees = [];
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    cancelMonthlyPlan() {
      this.showMode = !this.showMode;
      this.showMonthPlanInfo.reset();
      this.$bvModal.hide("modal-show-month-plan");
    },
    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },
    createMonthPlan() {
      console.log(this.myEmpId);
      this.form.owner_id = this.myEmpId;
      this.form.leader_id = this.myEmpId;
      this.form
        .post("/all_worker_functions/createMonthPlan")
        .then((response) => {
          this.$toastr.s("Сарын төлөвлөгөө амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getMonthPlans");
          this.form.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    updateMonthPlan() {
      this.action = "Сарын төлөвлөгөөний мэдээллийг шинэчилж байна...";
      console.log(this.form);
      this.form
        .put("/all_worker_functions/updateMonthPlan/" + this.form.monthPlanId)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getMonthPlans");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addTeacherMonthPlan() {
      this.formAddTeacher.monthPlanId = this.showMonthPlanInfo.monthPlanId;
      this.formAddTeacher
        .post("/all_worker_functions/addTeacherMonthPlan")
        .then((response) => {
          this.monthPlanEmps = response.data.monthPlanEmps;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formAddTeacher.reset();
          Fire.$emit("getMonthPlans");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeTeacher(schoolteacher) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Сарын төлөвлөгөөнөөс багш хасах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              "/all_worker_functions/removeTeacherMonthPlan/" +
                schoolteacher.id,
              {
                params: {
                  monthPlanId: schoolteacher.month_plan_id,
                },
              }
            )
            .then((response) => {
              this.monthPlanEmps = response.data.monthPlanEmps;
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getMonthPlans");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
  },
  created() {
    this.getMyMonthPlans();
    this.getMyInfo();
    Fire.$on("getMonthPlans", () => {
      this.getMyMonthPlans();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
.center-badge {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Ensure text inside the badge is centered */
}
</style>
