<template>
  <div class="col-lg-12 mt-2">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">
          Танин мэдэхүйн үйлийн системийн түвшин:
          {{ mentalData.mental_system_name }} -ийн түвшин
        </h3>
        <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
      </div>
      <div class="card-body table-responsive p-0">
        <hr />
        <table class="table table-hover table-striped table-valign-middle">
          <thead>
            <tr role="row">
              <th>№</th>
              <th>Танин мэдэхүйн үйлийн түвшин</th>
              <th>Танин мэдэхүйн үйлийн түвшний тайлбар</th>
              <th>Үүсгэсэн</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="odd"
              v-for="(levelName, index) in allTaxanomyLevelName"
              :key="levelName.id"
            >
              <td class="dtr-control sorting_1" tabindex="0">
                {{ index + 1 }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                <b-badge variant="info" @click="childCompLevelName(levelName)">
                  {{ levelName.tax_lev_name }}
                </b-badge>
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ levelName.tax_lev_name_explain }}
              </td>
              <td class="dtr-control sorting_1" tabindex="0">
                {{ levelName.created_at | dateYearMonthDay }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /.card-body -->
    </div>
    <child-2-level-name-mode
      v-if="childMode2"
      :changeData2="changeData2"
      :child2LevelName="child2LevelName"
    ></child-2-level-name-mode>
  </div>
</template>

<script>
import child2LevelNameMode from "./Child2LevelName/Child2LevelName.vue";
export default {
  props: ["mentalData", "changeData1"],
  data() {
    return {
      childMode2: false,
      child2LevelName: "",
      allTaxanomyLevelName: [],
      changeData2: false,
    };
  },
  watch: {
    mentalData: function (newVal, oldval) {
      this.getAllInfo();
    },
    changeData1: function (newVal1, oldVal1) {
      //   console.log(newVal1, oldVal1);
      this.childMode2 = false;
      this.changeData2 = !this.changeData2;
    },
  },
  components: {
    child2LevelNameMode,
  },
  methods: {
    getAllInfo() {
      axios
        .get(`/teacher/getAllTaxamoyLevelNameTeacher/${this.mentalData["id"]}`)
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.allTaxanomyLevelName = response.data.allTaxanomyLevelName;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    childCompLevelName(levelName) {
      //   console.log(levelName);
      this.child2LevelName = "";
      this.childMode2 = false;
      this.child2LevelName = levelName;
      this.changeData2 = !this.changeData2;
      //   console.log(this.child2LevelName);
      this.childMode2 = true;
    },
  },
  destroyed() {
    console.log("Лог хийгдэж байна Child system 1");
  },
  created() {
    this.getAllInfo();
  },
};
</script>

<style></style>
