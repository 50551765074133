<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Таксономын хавтгай болон ББЗ алхмаар даалгавар боловсруулах онолын
            мэдлэг: Тархины үйлийн систем
          </h3>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн систем</th>
                <th>Үйлийн системийн тайлбар</th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(mental, index) in allmentalsystem"
                :key="mental.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="secondary" @click="childComp(mental)">
                    {{ mental.mental_system_name }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.mental_system_name_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ mental.created_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <child-system
          v-if="childMode1"
          :mentalData="childMentalData"
          :changeData1="changeData1"
        ></child-system>
        <div class="card-body table-responsive">
          <b-overlay :show="taxTableLoaded" rounded="sm">
            <table
              id="cp_table"
              class="table table-hover table-sm table-bordered"
            >
              <tbody>
                <tr>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    ...
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    colspan="1"
                  >
                    Мэдлэгийн төрөл
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    v-for="(knowledge, index) in allTaxKnowSystems"
                    :key="index"
                    :colspan="countSteps(knowledge)"
                  >
                    {{ knowledge.knowledge_system_name }}
                  </td>
                </tr>
                <tr>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    ...
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    colspan="1"
                  >
                    Мэдлэгийн ангилал
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    v-for="(knowledgeLevels, index) in allTaxKnowSysLevels"
                    :key="index"
                    :colspan="countStepsKnowLev(knowledgeLevels)"
                  >
                    {{ knowledgeLevels.knowledge_lev_name }}
                  </td>
                </tr>
                <tr>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    ...
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    colspan="1"
                  >
                    Мэдлэг
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    v-for="(
                      knowledgeLevOpName, index
                    ) in allTaxKnowSysLevOpeName"
                    :key="index"
                  >
                    <!-- :colspan="countStepsKnowLev(knowledgeLevOpName)" -->
                    {{ knowledgeLevOpName.tax_know_lev_name_operation }}
                  </td>
                </tr>
                <!-- <span
                  v-for="(mentals, index1) in allmentalsystem"
                  :key="index1"
                >
                  <span v-for="(level, index2) in mentals.levels" :key="index2">
                    <span
                      v-for="(operation, index3) in level.operations"
                      :key="index3"
                    >
                      {{ operation.tax_lev_name_operation }}
                    </span>
                  </span>
                </span> -->

                <!-- <tr v-for="(mental, index) in allmentalsystem" :key="index">
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    {{ mental.mental_system_name }}
                  </td>
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    <tr v-for="(level, index) in mental.levels" :key="index">
                      <td>
                        {{ level.tax_lev_name }}
                      </td>
                    </tr>
                  </td>
                  <td>
                    <span v-for="(level, index) in mental.levels" :key="index">
                      <tr
                        v-for="(operation, index) in level.operations"
                        :key="index"
                      >
                        <td>
                          {{ operation.tax_lev_name_operation }}
                        </td>
                      </tr>
                    </span>
                  </td>
                </tr> -->
                <tr v-for="(level, index) in allTaxMenSysLevels" :key="index">
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    <!-- :rowspan="countStepsMental(mental)" -->
                    {{ level.tax_lev_name }}
                  </td>

                  <td>
                    <table>
                      <tr
                        v-for="(operation, index) in level.operations"
                        :key="index"
                      >
                        <td>
                          {{ operation.tax_lev_name_operation }}
                        </td>
                      </tr>
                    </table>
                    <!-- <span
                      
                    >
                      <tr>
                        <td>
                          {{ operation.tax_lev_name_operation }}
                        </td>
                      </tr>
                    </span> -->
                  </td>
                  <td
                    v-for="(
                      knowledgeLevOpName, index
                    ) in allTaxKnowSysLevOpeName"
                    :key="index"
                  ></td>
                </tr>
                <!-- <tr v-for="(employee, index) in skilEvalForEmps" :key="index">
                  <td class="dtr-control sorting_1 text-center" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                    colspan="1"
                  >
                    <b-badge variant="light">
                      {{ employee.emp_teacher_last_name[0]
                      }}{{ employee.emp_teacher_last_name[1] }}.
                      {{ employee.emp_teacher_name }}
                      <span> ({{ employee.dep_department_code }})</span>
                    </b-badge>
                  </td>
                  <td
                    v-for="skillValue in employee.my_skill_eval"
                    :key="skillValue.id"
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                  >
                    <input
                      style="width: 60px"
                      type="number"
                      placeholder="Ур чадварын үнэлгээ"
                      required
                      :value="skillValue.value"
                      @change="onStoreSkillValue($event, skillValue)"
                    />
                  </td>
                  <td class="dtr-control sorting_1 text-center">
                  </td>
                  <td class="dtr-control sorting_1 text-center">
                  </td>
                  <td class="dtr-control sorting_1 text-center">
                  </td>
                  <td class="dtr-control sorting_1 text-center">
                  </td>
                </tr> -->
              </tbody>
            </table>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import childSystem from "./ChildSystem/ChildSystem.vue";
export default {
  data() {
    return {
      childMode1: false,
      childMentalData: "",
      allmentalsystem: [],
      allTaxMenSysLevels: [],
      allTaxMenSysLevOperations: [],
      allTaxMenSysLevOpeWords: [],
      changeData1: false,

      taxTableLoaded: true,
      allTaxKnowSystems: [],
      allTaxKnowSysLevels: [],
      allTaxKnowSysLevOpeName: [],
    };
  },
  components: {
    childSystem,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/teacher/getAllTaxamoySystemTeacher")
        .then((res) => {
          // console.log(res.data.schoolteachers);
          //MentalSystem
          this.allmentalsystem = res.data.allTaxanomySystem;
          this.allTaxMenSysLevels = res.data.allTaxMenSysLevels;
          this.allTaxMenSysLevOperations = res.data.allTaxMenSysLevOperations;
          this.allTaxMenSysLevOpeWords = res.data.allTaxMenSysLevOpeWords;

          //Knowledge
          this.allTaxKnowSystems = res.data.allTaxKnowSystems;
          this.allTaxKnowSysLevels = res.data.allTaxKnowSysLevels;
          this.allTaxKnowSysLevOpeName = res.data.allTaxKnowSysLevOpeName;
          //   Fire.$emit("loadAllTaxanomyMentalSystem");
          this.taxTableLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    childComp(mental) {
      //   console.log(mental);
      this.childMode1 = false;
      this.childMentalData = "";
      this.childMentalData = mental;
      //   console.log(this.childMentalData);
      this.childMode1 = true;
      this.changeData1 = !this.changeData1;
    },

    // Mental count
    countStepsMental(items) {
      // console.log(items);
      let myCheckMentalStep = [];
      items.knowledge_levels.forEach((el) => {
        // console.log("symbol for", el);
        el.level_operations.forEach((el1) => {
          myCheckMentalStep.push(el1);
        });
      });
      return myCheckMentalStep.length;
    },

    // Knowledge count
    countSteps(items) {
      // console.log(items);
      let myCheckKnowledgeStep = [];
      items.knowledge_levels.forEach((el) => {
        // console.log("symbol for", el);
        el.level_operations.forEach((el1) => {
          myCheckKnowledgeStep.push(el1);
        });
      });
      return myCheckKnowledgeStep.length;
    },
    countStepsKnowLev(items) {
      // console.log(items);
      let myCheckKnowledgeLevelStep = [];
      items.level_operations.forEach((el) => {
        // console.log("symbol for", el);
        myCheckKnowledgeLevelStep.push(el);
      });
      return myCheckKnowledgeLevelStep.length;
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyMentalSystem", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style scoped>
td {
  font-size: 80%;
}
</style>
