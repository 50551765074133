<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Эцэг эхийн хурлын тэмдэглэл</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addParentMeet()"
                    >Эцэг эхийн хурал бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      <b>Нийт эцэг эхийн хурлын тоо:</b>
                      {{
                        parentMeets.length
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>Шүүсэн эцэг эхийн хурлын тоо:</b>
                      {{ filteredCount }}

                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>Нийт эцэг эхийн хурлын гүйцэтгэл:</b>
                      {{ percentAtt }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="parentsLoaded" rounded="sm">
                    <b-row>
                      <!-- <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Sort"
                          label-for="sort-by-select"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-input-group size="sm">
                            <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              :options="sortOptions"
                              :aria-describedby="ariaDescribedby"
                              class="w-75"
                            >
                              <template #first>
                                <option value="">-- none --</option>
                              </template>
                            </b-form-select>

                            <b-form-select
                              v-model="sortDesc"
                              :disabled="!sortBy"
                              :aria-describedby="ariaDescribedby"
                              size="sm"
                              class="w-25"
                            >
                              <option :value="false">Asc</option>
                              <option :value="true">Desc</option>
                            </b-form-select>
                          </b-input-group>
                        </b-form-group>
                      </b-col> -->
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptionsDocuments"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Хурлын гарчиг(нэр)-аар шүүлт хийхийг анхаарна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="parentMeets"
                      :fields="fieldsActive"
                      :current-page="currentPage"
                      :per-page="perPage"
                      style="font-size: 80%"
                      :filter-ignored-fields="filterIgnoredFields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(class_teacher_info)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{
                            data.item.class_teacher_info.teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ data.item.class_teacher_info.teacher_name }}
                          <br />
                        </b-badge>
                      </template>
                      <template #cell(teacher_class_info)="data">
                        <b-badge variant="light" class="text-wrap">
                          {{ data.item.teacher_class_info.full_name }}
                        </b-badge>
                      </template>

                      <template #cell(date)="data">
                        <b-badge
                          variant="light"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.date | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(parent_meet_title)="data">
                        <b-badge
                          variant="secondary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.parent_meet_title }}
                        </b-badge>
                      </template>
                      <template #cell(parent_meet_content)="data">
                        <span
                          v-html="
                            data.item.parent_meet_content.substring(0, 100)
                          "
                        >
                        </span>
                      </template>
                      <template #cell(parent_meet_votes)="data">
                        <span
                          v-html="data.item.parent_meet_votes.substring(0, 100)"
                        >
                        </span>
                      </template>
                      <template #cell(parent_meet_decisions)="data">
                        <span
                          v-html="
                            data.item.parent_meet_decisions.substring(0, 100)
                          "
                        >
                        </span>
                      </template>

                      <template #cell(my_parents)="data">
                        <span v-if="data.item.my_parents">
                          <b-badge variant="warning">
                            {{ data.item.my_parents.length }}
                            /
                            {{ data.item.allParentCount }}
                            (
                            {{
                              percentParentsAtt(
                                data.item.my_parents,
                                data.item.allParentCount
                              )
                            }}
                            )
                          </b-badge>
                          <b-badge
                            v-for="(parent, index) in data.item.my_parents"
                            :key="parent.user_id"
                            >{{ index + 1 }}.
                            {{ parent["parent_info"].parent_father_name }}(аав)
                            {{ parent["parent_info"].parent_mother_name }}(ээж)
                          </b-badge>
                        </span>
                      </template>

                      <template #cell(created_at)="data">
                        {{ data.item.created_at | dateYearMonthDay }}
                      </template>
                      <template #cell(actions)="data">
                        <div>
                          <!-- v-if="
                                                        userInfo.pivot
                                                            .model_id ==
                                                            data.item
                                                                .doc_owner_id ||
                                                            userInfo.name ==
                                                                'cipalprin'
                                                    " -->
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="edit(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deleteParentMeet(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Эцэг эхийн хурлын тэмдэглэл бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-parent-meet"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="formDocModalTitle"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editMode">
                    Эцэг эхийн хурал бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editMode">
                    Эцэг эхийн хурал засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editMode ? createParentMeet() : updateParentMeet()
                    "
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-7">
                          <div class="form-group">
                            <label>Хурлын нэр(гарчиг)</label>
                            <input
                              type="text"
                              v-model="form.parent_meet_title"
                              placeholder="Хурлын нэр(гарчиг  )"
                              class="form-control"
                              :class="{
                                'is-invalid': form.errors.has('title'),
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="form-group">
                            <label>Хурал хийсэн огноо</label>
                            <b-form-datepicker
                              v-model="form.date"
                              class="mb-2"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': form.errors.has('date'),
                                },
                              ]"
                              placeholder="Хурал хийсэн огноо"
                            ></b-form-datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label
                              v-if="
                                userInfo.name == 'cipalprin' ||
                                userInfo.name == 'agerman' ||
                                mySchoolClasses.length > 1
                              "
                              >Анги сонгох</label
                            >
                            <div class="row">
                              <multiselect
                                v-if="
                                  userInfo.name == 'cipalprin' ||
                                  userInfo.name == 'agerman' ||
                                  mySchoolClasses.length > 1
                                "
                                v-model="school_class_id"
                                :multiple="false"
                                deselect-label="Can't remove this value"
                                :custom-label="nameSchoolClass"
                                track-by="id"
                                label="id"
                                placeholder="Сонголт хийх"
                                :options="mySchoolClasses"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="true"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.full_name }} анги</strong>
                                  сонгогдлоо
                                </template>
                              </multiselect>
                              <label v-if="userInfo.name == 'chertea'"
                                >Эцэг эх сонгох</label
                              >
                              <multiselect
                                v-model="form.parents"
                                :multiple="true"
                                deselect-label="Can't remove this value"
                                :custom-label="nameParent"
                                track-by="user_id"
                                label="user_id"
                                placeholder="Сонголт хийх"
                                :options="myClassParents"
                                :searchable="true"
                                :allow-empty="true"
                                :close-on-select="false"
                                :preserve-search="true"
                                :hide-selected="true"
                              >
                                <!-- @select="toggleSelect" -->
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong
                                    >{{ option.parent_father_name }} ({{
                                      option.school_class_full_name
                                    }}
                                    {{ option.student_name }})</strong
                                  >
                                  сонгогдлоо
                                </template>
                              </multiselect>
                              <b-button
                                variant="outline-info"
                                class="mb-3"
                                @click="selectAllParents"
                                >Бүх эцэг эх сонгох</b-button
                              >
                              <label class="typo__label"
                                >Нийт:
                                {{ form.parents.length }}
                                эцэг эх сонгогдлоо</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label>Хурлын агуулга</label>

                            <quill-editor
                              v-model="form.parent_meet_content"
                              :options="editorOptionParentMeet"
                            >
                            </quill-editor>
                          </div>
                          <div class="form-group">
                            <label>Хурлаас гарсан саналууд</label>
                            <quill-editor
                              v-model="form.parent_meet_votes"
                              :options="editorOptionParentMeet"
                            >
                            </quill-editor>
                          </div>
                          <div class="form-group">
                            <label>Хурлаас гарсан шийдвэрүүд</label>
                            <quill-editor
                              v-model="form.parent_meet_decisions"
                              :options="editorOptionParentMeet"
                            >
                            </quill-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editMode"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editMode"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editMode"
                        @click.prevent="cancelEdit"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editMode"
                        @click.prevent="cancelCreate"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      docValue: "",
      pdfValue: "",
      docType: "",
      showFileInfo: {
        info: "",
      },
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formDocModalTitle: "Эцэг эхийн хурлын тэмдэглэл бүртгэх",

      //Бүртгэлтэй Эцэг эхийн хурлын тэмдэглэл хэсэг
      parentMeets: [],
      myClassParents: [],
      mySchoolClasses: [],
      school_class_id: "",
      editMode: false,

      //b-table тохиргоо
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptionsDocuments: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filter: "",
      filterOn: [],
      filteredCount: 0,
      filterIgnoredFields: [
        "index",
        "class_teacher_info",
        "teacher_class_info",
        "date",
        "parent_meet_content",
        "parent_meet_votes",
        "parent_meet_decisions",
        "my_parents",
        "created_at",
        "actions",
      ],
      fieldsActive: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "class_teacher_info",
          label: "АУБ",
          sortable: true,
        },
        {
          key: "teacher_class_info",
          label: "Анги",
          sortable: true,
        },

        {
          key: "date",
          label: "Хурал хийсэн огноо",
          sortable: true,
        },

        {
          key: "parent_meet_title",
          label: "Хурлын гарчиг(нэр)",
          sortable: true,
        },
        {
          key: "parent_meet_content",
          label: "Агуулга",
          sortable: true,
        },
        {
          key: "parent_meet_votes",
          label: "Саналууд",
          sortable: true,
        },
        {
          key: "parent_meet_decisions",
          label: "Шийдвэрүүд",
          sortable: true,
        },
        {
          key: "my_parents",
          label: "Оролцсон эцэг эхчүүд",
          sortable: true,
          //   sortDirection: "desc",
        },

        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        school_class_id: "",
        parents: [],
        date: "", //хурал болсон огноо
        parent_meet_title: "", //хурлын гарчин
        parent_meet_content: "", //хурлаар хэлэлцсэ агуулга
        parent_meet_votes: "", //Хурлаас гарсан саналууд
        parent_meet_decisions: "", //Хурлаас гарсан шийдвэрүүд
      }),

      //   vue-quill-editor

      editorOptionParentMeet: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder: "Эцэг эхийн хурлын тэмдэглэл...",
      },
    };
  },
  components: { Multiselect },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },

    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fieldsActive
    //     .filter((f) => f.sortable)
    //     .map((f) => {
    //       return { text: f.label, value: f.key };
    //     });
    // },
    percentAtt() {
      let a = 0;
      let b = 0;
      this.parentMeets.forEach((element) => {
        a = a + element.my_parents.length;
        b = b + element.allParentCount;
      });
      let val = (a / b) * 100;
      return (
        "Оролцсон: " +
        a +
        " Оролцох: " +
        b +
        " Гүйцэтгэл: " +
        val.toFixed(1) +
        "%"
      );
    },
  },
  watch: {
    school_class_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      this.form.parents = [];
      this.form.school_class_id = "";
      axios
        .get("/teacher/getSchoolClassesParets/" + newVal.id)
        .then((res) => {
          //   console.log(response);
          this.form.school_class_id = newVal.id;
          this.myClassParents = res.data.myClassParents;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.room_owner_employees = "";
    //   this.form.room_plate_face = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/manager/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
  },
  methods: {
    getAllMyParentMeet() {
      this.parentsLoaded = true;
      axios
        .get("/teacher/getAllMyParentMeet")
        .then((res) => {
          //   console.log(response);
          this.parentMeets = res.data.parentMeets;
          this.myClassParents = res.data.myClassParents;
          this.mySchoolClasses = res.data.mySchoolClasses;
          this.totalRows = this.parentMeets ? this.parentMeets.length : 0;
          this.parentsLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    percentParentsAtt(enrolled, allParent) {
      // console.log(enrolled);
      let checkVal = (enrolled.length / allParent) * 100;
      return checkVal.toFixed(1) + "%";
    },
    selectAllParents() {
      this.form.parents = this.myClassParents;
    },

    //b-table тохирго
    onFiltered(filteredItems) {
      //   console.log(filteredItems);
      this.filteredCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addParentMeet() {
      this.form.reset();
      this.$bvModal.show("modal-create-parent-meet");
    },
    createParentMeet() {
      //   console.log("create");
      this.form
        .post("/teacher/createParentMeet")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadParentMeets");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    edit(parent) {
      // console.log(parent);
      //exams_teacher_id, block_name,
      this.editMode = true;
      this.form.fill(parent);
      let checkUsers = [];
      this.myClassParents.forEach((element) => {
        parent.my_parents.forEach((el) => {
          if (element.user_id == el.parent_id) {
            checkUsers.push(element);
          }
        });
      });

      this.form.parents = checkUsers;

      this.$bvModal.show("modal-create-parent-meet");
    },
    updateParentMeet() {
      //   console.log("udpate");
      this.form
        .put("/teacher/updateParentMeet/" + this.form.id)
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadParentMeets");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editMode = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-parent-meet");
    },

    nameSchoolClass({ full_name }) {
      return `${full_name}`;
    },

    nameParent({ parent_father_name, school_class_full_name, student_name }) {
      return `${parent_father_name}(${school_class_full_name} ${student_name})`;
    },

    deleteParentMeet(parentmeet) {
      //   console.log(parentmeet);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд хурлын тэмдэглэл сэргээх боломжгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteParentMeet/" + parentmeet.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Хурлын тэмдэглэл системээс устгалаа.",
                "success"
              );
              Fire.$emit("loadParentMeets");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },

  created() {
    this.getAllMyParentMeet();
    Fire.$on("loadParentMeets", () => {
      this.getAllMyParentMeet();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}

.cardFile {
  border-width: 1px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
