<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card" style="overflow-x: auto">
        <div class="card-header">
          <h3 class="card-title">Эцэг эхийн талархлууд</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th class="text-sm">#</th>
                <th class="text-sm">Эцэг эх</th>
                <th class="text-sm">Суралцагч</th>
                <th class="text-sm" style="max-width: 200px">Талархал</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(appreciation, index) in allParentAppreciations"
                :key="appreciation.id"
              >
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <span style="font-size: 80%">
                    <b>Аав:</b>
                    <b-badge variant="info">
                      {{ appreciation.profile_parent.parent_father_ovog[0] }}.
                      {{ appreciation.profile_parent.parent_father_name }}
                      <br />
                      Утас:
                      {{
                        appreciation.profile_parent.parent_father_phone_number
                      }}
                    </b-badge>
                    <br />
                    <b>Ээж:</b>
                    <b-badge variant="info">
                      {{ appreciation.profile_parent.parent_mother_ovog[0] }}.
                      {{ appreciation.profile_parent.parent_mother_name }}
                      <br />
                      Утас:
                      {{
                        appreciation.profile_parent.parent_mother_phone_number
                      }}
                    </b-badge>
                  </span>
                </td>
                <td class="text-sm">
                  <b-badge
                    variant="gray"
                    v-for="(child, i) in appreciation.profile_parent
                      .get_my_primary_child"
                    :key="i"
                  >
                    {{ child.student_name }}({{
                      child.student_migration_info.my_school_class_info
                        .full_name
                    }})
                  </b-badge>
                </td>
                <td class="text-sm">
                  <!-- class="badge bg-gray" -->
                  <span
                    class="badge"
                    style="white-space: pre-wrap"
                    v-html="appreciation.appreciation_talarkhal"
                  >
                  </span>
                  <!-- <b-badge variant="info" style="white-space: pre-wrap">{{
                    appreciation.talarkhal
                  }}</b-badge> -->
                </td>
                <td>
                  {{ appreciation.created_at | dateYearMonthDay }}
                </td>

                <td>
                  <!-- <a
                    href="#"
                    class="badge bg-warning"
                    @click="editAppreciation(appreciation)"
                    ><i class="fas fa-edit"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteAppreciations(appreciation)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      allParentAppreciations: [],
      form: new Form({
        id: "",
        talarkhal: "",
      }),
    };
  },
  methods: {
    getAllAppreciations() {
      axios
        .get("/manager/getAllParentAppreciations")
        .then((response) => {
          //   console.log(response.data.schoolentrants);
          this.allParentAppreciations = response.data.allAppreciations;
          //console.log(this.schoolentrants);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
  },
  computed: {
    // validation() {
    //   return this.form.student_reg.length == 10;
    // },
  },
  created() {
    this.getAllAppreciations();
  },
};
</script>

<style></style>
