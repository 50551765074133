<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <multiselect
        v-model="year_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="id"
        label="id"
        :custom-label="customYearName"
        placeholder="Хичээлийн жилээр шүүж харах"
        :options="allYears"
        :searchable="true"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{ option.finish_date | dateYear }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <!-- <div class="card">
        <div class="card-header p-2"></div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12"></div>
          </div>
        </div>
      </div> -->
      <div v-if="isShow">
        <!-- Мэргэжлийн багуудын бүтэц дахин нягтлав. Нийт идэвхитэй багш ажилтан -->
        <div class="row">
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="optionsDep"
                :series="seriesDep"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options1Dep"
                :series="series1Dep"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- Мэргэжлийн багуудын бүтэц шилжилт хөдөлгөөнөөр-->
        <div class="row">
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options1"
                :series="series1"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- Насны харьцаа -->
        <div class="row">
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="options2"
                :series="series2"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options3"
                :series="series3"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- Хүйсийн харьцаа -->
        <div class="row">
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="85%"
                type="bar"
                :options="options4"
                :series="series4"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-6">
            <div class="small-box bg-white">
              <apexchart
                width="80%"
                type="donut"
                :options="options5"
                :series="series5"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isShow: false,
      year_id: "",
      allYears: [],
      allEmployees: [],
      getAllDeletedEmployees: [],

      //Мэргэжлийн багийн бүтэц тоо
      options: {
        chart: {
          id: "Байгууллагын ажилчдын бүтэц",
        },
        title: {
          text: "Байгууллагын ажилчдын бүтэц шилжилт хөдөлгөөнөөр",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: "Жил",
          data: [], //жил
        },
      ],
      //Мэргэжлийн багийн бүтэц тоо дахин нягтлав.
      optionsDep: {
        chart: {
          id: "Байгууллагын хүний нөөцийн бүтэц",
        },
        title: {
          text: "Байгууллагын хүний нөөцийн бүтэц",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      seriesDep: [
        {
          name: "Жил",
          data: [], //жил
        },
      ],
      options1Dep: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series1Dep: [],
      //Мэргэжлийн багийн бүтэц хувь
      options1: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series1: [],
      //Байгууллагын насны харьцаа тоо
      options2: {
        chart: {
          id: "Байгууллагын ажилчдын насны бүтэц",
        },
        title: {
          text: "Байгууллагын ажилчдын насны бүтэц",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series2: [
        {
          name: "Нас",
          data: [], //жил
        },
      ],
      //Байгууллагын насны харьцаа хувь
      options3: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series3: [],
      //Байгууллагын хүйсийн харьцаа тоо
      options4: {
        chart: {
          id: "Байгууллагын ажилчдын хүйсийн бүтэц",
        },
        title: {
          text: "Байгууллагын ажилчдын хүйсийн бүтэц",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series4: [
        {
          name: "Хүйс",
          data: [], //жил
        },
      ],
      //Байгууллагын хүйсийн харьцаа хувь
      options5: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series5: [],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    // allComplaintsPercent() {
    //   let percent = (this.allComplaints / this.allComplaints) * 100;
    //   return percent.toFixed(1);
    // },
    // pollPercent() {
    //   let percent =
    //     (this.allComplaintsParentPoll / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // complaintPercent() {
    //   let percent =
    //     (this.allComplaintsParentComplaint / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // contributionPercent() {
    //   let percent =
    //     (this.allComplaintsParentContribution / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
    // ethicsPercent() {
    //   let percent =
    //     (this.allComplaintsParentEthics / this.allComplaintsParent) * 100;
    //   return percent.toFixed(1);
    // },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      this.seriesDep[0].data = [];
      this.optionsDep.xaxis.categories = [];
      // console.log(this.seriesDep[0].data);
      this.series1Dep = [];
      this.options1Dep.labels = [];

      this.series[0].data = [];
      this.options.xaxis.categories = [];
      this.series1 = [];
      this.options1.labels = [];

      this.options2.xaxis.categories = [];
      this.series2[0].data = [];

      this.options3.labels = [];
      this.series3 = [];

      this.options4.xaxis.categories = [];
      this.series4[0].data = [];

      this.options5.labels = [];
      this.series5 = [];
      this.isShow = false;
      this.getAllUsersStat(newVal.id);
      // console.log(newVal);
      // console.log(this.seriesDep[0].data);
      // console.log(this.optionsDep.xaxis.categories);
    },
  },
  methods: {
    getAllYear() {
      axios.get("/hr/getAllYear").then((response) => {
        this.allYears = response.data.allYears;
      });
    },

    getAllUsersStat(id) {
      // console.log(Array.isArray(this.seriesDep[0].data));
      // console.log(Array.isArray(this.optionsDep.xaxis.categories));

      axios
        .post("/hr/getAllUsersStat", { yearId: id })
        .then((response) => {
          this.allEmployees = response.data.getAllEmployees;
          this.getAllDeletedEmployees = response.data.getAllDeletedEmployees;

          // Мэргэжлийн багийн бүтэц дахин шалгав

          // console.log(this.optionsDep);
          // console.log(this.series1Dep);
          // console.log(this.options1Dep);

          this.allEmployees.forEach((el) => {
            this.seriesDep[0].data.push(el.dep_too);
            this.optionsDep.xaxis.categories.push(el.dep_department_code);
            this.series1Dep.push(parseInt(el.dep_too));
            this.options1Dep.labels.push(el.dep_department_code);
          });
          // console.log(this.seriesDep[0].data);
          // console.log(this.optionsDep.xaxis.categories);

          this.getAllDeletedEmployees.forEach((el) => {
            this.series[0].data.push(el.dep_too);
            this.options.xaxis.categories.push(el.dep_department_code);
            this.series1.push(parseInt(el.dep_too));
            this.options1.labels.push(el.dep_department_code);
          });

          // Мэргэжлийн багийн бүтэц төгсгөл
          // Насны бүтэц эхлэл
          let filterNotZeroAsd = response.data.allAgesCheck;
          // console.log(filterNotZeroAsd);
          let myArr = [];
          filterNotZeroAsd.forEach((element) => {
            // console.log(element.value);
            if (element > 0) {
              myArr.push(element);
            }
          });
          // console.log(myArr);
          let Niilber = filterNotZeroAsd.reduce((a, b) => a + b);
          // console.log(Niilber);

          let filterNotZeroAvg = Niilber / myArr.length || 0;
          // console.log(filterNotZeroAvg);

          let new2DataAgeCats = [
            "20-25 нас",
            "25-30 нас",
            "30-35 нас",
            "35-40 нас",
            "40-45 нас",
            "45-ээс дээш",
            "Нас бүртгэгдээгүй",
            "Дундаж",
          ];
          let new2DataAge = [
            response.data.allAge20To25Count,
            response.data.allAge25To30Count,
            response.data.allAge30To35Count,
            response.data.allAge35To40Count,
            response.data.allAge40To45Count,
            response.data.allAge45UpCount,
            response.data.allAgeUnRegCount,
            filterNotZeroAvg.toFixed(1),
          ];
          new2DataAgeCats.forEach((element) => {
            this.options2.xaxis.categories.push(element);
          });
          // this.options.xaxis.categories = [new1DataCats];
          this.series2 = [{ data: new2DataAge }];
          //Насны харьцааг хувиар илэрхийлэх
          let new3DataAgeCatsLebels = [
            "20-25 нас",
            "25-30 нас",
            "30-35 нас",
            "35-40 нас",
            "40-45 нас",
            "45-ээс дээш",
            "Нас бүртгэгдээгүй",
          ];
          new3DataAgeCatsLebels.forEach((element) => {
            this.options3.labels.push(element);
          });
          this.series3 = [
            response.data.allAge20To25Count,
            response.data.allAge25To30Count,
            response.data.allAge30To35Count,
            response.data.allAge35To40Count,
            response.data.allAge40To45Count,
            response.data.allAge45UpCount,
            response.data.allAgeUnRegCount,
          ];
          // Насны бүтэц төгсгөл

          // Хүйсийн бүтэц эхлэл

          let new4DataAgeCats = ["Эр", "Эм", "Хүйс бүртгэгдээгүй"];
          let new4DataAge = [
            response.data.allUsersMale,
            response.data.allUsersFemale,
            response.data.allUsersUnGender,
          ];
          new4DataAgeCats.forEach((element) => {
            this.options4.xaxis.categories.push(element);
          });
          // this.options.xaxis.categories = [new1DataCats];
          this.series4 = [{ data: new4DataAge }];
          //Хүйсийн харьцааг хувиар илэрхийлэх
          let new5DataAgeCatsLebels = ["Эр", "Эм", "Хүйс бүртгэгдээгүй"];
          new5DataAgeCatsLebels.forEach((element) => {
            this.options5.labels.push(element);
          });
          this.series5 = [
            response.data.allUsersMale,
            response.data.allUsersFemale,
            response.data.allUsersUnGender,
          ];
          // Хүйсийн бүтэц төгсгөл
          this.isShow = true;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  // beforeMount() {
  //   this.getAllUsersStat();
  // },
  created() {
    this.getAllYear();
  },
};
</script>

<style></style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
