<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Сургалтын төрлийн мэдээлэл</h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Хичээлийн төрөл</th>
                <th>Хичээлийн төрлийн тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(type, index) in trainingPlanSubjectTypes"
                :key="type.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ type.name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ type.description }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ type.created_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ type.updated_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(type)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTrainingPlanSubjectType(type)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteTrainingPlanSubjectType(type)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Сургалтын төрөл</h3>
          <h3 class="card-title" v-show="editMode">Сургалтын төрөл засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editMode
                ? createTrainingPlanSubjectType()
                : updateTrainingPlanSubjectType()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label>Сургалтын төрөл</label>
                <b-textarea
                  name="name"
                  v-model="form.name"
                  text-field="name"
                  value-field="id"
                ></b-textarea>
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label>Сургалтын төрлийн тайлбар</label>
                <b-textarea
                  name="description"
                  v-model="form.description"
                  text-field="description"
                  value-field="id"
                ></b-textarea>
                <has-error :form="form" field="description"></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
export default {
  data() {
    return {
      editMode: false,
      trainingPlanSubjectTypes: [],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        name: "",
        description: "",
      }),
    };
  },
  components: {
    // searchManager: searchManager,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/getAllTrainingPlanSubjectTypes")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.trainingPlanSubjectTypes =
            response.data.trainingPlanSubjectTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTrainingPlanSubjectType(type) {
      console.log("Дэлгэрэнгүй мэдээлэл засах", type);
      this.editMode = true;
      this.form.reset();
      this.form.fill(type);
    },
    updateTrainingPlanSubjectType() {
      //   console.log(this.form);
      this.form
        .put(
          "/curriculumadministrator/updateTrainingPlanSubjectType/" +
            this.form.id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTrainingSubjectType");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteTrainingPlanSubjectType(type) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + type);

      Swal.fire({
        title: "Хичээлийн төрөл устгахдаа итгэлтэй байна уу?",
        text: '"' + type.name + '"' + " төрлийг  устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete(
              "/curriculumadministrator/deleteTrainingPlanSubjectType/" +
                type.id
            )
            .then((res) => {
              //   console.log(res.data.success);
              Fire.$emit("loadAllTrainingSubjectType");
            })
            .catch((err) => console.log(err));
        }
      });
    },
    createTrainingPlanSubjectType() {
      this.form
        .post("/curriculumadministrator/createTrainingPlanSubjectType")
        .then((response) => {
          this.$toastr.s("Сургалтын төрлийг амжилттай хадгалсан.", "Амжилттай");
          Fire.$emit("loadAllTrainingSubjectType");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Сургалтын төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTrainingSubjectType", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
