var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('multiselect',{attrs:{"deselect-label":"Хасах","select-label":"Сонгох","track-by":"id","label":"name","custom-label":_vm.customYearName,"placeholder":"Шалгалтын төрөл сонгоно уу","options":_vm.years,"searchable":true,"allow-empty":true},on:{"input":_vm.getSuccessesStat},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm._f("dateYear")(option.start_date))+"-"+_vm._s(_vm._f("dateYear")(option.finish_date))+"\n          - хичээлийн жил\n        ")])]}}]),model:{value:(_vm.yearId),callback:function ($$v) {_vm.yearId=$$v},expression:"yearId"}}),_vm._v(" "),_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.yearId != null),expression:"yearId != null"}]},[_c('table',{staticClass:"table table-bordered text-center"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Эзэлсэн байр")]),_vm._v(" "),_c('th',[_vm._v("Алт")]),_vm._v(" "),_c('th',[_vm._v("Мөнгө")]),_vm._v(" "),_c('th',[_vm._v("Хүрэл")]),_vm._v(" "),_c('th',[_vm._v("Тусгай байр(4-10)")]),_vm._v(" "),_c('th',[_vm._v("Оролцсон")]),_vm._v(" "),_c('th',[_vm._v("Нийт")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.turulBairCounts),function(counts,turul){return _c('tr',{key:turul},[_c('td',[_vm._v("\n                "+_vm._s(turul === "1"
                    ? "Анги"
                    : turul === "2"
                    ? "Сургууль"
                    : turul === "3"
                    ? "Дүүрэг"
                    : turul === "4"
                    ? "Нийслэл"
                    : turul === "5"
                    ? "Улс"
                    : turul === "6"
                    ? "Олон улс"
                    : turul === "0"
                    ? "Бусад"
                    : turul)+"\n              ")]),_vm._v(" "),_vm._l((counts.bairCounts),function(count,bair){return _c('td',{key:bair},[_vm._v("\n                "+_vm._s(count !== null ? count : 0)+"\n              ")])}),_vm._v(" "),_c('th',[_vm._v(_vm._s(counts.totalBairCount))])],2)}),0),_vm._v(" "),_c('tfoot',[_c('tr',[_c('th',[_vm._v("Нийт")]),_vm._v(" "),_vm._l((_vm.turulSums),function(turulSum){return _c('th',{key:turulSum.id},[_vm._v("\n                "+_vm._s(turulSum)+"\n              ")])}),_vm._v(" "),_c('th',[_vm._v("\n                "+_vm._s(this.totalCount)+"\n              ")])],2)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[(
                _vm.seriesTurul.length != 0 && _vm.optionsTurul.labels.length != 0
              )?_c('span',[_c('apexchart',{staticClass:"pieChart",attrs:{"type":"donut","height":"350","options":_vm.optionsTurul,"series":_vm.seriesTurul}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-6"},[(_vm.seriesBair.length != 0 && _vm.optionsBair.labels.length != 0)?_c('span',[_c('apexchart',{staticClass:"pieChart",attrs:{"type":"donut","height":"350","options":_vm.optionsBair,"series":_vm.seriesBair}})],1):_vm._e()])])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.yearId != null),expression:"yearId != null"}],staticClass:"col-lg-12 mt-12"},[_c('multiselect',{attrs:{"select-label":"Сонгох","selected-label":"Сонгогдсон","deselect-label":"Сонгосон өгөгдлийг устгах боломжгүй.","label":"student_turul","track-by":"name","placeholder":"Амжилтын төрөл сонгоно уу","options":_vm.turul},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
              var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(option.student_turul)+" ")])]}}]),model:{value:(_vm.statTurul_id),callback:function ($$v) {_vm.statTurul_id=$$v},expression:"statTurul_id"}}),_vm._v(" "),(_vm.seriesSchool.length != 0 && _vm.optionsSchool.labels.length != 0)?_c('span',[_c('apexchart',{staticClass:"pieChart",attrs:{"type":"donut","height":"350","options":_vm.optionsSchool,"series":_vm.seriesSchool}})],1):_vm._e()],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header",staticStyle:{"background-color":"#ec742545"}},[_c('h5',{staticClass:"card-title"},[_c('b',[_vm._v("Амжилтын статистик")])]),_vm._v(" "),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])}]

export { render, staticRenderFns }