<template>
    <cultural class="pt-3"></cultural>
</template>

<script>
import cultural from "../Project/CulturalCalendar.vue";
export default {
    components: {
        cultural: cultural
    },
    data() {
        return {};
    },
    methods: {},
    created() {}
};
</script>

<style scoped></style>
