<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Төлбөрийн мэдээлэл(1-12 анги) Бүрэн засварлаагүй
          </h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-sm-6">
                  <multiselect
                    v-model="selected"
                    deselect-label="Can't remove this value"
                    track-by="id"
                    label="id"
                    :custom-label="nameSchoolClass"
                    placeholder="Анги сонгох"
                    :options="schoolClasses"
                    :searchable="true"
                    :allow-empty="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>{{ option.full_name }} анги</strong>
                      сонгогдлоо
                    </template>
                  </multiselect>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn bg-gradient-warning btn-sm"
                    @click.prevent="allEntruntsExist"
                  >
                    1-12-р ангийн суралцагчдын төлбөрийн мэдээллийг автоматаар
                    үүсгэх.
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>РД</th>
                <th>Анги</th>
                <th>Төлбөр</th>
                <th>Төлсөн</th>
                <th>Төлөв</th>
                <th>Хувь</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr
                v-bind="
                  getPercent(schoolEntrant.pay, schoolEntrant.paid, index + 1)
                "
              > -->
              <tr
                v-for="(schoolEntrant, index) in schoolentrants"
                :key="schoolEntrant.user_id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.student_reg }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.school_class_full_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.pay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.paid }}
                </td>
                <td class="dtr-control sorting_1 pt-4" tabindex="0">
                  <div class="progress progress-xs progress-striped active">
                    <div
                      v-if="schoolEntrant.percent < 30"
                      class="progress-bar bg-danger"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 30 &&
                        schoolEntrant.percent < 60
                      "
                      class="progress-bar bg-warning"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 60 &&
                        schoolEntrant.percent < 80
                      "
                      class="progress-bar bg-primary"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="schoolEntrant.percent >= 80"
                      class="progress-bar bg-success"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                  </div>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-danger"
                    >ШИНЭ</span
                  >
                  <span
                    v-if="schoolEntrant.percent < 30"
                    class="badge bg-danger"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 30 && schoolEntrant.percent < 60
                    "
                    class="badge bg-warning"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 60 && schoolEntrant.percent < 80
                    "
                    class="badge bg-primary"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="schoolEntrant.percent >= 80"
                    class="badge bg-success"
                    >{{ schoolEntrant.percent }}%</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="editSchoolEntrant(schoolEntrant)"
                    ><i class="fa fa-check"></i
                  ></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr role="row">
                <th colspan="4"></th>
                <th>{{ totalClass }}</th>
                <th>{{ totalPaid }}</th>
                <th>70%</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">
            Элсэгчийн төлбөр баталгаажуулах
          </h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="editMode ? updateSchoolEntrant() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="profile_last_name"
                v-model="form.profile_last_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('profile_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="profile_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="user_name"
                v-model="form.user_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="user_name"></has-error>
            </div>
            <div class="form-group">
              <label>Төлсөн төлбөр</label>
              <input
                :max="form.pay"
                type="number"
                name="paid"
                v-model="form.paid"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('paid'),
                  },
                ]"
                placeholder="Төлсөн төлбөр"
              />
              <has-error :form="form" field="paid"></has-error>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-success" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      selected: "1",
      //   school_classes: [
      //     // { value: "0", text: "Бэлтгэл анги" },
      //     // { value: "1", text: "1-р анги" },
      //     // { value: "2", text: "2-р анги" },
      //     // { value: "3", text: "3-р анги" },
      //     // { value: "4", text: "4-р анги" },
      //     // { value: "5", text: "5-р анги" },
      //     // { value: "6", text: "6-р анги" },
      //     // { value: "7", text: "7-р анги" },
      //     // { value: "8", text: "8-р анги" },
      //     // { value: "9", text: "9-р анги" },
      //     // { value: "10", text: "10-р анги" },
      //     // { value: "11", text: "11-р анги" },
      //     // { value: "12", text: "12-р анги" },
      //   ],
      action: "",
      //   percentVal: 0,
      editMode: false,
      schoolentrants: [],
      schoolClasses: [],
      form: new Form({
        id: "",
        user_name: "",
        profile_last_name: "",
        user_id: "",
        pay: "",
        paid: "",
      }),
      totalClass: 0,
      totalPaid: 0,
      averagePer: 0,
      percent: 0,

      constClassId: "",
    };
  },
  components: {
    Multiselect,
  },
  computed: {},
  watch: {
    selected(newVal, oldVal) {
      this.getAllEntrants(newVal);
    },
  },

  methods: {
    getAllInfo() {
      axios
        .get("/finance/getAllInfo")
        .then((response) => {
          // this.schoolClasses = response.data.schoolClasses;
          // this.selected = this.schoolClasses[0];
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    //id-г тогтмол барьж утгыг авах
    constGetAllEntrunts() {
      //   this.schoolentrants = [];
      axios
        .get("/finance/getAllEntrants/" + this.constClassId)
        .then((response) => {
          const allStudents = response.data.schoolentrants;
          //   //   console.log(allStudents, "Бүх сурагчид");
          //   const allStudentsPayed = response.data.entrantsPayed;
          //   //   console.log(allStudentsPayed, "Төлбөр төлсөн суралцагчид");
          allStudents.forEach((element) => {
            if (element.paid != 0) {
              element.percent = parseFloat(
                (element.paid * 100) / element.pay
              ).toFixed(2);
            } else {
              element.percent = 0;
            }
          });
          //   console.log(allStudents, "Нэгтгэсэн data");
          this.schoolentrants = allStudents;
          //   this.schoolentrants = response.data.schoolentrants;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    getAllEntrants(newVal) {
      // console.log(newVal, "new val");
      this.constClassId = newVal["id"];
      axios
        .post("/finance/getAllEntrants", {
          schoolClassId: this.selected["id"],
          year_id: this.selectedYear["id"],
        })
        .then((response) => {
          //   this.constGetAllEntrunts();
          Fire.$emit("loadConstClassId");
          //   console.log(response.data);
          //   const allStudents = response.data.schoolentrants;
          //   //   //   console.log(allStudents, "Бүх сурагчид");
          //   //   const allStudentsPayed = response.data.entrantsPayed;
          //   //   //   console.log(allStudentsPayed, "Төлбөр төлсөн суралцагчид");
          //   allStudents.forEach((element) => {
          //     element.talbar = "zolboo";
          //   });
          //   //   console.log(allStudents, "Нэгтгэсэн data");
          //   this.schoolentrants = allStudents;
          //   this.schoolentrants = response.data.schoolentrants;
          //   this.schoolentrants = response.data.schoolentrants;
          //   this.entrantsPayed = response.data.entrantsPayed;
          //   console.log(this.schoolentrants);
          //   console.log(this.entrantsPayed);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //Бүх суралцагчыг 0 төлбөр төлсөн болгож finance_payments хүснэгтэнд нэг удаа үүсгэж өгнө. Дахиж хамаагүй ашиглаж болохгүй.
    allEntruntsExist() {
      axios
        .get("/finance/checkStudentPaymentInfoExists")
        .then((response) => {
          console.log(response.data);
          //   console.log(allStudents, "Бүх сурагчид");
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    percentVal(pay, paid) {
      if (paid === 0) {
        return 0;
      } else {
        this.percent = (paid * 100) / pay;
        // console.log(this.percent);
        //   this.percentVal = parseFloat(this.percent).toFixed(2);
        //   this.totalClass = index;
        return parseFloat(this.percent).toFixed(2);
      }
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
    },
    updateSchoolEntrant() {
      this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/finance/updateEntrantPay/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadConstClassId");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },

    nameSchoolClass({ full_name }) {
      return `${full_name} - анги`;
    },
  },
  created() {
    this.getAllInfo();
    // this.getAllEntrants();
    Fire.$on("loadAllEntrunts", () => {
      this.getAllEntrants();
    });
    Fire.$on("loadConstClassId", () => {
      this.constGetAllEntrunts();
    });
  },
};
</script>

<style></style>
