var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"}),_vm._v(" "),_c('div',{staticClass:"card-body p-0",staticStyle:{"overflow-x":"auto"}},[_c('table',{staticClass:"table table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"5px"}},[_vm._v("№")]),_vm._v(" "),_c('th',[_vm._v("Овог нэр")]),_vm._v(" "),_vm._l((_vm.allSchoolClassCourses),function(allSchoolClassCourse,index){return _c('th',{key:index,staticClass:"col-span-3"},[_vm._v("\n                "+_vm._s(allSchoolClassCourse.subject_short_name)+"\n              ")])}),_vm._v(" "),_c('th',[_vm._v("Үйлдлүүд")])],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.allStudents),function(allStudent,indexStudentRow){return _c('tr',{key:indexStudentRow},[_c('td',[_vm._v(_vm._s(indexStudentRow + 1))]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(allStudent.profile_student_last_name[0])+_vm._s(allStudent.profile_student_last_name[1])+"."+_vm._s(allStudent.profile_student_name)+"\n              ")]),_vm._v(" "),_vm._l((_vm.allSchoolClassCourses),function(allSchoolClassCourse,indexCourseColumn){return _c('td',{key:indexCourseColumn},_vm._l((_vm.allStudentsGrades),function(allStudentsGrade,indexGrade){return _c('span',{key:indexGrade},[(
                      allSchoolClassCourse.course_id ===
                        allStudentsGrade.grade_course_id &&
                      allSchoolClassCourse.course_subject_id ===
                        allStudentsGrade.grade_subject_id &&
                      allStudent.grade_student_id ===
                        allStudentsGrade.grade_student_id
                    )?_c('span',{staticClass:"badge bg-info"},[_vm._v(_vm._s(allStudentsGrade["grade_exam"]))]):_vm._e()])}),0)}),_vm._v(" "),_c('td',[_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:('Дүнгийн тодорхойлолт! Тун удахгүй.'),expression:"'Дүнгийн тодорхойлолт! Тун удахгүй.'",modifiers:{"hover":true}}],staticClass:"btn btn-warning btn-sm",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.dungiinTod(allStudent)}}},[_c('b-icon',{attrs:{"icon":"file-earmark","variant":"success"}})],1),_vm._v(" "),_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(
                    'Суралцдаг нь үнэн тодорхойлолт! Тун удахгүй.'
                  ),expression:"\n                    'Суралцдаг нь үнэн тодорхойлолт! Тун удахгүй.'\n                  ",modifiers:{"hover":true}}],staticClass:"btn btn-info btn-sm",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.surdagUnen(allStudent)}}},[_c('b-icon',{attrs:{"icon":"file-earmark-ruled","variant":"danger"}})],1)])],2)}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }