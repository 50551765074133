<template>
  <div class="col-12 pt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <multiselect
        class="mr-2"
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="1-р алхам-Улирал сонголт"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="department_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="department_id"
        label="department_name"
        placeholder="2-р алхам-Мэргэжлийн баг сонголт"
        :options="mySchoolsDepartments"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.department_name }} </strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="employees_user_id"
        label="teacher_name"
        :custom-label="customLabelTeacherName"
        placeholder="3-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.teacher_last_name[0]
            }}{{ option.teacher_last_name[1] }}.{{
              option.teacher_name
            }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="4-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="exam_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="exam_id"
        label="name"
        :custom-label="customLabelExam"
        placeholder="5-р алхам-Шалгалт сонголт"
        :options="mySchoolCoursesExam"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.exam_name }}</strong></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button>
    </nav>
    <every-student
      v-if="selectedCourseData"
      :selectedLessonExamData="selectedLessonExamData"
    ></every-student>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudent from "./ExamStatForManagers/everyStudent.vue";
export default {
  components: {
    Multiselect,
    everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      mySchoolQuarters: [],
      mySchoolsDepartments: [],
      mySchoolsTeachers: [],
      mySchoolCourses: [],
      mySchoolCoursesExam: [],
      // Дуудах request
      quarter_id: "",
      department_id: "",
      teacher_id: "",
      course_id: "",
      exam_id: "",
      //Pass to child component
      selectedCourseData: false,
      selectedLessonExamData: [],
    };
  },
  watch: {
    department_id: function (newValue, oldValue) {
      //   console.log("Өөрчлөгдсөн.");
      //   this.selectedCourseData = false;
      //   this.selectedExamData = false;
      //   this.selectedQuarterData = false;
      axios
        .post("/manager/departmentsTeacher", {
          department: this.department_id,
        })
        .then((response) => {
          this.mySchoolsTeachers = [];
          this.mySchoolsTeachers = response.data.mySchoolsTeachers;
          //   this.selectedLessonExamData.forEach((el) => {
          //     el.examAverage =
          //       (parseFloat(el.exam1) +
          //         parseFloat(el.exam2) +
          //         parseFloat(el.exam3) +
          //         parseFloat(el.exam4) +
          //         parseFloat(el.exam5) +
          //         parseFloat(el.exam6) +
          //         parseFloat(el.exam7) +
          //         parseFloat(el.exam8)) /
          //       8;
          //   });
          //   console.log(this.selectedLessonExamData);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    teacher_id: function (newValue, oldValue) {
      axios
        .post("/manager/getMySchoolCourses", {
          teacher: this.teacher_id,
        })
        .then((response) => {
          this.mySchoolCourses = [];
          this.mySchoolCourses = response.data.mySchoolCourses;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    course_id: function (newValue, oldValue) {
      axios
        .post("/manager/mySchoolCourseExams", {
          myCourseData: this.course_id,
        })
        .then((response) => {
          this.mySchoolCoursesExam = [];
          this.mySchoolCoursesExam = response.data.mySchoolCoursesExam;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // quarter_id: function (newValue, oldValue) {
    //   axios
    //     .post("/manager/mySchoolCourseExamsQuarter", {
    //       myCourseData: this.course_id,
    //       quarter: newValue,
    //       teacher: this.teacher_id,
    //     })
    //     .then((response) => {
    //       this.mySchoolCoursesExam = [];
    //       this.mySchoolCoursesExam = response.data.mySchoolCoursesExam;
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
  },
  methods: {
    getMySchoolExamStatData() {
      axios
        .get("/manager/mySchoolExamsStatData")
        .then((response) => {
          //   console.log(response.data);
          this.quarter_id = response.data.currentQuarter;
          this.mySchoolsDepartments = response.data.mySchoolDepartments;
          this.mySchoolQuarters = response.data.mySchoolsQuarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getSelectMyExamData() {
      this.selectedCourseData = false;
      axios
        .post("/manager/mySelectStatData", {
          quarter_id: this.quarter_id,
          department_id: this.department_id,
          teacher_id: this.teacher_id,
          course_id: this.course_id,
          exam_id: this.exam_id,
        })
        .then((response) => {
          //   console.log(response.data);
          this.selectedLessonExamData = [];
          this.selectedLessonExamData = response.data.selectedLessonExamData;
          this.selectedLessonExamData.forEach((el) => {
            el.examAverage =
              parseFloat(el.exam1) * 0.1 +
              parseFloat(el.exam2) * 0.1 +
              parseFloat(el.exam3) * 0.1 +
              parseFloat(el.exam4) * 0.1 +
              parseFloat(el.exam5) * 0.2 +
              parseFloat(el.exam6) * 0.2 +
              parseFloat(el.exam7) * 0.1 +
              parseFloat(el.exam8) * 0.1;

            if (el.examAverage >= 97) {
              el.gpa = this.symbolGrade = "A+, GPA 4.33 or 4.00";
            } else if (el.examAverage >= 93) {
              el.gpa = this.symbolGrade = "A, GPA 4.00";
            } else if (el.examAverage >= 90) {
              el.gpa = this.symbolGrade = "A-, GPA 3.67";
            } else if (el.examAverage >= 87) {
              el.gpa = this.symbolGrade = "B+, GPA 3.33";
            } else if (el.examAverage >= 83) {
              el.gpa = this.symbolGrade = "B, GPA 3.00";
            } else if (el.examAverage >= 80) {
              el.gpa = this.symbolGrade = "B-, GPA 2.67";
            } else if (el.examAverage >= 77) {
              el.gpa = this.symbolGrade = "C+, GPA 2.33";
            } else if (el.examAverage >= 73) {
              el.gpa = this.symbolGrade = "C, GPA 2.00";
            } else if (el.examAverage >= 70) {
              el.gpa = this.symbolGrade = "C-, GPA 1.67";
            } else if (el.examAverage >= 67) {
              el.gpa = this.symbolGrade = "D+, GPA 1.33";
            } else if (el.examAverage >= 63) {
              el.gpa = this.symbolGrade = "D, GPA 1.00";
            } else if (el.examAverage >= 60) {
              el.gpa = this.symbolGrade = "D-, GPA 0.67";
            } else {
              el.gpa = this.symbolGrade = "F, GPA 0.00";
            }
          });

          //   console.log(this.selectedLessonExamData);
          this.selectedCourseData = true;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelTeacherName({ teacher_name, teacher_last_name }) {
      return `${teacher_last_name[0]}${teacher_last_name[1]} . ${teacher_name}`;
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
    customLabelExam({ exam_name, exam_type }) {
      return `${exam_name} – ${exam_type}`;
    },
  },
  created() {
    this.getMySchoolExamStatData();
  },
};
</script>

<style>
</style>
