<template>
  <div class="card card-success card-outline card-outline-tabs mt-3">
    <div class="card-header">
      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="calendar-tab"
            data-toggle="pill"
            href="#calendar"
            role="tab"
            aria-controls="calendar"
            aria-selected="true"
            >Соёл хүмүүжлийн ажил</a
          >
        </li>
        <li class="nav-item">
          <a
            @click="getMyComments()"
            class="nav-link"
            id="homework-tab"
            data-toggle="pill"
            href="#homework"
            role="tab"
            aria-controls="homework"
            aria-selected="false"
            >Гэрийн даалгавар</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="courseComment-tab"
            data-toggle="pill"
            href="#courseComment"
            role="tab"
            aria-controls="courseComment"
            aria-selected="false"
            >Хичээлийн ажиглалт, тэмдэглэл</a
          >
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="calendar"
          role="tabpanel"
          aria-labelledby="calendar-tab"
        >
          <cultural></cultural>
        </div>
        <div
          class="tab-pane fade show"
          id="homework"
          role="tabpanel"
          aria-labelledby="homework-tab"
        >
          <div class="card card-primary card-tabs">
            <div class="card-header p-0 pt-1">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-undone-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-undone-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-undone-homework"
                    aria-selected="true"
                    >Хүлээгдэж буй</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-done-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-done-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-done-homework"
                    aria-selected="false"
                    >Гүйцэтгэсэн</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-one-noted-homework-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-noted-homework"
                    role="tab"
                    aria-controls="custom-tabs-one-noted-homework"
                    aria-selected="false"
                    >Эцэг эх, хүүхэд тэмдэглэл оруулсан.</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="col-md-8">
                <b-container class="bv-example-row" fluid>
                  <b-row
                    ><label>Улирал сонгох:</label>
                    <b-col sm="2"
                      ><b-form-select
                        name="selectedQuarter"
                        v-model="selectedQuarter"
                        :options="quarterOptions"
                        text-field="name"
                        value-field="id"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('selectedQuarter'),
                          },
                        ]"
                        @input="getMyComments"
                      ></b-form-select>
                    </b-col>
                    <label>Даалгавар хаагдах огноо</label>
                    <b-col sm="3">
                      <b-form-datepicker
                        size="sm"
                        v-model="hwEndDate"
                        class="mb-2"
                        :class="['form-control']"
                        @input="getMyComments"
                        placeholder="Даалгавар хаагдах огноо"
                      ></b-form-datepicker>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="tab-content" id="custom-tabs-one-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-one-undone-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-undone-homework-tab"
                >
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-atlas"></i>
                        Хүлээгдэж буй гэрийн даалгавар:
                        <b class="text-danger">
                          {{ myCourseHomeworkunDone.length }}
                        </b>
                        байна.
                      </h3>
                      <h3 class="card-title float-right">
                        Нийт даалгаврын гүйцэтгэх хугацаа:
                        <b class="text-warning"> {{ sumSpendHW }} минут</b>
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageUndoneHW"
                                :options="pageOptionsUnDoneHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterUnDoneHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterUnDoneHW"
                                    @click="filterUnDoneHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageUndoneHW"
                          :total-rows="totalRowsUndoneHW"
                          :per-page="perPageUndoneHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myCourseHomeworkunDone"
                          :fields="fieldsUndoneHW"
                          :current-page="currentPageUndoneHW"
                          :per-page="perPageUndoneHW"
                          :filter="filterUnDoneHW"
                          :filter-ignored-fields="filterIgnoredFieldsUnDoneHW"
                          @filtered="onFilteredUndoneHW"
                          :filter-included-fields="filterOnUnDoneHW"
                        >
                          <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-warning">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 0
                                    "
                                    style="float: right"
                                    variant="warning"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                      <!-- <div
                    class="callout callout-warning"
                    v-for="(unDoneHw, i) in myCourseHomeworkunDone"
                    :key="i"
                  >
                    <div class="row">
                      <div class="col-md-10">
                        <span style="font-size: 80%">
                          <b> Бүртгэсэн: </b>
                          {{ unDoneHw.created_at | dateYearMonthDay }}
                        </span>
                        <br />
                        <p style="font-size: 80%">
                          <b>Суралцагч:</b>
                          {{
                            unDoneHw.student_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ unDoneHw.student_name }}
                          <br />
                          <b>Эхлэх:</b>
                          {{ unDoneHw.course_hw_start_date | dateYearMonthDay }}
                          {{ unDoneHw.course_hw_start_time }}
                          <br />
                          <b>Дуусах:</b>
                          {{ unDoneHw.course_hw_end_date | dateYearMonthDay }}
                          {{ unDoneHw.course_hw_end_time }}
                          <br />
                          <b>Багш:</b>
                          {{
                            unDoneHw.employee_teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ unDoneHw.employee_teacher_name }}
                          <br />
                          <b> Цаг: </b>
                          {{ unDoneHw.course_hw_course_hour }}-р цаг
                          <br />
                          <b>Хичээл:</b>
                          {{ unDoneHw.subject_name }}
                        </p>
                      </div>
                      <div class="col-md-2">
                        <span style="font-size: 80%">
                          <b-iconstack
                            font-scale="2"
                            v-show="unDoneHw.course_hw_stud_hw_status == 0"
                            style="float: right"
                            variant="warning"
                            @click.prevent="viewMoreInfo(unDoneHw)"
                          >
                            <b-icon stacked icon="square"></b-icon>
                            <b-icon
                              stacked
                              icon="bell-fill"
                              scale="0.75"
                            ></b-icon>
                          </b-iconstack>
                          <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon>
                        </span>
                      </div>
                    </div>
                  </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-one-done-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-done-homework-tab"
                >
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-bullhorn"></i>
                        Багш гэрийн даалгаварыг үнэлсэн:
                        {{ myCourseHomeworkDone.length }}
                        байна.
                      </h3>
                      <h3 class="card-title float-right">
                        Нийт даалгаврын гүйцэтгэсэн хугацаа:
                        <b class="text-success"> {{ sumSpendHWDone }} минут </b>
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageDoneHW"
                                :options="pageOptionsDoneHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterDoneHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterDoneHW"
                                    @click="filterDoneHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageDoneHW"
                          :total-rows="totalRowsDoneHW"
                          :per-page="perPageDoneHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myCourseHomeworkDone"
                          :fields="fieldsDoneHW"
                          :current-page="currentPageDoneHW"
                          :per-page="perPageDoneHW"
                          :filter="filterDoneHW"
                          :filter-ignored-fields="filterIgnoredFieldsDoneHW"
                          @filtered="onFilteredDoneHW"
                          :filter-included-fields="filterOnDoneHW"
                        >
                          <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-success">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 1
                                    "
                                    style="float: right"
                                    variant="success"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                      <!-- <div
                    class="callout callout-success"
                    v-for="(unDoneHw, i) in myCourseHomeworkDone"
                    :key="i"
                  >
                    <div class="row">
                      <div class="col-md-10">
                        <span style="font-size: 80%">
                          <b> Бүртгэсэн: </b>
                          {{ unDoneHw.created_at | dateYearMonthDay }}
                        </span>
                        <br />
                        <p style="font-size: 80%">
                          <b>Суралцагч:</b>
                          {{
                            unDoneHw.student_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ unDoneHw.student_name }}
                          <br />
                          <b>Эхлэх:</b>
                          {{ unDoneHw.course_hw_start_date | dateYearMonthDay }}
                          {{ unDoneHw.course_hw_start_time }}
                          <br />
                          <b>Дуусах:</b>
                          {{ unDoneHw.course_hw_end_date | dateYearMonthDay }}
                          {{ unDoneHw.course_hw_end_time }}
                          <br />
                          <b>Багш:</b>
                          {{
                            unDoneHw.employee_teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ unDoneHw.employee_teacher_name }}
                          <br />
                          <b> Цаг: </b>
                          {{ unDoneHw.course_hw_course_hour }}-р цаг
                          <br />
                          <b>Хичээл:</b>
                          {{ unDoneHw.subject_name }}
                          <br />
                          <b>Үнэлгээ:</b>
                          {{ unDoneHw.course_hw_stud_hw_grade }}
                        </p>
                      </div>
                      <div class="col-md-2">
                        <span style="font-size: 80%">
                          <b-iconstack
                            font-scale="2"
                            v-show="unDoneHw.course_hw_stud_hw_status == 1"
                            style="float: right"
                            variant="success"
                            @click.prevent="viewMoreInfo(unDoneHw)"
                          >
                            <b-icon stacked icon="square"></b-icon>
                            <b-icon
                              stacked
                              icon="bell-fill"
                              scale="0.75"
                            ></b-icon>
                          </b-iconstack>
                          <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon>
                        </span>
                      </div>
                    </div>
                  </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-one-noted-homework"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-noted-homework-tab"
                >
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <b-icon icon="pencil-square"></b-icon>
                        Тэмдэглэл оруулсан:
                        {{ myCourseHomeworkNoted.length }}
                        байна.
                      </h3>
                    </div>
                    <div class="card-body">
                      <b-overlay :show="homeWorkLoaded" rounded="sm">
                        <b-row>
                          <b-col lg="12" class="my-1">
                            <b-form-group
                              label="Нэг хуудсанд"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="perPageNotedHW"
                                :options="pageOptionsNotedHW"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>

                            <b-form-group
                              label="Хайх утга!"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="filterNotedHW"
                                  type="search"
                                  placeholder="Хайлт хийх утгаа оруулна уу!"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    :disabled="!filterNotedHW"
                                    @click="filterNotedHW = ''"
                                    >Цэвэрлэх</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-pagination
                          v-model="currentPageNotedHW"
                          :total-rows="totalRowsNotedHW"
                          :per-page="perPageNotedHW"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                        <b-table
                          hover
                          :items="myCourseHomeworkNoted"
                          :fields="fieldsNotedHW"
                          :current-page="currentPageNotedHW"
                          :per-page="perPageNotedHW"
                          :filter="filterNotedHW"
                          :filter-ignored-fields="filterIgnoredFieldsNotedHW"
                          @filtered="onFilteredNotedHW"
                          :filter-included-fields="filterOnNotedHW"
                        >
                          <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>

                          <template #cell(subject_short_name)="data">
                            <div class="row callout callout-warning">
                              <div class="col-md-10">
                                <span style="font-size: 80%">
                                  <b>Хичээл:</b>
                                  <b-badge variant="info">
                                    {{ data.item.subject_name }}
                                  </b-badge>
                                  <br />
                                  <b> Бүртгэсэн: </b>
                                  {{ data.item.created_at | dateYearMonthDay }}
                                </span>
                                <br />
                                <p style="font-size: 80%">
                                  <b>Суралцагч:</b>
                                  {{
                                    data.item.student_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.student_name }}
                                  <br />
                                  <b>Эхлэх:</b>
                                  {{
                                    data.item.course_hw_start_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_start_time }}
                                  <br />
                                  <b>Дуусах:</b>
                                  {{
                                    data.item.course_hw_end_date
                                      | dateYearMonthDay
                                  }}
                                  {{ data.item.course_hw_end_time }}
                                  <br />
                                  <b>Тухайн даалгавар гүйцэтгэх хугацаа:</b>
                                  {{ data.item.spend_minute }} минут
                                  <br />
                                  <b>Үнэлгээ:</b>
                                  {{ data.item.course_hw_stud_hw_grade }}
                                  <br />
                                  <b>Багш:</b>
                                  {{
                                    data.item.employee_teacher_last_name
                                      | capitalizeAndEkhniiVseg
                                  }}.
                                  {{ data.item.employee_teacher_name }}
                                  <br />
                                  <b> Цаг: </b>
                                  {{ data.item.course_hw_course_hour }}-р цаг
                                  <br />
                                </p>
                              </div>
                              <div class="col-md-2">
                                <span style="font-size: 80%">
                                  <b-iconstack
                                    font-scale="2"
                                    v-show="
                                      data.item.course_hw_stud_hw_status == 0
                                    "
                                    style="float: right"
                                    variant="warning"
                                    @click.prevent="viewMoreInfo(data.item)"
                                  >
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon
                                      stacked
                                      icon="bell-fill"
                                      scale="0.75"
                                    ></b-icon>
                                  </b-iconstack>
                                  <!-- <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon> -->
                                </span>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </b-overlay>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
            <b-modal
              size="xl"
              id="modal-homework-student"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
              :body-bg-variant="bodyBgVariant"
              title="Гэрийн даалгаврын гүйцэтгэл"
              class="modal fade"
              hide-footer
              @hidden="resetFormHwGradeStudent"
            >
              <div class="modal-body">
                <div class="direct-chat-msg">
                  <div class="direct-chat-infos clearfix">
                    <span class="direct-chat-name float-left">
                      Гэрийн даалгавар/Homework</span
                    >
                    <span class="direct-chat-timestamp float-right"
                      ><b>Даалгавар эхлэх огноо: </b
                      >{{
                        selectHWStudents.course_hw_start_date | dateYearMonthDay
                      }}
                      -
                      {{ selectHWStudents.course_hw_start_time }}
                      цаг</span
                    ><br />
                    <span class="direct-chat-timestamp float-right"
                      ><b>Даалгавар дуусах огноо: </b>
                      {{
                        selectHWStudents.course_hw_end_date | dateYearMonthDay
                      }}
                      -
                      {{ selectHWStudents.course_hw_end_time }}
                      цаг</span
                    >
                  </div>
                  <div class="direct-chat-text primary ml-0">
                    <label> Гэрийн даалгавар</label>
                    <!-- <quill-editor
                      v-model="selectHWStudents.hw"
                      :options="editorOptionStudent"
                    >
                    </quill-editor> -->
                    <div v-html="selectHWStudents.hw"></div>
                  </div>
                  <div
                    class="direct-chat-text primary ml-0"
                    v-if="teacherCommentCheck !== ''"
                  >
                    <label> Багшийн тайлбар</label>
                    <quill-editor
                      v-model="teacherCommentCheck"
                      :options="editorOptionStudent"
                    >
                    </quill-editor>
                  </div>
                  <div class="direct-chat-text primary ml-0">
                    <label> Миний тэмдэглэл</label>
                    <quill-editor
                      v-model="form.hw_student_comment"
                      :options="editorOptionStudentPlus"
                    >
                    </quill-editor>
                  </div>
                  <b-button
                    variant="outline-primary"
                    @click="saveHWStudentExplain()"
                    >Хадгалах</b-button
                  >
                </div>
              </div>
            </b-modal>
          </div>
        </div>
        <div
          class="tab-pane fade show"
          id="courseComment"
          role="tabpanel"
          aria-labelledby="courseComment-tab"
        >
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <i class="fas fa-bullhorn"></i>
                Сургалтын үйл ажиллагаанд оролцох явцад ажилглагдсан ахиц,
                амжилт, давуу ба сул тал, анхаарах зүйлсийн талаар багш нарын
                өгсөн мэдээлэл.
              </h3>
            </div>
            <div class="card-body">
              <div
                class="callout callout-warning"
                v-for="(readComm, i) in myCourseCommentRead"
                :key="i"
              >
                <div class="row">
                  <div class="col-md-6">
                    <span style="font-size: 120%">
                      {{ readComm.subject_name }}
                      {{ readComm.teacher_last_name[0] }}.
                      {{ readComm.teacher_name }}
                      багш
                    </span>
                    <span style="font-size: 80%">
                      {{ readComm.created_at | dateYearMonthDay }}</span
                    >
                  </div>
                </div>

                <p style="font-size: 80%">
                  <b
                    >{{ readComm.student_last_name }}
                    {{ readComm.student_name }}:
                  </b>
                  <quill-editor
                    v-model="readComm.comments"
                    :options="editorOptionStudent"
                    placeholder="Тэмдэглэл"
                  >
                  </quill-editor>
                </p>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cultural from "../Project/CulturalCalendar.vue";
export default {
  data() {
    return {
      myCourseCommentRead: [],
      myCourseHomeworkunDone: [],
      myCourseHomeworkDone: [],
      myCourseHomeworkNoted: [],
      quarterOptions: [],
      selectedQuarter: 0,
      selectHWStudents: "",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },

      form: new Form({
        id: "",
        course_hw_id: "",
        student_id: "",
        hw_student_comment: "",
        teacher_id: "",
        student_class: "",
        student_name: "",
        student_last_name: "",
      }),
      teacherCommentCheck: "",
      hwEndDate: "",
      sumSpendHW: 0,
      sumSpendHWDone: 0,
      editorOptionStudentPlus: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: "Гэрийн даалгаварын гүйцэтгэлд тайлбар өгөх...",
      },

      homeWorkLoaded: true,
      // Хүснэгтийн тохиргоо
      totalRowsUndoneHW: 1,
      currentPageUndoneHW: 1,
      perPageUndoneHW: 10,
      pageOptionsUnDoneHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsUnDoneHW: ["course_hw_stud_hw_parent_comment"],
      filterUnDoneHW: null,
      filterOnUnDoneHW: [],
      fieldsUndoneHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],

      //   Хийсэн
      totalRowsDoneHW: 1,
      currentPageDoneHW: 1,
      perPageDoneHW: 10,
      pageOptionsDoneHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      filterIgnoredFieldsDoneHW: ["course_hw_stud_hw_parent_comment"],
      filterDoneHW: null,
      filterOnDoneHW: [],
      fieldsDoneHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],
      //   Тэмдэглэл хийсэн
      totalRowsNotedHW: 1,
      currentPageNotedHW: 1,
      perPageNotedHW: 10,
      pageOptionsNotedHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      filterIgnoredFieldsNotedHW: ["course_hw_stud_hw_parent_comment"],
      filterNotedHW: null,
      filterOnNotedHW: [],
      fieldsNotedHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "subject_short_name",
          label: "Дэлгэрэнгүй",
          sortable: true,
        },
      ],
    };
  },
  components: {
    cultural: cultural,
  },
  methods: {
    //b-table тохирго
    onFilteredUndoneHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsUndoneHW = filteredItems.length;
      this.currentPageUndoneHW = 1;
    },
    onFilteredDoneHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDoneHW = filteredItems.length;
      this.currentPageDoneHW = 1;
    },
    onFilteredNotedHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsNotedHW = filteredItems.length;
      this.currentPageNotedHW = 1;
    },
    getSchoolQuarters() {
      axios
        .get("/student/getSchoolQuarters")
        .then((response) => {
          this.quarterOptions = response.data.schoolquarters;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/student/getCurrentQuarter")
        .then((response) => {
          this.selectedQuarter = response.data.currentQuarter.id;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getMyComments() {
      this.homeWorkLoaded = true;
      this.sumSpendHW = 0;
      this.sumSpendHWDone = 0;
      axios
        .post("/student/getMyComments", {
          selectedQuarter: this.selectedQuarter,
          hwEndDate: this.hwEndDate,
        })
        .then((res) => {
          this.myCourseCommentRead = res.data.myCourseCommentRead;
          this.myCourseHomeworkunDone = res.data.myCourseHomeworkunDone;
          this.totalRowsUndoneHW = this.myCourseHomeworkunDone.length;
          this.myCourseHomeworkDone = res.data.myCourseHomeworkDone;
          this.totalRowsDoneHW = this.myCourseHomeworkDone.length;
          this.myCourseHomeworkNoted = res.data.myCourseHomeworkNoted;
          this.totalRowsNotedHW = this.myCourseHomeworkNoted.length;
          this.myCourseHomeworkDone.forEach((element) => {
            if (element.spend_minute)
              this.sumSpendHWDone += element.spend_minute;
          });
          this.myCourseHomeworkunDone.forEach((element) => {
            if (element.spend_minute) this.sumSpendHW += element.spend_minute;
          });
          this.homeWorkLoaded = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    viewMoreInfo(infos) {
      //   console.log(infos);
      this.form.course_hw_id = infos.course_hw_id;
      this.form.student_id = infos.course_hw_stud_id;
      this.form.teacher_id = infos.course_hw_teacher_id;
      this.form.school_class_id = infos.course_hw_school_class_id;
      this.form.student_last_name = infos.student_last_name;
      this.form.student_name = infos.student_name;
      this.form.hw_student_comment = infos.course_hw_stud_hw_student_comment;
      this.teacherCommentCheck = infos.course_hw_teacher_explain;
      axios
        .post("/student/getMyHW", {
          info: infos,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectHWStudents = res.data.selectHWStudents;
        })
        .catch((err) => console.log(err));
      this.$bvModal.show("modal-homework-student");
      //   console.log(infos);
    },
    saveHWStudentExplain() {
      this.form
        .post("/student/saveHWStudentExplain")
        .then((res) => {
          if (res.data.info.code === "success") {
            Fire.$emit("readComment");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if ((res.info.code = "error")) {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.$bvModal.hide("modal-homework-student");
        })
        .catch((err) => console.log(err));
    },
    resetFormHwGradeStudent() {
      this.selectHWStudents = "";
      this.teacherCommentCheck = "";
      this.form.reset();
    },
  },
  created() {
    this.getSchoolQuarters();
    Fire.$on("readComment", () => {
      this.getMyComments();
    });
  },
};
</script>

<style></style>
