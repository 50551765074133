<template>
  <form class="form-inline ml-30">
    <b-popover target="popover-target-1" triggers="hover" placement="top">
      <template #title>Хайх утга</template>
      Суралцагчийн <b>нэр, имэйл, анги</b>-аар хайх боломжтой
    </b-popover>
    <div class="input-group input-group-sm">
      <input
        class="form-control form-control-navbar"
        type="search"
        placeholder="Хайх утгаа оруулна уу?"
        aria-label="Search"
        v-model="keyword"
        id="popover-target-1"
      />

      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      keyword: null,
      getSearchstudents: [],
    };
  },
  watch: {
    keyword(after, before) {
      this.searchstudents();
    },
  },
  methods: {
    searchstudents() {
      axios
        .get("/manager/studentsearch", {
          params: { keyword: this.keyword },
        })
        .then((response) => {
          //   console.log(response.data.teachers);
          this.getSearchstudents = response.data.students;
          //   console.log(this.getSearchstudents);
          this.$emit("getWasSearchStudents", this.getSearchstudents);
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
};
</script>

<style>
</style>
