<template>
  <div class="pt-3">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Сургуулийн улирал</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="dataTables_wrapper dt-bootstrap4">
          <div class="row">
            <div class="col-sm-8">
              <table
                class="table table-bordered table-hover table-striped dataTable dtr-inline"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Улирал</th>
                    <th>Дүн гаргах</th>
                    <th>Сарууд</th>
                    <th>Үүсгэсэн</th>
                    <th>Зассан</th>
                    <th>Үйлдлүүд</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="odd"
                    v-for="schoolquarter in schoolquarters"
                    :key="schoolquarter.id"
                  >
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ schoolquarter.name }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="success"
                        v-if="schoolquarter.locked == 1"
                      >
                        Тийм
                      </b-badge>
                      <b-badge variant="warning" v-else> Үгүй </b-badge>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ schoolquarter.months }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ schoolquarter.created_at | dateFullYear }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ schoolquarter.updated_at | dateFullYear }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <a
                        href="#"
                        class="badge bg-purple"
                        @click="viewSchoolQuarter(schoolquarter)"
                        ><i class="far fa-eye"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-warning"
                        @click="editSchoolQuarter(schoolquarter)"
                        ><i class="fas fa-edit"></i
                      ></a>

                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="deleteSchoolQuarter(schoolquarter)"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-4">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editMode">Улирал бүртгэх</h3>
                  <h3 class="card-title" v-show="editMode">Улирал засах</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form
                  @submit.prevent="
                    !editMode ? createSchoolQuarter() : updateSchoolQuarter()
                  "
                >
                  <div class="card-body">
                    <div class="form-group">
                      <label>Улирал</label>
                      <input
                        type="text"
                        name="name"
                        v-model="form.name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('name'),
                          },
                        ]"
                        placeholder="Улирал"
                      />
                      <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Сар</label>
                      <multiselect
                        v-model="form.selected_months"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        placeholder="Сар сонгоно уу?"
                        deselect-label="Хасах"
                        :options="months"
                        :searchable="false"
                        :allow-empty="false"
                        :close-on-select="false"
                        :multiple="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option }}
                            сар
                          </strong>
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <label>Түгжих</label>
                      <b-form-checkbox
                        v-model="form.locked"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                      >
                        Энэ улиралд дүн гаргах бол заавал сонгоно.
                      </b-form-checkbox>
                    </div>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-show="!editMode"
                    >
                      Хадгалах
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-show="editMode"
                    >
                      Засах
                    </button>
                    <button
                      class="btn btn-warning"
                      v-show="editMode"
                      @click.prevent="cancelEdit"
                    >
                      Болих
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      action: "",
      editMode: false,
      schoolquarters: [],
      form: new Form({
        id: "",
        name: "",
        locked: "",
        school_id: "",
        selected_months: [],
      }),
      months: [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  methods: {
    getAllSchoolQuarters() {
      axios
        .get("/manager/getAllSchoolQuarters")
        .then((response) => {
          //   console.log(response);
          this.schoolquarters = response.data.schoolquarters;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewSchoolQuarter(schoolquarter) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolquarter);
    },
    editSchoolQuarter(schoolquarter) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolquarter);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolquarter);
      this.form.selected_months = [];
      let mList = schoolquarter.months.split("-");
      mList.forEach((val) => {
        if (val != "") this.form.selected_months.push(parseInt(val));
      });
    },
    updateSchoolQuarter() {
      this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/quarter/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("loadSchoolQuarters");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteSchoolQuarter(schoolquarter) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolquarter);
    },
    createSchoolQuarter() {
      this.action = "Ангийг үүсгэж байна...";
      this.form
        .post("/manager/quarter")
        .then((response) => {
          this.$toastr.s("Хичээлийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolQuarters");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Хичээлийг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllSchoolQuarters();
    Fire.$on("loadSchoolQuarters", () => {
      this.getAllSchoolQuarters();
    });
  },
};
</script>

<style></style>
