<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Гэрээний төрөл</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addContractType()"
                    >Гэрээний төрөл бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт гэрээний төрлийн тоо:
                      {{ contractWorkTypes.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="contractStatusLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageRooms"
                            :options="pageOptionsRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterRooms"
                                @click="filterRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageContractTypes"
                      :total-rows="totalRowsContractTypes"
                      :per-page="perPageRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="contractWorkTypes"
                      :fields="fieldsActiveContractTypes"
                      :current-page="currentPageContractTypes"
                      :per-page="perPageRooms"
                    >
                      <!-- :filter="filterRooms"
                      :filter-included-fields="filterOnRooms"
                      @filtered="onFilteredRooms" -->
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge variant="info">
                          {{ data.item.name }}
                        </b-badge>
                      </template>
                      <template #cell(description)="data">
                        <b-badge variant="secondary">
                          {{ data.item.description }}
                        </b-badge>
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editContractType(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteContractType(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong>Гэрээний төрөл</strong>
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт устгагдсан гэрээний төрлийн тоо:
                      {{ contractWorkTypesDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="contractStatusLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveRooms"
                            :options="pageOptionsInactiveDocumentStatus"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-inactive-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-inactive-input"
                              v-model="filterInactiveRooms"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterInactiveRooms"
                                @click="filterInactiveRooms = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveRooms"
                      :total-rows="totalRowsInactiveContractTypesDeleted"
                      :per-page="perPageInactiveRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="contractWorkTypesDeleted"
                      :fields="fieldsInactiveDocumentStatus"
                      :current-page="currentPageInactiveRooms"
                      :per-page="perPageInactiveRooms"
                    >
                      <!-- :filter="filterInactiveRooms"
                      :filter-included-fields="filterOnInactiveRooms"
                      @filtered="onFilteredInactiveRooms" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge variant="info">
                          {{ data.item.name }}
                        </b-badge>
                      </template>
                      <template #cell(description)="data">
                        <b-badge variant="secondary">
                          {{ data.item.description }}
                        </b-badge>
                      </template>
                      <template #cell(icon)="data">
                        <b-icon
                          v-if="data.item.iconType == 'bootstrap'"
                          :icon="data.item.icon"
                        ></b-icon>
                        <i
                          v-if="data.item.iconType == 'favicon'"
                          :class="[data.item.icon, 'text-dark']"
                        ></i>
                        <b-badge variant="success">
                          {{ data.item.iconType }}:
                          {{ data.item.icon }}
                        </b-badge>
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreContractType(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Гэрээний төрөл бүртгэх хэсэг -->
    <b-modal
      size="lg"
      id="modal-create-contract-type"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="
        !editModeContractType
          ? 'Гэрээний төрөл бүртгэх'
          : ' Гэрээний төрөл засах'
      "
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="
                !editModeContractType
                  ? createSchoolContractType()
                  : updateSchoolContractType()
              "
            >
              <div class="form-group">
                <input
                  type="text"
                  v-model="form.name"
                  placeholder="Гэрээний төрөл"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has('name'),
                  }"
                />
              </div>
              <div class="form-group">
                <b-form-textarea
                  id="textarea"
                  v-model="form.description"
                  placeholder="Гэрээний төрлийн тайлбар"
                  rows="3"
                  max-rows="6"
                  :class="{
                    'is-invalid': form.errors.has('description'),
                  }"
                ></b-form-textarea>
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeContractType"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeContractType"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeContractType"
                @click.prevent="cancelEdit"
              >
                Цуцлах
              </button>
              <button
                class="btn btn-warning"
                v-show="!editModeContractType"
                @click.prevent="cancelCreate"
              >
                Болих
              </button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      iconTypes: [{ name: "favicon" }, { name: "bootstrap" }],
      //Төлөвийн хэсэг
      contractWorkTypes: [],
      contractWorkTypesDeleted: [],

      contractStatusLoaded: true,
      editModeContractType: false,

      //b-table тохиргоо
      totalRowsContractTypes: 1,
      currentPageContractTypes: 1,
      perPageRooms: 200,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterRooms: "",
      filterOnRooms: [],
      fieldsActiveContractTypes: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Төлөв",
          sortable: true,
        },
        {
          key: "description",
          label: "Төлөв",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveContractTypesDeleted: 1,
      currentPageInactiveRooms: 1,
      perPageInactiveRooms: 25,
      pageOptionsInactiveDocumentStatus: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveDocumentStatus: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Төлөв",
          sortable: true,
        },
        {
          key: "description",
          label: "Төлөв",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      form: new Form({
        id: "",
        name: "",
        description: "",
      }),
    };
  },
  components: { Multiselect },
  computed: {
    // items() {
    //   if (this.filterInactiveRooms != "") {
    //     this.contractWorkTypesDeleted.forEach((element) => {
    //       return element.room_employees.filter(
    //         (item) =>
    //           item.my_user_info.teacher_name.includes(this.filterInactiveRooms)
    //         //   ||
    //         //   item.lastname.includes(this.keyword) ||
    //         //   item.email.includes(this.keyword)
    //       );
    //     });
    //   } else {
    //     return this.contractWorkTypesDeleted;
    //   }
    // },
  },
  methods: {
    getAllContractTypes() {
      axios
        .get("/hr/getAllContractTypes")
        .then((res) => {
          //   console.log(response);
          this.contractWorkTypes = res.data.contractWorkTypes;
          this.contractWorkTypesDeleted = res.data.contractWorkTypesDeleted;

          this.totalRowsContractTypes = this.contractWorkTypes
            ? this.contractWorkTypes.length
            : 0;
          this.totalRowsInactiveContractTypesDeleted = this
            .contractWorkTypesDeleted
            ? this.contractWorkTypesDeleted.length
            : 0;

          this.contractStatusLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    // customLabel({ name, profile_student_last_name }) {
    //     return `${profile_student_last_name[0]}. ${name}`;
    // },

    //b-table тохирго
    onFilteredRooms(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsContractTypes = filteredItems.length;
      this.currentPageContractTypes = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveContractTypesDeleted = filteredItems.length;
      this.currentPageInactiveRooms = 1;
    },

    addContractType() {
      this.form.reset();
      this.$bvModal.show("modal-create-contract-type");
    },
    createSchoolContractType() {
      //   console.log("create");
      this.form
        .post("/hr/createSchoolContractType")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadContractTypes");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editContractType(room) {
      //   console.log(room);
      //exams_teacher_id, block_name,
      this.editModeContractType = true;
      this.form.fill(room);

      this.$bvModal.show("modal-create-contract-type");
    },
    updateSchoolContractType() {
      //   console.log("udpate");
      this.form
        .put("/hr/updateSchoolContractType")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadContractTypes");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeContractType = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-contract-type");
    },

    deleteContractType(room) {
      //   console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн сургуульд хамаарал бүхий бичиг баримтууд ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deleteContractType/" + room.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Сургуулийн гэрээний төрлийг амжилттай устгалаа.",
                "success"
              );
              Fire.$emit("loadContractTypes");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreContractType(room) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/restoreContractType/" + room.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн гэрээний төрлийг буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadContractTypes");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  watch: {
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.room_owner_employees = "";
    //   this.form.room_plate_face = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/manager/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
  },
  created() {
    this.getAllContractTypes();
    Fire.$on("loadContractTypes", () => {
      this.getAllContractTypes();
    });
    // Fire.$on("getExamTeachers", () => {
    //     this.getExamTeachers();
    // });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}
a {
  font-size: 60%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
