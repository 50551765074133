<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header"></div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                    <table class="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th style="width: 5px">№</th>
                                <th>Овог Нэр</th>
                                <th>Улирал 1</th>
                                <th>Улирал 2</th>
                                <th>Улирал 3</th>
                                <th>Улирал 4</th>
                                <th>Жил</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(examData, index) in changeSelectedLessonExamData" :key="examData.id">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ examData.student_last_name[0]
                                    }}{{ examData.student_last_name[1] }}.{{
                                        examData.student_name
                                    }}
                                </td>

                                <td>
                                    <span class="badge bg-success" v-if="examData.uliral_1 >= 0">
                                        {{ examData.uliral_1.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        (
                                        {{ symbolGradeCheck(examData.uliral_1) }}
                                        )</span>
                                </td>
                                <td>
                                    <span class="badge bg-success" v-if="examData.uliral_2 >= 0">
                                        {{ examData.uliral_2.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        (
                                        {{ symbolGradeCheck(examData.uliral_2) }}
                                        )</span>
                                </td>
                                <td>
                                    <span class="badge bg-success" v-if="examData.uliral_3 >= 0">
                                        {{ examData.uliral_3.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        (
                                        {{ symbolGradeCheck(examData.uliral_3) }}
                                        )</span>
                                </td>
                                <td>
                                    <span class="badge bg-success" v-if="examData.uliral_4 >= 0">
                                        {{ examData.uliral_4.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        (
                                        {{ symbolGradeCheck(examData.uliral_4) }}
                                        )</span>
                                </td>
                                <td>
                                    <span class="badge bg-success" v-if="examData.uliral_dundaj > 0">
                                        {{ examData.uliral_dundaj.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        (
                                        {{ symbolGradeCheck(examData.uliral_dundaj) }}
                                        )</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th style="width: 5px"></th>
                                <th style="text-align: right">Дундаж</th>
                                <th>
                                    <span class="badge bg-warning" v-if="quarter1CalsAvg > 0">
                                        {{ quarter1CalsAvg.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        ({{ symbolGradeCheck(quarter1CalsAvg) }})
                                    </span>
                                </th>
                                <th>
                                    <span class="badge bg-warning" v-if="quarter2CalsAvg > 0">
                                        {{ quarter2CalsAvg.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        ({{ symbolGradeCheck(quarter2CalsAvg) }})
                                    </span>
                                </th>
                                <th>
                                    <span class="badge bg-warning" v-if="quarter3CalsAvg > 0">
                                        {{ quarter3CalsAvg.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        ({{ symbolGradeCheck(quarter3CalsAvg) }})
                                    </span>
                                </th>
                                <th>
                                    <span class="badge bg-warning" v-if="quarter4CalsAvg > 0">
                                        {{ quarter4CalsAvg.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        ({{ symbolGradeCheck(quarter4CalsAvg) }})
                                    </span>
                                </th>
                                <th>
                                    <span class="badge bg-warning" v-if="yearAverageCalsAvg > 0">
                                        {{ yearAverageCalsAvg.toFixed(1) }}
                                        %</span>
                                    <span class="badge bg-info">
                                        ({{ symbolGradeCheck(yearAverageCalsAvg) }})
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th style="width: 5px">№</th>
                                <th style="text-align: right">Үнэлгээ</th>
                                <th style="text-align: center">1-р улирал</th>
                                <th style="text-align: center">2-р улирал</th>
                                <th style="text-align: center">3-р улирал</th>
                                <th style="text-align: center">4-р улирал</th>
                                <th style="text-align: center">Жил</th>
                            </tr>
                            <tr>
                                <th style="text-align: right">1</th>
                                <th style="text-align: right">A</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountA.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountA.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountA.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountA.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountA.length }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">2</th>
                                <th style="text-align: right">B</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountB.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountB.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountB.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountB.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountB.length }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">3</th>
                                <th style="text-align: right">C</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountC.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountC.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountC.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountC.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountC.length }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">4</th>
                                <th style="text-align: right">D</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountD.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountD.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountD.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountD.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountD.length }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">5</th>
                                <th style="text-align: right">F</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountF.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountF.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountF.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountF.length }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountF.length }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">6</th>
                                <th style="text-align: right">Success/Амжилт</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountSuccess.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountSuccess.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountSuccess.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountSuccess.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountSuccess.toFixed(1) }}
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align: right">7</th>
                                <th style="text-align: right">Quality/Чанар</th>
                                <th style="text-align: center">
                                    {{ examAverageUliral1CountQuality.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral2CountQuality.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral3CountQuality.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageUliral4CountQuality.toFixed(1) }}
                                </th>
                                <th style="text-align: center">
                                    {{ examAverageYearCountQuality.toFixed(1) }}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>
        <!-- /.col -->
    </div>
</template>

<script>
export default {
    props: ["selectedLessonExamData", "teacher_id", "course_id", "quarter_id"],
    data() {
        return {
            //Шалгах дата
            dataChecked: [],
            //props-оор орж ирсэн өгөгдлийг шинэчилж авна.
            changeSelectedLessonExamData: [],

            //Улирал 1
            examAverageUliral1CountA: [],
            examAverageUliral1CountB: [],
            examAverageUliral1CountC: [],
            examAverageUliral1CountD: [],
            examAverageUliral1CountF: [],
            examAverageUliral1Full: [],
            examCalsAvgUliral1Full: "",
            examAverageUliral1CountSuccess: 0,
            examAverageUliral1CountQuality: 0,
            //Улирал 2
            examAverageUliral2CountA: [],
            examAverageUliral2CountB: [],
            examAverageUliral2CountC: [],
            examAverageUliral2CountD: [],
            examAverageUliral2CountF: [],
            examAverageUliral2Full: [],
            examCalsAvgUliral2Full: "",
            examAverageUliral2CountSuccess: 0,
            examAverageUliral2CountQuality: 0,
            //Улирал 3
            examAverageUliral3CountA: [],
            examAverageUliral3CountB: [],
            examAverageUliral3CountC: [],
            examAverageUliral3CountD: [],
            examAverageUliral3CountF: [],
            examAverageUliral3Full: [],
            examCalsAvgUliral3Full: "",
            examAverageUliral3CountSuccess: 0,
            examAverageUliral3CountQuality: 0,
            //Улирал 4
            examAverageUliral4CountA: [],
            examAverageUliral4CountB: [],
            examAverageUliral4CountC: [],
            examAverageUliral4CountD: [],
            examAverageUliral4CountF: [],
            examAverageUliral4Full: [],
            examCalsAvgUliral4Full: "",
            examAverageUliral4CountSuccess: 0,
            examAverageUliral4CountQuality: 0,
            //Гурван улирлын дундаж
            examAverageYearCountA: [],
            examAverageYearCountB: [],
            examAverageYearCountC: [],
            examAverageYearCountD: [],
            examAverageYearCountF: [],
            examAverageYearFull: [],
            examCalsAvgYearFull: "",
            examAverageYearCountSuccess: 0,
            examAverageYearCountQuality: 0,
            quarter1Average: [],
            quarter1CalsAvg: "",
            quarter2CalsAvg: "",
            quarter2Average: [],
            quarter3Average: [],
            quarter3CalsAvg: "",
            quarter4Average: [],
            quarter4CalsAvg: "",
            yearAverage: [],
            yearAverageCalsAvg: "",
        };
    },
    computed: {},
    methods: {
        symbolGradeCheck(gradeValue) {
            // console.log(gradeValue);
            if (gradeValue >= 97) {
                return "A+, GPA 4.33 or 4.00";
            } else if (gradeValue >= 93) {
                return "A, GPA 4.00";
            } else if (gradeValue >= 90) {
                return "A-, GPA 3.67";
            } else if (gradeValue >= 87) {
                return "B+, GPA 3.33";
            } else if (gradeValue >= 83) {
                return "B, GPA 3.00";
            } else if (gradeValue >= 80) {
                return "B-, GPA 2.67";
            } else if (gradeValue >= 77) {
                return "C+, GPA 2.33";
            } else if (gradeValue >= 73) {
                return "C, GPA 2.00";
            } else if (gradeValue >= 70) {
                return "C-, GPA 1.67";
            } else if (gradeValue >= 67) {
                return "D+, GPA 1.33";
            } else if (gradeValue >= 63) {
                return "D, GPA 1.00";
            } else if (gradeValue >= 60) {
                return "D-, GPA 0.67";
            } else {
                return "F, GPA 0.00";
            }
        },
        countByMyExams() {
            this.changeSelectedLessonExamData = [...this.selectedLessonExamData];
            //   console.log(
            //     "childComp everyStudent props selectedLessonExamData",
            //     this.selectedLessonExamData
            //   );
            // console.log(this.changeSelectedLessonExamData);
            this.changeSelectedLessonExamData.forEach((el, index) => {
                // this.examAverageFull.push(el.examAverage);
                // this.examAverageQuarterFull.push(el.examAverageQuarter);
                this.quarter1Average.push(el.uliral_1 ? el.uliral_1 : 0);
                this.quarter2Average.push(el.uliral_2 ? el.uliral_2 : 0);
                this.quarter3Average.push(el.uliral_3 ? el.uliral_3 : 0);
                this.quarter4Average.push(el.uliral_4 ? el.uliral_4 : 0);
                this.yearAverage.push(el.uliral_dundaj ? el.uliral_dundaj : 0);

                //Улирал 1
                if (el.uliral_1 >= 90) {
                    this.examAverageUliral1CountA.push(el.uliral_1);
                } else if (el.uliral_1 >= 80) {
                    this.examAverageUliral1CountB.push(el.uliral_1);
                } else if (el.uliral_1 >= 70) {
                    this.examAverageUliral1CountC.push(el.uliral_1);
                } else if (el.uliral_1 >= 60) {
                    this.examAverageUliral1CountD.push(el.uliral_1);
                } else {
                    this.examAverageUliral1CountF.push(el.uliral_1);
                }
                //Улирал 2
                if (el.uliral_2 >= 90) {
                    this.examAverageUliral2CountA.push(el.uliral_2);
                } else if (el.uliral_2 >= 80) {
                    this.examAverageUliral2CountB.push(el.uliral_2);
                } else if (el.uliral_2 >= 70) {
                    this.examAverageUliral2CountC.push(el.uliral_2);
                } else if (el.uliral_2 >= 60) {
                    this.examAverageUliral2CountD.push(el.uliral_2);
                } else {
                    this.examAverageUliral2CountF.push(el.uliral_2);
                }
                //Улирал 3
                if (el.uliral_3 >= 90) {
                    this.examAverageUliral3CountA.push(el.uliral_3);
                } else if (el.uliral_3 >= 80) {
                    this.examAverageUliral3CountB.push(el.uliral_3);
                } else if (el.uliral_3 >= 70) {
                    this.examAverageUliral3CountC.push(el.uliral_3);
                } else if (el.uliral_3 >= 60) {
                    this.examAverageUliral3CountD.push(el.uliral_3);
                } else {
                    this.examAverageUliral3CountF.push(el.uliral_3);
                }
                //Улирал 4
                if (el.uliral_4 >= 90) {
                    this.examAverageUliral4CountA.push(el.uliral_4);
                } else if (el.uliral_4 >= 80) {
                    this.examAverageUliral4CountB.push(el.uliral_4);
                } else if (el.uliral_4 >= 70) {
                    this.examAverageUliral4CountC.push(el.uliral_4);
                } else if (el.uliral_4 >= 60) {
                    this.examAverageUliral4CountD.push(el.uliral_4);
                } else {
                    this.examAverageUliral4CountF.push(el.uliral_4);
                }

                //Гурван улирлын дүн тэгээс ялгаатай дундаж

                if (el.uliral_dundaj >= 90) {
                    this.examAverageYearCountA.push(el.uliral_dundaj);
                } else if (el.uliral_dundaj >= 80) {
                    this.examAverageYearCountB.push(el.uliral_dundaj);
                } else if (el.uliral_dundaj >= 70) {
                    this.examAverageYearCountC.push(el.uliral_dundaj);
                } else if (el.uliral_dundaj >= 60) {
                    this.examAverageYearCountD.push(el.uliral_dundaj);
                } else {
                    this.examAverageYearCountF.push(el.uliral_dundaj);
                }
                // //1 улирлын дүн тэгээс ялгаатай дундаж

                // if (el.uliral_1 > 0 && el.uliral_2 == 0 && el.uliral_3 == 0) {
                //   el.uliral_dundaj = el.uliral_1;
                //   if (el.uliral_dundaj >= 90) {
                //     this.examAverageYearCountA.push(el.uliral_dundaj);
                //   } else if (el.uliral_dundaj >= 80) {
                //     this.examAverageYearCountB.push(el.uliral_dundaj);
                //   } else if (el.uliral_dundaj >= 70) {
                //     this.examAverageYearCountC.push(el.uliral_dundaj);
                //   } else if (el.uliral_dundaj >= 60) {
                //     this.examAverageYearCountD.push(el.uliral_dundaj);
                //   } else {
                //     this.examAverageYearCountF.push(el.uliral_dundaj);
                //   }
                // }
            });

            //Exam1 average утгыг бодож олъё.
            var exam1Sum = 0;
            for (var i = 0; i < this.quarter1Average.length; i++) {
                exam1Sum += parseFloat(this.quarter1Average[i]); //don't forget to add the base
            }
            this.quarter1CalsAvg = exam1Sum / this.quarter1Average.length;
            // console.log(this.quarter1CalsAvg);
            //Exam2 average утгыг бодож олъё.
            var exam2Sum = 0;
            for (var i = 0; i < this.quarter2Average.length; i++) {
                exam2Sum += parseFloat(this.quarter2Average[i]); //don't forget to add the base
            }
            this.quarter2CalsAvg = exam2Sum / this.quarter2Average.length;
            // console.log(this.quarter2CalsAvg);
            //Exam3 average утгыг бодож олъё.
            var exam3Sum = 0;
            for (var i = 0; i < this.quarter3Average.length; i++) {
                exam3Sum += parseFloat(this.quarter3Average[i]); //don't forget to add the base
            }
            this.quarter3CalsAvg = exam3Sum / this.quarter3Average.length;
            // console.log(this.quarter3CalsAvg);
            //Exam4 average утгыг бодож олъё.
            var exam4Sum = 0;
            for (var i = 0; i < this.quarter4Average.length; i++) {
                exam4Sum += parseFloat(this.quarter4Average[i]); //don't forget to add the base
            }
            this.quarter4CalsAvg = exam4Sum / this.quarter4Average.length;
            // console.log(this.quarter4CalsAvg);

            //year average утгыг бодож олъё.
            var yearSum = 0;
            for (var i = 0; i < this.yearAverage.length; i++) {
                yearSum += parseFloat(this.yearAverage[i]); //don't forget to add the base
            }
            this.yearAverageCalsAvg = yearSum / this.yearAverage.length;
            // console.log(this.quarter4CalsAvg);

            //   Ерөнхий амжилт чанар 1-р улирал

            this.examAverageUliral1CountSuccess =
                ((this.examAverageUliral1CountA.length +
                    this.examAverageUliral1CountB.length +
                    this.examAverageUliral1CountC.length +
                    this.examAverageUliral1CountD.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral1CountQuality =
                ((this.examAverageUliral1CountA.length +
                    this.examAverageUliral1CountB.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral2CountSuccess =
                ((this.examAverageUliral2CountA.length +
                    this.examAverageUliral2CountB.length +
                    this.examAverageUliral2CountC.length +
                    this.examAverageUliral2CountD.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral2CountQuality =
                ((this.examAverageUliral2CountA.length +
                    this.examAverageUliral2CountB.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral3CountSuccess =
                ((this.examAverageUliral3CountA.length +
                    this.examAverageUliral3CountB.length +
                    this.examAverageUliral3CountC.length +
                    this.examAverageUliral3CountD.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral3CountQuality =
                ((this.examAverageUliral3CountA.length +
                    this.examAverageUliral3CountB.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral4CountSuccess =
                ((this.examAverageUliral4CountA.length +
                    this.examAverageUliral4CountB.length +
                    this.examAverageUliral4CountC.length +
                    this.examAverageUliral4CountD.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageUliral4CountQuality =
                ((this.examAverageUliral4CountA.length +
                    this.examAverageUliral4CountB.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageYearCountSuccess =
                ((this.examAverageYearCountA.length +
                    this.examAverageYearCountB.length +
                    this.examAverageYearCountC.length +
                    this.examAverageYearCountD.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
            this.examAverageYearCountQuality =
                ((this.examAverageYearCountA.length +
                    this.examAverageYearCountB.length) /
                    this.changeSelectedLessonExamData.length) *
                100;
        },

        averageYear(value) {
            console.log(value);
        },
    },
    beforeMount() {
        this.countByMyExams();
        // console.log(this.selectedLessonExamData);

        // axios
        //   .post("/manager/checkConfirmExamStudYear", {
        //     checkData: this.selectedLessonExamData,
        //     teacher_id: this.teacher_id,
        //     course_id: this.course_id,
        //     quarter_id: this.quarter_id,
        //   })
        //   .then((response) => {
        //     this.dataChecked = response.data.dataChecked;
        //     // console.log(response.data);
        //   })
        //   .catch((errors) => {});
    },
    created() { },
};
</script>

<style></style>
