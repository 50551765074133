<template>
  <div class="row pt-3">
    <!-- /.col -->
    <div class="col-md-12">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Эцэг эхийн мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#edit" data-toggle="tab"
                >Мэдээлэл засах</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#password" data-toggle="tab"
                >Нууц үг солих</a
              >
            </li>
          </ul>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="active tab-pane" id="activity">
              <div class="row">
                <div
                  class="
                    col-12 col-sm-6 col-md-6
                    d-flex
                    align-items-stretch
                    flex-column
                  "
                >
                  <div class="card bg-light d-flex flex-fill mr-1">
                    <div class="card-header text-muted border-bottom-0">
                      Эцгийн мэдээлэл
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-7">
                          <h2 class="lead">
                            <b
                              >{{ getParentInfo.parent_father_ovog }}
                              {{ getParentInfo.parent_father_name }}</b
                            >
                          </h2>
                          <p class="text-muted text-sm">
                            {{ getParentInfo.parent_father_mergejil }},
                            {{ getParentInfo.parent_father_ajil_gazar }}
                          </p>
                          <ul class="ml-4 mb-0 fa-ul text-muted">
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-users"></i
                              ></span>
                              Ургийн овог:
                              {{ getParentInfo.parent_father_urgiin_ovog }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-building"></i
                              ></span>
                              Хаяг: {{ getParentInfo.parent_father_address }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-phone"></i
                              ></span>
                              Утас:
                              {{ getParentInfo.parent_father_phone_number }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-envelope"></i
                              ></span>
                              Цахим шуудан:
                              {{ getParentInfo.parent_father_email }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fa fa-hashtag"></i
                              ></span>
                              РД: {{ getParentInfo.parent_father_reg }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fa fa-id-card"></i
                              ></span>
                              Яс үндэс:
                              {{ getParentInfo.parent_father_yas_und }}
                            </li>
                          </ul>
                        </div>
                        <div class="col-5 text-center">
                          <img
                            v-if="
                              getParentInfo.parent_father_profile_photo_url !=
                              null
                            "
                            :src="getParentInfo.parent_father_profile_photo_url"
                            alt="user-avatar"
                            class="img-circle img-fluid"
                          />
                          <img
                            v-else
                            src="/images/users/user.png"
                            alt="user-avatar"
                            class="img-circle img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row text-right">
                        <form
                          @submit.prevent="uploadFatherImg"
                          enctype="multipart/form-data"
                        >
                          <input
                            type="file"
                            name="getSelectedFatherImg"
                            @change="getFatherImg"
                            accept="image/png,image/gif,image/jpeg"
                            required
                          />
                          <button
                            type="submit"
                            class="btn btn-success"
                            v-if="loadedFather"
                          >
                            Хадгалах
                          </button>
                        </form>
                        <button
                          class="btn btn-danger ml-2"
                          @click.prevent="uploadFatherImgCancel"
                          v-if="loadedFather"
                        >
                          Цуцлах
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-12 col-sm-6 col-md-6
                    d-flex
                    align-items-stretch
                    flex-column
                  "
                >
                  <div class="card bg-light d-flex flex-fill ml-1">
                    <div class="card-header text-muted border-bottom-0">
                      Эхийн мэдээлэл
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-7">
                          <h2 class="lead">
                            <b
                              >{{ getParentInfo.parent_mother_ovog }}
                              {{ getParentInfo.parent_mother_name }}</b
                            >
                          </h2>
                          <p class="text-muted text-sm">
                            {{ getParentInfo.parent_mother_mergejil }},
                            {{ getParentInfo.parent_mother_ajil_gazar }}
                          </p>
                          <ul class="ml-4 mb-0 fa-ul text-muted">
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-users"></i
                              ></span>
                              Ургийн овог:
                              {{ getParentInfo.parent_mother_urgiin_ovog }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-building"></i
                              ></span>
                              Хаяг: {{ getParentInfo.parent_mother_address }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-phone"></i
                              ></span>
                              Утас:
                              {{ getParentInfo.parent_mother_phone_number }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fas fa-lg fa-envelope"></i
                              ></span>
                              Цахим шуудан:
                              {{ getParentInfo.parent_mother_email }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fa fa-hashtag"></i
                              ></span>
                              РД: {{ getParentInfo.parent_mother_reg }}
                            </li>
                            <li class="small">
                              <span class="fa-li"
                                ><i class="fa fa-id-card"></i
                              ></span>
                              Яс үндэс:
                              {{ getParentInfo.parent_mother_yas_und }}
                            </li>
                          </ul>
                        </div>
                        <div class="col-5 text-center">
                          <img
                            v-if="
                              getParentInfo.parent_mother_profile_photo_url !=
                              null
                            "
                            :src="getParentInfo.parent_mother_profile_photo_url"
                            alt="user-avatar"
                            class="img-circle img-fluid"
                          />
                          <img
                            v-else
                            src="/images/users/user.png"
                            alt="user-avatar"
                            class="img-circle img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row text-right">
                        <form
                          @submit.prevent="uploadMotherImg"
                          enctype="multipart/form-data"
                        >
                          <input
                            type="file"
                            name="getSelectedMotherImg"
                            @change="getMotherImg"
                            accept="image/png,image/gif,image/jpeg"
                            required
                          />
                          <button
                            type="submit"
                            class="btn btn-success"
                            v-if="loadedMother"
                          >
                            Хадгалах
                          </button>
                        </form>
                        <button
                          class="btn btn-danger ml-2"
                          @click.prevent="uploadMotherImgCancel"
                          v-if="loadedMother"
                        >
                          Цуцлах
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="edit">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h3 class="card-title">Эцгийн талаарх мэдээлэл</h3>
                    </div>
                    <!-- /.card-header -->

                    <div class="card-body">
                      <b-form-group
                        id="input-group-1"
                        label="Ургийн овог:"
                        label-for="parent_father_urgiin_ovog"
                      >
                        <b-form-input
                          id="parent_father_urgiin_ovog"
                          v-model="form.parent_father_urgiin_ovog"
                          placeholder="Ургийн овог"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_urgiin_ovog'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_urgiin_ovog"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_ovog"
                        label="Овог:"
                        label-for="parent_father_ovog"
                      >
                        <b-form-input
                          id="parent_father_ovog"
                          v-model="form.parent_father_ovog"
                          placeholder="Овог"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_ovog'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_ovog"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_name"
                        label="Нэр:"
                        label-for="parent_father_name"
                      >
                        <b-form-input
                          id="parent_father_name"
                          v-model="form.parent_father_name"
                          placeholder="Нэр"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_name'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_name"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_reg"
                        label="Регистрийн дугаар:"
                        label-for="parent_father_reg"
                      >
                        <b-form-input
                          id="parent_father_reg"
                          v-model="form.parent_father_reg"
                          placeholder="Регистрийн дугаар"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_reg'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_reg"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_yas_und"
                        label="Яс үндэс:"
                        label-for="parent_father_yas_und"
                      >
                        <b-form-input
                          id="parent_father_yas_und"
                          v-model="form.parent_father_yas_und"
                          placeholder="Яс үндэс"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_yas_und'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_yas_und"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_mergejil"
                        label="Мэргэжил:"
                        label-for="parent_father_mergejil"
                      >
                        <b-form-input
                          id="parent_father_mergejil"
                          v-model="form.parent_father_mergejil"
                          placeholder="Мэргэжил"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_mergejil'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_mergejil"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_bol"
                        label="Боловсрол:"
                        label-for="parent_father_bol"
                      >
                        <b-form-input
                          id="parent_father_bol"
                          v-model="form.parent_father_bol"
                          placeholder="Боловсрол"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_bol'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_bol"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_ajil_gazar"
                        label="Ажлын газар:"
                        label-for="parent_father_ajil_gazar"
                      >
                        <b-form-input
                          id="parent_father_ajil_gazar"
                          v-model="form.parent_father_ajil_gazar"
                          placeholder="Ажлын газар"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_ajil_gazar'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_ajil_gazar"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_phone_number"
                        label="Утасны дугаар:"
                        label-for="parent_father_phone_number"
                      >
                        <b-form-input
                          id="parent_father_phone_number"
                          v-model="form.parent_father_phone_number"
                          placeholder="Утасны дугаар"
                          type="number"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_phone_number'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_phone_number"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_email"
                        label="Имэйл:"
                        label-for="parent_father_email"
                      >
                        <b-form-input
                          id="parent_father_email"
                          v-model="form.parent_father_email"
                          placeholder="Имэйл"
                          type="email"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_email'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_email"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_father_address"
                        label="Гэрийн хаяг:"
                        label-for="parent_father_address"
                      >
                        <b-form-input
                          id="parent_father_address"
                          v-model="form.parent_father_address"
                          placeholder="Гэрийн хаяг"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_father_address'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_father_address"
                        ></has-error>
                      </b-form-group>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <div class="card-header">
                      <h3 class="card-title">Эхийн талаарх мэдээлэл</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <b-form-group
                        id="input-group-1"
                        label="Ургийн овог:"
                        label-for="parent_mother_urgiin_ovog"
                      >
                        <b-form-input
                          id="parent_mother_urgiin_ovog"
                          v-model="form.parent_mother_urgiin_ovog"
                          placeholder="Ургийн овог"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_urgiin_ovog'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_urgiin_ovog"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_ovog"
                        label="Овог:"
                        label-for="parent_mother_ovog"
                      >
                        <b-form-input
                          id="parent_mother_ovog"
                          v-model="form.parent_mother_ovog"
                          placeholder="Овог"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_ovog'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_ovog"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_name"
                        label="Нэр:"
                        label-for="parent_mother_name"
                      >
                        <b-form-input
                          id="parent_mother_name"
                          v-model="form.parent_mother_name"
                          placeholder="Нэр"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_name'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_name"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_reg"
                        label="Регистрийн дугаар:"
                        label-for="parent_mother_reg"
                      >
                        <b-form-input
                          id="parent_mother_reg"
                          v-model="form.parent_mother_reg"
                          placeholder="Регистрийн дугаар"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_reg'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_reg"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_yas_und"
                        label="Яс үндэс:"
                        label-for="parent_mother_yas_und"
                      >
                        <b-form-input
                          id="parent_mother_yas_und"
                          v-model="form.parent_mother_yas_und"
                          placeholder="Яс үндэс"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_yas_und'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_yas_und"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_mergejil"
                        label="Мэргэжил:"
                        label-for="parent_mother_mergejil"
                      >
                        <b-form-input
                          id="parent_mother_mergejil"
                          v-model="form.parent_mother_mergejil"
                          placeholder="Мэргэжил"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_mergejil'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_mergejil"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_bol"
                        label="Боловсрол:"
                        label-for="parent_mother_bol"
                      >
                        <b-form-input
                          id="parent_mother_bol"
                          v-model="form.parent_mother_bol"
                          placeholder="Боловсрол"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_bol'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_bol"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_ajil_gazar"
                        label="Ажлын газар:"
                        label-for="parent_mother_ajil_gazar"
                      >
                        <b-form-input
                          id="parent_mother_ajil_gazar"
                          v-model="form.parent_mother_ajil_gazar"
                          placeholder="Ажлын газар"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_ajil_gazar'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_ajil_gazar"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_phone_number"
                        label="Утасны дугаар:"
                        label-for="parent_mother_phone_number"
                      >
                        <b-form-input
                          id="parent_mother_phone_number"
                          v-model="form.parent_mother_phone_number"
                          placeholder="Утасны дугаар"
                          type="number"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_phone_number'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_phone_number"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_email"
                        label="Имэйл:"
                        label-for="parent_mother_email"
                      >
                        <b-form-input
                          id="parent_mother_email"
                          v-model="form.parent_mother_email"
                          placeholder="Имэйл"
                          type="email"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_email'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_email"
                        ></has-error>
                      </b-form-group>
                      <b-form-group
                        id="input-parent_mother_address"
                        label="Гэрийн хаяг:"
                        label-for="parent_mother_address"
                      >
                        <b-form-input
                          id="parent_mother_address"
                          v-model="form.parent_mother_address"
                          placeholder="Гэрийн хаяг"
                          required
                          class="
                            [
                            'form-control',
                            {
                            'is-invalid':
                            form.errors.has('parent_mother_address'),
                            },
                            ]
                          "
                        ></b-form-input>
                        <has-error
                          :form="form"
                          field="parent_mother_address"
                        ></has-error>
                      </b-form-group>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
              <div class="row pl-2">
                <b-button
                  v-if="this.getParentInfo != null"
                  @click="updateParentInfo()"
                  variant="primary"
                  type="submit"
                >
                  Засах
                </b-button>
              </div>
            </div>
            <div class="tab-pane" id="password">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card card-secondary">
                    <!-- /.card-header -->
                    <div class="card-body">
                      <form>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг:"
                          label-for="parent_pass"
                        >
                          <b-form-input
                            type="password"
                            v-model="parent_pass"
                            placeholder="Нууц үг"
                            required
                            class="
                              [
                              'form-control',
                              {
                              'is-invalid':
                              form.errors.has('parent_pass'),
                              },
                              ]
                            "
                          ></b-form-input>
                          <has-error
                            :form="form"
                            field="parent_pass"
                          ></has-error>
                        </b-form-group>
                        <b-form-group
                          id="input-group-1"
                          label="Нууц үг давтах:"
                          label-for="parent_pass_verify"
                        >
                          <b-form-input
                            type="password"
                            v-model="parent_pass_verify"
                            placeholder="Нууц үг давтах"
                            required
                            class="
                              [
                              'form-control',
                              {
                              'is-invalid':
                              form.errors.has('parent_pass_verify'),
                              },
                              ]
                            "
                          ></b-form-input>
                          <has-error
                            :form="form"
                            field="parent_pass_verify"
                          ></has-error>
                        </b-form-group>
                      </form>
                      <h6
                        class="text-success"
                        v-if="
                          errorPassword ==
                          'Таны нууц үгийг амжилттай шинэчиллээ!'
                        "
                      >
                        {{ errorPassword }}
                      </h6>
                      <h6 class="text-danger" v-else>{{ errorPassword }}</h6>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
              <div class="row pl-2">
                <b-button
                  @click="resetPassword()"
                  variant="primary"
                  type="submit"
                >
                  Нууц үг солих
                </b-button>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>

        <!-- /.card-body -->
      </div>
      <!-- /.nav-tabs-custom -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadedFather: false,
      loadedMother: false,
      errorPassword: "",
      parent_pass: "",
      parent_pass_verify: "",
      getSelectedFatherImg: null,
      getSelectedMotherImg: null,
      getParentInfo: [],
      form: new Form({
        id: "",
        user_id: "",
        user_name: "",
        user_email: "",
        student_profile_id: "",
        schoolYearId: "",
        parent_father_urgiin_ovog: "",
        parent_father_ovog: "",
        parent_father_name: "",
        parent_father_reg: "",
        parent_father_yas_und: "",
        parent_father_mergejil: "",
        parent_father_bol: "",
        parent_father_ajil_gazar: "",
        parent_father_phone_number: "",
        parent_father_email: "",
        parent_father_address: "",
        parent_father_profile_photo_url: "",
        parent_mother_urgiin_ovog: "",
        parent_mother_ovog: "",
        parent_mother_name: "",
        parent_mother_reg: "",
        parent_mother_yas_und: "",
        parent_mother_mergejil: "",
        parent_mother_bol: "",
        parent_mother_ajil_gazar: "",
        parent_mother_phone_number: "",
        parent_mother_email: "",
        parent_mother_address: "",
        parent_mother_profile_photo_url: "",
      }),
    };
  },
  methods: {
    getMyInfo() {
      console.log("Ажиллаж байна. getMyInfo");
      axios
        .get("/parent/getMyInfo")
        .then((res) => {
          this.form.fill(res.data.getParentInfo);
          this.getParentInfo = res.data.getParentInfo;
          this.getSelectedFatherImg = res.data.parent_father_profile_photo_url;
          this.getSelectedMotherImg = res.data.parent_mother_profile_photo_url;
        })
        .catch();
    },
    uploadFatherImg() {
      axios
        .post("/parent/uploadParentImg/" + this.form.id, {
          getSelectedFatherImg:
            this.getParentInfo.parent_father_profile_photo_url,
        })
        .then((res) => {
          Fire.$emit("loadStart");
          this.loadedFather = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadFatherImgCancel() {
      this.loadedFather = false;
      this.getParentInfo.parent_father_profile_photo_url =
        this.getSelectedFatherImg;
      this.getSelectedFatherImg = null;
    },
    getFatherImg(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      this.getSelectedFatherImg =
        this.getParentInfo.parent_father_profile_photo_url;

      reader.onload = (e) => {
        this.getParentInfo.parent_father_profile_photo_url = e.target.result;
      };
      this.loadedFather = true;
    },
    uploadMotherImg() {
      axios
        .post("/parent/uploadParentImg/" + this.form.id, {
          getSelectedMotherImg:
            this.getParentInfo.parent_mother_profile_photo_url,
        })
        .then((res) => {
          Fire.$emit("loadStart");
          this.loadedMother = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadMotherImgCancel() {
      this.loadedMother = false;
      this.getParentInfo.parent_mother_profile_photo_url =
        this.getSelectedMotherImg;
      this.getSelectedMotherImg = null;
    },
    getMotherImg(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      this.getSelectedMotherImg =
        this.getParentInfo.parent_mother_profile_photo_url;

      reader.onload = (e) => {
        this.getParentInfo.parent_mother_profile_photo_url = e.target.result;
      };
      this.loadedMother = true;
    },
    updateParentInfo() {
      this.form
        .put("/parent/updateParentInfo/" + this.form.id)
        .then((response) => {
          this.$toastr.s(
            "Суралцагчид эцэг эхийг амжилттай бүтгэлээ.",
            "Амжилттай"
          );
        })
        .catch(() => {
          this.$toastr.e(
            "Суралцагчид эцэг эхийг бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    resetPassword() {
      if (this.parent_pass == this.parent_pass_verify) {
        axios
          .post("/parent/changePassword", {
            password: this.parent_pass,
          })
          .then((res) => {
            Swal.fire(
              "Нууц өөрчлөгдлөө!",
              "Хэрэглэгчийн нууц үг амжилттай шинэчиллээ.",
              "success"
            );
            this.parent_pass = "";
            this.parent_pass_verify = "";
            this.errorPassword = "Таны нууц үгийг амжилттай шинэчиллээ!";
            this.loaded = false;
          })
          .catch((err) => {
            this.errorPassword =
              "Нууц үг нь хамгийн багадаа 6 оронтой байх ёстой!";
          });
      } else {
        this.errorPassword = "Нууц үгийг зөв давтан оруулна уу!";
      }
    },
  },
  created() {
    this.getMyInfo();
    Fire.$on("loadStart", () => {
      this.getMyInfo();
    });
  },
};
</script>

<style>
</style>
