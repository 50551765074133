<template>
  <div class="row">
    <div class="col-lg-12 mt-3" id="nonPrintable">
      <div class="card">
        <div class="card-header">
          <strong>Сэтгэл ханамжийн судалгааны форм</strong>
          <b-button
            class="float-right"
            variant="success"
            v-b-toggle.sidebar-form
            @click.prevent="addForm()"
            ><i class="fa fa-plus mr-1"></i>Форм үүсгэх</b-button
          >
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <b-table
              hover
              :items="myForms"
              :fields="fields"
              sort-by.sync="name"
              sort-desc.sync="false"
              responsive="sm"
              :filter="null"
              :filter-included-fields="filterOn"
              empty-text="Одоогоор форм бүртгэгдээгүй байна."
            >
              <template #empty="scope">
                <p class="text-center text-muted h5 mt-5">
                  <b-icon icon="inboxes"></b-icon>
                </p>
                <p class="text-center text-muted font-italic">
                  {{ scope.emptyText }}
                </p>
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(status)="data">
                <b-form-checkbox
                  :checked="`${getStatusBool(data.item.status)}`"
                  aria-describedby="active"
                  switch
                  size="xs"
                  @change="updateFormStatus(data.item.id)"
                >
                </b-form-checkbox>
              </template>
              <template #cell(owner)="data">
                <img
                  alt="Avatar"
                  v-b-tooltip.hover.top.html="
                    '<dd><img src=' +
                    data.item.owner.profile_photo_url +
                    ' style=' +
                    'width:180px!important' +
                    '></dd><dt>Форм үүсгэсэн</dt><dd>' +
                    data.item.owner.teacher_last_name +
                    ' <span class=text-uppercase>' +
                    data.item.owner.teacher_name +
                    '</span></dd></dl>'
                  "
                  class="table-avatar"
                  :src="
                    data.item.owner.profile_photo_url
                      ? data.item.owner.profile_photo_url
                      : '/images/users/user.png'
                  "
                />
              </template>
              <template #cell(description)="data">
                <b-badge class="text-wrap" variant="light">{{
                  data.item.description
                }}</b-badge>
              </template>
              <!-- <template #cell(inputs)="data">
                <b-badge
                  variant="light"
                  class="mr-1 p-1"
                  v-for="input in data.item.inputs"
                  :key="input.id"
                >
                  <b-icon
                    v-if="input.type == 'Choice'"
                    variant="info"
                    icon="ui-radios"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon
                    v-if="input.type == 'Text'"
                    variant="info"
                    icon="file-font"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon
                    v-if="input.type == 'Rate'"
                    variant="info"
                    icon="star-half"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon
                    v-if="input.type == 'Date'"
                    variant="info"
                    icon="calendar2-date"
                    aria-hidden="true"
                  ></b-icon>
                  {{ input.label }}
                </b-badge>
              </template> -->
              <template #cell(actions)="data">
                <b-button
                  class="my-1"
                  size="sm"
                  v-b-toggle.sidebar-form
                  @click.prevent="editForm(data.item)"
                  variant="outline-primary"
                  ><i class="fas fa-edit"></i
                ></b-button>
                <b-button
                  class="my-1"
                  size="sm"
                  @click.prevent="deleteForm(data.item.id)"
                  variant="outline-danger"
                  ><b-icon icon="trash" aria-hidden="true"> </b-icon
                ></b-button>
                <b-button
                  class="my-1"
                  size="sm"
                  v-b-toggle.sidebar-satisfaction
                  @click.prevent="addSatisfaction(data.item)"
                  variant="outline-info"
                  ><b-icon icon="ui-checks" aria-hidden="true"> </b-icon>
                  Судалгаа</b-button
                >
                <b-button
                  class="my-1"
                  size="sm"
                  @click.prevent="showUsers(data.item)"
                  variant="outline-dark"
                  ><b-icon icon="person-circle" aria-hidden="true"> </b-icon>
                  Хэрэглэгч</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
      id="sidebar-form"
      :title="formTitle"
      backdrop-variant="secondary"
      left
      z-index="1039"
      shadow
      width="80%"
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="editFormMode ? updateForm() : createForm()">
              <div class="form-group col-lg-12">
                <input
                  type="text"
                  v-model="form.title"
                  placeholder="Нэр"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has('title'),
                  }"
                  required
                />
              </div>
              <div class="form-group col-lg-12">
                <b-form-textarea
                  id="textarea"
                  v-model="form.description"
                  placeholder="Дэлгэрэнгүй мэдээлэл..."
                  rows="3"
                  max-rows="6"
                  :class="{
                    'is-invalid': form.errors.has('description'),
                  }"
                  required
                ></b-form-textarea>
              </div>
              <hr />
              <div class="col-lg-12 mt-3" v-if="filteredInputs">
                <b-button
                  v-if="filteredInputs.length > 0"
                  size="sm"
                  class="text-muted"
                  variant="light"
                  @click="removeAllInput()"
                >
                  <b-icon icon="trash" aria-hidden="true"> </b-icon>
                  Бүх талбар устгах
                </b-button>
                <b-button
                  v-if="filteredInputs.length > 0"
                  size="sm"
                  class="text-muted"
                  variant="light"
                  @click="reorderAllInput()"
                >
                  <b-icon icon="arrow-repeat" aria-hidden="true"> </b-icon>
                  Бүх талбарын эрэмбэ сэргээх
                </b-button>
                <b-button
                  v-if="filteredInputs.length > 0"
                  size="sm"
                  class="text-muted"
                  variant="light"
                  @click="setMethodAllInput()"
                >
                  <b-icon icon="clipboard-data" aria-hidden="true"> </b-icon>
                  Бүх талбарт тусгай аргачлал ашиглах
                </b-button>
                <span class="float-right"
                  ><b>Нийт судалгаа өгсөн: </b>{{ form.usersCount }}
                </span>
              </div>
              <div class="col-lg-12 mt-3">
                <b-card
                  v-for="input in filteredInputs"
                  :key="input.id"
                  header-tag="header"
                  footer-tag="footer"
                  :id="input.id"
                  class="formCard"
                  :class="[{ 'animated fadeInDown': isFade == true }]"
                >
                  <template #header>
                    <div class="d-flex py-1 float-left">
                      <b-form-checkbox
                        :checked="`${getInputBool(input.isRequired)}`"
                        aria-describedby="active"
                        switch
                        class="mr-2"
                        size="xs"
                        @change="changeIsRequire(input)"
                      >
                        <span
                          :class="[
                            'text-muted',
                            input.isRequired == 1 ? 'labelActive' : 'label',
                          ]"
                          >Required
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        :checked="`${getInputBool(input.isMethod)}`"
                        aria-describedby="active"
                        switch
                        v-if="input.type == 'Choice'"
                        class="mr-2"
                        size="xs"
                        @change="changeIsMethod(input)"
                      >
                        <span
                          :class="[
                            'text-muted',
                            input.isMethod == 1 ? 'labelActive' : 'label',
                          ]"
                          >Тусгай аргачлал
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        :checked="`${getInputBool(input.isNumberOrText)}`"
                        variant="success"
                        v-if="input.type == 'Text'"
                        aria-describedby="active"
                        switch
                        class="mr-2"
                        size="xs"
                        @change="changeIsNumberOrText(input)"
                      >
                        <span
                          v-if="input.isNumberOrText == 1"
                          class="'text-muted labelActive"
                        >
                          Бичвэр
                        </span>
                        <span v-else class="text-muted label"> Тоо </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        :checked="`${getInputBool(input.isMultiple)}`"
                        variant="success"
                        v-if="input.type == 'Choice' || input.type == 'Text'"
                        aria-describedby="active"
                        switch
                        size="xs"
                        @change="changeIsMultiple(input)"
                      >
                        <span
                          :class="[
                            'text-muted',
                            input.isMultiple == 1 ? 'labelActive' : 'label',
                          ]"
                        >
                          <span v-if="input.type == 'Choice'">Олон сонголттой эсэх</span>
                          <span v-if="input.type == 'Text'">Урт хариулттай эсэх</span>
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div class="card-tools">
                      <b-button
                        size="sm"
                        class="text-muted"
                        variant="light"
                        :disabled="input.order == 1 ? true : false"
                        @click="downInputOrder(input)"
                        ><b-icon icon="arrow-up" aria-hidden="true"></b-icon
                      ></b-button>
                      <b-button
                        size="sm"
                        class="text-muted"
                        variant="light"
                        @click="upInputOrder(input)"
                        :disabled="input.order == filteredInputs.length ? true : false"
                        ><b-icon icon="arrow-down" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button
                        size="sm"
                        class="text-muted"
                        variant="light"
                        :disabled="filteredInputs.length == 1 ? true : false"
                        @click="removeInput(input)"
                        ><b-icon icon="trash" aria-hidden="true"></b-icon
                      ></b-button>
                    </div>
                  </template>
                  <b-card-text :disabled="input.input_answers_count > 0 ? true : false">
                    <div class="form-group">
                      <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                          <strong> {{ input.order }}</strong>
                        </b-input-group-prepend>
                        <b-form-input
                          type="text"
                          required
                          v-model="input.label"
                          :placeholder="input.order + '-р асуулт оруулна уу?'"
                          class="form-control"
                          @input="inputChanged(input)"
                        />
                      </b-input-group>
                    </div>
                    <div class="form-group">
                      <b-form-textarea
                        id="textarea"
                        v-model="input.description"
                        placeholder="Дэлгэрэнгүй мэдээлэл..."
                        rows="2"
                        @input="inputChanged(input)"
                        max-rows="5"
                      ></b-form-textarea>
                    </div>
                    <div v-if="input.type == 'Choice'">
                      <div
                        class="ml-5"
                        v-for="(item, index) in input.valueOptions"
                        :key="item.order"
                      >
                        <div class="form-group d-flex">
                          <span class="mr-2">
                            <b-icon
                              v-if="input.isMultiple == false"
                              icon="check-circle"
                              aria-hidden="true"
                            ></b-icon>
                            <b-icon
                              v-else
                              icon="check-square"
                              aria-hidden="true"
                            ></b-icon>
                          </span>
                          <b-input-group size="sm" class="mb-2">
                            <b-form-input
                              @input="inputChanged(input)"
                              :disabled="input.input_answers_count > 0 ? true : false"
                              required
                              type="text"
                              v-model="item.option"
                              :placeholder="'Сонголт ' + (index + 1)"
                              class="form-control"
                            />
                            <b-input-group-prepend is-text>
                              <b-icon
                                role="button"
                                class="text-muted"
                                variant="light"
                                :disabled="input.value.length == 1 ? true : false"
                                @click="removeOption(input, index)"
                                icon="x-circle"
                              ></b-icon>
                            </b-input-group-prepend>
                          </b-input-group>
                        </div>
                      </div>
                      <a class="m-5 text-info" href="#" @click="addOption(input)">
                        <b-icon icon="plus-lg" aria-hidden="true" class="mr-1"></b-icon>
                        Сонголт нэмэх</a
                      >
                    </div>
                    <div v-else-if="input.type == 'Text'">
                      <span class="ml-4">
                        <b-icon
                          variant="warning"
                          icon="info-circle"
                          aria-hidden="true"
                        ></b-icon>
                        <span class="text-muted ml-1"
                          >Хэрэглэгчээс асуух асуултаа оруулна уу!
                        </span>
                      </span>
                    </div>
                    <div v-else-if="input.type == 'Rate'">
                      <span class="ml-4">
                        <span
                          v-for="(level, index) in parseInt(input.value)"
                          :key="level"
                        >
                          <b-icon
                            v-if="input.symbol != 'Number'"
                            :icon="input.symbol"
                            aria-hidden="true"
                            font-scale="2"
                            class="mr-3 level-color"
                          ></b-icon>
                          <b-badge
                            v-else
                            class="mr-3 level-color"
                            font-scale="2"
                            variant="light"
                            >{{ index + 1 }}</b-badge
                          >
                        </span>
                      </span>
                      <div class="row mt-3">
                        <div class="col-lg-6">
                          <b-form-group
                            label="Түвшин:"
                            :disabled="input.input_answers_count > 0 ? true : false"
                            label-for="nested-street"
                            label-cols-sm="3"
                            label-align-sm="right"
                          >
                            <b-form-select
                              v-model="input.value"
                              :options="levelOptions"
                              size="sm"
                              class="mt-1"
                              @change="inputChanged(input)"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                        <div class="col-lg-6">
                          <b-form-group
                            label="Тэмдэг:"
                            :disabled="input.input_answers_count > 0 ? true : false"
                            label-for="nested-street"
                            label-cols-sm="3"
                            label-align-sm="right"
                          >
                            <b-form-select
                              v-model="input.symbol"
                              :options="symbolOptions"
                              size="sm"
                              class="mt-1"
                              @change="inputChanged(input)"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="input.type == 'Date'" class="form-group ml-4">
                      <b-form-datepicker
                        disabled
                        placeholder="Огноо (M/d/yyyy)"
                      ></b-form-datepicker>
                    </div>
                  </b-card-text>
                </b-card>
                <div class="form-group">
                  <b-button-group class="p-1 btn-group">
                    <b-button
                      @click="showButtons"
                      size="sm"
                      variant="info"
                      title="Сонголт нэмэх"
                    >
                      <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                      <span v-show="!addInput" class="ml-1"> Нэмэх</span>
                    </b-button>
                    <b-button
                      v-show="addInput"
                      class="mx-1"
                      size="sm"
                      variant="light"
                      title="Сонголт нэмэх"
                      @click="addChoice"
                    >
                      <b-icon variant="info" icon="ui-radios" aria-hidden="true"></b-icon>
                      Сонголт
                    </b-button>
                    <b-button
                      v-show="addInput"
                      size="sm"
                      variant="light"
                      title="Бичвэр нэмэх"
                      @click="addTextInput"
                    >
                      <b-icon variant="info" icon="file-font" aria-hidden="true"></b-icon>
                      Бичвэр
                    </b-button>
                    <b-button
                      v-show="addInput"
                      class="ml-1"
                      size="sm"
                      variant="light"
                      title="Үнэлгээ нэмэх"
                      @click="addRateInput"
                    >
                      <b-icon variant="info" icon="star-half" aria-hidden="true"></b-icon>
                      Үнэлгээ
                    </b-button>
                    <b-button
                      v-show="addInput"
                      class="ml-1"
                      size="sm"
                      variant="light"
                      title="Огноо нэмэх"
                      @click="addDateInput"
                    >
                      <b-icon
                        variant="info"
                        icon="calendar2-date"
                        aria-hidden="true"
                      ></b-icon>
                      Огноо
                    </b-button>
                  </b-button-group>
                </div>
              </div>
              <div class="float-right">
                <b-overlay
                  :show="submitClicked"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn btn-success"
                    v-if="editFormMode"
                  >
                    <i class="fa fa-save mr-1"></i> Хадгалах
                  </b-button>
                  <button
                    v-if="editFormMode"
                    @click.prevent="addForm"
                    class="btn btn-warning"
                  >
                    <i class="fa fa-plus mr-1"></i> Болих
                  </button>
                  <button type="submit" v-if="!editFormMode" class="btn btn-primary">
                    <i class="fa fa-save mr-1"></i> Үүсгэх
                  </button>
                </b-overlay>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-sidebar>
    <b-sidebar
      id="sidebar-satisfaction"
      title="Сэтгэл ханамжийн судалгаа"
      backdrop-variant="info"
      left
      z-index="1039"
      shadow
      width="100%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-button size="sm" variant="info" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="row">
          <div class="col-lg-9">
            <div class="card">
              <div class="card-header">
                <strong>Бүртгэсэн судалгаанууд</strong>
              </div>
              <div class="card-body">
                <multiselect
                  v-model="year_id"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  track-by="id"
                  label="id"
                  :custom-label="customYearName"
                  placeholder="Хичээлийн жилээр шүүж харах"
                  :options="years"
                  :searchable="true"
                  :allow-empty="false"
                  @input="getSatisfactions"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.start_date | dateYear }}-{{
                        option.finish_date | dateYear
                      }}
                      - хичээлийн жил
                    </strong></template
                  >
                </multiselect>
                <b-overlay
                  :show="isLoaded"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Төлөв</th>
                          <th>Үйлдэл</th>
                          <th>Эзэмшигч</th>
                          <th>Төрөл</th>
                          <th>Мэдээлэл</th>
                          <!-- <th>Талбар</th> -->
                          <th>Үүсгэсэн</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(satisfaction, index) in satisfactions"
                          :key="satisfaction.id"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="4">
                            <b-form-checkbox
                              :checked="`${getStatusBool(satisfaction.status)}`"
                              aria-describedby="active"
                              switch
                              size="xs"
                              @change="changeStatus(satisfaction.id)"
                            >
                            </b-form-checkbox>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="12">
                            <b-button
                              class="my-1"
                              size="sm"
                              @click.prevent="editSatisfaction(satisfaction)"
                              variant="outline-primary"
                              ><i class="fas fa-edit"></i
                            ></b-button>
                            <b-button
                              class="my-1"
                              size="sm"
                              @click.prevent="deleteSatisfaction(satisfaction.id)"
                              variant="outline-danger"
                              ><b-icon icon="trash" aria-hidden="true"> </b-icon
                            ></b-button>
                            <b-button
                              class="my-1"
                              size="sm"
                              @click.prevent="showSatisfactionQR(satisfaction)"
                              variant="outline-dark"
                              ><i class="fas fa-qrcode"></i>
                            </b-button>
                            <b-button
                              size="sm"
                              @click.prevent="showSatisfactionUsers(satisfaction)"
                              variant="outline-info"
                              ><i class="fas fa-users"></i>
                            </b-button>
                            <b-button
                              class="my-1"
                              size="sm"
                              @click.prevent="getSatisfactionResult(satisfaction)"
                              variant="success"
                            >
                              <b-icon icon="eye"></b-icon>
                              Үр дүн</b-button
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <img
                              alt="Avatar"
                              v-b-tooltip.hover.top.html="
                                '<dd><img src=' +
                                satisfaction.owner.profile_photo_url +
                                ' style=' +
                                'width:180px!important' +
                                '></dd><dt>Сэтгэл ханамжийн судалгаа үүсгэсэн</dt><dd>' +
                                satisfaction.owner.teacher_last_name +
                                ' <span class=text-uppercase>' +
                                satisfaction.owner.teacher_name +
                                '</span></dd></dl>'
                              "
                              class="table-avatar"
                              :src="
                                satisfaction.owner.profile_photo_url
                                  ? satisfaction.owner.profile_photo_url
                                  : '/images/users/user.png'
                              "
                            />
                          </td>
                          <td class="dtr-control sorting_1" tabindex="4">
                            <p>
                              <small>
                                Хэлбэр:
                                <strong>{{ satisfaction.type }}</strong>
                              </small>
                              <br />
                              <small>
                                Хэрэглэгч:
                                <strong>{{ satisfaction.userType }}</strong>
                              </small>
                            </p>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="5" width="30%">
                            <b-badge variant="light">
                              {{ satisfaction.title }}
                              <p class="text-wrap">
                                {{ satisfaction.description }}
                              </p>
                            </b-badge>
                          </td>
                          <!-- <td class="dtr-control sorting_1" tabindex="5">
                            <b-badge
                              variant="light"
                              class="mr-1 p-1"
                              v-for="input in satisfaction.selected_inputs"
                              :key="input.id"
                            >
                              <b-icon
                                v-if="input.type == 'Choice'"
                                variant="info"
                                icon="ui-radios"
                                aria-hidden="true"
                              ></b-icon>
                              <b-icon
                                v-if="input.type == 'Text'"
                                variant="info"
                                icon="file-font"
                                aria-hidden="true"
                              ></b-icon>
                              <b-icon
                                v-if="input.type == 'Rate'"
                                variant="info"
                                icon="star-half"
                                aria-hidden="true"
                              ></b-icon>
                              <b-icon
                                v-if="input.type == 'Date'"
                                variant="info"
                                icon="calendar2-date"
                                aria-hidden="true"
                              ></b-icon>
                              {{ input.label }}
                            </b-badge>
                            <span
                              v-if="satisfaction.selected_inputs.length == 0"
                              >Бүх талбар сонгогдсон</span
                            >
                          </td> -->
                          <td class="dtr-control sorting_1" tabindex="12">
                            {{ satisfaction.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card">
              <div class="card-header">
                <strong v-if="!editSatisfactionMode">Судалгаа үүсгэх</strong>
                <strong v-else>Судалгаа засах</strong>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <b-form-select
                      v-model="satisfactionForm.type"
                      :options="satisfactionTypeOptions"
                    ></b-form-select>
                  </div>
                  <div v-if="isModelSelected == true">
                    <div class="form-group">
                      <multiselect
                        v-model="selectedModel"
                        :options="modelOptions"
                        :multiple="false"
                        track-by="name"
                        label="title"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        deselect-label="Хасах"
                        placeholder="Судалгааны төрөл сонгоно уу?"
                        :searchable="false"
                        :allow-empty="false"
                        @input="getModelDatas"
                      >
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="selectedModelDatas"
                        :options="modelDataOptions"
                        :multiple="true"
                        track-by="id"
                        label="name"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        deselect-label="Хасах"
                        placeholder="Судалгааны сэдэв сонгоно уу?"
                        :searchable="true"
                        :close-on-select="false"
                        :allow-empty="false"
                        @input="pushSelectedModel"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="satisfactionForm.isUseMethod"
                      value="1"
                      unchecked-value="0"
                    >
                      Тусгай аргачлал сонгох эсэх
                    </b-form-checkbox>
                  </div>
                  <div class="form-group">
                    <multiselect
                      v-if="satisfactionForm.isUseMethod == 1"
                      v-model="satisfactionForm.method"
                      :options="methodOptions"
                      :multiple="false"
                      track-by="id"
                      label="name"
                      select-label="Сонгох"
                      selected-label="Сонгогдсон"
                      deselect-label="Хасах"
                      placeholder="Тусгай аргачлал"
                      :searchable="false"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </div>
                  <div class="form-group" v-if="isModelSelected == true">
                    <label>Сонгогдсон сэдвүүд</label>
                    <div v-if="satisfactionForm.satisfaction_models.length > 0">
                      <b-list-group>
                        <b-list-group-item
                          v-for="(model, index) in satisfactionForm.satisfaction_models"
                          :key="model.id"
                          class="d-flex justify-content-between py-0"
                        >
                          <span>
                            {{ index + 1 }}. {{ model.title }} |
                            {{ model.modelData }}
                          </span>
                          <a
                            href="#"
                            size="sm"
                            @click.prevent="removeSatisfactionModel(model.model_id)"
                            ><i class="fas fa-times text-danger"></i
                          ></a>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <div v-else>
                      <small class="font-italic text-danger"
                        >Сэдэв сонгоогүй байна!</small
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Нэр</label>
                    <input
                      type="text"
                      v-model="satisfactionForm.title"
                      placeholder="Нэр"
                      class="form-control"
                      :class="{
                        'is-invalid': satisfactionForm.errors.has('title'),
                      }"
                    />
                  </div>
                  <div class="form-group">
                    <label>Тайлбар</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="satisfactionForm.description"
                      placeholder="Дэлгэрэнгүй мэдээлэл..."
                      rows="3"
                      max-rows="6"
                      :class="{
                        'is-invalid': satisfactionForm.errors.has('description'),
                      }"
                    ></b-form-textarea>
                  </div>
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="satisfactionForm.isInput"
                      value="1"
                      unchecked-value="0"
                    >
                      Зарим талбарыг хасах бол сонголт хийнэ үү.
                    </b-form-checkbox>
                  </div>
                  <div v-if="satisfactionForm.isInput == 1">
                    <b-button variant="outline-info" @click="selectAllInputs" size="sm"
                      >Бүх талбар сонгох</b-button
                    >
                    <div class="form-group">
                      <label>Талбар сонгох</label>
                      <multiselect
                        v-model="satisfactionForm.selected_inputs"
                        :options="formOptions"
                        :multiple="true"
                        track-by="id"
                        label="label"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        deselect-label="Хасах"
                        placeholder="Судалгааны талбар сонгоно уу?"
                        :searchable="true"
                        :close-on-select="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div v-if="satisfactionForm.type != 'Дурын хэрэглэгчид'">
                    <div class="form-group">
                      <label>Судалгаанд хамрагдах хэрэглэгч сонгох</label>
                      <b-form-select
                        v-model="satisfactionForm.userType"
                        :options="userTypeOptions"
                        @input="onChangeUserType()"
                        :class="{
                          'is-invalid': satisfactionForm.errors.has('userType'),
                        }"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <b-form-select
                        v-model="satisfactionForm.departments"
                        :options="depOptions"
                        text-field="department_name"
                        value-field="id"
                        v-if="satisfactionForm.userType == 'Багш нарын баг'"
                        multiple
                        :select-size="10"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <b-form-select
                        v-model="satisfactionForm.school_classes"
                        :options="classOptions"
                        text-field="full_name"
                        value-field="id"
                        v-if="
                          satisfactionForm.userType == 'Анги (эцэг, эх)' ||
                          satisfactionForm.userType == 'Анги (суралцагчид)'
                        "
                        multiple
                        :select-size="10"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="satisfactionForm.satisfaction_users"
                        :multiple="true"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        track-by="user_id"
                        label="teacher_name"
                        placeholder="Багш сонгох"
                        :options="teacherOptions"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="false"
                        v-if="satisfactionForm.userType == 'Багшид'"
                        @input="pushSelectedUser()"
                      >
                        <template slot="selection" slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length"
                            v-show="!isOpen"
                          >
                            {{ values.length }} багш сонгогдсон хэрэглэгч рүү
                            нэмэгдсэн</span
                          >
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="satisfactionForm.satisfaction_users"
                        :multiple="true"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        track-by="user_id"
                        label="dad_full_name"
                        placeholder="Эцэг, эх сонгох"
                        :options="parentOptions"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="false"
                        v-if="satisfactionForm.userType == 'Эцэг эхэд'"
                        @input="pushSelectedUser()"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.dad_full_name }}
                        </template>
                        <template slot="option" slot-scope="{ option }">
                          {{ option.full_name }}
                          {{ option.dad_full_name }}
                          {{ option.student_name }}
                        </template>
                        <template slot="selection" slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length"
                            v-show="!isOpen"
                            >{{ values.length }} эцэг, эх сонгогдсон хэрэглэгч рүү
                            нэмэгдсэн</span
                          ></template
                        >
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="satisfactionForm.satisfaction_users"
                        :multiple="true"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                        selected-label="Сонгогдсон"
                        track-by="user_id"
                        placeholder="Суралцагч сонгох"
                        :options="studOptions"
                        label="email"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="false"
                        v-if="satisfactionForm.userType == 'Суралцагчид'"
                        @input="pushSelectedUser()"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.email }}
                        </template>
                        <template slot="selection" slot-scope="{ values, isOpen }"
                          ><span
                            class="multiselect__single"
                            v-if="values.length"
                            v-show="!isOpen"
                            >{{ values.length }} суралцагч сонгогдсон хэрэглэгч рүү
                            нэмэгдсэн</span
                          ></template
                        >
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <label>Сонгогдсон хэрэглэгчид</label>
                      <div v-if="satisfactionForm.users.length > 0">
                        <b-list-group>
                          <b-list-group-item
                            v-for="(user, index) in satisfactionForm.users.filter(
                              (i) => i.isDeleted === 0
                            )"
                            :key="user.id"
                            class="d-flex justify-content-between py-0"
                          >
                            <span>
                              {{ index + 1 }}.
                              {{ user.teacher_name }}
                              {{ user.full_name }}
                              {{ user.student_name }}
                              {{ user.dad_full_name }}
                              {{ user.mom_full_name }}
                              {{ user.email }}
                            </span>
                            <a
                              href="#"
                              size="sm"
                              @click.prevent="removeSatisfactionUser(user)"
                              ><i class="fas fa-times text-danger"></i>
                            </a>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                      <div v-else>
                        <small class="font-italic text-danger">
                          Хэрэглэгч сонгоогүй байна!
                        </small>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="float-right">
                    <b-overlay
                      :show="submitClicked"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        class="btn btn-success"
                        @click.prevent="updateSatisfaction"
                        v-if="editSatisfactionMode"
                      >
                        <i class="fa fa-save mr-1"></i> Засах
                      </b-button>
                      <button
                        v-if="editSatisfactionMode"
                        @click.prevent="cancelSatisfaction"
                        class="btn btn-warning"
                      >
                        Болих
                      </button>
                      <button
                        type="submit"
                        v-if="!editSatisfactionMode"
                        @click.prevent="createSatisfaction"
                        class="btn btn-primary"
                      >
                        <i class="fa fa-save mr-1"></i> Үүсгэх
                      </button>
                    </b-overlay>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
    <b-modal
      size="xl"
      id="modal-satisfaction-users"
      title="Хэрэглэгчид"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp float-left" v-if="satisfactionShow.owner">
              <img
                v-if="satisfactionShow.owner.profile_photo_url"
                class="direct-chat-img mr-2"
                :src="satisfactionShow.owner.profile_photo_url"
                alt="Зураг"
              />
              <img
                v-else
                src="/images/users/user.png"
                class="direct-chat-img mr-2"
                alt="user-avatar"
              />
              <b>Эзэмшигч: </b>{{ satisfactionShow.owner.teacher_name }}
              <br />
              <b>Гарчиг: </b> {{ satisfactionShow.title }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>{{ satisfactionShow.created_at | dateYearMonthDay }} </span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Хэрэглэгчийн төрөл: </b> {{ satisfactionShow.userType }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ satisfactionShow.title }}
            {{ satisfactionShow.description }}
          </div>
        </div>
        <div class="row">
          <div
            class="card col-lg-6 border-success"
            v-if="satisfactionYesUsers && satisfactionYesUsers.data"
          >
            <div class="card-header bg-success text-white">
              <h3 class="card-title">Судалгаанд хамрагдсан</h3>
              <span class="direct-chat-timestamp float-right text-white"
                ><b>Нийт: </b>
                {{ satisfactionYesUsers.total }}
              </span>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
              <b-overlay :show="satYesIsLoaded" rounded="sm">
                <ul class="users-list clearfix overflow-hidden">
                  <li v-for="user in satisfactionYesUsers.data" :key="user.id">
                    <span v-if="user.profile_employee">
                      <img
                        v-if="
                          user.profile_employee.profile_photo_url &&
                          user.profile_employee.profile_photo_url != null
                        "
                        :src="user.profile_employee.profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a class="users-list-name" href="#"
                        >{{ user.profile_employee.teacher_last_name[0] }}.{{
                          user.profile_employee.teacher_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="user.profile_student">
                      <img
                        v-if="
                          user.profile_student.student_profile_photo_url &&
                          user.profile_student.student_profile_photo_url != null
                        "
                        :src="user.profile_student.student_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a class="users-list-name" href="#"
                        >{{ user.profile_student.student_last_name[0] }}.{{
                          user.profile_student.student_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="user.profile_parent">
                      <img
                        v-if="
                          user.profile_parent.parent_father_profile_photo_url &&
                          user.profile_parent.parent_father_profile_photo_url != null
                        "
                        :src="user.profile_parent.parent_father_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a
                        class="users-list-name"
                        v-if="user.profile_parent.parent_father_ovog"
                        href="#"
                        >{{ user.profile_parent.parent_father_ovog[0] }}.{{
                          user.profile_parent.parent_father_name
                        }}</a
                      >
                    </span>
                    <span v-else>
                      <img src="/images/users/user.png" alt="user-avatar" />
                      <a class="users-list-name" href="#">{{ user.user_id }}</a>
                    </span>
                  </li>
                </ul>
              </b-overlay>
              <div class="text-center overflow-auto">
                <pagination
                  :data="satisfactionYesUsers"
                  @pagination-change-page="listYes"
                ></pagination>
              </div>
            </div>
          </div>
          <div
            class="card col-lg-6 border-light"
            v-if="satisfactionNoUsers && satisfactionNoUsers.data"
          >
            <div class="card-header bg-warning">
              <h3 class="card-title">Судалгаанд хамрагдаагүй</h3>
              <span class="direct-chat-timestamp float-right"
                ><b>Нийт: </b>
                {{ satisfactionNoUsers.total }}
              </span>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
              <b-overlay :show="satNoIsLoaded" rounded="sm">
                <ul class="users-list clearfix overflow-hidden">
                  <li v-for="user in satisfactionNoUsers.data" :key="user.id">
                    <span v-if="user.profile_employee">
                      <img
                        v-if="
                          user.profile_employee.profile_photo_url &&
                          user.profile_employee.profile_photo_url != null
                        "
                        :src="user.profile_employee.profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a class="users-list-name" href="#"
                        >{{ user.profile_employee.teacher_last_name[0] }}.{{
                          user.profile_employee.teacher_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="user.profile_student">
                      <img
                        v-if="
                          user.profile_student.student_profile_photo_url &&
                          user.profile_student.student_profile_photo_url != null
                        "
                        :src="user.profile_student.student_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a class="users-list-name" href="#"
                        >{{ user.profile_student.student_last_name[0] }}.{{
                          user.profile_student.student_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="user.profile_parent">
                      <img
                        v-if="
                          user.profile_parent.parent_father_profile_photo_url &&
                          user.profile_parent.parent_father_profile_photo_url != null
                        "
                        :src="user.profile_parent.parent_father_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img v-else src="/images/users/user.png" alt="user-avatar" />
                      <a
                        class="users-list-name"
                        v-if="user.profile_parent.parent_father_ovog"
                        href="#"
                        >{{ user.profile_parent.parent_father_ovog[0] }}.{{
                          user.profile_parent.parent_father_name
                        }}</a
                      >
                    </span>
                  </li>
                </ul>
              </b-overlay>
              <pagination
                class="text-center overflow-auto"
                :data="satisfactionNoUsers"
                @pagination-change-page="listNo"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-satisfaction-result"
      title="Судалгааны үр дүн"
      class="modal fade"
      hide-footer
    >
      <b-overlay :show="isLoadResult" rounded="sm">
        <div class="modal-body">
          <div class="direct-chat-msg">
            <div class="direct-chat-infos clearfix">
              <span
                class="direct-chat-timestamp float-left"
                v-if="satisfactionShow.owner"
              >
                <img
                  v-if="satisfactionShow.owner.profile_photo_url"
                  class="direct-chat-img mr-2"
                  :src="satisfactionShow.owner.profile_photo_url"
                  alt="Зураг"
                />
                <img
                  v-else
                  src="/images/users/user.png"
                  class="direct-chat-img mr-2"
                  alt="user-avatar"
                />
                <b>Эзэмшигч: </b>{{ satisfactionShow.owner.teacher_name }}
                <br />
                <b>Гарчиг: </b> {{ satisfactionShow.title }}
              </span>
              <span class="direct-chat-timestamp float-right"
                ><b>Огноо: </b>{{ satisfactionShow.created_at | dateYearMonthDay }} </span
              ><br />
              <span class="direct-chat-timestamp float-right"
                ><b>Хэрэглэгчийн төрөл: </b> {{ satisfactionShow.userType }}</span
              >
            </div>
            <div class="direct-chat-text primary ml-0">
              {{ satisfactionShow.description }}
            </div>
          </div>
          <div class="row">
            <multiselect
              v-model="selectedClass"
              deselect-label="Хасах"
              select-label="Сонгох"
              selected-label="Сонгогдсон"
              track-by="id"
              label="full_name"
              placeholder="Ангиар шүүж харах"
              :options="classOptions"
              :searchable="true"
              :allow-empty="false"
              @input="getSatisfactionResultData()"
              v-if="satisfactionShow.userType == 'Анги (суралцагчид)'"
              class="my-2"
            >
            </multiselect>
            <div class="card col-lg-12 border-success" v-if="satUsers && satUsers.data">
              <div class="card-header bg-success text-white">
                <span class="direct-chat-timestamp float-right text-white"
                  ><b>Нийт: </b> {{ satisfactionShow.satUserTotal }}/{{ satUsers.total }}
                </span>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <download-excel
                  class="btn btn-success btn-sm"
                  :data="json_data"
                  :fields="json_fields"
                  worksheet="Үр дүн"
                  name="Судалгааны үр дүн.xls"
                >
                  <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                  Файл татах
                </download-excel>
                <b-button
                  :disabled="selectedMethod == null"
                  size="sm"
                  @click.prevent="getSkillList(null)"
                  variant="outline-success"
                >
                  <b-icon icon="award"></b-icon>
                  Шалгалтын дүн оруулах
                </b-button>
                <div
                  v-for="(field, index) in resultFields.filter(
                    (field) => field.type == 'Choice'
                  )"
                  :key="field.key"
                >
                  {{ index + 1 }}. {{ field.label }}
                  <answer-chart
                    :show="true"
                    :labels="field.labels"
                    :datas="field.datas"
                  />
                </div>
                <table class="table table-hover">
                  <thead>
                    <tr role="row">
                      <th v-for="field in resultFields" :key="field.key">
                        {{ field.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(satItem, index) in satUsers.data" :key="satItem.id">
                      <td class="dtr-control sorting_1" tabindex="12">
                        {{ index + 1 }}
                      </td>
                      <td>
                        <span v-if="satItem.profile_employee">
                          <img
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              satItem.profile_employee.profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Судалгаа өгсөн</dt><dd>' +
                              satItem.profile_employee.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              satItem.profile_employee.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              satItem.profile_employee.profile_photo_url
                                ? satItem.profile_employee.profile_photo_url
                                : '/images/users/user.png'
                            "
                            alt="user-avatar"
                          />
                          {{ satItem.profile_employee.teacher_last_name[0] }}.{{
                            satItem.profile_employee.teacher_name
                          }}
                        </span>
                        <span v-else-if="satItem.profile_student">
                          <img
                            :src="
                              satItem.profile_student.student_profile_photo_url
                                ? satItem.profile_student.student_profile_photo_url
                                : '/images/users/user.png'
                            "
                            alt="user-avatar"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              satItem.profile_student.student_profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Судалгаа өгсөн</dt><dd>' +
                              satItem.profile_student.student_last_name +
                              ' <span class=text-uppercase>' +
                              satItem.profile_student.student_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                          />
                          {{ satItem.profile_student.student_last_name[0] }}.{{
                            satItem.profile_student.student_name
                          }}
                        </span>
                        <span v-else-if="satItem.profile_parent">
                          <img
                            :src="
                              satItem.profile_parent.parent_father_profile_photo_url
                                ? satItem.profile_parent.parent_father_profile_photo_url
                                : '/images/users/user.png'
                            "
                            alt="user-avatar"
                            v-b-tooltip.hover.top.html="
                              '<dd><img src=' +
                              satItem.profile_parent.parent_father_profile_photo_url +
                              ' style=' +
                              'width:180px!important' +
                              '></dd><dt>Судалгаа өгсөн</dt><dd>' +
                              satItem.profile_parent.parent_father_ovog +
                              ' <span class=text-uppercase>' +
                              satItem.profile_parent.parent_father_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                          />
                          <span v-if="satItem.profile_parent.parent_father_ovog">
                            {{ satItem.profile_parent.parent_father_ovog[0] }}.
                          </span>
                          {{ satItem.profile_parent.parent_father_name }}
                        </span>
                        <span v-else>
                          <img src="/images/users/user.png" class="table-avatar" />
                          {{ satItem.user_id }}
                        </span>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <template #append>
                            <b-overlay :show="isLoadExam" rounded="sm">
                              <b-button
                                :disabled="selectedMethod == null"
                                size="sm"
                                @click.prevent="
                                  getMethodResult(
                                    satItem.user_id,
                                    satItem.user_answers[0].answer
                                  )
                                "
                                v-b-toggle.sidebar-method
                                variant="outline-secondary"
                              >
                                <b-icon
                                  icon="gear-wide-connected"
                                  animation="spin"
                                ></b-icon>
                                ТООЦООЛОХ
                              </b-button>
                              <b-button
                                :disabled="selectedMethod == null"
                                size="sm"
                                @click.prevent="getSkillList(satItem.user_id)"
                                variant="outline-success"
                              >
                                <b-icon icon="award"></b-icon>
                                ШАЛГАЛТ
                              </b-button>
                              <b-button
                                size="sm"
                                @click.prevent="deleteResult(satItem.user_id)"
                                variant="outline-danger"
                              >
                                <b-icon icon="trash"></b-icon>
                                Устгах
                              </b-button>
                            </b-overlay>
                          </template>
                        </b-input-group>
                      </td>
                      <td>
                        <span>
                          {{ satItem.updated_at | dateYearMonthDay }}
                        </span>
                      </td>
                      <td v-for="item in satItem.user_answers" :key="item.input.id">
                        <p v-if="item.input.type == 'Choice'">
                          <b-badge
                            v-for="option in getOption(item.answer)"
                            :key="option"
                            class="mr-3"
                          >
                            {{ option }}
                          </b-badge>
                        </p>
                        <p v-else>
                          {{ item.answer }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="satUsers.total == 0">
                    <tr>
                      <th :colspan="resultFields.length">
                        <p class="text-center text-muted h5 mt-5">
                          <b-icon icon="inboxes"></b-icon>
                        </p>
                        <p class="text-center text-muted font-italic">
                          Одоогоор судалгааны үр дүн байхгүй байна.
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <pagination
                  class="text-center overflow-auto"
                  :data="satUsers"
                  @pagination-change-page="listSatResults"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-result-skill"
      title="Судалгааны үр дүнг чадварт хадгалах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp float-left" v-if="satisfactionShow.owner">
              <img
                v-if="satisfactionShow.owner.profile_photo_url"
                class="direct-chat-img mr-2"
                :src="satisfactionShow.owner.profile_photo_url"
                alt="Зураг"
              />
              <img
                v-else
                src="/images/users/user.png"
                class="direct-chat-img mr-2"
                alt="user-avatar"
              />
              <b>Эзэмшигч: </b>{{ satisfactionShow.owner.teacher_name }}
              <br />
              <b>Гарчиг: </b> {{ satisfactionShow.title }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>{{ satisfactionShow.created_at | dateYearMonthDay }} </span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Хэрэглэгчийн төрөл: </b> {{ satisfactionShow.userType }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ satisfactionShow.description }}
          </div>
        </div>
        <div class="row">
          <div class="card col-lg-12 border-success" v-if="satUsers && satUsers.data">
            <div class="card-body">
              <multiselect
                v-model="selectedSgs"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                track-by="id"
                label="name"
                placeholder="Чадвар сонгох"
                :options="skillOptions"
                :searchable="true"
                :allow-empty="false"
                class="my-2"
              >
              </multiselect>
              <b-button
                :disabled="selectedMethod == null"
                size="sm"
                @click.prevent="setSkillPoints(null)"
                variant="outline-success"
              >
                <b-icon icon="award"></b-icon>
                Шалгалтын дүн оруулах
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal size="sm" id="modal-qr" title="QR код">
      <div class="text-center">
        <p>
          <b>{{ qrShow.name }}</b>
        </p>
        <p style="text-align: -webkit-center">
          <qr-code class="qrCode" :text="qrShow.url"></qr-code>
        </p>
        <p class="mt-3">
          <b>Холбоос: </b>
          <a :href="qrShow.url" target="_blank">Харах</a>
        </p>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-method"
      backdrop-variant="info"
      left
      z-index="1045"
      shadow
      width="100%"
      backdrop
    >
      <template #footer>
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <strong class="mr-auto"></strong>
          <button
            size="sm"
            @click="printResult"
            variant="light"
            class="btn btn-light text-right"
            type="button"
          >
            <b-icon icon="printer" />
            Хэвлэх
          </button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card" ref="printContent" id="section-to-print">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <b-overlay
                  :show="isLoadMethod"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <div class="clearfix">
                    <b-img
                      v-if="schoolInfo"
                      left
                      :src="schoolInfo.school_logo_url"
                      style="width: 70px"
                    ></b-img>
                    <p class="text-right mt-5">
                      {{ timestamp }}<br />
                      Судалгаанд хамрагдсан: <b>{{ userInfo }}</b>
                    </p>
                  </div>
                  <h3 class="text-center" v-if="selectedMethod">
                    {{ selectedMethod.name }}
                  </h3>
                  <div class="d-flex justify-content-center" v-if="!isLoadMethod">
                    <apexchart
                      v-if="chartType == 'pie'"
                      width="600"
                      type="donut"
                      :options="optionsPieChart"
                      :series="seriesChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'bar' && resultData.length > 1"
                      type="bar"
                      width="600"
                      :options="optionsBarChart"
                      :series="seriesBarChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'radar'"
                      type="radar"
                      width="800"
                      :options="optionsRadarChart"
                      :series="seriesBarChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'area'"
                      type="area"
                      width="1300"
                      height="400"
                      :options="optionsAreaChart"
                      :series="seriesAreaChart"
                    ></apexchart>
                  </div>
                  <p v-if="selectedMethod" v-html="selectedMethod.description"></p>
                  <div class="row" v-if="resultData.length > 3">
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in resultData"
                      :key="data.id"
                    >
                      <b>{{ index + 1 }}. {{ data.name }}</b>
                      <span v-html="data.result" class="text-justify"></span>
                      <span v-html="data.description" class="text-justify"></span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(data, index) in resultData" :key="data.id">
                      <b>{{ index + 1 }}. {{ data.name }}</b>
                      <span v-html="data.result" class="text-justify"></span>
                      <span v-html="data.description" class="text-justify"></span>
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
    <!-- Хэрэглэгч нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-users"
      title="Хэрэглэгч нарын мэдээлэл"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="light"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">{{ showForm.title }}</span>
            <span class="direct-chat-timestamp float-right"
              ><b>Үүсгэсэн: </b>{{ showForm.created_at | dateYearMonthDay }} </span
            ><br />
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ showForm.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Хэрэглэгч нар</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="formUser != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Зураг</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody v-for="(formUser, index) in formUsers" :key="formUser.user_id">
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="formUser.profile_photo_url != null"
                          :src="formUser.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ formUser.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ formUser.teacher_lname[0] }}.
                        {{ formUser.teacher_fname }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a href="#" class="badge bg-danger" @click="removeUser(formUser)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Харьяалагдах мэргэжлийн баг</label>
                    <b-form-select
                      v-model="formUser.department_id"
                      :options="departmentOptions"
                      text-field="department_name"
                      value-field="id"
                      @input="getDepUsers(formUser.department_id)"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formUser.users"
                      :options="userOptions"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="10"
                    ></b-form-select>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button type="submit" class="btn btn-success" @click.prevent="addUser()">
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Pagination from "laravel-vue-pagination";
import answerChart from "../Charts/pie.vue";
export default {
  data() {
    return {
      //   vue-quill-editor
      year_id: "",
      editorOption: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      modelOptions: [],
      myForms: [],
      formTitle: "Судалгааны форм нэмэх",
      editFormMode: false,
      form: new Form({
        id: "",
        title: "",
        description: "",
        inputs: [],
        usersCount: 0,
      }),
      addInput: false,
      isFade: false,
      levelOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      symbolOptions: [
        "Star",
        "Number",
        "Heart",
        "Hand-thumbs-up",
        "Emoji-smile",
        "Flag",
        "Lightbulb",
        "Trophy",
        "Check-circle",
      ],
      filterOn: [],
      fields: [
        { key: "index", label: "№" },
        { key: "status", label: "Төлөв", sortable: true },
        { key: "actions", label: "Үйлдлүүд" },
        { key: "owner", label: "Үүсгэсэн", sortable: true },
        { key: "title", label: "Нэр", sortable: true },
        {
          key: "description",
          label: "Тайлбар",
          sortable: true,
          class: "description-col",
        },
        // { key: "inputs", label: "Талбар", sortable: false },
      ],
      selectedClass: 0,
      satisfactionForm: new Form({
        id: "",
        isUseMethod: 0,
        method: null,
        status: 0,
        type: "Сэдэвт суурилаагүй",
        form_id: "",
        owner: null,
        title: "",
        description: "",
        selected_inputs: [],
        isInput: 0,
        selected_models: [],
        satisfaction_users: [],
        satisfaction_models: [],
        userType: "",
        departments: [],
        school_classes: [],
        users: [],
      }),
      formOptions: [],
      satisfactions: [],
      satisfactionTypeOptions: [
        "Сэдэвт суурилаагүй",
        "Сэдэвт суурилсан",
        "Дурын хэрэглэгчид",
      ],
      modelDataOptions: [],
      isModelSelected: false,
      selectedModel: null,
      selectedModelDatas: [],
      userTypeOptions: [
        "Бүх ажилчид",
        "Бүх багш нар",
        "Бүх эцэг эх",
        "Бүх суралцагчид",
        "Багш нарын баг",
        "Анги (эцэг, эх)",
        "Анги (суралцагчид)",
        "Багшид",
        "Эцэг эхэд",
        "Суралцагчид",
      ],
      depOptions: [],
      classOptions: [],
      teacherOptions: [],
      parentOptions: [],
      studOptions: [],
      editSatisfactionMode: false,
      satisfactionUserType: "",
      satisfactionYesUsers: [],
      satisfactionNoUsers: [],
      satisfactionShow: new Form({
        id: "",
        title: "",
        description: "",
        owner: null,
        userType: "",
        created_at: "",
        satUserTotal: [],
      }),
      skillUserId: null,
      selectedSgs: null,
      skillOptions: [],
      satYesIsLoaded: true,
      submitClicked: false,
      isLoaded: false,
      satisfactionResult: [],
      resultFilterOn: [],
      resultFields: [
        { key: "index", label: "№" },
        { key: "owner", label: "Хамрагдсан", sortable: true },
        { key: "date", label: "Хамрагдсан огноо", sortable: true },
      ],
      isLoadResult: false,
      isLoadExam: false,
      satUsers: [],
      qrShow: { name: "", url: "" },
      isLoadMethod: false,
      selectedMethod: null,
      methodOptions: [],
      resultData: [],
      answer: [],
      schoolInfo: null,
      timestamp: "",
      userInfo: "",
      chartType: "",
      seriesChart: [],
      seriesBarChart: [
        {
          name: "Үр дүн",
          data: [],
        },
      ],
      seriesAreaChart: [],
      optionsPieChart: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
      optionsBarChart: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom`
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsRadarChart: {
        chart: {
          height: 350,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#8eceff", "#cbe6fa"],
              },
            },
          },
        },
        colors: ["#FF4560"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
            },
          },
        },
      },
      optionsAreaChart: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "string",
          categories: [],
          showDuplicates: false,
          offsetX: 60,
          labels: {
            show: true,
            style: {
              fontSize: "8px",
              fontWeight: "bold",
            },
          },
        },
      },
      colors: [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#ff4560",
        "#701add",
        "#c90076",
        "#ebdb5e",
        "#3b185a",
        "#134f5c",
        "#ffde14",
      ],
      departmentOptions: [],
      userOptions: [],
      formUsers: [],
      showForm: new Form({
        id: "",
        title: "",
        created_at: "",
        description: "",
      }),
      formUser: new Form({
        id: "",
        form_id: "",
        department_id: "",
        users: [],
      }),
      userTypes: [
        { name: "Гишүүн", value: "member" },
        { name: "Ахлах", value: "senior" },
      ],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    Multiselect,
    Pagination,
    answerChart,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    filteredInputs() {
      return this.form.inputs.filter((input) => {
        return input.isRemoved == false;
      });
    },
    baseURL() {
      return (
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
      );
    },
  },
  watch: {
    "satisfactionForm.userType": function (newVal) {
      if (newVal == "Багш нарын баг")
        axios.get("/manager/getAllDepartments/").then((response) => {
          this.depOptions = response.data.departments;
        });
      else if (newVal == "Анги (эцэг, эх)" || newVal == "Анги (суралцагчид)")
        axios.get("/physical/getAllSchoolClass/").then((response) => {
          this.classOptions = response.data.schoolclasses;
        });
      else if (newVal == "Багшид")
        axios.get("/manager/getAllTeacher/").then((response) => {
          this.teacherOptions = response.data.teachers;
        });
      else if (newVal == "Эцэг эхэд")
        axios.get("/manager/getAllUsersParent/").then((response) => {
          this.parentOptions = response.data.parents;
        });
      else if (newVal == "Суралцагчид")
        axios.get("/manager/getAllStudents").then((response) => {
          this.studOptions = response.data.students;
        });
    },
    "satisfactionForm.type": function (newVal) {
      if (newVal == "Сэдэвт суурилаагүй" || newVal == "Дурын хэрэглэгчид") {
        this.isModelSelected = false;
        this.satisfactionForm.satisfaction_models = [];
        if (newVal == "Дурын хэрэглэгчид")
          this.satisfactionForm.userType = "Дурын хэрэглэгчид";
      } else {
        this.isModelSelected = true;
        this.selectedModelDatas = [];
      }
    },
  },
  methods: {
    //#region Form create
    order() {
      this.form.inputs = _.orderBy(this.form.inputs, "order");
    },
    getAllForm() {
      axios
        .get("/manager/getAllForm")
        .then((response) => {
          this.myId = response.data.myId;
          this.myForms = response.data.myForms;
        })
        .catch(() => {});
    },
    addForm() {
      this.editFormMode = false;
      this.formTitle = "Судалгааны форм нэмэх";
      this.form.reset();
    },
    showButtons() {
      this.addInput = !this.addInput;
    },
    //Choice box
    addChoice() {
      this.order();
      this.addInput = !this.addInput;
      this.form.inputs.push({
        id: null,
        elementId: this.filteredInputs.length + 1,
        order: this.filteredInputs.length + 1,
        type: "Choice",
        label: "",
        description: "",
        valueOptions: [
          { order: 1, option: "" },
          { order: 2, option: "" },
        ],
        value: "",
        isRequired: 1,
        isNumberOrText: 0,
        isMultiple: 0,
        isMethod: 1,
        symbol: "",
        isRemoved: false,
        isChanged: false,
      });
    },
    addOption(input) {
      input.isChanged = true;
      let optionId = 0;
      if (input.valueOptions.length > 0)
        optionId = input.valueOptions[input.valueOptions.length - 1].order;
      input.value = input.value + "<^>";
      input.valueOptions.push({
        order: optionId + 1,
        option: "",
      });
    },
    removeOption(input, index) {
      if (input.valueOptions.length > 1) {
        input.isChanged = true;
        input.valueOptions.splice(index, 1);
        input.value = "<^>";
        input.valueOptions.forEach((val) => {
          input.value = val.option + "<^>";
        });
      }
    },
    //Text input
    addTextInput() {
      this.order();
      this.addInput = !this.addInput;
      this.form.inputs.push({
        id: null,
        elementId: this.filteredInputs.length + 1,
        order: this.filteredInputs.length + 1,
        type: "Text",
        label: "",
        description: "",
        value: "",
        isRequired: 1,
        isNumberOrText: 1,
        isMultiple: 0,
        isMethod: 0,
        symbol: "",
        isRemoved: false,
        isChanged: false,
      });
    },
    //Rate input
    addRateInput() {
      this.order();
      this.addInput = !this.addInput;
      this.form.inputs.push({
        id: null,
        elementId: this.filteredInputs.length + 1,
        order: this.filteredInputs.length + 1,
        type: "Rate",
        label: "",
        description: "",
        value: 5,
        isRequired: 1,
        isNumberOrText: 0,
        isMultiple: 0,
        isMethod: 0,
        symbol: "Star",
        isRemoved: false,
        isChanged: false,
      });
    },
    //Date input
    addDateInput() {
      this.order();
      this.addInput = !this.addInput;
      this.form.inputs.push({
        id: null,
        elementId: this.filteredInputs.length + 1,
        order: this.filteredInputs.length + 1,
        type: "Date",
        label: "",
        description: "",
        value: "",
        isRequired: 1,
        isNumberOrText: 0,
        isMultiple: 0,
        isMethod: 0,
        symbol: "",
        isRemoved: false,
        isChanged: false,
      });
    },
    //Card tools
    removeAllInput() {
      this.form.inputs.forEach((element) => {
        element.isRemoved = true;
        element.isChanged = true;
      });
      this.order();
    },
    reorderAllInput() {
      this.form.inputs.forEach((element, index) => {
        element.order = index + 1;
        element.isChanged = true;
      });
      this.order();
    },
    setMethodAllInput() {
      this.form.inputs.forEach((element, index) => {
        element.isMethod = 1;
        element.isChanged = true;
      });
      this.order();
    },
    getInputBool(value) {
      if (value == 1) return true;
      else return false;
    },
    inputChanged(input) {
      input.isChanged = true;
    },
    changeIsRequire(input) {
      input.isRequired = !input.isRequired;
      input.isChanged = true;
    },
    changeIsMethod(input) {
      input.isMethod = !input.isMethod;
      input.isChanged = true;
    },
    changeIsNumberOrText(input) {
      input.isChanged = true;
      input.isNumberOrText = !input.isNumberOrText;
    },
    changeIsMultiple(input) {
      input.isChanged = true;
      input.isMultiple = !input.isMultiple;
    },
    upInputOrder(input) {
      if (this.form.inputs.length > 1 && input.order + 1 <= this.form.inputs.length) {
        input.isChanged = true;
        this.form.inputs.reverse().forEach((element) => {
          if (element.order == input.order + 1 && element.elementId != input.elementId) {
            element.isChanged = true;
            element.order = input.order;
          }
          //next
          else if (element.elementId == input.elementId) element.order = input.order + 1; //current
        });
        this.isFade = true;
      }
      this.order();
    },
    downInputOrder(input) {
      if (this.form.inputs.length > 1 && input.order > 1) {
        input.isChanged = true;
        this.form.inputs.forEach((element) => {
          if (element.order == input.order - 1 && element.elementId != input.elementId) {
            element.isChanged = true;
            element.order = input.order;
          }
          //before
          else if (element.elementId == input.elementId) element.order = input.order - 1; //current
        });
        this.isFade = true;
      }
      this.order();
    },
    removeInput(input) {
      if (this.filteredInputs.length > 1) {
        input.isRemoved = true;
        this.form.inputs.forEach((element) => {
          if (element.order > input.order) {
            element.isChanged = true;
            element.elementId = element.elementId - 1;
            element.order = element.order - 1;
          }
        });
      }
      this.order();
    },
    //end input card tools
    createForm() {
      this.submitClicked = true;
      this.form
        .post("/manager/createForm")
        .then(() => {
          this.$toastr.s("Судалгааны форм амжилттай нэмлээ.", "Амжилттай");
          this.getAllForm();
          this.form.reset();
          this.editFormMode = false;
          this.submitClicked = false;
        })
        .catch(() => {
          this.submitClicked = false;
        });
    },
    editForm(form) {
      this.form.reset();
      this.formTitle = "Судалгааны форм засах";
      axios
        .get("/manager/getForm/" + form.id)
        .then((response) => {
          this.form.fill(response.data.form);
          this.form.usersCount = response.data.usersCount;
          this.form.inputs.forEach(function (input) {
            input.isRemoved = false;
            input.isChanged = false;
            input.elementId = input.id;
            if (input.type == "Choice") {
              let inputList = [];
              input.valueOptions = [];
              inputList = input.value.split("<^>");
              inputList.forEach((val, index) => {
                if (val != "") {
                  input.valueOptions.push({
                    order: index,
                    option: val,
                  });
                }
              });
            }
          });
          this.editFormMode = true;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    updateForm() {
      this.submitClicked = true;
      this.form
        .put("/manager/updateForm/" + this.form.id)
        .then(() => {
          this.$toastr.s("Судалгааны форм амжилттай заслаа.", "Амжилттай");
          this.getAllForm();
          this.form.reset();
          this.editFormMode = false;
          this.submitClicked = false;
        })
        .catch(() => {
          this.submitClicked = false;
        });
    },
    deleteForm(satformId) {
      Swal.fire({
        title: "Судалгааны форм устгах!",
        text: "Судалгааны форм устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Судалгааны форм хасаж байна...";
          axios
            .get("/manager/deleteForm/" + satformId)
            .then((response) => {
              if (!response.data.errorMsj) {
                this.$toastr.s("Судалгааны форм амжилттай устгалаа!", "Шинэчилсэн");
                this.getAllForm();
              } else this.$toastr.w(response.data.errorMsj, "Анхаар!");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    updateFormStatus(formId) {
      axios
        .put("/manager/updateFormStatus/" + formId)
        .then((response) => {
          if (!response.data.errorMsj) {
            this.$toastr.s("Амжилттай", "Шинэчилсэн");
            this.getAllForm();
          } else this.$toastr.w(response.data.errorMsj, "Анхаар");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    //#endregion
    //#region Сэтгэл ханамжийн судалгаа
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    resetSatisfaction() {
      this.isLoaded = false;
      this.isModelSelected = false;
      this.modelDataOptions = [];
      this.selectedModel = null;
      this.selectedModelDatas = [];
      this.depOptions = [];
      this.classOptions = [];
      this.teacherOptions = [];
      this.parentOptions = [];
      this.studOptions = [];
      this.editSatisfactionMode = false;
      this.satisfactionForm.reset();
      this.satisfactionForm.form_id = this.form.id;
      this.satisfactionForm.title = this.form.title;
      this.satisfactionForm.description = this.form.description;
      this.submitClicked = false;
    },
    addSatisfaction(form) {
      this.form.fill(form);
      this.resetSatisfaction();
      this.getSatisfactions();
    },
    getSatisfactions() {
      this.isLoaded = true;
      axios
        .post("/manager/getSatisfactions/" + this.form.id, {
          year_id: this.year_id.id,
        })
        .then((response) => {
          this.satisfactions = response.data.satisfactions;
          this.modelOptions = response.data.satisfactionModels;
          this.formOptions = response.data.formInputs;
          this.myId = response.data.myId;
          this.isLoaded = false;
        })
        .catch((errors) => {
          this.isLoaded = false;
          //   console.log(errors);
        });
      axios
        .get("/manager/getMethods")
        .then((response) => {
          this.methodOptions = response.data.methods;
        })
        .catch((errors) => {});
    },
    getModelDatas() {
      axios
        .post("/manager/getModelDatas", {
          selected_model: this.selectedModel.name,
        })
        .then((response) => {
          this.modelDataOptions = response.data.modelDataOptions;
          this.selectedModelDatas = [];
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    pushSelectedModel() {
      this.satisfactionForm.satisfaction_models = this.satisfactionForm.satisfaction_models.filter(
        (i) => i.model_name !== this.selectedModel.name
      );
      this.selectedModelDatas.forEach((element) => {
        this.satisfactionForm.satisfaction_models.push({
          title: this.selectedModel.title,
          model_name: this.selectedModel.name,
          modelData: element.name,
          model_id: element.id,
        });
      });
    },
    getStatusBool(value) {
      if (value == "active") return true;
      else return false;
    },
    changeStatus(satisfactionId) {
      axios
        .put("/manager/updateSatisfactionStatus/" + satisfactionId)
        .then((response) => {
          if (!response.data.errorMsj) {
            this.$toastr.s("Амжилттай", "Шинэчилсэн");
            this.getSatisfactions();
          } else this.$toastr.w(response.data.errorMsj, "Анхаар");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeSatisfactionModel(modelId) {
      this.satisfactionForm.satisfaction_models = this.satisfactionForm.satisfaction_models.filter(
        (i) => i.model_id !== modelId
      );
    },
    onChangeUserType() {
      this.satisfactionForm.satisfaction_users = [];
    },
    pushSelectedUser() {
      this.satisfactionForm.satisfaction_users.forEach((satUser) => {
        let isDuplicate = [];
        isDuplicate = this.satisfactionForm.users.filter((i) => i.id == satUser.user_id);
        if (isDuplicate.length == 0) {
          if (this.satisfactionForm.userType == "Багшид")
            this.satisfactionForm.users.push({
              id: satUser.user_id,
              teacher_name: satUser.teacher_name,
              isDeleted: 0,
            });
          else if (this.satisfactionForm.userType == "Эцэг эхэд")
            this.satisfactionForm.users.push({
              id: satUser.user_id,
              dad_full_name:
                satUser.full_name +
                ", " +
                satUser.student_name +
                ", " +
                satUser.dad_full_name +
                ", " +
                satUser.mom_full_name,
              isDeleted: 0,
            });
          else if (this.satisfactionForm.userType == "Суралцагчид")
            this.satisfactionForm.users.push({
              id: satUser.user_id,
              email: satUser.email,
              isDeleted: 0,
            });
        } else isDuplicate[0].isDeleted = 0;
      });
    },
    removeSatisfactionUser(user) {
      if (this.satisfactionForm.users.length > 1) user.isDeleted = 1;
      this.satisfactionForm.satisfaction_users = [];
    },
    selectAllInputs() {
      this.satisfactionForm.selected_inputs = this.formOptions;
    },
    createSatisfaction() {
      this.submitClicked = true;
      if (
        this.isModelSelected == true &&
        this.satisfactionForm.satisfaction_models.length == 0
      ) {
        this.$toastr.e("Сэдэв сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      }
      if (
        this.satisfactionForm.userType == "Багш нарын баг" &&
        this.satisfactionForm.departments.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Багш нарын баг сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      } else if (
        (this.satisfactionForm.userType == "Анги (эцэг, эх)" ||
          this.satisfactionForm.userType == "Анги (суралцагчид)") &&
        this.satisfactionForm.school_classes.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Анги сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      } else if (
        (this.satisfactionForm.userType == "Багшид" ||
          this.satisfactionForm.userType == "Эцэг эхэд" ||
          this.satisfactionForm.userType == "Суралцагчид") &&
        this.satisfactionForm.users.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Хэрэглэгч сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      }
      this.satisfactionForm
        .post("/manager/createSatisfaction")
        .then(() => {
          this.$toastr.s("Судалгаа амжилттай нэмлээ.", "Амжилттай");
          this.getSatisfactions();
          this.resetSatisfaction();
          this.editSatisfactionMode = false;
        })
        .catch(() => {
          this.submitClicked = false;
        });
    },
    editSatisfaction(satisfaction) {
      this.editSatisfactionMode = true;
      this.satisfactionUserType = satisfaction.userType;
      this.satisfactionForm.fill(satisfaction);
      this.satisfactionForm.users = [];
      this.satisfactionForm.isInput = satisfaction.inputs == "-" ? 0 : 1;
      this.satisfactionForm.departments = [];
      this.satisfactionForm.school_classes = [];
      this.satisfactionForm.isUseMethod = satisfaction.method == null ? 0 : 1;
      this.satisfactionForm.satisfaction_users.forEach((satUser) => {
        if (satUser.user != null)
          this.satisfactionForm.users.push({
            id: satUser.user_id,
            teacher_name: satUser.user.name ? satUser.user.name : "",
            email: satUser.user.email ? satUser.user.email : "",
            dad_full_name: satUser.profile_parent
              ? satUser.profile_parent.parent_father_ovog
                ? satUser.profile_parent.parent_father_ovog.substring(0, 2) +
                  "." +
                  satUser.profile_parent.parent_father_name
                : ""
              : "",
            isDeleted: 0,
          });
      });
    },
    cancelSatisfaction() {
      this.editSatisfactionMode = false;
      this.resetSatisfaction();
    },
    updateSatisfaction() {
      this.submitClicked = true;
      if (
        this.isModelSelected == true &&
        this.satisfactionForm.satisfaction_models.length == 0
      ) {
        this.$toastr.e("Сэдэв сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      }
      if (
        this.satisfactionForm.userType == "Багш нарын баг" &&
        this.satisfactionForm.departments.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Багш нарын баг сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      } else if (
        (this.satisfactionForm.userType == "Анги (эцэг, эх)" ||
          this.satisfactionForm.userType == "Анги (суралцагчид)") &&
        this.satisfactionForm.school_classes.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Анги сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      } else if (
        (this.satisfactionForm.userType == "Багшид" ||
          this.satisfactionForm.userType == "Эцэг эхэд" ||
          this.satisfactionForm.userType == "Суралцагчид") &&
        this.satisfactionForm.users.length == 0
      ) {
        this.$toastr.e("Судалгаа өгөх Хэрэглэгч сонгоно уу!", "Анхаар");
        this.submitClicked = false;
        return;
      }
      if (this.satisfactionForm.isInput == 0) this.satisfactionForm.selected_inputs = [];
      this.satisfactionForm
        .put("/manager/updateSatisfaction/" + this.satisfactionForm.id)
        .then(() => {
          this.$toastr.s("Судалгаа амжилттай заслаа.", "Амжилттай");
          this.getSatisfactions();
          this.resetSatisfaction();
          this.editSatisfactionMode = false;
        })
        .catch(() => {
          this.submitClicked = false;
        });
    },
    deleteSatisfaction(satId) {
      Swal.fire({
        title: "Судалгаа устгах!",
        text: "Судалгаа устгах гэж байна. Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "error",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Судалгаа хасаж байна...";
          axios
            .get("/manager/deleteSatisfaction/" + satId)
            .then((response) => {
              if (!response.data.errorMsj) {
                this.$toastr.s("Судалгаа амжилттай устгалаа!", "Шинэчилсэн");
                this.getSatisfactions();
              } else this.$toastr.w(response.data.errorMsj, "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    showSatisfactionUsers(sat) {
      this.satYesIsLoaded = true;
      this.satNoIsLoaded = true;
      this.satisfactionShow.fill(sat);
      this.$bvModal.show("modal-satisfaction-users");
      axios
        .get("/manager/getSatisfactionYesUsers/" + sat.id)
        .then((response) => {
          this.satisfactionYesUsers = response.data.satisfactionYesUsers;
          this.satYesIsLoaded = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/manager/getSatisfactionNoUsers/" + sat.id)
        .then((response) => {
          this.satisfactionNoUsers = response.data.satisfactionNoUsers;
          this.satNoIsLoaded = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    async listYes(page = 1) {
      this.satYesIsLoaded = true;
      await axios
        .get(`/manager/getSatisfactionYesUsers/${this.satisfactionShow.id}?page=${page}`)
        .then(({ data }) => {
          this.satisfactionYesUsers = data.satisfactionYesUsers;
          this.satYesIsLoaded = false;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    async listNo(page = 1) {
      this.satNoIsLoaded = true;
      await axios
        .get(`/manager/getSatisfactionNoUsers/${this.satisfactionShow.id}?page=${page}`)
        .then(({ data }) => {
          this.satisfactionNoUsers = data.satisfactionNoUsers;
          this.satNoIsLoaded = false;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    getOption(optionValue) {
      let optionList = [];
      let option = [];
      optionList = optionValue.split("<^>");
      optionList.forEach((val) => {
        if (val != "") {
          option.push(val);
        }
      });
      return option;
    },
    showSatisfactionQR(sat) {
      if (sat.type == "Дурын хэрэглэгчид")
        this.qrShow.url = this.baseURL + "/enterSatisfaction/" + sat.id;
      else this.qrShow.url = this.baseURL + "/all_worker_functions/satisfactions";
      this.qrShow.name = sat.title;
      this.$bvModal.show("modal-qr");
    },
    //#endregion
    //#region Result
    getSatisfactionResult(sat) {
      this.selectedClass = null;
      this.isLoadResult = true;
      this.selectedMethod = sat.method;
      this.satisfactionShow.fill(sat);
      this.satisfactionShow.satUserTotal = sat.satisfaction_users.length;
      axios
        .get("/manager/schoolLogo")
        .then((response) => {
          this.schoolInfo = response.data.schoolLogo[0];
        })
        .catch((errors) => {});
      if (sat.userType == "Анги (суралцагчид)")
        axios.get("/form_satisfaction/getAllSchoolClass/").then((response) => {
          this.classOptions = response.data.schoolclasses;
        });
      this.getSatisfactionResultData();
      this.$bvModal.show("modal-satisfaction-result");
    },
    getSatisfactionResultData() {
      this.resultFields = [
        { key: "index", label: "№" },
        { key: "owner", label: "Үүсгэсэн" },
        { key: "method", label: "Аргачлал" },
        { key: "date", label: "Хамрагдсан огноо", sortable: true },
      ];
      axios
        .post("/form_satisfaction/getSatisfactionResult/" + this.satisfactionShow.id, {
          classId: this.selectedClass ? this.selectedClass.id : 0,
        })
        .then((response) => {
          this.satUsers = response.data.satUsers;
          var jsonF = {};
          Object.assign(jsonF, {
            Үүсгэсэн: "created",
          });
          response.data.satisfactionInputs.forEach((data) => {
            this.resultFields.push({
              key: data.id,
              label: data.label,
              type: data.type,
              labels: data.labels,
              datas: data.datas,
            });
            var obj = {};
            obj = { [data.label]: data.id };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
          var jsonData = [];
          response.data.satUsers.data.forEach((data) => {
            var obj1 = {};
            if (data.profile_employee)
              Object.assign(obj1, {
                created:
                  data.profile_employee.teacher_name[0] +
                  "." +
                  data.profile_employee.teacher_name,
              });
            else if (data.profile_student)
              Object.assign(obj1, {
                created:
                  data.profile_student.student_last_name[0] +
                  "." +
                  data.profile_student.student_name,
              });
            else Object.assign(obj1, { created: data.user_id });
            data.user_answers.forEach((answer) => {
              Object.assign(obj1, { [answer.input.id]: answer.answer });
            });
            jsonData.push(obj1);
          });
          this.json_data = jsonData;
          this.isLoadResult = false;
        })
        .catch((errors) => {
          this.isLoadResult = false;
        });
    },
    deleteResult(userId) {
      Swal.fire({
        title: "Үр дүн хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Үр дүн хасаж байна...";
          axios
            .post("/manager/deleteResult", {
              userId: userId,
              satId: this.satisfactionShow.id,
            })
            .then(() => {
              this.getSatisfactionResultData();
              this.$toastr.s("Үр дүн амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    async listSatResults(page = 1) {
      this.isLoadResult = true;
      await axios
        .post(
          `/form_satisfaction/getSatisfactionResult/${this.satisfactionShow.id}?page=${page}`,
          {
            classId: this.selectedClass ? this.selectedClass.id : 0,
          }
        )
        .then(({ data }) => {
          this.satUsers = data.satUsers;
          var jsonData = [];
          this.json_data = [];
          data.satUsers.data.forEach((data) => {
            var obj1 = {};
            if (data.profile_employee)
              Object.assign(obj1, {
                created:
                  data.profile_employee.teacher_name[0] +
                  "." +
                  data.profile_employee.teacher_name,
              });
            else if (data.profile_student)
              Object.assign(obj1, {
                created:
                  data.profile_student.student_last_name[0] +
                  "." +
                  data.profile_student.student_name,
              });
            else Object.assign(obj1, { created: data.user_id });
            data.user_answers.forEach((answer) => {
              Object.assign(obj1, { [answer.input.id]: answer.answer });
            });
            jsonData.push(obj1);
          });
          this.json_data = jsonData;
          this.isLoadResult = false;
        })
        .catch(({ response }) => {
          this.isLoadResult = false;
        });
    },
    //#endregion
    //#region Method
    getMethodResult(userId, userInfo) {
      this.userInfo = userInfo;
      this.seriesChart = [];
      this.seriesBarChart = [];
      this.seriesBarChart = [
        {
          name: "Үр дүн",
          data: [],
        },
      ];
      this.isLoadMethod = true;
      this.optionsPieChart.labels = [];
      this.optionsBarChart.xaxis.categories = [];
      if (this.selectedMethod == null) {
        this.$toastr.w("Тусгай аргачлал сонгоно уу.", "Анхаар");
        return;
      }
      axios
        .post("/manager/getMethodResult", {
          methodId: this.selectedMethod.id,
          satId: this.satisfactionShow.id,
          userId: userId,
        })
        .then((response) => {
          this.chartType = response.data.chartType;
          if (this.chartType == "pie") {
            this.seriesChart = response.data.chartSeries;
            this.optionsPieChart.labels = response.data.chartLabels;
          } else if (this.chartType == "bar") {
            this.optionsBarChart.colors = this.getRandomColor();
            this.seriesBarChart[0].data = response.data.chartSeries;
            this.optionsBarChart.xaxis.categories = response.data.chartLabels;
          } else if (this.chartType == "radar") {
            this.seriesBarChart[0].data = response.data.chartSeries;
            this.optionsRadarChart.xaxis.categories = response.data.chartLabels;
          } else if (this.chartType == "area") {
            this.seriesAreaChart = response.data.chartSeries;
            this.optionsAreaChart.xaxis.categories = response.data.chartLabels;
          }
          this.resultData = response.data.resultData;
          this.isLoadMethod = false;
        })
        .catch((errors) => {
          this.isLoadMethod = false;
          this.$toastr.w("Алдаа гарлаа." + errors, "Анхаар");
          return;
        });
    },
    getSkillList(uId) {
      this.skillUserId = uId;
      this.selectedSgs = null;
      axios
        .get("/manager/getSkillList")
        .then((response) => {
          this.skillOptions = response.data.skillOptions;
          this.$bvModal.show("modal-result-skill");
        })
        .catch((errors) => {});
    },
    setSkillPoints() {
      this.isLoadExam = true;
      let userIds = [];
      if (this.skillUserId) userIds.push(this.skillUserId);
      axios
        .post("/manager/setSkillPoints", {
          methodId: this.selectedMethod.id,
          satId: this.satisfactionShow.id,
          userIds: userIds,
          selectedSgs: this.selectedSgs.id,
        })
        .then((response) => {
          this.isLoadExam = false;
          this.$toastr.s("Шалгалтын дүнг амжилттай орууллаа!", "Анхаар");
        })
        .catch((errors) => {
          this.isLoadExam = false;
          this.$toastr.w("Алдаа гарлаа." + errors, "Анхаар");
          return;
        });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    printResult() {
      const printContent = this.$refs.printContent;
      if (printContent) {
        var result = document.getElementById("modal-satisfaction-result");
        var nonPrintable = document.getElementById("nonPrintable");
        var satisfaction = document.getElementById("sidebar-satisfaction");
        result.style.display = "none";
        nonPrintable.style.display = "none";
        satisfaction.style.display = "none";

        var method = document.getElementById("sidebar-method");
        method.style.minHeight = "100vh";
        window.print();

        result.style.display = "block";
        nonPrintable.style.display = "block";
        satisfaction.style.display = "block";
      }
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      const randomColor = this.colors[randomIndex];
      return randomColor;
    },
    //#endregion
    //#region Users
    showUsers(form) {
      this.showForm.fill(form);
      this.$bvModal.show("modal-users");
      this.getFormUser();
    },
    getFormUser() {
      axios
        .get("/manager/showFormUsers/" + this.showForm.id)
        .then((response) => {
          this.formUsers = response.data.formUsers;
          this.formUser.reset();
          this.departmentOptions = response.data.departments;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDepUsers(dep_id) {
      axios
        .get("/manager/getTeacherListForm/" + dep_id)
        .then((response) => {
          this.userOptions = response.data.teachers;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addUser() {
      this.formUser.form_id = this.showForm.id;
      this.formUser
        .post("/manager/addUser")
        .then(() => {
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.getFormUser();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeUser(user) {
      Swal.fire({
        title: "Хэрэглэгч хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Багш хасаж байна...";
          axios
            .get("/manager/removeUser/" + user.id)
            .then(() => {
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
              this.getFormUser();
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    //#endregion
  },
  created() {
    setInterval(this.getNow, 1000);
    this.getAllForm();
  },
};
</script>

<style scoped>
.label {
  font-weight: 200;
}
.labelActive {
  font-weight: 600;
}
.btn-group {
  background-color: rgb(23, 162, 184);
  border-radius: 5px;
}
.level-color {
  color: #b0b6bb !important;
  font-size: 140%;
}
.formCard:hover {
  background-color: rgb(251 248 248);
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.675rem;
}
.table {
  min-height: 400px;
}
.table td {
  vertical-align: top;
}
</style>
