<template>
    <div class="pt-3">
        <!-- /.col -->
        <div class="card">
            <h5 class="card-title"><b>Ангийн суралцагчдийн ирцийн мэдээлэл</b></h5>
            <div class="card-body table-responsive bodyOfYear">
                <b-row>
                    <b-col sm="2">
                        <multiselect v-model="selectedQuarter" deselect-label="Сонголт цуцлах"
                            select-label="Улирал сонгох" track-by="id" :custom-label="customQuarterName" label="name"
                            placeholder="Хичээлийн улирал сонгоно уу?" :options="quarters" :searchable="false"
                            :allow-empty="false" @input="getMyClassAtt">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}-р улирал
                                </strong></template>
                        </multiselect>
                    </b-col>
                </b-row>
                <b-overlay rounded="sm">
                    <div class="row">
                        <div class="col-12 col-sm-12" v-if="myClassChart">
                            <div id="chart">
                                <div class="info-box bg-light">
                                    <div class="info-box-content">
                                        <span class="info-box-number text-muted mb-0">
                                            <apexchart type="bar" height="350" :options="chartOptionsClass"
                                                :series="seriesClass">
                                            </apexchart>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <b-row>
                    <b-col sm="3">
                        <multiselect v-model="selectedLesson" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            placeholder="Хичээл сонгоно уу?" track-by="id" label="subject_name" :options="lessonList"
                            :searchable="false" :allow-empty="false" @input="getSelectedLessonClassAtt">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.subject_name }}
                                </strong></template>
                        </multiselect>
                    </b-col>
                </b-row>
                <b-overlay rounded="sm">
                    <div class="row">
                        <div class="col-12 col-sm-12" v-if="viewSelectLessonClassChart">
                            <div id="chart">
                                <div class="info-box bg-light">
                                    <div class="info-box-content">
                                        <span class="info-box-number text-muted mb-0">
                                            <apexchart type="bar" height="350" :options="chartOptionsLesson"
                                                :series="seriesLesson">
                                            </apexchart>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <div class="row">
                    <div class="col-md-12" v-if="viewSelectedStud">
                        <b-row>
                            <b-col sm="2">
                                <multiselect v-model="selectedMonth" deselect-label="Сонголт цуцлах"
                                    select-label="Сар сонгох" :custom-label="customMonthName" track-by="month_name"
                                    label="month_name" placeholder="Хичээлийн улирал сонгоно уу?" :options="monthList"
                                    :searchable="false" :allow-empty="false">
                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.month_name
                                            }}-р сар</strong></template>
                                </multiselect>
                            </b-col>
                            <b-col sm="3">
                                <multiselect v-model="selectedStud"
                                    deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                                    placeholder="Сурагч сонгоно уу?" track-by="user_id" label="student_name"
                                    :options="studNames" :searchable="false" :allow-empty="false">
                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{
                                        option.student_last_name }}.{{ option.student_name }}
                                        </strong></template>
                                </multiselect>
                            </b-col>
                            <b-col sm="2">
                                <b-button variant="primary" size="sm" @click.prevent="getMonthsStudAttend()">Шүүж
                                    харах</b-button>
                            </b-col>
                        </b-row>
                        <!-- The time line  for end bichne-->
                        <div class="timeline mt-3">
                            <div class="col-lg-12" v-for="monthAtt in monthStudentAttList" :key="monthAtt.id">
                                <div class="time-label">
                                    <span :class="getColor(monthAtt.attendance_type_id)">{{
                                        monthAtt.lesson_date
                                    }}</span>
                                </div>
                                <div class="timeItem">
                                    <i :class="[
                                        'fa fa-clock',
                                        getColor(monthAtt.attendance_type_id),
                                    ]"></i>
                                    <div class="timeline-item">
                                        <span class="time"><i class="fas fa-clock"></i>
                                            {{ monthAtt.created_at | dateYearMonthDay }}</span>
                                        <h3 class="timeline-header">
                                            <a href="#" :class="getFontColor(monthAtt.attendance_type_id)">{{
                                                monthAtt.attendance_type.attendance_type }}</a>
                                        </h3>
                                        <div class="timeline-body">
                                            {{ monthAtt.temdeglel }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card-header -->
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    props: ["teacherId"],
    components: {
        Multiselect,
    },
    data() {
        return {
            show: false,
            viewStudInfo: false,
            viewSelectedStud: false,
            viewSelectLessonClassChart: false,
            myClassChart: false,
            selectedQuarter: "",
            selectedLesson: "",
            selectedStud: "",
            selectedMonth: 0,
            selectedClass: "",
            quarters: [],
            lessonList: [],
            studNames: [],
            monthList: [],
            classOptions: [],
            quarter: [],
            // selected class
            seriesClass: [
                {
                    name: "Ирсэн",
                    data: [],
                },
                {
                    name: "Тасалсан",
                    data: [],
                },
                {
                    name: "Өвчтэй",
                    data: [],
                },
                {
                    name: "Чөлөөтэй",
                    data: [],
                },
                {
                    name: "Хоцорсон",
                    data: [],
                },
            ],
            chartOptionsClass: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    stackType: "100%",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                    },
                },
                xaxis: {
                    type: "primaryClass",
                    categories: [],
                },
                legend: {
                    position: "bottom",
                },
                fill: {
                    opacity: 1,
                },
            },
            //Select lesson
            seriesLesson: [
                {
                    name: "Ирсэн",
                    data: [],
                },
                {
                    name: "Тасалсан",
                    data: [],
                },
                {
                    name: "Өвчтэй",
                    data: [],
                },
                {
                    name: "Чөлөөтэй",
                    data: [],
                },
                {
                    name: "Хоцорсон",
                    data: [],
                },
            ],
            chartOptionsLesson: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    stackType: "100%",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                    },
                },
                xaxis: {
                    type: "primaryClass",
                    categories: [],
                },
                legend: {
                    position: "bottom",
                },
                fill: {
                    opacity: 1,
                },
            },
            seriesStud: [
                {
                    name: "Ирсэн",
                    data: [],
                },
                {
                    name: "Тасалсан",
                    data: [],
                },
                {
                    name: "Өвчтэй",
                    data: [],
                },
                {
                    name: "Чөлөөтэй",
                    data: [],
                },
                {
                    name: "Хоцорсон",
                    data: [],
                },
            ],
            chartOptionsStud: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    stackType: "100%",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                    },
                },
                xaxis: {
                    type: "primaryClass",
                    categories: [],
                },
                legend: {
                    position: "bottom",
                },
                fill: {
                    opacity: 1,
                },
            },
            monthStudentAttList: [],
        };
    },
    methods: {
        getMyClassAtt() {
            this.myClassChart = false;
            axios
                .post("/teacher/getMyClassAtt", {
                    quarter: this.selectedQuarter.id,
                    teacherId: this.teacherId,
                })
                .then((response) => {
                    this.seriesClass[0].data = response.data.tIrsenList;
                    this.seriesClass[1].data = response.data.tTasalsanList;
                    this.seriesClass[2].data = response.data.tUvchteiList;
                    this.seriesClass[3].data = response.data.tChuluuteiList;
                    this.seriesClass[4].data = response.data.tHotsorsonList;

                    // this.pMonthNames = response.data.monthList;
                    this.chartOptionsClass.xaxis.categories = response.data.subjName;
                    this.lessonList = response.data.classCourse;
                    this.monthList = response.data.monthList;
                    this.myClass = response.data.myClass;
                    this.myClassChart = true;
                })
                .catch((errors) => {
                    //console.log(errors);
                });
        },
        getSelectedLessonClassAtt() {
            this.school_quarter_id = "";
            this.viewSelectLessonClassChart = false;
            axios
                .post("/teacher/getSelectedLessonMyClassAtt", {
                    selectedQuarter: this.selectedQuarter.id,
                    selectedLessonId: this.selectedLesson,
                    selectedClassId: this.myClass,
                    teacherId: this.teacherId,
                })
                .then((response) => {
                    this.seriesLesson[0].data = response.data.tIrsenList;
                    this.seriesLesson[1].data = response.data.tTasalsanList;
                    this.seriesLesson[2].data = response.data.tUvchteiList;
                    this.seriesLesson[3].data = response.data.tChuluuteiList;
                    this.seriesLesson[4].data = response.data.tHotsorsonList;

                    // this.pMonthNames = response.data.monthList;
                    this.chartOptionsLesson.xaxis.categories = response.data.tStudNames;
                    this.studNames = response.data.studentIds;
                    this.monthList = response.data.monthList;
                    this.viewSelectLessonClassChart = true;
                    this.viewSelectedStud = true;
                })
                .catch((errors) => {
                    //console.log(errors);
                });
        },
        getMonthsStudAttend() {
            this.school_quarter_id = "";
            if (this.selectedQuarter) {
                this.viewSelectLessonClassChart = true;
                this.viewSelectedStud = true;
                this.viewStudInfo = false;
                axios
                    .post("/teacher/getMonthsMyStudAttend", {
                        selectedQuarter: this.selectedQuarter.id,
                        selectedLesson: this.selectedLesson.id,
                        selectedClass: this.myClass,
                        selectedStud: this.selectedStud.user_id,
                        selectedMonth: this.selectedMonth.month_name,
                    })
                    .then((response) => {
                        this.monthStudentAttList = response.data.attList;
                        this.viewStudInfo = true;
                    })
                    .catch((errors) => {
                        //console.log(errors);
                    });
            }
        },
        allQuarters() {
            axios
                .get("/teacher/getSchoolQuarters")
                .then((response) => {
                    this.quarters = response.data.schoolquarters;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        remove(attendance_id, tooluur) {
            this.passPropsMyLessonDate.splice(tooluur, 1);
        },
        customQuarterName({ name }) {
            return `${name}-р улирал`;
        },
        customMonthName({ month_name }) {
            return `${month_name}-р сар`;
        },
        getColor(type) {
            if (type == 1) return "bg-blue";
            else if (type == 2) return "bg-red";
            else if (type == 3) return "bg-yellow";
            else if (type == 4) return "bg-green";
            else if (type == 5) return "bg-purple";
        },
        getFontColor(type) {
            if (type == 1) return "text-primary";
            else if (type == 2) return "text-danger";
            else if (type == 3) return "text-warning";
            else if (type == 4) return "text-success";
            else if (type == 5) return "text-purple";
        },
    },

    created() {
        this.allQuarters();
        this.getMyClassAtt();
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.bodyOfYear {
    min-height: 200px;
}

.timeline-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color: #495057;
    font-size: 16px;
    line-height: 1.1;
    margin: 0;
    padding: 10px;
}

.timeline-item {
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    background-color: #fff;
    color: #495057;
    margin-left: 60px;
    margin-right: 15px;
    margin-top: 0;
    padding: 0;
    position: relative;
}

.timeItem {
    margin-bottom: 15px;
    margin-right: 10px;
    padding-top: 22px;
    position: relative;
}

.time {
    color: #999;
    float: right;
    font-size: 12px;
    padding: 10px;
}

.timeline-body,
.timeline-footer {
    padding: 10px;
}

.timeItem>.fa,
.timeItem>.fab,
.timeItem>.fad,
.timeItem>.fal,
.timeItem>.far,
.timeItem>.fas,
.timeItem>.ion,
.timeItem>.svg-inline--fa {
    background-color: #adb5bd;
    border-radius: 50%;
    font-size: 16px;
    height: 30px;
    left: 18px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 30px;
}

.timeItem>i {
    margin-top: 15px;
}

.timeline::before {
    border-radius: 0.25rem;
    background-color: #dee2e6;
    bottom: 0;
    content: "";
    left: 38px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 4px;
}

.time-label>span {
    border-radius: 4px;
    background-color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 5px;
}

.text-purple {
    color: #6f42c1;
}
</style>
