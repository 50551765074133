<template>
  <div>
    <multiselect
      v-model="yearId"
      deselect-label="Хасах"
      select-label="Сонгох"
      track-by="id"
      :custom-label="customYearName"
      placeholder="Хичээлийн жил сонгоно уу?"
      :options="years"
      :searchable="true"
      :allow-empty="true"
      @input="getAllHrDevStatsDatas"
    >
      <template slot="singleLabel" slot-scope="{ option }"
        ><strong
          >{{ option.start_date | dateYear }}-{{
            option.finish_date | dateYear
          }}
          - хичээлийн жил
        </strong></template
      >
    </multiselect>
    <div class="row pt-3">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box">
          <span class="info-box-icon bg-info elevation-1">
            <i class="fas fa-shapes"></i>
          </span>

          <div class="info-box-content">
            <span class="info-box-text">Модуль сургалт</span>
            <span class="info-box-number">
              {{ allModules }}
              <!-- <small>%</small> -->
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-danger elevation-1"
            ><i class="fas fa-brain"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Дэд сургалт</span>
            <span class="info-box-number">{{ allModulesSubTraining }}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->

      <!-- fix for small devices only -->
      <div class="clearfix hidden-md-up"></div>

      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-success elevation-1"
            ><i class="fas fa-shopping-cart"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Зарцуулсан хугацаа(Мин/Цаг)</span>
            <span class="info-box-number"
              >{{ allModulesSubTrainingTimesNiilber }} мин /
              {{ convertFromMinuteToHour }} цаг
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-warning elevation-1"
            ><i class="fas fa-users"></i
          ></span>

          <div class="info-box-content">
            <span class="info-box-text">Хамрагдсан багш, ажилчид</span>
            <span class="info-box-number">
              {{ allModulesSubTrainingStudents }}
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
    </div>
    <!-- /.col -->
    <div class="row">
      <div class="col-lg-6 col-6">
        <div class="small-box bg-white">
          <!-- height="350" -->
          <apexchart
            v-if="series[0].data.length != 0"
            width="100%"
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
      <div class="col-lg-6 col-6">
        <div class="small-box bg-white">
          <apexchart
            v-if="series1.length != 0"
            width="100%"
            type="donut"
            :options="options1"
            :series="series1"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MyPie from "./PieChart.vue";
// import VueApexCharts from "vue-apexcharts";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      yearId: null,
      allModules: "",
      allModulesSubTraining: "",
      allModulesSubTrainingTimesNiilber: "",
      allModulesSubTrainingTimes: "",
      allModulesSubTrainingStudents: "",
      allModulesTimes: "",

      //График
      //Мэргэжлийн багийн бүтэц тоо
      series: [
        {
          name: "Сургалт хөгжил",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            // "Feb",
          ],
        },
        yaxis: {
          title: {
            text: "Цаг",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val + " цаг";
            },
          },
        },
      },

      options1: {
        labels: [],
        legend: {
          // show: false,
          // position: "left",
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        //   dropShadow: {},
        // },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      series1: [],
    };
  },
  components: { Multiselect },
  computed: {
    convertFromMinuteToHour() {
      let a = this.allModulesSubTrainingTimesNiilber / 60;
      return a.toFixed(1);
    },
    years: function () {
      return this.$store.state.years;
    },
  },
  methods: {
    getAllHrDevStatsDatas() {
      this.allModules = "";
      this.allModulesSubTraining = "";
      this.allModulesSubTrainingTimesNiilber = "";
      this.allModulesSubTrainingStudents = "";
      this.allModulesTimes = "";
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      this.options1.labels = [];
      this.series1 = [];
      axios
        .post("/hr/indexHrDevStatDatas", {
          year: this.yearId != null ? this.yearId.id : null,
        })
        .then((response) => {
          this.allModules = response.data.allModules;
          this.allModulesSubTraining = response.data.allModulesSubTraining;
          this.allModulesSubTrainingTimesNiilber =
            response.data.allModulesSubTrainingTimesNiilber;
          this.allModulesSubTrainingStudents =
            response.data.allModulesSubTrainingStudents;
          this.allModulesTimes = _.orderBy(
            response.data.allModulesTimes,
            "taining_name",
            "ASC"
          );

          this.allModulesTimes.forEach((element) => {
            this.chartOptions.xaxis.categories.push(element.taining_name);
            this.series[0].data.push(element.time_minute);

            this.options1.labels.push(element.taining_name);
            this.series1.push(parseInt(element.time_minute));
          });
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    //Орон нарийвчлах
    getScore(val) {
      return val.toFixed(1);
    },
    //Эерэг сурах утга хэвлэх
    signCheck(number) {
      return Math.sign(number);
    },
    checkClass(value) {
      if (value <= 50) {
        return "bg-danger";
      } else if (value <= 80) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  created() {
    this.getAllHrDevStatsDatas();
  },
};
</script>

<style>
</style>
