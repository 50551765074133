<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">Миний хүүхдийн ном</div>
        <div class="card-body p-0">
          <div class="card-header">
            <b-row>
              <b-col sm="3">
                <b-form-group
                  label="Хүүхэд"
                  label-for="child_id"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                  <b-form-select
                    v-model="childId"
                    :options="childOptions"
                    text-field="student_name"
                    value-field="id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('child_id'),
                      },
                    ]"
                    @input="getMyBooks()"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-overlay :show="bookIsLoaded" rounded="sm">
            <b-card-group deck class="p-3">
              <div
                class="col-lg-3 mb-3"
                v-for="book in libraryBooks"
                :key="book.id"
              >
                <div class="card-container">
                  <div class="box">
                    <img :src="book.image" alt="Avatar" class="image-card" />
                    <div
                      class="ribbon ribbon-top-right"
                      v-if="
                        myBooks.some((myBook) => myBook.book_id === book.id)
                      "
                    >
                      <span><small>Авсан ном</small></span>
                    </div>
                    <div
                      class="ribbon ribbon-top-right"
                      variant="secondary"
                      v-else
                    >
                      <span style="background-color: #ffc107"
                        ><small>Хэрэгтэй ном</small></span
                      >
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      <small class="text-muted">
                        {{ book.name.slice(0, 30) }}
                      </small>
                    </h5>
                    <p
                      class="card-text"
                      style="
                        text-align: justify;
                        height: 0.8rem;
                        word-break: break-all;
                      "
                    >
                      <small class="text-muted d-flex justify-content-between">
                        <span>
                          Анги:
                          <b>
                            <span> {{ book.class_number }} -р анги </span>
                          </b>
                        </span>
                        <span>
                          Үнэ:
                          <b>
                            <span>
                              {{ book.price }}
                            </span>
                          </b>
                        </span>
                      </small>
                    </p>
                  </div>
                  <div class="card-footer pt-0">
                    <small class="text-muted">
                      <i class="far fa-calendar-check ml-2"></i>
                      <b>{{ book.created_at | dateYearMonthDay }}</b>
                    </small>
                  </div>
                </div>
              </div>
            </b-card-group>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      bookIsLoaded: false,
      books: [],
      libraryBooks: [],
      myBooks: [],
      childId: "",
      childOptions: [],
      editMode: false,
      myChilds: "",
      classNumbers: [
        { class_number: 1, class_text: "1-р анги" },
        { class_number: 2, class_text: "2-р анги" },
        { class_number: 3, class_text: "3-р анги" },
        { class_number: 4, class_text: "4-р анги" },
        { class_number: 5, class_text: "5-р анги" },
        { class_number: 6, class_text: "6-р анги" },
        { class_number: 7, class_text: "7-р анги" },
        { class_number: 8, class_text: "8-р анги" },
        { class_number: 9, class_text: "9-р анги" },
        { class_number: 10, class_text: "10-р анги" },
        { class_number: 11, class_text: "11-р анги" },
        { class_number: 12, class_text: "12-р анги" },
      ],
      imgUrl: "",
      form: new Form({
        id: "",
        class_number: "",
        name: "",
        description: "",
        price: "",
        user: "",
        image: null,
      }),
      defaultimage: "/images/users/user.png",
      imgError: "",
      imgLoaded: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getMyChilds() {
      axios
        .get("/parent/getMyChilds")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0)
            this.childId = this.childOptions[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyBooks() {
      axios
        .get("/parent/getMyBooks/" + this.childId)
        .then((response) => {
          this.libraryBooks = response.data.libraryBooks;
          this.myBooks = response.data.myBook;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    connectBook() {
      this.form.reset();
      this.imgUrl = "";
      this.$bvModal.show("modal-Books");
    },
    getImg(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError =
          "1024 x 1024 пикселээс бага хэмжээтэй зураг оруулна уу!";
        return;
      } else this.imgError = "";
      //   console.log(img);
      this.readImage(img);
    },
    readImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.form.image = e.target.result;
        this.imgUrl = e.target.result;
      };
      this.imgLoaded = true;
    },
  },
  created() {
    this.getMyBooks();
    this.getMyChilds();
    Fire.$on("loadBooks", () => {
      this.getMyBooks();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
dd {
  padding-left: 20px;
}
.buyers {
  position: absolute;
  bottom: 5px;
  right: 15px;
}
</style>
