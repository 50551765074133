<template>
  <div class="row pt-3">
    <!-- /.col -->
    <div class="col-md-12">
      <div class="card">
        <div class="card-header p-2">
          <!-- /.card-header -->
          <div class="card-body">
            <div class="tab-content">
              <div class="active tab-pane" id="activity">
                <div class="row">
                  <div
                    class="
                      col-12 col-sm-6 col-md-6
                      d-flex
                      align-items-stretch
                      flex-column
                    "
                  >
                    <div class="card bg-light d-flex flex-fill mr-1">
                      <div class="card-header text-muted border-bottom-0">
                        Эцгийн мэдээлэл
                      </div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="lead">
                              <b
                                >{{ getParentInfo.parent_father_ovog }}
                                {{ getParentInfo.parent_father_name }}</b
                              >
                            </h2>
                            <p class="text-muted text-sm">
                              {{ getParentInfo.parent_father_mergejil }},
                              {{ getParentInfo.parent_father_ajil_gazar }}
                            </p>
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-users"></i
                                ></span>
                                Ургийн овог:
                                {{ getParentInfo.parent_father_urgiin_ovog }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-building"></i
                                ></span>
                                Хаяг: {{ getParentInfo.parent_father_address }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-phone"></i
                                ></span>
                                Утас:
                                {{ getParentInfo.parent_father_phone_number }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-envelope"></i
                                ></span>
                                Цахим шуудан:
                                {{ getParentInfo.parent_father_email }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fa fa-hashtag"></i
                                ></span>
                                РД: {{ getParentInfo.parent_father_reg }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fa fa-id-card"></i
                                ></span>
                                Яс үндэс:
                                {{ getParentInfo.parent_father_yas_und }}
                              </li>
                            </ul>
                          </div>
                          <div class="col-5 text-center">
                            <img
                              v-if="
                                getParentInfo.parent_father_profile_photo_url !=
                                null
                              "
                              :src="
                                getParentInfo.parent_father_profile_photo_url
                              "
                              alt="user-avatar"
                              class="img-circle img-fluid"
                            />
                            <img
                              v-else
                              src="/images/users/user.png"
                              alt="user-avatar"
                              class="img-circle img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-sm-6 col-md-6
                      d-flex
                      align-items-stretch
                      flex-column
                    "
                  >
                    <div class="card bg-light d-flex flex-fill ml-1">
                      <div class="card-header text-muted border-bottom-0">
                        Эхийн мэдээлэл
                      </div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="lead">
                              <b
                                >{{ getParentInfo.parent_mother_ovog }}
                                {{ getParentInfo.parent_mother_name }}</b
                              >
                            </h2>
                            <p class="text-muted text-sm">
                              {{ getParentInfo.parent_mother_mergejil }},
                              {{ getParentInfo.parent_mother_ajil_gazar }}
                            </p>
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-users"></i
                                ></span>
                                Ургийн овог:
                                {{ getParentInfo.parent_mother_urgiin_ovog }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-building"></i
                                ></span>
                                Хаяг: {{ getParentInfo.parent_mother_address }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-phone"></i
                                ></span>
                                Утас:
                                {{ getParentInfo.parent_mother_phone_number }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-envelope"></i
                                ></span>
                                Цахим шуудан:
                                {{ getParentInfo.parent_mother_email }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fa fa-hashtag"></i
                                ></span>
                                РД: {{ getParentInfo.parent_mother_reg }}
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fa fa-id-card"></i
                                ></span>
                                Яс үндэс:
                                {{ getParentInfo.parent_mother_yas_und }}
                              </li>
                            </ul>
                          </div>
                          <div class="col-5 text-center">
                            <img
                              v-if="
                                getParentInfo.parent_mother_profile_photo_url !=
                                null
                              "
                              :src="
                                getParentInfo.parent_mother_profile_photo_url
                              "
                              alt="user-avatar"
                              class="img-circle img-fluid"
                            />
                            <img
                              v-else
                              src="/images/users/user.png"
                              alt="user-avatar"
                              class="img-circle img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.tab-content -->
          </div>
        </div>

        <!-- /.card-body -->
      </div>
      <!-- /.nav-tabs-custom -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadedFather: false,
      loadedMother: false,
      getSelectedFatherImg: null,
      getSelectedMotherImg: null,
      getParentInfo: [],
      parent_pass: null,
      parent_pass_verify: null,
      form: new Form({
        id: "",
        user_id: "",
        user_name: "",
        user_email: "",
        student_profile_id: "",
        schoolYearId: "",
        parent_father_urgiin_ovog: "",
        parent_father_ovog: "",
        parent_father_name: "",
        parent_father_reg: "",
        parent_father_yas_und: "",
        parent_father_mergejil: "",
        parent_father_bol: "",
        parent_father_ajil_gazar: "",
        parent_father_phone_number: "",
        parent_father_email: "",
        parent_father_address: "",
        parent_father_profile_photo_url: "",
        parent_mother_urgiin_ovog: "",
        parent_mother_ovog: "",
        parent_mother_name: "",
        parent_mother_reg: "",
        parent_mother_yas_und: "",
        parent_mother_mergejil: "",
        parent_mother_bol: "",
        parent_mother_ajil_gazar: "",
        parent_mother_phone_number: "",
        parent_mother_email: "",
        parent_mother_address: "",
        parent_mother_profile_photo_url: "",
      }),
    };
  },
  methods: {
    getParentsInfo() {
      console.log("Ажиллаж байна. getParentsInfo");
      axios
        .get("/student/getParentsInfo")
        .then((res) => {
          this.form.fill(res.data.getParentInfo);
          this.getParentInfo = res.data.getParentInfo;
          this.getSelectedFatherImg = res.data.parent_father_profile_photo_url;
          this.getSelectedMotherImg = res.data.parent_mother_profile_photo_url;
        })
        .catch();
    },
  },
  created() {
    this.getParentsInfo();
    Fire.$on("loadStart", () => {
      this.getParentsInfo();
    });
  },
};
</script>

<style>
</style>
