<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Мэргэжлийн багш нарын баг</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
            <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="card card-default">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-atlas"></i>
                                    Бүртгэлтэй мэргэжлийн баг:
                                    {{ departments.length }} байна.
                                </h3>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loaded" rounded="sm">
                                    <b-row>
                                        <b-col lg="12" class="my-1">
                                            <b-form-group
                                                label="Нэг хуудсанд"
                                                label-for="per-page-select"
                                                label-cols-sm="6"
                                                label-cols-md="4"
                                                label-cols-lg="3"
                                                label-align-sm="right"
                                                label-size="sm"
                                                class="mb-0"
                                            >
                                                <b-form-select
                                                    id="per-page-select"
                                                    v-model="perPage"
                                                    :options="pageOptions"
                                                    size="sm"
                                                ></b-form-select>
                                            </b-form-group>

                                            <b-form-group
                                                label="Хайх утга!"
                                                label-for="filter-input"
                                                label-cols-sm="3"
                                                label-align-sm="right"
                                                label-size="sm"
                                                class="mb-0"
                                            >
                                                <b-input-group size="sm">
                                                    <b-form-input
                                                        id="filter-input"
                                                        v-model="filter"
                                                        type="search"
                                                        placeholder="Хайлт хийх утгаа оруулна уу!"
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button
                                                            :disabled="!filter"
                                                            @click="filter = ''"
                                                            >Цэвэрлэх</b-button
                                                        >
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        align="fill"
                                        size="sm"
                                        class="my-0"
                                    ></b-pagination>
                                    <b-table
                                        hover
                                        :items="departments"
                                        :fields="fields"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        :filter="filter"
                                        :filter-ignored-fields="
                                            filterIgnoredFields
                                        "
                                        @filtered="onFiltered"
                                        :filter-included-fields="filterOn"
                                    >
                                        <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template
                                            #cell(my_dep_curr_teams)="data"
                                        >
                                            <span
                                                v-if="
                                                    data.item.my_dep_curr_teams
                                                "
                                            >
                                                {{
                                                    data.item.my_dep_curr_teams
                                                        .name
                                                }}
                                            </span>
                                        </template>
                                        <template #cell(department_name)="data">
                                            {{ data.item.department_name }}
                                        </template>
                                        <template #cell(department_code)="data">
                                            {{ data.item.department_code }}
                                        </template>
                                        <template #cell(action)="data">
                                            <a
                                                href="#"
                                                class="badge bg-warning"
                                                @click="
                                                    editDepartment(data.item)
                                                "
                                                ><i class="fas fa-edit"></i
                                            ></a>
                                            <!-- <a
                        href="#"
                        class="badge bg-warning"
                        @click="editDepartmentCurriculumTeam(data.item)"
                        ><i class="fas fa-pencil-alt"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="removeDepartmentCurriculumTeam(data.item)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </a> -->
                                        </template>
                                    </b-table>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title" v-show="!editMode">
                                    Мэргэжлийн баг бүртгэх
                                </h3>
                                <h3 class="card-title" v-show="editMode">
                                    Мэргэжлийн баг засах
                                </h3>
                            </div>
                            <!-- /.card-header -->
                            <!-- form start -->
                            <form
                                @submit.prevent="
                                    !editMode
                                        ? createDepartment()
                                        : updateDepartment()
                                "
                            >
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Хөтөлбөрийн баг сонгох</label>
                                        <multiselect
                                            v-model="form.dep_curr_team_id"
                                            :multiple="false"
                                            deselect-label="Can't remove this value"
                                            :custom-label="nameDepCurrTeam"
                                            track-by="name"
                                            label="name"
                                            placeholder="Сонголт хийх"
                                            :options="
                                                departmentsCurriculumTeams
                                            "
                                            :searchable="true"
                                            :allow-empty="true"
                                        >
                                            <!-- @select="toggleSelect" -->
                                            <template
                                                slot="singleLabel"
                                                slot-scope="{ option }"
                                                ><strong>{{
                                                    option.name
                                                }}</strong>
                                                сонгогдлоо
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="form-group">
                                        <label>Мэргэжлийн багийн нэр</label>
                                        <input
                                            type="text"
                                            name="department_name"
                                            v-model="form.department_name"
                                            :class="[
                                                'form-control',
                                                {
                                                    'is-invalid': form.errors.has(
                                                        'department_name'
                                                    )
                                                }
                                            ]"
                                            placeholder="Мэргэжлийн багийн нэр"
                                        />
                                        <has-error
                                            :form="form"
                                            field="department_name"
                                        ></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Мэргэжлийн багийн код</label>
                                        <input
                                            type="text"
                                            name="department_code"
                                            v-model="form.department_code"
                                            :class="[
                                                'form-control',
                                                {
                                                    'is-invalid': form.errors.has(
                                                        'department_code'
                                                    )
                                                }
                                            ]"
                                            placeholder="Мэргэжлийн багийн код"
                                        />
                                        <has-error
                                            :form="form"
                                            field="department_code"
                                        ></has-error>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        v-show="!editMode"
                                    >
                                        Хадгалах
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        v-show="editMode"
                                    >
                                        Засах
                                    </button>
                                    <button
                                        class="btn btn-warning"
                                        v-show="editMode"
                                        @click.prevent="cancelEdit"
                                    >
                                        Болих
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            action: "",
            editMode: false,
            departments: [],
            departmentsCurriculumTeams: [],
            form: new Form({
                id: "",
                dep_curr_team_id: "",
                department_name: "",
                department_code: ""
            }),

            loaded: true,
            // Хүснэгтийн тохиргоо
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [
                1,
                2,
                3,
                4,
                5,
                10,
                15,
                25,
                35,
                45,
                55,
                65,
                75,
                100,
                { value: 200, text: "Илүү олноор харах" }
            ],
            //   sortBy: "",
            //   sortDesc: false,
            //   sortDirection: "asc",
            filterIgnoredFields: [],
            filter: null,
            filterOn: [],
            fields: [
                {
                    key: "index",
                    label: "№",
                    sortable: true
                },
                {
                    key: "my_dep_curr_teams",
                    label: "Хөтөлбөрийн баг",
                    sortable: true
                },
                {
                    key: "department_name",
                    label: "Мэргэжлийн багш нарын баг",
                    sortable: true
                },
                {
                    key: "department_code",
                    label: "Мэргэжлийн багш нарын баг",
                    sortable: true
                },
                {
                    key: "action",
                    label: "Үйлдлүүд",
                    sortable: true
                }
            ]
        };
    },
    components: {
        Multiselect
    },
    methods: {
        onFiltered(filteredItems) {
            //   console.log(filteredItems);
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getAllDepartments() {
            axios
                .get("/manager/getAllDepartments")
                .then(response => {
                    //   console.log(response);
                    this.departments = response.data.departments;
                    this.departmentsCurriculumTeams =
                        response.data.departmentsCurriculumTeams;
                    this.totalRows = this.departments.length;
                    this.loaded = false;
                })
                .catch(error => {
                    //   console.log(error);
                });
        },
        viewDepartment(department) {
            //   console.log("Дэлгэрэнгүй мэдээлэл харах" + department);
        },
        editDepartment(department) {
            //   console.log(department);
            let selectDepCurrTeam = null;
            if (department.dep_curr_team_id !== null) {
                selectDepCurrTeam = this.departmentsCurriculumTeams.filter(
                    el => el.id === department.dep_curr_team_id
                );
            }
            //   console.log(selectDepCurrTeam);
            this.form.reset();
            this.form.fill(department);
            this.form.dep_curr_team_id = selectDepCurrTeam[0];
            this.editMode = true;
        },
        updateDepartment() {
            this.action = "Мэргэжлийн багийн мэдээллийг шинэчилж байна...";
            this.form
                .put("/manager/department/" + this.form.id)
                .then(response => {
                    this.$toastr.s(
                        "Мэргэжлийн багийн мэдээллийг амжилттай шинэчиллээ.",
                        "Шинэчилсэн"
                    );
                    Fire.$emit("loadDepartment");
                    this.form.reset();
                    this.editMode = !this.editMode;
                })
                .catch();
        },
        cancelEdit() {
            this.form.reset();
            this.editMode = !this.editMode;
        },
        deleteDepartment(department) {
            //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + department);
        },
        createDepartment() {
            this.action = "Мэргэжлийн багийг үүсгэж байна...";
            this.form
                .post("/manager/department")
                .then(response => {
                    this.$toastr.s(
                        "Мэргэжлийн багийг амжилттай үүсгэлээ.",
                        "Амжилттай"
                    );
                    Fire.$emit("loadDepartment");
                    this.form.reset();
                })
                .catch(() => {
                    this.$toastr.e(
                        "Мэргэжлийн баг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },

        nameDepCurrTeam({ name }) {
            return `${name}`;
        }
    },
    created() {
        this.getAllDepartments();
        Fire.$on("loadDepartment", () => {
            this.getAllDepartments();
        });
    }
};
</script>

<style></style>
