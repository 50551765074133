<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Хүлээлгийн жагсаалт</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <div class="col-sm-6">
                  <b-form-select
                    v-model="selected"
                    :options="study_classes"
                    text-field="text"
                    value-field="value"
                    size="sm"
                  >
                  </b-form-select>
                  <has-error :form="form" field="value"></has-error>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getAllEntrants"
                  >
                    Хайх
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap" style="font-size: 80%">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Төлөв</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Цахим шуудан</th>
                <th>Анги</th>
                <th>С/З санал</th>
                <th>Үүсгэсэн</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody
              v-for="(schoolEntrant, index) in schoolentrants"
              :key="schoolEntrant.user_id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-warning"
                    >БАТАЛСАН</span
                  >
                  <span
                    v-if="schoolEntrant.status1 === 'registered'"
                    class="badge bg-secondary"
                    >БҮРТГЭГДСЭН</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.study_class }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.psychologist_offer === 1"
                    >Элсүүлэх</b-badge
                  >
                  <b-badge variant="info" v-else>Элсүүлэхгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    v-b-popover.hover="'Уулзалтын тэмдэглэл оруулах!'"
                    title="Уулзалтын тэмдэглэл"
                    href="#"
                    class="badge bg-info"
                    @click="editSchoolEntrantExam(schoolEntrant)"
                    ><i class="fas fa-brain"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal
      size="sx"
      id="modal-entrustInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Сэтгэл зүйч уулзалтын тэмдэглэл оруулах"
      class="modal fade"
      hide-footer
      @hide="cancelEntrant"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-0.2">
            <form @submit.prevent="updateEntrantExamGrade()">
              <div class="card-body">
                <div class="form-group">
                  <label>Уулзалтын тэмдэглэл</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="formEntrunt.psychologist_note"
                    placeholder="Дэлгэрэнгүй тэмдэглэл"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('psychologist_note'),
                      },
                    ]"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <div class="form-group">
                  <label>Санал</label>
                  <b-form-select
                    v-model="formEntrunt.psychologist_offer"
                    :options="options"
                    size="sm"
                    class="mt-3"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('psychologist_offer'),
                      },
                    ]"
                  ></b-form-select>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button
                  class="btn btn-secondary"
                  @click.prevent="cancelEntrant"
                >
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      selected: "0",
      study_classes: [
        { value: "0", text: "Бэлтгэл анги" },
        { value: "1", text: "1-р анги" },
        { value: "2", text: "2-р анги" },
        { value: "3", text: "3-р анги" },
        { value: "4", text: "4-р анги" },
        { value: "5", text: "5-р анги" },
        { value: "6", text: "6-р анги" },
        { value: "7", text: "7-р анги" },
        { value: "8", text: "8-р анги" },
        { value: "9", text: "9-р анги" },
        { value: "10", text: "10-р анги" },
        { value: "11", text: "11-р анги" },
        { value: "12", text: "12-р анги" },
      ],
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      action: "",
      editMode: false,
      schoolentrants: [],
      form: new Form({
        user_name: "",
        profile_last_name: "",
        checked: false,
        user_id: "",
      }),
      formEntrunt: new Form({
        user_id: "",
        psychologist_note: "",
        psychologist_offer: 0,
      }),
      options: [
        { value: 0, text: "Элсүүлэхгүй" },
        { value: 1, text: "Элсүүлнэ" },
      ],
    };
  },

  methods: {
    getAllEntrants() {
      axios
        .get("/psycho/getWaitEntrants/" + this.selected)
        .then((response) => {
          this.schoolentrants = response.data.schoolentrants;
          this.school_classes = response.data.school_classes;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    // Дүн оруулах
    editSchoolEntrantExam(schoolEntrant) {
      this.formEntrunt.fill(schoolEntrant);
      this.$bvModal.show("modal-entrustInfo");
    },
    updateEntrantExamGrade() {
      this.formEntrunt
        .put("/psycho/updateEntruntExamGradePsy")
        .then((res) => {
          Fire.$emit("loadSchoolStudents");
          this.$toastr.s(
            "Амжилттай",
            "Уулзалтын тэмдэглэлийг амжилттай орууллаа."
          );
          this.cancelEntrant();
        })
        .catch((errors) => {
          this.$toastr.e(
            "Алдаа гарлаа",
            "Формыг бүрэн бөглөсөн эсэхээ нягтална уу!"
          );
          console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEntrant() {
      this.formEntrunt.reset();
      this.$bvModal.hide("modal-entrustInfo");
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
