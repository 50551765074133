<template>
  <div id="chatdiv">
    <div id="chat" v-if="chatShow && authUser.userRoleInfo.name != 'dentstu'">
      <div
        class="card card-primary card-outline direct-chat direct-chat-primary direct-chat-contacts-open shadow-lg"
      >
        <div class="card-header">
          <h3 class="card-title">Чат</h3>
          <div class="card-tools">
            <small v-if="unSeenCount > 0">
              Шинэ зурвас
              <span
                :title="unSeenCount + ' хүнээс шинэ зурвас ирсэн байна.'"
                class="badge badge-warning"
                >{{ unSeenCount }}
              </span>
            </small>
            <button
              type="button"
              v-if="authUser.userRoleInfo.name != 'dentstu'"
              class="btn btn-tool"
              title="Идэвхитэй багш"
              @click="showUsers()"
            >
              <i class="fas fa-users"></i>
              <b-badge variant="light"
                >{{ chatUsers.length }}/<span class="text-success">{{
                  chatUsers.filter((user) => user.active == true).length
                }}</span>
              </b-badge>
            </button>
            <button
              type="button"
              v-if="
                authUser.userRoleInfo.name != 'dentstu' &&
                authUser.userRoleInfo.name != 'rentpa' &&
                chatParents.length > 0
              "
              class="btn btn-tool"
              title="Идэвхитэй эцэг эх"
              @click="showUsers()"
            >
              <b-icon icon="people-fill" aria-hidden="true"></b-icon>
              <b-badge variant="light"
                >{{ chatParents.length }}/<span class="text-success">{{
                  chatParents.filter((user) => user.active == true).length
                }}</span>
              </b-badge>
            </button>
            <button type="button" class="btn btn-tool" @click="chatShow = !chatShow">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body d-flex">
          <div>
            <ul
              class="nav nav-tabs nav-fill"
              v-if="
                authUser.userRoleInfo.name != 'dentstu' &&
                authUser.userRoleInfo.name != 'rentpa'
              "
            >
              <li class="nav-item">
                <a class="nav-link active py-0" href="#teachersList" data-toggle="tab">
                  Багш
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link py-0" href="#parentsList" data-toggle="tab">
                  Эцэг эх
                </a>
              </li>
            </ul>
            <ul class="nav nav-tabs nav-fill" v-else>
              <li class="nav-item">
                <a class="nav-link active py-0" href="#teachersList" data-toggle="tab">
                  Багш нар
                </a>
              </li>
            </ul>
            <div
              class="contacts"
              :style="
                isShowUsers
                  ? 'margin-left: -230px!important;'
                  : 'margin-left: 0px!important;'
              "
            >
              <div class="tab-content">
                <div class="tab-pane active" id="teachersList">
                  <div class="chatSearch">
                    <b-form-input
                      v-model="searchValue"
                      placeholder="Багш хайх..."
                      size="sm"
                    ></b-form-input>
                  </div>
                  <div class="direct-chat-contacts">
                    <b-overlay :show="isSyncUser" rounded="sm">
                      <ul class="contacts-list" v-if="this.myInfo">
                        <li
                          role="button"
                          v-for="user in chatUsers.filter((user) =>
                            this.searchValue != ''
                              ? user.name
                                  .toLowerCase()
                                  .includes(this.searchValue.toLowerCase())
                              : 1 == 1
                          )"
                          :key="user.id"
                          @click="showChat(user)"
                          :class="
                            selectedUser
                              ? {
                                  active: selectedUser.id == user.id,
                                }
                              : ''
                          "
                        >
                          <a href="#">
                            <div
                              :class="[
                                user.my_chats_count > 0
                                  ? 'contacts-list-info'
                                  : 'contacts-list-info-no',
                                user.active ? 'contact-active' : '',
                              ]"
                            >
                              <div class="contacts-list-name d-flex">
                                <b-avatar
                                  variant="light"
                                  :src="user.image"
                                  class="mr-1"
                                  size="24"
                                ></b-avatar>
                                <div
                                  class="chatListStatus"
                                  :class="
                                    user.active
                                      ? 'activeListStatus'
                                      : 'inActiveListStatus'
                                  "
                                ></div>
                                <div class="contact-item">
                                  <div class="contact-name">
                                    <small>
                                      <strong v-if="user.active">
                                        {{ user.name }}
                                      </strong>
                                      <span v-else>
                                        {{ user.name }}
                                      </span>
                                      <small>
                                        <b-badge
                                          :title="'Шинэ зурвас: ' + user.my_chats_count"
                                          variant="light"
                                          v-if="user.my_chats_count > 0"
                                        >
                                          {{ user.my_chats_count }}
                                        </b-badge>
                                      </small>
                                    </small>
                                  </div>
                                  <div class="contacts-list-msg">
                                    <small>
                                      {{ user.position_name }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </b-overlay>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="parentsList"
                  v-if="
                    authUser.userRoleInfo.name != 'dentstu' &&
                    authUser.userRoleInfo.name != 'rentpa'
                  "
                >
                  <div class="chatSearch">
                    <b-form-input
                      v-model="searchParent"
                      placeholder="Эцэг эх хайх..."
                      size="sm"
                    ></b-form-input>
                  </div>
                  <div class="direct-chat-contacts">
                    <b-overlay :show="isSyncUser" rounded="sm">
                      <ul class="contacts-list" v-if="this.myInfo">
                        <li
                          role="button"
                          v-for="user in chatParents.filter((user) =>
                            this.searchParent != ''
                              ? user.name
                                  .toLowerCase()
                                  .includes(this.searchParent.toLowerCase()) ||
                                user.child_name
                                  .toLowerCase()
                                  .includes(this.searchParent.toLowerCase())
                              : 1 == 1
                          )"
                          :key="user.id"
                          @click="showChat(user)"
                          :class="
                            selectedUser
                              ? {
                                  active: selectedUser.id == user.id,
                                }
                              : ''
                          "
                        >
                          <a href="#">
                            <div
                              :class="[
                                user.my_chats_count > 0
                                  ? 'contacts-list-info'
                                  : 'contacts-list-info-no',
                                user.active ? 'contact-active' : '',
                              ]"
                            >
                              <div class="contacts-list-name d-flex">
                                <b-avatar
                                  variant="light"
                                  :src="user.image"
                                  class="mr-1"
                                  size="24"
                                ></b-avatar>
                                <div
                                  class="chatListStatus"
                                  :class="
                                    user.active
                                      ? 'activeListStatus'
                                      : 'inActiveListStatus'
                                  "
                                ></div>
                                <div class="contact-item">
                                  <div class="contact-name">
                                    <small>
                                      <strong v-if="user.active">
                                        {{ user.name }}
                                      </strong>
                                      <span v-else>
                                        {{ user.name }}
                                      </span>
                                      <small>
                                        <b-badge
                                          :title="'Шинэ зурвас: ' + user.my_chats_count"
                                          variant="light"
                                          v-if="user.my_chats_count > 0"
                                        >
                                          {{ user.my_chats_count }}
                                        </b-badge>
                                      </small>
                                    </small>
                                  </div>
                                  <div class="contacts-list-msg">
                                    <small>
                                      {{ user.full_name }}
                                      {{ user.child_name }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </b-overlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chats">
            <div class="chatBody">
              <div class="card card-light chatCard" v-show="showChatMode">
                <div class="card-header" v-if="selectedUser">
                  <h3 class="card-title">
                    <b-avatar
                      variant="primary"
                      :src="selectedUser.image"
                      class="mr-3"
                      size="24"
                    ></b-avatar>
                    <div
                      :class="[
                        'chatStatus',
                        selectedUser.active == true ? 'activeStatus' : 'inActiveStatus',
                      ]"
                    ></div>
                    {{ selectedUser.name }}
                  </h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" @click="closeUserChat">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <!-- /.card-tools -->
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <div class="direct-chat-messages" v-chat-scroll v-if="selectedUser">
                    <b-overlay :show="chatIsLoad" rounded="sm">
                      <div
                        v-for="chat in chats"
                        :key="chat.id"
                        :class="[
                          'direct-chat-msg position-relative',
                          {
                            right: myInfo.id == chat.from_id,
                          },
                        ]"
                      >
                        <small
                          class="direct-chat-timestamp"
                          :class="
                            myInfo.id == chat.from_id ? 'float-right' : 'direct-chat-left'
                          "
                        >
                          {{ chat.created_at | dateWithTime }}
                        </small>
                        <div>
                          <b-avatar
                            v-if="chat.from_id != myInfo.id"
                            variant="primary"
                            :src="selectedUser.image"
                            class="direct-chat-img"
                            size="24"
                          ></b-avatar>
                          <div
                            @mouseover="showReactions(chat)"
                            class="direct-chat-text position-relative"
                            :class="{
                              'float-right direct-chat-text-right':
                                chat.from_id == myInfo.id,
                            }"
                            role="button"
                            :style="
                              checkImg(chat.message) == true &&
                              chat.message.replace(/<\/?[^>]+(>|$)/g, '').length <= 40
                                ? myInfo.id == chat.from_id
                                  ? `margin-left:calc(100% - 80px - ${
                                      chat.message.replace(/<\/?[^>]+(>|$)/g, '').length
                                    }ch - 4ch + 80px)`
                                  : `margin-right:calc(100% - 80px - 30px - ${
                                      chat.message.replace(/<\/?[^>]+(>|$)/g, '').length
                                    }ch - 4ch + 80px)`
                                : ''
                            "
                          >
                            <span v-html="chat.message"></span>
                            <b-badge
                              pill
                              variant="light"
                              class="reaction-button mb-1"
                              :title="chat.reaction"
                              role="button"
                              v-if="chat.reaction"
                              :class="{
                                'reaction-button-right': chat.from_id == myInfo.id,
                                likeButton: chat.reaction == 'like',
                              }"
                            >
                              <img
                                v-if="chat.reaction != 'like'"
                                width="15"
                                :title="
                                  chat.reaction == 'like'
                                    ? 'Таалагдсан'
                                    : chat.reaction == 'heart'
                                    ? 'Маш их таалагдсан'
                                    : chat.reaction == 'laughing'
                                    ? 'Инээсэн'
                                    : chat.reaction == 'astonished'
                                    ? 'Гайхширсан'
                                    : chat.reaction == 'sob'
                                    ? 'Гунигласан'
                                    : 'Уурласан'
                                "
                                :src="
                                  chat.reaction == 'heart'
                                    ? '/img/heart.svg'
                                    : chat.reaction == 'laughing'
                                    ? '/img/laughing.svg'
                                    : chat.reaction == 'astonished'
                                    ? '/img/astonished.svg'
                                    : chat.reaction == 'sob'
                                    ? '/img/sob.svg'
                                    : '/img/rage.svg'
                                "
                              />
                              <span v-else>👍</span>
                            </b-badge>
                          </div>
                          <div v-if="chat.from_id != myInfo.id">
                            <b-badge
                              @mouseleave="reactionClose(chat)"
                              pill
                              variant="light"
                              v-show="chat.showReact == 1"
                              class="reactions"
                              :class="{
                                'reactions-right': chat.from_id == myInfo.id,
                              }"
                              :style="
                                chat.showReact == 1
                                  ? 'visibility: visible;opacity: 1;'
                                  : 'visibility: hidden;opacity: 0;'
                              "
                            >
                              <div
                                class="st-btn st-first"
                                :class="{
                                  'st-btn-active': chat.reaction == 'like',
                                }"
                                data-reaction="like"
                                @click="reactChat(chat, 'like')"
                              >
                                👍
                              </div>
                              <div
                                class="st-btn"
                                :class="{
                                  'st-btn-active': chat.reaction == 'heart',
                                }"
                                data-reaction="heart_eyes"
                                @click="reactChat(chat, 'heart')"
                              >
                                <img src="/img/heart.svg" />
                              </div>
                              <div
                                class="st-btn"
                                :class="{
                                  'st-btn-active': chat.reaction == 'laughing',
                                }"
                                data-reaction="laughing"
                                @click="reactChat(chat, 'laughing')"
                              >
                                <img src="/img/laughing.svg" />
                              </div>
                              <div
                                class="st-btn"
                                :class="{
                                  'st-btn-active': chat.reaction == 'astonished',
                                }"
                                data-reaction="astonished"
                                @click="reactChat(chat, 'astonished')"
                              >
                                <img src="/img/astonished.svg" />
                              </div>
                              <div
                                class="st-btn"
                                :class="{
                                  'st-btn-active': chat.reaction == 'sob',
                                }"
                                data-reaction="sob"
                                @click="reactChat(chat, 'sob')"
                              >
                                <img src="/img/sob.svg" />
                              </div>
                              <div
                                class="st-btn st-last"
                                :class="{
                                  'st-btn-active': chat.reaction == 'rage',
                                }"
                                data-reaction="rage"
                                @click="reactChat(chat, 'rage')"
                              >
                                <img src="/img/rage.svg" />
                              </div>
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div
                        class="text-muted"
                        v-show="
                          typingUser.id == selectedUser.id &&
                          this.typingUser.toUid == this.myInfo.id
                        "
                      >
                        <b-avatar
                          variant="primary"
                          :src="selectedUser.image"
                          class="direct-chat-img mr-1 mt-2"
                          size="15"
                        ></b-avatar>
                        <small>
                          {{ typingUser.name }}
                        </small>
                        <img src="/img/typing.svg" class="isTyping" />
                      </div>
                    </b-overlay>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer p-0">
                  <div class="chat-input" v-show="showChatMode">
                    <div class="input-group">
                      <quill-editor
                        class="textEditor"
                        v-model="newChat"
                        :options="editorOption"
                        ref="myQuillEditor"
                        @change="handleTextChange($event)"
                      />
                      <span class="input-group-append">
                        <button
                          @click="sendChat()"
                          type="button"
                          class="btn btn-outline-primary"
                        >
                          <i class="fas fa-paper-plane"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div id="chatButton" v-if="authUser.userRoleInfo.name != 'dentstu'">
      <b-button class="btn-sm" variant="primary" block @click.prevent="showChatWindow()">
        <i class="fas fa-comments"></i>
      </b-button>
      <b-badge
        pill
        variant="warning"
        v-show="unSeenCount > 0"
        class="navbar-badge chatEffect"
      >
        <span>{{ unSeenCount }}</span>
      </b-badge>
    </div>
  </div>
</template>
<script>
import VueChatScroll from "vue-chat-scroll";
export default {
  data() {
    return {
      beforeChatid: 0,
      countChatReact: 0,
      isSyncUser: false,
      chatShow: false,
      isShowUsers: false,
      unSeenCount: 0,
      myInfo: null,
      searchValue: "",
      searchParent: "",
      chatUsers: [],
      chatParents: [],
      activeUsers: [],
      chats: [],
      newChat: "",
      selectedUser: null,
      showChatMode: false,
      typingUser: false,
      typingTimer: false,
      chatIsLoad: false,
      audio: new Audio("/sounds/message_sound.mp3"),
      editorOption: {
        placeholder: "Зурвас бичих ...",
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              { color: [] },
              { background: [] },
              "image",
            ],
          ],
          imageCompress: {
            quality: 0.5, // default
            maxWidth: 550, // default
            imageType: ["image/jpeg", "image/png", "image/jpg"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
    };
  },
  components: {
    VueChatScroll,
  },
  computed: {
    authUser: function () {
      return this.$store.state;
    },
  },
  methods: {
    showReactions(chat) {
      if (this.beforeChatid != chat.id && chat.showReact != 1) {
        let filteredChat = this.chats.find((ch) => ch.id === this.beforeChatid);
        if (filteredChat) {
          filteredChat.showReact = 0;
        }
        chat.showReact = 1;
        this.beforeChatid = chat.id;
      }
    },
    reactionClose(chat) {
      this.beforeChatid = 0;
      chat.showReact = 0;
    },
    reactChat(chat, reaction) {
      chat.reaction = reaction;
      Echo.join("messenger").whisper("reaction", chat);
      this.reactionClose(chat);
      axios
        .post("/all_worker_functions/reactChat/" + chat.id, {
          reaction: reaction,
        })
        .then((response) => {});
    },
    showChatWindow() {
      this.getUnseenCount();
      this.chatShow = !this.chatShow;
      //   this.getChatUsers();
    },
    getChatUser() {
      axios.get("/all_worker_functions/getChatUser").then((response) => {
        this.myInfo = response.data.myInfo;
      });
    },
    getUnseenCount() {
      axios.get("/all_worker_functions/getUnseenCount").then((response) => {
        this.unSeenCount = response.data.unSeenCount;
      });
    },
    getChatUsers() {
      axios.get("/all_worker_functions/getChatUsers").then((response) => {
        this.chatUsers = response.data.chatUsers;
        this.chatParents = response.data.chatParents;
      });
    },
    showUsers() {
      if (this.selectedUser) this.isShowUsers = !this.isShowUsers;
    },
    showChat(user) {
      user.my_chats_count = 0;
      this.searchValue = "";
      this.selectedUser = {
        id: user.id,
        name: user.name,
        image: user.image,
        active: user.active,
      };
      this.getChats();
      this.getUnseenCount();
      this.showChatMode = true;
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        this.isShowUsers = true;
      }
    },
    getChats() {
      this.chatIsLoad = true;
      axios
        .get("/all_worker_functions/getChats/" + this.selectedUser.id)
        .then((response) => {
          this.chats = response.data;
          this.chatIsLoad = false;
        });
    },
    closeUserChat() {
      this.selectedUser = null;
      this.showChatMode = false;
      this.isShowUsers = false;
    },
    checkImg(message) {
      if (message.includes("<img src=")) return false;
      else return true;
    },
    handleTextChange(event) {
      this.getTypingEvent();
      if (event.html.includes("<br>")) {
        this.newChat = event.html.replace("<br>", "");
        this.sendChat();
      }
    },
    sendChat() {
      if (this.newChat != "") {
        let chatText = this.newChat;
        this.newChat = "";
        axios
          .post("/all_worker_functions/sendChat", {
            chat: chatText,
            userId: this.selectedUser.id,
          })
          .then((response) => {
            this.chats.push(response.data.chat);
          })
          .catch((errors) => {
            console.log(errors);
          });
        this.getUnseenCount();
      }
    },
    updateSeenChats() {
      axios
        .post("/all_worker_functions/updateSeenChats/" + this.selectedUser.id)
        .then(() => {})
        .catch((errors) => {
          console.log(errors);
        });
    },
    getTypingEvent() {
      this.myInfo.toUid = this.selectedUser.id;
      Echo.join("messenger").whisper("typing", this.myInfo);
    },
  },
  created() {
    this.getUnseenCount();
    this.getChatUser();
    this.getChatUsers();
    Echo.join("messenger")
      .here((users) => {
        users.forEach((user) => {
          let filteredUser = this.chatUsers.find((u) => u.id === user.id);
          if (filteredUser) {
            filteredUser.active = true;
          }
          let filteredParent = this.chatParents.find((u) => u.id === user.id);
          if (filteredParent) {
            filteredParent.active = true;
          }
        });
      })
      .joining((user) => {
        // this.$toastr.defaultTimeout = 3000; // default timeout : 5000
        // this.$toastr.defaultClassNames = ["animated", "bounceInRight"];
        // this.$toastr.defaultPosition = "toast-top-right";
        // this.$toastr.i(
        //   "<span class='text-muted'><span class='b-avatar mr-1 badge-light rounded-circle' style='width: 24px; height: 24px;'><span class='b-avatar-img'><img src='" +
        //     user.image +
        //     "' alt='avatar'></span></span><b>" +
        //     user.name +
        //     "</b> системд нэвтэрлээ.</span>"
        // );
        this.isSyncUser = true;
        let filteredUser = this.chatUsers.find((u) => u.id === user.id);
        if (filteredUser) {
          filteredUser.active = true;
        }
        let filteredParent = this.chatParents.find((u) => u.id === user.id);
        if (filteredParent) {
          filteredParent.active = true;
        }
        this.isSyncUser = false;
      })
      .leaving((user) => {
        this.isSyncUser = true;
        let filteredUser = this.chatUsers.find((u) => u.id === user.id);
        if (filteredUser) {
          filteredUser.active = false;
        }
        let filteredParent = this.chatParents.find((u) => u.id === user.id);
        if (filteredParent) {
          filteredParent.active = false;
        }
        this.isSyncUser = false;
      })
      .listenForWhisper("typing", (user) => {
        this.typingUser = user;
        if (this.typingTimer) {
          clearTimeout(this.typingTimer);
        }
        this.typingTimer = setTimeout(() => {
          this.typingUser = false;
        }, 3000);
      })
      .listen("MessageSent", (event) => {
        if (
          this.selectedUser &&
          event.message.from_id == this.selectedUser.id &&
          event.message.to_id == this.myInfo.id
        ) {
          this.chats.push(event.message);
        }
        if (event.message.to_id == this.myInfo.id) {
          let filteredUser = this.chatUsers.find((u) => u.id === event.message.from_id);
          if (filteredUser) {
            filteredUser.order = event.message.id;
            filteredUser.message = event.message.message;
            if (this.selectedUser && event.message.from_id == this.selectedUser.id) {
              filteredUser.my_chats_count = 0;
              this.updateSeenChats();
            } else {
              this.audio.play();
              filteredUser.my_chats_count++;
            }
            this.chatUsers = _.orderBy(this.chatUsers, "order", "desc");
            this.getUnseenCount();
          }
          let filteredParent = this.chatParents.find(
            (u) => u.id === event.message.from_id
          );
          if (filteredParent) {
            filteredParent.order = event.message.id;
            filteredParent.message = event.message.message;
            if (this.selectedUser && event.message.from_id == this.selectedUser.id) {
              filteredParent.my_chats_count = 0;
              this.updateSeenChats();
            } else {
              this.audio.play();
              filteredParent.my_chats_count++;
            }
            this.chatParents = _.orderBy(this.chatParents, "order", "desc");
            this.getUnseenCount();
          }
        }
      })
      .listenForWhisper("reaction", (chat) => {
        this.isSyncUser = true;
        let reactedChat = this.chats.find((ch) => ch.id === chat.id);
        if (reactedChat) {
          reactedChat.reaction = chat.reaction;
        }
        this.isSyncUser = false;
      });
  },
};
</script>

<style scoped>
.reactions {
  /* position: relative; */
  border: 1px solid #d9dde3;
  visibility: hidden;
  position: absolute;
  z-index: 1040;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 31px;
  bottom: -30px;
}
.reaction-button {
  position: absolute;
  bottom: -15px;
  right: 0;
  border: 1px solid #d9dde3;
  width: 25px;
  z-index: 1000;
}
.likeButton {
  padding: 3px 0 !important;
}
.st-btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  display: inline-block;
  opacity: 1;
  width: 18px;
}
.st-btn-active {
  border-bottom: 2px solid #c5bfbf;
  padding: 2px;
  margin: 0px 5px;
}
.st-btn:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
}
.isTyping {
  height: 30px;
}
#chatButton {
  z-index: 1040;
  position: fixed;
  bottom: 12px;
  right: 12px;
}

#chatButton button {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
  border-radius: 60px;
  padding: 14px 17px;
}

@media (max-width: 470px) {
  .contacts {
    margin-left: -235px !important;
  }

  #chat {
    width: 90vw !important;
  }

  .direct-chat-text {
    max-width: 90vw !important;
  }
}

@media (min-width: 471px) and (max-width: 600px) {
  .contacts {
    margin-left: -235px !important;
  }

  #chat {
    width: 90vw !important;
  }

  .direct-chat-text {
    max-width: 90vw !important;
  }
}

@media (min-width: 601px) and (max-width: 990px) {
  .contacts {
    margin-left: -235px !important;
  }

  #chat {
    width: 90vw !important;
  }

  .direct-chat-text {
    max-width: calc(90vw - 365px) !important;
  }
}

@media (min-width: 991px) and (max-width: 1220px) {
  #chat {
    width: 70vw !important;
  }

  .direct-chat-text {
    max-width: calc(70vw - 365px) !important;
  }
}

#chat {
  z-index: 1040;
  position: fixed;
  bottom: 70px;
  right: 12px;
  width: 45vw;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
}

#chat .card-body {
  overflow-y: hidden;
}

#chat .card {
  margin-bottom: 0px;
  border-radius: 16px;
}

.chatBody {
  height: 75vh;
}

.chatCard {
  height: 100%;
  border-radius: 0 !important;
  border-bottom-right-radius: 16px !important;
}

.last-msg {
  margin-top: -15px;
  margin-left: 47px;
}

.direct-chat-messages {
  height: 100%;
  min-height: 60px;
  padding-bottom: 25px;
}
.direct-chat-msg {
  margin-bottom: 5px;
}
.direct-chat-text {
  border-radius: 0.8rem;
  background-color: #ffffff;
  margin: 5px 80px 0 30px;
  max-width: calc(45vw - 365px);
  font-size: 12px;
}

.right .direct-chat-text {
  margin-left: 0;
  margin: 5px 1px 8px 80px;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
.right .direct-chat-text::after {
  border-left-color: #007bff;
}
.direct-chat-timestamp {
  position: relative;
  clear: both;
  font-size: 9px;
  bottom: -5px;
}
.direct-chat-left {
  margin-left: 30px;
}

.contacts {
  width: 230px;
}

.contacts-list-info-no {
  color: #cbcbcb;
  opacity: 0.7;
}

.contacts-list .active {
  background-color: rgb(30 28 28 / 90%);
}

.contacts-list-info {
  color: #ffffff;
  margin-left: 0px;
  opacity: 1;
}

.contacts-list-name {
  font-weight: normal;
}
.contact-active {
  opacity: 1;
}
.contact-item {
  margin-top: -7px;
  margin-left: 5px;
}
.contact-name {
  height: 15px;
}
.contacts-list-msg {
  line-height: 13px;
  margin-top: 4px;
}
.chatSearch {
  width: 230px;
}

.chats {
  width: 100%;
}

.direct-chat-contacts {
  position: static;
  border-bottom-left-radius: 16px;
  height: calc(75vh - 57px);
}

#chat .card-footer {
  border-radius: 0px;
  border-bottom-right-radius: 16px;
}

#chat button {
  border-radius: 0px;
  border-bottom-right-radius: 16px;
}

.chatStatus {
  width: 9px;
  height: 9px;
  border-radius: 5px;
  position: absolute;
  left: 38px;
  bottom: 4px;
}

.activeStatus {
  border: white 1px solid;
  background-color: #31a24c;
}

.inActiveStatus {
  border: white 1px solid;
  background-color: #777474;
}

.chatListStatus {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 5px;
  left: 28px;
  margin-top: 15px;
}

.activeListStatus {
  border: white 1px solid;
  background-color: #31a24c;
}

.inActiveListStatus {
  background-color: #777474;
  border: white 1px solid;
}

.chatEffect {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 1);
  transform: scale(1);
  animation: chatPulse 2s infinite;
}
@keyframes chatPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
  }
}
.textEditor {
  flex: 1 1 auto;
  width: 1%;
}
.ql-snow {
  padding: 0px !important;
}
</style>
