<template>
  <apexchart
    v-if="show"
    type="pie"
    width="380"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: ["labels", "datas", "show"],
  data() {
    return {
      series: this.datas,
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style></style>
