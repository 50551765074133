<template>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Хүмүүжлийн хичээл орж буй ангиудын мэдээлэл
          </h3>

          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px">
              <input
                type="text"
                name="table_search"
                class="form-control float-right"
                placeholder="Хайлт хийх"
              />

              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Багш</th>
                <th>Анги</th>
                <th>Хичээл</th>
                <th>Хичээлийн төрөл</th>
                <th>Үнэлгээ</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="myCourses.length"
                v-for="(myCourse, index) in myCourses"
                :key="myCourse.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{ myCourse.teacher_last_name[0].toUpperCase() }}.{{
                    myCourse.name
                  }}
                </td>
                <td>{{ myCourse.full_name }}</td>
                <td>{{ myCourse.subject_name }}</td>
                <td>{{ myCourse.LessonType }}</td>
                <td>{{ myCourse.course_percent }}%</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-xs"
                    @click.prevent="selectCourse(myCourse)"
                    v-b-popover.hover="
                      'АУБ өөрийн ангийн суралцагчдад хүмүүжлийн хөтөлбөрийн дүн оруулах бол энд дарна уу.'
                    "
                    title="Хүмүүжлийн дүн гаргах"
                  >
                    <i class="fas fa-chalkboard-teacher"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- /.card-body -->
        <div>
          <div class="card-body" v-if="selectedSchoolCourse">
            <div class="row">
              <div class="col-12 col-md-12">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th style="width: 10px">#</th>
                      <th>Анги</th>
                      <th>Хичээл</th>
                      <th>Суралцагч дуудах</th>
                      <th>Улирал</th>
                      <th>Шалгалт</th>
                      <!-- <th>Даалгаварын дугаар</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td>{{ selectedSchoolClass }}</td>
                      <td>
                        {{ selectedSchoolCourse }}
                      </td>
                      <td>
                        <button
                          class="btn btn-success btn-xs"
                          @click.prevent="getStudentsCourseGradeExam"
                        >
                          Суралцагчдыг дуудах
                        </button>
                      </td>
                      <td>
                        <b-form-select
                          v-model="quarter_id"
                          :options="myQuarters"
                          value-field="id"
                          text-field="name"
                          size="sm"
                        ></b-form-select>
                      </td>
                      <td>
                        <b-form-select
                          v-model="exam_id"
                          :options="myExams"
                          value-field="value"
                          text-field="text"
                          size="sm"
                        ></b-form-select>
                      </td>
                      <!-- <td>
                        <b-form-select
                          v-model="daalgar_number"
                          :options="myDaalgavarNumbers"
                          size="sm"
                        ></b-form-select>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12 col-md-12 table-responsive-sm">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 10px">#</th>
                      <th>Суралцагч</th>
                      <th
                        v-b-popover.hover.top="
                          'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Зорилгоо нэгэн утгатай тодорхой болгож, хүлээгдэж буй үр дүнг дүрслэн төсөөлөх чадвар (“Ажлыг эхлэхдээ төгсгөлийг нь бод” дадал)'
                        "
                        title="Motivation & Intention"
                      >
                        MoIn-1 (100%)
                      </th>
                      <th
                        v-b-popover.hover.top="
                          'Сэдэл ба Хүсэл эрмэлзлэл (Motivation & Intention); Цуглуулсан мэдээлэл бодитой эсэхийг нягтлах чадвар (“Долоо хэмжиж нэг огтол” дадал)'
                        "
                        title="Motivation & Intention"
                      >
                        MoIn-2 (100%)
                      </th>
                      <th
                        v-b-popover.hover.top="
                          'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Өөрөө өөртэйгээ ярилцах, үйл ажиллагаагаа төлөвлөх чадвар (“Эзэн хичээвэл заяа хичээнэ” дадал)'
                        "
                        title="Attitude & Belief"
                      >
                        AtBe-1 (100%)
                      </th>
                      <th
                        v-b-popover.hover.top="
                          'Хандлага ба Итгэл үнэмшил (Attitude & Belief); Гүйцэтгэлээ хянах, өөр өөр арга замаар оролдлого хийж хүлээгдэж буй үр дүндээ хүрэх чадвар (“Оролдлого сайт оройд нь” дадал)'
                        "
                        title="Attitude & Belief"
                      >
                        AtBe-2 (100%)
                      </th>
                      <th
                        v-b-popover.hover.top="
                          'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Зорилго, хэрэгжилт, үр дүнгийн талаарх бусдын санаа бодлыг сонсох чадвар (“Нөхрөө харж биеэ, сүүдрээ харж бүсээ” дадал)'
                        "
                        title="Behaviour & Worth"
                      >
                        BeWo-1 (100%)
                      </th>
                      <th
                        v-b-popover.hover.top="
                          'Төлөвшил ба Үнэ цэн (Behaviour & Worth); Бусдыг өөртөө итгүүлэх, хамтран ажиллах замаар тэдэнд хүлээн зөвшөөрөгдөх чадвар (“Дэм дэмэндээ дээс эрчиндээ” дадал)'
                        "
                        title="Behaviour & Worth"
                      >
                        BeWo-2 (100%)
                      </th>
                      <th>Тэмдэглэл</th>
                      <th>Дүн</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <loading :loading="loading"></loading> -->
                    <behavior-teacher
                      v-if="
                        changeQuarterId === 1 &&
                        quarter_id !== '' &&
                        getCourseStudentsQ1 !== ''
                      "
                      v-for="(getCourseStudentQ1, index) in getCourseStudentsQ1"
                      :key="index"
                      :tooluur="index"
                      :getCourseStudents="getCourseStudentQ1"
                      :changeQuarterId="changeQuarterId"
                      :school_id="school_id"
                      :teacher_id="teacher_id"
                      :student_id="student_id"
                      :course_id="course_id"
                      :quarter_id="quarter_id"
                      :exam_id="exam_id"
                      :school_class_id="school_class_id"
                      :loading="loading"
                    ></behavior-teacher>
                    <behavior-teacher
                      v-if="
                        changeQuarterId === 2 &&
                        quarter_id !== '' &&
                        getCourseStudentsQ2 !== ''
                      "
                      v-for="(getCourseStudentQ2, index) in getCourseStudentsQ2"
                      :key="index"
                      :tooluur="index"
                      :getCourseStudents="getCourseStudentQ2"
                      :changeQuarterId="changeQuarterId"
                      :school_id="school_id"
                      :teacher_id="teacher_id"
                      :student_id="student_id"
                      :course_id="course_id"
                      :quarter_id="quarter_id"
                      :exam_id="exam_id"
                      :school_class_id="school_class_id"
                      :loading="loading"
                    ></behavior-teacher>
                    <behavior-teacher
                      v-if="
                        changeQuarterId === 3 &&
                        quarter_id !== '' &&
                        getCourseStudentsQ3 !== ''
                      "
                      v-for="(getCourseStudentQ3, index) in getCourseStudentsQ3"
                      :key="index"
                      :tooluur="index"
                      :getCourseStudents="getCourseStudentQ3"
                      :changeQuarterId="changeQuarterId"
                      :school_id="school_id"
                      :teacher_id="teacher_id"
                      :student_id="student_id"
                      :course_id="course_id"
                      :quarter_id="quarter_id"
                      :exam_id="exam_id"
                      :school_class_id="school_class_id"
                      :loading="loading"
                    ></behavior-teacher>
                    <behavior-teacher
                      v-if="
                        changeQuarterId === 4 &&
                        quarter_id !== '' &&
                        getCourseStudentsQ4 !== ''
                      "
                      v-for="(getCourseStudentQ4, index) in getCourseStudentsQ4"
                      :key="index"
                      :tooluur="index"
                      :getCourseStudents="getCourseStudentQ4"
                      :changeQuarterId="changeQuarterId"
                      :school_id="school_id"
                      :teacher_id="teacher_id"
                      :student_id="student_id"
                      :course_id="course_id"
                      :quarter_id="quarter_id"
                      :exam_id="exam_id"
                      :school_class_id="school_class_id"
                      :loading="loading"
                    ></behavior-teacher>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Attendance -->
      <!-- /.card -->
      <div class="card" v-if="selectedAttendance">
        <!-- <div class="card-header">
          <h3 class="card-title">{{ attendance_subject_name }} ирц бүртгэх</h3>
        </div> -->
        <!-- /.card-header -->
        <!-- <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>
                  <div>
                    <label for="example-datepicker">Онгоо сонгох</label>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="attendanceDate"
                      class="mb-2"
                      size="sm"
                    ></b-form-datepicker>
                  </div>
                </th>
                <th>
                  <label for="example-datepicker">Улирал сонгох</label>
                  <b-form-select
                    v-model="attendance_quarter_id"
                    :options="myQuarters"
                    value-field="id"
                    text-field="name"
                    size="sm"
                  ></b-form-select>
                </th>
                <th>
                  <label for="example-datepicker">Хичээлийн цаг сонгох</label>
                  <b-form-select
                    v-model="attendance_lesson_number"
                    :options="attendance_lesson_numbers"
                    value-field="value"
                    text-field="text"
                    size="sm"
                  ></b-form-select>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Суралцагч</th>
                <th>Ирцийн төрөл</th>
                <th>Тайлбар</th>
              </tr>
            </thead>
            <tbody>
              <attendance-student
                v-if="
                  attendance_quarter_id === 1 &&
                  attendanceDate !== '' &&
                  getAttendanceStudentsQ1 !== ''
                "
                v-for="(
                  getAttendanceStudentQ1, index
                ) in getAttendanceStudentsQ1"
                :key="index"
                :tooluur="index"
                :getAttendanceStudent="getAttendanceStudentQ1"
                :school_id="attendance_school_id"
                :teacher_id="attendance_teacher_id"
                :course_id="attendance_course_id"
                :quarter_id="attendance_quarter_id"
                :school_class_id="attendance_school_class_id"
                :attendanceDate="attendanceDate"
                :attendanceType="attendanceType"
                :attendance_lesson_number="attendance_lesson_number"
              ></attendance-student>
              <attendance-student
                v-if="
                  attendance_quarter_id === 2 &&
                  attendanceDate !== '' &&
                  getAttendanceStudentsQ1 !== ''
                "
                v-for="(
                  getAttendanceStudentQ1, index
                ) in getAttendanceStudentsQ1"
                :key="index"
                :tooluur="index"
                :getAttendanceStudent="getAttendanceStudentQ1"
                :school_id="attendance_school_id"
                :teacher_id="attendance_teacher_id"
                :course_id="attendance_course_id"
                :quarter_id="attendance_quarter_id"
                :school_class_id="attendance_school_class_id"
                :attendanceDate="attendanceDate"
                :attendanceType="attendanceType"
                :attendance_lesson_number="attendance_lesson_number"
              ></attendance-student>
              <attendance-student
                v-if="
                  attendance_quarter_id === 3 &&
                  attendanceDate !== '' &&
                  getAttendanceStudentsQ1 !== ''
                "
                v-for="(
                  getAttendanceStudentQ1, index
                ) in getAttendanceStudentsQ1"
                :key="index"
                :tooluur="index"
                :getAttendanceStudent="getAttendanceStudentQ1"
                :school_id="attendance_school_id"
                :teacher_id="attendance_teacher_id"
                :course_id="attendance_course_id"
                :quarter_id="attendance_quarter_id"
                :school_class_id="attendance_school_class_id"
                :attendanceDate="attendanceDate"
                :attendanceType="attendanceType"
                :attendance_lesson_number="attendance_lesson_number"
              ></attendance-student>
              <attendance-student
                v-if="
                  attendance_quarter_id === 4 &&
                  attendanceDate !== '' &&
                  getAttendanceStudentsQ1 !== ''
                "
                v-for="(
                  getAttendanceStudentQ1, index
                ) in getAttendanceStudentsQ1"
                :key="index"
                :tooluur="index"
                :getAttendanceStudent="getAttendanceStudentQ1"
                :school_id="attendance_school_id"
                :teacher_id="attendance_teacher_id"
                :course_id="attendance_course_id"
                :quarter_id="attendance_quarter_id"
                :school_class_id="attendance_school_class_id"
                :attendanceDate="attendanceDate"
                :attendanceType="attendanceType"
                :attendance_lesson_number="attendance_lesson_number"
              ></attendance-student>
            </tbody>
          </table>
        </div> -->
        <!-- /.card-body -->
      </div>

      <!-- Final exam's grade for quarter -->
      <!-- /.card -->
      <!-- <index-final-grade-exam
        v-if="selectTeachQuartFinal"
        :myPassCourse="myPassCourse"
      ></index-final-grade-exam> -->

      <!-- Багшийн зүгээс хичээл дээр суралцагч дээр сэтгэгдэл үлдээх -->
      <div>
        <div class="card-body" v-if="selectStudentForCourseComment">
          <div class="row">
            <div class="col-12 col-md-12">
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th style="width: 10px">#</th>
                    <th>Анги</th>
                    <th>Хичээл</th>
                    <!-- <th>Суралцагч дуудах</th> -->
                    <th>Улирал</th>
                    <!-- <th>Шалгалт</th> -->
                    <!-- <th>Даалгаварын дугаар</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>{{ selectedSchoolClassComment }}</td>
                    <td>
                      {{ selectedSchoolCourseComment }}
                    </td>
                    <!-- <td>
                      <button
                        class="btn btn-success btn-xs"
                        @click.prevent="getStudentsCourseGradeExam"
                      >
                        Суралцагчдыг дуудах
                      </button>
                    </td> -->
                    <td>
                      <b-form-select
                        :options="myQuarters"
                        value-field="id"
                        text-field="name"
                        size="sm"
                        @change="changeQuarter"
                      ></b-form-select>
                    </td>
                    <!-- <td>
                      <b-form-select
                        v-model="exam_id"
                        :options="myExams"
                        value-field="id"
                        text-field="name"
                        size="sm"
                      ></b-form-select>
                    </td> -->
                    <!-- <td>
                      <b-form-select
                        v-model="daalgar_number"
                        :options="myDaalgavarNumbers"
                        size="sm"
                      ></b-form-select>
                    </td> -->
                  </tr>
                </tbody>
              </table>

              <for-student-course-comment
                v-if="
                  comment_changeQuarterId === 1 &&
                  getCourseStudentsCommentQ1 !== '' &&
                  selectStudentForCourseComment
                "
                :getCourseStudentsCommentQ1="getCourseStudentsCommentQ1"
                :comment_changeQuarterId="comment_changeQuarterId"
                :comment_school_id="comment_school_id"
                :comment_teacher_id="comment_teacher_id"
                :comment_course_id="comment_course_id"
                :comment_school_class_id="comment_school_class_id"
              ></for-student-course-comment>
              <for-student-course-comment
                v-if="
                  comment_changeQuarterId === 2 &&
                  getCourseStudentsCommentQ2 !== '' &&
                  selectStudentForCourseComment
                "
                :getCourseStudentsCommentQ1="getCourseStudentsCommentQ2"
                :comment_changeQuarterId="comment_changeQuarterId"
                :comment_school_id="comment_school_id"
                :comment_teacher_id="comment_teacher_id"
                :comment_course_id="comment_course_id"
                :comment_school_class_id="comment_school_class_id"
              ></for-student-course-comment>
              <for-student-course-comment
                v-if="
                  comment_changeQuarterId === 3 &&
                  getCourseStudentsCommentQ3 !== '' &&
                  selectStudentForCourseComment
                "
                :getCourseStudentsCommentQ1="getCourseStudentsCommentQ3"
                :comment_changeQuarterId="comment_changeQuarterId"
                :comment_school_id="comment_school_id"
                :comment_teacher_id="comment_teacher_id"
                :comment_course_id="comment_course_id"
                :comment_school_class_id="comment_school_class_id"
              ></for-student-course-comment>
              <for-student-course-comment
                v-if="
                  comment_changeQuarterId === 4 &&
                  getCourseStudentsCommentQ4 !== '' &&
                  selectStudentForCourseComment
                "
                :getCourseStudentsCommentQ1="getCourseStudentsCommentQ4"
                :comment_changeQuarterId="comment_changeQuarterId"
                :comment_school_id="comment_school_id"
                :comment_teacher_id="comment_teacher_id"
                :comment_course_id="comment_course_id"
                :comment_school_class_id="comment_school_class_id"
              ></for-student-course-comment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BehaviorTeacher from "../../../Manager/Grade/GradeBehavior/behaviorComp/BehaviorTeacher.vue";
// import AttendanceStudent from "../Attendance/IndexAttendance";
// import IndexFinalGradeExam from "./Teacher/FinalGradeExam/IndexFinalGradeExam.vue";
// import forStudentCourseComment from "./Teacher/ForStudentCourseComment/IndexCourseCommentStudent.vue";
export default {
  data() {
    return {
      //   First requests
      loading: false,
      myCourses: [],
      myExams: [
        { value: "1", text: "Хичээл" },
        { value: "2", text: "Хөгжүүлэх" },
        { value: "3", text: "Өөрийн түүхээ бүтээсэн байдал" },
      ],
      myQuarters: [],
      //   myDaalgavarNumbers: [
      //     { value: "1", text: "Даалгавар-1" },
      //     { value: "2", text: "Даалгавар-2" },
      //     { value: "3", text: "Даалгавар-3" },
      //     { value: "4", text: "Даалгавар-4" },
      //     { value: "5", text: "Даалгавар-5" },
      //     { value: "6", text: "Даалгавар-6" },
      //     { value: "7", text: "Даалгавар-7" },
      //     { value: "8", text: "Даалгавар-8" },
      //     { value: "9", text: "Даалгавар-9" },
      //     { value: "10", text: "Даалгавар-10" },
      //   ],
      //props-руу дамжуулна.
      changeQuarterId: "",
      school_id: "",
      teacher_id: "",
      student_id: "",
      course_id: "",
      quarter_id: "",
      exam_id: "",
      school_class_id: "",
      daalgar_number: "",

      //Selected
      selectedSchoolClass: "",
      selectedSchoolCourse: "",
      getPassMyCourse: [],

      getCourseStudentsQ1: [],
      getCourseStudentsQ2: [],
      getCourseStudentsQ3: [],
      getCourseStudentsQ4: [],
      //Attendance
      selectedAttendance: null,
      getPassMyAttendanceCourse: [],
      attendanceStudents: [],
      attendance_school_id: "",
      attendance_teacher_id: "",
      attendance_course_id: "",
      attendance_quarter_id: "",
      attendance_school_class_id: "",
      //   all_attendance_type_id: "",
      attendanceDate: "",
      attendanceType: [],
      attendance_lesson_number: "",
      attendance_lesson_numbers: [
        { value: "1", text: "1-р цаг" },
        { value: "2", text: "2-р цаг" },
        { value: "3", text: "3-р цаг" },
        { value: "4", text: "4-р цаг" },
        { value: "5", text: "5-р цаг" },
        { value: "6", text: "6-р цаг" },
        { value: "7", text: "7-р цаг" },
        { value: "8", text: "8-р цаг" },
        { value: "9", text: "9-р цаг" },
        { value: "10", text: "10-р цаг" },
      ],
      attendance_subject_name: "",
      //Багшийн дүнгийн нэгтгэл.
      selectTeachQuartFinal: false,
      myPassCourse: [],

      //Суралцагч бүрт сэтгэгдэл бичээрэй
      selectStudentForCourseComment: false,

      //Selected
      selectedSchoolClassComment: "",
      selectedSchoolCourseComment: "",
      getPassMyCourseComment: [],

      getCourseStudentsCommentQ1: [],
      getCourseStudentsCommentQ2: [],
      getCourseStudentsCommentQ3: [],
      getCourseStudentsCommentQ4: [],

      //   comment_ props руу
      comment_changeQuarterId: "",
      comment_school_id: "",
      comment_teacher_id: "",
      comment_student_id: "",
      comment_course_id: "",
      comment_quarter_id: "",
      comment_school_class_id: "",
    };
  },
  components: {
    behaviorTeacher: BehaviorTeacher,
    // attendanceStudent: AttendanceStudent,
    // IndexFinalGradeExam: IndexFinalGradeExam,
    // forStudentCourseComment: forStudentCourseComment,
  },
  watch: {
    quarter_id(newVal, oldVal) {
      this.loading = true;
      this.changeQuarterId = this.quarter_id;
      //   console.log(newVal, oldVal);
      //   console.log(this.course_id);

      //   axios
      //     .post("/teacher/selectMyQuarterTeacherExam", {
      //       course_id: this.course_id,
      //       quarter_id: this.quarter_id,
      //     })
      //     .then((response) => {
      //       //   console.log(response.data);
      //       this.myExams = response.data.getAllMyExams; //Энд шалгалтын агуулгыг улирал сонгогдох үед дууддаг болгох...
      //       //   console.log(this.myExams);
      //       this.loading = false;
      //     })
      //     .catch((errors) => {
      //       console.log(errors);
      //     });
    },
  },
  methods: {
    getMyCourses() {
      axios
        .get("/teacher/assignCourseBehavior")
        .then((res) => {
          this.myCourses = res.data.courses;
          this.myQuarters = res.data.getAllMyQuarters;
          //   console.log(this.myCourses);
        })
        .catch((err) => {});
    },
    selectCourse(myCourse) {
      //   console.log(myCourse);
      this.selectedSchoolClass = myCourse.full_name;
      this.selectedSchoolCourse = myCourse.subject_name;
      this.school_id = myCourse.course_school_id;
      this.teacher_id = myCourse.course_teacher_id;
      this.course_id = myCourse.course_id;
      this.school_class_id = myCourse.school_class_id;

      this.getPassMyCourse = myCourse;
      this.selectedAttendance = null;
      this.selectTeachQuartFinal = false;
      this.selectStudentForCourseComment = false;
      //   console.log(myCourse);
    },
    getStudentsCourseGradeExam() {
      //   console.log(this.getPassMyCourse);
      this.loading = true;
      axios
        .post("/teacher/getStudentsCourseGradeExam", {
          myCourse: this.getPassMyCourse,
        })
        .then((res) => {
          // console.log(res.data);
          this.getCourseStudentsQ1 = res.data.getStudentCourseGradeQuarter1;
          this.getCourseStudentsQ2 = res.data.getStudentCourseGradeQuarter2;
          this.getCourseStudentsQ3 = res.data.getStudentCourseGradeQuarter3;
          this.getCourseStudentsQ4 = res.data.getStudentCourseGradeQuarter4;
          this.loading = false;
          //   Swal.fire({
          //     position: "top-end",
          //     icon: "success",
          //     title: "Суралцагчдыг амжилттай дуудлаа",
          //     showConfirmButton: false,
          //     timer: 500,
          //   });
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    // checkQuarter($event) {
    //   console.log("Улирал солигдсон.", $event);
    //   console.log(this.changeQuarterId);
    // },

    //Attendance
    getAttendance(myCourse) {
      //   console.log(myCourse);
      this.attendance_lesson_number = "";
      this.selectedAttendance = false;
      this.selectTeachQuartFinal = false;
      this.selectStudentForCourseComment = false;
      this.selectedSchoolCourse = "";
      this.getCourseStudentsQ1 = [];
      this.getCourseStudentsQ2 = [];
      this.getCourseStudentsQ3 = [];
      this.getCourseStudentsQ4 = [];
      this.selectedAttendance = true;
      this.getPassMyAttendanceCourse = myCourse;
      this.attendance_subject_name = myCourse.subject_name;

      this.attendance_school_id = myCourse.course_school_id;
      this.attendance_teacher_id = myCourse.course_teacher_id;
      this.attendance_course_id = myCourse.course_id;
      this.attendance_school_class_id = myCourse.school_class_id;
      this.getStudentsAttendance();
      //   console.log(myCourse, this.selectedAttendance);
    },
    getStudentsAttendance() {
      //   console.log(this.getPassMyCourse);
      axios
        .post("/teacher/getSelectedAttendance", {
          myCourse: this.getPassMyAttendanceCourse,
        })
        .then((res) => {
          this.attendanceStudents = res.data.getAttendanceStudents;
          this.attendanceType = res.data.attendanceType;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    myCourseExamGradeFinalQuarter(myCourse) {
      this.selectTeachQuartFinal = false;
      this.myPassCourse = myCourse;
      //   console.log(myCourse);
      this.$nextTick(() => {
        this.selectTeachQuartFinal = true;
        this.selectedAttendance = null;
        this.selectedSchoolCourse = false;
        this.selectStudentForCourseComment = false;
      });
    },

    //хичээлд хамрагдаж буй суралцагчид дээр багшийн явцын сэтгэгдэл бичих функц

    courseCommentForStudent(myCourse) {
      //Бусад утгуудыг хүчингүй болгох
      this.selectedSchoolCourse = null;
      this.selectedAttendance = false;
      this.selectTeachQuartFinal = false;
      //   console.log(myCourse);
      this.loading = true;
      this.getPassMyCourseComment = myCourse;
      this.selectedSchoolClassComment = this.getPassMyCourseComment.full_name;
      this.selectedSchoolCourseComment =
        this.getPassMyCourseComment.subject_name;
      this.comment_school_id = this.getPassMyCourseComment.course_school_id;
      this.comment_teacher_id = this.getPassMyCourseComment.course_teacher_id;
      this.comment_course_id = this.getPassMyCourseComment.course_id;
      this.comment_school_class_id =
        this.getPassMyCourseComment.course_school_class_id;

      axios
        .post("/teacher/getStudentsCourseGradeExam", {
          myCourse: this.getPassMyCourseComment,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectStudentForCourseComment = true;
          this.getCourseStudentsCommentQ1 =
            res.data.getStudentCourseGradeQuarter1;
          this.getCourseStudentsCommentQ2 =
            res.data.getStudentCourseGradeQuarter2;
          this.getCourseStudentsCommentQ3 =
            res.data.getStudentCourseGradeQuarter3;
          this.getCourseStudentsCommentQ4 =
            res.data.getStudentCourseGradeQuarter4;

          this.loading = false;
          //   Swal.fire({
          //     position: "top-end",
          //     icon: "success",
          //     title: "Суралцагчдыг амжилттай дуудлаа",
          //     showConfirmButton: false,
          //     timer: 500,
          //   });
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    changeQuarter(e) {
      //   console.log(e);
      this.comment_changeQuarterId = e;

      //   console.log("..........." + this.changeQuarterId);
    },
  },
  created() {
    this.getMyCourses();
  },
};
</script>

<style></style>
