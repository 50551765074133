<template>
  <div>
    <input v-model="prompt" placeholder="Текстээ бичнэ үү" />
    <button @click="getResponse">Хариу авах</button>
    <p>{{ response }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      prompt: "",
      response: "",
    };
  },
  methods: {
    async getResponse() {
      try {
        const res = await axios.post("/openai", {
          prompt: this.prompt,
        });
        this.response = res.data.choices[0].text;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>