<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="4">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#budgetThisYear"
                          data-toggle="tab"
                          >Энэ жилийн төсөв</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#budgetNextYear"
                          data-toggle="tab"
                          >Ирэх жилийн төсөв</a
                        >
                      </li>
                    </ul>
                  </div>
                </b-col>
                <b-col sm="8">
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="success"
                    @click.prevent="createBudget()"
                    >Төсөв бүртгэх</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <div class="tab-content">
            <div class="tab-pane active" id="budgetThisYear">
              <table
                class="table table-hover table-striped table-valign-middle"
              >
                <thead>
                  <tr role="row">
                    <th>№</th>
                    <th>Зураг</th>
                    <!-- <th>БНБ</th>
                    <th>Ажлын байр</th> -->
                    <th>Төсвийн төрөл</th>
                    <th>Төсөвлөсөн бүтээгдэхүүн</th>
                    <th>Нэгж үнэ(төсөвт ооруулсан)</th>
                    <th>Тухайн бүтээгдэхүүний үнэ</th>
                    <th>Нэгж үнийн зөрүү</th>
                    <th>Авах тоо</th>
                    <th>Батлуулах дүн</th>
                    <th>Баталсан</th>
                    <th>Баталсан дүн</th>
                    <th>Зарцуулсан дүн</th>
                    <th>Зарцуулсан тайлбар</th>
                    <th>Үйлдлүүд</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="odd"
                    v-for="(budget, index) in budgets"
                    :key="budget.id"
                  >
                    <td tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td
                      class="dtr-control sorting_1"
                      tabindex="0"
                      style="width: 8rem"
                    >
                      <!-- <span style="width: 100%" class="direct-chat-name float-left">
                    <img
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="budget.profile_photo_url"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-if="budget.profile_photo_url !== null"
                    />
                    <img
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="defaultimage"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-else-if="budget.profile_photo_url === null"
                    />
                  </span> -->
                      <img
                        :src="budget.budget_images_1"
                        alt="user-avatar"
                        class="direct-chat-img"
                      />
                    </td>
                    <!-- <td tabindex="0">
                      {{ budget.department_code }}
                    </td>
                    <td tabindex="0">
                      {{ budget.position_name }}
                    </td> -->
                    <td tabindex="0">
                      {{ budget.budget_type_name }}
                    </td>
                    <td tabindex="0">
                      {{ budget.budget_item_name }}
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success">
                        {{ budget.price }} төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="primary"
                        >{{ budget.budget_item_price }} төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="warning"
                        >{{
                          budgetPriceDifference(
                            budget.price,
                            budget.budget_item_price
                          )
                        }}
                        төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success">{{ budget.pieces }}</b-badge>
                    </td>
                    <td tabindex="0">
                      <b-badge variant="info">{{ budget.total }} төг</b-badge>
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success" v-show="budget.confirmed == 1"
                        >Баталсан</b-badge
                      >
                      <b-badge variant="warning" v-show="budget.confirmed == 0"
                        >Батлаагүй</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success" v-show="budget.confirmed == 1"
                        >{{ budget.confirmed_total }} төг</b-badge
                      >
                      <b-badge variant="warning" v-show="budget.confirmed == 0"
                        >0 төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge
                        variant="success"
                        v-show="budget.zartsuulsan !== null"
                        >{{ budget.zartsuulsan }} төг</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-show="budget.zartsuulsan === null"
                        >0 төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge
                        variant="info"
                        v-show="budget.zartsuulsan_tailbar !== null"
                        >{{ budget.zartsuulsan_tailbar }}</b-badge
                      >
                      <b-badge
                        variant="info"
                        v-show="budget.zartsuulsan_tailbar === null"
                        >Тайлбар ороогүй</b-badge
                      >
                    </td>
                    <td tabindex="0" style="width: 8rem">
                      <a
                        href="#"
                        class="badge bg-info"
                        v-if="budget.confirmed == 1"
                        @click="spentBudget(budget)"
                        v-b-popover.hover.top="
                          'Төсөв зарцуулах үеийн мэдээлэл оруулах. Төсөв дутсан, илүү гарсан. Шалтгаан нь юунаас болсон гэх зэргээр.'
                        "
                        title="Зарцуулалт"
                        ><i class="fas fa-sitemap"></i
                      ></a>
                      <a
                        href="#"
                        class="badge badge-warning"
                        v-if="budget.confirmed == 0"
                        @click="editBudget(budget)"
                        v-b-popover.hover.top="'Төсвийн мэдээллийг засах'"
                        title="Засах"
                        ><i class="fas fa-edit"></i
                      ></a>

                      <a
                        href="#"
                        class="badge bg-danger"
                        v-if="budget.confirmed == 0"
                        @click="deleteBudget(budget)"
                        v-b-popover.hover.top="'Төсвийн мэдээллийг устгах'"
                        title="Устгах"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td colspan="4"></td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ negjUne.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ tuhainUne.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ zuruuUne.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ avahToo.toLocaleString() }} </strong>
                    </td>
                    <td colspan="2" class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ batluulahUne.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ batalsanUne.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ zartsuulsanUne.toLocaleString() }} </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane" id="budgetNextYear">
              <table
                class="table table-hover table-striped table-valign-middle"
              >
                <thead>
                  <tr role="row">
                    <th>№</th>
                    <th>Зураг</th>
                    <!-- <th>БНБ</th>
                    <th>Ажлын байр</th> -->
                    <th>Төсвийн төрөл</th>
                    <th>Төсөвлөсөн бүтээгдэхүүн</th>
                    <th>Нэгж үнэ(төсөвт ооруулсан)</th>
                    <th>Тухайн бүтээгдэхүүний үнэ</th>
                    <th>Нэгж үнийн зөрүү</th>
                    <th>Авах тоо</th>
                    <th>Батлуулах дүн</th>
                    <th>Баталсан</th>
                    <th>Баталсан дүн</th>
                    <th>Зарцуулсан дүн</th>
                    <th>Зарцуулсан тайлбар</th>
                    <th>Үйлдлүүд</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="odd"
                    v-for="(budget, index) in budgetsNextYear"
                    :key="budget.id"
                  >
                    <td tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td
                      class="dtr-control sorting_1"
                      tabindex="0"
                      style="width: 8rem"
                    >
                      <!-- <span style="width: 100%" class="direct-chat-name float-left">
                    <img
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="budget.profile_photo_url"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-if="budget.profile_photo_url !== null"
                    />
                    <img
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="defaultimage"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-else-if="budget.profile_photo_url === null"
                    />
                  </span> -->
                      <img
                        :src="budget.budget_images_1"
                        alt="user-avatar"
                        class="direct-chat-img"
                      />
                    </td>
                    <!-- <td tabindex="0">
                      {{ budget.department_code }}
                    </td>
                    <td tabindex="0">
                      {{ budget.position_name }}
                    </td> -->
                    <td tabindex="0">
                      {{ budget.budget_type_name }}
                    </td>
                    <td tabindex="0">
                      {{ budget.budget_item_name }}
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success">
                        {{ budget.price }} төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="primary"
                        >{{ budget.budget_item_price }} төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="warning"
                        >{{
                          budgetPriceDifference(
                            budget.price,
                            budget.budget_item_price
                          )
                        }}
                        төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success">{{ budget.pieces }}</b-badge>
                    </td>
                    <td tabindex="0">
                      <b-badge variant="info">{{ budget.total }} төг</b-badge>
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success" v-show="budget.confirmed == 1"
                        >Баталсан</b-badge
                      >
                      <b-badge variant="warning" v-show="budget.confirmed == 0"
                        >Батлаагүй</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge variant="success" v-show="budget.confirmed == 1"
                        >{{ budget.confirmed_total }} төг</b-badge
                      >
                      <b-badge variant="warning" v-show="budget.confirmed == 0"
                        >0 төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge
                        variant="success"
                        v-show="budget.zartsuulsan !== null"
                        >{{ budget.zartsuulsan }} төг</b-badge
                      >
                      <b-badge
                        variant="warning"
                        v-show="budget.zartsuulsan === null"
                        >0 төг</b-badge
                      >
                    </td>
                    <td tabindex="0">
                      <b-badge
                        variant="info"
                        v-show="budget.zartsuulsan_tailbar !== null"
                        >{{ budget.zartsuulsan_tailbar }}</b-badge
                      >
                      <b-badge
                        variant="info"
                        v-show="budget.zartsuulsan_tailbar === null"
                        >Тайлбар ороогүй</b-badge
                      >
                    </td>
                    <td tabindex="0" style="width: 8rem">
                      <a
                        href="#"
                        class="badge bg-info"
                        v-if="budget.confirmed == 1"
                        @click="spentBudget(budget)"
                        v-b-popover.hover.top="
                          'Төсөв зарцуулах үеийн мэдээлэл оруулах. Төсөв дутсан, илүү гарсан. Шалтгаан нь юунаас болсон гэх зэргээр.'
                        "
                        title="Зарцуулалт"
                        ><i class="fas fa-sitemap"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-warning"
                        v-if="budget.confirmed == 0"
                        @click="editBudget(budget)"
                        v-b-popover.hover.top="'Төсвийн мэдээллийг засах'"
                        title="Засах"
                        ><i class="fas fa-edit"></i
                      ></a>

                      <a
                        href="#"
                        class="badge bg-danger"
                        v-if="budget.confirmed == 0"
                        @click="deleteBudget(budget)"
                        v-b-popover.hover.top="'Төсвийн мэдээллийг устгах'"
                        title="Устгах"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td colspan="4"></td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ negjUne1.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ tuhainUne1.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ zuruuUne1.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ avahToo1.toLocaleString() }} </strong>
                    </td>
                    <td colspan="2" class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ batluulahUne1.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ batalsanUne1.toLocaleString() }} </strong>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <strong>{{ zartsuulsanUne1.toLocaleString() }} </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-budgets"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="editMode === false ? 'Төсөв бүртгэх' : 'Төсөв засах'"
      class="modal fade"
      hide-footer
      @hide="cancelBudget"
    >
      <div class="modal-body">
        <form
          class="form-horizontal"
          wtx-context="9944B6A2-0454-4588-82D8-836F0401FA6F"
        >
          <div class="form-group row">
            <label for="name" class="col-sm-3 col-form-label"
              >Төсөв харьяалагдах хичээлийн жил сонгох</label
            >
            <div class="col-sm-9">
              <multiselect
                v-model="form.year_id"
                deselect-label="Can't remove this value"
                track-by="id"
                label="id"
                placeholder="Төсвийн харьяалагдах огноог сонгох."
                :options="years"
                :searchable="true"
                :allow-empty="true"
                :custom-label="budgetBelongsToYear"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>
                    {{ option.start_date | dateYear }} -
                    {{ option.finish_date | dateYear }}</strong
                  ><strong> хичээлийн жил</strong></template
                >
                <!-- `Ангилал: ` + `${name} — [${item_name} - ${item_description}]` -->
              </multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-sm-3 col-form-label"
              >Бүтээгдэхүүн</label
            >
            <div class="col-sm-9">
              <multiselect
                v-model="form.name"
                deselect-label="Can't remove this value"
                track-by="id"
                label="id"
                placeholder="Төсөвт захиалах бүтээгдэхүүн хайх. Үр дүн олдоогүй бол шинээр бүртгүүлнэ."
                :options="items"
                :searchable="true"
                :allow-empty="true"
                :custom-label="budgetName"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  >Ангилал: <strong>{{ option.name }}</strong
                  ><strong>
                    -{{ option.item_name }} -
                    {{ option.item_description }}</strong
                  ></template
                >
                <!-- `Ангилал: ` + `${name} — [${item_name} - ${item_description}]` -->
              </multiselect>
              <b-button
                mt="2"
                variant="outline-warning"
                size="sm"
                @click="itemAddChangeMode()"
                >Бүтээгдэхүүн шинээр бүртгэх бол дарна уу?</b-button
              >
              <div v-show="itemAddMode">
                <multiselect
                  v-model="formItem.budget_type_id"
                  deselect-label="Can't remove this value"
                  track-by="id"
                  label="id"
                  placeholder="Төсвийн ангилал сонгоно уу? Ангилал сонгохгүйгээр хадгалах боломжгүй."
                  :options="budgetTypes"
                  :searchable="true"
                  :allow-empty="false"
                  :custom-label="budgetType"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    >Ангилал: <strong>{{ option.name }}</strong>
                  </template>
                </multiselect>
                <input
                  type="text"
                  class="form-control"
                  id="item_name"
                  placeholder="Бүтээгдэхүүний нэр, Бүтээгдэхүүний жагсаалтанд байгаа нэртэй давхцах боломжгүйг анхаарна уу."
                  wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                  v-model="formItem.item_name"
                />
                <input
                  type="number"
                  class="form-control"
                  id="item_price"
                  placeholder="Бүтээгдэхүүний үнэ"
                  wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                  v-model="formItem.item_price"
                />
                <b-form-textarea
                  id="textarea"
                  v-model="formItem.item_description"
                  placeholder="Бүтээгдэхүүнтэй холбоотой дэлгэрэнгүй мэдээлэл..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click.prevent="addItem()"
                  >Бүтээгдэхүүн бүртгэх</b-button
                >
                <b-button
                  variant="outline-warning"
                  size="sm"
                  @click.prevent="cancelItem()"
                  >Цуцлах</b-button
                >
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="measure_unit" class="col-sm-3 col-form-label"
              >Хэмжих нэгж</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="measure_unit"
                placeholder="Хэмжих нэгж(Ширхэг, л, мл, , мг, кг, тн, м, см, мм,... гэх мэт)"
                wtx-context="82E1F3E5-8B75-4725-AE91-BDCBFF9F1462"
                v-model="form.measure_unit"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('measure_unit'),
                  },
                ]"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="pieces" class="col-sm-3 col-form-label"
              >Бүтээгдэхүүн авах тоо</label
            >
            <div class="col-sm-9">
              <input
                type="number"
                class="form-control"
                id="pieces"
                placeholder="Тухайн бүтээгдэхүүнээс авах тоо"
                wtx-context="D2961986-E5DC-4B7F-8546-BA5A247D39C8"
                v-model="form.pieces"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('pieces'),
                  },
                ]"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputName2" class="col-sm-3 col-form-label"
              >Бүтээгдэхүүний ширхэгийн үнэ</label
            >
            <div class="col-sm-9">
              <input
                type="number"
                class="form-control"
                id="inputName2"
                placeholder="Бүтээгдэхүүний ширхэгийн үнэ"
                wtx-context="D2961986-E5DC-4B7F-8546-BA5A247D39C8"
                v-model="form.price"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('price'),
                  },
                ]"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputName2" class="col-sm-3 col-form-label"
              >Нийт үнэ</label
            >
            <div class="col-sm-9">{{ totalUne }} төгрөг</div>
          </div>
          <div class="form-group row">
            <label for="zoriulalt" class="col-sm-3 col-form-label"
              >Зориулалт</label
            >
            <div class="col-sm-9">
              <b-form-textarea
                class="form-control"
                id="zoriulalt"
                v-model="form.zoriulalt"
                placeholder="Тухайн бүтээгдэхүүний хэрэглээ, зориулалтыг дэлгэрэнгүй тайлбарлаж бичнэ үү?"
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('zoriulalt'),
                  },
                ]"
              ></b-form-textarea>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputSkills" class="col-sm-3 col-form-label"
              >Харьцуулалт хийсэн эсэх</label
            >
            <div class="col-sm-9">
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.compare"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                wtx-context="B4FFCF8C-BB7B-420F-8685-27C8570029B5"
                @change="someImageClear"
              >
                <span v-if="form.compare === '' || form.compare === '0'"
                  >Үгүй</span
                >
                <span v-else>Тийм</span>
              </b-form-checkbox>
            </div>
          </div>

          <div class="form-group row">
            <label for="choose_1" class="col-sm-3 col-form-label"
              >Сонголт 1</label
            >
            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="choose_1"
                placeholder="Сонголт 1"
                v-model="form.choose_1"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('choose_1'),
                  },
                ]"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="choose_1_link" class="col-sm-3 col-form-label"
              >Сонголт 1 холбогдох мэдээлэл</label
            >
            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="choose_1_link"
                placeholder="Сонголт 1 холбогдох линк"
                v-model="form.choose_1_link"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('choose_1_link'),
                  },
                ]"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="budget_images_1" class="col-sm-3 col-form-label"
              >Бүтээгдэхүүний зураг 1</label
            >
            <div class="col-sm-9">
              <div class="row">
                <span class="col-sm-2 direct-chat-name float-left">
                  <img
                    v-show="imgLoaded1"
                    class="profile-user-img img-fluid attachment-img mr-2"
                    :src="form.budget_images_1"
                    alt="Төслийн зураг"
                    accept="image/*"
                    v-if="form.budget_images_1 !== ''"
                  />
                  <img
                    class="profile-user-img img-fluid attachment-img"
                    :src="defaultimage"
                    alt="Багшийн зураг"
                    accept="image/*"
                    v-else-if="form.budget_images_1 === ''"
                  />
                </span>
                <b-form-file
                  id="budget_images_1"
                  browse-text="Зураг сонгох"
                  class="col-sm-5"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('budget_images_1'),
                    },
                  ]"
                  @change="getImg1"
                ></b-form-file>
                <span class="col-sm-5">
                  {{ imgError1 }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="form.compare === '1'">
            <div class="form-group row">
              <label for="choose_2" class="col-sm-3 col-form-label"
                >Сонголт 2</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  id="choose_2"
                  placeholder="Сонголт 2"
                  v-model="form.choose_2"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="choose_2_link" class="col-sm-3 col-form-label"
                >Сонголт 2 холбогдох мэдээлэл</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  id="choose_2_link"
                  placeholder="Сонголт 2 холбогдох линк"
                  v-model="form.choose_2_link"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="budget_images_2" class="col-sm-3 col-form-label"
                >Бүтээгдэхүүний зураг 2</label
              >
              <div class="col-sm-9">
                <div class="row">
                  <span class="col-sm-2 direct-chat-name float-left">
                    <img
                      v-show="imgLoaded2"
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="form.budget_images_2"
                      alt="Төслийн зураг"
                      accept="image/*"
                      v-if="form.budget_images_2 !== ''"
                    />
                    <img
                      class="profile-user-img img-fluid attachment-img"
                      :src="defaultimage"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-else-if="form.budget_images_2 === ''"
                    />
                  </span>
                  <b-form-file
                    id="budget_images_2"
                    browse-text="Зураг сонгох"
                    class="col-sm-5"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('budget_images_2'),
                        'is-invalid': imgError1,
                      },
                    ]"
                    @change="getImg2"
                  ></b-form-file>
                  <span class="col-sm-5">
                    {{ imgError2 }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="choose_3" class="col-sm-3 col-form-label"
                >Сонголт 3</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  id="choose_3"
                  placeholder="Сонголт 3"
                  v-model="form.choose_3"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="choose_3_link" class="col-sm-3 col-form-label"
                >Сонголт 3 холбогдох мэдээлэл</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  id="choose_3_link"
                  placeholder="Сонголт 3 холбогдох линк"
                  v-model="form.choose_3_link"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="budget_images_3" class="col-sm-3 col-form-label"
                >Бүтээгдэхүүний зураг 3</label
              >
              <div class="col-sm-9">
                <div class="row">
                  <span class="col-sm-2 direct-chat-name float-left">
                    <img
                      v-show="imgLoaded3"
                      class="profile-user-img img-fluid attachment-img mr-2"
                      :src="form.budget_images_3"
                      alt="Төслийн зураг"
                      accept="image/*"
                      v-if="form.budget_images_3 !== ''"
                    />
                    <img
                      class="profile-user-img img-fluid attachment-img"
                      :src="defaultimage"
                      alt="Багшийн зураг"
                      accept="image/*"
                      v-else-if="form.budget_images_3 === ''"
                    />
                  </span>
                  <b-form-file
                    id="budget_images_3"
                    browse-text="Зураг сонгох"
                    class="col-sm-5"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('budget_images_3'),
                        'is-invalid': imgError3,
                      },
                    ]"
                    @change="getImg3"
                  ></b-form-file>
                  <span class="col-sm-5">
                    {{ imgError3 }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="storeBudget()" v-if="!editMode" variant="success">
          Хадгалах
        </b-button>
        <b-button @click="updateBudget()" v-if="editMode" variant="warning">
          Засах
        </b-button>
        <b-button @click="cancelBudget()" variant="danger"> Хаах </b-button>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-budget-spent"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсөв зарцуулалтыг тэмдэглэх"
      class="modal fade"
      hide-footer
      @hide="cancelBudget"
    >
      <div class="modal-body">
        <form
          class="form-horizontal"
          wtx-context="9944B6A2-0454-4588-82D8-836F0401FA6F"
        >
          <div class="form-group row">
            <label for="pieces" class="col-sm-3 col-form-label"
              >Батлагдсан төсвөөс зарцуулсан зүн</label
            >
            <div class="col-sm-9">
              <input
                type="number"
                class="form-control"
                id="pieces"
                placeholder="Батлагдсан төсвөөс зарцуулсан зүн"
                wtx-context="D2961986-E5DC-4B7F-8546-BA5A247D39C8"
                v-model="form.zartsuulsan"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputName2" class="col-sm-3 col-form-label"
              >Батлагдсан төсвөөс зарцуулсан тайлбар</label
            >
            <div class="col-sm-9">
              <b-form-textarea
                class="form-control"
                id="zoriulalt"
                v-model="form.zartsuulsan_tailbar"
                placeholder="Батлагдсан төсвөөс зарцуулсан тайлбар"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button
          @click="spentBudgetUpdate()"
          v-if="!editMode"
          variant="success"
        >
          Хадгалах
        </b-button>
        <b-button @click="cancelBudgetSpent()" variant="danger">
          Хаах
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      priceList: [],
      itemPList: [],
      diffList: [],
      piecesList: [],
      batluulahList: [],
      batalsanList: [],
      zartsuulsanList: [],
      negjUne: 0,
      tuhainUne: 0,
      zuruuUne: 0,
      avahToo: 0,
      batluulahUne: 0,
      batalsanUne: 0,
      zartsuulsanUne: 0,
      priceList1: [],
      itemPList1: [],
      diffList1: [],
      piecesList1: [],
      batluulahList1: [],
      batalsanList1: [],
      zartsuulsanList1: [],
      negjUne1: 0,
      tuhainUne1: 0,
      zuruuUne1: 0,
      avahToo1: 0,
      batluulahUne1: 0,
      batalsanUne1: 0,
      zartsuulsanUne1: 0,
      editMode: false,
      itemAddMode: false,
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      budgets: [],
      budgetsNextYear: [],
      years: [],
      budgetTypes: [],
      items: [],
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        budget_type_id: "",
        budget_item_id: "",
        dep_id: "",
        emp_id: "",
        project_id: "",
        name: "",
        measure_unit: "",
        pieces: "",
        price: "",
        total: "",
        budget_images_1: "",
        budget_images_2: "",
        budget_images_3: "",
        zoriulalt: "",
        compare: "",
        supplier: "",
        choose_1: "",
        choose_1_link: "",
        choose_2: "",
        choose_2_link: "",
        choose_3: "",
        choose_3_link: "",
        confirmed_total: "",
        zartsuulsan: "",
        zartsuulsan_tailbar: "",
      }),

      formItem: new Form({
        id: "",
        budget_type_id: "",
        item_name: "",
        item_price: "",
        item_description: "",
      }),

      //Зурагтай ажиллах
      defaultimage: "/images/users/user.png",
      imgError1: "",
      imgError2: "",
      imgError3: "",
      imgLoaded1: false,
      imgLoaded2: false,
      imgLoaded3: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    totalUne: function () {
      let totalDun;
      if (this.form.pieces && this.form.price) {
        totalDun = this.form.pieces * this.form.price;
        this.form.total = totalDun;
        return totalDun;
      } else {
        this.form.total = 0;
        return 0;
      }
    },
  },
  methods: {
    getBudgets() {
      axios
        .get("/all_worker_functions/getBudgets")
        .then((response) => {
          this.budgetTypes = response.data.budgetTypes;
          this.budgets = response.data.budgets;
          this.budgetsNextYear = response.data.budgetsNextYear;
          this.years = response.data.years;
          this.items = response.data.items;
          this.sumCheck(this.budgets);
          this.sumCheckNext(this.budgetsNextYear);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sumCheckNext(allBudget) {
      this.priceList1 = [];
      this.itemPList1 = [];
      this.diffList1 = [];
      this.piecesList1 = [];
      this.batluulahList1 = [];
      this.batalsanList1 = [];
      this.zartsuulsanList1 = [];
      allBudget.forEach((element) => {
        if (element.price == null) this.priceList1.push(0);
        else this.priceList1.push(parseInt(element.price));
        if (element.budget_item_price == null) this.itemPList1.push(0);
        else this.itemPList1.push(parseInt(element.budget_item_price));
        if (element.price == null && element.budget_item_price == null)
          this.diffList1.push(0);
        else
          this.diffList1.push(
            parseInt(
              this.budgetPriceDifference(
                element.price,
                element.budget_item_price
              )
            )
          );
        if (element.pieces == null) this.piecesList1.push(0);
        else this.piecesList1.push(parseInt(element.pieces));
        if (element.total == null) this.batluulahList1.push(0);
        else this.batluulahList1.push(parseInt(element.total));
        if (element.confirmed_total == null) this.batalsanList1.push(0);
        else this.batalsanList1.push(parseInt(element.confirmed_total));
        if (element.zartsuulsan == null) this.zartsuulsanList1.push(0);
        else this.zartsuulsanList1.push(parseInt(element.zartsuulsan));
      });

      this.negjUne1 = this.priceList1.reduce((a, b) => a + b, 0);
      this.tuhainUne1 = this.itemPList1.reduce((a, b) => a + b, 0);
      this.zuruuUne1 = this.diffList1.reduce((a, b) => a + b, 0);
      this.avahToo1 = this.piecesList1.reduce((a, b) => a + b, 0);
      this.batluulahUne1 = this.batluulahList1.reduce((a, b) => a + b, 0);
      this.batalsanUne1 = this.batalsanList1.reduce((a, b) => a + b, 0);
      this.zartsuulsanUne1 = this.zartsuulsanList1.reduce((a, b) => a + b, 0);
    },
    sumCheck(allBudget) {
      this.priceList = [];
      this.itemPList = [];
      this.diffList = [];
      this.piecesList = [];
      this.batluulahList = [];
      this.batalsanList = [];
      this.zartsuulsanList = [];
      allBudget.forEach((element) => {
        if (element.price == null) this.priceList.push(0);
        else this.priceList.push(parseInt(element.price));
        if (element.budget_item_price == null) this.itemPList.push(0);
        else this.itemPList.push(parseInt(element.budget_item_price));
        if (element.price == null && element.budget_item_price == null)
          this.diffList.push(0);
        else
          this.diffList.push(
            parseInt(
              this.budgetPriceDifference(
                element.price,
                element.budget_item_price
              )
            )
          );
        if (element.pieces == null) this.piecesList.push(0);
        else this.piecesList.push(parseInt(element.pieces));
        if (element.total == null) this.batluulahList.push(0);
        else this.batluulahList.push(parseInt(element.total));
        if (element.confirmed_total == null) this.batalsanList.push(0);
        else this.batalsanList.push(parseInt(element.confirmed_total));
        if (element.zartsuulsan == null) this.zartsuulsanList.push(0);
        else this.zartsuulsanList.push(parseInt(element.zartsuulsan));
      });

      console.log(this.zartsuulsanList, "asdasd");
      this.negjUne = this.priceList.reduce((a, b) => a + b, 0);
      this.tuhainUne = this.itemPList.reduce((a, b) => a + b, 0);
      this.zuruuUne = this.diffList.reduce((a, b) => a + b, 0);
      this.avahToo = this.piecesList.reduce((a, b) => a + b, 0);
      this.batluulahUne = this.batluulahList.reduce((a, b) => a + b, 0);
      this.batalsanUne = this.batalsanList.reduce((a, b) => a + b, 0);
      this.zartsuulsanUne = this.zartsuulsanList.reduce((a, b) => a + b, 0);
    },
    budgetPriceDifference(price, budget_item_price) {
      return price - budget_item_price;
    },

    createBudget() {
      this.$bvModal.show("modal-budgets");
    },
    storeBudget() {
      this.form
        .post("/all_worker_functions/createBudget")
        .then((response) => {
          this.$toastr.s("Төсөв амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadBudgets");
          this.cancelBudget();
        })
        .catch(() => {
          this.$toastr.e(
            "Төсвийн төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    editBudget(budget) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", budget);
      this.$bvModal.show("modal-budgets");
      this.form.reset();
      this.form.fill(budget);
      let item = this.items.filter((el) => {
        if (el.id == budget.budget_item_id) {
          return el;
        }
      });
      let year = this.years.filter((el) => {
        if (el.id == budget.year_id) {
          return el;
        }
      });
      if (budget.budget_images_1) {
        this.imgLoaded1 = true;
      }
      //   console.log(item, year);
      this.form.name = item[0];
      this.form.year_id = year[0];
      this.editMode = true;
      this.form.compare = budget.compare.toString();
    },
    updateBudget() {
      //   console.log(this.form);
      this.form
        .put("/all_worker_functions/updateBudget/" + this.form.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadBudgets");
          this.cancelEdit();
        })
        .catch((errors) => {
          this.$toastr.e(
            "Төсвийн төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-budgets");
    },
    cancelBudget() {
      this.$bvModal.hide("modal-budgets");
      this.form.reset();
      this.editMode = false;

      //   console.log("budget");
    },

    cancelBudgetSpent() {
      this.$bvModal.hide("modal-budget-spent");
      this.cancelBudget();
    },

    spentBudget(budget) {
      this.form.fill(budget);
      this.$bvModal.show("modal-budget-spent");
      //   console.log(budget);
    },
    spentBudgetUpdate() {
      this.form
        .put("/all_worker_functions/spentBudget/" + this.form.id)
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadBudgets");
          this.cancelBudgetSpent();
        })
        .catch((err) => console.log(err));
    },
    deleteBudget(budget) {
      Swal.fire({
        title: "Энэ бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?",
        text:
          "Энэ" +
          budget.budget_item_name +
          " бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Төсвийн төрлийг хасаж байна...";
          axios
            .delete("/all_worker_functions/deleteBudget/" + budget.id)
            .then((response) => {
              this.$toastr.s(
                "Бүтээгдэхүүнийг амжилттай устгалаа!",
                "Шинэчилсэн"
              );
              Fire.$emit("loadBudgets");
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    //Бүтээгдэхүүн хайх
    budgetBelongsToYear({ start_date, finish_date }) {
      return `${start_date} — ${finish_date}` + ` хичээлийн жил`;
    },
    //Бүтээгдэхүүн хайх
    budgetName({ item_name, item_description, name }) {
      return `Ангилал: ` + `${name} — [${item_name} - ${item_description}]`;
    },
    budgetType({ name }) {
      return `Төсвийн ангилал: ` + `${name} `;
    },
    itemAddChangeMode() {
      this.itemAddMode = !this.itemAddMode;
    },

    addItem() {
      this.formItem
        .post("/all_worker_functions/createItem")
        .then((res) => {
          this.itemAddMode = false;
          Fire.$emit("loadBudgets");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelItem() {
      this.itemAddMode = false;
      this.formItem.reset();
    },

    //зурагтай ажиллах
    getImg1(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img1 = e.target.files[0];
      if (!img1) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img1.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError1 =
          "1024 x 1024 пикселээс бага хэмжээтэй зураг оруулна уу!";
        return;
      }
      //   console.log(img1);
      this.readImage1(img1);
    },
    readImage1(img1) {
      let reader = new FileReader();
      reader.readAsDataURL(img1);
      reader.onload = (e) => {
        this.form.budget_images_1 = e.target.result;
      };
      this.imgLoaded1 = true;
    },
    getImg2(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img2 = e.target.files[0];
      if (!img2) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img2.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError2 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage2(img2);
    },
    readImage2(img2) {
      let reader = new FileReader();
      reader.readAsDataURL(img2);
      reader.onload = (e) => {
        this.form.budget_images_2 = e.target.result;
      };
      this.imgLoaded2 = true;
    },
    getImg3(e) {
      //   console.log("Ажиллаж байна. getProjectImg");
      let img3 = e.target.files[0];
      if (!img3) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img3.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError3 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage3(img3);
    },
    readImage3(img3) {
      let reader = new FileReader();
      reader.readAsDataURL(img3);
      reader.onload = (e) => {
        this.form.budget_images_3 = e.target.result;
      };
      this.imgLoaded3 = true;
    },
    someImageClear() {
      this.form.choose_2 = "";
      this.form.choose_2_link = "";
      this.form.budget_images_2 = "";
      this.form.choose_3 = "";
      this.form.choose_3_link = "";
      this.form.budget_images_3 = "";
    },
  },
  created() {
    this.getBudgets();
    Fire.$on("loadBudgets", () => {
      this.getBudgets();
    });
    // Fire.$on("loadBudgetTypeItem", () => {
    //   this.getMyItems(this.formItem.budget_type_id);
    // });
  },
};
</script>

<style></style>
