<template>
  <div class="row pt-3">
    <!-- Цэцэрлэгийн суралцагчдын тоо -->
    <div
      v-if="
        userInfo.name === 'cipalprin' ||
        userInfo.name === 'consultant' ||
        userInfo.name === 'agermankg'
      "
      class="col-lg-12 col-6"
    >
      <!-- small box -->
      <div class="small-box bg-info">
        <div class="inner text-center">
          <h4>
            {{ studentsSKGComplexCount.countKindergartenStudents }}/{{
              countStudents(allClassKGStudentsCount)
            }}
            :
            {{
              getScore(
                studentsSKGComplexCount.countKindergartenStudents,
                countStudents(allClassKGStudentsCount)
              )
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>Цэцэрлэг</p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-bag"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Ангиудын бүртгэл: {{ allClassKGStudentsCount.length }} анги. Анги
            дүүргэлт 16-24.
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr class="text-center">
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassKGStudentsCount"
                :key="stud_class.index"
                class="text-center"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.class_cabinet }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }} /
                  {{ stud_class.full_load }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      1
                    "
                    >{{ stud_class.stud_count - stud_class.full_load }} илүү
                    бүртгэл</span
                  >
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      0
                    "
                  >
                    анги дүүрсэн
                  </span>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      -1
                    "
                    >{{
                      (stud_class.stud_count - stud_class.full_load) * -1
                    }}
                    бүртгэх боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Сургуулийн суралцагчдын тоо -->
    <div
      v-if="
        userInfo.name === 'cipalprin' ||
        userInfo.name === 'consultant' ||
        userInfo.name === 'agerman'
      "
      class="col-lg-3 col-6"
    >
      <!-- small box -->
      <div class="small-box bg-info">
        <div class="inner">
          <h4>
            {{ studentsSKGComplexCount.countAllSchoolStudents }}/{{
              studentsSKGComplexCount.schoolCapacity
            }}
            :
            {{
              getScore(
                studentsSKGComplexCount.countAllSchoolStudents,
                studentsSKGComplexCount.schoolCapacity
              )
            }}
            <!-- <br />
            {{
              getScore(
                studentsSKGComplexCount.countAllSchoolStudents,
                countStudents(allClassStudentsCount)
              )
            }} -->

            /100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>Сургууль</p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-bag"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Ангиудын бүртгэл: {{ allClassStudentsCount.length }} анги
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCount"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }} /
                  <b-badge variant="success">
                    {{ stud_class.full_load }}
                  </b-badge>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      1
                    "
                    >{{ stud_class.stud_count - stud_class.full_load }} илүү
                    бүртгэл</span
                  >
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      0
                    "
                  >
                    анги дүүрсэн
                  </span>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      -1
                    "
                    >{{
                      (stud_class.stud_count - stud_class.full_load) * -1
                    }}
                    бүртгэх боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div
      v-if="
        userInfo.name === 'cipalprin' ||
        userInfo.name === 'consultant' ||
        userInfo.name === 'agerman'
      "
      class="col-lg-3 col-6"
    >
      <!-- small box -->
      <div class="small-box bg-success">
        <div class="inner">
          <h4>
            {{ studentsSKGComplexCount.countPrimaryStudents }}/{{
              studentsSKGComplexCount.schoolCapacity
            }}
            :
            {{
              getScore(
                studentsSKGComplexCount.countPrimaryStudents,
                studentsSKGComplexCount.schoolCapacity
              )
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Бага сургууль(10 бүлэг):
            {{ studentsSKGComplexCount.countPrimaryStudents }}/260 :
            {{
              getScore(studentsSKGComplexCount.countPrimaryStudents, 260)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-stats-bars"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Бага ангийн бүртгэл:
            {{ allClassStudentsCountPrimary.length }} анги
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountPrimary"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}/
                  <b-badge variant="success">
                    {{ stud_class.full_load }}
                  </b-badge>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      1
                    "
                    >{{ stud_class.stud_count - stud_class.full_load }} илүү
                    бүртгэл</span
                  >
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      0
                    "
                  >
                    анги дүүрсэн
                  </span>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      -1
                    "
                    >{{
                      (stud_class.stud_count - stud_class.full_load) * -1
                    }}
                    бүртгэх боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div
      v-if="
        userInfo.name === 'cipalprin' ||
        userInfo.name === 'consultant' ||
        userInfo.name === 'agerman'
      "
      class="col-lg-3 col-6"
    >
      <!-- small box -->
      <div class="small-box bg-warning">
        <div class="inner">
          <h4>
            {{ studentsSKGComplexCount.countSecondaryStudents }}/{{
              studentsSKGComplexCount.schoolCapacity
            }}
            :
            {{
              getScore(
                studentsSKGComplexCount.countSecondaryStudents,
                studentsSKGComplexCount.schoolCapacity
              )
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Дунд сургууль(6 бүлэг):
            {{ studentsSKGComplexCount.countSecondaryStudents }}/156 :
            {{
              getScore(studentsSKGComplexCount.countSecondaryStudents, 156)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-person-add"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Дунд ангийн бүртгэл: {{ allClassStudentsCountMiddle.length }} анги
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountMiddle"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}/
                  <b-badge variant="success">
                    {{ stud_class.full_load }}
                  </b-badge>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      1
                    "
                    >{{ stud_class.stud_count - stud_class.full_load }} илүү
                    бүртгэл</span
                  >
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      0
                    "
                  >
                    анги дүүрсэн
                  </span>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      -1
                    "
                    >{{
                      (stud_class.stud_count - stud_class.full_load) * -1
                    }}
                    бүртгэх боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div
      v-if="
        userInfo.name === 'cipalprin' ||
        userInfo.name === 'consultant' ||
        userInfo.name === 'agerman'
      "
      class="col-lg-3 col-6"
    >
      <!-- small box -->
      <div class="small-box bg-danger">
        <div class="inner">
          <h4>
            {{ studentsSKGComplexCount.countHighStudents }}/{{
              studentsSKGComplexCount.schoolCapacity
            }}
            :
            {{
              getScore(
                studentsSKGComplexCount.countHighStudents,
                studentsSKGComplexCount.schoolCapacity
              )
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Ахлах сургууль(8 бүлэг):
            {{ studentsSKGComplexCount.countHighStudents }}/208 :
            {{ getScore(studentsSKGComplexCount.countHighStudents, 208) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-pie-graph"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Ахлах ангийн бүртгэл: {{ allClassStudentsCountHigh.length }} анги
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountHigh"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}/
                  <b-badge variant="success">
                    {{ stud_class.full_load }}
                  </b-badge>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      1
                    "
                    >{{ stud_class.stud_count - stud_class.full_load }} илүү
                    бүртгэл</span
                  >
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      0
                    "
                  >
                    анги дүүрсэн
                  </span>
                  <span
                    v-if="
                      signCheck(stud_class.stud_count - stud_class.full_load) ==
                      -1
                    "
                    >{{
                      (stud_class.stud_count - stud_class.full_load) * -1
                    }}
                    бүртгэх боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: false,
      allClassKGStudentsCount: [],
      allClassStudentsCount: [],
      allClassStudentsCountPrimary: [],
      allClassStudentsCountMiddle: [],
      allClassStudentsCountHigh: [],
    };
  },
  components: {},
  computed: {
    studentsSKGComplexCount() {
      return this.$store.state.school_students_s_kg_complex_count_info;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  methods: {
    getAllSchoolStudents() {
      axios
        .get("/manager/getAllSchoolStudents")
        .then((response) => {
          //Ангиудын суралцагчдын тоо
          this.allClassKGStudentsCount = response.data.allClassKGStudentsCount;
          this.allClassStudentsCount = response.data.allClassStudentsCount;
          this.allClassStudentsCountPrimary =
            response.data.allClassStudentsCountPrimary;
          this.allClassStudentsCountMiddle =
            response.data.allClassStudentsCountMiddle;
          this.allClassStudentsCountHigh =
            response.data.allClassStudentsCountHigh;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    //Орон нарийвчлах
    getScore(val1, val2) {
      let a = (val1 / val2) * 100;
      return a.toFixed(1);
    },
    //Тухайн түвшинд хамаарах суралцагчын тоо. Нийт дүүргэлт.
    countStudents(val1) {
      //   console.log(val1);
      let a = val1.reduce((a, b) => a + b.full_load, 0);

      //   let a = val1.reduce((a, b) => a + b, 0);
      return a.toFixed(1);
    },
    //Эерэг сурах утга хэвлэх
    signCheck(number) {
      return Math.sign(number);
    },
    checkClass(value) {
      if (value <= 50) {
        return "bg-danger";
      } else if (value <= 80) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
  },
  created() {
    this.getAllSchoolStudents();
  },
};
</script>

<style scoped></style>
