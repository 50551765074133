<template>
  <div class="row">
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <strong>УЛИРАЛ</strong>
        </div>
        <div class="card-body">
          <b-list-group v-for="term in termSets" :key="term.id">
            <b-list-group-item
              variant="primary"
              class="d-flex justify-content-between align-items-center"
            >
              {{ term.term_name }}-р улирал
              <b-badge pill variant="light"
                >{{ term.term_week }} долоо хоног
              </b-badge>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start mb-3">
              <b-badge
                pill
                v-for="month in term.months"
                :key="month.id"
                variant="light"
                class="mr-1"
              >
                {{ month.month_name }}-р сар
                <b-badge
                  pill
                  variant="warning"
                  v-b-popover.hover.right="'7 хоног'"
                  >{{ month.month_week }} долоо хоног</b-badge
                >
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <strong>СУРГАЛТЫН ТӨЛӨВЛӨГӨӨ</strong>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row my-3" fluid>
              <b-row>
                <b-col sm="4">
                  <b-form-group
                    label="Хүүхэд"
                    label-for="child_id"
                    label-cols-sm="3"
                    label-size="sm"
                    label-align-sm="right"
                  >
                    <b-form-select
                      size="sm"
                      v-model="child_id"
                      :options="childOptions"
                      text-field="student_name"
                      value-field="user_id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('child_id'),
                        },
                      ]"
                      @input="getAllPlans()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="planIsLoaded" rounded="sm">
            <b-list-group>
              <div class="row mb-3 mx-2">
                <b-list-group-item
                  v-for="subjectPlan in subjectHours"
                  :key="subjectPlan.id"
                  class="col-lg-6 d-flex justify-content-between align-items-center hourItem"
                >
                  {{ subjectPlan.subject_name }}
                  <b-badge variant="success" pill
                    >{{
                      subjectPlan.hour *
                      splitMyString(subjectPlan.yearPerWeekGradeInterval, 1)
                    }}
                    цаг</b-badge
                  >
                </b-list-group-item>
              </div>
            </b-list-group>
            <div class="col-lg-12" v-if="allMongolPlan.length">
              <div class="card-header tableHeaderBlue">ҮНДЭСНИЙ ХӨТӨЛБӨР</div>
              <table class="table projects">
                <tbody>
                  <tr v-for="(plan, index) in allMongolPlan" :key="plan.id">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="py-0"
                        @click="showCalendar(plan)"
                        v-b-toggle.sidebar-topic
                        ><b-icon
                          icon="box-arrow-in-up-right"
                          aria-hidden="true"
                        ></b-icon>
                        Үндсэн хөтөлбөр харах</b-button
                      >
                      <b-button
                        variant="primary"
                        size="sm"
                        class="py-0"
                        @click="showCalendar(plan)"
                        v-b-toggle.sidebar-short-plan
                        ><b-icon
                          icon="box-arrow-in-up-right"
                          aria-hidden="true"
                        ></b-icon>
                        Эзэмших мэдлэг ба чадвар</b-button
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="4">
                      {{ plan.grade }}-р анги, {{ plan.subject_name }}
                    </td>
                    <td tabindex="0">
                      <ul class="list-inline">
                        <li
                          class="list-inline-item"
                          v-for="teacher in plan.plan_teachers"
                          :key="teacher.id"
                        >
                          <img
                            v-if="teacher.employee"
                            @click="showImage(teacher)"
                            alt="Avatar"
                            v-b-tooltip.hover.top.html="
                              '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                              teacher.department.department_name +
                              '</dd><dt>Овог Нэр</dt><dd>' +
                              teacher.employee.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              teacher.employee.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              teacher.employee.profile_photo_url
                                ? teacher.employee.profile_photo_url
                                : '/images/users/user.png'
                            "
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td class="dtr-control sorting_1" tabindex="0" colspan="10">
                      Нийт төлөвлөгөө:
                      <strong>{{ allMongolPlan.length }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-12" v-if="allCambridgePlan.length">
              <div class="card-header tableHeaderGreen">КЭМБРИЖ ХӨТӨЛБӨР</div>
              <table class="table projects">
                <tbody>
                  <tr v-for="(plan, index) in allCambridgePlan" :key="plan.id">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="py-0"
                        @click="showCalendar(plan)"
                        v-b-toggle.sidebar-topic
                        ><b-icon
                          icon="box-arrow-in-up-right"
                          aria-hidden="true"
                        ></b-icon>
                        Кэмбриж хөтөлбөр харах</b-button
                      >

                      <b-button
                        variant="primary"
                        size="sm"
                        class="py-0"
                        @click="showCalendar(plan)"
                        v-b-toggle.sidebar-short-plan
                        ><b-icon
                          icon="box-arrow-in-up-right"
                          aria-hidden="true"
                        ></b-icon>
                        Эзэмших мэдлэг ба чадвар</b-button
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="4">
                      {{ plan.grade }}-р анги, {{ plan.subject_name }}
                    </td>
                    <td tabindex="0">
                      <ul class="list-inline">
                        <li
                          class="list-inline-item"
                          v-for="teacher in plan.plan_teachers"
                          :key="teacher.id"
                        >
                          <img
                            v-if="teacher.employee"
                            @click="showImage(teacher)"
                            alt="Avatar"
                            v-b-tooltip.hover.top.html="
                              '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                              teacher.department.department_name +
                              '</dd><dt>Овог Нэр</dt><dd>' +
                              teacher.employee.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              teacher.employee.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              teacher.employee.profile_photo_url
                                ? teacher.employee.profile_photo_url
                                : '/images/users/user.png'
                            "
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td class="dtr-control sorting_1" tabindex="0" colspan="10">
                      Нийт төлөвлөгөө:
                      <strong>{{ allCambridgePlan.length }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-12" v-if="allExtraPlan.length">
              <div class="card-header tableHeaderExtra">НЭМЭЛТ ХӨТӨЛБӨР</div>
              <table class="table projects">
                <tbody>
                  <tr v-for="(plan, index) in allExtraPlan" :key="plan.id">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="py-0"
                        @click="showCalendar(plan)"
                        v-b-toggle.sidebar-topic
                        ><b-icon
                          icon="box-arrow-in-up-right"
                          aria-hidden="true"
                        ></b-icon>
                        Харах</b-button
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="4">
                      {{ plan.grade }}-р анги, {{ plan.subject_name }}
                    </td>
                    <td tabindex="0">
                      <ul class="list-inline">
                        <li
                          class="list-inline-item"
                          v-for="teacher in plan.plan_teachers"
                          :key="teacher.id"
                        >
                          <img
                            v-if="teacher.employee"
                            @click="showImage(teacher)"
                            alt="Avatar"
                            v-b-tooltip.hover.top.html="
                              '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                              teacher.department.department_name +
                              '</dd><dt>Овог Нэр</dt><dd>' +
                              teacher.employee.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              teacher.employee.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              teacher.employee.profile_photo_url
                                ? teacher.employee.profile_photo_url
                                : '/images/users/user.png'
                            "
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td class="dtr-control sorting_1" tabindex="0" colspan="10">
                      Нийт төлөвлөгөө:
                      <strong>{{ allExtraPlan.length }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-12" v-if="allProjects.length">
              <div class="card-header tableHeaderPink">ХӨГЖҮҮЛЭХ ХИЧЭЭЛ</div>
              <table class="table projects table-responsive">
                <tbody>
                  <tr
                    v-for="(project, index) in allProjects"
                    :key="project.id"
                    :class="{ 'table-success': project.choosed == 1 }"
                  >
                    <td tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td tabindex="4" class="text-nowrap">
                      <b-icon
                        v-if="project.choosed == 1"
                        icon="check-circle-fill"
                        v-b-popover.hover.top="
                          'Таны хүүхэд энэ хөгжүүлэх хичээлийг сонгосон.'
                        "
                        variant="success"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="check-circle"
                        v-b-popover.hover.top="
                          'Таны хүүхэд энэ хөгжүүлэх хичээлийг сонгоогүй.'
                        "
                        variant="success"
                      ></b-icon>
                      {{ project.name }}
                    </td>
                    <td tabindex="0">
                      <ul class="list-inline">
                        <li
                          class="list-inline-item"
                          v-for="teacher in project.project_teachers"
                          :key="teacher.id"
                        >
                          <img
                            v-if="teacher.employee"
                            @click="showImage(teacher)"
                            alt="Avatar"
                            v-b-tooltip.hover.top.html="
                              '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                              teacher.department.department_name +
                              '</dd><dt>Овог Нэр</dt><dd>' +
                              teacher.employee.teacher_last_name +
                              ' <span class=text-uppercase>' +
                              teacher.employee.teacher_name +
                              '</span></dd></dl>'
                            "
                            class="table-avatar"
                            :src="
                              teacher.employee.profile_photo_url
                                ? teacher.employee.profile_photo_url
                                : '/images/users/user.png'
                            "
                          />
                        </li>
                      </ul>
                    </td>
                    <td tabindex="4">
                      <b-badge variant="light" class="text-wrap">
                        {{ project.description }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="odd">
                    <td tabindex="0" colspan="10">
                      Нийт:
                      <strong>{{ allProjects.length }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Календарь харах хэсэг -->
    <b-sidebar
      id="sidebar-topic"
      title="КАЛЕНДАРЬ ТӨЛӨВЛӨГӨӨ"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Анги: <strong>{{ showTopic.grade }}</strong>
            <span class="ml-5"
              >Хичээл: <strong>{{ showTopic.subject_name }}</strong></span
            >
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-header">
            <strong
              >{{ showTopic.subject_name }} {{ showTopic.startYear }}-{{
                showTopic.finishYear
              }}
              оны хичээлийн жил</strong
            >
          </div>
          <div class="card-body table-responsive">
            <b-overlay :show="planIsLoaded" rounded="sm">
              <table id="cp_table" class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Анги
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.grade }}-р анги, {{ showTopic.subject_name }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Үечлэл
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.stage }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Улирал
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="showTerm in showTerms"
                      :key="showTerm.id"
                      :colspan="showTerm.weeks_count"
                    >
                      {{ showTerm.term_name }}-р улирал
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Сар
                    </td>
                    <td
                      v-for="termMonth in showMonths"
                      :key="termMonth.id"
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="termMonth.weeks_count"
                    >
                      {{ termMonth.month_name }}-р сар
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      7 хоног
                    </td>
                    <td
                      v-for="weekObj in showPlanWeeks"
                      :key="weekObj.id"
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                    >
                      <b-badge
                        v-if="
                          nowWeekCount == parseInt(weekObj.week_index) &&
                          nowMonth == parseInt(weekObj.month_name)
                        "
                        variant="purple"
                        v-b-popover.hover.right="'Энэ 7 хоног'"
                        >{{ weekObj.week_name }}<br />
                        Энэ 7 хоног
                      </b-badge>
                      <b-badge variant="secondary" v-else>
                        {{ weekObj.week_name }}
                      </b-badge>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Бүлэг сэдэв
                    </td>
                    <td
                      v-for="subject in showSubjects"
                      :key="subject.id"
                      class="dtr-control sorting_1 text-center"
                      :colspan="subject.subject_week"
                      tabindex="0"
                    >
                      {{ subject.subject_name }}
                    </td>
                  </tr>
                  <tr v-for="indexUnit in 2" :key="'unit' + indexUnit">
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                      v-if="indexUnit == 1"
                    >
                      Нэгж сэдэв
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                      v-if="indexUnit == 2"
                    >
                      Цаг
                    </td>
                    <td
                      v-for="unit in showUnits"
                      :key="unit.id"
                      class="dtr-control sorting_1 text-center"
                      :colspan="unit.unit_week"
                      tabindex="0"
                    >
                      <div v-if="indexUnit == 1">{{ unit.unit_name }}</div>
                      <div v-if="indexUnit == 2">{{ unit.unit_hour }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center p-0"
                      tabindex="0"
                      colspan="1"
                    >
                      <div class="topicColumn">
                        <div class="topic-column">Сэдэв</div>
                      </div>
                      <div class="topic-hour-column">Цаг</div>
                    </td>
                    <td
                      v-for="week in showPlanWeeks"
                      :key="week.id"
                      class="dtr-control sorting_1 text-center p-0"
                      tabindex="0"
                    >
                      <div class="topic-wrapper" v-if="week.topics.length > 0">
                        <div
                          class="topic"
                          v-for="topic in week.topics"
                          :key="topic.id"
                        >
                          <a href="#" v-if="topic.successCount == 0">
                            <b-icon
                              icon="check-circle"
                              variant="success"
                            ></b-icon
                          ></a>
                          <a href="#" v-else>
                            <b-icon
                              icon="check-circle-fill"
                              v-b-popover.hover.right="
                                'Сэдвийн ээлжит хичээлийг заасан байдал'
                              "
                              variant="success"
                            ></b-icon
                          ></a>
                          <div class="topic-title verticalText">
                            <b-badge
                              href="#"
                              id="topicBadge"
                              variant="light"
                              v-b-popover.hover.right="
                                topic.topic_name +
                                '/' +
                                topic.topic_hour +
                                'цаг/'
                              "
                            >
                              {{ topic.topic_name }}
                              <b-badge
                                variant="warning"
                                v-b-popover.hover.right="'Цаг'"
                              >
                                {{ topic.topic_hour }} цаг
                              </b-badge>
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="topic-wrapper" v-else>
                        <div class="topic">
                          <a href="#">
                            <b-icon
                              icon="check-circle"
                              variant="success"
                              class="noActive"
                            ></b-icon
                          ></a>
                          <div class="topic-title verticalText"></div>
                        </div>
                      </div>
                      <div :class="[
                          'topic-hour',
                          { 'bg-warning': week.topicTotalHour < week.week_hour },
                        ]">
                        {{ week.week_hour }} / {{ week.topicTotalHour }}
                      </div>
                    </td>
                    <td class="bg-success text-bold">
                      <div class="topic-wrapper">
                        <div class="topic text-center">
                            <div v-b-popover.hover.left="'Нийт заасан ээлжит хичээл'">{{ totalSuccessShort }}/{{ totalTopicHours }}</div>
                          <div class="topic-title verticalText" v-b-popover.hover.top="'Нийт батлагдсан ээлжит хичээл'">
                            <div class="shortNumber float-right">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="topic-hour" v-b-popover.hover.top="'Нийт цаг'">
                        {{ totalTopicHours }} / {{ totalWeekHours }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Нийт
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek+1"
                    >
                      <b>{{ showTopic.totalWeek }} долоо хоног</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </b-sidebar>
    <!-- short plan харах хэсэг -->
    <b-sidebar
      id="sidebar-short-plan"
      title="ЭЭЛЖИТ ХИЧЭЭЛИЙН ТӨЛӨВЛӨГӨӨ"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Анги: <strong>{{ showTopic.grade }}</strong>
            <span class="ml-5"
              >Хичээл: <strong>{{ showTopic.subject_name }}</strong></span
            >
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-header">
            <strong
              >{{ showTopic.subject_name }} {{ showTopic.startYear }}-{{
                showTopic.finishYear
              }}
              оны хичээлийн жил</strong
            >
          </div>
          <div class="card-body table-responsive">
            <b-overlay :show="planIsLoaded" rounded="sm">
              <table id="cp_table" class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.grade }}-р анги, {{ showTopic.subject_name }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.stage }}
                    </td>
                  </tr>
                  <tr>
                    <th>Сар</th>
                    <th>7 хоног</th>
                    <th>Сэдвийн нэр</th>
                    <th>Орох цаг</th>
                    <th>Мэддэг болох</th>
                    <th>Чаддаг болох</th>
                  </tr>
                  <tr
                    v-for="showPlan in showPlanWeeks"
                    :key="showPlan.id"
                    class="dtr-control sorting_1 text-center"
                    tabindex="0"
                  >
                    <td>{{ showPlan.month_name }}-р сар</td>
                    <td class="dtr-control sorting_1 text-center" tabindex="0">
                      <b-badge
                        v-if="
                          nowWeekCount == parseInt(showPlan.week_index) &&
                          nowMonth == parseInt(showPlan.month_name)
                        "
                        variant="warning"
                        v-b-popover.hover.right="'Энэ 7 хоног'"
                        >{{ showPlan.week_name }}<br />
                        Энэ 7 хоног
                      </b-badge>
                      <b-badge variant="secondary" v-else>
                        {{ showPlan.week_name }}
                      </b-badge>
                    </td>

                    <td>
                      <tr v-for="topic in showPlan.topics" :key="topic.id">
                        {{
                          topic.topic_name
                        }}
                      </tr>
                    </td>
                    <td>
                      <tr v-for="topic in showPlan.topics" :key="topic.id">
                        {{
                          topic.topic_hour
                        }}
                      </tr>
                    </td>
                    <td>
                      <tr v-for="topics in showPlan.topics" :key="topics.id">
                        <b-badge
                          variant="light"
                          style="white-space: pre-wrap"
                          v-if="topics.shortPlans"
                        >
                          <span
                            style="font-size: 100%; text-align: justify"
                            v-html="
                              topics.shortPlans.knowledge
                                ? topics.shortPlans.knowledge
                                : ''
                            "
                          >
                          </span
                        ></b-badge>
                      </tr>
                    </td>
                    <td>
                      <tr v-for="topics in showPlan.topics" :key="topics.id">
                        <b-badge
                          variant="light"
                          style="white-space: pre-wrap"
                          v-if="topics.shortPlans"
                        >
                          <span
                            style="font-size: 100%; text-align: justify"
                            v-html="
                              topics.shortPlans.ability
                                ? topics.shortPlans.ability
                                : ''
                            "
                          >
                          </span
                        ></b-badge>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </b-sidebar>

    <!-- Багш нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-teacher"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Багш нарын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp"
              ><b>Үечлэл /Cambridge stage/: </b>{{ form.stage }}</span
            >
            <span class="direct-chat-timestamp float-right"
              ><b>Анги: </b>{{ form.grade }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Хичээл: </b>{{ form.subject_name }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Багш нар</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(planTeacher, index) in planTeachers"
                    :key="planTeacher.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ planTeacher.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="planTeacher.profile_photo_url != null"
                          :src="planTeacher.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <b-badge variant="light" class="my-2 ml-2">
                          {{ planTeacher.teacher_lname[0] }}.
                          {{ planTeacher.teacher_fname }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-show-employee"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
    >
      <div class="modal-body">
        <div class="row" v-if="showEmployee.employee">
          <div class="col-lg-8 mt-2">
            <img
              style="width: 100%"
              v-if="showEmployee.employee.profile_photo_url != null"
              :src="showEmployee.employee.profile_photo_url"
            />
          </div>
          <div class="col-lg-4 mt-2">
            <dl class="text-left">
              <dt>Иргэншил:</dt>
              <dd>{{ showEmployee.employee.citizenship }}</dd>
              <dt>Мэргэжлийн баг:</dt>
              <dd>{{ showEmployee.department.department_name }}</dd>
              <dt>Овог Нэр:</dt>
              <dd>
                {{ showEmployee.employee.teacher_last_name }}
                <span class="text-uppercase">
                  {{ showEmployee.employee.teacher_name }}
                </span>
              </dd>
              <dt>Системд бүртгэгдсэн огноо:</dt>
              <dd>
                {{ showEmployee.employee.created_at | dateYearMonthDay }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
import AttendanceStudent from "../../Attendance/IndexAttendance";

export default {
  data() {
    return {
      planIsLoaded: false,
      subjectHours: [],
      allMongolPlan: [],
      allCambridgePlan: [],
      allExtraPlan: [],
      allProjects: [],
      niit: 0,
      totalWeek: 0,
      selectedClass: null,
      classOptions: [],
      selectedSubject: null,
      subjectOptions: [],
      formModalTitle: "Календарь төлөвлөгөө үүсгэх",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      editMode: false,
      form: new Form({
        id: null,
        owner_id: null,
        subject_id: null,
        subject_name: "",
        cp_status: "",
        teacher_type: "",
        grade: "",
        stage: "",
      }),
      termSets: [],
      quarterOptions: [],
      errWeek: "",
      editSubjectMode: false,
      planSubjects: [],
      subjectTerms: [],
      weekOptions: [],
      formSubject: new Form({
        id: null,
        cp_id: "",
        term_id: "",
        subject_name: "",
        s_start_week_id: null,
        s_end_week_id: null,
        subject_week: 0,
      }),
      sidebarTitle: "",
      topicHour: "",
      sidebarTopicId: null,
      nowMonth: new Date().getMonth() + 1,
      nowWeekCount: this.getWeekNumOfMonthOfDate(new Date()),
      selectedDep: 0,
      depOptions: [],
      teacherOptions: [],
      planName: "",
      planId: null,
      ownerId: null,
      planTeachers: [],
      editTeacherMode: false,
      formTeacher: new Form({
        id: null,
        teacher_type: "member",
        department_id: "",
        cp_id: null,
        teachers: [],
      }),
      teacherTypes: [
        { name: "Гишүүн", value: "member" },
        { name: "Ахлах", value: "senior" },
      ],
      myCourses: [],
      childOptions: [],
      child_id: "",
      showTopic: new Form({
        id: null,
        startYear: "",
        finishYear: "",
        subject_id: "",
        subject_name: "",
        cp_status: "",
        grade: "",
        stage: "",
        teacher_type: "",
        totalWeek: 0,
      }),
      showTerms: [],
      showMonths: [],
      showSubjects: [],
      showPlanWeeks: [],
      showUnits: [],
      showPlanWeeks: [],
      showEmployee: new Form({
        department: "",
        employee: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    totalWeekHours() {
      return this.showPlanWeeks.reduce((total, week) => {
        return total + week.week_hour;
      }, 0);
    },
    totalTopicHours() {
      return this.showPlanWeeks.reduce((total, week) => {
        return total + week.topicTotalHour;
      }, 0);
    },
    totalSuccessShort() {
      return this.showPlanWeeks.reduce((total, week) => {
        return (
          total +
          week.topics.reduce((total, short) => {
            return total + short.successCount;
          }, 0)
        );
      }, 0);
    },
  },
  methods: {
    getChilds() {
      axios
        .get("/project_parent/getChilList")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0)
            this.child_id = this.childOptions[0].user_id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTermSets() {
      axios
        .get("/project_parent/getTermSets")
        .then((response) => {
          this.termSets = response.data.termSets;
          this.totalWeek = response.data.totalWeek;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },
    splitMyString(infoText, value) {
      //   console.log(infoText);
      let splittesInfoText = [...infoText.split("-")];
      //   console.log(splittesInfoText);
      splittesInfoText = splittesInfoText.filter((n) => n);
      return splittesInfoText[value];
    },
    //#region ======================== Календарь төлөвлөгөө ========================
    getAllPlans() {
      this.planIsLoaded = true;
      axios
        .post("/project_parent/getParentCalendars", {
          child_id: this.child_id,
        })
        .then((response) => {
          this.subjectHours = response.data.subjectHours;
          this.allMongolPlan = response.data.allMongolPlan;
          this.allCambridgePlan = response.data.allCambridgePlan;
          this.allExtraPlan = response.data.allExtraPlan;
          this.allProjects = _.orderBy(
            response.data.myProjects,
            "choosed",
            "desc"
          );
          this.planIsLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //#region ======================== Бүлэг сэдэв ================================
    getWeekList: function (e) {
      this.formSubject.term_id = e;
      this.action = "Долоо хонгийг шинэчилж байна...";
      console.log(this.form);
      axios
        .get("/plan/getWeekList/" + this.formSubject.term_id, {
          params: { cp_id: this.form.id },
        })
        .then((response) => {
          this.weekOptions = response.data.weekOptions;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    setWeekCount() {
      let start = this.formSubject.s_start_week_id;
      let end = this.formSubject.s_end_week_id;
      if (end != null && start != null) {
        if (end < start)
          this.errWeek = "Дуусах 7 хоног нь эхлэх 7 хоногоос хойш байх ёстой.";
        else this.errWeek = "";
        this.formSubject.subject_week = end - start + 1;
      } else {
        this.errWeek = "";
        this.formSubject.subject_week = 0;
      }
    },
    //Одоо явж байгаа 7 хоногийг олно
    getWeekNumOfMonthOfDate(d) {
      let year = d.getFullYear();
      let month = d.getMonth();
      let firstDay = new Date(year, month, 1).getDay();
      return Math.ceil(d.getDate() / 7);
    },
    //Календар
    showCalendar(plan) {
      this.showTopic.reset();
      this.showTopic.fill(plan);
      this.getShowCalendar();
    },
    getShowCalendar() {
      this.planIsLoaded = true;
      axios
        .get("/project_parent/getChildTopic/" + this.showTopic.id)
        .then((response) => {
          this.showTopic.totalWeek = response.data.totalWeek;
          this.showTopic.startYear = response.data.startYear;
          this.showTopic.finishYear = response.data.finishYear;
          this.showTerms = response.data.terms;
          this.showMonths = response.data.months;
          this.showSubjects = response.data.subjects;
          this.showUnits = response.data.units;
          this.showPlanWeeks = response.data.planWeeks;
          this.planIsLoaded = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  created() {
    this.getTermSets();
    this.getChilds();
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}

.tableHeaderGreen {
  background-color: #4da985;
  color: white;
}

.tableHeaderBlue {
  background-color: #1a6fc9;
  color: white;
}

.tableHeaderPink {
  background-color: #682ec5;
  color: white;
}

.tableHeaderExtra {
  background-color: #db5b20;
  color: white;
}

.hourItem {
  padding: 5px 15px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
