<template>
  <section class="content">
    <div class="container-fluid">
      <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-body">
                <h5><b>Сургалтын үйл ажиллагааны цогц байдал</b></h5>
                <table class="table table-bordered text-center">
                  <tbody>
                    <tr role="row">
                      <th>Сургалтын үйл ажиллагааны хэлбэр</th>
                      <th>Нийт бүлгийн тоо</th>
                      <th>Хамрагдвал зохих нийт хүүхдийн тоо</th>
                      <th>Хамрагдсан нийт хүүхдийн тоо</th>
                      <th>Боловсролын үйлчилгээний хүртээмж</th>
                      <th>Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс</th>
                    </tr>
                    <tr>
                      <td>Хичээл</td>
                      <td>{{ myClass }}</td>
                      <td>{{ studCount }}</td>
                      <td>{{ myStudents }}</td>
                      <td>{{ lessonAccess }}%</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Хөгжүүлэх ажил</td>
                      <td>{{ allProject[0] }}</td>
                      <td>{{ allProject[1] }}</td>
                      <td>{{ allProject[2] }}</td>
                      <td>{{ allProject[3].toFixed(2) }}%</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Соёл хүмүүжлийн ажил</td>
                      <td>{{ projectCxa[0] }}</td>
                      <td>{{ projectCxa[1] }}</td>
                      <td>{{ projectCxa[2] }}</td>
                      <td v-if="projectCxa[3]">
                        {{ projectCxa[3].toFixed(2) }}%
                      </td>
                      <td v-else="projectCxa[3] == null">0</td>
                    </tr>

                    <tr>
                      <td>Дундаж үзүүлэлт</td>
                      <td>
                        {{
                          (
                            (myClass + allProject[0] + projectCxa[0]) /
                            3
                          ).toFixed(2)
                        }}
                      </td>
                      <td>
                        {{
                          (
                            (studCount + allProject[1] + projectCxa[1]) /
                            3
                          ).toFixed(2)
                        }}
                      </td>
                      <td>
                        {{
                          (
                            (myStudents + allProject[2] + projectCxa[2]) /
                            3
                          ).toFixed(2)
                        }}
                      </td>
                      <td>{{ avg }}%</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <apexchart
                  v-if="!showRadar"
                  type="radar"
                  height="350"
                  :options="optionsStat"
                  :series="seriesStat"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["teacherId"],
  data() {
    return {
      myStudents: [],
      studCount: [],
      myClass: [],
      myGrades: [],
      project: [],
      projectsCXA: [],
      projectScore: [],
      classAvg: [],
      studLimitAvg: [],
      studRegAvg: [],
      studExamAvg: [],
      lessonAccess: [],
      projectAccess: [],
      showRadar: true,
      editMode: false,
      avg: 0,
      seriesStat: [
        {
          name: "Дундаж хувь",
          data: [],
        },
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      optionsStat: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: ["Хичээл", "Хөгжүүлэх ажил", "Соёл хүмүүжлийн ажил"],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
    };
  },
  computed: {
    allProject() {
      let project = [];
      let projectClass = [];
      let projectStudCount = [];
      let projectMyStud = [];
      this.project.forEach((element) => {
        projectClass.push(parseFloat(element.classCount));
        projectStudCount.push(parseFloat(element.limit));
        projectMyStud.push(parseFloat(element.registered));
      });
      const classes = projectClass.reduce((a, b) => a + b, 0);
      const studLimit = projectStudCount.reduce((a, b) => a + b, 0);
      const studReg = projectMyStud.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(classes, studLimit, studReg, access);
      return project;
    },
    projectCxa() {
      let project = [];
      let projectStudLimit = [];
      let projectStudReg = [];
      this.projectsCXA.forEach((element) => {
        projectStudLimit.push(parseFloat(element.limit));
        projectStudReg.push(parseFloat(element.registered));
      });
      const count = this.projectsCXA.length;
      const studLimit = projectStudLimit.reduce((a, b) => a + b, 0);
      const studReg = projectStudReg.reduce((a, b) => a + b, 0);
      const access = (studReg / studLimit) * 100;
      project.push(count, studLimit, studReg, access);
      return project;
    },
  },
  methods: {
    getStat4() {
      this.showRadar = true;
      axios
        .post("/teacher/getStat4", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.myStudents = res.data.myStudents;
          this.studCount = res.data.studCount;
          this.myClass = res.data.myClass;
          this.myGrades = res.data.myGrades;
          this.project = res.data.project;
          this.projectScore = res.data.projectScore;
          this.projectsCXA = res.data.projectsCXA;
          //   this.studExamAvg = parseFloat(
          //     (this.studRegAvg / this.studLimitAvg) * 100
          //   ).toFixed(2);
          this.lessonAccess = this.studCount
            ? parseFloat((this.myStudents / this.studCount) * 100).toFixed(2)
            : 0;

          // Ensure arrays exist before accessing index 3
          const projectValue = this.allProject?.[3] || 0;
          const projectCxaValue = this.projectCxa?.[3] || 0;
          const project = this.allProject?.[3].toFixed(2) || 0;
          const projectCxa = this.projectCxa?.[3].toFixed(2) || 0;

          // Compute average safely
          this.avg = (
            (parseFloat(this.lessonAccess) + projectValue + projectCxaValue) /
            3
          ).toFixed(2);

          // Ensure seriesStat is properly structured
          this.seriesStat[0].data = [this.avg, this.avg, this.avg];
          this.seriesStat[1].data = [this.lessonAccess, project, projectCxa];
          this.showRadar = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getStat4();
  },
};
</script>
