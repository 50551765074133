<template>
  <div class="col-12 pt-3">
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <!-- Left navbar links -->
      <multiselect
        class="mr-2"
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="1-р алхам-Улирал сонголт"
        :options="mySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="department_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="department_id"
        label="department_name"
        placeholder="2-р алхам-Мэргэжлийн баг сонголт"
        :options="mySchoolsDepartments"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.department_name }} </strong></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="teacher_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="employees_user_id"
        label="teacher_name"
        :custom-label="customLabelTeacherName"
        placeholder="3-р алхам-Шалгалт сонголт"
        :options="mySchoolsTeachers"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.teacher_last_name[0]
            }}{{ option.teacher_last_name[1] }}.{{
              option.teacher_name
            }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        label="name"
        :custom-label="customLabelCourse"
        placeholder="4-р алхам-Хичээл сонголт"
        :options="mySchoolCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} -
            {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <button
        type="button"
        class="btn btn-warning"
        @click.prevent="getSelectMyExamData"
      >
        Анализ
      </button>
    </nav>
    <every-student
      v-if="selectedCourseData"
      :selectedLessonExamData="selectedLessonExamDataPass"
      :teacher_id="teacher_id"
      :course_id="course_id"
      :quarter_id="quarter_id"
    ></every-student>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudent from "../ExamStatForManagers/everyStudentConfirmYear.vue";
export default {
  components: {
    Multiselect,
    everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      mySchoolQuarters: [],
      mySchoolsDepartments: [],
      mySchoolsTeachers: [],
      mySchoolCourses: [],
      // Дуудах request
      quarter_id: "",
      department_id: "",
      teacher_id: "",
      course_id: "",
      //Pass to child component
      selectedCourseData: false,
      selectedLessonExamData: [],
      selectedLessonExamDataPass: [],
      selectedLessonExamDataUliralEtses: [],
      selectedLessonExamDataYear: [],
      allStudents: [],
    };
  },
  watch: {
    department_id: function (newValue, oldValue) {
      axios
        .post("/manager/departmentsTeacher", {
          department: this.department_id,
        })
        .then((response) => {
          this.mySchoolsTeachers = [];
          this.mySchoolsTeachers = response.data.mySchoolsTeachers; //Сонгогдсон багш нарын багтай хамааралтай багш нарын нэрсийг авах
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    teacher_id: function (newValue, oldValue) {
      axios
        .post("/manager/getMySchoolCourses", {
          teacher: this.teacher_id,
        })
        .then((response) => {
          this.mySchoolCourses = [];
          this.mySchoolCourses = response.data.mySchoolCourses;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    course_id: function (newValue, oldValue) {
      axios
        .post("/manager/mySchoolCourseExams", {
          myCourseData: this.course_id,
        })
        .then((response) => {
          //   console.log(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  methods: {
    getMySchoolExamStatData() {
      axios
        .get("/manager/mySchoolExamsStatData")
        .then((response) => {
          this.quarter_id = response.data.currentQuarter;
          this.mySchoolsDepartments = response.data.mySchoolDepartments;
          let a = response.data.mySchoolsQuarters;
          this.mySchoolQuarters = [a[a.length - 1]];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getSelectMyExamData() {
      this.selectedCourseData = false;
      axios
        .post("/manager/getManagerGradeDataYear", {
          quarter_id: this.quarter_id,
          department_id: this.department_id,
          teacher_id: this.teacher_id,
          course_id: this.course_id,
        })
        .then((response) => {
          //   console.log(response.data);
          //Улирал
          //   this.selectedLessonExamDataUliralEtses = [];
          //   this.selectedLessonExamDataUliralEtses =
          //     response.data.selectedLessonExamDataQuartersGrade;
          let enrolledStudents = response.data.enrolledCourseStudents;

          //Жил
          this.selectedLessonExamDataYear = [];
          this.selectedLessonExamDataYear =
            response.data.selectedLessonExamDataYear;

          //Бүх суралцагчид
          let sortManager = response.data.schoolClassAllStudents;
          sortManager.sort(
            (a, b) =>
              b.student_last_name - a.student_last_name ||
              b.student_name.localeCompare(a.student_name)
          );
          let filterStudent = [];
          sortManager.forEach((element) => {
            enrolledStudents.forEach((el) => {
              if (element.grade_exam_student_id == el.student_id) {
                filterStudent.push(element);
              }
            });
          });
          // console.log(filterStudent);

          this.allStudents = filterStudent;

          let merged = [];
          //Энийг гурван янзаар хийх ёстой, нөхцлийг нь шалгаад дамжуулах
          //   console.log(
          //     "явц" + "=>" + this.selectedLessonExamData.length,
          //     "улирал" + "=>" + this.selectedLessonExamDataUliralEtses.length,
          //     "жил" + "=>" + this.selectedLessonExamDataYear.length
          //   );

          //Зөвхөн жилийг тооцоолно.
          if (this.selectedLessonExamDataYear.length > 0) {
            let a1 = [];
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            this.selectedLessonExamDataYear.filter((el) => {
              this.allStudents.forEach((element) => {
                if (el.grade_exam_student_id == element.grade_exam_student_id) {
                  a1.push(element);
                }
              });
            });
            // console.log(a1);
            let a3 = [...this.selectedLessonExamDataYear];
            a1.forEach((ela1) => {
              a3.forEach((ela3) => {
                if (ela1.grade_exam_student_id === ela3.grade_exam_student_id) {
                  ela1.exam1Year = ela3.exam1Year;
                  ela1.exam2Year = ela3.exam2Year;
                  ela1.exam3Year = ela3.exam3Year;
                  ela1.exam4Year = ela3.exam4Year;
                  ela1.exam5Year = ela3.exam5Year;
                  ela1.exam6Year = ela3.exam6Year;
                  ela1.exam7Year = ela3.exam7Year;
                  ela1.exam8Year = ela3.exam8Year;
                  ela1.grade_exam_course_id = ela3.grade_exam_course_id;
                  ela1.grade_exam_daalgar_number =
                    ela3.grade_exam_daalgar_number;
                  ela1.grade_exam_id = ela3.grade_exam_id;
                  ela1.grade_exam_quarter_id = ela3.grade_exam_quarter_id;
                  ela1.grade_exam_school_class_id =
                    ela3.grade_exam_school_class_id;
                  ela1.grade_exam_school_id = ela3.grade_exam_school_id;
                  ela1.grade_exam_student_id = ela3.grade_exam_student_id;
                  ela1.grade_exam_teacher_id = ela3.grade_exam_teacher_id;
                  ela1.grade_exam_type = ela3.grade_exam_type;
                  ela1.student_last_name = ela3.student_last_name;
                  ela1.student_name = ela3.student_name;
                  ela1.essays = ela3.essays;
                }
              });
              merged = a1;
            });
            merged.forEach((el) => {
              if (
                parseFloat(el.exam1) +
                  parseFloat(el.exam2) +
                  parseFloat(el.exam3) +
                  parseFloat(el.exam4) +
                  parseFloat(el.exam5) +
                  parseFloat(el.exam6) +
                  parseFloat(el.exam7) +
                  parseFloat(el.exam8) >
                0
              ) {
                el.examAverage =
                  parseFloat(el.exam1) * 0.1 +
                  parseFloat(el.exam2) * 0.1 +
                  parseFloat(el.exam3) * 0.1 +
                  parseFloat(el.exam4) * 0.1 +
                  parseFloat(el.exam5) * 0.2 +
                  parseFloat(el.exam6) * 0.2 +
                  parseFloat(el.exam7) * 0.1 +
                  parseFloat(el.exam8) * 0.1;
              } else {
                el.exam1 = 0;
                el.exam2 = 0;
                el.exam3 = 0;
                el.exam4 = 0;
                el.exam5 = 0;
                el.exam6 = 0;
                el.exam7 = 0;
                el.exam8 = 0;
                el.examAverage = 0;
              }

              if (
                parseFloat(el.exam1FinalQuarter) +
                  parseFloat(el.exam2FinalQuarter) +
                  parseFloat(el.exam3FinalQuarter) +
                  parseFloat(el.exam4FinalQuarter) +
                  parseFloat(el.exam5FinalQuarter) +
                  parseFloat(el.exam6FinalQuarter) +
                  parseFloat(el.exam7FinalQuarter) +
                  parseFloat(el.exam8FinalQuarter) >
                0
              ) {
                el.examAverageQuarter =
                  parseFloat(el.exam1FinalQuarter) * 0.1 +
                  parseFloat(el.exam2FinalQuarter) * 0.1 +
                  parseFloat(el.exam3FinalQuarter) * 0.1 +
                  parseFloat(el.exam4FinalQuarter) * 0.1 +
                  parseFloat(el.exam5FinalQuarter) * 0.2 +
                  parseFloat(el.exam6FinalQuarter) * 0.2 +
                  parseFloat(el.exam7FinalQuarter) * 0.1 +
                  parseFloat(el.exam8FinalQuarter) * 0.1;
              } else {
                el.exam1FinalQuarter = 0;
                el.exam2FinalQuarter = 0;
                el.exam3FinalQuarter = 0;
                el.exam4FinalQuarter = 0;
                el.exam5FinalQuarter = 0;
                el.exam6FinalQuarter = 0;
                el.exam7FinalQuarter = 0;
                el.exam8FinalQuarter = 0;
                el.examAverageQuarter = 0;
              }

              if (
                parseFloat(el.exam1Year) +
                  parseFloat(el.exam2Year) +
                  parseFloat(el.exam3Year) +
                  parseFloat(el.exam4Year) +
                  parseFloat(el.exam5Year) +
                  parseFloat(el.exam6Year) +
                  parseFloat(el.exam7Year) +
                  parseFloat(el.exam8Year) >
                0
              ) {
                el.examAverageYear =
                  parseFloat(el.exam1Year) * 0.1 +
                  parseFloat(el.exam2Year) * 0.1 +
                  parseFloat(el.exam3Year) * 0.1 +
                  parseFloat(el.exam4Year) * 0.1 +
                  parseFloat(el.exam5Year) * 0.2 +
                  parseFloat(el.exam6Year) * 0.2 +
                  parseFloat(el.exam7Year) * 0.1 +
                  parseFloat(el.exam8Year) * 0.1;
              } else {
                el.exam1Year = 0;
                el.exam2Year = 0;
                el.exam3Year = 0;
                el.exam4Year = 0;
                el.exam5Year = 0;
                el.exam6Year = 0;
                el.exam7Year = 0;
                el.exam8Year = 0;
                el.examAverageYear = 0;
              }
            });
            this.selectedLessonExamDataPass = merged;
            // console.log(merged);
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          } else {
            let a1 = [];
            // console.log(this.allStudents);
            // console.log(this.selectedLessonExamDataUliralEtses);

            // this.selectedLessonExamDataYear.filter((el) => {
            //   this.allStudents.forEach((element) => {
            //     if (el.grade_exam_student_id == element.grade_exam_student_id) {
            //       a1.push(element);
            //     }
            //   });
            // });
            this.allStudents.forEach((element) => {
              element["exam1Year"] = 0;
              element["exam2Year"] = 0;
              element["exam3Year"] = 0;
              element["exam4Year"] = 0;
              element["exam5Year"] = 0;
              element["exam6Year"] = 0;
              element["exam7Year"] = 0;
              element["exam8Year"] = 0;
              element["examAverageYear"] = 0;
              element["grade_exam_course_id"] = this.course_id["course_id"];
              //   element["grade_exam_daalgar_number"] = "";
              //   element["grade_exam_id"] = "";
              element["grade_exam_quarter_id"] = this.quarter_id["quarter_id"];
              element["grade_exam_school_class_id"] =
                this.course_id["course_school_class_id"];
              //   element["grade_exam_school_id"] = "";
              //   element["grade_exam_student_id"] = "";
              element["grade_exam_teacher_id"] =
                this.teacher_id["employees_user_id"];
              //   element["grade_exam_type"] = "";
              //   element["student_last_name"] = "";
              //   element["student_name"] = "";

              a1.push(element);
            });

            // console.log(a1);
            this.selectedLessonExamDataPass = a1;
            this.selectedCourseData = true;
            this.selectedLessonExamData = [];
            this.selectedLessonExamDataUliralEtses = [];
            this.selectedLessonExamDataYear = [];
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //Select-ийн дэлгэцэнд харуулах утгуудыг өөрчлөх функцууд
    customLabelTeacherName({ teacher_name, teacher_last_name }) {
      return `${teacher_last_name[0]}${teacher_last_name[1]} . ${teacher_name}`;
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
  },
  created() {
    this.getMySchoolExamStatData();
  },
};
</script>

<style></style>
