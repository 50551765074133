<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Эцэг эхчүүдийн жагсаалт</h3>
          <b-row class="mt-3">
            <b-col sm="9"
              ><multiselect
                v-model="fieldName"
                :options="fieldList"
                @select="setFieldList"
                :multiple="true"
                track-by="value"
                label="label"
                :searchable="true"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Excel файл руу гаргах баганаа сонгоно уу."
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong> {{ option.month }}</strong
                  ><strong> сонгогдлоо</strong></template
                >
              </multiselect>
            </b-col>
            <b-col sm="3">
              <b-button
                size="sm"
                @click="resetFields"
                class="mr-2"
                pill
                variant="outline-primary"
              >
                <b-icon icon="check-all" aria-hidden="true"></b-icon> Бүх талбар
              </b-button>
              <download-excel
                class="btn btn-success btn-sm"
                :data="json_data"
                :fields="json_fields"
                worksheet="Эцэг эх"
                name="Эцэг эхийн мэдээлэл.xls"
              >
                <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                Файл татах
              </download-excel>
            </b-col>
          </b-row>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-md-5">
                  <multiselect
                    v-model="selected"
                    :options="school_classes"
                    :multiple="true"
                    track-by="school_class_id"
                    label="school_class_full_name"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Анги сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                  </multiselect>
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.school_class_id }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-gradient-primary btn-sm"
                    @click.prevent="getSchoolClassParents"
                  >
                    Хайх
                  </button>
                </div>
                <div class="col-lg-10">
                  <span class="badge badge-success" v-show="parents.length != 0"
                    >{{ parents.length }} эцэг эх бүртгэгдсэн</span
                  >
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Сурагч</th>
                <th>Аав</th>
                <th>Утас</th>
                <th>Имэйл</th>
                <th>Ээж</th>
                <th>Утас</th>
                <th>Имэйл</th>
                <th>Бүртгэсэн</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-for="(parent, index) in parents" :key="index">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="parent.profiles_status1 === 'leave'" class="badge bg-danger"
                    >ШИЛЖСЭН</span
                  >
                  {{ parent.school_class_full_name }}.
                  {{ parent.stud_prof_student_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="parent.pro_par_parent_father_ovog != null">
                    {{ parent.pro_par_parent_father_ovog[0] }}.
                  </span>
                  {{ parent.pro_par_parent_father_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ parent.pro_par_parent_father_phone_number }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ parent.pro_par_parent_father_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="parent.pro_par_parent_mother_ovog != null">
                    {{ parent.pro_par_parent_mother_ovog[0] }}.
                  </span>
                  {{ parent.pro_par_parent_mother_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ parent.pro_par_parent_mother_phone_number }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ parent.pro_par_parent_mother_email }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ parent.created_at | dateYearMonthDay }}
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  v-if="userInfo.name != 'finance' && userInfo.name != 'accountant'"
                >
                  <a href="#" class="badge bg-primary"><i class="fa fa-check"></i></a>
                  <!-- @click="refreshSchoolStudents(parent)" -->
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click.prevent="getParentAppreciations(parent)"
                  >
                    <i class="fas fa-hand-holding-heart"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <div v-if="parents.length == 0" class="row">
              <div class="col-lg-12">
                <p class="text-center text-muted h5 mt-5">
                  <b-icon icon="inboxes"></b-icon>
                </p>
                <p class="text-center text-muted font-italic text-sm">
                  Одоогоор анги сонгогдоогүй байна.
                </p>
              </div>
            </div>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">Эцэг эхээс ирсэн талархал</h3>
        </div>
        <div class="card-body">
          <div class="timeline">
            <!-- timeline time label -->
            <!-- <div class="time-label">
              <span class="bg-red">10 Feb. 2014</span>
            </div> -->
            <!-- /.timeline-label -->
            <!-- timeline item -->
            <div>
              <i class="fas fa-envelope bg-blue"></i>
              <div
                class="timeline-item"
                v-for="(appreciation, ind) in parentAppreciations"
                :key="ind"
              >
                <span class="time"
                  ><i class="fas fa-clock"></i>
                  {{ appreciation.created_at | dateYearMonthDay }}</span
                >
                <h3 class="timeline-header">
                  <a href="#">Талархал илгээсэн огноо</a>
                </h3>

                <div class="timeline-body">
                  {{ appreciation.talarkhal }}
                </div>
                <div class="timeline-footer">
                  <b-icon
                    icon="heart-half"
                    v-show="appreciation.readManager == 0"
                    @click.prevent="readManager(appreciation)"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    icon="heart-fill"
                    v-show="appreciation.readManager !== 0"
                    variant="success"
                  ></b-icon>
                  <!-- <a class="btn btn-danger btn-sm">Delete</a> -->
                </div>
              </div>
            </div>
            <!-- END timeline item -->
            <!-- timeline item -->
            <!-- <div>
              <i class="fas fa-user bg-green"></i>
              <div class="timeline-item">
                <span class="time"
                  ><i class="fas fa-clock"></i> 5 mins ago</span
                >
                <h3 class="timeline-header no-border">
                  <a href="#">Sarah Young</a> accepted your friend request
                </h3>
              </div>
            </div> -->
            <!-- END timeline item -->
            <!-- timeline item -->
            <!-- <div>
              <i class="fas fa-comments bg-yellow"></i>
              <div class="timeline-item">
                <span class="time"
                  ><i class="fas fa-clock"></i> 27 mins ago</span
                >
                <h3 class="timeline-header">
                  <a href="#">Jay White</a> commented on your post
                </h3>
                <div class="timeline-body">
                  Take me to your leader! Switzerland is small and neutral! We
                  are more like Germany, ambitious and misunderstood!
                </div>
                <div class="timeline-footer">
                  <a class="btn btn-warning btn-sm">View comment</a>
                </div>
              </div>
            </div> -->
            <!-- END timeline item -->
            <!-- timeline time label -->
            <div class="time-label">
              <!-- <span class="bg-green">3 Jan. 2014</span> -->
            </div>
            <!-- /.timeline-label -->
            <!-- timeline item -->
            <!-- <div>
              <i class="fa fa-camera bg-purple"></i>
              <div class="timeline-item">
                <span class="time"
                  ><i class="fas fa-clock"></i> 2 days ago</span
                >
                <h3 class="timeline-header">
                  <a href="#">Mina Lee</a> uploaded new photos
                </h3>
                <div class="timeline-body">
                  <img src="https://placehold.it/150x100" alt="..." />
                  <img src="https://placehold.it/150x100" alt="..." />
                  <img src="https://placehold.it/150x100" alt="..." />
                  <img src="https://placehold.it/150x100" alt="..." />
                  <img src="https://placehold.it/150x100" alt="..." />
                </div>
              </div>
            </div> -->
            <!-- END timeline item -->
            <!-- timeline item -->
            <!-- <div>
              <i class="fas fa-video bg-maroon"></i>

              <div class="timeline-item">
                <span class="time"
                  ><i class="fas fa-clock"></i> 5 days ago</span
                >

                <h3 class="timeline-header">
                  <a href="#">Mr. Doe</a> shared a video
                </h3>

                <div class="timeline-body">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      class="embed-responsive-item"
                      src="https://www.youtube.com/embed/tMWkeBIohBs"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>
                <div class="timeline-footer">
                  <a href="#" class="btn btn-sm bg-maroon">See comments</a>
                </div>
              </div>
            </div> -->
            <!-- END timeline item -->
            <div>
              <i class="fas fa-clock bg-gray"></i>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      selected: [],
      school_classes: [],
      action: "",
      editMode: false,
      parents: [],
      parentAppreciations: [],
      selectedParent: "",
      form: new Form({
        checked: false,
        profiles_id: "",
      }),
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldList: [],
      fieldName: "",
      firstMode: true,
      isAll: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  methods: {
    setFieldList: function (e) {
      if (this.firstMode == true) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = this.fieldList;
      this.fieldList.map(function (value, key) {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
    getAllLeavedInfo() {
      axios
        .get("/accountant/getAllLeavedStudentsInfo")
        .then((response) => {
          this.school_classes = response.data.mySchoolClassId;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getSchoolClassParents() {
      axios
        .post("/accountant/getSchoolClassParents", {
          selectedClass: this.selected,
        })
        .then((response) => {
          //   console.log(response.data.parents);
          this.parents = response.data.parents;
          this.json_data = response.data.parents;
          this.fieldList = response.data.fields;
          var jsonF = {};
          this.fieldList.map(function (value, key) {
            var obj = {};
            var myLabel = value.label;
            obj = { [myLabel]: value.value };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    // refreshSchoolStudents(schoolEntrant) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
    //   this.editMode = true;
    //   this.form.reset();
    //   this.form.fill(schoolEntrant);
    //   if (schoolEntrant.status1 == "registered") this.form.checked = false;
    //   else if (schoolEntrant.status1 == "confirmed") this.form.checked = true;
    // },
    getParentAppreciations(parent) {
      this.editMode = false;
      this.selectedParent = "";
      axios
        .get("/manager/getParentAppreciations/" + parent.pro_par_id)
        .then((response) => {
          //   console.log(response.data);
          this.parentAppreciations = response.data.parentAppreciations;
          this.editMode = true;
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.selectedParent = parent;
          //   Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    readManager(appreciation) {
      axios
        .post("/manager/readManager", {
          appreciation,
        })
        .then((response) => {
          //   console.log(response.data);
          this.editMode = true;
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSelectedParentApp");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllLeavedInfo();
    // this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getSchoolClassLeaveStudents();
    });
    Fire.$on("loadSelectedParentApp", () => {
      this.getParentAppreciations(this.selectedParent);
    });
  },
};
</script>

<style></style>
