<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="card">
        <div class="card-header p-2 float-center">
          <ul class="nav nav-pills">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#primaryschool" data-toggle="tab"
                >Бага</a
              >
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="#middleschool" data-toggle="tab"
                >Дунд</a
              >
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="#highschool" data-toggle="tab">Ахлах</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link active" href="#school" data-toggle="tab">Хувилбар-1</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <!-- <div class="tab-pane" id="primaryschool">Бага</div> -->
            <!-- <div class="tab-pane" id="middleschool">Дунд</div> -->

            <!-- <div class="tab-pane" id="highschool">Ахлах</div> -->
            <div class="active tab-pane" id="school">
              <div class="row">
                <div class="col-lg-6">
                  <multiselect v-model="school_year_id" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="id" label="id" :custom-label="customYearName" placeholder="Хичээлийн жил сонгоно уу?"
                    :options="allYears" :searchable="true" :allow-empty="false">
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                        - хичээлийн жил
                      </strong></template>
                  </multiselect>
                </div>
                <div class="col-lg-6">
                  <multiselect v-model="school_student_id" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                    track-by="pro_user_id" label="pro_user_id" :custom-label="customStudentName"
                    placeholder="Суралцагч сонгоно уу?" :options="allStudents" :searchable="true" :allow-empty="false">
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.email }}-{{
                      option.pro_student_last_name }}.
                        {{ option.pro_student_name }}
                      </strong></template>
                  </multiselect>
                </div>
              </div>
              <!-- Жилээр харуулах -->
              <div class="row">
                <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                  <div class="row">
                    <div class="
                        col-12 col-sm-6 col-md-6
                        d-flex
                        align-items-stretch
                        flex-column
                      ">
                      <div class="card bg-light d-flex flex-fill mr-1">
                        <div class="card-header text-muted border-bottom-0">
                          Эцгийн мэдээлэл
                        </div>
                        <div class="card-body pt-0">
                          <div class="row">
                            <div class="col-7">
                              <h2 class="lead">
                                <b>{{ form.parent_father_ovog }}
                                  {{ form.parent_father_name }}</b>
                              </h2>
                              <p class="text-muted text-sm">
                                {{ form.parent_father_mergejil }},
                                {{ form.parent_father_ajil_gazar }}
                              </p>
                              <ul class="ml-4 mb-0 fa-ul text-muted">
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-users"></i></span>
                                  Ургийн овог:
                                  {{ form.parent_father_urgiin_ovog }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-building"></i></span>
                                  Хаяг: {{ form.parent_father_address }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-phone"></i></span>
                                  Утас: {{ form.parent_father_phone_number }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-envelope"></i></span>
                                  Цахим шуудан: {{ form.parent_father_email }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fa fa-hashtag"></i></span>
                                  РД: {{ form.parent_father_reg }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fa fa-id-card"></i></span>
                                  Яс үндэс: {{ form.parent_father_yas_und }}
                                </li>
                              </ul>
                            </div>
                            <div class="col-5 text-center">
                              <img v-if="
                                form.parent_father_profile_photo_url != null
                              " :src="form.parent_father_profile_photo_url" alt="user-avatar"
                                class="img-circle img-fluid" />
                              <img v-else src="/images/users/user.png" alt="user-avatar" class="img-circle img-fluid" />
                            </div>
                          </div>
                        </div>
                        <!-- <div class="card-footer">
                          <div class="text-right">
                            <a href="#" class="btn btn-sm bg-teal">
                              <i class="fas fa-comments"></i>
                            </a>
                            <a href="#" class="btn btn-sm btn-primary">
                              <i class="fas fa-user"></i> View Profile
                            </a>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="
                        col-12 col-sm-6 col-md-6
                        d-flex
                        align-items-stretch
                        flex-column
                      ">
                      <div class="card bg-light d-flex flex-fill ml-1">
                        <div class="card-header text-muted border-bottom-0">
                          Эхийн мэдээлэл
                        </div>
                        <div class="card-body pt-0">
                          <div class="row">
                            <div class="col-7">
                              <h2 class="lead">
                                <b>{{ form.parent_mother_ovog }}
                                  {{ form.parent_mother_name }}</b>
                              </h2>
                              <p class="text-muted text-sm">
                                {{ form.parent_mother_mergejil }},
                                {{ form.parent_mother_ajil_gazar }}
                              </p>
                              <ul class="ml-4 mb-0 fa-ul text-muted">
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-users"></i></span>
                                  Ургийн овог:
                                  {{ form.parent_mother_urgiin_ovog }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-building"></i></span>
                                  Хаяг: {{ form.parent_mother_address }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-phone"></i></span>
                                  Утас: {{ form.parent_mother_phone_number }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fas fa-lg fa-envelope"></i></span>
                                  Цахим шуудан: {{ form.parent_mother_email }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fa fa-hashtag"></i></span>
                                  РД: {{ form.parent_mother_reg }}
                                </li>
                                <li class="small">
                                  <span class="fa-li"><i class="fa fa-id-card"></i></span>
                                  Яс үндэс: {{ form.parent_mother_yas_und }}
                                </li>
                              </ul>
                            </div>
                            <div class="col-5 text-center">
                              <img v-if="
                                form.parent_mother_profile_photo_url != null
                              " :src="form.parent_mother_profile_photo_url" alt="user-avatar"
                                class="img-circle img-fluid" />
                              <img v-else src="/images/users/user.png" alt="user-avatar" class="img-circle img-fluid" />
                            </div>
                          </div>
                        </div>
                        <!-- <div class="card-footer">
                        <div class="text-right">
                        <a href="#" class="btn btn-sm bg-teal">
                            <i class="fas fa-comments"></i>
                        </a>
                        <a href="#" class="btn btn-sm btn-primary">
                            <i class="fas fa-user"></i> View Profile
                        </a>
                        </div>
                    </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-success" @click.prevent="getMyCourse()">
                Хичээлтэй холбоотой мэдээлэл дуудах
              </button>
              <div class="col-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h6 class="card-title" style="font-size: 80%">
                      Хичээлүүд(Улирлын дүн баталгаажаагүй үед 0 утгатай байна.
                      0 утгатай байсан ч тухайн улиралд авсан байгаа явцын
                      шалгалтуудын дүнг харж болно. Тиймээс дүн дээр дарна уу.)
                    </h6>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="card-body" style="overflow-x: auto">
                    <div class="d-flex justify-content-center mb-3">
                      <b-spinner variant="success" label="Spinning" v-if="allGradeCheck === false"></b-spinner>
                    </div>
                    <table class="table table-hover table-sm" v-if="allGradeCheck === true">
                      <thead>
                        <tr>
                          <th style="width: 10px">#</th>
                          <th>Хичээл</th>
                          <th>Багш</th>
                          <th v-b-popover.hover.top="' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                            " title="Шалгалт">
                            Улир-1
                          </th>
                          <th v-b-popover.hover.top="' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                            " title="Шалгалт">
                            Улир-2
                          </th>
                          <th v-b-popover.hover.top="' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                            " title="Шалгалт">
                            Улир-3
                          </th>
                          <th>Дундаж(Улирал)</th>
                          <th v-b-popover.hover.top="' Хувийн хэрэгт бичигдэнэ.'" title="Жилийн эцсийн үнэлгээ">
                            Жил
                          </th>
                          <!-- <th>Үйлдэл</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(childCourse, i) in myChildCoursesGrades" :key="i">
                          <td>{{ ++i }}</td>
                          <td>{{ childCourse.subject_name }}</td>
                          <td>
                            <a href="#" @click.prevent="">{{
                              childCourse.emp_teacher_last_name[0].toUpperCase()
                            }}.{{ childCourse.emp_teacher_name }}</a>
                          </td>
                          <td>
                            <a href="" @click.prevent="
                              getQuarterExamAnalyse(childCourse, 1)
                              ">
                              <span v-if="childCourse.grade_exam_1 === null">0 ({{
                                symbolGradeCheck(childCourse.grade_exam_1)
                              }})</span>
                              <span v-if="childCourse.grade_exam_1 !== null">{{
                                toOronTootsokh(childCourse.grade_exam_1)
                              }}
                                ({{
                                  symbolGradeCheck(childCourse.grade_exam_1)
                                }})</span>
                            </a>
                          </td>
                          <td>
                            <a href="#" @click.prevent="
                              getQuarterExamAnalyse(childCourse, 2)
                              ">
                              <span v-if="childCourse.grade_exam_2 === null">0 ({{
                                symbolGradeCheck(childCourse.grade_exam_2)
                              }})</span>
                              <span v-if="childCourse.grade_exam_2 !== null">{{
                                toOronTootsokh(childCourse.grade_exam_2)
                              }}
                                ({{
                                  symbolGradeCheck(childCourse.grade_exam_2)
                                }})</span>
                            </a>
                          </td>
                          <td>
                            <a href="#" @click.prevent="
                              getQuarterExamAnalyse(childCourse, 3)
                              ">
                              <span v-if="childCourse.grade_exam_3 === null">0 ({{
                                symbolGradeCheck(childCourse.grade_exam_3)
                              }})</span>
                              <span v-if="childCourse.grade_exam_3 !== null">{{
                                toOronTootsokh(childCourse.grade_exam_3)
                              }}
                                ({{
                                  symbolGradeCheck(childCourse.grade_exam_3)
                                }})</span>
                            </a>
                          </td>
                          <!-- Улирлын шалгалтын дундаж -->
                          <td>
                            <span>{{
                              averageYearBefore(childCourse).toFixed(1)
                            }}
                              ({{
                                symbolGradeCheck(
                                  averageYearBefore(childCourse).toFixed(1)
                                )
                              }})</span>
                          </td>
                          <!-- Жилийн эцсийн баталгаажсан дүн -->
                          <td>
                            <a href="#" @click.prevent="
                              getYearExamAnalyse(childCourse, 3)
                              ">
                              <span v-if="childCourse.grade_exam_jil3 === null">0 ({{
                                symbolGradeCheck(childCourse.grade_exam_jil3)
                              }})</span>
                              <span v-if="childCourse.grade_exam_jil3 !== null">{{
                                toOronTootsokh(childCourse.grade_exam_jil3)
                              }}
                                ({{
                                  symbolGradeCheck(childCourse.grade_exam_jil3)
                                }})</span>
                            </a>
                          </td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="col-12 col-sm-12">
                    <div class="card card-primary card-tabs">
                      <div class="card-header p-0 pt-1">
                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill"
                              href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home"
                              aria-selected="true">Танилцаагүй</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill"
                              href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile"
                              aria-selected="false">Танилцсан</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body">
                        <div class="tab-content" id="custom-tabs-one-tabContent">
                          <div class="tab-pane fade show active" id="custom-tabs-one-home" role="tabpanel"
                            aria-labelledby="custom-tabs-one-home-tab">
                            <div class="card card-default">
                              <div class="card-header">
                                <h3 class="card-title">
                                  <i class="fas fa-bullhorn"></i>
                                  Таны хүүхэд сургалтын үйл ажиллагаанд оролцох
                                  явцад ажилглагдсан ахиц, амжилт, давуу ба сул
                                  тал, анхаарах зүйлсийн талаар багш нарын өгсөн
                                  мэдээлэл. Хүүхдийг амжилттай суралцахад эцэг
                                  эх, багшийн хамтын ажиллагааг бэхжүүлэхэд
                                  ашиглана.
                                </h3>
                              </div>
                              <div class="card-body">
                                <div class="callout callout-warning" v-for="(
                                    unReadComment, i
                                  ) in myChildCourseCommentunRead" :key="i">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <span style="font-size: 120%">
                                        {{ unReadComment.subject_name }}
                                        {{
                                          unReadComment.teacher_last_name[0]
                                        }}.
                                        {{ unReadComment.teacher_name }} багш
                                      </span>
                                      <span style="font-size: 80%">
                                        {{
                                          unReadComment.created_at
                                          | dateYearMonthDay
                                        }}</span>
                                    </div>
                                    <div class="col-md-6">
                                      <span style="font-size: 80%">
                                        <b-icon v-show="unReadComment.imReadParent == 0
                                          " style="float: right" icon="heart-fill" variant="warning" @click.prevent="
                                            imReadParentConfirm(unReadComment)
                                            "></b-icon>
                                        <!-- <b-icon
                              v-show="unReadComment.imReadParent == 1"
                              style="float: right"
                              icon="heart-fill"
                              variant="success"
                              @click.prevent="imReadParentConfirm(unReadComment)"
                            ></b-icon> -->
                                      </span>
                                    </div>
                                  </div>
                                  <p style="font-size: 80%">
                                    <b>{{ unReadComment.student_last_name }}
                                      {{ unReadComment.student_name }}:
                                    </b>
                                    {{ unReadComment.comments }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel"
                            aria-labelledby="custom-tabs-one-profile-tab">
                            <div class="card card-default">
                              <div class="card-header">
                                <h3 class="card-title">
                                  <i class="fas fa-bullhorn"></i>
                                  Таны хүүхэд сургалтын үйл ажиллагаанд оролцох
                                  явцад ажилглагдсан ахиц, амжилт, давуу ба сул
                                  тал, анхаарах зүйлсийн талаар багш нарын өгсөн
                                  мэдээлэл. Хүүхдийг амжилттай суралцахад эцэг
                                  эх, багшийн хамтын ажиллагааг бэхжүүлэхэд
                                  ашиглана.
                                </h3>
                              </div>
                              <div class="card-body">
                                <div class="callout callout-warning" v-for="(
                                    readComm, i
                                  ) in myChildCourseCommentRead" :key="i">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <span style="font-size: 120%">
                                        {{ readComm.subject_name }}
                                        {{ readComm.teacher_last_name[0] }}.
                                        {{ readComm.teacher_name }} багш
                                      </span>
                                      <span style="font-size: 80%">
                                        {{
                                          readComm.created_at | dateYearMonthDay
                                        }}</span>
                                    </div>
                                    <div class="col-md-6">
                                      <span style="font-size: 80%">
                                        <!-- @click.prevent="imReadParentConfirm(readComm)" -->
                                        <b-icon v-show="readComm.imReadParent == 1" style="float: right"
                                          icon="heart-fill" variant="success"></b-icon>
                                      </span>
                                    </div>
                                  </div>

                                  <p style="font-size: 80%">
                                    <b>{{ readComm.student_last_name }}
                                      {{ readComm.student_name }}:
                                    </b>
                                    {{ readComm.comments }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /.card -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      allYears: [],
      allStudents: [],
      school_year_id: "",
      school_student_id: "",

      form: new Form({
        id: "",
        school_id: "",
        user_id: "",
        user_name: "",
        user_email: "",
        student_profile_id: "",
        schoolYearId: "",
        parent_father_urgiin_ovog: "",
        parent_father_ovog: "",
        parent_father_name: "",
        parent_father_reg: "",
        parent_father_yas_und: "",
        parent_father_mergejil: "",
        parent_father_bol: "",
        parent_father_ajil_gazar: "",
        parent_father_phone_number: "",
        parent_father_email: "",
        parent_father_address: "",
        parent_father_profile_photo_url: "",
        parent_mother_urgiin_ovog: "",
        parent_mother_ovog: "",
        parent_mother_name: "",
        parent_mother_reg: "",
        parent_mother_yas_und: "",
        parent_mother_mergejil: "",
        parent_mother_bol: "",
        parent_mother_ajil_gazar: "",
        parent_mother_phone_number: "",
        parent_mother_email: "",
        parent_mother_address: "",
        parent_mother_profile_photo_url: "",
      }),
      // Бүх хичээл
      allGradeCheck: true,
      myChildCourses: [],
      myChildCoursesGrades: [],

      //Хичээлүүдийн коммент
      myChildCourseCommentunRead: [],
      myChildCourseCommentRead: [],
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    school_year_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      axios
        .get(
          "/curriculumadministrator/getCurrentStudentsOfYear/" + newVal["id"]
        )
        .then((res) => {
          this.allStudents = res.data.allStudents;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    school_student_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      //   this.viewMySchoolChart = false;
      axios
        .get(
          "/curriculumadministrator/getCurrentStudentsOfYearParent/" +
          newVal["pro_id"]
        )
        .then((res) => {
          // console.log(res.data);
          this.form.fill(res.data.parentDataProps);
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  methods: {
    getAllSchoolFilterData() {
      axios
        .get("/curriculumadministrator/indexStudentGetAllFilterData")
        .then((res) => {
          // console.log(res.data);
          //хичээлийн жил
          this.allYears = res.data.allYears;
          // this.allStudents = res.data.allStudents;
        })
        .catch();
    },

    getMyCourse() {
      // console.log(this.school_year_id);
      // console.log(this.school_student_id);
      this.allGradeCheck = false;
      // this.showExam = false;
      // this.myChildCourses = [];
      // this.myChildCoursesGrades = [];
      // this.courseExam = "";
      // this.myChildCourseExams = [];
      // this.selectChildCourseExam = "";
      // this.selectChildCourseExamValues = [];
      axios
        .post("/curriculumadministrator/getCurrentStudentYearCourses", {
          schoolYear: this.school_year_id,
          student: this.school_student_id,
        })
        .then((res) => {
          // this.allStudentCourses = response.data.allStudentCourses;
          this.allGradeCheck = true;
          this.myChildCourses = res.data.myChildCourses;
          this.myChildCoursesGrades = res.data.myChildCoursesGrades;
          this.myChildCourseCommentunRead = res.data.myChildCourseCommentunRead;
          this.myChildCourseCommentRead = res.data.myChildCourseCommentRead;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
    averageYearBefore(childCourse) {
      let average =
        (childCourse.grade_exam_1 +
          childCourse.grade_exam_2 +
          childCourse.grade_exam_3) /
        3;
      return average;
    },
    toOronTootsokh(value) {
      return parseFloat(value).toFixed(1);
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },

    customStudentName({ email }) {
      return `${email}`;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.getAllSchoolFilterData();
    //   this.viewMyChart = true;
    // });
  },
  created() {
    this.getAllSchoolFilterData();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
