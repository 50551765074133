require("./bootstrap");

window.Vue = require("vue");
window.Fire = new Vue();

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import store from "./store/store.js";
import router from "./router/router.js";
import VeeValidate from "vee-validate";
import { ValidationProvider } from "vee-validate";

import VueTableDynamic from "vue-table-dynamic";
Vue.use(VueTableDynamic);
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: "$veeFields",
    errorBagName: "$veeErrors"
});

import { mapState, mapGetters, mapActions } from "vuex";

// import CKEditor from "@ckeditor/ckeditor5-vue2";
// Vue.use(CKEditor);

// //Шинэ editor суулгаж тестлэв
// import Vue2Editor from "vue2-editor";

// Vue.use(Vue2Editor);
// import Quill from 'quill'
import VueQuillEditor, { Quill } from "vue-quill-editor";
// import Quill from 'quill';
import ImageResize from "quill-image-resize-vue";
import { ImageDrop } from "quill-image-drop-module";
import imageCompressor from "quill-image-compress";
import * as Emoji from "quill-emoji";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import "quill-emoji/dist/quill-emoji.css"; // emoji

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageCompress", imageCompressor);
Quill.register("modules/emoji", Emoji);
Vue.use(VueQuillEditor /* { default global options } */);

// Register it globally
Vue.component("pagination", require("laravel-vue-pagination"));
Vue.component("ValidationProvider", ValidationProvider);

// import 'viewerjs/dist/viewer.js'
import VueViewer from "v-viewer";
Vue.use(VueViewer);
// import

// Vue.component('test-hello', require('./components/Test.vue').default);
// Vue.component('schools', require('./components/Schools/index.vue').default);
// Vue.component('main-hello', require('./components/Partials/Main.vue').default);
// Vue.component('role-index', require('./components/Roles/index.vue').default);
// //component-ыг component.js дээр нэгтгэхээр салгалаа. олон файл нэг дор төвлөрч байгаа учраас.
// //Add these two components. Чат систем
// Vue.component(
//     "chat-messages",
//     require("./components/All/Chats/ChatMessage.vue").default
// );
// Vue.component(
//     "chat-form",
//     require("./components/All/Chats/ChatForm.vue").default
// );
require("./component");

// import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// ES6 Modules or TypeScript
import Swal from "sweetalert2";
window.Swal = Swal;

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//vform
import { Form, HasError, AlertError } from "vform";
window.Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

// import plugin
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultProgressBar: false,
    defaultProgressBarValue: 0,
    // defaultType: "error",
    defaultPosition: "toast-bottom-right"
    // defaultCloseOnHover: false,
    // defaultStyle: { "background-color": "red" },
    // defaultClassNames: ["animated", "zoomInUp"]
});

// momentjs Суулгаж ажиллууллаа.
import moment from "moment";

Vue.filter("dateYearMonthDayTime", function(created) {
    // moment().format();
    return moment(created).format("LLLL");
});
Vue.filter("dateYearMonthDay", function(created) {
    // moment().format();
    return moment(created).format("MM/DD/YYYY");
});
Vue.filter("dateWithTime", function(created) {
    // moment().format();
    let year = moment(created).format("YYYY");
    let month = moment(created).format("MM");
    let day = moment(created).format("DD");
    let date = moment(created).format("MM/DD/YYYY");
    let now = new Date();
    let nowYear = moment(now).format("YYYY");
    let nowMonth = moment(now).format("MM");
    let nowDay = moment(now).format("DD");
    let nowDate = moment(now).format("MM/DD/YYYY");
    if (year == nowYear)
        if (nowDate == date) {
            let hour = moment(created).format("HH");
            let nowHour = moment(now).format("HH");
            if (nowHour == hour) {
                let minute = moment(created).format("mm");
                let nowMinute = moment(now).format("mm");
                let difMinute = nowMinute - minute;
                if (difMinute > 0) return difMinute + " минутын өмнө";
                else {
                    let sec = moment(created).format("ss");
                    let nowSec = moment(now).format("ss");
                    let difSec = nowSec - sec;
                    return difSec + " секундын өмнө";
                }
            } else return moment(created).format("Өнөөдөр HH:mm:ss");
        } else if (nowMonth == month)
            if (nowDay - 7 <= day) {
                let difDay = nowDay - day;
                if (difDay == 1)
                    return moment(created).format("Өчигдөр HH:mm:ss");
                else
                    return (
                        difDay + moment(created).format(" өдрийн өмнө HH:mm:ss")
                    );
            } else return moment(created).format("MM сарын DD-нд HH:mm:ss");
        else return moment(created).format("MM сарын DD-нд HH:mm:ss");
    else return moment(created).format("YYYY оны MM сарын DD-нд HH:mm:ss");
});
Vue.filter("dateYear", function(created) {
    // moment().format();
    return moment(created).format("YYYY");
});
Vue.filter("dateMonth", function(created) {
    // moment().format();
    return moment(created).format("MM");
});
Vue.filter("dateFullYear", function(created) {
    // moment().format();
    return moment(created).format("ll");
});
Vue.filter("capitalizeAndEkhniiVseg", function(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase();
});

Vue.filter("uppercase", function(v) {
    return v?.toUpperCase() || "";
});
// Тоог төгрөгрүү шилжүүлж харуулах

Vue.filter("converTugrik", function(value) {
    return new Intl.NumberFormat("MN", {
        style: "currency",
        currency: "MNG"
    }).format(value);
});
moment.locale("mn");
import VueApexCharts from "vue-apexcharts";
import Vue from "vue";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);
const app = new Vue({
    el: "#app",
    //Store chat messages for display in this array.
    data: {
        messages: []
    },
    store,
    router,
    // 2nd method
    computed: mapState({
        ...mapGetters(["getCurrentUserInfos"]),
        ...mapState(["user"]),
        ...mapActions(["UPDATE_USER_INFO", "UPDATE_SOME_INFO"])
    }),

    components: { VueTableDynamic },
    //Upon initialisation, run fetchMessages().
    created() {
        // this.fetchMessages();
        this.UPDATE_USER_INFO;
        this.UPDATE_SOME_INFO;
        // window.Echo.private('chat')
        //     .listen('MessageSent', (e) => {
        //         this.messages.push({
        //             message: e.message.message,
        //             user: e.user
        //         });
        //     });
    },
    methods: {
        // fetchMessages() {
        //     //GET request to the messages route in our Laravel server to fetch all the messages
        //     axios.get('/all_worker_functions/messages').then(response => {
        //         //Save the response in the messages array to display on the chat view
        //         this.messages = response.data;
        //     });
        // },
        // //Receives the message that was emitted from the ChatForm Vue component
        // addMessage(message) {
        //     //Pushes it to the messages array
        //     this.messages.push(message);
        //     //POST request to the messages route with the message data in order for our Laravel server to broadcast it.
        //     axios.post('/all_worker_functions/messages', message).then(response => {
        //         console.log(response.data);
        //     });
        // }
    }
});
