<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#notifys" data-toggle="tab"
                >Ирсэн мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sentNotifys" data-toggle="tab"
                >Явуулсан мэдээлэл</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <!-- Багшийн өөрийн дадлууд -->
            <div class="tab-pane" id="sentNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Төрөл</th>
                        <th>Мэдээлэл</th>
                        <th>Танилцсан эсэх</th>
                        <th>Огноо</th>
                        <th>Үйлдэл</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(notifi, index) in sentNotifys"
                      :key="notifi.id"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.type }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showSentNotify(notifi)"
                          >
                            <b-badge variant="light">
                              <span
                                v-html="notifi.notifcation.substring(0, 100)"
                              >
                              </span> </b-badge
                          ></a>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="showNotifyViews(notifi)"
                          >
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{ notifi.notifies_count }}/{{
                                notifi.notify_views_count
                              }}
                            </b-badge>
                            |
                            <b-badge
                              :variant="
                                getColor(
                                  notifi.notify_views_count,
                                  notifi.notifies_count
                                )
                              "
                              pill
                              class="text-center"
                            >
                              {{
                                (
                                  (notifi.notify_views_count /
                                    notifi.notifies_count) *
                                  100
                                ).toFixed(0)
                              }}%</b-badge
                            >
                          </a>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ notifi.created_at | dateYearMonthDay }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editNotifi(notifi)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            v-show="notifi.id != null"
                            href="#"
                            class="badge bg-danger"
                            @click="removeNotifi(notifi)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="tab-pane active" id="notifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <a
                    v-show="notifi.id != null"
                    href="#"
                    class="dropdown-item"
                    v-for="notifi in notifications"
                    :key="notifi.id"
                    @click="showNotify(notifi)"
                  >
                    <div class="media mt-3">
                      <img
                        v-if="
                          notifi.my_manager &&
                          notifi.my_manager.profile_photo_url &&
                          notifi.my_manager.profile_photo_url != null
                        "
                        :src="notifi.my_manager.profile_photo_url"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <div class="media-body">
                        <h3
                          class="dropdown-item-title"
                          v-if="notifi.my_manager"
                        >
                          {{ notifi.my_manager.teacher_name }}
                        </h3>
                        <h3 class="dropdown-item-title" v-else>
                          Нэр бүртгэгдээгүй.
                        </h3>
                        <p class="text-sm">
                          <span
                            class="badge"
                            v-html="notifi.notifcation.substring(0, 100)"
                          >
                          </span>
                        </p>
                        <p class="text-sm text-muted">
                          <i class="far fa-clock mr-1"></i>
                          {{ notifi.created_at | dateYearMonthDay }}
                        </p>
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- /.card-body -->
        <b-modal
          size="xl"
          id="modal-views"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Мэдэгдэл"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="card card-success" v-if="viewShowViewers">
              <div class="card-header">
                <h3 class="card-title">Танилцсан</h3>

                <div class="card-tools">
                  <span
                    class="badge badge-warning"
                    v-if="showNotifyViewers.data"
                    >{{ showNotifyViewers.data.length }}</span
                  >
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0" v-if="showNotifyViewers">
                <ul class="users-list clearfix">
                  <li
                    v-for="viewed in showNotifyViewers.data"
                    :key="viewed.notification_user_id"
                  >
                    <span v-if="roleName == 'employee' && viewed.my_viewers">
                      <img
                        v-if="
                          viewed.my_viewers.profile_photo_url &&
                          viewed.my_viewers.profile_photo_url != null
                        "
                        :src="viewed.my_viewers.profile_photo_url"
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a class="users-list-name" href="#"
                        >{{ viewed.my_viewers.teacher_last_name[0] }}.{{
                          viewed.my_viewers.teacher_name
                        }}</a
                      >
                    </span>
                    <span
                      v-else-if="
                        roleName == 'student' && viewed.my_show_students
                      "
                    >
                      <img
                        v-if="
                          viewed.my_show_students.student_profile_photo_url &&
                          viewed.my_show_students.student_profile_photo_url !=
                            null
                        "
                        :src="viewed.my_show_students.student_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a class="users-list-name" href="#"
                        >{{ viewed.my_show_students.student_last_name[0] }}.{{
                          viewed.my_show_students.student_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="viewed.my_show_parents">
                      <img
                        v-if="
                          viewed.my_show_parents
                            .parent_father_profile_photo_url &&
                          viewed.my_show_parents
                            .parent_father_profile_photo_url != null
                        "
                        :src="
                          viewed.my_show_parents.parent_father_profile_photo_url
                        "
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a
                        class="users-list-name"
                        v-if="viewed.my_show_parents.parent_father_ovog"
                        href="#"
                        >{{ viewed.my_show_parents.parent_father_ovog[0] }}.{{
                          viewed.my_show_parents.parent_father_name
                        }}</a
                      >
                      <b-badge variant="light">
                        <span
                          v-for="child in viewed.my_show_parents
                            .get_my_primary_child"
                          :key="child.id"
                          class="mr-1"
                          >{{ child.full_name }}</span
                        ></b-badge
                      >
                    </span>
                  </li>
                </ul>
                <pagination
                  align="center"
                  :data="showNotifyViewers"
                  @pagination-change-page="list"
                ></pagination>
              </div>
            </div>
            <div class="card card-danger" v-if="viewShowViewers">
              <div class="card-header">
                <h3 class="card-title">Танилцаагүй</h3>

                <div class="card-tools">
                  <span
                    class="badge badge-warning"
                    v-if="showNotifyNotViewers.data"
                    >{{ showNotifyNotViewers.data.length }}</span
                  >
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0" v-if="showNotifyNotViewers">
                <ul class="users-list clearfix">
                  <li
                    v-for="viewed in showNotifyNotViewers.data"
                    :key="viewed.notification_user_id"
                  >
                    <span v-if="roleName == 'employee' && viewed.my_viewers">
                      <img
                        v-if="viewed.my_viewers.profile_photo_url != null"
                        :src="viewed.my_viewers.profile_photo_url"
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a class="users-list-name" href="#"
                        >{{ viewed.my_viewers.teacher_last_name[0] }}.{{
                          viewed.my_viewers.teacher_name
                        }}</a
                      >
                    </span>
                    <span
                      v-else-if="
                        roleName == 'student' && viewed.my_show_students
                      "
                    >
                      <img
                        v-if="
                          viewed.my_show_students.student_profile_photo_url &&
                          viewed.my_show_students.student_profile_photo_url !=
                            null
                        "
                        :src="viewed.my_show_students.student_profile_photo_url"
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a class="users-list-name" href="#"
                        >{{ viewed.my_show_students.student_last_name[0] }}.{{
                          viewed.my_show_students.student_name
                        }}</a
                      >
                    </span>
                    <span v-else-if="viewed.my_show_parents">
                      <img
                        v-if="
                          viewed.my_show_parents
                            .parent_father_profile_photo_url &&
                          viewed.my_show_parents
                            .parent_father_profile_photo_url != null
                        "
                        :src="
                          viewed.my_show_parents.parent_father_profile_photo_url
                        "
                        alt="user-avatar"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                      />
                      <a
                        class="users-list-name"
                        v-if="viewed.my_show_parents.parent_father_ovog"
                        href="#"
                        >{{ viewed.my_show_parents.parent_father_ovog[0] }}.{{
                          viewed.my_show_parents.parent_father_name
                        }}</a
                      >
                      <b-badge variant="light">
                        <span
                          v-for="child in viewed.my_show_parents
                            .get_my_primary_child"
                          :key="child.id"
                          class="mr-1"
                          >{{ child.full_name }}</span
                        ></b-badge
                      >
                    </span>
                  </li>
                </ul>
                <pagination
                  align="center"
                  :data="showNotifyNotViewers"
                  @pagination-change-page="listNot"
                ></pagination>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editEvalMode">Мэдээлэл оруулах</h3>
          <h3 class="card-title" v-show="editEvalMode">Мэдээлэл засах</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group" v-show="!editEvalMode">
              <label>Бүлэг сонгох</label>
              <multiselect
                v-model="form.type"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                label="text"
                placeholder="Төрөл сонгох"
                :options="notifiOptions"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.text }}
                </template>
              </multiselect>
            </div>
            <div class="form-group" v-show="!editEvalMode">
              <multiselect
                v-model="form.school_class_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                label="full_name"
                placeholder="Анги сонгох"
                :options="classOptions"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
                :close-on-select="false"
                :show-labels="false"
                v-show="showClassParent"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.full_name }}
                </template>
              </multiselect>
            </div>
            <div class="form-group" v-show="!editEvalMode">
              <multiselect
                v-model="form.school_class_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="id"
                label="full_name"
                placeholder="Анги сонгох"
                :options="classOptions"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
                :close-on-select="false"
                :show-labels="false"
                v-show="showClassStud"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.full_name }}
                </template>
              </multiselect>
            </div>
            <div class="form-group" v-show="!editEvalMode">
              <multiselect
                v-model="form.parent_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="user_id"
                :custom-label="customUserName"
                placeholder="Эцэг, эх сонгох"
                :options="parentOptions"
                :searchable="true"
                :allow-empty="true"
                v-show="showParent"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.full_name }}
                  {{ option.dad_full_name }}
                  {{ option.student_name }}
                </template>
              </multiselect>
            </div>
            <div class="form-group" v-show="!editEvalMode">
              <multiselect
                v-model="form.stud_id"
                deselect-label="Хасах"
                select-label="Сонгох"
                track-by="user_id"
                placeholder="Суралцагч сонгох"
                :options="studOptions"
                label="email"
                :searchable="true"
                :allow-empty="true"
                v-show="showStud"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.email }}
                </template>
              </multiselect>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-12">
                <label for="evaluation">Мэдээлэл</label>
                <validation-provider name="form.notification" rules="alpha_num">
                  <template>
                    <quill-editor
                      v-model="form.notification"
                      :options="editorOption"
                    />
                  </template>
                </validation-provider>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-success"
            v-show="!editEvalMode"
            @click.prevent="createNotifi"
          >
            Мэдээлэл оруулах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editEvalMode"
            @click.prevent="updateNotifi"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-dark"
            v-show="editEvalMode"
            @click.prevent="cancelNotifi"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-notify"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Мэдэгдэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span
              v-if="showNotifyData.my_manager"
              class="direct-chat-name float-left"
            >
              <img
                v-if="showNotifyData.my_manager.profile_photo_url != null"
                :src="showNotifyData.my_manager.profile_photo_url"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="img-size-50 mr-3 img-circle img-bordered-sm"
              />
              {{ showNotifyData.my_manager.teacher_last_name }}
              {{ showNotifyData.my_manager.teacher_name }}
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>
              {{ showNotifyData.created_at | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0" style="overflow-x: auto">
            <span v-html="showNotifyData.notifcation"> </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import pagination from "laravel-vue-pagination";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
      viewShowViewers: false,
      editEvalMode: false,
      notifications: [],
      sentNotifys: [],
      notifiType: [],
      showClassStud: false,
      showClassParent: false,
      showMode: false,
      showViewers: false,
      showParent: false,
      showStud: false,
      showNotifyData: "",
      showNotifyViewers: "",
      showNotifyNotViewers: "",
      roleName: "",
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true,
        },
      },
      form: new Form({
        id: "",
        type: "",
        notification: "",
        year_id: "",
        school_id: "",
        type: "",
        department_id: "",
        school_class_id: [],
        reg_emp_id: "",
        parent_id: "",
        stud_id: "",
        manager_id: "",
        teacher_id: "",
        notifcation: "",
        notification_status: "",
      }),
      notifiType: [],
      classOptions: [],
      parentOptions: [],
      studOptions: [],
      notifiOptions: [
        { id: 6, text: "Анги (эцэг, эх)" },
        { id: 7, text: "Анги (суралцагчид)" },
        { id: 9, text: "Эцэг эхэд" },
        { id: 10, text: "Суралцагчид" },
        { id: 11, text: "Манай ангийн эцэг, эх" },
        { id: 12, text: "Манай ангийн суралцагчид" },
      ],
    };
  },
  components: {
    Multiselect,
    pagination,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  watch: {
    "form.type": function (newVal) {
      if (newVal.id == 6) {
        this.showClassStud = true;
        axios.get("/teacher/getTeachAllClass/").then((response) => {
          this.classOptions = response.data.schoolClasses;
        });
      } else this.showClassStud = false;
      if (newVal.id == 7) {
        this.showClassParent = true;
        axios.get("/teacher/getTeachAllClass/").then((response) => {
          this.classOptions = response.data.schoolClasses;
        });
      } else this.showClassParent = false;
      if (newVal.id == 9) {
        this.showParent = true;
        axios.get("/teacher/getAllUsersParent/").then((response) => {
          this.parentOptions = response.data.parents;
        });
      } else this.showParent = false;
      if (newVal.id == 10) {
        this.showStud = true;
        axios.get("/teacher/getAllStudents/").then((response) => {
          this.studOptions = response.data.students;
        });
      } else this.showStud = false;
    },
  },
  methods: {
    async list(page = 1) {
      await axios
        .get(
          `/all_worker_functions/showNotifyViews/${this.showViewId}?page=${page}`
        )
        .then(({ data }) => {
          this.showNotifyViewers = data.notifiViews;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    async listNot(page = 1) {
      await axios
        .get(
          `/all_worker_functions/showNotNotifyViews/${this.showNotViewId}?page=${page}`
        )
        .then(({ data }) => {
          this.showNotifyNotViewers = data.notifiNotViews;
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    getColor(count, total) {
      let huvi = (count / total) * 100;
      if (huvi < 10) return "danger";
      else if (huvi < 30) return "warning";
      else if (huvi < 60) return "primer";
      else if (60 < huvi) return "success";
    },

    showNotifi() {
      this.showAddTypeMode = false;
      this.form.reset();
      this.getTeachAllNotifications();
    },
    addNotifi() {
      this.showAddTypeMode = true;
      this.editTypeMode = false;
    },
    createNotifi() {
      this.form.type = this.form.type.text;
      this.form.department_id = this.form.department_id.id;
      this.form.school_class_id = this.form.school_class_id;
      this.form.stud_id = this.form.stud_id.user_id;
      this.form.parent_id = this.form.parent_id.user_id;
      this.form.teacher_id = this.form.teacher_id.user_id;
      this.form
        .post("/teacher/createNotifi/")
        .then((response) => {
          this.$toastr.s("Мэдэгдэл амжилттай илгээлээ.", "Амжилттай");
          this.getSentTeach();
          this.showAddTypeMode = false;
          this.form.reset();
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editNotifi(notifi) {
      this.showAddTypeMode = false;
      this.editEvalMode = true;
      this.form.fill(notifi);
      this.form.type = notifi.type;
      this.form.notification = notifi.notifcation;
      console.log(notifi);
    },
    updateNotifi() {
      this.form
        .put("/teacher/updateNotifiClassTeach/" + this.form.id)
        .then((response) => {
          this.getSentTeach();
          this.form.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelNotifi() {
      this.editEvalMode = false;
      this.form.reset();
    },
    removeNotifi(p) {
      Swal.fire({
        title: "Мэдэгдэл устгах",
        text: p.type + " Мэдэгдэл устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Мэдэгдэл хасаж байна...";
          axios
            .get("/teacher/removeNotifi/" + p.id)
            .then((response) => {
              this.$toastr.s("Мэдэгдэл амжилттай устгалаа!", "Шинэчилсэн");
              this.getSentTeach();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    getTeachAllNotifications() {
      axios
        .get("/teacher/getTeachAllNotifications/")
        .then((response) => {
          this.notifications = response.data.notifications;
          if (response.data.isClassTeacher == false)
            this.notifiOptions.splice(4, 6);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    getSentTeach() {
      axios
        .get("/teacher/getSentTeach/")
        .then((response) => {
          this.sentNotifys = response.data.sentNotifys;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showNotify(notify) {
      this.action = "Mэдээллийг харах...";
      axios
        .get("/all_worker_functions/showNotify/" + notify.id)
        .then((response) => {
          this.showNotifyData = notify;
          this.showMode = !this.showMode;
          this.getTeachAllNotifications();
          this.$bvModal.show("modal-notify");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    showSentNotify(notify) {
      this.showNotifyData = notify;
      this.showMode = !this.showMode;
      this.$bvModal.show("modal-notify");
    },

    showNotifyViews(notify) {
      this.showViewId = notify.id;
      this.showNotViewId = notify.id;
      this.action = "Mэдээллийг харах...";
      axios
        .get("/all_worker_functions/showNotifyViews/" + notify.id)
        .then((response) => {
          this.showNotifyViewers = response.data.notifiViews;
          this.roleName = response.data.roleName;
          this.viewShowViewers = true;
          this.$bvModal.show("modal-views");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/all_worker_functions/showNotNotifyViews/" + notify.id)
        .then((response) => {
          this.showNotifyNotViewers = response.data.notifiNotViews;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    customUserName({ full_name, dad_full_name, student_name }) {
      return `${full_name}, ${dad_full_name}, ${student_name}`;
    },
  },
  created() {
    this.getTeachAllNotifications();
    this.getSentTeach();
    Fire.$on("getTeachAllNotifications", () => {
      this.getTeachAllNotifications();
    });
  },
};
</script>

  <style scoped>
.users-list > li {
  width: 16%;
}
</style>
