<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#newNotifys" data-toggle="tab"
                >Шинэ мэдээлэл</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#oldNotifys" data-toggle="tab"
                >Танилцсан мэдээлэл</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <!-- /.card-header -->
            <div class="tab-pane active" id="newNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <a
                    v-show="notifi.id != null"
                    href="#"
                    class="dropdown-item"
                    v-for="notifi in newNotifys"
                    :key="notifi.id"
                    @click="showNotify(notifi)"
                  >
                    <div class="media mt-3" v-if="notifi.my_manager">
                      <img
                        v-if="notifi.my_manager.profile_photo_url != null"
                        :src="notifi.my_manager.profile_photo_url"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle"
                      />
                      <div class="media-body">
                        <h3 class="dropdown-item-title">
                          {{ notifi.my_manager.teacher_name }}
                        </h3>
                        <p class="text-sm">
                          <span
                            v-html="
                              notifi.notifcation.substring(0, 250) + '...'
                            "
                          >
                          </span>
                        </p>
                        <p class="text-sm text-muted">
                          <i class="far fa-clock mr-1"></i>
                          {{ notifi.created_at | dateYearMonthDay }}
                        </p>
                      </div>
                    </div>
                    <div v-else-if="notifi.my_user" class="media">
                      <img
                        v-if="
                          notifi.my_user.parent_father_profile_photo_url != null
                        "
                        :src="notifi.my_user.parent_father_profile_photo_url"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <div class="media-body">
                        <h3 class="dropdown-item-title">
                          {{ notifi.my_user.parent_father_ovog }}
                          {{ notifi.my_user.parent_father_name }}
                        </h3>
                        <p
                          class="text-sm"
                          v-html="notifi.notifcation.substring(0, 50)"
                        ></p>
                        <p class="text-sm text-muted">
                          <i class="far fa-clock mr-1"></i>
                          {{ notifi.created_at | dateYearMonthDay }}
                        </p>
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </a>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="oldNotifys">
              <div class="card">
                <div class="card-body table-responsive">
                  <a
                    v-show="notifi.id != null"
                    href="#"
                    class="dropdown-item"
                    v-for="notifi in oldNotifys"
                    :key="notifi.id"
                    @click="showNotify(notifi)"
                  >
                    <div class="media mt-3" v-if="notifi.my_manager">
                      <img
                        v-if="notifi.my_manager.profile_photo_url != null"
                        :src="notifi.my_manager.profile_photo_url"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle"
                      />
                      <div class="media-body">
                        <h3 class="dropdown-item-title">
                          {{ notifi.my_manager.teacher_name }}
                        </h3>
                        <p class="text-sm">
                          <span
                            v-html="
                              notifi.notifcation.substring(0, 250) + '...'
                            "
                          >
                          </span>
                        </p>
                        <p class="text-sm text-muted">
                          <i class="far fa-clock mr-1"></i>
                          {{ notifi.created_at | dateYearMonthDay }}
                        </p>
                      </div>
                    </div>
                    <div v-else-if="notifi.my_user" class="media">
                      <img
                        v-if="
                          notifi.my_user.parent_father_profile_photo_url != null
                        "
                        :src="notifi.my_user.parent_father_profile_photo_url"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <img
                        v-else
                        src="/images/users/user.png"
                        alt="user-avatar"
                        class="img-size-50 mr-3 img-circle img-bordered-sm"
                      />
                      <div class="media-body">
                        <h3 class="dropdown-item-title">
                          {{ notifi.my_user.parent_father_ovog }}
                          {{ notifi.my_user.parent_father_name }}
                        </h3>
                        <p
                          class="text-sm"
                          v-html="notifi.notifcation.substring(0, 50)"
                        ></p>
                        <p class="text-sm text-muted">
                          <i class="far fa-clock mr-1"></i>
                          {{ notifi.created_at | dateYearMonthDay }}
                        </p>
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
        <b-modal
          v-show="showMode"
          size="xl"
          id="modal-notify"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Мэдэгдэл"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span
                  v-if="showNotifyData.my_manager"
                  class="direct-chat-name float-left"
                >
                  <img
                    v-if="showNotifyData.my_manager.profile_photo_url != null"
                    :src="showNotifyData.my_manager.profile_photo_url"
                    alt="user-avatar"
                    class="img-size-50 mr-3 img-circle img-bordered-sm"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-size-50 mr-3 img-circle img-bordered-sm"
                  />
                  {{ showNotifyData.my_manager.teacher_last_name }}
                  {{ showNotifyData.my_manager.teacher_name }}
                </span>
                <span
                  v-else-if="showNotifyData.my_user"
                  class="direct-chat-name float-left"
                >
                  <img
                    v-if="
                      showNotifyData.my_user.parent_father_profile_photo_url !=
                      null
                    "
                    :src="
                      showNotifyData.my_user.parent_father_profile_photo_url
                    "
                    alt="user-avatar"
                    class="img-size-50 mr-3 img-circle img-bordered-sm"
                  />
                  <img
                    v-else
                    src="/images/users/user.png"
                    alt="user-avatar"
                    class="img-size-50 mr-3 img-circle img-bordered-sm"
                  />
                  {{ showNotifyData.my_user.parent_father_ovog }}
                  {{ showNotifyData.my_user.parent_father_name }}
                </span>
                <span class="direct-chat-timestamp float-right"
                  ><b>Огноо: </b>
                  {{ showNotifyData.created_at | dateYearMonthDay }}</span
                >
              </div>
              <div
                class="direct-chat-text primary ml-0"
                style="overflow-x: auto"
              >
                <span v-html="showNotifyData.notifcation"> </span>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      editEvalMode: false,
      oldNotifys: [],
      newNotifys: [],
      notifiType: [],
      showMode: false,
      showNotifyData: "",
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getParentAllInfo() {
      axios
        .get("/parent/getParentAllInfo/")
        .then((response) => {
          this.oldNotifys = response.data.oldNotifys;
          this.newNotifys = response.data.newNotifys;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showNotify(notify) {
      this.action = "Mэдээллийг харах...";
      axios
        .get("/all_worker_functions/showNotify/" + notify.id)
        .then((response) => {
          this.showNotifyData = notify;
          this.showMode = !this.showMode;
          this.getParentAllInfo();
          this.$bvModal.show("modal-notify");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  created() {
    this.getParentAllInfo();
    Fire.$on("getParentAllInfo", () => {
      this.getParentAllInfo();
    });
  },
};
</script>

<style scoped></style>
