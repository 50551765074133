var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mt-2"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_vm._v(" "),_c('table',{staticClass:"table table-bordered text-center"},[_c('tbody',[_vm._m(1),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Хичээл")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.myClass))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.studCount))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.myStudents))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.lessonAccess)+"%")]),_vm._v(" "),_c('td')]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Хөгжүүлэх ажил")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.allProject[0]))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.allProject[1]))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.allProject[2]))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.allProject[3].toFixed(2))+"%")]),_vm._v(" "),_c('td')]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Соёл хүмүүжлийн ажил")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.projectCxa[0]))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.projectCxa[1]))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.projectCxa[2]))]),_vm._v(" "),(_vm.projectCxa[3])?_c('td',[_vm._v("\n                      "+_vm._s(_vm.projectCxa[3].toFixed(2))+"%\n                    ")]):_c('td',[_vm._v("0")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Дундаж үзүүлэлт")]),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s((
                          (_vm.myClass + _vm.allProject[0] + _vm.projectCxa[0]) /
                          3
                        ).toFixed(2))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s((
                          (_vm.studCount + _vm.allProject[1] + _vm.projectCxa[1]) /
                          3
                        ).toFixed(2))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s((
                          (_vm.myStudents + _vm.allProject[2] + _vm.projectCxa[2]) /
                          3
                        ).toFixed(2))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.avg)+"%")]),_vm._v(" "),_c('td')])])]),_vm._v(" "),(!_vm.showRadar)?_c('apexchart',{attrs:{"type":"radar","height":"350","options":_vm.optionsStat,"series":_vm.seriesStat}}):_vm._e()],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('b',[_vm._v("Сургалтын үйл ажиллагааны цогц байдал")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{attrs:{"role":"row"}},[_c('th',[_vm._v("Сургалтын үйл ажиллагааны хэлбэр")]),_vm._v(" "),_c('th',[_vm._v("Нийт бүлгийн тоо")]),_vm._v(" "),_c('th',[_vm._v("Хамрагдвал зохих нийт хүүхдийн тоо")]),_vm._v(" "),_c('th',[_vm._v("Хамрагдсан нийт хүүхдийн тоо")]),_vm._v(" "),_c('th',[_vm._v("Боловсролын үйлчилгээний хүртээмж")]),_vm._v(" "),_c('th',[_vm._v("Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс")])])}]

export { render, staticRenderFns }