<template>
  <div class="pt-3">
    <!-- /.col -->
    <div class="card">
      <div class="card-body table-responsive bodyOfYear">
        <b-row>
          <b-col sm="3">
            <b-col
              label="Хүүхэд"
              label-for="child_id"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-select
                v-model="child_id"
                :options="childOptions"
                text-field="student_name"
                value-field="user_id"
              ></b-select>
            </b-col>
          </b-col>
          <b-col sm="3">
            <multiselect
              v-model="selectedQuarter"
              deselect-label="Сонголт цуцлах"
              select-label="Улирал сонгох"
              track-by="id"
              :custom-label="customQuarterName"
              label="name"
              placeholder="Хичээлийн улирал сонгоно уу?"
              :options="quarters"
              :searchable="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}-р улирал </strong></template
              >
            </multiselect>
          </b-col>
          <b-col sm="2">
            <b-button variant="primary" size="sm" @click.prevent="getLessonChildAtt()"
              >Шүүж харах</b-button
            >
          </b-col>
        </b-row>
        <b-overlay :show="loadClassData" rounded="sm">
          <div class="row">
            <div class="col-12 col-sm-12" v-if="viewSelectClassChart">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-number text-muted mb-0">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonName"
                        :series="seriesClassLesson"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <div class="row mt-3">
          <div class="col-12" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <b-col sm="3">
                    <multiselect
                      v-model="selectedMonth"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Хичээл сонгоно уу?"
                      track-by="id"
                      :custom-label="customMonthName"
                      label="month_name"
                      :options="monthList"
                      :searchable="false"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong>{{ option.month_name }} -р сар</strong></template
                      >
                    </multiselect></b-col
                  >
                  <span
                    class="info-box-number text-muted mb-0"
                    v-if="viewSelectlessonChart"
                  >
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsMonthLessonName"
                      :series="seriesMonthLesson"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card-header -->
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudentAttStat from "./everyStudentAttStat.vue";
export default {
  components: {
    Multiselect,
    everyStudentAttStat,
  },
  data() {
    return {
      show: false,
      loadClassData: false,
      viewSelectlessonChart: false,
      viewSelectClassChart: false,
      viewChart: false,
      viewQuarterChart: false,
      viewLessonChartP: false,
      viewClassChartP: false,
      selectedQuarter: "",
      selectedMonth: "",
      selectedClassStudP: "",
      child_id: "",
      childOptions: [],
      years: [],
      quarters: [],
      lessonNames: [],
      monthNames: [],
      quarter: [],
      //Select lesson
      seriesClassLesson: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsLessonName: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesMonthLesson: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsMonthLessonName: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  watch: {
    selectedMonth: function (newVal, oldVal) {
      this.viewSelectlessonChart = false;
      this.viewQuarterChart = true;
      this.school_quarter_id = "";
      if (this.selectedQuarter) {
        axios
          .post("/parent/getMonthChildAtt", {
            selectedQuarter: this.selectedQuarter.id,
            selectedChild: this.child_id,
            selectedMonth: this.selectedMonth.month_name,
          })
          .then((response) => {
            this.seriesMonthLesson[0].data = response.data.monthIrsenList;
            this.seriesMonthLesson[1].data = response.data.monthTasalsanList;
            this.seriesMonthLesson[2].data = response.data.monthUvchteiList;
            this.seriesMonthLesson[3].data = response.data.monthChuluuteiList;
            this.seriesMonthLesson[4].data = response.data.monthHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsMonthLessonName.xaxis.categories = response.data.lessonNames;
            this.lessonNames = response.data.lessonNames;
            this.lessonList = response.data.lessonList;
            this.viewSelectlessonChart = true;
            this.viewSelectClassChart = true;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
  },
  methods: {
    getLessonChildAtt() {
      this.school_quarter_id = "";
      this.loadClassData = true;
      if (this.selectedQuarter && this.child_id) {
        this.viewQuarterChart = false;
        axios
          .post("/parent/getLessonChildAtt", {
            selectedQuarter: this.selectedQuarter.id,
            selectedChild: this.child_id,
          })
          .then((response) => {
            this.seriesClassLesson[0].data = response.data.lessonIrsenList;
            this.seriesClassLesson[1].data = response.data.lessonTasalsanList;
            this.seriesClassLesson[2].data = response.data.lessonUvchteiList;
            this.seriesClassLesson[3].data = response.data.lessonChuluuteiList;
            this.seriesClassLesson[4].data = response.data.lessonHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsLessonName.xaxis.categories = response.data.lessonNames;
            this.lessonNames = response.data.lessonNames;
            this.lessonList = response.data.lessonList;
            this.monthList = response.data.monthList;
            this.monthNames = response.data.monthNames;
            this.viewQuarterChart = true;
            this.loadClassData = false;
            this.viewSelectClassChart = true;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    getChilds() {
      axios
        .get("/project_parent/getChilList")
        .then((response) => {
          this.childOptions = response.data.childs;
          if (this.childOptions.length > 0) this.child_id = this.childOptions[0].user_id;
          this.quarters = response.data.quarterList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove(attendance_id, tooluur) {
      this.passPropsMyLessonDate.splice(tooluur, 1);
    },
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    customMonthName({ month_name }) {
      return `${month_name}-р сар`;
    },
  },

  created() {
    this.getChilds();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bodyOfYear {
  min-height: 200px;
}
</style>
