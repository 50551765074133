<template>
  <div>
    <multiselect
      v-model="quarterIds"
      deselect-label="Сонголт цуцлах"
      select-label="Улирал сонгох"
      track-by="id"
      :custom-label="customQuarterName"
      label="name"
      placeholder="Хичээлийн улирал сонгоно уу?"
      :options="quarters"
      :searchable="false"
      :allow-empty="true"
      :multiple="true"
      @input="getOpenDayData()"
      class="mb-2"
    >
      <template slot="singleLabel" slot-scope="{ option }"
        ><strong>{{ option.name }}-р улирал </strong></template
      >
    </multiselect>
    <div class="card card-light">
      <div class="card-header">
        <h3 class="card-title">
          Хүүхдийн хөгжлийн ерөнхий үнэлгээ /<span v-if="quarterIds.length > 0">
            <b-badge
              v-for="quarter in quarterIds"
              :key="quarter.id"
              variant="success"
              class="mr-1"
              >{{ quarter.name }}</b-badge
            >-р улирал
          </span>
          <span v-else>Жилээр</span>/
        </h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize">
            <i class="fas fa-expand"></i>
          </button>
        </div>
      </div>
      <div class="card-body" style="overflow-x: auto">
        <b-overlay :show="isShowData" rounded="sm">
          <apexchart
            v-if="!isShowData"
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <b-jumbotron
            v-if="userInfo.user.roles && userInfo.user.roles[0].name == 'rentpa'"
            class="p-3"
          >
            <template #lead>
              Та дараах товчнууд дээр дарж дэлгэрэнгүй мэдээлэл авна уу.
            </template>
            <b-tabs content-class="mt-3">
              <b-tab title="Бие бялдар" active
                ><physical-student></physical-student
              ></b-tab>
              <b-tab title="Соёл хүмүүжлийн ажил"
                ><parent-culture></parent-culture
              ></b-tab>
              <b-tab title="Хүмүүжлийн дүн"
                ><parent-my-child-behave-course></parent-my-child-behave-course
              ></b-tab>
              <b-tab title="Хөгжүүлэх хичээл"><parent-dev></parent-dev></b-tab>
              <b-tab title="Хичээлийн дүн"
                ><parent-my-child-course></parent-my-child-course
              ></b-tab>
            </b-tabs>
          </b-jumbotron>
        </b-overlay>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import physicalStudent from "../../Physical/physicalStudent.vue";
import parentCulture from "../../Project/Parent/Culture.vue";
import parentMyChildBehaveCourse from "../../Parent/Courses/MyChildBehaveCourses.vue";
import parentDev from "../../Project/Parent/Dev.vue";
import parentMyChildCourse from "../../Parent/Courses/MyChildCourses.vue";
export default {
  props: ["selectedChildId", "quarters"],
  components: {
    Multiselect,
    physicalStudent: physicalStudent,
    parentCulture: parentCulture,
    parentMyChildBehaveCourse: parentMyChildBehaveCourse,
    parentDev: parentDev,
    parentMyChildCourse: parentMyChildCourse,
  },
  computed: {
    userInfo: function () {
      return this.$store.state;
    },
  },
  data() {
    return {
      quarterIds: [],
      selectedQuarterIds: [],
      isShowData: true,
      series: [
        {
          name: "Суралцагчийн дундаж",
          type: "column",
          data: [],
        },
        {
          name: "Сургуулийн дундаж",
          type: "line",
          data: [],
        },
        {
          name: "Стандарт",
          type: "line",
          data: [60, 60, 60, 60, 60],
        },
      ],
      chartOptions: {
        chart: {
          height: 550,
          type: "line",
        },
        colors: ["#007bff", "#8b75d7", "#DC3545"],
        plotOptions: {
          bar: {
            columnWidth: "35%",
            // distributed: true,
          },
        },
        stroke: {
          width: [0, 4],
        },
        labels: [
          "Бие бялдар",
          "СХА",
          "Хүмүүжлийн дүн",
          "Хөгжүүлэх хичээл",
          "Хичээлийн дүн",
        ],
        xaxis: {
          type: "string",
        },
        yaxis: {
          max: 100,
          min: 0,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    };
  },
  methods: {
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    getOpenDayData() {
      this.isShowData = true;
      this.selectedQuarterIds = [];
      if (this.quarterIds)
        this.quarterIds.map((q) => {
          this.selectedQuarterIds.push(q.quarter_id);
        });
      axios
        .post("/parent/getOpenDayData/" + this.selectedChildId, {
          quarter_ids: this.selectedQuarterIds,
        })
        .then((response) => {
          this.isShowData = false;
          this.series[0].data = response.data.studentAvg;
          this.series[1].data = response.data.schoolAvg;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getOpenDayData();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
