<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Эрсдэлийн удирдлага(Эрсдэлийн түвшинг тогтоох матриц)
          </h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="2">
                  <multiselect
                    v-model="formRiskMatrice.rlp_id"
                    :options="riskProbabilities"
                    :multiple="false"
                    track-by="id"
                    label="id"
                    :custom-label="customProbabiltiyName"
                    :hideSelected="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Тохиолдох магадлал сонгох"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.rlp_name }}({{ option.rlp_value }})</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <multiselect
                    v-model="formRiskMatrice.rlc_id"
                    :options="riskConsequences"
                    :multiple="false"
                    track-by="id"
                    :custom-label="customConsequenceName"
                    :hideSelected="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Үр дагавар сонгох"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{ option.rlc_name }}({{ option.rlc_value }})</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <b-form-input
                    id="input-2"
                    v-model="formRiskMatrice.rm_description"
                    placeholder="Эрсдэлийн үр дагаврын нэр..."
                    required
                  ></b-form-input>
                </b-col>
                <b-col sm="2">
                  Эрсдэлийн түвшин:
                  {{ calculateRiskLevels() }}
                  {{ formRiskMatrice.rm_result }}
                </b-col>
                <b-col sm="3">
                  <b-iconstack
                    font-scale="2"
                    @click.prevent="riskLevelRiskLevelMatriceCreate()"
                    v-if="!showEdit"
                  >
                    <b-icon stacked icon="square" variant="info"></b-icon>
                    <b-icon stacked icon="check" variant="success"></b-icon>
                  </b-iconstack>
                  <b-iconstack
                    font-scale="2"
                    @click.prevent="riskLevelMtriceUpdate()"
                    v-if="showEdit"
                  >
                    <b-icon stacked icon="square" variant="info"></b-icon>
                    <b-icon
                      stacked
                      scale="0.5"
                      icon="tools"
                      variant="warning"
                    ></b-icon>
                  </b-iconstack>
                  <b-iconstack
                    font-scale="2"
                    @click.prevent="riskLevelConsequenceClear()"
                  >
                    <b-icon stacked icon="square" variant="info"></b-icon>
                    <b-icon
                      stacked
                      icon="x-circle"
                      scale="0.6"
                      variant="danger"
                    ></b-icon>
                  </b-iconstack>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Тохиолдох магадлал</th>
                <th>Үр дагавар</th>
                <th>Тайлбар</th>
                <th>Эрсдэлийн түвшин</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(matrice, index) in riskMatrices" :key="matrice.id">
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap"
                    >{{ matrice.my_probability.rlp_name }}({{
                      matrice.my_probability.rlp_value
                    }})</b-badge
                  >
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap"
                    >{{ matrice.my_consequence.rlc_name }}({{
                      matrice.my_consequence.rlc_value
                    }})</b-badge
                  >
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    matrice.rm_description
                  }}</b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    matrice.rm_result
                  }}</b-badge>
                </td>

                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editRiskLevelMatrice(matrice)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <!-- <a
                                        href="#"
                                        class="badge bg-success"
                                        @click="
                                            createRiskDefinitionList(
                                                matrice
                                            )
                                        "
                                        ><i class="fa fa-eye"></i
                                    ></a> -->
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteRiskLevelMatrice(matrice)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ matrice.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ matrice.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-risk-definition-list"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дэд ангилал"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <label>Нэр</label>
                <b-form-input
                  id="input-2"
                  v-model="formRiskDefinitionList.rdl_name"
                  placeholder="Дэд ангилалын нэр..."
                  required
                ></b-form-input>
                <has-error
                  :form="formRiskDefinitionList"
                  field="rcl_name"
                ></has-error>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          type="submit"
          class="btn btn-success"
          v-show="!showEditRCL"
          @click.prevent="storeRiskDefinitionList()"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          v-show="showEditRCL"
          @click.prevent="updateRiskDefinitionList()"
        >
          Засах
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from "vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      departments: [
        { value: null, department_name: "Мэргэжлийн багш сонгоно уу?" },
      ],
      teachers: [],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //Training Эндээс эхэллээ...

      allTrainings: [],

      //subTrainings
      showSubRiskCategoryMode: false,
      editSubTrainMode: false,
      useSubTrainofParent: "",
      allSubTrainings: [],

      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      modalTeachers: [],

      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),

      // RiskMatrices
      riskMatrices: [],
      riskProbabilities: [],
      riskConsequences: [],
      formRiskMatrice: new Form({
        id: "",
        rlp_id: "",
        rlc_id: "",
        rm_description: "",
        rm_result: 0,
      }),
      showEdit: false,

      //   RiskCategoryList эхэллээ
      formRiskDefinitionList: new Form({
        id: "",
        rd_id: "",
        rdl_name: "",
      }),
      showEditRCL: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {},
  methods: {
    //Эрсдэлийн матриц эхэлж байна...
    getAllRiskLevelMatrices() {
      axios
        .get("/principal/getAllRiskLevelMatrices")
        .then((res) => {
          //   console.log(res);
          this.riskMatrices = res.data.riskMatrices;
          this.riskProbabilities = res.data.riskProbabilities;
          this.riskConsequences = res.data.riskConsequences;

          //   this.teachers = res.data.teachers;
          //   this.allTrainings = res.data.getAllTrainings;
          //   this.filterForm.department_id = 0;
          //   this.departments = res.data.departments;
          //   this.departments.push({
          //     id: 0,
          //     department_name: "Нийт мэргэжлийн баг",
          //   });
          //   this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    calculateRiskLevels() {
      console.log(this.formRiskMatrice.rlp_id.rlc_value);
      console.log(this.formRiskMatrice.rlc_id.rlc_value);
      let aToo = parseFloat(this.formRiskMatrice.rlp_id.rlc_value);
      let bToo = parseFloat(this.formRiskMatrice.rlc_id.rlc_value);
      let c = aToo * bToo;
      this.formRiskMatrice.rm_result = c;
      return c;
    },
    riskLevelRiskLevelMatriceCreate() {
      //   console.log(this.formRiskMatrice);
      this.formRiskMatrice
        .post("/principal/riskLevelRiskLevelMatriceCreate")
        .then((res) => {
          // console.log(res);
          Fire.$emit("getRiskLevelMatices");
          this.formRiskMatrice.reset();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    editRiskLevelMatrice(matrice) {
      //   console.log(matrice);
      this.formRiskMatrice.fill(matrice);
      this.formRiskMatrice.rlp_id = matrice.my_probability;
      this.formRiskMatrice.rlc_id = matrice.my_consequence;
      this.showEdit = true;
    },
    riskLevelMtriceUpdate() {
      this.formRiskMatrice
        .put("/principal/riskLevelMtriceUpdate/" + this.formRiskMatrice.id)
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getRiskLevelMatices");
          this.formRiskMatrice.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskLevelMatrice(riskLevelMatrice) {
      //   console.log(riskLevelMatrice);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн матриц устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/principal/riskLevelMatriceDestroy/${riskLevelMatrice.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRiskLevelMatices");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Эрсдэлийн матриц устгасангүй :)", "error");
        }
      });
    },
    riskLevelConsequenceClear() {
      this.formRiskMatrice.reset();
      this.showEdit = false;
    },
    //Эрсдэлийн дэд ангилалууд

    createRiskDefinitionList(riskDefinition) {
      // console.log(riskDefinition);
      //   this.getCourseStudents(train.id);
      //   this.showModalTeachers = true;
      //   this.useInfosofSubTrains = train;
      this.formRiskDefinitionList.rd_id = riskDefinition.id;
      this.$bvModal.show("modal-risk-definition-list");
    },

    storeRiskDefinitionList() {
      this.formRiskDefinitionList
        .post("/principal/storeRiskDefinitionList")
        .then((res) => {
          this.$toastr.s("Дэд ангилал амжилттай нэмлээ!", "Шинэчилсэн");
          this.resetFormRiskList();
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    resetFormRiskList() {
      this.showEditRCL = false;
      Fire.$emit("getRiskLevelMatices");
      this.formRiskDefinitionList.reset();
      this.$bvModal.hide("modal-risk-definition-list");
    },

    cancel() {
      this.showEditRCL = false;
      this.formRiskDefinitionList.reset();
      this.$bvModal.hide("modal-risk-definition-list");
    },

    editRiskDefinitionList(rdl) {
      //   console.log(rdl);
      this.formRiskDefinitionList.fill(rdl);
      this.showEditRCL = true;
      this.$bvModal.show("modal-risk-definition-list");
    },
    updateRiskDefinitionList() {
      //   console.log("update");

      this.formRiskDefinitionList
        .put(
          "/principal/updateRiskDefinitionList/" +
            this.formRiskDefinitionList.id
        )
        .then((res) => {
          //   console.log(res);
          this.resetFormRiskList();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteRiskDefinitionList(rdl) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Эрсдэлийн тодорхойлолтын дэд ангилал устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/principal/riskDefinitionListDestroy/${rdl.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getRiskLevelMatices");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Дэд ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Дэд ангилал устгахыг цуцалсан :)", "error");
        }
      });
    },

    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customProbabiltiyName({ rlp_name, rlp_value }) {
      return `${rlp_name}(${rlp_value})`;
    },
    customConsequenceName({ rlc_name, rlc_value }) {
      return `${rlc_name}(${rlc_value})`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllRiskLevelMatrices();
    Fire.$on("getRiskLevelMatices", () => {
      this.getAllRiskLevelMatrices();
    });
    Fire.$on("getSubTrainings", () => {
      this.getSubTrainAgain();
    });
  },
};
</script>

<style></style>
