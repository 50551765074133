<template>
  <div class="card card-default color-palette-box mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр OGSM шүүж харах"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div>
        <div class="col-lg-3" v-if="userRole == 'cipalprin'">
          <multiselect
            v-model="selectedDepartments"
            deselect-label="Болих"
            selected-label="Сонгогдсон"
            track-by="id"
            label="department_name"
            placeholder="Хэлтэс сонгох"
            :options="departments"
            :close-on-select="false"
            :searchable="true"
            :allow-empty="true"
            :multiple="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.department_name }}
            </template>
          </multiselect>
        </div>
        <div class="col-lg-3" v-if="userRole == 'cipalprin'">
          <multiselect
            v-model="selectedManager"
            deselect-label="Болих"
            selected-label="Сонгогдсон"
            track-by="id"
            label="name"
            placeholder="Менежер сонгох"
            :options="managers"
            :searchable="true"
            :allow-empty="true"
          >
          </multiselect>
        </div>
        <div class="col-lg-3" v-if="userRole == 'cipalprin'">
          <b-button
            variant="outline-warning"
            @click="
              selectedManager = null;
              selectedDepartments = [];
              getAllOgsmObjectivesYear();
            "
          >
            <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
          </b-button>
          <b-button variant="primary" @click="getAllOgsmObjectivesYear()"
            >Шүүлт хийх</b-button
          >
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table
              class="table table-striped table-hover table-bordered table-sm border-warning"
            >
              <!-- Бүртгэх форм эхэлж байна. -->
              <thead>
                <tr>
                  <th scope="col">
                    <b-badge
                      variant="success"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ЗОРИЛГО(Objective)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="warning"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ЗОРИЛТ(Goal)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="primary"
                      class="py-2 text-center"
                      style="width: 20%"
                      >СТРАТЕГИ(Strategy)</b-badge
                    >
                    <i class="fa fa-arrow-right mx-3" aria-hidden="true"></i>
                    <b-badge
                      variant="secondary"
                      class="py-2 text-center"
                      style="width: 20%"
                      >ХЭМЖҮҮР(Measure)</b-badge
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Шууд objective талаас нь авсан -->
                <b-overlay :show="dataLoad" rounded="sm" class="mt-4">
                  <tr>
                    <div
                      v-for="(ogsmStrategyM, index) in allOgsm"
                      :key="ogsmStrategyM.id"
                    >
                      <td style="width: 600px">
                        <div class="info-box bg-gradient-light">
                          <div class="info-box-content">
                            <span class="info-box-text">
                              <b-badge variant="success" pill
                                ><strong>{{ index + 1 }}</strong></b-badge
                              >
                              <b-badge variant="success" pill
                                >Зорилго
                                {{ index + 1 }}
                              </b-badge>
                              <span
                                class="badge badge-success float-right"
                                v-if="ogsmStrategyM.goalTotal"
                                >{{
                                  (
                                    ogsmStrategyM.goalTotal /
                                    ogsmStrategyM.goalCount
                                  ).toFixed(2)
                                }}</span
                              >
                            </span>
                            <span class="progress-description">
                              <b-badge
                                class="text-wrap text-justify"
                                variant="light"
                              >
                                {{ ogsmStrategyM.strategies }}
                              </b-badge>
                            </span>
                            <span class="info-box-text"
                              ><b-badge variant="secondary">
                                {{ objectiveStatus1(ogsmStrategyM) }}
                              </b-badge></span
                            >
                          </div>
                          <!-- /.info-box-content -->
                        </div>
                      </td>
                      <td style="width: 90vw">
                        <div v-if="ogsmStrategyM.manager_goals.length > 0">
                          <div
                            v-for="(
                              ogsmGoal, indexG
                            ) in ogsmStrategyM.manager_goals"
                            :key="ogsmGoal.id"
                          >
                            <div
                              class="card card-warning collapsed-card"
                              id="ogsmCard"
                            >
                              <div class="card-header">
                                <h3 class="card-title">
                                  <b-badge variant="light" pill
                                    ><strong>{{ indexG + 1 }}</strong></b-badge
                                  >
                                  Зорилт
                                  {{ indexG + 1 }}
                                </h3>
                                <div class="card-tools">
                                  <span
                                    class="badge badge-success"
                                    v-if="ogsmGoal.strategyTotal"
                                    >{{
                                      (
                                        ogsmGoal.strategyTotal /
                                        ogsmGoal.measureCount
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span
                                    class="badge badge-light"
                                    v-if="ogsmGoal.strategyEmployeeTotal"
                                    >{{
                                      (
                                        ogsmGoal.strategyEmployeeTotal /
                                        ogsmGoal.measureCount
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span class="badge badge-light" v-else
                                    >0</span
                                  >
                                  <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-card-widget="collapse"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                              <div class="card-body p-0">
                                <div class="info-box bg-gradient-light">
                                  <div class="info-box-content">
                                    <span class="info-box-text">
                                      <b-badge variant="success" pill
                                        >Зорилго
                                        {{ index + 1 }}
                                      </b-badge>
                                      <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                      <b-badge variant="warning" pill>
                                        Зорилт
                                        {{ indexG + 1 }}
                                      </b-badge>
                                      <b-badge
                                        @click="visibleForm3(ogsmGoal)"
                                        class="float-right"
                                        variant="primary"
                                      >
                                        <i class="fas fa-plus"></i> Стратеги
                                        нэмэх
                                      </b-badge>
                                    </span>
                                    <span class="progress-description">
                                      <b-badge
                                        @click="visibleForm3(ogsmGoal)"
                                        style="width: 100%"
                                        class="text-wrap text-justify"
                                        variant="light"
                                      >
                                        {{ ogsmGoal.measures }}
                                      </b-badge>
                                    </span>
                                    <div
                                      v-if="
                                        ogsmGoal.manager_strategies.length > 0
                                      "
                                    >
                                      <div
                                        class="card card-primary collapsed-card"
                                        id="strategyCard"
                                        v-for="(
                                          strategy, indexS
                                        ) in ogsmGoal.manager_strategies"
                                        :key="strategy.id"
                                      >
                                        <div class="card-header">
                                          <h3 class="card-title">
                                            <b-badge variant="light" pill
                                              ><strong>{{
                                                indexS + 1
                                              }}</strong></b-badge
                                            >
                                            <b-badge variant="primary" pill>
                                              {{ strategy.strategy_name }}
                                              <b-badge variant="light" pill
                                                ><b-icon
                                                  icon="pencil-square"
                                                  variant="primary"
                                                  @click.prevent="
                                                    strategyEdit(strategy)
                                                  "
                                                ></b-icon>
                                                <b-icon
                                                  icon="trash-fill"
                                                  variant="danger"
                                                  @click.prevent="
                                                    strategyDelete(strategy)
                                                  "
                                                ></b-icon></b-badge
                                            ></b-badge>
                                            <b-avatar
                                              :src="
                                                strategy.employee
                                                  .profile_photo_url
                                              "
                                              variant="light"
                                              size="35"
                                              v-b-tooltip.hover.html="
                                                '<dd><img src=' +
                                                strategy.employee
                                                  .profile_photo_url +
                                                ' style=' +
                                                'width:150px!important' +
                                                '></dd><dd>' +
                                                strategy.employee
                                                  .teacher_last_name +
                                                ' <strong>' +
                                                strategy.employee.teacher_name.toUpperCase() +
                                                '</strong></dd></dl>'
                                              "
                                            ></b-avatar>
                                          </h3>
                                          <div class="card-tools">
                                            <span
                                              class="badge badge-success"
                                              v-if="strategy.measureTotal"
                                              >{{
                                                (
                                                  strategy.measureTotal /
                                                  strategy.manager_measures
                                                    .length
                                                ).toFixed(2)
                                              }}</span
                                            >
                                            <span
                                              class="badge badge-light"
                                              v-if="
                                                strategy.measureEmployeeTotal
                                              "
                                              >{{
                                                (
                                                  strategy.measureEmployeeTotal /
                                                  strategy.manager_measures
                                                    .length
                                                ).toFixed(2)
                                              }}</span
                                            >
                                            <span
                                              class="badge badge-light"
                                              v-else
                                              >0</span
                                            >
                                            <button
                                              type="button"
                                              class="btn btn-tool"
                                              data-card-widget="collapse"
                                            >
                                              <i class="fas fa-plus"></i>
                                            </button>
                                          </div>
                                        </div>
                                        <div class="card-body p-0">
                                          <div
                                            class="info-box bg-gradient-light"
                                          >
                                            <div class="info-box-content">
                                              <span
                                                class="progress-description"
                                              >
                                                <span
                                                  @click="
                                                    visibleForm4(strategy)
                                                  "
                                                  variant="light"
                                                  class="text-wrap col-10 text-justify"
                                                >
                                                  {{ strategy.strategy }}
                                                </span>
                                                <b-badge
                                                  @click="
                                                    visibleForm4(strategy)
                                                  "
                                                  class="float-right"
                                                  variant="secondary"
                                                >
                                                  <i class="fas fa-plus"></i>
                                                  Хэмжүүр нэмэх
                                                </b-badge>
                                              </span>
                                              <div
                                                v-if="
                                                  strategy.manager_measures
                                                    .length > 0
                                                "
                                              >
                                                <div
                                                  class="card card-secondary collapsed-card"
                                                  id="measureCard"
                                                  v-for="(
                                                    measure, indexM
                                                  ) in strategy.manager_measures"
                                                  :key="measure.id"
                                                >
                                                  <div class="card-header">
                                                    <h3 class="card-title">
                                                      <b-badge
                                                        variant="light"
                                                        pill
                                                        ><strong>{{
                                                          indexM + 1
                                                        }}</strong></b-badge
                                                      >
                                                      <b-badge
                                                        variant="light"
                                                        pill
                                                      >
                                                        {{
                                                          measure.measure_name
                                                        }}
                                                        <b-badge
                                                          variant="light"
                                                          pill
                                                          ><b-icon
                                                            icon="pencil-square"
                                                            variant="primary"
                                                            @click.prevent="
                                                              measureEdit(
                                                                measure
                                                              )
                                                            "
                                                          ></b-icon>
                                                          <b-icon
                                                            icon="trash-fill"
                                                            variant="danger"
                                                            @click.prevent="
                                                              measureDelete(
                                                                measure,
                                                                strategy
                                                              )
                                                            "
                                                          ></b-icon></b-badge
                                                      ></b-badge>
                                                    </h3>

                                                    <div class="card-tools">
                                                      <span
                                                        class="badge badge-success"
                                                      >
                                                        {{
                                                          measure.measure_number
                                                        }}
                                                      </span>
                                                      <button
                                                        type="button"
                                                        class="btn btn-tool"
                                                        data-card-widget="collapse"
                                                      >
                                                        <i
                                                          class="fas fa-plus"
                                                        ></i>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div class="card-body p-0">
                                                    <div
                                                      class="info-box bg-gradient-light"
                                                    >
                                                      <div
                                                        class="info-box-content"
                                                      >
                                                        <span
                                                          class="progress-description"
                                                        >
                                                          <span
                                                            style="width: 100%"
                                                            class="text-wrap text-justify"
                                                          >
                                                            {{
                                                              measure.measure
                                                            }}
                                                          </span>
                                                        </span>
                                                        <p
                                                          v-if="
                                                            measure.employee_strategies_avg_strategy_number
                                                          "
                                                        >
                                                          <strong>
                                                            {{
                                                              parseFloat(
                                                                measure.employee_strategies_avg_strategy_number
                                                              ).toFixed(2)
                                                            }}
                                                          </strong>
                                                          <small>
                                                            /Багш нарын оруулсан
                                                            хэмжүүрийн
                                                            дундажаар/
                                                          </small>
                                                        </p>
                                                        <p v-else>
                                                          <small>
                                                            /Багш нар хэмжүүрт
                                                            үнэлгээ оруулаагүй
                                                            байна!/
                                                          </small>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                v-else
                                                class="callout callout-dark text-muted"
                                              >
                                                <h5>Мэдээлэл байхгүй!</h5>
                                                <p>
                                                  Хэмжүүр одоогоор бүртгэгдээгүй
                                                  байна.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else
                                      class="callout callout-dark text-muted"
                                    >
                                      <h5>Мэдээлэл байхгүй!</h5>
                                      <p>
                                        Стратеги одоогоор бүртгэгдээгүй байна.
                                      </p>
                                    </div>
                                  </div>
                                  <!-- /.info-box-content -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="callout callout-dark text-muted">
                          <h5>Мэдээлэл байхгүй!</h5>
                          <p>Зорилт одоогоор бүртгэгдээгүй байна.</p>
                        </div>
                      </td>
                    </div>
                  </tr>
                </b-overlay>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Нэмэх хэсэг -->
    <b-modal
      size="xs"
      id="modal-add"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- /.card-header -->
              <!-- form start -->
              <form>
                <div class="card-body">
                  <div v-show="form3">
                    <div class="form-group" v-if="strategySaveOrEdit">
                      <label>Зорилт</label>
                      <b-form-select
                        v-model="vForm3.ogsm_measure_id"
                        :options="goalOptions"
                        text-field="indexName"
                        value-field="id"
                        placeholder="Зорилт"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('ogsm_measure_id'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <label>Нэр</label>
                      <input
                        type="text"
                        v-model="vForm3.strategy_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('strategy_name'),
                          },
                        ]"
                        placeholder="Стратеги 1 гэх мэтээр дугаарлах"
                      />
                    </div>
                    <div class="form-group">
                      <label>Стратеги</label>
                      <textarea
                        rows="4"
                        cols="50"
                        v-model="vForm3.strategy"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm3.errors.has('strategy'),
                          },
                        ]"
                        placeholder="Стратеги(Strategy) оруулах"
                      ></textarea>
                    </div>
                  </div>
                  <div v-show="form4">
                    <div class="form-group" v-if="measureSaveOrEdit">
                      <label>Стратеги</label>
                      <b-form-select
                        v-model="vForm4.ogsm_manager_strategy_id"
                        :options="strategyOptions"
                        text-field="strategy_name"
                        value-field="id"
                        placeholder="Стратеги"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has(
                              'ogsm_manager_strategy_id'
                            ),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group">
                      <label>Нэр</label>
                      <input
                        type="text"
                        v-model="vForm4.measure_name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('measure_name'),
                          },
                        ]"
                        placeholder="Хэмжүүр 1 гэх мэтээр дугаарлах"
                      />
                    </div>
                    <div class="form-group">
                      <label>Хэмжүүр</label>
                      <textarea
                        rows="4"
                        cols="50"
                        v-model="vForm4.measure"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('measure'),
                          },
                        ]"
                        placeholder="Хэмжүүр(Measure) оруулах"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label>Утга</label>
                      <input
                        type="number"
                        v-model="vForm4.measure_number"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': vForm4.errors.has('measure_number'),
                          },
                        ]"
                        placeholder="Хэмжүүр(Measure) оруулах"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div v-show="form3">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="strategyStore()"
                      v-show="!strategySaveOrEdit"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="strategyUpdate()"
                      v-show="strategySaveOrEdit"
                    >
                      Хадгалах
                    </button>
                  </div>
                  <div v-show="form4">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="measureStore()"
                      v-show="!measureSaveOrEdit"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="measureUpdate()"
                      v-show="measureSaveOrEdit"
                    >
                      Хадгалах
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      formModalTitle: "Зорилт нэмэх",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allOgsm: [],
      allYears: [],
      year_id: "",
      editorOption: {
        // some quill options
      },
      statusTeams: [
        { name: "Удирдлага", value: 0 },
        { name: "Сургалт", value: 1 },
        { name: "Үйл ажиллагааны алба", value: 2 },
        { name: "Цэцэрлэг", value: 3 },
        { name: "Спорт клуб", value: 4 },
        { name: "Сургалтын төв", value: 5 },
      ],

      strategySaveOrEdit: false,
      measureSaveOrEdit: false,

      form3: false,
      form4: false,
      strategyOptions: [],
      goalOptions: [],

      vForm3: new Form({
        id: "",
        year_id: "",
        ogsm_measure_id: "",
        status: { name: "Удирдлага", value: 0 },
        strategy_name: "",
        strategy: "",
      }),
      vForm4: new Form({
        id: "",
        year_id: "",
        ogsm_manager_strategy_id: "",
        status: { name: "Удирдлага", value: 0 },
        measure_name: "",
        measure: "",
        measure_number: "",
      }),
      dataLoad: true,
      selectedDepartments: [],
      depIds: [],
      departments: [],
      selectedManager: null,
      managers: [],
      userRole: "",
    };
  },
  components: { Multiselect },
  watch: {
    year_id: function (newVal, oldVal) {
      this.getAllOgsmObjectivesYear();
    },
  },
  methods: {
    objectiveStatus1(value) {
      let status1Name = this.statusTeams.filter(
        (el) => el.value === value.status
      );
      return status1Name[0].name;
    },
    getAllOgsmObjectivesYear() {
      this.dataLoad = true;
      this.depIds = [];
      this.selectedDepartments.forEach((dep) => {
        this.depIds.push(dep.id);
      });
      axios
        .post("/ogsm/getAllManagerOgsmYear", {
          year_id: this.year_id,
          manager_id: this.selectedManager?.id,
          depIds: this.depIds,
        })
        .then((res) => {
          this.allOgsm = [];
          this.allOgsm = res.data.allOgsm;
          this.dataLoad = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllOgsmObjectives() {
      axios
        .get("/ogsm/getAllManagerOgsm")
        .then((res) => {
          this.allYears = res.data.allYears;
          this.year_id = res.data.yearId;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //Form3 нээх хаах
    visibleForm3(ogsmGoal) {
      //   console.log(ogsmGoal);
      this.vForm3.reset();
      this.form3 = false;
      this.strategySaveOrEdit = false;
      this.vForm3.ogsm_measure_id = ogsmGoal.id;
      this.vForm3.status = ogsmGoal.status;
      this.vForm3.year_id = ogsmGoal.year_id;
      this.form3 = true;
      this.form4 = false;
      this.formModalTitle = "Стратеги нэмэх";
      this.headerBgVariant = "primary";
      this.$bvModal.show("modal-add");
    },

    strategyStore() {
      //   console.log("Ажилласан байна.");
      this.vForm3
        .post("/ogsm/ogsmManagerStrategyStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm3Reset();
          this.$toastr.s("Амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    strategyEdit(ogsmStrategy) {
      this.getGoalOptions(ogsmStrategy.ogsm_measure_id);
      this.vForm3.reset();
      this.form3 = true;
      this.strategySaveOrEdit = true;
      this.vForm3.fill(ogsmStrategy);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsmStrategy.status
      );
      this.vForm3.status = status1Name[0];
      this.form4 = false;
      this.formModalTitle = "Стратеги засах";
      this.headerBgVariant = "primary";
      this.$bvModal.show("modal-add");
    },
    strategyUpdate() {
      //   console.log("Ажилласан байна.");
      this.vForm3
        .patch("/ogsm/ogsmManagerStrategyUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm3Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    strategyDelete(strategy) {
      console.log(strategy);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Стратегид хамаарал бүхий хэмжүүрүүд хамт устгагдана. Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmManagerStrategyDelete/" + strategy.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    vForm3Reset() {
      this.vForm3.reset();
      this.strategySaveOrEdit = false;
      this.form3 = false;
      this.vForm4Reset();
    },
    getGoalOptions(goalId) {
      axios
        .get("/ogsm/getManagerOgsmGoals/" + goalId)
        .then((res) => {
          //   console.log(res.data);
          this.goalOptions = res.data.goalList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //Form4 нээх хаах
    visibleForm4(ogsmStrategy) {
      this.vForm4.reset();
      this.form4 = false;
      this.vForm4.ogsm_manager_strategy_id = ogsmStrategy.id;
      this.vForm4.status = ogsmStrategy.status;
      this.vForm4.year_id = ogsmStrategy.year_id;
      this.measureSaveOrEdit = false;
      this.form4 = true;
      this.form3 = false;
      this.formModalTitle = "Хэмжүүр нэмэх";
      this.headerBgVariant = "secondary";
      this.$bvModal.show("modal-add");
    },
    measureStore() {
      //   console.log("Ажилласан байна.");
      this.vForm4
        .post("/ogsm/ogsmManagerMeasureStore")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.vForm4Reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    measureEdit(ogsmMeasure) {
      this.getStrategyOptions(ogsmMeasure.ogsm_manager_strategy_id);
      this.vForm4.reset();
      this.form4 = true;
      this.measureSaveOrEdit = true;
      this.vForm4.fill(ogsmMeasure);
      let status1Name = this.statusTeams.filter(
        (el) => el.value === ogsmMeasure.status
      );
      this.vForm4.status = status1Name[0];
      this.form3 = false;
      this.formModalTitle = "Хэмжүүр засах";
      this.headerBgVariant = "secondary";
      this.$bvModal.show("modal-add");
    },
    measureUpdate() {
      //   console.log("Ажилласан байна.");
      this.vForm4
        .patch("/ogsm/ogsmManagerMeasureUpdate")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadOgsmObjectives");
          this.vForm4Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStrategyOptions(strategyId) {
      axios
        .get("/ogsm/getManagerOgsmStrategy/" + strategyId)
        .then((res) => {
          //   console.log(res.data);
          this.strategyOptions = res.data.strategyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    measureDelete(measure) {
      console.log(measure);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/ogsm/ogsmManagerMeasureDelete/" + measure.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadOgsmObjectives");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },

    vForm4Reset() {
      this.vForm4.reset();
      this.measureSaveOrEdit = false;
      this.form4 = false;
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    axios
      .get("/getRoleName")
      .then((res) => {
        this.userRole = res.data.roleName;
        if (this.userRole == "cipalprin") {
          axios.get("/manager/getAllDepartments/").then((response) => {
            this.departments = response.data.departments;
          });
          axios
            .get("/ogsm/getSchoolManagers")
            .then((response) => {
              this.managers = response.data.schoolmanagers;
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
        this.getAllOgsmObjectives();
        Fire.$on("loadOgsmObjectives", () => {
          this.getAllOgsmObjectivesYear();
          this.$bvModal.hide("modal-add");
        });
      })
      .catch((errors) => {
        //   console.log(errors);
      });
  },
};
</script>

<style lang="scss" scoped>
.b-icon,
.badge {
  cursor: pointer !important;
}
.list-group-item {
  padding: 0.2rem 1rem;
}
#strategyCard {
  margin-left: 23%;
}
#measureCard {
  margin-left: 30%;
}
.verticalOgsm {
  width: 20px !important;
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.table-responsive {
  overflow-x: unset !important;
}
</style>
