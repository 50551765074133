<template>
  <div class="card card-default color-palette-box mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="allYearsInfo"
            :searchable="true"
            :allow-empty="false"
            @input="getRiskStat"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div>
        <div class="col-lg-4">
          <multiselect
            v-model="dep_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="department_name"
            placeholder="Мэргэжлийн багаар нь шүүж харах"
            :options="allDepartments"
            :searchable="true"
            :allow-empty="false"
            @input="getRiskStat"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.department_name }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-body">
      <span v-if="chart === true">
        <div class="row">
          <div class="col-lg-6" v-if="riskStatCount.length">
            <div>
              <apexchart
                type="donut"
                height="350"
                :options="riskStatCountOption"
                :series="riskStatCountSeries"
              >
              </apexchart>
            </div>
          </div>
          <div class="col-lg-6" v-if="riskDescription.length">
            <div>
              <apexchart
                type="donut"
                height="350"
                :options="riskDescCountOption"
                :series="riskDescCountSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12" v-if="riskCatCount.length">
            <div>
              <apexchart
                type="bar"
                height="450"
                :options="riskCatOptions"
                :series="seriesRiskCat"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3">
          <!-- <multiselect v-model="selectedDesc" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        track-by="value" label="value" placeholder="Үр дүнгээр шүүж харах" :options="riskDescription"
                        :searchable="true" :allow-empty="false" @input="getAllRiskDatas">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.value }}
                            </strong></template>
                    </multiselect> -->
          <b-form-select
            v-model="selectedDesc"
            :options="riskDescription"
            value-field="value"
            text-field="value"
            size="sm"
            @input="getAllRiskDatas"
          ></b-form-select>
        </div>
        <!-- <div class="col-lg-3">
                    <multiselect v-model="year_id" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй." track-by="id"
                        label="id" :custom-label="customYearName" placeholder="Хичээлийн жилээр шүүж харах"
                        :options="allYearsInfo" :searchable="true" :allow-empty="false" @input="getRiskStat">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear
                                }}-{{ option.finish_date | dateYear }}
                                - хичээлийн жил
                            </strong></template>
                    </multiselect>
                </div>
                <div class="col-lg-3">
                    <multiselect v-model="year_id" deselect-label="Сонгосон өгөгдлийг устгах боломжгүй." track-by="id"
                        label="id" :custom-label="customYearName" placeholder="Хичээлийн жилээр шүүж харах"
                        :options="allYearsInfo" :searchable="true" :allow-empty="false" @input="getRiskStat">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.start_date | dateYear
                                }}-{{ option.finish_date | dateYear }}
                                - хичээлийн жил
                            </strong></template>
                    </multiselect>
                </div> -->
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="table-responsive">
            <b-form-group
              label="Нэг хуудсанд харуулах тоо"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPageRisks"
                :options="pageOptionsRisks"
                size="sm"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Хайлт хийх утга оруулна уу!"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filterRisks"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-pagination
              v-model="currentPageRisks"
              :total-rows="totalRowsRisks"
              :per-page="perPageRisks"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <b-table
              hover
              :items="risks"
              :fields="fieldsActiveRisks"
              :current-page="currentPageRisks"
              :per-page="perPageRisks"
              :filter="filterRisks"
              :filter-included-fields="filterOnRisks"
              @filtered="onFilteredRisks"
              :filter-ignored-fields="filterIgnoreColumnRisks"
              sort-icon-right
            >
              <!-- :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(view)="data">
                <a
                  href="#"
                  class="badge bg-primary"
                  @click="riskView(data.item)"
                  ><i class="fa fa-eye"></i
                ></a>
              </template>
              <template #cell(regEmp)="data">
                <small v-if="data.item.reg_emp">
                  {{ data.item.reg_emp.teacher_last_name[0] }}.{{
                    data.item.reg_emp.teacher_name
                  }}</small
                >
              </template>
              <template #cell(confirmEmp)="data">
                <small v-if="data.item.confirm_emp">
                  {{ data.item.confirm_emp.teacher_last_name[0] }}.{{
                    data.item.confirm_emp.teacher_name
                  }}</small
                >
              </template>
              <template #cell(status)="data">
                <b-badge
                  variant="primary"
                  v-if="data.item.status == 'registred'"
                >
                  {{ data.item.status }}</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="data.item.status == 'confirmed'"
                >
                  {{ data.item.status }}</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.status == 'success'"
                >
                  {{ data.item.status }}</b-badge
                >
              </template>
              <template #cell(catigory)="data">
                <b-badge variant="info">
                  <span v-if="data.item.my_cat" class="text-wrap">
                    {{ data.item.my_cat.my_own_cat.rc_name
                    }}{{ data.item.my_cat.rcl_name }}
                  </span>
                </b-badge>
              </template>
              <template #cell(rlcvalue)="data">
                <b-badge
                  v-if="data.item.my_level_cons"
                  :style="{
                    backgroundColor:
                      parseInt(data.item.my_level_cons.rlc_value) >= 1 &&
                      parseInt(data.item.my_level_cons.rlc_value) <= 2
                        ? `green`
                        : parseInt(data.item.my_level_cons.rlc_value) === 3
                        ? `yellow`
                        : parseInt(data.item.my_level_cons.rlc_value) === 4
                        ? `orange`
                        : `red`,
                  }"
                >
                  {{ data.item.my_level_cons.rlc_name }}
                </b-badge>
              </template>
              <template #cell(rlpvalue)="data">
                <b-badge
                  v-if="data.item.my_level_prob"
                  :style="{
                    backgroundColor:
                      parseInt(data.item.my_level_prob.rlp_value) >= 1 &&
                      parseInt(data.item.my_level_prob.rlp_value) <= 2
                        ? `green`
                        : parseInt(data.item.my_level_prob.rlp_value) == 3
                        ? `yellow`
                        : parseInt(data.item.my_level_prob.rlp_value) == 4
                        ? `orange`
                        : `red`,
                  }"
                >
                  <span v-if="data.item.my_level_prob">
                    {{ data.item.my_level_prob.rlp_name }}
                  </span>
                </b-badge>
              </template>
              <template #cell(result)="data">
                <b-badge
                  :style="{
                    backgroundColor:
                      parseInt(data.item.rm_result) >= 1 &&
                      parseInt(data.item.rm_result) <= 4
                        ? `green`
                        : parseInt(data.item.rm_result) >= 5 &&
                          parseInt(data.item.rm_result) <= 9
                        ? `yellow`
                        : parseInt(data.item.rm_result) >= 10 &&
                          parseInt(data.item.rm_result) <= 16
                        ? `orange`
                        : `red`,
                  }"
                >
                  <span v-if="data.item">
                    {{ data.item.rm_description }}
                  </span>
                </b-badge>
              </template>
              <template #cell(created_at)="data">
                <b-badge>
                  {{ data.item.created_at | dateWithTime }}
                </b-badge>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- дэлгэрэнгүй харах -->
    <b-modal
      size="xl"
      id="modal-risk-view"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
      v-show="riskIsView"
    >
      <div class="d-flex justify-content-between mb-3">
        <div>
          Төлөв:
          <span
            class="badge badge-primary"
            v-if="viewRisk.status == 'registred'"
            >{{ viewRisk.status }}</span
          >
          <span
            class="badge badge-warning"
            v-else-if="viewRisk.status == 'confirmed'"
            >{{ viewRisk.status }}</span
          >
          <span
            class="badge badge-success"
            v-else-if="viewRisk.status == 'success'"
            >{{ viewRisk.status }}</span
          >
        </div>
        <div>
          Ангилал:
          <span class="badge badge-light" v-if="viewRisk.my_cat">
            {{ viewRisk.my_cat.my_own_cat.rc_name
            }}{{ viewRisk.my_cat.rcl_name }}
          </span>
        </div>
        <div v-if="viewRisk.my_level_cons">
          Үр дагавар:
          <b-badge
            :style="{
              backgroundColor:
                parseInt(viewRisk.my_level_cons.rlc_value) >= 1 &&
                parseInt(viewRisk.my_level_cons.rlc_value) <= 2
                  ? 'green'
                  : parseInt(viewRisk.my_level_cons.rlc_value) === 3
                  ? 'yellow'
                  : parseInt(viewRisk.my_level_cons.rlc_value) === 4
                  ? 'orange'
                  : 'red',
            }"
          >
            {{ viewRisk.my_level_cons.rlc_name }}
          </b-badge>
        </div>
        <div v-if="viewRisk.my_level_prob">
          Магадлал:
          <b-badge
            :style="{
              backgroundColor:
                parseInt(viewRisk.my_level_prob.rlp_value) >= 1 &&
                parseInt(viewRisk.my_level_prob.rlp_value) <= 2
                  ? 'green'
                  : parseInt(viewRisk.my_level_prob.rlp_value) == 3
                  ? 'yellow'
                  : parseInt(viewRisk.my_level_prob.rlp_value) == 4
                  ? 'orange'
                  : 'red',
            }"
          >
            <span v-if="viewRisk.my_level_prob">{{
              viewRisk.my_level_prob.rlp_name
            }}</span>
          </b-badge>
        </div>
        <div v-if="viewRisk.rm_result">
          Үр дүн:
          <b-badge
            :style="{
              backgroundColor:
                parseInt(viewRisk.rm_result) >= 1 &&
                parseInt(viewRisk.rm_result) <= 4
                  ? 'green'
                  : parseInt(viewRisk.rm_result) >= 5 &&
                    parseInt(viewRisk.rm_result) <= 9
                  ? 'yellow'
                  : parseInt(viewRisk.rm_result) >= 10 &&
                    parseInt(viewRisk.rm_result) <= 16
                  ? 'orange'
                  : 'red',
            }"
          >
            <span v-if="viewRisk">{{ viewRisk.rm_description }}</span>
          </b-badge>
        </div>
      </div>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link active" href="#registred" data-toggle="tab"
            >Бүртгэгдсэн</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#confirmed" data-toggle="tab"
            >Баталгаажуулсан</a
          >
        </li>
      </ul>
      <div class="card-body table-responsive p-0">
        <div class="tab-content">
          <div class="tab-pane active" id="registred">
            <template>
              <div
                class="p-3"
                v-for="riskDef in riskDefinitions"
                :key="riskDef.id"
              >
                <h4 id="sidebar-no-header-title">{{ riskDef.rd_name }}</h4>
                <div class="col-lg-12">
                  <table class="table text-wrap report-user-info">
                    <tbody>
                      <tr
                        role="row"
                        v-for="my_r_d in riskDef.my_r_d_lists"
                        :key="my_r_d.id"
                      >
                        <th>{{ my_r_d.rdl_name }}</th>
                        <td>{{ my_r_d.explain }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
          <div class="tab-pane" id="confirmed">
            <template>
              <div
                class="p-3"
                v-for="riskDef in riskDefinitions"
                :key="riskDef.id"
              >
                <h4 id="sidebar-no-header-title">{{ riskDef.rd_name }}</h4>
                <div class="col-lg-12">
                  <table class="table text-wrap report-user-info">
                    <tbody>
                      <tr
                        role="row"
                        v-for="my_r_d in riskDef.my_r_d_lists"
                        :key="my_r_d.id"
                      >
                        <th>{{ my_r_d.rdl_name }}</th>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ my_r_d.explainMan }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <b-button
        v-if="userInfo.name == 'cipalprin' || userInfo.name == 'consultant'"
        class="mt-3"
        block
        variant="outline-warning"
        v-b-toggle.sidebar-monthPlan
        @click.prevent="getRiskMonthPlans()"
        >Залруулах арга хэмжээ</b-button
      >
    </b-modal>
    <!-- Сарын ажлын төлөвлөгөө -->
    <b-modal
      size="xl"
      id="modal-monthPlan"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 mt-3">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Эрсдлийн сарын төлөвлөгөө</h3>
              <!-- <search-student
            ></search-student> -->
            </div>
            <div class="card-body table-responsive">
              <b-overlay rounded="sm">
                <table class="table projects">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Үйлдлүүд</th>
                      <th>Гүйцэтгэл</th>
                      <th>Нэр</th>
                      <!-- <th>Агуулга</th> -->
                      <th>Хариуцах эзэн</th>
                      <th>Хамтран ажиллах</th>
                      <th>Огноо</th>
                      <!-- <th>Анализ</th>
                <th>Хяналтын үзүүлэлт</th> -->
                    </tr>
                  </thead>
                  <tbody
                    v-for="(monthPlan, index) in allMonthPlans"
                    :key="monthPlan.monthPlanId"
                  >
                    <tr>
                      <td tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td tabindex="0">
                        <a
                          href="#"
                          class="badge bg-success"
                          @click="showMonthPlan(monthPlan)"
                          ><i class="fa fa-eye"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-success"
                          @click="showRiskTask(monthPlan)"
                          ><i class="fa fa-plus"></i
                        ></a>
                      </td>
                      <td style="text-align: center" tabindex="0">
                        <div
                          class="progress progress-xs progress-striped active"
                        >
                          <div
                            v-if="monthPlan.point < 30"
                            class="progress-bar bg-danger"
                            :style="{
                              width: `${monthPlan.point}%`,
                            }"
                          ></div>
                          <div
                            v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                            class="progress-bar bg-warning"
                            :style="{
                              width: `${monthPlan.point}%`,
                            }"
                          ></div>
                          <div
                            v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                            class="progress-bar bg-primary"
                            :style="{
                              width: `${monthPlan.point}%`,
                            }"
                          ></div>
                          <div
                            v-if="monthPlan.point >= 80"
                            class="progress-bar bg-success"
                            :style="{
                              width: `${monthPlan.point}%`,
                            }"
                          ></div>
                        </div>
                        <small>
                          <b-badge
                            v-if="monthPlan.point >= 80"
                            variant="success"
                            v-show="monthPlan.point != null"
                          >
                            {{ monthPlan.point }}</b-badge
                          >
                          <b-badge
                            v-if="monthPlan.point >= 60 && monthPlan.point < 80"
                            variant="primary"
                            v-show="monthPlan.point != null"
                          >
                            {{ monthPlan.point }}</b-badge
                          >
                          <b-badge
                            v-if="monthPlan.point >= 30 && monthPlan.point < 60"
                            variant="warning"
                            v-show="monthPlan.point != null"
                          >
                            {{ monthPlan.point }}</b-badge
                          >
                          <b-badge
                            v-if="monthPlan.point < 30"
                            variant="danger"
                            v-show="monthPlan.point != null"
                          >
                            {{ monthPlan.point }}</b-badge
                          >
                          <b-badge
                            variant="warning"
                            v-show="monthPlan.point === null"
                          >
                            ОРООГҮЙ</b-badge
                          >
                        </small>
                      </td>
                      <td tabindex="0">
                        <b-badge
                          variant="light"
                          style="white-space: pre-wrap"
                          >{{ monthPlan.name }}</b-badge
                        >
                      </td>
                      <!-- <td tabindex="0">
                  <small>
                    <b-badge variant="light" style="white-space: pre-wrap">{{
                      monthPlan.description
                    }}</b-badge>
                  </small>
                </td> -->
                      <td tabindex="0" v-if="monthPlan.owner">
                        {{
                          monthPlan.owner.teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}.
                        {{ monthPlan.owner.teacher_name }}
                      </td>
                      <td tabindex="0">
                        <ul class="list-inline">
                          <li
                            class="list-inline-item"
                            v-for="teacher in monthPlan.month_plan_teachers"
                            :key="teacher.id"
                          >
                            <img
                              @click="showImage(teacher)"
                              v-if="teacher.employee"
                              alt="Avatar"
                              v-b-tooltip.hover.top.html="
                                '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                                teacher.department.department_name +
                                '</dd><dt>Ажлын байр:</dt><dd>' +
                                teacher.position.position_name +
                                '</dd><dt>Овог Нэр</dt><dd>' +
                                teacher.employee.teacher_last_name +
                                ' <span class=text-uppercase>' +
                                teacher.employee.teacher_name +
                                '</span></dd></dl>'
                              "
                              class="table-avatar"
                              :src="
                                teacher.employee.profile_photo_url
                                  ? teacher.employee.profile_photo_url
                                  : '/images/users/user.png'
                              "
                            />
                          </li>
                        </ul>
                      </td>
                      <td
                        class="dtr-control sorting_1 text-center"
                        tabindex="0"
                      >
                        {{ monthPlan.start_date | dateYearMonthDay }}
                        <br />
                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                        <br />
                        {{ monthPlan.end_date | dateYearMonthDay }}
                      </td>
                      <!-- <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.analysis
                  }}</b-badge>
                </td>
                <td tabindex="0" style="white-space: pre-wrap">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    monthPlan.indicator
                  }}</b-badge>
                </td> -->
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td colspan="3" class="text-right">
                        <small>Дундаж /0 тооцсон/</small>
                      </td>
                      <td colspan="3">
                        <strong>{{ unelgeeAvg }}%</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-right">
                        <small>Дундаж /0 тооцохгүй/</small>
                      </td>
                      <td colspan="3">
                        <strong>{{ unelgee0Avg }}%</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-right">
                        <small>Нийт:</small>
                      </td>
                      <td colspan="3">
                        <strong>{{ niit }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <b-modal
          v-show="showMode"
          size="xl"
          id="modal-show-month-plan"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          :title="showMonthPlanInfo.name"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span
                  class="direct-chat-name float-left"
                  v-if="showMonthPlanInfo.owner"
                >
                  {{ showMonthPlanInfo.owner.teacher_last_name }}
                  {{ showMonthPlanInfo.owner.teacher_name | uppercase }}</span
                >
                <span class="direct-chat-timestamp float-right"
                  >{{ showMonthPlanInfo.start_date | dateYearMonthDay }}
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  {{ showMonthPlanInfo.end_date | dateYearMonthDay }}
                </span>
              </div>
              <!-- /.direct-chat-infos -->
              <img
                :src="
                  showMonthPlanInfo.owner.profile_photo_url
                    ? showMonthPlanInfo.owner.profile_photo_url
                    : '/images/users/user.png'
                "
                alt="user-avatar"
                class="direct-chat-img"
              />
              <!-- /.direct-chat-img -->
              <div class="row">
                <div class="direct-chat-text col-lg-3">
                  <b>АГУУЛГА:</b><br />
                  <span v-html="showMonthPlanInfo.description"> </span>
                  <!-- ? data.item.manager_explain.substring(0, 100)
              : '' -->
                  <!-- <b-badge variant="light">
                            </b-badge> -->
                  <!-- {{ showMonthPlanInfo.description }} -->
                </div>
                <div class="direct-chat-text col-lg-3">
                  <b>АНАЛИЗ:</b><br />
                  <span v-html="showMonthPlanInfo.analysis"> </span>
                </div>
                <div class="direct-chat-text col-lg-3">
                  <b>ХЯНАЛТЫН ҮЗҮҮЛЭЛТ:</b><br />
                  <span v-html="showMonthPlanInfo.indicator"> </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt-2">
                <div
                  class="callout callout-success"
                  v-if="showMonthPlanInfo.point > 0"
                >
                  <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
                  <p>
                    <small>
                      <span v-html="showMonthPlanInfo.point_explain"> </span>
                    </small>
                  </p>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Сарын төлөвлөгөө авсан багш нар</h3>
                    <span class="direct-chat-timestamp float-right"
                      ><b>Үнэлгээ: </b> {{ showMonthPlanInfo.point }}%
                    </span>
                  </div>
                  <!-- /.card-header -->
                  <div
                    class="card-body table-responsive"
                    v-if="monthPlanEmps != null"
                  >
                    <table class="table text-wrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Мэргэжлийн баг</th>
                          <th>Ажлын байр</th>
                          <th>Зураг</th>
                          <th>Багш</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(emp, index) in monthPlanEmps"
                        :key="emp.show_monthPlan_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ emp.department.department_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ emp.position.position_name }}
                          </td>
                          <td>
                            <img
                              :src="
                                emp.employee.profile_photo_url
                                  ? emp.employee.profile_photo_url
                                  : '/images/users/user.png'
                              "
                              alt="user-avatar"
                              class="direct-chat-img"
                            />
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="emp.employee"
                          >
                            {{
                              emp.employee.teacher_last_name
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ emp.employee.teacher_name | uppercase }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <b-button @click="cancelTaskInfo()" variant="danger" type="reset">
              Хаах
            </b-button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
          <!-- /.modal-dialog -->
        </b-modal>
        <b-modal
          size="lg"
          id="modal-show-employee"
          :body-bg-variant="bodyBgVariant"
          class="modal fade"
          hide-footer
          hide-header
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-8 mt-2">
                <img
                  style="width: 100%"
                  v-if="showEmployee.employee.profile_photo_url != null"
                  :src="showEmployee.employee.profile_photo_url"
                />
              </div>
              <div class="col-lg-4 mt-2">
                <dl class="text-left">
                  <dt>Иргэншил:</dt>
                  <dd>{{ showEmployee.employee.citizenship }}</dd>
                  <dt>Мэргэжлийн баг:</dt>
                  <dd>
                    {{ showEmployee.department.department_name }}
                  </dd>
                  <dt>Ажлын байр:</dt>
                  <dd>{{ showEmployee.position.position_name }}</dd>
                  <dt>Овог Нэр:</dt>
                  <dd v-if="showEmployee.employee">
                    {{ showEmployee.employee.teacher_last_name }}
                    <span class="text-uppercase">
                      {{ showEmployee.employee.teacher_name }}
                    </span>
                  </dd>
                  <dt>Системд бүртгэгдсэн огноо:</dt>
                  <dd>
                    {{ showEmployee.employee.created_at | dateYearMonthDay }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- /.modal-dialog -->
        </b-modal>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Ангилал:
            <span class="badge badge-light" v-if="viewRisk.my_cat">
              {{ viewRisk.my_cat.my_own_cat.rc_name
              }}{{ viewRisk.my_cat.rcl_name }}
            </span>
            Үр дүн:
            <b-badge
              :style="{
                backgroundColor:
                  parseInt(viewRisk.rm_result) >= 1 &&
                  parseInt(viewRisk.rm_result) <= 4
                    ? 'green'
                    : parseInt(viewRisk.rm_result) >= 5 &&
                      parseInt(viewRisk.rm_result) <= 9
                    ? 'yellow'
                    : parseInt(viewRisk.rm_result) >= 10 &&
                      parseInt(viewRisk.rm_result) <= 16
                    ? 'orange'
                    : 'red',
              }"
            >
              <span v-if="viewRisk">{{ viewRisk.rm_description }}</span>
            </b-badge>
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
    </b-modal>
    <!-- Үүрэг даалгавар -->
    <b-modal
      size="xl"
      id="modal-show-task"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitleTask"
      class="modal fade"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 mt-3">
          <div class="card card-primary card-outline">
            <div class="card-header p-2">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" href="#inactive" data-toggle="tab"
                    >Хариу өгөөгүй</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#activity" data-toggle="tab"
                    >Хариу өгсөн</a
                  >
                </li>
              </ul>
            </div>
            <div class="card">
              <div class="card-body table-responsive p-0">
                <div class="tab-content">
                  <div class="tab-pane active" id="inactive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үйлдлүүд</th>
                          <th>Гүйцэтгэл</th>
                          <th>Өгсөн үүрэг</th>
                          <th>Эхлэх огноо</th>
                          <th>Дуусах огноо</th>
                          <th>Тайлбар</th>
                          <th>Зассан</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(task, index) in allTask"
                        :key="task.task_teachers_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="editTask(task)"
                              ><i class="fa fa-check"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-success"
                              @click="showTask(task)"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <b-badge
                              v-b-tooltip.hover.top.html="
                                '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                                task.replyTeachers.join('<br>') +
                                ' <span class=text-uppercase>' +
                                '</span></dd></dl>'
                              "
                              pill
                              class="text-center"
                            >
                              {{ task.allTeacherCount }}/{{ task.replyCount }}
                            </b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            style="text-align: center"
                            tabindex="0"
                          >
                            <b-badge
                              v-if="task.guitsetgel >= 80"
                              variant="success"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 60) & (task.guitsetgel < 80)
                              "
                              variant="primary"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 30) & (task.guitsetgel < 60)
                              "
                              variant="warning"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="task.guitsetgel < 30"
                              variant="danger"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              variant="warning"
                              v-show="task.guitsetgel === null"
                            >
                              ОРООГҮЙ</b-badge
                            >
                            <div
                              class="progress progress-xs progress-striped active"
                            >
                              <div
                                v-if="task.guitsetgel < 30"
                                class="progress-bar bg-danger"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 30) &
                                  (task.guitsetgel < 60)
                                "
                                class="progress-bar bg-warning"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 60) &
                                  (task.guitsetgel < 80)
                                "
                                class="progress-bar bg-primary"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="task.guitsetgel >= 80"
                                class="progress-bar bg-success"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                            </div>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge variant="light">
                              <p class="text-sm">
                                <a
                                  href="#"
                                  class="dropdown-item"
                                  @click="showTaskView(task)"
                                >
                                  <b-badge variant="light">
                                    <span v-html="task.task.substring(0, 100)">
                                    </span>
                                  </b-badge>
                                </a>
                              </p>
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.created_at | dateYearMonthDay }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.performance_date | dateYearMonthDay }}
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap"
                              >{{ task.manager_explain }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.updated_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцсон/
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ unelgeeAvg }}%</strong>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцохгүй/
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ unelgee0Avg }}%</strong>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Нийт:
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ niit }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="tab-pane" id="activity">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үйлдлүүд</th>
                          <th>Гүйцэтгэл</th>
                          <th>Өгсөн үүрэг</th>
                          <th>Эхлэх огноо</th>
                          <th>Дуусах огноо</th>
                          <th>Тайлбар</th>
                          <th>Зассан</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(task, index) in allTaskReplied"
                        :key="task.task_teachers_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="editTask(task)"
                              ><i class="fa fa-check"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-success"
                              @click="showTask(task)"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <b-badge
                              v-b-tooltip.hover.top.html="
                                '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                                task.replyTeachers.join('<br>') +
                                ' <span class=text-uppercase>' +
                                '</span></dd></dl>'
                              "
                              pill
                              class="text-center"
                            >
                              {{ task.allTeacherCount }}/{{ task.replyCount }}
                            </b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            style="text-align: center"
                            tabindex="0"
                          >
                            <b-badge
                              v-if="task.guitsetgel >= 80"
                              variant="success"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 60) & (task.guitsetgel < 80)
                              "
                              variant="primary"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 30) & (task.guitsetgel < 60)
                              "
                              variant="warning"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="task.guitsetgel < 30"
                              variant="danger"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              variant="warning"
                              v-show="task.guitsetgel === null"
                            >
                              ОРООГҮЙ</b-badge
                            >
                            <div
                              class="progress progress-xs progress-striped active"
                            >
                              <div
                                v-if="task.guitsetgel < 30"
                                class="progress-bar bg-danger"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 30) &
                                  (task.guitsetgel < 60)
                                "
                                class="progress-bar bg-warning"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 60) &
                                  (task.guitsetgel < 80)
                                "
                                class="progress-bar bg-primary"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="task.guitsetgel >= 80"
                                class="progress-bar bg-success"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                            </div>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge variant="light">
                              <p class="text-sm">
                                <a
                                  href="#"
                                  class="dropdown-item"
                                  @click="showTaskView(task)"
                                >
                                  <b-badge variant="light">
                                    <span v-html="task.task.substring(0, 100)">
                                    </span>
                                  </b-badge>
                                </a>
                              </p>
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.created_at | dateYearMonthDay }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.performance_date | dateYearMonthDay }}
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <!-- <b-badge
                                                    variant="light"
                                                    style="white-space: pre-wrap"
                                                    >{{
                                                        task.manager_explain
                                                    }}</b-badge
                                                > -->

                            <a
                              href="#"
                              class="dropdown-item"
                              @click="showTaskView(task)"
                            >
                              <b-badge variant="light">
                                <span
                                  v-html="
                                    task.manager_explain
                                      ? task.manager_explain.substring(0, 100)
                                      : ''
                                  "
                                >
                                </span>
                              </b-badge>
                            </a>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.updated_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцсон/
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ unelgeeAvgRep }}%</strong>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцохгүй/
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ unelgee0AvgRep }}%</strong>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Нийт:
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <strong>{{ niitRep }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
        <b-modal
          size="xl"
          id="modal-view-task"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Үүрэг даалгавар"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-timestamp float-right"
                  ><b>Огноо: </b>
                  {{ showTaskData.created_at | dateYearMonthDay }}</span
                >
              </div>
              <div
                class="direct-chat-text primary ml-0"
                style="overflow-x: auto"
              >
                <span v-html="showTaskData.task"> </span>
                <span
                  v-html="
                    showTaskData.teacher_explain
                      ? showTaskData.teacher_explain
                      : ''
                  "
                >
                </span>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          v-show="showMode"
          size="xl"
          id="modal-taskInfo"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Үүрэг даалгавар"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left"
                  >{{ showTaskInfo.enrol_lname }}
                  {{ showTaskInfo.enrol_fname | uppercase }}</span
                >
                <span class="direct-chat-timestamp float-right">{{
                  showTaskInfo.created_at | dateYearMonthDay
                }}</span>
              </div>
              <!-- /.direct-chat-infos -->
              <img
                v-if="showTaskInfo.profile_photo_url != null"
                :src="showTaskInfo.profile_photo_url"
                alt="user-avatar"
                class="direct-chat-img"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="direct-chat-img"
              />
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text primary">
                <!-- {{ showTaskInfo.task }} -->
                <span v-html="showTaskInfo.task"> </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt-2">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Үүрэг даалгавар авсан багш нар</h3>
                  </div>
                  <!-- /.card-header -->
                  <div
                    class="card-body table-responsive"
                    v-if="taskInfo != null"
                  >
                    <table class="table text-wrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үнэлгээ</th>
                          <th>Мэргэжлийн баг</th>
                          <th>Ажлын байр</th>
                          <th>Багш</th>
                          <th>Тайлбар</th>
                          <th>Үйлдэл</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(taskD, index) in taskInfo"
                        :key="taskD.show_task_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.guitsetgel }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.department_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.emp_position_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.emp_teacher_last_name[0] }}.
                            {{ taskD.emp_teacher_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap"
                            >
                              <!-- {{
                                                        taskD.teacher_explain
                                                    }} -->
                              <!-- <span
                                                        v-html="
                                                            taskD.teacher_explain
                                                        "
                                                    >
                                                    </span> -->

                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showTaskView(taskD)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      taskD.teacher_explain
                                        ? taskD.teacher_explain.substring(
                                            0,
                                            100
                                          )
                                        : ''
                                    "
                                  >
                                  </span>
                                </b-badge>
                              </a>
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="editTeacher(taskD)"
                              ><i class="fa fa-edit"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-danger"
                              @click="removeTeacher(taskD)"
                              ><i class="fa fa-minus"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <b-button @click="cancelTaskInfo()" variant="danger" type="reset">
              Хаах
            </b-button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
          <!-- /.modal-dialog -->
        </b-modal>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import riskTask from "../../Manager/Task/Task.vue";
export default {
  data() {
    return {
      formModalTitle: "Эрсдэл бүртгэх",
      formModalTitleTask: "Эрсдэлтэй холбоотой үүрэг даалгавар",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      allYears: [],
      allDepartments: [],
      year_id: "",
      dep_id: "",
      selectedDesc: "",
      // editorOption: {
      //   // some quill options
      // },

      chart: false,
      risks: [],
      riskStatCount: [],
      riskDescription: [],
      riskCatCount: [],
      riskMatrices: [],
      riskConsequences: [],
      riskProbabilities: [],
      riskCategories: [],
      riskCategoryLists: [],
      riskDefinitions: [],
      riskDefManag: [],
      confRisk: [],
      regRisk: [],
      viewRisk: [],
      riskIsView: false,
      // riskCategoryLists: [],
      riskSaveOrEdit: false,
      riskConfirmSaveOrEdit: false,

      vForm1: new Form({
        id: "",
        year_id: "",
        school_id: "",
        rcl_id: "",
        rlp_id: "",
        rlc_id: "",
        regis_worker_id: "",
        department_id: "",
        explain: "Зорилт ",
        explain_manag: "",
      }),

      //Task хүснэгтийн тайлбар
      totalRowsRisks: 1,
      currentPageRisks: 1,
      perPageRisks: 25,
      pageOptionsRisks: [
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        { value: 100, text: "Илүү ихээр харах" },
      ],
      sortBy: "status",
      sortDesc: false,
      // sortDirection: "registred",
      filterRisks: null,
      filterOnRisks: [],
      filterIgnoreColumnRisks: [
        "confirm_emp",
        "created_at",
        "deleted_at",
        "department_id",
        // "explain",
        // "explain_manag",
        "id",
        "manager_worker_id",
        "my_cat",
        "my_level_cons",
        "my_level_prob",
        "rcl_id",
        "reg_emp",
        "regis_worker_id",
        "rlc_id",
        "rlp_id",
        "school_id",
        "updated_at",
        "year_id",
      ],
      fieldsActiveRisks: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "view",
          label: "Харах",
          sortable: false,
        },
        {
          key: "status",
          label: "Төлөв",
          sortable: true,
        },
        {
          key: "regEmp",
          label: "Бүртгэсэн",
          sortable: true,
        },
        {
          key: "confirmEmp",
          label: "Баталгаажуулсан",
          sortable: true,
        },
        {
          key: "catigory",
          label: "Ангилал",
          sortable: true,
        },
        {
          key: "rlcvalue",
          label: "Үр дагавар",
          sortable: true,
        },
        {
          key: "rlpvalue",
          label: "Магадлал",
          sortable: true,
        },
        {
          key: "result",
          label: "Үр дүн",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
      ],
      // month plan
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allMonthPlans: [],
      monthPlanEmps: [],
      departments: [{ value: 0, department_name: "Please select an option" }],
      leaderTeachers: [],
      myDepId: "",
      myEmpId: "",
      ownId: "",
      leadId: "",

      teachers: [],
      topSelectTeachers: [],
      showMode: false,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,
      filterForm: new Form({
        department_id: 0,
      }),
      filterLeaderForm: new Form({
        department_id: 0,
      }),
      showMonthPlanInfo: new Form({
        monthPlanId: "",
        owner_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
        owner: [],
      }),
      editMode: false,
      form: new Form({
        monthPlanId: "",
        owner_id: "",
        leader_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
      }),
      modalTeachers: [],
      formAddTeacher: new Form({
        monthPlanId: null,
        monthPlanIdTask: null,
        department_id: 0,
        recieved_employees: [],
      }),
      showEmployee: new Form({
        department: "",
        employee: "",
        position: "",
      }),
      // quill тохиргоо
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
      //task
      monthPlanIdTask: "",
      selectedMonthsForTask: null,
      filterFormFoTask: new Form({
        department_id: 0,
      }),
      teachersForTask: [],
      taskNiit: "",
      niitRep: "",
      unelgeeListTask: [],
      unelgeeSumTask: "",
      unelgeeAvgTask: "",
      unelgeeAvgRep: "",

      unelgee0ListTask: [],
      unelgee0SumTask: "",
      unelgee0AvgTask: "",
      unelgee0AvgRep: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showTaskData: "",
      allTask: [],
      allTaskReplied: [],
      taskInfo: [],
      showModeTask: false,
      showTaskInfo: new Form({
        task_id: "",
        department_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employee_id: "",
        position_id: "",
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editTaskMode: false,
      formTask: new Form({
        monthPlanId: "",
        task_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employees: [],
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editTeacherMode: false,
      formAddTeacherTask: new Form({
        show_task_id: null,
        task_id: null,
        department_id: 0,
        recieved_employees: [],
        guitsetgel: "",
      }),
      riskStatCountSeries: [],
      riskStatCountOption: {
        labels: ["Баталгаажуулсан", "Баталгаажаагүй"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      riskDescCountSeries: [],
      riskDescCountOption: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      seriesRiskCat: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      riskCatOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "4px",
            },
          },
        },
      },
    };
  },
  components: { Multiselect, riskTask: riskTask },
  watch: {
    // year_id: function (newVal, oldVal) {
    //   //   console.log(newVal, oldVal);
    //   axios
    //     .post("/principal/getAllOgsmYear", { year_id: newVal })
    //     .then((res) => {
    //       //   console.log(res.data);
    //       this.allOgsm = [];
    //       this.allOgsm = res.data.allOgsm;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  computed: {
    allYearsInfo() {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    editor() {
      return this.$ref.myQuillEditor;
    },
  },
  methods: {
    onFilteredRisks(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRisks = filteredItems.length;
      this.currentPageRisks = 1;
    },
    getRiskStat() {
      this.riskStatCountSeries = [];
      this.riskDescCountSeries = [];
      this.riskDescCountOption.labels = [];
      this.seriesRiskCat[0].data = [];
      this.riskCatOptions.xaxis.categories = [];
      this.chart = false;
      axios
        .post("/all_worker_functions/getRiskStat", {
          year: this.year_id,
          depId: this.dep_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.riskStatCount = res.data.riskStatCount;
          this.riskDescription = res.data.descriptions;
          this.riskCatCount = res.data.riskCatCount;
          this.allDepartments = res.data.departments;
          this.riskCatCount.forEach((element) => {
            this.seriesRiskCat[0].data.push(element.count);
            this.riskCatOptions.xaxis.categories.push(element.rcl_name);
          });
          this.riskDescCountSeries = res.data.descCount;
          this.riskDescCountOption.labels = res.data.descriptions;
          this.riskStatCountSeries = res.data.riskStatCount;
          this.chart = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllRiskDatas() {
      axios
        .post("/all_worker_functions/getAllRiskDatas", {
          year: this.year_id,
          selectedDesc: this.selectedDesc,
        })
        .then((res) => {
          //   console.log(res.data);
          this.risks = res.data.risks;
          this.riskMatrices = res.data.riskMatrices;
          this.riskConsequences = res.data.riskConsequences;
          this.riskProbabilities = res.data.riskProbabilities;
          this.riskCategories = res.data.riskCategories;
          this.riskCategoryLists = res.data.riskCategoryLists;
          this.riskDefinitions = res.data.riskDefinitions;

          this.totalRowsRisks = this.risks.length;

          this.riskDefinitions.forEach((element) => {
            element.my_r_d_lists.forEach((el) => {
              // this.vForm2["rdl2_" + el.id + "_list"] = "";
              this.vForm1["rdl1_" + el.id + "_list"] = "";
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    vForm1Reset() {
      this.vForm1.reset();
      // this.vForm2.reset();
      this.riskSaveOrEdit = false;
      this.riskConfirmSaveOrEdit = false;
      this.$bvModal.hide("modal-risk-create");
      this.$bvModal.hide("modal-risk-confirm");
    },
    riskView(risk) {
      this.riskIsView = false;
      this.viewRisk = risk;
      this.formModalTitle = "Эрсдэл харах";
      let expManArr = [];
      let expArr = risk.explain.split("-/-");

      if (risk.explain_manag != null)
        expManArr = risk.explain_manag.split("-/-");
      this.riskDefinitions.forEach((element) => {
        element.my_r_d_lists.forEach((el) => {
          el.explain = "";
          if (expArr.length > 0) el.explain = expArr[el.id];
          el.explainMan = "";
          if (expManArr.length > 0) el.explainMan = expManArr[el.id];
        });
      });
      this.riskIsView = true;
      this.$bvModal.show("modal-risk-view");
    },
    riskConfirmedUpdate() {
      this.vForm1
        .put("/all_worker_functions/riskConfirmedUpdate/" + this.vForm1.id)
        .then((res) => {
          Fire.$emit("loadRisks");
          this.vForm1Reset();
          this.$toastr.s("Амжилттай заслаа!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelConfirmRisk() {
      this.vForm1.reset();
      this.$bvModal.hide("modal-risk-confirm");
    },
    customCategoriesLists({ rcl_name, my_own_cat }) {
      return `${my_own_cat.rc_name}(${rcl_name})`;
    },
    //#region monthPlans
    customOwnerName({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    averageCheck(allMonthPlans) {
      this.niit = 0;
      this.unelgeeList = [];
      this.unelgeeSum = "";
      this.unelgeeAvg = "";

      this.unelgee0List = [];
      this.unelgee0Sum = "";
      this.unelgee0Avg = "";

      allMonthPlans.forEach((element) => {
        if (element.point == null) this.unelgeeList.push(0);
        else this.unelgeeList.push(parseInt(element.point));
      });

      this.unelgeeSum = this.unelgeeList.reduce((a, b) => a + b, 0);
      this.unelgeeAvg = (this.unelgeeSum / this.unelgeeList.length).toFixed();
      this.niit = this.unelgeeList.length;

      //together zero
      this.unelgee0List = this.unelgeeList.filter((el) => el !== 0);
      this.unelgee0Sum = this.unelgee0List.reduce((a, b) => a + b, 0);
      this.unelgee0Avg = (
        this.unelgee0Sum / this.unelgee0List.length
      ).toFixed();
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formAddTeacher
        .get(
          "/month_plan_manager/getTeacherList/" +
            this.formAddTeacher.department_id
        )
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getRiskMonthPlans() {
      // console.log(this.activeYear);
      this.$bvModal.hide("modal-risk-view");
      this.$bvModal.show("modal-monthPlan");
      let ownerId = null;
      let yearId = null;
      let leaderId = null;
      if (this.yearId) yearId = this.yearId.id;
      if (this.ownId) ownerId = this.ownId.emp_id;
      if (this.leadId) leaderId = this.leadId.emp_id;
      axios
        .post("/month_plan_manager/getRiskMonthPlans", {
          selectedMonths: this.selectedMonths,
          year_id: this.viewRisk.year_id,
          ownerId: ownerId,
          leaderId: leaderId,
          riskId: this.viewRisk.id,
        })
        .then((response) => {
          this.filterForm.department_id = 0;
          this.allMonthPlans = response.data.allMonthPlans;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.topSelectTeachers = response.data.teachers;
          this.teachers = response.data.teachers;
          this.leaderTeachers = this.teachers;
          this.myDepId = response.data.dep_id;
          this.filterLeaderForm.department_id = this.myDepId;
          this.myEmpId = response.data.leader_id;
          this.changeOwnerTeacherList();
          this.form.leader_id = this.myEmpId;
          this.averageCheck(this.allMonthPlans);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    cancelTaskInfo() {
      this.showMode = false;
      this.showMonthPlanInfo.reset();
      this.$bvModal.hide("modal-show-month-plan");
    },
    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },
    showMonthPlan(monthPlan) {
      // console.log(monthPlan);
      this.monthPlanEmps = monthPlan.month_plan_teachers;
      this.showMonthPlanInfo.reset();
      this.showMonthPlanInfo.fill(monthPlan);
      this.showMode = !this.showMode;
      this.modalTeachers = this.teachers;
      this.formAddTeacher.reset();
      this.$bvModal.show("modal-show-month-plan");
    },

    //#endregion
    //#region task
    showRiskTask(monthPlan) {
      // console.log(this.year_id);
      this.monthPlanIdTask = monthPlan.id;
      this.modalTeachers = this.teachers;
      this.formAddTeacherTask.reset();
      this.$bvModal.show("modal-show-task");
      this.getAllTask();
    },
    averageCheck(allTask) {
      this.taskNiit = 0;
      this.unelgeeListTask = [];
      this.unelgeeSumTask = "";
      this.unelgeeAvgTask = "";

      this.unelgee0ListTask = [];
      this.unelgee0SumTask = "";
      this.unelgee0AvgTask = "";

      allTask.forEach((element) => {
        if (element.guitsetgel == null) this.unelgeeListTask.push(0);
        else this.unelgeeListTask.push(parseInt(element.guitsetgel));
      });

      this.unelgeeSumTask = this.unelgeeListTask.reduce((a, b) => a + b, 0);
      this.unelgeeAvgTask = (
        this.unelgeeSumTask / this.unelgeeListTask.length
      ).toFixed();
      this.taskNiit = this.unelgeeListTask.length;

      //together zero
      this.unelgee0ListTask = this.unelgeeListTask.filter((el) => el !== 0);
      this.unelgee0SumTask = this.unelgee0ListTask.reduce((a, b) => a + b, 0);
      this.unelgee0AvgTask = (
        this.unelgee0SumTask / this.unelgee0ListTask.length
      ).toFixed();
    },
    averageCheckReplied(allTaskReplied) {
      this.niitRep = 0;
      this.unelgeeListTask = [];
      this.unelgeeSumTask = "";
      this.unelgeeAvgRep = "";

      this.unelgee0ListTask = [];
      this.unelgee0SumTask = "";
      this.unelgee0AvgRep = "";

      allTaskReplied.forEach((element) => {
        if (element.guitsetgel == null) this.unelgeeListTask.push(0);
        else this.unelgeeListTask.push(parseInt(element.guitsetgel));
      });

      this.unelgeeSumTask = this.unelgeeListTask.reduce((a, b) => a + b, 0);
      this.unelgeeAvgRep = (
        this.unelgeeSumTask / this.unelgeeListTask.length
      ).toFixed();
      this.niitRep = this.unelgeeListTask.length;

      //together zero
      this.unelgee0ListTask = this.unelgeeListTask.filter((el) => el !== 0);
      this.unelgee0SumTask = this.unelgee0ListTask.reduce((a, b) => a + b, 0);
      this.unelgee0AvgRep = (
        this.unelgee0SumTask / this.unelgee0ListTask.length
      ).toFixed();
    },

    changeTeacherListWithTeam: function (e) {
      this.filterForm.department_id = e.target.value;
      this.filterForm
        .get("/task_manager/getTeacherList/" + this.filterForm.department_id)
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherListWithTeam: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      this.formAddTeacher
        .get(
          "/task_manager/getTeacherList/" + this.formAddTeacher.department_id
        )
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    getAllTask() {
      axios
        .post(`/task_manager/monthPlanTask`, {
          year_id: this.year_id != null ? this.year_id.id : this.year_id,
          selectedMonthTask: this.selectedMonths,
          monthPlanId: this.monthPlanIdTask,
        })
        .then((response) => {
          this.filterFormFoTask.department_id = 0;
          this.allTask = response.data.monthPlanTask;
          this.allTaskReplied = response.data.monthPlanTaskReplied;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.teachersForTask = response.data.teachers;
          this.averageCheck(this.allTask);
          this.averageCheckReplied(this.allTaskReplied);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    changeTeacherListForTask: function (e) {
      this.filterFormFoTask.department_id = e.target.value;
      this.filterFormFoTask
        .get(
          "/task_manager/getTeacherList/" + this.filterFormFoTask.department_id
        )
        .then((response) => {
          this.teachersForTask = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    showTask(task) {
      axios
        .get("/task_manager/showTask/" + task.task_id)
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.showTaskInfo.reset();
          this.showTaskInfo.fill(task);
          this.showMode = false;
          this.modalTeachers = this.teachersForTask;
          this.formAddTeacherTask.reset();
          this.$bvModal.show("modal-taskInfo");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelTaskInfo() {
      this.showMode = !this.showMode;
      this.showTaskInfo.reset();
      this.$bvModal.hide("modal-taskInfo");
    },
    // showTaskView(task) {
    //   this.showTaskData = task;
    //   this.$bvModal.show("modal-view-task");
    // },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    //#endregion
  },
  created() {
    this.getAllRiskDatas();
    this.getRiskStat();
    Fire.$on("loadRisks", () => {
      this.getAllRiskDatas();
      this.$bvModal.hide("modal-add");
    });
    Fire.$on("getMonthPlans", () => {
      this.getRiskMonthPlans();
    });
    Fire.$on("getTasks", () => {
      this.getAllTask();
    });
  },
};
</script>

<style lang="scss" scoped>
.b-icon,
.badge {
  cursor: pointer !important;
}

.list-group-item {
  padding: 0.2rem 1rem;
}

#strategyCard {
  margin-left: 23%;
}

#measureCard {
  margin-left: 30%;
}

.two-columns-container {
  display: flex;
  /* Use Flexbox to create a two-column layout */
  gap: 10px;
  /* Add horizontal spacing between columns */
  flex-wrap: nowrap;
  /* Prevent columns from wrapping to the next line */
  justify-content: space-between;
  /* Distribute columns evenly */
}

b-form-textarea {
  flex: 1;
  /* Each textarea takes up equal horizontal space */
}
</style>
