<template>
    <div class="pt-3">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Бүртгэлтэй ажлын байр</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4">
                    <div class="row">
                        <div class="col-sm-8">
                            <table class="table table-bordered table-hover table-striped dataTable dtr-inline"
                                role="grid">
                                <thead>
                                    <tr role="row">
                                        <th>№</th>
                                        <th>Ажлын байрны нэр</th>
                                        <th style="width: 40px">Заах боломжтой хичээл</th>
                                        <th>Үүсгэсэн</th>
                                        <th>Зассан</th>
                                        <th>Үйлдлүүд</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd" v-for="(position, index) in positions" :key="position.id">
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            {{ position.position_name }}
                                        </td>
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            <span v-if="position.my_position_subjects_info.length != 0">
                                                <b-badge variant="info"
                                                    v-for="(info, ind) in position.my_position_subjects_info"
                                                    :key="ind">
                                                    <span v-if="info.my_subject_info !== null">
                                                        {{
                                        info.my_subject_info.subject_name }},
                                                    </span>
                                                    <span v-else>Хичээл бүртгэгдээгүй</span>
                                                </b-badge>
                                            </span>
                                            <b-badge variant="warning" v-else>Хичээл бүртгэгдээгүй.</b-badge>
                                        </td>

                                        <td class="dtr-control sorting_1" tabindex="0">
                                            {{
                                        position.created_at
                                        | dateFullYear
                                    }}
                                        </td>
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            {{
                                            position.updated_at
                                            | dateFullYear
                                        }}
                                        </td>
                                        <td class="dtr-control sorting_1" tabindex="0">
                                            <!-- <a
                      href="#"
                      class="badge bg-purple"
                      @click="viewPosition(position)"
                      ><i class="far fa-eye"></i
                    ></a> -->
                                            <a href="#" class="badge bg-warning" @click="editPosition(position)"><i
                                                    class="fas fa-edit"></i></a>
                                            <a v-if="userInfo.name == 'cipalprin'
                                        " href="#" class="badge bg-danger" @click="
                                        deletePosition(position)
                                        "><i class="fas fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-4">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title" v-show="!editMode">
                                        Ажлын байр бүртгэх
                                    </h3>
                                    <h3 class="card-title" v-show="editMode">
                                        Ажлын байр засах
                                    </h3>
                                </div>
                                <!-- /.card-header -->
                                <!-- form start -->
                                <form @submit.prevent="
                                        !editMode
                                            ? createPosition()
                                            : updatePosition()
                                        ">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Ажлын байрны нэр</label>
                                            <input type="text" name="position_name" v-model="form.position_name" :class="[
                                        'form-control',
                                        {
                                            'is-invalid': form.errors.has(
                                                'position_name'
                                            )
                                        }
                                    ]" placeholder="Ажлын байрны нэр" />
                                            <has-error :form="form" field="position_name"></has-error>
                                        </div>
                                        <div class="form-group">
                                            <label>Тухайн ажлын байр заах боломжтой хичээлүүдийг сонгох</label>
                                            <multiselect v-model="form.subject_id" :options="subjects" :multiple="true"
                                                :custom-label="customSubject" track-by="id" label="id"
                                                :searchable="true" :close-on-select="false" :show-labels="true"
                                                placeholder="Хамрах хичээлүүдийг сонгоно уу!" :allow-empty="false"
                                                deselect-label="Хасах" select-label="Сонгох">
                                                <template slot="singleLabel" slot-scope="{ option }"><strong> {{
                                        option.subject_name }}({{ option.department.department_code
                                                        }})</strong><strong>
                                                        сонгогдлоо</strong></template>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <!-- /.card-body -->
                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary" v-show="!editMode">
                                            Хадгалах
                                        </button>
                                        <button type="submit" class="btn btn-primary" v-show="editMode">
                                            Засах
                                        </button>
                                        <button class="btn btn-warning" v-show="editMode" @click.prevent="cancelEdit">
                                            Болих
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            action: "",
            editMode: false,
            positions: [],
            subjects: [],
            form: new Form({
                id: "",
                school_id: "",
                position_name: "",
                subject_id: []
            })
        };
    },
    components: {
        Multiselect,
    },
    computed: {
        userInfo() {
            return this.$store.state.userRoleInfo;
            //   .filter((todo) => todo.done).length;
        }
    },
    methods: {
        getAllPositions() {
            axios
                .get("/hr/getAllPositions")
                .then(response => {
                    // console.log(response);
                    this.positions = response.data.positions;
                    // this.positions.forEach(element => {
                    //     element.my_position_subjects_info.forEach(element1 => {
                    //         // console.log(element1.my_subject_info)
                    //         if (element1.my_subject_info == null) {
                    //             console.log(element1)
                    //         }
                    //     });
                    // });
                    this.subjects = response.data.subjects;
                })
                .catch(error => {
                    // console.log(error);
                });
        },
        viewPosition(position) {
            // console.log("Дэлгэрэнгүй мэдээлэл харах" + position);
        },
        editPosition(position) {
            // console.log(position);

            this.form.reset();
            this.form.fill(position);
            let a = []
            if (position.my_position_subjects_info) {
                position.my_position_subjects_info.forEach(element => {
                    this.subjects.forEach(el => {
                        if (element.subject_id == el.id) {
                            a.push(el);
                        }
                    });
                });
                this.form.subject_id = a;
            }
            this.editMode = true;
        },
        updatePosition() {
            this.action = "Ажлын байрны мэдээллийг шинэчилж байна...";
            this.form
                .put("/hr/position/" + this.form.id)
                .then(response => {
                    this.$toastr.s(
                        "Ажлын байрны мэдээллийг амжилттай шинэчиллээ.",
                        "Шинэчилсэн"
                    );
                    Fire.$emit("loadPositions");
                    this.form.reset();
                    this.editMode = !this.editMode;
                })
                .catch();
        },
        cancelEdit() {
            this.form.reset();
            this.editMode = !this.editMode;
        },
        deletePosition(position) {
            // console.log("Дэлгэрэнгүй мэдээлэл устгах" + position);

            Swal.fire({
                title: "Та итгэлтэй байна уу?",
                text:
                    "Устгасан тохилдолд тухайн сургуультай хамаарал бүхий албан тушаал системээс устах болно!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Тийм, үүнийг устга!",
                cancelButtonText: "Үгүй, цуцла!"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/hr/position/" + position.id)
                        .then(res => {
                            // console.log(res.data);
                            Fire.$emit("loadPositions");
                        })
                        .catch(err => { });
                }
            });
        },
        createPosition() {
            this.form
                .post("/hr/position")
                .then(response => {
                    this.$toastr.s(
                        "Ажлын байрыг амжилттай үүсгэлээ.",
                        "Амжилттай"
                    );
                    Fire.$emit("loadPositions");
                    this.form.reset();
                })
                .catch(() => {
                    this.$toastr.e(
                        "Ажлын байрыг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },
        customSubject({ subject_name, department }) {
            return `${subject_name} (${department.department_code})`;
        },
    },
    created() {
        this.getAllPositions();
        Fire.$on("loadPositions", () => {
            this.getAllPositions();
        });
    }
};
</script>

<style></style>
