<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <nav class="navbar navbar-expand-lg navbar-light bg-light m-0 py-0">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <div class="mode-container">
                    <span
                      @click="switchMode(mode)"
                      ref="ModeInfo"
                      class="mode-info"
                      :class="{ 'dark-mode': modestyle }"
                    >
                      <i :class="modesrc" />
                      <strong>{{ mode }}</strong>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="card-header" v-show="mode == 'ХӨГЖҮҮЛЭХ ХИЧЭЭЛ'">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedQuarter"
                    :options="quarterOptions"
                    text-field="name"
                    value-field="quarter_id"
                    placeholder="Улирал"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedQuarter'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedStatus"
                    :options="statusOptions"
                    text-field="text"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedStatus'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedTime"
                    :options="timeFilterOptions"
                    text-field="text"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedTime'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedDay"
                    :options="dayOptions"
                    text-field="text"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedDay'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="3">
                  <b-form-select
                    v-model="selectedDep"
                    :options="depOptions"
                    text-field="department_name"
                    value-field="id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedDep'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="primary" @click.prevent="getAllProject()"
                    >Шүүж харах</b-button
                  >
                </b-col>
                <b-col sm="2"
                  ><b-button
                    class="float-right"
                    variant="success"
                    @click.prevent="addProject()"
                    >Хөгжүүлэх хичээл нэмэх</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-header" v-show="mode == 'СУРАЛЦАГЧ'">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedQuarter"
                    :options="quarterOptions"
                    text-field="name"
                    value-field="quarter_id"
                    placeholder="Улирал"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedQuarter'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="1">
                  <b-form-select
                    v-model="selectedClass"
                    :options="classOptions"
                    text-field="full_name"
                    value-field="id"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedClass'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="10"
                  ><b-button variant="primary" @click.prevent="getClassStudent()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="devIsLoaded" rounded="sm">
            <table class="table table-hover" v-show="mode == 'ХӨГЖҮҮЛЭХ ХИЧЭЭЛ'">
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Үйлдэл</th>
                  <th>Төлөв</th>
                  <th>Хугацаа</th>
                  <th>Нэр</th>
                  <th>Тайлбар</th>
                  <th>Анги</th>
                  <th>Хамрагдах</th>
                  <th>Хуваарь 1</th>
                  <th>Хуваарь 2</th>
                  <th>Бүртгэл дуусах</th>
                  <th>Эхлэх</th>
                  <th>Дуусах</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody v-for="(project, index) in allProject" :key="project.project_id">
                <tr :class="{ 'table-warning': project.isMyDepEmp }">
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="1">
                    <b-dropdown variant="light" size="sm">
                      <template #button-content>
                        <i class="fa fa-tasks"></i> Тохиргоо
                      </template>
                      <b-dropdown-item-button
                        size="sm"
                        @click="showPlan(project)"
                        variant="dark"
                      >
                        <b-icon icon="clock-history" aria-hidden="true"></b-icon>
                        Төлөвлөгөө
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        size="sm"
                        v-show="
                          project.status === 'registered' && project.isMyDepEmp == true
                        "
                        @click="editStatus(project)"
                        variant="success"
                      >
                        <i class="fas fa-check"></i>
                        Төлөв засах
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        size="sm"
                        @click="showBudgets(project)"
                        variant="warning"
                      >
                        <i class="fas fa-coins"></i>
                        Төсвийн мэдээлэл
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        size="sm"
                        @click="showProject(project)"
                        variant="info"
                      >
                        <i class="fa fa-chalkboard-teacher"></i>
                        Багш нар
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        size="sm"
                        @click="showStudents(project)"
                        variant="dark"
                      >
                        <i class="fas fa-user-graduate"></i>
                        Суралцагч
                      </b-dropdown-item-button>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button
                        v-show="project.isMyDepEmp == true"
                        variant="primary"
                        size="sm"
                        @click="editProject(project)"
                      >
                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                        Засах</b-dropdown-item-button
                      >
                      <b-dropdown-item-button
                        v-show="
                          project.status === 'registered' &&
                          project.owner_id == currentUserId
                        "
                        size="sm"
                        @click="deleteProject(project)"
                        variant="danger"
                      >
                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                        Устгах
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="2">
                    <span
                      v-show="project.status === 'registered'"
                      class="badge bg-warning"
                      >БҮРТГЭГДСЭН</span
                    >
                    <span v-show="project.status === 'check'" class="badge bg-danger"
                      >ИДЭВХГҮЙ</span
                    >
                    <span v-show="project.status === 'active'" class="badge bg-primary"
                      >ИДЭВХТЭЙ</span
                    >
                    <span v-show="project.status === 'end'" class="badge bg-success"
                      >ДУУССАН</span
                    >
                  </td>
                  <td class="dtr-control sorting_1" tabindex="4">
                    {{ project.time_type }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="5">
                    {{ project.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    style="white-space: pre-wrap"
                  >
                    <b-badge class="text-wrap text-left" variant="light">{{
                      project.description.slice(0, 40)
                    }}</b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="7">
                    <span v-for="scope in project.scope.split('-')" :key="scope.id">
                      <b-badge
                        v-if="scope"
                        class="text-wrap text-left mr-2"
                        variant="secondary"
                      >
                        {{ scope }}-р анги:
                        <b-badge
                          pill
                          v-if="scope"
                          class="text-wrap text-left"
                          variant="warning"
                        >
                          {{ project.class_limit.split("-")[scope] }}
                        </b-badge>
                      </b-badge>
                    </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="10">
                    {{ project.limit }}/{{ project.registered }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="10">
                    Өдөр:{{ project.day }}, Цаг:{{ project.startTime }} -
                    {{ project.endTime }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="10">
                    <span v-show="project.isTwo == 1">
                      Өдөр: {{ project.day2 }}, Цаг: {{ project.startTime2 }} -
                      {{ project.endTime2 }}
                      <br />
                    </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="11">
                    {{ project.reg_close_date }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="8">
                    <span v-if="project.time_type != 'Тогтмол'">
                      {{ project.start_date | dateYearMonthDay }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="9">
                    <span v-if="project.time_type != 'Тогтмол'">
                      {{ project.end_date | dateYearMonthDay }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="12">
                    {{ project.created_at | dateYearMonthDay }}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="odd">
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    colspan="10"
                    align="right"
                  >
                    Нийт суралцагч:
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <strong>{{ totalLimit }} / {{ totalRegistered }}</strong>
                  </td>
                </tr>
                <tr class="odd">
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    colspan="10"
                    align="right"
                  >
                    Нийт хичээл:
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <strong>{{ niit }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
          <b-card
            v-show="mode == 'СУРАЛЦАГЧ'"
            bg-variant="dark"
            text-variant="white"
            title="Ангийн хамрагдах боломжтой хөгжүүлэх хичээлүүд"
          >
            <b-card-text>
              <b-badge
                pill
                v-for="project in classProjects"
                :key="project.id"
                variant="warning"
                class="mr-1"
                >{{ project.name }}
                <b-badge pill variant="dark"
                  >{{ project.limit }} / {{ project.registered }}</b-badge
                >
              </b-badge>
            </b-card-text>
          </b-card>
          <table class="table text-nowrap" v-show="mode == 'СУРАЛЦАГЧ'">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Зураг</th>
                <th>Суралцагч</th>
                <th>Хөгжүүлэх хичээл</th>
              </tr>
            </thead>
            <tbody
              v-for="(myClassProject, index) in selectedClassProjects"
              :key="myClassProject.id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0" width="50px">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0" width="80px">
                  <a href="#">
                    <img
                      v-if="myClassProject.student_profile_photo_url != null"
                      :src="myClassProject.student_profile_photo_url"
                      alt="user-avatar"
                      class="direct-chat-img"
                    />
                    <img
                      v-else
                      src="/images/users/user.png"
                      alt="user-avatar"
                      class="direct-chat-img"
                    />
                  </a>
                </td>
                <td class="dtr-control sorting_1" tabindex="4" width="150px">
                  <span
                    v-if="myClassProject.projects.length == 0"
                    class="text-danger"
                    v-b-tooltip.hover.topright
                    title="Энэ суралцагч ямар ч хөгжүүлэх хичээл сонгоогүй байна!"
                    ><i class="fa fa-exclamation-circle" color="danger"> </i>
                    {{ myClassProject.name }}
                  </span>
                  <span v-else>{{ myClassProject.name }}</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="5">
                  <b-badge
                    v-for="project in myClassProject.projects"
                    :key="project.id"
                    variant="primary"
                    class="mr-1"
                    >{{ project.name }}</b-badge
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="odd">
                <td class="dtr-control sorting_1" tabindex="0" align="right">
                  БҮРТГҮҮЛСЭН БАЙДАЛ:
                </td>
                <td class="dtr-control sorting_1" tabindex="0" colspan="3">
                  <b-progress class="mt-2" max="100" show-value show-progress animated>
                    <b-progress-bar
                      :value="class0DevPercent"
                      :label="`${class0DevPercent}%`"
                      variant="danger"
                    ></b-progress-bar>
                    <b-progress-bar
                      :value="class1DevPercent"
                      :label="`${class1DevPercent}%`"
                      variant="warning"
                    ></b-progress-bar>
                    <b-progress-bar
                      :value="class2DevPercent"
                      :label="`${class2DevPercent}%`"
                      variant="info"
                    ></b-progress-bar>
                    <b-progress-bar
                      :value="class3DevPercent"
                      :label="`${class3DevPercent}%`"
                      variant="primary"
                    ></b-progress-bar>
                    <b-progress-bar
                      :value="class4DevPercent"
                      :label="`${class4DevPercent}%`"
                      variant="success"
                    ></b-progress-bar>
                  </b-progress>
                </td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                ></td>
                <td class="dtr-control sorting_1" tabindex="0" colspan="3">
                  <b-badge pill variant="danger"
                    >ОГТ СОНГООГҮЙ: {{ class0DevCount }}</b-badge
                  >
                  <b-badge pill variant="warning"
                    >НЭГИЙГ СОНГОСОН: {{ class1DevCount }}</b-badge
                  >
                  <b-badge pill variant="info"
                    >ХОЁРЫГ СОНГОСОН: {{ class2DevCount }}</b-badge
                  >
                  <b-badge pill variant="primary"
                    >ГУРВЫГ СОНГОСОН: {{ class3DevCount }}</b-badge
                  >
                  <b-badge pill variant="success"
                    >ДӨРВИЙГ СОНГОСОН: {{ class4DevCount }}</b-badge
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Хичээл нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-project"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitle"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="!editProjectMode ? createProject() : updateProject()"
              >
                <div class="card-body">
                  <div class="form-row">
                    <div class="col-lg-12">
                      <b-alert show variant="light" class="groupForm">
                        ТӨСЛИЙН ТАНИЛЦУУЛГА
                      </b-alert>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Улирал</label>
                      <b-form-select
                        v-model="form.quarter_id"
                        :options="quarterOptions"
                        text-field="name"
                        value-field="quarter_id"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('quarter_id'),
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Давтамж</label>
                      <b-form-select
                        v-model="form.time_type"
                        :options="timeOptions"
                        text-field="name"
                        value-field="value"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('time_type'),
                            'is-invalid': imgError,
                          },
                        ]"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-lg-4">
                      <label for="name">Зураг</label>
                      <b-form-file
                        id=""
                        browse-text="Зураг сонгох"
                        accept="image/jpeg, image/png"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('image'),
                          },
                        ]"
                        @change="getProjectImg"
                      ></b-form-file>
                      <span>
                        {{ imgError }}
                      </span>
                    </div>
                    <div class="form-group col-lg-4">
                      <label for="name">Нэр</label>
                      <input
                        type="text"
                        name="name"
                        v-model="form.name"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('name'),
                          },
                        ]"
                        placeholder="Нэр"
                      />
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Бүртгэл хаагдах огноо</label>
                      <b-form-datepicker
                        v-model="form.reg_close_date"
                        class="mb-2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('reg_close_date'),
                            'is-invalid': errCloseDate,
                          },
                        ]"
                        placeholder="Бүртгэл хаагдах огноо"
                      ></b-form-datepicker>
                      <span>
                        {{ errCloseDate }}
                      </span>
                    </div>
                    <div v-show="form.time_type != 'Тогтмол'" class="col-lg-8">
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label>Эхлэх хугацаа</label>
                          <b-form-datepicker
                            v-model="form.start_date"
                            class="mb-2"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('start_date'),
                              },
                            ]"
                            placeholder="Эхлэх хугацаа"
                          ></b-form-datepicker>
                        </div>
                        <div class="form-group col-lg-6">
                          <label>Дуусах хугацаа</label>
                          <b-form-datepicker
                            v-model="form.end_date"
                            class="mb-2"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('end_date'),
                              },
                            ]"
                            placeholder="Дуусах хугацаа"
                          ></b-form-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Хичээллэх өдөр</label>
                      <input
                        type="number"
                        name="day"
                        max="5"
                        min="1"
                        v-model="form.day"
                        aria-describedby="input-live-help input-live-feedback"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('day'),
                            'is-invalid': dayState,
                          },
                        ]"
                        placeholder="Өдөр"
                        trim
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        1-5 гэсэн утга оруулна.
                      </b-form-invalid-feedback>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Эхлэх цаг</label>
                      <b-form-timepicker
                        v-model="form.startTime"
                        v-bind="labels['de']"
                        locale="de"
                      ></b-form-timepicker>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Дуусах цаг</label>
                      <b-form-timepicker
                        v-model="form.endTime"
                        v-bind="labels['de']"
                        locale="de"
                      ></b-form-timepicker>
                    </div>
                    <div class="form-group col-lg-12" v-show="form.project_type != 'СХА'">
                      <b-form-checkbox
                        id="isTwo"
                        v-model="form.isTwo"
                        name="isTwo"
                        value="1"
                        unchecked-value="0"
                      >
                        2 өдөр хуваагдаж ордог бол сонгох
                      </b-form-checkbox>
                      <has-error :form="form" field="form.isTwo"></has-error>
                    </div>
                    <div v-show="form.isTwo == 1" class="col-lg-6">
                      <div class="row">
                        <div class="form-group col-lg-4">
                          <label>Өдөр - 2</label>
                          <input
                            type="number"
                            v-model="form.day2"
                            aria-describedby="input-live-help input-live-feedback"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': day2State,
                              },
                            ]"
                            placeholder="Өдөр - 2"
                            trim
                          />
                          <b-form-invalid-feedback id="input-live-feedback">
                            1-5 гэсэн утга оруулна.
                          </b-form-invalid-feedback>
                        </div>
                        <div class="form-group col-lg-4">
                          <label>Эхлэх цаг - 2</label>
                          <b-form-timepicker
                            v-model="form.startTime2"
                            v-bind="labels['de']"
                            locale="de"
                          ></b-form-timepicker>
                        </div>
                        <div class="form-group col-lg-4">
                          <label>Дуусах цаг - 2</label>
                          <b-form-timepicker
                            v-model="form.endTime2"
                            v-bind="labels['de']"
                            locale="de"
                          ></b-form-timepicker>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-10">
                      <label>Хамрах хүрээ</label>
                      <multiselect
                        v-model="form.scopeList"
                        :options="scopeOptions"
                        :multiple="true"
                        track-by="name"
                        label="text"
                        :searchable="true"
                        :close-on-select="false"
                        :show-labels="true"
                        placeholder="Хамрах анги сонгох"
                        :allow-empty="false"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong> {{ option.text }}</strong
                          ><strong> сонгогдлоо</strong></template
                        >
                      </multiselect>
                    </div>
                    <div class="form-group col-lg-2">
                      <label>Нийт хамрагдах</label>
                      <input
                        type="number"
                        name="limit"
                        v-model="form.limit"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('limit'),
                          },
                        ]"
                        placeholder="Тоо"
                      />
                    </div>
                    <b-card
                      border-variant="secondary"
                      header-bg-variant="secondary"
                      header-text-variant="white"
                      header="Анги бүрийн хамрагдах тоо"
                      align="center"
                      class="col-lg-12"
                    >
                      <b-card-text>
                        <div class="row">
                          <div
                            class="col-lg-2"
                            v-for="scope in form.scopeList"
                            :key="scope.name"
                          >
                            <label>{{ scope.text }}</label>
                            <input
                              type="number"
                              :name="form['classLimit' + scope.name]"
                              v-model="form['classLimit' + scope.name]"
                              :class="[
                                'form-control',
                                {
                                  'is-invalid': form.errors.has(
                                    'classLimit' + scope.name
                                  ),
                                },
                              ]"
                              placeholder="Тоо"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <small class="col-lg-12 text-danger"
                            >Ангиудаа сонгоод хамрагдах хүүхдийн тоог оруулна уу!</small
                          >
                        </div>
                      </b-card-text>
                    </b-card>
                    <div class="form-group col-lg-12">
                      <label>Тайлбар</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.description"
                        placeholder="Төслийн тухай тайлбар"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('description'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="col-lg-12">
                      <b-alert show variant="light" class="groupForm">
                        ТӨСЛИЙН ТАНИЛЦУУЛГА ЗОРИЛГО
                        <hr class="m-0" />
                        <p class="mb-0">
                          Төслийн танилцуулга, хүлээгдэж буй үр дүн эрэлт хэрэгцээ, ач
                          холбогдол, үнэ цэнийг нь үнэлэх зорилго.
                        </p>
                      </b-alert>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Юу мэддэг болох вэ?</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.what_know"
                        placeholder="Юу мэддэг болох вэ?"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('what_know'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Юу ойлгох вэ?</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.what_understand"
                        placeholder="Юу ойлгох вэ?"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('what_understand'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Юу чаддаг болох вэ?</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.what_can"
                        placeholder="Юу чаддаг болох вэ?"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('what_can'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="col-lg-12">
                      <b-alert show variant="light" class="groupForm">
                        Урьдчилсан судалгаа
                      </b-alert>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Хэрэглэгдэх зүйлсийн жагсаалт</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.tools"
                        placeholder="Хэрэглэгдэх зүйлсийн жагсаалт"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('tools'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Хөдөлмөр аюулгүй байдал</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.safety"
                        placeholder="Хөдөлмөр аюулгүй байдал"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('safety'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group col-lg-4">
                      <label>Судлагдахууны талаарх мэдээлэл</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.info"
                        placeholder="Судлагдахууны талаарх мэдээлэл"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('info'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                    <div class="col-lg-12" v-show="!editProjectMode">
                      <b-alert show variant="light" class="groupForm">
                        СУРАГЧДАД ӨГӨХ ИЖИЛ ТӨРЛИЙН ДААЛГАВАР, ШИНЭ САНАА
                      </b-alert>
                    </div>
                    <div class="form-group col-lg-4" v-show="!editProjectMode">
                      <label for="name">Даалгаврын зураг</label>
                      <b-form-file
                        id="task_img"
                        browse-text="Зураг сонгох"
                        accept="image/jpeg, image/png"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('task_img'),
                          },
                        ]"
                        @change="getTaskImg"
                      ></b-form-file>
                      <span class="text-red">{{ taskImgError }}</span>
                    </div>
                    <div class="form-group col-lg-8" v-show="!editProjectMode">
                      <label>Даалгаврын тайлбар</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.task_text"
                        placeholder="Тайлбар"
                        rows="3"
                        max-rows="6"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': form.errors.has('task_text'),
                          },
                        ]"
                      ></b-form-textarea>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary" v-show="!editProjectMode">
                    Хадгалах
                  </button>
                  <button type="submit" class="btn btn-primary" v-show="editProjectMode">
                    Засах
                  </button>
                  <button class="btn btn-secondary" @click.prevent="cancelProject">
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Багш нэмэх хэсэг -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-projectInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Багш нарын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төслийн багш нар</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="projectMentors != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Зураг</th>
                      <th>Үүрэг</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectMentor, index) in projectMentors"
                    :key="projectMentor.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="projectMentor.profile_photo_url != null"
                          :src="projectMentor.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span
                          v-show="projectMentor.mentor_type === 'member'"
                          class="badge bg-dark"
                          >ГИШҮҮН</span
                        >
                        <span
                          v-show="projectMentor.mentor_type === 'owner'"
                          class="badge bg-success"
                          >ЭЗЭМШИГЧ</span
                        >
                        <span
                          v-show="projectMentor.mentor_type === 'senior'"
                          class="badge bg-primary"
                          >АХЛАГЧ</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectMentor.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectMentor.teacher_lname[0] }}.
                        {{ projectMentor.teacher_fname }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          v-show="projectMentor.mentor_type != 'owner'"
                          href="#"
                          class="badge bg-primary"
                          @click="editMentor(projectMentor)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          v-show="projectMentor.mentor_type != 'owner'"
                          href="#"
                          class="badge bg-danger"
                          @click="removeMentor(projectMentor)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">Багш нэмэх</h3>
                <h3 class="card-title" v-show="editMode">Багш засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)" v-show="!editMode">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formMentor.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group" v-show="!editMode">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formMentor.mentors"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="10"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Гишүүний төрөл</label>
                    <b-form-select
                      v-model="formMentor.mentor_type"
                      :options="mentorTypes"
                      text-field="name"
                      value-field="value"
                    ></b-form-select>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addMentor()"
                  v-show="!editMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateMentor()"
                  v-show="editMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditMentor()"
                  v-show="editMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Суралцагч нэмэх хэсэг -->
    <b-modal
      v-show="showStudentMode"
      size="xl"
      id="modal-project-student"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчдын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Суралцагч нар</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Бүртгүүлсэн: </b> {{ form.limit }} / {{ form.registered }}</span
                >
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="projectStudents != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Зураг</th>
                      <th>Анги</th>
                      <th>Суралцагч</th>
                      <th>Гүйцэтгэл</th>
                      <th>Тайлбар</th>
                      <th>Зургийн сан</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectStudent, index) in projectStudents"
                    :key="projectStudent.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="projectStudent.profile_photo_url != null"
                          :src="projectStudent.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectStudent.full_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectStudent.student_lname[0] }}.
                        {{ projectStudent.student_fname }}
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        style="text-align: center"
                        tabindex="0"
                      >
                        <b-badge
                          v-if="projectStudent.score >= 80"
                          variant="success"
                          v-show="projectStudent.score != null"
                        >
                          {{ projectStudent.score }}</b-badge
                        >
                        <b-badge
                          v-if="
                            (projectStudent.score >= 60) & (projectStudent.score < 80)
                          "
                          variant="primary"
                          v-show="projectStudent.score != null"
                        >
                          {{ projectStudent.score }}</b-badge
                        >
                        <b-badge
                          v-if="
                            (projectStudent.score >= 30) & (projectStudent.score < 60)
                          "
                          variant="warning"
                          v-show="projectStudent.score != null"
                        >
                          {{ projectStudent.score }}</b-badge
                        >
                        <b-badge
                          v-if="projectStudent.score < 30"
                          variant="danger"
                          v-show="projectStudent.score != null"
                        >
                          {{ projectStudent.score }}</b-badge
                        >
                        <b-badge variant="warning" v-show="projectStudent.score === null">
                          ОРООГҮЙ</b-badge
                        >
                        <div class="progress progress-xs progress-striped active">
                          <div
                            v-if="projectStudent.score < 30"
                            class="progress-bar bg-danger"
                            :style="{ width: `${projectStudent.score}%` }"
                          ></div>
                          <div
                            v-if="
                              (projectStudent.score >= 30) & (projectStudent.score < 60)
                            "
                            class="progress-bar bg-warning"
                            :style="{ width: `${projectStudent.score}%` }"
                          ></div>
                          <div
                            v-if="
                              (projectStudent.score >= 60) & (projectStudent.score < 80)
                            "
                            class="progress-bar bg-primary"
                            :style="{ width: `${projectStudent.score}%` }"
                          ></div>
                          <div
                            v-if="projectStudent.score >= 80"
                            class="progress-bar bg-success"
                            :style="{ width: `${projectStudent.score}%` }"
                          ></div>
                        </div>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectStudent.description }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-badge
                          v-for="img in projectStudent.images"
                          :key="img.id"
                          variant="light"
                          class="mr-1 mb-1"
                        >
                          <b-avatar
                            variant="light"
                            :src="img.image"
                            size="40"
                            square
                            v-b-tooltip.hover.html="
                              '<img src=' +
                              img.image +
                              ' style=' +
                              'width:180px!important>'
                            "
                          >
                          </b-avatar>
                          <b-badge
                            @click.prevent="deleteImage(img)"
                            variant="danger"
                            title="Зураг устгах"
                            role="button"
                            ><b-icon icon="x"></b-icon
                          ></b-badge>
                        </b-badge>
                        <b-button
                          size="sm"
                          class="mr-2"
                          href="#"
                          variant="success"
                          @click.prevent="showImageUploader(projectStudent)"
                        >
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <b-button
                          size="sm"
                          class="mt-2 mr-2"
                          href="#"
                          variant="outline-success"
                          @click.prevent="showComment(projectStudent)"
                          >Эсээ харах</b-button
                        >
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editStudent(projectStudent)"
                          ><i class="fa fa-check"></i
                        ></a>
                        <a
                          v-show="form.status === 'active'"
                          href="#"
                          class="badge bg-danger"
                          @click="removeStudent(projectStudent)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editStudentMode">Суралцагч нэмэх</h3>
                <h3 class="card-title" v-show="editStudentMode">Суралцагч засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form
                  v-on:change="modalChangeStudentList($event)"
                  v-show="!editStudentMode"
                >
                  <div class="form-group">
                    <label>Анги</label>
                    <b-form-select
                      v-model="formStudent.class_id"
                      :options="schoolClasses"
                      text-field="full_name"
                      value-field="id"
                    ></b-form-select>
                  </div>
                </form>
                <form>
                  <div class="form-group" v-show="!editStudentMode">
                    <label>Суралцагч</label>
                    <b-form-select
                      v-model="formStudent.students"
                      :options="modalStudents"
                      text-field="student_full_name"
                      value-field="student_user_id"
                      multiple
                      :select-size="10"
                    ></b-form-select>
                    <has-error :form="formStudent" field="student_full_name"></has-error>
                  </div>
                  <div class="form-group" v-show="editStudentMode">
                    <label>Гүйцэтгэл</label>
                    <input
                      type="number"
                      name="score"
                      max="100"
                      v-model="formStudent.score"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formStudent.errors.has('score'),
                        },
                      ]"
                      placeholder="Гүйцэтгэлийн оноо %-аар тавих"
                    />
                  </div>
                  <div class="form-group" v-show="editStudentMode">
                    <b-form-textarea
                      id="textarea"
                      v-model="formStudent.description"
                      placeholder="Гүйцэтгэлийн талаарх тайлбар оруулна уу."
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formStudent.errors.has('description'),
                        },
                      ]"
                    ></b-form-textarea>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addStudent()"
                  v-show="!editStudentMode"
                  :disabled="form.status == 'registered' ? true : false"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateStudent()"
                  v-show="editStudentMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditStudent()"
                  v-show="editStudentMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Зургийн сан -->
    <b-modal
      size="xs"
      id="modal-image"
      class="modal fade"
      title="Зураг нэмэх"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label>Зураг</label>
              <b-form-file
                v-model="imageData"
                id="image"
                browse-text="Зураг сонгох"
                :class="[
                  'form-control',
                  {
                    'is-invalid': imgError,
                  },
                ]"
                @change="setImg"
                accept="image/jpeg, image/png"
              ></b-form-file>
              <span class="col-sm-5">
                {{ imgError }}
              </span>
            </div>
            <div v-if="formImage.image" class="form-group">
              <b-avatar variant="light" size="6rem" :src="formImage.image" square />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" @click.prevent="createImage()">
          Хадгалах
        </button>
        <button class="btn btn-secondary" @click.prevent="cancelUploadImg">Болих</button>
      </div>
    </b-modal>
    <!-- Төсөв нэмэх хэсэг -->
    <b-modal
      v-show="showBudgetMode"
      size="xl"
      id="modal-project-budget"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсвийн мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төсвийн жагсаалт</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Нийт: </b> {{ projectBudgets.length }}</span
                >
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="projectBudgets != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Үйлдэл</th>
                      <th>Төрөл</th>
                      <th>Нэр</th>
                      <th>Дүн</th>
                      <th>Баталсан</th>
                      <th>Ширхэг</th>
                      <th>Нэгжийн үнэ</th>
                      <th>Нийт</th>
                      <th>Зөрүү</th>
                      <th>Нийлүүлэгч</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectBudget, index) in projectBudgets"
                    :key="projectBudget.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <div>
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editBudget(projectBudget)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="removeBudget(projectBudget)"
                            ><i class="fa fa-minus"></i
                          ></a>
                        </div>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.type_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.item_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.total }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.confirmed_total }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.pieces }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.price }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.price * projectBudget.pieces }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.total - projectBudget.confirmed_total }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.choose_1 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editBudgetMode">Төсөв нэмэх</h3>
                <h3 class="card-title" v-show="editBudgetMode">Төсөв засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div
                class="card-body"
                v-show="form.status == 'check' || form.status == 'registered'"
              >
                <form>
                  <div class="form-group">
                    <label>Төрөл</label>
                    <b-form-select
                      v-model="formBudget.budget_type_id"
                      v-on:change="getBudgetItemList"
                      :options="budgetTypes"
                      text-field="name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('budget_type_id'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Бүтээгдэхүүн</label>
                    <multiselect
                      v-model="formBudget.budget_item"
                      deselect-label="Хасах"
                      select-label="Сонгох"
                      track-by="id"
                      label="item_name"
                      placeholder="Байхгүй бол шинээр нэмнэ үү"
                      :options="budgetItems"
                      :searchable="true"
                      :allow-empty="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.item_name }} -
                        {{ option.item_description }}
                      </template>
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <b-button
                      mt="2"
                      variant="outline-success"
                      size="sm"
                      @click="itemAddChangeMode()"
                      >Бүтээгдэхүүн шинээр бүртгэх бол дарна уу?</b-button
                    >
                  </div>
                  <div v-show="itemAddMode">
                    <div class="form-group">
                      <multiselect
                        v-model="formItem.budget_type_id"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                        track-by="id"
                        label="name"
                        placeholder="Төсвийн ангилал сонгоно уу?"
                        :options="budgetTypes"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          >Ангилал: <strong>{{ option.name }}</strong>
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="item_name"
                        placeholder="Бүтээгдэхүүний нэр, Бүтээгдэхүүний жагсаалтанд байгаа нэртэй давхцах боломжгүйг анхаарна уу."
                        wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                        v-model="formItem.item_name"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control"
                        id="item_price"
                        placeholder="Бүтээгдэхүүний үнэ"
                        wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                        v-model="formItem.item_price"
                      />
                    </div>
                    <div class="form-group">
                      <b-form-textarea
                        id="textarea"
                        v-model="formItem.item_description"
                        placeholder="Бүтээгдэхүүнтэй холбоотой дэлгэрэнгүй мэдээлэл..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group">
                      <b-button
                        variant="outline-success"
                        size="sm"
                        @click.prevent="addItem()"
                        >Бүтээгдэхүүн бүртгэх</b-button
                      >
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        @click.prevent="cancelItem()"
                        >Цуцлах</b-button
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Ширхэг</label>
                    <input
                      type="number"
                      name="pieces"
                      v-model="formBudget.pieces"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('pieces'),
                        },
                      ]"
                      placeholder="Ширхэг"
                    />
                  </div>
                  <div class="form-group">
                    <label>Нэгжийн үнэ</label>
                    <input
                      type="number"
                      name="price"
                      v-model="formBudget.price"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('price'),
                        },
                      ]"
                      placeholder="Нэгжийн үнэ"
                    />
                  </div>
                  <div class="form-group">
                    <label>Нийт</label>
                    <input
                      type="number"
                      name="total"
                      v-text="totalUne"
                      disabled
                      v-model="formBudget.total"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('total'),
                        },
                      ]"
                      placeholder="Нийт"
                    />
                  </div>
                  <div class="form-group">
                    <label>Хэмжих нэгж</label>
                    <input
                      type="text"
                      name="measure_unit"
                      v-model="formBudget.measure_unit"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('measure_unit'),
                        },
                      ]"
                      placeholder="Ширхэг, л, мл, , мг, кг, тн, м, см, мм,... гэх мэт"
                    />
                  </div>
                  <div class="form-group">
                    <label>Зориулалт</label>
                    <input
                      type="text"
                      name="zoriulalt"
                      v-model="formBudget.zoriulalt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('zoriulalt'),
                        },
                      ]"
                      placeholder="Зориулалт"
                    />
                  </div>
                  <div class="form-group">
                    <b-form-checkbox
                      id="compare"
                      v-model="formBudget.compare"
                      name="compare"
                      value="1"
                      unchecked-value="0"
                    >
                      Харьцуулалт хийсэн эсэх
                    </b-form-checkbox>
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0">Сонголт</label>
                    <label v-else>Сонголт 1</label>
                    <input
                      type="text"
                      name="choose_1"
                      v-model="formBudget.choose_1"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('choose_1'),
                        },
                      ]"
                      placeholder="Сонголт"
                    />
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0">Сонголт холбоос</label>
                    <label v-else>Сонголт 1 холбоос</label>
                    <input
                      type="text"
                      name="choose_1_link"
                      v-model="formBudget.choose_1_link"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('choose_1_link'),
                        },
                      ]"
                      placeholder="Сонголт холбоос"
                    />
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0">Зураг</label>
                    <label v-else>Зураг 1</label>
                    <b-form-file
                      id=""
                      browse-text="Зураг сонгох"
                      accept="image/jpeg, image/png"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': imgError1,
                        },
                      ]"
                      @change="getBudgetImg1"
                    ></b-form-file>
                    <span>
                      {{ imgError1 }}
                    </span>
                  </div>
                  <div v-show="formBudget.compare == 1">
                    <div class="form-group">
                      <label>Сонголт 2</label>
                      <input
                        type="text"
                        name="choose_2"
                        v-model="formBudget.choose_2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_2'),
                          },
                        ]"
                        placeholder="Сонголт 2"
                      />
                    </div>
                    <div class="form-group">
                      <label>Сонголт 2 холбоос</label>
                      <input
                        type="text"
                        name="choose_2_link"
                        v-model="formBudget.choose_2_link"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_2_link'),
                          },
                        ]"
                        placeholder="Сонголт 2 холбоос"
                      />
                    </div>
                    <div class="form-group">
                      <label for="name">Зураг 2</label>
                      <b-form-file
                        id=""
                        browse-text="Зураг 2 сонгох"
                        accept="image/jpeg, image/png"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': imgError2,
                          },
                        ]"
                        @change="getBudgetImg2"
                      ></b-form-file>
                      <span>
                        {{ imgError2 }}
                      </span>
                    </div>
                    <div class="form-group">
                      <label>Сонголт 3</label>
                      <input
                        type="text"
                        name="choose_3"
                        v-model="formBudget.choose_3"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_3'),
                          },
                        ]"
                        placeholder="Сонголт 3"
                      />
                    </div>
                    <div class="form-group">
                      <label>Сонголт 3 холбоос</label>
                      <input
                        type="text"
                        name="choose_3_link"
                        v-model="formBudget.choose_3_link"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_3_link'),
                          },
                        ]"
                        placeholder="Сонголт 3 холбоос"
                      />
                    </div>
                    <div class="form-group">
                      <label for="name">Зураг 3</label>
                      <b-form-file
                        id=""
                        browse-text="Зураг 3 сонгох"
                        accept="image/jpeg, image/png"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': imgError3,
                          },
                        ]"
                        @change="getBudgetImg3"
                      ></b-form-file>
                      <span>
                        {{ imgError3 }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div
                class="card-footer"
                v-if="form.status == 'check' || form.status == 'registered'"
              >
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addBudget()"
                  v-show="!editBudgetMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateBudget()"
                  v-show="editBudgetMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditBudget()"
                  v-show="editBudgetMode"
                >
                  Болих
                </button>
              </div>
              <div class="card-footer" v-else>
                <span class="text-danger text-sm"
                  ><b>БҮРТГЭГДСЭН</b> болон <b>БУЦААГДСАН</b> төвөвтэй үед төсөв нэмэх
                  боломжтой!</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Идэвхжүүлэх хэсэг -->
    <b-modal
      v-show="showActiveMode"
      size="lg"
      id="modal-project-active"
      header-bg-variant="success"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсөл идэвхжүүлэх"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card card-primary">
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Төлөв</label>
                    <b-form-select
                      v-model="formActive.status"
                      :options="statusList"
                      text-field="label"
                      value-field="value"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formActive.errors.has('status'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Тайлбар</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="formActive.status_description"
                      placeholder="Тайлбар"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formActive.errors.has('status_description'),
                        },
                      ]"
                    ></b-form-textarea>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateStatus()"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditStatus()"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Төсөв нэмэх хэсэг -->
    <b-modal
      size="xl"
      id="modal-project-plan"
      :show="showPlanMode"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төлөвлөгөө"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Цагийн болон үйл ажиллагааны төлөвлөлт</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Нийт: </b> {{ projectPlans.length }}</span
                >
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="projectPlans != null">
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th v-show="ownerId == currentUserId">Үйлдэл</th>
                      <th>Үйл ажиллагааны нэр</th>
                      <th>Зарцуулах хугацаа</th>
                      <th>Тайлбар</th>
                      <th>Хариуцах эзэн</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectPlan, index) in projectPlans"
                    :key="projectPlan.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                        v-show="
                          ownerId == currentUserId &&
                          (form.status === 'registered' || form.status === 'check')
                        "
                      >
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editPlan(projectPlan)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removePlan(projectPlan)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectPlan.name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectPlan.time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectPlan.description }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectPlan.owner }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editPlanMode">Төлөвлөгөө нэмэх</h3>
                <h3 class="card-title" v-show="editPlanMode">Төлөвлөгөө засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Үйл ажиллагааны нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="formPlan.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formPlan.errors.has('name'),
                        },
                      ]"
                    />
                  </div>
                  <div class="form-group">
                    <label>Зарцуулах хугацаа</label>
                    <input
                      type="number"
                      name="time"
                      v-model="formPlan.time"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formPlan.errors.has('time'),
                        },
                      ]"
                    />
                  </div>
                  <div class="form-group">
                    <label>Тайлбар</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="formPlan.description"
                      placeholder="Тайлбар"
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formPlan.errors.has('description'),
                        },
                      ]"
                    ></b-form-textarea>
                  </div>
                  <div class="form-group">
                    <label>Хариуцах эзэн</label>
                    <b-form-select
                      v-model="formPlan.owner"
                      :options="ownerOptions"
                      text-field="text"
                      value-field="value"
                      placeholder="Хариуцах эзэн"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('formPlan.owner'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addPlan()"
                  v-show="!editPlanMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updatePlan()"
                  v-show="editPlanMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditPlan()"
                  v-show="editPlanMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ"
      hide-footer
    >
      <div class="modal-body">
        <div class="row" v-if="beforeComments.length > 0">
          <div class="col-lg-12">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card v-html="comm.comment" class="p-1 text-justify" />
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <p class="text-center text-muted h5 mt-5">
              <b-icon icon="inboxes"></b-icon>
            </p>
            <p class="text-center text-muted font-italic text-sm">
              Одоогоор суралцагч эсээ бичээгүй байна.
            </p>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  computed: {
    dayState() {
      return this.form.day > 5 ? true : false;
    },
    day2State() {
      return this.form.day2 > 5 ? true : false;
    },
    totalUne: function () {
      let totalDun;
      if (this.formBudget.pieces && this.formBudget.price) {
        totalDun = this.formBudget.pieces * this.formBudget.price;
        this.formBudget.total = totalDun;
        return totalDun;
      } else {
        this.form.total = 0;
        return 0;
      }
    },
  },
  data() {
    return {
      devIsLoaded: true,
      class0DevCount: 0,
      class1DevCount: 0,
      class2DevCount: 0,
      class3DevCount: 0,
      class4DevCount: 0,
      class0DevPercent: 0,
      class1DevPercent: 0,
      class2DevPercent: 0,
      class3DevPercent: 0,
      class4DevPercent: 0,
      mode: [],
      modesrc: [],
      modestyle: false,
      darkTheme: false,
      selectedClassProjects: [],
      classProjects: [],
      selectedClass: "",
      classOptions: [],
      selectedDep: 0,
      depOptions: [],
      itemAddMode: false,
      formItem: new Form({
        id: "",
        budget_item: "",
        budget_type_id: "",
        item_name: "",
        item_price: "",
        item_description: "",
      }),
      imgError: "",
      imgError1: "",
      imgError2: "",
      imgError3: "",
      taskImgError: "",
      locale: "de",
      labels: {
        de: {
          labelHours: "Цаг",
          labelMinutes: "Минут",
          labelSeconds: "Секунд",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Сонгосон",
          labelNoTimeSelected: "Цаг сонгох",
          labelCloseButton: "Хаах",
        },
      },
      niit: "",
      limitList: [],
      registeredList: [],
      totalLimit: "",
      totalRegistered: "",

      currentUserId: null,
      formModalTitle: "Хөгжүүлэх хичээл нэмэх",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allProject: [],
      projectMentors: [],
      showMode: false,
      editProjectMode: false,
      timeOptions: [
        { name: "Тогтмол", value: "Тогтмол" },
        { name: "Нэг удаа", value: "Нэг удаа" },
      ],
      scopeOptions: [
        { name: 0, text: "Бэлтгэл анги" },
        { name: 1, text: "1-р анги" },
        { name: 2, text: "2-р анги" },
        { name: 3, text: "3-р анги" },
        { name: 4, text: "4-р анги" },
        { name: 5, text: "5-р анги" },
        { name: 6, text: "6-р анги" },
        { name: 7, text: "7-р анги" },
        { name: 8, text: "8-р анги" },
        { name: 9, text: "9-р анги" },
        { name: 10, text: "10-р анги" },
        { name: 11, text: "11-р анги" },
        { name: 12, text: "12-р анги" },
      ],
      quarterOptions: [],
      selectedQuarter: 0,
      selectedDay: null,
      dayOptions: [
        { value: null, text: "Бүх өдөр" },
        { value: 1, text: "1 дэх өдөр" },
        { value: 2, text: "2 дахь өдөр" },
        { value: 3, text: "3 дахь өдөр" },
        { value: 4, text: "4 дэх өдөр" },
        { value: 5, text: "5 дахь өдөр" },
        { value: 6, text: "6 дахь өдөр" },
        { value: 7, text: "7 дахь өдөр" },
      ],
      selectedTime: null,
      timeFilterOptions: [
        { value: null, text: "Бүгд" },
        { value: "Тогтмол", text: "Тогтмол" },
        { value: "Нэг удаа", text: "Нэг удаа" },
      ],
      selectedStatus: null,
      statusOptions: [
        { value: null, text: "Бүх төлөв" },
        { value: "registered", text: "Бүртгэгдсэн" },
        { value: "active", text: "Идэвхтэй" },
        { value: "check", text: "Идэвхгүй" },
        { value: "end", text: "Дууссан" },
      ],
      ownerId: null,
      editMode: false,
      form: new Form({
        id: null,
        day: 1,
        startTime: "14:15",
        endTime: "14:55",
        isTwo: 0,
        day2: 0,
        startTime2: "",
        endTime2: "",
        status: "",
        project_type: "Хөгжүүлэх хичээл",
        time_type: "Тогтмол",
        quarter_id: 0,
        scopeList: [],
        name: "",
        image: "",
        projectImage: "",
        description: "",
        start_date: "",
        end_date: "",
        limit: "",
        registered: "",
        reg_close_date: "",
        created_at: "",
        classLimit1: 0,
        classLimit2: 0,
        classLimit3: 0,
        classLimit4: 0,
        classLimit5: 0,
        classLimit6: 0,
        classLimit7: 0,
        classLimit8: 0,
        classLimit9: 0,
        classLimit10: 0,
        classLimit11: 0,
        classLimit12: 0,
        what_know: "",
        what_understand: "",
        what_can: "",
        tools: "",
        safety: "",
        info: "",
        task_img: "",
        task_text: "",
      }),
      departments: [],
      modalTeachers: [],
      formMentor: new Form({
        id: null,
        mentor_type: "member",
        department_id: "",
        project_id: "",
        mentors: [],
      }),
      mentorTypes: [
        { name: "Гишүүн", value: "member" },
        { name: "Ахлах", value: "senior" },
      ],
      showStudentMode: false,
      editStudentMode: false,
      projectStudents: [],
      schoolClasses: [],
      modalStudents: [],
      formStudent: new Form({
        id: null,
        class_id: "",
        project_id: "",
        score: "",
        description: "",
        students: [],
      }),
      showBudgetMode: false,
      editBudgetMode: false,
      budgetTypes: [],
      budgetItems: [],
      projectBudgets: [],
      formBudget: new Form({
        id: null,
        budget_type_id: "",
        project_id: "",
        name: "",
        total: "",
        confirmed_total: "",
        pieces: "",
        price: "",
        supplier: "",
        measure_unit: "",
        budget_images_1: "",
        budget_images_2: "",
        budget_images_3: "",
        zoriulalt: "",
        compare: 0,
        choose_1: "",
        choose_1_link: "",
        choose_2: "",
        choose_2_link: "",
        choose_3: "",
        choose_3_link: "",
        zartsuulsan: "",
        zartsuulsan_tailbar: "",
      }),
      showActiveMode: false,
      statusList: [
        { label: "Идэвхитэй", value: "active" },
        { label: "Шалгах шаардлагатай", value: "check" },
      ],
      formActive: new Form({
        id: null,
        status: "",
        status_description: "",
      }),
      errCloseDate: "",
      currentQuarter: 0,
      projectPlans: [],
      showPlanMode: false,
      editPlanMode: false,
      ownerOptions: [
        { text: "Төслийн баг", value: "Төслийн баг" },
        {
          text: "Төслийн ахлагч болон гишүүд",
          value: "Төслийн ахлагч болон гишүүд",
        },
        { text: "Төслийн гишүүн бүр", value: "Төслийн гишүүн бүр" },
      ],
      formPlan: new Form({
        id: null,
        project_id: "",
        name: "",
        time: "",
        description: "",
        owner: "",
      }),
      beforeComments: [],
      imageData: null,
      imgError: "",
      formImage: new Form({
        model: "project",
        model_id: null,
        student_id: null,
        image: "",
      }),
    };
  },
  mounted() {
    this.mode = "ХӨГЖҮҮЛЭХ ХИЧЭЭЛ";
    this.modesrc = "fab fa-dev nav-icon";
  },
  components: {
    Multiselect,
  },
  methods: {
    getClassStudent() {
      if (this.selectedQuarter) {
        axios
          .post("/project_mentor/getClassProjects/" + this.selectedClass, {
            project_type: "Хөгжүүлэх хичээл",
            selectedQuarter: this.selectedQuarter,
          })
          .then((response) => {
            this.selectedClassProjects = response.data.students;
            this.classProjects = response.data.classProjects;
            this.percentClass(this.selectedClassProjects);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Swal.fire({
          title: "Улирал сонгоно уу",
          icon: "warning",
        });
      }
    },
    percentClass(classProject) {
      this.class0DevCount = 0;
      this.class1DevCount = 0;
      this.class2DevCount = 0;
      this.class3DevCount = 0;
      this.class4DevCount = 0;

      classProject.forEach((element) => {
        if (element.projects.length == 0) this.class0DevCount++;
        if (element.projects.length == 1) this.class1DevCount++;
        if (element.projects.length == 2) this.class2DevCount++;
        if (element.projects.length == 3) this.class3DevCount++;
        if (element.projects.length == 4) this.class4DevCount++;
      });
      this.class0DevPercent = parseFloat(
        (this.class0DevCount * 100) / classProject.length
      ).toFixed(2);
      this.class1DevPercent = parseFloat(
        (this.class1DevCount * 100) / classProject.length
      ).toFixed(2);
      this.class2DevPercent = parseFloat(
        (this.class2DevCount * 100) / classProject.length
      ).toFixed(2);
      this.class3DevPercent = parseFloat(
        (this.class3DevCount * 100) / classProject.length
      ).toFixed(2);
      this.class4DevPercent = parseFloat(
        (this.class4DevCount * 100) / classProject.length
      ).toFixed(2);
    },
    switchMode(mode) {
      if (mode == "ХӨГЖҮҮЛЭХ ХИЧЭЭЛ") {
        this.mode = "СУРАЛЦАГЧ";
        this.modesrc = "fa fa-users";
        this.modestyle = true;
        this.darkTheme = !this.darkTheme;
        this.value = true;
      } else {
        this.mode = "ХӨГЖҮҮЛЭХ ХИЧЭЭЛ";
        this.modesrc = "fab fa-dev nav-icon";
        this.modestyle = false;
        this.darkTheme = !this.darkTheme;
        this.value = false;
      }
    },
    getAllProject() {
      this.devIsLoaded = true;
      axios
        .post("/project_mentor/getAllProjects", {
          project_type: "Хөгжүүлэх хичээл",
          selectedQuarter: this.selectedQuarter,
          selectedDay: this.selectedDay,
          selectedStatus: this.selectedStatus,
          selectedTime: this.selectedTime,
          selectedDep: this.selectedDep,
        })
        .then((response) => {
          this.allProject = _.orderBy(response.data.allProjects, "isMyDepEmp", "desc");
          this.teachers = response.data.teachers;
          this.quarterOptions = response.data.mySchoolsQuarters;
          this.quarterOptions.push({
            quarter_id: 0,
            name: "Улирал",
          });
          this.currentUserId = response.data.currentUserId;
          this.classOptions = response.data.schoolClasses;
          this.depOptions = response.data.schoolDeps;
          this.depOptions.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.sumCheck(this.allProject);
          this.devIsLoaded = false;
          this.currentQuarter = response.data.currentQuarter;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sumCheck(allProject) {
      this.niit = 0;
      this.limitList = [];
      this.registeredList = [];
      this.totalLimit = "";
      this.totalRegistered = "";

      allProject.forEach((element) => {
        this.limitList.push(parseInt(element.limit));
        this.registeredList.push(parseInt(element.registered));
      });

      this.totalLimit = this.limitList.reduce((a, b) => a + b, 0);
      this.totalRegistered = this.registeredList.reduce((a, b) => a + b, 0);
      this.niit = this.limitList.length;
    },

    addProject() {
      this.editProjectMode = false;
      this.formModalTitle = "Хөгжүүлэх хичээл нэмэх";
      this.form.reset();
      this.form.quarter_id = this.currentQuarter;
      this.$bvModal.show("modal-project");
    },
    createProject() {
      this.action = "Хичээл үүсгэж байна...";
      let nowDate = new Date().toJSON().slice(0, 10);
      let regDate = this.form.reg_close_date;
      if (regDate > nowDate) {
        this.errCloseDate = "";
      } else
        this.errCloseDate = "Бүртгэл дуусах огноо өнөөдрийн огнооноос их байх ёстой.";
      if (this.imgError == "" && this.errCloseDate == "" && this.taskImgError == "") {
        this.form
          .post("/project_mentor/createProject")
          .then((response) => {
            this.$toastr.s("Хичээл амжилттай үүсгэлээ.", "Амжилттай");
            Fire.$emit("getMyProjects");
            this.form.reset();
            this.$bvModal.hide("modal-project");
          })
          .catch(() => {
            this.$toastr.e(
              "Төсөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
              "Алдаа гарлаа."
            );
          });
      }
    },
    editProject(project) {
      this.editProjectMode = true;
      this.formModalTitle = "Төсөл засах";
      this.form.reset();
      this.form.fill(project);
      //this.form.quarter_id = this.currentQuarter;
      this.form.scopeList = [];
      let sList = [];
      sList = project.scope.split("-");
      sList.forEach((val, index) => {
        if (val != "") {
          this.form.scopeList.push({
            name: val,
            text: val + "-р анги",
          });
          this.form["classLimit" + val] = project.class_limit.split("-")[val];
        }
      });
      this.$bvModal.show("modal-project");
    },
    updateProject() {
      this.action = "Төслийг шинэчилж байна...";
      console.log(this.form);
      this.form
        .put("/project_mentor/updateProject/" + this.form.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getMyProjects");
          this.form.reset();
          this.editProjectMode = false;
          this.$bvModal.hide("modal-project");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelProject() {
      this.editProjectMode = false;
      this.form.reset();
      this.$bvModal.hide("modal-project");
    },
    deleteProject(project) {
      Swal.fire({
        title: "Энэ төслийг устгахдаа итгэлтэй байна уу?",
        text: "Устгасан үед зөвхөн менежер сэргээх боломжтой?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Төсөл хасаж байна...";
          this.form
            .get("/project_mentor/removeProject/" + project.id)
            .then((response) => {
              this.$toastr.s("Төсөл амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getMyProjects");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    //Багш нэмэх хэсэг
    showProject(project) {
      this.action = "Төслийн мэдээллийг харах...";
      axios
        .get("/project_mentor/showProject/" + project.id)
        .then((response) => {
          this.projectMentors = response.data.projectMentors;
          this.form.reset();
          this.form.fill(project);
          this.ownerId = response.data.ownerId;
          this.showMode = !this.showMode;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
            department_code: "НМБНБ",
          });
          this.teachers = response.data.teachers;
          this.$bvModal.show("modal-projectInfo");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formMentor.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formMentor
        .get("/project_mentor/getTeacherList/" + this.formMentor.department_id)
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addMentor() {
      this.formMentor.project_id = this.form.id;
      this.formMentor
        .post("/project_mentor/addMentor")
        .then((response) => {
          this.projectMentors = response.data.projectMentors;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formMentor.reset();
          this.modalTeachers = [];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editMentor(mentor) {
      this.editMode = true;
      this.formMentor.reset();
      this.formMentor.fill(mentor);
      this.formMentor.mentors = [];
    },
    updateMentor() {
      this.action = "Төслийн багшийн мэдээллийг шинэчилж байна...";
      this.formMentor
        .put("/project_mentor/updateMentor/" + this.formMentor.id)
        .then((response) => {
          console.log(response.data);
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formMentor.reset();
          this.editMode = false;
          this.modalTeachers = [];
          this.projectMentors = response.data.projectMentors;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeMentor(mentor) {
      Swal.fire({
        title: "Та төслөөс багш хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Багш хасаж байна...";
          this.formMentor
            .get("/project_mentor/removeMentor/" + mentor.id, {
              params: { project_id: mentor.project_id },
            })
            .then((response) => {
              this.projectMentors = response.data.projectMentors;
              this.formMentor.reset();
              this.modalTeachers = [];
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditMentor() {
      this.formMentor.reset();
      this.editMode = false;
    },

    //Суралцагч нэмэх хэсэг========================================================
    showStudents(project) {
      this.action = "Төслийн мэдээллийг харах...";
      this.form.reset();
      this.form.fill(project);
      this.getStudents();
    },
    getStudents() {
      axios
        .get("/project_mentor/showStudents/" + this.form.id)
        .then((response) => {
          this.projectStudents = response.data.projectStudents;
          this.ownerId = response.data.ownerId;
          this.showStudentMode = !this.showStudentMode;
          this.schoolClasses = response.data.schoolClasses;
          this.students = response.data.students;
          this.$bvModal.show("modal-project-student");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    modalChangeStudentList: function (e) {
      this.formStudent.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formStudent
        .get("/project_mentor/getStudentList/" + this.formStudent.department_id)
        .then((response) => {
          this.modalStudents = response.data.students;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addStudent() {
      this.formStudent.project_id = this.form.id;
      this.formStudent
        .post("/project_mentor/addStudent")
        .then((response) => {
          this.projectStudents = response.data.projectStudents;
          this.form.registered = response.data.currentReg;
          this.$toastr.s("Суралцагч амжилттай нэмлээ!", "Шинэчилсэн");
          this.formStudent.reset();
          this.modalStudents = [];
          if (response.data.error == "error") {
            Swal.fire({
              icon: "error",
              title: "Алдаа гарлаа...",
              text: response.data.errorMsj,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Амжилттай",
              text: response.data.errorMsj,
            });
            Fire.$emit("getMyProjects");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showComment(project) {
      this.beforeComments = [];
      axios
        .post("/project_mentor/getChildComment/" + project.project_id, {
          child_id: project.student_id,
          type: "project",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$bvModal.show("modal-project-comment");
    },
    editStudent(student) {
      this.editStudentMode = true;
      this.formStudent.reset();
      this.formStudent.fill(student);
      this.formStudent.students = [];
    },
    updateStudent() {
      this.action = "Суралцагчийн мэдээллийг шинэчилж байна...";
      this.formStudent
        .put("/project_mentor/updateStudent/" + this.formStudent.id)
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formStudent.reset();
          this.editStudentMode = false;
          this.modalStudents = [];
          this.projectStudents = response.data.projectStudents;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeStudent(student) {
      Swal.fire({
        title: "Та суралцагч хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Суралцагч хасаж байна...";
          this.formStudent
            .get("/project_mentor/removeStudent/" + student.id, {
              params: { project_id: student.project_id },
            })
            .then((response) => {
              this.projectStudents = response.data.projectStudents;
              this.form.registered = response.data.$currentReg;
              this.$toastr.s("Суралцагч амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getMyProjects");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditStudent() {
      this.formStudent.reset();
      this.editStudentMode = false;
    },
    //======================== Төсөв нэмэх хэсэг ================================
    getBudgetItemList: function (e) {
      this.projectBudgets.budget_type_id = e;
      this.action = "Бүтээгдэхүүний мэдээллийг шинэчилж байна...";
      axios
        .get("/project_mentor/getBudgetItemList/" + this.projectBudgets.budget_type_id)
        .then((response) => {
          this.budgetItems = response.data.budgetItems;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showBudgets(project) {
      this.action = "Төсвийн мэдээллийг харах...";
      axios
        .get("/project_mentor/showBudgets/" + project.id)
        .then((response) => {
          this.projectBudgets = response.data.projectBudgets;
          this.form.reset();
          this.form.fill(project);
          this.ownerId = response.data.ownerId;
          this.showBudgetMode = !this.showBudgetMode;
          this.budgetTypes = response.data.budgetTypes;
          this.$bvModal.show("modal-project-budget");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addBudget() {
      this.formBudget.project_id = this.form.id;
      if (this.imgError1 == "" && this.imgError2 == "" && this.imgError3 == "") {
        this.formBudget
          .post("/project_mentor/addBudget")
          .then((response) => {
            this.projectBudgets = response.data.projectBudgets;
            this.$toastr.s("Төсөв амжилттай нэмлээ!", "Шинэчилсэн");
            this.formBudget.reset();
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    editBudget(budget) {
      this.editBudgetMode = true;
      this.formBudget.reset();
      this.formBudget.fill(budget);
    },
    updateBudget() {
      this.action = "Төсвийн мэдээллийг шинэчилж байна...";
      if (this.imgError1 == "" && this.imgError2 == "" && this.imgError3 == "") {
        this.formBudget
          .put("/project_mentor/updateBudget/" + this.formBudget.id)
          .then((response) => {
            this.$toastr.s("Төсөв амжилттай заслаа!", "Шинэчилсэн");
            this.projectBudgets = response.data.projectBudgets;
            this.formBudget.reset();
            this.editBudgetMode = false;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    getBudgetImg1(e) {
      let p1Img = e.target.files[0];
      if (!p1Img) {
        e.preventDefault();
        this.imgError1 = "";
        return;
      }

      if (p1Img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError1 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage1(p1Img);
    },
    readImage1(p1Img) {
      let reader = new FileReader();
      reader.readAsDataURL(p1Img);
      reader.onload = (e) => {
        this.formBudget.budget_images_1 = e.target.result;
      };
      this.loaded = true;
    },
    getBudgetImg2(e) {
      let pImg2 = e.target.files[0];
      if (!pImg2) {
        e.preventDefault();
        this.imgError2 = "";
        return;
      }

      if (pImg2.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError2 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage2(pImg2);
    },
    readImage2(pImg2) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg2);
      reader.onload = (e) => {
        this.formBudget.budget_images_2 = e.target.result;
      };
      this.loaded = true;
    },
    getBudgetImg3(e) {
      let pImg3 = e.target.files[0];
      if (!pImg3) {
        e.preventDefault();
        this.imgError3 = "";
        return;
      }

      if (pImg3.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError3 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage3(pImg3);
    },
    readImage3(pImg3) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg3);
      reader.onload = (e) => {
        this.formBudget.budget_images_3 = e.target.result;
      };
      this.loaded = true;
    },
    removeBudget(budget) {
      Swal.fire({
        title: "Та төсөв хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Төсөв хасаж байна...";
          this.formBudget
            .get("/project_mentor/removeBudget/" + budget.id, {
              params: { project_id: budget.project_id },
            })
            .then((response) => {
              this.projectBudgets = response.data.projectBudgets;
              this.$toastr.s("Төсөв амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditBudget() {
      this.formBudget.reset();
      this.editBudgetMode = false;
    },

    //======================== Идэвхжүүлэх хэсэг ================================
    editStatus(project) {
      this.form.reset();
      this.form.fill(project);
      this.showActiveMode = true;
      this.formActive.reset();
      this.formActive.fill(project);
      this.$bvModal.show("modal-project-active");
    },
    updateStatus() {
      this.action = "Төсвийн төлвийг шинэчилж байна...";
      this.formActive
        .put("/project_mentor/updateStatus/" + this.formActive.id)
        .then((response) => {
          this.$toastr.s("Төлөв амжилттай заслаа!", "Шинэчилсэн");
          this.formActive.reset();
          this.showActiveMode = false;
          this.$bvModal.hide("modal-project-active");
          Fire.$emit("getMyProjects");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditStatus() {
      this.formActive.reset();
      this.showActiveMode = false;
      this.$bvModal.hide("modal-project-active");
    },
    getProjectImg(e) {
      this.imgError = "";
      console.log("Ажиллаж байна. getProjectImg");
      let pImg = e.target.files[0];
      if (!pImg) {
        e.preventDefault();
        return;
      }

      if (pImg.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError = `1mb-аас бага файл оруулна уу! Зургийн хэмжээ: ${(
          pImg.size / 1048576
        ).toFixed(2)}mb байна.`;
        return;
      }
      this.readImage(pImg);
    },
    readImage(pImg) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg);
      reader.onload = (e) => {
        this.form.projectImage = e.target.result;
      };
      this.loaded = true;
    },
    getTaskImg(e) {
      let pImg = e.target.files[0];
      this.taskImgError = "";
      if (!pImg) {
        e.preventDefault();
        return;
      }

      if (pImg.size > 1024 * 1024) {
        e.preventDefault();
        this.taskImgError = `1mb-аас бага файл оруулна уу! Зургийн хэмжээ: ${(
          pImg.size / 1048576
        ).toFixed(2)}mb байна.`;
        return;
      }
      this.readTaskImage(pImg);
    },
    readTaskImage(pImg) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg);
      reader.onload = (e) => {
        this.form.task_img = e.target.result;
      };
      this.loaded = true;
    },
    toggleSelect() {
      console.log("filter department");
    },
    itemAddChangeMode() {
      this.formItem.reset();
      this.itemAddMode = !this.itemAddMode;
    },
    addItem() {
      this.formItem
        .post("/teacher/createItem")
        .then((res) => {
          this.itemAddMode = false;
          Fire.$emit("getBudgetItemList");
          this.$toastr.s("Бүтээгдэхүүн амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelItem() {
      this.itemAddMode = false;
      this.formItem.reset();
    },
    //======================== Төлөвлөгөө нэмэх хэсэг ================================
    showPlan(project) {
      this.action = "Төлөвлөгөө харах...";
      axios
        .get("/project_mentor/showProjectPlan/" + project.id)
        .then((response) => {
          this.projectPlans = response.data.projectPlans;
          this.form.reset();
          this.form.fill(project);
          this.ownerId = response.data.ownerId;
          this.showPlanMode = !this.showPlanMode;
          this.$bvModal.show("modal-project-plan");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addPlan() {
      this.formPlan.project_id = this.form.id;
      this.formPlan
        .post("/project_mentor/addPlan")
        .then((response) => {
          this.projectPlans = response.data.projectPlans;
          this.$toastr.s("Төлөвлөгөө амжилттай нэмлээ!", "Шинэчилсэн");
          this.formPlan.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editPlan(plan) {
      this.editPlanMode = true;
      this.formPlan.reset();
      this.formPlan.fill(plan);
    },
    updatePlan() {
      this.action = "Төлөвлөгөө шинэчилж байна...";
      this.formPlan
        .put("/project_mentor/updatePlan/" + this.formPlan.id)
        .then((response) => {
          this.$toastr.s("Төлөвлөгөө амжилттай заслаа!", "Шинэчилсэн");
          this.projectPlans = response.data.projectPlans;
          this.formPlan.reset();
          this.editPlanMode = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removePlan(plan) {
      Swal.fire({
        title: "Та төлөвлөгөө хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Төлөвлөгөө хасаж байна...";
          this.formPlan
            .get("/project_mentor/removePlan/" + plan.id, {
              params: { project_id: plan.project_id },
            })
            .then((response) => {
              this.projectPlans = response.data.projectPlans;
              this.$toastr.s("Төлөвлөгөө амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditPlan() {
      this.formPlan.reset();
      this.editPlanMode = false;
    },
    showImageUploader(student) {
      this.imageData = null;
      this.formImage.reset();
      this.formImage.model_id = student.project_id;
      this.formImage.student_id = student.student_id;
      this.$bvModal.show("modal-image");
    },
    setImg(e) {
      this.imgError = "";
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        return;
      }

      if (img.size > 2024 * 2024) {
        e.preventDefault();
        this.imgError = "4mb-аас бага файл оруулна уу!";
        return;
      }
      this.reamImage(img);
    },
    reamImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.formImage.image = e.target.result;
      };
    },
    createImage() {
      this.formImage
        .post("/teacher/uploadImage")
        .then((response) => {
          if (response.data.success == "yes") {
            this.$toastr.s("Зураг амжилттай нэмлээ", "Амжилттай");
            this.cancelUploadImg();
            this.getStudents();
          } else this.$toastr.e("Зураг нэмэх үед алдаа гарлаа.", "Анхаар!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteImage(image) {
      //   console.log(parentmeet);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Та суралцагчийн зургийг устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteImage/" + image.id)
            .then((res) => {
              this.$toastr.s("Зураг амжилттай устгалаа", "Амжилттай");
              this.getStudents();
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    cancelUploadImg() {
      this.imageData = null;
      this.formImage.reset();
      this.$bvModal.hide("modal-image");
    },
  },
  created() {
    this.getAllProject();
    Fire.$on("getMyProjects", () => {
      this.getAllProject();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
</style>
