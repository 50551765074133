<template>
  <div class="row">
    <div class="col-lg-9 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong>Календар тохиргоо</strong>
          </h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <th>№</th>
                <th>Сар</th>
                <th>Эрэмбэ</th>
                <th>Зураг</th>
                <th>Үйлдэл</th>
              </thead>
              <tbody>
                <tr v-for="(config, index) in calendarConfigs" :key="config.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ config.month }}
                  </td>
                  <td>
                    {{ config.order }}
                  </td>
                  <td>
                    <img
                      :src="config.image"
                      alt="user-avatar"
                      class="direct-chat-img"
                      v-b-tooltip.hover.right.html="
                        '<dd><img src=' +
                        config.image +
                        ' style=' +
                        'width:280px!important' +
                        '><dd>'
                      "
                    />
                  </td>
                  <td>
                    <a href="#" @click.prevent="editCalendarConfig(config)"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a href="#" @click.prevent="removeCalendarConfig(config)"
                      ><i class="fas fa-trash text-danger"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            <strong v-if="!editCalendarMode">Тохиргоо нэмэх</strong>
            <strong v-else>Тохиргоо засах</strong>
          </h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Сар</label>
              <b-form-select
                v-if="!editCalendarMode"
                v-model="formCalendarConfig.month"
                :options="monthOptions"
                size="sm"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formCalendarConfig.errors.has('month'),
                  },
                ]"
              ></b-form-select>
              <strong class="form-control" v-else>{{ formCalendarConfig.month }}</strong>
            </div>
            <div class="form-group">
              <label>Эрэмбэ</label>
              <b-form-select
                v-model="formCalendarConfig.order"
                :options="orderOptions"
                size="sm"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formCalendarConfig.errors.has('order'),
                  },
                ]"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>Зургийн мэдээлэл</label>
              <b-form-textarea
                id="textarea"
                v-model="formCalendarConfig.description"
                placeholder="Зурсан зураачийн нэр, зургийн тайлбар оруулна уу."
                rows="3"
                max-rows="6"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formCalendarConfig.errors.has('description'),
                  },
                ]"
              ></b-form-textarea>
            </div>
            <div class="form-group">
              <label>Зураг</label>
              <b-form-file
                id="image"
                browse-text="Зураг сонгох"
                :class="[
                  'form-control',
                  {
                    'is-invalid': formCalendarConfig.errors.has('image'),
                  },
                ]"
                @change="getImg"
              ></b-form-file>
              <img
                v-show="imgLoaded"
                class="profile-user-img img-fluid attachment-img mt-2"
                :src="imgUrl"
                alt="Төслийн зураг"
                accept="image/*"
                v-if="imgUrl !== ''"
              />
              <span class="col-sm-5">
                {{ imgError }}
              </span>
            </div>
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-sm btn-success"
              @click.prevent="updateCalendarConfig()"
              v-if="editCalendarMode"
            >
              Засах
            </b-button>
            <button
              v-if="editCalendarMode"
              @click.prevent="cancelEditCalendarConfig()"
              class="btn btn-sm btn-warning"
            >
              Болих
            </button>
            <button
              type="submit"
              v-if="!editCalendarMode"
              @click.prevent="createCalendarConfig()"
              class="btn btn-sm btn-primary"
            >
              Нэмэх
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      calendarConfigs: [],
      editCalendarMode: false,
      imgUrl: "",
      formCalendarConfig: new Form({
        id: "",
        month: "",
        order: "",
        description: "",
        image: null,
      }),
      monthOptions: [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
      orderOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      imgError: "",
      imgLoaded: false,
    };
  },
  methods: {
    getCalenderConfigs() {
      axios
        .get("/manager/getCalenderConfigs")
        .then((response) => {
          this.calendarConfigs = response.data.calendarConfigs;
        })
        .catch(() => {});
    },
    createCalendarConfig() {
      this.formCalendarConfig
        .post("/manager/createCalendarConfig")
        .then((response) => {
          if (!response.data.errMesg) {
            this.$toastr.s("Тохиргоо амжилттай нэмлээ.", "Амжилттай");
            this.getCalenderConfigs();
            this.cancelEditCalendarConfig();
          } else
            this.$toastr.w(
              this.formCalendarConfig.month + " сард тохиргоо хийгдсэн байна.",
              "Анхаар!"
            );
        })
        .catch(() => {});
    },
    editCalendarConfig(config) {
      this.imgUrl = config.image;
      this.formCalendarConfig.fill(config);
      this.formCalendarConfig.image = null;
      this.editCalendarMode = true;
      this.imgLoaded = true;
    },
    updateCalendarConfig() {
      this.formCalendarConfig
        .put("/manager/updateCalendarConfig/" + this.formCalendarConfig.id)
        .then(() => {
          this.$toastr.s("Тохиргоо амжилттай заслаа.", "Амжилттай");
          this.imgUrl = "";
          this.getCalenderConfigs();
          this.cancelEditCalendarConfig();
        })
        .catch(() => {});
    },
    cancelEditCalendarConfig() {
      this.formCalendarConfig.reset();
      this.editCalendarMode = false;
      this.imgLoaded = false;
    },
    removeCalendarConfig(config) {
      Swal.fire({
        title: "Энэ тохиргоог устгахдаа итгэлтэй байна уу?",
        text: config.month + " сарын тохиргоог устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Сарын тохиргоог хасаж байна...";
          axios
            .get("/manager/removeCalendarConfig/" + config.id)
            .then(() => {
              this.$toastr.s("Сарын тохиргоог амжилттай устгалаа!", "Шинэчилсэн");
              this.getCalenderConfigs();
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    getImg(e) {
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError = "1024 x 1024 пикселээс бага хэмжээтэй зураг оруулна уу!";
        return;
      } else this.imgError = "";
      this.readImage(img);
    },
    readImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.formCalendarConfig.image = e.target.result;
        this.imgUrl = e.target.result;
      };
      this.imgLoaded = true;
    },
  },
  created() {
    this.getCalenderConfigs();
  },
};
</script>

<style scoped>
.label {
  font-weight: 200;
}
.labelActive {
  font-weight: 600;
}
</style>
