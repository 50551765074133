<template>
  <div class="pt-3">
    <!-- /.col -->
    <div class="card">
      <div class="card-body table-responsive bodyOfYear">
        <b-row>
          <label>Анги сонгох</label>
          <div class="col-4" v-if="mySchoolClasses.length > 1">
            <multiselect
              v-model="school_class_id"
              :multiple="false"
              deselect-label="Can't remove this value"
              label="full_name"
              track-by="id"
              placeholder="Сонголт хийх"
              :options="mySchoolClasses"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="true"
              :preserve-search="true"
              :hide-selected="true"
            >
              <!-- @select="toggleSelect" -->
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.full_name }} анги</strong>
                сонгогдлоо
              </template>
            </multiselect>
          </div>
          <b-col sm="3">
            <multiselect
              v-model="selectedQuarter"
              deselect-label="Сонголт цуцлах"
              select-label="Улирал сонгох"
              track-by="id"
              :custom-label="customQuarterName"
              label="name"
              placeholder="Хичээлийн улирал сонгоно уу?"
              :options="quarters"
              :searchable="false"
              :allow-empty="false"
              @input="getLessonClassAtt"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}-р улирал </strong></template
              >
            </multiselect>
          </b-col>
        </b-row>
        <b-overlay :show="loadClassData" rounded="sm">
          <div class="row">
            <div class="col-12 col-sm-12" v-if="viewSelectClassChart">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span class="info-box-number text-muted mb-0">
                      <apexchart
                        :show="loadClassData"
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonName"
                        :series="seriesClassLesson"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <div class="row mt-3">
          <div class="col-12" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <b-col sm="3">
                    <multiselect
                      v-model="selectedLesson"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      placeholder="Хичээл сонгоно уу?"
                      track-by="id"
                      label="subject_name"
                      :options="lessonList"
                      :searchable="false"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong>{{ option.subject_name }} </strong></template
                      >
                    </multiselect></b-col
                  >
                  <span
                    class="info-box-number text-muted mb-0"
                    v-if="viewSelectlessonChart"
                  >
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsStudName"
                      :series="seriesClassStud"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card-header -->
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudentAttStat from "./everyStudentAttStat.vue";
export default {
  components: {
    Multiselect,
    everyStudentAttStat,
  },
  data() {
    return {
      show: false,
      loadClassData: false,
      viewSelectlessonChart: false,
      viewSelectClassChart: false,
      viewChart: false,
      viewQuarterChart: false,
      viewLessonChartP: false,
      viewClassChartP: false,
      selectedQuarter: "",
      selectedLesson: "",
      selectedClassStudP: "",
      years: [],
      quarters: [],
      lessonNames: [],
      quarter: [],
      mySchoolClasses: [],
      school_class_id: null,
      //Select lesson
      seriesClassLesson: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsLessonName: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },

      // Select class
      seriesClassStud: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsStudName: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  watch: {
    selectedLesson() {
      this.viewSelectlessonChart = false;
      this.viewQuarterChart = true;
      this.school_quarter_id = "";
      if (this.selectedQuarter) {
        this.seriesClassStud[0].data = [];
        this.seriesClassStud[1].data = [];
        this.seriesClassStud[2].data = [];
        this.seriesClassStud[3].data = [];
        this.seriesClassStud[4].data = [];
        this.chartOptionsStudName.xaxis.categories = [];
        axios
          .post("/teacher/getLessonStudAtt", {
            selectedQuarter: this.selectedQuarter.id,
            selectedLesson: this.selectedLesson.id,
            classId: this.school_class_id.id,
          })
          .then((response) => {
            this.seriesClassStud[0].data = response.data.sIrsenList;
            this.seriesClassStud[1].data = response.data.sTasalsanList;
            this.seriesClassStud[2].data = response.data.sUvchteiList;
            this.seriesClassStud[3].data = response.data.sChuluuteiList;
            this.seriesClassStud[4].data = response.data.sHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsStudName.xaxis.categories =
              response.data.studNames;
            this.studentIds = response.data.studentIds;
            this.viewSelectlessonChart = true;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },

    school_class_id() {
      this.getLessonClassAtt();
    },
  },
  methods: {
    allQuarters() {
      axios
        .get("/teacher/getSchoolQuarters")
        .then((response) => {
          this.quarters = response.data.schoolquarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    remove(attendance_id, tooluur) {
      this.passPropsMyLessonDate.splice(tooluur, 1);
    },
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    getLessonClassAtt() {
      this.school_quarter_id = "";
      this.viewSelectClassChart = false;
      this.viewQuarterChart = false;
      this.loadClassData = true;
      if (this.selectedQuarter) {
        this.seriesClassLesson[0].data = [];
        this.seriesClassLesson[1].data = [];
        this.seriesClassLesson[2].data = [];
        this.seriesClassLesson[3].data = [];
        this.seriesClassLesson[4].data = [];
        this.chartOptionsLessonName.xaxis.categories = [];
        axios
          .post("/teacher/getLessonClassAtt", {
            classId: this.school_class_id.id,
            selectedQuarter: this.selectedQuarter.id,
          })
          .then((response) => {
            this.seriesClassLesson[0].data = response.data.cIrsenList;
            this.seriesClassLesson[1].data = response.data.cTasalsanList;
            this.seriesClassLesson[2].data = response.data.cUvchteiList;
            this.seriesClassLesson[3].data = response.data.cChuluuteiList;
            this.seriesClassLesson[4].data = response.data.cHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsLessonName.xaxis.categories =
              response.data.cLessonNames;
            this.lessonNames = response.data.cLessonNames;
            this.lessonList = response.data.lessonList;
            this.viewQuarterChart = true;
            this.loadClassData = false;
            this.viewSelectClassChart = true;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
  },

  created() {
    axios
      .get("/teacher/getMyClasses")
      .then((res) => {
        this.mySchoolClasses = res.data.schoolclasses;
        this.selectedQuarter = res.data.currentQuarter;
        this.mySchoolClasses.length > 0
          ? (this.school_class_id = this.mySchoolClasses[0])
          : null;
      })
      .catch((err) => {
        console.log(err);
      });
    this.allQuarters();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bodyOfYear {
  min-height: 200px;
}
</style>
