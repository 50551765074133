<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#inactive" data-toggle="tab"
                >Устгагдсан</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Байгууллагын хэмжээнд бүртгэлтэй ангилалууд</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addProblemRoomCategory()"
                    >Ангилал бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Ангилалын тоо:
                      {{ roomCategories.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="roomLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPageRooms"
                            :options="pageOptionsRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                        label="Хайлт хийх утга оруулна уу!"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-input"
                            v-model="filterRooms"
                            type="search"
                            placeholder="Хайлт хийх утгаа оруулна уу!"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              :disabled="!filterRooms"
                              @click="filterRooms = ''"
                              >Цэвэрлэх</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageRooms"
                      :total-rows="totalRowsRooms"
                      :per-page="perPageRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="roomCategories"
                      :fields="fieldsActiveRooms"
                      :current-page="currentPageRooms"
                      :per-page="perPageRooms"
                    >
                      <!-- :filter="filterRooms"
                    :filter-included-fields="filterOnRooms"
                    @filtered="onFilteredRooms" -->
                      <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(block_name)="data">
                        <b-badge variant="info">
                          {{ data.item.block_name }}
                        </b-badge>
                      </template>
                      <template #cell(room_number)="data">
                        <b-badge variant="success">
                          {{ data.item.room_number }}
                        </b-badge>
                      </template>
                      <template #cell(room_owner_employees)="data">
                        <div style="display: inline-block">
                          <b-badge
                            variant="success"
                            v-for="(employee, index) in data.item
                              .room_employees"
                            :key="index"
                          >
                            {{ employee.my_user_info.teacher_last_name[0] }}.
                            {{ employee.my_user_info.teacher_name }}
                            <!-- {{ data.item.room_employees }} -->
                          </b-badge>
                        </div>
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="editSchoolRoomCategory(data.item)"
                          ><i class="fas fa-pencil-alt"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="deleteSchoolRoomCategory(data.item)"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="inactive">
              <div class="card">
                <div class="card-header">
                  <strong
                    >Байгууллагын хэмжээнд бүртгэлтэй өрөө, танхимууд</strong
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      Нийт өрөө танхимуудын тоо:
                      {{ roomCategoriesDeleted.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="roomLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-inactive-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-inactive-select"
                            v-model="perPageInactiveRooms"
                            :options="pageOptionsInactiveRooms"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <!-- <b-form-group
                        label="Хайлт хийх утга оруулна уу!"
                        label-for="filter-inactive-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-inactive-input"
                            v-model="filterInactiveRooms"
                            type="search"
                            placeholder="Хайлт хийх утгаа оруулна уу!"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              :disabled="!filterInactiveRooms"
                              @click="filterInactiveRooms = ''"
                              >Цэвэрлэх</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPageInactiveRooms"
                      :total-rows="totalRowsInactiveRooms"
                      :per-page="perPageInactiveRooms"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="roomCategoriesDeleted"
                      :fields="fieldsInactiveRooms"
                      :current-page="currentPageInactiveRooms"
                      :per-page="perPageInactiveRooms"
                    >
                      <!-- :filter="filterInactiveRooms"
                    :filter-included-fields="filterOnInactiveRooms"
                    @filtered="onFilteredInactiveRooms"
                    :filter-ignored-fields="filterIgnoredFieldsInactive" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(block_name)="data">
                        <b-badge variant="info">
                          {{ data.item.block_name }}
                        </b-badge>
                      </template>
                      <template #cell(room_number)="data">
                        <b-badge variant="success">
                          {{ data.item.room_number }}
                        </b-badge>
                      </template>
                      <template #cell(room_owner_employees)="data">
                        <div style="display: inline-block">
                          <b-badge
                            variant="success"
                            v-for="(employee, index) in data.item
                              .room_employees"
                            :key="index"
                          >
                            {{ employee.my_user_info.teacher_last_name[0] }}.
                            {{ employee.my_user_info.teacher_name }}
                          </b-badge>
                        </div>
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <a
                          href="#"
                          class="badge bg-warning"
                          @click="restoreSchoolRoomCategory(data.item)"
                          ><i class="fas fa-trash-restore-alt"></i
                        ></a>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Өрөө танхим бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-room"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="formRoomModalTitle"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModeRoom">
                    Ангилал бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editModeRoom">
                    Ангилал засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editModeRoom
                        ? createSchoolRoomCategory()
                        : updateSchoolRoomCategory()
                    "
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Ангилалын нэр</label>
                            <b-form-input
                              v-model="form.name"
                              text-field="name"
                              value-field="value"
                              type="text"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Тайлбар</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="form.description"
                              placeholder="тайлбар..."
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editModeRoom"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editModeRoom"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editModeRoom"
                        @click.prevent="cancelEdit"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editModeRoom"
                        @click.prevent="cancelCreate"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formRoomModalTitle: "Ангилал",

      //Өрөө танхимын ангилал
      roomCategories: [],
      roomCategoriesDeleted: [],
      employees: [],
      roomLoaded: true,
      editModeRoom: false,

      //b-table тохиргоо
      totalRowsRooms: 1,
      currentPageRooms: 1,
      perPageRooms: 200,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,

        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterRooms: "",
      filterOnRooms: [],
      fieldsActiveRooms: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ангилалын нэр",
          sortable: true,
        },
        {
          key: "description",
          label: "Ангилалын тайлбар",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      //b-table тохиргоо inactiveroom
      totalRowsInactiveRooms: 1,
      currentPageInactiveRooms: 1,
      perPageInactiveRooms: 25,
      pageOptionsInactiveRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortByInactive: "",
      //   sortDescInactive: false,
      //   sortDirectionInactive: "asc",
      filterInactiveRooms: "",
      filterOnInactiveRooms: [],
      fieldsInactiveRooms: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Ангилалын нэр",
          sortable: true,
        },
        {
          key: "description",
          label: "Ангилалын тайлбар",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      filterIgnoredFieldsInactive: [
        {
          key: "room_owner_employees",
          label: "Өрөө эзэмшигчид",
          //   sortable: true,
        },
      ],

      editRoomMode: false,

      form: new Form({
        id: "",
        name: "",
        description: "",
      }),
    };
  },
  components: { Multiselect },
  computed: {
    // items() {
    //   if (this.filterInactiveRooms != "") {
    //     this.roomCategoriesDeleted.forEach((element) => {
    //       return element.room_employees.filter(
    //         (item) =>
    //           item.my_user_info.teacher_name.includes(this.filterInactiveRooms)
    //         //   ||
    //         //   item.lastname.includes(this.keyword) ||
    //         //   item.email.includes(this.keyword)
    //       );
    //     });
    //   } else {
    //     return this.roomCategoriesDeleted;
    //   }
    // },
  },
  methods: {
    getAllRoomCategories() {
      axios
        .get("/manager_vaa/getAllRoomCategories")
        .then((res) => {
          //   console.log(response);
          this.roomCategories = res.data.roomCategories;
          this.roomCategoriesDeleted = res.data.roomCategoriesDeleted;
          this.totalRowsRooms = this.roomCategories
            ? this.roomCategories.length
            : 0;
          this.totalRowsInactiveRooms = this.roomCategoriesDeleted
            ? this.roomCategoriesDeleted.length
            : 0;
          this.employees = res.data.employees;
          this.roomLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    customLabel({ name, profile_student_last_name }) {
      return `${profile_student_last_name[0]}. ${name}`;
    },
    schoolClassLabel({ my_course }) {
      return `${my_course.my_school_class.full_name}`;
    },

    //b-table тохирго
    onFilteredRooms(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRooms = filteredItems.length;
      this.currentPageRooms = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveRooms = filteredItems.length;
      this.currentPageInactiveRooms = 1;
    },

    addProblemRoomCategory() {
      this.form.reset();
      this.$bvModal.show("modal-create-room");
    },
    createSchoolRoomCategory() {
      //   console.log("create");
      this.form
        .post("/manager_vaa/createSchoolRoomCategory")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadRooms");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editSchoolRoomCategory(roomCategory) {
      this.editModeRoom = true;
      this.form.fill(roomCategory);
      this.$bvModal.show("modal-create-room");
    },
    updateSchoolRoomCategory() {
      //   console.log("udpate");
      this.form
        .put("/manager_vaa/updateSchoolRoomCategory")
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadRooms");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    toggleSelect() {
      //   console.log("toggleSelect");
    },
    cancelEdit() {
      //   this.form.reset();
      this.cancelCreate();
      this.editModeRoom = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-room");
    },

    nameTeacher({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },

    deleteSchoolRoomCategory(roomCategory) {
      //   console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан тохилдолд тухайн сургуультай хамаарал бүхий хэрэглэгчид ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/deleteSchoolRoomCategory/" + roomCategory.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Сургуулийн өрөө танхимыг системээс устгалаа.",
                "success"
              );
              Fire.$emit("loadRooms");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    restoreSchoolRoomCategory(room) {
      // console.log(room);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан мэдээллийг сэргээх гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг сэргээ!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager_vaa/restoreSchoolRoomCategory/" + room.id)
            .then((res) => {
              Swal.fire(
                "Сэргээлээ!",
                "Сургуулийн өрөө танхимыг системд буцаан сэргээлээ.",
                "success"
              );
              Fire.$emit("loadRooms");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
  },
  watch: {
    // "form.exams_teacher_id"(newVal, oldVal) {
    //   this.form.exams_course_id = "";
    //   this.form.exams_quarter_id = "";
    //   this.form.room_owner_employees = "";
    //   this.form.room_plate_face = "";
    //   this.form.exams_calendar_plan_content = "";
    //   this.form.exams_description = "";
    //   //   console.log("teacher_id Өөрчлөгдсөн.", newVal);
    //   if (this.form.exams_teacher_id !== "") {
    //     axios
    //       .post("/manager/getTeacherCourses", {
    //         teacher: newVal,
    //       })
    //       .then((res) => {
    //         this.teacherCourses = res.data.courses;
    //         this.quarters = res.data.quarters;
    //       });
    //   } else {
    //     this.teacherCourses = [];
    //   }
    // },
  },
  created() {
    this.getAllRoomCategories();
    Fire.$on("loadRooms", () => {
      this.getAllRoomCategories();
    });
    Fire.$on("getExamTeachers", () => {
      this.getExamTeachers();
    });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}
a {
  font-size: 60%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
