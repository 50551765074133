<template>
  <div class="row pt-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Хүлээлгэнд бүртгэгдсэн суралцагчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#timeline" data-toggle="tab"
                >Хүлээлгэнээс хасагдсан суралцагчид</a
              >
              <!-- v-if="this.userInfo.name !== 'agermankg'" -->
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="active tab-pane" id="activity">
              <div class="row">
                <div class="col-lg-8 mt-1">
                  <div class="card-body table-responsive p-0">
                    <b-overlay :show="waitEntruntLoaded" rounded="sm">
                      <b-row>
                        <b-col lg="12" class="my-1">
                          <b-form-group
                            label="Ангилах багана сонгох"
                            label-for="sort-by-select"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                            v-slot="{ ariaDescribedby }"
                          >
                            <b-input-group size="sm">
                              <b-form-select
                                id="sort-by-select"
                                v-model="sortBySchoolEntrunt"
                                :options="sortOptionsSchoolEntrunt"
                                :aria-describedby="ariaDescribedby"
                                class="w-75"
                              >
                                <template #first>
                                  <option value="">-- none --</option>
                                </template>
                              </b-form-select>

                              <b-form-select
                                v-model="sortDescSchoolEntrunt"
                                :disabled="!sortBySchoolEntrunt"
                                :aria-describedby="ariaDescribedby"
                                size="sm"
                                class="w-25"
                              >
                                <option :value="false">Asc</option>
                                <option :value="true">Desc</option>
                              </b-form-select>
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            label="Нэг хуудсанд"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="perPageSchoolEntrunt"
                              :options="pageOptionsSchoolEntrunt"
                              size="sm"
                            ></b-form-select>
                          </b-form-group>

                          <b-form-group
                            label="Хайх утга!"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-input-group size="sm">
                              <b-form-input
                                id="filter-input"
                                v-model="filterSchoolEntrunt"
                                type="search"
                                placeholder="Хайлт хийх утгаа оруулна уу!"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  :disabled="!filterSchoolEntrunt"
                                  @click="filterSchoolEntrunt = ''"
                                  >Цэвэрлэх</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-pagination
                        v-model="currentPageSchoolEntrunt"
                        :total-rows="totalRowsSchoolEntrunt"
                        :per-page="perPageSchoolEntrunt"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                      <b-table
                        hover
                        :items="schoolentrantsCheck"
                        :fields="fieldsSchoolEntrunt"
                        :current-page="currentPageSchoolEntrunt"
                        :per-page="perPageSchoolEntrunt"
                        :filter="filterSchoolEntrunt"
                        :filter-ignored-fields="
                          filterIgnoredFieldsSchoolEntrunt
                        "
                        @filtered="onFilteredSchoolEntrunt"
                        :filter-included-fields="filterOnSchoolEntrunt"
                        :sort-by.sync="sortBySchoolEntrunt"
                        :sort-desc.sync="sortDescSchoolEntrunt"
                        :sort-direction="sortDirectionSchoolEntrunt"
                      >
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(profile_last_name)="data">
                          <b-badge variant="warning" class="text-wrap">
                            {{ data.item.profile_last_name }}
                            {{ data.item.user_name }}
                          </b-badge>
                        </template>
                        <template #cell(status1)="data">
                          <b-badge
                            variant="warning"
                            class="text-wrap"
                            v-if="data.item.payment_type === 0"
                          >
                            Нөхөн
                          </b-badge>
                          <b-badge
                            variant="success"
                            class="text-wrap"
                            v-if="data.item.payment_type === 1"
                          >
                            Үндсэн
                          </b-badge>
                          <b-badge
                            variant="info"
                            class="text-wrap"
                            v-if="data.item.payment_type === 2"
                          >
                            Дэвших
                          </b-badge>
                        </template>
                        <template #cell(study_class)="data">
                          <b-badge
                            variant="warning"
                            class="text-wrap"
                            v-if="data.item.study_class === '-1'"
                          >
                            Цэцэрлэг
                          </b-badge>
                          <b-badge
                            variant="success"
                            class="text-wrap"
                            v-else-if="data.item.study_class === '0'"
                          >
                            Бэлтгэл анги
                          </b-badge>
                          <b-badge variant="info" class="text-wrap" v-else
                            >{{ data.item.study_class }} анги
                          </b-badge>
                        </template>

                        <!-- <template #cell(subject_short_name)="data">
                      <div class="row callout callout-warning">
                        <div class="col-md-10">
                          <span style="font-size: 80%">
                            <b>Хичээл:</b>
                            <b-badge variant="info">
                              {{ data.item.subject_name }}
                            </b-badge>
                            <br />
                            <b> Бүртгэсэн: </b>
                            {{ data.item.created_at | dateYearMonthDay }}
                          </span>
                          <br />
                          <p style="font-size: 80%">
                            <b>Суралцагч:</b>
                            {{
                              data.item.student_last_name
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ data.item.student_name }}
                            <br />
                            <b>Эхлэх:</b>
                            {{
                              data.item.course_hw_start_date | dateYearMonthDay
                            }}
                            {{ data.item.course_hw_start_time }}
                            <br />
                            <b>Дуусах:</b>
                            {{
                              data.item.course_hw_end_date | dateYearMonthDay
                            }}
                            {{ data.item.course_hw_end_time }}
                            <br />
                            <b>Үнэлгээ:</b>
                            {{ data.item.course_hw_stud_hw_grade }}
                            <br />
                            <b>Багш:</b>
                            {{
                              data.item.employee_teacher_last_name
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ data.item.employee_teacher_name }}
                            <br />
                            <b> Цаг: </b>
                            {{ data.item.course_hw_course_hour }}-р цаг
                            <br />
                          </p>
                        </div>
                        <div class="col-md-2">
                          <span style="font-size: 80%">
                            <b-iconstack
                              font-scale="2"
                              v-show="data.item.course_hw_stud_hw_status == 0"
                              style="float: right"
                              variant="warning"
                              @click.prevent="viewMoreInfo(data.item)"
                            >
                              <b-icon stacked icon="square"></b-icon>
                              <b-icon
                                stacked
                                icon="bell-fill"
                                scale="0.75"
                              ></b-icon>
                            </b-iconstack>
                          </span>
                        </div>
                      </div>
                    </template> -->
                        <template #cell(contact_info)="data">
                          <b-badge
                            variant="info"
                            class="text-wrap"
                            style="width: 8rem"
                            v-if="data.item.contact_info !== null"
                          >
                            {{ data.item.contact_info }}
                          </b-badge>
                          <b-badge
                            variant="warning"
                            class="text-wrap"
                            style="width: 8rem"
                            v-else
                          >
                            Мэдээлэл бүртгэгдээгүй.
                          </b-badge>
                        </template>
                        <template #cell(user_created_at)="data">
                          {{ data.item.user_created_at | dateYearMonthDay }}
                        </template>
                        <template #cell(actions)="data">
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editSchoolEntrant(data.item)"
                            ><i class="fas fa-edit"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deductibleSchoolEntrant(data.item)"
                            v-b-popover.hover.top="'Хүлээлгийн горимоос хасах!'"
                            title="Хүлээлгийн горимоос хасах"
                            ><i class="fas fa-arrow-alt-circle-left"></i
                          ></a>
                        </template>
                      </b-table>
                    </b-overlay>
                  </div>
                </div>
                <div class="col-lg-4 mt-1">
                  <div class="card card-primary">
                    <div class="card-header">
                      <h3 class="card-title" v-show="!editMode">
                        Элсэгч нэмэх
                      </h3>
                      <h3 class="card-title" v-show="editMode">
                        Элсэгчийн мэдээлэл засах
                      </h3>
                    </div>
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form
                      @submit.prevent="
                        !editMode
                          ? createSchoolEntrant()
                          : updateSchoolEntrant()
                      "
                    >
                      <div class="card-body">
                        <div class="form-group">
                          <label>Ургийн овог</label>
                          <input
                            type="text"
                            name="profile_family_name"
                            v-model="form.profile_family_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has(
                                  'profile_family_name'
                                ),
                              },
                            ]"
                            placeholder="Ургийн овог"
                          />
                          <has-error
                            :form="form"
                            field="profile_family_name"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Овог</label>
                          <input
                            type="text"
                            name="profile_last_name"
                            v-model="form.profile_last_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  form.errors.has('profile_last_name'),
                              },
                            ]"
                            placeholder="Овог"
                          />
                          <has-error
                            :form="form"
                            field="profile_last_name"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Нэр</label>
                          <input
                            type="text"
                            name="user_name"
                            v-model="form.user_name"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('user_name'),
                              },
                            ]"
                            placeholder="Нэр"
                          />
                          <has-error :form="form" field="user_name"></has-error>
                        </div>
                        <!-- <div class="form-group">
                          <label>Элсэлтийн төрөл</label>
                          <b-form-select
                            v-model="form.payment_type"
                            :options="payment_types"
                            text-field="text"
                            value-field="value"
                          >
                          </b-form-select>
                          <has-error
                            :form="form"
                            field="payment_type"
                          ></has-error>
                        </div> -->
                        <div class="form-group">
                          <label>Төлбөр сонгох</label>
                          <multiselect
                            v-model="form.payment_value"
                            deselect-label="Хасах"
                            :options="valueOfPayments"
                            :searchable="true"
                            :close-on-select="true"
                            track-by="payment_value"
                            label="payment_value"
                            :show-labels="true"
                            placeholder="Тухайн ангид тохирох төлбөрийг сонгоно уу?"
                            :allow-empty="false"
                            select-label="Сонгох"
                            :custom-label="nameValueOfPayment"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              <strong
                                >({{ option.my_year_info.start_date }}-{{
                                  option.my_year_info.start_date
                                }}) {{ option.payment_name }}</strong
                              >
                              <strong> {{ option.payment_value }}</strong>
                              <strong> сонгогдлоо</strong>
                            </template>
                          </multiselect>
                        </div>
                        <div class="form-group">
                          <label>Хүйс</label>
                          <b-form-select
                            v-model="form.student_gender"
                            :options="genderOptions"
                            text-field="text"
                            value-field="value"
                          >
                          </b-form-select>
                          <has-error
                            :form="form"
                            field="student_gender"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Холбоо барих мэдээлэл</label>
                          <b-form-textarea
                            id="textarea"
                            v-model="form.contact_info"
                            placeholder="Холбоо барих мэдээлэл(Хууль ёсны асран хамгаалагчийн утас, имэйл гэх мэт)"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                          <has-error
                            :form="form"
                            field="contact_info"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Регистрийн дугаар</label>
                          <b-form-input
                            v-model="form.student_reg"
                            id="inline-form-input-student_reg"
                            placeholder="Регистрийн дугаар"
                            max="10"
                            :state="validation"
                          ></b-form-input>
                          <has-error
                            :form="form"
                            field="student_reg"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Имэйл</label>
                          <input
                            type="email"
                            name="user_email"
                            v-model="form.user_email"
                            :class="[
                              'form-control',
                              {
                                'is-invalid': form.errors.has('user_email'),
                              },
                            ]"
                            placeholder="төгсөх он_нэр.овгийн эхний үсэг@selbe.edu.mn (Он хоёр оронгоор 2034 он гэвэл 34_)"
                          />
                          <has-error
                            :form="form"
                            field="user_email"
                          ></has-error>
                        </div>
                        <div class="form-group">
                          <label>Анги</label>
                          <b-form-select
                            v-model="form.study_class"
                            :options="schoolClassesCheck"
                            text-field="text"
                            value-field="value"
                          >
                          </b-form-select>
                          <has-error :form="form" field="value"></has-error>
                        </div>
                      </div>
                      <!-- /.card-body -->
                      <div class="card-footer">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          v-show="!editMode"
                        >
                          Хадгалах
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          v-show="editMode"
                        >
                          Засах
                        </button>
                        <button
                          class="btn btn-warning"
                          v-show="editMode"
                          @click.prevent="cancelEdit"
                        >
                          Болих
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="timeline">
              <!-- v-if="this.userInfo.name !== 'agermankg'" -->
              <div class="row">
                <div class="col-lg-12 mt-1">
                  <!-- <div class="card-header">
                    <h3 class="card-title">Элсэлтийн бүртгэл</h3>
                    <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student>
                  </div> -->
                  <div class="card-body table-responsive p-0">
                    <table
                      class="table table-hover table-striped table-valign-middle"
                      style="font-size: 80%"
                    >
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Овог</th>
                          <th>Нэр</th>
                          <th>Хүйс</th>
                          <th>РД</th>
                          <th>Имэйл</th>
                          <th>Анги</th>
                          <th>Холбоо барих мэдээлэл</th>
                          <th>Үүсгэсэн</th>
                          <th>Үйлдлүүд</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="odd"
                          v-for="(
                            schoolEntrant, index
                          ) in schoolDeductibleEntrantsCheck"
                          :key="schoolEntrant.user_id"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.profile_last_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.user_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.student_gender }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.student_reg }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.user_email }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="warning"
                              class="text-wrap"
                              v-if="schoolEntrant.study_class === '-1'"
                            >
                              Цэцэрлэг
                            </b-badge>
                            <b-badge
                              variant="success"
                              class="text-wrap"
                              v-else-if="schoolEntrant.study_class === '0'"
                            >
                              Бэлтгэл анги
                            </b-badge>
                            <b-badge variant="info" class="text-wrap" v-else
                              >{{ schoolEntrant.study_class }} анги
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="info"
                              class="text-wrap"
                              style="width: 8rem"
                              v-if="schoolEntrant.contact_info !== null"
                            >
                              {{ schoolEntrant.contact_info }}
                            </b-badge>
                            <b-badge
                              variant="warning"
                              class="text-wrap"
                              style="width: 8rem"
                              v-else
                            >
                              Мэдээлэл бүртгэгдээгүй.
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ schoolEntrant.created_at | dateYearMonthDay }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-success"
                              @click="restoreSchoolEntrant(schoolEntrant)"
                              v-b-popover.hover.top="
                                'Хүлээлгийн горим идэвхижүүлэх!'
                              "
                              title="Хүлээлгийн горим"
                              ><i class="fa fa-check-circle"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      school_classes: [
        { value: "-1", text: "Цэцэрлэг" },
        { value: "0", text: "Бэлтгэл анги" },
        { value: "1", text: "1-р анги" },
        { value: "2", text: "2-р анги" },
        { value: "3", text: "3-р анги" },
        { value: "4", text: "4-р анги" },
        { value: "5", text: "5-р анги" },
        { value: "6", text: "6-р анги" },
        { value: "7", text: "7-р анги" },
        { value: "8", text: "8-р анги" },
        { value: "9", text: "9-р анги" },
        { value: "10", text: "10-р анги" },
        { value: "11", text: "11-р анги" },
        { value: "12", text: "12-р анги" },
      ],
      editMode: false,
      schoolentrants: [],
      deductibleEntrants: [],
      valueOfPayments: [],
      //gender
      genderOptions: [
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      //payment_type
      payment_types: [
        { value: 0, text: "Нөхөн" },
        { value: 1, text: "Үндсэн" },
        // { value: 2, text: "Дэвших" },
      ],
      form: new Form({
        id: null,
        user_id: "",
        user_email: "",
        profile_family_name: "",
        profile_last_name: "",
        contact_info: "", //Холбоо барих мэдээлэл
        user_name: "",
        study_class: "",
        student_gender: "", //Эмэгтэй, эрэгтэй
        student_reg: "",
        // payment_type: "", //Элсэлтийн төрөл: Нөхөн, үндсэн
        payment_value: "", //Элсэлтийн төрөл: хамаарах төлбөрийн утгууд
      }),

      waitEntruntLoaded: true,
      // Хүснэгтийн тохиргоо
      totalRowsSchoolEntrunt: 1,
      currentPageSchoolEntrunt: 1,
      perPageSchoolEntrunt: 25,
      pageOptionsSchoolEntrunt: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      sortBySchoolEntrunt: "",
      sortDescSchoolEntrunt: false,
      sortDirectionSchoolEntrunt: "asc",
      filterIgnoredFieldsSchoolEntrunt: ["course_hw_stud_hw_parent_comment"],
      filterSchoolEntrunt: null,
      filterOnSchoolEntrunt: [],
      //                       <th>Үүсгэсэн</th>
      //                       <th>Үйлдлүүд</th>
      fieldsSchoolEntrunt: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "profile_last_name",
          label: "Овог",
          sortable: true,
        },
        {
          key: "student_gender",
          label: "Хүйс",
          sortable: true,
        },
        {
          key: "status1",
          label: "Элсэлтийн төрөл",
          sortable: true,
        },
        {
          key: "student_reg",
          label: "РД",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Имэйл",
          sortable: true,
        },
        {
          key: "study_class",
          label: "Анги",
          sortable: true,
        },
        {
          key: "contact_info",
          label: "Холбоо барих мэдээлэл",
          sortable: true,
        },
        {
          key: "user_created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    validation() {
      if (this.form.student_reg === null) {
        return false;
      } else {
        return this.form.student_reg.length == 10;
      }
    },
    sortOptionsSchoolEntrunt() {
      // Create an options list from our fields
      return this.fieldsSchoolEntrunt
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    schoolentrantsCheck: function () {
      // Create an options list from our fields
      let a = [];
      if (this.userInfo.name == "agermankg") {
        // this.fieldsSchoolEntrunt
        a = this.schoolentrants.filter((el) => el.study_class === "-1");
        //         this.schoolentrants.forEach(element => {
        //           if (element.study_class == "0") {
        //
        //           }
        //         });
      } else if (
        this.userInfo.name == "cipalprin" ||
        this.userInfo.name == "minadcurri" ||
        this.userInfo.name == "hr" ||
        this.userInfo.name == "adminOfficer"
      ) {
        a = this.schoolentrants;
      } else if (
        this.userInfo.name == "agerman" ||
        this.userInfo.name == "reg_emp"
      ) {
        a = this.schoolentrants.filter((el) => el.study_class !== "-1");
      }
      // console.log(a)
      return a;
    },
    schoolDeductibleEntrantsCheck: function () {
      // Create an options list from our fields

      let a = [];
      if (this.userInfo.name == "agermankg") {
        // this.fieldsSchoolEntrunt
        a = this.deductibleEntrants.filter((el) => el.study_class === "-1");
      } else if (
        this.userInfo.name == "cipalprin" ||
        this.userInfo.name == "minadcurri" ||
        this.userInfo.name == "hr" ||
        this.userInfo.name == "adminOfficer"
      ) {
        a = this.deductibleEntrants;
      } else if (
        this.userInfo.name == "agerman" ||
        this.userInfo.name == "reg_emp"
      ) {
        a = this.deductibleEntrants.filter((el) => el.study_class !== "-1");
      }
      // console.log(a)
      return a;
    },
    // validationPhoneNumber() {
    //   if (this.form.student_phone_number === null) {
    //     return false;
    //   } else {
    //     return this.form.student_phone_number.length == 8;
    //   }
    // },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
    years: function () {
      return this.$store.state.years;
    },
    schoolClassesCheck: function () {
      //   console.log(this.school_classes);
      //   console.log(this.school_classes[0], "000000000");
      //   console.log(this.school_classes[1], "111111111");
      let a = [];
      if (this.userInfo.name == "agermankg") {
        a = this.school_classes.filter((el) => el.value === "-1");
        // console.log(a);
        // a.push(this.school_classes[0]);
      } else if (
        this.userInfo.name == "cipalprin" ||
        this.userInfo.name == "minadcurri" ||
        this.userInfo.name == "hr" ||
        this.userInfo.name == "adminOfficer"
      ) {
        a = this.school_classes;
      } else if (
        this.userInfo.name == "agerman" ||
        this.userInfo.name == "reg_emp"
      ) {
        a = this.school_classes.filter((el) => el.value !== "-1");
      }
      return a;
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    //b-table тохирго
    onFilteredSchoolEntrunt(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsSchoolEntrunt = filteredItems.length;
      this.currentPageSchoolEntrunt = 1;
    },
    getAllEntrants() {
      axios
        .get("/reg_emp/getAllEntrants")
        .then((response) => {
          //   console.log(response.data.schoolentrants);
          this.schoolentrants = response.data.schoolentrants;
          this.deductibleEntrants = response.data.deductibleEntrants;
          this.valueOfPayments = response.data.valueOfPayments;
          //console.log(this.schoolentrants);
          this.totalRowsSchoolEntrunt = this.schoolentrantsCheck.length;
          this.waitEntruntLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log(schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
      //Бүх хэрэглэгчийн төлбөрийн мэдээллийг зассаны дараа оруулна.
      this.form.payment_value = this.valueOfPayments.filter(
        (el) => el.id == schoolEntrant.f_p_payment_id
      )[0];
    },
    updateSchoolEntrant() {
      //   console.log(this.form);
      this.form
        .put("/reg_emp/updateEntrant/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolStudents");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    createSchoolEntrant() {
      this.form
        .post("/reg_emp/createEntrant")
        .then((response) => {
          this.$toastr.s("Элсэгчийг амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolStudents");
          this.form.reset();
        })
        .catch((error) => {
          this.$toastr.e(
            "Өмнө нь бүртгэсэн эсэхийг нягтална уу. Мөн мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа:" + error
          );
        });
    },
    deductibleSchoolEntrant(schoolEntrant) {
      //   console.log(schoolEntrant);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Хүлээлгийн горимоос хасах гэж байна.!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, хас!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/reg_emp/deductibleSchoolEntrant/" + schoolEntrant.user_id)
            .then((response) => {
              Fire.$emit("loadSchoolStudents");
              this.$toastr.s("Хүлээлгийн төлвөөс хаслаа.", "Амжилттай");
              Swal.fire(
                "Шилжүүлсэн!",
                "Суралцагчийг хүлээлгийн төлвөөс хаслаа.",
                "success"
              );
            })
            .catch(() => {
              this.$toastr.e(
                "Элсэгчийг хүлээлгийн төлвөөс хасч чадсангүй.",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    restoreSchoolEntrant(schoolEntrant) {
      //   console.log(schoolEntrant);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Хүлээлгийн горимд шилжүүлэх гэж байна.!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/reg_emp/restoreSchoolEntrant/" + schoolEntrant.user_id)
            .then((response) => {
              Fire.$emit("loadSchoolStudents");
              this.$toastr.s("Хүлээлгийн төлөвт бүртгэлээ.", "Амжилттай");
              Swal.fire(
                "Шилжүүлсэн!",
                "Суралцагчийг хүлээлгийн төлөвт бүртгэлээ.",
                "success"
              );
            })
            .catch(() => {
              this.$toastr.e(
                "Элсэгчийг хүлээлгийн төлөвт шилжүүлж чадсангүй.",
                "Алдаа гарлаа."
              );
            });
        }
      });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    nameValueOfPayment({ payment_name, payment_value, my_year_info }) {
      return `(${my_year_info.start_date} - ${my_year_info.finish_date})${payment_name} — [${payment_value}] `;
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadSchoolStudents", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
