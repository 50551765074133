<template>
  <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col lg="3" class="pb-2">
              <h3 class="card-title">
                Барилга байгууламжийн цагийн мэдээлэл
              </h3></b-col
            >
            <b-col lg="3" class="pb-2">
              <b-row>
                <b-col sm="10">
                  <multiselect
                    v-model="selectYear"
                    :options="years"
                    :multiple="false"
                    :custom-label="customYears"
                    track-by="id"
                    label="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээлийн жил сонгох"
                    :allow-empty="false"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.start_date }}</strong
                      ><strong> {{ option.finish_date }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <b-button
                    variant="success"
                    size="sm"
                    @click.prevent="getAllTimeTableInfoSelectYear()"
                    >Хайх</b-button
                  >
                </b-col>
              </b-row>
            </b-col>

            <b-col lg="3" class="pb-2">
              <b-button
                size="sm"
                variant="success"
                @click.prevent="createTimeTable()"
                >Цаг бүртгэх</b-button
              ></b-col
            >
            <b-col lg="3" class="pb-2">
              <b-button
                size="sm"
                variant="success"
                @click.prevent="copyTimeTableFromYear()"
                >Цаг бүртгэх(Өмнөх оныг дараа онд)</b-button
              ></b-col
            >
          </b-row>
          <!-- <b-row class="pb-2">
            <b-col lg="12">
              5.0/3/4 - гэдэг нь 5 цагаар 3 бүлэгт 4 долоо хоног орно гэсэн утга
              илэрхийлнэ. Монгол багшийн 7 хоногийн танхимд заах цаг 19, гадаад
              багшийн цаг 25.
            </b-col>
            <b-col lg="12"> Монгол хөтөлбөр. </b-col>
          </b-row> -->
        </div>
        <div class="card-body table-responsive pb-2">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Цагийн нэр</th>
                <th>Эхлэх цаг</th>
                <th>Дуусах цаг</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody style="font-size: 80%">
              <tr
                class="odd"
                v-for="(time, index) in getAllTimeTables"
                :key="time.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="secondary" class="text-wrap">
                    <!-- style="width: 8rem" -->
                    {{ time.name }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="secondary"
                    class="text-wrap"
                    style="width: 6rem"
                  >
                    {{ time.start_time }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="secondary"
                    class="text-wrap"
                    style="width: 6rem"
                  >
                    {{ time.finish_time }}
                  </b-badge>
                </td>

                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(time)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editTimeTable(time)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteTimeTable(time)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot style="font-size: 70%">
              <tr role="row">
                <th colspan="16" style="text-align: right">Нийт</th>
                <th>
                  <b-badge variant="primary" class="text-wrap">

                    {{ fullHourLessonHicheel }} ц
                  </b-badge>
                </th>

                <th>
                  <b-badge variant="warning" class="text-wrap">

                    {{ fullCountTeacherHicheel }} б
                  </b-badge>
                </th>

                <th>
                  <b-badge variant="success" class="text-wrap">

                    {{ existCountTeacherHicheel }} б
                  </b-badge>
                </th>
                <th>
                  <b-badge variant="danger" class="text-wrap">
                    {{ teachersZuruuHicheel() }} б
                  </b-badge>
                </th>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-time-table"
      size="sm"
      :title="editMode == false ? 'Цаг бүртгэх' : 'Цаг засах'"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      hide-footer
      @hidden="cancelEdit"
    >
      <div class="card card-primary">
        <form
          @submit.prevent="!editMode ? storeTimeTable() : updateTimeTable()"
        >
          <div class="card-body">
            <b-row>
              <b-col lg="12">
                <div class="form-group">
                  <small class="col-lg-12 text-danger">Хичээлийн жил</small>
                  <multiselect
                    v-model="form.year_id"
                    :options="years"
                    :multiple="false"
                    :custom-label="customYears"
                    track-by="id"
                    label="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээлийн жил сонгох"
                    :allow-empty="false"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.start_date }}</strong
                      ><strong> {{ option.finish_date }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                  <small class="col-lg-12 text-danger">Цагийн нэр</small>
                  <div class="col-lg-12">
                    <b-form-input v-model="form.name"></b-form-input>
                  </div>
                  <div class="col-lg-12">
                    <label>Эхлэх цаг</label>
                    <b-form-timepicker
                      v-model="form.start_time"
                      v-bind="labels['de']"
                      locale="de"
                    ></b-form-timepicker>
                  </div>
                  <div class="col-lg-12">
                    <label>Дуусах цаг</label>
                    <b-form-timepicker
                      v-model="form.finish_time"
                      v-bind="labels['de']"
                      locale="de"
                    ></b-form-timepicker>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="!editMode">
              Хадгалах
            </button>
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Засах
            </button>
            <button
              class="btn btn-warning"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal
      id="modal-time-table-copy"
      size="sm"
      title="Шинэ онд цагийн хуваарийг хуулж тохируулах"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      hide-footer
      @hidden="cancelEditCopy"
    >
      <div class="card card-primary">
        <form @submit.prevent="copyStoreTimeTableFromYear()">
          <div class="card-body">
            <b-row>
              <b-col lg="12">
                <div class="form-group">
                  <small class="col-lg-12 text-danger">Хичээлийн жил</small>
                  <multiselect
                    v-model="formCopy.year_id"
                    :options="years"
                    :multiple="false"
                    :custom-label="customYears"
                    track-by="id"
                    label="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээлийн жил сонгох"
                    :allow-empty="false"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.start_date }}</strong
                      ><strong> {{ option.finish_date }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                  <small class="col-lg-12 text-danger"
                    >Дэвшүүлэх хичээлийн жил</small
                  >
                  <multiselect
                    v-model="formCopy.year_id_up"
                    :options="years"
                    :multiple="false"
                    :custom-label="customYears"
                    track-by="id"
                    label="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    placeholder="Хичээлийн жил сонгох"
                    :allow-empty="false"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.start_date }}</strong
                      ><strong> {{ option.finish_date }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary">Хадгалах</button>
            <button class="btn btn-warning" @click.prevent="cancelEditCopy">
              Болих
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      editMode: false,

      getAllTimeTables: [],
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "warning",
      footerTextVariant: "dark",
      selectYear: "",
      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        name: "",
        start_time: "",
        finish_time: "",
      }),
      formCopy: new Form({
        year_id: "",
        year_id_up: "",
      }),
      locale: "de",
      labels: {
        de: {
          labelHours: "Цаг",
          labelMinutes: "Минут",
          labelSeconds: "Секунд",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Сонгосон",
          labelNoTimeSelected: "Цаг сонгох",
          labelCloseButton: "Хаах",
        },
      },
    };
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  watch: {},
  methods: {
    getAllTimeTableInfo() {
      axios
        .post("/curriculumadministrator/getAllTimeTableInfo", {
          yearInfo: this.selectYear,
        })
        .then((response) => {
          this.getAllTimeTables = response.data.getAllTimeTables;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Оноор шүүж авах
    getAllTimeTableInfoSelectYear() {
      if (this.selectYear != "") {
        this.getAllTimeTables = [];
        axios
          .post("/curriculumadministrator/getAllTimeTableInfo", {
            yearInfo: this.selectYear,
          })
          .then((response) => {
            // console.log(response.data);
            this.getAllTimeTables = response.data.getAllTimeTables;
            this.$toastr.s(
              "Хичээлийн жилд харгалзах сургалтын төлөвлөгөөг дуудлаа!",
              "Амжилттай."
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$toastr.e("Та хичээлийн жил сонгоно уу!", "Анхааралтай хийлдээ.");
      }
    },
    createTimeTable() {
      this.form.reset();
      this.$bvModal.show("modal-time-table");
    },
    storeTimeTable() {
      this.form
        .post("/curriculumadministrator/storeTimeTable")
        .then((response) => {
          this.$toastr.s("Амжилттай хадгалсан.", "Амжилттай");
          // this.getAllInfoSelectYearCreateOrUpdate(this.form.tp_id);
          Fire.$emit("loadAllTimeTables");
          this.cancelEdit();
        })
        .catch(() => {
          this.$toastr.e(
            "Цаг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },

    editTimeTable(type) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", type);
      this.editMode = true;
      this.form.reset();
      this.form.fill(type);

      this.years.forEach((element) => {
        if (element.id == type.year_id) {
          this.form.year_id = element;
        }
      });
      this.$bvModal.show("modal-time-table");
    },
    updateTimeTable() {
      //   console.log(this.form);
      this.form
        .put("/curriculumadministrator/updateTimeTable/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTimeTables");
          this.cancelEdit();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = false;
      this.$bvModal.hide("modal-time-table");
    },

    deleteTimeTable(type) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + type);

      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Цагийн устгахдаа итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Цуцлах`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete("/curriculumadministrator/deleteTimeTable/" + type.id)
            .then((res) => {
              //   console.log(res.data.success);
              Fire.$emit("loadAllTimeTables");
            })
            .catch((err) => console.log(err));
        }
      });
    },

    customYears({ start_date, finish_date }) {
      return `${start_date} : ${finish_date}`;
    },

    //Хичээлийн жилд харгалзах утгуудыг хуулах
    copyTimeTableFromYear() {
      this.formCopy.reset();
      this.$bvModal.show("modal-time-table-copy");
    },
    copyStoreTimeTableFromYear() {
      this.formCopy
        .post("/curriculumadministrator/copyStoreTimeTableFromYear")
        .then((response) => {
          this.$toastr.s("Амжилттай хадгалсан.", "Амжилттай");
          // this.getAllInfoSelectYearCreateOrUpdate(this.form.tp_id);
          Fire.$emit("loadAllTimeTables");
          this.cancelEditCopy();
        })
        .catch(() => {
          this.$toastr.e(
            "Цаг үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEditCopy() {
      this.formCopy.reset();
      this.$bvModal.hide("modal-time-table-copy");
    },
  },
  created() {
    this.getAllTimeTableInfo();
    Fire.$on("loadAllTimeTables", () => {
      this.getAllTimeTableInfo();
    });
  },
};
</script>

<style></style>
