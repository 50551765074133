<template>
  <div class="row pt-3">
    <div class="col-lg-8 d-flex align-items-center">
      <b-button size="sm" @click="resetFields" variant="outline-primary" class="mr-2">
        <b-icon icon="check-all" aria-hidden="true"></b-icon>
        Бүх талбар
      </b-button>
      <multiselect
        v-model="fieldName"
        :options="fieldList"
        @select="setFieldList"
        :multiple="true"
        track-by="value"
        label="label"
        :searchable="true"
        :allowEmpty="false"
        :close-on-select="false"
        :show-labels="true"
        placeholder="Excel файл руу гаргах баганаа сонгоно уу."
        :allow-empty="true"
        deselect-label="Хасах"
        select-label="Сонгох"
        selected-label="Сонгогдсон"
        class="mr-2 w-50"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <strong>{{ option.month }}</strong>
          <strong> сонгогдлоо</strong>
        </template>
      </multiselect>
      <download-excel
        class="btn btn-success btn-sm mr-2"
        :data="json_data"
        :fields="json_fields"
        worksheet="Суралцагч"
        name="Суралцагчид.xls"
      >
        <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
        Файл татах
      </download-excel>
    </div>
    <div class="col-lg-4">
      <b-button
        size="sm"
        variant="outline-info"
        class="float-right"
        @click.prevent="addParentBoard()"
      >
        <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>
        Эцэг эхийн зөвлөл
      </b-button>
    </div>
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Суралцагчдын жагсаалт</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <div class="row">
            <div class="col-lg-8 d-flex align-items-center my-3 pl-3">
              <multiselect
                v-model="selectedClass"
                :options="vuexDataSchoolClass"
                :custom-label="customSchoolClassName"
                :multiple="false"
                track-by="id"
                label="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                placeholder="Ангиа сонгоно уу."
                :allow-empty="true"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                class="mr-2 w-50"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.full_name }}</strong>
                  <strong> сонгогдлоо</strong>
                </template>
              </multiselect>

              <b-button
                size="sm"
                @click="getTeacherStudents(selectedClass)"
                variant="primary"
                class="mr-2"
              >
                <b-icon icon="check-all" aria-hidden="true"></b-icon>
                Шүүх
              </b-button>
            </div>
            <div class="col-lg-4 my-3 pr-3">
              <b-button
                size="sm"
                variant="outline-primary"
                class="float-right"
                @click.prevent="addClassImage()"
              >
                <b-icon icon="image" aria-hidden="true"></b-icon> Ангийн зураг
              </b-button>
            </div>
          </div>
          <table class="table table-hover table-sm">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Профайл зураг</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Жил</th>
                <th>Суудал баталгаажилт(Дараа жил)</th>
                <th>Имэйл</th>
                <th>Анги</th>
                <th>Үүсгэсэн</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                :class="{ 'table-warning': isAll }"
                v-for="(schoolstudent, index) in schoolstudents"
                :key="index"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a href="#" @click.prevent="changeImage(schoolstudent)">
                    <img
                      v-if="schoolstudent.student_profile_photo_url_sm != null"
                      :src="schoolstudent.student_profile_photo_url_sm"
                      alt="user-avatar"
                      class="direct-chat-img"
                    />
                    <img
                      v-else
                      src="/images/users/user.png"
                      alt="user-avatar"
                      class="direct-chat-img"
                    />
                  </a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click.prevent="createParent(schoolstudent)"
                  >
                    {{ schoolstudent.student_last_name }}</a
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-success"
                    @click.prevent="editStudentInfo(schoolstudent)"
                  >
                    {{ schoolstudent.student_name }}
                  </a>
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="resetPass(schoolstudent)"
                  >
                    <i class="fa fa-key"></i>
                  </a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.start_date | dateYear }} :
                  {{ schoolstudent.finish_date | dateYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="success" v-if="schoolstudent.confirm_next_year == 1"
                    >Баталгаажив</b-badge
                  >
                  <b-badge variant="danger" v-else>Баталгаажаагүй</b-badge>
                  <br />
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="confirmNextYear(schoolstudent)"
                  >
                    <b-icon icon="calendar-plus" aria-hidden="true"></b-icon>
                  </a>
                  <a
                    v-if="schoolstudent.confirm_next_year_file"
                    href="#"
                    v-b-toggle.sidebar-file
                    @click="viewFile(schoolstudent.confirm_next_year_file)"
                  >
                    {{ schoolstudent.confirm_next_year_file.split("-")[1] }}
                  </a>
                  <span v-else>Файл хавсаргаагүй</span>

                  <!-- <a
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="resetPass(schoolstudent)"
                  >
                    <i class="fa fa-key"></i>
                  </a> -->

                  <!-- <a
                    href="#"
                    class="badge bg-primary"
                    @click.prevent="resetPass(schoolstudent)"
                  >
                    <i class="fa fa-key"></i>
                  </a> -->
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="warning">
                    {{ schoolstudent.email }}
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.full_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolstudent.created_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <td class="dtr-control sorting_1" tabindex="0">Хүйс(Эрэгтэй)</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAMale }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">Баталгаажсан</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ selectedClassConfirmParent }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <td class="dtr-control sorting_1" tabindex="0">Хүйс(Эмэгтэй)</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ countAFeMale }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">Баталгаажаагүй</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ selectedClassNotConfirmParent }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
              <tr class="odd">
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="2"
                  align="right"
                ></td>
                <td class="dtr-control sorting_1" tabindex="0">Нийт</td>
                <td class="dtr-control sorting_1" tabindex="0">{{ countA }}</td>
                <td class="dtr-control sorting_1" tabindex="0">Хувь</td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ selectedClassConfirmParentPercent }} %
                </td>
                <td class="dtr-control sorting_1" tabindex="0"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <create-parent
      v-show="createMode"
      :forParentChild="forParentChild"
      :parentDataProps="parentDataProps"
    />
    <b-modal
      v-show="uploadMode"
      size="m"
      id="modal-profileImg"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Зураг солих"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Суралцагчын зураг солих</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive" v-if="form != null">
                <div class="row mt-2">
                  <div class="col-sm-12">
                    <multiselect
                      v-model="yearId"
                      deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                      track-by="year_id"
                      label="year_id"
                      :custom-label="customYearName"
                      placeholder="Хичээлийн жилээр шүүж харах"
                      :options="years"
                      :searchable="true"
                      :allow-empty="false"
                      @input="studMigProfile(yearId)"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong
                          >{{ option.my_year_info.start_date | dateYear }}-{{
                            option.my_year_info.finish_date | dateYear
                          }}
                          - хичээлийн жил
                        </strong></template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-2">
                    <b-avatar
                      :src="getSelectedProfileImg"
                      variant="light"
                      size="30"
                      class="mr-1"
                    ></b-avatar>
                  </div>
                  <div class="col-sm-10">
                    <b-form-file
                      id=""
                      browse-text="Зураг солих"
                      size="sm"
                      @change="getProfileImg"
                    ></b-form-file>
                    <h6 class="text-success">
                      {{ successUpload }}
                    </h6>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-6">
                    <a
                      href="#"
                      class="btn btn-block btn-primary btn-xs"
                      @click="uploadProfile"
                      v-if="loaded"
                      >Хадгалах</a
                    >
                  </div>
                  <div class="col-sm-6">
                    <a
                      href="#"
                      class="btn btn-block btn-secondary btn-xs"
                      @click.prevent="uploadProfileCancel"
                      v-if="loaded"
                      >Цуцлах</a
                    >
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpload()" variant="danger" type="reset"> Хаах </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <!-- Суралцагчдын мэдээлэл засах -->

    <b-modal
      size="xl"
      id="modal-student-info"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчийн мэдээлэл засах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <!-- <div class="card-header">
                <h3 class="card-title">Суралцагчын зураг солих</h3>
              </div> -->
              <!-- /.card-header -->
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a class="nav-link active" href="#activity" data-toggle="tab"
                          >Суралцагчийн мэдээлэл</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#edit" data-toggle="tab"
                          >Мэдээлэл засах</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="active tab-pane" id="activity">
                        <!-- Post -->
                        <div class="post">
                          <div class="user-block">
                            <img
                              v-if="getMyInfoStudent.student_profile_photo_url != null"
                              :src="getMyInfoStudent.student_profile_photo_url"
                              alt="user-avatar"
                              class="img-circle img-bordered-sm"
                            />
                            <img
                              v-else
                              src="/images/users/user.png"
                              alt="user-avatar"
                              class="img-circle img-bordered-sm"
                            />
                            <span class="username">
                              <a href="#"
                                >{{ getMyInfoStudent.student_last_name }}
                                {{ getMyInfoStudent.student_name }}</a
                              >
                            </span>
                            <span class="description"
                              >Системд бүртгэсэн -
                              {{
                                getMyInfoStudent.user_created_at | dateYearMonthDay
                              }}</span
                            >
                            <table class="table table-borderless">
                              <thead>
                                <tr>
                                  <th class="col-sm-3"></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-right text-bold">Регистр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_reg }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Ургийн овог:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_family_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Эцэг/Эх-ийн нэр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_last_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Нэр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Хүйс:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_gender }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Төрсөн өдөр:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_birthDate }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Иргэншил:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_citizenship }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Төрсний гэрчилгээний дугаар:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_birth_ger_num }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Эх хэл:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_ekh_khel }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Өнчин эсэх:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_unchin_esekh }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Асран хамгаалагч:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_asran_kham }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">
                                    Асран хамгаалагчын мэдээлэл:
                                  </td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_asran_kham_med }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right text-bold">Цусны бүлэг:</td>
                                  <td class="text-left">
                                    {{ getMyInfoStudent.student_tsus_bul }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- /.user-block -->
                        </div>
                        <!-- /.post -->
                      </div>
                      <!-- /.tab-pane -->
                      <div class="tab-pane" id="edit">
                        <form>
                          <div class="form-group row">
                            <div class="col-sm-4 form-group">
                              <label>Регистр</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_reg"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_reg'),
                                  },
                                ]"
                                v-model="formEdit.student_reg"
                                placeholder="Регистр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Ургийн овог</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_family_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_family_name'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_family_name"
                                placeholder="Ургийн овог"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Овог</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_last_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_last_name'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_last_name"
                                placeholder="Овог"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Нэр</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_name"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_name'),
                                  },
                                ]"
                                v-model="formEdit.student_name"
                                placeholder="Нэр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Нас</label>
                              <input
                                type="number"
                                class="form-control"
                                name="student_age"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_age'),
                                  },
                                ]"
                                v-model="formEdit.student_age"
                                placeholder="Нас"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Хүйс</label>
                              <b-form-select
                                v-model="formEdit.student_gender"
                                :options="genders"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_gender'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Төрсөн он сар өдөр</label>
                              <input
                                type="date"
                                class="form-control"
                                name="student_birthDate"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_birthDate'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_birthDate"
                                placeholder="Төрсөн он сар өдөр"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Иргэншил</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_citizenship"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_citizenship'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_citizenship"
                                placeholder="Иргэншил"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Төрсний гэрчилгээний дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_birth_ger_num"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_birth_ger_num'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_birth_ger_num"
                                placeholder="Төрсний гэрчилгээний дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Эх хэл</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_ekh_khel"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_ekh_khel'),
                                  },
                                ]"
                                v-model="formEdit.student_ekh_khel"
                                placeholder="Эх хэл"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Цусны бүлэг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_tsus_bul"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_tsus_bul'),
                                  },
                                ]"
                                v-model="formEdit.student_tsus_bul"
                                placeholder="Цусны бүлэг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Асран хамгаалагч</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_asran_kham"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_asran_kham'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_asran_kham"
                                placeholder="Асран хамгаалагч"
                              />
                            </div>
                            <div class="col-sm-8 form-group">
                              <label>Асран хамгаалагчийн мэдээлэл</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_asran_kham_med"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_asran_kham_med'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_asran_kham_med"
                                placeholder="Асран хамгаалагчийн мэдээлэл"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Өнчин эсэх</label>
                              <b-form-select
                                v-model="formEdit.student_unchin_esekh"
                                :options="opts"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_unchin_esekh'
                                    ),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Аймаг, нийслэл</label>
                              <b-form-select
                                v-model="formEdit.student_duureg"
                                value-field="id"
                                text-field="name"
                                v-on:change="getSum"
                                :options="aimagOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_duureg'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Сум, дүүрэг</label>
                              <b-form-select
                                v-model="formEdit.student_khoroo"
                                value-field="id"
                                text-field="name"
                                v-on:change="getKhoroo"
                                :options="sumOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_khoroo'),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Баг, хороо</label>
                              <b-form-select
                                v-model="formEdit.student_khoroolol"
                                value-field="code"
                                text-field="name"
                                :options="khorooOptions"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_khoroolol'
                                    ),
                                  },
                                ]"
                              ></b-form-select>
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Хотхон</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_bair"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_bair'),
                                  },
                                ]"
                                v-model="formEdit.student_bair"
                                placeholder="Хотхон"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Байрны дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_dugaar"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_dugaar'),
                                  },
                                ]"
                                v-model="formEdit.student_dugaar"
                                placeholder="Байрны дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Тоот</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_toot"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_toot'),
                                  },
                                ]"
                                v-model="formEdit.student_toot"
                                placeholder="Тоот"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Амдардаг хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_live_address"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_live_address'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_live_address"
                                placeholder="Амдардаг хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Утасны дугаар</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_phone_number"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_phone_number'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_phone_number"
                                placeholder="Утасны дугаар"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Имэйл хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_email"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_email'),
                                  },
                                ]"
                                v-model="formEdit.student_email"
                                placeholder="Имэйл хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Social хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_social_acc"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_social_acc'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_social_acc"
                                placeholder="Social хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Веб хаяг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_web_site"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_web_site'),
                                  },
                                ]"
                                v-model="formEdit.student_web_site"
                                placeholder="Веб хаяг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Зэрэг</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_bol_zer"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_bol_zer'),
                                  },
                                ]"
                                v-model="formEdit.student_bol_zer"
                                placeholder="Зэрэг"
                              />
                            </div>
                            <div class="col-sm-4 form-group">
                              <label>Шагнал</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_shagnal"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has('student_shagnal'),
                                  },
                                ]"
                                v-model="formEdit.student_shagnal"
                                placeholder="Шагнал"
                              />
                            </div>
                            <div class="col-sm-8 form-group">
                              <label>Шилжсэн шалтгаан</label>
                              <input
                                type="text"
                                class="form-control"
                                name="student_shiljilt_shaltgaan"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid': formEdit.errors.has(
                                      'student_shiljilt_shaltgaan'
                                    ),
                                  },
                                ]"
                                v-model="formEdit.student_shiljilt_shaltgaan"
                                placeholder="Шилжсэн шалтгаан"
                              />
                            </div>
                            <div class="col-sm-12 form-group">
                              <label>Вакцины мэдээлэл</label>
                            </div>
                            <div class="row">
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_1"
                                  v-model="formEdit.student_vac_1"
                                  name="checkbox-student_vac_1"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 1
                                </b-form-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_2"
                                  v-model="formEdit.student_vac_2"
                                  name="checkbox-student_vac_2"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 2
                                </b-form-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <b-form-checkbox
                                  id="checkbox-student_vac_3"
                                  v-model="formEdit.student_vac_3"
                                  name="checkbox-student_vac_3"
                                  value="1"
                                  unchecked-value="0"
                                >
                                  Вакцин 3
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-sm-6">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                @click.prevent="updateStudent()"
                              >
                                Хадгалах
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <!-- /.tab-content -->
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelUpdate()" variant="danger" type="reset"> Хаах </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>

    <!-- Эцэг эхийн зөвлөлийн мэдээлэл -->
    <b-modal
      size="xl"
      id="modal-parent-boards"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Эцэг эхийн зөвлөлийн мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header p-2">
                    <b-container fluid>
                      <b-row>
                        <b-col sm="12">
                          <multiselect
                            v-model="yearIdSchoolParentBoard"
                            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                            track-by="id"
                            label="id"
                            :custom-label="customAllYearName"
                            placeholder="Хичээлийн жилээр шүүж харах"
                            :options="allYears"
                            :searchable="true"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong
                                >{{ option.start_date | dateYear }}-{{
                                  option.finish_date | dateYear
                                }}
                                - хичээлийн жил
                              </strong></template
                            >
                          </multiselect>
                          <multiselect
                            v-model="selectedClassSchoolParentBoard"
                            :options="vuexDataSchoolClass"
                            :custom-label="customSchoolClassName"
                            :multiple="false"
                            track-by="id"
                            label="id"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="true"
                            placeholder="Ангиа сонгоно уу."
                            :allow-empty="true"
                            deselect-label="Хасах"
                            select-label="Сонгох"
                            selected-label="Сонгогдсон"
                            class="mr-2"
                          >
                            <template slot="singleLabel" slot-scope="{ option }">
                              <strong>{{ option.full_name }}</strong>
                              <strong> сонгогдлоо</strong>
                            </template>
                          </multiselect>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="5">
                          <multiselect
                            v-model="formParentHeadmans.profile_parent_id"
                            :options="myClassParents"
                            track-by="id"
                            label="id"
                            :searchable="true"
                            :close-on-select="true"
                            :custom-label="customParentName"
                            placeholder="Эцэг эх сонгох"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            :show-labels="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>
                                {{ option.parent_father_ovog[0] }}.
                                {{ option.parent_father_name }}({{
                                  option.student_name
                                }}-{{ option.school_class_full_name }})</strong
                              ><strong> сонгогдлоо</strong></template
                            >
                          </multiselect>
                        </b-col>
                        <b-col sm="5">
                          <b-select
                            v-model="formParentHeadmans.board_type"
                            :options="options"
                            text-field="name"
                            value-field="value"
                          ></b-select>
                        </b-col>
                        <b-col sm="2">
                          <b-iconstack
                            font-scale="2"
                            @click.prevent="storeParentBoard()"
                            v-if="showEditParentBoard == false"
                          >
                            <b-icon stacked icon="square" variant="info"></b-icon>
                            <b-icon stacked icon="check" variant="success"></b-icon>
                          </b-iconstack>
                          <!-- @click.prevent="trainingUpdate()" -->
                          <b-iconstack font-scale="2" v-if="showEditParentBoard == true">
                            <b-icon stacked icon="square" variant="info"></b-icon>
                            <b-icon
                              stacked
                              scale="0.5"
                              icon="tools"
                              variant="warning"
                            ></b-icon>
                          </b-iconstack>
                          <b-iconstack font-scale="2" @click.prevent="resetParentBoard()">
                            <b-icon stacked icon="square" variant="info"></b-icon>
                            <b-icon
                              stacked
                              icon="x-circle"
                              scale="0.6"
                              variant="danger"
                            ></b-icon>
                          </b-iconstack>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <table class="table table-hover table-sm">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <!-- <th>Профайл зураг</th> -->
                          <th>Аав</th>
                          <th>Ээж</th>
                          <th>Анги/Сургууль</th>
                          <th>Үүрэг</th>
                          <th>Үйлдэл</th>
                          <th>Үүсгэсэн</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="odd"
                          :class="{
                            'table-warning': isAll,
                          }"
                          v-for="(parent, index) in myClassParentboards"
                          :key="index"
                        >
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>

                          <td class="dtr-control sorting_1" tabindex="0">
                            <span v-if="parent.my_parent_info">
                              <img
                                v-if="
                                  parent.my_parent_info.parent_father_profile_photo_url !=
                                  null
                                "
                                :src="
                                  parent.my_parent_info.parent_father_profile_photo_url
                                "
                                alt="user-avatar"
                                class="direct-chat-img"
                              />
                              <img
                                v-else
                                src="/images/users/user.png"
                                alt="user-avatar"
                                class="direct-chat-img"
                              />
                              {{
                                parent.my_parent_info.parent_father_ovog
                                  | capitalizeAndEkhniiVseg
                              }}.{{ parent.my_parent_info.parent_father_name }}<br />({{
                                parent.my_parent_info.parent_father_phone_number
                              }})
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span v-if="parent.my_parent_info">
                              <img
                                v-if="
                                  parent.my_parent_info.parent_mother_profile_photo_url !=
                                  null
                                "
                                :src="
                                  parent.my_parent_info.parent_mother_profile_photo_url
                                "
                                alt="user-avatar"
                                class="direct-chat-img"
                              />
                              <img
                                v-else
                                src="/images/users/user.png"
                                alt="user-avatar"
                                class="direct-chat-img"
                              />
                              {{
                                parent.my_parent_info.parent_mother_ovog
                                  | capitalizeAndEkhniiVseg
                              }}.{{ parent.my_parent_info.parent_mother_name }}
                              <br />
                              {{ parent.my_parent_info.parent_mother_phone_number }}
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span v-if="parent.my_school_class_info">
                              {{ parent.my_school_class_info.full_name }}</span
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <div v-if="parent.board_type != null">
                              <b-badge
                                variant="success"
                                v-if="parent.board_type == 'headman'"
                                >Ангийн зөвлөлийн дарга</b-badge
                              >
                              <b-badge
                                variant="warning"
                                v-if="parent.board_type == 'member'"
                                >Зөвлөлийн гишүүн</b-badge
                              >
                            </div>
                            <div v-if="parent.school_board != null">
                              /
                              <b-badge
                                variant="success"
                                v-if="parent.school_board == 'member'"
                                >Сургуулийн зөвлөлийн гишүүн</b-badge
                              ><b-badge
                                variant="success"
                                v-if="parent.school_board == 'headman'"
                                >Сургуулийн зөвлөлийн дарга</b-badge
                              >
                            </div>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-danger"
                              v-if="parent.school_board == null"
                              @click.prevent="deleteParentBoard(parent)"
                            >
                              <i class="fas fa-trash"></i>
                            </a>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ parent.created_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelParentBoard()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <!-- Эцэг эхийн суудал баталгаажилт -->
    <b-modal
      size="xl"
      id="modal-student-confirm"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Файл хавсаргах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form
              @submit.prevent="updateConfrimNextYearInfo()"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="formConfirmNextYear.confirm_next_year_status"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  Баталгаажуулах эсэх (Тийм/Үгүй)
                </b-form-checkbox>
                <b-form-group
                  label="PDF файл хавсаргах:"
                  label-cols-sm="2"
                  label-size="sm"
                >
                  <input
                    type="file"
                    name="filename"
                    class="form-control"
                    id="inputFileUpload"
                    placeholder="Файл хавсаргах:"
                    v-on:change="selectFiles"
                  />
                </b-form-group>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Хадгалах</button>
                <button class="btn btn-warning" @click.prevent="hidemyconfirmmodal()">
                  Болих
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Бичиг баримт харуулах хэсэг -->
    <b-sidebar
      title="Суудал баталгаажуулсан файл"
      id="sidebar-file"
      bg-variant="dark"
      text-variant="light"
      right
      z-index="1039"
      shadow
      width="60%"
      backdrop
    >
      <div class="px-3 py-2 text-center">
        <div>
          <embed v-if="docType === 'pdf'" :src="pdfValue" width="100%" class="vh-100" />

          <VueDocPreview v-else :value="docValue" type="office" />
        </div>
      </div>
    </b-sidebar>
    <b-modal
      size="m"
      id="modal-classimg"
      title="Ангийн зураг оруулах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="row mt-2">
          <div class="col-sm-12">
            <multiselect
              v-model="yearId"
              deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
              track-by="id"
              :custom-label="customAllYearName"
              placeholder="Хичээлийн жилээр шүүж харах"
              :options="years"
              :searchable="true"
              :allow-empty="false"
              @input="getClassYearImg(yearId)"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong
                  >{{ option.start_date | dateYear }}-{{ option.finish_date | dateYear }}
                  - хичээлийн жил
                </strong></template
              >
            </multiselect>
          </div>
        </div>
        <div v-if="myYearClassId == null">
          <h6 v-if="yearId != null && myYearClassId == null" class="text-danger">
            Энэ жилд ангийн мэдээлэл байхгүй байна. Сургалтын менежерт хандана уу.
          </h6>
        </div>
        <div class="row mt-2" v-if="myYearClassId">
          <b-overlay :show="isLoad" rounded="sm">
            <b-avatar
              :src="selectedClassImg"
              variant="light"
              icon="people-fill"
              size="100%"
              class="mr-1"
              rounded="sm"
            ></b-avatar>
          </b-overlay>
        </div>
        <div class="row mt-2" v-if="myYearClassId">
          <b-form-file
            id=""
            browse-text="Зураг солих"
            size="sm"
            @change="getClassImg"
            accept="image/*"
          ></b-form-file>
          <h6 class="text-success">
            {{ successUpload }}
          </h6>
        </div>
        <div class="row mt-2" v-if="myYearClassId">
          <div class="col-sm-6">
            <b-overlay :show="isLoad" rounded="sm">
              <a
                href="#"
                class="btn btn-block btn-primary btn-xs"
                @click="uploadClassImg"
                v-if="loaded"
                >Хадгалах</a
              >
            </b-overlay>
          </div>
          <div class="col-sm-6">
            <a
              href="#"
              class="btn btn-block btn-secondary btn-xs"
              @click.prevent="uploadClassImgCancel"
              v-if="loaded"
              >Цуцлах</a
            >
          </div>
        </div>
      </div>
      <!-- /.card-body -->
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelClassImgUpload()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import createParent from "./Parent/createParent.vue";
import Multiselect from "vue-multiselect";
import VueDocPreview from "vue-doc-preview";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      //Энэ хоёрыг юу гэдийг тогтооно.
      //   allYears: [],
      years: [], //Суралцагчийн суралцсан жилээр харуулж байгаа юм байна.

      year_id: "",
      yearId: "", //Суралцагчийн суралцсан жилээр зургийг харууж байгаа юм байна.
      selectedClass: null, //   Багш олон анги хариуцаж болно.
      yearIdSchoolParentBoard: null, //   Эцэг эхийн зөвлөлийн гишүүдийн шүүлтэнд ашиглана.
      selectedClassSchoolParentBoard: null, //   Эцэг эхийн зөвлөлд ашиглаж байгаа.

      getMyInfoStudent: [],
      errorPassword: "",
      student_pass: "",
      student_pass_verify: "",
      uploadMode: false,
      successUpload: "",
      loaded: false,
      getSelectedProfileImg: "",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      action: "",
      createMode: false,
      forParentChild: "",
      parentDataProps: null,
      editMode: false,
      schoolstudents: [],
      form: new Form({
        id: "",
        student_profile_photo_url: "",
        profile_id: "",
        yearId: null,
      }),
      formEdit: new Form({
        user_id: "",
        student_family_name: "",
        student_last_name: "",
        student_name: "",
        student_gender: "",
        student_birthDate: "",
        student_age: "",
        student_citizenship: "",
        student_reg: "",
        student_birth_ger_num: "",
        student_ekh_khel: "",
        student_unchin_esekh: "",
        student_asran_kham: "",
        student_asran_kham_med: "",
        student_tsus_bul: "",
        profile_id: "",
        school_class_id: "",
        student_age: "",
        student_duureg: 0,
        student_khoroo: 0,
        student_khoroolol: 0,
        student_bair: "",
        student_dugaar: "",
        student_toot: "",
        student_live_address: "",
        student_phone_number: "",
        student_email: "",
        student_social_acc: "",
        student_web_site: "",
        student_bol_zer: "",
        student_shagnal: "",
        student_vac_1: 0,
        student_vac_2: 0,
        student_vac_3: 0,
        student_shiljilt_shaltgaan: "",
      }),
      myClassParentboards: [],
      myClassParents: [],
      options: [
        { name: "Эцэг эхийн зөвлөлийн гишүүн", value: "member" },
        { name: "Эцэг эхийн зөвлөлийн дарга", value: "headman" },
      ],
      showEditParentBoard: false,
      formParentHeadmans: new Form({
        profile_parent_id: "",
        board_type: "",
      }),
      genders: [
        { value: null, text: "Сонгох" },
        { value: "Эрэгтэй", text: "Эрэгтэй" },
        { value: "Эмэгтэй", text: "Эмэгтэй" },
      ],
      opts: [
        { value: null, text: "Сонгох" },
        { value: "Тийм", text: "Тийм" },
        { value: "Үгүй", text: "Үгүй" },
      ],
      aimagOptions: [],
      sumOptions: [],
      khorooOptions: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      fieldList: [],
      fieldName: "",
      firstMode: true,
      isAll: false,
      formConfirmNextYear: new Form({
        stud_mig_id: null,
        confirm_next_year_status: false,
        file: "",
      }),
      docValue: "",
      pdfValue: "",
      docType: "",
      selectedClassImg: null,
      isLoad: false,
      myYearClassId: null,
    };
  },
  components: {
    createParent,
    Multiselect,
    VueDocPreview,
  },
  computed: {
    ...mapState({
      vuexDataSchoolClass: (state) => state.allSchoolClasses, // Vuex-ээс өөрийн даадаг ангийн дата авах
      allYears: (state) => state.years,
      activeYearInfo: (state) => state.activeYear,
    }),
    // myAllSchoolClasses() {
    //   return this.$store.state.allSchoolClasses;
    // },

    selectedClassConfirmParent() {
      return this.schoolstudents.filter((student) => student.confirm_next_year == 1)
        .length;
    },
    selectedClassNotConfirmParent() {
      return this.schoolstudents.filter((student) => student.confirm_next_year == 0)
        .length;
    },
    selectedClassConfirmParentPercent() {
      return (
        (this.selectedClassConfirmParent / this.schoolstudents.length) *
        100
      ).toFixed(1);
    },
    countAMale() {
      return this.schoolstudents.filter((student) => student.student_gender === "Эрэгтэй")
        .length;
    },
    countAFeMale() {
      return this.schoolstudents.filter((student) => student.student_gender === "Эмэгтэй")
        .length;
    },
    countA() {
      return this.countAMale + this.countAFeMale;
    },

    //хоёр параметрээс хамаарч ажиллуулах
    paramWatcher() {
      return {
        selectYearID: this.yearIdSchoolParentBoard,
        selectSchoolClass: this.selectedClassSchoolParentBoard,
      };
    },
  },
  watch: {
    paramWatcher: {
      handler: function (newVal, oldVal) {
        // console.log("paramWatcher", newVal);
        axios
          .post("/teacher/myClassParentBoard", {
            yearID: newVal.selectYearID,
            schoolClassID: newVal.selectSchoolClass,
          })
          .then((res) => {
            this.myClassParents = res.data.mySchooClassAllParents;
            this.myClassParentboards = res.data.myClassParentboards;
          })
          .catch((err) => console.log(err));
      },
      deep: true,
    },

    vuexDataSchoolClass(newVal) {
      if (newVal) {
        this.activeMySchoolClass();
      }
    },
  },
  methods: {
    setFieldList(e) {
      if (this.firstMode) this.json_fields = {};
      var obj = {};
      var myLabel = e.label;
      obj = { [myLabel]: e.value };
      Object.assign(this.json_fields, obj);
      this.firstMode = false;
      this.isAll = true;
    },
    resetFields() {
      this.json_fields = {};
      var jsonF = {};
      this.fieldName = "";
      this.fieldList.forEach((value) => {
        var obj = {};
        var myLabel = value.label;
        obj = { [myLabel]: value.value };
        Object.assign(jsonF, obj);
      });
      this.json_fields = jsonF;
      this.isAll = false;
      Swal.fire({
        icon: "success",
        title: "Бүх багана сонгогдлоо",
        text: "Excel файл руу бүх баганаар гаргах тохиргоо хийгдлээ.",
      });
    },
    ...mapActions(["UPDATE_SOME_INFO"]), // Vuex action-ийг дуудах
    activeMySchoolClass() {
      //   console.log(this.vuexDataSchoolClass);
      this.selectedClass =
        this.vuexDataSchoolClass.length > 0 ? this.vuexDataSchoolClass[0] : null;
      this.selectedClassSchoolParentBoard =
        this.vuexDataSchoolClass.length > 0 ? this.vuexDataSchoolClass[0] : null;

      this.getTeacherStudents(this.selectedClass);
    },
    getTeacherStudents($a = this.selectedClass) {
      axios
        .post("/teacher/getTeacherStudents", {
          selectedClass: $a,
        })
        .then((response) => {
          this.schoolstudents = response.data.schoolstudents;
          this.json_data = response.data.schoolstudents;
          this.fieldList = response.data.fields;
          var jsonF = {};
          this.fieldList.forEach((value) => {
            var obj = {};
            var myLabel = value.label;
            obj = { [myLabel]: value.value };
            Object.assign(jsonF, obj);
          });
          this.json_fields = jsonF;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    createParent(studentInfo) {
      this.createMode = !this.createMode;
      this.forParentChild = studentInfo;
      if (studentInfo.profile_parent_id != null) {
        axios
          .get("/teacher/getCurrentParent/" + studentInfo.profile_parent_id)
          .then((response) => {
            this.parentDataProps = response.data.parentDataProps;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      } else this.parentDataProps = null;
      this.$bvModal.show("my-modal");
    },
    resetPass(student) {
      Swal.fire({
        title: "Нууц үг өөрчлөх үү?",
        text: "Нууц үг өөрчилснөөр буцаах боломжгүй болохыг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Нууц үг өөрчлөх!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/teacher/resetRandomPass/" + student.user_id)
            .then((response) => {
              Swal.fire(
                "Нууц үг амжилттай шинэчиллээ!",
                "Нууц үг: " + response.data.randomPass,
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    changeImage(student) {
      //   console.log(student);
      this.form.reset();
      this.successUpload = "";
      this.form.fill(student);
      this.years = student.profile_info.student_migrations;
      this.yearId = this.years.filter(
        (year) => year.year_id == this.activeYearInfo.id
      )[0];
      this.studMigProfile(this.yearId);
      this.uploadMode = !this.uploadMode;
      this.$bvModal.show("modal-profileImg");
    },
    studMigProfile(year_id) {
      axios
        .post("/teacher/studProfileImg/", {
          studMigId: this.form.profile_id,
          yearID: year_id.year_id,
        })
        .then((res) => {
          this.form.yearId = year_id;
          this.form.profile_id = res.data.studMigData.profile_id;
          this.form.student_profile_photo_url =
            res.data.studMigData.student_profile_photo_url;
          this.getSelectedProfileImg = res.data.studMigData.student_profile_photo_url;
        });
    },
    cancelUpload() {
      this.uploadMode = !this.uploadMode;
      this.form.reset();
      this.$bvModal.hide("modal-profileImg");
    },
    uploadProfile() {
      axios
        .post("/teacher/uploadStudentImg/" + this.form.profile_id, {
          getSelectedProfileImg: this.getSelectedProfileImg,
          yearID: this.yearId.year_id,
        })
        .then((res) => {
          Fire.$emit("loadSchoolStudents");
          this.successUpload = "Суралцагчийн зургийг амжилттай шинэчиллээ!";
          this.loaded = false;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    uploadProfileCancel() {
      this.loaded = false;
      this.getSelectedProfileImg = "/images/users/user.png";
    },
    getProfileImg(e) {
      let studentProfileImg = e.target.files[0];
      this.readImageHor(studentProfileImg);
    },
    readImageHor(studentProfileImg) {
      let reader = new FileReader();
      reader.readAsDataURL(studentProfileImg);
      reader.onload = (e) => {
        this.getSelectedProfileImg = e.target.result;
      };
      this.loaded = true;
    },
    editStudentInfo(studentInfo) {
      axios
        .get("/allCity")
        .then((response) => {
          this.aimagOptions = response.data.allCity;
          this.getMyInfoStudent = studentInfo;
          this.formEdit.reset();
          this.formEdit.fill(studentInfo);
          this.$bvModal.show("modal-student-info");
          if (this.formEdit.student_duureg != 0) {
            this.getSum();
          } else this.formEdit.student_duureg = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getSum() {
      axios
        .get("/allDistrict/" + this.formEdit.student_duureg)
        .then((response) => {
          this.sumOptions = response.data.allDistrict;
          this.khorooOptions = [];
          if (this.formEdit.student_khoroo != 0) {
            this.getKhoroo();
          } else this.formEdit.student_khoroo = 0;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getKhoroo() {
      axios
        .post("/allKhoroo", {
          cityId: this.formEdit.student_duureg,
          districtId: this.formEdit.student_khoroo,
        })
        .then((response) => {
          this.khorooOptions = response.data.allKhoroo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    updateStudent() {
      this.action = "Таны мэдээллийг шинэчилж байна...";
      this.formEdit
        .put("/teacher/updateMyClassStudent/" + this.getMyInfoStudent.profile_id)
        .then((response) => {
          this.$toastr.s("Сурагчийн мэдээллийг амжилттай заслаа!", "Шинэчилсэн");
          this.formEdit.reset();
          this.$bvModal.hide("modal-student-info");
          Fire.$emit("loadSchoolStudents");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelUpdate() {
      this.formEdit.reset();
      this.$bvModal.hide("modal-student-info");
    },
    addParentBoard() {
      this.yearIdSchoolParentBoard = this.allYears.filter(
        (year) => year.id == this.activeYearInfo.id
      )[0];
      axios
        .post("/teacher/myClassParentBoard", {
          yearID: this.yearIdSchoolParentBoard,
          schoolClassID: this.selectedClass,
        })
        .then((res) => {
          this.myClassParents = res.data.mySchooClassAllParents; //бүх эцэг эх
          this.myClassParentboards = res.data.myClassParentboards; //эцэг эхийн зөвлөлийн гишүүд.
        })
        .catch((err) => console.log(err));
      this.$bvModal.show("modal-parent-boards");
    },
    //WAtch болон хувьсан өөрчлөгдөөгүй үед, мөн өгөгдөл хадгалах, устгах үед дуудна.
    whenStoreParentBoardSelect() {
      axios
        .post("/teacher/myClassParentBoard", {
          yearID: this.yearIdSchoolParentBoard,
          schoolClassID: this.selectedClass,
        })
        .then((res) => {
          this.myClassParents = res.data.mySchooClassAllParents; //бүх эцэг эх
          this.myClassParentboards = res.data.myClassParentboards; //эцэг эхийн зөвлөлийн гишүүд.
        })
        .catch((err) => console.log(err));
    },
    storeParentBoard() {
      this.formParentHeadmans
        .post("/teacher/storeParentBoard")
        .then((res) => {
          Fire.$emit("loadParentBoards");
          this.resetParentBoard();
        })
        .catch();
    },
    deleteParentBoard(parent) {
      Swal.fire({
        title: "Эцэг эхийн зөвлөлөөс хасах уу?",
        text: "Сайтар нягтална уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Эцэг эхийн зөвлөлөөс хасах!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/teacher/deleteParentBoard/" + parent.id)
            .then((response) => {
              Fire.$emit("loadParentBoards");
              Swal.fire(
                "Эцэг эхийн зөвлөлөөс амжилттай хаслаа!",
                "Эцэг эхийн зөвлөл: ",
                "success"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    cancelParentBoard() {
      this.resetParentBoard();
      this.$bvModal.hide("modal-parent-boards");
    },
    resetParentBoard() {
      this.showEditParentBoard = false;
      this.formParentHeadmans.reset();
    },
    customParentName({
      parent_father_ovog,
      parent_father_name,
      student_name,
      school_class_full_name,
    }) {
      return `${
        parent_father_ovog ? parent_father_ovog[0] : ""
      }. ${parent_father_name}(${student_name}-${school_class_full_name})`;
    },
    customYearName({ my_year_info }) {
      return `${my_year_info.start_date} - ${my_year_info.finish_date} жил`;
    },
    customAllYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
    confirmNextYear(item) {
      this.formConfirmNextYear.stud_mig_id = item.stud_mig_id;
      this.formConfirmNextYear.confirm_next_year_status =
        item.confirm_next_year == 1 ? "true" : "false";
      this.$bvModal.show("modal-student-confirm");
    },
    hidemyconfirmmodal() {
      this.$bvModal.hide("modal-student-confirm");
      this.formConfirmNextYear.reset();
      this.docValue = "";
      this.pdfValue = "";
      this.docType = "";
    },
    selectFiles(e) {
      this.formConfirmNextYear.file = e.target.files[0];
    },
    updateConfrimNextYearInfo() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", this.formConfirmNextYear.file);
      formData.append("status", this.formConfirmNextYear.confirm_next_year_status);
      axios
        .post(
          "/teacher/updateConfrimNextYearInfo/" + this.formConfirmNextYear.stud_mig_id,
          formData,
          config
        )
        .then((res) => {
          if (res.data.errorMsj != "")
            Swal.fire("Алдаа гарлаа!", res.data.errorMsj, "warning");
          else {
            Fire.$emit("loadSchoolStudents");
            Swal.fire("Амжилттай", "Файл амжилттай бүртгэлээ.", "success");
            this.hidemyconfirmmodal();
          }
        })
        .catch((err) => console.log(err));
    },
    viewFile(url) {
      var typeArr = url.split(".");
      this.docType = typeArr[typeArr.length - 1];
      if (this.docType == "pdf") {
        this.pdfValue = url;
      } else this.docValue = "grading4you.com" + url;
    },
    customSchoolClassName({ full_name }) {
      return `${full_name} - анги`;
    },
    addClassImage() {
      this.successUpload = "";
      axios.get("/teacher/getYears").then((res) => {
        this.years = res.data.years;
      });
      this.yearId = null;
      this.$bvModal.show("modal-classimg");
    },
    getClassYearImg(year) {
      this.isLoad = true;
      axios
        .get("/teacher/getClassImg/" + year.id)
        .then((res) => {
          if (res.data.class != null) {
            this.myYearClassId = res.data.class.id;
            this.selectedClassImg = res.data.class.image;
          } else {
            this.myYearClassId = null;
            this.selectedClassImg = null;
          }
          this.isLoad = false;
        })
        .catch((err) => {
          this.isLoad = false;
        });
    },
    uploadClassImg() {
      this.isLoad = true;
      axios
        .post("/teacher/uploadClassImg", {
          id: this.myYearClassId,
          selectedClassImg: this.selectedClassImg,
          yearID: this.yearId.id,
        })
        .then((res) => {
          this.successUpload = "Ангийн зургийг амжилттай шинэчиллээ!";
          this.isLoad = false;
          this.loaded = false;
        })
        .catch((err) => {
          this.isLoad = false;
          //   console.log(err);
        });
    },
    uploadClassImgCancel() {
      this.loaded = false;
      this.selectedClassImg = null;
    },
    getClassImg(e) {
      let img = e.target.files[0];
      this.readClassImg(img);
    },
    readClassImg(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.selectedClassImg = e.target.result;
      };
      this.loaded = true;
    },
    cancelClassImgUpload() {
      this.yearId = null;
      this.selectedClassImg = null;
      this.$bvModal.hide("modal-classimg");
    },
  },
  async created() {
    await this.UPDATE_SOME_INFO();
    // this.activeMySchoolClass();

    Fire.$on("loadSchoolStudents", () => {
      this.getTeacherStudents(this.selectedClass);
    });
    Fire.$on("loadParentBoards", () => {
      this.whenStoreParentBoardSelect();
    });
  },
};
</script>
