<template>
  <div>
    <stat-stud class="pt-3"></stat-stud>
    <cultural class="pt-3"></cultural>
  </div>
</template>

<script>
import cultural from "../Project/CulturalCalendar.vue";
import statStud from "./Statistic/StatisticStudents.vue";
export default {
  data() {
    return {
      //       selected: false,
      //       schoolCapacity: "",
      //
      //       allSchoolStudents: "",
      //       allSchoolStudentsPrimary: "",
      //       allSchoolStudentsSecondary: "",
      //       allSchoolStudentsHigh: "",
      //
      //       getClassesAll: "",
      //       getClassesPrimary: "",
      //       getClassesSecondary: "",
      //       getClassesHigh: "",
      //
      //       allClassStudentsCount: [],
      //       allClassStudentsCountPrimary: [],
      //       allClassStudentsCountMiddle: [],
      //       allClassStudentsCountHigh: [],
      //
      //       teachers: "",
      //       teachersPrimary: "",
      //       teachersSecondary: "",
      //       teachersHigh: "",
      //       teachersErdZer: "",
      //       teacherMerZer: "",
    };
  },
  components: { statStud, cultural },
  computed: {},
  methods: {
    //     getAllSchoolStudents() {
    //       axios
    //         .get("/manager/getAllSchoolStudents")
    //         .then((response) => {
    //           this.schoolCapacity = response.data.schoolCapacity;
    //           //Суралцагчид
    //           this.allSchoolStudents = response.data.allSchoolStudents;
    //           //Бага суралцагч
    //           this.allSchoolStudentsPrimary =
    //             response.data.getSchoolStudentsPrimary;
    //           //Дунд суралцагч
    //           this.allSchoolStudentsSecondary =
    //             response.data.getSchoolStudentsSecondary;
    //           //ахлах суралцагч
    //           this.allSchoolStudentsHigh = response.data.getSchoolStudentsHigh;
    //           //   console.log(response.data);
    //
    //           //Ангиуд
    //           this.getClassesAll = response.data.getClassesAll;
    //           this.getClassesPrimary = response.data.getClassesPrimary;
    //           this.getClassesSecondary = response.data.getClassesSecondary;
    //           this.getClassesHigh = response.data.getClassesHigh;
    //           //Ангиудын суралцагчдын тоо
    //           this.allClassStudentsCount = response.data.allClassStudentsCount;
    //           this.allClassStudentsCountPrimary =
    //             response.data.allClassStudentsCountPrimary;
    //           this.allClassStudentsCountMiddle =
    //             response.data.allClassStudentsCountMiddle;
    //           this.allClassStudentsCountHigh =
    //             response.data.allClassStudentsCountHigh;
    //           //Багш нар
    //           this.teachers = response.data.teachers;
    //           this.teachersPrimary = response.data.teachersPrimary;
    //           this.teachersSecondary = response.data.teachersSecondary;
    //           this.teachersHigh = response.data.teachersHigh;
    //           //Багш боловсролын зэрэг
    //           this.teachersErdZer = response.data.erdZerCountTeach;
    //           this.teacherMerZer = response.data.merZerCountTeach;
    //         })
    //         .catch((err) => {
    //           //   console.log(err);
    //         });
    //     },
    //     //Орон нарийвчлах
    //     getScore(val) {
    //       return val.toFixed(1);
    //     },
    //     //Эерэг сурах утга хэвлэх
    //     signCheck(number) {
    //       return Math.sign(number);
    //     },
    //     checkClass(value) {
    //       if (value <= 50) {
    //         return "bg-danger";
    //       } else if (value <= 80) {
    //         return "bg-warning";
    //       } else {
    //         return "bg-success";
    //       }
    //     },
  },
  created() {
    // this.getAllSchoolStudents();
  },
};
</script>

<style scoped></style>./Statistic/StatisticStudents.vue
