<template>
  <div class="row">
    <div class="col-lg-12 mt-3" @click.prevent="classFill">
      <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-warning text-center">
          Элсэлт ба шилжилт хөдөлгөөн : Анги дүүргэлт
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-3" v-if="classFillSelected">
      <!-- small box -->
      <div class="small-box bg-info">
        <div class="inner">
          <h4>
            {{ allSchoolStudents }}/{{ schoolCapacity }} :
            {{ getScore((allSchoolStudents / schoolCapacity) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>Сургууль</p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-bag"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ангиудын бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCount"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-3" v-if="classFillSelected">
      <!-- small box -->
      <div class="small-box bg-success">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsPrimary }}/{{ schoolCapacity }} :
            {{
              getScore((allSchoolStudentsPrimary / schoolCapacity) * 100)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Бага сургууль: {{ allSchoolStudentsPrimary }}/208 :
            {{ getScore((allSchoolStudentsPrimary / 312) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-stats-bars"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Бага ангийн бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountPrimary"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-3" v-if="classFillSelected">
      <!-- small box -->
      <div class="small-box bg-warning">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsSecondary }}/{{ schoolCapacity }} :
            {{
              getScore((allSchoolStudentsSecondary / schoolCapacity) * 100)
            }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Дунд сургууль: {{ allSchoolStudentsSecondary }}/208 :
            {{ getScore((allSchoolStudentsSecondary / 156) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-person-add"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Дунд ангийн бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountMiddle"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-3" v-if="classFillSelected">
      <!-- small box -->
      <div class="small-box bg-danger">
        <div class="inner">
          <h4>
            {{ allSchoolStudentsHigh }}/{{ schoolCapacity }} :
            {{ getScore((allSchoolStudentsHigh / schoolCapacity) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </h4>

          <p>
            Ахлах сургууль: {{ allSchoolStudentsHigh }}/208 :
            {{ getScore((allSchoolStudentsHigh / 208) * 100) }}/100
            <sup style="font-size: 14px">%</sup>
          </p>
        </div>
        <div class="icon">
          <!-- <i class="ion ion-pie-graph"></i> -->
          <i class="fas fa-school"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ахлах ангийн бүртгэл</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Анги</th>
                <th>Тоо</th>
                <th style="width: 40px">%</th>
                <th style="width: 40px">Сул О/Т</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stud_class, index) in allClassStudentsCountHigh"
                :key="stud_class.index"
              >
                <td>{{ ++index }}</td>
                <td>{{ stud_class.full_name }}</td>
                <td>
                  <!-- <div class="progress progress-xs">
                    <div
                      class="progress-bar progress-bar-danger"
                      style="width: 55%"
                    ></div>
                  </div> -->
                  {{ stud_class.stud_count }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      checkClass(stud_class.stud_count_percent.toFixed(1))
                    "
                    >{{ stud_class.stud_count_percent.toFixed(1) }}%</span
                  >
                </td>
                <td>
                  <span v-if="signCheck(stud_class.stud_count - 26) == 1"
                    >{{ stud_class.stud_count - 26 }} илүү бүртгэл</span
                  >
                  <span v-if="signCheck(stud_class.stud_count - 26) == 0">
                    анги дүүрсэн
                  </span>
                  <span v-if="signCheck(stud_class.stud_count - 26) == -1"
                    >{{ (stud_class.stud_count - 26) * -1 }} бүртгэх
                    боломжтой</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <!-- Суралцагчдын нас -->
    <div class="col-lg-12 col-12">
      <div class="card card-widget widget-user-2">
        <div
          class="widget-user-header bg-info text-center"
          @click.prevent="allStudentAges"
        >
          Элсэлт ба шилжилт хөдөлгөөн : Суралцагчдын нас
        </div>
        <div class="text-center">
          <b-spinner
            class="flex text-center"
            variant="success"
            type="grow"
            label="Spinning"
            v-if="selectSpinClass"
          ></b-spinner>
        </div>
        <div class="card-content p-0" v-if="ageSelected">
          <ul class="nav flex-column">
            <li class="nav-item">
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-12 border-right">
                    <div class="description-block">
                      <h5 class="description-header">Анги бүрээр</h5>
                      <span class="description-text">нас</span>
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                    <!-- /.description-block -->
                  </div>
                </div>
                <!-- /.row -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Суралцагчдын хүйс -->
    <div class="col-lg-12 col-12">
      <div class="card card-widget widget-user-2">
        <div
          class="widget-user-header bg-success text-center"
          @click.prevent="allStudentAges"
        >
          Элсэлт ба шилжилт хөдөлгөөн : Суралцагчдын хүйс
        </div>
        <div class="card-content p-0" v-if="ageSelected">
          <ul class="nav flex-column">
            <li class="nav-item">
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">Анги бүрээр</h5>
                      <span class="description-text">нас</span>
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                    <!-- /.description-block -->
                  </div>
                </div>
                <!-- /.row -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Суралцагчдын оршин суугаа хаяг дүүргээр -->
    <div class="col-lg-12 col-12">
      <div class="card card-widget widget-user-2">
        <div
          class="widget-user-header bg-danger text-center"
          @click.prevent="allStudentAges"
        >
          Элсэлт ба шилжилт хөдөлгөөн : Суралцагчдын оршин суугаа хаяг дүүргээр
        </div>
        <div class="card-content p-0" v-if="ageSelected">
          <ul class="nav flex-column">
            <li class="nav-item">
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">Анги бүрээр</h5>
                      <span class="description-text">нас</span>
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                    <!-- /.description-block -->
                  </div>
                </div>
                <!-- /.row -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Суралцагчдын гэр бүлийн байдал -->
    <div class="col-lg-12 col-12">
      <div class="card card-widget widget-user-2">
        <div
          class="widget-user-header bg-lightblue text-center"
          @click.prevent="allStudentAges"
        >
          Элсэлт ба шилжилт хөдөлгөөн : Суралцагчдын гэр бүл
        </div>
        <div class="card-content p-0" v-if="ageSelected">
          <ul class="nav flex-column">
            <li class="nav-item">
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">Анги бүрээр</h5>
                      <span class="description-text">нас</span>
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                    <!-- /.description-block -->
                  </div>
                </div>
                <!-- /.row -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MyPie from "./PieChart.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  data() {
    return {
      selectSpinClass: false,
      classFillSelected: false,
      schoolCapacity: "",

      allSchoolStudents: "",
      allSchoolStudentsPrimary: "",
      allSchoolStudentsSecondary: "",
      allSchoolStudentsHigh: "",

      getClassesAll: "",
      getClassesPrimary: "",
      getClassesSecondary: "",
      getClassesHigh: "",

      allClassStudentsCount: [],
      allClassStudentsCountPrimary: [],
      allClassStudentsCountMiddle: [],
      allClassStudentsCountHigh: [],

      teachers: "",
      teachersPrimary: "",
      teachersSecondary: "",
      teachersHigh: "",

      //График дата

      //   Суралцагчдын нас
      ageSelected: false,
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            // horizontal: false,
            // columnWidth: "55%",
            // endingShape: "rounded",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [], //Ангиудыг задлах
        },
        yaxis: {
          title: {
            text: "$ (thousands)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  },
  components: { apexchart: VueApexCharts },
  computed: {},
  methods: {
    getAllSchoolStudents() {
      axios
        .get("/manager/getAllSchoolStudents")
        .then((response) => {
          this.schoolCapacity = response.data.schoolCapacity;
          //Суралцагчид
          this.allSchoolStudents = response.data.allSchoolStudents;
          //Бага суралцагч
          this.allSchoolStudentsPrimary =
            response.data.getSchoolStudentsPrimary;
          //Дунд суралцагч
          this.allSchoolStudentsSecondary =
            response.data.getSchoolStudentsSecondary;
          //ахлах суралцагч
          this.allSchoolStudentsHigh = response.data.getSchoolStudentsHigh;
          //   console.log(response.data);

          //Ангиуд
          this.getClassesAll = response.data.getClassesAll;
          this.getClassesPrimary = response.data.getClassesPrimary;
          this.getClassesSecondary = response.data.getClassesSecondary;
          this.getClassesHigh = response.data.getClassesHigh;
          //Ангиудын суралцагчдын тоо
          this.allClassStudentsCount = response.data.allClassStudentsCount;
          this.allClassStudentsCountPrimary =
            response.data.allClassStudentsCountPrimary;
          this.allClassStudentsCountMiddle =
            response.data.allClassStudentsCountMiddle;
          this.allClassStudentsCountHigh =
            response.data.allClassStudentsCountHigh;
          //Багш нар
          this.teachers = response.data.teachers;
          this.teachersPrimary = response.data.teachersPrimary;
          this.teachersSecondary = response.data.teachersSecondary;
          this.teachersHigh = response.data.teachersHigh;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    //Орон нарийвчлах
    getScore(val) {
      return val.toFixed(1);
    },
    //Эерэг сурах утга хэвлэх
    signCheck(number) {
      return Math.sign(number);
    },
    checkClass(value) {
      if (value <= 50) {
        return "bg-danger";
      } else if (value <= 80) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },

    classFill() {
      this.classFillSelected = !this.classFillSelected;
    },

    allStudentAges() {
      this.selectSpinClass = !this.selectSpinClass;
      axios
        .get("/all_worker_functions/getAllStudentAges")
        .then((res) => {
          console.log(res.data);

          let sClass = res.data.allClassStudentsCount;
          let sClassData = [];
          sClass.forEach((element) => {
            this.chartOptions.xaxis.categories.push(element.full_name);
            sClassData.push({
              name: element.stud_count.student_age,
              data: element.stud_count.total,
            });
          });
          console.log(sClassData);
          this.series = [...sClassData];

          //   sClassData.forEach((element) => {
          //     this.series.push({
          //       name: element.full_name,
          //       data: element.total,
          //     });
          //   });
          this.ageSelected = !this.ageSelected;
          this.selectSpinClass = !this.selectSpinClass;
        })
        .catch((err) => console.log("errors"));
    },
  },
  created() {
    this.getAllSchoolStudents();
  },
};
</script>

<style>
</style>
