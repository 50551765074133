<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card" style="overflow-x: auto">
        <div class="card-header">
          <h3 class="card-title">Эцэг эхийн талархлууд</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th class="text-sm">#</th>
                <th class="text-sm" style="max-width: 200px">Талархал</th>
                <th class="text-sm">Үүсгэсэн</th>
                <th class="text-sm">Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(appreciation, index) in myAppreciations"
                :key="appreciation.id"
              >
                <td>
                  {{ index + 1 }}
                </td>
                <td class="text-sm">
                  <span
                    class="badge bg-info"
                    style="white-space: pre-wrap"
                    v-html="appreciation.talarkhal.substring(0, 1000)"
                  >
                  </span>
                  <!-- <b-badge variant="info" style="white-space: pre-wrap">{{
                    appreciation.talarkhal
                  }}</b-badge> -->
                </td>
                <td>
                  {{ appreciation.created_at | dateYearMonthDay }}
                </td>

                <td>
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editAppreciation(appreciation)"
                    ><i class="fas fa-edit"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteAppreciations(appreciation)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">Талархал илэрхийлэх</h3>
          <h3 class="card-title" v-show="editMode">Талархал засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form
          @submit.prevent="
            !editMode ? createAppreciation() : updateAppreciation()
          "
        >
          <div class="card-body">
            <div class="form-group col-lg-12">
              <label>Талархал</label>
              <quill-editor
                v-model="form.talarkhal"
                :options="editorOptionParent"
                style="height: 1 00px"
              >
              </quill-editor>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      myAppreciations: [],
      form: new Form({
        id: "",
        talarkhal: "",
      }),
      editorOptionParent: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
        placeholder: "Эцэг эх та талархал илэрхийлэх бол энд бичнэ үү! ",
      },
    };
  },
  methods: {
    getAllAppreciations() {
      axios
        .get("/parent/getAllAppreciations")
        .then((response) => {
          //   console.log(response.data.schoolentrants);
          this.myAppreciations = response.data.myAppreciations;
          //console.log(this.schoolentrants);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editAppreciation(appreciation) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + appreciation);
      this.editMode = true;
      this.form.reset();
      this.form.fill(appreciation);
    },
    updateAppreciation() {
      this.action = "Элсэгчийн мэдээллийг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/parent/appreciationUpdate/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadMyAppreciations");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteAppreciations(appreciation) {
      // appreciationDelete
      axios
        .delete("/parent/appreciationDelete/" + appreciation.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай устгалаа.", "Устгалаа");
          Fire.$emit("loadMyAppreciations");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createAppreciation() {
      this.form
        .post("/parent/appreciationCreate")
        .then((response) => {
          this.$toastr.s("Талархал амжилттай үүсгэлээ.", "Амжилттай");
          Fire.$emit("loadMyAppreciations");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Талархал үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  computed: {
    // validation() {
    //   return this.form.student_reg.length == 10;
    // },
  },
  created() {
    this.getAllAppreciations();
    Fire.$on("loadMyAppreciations", () => {
      this.getAllAppreciations();
    });
  },
};
</script>

<style></style>
