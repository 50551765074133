var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mt-2"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_vm._v(" "),_c('b-overlay',{attrs:{"show":_vm.isLoad,"rounded":"sm"}},[_c('table',{staticClass:"table table-bordered text-center"},[_c('tbody',[_c('tr',{attrs:{"role":"row"}},[_c('th',{staticClass:"text-wrap"},[_vm._v("Төсвийн төрөл")]),_vm._v(" "),_c('th',{staticClass:"text-wrap"},[_vm._v("Төсвийн тоо")]),_vm._v(" "),_c('th',{staticClass:"text-wrap"},[_vm._v("Мөнгөн дүн")])]),_vm._v(" "),_c('tr',[_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                Бүртгэсэн төсөв\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.count_all)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.total !== undefined && _vm.allBudget.total !== null ?
                                                    _vm.allBudget.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                    '')+"\n                                            ")])]),_vm._v(" "),_c('tr',[_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                Батлагдсан төсөв\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.count_confirmed)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.confirmed !== undefined && _vm.allBudget.confirmed !== null
                                                    ? _vm.allBudget.confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                        ",") : '')+"\n                                            ")])]),_vm._v(" "),_c('tr',[_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                Зарцуулсан төсөв\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.count_zartsuulsan)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(_vm.allBudget.zartsuulsan !== undefined && _vm.allBudget.zartsuulsan !==
                                                    null ?
                                                    _vm.allBudget.zartsuulsan.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                        ",") : '')+"\n                                            ")])])])]),_vm._v(" "),_c('h5',[_c('b',[_vm._v("Батлагдсан төсөв ")])]),_vm._v(" "),_c('table',{staticClass:"table table-bordered text-center"},[_c('tbody',[_c('tr',{attrs:{"role":"row"}},[_c('th',{staticClass:"text-wrap"},[_vm._v("Төрөл")]),_vm._v(" "),_c('th',{staticClass:"text-wrap"},[_vm._v("Батлагдсан төсөв")]),_vm._v(" "),_c('th',{staticClass:"text-wrap"},[_vm._v("Зарцуулсан төсөв")]),_vm._v(" "),_c('th',{staticClass:"text-wrap"},[_vm._v("Зөрүү")])]),_vm._v(" "),_vm._l((_vm.confirmedBudgets),function(confirmedBudget){return _c('tr',{key:confirmedBudget.id},[_c('th',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(confirmedBudget.name)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(confirmedBudget.confirmed !== undefined &&
                                                    confirmedBudget.confirmed !== null ?
                                                    confirmedBudget.confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                        ",") : '')+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(confirmedBudget.zartsuulsan !== undefined &&
                                                    confirmedBudget.zartsuulsan !== null ?
                                                    confirmedBudget.zartsuulsan.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                        ",") : '')+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"dtr-control sorting_1",attrs:{"tabindex":"0"}},[_vm._v("\n                                                "+_vm._s(confirmedBudget.zartsuulsan !== undefined &&
                                                    confirmedBudget.zartsuulsan !== null && confirmedBudget.confirmed
                                                    !== undefined && confirmedBudget.confirmed !== null ?
                                                    (confirmedBudget.confirmed -
                                                        confirmedBudget.zartsuulsan).toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                            ",") : '')+"\n                                            ")])])})],2)])])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('b',[_vm._v("Төсөв зарцуулалтын тайлан ")])])}]

export { render, staticRenderFns }