<template>
  <div class="mt-2">
    <b-button @click.prevent="getEduYear()" block variant="outline-primary"
      >Ажилчдыг боловсролын байгууллагад ажилласан жилээр харах</b-button
    >
    <div class="row" v-if="isWorkYearEduView">
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="340"
                class="barChart"
                :options="optionsEduYearAllEmp"
                :series="seriesEduYearAllEmp"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="350"
                class="pieChart"
                :options="optionsEduYearAllEmpP"
                :series="seriesEduYearAllEmpP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <!-- Багш нар харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsEduYearTeach"
                :series="seriesEduYearTeach"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Багш нарын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsEduYearTeachP"
                :series="seriesEduYearTeachP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="teachEduYear in eduYearTeach"
              :key="teachEduYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ teachEduYear.teacher_bol_bai_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ teachEduYear.total }}</span>
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="teachEduYearDetal in eduYearTeachDetal"
                    :key="teachEduYearDetal.id"
                  >
                    <tr
                      v-if="
                        teachEduYear.teacher_bol_bai_ajiljil ==
                        teachEduYearDetal.teacher_bol_bai_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="teachEduYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="teachEduYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachEduYearDetal.teacher_last_name[0] }}.{{
                          teachEduYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ teachEduYearDetal.teacher_bol_bai_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewEduYearTeach"
              @click.prevent="getEduYearTeachDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Ажилчид харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsEduYearEmployee"
                :series="seriesEduYearEmployee"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Ажилчдын боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsEduYearEmployeeP"
                :series="seriesEduYearEmployeeP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="employeeEduYear in eduYearEmployee"
              :key="employeeEduYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ employeeEduYear.teacher_bol_bai_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ employeeEduYear.total }}</span>
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="employeeEduYearDetal in eduYearEmployeeDetal"
                    :key="employeeEduYearDetal.id"
                  >
                    <tr
                      v-if="
                        employeeEduYear.teacher_bol_bai_ajiljil ==
                        employeeEduYearDetal.teacher_bol_bai_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="employeeEduYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="employeeEduYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeEduYearDetal.teacher_last_name[0] }}.{{
                          employeeEduYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ employeeEduYearDetal.teacher_bol_bai_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewEduYearEmployee"
              @click.prevent="getEduYearEmployeeDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Удирдлагууд харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsEduYearManag"
                :series="seriesEduYearManag"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Удирдлагын алба боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsEduYearManagP"
                :series="seriesEduYearManagP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="managEduYear in eduYearManag"
              :key="managEduYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ managEduYear.teacher_bol_bai_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ managEduYear.total }}</span>
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="managEduYearDetal in eduYearManagDetal"
                    :key="managEduYearDetal.id"
                  >
                    <tr
                      v-if="
                        managEduYear.teacher_bol_bai_ajiljil ==
                        managEduYearDetal.teacher_bol_bai_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="managEduYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="managEduYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managEduYearDetal.teacher_last_name[0] }}.{{
                          managEduYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ managEduYearDetal.teacher_bol_bai_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewEduYearManag"
              @click.prevent="getEduYearManagDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
      <!-- Цэцэрлэг харуулах -->
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="bar"
                height="320"
                :options="optionsEduYearKg"
                :series="seriesEduYearKg"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span>
              <strong class="info-box-text mt-4 text-center">
                Цэцэрлэг боловсролын байгууллагад ажилласан жил</strong
              >
              <apexchart
                type="donut"
                height="360"
                class="pieChart"
                :options="optionsEduYearKgP"
                :series="seriesEduYearKgP"
              ></apexchart>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <div
              class="card card-secondary collapsed-card"
              v-for="kgEduYear in eduYearKg"
              :key="kgEduYear.id"
            >
              <div class="card-header">
                <h3 class="card-title">
                  {{ kgEduYear.teacher_bol_bai_ajiljil }}
                </h3>
                <div class="card-tools">
                  <span class="badge badge-warning">{{ kgEduYear.total }}</span>
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table class="table table-bordered">
                  <thead style="font-size: 100%">
                    <tr role="row" class="text-center">
                      <th>Зураг</th>
                      <th>Овог нэр</th>
                      <th>Ажилласан жил</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="kgEduYearDetal in eduYearKgDetal"
                    :key="kgEduYearDetal.id"
                  >
                    <tr
                      v-if="
                        kgEduYear.teacher_bol_bai_ajiljil ==
                        kgEduYearDetal.teacher_bol_bai_ajiljil
                      "
                    >
                      <td class="text-center" tabindex="10" padding="150px">
                        <img
                          v-if="kgEduYearDetal.profile_photo_url != null"
                          class="direct-chat-img"
                          :src="kgEduYearDetal.profile_photo_url"
                          alt="user-avatar"
                        />
                        <img
                          v-else
                          class="direct-chat-img"
                          src="/images/users/user.png"
                          alt="user-avatar"
                        />
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgEduYearDetal.teacher_last_name[0] }}.{{
                          kgEduYearDetal.teacher_name
                        }}
                      </td>
                      <td class="text-center" tabindex="10" padding="150px">
                        {{ kgEduYearDetal.teacher_bol_bai_ajiljil }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-button
              v-if="viewEduYearKg"
              @click.prevent="getEduYearKgDetal()"
              block
              variant="outline-primary"
              >Ажилчдыг харах</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./card-body -->
</template>

<script>
export default {
  props: ["year_id"],
  data() {
    return {
      isWorkYearEduView: false,
      viewEduYearTeach: true,
      viewEduYearEmployee: true,
      viewEduYearManag: true,
      viewEduYearKg: true,
      eduYearTeach: [],
      eduYearEmployee: [],
      eduYearManag: [],
      eduYearKg: [],
      eduYearTeachDetal: [],
      eduYearEmployeeDetal: [],
      eduYearManagDetal: [],
      eduYearKgDetal: [],
      optionsEduYearAllEmp: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesEduYearAllEmp: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsEduYearTeach: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesEduYearTeach: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsEduYearEmployee: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesEduYearEmployee: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsEduYearManag: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesEduYearManag: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      optionsEduYearKg: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (data) {
            return data;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesEduYearKg: [
        {
          name: "Ажилчдын тоо",
          data: [],
        },
      ],
      //Хувиар харуулах
      seriesEduYearAllEmpP: [],
      seriesEduYearTeachP: [],
      seriesEduYearEmployeeP: [],
      seriesEduYearManagP: [],
      seriesEduYearKgP: [],
      optionsEduYearAllEmpP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsEduYearTeachP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsEduYearEmployeeP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsEduYearManagP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
      optionsEduYearKgP: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {
    getEduYear() {
      if (!this.isWorkYearEduView) {
        axios
          .post("/manager/getEduYear", {
            year: this.year_id,
          })
          .then((res) => {
            this.seriesEduYearAllEmp[0].data = res.data.eduYearTotal;
            this.optionsEduYearAllEmp.xaxis.categories = res.data.eduYearName;
            this.seriesEduYearTeach[0].data = res.data.eduYearTotalTeach;
            this.optionsEduYearTeach.xaxis.categories = res.data.eduYearNameTeach;
            this.seriesEduYearEmployee[0].data = res.data.eduYearTotalEmployee;
            this.optionsEduYearEmployee.xaxis.categories = res.data.eduYearNameEmployee;
            this.seriesEduYearManag[0].data = res.data.eduYearTotalManag;
            this.optionsEduYearManag.xaxis.categories = res.data.eduYearNameManag;
            this.seriesEduYearKg[0].data = res.data.eduYearTotalKg;
            this.optionsEduYearKg.xaxis.categories = res.data.eduYearNameKg;

            this.seriesEduYearAllEmpP = res.data.eduYearTotal;
            this.optionsEduYearAllEmpP.labels = res.data.eduYearName;
            this.seriesEduYearTeachP = res.data.eduYearTotalTeach;
            this.optionsEduYearTeachP.labels = res.data.eduYearNameTeach;
            this.seriesEduYearEmployeeP = res.data.eduYearTotalEmployee;
            this.optionsEduYearEmployeeP.labels = res.data.eduYearNameEmployee;
            this.seriesEduYearManagP = res.data.eduYearTotalManag;
            this.optionsEduYearManagP.labels = res.data.eduYearNameManag;
            this.seriesEduYearKgP = res.data.eduYearTotalKg;
            this.optionsEduYearKgP.labels = res.data.eduYearNameKg;
            this.isWorkYearEduView = !this.isWorkYearEduView;
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.isWorkYearEduView = !this.isWorkYearEduView;
    },
    getEduYearTeachDetal() {
      axios
        .post("/manager/getEduYearTeachDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.eduYearTeach = res.data.eduYearCountTeach;
          this.eduYearTeachDetal = res.data.eduYearTeachDetal;
          this.viewEduYearTeach = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getEduYearEmployeeDetal() {
      axios
        .post("/manager/getEduYearEmployeeDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.eduYearEmployee = res.data.eduYearCountEmployee;
          this.eduYearEmployeeDetal = res.data.eduYearEmployeeDetal;
          this.viewEduYearEmployee = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getEduYearManagDetal() {
      axios
        .post("/manager/getEduYearManagDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.eduYearManag = res.data.eduYearCountManag;
          this.eduYearManagDetal = res.data.eduYearManagDetal;
          this.viewEduYearManag = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEduYearKgDetal() {
      axios
        .post("/manager/getEduYearKgDetal", {
          year: this.year_id,
        })
        .then((res) => {
          this.eduYearKg = res.data.eduYearCountKg;
          this.eduYearKgDetal = res.data.eduYearKgDetal;
          this.viewEduYearKg = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
  font-size: 70%;
}
.pieChart {
  height: 330px;
}
.barChart {
  height: 340px;
}
</style>
