<template>
  <div class="card card-success card-outline card-outline-tabs mt-3">
    <div class="card-body">
      <div class="tab-content">
        <div class="card card-dark card-outline card-outline-tabs">
          <div class="card-header p-0 pt-1">
            <h3>Гэрийн даалгаварын статистик</h3>
          </div>
          <div class="card-body">
            <div class="card card-default">
              <div class="col-md-6">
                <b-container class="bv-example-row" fluid>
                  <b-row>
                    <b-col sm="4">
                      <multiselect
                        v-model="quarterClass"
                        deselect-label="Сонголт цуцлах"
                        select-label="Улирал сонгох"
                        track-by="id"
                        :custom-label="customQuarterName"
                        label="name"
                        placeholder="Хичээлийн улирал сонгоно уу?"
                        :options="quarterOptions"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStatClass"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.name }}-р улирал
                          </strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedMonthClass"
                        deselect-label="Сонголт цуцлах"
                        select-label="Сар сонгох"
                        :custom-label="customMonthName"
                        track-by="month_name"
                        label="month_name"
                        placeholder="Хичээлийн сар сонгоно уу?"
                        :options="monthListClass"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStatClass"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.month_name }}-р сар</strong>
                        </template>
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedLessonClass"
                        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        placeholder="Хичээл сонгоно уу?"
                        track-by="id"
                        label="subject_name"
                        :options="lessonListClass"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStatClass"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.subject_name }} </strong></template
                        >
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="card-body">
                <span v-if="chartClass === true">
                  <div>
                    <apexchart
                      type="bar"
                      height="450"
                      :options="chartHWCOptions"
                      :series="seriesHWC"
                    >
                    </apexchart>
                  </div>
                </span>
              </div>
            </div>
            <div class="card card-default">
              <div class="col-md-6">
                <b-container class="bv-example-row" fluid>
                  <b-row>
                    <b-col sm="4">
                      <multiselect
                        v-model="quarter"
                        deselect-label="Сонголт цуцлах"
                        select-label="Улирал сонгох"
                        track-by="id"
                        :custom-label="customQuarterName"
                        label="name"
                        placeholder="Хичээлийн улирал сонгоно уу?"
                        :options="quarterOptions"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.name }}-р улирал
                          </strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedMonth"
                        deselect-label="Сонголт цуцлах"
                        select-label="Сар сонгох"
                        :custom-label="customMonthName"
                        track-by="month_name"
                        label="month_name"
                        placeholder="Хичээлийн сар сонгоно уу?"
                        :options="monthList"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.month_name }}-р сар</strong>
                        </template>
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedChild"
                        deselect-label="Сонголт цуцлах"
                        select-label="Хүүхэд сонгох"
                        placeholder="Хүүхэд сонгоно уу?"
                        track-by="user_id"
                        label="student_name"
                        :options="studOptions"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.student_name }}</strong></template
                        >
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="card-body">
                <span v-if="chart === true">
                  <div v-if="courseAvgName.length > 0">
                    <apexchart
                      type="bar"
                      height="450"
                      :options="chartHWOptions"
                      :series="seriesHW"
                    >
                    </apexchart>
                  </div>
                </span>
              </div>
            </div>
            <div class="card card-default" v-if="selectedChild">
              <div class="col-md-6">
                <b-container class="bv-example-row" fluid>
                  <b-row>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedLesson"
                        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                        placeholder="Хичээл сонгоно уу?"
                        track-by="id"
                        label="subject_name"
                        :options="lessonList"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkLessonStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.subject_name }} </strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="quarterLesson"
                        deselect-label="Сонголт цуцлах"
                        select-label="Улирал сонгох"
                        track-by="id"
                        :custom-label="customQuarterName"
                        label="name"
                        placeholder="Хичээлийн улирал сонгоно уу?"
                        :options="quarterOptions"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkLessonStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.name }}-р улирал
                          </strong></template
                        >
                      </multiselect>
                    </b-col>
                    <b-col sm="4">
                      <multiselect
                        v-model="selectedMonthLesson"
                        deselect-label="Сонголт цуцлах"
                        select-label="Сар сонгох"
                        :custom-label="customMonthName"
                        track-by="month_name"
                        label="month_name"
                        placeholder="Хичээлийн сар сонгоно уу?"
                        :options="months"
                        :searchable="false"
                        :allow-empty="false"
                        @input="homeWorkLessonStat"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong
                            >{{ option.month_name }}-р сар</strong
                          ></template
                        >
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="card-body">
                <span v-if="chartLesson === true">
                  <div v-if="courseLessonName.length > 0">
                    <apexchart
                      type="line"
                      height="350"
                      :options="chartHWLessonOptions"
                      :series="seriesHWLesson"
                    >
                    </apexchart>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["teacherId"],
  components: {
    Multiselect,
  },
  data() {
    return {
      editMode: "",
      studOptions: [],
      quarterOptions: [],
      monthList: [],
      monthListClass: [],
      months: [],
      lessonList: [],
      lessonListClass: [],
      courseAvgName: [],
      courseLessonName: [],
      chart: false,
      chartClass: false,
      chartLesson: false,
      selectedQuarter: 0,
      quarter: "",
      quarterClass: "",
      selectedMonth: null,
      selectedMonthClass: null,
      quarterLesson: "",
      selectedMonthLesson: null,
      selectedChild: "",
      selectedLesson: "",
      selectedLessonClass: "",
      //   гэрийн даалгавар
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selectHWStudents: "",
      homeWorkLoaded: true,
      //   Тэмдэглэл хийсэн
      seriesHW: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      chartHWOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesHWC: [
        {
          name: "Суралцагчийн үнэлгээ",
          data: [],
        },
      ],
      chartHWCOptions: {
        chart: {
          height: 450,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesHWLesson: [
        {
          name: "Суралцагчийн үнэлгээ",
          type: "column",
          data: [],
        },
        {
          name: "Суралцагчийн дундаж үнэлгээ",
          type: "line",
          data: [],
        },
      ],
      chartHWLessonOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    customQuarterName({ name }) {
      return `${name}-р улирал`;
    },
    customMonthName({ month_name }) {
      return `${month_name}-р сар`;
    },
    getSchoolQuarters() {
      axios
        .get("/teacher/getSchoolQuarters")
        .then((response) => {
          this.quarterOptions = response.data.schoolquarters;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      axios
        .get("/teacher/getCurrentQuarter")
        .then((response) => {
          this.selectedQuarter = response.data.currentQuarter.id;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    homeWorkStatClass() {
      this.seriesHWC[0].data = [];
      this.chartHWCOptions.xaxis.categories = [];
      this.chartClass = false;
      axios
        .post("/teacher/myClasshomeWorkStatClass", {
          quarterClass: this.quarterClass.id,
          selectedMonthClass: this.selectedMonthClass,
          selectedLessonClass: this.selectedLessonClass.id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.lessonListClass = res.data.courses;
          this.monthListClass = res.data.monthList;
          this.seriesHWC[0].data = res.data.courseAvg;
          this.chartHWCOptions.xaxis.categories = res.data.courseAvgName;
          this.chartClass = true;
        })
        .catch((errors) => {});
    },
    homeWorkStat() {
      this.seriesHW[0].data = [];
      this.chartHWOptions.xaxis.categories = [];
      this.chart = false;
      axios
        .post("/teacher/myClasshomeWorkStat", {
          quarter: this.quarter.id,
          selectedMonth: this.selectedMonth,
          selectedChild: this.selectedChild.user_id,
          teacherId: this.teacherId,
        })
        .then((res) => {
          //   console.log(res.data);
          //Тэмдэглэл
          this.lessonList = res.data.courses;
          this.studOptions = res.data.students;
          this.monthList = res.data.monthList;
          this.courseAvgName = res.data.courseAvgName;
          this.seriesHW[0].data = res.data.courseAvg;
          this.chartHWOptions.xaxis.categories = res.data.courseAvgName;
          this.chart = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    homeWorkLessonStat() {
      this.seriesHWLesson[0].data = [];
      this.seriesHWLesson[1].data = [];
      this.chartHWLessonOptions.xaxis.categories = [];
      this.chartLesson = false;
      axios
        .post("/teacher/myClasshomeWorkLessonStat", {
          quarter: this.quarterLesson.id,
          selectedMonth: this.selectedMonthLesson,
          selectedChild: this.selectedChild.user_id,
          selectedLesson: this.selectedLesson.id,
        })
        .then((res) => {
          this.courseLessonName = res.data.hwName;
          this.months = res.data.months;
          this.seriesHWLesson[0].data = res.data.hwGrade;
          res.data.hwGrade.forEach((el) => {
            this.seriesHWLesson[1].data.push(res.data.avg.hw_grade_avg);
          });
          this.chartHWLessonOptions.xaxis.categories = res.data.hwName;
          this.chartLesson = true;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  created() {
    this.homeWorkStat();
    this.homeWorkStatClass();
    this.getSchoolQuarters();
  },
};
</script>

<style scoped>
.table-avatar {
  width: 50%;
  height: auto;
  /* width: 40px !important;
  height: 40px;
  object-fit: cover; */
}
</style>
