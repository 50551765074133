var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"chatdiv"}},[(_vm.chatShow && _vm.authUser.userRoleInfo.name != 'dentstu')?_c('div',{attrs:{"id":"chat"}},[_c('div',{staticClass:"card card-primary card-outline direct-chat direct-chat-primary direct-chat-contacts-open shadow-lg"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Чат")]),_vm._v(" "),_c('div',{staticClass:"card-tools"},[(_vm.unSeenCount > 0)?_c('small',[_vm._v("\n            Шинэ зурвас\n            "),_c('span',{staticClass:"badge badge-warning",attrs:{"title":_vm.unSeenCount + ' хүнээс шинэ зурвас ирсэн байна.'}},[_vm._v(_vm._s(_vm.unSeenCount)+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.authUser.userRoleInfo.name != 'dentstu')?_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","title":"Идэвхитэй багш"},on:{"click":function($event){return _vm.showUsers()}}},[_c('i',{staticClass:"fas fa-users"}),_vm._v(" "),_c('b-badge',{attrs:{"variant":"light"}},[_vm._v(_vm._s(_vm.chatUsers.length)+"/"),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.chatUsers.filter(function (user) { return user.active == true; }).length))])])],1):_vm._e(),_vm._v(" "),(
              _vm.authUser.userRoleInfo.name != 'dentstu' &&
              _vm.authUser.userRoleInfo.name != 'rentpa' &&
              _vm.chatParents.length > 0
            )?_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","title":"Идэвхитэй эцэг эх"},on:{"click":function($event){return _vm.showUsers()}}},[_c('b-icon',{attrs:{"icon":"people-fill","aria-hidden":"true"}}),_vm._v(" "),_c('b-badge',{attrs:{"variant":"light"}},[_vm._v(_vm._s(_vm.chatParents.length)+"/"),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.chatParents.filter(function (user) { return user.active == true; }).length))])])],1):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button"},on:{"click":function($event){_vm.chatShow = !_vm.chatShow}}},[_c('i',{staticClass:"fas fa-times"})])])]),_vm._v(" "),_c('div',{staticClass:"card-body d-flex"},[_c('div',[(
              _vm.authUser.userRoleInfo.name != 'dentstu' &&
              _vm.authUser.userRoleInfo.name != 'rentpa'
            )?_c('ul',{staticClass:"nav nav-tabs nav-fill"},[_vm._m(0),_vm._v(" "),_vm._m(1)]):_c('ul',{staticClass:"nav nav-tabs nav-fill"},[_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"contacts",style:(_vm.isShowUsers
                ? 'margin-left: -230px!important;'
                : 'margin-left: 0px!important;')},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active",attrs:{"id":"teachersList"}},[_c('div',{staticClass:"chatSearch"},[_c('b-form-input',{attrs:{"placeholder":"Багш хайх...","size":"sm"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_vm._v(" "),_c('div',{staticClass:"direct-chat-contacts"},[_c('b-overlay',{attrs:{"show":_vm.isSyncUser,"rounded":"sm"}},[(this.myInfo)?_c('ul',{staticClass:"contacts-list"},_vm._l((_vm.chatUsers.filter(function (user) { return this$1.searchValue != ''
                            ? user.name
                                .toLowerCase()
                                .includes(this$1.searchValue.toLowerCase())
                            : 1 == 1; }
                        )),function(user){return _c('li',{key:user.id,class:_vm.selectedUser
                            ? {
                                active: _vm.selectedUser.id == user.id,
                              }
                            : '',attrs:{"role":"button"},on:{"click":function($event){return _vm.showChat(user)}}},[_c('a',{attrs:{"href":"#"}},[_c('div',{class:[
                              user.my_chats_count > 0
                                ? 'contacts-list-info'
                                : 'contacts-list-info-no',
                              user.active ? 'contact-active' : '' ]},[_c('div',{staticClass:"contacts-list-name d-flex"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light","src":user.image,"size":"24"}}),_vm._v(" "),_c('div',{staticClass:"chatListStatus",class:user.active
                                    ? 'activeListStatus'
                                    : 'inActiveListStatus'}),_vm._v(" "),_c('div',{staticClass:"contact-item"},[_c('div',{staticClass:"contact-name"},[_c('small',[(user.active)?_c('strong',[_vm._v("\n                                      "+_vm._s(user.name)+"\n                                    ")]):_c('span',[_vm._v("\n                                      "+_vm._s(user.name)+"\n                                    ")]),_vm._v(" "),_c('small',[(user.my_chats_count > 0)?_c('b-badge',{attrs:{"title":'Шинэ зурвас: ' + user.my_chats_count,"variant":"light"}},[_vm._v("\n                                        "+_vm._s(user.my_chats_count)+"\n                                      ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"contacts-list-msg"},[_c('small',[_vm._v("\n                                    "+_vm._s(user.position_name)+"\n                                  ")])])])],1)])])])}),0):_vm._e()])],1)]),_vm._v(" "),(
                  _vm.authUser.userRoleInfo.name != 'dentstu' &&
                  _vm.authUser.userRoleInfo.name != 'rentpa'
                )?_c('div',{staticClass:"tab-pane",attrs:{"id":"parentsList"}},[_c('div',{staticClass:"chatSearch"},[_c('b-form-input',{attrs:{"placeholder":"Эцэг эх хайх...","size":"sm"},model:{value:(_vm.searchParent),callback:function ($$v) {_vm.searchParent=$$v},expression:"searchParent"}})],1),_vm._v(" "),_c('div',{staticClass:"direct-chat-contacts"},[_c('b-overlay',{attrs:{"show":_vm.isSyncUser,"rounded":"sm"}},[(this.myInfo)?_c('ul',{staticClass:"contacts-list"},_vm._l((_vm.chatParents.filter(function (user) { return this$1.searchParent != ''
                            ? user.name
                                .toLowerCase()
                                .includes(this$1.searchParent.toLowerCase()) ||
                              user.child_name
                                .toLowerCase()
                                .includes(this$1.searchParent.toLowerCase())
                            : 1 == 1; }
                        )),function(user){return _c('li',{key:user.id,class:_vm.selectedUser
                            ? {
                                active: _vm.selectedUser.id == user.id,
                              }
                            : '',attrs:{"role":"button"},on:{"click":function($event){return _vm.showChat(user)}}},[_c('a',{attrs:{"href":"#"}},[_c('div',{class:[
                              user.my_chats_count > 0
                                ? 'contacts-list-info'
                                : 'contacts-list-info-no',
                              user.active ? 'contact-active' : '' ]},[_c('div',{staticClass:"contacts-list-name d-flex"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light","src":user.image,"size":"24"}}),_vm._v(" "),_c('div',{staticClass:"chatListStatus",class:user.active
                                    ? 'activeListStatus'
                                    : 'inActiveListStatus'}),_vm._v(" "),_c('div',{staticClass:"contact-item"},[_c('div',{staticClass:"contact-name"},[_c('small',[(user.active)?_c('strong',[_vm._v("\n                                      "+_vm._s(user.name)+"\n                                    ")]):_c('span',[_vm._v("\n                                      "+_vm._s(user.name)+"\n                                    ")]),_vm._v(" "),_c('small',[(user.my_chats_count > 0)?_c('b-badge',{attrs:{"title":'Шинэ зурвас: ' + user.my_chats_count,"variant":"light"}},[_vm._v("\n                                        "+_vm._s(user.my_chats_count)+"\n                                      ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"contacts-list-msg"},[_c('small',[_vm._v("\n                                    "+_vm._s(user.full_name)+"\n                                    "+_vm._s(user.child_name)+"\n                                  ")])])])],1)])])])}),0):_vm._e()])],1)]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"chats"},[_c('div',{staticClass:"chatBody"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChatMode),expression:"showChatMode"}],staticClass:"card card-light chatCard"},[(_vm.selectedUser)?_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_c('b-avatar',{staticClass:"mr-3",attrs:{"variant":"primary","src":_vm.selectedUser.image,"size":"24"}}),_vm._v(" "),_c('div',{class:[
                      'chatStatus',
                      _vm.selectedUser.active == true ? 'activeStatus' : 'inActiveStatus' ]}),_vm._v("\n                  "+_vm._s(_vm.selectedUser.name)+"\n                ")],1),_vm._v(" "),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button"},on:{"click":_vm.closeUserChat}},[_c('i',{staticClass:"fas fa-times"})])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body"},[(_vm.selectedUser)?_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll"}],staticClass:"direct-chat-messages"},[_c('b-overlay',{attrs:{"show":_vm.chatIsLoad,"rounded":"sm"}},[_vm._l((_vm.chats),function(chat){return _c('div',{key:chat.id,class:[
                        'direct-chat-msg position-relative',
                        {
                          right: _vm.myInfo.id == chat.from_id,
                        } ]},[_c('small',{staticClass:"direct-chat-timestamp",class:_vm.myInfo.id == chat.from_id ? 'float-right' : 'direct-chat-left'},[_vm._v("\n                        "+_vm._s(_vm._f("dateWithTime")(chat.created_at))+"\n                      ")]),_vm._v(" "),_c('div',[(chat.from_id != _vm.myInfo.id)?_c('b-avatar',{staticClass:"direct-chat-img",attrs:{"variant":"primary","src":_vm.selectedUser.image,"size":"24"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"direct-chat-text position-relative",class:{
                            'float-right direct-chat-text-right':
                              chat.from_id == _vm.myInfo.id,
                          },style:(_vm.checkImg(chat.message) == true &&
                            chat.message.replace(/<\/?[^>]+(>|$)/g, '').length <= 40
                              ? _vm.myInfo.id == chat.from_id
                                ? ("margin-left:calc(100% - 80px - " + (chat.message.replace(/<\/?[^>]+(>|$)/g, '').length) + "ch - 4ch + 80px)")
                                : ("margin-right:calc(100% - 80px - 30px - " + (chat.message.replace(/<\/?[^>]+(>|$)/g, '').length) + "ch - 4ch + 80px)")
                              : ''),attrs:{"role":"button"},on:{"mouseover":function($event){return _vm.showReactions(chat)}}},[_c('span',{domProps:{"innerHTML":_vm._s(chat.message)}}),_vm._v(" "),(chat.reaction)?_c('b-badge',{staticClass:"reaction-button mb-1",class:{
                              'reaction-button-right': chat.from_id == _vm.myInfo.id,
                              likeButton: chat.reaction == 'like',
                            },attrs:{"pill":"","variant":"light","title":chat.reaction,"role":"button"}},[(chat.reaction != 'like')?_c('img',{attrs:{"width":"15","title":chat.reaction == 'like'
                                  ? 'Таалагдсан'
                                  : chat.reaction == 'heart'
                                  ? 'Маш их таалагдсан'
                                  : chat.reaction == 'laughing'
                                  ? 'Инээсэн'
                                  : chat.reaction == 'astonished'
                                  ? 'Гайхширсан'
                                  : chat.reaction == 'sob'
                                  ? 'Гунигласан'
                                  : 'Уурласан',"src":chat.reaction == 'heart'
                                  ? '/img/heart.svg'
                                  : chat.reaction == 'laughing'
                                  ? '/img/laughing.svg'
                                  : chat.reaction == 'astonished'
                                  ? '/img/astonished.svg'
                                  : chat.reaction == 'sob'
                                  ? '/img/sob.svg'
                                  : '/img/rage.svg'}}):_c('span',[_vm._v("👍")])]):_vm._e()],1),_vm._v(" "),(chat.from_id != _vm.myInfo.id)?_c('div',[_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(chat.showReact == 1),expression:"chat.showReact == 1"}],staticClass:"reactions",class:{
                              'reactions-right': chat.from_id == _vm.myInfo.id,
                            },style:(chat.showReact == 1
                                ? 'visibility: visible;opacity: 1;'
                                : 'visibility: hidden;opacity: 0;'),attrs:{"pill":"","variant":"light"},on:{"mouseleave":function($event){return _vm.reactionClose(chat)}}},[_c('div',{staticClass:"st-btn st-first",class:{
                                'st-btn-active': chat.reaction == 'like',
                              },attrs:{"data-reaction":"like"},on:{"click":function($event){return _vm.reactChat(chat, 'like')}}},[_vm._v("\n                              👍\n                            ")]),_vm._v(" "),_c('div',{staticClass:"st-btn",class:{
                                'st-btn-active': chat.reaction == 'heart',
                              },attrs:{"data-reaction":"heart_eyes"},on:{"click":function($event){return _vm.reactChat(chat, 'heart')}}},[_c('img',{attrs:{"src":"/img/heart.svg"}})]),_vm._v(" "),_c('div',{staticClass:"st-btn",class:{
                                'st-btn-active': chat.reaction == 'laughing',
                              },attrs:{"data-reaction":"laughing"},on:{"click":function($event){return _vm.reactChat(chat, 'laughing')}}},[_c('img',{attrs:{"src":"/img/laughing.svg"}})]),_vm._v(" "),_c('div',{staticClass:"st-btn",class:{
                                'st-btn-active': chat.reaction == 'astonished',
                              },attrs:{"data-reaction":"astonished"},on:{"click":function($event){return _vm.reactChat(chat, 'astonished')}}},[_c('img',{attrs:{"src":"/img/astonished.svg"}})]),_vm._v(" "),_c('div',{staticClass:"st-btn",class:{
                                'st-btn-active': chat.reaction == 'sob',
                              },attrs:{"data-reaction":"sob"},on:{"click":function($event){return _vm.reactChat(chat, 'sob')}}},[_c('img',{attrs:{"src":"/img/sob.svg"}})]),_vm._v(" "),_c('div',{staticClass:"st-btn st-last",class:{
                                'st-btn-active': chat.reaction == 'rage',
                              },attrs:{"data-reaction":"rage"},on:{"click":function($event){return _vm.reactChat(chat, 'rage')}}},[_c('img',{attrs:{"src":"/img/rage.svg"}})])])],1):_vm._e()],1)])}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.typingUser.id == _vm.selectedUser.id &&
                        this.typingUser.toUid == this.myInfo.id
                      ),expression:"\n                        typingUser.id == selectedUser.id &&\n                        this.typingUser.toUid == this.myInfo.id\n                      "}],staticClass:"text-muted"},[_c('b-avatar',{staticClass:"direct-chat-img mr-1 mt-2",attrs:{"variant":"primary","src":_vm.selectedUser.image,"size":"15"}}),_vm._v(" "),_c('small',[_vm._v("\n                        "+_vm._s(_vm.typingUser.name)+"\n                      ")]),_vm._v(" "),_c('img',{staticClass:"isTyping",attrs:{"src":"/img/typing.svg"}})],1)],2)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-footer p-0"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChatMode),expression:"showChatMode"}],staticClass:"chat-input"},[_c('div',{staticClass:"input-group"},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"textEditor",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.handleTextChange($event)}},model:{value:(_vm.newChat),callback:function ($$v) {_vm.newChat=$$v},expression:"newChat"}}),_vm._v(" "),_c('span',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.sendChat()}}},[_c('i',{staticClass:"fas fa-paper-plane"})])])],1)])])])])])])])]):_vm._e(),_vm._v(" "),(_vm.authUser.userRoleInfo.name != 'dentstu')?_c('div',{attrs:{"id":"chatButton"}},[_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.showChatWindow()}}},[_c('i',{staticClass:"fas fa-comments"})]),_vm._v(" "),_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.unSeenCount > 0),expression:"unSeenCount > 0"}],staticClass:"navbar-badge chatEffect",attrs:{"pill":"","variant":"warning"}},[_c('span',[_vm._v(_vm._s(_vm.unSeenCount))])])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active py-0",attrs:{"href":"#teachersList","data-toggle":"tab"}},[_vm._v("\n                Багш\n              ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link py-0",attrs:{"href":"#parentsList","data-toggle":"tab"}},[_vm._v("\n                Эцэг эх\n              ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active py-0",attrs:{"href":"#teachersList","data-toggle":"tab"}},[_vm._v("\n                Багш нар\n              ")])])}]

export { render, staticRenderFns }