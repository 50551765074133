<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Бүх ангийн эцэг эхийн зөвлөл</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <table class="table table-hover table-bordered">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Анги</th>
                <th>Анги удирдсан багш</th>
                <th>Эцэг эхийн зөвлөл</th>
                <th>Сургуулийн эцэг эхийн зөвлөл</th>
                <!-- <th>Үйлдлүүд</th> -->
              </tr>
            </thead>
            <tbody v-for="(parent, index) in schoolClassesBoards" :key="index">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge bg-info">{{ parent.full_name }}</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span class="badge bg-info"
                    >{{ parent.teacher.teacher_last_name[0] }}.
                    {{ parent.teacher.teacher_name }}</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="parent.my_parent_boards">
                    <div
                      v-for="(p, ind) in parent.my_parent_boards.filter(
                        (i) => i.school_board == null
                      )"
                      :key="ind"
                    >
                      {{ ind + 1 }}.
                      <span v-if="p.get_my_childs">
                        <span v-for="(stu, i) in p.get_my_childs" :key="i">
                          {{ stu.my_profile_info.student_last_name[0] }}.
                          {{ stu.my_profile_info.student_name }}({{
                            stu.my_school_class_info.full_name
                          }})
                        </span>
                      </span>
                      <span v-if="p.my_parent_info">
                        <b>Аав: </b>
                        <span v-if="p.my_parent_info.parent_father_ovog">
                          {{ p.my_parent_info.parent_father_ovog[0] }}.
                          {{ p.my_parent_info.parent_father_name }}
                        </span>

                        <b>Утас:</b>
                        {{ p.my_parent_info.parent_father_phone_number }},
                        {{ p.my_parent_info.parent_mother_phone_number }}
                        <b>Ажлын газар:</b>
                        {{ p.my_parent_info.parent_mother_ajil_gazar }}

                        <br />
                        <b>Ээж: </b>
                        <span v-if="p.my_parent_info.parent_mother_ovog">
                          {{ p.my_parent_info.parent_mother_ovog[0] }}.
                          {{ p.my_parent_info.parent_mother_name }}
                        </span>
                        <b>Утас:</b>
                        {{ p.my_parent_info.parent_mother_phone_number }}
                        <b>Ажлын газар:</b>
                        {{ p.my_parent_info.parent_mother_ajil_gazar }}
                      </span>
                      <b>Үүрэг:</b>
                      <b-badge
                        v-if="p.board_type == 'headman'"
                        variant="success"
                        >Зөвлөлийн дарга</b-badge
                      >
                      <b-badge v-if="p.board_type == 'member'" variant="info"
                        >Гишүүн</b-badge
                      >
                    </div>
                  </span>
                </td>
                <td class="dtr-control sorting_1 bg-success" tabindex="0">
                  <span v-if="parent.my_parent_boards">
                    <div
                      v-for="(p, ind) in parent.my_parent_boards.filter(
                        (i) => i.school_board != null
                      )"
                      :key="ind"
                    >
                      {{ ind + 1 }}.
                      <span v-if="p.get_my_childs">
                        <span v-for="(stu, i) in p.get_my_childs" :key="i">
                          {{ stu.my_profile_info.student_last_name[0] }}.
                          {{ stu.my_profile_info.student_name }}({{
                            stu.my_school_class_info.full_name
                          }})
                        </span>
                      </span>
                      <span v-if="p.my_parent_info">
                        <b>Аав: </b>
                        <span v-if="p.my_parent_info.parent_father_ovog">
                          {{ p.my_parent_info.parent_father_ovog[0] }}.
                          {{ p.my_parent_info.parent_father_name }}
                        </span>

                        <b>Утас:</b>
                        {{ p.my_parent_info.parent_father_phone_number }},
                        {{ p.my_parent_info.parent_mother_phone_number }}
                        <b>Ажлын газар:</b>
                        {{ p.my_parent_info.parent_mother_ajil_gazar }}

                        <br />
                        <b>Ээж: </b>
                        <span v-if="p.my_parent_info.parent_mother_ovog">
                          {{ p.my_parent_info.parent_mother_ovog[0] }}.
                          {{ p.my_parent_info.parent_mother_name }}
                        </span>
                        <b>Утас:</b>
                        {{ p.my_parent_info.parent_mother_phone_number }}
                        <b>Ажлын газар:</b>
                        {{ p.my_parent_info.parent_mother_ajil_gazar }}
                      </span>
                      <b>Үүрэг:</b>
                      <b-badge
                        v-if="p.school_board == 'headman'"
                        variant="success"
                        >Сургуулийн зөвлөлийн дарга</b-badge
                      >
                      <b-badge v-if="p.school_board == 'member'" variant="info"
                        >Сургуулийн зөвлөлийн гишүүн
                        <a v-if="p.board_type == 'headman'"
                          >(Ангийн зөвлөлийн дарга)</a
                        ><a v-if="p.board_type == 'member'"
                          >(Ангийн зөвлөлийн гишүүн)</a
                        ></b-badge
                      >

                      <b-badge @click="editShoolBoardParent(parent, p)"
                        ><i class="fa fa-check"></i
                      ></b-badge>
                    </div>
                    <b-badge
                      v-if="
                        parent.my_parent_boards.filter(
                          (i) => i.school_board != null
                        ).length == 0
                      "
                      @click="addShoolBoardParent(parent)"
                      ><i class="fa fa-plus"></i
                    ></b-badge>
                  </span>
                </td>
                <!-- <td class="dtr-control sorting_1" tabindex="0">
                  <a href="#" class="badge bg-primary"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click.prevent="getParentAppreciations(parent)"
                  >
                    <i class="fas fa-hand-holding-heart"></i>
                  </a>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <b-modal
      v-show="showMode"
      size="sl"
      id="modal-show-school-board"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Эцэг эх бүртгэх"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label>Эцэг эх</label>
            <multiselect
              v-model="parentId"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="profile_id"
              label="fullName"
              placeholder="Эцэг эх сонгох сонгоно уу?"
              :options="modalParents"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="true"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.fullName }}</strong></template
              >
            </multiselect>
          </div>
          <div class="form-group row">
            <b-form-checkbox
              id="schoolBoardType"
              v-model="schoolBoardType"
              name="schoolBoardType"
              value="headman"
              unchecked-value="member"
            >
              Сургуулийн зөвлөлийн дарга болгох
            </b-form-checkbox>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-between">
        <button
          type="submit"
          class="btn btn-success"
          @click.prevent="createSchoolBoard()"
        >
          Нэмэх
        </button>
        <b-button @click="cancelSchoolBoard()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      selected: "0",
      classId: null,
      changeParInfo: null,
      schoolBoardType: "member", //Сургулуийн зөвлөлийн гишүүн, Дарга
      parentId: "",
      schoolClassesBoards: [],
      modalParents: [],
      action: "",
      editMode: false,
      parents: [],
      allYears: [],
      year_id: "",
      showMode: false,
      parentAppreciations: [],
      selectedParent: "",
      form: new Form({
        checked: false,
        profiles_id: "",
      }),

      headerBgVariant: "light",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    year_id: function (newVal, oldVal) {
      axios
        .post("/manager/getAllSchoolClassParentBoards", {
          yearID: this.year_id,
        })
        .then((res) => {
          this.schoolClassesBoards = res.data.schoolClassesBoards;
          this.allYears = res.data.allYears;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    classId: function (newVal, oldVal) {
      this.action = "Эцэг эхийн мэдээллийг шинэчилж байна...";
      if (newVal != null) {
        axios
          .get("/teacher/getPlanParentList/" + newVal)
          .then((response) => {
            this.modalParents = response.data.parents;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
    },
  },
  methods: {
    getAllSchoolClassParentBoards() {
      axios
        .post("/manager/getAllSchoolClassParentBoards", {
          yearID: this.year_id,
        })
        .then((res) => {
          this.schoolClassesBoards = res.data.schoolClassesBoards;
          this.allYears = res.data.allYears;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    addShoolBoardParent(parent) {
      //   console.log(parent);
      this.showMode = !this.showMode;
      this.classId = parent.id;
      //   this.changeParInfo = parent;
      this.$bvModal.show("modal-show-school-board");
    },
    editShoolBoardParent(parent, pInfo) {
      //   console.log(parent);
      //   console.log(pInfo);
      this.showMode = !this.showMode;
      this.classId = parent.id;
      this.changeParInfo = pInfo.my_parent_info.id; //Сургуулийн зөвлөлөөс хасах
      this.$bvModal.show("modal-show-school-board");
    },
    createSchoolBoard() {
      axios
        .post("/manager/createSchoolBoard", {
          parentId: this.parentId, //Нэмэх эцэг эхийн Profile ID
          classId: this.classId, //Аль анги
          schoolBoardType: this.schoolBoardType,
          changeParID: this.changeParInfo,
        })
        .then((res) => {
          // console.log(res.data);
          this.classId = null;
          this.parentId = "";
          this.schoolBoardType = "member"; //Сургуулийн зөвлөийн гишүүн, дарга
          Fire.$emit("loadParentBoard");
          this.$bvModal.hide("modal-show-school-board");
        })
        .catch();
    },
    cancelSchoolBoard() {
      this.showMode = !this.showMode;
      this.classId = null;
      this.parentId = "";
      this.schoolBoardType = "member";
      this.$bvModal.hide("modal-show-school-board");
    },
    // getSchoolClassParents() {
    //   axios
    //     .get("/manager/getSchoolClassParents/" + this.selected)
    //     .then((response) => {
    //       //   console.log(response.data.parents);
    //       this.parents = response.data.parents;
    //     })
    //     .catch((error) => {
    //       //   console.log(error);
    //     });
    // },
    // refreshSchoolStudents(schoolEntrant) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
    //   this.editMode = true;
    //   this.form.reset();
    //   this.form.fill(schoolEntrant);
    //   if (schoolEntrant.status1 == "registered") this.form.checked = false;
    //   else if (schoolEntrant.status1 == "confirmed") this.form.checked = true;
    // },
    // getParentAppreciations(parent) {
    //   this.editMode = false;
    //   this.selectedParent = "";
    //   axios
    //     .get("/manager/getParentAppreciations/" + parent.pro_par_id)
    //     .then((response) => {
    //       //   console.log(response.data);
    //       this.parentAppreciations = response.data.parentAppreciations;
    //       this.editMode = true;
    //       this.$toastr.s("Амжилттай", "Шинэчилсэн");
    //       this.selectedParent = parent;
    //       //   Fire.$emit("loadParentBoard");
    //     })
    //     .catch((errors) => {
    //       //   console.log(errors);
    //     });
    // },

    // readManager(appreciation) {
    //   axios
    //     .post("/manager/readManager", {
    //       appreciation,
    //     })
    //     .then((response) => {
    //       //   console.log(response.data);
    //       this.editMode = true;
    //       this.$toastr.s("Амжилттай", "Шинэчилсэн");
    //       Fire.$emit("loadSelectedParentApp");
    //     })
    //     .catch((errors) => {
    //       //   console.log(errors);
    //     });
    // },
    // deleteSchoolStudent(schoolteacher) {
    //   //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    // },
    // cancelEdit() {
    //   this.form.reset();
    //   this.editMode = !this.editMode;
    // },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    this.getAllSchoolClassParentBoards();
    // this.getAllEntrants();
    Fire.$on("loadParentBoard", () => {
      this.getAllSchoolClassParentBoards();
    });
    // Fire.$on("loadSelectedParentApp", () => {
    //   this.getParentAppreciations(this.selectedParent);
    // });
  },
};
</script>

<style></style>
