<template>
  <div class="row">
    <div class="col-lg-8 mt-2">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Таксономын түвшин(Тархины үйлийн системийн түвшин)
          </h3>
          <!-- <search-manager
            @getWasSearchManagers="schoolmanagers = $event"
          ></search-manager> -->
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-hover table-striped table-valign-middle">
            <thead>
              <tr role="row">
                <th>№</th>
                <th>Үйлийн систем</th>
                <th>Үйлийн системийн түвшин</th>
                <th>Үйлийн системийн тайлбар</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="odd"
                v-for="(levelname, index) in alllevelname"
                :key="levelname.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ levelname.mental_system_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ levelname.tax_lev_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ levelname.tax_lev_name_explain }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ levelname.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ levelname.updated_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <!-- <a
                    href="#"
                    class="badge bg-purple"
                    @click="passChangeSchoolEmployee(complaint)"
                  >
                    <i class="fas fa-key"></i
                  ></a> -->
                  <a
                    href="#"
                    class="badge bg-warning"
                    @click="editLevelName(levelname)"
                    ><i class="fas fa-edit"></i
                  ></a>

                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteLevelName(levelname)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
          <h3 class="card-title" v-show="editMode">
            Тархины үйлийн систем бүртгэх
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="!editMode ? createLevelName() : updateLevelName()"
          >
            <div class="card-body">
              <div class="form-group">
                <multiselect
                  v-model="form.tax_men_sys_id"
                  deselect-label="Can't remove this value"
                  track-by="mental_system_name"
                  label="mental_system_name"
                  placeholder="Select one"
                  :options="mentalSystems"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.mental_system_name }}</strong> is written
                    in<strong>
                      {{ option.mental_system_name_explain }}</strong
                    ></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэр</label>
                <b-textarea
                  name="tax_lev_name"
                  v-model="form.tax_lev_name"
                  text-field="tax_lev_name"
                ></b-textarea>
                <has-error :form="form" field="tax_lev_name"></has-error>
              </div>
              <div class="form-group">
                <label>Тархины үйлийн системийн нэршлийн тайлбар</label>

                <b-textarea
                  name="tax_lev_name_explain"
                  v-model="form.tax_lev_name_explain"
                  text-field="tax_lev_name_explain"
                ></b-textarea>
                <has-error
                  :form="form"
                  field="tax_lev_name_explain"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" v-show="!editMode">
                Хадгалах
              </button>
              <button type="submit" class="btn btn-primary" v-show="editMode">
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editMode"
                @click.prevent="cancelEdit"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      alllevelname: [],
      mentalSystems: [],
      form: new Form({
        id: "",
        year_id: "", //backend талд хадгална
        school_id: "", //backend талд хадгална
        department_id: "", //backend талд хадгална
        create_emp_id: "", //backend талд хадгална
        position_id: "", //backend талд хадгална
        tax_men_sys_id: "",
        tax_lev_name: "",
        tax_lev_name_explain: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllInfo() {
      axios
        .get("/curriculumadministrator/getAlltaxanomyLevelName")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.alllevelname = response.data.alllevelname;
          this.mentalSystems = response.data.mentalSystems;
          //   Fire.$emit("loadAllTaxanomylevelname");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editLevelName(mental) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", mental);
      this.editMode = true;
      this.form.reset();
      this.form.fill(mental);

      let selectEl = this.mentalSystems.filter(function (e) {
        return e.id == mental.tax_men_sys_id;
      });

      this.form.tax_men_sys_id = selectEl[0];
      //   console.log(this.form.tax_men_sys_id);
    },
    updateLevelName() {
      this.form
        .put("/curriculumadministrator/updatetaxanomyLevelName/" + this.form.id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllTaxanomyLevelName");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    createLevelName() {
      this.form
        .post("/curriculumadministrator/createtaxanomyLevelName")
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай хадгалсан.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevelName");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    deleteLevelName(levelname) {
      this.form
        .delete(
          `/curriculumadministrator/deletetaxanomyLevelName/${levelname.id}`
        )
        .then((response) => {
          this.$toastr.s(
            "Тархины үйлийн системийг амжилттай устгалаа.",
            "Амжилттай"
          );
          Fire.$emit("loadAllTaxanomyLevelName");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Тархины үйлийн систем үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTaxanomyLevelName", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
