<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Боловсролын төрөл</h3>
    </div>
    <!-- /.card-header -->
    <div class="card-body table-responsive">
      <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
          <div class="col-sm-8">
            <div class="card card-default">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-atlas"></i>
                  Боловсролын төрөл:
                  {{ educationalLevels.length }} байна.
                </h3>
              </div>
              <div class="card-body">
                <b-overlay :show="loaded" rounded="sm">
                  <b-row>
                    <b-col lg="12" class="my-1">
                      <b-form-group
                        label="Нэг хуудсанд"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPageUndoneHW"
                          :options="pageOptionsUnDoneHW"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group
                        label="Хайх утга!"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-input"
                            v-model="filterUnDoneHW"
                            type="search"
                            placeholder="Хайлт хийх утгаа оруулна уу!"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              :disabled="!filterUnDoneHW"
                              @click="filterUnDoneHW = ''"
                              >Цэвэрлэх</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-pagination
                    v-model="currentPageUndoneHW"
                    :total-rows="totalRowsUndoneHW"
                    :per-page="perPageUndoneHW"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                  <b-table
                    hover
                    :items="educationalLevels"
                    :fields="fieldsUndoneHW"
                    :current-page="currentPageUndoneHW"
                    :per-page="perPageUndoneHW"
                    :filter="filterUnDoneHW"
                    :filter-ignored-fields="filterIgnoredFieldsUnDoneHW"
                    @filtered="onFilteredUndoneHW"
                    :filter-included-fields="filterOnUnDoneHW"
                    empty-text="Одоогоор мэдээлэл бүртгэгдээгүй байна."
                  >
                    <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(name)="data">
                      {{ data.item.name }}
                    </template>
                    <template #cell(description)="data">
                      {{ data.item.description }}
                    </template>
                    <template #cell(action)="data">
                      <a
                        href="#"
                        class="badge bg-warning"
                        @click="editEducationalLevel(data.item)"
                        ><i class="fas fa-pencil-alt"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="removeEducationalLevel(data.item)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">
                  Боловсролын төрөл бүртгэх
                </h3>
                <h3 class="card-title" v-show="editMode">
                  Боловсролын төрөл засах
                </h3>
              </div>
              <form
                @submit.prevent="
                  !editMode
                    ? createEducationalLevel()
                    : updateEducationalLevel()
                "
              >
                <div class="card-body">
                  <div class="form-group">
                    <label>Боловсролын төрлийн нэр</label>
                    <input
                      type="text"
                      name="name"
                      v-model="form.name"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('name'),
                        },
                      ]"
                      placeholder="Боловсролын төрлийн нэр"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Боловсролын төрлийн мэдээлэл</label>
                    <input
                      type="text"
                      name="description"
                      v-model="form.description"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has('description'),
                        },
                      ]"
                      placeholder="Боловсролын төрлийн мэдээлэл"
                    />
                    <has-error :form="form" field="description"></has-error>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="!editMode"
                  >
                    Хадгалах
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    v-show="editMode"
                  >
                    Засах
                  </button>
                  <button
                    class="btn btn-warning"
                    v-show="editMode"
                    @click.prevent="cancelEdit"
                  >
                    Болих
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      editMode: false,
      educationalLevels: [],
      form: new Form({
        id: "",
        name: "",
        description: "",
      }),

      loaded: true,
      // Хүснэгтийн тохиргоо
      totalRowsUndoneHW: 1,
      currentPageUndoneHW: 1,
      perPageUndoneHW: 10,
      pageOptionsUnDoneHW: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFieldsUnDoneHW: ["course_hw_stud_hw_parent_comment"],
      filterUnDoneHW: null,
      filterOnUnDoneHW: [],
      fieldsUndoneHW: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Боловсролын төрөл",
          sortable: true,
        },
        {
          key: "description",
          label: "Боловсролын төрлийн тухай",
          sortable: true,
        },
        {
          key: "action",
          label: "Үйлдлүүд",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    onFilteredUndoneHW(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsUndoneHW = filteredItems.length;
      this.currentPageUndoneHW = 1;
    },
    getAllEducationalLevel() {
      axios
        .get("/principal/getAllEducationalLevel")
        .then((res) => {
          //   console.log(res);
          this.educationalLevels = res.data.educationalLevels;
          this.totalRowsUndoneHW = this.educationalLevels.length;
          this.loaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    viewDepartment(department) {
      //   console.log("Дэлгэрэнгүй мэдээлэл харах" + department);
    },
    editEducationalLevel(item) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + item);
      this.editMode = true;
      this.form.reset();
      this.form.fill(item);
    },
    updateEducationalLevel() {
      this.form
        .put("/principal/updateEducationalLevel/" + this.form.id)
        .then((response) => {
          this.$toastr.s(
            "Боловсролын төрлийн мэдээллийг амжилттай шинэчиллээ.",
            "Шинэчилсэн"
          );
          Fire.$emit("loadEducationalLevel");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch();
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
    deleteDepartment(department) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + department);
    },
    createEducationalLevel() {
      this.form
        .post("/principal/createEducationalLevel")
        .then((res) => {
          this.$toastr.s(
            "Боловсролын төрлийг амжилттай үүсгэлээ.",
            "Амжилттай"
          );
          Fire.$emit("loadEducationalLevel");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Боловсролын төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    removeEducationalLevel(departmentsCurrTeam) {
      Swal.fire({
        title: "Боловсролын төрөл хасах гэж байна?",
        text: "Итгэлтэй байна уу? харгалзах багш нарын баг устах болно.",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          axios
            .delete(
              "/principal/removeEducationalLevel/" + departmentsCurrTeam.id,
              {
                params: { departmentsCurrTeam: departmentsCurrTeam },
              }
            )
            .then((response) => {
              Fire.$emit("loadEducationalLevel");
              this.$toastr.s(
                "Боловсролын төрлийг амжилттай устгалаа!",
                "Шинэчилсэн"
              );
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
  },
  created() {
    this.getAllEducationalLevel();
    Fire.$on("loadEducationalLevel", () => {
      this.getAllEducationalLevel();
    });
  },
};
</script>

<style></style>
