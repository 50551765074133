<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Сарын төлөвлөгөө</h3>
          <!-- <search-student
            ></search-student> -->
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="3">
                  <multiselect
                    v-model="yearId"
                    monthsRe
                    deselect-label="Хасах"
                    select-label="Сонгох"
                    track-by="id"
                    :custom-label="customYearName"
                    placeholder="Хичээлийн жил"
                    :options="years"
                    :searchable="true"
                    :allow-empty="true"
                    @input="getAllMonthPlans"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >{{ option.start_date | dateYear }}-{{
                          option.finish_date | dateYear
                        }}
                        - хичээлийн жил
                      </strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="3">
                  <multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="month"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <multiselect
                    v-if="userInfo.name == 'cipalprin'"
                    v-model="ownId"
                    :options="topSelectTeachers"
                    :custom-label="customOwnerName"
                    :multiple="false"
                    track-by="emp_user_id"
                    label="emp_user_id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Бүртгэсэн"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{
                          option.emp_teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}. {{ option.emp_teacher_name }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <multiselect
                    v-model="leadId"
                    :options="topSelectTeachers"
                    :custom-label="customOwnerName"
                    :multiple="false"
                    track-by="emp_user_id"
                    label="emp_user_id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Хариуцах эзэн"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong>
                        {{
                          option.emp_teacher_last_name
                            | capitalizeAndEkhniiVseg
                        }}. {{ option.emp_teacher_name }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button
                    variant="success"
                    @click.prevent="getAllMonthPlans()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="show" rounded="sm">
            <b-row>
              <b-col lg="12" class="my-1">
                <b-form-group
                  label="Нэг хуудсанд харуулах тоо"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageCompetitions"
                    :options="pageOptionsRooms"
                    size="sm"
                    >\</b-form-select
                  >
                </b-form-group>

                <b-form-group
                  label="Хайлт хийх утга оруулна уу!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterCompetitions"
                      type="search"
                      placeholder="Хайлт хийх утгаа оруулна уу!"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterCompetitions"
                        @click="filterCompetitions = ''"
                        >Цэвэрлэх</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-pagination
              v-model="currentPageCompetitions"
              :total-rows="totalRowsCompetitions"
              :per-page="perPageCompetitions"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <b-table
              hover
              :items="allMonthPlans"
              :fields="fields"
              :current-page="currentPageCompetitions"
              :per-page="perPageCompetitions"
              :filter="filterCompetitions"
              :filter-included-fields="filterOnCompetitions"
              :filter-ignored-fields="filterIgnoredFields"
              @filtered="onFilteredCompetitions"
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(actions)="row">
                <a
                  href="#"
                  class="badge bg-primary"
                  @click="editMonthPlan(row.item)"
                  v-if="
                    userInfo.name == 'cipalprin' || row.item.leader_id == myId
                  "
                  ><i class="fa fa-check"></i
                ></a>
                <a
                  href="#"
                  class="badge bg-success"
                  @click="showMonthPlan(row.item)"
                  ><i class="fa fa-eye"></i
                ></a>
                <a
                  href="#"
                  class="badge bg-warning"
                  @click="showTaskForMonthPlan(row.item)"
                  ><i class="fa fa-plus"></i
                ></a>
              </template>
              <template #cell(point)="row">
                <div class="d-flex justify-content">
                  <div class="row">
                    <div class="col-lg-12 mt-3">
                      <small>Төлөвлөгөөний гүйцэтгэл</small>
                      <div class="progress progress-xs progress-striped active">
                        <div
                          v-if="row.item.point < 30"
                          class="progress-bar bg-danger"
                          :style="{
                            width: `${row.item.point}%`,
                          }"
                        ></div>
                        <div
                          v-if="row.item.point >= 30 && row.item.point < 60"
                          class="progress-bar bg-warning"
                          :style="{
                            width: `${row.item.point}%`,
                          }"
                        ></div>
                        <div
                          v-if="row.item.point >= 60 && row.item.point < 80"
                          class="progress-bar bg-primary"
                          :style="{
                            width: `${row.item.point}%`,
                          }"
                        ></div>
                        <div
                          v-if="row.item.point >= 80"
                          class="progress-bar bg-success"
                          :style="{
                            width: `${row.item.point}%`,
                          }"
                        ></div>
                      </div>
                      <small>
                        <b-badge
                          v-if="row.item.point >= 80"
                          variant="success"
                          v-show="row.item.point != null"
                        >
                          {{ row.item.point }}</b-badge
                        >
                        <b-badge
                          v-if="row.item.point >= 60 && row.item.point < 80"
                          variant="primary"
                          v-show="row.item.point != null"
                        >
                          {{ row.item.point }}</b-badge
                        >
                        <b-badge
                          v-if="row.item.point >= 30 && row.item.point < 60"
                          variant="warning"
                          v-show="row.item.point != null"
                        >
                          {{ row.item.point }}</b-badge
                        >
                        <b-badge
                          v-if="row.item.point < 30"
                          variant="danger"
                          v-show="row.item.point != null"
                        >
                          {{ row.item.point }}</b-badge
                        >
                        <b-badge
                          variant="warning"
                          v-show="row.item.point === null"
                        >
                          ОРООГҮЙ</b-badge
                        >
                      </small>
                    </div>

                    <div
                      class="col-lg-12 mt-3"
                      v-if="row.item.tasks[0] != null"
                    >
                      <small>Үүрэг даалгаврын гүйцэтгэл</small>
                      <div
                        class="progress progress-xs progress-striped active"
                        v-if="row.item.tasks[0] != null"
                      >
                        <div
                          v-if="row.item.tasks[0].avg_guitsetgel < 30"
                          class="progress-bar bg-danger"
                          :style="{
                            width: `${row.item.tasks[0].avg_guitsetgel}%`,
                          }"
                        ></div>
                        <div
                          v-if="
                            row.item.tasks[0].avg_guitsetgel >= 30 &&
                            row.item.tasks[0].avg_guitsetgel < 60
                          "
                          class="progress-bar bg-warning"
                          :style="{
                            width: `${row.item.tasks[0].avg_guitsetgel}%`,
                          }"
                        ></div>
                        <div
                          v-if="
                            row.item.tasks[0].avg_guitsetgel >= 60 &&
                            row.item.tasks[0].avg_guitsetgel < 80
                          "
                          class="progress-bar bg-primary"
                          :style="{
                            width: `${row.item.tasks[0].avg_guitsetgel}%`,
                          }"
                        ></div>
                        <div
                          v-if="row.item.tasks[0].avg_guitsetgel >= 80"
                          class="progress-bar bg-success"
                          :style="{
                            width: `${row.item.tasks[0].avg_guitsetgel}%`,
                          }"
                        ></div>
                      </div>
                      <small v-if="row.item.tasks[0] != null">
                        <b-badge
                          v-if="row.item.tasks[0].avg_guitsetgel >= 80"
                          variant="success"
                          v-show="row.item.tasks[0].avg_guitsetgel != null"
                        >
                          0 тооцоогүй={{
                            row.item.tasks[0].avg_guitsetgel
                          }}</b-badge
                        >
                        <b-badge
                          v-if="
                            row.item.tasks[0].avg_guitsetgel >= 60 &&
                            row.item.tasks[0].avg_guitsetgel < 80
                          "
                          variant="primary"
                          v-show="row.item.tasks[0].avg_guitsetgel != null"
                        >
                          0 тооцоогүй={{
                            row.item.tasks[0].avg_guitsetgel
                          }}</b-badge
                        >
                        <b-badge
                          v-if="
                            row.item.tasks[0].avg_guitsetgel >= 30 &&
                            row.item.tasks[0].avg_guitsetgel < 60
                          "
                          variant="warning"
                          v-show="row.item.tasks[0].avg_guitsetgel != null"
                        >
                          0 тооцоогүй={{
                            row.item.tasks[0].avg_guitsetgel
                          }}</b-badge
                        >
                        <b-badge
                          v-if="row.item.tasks[0].avg_guitsetgel < 30"
                          variant="danger"
                          v-show="row.item.tasks[0].avg_guitsetgel != null"
                        >
                          0 тооцоогүй={{
                            row.item.tasks[0].avg_guitsetgel
                          }}</b-badge
                        >
                        <b-badge
                          variant="warning"
                          v-show="row.item.tasks[0].avg_guitsetgel === null"
                        >
                          ОРООГҮЙ</b-badge
                        >
                      </small>
                      <small v-if="row.item.tasks[0] != null">
                        <b-badge
                          v-if="row.item.tasks[0].avg_guitsetgel_zero >= 80"
                          variant="success"
                          v-show="row.item.tasks[0].avg_guitsetgel_zero != null"
                        >
                          0 тооцсон={{
                            row.item.tasks[0].avg_guitsetgel_zero
                          }}</b-badge
                        >
                        <b-badge
                          v-if="
                            row.item.tasks[0].avg_guitsetgel_zero >= 60 &&
                            row.item.tasks[0].avg_guitsetgel_zero < 80
                          "
                          variant="primary"
                          v-show="row.item.tasks[0].avg_guitsetgel_zero != null"
                        >
                          0 тооцсон={{
                            row.item.tasks[0].avg_guitsetgel_zero
                          }}</b-badge
                        >
                        <b-badge
                          v-if="
                            row.item.tasks[0].avg_guitsetgel_zero >= 30 &&
                            row.item.tasks[0].avg_guitsetgel_zero < 60
                          "
                          variant="warning"
                          v-show="row.item.tasks[0].avg_guitsetgel_zero != null"
                        >
                          0 тооцсон={{
                            row.item.tasks[0].avg_guitsetgel_zero
                          }}</b-badge
                        >
                        <b-badge
                          v-if="row.item.tasks[0].avg_guitsetgel_zero < 30"
                          variant="danger"
                          v-show="row.item.tasks[0].avg_guitsetgel_zero != null"
                        >
                          0 тооцсон={{
                            row.item.tasks[0].avg_guitsetgel_zero
                          }}</b-badge
                        >
                        <b-badge
                          variant="warning"
                          v-show="
                            row.item.tasks[0].avg_guitsetgel_zero === null
                          "
                        >
                          ОРООГҮЙ</b-badge
                        >
                      </small>
                      <small v-if="row.item.tasks[0] != null">
                        <b-badge
                          variant="warning"
                          v-show="row.item.tasks[0].nullCount != null"
                          >үнэлгээгүй=
                          {{ row.item.tasks[0].nullCount }}</b-badge
                        >
                      </small>
                      <small v-if="row.item.tasks[0] != null">
                        <b-badge
                          variant="warning"
                          v-show="row.item.tasks[0].nullCount != null"
                          >нийт= {{ row.item.tasks[0].count }}</b-badge
                        >
                      </small>
                    </div>
                    <div
                      class="col-lg-12 mt-3"
                      v-else="row.item.tasks[0] == null"
                    >
                      <small>Үүрэгдаалгавар бүртгэгдээгүй</small>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(name)="row">
                <b-badge variant="light" style="white-space: pre-wrap">{{
                  row.item.name
                }}</b-badge>
              </template>
              <template #cell(owner)="row">
                <div variant="light" class="center-badge" v-if="row.item.owner">
                  <ul class="list-inline" style="text-align: center">
                    <img
                      class="table-avatar"
                      :src="
                        row.item.owner.profile_photo_url
                          ? row.item.owner.profile_photo_url
                          : '/images/users/user.png'
                      "
                    />
                  </ul>
                  <b-badge variant="light" style="centered-text">
                    {{
                      row.item.owner.teacher_last_name
                        | capitalizeAndEkhniiVseg
                    }}.
                    {{ row.item.owner.teacher_name }}
                  </b-badge>
                </div>
              </template>
              <template #cell(employee)="row">
                <ul class="list-inline">
                  <li
                    class="list-inline-item"
                    v-for="teacher in row.item.month_plan_teachers"
                    :key="teacher.id"
                  >
                    <img
                      @click="showImage(teacher)"
                      v-if="teacher.employee"
                      alt="Avatar"
                      v-b-tooltip.hover.top.html="
                        '<dl class=text-left><dt>Мэргэжлийн баг:</dt><dd>' +
                        teacher.department.department_name +
                        '</dd><dt>Ажлын байр:</dt><dd>' +
                        teacher.position.position_name +
                        '</dd><dt>Овог Нэр</dt><dd>' +
                        teacher.employee.teacher_last_name +
                        ' <span class=text-uppercase>' +
                        teacher.employee.teacher_name +
                        '</span></dd></dl>'
                      "
                      class="table-avatar"
                      :src="
                        teacher.employee.profile_photo_url
                          ? teacher.employee.profile_photo_url
                          : '/images/users/user.png'
                      "
                    />
                  </li>
                </ul>
              </template>
              <template #cell(date)="row">
                <div variant="light" style="white-space: pre-wrap">
                  {{ row.item.start_date | dateYearMonthDay }}
                  <br />
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  <br />
                  {{ row.item.end_date | dateYearMonthDay }}
                </div>
              </template>
            </b-table>
            <b-tfoot>
              <b-tr class="odd">
                <b-td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Сарын төлөвлөгөө дундаж /0 тооцсон/
                  <b class="dtr-control sorting_1" tabindex="0"
                    >{{ unelgeeAvg }}%</b
                  >
                </b-td>
              </b-tr>
              <b-tr class="odd">
                <b-td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Сарын төлөвлөгөө дундаж /0 тооцохгүй/
                  <b class="dtr-control sorting_1" tabindex="0"
                    >{{ unelgee0Avg }}%</b
                  >
                </b-td>
              </b-tr>
              <b-tr class="odd">
                -------------------------------------------------------------------------
              </b-tr>
              <b-tr class="odd">
                <b-td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Үүрэг даалгавар дундаж /0 тооцсон/
                  <b class="dtr-control sorting_1" tabindex="0"
                    >{{ taskUnelgee0Avg }}%</b
                  >
                </b-td>
              </b-tr>
              <b-tr class="odd">
                <b-td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Үүрэг даалгавар дундаж /0 тооцохгүй/
                  <b class="dtr-control sorting_1" tabindex="0"
                    >{{ taskUnelgeeAvg }}%</b
                  >
                </b-td>
              </b-tr>
              <b-tr class="odd">
                <b-td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  colspan="3"
                  align="right"
                >
                  Нийт:
                  <b class="dtr-control sorting_1" tabindex="0">{{ niit }}</b>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Сарын төлөвлөгөө бүртгэх
          </h3>
          <h3 class="card-title" v-show="editMode">Сарын төлөвлөгөө засах</h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <div class="card-body">
          <form>
            <div class="form-group row">
              <div class="form-group">
                <label>Харьяалагдах мэргэжлийн баг</label>
                <b-form-select
                  v-on:change="changeOwnerTeacherList()"
                  v-model="filterLeaderForm.department_id"
                  :options="departments"
                  text-field="department_name"
                  value-field="id"
                ></b-form-select>
                <has-error
                  :form="filterLeaderForm"
                  field="department_name"
                ></has-error>
              </div>
            </div>
            <div class="form-group">
              <label>Хариуцах эзэн</label>
              <b-form-select
                v-model="form.leader_id"
                :options="leaderTeachers"
                text-field="fullName"
                value-field="emp_id"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('leader_id'),
                  },
                ]"
              ></b-form-select>
              <has-error :form="form" field="leader_id"></has-error>
            </div>
            <div class="form-group">
              <div class="form-group">
                <label>Харьяалагдах мэргэжлийн баг</label>
                <b-form-select
                  v-on:change="changeTeacherList()"
                  v-show="!editMode"
                  v-model="filterForm.department_id"
                  :options="departments"
                  text-field="department_name"
                  value-field="id"
                ></b-form-select>
                <has-error
                  :form="filterForm"
                  field="department_name"
                ></has-error>
              </div>
            </div>
            <div class="form-group" v-show="!editMode">
              <label>Багш, ажилтан</label>
              <b-form-select
                v-if="teachers.length != 0"
                v-model="form.recieved_employees"
                :options="teachers"
                text-field="fullName"
                value-field="emp_id"
                multiple
                :select-size="4"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('recieved_employees'),
                  },
                ]"
              ></b-form-select>
              <has-error :form="form" field="recieved_employees"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="name"
                max="100"
                v-model="form.name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-group">
              <label>Агуулга</label>
              <quill-editor
                v-model="form.description"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
              <!-- <b-form-textarea
                                id="textarea"
                                v-model="form.description"
                                placeholder="Агуулга..."
                                rows="3"
                                max-rows="6"
                                :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has(
                                            'description'
                                        )
                                    }
                                ]"
                            ></b-form-textarea>
                            <has-error
                                :form="form"
                                field="description"
                            ></has-error> -->
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Эхлэх огноо</label>
                <b-form-datepicker
                  id="start-datepicker"
                  :state="true"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="form.start_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('start_date'),
                    },
                  ]"
                  placeholder="Эхлэх огноо"
                ></b-form-datepicker>
                <has-error :form="form" field="start_date"></has-error>
              </div>
              <div class="form-group col-lg-6">
                <label>Дуусах огноо</label>
                <b-form-datepicker
                  id="end-datepicker"
                  :state="false"
                  size="sm"
                  today-button
                  label-today-button="Өнөөдөр"
                  reset-button
                  label-reset-button="Шинэчлэх"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="form.end_date"
                  class="mb-2"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': form.errors.has('end_date'),
                    },
                  ]"
                  placeholder="Дуусах огноо"
                ></b-form-datepicker>
                <has-error :form="form" field="end_date"></has-error>
              </div>
            </div>
            <div class="form-group">
              <label>Анализ</label>
              <quill-editor
                v-model="form.analysis"
                :options="editorOptionAnalysis"
              >
              </quill-editor>
              <!-- <b-form-textarea
                                id="textarea"
                                v-model="form.analysis"
                                placeholder="Анализ..."
                                rows="3"
                                max-rows="6"
                                :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has(
                                            'analysis'
                                        )
                                    }
                                ]"
                            ></b-form-textarea>
                            <has-error
                                :form="form"
                                field="analysis"
                            ></has-error> -->
            </div>
            <div class="form-group" style="height: 250px">
              <label>Хяналтын үзүүлэлт</label>
              <quill-editor
                v-model="form.indicator"
                :options="editorOptionIndicator"
                style="height: 150px"
              >
              </quill-editor>

              <!-- <b-form-textarea
                                id="textarea"
                                v-model="form.indicator"
                                placeholder="Хяналтын үзүүлэлт..."
                                rows="3"
                                max-rows="6"
                                :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has(
                                            'indicator'
                                        )
                                    }
                                ]"
                            ></b-form-textarea>
                            <has-error
                                :form="form"
                                field="indicator"
                            ></has-error> -->
            </div>
            <div class="form-group" v-show="editMode">
              <label>Үнэлгээ</label>
              <input
                type="number"
                name="point"
                max="100"
                v-model="form.point"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('point'),
                  },
                ]"
                placeholder="Үнэлгээ %-аар тавих"
              />
              <has-error :form="form" field="point"></has-error>
            </div>
            <div class="form-group" v-show="editMode">
              <label>Үнэлгээний тайлбар</label>
              <quill-editor
                v-model="form.point_explain"
                :options="editorOptionStudentPlus"
              >
              </quill-editor>
              <!-- <b-form-textarea
                                id="textarea"
                                v-model="form.point_explain"
                                placeholder="Менежер үнэлгээнд өгсөн тайлбар"
                                rows="3"
                                max-rows="6"
                                :class="[
                                    'form-control',
                                    {
                                        'is-invalid': form.errors.has(
                                            'point_explain'
                                        )
                                    }
                                ]"
                            ></b-form-textarea>
                            <has-error
                                :form="form"
                                field="point_explain"
                            ></has-error> -->
            </div>
          </form>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            v-show="!editMode"
            @click.prevent="createMonthPlan()"
          >
            Хадгалах
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-show="editMode"
            @click.prevent="updateMonthPlan()"
          >
            Засах
          </button>
          <button
            class="btn btn-secondary"
            v-show="editMode"
            @click.prevent="cancelEdit"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-show-month-plan"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="showMonthPlanInfo.name"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span
              class="direct-chat-name float-left"
              v-if="showMonthPlanInfo.owner"
            >
              {{ showMonthPlanInfo.owner.teacher_last_name }}
              {{ showMonthPlanInfo.owner.teacher_name | uppercase }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ showMonthPlanInfo.start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ showMonthPlanInfo.end_date | dateYearMonthDay }}
            </span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            :src="
              showMonthPlanInfo.owner.profile_photo_url
                ? showMonthPlanInfo.owner.profile_photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <div class="row" style="overflow-x: auto">
            <div class="direct-chat-text col-lg-11">
              <b>АГУУЛГА:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.description"
                :options="editorOptionView"
              >
              </quill-editor>
              <b>АНАЛИЗ:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.analysis"
                :options="editorOptionView"
              >
              </quill-editor>
              <b>ХЯНАЛТЫН ҮЗҮҮЛЭЛТ:</b><br />
              <quill-editor
                v-model="showMonthPlanInfo.indicator"
                :options="editorOptionView"
              >
              </quill-editor>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div
              class="callout callout-success"
              v-if="showMonthPlanInfo.point > 0"
            >
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>
                  <span v-html="showMonthPlanInfo.point_explain"> </span>
                </small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сарын төлөвлөгөө авсан багш нар</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ showMonthPlanInfo.point }}%
                </span>
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="monthPlanEmps != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Ажлын байр</th>
                      <th>Зураг</th>
                      <th>Багш</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(emp, index) in monthPlanEmps"
                    :key="emp.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.department.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ emp.position.position_name }}
                      </td>
                      <td>
                        <img
                          :src="
                            emp.employee && emp.employee.profile_photo_url
                              ? emp.employee.profile_photo_url
                              : '/images/users/user.png'
                          "
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                        v-if="emp.employee"
                      >
                        <b-badge variant="light"
                          >{{
                            emp.employee.teacher_last_name
                              | capitalizeAndEkhniiVseg
                          }}.
                          {{ emp.employee.teacher_name | uppercase }}</b-badge
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(emp)"
                          v-if="
                            showMonthPlanInfo.owner_id === myId ||
                            showMonthPlanInfo.leader_id === myId
                          "
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div
            class="col-lg-4 mt-2"
            v-if="
              userInfo.name === 'cipalprin' ||
              (showMonthPlanInfo &&
                (showMonthPlanInfo.owner_id === myId ||
                  showMonthPlanInfo.leader_id === myId))
            "
          >
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="fullName"
                      value-field="emp_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="emp_teacher_name"
                    ></has-error>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacherMonthPlan()"
                >
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelMonthPlanInfo()" variant="danger" type="reset">
          Хаах
        </b-button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
    <b-modal
      size="lg"
      id="modal-show-employee"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      hide-header
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 mt-2">
            <img
              style="width: 100%"
              v-if="showEmployee.employee.profile_photo_url != null"
              :src="showEmployee.employee.profile_photo_url"
            />
          </div>
          <div class="col-lg-4 mt-2">
            <dl class="text-left">
              <dt>Иргэншил:</dt>
              <dd>{{ showEmployee.employee.citizenship }}</dd>
              <dt>Мэргэжлийн баг:</dt>
              <dd>
                {{ showEmployee.department.department_name }}
              </dd>
              <dt>Ажлын байр:</dt>
              <dd>{{ showEmployee.position.position_name }}</dd>
              <dt>Овог Нэр:</dt>
              <dd v-if="showEmployee.employee">
                {{ showEmployee.employee.teacher_last_name }}
                <span class="text-uppercase">
                  {{ showEmployee.employee.teacher_name }}
                </span>
              </dd>
              <dt>Системд бүртгэгдсэн огноо:</dt>
              <dd>
                {{ showEmployee.employee.created_at | dateYearMonthDay }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>

    <!-- Үүрэг даалгавар -->
    <b-modal
      size="xl"
      id="modal-show-task"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalTitleTask"
      class="modal fade"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-8 mt-3">
          <div class="card card-primary card-outline">
            <div class="card-header p-2">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" href="#inactive" data-toggle="tab"
                    >Хариу өгөөгүй</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#activity" data-toggle="tab"
                    >Хариу өгсөн</a
                  >
                </li>
              </ul>
            </div>
            <div class="card">
              <div class="card-header">
                <!-- <search-student
            ></search-student> -->
              </div>
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <b-container class="bv-example-row" fluid>
                    <div class="row">
                      <div class="col-md-5">
                        <multiselect
                          v-model="selectedMonthsForTask"
                          :options="myTask"
                          :multiple="true"
                          track-by="id"
                          label="task"
                          :searchable="true"
                          :close-on-select="false"
                          :show-labels="false"
                          placeholder="Үүрэг даалгавар холбох"
                          :allow-empty="true"
                          deselect-label="Can't remove this value"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.task }}</strong></template
                          >
                          // option.task.replace(/<\ / ? [^>] + (>| $) / g, "")
                        </multiselect>
                      </div>
                      <div class="col-md-2">
                        <b-button
                          variant="success"
                          size="sm"
                          class="ml-1"
                          @click.prevent="addTask()"
                          >Холбох</b-button
                        >
                      </div>
                      <div class="col-md-3">
                        <multiselect
                          v-model="selectedMonthTask"
                          :options="monthsRe"
                          :multiple="true"
                          track-by="name"
                          label="month"
                          :searchable="false"
                          :close-on-select="false"
                          :show-labels="false"
                          placeholder="Сар сонгох"
                          :allow-empty="true"
                          deselect-label="Can't remove this value"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong> {{ option.month }}</strong
                            ><strong> сонгогдлоо</strong></template
                          >
                        </multiselect>
                      </div>
                      <div class="col-md-2">
                        <b-button
                          variant="success"
                          size="sm"
                          class="ml-1"
                          @click.prevent="getMonthPlanTask()"
                          >Шүүх</b-button
                        >
                      </div>
                    </div>
                  </b-container>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="tab-content">
                  <div class="tab-pane active" id="inactive">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үйлдлүүд</th>
                          <th>Гүйцэтгэл</th>
                          <th>Өгсөн үүрэг</th>
                          <th>Огноо</th>
                          <th>Тайлбар</th>
                          <th>Зассан</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(task, index) in monthPlanTask"
                        :key="task.task_teachers_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-primary"
                              v-show="task.enrol_employee_id == myEmpId"
                              @click="editTask(task)"
                              ><i class="fa fa-check"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-success"
                              @click="showTask(task)"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <b-badge
                              v-b-tooltip.hover.top.html="
                                '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                                task.replyTeachers.join('<br>') +
                                ' <span class=text-uppercase>' +
                                '</span></dd></dl>'
                              "
                              pill
                              class="text-center"
                            >
                              {{ task.allTeacherCount }}/{{ task.replyCount }}
                            </b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            style="text-align: center"
                            tabindex="0"
                          >
                            <b-badge
                              v-if="task.guitsetgel >= 80"
                              variant="success"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 60) & (task.guitsetgel < 80)
                              "
                              variant="primary"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 30) & (task.guitsetgel < 60)
                              "
                              variant="warning"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="task.guitsetgel < 30"
                              variant="danger"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              variant="warning"
                              v-show="task.guitsetgel === null"
                            >
                              ОРООГҮЙ</b-badge
                            >
                            <div
                              class="progress progress-xs progress-striped active"
                            >
                              <div
                                v-if="task.guitsetgel < 30"
                                class="progress-bar bg-danger"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 30) &
                                  (task.guitsetgel < 60)
                                "
                                class="progress-bar bg-warning"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 60) &
                                  (task.guitsetgel < 80)
                                "
                                class="progress-bar bg-primary"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="task.guitsetgel >= 80"
                                class="progress-bar bg-success"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                            </div>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="light"
                              style="max-width: 200px; overflow: hidden"
                            >
                              <p class="text-sm">
                                <a
                                  href="#"
                                  class="dropdown-item"
                                  @click="showTaskView(task)"
                                >
                                  <span
                                    class="truncated-text"
                                    style="white-space: pre-wrap; width: 16rem"
                                    v-html="task.task.substring(0, 200)"
                                  >
                                  </span>
                                </a>
                              </p>
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.created_at | dateYearMonthDay }}
                            <br />
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            <br />
                            {{ task.performance_date | dateYearMonthDay }}
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap; max-width: 150px"
                              >{{ task.manager_explain }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.updated_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцсон/
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong
                              >{{ monthPlanTasks.avg_guitsetgel_zero }}%</strong
                            >
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцохгүй/
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong
                              >{{ monthPlanTasks.avg_guitsetgel }}%</strong
                            >
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Сарын төлөвлөгөөтэй холбосон үүрэг даалгаварын тоо:
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong>{{ monthPlanTasks.count }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="tab-pane" id="activity">
                    <table class="table table-hover text-nowrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үйлдлүүд</th>
                          <th>Гүйцэтгэл</th>
                          <th>Өгсөн үүрэг</th>
                          <th>Огноо</th>
                          <th>Тайлбар</th>
                          <th>Зассан</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(task, index) in monthPlanTaskReplied"
                        :key="task.task_teachers_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <a
                              href="#"
                              class="badge bg-primary"
                              v-show="task.enrol_employee_id == myEmpId"
                              @click="editTask(task)"
                              ><i class="fa fa-check"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-success"
                              @click="showTask(task)"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <b-badge
                              v-b-tooltip.hover.top.html="
                                '<dd><dt>Хариу өгсөн багш</dt><dd>' +
                                task.replyTeachers.join('<br>') +
                                ' <span class=text-uppercase>' +
                                '</span></dd></dl>'
                              "
                              pill
                              class="text-center"
                            >
                              {{ task.allTeacherCount }}/{{ task.replyCount }}
                            </b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            style="text-align: center"
                            tabindex="0"
                          >
                            <b-badge
                              v-if="task.guitsetgel >= 80"
                              variant="success"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 60) & (task.guitsetgel < 80)
                              "
                              variant="primary"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="
                                (task.guitsetgel >= 30) & (task.guitsetgel < 60)
                              "
                              variant="warning"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              v-if="task.guitsetgel < 30"
                              variant="danger"
                              v-show="task.guitsetgel != null"
                            >
                              {{ task.guitsetgel }}</b-badge
                            >
                            <b-badge
                              variant="warning"
                              v-show="task.guitsetgel === null"
                            >
                              ОРООГҮЙ</b-badge
                            >
                            <div
                              class="progress progress-xs progress-striped active"
                            >
                              <div
                                v-if="task.guitsetgel < 30"
                                class="progress-bar bg-danger"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 30) &
                                  (task.guitsetgel < 60)
                                "
                                class="progress-bar bg-warning"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="
                                  (task.guitsetgel >= 60) &
                                  (task.guitsetgel < 80)
                                "
                                class="progress-bar bg-primary"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                              <div
                                v-if="task.guitsetgel >= 80"
                                class="progress-bar bg-success"
                                :style="{
                                  width: `${task.guitsetgel}%`,
                                }"
                              ></div>
                            </div>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="max-width: 200px; overflow: hidden"
                            >
                              <p class="text-sm">
                                <a
                                  href="#"
                                  class="dropdown-item"
                                  @click="showTaskView(task)"
                                >
                                  <span
                                    class="truncated-text"
                                    style="white-space: pre-wrap; width: 16rem"
                                    v-html="task.task.substring(0, 200)"
                                  >
                                  </span>
                                </a>
                              </p>
                            </b-badge>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.created_at | dateYearMonthDay }}
                            <br />
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            <br />
                            {{ task.performance_date | dateYearMonthDay }}
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            style="white-space: pre-wrap"
                          >
                            <!-- <b-badge
                                                    variant="light"
                                                    style="white-space: pre-wrap"
                                                    >{{
                                                        task.manager_explain
                                                    }}</b-badge
                                                > -->

                            <a
                              href="#"
                              class="dropdown-item"
                              @click="showTaskView(task)"
                            >
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap; max-width: 150px"
                              >
                                <span
                                  class="truncated-text"
                                  style="white-space: pre-wrap; width: 16rem"
                                  v-html="
                                    task.manager_explain
                                      ? task.manager_explain.substring(0, 100)
                                      : ''
                                  "
                                >
                                </span>
                              </b-badge>
                            </a>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ task.updated_at | dateYearMonthDay }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцсон/
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong
                              >{{ monthPlanTasks.avg_guitsetgel_zero }}%</strong
                            >
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Дундаж /0 тооцохгүй/
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong
                              >{{ monthPlanTasks.avg_guitsetgel }}%</strong
                            >
                          </td>
                        </tr>
                        <tr class="odd">
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            colspan="3"
                            align="right"
                          >
                            Сарын төлөвлөгөөтэй холбосон үүрэг даалгаварын тоо:
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="monthPlanTasks"
                          >
                            <strong>{{ monthPlanTasks.count }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title" v-show="!editTaskMode">
                Үүрэг даалгавар бүртгэх
              </h3>
              <h3 class="card-title" v-show="editTaskMode">
                Үүрэг даалгавар засах
              </h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <div class="card-body">
              <div class="form-group">
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="formTask.is_enrol"
                  name="checkbox-2"
                  value="1"
                  unchecked-value="0"
                  @change="
                    filterFormFoTaskEnrolEmp.department_id = 0;
                    formTask.enrol_employee_id = 0;
                  "
                >
                  Хариуцах эзэн сонгох эсэх
                </b-form-checkbox>
              </div>
              <div
                v-show="!editTaskMode && formTask.is_enrol == 1"
                class="form-row"
              >
                <div class="form-group">
                  <label>Харьяалагдах мэргэжлийн баг</label>
                  <b-form-select
                    v-model="filterFormFoTaskEnrolEmp.department_id"
                    :options="departments"
                    text-field="department_name"
                    value-field="id"
                    @change="changeEnrolForTask($event)"
                  ></b-form-select>
                  <has-error
                    :form="filterFormFoTaskEnrolEmp"
                    field="department_name"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label>Хариуцах эзэн</label>
                  <span>{{ Object.keys(teachersEnrolTask).length }}</span>
                  <b-form-select
                    v-model="formTask.enrol_employee_id"
                    :options="teachersEnrolTask"
                    text-field="fullName"
                    value-field="emp_id"
                  ></b-form-select>
                  <has-error
                    :form="formTask"
                    field="emp_teacher_name"
                  ></has-error>
                </div>
              </div>
              <form
                v-on:change="changeTeacherListForTask($event)"
                v-show="!editTaskMode"
              >
                <div class="form-group row">
                  <div class="form-group">
                    <label>Харьяалагдах мэргэжлийн баг</label>
                    <b-form-select
                      v-model="filterFormFoTask.department_id"
                      :options="departments"
                      text-field="department_name"
                      value-field="id"
                    ></b-form-select>
                    <has-error
                      :form="filterFormFoTask"
                      field="department_name"
                    ></has-error>
                  </div>
                </div>
              </form>
              <form>
                <div class="form-group" v-show="!editTaskMode">
                  <label>Багш, ажилтан</label>
                  <span>{{ Object.keys(teachersForTask).length }}</span>
                  <b-form-select
                    v-model="formTask.recieved_employees"
                    :options="teachersForTask"
                    text-field="fullName"
                    value-field="emp_id"
                    multiple
                    :select-size="4"
                  ></b-form-select>
                  <has-error
                    :form="formTask"
                    field="emp_teacher_name"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label>Гүйцэтгэл тайлагнах хугацаа</label>
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="formTask.performance_date"
                    today-button
                    label-today-button="Өнөөдөр"
                    reset-button
                    label-reset-button="Шинэчлэх"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    class="mb-2"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': formTask.errors.has('performance_date'),
                      },
                    ]"
                    placeholder="Гүйцэтгэл тайлагнах хугацаа"
                  ></b-form-datepicker>
                  <has-error
                    :form="formTask"
                    field="performance_date"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label for="evaluation">Өгсөн үүрэг, даалгавар</label>
                  <validation-provider
                    name="form.notification"
                    rules="alpha_num"
                  >
                    <template>
                      <quill-editor
                        ref="myQuillEditor"
                        v-model="formTask.task"
                        :options="editorOption"
                      />
                    </template>
                  </validation-provider>
                </div>
                <div class="form-group" v-show="editTaskMode">
                  <label>Менежер тайлбар</label>
                  <template>
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="formTask.manager_explain"
                      :options="editorOption"
                    />
                  </template>
                </div>
                <div class="form-group" v-show="editTaskMode">
                  <b-form-checkbox
                    class="float-right success"
                    :name="isConfirmType"
                    aria-describedby="active"
                    switch
                    size="xs"
                    @change="changeStatus"
                    ><span
                      v-if="isConfirmType == 'true'"
                      class="badge bg-primary"
                      >Нийт гүйцэтгэл оруулах
                    </span>
                    <span v-else class="badge bg-secondary"
                      >Дутуу гүйцэтгэл оруулах
                    </span>
                  </b-form-checkbox>
                  <input
                    type="number"
                    name="guitsetgel"
                    max="100"
                    v-model="formTask.guitsetgel"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('guitsetgel'),
                      },
                    ]"
                    placeholder="Гүйтэгэлийн оноо %-аар тавих"
                  />
                  <has-error :form="formTask" field="guitsetgel"></has-error>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editTaskMode"
                @click.prevent="createTask()"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editTaskMode"
                @click.prevent="updateTask()"
              >
                Засах
              </button>
              <button
                class="btn btn-secondary"
                v-show="editTaskMode"
                @click.prevent="cancelEditTask"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
        <b-modal
          size="xl"
          id="modal-view-task"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Үүрэг даалгавар"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-timestamp float-right"
                  ><b>Огноо: </b>
                  {{ showTaskData.created_at | dateYearMonthDay }}</span
                >
              </div>
              <div
                class="direct-chat-text primary ml-0"
                style="overflow-x: auto"
              >
                <span v-html="showTaskData.task"> </span>
                <span
                  v-html="
                    showTaskData.teacher_explain
                      ? showTaskData.teacher_explain
                      : ''
                  "
                >
                </span>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          v-show="showMode"
          size="xl"
          id="modal-taskInfo"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          title="Үүрэг даалгавар"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left"
                  >{{ showTaskInfo.enrol_lname }}
                  {{ showTaskInfo.enrol_fname | uppercase }}</span
                >
                <span class="direct-chat-timestamp float-right">{{
                  showTaskInfo.created_at | dateYearMonthDay
                }}</span>
              </div>
              <!-- /.direct-chat-infos -->
              <img
                v-if="showTaskInfo.profile_photo_url != null"
                :src="showTaskInfo.profile_photo_url"
                alt="user-avatar"
                class="direct-chat-img"
              />
              <img
                v-else
                src="/images/users/user.png"
                alt="user-avatar"
                class="direct-chat-img"
              />
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text primary">
                <!-- {{ showTaskInfo.task }} -->
                <span v-html="showTaskInfo.task"> </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 mt-2">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Үүрэг даалгавар авсан багш нар</h3>
                  </div>
                  <!-- /.card-header -->
                  <div
                    class="card-body table-responsive"
                    v-if="taskInfo != null"
                  >
                    <table class="table text-wrap">
                      <thead>
                        <tr role="row">
                          <th>#</th>
                          <th>Үнэлгээ</th>
                          <th>Мэргэжлийн баг</th>
                          <th>Ажлын байр</th>
                          <th>Багш</th>
                          <th>Тайлбар</th>
                          <th>Үйлдэл</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(taskD, index) in taskInfo"
                        :key="taskD.show_task_id"
                      >
                        <tr>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ index + 1 }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.guitsetgel }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            {{ taskD.department_name }}
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <span v-if="taskD.user">
                              <span v-if="taskD.user.employee_migration_info">
                                <span
                                  v-if="
                                    taskD.user.employee_migration_info
                                      .my_position_info
                                  "
                                >
                                  <small>
                                    {{
                                      taskD.user.employee_migration_info
                                        .my_position_info.position_name
                                    }}
                                  </small>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge variant="light"
                              >{{ taskD.emp_teacher_last_name[0] }}.
                              {{ taskD.emp_teacher_name }}</b-badge
                            >
                          </td>
                          <td class="dtr-control sorting_1" tabindex="0">
                            <b-badge
                              variant="light"
                              style="white-space: pre-wrap"
                            >
                              <!-- {{
                                                        taskD.teacher_explain
                                                    }} -->
                              <!-- <span
                                                        v-html="
                                                            taskD.teacher_explain
                                                        "
                                                    >
                                                    </span> -->

                              <a
                                href="#"
                                class="dropdown-item"
                                @click="showTaskView(taskD)"
                              >
                                <b-badge variant="light">
                                  <span
                                    v-html="
                                      taskD.teacher_explain
                                        ? taskD.teacher_explain.substring(
                                            0,
                                            100
                                          )
                                        : ''
                                    "
                                  >
                                  </span>
                                </b-badge>
                              </a>
                            </b-badge>
                          </td>
                          <td
                            class="dtr-control sorting_1"
                            tabindex="0"
                            v-if="showTaskInfo.enrol_employee_id == myEmpId"
                          >
                            <a
                              href="#"
                              class="badge bg-primary"
                              @click="editTeacher(taskD)"
                              ><i class="fa fa-edit"></i
                            ></a>
                            <a
                              href="#"
                              class="badge bg-danger"
                              @click="removeTeacherFromTask(taskD)"
                              ><i class="fa fa-minus"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <div
                class="col-lg-4 mt-2"
                v-if="showTaskInfo.enrol_employee_id == myEmpId"
              >
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title" v-show="!editTeacherMode">
                      Багш нэмэх
                    </h3>
                    <h3 class="card-title" v-show="editTeacherMode">
                      Багшид үнэлгээ өгөх
                    </h3>
                  </div>
                  <!-- /.card-header -->
                  <!-- form start -->
                  <div class="card-body">
                    <form
                      v-on:change="modalChangeTeacherList($event)"
                      v-show="!editTeacherMode"
                    >
                      <div class="form-group row">
                        <div class="form-group">
                          <label>Харьяалагдах мэргэжлийн баг</label>
                          <b-form-select
                            v-model="formAddTeacherTask.department_id"
                            :options="departments"
                            text-field="department_name"
                            value-field="id"
                          ></b-form-select>
                          <has-error
                            :form="formAddTeacherTask"
                            field="department_name"
                          ></has-error>
                        </div>
                      </div>
                    </form>
                    <form>
                      <div class="form-group" v-show="!editTeacherMode">
                        <label>Багш, ажилтан</label>
                        <b-form-select
                          v-model="formAddTeacherTask.recieved_employees"
                          :options="modalTeachers"
                          text-field="fullName"
                          value-field="emp_id"
                          multiple
                          :select-size="4"
                        ></b-form-select>
                        <has-error
                          :form="formAddTeacherTask"
                          field="emp_teacher_name"
                        ></has-error>
                      </div>
                      <div class="form-group" v-show="editTeacherMode">
                        <label>Гүйцэтгэл</label>
                        <input
                          type="number"
                          name="guitsetgel"
                          max="100"
                          v-model="formAddTeacherTask.guitsetgel"
                          :class="[
                            'form-control',
                            {
                              'is-invalid': form.errors.has('guitsetgel'),
                            },
                          ]"
                          placeholder="Гүйтэгэлийн оноо %-аар тавих"
                        />
                        <has-error
                          :form="formAddTeacherTask"
                          field="guitsetgel"
                        ></has-error>
                      </div>
                    </form>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="addTeacherTask()"
                      v-show="!editTeacherMode"
                    >
                      Нэмэх
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="updateTeacherTask()"
                      v-show="editTeacherMode"
                    >
                      Хадгалах
                    </button>
                    <button
                      type="submit"
                      class="btn btn-dark"
                      @click.prevent="cancelEditTeacher()"
                      v-show="editTeacherMode"
                    >
                      Болих
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <b-button @click="cancelTaskInfo()" variant="danger" type="reset">
              Хаах
            </b-button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
          <!-- /.modal-dialog -->
        </b-modal>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      yearId: null,
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",
      taskUnelgeeList: [],
      taskUnelgeeSum: "",
      taskUnelgeeAvg: "",
      taskUnelgee0List: [],
      taskUnelgee0Sum: "",
      taskUnelgee0Avg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",
      myEmpId: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allMonthPlans: [],
      monthPlanTasks: [],
      monthPlanEmps: [],
      departments: [{ value: 0, department_name: "Please select an option" }],
      leaderTeachers: [],
      myDepId: "",
      myId: "",
      ownId: "",
      leadId: "",
      teachers: [],
      topSelectTeachers: [],
      showMode: false,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: [
        {
          name: new Date().getMonth() + 1,
          month: new Date().getMonth() + 1 + "-р сар",
        },
      ],
      selectedMonthTask: [
        {
          name: new Date().getMonth() + 1,
          month: new Date().getMonth() + 1 + "-р сар",
        },
      ],
      filterForm: new Form({
        department_id: 0,
      }),
      filterLeaderForm: new Form({
        department_id: 0,
      }),
      showMonthPlanInfo: new Form({
        monthPlanId: "",
        owner_id: "",
        leader_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
        owner: [],
      }),
      editMode: false,
      form: new Form({
        monthPlanId: "",
        owner_id: "",
        leader_id: "",
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        analysis: "",
        indicator: "",
        point: "",
        point_explain: "",
        recieved_employees: [],
      }),
      modalTeachers: [],
      formAddTeacher: new Form({
        monthPlanId: null,
        department_id: 0,
        recieved_employees: [],
      }),
      showEmployee: new Form({
        department: "",
        employee: "",
        position: "",
      }),
      // quill тохиргоо
      editorOptionStudentPlus: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: "Агуулгад тайлбар өгөх...",
      },
      editorOptionIndicator: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: `
    1.Цаг хугацаандаа гүйцэтгэсэн байх
    2.Хяналтын үзүүлэлтүүдийг тодорхойлсон байх
    3.Хяналтын үзүүлэлтийн дагуу анализ хийж, анализ хэсэгт тэмдэглэсэн байх
      3.1. Гүйцэтгэл дутуу болон сайн байгаа шалтгаан
      3.2. Цаашид анхаарах зүйлс
  `,
      },
      editorOptionAnalysis: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder: "Хяналтын үзүүлэлтийн дагуу анализ хийсэн байх",
      },
      // зөвхөн харах
      editorOptionView: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },

      // Үүрэг даалгавар
      monthPlanIdTask: "",
      teachersForTask: [],
      teachersEnrolTask: [],
      showTaskData: "",
      taskInfo: [],
      isConfirmType: "false",
      showTaskInfo: new Form({
        task_id: "",
        department_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employee_id: "",
        position_id: "",
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),
      editTaskMode: false,
      monthPlanTask: [],
      monthPlanTaskReplied: [],
      myTask: [],
      formModalTitleTask: "Сарын ажлын төлөвлөгөөнд үүрэг даалгавар нэмэх",
      selectedMonthsForTask: [],

      niitRep: "",
      editTeacherMode: false,
      formAddTeacherTask: new Form({
        show_task_id: null,
        task_id: null,
        department_id: 0,
        recieved_employees: [],
        guitsetgel: "",
      }),
      filterFormFoTask: new Form({
        department_id: 0,
      }),
      filterFormFoTaskEnrolEmp: new Form({
        department_id: 0,
      }),
      formTask: new Form({
        is_enrol: 0,
        isConfirmType: "",
        monthPlanId: "",
        task_id: "",
        enrol_employee_id: "",
        enrol_fname: "",
        enrol_lname: "",
        recieved_employees: [],
        performance_date: "",
        task: "",
        manager_explain: "",
        guitsetgel: "",
        profile_photo_url: "",
        created_at: "",
      }),

      // quill тохиргоо
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "image", "video", "formula"],
            ["clean"],
          ],
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 600, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
        },
      },
      totalRowsCompetitions: 1,
      currentPageCompetitions: 1,
      perPageCompetitions: 25,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCompetitions: "",
      filterOnCompetitions: [],
      filterIgnoredFields: ["month_plan_teachers", "owner", "tasks"],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: true,
        },
        {
          key: "point",
          label: "Гүйцэтгэл",
          sortable: true,
        },
        {
          key: "name",
          label: "Нэр",
          sortable: true,
        },
        {
          key: "owner",
          label: "Хариуцах эзэн",
          sortable: true,
        },
        {
          key: "employee",
          label: "Хамтран ажиллах",
          sortable: true,
        },
        {
          key: "date",
          label: "Огноо",
          sortable: true,
        },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    activeYear() {
      this.yearId = this.$store.state.activeYear;
      return this.$store.state.activeYear;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  methods: {
    onFilteredCompetitions(filteredItems) {
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCompetitions = filteredItems.length;
      this.currentPageCompetitions = 1;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    customOwnerName({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    changeStatus() {
      if (this.isConfirmType == "false") {
        this.isConfirmType = "true";
        this.dNone = "";
      } else {
        this.isConfirmType = "false";
        this.dNone = "display: none";
      }
    },
    averageCheck(allMonthPlans) {
      this.niit = 0;
      this.unelgeeList = [];
      this.taskUnelgeeList = [];
      this.taskUnelgee0List = [];
      this.unelgeeSum = "";
      this.taskUnelgeeSum = "";
      this.taskUnelgee0Sum = "";
      this.taskUnelgeeAvg = "";
      this.taskUnelgee0Avg = "";

      this.unelgee0List = [];
      this.unelgee0Sum = "";
      this.unelgee0Avg = "";

      allMonthPlans.forEach((element) => {
        if (element.point == null) {
          this.unelgeeList.push(0);
        } else {
          this.unelgeeList.push(parseInt(element.point));
        }

        if (element.tasks.length > 0) {
          const avgGuitsetgel = element.tasks[0].avg_guitsetgel;
          const avgGuitsetgelZero = element.tasks[0].avg_guitsetgel_zero;

          if (avgGuitsetgel !== null) {
            this.taskUnelgeeList.push(parseInt(avgGuitsetgel));
          }

          if (avgGuitsetgelZero !== null) {
            this.taskUnelgee0List.push(parseInt(avgGuitsetgelZero));
          }
        }
      });

      this.unelgeeSum = this.unelgeeList.reduce((a, b) => a + b, 0);
      this.unelgeeAvg = (this.unelgeeSum / this.unelgeeList.length).toFixed();
      this.niit = this.unelgeeList.length;

      this.taskUnelgeeSum = this.taskUnelgeeList.reduce((a, b) => a + b, 0);
      this.taskUnelgeeAvg = (
        this.taskUnelgeeSum / this.taskUnelgeeList.length
      ).toFixed();

      this.taskUnelgee0Sum = this.taskUnelgee0List.reduce((a, b) => a + b, 0);
      this.taskUnelgee0Avg = (
        this.taskUnelgee0Sum / this.taskUnelgee0List.length
      ).toFixed();

      // Together zero
      this.unelgee0List = this.unelgeeList.filter((el) => el !== 0);
      this.unelgee0Sum = this.unelgee0List.reduce((a, b) => a + b, 0);
      this.unelgee0Avg = (
        this.unelgee0Sum / this.unelgee0List.length
      ).toFixed();
    },
    changeTeacherList() {
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get(
          "/month_plan_manager/getTeacherList/" + this.filterForm.department_id
        )
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    changeOwnerTeacherList() {
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get(
          "/month_plan_manager/getTeacherList/" +
            this.filterLeaderForm.department_id
        )
        .then((response) => {
          this.leaderTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get(
          "/month_plan_manager/getTeacherList/" +
            this.formAddTeacher.department_id
        )
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getMyInfo() {
      //   console.log("Ажиллаж байна. getMyInfo");
      axios
        .get("/all_worker_functions/getMyInfo")
        .then((res) => {
          this.myEmpId = res.data.getMyInfoTeacher.employee_id;
        })
        .catch();
    },
    getAllMonthPlans() {
      // console.log(this.years);
      // console.log(this.userInfo);
      this.show = true;
      let yearId = null;
      let ownerId = null;
      let leaderId = null;
      if (this.yearId) yearId = this.yearId.id;
      if (this.ownId) ownerId = this.ownId.emp_id;
      if (this.leadId) leaderId = this.leadId.emp_id;
      axios
        .post("/month_plan_manager/getAllMonthPlans", {
          selectedMonths: this.selectedMonths,
          year_id: yearId,
          ownerId: ownerId,
          leaderId: leaderId,
        })
        .then((response) => {
          this.filterForm.department_id = 0;
          this.allMonthPlans = response.data.allMonthPlans;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          // this.topSelectTeachers.forEach((el) => {
          //   console.log(el.emp_teacher_last_name);
          // });
          this.myDepId = response.data.dep_id;
          this.filterLeaderForm.department_id = this.myDepId;
          this.myId = response.data.leader_id;
          this.totalRowsCompetitions = this.allMonthPlans.length;
          this.changeOwnerTeacherList();
          this.form.leader_id = this.myId;
          this.averageCheck(this.allMonthPlans);
          this.show = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showMonthPlan(monthPlan) {
      // console.log(monthPlan);
      this.monthPlanEmps = monthPlan.month_plan_teachers;
      this.showMonthPlanInfo.reset();
      this.showMonthPlanInfo.fill(monthPlan);
      this.showMode = !this.showMode;
      this.modalTeachers = this.teachers;
      this.formAddTeacher.reset();
      this.$bvModal.show("modal-show-month-plan");
    },
    editMonthPlan(monthPlan) {
      this.editMode = true;
      this.form.reset();
      this.form.fill(monthPlan);
      this.form.recieved_employees = [];
      this.filterLeaderForm.department_id = monthPlan.department_id;
      this.changeOwnerTeacherList();
    },
    updateMonthPlan() {
      this.action = "Сарын төлөвлөгөөний мэдээллийг шинэчилж байна...";
      console.log(this.form);
      this.form
        .put("/month_plan_manager/updateMonthPlan/" + this.form.monthPlanId)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getMonthPlans");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createMonthPlan() {
      this.form
        .post("/month_plan_manager/createMonthPlan")
        .then((response) => {
          this.$toastr.s("Сарын төлөвлөгөө амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getMonthPlans");
          this.form.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeMonthPlan(monthPlan) {
      axios
        .get("/task_manager/removeMonthPlan/" + monthPlan.id)
        .then((response) => {
          Fire.$emit("getMonthPlans");
          this.$toastr.s("Сарын төлөвлөгөө амжилттай устгалаа!", "Шинэчилсэн");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteTask(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
      this.filterLeaderForm.department_id = this.myDepId;
      this.form.leader_id = this.myId;
      this.changeOwnerTeacherList();
    },
    cancelMonthPlanInfo() {
      this.showMode = !this.showMode;
      this.showMonthPlanInfo.reset();
      this.$bvModal.hide("modal-show-month-plan");
    },
    removeTeacher(schoolteacher) {
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Сарын төлөвлөгөөнөөс багш хасах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              "/month_plan_manager/removeTeacherMonthPlan/" + schoolteacher.id,
              {
                params: {
                  monthPlanId: schoolteacher.month_plan_id,
                },
              }
            )
            .then((response) => {
              this.monthPlanEmps = response.data.monthPlanEmps;
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getMonthPlans");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    addTeacherMonthPlan() {
      this.formAddTeacher.monthPlanId = this.showMonthPlanInfo.monthPlanId;
      this.formAddTeacher
        .post("/month_plan_manager/addTeacherMonthPlan")
        .then((response) => {
          this.monthPlanEmps = response.data.monthPlanEmps;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formAddTeacher.reset();
          Fire.$emit("getMonthPlans");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showImage(emp) {
      this.showEmployee.reset();
      this.showEmployee.fill(emp);
      this.$bvModal.show("modal-show-employee");
    },

    // Үүрэг даалгавар task
    showTaskForMonthPlan(monthPlan) {
      // console.log(monthPlan);
      this.monthPlanIdTask = monthPlan.id;
      this.monthPlanTasks = monthPlan.tasks[0];
      this.teachersForTask = this.teachers;
      this.teachersEnrolTask = this.teachers;
      this.formAddTeacherTask.reset();
      this.$bvModal.show("modal-show-task");
      this.getMonthPlanTask();
    },
    getMonthPlanTask() {
      axios
        .post(`/task_manager/monthPlanTask`, {
          year_id: this.yearId != null ? this.yearId.id : this.activeYear.id,
          selectedMonthTask: this.selectedMonthTask,
          monthPlanId: this.monthPlanIdTask,
        })
        .then((response) => {
          this.filterFormFoTask.department_id = 0;
          this.monthPlanTask = response.data.monthPlanTask;
          this.monthPlanTaskReplied = response.data.monthPlanTaskReplied;
          this.myTask = response.data.myTask;
          this.departments = response.data.departments;
          this.averageCheck(this.monthPlanTask);
          this.averageCheckReplied(this.monthPlanTaskReplied);
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    addTask() {
      axios
        .post(`/task_manager/addTask`, {
          selectedMonthsForTask: this.selectedMonthsForTask,
          monthPlanIdTask: this.monthPlanIdTask,
        })
        .then((response) => {
          this.$toastr.s("Үүрэг даалгавар амжилттай холбогдлоо");
          Fire.$emit("getTasks");
          this.selectedMonthsForTask = [];
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    changeTeacherListForTask: function (e) {
      this.filterFormFoTask.department_id = e.target.value;
      axios
        .get(
          "/task_manager/getTeacherList/" + this.filterFormFoTask.department_id
        )
        .then((response) => {
          this.teachersForTask = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    changeEnrolForTask() {
      axios
        .get(
          "/task_manager/getTeacherList/" +
            this.filterFormFoTaskEnrolEmp.department_id
        )
        .then((response) => {
          this.teachersEnrolTask = response.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createTask() {
      // console.log(this.monthPlanIdTask);
      // console.log(this.formTask);
      this.formTask.monthPlanId = this.monthPlanIdTask;
      this.formTask
        .post("/task_manager/createTask")
        .then((response) => {
          this.$toastr.s("Үүрэг даалгавар амжилттай нэмлээ", "Бүртгэлээ");
          Fire.$emit("getTasks");
          this.formTask.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    cancelEditTask() {
      this.formTask.reset();
      this.editTaskMode = false;
    },
    editTask(task) {
      // console.log(task);
      this.editTaskMode = true;
      this.formTask.reset();
      this.formTask.fill(task);
      this.formTask.recieved_employees = [];
      // console.log(this.formTask);
    },
    updateTask() {
      // console.log(this.form);
      this.formTask.monthPlanId = this.monthPlanIdTask;
      this.formTask.isConfirmType = this.isConfirmType;
      this.formTask
        .put("/task_manager/taskUpdate/" + this.formTask.task_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.formTask.reset();
          this.editTaskMode = false;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    showTask(task) {
      axios
        .get("/task_manager/showTask/" + task.task_id)
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.showTaskInfo.reset();
          this.showTaskInfo.fill(task);
          this.showMode = false;
          this.modalTeachers = this.teachersForTask;
          this.formAddTeacherTask.reset();
          this.$bvModal.show("modal-taskInfo");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelTaskInfo() {
      this.showMode = !this.showMode;
      this.showTaskInfo.reset();
      this.$bvModal.hide("modal-taskInfo");
    },
    editTeacher(task) {
      this.editTeacherMode = true;
      this.formAddTeacherTask.reset();
      this.formAddTeacherTask.fill(task);
      this.formAddTeacherTask.recieved_employees = [];
    },
    updateTeacherTask() {
      this.formAddTeacherTask
        .put(
          "/task_manager/updateTeacherTask/" +
            this.formAddTeacherTask.show_task_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Багшийг амжилттай үнэллээ!", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.formAddTeacherTask.reset();
          this.editTeacherMode = false;
          this.taskInfo = response.data.taskInfo;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelEditTeacher() {
      this.formAddTeacherTask.reset();
      this.editTeacherMode = false;
    },
    removeTeacherFromTask(schoolteacher) {
      this.form
        .get("/task_manager/removeTeacherTask/" + schoolteacher.show_task_id, {
          params: { task_id: schoolteacher.task_id },
        })
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addTeacherTask() {
      this.formAddTeacherTask.task_id = this.showTaskInfo.task_id;
      this.formAddTeacherTask
        .post("/task_manager/addTeacherTask")
        .then((response) => {
          this.taskInfo = response.data.taskInfo;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formAddTeacherTask.reset();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showTaskView(task) {
      this.showTaskData = task;
      this.$bvModal.show("modal-view-task");
    },
  },
  created() {
    axios
      .get(
        "/task_manager/getTeacherList/" + this.filterFormFoTask.department_id
      )
      .then((response) => {
        this.leaderTeachers = response.data.teachers;
        this.topSelectTeachers = response.data.teachers;
      })
      .catch((errors) => {
        //   console.log(errors);
      });
    this.getAllMonthPlans();
    this.getMyInfo();
    Fire.$on("getMonthPlans", () => {
      this.getAllMonthPlans();
    });
    Fire.$on("getTasks", () => {
      this.getMonthPlanTask();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}

.truncated-text {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupForm {
  background-color: #4da985;
  color: white;
}
.center-badge {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Ensure text inside the badge is centered */
}
</style>
