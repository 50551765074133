<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Шилжсэн шалтгааны төрөл</h3>
          <!-- <search-student
              @getWasSearchStudents="schoolentrants = $event"
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Шалтгаан</th>
                <th>Огноо</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody v-for="(leaveType, index) in leaveTypes" :key="leaveType.id">
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ leaveType.name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ leaveType.created_at | dateFullYear }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editLeaveType(leaveType)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteLeaveType(leaveType)"
                  >
                    <i class="fas fa-sign-out-alt" title="Шилжих"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editMode">
            Шилжсэн шалтгаан нэмэх
            <br />
            <h7>(Давхцуулахгүй байхад онцгой анхаарна уу)</h7>
          </h3>
          <h3 class="card-title" v-show="editMode">
            Шилжсэн шалтгаан засах<br />
            <h7>(Давхцуулахгүй байхад онцгой анхаарна уу)</h7>
          </h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form
          @submit.prevent="editMode ? updateLeaveType() : createLeaveType()"
        >
          <div class="card-body">
            <div class="form-group">
              <label>Шалтгаан</label>
              <input
                type="text"
                name="name"
                v-model="form.name"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('name'),
                  },
                ]"
                placeholder="Нэр"
              />
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-show="!editMode">
              Нэмэх
            </button>
            <button type="submit" class="btn btn-primary" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      leaveTypes: [],
      form: new Form({
        id: "",
        name: "",
      }),
    };
  },

  methods: {
    getAllLeaveTypes() {
      axios
        .get("/manager/getLeaveTypes")
        .then((response) => {
          this.leaveTypes = response.data.school_leave_types;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    createLeaveType() {
      this.action = "Шилжсэн шалтгааны төрөл үүсгэж байна...";
      this.form
        .post("/manager/createLeaveType")
        .then((response) => {
          this.$toastr.s(
            "Шилжсэн шалтгааны төрөл амжилттай үүсгэлээ.",
            "Амжилттай"
          );
          Fire.$emit("loadLeaveTypes");
          this.form.reset();
        })
        .catch(() => {
          this.$toastr.e(
            "Шилжсэн шалтгааны төрөл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    editLeaveType(leaveType) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + leaveType);
      this.editMode = true;
      this.form.reset();
      this.form.fill(leaveType);
    },
    updateLeaveType() {
      this.action = "Шилжсэн шалтгааныг шинэчилж байна...";
      //   console.log(this.form);
      this.form
        .put("/manager/updateLeaveType/" + this.form.id)
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadLeaveTypes");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteLeaveType(leaveType) {
      Swal.fire({
        title: "Шилжсэн шалтгаан устгах гэж байна?",
        text: "Та итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Устгах`,
        denyButtonText: `Болих`,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put("/manager/deleteLeaveType/" + leaveType.id)
            .then((response) => {
              //   console.log(response);
              Fire.$emit("loadLeaveTypes");
              Swal.fire("Амжилттай!", "", "success");
            })
            .catch((error) => {
              //   console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllLeaveTypes();
    Fire.$on("loadLeaveTypes", () => {
      this.getAllLeaveTypes();
    });
  },
};
</script>

<style></style>
