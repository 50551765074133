<template>
    <div class="row pt-3">
        <div class="col-md-1">
            <div class="card card-fuchsia" style="transition: all 0.15s ease 0s; height: inherit; width: inherit">
                <div class="card-header">
                    <h3 class="card-title">Анги</h3>

                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="maximize">
                            <i class="fas fa-expand"></i>
                        </button>
                    </div>
                    <!-- /.card-tools -->
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                    <p v-for="school_class in schoolclasses" :key="school_class.id" style="line-height: 60%">
                        <a href="#" @click.prevent="
                            schoolClass(school_class.id, school_class.full_name)
                            ">
                            {{ school_class.full_name }}
                        </a>
                    </p>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>
        <!-- Form Element sizes -->
        <div class="col-md-6">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title" v-show="!selectClass">
                        Хүмүүжлийн хичээл бүртгэх (Өмнөх баганаас анги заавал сонгох)
                    </h3>
                    <h3 class="card-title" v-show="selectClass">
                        Хүмүүжлийн хичээл бүртгэх - {{ chosesClass }}
                    </h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <b-row>
                        <b-col>
                            <b-table-simple hover small caption-top responsive>
                                <b-thead head-variant="light">
                                    <b-tr>
                                        <b-th>Багш</b-th>
                                        <b-th>Хичээл</b-th>
                                        <b-th>Улирал</b-th>
                                        <b-th>Хичээлийн төрөл</b-th>
                                        <b-th>Дүн (%)</b-th>
                                        <b-th>Үйлдлүүд</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(
                      schoolclassesscourse, index
                    ) in schoolclassesscourses" :key="index" v-if="schoolclassesscourses.length">
                                        <b-td>{{
                                            schoolclassesscourse.teacher_last_name[0].toUpperCase()
                                        }}.{{ schoolclassesscourse.name }}</b-td>
                                        <b-td>{{ schoolclassesscourse.subject_name }}</b-td>
                                        <b-td> {{ schoolclassesscourse.quarter_id }}</b-td>
                                        <b-td>{{ schoolclassesscourse.LessonType }}</b-td>
                                        <b-td>{{ schoolclassesscourse.percent }}</b-td>

                                        <b-td>
                                            <b-form-group label-size="sm" label-for="input-sm">
                                                <b-button-group size="sm">
                                                    <b-button variant="outline-primary" @click.prevent="
                                                        editSchoolCourse(schoolclassesscourse)
                                                        ">
                                                        <b-icon icon="tools"></b-icon>
                                                    </b-button>
                                                    <b-button variant="outline-primary" @click.prevent="
                                                        selectClassStudents(schoolclassesscourse)
                                                        ">
                                                        <i class="fas fa-user-graduate"></i>
                                                    </b-button>
                                                    <b-button variant="danger" @click.prevent="
                                                        deleteSchoolCourse(schoolclassesscourse)
                                                        "><i class="far fa-trash-alt"></i></b-button>
                                                </b-button-group>
                                            </b-form-group>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                                <b-tfoot>
                                    <b-tr>
                                        <b-td colspan="7" variant="secondary" class="text-right">
                                            Бүртгэлтэй хичээлүүд:
                                            <b v-if="schoolclassesscourses.length">{{
                                                schoolclassesscourses.length
                                            }}</b>
                                            <b v-if="!schoolclassesscourses.length">Одоогоор хичээл бүртгээгүй
                                                байна.</b>
                                        </b-td>
                                    </b-tr>
                                </b-tfoot>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label-size="sm" label="Хичээл" label-for="input-sm">
                                <multiselect v-model="form.subject_id" :options="schoolsubjects" track-by="id"
                                    label="subject_name" :show-labels="false" placeholder="Хичээл сонгох"></multiselect>
                            </b-form-group>
                        </b-col>
                        <b-col><b-form-group label-size="sm" label="Багш" label-for="input-sm">
                                <div>
                                    <multiselect v-model="form.teacher_id" :options="sortArrays(schoolteachers)"
                                        track-by="teacher_name" label="teacher_name" :custom-label="customLabelTeacher"
                                        :searchable="true" :close-on-select="false" :show-labels="false"
                                        placeholder="Багш сонгох"></multiselect>
                                </div>
                                <div class="mt-3">
                                    Selected:
                                    <strong>{{ form.teacher_id.teacher_id }}</strong>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label-size="sm" label-for="input-sm" label="Улирал сонгох:">
                                <b-form-checkbox-group size="sm" class="mt-1" id="checkbox-group-1"
                                    v-model="form.quarter_id" :options="schoolquarters" value-field="id"
                                    text-field="name"></b-form-checkbox-group>

                                <strong>{{ form.quarter_id }}</strong>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label-size="sm" label="Эзлэх жин" label-for="input-sm">
                                <b-form-select v-model="form.percent" :options="options1" size="sm" text-field="text"
                                    value-field="value" :class="[
                                        'form-control',
                                        {
                                            'is-invalid': form.errors.has('percent'),
                                        },
                                    ]"></b-form-select>
                                <div class="mt-3">
                                    Selected:
                                    <strong>{{ form.percent }}</strong>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label-size="sm" label="Ангилал" label-for="input-sm">
                                <b-form-select v-model="form.course_type_id" :options="schoolcoursetypes" size="sm"
                                    text-field="name" value-field="id" :class="[
                                        'form-control',
                                        {
                                            'is-invalid': form.errors.has('course_type_id'),
                                        },
                                    ]"></b-form-select>
                                <div class="mt-3">
                                    Selected:
                                    <strong>{{ form.course_type_id }}</strong>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Үйлдлүүд" label-size="sm" label-for="input-sm">
                                <b-button-group size="sm" class="mr-1">
                                    <b-button title="Save file" :variant="editMode ? 'warning' : 'success'"
                                        @click.prevent="
                                            editMode ? updateSchoolCourse() : createSchoolCourse()
                                            ">
                                        <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                                    </b-button>
                                    <b-button v-if="editMode" :variant="editMode ? 'danger' : 'success'">
                                        <b-icon @click="cancelEdit()" icon="backspace"></b-icon>
                                    </b-button>
                                </b-button-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <!-- Хичээлд суралцагч бүртгэх -->
        <div class="col-md-5">
            <!-- general form elements -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Хичээлд суралцагч бүртгэх</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <b-card-group deck>
                                <b-card header="Хичээлд бүртгэгдсэн">
                                    <b-list-group>
                                        <b-list-group-item>
                                            <h6>
                                                <b-badge v-for="(
                            enrolledCourseStudent, index
                          ) in enrolledCourseStudents" :key="enrolledCourseStudent.id" variant="info">
                                                    {{ enrolledCourseStudent.student_last_name[0] }}.
                                                    {{ enrolledCourseStudent.name }}(Ули-{{
                                                        enrolledCourseStudent.quarter_id
                                                    }})
                                                    <b-icon icon="x-circle" scale="1" variant="danger" @click.prevent="
                                                        removeEnroleStudent(enrolledCourseStudent)
                                                        "></b-icon>
                                                </b-badge>
                                            </h6>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-card-group>
                        </div>
                        <div class="col-md-6">
                            <b-card-group deck>
                                <b-card header="Хичээлд бүртгэгдээгүй">
                                    <b-list-group>
                                        <b-list-group-item>
                                            <h6>
                                                <b-badge v-for="(
                            enrolledCourseStudentRemoved, index
                          ) in enrolledCourseStudentsRemoved" :key="enrolledCourseStudentRemoved.id" variant="info">{{
                            enrolledCourseStudentRemoved.student_last_name[0]
                                                    }}. {{ enrolledCourseStudentRemoved.name }}(Ули-{{
                                                        enrolledCourseStudentRemoved.quarter_id
                                                    }})
                                                    <b-icon icon="x-circle" scale="1" variant="danger" @click.prevent="
                                                        reAddEnroleStudent(enrolledCourseStudentRemoved)
                                                        "></b-icon>
                                                </b-badge>
                                            </h6>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-card-group>
                        </div>
                    </div>
                    <div>
                        <label class="typo__label">Суралцагчийн нэрээр хайлт хийх / дээр нь дарснаар суралцагч
                            сонгогдоно: {{ schoolclassessstudents.length }}</label>
                        <b-button variant="outline-info" class="mb-3" @click="selectAllStudents">Бүх суралцагч
                            сонгох</b-button>
                        <multiselect v-model="enrolledStudents.student_id" :options="schoolclassessstudents"
                            :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                            placeholder="Нэрээр сонголт хийнэ үү?" label="profile_user_id" track-by="profile_user_id"
                            :custom-label="customLabel" :show-labels="false" :preselect-first="true">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span
                                    class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length
                                    }} Сурагч бүртгэхээр сонголоо</span></template>
                        </multiselect>
                        <pre class="language-json"><code>{{ enrolledStudents.student_id.name }}</code></pre>
                        <b-button variant="outline-primary" @click="enrollStudents">Сурагч бүртгэх</b-button>
                    </div>
                </div>
            </div>
            <!-- /.card -->
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            action: "",
            editMode: false,
            selectClass: false,
            chosesClass: "",
            schoolgrades: [],
            schoolclasses: [], //ашигласан
            schoolsubjects: [], //ашигласан
            schoolteachers: [], //ашигласан
            schoolcoursetypes: [], //ашигласан
            schoolclassesscourses: [], //ашигласан
            removeSchoolClassCourse: [], //ашигласан
            schoolquarters: [], //ашигласан
            deparments: [],
            form: new Form({
                id: "",
                school_id: "",
                teacher_id: [],
                subject_id: "",
                course_type_id: "",
                school_class_id: "",
                isBehavior: 1,
                quarter_id: [],
                percent: "",
            }),
            schoolclassessstudents: [],
            enrolledCourseStudents: [],
            enrolledCourseStudentsRemoved: [],
            enrolledStudents: new Form({
                teacher_id: "",
                course_id: "",
                quarter_id: "",
                student_id: [],
            }),

            formStudentCourse: new Form({
                user_id: "",
                course_id: "",
            }),
            selected: [], // Must be an array reference!
            options: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
            ],
            selected3: null,
            options1: [
                { value: null, text: "Гүйцэтгэлийн % сонгоно уу?" },
                { value: 10, text: "10%" },
                { value: 20, text: "20%" },
                { value: 30, text: "30%" },
                { value: 40, text: "40%" },
                { value: 50, text: "50%" },
                { value: 60, text: "60%" },
                { value: 70, text: "70%" },
                { value: 80, text: "80%" },
                { value: 90, text: "90%" },
                { value: 100, text: "100%" },
                // { value: "d", text: "This one is disabled", disabled: true }
            ],
        };
    },
    components: { Multiselect },
    methods: {
        sortArrays(schoolteachers) {
            return _.orderBy(schoolteachers, "teacher_name/department_name", "asc");
        },
        getAllCourses() {
            axios
                .get("/manager/getAllCourseBehave")
                .then((response) => {
                    this.schoolclasses = response.data.schoolclasses;
                    this.schoolsubjects = response.data.schoolsubjects;
                    this.schoolteachers = response.data.schoolteachers;
                    this.schoolcoursetypes = response.data.schoolcoursetypes;
                    this.schoolquarters = response.data.schoolquarters;
                    //   console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        viewSchoolTeacher(schoolteacher) {
            // console.log("Дэлгэрэнгүй мэдээлэл харах" + schoolteacher);
        },
        editschoolTeacher(schoolteacher) {
            // console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolteacher);
            this.editMode = true;
            this.form.reset();
            this.form.fill(schoolteacher);
            this.form.department = schoolteacher.department_id;
            //   this.form.teacher_id.teacher_id = schoolteacher.teacher_id;
        },
        updateSchoolTeacher() {
            this.action = "Хичээлийн мэдээллийг шинэчилж байна...";
            // console.log(this.form);
            this.form
                .put("/manager/updateTeacher/" + this.form.id)
                .then((response) => {
                    // console.log(response.data);
                    this.$toastr.s("Амжилттай", "Шинэчилсэн");
                    Fire.$emit("loadSchoolCourses");
                    this.form.reset();
                    this.editMode = !this.editMode;
                })
                .catch((errors) => {
                    //   console.log(errors);
                });
        },
        deleteSchoolTeacher(schoolteacher) {
            // console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
        },
        createSchoolCourse() {
            // console.log(this.form);
            this.action = "Анги дээр хичээл үүсгэж байна...";
            this.form
                .post("/manager/createCourse")
                .then((response) => {
                    // console.log(response.data.course.school_class_id);
                    // console.log(response.data.school_class[0]);
                    this.$toastr.s(response.data.course, "Амжилттай");
                    Fire.$emit("loadSchoolCourses");
                    const someData = {
                        school_class_id: response.data.course.school_class_id,
                        full_name: response.data.school_class[0].full_name,
                    };
                    this.form.reset();
                    Fire.$emit("loadSchoolClasses", someData);
                })
                .catch((errors) => {
                    // console.log(errors);
                    this.selectClass = true;
                    this.$toastr.e(
                        "Хичээлийг нэмж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа." + errors
                    );
                });
        },
        cancelEdit() {
            this.editMode = false;
            this.form.reset();
        },
        editSchoolCourse(schoolclassesscourse) {
            this.schoolclassessstudents = [];
            this.enrolledCourseStudents = [];
            this.enrolledCourseStudentsRemoved = [];
            //   console.log(schoolclassesscourse);
            schoolclassesscourse.quarter_id = this.modifyQuarter(
                schoolclassesscourse.quarter_id
            );
            this.editMode = true;
            // console.log(this.editMode);
            //   JSON.parse(schoolclassesscourse.teacher_id);
            //   console.log(schoolclassesscourse);
            this.form.reset();
            //   this.form.fill(schoolclassesscourse);
            this.form.id = schoolclassesscourse.id;
            this.form.school_id = schoolclassesscourse.school_id;

            this.form.teacher_id = schoolclassesscourse.teacher;
            this.form.subject_id = schoolclassesscourse.my_subject;

            this.form.course_type_id = schoolclassesscourse.course_type_id;
            this.form.school_class_id = schoolclassesscourse.school_class_id;
            this.form.quarter_id = schoolclassesscourse.quarter_id;
            this.form.percent = schoolclassesscourse.percent;
            var arrayQuarter = JSON.parse(
                "[" + schoolclassesscourse.quarter_id + "]"
            );
            this.form.quarter_id = arrayQuarter;
            // console.log(this.form);
        },
        updateSchoolCourse() {
            // console.log(this.form);
            this.action = "Анги дээр хүмүүжлийн хичээл үүсгэж байна...";
            this.form
                .put("/manager/updateCourse")
                .then((response) => {
                    // console.log(response.data.course.school_class_id);
                    // console.log(response.data, "updateCourse-оос хэвлэв");
                    this.$toastr.s(
                        "Ангид хүмүүжлийн хичээл амжилттай бүртгэлээ.",
                        "Амжилттай"
                    );

                    const someData = {
                        school_class_id: response.data.course.school_class_id,
                        full_name: response.data.school_class[0].full_name,
                    };
                    // console.log(someData);
                    this.form.reset();
                    Fire.$emit("loadSchoolClasses", someData);
                })
                .catch((errors) => {
                    // console.log(errors);
                    this.selectClass = true;
                    this.$toastr.e(
                        "Хүмүүжлийн хичээлийг нэмж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа." + errors
                    );
                });
        },
        modifyQuarter(rawQuarters) {
            // console.log(rawQuarters + "xxxxx");
            let quarters = [];
            if (rawQuarters != "") {
                let data = rawQuarters.split(",");
                data.forEach((element) => {
                    if (parseInt(element.split("=>")[2]) == 1) {
                        quarters.push(parseInt(element.split("=>")[0]));
                    }
                });
            }
            return quarters;
        },

        schoolClass(id, full_name) {
            this.cancelEdit();
            this.form.school_class_id = id;
            this.selectClass = true;
            this.chosesClass = full_name;
            this.enrolledCourseStudents = [];
            this.schoolclassessstudents = [];
            // console.log(id);
            axios
                .get(
                    "/manager/getSchoolClassessCourseBehavior/" +
                    this.form.school_class_id
                )
                .then((response) => {
                    this.schoolclassesscourses = response.data.schoolclassesscourses;
                    // console.log(response.data.schoolclassesscourses);
                })
                .catch((error) => {
                    //   console.log(error);
                });
        },

        selectAllStudents() {
            this.enrolledStudents.student_id = this.schoolclassessstudents;
        },
        selectClassStudents(schoolclassesscourse) {
            //   console.log(schoolclassesscourse);
            this.form.reset();
            this.removeSchoolClassCourse = schoolclassesscourse;
            this.enrolledCourseStudents = [];
            this.enrolledCourseStudentsRemoved = [];
            axios
                .post("/manager/getSchoolClassStudent", {
                    school_class_id: schoolclassesscourse.school_class_id,
                    course_id: schoolclassesscourse.course_id,
                })
                .then((response) => {
                    this.schoolclassessstudents = response.data.getSchoolClassStudents;
                    // console.log(response.data.getSchoolClassStudents);
                    this.enrolledCourseStudents = response.data.enrolledCourseStudents;
                    this.enrolledCourseStudentsRemoved =
                        response.data.enrolledCourseStudentsRemoved;
                    // console.log(response.data.enrolledCourseStudents);
                    this.enrolledStudents.course_id = schoolclassesscourse.id;
                    this.enrolledStudents.teacher_id = schoolclassesscourse.teacher_id;
                    this.enrolledStudents.quarter_id = this.modifyQuarter(
                        schoolclassesscourse.quarter_id
                    );
                })
                .catch((error) => {
                    // console.log(error);
                });
        },
        customLabel({ user_name, profile_student_last_name }) {
            return `${user_name} – ${profile_student_last_name}`;
        },
        customLabelTeacher({ teacher_name, teacher_last_name }) {
            return ` ${teacher_last_name[0].toUpperCase()}.${teacher_name}`;
        },
        enrollStudents() {
            // console.log(this.enrolledStudents.teacher_id);
            let arr = [...this.enrolledStudents.student_id];
            // console.log(arr);
            arr.forEach((el) => {
                // console.log(el.email);
                el.course_id = this.enrolledStudents.course_id;
                el.teacher_id = this.enrolledStudents.teacher_id;
                el.school_id = this.enrolledStudents.profile_school_id;
                el.school_class_id = this.enrolledStudents.profile_school_class_id;
                // console.log(el.course_id);
            });
            // console.log(arr);
            let arrQuarter = [...this.enrolledStudents.quarter_id];
            // console.log(arrQuarter);
            let d = [];
            arr.forEach((el, index) => {
                arrQuarter.forEach((elQ, indexQ) => {
                    // console.log(elQ + "=>" + indexQ);
                    // console.log(indexQ);
                    el.quarter_id = elQ;
                    d.push($.extend({}, el, elQ));
                    // console.log(d);
                });
            });
            // console.log(arr);
            //   console.log(d);

            axios
                .post("/manager/enrollCourseStudents", { enrollStudents: d })
                .then((response) => {
                    //   console.log(response.data.allEditData[0].profile_school_class_id);
                    this.$toastr.s("Сурагчдыг хичээлд амжилттай бүртгэлээ.", "Амжилттай");
                    this.schoolclassessstudents = [];
                    this.enrolledStudents.student_id = [];
                    Fire.$emit("removeFromGradeStudent");
                    this.form.school_class_id =
                        response.data.allEditData[0].profile_school_class_id;
                })
                .catch(() => {
                    this.$toastr.e(
                        "Хичээлд сурагч бүртгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },
        removeEnroleStudent(enrolledCourseStudent) {
            // removeFromGradeStudent
            // console.log(enrolledCourseStudent);
            axios
                .delete(`/manager/removeFromGradeStudent/${enrolledCourseStudent.id}`)
                .then((response) => {
                    this.$toastr.s(
                        "Суралцагчыг тухайн улирлаас амжилттай хаслаа." +
                        response.data.removeGrade,
                        "Амжилттай"
                    );
                    Fire.$emit("removeFromGradeStudent");
                })
                .catch(() => {
                    this.$toastr.e(
                        "Хичээлээс суралцагч хасч чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },
        reAddEnroleStudent(enrolledCourseStudent) {
            // removeFromGradeStudent
            // console.log(enrolledCourseStudent);
            axios
                .delete(`/manager/reAddFromGradeStudent/${enrolledCourseStudent.id}`)
                .then((response) => {
                    this.$toastr.s(
                        "Суралцагчыг тухайн улирлаас амжилттай хаслаа." +
                        response.data.reAddGrade,
                        "Амжилттай"
                    );
                    Fire.$emit("removeFromGradeStudent");
                })
                .catch(() => {
                    this.$toastr.e(
                        "Хичээлээс суралцагч хасч чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
                        "Алдаа гарлаа."
                    );
                });
        },

        //Хичээл устгах
        deleteSchoolCourse(schoolclassesscourse) {
            //   console.log(schoolclassesscourse);
            Swal.fire({
                title: "Та итгэлтэй байна уу? Маш анхааралтай ажиллана уу?",
                text: "Тухайн хичээлд хамааралтай суралцагчдын дүн, улирал, сэтгэгдэл, шалгалтын дүн, улирлын дүн болон жилийн эцсийн дүн, ирц, ББЗ циклийн даалгаврууд цуг устгагдана.!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Тийм, хичээл устга!",
                cancelButtonText: "Үгүй, цуцал!",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post("/manager/deleteCourse", {
                            schoolClassCourse: schoolclassesscourse,
                        })
                        .then((res) => {
                            Swal.fire(
                                "Устгагдлаа",
                                `${schoolclassesscourse.name}` +
                                " " +
                                "багштай" +
                                " " +
                                `${schoolclassesscourse.subject_name}` +
                                " хичээл устгагдлаа.!",
                                "success"
                            );

                            const someData = {
                                school_class_id: schoolclassesscourse.school_class_id,
                                full_name: schoolclassesscourse.full_name,
                            };
                            Fire.$emit("loadSchoolClasses", someData);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            });
        },
    },
    created() {
        this.getAllCourses();
        Fire.$on("loadSchoolCourses", () => {
            this.getAllCourses();
        });
        Fire.$on("loadSchoolClasses", (someData) => {
            // console.log(
            //     someData,
            //     "Fire on loadSchoolClasses аас " + someData.school_class_id,
            //     someData.full_name
            // );
            this.schoolClass(someData.school_class_id, someData.full_name);
        });
        Fire.$on("removeFromGradeStudent", (schoolclassesscourse) => {
            this.selectClassStudents(this.removeSchoolClassCourse);
        });
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
