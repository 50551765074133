<template>
    <section class="content">
        <div class="container-fluid">
            <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
            <div class="row">
                <div class="col-md-11 mt-3">
                    <multiselect v-model="teacherId" deselect-label="Хасах" select-label="Сонгох" track-by="user_id"
                        :custom-label="customUserName" placeholder="Багш сонгох" :options="teachers" :searchable="true"
                        :allow-empty="true">
                        <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.teacher_last_name[0] }}.{{ option.teacher_name }}
                        </template>
                    </multiselect>
                </div>
                <div class="col-md-1 mt-3">
                    <b-button variant="danger" size="sm" @click.prevent="clearTeacher()">Арилгах</b-button>
                </div>
            </div>
            <div v-if="teacherId != null">
                <teacherRep :teacherId="teacherId"></teacherRep>
            </div>
        </div>
    </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import teacherRep from "../../Teacher/Report/Report.vue";
export default {
    data() {
        return {
            iconMode: "fa fa-bars",
            btnColor: "warning",
            teachers: [],
            teacherId: null,
        };
    },
    components: {
        teacherRep: teacherRep,
        Multiselect,
    },
    methods: {
        switchMenu() {
            if (this.iconMode != "fa fa-bars") {
                this.iconMode = "fa fa-bars";
                this.btnColor = "warning";
            } else {
                this.iconMode = "fa fa-times";
                this.btnColor = "danger";
            }
        },
        clearTeacher() {
            this.teacherId = null;
        },
        getAllTeachers() {
            axios
                .get("/manager/getAllTeacher")
                .then((res) => {
                    this.teachers = res.data.teachers;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        customUserName({ teacher_name, teacher_last_name }) {
            return `${teacher_last_name[0]}. ${teacher_name}`;
        },
    },
    beforeMount() { },
    created() {
        this.getAllTeachers();
    },
};
</script>

<style scoped>
.info-box-text,
.info-box-number,
.description-text {
    font-size: 70%;
}

.pieChart {
    height: 330px;
}

.barChart {
    height: 340px;
}
</style>
