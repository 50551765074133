<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Соёл хүмүүжлийн ажил</h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container class="bv-example-row" fluid>
              <b-row>
                <b-col sm="2"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :searchable="true"
                    :close-on-select="false"
                    :show-labels="true"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Хасах"
                    select-label="Сонгох"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    v-model="selectedStatus"
                    :options="statusOptions"
                    text-field="text"
                    value-field="value"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': form.errors.has('selectedStatus'),
                      },
                    ]"
                  ></b-form-select>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="primary" @click.prevent="getAllProject()"
                    >Шүүж харах</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-overlay :show="devIsLoaded" rounded="sm">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Үйлдэл</th>
                  <th>Төлөв</th>
                  <th>Хугацаа</th>
                  <th>Нэр</th>
                  <th>Тайлбар</th>
                  <th>Анги</th>
                  <th>Эхлэх</th>
                  <th>Дуусах</th>
                  <th>Хамрагдах</th>
                  <th>Хуваарь</th>
                  <th>Бүртгэл дуусах</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody
                v-for="(project, index) in allProject"
                :key="project.project_id"
              >
                <tr>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="1">
                    <a
                      v-show="
                        project.status === 'check' &&
                        project.owner_id == currentUserId
                      "
                      href="#"
                      class="badge bg-success"
                      @click="editStatus(project)"
                      ><i class="fas fa-check"></i>
                    </a>
                    <a
                      v-show="
                        project.owner_id == currentUserId ||
                        project.project_teachers.filter(
                          (i) =>
                            i.teacher_id == currentUserId &&
                            i.mentor_type == 'senior'
                        ).length > 0
                      "
                      href="#"
                      class="badge bg-warning"
                      @click="showBudgets(project)"
                      ><i class="fas fa-coins"></i>
                    </a>
                    <a
                      href="#"
                      class="badge bg-info"
                      @click="showProject(project)"
                      ><i class="fa fa-chalkboard-teacher"></i>
                    </a>
                    <a
                      href="#"
                      class="badge bg-dark"
                      @click="showStudents(project)"
                      ><i class="fas fa-user-graduate"></i
                    ></a>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="2">
                    <span
                      v-show="project.status === 'registered'"
                      class="badge bg-warning"
                      >БҮРТГЭГДСЭН</span
                    >
                    <span
                      v-show="project.status === 'check'"
                      class="badge bg-danger"
                      >ИДЭВХГҮЙ</span
                    >
                    <span
                      v-show="project.status === 'active'"
                      class="badge bg-primary"
                      >ИДЭВХТЭЙ</span
                    >
                    <span
                      v-show="project.status === 'end'"
                      class="badge bg-success"
                      >ДУУССАН</span
                    >
                  </td>
                  <td class="dtr-control sorting_1" tabindex="4">
                    {{ project.time_type }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="5">
                    {{ project.name }}
                  </td>
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    style="white-space: pre-wrap"
                  >
                    <b-badge class="text-wrap text-left" variant="light">{{
                      project.description.slice(0, 40)
                    }}</b-badge>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="7">
                    {{ project.scope }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="8">
                    <span v-if="project.time_type != 'Тогтмол'">
                      {{ project.start_date | dateYearMonthDay }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="9">
                    <span v-if="project.time_type != 'Тогтмол'">
                      {{ project.end_date | dateYearMonthDay }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="10">
                    {{ project.limit }}/{{ project.registered }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="10">
                    Өдөр:{{ project.day }}, Цаг:{{ project.startTime }}
                    -
                    {{ project.endTime }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="11">
                    {{ project.reg_close_date }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="12">
                    {{ project.created_at | dateYearMonthDay }}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="odd">
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    colspan="10"
                    align="right"
                  >
                    Нийт суралцагч:
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <strong>{{ totalLimit }}</strong>
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <strong>{{ totalRegistered }}</strong>
                  </td>
                </tr>
                <tr class="odd">
                  <td
                    class="dtr-control sorting_1"
                    tabindex="0"
                    colspan="10"
                    align="right"
                  >
                    Нийт төсөл:
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <strong>{{ niit }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <!-- Багш нэмэх хэсэг -->
    <b-modal
      v-show="showMode"
      size="xl"
      id="modal-projectInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Багш нарын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              <img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />
              {{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төслийн багш нар</h3>
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="projectMentors != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Зураг</th>
                      <th>Үүрэг</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th v-show="ownerId == currentUserId">Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectMentor, index) in projectMentors"
                    :key="projectMentor.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <img
                          v-if="projectMentor.profile_photo_url != null"
                          :src="projectMentor.profile_photo_url"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                        <img
                          v-else
                          src="/images/users/user.png"
                          alt="user-avatar"
                          class="direct-chat-img"
                        />
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span
                          v-show="projectMentor.mentor_type === 'member'"
                          class="badge bg-dark"
                          >ГИШҮҮН</span
                        >
                        <span
                          v-show="projectMentor.mentor_type === 'owner'"
                          class="badge bg-success"
                          >ЭЗЭМШИГЧ</span
                        >
                        <span
                          v-show="projectMentor.mentor_type === 'senior'"
                          class="badge bg-primary"
                          >АХЛАГЧ</span
                        >
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectMentor.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectMentor.teacher_lname[0] }}.
                        {{ projectMentor.teacher_fname }}
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                        v-show="ownerId == currentUserId"
                      >
                        <a
                          v-show="projectMentor.mentor_type != 'owner'"
                          href="#"
                          class="badge bg-primary"
                          @click="editMentor(projectMentor)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          v-show="projectMentor.mentor_type != 'owner'"
                          href="#"
                          class="badge bg-danger"
                          @click="removeMentor(projectMentor)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editMode">Багш нэмэх</h3>
                <h3 class="card-title" v-show="editMode">Багш засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div
                class="card-body"
                v-show="
                  ownerId == currentUserId ||
                  projectMentors.filter(
                    (i) =>
                      i.teacher_id == currentUserId && i.mentor_type == 'senior'
                  ).length > 0
                "
              >
                <form
                  v-on:change="modalChangeTeacherList($event)"
                  v-show="!editMode"
                >
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formMentor.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group" v-show="!editMode">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formMentor.mentors"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="10"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Гишүүний төрөл</label>
                    <b-form-select
                      v-model="formMentor.mentor_type"
                      :options="mentorTypes"
                      text-field="name"
                      value-field="value"
                    ></b-form-select>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div
                class="card-footer"
                v-if="
                  ownerId == currentUserId ||
                  projectMentors.filter(
                    (i) =>
                      i.teacher_id == currentUserId && i.mentor_type == 'senior'
                  ).length > 0
                "
              >
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addMentor()"
                  v-show="!editMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateMentor()"
                  v-show="editMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditMentor()"
                  v-show="editMode"
                >
                  Болих
                </button>
              </div>
              <div class="card-footer" v-else>
                <span class="text-danger text-sm"
                  >Зөвхөн <b>менежер</b> соёл хүмүүжлийн ажилд багш нэмэх
                  боломжтой!</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Суралцагч нэмэх хэсэг -->
    <b-modal
      v-show="showStudentMode"
      size="xl"
      id="modal-project-student"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Суралцагчдын мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Суралцагч нар</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Бүртгүүлсэн: </b> {{ form.limit }} /
                  {{ form.registered }}</span
                >
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="projectStudents != null"
              >
                <b-row class="my-1">
                  <b-col sm="5" class="text-right">
                    <label>Бүх суралцагчдын оролцоог бүртгэх:</label>
                  </b-col>
                  <b-col sm="2">
                    <b-form-select
                      v-model="score"
                      :options="scoreOption"
                      size="sm"
                    ></b-form-select>
                  </b-col>
                  <b-col sm="2">
                    <b-button
                      @click="changeAllStudent"
                      variant="outline-primary"
                      size="sm"
                      >Бүртгэх</b-button
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" class="my-1">
                    <b-form-group
                      label="Нэг хуудсанд харуулах тоо"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageCompetitions"
                        :options="pageOptionsRooms"
                        size="sm"
                        >\</b-form-select
                      >
                    </b-form-group>

                    <b-form-group
                      label="Хайлт хийх утга оруулна уу!"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filterCompetitions"
                          type="search"
                          placeholder="Хайлт хийх утгаа оруулна уу!"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            :disabled="!filterCompetitions"
                            @click="filterCompetitions = ''"
                            >Цэвэрлэх</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPageCompetitions"
                  :total-rows="totalRowsCompetitions"
                  :per-page="perPageCompetitions"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  striped
                  hover
                  :items="projectStudents"
                  :fields="fields"
                  sort-desc.sync="false"
                  responsive="sm"
                  :current-page="currentPageCompetitions"
                  :per-page="perPageCompetitions"
                  :filter="filterCompetitions"
                  :filter-included-fields="filterOnCompetitions"
                  :filter-ignored-fields="filterIgnoredFields"
                  @filtered="onFilteredCompetitions"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(Photo)="data">
                    <b-badge
                      v-for="img in data.item.images"
                      :key="img.id"
                      variant="secondary"
                      class="mr-1 mb-1"
                    >
                      <b-avatar
                        variant="light"
                        :src="img.image"
                        size="40"
                        square
                        v-b-tooltip.hover.html="
                          '<img src=' +
                          img.image +
                          ' style=' +
                          'width:180px!important>'
                        "
                      >
                      </b-avatar>
                      <b-badge
                        @click.prevent="deleteImage(img)"
                        variant="danger"
                        title="Зураг устгах"
                        role="button"
                        ><b-icon icon="x"></b-icon
                      ></b-badge>
                    </b-badge>
                    <b-button
                      size="sm"
                      class="mr-2"
                      href="#"
                      variant="success"
                      @click.prevent="showImageUploader(data.item)"
                    >
                      <b-icon icon="plus"></b-icon>
                    </b-button>
                  </template>
                  <template #cell(Action)="data">
                    <b-button
                      size="sm"
                      class="mt-2 mr-2"
                      href="#"
                      variant="outline-success"
                      @click.prevent="showComment(data.item)"
                      >Эсээ харах</b-button
                    >
                    <a
                      href="#"
                      class="badge bg-primary"
                      @click="editStudent(data.item)"
                      ><i class="fa fa-check"></i
                    ></a>
                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="removeStudent(data.item)"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </template>
                  <template #cell(image)="data">
                    <b-avatar
                      variant="secondary"
                      :src="data.item.profile_photo_url"
                    ></b-avatar>
                  </template>
                  <template #cell(Student)="data">
                    {{ data.item.student_lname[0] }}.
                    {{ data.item.student_fname }}
                  </template>
                  <template #cell(Score)="data">
                    <b-badge
                      v-if="data.item.score >= 80"
                      variant="success"
                      v-show="data.item.score != null"
                    >
                      {{ data.item.score }}</b-badge
                    >
                    <b-badge
                      v-if="(data.item.score >= 60) & (data.item.score < 80)"
                      variant="primary"
                      v-show="data.item.score != null"
                    >
                      {{ data.item.score }}</b-badge
                    >
                    <b-badge
                      v-if="(data.item.score >= 30) & (data.item.score < 60)"
                      variant="warning"
                      v-show="data.item.score != null"
                    >
                      {{ data.item.score }}</b-badge
                    >
                    <b-badge
                      v-if="data.item.score < 30"
                      variant="danger"
                      v-show="data.item.score != null"
                    >
                      {{ data.item.score }}</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-show="data.item.score === null"
                    >
                      ОРООГҮЙ</b-badge
                    >
                    <div class="progress progress-xs progress-striped active">
                      <div
                        v-if="data.item.score < 30"
                        class="progress-bar bg-danger"
                        :style="{ width: `${data.item.score}%` }"
                      ></div>
                      <div
                        v-if="(data.item.score >= 30) & (data.item.score < 60)"
                        class="progress-bar bg-warning"
                        :style="{ width: `${data.item.score}%` }"
                      ></div>
                      <div
                        v-if="(data.item.score >= 60) & (data.item.score < 80)"
                        class="progress-bar bg-primary"
                        :style="{ width: `${data.item.score}%` }"
                      ></div>
                      <div
                        v-if="data.item.score >= 80"
                        class="progress-bar bg-success"
                        :style="{ width: `${data.item.score}%` }"
                      ></div>
                    </div>
                  </template>
                </b-table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editStudentMode">
                  Суралцагч нэмэх
                </h3>
                <h3 class="card-title" v-show="editStudentMode">
                  Суралцагч засах
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form
                  v-on:change="modalChangeStudentList($event)"
                  v-show="!editStudentMode"
                >
                  <div class="form-group">
                    <label>Анги</label>
                    <b-form-select
                      v-model="formStudent.class_id"
                      :options="schoolClasses"
                      text-field="full_name"
                      value-field="id"
                    ></b-form-select>
                  </div>
                </form>
                <form>
                  <div class="form-group" v-show="!editStudentMode">
                    <label>Суралцагч</label>
                    <b-form-select
                      v-model="formStudent.students"
                      :options="modalStudents"
                      text-field="student_full_name"
                      value-field="student_user_id"
                      multiple
                      :select-size="10"
                    ></b-form-select>
                    <has-error
                      :form="formStudent"
                      field="student_full_name"
                    ></has-error>
                  </div>
                  <div class="form-group" v-show="editStudentMode">
                    <label>Гүйцэтгэл</label>
                    <b-form-select
                      v-model="formStudent.score"
                      :options="scoreOption"
                    ></b-form-select>
                  </div>
                  <div class="form-group" v-show="editStudentMode">
                    <b-form-textarea
                      id="textarea"
                      v-model="formStudent.description"
                      placeholder="Гүйтэгэлийн талаарх тайлбар оруулна уу."
                      rows="3"
                      max-rows="6"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formStudent.errors.has('description'),
                        },
                      ]"
                    ></b-form-textarea>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addStudent()"
                  v-show="!editStudentMode"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateStudent()"
                  v-show="editStudentMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditStudent()"
                  v-show="editStudentMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Зургийн сан -->
    <b-modal
      size="xs"
      id="modal-image"
      class="modal fade"
      title="Зураг нэмэх"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label>Зураг</label>
              <b-form-file
                v-model="imageData"
                id="image"
                browse-text="Зураг сонгох"
                :class="[
                  'form-control',
                  {
                    'is-invalid': imgError,
                  },
                ]"
                @change="setImg"
                accept="image/jpeg, image/png"
              ></b-form-file>
              <span class="col-sm-5">
                {{ imgError }}
              </span>
            </div>
            <div v-if="formImage.image" class="form-group">
              <b-avatar
                variant="light"
                size="6rem"
                :src="formImage.image"
                square
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="createImage()"
        >
          Хадгалах
        </button>
        <button class="btn btn-secondary" @click.prevent="cancelUploadImg">
          Болих
        </button>
      </div>
    </b-modal>
    <!-- Төсөв нэмэх хэсэг -->
    <b-modal
      v-show="showBudgetMode"
      size="xl"
      id="modal-project-budget"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Төсвийн мэдээлэл"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              ><img
                class="profile-user-img img-fluid attachment-img mr-2"
                :src="form.image"
                alt="Төслийн зураг"
                accept="image/*"
              />{{ form.name }}</span
            >
            <span
              v-if="form.time_type != 'Тогтмол'"
              class="direct-chat-timestamp float-right"
              ><b>Хугацаа: </b>{{ form.start_date | dateYearMonthDay }} -
              {{ form.end_date | dateYearMonthDay }}</span
            ><br />
            <span class="direct-chat-timestamp float-right"
              ><b>Бүртгэл дуусах огноо: </b>
              {{ form.close_reg_date | dateYearMonthDay }}</span
            >
          </div>
          <div class="direct-chat-text primary ml-0">
            {{ form.description }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төсвийн жагсаалт</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Нийт: </b> {{ projectBudgets.length }}</span
                >
              </div>
              <!-- /.card-header -->
              <div
                class="card-body table-responsive"
                v-if="projectBudgets != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th v-show="ownerId == currentUserId">Үйлдэл</th>
                      <th>Төрөл</th>
                      <th>Нэр</th>
                      <th>Дүн</th>
                      <th>Баталсан</th>
                      <th>Ширхэг</th>
                      <th>Нэгжийн үнэ</th>
                      <th>Нийт</th>
                      <th>Зөрүү</th>
                      <th>Нийлүүлэгч</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(projectBudget, index) in projectBudgets"
                    :key="projectBudget.mentor_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td
                        class="dtr-control sorting_1"
                        tabindex="0"
                        v-show="
                          ownerId == currentUserId &&
                          (form.status === 'registered' ||
                            form.status === 'check')
                        "
                      >
                        <a
                          href="#"
                          class="badge bg-primary"
                          @click="editBudget(projectBudget)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeBudget(projectBudget)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.type_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.item_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.total }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.confirmed_total }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.pieces }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.price }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.price * projectBudget.pieces }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{
                          projectBudget.total - projectBudget.confirmed_total
                        }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ projectBudget.choose_1 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editBudgetMode">Төсөв нэмэх</h3>
                <h3 class="card-title" v-show="editBudgetMode">Төсөв засах</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label>Төрөл</label>
                    <b-form-select
                      v-model="formBudget.budget_type_id"
                      v-on:change="getBudgetItemList"
                      :options="budgetTypes"
                      text-field="name"
                      value-field="id"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('budget_type_id'),
                        },
                      ]"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Бүтээгдэхүүн</label>
                    <multiselect
                      v-model="formBudget.budget_item"
                      deselect-label="Хасах"
                      select-label="Сонгох"
                      track-by="id"
                      label="item_name"
                      placeholder="Байхгүй бол шинээр нэмнэ үү"
                      :options="budgetItems"
                      :searchable="true"
                      :allow-empty="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.item_name }} -
                        {{ option.item_description }}
                      </template>
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <b-button
                      mt="2"
                      variant="outline-success"
                      size="sm"
                      @click="itemAddChangeMode()"
                      >Бүтээгдэхүүн шинээр бүртгэх бол дарна уу?</b-button
                    >
                  </div>
                  <div v-show="itemAddMode">
                    <div class="form-group">
                      <multiselect
                        v-model="formItem.budget_type_id"
                        deselect-label="Хасах"
                        select-label="Сонгох"
                        track-by="id"
                        label="name"
                        placeholder="Төсвийн ангилал сонгоно уу?"
                        :options="budgetTypes"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          >Ангилал:
                          <strong>{{ option.name }}</strong>
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="item_name"
                        placeholder="Бүтээгдэхүүний нэр, Бүтээгдэхүүний жагсаалтанд байгаа нэртэй давхцах боломжгүйг анхаарна уу."
                        wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                        v-model="formItem.item_name"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control"
                        id="item_price"
                        placeholder="Бүтээгдэхүүний үнэ"
                        wtx-context="9265B7B5-CD6B-4067-8086-7172D1D95A8B"
                        v-model="formItem.item_price"
                      />
                    </div>
                    <div class="form-group">
                      <b-form-textarea
                        id="textarea"
                        v-model="formItem.item_description"
                        placeholder="Бүтээгдэхүүнтэй холбоотой дэлгэрэнгүй мэдээлэл..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                    <div class="form-group">
                      <b-button
                        variant="outline-success"
                        size="sm"
                        @click.prevent="addItem()"
                        >Бүтээгдэхүүн бүртгэх</b-button
                      >
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        @click.prevent="cancelItem()"
                        >Цуцлах</b-button
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Ширхэг</label>
                    <input
                      type="number"
                      name="pieces"
                      v-model="formBudget.pieces"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('pieces'),
                        },
                      ]"
                      placeholder="Ширхэг"
                    />
                  </div>
                  <div class="form-group">
                    <label>Нэгжийн үнэ</label>
                    <input
                      type="number"
                      name="price"
                      v-model="formBudget.price"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('price'),
                        },
                      ]"
                      placeholder="Нэгжийн үнэ"
                    />
                  </div>
                  <div class="form-group">
                    <label>Нийт</label>
                    <input
                      type="number"
                      name="total"
                      v-text="totalUne"
                      disabled
                      v-model="formBudget.total"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('total'),
                        },
                      ]"
                      placeholder="Нийт"
                    />
                  </div>
                  <div class="form-group">
                    <label>Хэмжих нэгж</label>
                    <input
                      type="text"
                      name="measure_unit"
                      v-model="formBudget.measure_unit"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('measure_unit'),
                        },
                      ]"
                      placeholder="Ширхэг, л, мл, , мг, кг, тн, м, см, мм,... гэх мэт"
                    />
                  </div>
                  <div class="form-group">
                    <label>Зориулалт</label>
                    <input
                      type="text"
                      name="zoriulalt"
                      v-model="formBudget.zoriulalt"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('zoriulalt'),
                        },
                      ]"
                      placeholder="Зориулалт"
                    />
                  </div>
                  <div class="form-group">
                    <b-form-checkbox
                      id="compare"
                      v-model="formBudget.compare"
                      name="compare"
                      value="1"
                      unchecked-value="0"
                    >
                      Харьцуулалт хийсэн эсэх
                    </b-form-checkbox>
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0">Сонголт</label>
                    <label v-else>Сонголт 1</label>
                    <input
                      type="text"
                      name="choose_1"
                      v-model="formBudget.choose_1"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('choose_1'),
                        },
                      ]"
                      placeholder="Сонголт"
                    />
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0"
                      >Сонголт холбоос</label
                    >
                    <label v-else>Сонголт 1 холбоос</label>
                    <input
                      type="text"
                      name="choose_1_link"
                      v-model="formBudget.choose_1_link"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': formBudget.errors.has('choose_1_link'),
                        },
                      ]"
                      placeholder="Сонголт холбоос"
                    />
                  </div>
                  <div class="form-group">
                    <label v-if="formBudget.compare == 0">Зураг</label>
                    <label v-else>Зураг 1</label>
                    <b-form-file
                      id=""
                      browse-text="Зураг сонгох"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': imgError1,
                        },
                      ]"
                      @change="getBudgetImg1"
                    ></b-form-file>
                    <span>
                      {{ imgError1 }}
                    </span>
                  </div>
                  <div v-show="formBudget.compare == 1">
                    <div class="form-group">
                      <label>Сонголт 2</label>
                      <input
                        type="text"
                        name="choose_2"
                        v-model="formBudget.choose_2"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_2'),
                          },
                        ]"
                        placeholder="Сонголт 2"
                      />
                    </div>
                    <div class="form-group">
                      <label>Сонголт 2 холбоос</label>
                      <input
                        type="text"
                        name="choose_2_link"
                        v-model="formBudget.choose_2_link"
                        :class="[
                          'form-control',
                          {
                            'is-invalid':
                              formBudget.errors.has('choose_2_link'),
                          },
                        ]"
                        placeholder="Сонголт 2 холбоос"
                      />
                    </div>
                    <div class="form-group">
                      <label for="name">Зураг 2</label>
                      <b-form-file
                        id=""
                        browse-text="Зураг 2 сонгох"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': imgError2,
                          },
                        ]"
                        @change="getBudgetImg2"
                      ></b-form-file>
                      <span>
                        {{ imgError2 }}
                      </span>
                    </div>
                    <div class="form-group">
                      <label>Сонголт 3</label>
                      <input
                        type="text"
                        name="choose_3"
                        v-model="formBudget.choose_3"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': formBudget.errors.has('choose_3'),
                          },
                        ]"
                        placeholder="Сонголт 3"
                      />
                    </div>
                    <div class="form-group">
                      <label>Сонголт 3 холбоос</label>
                      <input
                        type="text"
                        name="choose_3_link"
                        v-model="formBudget.choose_3_link"
                        :class="[
                          'form-control',
                          {
                            'is-invalid':
                              formBudget.errors.has('choose_3_link'),
                          },
                        ]"
                        placeholder="Сонголт 3 холбоос"
                      />
                    </div>
                    <div class="form-group">
                      <label for="name">Зураг 3</label>
                      <b-form-file
                        id=""
                        browse-text="Зураг 3 сонгох"
                        :class="[
                          'form-control',
                          {
                            'is-invalid': imgError3,
                          },
                        ]"
                        @change="getBudgetImg3"
                      ></b-form-file>
                      <span>
                        {{ imgError3 }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addBudget()"
                  v-show="!editBudgetMode"
                  :disabled="
                    form.status != 'registered' || form.status != 'check'
                      ? false
                      : true
                  "
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateBudget()"
                  v-show="editBudgetMode"
                >
                  Хадгалах
                </button>
                <button
                  type="submit"
                  class="btn btn-dark"
                  @click.prevent="cancelEditBudget()"
                  v-show="editBudgetMode"
                >
                  Болих
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="modal-project-comment"
      class="modal fade"
      title="Эсээ"
      hide-footer
    >
      <div class="modal-body">
        <div class="row" v-if="beforeComments.length > 0">
          <div class="col-lg-12">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th style="width: 5px">№</th>
                  <th>Суралцагч</th>
                  <th>Эсээ</th>
                  <th>Зассан</th>
                  <th>Үүсгэсэн</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comm, index) in beforeComments" :key="comm.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ comm.student.student_last_name[0]
                    }}{{ comm.student.student_last_name[1] }}.{{
                      comm.student.student_name
                    }}
                  </td>
                  <td>
                    <b-card v-html="comm.comment" class="p-1 text-justify" />
                  </td>
                  <td>
                    {{ comm.updated_at | dateWithTime }}
                  </td>
                  <td>
                    {{ comm.created_at | dateWithTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <p class="text-center text-muted h5 mt-5">
              <b-icon icon="inboxes"></b-icon>
            </p>
            <p class="text-center text-muted font-italic text-sm">
              Одоогоор суралцагч эсээ бичээгүй байна.
            </p>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  computed: {
    dayState() {
      return this.form.day > 5 ? true : false;
    },
    totalUne: function () {
      let totalDun;
      if (this.formBudget.pieces && this.formBudget.price) {
        totalDun = this.formBudget.pieces * this.formBudget.price;
        this.formBudget.total = totalDun;
        return totalDun;
      } else {
        this.form.total = 0;
        return 0;
      }
    },
  },
  data() {
    return {
      totalRowsCompetitions: 1,
      currentPageCompetitions: 1,
      perPageCompetitions: 25,
      pageOptionsRooms: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        { value: 100, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterCompetitions: "",
      filterOnCompetitions: [],
      filterIgnoredFields: ["projectStudents", "image", "Action"],
      fields: [
        {
          key: "index",
          label: "#",
          sortable: false,
        },
        {
          key: "image",
          label: "Зураг",
          sortable: false,
        },
        {
          key: "full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "Student",
          label: "Суралцагч",
          sortable: true,
        },
        {
          key: "Score",
          label: "Гүйцэтгэл",
          sortable: false,
        },
        {
          key: "description",
          label: "Тайлбар",
          sortable: false,
        },
        {
          key: "Photo",
          label: "Зургийн сан",
          sortable: false,
        },
        {
          key: "Action",
          label: "Үйлдэл",
          sortable: false,
        },
      ],
      planIsLoaded: true,
      itemAddMode: false,
      formItem: new Form({
        id: "",
        budget_item: "",
        budget_type_id: "",
        item_name: "",
        item_price: "",
        item_description: "",
      }),
      imgError: "",
      imgError1: "",
      imgError2: "",
      imgError3: "",
      locale: "de",
      labels: {
        de: {
          labelHours: "Цаг",
          labelMinutes: "Минут",
          labelSeconds: "Секунд",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Сонгосон",
          labelNoTimeSelected: "Цаг сонгох",
          labelCloseButton: "Хаах",
        },
      },
      niit: "",
      limitList: [],
      registeredList: [],
      totalLimit: "",
      totalRegistered: "",

      currentUserId: null,
      formModalTitle: "СХА нэмэх",
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      allProject: [],
      projectMentors: [],
      showMode: false,
      timeOptions: [
        { name: "Тогтмол", value: "Тогтмол" },
        { name: "Нэг удаа", value: "Нэг удаа" },
      ],
      scopeOptions: [
        { name: 0, text: "Бэлтгэл анги" },
        { name: 1, text: "1-р анги" },
        { name: 2, text: "2-р анги" },
        { name: 3, text: "3-р анги" },
        { name: 4, text: "4-р анги" },
        { name: 5, text: "5-р анги" },
        { name: 6, text: "6-р анги" },
        { name: 7, text: "7-р анги" },
        { name: 8, text: "8-р анги" },
        { name: 9, text: "9-р анги" },
        { name: 10, text: "10-р анги" },
        { name: 11, text: "11-р анги" },
        { name: 12, text: "12-р анги" },
      ],
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      quarterOptions: [],
      selectedMonths: null,
      selectedStatus: null,
      statusOptions: [
        { value: null, text: "Бүх төлөв" },
        { value: "registered", text: "Бүртгэгдсэн" },
        { value: "active", text: "Идэвхтэй" },
        { value: "check", text: "Идэвхгүй" },
        { value: "end", text: "Дууссан" },
      ],
      ownerId: null,
      editMode: false,
      form: new Form({
        id: null,
        day: 1,
        startTime: "14:15",
        endTime: "14:55",
        status: "",
        project_type: "СХА",
        quarter_id: 0,
        scopeList: [],
        name: "",
        image: "",
        projectImage: "",
        description: "",
        start_date: "",
        end_date: "",
        limit: "",
        registered: "",
        reg_close_date: "",
        created_at: "",
        classLimit1: 0,
        classLimit2: 0,
        classLimit3: 0,
        classLimit4: 0,
        classLimit5: 0,
        classLimit6: 0,
        classLimit7: 0,
        classLimit8: 0,
        classLimit9: 0,
        classLimit10: 0,
        classLimit11: 0,
        classLimit12: 0,
        what_know: "",
        what_understand: "",
        what_can: "",
        tools: "",
        safety: "",
        info: "",
      }),
      departments: [],
      modalTeachers: [],
      formMentor: new Form({
        id: null,
        mentor_type: "member",
        department_id: "",
        project_id: "",
        mentors: [],
      }),
      mentorTypes: [
        { name: "Гишүүн", value: "member" },
        { name: "Ахлах", value: "senior" },
      ],
      showStudentMode: false,
      editStudentMode: false,
      projectStudents: [],
      schoolClasses: [],
      modalStudents: [],
      formStudent: new Form({
        id: null,
        class_id: "",
        project_id: "",
        score: 100,
        description: "",
        students: [],
      }),
      showBudgetMode: false,
      editBudgetMode: false,
      budgetTypes: [],
      budgetItems: [],
      projectBudgets: [],
      formBudget: new Form({
        id: null,
        budget_type_id: "",
        project_id: "",
        name: "",
        total: "",
        confirmed_total: "",
        pieces: "",
        price: "",
        supplier: "",
        measure_unit: "",
        budget_images_1: "",
        budget_images_2: "",
        budget_images_3: "",
        zoriulalt: "",
        compare: 0,
        choose_1: "",
        choose_1_link: "",
        choose_2: "",
        choose_2_link: "",
        choose_3: "",
        choose_3_link: "",
        zartsuulsan: "",
        zartsuulsan_tailbar: "",
      }),
      beforeComments: [],
      scoreOption: [0, 100],
      score: 100,
      imageData: null,
      imgError: "",
      formImage: new Form({
        model: "project",
        model_id: null,
        student_id: null,
        image: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    onFilteredCompetitions(filteredItems) {
      this.totalRowsCompetitions = filteredItems.length;
      this.currentPageCompetitions = 1;
    },
    //#region СХА нэмэх
    getAllProject() {
      this.devIsLoaded = true;
      axios
        .post("/project_mentor/getTeacherCulturals", {
          project_type: "СХА",
          selectedMonths: this.selectedMonths,
          selectedDay: this.selectedDay,
          selectedStatus: this.selectedStatus,
        })
        .then((response) => {
          this.allProject = response.data.myProjects;
          this.teachers = response.data.teachers;
          this.quarterOptions = response.data.mySchoolsQuarters;
          this.currentUserId = response.data.currentUserId;
          this.sumCheck(this.allProject);
          this.devIsLoaded = false;
          this.currentQuarter = response.data.currentQuarter;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sumCheck(allProject) {
      this.niit = 0;
      this.limitList = [];
      this.registeredList = [];
      this.totalLimit = "";
      this.totalRegistered = "";

      allProject.forEach((element) => {
        this.limitList.push(parseInt(element.limit));
        this.registeredList.push(parseInt(element.registered));
      });

      this.totalLimit = this.limitList.reduce((a, b) => a + b, 0);
      this.totalRegistered = this.registeredList.reduce((a, b) => a + b, 0);
      this.niit = this.limitList.length;
    },
    //#endregion
    //#region Багш нэмэх хэсэг
    showProject(project) {
      this.action = "Төслийн мэдээллийг харах...";
      axios
        .get("/project_mentor/showProject/" + project.id)
        .then((response) => {
          this.projectMentors = response.data.projectMentors;
          this.form.reset();
          this.form.fill(project);
          this.ownerId = response.data.ownerId;
          this.showMode = !this.showMode;
          this.departments = response.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.teachers = response.data.teachers;
          this.$bvModal.show("modal-projectInfo");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    modalChangeTeacherList: function (e) {
      this.formMentor.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formMentor
        .get("/project_mentor/getTeacherList/" + this.formMentor.department_id)
        .then((response) => {
          this.modalTeachers = response.data.teachers;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addMentor() {
      this.formMentor.project_id = this.form.id;
      this.formMentor
        .post("/project_mentor/addMentor")
        .then((response) => {
          this.projectMentors = response.data.projectMentors;
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          this.formMentor.reset();
          this.modalTeachers = [];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editMentor(mentor) {
      this.editMode = true;
      this.formMentor.reset();
      this.formMentor.fill(mentor);
      this.formMentor.mentors = [];
    },
    updateMentor() {
      this.action = "Төслийн багшийн мэдээллийг шинэчилж байна...";
      this.formMentor
        .put("/project_mentor/updateMentor/" + this.formMentor.id)
        .then((response) => {
          console.log(response.data);
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formMentor.reset();
          this.editMode = false;
          this.modalTeachers = [];
          this.projectMentors = response.data.projectMentors;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeMentor(mentor) {
      Swal.fire({
        title: "Та төслөөс багш хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Багш хасаж байна...";
          this.formMentor
            .get("/project_mentor/removeMentor/" + mentor.id, {
              params: { project_id: mentor.project_id },
            })
            .then((response) => {
              this.projectMentors = response.data.projectMentors;
              this.formMentor.reset();
              this.modalTeachers = [];
              this.$toastr.s("Багш амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditMentor() {
      this.formMentor.reset();
      this.editMode = false;
    },
    //#endregion
    //#region Суралцагч нэмэх хэсэг========================================================
    showStudents(project) {
      this.action = "Төслийн мэдээллийг харах...";
      this.form.reset();
      this.form.fill(project);
      this.getStudents();
    },
    getStudents() {
      axios
        .get("/project_mentor/showStudents/" + this.form.id)
        .then((response) => {
          this.projectStudents = response.data.projectStudents;
          this.totalRowsCompetitions = this.projectStudents.length;
          this.ownerId = response.data.ownerId;
          this.showStudentMode = !this.showStudentMode;
          this.schoolClasses = response.data.schoolClasses;
          this.students = response.data.students;
          this.$bvModal.show("modal-project-student");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    modalChangeStudentList: function (e) {
      this.formStudent.department_id = e.target.value;
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      this.formStudent
        .get("/project_mentor/getStudentList/" + this.formStudent.department_id)
        .then((response) => {
          this.modalStudents = response.data.students;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    changeAllStudent() {
      Swal.fire({
        title: "Та бүх суралцагчийн ирцийг бүртгэх гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#41b882",
        denyButtonColor: "#ff7674",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Бүх суралцагчийн ирцийг бүртгэж байна...";
          axios
            .post("/project_mentor/changeProjectStudentScore/" + this.form.id, {
              score: this.score,
            })
            .then((response) => {
              this.projectStudents = response.data.projectStudents;
              this.$toastr.s(
                "Бүх суралцагчийн ирцийг амжилттай заслаа!",
                "Шинэчилсэн"
              );
              Fire.$emit("getMyProjects");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    addStudent() {
      this.formStudent.project_id = this.form.id;
      this.formStudent
        .post("/project_mentor/addStudent")
        .then((response) => {
          this.projectStudents = response.data.projectStudents;
          this.form.registered = response.data.currentReg;
          this.formStudent.reset();
          this.modalStudents = [];
          if (response.data.error == "error") {
            Swal.fire({
              icon: "error",
              title: "Алдаа гарлаа...",
              text: response.data.errorMsj,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Амжилттай",
              text: response.data.errorMsj,
            });
            Fire.$emit("getMyProjects");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showComment(project) {
      this.beforeComments = [];
      axios
        .post("/project_mentor/getChildComment/" + project.project_id, {
          child_id: project.student_id,
          type: "project",
        })
        .then((response) => {
          this.beforeComments = response.data.projectComment;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$bvModal.show("modal-project-comment");
    },
    editStudent(student) {
      this.editStudentMode = true;
      this.formStudent.reset();
      this.formStudent.fill(student);
      this.formStudent.students = [];
    },
    updateStudent() {
      this.action = "Суралцагчийн мэдээллийг шинэчилж байна...";
      this.formStudent
        .put("/project_mentor/updateStudent/" + this.formStudent.id)
        .then((response) => {
          this.$toastr.s("Багшийг амжилттай заслаа!", "Шинэчилсэн");
          this.formStudent.reset();
          this.editStudentMode = false;
          this.modalStudents = [];
          this.projectStudents = response.data.projectStudents;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeStudent(student) {
      Swal.fire({
        title: "Та суралцагч хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        // console.log(result.isConfirmed);
        if (result.isConfirmed) {
          this.action = "Суралцагч хасаж байна...";
          this.formStudent
            .get("/project_mentor/removeStudent/" + student.id, {
              params: { project_id: student.project_id },
            })
            .then((response) => {
              this.projectStudents = response.data.projectStudents;
              this.form.registered = response.data.$currentReg;
              this.$toastr.s("Суралцагч амжилттай устгалаа!", "Шинэчилсэн");
              Fire.$emit("getMyProjects");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditStudent() {
      this.formStudent.reset();
      this.editStudentMode = false;
    },
    //#endregion
    //#region ======================== Төсөв нэмэх хэсэг ================================
    getBudgetItemList: function (e) {
      this.projectBudgets.budget_type_id = e;
      this.action = "Бүтээгдэхүүний мэдээллийг шинэчилж байна...";
      axios
        .get(
          "/project_mentor/getBudgetItemList/" +
            this.projectBudgets.budget_type_id
        )
        .then((response) => {
          this.budgetItems = response.data.budgetItems;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showBudgets(project) {
      this.action = "Төсвийн мэдээллийг харах...";
      axios
        .get("/project_mentor/showBudgets/" + project.id)
        .then((response) => {
          this.projectBudgets = response.data.projectBudgets;
          this.form.reset();
          this.form.fill(project);
          this.ownerId = response.data.ownerId;
          this.showBudgetMode = !this.showBudgetMode;
          this.budgetTypes = response.data.budgetTypes;
          this.$bvModal.show("modal-project-budget");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addBudget() {
      this.formBudget.project_id = this.form.id;
      if (
        this.imgError1 == "" &&
        this.imgError2 == "" &&
        this.imgError3 == ""
      ) {
        this.formBudget
          .post("/project_mentor/addBudget")
          .then((response) => {
            this.projectBudgets = response.data.projectBudgets;
            this.$toastr.s("Төсөв амжилттай нэмлээ!", "Шинэчилсэн");
            this.formBudget.reset();
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    editBudget(budget) {
      this.editBudgetMode = true;
      this.formBudget.reset();
      this.formBudget.fill(budget);
    },
    updateBudget() {
      this.action = "Төсвийн мэдээллийг шинэчилж байна...";
      if (
        this.imgError1 == "" &&
        this.imgError2 == "" &&
        this.imgError3 == ""
      ) {
        this.formBudget
          .put("/project_mentor/updateBudget/" + this.formBudget.id)
          .then((response) => {
            this.$toastr.s("Төсөв амжилттай заслаа!", "Шинэчилсэн");
            this.projectBudgets = response.data.projectBudgets;
            this.formBudget.reset();
            this.editBudgetMode = false;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    getBudgetImg1(e) {
      let p1Img = e.target.files[0];
      if (!p1Img) {
        e.preventDefault();
        this.imgError1 = "";
        return;
      }

      if (p1Img.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError1 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage1(p1Img);
    },
    readImage1(p1Img) {
      let reader = new FileReader();
      reader.readAsDataURL(p1Img);
      reader.onload = (e) => {
        this.formBudget.budget_images_1 = e.target.result;
      };
      this.loaded = true;
    },
    getBudgetImg2(e) {
      let pImg2 = e.target.files[0];
      if (!pImg2) {
        e.preventDefault();
        this.imgError2 = "";
        return;
      }

      if (pImg2.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError2 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage2(pImg2);
    },
    readImage2(pImg2) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg2);
      reader.onload = (e) => {
        this.formBudget.budget_images_2 = e.target.result;
      };
      this.loaded = true;
    },
    getBudgetImg3(e) {
      let pImg3 = e.target.files[0];
      if (!pImg3) {
        e.preventDefault();
        this.imgError3 = "";
        return;
      }

      if (pImg3.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError3 = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage3(pImg3);
    },
    readImage3(pImg3) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg3);
      reader.onload = (e) => {
        this.formBudget.budget_images_3 = e.target.result;
      };
      this.loaded = true;
    },
    removeBudget(budget) {
      Swal.fire({
        title: "Та төсөв хасах гэж байна?",
        text: "Итгэлтэй байна уу?",
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Төсөв хасаж байна...";
          this.formBudget
            .get("/project_mentor/removeBudget/" + budget.id, {
              params: { project_id: budget.project_id },
            })
            .then((response) => {
              this.projectBudgets = response.data.projectBudgets;
              this.$toastr.s("Төсөв амжилттай устгалаа!", "Шинэчилсэн");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    cancelEditBudget() {
      this.formBudget.reset();
      this.editBudgetMode = false;
    },
    //#endregion
    //#region  ======================== Бүртгэгдсэн төлөвт шилжүүлэх ================================
    editStatus(project) {
      Swal.fire({
        title: "Төслийн төлвийг солих гэж байна?",
        text:
          'Та "' +
          project.name +
          '" төслийг БҮРТГЭГДСЭН төлөвт шилжүүлэх гэж байна. Ингэснээр менежер хянах боломжтой болно. Та итгэлтэй байна уу?',
        showDenyButton: true,
        icon: "warning",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#ff7674",
        denyButtonColor: "#41b882",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "Төсөв илгээж байна...";
          axios
            .put("/project_mentor/updateCheck/" + project.id)
            .then((response) => {
              this.$toastr.s("Төлөв амжилттай шилжүүллээ!", "Шинэчилсэн");
              Fire.$emit("getMyProjects");
            })
            .catch((errors) => {
              console.log(errors);
            });
        } else if (result.isDenied) {
          Swal.fire("Цуцаллаа.", "", "info");
        }
      });
    },
    getProjectImg(e) {
      console.log("Ажиллаж байна. getProjectImg");
      let pImg = e.target.files[0];
      if (!pImg) {
        e.preventDefault();
        this.imgError = "";
        return;
      }

      if (pImg.size > 1024 * 1024) {
        e.preventDefault();
        this.imgError = "1mb-аас бага файл оруулна уу!";
        return;
      }
      this.readImage(pImg);
    },
    readImage(pImg) {
      let reader = new FileReader();
      reader.readAsDataURL(pImg);
      reader.onload = (e) => {
        this.form.projectImage = e.target.result;
      };
      this.loaded = true;
    },
    toggleSelect() {
      console.log("filter department");
    },
    finishProject() {
      Swal.fire({
        title: "Та энэ СХА-г дуусгах гэж байна!",
        text:
          'Та "' +
          this.form.name +
          '" соёл хүмүүжлийн ажлыг дуусгах гэж байна. Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?',
        showDenyButton: true,
        icon: "success",
        confirmButtonText: `Тийм`,
        confirmButtonColor: "#41b882",
        denyButtonColor: "#ff7674",
        denyButtonText: `Үгүй`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.action = "СХА илгээж байна...";
          axios
            .put("/project_mentor/finishProject/" + this.form.id)
            .then((response) => {
              Fire.$emit("getMyProjects");
              this.$bvModal.hide("modal-project-student");
              this.form.reset();
              Swal.fire({
                icon: "success",
                title: "Амжилттай",
                text: "Соёл хүмүүжлийн ажлыг амжилттай дуусгалаа!",
              });
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    itemAddChangeMode() {
      this.formItem.reset();
      this.itemAddMode = !this.itemAddMode;
    },
    addItem() {
      this.formItem
        .post("/teacher/createItem")
        .then((res) => {
          this.itemAddMode = false;
          Fire.$emit("getBudgetItemList");
          this.$toastr.s("Бүтээгдэхүүн амжилттай нэмлээ!", "Шинэчилсэн");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelItem() {
      this.itemAddMode = false;
      this.formItem.reset();
    },
    //#endregion
    showImageUploader(student) {
      this.imageData = null;
      this.formImage.reset();
      this.formImage.model_id = student.project_id;
      this.formImage.student_id = student.student_id;
      this.$bvModal.show("modal-image");
    },
    setImg(e) {
      this.imgError = "";
      let img = e.target.files[0];
      if (!img) {
        e.preventDefault();
        return;
      }

      if (img.size > 2024 * 2024) {
        e.preventDefault();
        this.imgError = "4mb-аас бага файл оруулна уу!";
        return;
      }
      this.reamImage(img);
    },
    reamImage(img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.formImage.image = e.target.result;
      };
    },
    createImage() {
      this.formImage
        .post("/teacher/uploadImage")
        .then((response) => {
          if (response.data.success == "yes") {
            this.$toastr.s("Зураг амжилттай нэмлээ", "Амжилттай");
            this.cancelUploadImg();
            this.getStudents();
          } else this.$toastr.e("Зураг нэмэх үед алдаа гарлаа.", "Анхаар!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteImage(image) {
      //   console.log(parentmeet);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Та суралцагчийн зургийг устгах гэж байна!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteImage/" + image.id)
            .then((res) => {
              this.$toastr.s("Зураг амжилттай устгалаа", "Амжилттай");
              this.getStudents();
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    cancelUploadImg() {
      this.imageData = null;
      this.formImage.reset();
      this.$bvModal.hide("modal-image");
    },
  },
  created() {
    this.getAllProject();
    Fire.$on("getMyProjects", () => {
      this.getAllProject();
    });
  },
};
</script>

<style scoped>
.groupForm {
  background-color: #4da985;
  color: white;
}
</style>
