<template>
  <div>
    <!-- /.col -->
    <b-button @click.prevent="showLessonData()" block variant="outline-primary"
      >Сурагчдийн ирцийг хичээлээр эхлэж харах</b-button
    >
    <div class="card" v-if="viewLessonChart">
      <div class="card-body table-responsive bodyOfYear">
        <multiselect
          v-model="selectedYear"
          deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
          track-by="id"
          label="id"
          :custom-label="customYearName"
          placeholder="Хичээлийн жил сонгоно уу?"
          :options="years"
          :searchable="false"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            ><strong
              >{{ option.start_date | dateYear }}-{{
                option.finish_date | dateYear
              }}
              - хичээлийн жил
            </strong></template
          >
        </multiselect>
        <b-overlay :show="show" rounded="sm">
          <div class="row mt-3">
            <div class="col-12 col-sm-12">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Нийт сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLesson"
                        :series="seriesLesson"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Бага сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonP"
                        :series="seriesLessonP"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Дунд сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonM"
                        :series="seriesLessonM"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div id="chart">
                <div class="info-box bg-light">
                  <div class="info-box-content">
                    <span
                      class="info-box-text text-center text-muted"
                      v-if="viewChart"
                      >Ахлах сургууль(Жилээр)</span
                    ><span
                      class="info-box-number text-muted mb-0"
                      v-if="viewChart"
                    >
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptionsLessonH"
                        :series="seriesLessonH"
                      ></apexchart>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <multiselect
        v-model="selectedQuarter"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="id"
        label="name"
        placeholder="Хичээлийн улирал сонгоно уу?"
        :options="quarters"
        :searchable="false"
        :allow-empty="false"
        v-if="viewChart"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.name }}
            - улирал
          </strong></template
        >
      </multiselect>
      <b-overlay :show="showQuarter" rounded="sm">
        <div class="row mt-3">
          <div class="col-12 col-sm-12" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Нийт сургууль(Улирлаар)</span
                  ><span class="info-box-number text-muted mb-0">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsLessonQ"
                      :series="seriesLessonQ"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Бага сургууль(Улирлаар)</span
                  ><span class="info-box-number text-muted mb-0">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsLessonQP"
                      :series="seriesLessonQP"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Дунд сургууль(Улирлаар)</span
                  ><span class="info-box-number text-muted mb-0">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsLessonQM"
                      :series="seriesLessonQM"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4" v-if="viewQuarterChart">
            <div id="chart">
              <div class="info-box bg-light">
                <div class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Ахлах сургууль(Улирлаар)</span
                  ><span class="info-box-number text-muted mb-0">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsLessonQH"
                      :series="seriesLessonQH"
                    ></apexchart>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-button
        @click.prevent="selectLessonData()"
        v-if="viewQuarterChart"
        block
        variant="outline-primary"
        >Хичээл сонгох</b-button
      >
      <div class="row mt-3">
        <div class="col-12 col-sm-4" v-if="viewSelectlessonChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedLessonP"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Хичээл сонгоно уу?"
                  track-by="id"
                  label="subject_name"
                  :options="pLessonNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.subject_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewLessonChartP"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassP"
                    :series="seriesClassP"
                  ></apexchart>
                </span>
                <multiselect
                  v-model="selectedClassStudP"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="pClassFullName"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewLessonChartP"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassStudChartP"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassStudP"
                    :series="seriesClassStudP"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4" v-if="viewSelectlessonChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedLessonM"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Хичээл сонгоно уу?"
                  track-by="course_id"
                  label="subject_name"
                  :options="mLessonNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.subject_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewLessonChartM"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassM"
                    :series="seriesClassM"
                  ></apexchart>
                </span>
                <multiselect
                  v-model="selectedClassStudM"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="mClassFullName"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewLessonChartM"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassStudChartM"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassStudM"
                    :series="seriesClassStudM"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4" v-if="viewSelectlessonChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedLessonH"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Хичээл сонгоно уу?"
                  track-by="course_id"
                  label="subject_name"
                  :options="hLessonNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.subject_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewLessonChartH"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassH"
                    :series="seriesClassH"
                  ></apexchart>
                </span>
                <multiselect
                  v-model="selectedClassStudH"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="hClassFullName"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewLessonChartH"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassStudChartH"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassStudH"
                    :series="seriesClassStudH"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button
        @click.prevent="selectClassData()"
        v-if="viewQuarterChart"
        block
        variant="outline-primary"
        >Анги сонгох</b-button
      >
      <div class="row mt-3">
        <div class="col-12 col-sm-4" v-if="viewSelectClassChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedClassP"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="pClassNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassChartP"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassLessonP"
                    :series="seriesClassLessonP"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4" v-if="viewSelectClassChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedClassM"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="mClassNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassChartM"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassLessonM"
                    :series="seriesClassLessonM"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4" v-if="viewSelectClassChart">
          <div id="chart">
            <div class="info-box bg-light">
              <div class="info-box-content">
                <multiselect
                  v-model="selectedClassH"
                  deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                  placeholder="Анги сонгоно уу?"
                  track-by="id"
                  label="full_name"
                  :options="hClassNames"
                  :searchable="false"
                  :allow-empty="false"
                  v-if="viewQuarterChart"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.full_name }} </strong></template
                  >
                </multiselect>
                <span
                  class="info-box-number text-muted mb-0"
                  v-if="viewClassChartH"
                >
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptionsClassLessonH"
                    :series="seriesClassLessonH"
                  ></apexchart>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card-header -->
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudentAttStat from "./everyStudentAttStat.vue";
export default {
  components: {
    Multiselect,
    everyStudentAttStat,
  },
  data() {
    return {
      show: false,
      showQuarter: false,
      viewLessonChart: false,
      viewSelectlessonChart: false,
      viewSelectClassChart: false,
      viewChart: false,
      viewQuarterChart: false,
      viewLessonChartP: false,
      viewLessonChartM: false,
      viewLessonChartH: false,
      viewClassStudChartP: false,
      viewClassStudChartM: false,
      viewClassStudChartH: false,
      viewClassChartP: false,
      viewClassChartM: false,
      viewClassChartH: false,
      selectedYear: "",
      selectedQuarter: "",
      selectedLessonP: "",
      selectedLessonM: "",
      selectedLessonH: "",
      selectedClassStudP: "",
      selectedClassStudM: "",
      selectedClassStudH: "",
      selectedClassP: "",
      selectedClassM: "",
      selectedClassH: "",
      years: [],
      quarters: [],
      pLessonNames: [],
      mLessonNames: [],
      hLessonNames: [],
      pClassNames: [],
      mClassNames: [],
      hClassNames: [],
      pClassFullName: [],
      mClassFullName: [],
      hClassFullName: [],
      chartOptionsLesson: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesLesson: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      //улирал
      chartOptionsLessonQ: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonQP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonQM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsLessonQH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesLessonQ: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonQP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonQM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesLessonQH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      //Select lesson
      seriesClassP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsClassP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      seriesClassStudP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassStudM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassStudH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsClassStudP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassStudM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassStudH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },

      // Select class
      seriesClassLessonP: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassLessonM: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      seriesClassLessonH: [
        {
          name: "Ирсэн",
          data: [],
        },
        {
          name: "Тасалсан",
          data: [],
        },
        {
          name: "Өвчтэй",
          data: [],
        },
        {
          name: "Чөлөөтэй",
          data: [],
        },
        {
          name: "Хоцорсон",
          data: [],
        },
      ],
      chartOptionsClassLessonP: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassLessonM: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      chartOptionsClassLessonH: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "primaryClass",
          categories: [],
        },
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      this.show = true;
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewChart = false;
        axios
          .post("/manager/getLessonAttendance", {
            selectedYear: this.selectedYear.id,
          })
          .then((response) => {
            this.seriesLesson[0].data = response.data.lessonIrsenList;
            this.seriesLesson[1].data = response.data.lessonTasalsanList;
            this.seriesLesson[2].data = response.data.lessonUvchteiList;
            this.seriesLesson[3].data = response.data.lessonChuluuteiList;
            this.seriesLesson[4].data = response.data.lessonHotsorsonList;
            this.chartOptionsLesson.xaxis.categories =
              response.data.lessonNames;

            this.seriesLessonP[0].data = response.data.lessonIrsenListP;
            this.seriesLessonP[1].data = response.data.lessonTasalsanListP;
            this.seriesLessonP[2].data = response.data.lessonUvchteiListP;
            this.seriesLessonP[3].data = response.data.lessonChuluuteiListP;
            this.seriesLessonP[4].data = response.data.lessonHotsorsonListP;
            this.chartOptionsLessonP.xaxis.categories =
              response.data.lessonNamesP;

            this.seriesLessonM[0].data = response.data.lessonIrsenListM;
            this.seriesLessonM[1].data = response.data.lessonTasalsanListM;
            this.seriesLessonM[2].data = response.data.lessonUvchteiListM;
            this.seriesLessonM[3].data = response.data.lessonChuluuteiListM;
            this.seriesLessonM[4].data = response.data.lessonHotsorsonListM;
            this.chartOptionsLessonM.xaxis.categories =
              response.data.lessonNamesM;

            this.seriesLessonH[0].data = response.data.lessonIrsenListH;
            this.seriesLessonH[1].data = response.data.lessonTasalsanListH;
            this.seriesLessonH[2].data = response.data.lessonUvchteiListH;
            this.seriesLessonH[3].data = response.data.lessonChuluuteiListH;
            this.seriesLessonH[4].data = response.data.lessonHotsorsonListH;
            this.chartOptionsLessonH.xaxis.categories =
              response.data.lessonNamesH;
            this.viewChart = true;
            this.show = false;
            this.quarters = response.data.getMySchoolQuarters;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      }
    },
    selectedQuarter: function (newVal, oldVal) {
      this.school_quarter_id = "";
      this.showQuarter = true;
      if (newVal != "") {
        this.viewQuarterChart = false;
        axios
          .post("/manager/getLessonQuarterAttendance", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
          })
          .then((response) => {
            this.seriesLessonQ[0].data = response.data.lessonIrsenList;
            this.seriesLessonQ[1].data = response.data.lessonTasalsanList;
            this.seriesLessonQ[2].data = response.data.lessonUvchteiList;
            this.seriesLessonQ[3].data = response.data.lessonChuluuteiList;
            this.seriesLessonQ[4].data = response.data.lessonHotsorsonList;
            this.pLessonNames = response.data.lessonListP;
            this.mLessonNames = response.data.lessonListM;
            this.hLessonNames = response.data.lessonListH;
            this.pClassNames = response.data.primaryClassLevel;
            this.mClassNames = response.data.middleClassLevel;
            this.hClassNames = response.data.highClassLevel;
            this.chartOptionsLessonQ.xaxis.categories =
              response.data.lessonNames;

            this.seriesLessonQP[0].data = response.data.lessonIrsenListP;
            this.seriesLessonQP[1].data = response.data.lessonTasalsanListP;
            this.seriesLessonQP[2].data = response.data.lessonUvchteiListP;
            this.seriesLessonQP[3].data = response.data.lessonChuluuteiListP;
            this.seriesLessonQP[4].data = response.data.lessonHotsorsonListP;
            this.chartOptionsLessonQP.xaxis.categories =
              response.data.lessonNamesP;

            this.seriesLessonQM[0].data = response.data.lessonIrsenListM;
            this.seriesLessonQM[1].data = response.data.lessonTasalsanListM;
            this.seriesLessonQM[2].data = response.data.lessonUvchteiListM;
            this.seriesLessonQM[3].data = response.data.lessonChuluuteiListM;
            this.seriesLessonQM[4].data = response.data.lessonHotsorsonListM;
            this.chartOptionsLessonQM.xaxis.categories =
              response.data.lessonNamesM;

            this.seriesLessonQH[0].data = response.data.lessonIrsenListH;
            this.seriesLessonQH[1].data = response.data.lessonTasalsanListH;
            this.seriesLessonQH[2].data = response.data.lessonUvchteiListH;
            this.seriesLessonQH[3].data = response.data.lessonChuluuteiListH;
            this.seriesLessonQH[4].data = response.data.lessonHotsorsonListH;
            this.chartOptionsLessonQH.xaxis.categories =
              response.data.lessonNamesH;
            this.viewQuarterChart = true;
            this.showQuarter = false;
          })
          .catch((errors) => {
            //console.log(errors);
          });
      }
    },
    selectedLessonP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartP = false;
        axios
          .post("/manager/getLessonClassAttInfoP", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: newVal.id,
          })
          .then((response) => {
            this.seriesClassP[0].data = response.data.pIrsenList;
            this.seriesClassP[1].data = response.data.pTasalsanList;
            this.seriesClassP[2].data = response.data.pUvchteiList;
            this.seriesClassP[3].data = response.data.pChuluuteiList;
            this.seriesClassP[4].data = response.data.pHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassP.xaxis.categories =
              response.data.pClassNames;
            this.pClassFullName = response.data.primaryClassLevel;
            this.viewLessonChartP = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedLessonM: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartM = false;
        axios
          .post("/manager/getLessonClassAttInfoM", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: newVal.id,
          })
          .then((response) => {
            this.seriesClassM[0].data = response.data.mIrsenList;
            this.seriesClassM[1].data = response.data.mTasalsanList;
            this.seriesClassM[2].data = response.data.mUvchteiList;
            this.seriesClassM[3].data = response.data.mChuluuteiList;
            this.seriesClassM[4].data = response.data.mHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassM.xaxis.categories =
              response.data.mClassNames;
            this.mClassFullName = response.data.middleClassLevel;
            this.viewLessonChartM = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedLessonH: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewLessonChartH = false;
        axios
          .post("/manager/getLessonClassAttInfoH", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: newVal.id,
          })
          .then((response) => {
            this.seriesClassH[0].data = response.data.hIrsenList;
            this.seriesClassH[1].data = response.data.hTasalsanList;
            this.seriesClassH[2].data = response.data.hUvchteiList;
            this.seriesClassH[3].data = response.data.hChuluuteiList;
            this.seriesClassH[4].data = response.data.hHotsorsonList;

            // this.pHonthNames = response.data.monthList;
            this.chartOptionsClassH.xaxis.categories =
              response.data.hClassNames;
            this.hClassFullName = response.data.highClassLevel;
            this.viewLessonChartH = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassStudP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassStudChartP = false;
        axios
          .post("/manager/getStudAttInfoP", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: this.selectedLessonP.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassStudP[0].data = response.data.pIrsenList;
            this.seriesClassStudP[1].data = response.data.pTasalsanList;
            this.seriesClassStudP[2].data = response.data.pUvchteiList;
            this.seriesClassStudP[3].data = response.data.pChuluuteiList;
            this.seriesClassStudP[4].data = response.data.pHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassStudP.xaxis.categories =
              response.data.pStudNames;
            this.viewClassStudChartP = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassStudM: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassStudChartM = false;
        axios
          .post("/manager/getStudAttInfoM", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: this.selectedLessonM.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassStudM[0].data = response.data.mIrsenList;
            this.seriesClassStudM[1].data = response.data.mTasalsanList;
            this.seriesClassStudM[2].data = response.data.mUvchteiList;
            this.seriesClassStudM[3].data = response.data.mChuluuteiList;
            this.seriesClassStudM[4].data = response.data.mHotsorsonList;

            // this.mMonthNames = response.data.monthList;
            this.chartOptionsClassStudM.xaxis.categories =
              response.data.mStudNames;
            this.viewClassStudChartM = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassStudH: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassStudChartH = false;
        axios
          .post("/manager/getStudAttInfoH", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedLessonId: this.selectedLessonH.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassStudH[0].data = response.data.hIrsenList;
            this.seriesClassStudH[1].data = response.data.hTasalsanList;
            this.seriesClassStudH[2].data = response.data.hUvchteiList;
            this.seriesClassStudH[3].data = response.data.hChuluuteiList;
            this.seriesClassStudH[4].data = response.data.hHotsorsonList;

            // this.hHonthNames = response.data.honthList;
            this.chartOptionsClassStudH.xaxis.categories =
              response.data.hStudNames;
            this.viewClassStudChartH = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassP: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartP = false;
        axios
          .post("/manager/getClassAttInfoP", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassLessonP[0].data = response.data.pIrsenList;
            this.seriesClassLessonP[1].data = response.data.pTasalsanList;
            this.seriesClassLessonP[2].data = response.data.pUvchteiList;
            this.seriesClassLessonP[3].data = response.data.pChuluuteiList;
            this.seriesClassLessonP[4].data = response.data.pHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassLessonP.xaxis.categories =
              response.data.pLessonNames;
            this.viewClassChartP = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassM: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartM = false;
        axios
          .post("/manager/getClassAttInfoM", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassLessonM[0].data = response.data.mIrsenList;
            this.seriesClassLessonM[1].data = response.data.mTasalsanList;
            this.seriesClassLessonM[2].data = response.data.mUvchteiList;
            this.seriesClassLessonM[3].data = response.data.mChuluuteiList;
            this.seriesClassLessonM[4].data = response.data.mHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassLessonM.xaxis.categories =
              response.data.mLessonNames;
            this.viewClassChartM = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
    selectedClassH: function (newVal, oldVal) {
      this.school_quarter_id = "";
      if (newVal != "") {
        this.viewClassChartH = false;
        axios
          .post("/manager/getClassAttInfoH", {
            selectedYear: this.selectedYear.id,
            selectedQuarter: this.selectedQuarter.id,
            selectedClassId: newVal.id,
          })
          .then((response) => {
            this.seriesClassLessonH[0].data = response.data.hIrsenList;
            this.seriesClassLessonH[1].data = response.data.hTasalsanList;
            this.seriesClassLessonH[2].data = response.data.hUvchteiList;
            this.seriesClassLessonH[3].data = response.data.hChuluuteiList;
            this.seriesClassLessonH[4].data = response.data.hHotsorsonList;

            // this.pMonthNames = response.data.monthList;
            this.chartOptionsClassLessonH.xaxis.categories =
              response.data.hLessonNames;
            this.viewClassChartH = true;
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
  },

  methods: {
    showLessonData() {
      this.viewLessonChart = !this.viewLessonChart;
    },
    selectLessonData() {
      this.viewSelectlessonChart = !this.viewSelectlessonChart;
    },
    selectClassData() {
      this.viewSelectClassChart = !this.viewSelectClassChart;
    },
    allYears() {
      axios
        .get("/manager/getYears")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    remove(attendance_id, tooluur) {
      //   this.passPropsMyLessonDate.indexOf(attendance_id, 1);
      //   console.log(this.passPropsMyLessonDate);
      // console.log(attendance_id, tooluur);
      this.passPropsMyLessonDate.splice(tooluur, 1);
      //   this.$delete(this.passPropsMyLessonDate, attendance_id);
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },

  created() {
    this.allYears();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bodyOfYear {
  min-height: 200px;
}
</style>
