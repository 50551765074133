<template>
  <div class="row pt-3">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">
          Багшийн хэрэгжүүлсэн ээлжит үйл ажиллагааны үнэлгээ
        </h3>
        <div class="card-tools">
          <ul class="pagination pagination-sm float-right">
            <div v-if="!editMode">
              <create-lesson
                :editMode="editMode"
                :form="form"
                :mySchoolQuarters="mySchoolQuarters"
                :schoolDepartments="schoolDepartments"
                :schoolClasses="schoolClasses"
                :schoolTeachers="schoolTeachers"
                :schoolSubjects="schoolSubjects"
                @getLoadTeacherEvaluations="getLoadTeacherEvaluations = $event"
                @getWasEditMode="changeEdit($event)"
              ></create-lesson>
            </div>
            <div v-else>
              <create-lesson
                :editMode="editMode"
                :form="form"
                :mySchoolQuarters="mySchoolQuarters"
                :schoolDepartments="schoolDepartments"
                :schoolClasses="schoolClasses"
                :schoolTeachers="schoolTeachers"
                :schoolSubjects="schoolSubjects"
                @getLoadTeacherEvaluations="getLoadTeacherEvaluations = $event"
                @getWasEditMode="changeEdit($event)"
              ></create-lesson>
            </div>
          </ul>
          <!-- <search-evaluation
            @getWasSearchTeacherEvaluations="changeTeacherEvaluations = $event"
          ></search-evaluation> -->
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body table-responsive">
        <div class="row">
          <div class="col-lg-12" style="display: inline-flex">
            <div class="col-sm-3">
              <multiselect
                v-model="school_year_id"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="id"
                label="id"
                :custom-label="customYearName"
                placeholder="Хичээлийн жил сонгоно уу?"
                :options="years"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.start_date | dateYear }}-{{
                      option.finish_date | dateYear
                    }}
                    - хичээлийн жил
                  </strong></template
                >
              </multiselect>
            </div>
            <div class="col-sm-4">
              <multiselect
                v-model="school_manager_id"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="user_id"
                label="user_id"
                :custom-label="customTeacherName"
                placeholder="Ажиглалт хийсэн багш, удирдлага сонгоно уу?"
                :options="schoolEmployees"
                :searchable="true"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.employee_teacher_last_name[0].toUpperCase() }}.
                    {{ option.employee_teacher_name }}
                  </strong></template
                >
              </multiselect>
            </div>
            <div class="col-sm-3">
              <multiselect
                v-model="school_teacher_id"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                track-by="user_id"
                label="user_id"
                :custom-label="customTeacherName"
                placeholder="Багш сонгоно уу?"
                :options="schoolTeachers"
                :searchable="true"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.employee_teacher_last_name[0].toUpperCase() }}.
                    {{ option.employee_teacher_name }}
                  </strong></template
                >
              </multiselect>
            </div>
            <div class="col-sm-2">
              <b-button
                variant="outline-info"
                @click.prevent="getLessonEvaluationDatas()"
                >Дуудах</b-button
              >
            </div>
          </div>
          <!-- Эндээс b-table эхлүүлнэ. -->
          <b-overlay :show="teacherEvaluationsLoaded" rounded="sm">
            <b-row>
              <b-col lg="12" class="my-1">
                <b-form-group
                  label="Нийт: "
                  label-for="allTE"
                  label-cols-sm="2"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    {{ changeTeacherEvaluations.length }} хичээлийн ажиглалт,
                    үнэлгээ байна.
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label="Нэг хуудсанд"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageTE"
                    :options="pageOptionsTE"
                    size="sm"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label="Хайх утга!"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterTE"
                      type="search"
                      placeholder="Хайлт хийх утгаа оруулна уу!"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filterTE" @click="filterTE = ''"
                        >Цэвэрлэх</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-pagination
              v-model="currentPageTE"
              :total-rows="totalRowsTE"
              :per-page="perPageTE"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <b-table
              hover
              :items="changeTeacherEvaluations"
              :fields="fieldsTE"
              :current-page="currentPageTE"
              :per-page="perPageTE"
              :filter="filterTE"
              :filter-ignored-fields="filterIgnoredFieldsTE"
              @filtered="onFilteredTE"
              :filter-included-fields="filterOnTE"
              empty-text="Одоогоор үнэлгээ хийгдээгүй байна."
            >
              <!-- :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" -->
              <template #empty="scope">
                <p class="text-center text-muted h5 mt-5">
                  <b-icon icon="inboxes"></b-icon>
                </p>
                <p class="text-center text-muted font-italic">
                  {{ scope.emptyText }}
                </p>
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(employees_teacher_name)="data">
                {{ data.item.employees_teacher_last_name[0] }}.{{
                  data.item.employees_teacher_name
                }}
              </template>
              <template #cell(department_code)="data">
                {{ data.item.department_code }}
              </template>
              <template #cell(evaluation_worker_name)="data">
                {{ data.item.evaluation_worker_name }}
              </template>
              <template #cell(school_class_full_name)="data">
                {{ data.item.school_class_full_name }}
              </template>
              <template #cell(subject_name)="data">
                {{ data.item.subject_name }}
              </template>
              <template #cell(lesson_topic)="data">
                {{ data.item.lesson_topic }}
              </template>
              <template #cell(lesson_ognoo)="data">
                {{ data.item.lesson_ognoo }}
              </template>
              <template #cell(start_section)="data">
                <div class="progress progress-xs">
                  <div
                    class="progress-bar progress-bar-warning"
                    :style="{
                      width: data.item.averageShalBegProcess + '%',
                    }"
                  ></div>
                </div>
                <span class="badge bg-warning"
                  >{{ data.item.averageShalBegProcess }}%</span
                >
              </template>
              <template #cell(mid_section)="data">
                <div class="progress progress-xs">
                  <div
                    class="progress-bar progress-bar-success"
                    :style="{
                      width: data.item.averageShalMidProcess + '%',
                    }"
                  ></div>
                </div>
                <span class="badge bg-success"
                  >{{ data.item.averageShalMidProcess }}%</span
                >
              </template>
              <template #cell(end_section)="data">
                <div class="progress progress-xs">
                  <div
                    class="progress-bar progress-bar-danger"
                    :style="{
                      width: data.item.averageShalEndProcess + '%',
                    }"
                  ></div>
                </div>
                <span class="badge bg-danger"
                  >{{ data.item.averageShalEndProcess }}%</span
                >
              </template>
              <template #cell(gen_evaluation)="data">
                <div class="progress progress-xs">
                  <div
                    class="progress-bar progress-bar-info"
                    :style="{
                      width: data.item.averageShalFullProcess + '%',
                    }"
                  ></div>
                </div>
                <span class="badge bg-info"
                  >{{ data.item.averageShalFullProcess }}%</span
                >
              </template>
              <template #cell(actions)="data">
                <a
                  href="#"
                  class="badge bg-purple"
                  @click="viewLessonEvaluation(data.item)"
                >
                  <i class="fas fa-chart-bar"></i>
                </a>
                <a
                  href="#"
                  class="badge bg-orange"
                  @click="
                    showSetSkill(
                      data.item.employees_teacher_id,
                      data.item.employees_teacher_last_name[0] +
                        '.' +
                        data.item.employees_teacher_name
                    )
                  "
                  v-b-tooltip="'Үнэлгээ оруулах'"
                >
                  <b-icon icon="award"></b-icon>
                </a>
                <a
                  href="#"
                  class="badge bg-warning"
                  @click="editLessonEvaluation(data.item)"
                  ><i class="fas fa-edit"></i
                ></a>

                <a
                  href="#"
                  class="badge bg-danger"
                  @click="deleteLessonEvaluation(data.item)"
                  ><i class="fas fa-trash-alt"></i
                ></a>
              </template>
            </b-table>
          </b-overlay>
        </div>

        <b-modal
          size="xl"
          id="modal-teacher-lesson-evaluations"
          title="Ээлжит хичээлийн үнэлгээг харьцуулж харах"
          class="modal fade"
          hide-footer
        >
          <div class="modal-body">
            <div class="row">
              <div class="card col-lg-12 border-success">
                <compare-teacher-lesson-evaluation
                  v-if="compareTE"
                  :passTeachersEvaluationData="passTeachersEvaluationData"
                  :schoolEmployees="schoolEmployees"
                >
                </compare-teacher-lesson-evaluation>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <!-- /.card-body -->
    </div>
    <b-modal
      size="lg"
      id="modal-result-skill"
      title="Багшийн ээлжит хичээлийн бүх үнэлгээний дундаж оноог чадварт хадгалах"
      class="modal fade"
      hide-footer
    >
      <div class="modal-body">
        <register-exam :userDatas="skillUsers"></register-exam>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import createLesson from "./createLesson/createLesson.vue";
// import searchEvaluation from "./searchLessonEvaluation/searchEvaluation.vue";
import compareteacherLessonEvaluation from "./CompareTEva/CompareteacherteacherEvaluation.vue";
import RegisterExam from "../SatisfactionForm/registerExam.vue";
export default {
  data() {
    return {
      skillUsers: [],
      school_year_id: "",
      school_manager_id: "",
      school_teacher_id: "",
      years: [],
      editMode: false,
      compareTE: false,
      teacherEvaluations: [],
      changeTeacherEvaluations: [],
      schoolDepartments: [],
      schoolClasses: [],
      schoolTeachers: [],
      schoolEmployees: [],
      schoolSubjects: [],
      mySchoolQuarters: [],
      form: new Form({
        id: null,
        quarter_id: null,
        department_id: null,
        school_class_id: null,
        teacher_id: null,
        subject_id: null,
        lesson_time: null,
        lesson_topic: null,
        lesson_ognoo: null,
        shalles1: 0,
        shalles1_pos_note: null,
        shalles1_neg_note: null,
        shalles2: 0,
        shalles2_pos_note: null,
        shalles2_neg_note: null,
        shalles3: 0,
        shalles3_pos_note: null,
        shalles3_neg_note: null,
        shalles4: 0,
        shalles4_pos_note: null,
        shalles4_neg_note: null,
        shalles5: 0,
        shalles5_pos_note: null,
        shalles5_neg_note: null,
        shalles6: 0,
        shalles6_pos_note: null,
        shalles6_neg_note: null,
        shalles7: 0,
        shalles7_pos_note: null,
        shalles7_neg_note: null,
        shalles8: 0,
        shalles8_pos_note: null,
        shalles8_neg_note: null,
        shalles9: 0,
        shalles9_pos_note: null,
        shalles9_neg_note: null,
        shalles10: 0,
        shalles10_pos_note: null,
        shalles10_neg_note: null,
        some_note: null,
      }),
      //Compare evaluations
      passTeachersEvaluationData: [],

      //Өөрчлөлт
      satYesIsLoaded: true,

      teacherEvaluationsLoaded: true,
      filterTeacherEvaluation: null,
      totalRowsTE: 1,
      currentPageTE: 1,
      perPageTE: 50,
      pageOptionsTE: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      filterIgnoredFieldsTE: [
        // "course_hw_stud_hw_parent_comment",
        // "course_hw_stud_hw_student_comment",
        // "course_hw_teacher_explain",
      ],
      filterTE: null,
      filterOnTE: [],
      fieldsTE: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "employees_teacher_name",
          label: "Багш",
          sortable: true,
        },
        {
          key: "department_code",
          label: "БНБ",
          sortable: true,
        },
        {
          key: "evaluation_worker_name",
          label: "Ажиглалт хийсэн багш",
          sortable: true,
        },
        {
          key: "school_class_full_name",
          label: "Анги",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Хичээл",
          sortable: true,
        },
        {
          key: "lesson_topic",
          label: "Сэдэв",
          sortable: true,
        },
        {
          key: "lesson_ognoo",
          label: "Огноо(Ажигласан)",
          sortable: true,
        },
        {
          key: "start_section",
          label: "Эхлэл хэсэг",
          sortable: true,
        },
        {
          key: "mid_section",
          label: "Өрнөл хэсэг",
          sortable: true,
        },
        {
          key: "end_section",
          label: "Төгсгөл хэсэг",
          sortable: true,
        },
        {
          key: "gen_evaluation",
          label: "Ерөнхий үнэлгээ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    // school_year_id: function (newVal, oldVal) {
    //   this.getLessonEvaluationDatas();
    // },
  },
  components: {
    Multiselect,
    createLesson,
    // searchEvaluation,
    compareTeacherLessonEvaluation: compareteacherLessonEvaluation,
    RegisterExam,
  },
  methods: {
    showSetSkill(userId, userName) {
      this.skillUsers = [];
      let evalPercent = 0;
      axios
        .post("/manager/getTeacherAvgEval/", {
          yearId: this.school_year_id.id,
          teacherId: userId,
        })
        .then((response) => {
          evalPercent = response.data.evalPercent;
          if (userId)
            this.skillUsers.push({
              userId: userId,
              userName: userName,
              value: evalPercent,
            });
          this.$bvModal.show("modal-result-skill");
        })
        .catch((errors) => {
          console.log(errors);
          return 0;
        });
    },
    onFilteredTE(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsTE = filteredItems.length;
      this.currentPageTE = 1;
    },
    changeEdit(event) {
      //   console.log(event);
      this.editMode = event;
    },
    calculateLessonBegun(shalles1, shalles2, shalles3) {
      let shalles11 = parseFloat(shalles1);
      let shalles22 = parseFloat(shalles2);
      let shalles33 = parseFloat(shalles3);
      let aveBegun = ((shalles11 + shalles22 + shalles33) / 8) * 100;
      return aveBegun.toFixed(1);
    },
    calculateLessonMid(shalles4, shalles5, shalles6, shalles7, shalles8) {
      let shalles44 = parseFloat(shalles4);
      let shalles55 = parseFloat(shalles5);
      let shalles66 = parseFloat(shalles6);
      let shalles77 = parseFloat(shalles7);
      let shalles88 = parseFloat(shalles8);
      let aveMid =
        ((shalles44 + shalles55 + shalles66 + shalles77 + shalles88) / 16) *
        100;
      return aveMid.toFixed(1);
    },
    calculateLessonEnd(shalles9, shalles10) {
      let shalles99 = parseFloat(shalles9);
      let shalles100 = parseFloat(shalles10);
      let aveEnd = ((shalles99 + shalles100) / 6) * 100;

      return aveEnd.toFixed(1);
    },

    calculateLessonFull(
      shalles1,
      shalles2,
      shalles3,
      shalles4,
      shalles5,
      shalles6,
      shalles7,
      shalles8,
      shalles9,
      shalles10
    ) {
      let shalles11 = parseFloat(shalles1);
      let shalles22 = parseFloat(shalles2);
      let shalles33 = parseFloat(shalles3);
      let shalles44 = parseFloat(shalles4);
      let shalles55 = parseFloat(shalles5);
      let shalles66 = parseFloat(shalles6);
      let shalles77 = parseFloat(shalles7);
      let shalles88 = parseFloat(shalles8);
      let shalles99 = parseFloat(shalles9);
      let shalles100 = parseFloat(shalles10);
      let aveFull =
        ((shalles11 +
          shalles22 +
          shalles33 +
          shalles44 +
          shalles55 +
          shalles66 +
          shalles77 +
          shalles88 +
          shalles99 +
          shalles100) /
          30) *
        100;
      return aveFull.toFixed(1);
    },
    getYearList() {
      axios
        .get("/manager/getYearList")
        .then((response) => {
          this.years = response.data.years;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getLessonEvaluationDatas() {
      axios
        .post("/manager/getLessonsEvaluationData", {
          yearId: this.school_year_id.id,
          managerId: this.school_manager_id.user_id,
          teacherId: this.school_teacher_id.user_id,
        })
        .then((response) => {
          this.mySchoolQuarters = response.data.mySchoolsQuarters;
          this.teacherEvaluations = response.data.teacherEvaluations;
          this.schoolDepartments = response.data.schoolDepartments;
          this.schoolClasses = response.data.schoolClasses;
          this.schoolTeachers = response.data.schoolTeachers;
          this.schoolEmployees = response.data.schoolEmployees;
          this.schoolSubjects = response.data.schoolSubjects;
          //   console.log(this.teacherEvaluations);
          this.changeTeacherEvaluations = [...this.teacherEvaluations];

          this.changeTeacherEvaluations.forEach((element) => {
            element.averageShalBegProcess = this.calculateLessonBegun(
              element.shalles1,
              element.shalles2,
              element.shalles3
            );
            // console.log("Эхлэл" + element.averageShalBegProcess);
            element.averageShalMidProcess = this.calculateLessonMid(
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8
            );
            // console.log("Өрнөл" + element.averageShalMidProcess);
            element.averageShalEndProcess = this.calculateLessonEnd(
              element.shalles9,
              element.shalles10
            );
            //Ээлжит хичээлийн нийт үнэлгээний дундаж үзүүлэлт
            element.averageShalFullProcess = this.calculateLessonFull(
              element.shalles1,
              element.shalles2,
              element.shalles3,
              element.shalles4,
              element.shalles5,
              element.shalles6,
              element.shalles7,
              element.shalles8,
              element.shalles9,
              element.shalles10
            );
          });

          this.teacherEvaluationsLoaded = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    editLessonEvaluation(teacherEvaluation) {
      this.editMode = true;
      this.form.fill(teacherEvaluation);
      //   console.log(this.form);
      $("#modal-xl").modal("show");
    },
    viewLessonEvaluation(teacherEvaluation) {
      console.log(teacherEvaluation);

      this.compareTE = false;
      this.passTeachersEvaluationData = [];
      this.passTeachersEvaluationData = teacherEvaluation;
      //   this.$nextTick(() => {
      //     this.compareTE = true;
      //     //$nextTick супер функц нь component-ыг дахин релоад хийх боломж олгоно.
      //   });
      this.compareTE = true;
      this.$bvModal.show("modal-teacher-lesson-evaluations");
      //   console.log(this.passTeachersEvaluationData);
    },

    deleteLessonEvaluation(teacherEvaluation) {
      // console.log(teacherEvaluation);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text:
          teacherEvaluation["employees_teacher_last_name"][0] +
          ". " +
          teacherEvaluation["employees_teacher_name"] +
          " багшийн " +
          '"' +
          teacherEvaluation["lesson_topic"] +
          '"' +
          " сэдвээр заасан хичээлийн ажиглалтын үнэлгээг" +
          " устгах гэж байна." +
          " Дахин сэргээх боломжгүйг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцал!",
        confirmButtonColor: "#008000",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/manager/deleteLessonEvaluation/" + teacherEvaluation.id)
            .then((res) => {
              Swal.fire("Устгалаа!", res.data.success, "success");
              Fire.$emit("getLoadTeacherEvaluations");
              //   console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire(
            "Цуцлагдсан",
            "Устгах үйлдлийг амжилттай цуцалсан :)",
            "error"
          );
        }
      });
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    customTeacherName({ employee_teacher_last_name, employee_teacher_name }) {
      return `${employee_teacher_last_name[0].toUpperCase()}. ${employee_teacher_name}`;
    },
  },
  created() {
    this.getYearList();
    this.getLessonEvaluationDatas();
    Fire.$on("getLoadTeacherEvaluations", () => {
      this.getLessonEvaluationDatas();
    });
    Fire.$on("getWasEditMode", (event) => {
      this.editMode = event;
    });
  },
};
</script>

<style lang="scss" scoped>
// #special-select {
//   display: inline-flex;
// }
</style>
