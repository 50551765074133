 <!-- Календарь харах хэсэг -->
 <template>
  <div>
    <a
      @click.prevent="showCalendar()"
      v-b-toggle="'sidebar-topic-' + index"
      href="#"
      class="badge bg-success"
      ><i class="fa fa-eye"></i
    ></a>
    <b-sidebar
      :id="'sidebar-topic-' + index"
      title="КАЛЕНДАРЬ ТӨЛӨВЛӨГӨӨ"
      backdrop-variant="secondary"
      right
      z-index="1039"
      shadow
      width="90%"
      backdrop
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <span class="mr-auto">
            Анги: <strong>{{ showTopic.grade }}</strong>
            <span class="ml-5"
              >Хичээл: <strong>{{ showTopic.subject_name }}</strong></span
            >
          </span>
          <b-button size="sm" variant="primary" @click="hide">Хаах</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="card">
          <div class="card-header">
            <strong
              >{{ showTopic.subject_name }} {{ showTopic.startYear }}-{{
                showTopic.finishYear
              }}
              оны хичээлийн жил</strong
            >
          </div>
          <div class="card-body table-responsive">
            <b-overlay :show="planIsLoaded" rounded="sm">
              <table id="cp_table" class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Анги
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.grade }}-р анги,
                      {{ showTopic.subject_name }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Үечлэл
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      {{ showTopic.stage }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Улирал
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      v-for="showTerm in showTerms"
                      :key="showTerm.id"
                      :colspan="showTerm.weeks_count"
                    >
                      {{ showTerm.term_name }}-р улирал
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Сар
                    </td>
                    <td
                      v-for="termMonth in showMonths"
                      :key="termMonth.id"
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="termMonth.weeks_count"
                    >
                      {{ termMonth.month_name }}-р сар
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      7 хоног
                    </td>
                    <td
                      v-for="weekObj in showPlanWeeks"
                      :key="weekObj.id"
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                    >
                      <b-badge
                        v-if="
                          nowWeekCount == parseInt(weekObj.week_index) &&
                          nowMonth == parseInt(weekObj.month_name)
                        "
                        variant="warning"
                        v-b-popover.hover.right="'Энэ 7 хоног'"
                        >{{ weekObj.week_name }}<br />
                        Энэ 7 хоног
                      </b-badge>
                      <b-badge variant="secondary" v-else>
                        {{ weekObj.week_name }}
                      </b-badge>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Бүлэг сэдэв
                    </td>
                    <td
                      v-for="subject in showSubjects"
                      :key="subject.id"
                      class="dtr-control sorting_1 text-wrap"
                      :colspan="subject.subject_week"
                      tabindex="0"
                    >
                      {{ subject.subject_name }}
                    </td>
                  </tr>
                  <tr v-for="indexUnit in 2" :key="'unit' + indexUnit">
                    <td
                      class="dtr-control sorting_1"
                      tabindex="0"
                      colspan="1"
                      v-if="indexUnit == 1"
                    >
                      Нэгж сэдэв
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                      v-if="indexUnit == 2"
                    >
                      Цаг
                    </td>
                    <td
                      v-for="unit in showUnits"
                      :key="unit.id"
                      class="dtr-control sorting_1 text-center text-wrap"
                      :colspan="unit.unit_week"
                      tabindex="0"
                    >
                      <div v-if="indexUnit == 1">{{ unit.unit_name }}</div>
                      <div v-if="indexUnit == 2">{{ unit.unit_hour }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center p-0"
                      tabindex="0"
                      colspan="1"
                    >
                      <div class="topicColumn">
                        <div class="topic-column">Сэдэв</div>
                      </div>
                      <div class="topic-hour-column">Цаг</div>
                    </td>
                    <td
                      v-for="week in showPlanWeeks"
                      :key="week.id"
                      class="dtr-control sorting_1 text-center p-0"
                      tabindex="0"
                    >
                      <div class="topic-wrapper" v-if="week.topics.length > 0">
                        <div
                          class="topic"
                          v-for="topic in week.topics"
                          :key="topic.id"
                        >
                          <a href="#" v-if="topic.successCount == 0">
                            <b-icon
                              icon="check-circle"
                              variant="success"
                            ></b-icon
                          ></a>
                          <a href="#" v-else>
                            <b-icon
                              icon="check-circle-fill"
                              v-b-popover.hover.right="
                                'Сэдвийн ээлжит хичээлийг заасан байдал'
                              "
                              variant="success"
                            ></b-icon
                          ></a>
                          <div class="topic-title verticalText">
                            <b-badge
                              href="#"
                              id="topicBadge"
                              :variant="
                                topics.filter((t) => t.topic_id == topic.id)
                                  .length > 0
                                  ? 'success'
                                  : 'light'
                              "
                              v-b-popover.hover.right="
                                topic.topic_name +
                                '/' +
                                topic.topic_hour +
                                'цаг/'
                              "
                            >
                              {{ topic.topic_name }}
                              <b-badge
                                variant="warning"
                                v-b-popover.hover.right="'Цаг'"
                              >
                                {{ topic.topic_hour }} цаг
                              </b-badge>
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="topic-wrapper" v-else>
                        <div class="topic">
                          <a href="#">
                            <b-icon
                              icon="check-circle"
                              variant="success"
                              class="noActive"
                            ></b-icon
                          ></a>
                          <div class="topic-title verticalText"></div>
                        </div>
                      </div>
                      <div class="topic-hour">
                        {{ week.week_hour }} / {{ week.topicTotalHour }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      colspan="1"
                    >
                      Нийт
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      tabindex="0"
                      :colspan="showTopic.totalWeek"
                    >
                      <b>{{ showTopic.totalWeek }} долоо хоног</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </b-sidebar>
  </div>
</template>


<script>
export default {
  props: ["cp_id", "topics", "index"],
  data() {
    return {
      nowMonth: new Date().getMonth() + 1,
      nowWeekCount: this.getWeekNumOfMonthOfDate(new Date()),
      planIsLoaded: false,
      showTopic: new Form({
        id: null,
        startYear: "",
        finishYear: "",
        subject_id: "",
        subject_name: "",
        cp_status: "",
        grade: "",
        stage: "",
        teacher_type: "",
        totalWeek: 0,
      }),
      showTerms: [],
      showMonths: [],
      showSubjects: [],
      showPlanWeeks: [],
      showUnits: [],
    };
  },
  methods: {
    //Одоо явж байгаа 7 хоногийг олно
    getWeekNumOfMonthOfDate(d) {
      let year = d.getFullYear();
      let month = d.getMonth();
      let firstDay = new Date(year, month, 1).getDay();
      return Math.ceil(d.getDate() / 7);
    },
    //Календар
    showCalendar() {
      axios
        .get("/all_user_plan/getPlan/" + this.cp_id)
        .then((response) => {
          this.showTopic.reset();
          this.showTopic.fill(response.data.plan);
          this.getShowCalendar();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getShowCalendar() {
      this.planIsLoaded = true;
      axios
        .get("/all_user_plan/getTopics/" + this.cp_id)
        .then((response) => {
          this.showTopic.totalWeek = response.data.totalWeek;
          this.showTopic.startYear = response.data.startYear;
          this.showTopic.finishYear = response.data.finishYear;
          this.showTerms = response.data.terms;
          this.showMonths = response.data.months;
          this.showSubjects = response.data.subjects;
          this.showUnits = response.data.units;
          this.showPlanWeeks = response.data.planWeeks;
          this.planIsLoaded = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  created() {},
};
</script>
