<template>
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="tab-pane active" id="mySuccess">
          <button
            type="button"
            class="btn btn-block btn-success btn-xs"
            @click.prevent="createFillFromForTeacher()"
            v-if="role !== 'dentstu'"
          >
            Өөрийн амжилт бүртгэх
          </button>
          <div class="card-body table-responsive p-0" style="overflow-x: auto">
            <table class="table table-hover table-striped table-valign-middle">
              <thead>
                <tr role="row">
                  <th>№</th>
                  <th>Оролцсон олимпиад, уралдаан тэмцээн</th>
                  <th>Багшийн овог, нэр</th>
                  <th>Байр</th>
                  <th>Түвшин</th>
                  <th>Гаргасан амжилт</th>
                  <th>Тайлбар</th>
                  <th>Үүсгэсэн</th>
                  <th>Зассан</th>
                  <th v-if="role !== 'dentstu'">Үйлдлүүд</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="odd"
                  v-for="(tSuccess, index) in mySuccesses"
                  :key="tSuccess.success_id"
                >
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ index + 1 }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <span v-if="tSuccess.my_competition_info !== null">
                      <b-badge
                        variant="success"
                        class="text-wrap"
                        style="width: 8rem"
                        >{{ tSuccess.my_competition_info.name }}</b-badge
                      >
                      {{
                    }}</span>
                    <span
                      v-if="tSuccess.my_competition_info === null"
                      class="text-wrap"
                      style="width: 8rem"
                      >Олимпиад, уралдаан тэмцээн бүртгээгүй байна.</span
                    >
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ tSuccess.emp_teacher_last_name[0] }}.
                    {{ tSuccess.emp_teacher_name }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ filterEzelsenBair(tSuccess.amjilt_ezelsen_bair) }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ filterStudent_turul(tSuccess.student_turul) }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ tSuccess.amjilt_name }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ tSuccess.amjilt_tailbar }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ tSuccess.created_at | dateYearMonthDay }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    {{ tSuccess.updated_at | dateYearMonthDay }}
                  </td>
                  <td class="dtr-control sorting_1" tabindex="0">
                    <a
                      href="#"
                      class="badge bg-warning"
                      @click="editSchoolTeacherSuccess(tSuccess)"
                      ><i class="fas fa-edit"></i
                    ></a>

                    <a
                      href="#"
                      class="badge bg-danger"
                      @click="deleteSchoolTeacherSuccess(tSuccess)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Багш ажилтан" -->
    <b-modal
      id="modal-teacher-success"
      ref="modal"
      title="Багш нарын амжилтыг бүртгэх модуль"
      @hidden="resetTeacherSuccessModal"
      hide-footer
    >
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title" v-show="!editModeTeacher">Амжилт бүртгэх</h3>
          <h3 class="card-title" v-show="editModeTeacher">Амжилт засах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body form-responsive p-0">
          <!-- form start -->
          <form
            @submit.prevent="
              !editModeTeacher
                ? createSchoolTeacherSuccess()
                : updateSchoolTeacherSuccess()
            "
          >
            <div class="card-body">
              <div class="form-group">
                <label
                  >Олимпиад, уралдаан тэмцээн сонгох
                  <h6>
                    <b-badge
                      class="badge badge-primary text-wrap"
                      style="font-size: 80%; text-align: justify"
                      variant="warning"
                      >Суралцахуйн албаны менежерт олимпиад, уралдаан тэмцээний
                      мэдээллийг өгч, бүртгүүлсний дараа сонгох боломжтой.
                      (Заавал сонгох шаардлагагүй)</b-badge
                    >
                  </h6>
                </label>
                <multiselect
                  v-model="formTeacherSuccess.competition_id"
                  deselect-label="Can't remove this value"
                  track-by="id"
                  label="id"
                  :custom-label="customCompetitionName"
                  placeholder="Олимпиад, уралдаан тэмцээн сонгох(Заавал сонгох шаардлагагүй)"
                  :options="competitions"
                  :searchable="true"
                  :allow-empty="true"
                  :close-on-select="true"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.name }}</strong></template
                  >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Амжилт гаргасан түвшинг сонгох</label>
                <multiselect
                  v-model="formTeacherSuccess.student_turul"
                  :options="turul"
                  :multiple="false"
                  track-by="student_turul"
                  label="student_turul"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Амжилт гаргасан түвшинг сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.student_turul }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formTeacherSuccess"
                  field="student_turul"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Эзэлсэн байр сонгох</label>
                <multiselect
                  v-model="formTeacherSuccess.amjilt_ezelsen_bair"
                  :options="ezelsenBair"
                  :multiple="false"
                  track-by="ezelsen_bair"
                  label="ezelsen_bair"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Эзэлсэн байр сонгох"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.ezelsen_bair }}</strong></template
                  >
                </multiselect>
                <has-error
                  :form="formTeacherSuccess"
                  field="ezelsen_bair"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын нэр</label>
                <input
                  type="text"
                  name="amjilt_name"
                  v-model="formTeacherSuccess.amjilt_name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        formTeacherSuccess.errors.has('amjilt_name'),
                    },
                  ]"
                  placeholder="Шагналын нэр"
                />
                <has-error
                  :form="formTeacherSuccess"
                  field="amjilt_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Амжилтын тайлбар</label>
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formTeacherSuccess.amjilt_tailbar"
                  placeholder="Гаргасан амжилтын хувьд аль болох дэлгэрэнгүй
                мэдээлэл оруулна уу?"
                >
                </b-form-textarea>
                <has-error
                  :form="formTeacherSuccess"
                  field="amjilt_tailbar"
                ></has-error>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                v-show="!editModeTeacher"
              >
                Хадгалах
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                v-show="editModeTeacher"
              >
                Засах
              </button>
              <button
                class="btn btn-warning"
                v-show="editModeTeacher"
                @click.prevent="resetTeacherSuccessModal"
              >
                Болих
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      action: "",
      editModeTeacher: false,
      role: "",
      competitions: [],
      mySuccesses: [],
      teachers: [],
      teacherSuccesses: [],
      formTeacherSuccess: new Form({
        id: "",
        competition_id: null,
        employee_migration_id: "",
        emp_role: "",
        student_turul: 0,
        amjilt_ezelsen_bair: 0,
        amjilt_name: "",
        amjilt_tailbar: "",
      }),
      turul: [
        { name: 0, student_turul: "Түвшин сонгох" },
        { name: 1, student_turul: "Анги" },
        { name: 2, student_turul: "Сургууль" },
        { name: 3, student_turul: "Дүүрэг" },
        { name: 4, student_turul: "Нийслэл" },
        { name: 5, student_turul: "Улс" },
        { name: 6, student_turul: "Олон улс" },
      ],
      ezelsenBair: [
        { name: 0, ezelsen_bair: "Сонголт хийнэ үү?" },
        { name: 1, ezelsen_bair: "Алт" },
        { name: 2, ezelsen_bair: "Мөнгө" },
        { name: 3, ezelsen_bair: "Хүрэл" },
        { name: 4, ezelsen_bair: "Тусгай байр(4-10)" },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  components: { Multiselect },
  methods: {
    getAllTeacherSuccesses() {
      axios
        .post("/teacher/getAllTeacherSuccesses")
        .then((res) => {
          // console.log(response.data.rewards);
          this.role = res.data.role;
          this.teachers = res.data.teachers;
          this.competitions = res.data.competitions;
          this.mySuccesses = res.data.mySuccesses;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    filterEzelsenBair(val) {
      //   console.log(val);
      let myEzBair = "";
      this.ezelsenBair.forEach((element) => {
        // console.log("name =>" + element.name);
        if (element.name == val) {
          myEzBair = element.ezelsen_bair;
        }
      });
      return myEzBair;
    },
    filterStudent_turul(val) {
      //   console.log(val);
      let myTurul = "";
      this.turul.forEach((element) => {
        // console.log("name =>" + element.name);
        if (element.name == val) {
          myTurul = element.student_turul;
        }
      });
      return myTurul;
    },
    editSchoolTeacherSuccess(success) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах", success);
      this.editModeTeacher = true;
      this.formTeacherSuccess.reset();
      this.formTeacherSuccess.fill(success);
      if (success.competition_id !== null) {
        this.competitions.filter((el) => {
          if (el.id == success.competition_id) {
            this.formTeacherSuccess.competition_id = el;
          }
        });
      }
      this.teachers.filter((el) => {
        if (el.emp_mig_id == success.emp_mig_id) {
          this.formTeacherSuccess.employee_migration_id = el;
        }
      });
      this.turul.filter((el) => {
        if (el.name == success.student_turul) {
          this.formTeacherSuccess.student_turul = el;
        }
      });
      this.ezelsenBair.filter((el) => {
        if (el.name == success.amjilt_ezelsen_bair) {
          this.formTeacherSuccess.amjilt_ezelsen_bair = el;
        }
      });

      this.$bvModal.show("modal-teacher-success");
    },
    updateSchoolTeacherSuccess() {
      this.formTeacherSuccess
        .put("/teacher/updateTeacherSuccess/" + this.formTeacherSuccess.id)
        .then((res) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadSchoolSuccess");
          this.resetTeacherSuccessModal();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    deleteSchoolTeacherSuccess(success) {
      Swal.fire({
        title: "Устгахдаа итгэлтэй байна уу?",
        text: "Систем админы тусламжтайгаар сэргээх боломжтой гэдгийг анхаарна уу!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Цуцлах",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/teacher/deleteTeacherSuccess/" + success.id)
            .then((res) => {
              Swal.fire("Устгагдсан!", "Амжилттай устгалаа.", "success");
              Fire.$emit("loadSchoolSuccess");
            })
            .catch((errors) => {
              //   console.log(errors);
            });
        }
      });
    },
    resetTeacherSuccessModal() {
      this.formTeacherSuccess.reset();
      this.editModeTeacher = false;
      this.$bvModal.hide("modal-teacher-success");
    },
    createFillFromForTeacher() {
      this.$bvModal.show("modal-teacher-success");
      this.formTeacherSuccess.emp_role = this.role;
    },
    createSchoolTeacherSuccess() {
      this.formTeacherSuccess
        .post("/teacher/createTeacherSuccess")
        .then((res) => {
          this.$toastr.s("Ажилтны гаргасан амжилтыг бүртгэлээ.", "Амжилттай");
          Fire.$emit("loadSchoolSuccess");
          this.resetTeacherSuccessModal();
        })
        .catch(() => {
          this.$toastr.e(
            "Шагналыг бүртгэж чадсангүй чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    customTeachers({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
    customCompetitionName({ name }) {
      return `${name}`;
    },
  },
  created() {
    axios
      .get("/all_worker_functions/getMyData")
      .then((res) => {
        // console.log(res.data);
        this.role = res.data.role;
      })
      .catch((err) => {
        // console.log(err);
      });
    this.getAllTeacherSuccesses();
    Fire.$on("loadSchoolSuccess", () => {
      this.getAllTeacherSuccesses();
    });
  },
};
</script>

<style></style>
