<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-primary card-tabs">
        <div class="card-header p-0 pt-1">
          <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
            <!-- Миний хүүхдийн анги -->
            <li class="nav-item">
              <a
                class="nav-link active"
                id="custom-tabs-one-student-tab"
                data-toggle="pill"
                href="#custom-tabs-one-student"
                role="tab"
                aria-controls="custom-tabs-one-student"
                aria-selected="true"
                >Таны хүүхдэд багш, сургуулиас</a
              >
            </li>
            <!-- Миний хүүхдийн ангид -->
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-school-class-tab"
                data-toggle="pill"
                href="#custom-tabs-one-school-class"
                role="tab"
                aria-controls="custom-tabs-one-school-class"
                aria-selected="true"
                >Таны хүүхдийн ангид багш, сургуулиас
              </a>
            </li>
            <!-- Миний хүүхдийн хувийн -->
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-studentOwn-tab"
                data-toggle="pill"
                href="#custom-tabs-one-studentOwn"
                role="tab"
                aria-controls="custom-tabs-one-studentOwn"
                aria-selected="true"
                >Миний хүүхдийн хувийн</a
              >
            </li>

            <!-- <b-button variant="warning" @click.prevent="createSP()"
              >Төлөвлөгөө бүртгэх</b-button
            > -->
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-one-tabContent">
            <!-- Суралцагч -->
            <div
              class="tab-pane fade show active"
              id="custom-tabs-one-student"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-student-tab"
            >
              <div class="col-lg-12 mt-3">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <b-container class="bv-example-row" fluid> </b-container>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <b-overlay :show="show" rounded="sm">
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(
                            specialPlan, index
                          ) in allspecialPlanOfStudents"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0">
                              <div
                                class="progress progress-xs progress-striped active"
                              >
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  class="progress-bar bg-danger"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  class="progress-bar bg-warning"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  class="progress-bar bg-primary"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  class="progress-bar bg-success"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                              </div>
                              <small>
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  variant="success"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  variant="primary"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  variant="danger"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) ===
                                    null
                                  "
                                >
                                  ОРООГҮЙ</b-badge
                                >
                              </small>
                            </td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td tabindex="0">
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li
                                  v-if="specialPlan.student_user_special_plan"
                                  class="list-inline-item"
                                >
                                  <img
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_user_special_plan
                                        .student_profile_photo_url
                                        ? specialPlan.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                                <li v-else class="list-inline-item">
                                  <img
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_profile_photo_url
                                        ? specialPlan.student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointWith0AVG(
                                    allspecialPlanOfStudents
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointNotWith0AVG(
                                    allspecialPlanOfStudents
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{
                                allspecialPlanOfStudents.length
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <!-- Ангиaр -->
            <div
              class="tab-pane fade show"
              id="custom-tabs-one-school-class"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-school-class-tab"
            >
              <div class="col-lg-12 mt-3">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <b-container class="bv-example-row" fluid> </b-container>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <b-overlay :show="show" rounded="sm">
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <th>Хариуцах эзэн</th>
                            <th>Төлөвлөгөөнд хамрагдах</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(
                            specialPlan, index
                          ) in allspecialPlanOfSchoolClass"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0">
                              <div
                                class="progress progress-xs progress-striped active"
                              >
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  class="progress-bar bg-danger"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  class="progress-bar bg-warning"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  class="progress-bar bg-primary"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  class="progress-bar bg-success"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                              </div>
                              <small>
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  variant="success"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  variant="primary"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  variant="danger"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) ===
                                    null
                                  "
                                >
                                  ОРООГҮЙ</b-badge
                                >
                              </small>
                            </td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td tabindex="0">
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_last_name | capitalizeAndEkhniiVseg
                              }}.
                              {{
                                specialPlan.en_roll_employee_user_special_plan
                                  .teacher_name
                              }}
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  {{
                                    specialPlan.school_class_special_plan
                                      .full_name
                                  }}
                                  анги
                                </li>
                              </ul>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointWith0AVG(
                                    allspecialPlanOfSchoolClass
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointNotWith0AVG(
                                    allspecialPlanOfSchoolClass
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{
                                allspecialPlanOfSchoolClass.length
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <!-- Суралцагч -->
            <div
              class="tab-pane fade show"
              id="custom-tabs-one-studentOwn"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-studentOwn-tab"
            >
              <div class="col-lg-12 mt-3">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <b-container class="bv-example-row" fluid> </b-container>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <b-overlay :show="show" rounded="sm">
                      <table class="table projects">
                        <thead>
                          <tr role="row">
                            <th>#</th>
                            <th>Үйлдлүүд</th>
                            <th>Гүйцэтгэл</th>
                            <th>Төлөвлөгөөний нэр</th>
                            <th>Хариуцах эзэн</th>
                            <th>Огноо</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(
                            specialPlan, index
                          ) in allspecialPlanOfStudentsOwn"
                          :key="specialPlan.id"
                        >
                          <tr>
                            <td tabindex="0">
                              {{ index + 1 }}
                            </td>
                            <td tabindex="0">
                              <a
                                href="#"
                                class="badge bg-success"
                                @click="
                                  showSpecialPlanForActionsStudent(specialPlan)
                                "
                                ><i class="fa fa-eye"></i>
                              </a>
                            </td>
                            <td style="text-align: center" tabindex="0">
                              <div
                                class="progress progress-xs progress-striped active"
                              >
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  class="progress-bar bg-danger"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  class="progress-bar bg-warning"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  class="progress-bar bg-primary"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                                <div
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  class="progress-bar bg-success"
                                  :style="{
                                    width: `${specialPlanactionsPointAVG(
                                      specialPlan
                                    )}%`,
                                  }"
                                ></div>
                              </div>
                              <small>
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                    80
                                  "
                                  variant="success"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      60 &&
                                    specialPlanactionsPointAVG(specialPlan) < 80
                                  "
                                  variant="primary"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) >=
                                      30 &&
                                    specialPlanactionsPointAVG(specialPlan) < 60
                                  "
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  v-if="
                                    specialPlanactionsPointAVG(specialPlan) < 30
                                  "
                                  variant="danger"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) !=
                                    null
                                  "
                                >
                                  {{
                                    specialPlanactionsPointAVG(specialPlan)
                                  }}</b-badge
                                >
                                <b-badge
                                  variant="warning"
                                  v-show="
                                    specialPlanactionsPointAVG(specialPlan) ===
                                    null
                                  "
                                >
                                  ОРООГҮЙ</b-badge
                                >
                              </small>
                            </td>
                            <td tabindex="0">
                              <b-badge
                                variant="light"
                                style="white-space: pre-wrap"
                                >{{ specialPlan.s_p_name }}</b-badge
                              >
                            </td>
                            <td tabindex="0">
                              <ul class="list-inline">
                                <li
                                  v-if="specialPlan.student_user_special_plan"
                                  class="list-inline-item"
                                >
                                  <img
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_user_special_plan
                                        .student_profile_photo_url
                                        ? specialPlan.student_user_special_plan
                                            .student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_user_special_plan
                                        .student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_user_special_plan
                                        .student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                                <li v-else class="list-inline-item">
                                  <img
                                    alt="Avatar"
                                    class="table-avatar"
                                    :src="
                                      specialPlan.student_profile_photo_url
                                        ? specialPlan.student_profile_photo_url
                                        : '/images/users/user.png'
                                    "
                                    v-b-tooltip.hover.top.html="
                                      '</dd><dt>Овог Нэр</dt><dd>' +
                                      specialPlan.student_last_name +
                                      ' ' +
                                      '<span class=text-uppercase>' +
                                      specialPlan.student_name +
                                      '</span></dd></dl>'
                                    "
                                  />
                                </li>
                              </ul>
                            </td>
                            <td
                              class="dtr-control sorting_1 text-center"
                              tabindex="0"
                            >
                              {{
                                specialPlan.s_p_start_date | dateYearMonthDay
                              }}
                              <br />
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                              <br />
                              {{
                                specialPlan.s_p_finish_date | dateYearMonthDay
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцсон/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointWith0AVG(
                                    allspecialPlanOfStudentsOwn
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Дундаж /0 тооцохгүй/</small>
                            </td>
                            <td colspan="3">
                              <strong
                                >{{
                                  allSpecialPlanactionsPointNotWith0AVG(
                                    allspecialPlanOfStudentsOwn
                                  )
                                }}%</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <small>Нийт:</small>
                            </td>
                            <td colspan="3">
                              <strong>{{
                                allspecialPlanOfStudentsOwn.length
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>

    <!-- Суралцагчийн тусгай төлөвлөгөөн дээр үйл ажиллагаа нэмэх функц -->
    <b-modal
      size="xl"
      id="modal-show-special-plan-action-student"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="
        formSpecial.s_p_name
          ? formSpecial.s_p_name
          : 'Төлөвлөгөө бүртгэгдээгүй байна.'
      "
      class="modal fade"
      hide-footer
      modal-header-close
      @hidden="hideSpecialPlanForActionsStudent"
    >
      <!-- @show="resetSP" -->
      <!-- @ok="handleOk" -->
      <div class="modal-body">
        <div class="direct-chat-msg" v-if="formSpecial.s_p_student_id">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left">
              {{ formSpecial.s_p_student_id.student_last_name }}.
              {{ formSpecial.s_p_student_id.student_name | uppercase }}</span
            >
            <span class="direct-chat-timestamp float-right"
              >{{ formSpecial.s_p_start_date | dateYearMonthDay }}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              {{ formSpecial.s_p_finish_date | dateYearMonthDay }}
            </span>
          </div>
          <!-- /.direct-chat-infos -->
          <img
            :src="
              formSpecial.s_p_student_id.student_profile_photo_url
                ? formSpecial.s_p_student_id.student_profile_photo_url
                : '/images/users/user.png'
            "
            alt="user-avatar"
            class="direct-chat-img"
          />
          <!-- /.direct-chat-img -->
          <div class="row">
            <div class="direct-chat-text col-lg-3">
              <b>ТӨЛӨВЛӨГӨӨНИЙ НЭР:</b><br />
              {{ formSpecial.s_p_name }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ЗОРИЛГО:</b><br />
              {{ formSpecial.s_p_objective }}
            </div>
            <div class="direct-chat-text col-lg-3">
              <b>ДУУССАН ЭСЭХ:</b>
              <span v-if="formSpecial.s_p_done == 1" class="float-right"
                >Тийм</span
              >
              <span v-if="formSpecial.s_p_done == 0" class="float-right"
                >Үгүй</span
              ><br />
              {{ formSpecial.s_p_a_success_point }}
              <b>ДҮГНЭЛТ:</b><br />
              {{ formSpecial.s_p_conclusion }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="callout callout-success" v-if="formSpecial.point > 0">
              <small> <strong>ҮНЭЛГЭЭНИЙ ТАЙЛБАР:</strong></small>
              <p>
                <small>{{ formSpecial.point_explain }}</small>
              </p>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Төлөвлөгдсөн үйл ажиллагаанууд</h3>
                <span class="direct-chat-timestamp float-right"
                  ><b>Үнэлгээ: </b> {{ formSpecial.point }}%
                </span>
              </div>
              <div
                class="card-body table-responsive"
                v-if="allspecialPlanActionsOfStudents"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>ҮА-ны нэр</th>
                      <th>Огноо</th>
                      <th>Хариуцах эзэн</th>
                      <th>Төлөвлөгөө харах</th>
                      <th>Үргэлжилсэн хугацаа</th>
                      <th>Гүйцэтгэлийн үнэлгээ</th>
                      <th>Тайлбар</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(action, index) in allspecialPlanActionsOfStudents"
                    :key="action.show_monthPlan_id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <span class="direct-chat-timestamp float-right"
                          >{{ action.s_p_a_start_date | dateYearMonthDay }}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          {{ action.s_p_a_finish_date | dateYearMonthDay }}
                        </span>
                      </td>
                      <td>
                        <ul class="list-inline">
                          <li
                            class="list-inline-item"
                            v-for="teacher in action.my_special_plan_action_users"
                            :key="teacher.id"
                          >
                            <img
                              @click="showImage(teacher)"
                              v-if="
                                teacher.special_plan_action_user_info != null
                              "
                              alt="Avatar"
                              class="direct-chat-img"
                              :src="
                                teacher.special_plan_action_user_info
                                  .profile_photo_url
                                  ? teacher.special_plan_action_user_info
                                      .profile_photo_url
                                  : '/images/users/user.png'
                              "
                              v-b-tooltip.hover.top.html="
                                '</dd><dt>Овог Нэр</dt><dd>' +
                                teacher.special_plan_action_user_info
                                  .teacher_last_name +
                                ' <span class=text-uppercase>' +
                                teacher.special_plan_action_user_info
                                  .teacher_name +
                                '</span></dd></dl>'
                              "
                            />

                            <!-- <i
                              class="fas fa-user-times"
                              @click="removeTeacherOfStudent(teacher)"
                            ></i> -->
                          </li>
                          <span
                            v-for="actionUser in action.my_special_plan_action_users"
                            :key="actionUser.s_p_a_owner_id"
                          >
                            <li
                              class="list-inline-item"
                              v-if="
                                actionUser.s_p_a_owner_id == userInfo.user.id
                              "
                            >
                              <b-badge variant="light">Эцэг эх </b-badge>
                            </li>
                          </span>
                        </ul>
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_during_time }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_point }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ action.s_p_a_success_explain }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        <p
                          v-for="actionUser in action.my_special_plan_action_users"
                          :key="actionUser.s_p_a_owner_id"
                        >
                          <a
                            v-if="action.my_special_plan_topics.length > 0"
                            class="badge bg-success"
                          >
                            <show-plan-calendar
                              :cp_id="action.my_special_plan_topics[0].cp_id"
                              :topics="action.my_special_plan_topics"
                              :index="index"
                            ></show-plan-calendar>
                          </a>
                          <a
                            v-if="actionUser.s_p_a_owner_id == userInfo.user.id"
                            href="#"
                            class="badge bg-warning"
                            @click="editSpecialPlanActionStudent(action)"
                          >
                            <i class="far fa-edit"></i>
                          </a>
                          <a
                            v-if="actionUser.s_p_a_owner_id == userInfo.user.id"
                            href="#"
                            class="badge bg-danger"
                            @click="deleteSpecialPlanActionStudent(action)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" v-show="!editModeSPAStudent">
                  Үйл ажиллагаа нэмэх
                </h3>
                <h3 class="card-title" v-show="editModeSPAStudent">
                  Үйл ажиллагаа Засах
                </h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="s_p_a_name">Үйл ажиллагаа</label>
                  <input
                    type="text"
                    class="form-control"
                    id="s_p_a_name"
                    aria-describedby="emailHelp"
                    v-model="formSpecialPlanAction.s_p_a_name"
                    placeholder="Үйл ажиллагааны нэр"
                  />
                  <has-error
                    :form="formSpecialPlanAction"
                    field="s_p_a_name"
                  ></has-error>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label>Эхлэх огноо</label>
                    <b-form-datepicker
                      id="start-datepicker"
                      :state="true"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_start_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_start_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_start_date"
                    ></has-error>
                  </div>
                  <div class="form-group col-lg-6">
                    <label>Дуусах огноо</label>
                    <b-form-datepicker
                      id="end-datepicker"
                      :state="false"
                      size="sm"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      placeholder="..."
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="formSpecialPlanAction.s_p_a_finish_date"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            formSpecialPlanAction.errors.has(
                              's_p_a_finish_date'
                            ),
                        },
                      ]"
                    >
                    </b-form-datepicker>
                    <has-error
                      :form="formSpecialPlanAction"
                      field="s_p_a_finish_date"
                    ></has-error>
                  </div>
                </div>
                <div class="form-group">
                  <label>Зарцуулсан хугацаа(мин)</label>
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_during_time"
                    placeholder="Үргэлжилсэн хугацаа(мин)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <label>Гүйцэтгэлийн оноо(0-100)</label>
                  <b-form-input
                    type="number"
                    v-model="formSpecialPlanAction.s_p_a_success_point"
                    placeholder="Гүйцэтгэлийн оноо(0-100)"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <b-form-textarea
                    id="s_p_a_success_explain"
                    v-model="formSpecialPlanAction.s_p_a_success_explain"
                    placeholder="Гүйцэтгэлийн тайлбар"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="!editModeSPAStudent"
                  @click.prevent="createSpecialPlanActionStudentParent()"
                >
                  Нэмэх
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  v-show="editModeSPAStudent"
                  @click.prevent="updateSpecialPlanActionStudentParent()"
                >
                  Засах
                </button>
                <b-button
                  @click="cancelSpecialPlanActionStudent()"
                  variant="danger"
                  type="reset"
                >
                  Форм цэвэрлэх
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button class="mt-3" block @click="hideSpecialPlanForActionsStudent()"
          >Хаах</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import showPlanCalendar from "../../Plan/PlanCalendarShow.vue";
export default {
  data() {
    return {
      show: true,
      niit: "",
      unelgeeList: [],
      unelgeeSum: "",
      unelgeeAvg: "",

      unelgee0List: [],
      unelgee0Sum: "",
      unelgee0Avg: "",

      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formSpecial: new Form({
        id: "",
        year_id: "",
        school_id: "",
        s_p_owner_id: "",
        s_p_name: "",
        s_p_objective: "",
        s_p_start_date: "",
        s_p_finish_date: "",
        s_p_employee_id: "",
        s_p_school_class_id: "",
        s_p_student_id: "",
        s_p_parent_id: "",
        s_p_done: null,
        s_p_conclusion: "",
      }),

      allspecialPlanOfStudents: [],
      allspecialPlanOfStudentsOwn: [],
      allspecialPlanOfSchoolClass: [],
      allspecialPlanActionsOfStudents: [],

      // Тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх форм
      editModeSPA: false,
      formSpecialPlanAction: new Form({
        id: "",
        year_id: "",
        school_id: "",
        s_p_id: "",
        s_p_a_name: "",
        s_p_a_start_date: "",
        s_p_a_finish_date: "",
        s_p_a_during_time: 0,
        s_p_a_success_point: 0,
        s_p_a_success_explain: "",
        s_p_a_owner_id: [],
      }),
      check_s_p_id: "",
      //Суралцагчийн тусгай төлөвлөгөөнд үйл ажиллагаа нэмэх
      showModeSpecialPlanStudent: false,
      editModeSPAStudent: false,
    };
  },
  components: {
    Multiselect,
    showPlanCalendar: showPlanCalendar,
  },
  computed: {
    userInfo: function () {
      return this.$store.state;
    },
  },
  methods: {
    // Төлөвлөгөө болгоны дундаж
    specialPlanactionsPointAVG(items) {
      if (items.special_plan_actions && items.special_plan_actions.length > 0) {
        const totalPoints = items.special_plan_actions.reduce((sum, action) => {
          return sum + (action.s_p_a_success_point || 0);
        }, 0);
        const average = totalPoints / items.special_plan_actions.length;
        return average.toFixed(1);
        // return Math.round(average);
      }
      return 0;
    },
    // Бүх төлөвлөгөөний дундаж
    allSpecialPlanactionsPointWith0AVG(items) {
      if (items && items.length > 0) {
        const totalPoints = items.reduce((sum, item) => {
          if (
            item.special_plan_actions &&
            item.special_plan_actions.length > 0
          ) {
            const itemTotal = item.special_plan_actions.reduce(
              (innerSum, action) => {
                return innerSum + (action.s_p_a_success_point || 0);
              },
              0
            );
            return sum + itemTotal / item.special_plan_actions.length;
          }
          return sum;
        }, 0);
        const average = totalPoints / items.length;
        return average.toFixed(1);
      }
      return 0;
    },
    // Бүх төлөвлөгөөний дундаж 0 тооцохгүй
    allSpecialPlanactionsPointNotWith0AVG(items) {
      if (items && items.length > 0) {
        const validItems = items.filter(
          (item) =>
            item.special_plan_actions && item.special_plan_actions.length > 0
        );

        if (validItems.length === 0) return 0;

        const totalPoints = validItems.reduce((sum, item) => {
          const itemTotal = item.special_plan_actions.reduce(
            (innerSum, action) => {
              return innerSum + (action.s_p_a_success_point || 0);
            },
            0
          );
          return sum + itemTotal / item.special_plan_actions.length;
        }, 0);

        const average = totalPoints / validItems.length;
        return average.toFixed(1);
      }
      return 0;
    },
    //Тусгай төлөвлөлт

    getAllSpecialDatas() {
      this.show = true;
      axios
        .get("/parent/getAllSpecialDatasParent")
        .then((res) => {
          //   console.log(res.data);
          this.allspecialPlanOfStudents = res.data.allspecialPlanOfStudents;
          this.allspecialPlanOfStudentsOwn =
            res.data.allspecialPlanOfStudentsOwn;
          this.allspecialPlanOfSchoolClass =
            res.data.allspecialPlanOfSchoolClass;
          this.show = false;
          //   console.log(this.userInfo.user.id);
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    // Суралцагч төлөвлөгөөнд үйл ажиллагаа нэмэх модал
    showSpecialPlanForActionsStudent(specialPlan) {
      //   console.log(specialPlan, "special plan");
      //   console.log(specialPlan.student_user_special_plan, "special plan");
      this.formSpecial.reset();
      this.formSpecial.fill(specialPlan);
      this.formSpecial.s_p_owner_id =
        specialPlan.en_roll_employee_user_special_plan;
      this.formSpecial.s_p_student_id = specialPlan.student_user_special_plan;
      this.formSpecialPlanAction.s_p_id = specialPlan.id;
      this.showModeSpecialPlanStudent = true;
      this.getMySpecialPlansActionsStudent(); //allspecialPlanActionsOfStudents
      this.$bvModal.show("modal-show-special-plan-action-student");
    },
    getMySpecialPlansActionsStudent() {
      this.allspecialPlanActionsOfStudents = [];
      axios
        .get("/psycho/getAllSpecialPlanActionsStudents/" + this.formSpecial.id)
        .then((res) => {
          //   console.log(res.data);
          this.allspecialPlanActionsOfStudents =
            res.data.allspecialPlanActionsOfStudent;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    hideSpecialPlanForActionsStudent() {
      this.formSpecialPlanAction.reset();
      //   this.formSpecial.reset();
      this.editModeSPAStudent = false;
      this.allspecialPlanActionsOfStudents = [];
      this.$bvModal.hide("modal-show-special-plan-action-student");
    },

    cancelSpecialPlanActionStudent() {
      this.editModeSPAStudent = false;
      //   this.formAddTeacher.department_id = 0;
      this.formSpecialPlanAction.reset();
      this.getMySpecialPlansActionsStudent();
    },
    createSpecialPlanActionStudentParent() {
      this.formSpecialPlanAction
        .post("/psycho/createSpecialPlanActionStudentParent")
        .then((res) => {
          if (res.data.info.code === "success") {
            // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
            this.$toastr.s(res.data.info.message, "Амжилттай");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionStudent();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editSpecialPlanActionStudent(action) {
      // console.log(action.my_special_plan_action_users[0]['special_plan_action_user_info']['department_id']);
      //   console.log(action);
      this.formSpecialPlanAction.reset();
      this.editModeSPAStudent = true;
      this.formSpecialPlanAction.fill(action);
    },
    updateSpecialPlanActionStudentParent() {
      this.formSpecialPlanAction
        .patch("/psycho/updateSpecialPlanActionStudentParent")
        .then((res) => {
          //   console.log(res.data);
          if (res.data.info.code === "success") {
            this.$toastr.s(res.data.info.message, "Амжилттай заслаа");
          } else if (res.data.info.code === "error") {
            this.$toastr.e(res.data.info.message, "Амжилтгүй");
          }
          this.cancelSpecialPlanActionStudent();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    deleteSpecialPlanActionStudent(action) {
      //   console.log(action);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Төлөвлөсөн үйл ажиллагааг устгах гэж байна. Төлөвлөсөн ҮА-г хариуцаж байгаа багш нар устгагдана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/psycho/deleteSpecialPlanAction/" + action.id)
            .then((res) => {
              // this.monthPlanEmps = response.data.monthPlanEmps;
              if (res.data.info.code === "success") {
                // Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
                this.$toastr.s(res.data.info.message, "Амжилттай");
              } else if (res.data.info.code === "error") {
                this.$toastr.e(res.data.info.message, "Амжилтгүй");
              }
              this.getMySpecialPlansActionsStudent();
            })
            .catch((err) => {
              //   console.log(err);
            });
        }
      });
    },
  },
  created() {
    this.getAllSpecialDatas();
    Fire.$on("getSpecialPlans", () => {
      this.getAllSpecialDatas();
    });
  },
};
</script>

<style scoped>
.table-avatar {
  width: 40px !important;
  height: 40px;
  object-fit: cover;
}
</style>
