<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="card">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Гадны хэрэглэгч</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#timeline" data-toggle="tab"
                >Эцэг эх(Хүүхэд нууцалсан)</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#settings" data-toggle="tab"
                >Эцэг эх хүүхэд тодорхой</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#decisions" data-toggle="tab"
                >Шийдвэрлэсэн</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header  гадны хэрэглэгчид -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Өргөдлийн агуулга</th>
                        <!-- <th>Шийдвэр</th>
                                                <th>Шийдвэрийн тайлбар</th> -->
                        <th>Үйлдэл</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(complain, index) in allComplaintsPublic"
                      :key="complain.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                          <span v-if="userInfo.name == 'cipalprin'">
                            <a
                              class="btn btn-danger btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i>
                              захирал
                            </a></span
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                            >{{ complain.comp_par_main_problem }}</b-badge
                          >
                        </td>
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    {{
                                                        complain.comp_par_decision
                                                    }}
                                                </td>
                                                <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    <b-badge
                                                        variant="light"
                                                        style="white-space: pre-wrap; width: 16rem"
                                                    >
                                                        {{
                                                            complain.comp_par_decision_explain
                                                        }}</b-badge
                                                    >
                                                </td> -->

                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-icon
                            icon="trash-fill"
                            variant="danger"
                            v-if="userInfo.name == 'cipalprin'"
                            @click.prevent="complaintDelete(complain)"
                          ></b-icon>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane Эцэг эх(Хүүхэд нууцалсан) -->
            <div class="tab-pane" id="timeline">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Мэргэжил</th>
                        <th>Утас</th>
                        <th>Хүүхэд</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Өргөдлийн агуулга</th>
                        <!-- <th>Шийдвэр</th>
                                                <th>Шийдвэрийн тайлбар</th> -->
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(complain, index) in allComplaintsParent"
                      :key="complain.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.parent_info.parent_father_name }}
                          </span>
                          <b-icon
                            icon="trash-fill"
                            variant="danger"
                            v-if="userInfo.name == 'cipalprin'"
                            @click.prevent="complaintDelete(complain)"
                          ></b-icon>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge
                            variant="success"
                            v-show="
                              complain.parent_info.parent_father_mergejil !=
                              null
                            "
                          >
                            Аав:
                            {{ complain.parent_info.parent_father_mergejil }}

                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_mergejil
                            }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge variant="light">
                            Аав:
                            {{
                              complain.parent_info.parent_father_phone_number
                            }}
                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_phone_number
                            }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            v-if="
                              complain.my_child_school_class_info ||
                              complain.my_child_profile
                            "
                            >{{ complain.my_child_school_class_info }}:
                            {{ complain.my_child_profile }}</b-badge
                          >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            v-else
                            >Хүүхдийн мэдээлэл бүртгэгдээгүй.</b-badge
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Мэргэжлийн баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                          <span v-if="userInfo.name == 'cipalprin'">
                            <a
                              class="btn btn-danger btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i>
                              захирал
                            </a></span
                          >
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    {{
                                                        complain.comp_par_decision
                                                    }}
                                                </td>
                                                <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    <b-badge
                                                        variant="light"
                                                        style="white-space: pre-wrap; width: 16rem"
                                                    >
                                                        {{
                                                            complain.comp_par_decision_explain
                                                        }}
                                                    </b-badge>
                                                </td> -->

                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane Эцэг эх хүүхэд тодорхой -->

            <div class="tab-pane" id="settings">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Мэргэжил</th>
                        <th>Утас</th>
                        <th>Хүүхэд</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Өргөдлийн агуулга</th>
                        <!-- <th>Шийдвэр</th>
                                                <th>Шийдвэрийн тайлбар</th> -->
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(complain, index) in allComplaintsParentChild"
                      :key="complain.index"
                    >
                      <tr>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.parent_info.parent_father_name }}
                          </span>
                          <b-icon
                            icon="trash-fill"
                            variant="danger"
                            v-if="userInfo.name == 'cipalprin'"
                            @click.prevent="complaintDelete(complain)"
                          ></b-icon>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge
                            variant="success"
                            v-show="
                              complain.parent_info.parent_father_mergejil !=
                              null
                            "
                          >
                            Аав:
                            {{ complain.parent_info.parent_father_mergejil }}

                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_mergejil
                            }}</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          style="text-align: center"
                          tabindex="0"
                        >
                          <b-badge variant="light">
                            Аав:
                            {{
                              complain.parent_info.parent_father_phone_number
                            }}
                            <br />
                            Ээж:
                            {{
                              complain.parent_info.parent_mother_phone_number
                            }}
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            v-if="
                              complain.my_child_school_class_info ||
                              complain.my_child_profile
                            "
                            >{{
                              complain.my_child_school_class_info.full_name
                            }}:
                            {{
                              complain.my_child_profile.student_name
                            }}</b-badge
                          >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            v-else
                            >Хүүхдийн мэдээлэл бүртгэгдээгүй.</b-badge
                          >
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else
                            >Багш нарын баг бүртгэгдээгүй
                            <a
                              class="btn btn-warning btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i> </a
                          ></span>
                          <span v-if="userInfo.name == 'cipalprin'">
                            <a
                              class="btn btn-danger btn-sm"
                              @click.prevent="editComplain(complain)"
                            >
                              <i class="far fa-edit"></i>
                              захирал
                            </a></span
                          >
                        </td>

                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                          >
                            <span
                              v-html="
                                complain.comp_par_main_problem.substring(
                                  0,
                                  10000
                                )
                              "
                            ></span>
                          </b-badge>
                        </td>
                        <!-- <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    {{
                                                        complain.comp_par_decision
                                                    }}
                                                </td>
                                                <td
                                                    class="dtr-control sorting_1"
                                                    tabindex="0"
                                                >
                                                    <b-badge
                                                        variant="light"
                                                        style="white-space: pre-wrap; width: 16rem"
                                                    >
                                                        {{
                                                            complain.comp_par_decision_explain
                                                        }}
                                                    </b-badge>
                                                </td> -->
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- Шийдвэрлэсэн -->
            <div class="tab-pane" id="decisions">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Ирсэн өргөдөл, гомдол, санал хүсэлтүүд
                  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Овог нэр</th>
                        <th>Санал</th>
                        <th>Гомдол</th>
                        <th>Хамтран ажиллах</th>
                        <th>Ёс зүй</th>
                        <th>Өргөдлийн агуулга</th>
                        <th>Шийдвэр</th>
                        <th>Шийдвэрийн тайлбар</th>
                        <th>Харьяа мэргэжлийн баг</th>
                        <th>Огноо</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(complain, index) in allComplaintsDecisions"
                        :key="complain.index"
                      >
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ index + 1 }}
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.parent_info">
                            {{
                              complain.parent_info.parent_father_ovog
                                | capitalizeAndEkhniiVseg
                            }}.
                            {{ complain.parent_info.parent_father_name }}
                          </span>
                          <span v-else>Гадны хэрэглэгч</span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_poll == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_poll == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_complaint == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_complaint == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_contribution == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_contribution == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="white-space: pre-wrap"
                        >
                          <b-badge
                            variant="success"
                            v-show="complain.comp_par_ethics == 1"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-check-circle"></i
                          ></b-badge>
                          <b-badge
                            variant="danger"
                            v-show="complain.comp_par_ethics == 0"
                            style="white-space: pre-wrap"
                            ><i class="fas fa-times"></i
                          ></b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                          >
                            <span
                              v-if="complain.comp_par_main_problem !== null"
                              v-html="complain.comp_par_main_problem"
                            ></span>
                            <span v-else>...</span>
                            {{
                          }}</b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <b-badge
                            variant="success"
                            v-if="
                              complain.comp_par_decision != null ||
                              complain.comp_par_decision == 1
                            "
                            >Шийдвэрлэсэн</b-badge
                          >
                        </td>
                        <td
                          class="dtr-control sorting_1"
                          tabindex="0"
                          style="text-align: center"
                        >
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap; width: 16rem"
                          >
                            <span
                              v-if="complain.comp_par_decision_explain !== null"
                              v-html="complain.comp_par_decision_explain"
                            ></span>
                            <span v-else>Тайлбар өгөөгүй.</span>
                          </b-badge>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          <span v-if="complain.complain_department">{{
                            complain.complain_department.department_code
                          }}</span>
                          <span v-else>Багш нарын баг бүртгэгдээгүй</span>
                        </td>
                        <td class="dtr-control sorting_1" tabindex="0">
                          {{ complain.created_at | dateYearMonthDay }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.tab-pane -->
          </div>
          <!-- /.tab-content -->
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-complain"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Өргөдөл, гомдол, санал, хүсэлтүүдийг харьяа мэргэжлийн багт хуваарилах"
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Мэргэжлийн баг сонгоно уу!</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <!-- <b-form @submit="onUpdate" @reset="onReset"> -->
                <!-- <b-form @submit="onUpdate"> -->
                <multiselect
                  v-model="form.comp_par_department_id"
                  :options="departments"
                  track-by="department_name"
                  label="department_name"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Мэргэжлийн баг сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.department_name }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <b-button type="submit" variant="primary" @click="onUpdate()"
                  >Засах</b-button
                >
                <b-button type="reset" variant="danger" @click="onCancel()"
                  >Болих</b-button
                >
                <!-- </b-form> -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      allComplaintsParent: [],
      allComplaintsPublic: [],
      allComplaintsParentChild: [],
      allComplaintsDecisions: [],
      departments: [],
      //MODAL BEGIN
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showMode: false,
      //MODAL END

      form: new Form({
        comp_par_id: "",
        comp_par_main_problem: "",
        comp_par_department_id: "",
        created_at: "",
      }),
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
    },
  },
  methods: {
    getAllComplaint() {
      axios
        .get("/hr/getAllParentsComplaint")
        .then((response) => {
          this.allComplaintsPublic = response.data.allComplaintsPublic;
          this.allComplaintsParent = response.data.allComplaintsParent;
          this.allComplaintsParentChild =
            response.data.allComplaintsParentChild;
          this.allComplaintsDecisions = response.data.allComplaintsDecisions;
          this.departments = response.data.departments;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editComplain(complain) {
      // console.log(complain);
      // this.form.fill(complain);
      this.form.comp_par_id = complain.id;
      this.departments.filter((el) => {
        if (el.department_id == complain.department_id) {
          this.form.comp_par_department_id = el;
        }
      });
      //   console.log(this.form);
      this.$bvModal.show("modal-complain");
    },
    onUpdate() {
      //   console.log("onUpdate");
      this.form
        .put("/hr/updateComplForDepart")
        .then((response) => {
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.$bvModal.hide("modal-complain");
          Fire.$emit("loadComplains");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    complaintDelete(complain) {
      // console.log(complain);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Хэрэглэгчээр ирсэн  өргөдөл, гомдол, санал хүсэлтийг утсгах гэж байна. Сэргээх боломжгүйг анхаарна уу?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, Үүнийг устга!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/hr/complainHrDelete/" + complain.id)
            .then((res) => {
              //   console.log(res.data);
              //   this.filterByGoals(strategy);
              Fire.$emit("loadComplains");
            })
            .catch((err) => {
              console.log(err);
            });
          Swal.fire("Устгасан!", "Амжилттай устгалаа.", "success");
        }
      });
    },
    onCancel() {
      // console.log("onCancel");
      //   this.form.reset();
      this.$bvModal.hide("modal-complain");
    },
    // onReset() {
    //   console.log("onReset");
    // },
    resetModal() {
      //   console.log("resetModal");
      this.form.reset();
    },
    // selectDepartment(value) {
    //     console.log(value);
    //     if (value) {
    //         let dep = this.departments.filter(
    //             el => el.department_id == value.id
    //         );
    //         console.log(dep);
    //         return dep[0]["department_code"];
    //     } else {
    //         return "Мэргэжлийн баг бүртгэгдээгүй.";
    //     }
    // }
  },
  created() {
    this.getAllComplaint();
    Fire.$on("loadComplains", () => {
      this.getAllComplaint();
    });
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
