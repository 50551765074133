<template>
  <!-- Багшийн зүгээс гэрийн даалгавар оруулах -->
  <div class="row">
    <div class="col-12 mt-1">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ангийн гэрийн даалгаврын мэдээлэл</h3>
          <b-container class="bv-example-row" fluid>
            <b-row>
              <b-col sm="10"
                ><multiselect
                  v-model="selectedMonths"
                  :options="monthsRe"
                  :multiple="true"
                  track-by="name"
                  label="month"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Сар сонгох(Сар сонгоогүй тохиолдолд тухайн сар автоматаар сонгогдоно)"
                  :allow-empty="true"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong> {{ option.month }}</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </multiselect>
              </b-col>
              <b-col sm="2"
                ><b-button
                  variant="success"
                  @click.prevent="getAllMyClassesHW()"
                  >Шүүж харах</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-12">
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th style="width: 10px">#</th>
                    <th>Анги</th>
                    <th>Улирал</th>
                    <th>Гэрийн даалгавар гүйцэтгэх огноо</th>
                    <th>ГД(HW) бүртгэсэн огноо</th>
                    <th>Багш</th>
                    <th>Хичээл</th>
                    <th>Хичээл орсон цаг</th>
                    <th>Гүйцэтгэл</th>
                    <th>Үйлдэл</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(thw, index) in teachersHomeWorks" :key="index">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ thw.my_home_work_school_class.full_name }}</td>
                    <td>{{ thw.my_home_work_quarter.name }}-р улирал</td>
                    <td>
                      {{ thw.course_hw_start_date }} :
                      {{ thw.course_hw_start_time }} -
                      {{ thw.course_hw_end_date }} :
                      {{ thw.course_hw_end_time }}
                    </td>
                    <td>{{ thw.created_at | dateYearMonthDay }}</td>
                    <td>
                      <span v-if="thw.my_class_homework_course">
                        <span v-if="thw.my_class_homework_course.teacher">
                          {{
                            thw.my_class_homework_course.teacher
                              .teacher_last_name[0]
                          }}.
                          {{
                            thw.my_class_homework_course.teacher.teacher_name
                          }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span v-if="thw.my_class_homework_course">
                        <span v-if="thw.my_class_homework_course.my_subject">
                          {{
                            thw.my_class_homework_course.my_subject.subject_name
                          }}
                        </span>
                      </span>
                    </td>
                    <td>{{ thw.course_hour }}</td>
                    <td>{{ thw.hw_grade_avg }}%</td>
                    <td>
                      <a href="#" class="badge bg-info" @click="moreInfoHW(thw)"
                        ><i class="fas fa-low-vision"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- loadingHw -->

              <b-spinner
                variant="primary"
                label="Spinning"
                v-if="loadingHw"
              ></b-spinner>

              <!-- Гэрийн даалгаврын гүйцэтгэл суралцагч дээр нэмэх -->
              <b-modal
                size="xl"
                id="modal-homework-student"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                :body-bg-variant="bodyBgVariant"
                title="Гэрийн даалгаврын гүйцэтгэл"
                class="modal fade"
                hide-footer
                @hidden="resetFormHwGradeStudent"
              >
                <div class="modal-body">
                  <div class="direct-chat-msg">
                    <div class="direct-chat-infos clearfix">
                      <span class="direct-chat-name float-left">
                        Гэрийн даалгавар/Homework</span
                      >
                      <span class="direct-chat-timestamp float-right"
                        ><b>Даалгавар эхлэх огноо: </b
                        >{{
                          selectHWStudents.course_hw_start_date
                            | dateYearMonthDay
                        }}
                        -
                        {{ selectHWStudents.course_hw_start_time }}
                        цаг</span
                      ><br />
                      <span class="direct-chat-timestamp float-right"
                        ><b>Даалгавар дуусах огноо: </b>
                        {{
                          selectHWStudents.course_hw_end_date | dateYearMonthDay
                        }}
                        -
                        {{ selectHWStudents.course_hw_end_time }} цаг</span
                      >
                    </div>
                    <div class="direct-chat-text primary ml-0">
                      <quill-editor
                        v-model="selectHWStudents.hw"
                        :options="editorOptionStudent"
                      >
                      </quill-editor>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-8">
                      <!-- <div class="row">
                        <b-col sm="3">
                          <span
                            class="direct-chat-name float-right"
                            style="font-size: 80%"
                          >
                            Бүх суралцагчид дээр дүн бүртгэх:</span
                          >
                        </b-col>

                        <b-col sm="5">
                          <b-form-input
                            id="hw_grade"
                            type="number"
                            size="sm"
                            v-model="formHwGradeStudent.hw_grade"
                            :class="[
                              'form-control',
                              {
                                'is-invalid':
                                  formHwGradeStudent.errors.has('hw_grade'),
                              },
                            ]"
                            placeholder="Гүйцэтгэлийн оноо %-аар тавих"
                          ></b-form-input>
                        </b-col>
                        <b-col sm="4">
                          <span class="direct-chat-timestamp">
                            <b-button
                              size="sm"
                              variant="outline-primary"
                              @click.prevent="addGradeClick(selectHWStudents)"
                            >
                              <b-icon icon="plus-circle-fill"></b-icon>
                              Гүйцэтгэл бүртгэх
                            </b-button>
                          </span></b-col
                        >
                      </div> -->
                      <div class="card">
                        <div class="card-header"></div>
                        <div class="card-body table-responsive">
                          <table class="table text-wrap">
                            <thead>
                              <tr role="row">
                                <th>#</th>
                                <th>Зураг</th>
                                <th>Суралцагч</th>
                                <th>Гүйцэтгэл</th>
                                <th>Тайлбар</th>
                                <th>Үйлдэл</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="(
                                hwInfo, index
                              ) in selectHWStudents.my_home_work_students"
                              :key="hwInfo.id"
                            >
                              <tr v-if="hwInfo.my_profile">
                                <td class="dtr-control sorting_1" tabindex="0">
                                  {{ index + 1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                  <img
                                    v-if="
                                      hwInfo.my_profile
                                        .student_profile_photo_url == null
                                    "
                                    src="/images/users/user.png"
                                    alt="user-avatar"
                                    class="direct-chat-img"
                                  />
                                  <img
                                    v-else
                                    :src="
                                      hwInfo.my_profile
                                        .student_profile_photo_url
                                    "
                                    alt="user-avatar"
                                    class="direct-chat-img"
                                  />
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                  {{ hwInfo.my_profile.student_last_name[0] }}.
                                  {{ hwInfo.my_profile.student_name }}
                                  <b-spinner
                                    v-if="hwInfo.hw_student_comment != null"
                                    variant="primary"
                                    key="primary"
                                    type="grow"
                                  ></b-spinner>
                                  <b-spinner
                                    v-if="hwInfo.hw_parent_comment != null"
                                    variant="info"
                                    key="info"
                                    type="grow"
                                  ></b-spinner>
                                </td>
                                <td
                                  class="dtr-control sorting_1"
                                  style="text-align: center"
                                  tabindex="0"
                                >
                                  <b-badge
                                    v-if="hwInfo.hw_grade >= 80"
                                    variant="success"
                                    v-show="hwInfo.hw_grade != null"
                                  >
                                    {{ hwInfo.hw_grade }}</b-badge
                                  >
                                  <b-badge
                                    v-if="
                                      (hwInfo.hw_grade >= 60) &
                                      (hwInfo.hw_grade < 80)
                                    "
                                    variant="primary"
                                    v-show="hwInfo.hw_grade != null"
                                  >
                                    {{ hwInfo.hw_grade }}</b-badge
                                  >
                                  <b-badge
                                    v-if="
                                      (hwInfo.hw_grade >= 30) &
                                      (hwInfo.hw_grade < 60)
                                    "
                                    variant="warning"
                                    v-show="hwInfo.hw_grade != null"
                                  >
                                    {{ hwInfo.hw_grade }}</b-badge
                                  >
                                  <b-badge
                                    v-if="hwInfo.hw_grade < 30"
                                    variant="danger"
                                    v-show="hwInfo.hw_grade != null"
                                  >
                                    {{ hwInfo.hw_grade }}</b-badge
                                  >
                                  <b-badge
                                    variant="warning"
                                    v-show="hwInfo.hw_grade === null"
                                  >
                                    0</b-badge
                                  >
                                  <div
                                    class="progress progress-xs progress-striped active"
                                  >
                                    <div
                                      v-if="hwInfo.hw_grade < 30"
                                      class="progress-bar bg-danger"
                                      :style="{
                                        width: `${hwInfo.hw_grade}%`,
                                      }"
                                    ></div>
                                    <div
                                      v-if="
                                        (hwInfo.hw_grade >= 30) &
                                        (hwInfo.hw_grade < 60)
                                      "
                                      class="progress-bar bg-warning"
                                      :style="{
                                        width: `${hwInfo.hw_grade}%`,
                                      }"
                                    ></div>
                                    <div
                                      v-if="
                                        (hwInfo.hw_grade >= 60) &
                                        (hwInfo.hw_grade < 80)
                                      "
                                      class="progress-bar bg-primary"
                                      :style="{
                                        width: `${hwInfo.hw_grade}%`,
                                      }"
                                    ></div>
                                    <div
                                      v-if="hwInfo.hw_grade >= 80"
                                      class="progress-bar bg-success"
                                      :style="{
                                        width: `${hwInfo.hw_grade}%`,
                                      }"
                                    ></div>
                                  </div>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                  <b-icon
                                    icon="eye"
                                    variant="info"
                                    @click="moreHwGradeStudent(hwInfo)"
                                  >
                                  </b-icon>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                  <!-- <a
                                    href="#"
                                    class="badge bg-primary"
                                    @click="editHwGradeStudent(hwInfo)"
                                    ><i class="fa fa-check"></i
                                  ></a> -->
                                  <a
                                    href="#"
                                    class="badge bg-danger"
                                    @click="removeHwGradeStudent(hwInfo)"
                                    ><i class="fa fa-minus"></i
                                  ></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-2">
                      <!-- <div class="card card-primary" v-show="editModeHwGrade">
                        <div class="card-header">
                          <h3 class="card-title">
                            Суралцагчийн гүйцэтгэл оруулах
                          </h3>
                        </div>
                        <div class="card-body">
                          <form>
                            <div class="form-group">
                              <label>Гүйцэтгэл</label>
                              <input
                                type="number"
                                name="hw_grade"
                                max="100"
                                v-model="formHwGradeSelectedStudent.hw_grade"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formHwGradeSelectedStudent.errors.has(
                                        'hw_grade'
                                      ),
                                  },
                                ]"
                                placeholder="Гүйцэтгэлийн оноо %-аар тавих"
                              />
                            </div>
                            <div class="form-group">
                              <b-form-textarea
                                id="textarea"
                                v-model="formHwGradeSelectedStudent.hw_explain"
                                placeholder="Гүйцэтгэлийн талаарх тайлбар оруулна уу."
                                rows="3"
                                max-rows="6"
                                :class="[
                                  'form-control',
                                  {
                                    'is-invalid':
                                      formHwGradeSelectedStudent.errors.has(
                                        'hw_explain'
                                      ),
                                  },
                                ]"
                              ></b-form-textarea>
                            </div>
                          </form>
                        </div>
                        <div class="card-footer">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            @click.prevent="updateHwGradeStudent()"
                          >
                            Хадгалах
                          </button>
                          <button
                            type="submit"
                            class="btn btn-dark"
                            @click.prevent="cancelHwGradeStudent()"
                          >
                            Болих
                          </button>
                        </div>
                      </div> -->
                      <div class="card card-primary">
                        <b-card-group deck>
                          <b-card
                            header="Гэрийн даалгавраас хасагдсан суралцагчид"
                          >
                            <b-list-group>
                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center"
                                v-for="(
                                  hwInfo, index
                                ) in selectHWStudents.my_home_work_deleted_students"
                                :key="hwInfo.id"
                                >{{ index + 1 }}.
                                <img
                                  v-if="
                                    hwInfo.my_profile
                                      .student_profile_photo_url == null
                                  "
                                  src="/images/users/user.png"
                                  alt="user-avatar"
                                  class="direct-chat-img" />
                                <img
                                  v-else
                                  :src="
                                    hwInfo.my_profile.student_profile_photo_url
                                  "
                                  alt="user-avatar"
                                  class="direct-chat-img" />
                                {{ hwInfo.my_profile.student_last_name[0] }}.
                                {{ hwInfo.my_profile.student_name }}
                                <b-badge variant="primary" pill>
                                  <b-link href="#">
                                    <b-icon
                                      variant="warning"
                                      pill
                                      icon="person-plus-fill"
                                      @click="restoreHWDStudent(hwInfo)"
                                    ></b-icon>
                                  </b-link> </b-badge
                              ></b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </b-card-group>
                      </div>

                      <div
                        class="card card-primary"
                        v-show="moreInfoHWShowMode"
                      >
                        <div class="card-header">
                          <h3 class="card-title">
                            Суралцагч, эцэг эх гэрийн даалгаварт оруулсан
                            мэдээлэл
                          </h3>
                        </div>
                        <div class="card-body">
                          <p
                            v-if="moreInfoStudentHW.hw_student_comment != null"
                          >
                            Суралцагч
                          </p>
                          <p
                            v-if="moreInfoStudentHW.hw_student_comment == null"
                          >
                            Суралцагч гэрийн даалгавар оруулаагүй байна.
                          </p>
                          <b-card-group>
                            <b-card-text>
                              <quill-editor
                                v-if="
                                  moreInfoStudentHW.hw_student_comment != null
                                "
                                ref="myQuillEditor"
                                v-model="moreInfoStudentHW.hw_student_comment"
                                :options="editorOption"
                              />
                            </b-card-text>
                          </b-card-group>
                          <p v-if="moreInfoStudentHW.hw_parent_comment != null">
                            Эцэг эх
                          </p>
                          <p v-if="moreInfoStudentHW.hw_parent_comment == null">
                            Эцэг эх гэрийн даалгаврын мэдээлэл оруулаагүй байна.
                          </p>
                          <b-card-group>
                            <b-card-text
                              ><quill-editor
                                v-if="
                                  moreInfoStudentHW.hw_parent_comment != null
                                "
                                ref="myQuillEditor"
                                v-model="moreInfoStudentHW.hw_parent_comment"
                                :options="editorOption"
                            /></b-card-text>
                          </b-card-group>
                        </div>
                        <div class="card-footer">
                          <button
                            type="submit"
                            class="btn btn-dark"
                            @click="moreInfoStudentHWClose"
                          >
                            Хаах
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      teachersHomeWorks: [],
      loadingHw: false,
      editModeHwGrade: false,
      //   Гэрийн даалгавар суралцагч, эцэг эх мэдээлэл оруулсан хэсгийг харуулах
      moreInfoHWShowMode: false,
      moreInfoStudentHW: "",
      myQuarters: [],
      attendance_lesson_numbers: [
        { value: "1", text: "1-р цаг" },
        { value: "2", text: "2-р цаг" },
        { value: "3", text: "3-р цаг" },
        { value: "4", text: "4-р цаг" },
        { value: "5", text: "5-р цаг" },
        { value: "6", text: "6-р цаг" },
        { value: "7", text: "7-р цаг" },
        { value: "8", text: "8-р цаг" },
        { value: "9", text: "9-р цаг" },
        { value: "10", text: "10-р цаг" },
      ],

      formHwGradeStudent: new Form({
        id: "",
        course_hw_id: "",
        hw_grade: "",
      }),

      formHwGradeSelectedStudent: new Form({
        id: "",
        course_hw_id: "",
        student_id: "",
        hw_explain: "",
        hw_status: "",
        hw_grade: "",
        hw_parent_comment: "",
      }),

      labels: {
        de: {
          labelHours: "Цаг",
          labelMinutes: "Минут",
          labelSeconds: "Секунд",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Сонгосон",
          labelNoTimeSelected: "Цаг сонгох",
          labelCloseButton: "Хаах",
        },
      },

      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      selectedMonths: null,

      //   vue-quill-editor
      editorOption: {
        // Some Quill options...
        modules: {
          //   theme: "snow",

          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ direction: "rtl" }, { align: [] }],
            ["link", "video", "formula", "image"], //"image"
            ["clean"],
          ],
          imageDrop: true,
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              //   color: white,
              // other camelCase styles for size display
            },
          },
          imageCompress: {
            quality: 0.6, // default
            maxWidth: 800, // default
            // maxHeight: 1000, // default
            imageType: ["image/jpeg", "image/png"], // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        placeholder:
          "Гэрийн даалгавар WWW.GRADING4YOU.COM системээр өгөхдөө АНХААРАХ зүйлс \n Зурган файл хэт ихээр илгээхгүй байх /workbook, coursebook ний зураг илгээхгүй байх/ \n Зааварчилгааг тодорхой өгөх Жишээ нь: Хуудас 156 Дасгал 27Б даалгавар дээр үгсийг хараад өгүүлбэр нөхөөрэй, шинэ үгсийг дэвтэртээ тэмдэглэх, Metaphor family шүлэг цээжлэх /цээжлэх шүлэгний зураг илгээж болно/ \n Үг үсэгний алдааг хянах",
      },

      myClassesHomeWorksUndone: [],
      myChildCourseHomeworkDone: [],
      myChildCourseHomeworkNoted: [],
      //   гэрийн даалгавар
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selectHWStudents: "",
      //   vue-quill-editor
      editorOptionStudent: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
    };
  },
  computed: {
    hwEndDate() {
      //   return this.$refs.myQuillEditor;
      //   let nowDate = new Date().toJSON().slice(0, 10);
      //   console.log(nowDate);
      //   let regDate = this.homeworkForm.course_hw_start_date;
      //   if (regDate > nowDate) {
      //   }
      if (
        this.homeworkForm.course_hw_start_date <=
        this.homeworkForm.course_hw_end_date
      ) {
        this.errCloseDate = "";
        return true;
      } else {
        this.errCloseDate =
          "Бүртгэл дуусах огноо өнөөдрийн огнооноос их байх ёстой.";
        return false;
      }
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllMyClassesHW() {
      axios
        .post("/teacher/getAllMyClassesHW", {
          selectedMonths: this.selectedMonths,
        })
        .then((res) => {
          console.log(res.data);
          // гэрийн даалгавар хүлээгдэж байгаа
          this.teachersHomeWorks = res.data.teachersHomeWorks;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    restoreHWDStudent(hwInfo) {
      axios
        .post("/teacher/restoreHomeWorkStudent", {
          hwInfoDelete: hwInfo,
        })
        .then((res) => {
          this.teachersHomeWorks = res.data.teachersHomeWorks;
          this.selectHWStudents = res.data.teachersHomeWorksupdate;
          //   console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Гэрийн даалгаварт суралцагч үнэлгээ өгөх, засах
    moreInfoHW(hwInfo) {
      //   console.log(hwInfo);
      //   array.forEach((element) => {});
      this.selectHWStudents = hwInfo;
      //   this.selectHWStudents.my_home_work_students.forEach((element) => {
      //     console.log(
      //       element.my_profile.student_profile_photo_url +
      //         " :" +
      //         element.my_profile.student_name +
      //         " :" +
      //         typeof element.my_profile.student_profile_photo_url
      //     );
      //   });

      this.formHwGradeStudent.course_hw_id = hwInfo.id;
      this.$bvModal.show("modal-homework-student");
    },
    resetFormHwGradeStudent() {
      this.formHwGradeStudent.reset();
    },

    addGradeClick(selectHWStudents) {
      //   console.log("addGradeClick");
      axios
        .post("/teacher/addGradeAllStudents", {
          course: selectHWStudents,
          formHwGradeStudent: this.formHwGradeStudent,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectHWStudents = res.data.teachersHomeWorks;
          this.formHwGradeStudent.hw_grade = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editHwGradeStudent(hwInfo) {
      //   console.log("editHwGradeStudent", hwInfo);
      this.formHwGradeSelectedStudent.fill(hwInfo);
      this.editModeHwGrade = true;
    },
    updateHwGradeStudent() {
      //   console.log("updateHwGradeStudent");
      axios
        .post("/teacher/updateHwGradeStudent", {
          //   course: selectHWStudents,
          formHwGradeSelectedStudent: this.formHwGradeSelectedStudent,
          //   selectHWStudents: this.selectHWStudents,
          quarter_id: this.selectHWStudents.quarter_id,
          school_class_id: this.selectHWStudents.school_class_id,
          teacher_id: this.selectHWStudents.teacher_id,
          course_id: this.selectHWStudents.course_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectHWStudents = res.data.teachersHomeWorks;
          this.cancelHwGradeStudent();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeHwGradeStudent(hwInfo) {
      //   console.log("removeHwGradeStudent");
      axios
        .post("/teacher/removeHwGradeStudent", {
          hwInfo,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectHWStudents = res.data.teachersHomeWorks;
        })
        .catch((err) => console.log(err));
    },
    moreHwGradeStudent(hwInfo) {
      //   console.log(hwInfo);
      this.moreInfoStudentHW = hwInfo;
      this.moreInfoHWShowMode = true;
    },
    moreInfoStudentHWClose() {
      this.moreInfoStudentHW = "";
      this.moreInfoHWShowMode = false;
    },
    cancelHwGradeStudent() {
      //   console.log("cancelHwGradeStudent");
      this.formHwGradeSelectedStudent.reset();
      this.editModeHwGrade = false;
    },
  },
  created() {
    this.getAllMyClassesHW();
    Fire.$on("readComment", () => {
      this.getAllMyClassesHW();
    });
  },
};
</script>

<style scoped>
#course_comment {
  color: #40c2d5;
  /* color: #17a2b8; */
}

.table-avatar {
  width: 50%;
  height: auto;
  /* width: 40px !important;
  height: 40px;
  object-fit: cover; */
}
</style>
