<template>
  <div class="row">
    <div class="col-12 col-sm-12 mt-3">
      <div class="card card-primary card-outline card-tabs">
        <div class="card-header p-0 pt-1 border-bottom-0">
          <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="custom-tabs-three-home-tab"
                data-toggle="pill"
                href="#custom-tabs-three-home"
                role="tab"
                aria-controls="custom-tabs-three-home"
                aria-selected="true"
                >Хамрагдах болон хамрагдсан сургалтууд</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-three-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-three-profile"
                role="tab"
                aria-controls="custom-tabs-three-profile"
                aria-selected="false"
                >Миний заасан сургалтууд</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-three-tabContent">
            <div
              class="tab-pane fade show active"
              id="custom-tabs-three-home"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-home-tab"
              style="overflow-x: auto; text-align: center"
            >
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr role="row">
                    <th>#</th>
                    <th style="font-size: 80%">Үйлдэл</th>
                    <th style="font-size: 80%">Модуль</th>
                    <th style="font-size: 80%">Сэдэв</th>
                    <th style="font-size: 80%">Агуулга</th>
                    <th style="font-size: 80%">Хугацаа</th>
                    <th style="font-size: 60%">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 5rem"
                        >Мэдлэгээ бодит үйл хэрэг болгоно</b-badge
                      >
                    </th>
                    <th style="font-size: 60%">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 5rem"
                        >Мэдлэгэгийн ач холбогдол, үнэ цэнийг хүлээн зөвшөөрч
                        байна</b-badge
                      >
                    </th>
                    <th style="font-size: 60%">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 5rem"
                        >Хэрэг болохоор мэдээлэл байна</b-badge
                      >
                    </th>
                    <th style="font-size: 60%">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 5rem"
                        >Тодорхой зүйл хэлж мэдэхгүй байна</b-badge
                      >
                    </th>
                    <th>Зассан</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(train, index) in allMyTrainings"
                  :key="train.tcs_id"
                >
                  <tr>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <a
                        href="#"
                        class="badge bg-success"
                        @click="showTraining(train)"
                        ><i class="fa fa-eye"></i
                      ></a>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ train.t_name }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ train.tc_title }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ train.tc_content }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ train.tc_time }} мин</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <p class="h5 mb-2">
                        <b-icon
                          icon="check-square"
                          scale="1"
                          variant="success"
                          v-show="
                            train.tcs_stat_1 != null && train.tcs_stat_1 != 0
                          "
                        ></b-icon>
                        <b-icon
                          v-show="
                            train.tcs_stat_1 == null || train.tcs_stat_1 == 0
                          "
                          icon="x-circle"
                          scale="1"
                          variant="danger"
                        ></b-icon>
                      </p>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <p class="h5 mb-2">
                        <b-icon
                          icon="check-square"
                          scale="1"
                          variant="success"
                          v-show="
                            train.tcs_stat_2 != null && train.tcs_stat_2 != 0
                          "
                        ></b-icon>
                        <b-icon
                          v-show="
                            train.tcs_stat_2 == null || train.tcs_stat_2 == 0
                          "
                          icon="x-circle"
                          scale="1"
                          variant="danger"
                        ></b-icon>
                      </p>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <p class="h5 mb-2">
                        <b-icon
                          icon="check-square"
                          scale="1"
                          variant="success"
                          v-show="
                            train.tcs_stat_3 != null && train.tcs_stat_3 != 0
                          "
                        ></b-icon>
                        <b-icon
                          v-show="
                            train.tcs_stat_3 == null || train.tcs_stat_3 == 0
                          "
                          icon="x-circle"
                          scale="1"
                          variant="danger"
                        ></b-icon>
                      </p>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <p class="h5 mb-2">
                        <b-icon
                          icon="check-square"
                          scale="1"
                          variant="success"
                          v-show="
                            train.tcs_stat_4 != null && train.tcs_stat_4 != 0
                          "
                        ></b-icon>
                        <b-icon
                          v-show="
                            train.tcs_stat_4 == null || train.tcs_stat_4 == 0
                          "
                          icon="x-circle"
                          scale="1"
                          variant="danger"
                        ></b-icon>
                      </p>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        >{{ train.updated_at | dateYearMonthDay }}</b-badge
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-three-profile"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-profile-tab"
              style="overflow-x: auto; text-align: center"
            >
              <div class="float-sm-right">
                <b-button
                  right
                  pill
                  variant="outline-success"
                  size="sm"
                  @click.prevent="responseMyTraining()"
                >
                  Сургалт хийх хүсэлт
                </b-button>
              </div>
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr role="row">
                    <th>#</th>
                    <th style="font-size: 80%">Зөвшөөрөгдсөн</th>
                    <th style="font-size: 80%">Модуль</th>
                    <th style="font-size: 80%">Сэдэв</th>
                    <th style="font-size: 80%">Агуулга</th>
                    <th style="font-size: 80%">Хамрагдах багш</th>
                    <th style="font-size: 80%">Хугацаа</th>
                    <th>Зассан</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(teachTrain, index) in allMyTeachTrainings"
                  :key="teachTrain.tc_id"
                >
                  <tr>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <!-- training_confirm_teacher -->
                      <p class="h5 mb-2">
                        <b-icon
                          icon="check-square"
                          scale="1"
                          variant="success"
                          v-show="teachTrain.training_confirm_teacher != 0"
                        ></b-icon>
                        <b-icon
                          v-show="teachTrain.training_confirm_teacher == 0"
                          icon="x-circle"
                          scale="1"
                          variant="danger"
                        ></b-icon>
                      </p>
                      <a
                        v-if="teachTrain.training_confirm_teacher === 1"
                        href="#"
                        class="badge bg-success"
                        @click="enrollSubTrain(teachTrain)"
                        ><b-icon icon="person-fill"></b-icon
                      ></a>
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ teachTrain.taining_name }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ teachTrain.taining_course_title }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ teachTrain.taining_course_content }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ teachTrain.taining_course_must_people }}</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        style="width: 8rem"
                        >{{ teachTrain.taining_course_time }} мин</b-badge
                      >
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      <b-badge
                        variant="light"
                        class="badge badge-primary text-wrap"
                        >{{ teachTrain.updated_at | dateYearMonthDay }}</b-badge
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
    <!-- Сургалтанд хамрагдсан тохиолдолд бөглөх -->
    <b-modal
      size="sm"
      id="modal-trainInfo"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      hide-footer
      @hide="cancelTrainInfo"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <div>
                  <label style="font-size: 80%"
                    >Мэдлэгээ бодит үйл хэрэг болгоно</label
                  >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.tcs_stat_1"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="null"
                  >
                    Тийм/Үгүй
                  </b-form-checkbox>
                </div>
              </div>
              <div class="form-group">
                <div>
                  <label style="font-size: 80%"
                    >Мэдлэгэгийн ач холбогдол, үнэ цэнийг хүлээн зөвшөөрч
                    байна</label
                  >
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="form.tcs_stat_2"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="null"
                  >
                    Тийм/Үгүй
                  </b-form-checkbox>
                </div>
              </div>
              <div class="form-group">
                <label style="font-size: 80%"
                  >Хэрэг болохоор мэдээлэл байна</label
                >
                <div>
                  <b-form-checkbox
                    id="checkbox-3"
                    v-model="form.tcs_stat_3"
                    name="checkbox-3"
                    value="1"
                    unchecked-value="null"
                  >
                    Тийм/Үгүй
                  </b-form-checkbox>
                </div>
              </div>
              <div class="form-group">
                <label style="font-size: 80%"
                  >Тодорхой зүйл хэлж мэдэхгүй байна</label
                >
                <div>
                  <b-form-checkbox
                    id="checkbox-4"
                    v-model="form.tcs_stat_4"
                    name="checkbox-4"
                    value="1"
                    unchecked-value="null"
                  >
                    Тийм/Үгүй
                  </b-form-checkbox>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelTrainInfo()" variant="danger">Хаах</b-button>
        <b-button @click="updateTrainInfo()" variant="warning"
          >Хадгалах</b-button
        >
      </div>
    </b-modal>

    <!-- Багш өөрөө сургалт хийх хүсэлт илэрхийлэх модуль -->
    <b-modal
      size="xl"
      id="modal-response-my-train"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      class="modal fade"
      title="Бүртгэлтэй байгаа модуль сургалтууд. Сургалтанд хамрагдах багш нарыг менежер нэмж өгнө. Тиймээс менежерт хандана уу!"
      hide-footer
      @hide="cancelResMyTrainInfo"
    >
      <div class="modal-body">
        <div class="row">
          <table
            class="table text-nowrap"
            style="text-align: center; overflow-x: auto"
          >
            <thead>
              <tr role="row">
                <th>#</th>
                <th style="font-size: 80%">Үйлдэл</th>
                <th style="font-size: 80%">Модуль</th>
                <th style="font-size: 80%">Хугацаа</th>
              </tr>
            </thead>
            <tbody
              v-for="(subTrain, index) in allSubTrainOfTrainings"
              :key="subTrain.id"
            >
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-icon
                    icon="share-fill"
                    variant="success"
                    @click="showResMyTrainInfo(subTrain)"
                  ></b-icon>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="light"
                    class="badge badge-primary text-wrap"
                    style="width: 8rem"
                    >{{ subTrain.taining_name }}</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="light"
                    class="badge badge-primary text-wrap"
                    >{{ subTrain.updated_at | dateYearMonthDay }}</b-badge
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-lg-12 mt-2" v-if="showSubtrainForm">
            {{ formSubTraining.training_name }}: дэд сургалт бүртгүүлэх
            <form>
              <div class="form-group">
                <b-form-input
                  style="font-size: 70%"
                  v-model="formSubTraining.taining_course_title"
                  placeholder="Дэд сургалтын сэдэв"
                ></b-form-input>
                <b-form-textarea
                  style="font-size: 70%"
                  id="textarea"
                  v-model="formSubTraining.taining_course_content"
                  placeholder="Дэд сургалтын агуулга болон сургалт болох газар, өрөө, байршил, огноо, бэлтгэх  зүйлс гэх мэт бусад шаардлагатай мэдээллээ оруулна уу?"
                  rows="3"
                  max-rows="6"
                  :class="[
                    'form-control',
                    {
                      'is-invalid': formSubTraining.errors.has(
                        'taining_course_content'
                      ),
                    },
                  ]"
                ></b-form-textarea>
                <b-form-input
                  type="number"
                  v-model="formSubTraining.taining_course_must_people"
                  placeholder="Хамрагдвал зохих хүний тоо"
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-model="formSubTraining.taining_course_time"
                  placeholder="Сургалтын үргэлжлэх хугацаа минутаар оруулах"
                ></b-form-input>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancelResMyTrainInfo()" variant="danger"
          >Хаах</b-button
        >
        <b-button
          v-if="showMySaveButton"
          @click="createResMyTrainInfo()"
          variant="warning"
          >Хадгалах</b-button
        >
      </div>
    </b-modal>

    <!-- Багш сургалтандаа суралцагч бүртгэх -->
    <b-modal
      v-show="showModalTeachers"
      size="xl"
      id="modal-train-teachers"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Сургалт"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-body">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-left"
              >Сургалт хийх хүний Овог Нэр</span
            >
            <span class="direct-chat-timestamp float-right">{{
              useInfosofSubTrains.created_at | dateYearMonthDay
            }}</span>
          </div>
          <img
            v-if="useInfosofSubTrains.profile_photo_url != null"
            :src="useInfosofSubTrains.profile_photo_url"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <img
            v-else
            src="/images/users/user.png"
            alt="user-avatar"
            class="direct-chat-img"
          />
          <div class="direct-chat-text primary">
            {{ useInfosofSubTrains.taining_course_content }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Сургалтанд хамрагдах багш нар</h3>
              </div>
              <div
                class="card-body table-responsive"
                v-if="useInfosofSubTrains != null"
              >
                <table class="table text-wrap">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Мэргэжлийн баг</th>
                      <th>Багш</th>
                      <th>Албан тушаал</th>
                      <th>Үйлдэл</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(enrollT, index) in enrolledTeachers"
                    :key="enrollT.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.department_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_teacher_last_name[0] }}.
                        {{ enrollT.emp_teacher_name }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ enrollT.emp_position_name }}
                      </td>

                      <td class="dtr-control sorting_1" tabindex="0">
                        <a
                          href="#"
                          class="badge bg-danger"
                          @click="removeTeacher(enrollT)"
                          ><i class="fa fa-minus"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Багш нэмэх</h3>
              </div>
              <div class="card-body">
                <form v-on:change="modalChangeTeacherList($event)">
                  <div class="form-group row">
                    <div class="form-group">
                      <label>Харьяалагдах мэргэжлийн баг</label>
                      <b-form-select
                        v-model="formAddTeacher.department_id"
                        :options="departments"
                        text-field="department_name"
                        value-field="id"
                      ></b-form-select>
                      <has-error
                        :form="formAddTeacher"
                        field="department_name"
                      ></has-error>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group">
                    <label>Багш, ажилтан</label>
                    <b-form-select
                      v-model="formAddTeacher.recieved_employees"
                      :options="modalTeachers"
                      text-field="emp_teacher_name"
                      value-field="emp_user_id"
                      multiple
                      :select-size="4"
                    ></b-form-select>
                    <has-error
                      :form="formAddTeacher"
                      field="recieved_employees"
                    ></has-error>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="addTeacher()"
                >
                  Нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      selectedMonths: null,
      monthsRe: [
        { name: 1, month: "1-р сар" },
        { name: 2, month: "2-р сар" },
        { name: 3, month: "3-р сар" },
        { name: 4, month: "4-р сар" },
        { name: 5, month: "5-р сар" },
        { name: 6, month: "6-р сар" },
        { name: 7, month: "7-р сар" },
        { name: 8, month: "8-р сар" },
        { name: 9, month: "9-р сар" },
        { name: 10, month: "10-р сар" },
        { name: 11, month: "11-р сар" },
        { name: 12, month: "12-р сар" },
      ],
      allMyTrainings: [],
      form: new Form({
        tcs_id: null,
        tcs_stat_1: null,
        tcs_stat_2: null,
        tcs_stat_3: null,
        tcs_stat_4: null,
      }),
      //Багш дэд сургалт үүсгэх функцүүд эндээс эхлэж байна.
      showMySaveButton: false,
      formSubTraining: new Form({
        id: "",
        training_id: "",
        training_name: "",
        taining_course_title: "",
        taining_course_content: "",
        taining_course_must_people: null,
        taining_course_time: null,
      }),
      allSubTrainOfTrainings: [],
      allMyTeachTrainings: [],
      showSubtrainForm: false,
      ariaSelected: false,

      //enrolled teachers
      showModalTeachers: false,
      useInfosofSubTrains: "",
      enrolledTeachers: [],
      showTeachers: false,
      teachers: [],
      modalTeachers: [],
      departments: [
        { value: null, department_name: "Мэргэжлийн баг сонгоно уу?" },
      ],
      filterForm: new Form({
        department_id: 0,
      }),
      formAddTeacher: new Form({
        training_course_id: null,
        department_id: 0,
        recieved_employees: [],
      }),
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getAllMyTrainingFilter() {
      axios
        .post("/all_worker_functions/getMyTrainings", {
          selectedMonths: this.selectedMonths,
        })
        .then((response) => {
          this.allMyTrainings = response.data.myTrainings;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    showTraining(train) {
      //   console.log(train);
      this.form.fill(train);
      this.$bvModal.show("modal-trainInfo");
    },
    updateTrainInfo() {
      this.form
        .put("/all_worker_functions/updateTrainInfo/" + this.form.tcs_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s(response.data, "Шинэчилсэн");
          Fire.$emit("getTrainings");
          this.cancelTrainInfo();
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    cancelTrainInfo() {
      this.form.reset();
      this.$bvModal.hide("modal-trainInfo");
    },
    // getMyTeachTraining() {
    //   axios
    //     .get("/all_worker_functions/getMyTeachTrainings")
    //     .then((res) => {
    //       console.log(res.data);
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },

    //Багш сургалт хийх хүсэлт илгээх
    responseMyTraining() {
      // console.log("Багш сургалт хийх хүсэлт гаргах");
      axios
        .get("/all_worker_functions/getAllsubTrainsOfTrainings")
        .then((res) => {
          //   console.log(res.data);
          this.allSubTrainOfTrainings = res.data.allSubTrains;
          this.ariaSelected = true;
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.$bvModal.show("modal-response-my-train");
    },
    getResMyTeachCreatedTrains() {
      axios
        .get("/all_worker_functions/getMyTeachTrainings")
        .then((res) => {
          //   console.log(res.data);
          this.allMyTeachTrainings = res.data.allMyTeachTrainings;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showResMyTrainInfo(subTrain) {
      //   console.log(subTrain);
      this.formSubTraining.reset();
      this.showSubtrainForm = false;
      this.showMySaveButton = false;
      this.formSubTraining.training_name = subTrain.taining_name;
      this.formSubTraining.training_id = subTrain.id;
      this.showSubtrainForm = true;
      this.showMySaveButton = true;
    },
    createResMyTrainInfo() {
      this.formSubTraining
        .post("/all_worker_functions/createResMyTrainInfo")
        .then((res) => {
          //   console.log(res.data);
          this.cancelResMyTrainInfo();
          Fire.$emit("getMyTeachTrainings");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },

    cancelResMyTrainInfo() {
      //   console.log("My response is ..........");
      this.showMySaveButton = false;
      this.showSubtrainForm = false;
      this.formSubTraining.reset();
      this.$bvModal.hide("modal-response-my-train");
    },

    //Дэд сургалтанд багш бүртгэх
    enrollSubTrain(train) {
      // console.log(train);
      this.getCourseStudents(train.tc_id);
      this.showModalTeachers = true;
      this.useInfosofSubTrains = train;
      this.formAddTeacher.training_course_id = train.tc_id;
      this.$bvModal.show("modal-train-teachers");
    },
    getCourseStudents(courseId) {
      //   this.enrolledTeachers = [];
      axios
        .get("/all_worker_functions/getCourseStudents/" + courseId)
        .then((res) => {
          //   console.log(res.data);
          this.enrolledTeachers = res.data.trainTeachers;
          this.modalTeachers = res.data.teachers;
          this.filterForm.department_id = 0;
          this.departments = res.data.departments;
          this.departments.push({
            id: 0,
            department_name: "Нийт мэргэжлийн баг",
          });
          this.filteredFormTeacherList(); //Бүх багш нарыг дуудаж авч байгаа...
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      this.showModalTeachers = false;
      this.useInfosofSubTrains = "";
      this.$bvModal.hide("modal-train-teachers");
      this.formAddTeacher.reset();
    },
    modalChangeTeacherList: function (e) {
      this.formAddTeacher.department_id = e.target.value;
      //   console.log(this.formAddTeacher.department_id);
      this.action = "Багш нарын мэдээллийг шинэчилж байна...";
      axios
        .get(
          "/all_worker_functions/getDepTeachers/" +
            this.formAddTeacher.department_id
        )
        .then((res) => {
          this.modalTeachers = res.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    filteredFormTeacherList: function () {
      axios
        .get(
          "/all_worker_functions/getDepTeachers/" +
            this.filterForm.department_id
        )
        .then((res) => {
          this.modalTeachers = res.data.teachers;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    addTeacher() {
      axios
        .post("/all_worker_functions/addTeacherTrainCourse", {
          enrollTeachers: this.formAddTeacher,
        })
        .then((response) => {
          this.$toastr.s("Багш амжилттай нэмлээ!", "Шинэчилсэн");
          //   this.formAddTeacher.reset();
          this.getCourseStudents(this.formAddTeacher.training_course_id);
          //   console.log(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    removeTeacher(train) {
      //   console.log(train);
      axios
        .post("/all_worker_functions/removeTeacherTrain", {
          removeTeacherOfTrain: train,
        })
        .then((response) => {
          this.getCourseStudents(train.training_course_id);
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  created() {
    this.getAllMyTrainingFilter();
    this.getResMyTeachCreatedTrains();
    Fire.$on("getTrainings", () => {
      this.getAllMyTrainingFilter();
    });
    Fire.$on("getMyTeachTrainings", () => {
      this.getResMyTeachCreatedTrains();
    });
  },
};
</script>

<style></style>
