<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <multiselect
        v-model="yearId"
        deselect-label="Хасах"
        select-label="Сонгох"
        track-by="id"
        :custom-label="customYearName"
        placeholder="Хичээлийн жил сонгоно уу?"
        :options="years"
        :searchable="true"
        :allow-empty="true"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{ option.finish_date | dateYear }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <div class="row mt-2" v-if="viewYearChart">
        <div class="col-12 col-sm-12">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span>
                <strong class="info-box-text mt-4 text-center">
                  Бүлгийн тоо хичээлийн жил тус бүрээр</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsAllClass"
                  :series="seriesAllClassNum"
                >
                </apexchart>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="viewYearChart">
        <div class="col-12 col-sm-12">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span>
                <strong class="info-box-text mt-4 text-center">
                  Өрөө танхим цогцолборын бүтцээр ашиглах боломж</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuildingEduLev"
                  :series="seriesBuildingEduLev"
                >
                </apexchart>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="viewYearChart">
        <div class="col-12 col-sm-12">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span>
                <strong class="info-box-text mt-4 text-center">
                  Барилгын хүчин чадал(Ангиллаар)</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuilding"
                  :series="seriesBuilding"
                >
                </apexchart>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-sm-12">
          <multiselect
            v-model="edu_lev_id"
            :options="educationLevelsRooms"
            :multiple="false"
            :custom-label="customEduLevName"
            track-by="id"
            label="id"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            placeholder="Цогцолборын бүтэц сонгох"
            :allow-empty="false"
            deselect-label="Хасах"
            select-label="Сонгох"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong> {{ option.name }}</strong> <strong> сонгогдлоо</strong></template
            >
          </multiselect>
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span v-if="viewSchool">
                <strong class="info-box-text mt-4 text-center">
                  Барилгын хүчин чадал(Цогцолборын бүтцээр)</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuildingSchool"
                  :series="seriesBuildingSchool"
                >
                </apexchart>
                <strong class="info-box-text mt-4 text-center">
                  Барилгын хүчин чадал(А блок)</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuildingSchoolABair"
                  :series="seriesBuildingSchoolABair"
                >
                </apexchart>
                <strong class="info-box-text mt-4 text-center">
                  Барилгын хүчин чадал(Б блок)</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuildingSchoolBBair"
                  :series="seriesBuildingSchoolBBair"
                >
                </apexchart>
                <strong class="info-box-text mt-4 text-center">
                  Барилгын хүчин чадал(С блок)</strong
                >
                <apexchart
                  type="bar"
                  height="340"
                  class="barChart"
                  :options="optionsBuildingSchoolCBair"
                  :series="seriesBuildingSchoolCBair"
                >
                </apexchart>
              </span>
            </div>
          </div>
        </div>
        <div v-if="viewSchool" class="col-12 col-sm-12 mt-3">
          <div class="d-flex">
            <multiselect
              v-model="selectedBlock"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="block_name"
              label="block_name"
              placeholder="Блок сонгоно уу?"
              :options="blocks"
              :searchable="true"
              :allow-empty="true"
              class="mr-3"
              :close-on-select="false"
              :multiple="true"
              @input="onchangeBlock"
            >
            </multiselect>
            <multiselect
              v-model="selectedRoom"
              deselect-label="Хасах"
              select-label="Сонгох"
              track-by="id"
              label="room_full_name"
              placeholder="Өрөө сонгоно уу?"
              :options="roomOptions"
              :searchable="true"
              :allow-empty="true"
              :multiple="true"
              :close-on-select="false"
              @input="onchangeRoom"
            >
            </multiselect>
          </div>
          <div class="card">
            <div class="text-center">
              <strong class="info-box-text mt-4 text-center"> Өрөөний ачаалал </strong>
            </div>
            <div class="card-body table-responsive mb-3">
              <table class="table table-bordered table-hover">
                <thead fixed>
                  <tr role="row">
                    <th>№</th>
                    <th>Өрөө</th>
                    <th v-for="time in timeTables" :key="time.id" class="text-center">
                      {{ time.name }}
                      <br />
                      <small>
                        {{ time.start_time }}
                        {{ time.finish_time }}
                      </small>
                    </th>
                    <th>Нийт: {{ timeTables.length }}</th>
                  </tr>
                </thead>
                <tbody v-if="roomTimes.length > 0">
                  <tr v-for="(roomTime, index) in roomTimes" :key="roomTime.key">
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ index + 1 }}
                    </td>
                    <td class="dtr-control sorting_1" tabindex="0">
                      {{ roomTime.room_full_name }}
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      v-for="time in timeTables"
                      :key="time.id"
                    >
                      <b-button
                        title="Цаг хасах"
                        v-if="
                          roomTime.rooms_times.find(
                            (a) => a.time_table_id == time.id && a.isHasLesson == 1
                          )
                        "
                        @click="
                          changeRoomTime(
                            roomTime.rooms_times.find((u) => u.time_table_id === time.id)
                          )
                        "
                        variant="outline-success"
                      >
                        <small>
                          {{ time.name }} <br />
                          {{ time.start_time }}
                          {{ time.finish_time }}
                        </small>
                      </b-button>
                      <b-button
                        title="Цаг нэмэх"
                        v-else-if="
                          roomTime.rooms_times.find(
                            (a) => a.time_table_id == time.id && a.isHasLesson == 0
                          )
                        "
                        @click="
                          changeRoomTime(
                            roomTime.rooms_times.find((u) => u.time_table_id === time.id)
                          )
                        "
                        variant="outline-secondary"
                        ><b-icon icon="plus" aria-hidden="true"></b-icon>
                      </b-button>
                    </td>
                    <td>
                      {{ roomTime.rooms_times_count }}
                      <b-badge variant="light">
                        {{
                          (
                            (100 * roomTime.rooms_times_count) /
                            timeTables.length
                          ).toFixed(1)
                        }}% /
                        {{ roomTime.rooms_times_count }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">
                      <b>Нийт: {{ roomTimes.length }}</b>
                    </td>
                    <td
                      class="dtr-control sorting_1 text-center"
                      v-for="(total, index) in roomTotal"
                      :key="index"
                    >
                      <b-badge variant="light">
                        {{ ((100 * total) / roomTimes.length).toFixed(1) }}% /
                        {{ total }}
                      </b-badge>
                    </td>
                    <td class="text-center text-bold">
                      {{
                        roomTotal.reduce(
                          (accumulator, currentValue) => accumulator + currentValue,
                          0
                        )
                      }}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <b-spinner
                variant="primary"
                label="Spinning"
                v-if="isLoadTimeChart === false"
              ></b-spinner>
              <div v-else>
                <div class="mt-4">
                  <strong class="info-box-text"> Өрөөний ачаалал </strong>
                </div>
                <apexchart
                  type="line"
                  height="350"
                  :options="roomChartOptions"
                  :series="roomSeries"
                ></apexchart>
                <div class="mt-4">
                  <strong class="info-box-text"> Цагийн ачаалал </strong>
                </div>
                <apexchart
                  type="line"
                  height="350"
                  :options="timeChartOptions"
                  :series="timeSeries"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //Шинээр эхэлнэ.
      yearId: "",
      viewYearChart: false,
      trainingPlans: [],
      rooms: [],
      //Хичээлийн жилээр бүлэг төлөвлөлт
      optionsAllClass: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesAllClassNum: [
        {
          name: "Нийт бүлгийн тоо",
          data: [],
        },
      ],
      //Цогцолборын бүтцийн ашиглалт
      educationLevelsRooms: [],

      optionsBuildingEduLev: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuildingEduLev: [
        {
          name: "Цогцолборын бүтцийн ашиглах боломж",
          data: [],
        },
      ],
      //Барилгын хүчин чадал нийт
      roomCategoriesWithRooms: [],
      optionsBuilding: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuilding: [
        {
          name: "Барилгын хүчин чадал(Ангиллаар)",
          data: [],
        },
      ],
      //Барилгын хүчин чадал сургууль
      viewSchool: false,
      edu_lev_id: "",
      roomCategoriesWithRoomsForSchool: [],
      optionsBuildingSchool: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuildingSchool: [
        {
          name: "Барилгын хүчин чадал(Ангиллаар)",
          data: [],
        },
      ],
      //Барилгын хүчин чадал сургууль А байр
      roomCategoriesWithRoomsForSchoolABair: [],
      optionsBuildingSchoolABair: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuildingSchoolABair: [
        {
          name: "Барилгын хүчин чадал(Ангиллаар)",
          data: [],
        },
      ],
      //Барилгын хүчин чадал сургууль Б байр
      roomCategoriesWithRoomsForSchoolBBair: [],
      optionsBuildingSchoolBBair: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuildingSchoolBBair: [
        {
          name: "Барилгын хүчин чадал(Ангиллаар)",
          data: [],
        },
      ],

      //Барилгын хүчин чадал сургууль C байр
      roomCategoriesWithRoomsForSchoolCBair: [],
      optionsBuildingSchoolCBair: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // let a = (val / 52) * 100;
            // return a.toFixed(1) + "%"; // хувиар илэрхийлэх

            return val.toFixed(1);
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              // let a = val - 52;
              // if (a > 0) {
              //   return "+" + a;
              // } else {
              //   return a;
              // }
              return val;
            },
          },
        },
      },
      seriesBuildingSchoolCBair: [
        {
          name: "Барилгын хүчин чадал(Ангиллаар)",
          data: [],
        },
      ],
      roomTimes: [],
      allRoomTimes: [],
      timeTables: [],
      blocks: [],
      roomOptions: [],
      selectedBlock: null,
      selectedRoom: null,
      roomTotal: [],
      roomSeries: [
        {
          name: "Өрөөны ачаалал",
          data: [],
        },
      ],
      roomChartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      isLoadTimeChart: false,
      timeSeries: [
        {
          name: "Өрөөны ачаалал",
          data: [],
        },
      ],
      timeChartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  watch: {
    edu_lev_id: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
      this.viewSchool = false;
      this.roomCategoriesWithRoomsForSchool = [];
      this.roomCategoriesWithRoomsForSchoolABair = [];
      this.roomCategoriesWithRoomsForSchoolBBair = [];
      this.roomCategoriesWithRoomsForSchoolCBair = [];
      this.optionsBuildingSchool.xaxis.categories = [];
      this.seriesBuildingSchool[0].data = [];
      this.optionsBuildingSchoolABair.xaxis.categories = [];
      this.seriesBuildingSchoolABair[0].data = [];
      this.optionsBuildingSchoolBBair.xaxis.categories = [];
      this.seriesBuildingSchoolBBair[0].data = [];
      this.optionsBuildingSchoolCBair.xaxis.categories = [];
      this.seriesBuildingSchoolCBair[0].data = [];
      axios
        .post("/curriculumadministrator/getAllEduLevCatRooms", { edu_lev_id: newVal })
        .then((res) => {
          this.getTimeTableDatas();
          // console.log(res.data)
          this.roomCategoriesWithRoomsForSchool = res.data.roomCategoriesWithRooms;
          this.roomCategoriesWithRoomsForSchoolABair =
            res.data.roomCategoriesWithRoomsForSchoolABair;
          this.roomCategoriesWithRoomsForSchoolBBair =
            res.data.roomCategoriesWithRoomsForSchoolBBair;
          this.roomCategoriesWithRoomsForSchoolCBair =
            res.data.roomCategoriesWithRoomsForSchoolCBair;
          let collect = [];
          this.roomCategoriesWithRoomsForSchool.forEach((element) => {
            this.optionsBuildingSchool.xaxis.categories.push(element.name);
            this.seriesBuildingSchool[0].data.push(element.my_rooms_info_count);
            collect.push(element.my_rooms_info_count);
          });
          // console.log(collect)
          let collectNiilber = collect.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber)
          this.optionsBuildingSchool.xaxis.categories.push("Нийт");
          this.seriesBuildingSchool[0].data.push(collectNiilber);

          // А байр
          let collectAbair = [];
          this.roomCategoriesWithRoomsForSchoolABair.forEach((element) => {
            this.optionsBuildingSchoolABair.xaxis.categories.push(element.name);
            this.seriesBuildingSchoolABair[0].data.push(element.my_rooms_info_count);
            collectAbair.push(element.my_rooms_info_count);
          });
          // console.log(collect)
          let collectNiilberAbair = collectAbair.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber)
          this.optionsBuildingSchoolABair.xaxis.categories.push("Нийт");
          this.seriesBuildingSchoolABair[0].data.push(collectNiilberAbair);

          // B байр
          let collectBbair = [];
          this.roomCategoriesWithRoomsForSchoolBBair.forEach((element) => {
            this.optionsBuildingSchoolBBair.xaxis.categories.push(element.name);
            this.seriesBuildingSchoolBBair[0].data.push(element.my_rooms_info_count);
            collectBbair.push(element.my_rooms_info_count);
          });
          // console.log(collect)
          let collectNiilberBbair = collectBbair.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber)
          this.optionsBuildingSchoolBBair.xaxis.categories.push("Нийт");
          this.seriesBuildingSchoolBBair[0].data.push(collectNiilberBbair);

          // B байр
          let collectCbair = [];
          this.roomCategoriesWithRoomsForSchoolCBair.forEach((element) => {
            this.optionsBuildingSchoolCBair.xaxis.categories.push(element.name);
            this.seriesBuildingSchoolCBair[0].data.push(element.my_rooms_info_count);
            collectCbair.push(element.my_rooms_info_count);
          });
          // console.log(collect)
          let collectNiilberCbair = collectCbair.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber)
          this.optionsBuildingSchoolCBair.xaxis.categories.push("Нийт");
          this.seriesBuildingSchoolCBair[0].data.push(collectNiilberCbair);

          this.viewSchool = true;
        })
        .catch((err) => console.log(err));
    },
    // "form.yearPerWeekGrade": function (newVal, oldVal) {
    //   //   console.log(newVal, oldVal);
    //   let defference = oldVal.filter((x) => !newVal.includes(x));
    //   //   console.log(defference.length);
    //   if (defference.length != 0) {
    //     this.form[this.classText + defference[0]["name"]] = 0;
    //   } else {
    //     console.log("nothing");
    //   }
    // },
  },
  methods: {
    async getTimeTableFooter() {
      this.roomTotal = [];
      let timeArr = [];
      await this.timeTables.forEach((time) => {
        let count = 0;
        this.roomTimes.forEach((a) => {
          count += a.rooms_times.filter(
            (b) => b.time_table_id == time.id && b.isHasLesson == 1
          ).length;
        });
        this.roomTotal.push(count);
        timeArr.push(time.name);
      });
      this.roomSeries[0].data = this.roomTotal;
      this.roomChartOptions.xaxis.categories = timeArr;

      await this.roomTimes.forEach((room) => {
        this.timeSeries[0].data.push(room.rooms_times_count);
        this.timeChartOptions.xaxis.categories.push(room.room_full_name);
      });
      this.isLoadTimeChart = true;
    },
    onchangeBlock() {
      let allRooms = [];
      let roomTimeDatas = this.allRoomTimes;
      this.selectedBlock.map(function (block) {
        allRooms = allRooms.concat(
          roomTimeDatas.filter((item) => item.block_name == block.block_name)
        );
      });
      this.roomOptions = allRooms;
    },
    onchangeRoom() {
      this.roomSeries[0].data = [];
      this.timeSeries[0].data = [];
      this.timeChartOptions.xaxis.categories = [];
      this.isLoadTimeChart = false;
      let allTimes = this.allRoomTimes;
      let filteredTimeTables = [];
      this.selectedRoom.forEach((room) => {
        const filteredTimes = allTimes.filter(
          (item) => item.room_full_name === room.room_full_name
        );
        filteredTimeTables = filteredTimeTables.concat(filteredTimes);
      });

      this.roomTimes = filteredTimeTables;
      this.getTimeTableFooter();
    },
    changeRoomTime(time_table) {
      time_table.isHasLesson = time_table.isHasLesson == 1 ? 0 : 1;
      axios
        .get("/curriculumadministrator/changeRoomTime/" + time_table.id)
        .then((response) => {
          this.$toastr.s("Цаг амжилттай бүргэгдлээ.", "Амжилттай");
        })
        .catch((error) => {
          this.$toastr.e("Алдаа гарлаа.", "Алдаа");
        });
    },
    getTimeTableDatas() {
      this.isLoadTimeChart = false;
      axios
        .get("/curriculumadministrator/getTimeTableDatas/" + this.edu_lev_id.id)
        .then((res) => {
          //Өрөөний ачааалал
          this.timeTables = res.data.timeTables;
          this.allRoomTimes = res.data.roomTimes;
          this.roomTimes = res.data.roomTimes;
          if (this.selectedBlock == null && this.selectedRoom == null) {
            this.blocks = [];
            this.blocks = this.roomTimes.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.block_name === item.block_name)
            );
          }
          this.getTimeTableFooter();
        });
    },
    getAllInfo() {
      this.viewYearChart = false;
      axios
        .get("/curriculumadministrator/getAllStatTrainingPlans")
        .then((response) => {
          // console.log(response.data.schoolteachers);
          this.trainingPlans = response.data.trainingPlans;
          this.roomCategoriesWithRooms = response.data.roomCategoriesWithRooms;
          this.roomCategoriesWithRoomsForSchool = response.data.roomCategoriesWithRooms;
          this.educationLevelsRooms = response.data.educationLevelsRooms;
          this.rooms = response.data.rooms;

          this.trainingPlans.forEach((element) => {
            let a = [...element.yearPerGradeCount.split("-")];
            a = a.filter((n) => n);

            element["splitPerGradeCount"] = a.shift();
            // console.log(a, 'el delete');
            let niilber = a.reduce((b, c) => Number(b) + Number(c));

            element["splitPerGradeCountSum"] = niilber;
            // console.log(element.splitPerGradeCountSum)
            this.seriesAllClassNum[0].data.push(element.splitPerGradeCountSum);
            // this.optionsAllClass.xaxis.categories.push(element.splitPerGradeCountSum)
            this.optionsAllClass.xaxis.categories.push(
              element.start_date[0] +
                element.start_date[1] +
                element.start_date[2] +
                element.start_date[3] +
                "-" +
                element.finish_date[0] +
                element.finish_date[1] +
                element.finish_date[2] +
                element.finish_date[3]
            );
          });
          // this.optionsAllClass.xaxis.categories = res.data.allClassNum;
          let collect = [];
          this.roomCategoriesWithRooms.forEach((element) => {
            this.optionsBuilding.xaxis.categories.push(element.name);
            this.seriesBuilding[0].data.push(element.my_rooms_info_count);
            collect.push(element.my_rooms_info_count);
          });
          // console.log(collect)
          let collectNiilber = collect.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber)
          this.optionsBuilding.xaxis.categories.push("Нийт");
          this.seriesBuilding[0].data.push(collectNiilber);

          let collect1 = [];
          this.educationLevelsRooms.forEach((element) => {
            this.optionsBuildingEduLev.xaxis.categories.push(element.name);
            this.seriesBuildingEduLev[0].data.push(element.my_e_l_rooms_info_count);
            collect1.push(element.my_rooms_info_count);
          });
          let collectNiilber1 = collect.reduce((a, b) => a + b, 0);
          // console.log(collectNiilber1)
          this.optionsBuildingEduLev.xaxis.categories.push("Нийт");
          this.seriesBuildingEduLev[0].data.push(collectNiilber1);
          this.viewYearChart = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
    customEduLevName({ name }) {
      return `${name}`;
    },
  },
  created() {
    this.getAllInfo();
    Fire.$on("loadAllTrainingPlan", () => {
      this.getAllInfo();
    });
  },
};
</script>

<style></style>
