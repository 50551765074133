<template>
  <div class="row">
    <div class="col-md-12 mt-3">
      <multiselect
        v-model="year_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="id"
        label="id"
        :custom-label="customYearName"
        placeholder="Хичээлийн жилээр шүүж харах"
        :options="years"
        :searchable="true"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.start_date | dateYear }}-{{
              option.finish_date | dateYear
            }}
            - хичээлийн жил
          </strong></template
        >
      </multiselect>
      <div v-if="isShow">
        <!--Нийт ажилчдын шатлалаар харуулбал-->
        <div class="row">
          <div class="col-lg-12 col-12">
            <b-overlay :show="loaded" rounded="sm">
              <b-row>
                <b-col lg="12" class="my-1">
                  <b-form-group
                    label="Нэг хуудсанд"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Хайх утга!"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Хайлт хийх утгаа оруулна уу!"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Цэвэрлэх</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
              <b-table
                hover
                :items="educationalLevels"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-ignored-fields="filterIgnoredFields"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
              >
                <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(name)="data">
                  <div class="row callout callout-warning">
                    {{ data.item.name }}
                    <br />
                    <b-badge variant="info">
                      {{ data.item.description }}
                    </b-badge>
                  </div>
                </template>

                <template #cell(dep_curr_team_info)="data">
                  <div class="row callout callout-warning">
                    <table class="table table-hover table-sm">
                      <thead>
                        <tr>
                          <th style="width: 10px">#</th>
                          <th>Хөтөлбөрийн баг</th>
                          <th>Тайлбар</th>
                          <th style="width: 40px">Багш нарын баг</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(eduLev, index) in data.item
                            .dep_curr_team_info"
                          :key="eduLev.index"
                        >
                          <td>{{ ++index }}</td>
                          <td>{{ eduLev.name }}</td>
                          <td>
                            {{ eduLev.description }}
                          </td>
                          <td>
                            <table class="table table-hover table-sm">
                              <thead>
                                <tr>
                                  <th style="width: 10px">#</th>
                                  <th>Мэргэжлийн баг</th>
                                  <th>Тайлбар</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(depI, ind) in eduLev.departments_info"
                                  :key="depI.id"
                                >
                                  <td>{{ ++ind }}</td>
                                  <td>{{ depI.department_name }}</td>
                                  <td>
                                    {{ depI.department_code }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>

                <template #cell(created_at)="data">
                  <div class="row callout callout-warning">
                    {{ data.item.created_at | dateYearMonthDayTime }}
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
          <div class="col-lg-12">
            <!-- <apexchart
              type="line"
              height="350"
              :options="chartOptionsLineEmployees"
              :series="seriesLineEmployees"
            >
            </apexchart> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      isShow: false,
      year_id: "",
      // allYears: [],
      educationalLevels: [],
      // getAllDeletedEmployees: [],

      // хүснэгтийн тохиргоо
      loaded: true,
      // Хүснэгтийн тохиргоо
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        { value: 200, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterIgnoredFields: ["dep_curr_team_info"],
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Боловсролын түвшин",
          sortable: true,
        },
        // {
        //   key: "description",
        //   label: "Дэлгэрэнгүй",
        //   sortable: true,
        // },
        {
          key: "dep_curr_team_info",
          label: "Хөтөлбөрийн багууд",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн",
          sortable: true,
        },
      ],

      //Ажлын зэрэглэл стат ерөнхий
      getAllCurriculumTypeStatTeacher: [],
      options: {
        chart: {
          id: "Багш (ерөнхий ур чадвар)",
        },
        title: {
          text: "Багш (ерөнхий ур чадвар)",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263",
          },
        },
        xaxis: {
          categories: [
            // "Бүх ажилтан",
            // "Удирдлага",
            // "Багш",
            // "СА Ажилтан",
            // "ҮАА ажилтан",
          ],
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: "Зэрэг",
          data: [], //жил
        },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    years: function () {
      return this.$store.state.years;
    },
  },
  watch: {
    year_id: function (newVal, oldVal) {
      this.getAllCurriculumTypeStat();
    },
  },
  methods: {
    getAllCurriculumTypeStat() {
      this.isShow = false;
      axios
        .post("/hr/getAllCurriculumTypeStat", {
          yearId: this.year_id,
        })
        .then((res) => {
          console.log(res);
          this.educationalLevels = res.data.educationalLevels;
          this.isShow = true;
          this.loaded = false;
        });
    },
    onFiltered(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} - оны хичээлийн жил`;
    },
  },
  // beforeMount() {
  //   this.getAllUsersStat();
  // },
  created() {
    this.getAllCurriculumTypeStat();
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
