<template>
  <aside
    v-if="userInfo.user"
    class="main-sidebar sidebar-dark-primary elevation-4"
  >
    <!-- Brand Logo -->
    <!-- Student -->
    <router-link
      to="/student/home"
      class="brand-link logo-switch center"
      v-if="userInfo.user.roles && userInfo.user.roles[0].name == 'dentstu'"
    >
      <img
        v-if="
          userInfo.user.profile &&
          userInfo.user.profile.school &&
          userInfo.user.profile.school.school_logo_url
        "
        :src="userInfo.user.profile.school.school_logo_url"
        :alt="userInfo.user.profile.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.profile &&
          userInfo.user.profile.school &&
          userInfo.user.profile.school.school_logo_url_hor
        "
        :src="userInfo.user.profile.school.school_logo_url_hor"
        :alt="userInfo.user.profile.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- parent -->
    <router-link
      to="/parent/home"
      class="brand-link logo-switch center"
      v-else-if="userInfo.user.roles && userInfo.user.roles[0].name == 'rentpa'"
    >
      <img
        v-if="userInfo.user.profile_parent.school.school_logo_url"
        :src="userInfo.user.profile_parent.school.school_logo_url"
        :alt="userInfo.user.profile_parent.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="userInfo.user.profile_parent.school.school_logo_url_hor"
        :src="userInfo.user.profile_parent.school.school_logo_url_hor"
        :alt="userInfo.user.profile_parent.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Principal -->
    <router-link
      to="/principal/home"
      class="brand-link logo-switch center"
      v-else-if="
        userInfo.user.roles && userInfo.user.roles[0].name == 'cipalprin'
      "
    >
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url
        "
        :src="userInfo.user.employee_user.school.school_logo_url"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url_hor
        "
        :src="userInfo.user.employee_user.school.school_logo_url_hor"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Manager -->
    <router-link
      to="/manager/home"
      class="brand-link logo-switch center"
      v-else-if="
        userInfo.user.roles && userInfo.user.roles[0].name == 'agerman'
      "
    >
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url
        "
        :src="userInfo.user.employee_user.school.school_logo_url"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url_hor
        "
        :src="userInfo.user.employee_user.school.school_logo_url_hor"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Manager цэцэрлэг -->
    <router-link
      to="/manager_kg/home"
      class="brand-link logo-switch center"
      v-else-if="
        userInfo.user.roles && userInfo.user.roles[0].name == 'agermankg'
      "
    >
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url
        "
        :src="userInfo.user.employee_user.school.school_logo_url"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url_hor
        "
        :src="userInfo.user.employee_user.school.school_logo_url_hor"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Бүртгэлийн ажилтан -->
    <router-link
      to="/reg_emp/home"
      class="brand-link logo-switch center"
      v-else-if="
        userInfo.user.roles && userInfo.user.roles[0].name == 'reg_emp'
      "
    >
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url
        "
        :src="userInfo.user.employee_user.school.school_logo_url"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url_hor
        "
        :src="userInfo.user.employee_user.school.school_logo_url_hor"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Багш -->
    <router-link
      to="/teacher/home"
      class="brand-link logo-switch center"
      v-else
    >
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url
        "
        :src="userInfo.user.employee_user.school.school_logo_url"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xl logo-xs"
      />
      <img
        v-if="
          userInfo.user.employee_user &&
          userInfo.user.employee_user.school.school_logo_url_hor
        "
        :src="userInfo.user.employee_user.school.school_logo_url_hor"
        :alt="userInfo.user.employee_user.school.school_name"
        class="brand-image-xs logo-xl"
        style="left: 12px"
      />
    </router-link>
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <!-- student -->
        <router-link
          to="/student/profile"
          v-if="userInfo.user.roles && userInfo.user.roles[0].name == 'dentstu'"
        >
          <div class="image" v-if="userInfo.user.profile">
            <b-avatar
              :src="userInfo.user.profile.student_profile_photo_url"
              variant="light"
              size="30"
              class="mr-1"
            ></b-avatar>
            {{ userInfo.user.name }}
          </div>
        </router-link>
        <!-- parent -->
        <router-link
          to="/parent/profile"
          v-else-if="
            userInfo.user.roles && userInfo.user.roles[0].name == 'rentpa'
          "
        >
          <div class="image" v-if="userInfo.user.profile_parent">
            <b-avatar
              :src="
                userInfo.user.profile_parent.parent_father_profile_photo_url
              "
              variant="light"
              size="30"
              class="mr-1"
            ></b-avatar>
            {{ userInfo.user.name }}
          </div>
        </router-link>

        <router-link
          to="/all_worker_functions/profile"
          v-else-if="userInfo.user.employee_user"
        >
          <div class="image" v-if="userInfo.user.employee_user">
            <b-avatar
              :src="userInfo.user.employee_user.profile_photo_url"
              variant="light"
              size="30"
              class="mr-1"
            ></b-avatar>
            {{ userInfo.user.name }}
          </div>
        </router-link>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item" v-for="menu in menus" :key="menu.id">
            <!-- Энгийн цэс -->
            <router-link
              v-if="menu.isUrl == 0 && menu.isParent == 0"
              :to="menu.url"
              class="nav-link"
              active-class="active"
              exact
            >
              <i
                v-if="menu.iconType == 'favicon'"
                :class="['nav-icon', menu.icon]"
              ></i>
              <b-icon
                v-if="menu.iconType == 'bootstrap'"
                :icon="menu.icon"
                aria-hidden="true"
                class="nav-icon"
              ></b-icon>
              <p>{{ menu.name }}</p>
            </router-link>
            <!-- Эцэг цэс -->
            <a
              href="#"
              v-else-if="menu.isUrl == 0 && menu.isParent == 1"
              class="nav-link"
            >
              <i
                v-if="menu.iconType == 'favicon'"
                :class="['nav-icon', menu.icon]"
              ></i>
              <b-icon
                v-if="menu.iconType == 'bootstrap'"
                :icon="menu.icon"
                aria-hidden="true"
                class="nav-icon"
              ></b-icon>
              <p>
                {{ menu.name }}
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <!-- Гадаад холбоос -->
            <a v-else :href="menu.url" class="nav-link" target="_blank">
              <i
                v-if="menu.iconType == 'favicon'"
                :class="['nav-icon', menu.icon]"
              ></i>
              <b-icon
                v-if="menu.iconType == 'bootstrap'"
                :icon="menu.icon"
                aria-hidden="true"
                class="nav-icon"
              ></b-icon>
              <p>{{ menu.name }}</p>
            </a>
            <ul
              v-for="child in menu.my_childs"
              :key="child.id"
              class="nav nav-treeview"
            >
              <li class="nav-item">
                <!-- Энгийн цэс -->
                <router-link
                  v-if="child.isUrl == 0 && child.isParent == 0"
                  :to="child.url"
                  class="nav-link"
                  active-class="active"
                  exact
                >
                  <i
                    v-if="child.iconType == 'favicon'"
                    :class="['nav-icon', child.icon]"
                  ></i>
                  <b-icon
                    v-if="child.iconType == 'bootstrap'"
                    :icon="child.icon"
                    aria-hidden="true"
                    class="nav-icon"
                  ></b-icon>
                  <p>{{ child.name }}</p>
                </router-link>
                <!-- Эцэг цэс -->
                <a
                  href="#"
                  v-else-if="child.isUrl == 0 && child.isParent == 1"
                  class="nav-link"
                >
                  <i
                    v-if="child.iconType == 'favicon'"
                    :class="['nav-icon', child.icon]"
                  ></i>
                  <b-icon
                    v-if="child.iconType == 'bootstrap'"
                    :icon="child.icon"
                    aria-hidden="true"
                    class="nav-icon"
                  ></b-icon>
                  <p>
                    {{ child.name }}
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <!-- Гадаад холбоос -->
                <a v-else :href="child.url" class="nav-link" target="_blank">
                  <i
                    v-if="child.iconType == 'favicon'"
                    :class="['nav-icon', child.icon]"
                  ></i>
                  <b-icon
                    v-if="child.iconType == 'bootstrap'"
                    :icon="child.icon"
                    aria-hidden="true"
                    class="nav-icon"
                  ></b-icon>
                  <p>{{ child.name }}</p>
                </a>
                <ul
                  v-for="child2 in child.my_childs"
                  :key="child2.id"
                  class="nav nav-treeview"
                >
                  <li class="nav-item">
                    <!-- Энгийн цэс холбоос -->
                    <router-link
                      v-if="child2.isUrl == 0 && child2.isParent == 0"
                      :to="child2.url"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <i
                        v-if="child2.iconType == 'favicon'"
                        :class="['nav-icon', child2.icon]"
                      ></i>
                      <b-icon
                        v-if="child2.iconType == 'bootstrap'"
                        :icon="child2.icon"
                        aria-hidden="true"
                        class="nav-icon"
                      ></b-icon>
                      <p>{{ child2.name }}</p>
                    </router-link>
                    <!-- Эцэг цэс холбоос -->
                    <a
                      href="#"
                      v-else-if="child2.isUrl == 0 && child2.isParent == 1"
                      class="nav-link"
                    >
                      <i
                        v-if="child2.iconType == 'favicon'"
                        :class="['nav-icon', child2.icon]"
                      ></i>
                      <b-icon
                        v-if="child2.iconType == 'bootstrap'"
                        :icon="child2.icon"
                        aria-hidden="true"
                        class="nav-icon"
                      ></b-icon>
                      <p>
                        {{ child2.name }}
                        <i class="fas fa-angle-left right"></i>
                      </p>
                    </a>
                    <!-- Гадаад холбоос -->
                    <a
                      v-else
                      :href="child2.url"
                      class="nav-link"
                      target="_blank"
                    >
                      <i
                        v-if="child2.iconType == 'favicon'"
                        :class="['nav-icon', child2.icon]"
                      ></i>
                      <b-icon
                        v-if="child2.iconType == 'bootstrap'"
                        :icon="child2.icon"
                        aria-hidden="true"
                        class="nav-icon"
                      ></b-icon>
                      <p>{{ child2.name }}</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <div id="newsbutton" v-if="newsList.length > 0">
      <b-button
        v-b-toggle.sidebar-news
        class="feedback btn-sm infoEffect"
        variant="warning"
        block
      >
        <i class="fa fa-globe" aria-hidden="true"></i>
      </b-button>
    </div>

    <!-- /.sidebar -->
    <b-sidebar
      id="sidebar-news"
      title="Сүүлийн 7 хоногийн мэдээлэл"
      :no-close-on-route-change="true"
      width="600px"
      z-index="1041"
      right
      shadow
      bg-variant="white"
      backdrop
      backdrop-variant="secondary"
    >
      <template>
        <a
          href="#"
          class="dropdown-item"
          v-for="notifi in newsList"
          :key="notifi.id"
        >
          <div class="media mt-3">
            <img
              v-if="notifi.my_manager.profile_photo_url != null"
              :src="notifi.my_manager.profile_photo_url"
              alt="user-avatar"
              class="img-size-50 mr-3 img-circle img-bordered-sm"
            />
            <img
              v-else
              src="/images/users/user.png"
              alt="user-avatar"
              class="img-size-50 mr-3 img-circle img-bordered-sm"
            />
            <div class="media-body">
              <h3 class="dropdown-item-title text-bold">
                {{ notifi.my_manager.teacher_name }}
              </h3>
              <p class="text-sm infoBody" v-html="notifi.notifcation"></p>
              <p class="text-sm text-muted">
                <i class="far fa-clock mr-1"></i>
                {{ notifi.created_at | dateYearMonthDay }}
              </p>
            </div>
          </div>
        </a>
      </template>
    </b-sidebar>
  </aside>
</template>

<script>
export default {
  data() {
    return { menus: [], newsList: [] };
  },
  computed: {
    userInfo: function () {
      return this.$store.state;
    },
  },
  methods: {
    getMenus() {
      axios
        .get("/getUserMenus/")
        .then((res) => {
          this.menus = res.data.menus;
          this.newsList = res.data.newsList;
        })
        .catch();
    },
  },
  created() {
    this.getMenus();
  },
};
</script>

<style scoped>
.infoBody {
  width: 90%;
  white-space: pre-wrap;
  text-align: justify;
}
.infoEffect {
  box-shadow: 0 0 0 0 rgb(203, 220, 53);
  transform: scale(1);
  animation: infoPulse 2s infinite;
}
@keyframes infoPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(195, 220, 53, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(220, 53, 68, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(220, 53, 68, 0);
  }
}
</style>
