<template>
  <div class="col-12 pt-3">
    <div class="col-lg-12">
      Хичээл, шалгалт(таксоном анализ), улирал гэсэн 3 талбараар сонгогдсон статистик
      харах боломжтой.
    </div>
    <nav class="navbar navbar-expand navbar-primary navbar-info">
      <multiselect
        class="mr-2"
        v-model="course_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="course_id"
        :custom-label="customLabelCourse"
        placeholder="Хичээл сонголт - Шалгалтын төрөл 'жил'"
        :options="getMyAllCourses"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong
            >{{ option.subject_name }} - {{ option.school_class_full_name }}</strong
          ></template
        >
      </multiselect>
      <multiselect
        class="mr-2"
        v-model="exam_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="exam_id"
        label="exam_name"
        placeholder="Шалгалтын сонголтоор"
        :options="myAllExams"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.exam_name }}</strong></template
        >
      </multiselect>
      <multiselect
        v-model="quarter_id"
        deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
        track-by="quarter_id"
        label="name"
        :custom-label="customLabelQuarter"
        placeholder="Улирал сонголт - Шалгалтын төрөл 'улирал'"
        :options="getMySchoolQuarters"
        :searchable="false"
        :allow-empty="false"
      >
        <template slot="singleLabel" slot-scope="{ option }"
          ><strong>{{ option.quarter_name }} - улирал</strong></template
        >
      </multiselect>
    </nav>

    <every-student
      v-if="selectedCourseData"
      :selectedLessonExamData="selectedLessonExamData"
    ></every-student>
    <every-student
      v-if="selectedExamData"
      :selectedLessonExamData="selectedLessonExamData"
    ></every-student>
    <every-student
      v-if="selectedQuarterData"
      :selectedLessonExamData="selectedLessonExamData"
    ></every-student>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import everyStudent from "./everyStudent.vue";
export default {
  components: {
    Multiselect,
    everyStudent,
  },
  data() {
    return {
      //Эхлэх үед дуудах дата
      getMyAllCourses: [],
      myAllExams: [],
      getMySchoolQuarters: [],
      //Багш сонголт хийж харах үеийн дата
      course_id: "",
      exam_id: "",
      quarter_id: "",
      //Сонголт хийгдсэний дараа серверээс авах өгөгдөл, энэ өгөгдөл ирснээр дэлгэцэнд статистик бодуулж харуулна.
      selectedCourseData: false,
      selectedExamData: false,
      selectedQuarterData: false,
      selectedLessonExamData: [],
    };
  },
  watch: {
    course_id: function (newValue, oldValue) {
      this.selectedCourseData = false;
      this.selectedExamData = false;
      this.selectedQuarterData = false;
      axios
        .post("/teacher/myCourseExamGetData", {
          myCourseData: newValue,
        })
        .then((response) => {
          this.selectedLessonExamData = [];
          this.selectedLessonExamData = response.data.selectedLessonExamData;
          this.selectedLessonExamData.forEach((el) => {
            el.examAverage =
              parseFloat(el.exam1) * 0.1 +
              parseFloat(el.exam2) * 0.1 +
              parseFloat(el.exam3) * 0.1 +
              parseFloat(el.exam4) * 0.1 +
              parseFloat(el.exam5) * 0.2 +
              parseFloat(el.exam6) * 0.2 +
              parseFloat(el.exam7) * 0.1 +
              parseFloat(el.exam8) * 0.1;
            el.examGPoint = this.symbolGradeCheck(el.examAverage);
          });
          //   console.log(this.selectedLessonExamData);
          this.selectedCourseData = true;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    exam_id: function (newEValue, oldEValue) {
      //   console.log(newValue);
      this.selectedCourseData = false;
      this.selectedExamData = false;
      this.selectedQuarterData = false;
      axios
        .post("/teacher/myExamExamGetData", {
          myCourseData: newEValue,
        })
        .then((response) => {
          //   console.log(response.data);
          this.selectedLessonExamData = [];
          this.selectedLessonExamData = response.data.selectedLessonExamData;
          this.selectedLessonExamData.forEach((el) => {
            el.examAverage =
              parseFloat(el.exam1) * 0.1 +
              parseFloat(el.exam2) * 0.1 +
              parseFloat(el.exam3) * 0.1 +
              parseFloat(el.exam4) * 0.1 +
              parseFloat(el.exam5) * 0.2 +
              parseFloat(el.exam6) * 0.2 +
              parseFloat(el.exam7) * 0.1 +
              parseFloat(el.exam8) * 0.1;

            el.examGPoint = this.symbolGradeCheck(el.examAverage);
          });
          this.selectedExamData = true;
          //   this.$nextTick(() => {
          //     console.log(this.selectedLessonExamData);
          //   });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    quarter_id: function (newQValue, oldValue) {
      this.selectedCourseData = false;
      this.selectedExamData = false;
      this.selectedQuarterData = false;
      axios
        .post("/teacher/myQuarterExamGetData", {
          myCourseData: newQValue,
        })
        .then((response) => {
          //   console.log(response.data);
          this.selectedLessonExamData = [];
          this.selectedLessonExamData = response.data.selectedLessonExamData;
          this.selectedLessonExamData.forEach((el) => {
            el.examAverage =
              parseFloat(el.exam1) * 0.1 +
              parseFloat(el.exam2) * 0.1 +
              parseFloat(el.exam3) * 0.1 +
              parseFloat(el.exam4) * 0.1 +
              parseFloat(el.exam5) * 0.2 +
              parseFloat(el.exam6) * 0.2 +
              parseFloat(el.exam7) * 0.1 +
              parseFloat(el.exam8) * 0.1;
            el.examGPoint = this.symbolGradeCheck(el.examAverage);
          });
          //   console.log(this.selectedLessonExamData);
          this.selectedQuarterData = true;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  methods: {
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
    getExamStatData() {
      axios
        .get("/teacher/examStatDatateachers")
        .then((response) => {
          this.quarter_id = response.data.currentQuarter;
          this.getMyAllCourses = response.data.getMyAllCourses;
          this.myAllExams = response.data.getMyExams;
          this.getMySchoolQuarters = response.data.getMySchoolQuarters;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    customLabelCourse({ subject_name, school_class_full_name }) {
      return `${subject_name} – ${school_class_full_name}`;
    },
    customLabelQuarter({ quarter_name }) {
      return `${quarter_name} – улирал`;
    },
  },
  created() {
    this.getExamStatData();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
