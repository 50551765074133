<template>
  <div class="row pt-3">
    <div class="col-lg-12">
      <div class="card card-primary card-outline">
        <div class="card-header p-2">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#activity" data-toggle="tab"
                >Бүртгэсэн</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body table-responsive p-0">
          <div class="tab-content">
            <div class="tab-pane active" id="activity">
              <div class="card">
                <div class="card-header">
                  <strong>Сахилгын шийтгэлийн төрөл</strong>
                  <b-button
                    size="sm"
                    class="float-right"
                    variant="primary"
                    @click.prevent="addPunishmentType()"
                    >Сахилгын шийтгэлийн төрөл бүртгэх</b-button
                  >
                  <div
                    class="main-header navbar navbar-expand navbar-white navbar-light"
                  >
                    <h3 class="card-title" style="font-size: 80%">
                      <b>Нийт сахилгын шийтгэлийн төрлийн тоо:</b>
                      {{ punishmentTypes.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <b-overlay :show="punishmentTypeStatusLoaded" rounded="sm">
                    <b-row>
                      <b-col lg="12" class="my-1">
                        <b-form-group
                          label="Нэг хуудсанд харуулах тоо"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="perPagepunishmentType"
                            :options="pageOptionspunishmentType"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                          label="Хайлт хийх утга оруулна уу!"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterpunishmentType"
                              type="search"
                              placeholder="Хайлт хийх утгаа оруулна уу!"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterpunishmentType"
                                @click="filterpunishmentType = ''"
                                >Цэвэрлэх</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-pagination
                      v-model="currentPagepunishmentType"
                      :total-rows="totalRowsPunishmentType"
                      :per-page="perPagepunishmentType"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      hover
                      :items="punishmentTypes"
                      :fields="fieldsActivePunishmentType"
                      :current-page="currentPagepunishmentType"
                      :per-page="perPagepunishmentType"
                      style="font-size: 80%"
                      :filter-ignored-fields="filterIgnoredFieldspunishmentType"
                      :filter="filterpunishmentType"
                      :filter-included-fields="filterOnpunishmentType"
                      @filtered="onFilteredpunishmentType"
                    >
                      <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(name)="data">
                        <b-badge
                          variant="info"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.name }}
                        </b-badge>
                      </template>
                      <template #cell(description)="data">
                        <b-badge
                          variant="secondary"
                          class="text-wrap"
                          style="width: 8rem"
                        >
                          {{ data.item.description }}
                        </b-badge>
                      </template>
                      <template #cell(my_doc_owner)="data">
                        <b-badge variant="secondary">
                          {{ data.item.my_doc_owner.teacher_name }}
                        </b-badge>
                      </template>
                      <template #cell(doc_approve_date)="data">
                        <b-badge variant="varning">
                          {{ data.item.doc_approve_date | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(my_doc_department)="data">
                        <b-badge variant="secondary">
                          {{ data.item.my_doc_department.department_code }}
                        </b-badge>
                      </template>
                      <template #cell(created_at)="data">
                        <b-badge>
                          {{ data.item.created_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(updated_at)="data">
                        <b-badge>
                          {{ data.item.updated_at | dateYearMonthDay }}
                        </b-badge>
                      </template>
                      <template #cell(actions)="data">
                        <div
                          v-if="
                            userInfo.pivot.model_id == data.item.doc_owner_id ||
                            userInfo.name == 'cipalprin'
                          "
                        >
                          <a
                            href="#"
                            class="badge bg-warning"
                            @click="editPunishmentType(data.item)"
                            ><i class="fas fa-pencil-alt"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="deletePunishmentType(data.item)"
                            ><i class="far fa-trash-alt"></i
                          ></a>
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Бичиг баримт бүртгэх хэсэг -->
      <b-modal
        size="xl"
        id="modal-create-punishment-type"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :title="formDocModalTitle"
        class="modal fade"
        hide-footer
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" v-show="!editModePunishmentType">
                    Бичиг баримтын ангилал бүртгэх
                  </h3>
                  <h3 class="card-title" v-show="editModePunishmentType">
                    Бичиг баримтын ангилал засах
                  </h3>
                </div>
                <div class="card-body form-responsive p-0">
                  <form
                    @submit.prevent="
                      !editModePunishmentType
                        ? createPunishmentType()
                        : updatePunishmentType()
                    "
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Сахилгын шийтгэлийн төрлийн нэр</label>
                            <input
                              type="text"
                              v-model="form.name"
                              placeholder="Сахилгын шийтгэлийн төрлийн нэр"
                              class="form-control"
                              :class="{
                                'is-invalid': form.errors.has('name'),
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Сахилгын шийтгэлийн төрлийн тайлбар</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="form.description"
                              placeholder="Сахилгын шийтгэлийн төрлийн тайлбар"
                              rows="3"
                              max-rows="6"
                              :class="{
                                'is-invalid': form.errors.has('description'),
                              }"
                            ></b-form-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="!editModePunishmentType"
                      >
                        Хадгалах
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        v-show="editModePunishmentType"
                      >
                        Засах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="editModePunishmentType"
                        @click.prevent="cancelEdit"
                      >
                        Цуцлах
                      </button>
                      <button
                        class="btn btn-warning"
                        v-show="!editModePunishmentType"
                        @click.prevent="cancelCreate"
                      >
                        Болих
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import everyExams from "./Exam/IndexEveryExams.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formDocModalTitle: "Бичиг баримтын ангилал бүртгэх",
      versions: [
        { name: 1 },
        { name: 2 },
        { name: 3 },
        { name: 4 },
        { name: 5 },
        { name: 6 },
        { name: 7 },
        { name: 8 },
        { name: 9 },
        { name: 10 },
        { name: 11 },
        { name: 12 },
        { name: 13 },
        { name: 14 },
        { name: 15 },
        { name: 16 },
        { name: 17 },
        { name: 18 },
        { name: 19 },
        { name: 20 },
        { name: 21 },
        { name: 22 },
        { name: 23 },
        { name: 24 },
        { name: 25 },
        { name: 26 },
        { name: 27 },
        { name: 28 },
        { name: 29 },
        { name: 30 },
        { name: 31 },
        { name: 32 },
        { name: 33 },
        { name: 34 },
        { name: 35 },
        { name: 36 },
        { name: 37 },
        { name: 38 },
        { name: 39 },
        { name: 40 },
        { name: 41 },
        { name: 42 },
        { name: 43 },
        { name: 44 },
        { name: 45 },
        { name: 46 },
        { name: 47 },
        { name: 48 },
        { name: 49 },
        { name: 50 },
      ],
      //Бүртгэлтэй бичиг баримт хэсэг
      punishmentTypes: [],

      punishmentTypeStatusLoaded: true,
      editModePunishmentType: false,
      editModeDocumentFiles: false,

      //b-table тохиргоо
      totalRowsPunishmentType: 1,
      currentPagepunishmentType: 1,
      perPagepunishmentType: 25,
      pageOptionspunishmentType: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterpunishmentType: "",
      filterOnpunishmentType: [],
      filteredPunishmentTypeCount: 0,
      filterIgnoredFieldspunishmentType: [
        // "my_doc_proccess",
        // "my_doc_owner",
        // "my_doc_department",
      ],
      fieldsActivePunishmentType: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "name",
          label: "Бичиг баримтын нэр",
          sortable: true,
        },
        {
          key: "description",
          label: "Бичиг баримтын тухай",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Үүсгэсэн",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Зассан",
          sortable: true,
        },
        {
          key: "actions",
          label: "Үйлдлүүд",
          sortable: false,
        },
      ],

      form: new Form({
        id: "",
        year_id: "",
        school_id: "",
        name: "",
        description: "",
      }),
    };
  },
  components: { Multiselect },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fieldsActivePunishmentType
    //     .filter((f) => f.sortable)
    //     .map((f) => {
    //       return { text: f.label, value: f.key };
    //     });
    // },
  },
  methods: {
    getAllPunishmentTypes() {
      this.punishmentTypeStatusLoaded = true;
      axios
        .get("/hr/getAllPunishmentTypes")
        .then((res) => {
          //   console.log(response);
          this.punishmentTypes = res.data.punishmentTypes;
          this.totalRowsPunishmentType = this.punishmentType
            ? this.punishmentType.length
            : 0;
          this.punishmentTypeStatusLoaded = false;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //b-table тохирго
    onFilteredpunishmentType(filteredItems) {
      //   console.log(filteredItems);
      this.filteredPunishmentTypeCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsPunishmentType = filteredItems.length;
      this.currentPagepunishmentType = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredInactiveRooms(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsInactiveDocumentClassify = filteredItems.length;
      this.currentPageInactivepunishmentType = 1;
    },

    addPunishmentType() {
      this.form.reset();
      this.$bvModal.show("modal-create-punishment-type");
    },
    createPunishmentType() {
      //   console.log("create");
      this.form
        .post("/hr/createPunishmentType")
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("loadPunishemntTypes");
          this.cancelCreate();
        })
        .catch((err) => {
          //   console.log(err);
        });
    },

    editPunishmentType(punishmentType) {
      //   console.log(punishmentType);
      //exams_teacher_id, block_name,
      this.editModePunishmentType = true;
      this.form.fill(punishmentType);

      this.$bvModal.show("modal-create-punishment-type");
    },
    updatePunishmentType() {
      //   console.log("udpate");
      this.form
        .put("/hr/updatePunishmentType/" + this.form.id)
        .then((res) => {
          //   console.log(res.data);
          this.cancelEdit();
          Fire.$emit("loadPunishemntTypes");
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    cancelEdit() {
      this.cancelCreate();
      this.editModePunishmentType = false;
    },
    cancelCreate() {
      this.form.reset();
      this.$bvModal.hide("modal-create-punishment-type");
    },

    deletePunishmentType(punishmentType) {
      //   console.log(punishmentType);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Устгасан сахилгын шийтгэлийн төрөл системд ажиллахгүй болно!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, үүнийг устга!",
        cancelButtonText: "Үгүй, цуцла!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/hr/deletePunishmentType/" + punishmentType.id)
            .then((res) => {
              Swal.fire(
                "Устгасан!",
                "Сахилгын шийтгэлийн төрөл системээс устгалаа.",
                "success"
              );
              Fire.$emit("loadPunishemntTypes");
            })
            .catch((err) => {
              // console.log(err)
            });
        }
      });
    },
    // restoreSchoolDocumentClassify(document) {
    //   // console.log(document);
    //   Swal.fire({
    //     title: "Та итгэлтэй байна уу?",
    //     text: "Устгасан мэдээллийг сэргээх гэж байна!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Тийм, үүнийг сэргээ!",
    //     cancelButtonText: "Үгүй, цуцла!",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       axios
    //         .delete("/hr/restoreSchoolDocumentClassify/" + document.id)
    //         .then((res) => {
    //           Swal.fire(
    //             "Сэргээлээ!",
    //             "Бичиг баримтын ангилалыг системд буцаан сэргээлээ.",
    //             "success"
    //           );
    //           Fire.$emit("loadPunishemntTypes");
    //         })
    //         .catch((err) => {
    //           // console.log(err)
    //         });
    //     }
    //   });
    // },
  },
  watch: {},
  created() {
    this.getAllPunishmentTypes();
    Fire.$on("loadPunishemntTypes", () => {
      this.getAllPunishmentTypes();
    });
    // Fire.$on("getExamTeachers", () => {
    //   this.getExamTeachers();
    // });
  },
};
</script>

<style scoped>
td,
tr {
  font-size: 80%;
}

a {
  font-size: 60%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
