<template>
  <div class="row pt-3">
    <!-- /.col -->
    <div class="col-12">
      <div class="card card-primary card-tabs">
        <div class="card-header p-0 pt-1">
          <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="custom-tabs-one-home-tab"
                data-toggle="pill"
                href="#custom-tabs-one-home"
                role="tab"
                aria-controls="custom-tabs-one-home"
                aria-selected="true"
                >Шинэ тэмдэглэл</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-one-profile"
                role="tab"
                aria-controls="custom-tabs-one-profile"
                aria-selected="false"
                >Эцэг эхэд илгээсэн тэмдэглэл</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="custom-tabs-one-messages-tab"
                data-toggle="pill"
                href="#custom-tabs-one-messages"
                role="tab"
                aria-controls="custom-tabs-one-messages"
                aria-selected="false"
                >Эцэг эх танилцсан тэмдэглэл</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="custom-tabs-one-tabContent">
            <div
              class="tab-pane fade show active"
              id="custom-tabs-one-home"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-home-tab"
            >
              <div class="card">
                <div class="card-header">
                  <form @change="getAllData">
                    <div class="input-group input-group-sm">
                      <input
                        class="form-control form-control-navbar"
                        type="search"
                        placeholder="Багшийн нэрээр хайх боломжтой..."
                        aria-label="Search"
                        v-model="search"
                        id="popover-target-1"
                      />
                    </div>
                  </form>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0" style="overflow-x: auto">
                  <pagination
                    :data="allComments"
                    @pagination-change-page="getAllData"
                    :limit="5"
                    size="small"
                    align="center"
                  >
                    <span slot="prev-nav">&lt; Өмнөх</span>
                    <span slot="next-nav">Дараах &gt;</span>
                  </pagination>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Багш</th>
                        <th>Профайл зураг</th>
                        <th>Хичээл</th>
                        <th>Суралцагч овог нэр</th>
                        <th>Профайл зураг</th>
                        <th>Анги</th>
                        <th style="width: 40px">Тэмдэглэл</th>
                        <th style="width: 40px">Эцэг эх харах</th>
                        <th style="width: 40px">Танилцсан</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(comment, index) in allComments"
                        :key="comment.course_comment_id"
                      >
                        <td>{{ ++index }}</td>
                        <td>
                          {{ comment.emp_teacher_last_name[0] }}.{{
                            comment.emp_teacher_name
                          }}
                        </td>
                        <td class="image">
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.emp_profile_photo"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.emp_profile_photo != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="comment.emp_profile_photo === null"
                          />
                        </td>
                        <td>{{ comment.subject_name }}</td>
                        <td>
                          {{ comment.student_last_name[0] }}.{{
                            comment.student_name
                          }}
                        </td>
                        <td>
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.student_profile_photo_url"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.student_profile_photo_url != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="
                              comment.student_profile_photo_url === null
                            "
                          />
                        </td>
                        <td>{{ comment.school_class_full_name }}</td>
                        <td class="text-sm" style="width: 200px">
                          <b-badge
                            variant="secondary"
                            style="white-space: pre-wrap"
                            >{{ comment.course_comment }}</b-badge
                          >
                          <span class="badge bg-info">{{
                            comment.created_at | dateYearMonthDay
                          }}</span>
                        </td>
                        <td class="text-sm" style="width: 200px">
                          <b-icon
                            icon="x-circle"
                            scale="1.4"
                            variant="danger"
                            v-show="comment.course_comment_readParent == 0"
                          ></b-icon>
                          <!-- @click.prevent="readParentConfirm(comment)" -->
                          <b-icon
                            icon="check-square"
                            scale="1.4"
                            variant="success"
                            v-show="comment.course_comment_readParent == 1"
                          ></b-icon>
                          <!-- @click.prevent="removeParentConfirm(comment)" -->
                        </td>
                        <td class="text-sm center-block">
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 1"
                            style="float: right"
                            icon="heart-fill"
                            variant="success"
                            left
                          ></b-icon>
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 0"
                            style="float: right"
                            icon="heart-fill"
                            variant="warning"
                            left
                          ></b-icon>
                          <!-- 'course_comments.imReadParent as course_comment_imReadParent', -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-one-profile"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-profile-tab"
            >
              <div class="card">
                <div class="card-header">
                  <form @change="getAllData1">
                    <div class="input-group input-group-sm">
                      <input
                        class="form-control form-control-navbar"
                        type="search"
                        placeholder="Багшийн нэрээр хайх боломжтой..."
                        aria-label="Search"
                        v-model="search1"
                        id="popover-target-1"
                      />
                    </div>
                  </form>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0" style="overflow-x: auto">
                  <pagination
                    :data="allCommentsConfirmParent"
                    @pagination-change-page="getAllData1"
                    :limit="5"
                    size="small"
                    align="center"
                  >
                    <span slot="prev-nav">&lt; Өмнөх</span>
                    <span slot="next-nav">Дараах &gt;</span>
                  </pagination>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Багш</th>
                        <th>Профайл зураг</th>
                        <th>Хичээл</th>
                        <th>Суралцагч овог нэр</th>
                        <th>Профайл зураг</th>
                        <th>Анги</th>
                        <th style="width: 40px">Тэмдэглэл</th>
                        <th style="width: 40px">Эцэг эх харах</th>
                        <th style="width: 40px">Танилцсан</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(comment, index) in allCommentsConfirmParent"
                        :key="comment.course_comment_id"
                      >
                        <td>{{ ++index }}</td>
                        <td>
                          {{ comment.emp_teacher_last_name[0] }}.{{
                            comment.emp_teacher_name
                          }}
                        </td>
                        <td class="image">
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.emp_profile_photo"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.emp_profile_photo != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="comment.emp_profile_photo === null"
                          />
                        </td>
                        <td>{{ comment.subject_name }}</td>
                        <td>
                          {{ comment.student_last_name[0] }}.{{
                            comment.student_name
                          }}
                        </td>
                        <td>
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.student_profile_photo_url"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.student_profile_photo_url != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="
                              comment.student_profile_photo_url === null
                            "
                          />
                        </td>
                        <td>{{ comment.school_class_full_name }}</td>
                        <td class="text-sm" style="width: 200px">
                          <b-badge
                            variant="secondary"
                            style="white-space: pre-wrap"
                            >{{ comment.course_comment }}</b-badge
                          >
                          <span class="badge bg-info">{{
                            comment.created_at | dateYearMonthDay
                          }}</span>
                        </td>
                        <td class="text-sm" style="width: 200px">
                          <b-icon
                            icon="x-circle"
                            scale="1.4"
                            variant="danger"
                            v-show="comment.course_comment_readParent == 0"
                          ></b-icon>
                          <!-- @click.prevent="readParentConfirm(comment)" -->
                          <b-icon
                            icon="check-square"
                            scale="1.4"
                            variant="success"
                            v-show="comment.course_comment_readParent == 1"
                          ></b-icon>
                          <!-- @click.prevent="removeParentConfirm(comment)" -->
                        </td>
                        <td class="text-sm center-block">
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 1"
                            style="float: right"
                            icon="heart-fill"
                            variant="success"
                            left
                          ></b-icon>
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 0"
                            style="float: right"
                            icon="heart-fill"
                            variant="warning"
                            left
                          ></b-icon>
                          <!-- 'course_comments.imReadParent as course_comment_imReadParent', -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="custom-tabs-one-messages"
              role="tabpanel"
              aria-labelledby="custom-tabs-one-messages-tab"
            >
              <div class="card">
                <div class="card-header">
                  <form @change="getAllData2">
                    <div class="input-group input-group-sm">
                      <input
                        class="form-control form-control-navbar"
                        type="search"
                        placeholder="Багшийн нэрээр хайх боломжтой..."
                        aria-label="Search"
                        v-model="search2"
                        id="popover-target-1"
                      />
                    </div>
                  </form>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0" style="overflow-x: auto">
                  <pagination
                    :data="allCommentsConfirmParentReaded"
                    @pagination-change-page="getAllData2"
                    :limit="5"
                    size="small"
                    align="center"
                  >
                    <span slot="prev-nav">&lt; Өмнөх</span>
                    <span slot="next-nav">Дараах &gt;</span>
                  </pagination>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Багш</th>
                        <th>Профайл зураг</th>
                        <th>Хичээл</th>
                        <th>Суралцагч овог нэр</th>
                        <th>Профайл зураг</th>
                        <th>Анги</th>
                        <th style="width: 40px">Тэмдэглэл</th>
                        <th style="width: 40px">Эцэг эх харах</th>
                        <th style="width: 40px">Танилцсан</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          comment, index
                        ) in allCommentsConfirmParentReaded"
                        :key="comment.course_comment_id"
                      >
                        <td>{{ ++index }}</td>
                        <td>
                          {{ comment.emp_teacher_last_name[0] }}.{{
                            comment.emp_teacher_name
                          }}
                        </td>
                        <td class="image">
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.emp_profile_photo"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.emp_profile_photo != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="comment.emp_profile_photo === null"
                          />
                        </td>
                        <td>{{ comment.subject_name }}</td>
                        <td>
                          {{ comment.student_last_name[0] }}.{{
                            comment.student_name
                          }}
                        </td>
                        <td>
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="comment.student_profile_photo_url"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-if="comment.student_profile_photo_url != null"
                          />
                          <img
                            class="profile-user-img img-fluid attachment-img"
                            :src="defaultimage"
                            alt="Багшийн зураг"
                            accept="image/*"
                            v-else-if="
                              comment.student_profile_photo_url === null
                            "
                          />
                        </td>
                        <td>{{ comment.school_class_full_name }}</td>
                        <td class="text-sm" style="width: 200px">
                          <b-badge
                            variant="secondary"
                            style="white-space: pre-wrap"
                            >{{ comment.course_comment }}</b-badge
                          >
                          <span class="badge bg-info">{{
                            comment.created_at | dateYearMonthDay
                          }}</span>
                        </td>
                        <td class="text-sm" style="width: 200px">
                          <b-icon
                            icon="x-circle"
                            scale="1.4"
                            variant="danger"
                            v-show="comment.course_comment_readParent == 0"
                          ></b-icon>
                          <!-- @click.prevent="readParentConfirm(comment)" -->
                          <b-icon
                            icon="check-square"
                            scale="1.4"
                            variant="success"
                            v-show="comment.course_comment_readParent == 1"
                          ></b-icon>
                          <!-- @click.prevent="removeParentConfirm(comment)" -->
                        </td>
                        <td class="text-sm center-block">
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 1"
                            style="float: right"
                            icon="heart-fill"
                            variant="success"
                            left
                          ></b-icon>
                          <b-icon
                            v-show="comment.course_comment_imReadParent == 0"
                            style="float: right"
                            icon="heart-fill"
                            variant="warning"
                            left
                          ></b-icon>
                          <!-- 'course_comments.imReadParent as course_comment_imReadParent', -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>

      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      search: "",
      search1: "",
      search2: "",
      checkPage: undefined,
      checkPage1: undefined,
      checkPage2: undefined,
      allComments: {},
      allCommentsConfirmParent: {},
      allCommentsConfirmParentReaded: {},
      defaultimage: "http://localhost:8000/images/users/user.png",
    };
  },
  methods: {
    getAllData(page = 1) {
      this.checkPage = undefined ? page == 1 : page == this.checkPage;
      axios
        .get(
          `/manager/getTeachersAllComment?page=${page}&search=${this.search}`
        )
        .then((res) => {
          //   console.log(res.data);
          this.allComments = res.data.allComments;
          this.checkPage = page;
        })
        .catch();
    },
    getAllData1(page = 1) {
      this.checkPage1 = undefined ? page == 1 : page == this.checkPage1;
      axios
        .get(
          `/manager/getTeachersAllCommentConfirmParent?page=${page}&search=${this.search1}`
        )
        .then((res) => {
          //   console.log(res.data);
          this.allCommentsConfirmParent = res.data.allCommentsConfirmParent;
          this.checkPage1 = page;
        })
        .catch();
    },
    getAllData2(page = 1) {
      this.checkPage2 = undefined ? page == 1 : page == this.checkPage2;
      axios
        .get(
          `/manager/getTeachersAllCommentConfirmParentReaded?page=${page}&search=${this.search2}`
        )
        .then((res) => {
          //   console.log(res.data);
          this.allCommentsConfirmParentReaded =
            res.data.allCommentsConfirmParentReaded;
          this.checkPage2 = page;
        })
        .catch();
    },
    readParentConfirm(comment) {
      axios
        .post("/manager/confirmReadParent", { comment, read: true })
        .then((res) => {
          //   console.log(res.data);
          Fire.$emit("read");
          Fire.$emit("read1");
          Fire.$emit("read2");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    removeParentConfirm(comment) {
      axios
        .post("/manager/confirmReadParent", { comment, read: false })
        .then((res) => {
          Fire.$emit("read");
          Fire.$emit("read1");
          Fire.$emit("read2");
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  created() {
    this.getAllData(this.checkPage);
    this.getAllData1(this.checkPage1);
    this.getAllData2(this.checkPage2);
    Fire.$on("read", () => {
      this.getAllData(this.checkPage);
    });
    Fire.$on("read1", () => {
      this.getAllData1(this.checkPage1);
    });
    Fire.$on("read2", () => {
      this.getAllData2(this.checkPage2);
    });
  },
};
</script>

<style>
</style>
