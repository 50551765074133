<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-header p-2">
          <multiselect
            v-model="year_id"
            deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
            track-by="id"
            label="id"
            :custom-label="customYearName"
            placeholder="Хичээлийн жилээр шүүж харах"
            :options="allYears"
            :searchable="true"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.start_date | dateYear }}-{{
                  option.finish_date | dateYear
                }}
                - хичээлийн жил
              </strong></template
            >
          </multiselect>
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#employeesList" data-toggle="tab"
                >Багш, ажилчид</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#studentsList" data-toggle="tab"
                >Суралцагч</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body" style="overflow-x: auto">
          <b-overlay :show="rewardSuccessStatusLoaded" rounded="sm">
            <b-row>
              <b-col lg="10">
                <h3 class="card-title">Шагналын бүртгэл</h3>
              </b-col>
              <b-col lg="2" class="pb-2"
                ><b-button
                  variant="outline-primary"
                  @click.prevent="createRewardSuccess()"
                  size="sm"
                  >Бүртгэх</b-button
                ></b-col
              >
            </b-row>
            <div class="tab-content">
              <div class="tab-pane active" id="employeesList">
                <b-row>
                  <b-col lg="12" class="my-1">
                    <b-form-group
                      label="Нэг хуудсанд харуулах тоо"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageRewardSuccesses"
                        :options="pageOptionsDocuments"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Хайлт хийх утга оруулна уу!"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filterRewardSuccess"
                          type="search"
                          placeholder="Хайлт хийх утгаа оруулна уу!"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            :disabled="!filterRewardSuccess"
                            @click="filterRewardSuccess = ''"
                            >Цэвэрлэх</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPageRewardSuccesses"
                  :total-rows="totalRowsRewardSuccesstStatuses"
                  :per-page="perPageRewardSuccesses"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="allEmployeesRewardSuccesses"
                  :fields="fieldsActiveRewardSuccess"
                  :current-page="currentPageRewardSuccesses"
                  :per-page="perPageRewardSuccesses"
                  style="font-size: 80%"
                  :filter-ignored-fields="filterIgnoredFieldsRewardSuccess"
                  :filter="filterRewardSuccess"
                  :filter-included-fields="filterOnRewardSuccess"
                  @filtered="onFilteredRewardSuccesses"
                >
                  <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(my_reward_info)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_name }}
                    </b-badge>
                    <div
                      v-if="
                        userInfo.pivot.model_id == data.item.doc_owner_id ||
                        userInfo.name == 'cipalprin'
                      "
                    >
                      <a
                        href="#"
                        class="badge bg-warning"
                        @click="editRewardSuccess(data.item)"
                        ><i class="fas fa-pencil-alt"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="deleteRewardSuccess(data.item)"
                        ><i class="far fa-trash-alt"></i
                      ></a>
                    </div>
                  </template>
                  <template #cell(my_reward_info_tuvshin)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_tuvshin }}
                    </b-badge>
                  </template>
                  <template #cell(my_reward_info_turul)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_turul }}
                    </b-badge>
                  </template>
                  <template #cell(my_employee_info)="data">
                    <b-badge variant="secondary" class="text-wrap">
                      {{
                        data.item.my_employee_info.employee.teacher_last_name
                          | capitalizeAndEkhniiVseg
                      }}.
                      {{ data.item.my_employee_info.employee.teacher_name }}
                    </b-badge>
                  </template>
                  <template #cell(my_year_info)="data">
                    <b-badge variant="warning" class="text-wrap">
                      {{ data.item.my_year_info.start_date | dateYear }}
                      :
                      {{ data.item.my_year_info.finish_date | dateYear }}
                    </b-badge>
                  </template>
                  <template #cell(shagnal_olgogdson_tailbar)="data">
                    <b-badge
                      variant="warning"
                      class="text-wrap"
                      style="width: 8rem"
                    >
                      {{ data.item.shagnal_olgogdson_tailbar }}
                    </b-badge>
                  </template>
                  <template #cell(shagnal_olgoson_ognoo)="data">
                    {{ data.item.shagnal_olgoson_ognoo | dateYearMonthDay }}
                  </template>
                  <template #cell(shagnal_olgoson_dugaar)="data">
                    {{ data.item.shagnal_olgoson_dugaar }}({{
                      data.item.shagnal_olgoson_dugaar
                    }})
                  </template>
                  <template #cell(shagnal_duusakh_ognoo)="data">
                    {{ data.item.shagnal_duusakh_ognoo | dateYearMonthDay }}
                  </template>
                  <template #cell(created_at)="data">
                    {{ data.item.created_at | dateYearMonthDay }}
                  </template>
                </b-table>
              </div>
              <div class="tab-pane" id="studentsList">
                <b-row>
                  <b-col lg="12" class="my-1">
                    <b-form-group
                      label="Нэг хуудсанд харуулах тоо"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageRewardSuccessesStudent"
                        :options="pageOptionsDocumentsStudent"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Хайлт хийх утга оруулна уу!"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filterRewardSuccessStudent"
                          type="search"
                          placeholder="Хайлт хийх утгаа оруулна уу!"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            :disabled="!filterRewardSuccessStudent"
                            @click="filterRewardSuccessStudent = ''"
                            >Цэвэрлэх</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPageRewardSuccessesStudent"
                  :total-rows="totalRowsRewardSuccessStatusesStudent"
                  :per-page="perPageRewardSuccessesStudent"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table
                  hover
                  :items="allStudentsRewardSuccesses"
                  :fields="fieldsActiveRewardSuccessStudent"
                  :current-page="currentPageRewardSuccessesStudent"
                  :per-page="perPageRewardSuccessesStudent"
                  style="font-size: 80%"
                  :filter-ignored-fields="
                    filterIgnoredFieldsRewardSuccessStudent
                  "
                  :filter="filterRewardSuccessStudent"
                  :filter-included-fields="filterOnRewardSuccessStudent"
                  @filtered="onFilteredRewardSuccessesStudent"
                >
                  <!-- :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection" -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(my_reward_info)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_name }}
                    </b-badge>
                    <div
                      v-if="
                        userInfo.pivot.model_id == data.item.doc_owner_id ||
                        userInfo.name == 'cipalprin'
                      "
                    >
                      <a
                        href="#"
                        class="badge bg-warning"
                        @click="editRewardSuccess(data.item)"
                        ><i class="fas fa-pencil-alt"></i
                      ></a>
                      <a
                        href="#"
                        class="badge bg-danger"
                        @click="deleteRewardSuccess(data.item)"
                        ><i class="far fa-trash-alt"></i
                      ></a>
                    </div>
                  </template>
                  <template #cell(my_reward_info_tuvshin)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_tuvshin }}
                    </b-badge>
                  </template>
                  <template #cell(my_reward_info_turul)="data">
                    <b-badge variant="light" class="text-wrap">
                      {{ data.item.my_reward_info.shagnal_turul }}
                    </b-badge>
                  </template>
                  <template #cell(my_student_info)="data">
                    <b-badge variant="secondary" class="text-wrap">
                      {{
                        data.item.my_student_info.my_profile_info
                          .student_last_name | capitalizeAndEkhniiVseg
                      }}.
                      {{
                        data.item.my_student_info.my_profile_info.student_name
                      }}
                    </b-badge>
                  </template>
                  <template #cell(my_year_info)="data">
                    <b-badge variant="warning" class="text-wrap">
                      {{ data.item.my_year_info.start_date | dateYear }}
                      :
                      {{ data.item.my_year_info.finish_date | dateYear }}
                    </b-badge>
                  </template>
                  <template #cell(shagnal_olgogdson_tailbar)="data">
                    <b-badge
                      variant="warning"
                      class="text-wrap"
                      style="width: 8rem"
                    >
                      {{ data.item.shagnal_olgogdson_tailbar }}
                    </b-badge>
                  </template>
                  <template #cell(shagnal_olgoson_ognoo)="data">
                    {{ data.item.shagnal_olgoson_ognoo | dateYearMonthDay }}
                  </template>
                  <template #cell(shagnal_olgoson_dugaar)="data">
                    {{ data.item.shagnal_olgoson_dugaar }}({{
                      data.item.shagnal_olgoson_dugaar
                    }})
                  </template>
                  <template #cell(shagnal_duusakh_ognoo)="data">
                    {{ data.item.shagnal_duusakh_ognoo | dateYearMonthDay }}
                  </template>
                  <template #cell(created_at)="data">
                    {{ data.item.created_at | dateYearMonthDay }}
                  </template>
                </b-table>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-reward-success"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Шагнал бүртгэх"
      class="modal fade"
      hide-footer
      @hidden="resetModal"
      scrollable
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <!-- {{ form.comp_par_main_problem }} -->
                </h3>
              </div>
              <div class="card-body table-responsive">
                <b-row>
                  <b-col lg="6">
                    <b-form-radio
                      v-model="selected"
                      name="some-teacher"
                      value="employee"
                      :disabled="notEnabled"
                      >Багш, ажилчид</b-form-radio
                    ></b-col
                  >
                  <b-col lg="6">
                    <b-form-radio
                      v-model="selected"
                      name="some-student"
                      value="student"
                      :disabled="notEnabled"
                      >Суралцагч</b-form-radio
                    ></b-col
                  >
                </b-row>
                <!-- <b-form @submit="onUpdate" @reset="onReset"> -->
                <!-- <b-form @submit="onUpdate"> -->
                <!-- @submit="" @reset="" -->
                <Multiselect
                  v-show="selected === 'employee'"
                  v-model="form.employee_migration_id"
                  :options="employees"
                  track-by="emp_teacher_name"
                  label="emp_teacher_name"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="Багш, ажилтан сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :custom-label="customNameEmployee"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>
                      {{ option.emp_teacher_last_name }}
                      {{ option.emp_teacher_name }}({{
                        option.dep_department_code
                      }})</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </Multiselect>

                <Multiselect
                  v-show="selected === 'student'"
                  v-model="form.student_migration_id"
                  :options="students"
                  track-by="pro_student_name"
                  label="pro_student_name"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="Суралцагч сонгох"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  :custom-label="customNameStudent"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>
                      {{ option.pro_student_last_name }}
                      {{ option.pro_student_name }}({{
                        option.school_class_full_name
                      }})</strong
                    ><strong> сонгогдлоо</strong></template
                  >
                </Multiselect>
                <b-form>
                  <b-form-group>
                    <Multiselect
                      v-model="form.reward_id"
                      :options="rewards"
                      track-by="id"
                      label="id"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      placeholder="Шагнал сонгох"
                      :allow-empty="false"
                      deselect-label="Can't remove this value"
                      :custom-label="customNameShagnal"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        ><strong>
                          Нэр:
                          {{ option.shagnal_name }} </strong
                        ><strong> сонгогдлоо</strong></template
                      >
                    </Multiselect>
                  </b-form-group>
                  <b-form-group
                    id="shagnal_olgogdson_tailbar"
                    label-for="shagnal_olgogdson_tailbar"
                  >
                    <label>Шагнал олгосон тайлбар</label>
                    <b-form-textarea
                      id="shagnal_olgogdson_tailbar"
                      v-model="form.shagnal_olgogdson_tailbar"
                      placeholder="Шагнал олгосон тайлбар"
                      required
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group>
                    <label>Шагнал олгосон хугацаа</label>
                    <b-form-datepicker
                      id="shagnal_olgoson_ognoo"
                      v-model="form.shagnal_olgoson_ognoo"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'shagnal_olgoson_ognoo'
                          ),
                        },
                      ]"
                      placeholder="Шагнал олгосон хугацаа"
                    ></b-form-datepicker>
                    <has-error
                      :form="form"
                      field="shagnal_olgoson_ognoo"
                    ></has-error>
                  </b-form-group>

                  <b-form-group
                    id="shagnal-duusakh-hugatsaatai"
                    v-slot="{ ariaDescribedby }"
                  >
                    <label>Шагнал дуусах хугацаатай эсэх</label>
                    <b-form-checkbox-group
                      id="checkboxes-4"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-radio
                        value="1"
                        v-model="form.shagnal_duusakh_hugatsaatai"
                        >Тийм</b-form-radio
                      >
                      <b-form-radio
                        value="0"
                        v-model="form.shagnal_duusakh_hugatsaatai"
                        >Үгүй</b-form-radio
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-form-group
                    v-show="form.shagnal_duusakh_hugatsaatai == '1'"
                  >
                    <label>Шагналын хугацаа дуусах огноо</label>
                    <b-form-datepicker
                      id="shagnal_duusakh_ognoo"
                      v-model="form.shagnal_duusakh_ognoo"
                      today-button
                      label-today-button="Өнөөдөр"
                      reset-button
                      label-reset-button="Шинэчлэх"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      class="mb-2"
                      :class="[
                        'form-control',
                        {
                          'is-invalid': form.errors.has(
                            'shagnal_duusakh_ognoo'
                          ),
                        },
                      ]"
                      placeholder="Шагнал дуусан хугацаа"
                    ></b-form-datepicker>
                    <has-error
                      :form="form"
                      field="shagnal_duusakh_ognoo"
                    ></has-error>
                  </b-form-group>

                  <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <label>Шагналын дугаар</label>

                    <b-form-input
                      type="text"
                      v-model="form.shagnal_olgoson_dugaar"
                    ></b-form-input>
                  </b-form-group>

                  <b-button
                    variant="primary"
                    @click.prevent="storeRewardSuccess()"
                    v-show="!editMode"
                    >Хадгалах</b-button
                  >
                  <b-button
                    variant="warning"
                    @click.prevent="updateRewardSuccess()"
                    v-show="editMode"
                    >Хадгалах</b-button
                  >
                  <b-button @click="resetModal" variant="danger"
                    >Цуцлах</b-button
                  >
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import searchManager from "./Search/managerSearch.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      //MODAL BEGIN
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      showMode: false,
      //MODAL END

      editMode: false,
      allEmployeesRewardSuccesses: [],
      allStudentsRewardSuccesses: [],
      rewards: [],
      allYears: [],
      year_id: "",
      //   keyword: "",
      selected: false,
      employees: [],
      students: [],

      habitsTeachers: [],
      habitsStudents: [],
      habitsParents: [],
      shiidver: [
        { value: null, text: "Төвөл сонгоно уу?" },
        { value: 0, text: "Эерэг" },
        { value: 1, text: "Сөрөг" },
      ],
      form: new Form({
        id: "",
        reward_id: "",
        employee_migration_id: "",
        student_migration_id: "",
        shagnal_olgogdson_tailbar: "",
        shagnal_olgoson_ognoo: "",
        shagnal_duusakh_hugatsaatai: "",
        shagnal_duusakh_ognoo: "",
        shagnal_olgoson_dugaar: "",
      }),
      notEnabled: false,

      //b-table тохиргоо
      rewardSuccessStatusLoaded: true,
      totalRowsRewardSuccesstStatuses: 1,
      currentPageRewardSuccesses: 1,
      perPageRewardSuccesses: 25,
      pageOptionsDocuments: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterRewardSuccess: "",
      filterOnRewardSuccess: [],
      filteredRewardSuccessCount: 0,
      filterIgnoredFieldsRewardSuccess: ["my_employee_info"],
      fieldsActiveRewardSuccess: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "my_reward_info_tuvshin",
          label: "Салбар/Түвшин",
          sortable: true,
        },
        {
          key: "my_reward_info_turul",
          label: "Төрөл",
          sortable: true,
        },
        {
          key: "my_reward_info",
          label: "Шагнал",
          sortable: true,
        },
        {
          key: "my_employee_info",
          label: "Ажилтан",
          sortable: true,
        },
        {
          key: "my_year_info",
          label: "Хичээлийн жил",
          sortable: true,
        },
        {
          key: "shagnal_olgogdson_tailbar",
          label: "Шагнал олгосон үндэслэл",
          sortable: true,
        },
        {
          key: "shagnal_olgoson_ognoo",
          label: "Шагнал олгосон огноо",
          sortable: true,
        },
        {
          key: "shagnal_olgoson_dugaar",
          label: "Шагналын дугаар",
          sortable: true,
        },
        {
          key: "shagnal_duusakh_ognoo",
          label: "Хүчинтэй хугацаа",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
      ],
      //b-table тохиргоо суралцагч
      // rewardSuccessStatusLoaded: true,
      totalRowsRewardSuccessStatusesStudent: 1,
      currentPageRewardSuccessesStudent: 1,
      perPageRewardSuccessesStudent: 25,
      pageOptionsDocumentsStudent: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        100,
        125,
        150,
        175,
        200,
        { value: 300, text: "Илүү олноор харах" },
      ],
      //   sortBy: "",
      //   sortDesc: false,
      //   sortDirection: "asc",
      filterRewardSuccessStudent: "",
      filterOnRewardSuccessStudent: [],
      filteredRewardSuccessCountStudent: 0,
      filterIgnoredFieldsRewardSuccessStudent: ["my_student_info"],
      fieldsActiveRewardSuccessStudent: [
        {
          key: "index",
          label: "№",
          sortable: true,
        },
        {
          key: "my_reward_info_tuvshin",
          label: "Салбар/Түвшин",
          sortable: true,
        },
        {
          key: "my_reward_info_turul",
          label: "Төрөл",
          sortable: true,
        },
        {
          key: "my_reward_info",
          label: "Шагнал",
          sortable: true,
        },
        {
          key: "my_student_info",
          label: "Суралцагч",
          sortable: true,
        },
        {
          key: "my_year_info",
          label: "Хичээлийн жил",
          sortable: true,
        },
        {
          key: "shagnal_olgogdson_tailbar",
          label: "Шагнал олгосон үндэслэл",
          sortable: true,
        },
        {
          key: "shagnal_olgoson_ognoo",
          label: "Шагнал олгосон огноо",
          sortable: true,
        },
        {
          key: "shagnal_olgoson_dugaar",
          label: "Шагналын дугаар",
          sortable: true,
        },
        {
          key: "shagnal_duusakh_ognoo",
          label: "Хүчинтэй хугацаа",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Бүртгэсэн огноо",
          sortable: true,
        },
      ],
    };
  },
  components: {
    // searchManager: searchManager,
    Multiselect,
  },
  computed: {
    userInfo() {
      return this.$store.state.userRoleInfo;
      //   .filter((todo) => todo.done).length;
    },
  },
  watch: {
    selected(newValue, oldValue) {
      if (this.editMode === false) {
        this.form.reset();
      } else if (this.editMode === true) {
        // console.log("editMode = true");
      }
    },
    year_id: function (newVal, oldVal) {
      // console.log(newVal);
      axios
        .post("/all_worker_functions/getAllRewardSuccess", {
          yearID: newVal.id,
        })
        .then((res) => {
          this.allYears = res.data.allYears;
          this.rewards = res.data.rewards;
          this.employees = res.data.employees;
          this.students = res.data.students;
          this.allEmployeesRewardSuccesses =
            res.data.allEmployeesRewardSuccesses;
          this.allStudentsRewardSuccesses = res.data.allStudentsRewardSuccesses;

          this.rewardSuccessStatusLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  methods: {
    getAllRewardSuccess() {
      axios
        .post("/all_worker_functions/getAllRewardSuccess", {
          yearID: this.year_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.allYears = res.data.allYears;
          this.rewards = res.data.rewards;
          this.employees = res.data.employees;
          this.students = res.data.students;
          this.allEmployeesRewardSuccesses =
            res.data.allEmployeesRewardSuccesses;
          this.allStudentsRewardSuccesses = res.data.allStudentsRewardSuccesses;

          this.rewardSuccessStatusLoaded = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //b-table тохирго
    onFilteredRewardSuccesses(filteredItems) {
      //   console.log(filteredItems);
      this.filteredRewardSuccessCount = filteredItems.length;
      //   console.log(filteredItems.length);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRewardSuccesstStatuses = filteredItems.length;
      this.currentPageRewardSuccesses = 1;
    },
    //b-table тохирго устгагдсан
    onFilteredRewardSuccessesStudent(filteredItems) {
      //   console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRewardSuccessStatusesStudent = filteredItems.length;
      this.currentPageRewardSuccessesStudent = 1;
    },

    customNameEmployee({
      emp_teacher_name,
      emp_teacher_last_name,
      dep_department_code,
    }) {
      return `${emp_teacher_last_name} ${emp_teacher_name}(${dep_department_code})`;
    },
    customNameStudent({
      pro_student_name,
      pro_student_last_name,
      school_class_full_name,
    }) {
      return `${pro_student_last_name} ${pro_student_name}: Анги: ${school_class_full_name}`;
    },
    customNameShagnal({ shagnal_name }) {
      return `${shagnal_name}`;
    },

    resetModal() {
      //   console.log("resetModal");
      this.$bvModal.hide("modal-reward-success");
      this.selected = "";
      this.notEnabled = false;
      this.form.reset();
    },
    createRewardSuccess() {
      this.$bvModal.show("modal-reward-success");
      //   console.log("create function load");
    },
    storeRewardSuccess() {
      this.form
        .post("/all_worker_functions/storeRewardSuccess", {
          selected: this.selected,
        })
        .then((response) => {
          this.resetModal();
          this.$toastr.s("Ажиглалт амжилттай хадгаллаа.", "Амжилттай");
          Fire.$emit("loadAllEmployeesRewards");
        })
        .catch(() => {
          this.$toastr.e(
            "Уулзалтын бүртгэл үүсгэж чадсангүй, Мэдээллийг бүрэн оруулсан эсэхээ хянаад, дахин оролдоно уу?",
            "Алдаа гарлаа."
          );
        });
    },
    editRewardSuccess(rewardSuccess) {
      // console.log(rewardSuccess);
      this.form.reset();
      this.form.fill(rewardSuccess);

      if (rewardSuccess.employee_migration_id != 0) {
        this.employees.filter((el) => {
          // console.log(el.emp_mig_id);
          if (el.emp_mig_id == rewardSuccess.employee_migration_id) {
            this.form.employee_migration_id = el;
            //   console.log(this.formTeacherSuccess.employee_migration_id);
          }
        });
        this.selected = "employee";
      } else if (rewardSuccess.student_migration_id != 0) {
        this.students.filter((el) => {
          if (el.stud_mig_id == rewardSuccess.student_migration_id) {
            this.form.student_migration_id = el;
          }
        });
        this.selected = "student";
      }
      this.rewards.filter((el) => {
        if (el.id == rewardSuccess.reward_id) {
          this.form.reward_id = el;
        }
      });

      this.notEnabled = true;
      this.editMode = true;
      this.$bvModal.show("modal-reward-success");
    },
    updateRewardSuccess() {
      //   console.log(this.form);
      this.form
        .put(
          "/all_worker_functions/updateRewardSuccess/" +
            this.form.id +
            "/" +
            this.selected
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          this.resetModal();
          Fire.$emit("loadAllEmployeesRewards");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    deleteRewardSuccess(rewardSuccess) {
      // console.log(rewardSuccess);
      Swal.fire({
        title: "Устгах уу?",
        text: "Бүртгэгдсэн шагнал устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, Цуцлах",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              "/all_worker_functions/deleteRewardSuccess/" + rewardSuccess.id
            )
            .then((response) => {
              //   console.log(response.data);
              Fire.$emit("loadAllEmployeesRewards");
              Swal.fire(
                "Амжилттай!",
                "Шагналын мэдээллийг мажилттай устгалаа.",
                "success"
              );
            })
            .catch((errors) => {
              // console.log(errors);
            });
        }
      });
    },
    customYearName({ start_date, finish_date }) {
      return `${start_date} - ${finish_date} жил`;
    },
  },
  created() {
    this.getAllRewardSuccess();
    Fire.$on("loadAllEmployeesRewards", () => {
      this.getAllRewardSuccess();
    });
  },
};
</script>
