<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Өдрийн тайлан</h3>
          <!-- <search-student
            ></search-student> -->
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-3">
              <form @submit.prevent="getAllLogs">
                <div class="input-group input-group-sm">
                  <input
                    class="form-control form-control-navbar"
                    type="search"
                    placeholder="Логийн нэрээр хайх боломжтой..."
                    aria-label="Search"
                    v-model="search"
                    id="popover-target-1"
                  />
                </div>
              </form>
            </div>
            <div class="col-md-3">
              Сонгогдсон: {{ allActivitiesLogCount }} Нийт:
              {{ allActivities }}
            </div>

            <div class="col-md-6">
              <pagination
                :data="allActivitiesLog"
                @pagination-change-page="getAllLogs"
                :limit="5"
                size="small"
                align="center"
              >
                <span slot="prev-nav">&lt; Өмнөх</span>
                <span slot="next-nav">Дараах &gt;</span>
              </pagination>
            </div>
          </div>

          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Үйлдлүүд</th>
                <th>Логын нэр</th>
                <th>Тайлбар</th>
                <th>Засагдсан модел</th>
                <th>Хийсэн үйлдэл</th>
                <th>Тухайн моделын зассан id</th>
                <th>Зассан хэрэглэгчийн модел</th>
                <th>Зассан хэрэглэгчийн id</th>
                <th>Зассан агуулга</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody
              v-for="(log, index) in allActivitiesLog.data"
              :key="log.task_id"
            >
              <!-- //:key="schoolEntrant.user_id" -->
              <tr>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a href="#" class="badge bg-primary" @click="editLog(log)"
                    ><i class="fa fa-check"></i
                  ></a>
                </td>
                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <!-- v-if="log.guitsetgel >= 85" -->
                  <!-- v-show="log.guitsetgel != null" -->
                  <b-badge variant="success"> {{ log.log_name }}</b-badge>
                  <!-- <b-badge
                    v-if="log.guitsetgel < 85"
                    variant="light"
                    v-show="log.guitsetgel != null"
                  >
                    {{ log.guitsetgel }}</b-badge
                  >
                  <b-badge variant="warning" v-show="log.guitsetgel === null">
                    Гүйцэтгэл ороогүй.</b-badge
                  >
                  <div class="progress progress-xs progress-striped active">
                    <div
                      v-if="log.guitsetgel < 30"
                      class="progress-bar bg-danger"
                      :style="{ width: `${log.guitsetgel}%` }"
                    ></div>
                    <div
                      v-if="(log.guitsetgel >= 30) & (log.guitsetgel < 60)"
                      class="progress-bar bg-warning"
                      :style="{ width: `${log.guitsetgel}%` }"
                    ></div>
                    <div
                      v-if="(log.guitsetgel >= 60) & (log.guitsetgel < 80)"
                      class="progress-bar bg-primary"
                      :style="{ width: `${log.guitsetgel}%` }"
                    ></div>
                    <div
                      v-if="log.guitsetgel >= 80"
                      class="progress-bar bg-success"
                      :style="{ width: `${log.guitsetgel}%` }"
                    ></div>
                  </div> -->
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="light"
                    style="width: 8rem; white-space: pre-wrap"
                    >{{ log.description }}</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light"> {{ log.subject_type }}</b-badge>
                </td>

                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light">{{ log.event }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light"> {{ log.subject_id }}</b-badge>
                </td>
                <td
                  class="dtr-control sorting_1"
                  tabindex="0"
                  style="white-space: pre-wrap"
                >
                  <b-badge variant="light"> {{ log.causer_type }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light"> {{ log.causer_id }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="light"
                    style="width: 16rem; white-space: pre-wrap"
                  >
                    {{ log.properties }}</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ log.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ log.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      allActivitiesLog: {},
      allActivities: "",
      allActivitiesLogCount: "",
      editMode: false,
      checkPage: undefined,
      search: "",
      form: new Form({
        task_id: "",
        department_id: "",
        enrol_employee_id: "",
        recieved_employee_id: "",
        position_id: "",
        performance_date: "",
        log: "",
        manager_explain: "",
        guitsetgel: "",
      }),
    };
  },

  methods: {
    getAllLogs(page = 1) {
      this.checkPage = undefined ? page == 1 : page == this.checkPage;
      axios
        .get(
          `/curriculumadministrator/allActivitiesLog?page=${page}&search=${this.search}`
        )
        .then((response) => {
          this.allActivities = response.data.allActivities;
          this.allActivitiesLogCount = response.data.allActivitiesLogCount;
          this.allActivitiesLog = response.data.allActivitiesLog;
          this.checkPage = page;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editLog(log) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(log);
      //   if (log.status1 == "registered") this.form.checked = false;
      //   else if (log.status1 == "confirmed") this.form.checked = true;
    },
    updateTask() {
      this.action = "Үүрэг даалгаврын мэдээллийг шинэчилж байна...";
      console.log(this.form);
      this.form
        .put(
          "/curriculumadministrator/taskUpdateCurrAdmin/" + this.form.task_id
        )
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("getTasks");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    createTask() {
      console.log("Үүрэг даалгавар бүртгэх");
      this.form
        .post("/curriculumadministrator/createTaskCurrAdmin")
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Бүртгэлээ");
          Fire.$emit("getTasks");
          this.form.reset();
          //   this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteTask(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllLogs();
    Fire.$on("getTasks", () => {
      this.getAllLogs();
    });
  },
};
</script>

<style></style>
