<template>
    <div class="card card-default color-palette-box">
        <b-tabs pills card>
            <b-tab title="Ангийн суралцагчдын гаргасан амжилт">
                <b-card-text>
                    <table class="table table-hover table-striped table-valign-middle">
                        <thead>
                            <tr role="row">
                                <th>№</th>
                                <th>Оролцсон олимпиад, уралдаан тэмцээн</th>
                                <th>Суралцагчийн овог, нэр</th>
                                <th>Анги</th>
                                <th>Байр</th>
                                <th>Амжилт гаргасан түвшин</th>
                                <th>Гаргасан амжилт</th>
                                <th>Тайлбар</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd" v-for="(sSuccess, index) in studentSuccesses" :key="sSuccess.id">
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ index + 1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <span v-if="sSuccess.my_competition_info !== null">
                                        <b-badge variant="success" class="text-wrap" style="width: 8rem">{{
                                            sSuccess.my_competition_info.name }}</b-badge>
                                        {{
                                        }}</span>
                                    <span v-if="sSuccess.my_competition_info === null" class="text-wrap"
                                        style="width: 8rem">Олимпиад, уралдаан тэмцээн бүртгээгүй байна.</span>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ sSuccess.student_last_name[0] }}.
                                    {{ sSuccess.student_name }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ sSuccess.student_tuvshin
                                    }}{{ sSuccess.student_buleg }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <b-badge variant="info">{{
                                        filterEzelsenBair(sSuccess.amjilt_ezelsen_bair)
                                    }}</b-badge>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <b-badge variant="info">
                                        {{
                                            filterStudent_turul(sSuccess.student_turul)
                                        }}</b-badge>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ sSuccess.amjilt_name }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ sSuccess.amjilt_tailbar }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-card-text>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
export default {
    props: ["teacherId"],
    data() {
        return {
            mySuccesses: [],
            studentSuccesses: [],
            turul: [
                { name: 0, student_turul: "Түвшин сонгох" },
                { name: 1, student_turul: "Анги" },
                { name: 2, student_turul: "Сургууль" },
                { name: 3, student_turul: "Дүүрэг" },
                { name: 4, student_turul: "Нийслэл" },
                { name: 5, student_turul: "Улс" },
                { name: 6, student_turul: "Олон улс" },
            ],
            ezelsenBair: [
                { name: 0, ezelsen_bair: "Сонголт хийнэ үү?" },
                { name: 1, ezelsen_bair: "Алт" },
                { name: 2, ezelsen_bair: "Мөнгө" },
                { name: 3, ezelsen_bair: "Хүрэл" },
                { name: 4, ezelsen_bair: "Тусгай байр(4-10)" },
            ],
        };
    },
    methods: {
        getClassSuccesses() {
            axios
                .post("/teacher/getClassSuccesses", {
                    teacherId: this.teacherId,
                })
                .then((res) => {
                    this.studentSuccesses = res.data.studentSuccesses;
                })
                .catch((error) => {
                    //   console.log(error);
                });
        },
        filterEzelsenBair(val) {
            //   console.log(val);
            let myEzBair = "";
            this.ezelsenBair.forEach((element) => {
                // console.log("name =>" + element.name);
                if (element.name == val) {
                    myEzBair = element.ezelsen_bair;
                }
            });
            return myEzBair;
        },
        filterStudent_turul(val) {
            //   console.log(val);
            let myTurul = "";
            this.turul.forEach((element) => {
                // console.log("name =>" + element.name);
                if (element.name == val) {
                    myTurul = element.student_turul;
                }
            });
            return myTurul;
        },
    },
    created() {
        this.getClassSuccesses();
    },
};
</script>

<style></style>
