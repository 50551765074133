<template>
  <div class="row pt-3">
    <div class="col-md-3">
      <div
        class="card card-fuchsia"
        style="transition: all 0.15s ease 0s; height: inherit; width: inherit"
      >
        <div class="card-header">
          <h3 class="card-title">Миний хүүхдүүд</h3>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="maximize"
            >
              <i class="fas fa-expand"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Овог</th>
                <th>Нэр(Дарна уу?)</th>
                <th style="width: 40px">Анги</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(child, i) in myChilds" :key="i">
                <td>{{ ++i }}</td>
                <td>{{ child.profile_student_last_name }}</td>
                <td>
                  <a href="#" @click.prevent="getMyChildCourses(child)">{{
                    child.profile_student_name
                  }}</a>
                </td>
                <td>{{ child.school_class_full_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- Form Element sizes -->
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="card-header">
          <h6 class="card-title" style="font-size: 80%">
            Хичээлүүд(Улирлын дүн баталгаажаагүй үед 0 утгатай байна. 0 утгатай
            байсан ч тухайн улиралд авсан байгаа явцын шалгалтуудын дүнг харж
            болно. Тиймээс дүн дээр дарна уу.)
          </h6>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body" style="overflow-x: auto">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="success"
              label="Spinning"
              v-if="allGradeCheck === false"
            ></b-spinner>
          </div>
          <table class="table table-hover table-sm" v-if="allGradeCheck === true">
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Хичээл</th>
                <th>Багш</th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Шалгалт"
                >
                  Улирал-1
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Шалгалт"
                >
                  Улирал-2
                </th>
                <th
                  v-b-popover.hover.top="
                    ' Тухайн улиралд авсан явц болон улирлын шалгалтын дүнтэй танилцах.'
                  "
                  title="Шалгалт"
                >
                  Улирал-3
                </th>
                <th>Дундаж(Улирал)</th>
                <th
                  v-b-popover.hover.top="' Хувийн хэрэгт бичигдэнэ.'"
                  title="Жилийн эцсийн үнэлгээ"
                >
                  Жил
                </th>
                <!-- <th>Үйлдэл</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(childCourse, i) in myChildCoursesGrades" :key="i">
                <td>{{ ++i }}</td>
                <td>{{ childCourse.subject_name }}</td>
                <td>
                  <a href="#" @click.prevent=""
                    >{{ childCourse.emp_teacher_last_name[0].toUpperCase() }}.{{
                      childCourse.emp_teacher_name
                    }}</a
                  >
                </td>
                <td>
                  <a
                    href=""
                    @click.prevent="getQuarterExamAnalyse(childCourse, 1)"
                  >
                    <span v-if="childCourse.grade_exam_1 === null"
                      >0 ({{
                        symbolGradeCheck(childCourse.grade_exam_1)
                      }})</span
                    >
                    <span v-if="childCourse.grade_exam_1 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_1) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_1)
                      }})</span
                    >
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 2)"
                  >
                    <span v-if="childCourse.grade_exam_2 === null"
                      >0 ({{
                        symbolGradeCheck(childCourse.grade_exam_2)
                      }})</span
                    >
                    <span v-if="childCourse.grade_exam_2 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_2) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_2)
                      }})</span
                    >
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    @click.prevent="getQuarterExamAnalyse(childCourse, 3)"
                  >
                    <span v-if="childCourse.grade_exam_3 === null"
                      >0 ({{
                        symbolGradeCheck(childCourse.grade_exam_3)
                      }})</span
                    >
                    <span v-if="childCourse.grade_exam_3 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_3) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_3)
                      }})</span
                    >
                  </a>
                </td>
                <!-- Улирлын шалгалтын дундаж -->
                <td>
                  <span
                    >{{ averageYearBefore(childCourse).toFixed(1) }} ({{
                      symbolGradeCheck(
                        averageYearBefore(childCourse).toFixed(1)
                      )
                    }})</span
                  >
                </td>
                <!-- Жилийн эцсийн баталгаажсан дүн -->
                <td>
                  <a
                    href="#"
                    @click.prevent="getYearExamAnalyse(childCourse, 3)"
                  >
                    <span v-if="childCourse.grade_exam_jil3 === null"
                      >0 ({{
                        symbolGradeCheck(childCourse.grade_exam_jil3)
                      }})</span
                    >
                    <span v-if="childCourse.grade_exam_jil3 !== null"
                      >{{ toOronTootsokh(childCourse.grade_exam_jil3) }} ({{
                        symbolGradeCheck(childCourse.grade_exam_jil3)
                      }})</span
                    >
                  </a>
                </td>
                <!-- <td></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <div class="col-md-12" v-show="showExam">
      <div class="card card-primary">
        <div class="card-header bg-info color-palette">
          <div class="row">
            <div class="col-md-3">
              <h3 class="card-title">
                {{ courseExam.subject_name }} шалгалтууд
              </h3>
            </div>
            <div class="col-md-6">
              <multiselect
                v-model="selectChildCourseExam"
                :options="myChildCourseExams"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                placeholder="Шалгалт сонгох"
                :custom-label="nameWithExams"
              ></multiselect>
            </div>
            <div class="col-md-3">
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" style="overflow-x: auto">
          <table
            class="table table-sm"
            v-show="selectChildCourseExamValues.length"
          >
            <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Даалгавар</th>
                <th>Алхам-1</th>
                <th>Алхам-2</th>
                <th>Алхам-3</th>
                <th>Алхам-4</th>
                <th>Алхам-5</th>
                <th>Алхам-6</th>
                <th>Алхам-7</th>
                <th>Алхам-8</th>
                <th>Гүйцэтгэл</th>
                <th>GPA</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(exam, index) in selectChildCourseExamValues"
                :key="index"
              >
                <td>{{ ++index }}</td>
                <td>{{ exam.daalgar_number }}</td>
                <td>
                  {{ transferInt(exam.exam1) }}
                </td>
                <td>
                  {{ transferInt(exam.exam2) }}
                </td>
                <td>
                  {{ transferInt(exam.exam3) }}
                </td>
                <td>
                  {{ transferInt(exam.exam4) }}
                </td>
                <td>
                  {{ transferInt(exam.exam5) }}
                </td>
                <td>
                  {{ transferInt(exam.exam6) }}
                </td>
                <td>
                  {{ transferInt(exam.exam7) }}
                </td>
                <td>
                  {{ transferInt(exam.exam8) }}
                </td>
                <td>
                  <span>{{ averageExamStep(exam) }}</span>
                </td>
                <td>
                  <span class="badge badge-info"
                    >({{ symbolGradeCheck(averageExamStep(exam)) }})</span
                  >
                </td>
              </tr>
              <tr v-if="selectChildCourseExamValues.length != 0">
                <td></td>
                <td>Дундаж гүйцэтгэл</td>
                <td>{{ examPerValuesAverage.exam1Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam2Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam3Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam4Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam5Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam6Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam7Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam8Ave.toFixed(1) }}</td>
                <td>{{ examPerValuesAverage.exam1_8Ave.toFixed(1) }}</td>
                <td>
                  <span class="badge badge-info">
                    ({{
                      symbolGradeCheck(
                        examPerValuesAverage.exam1_8Ave.toFixed(1)
                      )
                    }})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      editMode: false,
      myChilds: [],
      myChildCoursesGrades: [],

      //Хичээлээр шалгалтыг шүүж харах.
      showExam: false,
      courseExam: "",
      myChildCourseExams: [],
      selectChildCourseExam: "",
      selectChildCourseExamValues: "",
      examPerValuesAverage: [],
      allGradeCheck: true,
    };
  },
  components: { Multiselect },
  watch: {
    selectChildCourseExam: {
      handler: function (newVal, oldVal) {
        if (oldVal !== null) {
          //   console.log(newVal, oldVal);
          this.getExamValues(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    getMyChilds() {
      axios
        .get("/parent/mychilds")
        .then((res) => {
          //   console.log(res.data);
          this.myChilds = res.data.mychilds;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    getMyChildCourses(child) {
      //   console.log("my courses");
      this.allGradeCheck = false;
      this.showExam = false;
      this.myChildCoursesGrades = [];
      this.courseExam = "";
      this.myChildCourseExams = [];
      this.selectChildCourseExam = "";
      this.selectChildCourseExamValues = [];
      axios
        .post("/parent/getMyChildCourses", {
          child: child,
        })
        .then((res) => {
          this.allGradeCheck = true;
          this.myChildCoursesGrades = res.data.myChildCoursesGrades;
          //   console.log(res.data);
        })
        .catch();
    },
    averageYearBefore(childCourse) {
      let average =
        (childCourse.grade_exam_1 +
          childCourse.grade_exam_2 +
          childCourse.grade_exam_3) /
        3;
      return average;
    },
    //явц улиралын шалгалтууд дуудах
    getQuarterExamAnalyse(course, quarter_id) {
      this.showExam = false;
      //   console.log(course, quarter_id);
      this.courseExam = course;
      axios
        .post("/parent/getMyChildCourseExams", {
          course,
          quarter_id,
        })
        .then((res) => {
          console.log(res.data.getMyChildCourseExams);
          this.myChildCourseExams = res.data.getMyChildCourseExams;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      this.showExam = true;
    },
    //жилийн шалгалтууд дуудах
    getYearExamAnalyse(course, quarter_id) {
      this.showExam = false;
      //   console.log(course, quarter_id);
      this.courseExam = course;
      axios
        .post("/parent/getMyChildCourseExamsYears", {
          course,
          quarter_id,
        })
        .then((res) => {
          //   console.log(res.data);
          this.myChildCourseExams = res.data.getMyChildCourseExams;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
      this.showExam = true;
    },

    getExamValues(newVal) {
      this.selectChildCourseExamValues = [];
      axios
        .post("/parent/examValues", {
          exam: newVal,
        })
        .then((res) => {
          //   console.log(res.data);
          this.selectChildCourseExamValues = res.data.examValues;
          this.calculateAverageExamValues(this.selectChildCourseExamValues);
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    transferInt(value) {
      return parseInt(value);
    },
    averageExamStep(exam) {
      let averageExam =
        parseFloat(exam.exam1) * 0.1 +
        parseFloat(exam.exam2) * 0.1 +
        parseFloat(exam.exam3) * 0.1 +
        parseFloat(exam.exam4) * 0.1 +
        parseFloat(exam.exam5) * 0.2 +
        parseFloat(exam.exam6) * 0.2 +
        parseFloat(exam.exam7) * 0.1 +
        parseFloat(exam.exam8) * 0.1;
      return averageExam;
    },
    calculateAverageExamValues(values) {
      //   console.log(values);
      let exam1 = [];
      let exam2 = [];
      let exam3 = [];
      let exam4 = [];
      let exam5 = [];
      let exam6 = [];
      let exam7 = [];
      let exam8 = [];
      let exam1_8 = [];
      values.forEach((element) => {
        exam1.push(parseFloat(element.exam1));
        exam2.push(parseFloat(element.exam2));
        exam3.push(parseFloat(element.exam3));
        exam4.push(parseFloat(element.exam4));
        exam5.push(parseFloat(element.exam5));
        exam6.push(parseFloat(element.exam6));
        exam7.push(parseFloat(element.exam7));
        exam8.push(parseFloat(element.exam8));
        exam1_8.push(
          parseFloat(
            element.exam1 * 0.1 +
              element.exam2 * 0.1 +
              element.exam3 * 0.1 +
              element.exam4 * 0.1 +
              element.exam5 * 0.2 +
              element.exam6 * 0.2 +
              element.exam7 * 0.1 +
              element.exam8 * 0.1
          )
        );
      });
      //   console.log(exam1);
      let exam1Sum = exam1.reduce(function (a, b) {
        return a + b;
      });
      let exam2Sum = exam2.reduce(function (a, b) {
        return a + b;
      });
      let exam3Sum = exam3.reduce(function (a, b) {
        return a + b;
      });
      let exam4Sum = exam4.reduce(function (a, b) {
        return a + b;
      });
      let exam5Sum = exam5.reduce(function (a, b) {
        return a + b;
      });
      let exam6Sum = exam6.reduce(function (a, b) {
        return a + b;
      });
      let exam7Sum = exam7.reduce(function (a, b) {
        return a + b;
      });
      let exam8Sum = exam8.reduce(function (a, b) {
        return a + b;
      });
      let exam1_8Sum = exam1_8.reduce(function (a, b) {
        return a + b;
      });
      this.examPerValuesAverage.exam1Ave = exam1Sum / exam1.length;
      this.examPerValuesAverage.exam2Ave = exam2Sum / exam2.length;
      this.examPerValuesAverage.exam3Ave = exam3Sum / exam3.length;
      this.examPerValuesAverage.exam4Ave = exam4Sum / exam4.length;
      this.examPerValuesAverage.exam5Ave = exam5Sum / exam5.length;
      this.examPerValuesAverage.exam6Ave = exam6Sum / exam6.length;
      this.examPerValuesAverage.exam7Ave = exam7Sum / exam7.length;
      this.examPerValuesAverage.exam8Ave = exam8Sum / exam8.length;
      this.examPerValuesAverage.exam1_8Ave = exam1_8Sum / exam1_8.length;
      //   console.log(this.examPerValuesAverage);
    },
    nameWithExams({ exam_type, name }) {
      return `${exam_type}-ын шалгалт: ${name}`;
    },
    symbolGradeCheck(gradeValue) {
      if (gradeValue >= 97) {
        return (this.symbolGrade = "A+, GPA 4.33 or 4.00");
      } else if (gradeValue >= 93) {
        return (this.symbolGrade = "A, GPA 4.00");
      } else if (gradeValue >= 90) {
        return (this.symbolGrade = "A-, GPA 3.67");
      } else if (gradeValue >= 87) {
        return (this.symbolGrade = "B+, GPA 3.33");
      } else if (gradeValue >= 83) {
        return (this.symbolGrade = "B, GPA 3.00");
      } else if (gradeValue >= 80) {
        return (this.symbolGrade = "B-, GPA 2.67");
      } else if (gradeValue >= 77) {
        return (this.symbolGrade = "C+, GPA 2.33");
      } else if (gradeValue >= 73) {
        return (this.symbolGrade = "C, GPA 2.00");
      } else if (gradeValue >= 70) {
        return (this.symbolGrade = "C-, GPA 1.67");
      } else if (gradeValue >= 67) {
        return (this.symbolGrade = "D+, GPA 1.33");
      } else if (gradeValue >= 63) {
        return (this.symbolGrade = "D, GPA 1.00");
      } else if (gradeValue >= 60) {
        return (this.symbolGrade = "D-, GPA 0.67");
      } else {
        return (this.symbolGrade = "F, GPA 0.00");
      }
    },
    toOronTootsokh(value) {
      return parseFloat(value).toFixed(1);
    },
  },
  created() {
    this.getMyChilds();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
</style>
