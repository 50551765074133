<template>
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Цахим хэрэглэгдэхүүн(Ангилал) - Багшийн бүх төрлийн цахим
            контентуудтай холбогдох учир устгахдаа маш сайн нягтална уу!!!
          </h3>
        </div>
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <b-container fluid>
              <b-row>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="3">
                      <b-form-input
                        id="input-2"
                        v-model="formDigitalResourceCategory.drc_name"
                        placeholder="Ангилалын нэр..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        id="input-2"
                        v-model="formDigitalResourceCategory.drc_description"
                        placeholder="Ангилалын тайлбар..."
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="digtalResourceCategoryCreate()"
                        v-if="!showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon stacked icon="check" variant="success"></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="digtalResourceCategoryUpdate()"
                        v-if="showEdit"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          scale="0.5"
                          icon="tools"
                          variant="warning"
                        ></b-icon>
                      </b-iconstack>
                      <b-iconstack
                        font-scale="2"
                        @click.prevent="digitalResourceCategoryClear()"
                      >
                        <b-icon stacked icon="square" variant="info"></b-icon>
                        <b-icon
                          stacked
                          icon="x-circle"
                          scale="0.6"
                          variant="danger"
                        ></b-icon>
                      </b-iconstack>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col sm="2"
                  ><multiselect
                    v-model="selectedMonths"
                    :options="monthsRe"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    placeholder="Сар сонгох"
                    :allow-empty="true"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong> {{ option.month }}</strong
                      ><strong> сонгогдлоо</strong></template
                    >
                  </multiselect>
                </b-col>
                <b-col sm="2"
                  ><b-button variant="success" @click.prevent="getAllTask()"
                    >Шүүж харах</b-button
                  >
                </b-col> -->
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Ангилалын нэр</th>
                <th>Ангилалын тайлбар</th>
                <th>Дэд ангилал</th>
                <th>Үйлдлүүд</th>
                <th>Үүсгэсэн</th>
                <th>Зассан</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(drCategory, index) in digitalResourceCategories"
                :key="drCategory.id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>

                <td
                  class="dtr-control sorting_1"
                  style="text-align: center"
                  tabindex="0"
                >
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    drCategory.drc_name
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge variant="light" style="white-space: pre-wrap">{{
                    drCategory.drc_description
                  }}</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Нэр</th>
                        <th scope="col">Тайлбар</th>
                        <th scope="col">Үүсгэсэн</th>
                        <th scope="col">Үйлдлүүд</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(drci, i) in drCategory.my_d_r_c_items"
                        :key="drci.id"
                      >
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ drci.drci_name }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ drci.drci_description }}</b-badge
                          >
                        </td>
                        <td>
                          <b-badge
                            variant="light"
                            style="white-space: pre-wrap"
                            >{{ drci.created_at | dateYearMonthDay }}</b-badge
                          >
                        </td>
                        <td>
                          <a
                            href="#"
                            class="badge bg-primary"
                            @click="editDigitalResourceCategoryList(drci)"
                            ><i class="fa fa-check"></i
                          ></a>
                          <a
                            href="#"
                            class="badge bg-danger"
                            @click="digtalResourceCategoryListDestroy(drci)"
                            ><b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-primary"
                    @click="editDigitalResourceCategory(drCategory)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="createDigitalResourceCategoryList(drCategory)"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <a
                    href="#"
                    class="badge bg-danger"
                    @click="deleteDigitalResourceCategory(drCategory)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></a>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ drCategory.created_at | dateYearMonthDay }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ drCategory.updated_at | dateYearMonthDay }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-dr-category-list"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      title="Дэд ангилал"
      class="modal fade"
      hide-footer
      @hide="cancel"
    >
      <div class="modal-header">
        <h1>{{ drCategoryInfo.drc_name }}</h1>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <form>
              <div class="form-group">
                <label>Дэд ангилалын нэр</label>
                <b-form-input
                  id="input-2"
                  v-model="formDigitalResourceCategoryItem.drci_name"
                  placeholder="Дэд ангилалын нэр..."
                  required
                ></b-form-input>
                <has-error
                  :form="formDigitalResourceCategoryItem"
                  field="drci_name"
                ></has-error>
              </div>
              <div class="form-group">
                <label>Дэд ангилалын тайлбар</label>
                <b-form-input
                  id="input-3"
                  v-model="formDigitalResourceCategoryItem.drci_description"
                  placeholder="Дэд ангилалын тайлбар..."
                  required
                ></b-form-input>
                <has-error
                  :form="formDigitalResourceCategoryItem"
                  field="drci_description"
                ></has-error>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <b-button @click="cancel()" variant="danger" type="reset">
          Хаах
        </b-button>
        <button
          type="submit"
          class="btn btn-success"
          v-show="!showEditRCL"
          @click.prevent="storeDigitalResourceCategoryList()"
        >
          Хадгалах
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          v-show="showEditRCL"
          @click.prevent="updateDigitalResourceCategoryItem()"
        >
          Засах
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      headerBgVariant: "light",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      // RiskCategory эхэллээ
      digitalResourceCategories: [],
      formDigitalResourceCategory: new Form({
        id: "",
        drc_name: "",
        drc_description: "",
      }),
      showEdit: false,
      drCategoryInfo: "",

      //   RiskCategoryList эхэллээ
      formDigitalResourceCategoryItem: new Form({
        id: "",
        drc_id: "",
        drci_name: "",
        drci_description: "",
      }),
      showEditRCL: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //Эрсдэлийн ангилал эхэлж байна...
    getAllDigitalResourceCategories() {
      axios
        .get("/manager/getAllDigitalResourceCategories")
        .then((res) => {
          //   console.log(res);
          this.digitalResourceCategories = res.data.digitalResourceCategories;
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    digtalResourceCategoryCreate() {
      //   console.log(this.formDigitalResourceCategory);
      this.formDigitalResourceCategory
        .post("/manager/digtalResourceCategoryCreate")
        .then((res) => {
          // console.log(res);
          Fire.$emit("getDigitalResources");
          this.formDigitalResourceCategory.reset();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    editDigitalResourceCategory(drCategory) {
      //   console.log(drCategory);
      this.formDigitalResourceCategory.fill(drCategory);
      this.showEdit = true;
    },
    digtalResourceCategoryUpdate() {
      this.formDigitalResourceCategory
        .put(
          "/manager/digtalResourceCategoryUpdate/" +
            this.formDigitalResourceCategory.id
        )
        .then((res) => {
          //   console.log(res);
          Fire.$emit("getDigitalResources");
          this.formDigitalResourceCategory.reset();
          this.showEdit = false;
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    deleteDigitalResourceCategory(drCategory) {
      //   console.log(drCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Ангилал устгахад, түүнд харьяалагдах бүх дэд ангилал устана.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/manager/digtalResourceCategoryDestroy/${drCategory.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getDigitalResources");
              Swal.fire("Амжилттай устгалаа!", "Ангилал устгасан.", "success");
            })
            .catch((err) => {
              // console.log(err);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Устгах үйлдлийг цуцалллаа :)", "error");
        }
      });
    },
    digitalResourceCategoryClear() {
      this.formDigitalResourceCategory.reset();
      this.showEdit = false;
    },
    //Дэд ангилалууд

    createDigitalResourceCategoryList(drCategory) {
      // console.log(drCategory);
      //   this.getCourseStudents(train.id);
      //   this.showModalTeachers = true;
      this.drCategoryInfo = drCategory;
      this.formDigitalResourceCategoryItem.drc_id = drCategory.id;
      this.$bvModal.show("modal-dr-category-list");
    },

    storeDigitalResourceCategoryList() {
      this.formDigitalResourceCategoryItem
        .post("/manager/storeDigitalResourceCategoryList")
        .then((res) => {
          this.$toastr.s("Дэд ангилал амжилттай нэмлээ!", "Шинэчилсэн");
          this.resetFormDRCI();
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    resetFormDRCI() {
      this.showEditRCL = false;
      Fire.$emit("getDigitalResources");
      this.formDigitalResourceCategoryItem.reset();
      this.$bvModal.hide("modal-dr-category-list");
    },

    cancel() {
      this.showEditRCL = false;
      this.formDigitalResourceCategoryItem.reset();
      this.drCategoryInfo = "";
      this.$bvModal.hide("modal-dr-category-list");
    },

    editDigitalResourceCategoryList(drci) {
      //   console.log(drci);
      this.formDigitalResourceCategoryItem.fill(drci);
      this.showEditRCL = true;
      this.$bvModal.show("modal-dr-category-list");
    },
    updateDigitalResourceCategoryItem() {
      //   console.log("update");

      this.formDigitalResourceCategoryItem
        .put(
          "/manager/updateDigitalResourceCategoryItem/" +
            this.formDigitalResourceCategoryItem.id
        )
        .then((res) => {
          //   console.log(res);
          this.resetFormDRCI();
        })
        .catch((err) => {
          // console.log("errors");
        });
    },
    digtalResourceCategoryListDestroy(drci) {
      //   console.log(riskCategory);
      Swal.fire({
        title: "Та итгэлтэй байна уу?",
        text: "Дэд ангилал устгах гэж байна.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Тийм, устга!",
        cancelButtonText: "Үгүй, цуцлах!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/manager/digtalResourceCategoryListDestroy/${drci.id}`)
            .then((res) => {
              //   console.log(res.data);
              Fire.$emit("getDigitalResources");
            })
            .catch((err) => {
              // console.log(err);
            });
          Swal.fire("Амжилттай устгалаа!", "Дэд ангилал устгасан.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Цуцалсан", "Дэд ангилал устгахыг цуцалсан :)", "error");
        }
      });
    },

    customLabel({ emp_teacher_last_name, emp_teacher_name }) {
      return `${emp_teacher_last_name[0]}. ${emp_teacher_name}`;
    },
  },
  created() {
    // this.getAllTask();
    this.getAllDigitalResourceCategories();
    Fire.$on("getDigitalResources", () => {
      this.getAllDigitalResourceCategories();
    });
    // Fire.$on("getSubTrainings", () => {
    //     this.getSubTrainAgain();
    // });
  },
};
</script>

<style></style>
