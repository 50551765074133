<template>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Бэлтгэл ангийн төлбөрийн мэдээлэл хянах</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <div class="col-sm-6">
                  <div class="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover text-nowrap">
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>РД</th>
                <th>Анги</th>
                <th>Тэмдэглэл</th>
                <th>Холбоо барих мэдээлэл</th>
                <th>Төлөв(элсэлт)</th>
                <th>Төлбөр</th>
                <th>Төлсөн</th>
                <th>Үлдэгдэл</th>
                <th>Төлөв</th>
                <th>Хувь</th>
                <th>Хөнгөлөлт</th>
                <th>Санхүүгийн тэмдэглэл</th>
                <th>Үйлдлүүд</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr
                v-bind="
                  getPercent(schoolEntrant.pay, schoolEntrant.paid, index + 1)
                "
              > -->
              <tr
                v-for="(schoolEntrant, index) in schoolentrants"
                :key="schoolEntrant.user_id"
              >
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ index + 1 }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.profile_last_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.user_name }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.student_reg }}
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span v-if="schoolEntrant.study_class == 0">Бэлтгэл</span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="warning"
                    class="badge badge-primary text-wrap"
                    style="width: 8rem"
                    v-if="schoolEntrant.study_class_sanal_desc !== null"
                    >{{ schoolEntrant.study_class_sanal_desc }}</b-badge
                  >
                  <b-badge variant="info" v-else
                    >Мэдээлэл бүртгэгдээгүй.</b-badge
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="success"
                    v-if="schoolEntrant.contact_info !== null"
                    >{{ schoolEntrant.contact_info }}</b-badge
                  >
                  <b-badge variant="info" v-else>Мэдээлэлгүй</b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    v-if="schoolEntrant.payment_type === 0"
                  >
                    Нөхөн
                  </b-badge>
                  <b-badge
                    variant="success"
                    class="text-wrap"
                    v-if="schoolEntrant.payment_type === 1"
                  >
                    Үндсэн
                  </b-badge>
                  <b-badge
                    variant="info"
                    class="text-wrap"
                    v-if="schoolEntrant.payment_type === 2"
                  >
                    Дэвших
                  </b-badge>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.pay }} төг
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{ schoolEntrant.paid }} төг
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  {{
                    schoolEntrant.pay -
                    schoolEntrant.paid -
                    schoolEntrant.discount
                  }}
                  төг
                </td>
                <td class="dtr-control sorting_1 pt-4" tabindex="0">
                  <div class="progress progress-xs progress-striped active">
                    <div
                      v-if="schoolEntrant.percent < 30"
                      class="progress-bar bg-danger"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 30 &&
                        schoolEntrant.percent < 60
                      "
                      class="progress-bar bg-warning"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="
                        schoolEntrant.percent >= 60 &&
                        schoolEntrant.percent < 80
                      "
                      class="progress-bar bg-primary"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                    <div
                      v-if="schoolEntrant.percent >= 80"
                      class="progress-bar bg-success"
                      :style="{
                        width: `${schoolEntrant.percent}%`,
                      }"
                    ></div>
                  </div>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.status1 === 'confirmed'"
                    class="badge bg-danger"
                    >ШИНЭ</span
                  >
                  <span
                    v-if="schoolEntrant.percent < 30"
                    class="badge bg-danger"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 30 && schoolEntrant.percent < 60
                    "
                    class="badge bg-warning"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.percent >= 60 && schoolEntrant.percent < 80
                    "
                    class="badge bg-primary"
                    >{{ schoolEntrant.percent }}%</span
                  >
                  <span
                    v-if="schoolEntrant.percent >= 80"
                    class="badge bg-success"
                    >{{ schoolEntrant.percent }}%</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    v-if="schoolEntrant.discountPercent < 30"
                    class="badge bg-danger"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.discountPercent >= 30 &&
                      schoolEntrant.discountPercent < 60
                    "
                    class="badge bg-warning"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="
                      schoolEntrant.discountPercent >= 60 &&
                      schoolEntrant.discountPercent < 80
                    "
                    class="badge bg-primary"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                  <span
                    v-if="schoolEntrant.discountPercent >= 80"
                    class="badge bg-success"
                    >{{ schoolEntrant.discountPercent }}%</span
                  >
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <span
                    class="badge badge-primary text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-if="schoolEntrant.info_of_pay !== null"
                  >
                    {{ schoolEntrant.info_of_pay }}
                  </span>
                  <span
                    class="badge badge-info text-wrap"
                    style="width: 10rem; font-size: 80%"
                    v-else
                  >
                    Мэдээлэл бүртгэгдээгүй
                  </span>
                </td>
                <td class="dtr-control sorting_1" tabindex="0">
                  <a
                    href="#"
                    class="badge bg-success"
                    @click="editSchoolEntrant(schoolEntrant)"
                    ><i class="fa fa-check"></i
                  ></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr role="row">
                <th colspan="4"></th>
                <th>{{ totalClass }}</th>
                <th>{{ totalPaid }}</th>
                <th>70%</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
    <div class="col-lg-4 mt-3" v-show="editMode">
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title" v-show="editMode">
            Элсэгчийн төлбөр баталгаажуулах
          </h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form @submit.prevent="editMode ? updateSchoolEntrantBeltgel() : ''">
          <div class="card-body">
            <div class="form-group">
              <label>Овог</label>
              <input
                type="text"
                name="profile_last_name"
                v-model="form.profile_last_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('profile_last_name'),
                  },
                ]"
                placeholder="Овог"
              />
              <has-error :form="form" field="profile_last_name"></has-error>
            </div>
            <div class="form-group">
              <label>Нэр</label>
              <input
                type="text"
                name="user_name"
                v-model="form.user_name"
                disabled
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('user_name'),
                  },
                ]"
                placeholder="Нэр"
              />
              <has-error :form="form" field="user_name"></has-error>
            </div>
            <div class="form-group">
              <label>Элсэлтийн төрөл</label>
              <b-form-select
                v-model="form.payment_type"
                :options="payment_types"
                text-field="text"
                value-field="value"
              >
              </b-form-select>
              <has-error :form="form" field="payment_type"></has-error>
            </div>
            <div class="form-group">
              <label>Төлсөн төлбөр</label>
              <input
                :max="form.pay"
                type="number"
                name="paid"
                v-model="form.paid"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('paid'),
                  },
                ]"
                placeholder="Төлсөн төлбөр"
              />
              <has-error :form="form" field="paid"></has-error>
            </div>
            <div class="form-group">
              <label>Хөнгөлөлт</label>
              <input
                :max="form.pay"
                type="number"
                name="paid"
                v-model="form.discount"
                :class="[
                  'form-control',
                  {
                    'is-invalid': form.errors.has('discount'),
                  },
                ]"
                placeholder="Төлсөн төлбөр"
              />
              <has-error :form="form" field="discount"></has-error>
            </div>
            <div class="form-group">
              <label>Санхүүгийн тэмдэглэл</label>
              <b-form-textarea
                id="info_of_pay"
                v-model="form.info_of_pay"
                placeholder="Төлбөртэй холбоотой мэдээллүүд тэмдэглэх."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-success" v-show="editMode">
              Хадгалах
            </button>
            <button
              class="btn btn-secondary"
              v-show="editMode"
              @click.prevent="cancelEdit"
            >
              Болих
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import searchStudent from "./StudentSearch/studentSearch.vue";
export default {
  data() {
    return {
      action: "",
      editMode: false,
      schoolentrants: [],
      form: new Form({
        id: "",
        user_name: "",
        profile_last_name: "",
        user_id: "",
        payment_type: "",
        pay: "",
        paid: "",
        discount: "",
        info_of_pay: "",
      }),
      totalClass: 0,
      totalPaid: 0,
      averagePer: 0,
      percent: 0,

      constClassId: "",

      //payment_type
      payment_types: [
        { value: 0, text: "Нөхөн" },
        { value: 1, text: "Үндсэн" },
        { value: 2, text: "Дэвших" },
      ],
    };
  },
  components: {
    Multiselect,
  },

  methods: {
    getAllEntrants() {
      //   console.log(newVal, "new val");
      axios
        .get("/finance/getAllEntrantsBeltgel")
        .then((response) => {
          //   this.constGetAllEntrunts();
          const allStudents = response.data.students;
          //   allStudents.filter((el) => el.school_class_id);
          allStudents.forEach((element) => {
            if (element.paid != 0) {
              element.percent = parseFloat(
                (element.paid * 100) / element.pay
              ).toFixed(2);
            } else {
              element.percent = 0;
            }
            if (element.discount != 0) {
              element.discountPercent = parseFloat(
                (element.discount * 100) / element.pay
              ).toFixed(2);
            } else {
              element.discountPercent = 0;
            }
          });
          //   console.log(allStudents, "Нэгтгэсэн data");
          this.schoolentrants = allStudents;
          //   this.schoolentrants = response.data.schoolentrants;
        })
        .catch((error) => {
          //   console.log(error);
        });
    },

    //Бүх суралцагчыг 0 төлбөр төлсөн болгож finance_payments хүснэгтэнд нэг удаа үүсгэж өгнө. Дахиж хамаагүй ашиглаж болохгүй.
    allEntruntsExistBeltgel() {
      axios
        .get("/finance/checkStudentBeltgelPaymentInfoExists")
        .then((response) => {
          console.log(response.data);
          //   console.log(allStudents, "Бүх сурагчид");
        })
        .catch((error) => {
          //   console.log(error);
        });
    },
    editSchoolEntrant(schoolEntrant) {
      //   console.log("Дэлгэрэнгүй мэдээлэл засах" + schoolEntrant);
      this.editMode = true;
      this.form.reset();
      this.form.fill(schoolEntrant);
    },
    updateSchoolEntrantBeltgel() {
      //   console.log(this.form);
      this.form
        .put("/finance/updateEntrantPayBeltgel/" + this.form.user_id)
        .then((response) => {
          //   console.log(response.data);
          this.$toastr.s("Амжилттай", "Шинэчилсэн");
          Fire.$emit("loadAllEntrunts");
          this.form.reset();
          this.editMode = !this.editMode;
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
    deleteSchoolStudent(schoolteacher) {
      //   console.log("Дэлгэрэнгүй мэдээлэл устгах" + schoolteacher);
    },
    cancelEdit() {
      this.form.reset();
      this.editMode = !this.editMode;
    },
  },
  created() {
    this.getAllEntrants();
    Fire.$on("loadAllEntrunts", () => {
      this.getAllEntrants();
    });
  },
};
</script>

<style></style>
