<template>
  <section class="content">
    <div class="container-fluid">
      <!-- Багшийн ерөнхий болон ур чадварын мэдээлэл эхлэл -->
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mt-2">
            <div class="card">
              <div class="card-body">
                <h5><b>КАЛЕНДАР ТӨЛӨВЛӨГӨӨНИЙ ХЭРЭГЖИЛТ</b></h5>
                <table class="table table-bordered text-center">
                  <thead>
                    <tr role="row">
                      <th rowspan="2">№</th>
                      <th rowspan="2">Судлагдахууны нэр (Анги)</th>
                      <th colspan="5">
                        Сургалтын хөтөлбөрийн агуулгын гүйцэтгэл
                      </th>
                      <th rowspan="2">
                        Гүйцэтгэлд нөлөөлсөн сөрөг хүчин зүйлс
                      </th>
                    </tr>
                    <tr role="row">
                      <th>Хэрэгжүүлбэл зохих бүлэг сэдвийн тоо</th>
                      <th>Хэрэгжүүлбэл зохих ээлжит хичээлийн тоо</th>
                      <th>Хэрэгжүүлсэн бүлэг сэдвийн тоо</th>
                      <th>Баталгаажуулсан ээлжит хичээлийн тоо</th>
                      <th>Хэрэгжүүлсэн ээлжит хичээлийн тоо</th>
                      <th>Гүйцэтгэлийн хувь</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(subName, index) in myPlanCalendar"
                    :key="subName.id"
                  >
                    <tr>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ index + 1 }}
                      </td>

                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.subject_name }} / {{ subName.grade }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.subjCount }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.topicCount }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.successCount }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.successShortCount }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{ subName.successTopicCount }}
                      </td>
                      <td class="dtr-control sorting_1" tabindex="0">
                        {{
                          (
                            (subName.successTopicCount / subName.topicCount) *
                            100
                          ).toFixed(2)
                        }}%
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <apexchart
                  v-if="!showStat"
                  type="radar"
                  height="350"
                  :options="chartStatAll"
                  :series="seriesStatAll"
                ></apexchart>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["teacherId"],
  data() {
    return {
      myPlanCalendar: [],
      editMode: false,
      showStat: true,
      seriesStatAll: [
        {
          name: "Гүйцэтгэлийн хувь",
          data: [],
        },
      ],
      chartStatAll: {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560", "#775DD0"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      },
    };
  },
  methods: {
    getStat7() {
      this.showStat = true;
      axios
        .post("/teacher/getStat7", {
          teacherId: this.teacherId,
        })
        .then((res) => {
          this.myPlanCalendar = res.data.myPlanCalendar;
          this.seriesStatAll[0].data = res.data.myPlanCalendar.map((item) => {
            return ((item.successTopicCount / item.topicCount) * 100).toFixed(
              2
            );
          });
          this.chartStatAll.xaxis.categories = res.data.myPlanCalendar.map(
            (item) => {
              return item.subject_name + " / " + item.grade;
            }
          );
          this.showStat = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getStat7();
  },
};
</script>
